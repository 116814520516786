import FiltersDto from "../../../library/FilterList/FiltersDto"
import FlowNode from "../../../library/flow/FlowNode"
import FlowNodeType, { getDefaultTitleForType } from "./FlowNodeType"
import { v4 as uuid } from "uuid"

export type EntityCreatedFlowNode = FlowNode & {
    nodeType: "entity created"
}

export type WaitForConditionFlowNode = FlowNode & {
    nodeType: "wait for condition"
    filterGroups: {
        reference: string
        displayName: string
        filters: FiltersDto
    }[]
}

export type FiltersFlowNode = FlowNode & {
    nodeType: "filters"
    filterGroups: {
        reference: string
        displayName: string
        filters: FiltersDto
    }[]
    defaultDisplayName: string
}

export type SendEmailActionFlowNode = FlowNode & {
    nodeType: "send email action"
    templateReference: string
}

export type SendSmsActionFlowNode = FlowNode & {
    nodeType: "send sms action"
    templateReference: string
}

export type SendPostActionFlowNode = FlowNode & {
    nodeType: "send post action"
    templateReference: string
}

export type UpdateFieldsActionFlowNode = FlowNode & {
    nodeType: "update fields action"
    fields: {
        reference: string
        fieldName: string
        updateValue: string
        type: "set" | "increment"
    }[]
}

export type ExportActionFlowNode = FlowNode & {
    nodeType: "export action"
    exportConfigReference: string
}

export type OutboundIvrActionFlowNode = FlowNode & {
    nodeType: "outbound ivr action"
    outputs: {
        reference: string
        displayName: string
    }[]
}

export type FetchExternalDataActionFlowNode = FlowNode & {
    nodeType: "fetch external data action"
}

export type UndefinedProcessFlowNode = FlowNode & {
    nodeType: "undefined process"
    outputs: {
        reference: string
        displayName: string
    }[]
}

export const getDefaultStateForType = (nodeType: FlowNodeType, position: { x: number; y: number }): FlowSculptorNode => {
    const reference = uuid()

    const baseFlowNode: FlowNode = {
        reference,
        displayName: getDefaultTitleForType(nodeType),
        description: "",
        x: position.x,
        y: position.y
    }

    switch (nodeType) {
        case "entity created":
            return {
                nodeType,
                ...baseFlowNode
            }
        case "wait for condition":
            return {
                nodeType,
                filterGroups: [],
                ...baseFlowNode
            }
        case "filters":
            return {
                nodeType,
                filterGroups: [],
                defaultDisplayName: "Didn't Match",
                ...baseFlowNode
            }
        case "send email action":
            return {
                nodeType,
                templateReference: "",
                ...baseFlowNode
            }
        case "send sms action":
            return {
                nodeType,
                templateReference: "",
                ...baseFlowNode
            }
        case "send post action":
            return {
                nodeType,
                templateReference: "",
                ...baseFlowNode
            }
        case "update fields action":
            return {
                nodeType,
                fields: [],
                ...baseFlowNode
            }
        case "export action":
            return {
                nodeType,
                exportConfigReference: "",
                ...baseFlowNode
            }
        case "outbound ivr action":
            return {
                nodeType,
                outputs: [],
                ...baseFlowNode
            }
        case "fetch external data action":
            return {
                nodeType,
                ...baseFlowNode
            }
        case "undefined process":
            return {
                nodeType,
                outputs: [],
                ...baseFlowNode
            }
    }
}

export const getOutputsForFlowNode = (flowNode: FlowSculptorNode): { reference: string; displayName: string }[] => {
    switch (flowNode.nodeType) {
        case "entity created":
            return [{ reference: "_on-created", displayName: "On Created" }]
        case "wait for condition":
            return [
                ...flowNode.filterGroups.map(filterGroup => ({
                    ...filterGroup,
                    displayName: filterGroup.displayName || "New Filter Group"
                })),
                { reference: "_timed-out", displayName: "After X Days" }
            ]
        case "filters":
            return [
                ...flowNode.filterGroups.map(filterGroup => ({
                    ...filterGroup,
                    displayName: filterGroup.displayName || "New Filter Group"
                })),
                { reference: "_didnt-match", displayName: flowNode.defaultDisplayName }
            ]
        case "send email action":
            return [
                { reference: "_success", displayName: "On Success" },
                { reference: "_failed-invalid-email", displayName: "On Failed: Invalid Email Address" },
                { reference: "_failed-no-template", displayName: "On Failed: Missing Template" },
                { reference: "_failed-invalid-config", displayName: "On Failed: Invalid Config" }
            ]
        case "send sms action":
            return [
                { reference: "_success", displayName: "On Success" },
                { reference: "_failed-invalid-phone", displayName: "On Failed: Invalid Phone Number" },
                { reference: "_failed-no-template", displayName: "On Failed: Missing Template" },
                { reference: "_failed-invalid-config", displayName: "On Failed: Invalid Config" }
            ]
        case "send post action":
            return [
                { reference: "_success", displayName: "On Success" },
                { reference: "_failed-invalid-address", displayName: "On Failed: Invalid Address" },
                { reference: "_failed-no-template", displayName: "On Failed: Missing template" },
                { reference: "_failed-invalid-config", displayName: "On Failed: Invalid Config" }
            ]
        case "update fields action":
            return [{ reference: "_success", displayName: "On Updated" }]
        case "export action":
            return [
                { reference: "_success", displayName: "On Success" },
                { reference: "_failed-invalid-config", displayName: "On Failed: Invalid Config" }
            ]
        case "outbound ivr action":
        case "undefined process":
            return flowNode.outputs.filter(o => o.displayName !== "")
        case "fetch external data action":
            return [
                { reference: "_success", displayName: "On Success" },
                { reference: "_failed-invalid-config", displayName: "On Failed: Invalid Config" }
            ]
        default:
            return []
    }
}

type FlowSculptorNode =
    | EntityCreatedFlowNode
    | WaitForConditionFlowNode
    | FiltersFlowNode
    | SendEmailActionFlowNode
    | SendSmsActionFlowNode
    | SendPostActionFlowNode
    | UpdateFieldsActionFlowNode
    | ExportActionFlowNode
    | OutboundIvrActionFlowNode
    | FetchExternalDataActionFlowNode
    | UndefinedProcessFlowNode

export default FlowSculptorNode
