import React from 'react'

const TextInput = ({ change, value, tabIndex, nullable, maxLength = 255 }) => {
  const onLocalChange = (e) => change(e.target.value)

  const input = (
    <input
      type="text"
      className="form-control"
      maxLength={maxLength}
      value={value}
      onChange={onLocalChange}
      tabIndex={tabIndex}
    />
  )

  return nullable ? (
    input
  ) : (
    <div className="form-control-feedback-wrapper warning">
      {input}
      <span className="form-control-feedback">
        <i className='fa-solid fa-asterisk'></i>
      </span>
    </div>
  )
}

export default TextInput
