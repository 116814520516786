import './scss/app.scss';
import { Provider } from 'react-redux';
import Ero57IntlProvider from './components/ero57IntlProvider';
import { bindActionCreators } from 'redux';
import * as configurationActions from './actions/configurationActions';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import { useClient } from 'invevo-react-components';
import LegacyLoader from './LegacyLoader';
import '@progress/kendo-theme-bootstrap/dist/all.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap4c-custom-switch/dist/css/component-custom-switch.min.css';
import './styles/app.scss';
import $ from 'jquery';
window.$ = $; // aim to remove jquery dependancy

const LegacyApp = ({
    className,
    children,
    actions: { loadGlobalConfiguration },
    store
}) => {
  const client = useClient()

  useEffect(() => {
    if (!client) {
      return
    }

    loadGlobalConfiguration(client);
  }, [loadGlobalConfiguration, client]);

  return (
    <div className={className}>
        <Provider store={store}>
          <Ero57IntlProvider>
              <LegacyLoader>
                {children}
              </LegacyLoader>
          </Ero57IntlProvider>
        </Provider>
    </div>
  );
}

const mapStateToProps = (state) => ({
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(configurationActions, dispatch)
  };
}

const reduxWrappedApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(LegacyApp);

export default hot(module)(reduxWrappedApp);