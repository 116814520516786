import moment from "moment"
import { DataTypeEnum, getDataPrimitiveTypeForEntityDataType } from "../../routes/entityConfig/types/DataType"
import { FieldsUpdate } from "../common"
import HeaderDefinition from "./types/HeaderDefinition"
import RowData from "./types/RowData"
import { formatDateFromNumber } from "../helpers/dateUtils"
import { convertToCurrency } from "../helpers/numberUtils"
import Lookup from "../../types/Lookup"
import EntityDataFieldDataType from "../../routes/entityConfig/types/EntityDataFieldDataType"
import FiltersDto from "../FilterList/FiltersDto"
import DataPrimitive from "../../types/DataPrimitive"
import { GridState } from "./Grid"

export const getValueForHeader = (rowData: RowData, header: HeaderDefinition) => {
    if (header.value === "reference") {
        return rowData.reference.toUpperCase()
    }
    switch (header.displayType) {
        case DataTypeEnum.TEXT:
        case DataTypeEnum.RICH_TEXT:
        case DataTypeEnum.EMAIL:
        case DataTypeEnum.PHONE_NUMBER:
        case DataTypeEnum.LOOKUP:
            return rowData.fields.textFields[header.value]
        case DataTypeEnum.MONEY:
        case DataTypeEnum.INT:
        case DataTypeEnum.DECIMAL:
        case DataTypeEnum.PERCENTAGE:
            return rowData.fields.numberFields[header.value]
        case DataTypeEnum.DATE:
            return rowData.fields.dateFields[header.value]
        case DataTypeEnum.BOOLEAN:
            return rowData.fields.booleanFields[header.value]
    }
}

export const mergeFieldsUpdateIntoRowData = (originalRow: RowData, fieldUpdates: FieldsUpdate): RowData => ({
    reference: originalRow.reference,
    fields: {
        booleanFields: fieldUpdates.booleanFields.reduce(fieldReduceF, originalRow.fields.booleanFields),
        numberFields: fieldUpdates.numberFields.reduce(fieldReduceF, originalRow.fields.numberFields),
        dateFields: fieldUpdates.dateTimeFields
            .map(val => ({ ...val, fieldValue: val.fieldValue === undefined ? undefined : moment(val.fieldValue).valueOf() }))
            .reduce(fieldReduceF, originalRow.fields.dateFields),
        textFields: fieldUpdates.stringFields.reduce(fieldReduceF, originalRow.fields.textFields)
    }
})

const fieldReduceF = <T>(acc: Record<string, T>, val: { fieldName: string; fieldValue: T | undefined }) =>
    val.fieldValue === undefined
        ? acc
        : {
              ...acc,
              [val.fieldName]: val.fieldValue
          }

export const formatFieldValueForDataType = (value: string, field: EntityDataFieldDataType, lookups: Lookup[], hideCurrencySymbol: boolean = false) =>
    formatFieldValue(value, { ...field, displayType: field.type }, lookups, hideCurrencySymbol)

export const formatFieldValueForHeader = (
    value: string,
    header: HeaderDefinition,
    lookups: Lookup[],
    hideCurrencySymbol: boolean = false,
    shouldClearDateField: boolean = false
) => formatFieldValue(value, header, lookups, hideCurrencySymbol, shouldClearDateField)

const formatFieldValue = (
    value: string,
    header: {
        displayType: DataTypeEnum
        lookupReference?: string
        currencyCode?: string
        format?: string
        timezone?: string
    },
    lookups: Lookup[],
    hideCurrencySymbol: boolean = false,
    shouldClearDateField: boolean = false
) => {
    if (value === "") return ""
    switch (header.displayType) {
        case DataTypeEnum.TEXT:
        case DataTypeEnum.EMAIL:
        case DataTypeEnum.PHONE_NUMBER:
        case DataTypeEnum.RICH_TEXT:
            return value
        case DataTypeEnum.LOOKUP:
            if (header.lookupReference === undefined) return value
            return getDisplayNameForLookup(lookups, header.lookupReference, value) ?? value
        case DataTypeEnum.MONEY:
            return convertToCurrency(value, header.currencyCode !== undefined ? header.currencyCode : "", undefined, hideCurrencySymbol)
        case DataTypeEnum.INT:
            return parseInt(value).toFixed(0)
        case DataTypeEnum.DECIMAL:
            return parseFloat(value).toFixed(2)
        case DataTypeEnum.PERCENTAGE:
            return parseFloat(value).toFixed(4) + "%"
        case DataTypeEnum.DATE:
            return shouldClearDateField
                ? Math.max(parseInt(value), 0) === 0
                    ? ""
                    : formatDateFromNumber(parseInt(value), header.format, header.timezone)
                : formatDateFromNumber(parseInt(value), header.format, header.timezone)
        case DataTypeEnum.BOOLEAN:
            return value.toUpperCase()
    }
}

const getDisplayNameForLookup = (lookups: Lookup[], lookupRef: string, data: string) => {
    const lookup = lookups.find(l => l.reference === lookupRef)
    return lookup?.entries.find(e => e.reference.toLowerCase() === data.toLowerCase())?.name
}

export const mergeOptimisticUpdatesIntoEntities = (entitiesToUpdate: RowData[], optimisticallyUpdatedRows: RowData[]): RowData[] =>
    entitiesToUpdate.map(entity => {
        const optimisticValue = optimisticallyUpdatedRows.find(row => row.reference === entity.reference)?.fields ?? {
            booleanFields: {},
            numberFields: {},
            dateFields: {},
            textFields: {}
        }

        return {
            ...entity,
            fields: {
                ...entity.fields,
                booleanFields: {
                    ...entity.fields.booleanFields,
                    ...optimisticValue.booleanFields
                },
                numberFields: {
                    ...entity.fields.numberFields,
                    ...optimisticValue.numberFields
                },
                dateTimeFields: {
                    ...entity.fields.dateFields,
                    ...optimisticValue.dateFields
                },
                textFields: {
                    ...entity.fields.textFields,
                    ...optimisticValue.textFields
                }
            }
        }
    })

export const addSelectionFilter = (prevState: FiltersDto, selectedRefs: string[]) => ({
    ...prevState,
    textFieldIsOneOf: [
        ...(prevState.textFieldIsOneOf ?? []),
        {
            fieldName: "referenceSelected",
            values: selectedRefs,
            notOneOf: false
        }
    ]
})

export const getSortFromGridState = (gridState: GridState | undefined) =>
    gridState
        ? {
              fieldName: gridState.sorting.header.value,
              dataPrimitive: DataPrimitive[getDataPrimitiveTypeForEntityDataType(gridState?.sorting.header.displayType) ?? "TEXT"],
              direction: gridState.sorting.direction
          }
        : undefined
