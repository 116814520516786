import { Alert } from 'invevo-react-components'
import { widgetThemes } from "../../../enums/widgetThemes"
import ValueCard from '../../../../../library/cards/ValueCard'
import { DataTypeEnum } from '../../../../../routes/entityConfig/types/DataType'

const isNumber = value => !isNaN(value) && !isNaN(parseFloat(value))

const getStyle = value => isNumber(value) ? (Number(value) >= 0 ? "good" : "bad") : "info"

const EntityValueDisplayWidget = ({ state }) => {
    const { widget, entityFields, entityData } = state

    const getValue = (fieldName, fieldType) => {
        switch (fieldType) {
            case DataTypeEnum.BOOLEAN: return entityData.booleanFields.find(f => f.name === fieldName)?.value
            case DataTypeEnum.DATE: return entityData.dateFields.find(f => f.name === fieldName)?.value
            case DataTypeEnum.MONEY:
            case DataTypeEnum.DECIMAL:
            case "NUMBER":
            case DataTypeEnum.INT: return entityData.numberFields.find(f => f.name === fieldName)?.value
            default: return entityData.textFields.find(f => f.name === fieldName)?.value
        }
    }

    const entityFieldConfig = entityFields?.find(f => f.fieldName === widget.field.fieldName)
    return (
        getValue(widget.field.fieldName, widget.field.dataType) ?
            <ValueCard
                className="w-100 h-100"
                title={widget.displayName}
                theme={widget.theme === widgetThemes.DEFAULT ? "white" : "blue"}
                value1={{
                    label: widget.field.displayName,
                    value: getValue(widget.field.fieldName, widget.field.dataType),
                    style: getStyle(getValue(widget.field.fieldName, widget.field.dataType)),
                    dateFormat: 'DD MMM yyyy',
                    culture: "en-GB",
                    currency: entityFieldConfig?.dataType?.currencyCode
                }}
                value2={{
                    label: widget.field.displayName,
                    value: getValue(widget.field.fieldName, widget.field.dataType),
                    style: getStyle(getValue(widget.field.fieldName, widget.field.dataType)),
                    dateFormat: 'DD MMM yyyy',
                    culture: "en-GB",
                    currency: entityFieldConfig?.dataType?.currencyCode
                }}
            />
            : <Alert className="mb-3" iconClasses="fal fa-exclamation-triangle" text={`No ${widget.field.fieldName} value available for this customer`} />
    )
}

export default EntityValueDisplayWidget