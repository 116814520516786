import { useClient } from 'invevo-react-components';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Nav from '../../api/navApi';
import { useSearchQuery } from '../../hooks/useSearchQuery';
import AdvancedSearch from './AdvancedSearch';
import QuickSearch from './QuickSearch';
import SearchResultsGrid from './SearchResultsGrid';

const LegacySearchContainer = ({ removeRouteQuery }) => {
  const client = useClient()
  const [selected, setSelected] = useState(0);

  const [showSearchResult, setShowSearchResult] = useState(false);
  const searchToggle = () => setShowSearchResult(!showSearchResult);

  const isNew = useSearchQuery();

  useEffect(() => {
    if (isNew && showSearchResult) {
      setShowSearchResult(false);
    }
    if (isNew) {
      removeRouteQuery('new');
      setSelected(0);
    }
  }, [isNew, showSearchResult, removeRouteQuery]);

  return (
    <div className="d-flex w-100 h-100">
      <div className="search-container w-100">
        <div
          className="search-criteria"
          style={{ display: showSearchResult ? 'none' : 'block' }}
        >
          <div className={'d-flex flex-wrap flex-column'}>
            <div
              className="d-flex m-3 btn-group"
              role="group"
              aria-label="Grid filter"
            >
              <button
                className={
                  selected === 0
                    ? 'btn btn-primary w-100 p-0'
                    : 'btn btn-default w-100 p-0'
                }
                onClick={() => setSelected(0)}
              >
                Quick Search
              </button>
              <button
                className={
                  selected === 1
                    ? 'btn btn-primary w-100 p-0'
                    : 'btn btn-default w-100 p-0'
                }
                onClick={() => setSelected(1)}
              >
                Advanced Search
              </button>
            </div>
            <div
              className="flex-grow-1"
              style={{ display: selected === 0 ? 'block' : 'none' }}
            >
              <QuickSearch onSearch={searchToggle} isNew={isNew} />
            </div>
            <div
              className="flex-grow-1"
              style={{ display: selected === 1 ? 'block' : 'none' }}
            >
              <AdvancedSearch onSearch={searchToggle} isNew={isNew} client={client} />
            </div>
          </div>
        </div>

        {showSearchResult && <SearchResultsGrid onBack={searchToggle} client={client} />}
      </div>
    </div>
  );
};

export default connect(null, {
  removeRouteQuery: Nav.RemoveRouteQuery
})(LegacySearchContainer);
