import { useClient } from "invevo-react-components"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import classes from "./SystemConfigurationButton.module.scss"

const SystemConfigurationButton = ({ baseRoute, title, page, iconsClasses, colour }) => {
    const history = useHistory()
    const client = useClient()

    const [isHovering, setIsHovering] = useState(false)

    return (
        <div
            className={`d-flex flex-column m-4 ${classes.container}`}
            role='button'
            onMouseOver={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onClick={() => history.push(`${baseRoute}${page}?client=${client}`)}
        >
            <div className={`d-flex justify-content-center w-100 px-5 py-5 ${classes.iconContainer} ${isHovering && classes.hovering}`}>
                <i className={`${iconsClasses} fa-2xl px-3`} style={{ "color": colour }} />
            </div>
            <div className={`d-flex justify-content-center align-items-center w-100 px-3 ${classes.textContainer} ${isHovering && classes.hovering}`}>
                <span className="mb-0 text-center">{title}</span>
            </div>
        </div>
    )
}

export default SystemConfigurationButton