import classes from "./GridRow.module.scss"
import moment from "moment"
import DataRow from "./types/DataRow"
import DataField from "../../types/DataField"
import Lookup from "../../types/Lookup"
import DataPrimitive from "../../types/DataPrimitive"
import { ReactNode } from "react"
import { convertToCurrency } from "../helpers/numberUtils"

type GridRowProps = {
    dataRow: DataRow
    dataFields: DataField[]
    lookups: Lookup[]
    renderRowActions?: (dataRow: DataRow) => ReactNode
}

const GridRow = ({ dataRow, dataFields, lookups, renderRowActions }: GridRowProps) => {
    const getDisplayNameForLookup = (lookupRef: string, data: string) => {
        const lookup = lookups.find(l => l.reference === lookupRef)
        return lookup?.entries.find(e => e.reference.toLowerCase() === data.toLowerCase())?.name
    }

    const getColumnDisplayValue = (field: DataField): string => {
        const data: string | undefined = dataRow.data[field.value]
        if (data === undefined) return ""

        switch (field.type) {
            case DataPrimitive.DATE:
                return data !== undefined ? moment(data).format(field.format || "DD MMM yyyy") : ""
            case DataPrimitive.LOOKUP:
                if (field.lookup === undefined) {
                    return data
                }
                return getDisplayNameForLookup(field.lookup, data) ?? data
            case DataPrimitive.NUMBER:
                if (field.value === "balance" || field.value === "overdue") {
                    return convertToCurrency(parseFloat(data).toFixed(2), dataRow.data["currencycode"])
                }
                return parseFloat(data).toFixed(0)
            case DataPrimitive.BOOLEAN:
                return data?.toUpperCase()
            case DataPrimitive.TEXT:
                return field.value.includes("ref") ? data.toUpperCase() : data
        }
    }

    return (
        <tr className={`${classes.tableRow} pointer`} role="row" aria-label="data-row">
            {dataFields.map((dataField, index) => {
                const fieldValue = getColumnDisplayValue(dataField)
                return (
                    <td key={`${dataRow.id}-${dataField.value}`} className={`${classes.dataItem} ${index === 0 ? classes.firstCell : ""} py-2 ps-2`}>
                        <div className="d-flex">
                            <div className="d-flex px-1 py-1 rounded fw-bold" style={fieldValue === "" ? {} : getFieldBorderStyle(dataField)}>
                                {fieldValue}
                            </div>
                        </div>
                    </td>
                )
            })}
            {renderRowActions ? (
                <td className={`${classes.stickRight} ${classes.lastCell}`}>
                    <div className="align-items-center d-flex justify-content-end pe-2">
                        <div className="ms-auto d-flex align-items-center">{renderRowActions(dataRow)}</div>
                    </div>
                </td>
            ) : (
                <td></td>
            )}
        </tr>
    )
}

export default GridRow

const getFieldBorderStyle = (field: DataField) => {
    switch (field.value) {
        case "text_name":
        case "name":
            return {
                border: "1px solid #96BED2",
                backgroundColor: "#C5DFEB"
            }
        case "number_overdue":
        case "overdue":
            return {
                border: "1px solid #C78488",
                backgroundColor: "#D6D4E2"
            }
        case "number_balance":
        case "balance":
            return {
                border: "1px solid #9AD0C9",
                backgroundColor: "#C5E0E5"
            }
        default:
            return {}
    }
}
