import update from 'immutability-helper';
import { isNil } from 'lodash';
import { NavigationType } from '../constants';

const init = {
  search: {
    criteria: {},
    foundData: undefined
  },
  immediateSearch: {
    query: {},
    searchNow: false
  },
  activityList: {
    /* Properties to restore user navigation state after "glimpsing" a panel */
    // Used to hold parameters required for last activity list.
    criteria: {},
    navType: NavigationType.default,
    gridState: {},
    isPersistedList: false,
    searchListPos: 1,
    increment: 1,
    next: {
      // Data for Next In Activity list.  This data represents the customer that will be loaded when you click on "Next"
      customerId: null,
      virtualAccountId: null,
      isLoading: false,
      page: 1,
      total: null,
      endOfList: false,
      paused: null
    }
  }
};

const searchReducer = (state = init, action) => {
  switch (action.type) {
    case 'DO_SEARCH':
      return update(state, {
        search: {
          criteria: { $set: action.searchCriteria },
          foundData: { $set: undefined }
        }
      });
    case 'DO_IMMEDIATE_SEARCH':
      return update(state, {
        immediateSearch: {
          $set: action.searchCriteria
        }
      });
    case 'RESET_IMMEDIATE_SEARCH':
      return update(state, {
        immediateSearch: {
          query: { $set: state.immediateSearch.query },
          searchNow: { $set: false }
        }
      })
    case 'RESET_IMMEDIATE_SEARCH_QUERY':
      return update(state, {
        immediateSearch: {
          query: { $set: {} },
          searchNow: { $set: false }
        }
      })
    case 'PAUSE_SEARCH_LIST':
      return update(state, {
        activityList: {
          next: {
            paused: {
              $set: {
                customerId: action.customerId,
                virtualAccountId: action.virtualAccountId
              }
            }
          }
        }
      });
    case 'RESUME_SEARCH_LIST':
      return update(state, {
        activityList: {
          next: {
            paused: { $set: null }
          }
        }
      });
    case 'CREATE_SEARCH_LIST':
      return update(state, {
        activityList: {
          searchListPos: { $set: 1 },
          gridState: { $set: action.gridState },
          criteria: { $set: action.searchCriteria },
          pause: { $set: false },
          isPersistedList: { $set: false },
          next: {
            customerId: { $set: null },
            virtualAccountId: { $set: null },
            isLoading: { $set: false },
            endOfList: { $set: false },
            page: { $set: 1 },
            total: { $set: action.total }
          }
        }
      });
    case 'DESTROY_SEARCH_LIST':
      return update(state, {
        activityList: { $set: init.activityList }
      });
    case 'SET_SEARCH_GRIDSTATE': {
      return update(state, {
        activityList: {
          gridState: { $set: action.gridState }
        }
      });
    }
    case 'SEARCH_LIST_NEXT_LOADING': {
      return update(state, {
        activityList: {
          next: {
            isLoading: { $set: true },
            endOfList: { $set: false },
            customerId: { $set: null },
            virtualAccountId: { $set: null }
          }
        }
      });
    }
    case 'SEARCH_LIST_NEXT_LOADED': {
      return update(state, {
        activityList: {
          next: {
            isLoading: { $set: false },
            endOfList: { $set: false },
            customerId: { $set: action.customerId },
            virtualAccountId: { $set: action.virtualAccountId },
            page: {
              $apply: function (page) {
                return isNil(action.page) ? page : action.page;
              }
            },
            total: {
              $apply: function (total) {
                return isNil(action.total) ? total : action.total;
              }
            }
          }
        }
      });
    }
    case 'STEP_SEARCH_LIST': {
      return update(state, {
        activityList: {
          searchListPos: { $set: action.page },
          next: {
            page: { $set: action.page },
            customerId: { $set: null },
            virtualAccountId: { $set: null }
          }
        }
      });
    }
    case 'SEARCH_LIST_END': {
      return update(state, {
        activityList: {
          next: {
            isLoading: { $set: false },
            endOfList: { $set: true },
            total: { $set: 0 }
          }
        }
      });
    }
    case 'LOAD_ACTIVITY_LIST':
      return update(state, {
        criteria: state.activityList.criteria
      });
    default:
      return state;
  }
};

export default searchReducer;
