import classes from "./NewNote.module.scss"
import moment from "moment"
import { useMemo } from "react"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import { useCustomer } from "../../../../contexts/CustomerContext"
import { selectTransactionRefs } from "../../actions/functionActions"
import { useInvevoSession } from "invevo-react-components"
import { useDispatch } from "react-redux"
import Note, { convertSqlNoteToNote } from "../../../../routes/entitySearch/components/notes/types/Note"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"
import { useEvent } from "../../../../contexts/EventContext"

type Email = {
    emailTrackingId: string
    noteId: string
    emailDate: string
    customerId: string
}

type ViewEmail = Email & {
    initialViewType: string
    canReply?: boolean
}

type EmailReply = Email & {
    type: string
}

type NewNoteProps = {
    note: any
    isParentNote: boolean
    onReplyToEmail: (email: EmailReply) => void
    onViewEmail: (email: ViewEmail) => void
}

const NewNote = ({ note: originalNote, isParentNote, onReplyToEmail, onViewEmail }: NewNoteProps) => {
    const { isEnabled: useLocalTimeForNotes } = useFeatureToggle("seeNotesInLocalTime")
    const { isEnabled: isNewEntityStructure } = useFeatureToggle("newEntityStructure")

    const { triggerEvent } = useEvent()
    const [, setCustomer] = useCustomer()
    const [, setInvevoSession] = useInvevoSession()
    const dispatch = useDispatch()

    const note: Note = originalNote.noteType ? originalNote : convertSqlNoteToNote(originalNote)

    const transactionRefs = useMemo(() => {
        if (note.noteType !== "transaction" && note.noteType !== "payment") {
            return []
        }

        const refs: string[] = note.transactionRef.split(",")
        return refs.map(ref => ref.trim()).filter(ref => ref !== "")
    }, [note])

    const getNoteBackgroundClass = () => {
        switch (note.noteType) {
            case "payment":
                return "bg-purple"
            case "communication":
                return "bg-blue"
            case "customer":
                return "bg-yellow"
            case "virtual account":
                return "bg-yellow"
            case "task":
                return "bg-pink"
            default:
                return "bg-green"
        }
    }

    const formattedDate = moment.utc(note.noteEpochTime).format("YYYY-MM-DD HH:mm:ss.SSS")

    const onViewEmailClick = () => {
        if (note.noteType !== "communication") return
        const email = {
            emailTrackingId: note.emailTrackingId,
            noteId: note.noteId,
            emailDate: formattedDate,
            customerId: note.customerId,
            initialViewType: "view",
            canReply: note.inbound
        }
        onViewEmail(email)
    }

    const onReplyEmailClick = () => {
        if (note.noteType !== "communication") return
        const emailToReply = {
            emailTrackingId: note.emailTrackingId,
            noteId: note.noteId,
            emailDate: formattedDate,
            customerId: note.customerId,
            type: "reply"
        }
        onReplyToEmail(emailToReply)
    }

    const onReplyAllEmailClick = () => {
        if (note.noteType !== "communication") return
        const emailToReply = {
            emailTrackingId: note.emailTrackingId,
            noteId: note.noteId,
            emailDate: formattedDate,
            customerId: note.customerId,
            type: "reply-all"
        }
        onReplyToEmail(emailToReply)
    }

    const onViewTransactionsClick = () => {
        setInvevoSession({ selectedCustomerTab: "transactions" })
        dispatch(selectTransactionRefs(transactionRefs))
        setCustomer({
            transactionFilters: transactionRefs.map(ref => {
                return { type: "UNIQUE_TRANSACTION_REFERENCE", label: ref.toLowerCase() }
            })
        })

        if (isNewEntityStructure) {
            triggerEvent("select_transactions", { selection: transactionRefs, filterToSelected: true })
        }
    }

    return (
        <div className={`d-flex w-100 flex-column border border-white shadow border-4 text-grey ${classes[getNoteBackgroundClass()]}`}>
            <div className="d-flex p-2 fw-bold border-bottom border-white border-1">
                <span className="me-3 text-nowrap">
                    {useLocalTimeForNotes ? moment(note.noteEpochTime).format("DD MMM yyyy HH:mm") : moment.utc(note.noteEpochTime).format("DD MMM yyyy HH:mm")}
                    {!useLocalTimeForNotes && " UTC"}
                </span>
                <span className="flex-grow-1 text-uppercase">
                    {(note.noteType === "field update" ? `${note.entityDisplayName} ` : "") + note.noteType}
                    {note.noteType === "task" ? ` - ${note.taskType}` : ""}
                </span>
                <span className="ms-3 text-uppercase text-truncate">{note.userName}</span>
                {note.noteType === "communication" && note.inbound && note.readByUserId == null && <i className="fs-3 fa-regular fa-envelope-dot text-red" />}
            </div>
            <div className={`d-flex flex-column py-2 ${note.noteType === "customer" || note.noteType === "virtual account" ? classes["bg-yellow-note"] : ""}`}>
                <div className="d-flex flex-column px-2">
                    <span>{note.note}</span>
                    {note.noteType === "task" && note.taskCompletion && note.taskCompletion !== "" && (
                        <div className="d-flex align-items-center rounded bg-white shadow align-self-start px-2 mt-2">
                            <i className="far fa-check-circle text-green me-2"></i>
                            <span>{note.taskCompletion}</span>
                        </div>
                    )}
                </div>
                {!isParentNote && note.noteType === "field update" && (
                    <div className="d-flex mt-2 border-top border-white border-1 px-2">
                        <span>
                            {note.entityDisplayName} Ref: {note.noteId}
                        </span>
                    </div>
                )}
                {note.noteType === "communication" && (
                    <div className="d-flex mt-2 border-top border-white border-1 p-2">
                        <div className="d-flex ms-auto">
                            {note.emailTrackingId && (
                                <StandardButton label="View email" iconClasses="fa-regular fa-envelope-open-text" onClick={onViewEmailClick} />
                            )}
                            {note.inbound && (
                                <>
                                    <StandardButton className="ms-2" label="Reply" iconClasses="fa-regular fa-reply" onClick={onReplyEmailClick} />
                                    <StandardButton className="ms-2" label="Reply all" iconClasses="fa-regular fa-reply-all" onClick={onReplyAllEmailClick} />
                                </>
                            )}
                        </div>
                    </div>
                )}
                {transactionRefs.length > 0 && (
                    <div className="d-flex mt-2 border-top border-white border-1 p-2">
                        <div className="d-flex w-50 fst-italic">
                            <span aria-label="note-transaction-refs">Transaction Reference: {transactionRefs.join(", ")}</span>
                        </div>

                        <div className="d-flex ms-auto">
                            <StandardButton
                                label={transactionRefs.length === 1 ? "View transaction" : `View transactions (${transactionRefs.length})`}
                                iconClasses="fa-regular fa-credit-card"
                                onClick={onViewTransactionsClick}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default NewNote
