import { useRef, useEffect, useState } from "react"
import classes from "./Dropdown.module.scss"
import * as bootstrap from "bootstrap"
import DropdownOption from "./DropdownOption"
import { generateHtmlId } from "../../../../library/helpers"

const Dropdown = ({ option, options, onOptionChange, placeholder = "Please select" }) => {
    const [dropdownId] = useState(generateHtmlId())
    const [isExpanded, setIsExpanded] = useState(false)
    const [isCollapsing, setIsCollapsing] = useState(false)
    const ref = useRef()

    useEffect(() => {
        document.addEventListener("click", handleClickOutsideComponent)
        const collapsable = document.getElementById(dropdownId)
        collapsable.addEventListener("hidden.bs.collapse", onHidden)
        collapsable.addEventListener("shown.bs.collapse", onShown)
        collapsable.addEventListener("show.bs.collapse", onCollapsing)
        collapsable.addEventListener("hide.bs.collapse", onCollapsing)

        return () => {
            document.removeEventListener("click", handleClickOutsideComponent)
            collapsable.removeEventListener("hidden.bs.collapse", onHidden)
            collapsable.removeEventListener("shown.bs.collapse", onShown)
            collapsable.addEventListener("show.bs.collapse", onCollapsing)
            collapsable.addEventListener("hide.bs.collapse", onCollapsing)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClickOutsideComponent = event => {
        if (ref && ref.current) {
            if (!ref.current.contains(event.target)) {
                collapse()
            }
        }
    }

    const onShown = () => {
        setIsExpanded(true)
        setIsCollapsing(false)
    }

    const onHidden = () => {
        setIsExpanded(false)
        setIsCollapsing(false)
    }

    const onCollapsing = () => setIsCollapsing(true)

    const collapse = () => {
        const dropdown = document.getElementById(dropdownId)
        const collapsable = new bootstrap.Collapse(dropdown, {
            toggle: false
        })
        collapsable.hide()
    }

    const getHeaderText = () => {
        if (option) {
            return option.label
        }

        return placeholder
    }

    const onOptionSelected = selectedOption => {
        onOptionChange(selectedOption)
        collapse()
    }

    return (
        <div ref={ref} className="d-flex flex-grow-1 position-relative flex-column">
            <div
                className={`d-flex align-items-center pointer p-2 ${classes.header} ${
                    isExpanded || isCollapsing ? `${classes.expanded} bg-white text-grey` : "text-white"
                }`}
                data-bs-toggle="collapse"
                data-bs-target={`#${dropdownId}`}
                aria-expanded={isExpanded}
            >
                <span className="no-select">{getHeaderText()}</span>
                <div className={`${classes.icon} d-flex ms-auto`}>
                    <i className={`ms-auto text-blue fas ${isExpanded ? "fa-chevron-down" : "fa-chevron-right"}`}></i>
                </div>
            </div>
            <div className="d-flex w-100">
                {options.length > 0 && (
                    <div
                        id={dropdownId}
                        className={`options position-absolute w-100 border-top ${classes.options} bg-white collapse hide`}
                        data-bs-parent={`#${dropdownId}`}
                    >
                        {options
                            .map(o => <DropdownOption key={o.value} option={o} selectedOption={option} onOptionSelected={onOptionSelected} />)
                            .reduce((prev, curr) => [prev, <div key={generateHtmlId()} className="w-100 border-top"></div>, curr])}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Dropdown
