import * as api from '../api/tedApi';

export const getCustomerTED = (client, customerId) => (dispatch) =>
  api
    .getCustomerTED(client, dispatch, customerId)
    .then((data) => {
      dispatch({
        type: 'RECEIVE_CUSTOMER_TED',
        data
      });
    })
    .catch(function (err) {
      dispatch({
        type: 'TED_ERROR',
        tedError: err.statusText
      });
    });

export const refreshCustomerTED = (client, customerId) => (dispatch) =>
  api
    .refreshCustomerTED(client, dispatch, customerId)
    .then((data) => {
      dispatch({
        type: 'TED_SUCCESS',
        tedSuccess: 'Refresh succeeded'
      });
      dispatch({
        type: 'RECEIVE_CUSTOMER_TED',
        data
      });
    })
    .catch(function () {
      dispatch({
        type: 'TED_ERROR',
        tedError: 'Unable to refresh TED'
      });
    });

export const clearTedError = () => {
  return {
    type: 'TED_ERROR',
    tedError: ''
  };
};

export const clearTedSuccess = () => {
  return {
    type: 'TED_SUCCESS',
    tedSuccess: ''
  };
};
