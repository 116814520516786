import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import Nav from '../../api/navApi';
import { useInternationalization } from '@progress/kendo-react-intl';
import {
  executeAuthAsyncGet
} from '../../utility/asyncSupport';

const DocumentCard = ({ dataItem, onClick, onDownloadClick, dateFormat }) => {
  const panelClass = dataItem.selected ? 'result-selected' : 'result-default';

  const internationalization = useInternationalization();

  const rowRef = useRef(null);
  const handleClick = (e) => onClick({ ...e, dataItem });
  const handleDownloadClick = (e) => onDownloadClick({ ...e, dataItem });
  const handleMouseHover = () => {
    setIsHovering(!isHovering);
  };

  const [isHovering, setIsHovering] = useState(false);

  const renderDocType = () => {
    const fileType = 'file';
    return (
      <i 
        className={`fa-solid fa-${isHovering ? 'file-download' : fileType}`}
        onClick={handleDownloadClick}
        onMouseEnter={handleMouseHover}
        onMouseLeave={handleMouseHover}
      >
      </i>
    );
  };

  return (
    <td className={panelClass} ref={rowRef} onClick={handleClick}>
      {
        <div className="container-fluid doc-card">
          <div className="row equal">
            <div className="col-2 doc-type col-no-gutters">
              {renderDocType()}
            </div>
            <div className="col-10 col-no-gutters">
              <h3>{dataItem.DocumentName} </h3>
              <h5>{dataItem.FileName}</h5>
              {dataItem.DateUploaded && (
                <h5>{
                  internationalization.formatDate(
                    new Date(dataItem.DateUploaded),
                    dateFormat
                  )}
                </h5>
              )}
            </div>
          </div>
        </div>
      }
    </td>
  );
};

const mapStateToProps = (state) => ({
  dateFormat: state.lookupReducer.globalSettings.results.dateFormat,
});

export default connect(mapStateToProps, {
  executeAuthAsyncGet,
  Email: () => (
    dispatch
  ) => {
    dispatch(Nav.Workspace.Email.Index())
  }
})(DocumentCard);

