import { useClient, useConfig } from "invevo-react-components"
import { useState } from "react"
import useApiQuery from "../../../../hooks/useApiQuery"
import BlueButton from "../../../../library/buttons/BlueButton/BlueButton"
import DataGrid from "../../../../library/dataGrid/DataGrid"
import GridRowActionButton from "../../../../library/dataGrid/GridRowActionButton"
import DataGridState from "../../../../library/dataGrid/types/DataGridState"
import DataRow from "../../../../library/dataGrid/types/DataRow"
import DataField from "../../../../types/DataField"
import SortDirection from "../../../../types/enums/SortDirection"
import Role from "../../roles/types/Role"
import { ClientUserDataFields, DefaultClientUsersGridConfig, DefaultSortByDataField, mapToDataRow } from "../helpers"
import { ClientUserDto, ClientUserResultsDto } from "../types/ClientUserResultsDto"
import classes from "./ClientUserGrid.module.scss"
import { ClientUsersAction, ClientUsersActionEnum } from "../reducers/ClientUserReducer"
import { useOverlay } from "../../../../contexts/overlay/OverlayContext"

type ClientUserGridProps = {
    clientUserDataRows: DataRow[]
    roles: Role[]
    quickSearchFilter: string
    onAddNewClientUser: () => void
    onEditClientUser: (clientUser: ClientUserDto) => void
    dispatch: React.Dispatch<ClientUsersAction>
}

const ClientUserGrid = ({ clientUserDataRows, roles, quickSearchFilter, onAddNewClientUser, onEditClientUser, dispatch }: ClientUserGridProps) => {
    const client = useClient()
    const config = useConfig()

    const [totalClientUsers, setTotalClientUsers] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(20)
    const [pageIndex, setPageIndex] = useState<number>(0)
    const [sortByField, setSortByField] = useState<DataField>(DefaultSortByDataField)
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC)
    const [lastClientUserForPage, setLastClientUserForPage] = useState<{ [key: number]: DataRow | undefined }>({})

    const getClientUsersRequest = useApiQuery<ClientUserResultsDto>({
        url: `${config.USER_ROLES_API_URL}/api/${client}/client-users`,
        method: "POST",
        dto: {
            pageSize: pageSize,
            searchAfterId: lastClientUserForPage[pageIndex - 1]?.id ?? null,
            sortBy: {
                fieldName: sortByField.value,
                dataPrimitive: sortByField.type,
                direction: sortDirection,
                searchAfter: lastClientUserForPage[pageIndex - 1]?.data[sortByField.value] ?? null
            },
            quickSearchValue: quickSearchFilter
        },
        onSuccess: clientUserResults => {
            const dataRows: DataRow[] = clientUserResults.pagedClientUsers.map(clientUser => mapToDataRow(clientUser, roles))

            dispatch({ type: ClientUsersActionEnum.CLIENT_USERS_FETCHED, clientUserDataRows: dataRows })
            setTotalClientUsers(clientUserResults.totalCount)
            setLastClientUserForPage({ ...lastClientUserForPage, [pageIndex]: dataRows.at(-1) })
        }
    })

    const deleteClientUserRequest = useApiQuery({
        url: `${config.USER_ROLES_API_URL}/api/${client}/client-user`,
        method: "DELETE",
        isExecutedAutomatically: false
    })

    const gridState: DataGridState = {
        dataRows: clientUserDataRows,
        totalRowCount: totalClientUsers,
        isLoading: getClientUsersRequest.isFetching,
        pageIndex: pageIndex,
        pageSize: pageSize,
        sortField: sortByField,
        sortDirection: sortDirection
    }

    const onStateChanged = (state: DataGridState) => {
        if (state.pageIndex !== pageIndex) {
            setPageIndex(state.pageIndex)
        }

        if (state.sortField !== sortByField) {
            setPageIndex(0)
            setLastClientUserForPage({})
            setSortByField(state.sortField as DataField)
        }

        if (state.sortDirection !== sortDirection) {
            setPageIndex(0)
            setLastClientUserForPage({})
            setSortDirection(state.sortDirection as SortDirection)
        }

        if (state.pageSize !== pageSize) {
            setPageSize(state.pageSize)
        }
    }

    const editClientUser = (clientUserDataRow: DataRow) => {
        const clientUser = getClientUsersRequest.data?.pagedClientUsers?.find(c => c.username === clientUserDataRow.data.username)

        if (clientUser === undefined) return

        onEditClientUser(clientUser)
    }

    const confirmDeleteSwalHtml = (clientUser: DataRow) => (
        <div className="fs-4 text-grey">
            Are you sure you want to delete
            <span className="fw-bold"> {clientUser.id}</span>?
        </div>
    )

    const overlay = useOverlay()
    const deleteClientUser = (clientUser: DataRow) =>
        overlay.showConfirmDeletionOverlay("User", confirmDeleteSwalHtml(clientUser), () =>
            deleteClientUserRequest.execute(clientUser.id).then(() => {
                dispatch({ type: ClientUsersActionEnum.CLIENT_USER_DELETED, clientUserDataRow: clientUser })
            })
        )

    const renderGridHeader = () => (
        <div className={`w-100 rounded-top d-flex align-items-start p-1 bg-blue ${classes.gridHeader}`}>
            <div className="d-flex align-items-center p-2 w-100">
                <span className="text-uppercase text-white me-2 no-select">Users</span>
                <div className={`d-flex align-items-center py-1 px-2 text-white ms-auto`}>
                    <BlueButton iconClasses="fa fa-plus" label="Add User" onClick={onAddNewClientUser} ariaLabel="show-add-user" />
                </div>
            </div>
        </div>
    )

    const renderRowActions = (row: DataRow) => {
        return (
            <div className="d-flex">
                <GridRowActionButton
                    dataRow={row}
                    onClick={editClientUser}
                    ariaLabel={`edit-user-${row.id}`}
                    iconClasses={"ms-auto text-blue fa-solid fa-user-pen"}
                />
                <div className="me-2"></div>

                <GridRowActionButton
                    dataRow={row}
                    onClick={deleteClientUser}
                    ariaLabel={`delete-user-${row.id}`}
                    iconClasses={"ms-auto text-blue far fa-trash-can pointer"}
                />
            </div>
        )
    }

    return (
        <div className="mt-1 h-100 overflow-hidden">
            <DataGrid
                gridReference="client-users-grid"
                ariaLabel="client-users"
                fields={ClientUserDataFields}
                lookups={[]}
                defaultFields={DefaultClientUsersGridConfig}
                state={gridState}
                onStateChanged={onStateChanged}
                renderHeader={renderGridHeader}
                renderRowActions={renderRowActions}
            />
        </div>
    )
}

export default ClientUserGrid
