import Filter from "../../../../library/FilterList/Filter"
import { convertFromArrayToDto, convertFromDtoToArray } from "../../../../library/FilterList/FiltersDto"
import BlueButton from "../../../../library/buttons/BlueButton/BlueButton"
import EntityConfig from "../../../entityConfig/types/EntityConfig"
import DataField from "../../../../types/DataField"
import FieldType from "../../../../types/enums/FieldType"
import Lookup from "../../../../types/Lookup"
import { EntityFilter } from "../types/UserRole"
import GenericFilter from "../../../../library/FilterList/filterTypes/GenericFilter"
import { useState } from "react"
import FilterTypesByField from "../../../../library/FilterList/FilterTypesByField"
import { v4 as uuidv4 } from "uuid"
import { toDataPrimitive } from "../../../../library/helpers/entityHelpers"

type EntityFilterConfigProps = {
    entityFilter: EntityFilter
    entityConfigs: EntityConfig[]
    lookups: Lookup[]
    onEntityFilterUpdated: (entityFilter: EntityFilter) => void
    onEntityFilterRemoved: (entityTypeReference: string) => void
}

const EntityFilterConfig = ({ entityFilter, entityConfigs, lookups, onEntityFilterUpdated, onEntityFilterRemoved }: EntityFilterConfigProps) => {
    const entityConfig = entityConfigs.find(entityConfig => entityConfig.reference === entityFilter.entityTypeReference)
    const entityConfigDataFields: DataField[] =
        entityConfig?.fields.map(field => ({
            value: field.fieldName,
            label: field.displayName,
            type: toDataPrimitive(field.dataType.type),
            fieldType: FieldType.ENTITY,
            format: field.dataType.format,
            lookup: field.dataType.lookupReference,
            isQuickFilter: field.isQuickFilter
        })) ?? []

    const [filters, setFilters] = useState<GenericFilter[]>(
        entityFilter.filters ? convertFromDtoToArray(entityFilter.filters, entityConfigDataFields, lookups) : []
    )

    const getEntityDisplayName = () => entityConfigs.find(entityConfig => entityConfig.reference === entityFilter.entityTypeReference)?.displayName

    const addNewFilter = () => setFilters(filters => [...filters, { type: FilterTypesByField.NO_TYPE, id: uuidv4(), fieldName: "" }])

    const onFilterUpdated = (filter: GenericFilter) => updateFilters(filters.map(f => (f.id === filter.id ? filter : f)))

    const onFilterDeleted = (id: string) => updateFilters(filters.filter(filter => filter.id !== id))

    const updateFilters = (updatedFilters: GenericFilter[]) => {
        setFilters(updatedFilters)
        onEntityFilterUpdated({
            ...entityFilter,
            filters: convertFromArrayToDto(updatedFilters)
        })
    }

    const deleteEntityFilter = () => onEntityFilterRemoved(entityFilter.entityTypeReference)

    return (
        <div className="d-flex flex-column p-2 rounded bg-grey mb-2">
            <div className="d-flex mb-2">
                <span className="text-uppercase mb-1 ms-1 text-grey no-select">{getEntityDisplayName()} filters</span>
                <i className="far fa-trash-can text-grey ms-auto my-auto me-2 pointer" onClick={deleteEntityFilter}></i>
            </div>
            {filters.length === 0 && (
                <div className="d-flex my-2">
                    <span className="mx-auto text-grey">No filters added for the {getEntityDisplayName()} entity</span>
                </div>
            )}
            {filters.map(filter => (
                <div className="bg-white rounded mb-1" key={filter.id}>
                    <Filter
                        key={filter.id}
                        filter={filter}
                        fields={entityConfigDataFields}
                        lookups={lookups}
                        disabled={false}
                        onFilterUpdated={onFilterUpdated}
                        onFilterDeleted={() => onFilterDeleted(filter.id)}
                        showFieldType={false}
                    />
                </div>
            ))}
            <div className="d-flex mt-2">
                <BlueButton className="text-white ms-auto" iconClasses="fa fa-plus" label="Add filter" onClick={addNewFilter} ariaLabel="add-filter" />
            </div>
        </div>
    )
}

export default EntityFilterConfig
