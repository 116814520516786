import { LogicOperatorOptions } from 'invevo-react-components'
import classes from './LogicOperatorButton.module.scss'

const LogicOperatorButton = ({ operator, onClick }) => {
    const getIconClass = () => {
        switch (operator) {
            case LogicOperatorOptions.NOT_IN:
                return "fal fa-not-equal"
            default:
                return "fal fa-equals"
        }
    }

    const onClicked = () => {
        if (!onClick) return
        onClick()
    }

    return (
        <div className={`d-flex ${onClick && "pointer"} ${classes.shadow} ${classes.operator}`} onClick={onClicked}>
            <i className={`m-auto ${getIconClass()} text-blue`}></i>
        </div>
    )
}

export default LogicOperatorButton