import NumberFieldIsOneOfFilter from "./filterTypes/NumberFieldIsOneOfFilter"
import NumberFieldMatchesOperationFilter from "./filterTypes/NumberFieldMatchesOperationFilter"
import DateFieldIsOneOfFilter from "./filterTypes/DateFieldIsOneOfFilter"
import DateFieldMatchesOperationFilter from "./filterTypes/DateFieldMatchesOperationFilter"
import FieldExistsFilter from "./filterTypes/FieldExistsFilter"
import BooleanFieldIsEqualToFilter from "./filterTypes/BooleanFieldIsEqualToFilter"
import TextFieldStartsWithFilter from "./filterTypes/TextFieldStartsWithFilter"
import TextFieldIsOneOfFilter from "./filterTypes/TextFieldIsOneOfFilter"
import LookupFieldIsOneOfFilter from "./filterTypes/LookupFieldIsOneOfFilter"
import FilterTypesByField from "./FilterTypesByField"
import GenericFilter from "./filterTypes/GenericFilter"
import { v4 as uuid } from "uuid"
import Lookup from "../../types/Lookup"
import DataField from "../../types/DataField"
import EntityData from "../../types/EntityData"
import DataPrimitive from "../../types/DataPrimitive"
import moment, { Moment } from "moment"
import DateFieldRelativeFilter, { Relativity } from "./filterTypes/DateFieldRelativeFilter"
import LookupFieldIsOneOfFieldFilter from "./filterTypes/LookupFieldIsOneOfFieldFilter"
import TextFieldIsOneOfFieldFilter from "./filterTypes/TextFieldIsOneOfFieldFilter"
import DateFieldIsOneOfFieldFilter from "./filterTypes/DateFieldIsOneOfFieldFilter"
import BooleanFieldIsOneOfFieldFilter from "./filterTypes/BooleanFieldIsOneOfFieldFilter"
import NumberFieldIsOneOfFieldFilter from "./filterTypes/NumberFieldIsOneOfFieldFilter"

type FiltersDto = {
    textFieldIsOneOf?: {
        fieldName: string
        values: string[]
        notOneOf: boolean
    }[]
    textFieldStartsWith?: {
        fieldName: string
        values: string[]
    }[]
    textFieldIsOneOfField?: {
        fieldName: string
        values: string[]
        notOneOf: boolean
    }[]
    booleanFieldIsEqualTo?: {
        fieldName: string
        equalTo: boolean
    }[]
    booleanFieldIsOneOfField?: {
        fieldName: string
        values: string[]
        notOneOf: boolean
    }[]
    numberFieldIsOneOf?: {
        fieldName: string
        values: number[]
        notOneOf: boolean
    }[]
    numberFieldMatchesOperation?: {
        fieldName: string
        values: {
            greaterThan?: number
            lessThan?: number
            greaterThanOrEqualTo?: number
            lessThanOrEqualTo?: number
        }[]
    }[]
    numberFieldIsOneOfField?: {
        fieldName: string
        values: string[]
        notOneOf: boolean
    }[]
    dateFieldIsOneOf?: {
        fieldName: string
        values: string[]
        notOneOf: boolean
    }[]
    dateFieldMatchesOperation?: {
        fieldName: string
        values: {
            greaterThan?: string
            lessThan?: string
            greaterThanOrEqualTo?: string
            lessThanOrEqualTo?: string
        }[]
    }[]
    dateFieldIsOneOfField?: {
        fieldName: string
        values: string[]
        notOneOf: boolean
    }[]
    fieldExists?: {
        fieldName: string
        dataPrimitive: string
        notExists: boolean
    }[]
    dateFieldIsRelativeTo?: {
        fieldName: string
        numberOfDays: number
        referenceFieldName?: string
        relativeToDate?: string
        isRelativeToToday: boolean
        isBefore: boolean
        relativity: Relativity
    }[]
}

export default FiltersDto

export const convertFromDtoToArray = (filters: FiltersDto | undefined, dataFields: DataField[], lookups: Lookup[]): GenericFilter[] => {
    const lookupRefs = lookups.map(lookup => lookup.reference)
    const lookupFields = dataFields.filter(field => lookupRefs.includes(field.lookup ?? "")).map(field => field.value)

    const lookupFieldIsOneOfFilters = filters?.textFieldIsOneOf
        ?.filter(f => lookupFields.includes(f.fieldName))
        ?.map(filter => ({
            id: uuid(),
            type: FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF,
            ...filter
        }))
    const lookupFieldIsOneOfFieldFilters = filters?.textFieldIsOneOfField
        ?.filter(f => lookupFields.includes(f.fieldName))
        ?.map(filter => ({
            id: uuid(),
            type: FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF_FIELD,
            ...filter
        }))
    const textFieldIsOneOfFilters = filters?.textFieldIsOneOf
        ?.filter(f => !lookupFields.includes(f.fieldName))
        ?.map(filter => ({
            id: uuid(),
            type: FilterTypesByField.TEXT_FIELD_IS_ONE_OF,
            ...filter
        }))
    const textFieldStartsWithFilters = filters?.textFieldStartsWith?.map(filter => ({
        id: uuid(),
        type: FilterTypesByField.TEXT_FIELD_STARTS_WITH,
        ...filter
    }))
    const textFieldIsOneOfFieldFilters = filters?.textFieldIsOneOfField
        ?.filter(f => !lookupFields.includes(f.fieldName))
        ?.map(filter => ({
            id: uuid(),
            type: FilterTypesByField.TEXT_FIELD_IS_ONE_OF_FIELD,
            ...filter
        }))
    const booleanFieldIsEqualToFilters = filters?.booleanFieldIsEqualTo?.map(filter => ({
        id: uuid(),
        type: FilterTypesByField.BOOLEAN_FIELD_IS_EQUAL_TO,
        ...filter
    }))
    const booleanFieldIsOneOfFieldFilters = filters?.booleanFieldIsOneOfField?.map(filter => ({
        id: uuid(),
        type: FilterTypesByField.BOOLEAN_FIELD_IS_ONE_OF_FIELD,
        ...filter
    }))
    const numberFieldIsOneOfFilters = filters?.numberFieldIsOneOf?.map(filter => ({
        id: uuid(),
        type: FilterTypesByField.NUMBER_FIELD_IS_ONE_OF,
        ...filter,
        values: filter.values.map(value => value.toString())
    }))
    const numberFieldMatchesOperationFilters = filters?.numberFieldMatchesOperation?.map(filter => ({
        id: uuid(),
        type: FilterTypesByField.NUMBER_FIELD_MATCHES_OPERATION,
        ...filter,
        values: filter.values.map(value => {
            const greaterThan = value.greaterThan?.toString() ?? undefined
            const greaterThanOrEqualTo = value.greaterThanOrEqualTo?.toString() ?? undefined
            const lessThan = value.lessThan?.toString() ?? undefined
            const lessThanOrEqualTo = value.lessThanOrEqualTo?.toString() ?? undefined
            return {
                greaterThan,
                greaterThanOrEqualTo,
                lessThan,
                lessThanOrEqualTo
            }
        })
    }))
    const numberFieldIsOneOfFieldFilters = filters?.numberFieldIsOneOfField?.map(filter => ({
        id: uuid(),
        type: FilterTypesByField.NUMBER_FIELD_IS_ONE_OF_FIELD,
        ...filter,
        values: filter.values.map(value => value.toString())
    }))
    const dateFieldIsOneOfFilters = filters?.dateFieldIsOneOf?.map(filter => ({
        id: uuid(),
        type: FilterTypesByField.DATE_FIELD_IS_ONE_OF,
        ...filter
    }))
    const dateFieldMatchesOperationFilters = filters?.dateFieldMatchesOperation?.map(filter => ({
        id: uuid(),
        type: FilterTypesByField.DATE_FIELD_MATCHES_OPERATION,
        ...filter
    }))
    const dateFieldIsOneOfFieldFilters = filters?.dateFieldIsOneOfField?.map(filter => ({
        id: uuid(),
        type: FilterTypesByField.DATE_FIELD_IS_ONE_OF_FIELD,
        ...filter
    }))
    const fieldExistsFilters = filters?.fieldExists?.map(filter => ({
        id: uuid(),
        type: FilterTypesByField.FIELD_EXISTS,
        ...filter
    }))
    const dateFieldRelativeFilters = filters?.dateFieldIsRelativeTo?.map(filter => ({
        id: uuid(),
        type: FilterTypesByField.DATE_FIELD_RELATIVE,
        ...filter
    }))
    return [
        ...((lookupFieldIsOneOfFilters ?? []) as LookupFieldIsOneOfFilter[]),
        ...((lookupFieldIsOneOfFieldFilters ?? []) as LookupFieldIsOneOfFieldFilter[]),
        ...((textFieldIsOneOfFilters ?? []) as TextFieldIsOneOfFilter[]),
        ...((textFieldStartsWithFilters ?? []) as TextFieldStartsWithFilter[]),
        ...((textFieldIsOneOfFieldFilters ?? []) as TextFieldIsOneOfFieldFilter[]),
        ...((booleanFieldIsEqualToFilters ?? []) as BooleanFieldIsEqualToFilter[]),
        ...((booleanFieldIsOneOfFieldFilters ?? []) as BooleanFieldIsOneOfFieldFilter[]),
        ...((numberFieldIsOneOfFilters ?? []) as NumberFieldIsOneOfFilter[]),
        ...((numberFieldMatchesOperationFilters ?? []) as NumberFieldMatchesOperationFilter[]),
        ...((numberFieldIsOneOfFieldFilters ?? []) as NumberFieldIsOneOfFieldFilter[]),
        ...((dateFieldIsOneOfFilters ?? []) as DateFieldIsOneOfFilter[]),
        ...((dateFieldMatchesOperationFilters ?? []) as DateFieldMatchesOperationFilter[]),
        ...((dateFieldIsOneOfFieldFilters ?? []) as DateFieldIsOneOfFieldFilter[]),
        ...((fieldExistsFilters ?? []) as FieldExistsFilter[]),
        ...((dateFieldRelativeFilters ?? []) as DateFieldRelativeFilter[])
    ]
}

export const convertFromArrayToDto = (filters: GenericFilter[], shouldIncludeNewFilters: boolean = false): FiltersDto => ({
    textFieldIsOneOf: filters
        .filter(f => f.type === FilterTypesByField.TEXT_FIELD_IS_ONE_OF || f.type === FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF)
        .map(f => f as TextFieldIsOneOfFilter | LookupFieldIsOneOfFilter)
        .map(f => ({
            fieldName: f.fieldName,
            values: f.values.map(value => value),
            notOneOf: f.notOneOf
        })),
    ...(shouldIncludeNewFilters && {
        textFieldIsOneOfField: filters
            .filter(f => f.type === FilterTypesByField.TEXT_FIELD_IS_ONE_OF_FIELD || f.type === FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF_FIELD)
            .map(f => f as TextFieldIsOneOfFieldFilter | LookupFieldIsOneOfFieldFilter)
            .map(f => ({
                fieldName: f.fieldName,
                values: f.values.map(value => value),
                notOneOf: f.notOneOf
            }))
    }),
    textFieldStartsWith: filters
        .filter(f => f.type === FilterTypesByField.TEXT_FIELD_STARTS_WITH)
        .map(f => f as TextFieldStartsWithFilter)
        .map(f => ({
            fieldName: f.fieldName,
            values: f.values
        })),
    booleanFieldIsEqualTo: filters
        .filter(f => f.type === FilterTypesByField.BOOLEAN_FIELD_IS_EQUAL_TO)
        .map(f => f as BooleanFieldIsEqualToFilter)
        .map(f => ({
            fieldName: f.fieldName,
            equalTo: f.equalTo
        })),
    ...(shouldIncludeNewFilters && {
        booleanFieldIsOneOfField: filters
            .filter(f => f.type === FilterTypesByField.BOOLEAN_FIELD_IS_ONE_OF_FIELD)
            .map(f => f as BooleanFieldIsOneOfFieldFilter)
            .map(f => ({
                fieldName: f.fieldName,
                values: f.values.map(value => value),
                notOneOf: f.notOneOf
            }))
    }),
    numberFieldIsOneOf: filters
        .filter(f => f.type === FilterTypesByField.NUMBER_FIELD_IS_ONE_OF)
        .map(f => f as NumberFieldIsOneOfFilter)
        .map(f => ({
            fieldName: f.fieldName,
            values: f.values.map(value => Number(value)),
            notOneOf: f.notOneOf
        })),
    ...(shouldIncludeNewFilters && {
        numberFieldIsOneOfField: filters
            .filter(f => f.type === FilterTypesByField.NUMBER_FIELD_IS_ONE_OF_FIELD)
            .map(f => f as NumberFieldIsOneOfFieldFilter)
            .map(f => ({
                fieldName: f.fieldName,
                values: f.values.map(value => value),
                notOneOf: f.notOneOf
            }))
    }),
    numberFieldMatchesOperation: filters
        .filter(f => f.type === FilterTypesByField.NUMBER_FIELD_MATCHES_OPERATION)
        .map(f => f as NumberFieldMatchesOperationFilter)
        .map(f => ({
            fieldName: f.fieldName,
            values: f.values.map(v => ({
                greaterThan: v.greaterThan ? Number(v.greaterThan) : undefined,
                lessThan: v.lessThan ? Number(v.lessThan) : undefined,
                greaterThanOrEqualTo: v.greaterThanOrEqualTo ? Number(v.greaterThanOrEqualTo) : undefined,
                lessThanOrEqualTo: v.lessThanOrEqualTo ? Number(v.lessThanOrEqualTo) : undefined
            }))
        })),
    dateFieldIsOneOf: filters
        .filter(f => f.type === FilterTypesByField.DATE_FIELD_IS_ONE_OF)
        .map(f => f as DateFieldIsOneOfFilter)
        .map(f => ({
            fieldName: f.fieldName,
            values: f.values.map(value => moment.utc(value).toISOString()),
            notOneOf: f.notOneOf
        })),
    ...(shouldIncludeNewFilters && {
        dateFieldIsOneOfField: filters
            .filter(f => f.type === FilterTypesByField.DATE_FIELD_IS_ONE_OF_FIELD)
            .map(f => f as DateFieldIsOneOfFieldFilter)
            .map(f => ({
                fieldName: f.fieldName,
                values: f.values.map(value => value),
                notOneOf: f.notOneOf
            }))
    }),
    dateFieldMatchesOperation: filters
        .filter(f => f.type === FilterTypesByField.DATE_FIELD_MATCHES_OPERATION)
        .map(f => f as DateFieldMatchesOperationFilter)
        .map(f => ({
            fieldName: f.fieldName,
            values: f.values
        })),
    fieldExists: filters
        .filter(f => f.type === FilterTypesByField.FIELD_EXISTS)
        .map(f => f as FieldExistsFilter)
        .map(f => ({
            fieldName: f.fieldName,
            dataPrimitive: f.dataPrimitive,
            notExists: f.notExists
        })),
    dateFieldIsRelativeTo: filters
        .filter(f => f.type === FilterTypesByField.DATE_FIELD_RELATIVE)
        .map(f => f as DateFieldRelativeFilter)
        .map(f => ({
            fieldName: f.fieldName,
            numberOfDays: f.numberOfDays,
            referenceFieldName: f.referenceFieldName,
            relativeToDate: f.relativeToDate,
            isRelativeToToday: f.isRelativeToToday,
            isBefore: f.isBefore,
            relativity: f.relativity
        }))
})

export const doesEntityMatchFilters = (entity: EntityData, filters: FiltersDto): boolean =>
    entityMatchesTextFieldIsOneOf(entity, filters.textFieldIsOneOf ?? []) &&
    entityMatchesTextFieldIsOneOfField(entity, filters.textFieldIsOneOfField ?? []) &&
    entityMatchesTextFieldStartsWith(entity, filters.textFieldStartsWith ?? []) &&
    entityMatchesBooleanFieldIsOneOfField(entity, filters.booleanFieldIsOneOfField ?? []) &&
    entityMatchesBooleanFieldIsEqualTo(entity, filters.booleanFieldIsEqualTo ?? []) &&
    entityMatchesNumberFieldIsOneOf(entity, filters.numberFieldIsOneOf ?? []) &&
    entityMatchesNumberFieldMatchesOperation(entity, filters.numberFieldMatchesOperation ?? []) &&
    entityMatchesNumberFieldIsOneOfField(entity, filters.numberFieldIsOneOfField ?? []) &&
    entityMatchesDateFieldIsOneOf(entity, filters.dateFieldIsOneOf ?? []) &&
    entityMatchesDateFieldIsRelativeTo(entity, filters.dateFieldIsRelativeTo ?? []) &&
    entityMatchesDateFieldMatchesOperation(entity, filters.dateFieldMatchesOperation ?? []) &&
    entityMatchesDateFieldIsOneOfField(entity, filters.dateFieldIsOneOfField ?? []) &&
    entityMatchesFieldExists(entity, filters.fieldExists ?? [])

const entityMatchesTextFieldIsOneOf = (entity: EntityData, filters: Omit<TextFieldIsOneOfFilter, "type" | "id">[]) =>
    filters.every(filter => {
        const entityField = entity.entityFields.textFields.find(field => field.name === filter.fieldName)
        if (entityField === undefined) return filter.notOneOf // if the field doesn't exist, then it cannot be "one of" the options
        const fieldIsInValues = filter.values.map(value => value.toLowerCase()).includes(entityField.value.toLowerCase())
        return filter.notOneOf ? !fieldIsInValues : fieldIsInValues
    })

const entityMatchesTextFieldStartsWith = (entity: EntityData, filters: Omit<TextFieldStartsWithFilter, "type" | "id">[]) =>
    filters.every(filter => {
        const entityField = entity.entityFields.textFields.find(field => field.name === filter.fieldName)
        if (entityField === undefined) return false
        return filter.values.some(value => entityField.value.toLowerCase().startsWith(value.toLowerCase()))
    })
const entityMatchesTextFieldIsOneOfField = (entity: EntityData, filters: Omit<TextFieldIsOneOfFieldFilter, "type" | "id">[]) =>
    true

const entityMatchesBooleanFieldIsEqualTo = (entity: EntityData, filters: Omit<BooleanFieldIsEqualToFilter, "type" | "id">[]): boolean =>
    filters.every(filter => {
        const entityField = entity.entityFields.booleanFields.find(field => field.name === filter.fieldName)
        if (entityField === undefined) return false
        return entityField.value === filter.equalTo
    })

const entityMatchesBooleanFieldIsOneOfField = (entity: EntityData, filters: Omit<BooleanFieldIsOneOfFieldFilter, "type" | "id">[]) =>
    true

const entityMatchesNumberFieldIsOneOf = (
    entity: EntityData,
    filters: {
        fieldName: string
        values: number[]
        notOneOf: boolean
    }[]
): boolean =>
    filters.every(filter => {
        const entityField = entity.entityFields.numberFields.find(field => field.name === filter.fieldName)
        if (entityField === undefined) return filter.notOneOf // if the field doesn't exist, then it cannot be "one of" the options
        const fieldIsInValues = filter.values.includes(entityField.value)
        return filter.notOneOf ? !fieldIsInValues : fieldIsInValues
    })

const entityMatchesNumberFieldMatchesOperation = (
    entity: EntityData,
    filters: {
        fieldName: string
        values: {
            greaterThan?: number
            lessThan?: number
            greaterThanOrEqualTo?: number
            lessThanOrEqualTo?: number
        }[]
    }[]
): boolean =>
    filters.every(filter => {
        const entityField = entity.entityFields.numberFields.find(field => field.name === filter.fieldName)
        if (entityField === undefined) return false
        return filter.values.some(value => {
            const greaterThan = value.greaterThan === undefined || value.greaterThan === null || entityField.value > value.greaterThan
            const lessThan = value.lessThan === undefined || value.lessThan === null || entityField.value < value.lessThan
            const greaterThanOrEqualTo =
                value.greaterThanOrEqualTo === undefined || value.greaterThanOrEqualTo === null || entityField.value >= value.greaterThanOrEqualTo
            const lessThanOrEqualTo = value.lessThanOrEqualTo === undefined || value.lessThanOrEqualTo === null || entityField.value <= value.lessThanOrEqualTo
            return greaterThan && lessThan && greaterThanOrEqualTo && lessThanOrEqualTo
        })
    })

const entityMatchesNumberFieldIsOneOfField = (entity: EntityData, filters: Omit<NumberFieldIsOneOfFieldFilter, "type" | "id">[]) =>
    true

const entityMatchesDateFieldIsOneOf = (entity: EntityData, filters: Omit<DateFieldIsOneOfFilter, "type" | "id">[]): boolean =>
    filters.every(filter => {
        const entityField = entity.entityFields.dateFields.find(field => field.name === filter.fieldName)
        if (entityField === undefined) return filter.notOneOf // if the field doesn't exist, then it cannot be "one of" the options
        const fieldIsInValues = filter.values.map(value => moment(value).valueOf()).includes(entityField.value)
        return filter.notOneOf ? !fieldIsInValues : fieldIsInValues
    })

const entityMatchesDateFieldIsRelativeTo = (entity: EntityData, filters: Omit<DateFieldRelativeFilter, "type" | "id">[]): boolean =>
    filters.every(filter => {
        const entityField = entity.entityFields.dateFields.find(field => field.name === filter.fieldName)
        if (!entityField) return false
        const relDate = getDateForRelDateFilter(filter, entity)
        if (!relDate) return false
        return isDateFieldWithinRelativeBound(moment(entityField.value), relDate, filter.isBefore, filter.numberOfDays, filter.relativity)
    })

const entityMatchesDateFieldIsOneOfField = (entity: EntityData, filters: Omit<DateFieldIsOneOfFieldFilter, "type" | "id">[]) =>
    true

const getDateForRelDateFilter = (filter: Omit<DateFieldRelativeFilter, "type" | "id">, entity: EntityData): Moment | undefined => {
    if (filter.referenceFieldName) {
        const refField = entity.entityFields.dateFields.find(field => field.name === filter.referenceFieldName)
        if (!refField) return undefined
        return moment(refField.value)
    }
    if (filter.isRelativeToToday) {
        return moment()
    }
    if (filter.relativeToDate) {
        return moment(filter.relativeToDate)
    }
}

const isDateFieldWithinRelativeBound = (val: Moment, relDate: Moment, isBefore: Boolean, numberOfDays: number, relativity: Relativity) => {
    const startOfRelDate = moment(relDate).startOf("day")
    const endOfRelDate = moment(relDate).endOf("day")
    switch (relativity) {
        case "AT_LEAST":
            return isBefore ? val <= endOfRelDate.subtract(numberOfDays, "days") : val >= startOfRelDate.add(numberOfDays, "days")
        case "AT_MOST":
            return isBefore
                ? val <= startOfRelDate.subtract(numberOfDays, "days") && val >= relDate
                : val >= endOfRelDate.add(numberOfDays, "days") && val <= relDate
        case "EXACTLY":
            return isBefore
                ? val >= startOfRelDate.subtract(numberOfDays, "days") && val <= endOfRelDate.subtract(numberOfDays, "days")
                : val >= startOfRelDate.add(numberOfDays, "days") && val <= endOfRelDate.add(numberOfDays, "days")
    }
}

const entityMatchesDateFieldMatchesOperation = (entity: EntityData, filters: Omit<DateFieldMatchesOperationFilter, "type" | "id">[]): boolean =>
    filters.every(filter => {
        const entityField = entity.entityFields.dateFields.find(field => field.name === filter.fieldName)
        if (entityField === undefined) return false
        return filter.values.some(value => {
            const greaterThan = value.greaterThan === undefined || value.greaterThan === null || entityField.value > moment(value.greaterThan).valueOf()
            const lessThan = value.lessThan === undefined || value.lessThan === null || entityField.value < moment(value.lessThan).valueOf()
            const greaterThanOrEqualTo =
                value.greaterThanOrEqualTo === undefined ||
                value.greaterThanOrEqualTo === null ||
                entityField.value >= moment(value.greaterThanOrEqualTo).valueOf()
            const lessThanOrEqualTo =
                value.lessThanOrEqualTo === undefined || value.lessThanOrEqualTo === null || entityField.value <= moment(value.lessThanOrEqualTo).valueOf()
            return greaterThan && lessThan && greaterThanOrEqualTo && lessThanOrEqualTo
        })
    })

const entityMatchesFieldExists = (
    entity: EntityData,
    filters: {
        fieldName: string
        dataPrimitive: string
        notExists: boolean
    }[]
): boolean =>
    filters.every(filter => {
        switch (filter.dataPrimitive) {
            case DataPrimitive.TEXT:
            case DataPrimitive.LOOKUP:
                const fieldExists = entity.entityFields.textFields.some(field => field.name === filter.fieldName)
                return filter.notExists ? !fieldExists : fieldExists
            case DataPrimitive.BOOLEAN:
                const booleanFieldExists = entity.entityFields.booleanFields.some(field => field.name === filter.fieldName)
                return filter.notExists ? !booleanFieldExists : booleanFieldExists
            case DataPrimitive.NUMBER:
                const numberFieldExists = entity.entityFields.numberFields.some(field => field.name === filter.fieldName)
                return filter.notExists ? !numberFieldExists : numberFieldExists
            case DataPrimitive.DATE:
                const dateFieldExists = entity.entityFields.dateFields.some(field => field.name === filter.fieldName)
                return filter.notExists ? !dateFieldExists : dateFieldExists
        }

        return true
    })
