import DataCard from "./DataCard"
import { useGrid } from "./../Grid"
import RowData from "./../types/RowData"
import { useLayoutEffect, useMemo, useRef } from "react"
import classes from "./Cards.module.scss"
import EntityConfig from "../../../routes/entityConfig/types/EntityConfig"

type Props = {
    selectableCards: boolean
    colour?: "blue" | "grey"
    entityConfig?: EntityConfig
    onCardSelected?: (data: RowData) => void
    multiSelect?: boolean
    ariaLabel?: string
}

const Cards = ({ selectableCards, colour = "grey", entityConfig, onCardSelected, multiSelect = true, ariaLabel }: Props) => {
    const grid = useGrid()

    const toggleSelectSingle = (reference: string) => {
        if (multiSelect) {
            if (grid.selection.selectedRefs.includes(reference)) {
                grid.onSelect(
                    {
                        selectedRefs: grid.selection.selectedRefs.filter(ref => ref !== reference),
                        isAllRowsSelected: false
                    },
                    false
                )
                return
            }

            grid.onSelect(
                {
                    selectedRefs: [...grid.selection.selectedRefs, reference],
                    isAllRowsSelected: false
                },
                false
            )
        } else {
            grid.onSelect({ selectedRefs: [reference], isAllRowsSelected: false }, false)
        }
    }

    const ref = useRef<HTMLDivElement>(null)

    const singleSelectedReference = useMemo(
        () => (grid.selection.selectedRefs.length === 1 ? grid.selection.selectedRefs[0] : undefined),
        [grid.selection.selectedRefs]
    )

    useLayoutEffect(() => {
        if (!ref.current) return
        ref.current?.scrollIntoView({ behavior: "smooth", block: "center" })
    }, [singleSelectedReference])

    const sortedHeaders = grid.displayedHeaders.sort((a, b) => a.ordinal - b.ordinal)

    return (
        <div className={`${classes.grid} align-content-start gap-2 p-2 w-100 overflow-auto`} role="list" aria-label={ariaLabel}>
            {grid.data.rows.map(rowData => (
                <div ref={singleSelectedReference === rowData.reference ? ref : null} key={rowData.reference}>
                    <DataCard
                        cardData={rowData}
                        optimisticData={grid.data.optimisticallyUpdatedRows.find(row => row.reference === rowData.reference)}
                        isSelectable={selectableCards}
                        headers={sortedHeaders}
                        colour={colour}
                        onCardSelected={onCardSelected}
                        toggleSelectSingle={toggleSelectSingle}
                        entityConfig={entityConfig}
                    />
                </div>
            ))}
        </div>
    )
}

export default Cards
