import { useState } from 'react';
import { createSelector } from 'reselect';
import { saveUserConfig, createUserConfig } from '../api/uiConfigApi';
import { useSelector, useDispatch } from 'react-redux';
import { UserConfigViewType } from '../constants';
import { useClient } from 'invevo-react-components';

const summaryConfigSelector = createSelector(
  (state) =>
    state.uiConfigReducer.configs[UserConfigViewType.summaryCardConfig],
  (config) => {
    return config ? config : { ID: null, Content: {} };
  }
);
const defaultItemObject = { show: true };

function useSummaryConfig() {
  const client = useClient()
  const summaryConfig = useSelector(summaryConfigSelector);
  const dispatch = useDispatch();

  const [reqSummaryConfig, setReqSummaryConfig] = useState({});

  function setSummaryConfigItem(id, value, configKey = 'show') {
    const persistedItemObject = summaryConfig.Content[id];
    const prevRequestedItemObject = reqSummaryConfig[id];

    const requestedSummaryConfig = {
      ...reqSummaryConfig,
      [id]: {
        ...persistedItemObject,
        ...prevRequestedItemObject,
        [configKey]: value
      }
    };

    setReqSummaryConfig(requestedSummaryConfig);

    if (summaryConfig.ID) {
      const configToSave = {
        ...summaryConfig.Content,
        ...requestedSummaryConfig
      };
      dispatch(
        saveUserConfig(
          client,
          summaryConfig.ID,
          UserConfigViewType.summaryCardConfig,
          configToSave
        )
      );
    } else {
      const configToCreate = { ...requestedSummaryConfig };
      dispatch(
        createUserConfig(client, UserConfigViewType.summaryCardConfig, configToCreate)
      );
    }
  }

  function summaryConfigItem(id, configKey = 'show') {
    const persistedObject = summaryConfig.Content[id]
      ? summaryConfig.Content[id]
      : undefined;
    const reqObject = reqSummaryConfig[id];

    const config = { ...defaultItemObject, ...persistedObject, ...reqObject };

    return config[configKey];
  }

  return [summaryConfigItem, setSummaryConfigItem];
}

export default useSummaryConfig;
