import { useState, useEffect } from 'react'
import {
    Input,
    Alert,
    CheckboxList,
    Collapsable,
    Loading,
    ProgressButton,
    useApi,
    useClient,
    useConfig
} from 'invevo-react-components'
import classes from './SmsTemplateDetails.module.scss'

const SmsTemplateDetails = ({
    customerGroups,
    senderIds,
    smsTemplate,
    selectedTemplate,
    setSelectedTemplate,
    dispatch
}) => {
    const api = useApi()
    const client = useClient()
    const config = useConfig()
    
    const [saveDisabled, setSaveDisabled] = useState(true)
    const [selectedCustomerGroups, setSelectedCustomerGroups] = useState([])
    const [selectedSenderIds, setSelectedSenderIds] = useState([])
    const [allGroupsSelected, setAllGroupsSelected] = useState(true)
    const [allSenderIdSelected, setAllSenderIdSelected] = useState(false)

    const isLoading = !customerGroups || customerGroups.length === 0

    useEffect(() => {
        setSelectedCustomerGroups(customerGroups.filter(g => g.isActive).map(rg => {
            return {
                id: rg.reference,
                label: rg.name,
                isSelected: smsTemplate && smsTemplate.customerGroups && smsTemplate.customerGroups.includes(rg.reference)
            }
        }))
        setSelectedSenderIds(senderIds.map(si => {
            return {
                id: si.reference,
                label: si.senderId,
                isSelected: smsTemplate && smsTemplate.smsSenderIds && smsTemplate.smsSenderIds.includes(si.reference)
            }
        }))

        if (smsTemplate && smsTemplate.smsSenderIds) {
            setAllSenderIdSelected(smsTemplate.smsSenderIds.length === senderIds.length ? true : false)
            setAllGroupsSelected(!smsTemplate.hasCustomerGroupFilterApplied)
        }
    }, [customerGroups, smsTemplate, senderIds])

    useEffect(() => {
        if (
            ((selectedTemplate.name !== smsTemplate.name) || (selectedTemplate.body !== smsTemplate.body) ||
                isReferenceListChanged(smsTemplate.customerGroups, selectedCustomerGroups.filter(rg => rg.isSelected)) ||
                isReferenceListChanged(smsTemplate.smsSenderIds, selectedSenderIds.filter(si => si.isSelected))
                || smsTemplate.hasCustomerGroupFilterApplied === allGroupsSelected)
            && (selectedTemplate.body.length > 0)
            && (selectedTemplate.name.length > 0)
        )
            setSaveDisabled(false)
        else setSaveDisabled(true)
    }, [selectedSenderIds, selectedCustomerGroups, selectedTemplate, smsTemplate, allGroupsSelected])

    const newRequestBody = (selectedSmsTemplate) => {
        return {
            "name": selectedSmsTemplate.name,
            "body": selectedSmsTemplate.body,
            "isActive": selectedSmsTemplate.isActive,
            "hasCustomerGroupFilterApplied": !allGroupsSelected,
            "customerGroups": selectedCustomerGroups.filter(rg => !allGroupsSelected && rg.isSelected).map(rg => rg.id),
            "smsSenderIds": selectedSenderIds.filter(si => allSenderIdSelected || si.isSelected).map(si => si.id),
        }
    }

    const onNameChange = (event) => {
        setSelectedTemplate({ ...selectedTemplate, name: event.target.value })
    }

    const saveSmsTemplate = (smsTemplateSelected) => {
        const body = newRequestBody(smsTemplateSelected)
        const promise = new Promise((resolve, reject) => {
            api.put(`${config.CONNECT_API_URL}/api/${client}/sms-template/${smsTemplateSelected.reference}`, body)
                .then(_ => {
                    setSaveDisabled(true)
                    dispatch({
                        type: 'SMS_TEMPLATE_UPDATED', smsTemplate: {
                            ...smsTemplateSelected,
                            customerGroups: selectedCustomerGroups.filter(rg => !allGroupsSelected && rg.isSelected).map(rg => rg.id),
                            hasCustomerGroupFilterApplied: !allGroupsSelected,
                            smsSenderIds: selectedSenderIds.filter(si => allSenderIdSelected || si.isSelected).map(si => si.id)
                        }
                    })
                    resolve()
                })
                .catch(error => {
                    console.error(error)
                    reject()
                })
        })
        return promise
    }

    const changeTemplateStatus = (isActiveNow) => {
        const promise = new Promise((resolve, reject) => {
            api.put(`${config.CONNECT_API_URL}/api/${client}/sms-template/${selectedTemplate.reference}`, newRequestBody({ ...smsTemplate, isActive: isActiveNow }, selectedTemplate.customerGroups))
                .then(_ => {
                    dispatch({ type: 'SMS_TEMPLATE_UPDATED', smsTemplate: { ...smsTemplate, isActive: isActiveNow } })
                    resolve()
                })
                .catch(error => {
                    console.error(error)
                    reject()
                })
        })
        return promise
    }

    const onCustomerGroupSelected = (checkedGroup) => {
        setSelectedCustomerGroups(selectedCustomerGroups.map(group => {
            if (group.id !== checkedGroup.id) {
                return group
            }

            return {
                ...group,
                isSelected: !group.isSelected
            }
        }))

        setSelectedTemplate({
            ...selectedTemplate,
            customerGroups: customerGroups.filter(rg => selectedCustomerGroups.some(srg => srg.isSelected && srg.id === rg.reference))
        })
    }

    const onSmsSenderIDSelected = (checkedSenderId) => {
        setSelectedSenderIds(selectedSenderIds.map(senderId => {
            if (senderId.id !== checkedSenderId.id) {
                return senderId
            }

            return {
                ...senderId,
                isSelected: !senderId.isSelected
            }
        }))

        setSelectedTemplate({
            ...selectedTemplate,
            smsSenderIds: senderIds.filter(si => selectedSenderIds.some(ssi => ssi.isSelected && ssi.id === si.reference))
        })
    }

    const isReferenceListChanged = (original, updated) => {
        if (!original) {
            return false
        }

        return original.sort().toString() !== updated.map(g => g.id).sort().toString()
    }

    return (
        <Loading isLoading={!config.CONNECT_API_URL} colour="blue">
            <div className={`d-flex flex-column ${classes.container}`}>
                <div className="d-flex flex-column p-3 h-100">
                    <h3 className="text-white no-select">SMS template details</h3>
                    <Loading isLoading={isLoading} colour="white">
                        <Input className="mb-3" placeholder="Please enter name" label="SMS Template name" value={selectedTemplate.name ? selectedTemplate.name : ""} onChange={onNameChange} />
                        {selectedCustomerGroups && selectedCustomerGroups.length > 0 && <div className="d-flex flex-column">
                            <Collapsable colour="light-blue" title="SELECT CUSTOMER GROUPS" id="customerGroup" open={true}>
                                <div className="d-flex mt-2 w-100">
                                    <CheckboxList
                                        className="w-100"
                                        items={selectedCustomerGroups}
                                        onItemChecked={onCustomerGroupSelected}
                                        allOptionEnabled={true}
                                        allChecked={allGroupsSelected}
                                        onAllChecked={() => setAllGroupsSelected(!allGroupsSelected)} />
                                </div>
                                {!allGroupsSelected && selectedCustomerGroups.filter(rg => rg.isSelected).length === 0 &&
                                    <Alert
                                        className="my-3"
                                        iconClasses="fal fa-exclamation-triangle"
                                        text="This template will not be used for any customer. Please select at least one customer group for this template to be used." />
                                }
                            </Collapsable>
                        </div>}
                        <hr className="text-white" />
                        {selectedSenderIds && selectedSenderIds.length > 0 && <div className="d-flex flex-column">
                            <Collapsable colour="light-blue" title="SELECT SMS SENDER IDS" id="smsSenderIds" open={false}>
                                <div className="d-flex mt-2 w-100">
                                    <CheckboxList
                                        className="w-100"
                                        items={selectedSenderIds}
                                        onItemChecked={onSmsSenderIDSelected}
                                        allOptionEnabled={true}
                                        allChecked={allSenderIdSelected}
                                        onAllChecked={() => setAllSenderIdSelected(!allSenderIdSelected)} />
                                </div>
                                {!allSenderIdSelected && selectedSenderIds.filter(si => si.isSelected).length === 0 &&
                                    <Alert
                                        className="my-3"
                                        iconClasses="fal fa-exclamation-triangle"
                                        text="No sender ID is assigned to this template. Please select at least one sender ID for this template to be used." />
                                }
                            </Collapsable>
                        </div>}

                        <div className="d-flex flex-column mt-auto pt-5 mb-1">
                            <ProgressButton
                                className="mb-3 align-self-center w-100 px-4"
                                iconClasses="fal fa-save"
                                label="Save SMS template"
                                colour="blue"
                                onClickWithPromise={() => {
                                    const promise = new Promise((resolve, reject) => {
                                        saveSmsTemplate(selectedTemplate)
                                            .then(_ => {
                                                resolve()
                                            },
                                                _ => reject())
                                            .catch(_ => reject())
                                    })
                                    return promise
                                }}
                                succeededText="Saved successfully!"
                                failedText="Failed to add template!"
                                disabled={saveDisabled}
                                disappearable={false}
                            />

                            <ProgressButton
                                className="mb-3 align-self-center w-100 px-4"
                                iconClasses="fal fa-archive"
                                label={selectedTemplate.isActive ? "Archive SMS template" : "Restore SMS template"}
                                colour="blue"
                                onClickWithPromise={() => {
                                    const promise = new Promise((resolve, reject) => {
                                        const result = selectedTemplate.isActive
                                            ? changeTemplateStatus(false)
                                            : changeTemplateStatus(true)
                                        result
                                            .then(_ => {
                                                resolve()
                                            },
                                                _ => reject())
                                            .catch(_ => reject())
                                    })
                                    return promise
                                }}
                                succeededText="Success!"
                                failedText="Failed!"
                                disabled={selectedTemplate.isNew}
                                disappearable={false}
                            />
                        </div>
                    </Loading>
                </div>
            </div>
        </Loading>
    )
}

export default SmsTemplateDetails