import classes from "./EntityConfigList.module.scss"
import EntityConfig from "../types/EntityConfig"
import EntityConfigCard from "./EntityConfigCard"
import IconButton from "../../../library/buttons/IconButton/IconButton"
import { useFeatureToggle } from "../../../hooks/useFeatureToggle"

type EntityConfigListProps = {
    entityConfigs: EntityConfig[]
    selectedEntityConfig: EntityConfig | undefined
    onEntityConfigSelected: (entityConfigRef: string) => void
    onNewEntityClicked: () => void
    enableDiagramView: () => void
}

const EntityConfigList = ({ entityConfigs, selectedEntityConfig, onEntityConfigSelected, onNewEntityClicked, enableDiagramView }: EntityConfigListProps) => {
    const { isEnabled: allowDiagramView } = useFeatureToggle("entityConfigDiagramView")

    return (
        <div className={`d-flex flex-column ${classes.container} p-3`} role="list" aria-label="entity-configs">
            <div className="d-flex align-items-center" aria-label="header">
                <div className="d-flex me-4">
                    <h2 className="text-grey no-select fw-light mb-0">Entities</h2>
                </div>
                {allowDiagramView && (
                    <div className="d-flex ms-auto">
                        <IconButton
                            icon="fa-light fa-chart-network"
                            theme="white"
                            dynamicShadow={true}
                            onClick={enableDiagramView}
                            ariaLabel="enable-entity-diagram-view"
                        />
                    </div>
                )}
                <div className="d-flex ms-auto">
                    <IconButton icon="fal fa-plus" theme="white" dynamicShadow={true} onClick={onNewEntityClicked} ariaLabel="add-entity-config" />
                </div>
            </div>
            <div>
                <div className="d-flex flex-column gap-2 mt-3">
                    {entityConfigs
                        .sort((a, b) => a.displayName.localeCompare(b.displayName))
                        .map(entityConfig => (
                            <EntityConfigCard
                                key={entityConfig.reference}
                                entityConfig={entityConfig}
                                isSelected={selectedEntityConfig?.reference === entityConfig.reference}
                                onEntityConfigSelected={onEntityConfigSelected}
                            />
                        ))}
                </div>
            </div>
        </div>
    )
}

export default EntityConfigList
