import { actionTypes } from "../../../reducers/actionTypes"
import classes from "./Widgets.module.scss"
import { DashboardConfigAction } from "../../../reducers/dashboardConfigsReducer"
import { TrendingChartType, TrendingPeriod, TrendingWidgetState } from "../../../types/DashboardConfigState"
import Dropdown from "../../../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../../../types/DropdownOptions"
import EntityDataFieldDropdown from "../../../../../library/dropdowns/EntityDataFieldDropdown"
import { useState } from "react"
import FilterList from "../../../../../library/FilterList/FilterList"
import Lookup from "../../../../../types/Lookup"
import EntityConfig from "../../../../../routes/entityConfig/types/EntityConfig"
import EntityDataField from "../../../../../routes/entityConfig/types/EntityDataField"
import { DataPrimitiveTypeEnum } from "../../../../../routes/entityConfig/types/DataType"
import DataField from "../../../../../types/DataField"
import DataPrimitive from "../../../../../types/DataPrimitive"
import FieldType from "../../../../../types/enums/FieldType"
import GenericFilter from "../../../../../library/FilterList/filterTypes/GenericFilter"
import Toggle from "../../../../../library/toggle/Toggle"

type TrendingWidgetProps = {
    className?: string
    state: {
        widget: TrendingWidgetState
        entityConfig?: EntityConfig
        entityConfigs: EntityConfig[]
        lookups: Lookup[]
    }
    dispatch: React.Dispatch<DashboardConfigAction>
}

const TrendingWidget = ({ className = "", state, dispatch }: TrendingWidgetProps) => {
    const { widget, entityConfig: defaultEntityConfig, entityConfigs, lookups } = state

    const timeOptions: DropdownOption<TrendingPeriod>[] = [
        { label: "Today", value: "TODAY" },
        { label: "7 days", value: "SEVEN_DAYS" },
        { label: "30 days", value: "THIRTY_DAYS" }
    ]
    const onStartTimeSelected = (opt: DropdownOption<TrendingPeriod>) => onPeriodChange(opt.value)

    const entityConfig = entityConfigs.find(e => e.reference === widget.entityConfigReference) ?? defaultEntityConfig
    const groupingFieldOptions = (entityConfig?.fields ?? []).filter(f => f.dataPrimitiveType === DataPrimitiveTypeEnum.TEXT)

    const [isShowingFilters, setIsShowingFilters] = useState(false)
    const toggleShowFilters = () => setIsShowingFilters(f => !f)

    const onRemoveClick = () => dispatch({ type: actionTypes.WIDGET_REMOVED, widget })

    const entityFieldsAsDataFields = convertEntityDataFieldsToDataFields(entityConfig?.fields ?? [])

    const entityConfigDropdownOptions = entityConfigs.map(config => {
        return { value: config.reference, label: config.displayName }
    })

    const defaultLineChart = { label: "Line Chart", value: "LINE" } as const
    const chartTypeOptions: DropdownOption<TrendingChartType>[] = [
        ...(widget.period !== "TODAY" ? [defaultLineChart] : []),
        { label: "Bar Chart", value: "BAR" }
    ]

    const onEntityConfigChange = (opt: DropdownOption<string>) =>
        dispatch({ type: actionTypes.TRENDING_WIDGET_ENTITY_CONFIG_CHANGED, widget, entityConfigReference: opt.value })
    const onTypeChange = (typeOption: DropdownOption<TrendingChartType>) =>
        dispatch({ type: actionTypes.TRENDING_WIDGET_CHART_TYPE_CHANGED, widget, chartType: typeOption.value })
    const onPeriodChange = (period: TrendingPeriod) => {
        dispatch({ type: actionTypes.TRENDING_WIDGET_PERIOD_CHANGED, widget, period })
        if (widget.chartType === "LINE" && period === "TODAY") onTypeChange({ label: "", value: "BAR" })
    }
    const onGroupingChange = (field?: EntityDataField) =>
        field && dispatch({ type: actionTypes.TRENDING_WIDGET_GROUPING_FIELD_CHANGED, widget, groupingField: field })
    const onComparisonChange = (field?: EntityDataField) => {
        field && dispatch({ type: actionTypes.TRENDING_WIDGET_COMPARISON_FIELD_CHANGED, widget, comparisonField: field })
        field?.dataPrimitiveType !== DataPrimitiveTypeEnum.NUMBER && widget.showValues && onShowValuesChange(false)
    }
    const onShowValuesChange = (value: boolean) => dispatch({ type: actionTypes.TRENDING_WIDGET_SHOW_VALUES_CHANGED, widget, value })
    const onFiltersChange = (filters: GenericFilter[]) => dispatch({ type: actionTypes.TRENDING_WIDGET_FILTERS_CHANGED, widget, filters })

    return (
        <div className={`${className} d-flex flex-column ${classes.widget} bg-white p-2 text-grey overflow-auto`}>
            <div className="d-flex align-items-center border-bottom pb-2">
                <i className="fal fa-chart-pie-alt me-2"></i>
                <i className="fal fa-times ms-3 me-1 pointer" onClick={onRemoveClick}></i>
            </div>
            <div className="d-flex flex-column mt-2 px-2">
                <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-row gap-3 align-items-center">
                        <span className="w-25">Entity Type:</span>
                        <Dropdown
                            options={entityConfigDropdownOptions}
                            onOptionSelected={onEntityConfigChange}
                            selectedOption={entityConfigDropdownOptions.find(o => o.value === widget.entityConfigReference)}
                            className="w-100"
                            ariaLabel="trending-entity-type-dropdown"
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-2 gap-3">
                        <span className="w-25">Display:</span>
                        <Dropdown
                            className="w-100"
                            options={chartTypeOptions}
                            onOptionSelected={onTypeChange}
                            selectedOption={chartTypeOptions.find(o => o.value === widget.chartType)}
                            ariaLabel="trending-chart-type-dropdown"
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-2 gap-3">
                        <span className="w-25">Period:</span>
                        <Dropdown
                            options={timeOptions}
                            onOptionSelected={onStartTimeSelected}
                            selectedOption={timeOptions.find(o => o.value === widget.period)}
                            className="w-100"
                            ariaLabel="trending-period-dropdown"
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-2 gap-3">
                        <span className="w-25">Grouping Field:</span>
                        <EntityDataFieldDropdown
                            className="w-100"
                            options={groupingFieldOptions}
                            onOptionSelected={onGroupingChange}
                            selectedOption={widget.groupingField}
                            ariaLabel="trending-grouping-field-dropdown"
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-2 gap-3">
                        <span className="w-25">Comparison Field:</span>
                        <EntityDataFieldDropdown
                            className="w-100"
                            options={entityConfig?.fields ?? []}
                            onOptionSelected={onComparisonChange}
                            selectedOption={widget.comparisonField}
                            ariaLabel="trending-comparison-field-dropdown"
                        />
                        {widget.comparisonField?.dataPrimitiveType === DataPrimitiveTypeEnum.NUMBER && (
                            <>
                                <span>Show Values</span>
                                <Toggle status={widget.showValues} onStatusChanged={onShowValuesChange} ariaLabel="trending-show-values-toggle" />
                            </>
                        )}
                    </div>
                    <div
                        className={`d-flex p-2 rounded pointer align-items-center justify-content-center text-blue ${classes["filter-button"]} ${
                            isShowingFilters ? classes["selected-filter-button"] : ""
                        } gap-2`}
                        role="button"
                        aria-label="show-grid-filters"
                        onClick={toggleShowFilters}
                    >
                        <i className="fal fa-filter"></i>
                        Additional Filters
                        <div className={`px-2 bg-blue text-white ${classes["filter-count-label"]}`}>{widget.filters.length}</div>
                    </div>
                    {isShowingFilters && (
                        <FilterList
                            colour="grey"
                            fields={entityFieldsAsDataFields}
                            lookups={lookups}
                            appliedFilters={widget.filters}
                            onFiltersApplied={onFiltersChange}
                            autoApply={true}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default TrendingWidget

const convertEntityDataFieldsToDataFields = (fields: EntityDataField[]): DataField[] =>
    fields.map(f => ({
        value: f.fieldName,
        label: f.displayName,
        type: DataPrimitive[f.dataPrimitiveType],
        isQuickFilter: f.isQuickFilter,
        fieldType: FieldType.ENTITY
    }))
