import { useState } from "react"
import Input from "../../../library/Inputs/Input"
import EntityConfig from "../types/EntityConfig"
import { toSnakeCase } from "../../../library/helpers/stringUtils"
import useApiQuery from "../../../hooks/useApiQuery"
import { useEnvConfig } from "../../../contexts/EnvironmentConfigContext"
import useClient from "../../../hooks/useClient"
import Dropdown from "../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../types/DropdownOptions"
import { useUser } from "../../../contexts/UserContext"
import StandardButton from "../../../library/buttons/StandardButton/StandardButton"
import ProgressButton from "../../../library/buttons/ProgressButton/ProgressButton"
import EntityRelationshipDto from "../types/EntityRelationshipDto"
import EntityRelationshipType from "../types/EntityRelationship"
import { convertEntityRelationshipsFromDto } from "../util"
import { useOverlay } from "../../../contexts/overlay/OverlayContext"
import Spinner from "../../../library/Loading/Spinner"

type Props = {
    entityConfig: EntityConfig
    existingRelationships: EntityRelationshipDto[]
    existingEntityConfigRefs: string[]
    onEntityConfigDuplicated: (entityConfig: EntityConfig) => void
    onEntityRelationshipsUpdated: React.Dispatch<React.SetStateAction<EntityRelationshipType[]>>
    onClose: () => void
}

const DuplicateEntityConfigForm = ({
    entityConfig,
    existingRelationships,
    existingEntityConfigRefs,
    onEntityConfigDuplicated,
    onEntityRelationshipsUpdated,
    onClose
}: Props) => {
    const [displayName, setDisplayName] = useState(entityConfig.displayName)
    const entityConfigReference = toSnakeCase(displayName)

    const client = useClient()
    const [selectedClient, setSelectedClient] = useState<string>()
    const clientToUse = selectedClient ?? client

    const config = useEnvConfig()
    const saveEntityConfigRequest = useApiQuery({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${clientToUse}/entity-config`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    const saveEntityRelationshipsRequest = useApiQuery({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${clientToUse}/entity-relationships`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    const getEntityRelationshipsRequest = useApiQuery({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${clientToUse}/entity-relationships`,
        method: "GET",
        isExecutedAutomatically: false
    })

    const user = useUser()
    const clientOptions = user.accessibleClients.sort().map(client => ({
        value: client,
        label: client
    }))
    const onClientSelected = (option: DropdownOption<string>) => setSelectedClient(option.value)

    const getClientEntityConfigsRequest = useApiQuery<EntityConfig[]>({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${clientToUse}/entity-config`,
        method: "GET",
        isExecutedAutomatically: clientToUse !== client
    })
    const disallowedEntityNames = clientToUse === client ? existingEntityConfigRefs : getClientEntityConfigsRequest.data?.map(c => c.reference) ?? []

    const overlay = useOverlay()
    const onConfirmClicked = () => {
        const newEntityConfig = {
            displayName: displayName,
            fields: entityConfig.fields,
            parents: []
        }

        const newRelationships = existingRelationships.map(relationship => ({
            ...relationship,
            parentEntityReference: entityConfigReference
        }))

        const relationshipPromise = getEntityRelationshipsRequest
            .execute()
            .then(async res => {
                const relationshipsToSave = [...(res.data as EntityRelationshipDto[]), ...newRelationships]
                await saveEntityRelationshipsRequest.execute(undefined, relationshipsToSave)
                return relationshipsToSave
            })
            .then(relationshipsSaved => {
                if (clientToUse !== client) return
                onEntityRelationshipsUpdated(convertEntityRelationshipsFromDto(relationshipsSaved))
            })

        const entityConfigPromise = saveEntityConfigRequest.execute(entityConfigReference, newEntityConfig).then(() => {
            if (clientToUse !== client) return
            onEntityConfigDuplicated({ reference: entityConfigReference, ...newEntityConfig })
        })

        return Promise.allSettled([relationshipPromise, entityConfigPromise]).then(result => {
            if (result.some(r => r.status === "rejected")) return Promise.reject()
            onClose()
            overlay.showCreatedSuccessfullyOverlay("Entity config")
        })
    }

    const hasInvalidName = disallowedEntityNames.some(n => n === entityConfigReference)

    return (
        <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-column">
                <div className="d-flex mb-1">
                    <span className="text-uppercase my-auto me-3 text-grey no-select">Entity Name</span>
                    <span className="text-grey ms-1 no-select">{entityConfigReference}</span>
                </div>
                <div className="d-flex gap-2">
                    <span className="text-uppercase my-auto text-grey no-select">Display Name</span>
                    <Input placeholder="Please enter a name" ariaLabel="entity-config-duplicate-display-name" value={displayName} onChange={setDisplayName} />
                </div>
            </div>
            {clientOptions.length > 1 && (
                <div className="d-flex gap-2 align-items-center">
                    <span className="text-uppercase my-auto text-grey no-select">Client:</span>
                    <Dropdown
                        options={clientOptions}
                        onOptionSelected={onClientSelected}
                        selectedOption={clientOptions.find(o => o.value === clientToUse)}
                        ariaLabel="client-dropdown"
                        disabled={getClientEntityConfigsRequest.isFetching}
                    />
                    {getClientEntityConfigsRequest.isFetching && <Spinner colour="blue" size="small" />}
                </div>
            )}
            {hasInvalidName && (
                <div className="d-flex align-items-center text-danger text-center">
                    <i className="fal fa-exclamation-triangle me-2" />
                    <span>Entity config with this name already exists</span>
                </div>
            )}
            {getClientEntityConfigsRequest.hasErrored && (
                <div className="d-flex align-items-center text-danger text-center">
                    <i className="fal fa-exclamation-triangle me-2" />
                    <span>Failed to fetch clients entity configs</span>
                </div>
            )}
            <div className="d-flex gap-2 justify-content-between">
                <StandardButton colour="red" iconClasses="fal fa-times" label="Cancel" onClick={onClose} />
                <ProgressButton
                    colour="blue"
                    iconClasses="fal fa-check"
                    label="Confirm"
                    failedText="Failed!"
                    onClickWithPromise={onConfirmClicked}
                    disabled={getClientEntityConfigsRequest.isFetching || hasInvalidName || getClientEntityConfigsRequest.hasErrored}
                />
            </div>
        </div>
    )
}

export default DuplicateEntityConfigForm
