import React, { useState, useRef, useImperativeHandle } from "react"
import { connect, useDispatch } from "react-redux"
import { GridColumn } from "@progress/kendo-react-grid"
import StatefulGrid from "../common/grids/StatefulGrid"
import { CommandCell } from "../common/grids/commandCell"
import { getFocusedAccount } from "../../api/currentSelectionApi"
import { normaliseFormat, normaliseFormatDef } from "../../selectors/lookup"
import { selectTransaction } from "../../actions/functionActions"
import { refreshVpHierarchy } from "../../actions/vpActions"
import { getCustomerTransactionsRefs } from "../../api/functionApi"
import { useInternationalization } from "@progress/kendo-react-intl"
import CustomSwitch from "../common/buttons/customSwitch"
import { setChildToParentCreditLimit } from "../../api/vpApi"
import { showToastErrorMessage } from "../../api/toasterApi"
import useGlobalConfig from "../../hooks/useGlobalConfig"
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useClient, useInvevoSession } from "invevo-react-components"
import { getApiUrl } from "../../constants"
import { webApiInterface } from "../../api/webApiInterface"
import { useCustomer } from "../../../../contexts/CustomerContext"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"
import { useEntity } from "../../../../contexts/EntityContext"

const HierarchyGrid = ({
    passedInRef,
    selectedAccount,
    currencyFormat,
    currencyFormatDef,
    refresh,
    refreshVpHierarchy,
    getFocusedAccount,
    onEdit,
    selectTransaction,
    getCustomerTransactionsRefs,
    setChildToParentCreditLimit
}) => {
    const client = useClient()
    const hierarchyGridRef = useRef()
    const exportExcelRef = useRef()
    const internationalization = useInternationalization()
    const globalConfiguration = useGlobalConfig()
    const { isEnabled: newEntityStructure } = useFeatureToggle("newEntityStructure")

    const { invevoVpGridBrand, invevoVpGridTed } = useFlags()

    const [, setCustomer] = useCustomer()
    const [, setEntity] = useEntity()

    useImperativeHandle(passedInRef, () => ({
        exportExcel: () => onExportExcel()
    }))

    const accountId = selectedAccount.miaAccountId
    const isVirtual = selectedAccount.miaAccountIsVirtualAccount

    const [selectedId, setSelectedId] = useState([])

    const [, setInvevoSession] = useInvevoSession()
    const dispatch = useDispatch()

    const goToAccount = (e, dataItem) => {
        e.preventDefault()
        webApiInterface
            .authFetch(
                client,
                getApiUrl(client) + `api/VirtualAccount/Hierarchy?pageSize=100&page=1&id=${dataItem.Id}&isVirtualParent=${dataItem.IsVirtual}`,
                dispatch
            )
            .then(response => response.json())
            .then(data => {
                getFocusedAccount(client, dataItem.Id, dataItem.IsVirtual)
                setInvevoSession({
                    changeFromHistoryGrid: true
                })
                if (newEntityStructure)
                    setEntity({
                        entityType: dataItem.IsVirtual ? "virtual_parent" : "customer",
                        entityTypeReference: dataItem.IsVirtual ? "virtual_parent" : "customer",
                        sqlId: dataItem.Id,
                        reference: dataItem.AccountCode
                    })
                else
                    setCustomer({
                        reference: dataItem.AccountCode,
                        sqlId: dataItem.Id,
                        isVirtualAccount: dataItem.IsVirtual
                    })
            })
    }

    const editParent = (e, dataItem) => {
        onEdit(dataItem)
    }

    const rowRender = trElement => {
        const type = trElement.props.children && trElement.props.children.length > 0 ? trElement.props.children[0].props.dataItem.Type.toLowerCase() : "none"

        return React.cloneElement(
            trElement,
            {
                className: `${trElement.props.className} type-${type}`
            },
            trElement.props.children
        )
    }

    const isSelectedRow = dataItem => dataItem.Id === selectedId

    const onRowClick = gridRowEvent => {
        const dataItem = gridRowEvent.dataItem

        if (dataItem && !dataItem.selected && dataItem.Type === "Child") {
            selectTransaction([])
            getCustomerTransactionsRefs(client, setCustomer, {
                customerid: dataItem.Id,
                isVirtualAccount: dataItem.IsVirtual,
                isClosed: null
            })

            setSelectedId(dataItem.Id)
        } else {
            setSelectedId(null)
        }
    }

    const availableCreditLimitCell = ({ dataItem }) => {
        const creditLimit = internationalization.formatNumber(dataItem.CreditLimit, currencyFormat)

        const allocatedCreditSum = internationalization.formatNumber(dataItem.AllocatedCreditSum, currencyFormat)

        const display = dataItem.IsVirtual ? `${allocatedCreditSum} of ${creditLimit}` : creditLimit

        return <td className="text-right">{display}</td>
    }

    const titleCell = ({ dataItem }) => (
        <td className="text-muted">
            {dataItem.IsVirtual ? (
                <i className={`fa-solid fa-sitemap ${dataItem.selected ? "highlight" : ""}`}></i>
            ) : (
                <i className={`fa-solid fa-building ${dataItem.selected ? "highlight" : ""}`}></i>
            )}{" "}
            {dataItem.Name} <i className="fa-solid fa-hashtag"></i> {dataItem.AccountCode}
        </td>
    )

    const childrenCreditLimitTotalFooterCell = ({ colSpan, style }) => {
        const grid = hierarchyGridRef && hierarchyGridRef.current ? hierarchyGridRef.current.state.data : []
        const total = grid
            .filter(f => f.Type === "Child")
            .map(r => r.CreditLimit)
            .reduce((total, c) => total + c, 0)
        return (
            <td colSpan={colSpan} style={style} className="text-right">
                {total > 0 ? internationalization.formatNumber(total, currencyFormat) : ""}
            </td>
        )
    }

    const [requestedUseCredit, setRequestedUseCredit] = useState({})
    const reset = () => {
        setRequestedUseCredit({})
        setSelectedId(null)
    }

    // messy but allows for grid to have switch
    function useCreditSwitchCell({ dataItem }) {
        if (dataItem.Type !== "Child") return <td></td>

        const id = dataItem.Id
        const checked = Boolean(dataItem.UsingParentCreditLimit)

        const onCreditLimitChange = value => {
            setRequestedUseCredit(r => ({
                ...r,
                [id]: {
                    value,
                    inFlight: true
                }
            }))

            const onError = (error = "Error saving use credit") => {
                showToastErrorMessage(error)
                setRequestedUseCredit(r => ({
                    ...r,
                    [id]: {
                        value: !value,
                        inFlight: false
                    }
                }))
            }

            setChildToParentCreditLimit(client, dataItem.Id, accountId, value)
                .then(response => {
                    if (response.success) {
                        refreshVpHierarchy()
                    } else {
                        onError(response.error)
                    }
                })
                .catch(onError)
        }

        const hasRequested = requestedUseCredit.hasOwnProperty(id)

        const checkedToShow = hasRequested ? requestedUseCredit[id].value : checked
        const disabled = hasRequested ? requestedUseCredit[id].inFlight : false

        return (
            <td className="switch">
                <CustomSwitch id={id} checked={checkedToShow} onChange={onCreditLimitChange} disabled={disabled} />
            </td>
        )
    }

    const showFn = ({ Type }) => Type !== "This"
    const onExportExcel = () => {
        exportExcelRef.current.save(hierarchyGridRef.current.state.data)
    }

    return (
        <ExcelExport ref={exportExcelRef}>
            <StatefulGrid
                client={client}
                ref={hierarchyGridRef}
                path={getApiUrl(client) + "api/VirtualAccount/Hierarchy"}
                className={"w-100 scrollable-none custom-grid"}
                additionalRequestPayload={{
                    id: accountId,
                    isVirtualParent: isVirtual
                }}
                pageable={{
                    pageSizes: [50, 100, 200],
                    refresh: true,
                    buttonCount: 5
                }}
                pageSize={100}
                hideablePaging={true}
                isSelectedRow={isSelectedRow}
                onRowClick={onRowClick}
                rowRender={rowRender}
                refresh={refresh}
                afterReceivedFn={reset}
            >
                <ExcelExportColumn field="Name" title="Customer" />
                <ExcelExportColumn field="AccountCode" title="Account Code" />
                <GridColumn title="Customer" cell={titleCell} />
                <GridColumn field="Type" title="Type" width="70px" />
                {invevoVpGridBrand && <GridColumn field="Brand" title="Brand" width="115px" />}
                <GridColumn
                    field="AccountBalance"
                    title="Balance"
                    format={currencyFormatDef}
                    width="115px"
                    className="text-right"
                    headerClassName={"text-right"}
                />
                <GridColumn
                    field="Overdue"
                    title="Overdue Debt"
                    format={currencyFormatDef}
                    width="115px"
                    className="text-right"
                    headerClassName={"text-right"}
                />
                {invevoVpGridTed && (
                    <GridColumn field="TedValue" title="TED" format={currencyFormatDef} width="115px" className="text-right" headerClassName={"text-right"} />
                )}
                <ExcelExportColumn field="AllocatedCreditSum" title="Allocated Credit" />
                <ExcelExportColumn field="CreditLimit" title="Credit Limit" />
                {globalConfiguration.showCreditLimit && (
                    <GridColumn
                        title="Credit Limit"
                        cell={availableCreditLimitCell}
                        width="130px"
                        headerClassName={"text-right"}
                        footerCell={childrenCreditLimitTotalFooterCell}
                    />
                )}
                {isVirtual && globalConfiguration.showCreditLimit && <GridColumn title="Use Credit" cell={useCreditSwitchCell} width="85px" />}
                {isVirtual && <GridColumn field="AccountStatusText" title="Status" width="75px" />}

                <GridColumn
                    title="Edit"
                    width="60px"
                    headerClassName={"text-right"}
                    cell={CommandCell({
                        cssTag: "hierarchy",
                        onCommandClick: editParent,
                        buttonText: <i className="fa-solid fa-edit"></i>,
                        showFn
                    })}
                />
                <GridColumn
                    title="Go To"
                    headerClassName={"text-right"}
                    width="60px"
                    cell={CommandCell({
                        cssTag: "hierarchy",
                        onCommandClick: goToAccount,
                        ariaLabel: "vp-navigate-to-account",
                        buttonText: <i className="fa-regular fa-arrow-circle-right"></i>,
                        showFn
                    })}
                />
            </StatefulGrid>
        </ExcelExport>
    )
}

const mapStateToProps = state => {
    return {
        selectedAccount: state.currentSelectionReducer.selectedAccount,
        currencyFormatDef: normaliseFormatDef(state.lookupReducer.globalSettings.results.currencyFormat),
        currencyFormat: normaliseFormat(state.lookupReducer.globalSettings.results.currencyFormat),
        refresh: state.vpReducer.refreshVpHierarchy
    }
}

export default connect(mapStateToProps, {
    getFocusedAccount,
    selectTransaction,
    getCustomerTransactionsRefs,
    setChildToParentCreditLimit,
    refreshVpHierarchy
})(HierarchyGrid)
