import React from 'react';

const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

const GuidInput = ({ change, value, nullable, tabIndex, maxLength = 25 }) => {
  const onLocalChange = (e) => change(e.target.value);

  const setEmpty = () => change(EMPTY_GUID);

  const input = (
    <div>
      <input
        type="text"
        className="form-control"
        maxLength={EMPTY_GUID.length}
        value={value}
        onChange={onLocalChange}
        tabIndex={tabIndex}
      />
      <button type="button" onClick={setEmpty} className="btn btn-default">
        Empty
      </button>
    </div>
  );

  return nullable ? (
    input
  ) : (
    <div className="form-control-feedback-wrapper warning">
      {input}
      <span className="form-control-feedback">
        <i className='fa-solid fa-asterisk'></i>
      </span>
    </div>
  );
};

export default GuidInput;
