import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as lodash from 'lodash';

import { authZ_Permissions, getApiUrl } from '../../../constants';
import { webApiInterface } from '../../../api/webApiInterface';
import { executeAuthAsyncGet } from '../../../utility/asyncSupport';
import ExportCsvButton from './exportCsvButton';
import { hasPermission } from '../../../utility/authZ';

import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../../common/grids/StatefulGrid';

import { ColumnMenuFilter } from '../../common/grids/columnMenu';

import EditDeleteCommandCell from '../../common/grids/editDeleteCommandCell';
import { ConfrimDialog } from '../../common/confirmDialog';

class FileTypeTableGrid extends Component {
  constructor(props) {
    super(props);

    this.onEditClick = this.onEditClick.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);

    this.exportCsv = this.exportCsv.bind(this);

    this.state = {
      refresh: 0,
      showDeleteDialog: false,
      itemToDelete: null
    };

    this.CommandCell = EditDeleteCommandCell({
      onEditClick: this.onEditClick,
      onDeleteClick: this.onDeleteClick
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.fileTypeTable.success ||
      nextProps.fileTypeTable.deleted ||
      nextProps.refresh.update
    ) {
      this.rebind();
    }
  }

  rebind = () => {
    this.setState({ refresh: this.state.refresh + 1 });
  };

  onEditClick = (e, dataItem) => {
    e.preventDefault();
    const fileTypeTableId = dataItem.id;
    this.props.onAdd(fileTypeTableId);
  };

  onAddClick() {
    this.props.onAdd();
  }

  onDeleteClick = (e, dataItem) => {
    e.preventDefault();
    this.setState({
      showDeleteDialog: true,
      itemToDelete: dataItem
    });
  };

  deleteItem = () => {
    if (this.state.itemToDelete !== null) {
      this.props.onDelete(this.state.itemToDelete);
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.setState({
      showDeleteDialog: false
    });
  };

  getColumns() {
    const columns = [
      { field: 'id', title: 'Id' },
      { field: 'importTable', title: 'Import Table' },
      {
        field: 'insertOnly',
        title: 'Insert Only',
        type: 'boolean',
        filter: 'boolean'
      },
      {
        field: 'updateOnly',
        title: 'Update Only',
        type: 'boolean',
        filter: 'boolean'
      },
      { field: 'resolutionOrder', title: 'Resolution Order' },
      {
        field: 'treatEmptyStringAsNotSupplied',
        title: 'Empty String Not Supplied',
        type: 'boolean',
        filter: 'boolean'
      },
      {
        field: 'isMasterTable',
        title: 'Master Table',
        type: 'boolean',
        filter: 'boolean'
      },
      { field: 'masterTableKeyFieldName', title: 'Master Table Key Field Name' }
    ];

    return columns;
  }

  exportCsv() {
    const xhr = new XMLHttpRequest();
    const url =
      getApiUrl(this.props.client) +
      'api/import-configuration/export-csv?exportType=4&fileTypeId=' +
      this.props.fileTypeId;

    xhr.open('GET', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(xhr.response);
      a.download = 'FileTypeTables.csv';
      a.click();
    };

    this.props.executeAuthAsyncGet(this.props.client, 'Lookup/OK', 'CHECK_OK', null, function () {
      webApiInterface.applySecurity(xhr);
      xhr.send(null);
    });
  }

  static getKey() {
    return 'file-type-table-grid';
  }

  render() {
    const button = hasPermission(
      this.props.permissions,
      authZ_Permissions.FileImportAdminUpdate
    ) ? (
      <button
        className="btnDefault btn btn-default float-end"
        onClick={this.onAddClick}
      >
        New File Type Table
      </button>
    ) : (
      ''
    );

    return (
      <div>
        <h3>File Type Table</h3>
        <ExportCsvButton clicked={this.exportCsv} />
        {button}

        <StatefulGrid
          client={this.props.client}
          id={FileTypeTableGrid.getKey()}
          key={FileTypeTableGrid.getKey()}
          path={getApiUrl(this.props.client) + 'api/import-configuration/file-type-tables'}
          sortable
          className={'scrollable-none'}
          pageSize={10}
          pageable={{
            pageSizes: [5, 10, 20, 100],
            buttonCount: 5
          }}
          additionalRequestPayload={{
            fileTypeId: this.props.fileTypeId.toString()
          }}
          defaultField={{ field: 'id', dir: 'asc' }}
          refresh={this.state.refresh}
          columnMenu={ColumnMenuFilter}
        >
          {this.getColumns().map((column) => (
            <GridColumn key={column.field} {...column} />
          ))}

          {hasPermission(
            this.props.permissions,
            authZ_Permissions.FileImportAdminUpdate
          ) && <GridColumn cell={this.EditDeleteCommandCell} width={'200px'} />}
        </StatefulGrid>

        <ConfrimDialog
          show={this.state.showDeleteDialog}
          message={'Are you sure you want to delete this record?'}
          onConfirm={this.deleteItem}
          onClose={this.closeDialog}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let fileTypeTable = {
    success: false
  };

  let refresh = {
    update: false
  };

  if (state.fileTypeTables.length > 0) {
    fileTypeTable = lodash.last(state.fileTypeTables);
  }

  if (state.refresh.length > 0) {
    refresh = lodash.last(state.refresh);
  }

  return {
    permissions: state.authReducer.permissions,
    fileTypeTable: fileTypeTable,
    refresh: refresh
  };
}

export default connect(mapStateToProps, {
  executeAuthAsyncGet
})(FileTypeTableGrid);
