import React from 'react';
import { connect } from 'react-redux';
import { getApiUrl } from '../../constants';
import { storeGridState } from '../../actions/gridCacheActions';
import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../common/grids/StatefulGrid.js';
import TaskNotesHistoryGrid from './taskNotesHistoryGrid';
import { getFocusedTask, dismissFocusedTask } from '../../actions/taskActions';
import TaskCard from './taskCard';
import existential from '../../utility/existential';

class CustomerTasksGrid extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = this.defaultState();
  }

  defaultState() {
    return {
      refresh: 0
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.customerId !==
      this.props.customerId
    ) {
      const gridState = { ...this.props.gridState, page: 1 };

      this.props.onStoreGridState(
        CustomerTasksGrid.getKey() + this.props.customerId,
        gridState
      );
    }

    if (
      prevProps.selectedTask.id !== this.props.selectedTask.id ||
      prevProps.selectedTask.lastUpdated !== this.props.selectedTask.lastUpdated
    ) {
      this.refresh();
    }
  }

  refresh = () => {
    this.setState({ refresh: this.state.refresh + 1 });
  };

  isSelectedRow = (dataItem) => dataItem.Id === this.props.selectedTask.id;

  onRowClick = (gridRowEvent) => {
    const dataItem = gridRowEvent.dataItem;

    if (dataItem && !dataItem.selected) {
      this.props.getFocusedTask(dataItem.Id, !!dataItem.TaskCompletionId, this.props.client);
    } else if (gridRowEvent.nativeEvent.ctrlKey) {
      this.props.dismissFocusedTask();
    }
  };

  onStateChange = (data) => {
    this.props.onStoreGridState(
      CustomerTasksGrid.getKey() + this.props.customerId,
      data
    );
  };

  additionalRequestPayload = () => {
    return {
      accountid: this.props.customerId,
      isvirtualaccount: this.props.isVirtualAccount,
      isclosed: this.props.statusFilter.isClosed,
      onlycurrentusertasks: this.props.statusFilter.isCurrentUserTasks
    };
  };

  // TODO do we need these details on grid?
  innerGrid = (props) => <TaskNotesHistoryGrid {...props} />;
2849
  static getKey() {
    return 'tasks-grid-key';
  }

  taskCard = (e) => (
    <TaskCard dataItem={e.dataItem} onClick={this.onRowClick} />
  );

  render() {
    const filter =
      this.props.filters && this.props.filters.length > 0
        ? { logic: 'and', filters: this.props.filters }
        : null;

    return (
      <StatefulGrid
        client={this.props.client}
        path={getApiUrl(this.props.client) + 'api/task/list'}
        sortable
        pageable={{
          pageSizes: [50, 100, 200],
          refresh: true,
          buttonCount: 5
        }}
        onStateChange={this.onStateChange}
        onRowClick={this.onRowClick}
        additionalRequestPayload={this.additionalRequestPayload()}
        defaultField={{ field: 'TaskDate', dir: 'asc' }}
        isSelectedRow={this.isSelectedRow}
        hideablePaging={true}
        className={'hide-header single-cell scrollable-none'}
        filter={filter}
        refresh={this.state.refresh}
      >
        <GridColumn cell={this.taskCard} />
      </StatefulGrid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gridState: existential(state.gridCacheReducer.grids, 'gridState', {}),
    selectedTask: state.taskReducer.selectedTask || {}
  };
};

export default connect(mapStateToProps, {
  onStoreGridState: storeGridState,
  getFocusedTask,
  dismissFocusedTask,
})(CustomerTasksGrid);
