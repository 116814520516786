import React from "react"
import * as Sentry from "@sentry/browser"

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        console.error(error)
        Sentry.withScope(scope => {
            scope.setExtras(errorInfo)
            Sentry.captureException(error)
        })
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="p-3">
                    <h4>Something has gone wrong, please try again later.</h4>
                    <h4>If the problem persists, please contact our support team</h4>
                </div>
            )
        }
        return this.props.children
    }
}

export default ErrorBoundary
