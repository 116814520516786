import Trigger from "../../../types/Trigger"
import Checkbox from "../../../../../library/form/Checkbox"
import TimeInput from "../../../../../library/Inputs/TimeInput"

type Props = {
    ruleTrigger: Trigger
    onRuleTriggerChanged: (triggers: Trigger | undefined) => void
}

const ScheduledTriggerSetup = ({ ruleTrigger, onRuleTriggerChanged }: Props) => {
    const onTimeChanged = (time: { hour: number; minute: number; timeZone: string }) => {
        onRuleTriggerChanged({
            timeToTrigger: {
                hour: time.hour,
                minute: time.minute,
                timeZone: time.timeZone
            },
            onlyTriggerOnSpecificDaysOfWeek: ruleTrigger.onlyTriggerOnSpecificDaysOfWeek,
            onlyTriggerOnSpecificDaysOfMonth: ruleTrigger.onlyTriggerOnSpecificDaysOfMonth
        })
    }

    const allDaysSelected =
        ruleTrigger?.onlyTriggerOnSpecificDaysOfWeek !== undefined && Object.values(ruleTrigger.onlyTriggerOnSpecificDaysOfWeek).every(enabled => enabled)
    const noDaysSelected =
        ruleTrigger?.onlyTriggerOnSpecificDaysOfWeek !== undefined && Object.values(ruleTrigger.onlyTriggerOnSpecificDaysOfWeek).every(enabled => !enabled)

    const onAllDaysClicked = () => {
        if (ruleTrigger === undefined) return

        onRuleTriggerChanged({
            ...ruleTrigger,
            onlyTriggerOnSpecificDaysOfWeek: {
                monday: !allDaysSelected,
                tuesday: !allDaysSelected,
                wednesday: !allDaysSelected,
                thursday: !allDaysSelected,
                friday: !allDaysSelected,
                saturday: !allDaysSelected,
                sunday: !allDaysSelected
            }
        })
    }

    const onDayClicked = (day: string) => {
        if (ruleTrigger === undefined) return

        onRuleTriggerChanged({
            ...ruleTrigger,
            onlyTriggerOnSpecificDaysOfWeek: {
                ...ruleTrigger.onlyTriggerOnSpecificDaysOfWeek,
                //@ts-ignore
                [day]: !ruleTrigger.onlyTriggerOnSpecificDaysOfWeek[day]
            }
        })
    }

    const enabledDaysOfTheWeek = ruleTrigger?.onlyTriggerOnSpecificDaysOfWeek ?? {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
    }
    const daysOfTheWeek = Object.keys(enabledDaysOfTheWeek)

    return (
        <div className="d-flex flex-column text-grey">
            <div className="d-flex flex-column gap-2">
                <div className="d-flex align-items-center gap-2">
                    <span>TRIGGERS AT</span>
                    <TimeInput dateTime={ruleTrigger.timeToTrigger} onDateTimeChange={onTimeChanged} ariaLabel="trigger-timer" />
                    {noDaysSelected && (
                        <div className="d-flex align-items-center ms-auto fs-5">
                            <i className="fal fa-exclamation-triangle text-warning me-2"></i>
                            <span className="me-2">No days are selected. This rule will not trigger</span>
                        </div>
                    )}
                </div>
                <div className="d-flex align-items-center gap-4">
                    <Checkbox label="Every day" isChecked={allDaysSelected} onClick={onAllDaysClicked} />
                    {daysOfTheWeek.map(day => {
                        const onClick = () => onDayClicked(day)
                        //@ts-ignore
                        return <Checkbox key={day} label={day} isChecked={enabledDaysOfTheWeek[day] || allDaysSelected} onClick={onClick} />
                    })}
                </div>
            </div>
        </div>
    )
}

export default ScheduledTriggerSetup
