import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { getLookup, postLookup } from '../../../api/lookupApi';
import { sectionTypes } from '../../../constants';
import { distinctTemplateList } from '../../../utility/distinctTemplateList';
import { useClient } from 'invevo-react-components';

const LookupList = ({
  nullable,
  value,
  change,
  tabIndex,
  multi,
  list,
  parameters,

  getLookup,
  postLookup,
  regions,
  reminderTypes,
  roles,
  taskCompletions,
  taskDescriptions,
  taskTypes,
  stateMachineActionDateFields,
  brands,
  addresses,
  addressTypes,
  branches,
  contactTypeExternals,
  contactTypeInternals,
  contactTypes,
  reasonsForDecision,
  reasonsLimitIncrease,
  availableLanguages,
  customerTypes,
  customerStatuses,
  eBillingStatuses,
  guaranteeTypes,
  legalStatuses,
  paymentTerms,
  invoiceDeliveryTypes,
  ledgers,
  paymentMethods,
  tradeClassifications,
  tradeLimitSearches,
  tradeTypes,
  transactionLists,
  transStatus,
  transSubStatus,
  transType,
  transactionDisputeStates,
  transactionClosingTypes,
  currencies,
  mailEventTypes,
  followUpReasons,
  followUpSources,
  followUpTypes,
  regionCodes,
  noteTypes,
  contactLists,
  emailTemplates,
  postTemplates,
  countries,
  virtualAccountTypes,
  dormantMethods,
  smsTemplates,
  accountBalanceModes,
  documentTypes,
  ubmEventMappingIds,
  cardPaymentStatuses
}) => {
  const client = useClient()
  useEffect(() => {
    switch (list) {
      case 'region':
        getLookup(client, 'lookup/regions', 'regions');
        break;
      case 'reminderTypes':
        getLookup(client, 'lookup/remindertypes', 'remindertypes');
        break;
      case 'role':
        getLookup(client, 'lookup/roles', 'roles');
        break;
      case 'taskType':
        getLookup(client, 'lookup/tasktypes', 'taskTypes');
        break;
      case 'taskDescription':
        if (parameters) {
          postLookup(client, 
            'lookup/TaskDescriptionsByTaskTypes',
            'taskDescriptions',
            parameters
          );
        } else {
          getLookup(client, 'lookup/TaskDescriptions', 'taskDescriptions');
        }
        break;
      case 'stateMachineActionDateField':
        if (parameters) {
          postLookup(client, 
            'rule/StateMachineActionDateFields',
            'stateMachineActionDateFields',
            parameters
          );
        }
        break;
      case 'reasonsLimitIncrease':
        getLookup(client, 'lookup/ReasonsForLimitIncrease', 'reasonsLimitIncrease');
        break;
      case 'address':
        if (parameters) {
          postLookup(client, 'lookup/Addresses', 'addresses', parameters);
        }
        break;
      case 'brand':
        getLookup(client, 'lookup/Brands', 'brands');
        break;
      case 'branch':
        if (parameters) {
          postLookup(client, 'lookup/Branches', 'branches', parameters);
        } else {
          getLookup(client, 'lookup/Branches', 'branches');
        }
        break;
      case 'contactTypeExternal':
        getLookup(client, 'lookup/ContactTypeExternals', 'contactTypeExternals');
        break;
      case 'contactType':
        getLookup(client, 'lookup/ContactTypes', 'contactTypes');
        break;
      case 'contactTypeInternal':
        getLookup(client, 'lookup/ContactTypeInternals', 'contactTypeInternals');
        break;
      case 'requestReason':
        getLookup(client, 'lookup/ReasonsForLimitIncrease', 'reasonsLimitIncrease');
        break;
      case 'reasonForDecision':
        getLookup(client, 'lookup/ReasonsForDecision', 'reasonsForDecision');
        break;
      case 'customerType':
        getLookup(client, 'lookup/CustomerTypes', 'customerTypes');
        break;
      case 'customerStatus':
        getLookup(client, 'lookup/CustomerStatuses', 'customerStatuses');
        break;
      case 'eBillingStatus':
        getLookup(client, 'lookup/EBillingStatuses', 'eBillingStatuses');
        break;
      case 'guaranteeType':
        getLookup(client, 'lookup/GuaranteeTypes', 'guaranteeTypes');
        break;
      case 'legalStatus':
        getLookup(client, 'lookup/LegalStatuses', 'legalStatuses');
        break;
      case 'paymentTerm':
        getLookup(client, 'lookup/PaymentTerms', 'paymentTerms');
        break;
      case 'invoiceDeliveryType':
        getLookup(client, 'lookup/InvoiceDeliveryTypes', 'invoiceDeliveryTypes');
        break;
      case 'ledger':
        getLookup(client, 'lookup/Ledgers', 'ledgers');
        break;
      case 'paymentMethod':
        getLookup(client, 'lookup/PaymentMethods', 'paymentMethods');
        break;
      case 'tradeClassification':
        getLookup(client, 'lookup/TradeClassifications', 'tradeClassifications');
        break;
      case 'tradeType':
        getLookup(client, 'lookup/TradeTypes', 'tradeTypes');
        break;
      case 'currency':
        getLookup(client, 'lookup/Currencies', 'currencies');
        break;
      case 'transactionClosingType':
        getLookup(client, 'lookup/TransactionClosingTypes', 'transactionClosingTypes');
        break;
      case 'transactionDisputeState':
        getLookup(client, 
          'lookup/TransactionDispluteStates',
          'transactionDisputeStates'
        );
        break;
      case 'transactionStatus':
        getLookup(client, 'lookup/TransactionStatuses', 'transStatus');
        break;
      case 'transactionSubStatus':
        if (parameters) {
          postLookup(client, 
            'lookup/TransactionSubStatuses',
            'transSubStatus',
            parameters
          );
        }
        break;
      case 'transactionType':
        getLookup(client, 'lookup/TransactionTypes', 'transType');
        break;
      case 'addressType':
        getLookup(client, 'contact/customeraddresstypes', 'addressTypes');
        break;
      case 'mailEventType':
        getLookup(client, 'lookup/MailEventTypes', 'mailEventTypes');
        break;
      case 'followUpReason':
        getLookup(client, 'lookup/followupreasons', 'followUpReasons');
        break;
      case 'followUpSource':
        getLookup(client, 'lookup/FollowUpSources', 'followUpSources');
        break;
      case 'followUpType':
        getLookup(client, 'lookup/FollowUpTypes', 'followUpTypes');
        break;
      case 'taskCompletion':
        if (parameters) {
          postLookup(client, 
            'lookup/TaskCompletionsByTaskTypes',
            'taskCompletions',
            parameters
          );
        }
        break;
      case 'noteType':
        getLookup(client, 'lookup/NoteTypes', 'noteTypes');
        break;
      case 'transactionList':
        getLookup(client, 'lookup/TransactionLists', 'transactionLists');
        break;
      case 'contactList':
        getLookup(client, 'lookup/ContactLists', 'contactLists');
        break;
      case 'tradeLimitSearch':
        getLookup(client, 'lookup/TradeLimitSearches', 'tradeLimitSearches');
        break;
      case 'emailTemplate':
        getLookup(client, 'email/templates', 'emailTemplates');
        break;
      case 'postTemplate':
        getLookup(client, 'post/templates', 'postTemplates');
        break;
      case 'country':
        getLookup(client, 'countries/all', 'countries');
        break;
      case 'virtualAccountType':
        getLookup(client, 
          'VirtualAccount/GetVirtualAccountTypes',
          'virtualAccountTypes'
        );
        break;
      case 'dormantMethod':
        getLookup(client, 'lookup/dormantMethods', 'dormantMethods');
        break;
      case 'smsTemplate':
        getLookup(client, 'sms/GetTemplates', 'smsTemplates');
        break;
      case 'accountBalanceMode':
        getLookup(client, 'lookup/accountBalanceModes', 'accountBalanceModes');
        break;
      case 'documentType':
        getLookup(client, 'lookup/DocumentTypes', 'documentTypes');
        break;
      case 'ubmEventMappingId':
        getLookup(client, 'lookup/ubmEventMappingIds', 'ubmEventMappingIds');
        break;
      case 'cardPaymentStatus':
        getLookup(client, 'lookup/cardPaymentStatuses', 'cardPaymentStatuses');
        break;
      case 'regionCode':
        getLookup(client, 'lookup/regionCodes', 'regionCodes');
        break;
      default:
        break;
    }
  }, [getLookup, parameters, list, postLookup, client]);

  const select = (option) => {
    if (option) {
      change(option);
    } else {
      change(null);
    }
  };

  let options = [];

  switch (list) {
    case 'sectionTypes':
      options = sectionTypes;
      break;
    case 'region':
      options = regions.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'reminderTypes':
      options = reminderTypes.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'role':
      options = roles.results.map((m) => {
        return { value: String(m.Id), label: m.Name };
      });
      break;
    case 'taskType':
      options = taskTypes.results.map((m) => {
        return { value: String(m.id), label: m.text };
      });
      break;
    case 'taskDescription':
      options = taskDescriptions.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'stateMachineActionDateField':
      options = stateMachineActionDateFields.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'reasonsLimitIncrease':
      options = reasonsLimitIncrease.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'addresses':
      options = addresses.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'brand':
      options = brands.results.map((m) => {
        return { value: String(m.Id), label: m.Name };
      });
      break;
    case 'branch':
      options = branches.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'contactTypeExternal':
      options = contactTypeExternals.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'contactType':
      options = contactTypes.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'contactTypeInternal':
      options = contactTypeInternals.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'requestReason':
      options = reasonsLimitIncrease.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'reasonForDecision':
      options = reasonsForDecision.results.map((m) => {
        return { value: String(m.Item1), label: m.Item3 };
      });
      break;
    case 'language':
      options = availableLanguages.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'customerType':
      options = customerTypes.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'customerStatus':
      options = customerStatuses.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'eBillingStatus':
      options = eBillingStatuses.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'guaranteeType':
      options = guaranteeTypes.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'legalStatus':
      options = legalStatuses.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'paymentTerm':
      options = paymentTerms.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'invoiceDeliveryType':
      options = invoiceDeliveryTypes.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'ledger':
      options = ledgers.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'paymentMethod':
      options = paymentMethods.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'tradeClassification':
      options = tradeClassifications.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'tradeType':
      options = tradeTypes.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'currency':
      options = currencies.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'transactionClosingType':
      options = transactionClosingTypes.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'transactionDisputeState':
      options = transactionDisputeStates.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'transactionStatus':
      options = transStatus.results.map((m) => {
        return { value: String(m.id), label: m.text };
      });
      break;
    case 'transactionSubStatus':
      options = transSubStatus.results.map((m) => {
        return { value: String(m.id), label: m.text };
      });
      break;
    case 'transactionType':
      options = transType.results.map((m) => {
        return { value: String(m.id), label: m.text };
      });
      break;
    case 'addressType':
      options = addressTypes.results.map((m) => {
        return { value: String(m.id), label: m.text };
      });
      break;
    case 'mailEventType':
      options = mailEventTypes.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'followUpReason':
      options = followUpReasons.results.map((m) => {
        return { value: String(m.id), label: m.name };
      });
      break;
    case 'followUpSource':
      options = followUpSources.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'followUpType':
      options = followUpTypes.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'taskCompletion':
      options = taskCompletions.results.map((m) => {
        return { value: String(m.Id), label: m.Title };
      });
      break;
    case 'noteType':
      options = noteTypes.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'transactionList':
      options = transactionLists.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'contactList':
      options = contactLists.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'tradeLimitSearch':
      options = tradeLimitSearches.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'emailTemplate':
      options = emailTemplates.results.map((m) => {
        return { value: String(m.EmailTemplateId), label: m.Name };
      });
      break;
    case 'postTemplate':
      options = distinctTemplateList(postTemplates.results)
      break;
    case 'country':
      options = countries.results.map((m) => {
        return { value: String(m.Id), label: m.Name };
      });
      break;
    case 'virtualAccountType':
      options = virtualAccountTypes.results.map((m) => {
        return { value: String(m.id), label: m.recordName };
      });
      break;
    case 'dormantMethod':
      options = dormantMethods.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'smsTemplate':
      options = smsTemplates.results.map((m) => {
        return { value: String(m.id), label: m.name };
      });
      break;
    case 'accountBalanceMode':
      options = accountBalanceModes.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'documentType':
      options = documentTypes.results.map((m) => {
        return { value: String(m.Id), label: m.Name };
      });
      break;
    case 'ubmEventMappingId':
      options = ubmEventMappingIds.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'cardPaymentStatus':
      options = cardPaymentStatuses.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    case 'regionCode':
      options = regionCodes.results.map((m) => {
        return { value: String(m.Id), label: m.Description };
      });
      break;
    default:
      break;
  }

  if (multi && nullable) {
    options.push({ value: 'NULL', label: 'Null' });
  }

  let selectedOptions = value;
  if (typeof value === 'string') {
    const valueIds = value.split(',');
    selectedOptions = options.filter(
      (f) => valueIds.findIndex((vId) => f.value === vId) !== -1
    );
  }
  return (
    <Select
      required
      name="rep-codes-select"
      isMulti={multi}
      value={selectedOptions}
      options={options}
      onChange={(e) => select(e)}
      isClearable={nullable}
      tabIndex={tabIndex}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    regions: state.lookupReducer.regions,
    reminderTypes: state.lookupReducer.reminderTypes,
    roles: state.lookupReducer.roles,
    taskTypes: state.lookupReducer.taskTypes,
    taskDescriptions: state.lookupReducer.taskDescriptions,
    reasonsLimitIncrease: state.lookupReducer.reasonsLimitIncrease,
    reasonsForDecision: state.lookupReducer.reasonsForDecision,
    stateMachineActionDateFields:
      state.lookupReducer.stateMachineActionDateFields,
    addresses: state.lookupReducer.addresses,
    brands: state.lookupReducer.brands,
    branches: state.lookupReducer.branches,
    contactTypeExternals: state.lookupReducer.contactTypeExternals,
    contactTypes: state.lookupReducer.contactTypes,
    contactTypeInternals: state.lookupReducer.contactTypeInternals,
    languages: state.lookupReducer.availableLanguages,
    customerTypes: state.lookupReducer.customerTypes,
    customerStatuses: state.lookupReducer.customerStatuses,
    eBillingStatuses: state.lookupReducer.eBillingStatuses,
    guaranteeTypes: state.lookupReducer.guaranteeTypes,
    legalStatuses: state.lookupReducer.legalStatuses,
    paymentTerms: state.lookupReducer.paymentTerms,
    invoiceDeliveryTypes: state.lookupReducer.invoiceDeliveryTypes,
    ledgers: state.lookupReducer.ledgers,
    paymentMethods: state.lookupReducer.paymentMethods,
    tradeClassifications: state.lookupReducer.tradeClassifications,
    tradeTypes: state.lookupReducer.tradeTypes,
    currencies: state.lookupReducer.currencies,
    transactionClosingTypes: state.lookupReducer.transactionClosingTypes,
    transactionDisputeStates: state.lookupReducer.transactionDisputeStates,
    transStatus: state.lookupReducer.transStatus,
    transSubStatus: state.lookupReducer.transSubStatus,
    transType: state.lookupReducer.transType,
    addressTypes: state.lookupReducer.addressTypes,
    mailEventTypes: state.lookupReducer.mailEventTypes,
    followUpReasons: state.lookupReducer.followUpReasons,
    followUpSources: state.lookupReducer.followUpSources,
    followUpTypes: state.lookupReducer.followUpTypes,
    taskCompletions: state.lookupReducer.taskCompletions,
    noteTypes: state.lookupReducer.noteTypes,
    transactionLists: state.lookupReducer.transactionLists,
    contactLists: state.lookupReducer.contactLists,
    tradeLimitSearches: state.lookupReducer.tradeLimitSearches,
    emailTemplates: state.lookupReducer.emailTemplates,
    postTemplates: state.lookupReducer.postTemplates,
    countries: state.lookupReducer.countries,
    virtualAccountTypes: state.lookupReducer.virtualAccountTypes,
    dormantMethods: state.lookupReducer.dormantMethods,
    smsTemplates: state.lookupReducer.smsTemplates,
    accountBalanceModes: state.lookupReducer.accountBalanceModes,
    documentTypes: state.lookupReducer.documentTypes,
    ubmEventMappingIds: state.lookupReducer.ubmEventMappingIds,
    cardPaymentStatuses: state.lookupReducer.cardPaymentStatuses,
    regionCodes: state.lookupReducer.regionCodes
  };
};

export default connect(mapStateToProps, {
  getLookup,
  postLookup
})(LookupList);
