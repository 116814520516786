import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextInputLabel from './textInputLabel';
import TextInputField from './TextInputField';

class InputWrapper extends Component {
  render() {
    let wrapperClass = 'form-group';
    if (this.props.error && this.props.error.length > 0) {
      wrapperClass += ' has-error';
    }
    return (
      <div className={wrapperClass}>
        <TextInputLabel {...this.props} />
        <div className="field">
          <TextInputField {...this.props} />
          <div className="input">{this.props.error}</div>
        </div>
      </div>
    );
  }
}

InputWrapper.defaultProps = {
  value: ''
};

InputWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string
};

export default InputWrapper;
