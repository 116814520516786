import { v4 as uuid } from "uuid"
import FilterTypesByField from "../../../../library/FilterList/FilterTypesByField"
import GenericFilter from "../../../../library/FilterList/filterTypes/GenericFilter"
import EntityConfig from "../../types/EntityConfig"
import EntityDataField from "../../types/EntityDataField"
import EntityRelationship from "../../types/EntityRelationship"
import EntityRelationshipCalculation from "../../types/EntityRelationshipCalculation"
import IntraEntityCalculation from "../../types/IntraEntityCalculation"
import IntraEntityCalculationOperation from "../../types/IntraEntityCalculationOperation"
import { IntraEntityCalculationOperationEnum } from "../../types/IntraEntityCalculationOperationEnum"
import { EntityCalculationFlowEdge, EntityRelationshipFlowEdge } from "./types/EntityConfigFlowEdge"
import { CalculationFlowNode, EntityFlowNode, EntityFlowNodePosition } from "./types/EntityConfigFlowNode"

export const isValidEntityConfigFieldToDisplay = (fieldName: string) => fieldName !== "client" && fieldName !== "archived" && fieldName !== "entity_type"

export const mapEntityConfigsToFlowNodes = (
    entityConfigs: EntityConfig[],
    nodePositions: EntityFlowNodePosition[],
    collapsedNodes: string[]
): EntityFlowNode[] =>
    entityConfigs.map((c, i) => {
        const nodePos = nodePositions.find(p => p.nodeRef === c.reference)
        return {
            ...c,
            type: "entity",
            description: "",
            x: nodePos?.x ?? (entityConfigs.length / 2) * i * 50,
            y: nodePos?.y ?? 0,
            collapsed: collapsedNodes.includes(c.reference),
            relationshipFields: c.fields.filter(f => isValidEntityConfigFieldToDisplay(f.fieldName)).map(f => f.fieldName)
        }
    })

export const mapEntityRelationshipsToFlowEdges = (entityRelationships: EntityRelationship[]): EntityRelationshipFlowEdge[] => {
    return entityRelationships.map(r => ({
        type: "relationship",
        sourceNodeReference: r.childEntityReference,
        sourceNodeHandleReference: r.groupingFieldName,
        targetNodeReference: r.parentEntityReference,
        targetNodeHandleReference: r.parentEntityReference
    }))
}

export const mapCalculationsToFlowNodesAndEdges = (
    entityConfigs: EntityConfig[],
    entityRelationships: EntityRelationship[],
    nodePositions: EntityFlowNodePosition[],
    collapsedNodes: string[] = []
): { nodes: CalculationFlowNode[]; edges: EntityCalculationFlowEdge[] } =>
    entityConfigs
        .map(config => {
            const nodeEdgesList = [
                ...mapConfigToNodesAndEdgesIntra(config, nodePositions, collapsedNodes),
                ...mapConfigAndRelationshipsToNodesAndEdgesInter(config, entityConfigs, entityRelationships, nodePositions, collapsedNodes)
            ]
            const nodes = nodeEdgesList.map(y => y.node)
            const edges = nodeEdgesList.flatMap(y => y.edges)
            return { nodes, edges }
        })
        .reduce(
            (obj, x) => {
                obj.nodes.push(...x.nodes)
                obj.edges.push(...x.edges)
                return obj
            },
            { nodes: [], edges: [] }
        )

const mapConfigToNodesAndEdgesIntra = (
    currentEntityConfig: EntityConfig,
    nodePositions: EntityFlowNodePosition[],
    collapsedNodes: string[]
): { node: CalculationFlowNode; edges: EntityCalculationFlowEdge[] }[] => {
    const operationRefs = new Map<IntraEntityCalculationOperation, string>()
    return currentEntityConfig.fields
        .flatMap((field, i) => {
            if (field.intraEntityCalculation === undefined) return undefined
            return buildIntraCalcNodeAndEdges(field, i, field.intraEntityCalculation, currentEntityConfig, nodePositions, operationRefs, collapsedNodes)
        })
        .filter(Boolean)
}

const mapConfigAndRelationshipsToNodesAndEdgesInter = (
    currentEntityConfig: EntityConfig,
    entityConfigs: EntityConfig[],
    entityRelationships: EntityRelationship[],
    nodePositions: EntityFlowNodePosition[],
    collapsedNodes: string[]
): { node: CalculationFlowNode; edges: EntityCalculationFlowEdge[] }[] => {
    const calculationRefs = new Map<EntityRelationshipCalculation, string>()
    return getCalculationsByEntityConfig(currentEntityConfig, entityRelationships).map(([targetFieldName, sourceCalculations], i) => {
        const targetField = currentEntityConfig.fields.find(f => f.fieldName === targetFieldName)
        return buildInterCalcNodeAndEdges(
            targetFieldName,
            targetField,
            i,
            currentEntityConfig,
            entityConfigs,
            nodePositions,
            sourceCalculations,
            calculationRefs,
            collapsedNodes
        )
    })
}

const stringifyInterEntityCalculationFilters = (filters: GenericFilter[], fields: EntityDataField[] = []) => {
    return filters
        .map(f => {
            const displayName = fields.find(cf => cf.fieldName === f.fieldName)?.displayName ?? f.fieldName
            switch (f.type) {
                case FilterTypesByField.TEXT_FIELD_IS_ONE_OF:
                case FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF:
                case FilterTypesByField.NUMBER_FIELD_IS_ONE_OF:
                case FilterTypesByField.DATE_FIELD_IS_ONE_OF:
                    return displayName + " is " + (f.notOneOf ? "not " : "") + f.values.join(", OR ")
                case FilterTypesByField.TEXT_FIELD_STARTS_WITH:
                    return displayName + " starts with " + f.values.join(", OR ")
                case FilterTypesByField.BOOLEAN_FIELD_IS_EQUAL_TO:
                    return displayName + " is " + JSON.stringify(f.equalTo)
                case FilterTypesByField.NUMBER_FIELD_MATCHES_OPERATION:
                case FilterTypesByField.DATE_FIELD_MATCHES_OPERATION:
                    return (
                        displayName +
                        " is " +
                        f.values
                            .map(
                                v =>
                                    (v.greaterThan && "greater than " + v.greaterThan) ||
                                    (v.greaterThanOrEqualTo && "greater than or equal to " + v.greaterThanOrEqualTo) ||
                                    (v.lessThan && "less than " + v.lessThan) ||
                                    (v.lessThanOrEqualTo && "less than or equal to " + v.lessThanOrEqualTo)
                            )
                            .join("|")
                    )
                case FilterTypesByField.FIELD_EXISTS:
                    return displayName + (f.notExists ? " doesn't exist" : " exists")
                case FilterTypesByField.NO_TYPE:
                default:
                    return displayName
            }
        })
        .join(", ")
}

export const getCalcNodeRef = (entityConfigRef: string, fieldName: string, isIntra: boolean): string =>
    `CALCULATION~${isIntra ? "intra" : "inter"}~${entityConfigRef}~${fieldName}`

const buildInterCalcNodeAndEdges = (
    targetFieldName: string,
    targetField: EntityDataField | undefined,
    positionIndex: number = 1,
    currentEntityConfig: EntityConfig,
    entityConfigs: EntityConfig[],
    nodePositions: EntityFlowNodePosition[],
    calculationsBySourceEntity: { sourceEntityReference: string; calculation: EntityRelationshipCalculation }[],
    calculationRefs: Map<EntityRelationshipCalculation, string>,
    collapsedNodes: string[]
): { node: CalculationFlowNode; edges: EntityCalculationFlowEdge[] } => {
    const nodeRef = getCalcNodeRef(currentEntityConfig.reference, targetFieldName, false)
    const nodePos = nodePositions.find(p => p.nodeRef === nodeRef)
    const outputEdge: EntityCalculationFlowEdge = {
        type: "calculation",
        sourceNodeReference: nodeRef,
        sourceNodeHandleReference: nodeRef,
        targetNodeReference: currentEntityConfig.reference,
        targetNodeHandleReference: targetFieldName + "-left"
    }
    return {
        node: {
            type: "calculation",
            targetField: targetFieldName,
            displayName: targetField?.displayName ?? targetFieldName,
            reference: nodeRef,
            description: "",
            x: nodePos?.x ?? positionIndex * 50,
            y: nodePos?.y ?? 0,
            collapsed: collapsedNodes.includes(nodeRef),
            calculations: calculationsBySourceEntity.map(c => {
                const calcRef = uuid()
                calculationRefs.set(c.calculation, calcRef)
                const sourceFieldDisplayName =
                    entityConfigs.find(e => e.reference === c.sourceEntityReference)?.fields.find(f => f.fieldName === c.calculation.sourceFieldName)
                        ?.displayName ?? c.calculation.sourceFieldName
                return {
                    ref: calcRef,
                    sourceField: c.calculation.sourceFieldName,
                    sourceEntity: c.sourceEntityReference,
                    type: "inter",
                    operation: c.calculation.calculationType,
                    label: `${c.calculation.calculationType} of ${sourceFieldDisplayName} ${
                        c.calculation.filters.length > 0 ? "where " + stringifyInterEntityCalculationFilters(c.calculation.filters) : ""
                    }`
                }
            })
        },
        edges: [
            ...calculationsBySourceEntity.map(
                (c): EntityCalculationFlowEdge => ({
                    type: "calculation",
                    sourceNodeReference: c.sourceEntityReference,
                    sourceNodeHandleReference: c.calculation.sourceFieldName,
                    targetNodeReference: nodeRef,
                    targetNodeHandleReference: (calculationRefs.get(c.calculation) ?? "") + "-left"
                })
            ),
            outputEdge
        ]
    }
}

const buildIntraCalcNodeAndEdges = (
    field: EntityDataField,
    positionIndex: number = 1,
    calc: IntraEntityCalculation,
    currentEntityConfig: EntityConfig,
    nodePositions: EntityFlowNodePosition[],
    operationRefs: Map<IntraEntityCalculationOperation, string>,
    collapsedNodes: string[]
): { node: CalculationFlowNode; edges: EntityCalculationFlowEdge[] } => {
    const nodeRef = getCalcNodeRef(currentEntityConfig.reference, field.fieldName, true)
    const nodePos = nodePositions.find(p => p.nodeRef === nodeRef)
    return {
        node: {
            type: "calculation",
            targetField: field.fieldName,
            displayName: field.displayName,
            reference: nodeRef,
            description: field.description ?? "",
            x: nodePos?.x ?? positionIndex * 50,
            y: nodePos?.y ?? 0,
            collapsed: collapsedNodes.includes(nodeRef),
            calculations: calc.operations.map(o => {
                const targetDisplayName =
                    (o.fieldName && currentEntityConfig.fields.find(f => f.fieldName === o.fieldName)?.displayName) ?? o.fieldName ?? o.hardcodedValue
                const calcRef = uuid()
                operationRefs.set(o, calcRef)
                return {
                    ref: calcRef,
                    type: "intra",
                    sourceField: calc.baseField,
                    sourceEntity: currentEntityConfig.reference,
                    operation: o.operation,
                    ordinal: o.ordinal,
                    hardcodedValue: o.hardcodedValue,
                    label:
                        (o.operation ?? "") +
                        (o.operation &&
                        (o.operation === IntraEntityCalculationOperationEnum.MULTIPLY || o.operation === IntraEntityCalculationOperationEnum.DIVIDE)
                            ? " by "
                            : " ") +
                        targetDisplayName
                }
            })
        },
        edges: [
            ...calc.operations
                .filter(o => o.hardcodedValue === undefined)
                .map(
                    (o): EntityCalculationFlowEdge => ({
                        type: "calculation",
                        sourceNodeReference: currentEntityConfig.reference,
                        sourceNodeHandleReference: o.fieldName ?? "",
                        targetNodeReference: nodeRef,
                        targetNodeHandleReference: (operationRefs.get(o) ?? "") + "-left"
                    })
                ),
            {
                type: "calculation",
                sourceNodeReference: nodeRef,
                sourceNodeHandleReference: nodeRef,
                targetNodeReference: currentEntityConfig.reference,
                targetNodeHandleReference: field.fieldName + "-left"
            }
        ]
    }
}

const getCalculationsByEntityConfig = (currentEntityConfig: EntityConfig, entityRelationships: EntityRelationship[]) => {
    return Array.from(
        entityRelationships
            .filter(r => r.calculations.length > 0 && r.parentEntityReference === currentEntityConfig.reference)
            .reduce((map, r) => {
                r.calculations.forEach(c => {
                    const index = c.destinationFieldName
                    const matchingEntityField = map.get(index) ?? []
                    matchingEntityField?.push({ sourceEntityReference: r.childEntityReference, calculation: c })
                    map.set(index, matchingEntityField)
                })
                return map
            }, new Map<string, { sourceEntityReference: string; calculation: EntityRelationshipCalculation }[]>())
            .entries()
    )
}
