import classes from './AdminUserCard.module.scss'

const AdminUserCard = ({ className, state }) => {
    return (
        <div className={`d-flex flex-column no-select ${className ? className : ''} ${classes.card} ${classes.shadow}`}>
            <div className="d-flex align-items-center m-2">
                <div className={`d-flex ${classes["profile-picture"]}`}>
                    <i className="fas fa-user fa-2x pb-1 mx-2 my-auto text-grey" />
                </div>
                <h5 className="mb-0 mx-2 text-grey text-truncate">{state.clientUsername}</h5>
            </div>
        </div>
    )
}

export default AdminUserCard