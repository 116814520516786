export const convertRoleToDto = role => ({
    name: role.name,
    customerPermission: role.customerPermission || null,
    userPermission: role.userPermission || null,
    automatePermission: role.automatePermission || null,
    brandPermission: role.brandPermission || null,
    calculatePermission: role.calculatePermission || null,
    connectPermission: role.connectPermission || null,
    dashboardPermission: role.dashboardPermission || null,
    dataConfigPermission: role.dataConfigPermission || null,
    integrationPermission: role.integrationPermission || null,
    payPermission: role.payPermission || null,
    taskPermission: role.taskPermission || null,
    makeCallPermission: role.makeCallPermission || null,
    assignedUsers: [...role.assignedUsers.map(user => ({
        clientUserId: user.clientUserId,
        permissions: {
            customerPermission: user.permissions.customerPermission || null,
        }
    }))]
})