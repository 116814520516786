import { connect } from 'react-redux';
import { useCustomer } from '../../../../contexts/CustomerContext';
import TransactionsGrid from './TransactionsGrid';
import { useFeatureToggle } from '../../../../hooks/useFeatureToggle';

const TransactionsPanel = ({ transactionIds, client }) => {
  const [customer, setCustomer] = useCustomer()
  const { isEnabled: defaultToThousand } = useFeatureToggle("defaultTransactionGridTo1000")

  return (
    <div id="transaction-panel">
      <div id="transactions-container" className={'container-fluid'}>
        <div className="row">
          <div className="col-12">
            <TransactionsGrid client={client} selectedTransactionIds={transactionIds} customer={customer} setCustomer={setCustomer} defaultToThousand={defaultToThousand} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    transactionIds: state.functionReducer.transactionIds
  };
};

export default connect(mapStateToProps)(TransactionsPanel);