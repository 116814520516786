import * as types from '../actions/actionTypes';

export default function refreshProcessReducer(state = [], action) {
    switch (action.type) {
        case types.REFRESH_PROCESS:
            return [...state, Object.assign({}, action.refreshProcess)];
        case types.LOAD_REFRESH_PROCESS:
            return [...state, Object.assign({}, action.refreshProcesses)];
        default:
            return state;
    }
}