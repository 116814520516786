import moment from "moment"
import classes from "./ScheduledTriggerNode.module.scss"
import Trigger from "../../types/Trigger"

type ScheduledTriggerNodeProps = {
    trigger: Trigger | undefined
}

const ScheduledTriggerNode = ({ trigger }: ScheduledTriggerNodeProps) => {
    const triggerTime = moment(`${trigger?.timeToTrigger.hour}:${trigger?.timeToTrigger.minute}`, "H:m").format("HH:mm")

    return (
        <div className="bg-grey p-2 border-top border-4 rounded-1 border-danger">
            <div className="d-flex align-items-center gap-2 me-3">
                <i className="fal fa-clock text-blue fs-5" />
                <span className="text-black fw-bold small text-capitalize fs-5">Trigger</span>
            </div>
            <div className={`${classes.separator} d-flex flex-row my-2 bg-dark-gradient`} />
            {trigger ? (
                <span>Automatically triggers at {triggerTime} everyday</span>
            ) : (
                <div className={"d-flex align-items-center no-select"}>
                    <i className="fal fa-exclamation-triangle text-warning me-2"></i>
                    <span>No trigger set for this workflow</span>
                </div>
            )}
        </div>
    )
}

export default ScheduledTriggerNode
