import React, { useState, useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { loadTransactionIds, selectTransaction } from '../../actions/functionActions';
import { executeAuthAsyncGet } from '../../utility/asyncSupport';
import useTransactionFilterOptions from '../../hooks/useTransactionFilterOptions';
import Select, { components } from 'react-select';
import { transactionFilterTypes } from '../../constants';
import {
  getCustomerTransactionsIds,
  getRecentPaymentTransactionsIds
} from '../../api/functionApi';
import { useClient } from 'invevo-react-components';

// handle options group header click event
// hide and show the options under clicked group
const handleHeaderClick = (id) => {
  const node = document.querySelector(`#${id}`).parentElement
    .nextElementSibling;
  const classes = node.classList;
  if (classes.contains('d-none')) {
    node.classList.remove('d-none');
  } else {
    node.classList.add('d-none');
  }
};

// Create custom GroupHeading component, which will wrap
// react-select GroupHeading component inside a div and
// register onClick event on that div
const CustomGroupHeading = (props) => {
  return (
    <div
      className="group-heading-wrapper"
      onClick={() => handleHeaderClick(props.id)}
    >
      <components.GroupHeading {...props} />
    </div>
  );
};

const TransactionFilter = ({
  onChange,
  refresh,
  accountId,
  isVirtualAccount,
  dateFormat,
  currencyFormat,
  selectTransaction,
  getCustomerTransactionsIds,
  getRecentPaymentTransactionsIds,
  passedInRef
}) => {
  const client = useClient()
  const [option, setOption] = useState(null);
  const options = useTransactionFilterOptions(
    accountId,
    dateFormat,
    currencyFormat,
    refresh
  );

  useImperativeHandle(passedInRef, () => ({
    clear: () => setOption(null)
  }));

  const localOnChange = (option) => {
    setOption(option);
    onChange(option);

    // clear before change
    selectTransaction([]);

    if (option === null) {
      return;
    }

    const request = {
      customerid: accountId,
      isVirtualAccount,
      gridType: 2,
      isClosed: false
    };

    switch (option.parentId) {
      case transactionFilterTypes.agedDebt:
        getCustomerTransactionsIds(
          client,
          {
          ...request,
          currency: option.Currency,
          dateType: option.Group
        });
        break;
      case transactionFilterTypes.fallingDue:
        getCustomerTransactionsIds(
          client,
          {
          ...request,
          currency: option.Currency,
          dateType: option.Group
        });
        break;
      case transactionFilterTypes.recentPayment:
        getRecentPaymentTransactionsIds(client, option.Id, accountId);
        break;
      case transactionFilterTypes.transactionStatus:
        getCustomerTransactionsIds(
          client,
          {
          ...request,
          currency: option.Currency,
          transactionStatusId: option.Group
        });
        break;
      case transactionFilterTypes.transactionType:
        getCustomerTransactionsIds(
          client,
          {
          ...request,
          currency: option.Currency,
          transactionTypeId: option.Group
        });
        break;
      default:
        break;
    }
  };

  return (
    <Select
      placeholder="Filter By"
      options={options}
      value={option}
      components={{
        GroupHeading: CustomGroupHeading
      }}
      onChange={localOnChange}
      isClearable
    />
  );
};

const mapStateToProps = (state) => {
  return {
    accountId: state.currentSelectionReducer.selectedAccount.miaAccountId,
    isVirtualAccount:
      state.currentSelectionReducer.selectedAccount.miaAccountIsVirtualAccount,
    dateFormat: state.lookupReducer.globalSettings.results.dateFormat,
    currencyFormat: state.lookupReducer.globalSettings.results.currencyFormat
  };
};

export default connect(mapStateToProps, {
  executeAuthAsyncGet,
  loadTransactionIds,
  selectTransaction,
  getCustomerTransactionsIds,
  getRecentPaymentTransactionsIds
})(TransactionFilter);
