import { Toggle } from "invevo-react-components"
import { convertToCurrency } from "../../../../library/helpers/numberUtils"
import { AccountDto } from "../../types/dtos/AccountDto"

type BankAccountProps = {
    account: AccountDto
    isSelected: boolean
    onSelectionToggled: (account: AccountDto) => void
}

const BankAccount = ({ account, isSelected, onSelectionToggled }: BankAccountProps) => {
    const isCurrentDebit = account.balance.current?.creditDebitIndicator === "Debit"
    const isAvailableDebit = account.balance.available?.creditDebitIndicator === "Debit"

    const onToggleClick = () => {
        onSelectionToggled(account)
    }

    return (
        <div className="d-flex align-items-center text-grey w-100">
            <span>{account.metadata.accountHolderNames}</span>
            <span className="ms-auto me-2">Available:</span>
            <span className={`fw-bold ${isAvailableDebit ? "text-red" : ""}`}>
                {isAvailableDebit ? "-" : ""}
                {convertToCurrency(account.balance.available?.amount ?? 0, account.metadata.currencyCode)}
            </span>
            <i className="mx-2 fas fa-pipe"></i>
            <span className="me-2">Current:</span>
            <span className={`fw-bold ${isCurrentDebit ? "text-red" : ""}`}>
                {isCurrentDebit ? "-" : ""}
                {convertToCurrency(account.balance.current?.amount ?? 0, account.metadata.currencyCode)}
            </span>
            <i className="mx-2 fas fa-pipe"></i>
            <Toggle className="" status={isSelected} onStatusChanged={onToggleClick} />
        </div>
    )
}

export default BankAccount
