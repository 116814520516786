import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getApiUrl } from '../../../constants';
import { storeGridState } from '../../../actions/gridCacheActions';

import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../../common/grids/StatefulGrid';
import { ColumnMenuFilter } from '../../common/grids/columnMenu';
import FileImporterLogErrorsGrid from './FileImportLogErrorsGrid';
import { normaliseFormatDef } from '../../../selectors/lookup';
import existential from '../../../utility/existential';

class FileImporterLogGrid extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      selectedItemId: '',
      columns: [
        { field: 'Id', title: 'Id' },
        { field: 'FileName', title: 'File Name' },
        { field: 'FileType', title: 'File Type' },
        { field: 'Instance', title: 'Instance' },
        { field: 'Status', title: 'Status' },
        {
          field: 'Detected',
          title: 'Detected',
          format: this.props.dateTimeFormat
        },
        {
          field: 'ImportStarted',
          title: 'Import Started',
          format: this.props.dateTimeFormat
        },
        {
          field: 'ImportFinished',
          title: 'Import Finished',
          format: this.props.dateTimeFormat
        },
        { field: 'Records', title: 'Records' },
        { field: 'Processed', title: 'Processed' },
        { field: 'Errors', title: 'Errors' },
        { field: 'Warnings', title: 'Warnings' }
      ]
    };
  }

  isSelectedRow = (dataItem) => this.state.selectedItemId === dataItem.Id;

  onStateChange = (data) => {
    this.props.onStoreGridState(FileImporterLogGrid.getKey(), data);
  };

  onRowClick = (gridRowEvent) => {
    const dataItem = gridRowEvent.dataItem;
    if (this.props.setCustomerGrdId && dataItem) {
      this.setState({ selectedItemId: dataItem.Id });
    } else {
      this.setState({ selectedItemId: '' });
    }
  };

  innerGrid = (props) => <FileImporterLogErrorsGrid {...props} client={this.props.client} />;

  static getKey() {
    return 'file-importer-log-grid-key';
  }

  render() {
    return (
      <StatefulGrid
        client={this.props.client}
        id={FileImporterLogGrid.getKey()}
        key={FileImporterLogGrid.getKey()}
        path={getApiUrl(this.props.client) + 'api/fileimporterlog/list'}
        sortable
        className={'scrollable-none'}
        pageable={{
          pageSizes: [10, 50, 100],
          buttonCount: 5
        }}
        hideablePaging={true}
        onRowClick={this.onRowClick}
        expandableRow={{
          detail: this.innerGrid,
          id: 'Id',
          type: 'multiple'
        }}
        isSelectedRow={this.isSelectedRow}
        columnMenu={ColumnMenuFilter}
        defaultField={{ field: 'Id', dir: 'desc' }}
      >
        {this.state.columns.map((column) => (
          <GridColumn key={column.field} {...column} />
        ))}
      </StatefulGrid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gridState: existential(
      state.gridCacheReducer.grids[FileImporterLogGrid.getKey()],
      'gridState',
      {}
    ),
    dateTimeFormat: normaliseFormatDef(
      state.lookupReducer.globalSettings.results.dateTimeFormat
    )
  };
};

export default connect(mapStateToProps, {
  onStoreGridState: storeGridState
})(FileImporterLogGrid);
