import ListCard from "../../../../../../../library/listCards/ListCard"
import { IconButton } from "invevo-react-components"
import { XmlEntityAggregatedExportNodeState } from "../../../../../reducers/FileEntityExportConfigReducer"
import XmlExportFieldConfigCard from "./XmlExportAggregatedFieldConfigCard"
import EntityDataField from "../../../../../../entityConfig/types/EntityDataField"
import classes from "../XmlExportFieldConfig.module.scss"

type XmlExportAggregatedFieldConfigProps = {
    state: {
        root: XmlEntityAggregatedExportNodeState
        onChanged: (node: XmlEntityAggregatedExportNodeState) => void
        onDeleted: (node: XmlEntityAggregatedExportNodeState) => void
        editingNode?: XmlEntityAggregatedExportNodeState
        onEditingNodeChanged: (id: XmlEntityAggregatedExportNodeState) => void
        dataFields: EntityDataField[]
        saveEditingConfig: () => void
    }
}
const XmlExportAggregatedFieldConfig = ({ state }: XmlExportAggregatedFieldConfigProps) => {
    const { root, onChanged, onDeleted, editingNode, onEditingNodeChanged, dataFields, saveEditingConfig } = state

    const body = (node: XmlEntityAggregatedExportNodeState) => {
        const onEdit = () => onEditingNodeChanged(node)
        const onDelete = () => onDeleted(node)

        return (
            <div className="d-flex align-items-center text-white">
                <i className={`fa-light ${node.type === "parent" ? "fa-folder-tree" : "fa-memo-pad"} fa-2x`}></i>
                <span className="ms-3 fs-4 text-wrap" aria-label="xml node">
                    {node.name}
                </span>
                <div className="d-flex ms-auto">
                    <IconButton
                        className="ms-3"
                        colour="blue"
                        iconClasses="far fa-edit"
                        onClick={onEdit}
                        ariaLabel="Edit the File Export Config node"
                        disabled={editingNode !== undefined}
                    />
                    <IconButton
                        className="ms-3"
                        colour="blue"
                        iconClasses="far fa-trash"
                        onClick={onDelete}
                        ariaLabel="Delete the File Export Config node"
                        disabled={editingNode !== undefined}
                    />
                </div>
            </div>
        )
    }
    const renderRecursively = (onChanged: (id: XmlEntityAggregatedExportNodeState) => void) => {
        const inner = (node: XmlEntityAggregatedExportNodeState) => {
            switch (node.type) {
                case "value":
                case "timestamp":
                case "aggregation":
                    return node.key === editingNode?.key ? (
                        <XmlExportFieldConfigCard
                            key={node.key}
                            state={{
                                config: editingNode,
                                dataFields: dataFields,
                                saveEditingConfig,
                                onEditingNodeChanged
                            }}
                        />
                    ) : (
                        <ListCard key={node.key} className="m-1" isSelected={false} onSelect={() => {}}>
                            {body(node)}
                        </ListCard>
                    )

                case "parent":
                    return (
                        <div key={node.key} className={"d-flex flex-column"}>
                            {node.key === editingNode?.key ? (
                                <XmlExportFieldConfigCard
                                    state={{
                                        config: editingNode,
                                        dataFields: dataFields,
                                        onEditingNodeChanged,
                                        saveEditingConfig
                                    }}
                                />
                            ) : (
                                <ListCard
                                    className="m-1"
                                    isSelected={node.selected && node.children.length > 0}
                                    onSelect={() => {
                                        if (editingNode) return
                                        onChanged({ ...node, selected: !node.selected })
                                    }}
                                >
                                    {body(node)}
                                </ListCard>
                            )}
                            {node.key === editingNode?.key && editingNode.type === "parent" ? (
                                <div className={`d-flex flex-column ps-1 ${classes.border} ms-2`}>
                                    {editingNode.children.map((child: XmlEntityAggregatedExportNodeState) => inner(child))}
                                </div>
                            ) : (
                                node.selected && (
                                    <div className={`d-flex flex-column ps-1 ${classes.border} ms-2 ${node.selected ? classes.darkCard : ""}`}>
                                        {node.children.map((child: XmlEntityAggregatedExportNodeState) => inner(child))}
                                    </div>
                                )
                            )}
                        </div>
                    )
            }
        }
        return inner
    }

    return <div className={"d-flex"}>{renderRecursively(onChanged)(root)}</div>
}

export default XmlExportAggregatedFieldConfig
