import React from "react"
import { IconButton, Loading, SelectableCard, useClient, useConfig } from "invevo-react-components"
import classes from "./FileImportConfigCard.module.scss"
import { FileImportConfigActionEnum } from "../../../reducers/FileEntityImportConfigReducer"
import { FileImportConfigState, FileImportConfigAction } from "../../../reducers/FileEntityImportConfigReducer"
import useApiQuery from "../../../../../hooks/useApiQuery"
import EntityConfig from "../../../../entityConfig/types/EntityConfig"

type FileImportConfigCardProps = {
    config: FileImportConfigState
    dispatch: React.Dispatch<FileImportConfigAction>
    entities: EntityConfig[]
    isSelected: Boolean
}

const FileImportConfigCard = ({ config, dispatch, entities, isSelected }: FileImportConfigCardProps) => {
    const client = useClient()
    const apiConfig = useConfig()

    const deleteConfigRequest = useApiQuery({
        url: `${apiConfig.INTEGRATION_API_URL}/api/${client}/file-import-config/${config.reference}`,
        method: "DELETE",
        isExecutedAutomatically: false
    })

    const onDeleteClick = () => {
        deleteConfigRequest.execute().then(() =>
            dispatch({
                type: FileImportConfigActionEnum.FILE_IMPORT_CONFIG_DELETED,
                reference: config.reference
            })
        )
    }

    const body = (
        <div className="d-flex align-items-center text-white">
            <i className="fa-light fa-memo-pad fa-2x"></i>
            <span className="ms-3 fs-4 text-wrap" aria-label="filename pattern">
                {config.dataFileImportConfig.configName}
            </span>
            <div className="d-flex ms-auto">
                <Loading isLoading={deleteConfigRequest.isFetching} colour="white" size="sm">
                    <IconButton className="ms-auto" colour="blue" iconClasses="far fa-trash" onClick={onDeleteClick} ariaLabel="Delete File Import Config" />
                </Loading>
            </div>
        </div>
    )

    const footer = (
        <div className="d-flex flex-column">
            <div className="d-flex">
                <div className="d-flex flex-column me-3">
                    <span className={`text-light-blue text-uppercase small`}>File Type</span>
                    {config.dataFileImportConfig.entityType && (
                        <div className="d-flex align-items-center no-select">
                            <span className={`${classes["customer-label"]} px-2`}>
                                {entities.find(entity => entity.reference === config.dataFileImportConfig.entityType)?.displayName}
                            </span>
                        </div>
                    )}
                </div>
                <div className="d-flex flex-column">
                    <span className={`text-light-blue text-uppercase small`}>File Format</span>
                    <span className="fw-bold">{config.fileFormat}</span>
                </div>
            </div>
        </div>
    )

    const onCardClick = () => {
        if (isSelected) return
        dispatch({
            type: FileImportConfigActionEnum.FILE_IMPORT_CONFIG_SELECTED,
            reference: config.reference
        })
    }

    return (
        <div className={`${isSelected ? `${classes.selected} shadow` : ""}`} aria-label="File Import Config Card">
            <SelectableCard body={body} footer={footer} isSelected={isSelected} onClick={onCardClick} />
        </div>
    )
}

export default FileImportConfigCard
