import Actions from "./actionsSetup/Actions"
import Lookup from "../../../../types/Lookup"
import ScheduledWorkflowRule from "../../types/ScheduledWorkflowRule"
import GenericFilter from "../../../../library/FilterList/filterTypes/GenericFilter"
import GenericAction from "../../types/actions/GenericAction"
import DataField from "../../../../types/DataField"
import LegacySmsTemplate from "../../types/LegacySmsTemplate"
import Trigger from "../../types/Trigger"
import FilterList from "../../../../library/FilterList/FilterList"
import { FileEntityExportConfig } from "../../../integration/types/fileEntityExport/FileExportConfig"
import EntityRelationshipDto from "../../../entityConfig/types/EntityRelationshipDto"
import EntityConfig from "../../../entityConfig/types/EntityConfig"
import TriggerSetup from "./TriggerSetup"
import FileImportConfig from "../../types/FileImportConfig"
import { WorkflowTriggerConfig } from "../../types/WorkflowTriggerConfigs"

type ScheduledWorkflowSetupProps = {
    entityConfigFields: { entity: string; fields: DataField[] }[]
    lookups: Lookup[]
    customerFields: DataField[]
    fileEntityExportConfigs: FileEntityExportConfig[]
    smsTemplates: LegacySmsTemplate[]
    selectedRule: ScheduledWorkflowRule
    entityRelationships: EntityRelationshipDto[]
    entityConfigs: EntityConfig[]
    isNewEntityStructureEnabled: boolean
    onRuleUpdated: (updatedRule: ScheduledWorkflowRule) => void
    onWorkflowTriggerConfigsUpdated: (workflowTriggerConfigs: WorkflowTriggerConfig[]) => void
    fileImportConfigs: FileImportConfig[]
    scheduledRules: ScheduledWorkflowRule[]
    workflowTriggerConfigs: WorkflowTriggerConfig[]
}

const ScheduledWorkflowSetup = ({
    entityConfigFields,
    lookups,
    customerFields,
    fileEntityExportConfigs,
    smsTemplates,
    selectedRule,
    entityRelationships,
    entityConfigs,
    isNewEntityStructureEnabled,
    onRuleUpdated,
    onWorkflowTriggerConfigsUpdated,
    fileImportConfigs,
    scheduledRules,
    workflowTriggerConfigs
}: ScheduledWorkflowSetupProps) => {
    const onRuleTriggerChanged = (trigger: Trigger | undefined, workflowTriggerConfigs: WorkflowTriggerConfig[]) => {
        onRuleUpdated({ ...selectedRule, trigger })
        onWorkflowTriggerConfigsUpdated(workflowTriggerConfigs)
    }
    const onRuleFiltersChanged = (filters: GenericFilter[]) => onRuleUpdated({ ...selectedRule, baseFilters: filters })
    const onRuleActionsChanged = (actions: GenericAction[]) => onRuleUpdated({ ...selectedRule, actions })

    const fieldsToUse = isNewEntityStructureEnabled
        ? entityConfigFields.find(pair => pair.entity === selectedRule.entityConfigReference)?.fields ?? []
        : customerFields

    return (
        <div className="d-flex flex-column flex-grow-1 m-3 p-3 bg-grey overflow-auto rounded">
            <div className="m-2">
                <TriggerSetup
                    ruleTrigger={selectedRule.trigger}
                    onRuleTriggerChanged={onRuleTriggerChanged}
                    fileImportConfigs={fileImportConfigs}
                    scheduledRules={scheduledRules}
                    selectedRule={selectedRule}
                    workflowTriggerConfigs={workflowTriggerConfigs}
                />
            </div>
            <hr />
            <div className="d-flex flex-column m-2">
                <div className="d-flex justify-content-between mb-3">
                    <h2 className="text-grey mb-0">Evaluation group</h2>
                </div>
                <FilterList
                    key={selectedRule.reference}
                    fields={fieldsToUse}
                    lookups={lookups}
                    appliedFilters={selectedRule.baseFilters}
                    onFiltersApplied={onRuleFiltersChanged}
                    autoApply={true}
                    showRelativeDateOptions={true}
                    showFieldIsOneOfOptions={true}
                />
            </div>
            <hr />
            <div className="m-2">
                <Actions
                    selectedRule={selectedRule}
                    smsTemplates={smsTemplates}
                    fields={fieldsToUse}
                    lookups={lookups}
                    fileEntityExportConfigs={fileEntityExportConfigs}
                    onRuleActionsChanged={onRuleActionsChanged}
                    entityRelationships={entityRelationships}
                    entityConfigs={entityConfigs}
                    isNewEntityStructureEnabled={isNewEntityStructureEnabled}
                />
            </div>
        </div>
    )
}

export default ScheduledWorkflowSetup
