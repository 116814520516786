import { ReactNode, createContext, useContext } from "react"
import EnvironmentConfig from "../library/config/EnvironmentConfig"

const EnvironmentConfigContext = createContext<EnvironmentConfig | undefined>(undefined)

type Props = {
    environmentConfig: EnvironmentConfig
    children: ReactNode
}

const EnvironmentConfigProvider = ({ environmentConfig, children }: Props) => (
    <EnvironmentConfigContext.Provider value={environmentConfig} children={children} />
)

const useEnvConfig = () => {
    const context = useContext(EnvironmentConfigContext)

    if (!context) throw new Error(`useEnvConfig must be used within a <EnvironmentProvider>`)
    return context
}

export { useEnvConfig, EnvironmentConfigProvider }
