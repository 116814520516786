import { Loading, useClient, useConfig } from "invevo-react-components"
import { useEffect, useReducer, useState } from "react"
import { FileImportConfigActionEnum } from "../../reducers/FileImportConfigReducer"
import FileImportConfigSetup from "./fileImportConfigSetup/FileImportConfigSetup"
import FileImportConfigs from "./fileImportConfigs/FileImportConfigs"
import FileImportConfigReducer from "../../reducers/FileImportConfigReducer"
import useApiQuery from "../../../../hooks/useApiQuery"
import { GetFileImportConfigDto } from "../../types/dtos/FileImportConfigDto"
import { mapGetFileImportConfigDtoToDomain, mapDataFieldsDtoToDomain, mapDomainToPutFileImportConfigDto } from "../utils"
import { DataFieldDto } from "../../../../types/dtos/DataFieldDto"
import classes from "./FileImportConfiguration.module.scss"
import ProgressButton from "../../../../library/buttons/ProgressButton/ProgressButton"
import { FileImportConfig } from "../../types/FileImportConfig"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"

const FileImportConfiguration = () => {
    const [state, dispatch] = useReducer(FileImportConfigReducer, {
        configs: [],
        dataFields: [],
        editingConfig: undefined
    })

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

    const client = useClient()
    const config = useConfig()

    const fileImportConfigRequest = useApiQuery<GetFileImportConfigDto[]>({
        url: `${config.INTEGRATION_API_URL}/api/${client}/file-import-config`,
        method: "get",
        onSuccess: data =>
            dispatch({
                type: FileImportConfigActionEnum.FILE_IMPORT_CONFIGS_RETRIEVED,
                configs: mapGetFileImportConfigDtoToDomain(data)
            })
    })

    const dataFieldsRequest = useApiQuery({
        url: `${config.DATA_API_URL}/api/${client}/data-field`,
        method: "get",
        onSuccess: (data: DataFieldDto[]) =>
            dispatch({
                type: FileImportConfigActionEnum.DATA_FIELDS_RETRIEVED,
                dataFields: mapDataFieldsDtoToDomain(data)
            })
    })

    const saveFileImportConfigRequest = useApiQuery<GetFileImportConfigDto[]>({
        url: `${config.INTEGRATION_API_URL}/api/${client}/file-import-config`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    useEffect(() => {
        const originalConfig = state.configs.find(config => config.reference === state.editingConfig?.reference)
        if (!originalConfig) {
            setHasUnsavedChanges(false)
            return
        }

        const hasChanges = JSON.stringify(originalConfig) !== JSON.stringify(state.editingConfig)
        setHasUnsavedChanges(hasChanges)
    }, [state.configs, state.editingConfig])

    const onDiscardChangesClick = () => {
        dispatch({
            type: FileImportConfigActionEnum.FILE_IMPORT_EDITING_CONFIG_DISCARDED
        })
    }
    const onSaveChangesClick = () => {
        const dto = mapDomainToPutFileImportConfigDto(state.editingConfig as FileImportConfig)
        return saveFileImportConfigRequest.execute(state.editingConfig?.reference, dto).then(res => {
            dispatch({
                type: FileImportConfigActionEnum.FILE_IMPORT_EDITING_CONFIG_SAVED
            })

            return res
        })
    }

    return (
        <div className="d-flex w-100 h-100">
            <Loading isLoading={fileImportConfigRequest.isFetching || dataFieldsRequest.isFetching} colour="blue">
                <FileImportConfigs state={state} dispatch={dispatch} />
                <div className="d-flex flex-column flex-grow-1">
                    <div className="flex-grow-1 p-4 overflow-auto">
                        {state.editingConfig ? (
                            <FileImportConfigSetup state={{ editingConfig: state.editingConfig, dataFields: state.dataFields }} dispatch={dispatch} />
                        ) : (
                            <span className="text-grey fs-4">Please select a configuration for editing or create a new one</span>
                        )}
                    </div>
                    {state.editingConfig && (
                        <div className={`d-flex bg-white p-4 mt-auto ${classes.footer} align-items-center border-top border-2`}>
                            <StandardButton
                                className="ms-auto"
                                iconClasses="far fa-undo"
                                label="Discard changes"
                                onClick={onDiscardChangesClick}
                                disabled={!hasUnsavedChanges}
                            />
                            <ProgressButton
                                className="ms-3"
                                iconClasses="far fa-save"
                                label="Save changes"
                                onClickWithPromise={onSaveChangesClick}
                                disabled={!hasUnsavedChanges}
                            />
                        </div>
                    )}
                </div>
            </Loading>
        </div>
    )
}

export default FileImportConfiguration
