import classes from './SelectPaymentCard.module.scss'

const SelectPaymentCard = ({onPaymentCardSelected, brand, amount, label = '' }) => {
    const iconClass = getIconClass(brand)    
    return (
        amount.total <= 0 ?
        <></>
        :
        <div onClick={() => onPaymentCardSelected(amount)} className={`d-flex ${classes['action-button']} pointer no-select align-items-center`}>
            <div className={`${classes.icon} py-2 text-center`}>
                <i className={iconClass}></i>
            </div>
            <div className={`d-flex align-self-stretch flex-grow-1 px-3 ${classes.label}`}>
                <span className="font-weight-bold my-auto">Pay {label !== '' ? `${label} ` : ''}{formatCurrencyAmount(amount.total, "en-GB", amount.currency)} securely using {brand}</span>
            </div>
        </div>
    )
}

const formatCurrencyAmount = (value, culture, currencySymbol) => new Intl.NumberFormat(culture, { style: 'currency', currency: currencySymbol }).format(value)
const getIconClass = (brand) => {
    switch(brand.toLowerCase()) {
        case 'visa':
            return 'fab fa-cc-visa'
        case 'mastercard':
            return 'fab fa-cc-mastercard'
        case 'amex':
            return 'fab fa-cc-amex'
        default:
            return 'fal fa-credit-card'
    }
}

export default SelectPaymentCard