/**
 * @deprecated This enum is for the old data fields. Try to replace with DataPrimitiveTypeEnum for entity structure.
 */
enum DataPrimitive {
    TEXT = "TEXT",
    DATE = "DATE",
    BOOLEAN = "BOOLEAN",
    NUMBER = "NUMBER",
    LOOKUP = "LOOKUP"
}

export default DataPrimitive

export const getBackgroundColourForDataType = (type: DataPrimitive) => {
    switch (type) {
        case DataPrimitive.DATE:
            return "#e3e6ff"
        case DataPrimitive.NUMBER:
            return "#ffddfc"
        case DataPrimitive.LOOKUP:
            return "#cdf7e5"
        case DataPrimitive.BOOLEAN:
            return "#ffccd3"
        case DataPrimitive.TEXT:
            return "#d9f7ff"
        default:
            return "white"
    }
}

export const getForegroundColourForDataType = (type: DataPrimitive) => {
    switch (type) {
        case DataPrimitive.DATE:
            return "#4754cc"
        case DataPrimitive.NUMBER:
            return "#b83aad"
        case DataPrimitive.LOOKUP:
            return "#73bf9d"
        case DataPrimitive.BOOLEAN:
            return "#ee4057"
        case DataPrimitive.TEXT:
        default:
            return "#47a5bd"
    }
}
