import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getStatusSubStatus } from '../../api/functionApi';
import StatefulSelect from '../common/statefulSelect';
import { uniqueStatus } from '../../selectors/transactionStatus';
import { useClient } from 'invevo-react-components';

const StatusSelect = ({ id, onChange, getStatusSubStatus, status }) => {
  const client = useClient()
  useEffect(() => {
    if (!status || status.length === 0) getStatusSubStatus(client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (id) => {
    const selectedStatus = status.find((s) => s.value === id);
    onChange(id, selectedStatus.showDate, selectedStatus);
  };
  return (
    <StatefulSelect options={status} optionId={id} onChange={handleOnChange} />
  );
};

StatusSelect.propTypes = {
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  status: uniqueStatus(state.functionReducer.statusSubStatus)
});

export default connect(mapStateToProps, { getStatusSubStatus })(StatusSelect);
