import classes from "./DataRow.module.scss"
import { useFeatureToggle } from "../../../hooks/useFeatureToggle"
import { filterLookupEntriesByParent } from "../../../routes/entitySearch/helpers"
import Checkbox from "../../form/Checkbox"
import Grid, { useGrid } from "./../Grid"
import DataCell from "./DataCell"
import RowData from "./../types/RowData"
import { getValueForHeader } from "./../util"
import { ReactNode, useState } from "react"
import EntityConfig from "../../../routes/entityConfig/types/EntityConfig"
import InlineTransactionNotesGrid from "./InlineTransactionNotesGrid"

type Props = {
    rowData: RowData
    optimisticData: RowData | undefined
    isSelectable: boolean
    entityConfig?: EntityConfig
    toggleSelectSingle: (reference: string) => void
    renderRowActions?: (rowData: RowData) => ReactNode
    canWrap?: boolean
}

const DataRow = ({ rowData, optimisticData, isSelectable, entityConfig, toggleSelectSingle, renderRowActions, canWrap = false }: Props) => {
    const grid = useGrid()

    const entityFields = grid.displayedHeaders.map(field => ({
        fieldName: field.value,
        dataType: {
            lookupReference: field.lookupReference
        }
    }))

    const filterLookupsFeatureToggle = useFeatureToggle("filterLookupEntriesOnLookupParents")
    const filteredLookups = filterLookupsFeatureToggle.isEnabled
        ? filterLookupEntriesByParent(grid.lookups, entityFields, { ...rowData.fields.textFields, ...(optimisticData?.fields.textFields ?? {}) })
        : grid.lookups

    const isSelected = grid.selection.selectedRefs.includes(rowData.reference)

    const onRowToggled = () => toggleSelectSingle(rowData.reference)

    const [isShowingNotes, setIsShowingNotes] = useState(false)
    const shouldShowNotesToggle =
        entityConfig?.reference === "transaction" &&
        entityConfig?.fields.some(f => f.fieldName === "text_customer_reference" || f.fieldName === "text_customerreference")
    const showNotesToggle = () => {
        return (
            <Grid.ActionButton
                ariaLabelPrefix="toggle-notes"
                rowData={rowData}
                icon={isShowingNotes ? "fas fa-minus" : "fas fa-plus"}
                onClick={() => {
                    setIsShowingNotes(v => !v)
                }}
                selectOnClick={false}
            />
        )
    }

    return (
        <>
            <tr className={`${classes.tableRow} ${isSelected ? classes.selected : ""} position-relative`} role="row" aria-label="data-row">
                {isSelectable && (
                    <td className={`p-2 ${classes.roundedLeft}`}>
                        <Checkbox
                            checkboxClasses={classes.checkbox}
                            onClick={onRowToggled}
                            isChecked={isSelected}
                            ariaLabel={`checkbox-${rowData.reference}`}
                        />
                    </td>
                )}
                {grid.displayedHeaders.map((header, index) => {
                    const optimisticValue = optimisticData ? getValueForHeader(optimisticData, header)?.toString() : undefined
                    const rounding = index === 0 && !isSelectable ? classes.roundedLeft : ""
                    return (
                        <td key={`${rowData.reference}-${header.value}`} className={`${classes.dataItem} py-1 ps-1 ${rounding}`}>
                            <DataCell
                                rowReference={rowData.reference}
                                fieldValue={getValueForHeader(rowData, header)?.toString() ?? ""}
                                optimisticFieldValue={header.value === "reference" ? undefined : optimisticValue}
                                header={header}
                                filteredLookups={filteredLookups}
                                isEditable={header.isEditable ?? false}
                                entityConfig={entityConfig}
                                canWrap={canWrap}
                            />
                        </td>
                    )
                })}
                <td className={`${classes.stickRight} position-sticky d-flex flex-row pe-1 ${classes.roundedRight} justify-content-end`}>
                    {shouldShowNotesToggle && showNotesToggle()}
                    {renderRowActions && renderRowActions(rowData)}
                </td>
            </tr>
            {isShowingNotes && (
                <InlineTransactionNotesGrid
                    customerReference={rowData.fields.textFields.text_customer_reference ?? rowData.fields.textFields.text_customerreference ?? ""}
                    transactionReference={rowData.reference}
                />
            )}
        </>
    )
}

export default DataRow
