import { ReactNode, createContext, useContext, useState } from "react"
import useClient from "../hooks/useClient"
import useApiQuery from "../hooks/useApiQuery"
import RouteArea from "../routes/client/types/RouteArea"
import UserRole from "../types/UserRole"
import { useEnvConfig } from "./EnvironmentConfigContext"
import { useAuth } from "./AuthContext"

export type Permissions = typeof defaultPermissions

type ClientUserProfile = {
    userRoles: Array<UserRole>
}

const defaultPermissions = {
    isBasicUser: false,
    hasAdminPermission: false,
    hasCustomerPermission: false,
    hasUserPermission: false,
    hasAutomatePermission: false,
    hasBrandPermission: false,
    hasCalculatePermission: false,
    hasConnectPermission: false,
    hasDashboardPermission: false,
    hasDataConfigPermission: false,
    hasIntegrationPermission: false,
    hasTaskPermission: false,
    hasMakeCallPermission: false
}

type UserContextValue = {
    username: string
    isGuest: boolean
    permissions: Permissions
    userRoles: UserRole[]
    accessibleClients: string[]
    canAccess: (area: RouteArea) => boolean
    setUserRoles: (roles: UserRole[]) => void
}

const UserContext = createContext<UserContextValue | undefined>(undefined)

type UserProviderProps = {
    children: ReactNode
}

const UserProvider = ({ children }: UserProviderProps) => {
    const client = useClient()
    const [userRoles, setUserRoles] = useState<UserRole[]>([])
    const config = useEnvConfig()
    const auth = useAuth()

    const username = auth.accounts[0]?.username ?? ""

    const accessibleClientsRequest = useApiQuery<{ clients: string[] }>({
        url: `${config.PLATFORM_API_URL}/api/user-clients`,
        method: "GET"
    })

    const permissionsRequest = useApiQuery<Permissions>({
        url: `${config.PLATFORM_API_URL}/api/${client}/client-user-permissions`,
        method: "GET"
    })

    useApiQuery<ClientUserProfile>({
        url: `${config.PLATFORM_API_URL}/api/${client}/client-user-profile`,
        method: "GET",
        onSuccess: data => {
            setUserRoles(
                data?.userRoles
                    ?.sort((a, b) => a.roleName.localeCompare(b.roleName))
                    .map(r => {
                        return { ...r, enabled: true }
                    }) ?? []
            )
        }
    })

    const isGuest = client === "creditcircle" && permissionsRequest.data === undefined

    const permissions = permissionsRequest.data ?? defaultPermissions

    const canAccess = (area: RouteArea) => {
        if (isGuest) return area === "user-profile"
        if (permissions.hasAdminPermission) return area !== "user-profile"

        switch (area) {
            case "user-profile":
                return false
            case "dashboards":
            case "search":
            case "myinvevo":
                return true
            case "dashboard-config":
                return permissions.hasDashboardPermission
            case "automate":
                return permissions.hasAutomatePermission
            case "flow-sculptor":
                return permissions.hasAutomatePermission
            case "data-fields":
                return permissions.hasDataConfigPermission
            case "entity-config":
                return permissions.hasDataConfigPermission
            case "file-config":
                return permissions.hasIntegrationPermission
            case "file-history":
                return permissions.hasIntegrationPermission
            case "sms":
                return permissions.hasConnectPermission
            case "voip":
                return permissions.hasConnectPermission
            case "roles":
                return permissions.hasUserPermission
            case "users":
                return permissions.hasUserPermission
        }
    }

    const value = {
        username,
        isGuest,
        permissions,
        userRoles,
        accessibleClients: accessibleClientsRequest.data?.clients ?? [],
        canAccess,
        setUserRoles
    }

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

const useUser = () => {
    const context = useContext(UserContext)

    if (!context) throw new Error(`useUser must be used within a <UserProvider>`)
    return context
}

export default UserContext
export { useUser, UserProvider }
