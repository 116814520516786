import { ReactFlowProvider } from "reactflow"
import FlowDashboard from "./FlowDashboard"
import useApiQuery from "../../../hooks/useApiQuery"
import { useClient, useConfig } from "invevo-react-components"
import Loading from "../../../library/Loading/Loading"
import ExportConfig from "../types/ExportConfig"
import Lookup from "../../../types/Lookup"
import EntityConfig from "../../entityConfig/types/EntityConfig"
import GetFlowDto from "../types/GetFlowDto"
import FlowEdge from "../../../library/flow/FlowEdge"
import FlowSculptorNode from "../types/FlowSculptorNode"

const FlowSculptor = () => {
    // Update to get new templates from API once brand work is complete
    const communicationTemplates: { reference: string; name: string }[] = [
        { reference: "1", name: "Example Template 1" },
        { reference: "2", name: "Example Template 2" }
    ]

    const config = useConfig()
    const client = useClient()
    const fileExportConfigRequest = useApiQuery<ExportConfig[]>({
        url: `${config.INTEGRATION_API_URL}/api/${client}/file-export-config`,
        method: "get"
    })

    const entityConfigsRequest = useApiQuery<EntityConfig[]>({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-config`,
        method: "GET"
    })

    const getLookupsRequest = useApiQuery<Lookup[]>({
        url: `${config.DATA_API_URL}/api/${client}/lookups`,
        method: "GET"
    })

    const getFlowsRequest = useApiQuery<GetFlowDto[]>({
        url: `${config.FLOW_API_URL}/api/${client}/flow`,
        method: "GET"
    })

    const isLoading = fileExportConfigRequest.isFetching || entityConfigsRequest.isFetching || getLookupsRequest.isFetching || getFlowsRequest.isFetching

    return (
        <Loading isLoading={isLoading} colour="blue">
            {getFlowsRequest.data && (
                <ReactFlowProvider>
                    <FlowDashboard
                        originalFlows={getFlowsRequest.data.map(flow => ({
                            ...flow,
                            edges: JSON.parse(flow.edges) as FlowEdge[],
                            nodes: JSON.parse(flow.nodes) as FlowSculptorNode[]
                        }))}
                        communicationTemplates={communicationTemplates}
                        exportConfigs={fileExportConfigRequest.data ?? []}
                        entityConfigs={entityConfigsRequest.data ?? []}
                        lookups={getLookupsRequest.data ?? []}
                    />
                </ReactFlowProvider>
            )}
        </Loading>
    )
}

export default FlowSculptor
