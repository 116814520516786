import React, { Component } from 'react';
import { connect } from 'react-redux';
import { filterTypes, filterValueTypes } from '../../../constants';
import { executeAuthAsyncGet } from '../../../utility/asyncSupport';
import { find, isEqual, sortBy } from 'lodash';
import { showToastErrorMessage } from '../../../api/toasterApi';
import TagsInput from 'react-tagsinput';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { noOptionsMessage, optionStyles } from '../../../utility/selectSupport';

class AddUserFilter extends Component {
  constructor(props, context) {
    super(props, context);

    this.add = this.add.bind(this);
    this.cancel = this.cancel.bind(this);

    this.onTypeChanged = this.onTypeChanged.bind(this);
    this.onRoleChanged = this.onRoleChanged.bind(this);
    this.onfilterNameChanged = this.onfilterNameChanged.bind(this);
    this.onFilterValuesChanged = this.onFilterValuesChanged.bind(this);
    this.onCheckboxFilterTypeChanged = this.onCheckboxFilterTypeChanged.bind(
      this
    );

    this.updateFilterDefinitions = this.updateFilterDefinitions.bind(this);
    this.updateFilterTypes = this.updateFilterTypes.bind(this);

    this.validateForm = this.validateForm.bind(this);
    this.getInitialValue = this.getInitialValue.bind(this);
    this.getDefaultValue = this.getDefaultValue.bind(this);

    this.state = {
      //Note - filterTypes NOT camelCase as API is not returning camelcased objects for other lookups
      filterTypes: [
        //{ Id: filterTypes.Account, Name: filterTypes.Account }
      ],
      filterDefinitions: [
        // {Id: 1, Name: 'Branch Code', DefaultValue: 'xyz', FilterType: 'SimpleLookup', FilterLookupType: 'userNames' }
      ],
      filterValuesLookup: [
        // { value: 'One', label: 'One' }, { value: 'Two', label: 'Two' }
      ],
      filter: {
        filterType: null,
        roleId: null,
        roleDescription: null,
        filterDefinitionId: null,
        name: null,
        filterValueType: null,
        filterLookupType: null,
        values: []
      },
      canAdd: false
    };
  }

  onRoleChanged(selectItem) {
    this.setState(
      (previousState) => ({
        ...previousState,
        filter: {
          ...previousState.filter,
          roleId: selectItem.Id,
          roleDescription: selectItem.Name
        }
      }),
      () => {
        this.validateForm();
        this.updateFilterTypes();
        this.updateFilterDefinitions();
      }
    );
  }

  onTypeChanged(selectItem) {
    this.setState(
      (previousState) => ({
        ...previousState,
        filter: {
          ...previousState.filter,
          filterType: selectItem.Name
        }
      }),
      () => {
        this.validateForm();
        this.updateFilterDefinitions();
      }
    );
  }

  onfilterNameChanged(selectItem) {
    this.setState(
      (previousState) => ({
        ...previousState,
        filter: {
          ...previousState.filter,

          filterDefinitionId: selectItem.Id,
          name: selectItem.Name,
          values: this.getInitialValue(selectItem),
          filterValueType: selectItem.FilterType,
          filterLookupType: selectItem.FilterLookupType
        },
        filterValuesLookup:
          selectItem.FilterType === filterValueTypes.SimpleLookup
            ? this.mapSelectValues(
              this.props.filterLookups[selectItem.FilterLookupType]
            )
            : previousState.filterValuesLookup
      }),
      this.validateForm
    );
  }

  getInitialValue(selectItem) {
    if (selectItem.FilterType === filterValueTypes.Boolean) {
      return selectItem.DefaultValue &&
        selectItem.DefaultValue.toLowerCase() === 'true'
        ? ['True']
        : ['False'];
    } else {
      return selectItem.DefaultValue
        ? [this.getDefaultValue(selectItem.DefaultValue)]
        : [];
    }
  }

  getDefaultValue(defaultValue) {
    if (defaultValue === '{UserName}') {
      return this.props.userName;
    }
    if (defaultValue === '{ContactName}') {
      return this.props.contactName;
    }

    return defaultValue;
  }

  onFilterValuesChanged(values) {
    this.setState(
      (previousState) => ({
        ...previousState,
        filter: { ...previousState.filter, values }
      }),
      this.validateForm
    );
  }

  onFilterValuesSelectChanged(values) {
    if (values === null) values = [];

    const filterValues = values.map((v) => {
      return v.value;
    });

    this.setState(
      (previousState) => ({
        ...previousState,
        filter: { ...previousState.filter, values: filterValues }
      }),
      this.validateForm
    );
  }

  onCheckboxFilterTypeChanged(event) {
    const values = event.target.checked ? ['True'] : ['False'];

    this.setState(
      (previousState) => ({
        ...previousState,
        filter: { ...previousState.filter, values }
      }),
      this.validateForm
    );
  }

  getValuesSearchOptions(input, callback) {
    switch (this.state.filter.filterLookupType) {
      case 'branchCodes':
        this.props.executeAuthAsyncGet(
          this.props.client,
          'branches',
          'SEARCH_BRANCHES',
          { searchTerm: input, searchType: 'Code' },
          function (branches) {
            const options = sortBy(branches, (b) => b.Code).map((b) => {
              return { label: b.Code, value: b.Code };
            });
            callback(options);
          },
          function (/*error*/) {
            callback('Error searching for branches', null);
          }
        );
        break;
      case 'branchNames':
        this.props.executeAuthAsyncGet(
          this.props.client,
          'branches',
          'SEARCH_BRANCHES',
          { searchTerm: input, searchType: 'Name' },
          function (branches) {
            const options = sortBy(branches, (b) => b.Name).map((b) => {
              return { label: b.Name, value: b.Name };
            });
            callback(options);
          },
          function (/*error*/) {
            callback('Error searching for branches', null);
          }
        );
        break;
      case 'countryNames':
        this.props.executeAuthAsyncGet(
          this.props.client,
          'countries',
          'SEARCH_COUNTRIES',
          { searchTerm: input },
          function (countries) {
            const options = sortBy(countries, (c) => c.Name).map((c) => {
              return { label: c.Name, value: c.Name };
            });
            callback(options);
          },
          function (/*error*/) {
            callback('Error searching for countries', null);
          }
        );
        break;
      case 'userNames':
        if (input.length > 1) {
          this.props.executeAuthAsyncGet(
            this.props.client,
            'users',
            'SEARCH_USERS',
            { searchTerm: input },
            function (users) {
              const options = sortBy(users, (u) => u.userName).map((u) => {
                return { label: u.userName, value: u.userName };
              });
              callback(options);
            },
            function (/*error*/) {
              callback('Error searching for users', null);
            }
          );
        } else {
          callback(null, null);
        }
        break;
      default:
        break;
    }
  }

  add() {
    if (this.props.onAdd != null) {
      this.props.onAdd(this.state.filter);
    }
  }

  cancel() {
    if (this.props.onCancel != null) {
      this.props.onCancel();
    }
  }

  validateForm() {
    const canAdd =
      this.state.filter.filterType != null &&
      this.state.filter.roleId != null &&
      this.state.filter.name != null &&
      this.state.filter.values.length > 0;

    this.setState({ canAdd: canAdd });
  }

  updateFilterTypes() {
    let types = [];
    const role = find(this.props.roles, { Id: this.state.filter.roleId });
    if (!this.state.filter.roleId || !role) {
      types = [];
    } else {
      if (role.AccountFilterDefinitions.length > 0) {
        types.push({ Id: filterTypes.Account, Name: filterTypes.Account });
      }
      if (role.VirtualAccountFilterDefinitions.length > 0) {
        types.push({
          Id: filterTypes.VirtualAccount,
          Name: filterTypes.VirtualAccount
        });
      }
      if (role.TransactionFilterDefinitions.length > 0) {
        types.push({
          Id: filterTypes.Transaction,
          Name: filterTypes.Transaction
        });
      }
    }

    if (!isEqual(this.state.filterTypes, types)) {
      this.setState((previousState) => ({
        ...previousState,
        filterTypes: types
      }));
    }

    this.onTypeChanged({}); //Deselect the type
  }

  updateFilterDefinitions() {
    let filterDefinitions = [];
    const role = find(this.props.roles, { Id: this.state.filter.roleId });
    if (!this.state.filter.filterType || !this.state.filter.roleId || !role) {
      filterDefinitions = [];
    } else {
      switch (this.state.filter.filterType) {
        case filterTypes.Account:
          filterDefinitions = role.AccountFilterDefinitions;
          break;
        case filterTypes.VirtualAccount:
          filterDefinitions = role.VirtualAccountFilterDefinitions;
          break;
        case filterTypes.Transaction:
          filterDefinitions = role.TransactionFilterDefinitions;
          break;
        default:
          break;
      }
    }

    if (!isEqual(this.state.filterDefinitions, filterDefinitions)) {
      this.setState(
        (previousState) => ({
          ...previousState,
          filterDefinitions
        }),
        () => this.onfilterNameChanged({})
      );
    } //Deselect the filter
  }

  onNumberFilterTypeValidationReject(/*tags*/) {
    showToastErrorMessage(
      'Filter values must be numbers less than 15 digits long.'
    );
  }

  onDateFilterTypeValidationReject(/*tags*/) {
    showToastErrorMessage('Filter values must be dates in format dd/mm/yyyy.');
  }

  renderDropDownLabel(name, value) {
    return value ? (
      <span>
        {name}: {value}
      </span>
    ) : (
      <span>Select {name} </span>
    );
  }

  renderDropDownItems(items, onChangeFunction) {
    const markup = items.map((item) => {
      const key = 'filter' + item.Id;
      return (
        <li
          key={key}
          className="dropdown-item"
          onClick={onChangeFunction.bind(this, item)}
        >
          {item.Name}
        </li>
      );
    });
    return markup;
  }

  mapSelectValues(values) {
    return values.map((v) => {
      return { label: v, value: v };
    });
  }

  render() {
    const filter = this.state.filter;
    return (
      <div className="sub-form">
        <div className="row form-group">
          <div className="col-md-4">
            <ul className="nav" id="roleList">
              <li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  id="dropdownMenuButton"
                  className="nav-link dropdown-toggle boldTitle noPaddingLeft"
                  data-bs-toggle="dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  {this.renderDropDownLabel('Role', filter.roleDescription)}
                  <b className="caret"></b>
                </a>
                <ul aria-labelledby="dropdownMenuButton" className="dropdown-menu ddscroll">
                  {this.renderDropDownItems(
                    this.props.roles,
                    this.onRoleChanged
                  )}
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul className="nav" id="filterTypeList">
              <li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  id="dropdownMenuButton"
                  className="nav-link dropdown-toggle boldTitle noPaddingLeft"
                  data-bs-toggle="dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  {this.renderDropDownLabel('Filter Type', filter.filterType)}
                  <b className="caret"></b>
                </a>
                <ul aria-labelledby="dropdownMenuButton" className="dropdown-menu ddscroll">
                  {this.renderDropDownItems(
                    this.state.filterTypes,
                    this.onTypeChanged
                  )}
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul className="nav" id="filterList">
              <li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  id="dropdownMenuButton"
                  className="nav-link dropdown-toggle boldTitle noPaddingLeft"
                  data-bs-toggle="dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  {this.renderDropDownLabel('Filter', filter.name)}
                  <b className="caret"></b>
                </a>
                <ul aria-labelledby="dropdownMenuButton" className="dropdown-menu ddscroll">
                  {this.renderDropDownItems(
                    this.state.filterDefinitions,
                    this.onfilterNameChanged
                  )}
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-12">
            {this.state.filter.filterValueType === filterValueTypes.FreeText ? (
              <TagsInput
                value={this.state.filter.values}
                onChange={this.onFilterValuesChanged}
                onlyUnique="true"
                inputProps={{
                  placeholder: 'Add filter values, press enter to add values.'
                }}
              />
            ) : (
              ''
            )}

            {this.state.filter.filterValueType === filterValueTypes.Number ? (
              <TagsInput
                value={this.state.filter.values}
                onChange={this.onFilterValuesChanged}
                onlyUnique="true"
                inputProps={{
                  placeholder: 'Add filter values, press enter to add values.'
                }}
                // eslint-disable-next-line no-useless-escape
                validationRegex={/^-?[\d+\.?\d]{1,14}$/}
                onValidationReject={this.onNumberFilterTypeValidationReject}
              />
            ) : (
              ''
            )}

            {this.state.filter.filterValueType === filterValueTypes.Date ? (
              <TagsInput
                value={this.state.filter.values}
                onChange={this.onFilterValuesChanged}
                onlyUnique="true"
                inputProps={{
                  placeholder: 'Add filter values, press enter to add values.'
                }}
                validationRegex={
                  // eslint-disable-next-line no-useless-escape
                  /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/
                }
                onValidationReject={this.onDateFilterTypeValidationReject}
              />
            ) : (
              ''
            )}

            {this.state.filter.filterValueType === filterValueTypes.Boolean ? (
              <label className="my-label">
                <input
                  type="checkbox"
                  id="checkboxFilterType"
                  name="checkboxFilterType"
                  checked={
                    this.state.filter.values.length > 0 &&
                    this.state.filter.values[0] === 'True'
                  }
                  onChange={this.onCheckboxFilterTypeChanged}
                />{' '}
                {this.state.filter.name}
              </label>
            ) : (
              ''
            )}

            {this.state.filter.filterValueType ===
              filterValueTypes.SimpleLookup ? (
              <Select
                name="filter-values-select"
                isMulti
                value={this.mapSelectValues(this.state.filter.values)}
                options={this.state.filterValuesLookup}
                onChange={this.onFilterValuesSelectChanged.bind(this)}
                placeholder="Select filter values"
              />
            ) : (
              ''
            )}

            {this.state.filter.filterValueType ===
              filterValueTypes.SearchLookup ? (
              <AsyncSelect
                name="filter-values-search"
                isMulti
                value={this.mapSelectValues(this.state.filter.values)}
                loadOptions={this.getValuesSearchOptions.bind(this)}
                onChange={this.onFilterValuesSelectChanged.bind(this)}
                placeholder="Search for filter values"
                noOptionsMessage={noOptionsMessage}
                styles={{
                  option: optionStyles
                }}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="row form-group">
          <div className="col-12">
            <button
              id="btnAddFilter"
              type="button"
              onClick={this.add}
              className="btn btn-default"
              disabled={!this.state.canAdd}
            >
              Add Filter
            </button>
            <button
              type="button"
              onClick={this.cancel}
              className="btn btn-default"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {
    executeAuthAsyncGet
  },
  null,
  { forwardRef: true }
)(AddUserFilter);
