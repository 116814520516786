import { Link, matchRoutes, useLocation } from "react-router-dom-v5-compat"
import useIsHovering from "../../../hooks/useIsHovering"
import classes from "./MenuSingleRouteItem.module.scss"
import useClient from "../../../hooks/useClient"
import MenuItemLabel from "./MenuItemLabel"

type MenuSingleRouteItemProps = {
    label: string
    iconClasses: string
    route: string
    isClientRoute?: boolean
    opensInNewTab?: boolean
}

const MenuSingleRouteItem = ({ label, iconClasses, route, isClientRoute = true, opensInNewTab = false }: MenuSingleRouteItemProps) => {
    const [hoverableRef, isHoveringRef] = useIsHovering()
    const client = useClient()

    const location = useLocation()
    const isActive = matchRoutes([{ path: `/client/:client/${route}` }], location) != null

    const destinationRoute = isClientRoute ? `/client/${client}/${route}?client=${client}` : route

    return (
        <div ref={hoverableRef} className={`d-flex flex-column justify-content-center w-100 h-100 ${classes.boundHeight}`}>
            <div className="d-flex position-relative h-100">
                <Link
                    className={`${classes.menuItem} ${isActive ? classes.active : ""} d-flex w-100 text-decoration-none`}
                    to={destinationRoute}
                    target={opensInNewTab ? "_blank" : undefined}
                >
                    <i className={`m-auto ${iconClasses} text-white fs-5`} />
                </Link>
                {isHoveringRef && <MenuItemLabel label={label} location="right" />}
            </div>
        </div>
    )
}

export default MenuSingleRouteItem
