import { Loading, useApi, useClient } from "invevo-react-components"
import { useState } from "react"
import { actionTypes } from "../../../reducers/actionTypes"
import { convertRoleToDto } from "../helpers"
import classes from "./Permission.module.scss"

const Permission = ({ children, label, value, role, dispatch, isOnRole, permissionIcons }) => {
    const client = useClient()
    const api = useApi()

    const [isRemoving, setIsRemoving] = useState(false)

    const onRemoveClick = () => {
        setIsRemoving(true)
        const updatedRole = {
            ...role,
            [value]: null,
            assignedUsers: role.assignedUsers.map(user => ({
                ...user,
                permissions: {
                    ...user.permissions,
                    [value]: null
                }
            }))
        }

        const dto = convertRoleToDto(updatedRole)
        api.put(`/api/${client}/roles/${role.id}`, dto)
            .then(_ => {
                setIsRemoving(false)
                dispatch({ type: actionTypes.ROLE_PERMISSIONS_UPDATED, updatedRole: { id: role.id, ...updatedRole } })
            })
            .catch(error => {
                console.error(error)
            })
    }

    return (
        <div className={`d-flex flex-column bg-grey p-1 mt-2 ${classes.rounded}`}>
            <div className="d-flex flex-grow-1 align-items-center">
                <span className="ms-2 text-nowrap" role="textbox" aria-label={`permission-label-${label}`}>{label}</span>
                <div className="d-flex ms-auto align-items-center">
                    <div className={`d-flex p-1 bg-white ${classes.rounded} ${classes.shadow}`}>
                        {permissionIcons}
                    </div>
                    {
                        isOnRole &&
                        <Loading isLoading={isRemoving} size="small" colour="blue">
                            <i className="fs-5 fas fa-times-circle pointer text-light-grey ms-2" role="button" aria-label={`remove-permission-${role.name}-${label}`} onClick={onRemoveClick} />
                        </Loading>
                    }
                </div>
            </div>
            <div className="mx-2">
                {
                    children &&
                    <div className="mt-2">
                        {children}
                    </div>
                }
            </div>
        </div>
    )
}

export default Permission