import { useEffect, useReducer, useState } from "react"
import FileImportConfigSetup from "./fileImportConfigSetup/FileImportConfigSetup"
import FileImportConfigs from "./fileImportConfigs/FileImportConfigs"
import FileEntityImportConfigReducer, { FileImportConfigActionEnum } from "../../reducers/FileEntityImportConfigReducer"
import useApiQuery from "../../../../hooks/useApiQuery"
import { GetFileImportConfigDto } from "../../types/dtos/FileImportConfigDto"
import { mapGetFileImportConfigDtoToDomain, mapDomainToPutFileImportConfigDto } from "../utils"
import classes from "./FileImportConfiguration.module.scss"
import ProgressButton from "../../../../library/buttons/ProgressButton/ProgressButton"
import { FileImportConfig, FileType } from "../../types/FileImportConfig"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import EntityConfig from "../../../entityConfig/types/EntityConfig"
import useClient from "../../../../hooks/useClient"
import { useEnvConfig } from "../../../../contexts/EnvironmentConfigContext"
import Lookup from "../../../../types/Lookup"
import Loading from "../../../../library/Loading/Loading"

const FileImportConfiguration = () => {
    const [state, dispatch] = useReducer(FileEntityImportConfigReducer, {
        configs: [],
        entities: [],
        editingDataFields: [],
        lookups: [],
        editingConfig: undefined
    })

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

    const client = useClient()
    const config = useEnvConfig()

    const fileImportConfigRequest = useApiQuery<GetFileImportConfigDto[]>({
        url: `${config.INTEGRATION_API_URL}/api/${client}/file-import-config`,
        method: "get",
        onSuccess: data =>
            dispatch({
                type: FileImportConfigActionEnum.FILE_IMPORT_CONFIGS_RETRIEVED,
                configs: mapGetFileImportConfigDtoToDomain(data)
            })
    })

    const entitiesRequest = useApiQuery({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-config`,
        method: "get",
        onSuccess: (data: EntityConfig[]) =>
            dispatch({
                type: FileImportConfigActionEnum.ENTITIES_RETRIEVED,
                entities: data
            })
    })

    const saveFileImportConfigRequest = useApiQuery<GetFileImportConfigDto[]>({
        url: `${config.INTEGRATION_API_URL}/api/${client}/file-import-config`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    const lookupsRequest = useApiQuery<Lookup[]>({
        url: `${config.DATA_API_URL}/api/${client}/lookups`,
        method: "GET",
        onSuccess: response => dispatch({ type: FileImportConfigActionEnum.LOOKUPS_RETRIEVED, lookups: response })
    })

    useEffect(() => {
        const originalConfig = state.configs.find(config => config.reference === state.editingConfig?.reference)
        if (!originalConfig) {
            setHasUnsavedChanges(false)
            return
        }

        const hasChanges = JSON.stringify(originalConfig) !== JSON.stringify(state.editingConfig)
        setHasUnsavedChanges(hasChanges)
    }, [state.configs, state.editingConfig])

    const onDiscardChangesClick = () => {
        dispatch({
            type: FileImportConfigActionEnum.FILE_IMPORT_EDITING_CONFIG_DISCARDED
        })
    }
    const onSaveChangesClick = () => {
        const dto = mapDomainToPutFileImportConfigDto({ ...state.editingConfig, fileType: FileType.CUSTOMER } as FileImportConfig)
        return saveFileImportConfigRequest.execute(state.editingConfig?.reference, dto).then(res => {
            dispatch({
                type: FileImportConfigActionEnum.FILE_IMPORT_EDITING_CONFIG_SAVED
            })

            return res
        })
    }

    return (
        <div className="d-flex w-100 h-100">
            <Loading isLoading={fileImportConfigRequest.isFetching || entitiesRequest.isFetching || lookupsRequest.isFetching} colour="blue">
                <FileImportConfigs state={state} dispatch={dispatch} />
                <div className="d-flex flex-column flex-grow-1">
                    <div className="overflow-auto p-4">
                        {state.editingConfig ? (
                            <FileImportConfigSetup
                                state={{
                                    editingConfig: state.editingConfig,
                                    entities: state.entities,
                                    dataFields: state.editingDataFields,
                                    lookups: state.lookups
                                }}
                                dispatch={dispatch}
                            />
                        ) : (
                            <span className="text-grey fs-4">Please select a configuration for editing or create a new one</span>
                        )}
                    </div>
                    {state.editingConfig && (
                        <div className={`d-flex bg-white p-4 mt-auto ${classes.footer} align-items-center border-top border-2`}>
                            <StandardButton
                                className="ms-auto"
                                iconClasses="far fa-undo"
                                label="Discard changes"
                                onClick={onDiscardChangesClick}
                                disabled={!hasUnsavedChanges}
                            />
                            <ProgressButton
                                className="ms-3"
                                iconClasses="far fa-save"
                                label="Save changes"
                                onClickWithPromise={onSaveChangesClick}
                                disabled={!hasUnsavedChanges}
                            />
                        </div>
                    )}
                </div>
            </Loading>
        </div>
    )
}

export default FileImportConfiguration
