import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showToastErrorMessage, showToastWarningMessage } from '../../api/toasterApi';
import DocumentCard from './documentCard';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { downloadDocuments } from '../../api/documentApi';
import { taskId } from '../../selectors/task';
import { filterBy } from '@progress/kendo-data-query';
import { getTaskDocuments } from '../../actions/documentActions';
import { useClient } from 'invevo-react-components';

const TaskDocumentsGrid = ({
  taskId,
  getTaskDocuments,
  downloadDocuments,
  taskDocuments,
  filterByText,
  refresh = 0
}) => {
  const client = useClient()
  
  useEffect(() => {
    getTaskDocuments(client, taskId);
  }, [refresh, taskId, getTaskDocuments, client]);

  const onDownloadDocuments = (gridRowEvent) => {
    const dataItem = gridRowEvent.dataItem;

    downloadDocuments(
      client,
      [
        {
          Id: dataItem.Id,
          DocumentName: dataItem.DocumentName,
          FileName: dataItem.FileName
        }
      ],
      () =>
        showToastWarningMessage(
          'Download has started in background; please wait and follow indications on screen.'
        ),
      (error) => {
        showToastErrorMessage(error);
      }
    );
  };

  const rowRender = (trElement) => {
    return React.cloneElement(
      trElement,
      { className: `${trElement.props.className} col-lg-4 col-6 top15` },
      trElement.props.children
    );
  };

  const emptyFn = () => {};
  const resultCard = (e) => (
    <DocumentCard
      dataItem={e.dataItem}
      onClick={emptyFn}
      onDownloadClick={onDownloadDocuments}
    />
  );

  const textFilters =
    filterByText !== ''
      ? {
          logic: 'or',
          filters: [
            {
              field: 'FileName',
              operator: 'contains',
              value: filterByText,
              ignoreCase: true
            },
            {
              field: 'DocumentName',
              operator: 'contains',
              value: filterByText,
              ignoreCase: true
            }
          ]
        }
      : null;
  return (
    <div style={{ userSelect: 'none' }}>
      {taskId ? (
        <Grid
          data={filterBy(taskDocuments, textFilters)}
          defaultField={{ field: 'DocumentDate', dir: 'desc' }}
          className={
            'hide-header single-cell scrollable-none row row-sm-gutters'
          }
          selectedField={'Id'}
          rowRender={rowRender}
        >
          <GridColumn cell={resultCard} />
        </Grid>
      ) : (
        <p>No task selected</p>
      )}
    </div>
  );
};

TaskDocumentsGrid.propTypes = {
  refresh: PropTypes.number.isRequired,
  filterByText: PropTypes.string
};

const mapStateToProps = (state) => ({
  taskId: taskId(state.taskReducer.selectedTask),
  taskDocuments: state.documentReducer.taskDocuments
});
export default connect(mapStateToProps, {
  getTaskDocuments,
  downloadDocuments
})(TaskDocumentsGrid);
