export const doSearch = (searchCriteria) => ({
    type: 'DO_SEARCH',
    searchCriteria
  });
  export const createSearchList = (searchCriteria, gridState, total) => ({
    type: 'CREATE_SEARCH_LIST',
    searchCriteria,
    gridState,
    total
  });
  export const destroySearchList = () => ({
    type: 'DESTROY_SEARCH_LIST'
  });
  export const setSearchGridState = (gridState) => ({
    type: 'SET_SEARCH_GRIDSTATE',
    gridState
  });
  export const pauseSearchList = (customerId, virtualAccountId) => ({
    type: 'PAUSE_SEARCH_LIST',
    customerId,
    virtualAccountId
  });
  export const resumeSearchList = () => ({
    type: 'RESUME_SEARCH_LIST'
  });
  export const stepSearchList = (page) => ({
    type: 'STEP_SEARCH_LIST',
    page
  });
  export const searchListEnd = () => ({
    type: 'SEARCH_LIST_END'
  });
  export const searchListNextLoading = () => ({
    type: 'SEARCH_LIST_NEXT_LOADING'
  });
  export const searchListNextLoaded = (
    customerId,
    virtualAccountId,
    page,
    total
  ) => ({
    type: 'SEARCH_LIST_NEXT_LOADED',
    customerId,
    virtualAccountId,
    page,
    total
  });
  
  export const loadActivityList = () => ({
    type: 'LOAD_ACTIVITY_LIST'
  });
  
  export const doImmediateSearch = (searchCriteria) => ({
    type: 'DO_IMMEDIATE_SEARCH',
    searchCriteria
  });
  
  export const resetImmediateSearch = () => ({
    type: 'RESET_IMMEDIATE_SEARCH'
  });
  
  export const resetImmediateSearchQuery = () => ({
    type: 'RESET_IMMEDIATE_SEARCH_QUERY'
  });
  