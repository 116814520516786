import React from 'react';

export default function EditDeleteCommandCell({
  onEditClick,
  onDeleteClick,
  onCopyClick
}) {
  return (props) => {
    const { dataItem } = props;

    return (
      <td className="k-command-cell">
        <button
          className={'k-button k-grid-ViewEmail'}
          onClick={e => onEditClick(e, dataItem)}
        >
          Edit
        </button>

        <button
          className={'k-button k-grid-QuickReply'}
          onClick={e => onDeleteClick(e, dataItem)}
        >
          Delete
        </button>

        <button
          className={'k-button k-grid-QuickReply'}
          onClick={e => onCopyClick(e, dataItem)}
        >
          Copy
        </button>
      </td>
    );
  }
};
