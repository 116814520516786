import classes from "./LookupConfig.module.scss"
import { IconButton } from "invevo-react-components"
import { actionTypes } from "../../../reducers/actionTypes"
import LookupEntries from "./LookupEntries"
import LookupCard from "./LookupCard"

const LookupConfig = ({ originalLookups, updatedLookups, dispatch, selectedLookupId, onSelectedLookupChanged, currentLookupEntryPage }) => {
    const addField = () => dispatch({ type: actionTypes.DATA_LOOKUP_ADDED })

    const onLookupChange = updatedLookup =>
        dispatch({
            type: actionTypes.DATA_LOOKUP_UPDATED,
            updatedLookup
        })

    const removeLookup = lookupId => dispatch({ type: actionTypes.DATA_LOOKUP_REMOVED, id: lookupId })

    return (
        <div className={`d-grid text-white h-100 w-100 ${classes.container} overflow-hidden`}>
            <div className="d-flex flex-column bg-grey m-2 h-100 overflow-auto">
                <div className="d-flex m-2 justify-content-between border-bottom ">
                    <h2>Lookups</h2>
                    <IconButton iconClasses="fal fa-plus m-auto" onClick={addField} />
                </div>
                <div className={`d-flex flex-column overflow-auto`}>
                    {updatedLookups.map(lookup => (
                        <LookupCard
                            key={lookup.id}
                            lookup={lookup}
                            selectedLookupId={selectedLookupId}
                            onLookupChange={onLookupChange}
                            onSelectedLookupChanged={onSelectedLookupChanged}
                            removeLookup={removeLookup}
                        />
                    ))}
                </div>
            </div>
            <LookupEntries
                selectedOriginalLookup={originalLookups.find(l => l.id === selectedLookupId)}
                selectedUpdatedLookup={updatedLookups.find(l => l.id === selectedLookupId)}
                onLookupChange={onLookupChange}
                dispatch={dispatch}
                currentPage={currentLookupEntryPage}
            />
        </div>
    )
}

export default LookupConfig
