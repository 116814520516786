import React, { useMemo } from "react";
import RGL from "react-grid-layout"
import { withSize } from 'react-sizeme'

const DEFAULT_MIN_WIDTH = 4
const DEFAULT_MIN_HEIGHT = 4

const RGLSizeMeContainer = (props: any) => { return <RGL {...props} width={props.size.width || -1} />}
const withSizeHoc = withSize()
const ReactGridLayout = withSizeHoc(RGLSizeMeContainer);

const DndGrid = ({ children, onLayoutChange, isReadOnly = false} : any) => {
    if (typeof (onLayoutChange) !== 'function' && !isReadOnly) {
        throw Error("Cannot render <DndGrid/> without specifying onLayoutChange handler")
    }

    validateChildren(children)


    const items = useMemo(() => getItemsFromChildren(children), [children])

    const onChange = (layout: any) => { !isReadOnly && onLayoutChange(layout.map((item: any) => {
            return { id: item.i, x: item.x, y: item.y, w: item.w, h: item.h }
        }))
    }

    return (
        <div className="w-100 h-100">
            <ReactGridLayout
                className="layout"
                onLayoutChange={onChange}
                cols={12}
                rowHeight={100}
                compactType="horizontal"
            >
                {items && items.map(item => {
                    return <div key={item.key} className={item.props.className} data-grid={{ x: item.x, y: item.y, w: item.w, h: item.h, minW: item.minW, minH: item.minH, static: isReadOnly }}>
                        {item.props.children}
                    </div>
                })}
            </ReactGridLayout>
        </div>
    )
}

const validateChildren = (children: any) => {
    const childrenAsArray: any = React.Children.toArray(children).filter((child: any) => child.type !== React.Fragment)
    if (!childrenAsArray.every((child: any) => child.props.id != null && child.props.id !== undefined)) {
        throw Error("Cannot render <DndGrid/> without specifying children with { id } values, e.g. <div id=\"0\" x={1} y={2} w={5} h={3}></div>")
    }

    if (childrenAsArray.length !== [...new Set<string>(childrenAsArray.map((child: any) => child.props.id))].length) {
        throw Error("Cannot render <DndGrid/> when specifying children with identical { id } values, e.g. <div id=\"0\" x={1} y={2} w={5} h={3}></div>")
    }

    if (!childrenAsArray.every((child: any) => child.props.x != null && child.props.x !== undefined)) {
        throw Error("Cannot render <DndGrid/> without specifying children with { x } values, e.g. <div id=\"0\" x={1} y={2} w={5} h={3}></div>")
    }

    if (!childrenAsArray.every((child: any) => child.props.y != null && child.props.y !== undefined)) {
        throw Error("Cannot render <DndGrid/> without specifying children with { y } values, e.g. <div id=\"0\" x={1} y={2} w={5} h={3}></div>")
    }

    if (!childrenAsArray.every((child: any) => child.props.w != null && child.props.w !== undefined)) {
        throw Error("Cannot render <DndGrid/> without specifying children with { w } values, e.g. <div id=\"0\" x={1} y={2} w={5} h={3}></div>")
    }

    if (!childrenAsArray.every((child: any) => child.props.h != null && child.props.h !== undefined)) {
        throw Error("Cannot render <DndGrid/> without specifying children with { h } values, e.g. <div id=\"0\" x={1} y={2} w={5} h={3}></div>")
    }
}

const getItemsFromChildren = (children: any) => {
    const childrenAsArray = React.Children.toArray(children).filter((child: any) => child.type !== React.Fragment)
    return childrenAsArray.map((child: any) => {
        const minW = child.props.minW ? child.props.minW : DEFAULT_MIN_WIDTH
        const minH = child.props.minH ? child.props.minH : DEFAULT_MIN_HEIGHT
        return {
            key: child.props.id,
            x: child.props.x,
            y: child.props.y,
            w: child.props.w < minW ? minW : child.props.w,
            h: child.props.h < minH ? minH : child.props.h,
            minW,
            minH,
            props: child.props
        }
    })
}

export default DndGrid