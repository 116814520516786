import React from 'react';
import { connect } from 'react-redux';
import { clearLookup } from '../../../actions/lookupActions';
import { selectTable } from '../../../actions/mappingActions';
import { getLookup } from '../../../api/lookupApi';
import { deleteMappingTable } from '../../../api/mappingApi';
import { parseInt as _parseInt } from 'lodash';
import { showToastInfoMessage } from '../../../api/toasterApi';
import { ConfrimDialog } from '../../common/confirmDialog';

export class MappingGridSelect extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showDeleteDialog: false,
      selectedTableId: -1
    };
  }

  componentDidMount() {
    this.props.getLookup(this.props.client, 'mapping/tablenames', 'mappingTableNames');
  }

  componentWillUnmount() {
    this.props.selectTable(-1);
    this.props.clearLookup('tableSchema');
  }

  selectTable = (event) => {
    const tableId = _parseInt(event.target.value);

    this.setState({
      selectedTableId: tableId
    });

    this.props.selectTable(tableId);
    this.props.getLookup(this.props.client, 'mapping/tableschema', 'tableSchema', { tableId });
  };

  onDeleteClick = () => {
    if (this.state.selectedTableId > -1) {
      this.setState({ showDeleteDialog: true });
    }
  };

  deleteItem = () => {
    if (this.state.selectedTableId > -1) {
      showToastInfoMessage('Deleting table and data.');
      this.props.deleteMappingTable(this.state.selectedTableId, this.props.client);
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.setState({
      showDeleteDialog: false
    });
  };

  static getKey() {
    return 'mapping-template';
  }

  render() {
    const tableSelectOptions = this.props.mappingTableNames.results.map((e) => {
      return (
        <option key={e.Id} value={e.Id}>
          {e.Text}
        </option>
      );
    });

    tableSelectOptions.unshift(
      <option key="-1" value="-1">
        [ New Mapping ]
      </option>
    );

    return (
      <div id="mapping-grid-frame" className="borderContainerNoTop">
        <div
          className="form-group well"
          style={{ width: '580px', marginTop: '1px' }}
        >
          <div className="row">
            <div className="col-12">
              <span>Table to edit:</span>&nbsp;
              <select
                id="table-select"
                name="table-select"
                onChange={this.selectTable}
                defaultValue={this.state.selectedTableId}
              >
                {tableSelectOptions}
              </select>
              &nbsp;
              <button
                onClick={this.onDeleteClick}
                disabled={this.state.selectedTableId === -1}
                className="btnDefault btn btn-default"
              >
                Delete Table &amp; Data
              </button>
            </div>
          </div>
        </div>

        <ConfrimDialog
          show={this.state.showDeleteDialog}
          message={
            'Are you sure you want to delete this table and all associated data?'
          }
          onConfirm={this.deleteItem}
          onClose={this.closeDialog}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    mappingTableNames: state.lookupReducer.mappingTableNames,
    tableSchema: state.lookupReducer.tableSchema,
    gridState: state.gridCacheReducer.grids[ownProps.gridKey]
  };
};

export default connect(mapStateToProps, {
  getLookup,
  clearLookup,
  deleteMappingTable,
  selectTable
})(MappingGridSelect);
