import {useApi, useClient, useConfig } from "invevo-react-components"
import React, { useState } from "react"
import { actionTypes } from "../../../reducers/actionTypes"
import { mapDashboardConfigToPutDto } from "../../../helpers/dashboardMapping"
import classes from "./RoleCard.module.scss"
import { DashboardConfigAction } from "../../../reducers/dashboardConfigsReducer"
import DashboardConfigState from "../../../types/DashboardConfigState"
import Loading from "../../../../../library/Loading/Loading"
import Role from "../../../../../routes/userRoles/roles/types/Role"

type RoleCardProps = {
    className?: string
    state: {
        role: Role
        config: DashboardConfigState
    }
    dispatch: React.Dispatch<DashboardConfigAction>
    isNewEntityStructureEnabled: boolean
}

const RoleCard = ({ className, state, dispatch, isNewEntityStructureEnabled }: RoleCardProps) => {
    const { role, config } = state

    const [isRemoving, setIsRemoving] = useState(false)

    const api = useApi()
    const client = useClient()
    const apiConfig = useConfig()

    const onRemoveClick = () => {
        const updatedConfig = {
            ...config,
            roleReferences: [...config.roleReferences.filter((r: string) => r !== role.reference)]
        }

        setIsRemoving(true)
        const dto = mapDashboardConfigToPutDto(updatedConfig, isNewEntityStructureEnabled)
        api.put(`${apiConfig.DATA_API_URL}/api/${client}/dashboard-config/${updatedConfig.reference}`, dto)
            .then(() => {
                dispatch({ type: actionTypes.DASHBOARD_CONFIG_SAVED, dashboardConfig: updatedConfig })
                setIsRemoving(false)
            })
            .catch((error: any) => {
                console.error(error)
            })
    }

    return (
        <Loading isLoading={!apiConfig.DATA_API_URL} colour="blue">
            <div className={`${className ? className : ""} ${classes.card} p-3 d-flex align-items-center w-100`}>
                <i className="text-blue fal fa-id-card-alt me-2 fs-5"></i>
                <span className="fs-6 text-uppercase text-grey">{role.name}</span>
                <div className="d-flex ms-auto">
                    <Loading colour="blue" size="small" isLoading={isRemoving}>
                        <i className={`${classes["remove-icon"]} fas fa-times-circle fs-5 pointer`} onClick={onRemoveClick}></i>
                    </Loading>
                </div>
            </div>
        </Loading>
    )
}

export default RoleCard
