import { Route, Switch } from 'react-router-dom'
import { useClient } from "invevo-react-components"
import LegacyApp from "../../../microfrontends/legacy/LegacyApp"
import SystemConfiguration from "../../../microfrontends/legacy/systemConfiguration/SystemConfiguration"
import LookupAdminContainer from "../../../microfrontends/legacy/systemConfiguration/lookupAdminContainer"
import FileImporterContainer from "../../../microfrontends/legacy/systemConfiguration/FileImporterContainer"
import FileImporterConfigurationContainer from "../../../microfrontends/legacy/systemConfiguration/FileImporterConfigurationContainer"
import SystemConfigurationContainer from "../../../microfrontends/legacy/systemConfiguration/systemConfigurationContainer"
import ClientConfigurationContainer from "../../../microfrontends/legacy/systemConfiguration/clientConfigurationContainer"
import RoleManagementContainer from "../../../microfrontends/legacy/systemConfiguration/RoleManagementContainer"
import UserManagementContainer from "../../../microfrontends/legacy/systemConfiguration/UserManagementContainer"
import EmailMasterTemplateManager from "../../../microfrontends/legacy/systemConfiguration/EmailMasterTemplateManager"
import EmailTemplateManager from "../../../microfrontends/legacy/systemConfiguration/EmailTemplateManager"
import PostTemplateManager from "../../../microfrontends/legacy/systemConfiguration/PostTemplateManager"
import CustomerAgreementTemplate from "../../../microfrontends/legacy/systemConfiguration/CustomerAgreementTemplate"
import RuleGroupManagementContainer from "../../../microfrontends/legacy/systemConfiguration/RuleGroupManagementContainer"
import MappingTemplate from "../../../microfrontends/legacy/systemConfiguration/mappingTemplate"

const SystemSettings = ({ store }) => {
    const client = useClient()

    return (
        <div className="d-flex w-100 h-100 p-4">
            <LegacyApp className="d-flex w-100 h-100" store={store}>
                <Switch>
                    <Route path="/home/system-settings" exact render={() => <SystemConfiguration baseRoute="/home/system-settings/" />} />
                    <Route path="/home/system-settings/lookup-admin" render={() => (<LookupAdminContainer client={client} />)} />
                    <Route path="/home/system-settings/file-importer-log" render={() => (<FileImporterContainer />)} />
                    <Route path="/home/system-settings/file-importer-configuration" render={() => (<FileImporterConfigurationContainer client={client} />)} />
                    <Route path="/home/system-settings/system-configuration" render={() => (<SystemConfigurationContainer client={client} />)} />
                    <Route path="/home/system-settings/client-configuration" render={() => (<ClientConfigurationContainer client={client} />)} />
                    <Route path="/home/system-settings/role-management" render={() => (<RoleManagementContainer client={client} />)} />
                    <Route path="/home/system-settings/user-management" render={() => (<UserManagementContainer client={client} />)} />
                    <Route path="/home/system-settings/email-master-template-manager" render={() => (<EmailMasterTemplateManager client={client} />)} />
                    <Route path="/home/system-settings/email-template-manager" render={() => (<EmailTemplateManager client={client} />)} />
                    <Route path="/home/system-settings/post-template-manager" render={() => (<PostTemplateManager client={client} />)} />
                    <Route path="/home/system-settings/customer-agreement-template" render={() => (<CustomerAgreementTemplate client={client} />)} />
                    <Route path="/home/system-settings/rule-group-management" render={() => (<RuleGroupManagementContainer client={client} />)} />
                    <Route path="/home/system-settings/mapping-template" render={() => (<MappingTemplate client={client} />)} />
                </Switch>
            </LegacyApp>
        </div>
    )
}

export default SystemSettings