import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { hasSelectedAccount } from "../../selectors/currentSelection"
import SelectAccountWarning from "../../components/common/selectAccountWarning"
import PlanGrid from "./planGrid"
import NewPlan from "./newPlan"
import { executeAuthAsyncGet, executeAsyncResetGet, executeAuthAsyncPost, executeAsyncResetPost } from "../../utility/asyncSupport"
import { showToastErrorMessage } from "../../api/toasterApi"
import ComponentLoader from "../../components/common/ComponentLoader"
import { ConfrimDialog } from "../../components/common/confirmDialog"
import { useRepaymentPlan, initPlan, createPlanFromExisting } from "../../contexts/repaymentPlanContext"
import Money from "../../components/common/money"
import { planStatus } from "./repaymentConstants"
import { useClient, useInvevoSession } from "invevo-react-components"
import classes from "./repaymentPlan.module.scss"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"
import { useEvent } from "../../../../contexts/EventContext"

function InfoRow({ title, children }) {
    return (
        <div className="d-flex justify-content-between">
            <div>{title}</div>
            <div>{children}</div>
        </div>
    )
}

const RepaymentPlan = () => {
    const client = useClient()
    const [isNew, setIsNew] = useState()
    const [status, setStatus] = useState("idle")
    const [forbidden, setForbidden] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const [, setInvevoSession] = useInvevoSession()
    const { triggerEvent } = useEvent()
    const { isEnabled: newEntityStructure } = useFeatureToggle("newEntityStructure")

    const selectedAccount = useSelector(state => state.currentSelectionReducer.selectedAccount)

    const requiresAccount = !hasSelectedAccount(selectedAccount)

    const accountId = selectedAccount.miaAccountId
    const isVirtual = selectedAccount.miaAccountIsVirtualAccount

    const dispatch = useDispatch()

    const [{ repaymentPlan }, repaymentPlanDispatch] = useRepaymentPlan()
    const relatedTransactionRefs = repaymentPlan.currentPosition ? repaymentPlan.currentPosition.RelatedTransactionRefs : []

    useEffect(() => {
        setIsNew(undefined)
        initPlan(repaymentPlanDispatch)
    }, [repaymentPlanDispatch, accountId])

    useEffect(() => {
        if (!accountId || isNew !== undefined || isVirtual) return

        setStatus("pending")
        dispatch(executeAsyncResetGet("REPAYMENT_PLAN_FOR_CUSTOMER"))
        dispatch(
            executeAuthAsyncGet(
                client,
                "RepaymentPlan/GetExistingRepaymentPlan",
                "REPAYMENT_PLAN_FOR_CUSTOMER",
                { customerId: accountId },
                result => {
                    setStatus("resolved")
                    setForbidden(false)

                    if (result) {
                        createPlanFromExisting(repaymentPlanDispatch, result)
                        setIsNew(false)
                    } else {
                        setIsNew(true)
                    }
                },
                ({ statusText }) => {
                    setStatus("rejected")
                    if (statusText) {
                        showToastErrorMessage(statusText)
                        if (statusText === "Forbidden") {
                            setForbidden(true)
                        }
                    }
                }
            )
        )
    }, [accountId, isVirtual, dispatch, repaymentPlanDispatch, isNew, client])

    const onClosePlan = () => setShowDialog(true)
    const onCloseDialog = () => setShowDialog(false)
    const executeClosePlan = () => {
        dispatch(executeAsyncResetPost("CLOSE_REPAYMENT_PLAN"))
        dispatch(
            executeAuthAsyncPost(
                client,
                "RepaymentPlan/CloseExistingRepaymentPlan",
                "CLOSE_REPAYMENT_PLAN",
                accountId,
                () => {
                    initPlan(repaymentPlanDispatch)
                    setIsNew(undefined)
                    onCloseDialog()
                },
                errorText => {
                    showToastErrorMessage(errorText)
                    onCloseDialog()
                }
            )
        )
    }

    const onCompleteNewPlan = () => {
        setIsNew(undefined)
    }

    function onSelectRelatedTransactions() {
        newEntityStructure &&
            triggerEvent("select_transactions", {
                selection: relatedTransactionRefs.map(ref => ref.toLowerCase()),
                filterToSelected: false
            })

        setInvevoSession({ selectedCustomerTab: "transactions" })
    }

    const rpStatusClass = status => {
        switch (status) {
            case planStatus.OPEN:
                return "badge bg-success"
            case planStatus.BROKEN:
                return "badge bg-danger"
            default:
                return "badge bg-secondary"
        }
    }

    const title = <h2 className="m-3 text-white">Repayment Plan</h2>

    if (isVirtual) {
        return (
            <div className={` ${classes.container} w-100`}>
                <div className="mt-3">
                    {title}
                    <h4 className="m-3">Not available for virtual accounts</h4>
                </div>
            </div>
        )
    }

    if (forbidden) {
        return (
            <div className={` ${classes.container} w-100`}>
                <div className="mt-3">
                    {title}
                    <h4 className="m-3 text-white">You have no rights to operate on the repayment plan for this customer</h4>
                </div>
            </div>
        )
    }

    if (requiresAccount) {
        return (
            <div className={` ${classes.container} w-100`}>
                <div className="mt-3">
                    {title}
                    <SelectAccountWarning />
                </div>
            </div>
        )
    }

    if (status === "rejected") {
        return (
            <div className={` ${classes.container} w-100`}>
                <div className="mt-3">
                    {title}
                    <h4 className="m-3 text-white">Oops we've hit a problem - Please try again</h4>
                </div>
            </div>
        )
    }

    if (status === "pending" && isNew === undefined) {
        return <ComponentLoader />
    }

    return (
        <div className={` ${classes.container} w-100 overflow-auto`}>
            <div className="mt-3 repayment-plan">
                {title}
                {status === "pending" && <ComponentLoader />}
                {isNew ? (
                    <NewPlan accountId={accountId} onComplete={onCompleteNewPlan} />
                ) : (
                    <>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <PlanGrid repaymentPlan={repaymentPlan} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>
                            {repaymentPlan.currentPosition && (
                                <>
                                    <h3 className="text-white">Current position in {repaymentPlan.currency}</h3>
                                    <InfoRow title="Status">
                                        <span className={rpStatusClass(repaymentPlan.planStatus)}>{planStatus[repaymentPlan.planStatus]}</span>
                                    </InfoRow>
                                    <InfoRow title="Open plan value">
                                        <Money number={repaymentPlan.totalOfRepaymentPlan} />
                                    </InfoRow>
                                    <InfoRow title={"Unallocated payments"}>
                                        <Money number={repaymentPlan.currentPosition.UnallocatedPayments} />
                                    </InfoRow>

                                    <InfoRow title={"Allocated payments"}>
                                        <Money number={repaymentPlan.currentPosition.AllocatedPayments} />
                                    </InfoRow>

                                    <InfoRow title={"Expected position"}>
                                        <Money number={repaymentPlan.currentPosition.Expected} />
                                    </InfoRow>

                                    <InfoRow title={"Actual position"}>
                                        <Money number={repaymentPlan.currentPosition.Actual} />
                                    </InfoRow>
                                </>
                            )}

                            <div className="row">
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>

                            <div className={"row d-flex"}>
                                <div className="col-6 my-3">
                                    <button className={`btn ${classes["btn-custom"]}`} onClick={onClosePlan}>
                                        Close Plan
                                    </button>
                                </div>
                                <div className="col-6 my-3 text-end ms-auto d-flex">
                                    <button
                                        className={`btn ${classes["btn-custom"]} ms-auto`}
                                        aria-label="navigate-to-transactions"
                                        onClick={onSelectRelatedTransactions}
                                    >
                                        Select related transactions
                                    </button>
                                </div>
                            </div>
                        </div>

                        <ConfrimDialog
                            show={showDialog}
                            message={"Are you sure you want to close this plan?"}
                            onConfirm={executeClosePlan}
                            onClose={onCloseDialog}
                        />
                    </>
                )}
            </div>
        </div>
    )
}

export default RepaymentPlan
