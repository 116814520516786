import * as types from './actionTypes';
import * as configurationApi from '../api/configurationApi';
import displayError from '../utility/error';
import { showToastSuccessMessage } from '../api/toasterApi';

export function cancel() {
  return function (dispatch) {
    const configuration = {
      showForm: false,
      showGrid: true
    };

    dispatch({
      type: types.CANCEL_CONFIGURATION,
      configuration: configuration
    });
  };
}

export function loadConfiguration(
  systemConfigurationId,
  ordinal,
  name,
  systemConfigurationTypeId,
  client
) {
  return function (dispatch) {
    if (systemConfigurationId === 0) {
      const data = {
        name: name,
        ordinal: ordinal,
        systemConfigurationTypeId: systemConfigurationTypeId,
        clientConfigurable: false,
        showForm: true,
        showGrid: false
      };
      dispatch({
        type: types.LOAD_CONFIGURATION,
        configuration: data
      });
    } else {
      configurationApi
        .getConfiguration(systemConfigurationId, dispatch, client)
        .then((data) => {
          data.showForm = true;
          data.showGrid = false;

          dispatch({
            type: types.LOAD_CONFIGURATION,
            configuration: data
          });
        })
        .catch(function (err) {
          displayError(err);
        });
    }
  };
}

export function insertConfiguration(configuration, client) {
  return function (dispatch) {
    configurationApi
      .insertConfiguration(configuration, dispatch, client)
      .then(() => {
        configuration.showForm = false;
        configuration.showGrid = true;
        showToastSuccessMessage('Record inserted');

        dispatch(loadGlobalConfiguration(client)); //Refresh the configuration

        dispatch({
          type: types.UPDATE_CONFIGURATION,
          configuration: configuration
        });
      })
      .catch(function (err) {
        displayError(err);
      });
  };
}

export function updateConfiguration(configuration, client) {
  return function (dispatch) {
    configurationApi
      .updateConfiguration(configuration, dispatch, client)
      .then(() => {
        configuration.showForm = false;
        configuration.showGrid = true;
        showToastSuccessMessage('Record updated');

        dispatch(loadGlobalConfiguration(client)); //Refresh the configuration

        dispatch({
          type: types.UPDATE_CONFIGURATION,
          configuration: configuration
        });
      })
      .catch(function (err) {
        displayError(err);
      });
  };
}

export function loadGlobalConfiguration(client) {
  return function (dispatch) {
    configurationApi
      .getGlobalConfiguration(dispatch, client)
      .then((data) => {
        dispatch({
          type: types.LOAD_GLOBAL_CONFIGURATION,
          globalConfiguration: data
        });
      })
      .catch(function (err) {
        displayError(err);
      });
  };
}
