import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLookup } from '../../../api/lookupApi';
import * as lodash from 'lodash';

class AddImportLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileImportLocation: {
        id: '',
        importFileLocation: '',
        instance: '',
        isActive: ''
      }
    };

    this.onChange = this.onChange.bind(this);
    this.onChecked = this.onChecked.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  // use this to set state from mapStateToProps
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      fileImportLocation: Object.assign({}, nextProps.fileImportLocation)
    });
  }

  componentDidMount() {
    this.props.getLookup(
      this.props.client,
      'import-configuration/locations/lookup',
      'fileImportLocations'
    );
  }

  onChange(event) {
    const field = event.target.name;
    const fileImportLocation = Object.assign({}, this.state.fileImportLocation);

    fileImportLocation[field] = event.target.value;

    return this.setState({ fileImportLocation: fileImportLocation });
  }

  onChecked(event) {
    const field = event.target.name;
    const fileImportLocation = Object.assign({}, this.state.fileImportLocation);

    fileImportLocation[field] = event.target.checked;

    return this.setState({ fileImportLocation: fileImportLocation });
  }

  onSave() {
    this.props.onSave(this.state.fileImportLocation);
  }

  render() {
    const {
      id,
      importFileLocation,
      instance,
      isActive
    } = this.state.fileImportLocation;
    const header = id ? `Edit File Location (Id:${id})` : 'Add File Location';
    const existingLocations = this.props.fileImportLocations.results;

    let isValid = false;

    if (this.props.fileImportLocation.importFileLocation.length > 0) {
      isValid =
        importFileLocation.length > 0 &&
        instance.length > 0 &&
        (importFileLocation.toLowerCase() ===
          this.props.fileImportLocation.importFileLocation.toLowerCase() ||
          !existingLocations.includes(importFileLocation.toLowerCase()));
    } else {
      isValid =
        importFileLocation.length > 0 &&
        instance.length > 0 &&
        !existingLocations.includes(importFileLocation.toLowerCase());
    }

    return (
      <div>
        <div key="import-location-form" className="card below-grid-form">
          <h3>{header}</h3>

          <div className="form-group" style={{ width: '480px' }}>
            <div className="form-control-feedback-wrapper warning">
              <span className="form-control-feedback">
                <i className="fa fa-asterisk" />
              </span>
            </div>
            <input
              name="importFileLocation"
              type="text"
              placeholder="Location"
              className="form-control"
              value={importFileLocation}
              onChange={this.onChange}
              title="Location"
            />
          </div>
          <div className="form-group" style={{ width: '480px' }}>
            <div className="form-control-feedback-wrapper warning">
              <span className="form-control-feedback">
                <i className="fa fa-asterisk" />
              </span>
            </div>
            <input
              name="instance"
              type="text"
              placeholder="Instance (must be unique)"
              className="form-control"
              value={instance}
              onChange={this.onChange}
              title="Instance (must be unique)"
            />
          </div>
          <div className="form-group">
            <div className="checkbox">
              <label>
                <input
                  name="isActive"
                  type="checkbox"
                  checked={isActive}
                  onChange={this.onChecked}
                />{' '}
                Is Active
              </label>
            </div>
          </div>
          <div className="form-group">
            <button
              className="btnDefault btn btn-default"
              disabled={!isValid}
              onClick={this.onSave}
            >
              Save
            </button>
            <button className="btn btn-default" onClick={this.props.onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let fileImportLocation = {
    id: '',
    importFileLocation: '',
    instance: '',
    isActive: ''
  };

  if (state.fileImportLocations.length > 0) {
    fileImportLocation = lodash.last(state.fileImportLocations);
  }

  return {
    fileImportLocation: fileImportLocation,
    fileImportLocations: state.lookupReducer.fileImportLocations
  };
}

export default connect(mapStateToProps, { getLookup })(AddImportLocation);
