import * as api from '../api/ruleGroupAdminApi';
import displayError from '../utility/error';
import { showToastSuccessMessage, showToastInfoMessage, showToastErrorMessage } from '../api/toasterApi';

export const getMessageTypes = (client) => (dispatch) =>
  api.getMessageTypes(client, dispatch).then(data => {
    dispatch({
      type: 'RECEIVE_MESSAGE_TYPE_LIST',
      data
    })
  }).catch(function (err) {
    displayError(err);
  });

export const getActionDefinitions = (client) => (dispatch) =>
  api.getActionDefinitions(client, dispatch).then(data => {
    dispatch({
      type: 'RECEIVE_ACTION_DEFINITION_LIST',
      data
    })
  }).catch(function (err) {
    displayError(err);
  });

export const getRuleGroup = (client, ruleGroupId) => (dispatch) =>
  api.getRuleGroup(client, dispatch, ruleGroupId).then(data => {
    dispatch({
      type: 'RECEIVE_RULE_GROUP',
      data
    })
  }).catch(function (err) {
    displayError(err);
  });

export const setRuleGroup = (ruleGroup) => {
  return {
    type: 'SET_RULE_GROUP',
    data: ruleGroup
  };
}

export const saveRuleGroup = (client, ruleGroup) => (dispatch) =>
  api.saveRuleGroup(client, dispatch, ruleGroup).then((result) => {
    if (result.valid) {
      showToastSuccessMessage('Rule group updated');
      dispatch(refresh(true));
    } else {
      displayError('Validation has failed');
    }
    dispatch(setRuleGroupValidation(result));
  }).catch(function (err) {
    displayError(err);
  });

export const validateRuleGroups = (client) => (dispatch) => {
  showToastInfoMessage('Validating Rule Groups - this may take a few minutes');
  api.validateRuleGroups(client, dispatch).then(() => {
    showToastSuccessMessage('Rule groups validated');
    dispatch(refresh(true));
  }).catch(function (err) {
    displayError(err);
  });
}

export const copyRuleGroup = (client, ruleGroupId) => (dispatch) =>
  api.copyRuleGroup(client, dispatch, ruleGroupId).then((result) => {
    if (result.valid) {
      showToastSuccessMessage('Rule group copied');
      dispatch(refresh(true));
    } else {
      result.items.forEach(function (element) {
        showToastErrorMessage(element.message);
      })
    }
  }).catch(function (err) {
    displayError(err);
  });

export const deleteRuleGroup = (client, ruleGroupId) => (dispatch) =>
  api.deleteRuleGroup(client, dispatch, ruleGroupId).then(() => {
    showToastSuccessMessage('Rule group deleted');
    dispatch(refresh(true));
  }).catch(function (err) {
    displayError(err);
  });

export const setRuleGroupValidation = (ruleGroupValidation) => {
  return {
    type: 'SET_RULE_GROUP_VALIDATION',
    data: ruleGroupValidation
  };
}

export const refresh = (update) => {
  return {
    type: 'RULE_GROUP_REFRESH',
    refresh: { update: update }
  };
}