import { useEffect, useRef, useState } from "react"
import { exists } from "../library/helpers/tsUtils"

const KEY_PREFIX = "invevo/"

const useLocalStorage = <T>(
    key: string,
    defaultValue: T | (() => T),
    isLocalStorageEnabled: boolean = false,
    serialize: (val: T) => string = JSON.stringify,
    deserialize: (str: string) => T = JSON.parse
): [T, React.Dispatch<React.SetStateAction<T>>, () => void, boolean] => {
    const getDefaultValue = () => (defaultValue instanceof Function ? defaultValue() : defaultValue)
    const [hasLoaded, setHasLoaded] = useState(!isLocalStorageEnabled)

    const [state, setState] = useState<T>(() => {
        if (!isLocalStorageEnabled) return getDefaultValue()
        setHasLoaded(false)
        const valueInLocalStorage = window.localStorage.getItem(KEY_PREFIX + key)
        if (exists(valueInLocalStorage)) {
            setHasLoaded(true)
            return deserialize(valueInLocalStorage)
        }
        setHasLoaded(true)
        return getDefaultValue()
    })

    const prevKeyRef = useRef(KEY_PREFIX + key)

    useEffect(() => {
        if (!isLocalStorageEnabled) return
        setHasLoaded(false)
        Promise.resolve().then(() => {
            prevKeyRef.current = KEY_PREFIX + key
            const newState = window.localStorage.getItem(KEY_PREFIX + key)
            if (exists(newState)) {
                setState(deserialize(newState))
            } else {
                setState(getDefaultValue())
            }
            setHasLoaded(true)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key, deserialize, isLocalStorageEnabled])

    useEffect(() => {
        if (!isLocalStorageEnabled) return
        if (!exists(state) || JSON.stringify(state) === JSON.stringify(getDefaultValue())) {
            window.localStorage.removeItem(KEY_PREFIX + key)
            return
        }
        window.localStorage.setItem(KEY_PREFIX + key, serialize(state))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, serialize])

    const resetState = () => setState(getDefaultValue())

    return [state, setState, resetState, hasLoaded]
}

/**
 * @param prefix The prefix of the keys to remove from local storage
 * @param suffixesToIgnore The suffixes of the keys (and their children) to skip when removing from local storage
 */
const removeKeysFromLocalStorageWithPrefix = (prefix: string, suffixesToIgnore: string[] = []) => {
    const keyPrefixesToIgnore = suffixesToIgnore.map(suffix => KEY_PREFIX + prefix + suffix)
    Object.keys(window.localStorage).forEach(key => {
        if (key.startsWith(KEY_PREFIX + prefix) && !keyPrefixesToIgnore.some(k => key.startsWith(k))) {
            window.localStorage.removeItem(key)
        }
    })
}

export { useLocalStorage, removeKeysFromLocalStorageWithPrefix }
