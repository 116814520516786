import { v4 as uuidv4 } from 'uuid'

export default function workflowRulesReducer(state, action) {
    switch (action.type) {
        case 'WORKFLOW_RULES_RETRIEVED':
            if (action.workflowRules.filter(wfr => wfr.status !== "archived").length > 0) {
                const activeRule = action.workflowRules.filter(wfr => wfr.status !== "archived")[0]
                return {
                    ...state,
                    workflowRules: action.workflowRules,
                    selectedWorkflowRule: activeRule,
                    selectedWorkflowRun: null
                }
            }
            return { ...state, workflowRules: action.workflowRules }

        case 'WORKFLOW_RULE_SELECTED': {
            const selectedRule = action.workflowRule.isNew
                ? action.workflowRule
                : state.workflowRules.find(rule => rule.reference === action.workflowRule.reference)
            return { ...state, selectedWorkflowRule: { ...selectedRule }, selectedWorkflowRun: null }
        }

        case 'WORKFLOW_RULE_UPDATED': {
            const updatedWorkflowRules = state.workflowRules.map((item) => {
                if (item.reference === action.workflowRule.reference) {
                    return action.workflowRule
                }
                return item
            })
            if (action.workflowRule.isNew)
                return {
                    ...state,
                    workflowRules: [{ ...action.workflowRule, isNew: false }, ...updatedWorkflowRules]
                }
            return { ...state, workflowRules: updatedWorkflowRules }
        }

        case 'SELECTED_WORKFLOW_RULE_UPDATED': {
            const updatedWorkflowRule = { ...state.selectedWorkflowRule, ...action.workflowRule }
            return { ...state, selectedWorkflowRule: updatedWorkflowRule }
        }
            
        case 'NEW_WORKFLOW_RULE_REQUESTED': {
            const newRulesList = [{
                reference: uuidv4(),
                name: "New workflow rule",
                status: 'on',
                outputs: [],
                trigger: null,
                customerFilters: [],
                smsSendActions: [],
                createdBy: "",
                isNew: true
            }].concat(state.workflowRules)

            return { ...state, isNewRequested: true, selectedWorkflowRule: newRulesList[0], workflowRules: newRulesList  }
        }
            
        case 'NEW_WORKFLOW_RULE_CLOSED': {
            return {
                ...state,
                workflowRules: action.workflowRules,
                selectedWorkflowRule: action.workflowRules.filter(wf => wf.status !== "archived")[0],
                isNewRequested: false
            }
        }

        case 'SMS_TEMPLATES_RETRIEVED': {
            const templates = action.smsTemplates.filter(s => s.isActive)
                .map((t, i) => ({
                    id: i,
                    value: t.name,
                    reference: t.reference,
                }))

            const updatesRules = state.workflowRules.map(rule => {
                if (rule.smsSendActions.length === 0 || rule.smsSendActions.length === 0)
                    return rule

                const smsSendActions = rule.smsSendActions.map(action => {
                    return { ...action, template: templates.find(t => t.reference === action.templateRef) }
                })
                return { ...rule, smsSendActions: smsSendActions }
            })

            const selectedRule = {
                ...state.selectedWorkflowRule,
                smsSendActions: state.selectedWorkflowRule.smsSendActions.map(action => {
                    return { ...action, template: templates.find(t => t.reference === action.templateRef) }
                })
            }
            return { ...state, smsTemplates: templates, workflowRules: updatesRules, selectedWorkflowRule: selectedRule }
        }

        case 'NEW_WORKFLOW_PREVIEW_REQUESTED': {
            return {
                ...state,
                selectedWorkflowRule: { ...state.selectedWorkflowRule },
                previewRequested: true,
                selectedWorkflowRun: null
            }
        }

        case 'NEW_WORKFLOW_PREVIEW_REQUEST_FAILED': {
            return {
                ...state,
                previewRequested: false
            }
        }

        case 'WORKFLOW_RUNS_RETRIEVED': {
            return { ...state, workflowRuns: action.workflowRuns, selectedWorkflowRun: null, previewRequested: false, actionSuccess: "all" }
        }
        case 'WORKFLOW_RUN_SELECTED': {
            return { ...state, selectedWorkflowRun: action.selectedWorkflowRun, actionSuccess: action.actionSuccess }
        }

        case 'CLEAR_WORKFLOW_RUNS': {
            return {
                ...state,
                workflowRuns: [],
                selectedWorkflowRun: null
            }
        }

        case 'CUSTOMER_DATA_RETRIEVED': {
            return { ...state, customerData: { fieldConfigs: action.customerFields, hasFetched: true } }
        }

        case 'COLLECT_DATA_RETRIEVED': {
            return { ...state, collectData: { customerFields: action.customerFields, dataConfiguration: action.dataConfiguration, hasFetched: true } }
        }

        case 'ASSESS_DATA_RETRIEVED': {
            return {
                ...state,
                assessData: {
                    hasCreditData: action.hasCreditData,
                    creditFields: action.creditFields,
                    dataConfiguration: action.dataConfiguration,
                    hasFetched: true
                }
            }
        }

        case 'CALCULATE_DATA_RETRIEVED': {
            return { ...state, calculateData: { calculateFields: action.calculateFields, hasFetched: true } }
        }

        default:
            throw new Error(`Invalid reducer action: ${action.type}`)
    }
}
