import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { removeParent, getVPTypes } from '../../api/vpApi';
import { vaTypesList } from '../../selectors/virtualParent';
import { isNil } from 'lodash';
import { useClient } from 'invevo-react-components';

const EditRelationship = ({
  selectedAccount,
  dataItem,
  vaTypes,
  getVpTypes,
  removeParent,
  onCompleteEdit
}) => {
  const client = useClient()
  useEffect(() => {
    if (isNil(vaTypes) || vaTypes.length === 0) {
      getVpTypes(client);
    }
  }, [vaTypes, getVpTypes, client]);

  const onRemoveRelationship = () => {
    if (dataItem.Type === 'Child') {
      removeParent(client, dataItem.Id, selectedAccount.miaAccountId, false).then(
        onCompleteEdit
      );
    } else if (dataItem.Type === 'Parent') {
      removeParent(
        client, 
        selectedAccount.miaAccountId,
        dataItem.Id,
        selectedAccount.miaAccountIsVirtualAccount
      ).then(onCompleteEdit);
    }
  };

  const isChild = dataItem.Type === 'Child';

  return (
    <div className="mx-1 my-2">
      <h3 className="mb-2">
        Edit Relationship with {selectedAccount.miaAccountName} |{' '}
        {selectedAccount.miaAccountCode}
      </h3>
      <div className="d-flex">
        <div className="flex-shirk-1">
          <h5 className="text-uppercase">Relationship Type</h5>
          <h4 className="fixed-relationship">{isChild ? 'Child' : 'Parent'}</h4>
        </div>

        <div className="flex-shirk-1 align-self-end ml-2">
          <button
            onClick={onRemoveRelationship}
            className="btn btn-default"
            disabled={false}
          >
            <i className='fa-solid fa-times me-2'></i>
            Remove relationship
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedAccount: state.currentSelectionReducer.selectedAccount,
    vaTypes: vaTypesList(state.vpReducer.vaTypes.data)
  };
};

export default connect(mapStateToProps, {
  getVpTypes: getVPTypes,
  removeParent
})(EditRelationship);
