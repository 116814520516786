import { IconButton } from "invevo-react-components"
import classes from "./FileExportConfigs.module.scss"
import { FileExportConfigAction, FileExportConfigActionEnum, FileExportConfigDashboardState } from "../../../reducers/FileExportConfigReducer"
import FileExportConfigCard from "./FileExportConfigCard"

type FileExportConfigsProps = {
    state: FileExportConfigDashboardState
    dispatch: React.Dispatch<FileExportConfigAction>
}

const FileExportConfigs = ({ state, dispatch }: FileExportConfigsProps) => {
    const onAddConfigClicked = () =>
        dispatch({
            type: FileExportConfigActionEnum.FILE_EXPORT_CONFIG_ADDED
        })

    return (
        <div className={`${classes.container} bg-grey px-4`} aria-label="File Export Configs">
            <div className="d-flex flex-column py-2 w-100">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="text-grey no-select fs-2">File Exporter</span>
                    <IconButton iconClasses="fal fa-plus" onClick={onAddConfigClicked} ariaLabel="Add file export config" />
                </div>
            </div>

            {state.configs.length > 0 ? (
                <div className="d-flex flex-column h-100 overflow-auto">
                    {state.configs.map(config => (
                        <FileExportConfigCard
                            key={config.reference}
                            config={config}
                            dispatch={dispatch}
                            isSelected={config.reference === state.editingConfig?.reference}
                        />
                    ))}
                </div>
            ) : (
                <span className="fs-4 text-grey">You have no file export configurations</span>
            )}
        </div>
    )
}

export default FileExportConfigs
