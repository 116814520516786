import React from 'react';
import AccountCard from './accountCard';
import AccountDetail from './accountDetail';

const AddressCard = ({ address, title, open, setOpen }) => {
  const renderLine = (line) => (line ? <p>{line}</p> : null);

  return address ? (
    <AccountCard title={title} open={open} setOpen={setOpen}>
      <AccountDetail copyToClipboard>
        <div>
          {renderLine(address.AddressLine1)}
          {renderLine(address.AddressLine2)}
          {renderLine(address.AddressLine3)}
          {renderLine(address.AddressLine4)}
          {renderLine(address.AddressLine5)}
          {renderLine(address.AddressLine6)}
          {renderLine(address.AddressLine7)}
        </div>
      </AccountDetail>
    </AccountCard>
  ) : null;
};

export default AddressCard;
