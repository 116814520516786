import { Handle, HandleType, Position } from "reactflow"
import classes from "./ReactFlowNode.module.scss"
import FlowNode from "../FlowNode"
import IconButton from "../../buttons/IconButton/IconButton"

export type ReactFlowColour = "success" | "danger" | "warning" | "secondary" | "primary"

type ReactFlowBackground = "bg-success" | "bg-danger" | "bg-warning" | "bg-secondary" | "bg-primary"

type ReactFlowNodeOutput = { reference: string; displayName: string }

type ReactFlowNodeProps = {
    state: FlowNode
    icon?: string
    colour: ReactFlowColour
    showMainInputHandle?: boolean
    showMainOutputHandle?: boolean
    showInputHandles?: boolean | ((key: string) => boolean)
    showOutputHandles?: boolean | ((key: string) => boolean)
    showDeleteButton?: boolean
    showCollapseButton?: boolean
    swapHandles?: boolean
    outputs?: ReactFlowNodeOutput[]
    relationshipFields?: string[]
    onNodeCollapseToggle?: (reference: string) => void
    onNodeDeleteClick: (reference: string) => void
}

const ReactFlowNode = ({
    state,
    colour,
    icon,
    showMainInputHandle = false,
    showMainOutputHandle = false,
    showInputHandles = false,
    showOutputHandles = true,
    showDeleteButton = false,
    showCollapseButton = false,
    swapHandles = false,
    outputs = [],
    relationshipFields = [],
    onNodeCollapseToggle = () => {},
    onNodeDeleteClick
}: ReactFlowNodeProps) => {
    const background: ReactFlowBackground = `bg-${colour}`

    const onDeleteClick = () => onNodeDeleteClick(state.reference)

    const onCollapseClick = () => onNodeCollapseToggle(state.reference)

    return (
        <div className={`d-flex flex-column rounded no-select ${classes.node} ${classes[colour]}`}>
            <div className={`${background} ${classes.nodeTopBorder} rounded-top`} />
            <div className="d-flex flex-column bg-grey rounded-bottom">
                <div className="d-flex align-items-center p-2">
                    {state &&
                        renderHeaderHandle(
                            state,
                            "left",
                            showMainInputHandle,
                            showMainOutputHandle,
                            swapHandles,
                            !!state.collapsed,
                            background,
                            relationshipFields
                        )}
                    {icon && <i className={`${icon} text-blue mx-1 fs-5`} />}
                    <span className="text-black fw-bold small ms-1 me-auto">{state.displayName}</span>
                    {showCollapseButton && (
                        <i
                            className={`fa-solid ${!state.collapsed ? "fa-chevron-down" : "fa-chevron-right"} text-grey ms-4 fs-6`}
                            role="button"
                            onClick={onCollapseClick}
                        />
                    )}
                    {showDeleteButton && (
                        <IconButton icon="fa-solid fa-trash-can" className="ms-4 fs-6" theme={"icon-only"} size="small" onClick={onDeleteClick} />
                    )}
                    {state &&
                        renderHeaderHandle(
                            state,
                            "right",
                            showMainInputHandle,
                            showMainOutputHandle,
                            swapHandles,
                            !!state.collapsed,
                            background,
                            relationshipFields
                        )}
                </div>
                {state.description !== "" && (
                    <>
                        <div className={`${classes.separator} d-flex bg-dark-gradient`} />
                        <span className={`p-2 text-break ${classes.description}`}>{state.description}</span>
                    </>
                )}
                {!state.collapsed && outputs.length > 0 && (
                    <>
                        <div className={`${classes.separator} d-flex bg-dark-gradient`} />
                        <div className="d-flex flex-grow-1 flex-column align-items-end gap-2 p-2">
                            {outputs.map(output => (
                                <div key={output.reference} className="d-flex flex-grow-1 align-items-center">
                                    {getValue(swapHandles ? showOutputHandles : showInputHandles, output.displayName) && (
                                        <Handle
                                            id={output.reference + (swapHandles ? "" : "-left")}
                                            className={`${background} ${classes.customHandle}`}
                                            type={swapSourceAndTarget(swapHandles, "target")}
                                            position={Position.Left}
                                        />
                                    )}
                                    <span className="text-black small">{output.displayName}</span>
                                    {getValue(swapHandles ? showInputHandles : showOutputHandles, output.displayName) && (
                                        <Handle
                                            id={output.reference + (swapHandles ? "-left" : "")}
                                            className={`${background} ${classes.customHandle}`}
                                            type={swapSourceAndTarget(swapHandles, "source")}
                                            position={Position.Right}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default ReactFlowNode

const swapSourceAndTarget = (swapHandles: boolean, primaryRole: HandleType): HandleType => {
    if (swapHandles) {
        if (primaryRole === "source") return "target"
        if (primaryRole === "target") return "source"
    }
    return primaryRole
}

const renderHeaderHandle = (
    state: FlowNode,
    position: "left" | "right",
    showMainInputHandle: boolean,
    showMainOutputHandle: boolean,
    swapHandles: boolean,
    showOutputs: boolean,
    background: ReactFlowBackground,
    relationshipFields: string[],
    showOutputHandles?: boolean | ((key: string) => boolean)
) => {
    const shouldShowMainInputHandle =
        (showMainInputHandle && position === "left" && !swapHandles) || (showMainInputHandle && position === "right" && swapHandles)
    const shouldShowMainOutputHandle =
        (showMainOutputHandle && position === "left" && swapHandles) || (showMainOutputHandle && position === "right" && !swapHandles)
    const shouldShowOutputs =
        getValue(showOutputHandles, "") && ((showOutputs && position === "right" && !swapHandles) || (showOutputs && position === "left" && swapHandles))
    return (
        <>
            {shouldShowMainInputHandle && (
                <Handle
                    id={state.reference}
                    className={`${background} ${classes.customHandle}`}
                    type={"target"}
                    position={swapHandles ? Position.Right : Position.Left}
                />
            )}
            {shouldShowMainOutputHandle && (
                <Handle
                    id={state.reference}
                    className={`${background} ${classes.customHandle}`}
                    type={"source"}
                    position={swapHandles ? Position.Left : Position.Right}
                />
            )}
            {shouldShowOutputs &&
                relationshipFields.map(f => (
                    <Handle
                        id={f}
                        className={`${background} ${classes.customHandle}`}
                        type={swapSourceAndTarget(swapHandles, "source")}
                        position={Position.Right}
                    />
                ))}
        </>
    )
}

const getValue = <TResult, TKey>(val: TResult | ((key: TKey) => TResult), key: TKey) => (val instanceof Function ? val(key) : val)
