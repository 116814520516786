import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  showToastSuccessMessage,
  showToastWarningMessage
} from '../../api/toasterApi';
import { IconButton } from '../common/buttons/iconButton';
import AccountCard from './accountCard';
import AccountDetail from './accountDetail';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ValidatingInput from '../common/ValidatingInput';
import {
  getParentDataById,
  updateVirtualAccount,
  getVPTypes,
  getBranches
} from '../../api/vpApi';

import Formsy from 'formsy-react';
import StatefulSelect from '../common/statefulSelect';
import {
  vaTypesList,
  ledgersList,
  brandsList,
  hasVPEditPermission
} from '../../selectors/virtualParent';
import { getLookup } from '../../api/lookupApi';
import { useClient } from 'invevo-react-components'

const VirtualParentEditCard = ({
  title,
  vpData,
  accountId,
  open,
  setOpen,
  isVirtualParent,
  vaTypes,
  brands,
  ledgers,
  hasVpEditPermission,
  getLookup,
  getVPTypes,
  getBranches,
  getParentDataById,
  updateVirtualAccount
}) => {
  const client = useClient()
  const hasChanged = vpData.msg !== '' && !vpData.isLoading;
  useEffect(() => {
    if (isVirtualParent) {
      getParentDataById(client, accountId);
    }
  }, [accountId, isVirtualParent, getParentDataById, hasChanged, client]);

  useEffect(() => {
    getVPTypes(client);
    getBranches(client);
    getLookup(client, 'lookup/Ledgers', 'ledgers');
    getLookup(client, 'lookup/Brands', 'brands');
  }, [getVPTypes, getBranches, getLookup, client]);

  const [editMode, setEditMode] = useState(false);
  const [rollbackForm, setRollbackForm] = useState();
  const [formData, setFormData] = useState({
    Id: accountId,
    name: '',
    isCreditHolder: false,
    languageId: null,
    branchId: null,
    virtualAccountTypeId: null,
    ledgerId: null,
    brandId: null
  });
  useDeepCompareEffect(() => {
    const form = {
      Id: accountId,
      name: vpData.name,
      isCreditHolder: vpData.isCreditHolder,
      languageId: vpData.languageId,
      branchId: vpData.branchId,
      virtualAccountTypeId: vpData.virtualAccountTypeId,
      msg: '',
      brandId: vpData.brandId,
      ledgerId: vpData.ledgerId
    };
    setFormData(form);
    setRollbackForm(form);
    if (hasChanged) {
      if (vpData.msg === 'Virtual Parent updated')
        showToastSuccessMessage(vpData.msg);
      else showToastWarningMessage(vpData.msg);
    }
  }, [vpData, hasChanged]);

  const onToggleEdit = () => {
    setEditMode(!editMode);

    if (!editMode) {
      setRollbackForm({ ...formData });
    }
  };

  function onChange(event) {
    const field = event.target.name;
    const value = event.target.value;
    setFormData({ ...formData, [field]: value });
  }

  function onChangeVpType(value) {
    setFormData({ ...formData, virtualAccountTypeId: value });
  }

  function onChangeBrand(value) {
    setFormData({ ...formData, brandId: value });
  }

  function onChangeLedger(value) {
    setFormData({ ...formData, ledgerId: value });
  }

  function onSubmit() {
    updateVirtualAccount(client, formData);
    setEditMode(false);
  }

  function onCancel() {
    setFormData(rollbackForm);
    setEditMode(false);
  }

  function onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  const foundBrand = brands
    ? brands.find((b) => b.value === formData.brandId)
    : null;
  const selectedBrandName = foundBrand ? foundBrand.label : '';

  const foundLedger = ledgers
    ? ledgers.find((l) => l.value === formData.ledgerId)
    : null;
  const selectedLedgerName = foundLedger ? foundLedger.label : '';

  const foundType = vaTypes
    ? vaTypes.find((t) => t.value === formData.virtualAccountTypeId)
    : null;
  const selectedTypeName = foundType ? foundType.label : '';
  const valid =
    formData.name && formData.name.length >= 3 &&
    formData.name.length <= 255 &&
    formData.virtualAccountTypeId !== null;
  return (
    <Formsy onKeyPress={onKeyPress}>
      <AccountCard title={title} open={open} setOpen={setOpen}>
        {!editMode ? (
          <>
            <AccountDetail>
              <div className="d-flex">
                <div className="flex-grow-1 mr-3">
                  <h4 className="text-break mt-2">{formData.name}</h4>
                </div>
                <div>
                  {hasVpEditPermission ? (
                    <IconButton
                      onClick={onToggleEdit}
                      title="Edit"
                      icon={'edit'}
                    ></IconButton>
                  ) : null}
                </div>
              </div>
            </AccountDetail>
            <AccountDetail title="Type" value={selectedTypeName} />
            <AccountDetail title="Brand" value={selectedBrandName} />
            <AccountDetail title="Ledger" value={selectedLedgerName} />
          </>
        ) : (
          <AccountDetail>
            <div className="d-flex">
              <div className="flex-grow-1 mr-3">
                <h3>Edit Virtual Parent</h3>
              </div>

              <div>
                <IconButton
                  onClick={onToggleEdit}
                  title="Cancel"
                  icon={'times-circle'}
                ></IconButton>
              </div>
            </div>

            <h3 className="mt-2">Name</h3>
            <ValidatingInput
              hideLabel={true}
              name="name"
              className="my-2"
              value={formData.name}
              placeholder="Name"
              validations={{ minLength: 3, maxLength: 255 }}
              validationError={'Must be between 3 and 255 characters.'}
              onChange={onChange}
              required
            />

            <h3 className="mt-2">Type</h3>
            <StatefulSelect
              className="mt-2"
              options={vaTypes}
              optionId={formData.virtualAccountTypeId}
              onChange={onChangeVpType}
            />
            <h3 className="mt-2">Brand</h3>
            <StatefulSelect
              className="mt-2"
              options={brands}
              optionId={formData.brandId}
              onChange={onChangeBrand}
            />
            <h3 className="mt-2">Ledger</h3>
            <StatefulSelect
              className="mt-2"
              options={ledgers}
              optionId={formData.ledgerId}
              onChange={onChangeLedger}
            />
            <div className="d-flex mt-3 justify-content-between">
              <button onClick={onCancel} className="btn btn-default">
                Cancel
              </button>
              <button
                onClick={onSubmit}
                className="btn btn-primary"
                disabled={!valid}
              >
                Update
              </button>
            </div>
          </AccountDetail>
        )}
      </AccountCard>
    </Formsy>
  );
};

const mapStateToProps = (state) => {
  return {
    vaTypes: vaTypesList(state.vpReducer.vaTypes.data),
    virtualAccountId: state.vpReducer.virtualAccountId,
    // languages: state.lookupReducer.availableLanguages,
    ledgers: ledgersList(state.lookupReducer.ledgers.results),
    brands: brandsList(state.lookupReducer.brands.results),
    vpData: state.vpReducer.vaUpdData,
    // vaBranches: state.vpReducer.vaBranches,
    hasVpEditPermission: hasVPEditPermission(state.authReducer.permissions),
    accountId: state.currentSelectionReducer.selectedAccount.miaAccountId,
    isVirtualParent:
      state.currentSelectionReducer.selectedAccount.miaAccountIsVirtualAccount
  };
};
export default connect(mapStateToProps, {
  getParentDataById,
  updateVirtualAccount,
  getVPTypes,
  getBranches,
  getLookup
})(VirtualParentEditCard);
