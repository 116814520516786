import { Input, IconButton, Checkbox, DatePicker } from "invevo-react-components"
import { dataPrimitive } from "../../../enums/dataPrimitive"

const LookupEntry = ({ field, entry, removeLookupEntry, onLookupChange, selectedLookup }) => {
    const onInputChange = event => onLookupEntryChange(event.target.value)
    const onCheckboxStateChange = () => onLookupEntryChange(!getEntry())
    const onDateInputChange = date => onLookupEntryChange(date.toISOString())
    const onLookupEntryRemoved = () => removeLookupEntry(entry.id)

    const parseDate = () => {
        const date = getEntry()

        if (!date) {
            return;
        }
        return Date.parse(date)
    }

    const displayDate = () => {
        const date = getEntry()

        if (!date) {
            return;
        }
        return date.substring(0, 10)
    }

    const onLookupEntryChange = value => {
        const index = selectedLookup.entries.findIndex(e => e.id === entry.id)
        onLookupChange({
            ...selectedLookup,
            entries: [
                ...selectedLookup.entries.slice(0, index),
                {
                    ...entry,
                    ...setEntry(value)
                },
                ...selectedLookup.entries.slice(index + 1)
            ]
        })
    }

    const onLookupEntryStatusChange = () => {
        const index = selectedLookup.entries.findIndex(e => e.id === entry.id)
        onLookupChange({
            ...selectedLookup,
            entries: [
                ...selectedLookup.entries.slice(0, index),
                {
                    ...entry,
                    editable: !entry.editable
                },
                ...selectedLookup.entries.slice(index + 1)
            ]
        })
    }

    const setEntry = value => {
        if (field.field === "reference")
            return { reference: value }

        if (field.field === "name")
            return { name: value }

        switch (field.dataPrimitive) {
            case dataPrimitive.BOOLEAN:
                return {
                    booleans: {
                        ...entry.booleans,
                        [field.field]: value
                    }
                }
            case dataPrimitive.DATE:
                return {
                    dateTimes: {
                        ...entry.datesTimes,
                        [field.field]: value
                    }
                }
            case dataPrimitive.NUMBER:
                return {
                    bigDecimals: {
                        ...entry.bigDecimals,
                        [field.field]: value
                    }
                }
            case dataPrimitive.TEXT:
            default:
                return {
                    strings: {
                        ...entry.strings,
                        [field.field]: value
                    }
                }
        }
    }

    const getEntry = () => {
        if (field.field === "reference")
            return entry.reference

        if (field.field === "name")
            return entry.name

        switch (field.dataPrimitive) {
            case dataPrimitive.BOOLEAN:
                return entry.booleans[field.field]
            case dataPrimitive.DATE:
                return entry.dateTimes[field.field]
            case dataPrimitive.NUMBER:
                return entry.bigDecimals[field.field]
            case dataPrimitive.TEXT:
            default:
                return entry.strings[field.field]
        }
    }

    const getEditableRow = () => {
        if (field.field === "reference") {
            return (
                <div className="d-flex h-100 align-items-center">
                    <div className="d-flex">
                        <IconButton
                            className=""
                            iconClasses="fal fa-trash m-auto"
                            onClick={onLookupEntryRemoved}
                        />
                        <IconButton
                            className=" ps-2"
                            iconClasses="fal fa-pen m-auto"
                            onClick={onLookupEntryStatusChange}
                        />
                    </div>
                    <Input
                        className={`h-100 w-100 justify-content-center ps-4`}
                        placeholder="Please fill out"
                        ariaLabel={`${field.field}-input`}
                        value={getEntry()}
                        onChange={onInputChange}
                    />
                </div>
            )
        }

        switch (field.dataPrimitive) {
            case dataPrimitive.BOOLEAN:
                return (
                    <div className="d-flex h-100 align-items-center justify-content-end">
                        <Checkbox
                            disabled={false}
                            ariaLabel={`${field.field}-checkbox`}
                            isChecked={getEntry()}
                            onClick={onCheckboxStateChange}
                        />
                    </div>
                )
            case dataPrimitive.DATE:
                return (
                    <div className="d-flex h-100 pt-1 justify-content-center">
                        <DatePicker
                            selectedDate={parseDate()}
                            onChange={onDateInputChange}
                            option={{ withPortal: true }}
                        />
                    </div>
                )
            case dataPrimitive.NUMBER:
                return (
                    <Input
                        className="h-100 justify-content-center"
                        type="number"
                        step="0.1"
                        value={getEntry()}
                        ariaLabel={`${field.field}-input`}
                        onChange={onInputChange}
                    />
                )
            case dataPrimitive.TEXT:
            default:
                return (
                    <Input
                        className="h-100 justify-content-center"
                        placeholder="Please fill out"
                        value={getEntry()}
                        ariaLabel={`${field.field}-input`}
                        onChange={onInputChange}
                    />
                )
        }
    }

    const getRow = () => {
        if (field.field === "reference") {
            return (
                <div className="d-flex h-100 justify-content-between align-items-center">
                    <div className="d-flex">
                        <IconButton
                            className=""
                            iconClasses="fal fa-trash m-auto"
                            onClick={onLookupEntryRemoved}
                        />
                        <IconButton
                            className="ps-2"
                            iconClasses="fal fa-pen m-auto"
                            onClick={onLookupEntryStatusChange}
                        />
                    </div>
                    <h4>{getEntry()}</h4>
                </div>
            )
        }

        switch (field.dataPrimitive) {
            case dataPrimitive.BOOLEAN:
                return (
                    <div className="d-flex h-100 align-items-center justify-content-end">
                        <Checkbox
                            disabled={true}
                            isChecked={getEntry()}
                            onClick={onCheckboxStateChange}
                        />
                    </div>
                )
            case dataPrimitive.DATE:
                return (
                    <div className="d-flex h-100 align-items-center justify-content-end">
                        <h4 className="mb-0">{displayDate()}</h4>
                    </div>
                )
            case dataPrimitive.NUMBER:
                return (
                    <div className="d-flex h-100 align-items-center justify-content-end">
                        <h4 className="mb-0">{getEntry()}</h4>
                    </div>
                )
            case dataPrimitive.TEXT:
            default:
                return (
                    <div className="d-flex h-100 align-items-center justify-content-end">
                        <h4 className="mb-0">{getEntry()}</h4>
                    </div>
                )
        }
    }

    return (
        entry.editable ? getEditableRow() : getRow()
    )
}

export default LookupEntry