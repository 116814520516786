import React from 'react';
import { connect } from 'react-redux';

import Formsy from 'formsy-react';
import ValidatingInput from '../common/ValidatingInput';
import TextInput from '../common/textInput';
import {
  saveContact,
  saveVAContact,
  updateContact,
  updateVAContact
} from '../../api/functionApi';
import { getLookup } from '../../api/lookupApi';
import { last, filter } from 'lodash';
import {
  executeAuthAsyncGet,
  executeAuthAsyncPost,
  executeAsyncResetGet
} from '../../utility/asyncSupport';
import { showToastSuccessMessage, showToastErrorMessage } from '../../api/toasterApi';

import { Modal, Button } from 'react-bootstrap';
import { ConfrimDialog } from '../common/confirmDialog';

export class AddEditCustomerContact extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.defaultFormState();
  }

  defaultFormState = () => {
    return {
      isNew: true,
      contact: {
        customerId: this.props.selectedAccount.miaAccountId,
        name: '',
        phone: '',
        mobile: '',
        email: '',
        languageId: 1,
        type: 'Customer',
        customerContactTypeId: '',
        defaultCustomerContact: false,
        smsRecipient: true,
        canDelete: false
      },
      canSubmit: false,
      formInputsCanSave: false,
      show: false,
      showDeleteDialog: false
    };
  };

  showDelete = () => this.setState({ showDeleteDialog: true });
  closeDialog = () => this.setState({ showDeleteDialog: false });

  delete = () => {
    const id = this.state.contact.id;
    if (id) {
      this.closeDialog();
      this.props.executeAuthAsyncPost(
        this.props.client,
        'Contact/Delete',
        'DELETE_CONTACT',
        id,
        (result) => {
          showToastSuccessMessage(result);
          this.setState({ ...this.defaultFormState(), show: false });
          if (this.props.onContactSaved != null) {
            this.props.onContactSaved();
          }
        }
      );
    }
  };

  add = () => this.setState({ ...this.defaultFormState(), show: true });

  hide = () => this.setState({ show: false });

  edit = (guid) => {
    this.setState({
      ...this.defaultFormState(),
      isNew: false,
      contactId: guid
    });

    if (this.props.selectedAccount.miaAccountIsVirtualAccount) {
      this.props.executeAuthAsyncGet(
        this.props.client,
        'virtualaccount/getvacontact',
        'VACONTACT',
        { contactId: guid },
        this.onContactLoaded
      );
    } else {
      this.props.executeAuthAsyncGet(
        this.props.client,
        'Contact/GetContact',
        'CONTACT',
        { contactId: guid },
        this.onContactLoaded
      );
    }
  };

  onContactLoaded = () => {
    const contact = this.props.selectedAccount.miaAccountIsVirtualAccount
      ? this.props.VACONTACT.result
      : this.props.CONTACT.result;

    this.setState(
      {
        contact: {
          id: contact.id,
          customerId: contact.customerId,
          name: contact.name,
          phone: contact.phone,
          mobile: contact.mobile,
          email: contact.email,
          languageId: contact.languageId,
          type: contact.type,
          customerContactTypeId: contact.customerContactTypeId,
          defaultCustomerContact: contact.defaultCustomerContact,
          addressId: contact.addressId,
          smsRecipient: contact.smsRecipient,
          canDelete: contact.canDelete
        },
        show: true
      },
      this.manualValidate
    );
  };

  loadCurrentValidatedContactIds() {
    this.props.getLookup(
      this.props.client,
      'contact/externalcontactsvalidatedlist?customerId=' +
      this.props.selectedAccount.miaAccountId,
      'validatedContactIds'
    );
  }

  save = () => {
    if (
      !this.canSaveContact(this.state.contact) &&
      this.props.globalConfiguration.contactValidation === true
    ) {
      showToastErrorMessage(
        'Saving multiple entries for this contact type is not allowed'
      );
      return;
    }

    this.state.isNew ? this.saveNew() : this.update();
  };

  saveNew = () => {
    if (this.props.selectedAccount.miaAccountIsVirtualAccount) {
      this.props.saveVAContact(
        this.props.client,
        this.state.contact,
        this.onSuccess,
        this.onError
      );
    } else {
      this.props.saveContact(this.props.client, this.state.contact, this.onSuccess, this.onError);
    }
  };

  update = () => {
    if (this.props.selectedAccount.miaAccountIsVirtualAccount) {
      this.props.updateVAContact(
        this.props.client,
        this.state.contact,
        this.onSuccess,
        this.onError
      );
    } else {
      this.props.updateContact(
        this.props.client,
        this.state.contact,
        this.onSuccess,
        this.onError
      );
    }
  };

  onSuccess = () => {
    showToastSuccessMessage(
      this.state.isNew ? 'Contact added' : 'Contact saved'
    );
    this.loadCurrentValidatedContactIds();
    this.setState({ ...this.defaultFormState(), show: false });
    if (this.props.onContactSaved != null) {
      this.props.onContactSaved();
    }
  };

  onError = (err) => {
    err.json().then((object) => {
      if (object && object.Message) {
        showToastErrorMessage(object.Message);
      } else {
        showToastErrorMessage(
          this.state.isNew ? 'Contact NOT added' : 'Contact NOT saved'
        );
      }
    });
  };

  canSaveContact = (contact) => {
    if (contact.customerContactTypeId === '') {
      return false;
    }

    const contactIds = filter(
      this.props.validatedContactTypeIds,
      (contactTypeId) => contactTypeId === contact.customerContactTypeId
    );

    const currentContactsWithSameType = filter(
      this.props.validatedContactIds,
      (currentContact) =>
        currentContact.CustomerContactTypeId ===
        contact.customerContactTypeId &&
        currentContact.ContactId !== contact.id
    );

    if (currentContactsWithSameType.length > 0 && contactIds.length > 0) {
      return false;
    }

    return true;
  };

  enableFormInputSave = () => {
    this.setState({ formInputsCanSave: true });
  };

  disableFormInputSave = () => {
    this.setState({ formInputsCanSave: false });
  };

  canSaveForm = () => {
    return this.state.canSubmit && this.state.formInputsCanSave;
  };

  manualValidate = () => {
    const submitOn =
      this.state.contact.name &&
      this.state.contact.name.length > 2 &&
      this.state.contact.name.trim() !== '' &&
      this.state.contact.customerContactTypeId !== '';

    this.setState({ canSubmit: submitOn });
  };

  onChange = (event) => {
    const field = event.target.name;
    const value = event.target.value;
    this.onNameValueChange(field, value);
  };

  onNameValueChange = (name, value) => {
    this.setState(
      (prevState) => ({
        contact: { ...prevState.contact, [name]: value }
      }),
      this.manualValidate
    );
  };

  onChangeDefault = (event) => {
    this.onNameValueChange('defaultCustomerContact', event.target.checked);
  };

  onChangeSmsRecipient = (event) => {
    this.onNameValueChange('smsRecipient', event.target.checked);
  };

  componentDidMount() {
    this.loadCurrentValidatedContactIds();
  }

  render() {
    const formData = this.state.contact;
    const errStrLen = 'Must be between 3 and 255 characters.';
    const errEMail = 'Email address is not well formed.';
    const errPhone = 'Must be at least 10 characters.';
    const lId = formData['languageId'];
    let langTitle = 'Language/Region';
    if (this.props.availableLanguages && this.props.availableLanguages.length > 0) {
      const foundLanguage = this.props.availableLanguages.find(
        (t) => t.Id === lId
      );
      if (foundLanguage != null) {
        langTitle = 'Language/Region: ' + foundLanguage.Description;
      }
    }
    const languages = this.props.availableLanguages.map((t) => {
      return (
        <li
          key={t.Id}
          className="dropdown-item"
          onClick={this.onNameValueChange.bind(this, 'languageId', t.Id)}
        >
          {t.Description}
        </li>
      );
    });

    const cctId = formData['customerContactTypeId'];
    let cctTitle = 'Customer Contact Type';
    let customerContactTypes = [];
    const ccts = this.props.contactTypes;
    if (ccts && ccts.length > 0) {
      const foundType = ccts.find((t) => t.id === cctId);
      if (foundType) {
        cctTitle = 'Customer Contact Type: ' + foundType.text;
      }
      customerContactTypes =
        ccts
          .filter(t => t.IsInUse)
          .map((t) => {
            return (
              <li
                key={t.id}
                className="dropdown-item"
                onClick={this.onNameValueChange.bind(
                  this,
                  'customerContactTypeId',
                  t.id
                )}
              >
                {t.text}
              </li>
            );
          });
    }

    const addressId = formData['addressId'];
    let addressTitle = 'Address';
    let addresses = [];
    const dbAddresses = this.props.customerAddresses;
    if (dbAddresses && dbAddresses.length > 0) {
      const foundType = dbAddresses.find((t) => t.id === addressId);
      if (foundType) {
        addressTitle = 'Address: ' + foundType.text;
      }

      addresses = this.props.customerAddresses.map((t) => {
        return (
          <li
            key={t.id}
            className="dropdown-item"
            onClick={this.onNameValueChange.bind(this, 'addressId', t.id)}
          >
            {t.text}
          </li>
        );
      });
    }

    const canDelete = !this.state.isNew && this.state.contact.canDelete;

    return (
      <>
        <Modal show={this.state.show} onHide={this.hide}>
          <Modal.Header>
            <Modal.Title>
              {this.state.isNew
                ? 'Add New Customer Contact'
                : 'Edit Customer Contact'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formsy
              key="customer-contact-form"
              className="form-group"
              onValid={this.enableFormInputSave}
              onInvalid={this.disableFormInputSave}
            >
              <ValidatingInput
                name="name"
                value={formData.name}
                placeholder="Name"
                validations={{ minLength: 3, maxLength: 255 }}
                validationError={errStrLen}
                onChange={this.onChange}
                required
              />
              <ValidatingInput
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={this.onChange}
                validationError={errPhone}
                validations={{ minLength: 10, maxLength: 50 }}
              />
              <TextInput
                name="mobile"
                placeholder="Mobile"
                label=""
                value={formData.mobile}
                onChange={this.onChange}
                validationError={errPhone}
                validations={{ minLength: 10, maxLength: 50 }}
              />
              <ValidatingInput
                name="email"
                value={formData.email}
                placeholder="Email"
                validations="isEmail"
                validationError={errEMail}
                onChange={this.onChange}
              />

              <div className="form-group">
                <ul className="nav" id="langLst">
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      id="dropdownMenuButton"
                      className="nav-link dropdown-toggle boldTitle noPaddingLeft"
                      data-bs-toggle="dropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      {langTitle}
                      <b className="caret"></b>
                    </a>
                    <ul aria-labelledby="dropdownMenuButton" className="dropdown-menu multi-level dropdown-scroll">
                      {languages}
                    </ul>
                  </li>
                </ul>
              </div>

              <div className="form-group">
                <ul className="nav" id="cctLst">
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      id="dropdownMenuButton"
                      className="nav-link dropdown-toggle boldTitle noPaddingLeft"
                      data-bs-toggle="dropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      {cctTitle}
                      <b className="caret"></b>
                    </a>
                    <ul aria-labelledby="dropdownMenuButton" className="dropdown-menu multi-level dropdown-scroll">
                      {customerContactTypes}
                    </ul>
                  </li>
                </ul>
              </div>

              <div className="form-group">
                <ul className="nav" id="addressList">
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      id="dropdownMenuButton"
                      className="nav-link dropdown-toggle boldTitle noPaddingLeft"
                      data-bs-toggle="dropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      {addressTitle}
                      <b className="caret"></b>
                    </a>
                    <ul aria-labelledby="dropdownMenuButton" className="dropdown-menu multi-level dropdown-scroll">
                      {addresses}
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="form-group">
                <label className="my-label">
                  <input
                    type="checkbox"
                    checked={formData.defaultCustomerContact}
                    onChange={this.onChangeDefault}
                  />{' '}
                  Default Contact
                </label>
              </div>

              <div className="form-group">
                <label className="my-label">
                  <input
                    type="checkbox"
                    checked={formData.smsRecipient}
                    onChange={this.onChangeSmsRecipient}
                  />{' '}
                  SMS Recipient
                </label>
              </div>
            </Formsy>
          </Modal.Body>
          <Modal.Footer>
            {canDelete && (
              <div className="flex-grow-1">
                <Button variant="default" onClick={this.showDelete}>
                  Delete
                </Button>
              </div>
            )}

            <Button variant="default" onClick={this.hide}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={this.save}
              disabled={!this.canSaveForm()}
            >
              {this.state.isNew ? 'Save' : 'Update'}
            </Button>
          </Modal.Footer>
        </Modal>

        <ConfrimDialog
          show={this.state.showDeleteDialog}
          message={'Are you sure you want to remove this contact?'}
          onConfirm={this.delete}
          onClose={this.closeDialog}
        />
      </>
    );
  }
}

AddEditCustomerContact.propTypes = {};

const inUseLanguages = (availableLanguages) =>
  filter(availableLanguages.results, (t) => t.IsInUse);

const validatedContactTypeIds = (contactTypes) =>
  filter(
    contactTypes.result,
    (contactType) => contactType.ValidateDuplicateEntry
  ).map((v) => v.id);

const mapStateToProps = (state) => {
  const { asyncReducer, lookupReducer, currentSelectionReducer } = state;
  const {
    CONTACT,
    VACONTACT,
    CUSTOMER_CONTACT_TYPES,
    CUSTOMER_ADDRESSES
  } = asyncReducer;
  const { availableLanguages, validatedContactIds } = lookupReducer;
  const { selectedAccount } = currentSelectionReducer;

  let configuration = {};

  if (state.globalConfiguration && state.globalConfiguration.length > 0) {
    configuration = last(state.globalConfiguration);
  }

  return {
    availableLanguages: inUseLanguages(availableLanguages),
    validatedContactTypeIds: validatedContactTypeIds(CUSTOMER_CONTACT_TYPES),
    contactTypes: CUSTOMER_CONTACT_TYPES.result,
    validatedContactIds: validatedContactIds.result,
    CONTACT,
    VACONTACT,
    customerAddresses: CUSTOMER_ADDRESSES.result,
    selectedAccount,
    globalConfiguration: configuration
  };
};

export default connect(
  mapStateToProps,
  {
    getLookup,
    updateContact,
    updateVAContact,
    saveContact,
    saveVAContact,
    executeAuthAsyncGet,
    executeAuthAsyncPost,
    executeAsyncResetGet
  },
  null,
  {
    forwardRef: true
  }
)(AddEditCustomerContact);
