import ActionType from "../../types/actions/ActionType"
import GenericAction from "../../types/actions/GenericAction"
import LegacySmsTemplate from "../../types/LegacySmsTemplate"
import EmailActionInfo from "./EmailActionInfo"
import ExportActionInfo from "./ExportActionInfo"
import SmsActionInfo from "./SmsActionInfo"
import UpdateDataFieldActionInfo from "./UpdateDataFieldActionInfo"
import UpdateChildEntityActionInfo from "./UpdateChildEntityActionInfo"

type ActionInfoProps = {
    action: GenericAction
    smsTemplates: LegacySmsTemplate[]
    isBigTitle?: boolean
}

const ActionInfo = ({ action, smsTemplates, isBigTitle = false }: ActionInfoProps) => {
    if (action.type === ActionType.ASYNC_COMM_ACTION) {
        if (action.communicationType === "SMS") {
            const template = smsTemplates.find(t => t.reference === action.communicationTemplateReference)
            return <SmsActionInfo template={template} bigTitle={isBigTitle} />
        }

        if (action.communicationType === "EMAIL") {
            return <EmailActionInfo template={undefined} bigTitle={isBigTitle} />
        }
    }

    if (action.type === ActionType.UPDATE_DATA_FIELD) {
        return <UpdateDataFieldActionInfo bigTitle={isBigTitle} />
    }
    if (action.type === ActionType.UPDATE_CHILD_ENTITY) {
        return <UpdateChildEntityActionInfo />
    }
    if (action.type === ActionType.EXPORT_ACTION) {
        return <ExportActionInfo bigTitle={isBigTitle} />
    }

    return <span className={`${isBigTitle && "fs-5"} me-3`}>Unknown action</span>
}

export default ActionInfo
