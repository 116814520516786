export const lowerCaseKeys = <TValue>(oldObject: Record<string, TValue>) =>
    Object.entries(oldObject).reduce<Record<string, TValue>>((newObject, [key, value]) => {
        newObject[key.toLowerCase()] = value
        return newObject
    }, {})

export const deepEqual = (object1: object, object2: object): boolean => {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length) {
        return false
    }

    for (let key of keys1) {
        // @ts-ignore
        const val1: any = object1[key]
        // @ts-ignore
        const val2 = object2[key]
        const areObjects = isObject(val1) && isObject(val2)
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false
        }
    }

    return true
}

const isObject = (object: any): boolean => {
    return object != null && typeof object === "object"
}
