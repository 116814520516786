import { React, useState, useEffect } from "react";
import classes from "./EditAmountButton.module.scss";

const EditAmountButton = ({
  onEditAmountChanged,
  originalAmount,
  amount,
  currencyCode,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newAmount, setNewAmount] = useState(amount);

  useEffect(() => {
    if (isEditing) {
      return;
    }

    if (amount !== newAmount) {
      onEditAmountChanged(newAmount);
    }
  });

  const newAmountUpdateHandler = (event) => {
    if (event.target.value) {
      const input = parseFloat(event.target.value);

      if (originalAmount < 0 && (input < originalAmount || input > 0)) {
        return;
      }

      setNewAmount(parseFloat(input.toFixed(2)));
      return;
    }

    setNewAmount("");
  };

  const revert = () => {
    setNewAmount(originalAmount);
    setIsEditing(false);
  };

  return isEditing ? (
    <div
      className={`d-flex ${classes["action-button"]} no-select align-items-center`}
    >
      <div className={`d-flex h-100 px-2 ${classes.label}`}>
        <input
          type="number"
          className="my-auto border-0"
          value={newAmount}
          onChange={newAmountUpdateHandler}
        />
      </div>
      <div className={`${classes.icon} p-2 text-center pointer`}>
        <i
          onClick={() => setIsEditing(false)}
          className="small fal fa-check me-3"
        ></i>
        <i
          onClick={() =>
            amount !== newAmount ? revert() : setIsEditing(false)
          }
          className={`small fal ${
            amount !== newAmount ? "fa-undo" : "fa-times"
          }`}
        ></i>
      </div>
    </div>
  ) : (
    <div
      onClick={() => setIsEditing(true)}
      className={`d-flex ${classes["action-button"]} pointer no-select align-items-center`}
    >
      <div className={`d-flex align-self-stretch px-2 ${classes.label}`}>
        <span className="my-auto">
          { formatCurrencyAmount(amount, "en-GB", currencyCode) }
        </span>
      </div>
      <div className={`${classes.icon} p-2 text-center`}>
        <i className="small fal fa-pencil"></i>
      </div>
    </div>
  );
};
const formatCurrencyAmount = (value, culture, currencySymbol) =>
  new Intl.NumberFormat(culture, {
    style: "currency",
    currency: currencySymbol,
  }).format(value);

export default EditAmountButton;
