import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const CacheBuster = ({ children }) => {
    const history = useHistory()

    useEffect(() => {
        history.listen(() => {
            fetch('/latest-version.txt')
                .then(response => response.text())
                .then((latestVersion) => {
                    const currentVersion = getCurrentVersion()
                    if (!currentVersion) return

                    if (latestVersion !== currentVersion) {
                        window.location.reload(true)
                    }
                });
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return children;
}

const getCurrentVersion = () =>
    document.querySelector("meta[name='version']").getAttribute("content")

export default CacheBuster;