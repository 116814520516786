import { LogicOperatorOptions, useApi, useClient, Loading } from 'invevo-react-components'
import { useEffect, useState, useCallback } from 'react';
import { actionTypes } from '../../../reducers/actionTypes'
import { Filters, filterTypes } from '../filters/Filters';

const CustomerFilters = ({ state: customerGroup, dispatch, isConfigReader }) => {
    const api = useApi()
    const client = useClient()

    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [customerFields, setCustomerFields] = useState([])
    const [customerFieldValuesForAutoComplete, setCustomerFieldValuesForAutoComplete] = useState([])

    const getDataConfiguration = useCallback(() => {
        return new Promise((resolve, reject) => {
            api.get(`/api/${client}/data-import-config`)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    if (error.response.status === 404) resolve({ data: { fields: [] } })
                    else reject(error)
                })
        })
    }, [api, client])

    const getCustomerFields = useCallback(() => {
        return new Promise((resolve, reject) => {
            api.get(`/api/${client}/customerfields`)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }, [api, client])


    useEffect(() => {
        if (isFetching || hasFetched || !isConfigReader) return
        setIsFetching(true)

        getDataConfiguration()
            .then(response => {
                setHasFetched(true)
                const dataConfiguration = response.data
                if (dataConfiguration.fields.length > 0) {
                    setCustomerFields(
                        dataConfiguration.fields.filter(f => f.isAvailableInGroups)
                            .map((field, index) => {
                                return {
                                    id: index,
                                    value: field.fieldName
                                }
                            }))
                    setCustomerFieldValuesForAutoComplete(dataConfiguration.fields.filter(f => f.isAvailableInAutoComplete).map(f => f.fieldName))
                }
                else {
                    getCustomerFields().then(response => {
                        setCustomerFields(response.data.map((field, index) => {
                            return {
                                id: index,
                                value: field
                            }
                        }))
                        setCustomerFieldValuesForAutoComplete(response.data)
                    })
                }
                setIsFetching(false)
            })
            .catch(error => {
                console.log(error)
            })

    }, [api, client, customerFields, getDataConfiguration, getCustomerFields, isFetching, hasFetched, isConfigReader])

    const onRuleChange = (rule, ruleIndex) => {
        const updatedFilters = customerGroup.filters.map((filter, filterIndex) => {
            if (filterIndex !== ruleIndex) {
                return filter
            }

            let operatorValue = ""
            if (rule.operator) {
                operatorValue = rule.operator.value
            }

            return {
                ...filter, ...{
                    name: rule.field, value: rule.value, operator: operatorValue, isValid: rule.isValid
                }
            }
        })

        dispatch({ type: actionTypes.SELECTED_CUSTOMER_GROUP_UPDATED, data: { ...customerGroup, filters: updatedFilters } })
    }

    const onAddRule = () => {
        dispatch({
            type: actionTypes.SELECTED_CUSTOMER_GROUP_UPDATED,
            data: {
                ...customerGroup,
                filters: [...customerGroup.filters, { name: "", value: "" }]
            }
        })
    }

    const onRemoveRule = (_, ruleIndex) => {
        dispatch({
            type: actionTypes.SELECTED_CUSTOMER_GROUP_UPDATED,
            data: {
                ...customerGroup,
                filters: customerGroup.filters.filter((_, index) => index !== ruleIndex)
            }
        })
    }

    const onRemoveAll = () => {
        dispatch({
            type: actionTypes.SELECTED_CUSTOMER_GROUP_UPDATED,
            data: {
                ...customerGroup,
                filters: customerGroup.filters.slice(0, 0)
            }
        })
    }

    const getFilterTypeFromOperator = (operator) => {
        if (operator === LogicOperatorOptions.DATE_DAYS_AFTER_TODAY || operator === LogicOperatorOptions.DATE_DAYS_BEFORE_TODAY) {
            const dateFilterType = filterTypes.find(ft => ft.value === LogicOperatorOptions.DATE_IS_IN)
            return { ...dateFilterType, value: operator }
        }

        return filterTypes.find(ft => ft.value === operator)
    }

    const getRulesFromFilters = (filters) => {
        return filters.map(f => {
            return {
                field: f.name,
                value: f.value,
                operator: getFilterTypeFromOperator(f.operator),
                isValid: f.isValid
            }
        })
    }
    return (
        <div className="d-flex flex-column w-100 p-4">
            <Loading isLoading={isFetching} colour="blue">
                {!customerGroup.reference &&
                    <h5 className="text-grey">Please select or create a customer group</h5>
                }
                {customerGroup.reference &&
                    <Filters
                        rules={getRulesFromFilters(customerGroup.filters)}
                        onRuleChange={onRuleChange}
                        onAddRule={onAddRule}
                        onRemoveRule={onRemoveRule}
                        onRemoveAll={onRemoveAll}
                        inputType={isConfigReader ? "autocomplete" : "normal"}
                        fieldOptions={customerFields}
                        fieldsAvailableForAutoComplete={customerFieldValuesForAutoComplete}
                        autoCompleteUrl={`/api/${client}/customer-field-values-autocomplete`}
                        title="Customer Filters"
                        enableTooltip={true}
                        scrollable={true}
                    />
                }
            </Loading>
        </div>
    )
}

export default CustomerFilters