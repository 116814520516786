import { DndProvider } from "react-dnd"
import classes from "./Rows.module.scss"
import { HTML5Backend } from "react-dnd-html5-backend"
import FieldHeader from "./FieldHeader"
import DataRow from "./DataRow"
import { useGrid } from "../Grid"
import RowData from "./../types/RowData"
import Checkbox from "../../form/Checkbox"
import { ReactNode, useEffect, useState } from "react"
import EntityConfig from "../../../routes/entityConfig/types/EntityConfig"
import { DataPrimitiveTypeEnum, DataTypeEnum } from "../../../routes/entityConfig/types/DataType"
import useApiQuery from "../../../hooks/useApiQuery"
import { useEnvConfig } from "../../../contexts/EnvironmentConfigContext"
import useClient from "../../../hooks/useClient"
import { useFeatureToggle } from "../../../hooks/useFeatureToggle"
import RowAggregations from "./RowAggregations"
import { convertFromArrayToDto, convertFromDtoToArray } from "../../FilterList/FiltersDto"
import GenericFilter from "../../FilterList/filterTypes/GenericFilter"
import { getDataFieldsFromEntityDataFields } from "../../helpers/entityHelpers"
import { addSelectionFilter } from "../util"
import FiltersDto from "../../FilterList/FiltersDto"

type GridAggregationDto = SingleGroupedGridAggregationDto | SumGridAggregationDto

type SingleGroupedGridAggregationDto = {
    type: "SINGLE_GROUPED"
    groupingField: string
    aggregationField: string
    aggregationFieldType: DataPrimitiveTypeEnum
}

type SumGridAggregationDto = {
    type: "SUM"
    aggregationField: string
    aggregationFieldType: DataPrimitiveTypeEnum
    consistentGroup: string
}

type EntityGridAggregationsResponseDto = {
    results: EntityGridAggregationValueDto[]
}

export type EntityGridAggregationValueDto = {
    entityFieldReference: string
    totalByGroup: { [group: string]: number }
}

type Props = {
    selectableRows?: boolean
    showHeaderRow?: boolean
    multiSelect?: boolean
    entityConfig?: EntityConfig
    filters?: GenericFilter[]
    dashboardFilterGroups?: FiltersDto[]
    dashboardAppliedRoles?: string[]
    canWrap?: boolean
    renderRowActions?: (rowData: RowData) => ReactNode
}

const Rows = ({
    selectableRows = false,
    showHeaderRow = true,
    multiSelect = true,
    entityConfig,
    filters = [],
    dashboardFilterGroups = [],
    dashboardAppliedRoles = [],
    canWrap = false,
    renderRowActions
}: Props) => {
    const grid = useGrid()
    const { isEnabled: tempEntityGridAggregations } = useFeatureToggle("tempEntityGridAggregations")
    const { isEnabled: useDashboardAppliedRoles } = useFeatureToggle("dashboardAppliedRoles")

    const combinedFilters = convertFromArrayToDto([
        ...filters,
        ...convertFromDtoToArray(grid.filters, getDataFieldsFromEntityDataFields(entityConfig?.fields ?? []), grid.lookups)
    ])

    const filtersForAggregations = () => {
        if (grid.isFilteredToSelection) {
            const selectionFilters = addSelectionFilter(grid.filters ?? {}, grid.selection.selectedRefs)
            const mappedSelectionFilters = {
                ...selectionFilters,
                textFieldIsOneOf: selectionFilters.textFieldIsOneOf?.map(f => (f.fieldName === "referenceSelected" ? { ...f, fieldName: "reference" } : f))
            }
            return convertFromArrayToDto([
                ...filters,
                ...convertFromDtoToArray(mappedSelectionFilters, getDataFieldsFromEntityDataFields(entityConfig?.fields ?? []), grid.lookups)
            ])
        }
        return combinedFilters
    }

    const sortedHeaders = grid.displayedHeaders.sort((a, b) => a.ordinal - b.ordinal)

    const toggleSelectAll = () => {
        if (!multiSelect) return

        if (grid.selection.isAllRowsSelected) {
            grid.onSelect({ selectedRefs: [], isAllRowsSelected: false }, true)
            return
        }

        const selectedRefs = grid.data.rows.map(row => row.reference)
        grid.onSelect({ selectedRefs, isAllRowsSelected: true }, true)
    }

    const toggleSelectSingle = (reference: string) => {
        if (multiSelect) {
            if (grid.selection.selectedRefs.includes(reference)) {
                grid.onSelect(
                    {
                        selectedRefs: grid.selection.selectedRefs.filter(ref => ref !== reference),
                        isAllRowsSelected: false
                    },
                    true
                )
                return
            }

            grid.onSelect(
                {
                    selectedRefs: [...grid.selection.selectedRefs, reference],
                    isAllRowsSelected: false
                },
                true
            )
        } else {
            grid.onSelect({ selectedRefs: [reference], isAllRowsSelected: false }, true)
        }
    }
    const config = useEnvConfig()
    const client = useClient()
    const [selectedAggregations, setSelectedGridAggregations] = useState<EntityGridAggregationValueDto[]>([])

    const gridAggregationDtos: GridAggregationDto[] =
        entityConfig?.fields
            .filter(field => field.dataType.type === DataTypeEnum.MONEY)
            .map(field => {
                if (field.dataType.currencyFieldReference) {
                    return {
                        type: "SINGLE_GROUPED",
                        groupingField: field.dataType.currencyFieldReference,
                        aggregationField: field.fieldName,
                        aggregationFieldType: DataPrimitiveTypeEnum.NUMBER
                    }
                } else {
                    return {
                        type: "SUM",
                        aggregationField: field.fieldName,
                        aggregationFieldType: DataPrimitiveTypeEnum.NUMBER,
                        consistentGroup: field.dataType.currencyCode ?? ""
                    }
                }
            }) ?? []

    const getEntityGridAggregations = useApiQuery<EntityGridAggregationsResponseDto>({
        url: `${config.SEARCH_API_URL}/api/${client}/entity-grid-aggregations`,
        method: "POST",
        dto: {
            filtersDto: filtersForAggregations(),
            matchSomeFiltersDto: dashboardFilterGroups,
            entityTypeReference: entityConfig?.reference,
            gridAggregationDtos: gridAggregationDtos,
            ...(useDashboardAppliedRoles ? { dashboardAppliedRolesNames: dashboardAppliedRoles } : {})
        },
        isExecutedAutomatically: gridAggregationDtos.length > 0 && tempEntityGridAggregations
    })

    const getEntityGridSelectedAggregations = useApiQuery<EntityGridAggregationsResponseDto>({
        url: `${config.SEARCH_API_URL}/api/${client}/entity-grid-aggregations`,
        method: "POST",
        isExecutedAutomatically: false
    })

    const setSelectedAggregations = () => {
        const hasSelectionOutsidePage = grid.selection.selectedRefs.some(ref => !grid.data.rows.some(row => row.reference === ref))

        if (hasSelectionOutsidePage && gridAggregationDtos.length > 0) {
            getEntityGridSelectedAggregations
                .execute(undefined, {
                    filtersDto: {
                        ...combinedFilters,
                        textFieldIsOneOf: [
                            ...(combinedFilters.textFieldIsOneOf ?? []),
                            { fieldName: "reference", values: grid.selection.selectedRefs, notOneOf: false }
                        ]
                    },
                    entityTypeReference: entityConfig?.reference,
                    gridAggregationDtos: gridAggregationDtos
                })
                .then(response => {
                    setSelectedGridAggregations(response.data.results)
                })
        } else {
            const selectedRows = grid.data.rows.filter(row => grid.selection.selectedRefs.includes(row.reference))
            const aggs = calculateSelectedAggregations(selectedRows, entityConfig)
            setSelectedGridAggregations(aggs)
        }
    }

    useEffect(() => {
        setSelectedAggregations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid.selection.selectedRefs, grid.data.rows])

    const gridAggregations = getEntityGridAggregations?.data?.results ?? []

    const [areAggsPinned, setAggsPinned] = useState<boolean>(true)
    return (
        <table className={`w-100 px-2 overflow-auto ${classes.table}`}>
            {showHeaderRow && (
                <thead aria-label={"entity-table-head"} className={`${classes.tableHead} position-sticky`}>
                    <tr className={`${classes.headerRow} bg-blue text-white`}>
                        {selectableRows && (
                            <th className="p-2">
                                <Checkbox checkboxClasses={classes.checkbox} onClick={toggleSelectAll} isChecked={grid.selection.isAllRowsSelected} />
                            </th>
                        )}
                        <DndProvider backend={HTML5Backend}>
                            {sortedHeaders.map(field => (
                                <FieldHeader key={field.value} {...field} />
                            ))}
                        </DndProvider>
                        <th />
                    </tr>
                    {tempEntityGridAggregations && areAggsPinned && (
                        <RowAggregations
                            sortedHeaders={sortedHeaders}
                            isPinned={areAggsPinned}
                            setPinned={setAggsPinned}
                            selectableRows={selectableRows}
                            totalAggs={gridAggregations}
                            totalFetching={getEntityGridAggregations.isFetching}
                            selectedAggs={selectedAggregations}
                            selectedFetching={getEntityGridSelectedAggregations.isFetching}
                        />
                    )}
                </thead>
            )}
            <tbody aria-label={"entity-table-body"} className="overflow-auto position-relative">
                {showHeaderRow && tempEntityGridAggregations && !areAggsPinned && (
                    <RowAggregations
                        sortedHeaders={sortedHeaders}
                        isPinned={areAggsPinned}
                        setPinned={setAggsPinned}
                        selectableRows={selectableRows}
                        totalAggs={gridAggregations}
                        totalFetching={getEntityGridAggregations.isFetching}
                        selectedAggs={selectedAggregations}
                        selectedFetching={getEntityGridSelectedAggregations.isFetching}
                    />
                )}
                {grid.data.rows.map(rowData => (
                    <DataRow
                        key={rowData.reference}
                        rowData={rowData}
                        optimisticData={grid.data.optimisticallyUpdatedRows.find(row => row.reference === rowData.reference)}
                        isSelectable={selectableRows}
                        toggleSelectSingle={toggleSelectSingle}
                        renderRowActions={renderRowActions}
                        entityConfig={entityConfig}
                        canWrap={canWrap}
                    />
                ))}
                <tr>
                    <td className="h-100" />
                </tr>
            </tbody>
        </table>
    )
}
export default Rows

const calculateSelectedAggregations = (selectedRows: RowData[], entityConfig: EntityConfig | undefined) => {
    const startingSelectedAggs: EntityGridAggregationValueDto[] = []
    return selectedRows.reduce((acc, row) => {
        for (const key in row.fields.numberFields) {
            const agg = acc.find(a => a.entityFieldReference === key) ?? { entityFieldReference: key, totalByGroup: {} }
            const value = row.fields.numberFields[key] ?? 0
            const currencyCode =
                entityConfig?.fields.find(f => f.fieldName === key)?.dataType.currencyCode ??
                row.fields.textFields[entityConfig?.fields.find(f => f.fieldName === key)?.dataType.currencyFieldReference ?? ""]
            if (currencyCode === undefined) continue
            agg.totalByGroup = { ...agg.totalByGroup, [currencyCode]: (agg.totalByGroup[currencyCode] ?? 0) + value }
            if (!acc.some(a => a.entityFieldReference === key)) acc.push(agg)
            else acc = acc.map(a => (a.entityFieldReference === key ? agg : a))
        }
        return acc
    }, startingSelectedAggs)
}
