export enum transactionAggregationTypes {
    AGED_DEBT = 'AGED_DEBT',
    FALLING_DUE = 'FALLING_DUE',
    RECENT_PAYMENTS = 'RECENT_PAYMENTS',
    TRANSACTION_STATUS = 'TRANSACTION_STATUS',
    TRANSACTION_TYPE = 'TRANSACTION_TYPE'
}

const getLabelForTimeChartType = (type: transactionAggregationTypes) => {
    switch (type) {
        case transactionAggregationTypes.AGED_DEBT:
            return "Aged debt"
        case transactionAggregationTypes.FALLING_DUE:
            return "Falling due"
        case transactionAggregationTypes.RECENT_PAYMENTS:
            return "Recent payments"
        case transactionAggregationTypes.TRANSACTION_STATUS:
            return "Transaction status"
        case transactionAggregationTypes.TRANSACTION_TYPE:
            return "Transaction type"

        default:
            return "Aged debt"
    }
}

export type BucketChartTypeState = {
    id: number
    value: transactionAggregationTypes
    label: string
}
export const bucketChartTypeDropdownOptions = Object.entries(transactionAggregationTypes).map(([, v], index) => ({ id: index, value: v, label: getLabelForTimeChartType(v) })) as BucketChartTypeState[]