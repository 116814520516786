import { MouseEvent, useEffect, useRef, useState } from "react"
import classes from "./SmallBarChart.module.scss"
import Chart from "chart.js/auto"
import { EntityChartDisplayWidgetData } from "../../microfrontends/dashboard/components/customerDashboards/widgets/EntityChartDisplayWidget"

type EntityLineChartProps = {
    className?: string
    colour: "white" | "blue"
    title: string
    state: EntityChartDisplayWidgetData
    headerButtons?: React.ReactNode
    onClick: (value: string) => void
}

const EntityLineChart = ({ className = "", colour = "white", title, state, headerButtons = <></>, onClick }: EntityLineChartProps) => {
    const { data, xLabels } = state

    const canvasRef = useRef<HTMLCanvasElement>(null)
    const [chart, setChart] = useState<Chart<"line", number[], string> | undefined>(undefined)

    const onCanvasClick = (event: MouseEvent<HTMLCanvasElement>) => {
        const element = chart?.getElementsAtEventForMode(event.nativeEvent, "nearest", { intersect: true }, false)
        if (!element || !chart?.data.labels) return
        if (!element[0]) return

        onClick(chart.data.labels[element[0].index] ?? "")
    }

    useEffect(() => {
        if (!canvasRef.current) return

        const chartColor = colour === "blue" ? "white" : "#0096d9"
        const gridColor = colour === "blue" ? "rgba(255,255,255, 0.3)" : "#0096d9"
        Chart.defaults.color = chartColor

        const chart = new Chart(canvasRef.current, {
            type: "line",
            data: {
                labels: xLabels.map(label => label.toUpperCase()),
                datasets: [
                    {
                        label: title,
                        data: data,
                        borderColor: chartColor,
                        backgroundColor: chartColor
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: false,
                        text: title
                    },
                    legend: {
                        display: false
                    }
                },
                scales: {
                    x: {
                        border: {
                            display: true
                        },
                        grid: {
                            display: true,
                            drawOnChartArea: true,
                            drawTicks: true,
                            color: gridColor
                        }
                    },
                    y: {
                        border: {
                            display: true
                        },
                        grid: {
                            color: gridColor
                        }
                    }
                },
                locale: "en-GB"
            }
        })
        setChart(chart)
        return () => chart?.destroy()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <div className={`${className} d-flex flex-column w-100 p-3 rounded ${classes[`widget-${colour}`]}`}>
            <div className="d-flex justify-content-between align-items-center">
                <div className={`fs-5 text-uppercase d-block mb-2 ${colour === "blue" ? "text-white" : "text-grey"}`}>{title}</div>
                {headerButtons}
            </div>
            <div className={` ${classes[`chartContainer`]} flex-grow-1`}>
                <canvas ref={canvasRef} onClick={onCanvasClick} />
            </div>
        </div>
    )
}

export default EntityLineChart
