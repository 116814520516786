import React from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import {
  setUserTaskFilterEndDate,
  setUserTaskFilterStartDate,
  setUserTaskDateType
} from '../../actions/taskActions';

const UserTaskDate = ({
  startDate,
  endDate,
  dateType,
  setUserTaskFilterStartDate,
  setUserTaskFilterEndDate,
  setUserTaskDateType,
  invevoColourScheme = false
}) => {
  const setToday = () => {
    setUserTaskDateType(0);
  };
  const isToday = dateType === 0;

  const setTomorrow = () => {
    setUserTaskDateType(1);
  };

  const isTomorrow = dateType === 1;

  const setAll = () => {
    setUserTaskDateType(2);
  };
  const isAll = dateType === 2;

  const setRange = () => {
    setUserTaskDateType(3);
  };
  const isRange = dateType === 3;

  const onChangeStartDate = (startDate) => {
    setUserTaskFilterStartDate(startDate);
  };

  const onChangeEndDate = (endDate) => {
    setUserTaskFilterEndDate(endDate);
  };

  const buttonClass = (enabled) =>
    enabled
      ? invevoColourScheme ? 'btn btn-primary-invevo' : 'btn btn-primary'
      : invevoColourScheme ? 'btn btn-default-invevo' : 'btn btn-default';

  return (
    <>
      <div className="row top10" id="task-date">
        <div className="col-12">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              className={buttonClass(isToday)}
              onClick={setToday}
            >
              Today
            </button>
            <button
              type="button"
              className={buttonClass(isTomorrow)}
              onClick={setTomorrow}
            >
              Tomorrow
            </button>
            <button
              type="button"
              className={buttonClass(isAll)}
              onClick={setAll}
            >
              All
            </button>
            <button
              type="button"
              className={buttonClass(isRange)}
              onClick={setRange}
            >
              Range
            </button>
          </div>
        </div>
      </div>
      {isRange && (
        <div className="row top10">
          <div className="section-1">
            <label>
              <DatePicker
                name="startDate"
                className="form-control"
                dateFormat="dd MMMM yyyy"
                selected={startDate}
                placeholderText="CHOOSE"
                popperPlacement="bottom-start"
                onChange={onChangeStartDate}
              />
              <i className="fa-far fa-calendar-alt ms-auto" /> {/* TODO Check this icon renders correctly */}
            </label>
          </div>
          <div className="section-2 text-grey">To</div>
          <div className="section-3">
            <label>
              <DatePicker
                name="endDate"
                className="form-control"
                dateFormat="dd MMMM yyyy"
                selected={endDate}
                placeholderText="CHOOSE"
                popperPlacement="bottom-end"
                onChange={onChangeEndDate}
              />
              <i className="fa-far fa-calendar-alt ms-auto" /> {/* TODO Check this icon renders correctly */}
            </label>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  startDate: state.taskReducer.userTaskList.startDate,
  endDate: state.taskReducer.userTaskList.endDate,
  dateType: state.taskReducer.userTaskList.dateType
});

export default connect(mapStateToProps, {
  setUserTaskFilterStartDate,
  setUserTaskFilterEndDate,
  setUserTaskDateType
})(UserTaskDate);
