import React, { DispatchWithoutAction, useMemo } from "react"
import { widgetTypes } from "../../enums/widgetTypes"
import EntityInfoDisplayWidget from "./widgets/EntityInfoDisplayWidget"
import EntityValueDisplayWidget from "./widgets/EntityValueDisplayWidget"
import GridDisplayWidget from "./widgets/gridDsiplayWidget/GridDisplayWidget"
import EntityChartDisplayWidget from "./widgets/EntityChartDisplayWidget"
import { v4 as uuidv4 } from "uuid"
import DndGrid from "../../../../library/dndGrid/DnDGrid"
import EntityConfig from "../../../../routes/entityConfig/types/EntityConfig"
import EntityRelationship from "../../../../routes/entityConfig/types/EntityRelationship"
import DashboardConfigState from "../../types/DashboardConfigState"
import Lookup from "../../../../types/Lookup"
import DataUpdateRule from "../../../../library/dataGridV2/types/DataUpdateRule"
import { Entity } from "../../../../contexts/EntityContext"
import FilterTypesByField from "../../../../library/FilterList/FilterTypesByField"
import GenericFilter from "../../../../library/FilterList/filterTypes/GenericFilter"
import { SortedWidget } from "../dashboardConfigs/DashboardEditor"
import { GridType } from "../../types/DashboardConfigDto"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"

type DashboardProps = {
    className?: string
    state: {
        entityData: Entity
        entityConfigs: EntityConfig[]
        entityRelationships: EntityRelationship[]
        dashboard: DashboardConfigState
        lookups: Lookup[]
        dataUpdateRules: DataUpdateRule[]
    }
    dispatch: DispatchWithoutAction
}

const Dashboard = ({ className, state }: DashboardProps) => {
    const { isEnabled: isCachingEnabled } = useFeatureToggle("searchDashboardCaching")
    const { entityData, entityConfigs, entityRelationships, dashboard, lookups, dataUpdateRules } = state

    const contextEntityConfig = entityConfigs.find(config => config.reference === entityData.entityType)

    const sortedWidgets: SortedWidget[] = useMemo(
        () =>
            sortWidgets([
                ...dashboard.infoWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...dashboard.valueWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...dashboard.transactionsWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...dashboard.smallBarChartWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...dashboard.areaChartWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...dashboard.lineChartWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...dashboard.smallPieChartWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...dashboard.gridWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...dashboard.entityChartWidgets.map(w => ({ ...w, id: uuidv4() }))
            ]),
        [dashboard]
    )
    const filterByReference = (entityRelationship: EntityRelationship): GenericFilter[] => [
        {
            type: FilterTypesByField.TEXT_FIELD_IS_ONE_OF,
            id: uuidv4(),
            fieldName: entityRelationship.groupingFieldName,
            values: [entityData.reference],
            notOneOf: false
        }
    ]

    return (
        <div className={`d-flex flex-column h-100 w-100 overflow-auto ${className ? className : ""}`}>
            <div className="h-100 px-4 pb-4 overflow-auto">
                {entityData.entityFields === undefined ? (
                    <></>
                ) : (
                    <DndGrid isReadOnly={true}>
                        {sortedWidgets.map(widget => {
                            switch (widget.widgetType) {
                                case widgetTypes.INFORMATION:
                                    return (
                                        <div
                                            key={widget.id}
                                            id={widget.id}
                                            x={widget.cellX}
                                            y={widget.cellY}
                                            w={widget.cellWidth}
                                            h={widget.cellHeight}
                                            minW={4}
                                            minH={2}
                                        >
                                            <div className="w-100 h-100 p-2">
                                                <EntityInfoDisplayWidget
                                                    widget={widget}
                                                    lookups={lookups}
                                                    entityFields={contextEntityConfig?.fields ?? []}
                                                    dataUpdateRules={dataUpdateRules}
                                                />
                                            </div>
                                        </div>
                                    )
                                case widgetTypes.LINE_CHART:
                                    return <></>
                                case widgetTypes.VALUE:
                                    return (
                                        <div
                                            key={widget.id}
                                            id={widget.id}
                                            x={widget.gridCellX}
                                            y={widget.gridCellY}
                                            w={widget.gridCellWidth}
                                            h={widget.gridCellHeight}
                                            minW={2}
                                            minH={2}
                                        >
                                            <div className="w-100 h-100 p-2">
                                                <EntityValueDisplayWidget
                                                    state={{
                                                        widget,
                                                        lookups,
                                                        entityFields: contextEntityConfig?.fields ?? [],
                                                        entityData: entityData.entityFields
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                case widgetTypes.GRID: {
                                    const entityConfig = entityConfigs.find(config => config.reference === widget.entityConfigReference)
                                    const entityRelationship =
                                        contextEntityConfig?.reference === "virtual_parent"
                                            ? ({
                                                  id: uuidv4(),
                                                  parentEntityReference: entityData.entityType,
                                                  childEntityReference: widget.entityConfigReference,
                                                  groupingFieldName: "text_customer_reference"
                                              } as EntityRelationship)
                                            : entityRelationships.find(
                                                  r =>
                                                      r.parentEntityReference === entityData.entityType &&
                                                      r.childEntityReference === widget.entityConfigReference
                                              )
                                    return (
                                        <div
                                            key={widget.id}
                                            id={widget.id}
                                            x={widget.gridCellX}
                                            y={widget.gridCellY}
                                            w={widget.gridCellWidth}
                                            h={widget.gridCellHeight}
                                            minW={2}
                                            minH={2}
                                        >
                                            <div className="w-100 h-100 p-2">
                                                {entityConfig && entityRelationship && (
                                                    <GridDisplayWidget
                                                        gridReference={widget.reference}
                                                        widgetColumns={widget.columns.map((c: any, index: number) => {
                                                            return {
                                                                fieldName: c.fieldName,
                                                                displayName: c.fieldName,
                                                                displayType: c.dataType,
                                                                ordinal: index,
                                                                isEditable: c.isEditable ?? false
                                                            }
                                                        })}
                                                        widgetDefaultSort={widget.defaultSort ?? undefined}
                                                        gridEntityConfig={entityConfig}
                                                        entityRelationship={entityRelationship}
                                                        lookups={lookups}
                                                        advancedFilters={widget.filters}
                                                        type={widget.type ?? GridType.TABLE}
                                                        groupingField={widget.groupingField ?? ""}
                                                        isCachingEnabled={isCachingEnabled}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )
                                }
                                case widgetTypes.ENTITY_CHART_WIDGET: {
                                    const entityConfig = entityConfigs.find(config => config.reference === widget.entityConfigReference)
                                    const entityRelationship = entityRelationships.find(
                                        r => r.parentEntityReference === entityData.entityType && r.childEntityReference === widget.entityConfigReference
                                    )
                                    return (
                                        <div
                                            key={widget.id}
                                            id={widget.id}
                                            x={widget.gridCellX}
                                            y={widget.gridCellY}
                                            w={widget.gridCellWidth}
                                            h={widget.gridCellHeight}
                                            minW={2}
                                            minH={2}
                                        >
                                            <div className="w-100 h-100 p-2">
                                                {entityConfig && entityRelationship && (
                                                    <EntityChartDisplayWidget
                                                        chartConfig={widget}
                                                        entityConfigs={entityConfigs}
                                                        lookups={lookups}
                                                        advancedFilters={entityRelationship ? filterByReference(entityRelationship) : []}
                                                        setDashboardFilterGroups={() => {}}
                                                        setAdvancedFilters={() => {}}
                                                        dashboardReference={dashboard.reference}
                                                        isCachingEnabled={isCachingEnabled}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )
                                }

                                default:
                                    return <React.Fragment key={widget.ordinal}></React.Fragment>
                            }
                        })}
                    </DndGrid>
                )}
            </div>
        </div>
    )
}

const sortWidgets = (widgets: SortedWidget[]) => [...widgets].sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1))
export default Dashboard
