import React from 'react'
import { SelectableCard } from 'invevo-react-components'

const SmsTemplateCard = ({
    template,
    onSmsTemplateSelected
}) => {

    const body = (
        <div className="d-flex align-items-center">
            <i className="fal fa-comment-alt-lines text-blue"></i>
            <h5 className="ms-3 mb-0">{template.name}</h5>
        </div>
    )

    const footer = (
        <div className="d-flex" />
    )

    return (
        <SelectableCard className="w-100 my-2" body={body} footer={footer} isSelected={template.isSelected} onClick={() => onSmsTemplateSelected(template)} />
    )
}

export default SmsTemplateCard