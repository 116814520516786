import classes from "./ListCard.module.scss"

type ListCardProps = {
    className?: string
    isSelected: boolean
    ariaLabel?: string
    onSelect: () => void
    children: React.ReactNode
}

const ListCard = ({ className = "", isSelected, ariaLabel = "", onSelect, children }: ListCardProps) => {
    return (
        <div
            className={`${classes.container} ${isSelected ? classes.selected : ""} p-2 bg-blue text-white pointer no-select ${className}`}
            onClick={onSelect}
            role="listitem"
            aria-label={ariaLabel}
        >
            {children}
        </div>
    )
}

export default ListCard
