import { Input } from "invevo-react-components"
import { useState, useEffect, BaseSyntheticEvent } from "react"
import Lookup from "../../../../types/Lookup"
import EntityConfig from "../../types/EntityConfig"
import EntityDataField from "../../types/EntityDataField"
import EntityRelationshipCalculation from "../../types/EntityRelationshipCalculation"
import EntityConfigDataFieldForm from "../EntityConfigDataFieldForm"
import EntityRelationship from "../../types/EntityRelationship"
import classes from "./EntityConfigDiagramOverlay.module.scss"
import IconButton from "../../../../library/buttons/IconButton/IconButton"
import EntityConfigDiagramEditor from "./EntityConfigDiagramEditor"
import useApiQuery from "../../../../hooks/useApiQuery"
import { useEnvConfig } from "../../../../contexts/EnvironmentConfigContext"
import useClient from "../../../../hooks/useClient"

type EntityConfigDataField = {
    entityConfigReference?: string
    field: EntityDataField
}

type EntityConfigDiagramOverlayProps = {
    entityConfigs: EntityConfig[]
    entityRelationships: EntityRelationship[]
    lookups: Lookup[]
    selected?: string
    setSelected: (key?: string) => void
    newEntityConfig: EntityConfig
    disableDiagramView: () => void
    onNewNodeAdded: () => void
    collapsedNodes: string[]
    toggleCollapseNodes: () => void
    onSaveEntityConfig: (config: EntityConfig) => void
    onNewEntityConfigSaved: (config: EntityConfig) => void
    onSaveEntityCalculation: (calc: EntityRelationshipCalculation) => void
    onSaveNodePositions: () => void
}

const EntityConfigDiagramOverlay = ({
    entityConfigs,
    entityRelationships,
    lookups,
    selected,
    setSelected,
    newEntityConfig,
    disableDiagramView,
    onNewNodeAdded,
    collapsedNodes,
    toggleCollapseNodes,
    onSaveEntityConfig,
    onNewEntityConfigSaved,
    onSaveEntityCalculation,
    onSaveNodePositions
}: EntityConfigDiagramOverlayProps) => {
    const config = useEnvConfig()
    const client = useClient()

    const [showEditPanel, setShowEditPanel] = useState<boolean>(false)
    const [editingNode, setEditingNode] = useState<EntityConfig | undefined>(undefined)
    const [editingField, setEditingField] = useState<EntityConfigDataField | undefined>(undefined)
    const [searchText, setSearchText] = useState<string>(selected ? entityConfigs.find(e => e.reference === selected)?.displayName ?? "" : "")

    const toggleShowEditPanel = () => setShowEditPanel(show => !show)

    const onSearchChange = (e: BaseSyntheticEvent) => {
        const text: string = e.target.value
        setSearchText(text)
        const exactMatch = entityConfigs.find(e => e.displayName === text.trim())
        if (exactMatch) setSelected(exactMatch.reference)
        else {
            const matchingEntities = entityConfigs.filter(e => e.displayName.startsWith(text))
            if (matchingEntities.length === 1) setSelected(matchingEntities[0]?.reference)
            else setSelected(undefined)
        }
    }

    const onSaveDataField = (entityDataField: EntityDataField, calculation: EntityRelationshipCalculation | undefined) => {
        if (!editingField) return
        const existingConfig = entityConfigs.find(c => c.reference === editingField.entityConfigReference)
        if (!existingConfig) return
        setEditingField(f => ({
            entityConfigReference: f?.entityConfigReference,
            field: entityDataField
        }))
        onSaveEntityConfig({
            ...existingConfig,
            fields: existingConfig.fields.map(f => (f.fieldName === entityDataField.fieldName ? entityDataField : f))
        })
        calculation && onSaveEntityCalculation(calculation)
    }

    useEffect(() => {
        const configsToSearch = [...entityConfigs, newEntityConfig]
        const configToEdit = configsToSearch.find(c => c.reference === selected)
        setSearchText(text => (selected ? configToEdit?.displayName ?? "" : text))

        if (!selected) {
            setEditingNode(undefined)
            setEditingField(undefined)
            setShowEditPanel(false)
            setSearchText("")
            return
        }

        if (configToEdit) {
            setEditingField(undefined)
            setEditingNode(configToEdit)
            return
        }

        const getFieldToEditForIntraEntityCalculation = (entityConfigs: EntityConfig[]): EntityConfigDataField | undefined => {
            const intraPrefix = "CALCULATION~intra~"
            const interPrefix = "CALCULATION~inter~"
            const isIntra = selected?.startsWith(intraPrefix) ?? false
            if (!isIntra && !selected?.startsWith(interPrefix)) return undefined
            const prefix = isIntra ? intraPrefix : interPrefix
            const selectedEntityField = selected?.split(prefix)[1]?.split("~")
            if (selectedEntityField === undefined) return undefined
            const selectedEntity = selectedEntityField[0]
            const selectedField = selectedEntityField[1]
            if (selectedEntity === undefined || selectedField === undefined) return undefined
            const configContainingField = entityConfigs.find(c => c.reference === selectedEntity)
            if (!configContainingField) return
            const field = configContainingField.fields.find(f => {
                if (isIntra && !f.intraEntityCalculation) return false
                return f.fieldName === selectedField
            })
            if (!field) return undefined
            return { entityConfigReference: configContainingField.reference, field }
        }

        const fieldToEdit = getFieldToEditForIntraEntityCalculation(entityConfigs)
        if (fieldToEdit) {
            setEditingNode(undefined)
            setEditingField(fieldToEdit)
            return
        }

        setEditingNode(undefined)
        setEditingField(undefined)
        setShowEditPanel(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityConfigs, entityRelationships, selected])

    const editingNewEntityConfig = editingNode?.reference === newEntityConfig.reference

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const saveEntityConfigsRequest = useApiQuery({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-configs`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const saveEntityRelationshipsRequest = useApiQuery({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-relationships`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    const onSaveClick = () => {
        onSaveNodePositions()
        // saveEntityConfigsRequest.execute(undefined, entityConfigs)
        // saveEntityRelationshipsRequest.execute(undefined, entityRelationships)
    }

    return (
        <div className={`d-flex flex-grow-1 ${classes.overlay} justify-content-between h-100 pe-none`}>
            <div className="d-flex flex-row gap-3 ms-3">
                <IconButton
                    icon="fa-light fa-chart-network"
                    theme="blue"
                    onClick={disableDiagramView}
                    ariaLabel="disable-entity-diagram-view"
                    className="my-3"
                    dynamicShadow={true}
                />
                <IconButton
                    icon="fa-light fa-plus"
                    theme="blue"
                    onClick={onNewNodeAdded}
                    ariaLabel="add-new-entity-config"
                    className="my-3"
                    dynamicShadow={true}
                />
                <IconButton icon="fa-light fa-check" theme="blue" onClick={onSaveClick} ariaLabel="save-all-configs" className="my-3" dynamicShadow={true} />
            </div>
            <div className="d-flex justify-content-end mx-3 w-100">
                <div className={`d-flex align-items-center justify-content-end gap-3 ${classes.actionRow}`}>
                    <Input value={searchText} onChange={onSearchChange} className="pe-auto" />
                    <IconButton
                        icon={`fa-light ${collapsedNodes.length <= 1 ? "fa-chevrons-up" : "fa-chevrons-down"}`}
                        theme="blue"
                        onClick={toggleCollapseNodes}
                        ariaLabel="add-new-entity-config"
                        className="my-3"
                        dynamicShadow={true}
                    />
                    <IconButton
                        disabled={!editingNode && !editingField}
                        icon="fa-light fa-pencil"
                        theme="blue"
                        onClick={toggleShowEditPanel}
                        ariaLabel="open-edit-panel"
                        className="my-3"
                        dynamicShadow={true}
                    />
                </div>
                {showEditPanel && (
                    <div className={`bg-white rounded-4 ms-3 my-3 ${classes.editPanel} pe-auto`}>
                        {editingNode && (
                            <EntityConfigDiagramEditor
                                allEntityConfigs={entityConfigs}
                                entityConfig={editingNode}
                                entityRelationships={entityRelationships}
                                lookups={lookups}
                                isNewEntityConfig={editingNewEntityConfig}
                                onSaveEntityConfig={onSaveEntityConfig}
                                onSaveNewEntityConfig={onNewEntityConfigSaved}
                                key={editingNode.reference}
                            />
                        )}
                        {editingField && (
                            <div key={editingField.field.fieldName + "-" + editingField.entityConfigReference} className="w-100 h-100 overflow-auto">
                                <EntityConfigDataFieldForm
                                    allEntityConfigs={entityConfigs}
                                    entityConfig={entityConfigs.find(e => e.reference === editingField.entityConfigReference)!}
                                    entityDataField={editingField.field}
                                    relationships={entityRelationships}
                                    isEditing={true}
                                    lookups={lookups}
                                    onSaveEntityDataField={onSaveDataField}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default EntityConfigDiagramOverlay
