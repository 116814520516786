import NavigationButton from "../../../library/buttons/NavigationButton/NavigationButton"
import IntegrationOptions from "./IntegrationOptions"

const IntegrationNav = () => {
    return (
        <div className="d-flex justify-content-center align-items-center w-100">
            <NavigationButton
                route={`/home/integration/${IntegrationOptions.FILE_CONFIGS}`}
                label="Configs"
                icon="fa-duotone fa-right-left"
                ariaLabel="fileConfig"
                colour="#b3dfe6"
            />
            <NavigationButton
                route={`/home/integration/${IntegrationOptions.HISTORY}`}
                label="History"
                icon="fa-solid fa-clock-rotate-left"
                ariaLabel="importHistory"
                colour="#ebe996"
            />
        </div>
    )
}

export default IntegrationNav
