import { IconButton } from "invevo-react-components"
import DataRow from "./types/DataRow"
import classes from './GridRowActionButton.module.scss'

type GridRowActionButtonProps = {
    dataRow: DataRow,
    onClick: (dataRow: DataRow) => void
    ariaLabel: string
    iconClasses: string
}

const GridRowActionButton = ({ dataRow, onClick, ariaLabel, iconClasses }: GridRowActionButtonProps) => {
    const onActionClicked = () => onClick(dataRow)

    return (
        <IconButton
            size="small"
            buttonClasses={classes.rowActionButton}
            iconClasses={iconClasses}
            onClick={onActionClicked}
            ariaLabel={ariaLabel}
        />
    )
}

export default GridRowActionButton