import { map } from 'lodash';
import React from 'react';
import AccountCard from './accountCard';
import AccountDetail from './accountDetail';

const CustomerContactsCard = ({ contacts, title, open, setOpen }) => {
  return (
    <AccountCard title={title} open={open} setOpen={setOpen}>
      {map(contacts, (c, i) => (
        <AccountDetail key={'cust-contact-' + i}>
          <div className="d-flex flex-wrap justify-content-between">
            <div>{c.Name}</div>
            <div className="text-right">{c.Phone}</div>
          </div>
        </AccountDetail>
      ))}
    </AccountCard>
  );
};

export default CustomerContactsCard;
