import classes from "./EditDashboardConfig.module.scss"
import DashboardEditor from "../DashboardEditor"
import ActionPanel from "../ActionPanel"
import { actionTypes } from "../../../reducers/actionTypes"
import { dashboardTypes } from "../../../types/DashboardTypes"
import { v4 as uuidv4 } from "uuid"
import DashboardConfigState from "../../../types/DashboardConfigState"
import Lookup from "../../../../../types/Lookup"
import {
    DashboardConfigAction,
    DashboardConfigCustomerEntityConfig,
    DashboardConfigEntityConfig,
    DashboardConfigTransactionFields
} from "../../../reducers/dashboardConfigsReducer"

type EditDashboardConfigProps = {
    state: {
        originalConfig: DashboardConfigState
        editingConfig: DashboardConfigState
        customerFields: DashboardConfigCustomerEntityConfig[]
        transactionFields: DashboardConfigTransactionFields[]
        showValidationErrors: boolean
        entityConfigs: DashboardConfigEntityConfig[]
        dashboardConfigs: DashboardConfigState[]
        lookups: Lookup[]
        isNewEntityStructureEnabled: boolean
    }
    dispatch: React.Dispatch<DashboardConfigAction>
    onDeleted: () => void
}

const EditDashboardConfig = ({ state, dispatch, onDeleted }: EditDashboardConfigProps) => {
    const {
        editingConfig,
        originalConfig,
        customerFields,
        transactionFields,
        showValidationErrors,
        dashboardConfigs,
        entityConfigs,
        isNewEntityStructureEnabled,
        lookups
    } = state

    const onSave = () => {
        if (dashboardConfigs.some(config => config.reference === editingConfig.reference)) {
            const configToSave = {
                ...editingConfig,
                gridWidgets: editingConfig.gridWidgets.map(widget => ({
                    ...widget,
                    reference:
                        JSON.stringify(widget) ===
                            JSON.stringify(originalConfig.gridWidgets.find(originalWidget => originalWidget.reference === widget.reference))
                            ? widget.reference
                            : uuidv4()
                }))
            }

            dispatch({ type: actionTypes.DASHBOARD_CONFIG_SAVED, dashboardConfig: configToSave })
            return
        }

        dispatch({ type: actionTypes.DASHBOARD_CONFIG_ADDED, dashboardConfig: editingConfig })
    }

    const onDelete = () => {
        dispatch({ type: actionTypes.DASHBOARD_CONFIG_DELETED, dashboardConfigReference: state.editingConfig.reference })
        onDeleted()
    }

    const onDuplicate = (duplicateDashboard: DashboardConfigState) => dispatch({ type: actionTypes.DASHBOARD_CONFIG_ADDED, dashboardConfig: duplicateDashboard })

    const isAggregatedDashboard = editingConfig.type === dashboardTypes.AGGREGATED

    return (
        <div className={`d-flex ${classes.container} overflow-auto`}>
            <DashboardEditor
                state={{
                    editingConfig: editingConfig,
                    customerFields,
                    transactionFields,
                    showValidationErrors,
                    selectedContextEntityConfig: entityConfigs.find(config => config.reference === editingConfig.entityConfigReference),
                    entityConfigs: entityConfigs,
                    isNewEntityStructureEnabled: isNewEntityStructureEnabled,
                    isAggregatedDashboard: isAggregatedDashboard,
                    lookups: state.lookups
                }}
                dispatch={dispatch}
            />
            <ActionPanel
                state={{
                    editingConfig,
                    originalConfig,
                    showValidationErrors,
                    dashboardConfigs,
                    entityConfigs,
                    isNewEntityStructureEnabled: isNewEntityStructureEnabled,
                    isAggregatedDashboard: isAggregatedDashboard,
                    lookups: lookups,
                    customerFields: customerFields
                }}
                dispatch={dispatch}
                onSave={onSave}
                onDelete={onDelete}
                onDuplicate={onDuplicate}
            />
        </div>
    )
}

export default EditDashboardConfig
