import classes from "./ValueCard.module.scss"
import { useFeatureToggle } from "../../hooks/useFeatureToggle"
import { convertToCurrency } from "../helpers/numberUtils"
import moment from "moment";

type ValueData = {
    label: string
    value: number
    style: string
    dateFormat: string
    culture: string
    currency: string
}

type ValueCardProps = {
    className?: string
    title: string
    value1: ValueData
    value2: ValueData
    theme: "blue" | "white"
}

function ValueCard({ className = "", title, value1, value2, theme }: ValueCardProps) {
    const percentage = value2.value !== 0 ? Math.round((value1.value / value2.value) * 100) : 0
    const percentageClass = percentage >= 0 ? "good" : "bad"
    const { isEnabled: removeCurrencySymbols } = useFeatureToggle("removeCurrencySymbols")

    const labelStyle1 = ["good", "bad", "info"].includes(value1.style) ? `text-${theme}-${value1.style}` : `text-${theme}-info`
    const labelStyle2 = ["good", "bad", "info"].includes(value2.style) ? `text-${theme}-${value2.style}` : `text-${theme}-info`

    const getShownValue = (value: number, currency: string, culture: string, dateFormat: string) => {
        if (value && currency && culture) {
            return convertToCurrency(value, currency, culture, removeCurrencySymbols)
        }
        else if (value && dateFormat) {
            return moment(value).format(dateFormat)
        }
        return value
    }

    return (
        <div className={`p-3 rounded d-flex flex-column fit-content ${className} ${classes[`widget-${theme}`]}`}>
            <div className={`fs-5 text-uppercase d-block mb-2 ${theme === "blue" ? "text-white" : "text-grey"}`}>{title}</div>
            <div className="d-flex gap-3 align-items-center">
                <div className={`fs-2 fw-semibold ${classes.percentage} ${classes[percentageClass]} ${theme === "blue" ? "text-white" : null}`}>
                    {`${percentage}%`}
                </div>
                <div className="d-flex flex-column gap-3">
                    <div>
                        <span className={`${theme === "blue" ? "text-white" : "text-grey"}`}>{value1.label}</span>
                        <div className={`fs-4 ${classes.value} ${classes[labelStyle1]}`}>
                            {getShownValue(value1.value, value1.currency, value1.culture, value1.dateFormat)}
                        </div>
                    </div>
                    <div>
                        <span className={`${theme === "blue" ? "text-white" : "text-grey"}`}>{value2.label}</span>
                        <div className={`fs-4 ${classes.value} ${classes[labelStyle2]}}`}>
                            {getShownValue(value2.value, value2.currency, value2.culture, value2.dateFormat)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ValueCard
