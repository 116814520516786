import { AccountDto } from "../../types/dtos/AccountDto"
import CurrentBalance from "./CurrentBalance"
import { DirectIdAffordabilityDto } from "../../types/dtos/DirectIdAffordabilityDto"
import EstimatedAffordability from "./EstimatedAffordability"
import Affordability from "./Affordability"

type InsightsProps = {
    account: AccountDto
    affordability: DirectIdAffordabilityDto[]
}

const Insights = ({ account, affordability }: InsightsProps) => {
    return (
        <div className="d-flex flex-column w-100">
            <div className="d-flex">
                {affordability.map(a => (
                    <EstimatedAffordability className="me-2 flex-grow-1" affordability={a} currencyCode={account.metadata.currencyCode ?? "GBP"} />
                ))}
                <CurrentBalance className="ms-2 flex-grow-1" account={account} />
            </div>
            <div className="d-flex my-3">
                {affordability.map(a =>
                    (a.monthAffordabilities?.length ?? 0) > 0 ? (
                        <Affordability
                            className="flex-grow-1"
                            monthAffordabilities={a.monthAffordabilities ?? []}
                            currencyCode={account.metadata.currencyCode ?? "GBP"}
                        />
                    ) : (
                        <div className="d-flex border border-1 p-3 flex-grow-1 rounded">
                            <span>This account has no affordability calculations</span>
                        </div>
                    )
                )}
            </div>
        </div>
    )
}

export default Insights
