import ImportFieldConfigs from "../fileImportConfigSetup/importFieldConfig/ImportFieldConfigs"
import ImportFileConfig from "./ImportFileConfig"
import { Loading } from "invevo-react-components"
import {
    FileImportConfigAction,
    FileImportConfigActionEnum,
    FileImportConfigColumnTypeState,
    FileImportConfigFieldTrimOptionState,
    FileImportConfigState
} from "../../../reducers/FileImportConfigReducer"
import { FileType } from "../../../types/FileImportConfig"
import DataField from "../../../../../types/DataField"
import FieldType from "../../../../../types/enums/FieldType"
import ReferenceFieldConfig from "../fileImportConfigSetup/importFieldConfig/ReferenceFieldConfig"

type FileImportConfigSetupProps = {
    state: { editingConfig: FileImportConfigState; dataFields: DataField[] }
    dispatch: React.Dispatch<FileImportConfigAction>
}

const FileImportConfigSetup = ({ state, dispatch }: FileImportConfigSetupProps) => {
    const onReferenceConfigColumnsChange = (columns: FileImportConfigColumnTypeState[]) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_REFERENCE_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.editingConfig.dataFileImportConfig.mandatoryFieldConfig,
                columns
            }
        })
    }

    const onCustomerReferenceConfigColumnsChange = (columns: FileImportConfigColumnTypeState[]) => {
        if (state.editingConfig.dataFileImportConfig.type === FileType.TRANSACTION)
            dispatch({
                type: FileImportConfigActionEnum.DATA_FILE_CONFIG_CUSTOMER_REFERENCE_FIELD_CONFIG_UPDATED,
                fieldConfig: {
                    ...state.editingConfig.dataFileImportConfig.mandatoryCustomerFieldConfig,
                    columns
                }
            })
    }

    const onReferenceConfigAddTrimOption = () => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_REFERENCE_FIELD_CONFIG_TRIM_OPTION_ADDED
        })
    }

    const onReferenceConfigTrimOptionChange = (trimOption: FileImportConfigFieldTrimOptionState) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_REFERENCE_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.editingConfig.dataFileImportConfig.mandatoryFieldConfig,
                trimOptions: state.editingConfig.dataFileImportConfig.mandatoryFieldConfig.trimOptions.map((to: FileImportConfigFieldTrimOptionState) => {
                    if (to.key !== trimOption.key) return to
                    return trimOption
                })
            }
        })
    }

    const onReferenceConfigTrimOptionDelete = (trimOption: FileImportConfigFieldTrimOptionState) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_REFERENCE_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.editingConfig.dataFileImportConfig.mandatoryFieldConfig,
                trimOptions: state.editingConfig.dataFileImportConfig.mandatoryFieldConfig.trimOptions.filter(
                    (to: FileImportConfigFieldTrimOptionState) => to.key !== trimOption.key
                )
            }
        })
    }

    const onCustomerReferenceConfigAddTrimOption = () => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_CUSTOMER_REFERENCE_FIELD_CONFIG_TRIM_OPTION_ADDED
        })
    }

    const onCustomerReferenceConfigTrimOptionChange = (trimOption: FileImportConfigFieldTrimOptionState) => {
        if (state.editingConfig.dataFileImportConfig.type === FileType.TRANSACTION)
            dispatch({
                type: FileImportConfigActionEnum.DATA_FILE_CONFIG_CUSTOMER_REFERENCE_FIELD_CONFIG_UPDATED,
                fieldConfig: {
                    ...state.editingConfig.dataFileImportConfig.mandatoryCustomerFieldConfig,
                    trimOptions: state.editingConfig.dataFileImportConfig.mandatoryCustomerFieldConfig.trimOptions.map(
                        (to: FileImportConfigFieldTrimOptionState) => {
                            if (to.key !== trimOption.key) return to
                            return trimOption
                        }
                    )
                }
            })
    }

    const onCustomerReferenceConfigTrimOptionDelete = (trimOption: FileImportConfigFieldTrimOptionState) => {
        if (state.editingConfig.dataFileImportConfig.type === FileType.TRANSACTION)
            dispatch({
                type: FileImportConfigActionEnum.DATA_FILE_CONFIG_CUSTOMER_REFERENCE_FIELD_CONFIG_UPDATED,
                fieldConfig: {
                    ...state.editingConfig.dataFileImportConfig.mandatoryCustomerFieldConfig,
                    trimOptions: state.editingConfig.dataFileImportConfig.mandatoryCustomerFieldConfig.trimOptions.filter(
                        (to: FileImportConfigFieldTrimOptionState) => to.key !== trimOption.key
                    )
                }
            })
    }

    const getDataFields = () =>
        state.dataFields.filter(df => {
            if (state.editingConfig.fileType === FileType.CUSTOMER) {
                return df.fieldType === FieldType.CUSTOMER
            }

            return df.fieldType === FieldType.TRANSACTION
        })

    return (
        <Loading isLoading={!state} colour="blue">
            <div className="d-flex flex-column w-100 h-100 overflow-auto">
                <ImportFileConfig config={state.editingConfig} dispatch={dispatch} />

                {state.editingConfig.fileType === FileType.CUSTOMER || state.editingConfig.fileType === FileType.TRANSACTION ? (
                    <div className="d-flex flex-column">
                        <ReferenceFieldConfig
                            label={
                                state.editingConfig.fileType === FileType.CUSTOMER
                                    ? "Customer Reference field (unique identifier for customer)"
                                    : "Transaction Reference field (unique identifier for transaction)"
                            }
                            state={{
                                config: state.editingConfig.dataFileImportConfig.mandatoryFieldConfig,
                                hasHeaderRow: state.editingConfig.dataFileImportConfig.hasHeaderRow
                            }}
                            onColumnsChange={onReferenceConfigColumnsChange}
                            onAddTrimOption={onReferenceConfigAddTrimOption}
                            onTrimOptionChange={onReferenceConfigTrimOptionChange}
                            onTrimOptionDelete={onReferenceConfigTrimOptionDelete}
                        />
                        {state.editingConfig.dataFileImportConfig.type === FileType.TRANSACTION && (
                            <ReferenceFieldConfig
                                label="Customer Reference field (unique identifier for customer)"
                                state={{
                                    config: state.editingConfig.dataFileImportConfig.mandatoryCustomerFieldConfig,
                                    hasHeaderRow: state.editingConfig.dataFileImportConfig.hasHeaderRow
                                }}
                                onColumnsChange={onCustomerReferenceConfigColumnsChange}
                                onAddTrimOption={onCustomerReferenceConfigAddTrimOption}
                                onTrimOptionChange={onCustomerReferenceConfigTrimOptionChange}
                                onTrimOptionDelete={onCustomerReferenceConfigTrimOptionDelete}
                            />
                        )}
                        <ImportFieldConfigs
                            state={{
                                fieldConfigs: state.editingConfig.dataFileImportConfig.optionalFieldConfigs,
                                dataFields: getDataFields(),
                                hasHeaderRow: state.editingConfig.dataFileImportConfig.hasHeaderRow,
                                type: state.editingConfig.dataFileImportConfig.type
                            }}
                            dispatch={dispatch}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </Loading>
    )
}

export default FileImportConfigSetup
