import { useEffect, useState } from 'react'
import { Collapsable, IconButton, Loading, useApi, useClient, useConfig } from 'invevo-react-components';
import SmsTemplateCard from './SmsTemplateCard';
import classes from './SmsTemplates.module.scss'

const SmsTemplates = ({
    templates,
    onSmsTemplateSelected,
    dispatch,
    onCreateClicked
}) => {
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const api = useApi()
    const client = useClient()
    const config = useConfig()

    useEffect(() => {
        if (isFetching || hasFetched || !config.CONNECT_API_URL) {
            return
        }

        setIsFetching(true)
        api.get(`${config.CONNECT_API_URL}/api/${client}/sms-template`)
            .then(response => {
                setIsFetching(false)
                setHasFetched(true)
                dispatch({ type: 'SMS_TEMPLATES_RETRIEVED', smsTemplates: mapTemplates(response.data) })
            })
            .catch(error => console.error(error))
    }, [api, client, config.CONNECT_API_URL, dispatch, hasFetched, isFetching])

    return (
        <div className={`d-flex flex-column py-3 px-3 shadow  ${classes.container}`}>
            <div className="d-flex w-100 pb-4">
                <h3 className="text-blue no-select ">SMS Templates</h3>
                <IconButton className="ms-auto" iconClasses="fal fa-plus" onClick={onCreateClicked} />
            </div>
            <Loading isLoading={isFetching} colour="blue">
                <div className="mt-4" >
                    <Collapsable title="ACTIVE SMS TEMPLATES" open={true} id="activeSmsTemplates">
                        <div className="d-flex flex-column">
                            {templates.filter(template => template.isActive).map(t =>
                                <SmsTemplateCard key={t.reference} template={t} onSmsTemplateSelected={onSmsTemplateSelected} />
                            )}
                            {templates.filter(template => template.isActive).length === 0 &&
                                <span className="text-grey">You do not have any active SMS templates</span>
                            }
                        </div>
                    </Collapsable>
                    <Collapsable className="mt-4" title="ARCHIVED SMS TEMPLATES" id="archivedSmsTemplates">

                        <div className="d-flex flex-column">
                            {templates.filter(template => !template.isActive).map(t =>
                                <SmsTemplateCard key={t.reference} template={t} onSmsTemplateSelected={onSmsTemplateSelected} />
                            )}
                            {templates.filter(template => !template.isActive).length === 0 &&
                                <span className="text-grey">You do not have any archived SMS templates</span>
                            }
                        </div>
                    </Collapsable>
                </div>
            </Loading>
        </div>
    )
}

export default SmsTemplates;

const mapTemplates = (data) => {
    return data.map(template => {
        return {
            reference: template.reference,
            name: template.name,
            body: template.body,
            isActive: template.isActive,
            isSelected: false,
            isNew: false,
            hasCustomerGroupFilterApplied: template.hasCustomerGroupFilterApplied,
            customerGroups: template.customerGroups,
            smsSenderIds: template.smsSenderIds
        }
    })
}
