import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AccountCard from './accountCard';
import AccountDetail from './accountDetail';
import { useInternationalization } from '@progress/kendo-react-intl';
import {
  executeAuthAsyncGet,
  executeAsyncResetGet
} from '../../utility/asyncSupport';
import { getRecentPaymentTransactionsIds } from '../../api/functionApi';
import { selectTransaction } from '../../actions/functionActions';
import moment from 'moment';
import { useClient } from 'invevo-react-components';

function getHighlight(amount) {
  if (amount > 0) return 'negitive';
  if (amount < 0) return 'positive';
  return 'neutral';
}

const RecentPaymentsCard = ({
  title,
  accountId,
  isVirtualAccount,
  open,
  setOpen,
  currencyFormat,
  recentPayments,
  executeAuthAsyncGet,
  executeAsyncResetGet,
  goToTransaction
}) => {
  const client = useClient()
  const internationalization = useInternationalization();

  useEffect(() => {
    const controller = new AbortController();
    if (isVirtualAccount) return;

    executeAuthAsyncGet(
      client,
      'panelcontent/GetRecentPaymentsPanelData',
      'RECENT_PAYMENTS',
      {
        customerId: accountId
      },
      null,
      null,
      controller.signal
    );

    return () => {
      if (isVirtualAccount) return;
      controller.abort();
      executeAsyncResetGet('RECENT_PAYMENTS');
    };
  }, [accountId, isVirtualAccount, executeAuthAsyncGet, executeAsyncResetGet, client]);

  if (isVirtualAccount) return null;

  let content = (
    <AccountDetail key="recent-payments-loading" style={{ minHeight: '161px' }}>
      Loading...
    </AccountDetail>
  );
  if (recentPayments.isFetched && recentPayments.result && recentPayments.result.length > 0) {
    content = recentPayments.result
      .filter((d) => d.currency !== 'ALL')
      .slice(0, 5) // serves 10
      .map((d) => {
        const amount = d.amount === null ? 0 : d.amount;
        const title = moment(d.text, 'YYYY-MM-DD').format('DD MMM YYYY');
        const value = `${d.currency} ${internationalization.formatNumber(
          amount,
          currencyFormat
        )}`;

        return (
          <AccountDetail
            key={`recent-payments-${d.id}`}
            title={title}
            value={value}
            highlight={getHighlight(amount)}
            onClick={() => goToTransaction(client, d.id, accountId)}
          />
        );
      });
  } else if (recentPayments.isFetched && recentPayments.result && recentPayments.result.length === 0) {
    content = <AccountDetail title="None" key="recent-payments-none" />;
  }

  return (
    <AccountCard title={title} open={open} setOpen={setOpen}>
      {content}
    </AccountCard>
  );
};

const mapStateToProps = (state) => ({
  dateFormat: state.lookupReducer.globalSettings.results.dateFormat,
  currencyFormat: state.lookupReducer.globalSettings.results.currencyFormat,
  recentPayments: state.asyncReducer.RECENT_PAYMENTS
});
export default connect(mapStateToProps, {
  executeAuthAsyncGet,
  executeAsyncResetGet,
  goToTransaction: (client, transactionId, accountId) => (dispatch) => {
    if (transactionId !== 'ALL') {
      dispatch(selectTransaction([]));
      dispatch(getRecentPaymentTransactionsIds(client, transactionId, accountId));
    }
  }
})(RecentPaymentsCard);
