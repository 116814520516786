import classes from './InformationWidget.module.scss'
import { Checkbox, CustomerFieldTypes } from 'invevo-react-components'
import DataFieldDropdown from '../../../../../library/dropdowns/DataFieldDropdown'
import DataField from '../../../../../types/DataField'

const REFERENCE_FIELD_NAME = "reference"


type InformationFieldNameProps = {
    index: number
    field: any
    customerFields: DataField[]
    onFieldNameChanged: (field: DataField, option: DataField | undefined) => void
    onFieldRemoveClicked: (field: DataField) => void
    onIsFieldEditableClicked: (field: any) => void
}
const InformationFieldName = ({
    index,
    field,
    customerFields,
    onFieldNameChanged,
    onFieldRemoveClicked,
    onIsFieldEditableClicked
}: InformationFieldNameProps) => {
    const onOptionSelected = (option: DataField | undefined) => onFieldNameChanged(field, option)
    const onFieldRemoveClick = () => onFieldRemoveClicked(field)
    const onFieldEditableClicked = () => onIsFieldEditableClicked(field)

    return (
        <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
                <span className={`text-uppercase small mb-1 ${classes.label}`}>field name {index + 1}</span>
                <div className={`d-flex align-items-center ms-auto`}>
                    {field.type !== CustomerFieldTypes.CALCULATE &&
                        <div className='d-flex align-items-center me-2'>
                            <Checkbox isChecked={field.isEditable} onClick={onFieldEditableClicked} disabled={field.value === REFERENCE_FIELD_NAME} />
                            <span className={`small ms-2 ${classes.label} ${field.value === REFERENCE_FIELD_NAME && classes.disabled}`}>is editable?</span>
                        </div>
                    }
                    <i className={`fas fa-times-circle pointer ${classes['remove-field-icon']}`} onClick={onFieldRemoveClick}></i>
                </div>
            </div>
            <DataFieldDropdown
                className="flex-grow-1 mb-2"
                options={customerFields}
                selectedOption={customerFields.find(cf => cf.value === field.value)}
                onOptionSelected={onOptionSelected}
            />
        </div>
    )
}

export default InformationFieldName