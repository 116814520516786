export const receiveGetAccountStatusList = (json) => ({
  type: 'RECEIVE_ACCOUNT_STATUS_LIST',
  json
});

export const receiveGetAccountStatus = (json) => ({
  type: 'RECEIVE_ACCOUNT_STATUS_DATA',
  json
});

export const requestSetAccountStatus = () => ({
  type: 'REQUEST_SET_ACCOUNT_STATUS'
});
export const receiveSetAccountStatus = (json) => ({
  type: 'RECEIVE_SET_ACCOUNT_STATUS',
  json
});
export const errorSetAccountStatus = (err) => ({
  type: 'ERROR_SET_ACCOUNT_STATUS',
  err
});
