import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showToastErrorMessage } from '../../api/toasterApi';
import { isNil } from 'lodash';

class ReportMenu extends Component {
  componentDidUpdate() {
    if (this.props.reportDetails.isFetched) {
      if (
        this.props.reportDetails.error === null &&
        !isNil(this.props.reportDetails.result.embedUrl)
      ) {
        this.loadReport();
      } else {
        showToastErrorMessage(
          'Error while loading Report. Please check the report configuration.'
        );
      }
    }
  }

  loadReport = () => {
    const models = window['powerbi-client'].models;
    let filters = [];
    if (
      !isNil(this.props.selectedAccount) &&
      this.props.selectedAccount.miaAccountCode !== '' &&
      !this.props.isReportNavigationFromMainMenu
    ) {
      filters = [
        {
          $schema: 'http://powerbi.com/product/schema#advanced',
          target: {
            table: this.props.reportDetails.result.customerQueryName,
            column: this.props.reportDetails.result.customerColumnName
          },
          operator: 'Eq',
          values: [this.props.selectedAccount.miaAccountCode]
        }
      ];
    }

    const config = {
      type: 'report',
      tokenType: models.TokenType.Embed,
      accessToken: this.props.reportDetails.result.accessToken,
      embedUrl: this.props.reportDetails.result.embedUrl,
      id: this.props.reportDetails.result.reportId,
      permissions: models.Permissions.All,
      filters: filters,
      settings: {
        filterPaneEnabled: true,
        navContentPaneEnabled: true,
        extensions: [
          {
            command: {
              name: 'command name',
              title: 'command title',
              extend: {
                visualContextMenu: {
                  title: 'context menu title'
                },
                visualOptionsMenu: {
                  title: 'options menu title'
                }
              }
            }
          }
        ]
      }
    };

    const reportContainer = window.$('#reportContainer')[0];
    const powerbi = window['powerbi'];
    powerbi.embed(reportContainer, config);
  };

  render() {
    return (
      <div
        id="reportContainer"
        style={{ width: '100%', height: '100vh' }}
      ></div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reportDetails: state.reportReducer.reportDetails,
    selectedAccount: state.currentSelectionReducer.selectedAccount,
    reportName: state.currentSelectionReducer.powerBIReportName,
    isReportNavigationFromMainMenu:
      state.currentSelectionReducer.isReportNavigationFromMainMenu
  };
};

export default connect(mapStateToProps)(ReportMenu);
