import moment from "moment"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import { convertToCurrency } from "../../../../library/helpers/numberUtils"
import classes from "./TransactionOverlay.module.scss"
import { DirectIdTransactionDto } from "../../types/dtos/CreditCircleTransactionsDto"

type TransactionOverlayProps = {
    transaction: DirectIdTransactionDto
    onClose: () => void
}

const TransactionOverlay = ({ transaction, onClose }: TransactionOverlayProps) => {
    return (
        <div className={`d-flex ${classes["overlay-background"]}`}>
            <div className={`d-flex mx-auto ${classes.overlay} p-3`}>
                <div className="d-flex flex-column bg-white border-1 border rounded w-100 px-2 pt-1 pb-3">
                    <div className="d-flex align-items-center justify-content-between py-3 text-grey border-1 border-bottom">
                        <span className="fs-6 text-uppercase">payment date</span>
                        <span className="fs-6 fw-bold">{moment(transaction.bookingDate).format("DD/MM/YY")}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between py-3 text-grey border-1 border-bottom">
                        <span className="fs-6 text-uppercase">amount</span>
                        <span className="fs-6 fw-bold">{convertToCurrency(transaction.amount, "EUR")}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between py-3 text-grey border-1 border-bottom">
                        <span className="fs-6 text-uppercase">Merchant name</span>
                        <span className="fs-6 fw-bold">{transaction.merchantDetails.merchantName ?? "n/a"}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between py-3 text-grey border-1 border-bottom">
                        <span className="fs-6 text-uppercase">category</span>
                        <span className="fs-6 fw-bold">{transaction.enrichedData.category.name}</span>
                    </div>
                    <StandardButton iconClasses="far fa-times" className="mt-auto" label="Close" onClick={onClose} />
                </div>
            </div>
        </div>
    )
}

export default TransactionOverlay
