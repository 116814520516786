import { IconButton } from "invevo-react-components"
import moment from "moment"
import ListCard from "../../../../library/listCards/ListCard"
import WorkflowRule from "../../types/WorkflowRule"
import classes from "./WorkflowRuleCard.module.scss"

type WorkflowCardProps = {
    rule: WorkflowRule
    isSelected: boolean
    entityType: string
    onRuleSelected: (ruleReference: string) => void
    onRuleRemoved: (ruleReference: string) => void
}

const WorkflowRuleCard = ({ rule, isSelected, entityType, onRuleSelected, onRuleRemoved }: WorkflowCardProps) => {
    const onCardClicked = () => onRuleSelected(rule.reference)
    const onRemoveClicked = () => onRuleRemoved(rule.reference)

    const sortedModificationDates = rule.modificationHistory.map(modification => modification.modifiedAt).sort()

    return (
        <ListCard isSelected={isSelected} onSelect={onCardClicked}>
            <div className="d-flex align-items-center my-2">
                <i className="fal fa-sitemap fa-2x text-white" />
                <span className="ms-3 fs-5">{rule.name}</span>
                {rule.modificationHistory.length === 0 && isSelected && (
                    <IconButton className="ms-auto" iconClasses="fal fa-trash" size="small" onClick={onRemoveClicked} />
                )}
            </div>
            <div className={`${classes.separator} d-flex flex-row my-2 bg-grey`} />
            <div className="d-flex">
                <span className="me-2">Type:</span>
                <span>{entityType}</span>
            </div>
            <div className="d-flex">
                <span className="me-2">Updated:</span>
                <span>{moment(sortedModificationDates[sortedModificationDates.length - 1]).format("DD MMMM yyyy")}</span>
            </div>
            <div className="d-flex">
                <span className="me-2">Created:</span>
                <span>{moment(sortedModificationDates[0]).format("DD MMMM yyyy")}</span>
            </div>
        </ListCard>
    )
}

export default WorkflowRuleCard
