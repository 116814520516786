import { useState } from "react"
import DashboardConfigState from "../../types/DashboardConfigState"
import useClient from "../../../../hooks/useClient"
import { useEnvConfig } from "../../../../contexts/EnvironmentConfigContext"
import useApiQuery from "../../../../hooks/useApiQuery"
import { useUser } from "../../../../contexts/UserContext"
import DropdownOption from "../../../../types/DropdownOptions"
import { useOverlay } from "../../../../contexts/overlay/OverlayContext"
import Input from "../../../../library/Inputs/Input"
import Dropdown from "../../../../library/dropdowns/Dropdown"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import ProgressButton from "../../../../library/buttons/ProgressButton/ProgressButton"
import Spinner from "../../../../library/Loading/Spinner"
import { v4 as uuidv4 } from "uuid"
import { mapDashboardConfigToPutDto } from "../../helpers/dashboardMapping"

type Props = {
    dashboardConfig?: DashboardConfigState | undefined,
    dashboardConfigs?: DashboardConfigState[] | undefined,
    isDuplicatingAll: boolean,
    isNewEntityStructureEnabled: boolean,
    isAggregatedDashboard: boolean,
    existingDashboardConfigNames: string[],
    onDashboardConfigDuplicated?: (dashboardConfig: DashboardConfigState | undefined) => void
    onDashboardConfigsDuplicated?: (dashboardConfig: DashboardConfigState[]) => void
    onClose: () => void
}

const DuplicateDashboardConfigForm = ({
    dashboardConfig,
    dashboardConfigs,
    isDuplicatingAll,
    existingDashboardConfigNames,
    isNewEntityStructureEnabled,
    isAggregatedDashboard,
    onDashboardConfigDuplicated,
    onDashboardConfigsDuplicated,
    onClose
}: Props) => {
    const [displayName, setDisplayName] = useState(dashboardConfig?.name ?? "")
    const dashboardConfigReference = uuidv4()

    const client = useClient()
    const [selectedClient, setSelectedClient] = useState<string>()
    const clientToUse = selectedClient ?? client

    const config = useEnvConfig()
    const saveDashboardConfigRequest = useApiQuery({
        url: `${config.DATA_API_URL}/api/${clientToUse}/dashboard-config`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    const user = useUser()
    const clientOptions = user.accessibleClients.sort().map(client => ({
        value: client,
        label: client
    }))
    const onClientSelected = (option: DropdownOption<string>) => setSelectedClient(option.value)

    const getDashboardConfigs = useApiQuery<DashboardConfigState[]>({
        url: `${config.DATA_API_URL}/api/${clientToUse}/dashboard-configs`,
        method: "GET",
        isExecutedAutomatically: clientToUse !== client
    })

    const disallowedDashboardConfigNames = clientToUse === client ? existingDashboardConfigNames : getDashboardConfigs.data?.map(c => c.name) ?? []

    const overlay = useOverlay()
    const onConfirmClicked = () => {
        if (!dashboardConfig || !onDashboardConfigDuplicated) return Promise.resolve()

        const newDashboardConfig = {
            ...dashboardConfig,
            name: displayName,
            id: dashboardConfigReference,
            reference: dashboardConfigReference,
            gridWidgets: dashboardConfig.gridWidgets?.map(widget => ({ ...widget, reference: uuidv4() })) ?? []
        }
        const putDto = mapDashboardConfigToPutDto(
            newDashboardConfig,
            isNewEntityStructureEnabled,
            isAggregatedDashboard
        )

        return saveDashboardConfigRequest.execute(dashboardConfigReference, putDto).then(() => {
            if (clientToUse !== client) return
            onDashboardConfigDuplicated(newDashboardConfig)
        }).then(() => {
            onClose()
            overlay.showCreatedSuccessfullyOverlay("Dashboard config")
        })
    }

    const onConfirmAllClicked = () => {
        if (!dashboardConfigs || !onDashboardConfigsDuplicated) return Promise.resolve()

        const newDashboardConfigs = dashboardConfigs.map((config: DashboardConfigState) => {
            const id = uuidv4()
            return {
                ...config,
                reference: id,
                name: client !== clientToUse ? config.name : `${config.name} - Copy`,
                gridWidgets: config.gridWidgets.map(widget => ({ ...widget, reference: uuidv4() }))
            }
        })

        const putDtos = newDashboardConfigs.map(newConfig => mapDashboardConfigToPutDto(
            newConfig,
            isNewEntityStructureEnabled,
            isAggregatedDashboard
        ))

        const saveConfigPromise = putDtos.map(dto => saveDashboardConfigRequest.execute(newDashboardConfigs.find(c => c.name === dto.name)?.reference, dto))

        return Promise.allSettled(saveConfigPromise).then(result => {
            if (result.some(r => r.status === "rejected")) return Promise.reject()
            if (clientToUse === client)
                onDashboardConfigsDuplicated(newDashboardConfigs.map(c => ({ ...c, id: c.reference })))
            onClose()
            overlay.showCreatedSuccessfullyOverlay("Dashboard config")
        })
    }

    const hasInvalidName = disallowedDashboardConfigNames.some(n => n === displayName)

    return (
        <div className="d-flex flex-column gap-4">
            {!isDuplicatingAll &&
                <div className="d-flex flex-column">
                    <div className="d-flex gap-2">
                        <span className="text-uppercase my-auto text-grey no-select">Dashboard Name</span>
                        <Input placeholder="Please enter a name" ariaLabel="dashboard-config-duplicate-display-name" value={displayName} onChange={setDisplayName} />
                    </div>
                </div>
            }
            {clientOptions.length > 1 && (
                <div className="d-flex gap-2 align-items-center">
                    <span className="text-uppercase my-auto text-grey no-select">Client:</span>
                    <Dropdown
                        options={clientOptions}
                        onOptionSelected={onClientSelected}
                        selectedOption={clientOptions.find(o => o.value === clientToUse)}
                        ariaLabel="client-dropdown"
                        disabled={getDashboardConfigs.isFetching}
                    />
                    {getDashboardConfigs.isFetching && <Spinner colour="blue" size="small" />}
                </div>
            )}
            {hasInvalidName && !isDuplicatingAll && (
                <div className="d-flex align-items-center text-danger text-center">
                    <i className="fal fa-exclamation-triangle me-2" />
                    <span>Dashboard config with this name already exists</span>
                </div>
            )}
            {getDashboardConfigs.hasErrored && (
                <div className="d-flex align-items-center text-danger text-center">
                    <i className="fal fa-exclamation-triangle me-2" />
                    <span>Failed to fetch clients dashboard configs</span>
                </div>
            )}
            <div className="d-flex gap-2 justify-content-between">
                <StandardButton colour="red" iconClasses="fal fa-times" label="Cancel" onClick={onClose} />
                <ProgressButton
                    colour="blue"
                    iconClasses="fal fa-check"
                    label="Confirm"
                    failedText="Failed!"
                    onClickWithPromise={isDuplicatingAll ? onConfirmAllClicked : onConfirmClicked}
                    disabled={getDashboardConfigs.isFetching || hasInvalidName || getDashboardConfigs.hasErrored}
                />
            </div>
        </div>
    )
}

export default DuplicateDashboardConfigForm