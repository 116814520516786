const DropdownOption = ({ option, selectedOption, onOptionSelected }) => {
    const onClick = () => onOptionSelected(option)

    return (
        <div className="d-flex p-2 pointer" onClick={onClick}>
            <div className="d-block w-100 no-select">
                <div className="d-flex align-items-center">
                    <span>{option.label}</span>
                    {option.value === selectedOption?.value && <i className="ms-auto text-blue fas fa-check"></i>}
                </div>
            </div>
        </div>
    )
}

export default DropdownOption
