import { Loading, useClient, useConfig } from "invevo-react-components"
import { useState, ChangeEvent, useReducer } from "react"
import useApiQuery from "../../../../hooks/useApiQuery"
import Role from "../../roles/types/Role"
import { getDefaultClientUser } from "../helpers"
import { ClientUserDto } from "../types/ClientUserResultsDto"
import classes from "./ClientUsers.module.scss"
import ClientUserGrid from "./ClientUserGrid"
import CustomerSearchReducer from "../reducers/ClientUserReducer"
import ClientUserForm from "./ClientUserForm"
import { DataFieldDto } from "../../../../types/dtos/DataFieldDto"
import DataField from "../../../../types/DataField"
import FieldType from "../../../../types/enums/FieldType"
import DataPrimitive from "../../../../types/DataPrimitive"
import Lookup from "../../../../types/Lookup"
import EntityConfig from "../../../entityConfig/types/EntityConfig"

const ClientUsers = () => {
    const client = useClient()
    const config = useConfig()

    const [state, dispatch] = useReducer(CustomerSearchReducer, {
        clientUserDataRows: []
    })

    const [showClientUserForm, setShowClientUserForm] = useState<boolean>(false)
    const [user, setUser] = useState<ClientUserDto>()
    const [roles, setRoles] = useState<Role[]>([])
    const [customerDataFields, setCustomerDataFields] = useState<DataField[]>([])
    const [quickSearchValue, setQuickSearchValue] = useState<string>("")
    const [appliedQuickSearchValue, setAppliedQuickSearchValue] = useState<string>("")
    const [isEditing, setIsEditing] = useState<boolean>(false)

    const getRolesRequest = useApiQuery<Role[]>({
        url: `${config.USER_ROLES_API_URL}/api/${client}/roles`,
        method: "GET",
        onSuccess: (roles: Role[]) => setRoles(roles)
    })

    useApiQuery<DataFieldDto[]>({
        url: `${config.DATA_API_URL}/api/${client}/data-field`,
        method: "GET",
        onSuccess: (fields: DataFieldDto[]) =>
            setCustomerDataFields(
                fields
                    .filter(field => field.fieldType === FieldType.CUSTOMER)
                    .map(field => ({
                        value: field.fieldName,
                        label: field.displayName,
                        type: field.dataPrimitive,
                        lookup: field.dataPrimitive === DataPrimitive.LOOKUP ? field.lookup : undefined,
                        isQuickFilter: field.isQuickFilter,
                        fieldType: field.fieldType
                    }))
            )
    })

    const getLookupsRequest = useApiQuery<Lookup[]>({
        url: `${config.DATA_API_URL}/api/${client}/lookups`,
        method: "GET"
    })

    const getEntityConfigsRequest = useApiQuery<EntityConfig[]>({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-config`,
        method: "GET"
    })

    const toggleShowClientUserForm = () => setShowClientUserForm(!showClientUserForm)

    const onQuickSearchValueChanged = (event: ChangeEvent<HTMLInputElement>) => setQuickSearchValue(event.target.value)

    const applyQuickSearch = () => setAppliedQuickSearchValue(quickSearchValue)

    const clearQuickSearch = () => {
        setAppliedQuickSearchValue("")
        setQuickSearchValue("")
    }

    const onAddNewClientUser = () => {
        setUser(getDefaultClientUser())
        setShowClientUserForm(true)
        setIsEditing(false)
    }

    const onEditClientUser = (clientUser: ClientUserDto) => {
        setUser(clientUser)
        setShowClientUserForm(true)
        setIsEditing(true)
    }

    return (
        <div className={`d-flex w-100 h-100 ${classes.background}`}>
            <div className="d-flex flex-column w-100 h-100 p-3">
                <div className="d-flex justify-content-between fs-3 fw-lighter p-2">
                    <span className="text-light-grey">Users</span>
                    <div className="d-flex flex-grow-1">
                        <div className={`d-flex ms-3  ${classes.quickSearchContainer}`}>
                            <input
                                className={`form-control ${classes.quickSearchInput}`}
                                value={quickSearchValue}
                                onChange={onQuickSearchValueChanged}
                                placeholder="Search..."
                                aria-label="quick-search"
                            />
                            <div
                                className={`d-flex fs-6 px-2 align-items-center pointer ${classes.quickSearchInputButton}`}
                                onClick={applyQuickSearch}
                                role="button"
                                aria-label="apply-quick-search"
                            >
                                Apply
                            </div>
                            <div
                                className={`d-flex fs-6 px-2 align-items-center pointer ${classes.quickSearchInputButton}`}
                                onClick={clearQuickSearch}
                                role="button"
                                aria-label="clear-quick-search"
                            >
                                Clear
                            </div>
                        </div>
                    </div>
                </div>
                <Loading isLoading={getRolesRequest.isFetching} colour="white">
                    {getRolesRequest.hasFetched && !getRolesRequest.isFetching && (
                        <ClientUserGrid
                            clientUserDataRows={state.clientUserDataRows}
                            roles={roles}
                            onAddNewClientUser={onAddNewClientUser}
                            onEditClientUser={onEditClientUser}
                            quickSearchFilter={appliedQuickSearchValue}
                            dispatch={dispatch}
                        />
                    )}
                </Loading>
            </div>
            {showClientUserForm && (
                <div className="w-100" key={user?.username || ""}>
                    <ClientUserForm
                        toggleIsShown={toggleShowClientUserForm}
                        userData={user}
                        roles={roles}
                        isEditing={isEditing}
                        dispatch={dispatch}
                        customerDataFields={customerDataFields}
                        lookups={getLookupsRequest.data ?? []}
                        entityConfigs={getEntityConfigsRequest.data ?? []}
                    />
                </div>
            )}
        </div>
    )
}

export default ClientUsers
