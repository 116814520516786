import React, { useEffect, useState } from "react"
import { useLocalStorage } from "../hooks/useLocalStorage"

const TranslatedContext = React.createContext()

const TranslationProvider = ({ children }) => {
    const [cachedLang, setCachedLang] = useLocalStorage("inv_language_user_settings", { preferredLanguageCode: "en", dictionaries: {} }, true)
    const [state, setState] = useState(cachedLang)
    useEffect(() => setCachedLang(state), [setCachedLang, state])

    return <TranslatedContext.Provider value={[state, setState]}>{children}</TranslatedContext.Provider>
}

const useTranslation = () => {
    const context = React.useContext(TranslatedContext)
    if (!context) {
        throw new Error(`useTranslation must be used within a <Translation />`)
    }
    return context
}

export { TranslationProvider, useTranslation }
