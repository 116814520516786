import React from 'react';
import { connect } from 'react-redux';
import { getApiUrl } from '../../../constants';
import { executeAuthAsyncPost } from '../../../utility/asyncSupport';
import {
  showToastSuccessMessage,
  showToastErrorMessage
} from '../../../api/toasterApi.js';

import { IsUser } from '../../common/grids/gridCells';
import EditDeleteCommandCell from '../../common/grids/editDeleteCommandCell';
import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../../common/grids/StatefulGrid';
import { ConfrimDialog } from '../../common/confirmDialog';

export class UserGrid extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      refresh: 0,
      showDeleteDialog: false,
      itemToDelete: ''
    };

    this.EditDeleteCommand = EditDeleteCommandCell({
      onEditClick: this.onEditRowClicked,
      onDeleteClick: this.onDeleteRowClicked
    });
  }

  componentDidMount() {
    if (this.props.refreshCallback != null) {
      this.props.refreshCallback(this.refresh);
    }
  }

  refresh = () => {
    this.setState({ refresh: this.state.refresh + 1 });
  };

  onEditRowClicked = (e, dataItem) => {
    e.preventDefault();
    this.props.onEdit(dataItem.userId);
  };

  onDeleteRowClicked = (e, dataItem) => {
    e.preventDefault();
    this.setState({ showDeleteDialog: true, itemToDelete: dataItem.userId });
  };

  deleteItem = () => {
    this.props.executeAuthAsyncPost(
      'users/delete',
      'DELETE_USER',
      this.state.itemToDelete,
      (result) => {
        this.onDeleteSuccess(result);
      }
    );
  };

  closeDialog = () => {
    this.setState({
      showDeleteDialog: false
    });
  };

  onDeleteSuccess = (response) => {
    if (response.IsDeleted) {
      showToastSuccessMessage('User Deleted');
      this.closeDialog();
      this.refresh();
    } else {
      showToastErrorMessage(response.Error);
    }
  };

  static getKey() {
    return 'user-grid';
  }

  render() {
    return (
      <>
        <StatefulGrid
          client={this.props.client}
          id={UserGrid.getKey()}
          key={UserGrid.getKey()}
          path={getApiUrl(this.props.client) + 'api/users'}
          pageSize={10}
          sortable
          filterable
          pageable={{
            pageSizes: [10, 50, 100],
            buttonCount: 5
          }}
          onRowClick={this.onRowClick}
          onStateChange={this.onStateChange}
          defaultField={{ field: 'UserName', dir: 'asc' }}
          refresh={this.state.refresh}
        >
          <GridColumn field={'userName'} title={'User Name'} />
          <GridColumn field={'contactName'} title={'Contact Name'} />
          <GridColumn field={'phone'} title={'Phone'} />
          <GridColumn field={'email'} title={'Email'} />
          <GridColumn field={'languageDescription'} title={'Language'} />
          <GridColumn
            field={'isUser'}
            title={'Is User'}
            cell={IsUser}
            filter={'boolean'}
          />
          <GridColumn
            cell={this.EditDeleteCommand}
            width={'250px'}
            filterable={false}
          />
        </StatefulGrid>
        <ConfrimDialog
          show={this.state.showDeleteDialog}
          message={'Are you sure you want to delete this user?'}
          onConfirm={this.deleteItem}
          onClose={this.closeDialog}
        />
      </>
    );
  }
}

//const mapStateToProps = (state) => ({ });

export default connect(null, {
  executeAuthAsyncPost
})(UserGrid);
