import { useState } from "react"
import classes from "./AssignUsersToRole.module.scss"
import { Overlay, Loading, Checkbox, StandardButton, useApi, useClient, useConfig, useFeatureToggle } from "invevo-react-components"
import { convertRoleToDto } from "../helpers"
import { actionTypes } from "../../../reducers/actionTypes"
import { defaultCustomerPermission } from "../permissions/CustomerPermission"
import SearchInput from "../SearchInput"
import { toast } from 'react-toastify'
import useApiQuery from "../../../hooks/useApiQuery"

const AssignUsersToRole = ({ state, dispatch, onClose }) => {
    const { role, users } = state

    const api = useApi()
    const client = useClient()
    const config = useConfig()

    const { isEnabled: isRolesAndUsersInElasticEnabled } = useFeatureToggle("storeRolesAndUsersInElastic")

    const putUserRoleAssignments = useApiQuery({
        url: `${config.PLATFORM_API_URL}/api/${client}/user-role-assignment`,
        method: "PUT",
        isExecutedAutomatically: false
    })
    const deleteUserRoleAssignments = useApiQuery({
        url: `${config.PLATFORM_API_URL}/api/${client}/user-role-assignment`,
        method: "DELETE",
        isExecutedAutomatically: false
    })

    const [isSaving, setIsSaving] = useState(false)
    const [assignedClientUserIds, setAssignedClientUserIds] = useState(role.assignedUsers.map(u => u.clientUserId))

    const [searchQuery, setSearchQuery] = useState("")

    const isClientUserSelected = clientUser => assignedClientUserIds.includes(clientUser.id)

    const toggleClientUser = clientUser => isClientUserSelected(clientUser) ?
        setAssignedClientUserIds(assignedClientUserIds.filter(u => u !== clientUser.id)) :
        setAssignedClientUserIds([...assignedClientUserIds, clientUser.id])

    const onSave = () => {
        const usersToAdd = users
            .filter(clientUser => !role.assignedUsers.map(user => user.clientUserId).includes(clientUser.id))
            .filter(clientUser => assignedClientUserIds.includes(clientUser.id))
            .map(clientUser => ({
                clientUserId: clientUser.id,
                clientUsername: clientUser.username,
                permissions: {
                    customerPermission: role.customerPermission && defaultCustomerPermission
                }
            }))

        const usersToRemove = role.assignedUsers
            .filter(user => !assignedClientUserIds.includes(user.clientUserId))
            
        if(isRolesAndUsersInElasticEnabled) {
            return Promise.all([
                ...usersToAdd.map(u => {
                    const dto = {
                        username: u.clientUsername,
                        roleReference: role.reference,
                        userRolePermissions: {
                            customerPermission: u.permissions.customerPermission || null,
                        }
                    }
                    return putUserRoleAssignments.execute(undefined, dto)
                }),
                ...usersToRemove.map(u => {
                    const dto = {
                        username: u.clientUsername,
                        roleReference: role.reference
                    }
                    return deleteUserRoleAssignments.execute(undefined, dto)
                })
            ])
                .then(_ => {
                    dispatch({ type: actionTypes.ROLE_ASSIGNED_USERS_UPDATED, roleId: role.id, newUsers: usersToAdd, removedUsers: usersToRemove })
                    setIsSaving(false)
                    onClose()
                })
                .catch(error => {
                    console.error(error)
                    toast.error("Error while updating role")
                    setIsSaving(false)
                })
        } else {
            return oldSaveCall(usersToAdd, usersToRemove)
        }
    }

    const oldSaveCall = (usersToAdd, usersToRemove) => {
        setIsSaving(true)

        const updatedRole = {
            ...role,
            assignedUsers: [...role.assignedUsers
                .filter(user => assignedClientUserIds.includes(user.clientUserId)),
            ...usersToAdd
            ]
        }

        const dto = convertRoleToDto(updatedRole)
        api.put(`/api/${client}/roles/${role.id}`, dto)
            .then(_ => {
                dispatch({ type: actionTypes.ROLE_ASSIGNED_USERS_UPDATED, roleId: role.id, newUsers: usersToAdd, removedUsers: usersToRemove })
                setIsSaving(false)
                onClose()
            })
            .catch(error => {
                console.error(error)
                toast.error("Error while updating role")
                setIsSaving(false)
            })
    }

    return (
        <Overlay title={`Assign users to ${role.name} role`} onCloseClick={onClose}>
            <div className="m-2">
                <SearchInput value={searchQuery} onChange={setSearchQuery} />
            </div>
            <div className="d-flex flex-column flex-grow-1 overflow-auto">
                <div className={`d-flex flex-column px-3 text-grey ${classes["scrolling-form"]} ${classes["assign-users-form"]}`}>
                    <Loading isLoading={isSaving} colour="blue">
                        {
                            users
                                .filter(clientUser => clientUser.username.toLowerCase().includes(searchQuery.toLowerCase()))
                                .sort((a, b) => a.username.localeCompare(b.username))
                                .map(user =>
                                    <Checkbox
                                        key={user.id}
                                        className="m-2"
                                        isChecked={isClientUserSelected(user)}
                                        onClick={() => toggleClientUser(user)}
                                        label={user.username}
                                        ariaLabel={user.username}
                                    />
                                )
                        }
                    </Loading>
                </div>
                <div className="mt-auto d-flex p-3 bg-grey">
                    <StandardButton className="ms-auto" label="Cancel" colour="grey" iconClasses="text-blue fal fa-times" border="blue" onClick={onClose} disabled={isSaving} ariaLabel="cancel" />
                    <StandardButton className="ms-3" label="Save" colour="grey" iconClasses="text-blue fal fa-save" border="blue" onClick={onSave} disabled={isSaving} ariaLabel="save" />
                </div>
            </div>
        </Overlay>
    )
}

export default AssignUsersToRole