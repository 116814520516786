import Dropdown from "../../../../../library/dropdowns/Dropdown"
import { RejectMandatoryActionEnum } from "../../../types/RejectMandatoryActionEnum"

type RejectMandatoryActionConfigProps = {
    rejectMandatoryAction: RejectMandatoryActionEnum
    onChange: (option: RejectMandatoryActionEnum) => any
}

const RejectMandatoryActionConfig = ({ rejectMandatoryAction, onChange }: RejectMandatoryActionConfigProps) => {
    const rejectMandatoryActionOptions = [
        { id: 1, value: RejectMandatoryActionEnum.FAIL_ROW, label: "Skip whole row if field invalid" },
        { id: 2, value: RejectMandatoryActionEnum.FAIL_FILE, label: "Skip whole file if field invalid" }
    ]

    const onRejectMandatoryActionOptionChange = (rejectMandatoryActionOption: any) => {
        onChange(rejectMandatoryActionOption.value)
    }

    return (
        <div className="d-flex w-100 h-100">
            <Dropdown
                className="me-3"
                placeholder={"Please select a reject action"}
                selectedOption={rejectMandatoryActionOptions.find(rao => rao.value === rejectMandatoryAction)}
                options={rejectMandatoryActionOptions}
                onOptionSelected={onRejectMandatoryActionOptionChange}
                ariaLabel="Please select a reject action for the mandatory fields"
            />
        </div>
    )
}

export default RejectMandatoryActionConfig
