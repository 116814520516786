import { useEffect, useReducer, useState } from "react"
import useApiQuery from "../../../../hooks/useApiQuery"
import FileEntityExportConfigReducer, { FileExportConfigActionEnum } from "../../reducers/FileEntityExportConfigReducer"
import { mapDomainToPutFileEntityExportConfigDto, mapGetFileEntityExportConfigsDtoToDomain } from "../utils"
import { GetFileEntityExportConfigDto } from "../../types/dtos/FileEntityExportConfigDto"
import { PutFileImportConfigDto } from "../../types/dtos/FileImportConfigDto"
import classes from "./FileExportConfiguration.module.scss"
import ProgressButton from "../../../../library/buttons/ProgressButton/ProgressButton"
import FileExportConfigSetup from "./fileExportConfigSetup/FileExportConfigSetup"
import FileExportConfigs from "./fileExportConfigs/FileExportConfigs"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import EntityConfig from "../../../entityConfig/types/EntityConfig"
import { FileEntityExportConfig } from "../../types/fileEntityExport/FileExportConfig"
import useClient from "../../../../hooks/useClient"
import { useEnvConfig } from "../../../../contexts/EnvironmentConfigContext"
import Loading from "../../../../library/Loading/Loading"

const FileExportConfiguration = () => {
    const [state, dispatch] = useReducer(FileEntityExportConfigReducer, {
        configs: [],
        entities: [],
        editingDataFields: [],
        editingConfig: undefined
    })

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

    const client = useClient()
    const config = useEnvConfig()

    const fileExportConfigRequest = useApiQuery<GetFileEntityExportConfigDto[]>({
        url: `${config.INTEGRATION_API_URL}/api/${client}/file-export-config`,
        method: "get",
        onSuccess: data =>
            dispatch({
                type: FileExportConfigActionEnum.FILE_EXPORT_CONFIGS_RETRIEVED,
                configs: mapGetFileEntityExportConfigsDtoToDomain(data)
            })
    })

    const entitiesRequest = useApiQuery({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-config`,
        method: "get",
        onSuccess: (data: EntityConfig[]) =>
            dispatch({
                type: FileExportConfigActionEnum.ENTITIES_RETRIEVED,
                entities: data
            })
    })

    const saveFileExportConfigRequest = useApiQuery<PutFileImportConfigDto>({
        url: `${config.INTEGRATION_API_URL}/api/${client}/file-export-config`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    useEffect(() => {
        const originalConfig = state.configs.find(config => config.reference === state.editingConfig?.reference)
        if (!originalConfig) {
            setHasUnsavedChanges(false)
            return
        }

        const hasChanges = JSON.stringify(originalConfig) !== JSON.stringify(state.editingConfig)
        setHasUnsavedChanges(hasChanges)
    }, [state.configs, state.editingConfig])

    const onDiscardChangesClick = () => {
        dispatch({
            type: FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_DISCARDED
        })
    }
    const onSaveChangesClick = () => {
        const dto = mapDomainToPutFileEntityExportConfigDto(state.editingConfig as FileEntityExportConfig)
        return saveFileExportConfigRequest.execute(state.editingConfig?.reference, dto).then(res => {
            dispatch({
                type: FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_SAVED
            })

            return res
        })
    }

    return (
        <div className="d-flex w-100 h-100">
            <Loading isLoading={fileExportConfigRequest.isFetching || entitiesRequest.isFetching} colour="blue">
                <FileExportConfigs state={state} dispatch={dispatch} />
                <div className="d-flex flex-column flex-grow-1">
                    <div className="h-100 overflow-auto p-4">
                        {state.editingConfig ? (
                            <FileExportConfigSetup
                                state={{ editingConfig: state.editingConfig, entities: state.entities, dataFields: state.editingDataFields }}
                                dispatch={dispatch}
                            />
                        ) : (
                            <span className="text-grey fs-4">Please select a configuration for editing or create a new one</span>
                        )}
                    </div>
                    {state.editingConfig && (
                        <div className={`d-flex bg-white p-4 mt-auto ${classes.footer} align-items-center border-top border-2`}>
                            <StandardButton
                                className="ms-auto"
                                iconClasses="far fa-undo"
                                label="Discard changes"
                                onClick={onDiscardChangesClick}
                                disabled={!hasUnsavedChanges}
                            />
                            <ProgressButton
                                className="ms-3"
                                iconClasses="far fa-save"
                                label="Save changes"
                                onClickWithPromise={onSaveChangesClick}
                                disabled={!hasUnsavedChanges}
                            />
                        </div>
                    )}
                </div>
            </Loading>
        </div>
    )
}

export default FileExportConfiguration
