import { VoipProvider } from "../../../contexts/VoipContext"
import { useIsMobile } from "../../../hooks/useIsMobile"
import Menu from "./Menu"
import MobileMenu from "../../../components/home/menu/Menu.mobile"
import { useUser } from "../../../contexts/UserContext"
import { Outlet } from "react-router-dom-v5-compat"

const Client = () => {
    const user = useUser()
    const isMobile = useIsMobile()

    return (
        <VoipProvider>
            <div className={`w-100 h-100 d-flex ${user.isGuest && isMobile ? "flex-column" : ""}`}>
                {isMobile && user.isGuest ? <MobileMenu /> : <Menu />}
                <Outlet />
            </div>
        </VoipProvider>
    )
}

export default Client
