import EnabledDaysOfTheWeek from "./EnabledDaysOfTheWeek"

type Trigger = {
    timeToTrigger: {
        hour: number
        minute: number
        timeZone: string
    }
    onlyTriggerOnSpecificDaysOfWeek: EnabledDaysOfTheWeek
    onlyTriggerOnSpecificDaysOfMonth: number[] | undefined | null
}

export default Trigger

export const defaultTrigger: Trigger = {
    timeToTrigger: {
        hour: 0,
        minute: 0,
        timeZone: "Z"
    },
    onlyTriggerOnSpecificDaysOfWeek: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true
    },
    onlyTriggerOnSpecificDaysOfMonth: undefined
}

export const ruleTriggerIsEmpty = (ruleTrigger: Trigger) =>
    ruleTrigger.timeToTrigger.hour === 0 &&
    ruleTrigger.timeToTrigger.minute === 0 &&
    ruleTrigger.timeToTrigger.timeZone === "Z" &&
    ruleTrigger.onlyTriggerOnSpecificDaysOfWeek.monday === false &&
    ruleTrigger.onlyTriggerOnSpecificDaysOfWeek.tuesday === false &&
    ruleTrigger.onlyTriggerOnSpecificDaysOfWeek.wednesday === false &&
    ruleTrigger.onlyTriggerOnSpecificDaysOfWeek.thursday === false &&
    ruleTrigger.onlyTriggerOnSpecificDaysOfWeek.friday === false &&
    ruleTrigger.onlyTriggerOnSpecificDaysOfWeek.saturday === false &&
    ruleTrigger.onlyTriggerOnSpecificDaysOfWeek.sunday === false
