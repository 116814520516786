import { useReducer, useState } from "react"
import Actions from "./Actions"
import dataConfigurationReducer from "../../reducers/dataConfigurationReducer"
import Loading from "../../../../library/Loading/Loading"
import { actionTypes } from "../../reducers/actionTypes"
import TransactionDataFieldConfigList from "./TransactionDataFieldConfigList"
import CustomerDataFieldConfigList from "./CustomerDataFieldConfigList"
import LookupConfig from "./lookups/LookupConfig"
import { fieldType } from "../../enums/fieldType"
import Tabs from "../../../../library/Tabs/Tabs"
import Tab from "../../../../library/Tabs/Tab"
import useApiQuery from "../../../../hooks/useApiQuery"
import useClient from "../../../../hooks/useClient"
import { useEnvConfig } from "../../../../contexts/EnvironmentConfigContext"

const DataConfiguration = () => {
    const client = useClient()
    const config = useEnvConfig()
    const [selectedLookupId, setSelectedLookupId] = useState()

    const [state, dispatch] = useReducer(dataConfigurationReducer, {
        originalTransactionDataFields: [],
        updatedTransactionDataFields: [],
        newTransactionDataFields: [],
        originalCustomerDataFields: [],
        updatedCustomerDataFields: [],
        newCustomerDataFields: [],
        originalLookupDataFields: [],
        updatedLookupDataFields: [],
        activeTab: fieldType.CUSTOMER,
        currentLookupEntryPage: 0
    })

    const getLookupsRequest = useApiQuery({
        url: `${config.DATA_API_URL}/api/${client}/lookups`,
        method: "GET",
        onSuccess: data => {
            dispatch({
                type: actionTypes.LOOKUPS_RETRIEVED,
                lookups: data
            })
        }
    })
    const getDataFieldRequest = useApiQuery({
        url: `${config.DATA_API_URL}/api/${client}/data-field`,
        method: "GET",
        onSuccess: data => {
            dispatch({
                type: actionTypes.DATA_FIELDS_RETRIEVED,
                dataFields: data
            })
        }
    })

    const onCustomerTabSelected = () => dispatch({ type: actionTypes.DATA_TAB_CHANGED, tab: fieldType.CUSTOMER })
    const onTransactionTabSelected = () => dispatch({ type: actionTypes.DATA_TAB_CHANGED, tab: fieldType.TRANSACTION })
    const onLookupTabSelected = () => dispatch({ type: actionTypes.DATA_TAB_CHANGED, tab: fieldType.LOOKUP })
    const onSelectedLookupChanged = lookupId => setSelectedLookupId(lookupId)

    return (
        <div className="d-flex h-100 w-100 pt-4">
            <Loading isLoading={getLookupsRequest.isFetching || getDataFieldRequest.isFetching} colour="blue">
                <Tabs className="flex-grow-1 px-4" contentClassName="overflow-auto">
                    <Tab title="Customer" isActive={true} onClick={onCustomerTabSelected}>
                        <CustomerDataFieldConfigList
                            fields={state.updatedCustomerDataFields}
                            newFields={state.newCustomerDataFields}
                            lookups={state.originalLookupDataFields}
                            dispatch={dispatch}
                        />
                    </Tab>
                    <Tab title="Transaction" onClick={onTransactionTabSelected}>
                        <TransactionDataFieldConfigList
                            fields={state.updatedTransactionDataFields}
                            newFields={state.newTransactionDataFields}
                            lookups={state.originalLookupDataFields}
                            dispatch={dispatch}
                        />
                    </Tab>
                    <Tab title="Lookups" onClick={onLookupTabSelected}>
                        <LookupConfig
                            originalLookups={state.originalLookupDataFields}
                            updatedLookups={state.updatedLookupDataFields}
                            dispatch={dispatch}
                            selectedLookupId={selectedLookupId}
                            onSelectedLookupChanged={onSelectedLookupChanged}
                            currentLookupEntryPage={state.currentLookupEntryPage}
                        />
                    </Tab>
                </Tabs>
                <Actions className="ms-auto" state={state} dispatch={dispatch} selectedLookupId={selectedLookupId} />
            </Loading>
        </div>
    )
}

export default DataConfiguration
