import PerformanceTargetCard from "./PerformanceTargetCard"
import UserCard from "./UserCard"

const UserRow = ({ userMetrics }) => {
    return (
        <div className="d-flex flex-row mb-tiny" key={userMetrics.name}>
            <UserCard username={userMetrics.username} />
            <PerformanceTargetCard performanceValue={userMetrics.totalCalls} metricText="Calls" />
            <PerformanceTargetCard performanceValue={Math.ceil(userMetrics.totalCallDurationInSeconds / 60)} metricText="Minutes" />
        </div>
    )
}

export default UserRow
