import { useInternationalization } from '@progress/kendo-react-intl';
import { useSelector } from 'react-redux';
import { normaliseFormat } from '../../selectors/lookup';
import { typeIds } from './repaymentConstants';

function PlanDescription({
  amount,
  interestRate,
  type,
  term,
  instalments,
  paymentsAmount,
  completionDate,
  currency
}) {
  const int8 = useInternationalization();

  const dateFormat = useSelector((state) =>
    normaliseFormat(state.lookupReducer.globalSettings.results.dateFormat)
  );

  const currencyFormat = useSelector((state) =>
    normaliseFormat(state.lookupReducer.globalSettings.results.currencyFormat)
  );

  const completionDateText = int8.formatDate(completionDate, dateFormat);

  const amountText = int8.formatNumber(amount, currencyFormat);

  let planText = '';
  switch (type) {
    case typeIds.payments:
      const paymentText = int8.formatNumber(paymentsAmount, currencyFormat);
      planText = `with regular payments of ${paymentText}`;
      break;
    case typeIds.instalments:
      planText = `over ${instalments} instalments`;
      break;
    default:
      planText = `over ${term} months`;
      break;
  }

  return (
    <p className={'info-text'}>
      {currency} {amountText} {planText} at {interestRate}%. Plan completion{' '}
      date {completionDateText}
    </p>
  );
}

export default PlanDescription;
