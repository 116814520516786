import React, { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getLookup } from "../../api/lookupApi.js"
import useTaskOwnership from "../../hooks/useTaskOwnership"
import { getFocusedTask, dismissFocusedTask } from "../../actions/taskActions"
import { selectTransactionRefs } from "../../actions/functionActions"
import { executeAuthAsyncPost } from "../../utility/asyncSupport"
import moment from "moment"
import { getTaskDocuments, clearTaskDocuments } from "../../actions/documentActions"
import { showToastSuccessMessage, showToastErrorMessage } from "../../api/toasterApi"

import TaskTypeSelect from "./taskTypeSelect"
import TaskCompletionSelect from "./taskCompletionSelect"
import RolesSelect from "./rolesSelect"
import DatePicker from "react-datepicker"
import Formsy from "formsy-react"
import TaskNotesHistoryGrid from "./taskNotesHistoryGrid"
import ComponentLoader from "../common/ComponentLoader.js"
import PreviousTaskHistory from "./previousTaskHistory"
import { ConfrimDialog } from "../common/confirmDialog"
import useDeepCompareEffect from "use-deep-compare-effect"
import TaskDescriptionSelect from "./taskDescriptionSelect"
import { useClient, useInvevoSession } from "invevo-react-components"
import UsersSelect from "./usersSelect"

import classes from "./customerTask.module.scss"
import { customerDetailsPane } from "../../constants.js"
import { useCustomer } from "../../../../contexts/CustomerContext.js"
import { useSelectedEntities } from "../../../../contexts/SelectedEntitiesContext"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"
import { useEvent } from "../../../../contexts/EventContext"

const defaultFormState = {
    id: 0,
    customerId: "",
    virtualAccountId: "",
    taskTypeId: 0,
    taskDescriptionId: 0,
    taskCompletionId: 0,
    assignedToUserIds: [],
    assignedToRoleIds: [],
    taskDate: new Date(),
    taskNotes: "Create task",
    createdUserName: "",
    originatingTaskId: null,
    originatingTaskIsComplete: false,
    successorTaskId: null,
    successorTaskIsComplete: false,
    customerContact: ""
}

const TaskForm = ({ customerId, isVirtualAccount, documentCount }) => {
    const client = useClient()
    const [refresh, setRefresh] = useState(0)
    const [isNewMode, setIsNewMode] = useState(true)
    const [isClean, setIsClean] = useState(true)
    const [isDisabled, setIsDisabled] = useState(false)
    const [formData, setFormData] = useState(defaultFormState)
    const [lookupDefaultUser, setLookupDefaultUser] = useState(false)
    const [showForceReleaseDialog, setShowForceReleaseDialog] = useState(false)
    const [invevoSession, setInvevoSession] = useInvevoSession()
    const [selectedTransactionRefs, setSelectedTransactionRefs] = useState([])
    const [customer, setCustomer] = useCustomer()
    const { selectedRefs } = useSelectedEntities()
    const dispatch = useDispatch()
    const { isEnabled: newEntityStructure } = useFeatureToggle("newEntityStructure")

    const task = useSelector(state => state.taskReducer.selectedTask)

    const { isTaskOwned, forceTaskOwnershipRelease } = useTaskOwnership(client, task.id, task.ownedByUserId)

    const taskDescriptionsLookup = useSelector(state => state.lookupReducer.taskDescriptions || {})

    const transactionsAggregate = useSelector(state => state.functionReducer.transactionsAggregate)

    useEffect(() => {
        if (isNewMode)
            if (newEntityStructure && selectedRefs["transaction"] && selectedRefs["transaction"].length > 0) {
                setSelectedTransactionRefs(selectedRefs["transaction"] ?? [])
            } else {
                setSelectedTransactionRefs(customer.selectedTransactions.map(t => t.transactionRef) || [])
            }
    }, [customer.selectedTransactions, newEntityStructure, isNewMode, selectedRefs])

    useDeepCompareEffect(() => {
        // loading already...
        const { isLoading, isNew } = task
        if (!isNew && isLoading) return

        // setup on task loaded
        setIsClean(true)

        if (task.isNew) {
            setIsNewMode(true)
            setFormData(defaultFormState)
            setLookupDefaultUser(true)

            dispatch(clearTaskDocuments())
        } else {
            setIsNewMode(false)
            setLookupDefaultUser(false)

            const {
                id,
                customerId,
                virtualAccountId,
                taskTypeId,
                taskDescriptionId,
                taskCompletionId,
                assignedToUserIds,
                assignedToRoleIds,
                taskDate,
                createdUserName,
                originatingTaskId,
                originatingTaskIsComplete,
                customerContact,
                successorTaskIsComplete,
                transactionRefs
            } = task

            setSelectedTransactionRefs(transactionRefs)

            dispatch(selectTransactionRefs(transactionRefs))

            setCustomer({
                transactionFilters: transactionRefs.map(ref => {
                    return { type: "UNIQUE_TRANSACTION_REFERENCE", label: ref.toLowerCase() }
                })
            })

            setFormData({
                id,
                customerId,
                virtualAccountId,
                taskTypeId,
                taskDescriptionId,
                taskCompletionId,
                assignedToUserIds,
                assignedToRoleIds,
                taskDate: new Date(taskDate),
                taskNotes: "Update Task",
                createdUserName,
                originatingTaskId,
                originatingTaskIsComplete,
                customerContact,
                successorTaskIsComplete
            })

            dispatch(getTaskDocuments(id))
        }
    }, [task, dispatch])

    function onChangeTaskTypeId(taskTypeId) {
        setFormData({ ...formData, taskTypeId, taskDescriptionId: 0 })
        dispatch(
            getLookup(client, "lookup/TaskDescriptionsByTaskType", "taskDescriptions", {
                taskTypeId
            })
        )
    }

    const { taskTypeId, taskDescriptionId } = formData
    useDeepCompareEffect(() => {
        if (
            taskTypeId &&
            !taskDescriptionId &&
            !taskDescriptionsLookup.isFetching &&
            taskDescriptionsLookup.results &&
            taskDescriptionsLookup.results.length === 1
        ) {
            setFormData(prevState => ({
                ...prevState,
                taskDescriptionId: taskDescriptionsLookup.results[0].Id
            }))
        }
    }, [taskTypeId, taskDescriptionId, taskDescriptionsLookup])

    const multipleDescriptions = formData.taskTypeId !== 0 && taskDescriptionsLookup && taskDescriptionsLookup.results.length > 1

    const [isValid, setIsValid] = useState(false)
    useEffect(() => {
        const { taskTypeId, taskDescriptionId, taskDate, assignedToRoleIds, assignedToUserIds } = formData

        const formIsValid = taskTypeId > 0 && taskDescriptionId > 0 && assignedToRoleIds.length + assignedToUserIds.length > 0 && taskDate !== null

        let hasSingleCurrency = true
        if (transactionsAggregate && transactionsAggregate.Count > 0) {
            hasSingleCurrency = transactionsAggregate.CurrencyCodes && transactionsAggregate.CurrencyCodes.length === 1
        }

        setIsValid(formIsValid && hasSingleCurrency)
    }, [formData, transactionsAggregate])

    const { triggerEvent } = useEvent()

    const navToTransactions = () => {
        if (isNewMode) {
            setInvevoSession({ selectedCustomerTab: "transactions" })
        } else {
            setInvevoSession({ selectedCustomerTab: "transactions" })

            newEntityStructure &&
                triggerEvent("select_transactions", { selection: selectedTransactionRefs.map(ref => ref.toLowerCase()), filterToSelected: false })
            setCustomer({
                transactionFilters: selectedTransactionRefs.map(ref => {
                    return { type: "UNIQUE_TRANSACTION_REFERENCE", label: ref.toLowerCase() }
                })
            })
        }
    }

    const onTaskDateChange = taskDate => {
        setFormData({
            ...formData,
            taskDate
        })
        setIsClean(false)
    }

    const onChange = ({ target: { name, value } }) => {
        setFormData({ ...formData, [name]: value })
        setIsClean(false)
    }

    const setTaskDate = date => () => onTaskDateChange(date)

    const getTransactionCount = () => {
        let len = selectedTransactionRefs.length

        return len > 0 && `(${len})`
    }

    const onReloadTask = () => dispatch(getFocusedTask(formData.id, !!formData.taskCompletionId, client))

    const onRemoveOwner = () => {
        onReloadTask()
        setShowForceReleaseDialog(true)
    }

    const reloadList = () => {
        dispatch(dismissFocusedTask())
        setInvevoSession({ reloadList: invevoSession.reloadList ? invevoSession.reloadList + 1 : 0 })
    }

    const onSubmitForm = () => {
        setIsDisabled(true)

        const saveTask = {
            ...formData,
            taskDate: moment(formData.taskDate).local().format("YYYY-MM-DD")
        }

        if (isVirtualAccount) {
            saveTask.customerId = ""
            saveTask.virtualAccountId = customerId
        } else {
            saveTask.virtualAccountId = ""
            saveTask.customerId = customerId
            saveTask.transactionRefs = selectedTransactionRefs.slice()
        }

        dispatch(
            executeAuthAsyncPost(
                client,
                isNewMode ? "task/newcreate" : "task/newupdate",
                "CREATE_TASK",
                saveTask,
                task => {
                    setIsDisabled(false)
                    if (task.taskCompletionId) {
                        dispatch(getFocusedTask(task.id, true, client))
                        showToastSuccessMessage(`Task #${task.id} completed`)
                    } else {
                        reloadList()
                        showToastSuccessMessage(
                            <>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a onClick={() => dispatch(getFocusedTask(task.id, !!task.taskCompletionId, client))}>Task #{task.id} saved</a>
                            </>
                        )
                    }

                    setRefresh(refresh + 1)
                },
                ({ message } = {}) => {
                    setIsDisabled(false)
                    showToastErrorMessage(message || "Error while saving task")
                }
            )
        )
    }

    const onCloseDialog = () => {
        setShowForceReleaseDialog(false)
    }

    const onConfirmDialog = () => {
        forceTaskOwnershipRelease()
        onCloseDialog()
    }

    const navToDocuments = () => {
        setInvevoSession({ selectedCustomerTab: customerDetailsPane.files, showAddDoc: false, showTaskFiles: true })
    }

    const navToAddDocuments = () => {
        setInvevoSession({ selectedCustomerTab: customerDetailsPane.files, showAddDoc: true, showTaskFiles: false })
    }

    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const tomorrow = new Date(today).setDate(today.getDate() + 1)

    const formRef = useRef()
    return (
        <div className="col-12 top10">
            <Formsy key="add-task-form" ref={formRef}>
                <div className="alert alert-danger text-align-centre" role="alert" hidden={!isTaskOwned}>
                    Task owned by another user
                    <br />
                    <button className="btn btn-primary" onClick={onReloadTask}>
                        Retry
                    </button>
                    <button className="btn btn-primary" onClick={onRemoveOwner}>
                        Remove Owner
                    </button>
                </div>

                <div className="form-row align-items-center">
                    <div className="col-4">
                        <h3 className="text-white">
                            <i className={`far fa-tasks pe-1`}></i>
                            Task Type
                        </h3>
                    </div>
                    <div className="col-8">
                        <TaskTypeSelect id={formData.taskTypeId} onChange={onChangeTaskTypeId} isDisabled={!task.isNew} />
                    </div>
                </div>

                {multipleDescriptions && (
                    <div className="form-row align-items-center mt-2">
                        <div className="col-4">
                            <h3 className="text-white">Description</h3>
                        </div>
                        <div className="col-8">
                            <TaskDescriptionSelect
                                id={formData.taskDescriptionId}
                                taskDescriptions={taskDescriptionsLookup.results}
                                onChange={taskDescriptionId => setFormData({ ...formData, taskDescriptionId })}
                                isDisabled={!task.isNew}
                            />
                        </div>
                    </div>
                )}

                <hr />

                {formData.createdUserName && (
                    <>
                        <div className="form-group">
                            <span className="nav">Created by: {formData.createdUserName}</span>
                        </div>

                        <hr />
                    </>
                )}

                <div className="form-group d-flex align-items-center">
                    <i className={`far fa-clock mr-2 pe-2`}></i>

                    <button className={`btn ${classes["btn-custom"]}`} onClick={setTaskDate(today)}>
                        Today
                    </button>

                    <button className={`btn ${classes["btn-custom"]}`} onClick={setTaskDate(tomorrow)}>
                        Tomorrow
                    </button>

                    <label style={{ width: "120px" }}>
                        <DatePicker
                            name="taskDate"
                            className="form-control"
                            dateFormat="dd MMMM yyyy"
                            selected={formData.taskDate}
                            placeholderText="CHOOSE"
                            popperPlacement="bottom-end"
                            onChange={onTaskDateChange}
                        />
                        <i className={`far fa-calendar-alt right-icon`}></i>
                    </label>
                </div>

                <hr />

                <h3 className="text-white">
                    <i className={`far fa-users pe-1`}></i>
                    Assign
                </h3>

                <div className="form-group">
                    <RolesSelect
                        ids={formData.assignedToRoleIds}
                        customerId={customerId}
                        isVirtualAccount={isVirtualAccount}
                        onChange={assignedToRoleIds =>
                            setFormData(prevFormData => ({
                                ...prevFormData,
                                assignedToRoleIds,
                                assignedToUserIds: []
                            }))
                        }
                    />
                </div>

                <div className="form-group">
                    <UsersSelect
                        ids={formData.assignedToUserIds}
                        customerId={customerId}
                        isVirtualAccount={isVirtualAccount}
                        onChange={assignedToUserIds =>
                            setFormData(prevFormData => ({
                                ...prevFormData,
                                assignedToUserIds,
                                assignedToRoleIds: []
                            }))
                        }
                        roleIds={formData.assignedToRoleIds}
                        lookupDefault={lookupDefaultUser}
                    />
                </div>

                <hr />

                <div className="form-group">
                    {!isVirtualAccount && (
                        <button className={`btn ${classes["btn-custom"]}`} type="button" aria-label="linked-items" onClick={navToTransactions}>
                            {isNewMode ? "Add" : "Linked"} Items {getTransactionCount()}
                        </button>
                    )}

                    {documentCount > 0 && (
                        <button className={`btn ${classes["btn-custom"]}`} type="button" onClick={navToDocuments} disabled={isNewMode}>
                            Linked Docs ({documentCount})
                        </button>
                    )}

                    {!isNewMode && (
                        <button className={`btn ${classes["btn-custom"]}`} type="button" onClick={navToAddDocuments}>
                            Add Doc
                        </button>
                    )}
                </div>
                <hr />

                {!!formData.id && (
                    <>
                        <div className="form-group">
                            <h3 className="text-white">Task Completion</h3>

                            <TaskCompletionSelect
                                id={formData.taskCompletionId}
                                onChange={taskCompletionId => setFormData({ ...formData, taskCompletionId })}
                                taskTypeId={formData.taskTypeId}
                            />
                        </div>
                        <hr />
                    </>
                )}

                <h3 className="text-white">
                    <i className={`far fa-comment-medical pe-1`}></i>
                    Add Comment
                </h3>
                <div className="form-group">
                    <textarea
                        rows="2"
                        name="taskNotes"
                        style={{ width: "100%" }}
                        value={formData.taskNotes}
                        placeholder="WRITE A COMMENT"
                        onChange={onChange}
                        maxLength="4000"
                    />
                </div>

                {!isNewMode && (
                    <>
                        <h3 className="text-white">Note History</h3>
                        <TaskNotesHistoryGrid client={client} dataItem={{ Id: formData.id }} refresh={refresh} />
                        <hr />
                    </>
                )}

                <div className="form-group row">
                    <div className="col-6">
                        {!isNewMode && (
                            <button type="button" onClick={onReloadTask} className={`btn ${classes["btn-custom"]}`} disabled={isClean || isDisabled}>
                                Revert
                            </button>
                        )}
                    </div>
                    <div className="col-6 text-end">
                        <button type="button" onClick={onSubmitForm} disabled={isTaskOwned || isDisabled || !isValid} className="btn btn-primary">
                            {isNewMode ? "Create Task" : "Save Task"}
                        </button>
                    </div>
                </div>
            </Formsy>

            <ConfrimDialog
                show={showForceReleaseDialog}
                message={
                    <>
                        <p>Warning: The other user could lose their work!</p>
                        <p>Are you sure you want to force unlock on this task?</p>
                    </>
                }
                onConfirm={onConfirmDialog}
                onClose={onCloseDialog}
            />

            {!isNewMode && formData.originatingTaskId && (
                <>
                    <hr />
                    <PreviousTaskHistory taskId={formData.originatingTaskId} client={client} />
                </>
            )}

            {task.isLoading && <ComponentLoader compoRef={formRef} />}
        </div>
    )
}

export default TaskForm
