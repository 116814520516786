import { useState } from "react"
import Dropdown from "../../../../../library/dropdowns/Dropdown"
import FilterList from "../../../../../library/FilterList/FilterList"
import GenericFilter from "../../../../../library/FilterList/filterTypes/GenericFilter"
import NumberInput from "../../../../../library/Inputs/NumberInput"
import DataField from "../../../../../types/DataField"
import DropdownOption from "../../../../../types/DropdownOptions"
import Lookup from "../../../../../types/Lookup"
import AsyncCommAction from "../../../types/actions/AsyncCommAction"
import LegacySmsTemplate from "../../../types/LegacySmsTemplate"
import classes from "./AsyncCommActionComponent.module.scss"
import Filter from "../../../../../library/FilterList/Filter"

type ContactSelectionOption = "ANY" | "ALL" | "ANY_MATCHING" | "ALL_MATCHING"

const getDefaultContactSelectionOption = (action: AsyncCommAction): ContactSelectionOption => {
    if (action.contactFilters.length === 0) {
        return action.contactSelectionType === "ALL_MATCHING" ? "ALL" : "ANY"
    } else {
        return action.contactSelectionType === "ALL_MATCHING" ? "ALL_MATCHING" : "ANY_MATCHING"
    }
}

const contactSelectionOptions: { label: string; value: ContactSelectionOption }[] = [
    { label: "Any contact", value: "ANY" },
    { label: "All contacts", value: "ALL" },
    { label: "Any contact matching filters", value: "ANY_MATCHING" },
    { label: "All contacts matching filters", value: "ALL_MATCHING" }
]

type AsyncCommActionProps = {
    action: AsyncCommAction
    onActionUpdated: (action: AsyncCommAction) => void
    onActionRemoved: (action: AsyncCommAction) => void
    smsTemplates: LegacySmsTemplate[]
    customerFields: DataField[]
    lookups: Lookup[]
    isShowFilters?: boolean
    isTextOnly?: boolean
}

const AsyncCommActionComponent = ({
    action,
    onActionUpdated,
    onActionRemoved,
    smsTemplates,
    customerFields,
    lookups,
    isShowFilters = true,
    isTextOnly = false
}: AsyncCommActionProps) => {
    const [contactSelectionOption, setContactSelectionOption] = useState<ContactSelectionOption>(getDefaultContactSelectionOption(action))

    const selectedTemplate = smsTemplates.find(t => t.reference === action.communicationTemplateReference)

    const onTemplateSelected = (template: DropdownOption<string>) => onActionUpdated({ ...action, communicationTemplateReference: template.value })

    const onMaxActionsTotalChange = (value: string | undefined) => {
        if (action.rateLimiter === undefined) return

        onActionUpdated({ ...action, rateLimiter: { ...action.rateLimiter, maxActionsTotal: parseInt(value ?? "0") } })
    }

    const onMaxPerMinuteChange = (value: string | undefined) => {
        if (action.rateLimiter === undefined) return

        onActionUpdated({
            ...action,
            rateLimiter: { ...action.rateLimiter, maxActionsPerMinute: parseInt(value ?? "0") }
        })
    }

    const onContactFiltersUpdated = (filters: GenericFilter[]) =>
        onActionUpdated({
            ...action,
            contactFilters: filters
        })

    const onCustomerFiltersUpdated = (filters: GenericFilter[]) =>
        onActionUpdated({
            ...action,
            filters
        })

    const onContactSelectionOptionUpdated = (option: DropdownOption<ContactSelectionOption>) => setContactSelectionOption(option.value)

    if (isTextOnly) {
        return (
            <div className="d-flex flex-column">
                <span>
                    Using template:{" "}
                    <span className={`fw-bold ${!selectedTemplate && "text-danger"}`}>{selectedTemplate ? selectedTemplate.name : "(none selected)"}</span>
                </span>
                <span>
                    Sending up to <span className="fw-bold">{action.rateLimiter?.maxActionsTotal?.toString()}</span> messages
                </span>
                <span>
                    Limit to <span className="fw-bold">{action.rateLimiter?.maxActionsPerMinute?.toString()}</span> messages per minute
                </span>
                <span>Sending to contacts matching filters:</span>
                {action.filters.map(filter => (
                    <Filter
                        key={filter.id}
                        filter={filter}
                        fields={[]}
                        lookups={lookups}
                        disabled={true}
                        onFilterUpdated={() => {}}
                        onFilterDeleted={() => {}}
                        showFieldType={false}
                        textOnly={isTextOnly}
                    />
                ))}
            </div>
        )
    }

    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <span className="text-grey me-2 text-uppercase">template</span>
                <Dropdown
                    className="me-2"
                    selectedOption={selectedTemplate ? { label: selectedTemplate.name, value: selectedTemplate.reference } : undefined}
                    options={smsTemplates.map(t => ({ label: t.name, value: t.reference }))}
                    onOptionSelected={onTemplateSelected}
                    fixedSize={true}
                    textAlign="left"
                />
            </div>
            <div className="d-flex align-items-center mb-3">
                <span className="text-grey me-2 text-uppercase">send up to</span>
                <NumberInput className="me-2" value={action.rateLimiter?.maxActionsTotal?.toString()} onChange={onMaxActionsTotalChange} />
                <span className="text-grey me-2 text-uppercase">messages, limit to</span>
                <NumberInput className="me-2" value={action.rateLimiter?.maxActionsPerMinute?.toString()} onChange={onMaxPerMinuteChange} />
                <span className="text-grey me-3 text-uppercase">message(s) per minute</span>
            </div>
            <div className={`mt-3 p-3 ${classes.container}`}>
                <div className="d-flex align-items-center">
                    <span className="fs-5 me-4">Contact Filters</span>
                    <span className="text-grey me-1">send to</span>
                    <Dropdown
                        className="me-auto"
                        options={contactSelectionOptions}
                        selectedOption={contactSelectionOptions.find(o => o.value === contactSelectionOption)}
                        onOptionSelected={onContactSelectionOptionUpdated}
                        textAlign="left"
                    />
                </div>
                {(contactSelectionOption === "ALL_MATCHING" || contactSelectionOption === "ANY_MATCHING") && (
                    <div className="mt-2">
                        <FilterList
                            key={action.actionReference}
                            fields={customerFields}
                            lookups={lookups}
                            appliedFilters={action.contactFilters}
                            onFiltersApplied={onContactFiltersUpdated}
                            autoApply={true}
                        />
                    </div>
                )}
            </div>
            {isShowFilters && (
                <div className={`mt-3 p-3 ${classes.container}`}>
                    <span className="fs-5">Customer Filters</span>
                    <div className="mt-2">
                        <FilterList
                            key={action.actionReference}
                            fields={customerFields}
                            lookups={lookups}
                            appliedFilters={action.filters}
                            onFiltersApplied={onCustomerFiltersUpdated}
                            autoApply={true}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default AsyncCommActionComponent
