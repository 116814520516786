import { useClient, useInvevoSession } from "invevo-react-components"
import React, { useEffect, useState } from 'react';
import TemplateSelector from './templateSelector';
import PostPreview from './postPreview';
import PostSuccess from './postSuccess';
import PostSendButtons from './postSendButtons';
import PostContentSelectionButtons from './postContentSelectionButtons';
import PersonalisedContentForm from './personalisedContentForm';
import classes from './Post.module.scss'
import { useCustomer } from "../../../../contexts/CustomerContext";

const PostIndex = () => {
  const [postTemplate, setPostTemplate] = useState({
    customerId: '',
    templateId: '',
    hasAttachedDocuments: false,
    hasAttachedTransactions: false,
    hasPersonalisedContent: false,
    isVirtualAccount: false
  });

  const client = useClient()

  const [isShowingSuccess, setIsShowingSuccess] = useState(false)
  const [invevoSession,] = useInvevoSession();
  const [contacts, setContacts] = useState([])
  const [transactions, setTransactions] = useState([])
  const [documents, setDocuemnts] = useState([])
  const [customer] = useCustomer()

  useEffect(() => {
    setContacts(
      (invevoSession.selectedCustomerContacts || []).concat(invevoSession.selectedInternalContacts || [])
    );
  }, [invevoSession.selectedCustomerContacts, invevoSession.selectedInternalContacts]);

  useEffect(() => {
    setTransactions(customer.selectedTransactions.map(t => t.transactionRef) || [])
  }, [customer.selectedTransactions]);

  useEffect(() => {
    setDocuemnts(
      (invevoSession.selectedDocuments || []).map((o) => o.id))
  }, [invevoSession.selectedDocuments]);

  const [personalisedContent, setPersonalisedContent] = useState('');

  const onPersonalisedContentChange = (value) => {
    setPersonalisedContent(value);
  };

  const onSaveTemplateInfos = (infos) => {
    setPostTemplate({
      customerId: customer.sqlId,
      templateId: infos.id,
      hasAttachedDocuments: infos.hasAttachedDocuments,
      hasAttachedTransactions: infos.hasAttachedTransactions,
      hasPersonalisedContent: infos.hasPersonalisedContent,
      isVirtualAccount: customer.isVirtualAccount
    });
  };

  if (isShowingSuccess) return <div id="post-pane"><PostSuccess /></div>;

  return <div id="post-pane" className={`d-flex flex-grow-1 overflow-auto ${classes.container}`}>
    <div className="top20 flex-column d-flex flex-grow-1">
      <h2 className="container-fluid text-white">Post</h2>
      <div className="d-flex justify-content-between align-items-center container-fluid mb-2">
        <TemplateSelector onSaveTemplateInfos={onSaveTemplateInfos} client={client} />
        <PostSendButtons
          client={client}
          setIsShowingSuccess={setIsShowingSuccess}
          postTemplate={postTemplate}
          contacts={contacts}
          embeddedTransactionIds={transactions}
          documents={documents}
          personalisedContent={personalisedContent}
        />
      </div>
      <PostContentSelectionButtons
        contacts={contacts}
        transactions={transactions}
        documents={documents}
      />
      {postTemplate.hasPersonalisedContent && (
        <div className="container-fluid mt-3">
          <PersonalisedContentForm
            onPersonalisedContentChange={onPersonalisedContentChange}
          />
        </div>
      )}
      <hr />
      <PostPreview
        client={client}
        postTemplate={postTemplate}
        contacts={contacts}
        embeddedTransactionIds={transactions}
        documents={documents}
        personalisedContent={personalisedContent}
      />
    </div>
  </div>
};

export default PostIndex;
