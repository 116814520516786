import React from 'react';
import { Collapse } from 'react-bootstrap';

const AccountCard = ({ title, children, open = null, setOpen }) => {
  const isCollapsible = open !== null;

  const toggleOpen = () => setOpen(!open);

  return (
    <div className="card bg-highlight">
      <div className="card-body p-1">
        <h5
          onClick={isCollapsible ? toggleOpen : null}
          className="card-title shout m-1 mb-2 d-flex justify-content-between font-weight-bold text-muted pointer"
        >
          {title}
          {isCollapsible && (
            <i className={`fa-regular ${open ? 'fa-minus' : 'fa-plus'} ms-1`}></i>
          )}
        </h5>
        <Collapse in={isCollapsible ? open : true}>
          <div className="container-fluid">{children}</div>
        </Collapse>
      </div>
    </div>
  );
};

export default AccountCard;
