import moment from "moment"
import DataFieldDropdown from "../../../../../library/dropdowns/DataFieldDropdown"
import Dropdown from "../../../../../library/dropdowns/Dropdown"
import DatePickerWithInput from "../../../../../library/Inputs/DatePickerWithInput"
import Input from "../../../../../library/Inputs/Input"
import DataField from "../../../../../types/DataField"
import DataPrimitive from "../../../../../types/DataPrimitive"
import DropdownOption from "../../../../../types/DropdownOptions"
import Lookup from "../../../../../types/Lookup"
import { isValidNumber } from "../../../../../library/helpers"

type UpdateDataFieldDefinitionProps = {
    customerFields: DataField[]
    lookups: Lookup[]
    id: string
    selectedField: DataField | undefined
    selectedValue: string
    selectedUpdateActionType: "set" | "increment"
    onDataFieldDefinitionUpdated: (id: string, field: DataField | undefined, value: string, updateActionType: "set" | "increment") => void
    onDataFieldDefinitionRemoved: (id: string) => void
    isTextOnly?: boolean
}

const UpdateDataFieldDefinition = ({
    customerFields,
    lookups,
    id,
    selectedField,
    selectedValue,
    selectedUpdateActionType,
    onDataFieldDefinitionUpdated,
    onDataFieldDefinitionRemoved,
    isTextOnly = false
}: UpdateDataFieldDefinitionProps) => {
    const onFieldSelected = (field: DataField | undefined) => {
        const updatedSelectedUpdateActionType = field?.type === DataPrimitive.NUMBER ? selectedUpdateActionType : "set"

        if (field && field.type === DataPrimitive.DATE) {
            onDataFieldDefinitionUpdated(id, field, moment(new Date()).format(), updatedSelectedUpdateActionType)
            return
        }
        onDataFieldDefinitionUpdated(id, field, "", updatedSelectedUpdateActionType)
    }

    const onUpdateActionTypeDropDownChanged = (option: DropdownOption<"set" | "increment">) => {
        onDataFieldDefinitionUpdated(id, selectedField, selectedValue, option.value)
    }

    const onRemoveClicked = () => onDataFieldDefinitionRemoved(id)

    const onDropdownValueChanged = (option: DropdownOption<string>) => onDataFieldDefinitionUpdated(id, selectedField, option.value, selectedUpdateActionType)
    const onInputValueChanged = (value: string) => onDataFieldDefinitionUpdated(id, selectedField, value, selectedUpdateActionType)
    const onDataValueChanged = (date: Date) => onDataFieldDefinitionUpdated(id, selectedField, moment(date).format(), selectedUpdateActionType)

    const getValueComponent = (selectedField: DataField) => {
        if (selectedField.type === DataPrimitive.LOOKUP) {
            const lookupEntries = lookups.find(lookup => lookup.reference === selectedField.lookup)?.entries ?? []
            const options = lookupEntries.map(lookup => ({
                label: lookup.name,
                value: lookup.reference
            }))
            return (
                <Dropdown
                    options={options}
                    onOptionSelected={onDropdownValueChanged}
                    selectedOption={options.find(o => o.value === selectedValue)}
                    fixedSize={true}
                />
            )
        }

        if (selectedField.type === DataPrimitive.BOOLEAN) {
            const options = [
                { label: "True", value: "true" },
                { label: "False", value: "false" }
            ]
            return (
                <Dropdown
                    options={options}
                    onOptionSelected={onDropdownValueChanged}
                    selectedOption={options.find(o => o.value === selectedValue)}
                    fixedSize={true}
                />
            )
        }

        if (selectedField.type === DataPrimitive.DATE) {
            const initialValue = selectedValue === "" ? new Date() : new Date(selectedValue)
            return <DatePickerWithInput date={initialValue} onDateChange={onDataValueChanged} />
        }

        return <Input value={selectedValue} onChange={onInputValueChanged} placeholder="Please enter value" />
    }

    const dropDownOptions: DropdownOption<"set" | "increment">[] = [
        {
            label: "Set",
            value: "set"
        },
        {
            label: "Increment",
            value: "increment"
        }
    ]

    if (isTextOnly) {
        return (
            <div className="d-flex gap-1">
                <span className="text-capitalize">{selectedUpdateActionType}</span>{" "}
                <span className={`fw-bold ${selectedField || "fw-danger"}`}>{selectedField?.label || "(not set)"}</span>
                {selectedField && (
                    <>
                        {selectedUpdateActionType === "set" ? "to" : "by"}{" "}
                        <span className={`fw-bold ${selectedValue || "fw-danger"}`}>{selectedValue || "(not set)"}</span>
                    </>
                )}
            </div>
        )
    }

    return (
        <div className="d-flex align-items-between gap-2">
            <div className="d-flex gap-2 align-items-center me-auto">
                <span className="text-grey text-uppercase">
                    <Dropdown
                        options={dropDownOptions}
                        onOptionSelected={onUpdateActionTypeDropDownChanged}
                        selectedOption={dropDownOptions.find(v => v.value === selectedUpdateActionType)}
                        fixedSize={true}
                        textAlign="left"
                        disabled={selectedField === undefined || selectedField.type !== DataPrimitive.NUMBER}
                    />
                </span>
                <DataFieldDropdown options={customerFields} onOptionSelected={onFieldSelected} selectedOption={selectedField} />
                {selectedField && (
                    <>
                        <span className="text-grey text-uppercase">{selectedUpdateActionType === "set" ? "to" : "by"}</span>
                        {getValueComponent(selectedField)}
                        {!isValidNumber(selectedValue) && selectedUpdateActionType === "increment" && (
                            <div className="d-flex align-items-center text-danger no-select">
                                <i className="fal fa-exclamation-triangle me-2"></i>
                                <span>Please specify a valid number</span>
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="d-flex gap-2 align-items-center">
                <div className="ms-auto ps-2 me-3 border-end align-self-stretch" />
                <div role="button" className="px-2" onClick={onRemoveClicked} aria-label="filter-remove">
                    <i className="fal fa-trash-alt" />
                </div>
            </div>
        </div>
    )
}

export default UpdateDataFieldDefinition
