import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  registerForIntl,
  provideIntlService
} from '@progress/kendo-react-intl';

class CurrencyFormatter extends Component {
  render() {
    const { amount = 0, currencyFormat } = this.props;

    return (
      <span className="money">
        {provideIntlService(this).formatNumber(amount, currencyFormat)}
      </span>
    );
  }
}

const mapStateToProps = state => {
  return {
    currencyFormat: state.lookupReducer.globalSettings.results.currencyFormat
  };
};

registerForIntl(CurrencyFormatter);

export default connect(mapStateToProps)(CurrencyFormatter);
