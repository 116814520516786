import update from 'immutability-helper';
import { each, isEmpty, isNil, map, max, assign, some } from 'lodash';
import { UserConfigViewType, NavigationType } from '../constants';
import { getRandomString } from '../utility/random';
import {
  PanelTypeId,
  SourceType,
  subItemsConstants
} from '../components/legacy_dashboard/dashboardConstants';

/* eslint-disable */

const buildPanelDescriptor = (
  panelTypeId,
  description,
  visibility,
  hasGraphics,
  subMenu
) => ({
  panelTypeId,
  description,
  visibility,
  hasGraphics,
  subMenu
});
const buildSubMenuItemDescriptor = (id, description, visibility) => ({
  id,
  description,
  visibility
});
const buildPanelForList = (
  id,
  panelTypeId,
  title,
  isMaximised,
  isSelected,
  displayGraphics,
  options = [],
  sourceData
) => ({
  id,
  panelTypeId,
  title,
  isMaximised,
  isSelected,
  displayGraphics,
  options,
  sourceData
});

export const initialMenuState = () => ({
  dashboardActivated: 0, // 0 == Customer Dashboard, 1 = User Dashboard
  displayMainMenu: -1,
  customer: {
    id: -1,
    name: undefined,
    accountCode: undefined,
    previousCustomerId: -1
  },
  isMainDashboardMin: false,
  isCustomerDashboardMin: false,
  sourceType: -1,
  dataList: {
    data: null,
    errMsg: '',
    isLoading: false
  },
  navigationType: NavigationType.default,
  search: {
    // Used for search and panel navigation criteria.
    criteria: {},
    foundData: undefined,
    advSearchVisible: false,
    quickSearchVisible: false
  },
  showLookupValidation: false,
  functionSourceType: null,
  saveDefaultDashboard: false,
  lookUpLoaded: false,
  doSaveDefaultDashboard: false,
  panelDescriptors: [
    buildPanelDescriptor(PanelTypeId.DIARY_PANEL, 'Diary', true, true, [
      buildSubMenuItemDescriptor(60, 'Today', true),
      buildSubMenuItemDescriptor(61, 'Tomorrow', true),
      buildSubMenuItemDescriptor(62, 'Future', true)
    ]),
    buildPanelDescriptor(
      PanelTypeId.UNREAD_EMAIL,
      'Unread Emails',
      true,
      true,
      []
    ),
    buildPanelDescriptor(
      PanelTypeId.AWAITING_CREDIT_LIMIT_DECISION,
      'Awaiting Credit Limit Decisions',
      true,
      true,
      []
    ),
    buildPanelDescriptor(
      PanelTypeId.NEW_CREDIT_LIMIT_DECISIONS,
      'New Credit Limit Decisions',
      true,
      true,
      [
        buildSubMenuItemDescriptor(
          subItemsConstants.NEW_CREDIT_LIMIT_DECISIONS.APPROVED,
          'Approved',
          true
        ),
        buildSubMenuItemDescriptor(
          subItemsConstants.NEW_CREDIT_LIMIT_DECISIONS.DECLINED,
          'Declined',
          true
        )
      ]
    )
  ],
  // default panel for first time login.
  panelList: [
    buildPanelForList(
      1,
      PanelTypeId.DIARY_PANEL,
      'Diary',
      false,
      false,
      true,
      [
        {
          menuId: PanelTypeId.DIARY_PANEL,
          subMenuId: 60,
          description: 'Today'
        },
        {
          menuId: PanelTypeId.DIARY_PANEL,
          subMenuId: 61,
          description: 'Tomorrow'
        },
        {
          menuId: PanelTypeId.DIARY_PANEL,
          subMenuId: 62,
          description: 'Future'
        }
      ],
      ['load']
    )
  ],
  panelDescriptorsCD: [
    buildPanelDescriptor(
      PanelTypeId.CUSTOMER_DETAILS_PANEL,
      'Customer Details',
      false,
      false,
      [
        buildSubMenuItemDescriptor(1011, 'Account Name', false),
        buildSubMenuItemDescriptor(1012, 'Account Code', false)
      ]
    ),
    buildPanelDescriptor(
      PanelTypeId.CUSTOMER_AGED_DEBT_PANEL,
      'Aged Debt',
      true,
      true,
      [
        buildSubMenuItemDescriptor(40, 'Current', true),
        buildSubMenuItemDescriptor(41, '0-30', true),
        buildSubMenuItemDescriptor(42, '31-60', true),
        buildSubMenuItemDescriptor(43, '61-90', true),
        buildSubMenuItemDescriptor(44, '90+', true),
        buildSubMenuItemDescriptor(45, 'Balance', true),
        buildSubMenuItemDescriptor(46, 'Overdue', true)
      ]
    ),
    buildPanelDescriptor(
      PanelTypeId.CUSTOMER_FALLING_DUE_BY_MONTH_PANEL,
      'Falling Due for Payment',
      true,
      true,
      []
    ),
    buildPanelDescriptor(
      PanelTypeId.CUSTOMER_RECENT_PAYMENTS_PANEL,
      'Recent Payments',
      true,
      true,
      []
    ),
    buildPanelDescriptor(
      PanelTypeId.CUSTOMER_TRANSACTION_STATUS_PANEL,
      'Transaction Status',
      true,
      true,
      []
    ),
    buildPanelDescriptor(
      PanelTypeId.CUSTOMER_TRANSACTION_TYPE_PANEL,
      'Transaction Type',
      true,
      true,
      []
    )
  ],
  // default panel for first time login.
  panelListCD: [
    buildPanelForList(
      1,
      PanelTypeId.CUSTOMER_DETAILS_PANEL,
      'Customer Details',
      false,
      false,
      false,
      [
        {
          menuId: PanelTypeId.CUSTOMER_DETAILS_PANEL,
          subMenuId: 1011,
          description: 'Account Name'
        },
        {
          menuId: PanelTypeId.CUSTOMER_DETAILS_PANEL,
          subMenuId: 1012,
          description: 'Account Code'
        }
      ],
      {}
    )
  ]
});

const clearSelected = (t) => ({ ...t, isSelected: false });
const maxPanel = (idPanel, state) => {
  const mainDashboardActivated = state.dashboardActivated === 0;
  const pl = mainDashboardActivated ? state.panelList : state.panelListCD;
  const panelIndex = pl.findIndex((p) => p.id === idPanel);
  const menuItem = pl[panelIndex].panelTypeId;
  const p = pl.map(clearSelected);
  const stateU = mainDashboardActivated
    ? update(state, { panelList: { $set: p } })
    : update(state, { panelListCD: { $set: p } });
  return mainDashboardActivated
    ? update(stateU, {
      panelList: {
        [panelIndex]: {
          isMaximised: { $set: true },
          isSelected: { $set: true }
        }
      },
      displayMainMenu: { $set: menuItem }
    })
    : update(stateU, {
      panelListCD: {
        [panelIndex]: {
          isMaximised: { $set: true },
          isSelected: { $set: true }
        }
      },
      displayMainMenu: { $set: menuItem }
    });
};
// DT Attempt to replay state transitions in order to restore previous configuration for "glimpse"
const restoreNavState = (state, action) => {
  let newState = minimisePanel(state, action); // default minimise panel behaviour.
  const functionSelected = !isNil(state.functionSourceType);

  if (
    functionSelected ||
    (!isEmpty(state.activityList.criteria) &&
      !state.isMainDashboardMin &&
      !state.activityList.isPersistedList)
  ) {
    // User had previously generated an activity list

    newState = update(newState, {
      navigationType: { $set: state.activityList.navType },
      search: {
        criteria: { $set: state.activityList.criteria },
        foundData: { $set: true }
      }
    });
    if (state.customer.previousCustomerId !== -1 && functionSelected) {
      // User had previously selected a customer
      newState = selectCustomer(newState, {
        customerId: state.customer.previousCustomerId,
        virtualCustomerId: null
      });
      if (functionSelected) {
        // User had previously selected a customer function
        return selectGridFunction(newState, {
          sourceType: state.functionSourceType
        });
      }
    }
  }

  if (state.customer.previousCustomerId !== -1) {
    newState = update(newState, {
      dashboardActivated: { $set: 1 }, // Force dashboard into customer level
      isMainDashboardMin: { $set: true },
      customer: {
        id: { $set: state.customer.previousCustomerId } // Restore customer id
      }
    });
  }
  return newState;
};

const minimisePanel = (state, action) => {
  const mainDashboardActivated = state.dashboardActivated === 0;
  const pl = mainDashboardActivated ? state.panelList : state.panelListCD;
  const panelIndex = pl.findIndex((p) => p.id === action.idPanelItem);
  return mainDashboardActivated
    ? update(state, {
      panelList: {
        [panelIndex]: {
          isMaximised: { $set: false }
        }
      },
      displayMainMenu: { $set: -1 }
    })
    : update(state, {
      panelListCD: {
        [panelIndex]: {
          isMaximised: { $set: false }
        }
      },
      displayMainMenu: { $set: -1 }
    });
};

const selectCustomer = (state, action) => {
  const newState = update(state, {
    dashboardActivated: { $set: 2 },
    isMainDashboardMin: { $set: true },
    isCustomerDashboardMin: { $set: true },
    customer: {
      id: {
        $set:
          action.customerId != '' ? action.customerId : action.virtualAccountId
      },
      previousCustomerId: { $set: action.customerId },
      isVirtualParent: {
        $set: action.virtualAccountId != null && action.virtualAccountId != ''
      }
    },
    displayMainMenu: { $set: -1 },
    navigationType: { $set: NavigationType.select_customer },
    sourceType: { $set: SourceType.SUMMARY },
    functionSourceType: { $set: SourceType.SUMMARY }
  });
  return newState;
};
const selectGridFunction = (state, action) => {
  return update(state, {
    dashboardActivated: { $set: 2 },
    isMainDashboardMin: { $set: true },
    isCustomerDashboardMin: { $set: true },
    sourceType: { $set: action.sourceType },
    functionSourceType: { $set: action.sourceType },
    gridFunctionContentKey: { $set: getRandomString() }
  });
};
const selectGridFunctionHome = (state, action) => {
  return update(state, {
    dashboardActivated: { $set: 1 },
    isMainDashboardMin: { $set: true },
    isCustomerDashboardMin: { $set: false },
    sourceType: { $set: action.sourceType },
    gridFunctionContentKey: { $set: getRandomString() }
  });
};
export function initState() {
  return initialMenuState();
}
const dashboardReducer = (state = initState(), action) => {
  switch (action.type) {
    case 'MINIMISE_PANEL':
      return minimisePanel(state, action);
    case 'NAVIGATE_HOME':
      return update(state, {
        customer: {
          id: { $set: -1 },
          previousCustomerId: { $set: -1 }
        },
        isMainDashboardMin: { $set: false },
        isCustomerDashboardMin: { $set: false },
        dashboardActivated: { $set: 0 }
      });
    case 'SHOW_ADV_SEARCH': {
      return update(state, {
        search: {
          advSearchVisible: { $set: action.isVisible }
        }
      });
    }
    case 'SHOW_QUICK_SEARCH': {
      return update(state, {
        search: {
          quickSearchVisible: { $set: action.isVisible }
        }
      });
    }
    case 'SHOW_VALIDATION_RESULTS': {
      return update(state, {
        showLookupValidation: { $set: action.isVisible }
      });
    }
    case 'USER_NAVIGATION':
      return update(state, {
        navigationType: { $set: action.navigationType },
        sourceType: { $set: 1 } //, // DT Make sure we fall through the correct case statement in dashboardIndex.
        //customer: {
        //previousCustomerId: {$set: -1}
        //} // Reset customer selection as this navigation is from the user dashboard
      });
    case 'SET_DASHBOARD_DISPLAY':
      return update(state, {
        isMainDashboardMin: { $set: action.isMainDashboardMin },
        isCustomerDashboardMin: { $set: action.isCustomerDashboardMin },
        sourceType: { $set: action.sourceType }
      });
    case 'MAXIMISE_PANEL': {
      return maxPanel(action.idPanelItem, state);
    }
    case 'DRAG_TO_PANEL_CONTAINER': {
      const mainDashboardActivated = state.dashboardActivated === 0;
      const pl = mainDashboardActivated ? state.panelList : state.panelListCD;
      const newUniqueId = pl.length == 0 ? 1 : max(map(pl, (v) => v.id)) + 1;
      const ml = mainDashboardActivated
        ? state.panelDescriptors
        : state.panelDescriptorsCD;
      const menuIndex = ml.findIndex(
        (m) => m.panelTypeId === action.panelTypeId
      );
      const p = pl.map(clearSelected);
      const stateU = mainDashboardActivated
        ? update(state, { panelList: { $set: p } })
        : update(state, { panelListCD: { $set: p } });

      // By default add in all options to panel!
      const allOptionsPreSelected = [];
      const collectionWeWant = mainDashboardActivated
        ? stateU.panelDescriptors
        : stateU.panelDescriptorsCD;
      each(collectionWeWant[menuIndex].subMenu, (o) =>
        allOptionsPreSelected.push({
          menuId: action.panelTypeId,
          subMenuId: o.id,
          description: o.description
        })
      );

      collectionWeWant[menuIndex].subMenu.map((t) => ({
        ...t,
        visibility: false
      }));

      //DT bug fix, sometimes when server responses are slow you get here with menuIndex == -1
      if (menuIndex !== -1) {
        const newState = mainDashboardActivated
          ? update(stateU, {
            panelDescriptors: {
              [menuIndex]: { visibility: { $set: false } }
            },
            panelList: {
              $push: [
                {
                  id: newUniqueId,
                  panelTypeId: action.panelTypeId,
                  title: action.description,
                  isMaximised: false,
                  isSelected: true,
                  displayGraphics: action.hasGraphics,
                  options: allOptionsPreSelected,
                  sourceData: {}
                }
              ]
            },
            displayMainMenu: { $set: -1 }
          })
          : update(stateU, {
            panelDescriptorsCD: {
              [menuIndex]: { visibility: { $set: false } }
            },
            panelListCD: {
              $push: [
                {
                  id: newUniqueId,
                  panelTypeId: action.panelTypeId,
                  title: action.description,
                  isMaximised: false,
                  isSelected: true,
                  displayGraphics: action.hasGraphics,
                  options: allOptionsPreSelected,
                  sourceData: {}
                }
              ]
            },
            displayMainMenu: { $set: -1 }
          });
        return newState;
      } else {
        return stateU;
      }
    }
    case 'REQUEST_PANEL_CONTENT_DATA': {
      let mainDashboardActivated = state.dashboardActivated === 0;
      if (action.source !== undefined && action.source === 0) {
        mainDashboardActivated = true;
      }
      const pl = mainDashboardActivated ? state.panelList : state.panelListCD;
      const panelIndex = pl.findIndex((p) => p.id == action.id);
      return mainDashboardActivated
        ? update(state, {
          panelList: {
            [panelIndex]: {
              sourceData: { $set: null }
            }
          }
        })
        : update(state, {
          panelListCD: {
            [panelIndex]: {
              sourceData: { $set: null }
            }
          }
        });
    }
    case 'RECEIVE_PANEL_CONTENT_DATA': {
      let mainDashboardActivated = state.dashboardActivated === 0;
      if (action.source !== undefined && action.source === 0) {
        mainDashboardActivated = true;
      }
      const pl = mainDashboardActivated ? state.panelList : state.panelListCD;
      const panelIndex = pl.findIndex((p) => p.id == action.id);
      let menuId = -1;
      if (panelIndex === -1) return state;
      const lst = mainDashboardActivated ? state.panelList : state.panelListCD;
      const doSave =
        state.panelList.length === 1 &&
        mainDashboardActivated &&
        lst[panelIndex].panelTypeId === 11;
      if (lst[panelIndex].isMaximised) menuId = lst[panelIndex].panelTypeId;
      return mainDashboardActivated
        ? update(state, {
          panelList: {
            [panelIndex]: {
              sourceData: { $set: action.results }
            }
          },
          doSaveDefaultDashboard: { $set: doSave },
          displayMainMenu: { $set: menuId }
        })
        : update(state, {
          panelListCD: {
            [panelIndex]: {
              sourceData: { $set: action.results }
            }
          },
          displayMainMenu: { $set: menuId }
        });
    }
    case 'MOVE_PANEL_ITEM': {
      const mainDashboardActivated = state.dashboardActivated === 0;
      const pl = mainDashboardActivated ? state.panelList : state.panelListCD;
      const sourceIndex = pl.findIndex((p) => p.id === action.idSource);
      const targetIndex = pl.findIndex((p) => p.id === action.idTarget);
      const p = pl.map(clearSelected);
      const elem = p[sourceIndex];
      const stateU = mainDashboardActivated
        ? update(state, { panelList: { $set: p } })
        : update(state, { panelListCD: { $set: p } });
      const stateP = mainDashboardActivated
        ? update(stateU, {
          panelList: {
            $splice: [
              [sourceIndex, 1],
              [targetIndex, 0, elem]
            ]
          }
        })
        : update(stateU, {
          panelListCD: {
            $splice: [
              [sourceIndex, 1],
              [targetIndex, 0, elem]
            ]
          }
        });
      return mainDashboardActivated
        ? update(stateP, {
          panelList: {
            [sourceIndex]: {
              isSelected: { $set: false }
            },
            [targetIndex]: {
              isSelected: { $set: true }
            }
          },
          displayMainMenu: { $set: -1 }
        })
        : update(stateP, {
          panelListCD: {
            [sourceIndex]: {
              isSelected: { $set: false }
            },
            [targetIndex]: {
              isSelected: { $set: true }
            }
          },
          displayMainMenu: { $set: -1 }
        });
    }
    case 'MOVE_PANELITEM_TO_TRASH': {
      const mainDashboardActivated = state.dashboardActivated === 0;
      const pl = mainDashboardActivated ? state.panelList : state.panelListCD;
      if (pl.length <= 1) {
        alert(
          'You must have at least one panel displayed on your user dashboard'
        );
        return state;
      }
      const panelIndex = pl.findIndex((p) => p.id === action.idPanelItem);
      const panelMenuId = pl[panelIndex].panelTypeId;
      const ml = mainDashboardActivated
        ? state.panelDescriptors
        : state.panelDescriptorsCD;
      const menuIndex = ml.findIndex((p) => p.panelTypeId === panelMenuId);
      const stateU = mainDashboardActivated
        ? update(state, { panelList: { $splice: [[panelIndex, 1]] } })
        : update(state, { panelListCD: { $splice: [[panelIndex, 1]] } });
      const npl = mainDashboardActivated
        ? stateU.panelList
        : stateU.panelListCD;
      let p = npl.map(clearSelected);
      ml[menuIndex].subMenu.map((t) => ({ ...t, visibility: true }));
      const newS = mainDashboardActivated
        ? update(stateU, { panelDescriptors: { $set: ml } })
        : update(stateU, { panelDescriptorsCD: { $set: ml } });
      p = npl.map((t) => ({
        id: t.id,
        title: t.title,
        panelTypeId: t.panelTypeId,
        isMaximised: false,
        isSelected: t.isSelected,
        displayGraphics: t.displayGraphics,
        options: t.options,
        sourceData: t.sourceData
      }));
      const newState = mainDashboardActivated
        ? update(newS, {
          panelDescriptors: {
            [menuIndex]: { visibility: { $set: true } }
          },
          panelList: { $set: p },
          displayMainMenu: { $set: -1 }
        })
        : update(newS, {
          panelDescriptorsCD: {
            [menuIndex]: { visibility: { $set: true } }
          },
          panelListCD: { $set: p },
          displayMainMenu: { $set: -1 }
        });
      return newState;
    }
    case 'SELECT_PANEL': {
      const mainDashboardActivated = state.dashboardActivated === 0;
      const pl = mainDashboardActivated ? state.panelList : state.panelListCD;
      const panelIndex = pl.findIndex((p) => p.id === action.idPanelItem);
      if (panelIndex === -1) return state;
      const isMax = pl[panelIndex].isMaximised;
      const menuItem = pl[panelIndex].panelTypeId;
      const p = pl.map(clearSelected);
      const stateU = mainDashboardActivated
        ? update(state, { panelList: { $set: p } })
        : update(state, { panelListCD: { $set: p } });
      return mainDashboardActivated
        ? update(stateU, {
          panelList: {
            [panelIndex]: {
              isSelected: { $set: true }
            }
          },
          displayMainMenu: { $apply: () => (isMax ? menuItem : -1) }
        })
        : update(stateU, {
          panelListCD: {
            [panelIndex]: {
              isSelected: { $set: true }
            }
          },
          displayMainMenu: { $apply: () => (isMax ? menuItem : -1) }
        });
    }
    case 'SELECT_PANEL_MIN_DASH': {
      const isMainDashboard = action.sourceType === 0;
      const pl = isMainDashboard ? state.panelList : state.panelListCD;
      const panelIndex = pl.findIndex((p) => p.id === action.idPanelItem);
      const customerId = state.customer.id;
      if (panelIndex === -1) return state;
      const p = pl.map((t) => ({ ...t, isMaximised: false }));
      const stateU = isMainDashboard
        ? update(state, { panelList: { $set: p } })
        : update(state, { panelListCD: { $set: p } });
      const nState = isMainDashboard
        ? update(stateU, {
          panelList: {
            [panelIndex]: {
              isSelected: { $set: true }
            }
          },
          isMainDashboardMin: { $set: false },
          isCustomerDashboardMin: { $set: false },
          dashboardActivated: { $set: action.sourceType },
          sourceType: { $set: -1 },
          customer: {
            id: { $apply: () => (isMainDashboard ? -1 : customerId) }
          }
        })
        : update(stateU, {
          panelListCD: {
            [panelIndex]: {
              isSelected: { $set: true }
            }
          },
          isMainDashboardMin: { $set: true },
          isCustomerDashboardMin: { $set: false },
          dashboardActivated: { $set: action.sourceType },
          sourceType: { $set: -1 },
          customer: {
            id: { $apply: () => (isMainDashboard ? -1 : customerId) }
          }
        });
      return maxPanel(action.idPanelItem, nState);
    }
    case 'DRAG_TO_PANEL_LIST': {
      const mainDashboardActivated = state.dashboardActivated === 0;
      const panelList = mainDashboardActivated
        ? state.panelList
        : state.panelListCD;
      const panelDescriptors = mainDashboardActivated
        ? state.panelDescriptors
        : state.panelDescriptorsCD;
      const menuIndex = panelDescriptors.findIndex(
        (m) => m.panelTypeId === action.idMenu
      );
      const subMenuIndex = panelDescriptors
        .find((m) => m.panelTypeId === action.idMenu)
        .subMenu.findIndex((s) => s.id === action.idSubMenu);
      const subMenuDesc =
        panelDescriptors[menuIndex].subMenu[subMenuIndex].description;
      const panelIndex = panelList.findIndex((p) => p.isSelected);

      if (!isNil(menuIndex) && !isNil(panelIndex)) {
        if (mainDashboardActivated) {
          if (
            !isNil(state.panelDescriptors[menuIndex].subMenu) &&
            !isNil(state.panelList[panelIndex].options)
          ) {
            return update(state, {
              panelDescriptors: {
                [menuIndex]: {
                  subMenu: {
                    [subMenuIndex]: { visibility: { $set: false } }
                  }
                }
              },
              panelList: {
                [panelIndex]: {
                  options: {
                    $push: [
                      {
                        menuId: action.idMenu,
                        subMenuId: action.idSubMenu,
                        description: subMenuDesc
                      }
                    ]
                  }
                }
              }
            });
          } else {
            return state;
          }
        } else {
          if (
            !isNil(state.panelDescriptorsCD[menuIndex].subMenu) &&
            !isNil(state.panelListCD[panelIndex].options)
          ) {
            return update(state, {
              panelDescriptorsCD: {
                [menuIndex]: {
                  subMenu: {
                    [subMenuIndex]: { visibility: { $set: false } }
                  }
                }
              },
              panelListCD: {
                [panelIndex]: {
                  options: {
                    $push: [
                      {
                        menuId: action.idMenu,
                        subMenuId: action.idSubMenu,
                        description: subMenuDesc
                      }
                    ]
                  }
                }
              }
            });
          } else {
            return state;
          }
        }
      } else {
        return state;
      }
    }
    case 'MOVE_LIST_ITEM_TO_TRASH': {
      const mainDashboardActivated = state.dashboardActivated === 0;
      const pl = mainDashboardActivated ? state.panelList : state.panelListCD;
      const ml = mainDashboardActivated
        ? state.panelDescriptors
        : state.panelDescriptorsCD;
      const panelIndex = pl.findIndex((p) => p.id === action.idPanel);
      const itemIndex = pl[panelIndex].options.findIndex(
        (i) => i.menuId === action.idMenu && i.subMenuId === action.idSubMenu
      );
      const menuIndex = ml.findIndex((m) => m.panelTypeId === action.idMenu);
      const subMenuIndex = ml[menuIndex].subMenu.findIndex(
        (s) => s.id === action.idSubMenu
      );
      return mainDashboardActivated
        ? update(state, {
          panelDescriptors: {
            [menuIndex]: {
              subMenu: {
                [subMenuIndex]: {
                  visibility: { $set: true }
                }
              }
            }
          },
          panelList: {
            [panelIndex]: {
              options: { $splice: [[itemIndex, 1]] }
            }
          }
        })
        : update(state, {
          panelDescriptorsCD: {
            [menuIndex]: {
              subMenu: {
                [subMenuIndex]: {
                  visibility: { $set: true }
                }
              }
            }
          },
          panelListCD: {
            [panelIndex]: {
              options: { $splice: [[itemIndex, 1]] }
            }
          }
        });
    }
    case 'SELECT_GRID_FN':
      if (action.sourceType === SourceType.CUSTOMER_DASHBOARD) {
        return selectGridFunctionHome(state, action);
      } else {
        return selectGridFunction(state, action);
      }
    case 'END_PANEL_GLIMPSE':
      return restoreNavState(state, action);
    case 'GET_DEFAULT_DASHBOARD': {
      const saveDD = state.saveDefaultDashboard;
      const lookupL = state.lookUpLoaded;
      const doSaveDD = state.doSaveDefaultDashboard;
      const init = initState();
      let nState;

      if (isNil(action.data)) {
        if (
          action.viewType === UserConfigViewType.user ||
          action.viewType === UserConfigViewType.customer
        ) {
          // User or Customer
          nState = update(state, {
            dashboardActivated: { $set: 0 },
            displayMainMenu: { $set: -1 },
            isMainDashboardMin: { $set: false },
            isCustomerDashboardMin: { $set: false },
            customer: { $set: init.customer }
          });
        }
        if (action.viewType === UserConfigViewType.user) {
          // User
          nState = update(nState, {
            panelList: { $set: init.panelList },
            saveDefaultDashboard: { $set: saveDD },
            lookUpLoaded: { $set: lookupL },
            doSaveDefaultDashboard: { $set: doSaveDD }
          });
        } else if (action.viewType === UserConfigViewType.customer) {
          // Customer
          nState = update(nState, {
            panelListCD: { $set: init.panelListCD },
            saveDefaultDashboard: { $set: saveDD },
            lookUpLoaded: { $set: lookupL },
            doSaveDefaultDashboard: { $set: doSaveDD }
          });
        } else {
          return state;
        }
        return nState;
      }

      const data = action.data.Content;

      if (data !== undefined) {
        const json = JSON.parse(data);
        let menuId = -1;
        if (json.viewType == UserConfigViewType.user) {
          // User
          const list = json.panelList.map((t) => {
            return assign(t, { sourceData: ['load'] });
          });
          const index = list.findIndex((t) => t.isMaximised === true);
          if (index !== -1) {
            menuId = list[index].panelTypeId;
          }
          nState = update(state, {
            panelList: { $set: list },
            saveDefaultDashboard: { $set: saveDD },
            lookUpLoaded: { $set: lookupL },
            doSaveDefaultDashboard: { $set: doSaveDD },
            displayMainMenu: { $set: menuId }
          });
        } else if (json.viewType == UserConfigViewType.customer) {
          // Customer
          const listCD = json.panelListCD.map((t) => {
            return assign(t, { sourceData: ['load'] });
          });
          const index = listCD.findIndex((t) => t.isMaximised === true);
          if (index !== -1) {
            menuId = listCD[index].panelTypeId;
          }
          nState = update(state, {
            panelListCD: { $set: listCD },
            saveDefaultDashboard: { $set: saveDD },
            lookUpLoaded: { $set: lookupL },
            doSaveDefaultDashboard: { $set: doSaveDD },
            displayMainMenu: { $set: menuId }
          });
        }
        if (action.fromHome === 1) {
          nState = update(nState, {
            dashboardActivated: { $set: 0 },
            displayMainMenu: { $set: menuId },
            isMainDashboardMin: { $set: false },
            isCustomerDashboardMin: { $set: false },
            customer: { $set: init.customer }
          });

          nState = update(nState, {
            customer: {
              previousCustomerId: { $set: state.customer.id } // Save customer id for "glimpse"
            }
          });
        }
      }

      return nState;
    }
    case 'MOVE_OPTION_ITEM': {
      const mainDashboardActivated = state.dashboardActivated === 0;
      const pl = mainDashboardActivated ? state.panelList : state.panelListCD;
      const panelIndex = pl.findIndex((p) => p.id === action.idPanel);
      const options = pl[panelIndex].options;
      const sourceIndex = options.findIndex(
        (o) => o.subMenuId === action.idSource
      );
      const targetIndex = options.findIndex(
        (o) => o.subMenuId === action.idTarget
      );
      const tmp = options[sourceIndex];
      return mainDashboardActivated
        ? update(state, {
          panelList: {
            [panelIndex]: {
              options: {
                $splice: [
                  [sourceIndex, 1],
                  [targetIndex, 0, tmp]
                ]
              }
            }
          }
        })
        : update(state, {
          panelListCD: {
            [panelIndex]: {
              options: {
                $splice: [
                  [sourceIndex, 1],
                  [targetIndex, 0, tmp]
                ]
              }
            }
          }
        });
    }
    case 'REQUEST_MENU': {
      return update(state, {
        dataList: {
          data: { $set: null },
          errMsg: { $set: action.menu },
          isLoading: { $set: true }
        }
      });
    }
    case 'RECEIVE_MENU': {
      let newState = state;
      switch (action.menu) {
        case 'STATUS_MENU': {
          const index = state.panelDescriptorsCD.findIndex(
            (e) => e.panelTypeId === 103
          );
          if (index !== undefined && index > -1) {
            const elem = [];
            action.data.map((item) => {
              elem.push(buildSubMenuItemDescriptor(item.id, item.text, true));
            });
            newState = update(newState, {
              panelDescriptorsCD: { [index]: { subMenu: { $set: elem } } }
            });
            break;
          }
        }
        case 'SUBSTATUS_MENU': {
          const indexMenu = state.panelDescriptors.findIndex(
            (e) => e.panelTypeId === 18
          );
          const index = state.panelDescriptorsCD.findIndex(
            (e) => e.panelTypeId === 105
          );
          if (
            index !== undefined &&
            index > -1 &&
            indexMenu !== undefined &&
            indexMenu > -1
          ) {
            const elem = [];
            action.data.map((item) => {
              elem.push(buildSubMenuItemDescriptor(item.id, item.text, true));
            });
            newState = update(newState, {
              panelDescriptors: { [indexMenu]: { subMenu: { $set: elem } } },
              panelDescriptorsCD: { [index]: { subMenu: { $set: elem } } }
            });
            break;
          }
        }
        case 'TRANSACTION_TYPES_MENU': {
          const index = state.panelDescriptorsCD.findIndex(
            (e) => e.panelTypeId === 104
          );
          if (index !== undefined && index > -1) {
            const elem = [];
            action.data.map((item) => {
              elem.push(buildSubMenuItemDescriptor(item.id, item.text, true));
            });
            newState = update(newState, {
              panelDescriptorsCD: { [index]: { subMenu: { $set: elem } } }
            });
          }
          break;
        }
        case 'TASK_TYPES_MENU': {
          const indexMenu = state.panelDescriptors.findIndex(
            (e) => e.panelTypeId === PanelTypeId.USER_TASK
          );
          if (indexMenu !== undefined && indexMenu > -1) {
            const elem = [];
            action.data.map((item) => {
              elem.push(buildSubMenuItemDescriptor(item.id, item.text, true));
            });
            newState = update(newState, {
              panelDescriptors: { [indexMenu]: { subMenu: { $set: elem } } }
            });
          }
          break;
        }
        case 'FOLLOWUP_REASONS_MENU': {
          const indexMenu = state.panelDescriptors.findIndex(
            (e) => e.panelTypeId === PanelTypeId.USER_FOLLOWUP
          );
          if (indexMenu !== undefined && indexMenu > -1) {
            const elem = [];
            action.data.map((item) => {
              elem.push(buildSubMenuItemDescriptor(item.id, item.text, true));
            });
            newState = update(newState, {
              panelDescriptors: { [indexMenu]: { subMenu: { $set: elem } } }
            });
          }
          break;
        }
      }
      return update(newState, {
        dataList: {
          data: { $set: action.data },
          errMsg: { $set: '' },
          isLoading: { $set: false }
        },
        lookUpLoaded: { $set: true }
      });
    }
    case 'ERROR_MENU': {
      return update(state, {
        dataList: {
          data: { $set: null },
          errMsg: { $set: action.menu + ' ' + action.err },
          isLoading: { $set: false }
        }
      });
    }
    case 'DO_SAVE_DEFAULT': {
      return update(state, {
        saveDefaultDashboard: { $set: action.doSave }
      });
    }
    default:
      return state;
  }
};

export default dashboardReducer;

/// Selectors

export function isAnyPanelMaximised(state, isCustomerDashboard) {
  const theList = isCustomerDashboard ? state.panelListCD : state.panelList;

  return some(theList, (p) => {
    return p.isMaximised;
  });
}

/* eslint-enable */
