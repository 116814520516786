import { useEffect, useState } from 'react';
import { transactionFilterTypes } from '../constants';
import { useClient } from 'invevo-react-components';
import { useInternationalization } from '@progress/kendo-react-intl';
import { executeAuthAsyncGet } from '../utility/asyncSupport';
import { useDispatch } from 'react-redux';

const AbortController = window.AbortController;

const useTransactionFilterOptions = (
  accountId,
  dateFormat,
  currencyFormat,
  refresh
) => {
  const client = useClient()
  const internationalization = useInternationalization();
  const [transactionFilters, setTransactionFilters] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    const controller = new AbortController();

    if (!accountId) return;

    dispatch(
      executeAuthAsyncGet(
        client,
        'panelcontent/getTransactionFilters',
        'TRANSACTION_FILTERS',
        { customerId: accountId },
        setTransactionFilters,
        null,
        controller.signal
      )
    );

    return () => controller.abort();
  }, [accountId, refresh, dispatch, client]);

  const hasData = !!transactionFilters;

  const options = [
    {
      value: transactionFilterTypes.agedDebt,
      label: 'Aged Debt',
      options: hasData
        ? transactionFilters.agedDebt.map((a) => ({
            value: a.Id,
            label: `${a.Title} ${
              a.Currency
            } ${internationalization.formatNumber(a.Amount, currencyFormat)}`,
            parentId: transactionFilterTypes.agedDebt,
            ...a
          }))
        : []
    },
    {
      value: transactionFilterTypes.fallingDue,
      label: 'Falling Due',
      options: hasData
        ? transactionFilters.fallingDue.map((a) => ({
            value: a.Id,
            label: `${internationalization.formatDate(
              new Date(a.Title),
              dateFormat
            )} ${a.Currency} ${internationalization.formatNumber(
              a.Amount,
              currencyFormat
            )}`,
            parentId: transactionFilterTypes.fallingDue,
            ...a
          }))
        : []
    },
    {
      value: transactionFilterTypes.recentPayment,
      label: 'Recent Payments',
      options: hasData
        ? transactionFilters.recentPayment.map((a) => ({
            value: a.Id,
            label: `${internationalization.formatDate(
              new Date(a.Title),
              dateFormat
            )} ${a.Currency} ${internationalization.formatNumber(
              a.Amount,
              currencyFormat
            )}`,
            parentId: transactionFilterTypes.recentPayment,
            ...a
          }))
        : []
    },
    {
      value: transactionFilterTypes.transactionStatus,
      label: 'Transaction Status',
      options: hasData
        ? transactionFilters.transactionStatus.map((a) => ({
            value: a.Id,
            label: `${a.Title} ${
              a.Currency
            } ${internationalization.formatNumber(a.Amount, currencyFormat)}`,
            parentId: transactionFilterTypes.transactionStatus,
            ...a
          }))
        : []
    },
    {
      value: transactionFilterTypes.transactionType,
      label: 'Transaction Type',
      options: hasData
        ? transactionFilters.transactionType.map((a) => ({
            value: a.Id,
            label: `${a.Title} ${
              a.Currency
            } ${internationalization.formatNumber(a.Amount, currencyFormat)}`,
            parentId: transactionFilterTypes.transactionType,
            ...a
          }))
        : []
    }
  ];

  return options;
};

export default useTransactionFilterOptions;
