import useHover from '../../../hooks/useHover'
import classes from './MenuItem.module.scss'

const MenuItem = ({children, className, label, iconClasses, isActive, onClick}) => {
    const [hoverRef, isHovering] = useHover()
    return (
        <div className={`${className ? className : ''} w-100 d-flex flex-column`}>
            <div className={`${className ? className : ''} position-relative w-100`}>
                <div ref={hoverRef} className={`${classes['menu-item']} ${isActive ? classes.active : ''} ${isActive && !children ? classes['active-with-no-sub-menu'] : ''} d-flex w-100 pointer`} onClick={onClick} role="button" aria-label={label}>
                    <i className={`m-auto ${iconClasses} text-white fs-5`}></i>
                </div>
                { isHovering &&
                    <div className={`position-absolute top-0 start-100 translate-end d-flex h-100 ${classes.label}`}>
                        <span className="m-auto text-nowrap fs-5 p-3 fw-lighter no-select">{label}</span>
                    </div>
                }
           </div>
            { isActive &&
                <div className={`d-flex flex-column ${classes['sub-menu']}`}>
                    {children}
                </div>
            }   
        </div>
    )
}

export default MenuItem