import classes from './PageNavButtons.module.scss'

type PageNavButtonsProps = {
    onPageIndexUpdated: (newPageIndex: number) => void
    pageIndex: number
    totalPageNumber: number
    disabled: boolean
}

const PageNavButtons = ({ onPageIndexUpdated, pageIndex, totalPageNumber, disabled }: PageNavButtonsProps) => {

    const trySetPageIndex = (newPageIndex: number) => {
        if (disabled) return
        if (newPageIndex < 0 || newPageIndex > totalPageNumber - 1 || newPageIndex === pageIndex) return
        onPageIndexUpdated(newPageIndex)
    }

    return (
        <div className='d-flex align-items-center border rounded'>
            <i className={`fal fa-chevron-double-left px-2 py-1 border-end ${disabled || pageIndex === 0 ? classes.disabled : 'pointer'}`} onClick={() => trySetPageIndex(0)} />
            <i className={`fal fa-chevron-left px-2 py-1 border-end ${disabled || pageIndex === 0 ? classes.disabled : 'pointer'}`} onClick={() => trySetPageIndex(pageIndex - 1)} />
            <span className='mx-2'><strong>{pageIndex + 1}</strong> of <strong>{totalPageNumber > 0 ? totalPageNumber : 1}</strong></span>
            <i className={`fal fa-chevron-right px-2 py-1 border-start ${disabled || pageIndex === totalPageNumber - 1 ? classes.disabled : 'pointer'}`} onClick={() => trySetPageIndex(pageIndex + 1)} />
        </div>
    )
}

export default PageNavButtons