import IconButton from "../../buttons/IconButton/IconButton"
import { useGrid } from "../Grid"
import RowData from "../types/RowData"

type Props = {
    rowData: RowData
    onClick: (data: RowData) => void
    selectOnClick?: boolean
    icon?: string
    ariaLabelPrefix?: string
}

const ActionButton = ({ rowData, onClick, selectOnClick = true, icon = "", ariaLabelPrefix = "" }: Props) => {
    const grid = useGrid()

    const onButtonClicked = () => {
        onClick(rowData)

        if (!selectOnClick) return
        grid.onSelect(
            {
                selectedRefs: [rowData.reference],
                isAllRowsSelected: false
            },
            false
        )
    }

    return (
        <div className="d-flex justify-content-end m-1">
            <IconButton size="small" icon={icon} onClick={onButtonClicked} ariaLabel={`${ariaLabelPrefix}-${rowData.reference}`} theme="white-flat" />
        </div>
    )
}

export default ActionButton
