import { useEffect, useState } from 'react'
import { useApi, useClient, useFeatureToggle, Loading, useConfig } from "invevo-react-components"
import WorkflowRunDetails from "./WorkflowRunDetails"
import WorkflowRuns from "./WorkflowRuns"

const WorkflowRunsDashboard = ({ state, dispatch }) => {
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)

    const api = useApi()
    const client = useClient()
    const config = useConfig()
    
    const { isEnabled: showAutomateWorklowRuns } = useFeatureToggle("showAutomateWorklowRuns")

    useEffect(() => {
        dispatch({ type: "CLEAR_WORKFLOW_RUNS" })
    }, [state.selectedWorkflowRule.reference, dispatch])

    useEffect(() => {
        setHasFetched(false)
    }, [state.selectedWorkflowRule.reference])

    useEffect(() => {
        if (state.previewRequested) {
            setHasFetched(false)
        }

        if (isFetching || hasFetched || !showAutomateWorklowRuns || state.selectedWorkflowRule.isNew || !config.AUTOMATE_API_URL) {
            return
        }

        if (!state.selectedWorkflowRule.reference) {
            return
        }

        setIsFetching(true)

        api.get(`${config.AUTOMATE_API_URL}/api/${client}/workflow-rule-runs?workflowRuleReference=${state.selectedWorkflowRule.reference}`)
            .then(response => {
                setHasFetched(true)
                dispatch({ type: 'WORKFLOW_RUNS_RETRIEVED', workflowRuns: response.data })
                setIsFetching(false)
            })
            .catch(error => {
                console.warn(error)
                setHasFetched(true)
                setIsFetching(false)
                state.previewRequested && dispatch({ type: 'NEW_WORKFLOW_PREVIEW_REQUEST_FAILED' })
            })

    }, [api, client, dispatch, isFetching, hasFetched, state.selectedWorkflowRule.isNew, showAutomateWorklowRuns, state.selectedWorkflowRule.reference, state.previewRequested, config.AUTOMATE_API_URL])

    const getWorkflowRunDetails = (workflowRun, actionSuccess) => {
        setIsFetching(true)
        api.get(`${config.AUTOMATE_API_URL}/api/${client}/workflow-rule-run?workflowRunReference=${workflowRun.runReference}`)
            .then(response => {
                setHasFetched(true)
                dispatch({ type: 'WORKFLOW_RUN_SELECTED', selectedWorkflowRun: { ...response.data, isPreview: workflowRun.isPreview }, actionSuccess: actionSuccess })
                setIsFetching(false)
            })
            .catch(error => {
                console.error(error)
            })
    }

    return (
        !state.selectedWorkflowRun
            ? <Loading isLoading={isFetching || !config.AUTOMATE_API_URL} colour="blue">
                <WorkflowRuns
                    workflowRuns={state.workflowRuns}
                    onClick={(workflowRun, actionSuccess) => getWorkflowRunDetails(workflowRun, actionSuccess)}
                />
            </Loading>
            : <Loading isLoading={isFetching} colour="blue">
                <WorkflowRunDetails
                    selectedWorkflowRun={state.selectedWorkflowRun}
                    actionSuccess={state.actionSuccess}
                    dispatch={() => dispatch({ type: 'WORKFLOW_RUN_SELECTED', selectedWorkflowRun: null })}
                />
            </Loading>
    )
}

export default WorkflowRunsDashboard