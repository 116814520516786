import { useUser, useFeatureToggle, Loading, StandardButton, useClient } from "invevo-react-components"
import { useHistory, useLocation } from "react-router-dom"
import { useEffect } from "react"
import classes from "./Onboarding.module.scss"
import LanguageDropdown from "./LanguageDropdown/LangaugeDropdown"
import { useMsal } from "@azure/msal-react"
import { useSessionStorage } from "../../../hooks/useSessionStorage"
import { EXTERNAL_AFFILIATE_KEY, PRE_AUTH_ROUTE_KEY } from "../../../library/auth/constants"
import { useIsMobile } from "../../../hooks/useIsMobile"
import MobileView from "./Onboarding.mobile"
import Translate from "../../../library/translate/Translate"

const Onboarding = () => {
    const [, setPreAuthRoute] = useSessionStorage(PRE_AUTH_ROUTE_KEY)
    const [, setExternalAffiliate] = useSessionStorage(EXTERNAL_AFFILIATE_KEY)

    const { instance } = useMsal()
    const user = useUser()
    const history = useHistory()
    const client = useClient()
    const location = useLocation()
    const isMobile = useIsMobile()

    const { isEnabled } = useFeatureToggle("onboardingCustomerSignUp")

    useEffect(() => {
        if (user.username || user.permissions) {
            history.push("/")
        }
    }, [history, user])

    const onSignUpClick = () => {
        const newPreAuthPath = `/home?client=${client}`
        setPreAuthRoute(newPreAuthPath)

        const externalAffiliate = new URLSearchParams(location.search).get("affiliate")
        if (!externalAffiliate) {
            return
        }

        setExternalAffiliate({ affiliate: externalAffiliate })

        instance.loginRedirect({ scopes: [] }).catch(error => {
            console.log("SSO error")
            console.log(error)
        })
    }

    return (
        <Loading isLoading={!isEnabled} colour="blue">
            {isMobile ? (
                <MobileView onSignUpClick={onSignUpClick} />
            ) : (
                <div className={`d-flex w-100 h-100 ${classes.background} p-4 overflow-auto`}>
                    <div className={`ms-auto d-flex flex-column text-white ${classes.steps}`}>
                        <div className={`${classes.logo}`}>
                            <img src="/Invevo_Logo_White.png" alt="Invevo Logo" />
                        </div>
                        <span className="fs-3 px-4">
                            <Translate>Sign up now to view your detailed credit profile</Translate>
                        </span>
                    </div>

                    <div className={`me-auto d-flex flex-column bg-white ${classes.form} w-50`}>
                        <div className={`d-flex bg-grey p-3 ${classes.header}`}>
                            <LanguageDropdown className="ms-auto" />
                        </div>
                        <div className="d-flex flex-column overflow-auto flex-grow-1">
                            <div className="d-flex flex-column bg-white m-5 p-3 rounded shadow">
                                <span className="text-uppercase text-purple fs-4">Credit Reports</span>
                                <div className="d-flex">
                                    <i className="fa-thin fa-file-certificate text-blue mt-2 me-3 fa-5x"></i>
                                    <span>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                                        aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                    </span>
                                </div>
                            </div>
                            <StandardButton className="mx-5" label="Get started using Invevo" iconClasses="fal fa-right-to-bracket" onClick={onSignUpClick} />
                        </div>
                    </div>
                </div>
            )}
        </Loading>
    )
}

export default Onboarding
