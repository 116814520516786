const init = {
  selectedTask: null,
  taskSaved: null,
  userTaskList: {
    dateType: 0, // 0 - today, 1 - tomorrow, 2 - all, 3 - range
    startDate: null,
    endDate: null,
    taskTypeId: null,
    stateTaskTypeId: null,
    index: 0
  }
};

const taskReducer = (state = init, action) => {
  switch (action.type) {
    case 'TASK_SAVED':
      // yuk but lastUpdated date is not working server side
      if (state.selectedTask.id === action.taskId) {
        return {
          ...state,
          taskSaved: new Date()
        };
      }

      return state;
    case 'SET_SELECTED_TASK':
      const { selectedTask } = action;
      return {
        ...state,
        taskSaved: null,
        selectedTask
      };

    case 'CLEAR_SELECTED_TASK':
      return { ...state, selectedTask: null, taskSaved: null };

    case 'SET_USER_TASK_FILTER_START_DATE':
      const { startDate } = action;
      return {
        ...state,
        userTaskList: {
          ...state.userTaskList,
          startDate
        }
      };

    case 'SET_USER_TASK_FILTER_END_DATE':
      const { endDate } = action;
      return {
        ...state,
        userTaskList: {
          ...state.userTaskList,
          endDate
        }
      };

    case 'SET_USER_TASK_FILTER_STATE_TASK_TYPE':
      const { stateTaskTypeId, taskTypeId } = action;
      return {
        ...state,
        userTaskList: {
          ...state.userTaskList,
          stateTaskTypeId,
          taskTypeId
        }
      };

    case 'SET_USER_TASK_GRID_INDEX':
      const { index = -1 } = action;
      return {
        ...state,
        userTaskList: {
          ...state.userTaskList,
          index
        }
      };

    case 'SET_USER_TASK_DATE_TYPE':
      const { dateType } = action;
      return {
        ...state,
        userTaskList: {
          ...state.userTaskList,
          dateType
        }
      };

    case 'SET_USER_TASK_RESULT_TYPE':
      const { resultType } = action;
      return {
        ...state,
        userTaskList: {
          ...state.userTaskList,
          resultType
        }
      };

    default:
      return state;
  }
};

export default taskReducer;
