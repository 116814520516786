import { useState } from "react"
import classes from "./Search.module.scss"
import { useGrid } from "../Grid"
import Input from "../../Inputs/Input"

type Props = {
    autoApply?: boolean
}

const Search = ({ autoApply }: Props) => {
    const grid = useGrid()

    const [searchValue, setSearchValue] = useState("")

    const onSearchValueChanged = (value: string) => {
        setSearchValue(value)
        if (autoApply) grid.setQuickSearchValue(value)
    }

    const onApplyQuickSearchClicked = () => grid.setQuickSearchValue(searchValue)

    const onQuickSearchClearClicked = () => {
        setSearchValue("")
        grid.setQuickSearchValue("")
    }

    return (
        <div className={`d-flex ${classes.container}`}>
            <Input value={searchValue} onChange={onSearchValueChanged} placeholder="Search..." ariaLabel="quick-search" rounding="left" />
            {!autoApply && (
                <button
                    className={`d-flex fs-6 px-2 align-items-center ${classes.blueButton}`}
                    onClick={onApplyQuickSearchClicked}
                    aria-label="apply-quick-search"
                >
                    Apply
                </button>
            )}
            <button className={`d-flex fs-6 px-2 align-items-center ${classes.blueButton}`} onClick={onQuickSearchClearClicked} aria-label="clear-quick-search">
                Clear
            </button>
        </div>
    )
}

export default Search
