import React, { useEffect, useState, useRef, useCallback } from 'react'
import ParameterTag from "./ParameterTag"
import classes from './TriggerValuesInput.module.scss'

const ENTER_KEY_CODE = 13
const RETURN_KEY_CODE = 8

const TriggerValuesInput = ({
    className = "",
    selectedValues = [],
    values,
    onValuesChange
}) => {
    const [lastValue, setLastValue] = useState("")
    const [shouldShow, setShouldShow] = useState(false)
    const [currentlyAvailableOptions, setCurrentlyAvailableOptions] = useState(values.filter(v => !selectedValues.includes(v)))
    const [matchedOptions, setMatchedOptions] = useState(getMatchedOptions(values, lastValue))
    const ref = useRef()

    useEffect(() => {
        document.addEventListener("click", handleClickOutsideComponent)
        return () => document.removeEventListener("click", handleClickOutsideComponent)
    }, [])

    const handleClickOutsideComponent = event => {
        if (ref && ref.current) {
            if (!ref.current.contains(event.target)) {
                setShouldShow(false)
            }
        }
    }

    useEffect(() => {
        setCurrentlyAvailableOptions(values.filter(v => !selectedValues.includes(v)))
    }, [values, selectedValues])

    useEffect(() => {
        setMatchedOptions(getMatchedOptions(currentlyAvailableOptions, lastValue))
    }, [currentlyAvailableOptions, lastValue])

    const onValueAppended = useCallback(option => {
        onValuesChange([...selectedValues, option])
        setCurrentlyAvailableOptions(values.filter(v => !selectedValues.includes(v) && v!== option))
        setLastValue("")
    }, [onValuesChange, selectedValues, values])

    const onValueRemoved = useCallback(value => {
        const updatedValues = selectedValues.filter(v => v !== value)
        onValuesChange(updatedValues)
        setCurrentlyAvailableOptions(values.filter(f => !updatedValues.includes(f)))
    }, [onValuesChange, selectedValues, values])
    
    const handleKeyPress = useCallback(event => {
        if (shouldShow && ref && ref.current) {
            if (event.keyCode === ENTER_KEY_CODE) {
                if (matchedOptions.length > 0) onValueAppended(matchedOptions[0])
            } else if (event.keyCode === RETURN_KEY_CODE && lastValue === "") {
                if (selectedValues.length > 0) 
                    onValueRemoved(selectedValues[selectedValues.length - 1])
            }
        }
    }, [lastValue, matchedOptions, onValueAppended, onValueRemoved, selectedValues, shouldShow])

    const getMatchedOptionValue = optionValue => {
        const matchingIndex = optionValue.toLowerCase().indexOf(lastValue.toLowerCase())
        const firstPart = optionValue.substring(0, matchingIndex)
        const matchingPart = optionValue.substring(matchingIndex, matchingIndex + lastValue.length)
        const lastPart = optionValue.substring(matchingIndex + lastValue.length, optionValue.length)

        return <span>{firstPart}<strong>{matchingPart}</strong>{lastPart}</span>
    }
        
    return (
        <div ref={ref} className={`${className ? className : `d-flex flex-column bg-white`}`}>
            <div className={`d-flex align-items-center p-1 ${classes.parameters}`}  >
                {
                    selectedValues.map(v =>
                        <ParameterTag
                            key={v.id}
                            parameter={v}
                            onDeleteClick={onValueRemoved}
                        />
                    )
                }
                <input
                    className={`d-flex p-0 ${classes.input} w-100`} value={lastValue} onChange={event => setLastValue(event.target.value)}
                    onClick={() => setShouldShow(true)}
                    onKeyDown={handleKeyPress}
                />
            </div>
            <div className="d-flex w-100">
                {matchedOptions.length > 0 && shouldShow &&
                    <div className={`position-absolute ${classes.options} ${classes["options-below"]} border text-grey shadow bg-grey`}>
                        {
                            matchedOptions.length === 0
                                ? <div />
                                : matchedOptions
                                    .map(o => 
                                        <div key={o} className="d-flex p-2 pointer" onClick={() => onValueAppended(o)}>
                                            <span>{getMatchedOptionValue(o)}</span>
                                        </div>
                                    ).reduce((prev, curr) => [prev, <div key={prev + curr} className="border-top"></div>, curr])
                        }
                    </div>
                }
            </div>
        </div>
    )
}

const getMatchedOptions = (options, lastValue) => {
    return options.filter(o => o.toLowerCase().includes(lastValue.toLowerCase()) ||
                o.toLowerCase().includes(lastValue.toLowerCase()))
}

export default TriggerValuesInput