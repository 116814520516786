import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { classNames } from '@progress/kendo-react-common'

const CustomSwitch = ({
  id,
  onChange,
  checked = null,
  size = 'xs',
  disabled = false,
  type = null,
  checkedTrueText = null,
  checkedFalseText = null
}) => {
  const [isHover, setIsHover] = useState(false)
  const customSwitchClassNames = classNames('custom-switch', {
    'custom-switch-xs': size === 'xs',
    'custom-swtich-sm': size === 'sm',
    'custom-switch-label-io': type === 'io',
    'custom-switch-label-onoff': type === 'onoff',
    'custom-switch-label-yesno': type === 'yesno',
    'custom-switch-label-status': type === 'status'
  })

  const isNull = checked === null

  const labelClasses = classNames('custom-switch-btn', {
    'custom-switch-btn-unset': isNull && !isHover && !disabled,
    'custom-switch-btn-disabled': disabled
  })

  const checkedInput = isNull ? false : checked.toString().toLowerCase() === "true"

  const onCheckedChange = event => onChange(event.target.checked)

  const onClick = event => event.stopPropagation()

  return (
    <div className={customSwitchClassNames}>
      <input
        className="custom-switch-input"
        id={id}
        type="checkbox"
        checked={checkedInput}
        disabled={disabled}
        onChange={onCheckedChange}
        onClick={onClick}
      />
      <label
        className={labelClasses}
        htmlFor={id}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      ></label>

      {checkedTrueText ? (
        <div className="custom-switch-content-checked pt-1">
          {checkedTrueText}
        </div>
      ) : null}
      {checkedFalseText ? (
        <div className="custom-switch-content-unchecked pt-1">
          {checkedFalseText}
        </div>
      ) : null}
    </div>
  )
}

CustomSwitch.propTypes = {
  /**
   * id of component - must be unique per page
   **/
  id: PropTypes.string.isRequired,
  /**
   * checked - true, false, null
   */
  checked: PropTypes.oneOf([true, false]),
  /**
   * on change call back
   **/
  onChange: PropTypes.func.isRequired,
  /**
   * disabled - read only
   */
  disabled: PropTypes.bool,
  /**
   * size - xs (default), sm and lg
   */
  size: PropTypes.oneOf(['xs', 'sm', 'lg']),

  /**
   * text when checked = true
   */
  checkedTrueText: PropTypes.string,
  /**
   * text when checked = false
   */
  checkedFalseText: PropTypes.string
}

export default CustomSwitch
