/////////////////////////
// StaticNotesByCustomerId
/////////////////////////
export const requestStaticNotesByCustomerId = (customerId) => ({
    type: 'REQUEST_STATIC_NOTES_BY_CUSTOMERID',
    customerId
  });
  export const receiveStaticNotesByCustomerId = (data, customerId) => ({
    type: 'RECEIVE_STATIC_NOTES_BY_CUSTOMERID',
    data,
    customerId
  });
  export const errorStaticNotesByCustomerId = (err) => ({
    type: 'ERROR_STATIC_NOTES_BY_CUSTOMERID',
    err
  });
  /////////////////////////
  // SaveStaticNote
  /////////////////////////
  export const requestSaveStaticNote = (note) => ({
    type: 'REQUEST_SAVE_STATIC_NOTE',
    staticNote: note
  });
  export const receiveSaveStaticNote = () => ({
    type: 'RECEIVE_SAVE_STATIC_NOTE'
  });
  export const errorSaveStaticNote = (err) => ({
    type: 'ERROR_SAVE_STATIC_NOTE',
    err
  });
  export const requestDeleteStaticNote = (id) => ({
    type: 'REQUEST_DELETE_STATIC_NOTE',
    id
  });
  export const receiveDeleteStaticNote = () => ({
    type: 'RECEIVE_DELETE_STATIC_NOTE'
  });
  export const errorDeleteStaticNote = (err) => ({
    type: 'ERROR_DELETE_STATIC_NOTE',
    err
  });
  export const followUpSaved = () => ({
    type: 'FOLLOW_UP_SAVED'
  });
  export const taskSaved = () => ({
    type: 'TASK_SAVED'
  });
  /////////////////////////
  // SaveNote
  /////////////////////////
  export const requestSaveNote = (note) => ({
    type: 'REQUEST_SAVE_NOTE',
    note
  });
  export const receiveSaveNote = () => ({
    type: 'RECEIVE_SAVE_NOTE'
  });
  export const errorSaveNote = (err) => ({
    type: 'ERROR_SAVE_NOTE',
    err
  });
  /////////////////////////
  // TransactionLine SaveNote
  /////////////////////////
  export const requestTransactionLineSaveNote = (note) => ({
    type: 'REQUEST_TRANSACTION_LINE_SAVE_NOTE',
    note
  });
  export const receiveTransactionLineSaveNote = () => ({
    type: 'RECEIVE_TRANSACTION_LINE_SAVE_NOTE'
  });
  export const errorTransactionLineSaveNote = (err) => ({
    type: 'ERROR_TRANSACTION_LINE_SAVE_NOTE',
    err
  });
  /////////////////////////
  // Select Transaction Line
  /////////////////////////
  export const selectTransactionLine = (transactionLineIds) => ({
    // <-- transactionIds is an array of GUID transactionIds.
    type: 'SELECT_TRANSACTION_LINE',
    transactionLineIds
  });
  /////////////////////////
  // Select Transaction
  /////////////////////////
  export const selectTransaction = (transactionIds) => ({
    // <-- transactionIds is an array of GUID transactionIds.
    type: 'SELECT_TRANSACTION',
    transactionIds
  });
  export const selectTransactionRefs = (transactionRefs) => ({
    // <-- transactionIds is an array of GUID transactionIds.
    type: 'SELECT_TRANSACTION_REFS',
    transactionRefs
  });
  export const receiveTransactionsAggregate = (data) => ({
    type: 'RECEIVE_TRANSACTIONS_AGGREGATE',
    data
  });
  export const resetAggregate = () => ({
    type: 'RESET_AGGREGATE'
  });
  export const selectAllTransactions = (allTransactionsSelected) => ({
    type: 'SELECT_ALL_TRANSACTIONS',
    allTransactionsSelected
  });
  /////////////////////////
  // Select Contact
  /////////////////////////
  export const selectCustomerContact = (id, email, name, addressId) => ({
    type: 'SELECT_CUSTOMER_CONTACT',
    id,
    email,
    addressId
  });
  export const selectInternalContact = (id, email) => ({
    type: 'SELECT_INTERNAL_CONTACT',
    id,
    email
  });
  
  export const setTransactionNoteParameters = (
    followUpDate,
    statusId,
    subStatusId,
    ptpDate,
    contactId
  ) => ({
    type: 'SET_TRANSACTION_NOTE_PARAMETERS',
    followUpDate,
    statusId,
    subStatusId,
    ptpDate,
    contactId
  });
  export const setTransactionStatusNoteParameters = (data) => ({
    type: 'SET_TRANSACTION_STATUS_NOTE_PARAMETERS',
    data
  });
  export const setTransactionLineStatusNoteParameters = (data) => ({
    type: 'SET_TRANSACTION_LINE_STATUS_NOTE_PARAMETERS',
    data
  });
  export const requestStatusSubStatus = () => ({
    type: 'REQUEST_STATUS_SUBSTATUS'
  });
  export const receiveStatusSubStatus = (data) => ({
    type: 'RECEIVE_STATUS_SUBSTATUS',
    data
  });
  export const errorStatusSubStatus = (err) => ({
    type: 'ERROR__STATUS_SUBSTATUS',
    err
  });
  /////////////////////////
  // TransactionLine Status Substatus
  /////////////////////////
  export const requestTransactionLineStatusSubStatus = () => ({
    type: 'REQUEST_TRANSACTION_LINE_STATUS_SUBSTATUS'
  });
  export const receiveTransactionLineStatusSubStatus = (data) => ({
    type: 'RECEIVE_TRANSACTION_LINE_STATUS_SUBSTATUS',
    data
  });
  export const errorTransactionLineStatusSubStatus = (err) => ({
    type: 'ERROR_TRANSACTION_LINE_STATUS_SUBSTATUS',
    err
  });
  export const setGroupId = (gId) => ({
    type: 'SET_GROUP_ID',
    gId
  });
  export const foundMatchingGroup = (foundGroupIdOrNull) => ({
    type: 'FOUND_MATCHING_GROUP',
    foundGroupIdOrNull
  });
  export const setCustomerGridId = (gCustomerId) => ({
    type: 'SET_CUSTOMER_GRID_ID',
    customerId: gCustomerId
  });
  export const setSelected = (isSelected) => ({
    type: 'SET_SELECTED_FILTER',
    isSelected
  });
  export const loadTransactionIds = (tIds) => ({
    type: 'LOAD_TRANSACTION_IDS',
    tIds
  });
  export const loadTransactionRefs = (tRefs) => ({
    type: 'LOAD_TRANSACTION_REFS',
    tRefs
  });
  export const errorLoadTransactionIds = (err) => ({
    type: 'ERROR_LOAD_TRANSACTION_IDS',
    err
  });
  export const resetMsg = () => ({
    type: 'REST_MSG'
  });
  /////////////////////////
  // Select Document
  /////////////////////////
  export const selectDocument = (documents) => ({
    type: 'SELECT_DOCUMENT',
    documents
  });
  /////////////////////////
  // Select Contact
  /////////////////////////
  export const selectCustomerContacts = (customerContacts) => ({
    type: 'SELECT_CUSTOMER_CONTACTS',
    customerContacts
  });
  export const selectInternalContacts = (internalContacts) => ({
    type: 'SELECT_INTERNAL_CONTACTS',
    internalContacts
  });
  /////////////////////////
  // Select Contact
  /////////////////////////
  export const selectReplyToEmail = (replyToEmail) => ({
    type: 'SELECT_REPLY_TO_EMAIL',
    replyToEmail
  });
  