import React from 'react';
import { connect } from 'react-redux';
import { last } from 'lodash';
import DatePicker from 'react-datepicker';

const TradeLimitExpirationDate = ({
  temporaryCreditLimitExpirationMax,
  value,
  onChange
}) => {
  let tradeLimitMaxExpiration = null;
  const tradeLimitMinExpiration = new Date();
  if (temporaryCreditLimitExpirationMax !== null) {
    const dt = new Date();

    dt.setDate(dt.getDate() + temporaryCreditLimitExpirationMax);
    tradeLimitMaxExpiration = dt;
  }

  return (
    <DatePicker
      className="form-control"
      placeholder=" "
      dateFormat="dd MMMM yyyy"
      onChange={onChange}
      selected={value}
      maxDate={tradeLimitMaxExpiration}
      minDate={tradeLimitMinExpiration}
      style={{ width: '100%' }}
    />
  );
};

function mapStateToProps(state) {
  let configuration = {};

  if (state.globalConfiguration.length > 0) {
    configuration = last(state.globalConfiguration);
  }

  return {
    temporaryCreditLimitExpirationMax:
      configuration.temporaryCreditLimitExpirationMax
  };
}

export default connect(mapStateToProps)(TradeLimitExpirationDate);
