import DataRow from "../../../../library/dataGrid/types/DataRow"

export type ClientUsersState = {
    clientUserDataRows: DataRow[]
}

export enum ClientUsersActionEnum {
    CLIENT_USERS_FETCHED,
    CLIENT_USER_UPDATED,
    CLIENT_USER_DELETED
}

type ClientUsersFetchedAction = {
    type: ClientUsersActionEnum.CLIENT_USERS_FETCHED
    clientUserDataRows: DataRow[]
}

type ClientUserUpdatedAction = {
    type: ClientUsersActionEnum.CLIENT_USER_UPDATED
    clientUserDataRow: DataRow
}

type ClientUserDeletedAction = {
    type: ClientUsersActionEnum.CLIENT_USER_DELETED
    clientUserDataRow: DataRow
}

export type ClientUsersAction = ClientUsersFetchedAction | ClientUserUpdatedAction | ClientUserDeletedAction

export default function CustomerSearchReducer(state: ClientUsersState, action: ClientUsersAction): ClientUsersState {
    switch (action.type) {
        case ClientUsersActionEnum.CLIENT_USERS_FETCHED: {
            return {
                ...state,
                clientUserDataRows: action.clientUserDataRows
            }
        }
        case ClientUsersActionEnum.CLIENT_USER_UPDATED: {
            return {
                ...state,
                clientUserDataRows: [...state.clientUserDataRows.map(row => (row.id === action.clientUserDataRow.id ? action.clientUserDataRow : row))]
            }
        }
        case ClientUsersActionEnum.CLIENT_USER_DELETED: {
            return {
                ...state,
                clientUserDataRows: state.clientUserDataRows.filter(row => row.id !== action.clientUserDataRow.id)
            }
        }
    }
}
