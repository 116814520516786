import { bucketChartTypeDropdownOptions, transactionAggregationTypes } from '../../../enums/transactionAggregationTypes'
import { widgetThemeDropdownOptions, widgetThemes } from '../../../enums/widgetThemes'
import { actionTypes } from '../../../reducers/actionTypes'
import WidgetInput from '../forms/WidgetInput'
import classes from './InformationWidget.module.scss'
import { DashboardConfigAction } from '../../../reducers/dashboardConfigsReducer'
import { AreaChartWidgetState } from '../../../types/DashboardConfigState'
import Dropdown from '../../../../../library/dropdowns/Dropdown'
import DropdownOption from '../../../../../types/DropdownOptions'

type AreaChartWidgetProps = {
    className?: string
    state: { 
        widget: AreaChartWidgetState
        showValidationErrors: boolean
    }
    dispatch: React.Dispatch<DashboardConfigAction>
}

const AreaChartWidget = ({ className, state, dispatch }: AreaChartWidgetProps) => {
    const { widget, showValidationErrors } = state

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>)  => dispatch({ type: actionTypes.AREA_CHART_WIDGET_NAME_CHANGED, widget, newName: event.target.value })
    const onRemoveClick = () => dispatch({ type: actionTypes.WIDGET_REMOVED, widget })

    const onThemeChange = (themeOption: DropdownOption<widgetThemes>) => dispatch({ type: actionTypes.AREA_CHART_WIDGET_THEME_CHANGED, widget, newTheme: themeOption.value })
    const onTypeChange = (typeOption: DropdownOption<transactionAggregationTypes>) => dispatch({ type: actionTypes.AREA_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED, widget, newType: typeOption.value })

    return (
        <div className={`${className ? className : ''} d-flex flex-column ${classes.widget} bg-white p-2 text-grey overflow-auto`}>
            <div className="d-flex align-items-center border-bottom pb-2">
                <i className="fal fa-chart-area me-2"></i>
                <WidgetInput
                    className="flex-grow-1"
                    value={widget.displayName}
                    placeholder="Please enter name"
                    onChange={onNameChange}
                    isValid={!showValidationErrors || widget.displayName !== ""}
                />
                <i className="fal fa-times ms-3 me-1 pointer" onClick={onRemoveClick}></i>
            </div>
            <div className="d-flex flex-column mt-2 px-2">
                <div className="d-flex flex-column">
                    <div className='d-flex align-items-center justify-content-between mb-2'>
                        <span className='me-2 text-uppercase small'>Theme:</span>
                        <Dropdown
                            ariaLabel="widgetThemeDropdown"
                            options={widgetThemeDropdownOptions}
                            selectedOption={widgetThemeDropdownOptions.find(o => o.value === widget.theme)}
                            onOptionSelected={onThemeChange}
                        />
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='me-2 text-uppercase small'>Aggregation:</span>
                        <Dropdown
                            ariaLabel="bucketChartTypeDropdown"
                            options={bucketChartTypeDropdownOptions}
                            selectedOption={bucketChartTypeDropdownOptions.find(o => o.value === widget.bucketChartType)}
                            onOptionSelected={onTypeChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AreaChartWidget