import { useCustomer } from '../../../../../contexts/CustomerContext'
import FilterList from '../../../../../library/FilterList/FilterList'

const AdvancedFilters = ({ fields, lookups }) => {
    const [customer, setCustomer] = useCustomer()

    const onFiltersApplied = filters => setCustomer({ advancedTransactionFilters: filters })

    const dataFields = fields.map(field => ({
        value: field.fieldName,
        label: field.displayName,
        type: field.dataPrimitive,
        lookup: field.lookup
    }))

    return (
        <div className="d-flex bg-grey rounded p-3">
            <FilterList
                fields={dataFields}
                lookups={lookups}
                appliedFilters={customer.advancedTransactionFilters}
                onFiltersApplied={onFiltersApplied}
            />
        </div>
    )
}
export default AdvancedFilters