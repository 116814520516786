import { IconButton } from "invevo-react-components"
import classes from "./FileImportConfigs.module.scss"
import { FileImportConfigAction, FileImportConfigActionEnum, FileImportConfigDashboardState } from "../../../reducers/FileEntityImportConfigReducer"
import FileImportConfigCard from "./FileImportConfigCard"

type FileImportConfigsProps = {
    state: FileImportConfigDashboardState
    dispatch: React.Dispatch<FileImportConfigAction>
}

const FileImportConfigs = ({ state, dispatch }: FileImportConfigsProps) => {
    const onAddConfigClicked = () =>
        dispatch({
            type: FileImportConfigActionEnum.FILE_IMPORT_CONFIG_ADDED
        })

    return (
        <div className={`${classes.container} d-flex flex-column bg-grey px-4`} aria-label="File Import Configs">
            <div className={`d-flex flex-column py-2 w-100`}>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="text-grey no-select fs-2">File Importer</span>
                    <IconButton iconClasses="fal fa-plus" onClick={onAddConfigClicked} ariaLabel="Add file import config" />
                </div>
            </div>

            {state.configs.length > 0 ? (
                <div className="overflow-auto">
                    <div className="d-flex flex-column gap-2">
                        {state.configs.map(config => (
                            <FileImportConfigCard
                                key={config.reference}
                                config={config}
                                dispatch={dispatch}
                                entities={state.entities}
                                isSelected={config.reference === state.editingConfig?.reference}
                            />
                        ))}
                    </div>
                </div>
            ) : (
                <span className="fs-4 text-grey">You have no file import configurations</span>
            )}
        </div>
    )
}

export default FileImportConfigs
