import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

const CallPhoneButton = ({ contact, disabled = false, icon = 'phone' }) => {
  const { invevoPhoneFeature } = useFlags();
  const isCallableNumber =
    contact.phoneNumber &&
    contact.phoneNumber.startsWith('+') &&
    invevoPhoneFeature;
  const hasNumber = contact.phoneNumber && contact.phoneNumber.length > 1;

  if (isCallableNumber)
    return (
      <button
        className="btn btn-default w-100 text-left"
        disabled={disabled} 
      >
        <i className={`fa-solid fa-${icon} me-2`}></i>
        <span className="text-muted">{contact.phoneNumber}</span>
      </button>
    );

  if (!isCallableNumber && hasNumber)
    return (
      <div className="btn btn-default w-100 text-left" disabled={true}>
        <i className={`fa-solid fa-${invevoPhoneFeature ? 'phone-slash' : icon} me-2`}></i>
        <span className="text-muted">{contact.phoneNumber}</span>
      </div>
    );

  return <></>;
};

export default CallPhoneButton;
