import Affordability from "./Affordability.mobile"
import { AccountDto } from "../../types/dtos/AccountDto"
import CurrentBalance from "./CurrentBalance.mobile"
import { DirectIdAffordabilityDto } from "../../types/dtos/DirectIdAffordabilityDto"
import EstimatedAffordability from "./EstimatedAffordability.mobile"

type InsightsProps = {
    account: AccountDto
    affordability: DirectIdAffordabilityDto[]
}

const Insights = ({ account, affordability }: InsightsProps) => {
    return (
        <div className="d-flex flex-column w-100 h-100">
            {affordability.map(a => (
                <EstimatedAffordability affordability={a} currencyCode={account.metadata.currencyCode ?? "GBP"} />
            ))}
            <div className="mt-3">
                {affordability.map(a =>
                    (a.monthAffordabilities?.length ?? 0) > 0 ? (
                        <Affordability
                            className="flex-grow-1 bg-white"
                            monthAffordabilities={a.monthAffordabilities ?? []}
                            currencyCode={account.metadata.currencyCode ?? "GBP"}
                        />
                    ) : (
                        <div className="d-flex border border-1 p-3 flex-grow-1 rounded bg-white">
                            <span>This account has no affordability calculations</span>
                        </div>
                    )
                )}
            </div>
            <CurrentBalance className="mt-3" account={account} />
        </div>
    )
}

export default Insights
