import { useState } from "react"
import { useEnvConfig } from "../../../../contexts/EnvironmentConfigContext"
import useApiQuery from "../../../../hooks/useApiQuery"
import useClient from "../../../../hooks/useClient"
import EntityConfig from "../../../entityConfig/types/EntityConfig"
import { EntityResultWithPreviousStateDto } from "./types/EntityResultWithPreviousStateDto"
import EntityRelationshipDto from "../../../entityConfig/types/EntityRelationshipDto"
import Lookup from "../../../../types/Lookup"
import { groupEntityNotes } from "./common"
import NoteCard from "./NoteCard"
import Loading from "../../../../library/Loading/Loading"
import DropdownOption from "../../../../types/DropdownOptions"
import MultiSelectDropdown from "../../../../library/dropdowns/MultiSelectDropdown"
import IconButton from "../../../../library/buttons/IconButton/IconButton"
import Toggle from "../../../../library/toggle/Toggle"
import Note from "./types/Note"
import { useSelectedEntities } from "../../../../contexts/SelectedEntitiesContext"
import { useOverlay } from "../../../../contexts/overlay/OverlayContext"
import UpdateField from "../UpdateField"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import { DataPrimitiveTypeEnum, DataTypeEnum } from "../../../entityConfig/types/DataType"
import { FieldsUpdate } from "../../../../library/common"

type Props = {
    entityConfigs: EntityConfig[]
    entityRelationships: EntityRelationshipDto[]
    lookups: Lookup[]
}

const EntityNotes = ({ entityConfigs, entityRelationships, lookups }: Props) => {
    const config = useEnvConfig()
    const client = useClient()
    const { selectedEntity, selectedRefs } = useSelectedEntities()

    const [childEntityTypes, setChildEntityTypes] = useState<string[]>(
        entityRelationships.filter(r => r.parentEntityReference === selectedEntity?.entityTypeReference).map(r => r.childEntityReference)
    )

    const onSelectedChildrenChanged = (options: DropdownOption<string>[]) => setChildEntityTypes(options.map(o => o.value))

    const trackedChildFields = entityConfigs
        .filter(c => childEntityTypes.includes(c.reference) || selectedEntity?.entityTypeReference === c.reference)
        .map(c => {
            //we need to make sure to look for text_comment for comments added from notes view (they update this field even if not in the entity config)
            const hasCommentField = c.fields.some(f => f.fieldName === "text_comment")
            const fields = c.fields.filter(f => f.trackChanges).map(f => ({ fieldName: f.fieldName, dataType: f.dataPrimitiveType }))
            return {
                [c.reference]: hasCommentField ? fields : [...fields, { fieldName: "text_comment", dataType: DataPrimitiveTypeEnum.TEXT }]
            }
        })

    const trackedFields: { [entityConfigRef: string]: { fieldName: string; dataType: DataPrimitiveTypeEnum }[] } = Object.assign({}, ...trackedChildFields)

    const getNotesRequest = useApiQuery<EntityResultWithPreviousStateDto[]>({
        url: `${config.SEARCH_API_URL}/api/${client}/notes`,
        method: "POST",
        dto: {
            entityReference: selectedEntity?.reference,
            parentEntityType: selectedEntity?.entityTypeReference,
            childEntityTypes: childEntityTypes,
            entityTrackedFields: trackedFields
        }
    })

    const [showPreviousValues, setShowPreviousValues] = useState(false)
    const entityConfig = entityConfigs.find(c => c.reference === selectedEntity?.entityTypeReference)

    const notes: Note[] = groupEntityNotes(getNotesRequest.data ?? [], entityConfigs, lookups, showPreviousValues)

    const entityRelationshipLookups: DropdownOption<string>[] = (entityRelationships ?? [])
        .filter(r => r.parentEntityReference === selectedEntity?.entityTypeReference)
        .map(r => ({
            label: entityConfigs?.find(c => c.reference === r.childEntityReference)?.displayName ?? r.childEntityReference,
            value: r.childEntityReference
        }))

    const selectedChildEntities = entityRelationshipLookups.filter(r => childEntityTypes.includes(r.value))

    const refresh = () => getNotesRequest.execute()

    const bulkUpdateEntityRequest = useApiQuery({
        url: `${config.INTEGRATION_API_URL}/api/${client}/entities`,
        method: "PATCH",
        isExecutedAutomatically: false
    })

    const onSubmitNewComment = async (updates: FieldsUpdate[]) => {
        return bulkUpdateEntityRequest.execute(undefined, updates).then(overlay.closeOverlay).then(refresh)
    }

    const overlay = useOverlay()
    const onNewCommentClicked = () =>
        overlay.showOverlay(
            <UpdateField
                entityConfigs={entityConfigs}
                fieldTypeName="Comment"
                field={{ fieldName: "text_comment", dataType: DataTypeEnum.RICH_TEXT }}
                onUpdateSubmit={onSubmitNewComment}
            />
        )

    return (
        <div className="d-flex flex-column flex-grow-1 gap-2 py-2">
            <div className="d-flex flex-row gap-2 align-items-center justify-content-between ">
                <div className="d-flex flex-column flex-fill gap-2">
                    <div className="d-flex align-items-center px-2 gap-3">
                        <span className="fs-5">Showing Children:</span>
                        <MultiSelectDropdown
                            className="w-50"
                            options={entityRelationshipLookups}
                            onSelectedOptionsChanged={onSelectedChildrenChanged}
                            selectedOptions={selectedChildEntities}
                        />
                    </div>
                    <div className="d-flex align-items-center px-2 gap-3">
                        <span className="fs-5">Show Previous Values:</span>
                        <Toggle status={showPreviousValues} onStatusChanged={setShowPreviousValues} ariaLabel="notes-previous-values-toggle" />
                    </div>
                </div>
                <div className="d-flex px-4 align-items-center justify-content-center">
                    <IconButton icon="fal fa-refresh" theme="blue" onClick={refresh} ariaLabel="refresh-notes" />
                </div>
            </div>
            <StandardButton
                iconClasses="fal fa-notes"
                label="Add Comment"
                onClick={onNewCommentClicked}
                colour="grey"
                className="px-3"
                ariaLabel="notes-add-comment"
            />
            <Loading isLoading={getNotesRequest.isFetching} colour="white">
                <div className={`d-flex flex-column flex-grow-1 gap-2 align-items-center px-2 ${getNotesRequest.isFetching ? "" : "overflow-auto"}`}>
                    {notes.length === 0 && (
                        <span className="fs-3 text white">{`There is no history for ${
                            (selectedRefs[entityConfig?.reference ?? ""] ?? []).length > 0 ? `these entities` : `this entity`
                        } `}</span>
                    )}
                    {notes.map((note, index) => (
                        <NoteCard key={index} note={note} isParentNote={note.noteId === selectedEntity?.reference} />
                    ))}
                </div>
            </Loading>
        </div>
    )
}

export default EntityNotes
