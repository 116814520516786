import { useFeatureToggle } from "../../../hooks/useFeatureToggle"
import DashboardCustomer from "../../../microfrontends/dashboard/components/customer/Customer"
import SelectedEntity from "../../../microfrontends/dashboard/components/customer/SelectedEntity"

const Customer = ({ store, isNewEntityStructureEnabled, goToNextEntity }) => {
    const { isEnabled: isSqlFirst } = useFeatureToggle("sqlFirst")
    const { isEnabled: useEntityDashboardsWhenSqlFirst } = useFeatureToggle("useEntityDashboardsWhenSqlFirst")
    return (
        <div className="d-flex w-100 h-100">
            {isNewEntityStructureEnabled && (!isSqlFirst || useEntityDashboardsWhenSqlFirst) ? (
                <SelectedEntity goToNextEntity={goToNextEntity} store={store} />
            ) : (
                <DashboardCustomer store={store} />
            )}
        </div>
    )
}

export default Customer
