import update from 'immutability-helper';
import { uiConfigAction } from '../constants';

const initialState = {
  isSavingConfig: false,
  isSavingConfigOk: false,
  isLoadingDefaultConfig: false,
  isLoadingDefaultConfigOk: false,
  isLoadingConfig: false,
  isLoadingConfigOk: false,

  validationMessage: '',
  configs: []
};

const parseJson = (response) =>
  response && response.Content
    ? {
        ...response,
        Content: JSON.parse(response.Content)
      }
    : response;

const uiConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    ///////////////////
    // SaveConfig
    ///////////////////

    case uiConfigAction.SAVECONFIG_REQUEST:
      return update(state, {
        isSavingConfig: { $set: true },
        isSavingConfigOk: { $set: false },
        validationMessage: { $set: '' }
      });
    case uiConfigAction.SAVECONFIG_SUCCESS:
      const saveResponse = parseJson(action.config);

      return update(state, {
        isSavingConfig: { $set: false },
        isSavingConfigOk: { $set: true },
        validationMessage: { $set: '' },
        configs: {
          [action.viewTypeId]: {
            $set: saveResponse
          }
        }
      });
    case uiConfigAction.SAVECONFIG_FAILURE:
      return update(state, {
        isSavingConfig: { $set: false },
        isSavingConfigOk: { $set: false },
        validationMessage: { $set: action.validationMessage }
      });

    ///////////////////
    // LoadDefaultConfig
    ///////////////////
    case uiConfigAction.LOADDEFAULTCONFIG_REQUEST:
      return update(state, {
        isLoadingDefaultConfig: { $set: true },
        isLoadingDefaultConfigOk: { $set: false },
        validationMessage: { $set: '' }
      });
    case uiConfigAction.LOADDEFAULTCONFIG_SUCCESS:
      const loadResponse = parseJson(action.config);

      return update(state, {
        isLoadingDefaultConfig: { $set: false },
        isLoadingDefaultConfigOk: { $set: true },
        validationMessage: { $set: '' },
        configs: { [action.viewTypeId]: { $set: loadResponse } }
      });
    case uiConfigAction.LOADDEFAULTCONFIG_FAILURE:
      return update(state, {
        isLoadingDefaultConfig: { $set: false },
        isLoadingDefaultConfigOk: { $set: false },
        validationMessage: { $set: action.validationMessage }
      });

    default: {
      return state;
    }
  }
};

export default uiConfigReducer;
