import { getApiUrl } from '../constants';
import { webApiInterface } from './webApiInterface';

export function getRefreshProcesses(client, dispatch) {
    const url = getApiUrl(client) + 'api/refresh-process';

    return webApiInterface.authFetch(client, url, dispatch)
        .then(function (response) {
            return response.json();
        });
}

export function setRefreshProcess(client, dispatch) {
    const url = getApiUrl(client) + 'api/refresh-process';

    return webApiInterface.authPost(client, url, dispatch, null, 'POST');
}