import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getReportDetails } from '../../api/reportApi';
import ReportMenu from './ReportMenu';

class ReportMenuForm extends Component {
  componentDidMount() {
    const reportName = this.props.isReportNavigationFromMainMenu
      ? 'userdashboard'
      : 'customerdashboard';
    this.props.getReportDetails(this.props.client, reportName);
  }

  render() {
    return <ReportMenu />;
  }
}

const mapStateToProps = (state) => {
  return {
    reportDetails: state.reportReducer.reportDetails,
    selectedAccount: state.currentSelectionReducer.selectedAccount,
    isReportNavigationFromMainMenu:
      state.currentSelectionReducer.isReportNavigationFromMainMenu
  };
};

export default connect(mapStateToProps, { getReportDetails })(ReportMenuForm);
