import { AutoCompleteList, DatePicker, Dropdown, GreyButton, IconButton, Loading, useApi, useClient, useConfig } from "invevo-react-components"
import { useState } from "react"

const CreateTaskForm = ({ customerRef, users, roles, taskTypes, setIsCreatingTask, dispatch }) => {
    const api = useApi()
    const client = useClient()
    const config = useConfig()

    const [isPosting, setIsPosting] = useState(false)
    const [hasPosted, setHasPosted] = useState(false)
    const [hasErrored, setHasErrored] = useState(false)

    const [taskType, setTaskType] = useState()
    const [assignedUsers, setAssignedUsers] = useState([])
    const [assignedRoles, setAssignedRoles] = useState([])
    const [taskDate, setTaskDate] = useState(new Date())

    const onCreateTaskClick = () => {
        setIsPosting(true)
        setHasPosted(false)
        setHasErrored(false)

        api.post(`${config.TASK_API_URL}/api/${client}/task`, {
            taskTypeReference: taskType.taskTypeReference,
            customerReference: customerRef,
            date: `${taskDate.toISOString().split('T')[0]}T00:00:00.000Z`,
            userIds: assignedUsers.map(user => user.value),
            roleIds: assignedRoles.map(role => role.value)
        })
            .then(_ => {
                dispatch({ type: "TASKS_INVALIDATED" })
                setIsPosting(false)
                setHasPosted(true)
            })
            .catch(error => {
                console.error(error)
                setIsPosting(false)
                setHasErrored(true)
            })
    }

    return (
        <Loading isLoading={isPosting || !config.TASK_API_URL}>
            <div className="d-flex mb-2">
                <h2 className="no-select mb-1 me-2">New Customer Task</h2>
                <IconButton
                    className="ms-auto"
                    label=""
                    colour="grey"
                    iconClasses="fal fa-times"
                    onClick={() => setIsCreatingTask(false)}
                />
            </div>
            <div className="d-flex border-bottom my-2" />
            {
                hasPosted || hasErrored ?
                    <div className="d-flex flex-column align-items-center">
                        {
                            hasErrored ?
                                <h4 className="m-3">Task creation failed</h4> :
                                <h4 className="m-3">Task created successfully</h4>
                        }
                        <GreyButton
                            className="m-3"
                            label="Back"
                            iconClasses="fal fa-left"
                            onClick={() => setIsCreatingTask(false)}
                        />
                    </div> :
                    <div>
                        <div className="d-flex mt-3 mb-2 align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <i className="fal fa-tasks fa-xl" />
                                <h4 className="mx-2 m-0">Task Type</h4>
                            </div>
                            <Dropdown
                                className="ms-4"
                                placeholder="Please select a task type"
                                colour="grey"
                                options={taskTypes.map(tt => ({
                                    ...tt,
                                    label: tt.typeName,
                                    value: tt.taskTypeReference
                                }))}
                                onOptionSelected={setTaskType}
                            />
                        </div>
                        <div className="d-flex border-bottom my-3" />
                        <div className="d-flex mt-3 mb-2 align-items-start justify-content-between">
                            <div className="d-flex align-items-center">
                                <i className="fal fa-users fa-xl" />
                                <h4 className="mx-2 m-0">Assigned Users</h4>
                            </div>
                            <AutoCompleteList
                                placeholder="Assign a user"
                                options={users}
                                selectedOptions={assignedUsers}
                                onOptionsChanged={setAssignedUsers}
                            />
                        </div>
                        <div className="d-flex border-bottom my-3" />
                        <div className="d-flex mt-3 mb-2 align-items-start justify-content-between">
                            <div className="d-flex align-items-center">
                                <i className="fal fa-users fa-xl" />
                                <h4 className="mx-2 m-0">Assigned Roles</h4>
                            </div>
                            <AutoCompleteList
                                placeholder="Assign a role"
                                options={roles}
                                selectedOptions={assignedRoles}
                                onOptionsChanged={setAssignedRoles}
                            />
                        </div>
                        <div className="d-flex border-bottom my-3" />
                        <div className="d-flex align-items-center mt-3">
                            <i className="fa-solid fa-calendar-days fa-xl" />
                            <h4 className="mx-2 m-0">Due Date</h4>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <DatePicker locale={"en-GB"} selectedDate={taskDate} onChange={setTaskDate} />
                        </div>
                        <div className="d-flex border-bottom my-3" />
                        <div className="d-flex">
                            <div className="d-flex flex-grow-1" />
                            <GreyButton
                                className="ms-auto mt-2"
                                label="Create Task"
                                iconClasses="fal fa-plus"
                                disabled={(assignedUsers.length === 0 && assignedRoles.length === 0) || !taskType}
                                onClick={onCreateTaskClick}
                            />
                        </div>
                    </div>
            }
        </Loading>
    )
}

export default CreateTaskForm