import classes from './WidgetInput.module.scss'

type WidgetInputProps = { 
    className?: string
    placeholder: string 
    value: string
    isValid?: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onClick?: () => void
}
const WidgetInput = ({ className, placeholder, value, isValid = true, onChange, onClick }: WidgetInputProps) => {
    const onInputClick = () => {
        onClick && onClick()
    }
    
    return (
        <div className={`${className ? className : ''} d-flex`} onClick={onInputClick}>
            <div className="d-flex w-100 position-relative">
                <input 
                    type="text" 
                    className={`form-control ${classes.input} ${isValid ? '' : `${classes.invalid}`}`}
                    value={value} 
                    onChange={onChange} 
                    placeholder={placeholder}
                />
            </div>
        </div>
    )
}
export default WidgetInput