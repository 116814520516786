import { useEffect, useState } from "react"
import { useApi, useClient, Loading, DashboardTable, getConfig } from "invevo-react-components"

const TransactionType = ({ transactionTypeGroups, setTransactionTypeGroups }) => {
    const api = useApi()
    const client = useClient()
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)

    useEffect(() => {
        if (isFetching || hasFetched) {
            return
        }
        setIsFetching(true)
        getConfig().then(config => {
            api.get(`${config.COLLECT_API_URL}/api/${client}/transaction-type`)
                .then(response => {
                    setHasFetched(true)
                    setTransactionTypeGroups(response.data.map(d => { return { ...d, name: d.transactionType, isSelected: false } }))
                    setIsFetching(false)
                })
                .catch(error => console.error(error))
        })
    }, [api, client, hasFetched, isFetching, setTransactionTypeGroups])

    return (
        <div className="d-flex w-100 h-100 me-1">
            <Loading isLoading={isFetching} colour="blue">
                <DashboardTable
                    data={transactionTypeGroups}
                    setData={setTransactionTypeGroups}
                    isFetching={isFetching}
                    contentType="transType"
                    title="Transaction Type"
                />
            </Loading>
        </div>
    )
}

export default TransactionType
