import { authZ_Permissions } from '../constants';
import { hasPermission } from '../utility/authZ';

export const vaTypesList = (data) => {
  return data
    ? data.map((t) => ({
        value: t.id,
        label: t.name
      }))
    : [];
};

export const ledgersList = (data) => {
  return data
    ? data.map((l) => ({
        value: l.Id,
        label: l.Description
      }))
    : [];
};

export const brandsList = (data) => {
  return data
    ? data.map((b) => ({
        value: b.Id,
        label: b.Name
      }))
    : [];
};

export const hasVPEditPermission = (permissions) =>
  hasPermission(permissions, authZ_Permissions.VirtualAccountChildClone);
