import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLookup } from '../../api/lookupApi.js';
import { taskTypesSelectList } from '../../selectors/task';
import { find } from 'lodash';
import Select from 'react-select';
import { optionStyles } from '../../utility/selectSupport';
import { useClient } from 'invevo-react-components';

const TaskTypeSelect = ({
  id,
  onChange,
  getLookup,
  taskTypes,
  isDisabled = false
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const hasIdChanged = id !== (selectedOption || {}).value;
  const client = useClient()

  useEffect(() => {
    if (id && hasIdChanged) {
      const found = find(taskTypes, (o) => o.value === id);
      if (found) setSelectedOption(found);
    }
  }, [taskTypes, id, hasIdChanged]);

  useEffect(() => {
    getLookup(client, 'lookup/tasktypes', 'taskTypes');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectChange = (selected) => {
    setSelectedOption(selected);
    if (selected === null) {
      return onChange(null);
    }

    onChange(selected.value);
  };

  if (isDisabled) {
    return <h3 className='text-white'>{selectedOption ? selectedOption.label : ''}</h3>;
  }

  return (
    <Select
      value={selectedOption}
      options={taskTypes.filter((f) => f.isInUse)}
      onChange={onSelectChange}
      placeholder={'Please select'}
      styles={{
        option: optionStyles
      }}
      isDisabled={isDisabled}
    />
  );
};

TaskTypeSelect.propTypes = {
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  taskTypes: taskTypesSelectList(state.lookupReducer.taskTypes.results)
});

export default connect(mapStateToProps, { getLookup })(TaskTypeSelect);
