import { StandardButton, useApi, useClient, Loading, useConfig } from 'invevo-react-components'
import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Action from './Action'

const Actions = ({ state, dispatch }) => {
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    
    const api = useApi()
    const client = useClient()
    const config = useConfig()

    useEffect(() => {
        if (!config.CONNECT_API_URL || hasFetched || isFetching)
            return
        setIsFetching(true)

        api.get(`${config.CONNECT_API_URL}/api/${client}/sms-template`).then(response => {
            dispatch({
                type: "SMS_TEMPLATES_RETRIEVED",
                smsTemplates: response.data
            })

            setHasFetched(true)
            setIsFetching(false)
        })
            .catch(error => {
                console.error(error)
            })
    }, [api, client, isFetching, hasFetched, dispatch, config.CONNECT_API_URL])

    const onRemoveAction = (index) => {
        dispatch({
            type: "SELECTED_WORKFLOW_RULE_UPDATED",
            workflowRule: {
                ...state.selectedWorkflowRule,
                smsSendActions: state.selectedWorkflowRule.smsSendActions.filter((_, i) => index !== i)
            }
        })
    }

    const onActionChange = (newAction, index) => {
        const updatedActions = state.selectedWorkflowRule.smsSendActions.map((action, i) => {
            if (i !== index) {
                return action
            }
            return newAction
        })
        dispatch({ type: "SELECTED_WORKFLOW_RULE_UPDATED", workflowRule: { ...state.selectedWorkflowRule, smsSendActions: updatedActions } })
    }

    const addNewAction = () => {
        dispatch({
            type: "SELECTED_WORKFLOW_RULE_UPDATED",
            workflowRule: {
                ...state.selectedWorkflowRule,
                smsSendActions: [...state.selectedWorkflowRule.smsSendActions, {
                    reference: uuidv4(),
                    name: "Send SMS",
                    templateRef: "",
                    messageCountOption: 10,
                    perMinuteOption: 1,
                    customerFilters: [],
                    smsContactConfig: {
                        "option": "ANY",
                        "contactFilters": { fieldsAreInValues: [] }
                    }
                }]
            }
        })
    }

    const getFieldOptions = (state) => {
        return state.customerData.fieldConfigs.map((field, index) => {
            return {
                id: index,
                type: field.customerFieldType,
                fieldName: field.fieldName,
                displayName: field.displayName,
                fieldDataType: field.fieldDataType
            }
        })
    }


    const getAutocompleteFields = (state) => {
        return state.customerData.fieldConfigs.filter(f => f.isAvailableInAutoComplete).map(cd => cd.fieldName)
    }

    return (
        <div className="d-flex flex-column">
            <div className="d-flex justify-content-between">
                <h2 className="text-grey mb-0">Actions</h2>
                <div className="d-flex w-50 ms-auto">
                    <StandardButton className="ms-5 w-50 ms-auto" iconClasses="fal fa-plus" label="Add new action" onClick={() => addNewAction()} />
                </div>

            </div>
            {state.selectedWorkflowRule.smsSendActions &&
                <Loading isLoading={isFetching} colour="blue">
                    {state.selectedWorkflowRule.smsSendActions.map((action, index) =>
                        <Action
                            key={index}
                            action={action}
                            index={index}
                            customerFields={getFieldOptions(state)}
                            customerDataConfiguration={getAutocompleteFields(state)}
                            smsTemplates={state.smsTemplates}
                            onRemoveAction={onRemoveAction}
                            onActionChange={newAction => onActionChange(newAction, index)}
                        />
                    )}
                </Loading>
            }
        </div>
    )
}

export default Actions