export const actionTypes = {
    TASK_TYPES_RETRIEVED: "TASK_TYPES_RETRIEVED",
    TASK_TYPES_SAVED: "TASK_TYPES_SAVED",
    TASK_TYPE_ADDED: "TASK_TYPE_ADDED",
    TASK_TYPE_UPDATED: "TASK_TYPE_UPDATED",
    TASK_TYPE_DELETED: "TASK_TYPE_DELETED",
    TASK_COMPLETION_TYPES_RETRIEVED: "TASK_COMPLETION_TYPES_RETRIEVED",
    TASK_COMPLETION_TYPES_SAVED: "TASK_COMPLETION_TYPES_SAVED",
    TASK_COMPLETION_TYPE_ADDED: "TASK_COMPLETION_TYPE_ADDED",
    TASK_COMPLETION_TYPE_UPDATED: "TASK_COMPLETION_TYPE_UPDATED",
    TASK_COMPLETION_TYPE_DELETED: "TASK_COMPLETION_TYPE_DELETED",

    CUSTOMER_FIELDS_RETRIEVED: "CUSTOMER_FIELDS_RETRIEVED",
    TASKS_RETRIEVED: "TASKS_RETRIEVED",
    TASKS_INVALIDATED: "TASKS_INVALIDATED",
    TYPES_RETRIEVED: "TYPES_RETRIEVED",
    COMPLETION_TYPES_RETRIEVED: "COMPLETION_TYPES_RETRIEVED",
    USERS_RETRIEVED: "USERS_RETRIEVED",
    ROLES_RETRIEVED: "ROLES_RETRIEVED",
    TASK_SELECTED: "TASK_SELECTED",
    TASK_SAVED: "TASK_SAVED"
}