import React, { Component } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { findIndex } from 'lodash';
import { CommandCell } from '../../common/grids/commandCell';
import EditDeleteCommandCell from '../../common/grids/editDeleteCommandCell';
import { hasPermission } from '../../../utility/authZ';
import { authZ_Permissions } from '../../../constants';

export default class RuleActionsGrid extends Component {
  constructor(props, context) {
    super(props, context);

    this.ViewCommandCell = CommandCell({
      onCommandClick: this.onEditRowClicked,
      cssTag: 'View',
      buttonText: 'View'
    });

    this.EditDeleteCommandCell = EditDeleteCommandCell({
      onEditClick: this.onEditRowClicked,
      onDeleteClick: this.onDeleteRowClicked
    });
  }

  onDeleteRowClicked = (e, dataItem) => {
    e.preventDefault();

    const conditionIndex = findIndex(
      this.props.ruleGroup.actions,
      (r) => r.id === dataItem.id
    );

    const ruleGroup = {
      ...this.props.ruleGroup,
      actions: [
        ...this.props.ruleGroup.actions.slice(0, conditionIndex),
        ...this.props.ruleGroup.actions.slice(conditionIndex + 1)
      ]
    };

    this.props.onUpdateRuleGroup(ruleGroup);
  };

  onEditRowClicked = (e, dataItem) => {
    e.preventDefault();

    const ruleActionId = dataItem.id;

    this.props.onEditAction(ruleActionId);
  };

  getKey = () => 'rule-conditions-grid';

  render() {
    const isAdmin = hasPermission(
      this.props.permissions,
      authZ_Permissions.RuleGroupManagementAdmin
    );
    return (
      <Grid
        id={this.getKey()}
        key={this.getKey()}
        data={this.props.ruleGroup.actions}
      >
        <GridColumn
          field={'executionOrder'}
          title={'Execution Order'}
          width="100px"
        />
        <GridColumn field={'rule'} title={'Rule'} />
        <GridColumn field={'name'} title={'Action'} />
        <GridColumn field={'inhibitorType'} title={'Inhibitor Type'} />
        <GridColumn field={'inhibitForDays'} title={'Inhibitor Days'} />

        {isAdmin && (
          <GridColumn cell={this.EditDeleteCommandCell} width="170px" />
        )}

        {!isAdmin && <GridColumn cell={this.ViewCommandCell} width="170px" />}
      </Grid>
    );
  }
}
