import { useEffect, useState } from 'react'
import { Collapsable, Loading, useApi, useClient, useConfig } from 'invevo-react-components';
import WorkflowsHeader from './WorkflowsHeader'
import WorkflowCard from './WorkflowCard'
import classes from './Workflows.module.scss'
import moment from 'moment'
import { EverWeekendDayOption, EveryWorkingDayOption, getFilters, Weekend, WorkingDays } from '../../utils/Utils'

const Workflows = ({ state, dispatch }) => {
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const api = useApi()
    const client = useClient()
    const config = useConfig()

    useEffect(() => {
        if (isFetching || hasFetched || !config.AUTOMATE_API_URL) {
            return
        }

        setIsFetching(true)
        api.get(`${config.AUTOMATE_API_URL}/api/${client}/workflow-rules`)
        .then(response => {
                setHasFetched(true)
                dispatch({ type: 'WORKFLOW_RULES_RETRIEVED', workflowRules: mapWorkflows(response.data) })
                setIsFetching(false)
            })
            .catch(error => console.error(error))
    }, [api, client, config.AUTOMATE_API_URL, dispatch, hasFetched, isFetching])


    const onCreateClicked = () => {
        dispatch({ type: "NEW_WORKFLOW_RULE_REQUESTED" })
    }

    const onNewWorkflowRuleClose = (index, event) => {
        event.stopPropagation()
        var remainingConfigs = state.workflowRules.filter((_, i) => i !== index)
        dispatch({ type: "NEW_WORKFLOW_RULE_CLOSED", workflowRules: remainingConfigs })
    }

    const onWorkflowSelected = (rule) => {
        dispatch({ type: "WORKFLOW_RULE_SELECTED", workflowRule: rule })
    }

    return (
        <div className={`d-flex flex-column p-3 ${classes.container}`}>
            <WorkflowsHeader onCreateClick={state.isNewRequested ? (rule) => (rule) : onCreateClicked} />
            <Loading isLoading={isFetching} colour="blue">
                <Collapsable className="mt-4" title="ACTIVE WORKFLOWS" open={true} id="activeWorkflows">
                    <div className="d-flex flex-column">
                        {state.workflowRules.filter(wf => wf.status !== "archived").map((wfr, index) =>
                            <WorkflowCard
                                key={wfr.reference}
                                workflowRule={wfr}
                                isSelected={wfr.reference === state.selectedWorkflowRule.reference}
                                onWorkflowSelected={state.isNewRequested ? (rule) => (rule) : onWorkflowSelected}
                                isNew={state.isNewRequested}
                                onNewWorkflowRuleClose={(event) => onNewWorkflowRuleClose(index, event)}
                            />
                        )}
                        {state.workflowRules.filter(wf => wf.status !== "archived").length === 0 ? <span className="text-grey">You have no active workflows</span> : <></>}
                    </div>
                </Collapsable>
                <Collapsable className="mt-3" title="ARCHIVED WORKFLOWS" id="archivedWorkflows">
                    <div className="d-flex flex-column">
                        {state.workflowRules.filter(wf => wf.status === "archived").map(wfr =>
                            <WorkflowCard
                                key={wfr.reference}
                                workflowRule={wfr}
                                isSelected={wfr.reference === state.selectedWorkflowRule.reference}
                                onWorkflowSelected={onWorkflowSelected}
                            />
                        )}
                        {state.workflowRules.filter(wf => wf.status === "archived").length === 0 ? <span className="text-grey">You have no archived workflows</span> : <></>}
                    </div>
                </Collapsable>
            </Loading>
        </div>
    )
}

const onlyWorkingDays = (days) => {
    return days.length === 5 && days.filter(d => WorkingDays.includes(d)).length === 5
}

const onlyWeekends = (days) => {
    return days.length === 2 && days.filter(d => Weekend.includes(d)).length === 2
}

const mapWorkflows = (data) => {
    return data.map(workflow => {
        return {
            reference: workflow.reference,
            name: workflow.name,
            status: setWorkflowStatusFromApi(workflow.status),
            trigger: workflow.trigger === null ? undefined
                : {
                    ...workflow.trigger,
                    values: workflow.trigger.type === "WEEKDAY"
                        && onlyWorkingDays(workflow.trigger.values)
                        ? [EveryWorkingDayOption]
                        : onlyWeekends(workflow.trigger.values) ? [EverWeekendDayOption] : workflow.trigger.values,
                    hour: { id: workflow.trigger.hourToTrigger, value: `${workflow.trigger.hourToTrigger}` }
                },
            customerFilters: getFilters(workflow.customerFilters),
            smsSendActions: workflow.smsSendActions.map(a => {
                return {
                    ...a, smsContactConfig: {
                        ...a.smsContactConfig,
                        contactFilters: {
                            fieldsAreInValues: a.smsContactConfig.contactFilters.fieldsAreInValues.map(f => { return { ...f, value: f.values.join(','), isValid: true } })
                        }
                    },
                    customerFilters: getFilters(a.customerFilters),
                }
            }),
            createdBy: workflow.createdBy,
            creationDate: moment(workflow.createdDate).format('DD MMMM YYYY'),
            outputs: [],
            isNew: false
        }
    })
}

export default Workflows;

const setWorkflowStatusFromApi = (workflowStatus) => {
    switch (workflowStatus) {
        case 0: return 'on'
        case 1: return 'off'
        default: return 'archived'
    }
}