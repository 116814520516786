import { Toggle } from "invevo-react-components"
import DataField from "../../types/DataField"

type ColumnConfigFieldOptionProps = {
    dataField: DataField
    isEnabled: boolean
    onToggleState: (field: DataField) => void
}

const ColumnConfigFieldOption = ({ dataField, isEnabled, onToggleState }: ColumnConfigFieldOptionProps) => {
    const onToggleClicked = () => onToggleState(dataField)

    return (
        <div className="d-flex align-items-center p-2">
            <Toggle
                toggleColour="blue"
                status={isEnabled}
                onStatusChanged={onToggleClicked}
                ariaLabel={`${dataField.value}-toggle`}
            />
            <span
                className="ms-2 text-truncate fw-light no-select"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                aria-label={`column-config-${dataField.value}-value`}
                role="listitem"
                title={dataField.label}
            >
                {dataField.label}
            </span>
        </div>
    )
}

export default ColumnConfigFieldOption
