import { IconButton, Loading, useApi, useClient, useInvevoSession } from "invevo-react-components"
import React, { useEffect, useState } from "react"
import TransactionGridInfoBar from "./TransactionGridInfoBar"
import SelectionFilters from "./SelectionFilters"
import AddTransactionFilters from "../../transactionFilters/AddTransactionFilters"
import moment from "moment-timezone"
import { transactionAggregationTypes } from "../../../../enums/transactionAggregationTypes"
import { sortDirection } from "../../../../enums/sortDirection"
import classes from "./TransactionsDisplayWidget.module.scss"
import TransactionHistory from "../../transactionHistory/TransactionHistory"
import GlobalActions from "./GlobalActions"
import { convertToCurrency } from "../../../../../../library/helpers/numberUtils"
import Checkbox from "../../../../../../library/form/Checkbox"
import { useCustomer } from "../../../../../../contexts/CustomerContext"
import { useConfigLegacy } from "../../../../../../hooks/useConfigLegacy"
import { convertFromArrayToDto } from "../../../../../../library/FilterList/FiltersDto"
import { useFeatureToggle } from "../../../../../../hooks/useFeatureToggle"

const TransactionsDisplayWidget = ({ state, dispatch }) => {
    const { widget, customerRefs, transactionFields, columnConfig, dashboardsConfig, lookups, aggregations } = state
    const { isEnabled: defaultToThousand } = useFeatureToggle("defaultTransactionGridTo1000")
    const { isEnabled: seeDatesInUkTime } = useFeatureToggle("seeKafkaDataInUkTime")
    const { isEnabled: isClearDatesInKafkaTransactionGrid } = useFeatureToggle("permClearDatesInKafkaTransactionGrid")

    const api = useApi()
    const client = useClient()
    const config = useConfigLegacy()
    const [invevoSession] = useInvevoSession()

    const [isFetchingGrid, setIsFetchingGrid] = useState(false)
    const [hasFetchedGrid, setHasFetchedGrid] = useState(false)

    const [transactions, setTransactions] = useState([])
    const [lastTransactionForPage, setLastTransactionForPage] = useState({})
    const [firstTransaction, setFirstTransaction] = useState({})
    const [allTransactionRefs, setAllTransactionRefs] = useState([])

    const [sortByField, setSortByField] = useState("duedate")
    const [selectedSortDirection, setSelectedSortDirection] = useState(sortDirection.ASC)

    const [selectedTransactionsBillingValue, setSelectedTransactionsBillingValue] = useState([])

    const [currentPageSize, setCurrentPageSize] = useState(defaultToThousand ? 1000 : 100)
    const [totalTransactionCount, setTotalTransactionCount] = useState(0)
    const [totalBillingValue, setTotalBillingvalue] = useState([])
    const [customer, setCustomer] = useCustomer()

    const [expandedTransactions, setExpandedTransactions] = useState([])

    const [pageIndex, setPageIndex] = useState(0)

    const [isApplyingFilters, setIsApplyingFilters] = useState(false)

    const transactionFieldNames = transactionFields.map(t => t.fieldName)
    const availableColumnConfig = { ...columnConfig, displayedColumns: columnConfig.displayedColumns.filter(c => transactionFieldNames.includes(c)) }

    const currentDashboardConfig = dashboardsConfig.transactionsGridConfigs.find(d => d.transactionsGridRef === widget.reference)
    const avaliableDashboardConfig = {
        transactionsGridConfigs: currentDashboardConfig
            ? [
                  ...dashboardsConfig.transactionsGridConfigs.filter(d => d.transactionsGridRef !== widget.reference),
                  {
                      ...currentDashboardConfig,
                      displayedColumns: currentDashboardConfig?.displayedColumns?.filter(c => transactionFieldNames.includes(c))
                  }
              ]
            : []
    }

    useEffect(() => {
        const openAggAmount = aggregations?.openStatusAggregation?.find(agg => agg.label === "open")?.totalByCurrency
        if (openAggAmount === undefined || customer.transactionFilters.length > 0) return
        setCustomer({
            transactionFilters: [
                {
                    type: "OPEN_STATUS",
                    label: "open",
                    amountByCurrency: openAggAmount.map(a => {
                        return { ...a, currency: getCurrencyCode(a.currency) }
                    })
                }
            ]
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (customer.selectedTransactions && customer.selectedTransactions.length === 0) {
            setSelectedTransactionsBillingValue([])
        }
    }, [customer])

    const onCloseFilters = () => setIsApplyingFilters(false)

    const isTransactionSelected = transactionRef => customer.selectedTransactions.map(t => t.transactionRef).includes(transactionRef)

    const sortTransactions = fieldName => {
        if (isFetchingGrid) return
        const newSortDirection =
            fieldName !== sortByField.toLowerCase() ? sortDirection.ASC : selectedSortDirection === sortDirection.ASC ? sortDirection.DESC : sortDirection.ASC

        setSelectedSortDirection(newSortDirection)
        setSortByField(fieldName)
    }

    const getSortIconClassesForColumn = column => {
        if (column !== sortByField.toLowerCase()) return `${classes.noSort} fas fa-sort`
        if (selectedSortDirection === sortDirection.DESC) return "fa-duotone fa-sort fa-swap-opacity"
        return "fa-duotone fa-sort"
    }

    const onSelectAllClicked = () => {
        if (allTransactionRefs.length === customer.selectedTransactions.length) {
            setCustomer({ selectedTransactions: [] })
            setSelectedTransactionsBillingValue([])
            return
        }

        setCustomer({ selectedTransactions: allTransactionRefs })
        setSelectedTransactionsBillingValue(totalBillingValue)
    }

    const onTransactionSelected = transaction => {
        const currency = transaction.data["billingcurrency"]?.toLowerCase()
        const currentValue = selectedTransactionsBillingValue.find(a => a.currency === currency)?.value || 0

        if (customer.selectedTransactions.map(t => t.transactionRef).includes(transaction.transactionRef)) {
            const newTransactionsRefs = customer.selectedTransactions.filter(t => t.transactionRef !== transaction.transactionRef)
            setCustomer({ selectedTransactions: newTransactionsRefs })

            setSelectedTransactionsBillingValue([
                ...selectedTransactionsBillingValue.filter(a => a.currency !== currency),
                { currency: currency, value: currentValue - Number(transaction.data["currentbillingvalue"]) }
            ])
            return
        }

        const newTransactionsRefs = [
            ...customer.selectedTransactions,
            { transactionRef: transaction.transactionRef, customerRef: transaction.data["customerreference"] }
        ]
        setCustomer({ selectedTransactions: newTransactionsRefs })

        setSelectedTransactionsBillingValue([
            ...selectedTransactionsBillingValue.filter(a => a.currency !== currency),
            { currency: currency, value: currentValue + Number(transaction.data["currentbillingvalue"]) }
        ])
    }

    const getSearchAfterForFirstTransaction = (sortByField, type) => {
        const sortStartFrom = firstTransaction.data[sortByField || "duedate"] || null
        return type === "DATE" && sortStartFrom !== null ? moment(sortStartFrom).valueOf().toString() : sortStartFrom
    }

    const getDisplayNameForLookup = (lookup, data) =>
        lookups.find(l => l.reference === lookup)?.entries.find(e => e.reference.toLowerCase() === data?.toLowerCase())?.name || data

    const getProperFormatData = (column, data, currency) => {
        const dataType = transactionFields.find(a => a.fieldName === column)
        switch (dataType.type) {
            case "DATE":
                if (isClearDatesInKafkaTransactionGrid && Math.max(moment(data).valueOf(), 0) === 0) {
                    return ""
                }

                if (seeDatesInUkTime) {
                    const splitDate = data?.split("T")
                    return splitDate !== undefined ? moment(splitDate[0]).format("DD MMM yyyy") : ""
                }
                return data !== undefined ? moment(data).format("DD MMM yyyy") : ""
            case "LOOKUP":
                return getDisplayNameForLookup(dataType.lookup, data)?.toUpperCase()
            case "NUMBER":
                if (
                    column === "currentbillingvalue" ||
                    column === "currentreportingvalue" ||
                    column === "openingbillingvalue" ||
                    column === "openingreportingvalue"
                ) {
                    return convertToCurrency(parseFloat(data).toFixed(2), getCurrencyCode(currency))
                }
                return parseFloat(data).toFixed(0)
            case "BOOLEAN":
                return data?.toUpperCase()
            default:
                return column.includes("ref") ? data?.toUpperCase() : data?.toString()
        }
    }

    const getFormatDataForFields = (column, row) =>
        getProperFormatData(
            column,
            row.data[column],
            row.data[column === "openingreportingvalue" || column === "currentreportingvalue" ? "reportingcurrency" : "billingcurrency"]
        )

    const getFieldDisplayName = column => transactionFields.find(a => a.fieldName === column)?.displayName || column

    const getSearchAfter = (sortByField, type) => {
        const sortStartFrom = lastTransactionForPage[pageIndex - 1]?.data[sortByField || "dueDate"] || null
        return type === "DATE" && sortStartFrom !== null ? moment(sortStartFrom).valueOf().toString() : sortStartFrom
    }

    const getCurrencyCode = currency =>
        lookups
            .find(l => l.reference === "currency")
            ?.entries.find(e => e.reference === currency)
            ?.strings?.isocode?.toUpperCase() || currency?.toUpperCase()

    useEffect(() => {
        setHasFetchedGrid(false)
    }, [pageIndex, currentPageSize])

    useEffect(() => {
        setPageIndex(0)
        setLastTransactionForPage({})
        setFirstTransaction({})
        setHasFetchedGrid(false)
    }, [customer.transactionFilters, sortByField, selectedSortDirection, customer.advancedTransactionFilters])

    useEffect(() => {
        if (!config.SEARCH_API_URL || isFetchingGrid || hasFetchedGrid || transactionFields.length === 0) return

        setIsFetchingGrid(true)

        const sortType = transactionFields.find(a => a.fieldName === sortByField.toLowerCase()).type
        const postTransactionQuery = api
            .post(
                `${config.SEARCH_API_URL}/api/${client}/transaction-query`,
                createPostQueryDto(false, sortType, getSearchAfter(sortByField, sortType), lastTransactionForPage[pageIndex - 1]?.id || null)
            )
            .then(response => {
                setTransactions(response.data.pagedTransactions)
                setLastTransactionForPage({
                    ...lastTransactionForPage,
                    [pageIndex]: response.data.pagedTransactions.at(-1)
                })

                if (pageIndex === 0) {
                    setFirstTransaction(response.data.pagedTransactions[0])
                }

                setTotalBillingvalue(response.data.totalCurrentBillingValues)
                setTotalTransactionCount(response.data.totalCount)
            })

        const postTransactionRefsQuery = api
            .post(`${config.SEARCH_API_URL}/api/${client}/all-transaction-refs-query`, { filters: createAdvancedFiltersObject() })
            .then(response => {
                setAllTransactionRefs(response.data)
            })

        Promise.all([postTransactionQuery, postTransactionRefsQuery])
            .then(() => {
                setHasFetchedGrid(true)
                setIsFetchingGrid(false)
            })
            .catch(error => {
                console.error(error)
                setHasFetchedGrid(true)
                setIsFetchingGrid(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, client, config.SEARCH_API_URL, hasFetchedGrid, isFetchingGrid, customerRefs, transactionFields])

    const [transactionsWithDeltas, setTransactionsWithDeltas] = useState(transactions)
    useEffect(() => setTransactionsWithDeltas(transactions), [transactions])
    useEffect(() => {
        const transactionDeltas = transactionsWithDeltas.map(t => {
            const delta = invevoSession?.stateDeltas?.transactions?.find(delta => t.transactionRef === delta.transactionRef)
            if (delta === undefined) return t
            return {
                ...t,
                data: {
                    ...t.data,
                    ...delta
                }
            }
        })
        setTransactionsWithDeltas(transactionDeltas)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invevoSession.stateDeltas?.transactions])

    const onExpandClicked = transactionRef => {
        expandedTransactions.includes(transactionRef)
            ? setExpandedTransactions(expandedTransactions.filter(t => t !== transactionRef))
            : setExpandedTransactions([...expandedTransactions, transactionRef])
    }

    const createAdvancedFiltersObject = () => {
        const legacyQuickFilters = getFiltersForRequest(customer.transactionFilters)

        const closedFilter = legacyQuickFilters.filterEquals.find(f => f.fieldName === "closed")
        const quickBoolFilters = [
            closedFilter ? { fieldName: "closed", equalTo: closedFilter.fieldValueOptions[0].toLowerCase() === "true" } : undefined
        ].filter(f => f !== undefined)

        const statusFilter = legacyQuickFilters.filterEquals.find(f => f.fieldName === "status")
        const typeFilter = legacyQuickFilters.filterEquals.find(f => f.fieldName === "type")
        const uniqueTransactionRefFilter = legacyQuickFilters.filterEquals.find(f => f.fieldName === "reference")
        const transactionRefFilter = legacyQuickFilters.filterEquals.find(f => f.fieldName === "transactionref")
        const quickTextFilters = [
            { fieldName: "customerreference", values: customerRefs, notOneOf: false },
            statusFilter ? { fieldName: "status", values: statusFilter.fieldValueOptions, notOneOf: false } : undefined,
            typeFilter ? { fieldName: "type", values: typeFilter.fieldValueOptions, notOneOf: false } : undefined,
            uniqueTransactionRefFilter ? { fieldName: "reference", values: uniqueTransactionRefFilter.fieldValueOptions, notOneOf: false } : undefined,
            transactionRefFilter ? { fieldName: "transactionref", values: transactionRefFilter.fieldValueOptions, notOneOf: false } : undefined
        ].filter(f => f !== undefined)

        const convertDateValue = date => moment(Number(date)).format()

        const dueDateFilters = legacyQuickFilters.filterRanges.filter(f => f.fieldName === "duedate")
        const dueDateFilter =
            dueDateFilters.length > 0
                ? {
                      fieldName: "duedate",
                      values: dueDateFilters.map(t => {
                          return {
                              ...(t.lessThan && { lessThanOrEqualTo: convertDateValue(t.lessThan) }),
                              ...(t.greaterThan && { greaterThan: convertDateValue(t.greaterThan) })
                          }
                      })
                  }
                : undefined

        const issueDateFilters = legacyQuickFilters.filterRanges.filter(f => f.fieldName === "issueddate")
        const issueDateFilter =
            issueDateFilters.length > 0
                ? {
                      fieldName: "issueddate",
                      values: issueDateFilters.map(t => {
                          return {
                              ...(t.lessThan && { lessThanOrEqualTo: convertDateValue(t.lessThan) }),
                              ...(t.greaterThan && { greaterThan: convertDateValue(t.greaterThan) })
                          }
                      })
                  }
                : undefined
        const quickDateFilters = [dueDateFilter, issueDateFilter].filter(f => f !== undefined)

        const advancedFilters = convertFromArrayToDto(customer.advancedTransactionFilters)

        return {
            textFieldIsOneOf: [...advancedFilters.textFieldIsOneOf, ...quickTextFilters],
            textFieldStartsWith: advancedFilters.textFieldStartsWith,
            booleanFieldIsEqualTo: [...advancedFilters.booleanFieldIsEqualTo, ...quickBoolFilters],
            numberFieldIsOneOf: advancedFilters.numberFieldIsOneOf,
            numberFieldMatchesOperation: advancedFilters.numberFieldMatchesOperation,
            dateFieldIsOneOf: advancedFilters.dateFieldIsOneOf,
            dateFieldMatchesOperation: [...advancedFilters.dateFieldMatchesOperation, ...quickDateFilters],
            fieldExists: advancedFilters.fieldExists
        }
    }

    const createPostQueryDto = (isForExport, sortType, sortBySearchAfterId, searchAfterId) => {
        return {
            pageSize: isForExport ? undefined : currentPageSize,
            searchAfterId,
            sortBy: {
                fieldName: sortByField,
                dataPrimitive: sortType,
                direction: selectedSortDirection,
                searchAfter: sortBySearchAfterId
            },
            filters: createAdvancedFiltersObject()
        }
    }

    return (
        <div className="d-flex flex-column text-grey overflow-auto h-100">
            <SelectionFilters setIsApplyingFilters={setIsApplyingFilters} aggregations={aggregations} getCurrencyCode={getCurrencyCode} />

            <TransactionGridInfoBar
                currentPageSize={currentPageSize}
                setCurrentPageSize={setCurrentPageSize}
                isFetchingGrid={isFetchingGrid}
                selectedTransactionsSummary={{
                    count: customer.selectedTransactions.length,
                    totalByCurrency: selectedTransactionsBillingValue
                }}
                transactionCount={totalTransactionCount}
                pageIndex={pageIndex}
                onPageIndexUpdated={setPageIndex}
            />
            <div className="d-flex flex-grow-1 overflow-auto h-100">
                <table className="h-100 w-100">
                    <tbody className="h-100 w-100">
                        <tr className={classes.head}>
                            <td className={`${classes.stickLeft} ${classes.headSide} p-3`}>
                                <Checkbox
                                    className={`${isFetchingGrid ? classes.notAllowed : ""}`}
                                    checkboxClasses={classes.customCheckboxes}
                                    onClick={onSelectAllClicked}
                                    isChecked={allTransactionRefs.length === customer.selectedTransactions.length}
                                    ariaLabel="select-all-transactions"
                                />
                            </td>
                            {availableColumnConfig.displayedColumns.map(column => (
                                <td key={column}>
                                    <div className="d-flex align-items-center pointer no-select me-3" onClick={() => sortTransactions(column)}>
                                        <span className="me-1 text-uppercase text-truncate fw-normal">{getFieldDisplayName(column)}</span>
                                        <i className={getSortIconClassesForColumn(column)}></i>
                                    </div>
                                </td>
                            ))}
                            <td className={`${classes.stickRight} ${classes.headSide}`}>
                                <GlobalActions
                                    selectedTransactions={customer.selectedTransactions}
                                    getTransactionsForExportDto={() => ({
                                        ...createPostQueryDto(
                                            true,
                                            transactionFields.find(a => a.fieldName === sortByField.toLowerCase()).type,
                                            getSearchAfterForFirstTransaction(
                                                sortByField,
                                                transactionFields.find(a => a.fieldName === sortByField.toLowerCase()).type
                                            ),
                                            undefined
                                        ),
                                        fields: availableColumnConfig.displayedColumns
                                    })}
                                    transactionFields={transactionFields}
                                    columnConfig={availableColumnConfig}
                                    clientUserDashboardConfigs={avaliableDashboardConfig}
                                    dispatch={dispatch}
                                />
                            </td>
                        </tr>
                        {isFetchingGrid ? (
                            <tr className={classes.background}>
                                <td colSpan={availableColumnConfig.displayedColumns.length + 2}>
                                    <Loading isLoading={true} colour="blue">
                                        <div className="d-flex flex-grow-1 p-5" />
                                    </Loading>
                                </td>
                            </tr>
                        ) : (
                            transactionsWithDeltas.map(row => (
                                <React.Fragment key={row.id}>
                                    <tr className={`${classes.tableRow} pointer`} role="row" aria-label="transaction-data-row">
                                        <td
                                            className={`${classes.stickLeft} ${
                                                isTransactionSelected(row.transactionRef) ? classes.selected : classes.notSelected
                                            } px-3 py-2`}
                                            onClick={() => onTransactionSelected(row)}
                                        >
                                            <Checkbox
                                                checkboxClasses={classes.customCheckboxes}
                                                onClick={() => onTransactionSelected(row)}
                                                isChecked={isTransactionSelected(row.transactionRef)}
                                                ariaLabel={`select-${row.id}`}
                                            />
                                        </td>
                                        {availableColumnConfig.displayedColumns.map(column => (
                                            <td
                                                onClick={() => onTransactionSelected(row)}
                                                key={column}
                                                className={`${isTransactionSelected(row.transactionRef) ? classes.selected : classes.notSelected} ${
                                                    classes.dataItem
                                                } fw-bold flex-grow-1 pe-3`}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                title={getFormatDataForFields(column, row)}
                                            >
                                                {getFormatDataForFields(column, row)}
                                            </td>
                                        ))}
                                        <td
                                            className={`${classes.stickRight} ${
                                                isTransactionSelected(row.transactionRef) ? classes.selected : classes.notSelected
                                            } justify-content-end`}
                                        >
                                            <div className="d-flex justify-content-end pe-3">
                                                <div className="ms-auto d-flex align-items-center">
                                                    <IconButton
                                                        size="small"
                                                        buttonClasses={classes.rowActionButton}
                                                        iconClasses={`ms-auto text-blue fa-light ${
                                                            expandedTransactions.includes(row.transactionRef) ? "fa-minus" : "fa-plus"
                                                        }  pointer`}
                                                        ariaLabel="expand-transaction-row"
                                                        onClick={() => onExpandClicked(row.transactionRef)}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    {expandedTransactions.includes(row.transactionRef) && (
                                        <tr role="row" aria-label="transaction-history">
                                            <td colSpan={availableColumnConfig.displayedColumns.length + 2}>
                                                <TransactionHistory transactionRef={row.transactionRef} />
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))
                        )}
                        <tr className={classes.background}>
                            <td className="h-100" colSpan={availableColumnConfig.displayedColumns.length + 2}>
                                {transactionsWithDeltas.length === 0 && !isFetchingGrid && (
                                    <h3 className="d-flex justify-content-center">No transactions found matching your search criteria</h3>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <TransactionGridInfoBar
                currentPageSize={currentPageSize}
                setCurrentPageSize={setCurrentPageSize}
                isFetchingGrid={isFetchingGrid}
                selectedTransactionsSummary={{
                    count: customer.selectedTransactions.length,
                    totalByCurrency: selectedTransactionsBillingValue
                }}
                transactionCount={totalTransactionCount}
                pageIndex={pageIndex}
                onPageIndexUpdated={setPageIndex}
            />
            {isApplyingFilters && (
                <AddTransactionFilters
                    onClose={onCloseFilters}
                    state={{
                        aggregations: aggregations,
                        isFetchingGrid,
                        customerRefs
                    }}
                    getCurrencyCode={getCurrencyCode}
                    getDisplayNameForLookup={getDisplayNameForLookup}
                />
            )}
        </div>
    )
}

const getFiltersForRequest = transactionFilters => {
    if (transactionFilters.some(a => a.type === "TRANSACTION_REFERENCE")) {
        return {
            filterRanges: [],
            filterEquals: [
                {
                    fieldName: "transactionref",
                    fieldValueOptions: transactionFilters.filter(a => a.type === "TRANSACTION_REFERENCE").map(a => a.label),
                    dataPrimitive: "TEXT"
                }
            ]
        }
    }

    if (transactionFilters.some(a => a.type === "UNIQUE_TRANSACTION_REFERENCE")) {
        return {
            filterRanges: [],
            filterEquals: [
                {
                    fieldName: "reference",
                    fieldValueOptions: transactionFilters.filter(a => a.type === "UNIQUE_TRANSACTION_REFERENCE").map(a => a.label),
                    dataPrimitive: "TEXT"
                }
            ]
        }
    }

    let filterEquals = []

    if (transactionFilters.some(a => a.type === transactionAggregationTypes.TRANSACTION_STATUS)) {
        filterEquals = [
            ...filterEquals,
            {
                fieldName: "status",
                fieldValueOptions: transactionFilters.filter(a => a.type === transactionAggregationTypes.TRANSACTION_STATUS).map(a => a.label),
                dataPrimitive: "TEXT"
            },
            {
                fieldName: "closed",
                fieldValueOptions: ["false"],
                dataPrimitive: "BOOLEAN"
            }
        ]
    }
    if (transactionFilters.some(a => a.type === transactionAggregationTypes.TRANSACTION_TYPE)) {
        filterEquals = [
            ...filterEquals,
            {
                fieldName: "type",
                fieldValueOptions: transactionFilters.filter(a => a.type === transactionAggregationTypes.TRANSACTION_TYPE).map(a => a.label),
                dataPrimitive: "TEXT"
            },
            {
                fieldName: "closed",
                fieldValueOptions: ["false"],
                dataPrimitive: "BOOLEAN"
            }
        ]
    }

    if (filterEquals.length === 0 && transactionFilters.some(a => a.type === "OPEN_STATUS")) {
        filterEquals = [
            {
                fieldName: "closed",
                fieldValueOptions: transactionFilters.filter(a => a.type === "OPEN_STATUS").map(a => (a.label === "closed" ? "true" : "false")),
                dataPrimitive: "BOOLEAN"
            }
        ]
    }

    // Default case
    if (filterEquals.length === 0) {
        filterEquals = [
            {
                fieldName: "closed",
                fieldValueOptions: ["false"],
                dataPrimitive: "BOOLEAN"
            }
        ]
    }

    const ageDebtAggs = transactionFilters.filter(a => a.type === transactionAggregationTypes.AGED_DEBT).map(a => getAgedDebtFilterRange(a.label))
    const fallingDueAggs = transactionFilters.filter(a => a.type === transactionAggregationTypes.FALLING_DUE).map(a => getFallingDueFilterRange(a.label))
    const recentPaymentsAggs = transactionFilters
        .filter(a => a.type === transactionAggregationTypes.RECENT_PAYMENTS)
        .map(a => getRecentPaymentsFilterRange(a.label))

    if (ageDebtAggs.length > 0 || fallingDueAggs.length > 0) {
        filterEquals = [
            ...filterEquals.filter(f => f.fieldName !== "closed"),
            {
                fieldName: "closed",
                fieldValueOptions: ["false"],
                dataPrimitive: "BOOLEAN"
            }
        ]
    }

    if (recentPaymentsAggs.length > 0) {
        filterEquals = [
            ...filterEquals.filter(f => f.fieldName !== "closed"),
            {
                fieldName: "closed",
                fieldValueOptions: ["true"],
                dataPrimitive: "BOOLEAN"
            }
        ]
    }
    return {
        filterRanges: [...ageDebtAggs, ...recentPaymentsAggs, ...fallingDueAggs],
        filterEquals: filterEquals
    }
}

const getAgedDebtFilterRange = agedDebtLabel => {
    switch (agedDebtLabel) {
        case "91+ Days":
            return {
                fieldName: "duedate",
                dataPrimitive: "DATE",
                lessThan: moment().subtract(91, "day").startOf("day").format("x")
            }
        case "61 - 90 Days":
            return {
                fieldName: "duedate",
                dataPrimitive: "DATE",
                greaterThan: moment().subtract(90, "day").startOf("day").format("x"),
                lessThan: moment().subtract(61, "day").startOf("day").format("x")
            }
        case "31 - 60 Days":
            return {
                fieldName: "duedate",
                dataPrimitive: "DATE",
                greaterThan: moment().subtract(60, "day").startOf("day").format("x"),
                lessThan: moment().subtract(31, "day").startOf("day").format("x")
            }
        case "1 - 30 Days":
            return {
                fieldName: "duedate",
                dataPrimitive: "DATE",
                greaterThan: moment().subtract(30, "day").startOf("day").format("x"),
                lessThan: moment().subtract(1, "day").startOf("day").format("x")
            }
        case "Current":
            return {
                fieldName: "duedate",
                dataPrimitive: "DATE",
                greaterThan: moment().startOf("day").format("x")
            }
        default:
            return {
                fieldName: "duedate",
                dataPrimitive: "DATE",
                greaterThan: moment().subtract(30, "day").startOf("day").format("x"),
                lessThan: moment().startOf("day").format("x")
            }
    }
}

const getFallingDueFilterRange = fallingDueLabel => {
    if (fallingDueLabel === "Future") {
        return {
            fieldName: "duedate",
            dataPrimitive: "DATE",
            greaterThan: moment().add(4, "month").startOf("month").startOf("day").format("x")
        }
    }

    if (fallingDueLabel === "Overdue") {
        return {
            fieldName: "duedate",
            dataPrimitive: "DATE",
            lessThan: moment().startOf("day").format("x")
        }
    }

    return {
        fieldName: "duedate",
        dataPrimitive: "DATE",
        greaterThan: moment(fallingDueLabel, "MMM YYYY").startOf("month").startOf("day").format("x"),
        lessThan: moment(fallingDueLabel, "MMM YYYY").endOf("month").endOf("day").format("x")
    }
}

const getRecentPaymentsFilterRange = recentPaymentsLabel => {
    if (recentPaymentsLabel === "Historic") {
        return {
            fieldName: "issueddate",
            dataPrimitive: "DATE",
            lessThan: moment().subtract(3, "month").startOf("month").startOf("day").format("x")
        }
    }

    const recentPaymentsDate = moment(recentPaymentsLabel, "MMM YYYY")
    if (recentPaymentsDate.month() === moment().month()) {
        return {
            fieldName: "issueddate",
            dataPrimitive: "DATE",
            greaterThan: moment(recentPaymentsLabel, "MMM YYYY").startOf("month").startOf("day").format("x"),
            lessThan: moment().add(1, "day").startOf("day").format("x")
        }
    }

    return {
        fieldName: "issueddate",
        dataPrimitive: "DATE",
        greaterThan: moment(recentPaymentsLabel, "MMM YYYY").startOf("month").startOf("day").format("x"),
        lessThan: moment(recentPaymentsLabel, "MMM YYYY").add(1, "month").startOf("month").startOf("day").format("x")
    }
}

export default TransactionsDisplayWidget
