import { IconButton } from 'invevo-react-components'

const WorkflowsHeader = ({ onCreateClick }) => {
    return (
        <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
                <div className="d-flex ">
                    <h3 className="text-grey no-select fw-light mb-0">Workflow</h3>
                </div>
                <div className="d-flex ms-auto">
                    <IconButton className="me-2" iconClasses="fal fa-plus" onClick={onCreateClick} />
                    <IconButton iconClasses="fal fa-filter" />
                </div>
            </div>
        </div>

    )
}

export default WorkflowsHeader;
