import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "@fortawesome/fontawesome-pro/css/all.css"
import "./index.css"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import configureStore from "./microfrontends/legacy/configureStore"
import ReactDOM from "react-dom"
import RedirectRouteLoader from "./library/auth/RedirectRouteLoader"
import { ApiProvider, ConfigProvider, InvevoSessionProvider, UserProvider, ClientProvider, FeatureToggled } from "invevo-react-components"
import { getConfig } from "./library/config/helpers"
import axios from "axios"
import Authenticated from "./library/auth/Authenticated"
import { EnvironmentConfigProvider } from "./contexts/EnvironmentConfigContext"
import { AuthProvider } from "./contexts/AuthContext"
import * as Sentry from "@sentry/react"

const store: any = configureStore()

const startApp = (createAppFunc: any) => {
    getConfig().then(config => {
        Sentry.init({
            dsn: "https://7dd22697c4f731a0a82d351e8ea41892@o4506677778448384.ingest.sentry.io/4506677785264128",
            environment: config.INVEVO_ORIGIN === "invevo.io" ? "production" : "qa",
            integrations: [
                new Sentry.BrowserProfilingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false
                }),
                Sentry.browserTracingIntegration()
            ],
            tracesSampleRate: 1.0,
            tracePropagationTargets: [
                /(automate|data|platform|search|connect|integration|dashboard|user-roles|voip|entity-config).invevo-qa.com/,
                /(automate|data|platform|search|connect|integration|dashboard|user-roles|voip|entity-config).invevo.io/
            ],
            profilesSampleRate: 1.0,
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: 1.0
        })

        axios.defaults.baseURL = config.PLATFORM_API_URL

        const app = createAppFunc()

        return ReactDOM.render(
            <React.StrictMode>
                <Core ldClientId={config.LD_CLIENT_SIDE_ID} config={config}>
                    {app}
                </Core>
            </React.StrictMode>,
            document.getElementById("root")
        )
    })
}

startApp(() => <App store={store} />)

const Core = ({ children, ldClientId, config }: any) => {
    return (
        <BrowserRouter>
            <RedirectRouteLoader>
                <ConfigProvider config={config}>
                    <EnvironmentConfigProvider environmentConfig={config}>
                        <ClientProvider>
                            <Authenticated>
                                <AuthProvider>
                                    <ApiProvider>
                                        <UserProvider>
                                            <InvevoSessionProvider>
                                                <FeatureToggled clientId={ldClientId}>{children}</FeatureToggled>
                                            </InvevoSessionProvider>
                                        </UserProvider>
                                    </ApiProvider>
                                </AuthProvider>
                            </Authenticated>
                        </ClientProvider>
                    </EnvironmentConfigProvider>
                </ConfigProvider>
            </RedirectRouteLoader>
        </BrowserRouter>
    )
}
