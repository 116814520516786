import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Formsy from 'formsy-react';
import { connect } from 'react-redux';
import { authZ_Permissions, getApiUrl,GLOBAL_SETTINGS} from '../../legacy/constants';
import { addFileContentToRequest } from '../../legacy/utility/upload';
import {executeAuthAsyncGet,executeAuthAsyncPost,  executeAsyncResetGet} from '../../legacy/utility/asyncSupport';
import { distinctTemplateList } from '../../legacy/utility/distinctTemplateList';
import { webApiInterface } from '../../legacy/api/webApiInterface';
import { hasPermission } from '../../legacy/utility/authZ';
import { showToastSuccessMessage, showToastErrorMessage } from '../../legacy/api/toasterApi';
import ValidatingInput from '../../legacy/components/common/ValidatingInput';
import Dropdown from '../../legacy/components/common/dropdown';
import { find, isNil, last } from 'lodash';
import DocumentsGrid from '../../legacy/components/admin/post/documentsGrid';
import AddDocument from '../../legacy/components/admin/post/addDocument';

class PostTemplateManager extends Component {
  constructor(props) {
    super(props);

    this.editor = null;

    this.executeAuthAsyncGet = this.props.executeAuthAsyncGet;
    this.executeAuthAsyncPost = this.props.executeAuthAsyncPost;
    this.executeAsyncResetGet = this.props.executeAsyncResetGet;

    this.onTemplateLoaded = this.onTemplateLoaded.bind(this);
    this.onBrandLoaded = this.onBrandLoaded.bind(this);

    this.changePostTemplateName = this.changePostTemplateName.bind(this);
    this.onChangeHasPersonalisedContent = this.onChangeHasPersonalisedContent.bind(
      this
    );
    this.onChangeHasAttachedDocuments = this.onChangeHasAttachedDocuments.bind(
      this
    );
    this.onChangeHasAttachedTransactions = this.onChangeHasAttachedTransactions.bind(
      this
    );
    this.onDdlChangeTemplateName = this.onDdlChangeTemplateName.bind(this);
    this.onDdlChangedPostTemplateUsageTypeId = this.onDdlChangedPostTemplateUsageTypeId.bind(
      this
    );
    this.onDdlChangedBrandId = this.onDdlChangedBrandId.bind(this);
    this.export = this.export.bind(this);
    this.onFileSelected = this.onFileSelected.bind(this);
    this.loadPostTemplates = this.loadPostTemplates.bind(this);

    this.state = {
      templateName: null,
      postTemplateUsageTypeId: null,
      postTemplateName: '',
      brandInternalId: null,
      hasPersonalisedContent: false,
      hasAttachedDocuments: false,
      hasAttachedTransactions: false,
      templateList: [],
      documentId: null,
      addingDocument: false,
      templates: [],
    };
  }

  componentDidMount() {
    this.props.executeAsyncResetGet([
      'CUSTOMER_BRANDS_LIST',
      'POST_TEMPLATES_LIST',
      'POST_TEMPLATE_USAGE_TYPES'
    ]);
    this.props.executeAuthAsyncGet(
      this.props.client,
      'Lookup/TemplateUsageTypes',
      'POST_TEMPLATE_USAGE_TYPES'
    );
    this.loadPostTemplates();
    this.props.executeAuthAsyncGet(
      this.props.client,
      'Email/CustomerBrands',
      'CUSTOMER_BRANDS_LIST'
    );
  }

  loadPostTemplates(name) {
    const that = this;
    this.props.executeAuthAsyncGet(
      this.props.client,
      'Post/Templates',
      'POST_TEMPLATES_LIST',
      { brandInternalId: null },
      () => {
        const distinctResult = distinctTemplateList(this.props.POST_TEMPLATES_LIST.result);
        this.setState({ templates: distinctResult });
        if (!!name) {
          const template = find(that.state.distinctResult, {
            label: name
          });
          if (template) {
            that.onDdlChangeTemplateName(template.label);
          }
        }
      }
    );
  }

  onDdlChangedPostTemplateUsageTypeId(value) {
    this.setState({
      postTemplateUsageTypeId: value
    });
  }

  onTemplateLoaded() {
    document.forms.postForm.reset();
    this.setState({
      postTemplateName: this.state.templateName,
      templateList: this.props.POST_TEMPLATE.result,
      showFileInput: false
    });
    const selectedTemplate = this.decideTemplate(this.state.brandInternalId, this.state.templateName);
    this.setTemplateProperties(selectedTemplate);
  }

  onBrandLoaded(brandId) {
    const selectedTemplate = this.decideTemplate(brandId, this.state.templateName);
    this.setTemplateProperties(selectedTemplate);
  }

  onDdlChangeTemplateName(value) {
    if (value != null) {
      this.props.executeAuthAsyncGet(
        this.props.client,
        'Post/Templates',
        'POST_TEMPLATE',
        { name: value },
        this.onTemplateLoaded
      );
      this.setState({ templateName: value });
    }
  }

  onDdlChangedBrandId(value) {
    this.setState({
      brandInternalId: value
    });
    this.onBrandLoaded(value);
  }

  changePostTemplateName(event) {
    this.setState({ postTemplateName: event.target.value });
  }

  canSaveForm() {
    const hasTemplateName =
      !isNil(this.state.postTemplateName) &&
      this.state.postTemplateName.length > 0;
    const postTemplateUsageTypeId =
      !isNil(this.state.postTemplateUsageTypeId) &&
      this.state.postTemplateUsageTypeId > -1;
    return hasTemplateName && postTemplateUsageTypeId;
  }

  onChangeHasPersonalisedContent(event) {
    this.setState({
      hasPersonalisedContent: event.target.checked
    });
  }

  onChangeHasAttachedDocuments(event) {
    this.setState({
      hasAttachedDocuments: event.target.checked
    });
  }

  onChangeHasAttachedTransactions(event) {
    this.setState({
      hasAttachedTransactions: event.target.checked
    });
  }

  savePostTemplate = () => {
    const data = {
      id: null,
      name: this.state.postTemplateName,
      languageId: this.state.languageId,
      templateUsageTypeId: this.state.postTemplateUsageTypeId,
      brandInternalId: this.state.brandInternalId,
      hasPersonalisedContent: this.state.hasPersonalisedContent,
      hasAttachedDocuments: this.state.hasAttachedDocuments,
      hasAttachedTransactions: this.state.hasAttachedTransactions
    };

    this.props.executeAuthAsyncPost(
      this.props.client,
      'Post/Save',
      'SAVE_POST_TEMPLATE',
      data,
      () => {
        showToastSuccessMessage('Template saved successfully');
        this.setState({ addingDocument: false });
      }
    );
  };

  export() {
    const xhr = new XMLHttpRequest();
    const url = getApiUrl(this.props.client) + 'api/post-templates/export-all';

    xhr.open('GET', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(xhr.response);
      a.download = 'PostTemplateFile.json';
      a.click();
    };

    this.props.executeAuthAsyncGet(this.props.client, 'Lookup/OK', 'CHECK_OK', null, function () {
      webApiInterface.applySecurity(xhr);
      xhr.send(null);
    });
  }

  onFileSelected(event) {
    const self = this;
    event.preventDefault();
    event.stopPropagation();

    const file = event.target.files[0];
    const jsonExtension = file.name.indexOf('.json');

    if (jsonExtension === -1) {
      showToastErrorMessage('Please select a valid json file');
      return;
    }

    const reader = new FileReader();
    const url = getApiUrl(this.props.client) + 'api/post-templates/import-all';

    showToastSuccessMessage('Upload of file: ' + file.name + ' started');

    reader.onload = function (event) {
      if (
        event.currentTarget.result.length >
        GLOBAL_SETTINGS.max_upload_file_size * 1024 * 1024
      ) {
        showToastErrorMessage(
          'File is too big to be uploaded to the server. Please select another file.'
        );
        return;
      }

      if (event.currentTarget.result.length === 0) {
        showToastErrorMessage(
          'File has no content. Please select another file.'
        );
        return;
      }

      const XHR = new XMLHttpRequest();
      const data = addFileContentToRequest(
        'importAllPostTemplates',
        file.name,
        file.type,
        event.currentTarget.result
      );

      XHR.onreadystatechange = function () {
        if (XHR.readyState === 4) {
          if (XHR.status === 200) {
            const response = JSON.parse(XHR.response);

            if (response.result.length > 0) {
              showToastErrorMessage('Error uploading file');
            } else {
              showToastSuccessMessage('File uploaded');
              self.loadPostTemplates();
            }
          }
        }
      };

      XHR.open('POST', url);
      XHR.setRequestHeader(
        'Content-Type',
        'multipart/form-data; boundary=blob'
      );
      XHR.setRequestHeader('Access-Control-Allow-Origin', '*');
      this.props.executeAuthAsyncGet(
        this.props.client,
        'Lookup/OK',
        'CHECK_OK',
        null,
        function () {
          webApiInterface.applySecurity(XHR);
          XHR.send(data + '--blob--');
        }
      );
    }.bind(this);

    reader.readAsBinaryString(file);
  }

  documentUpdated = () => {
    const { templateName } = this.state;

    this.loadPostTemplates();
    this.onDdlChangeTemplateName(templateName);
  };

  showAddDocument = () => {
    this.setState({
      addingDocument: true
    });
  };

  addDocumentDone = () => {
    this.setState({
      addingDocument: false
    });

    this.documentUpdated();
  };

  decideTemplate = (brandId, templateName) => {
    return this.state.templateList.filter(template => template.brandInternalId === brandId && template.name === templateName)[0];
  }

  setTemplateProperties(selectedTemplate) {
    if (selectedTemplate) {
      this.setState({
        postTemplateUsageTypeId: selectedTemplate.templateUsageTypeId,
        hasPersonalisedContent: selectedTemplate.hasPersonalisedContent,
        hasAttachedDocuments: selectedTemplate.hasAttachedDocuments,
        hasAttachedTransactions: selectedTemplate.hasAttachedTransactions,
      })
    }
  }

  onDeleteDocument = (documentId) => {
    this.props.executeAuthAsyncPost(
      this.props.client,
      'Post/DeleteDocument',
      'DELETE_POST_TEMPLATE',
      documentId,
      () => {
        showToastSuccessMessage('Template Document removed.');
        this.documentUpdated();
      }
    );
  };

  renderAddDocument = () => {
    const button = (
      <button
        id="add-document-button"
        className="btn btn-default"
        type="button"
        onClick={this.showAddDocument}
      >
        Add Template Document
      </button>
    );
    const existingBrands = this.state.templateList.map(t => t.brandInternalId)
    const addableBrands = this.props.CUSTOMER_BRANDS_LIST.result.filter(
      (b) => existingBrands.some(d => d === b.Id))

    const addDocumentForm = (
      <div id="add-doc-container">
        <AddDocument
          client={this.props.client}
          languages={this.props.availableLanguages.results}
          brands={addableBrands}
          templateName={this.state.templateName}
          onUpdated={this.addDocumentDone}
          onCancel={this.addDocumentDone}
          decideTemplate={this.decideTemplate}
        />
      </div>
    );
    return this.state.addingDocument ? addDocumentForm : button;
  };

  render() {
    const { postTemplateUsageTypeId, brandInternalId, templateName, templates, templateList } = this.state;
    return (
      <div className="d-flex flex-column flex-grow-1">
        <h2>Post Templates</h2>
        {hasPermission(
          this.props.permissions,
          authZ_Permissions.LookupAdmin
        ) && (
            <div>
              <button
                className="btnDefault btn btn-default btnExport"
                onClick={this.export}
              >
                Export All
              </button>
              <label className="btnDefault btn btn-default">
                Import All
                <input
                  type="file"
                  name="importAll"
                  onChange={this.onFileSelected}
                  accept=".json"
                  style={{ display: 'none' }}
                />
              </label>

              <hr />
            </div>
          )}
        <div className="below-grid-form">
          <div className="form-group" style={{ paddingTop: '10px' }}>
            <Dropdown
              title="Template"
              idProp="label"
              textProp="label"
              selectedItemId={templateName}
              data={templates}
              onClick={(id) => this.onDdlChangeTemplateName(id)}
            />
          </div>

          <Formsy
            id="postForm"
            onSubmit={this.onFormSubmit}
            ref={(me) => (this.postForm = me)}
          >
            <div className="form-group">
              <Dropdown
                title="Brand"
                idProp="Id"
                textProp="Name"
                selectedItemId={brandInternalId}
                data={this.props.CUSTOMER_BRANDS_LIST.result}
                onClick={(id) => this.onDdlChangedBrandId(id)}
              />
            </div>
            <div className="form-group">
              <Dropdown
                title="Usage"
                idProp="Id"
                textProp="Description"
                selectedItemId={postTemplateUsageTypeId}
                data={this.props.POST_TEMPLATE_USAGE_TYPES.result}
                onClick={(id) => this.onDdlChangedPostTemplateUsageTypeId(id)}
                required={true}
              />
            </div>
            <div className="admin-pages">
              <ValidatingInput
                hideLabel={true}
                required
                name="postTemplateName"
                placeholder="Template name"
                value={this.state.postTemplateName}
                validations={{ minLength: 1, maxLength: 255 }}
                validationError="You must enter a valid template name."
                onChange={this.changePostTemplateName}
              />
            </div>
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    id="hasPersonalisedContent"
                    name="hasPersonalisedContent"
                    checked={this.state.hasPersonalisedContent}
                    onChange={this.onChangeHasPersonalisedContent}
                  />{' '}
                  Include Personalised Content
                </label>
              </div>
            </div>
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    id="hasAttachedDocuments"
                    name="hasAttachedDocuments"
                    checked={this.state.hasAttachedDocuments}
                    onChange={this.onChangeHasAttachedDocuments}
                  />{' '}
                  Include Attached Documents
                </label>
              </div>
            </div>
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    id="hasAttachedTransactions"
                    name="hasAttachedTransactions"
                    checked={this.state.hasAttachedTransactions}
                    onChange={this.onChangeHasAttachedTransactions}
                  />{' '}
                  Include Attached Transactions
                </label>
              </div>
            </div>
            <div className="form-group">
              <button
                className="btnDefault btn btn-default"
                type="button"
                disabled={!this.canSaveForm()}
                onClick={this.savePostTemplate}
              >
                Save Template
              </button>
            </div>
          </Formsy>
          {['Mia', 'MiaLocalSingle', 'MiaLocalBoth'].indexOf(
            this.props.globalConfiguration.postProvider
          ) >= 0 ? (
            <div>
              {!this.state.templateName ? (
                <div>
                  <hr />
                  <p>
                    <i>Template documents can be added after saving</i>
                  </p>
                </div>
              ) : (
                <div>
                  <hr />
                  <h3>Template Documents</h3>
                  <DocumentsGrid
                    templateList={templateList}
                    brands={this.props.CUSTOMER_BRANDS_LIST.result}
                    onUpdated={this.documentUpdated}
                    onDeleteDocument={this.onDeleteDocument}
                  />
                  <br />
                  {this.renderAddDocument()}
                </div>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { asyncReducer, lookupReducer } = state;
  const {
    POST_TEMPLATES_LIST,
    POST_TEMPLATE,
    SAVE_POST_TEMPLATE,
    POST_TEMPLATE_USAGE_TYPES,
    CUSTOMER_BRANDS_LIST,
    DELETE_POST_TEMPLATE
  } = asyncReducer;
  const { availableLanguages } = lookupReducer;

  let configuration = {};

  if (state.globalConfiguration.length > 0) {
    configuration = last(state.globalConfiguration);
  }

  return {
    POST_TEMPLATES_LIST,
    POST_TEMPLATE,
    SAVE_POST_TEMPLATE,
    POST_TEMPLATE_USAGE_TYPES,
    CUSTOMER_BRANDS_LIST,
    DELETE_POST_TEMPLATE,
    availableLanguages,
    permissions: state.authReducer.permissions,
    globalConfiguration: configuration
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    executeAuthAsyncGet,
    executeAuthAsyncPost,
    executeAsyncResetGet
  })(PostTemplateManager)
);
