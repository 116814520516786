
import Dropdown from "../../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../../types/DropdownOptions"
import classes from "./Pagination.module.scss"

type Props = {
    page: number
    pageSize: number
    totalAcrossPages: number
    pageSizeOptions?: number[]
    setPage: (page:number) => void
    setPageSize: (page:number) => void
}

const Pagination = ({page, pageSize, totalAcrossPages,pageSizeOptions = [10, 20, 50, 100, 250, 1000], setPage, setPageSize }: Props) => {

    const totalPageNumber = Math.ceil(totalAcrossPages / pageSize)

    const lastItemOnPageCount = Math.min(page * pageSize + pageSize, totalAcrossPages)
    const firstItemOnPageCount = lastItemOnPageCount === 0 ? 0 : page * pageSize + 1

    const trySetPageIndex = (newPageIndex: number) => {
        if (newPageIndex < 0 || newPageIndex > totalPageNumber - 1 || newPageIndex === page) return
        onPageIndexChanged(newPageIndex)
    }

    const pageSizeDropdownOptions = pageSizeOptions.map(pageSizeOption => ({ value: pageSizeOption, label: pageSizeOption.toString() }))

    const onPageIndexChanged = (newPageIndex: number) => setPage(newPageIndex)
    const onPageSizeOptionSelected = (option: DropdownOption<number>) =>setPageSize(option.value)
    const onGoToFirstPageClicked = () => trySetPageIndex(0)
    const onGoToNextPageClicked = () => trySetPageIndex(page + 1)
    const onGoToPreviousPageClicked = () => trySetPageIndex(page - 1)

    return (
        <div className={`d-flex flex-column flex-grow-1 align-items-center mt-2 gap-2`}>
            <div className="d-flex align-items-center me-auto">
                <Dropdown
                    options={pageSizeDropdownOptions}
                    onOptionSelected={onPageSizeOptionSelected}
                    selectedOption={{ label: pageSize.toString(), value: pageSize }}
                    direction="up"
                    ariaLabel="Page size"
                />
                <span className="ms-2 me-2">items per page</span>
            </div>
            <div className="d-flex ms-auto">
                <span>
                    {`Showing results: `}
                    <strong>{firstItemOnPageCount}</strong>
                    {` - `}
                    <strong>{lastItemOnPageCount}</strong>
                    {` of `}
                    <strong>{totalAcrossPages}</strong>
                </span>
                <div className="d-flex align-items-center border rounded">
                    <i
                        className={`fal fa-chevron-double-left px-2 py-1 border-end ${page === 0 ? classes.disabled : "pointer"}`}
                        aria-label="first"
                        onClick={onGoToFirstPageClicked}
                    />
                    <i
                        className={`fal fa-chevron-left px-2 py-1 border-end ${page === 0 ? classes.disabled : "pointer"}`}
                        aria-label="previous"
                        onClick={onGoToPreviousPageClicked}
                    />
                    <span className="mx-2">
                        <strong>{page + 1}</strong> of <strong>{totalPageNumber > 0 ? totalPageNumber : 1}</strong>
                    </span>
                    <i
                        className={`fal fa-chevron-right px-2 py-1 border-start ${
                            page === totalPageNumber - 1 ? classes.disabled : "pointer"
                        }`}
                        aria-label="next"
                        onClick={onGoToNextPageClicked}
                    />
                </div> 
            </div>
        </div>
    )
}

export default Pagination
