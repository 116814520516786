import classes from './Filters.module.scss'
import FilterRules from './FilterRules'
import { IconButton, LogicOperatorOptions, StandardButton } from 'invevo-react-components'

export const Filters = ({
    className,
    rules,
    onRuleChange,
    onAddRule,
    onRemoveRule,
    onRemoveAll,
    fieldOptions = [],
    fieldsAvailableForAutoComplete = [],
    inputType = "normal",
    autoCompleteUrl,
    title = "Filters",
    enableTooltip = false,
    scrollable = false
}) => {
    return (
        <div className={`d-flex w-100 ${className ? className : ''}`}>
            <div className="d-flex bg-grey flex-grow-1">
                <div className={`d-flex my-2 px-3 ${classes['left-side']}`}>
                    <i className="fal fa-grip-lines fs-5"></i>
                </div>
                <div className="d-flex flex-grow-1 flex-column">
                    <div className={`d-flex flex-grow-1 my-2 ms-2 me-3 pb-2 ${classes.header}`}>
                        <div className="d-flex ms-2 align-items-center">
                            <i className={`fal fa-filter me-3 fs-5 ${classes['header-icon']}`}></i>
                            <span className="text-black fs-5">{title}</span>
                        </div>

                        <div className="d-flex ms-auto">
                            <StandardButton className={`${classes['add-rule-button']} py-2 pe-3`} iconClasses="fal fa-plus" label="ADD RULE" colour="grey" onClick={onAddRule} />
                            <IconButton className="py-2 ms-3" iconClasses="fal fa-trash-alt" onClick={onRemoveAll} />
                        </div>
                    </div>
                    <div className="d-flex flex-column mt-2 mb-3 ms-2 me-3">
                        {rules.length === 0 &&
                            <div className="d-flex align-items-center text-grey">
                                <i className="mx-2 fal fa-exclamation-circle"></i>
                                <h6 className="mb-0">You currently have no rules defined</h6>
                            </div>
                        }
                        <FilterRules
                            rules={rules}
                            onRuleChange={onRuleChange}
                            onRemoveRule={onRemoveRule}
                            fieldOptions={fieldOptions}
                            inputType={inputType}
                            autoCompleteUrl={autoCompleteUrl}
                            fieldsAvailableForAutoComplete={fieldsAvailableForAutoComplete}
                            enableTooltip={enableTooltip}
                            scrollable={scrollable}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export const filterTypes = [{
    id: 1,
    label: "Value is in",
    value: LogicOperatorOptions.IN
}, {
    id: 2,
    label: "Value is not in",
    value: LogicOperatorOptions.NOT_IN
},
{
    id: 3,
    label: "Value is greater than",
    value: LogicOperatorOptions.GREATER_THAN
}, {
    id: 4,
    label: "Value is less than",
    value: LogicOperatorOptions.LESS_THAN
}, {
    id: 5,
    label: "Value is greater than or equal to",
    value: LogicOperatorOptions.GREATER_THAN_OR_EQUAL_TO
}, {
    id: 6,
    label: "Value is less than or equal to",
    value: LogicOperatorOptions.LESS_THAN_OR_EQUAL_TO
}, {
    id: 7,
    label: "Value range is",
    value: LogicOperatorOptions.RANGE
}, {
    id: 8,
    label: "Date value is in",
    value: LogicOperatorOptions.DATE_IS_IN
}]

export const textFilterTypes = [{
    id: 1,
    label: "Value is in",
    value: LogicOperatorOptions.IN
}, {
    id: 2,
    label: "Value is not in",
    value: LogicOperatorOptions.NOT_IN
}]

export const numericFilterTypes = [{
    id: 1,
    label: "Value is in",
    value: LogicOperatorOptions.IN
}, {
    id: 2,
    label: "Value is not in",
    value: LogicOperatorOptions.NOT_IN
},
{
    id: 3,
    label: "Value is greater than",
    value: LogicOperatorOptions.GREATER_THAN
}, {
    id: 4,
    label: "Value is less than",
    value: LogicOperatorOptions.LESS_THAN
}, {
    id: 5,
    label: "Value is greater than or equal to",
    value: LogicOperatorOptions.GREATER_THAN_OR_EQUAL_TO
}, {
    id: 6,
    label: "Value is less than or equal to",
    value: LogicOperatorOptions.LESS_THAN_OR_EQUAL_TO
}, {
    id: 7,
    label: "Value range is",
    value: LogicOperatorOptions.RANGE
}]

export const dateFilterTypes = [{
    id: 8,
    label: "Date value is in",
    value: LogicOperatorOptions.DATE_IS_IN
}]