import { webApiInterface } from './webApiInterface';
import * as actions from '../actions/customerActions';
import { getApiUrl } from "../constants";

export const getAccountDetails = (client, customerId) => (dispatch) => {
  const url =
    getApiUrl(client) + `api/dynamiccolumn/alldynamicrecords?recordId=${customerId}`;

  dispatch(actions.getAccountDetails());

  return webApiInterface
    .authFetch(client, url, dispatch)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      dispatch(actions.receiveAccountDetails(json));
      return json;
    })
    .catch(function (err) {
      dispatch(actions.receiveAccountDetailsError(err));
    });
};

export const setAccountValue = (client, customerId, fieldId, value) => (dispatch) => {
  const parameters = { id: fieldId, recordId: customerId, value: value };
  const url = getApiUrl(client) + 'api/dynamiccolumn/dynamicrecord/update';

  return webApiInterface
    .authPost(client, url, dispatch, parameters)
    .then(function (response) {
      return response.json();
    });
};
