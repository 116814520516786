import React, { useRef, useState, useEffect } from "react"
import classes from "./RoleSelection.module.scss"
import { Toggle } from "invevo-react-components"
import { actionTypes } from "../../reducers/actionTypes"
import { FixedSizeList } from "react-window"

const RoleSelection = ({ state, dispatch }) => {
    const { roles } = state
    const ref = useRef()

    const [isOpen, setIsOpen] = useState(false)

    const handleClickOutsideComponent = e => {
        if (ref && ref.current) {
            if (!ref.current.contains(e.target)) {
                setIsOpen(false)
            }
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutsideComponent)
        return () => document.removeEventListener("click", handleClickOutsideComponent)
    }, [])

    const toggleShown = () => setIsOpen(!isOpen)

    const toggleRole = roleId => dispatch({ type: actionTypes.ROLE_TOGGLED, roleId })

    const selectedRoleCount = roles.filter(role => role.isEnabled).length

    const userRoles = roles.filter(r => !r.isAdmin)

    return (
        <div ref={ref} className={`d-flex flex-grow-1 position-relative flex-column no-select ${classes["role-selection"]}`}>
            <div
                className={`d-flex px-3 py-2 align-items-center justify-content-between pointer ${classes["role-selection-button"]} ${
                    isOpen ? `border-bottom ${classes.open}` : ""
                }`}
                onClick={toggleShown}
            >
                {selectedRoleCount} Selected Role{selectedRoleCount === 1 ? " " : "s"}
                <i className={`fas fa-chevron-right icon ${isOpen ? "fa-rotate-90" : ""}`} />
            </div>
            <div className="d-flex w-100">
                <div className={`position-absolute w-100 text-grey ${classes.options} ${isOpen ? classes.open : ""}`}>
                    <div className="d-flex align-items-center mt-2 mb-1 px-3">
                        <i className="fs-5 fal fa-user-crown text-purple"></i>
                        <span className="ms-2">Admin roles</span>
                    </div>
                    {roles.filter(r => r.isAdmin).length > 0 ? (
                        roles
                            .filter(r => r.isAdmin)
                            .map(r => {
                                return (
                                    <div key={r.id} className="d-flex p-2 pointer align-items-center" onClick={() => toggleRole(r.id)}>
                                        <Toggle className="mt-1" status={r.isEnabled} onStatusChanged={() => {}} toggleColour="purple" />
                                        <span className="d-flex flex-grow-1 mt-1 ms-2">{r.name}</span>
                                    </div>
                                )
                            })
                            .reduce((prev, curr) => [prev, <span key={`${prev}${curr}`} className="my-2" />, curr])
                    ) : (
                        <div className="d-flex">
                            <span className="mx-auto mt-3">No admin roles available</span>
                        </div>
                    )}

                    <div className="d-flex align-items-center mt-2 pt-2 mb-1 px-3 border-top">
                        <i className="fs-5 fal fa-id-card-alt text-blue"></i>
                        <span className="ms-2">User roles</span>
                    </div>
                    {userRoles.length > 0 ? (
                        <FixedSizeList height={350} itemSize={45} width={"100%"} itemCount={userRoles.length}>
                            {({ index, style }) => {
                                const role = userRoles[index]
                                return (
                                    <div key={role.id} style={style} className="d-flex p-2 pointer align-items-center" onClick={() => toggleRole(role.id)}>
                                        <Toggle className="mt-1" status={role.isEnabled} onStatusChanged={() => {}} toggleColour="blue" />
                                        <span className="d-flex flex-grow-1 mt-1 ms-2">{role.name}</span>
                                    </div>
                                )
                            }}
                        </FixedSizeList>
                    ) : (
                        <div className="d-flex">
                            <span className="mx-auto mt-3">No roles available</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default RoleSelection
