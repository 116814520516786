import { transactionAggregationTypes } from "../../../enums/transactionAggregationTypes"

export const getTitleFromWidgetConfig = label => {
    switch (label) {
        case transactionAggregationTypes.AGED_DEBT: return "Aged debt"
        case transactionAggregationTypes.FALLING_DUE: return "Falling due"
        case transactionAggregationTypes.RECENT_PAYMENTS: return "Recent payments"
        case transactionAggregationTypes.TRANSACTION_STATUS: return "Transaction status"
        case transactionAggregationTypes.TRANSACTION_TYPE: return "Transaction type"
        default: return "Aged debt"
    }
}

export const getBucketChartTypeFromAggs = (aggs, bucketChartType) => {
    switch (bucketChartType) {
        case transactionAggregationTypes.AGED_DEBT: return aggs.agedDebtAggregation
        case transactionAggregationTypes.FALLING_DUE: return aggs.fallingDueAggregation
        case transactionAggregationTypes.RECENT_PAYMENTS: return aggs.recentPaymentAggregation
        case transactionAggregationTypes.TRANSACTION_STATUS: return aggs.statusAggregation
        case transactionAggregationTypes.TRANSACTION_TYPE: return aggs.typeAggregation
        default: return []
    }
}

export const isDynamicData = data => data.stringFields