import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { getAccountStatus, setAccountStatus } from "../../api/accountStatusApi"
import { showToastSuccessMessage, showToastErrorMessage } from "../../api/toasterApi"
import AccountStatusSelect from "./accountStatusSelect"
import { IconButton } from "../common/buttons/iconButton"
import CustomSwitch from "../common/buttons/customSwitch"
import AccountCard from "./accountCard"
import AccountDetail from "./accountDetail"
import { authZ_Permissions } from "../../constants"
import { hasPermission } from "../../utility/authZ"
import TextareaAutosize from "react-textarea-autosize"
import { useClient, useFeatureToggle } from "invevo-react-components"

const AccountStatus = ({
    title,
    accountId,
    isVirtualAccount,
    getAccountStatus,
    setAccountStatus,
    accountStatusData,
    accountStatus,
    accountStatuses,
    myPermissions,
    onUpdate
}) => {
    const client = useClient()
    // Component is not for virtual account
    const { isEnabled: disableStatusChangeFrom08Code } = useFeatureToggle("disableStatusChangeFrom08Code")

    const [editMode, setEditMode] = useState(false)
    const [statusForm, setStatusForm] = useState({
        customerId: 0,
        statusId: 0,
        NOS: false,
        AOS: false,
        noteText: "",
        isActive: false,
        oldStatusId: 0,
        oldIsActive: false,
        oldNOS: false,
        oldAOS: false
    })
    const [canSubmit, setCanSubmit] = useState(false)

    useEffect(() => {
        if (isVirtualAccount) return

        getAccountStatus(client, accountId)
    }, [getAccountStatus, accountId, isVirtualAccount, client])

    useEffect(() => {
        if (isVirtualAccount) return

        const { msg, isLoading } = accountStatusData
        if (msg && !isLoading) {
            if (msg.isError) {
                showToastErrorMessage(msg.message)
            } else {
                showToastSuccessMessage(msg.message)
            }
            getAccountStatus(client, accountId)
        }

        setStatusForm({
            customerId: accountId,
            statusId: accountStatusData.statusId,
            NOS: accountStatusData.NOS,
            AOS: accountStatusData.AOS,
            noteText: "",
            isActive: accountStatusData.isActive,
            oldStatusId: accountStatusData.statusId,
            oldIsActive: accountStatusData.isActive,
            oldNOS: accountStatusData.NOS,
            oldAOS: accountStatusData.AOS
        })
        setCanSubmit(false)
    }, [accountId, isVirtualAccount, accountStatusData, getAccountStatus, client])

    // form validation
    useEffect(() => {
        let isValid = false
        const { noteText, AOS, oldAOS, NOS, oldNOS, statusId, oldStatusId, isActive } = statusForm

        const isUnchanged = noteText === "" && AOS === oldAOS && NOS === oldNOS && statusId === oldStatusId

        if (!isUnchanged) isValid = true

        if (isValid) isValid = (!NOS && !AOS) || (!NOS && !isActive) || (!AOS && isActive)

        setCanSubmit(isValid)
    }, [statusForm])

    const onCheckChange = value => checked => {
        setStatusForm(prevStatusForm => ({
            ...prevStatusForm,
            [value]: checked
        }))
    }

    const onStatusChange = (statusId, isActive, statusLabel) => {
        setStatusForm(prevStatusForm => ({
            ...prevStatusForm,
            statusId,
            isActive,
            statusLabel
        }))
    }

    const onChangeText = e => {
        const noteText = e.target.value
        setStatusForm(prevStatusForm => ({
            ...prevStatusForm,
            noteText
        }))
    }

    const onSubmit = () => {
        setEditMode(false)

        setAccountStatus(client, statusForm).then(res => {
            if (!res.isError) {
                const found = accountStatuses.find(s => s.id === statusForm.statusId)
                onUpdate(found ? found.clientName : "Unknown")
            }
        })
    }

    const onToggleEdit = () => {
        setEditMode(!editMode)
        if (!editMode) {
            setStatusForm(prevStatusForm => ({
                ...prevStatusForm,
                statusId: prevStatusForm.oldStatusId,
                AOS: prevStatusForm.oldAOS,
                NOS: prevStatusForm.oldNOS,
                noteText: ""
            }))
        }
    }

    return isVirtualAccount ? null : (
        <AccountCard title={title} style={{ minHeight: "43px" }}>
            <AccountDetail>
                <div className="d-flex">
                    {editMode ? (
                        <div className="flex-grow-1 mr-3">
                            <AccountStatusSelect id={statusForm.statusId} onChange={onStatusChange} />
                        </div>
                    ) : (
                        <div className="flex-grow-1 mt-1">{accountStatus}</div>
                    )}

                    <div>
                        {hasPermission(myPermissions, authZ_Permissions.ChangeAccountStatus)
                            ? (!disableStatusChangeFrom08Code ||
                                  (client !== "tp" && client !== "tptest" && client !== "tpfinuat") ||
                                  (client === "tp" && statusForm.statusId !== 8) ||
                                  (client === "tptest" && statusForm.statusId !== 8) ||
                                  (client === "tpfinuat" && statusForm.statusId !== 8)) && (
                                  <IconButton onClick={onToggleEdit} title="Edit" icon={editMode ? "times-circle" : "edit"}></IconButton>
                              )
                            : null}
                    </div>
                </div>
                {editMode && (
                    <>
                        <div className="d-flex mt-2">
                            <div className="flex-grow-1">Never On Stop</div>
                            <div>
                                <CustomSwitch id="nos" onChange={onCheckChange("NOS")} checked={statusForm.NOS} />
                            </div>
                        </div>
                        <div className="d-flex mt-2">
                            <div className="flex-grow-1">Always On Stop</div>
                            <div>
                                <CustomSwitch id="aos" onChange={onCheckChange("AOS")} checked={statusForm.AOS} />
                            </div>
                        </div>
                        <TextareaAutosize value={statusForm.noteText} onChange={onChangeText} className={"mt-2 w-100"} minRows={2} />
                        <button onClick={onSubmit} className="btn btn-default mt-2 float-right" disabled={!canSubmit}>
                            Update
                        </button>
                    </>
                )}
            </AccountDetail>
        </AccountCard>
    )
}

const mapStateToProps = state => {
    return {
        accountStatuses: state.accountStatusReducer.accountStatusList,
        accountStatusData: state.accountStatusReducer.accountStatusData,
        accountId: state.currentSelectionReducer.selectedAccount.miaAccountId,
        isVirtualAccount: state.currentSelectionReducer.selectedAccount.miaAccountIsVirtualAccount,
        myPermissions: state.authReducer.permissions
    }
}
export default connect(mapStateToProps, {
    getAccountStatus,
    setAccountStatus
})(AccountStatus)
