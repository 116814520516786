import CardLabel from "./CardLabel"
import classes from "./PerformanceTargetCard.module.scss"

const PerformanceTargetCard = ({ performanceValue, metricText }) => {
    return (
        <div className={`d-flex mx-2 p-2 ${classes["container"]} w-100`}>
            <div className="d-flex flex-row w-100">
                <CardLabel value={performanceValue} text={metricText} colour="blue" />
            </div>
        </div>
    )
}

export default PerformanceTargetCard
