import { useState, useEffect } from 'react'
import { useApi, useClient, useConfig } from 'invevo-react-components'
import PaymentSelection from './PaymentSelection'
import PayOverdueAccountBalances from './PayOverdueAccountBalances'
import { useFeatureToggle } from '../../../hooks/useFeatureToggle'
import { useEntity } from '../../../contexts/EntityContext'
import useApiQuery from '../../../hooks/useApiQuery'
import EntityConfig from '../../../routes/entityConfig/types/EntityConfig'
import Loading from '../../../library/Loading/Loading'

type Props = {
    entityConfig: EntityConfig
}

const EntityPayActionPanel = ({ entityConfig }: Props) => {
    const [selectedTransactionIds, setSelectedTransactionIds] = useState<string[]>([])
    const [allTransactions, setAllTransactions] = useState<any[]>([])
    const [selectedTransactions, setSelectedTransactions] = useState<any[]>([])
    const [cardProviders, setCardProviders] = useState<any>([])
    const [hasFetchedInvoices, setHasFetchedInvoices] = useState(false)
    const [isFetchingCardProviders, setIsFetchingCardProviders] = useState(false)

    const { isEnabled: isCanPayWithoutTransactionsEnabled } = useFeatureToggle("payWithoutSpecifyingTransactions")

    const client = useClient()
    const api = useApi()
    const config = useConfig()
    const [entity] = useEntity()

    useEffect(() => {
        if (!allTransactions) {
            return
        }

        setSelectedTransactions(
            allTransactions
                .filter(t => selectedTransactionIds.includes(t.id))
                .filter(t => t.amountDue !== 0)
        )
    }, [allTransactions, selectedTransactionIds])

    const getTransactionIds = useApiQuery<string[]>({
        url: `${config.PAY_API_URL}/api/${client}/legacy-transaction-ids-from-transaction-refs`,
        method: "POST",
        isExecutedAutomatically: false
    })

    useEffect(() => {
        if (!entity.sqlId || !entity.selectedChildEntities || !config.PAY_API_URL) {
            return
        }

        const body = {
            customerId: entity.sqlId,
            transactionRefs: entity?.selectedChildEntities?.find(e => e.entityType.toLowerCase() === "transaction")?.references
        }

        getTransactionIds.execute(undefined, body)
            .then((response) => {
                setSelectedTransactionIds(response.data)
            })
            .catch((error: string) => console.error(error))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, client, config.PAY_API_URL, entity.sqlId, entity.selectedChildEntities])

    const getPortalTransactions = useApiQuery<string[]>({
        url: `${config.PAY_API_URL}/api/${client}/legacy-portal-transactions?customerId=${entity.sqlId}&isVirtualAccount=false`,
        method: "GET",
        isExecutedAutomatically: false
    })

    useEffect(() => {
        if (hasFetchedInvoices || !entity.sqlId || !config.PAY_API_URL) {
            return
        }

        getPortalTransactions.execute().then(response => {
            setAllTransactions(mapTransactionsFromApi(response.data))
            setHasFetchedInvoices(true)
        }, error => { throw error })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasFetchedInvoices, client, entity.sqlId, api, config.PAY_API_URL])

    useEffect(() => {
        if (isFetchingCardProviders || allTransactions.length === 0 || cardProviders.length > 0 || !config.PAY_API_URL) {
            return
        }

        const invoicesGroupedByCurrency = groupByKey(allTransactions, 'currencyCode')
        const currencyCodes = []
        for (const currencyCodeKey in invoicesGroupedByCurrency) {
            currencyCodes.push(currencyCodeKey)
        }

        setIsFetchingCardProviders(true)
        const getCardProviders = currencyCodes.map(cc => api.get(`${config.PAY_API_URL}/api/${client}/legacy-card-providers?customerId=${entity.sqlId}&currency=${cc}`))
        Promise.all(getCardProviders).then((responses) => {
            let cardProviders: any[] = [];
            responses.forEach((r: any) => {
                const currencyCode = [...r.config.url.split('=')].pop()
                cardProviders = [...cardProviders, ...r.data.cardProviders.map((provider: any) => ({
                    cardId: provider.cardId,
                    brand: provider.cardType,
                    paymentProviderId: provider.paymentProviderId,
                    currencyCode: currencyCode
                }))]
            })

            setCardProviders(cardProviders)
            setIsFetchingCardProviders(false)
        }, error => { throw error });
    }, [allTransactions, api, cardProviders.length, client, config.PAY_API_URL, entity.sqlId, isFetchingCardProviders])

    const mapTransactionsFromApi = (data: any) => {
        return data.rows.map((t: any) => {
            return {
                id: t.transactionId,
                transactionRef: t.transactionReference,
                currencyCode: t.billingCurrencyCode,
                amountDue: t.currentBillingValue,
                transactionDate: t.transactionDate,
                invoiceReference: t.transactionRef
            }
        })
    }

    const groupByKey = (list: any, key: any) => list.reduce((hash: any, obj: any) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {})

    const overdue = entity?.entityFields?.numberFields?.find(f => f.name === "number_overdue")?.value ?? 0
    const balance = entity?.entityFields?.numberFields?.find(f => f.name === "number_balance")?.value ?? 0
    const overdueFieldConfig = entityConfig.fields.find(field => (field.displayName = "number_overdue"))

    return (
        <div className="d-flex flex-column w-100 h-100 bg-blue p-4">
            {selectedTransactions.length === 1
                ? <h5 className="text-white">Pay {selectedTransactions[0].transactionRef} transaction</h5>
                : selectedTransactions.length > 1
                    ? <h5 className="text-white">Pay {selectedTransactions.length} selected transactions</h5>
                    : hasFetchedInvoices
                        ? isCanPayWithoutTransactionsEnabled && entity.sqlId
                            ? <Loading colour="white" isLoading={!hasFetchedInvoices || isFetchingCardProviders}>
                                <PayOverdueAccountBalances
                                    customerId={entity.sqlId}
                                    cardProviders={cardProviders}
                                    overdue={overdue}
                                    balance={balance}
                                    currencyCode={overdueFieldConfig?.dataType.currencyCode ?? "GBP"}
                                />
                            </Loading>
                            : <h5 className="text-white">Please select a transaction with an amount due to pay</h5>
                        : <>No transaction selected with an amount due to pay</>
            }
            {selectedTransactionIds.length > 0 &&
                <Loading colour="white" isLoading={!hasFetchedInvoices || isFetchingCardProviders}>
                    <PaymentSelection
                        customerId={entity.sqlId}
                        isCustomerVirtualAccount={false}
                        cardProviders={cardProviders}
                        transactions={selectedTransactions} />
                </Loading>
            }
        </div>
    )
}

export default EntityPayActionPanel