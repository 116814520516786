import NavigationButton from "../../../library/buttons/NavigationButton/NavigationButton"
import { useFeatureToggle } from "invevo-react-components"

const ConnectNav = () => {
    const { isEnabled: isVoipEnabled } = useFeatureToggle("invevoVoip")

    return (
        <div className="d-flex justify-content-center align-items-center w-100">
            <NavigationButton route="/home/connect/sms" icon="fa-regular fa-message-sms" ariaLabel="sms" colour="#ebe996" />
            {
                isVoipEnabled ? <NavigationButton route="/home/connect/voip" icon="fa-regular fa-phone-volume" ariaLabel="voip" colour="#b3dfe6" /> : ""
            }
        </div>
    )
}

export default ConnectNav