import update from 'immutability-helper';

const init = {
  isVirtualParent: false,
  name: '',
  account: '',
  vaTypeId: null,
  vaTypes: {
    isLoading: false,
    msg: '',
    data: null
  },
  vaBranches: null,
  vaCloned: false,
  vaClonedMsg: '',
  vaClonedErrMsg: '',
  updateVpType: false,
  updateVpMsg: '',
  updateVpErrMsg: '',
  vaRemoved: false,
  vaRemovedErrMsg: '',
  vaAdded: false,
  vaAddedErrMsg: '',
  virtualAccountId: null,
  vaUpdData: {
    Id: null,
    name: '',
    isCreditHolder: false,
    CanBeCreditHolder: false,
    languageId: null,
    virtualAccountTypeId: null,
    branchId: null,
    isLoading: false,
    msg: ''
  },
  refreshVpHierarchy: 0
};

const vpReducer = (state = init, action) => {
  switch (action.type) {
    case 'REQUEST_CLONE_TO_VP':
      return update(state, {
        vaCloned: { $set: false },
        vaClonedMsg: { $set: '' },
        vaClonedErrMsg: { $set: '' }
      });
    case 'RECEIVE_CLONE_TO_VP':
      return update(state, {
        vaCloned: { $set: true },
        vaTypeId: { $set: null },
        vaClonedMsg: { $set: action.json },
        vaClonedErrMsg: { $set: '' }
      });
    case 'ERROR_CLONE_TO_VP':
      return update(state, {
        vaCloned: { $set: false },
        vaClonedMsg: { $set: '' },
        vaClonedErrMsg: { $set: action.err }
      });
    case 'REQUEST_UPDATE_VP_TYPE':
      return update(state, {
        updateVp: { $set: false },
        updateVpMsg: { $set: '' },
        updateVpErrMsg: { $set: '' }
      });
    case 'RECEIVE_UPDATE_VP_TYPE':
      return update(state, {
        updateVp: { $set: true },
        updateVpMsg: { $set: action.json },
        updateVpErrMsg: { $set: '' }
      });
    case 'ERROR_UPDATE_VP_TYPE':
      return update(state, {
        updateVp: { $set: false },
        updateVpMsg: { $set: '' },
        updateVpErrMsg: { $set: action.err }
      });
    case 'REQUEST_VP_TYPES':
      return update(state, {
        vaTypes: {
          isLoading: { $set: true },
          msg: { $set: '' },
          data: { $set: null }
        }
      });
    case 'RECEIVE_VP_TYPES':
      return update(state, {
        vaTypes: {
          isLoading: { $set: false },
          msg: { $set: '' },
          data: { $set: action.data }
        }
      });
    case 'ERROR_VP_TYPES':
      return update(state, {
        vaTypes: {
          isLoading: { $set: false },
          msg: { $set: action.err },
          data: { $set: null }
        }
      });
    case 'SET_VA_TYPE_ID':
      return update(state, {
        vaTypeId: { $set: action.id }
      });
    case 'RESET_VA_CLONED':
      return update(state, {
        vaCloned: { $set: false },
        vaClonedMsg: { $set: '' },
        vaClonedErrMsg: { $set: '' }
      });
    case 'REQUEST_REMOVE_PARENT':
      return update(state, {
        vaRemoved: { $set: false }
      });
    case 'RECEIVE_REMOVE_PARENT':
      return update(state, {
        vaRemoved: { $set: true }
      });
    case 'ERROR_REMOVE_PARENT':
      return update(state, {
        vaRemoved: { $set: false },
        vaRemovedErrMsg: { $set: action.err }
      });
    case 'RESET_VA_REMOVED':
      return update(state, {
        vaRemoved: { $set: false }
      });
    case 'REQUEST_ADD_PARENT':
      return update(state, {
        vaAdded: { $set: false },
        vaAddedErrMsg: { $set: '' }
      });
    case 'RECEIVE_ADD_PARENT':
      return update(state, {
        vaAdded: { $set: true },
        vaAddedErrMsg: { $set: action.json }
      });
    case 'ERROR_ADD_PARENT':
      return update(state, {
        vaAdded: { $set: false },
        vaAddedErrMsg: { $set: action.err }
      });
    case 'RESET_VA_ADDED':
      return update(state, {
        vaAdded: { $set: false }
      });
    case 'SET_VIRTUAL_ACCOUNT_MODE':
      return update(state, {
        isVirtualParent: { $set: true },
        virtualAccountId: { $set: action.virtualAccountId },
        name: { $set: action.name },
        account: { $set: action.account }
      });
    case 'RESET_VIRTUAL_ACCOUNT_MODE':
      return update(state, {
        isVirtualParent: { $set: false },
        virtualAccountId: { $set: null },
        name: { $set: '' },
        account: { $set: '' }
      });
    case 'RECEIVE_UPD_VIRTUAL_ACCOUNT':
      const jsonResult = action.json;
      return update(state, {
        vaUpdData: {
          msg: { $set: jsonResult },
          isLoading: { $set: false }
        }
      });
    case 'REQUEST_UPD_VIRTUAL_ACCOUNT':
    case 'REQUEST_GET_VIRTUAL_ACCOUNT':
      return update(state, {
        vaUpdData: {
          isLoading: { $set: true }
        }
      });
    case 'RECEIVE_GET_VIRTUAL_ACCOUNT':
      const json = action.json;
      return update(state, {
        vaUpdData: {
          isLoading: { $set: false },
          Id: { $set: json.Id },
          name: { $set: json.Name },
          isCreditHolder: { $set: json.CreditHolder },
          CanBeCreditHolder: { $set: json.CanBeCreditHolder },
          languageId: { $set: json.PreferredLanguage },
          ledgerId: { $set: json.LedgerId },
          brandId: { $set: json.BrandId },
          virtualAccountTypeId: { $set: json.VirtualAccountTypeId },
          branchId: { $set: json.BranchId },
          msg: { $set: '' }
        },
        name: { $set: json.Name }
      });
    case 'ERROR_UPD_VIRTUAL_ACCOUNT':
    case 'ERROR_GET_VIRTUAL_ACCOUNT':
      return update(state, {
        vaUpdData: {
          isLoading: { $set: false },
          msg: { $set: action.err }
        }
      });
    case 'RECEIVE_GET_BRANCHES':
      return update(state, {
        vaBranches: { $set: action.json }
      });
    case 'REFRESH_VP_HIERARCHY':
      return update(state, {
        refreshVpHierarchy: { $set: state.refreshVpHierarchy + 1 }
      });
    default:
      return state;
  }
};

export default vpReducer;
