import { useEffect, useState } from "react"
import StandardButton from "../../buttons/StandardButton/StandardButton"
import { useGrid } from "./../Grid"
import RowData from "./../types/RowData"

type Props = {
    onSelected?: (data: RowData) => void
}

const PreviousNextButtons = ({ onSelected }: Props) => {
    const grid = useGrid()

    const singleSelectedReference = grid.selection.selectedRefs.length === 1 ? grid.selection.selectedRefs.at(0) : undefined
    const isFirst = grid.paging.pageIndex === 0 && grid.data.rows.at(0)?.reference === singleSelectedReference
    const isLast =
        grid.paging.pageIndex === Math.ceil(grid.data.totalAcrossPages / grid.paging.pageSize) - 1 &&
        grid.data.rows.at(-1)?.reference === singleSelectedReference

    const [goToNextOnLoad, setGoToNextOnLoad] = useState(false)
    const [goToPreviousOnLoad, setGoToPreviousOnLoad] = useState(false)

    const selectData = (data: RowData) => {
        grid.onSelect(
            {
                selectedRefs: [data.reference],
                isAllRowsSelected: false
            },
            false
        )

        if (!onSelected) return
        onSelected(data)
    }

    useEffect(() => {
        if (grid.data.rows.length === 0) return

        if (goToNextOnLoad) {
            const next = grid.data.rows.at(0)
            if (!next) return

            selectData(next)
            setGoToNextOnLoad(false)
            return
        }

        if (goToPreviousOnLoad) {
            const previous = grid.data.rows.at(-1)
            if (!previous) return

            selectData(previous)
            setGoToPreviousOnLoad(false)
            return
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid.data.rows])

    const selectPrevious = () => {
        const current = grid.data.rows.find(c => c.reference === singleSelectedReference)
        if (!current) return

        const currentIndex = grid.data.rows.indexOf(current)
        const isFirstOnCurrentPage = currentIndex === 0

        if (isFirstOnCurrentPage) {
            setGoToPreviousOnLoad(true)
            grid.setPaging(current => ({ pageIndex: current.pageIndex - 1, pageSize: current.pageSize }))
        } else {
            const previous = grid.data.rows.at(currentIndex - 1)
            if (!previous) return

            selectData(previous)
        }
    }

    const selectNext = () => {
        const current = grid.data.rows.find(c => c.reference === singleSelectedReference)
        if (!current) return

        const currentIndex = grid.data.rows.indexOf(current)
        const isLastOnCurrentPage = currentIndex === grid.data.rows.length - 1

        if (isLastOnCurrentPage) {
            setGoToNextOnLoad(true)
            grid.setPaging(current => ({ pageIndex: current.pageIndex + 1, pageSize: current.pageSize }))
        } else {
            const next = grid.data.rows.at(currentIndex + 1)
            if (!next) return

            selectData(next)
        }
    }

    const isSelectionInList = grid.data.rows.some(r => r.reference === singleSelectedReference)

    return (
        <div className="d-flex flex-grow-1 justify-content-between text-grey">
            <StandardButton
                iconClasses="far fa-chevron-left"
                label="Previous"
                onClick={selectPrevious}
                small={true}
                disabled={isFirst || !singleSelectedReference || !isSelectionInList}
                ariaLabel="previous-entity"
            />
            <StandardButton
                iconClasses="far fa-chevron-right"
                label="Next"
                onClick={selectNext}
                small={true}
                iconRight={true}
                disabled={isLast || !singleSelectedReference || !isSelectionInList}
                ariaLabel="next-entity"
            />
        </div>
    )
}

export default PreviousNextButtons
