import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import useClient from "../../../hooks/useClient"
import { webApiInterface } from "../../../microfrontends/legacy/api/webApiInterface"
import { getApiUrl } from "../../../microfrontends/legacy/constants"
import { DataTypeEnum } from "../../../routes/entityConfig/types/DataType"
import Grid from "../Grid"
import HeaderDefinition from "../types/HeaderDefinition"
import RowData from "../types/RowData"
import { useEnvConfig } from "../../../contexts/EnvironmentConfigContext"
import useApiQuery from "../../../hooks/useApiQuery"
import moment from "moment"
import DataPrimitive from "../../../types/DataPrimitive"
import { v4 as uuidv4 } from "uuid"

type InlineTransactionNotesGridProps = {
    customerReference: string
    transactionReference: string
}

type LegacyNote = {
    dateTime: number
    text: string
    userName: string
    noteType: string
    taskId?: string
    taskCompletion?: string
}

const InlineTransactionNotesGrid = ({ customerReference, transactionReference }: InlineTransactionNotesGridProps) => {
    const client = useClient()
    const dispatch = useDispatch()
    const [legacyFetching, setLegacyFetching] = useState(false)
    const [legacyFetched, setLegacyFetched] = useState(false)
    const [legacyErrored, setLegacyErrored] = useState(false)
    const [sqlNotes, setSqlNotes] = useState<RowData[]>([])
    const config = useEnvConfig()

    const fetchLegacyNotes = useApiQuery({
        url: `${config.DASHBOARD_API_URL}/api/${client}/legacy-transaction-notes`,
        method: "POST",
        isExecutedAutomatically: false
    })

    useEffect(() => {
        if (legacyFetching || legacyFetched) return
        setLegacyFetching(true)
        webApiInterface
            .authPost(client, `${getApiUrl(client)}api/customer/byref`, dispatch, { reference: customerReference })
            .then(response => response.json())
            .then(customer => {
                fetchLegacyNotes
                    .execute(undefined, {
                        pageIndex: 1,
                        pageSize: 1000,
                        customerId: customer.id,
                        transactionRef: transactionReference
                    })
                    .then(response => {
                        const responseData = (response.data as any).data as any[]
                        setSqlNotes(
                            responseData.map(d =>
                                convertSqlNoteToRowData({
                                    dateTime: d.dateTime,
                                    text: d.text,
                                    userName: d.userName,
                                    noteType: d.noteType,
                                    taskId: d.taskId,
                                    taskCompletion: d.completion
                                })
                            )
                        )
                    })
                    .then(_ => {
                        setLegacyFetched(true)
                        setLegacyFetching(false)
                        setLegacyErrored(false)
                    })
                    .catch(_ => {
                        setLegacyFetched(true)
                        setLegacyFetching(false)
                        setLegacyErrored(true)
                        setSqlNotes([])
                    })
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, dispatch, legacyFetched, legacyFetching])

    const convertSqlNoteToRowData = (note: LegacyNote): RowData => ({
        reference: uuidv4(),
        fields: {
            textFields: {
                user: note.userName,
                note: note.text,
                noteType: note.noteType,
                taskId: note.taskId ?? "",
                taskCompletion: note.taskCompletion ?? ""
            },
            booleanFields: {},
            numberFields: {},
            dateFields: {
                noteDate: moment.tz(note.dateTime, "Europe/London").valueOf()
            }
        }
    })

    const defaultHeaders: HeaderDefinition[] = [
        {
            value: "noteDate",
            label: "Note Date",
            isEditable: false,
            ordinal: 0,
            defaultOrdinal: 0,
            displayType: DataTypeEnum.DATE,
            format: "DD-MM-YYYY HH:mm",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        { value: "noteType", label: "Note type", isEditable: false, ordinal: 1, defaultOrdinal: 1, displayType: DataTypeEnum.TEXT },
        { value: "taskId", label: "Task Id", isEditable: false, ordinal: 2, defaultOrdinal: 2, displayType: DataTypeEnum.TEXT },
        { value: "user", label: "User", isEditable: false, ordinal: 3, defaultOrdinal: 3, displayType: DataTypeEnum.TEXT },
        { value: "taskCompletion", label: "Completion", isEditable: false, ordinal: 4, defaultOrdinal: 4, displayType: DataTypeEnum.TEXT },
        { value: "note", label: "Note", isEditable: false, ordinal: 5, defaultOrdinal: 5, displayType: DataTypeEnum.TEXT }
    ]

    return (
        <td className={"overflow-auto"} colSpan={10}>
            <Grid
                reference={`transaction-notes-${transactionReference}`}
                colour="grey"
                defaultPageSize={20}
                defaultSort={{ fieldName: "noteDate", dataPrimitive: DataPrimitive.DATE, direction: "DESC" }}
                shouldLoadData={legacyFetched && !legacyErrored && !legacyFetching}
            >
                <Grid.DataTable data={sqlNotes} defaultHeaders={defaultHeaders}>
                    <Grid.Rows showHeaderRow={false} canWrap={true} />
                </Grid.DataTable>
                <Grid.Footer>
                    <Grid.Pagination pageSizeOptions={[20]} isCompact />
                </Grid.Footer>
            </Grid>
        </td>
    )
}

export default InlineTransactionNotesGrid
