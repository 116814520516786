import { Alert, AutoCompleteList, Collapsable, DatePicker, Dropdown, Loading, ProgressButton, SelectableCard, StandardButton, useApi, useClient, useConfig } from 'invevo-react-components'
import { useEffect, useState } from 'react'
import { actionTypes } from '../../reducers/actionTypes'
import classes from './TaskCard.module.scss'

const TaskCardExpanded = ({ state, taskId, taskReadableId, taskRef, dispatch }) => {
    const api = useApi()
    const client = useClient()
    const config = useConfig()

    const [originalTask, setOriginalTask] = useState({})
    const [task, setTask] = useState({})
    const [originalTaskType, setOriginalTaskType] = useState({})
    const [taskType, setTaskType] = useState({})
    const [originalTaskCompletionType, setOriginalTaskCompletionType] = useState({})
    const [taskCompletionType, setTaskCompletionType] = useState({})
    const [originalAssignedUsers, setOriginalAssignedUsers] = useState([])
    const [assignedUsers, setAssignedUsers] = useState([])
    const [originalAssignedRoles, setOriginalAssignedRoles] = useState([])
    const [assignedRoles, setAssignedRoles] = useState([])

    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [hasErrored, setHasErrored] = useState(false)

    useEffect(() => {
        if (isFetching || hasFetched || hasErrored || !config.TASK_API_URL) return

        setIsFetching(true)

        api.get(`${config.TASK_API_URL}/api/${client}/task/${taskRef}`)
            .then(response => {
                setTask(response.data)
                setOriginalTask(response.data)

                const users = state.users.filter(u => response.data.userIds.includes(u.value))
                setAssignedUsers(users)
                setOriginalAssignedUsers(users)

                const roles = state.roles.filter(r => response.data.roleIds.includes(r.value))
                setAssignedRoles(roles)
                setOriginalAssignedRoles(roles)

                const taskType = state.taskTypes.find(tt => tt.taskTypeReference === response.data.taskTypeReference)
                setTaskType(taskType)
                setOriginalTaskType(taskType)

                const taskCompletionType = state.taskCompletionTypes.find(tct => tct.taskCompletionTypeReference === response.data.taskCompletionTypeReference)
                setTaskCompletionType(taskCompletionType)
                setOriginalTaskCompletionType(taskCompletionType)

                setHasFetched(true)
                setIsFetching(false)
                setHasErrored(false)
            }).catch(error => {
                setHasFetched(true)
                setIsFetching(false)
                setHasErrored(true)
                console.error(error)
            })
    }, [api, client, hasErrored, hasFetched, isFetching, state.taskTypes, state.users, state.roles, taskRef, state.taskCompletionTypes, config.TASK_API_URL])

    const onCloseClick = () => dispatch({ type: actionTypes.TASK_SELECTED, selectedTask: null })

    const taskDate = task?.date ? new Date(task.date.split('T')[0]) : undefined

    const onUpdateDate = newDate => setTask({ ...task, date: newDate.toISOString() })

    const taskTypeDropdownOptions = state.taskTypes.map(tt => ({
        ...tt,
        label: tt.typeName,
        value: tt.taskTypeReference
    }))

    const taskCompletionTypeDropdownOptions = state.taskCompletionTypes
        .filter(tct => taskType?.completionTypes && taskType.completionTypes.includes(tct.taskCompletionTypeReference))
        .map(tct => ({
            ...tct,
            label: tct.completionTypeName,
            value: tct.taskCompletionTypeReference
        }))

    const hasDataChanged =
        JSON.stringify(originalTask) !== JSON.stringify(task) ||
        JSON.stringify(originalTaskType) !== JSON.stringify(taskType) ||
        JSON.stringify(originalTaskCompletionType) !== JSON.stringify(taskCompletionType) ||
        JSON.stringify(originalAssignedUsers) !== JSON.stringify(assignedUsers) ||
        JSON.stringify(originalAssignedRoles) !== JSON.stringify(assignedRoles)

    const saveTaskPromise = () => api.put(
        `${config.TASK_API_URL}/api/${client}/task/${taskRef}`,
        {
            taskTypeReference: taskType.taskTypeReference,
            taskCompletionTypeReference: taskCompletionType?.taskCompletionTypeReference || null,
            customerReference: task.customerReference,
            date: task.date,
            roleIds: assignedRoles.map(r => r.value),
            userIds: assignedUsers.map(u => u.value)
        }
    ).then(_ =>
        dispatch({
            type: actionTypes.TASK_SAVED, updatedTask: {
                id: taskId,
                customerReference: task.customerReference,
                date: task.date,
                readableId: taskReadableId,
                taskReference: taskRef,
                taskTypeReference: taskType.taskTypeReference
            }
        })
    )

    return (
        config.TASK_API_URL &&
        <SelectableCard
            className={`mx-3 my-1 ${classes["shadow"]}`}
            body={(
                <Loading isLoading={isFetching || !hasFetched} >
                    {
                        hasErrored ?
                            <div className="d-flex justify-content-center">
                                <Alert className="m-3" type="error" iconClasses="fal fa-exclamation-triangle" text="Failed to get task" />
                            </div> :
                            <div>
                                <div className="d-flex mb-1">
                                    <span className="user-select-all pe-auto me-auto small" onClick={e => e.stopPropagation()}>#{task.readableId}</span>
                                    <h5>{task.customerReference}</h5>
                                </div>
                                <h6>{taskType ? taskType.typeName : "Unknown Task Type"}</h6>
                                <div className="mx-2 my-1">
                                    {
                                        task.customerFields && task.customerFields
                                            .map(cf => ({
                                                fieldName: state.customerFields.find(f => f.fieldName === cf.fieldName && f.type === cf.fieldType)?.displayValue,
                                                fieldValue: cf.fieldValue
                                            }))
                                            .filter(cf => cf.fieldName)
                                            .map((cf, index) => (
                                                <div key={index} className="d-flex align-items-center justify-content-between my-1">
                                                    <span className="small">{cf.fieldName}</span>
                                                    <span className="small">{cf.fieldValue}</span>
                                                </div>
                                            ))
                                    }
                                </div>
                                <div className="mt-2">
                                    {
                                        taskType ?
                                            <div className="d-flex flex-column align-items-start">
                                                <div className="d-flex align-items-center">
                                                    <i className="fal fa-tasks me-2" />
                                                    <h5 className="mb-0">Task Type</h5>
                                                </div>
                                                <Dropdown
                                                    className="mt-2"
                                                    placeholder="Task type"
                                                    colour="blue"
                                                    defaultOption={taskTypeDropdownOptions.find(tt => tt.taskTypeReference === taskType.taskTypeReference)}
                                                    options={taskTypeDropdownOptions}
                                                    onOptionSelected={setTaskType}
                                                />
                                            </div> :
                                            <span>Unknown Task Type</span>
                                    }
                                </div>
                                <hr />
                                <div className="d-flex align-items-center mt-3">
                                    <i className="fa-solid fa-calendar-days" />
                                    <h5 className="ms-2 m-0 me-4">Due Date</h5>
                                    <h5 className="mx-2 mb-0">{task.date && task.date.split('T')[0]}</h5>
                                </div>
                                <div className={classes.dropOver}>
                                    <Collapsable>
                                        <div className="d-flex justify-content-center mt-3">
                                            {
                                                taskDate && <DatePicker locale={"en-GB"} selectedDate={taskDate} onChange={onUpdateDate} />
                                            }
                                        </div>
                                    </Collapsable>
                                </div>
                                <hr />
                                <div className="d-flex align-items-center">
                                    <i className="fal fa-users" />
                                    <h5 className="mx-2 m-0">Assign</h5>
                                </div>
                                <AutoCompleteList
                                    className="mt-3"
                                    placeholder="Assign a user"
                                    options={state.users}
                                    colour="blue"
                                    selectedOptions={assignedUsers}
                                    onOptionsChanged={setAssignedUsers}
                                />
                                <AutoCompleteList
                                    className="mt-3"
                                    placeholder="Assign a role"
                                    options={state.roles}
                                    colour="blue"
                                    selectedOptions={assignedRoles}
                                    onOptionsChanged={setAssignedRoles}
                                />
                                <hr />
                                <div>
                                    <div className="d-flex flex-column align-items-start">
                                        <div className="d-flex align-items-center">
                                            <i className="fal fa-check me-2" />
                                            <h5 className="mb-0">Task Completion</h5>
                                        </div>
                                        {
                                            !task.taskCompletionTypeReference || taskCompletionType ?
                                                <Dropdown
                                                    className="mt-2"
                                                    placeholder="Task completion type"
                                                    colour="blue"
                                                    defaultOption={taskCompletionTypeDropdownOptions.find(tct => tct.taskCompletionTypeReference === taskCompletionType?.taskCompletionTypeReference)}
                                                    options={taskCompletionTypeDropdownOptions}
                                                    onOptionSelected={setTaskCompletionType}
                                                /> :
                                                <span>Unknown Task Completion Type</span>
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </Loading>
            )}
            footer={hasFetched && !hasErrored && (
                <div className="d-flex justify-content-between mt-2">
                    <StandardButton
                        iconClasses="fal fa-times"
                        label="Close"
                        colour="blue"
                        onClick={onCloseClick}
                    />
                    <ProgressButton
                        iconClasses="fal fa-save"
                        label="Save Task"
                        colour="blue"
                        succeededText="Saved successfully"
                        failedText="Failed to save"
                        onClickWithPromise={saveTaskPromise}
                        disabled={!hasDataChanged}
                    />
                </div>
            )}
            isSelected={true}
        />
    )
}

export default TaskCardExpanded