import { useClient, useApi, useConfig, Loading, StandardButton } from 'invevo-react-components'
import classes from './Actions.module.scss'
import { actionTypes } from '../../reducers/actionTypes'
import { useEffect, useState } from 'react'
import { fieldType } from '../../enums/fieldType'
import { dataPrimitive } from '../../enums/dataPrimitive'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import ProgressButton from '../../../../library/buttons/ProgressButton/ProgressButton'
import FileUploadButton from '../../../../library/buttons/FileUploadButton/FileUploadButton'

const DATA_FIELD_FILE_HEADERS = ["fieldName", "displayName", "dataPrimitive", "lookup", "isQuickFilter"]

const Actions = ({ state, dispatch, selectedLookupId }) => {
    const client = useClient()
    const api = useApi()
    const config = useConfig()
    const [lastFieldsSize, setLastFieldsSize] = useState()

    const findCurrentLookup = () => state.originalLookupDataFields.find(l => l.id === selectedLookupId)

    const onAddField = () => {
        switch (state.activeTab) {
            case fieldType.TRANSACTION: {
                dispatch({ type: actionTypes.DATA_TRANSACTION_FIELD_ADDED })
                break;
            }
            case fieldType.CUSTOMER: {
                dispatch({ type: actionTypes.DATA_CUSTOMER_FIELD_ADDED })
                break;
            }
            case fieldType.LOOKUP:
            default: {
                dispatch({ type: actionTypes.DATA_LOOKUP_ADDED })
                break
            }
        }
    }

    const onDownloadButtonClicked = () => {
        const currentLookup = findCurrentLookup()
        const content = currentLookup.entries.map(entry => {
            return currentLookup.fields.map(header => {
                if (header.field === "reference" || header.field === "name") return entry[header.field]
                else {
                    switch (header.dataPrimitive) {
                        case dataPrimitive.BOOLEAN: {
                            return entry.booleans[header.field]
                        }
                        case dataPrimitive.DATE: {
                            return entry.dateTimes[header.field]
                        }
                        case dataPrimitive.NUMBER: {
                            return entry.bigDecimals[header.field]
                        }
                        case dataPrimitive.TEXT:
                        default: {
                            return entry.strings[header.field]
                        }
                    }
                }
            }).join(',')
        }).join('\r\n')

        const header = currentLookup.fields.map(a => a.field).join(',')

        const url = window.URL.createObjectURL(new Blob([header.concat('\r\n').concat(content)]))
        const link = document.createElement("a")
        let filename = `${currentLookup.name}_lookup.csv`

        link.href = url
        link.setAttribute(
            "download",
            filename
        )
        document.body.appendChild(link)
        link.click()

        link.parentNode.removeChild(link)
    }

    const savePromise = () => {
        const hasDataFieldsBeenDeleted = state.originalCustomerDataFields.length > state.updatedCustomerDataFields.length ||
            state.originalTransactionDataFields.length > state.updatedTransactionDataFields.length

        const deleteRequests = hasDataFieldsBeenDeleted ?
            state.originalTransactionDataFields.filter(originalDataField => !state.updatedTransactionDataFields.includes(originalDataField))
                .concat(state.originalCustomerDataFields.filter(originalDataField => !state.updatedCustomerDataFields.includes(originalDataField)))
                .map(field => api.delete(
                    `${config.DATA_API_URL}/api/${client}/data-field/${field.fieldName}/?fieldType=${field.fieldType}`)
                ) : []

        const fields = state.updatedTransactionDataFields.filter(uf => !state.originalTransactionDataFields.includes(uf))
            .concat(state.updatedCustomerDataFields.filter(uf => !state.originalCustomerDataFields.includes(uf)))
            .concat(state.newCustomerDataFields)
            .concat(state.newTransactionDataFields)
            .map(field => ({ ...field, id: undefined, editable: undefined, isFromFile: undefined, lookup: field.lookup ? field.lookup : undefined }))

        const dataFieldRequest = fields.length > 0 ? [api.post(`${config.DATA_API_URL}/api/${client}/data-fields`, fields)] : []

        const lookupRequests = state.updatedLookupDataFields.filter(uf => !state.originalLookupDataFields.includes(uf))
            .map(lookupUpdated => (
                {
                    ...lookupUpdated,
                    fields: undefined,
                    id: undefined,
                    editable: undefined,
                    columns: lookupUpdated.fields.map(({ field, dataPrimitive }) => ({ name: field, dataPrimitive })),
                    reference: undefined,
                    entries: lookupUpdated.entries.filter(e => e.reference !== "").map(entry => (
                        {
                            ...entry,
                            editable: undefined,
                            id: undefined,
                            bigDecimals: Object.fromEntries(
                                Object.entries(entry.bigDecimals).map(([key, value]) => [key, parseFloat(value)])
                            )
                        })
                    )
                }))
            .map(lookupUpdated => api.put(
                `${config.DATA_API_URL}/api/${client}/lookup/${lookupUpdated.name}`,
                {
                    ...lookupUpdated
                }
            ))

        const returnPromise = hasDataFieldsBeenDeleted ? dataFieldRequest.concat(lookupRequests).concat(deleteRequests) : dataFieldRequest.concat(lookupRequests)

        return Promise.all(returnPromise).then(_ =>
            dispatch({ type: actionTypes.DATA_FIELDS_SAVED })
        )
    }

    const hasDataChanged =
        JSON.stringify(state.originalCustomerDataFields) !== JSON.stringify(state.updatedCustomerDataFields) ||
        JSON.stringify(state.originalTransactionDataFields) !== JSON.stringify(state.updatedTransactionDataFields) ||
        JSON.stringify(state.originalLookupDataFields) !== JSON.stringify(state.updatedLookupDataFields) ||
        state.newCustomerDataFields.length > 0 || state.newTransactionDataFields.length > 0

    useEffect(() => {
        if (state.activeTab === fieldType.TRANSACTION) {
            if (lastFieldsSize && lastFieldsSize !== state.updatedTransactionDataFields.length) {
                setLastFieldsSize(state.updatedTransactionDataFields.length)
            }
            if (!lastFieldsSize) {
                setLastFieldsSize(state.updatedTransactionDataFields.length)
            }
        } else {
            if (lastFieldsSize && lastFieldsSize !== state.updatedCustomerDataFields.length) {
                setLastFieldsSize(state.updatedCustomerDataFields.length)
            }
            if (!lastFieldsSize) {
                setLastFieldsSize(state.updatedCustomerDataFields.length)
            }
        }
    }, [state.activeTab, state.updatedTransactionDataFields, state.updatedCustomerDataFields, lastFieldsSize])

    const onLookupFileSelected = event => {
        const file = event.target.files[0]
        const reader = new FileReader()
        let entries = []

        reader.onload = e => {
            const lines = e.target.result.split('\r\n')
            const header = lines[0].split(',')
            const content = lines.slice(1)

            const originalLookup = findCurrentLookup()
            if (originalLookup.fields.filter(f => !header.includes(f.field)).length !== 0) {
                toast.error("Uploaded lookup entries didn't contain all columns from configured lookup")
                return
            }

            content.forEach(line => {
                line = recogniseTextBetweenSpeachmarks(line)
                const fields = line.split(',')
                entries = [
                    ...entries,
                    getCurrentEntry(originalLookup.fields, header, fields)
                ]
            })

            dispatch({ type: actionTypes.DATA_LOOKUP_REPLACED, lookupId: selectedLookupId, entries })
        }
        reader.readAsText(file);
    }

    const onCustomerDataFieldFileSelected = event => {
        const file = event.target.files[0]
        const reader = new FileReader()
        let dataFields = []

        reader.onload = e => {
            const lines = e.target.result.split('\r\n')
            const header = lines[0].split(',')
            const content = lines.slice(1)

            if (DATA_FIELD_FILE_HEADERS.filter(f => !header.includes(f)).length !== 0) {
                toast.error("Uploaded data fields didn't contain all necessary columns")
                return
            }

            content.forEach(line => {
                const newLine = recogniseTextBetweenSpeachmarks(line)
                const fields = newLine.split(',')
                dataFields = [
                    ...dataFields,
                    getDataField(header, fields)
                ]
            })
            dispatch({ type: actionTypes.DATA_CUSTOMER_FIELDS_UPLOADED, dataFields })
        }
        reader.readAsText(file);
    }

    const recogniseTextBetweenSpeachmarks = (line) =>
        line.replace(/"[^"]+"/g, (match) => match.replace(/,/g, '')).replace(/"/g, '')

    const getDataField = (header, fields) => {
        return {
            id: uuidv4(),
            isFromFile: true,
            fieldType: fieldType.CUSTOMER,
            fieldName: fields[header.indexOf("fieldName")],
            displayName: fields[header.indexOf("displayName")],
            dataPrimitive: fields[header.indexOf("dataPrimitive")],
            lookup: fields[header.indexOf("lookup")],
            isQuickFilter: fields[header.indexOf("isQuickFilter")].toLowerCase() === "true",
        }
    }

    const getCurrentEntry = (originalFields, header, fields) => {
        let bigDecimals = {}
        let booleans = {}
        let dateTimes = {}
        let strings = {}
        let reference = ""
        let name = ""
        originalFields.forEach(field => {
            const index = header.indexOf(field.field)
            if (field.field === "reference") reference = fields[index]
            else if (field.field === "name") name = fields[index]
            else
                switch (field.dataPrimitive) {
                    case dataPrimitive.BOOLEAN: {
                        booleans = {
                            ...booleans,
                            [field.field]: fields[index]?.toLowerCase() === "true"
                        }
                        break
                    }
                    case dataPrimitive.DATE: {
                        dateTimes = {
                            ...dateTimes,
                            [field.field]: fields[index]
                        }
                        break
                    }
                    case dataPrimitive.NUMBER: {
                        bigDecimals = {
                            ...bigDecimals,
                            [field.field]: fields[index]

                        }
                        break
                    }
                    case dataPrimitive.TEXT:
                    default: {
                        strings = {
                            ...strings,
                            [field.field]: fields[index]
                        }
                        break
                    }
                }
        })
        return {
            reference: reference,
            name: name,
            id: uuidv4(),
            editable: true,
            booleans: booleans,
            bigDecimals: bigDecimals,
            dateTimes: dateTimes,
            strings: strings
        }
    }

    return (
        <Loading isLoading={!config.DATA_API_URL} colour="blue">
            <div className={`d-flex flex-column py-4 px-3 ${classes.container}`}>
                <h3 className="text-white">Data configuration</h3>

                <div className="d-flex flex-column mt-auto">
                    {state.activeTab !== fieldType.LOOKUP &&
                        <StandardButton
                            className="w-100"
                            iconClasses="fal fa-plus"
                            label="Add field"
                            colour="blue"
                            onClick={onAddField}
                            ariaLabel="add-field"
                        />
                    }
                    {state.activeTab === fieldType.CUSTOMER &&
                        <FileUploadButton
                            label="Import data fields"
                            onFileUpload={onCustomerDataFieldFileSelected}
                            className="w-100 mt-3"
                            colour="blue"
                            ariaLabel="import-data-fields" />
                    }

                    {(selectedLookupId && state.activeTab === fieldType.LOOKUP) &&
                        <>
                            <FileUploadButton
                                label="Import lookup"
                                onFileUpload={onLookupFileSelected}
                                className="w-100 mt-3"
                                colour="blue"
                                ariaLabel="import-lookup-entries" />

                            <StandardButton
                                className="w-100 mt-3"
                                iconClasses="fal fa-download"
                                label="Export lookup"
                                colour="blue"
                                ariaLabel="export-lookup-button"
                                onClick={onDownloadButtonClicked}
                            />
                        </>
                    }
                    <ProgressButton
                        className="w-100 mt-3"
                        iconClasses="fal fa-save"
                        label="Save changes"
                        colour="blue"
                        succeededText="Saved successfully"
                        failedText="Failed to save"
                        onClickWithPromise={savePromise}
                        disabled={!hasDataChanged} />
                </div>
            </div>
        </Loading>
    )
}

export default Actions