import classes from "./NavigationButton.module.scss"
import { useHistory } from "react-router-dom"
import { useClient } from "invevo-react-components"

type NavigationButtonProps = {
    route: string,
    icon: string,
    label?: string,
    ariaLabel?: string,
    colour?: string
}

const NavigationButton = ({ route, icon, label = "", ariaLabel = "", colour = "white" }: NavigationButtonProps) => {
    const history = useHistory()
    const client = useClient()

    const onButtonClick = () => history.push(`${route}?client=${client}`)

    return (
        <div
            className={`d-flex flex-column m-4 ${classes.container}`}
            role='button'
            onClick={onButtonClick}
            aria-label={ariaLabel}
        >
            <div className={`d-flex flex-column justify-content-center fw-100  ${classes.iconContainer}`}>
                <i className={`${icon} fa-2xl mt-4 align-self-center`} style={{ color: colour }} role="img" />
                <span className="mt-4 fs-5 align-self-center" style={{ color: colour }}>{label}</span>
            </div>
        </div>
    )
}

export default NavigationButton