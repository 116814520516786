import { useApi, Loading, useClient, useConfig, StandardButton, useInvevoSession } from "invevo-react-components"
import { useState, useEffect } from "react"
import { useHistory, useLocation } from 'react-router-dom'
import { useCustomer } from "../../../contexts/CustomerContext"

const PaymentResult = () => {
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const location = useLocation()
    const history = useHistory()
    const api = useApi()
    const client = useClient()
    const config = useConfig()
    const [, setCustomer] = useCustomer()
    const [invevoSession] = useInvevoSession()

    useEffect(() => {
        if (isFetching || hasFetched || !config.PAY_API_URL) {
            return
        }

        setIsFetching(true)
        const encryptedContent = extractEncryptedContentFromPath(location.pathname)
        const vendorCrypt = extractVendorCryptFromPath(location.pathname)

        api.post(`${config.PAY_API_URL}/api/${client}/legacy-decrypt-redirect?invevoCrypt=${encryptedContent}&vendorCrypt=${vendorCrypt}`).then(response => {
            setIsSuccess(response.data.success)
            setHasFetched(true)
            setIsFetching(false)
        }, error => { throw error })
    }, [hasFetched, isFetching, location.pathname, api, client, config.PAY_API_URL])

    const goBackToCustomerClick = () => {
        history.push(`/home/search?client=${client}`)
        setCustomer({
            reference: invevoSession.reference,
            sqlId: invevoSession.sqlId,
            isVirtualAccount: invevoSession.isVirtualAccount
        })
    }

    return (
        <div className="d-flex flex-column h-100 align-items-center w-100 text-white">
            <div className="mt-5">
                <Loading isLoading={!hasFetched || isFetching} colour="blue">
                    <div className="d-flex flex-column">
                        {
                            isSuccess ?
                                <div className="d-flex flex-column align-items-center">
                                    <i className="text-success fal fa-check-circle fa-6x mb-3"></i>
                                    <span className="text-grey text-center fs-3">Payment successful</span>
                                </div>
                                :
                                <div className="d-flex flex-column align-items-center">
                                    <i className="text-danger fal fa-times-circle fa-6x mb-3"></i>
                                    <span className="text-grey text-center fs-5">Your payment could not be taken.</span>
                                    <span className="text-grey text-center fs-5">Please try again later</span>
                                </div>
                        }
                        <StandardButton className="mt-4" iconClasses="fal fa-arrow-left" label="Go back to customer" onClick={goBackToCustomerClick} />
                    </div>
                </Loading>
            </div>
        </div>
    )
}

const extractEncryptedContentFromPath = (path) => {
    const parts = removeLeadingSlashes(path).split('/')
    if (parts.length > 2) {
        return parts[2]
    }

    return
}

const extractVendorCryptFromPath = (path) => {
    const parts = removeLeadingSlashes(path).split('/')
    if (parts.length > 3) {
        return parts[3]
    }

    return
}

const removeLeadingSlashes = (path) => path.replace(/^\/+/, '');

export default PaymentResult