import { LogicOperatorOptions } from 'invevo-react-components';
import { filterTypes } from '../components/filters/Filters';

const getFilterTypeFromOperator = (operator) => {
    if (operator === LogicOperatorOptions.DATE_DAYS_AFTER_TODAY || operator === LogicOperatorOptions.DATE_DAYS_BEFORE_TODAY) {
        const dateFilterType = filterTypes.find(ft => ft.value === LogicOperatorOptions.DATE_IS_IN)
        return { ...dateFilterType, value: operator }
    }

    return filterTypes.find(ft => ft.value === operator)
}

const getFilters = (filters) => {
    if (!filters) return []

    const inValues = filters.fieldsAreInValues
    const inRanges = filters.fieldsAreInNumberRanges
    const comparisons = filters.fieldsComparison

    const inValuesFilters = inValues.map((filter, index) => {
        return {
            fieldName: filter.field.fieldName,
            value: filter.values.join(','),
            operator: filter.useAsNotIn ? getFilterTypeFromOperator("NOT_IN") : getFilterTypeFromOperator("IN"),
            isValid: true,
            type: filter.field.type,
            id: index
        }
    })

    const inRangesFilters = inRanges.map((filter, index) => {
        return {
            fieldName: filter.field.fieldName,
            value: `${filter.rangeStart},${filter.rangeEnd}`,
            operator: getFilterTypeFromOperator("RANGE"),
            isValid: true,
            type: filter.field.type,
            id: index
        }
    })

    const compFilters = comparisons.map((filter, index) => {
        return {
            fieldName: filter.field.fieldName,
            value: filter.value,
            operator: getFilterTypeFromOperator(filter.comparisonType),
            isValid: true,
            type: filter.field.type,
            id: index
        }
    })

    return inValuesFilters.concat(inRangesFilters).concat(compFilters)
}

export { getFilters }

export const Weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
export const WorkingDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]
export const Weekend = ["Saturday", "Sunday"]
export const EveryWorkingDayOption = "Every working day"
export const EverWeekendDayOption = "Every weekend"