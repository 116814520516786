import Tab from "../../../library/Tabs/Tab"
import Tabs from "../../../library/Tabs/Tabs"
import FileExportConfiguration from "./exportConfigs/FileExportConfiguration"
import FileImportConfiguration from "./importConfigs/FileImportConfiguration"

const FileConfiguration = () => {
    return (
        <div className="d-flex w-100 h-100">
            <div className="d-flex flex-column flex-grow-1">
                <Tabs className="flex-grow-1 p-4 overflow-auto" contentClassName="">
                    <Tab title="File Import" isActive={true}>
                        <FileImportConfiguration />
                    </Tab>
                    <Tab title="File Export">
                        <FileExportConfiguration />
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default FileConfiguration
