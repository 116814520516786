import { IconButton, Input, Loading, useApi, useClient, useConfig, GreyButton } from "invevo-react-components"
import { useEffect, useState } from "react"
import CountryCodes from "./CountryCodes"
import classes from "./VoipContainer.module.scss"
import { Device } from "@twilio/voice-sdk"
import VoipContacts from "./VoipContacts"
import VoipNumberPad from "../../../../library/voip/VoipNumberPad"
import { useVoip } from "../../../../contexts/VoipContext"
import useApiQuery from "../../../../hooks/useApiQuery"
import { useCustomer } from "../../../../contexts/CustomerContext"
import { useEntity } from "../../../../contexts/EntityContext"
import EntityVoipContacts from "./EntityVoipContacts"
import axios from 'axios'
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"
import Countdown from "react-countdown"
import { DataTypeEnum } from "../../../../routes/entityConfig/types/DataType";
import Dropdown from "../../../../library/dropdowns/Dropdown";

const TWILIO_URL = "https://studio.twilio.com/v2/Flows"

const VoipContainer = ({ entityConfig = undefined, goToNextEntity }) => {
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [countryCode, setCountryCode] = useState({ name: "", phoneNumberCode: "", countryCode: "" })
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false)
    const [device, setDevice] = useState()
    const [fromNumbers, setFromNumbers] = useState([])
    const [selectedFromNumberOption, setSelectedFromNumberOption] = useState()
    const [selectedContactId, setSelectedContactId] = useState()
    const [accountSid, setAccountSid] = useState("")
    const [authToken, setAuthToken] = useState("")
    const [executionSid, setExecutionSid] = useState("")
    const [timeStartedAutoDialCountdown, setTimeStartedAutoDialCountdown] = useState(Date.now())
    const [hasAutoDialed, setHasAutoDialed] = useState(false)

    const api = useApi()
    const config = useConfig()
    const client = useClient()
    const [customer] = useCustomer()
    const [entity] = useEntity()
    const { inCall, call, setInCall, setCall, autoDial, setAutoDial } = useVoip()
    const { isEnabled: automatedTwilioCall } = useFeatureToggle("automatedTwilioCall")
    const { isEnabled: flowId } = useFeatureToggle("twilioStudioFlowId")
    const { isEnabled: autoDialCountdownAmountInSeconds } = useFeatureToggle("voipAutoDialerSecondsToWait")
    const { isEnabled: autoDialerCalling } = useFeatureToggle("voipAutoDialer")

    const isValidCallConfig = phoneNumber !== "" && selectedFromNumberOption !== null

    const firstPhoneNumber = () => {
        if (entityConfig === undefined) {
            return ""
        }

        const firstPhoneNumberFieldName = entityConfig.fields.filter(field => field.dataType.type === DataTypeEnum.PHONE_NUMBER)[0]?.fieldName
        if (firstPhoneNumberFieldName) {
            return entity.entityFields.textFields[firstPhoneNumberFieldName]
        }
        return ""
    }

    const postVoipCallMetricsRequest = useApiQuery({
        url: `${config.VOIP_API_URL}/api/${client}/call-metrics`,
        method: "POST",
        isExecutedAutomatically: false
    })

    useEffect(() => {
        if (isFetching || hasFetched || !config.CONNECT_API_URL) {
            return
        }

        const getVoipToken = api.get(`${config.CONNECT_API_URL}/api/${client}/voip-token`).then(response => {
            setDevice(new Device(response.data.token))
        })

        const getVoipFromNumbers = api.get(`${config.CONNECT_API_URL}/api/${client}/voip-from-numbers`).then(response => {
            setFromNumbers(
                response.data.map(fromNumber => {
                    return {
                        id: fromNumber.phoneNumber,
                        value: fromNumber.displayPhoneNumber,
                        label: fromNumber.displayPhoneNumber
                    }
                })
            )

            if (autoDial && response.data.length > 0) {
                setSelectedFromNumberOption({
                    id: response.data[0].phoneNumber,
                    value: response.data[0].displayPhoneNumber,
                    label: response.data[0].displayPhoneNumber
                })
            }
        })

        const getVoipConfig = api.get(`${config.CONNECT_API_URL}/api/${client}/voip-config`)
            .then(response => {
                setHasFetched(true)
                setIsFetching(false)
                setAccountSid(response.data.twilioAccountSid)
                setAuthToken(response.data.twilioAuthToken)
            })

        setIsFetching(true)
        Promise.all([getVoipToken, getVoipFromNumbers, getVoipConfig])
            .then(() => {
                setHasFetched(true)
                setIsFetching(false)
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    setHasFetched(true)
                    setIsFetching(false)
                } else {
                    console.error(error)
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, client, config.CONNECT_API_URL, hasFetched, isFetching])

    useEffect(() => {
        if (phoneNumber === "") {
            setPhoneNumberIsValid(false)
        } else {
            setPhoneNumberIsValid(true)
        }
    }, [phoneNumber])

    useEffect(() => {
        if (autoDial && !phoneNumber) {
            setPhoneNumber(firstPhoneNumber())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoDial])

    useEffect(() => {
        if (autoDial) {
            setPhoneNumber(firstPhoneNumber())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity])

    const onContactSelected = (phoneNumber, contactId) => {
        if (phoneNumber.startsWith("+") || phoneNumber.startsWith("0") || phoneNumber.startsWith("(0)")) {
            setCountryCode({ name: "", phoneNumberCode: "", countryCode: "" })
        }

        contactId !== undefined && setSelectedContactId(contactId)
        setPhoneNumber(phoneNumber)
    }

    const onPhoneNumberChanged = event => {
        setSelectedContactId(null)
        setPhoneNumber(event.target.value)
    }

    const onPhoneNumberRemoved = () => {
        setSelectedContactId(null)
        setPhoneNumber("")
    }

    const onNextEntityClick = () => {
        goToNextEntity()
        setTimeStartedAutoDialCountdown(Date.now())
        setHasAutoDialed(false)
        setPhoneNumber("")
    }

    const onStartCall = () => {
        setInCall(true)
        const toNumber = countryCode.name === "" ? phoneNumber : `+${countryCode.phoneNumberCode.concat(phoneNumber)}`
        device
            .connect({
                params: {
                    From: `${selectedFromNumberOption.id}`,
                    To: `${toNumber}`
                }
            })
            .then(phoneCall => {
                phoneCall.on("disconnect", () => {
                    setInCall(false)
                    const reference = customer.reference ? customer.reference : entity.reference
                    postVoipCallMetricsRequest.execute(undefined, {
                        callId: phoneCall.parameters.CallSid,
                        customerRef: reference,
                        contactId: selectedContactId
                    })
                })
                setCall(phoneCall)
            })
    }

    const onStartAutomatedCall = () => {
        setInCall(true)
        const toNumber = countryCode.name === "" ? phoneNumber : `+${countryCode.phoneNumberCode.concat(phoneNumber)}`

        const params = new URLSearchParams({ From: `${selectedFromNumberOption.value}`, To: `${toNumber}` })
        axios.post(`${TWILIO_URL}/${flowId}/Executions`, params.toString(),
            {
                auth: {
                    username: accountSid,
                    password: authToken
                }
            }).then(response => setExecutionSid(response.data.sid))
    }

    const onAutoDialCountdownEnd = () => {
        if (!inCall && isValidCallConfig && !hasAutoDialed) {
            onStartCall()
        }
    }

    const onStartAutoDial = () => {
        setAutoDial(true)
        setTimeStartedAutoDialCountdown(Date.now())
    }

    const onCancelCall = () => {
        setInCall(false)
        const params = new URLSearchParams({ Status: "ended" })
        if (executionSid !== "") {
            axios.post(`${TWILIO_URL}/${flowId}/Executions/${executionSid}`, params.toString(),
                {
                    auth: {
                        username: accountSid,
                        password: authToken
                    }
                }).then(_ => setExecutionSid(""))
                .catch(_ => setExecutionSid(""))
        }
        else {
            call.disconnect()
        }

        if (autoDial) {
            setHasAutoDialed(true)
        }
    }

    const onKeyPress = (key) => {
        if (call) call.sendDigits(key + "w")
    }

    const onFromNumberChanged = fromNumberOption => setSelectedFromNumberOption(fromNumberOption)

    return (
        <div className={`${classes.container} d-flex flex-column w-100`}>
            <h2 className="text-white ms-3">Phone</h2>
            <Loading isLoading={isFetching} colour="white">
                <div className="d-flex w-100 justify-content-center mt-3">
                    <span className="text-white me-3 mt-2 text-uppercase align-items-center">call from:</span>
                    <Dropdown colour="blue" className="w-50" options={fromNumbers} onOptionSelected={onFromNumberChanged} ariaLabel="from-numbers" selectedOption={selectedFromNumberOption} />
                </div>
                <div className="d-flex w-100 justify-content-center align-items-center mt-4">
                    <CountryCodes countryCode={countryCode} setCountryCode={setCountryCode} />
                    <Input
                        className="ms-2 w-50"
                        placeholder="Please enter a phone number"
                        value={phoneNumber}
                        onChange={onPhoneNumberChanged}
                        ariaLabel="phone-number"
                    />
                    <IconButton
                        className="ms-2"
                        iconClasses="fal fa-trash-alt"
                        size="small"
                        colour="blue"
                        onClick={onPhoneNumberRemoved}
                        ariaLabel="clear-phone-number"
                    />
                </div>
                {inCall && <VoipNumberPad onButtonPress={onKeyPress} />}
                <div className="align-self-center pt-4">
                    {!inCall ? (
                        <div className="d-flex">
                            <IconButton
                                buttonClasses={classes.startCallButton}
                                colour="white"
                                iconClasses="fa-solid fa-phone-flip"
                                onClick={onStartCall}
                                ariaLabel="start-call"
                                disabled={!phoneNumberIsValid || selectedFromNumberOption == null}
                            />
                            {automatedTwilioCall &&
                                <IconButton
                                    className="ms-2"
                                    buttonClasses={classes.startCallButton}
                                    colour="white"
                                    iconClasses="fa-solid fa-headset"
                                    onClick={onStartAutomatedCall}
                                    ariaLabel="start-automated-call"
                                    disabled={!phoneNumberIsValid || selectedFromNumberOption == null}
                                />
                            }
                        </div>
                    ) : (
                        <IconButton
                            buttonClasses={classes.endCallButton}
                            colour="white"
                            iconClasses="fa-solid fa-phone-slash"
                            onClick={onCancelCall}
                            ariaLabel="end-call"
                        />
                    )}
                </div>

                {(autoDialerCalling && !inCall) && (
                    <div className="d-flex flex-column gap-3 my-4">
                        {autoDial ? (
                            <>
                                {hasAutoDialed ? (
                                    <h5 className="text-white">Waiting for next customer to auto dial...</h5>
                                ) : (
                                    <Countdown
                                        date={timeStartedAutoDialCountdown + autoDialCountdownAmountInSeconds * 1000}
                                        intervalDelay={0}
                                        precision={1}
                                        onComplete={onAutoDialCountdownEnd}
                                        renderer={({ seconds }) =>
                                            <>
                                                <h2 className="text-white">Call starts in {seconds} seconds...</h2>
                                            </>
                                        }
                                    />
                                )}
                                {!isValidCallConfig && (
                                    <h5 className="text-red">Invalid config, won't be able to call...</h5>
                                )}

                                <GreyButton label="Stop Auto Dial" iconClasses="fa-solid fa-stop" onClick={() => setAutoDial(false)} />

                            </>
                        ) : (
                            <GreyButton label="Start Auto Dial" iconClasses="fa-solid fa-play" onClick={onStartAutoDial} />
                        )}
                        <GreyButton label="Go to next entity" iconClasses="fa-solid fa-forward" onClick={onNextEntityClick} />
                    </div>
                )}

                {!inCall && <VoipContacts entitySqlId={entity?.sqlId} onSelectContact={onContactSelected} />}
                {!inCall && entityConfig && <EntityVoipContacts selectedEntity={entity} entityConfig={entityConfig} onEntityContactSelected={onContactSelected} />}
            </Loading>
        </div>
    )
}

export default VoipContainer
