import classes from "./UserCard.module.scss"

const UserCard = ({ username }) => {
    return (
        <div className={`d-flex align-items-center w-100 mx-2 p-2 ${classes["container"]}`}>
            <div className={`d-flex justify-content-center ${classes["profile-picture"]}`}>
                <i className="fas fa-user pb-1 mx-2 my-auto text-grey" />
            </div>

            <div className="d-flex flex-column mx-2">
                <h4 className="mb-1 text-dark-grey">{username}</h4>
            </div>
        </div>
    )
}

export default UserCard
