import React, { useEffect, useMemo, useState } from "react"
import { useLayout } from "../../../contexts/LayoutContext"
import classes from "./EntitySearch.module.scss"
import { convertFromDtoToArray } from "../../../library/FilterList/FiltersDto"
import FilterGroupList from "../../../library/FilterList/FilterGroupList"
import Lookup from "../../../types/Lookup"
import FieldType from "../../../types/enums/FieldType"
import EntityConfig from "../../entityConfig/types/EntityConfig"
import { DataTypeEnum } from "../../entityConfig/types/DataType"
import GenericFilter from "../../../library/FilterList/filterTypes/GenericFilter"
import Dropdown from "../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../types/DropdownOptions"
import { DashboardConfig, DashboardTypeEnum, GridWidget } from "../types/DashboardConfig"
import { getDataFieldsFromEntityDataFields, toDataPrimitive } from "../../../library/helpers/entityHelpers"
import { useFeatureToggle } from "../../../hooks/useFeatureToggle"
import { removeKeysFromLocalStorageWithPrefix, useLocalStorage } from "../../../hooks/useLocalStorage"
import { widgetTypes } from "../../../microfrontends/dashboard/enums/widgetTypes"
import EntityChartDisplayWidget from "../../../microfrontends/dashboard/components/customerDashboards/widgets/EntityChartDisplayWidget"
import GridSearchDisplayWidget from "./GridSearchDisplayWidget"
import DndGrid from "../../../library/dndGrid/DnDGrid"
import { v4 as uuidv4 } from "uuid"
import useClient from "../../../hooks/useClient"
import { useUser } from "../../../contexts/UserContext"
import { EntityChartConfigWidgetState, TrendingWidgetState } from "../../../microfrontends/dashboard/types/DashboardConfigState"
import { GridState } from "../../../library/grid/Grid"
import FilterTypesByField from "../../../library/FilterList/FilterTypesByField"
import EntityTrendingChart from "../../../library/charts/EntityTrendingChart"
import EntityRelationshipDto from "../../entityConfig/types/EntityRelationshipDto"
import IconButton from "../../../library/buttons/IconButton/IconButton"
import { useEvent } from "../../../contexts/EventContext"
import LeftPanelDisplayWidget from "./LeftPanelDisplayWidget"
import { useEntity } from "../../../contexts/EntityContext"
import { useCustomer } from "../../../contexts/CustomerContext"
import RowData from "../../../library/grid/types/RowData"
import { convertDtoFieldsToDatafields } from "../../../types/EntityData"

type EntitySearchModularDashboardProps = {
    className: string
    entityConfigs: EntityConfig[]
    entityRelationships: EntityRelationshipDto[]
    lookups: Lookup[]
    dashboardConfigs: DashboardConfig[]
}

export type FilterGroupWithId = { id: string; filterGroup: GenericFilter[] }

type EntitySearchWidget =
    | (EntityChartConfigWidgetState & {
          id: string
      })
    | (GridWidget & {
          id: string
          widgetType: "GRID"
      })
    | (TrendingWidgetState & { id: string })
const sortWidgets = (widgets: EntitySearchWidget[]) => [...widgets].sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1))

const EntitySearchModularDashboard = ({ className, entityConfigs, entityRelationships, lookups, dashboardConfigs }: EntitySearchModularDashboardProps) => {
    const client = useClient()
    const user = useUser()
    const { setExpandedPanel } = useLayout()
    const { triggerEvent } = useEvent()
    const [, setEntity] = useEntity()
    const [selectedTransaction, setSelectedTransaction] = useState<string>()
    const [, setCustomer] = useCustomer()

    const { isEnabled: showAdminEntityDashboards } = useFeatureToggle("showAdminEntityDashboards")
    const { isEnabled: isCachingEnabled } = useFeatureToggle("searchDashboardCaching")
    const { isEnabled: isSqlFirst } = useFeatureToggle("sqlFirst")
    const { isEnabled: useEntityDashboardsWhenSqlFirst } = useFeatureToggle("useEntityDashboardsWhenSqlFirst")

    const [selectedDashboardRef, setSelectedDashboardRef, , hasRefLoaded] = useLocalStorage<string>(
        client + "/entitySearch/dashboard",
        dashboardConfigs[0]!.reference,
        isCachingEnabled
    )

    const adminDashboardOptions = getAdminDashboards(entityConfigs)
    const configuredDashboardOptions: DropdownOption<DashboardConfig>[] = dashboardConfigs.map(config => ({ value: config, label: config.name }))
    const dashboardOptions =
        showAdminEntityDashboards && user?.permissions?.hasAdminPermission
            ? configuredDashboardOptions.concat(adminDashboardOptions)
            : configuredDashboardOptions

    useEffect(() => {
        const prefix = `${client}/entitySearch/`
        removeKeysFromLocalStorageWithPrefix(prefix, ["dashboard", ...dashboardOptions.map(d => `${d.value.reference}`)])
        dashboardOptions.forEach(d => {
            const keys = ["filterGroups", "advancedFilters", "groupingFilter", ...getCacheKeyExtensionsForDashboardWidgets(d.value)]
            removeKeysFromLocalStorageWithPrefix(`${prefix}${d.value.reference}/`, keys)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const selectedDashboardOption = dashboardOptions.find(option => option.value.reference === selectedDashboardRef) || dashboardOptions[0]!
    useEffect(() => {
        setSelectedGridState(undefined)
    }, [selectedDashboardRef])
    const dashboardAppliedRoleNames = user?.userRoles
        .filter(role => selectedDashboardOption?.value.roleReferences.includes(role.reference))
        .map(role => role.roleName)

    const sortedWidgets = useMemo(
        () =>
            sortWidgets([
                ...(selectedDashboardOption?.value.entityChartWidgets.map(
                    w => ({ ...w, id: `${w.displayName}-${w.entityConfigReference}-${w.aggregationType}-${w.gridCellX}-${w.gridCellY}` } as EntitySearchWidget)
                ) ?? []),
                ...(selectedDashboardOption?.value.gridWidgets.map(w => ({ ...w, widgetType: "GRID", id: w.reference } as EntitySearchWidget)) ?? []),
                ...(selectedDashboardOption?.value.trendingWidgets.map(
                    w => ({ ...w, widgetType: widgetTypes.ENTITY_TRENDING, id: w.reference } as EntitySearchWidget)
                ) ?? [])
            ]),
        [selectedDashboardOption]
    )

    const getSelectedConfigFilters = (dashboard: DashboardConfig | undefined) =>
        dashboard !== undefined && dashboard.filters !== undefined
            ? convertFromDtoToArray(
                  dashboard.filters,
                  getDataFieldsFromEntityDataFields(
                      entityConfigs.find(entityConfig => entityConfig.reference === dashboard.entityConfigReference)?.fields ?? []
                  ),
                  lookups
              )
            : []

    const [advancedFilters, setAdvancedFilters, clearCachedFilters, haveFiltersLoaded] = useLocalStorage<GenericFilter[]>(
        client + "/entitySearch/" + selectedDashboardRef + "/advancedFilters",
        getSelectedConfigFilters(selectedDashboardOption.value),
        isCachingEnabled
    )
    const [dashboardFilterGroups, setDashboardFilterGroups, clearCachedFilterGroups, haveFilterGroupsLoaded] = useLocalStorage<FilterGroupWithId[]>(
        client + "/entitySearch/" + selectedDashboardRef + "/filterGroups",
        [],
        isCachingEnabled
    )
    const [groupingFilter, setGroupingFilter, clearCachedGrouping, hasGroupingLoaded] = useLocalStorage<{ fieldName: string; fieldValue: string } | undefined>(
        client + "/entitySearch/" + selectedDashboardRef + "/groupingFilter",
        undefined,
        isCachingEnabled
    )

    const [selectedGridWidget, setSelectedGridWidget] = useState<GridWidget | undefined>()
    const [selectedGridState, setSelectedGridState] = useState<GridState | undefined>()

    const advancedFiltesWithGroupingFilter: GenericFilter[] = groupingFilter
        ? [
              ...advancedFilters,
              {
                  type: FilterTypesByField.TEXT_FIELD_IS_ONE_OF,
                  id: uuidv4(),
                  fieldName: groupingFilter.fieldName,
                  values: [groupingFilter.fieldValue],
                  notOneOf: false
              }
          ]
        : advancedFilters
    const allGridFilters = [...convertFromDtoToArray(selectedGridState?.filters, [], lookups), ...advancedFiltesWithGroupingFilter]

    const clearCaches = () => {
        clearCachedFilters()
        clearCachedFilterGroups()
        clearCachedGrouping()
        triggerEvent("clear_dashboard_cache", {})
    }

    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
    const { isLeftPanelExpanded } = useLayout()

    const dashboardFilterGroupsWithoutId = dashboardFilterGroups.map(group => group.filterGroup)

    const entityDataFields = (
        entityConfigs.find(entityConfig => entityConfig.reference === selectedDashboardOption.value.entityConfigReference)?.fields ?? []
    ).map(field => ({
        value: field.fieldName,
        label: field.displayName,
        type: toDataPrimitive(field.dataType.type),
        lookup: field.dataType.type === DataTypeEnum.LOOKUP ? field.dataType.lookupReference : undefined,
        isQuickFilter: field.isQuickFilter,
        fieldType: FieldType.ENTITY
    }))

    const onToggleShowAdvancedFilters = () => setShowAdvancedFilters(sAF => !sAF)

    const [hasCacheLoaded, setHasCacheLoaded] = useState(!isCachingEnabled)
    useEffect(() => {
        if (isCachingEnabled && hasRefLoaded && haveFiltersLoaded && haveFilterGroupsLoaded && hasGroupingLoaded) {
            setHasCacheLoaded(true)
        }
    }, [isCachingEnabled, hasRefLoaded, haveFiltersLoaded, haveFilterGroupsLoaded, hasGroupingLoaded])

    const onDashboardSelected = (option: DropdownOption<DashboardConfig>) => {
        setSelectedDashboardRef(option.value.reference)
        if (isCachingEnabled) {
            setHasCacheLoaded(false)
        } else {
            clearCaches()
        }
    }

    const onEntitySelected = (data: RowData, widget?: GridWidget, gridState?: GridState) => {
        const selectedEntityType =
            entityConfigs.find(entityConfig => entityConfig.reference === selectedDashboardOption.value.entityConfigReference) ?? entityConfigs[0]!
        if (isSqlFirst && !useEntityDashboardsWhenSqlFirst && selectedEntityType.reference !== "customer" && selectedEntityType.reference !== "transaction") {
            return
        }
        const customerRefField = entityRelationships.find(
            r => r.childEntityReference === "transaction" && r.parentEntityReference === "customer"
        )?.groupingFieldName
        const customerRef = data.fields.textFields[customerRefField ?? "text_customerreference"]
        if (isSqlFirst && !useEntityDashboardsWhenSqlFirst && selectedEntityType.reference === "customer") {
            setCustomer({ reference: data.reference })
        } else if (isSqlFirst && !useEntityDashboardsWhenSqlFirst && selectedEntityType.reference === "transaction") {
            setCustomer({ reference: customerRef })
        } else if (selectedDashboardOption.value.type === "AGGREGATED" && selectedEntityType.reference === "transaction" && customerRef) {
            setEntity({ reference: customerRef, entityType: "customer" })
            setSelectedTransaction(data.reference)
        } else {
            setEntity({ reference: data.reference, entityType: selectedEntityType.reference, entityFields: convertDtoFieldsToDatafields(data.fields) })
        }
        if (widget) setSelectedGridWidget(widget)
        if (gridState) setSelectedGridState(gridState)
        setExpandedPanel("DEFAULT")
    }

    return (
        <div
            className={`${className ? className : ""} ${classes.container} d-flex h-100 bg-grey ${
                isLeftPanelExpanded ? `w-100 ${classes.gridBackground}` : classes.sidebar
            }`}
        >
            <div className={`d-flex flex-column h-100 w-100 overflow-auto ${isLeftPanelExpanded ? "p-3" : ""}`}>
                <div className="d-flex justify-content-between fs-3 fw-lighter">
                    {isLeftPanelExpanded ? <></> : <span className="ps-3 pt-3 text-grey">Entity Search Results</span>}
                    {isLeftPanelExpanded && (
                        <div className="d-flex flex-row ms-2 my-auto align-items-center justify-content-center gap-3">
                            <Dropdown
                                options={dashboardOptions}
                                onOptionSelected={onDashboardSelected}
                                selectedOption={selectedDashboardOption}
                                textAlign="left"
                                ariaLabel="dashboard-config"
                            />
                            {isCachingEnabled && <IconButton icon="fal fa-arrow-rotate-left" theme="grey" tooltip="Reset Dashboard" onClick={clearCaches} />}
                        </div>
                    )}

                    {isLeftPanelExpanded && (
                        <div className="d-flex flex-grow-1">
                            <div
                                role="button"
                                className={`position-relative d-flex align-items-center bg-grey text-grey ms-auto px-2 bg-white no-select ${
                                    showAdvancedFilters ? "rounded-top" : "rounded"
                                } `}
                                onClick={onToggleShowAdvancedFilters}
                            >
                                <i className="text-blue fal fa-filter"></i>
                                <span className="fs-4 mx-2">Dashboard Filters</span>
                                {advancedFilters.length + dashboardFilterGroups.length > 0 && (
                                    <span className={`position-absolute text-white text-center fs-5 rounded-circle mb-0 bg-blue ${classes.filterCount}`}>
                                        {advancedFilters.length + dashboardFilterGroups.length}
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {showAdvancedFilters && isLeftPanelExpanded && (
                    <div className={`d-flex bg-white rounded p-3 mb-1 ${classes.advancedFiltersContainer}`}>
                        <FilterGroupList
                            fields={entityDataFields}
                            lookups={lookups}
                            appliedGroupFilters={dashboardFilterGroups}
                            onGroupFiltersApplied={setDashboardFilterGroups}
                            showRelativeDateOptions={true}
                        />
                    </div>
                )}

                {!isLeftPanelExpanded ? (
                    selectedGridState &&
                    selectedGridWidget && (
                        <div className="w-100 h-100 p-2">
                            <LeftPanelDisplayWidget
                                entityConfigs={entityConfigs}
                                widget={selectedGridWidget}
                                selectedGridState={selectedGridState}
                                lookups={lookups}
                                advancedFilters={allGridFilters}
                                dashboardFilterGroups={dashboardFilterGroupsWithoutId}
                                dashboardAppliedRoles={dashboardAppliedRoleNames}
                                setExpandedPanel={setExpandedPanel}
                                dashboardReference={selectedDashboardOption.value.reference}
                                onEntitySelected={onEntitySelected}
                                selectedTransactionRef={selectedTransaction}
                            />
                        </div>
                    )
                ) : (
                    <DndGrid isReadOnly={true}>
                        {sortedWidgets.map(widget => {
                            switch (widget.widgetType) {
                                case widgetTypes.ENTITY_CHART_WIDGET:
                                    return (
                                        <div
                                            key={widget.id}
                                            id={widget.id}
                                            x={widget.gridCellX}
                                            y={widget.gridCellY}
                                            w={widget.gridCellWidth}
                                            h={widget.gridCellHeight}
                                            minW={2}
                                            minH={2}
                                        >
                                            <div className="w-100 h-100 p-2">
                                                <EntityChartDisplayWidget
                                                    chartConfig={widget}
                                                    advancedFilters={advancedFilters}
                                                    dashboardFilterGroups={dashboardFilterGroups}
                                                    dashboardAppliedRoles={dashboardAppliedRoleNames}
                                                    lookups={lookups}
                                                    entityConfigs={entityConfigs}
                                                    setDashboardFilterGroups={setDashboardFilterGroups}
                                                    setAdvancedFilters={setAdvancedFilters}
                                                    shouldLoadData={hasCacheLoaded}
                                                    dashboardReference={selectedDashboardOption.value.reference}
                                                    isCachingEnabled={isCachingEnabled}
                                                />
                                            </div>
                                        </div>
                                    )
                                case widgetTypes.GRID:
                                    return (
                                        <div
                                            key={widget.id}
                                            id={widget.id}
                                            x={widget.gridCellX}
                                            y={widget.gridCellY}
                                            w={widget.gridCellWidth}
                                            h={widget.gridCellHeight}
                                            minW={2}
                                            minH={2}
                                        >
                                            <div className="w-100 h-100 p-2">
                                                <GridSearchDisplayWidget
                                                    entityConfigs={entityConfigs}
                                                    entityRelationships={entityRelationships}
                                                    widget={widget}
                                                    lookups={lookups}
                                                    isLeftPanelExpanded={isLeftPanelExpanded}
                                                    advancedFilters={advancedFilters}
                                                    dashboardFilterGroups={dashboardFilterGroupsWithoutId}
                                                    dashboardAppliedRoles={dashboardAppliedRoleNames}
                                                    setExpandedPanel={setExpandedPanel}
                                                    setGroupingFilter={setGroupingFilter}
                                                    shouldLoadData={hasCacheLoaded}
                                                    dashboardReference={selectedDashboardOption.value.reference}
                                                    isCachingEnabled={isCachingEnabled}
                                                    onEntitySelected={onEntitySelected}
                                                />
                                            </div>
                                        </div>
                                    )
                                case widgetTypes.ENTITY_TRENDING:
                                    return (
                                        <div
                                            key={widget.id}
                                            id={widget.id}
                                            x={widget.gridCellX}
                                            y={widget.gridCellY}
                                            w={widget.gridCellWidth}
                                            h={widget.gridCellHeight}
                                            minW={2}
                                            minH={2}
                                        >
                                            <div className="w-100 h-100 p-2">
                                                <EntityTrendingChart
                                                    widget={widget}
                                                    advancedFilters={advancedFilters}
                                                    lookups={lookups}
                                                    entityConfigs={entityConfigs}
                                                    colour="blue"
                                                    title={""}
                                                    entityConfigReference={selectedDashboardOption.value.entityConfigReference}
                                                />
                                            </div>
                                        </div>
                                    )
                                default:
                                    return <React.Fragment key={widget.id}></React.Fragment>
                            }
                        })}
                    </DndGrid>
                )}
            </div>
        </div>
    )
}

export default EntitySearchModularDashboard

const getAdminDashboards = (entityConfigs: EntityConfig[]): DropdownOption<DashboardConfig>[] =>
    entityConfigs.map(entityConfig => ({
        label: `Entity - ${entityConfig.displayName}`,
        value: {
            reference: `entity-${entityConfig.reference}`,
            name: `Entity - ${entityConfig.displayName}`,
            type: DashboardTypeEnum.AGGREGATED,
            entityConfigReference: entityConfig.reference,
            infoWidgets: [],
            valueWidgets: [],
            chartWidgets: [],
            entityChartWidgets: [],
            gridWidgets: [
                {
                    reference: `entity-${entityConfig.reference}`,
                    ordinal: 1,
                    gridCellX: 0,
                    gridCellY: 0,
                    gridCellWidth: 12,
                    gridCellHeight: 6,
                    displayName: "Data Grid",
                    columns: entityConfig.fields.map((field, index) => ({
                        fieldName: field.fieldName,
                        dataType: field.dataType.type,
                        isEditable: field.fieldName !== "reference" && field.fieldName !== "client" && field.fieldName !== "entity_type",
                        ordinal: index
                    })),
                    type: null,
                    entityConfigReference: entityConfig.reference,
                    groupingField: null,
                    draggable: false
                }
            ],
            trendingWidgets: [],
            roleReferences: []
        }
    }))

const getCacheKeyExtensionsForDashboardWidgets = (dashboard?: DashboardConfig) => {
    if (dashboard === undefined) return []
    return [
        ...dashboard.gridWidgets.map(d => d.reference),
        ...dashboard.entityChartWidgets.map(d => `${d.displayName}-${d.entityConfigReference}-${d.chartType}-${d.aggregationType}`),
        ...dashboard.trendingWidgets.map(d => d.reference),
        ...dashboard.valueWidgets.map(d => `${d.displayName}-${d.field.fieldName}`),
        ...dashboard.infoWidgets.map(d => d.displayName)
    ]
}
