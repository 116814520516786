import { Collapsable } from "invevo-react-components"
import WorkflowRuleCard from "./WorkflowRuleCard"
import { padElementsWith } from "../../../../library/helpers"
import WorkflowRule from "../../types/WorkflowRule"
import EntityConfig from "../../../entityConfig/types/EntityConfig"

type WorkflowRulesProps = {
    rules: WorkflowRule[]
    selectedRuleReference: string | undefined
    entityConfigs: EntityConfig[]
    isNewEntityStructureEnabled: boolean
    onRuleSelected: (ruleReference: string) => void
    onRuleRemoved: (ruleReference: string) => void
}

const WorkflowRules = ({ rules, selectedRuleReference, entityConfigs, isNewEntityStructureEnabled, onRuleSelected, onRuleRemoved }: WorkflowRulesProps) => {
    const activeRuleElements = rules
        .filter(rule => !rule.archived)
        .sort((a, b) => {
            const nameA = a.name.toLowerCase()
            const nameB = b.name.toLowerCase()
            if (nameA < nameB) return -1
            if (nameA > nameB) return 1
            return 0
        })
        .map(rule => {
            const ruleEntityConfig = entityConfigs.find(c => c.reference === rule.entityConfigReference)
            const ruleEntityType = isNewEntityStructureEnabled ? ruleEntityConfig?.displayName ?? "Kafka Customer" : "Customer"
            return (
                <WorkflowRuleCard
                    key={rule.reference}
                    rule={rule}
                    isSelected={rule.reference === selectedRuleReference}
                    entityType={ruleEntityType}
                    onRuleSelected={onRuleSelected}
                    onRuleRemoved={onRuleRemoved}
                />
            )
        })

    const sortDates = (a: { modifiedAt: number }, b: { modifiedAt: number }) => {
        if (a.modifiedAt < b.modifiedAt) return -1
        if (a.modifiedAt > b.modifiedAt) return 1
        return 0
    }

    const archivedRuleElements = rules
        .filter(rule => rule.archived)
        .sort((a, b) => {
            const latestUpdateA = a.modificationHistory.sort(sortDates).at(a.modificationHistory.length - 1)?.modifiedAt
            const latestUpdateB = b.modificationHistory.sort(sortDates).at(b.modificationHistory.length - 1)?.modifiedAt
            if (latestUpdateA !== undefined && latestUpdateB === undefined) return -1
            if (latestUpdateA === undefined && latestUpdateB !== undefined) return 1
            if (latestUpdateA === undefined && latestUpdateB === undefined) return 0
            if (latestUpdateA !== undefined && latestUpdateB !== undefined && latestUpdateA > latestUpdateB) return -1
            if (latestUpdateA !== undefined && latestUpdateB !== undefined && latestUpdateA < latestUpdateB) return 1
            return 0
        })
        .map(rule => {
            const ruleEntityConfig = entityConfigs.find(c => c.reference === rule.entityConfigReference)
            const ruleEntityType = isNewEntityStructureEnabled ? ruleEntityConfig?.displayName ?? "Kafka Customer" : "Customer"
            return (
                <WorkflowRuleCard
                    key={rule.reference}
                    rule={rule}
                    isSelected={rule.reference === selectedRuleReference}
                    entityType={ruleEntityType}
                    onRuleSelected={onRuleSelected}
                    onRuleRemoved={onRuleRemoved}
                />
            )
        })

    return (
        <div>
            <div className="d-flex flex-column mt-3">
                {padElementsWith(activeRuleElements, "mb-3")}
                {activeRuleElements.length === 0 && <span className="text-grey">You have no active workflows</span>}
            </div>
            <Collapsable className="mt-3" title="ARCHIVED WORKFLOWS" open={false} id="archivedWorkflows">
                <div className="d-flex flex-column">
                    {padElementsWith(archivedRuleElements, "mb-3")}
                    {archivedRuleElements.length === 0 && <span className="text-grey">You have no archived workflows</span>}
                </div>
            </Collapsable>
        </div>
    )
}

export default WorkflowRules
