import { useState } from "react"
import Role from "../types/Role"
import { RoleTypeEnum } from "../types/RoleTypeEnum"
import RoleActions from "./RoleActions"
import RoleConfig from "./RoleConfig"
import RoleList from "./RoleList"
import classes from "./Roles.module.scss"
import { v4 as uuidv4 } from "uuid"
import { Loading, useClient, useConfig } from "invevo-react-components"
import useApiQuery from "../../../../hooks/useApiQuery"
import EntityConfig from "../../../entityConfig/types/EntityConfig"

const Roles = () => {
    const client = useClient()
    const config = useConfig()

    const [selectedRoleReference, setSelectedRoleReference] = useState<string | undefined>(undefined)
    const [roles, setRoles] = useState<Role[]>([])
    const [originalRoles, setOriginalRoles] = useState<Role[]>([])
    const [entityConfigs, setEntityConfigs] = useState<EntityConfig[]>([])

    const getRolesRequest = useApiQuery<Role[]>({
        url: `${config.USER_ROLES_API_URL}/api/${client}/roles`,
        method: "GET",
        onSuccess: (roles: Role[]) => {
            setRoles(roles)
            setOriginalRoles(roles)
        }
    })

    const getEntityConfigsRequest = useApiQuery<EntityConfig[]>({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-config`,
        method: "GET",
        onSuccess: (entityConfigs: EntityConfig[]) => {
            setEntityConfigs(entityConfigs)
        }
    })

    const selectedRole = roles.find(role => role.reference === selectedRoleReference)

    const onRoleUpdated = (updatedRole: Role) => setRoles(roles => roles.map(role => (role.reference === updatedRole.reference ? updatedRole : role)))

    const onRoleSaved = (savedRole: Role) => {
        if (originalRoles.find(role => role.reference === savedRole.reference) === undefined) {
            setOriginalRoles(originalRoles => [...originalRoles, savedRole])
        } else {
            setOriginalRoles(originalRoles => originalRoles.map(role => (role.reference === savedRole.reference ? savedRole : role)))
        }
    }

    const onRoleRemoved = (roleReference: string) => setRoles(roles => roles.filter(role => role.reference !== roleReference))

    const onRoleDeleted = (deletedRole: Role) => {
        setRoles(roles => roles.filter(role => role.reference !== deletedRole.reference))
        setOriginalRoles(originalRoles => originalRoles.filter(role => role.reference !== deletedRole.reference))
    }

    const onNewRoleAdded = () => {
        const newRole: Role = {
            reference: uuidv4(),
            name: "New Role",
            roleType: RoleTypeEnum.STANDARD,
            customerPermissions: false,
            automateAdmin: false,
            connectAdmin: false,
            userAdmin: false,
            dashboardAdmin: false,
            integrationAdmin: false,
            dataConfigAdmin: false,
            entityPermissions: []
        }
        setRoles(roles => [...roles, newRole])
        setSelectedRoleReference(newRole.reference)
    }

    const onRoleDuplicated = (roleToDuplicate: Role) => {
        const duplicateRole = { ...roleToDuplicate, reference: uuidv4(), name: `${roleToDuplicate.name} - copy` }
        setRoles(roles => [...roles, duplicateRole])
        setSelectedRoleReference(duplicateRole.reference)
    }

    return (
        <div className="d-flex w-100 h-100">
            <Loading isLoading={getRolesRequest.isFetching || getEntityConfigsRequest.isFetching} colour="blue">
                <RoleList
                    roles={roles}
                    originalRoles={originalRoles}
                    selectedRoleReference={selectedRoleReference}
                    onRoleSelected={setSelectedRoleReference}
                    onNewRoleAdded={onNewRoleAdded}
                    onRoleRemoved={onRoleRemoved}
                />
                <div className={`d-flex w-100 h-100 ${classes.background}`}>
                    {selectedRole === undefined ? (
                        <div className="d-flex flex-grow-1 justify-content-center mt-4">
                            <span className="text-white fs-5">Please select an existing role or add a new one</span>
                        </div>
                    ) : (
                        <>
                            <RoleConfig role={selectedRole} onRoleUpdated={onRoleUpdated} entityConfigs={entityConfigs} />
                            <RoleActions
                                role={selectedRole}
                                originalRole={originalRoles.find(role => role.reference === selectedRoleReference)}
                                onRoleUpdated={onRoleUpdated}
                                onRoleSaved={onRoleSaved}
                                onRoleDeleted={onRoleDeleted}
                                onRoleDuplicated={onRoleDuplicated}
                            />
                        </>
                    )}
                </div>
            </Loading>
        </div>
    )
}

export default Roles
