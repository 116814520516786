import React, { useState, useEffect } from "react"
import { useRepaymentPlan, createPlan } from "../../contexts/repaymentPlanContext"
import PlanGrid from "./planGrid"
import PlanForm from "./planForm"
import WelcomeNewPlan from "./welcomeNewPlan"
import PlanDescription from "./planDescription"
import { frequencyIds, typeIds } from "./repaymentConstants"
import { useDispatch } from "react-redux"
import { executeAuthAsyncPost, executeAsyncResetPost } from "../../utility/asyncSupport"
import { ConfrimDialog } from "../common/confirmDialog"
import { displayErrorMessage } from "../../utility/error"
import ComponentLoader from "../common/ComponentLoader"
import { useClient } from "invevo-react-components"
import classes from "./repaymentPlan.module.scss"

const defaultFormState = {
    amount: null,
    interestRate: 0,
    frequencyId: frequencyIds.WEEKLY,
    type: typeIds.term,
    term: 0,
    instalments: 0,
    paymentDayId: 0,
    paymentsAmount: null,
    firstPayment: null,
    lastPayment: null,
    startDate: new Date()
}

const NewPlan = ({ accountId, onComplete }) => {
    const client = useClient()
    const [status, setStatus] = useState("idle")
    const [isValid, setIsValid] = useState(false)
    const [formState, setFormState] = useState({})
    const [showDialog, setShowDialog] = useState(false)

    const [{ repaymentPlan }, repaymentPlanDispatch] = useRepaymentPlan()

    const dispatch = useDispatch()

    useEffect(() => setFormState(defaultFormState), [setFormState])

    const onCreate = criteria => {
        createPlan(repaymentPlanDispatch, criteria)
        setStatus("created")
    }

    const onClose = () => setShowDialog(false)

    const onCommit = () => setShowDialog(true)

    const onConfirm = () => {
        setStatus("committing")
        const rp = repaymentPlan
        const invoices = rp.sourceInvoices.map(i => {
            return {
                transactionId: i.TransactionId,
                invoiceNumber: i.InvoiceNumber,
                amountInLocalCurrency: i.AmountInLocalCurrency,
                invoiceDate: i.InvoiceDate,
                dueDate: i.DueDate,
                currency: i.Currency
            }
        })

        const instalments = rp.scheduleRows.map(r => {
            return {
                instalmentDate: r.PaymentDate,
                amountInLocalCurrency: r.PrincipalSum,
                interestToPlanDate: r.Interest,
                paymentValue: r.PaymentValue
            }
        })

        const repaymentPlanCommitRequest = {
            customerId: accountId,
            interestRatePercent: rp.interestNumber,
            InterestPeriod: rp.frequencyId,
            StartDate: rp.startDate,
            NumberPayments: rp.numberOfPayments,
            DayPaymentTaken: rp.paymentDayId,
            TotalAmountLocalCurrency: rp.sourceInvoicesTotalAmount,
            TotalInterest: rp.sourceInvoicesTotalInterests,
            TotalOfRepaymentPlan: rp.totalOfRepaymentPlan,
            invoices,
            instalments,
            actual: [],
            Currency: rp.currency
        }
        onClose()
        dispatch(executeAsyncResetPost("COMMIT_REPAYMENT_PLAN_REQUEST"))
        dispatch(
            executeAuthAsyncPost(
                client,
                "RepaymentPlan/CommitRepaymentPlan",
                "COMMIT_REPAYMENT_PLAN_REQUEST",
                repaymentPlanCommitRequest,
                () => {
                    setStatus("committed")
                    onComplete()
                },
                error => {
                    setStatus("rejected")
                    displayErrorMessage(error)
                }
            )
        )
    }

    const onEdit = () => setStatus("new")

    if (status === "idle") {
        return <WelcomeNewPlan onCreateNew={onEdit} />
    }

    if (status === "new") {
        return <PlanForm onCreate={onCreate} isValid={isValid} setIsValid={setIsValid} formState={formState} setFormState={setFormState} />
    }

    if (status === "rejeted") {
        return <div className="m-3">Sorry but there is a problem.</div>
    }

    return (
        <div className="container-fluid">
            {status === "committing" && <ComponentLoader />}
            <div className="row">
                <div className="col-12">
                    <PlanDescription {...formState} completionDate={repaymentPlan.endDate} currency={repaymentPlan.currency} />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <PlanGrid repaymentPlan={repaymentPlan} />
                </div>
            </div>
            <div
                className="row my-3"
                style={{
                    maxWidth: "530px"
                }}
            >
                <div className="col-6">
                    <button className={`btn ${classes["btn-custom"]} w-100`} aria-label="commit-to-plan" onClick={onCommit}>
                        <i className="fa-regular fa-signature me-2"></i>
                        Commit to plan
                    </button>
                </div>

                <div className="col-6">
                    <button className={`btn ${classes["btn-custom"]} w-100`} onClick={onEdit}>
                        <i className="fa-solid fa-edit me-2"></i>
                        Edit
                    </button>
                </div>
            </div>

            <ConfrimDialog show={showDialog} message={"Are you sure you want to commit this plan?"} onConfirm={onConfirm} onClose={onClose} />
        </div>
    )
}

export default NewPlan
