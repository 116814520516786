import DataField from "../../../types/DataField"
import QuickFilter from "../../automate/types/QuickFilter"
import SortDirection from "../../../types/enums/SortDirection"

export type CustomerSearchState = {
    pageIndex: number
    pageSize: number
    sortByField: DataField
    sortDirection: SortDirection
    selectedQuickFilters: QuickFilter[]
    showQuickFilters: boolean
}

export enum CustomerSearchActionEnum {
    PAGE_INDEX_UPDATED,
    PAGE_SIZE_UPDATED,
    SORT_BY_FIELD_UPDATED,
    SORT_DIRECTION_UPDATED,
    TOGGLE_QUICK_FILTER,
    REMOVE_QUICK_FILTER,
    TOGGLE_SHOW_QUICK_FILTERS,
    CLEAR_QUICK_FILTERS,
    UPDATE_GRID_STATE
}

type UpdateGridStateAction = {
    type: CustomerSearchActionEnum.UPDATE_GRID_STATE
    state: CustomerSearchState
}

type PageIndexUpdatedAction = {
    type: CustomerSearchActionEnum.PAGE_INDEX_UPDATED
    pageIndex: number
}

type PageSizeUpdatedAction = {
    type: CustomerSearchActionEnum.PAGE_SIZE_UPDATED
    pageSize: number
}

type SortByFieldUpdatedAction = {
    type: CustomerSearchActionEnum.SORT_BY_FIELD_UPDATED
    sortByField: DataField
}

type SortDirectionUpdatedAction = {
    type: CustomerSearchActionEnum.SORT_DIRECTION_UPDATED
    sortDirection: SortDirection
}

type ToggleQuickFilterAction = {
    type: CustomerSearchActionEnum.TOGGLE_QUICK_FILTER
    selectedQuickFilters: QuickFilter[]
    toggledQuickFilter: QuickFilter
}

type RemoveQuickFilterAction = {
    type: CustomerSearchActionEnum.REMOVE_QUICK_FILTER
    selectedQuickFilters: QuickFilter[]
    removedQuickFilter: QuickFilter
}

type ToggleShowQuickFiltersAction = {
    type: CustomerSearchActionEnum.TOGGLE_SHOW_QUICK_FILTERS
}

type ClearQuickFiltersAction = {
    type: CustomerSearchActionEnum.CLEAR_QUICK_FILTERS
}

export type CustomerSearchAction =
    | PageIndexUpdatedAction
    | PageSizeUpdatedAction
    | SortByFieldUpdatedAction
    | SortDirectionUpdatedAction
    | ToggleQuickFilterAction
    | RemoveQuickFilterAction
    | ToggleShowQuickFiltersAction
    | ClearQuickFiltersAction
    | UpdateGridStateAction

export default function CustomerSearchReducer(state: CustomerSearchState, action: CustomerSearchAction): CustomerSearchState {
    switch (action.type) {
        case CustomerSearchActionEnum.PAGE_INDEX_UPDATED: {
            return {
                ...state,
                pageIndex: action.pageIndex
            }
        }
        case CustomerSearchActionEnum.PAGE_SIZE_UPDATED: {
            return {
                ...state,
                pageSize: action.pageSize
            }
        }
        case CustomerSearchActionEnum.SORT_BY_FIELD_UPDATED: {
            return {
                ...state,
                sortByField: action.sortByField
            }
        }
        case CustomerSearchActionEnum.SORT_DIRECTION_UPDATED: {
            return {
                ...state,
                sortDirection: action.sortDirection
            }
        }
        case CustomerSearchActionEnum.TOGGLE_QUICK_FILTER: {
            let updatedQuickFilters = []
            if (action.selectedQuickFilters.some(quickFilter => quickFilter.filter === action.toggledQuickFilter.filter)) {
                updatedQuickFilters = action.selectedQuickFilters.filter(quickFilter => quickFilter.filter !== action.toggledQuickFilter.filter)
            } else {
                updatedQuickFilters = [...action.selectedQuickFilters, action.toggledQuickFilter]
            }

            return {
                ...state,
                selectedQuickFilters: updatedQuickFilters
            }
        }
        case CustomerSearchActionEnum.REMOVE_QUICK_FILTER: {
            return {
                ...state,
                selectedQuickFilters: action.selectedQuickFilters.filter(quickFilter => quickFilter !== action.removedQuickFilter)
            }
        }
        case CustomerSearchActionEnum.TOGGLE_SHOW_QUICK_FILTERS: {
            return {
                ...state,
                showQuickFilters: !state.showQuickFilters
            }
        }
        case CustomerSearchActionEnum.CLEAR_QUICK_FILTERS: {
            return {
                ...state,
                selectedQuickFilters: []
            }
        }
        case CustomerSearchActionEnum.UPDATE_GRID_STATE: {
            return { ...action.state }
        }
    }
}
