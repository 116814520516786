import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  executeAuthAsyncGet,
  executeAuthAsyncPost,
  executeAsyncResetGet
} from '../../legacy/utility/asyncSupport';
import { getLookup } from '../../legacy/api/lookupApi';

import LookupAdminGrid from '../../legacy/components/admin/lookup/lookupAdminGrid';
import LookupTransfer from '../../legacy/components/admin/lookup/lookupTransfer';
import LookupTransferAll from '../../legacy/components/admin/lookup/lookupTransferAll';
import { showToastErrorMessage } from '../../legacy/api/toasterApi.js';
import AddLookup from '../../legacy/components/admin/lookup/addLookup';
import { Collapse } from 'react-bootstrap';
import StatefulSelect from '../../legacy/components/common/statefulSelect';

export class LookupAdminContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = LookupAdminContainer.defaultFormState();
  }

  componentDidMount() {
    this.props.getLookup(this.props.client, 'lookup/adminlookups', 'adminLookups');
  }

  static defaultFormState() {
    return {
      LookupAdminId: 0,
      LookupAdminDesc: '',
      showAddLookupButton: true,
      LineItemId: null,
      CurrentLookup: [],
      LookupLineItemId: null,
      refreshGrid: false,
      IsEditMode: false,
      show: {
        add: false,
        grid: true
      }
    };
  }

  getLookupDefinition = (lookupId) => {
    if (lookupId) {
      this.props.executeAuthAsyncGet(
        this.props.client,
        'lookupadmin/getdefinitions',
        'LOOKUP_DEFINITIONS',
        { lookupId: lookupId },
        (result) => {
          this.setState((previousState) => ({
            ...previousState,
            CurrentLookup: result
          }));
        },
        (error) => {
          showToastErrorMessage(
            'Error while loading lookup definition.' + error.message
          );
        }
      );
    }
  };

  onLookupAdminChange = (value) => {
    const type = this.props.adminLookups.find((t) => t.Id === value);
    this.setState(
      (previousState) => ({
        ...previousState,
        LookupAdminId: type.Id,
        LookupAdminDesc: type.Name,
        LookupLineItemId: null
      }),
      this.getLookupDefinition(type.Id)
    );
  };

  onAddNewLookup = () => {
    this.setState((previousState) => ({
      ...previousState,
      showAddLookupButton: false,
      LookupLineItemId: null,
      IsEditMode: false,
      show: {
        add: true,
        grid: false
      }
    }));
  };

  addLookupCallback = (addLookupFunction) => {
    this.addLookup = addLookupFunction;
  };

  editLookupCallback = (editLookupFunction) => {
    this.editLookup = editLookupFunction;
  };

  onEditLookup = (lookupLineItemId) => {
    this.getLookupDefinition(this.state.LookupAdminId);

    this.setState(
      (previousState) => ({
        ...previousState,
        showAddLookupButton: false,
        LookupLineItemId: lookupLineItemId,
        IsEditMode: true,
        show: {
          add: true,
          grid: false
        }
      }),
      () => {
        this.editLookup(lookupLineItemId);
        this.refreshLookupGrid();
      }
    );
  };

  refreshGrid = () => {
    this.setState(
      (previousState) => ({
        ...previousState,
        refreshGrid: !previousState.refreshGrid
      }),
      this.afterDefinition
    );
  };

  lookupGridRefreshCallback = (refreshGrid) => {
    this.refreshLookupGrid = refreshGrid;
  };

  lookupSaved = () => {
    this.refreshGrid();
    this.refreshLookupGrid();
    this.getLookupDefinition(this.state.LookupAdminId);
    this.setState({
      showAddLookupButton: true,
      show: {
        add: false,
        grid: true
      }
    });
  };

  addLookupCancelled = () => {
    this.refreshGrid();
    this.refreshLookupGrid();
    this.setState((previousState) => ({
      ...previousState,
      refreshGrid: !previousState.refreshGrid,
      showAddLookupButton: true,
      IsEditMode: false,
      show: {
        add: false,
        grid: true
      }
    }));
  };

  showTransfer = () => {
    if (this.state.LookupAdminId !== 0) {
      return (
        <LookupTransfer
          client={this.props.client}
          lookupId={this.state.LookupAdminId}
          tableName={this.state.LookupAdminDesc}
        />
      );
    } else {
      return <span />;
    }
  };

  renderAddButton = () => {
    if (this.state.LookupAdminId !== 0) {
      return (
        <button
          id="btn-add-lookup"
          className="btn btn-default"
          type="button"
          onClick={this.onAddNewLookup.bind(this)}
        >
          Add New Lookup
        </button>
      );
    } else {
      return <span />;
    }
  };

  renderAddLookupForm = (lookup) => {
    if (this.state.LookupAdminId !== 0 && lookup.length > 0) {
      return (
        <AddLookup
          client={this.props.client}
          onSaved={this.lookupSaved}
          onCancelled={this.addLookupCancelled}
          onAdd={this.addLookupCallback}
          onEdit={this.editLookupCallback}
          Lookup={lookup[0]}
          LookupLineItemId={this.state.LookupLineItemId}
          IsEditMode={this.state.IsEditMode}
        />
      );
    } else {
      return <span />;
    }
  };

  renderGrid = () => {
    if (this.state.LookupAdminId !== 0) {
      return (
        <div>
          <LookupAdminGrid
            client={this.props.client}
            lookupId={this.state.LookupAdminId}
            tableName={this.state.LookupAdminDesc}
            refreshCallback={this.state.refreshGrid}
            onEdit={this.onEditLookup}
            lookupGridRefreshCallback={this.lookupGridRefreshCallback}
          />
        </div>
      );
    } else {
      return <span />;
    }
  };

  render() {
    return (
      <div className="d-flex flex-column flex-grow-1">
        <h2>Lookup Management</h2>
        <Collapse in={this.state.show.grid}>
          <div className="mb-5">
            <LookupTransferAll client={this.props.client} />

            <hr />

            <h3>Lookup</h3>
            <StatefulSelect
              className="w-25"
              options={this.props.adminLookups.map((m) => ({
                value: m.Id,
                label: m.Name
              }))}
              optionId={this.state.LookupAdminId}
              onChange={this.onLookupAdminChange}
              placeholder={'Select Lookup'}
            />
            {this.showTransfer()}
            <br />
            {this.renderAddButton()}
            {this.renderGrid()}
          </div>
        </Collapse>
        <Collapse in={this.state.show.add}>
          <div>{this.renderAddLookupForm(this.state.CurrentLookup)}</div>
        </Collapse>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    adminLookups: state.lookupReducer.adminLookups.results
  }),
  {
    getLookup,
    executeAuthAsyncGet,
    executeAuthAsyncPost,
    executeAsyncResetGet
  },
  null,
  { forwardRef: true }
)(LookupAdminContainer);
