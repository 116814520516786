import React, { Component } from 'react';
import { connect } from 'react-redux';

class AddFilter extends Component {
  constructor(props, context) {
    super(props, context);

    this.add = this.add.bind(this);
    this.cancel = this.cancel.bind(this);
    this.onDefaultChanged = this.onDefaultChanged.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.renderFilterLabel = this.renderFilterLabel.bind(this);
    this.renderAvailableFilters = this.renderAvailableFilters.bind(this);
    this.renderDefault = this.renderDefault.bind(this);
    this.renderOperator = this.renderOperator.bind(this);
    this.onOperatorChanged = this.onOperatorChanged.bind(this);
    this.renderOperatorOptions = this.renderOperatorOptions.bind(this);
    this.renderOperatorLabel = this.renderOperatorLabel.bind(this);

    this.validateForm = this.validateForm.bind(this);

    this.state = {
      filter: { filterId: null, name: '', default: '', operator: '' },
      canAdd: false
    };
  }

  add() {
    if (this.props.onAdd != null) {
      this.props.onAdd(this.state.filter);
    }
  }

  cancel() {
    if (this.props.onCancel != null) {
      this.props.onCancel();
    }
  }

  onDefaultChanged(e) {
    const val = e.target.value;

    this.setState(
      (previousState) => ({
        ...previousState,
        filter: {
          ...previousState.filter,
          default: val
        }
      }),
      this.validateForm
    );
  }

  onFilterChanged(value) {
    const p = this.props.addableFilters.find((t) => t.Id === value);

    this.setState(
      (previousState) => ({
        ...previousState,
        filter: {
          ...previousState.filter,
          filterId: p.Id,
          name: p.Name,
          operator: this.props.isAdvancedFilterMode
            ? previousState.filter.operator
            : ''
        }
      }),
      this.validateForm
    );
  }

  onOperatorChanged(value) {
    this.setState(
      (previousState) => ({
        ...previousState,
        filter: {
          ...previousState.filter,
          operator: value
        }
      }),
      this.validateForm
    );
  }

  validateForm() {
    let canAdd = this.state.filter.filterId !== null;
    if (this.props.isAdvancedFilterMode) {
      canAdd = canAdd && this.state.filter.operator !== '';
    }
    this.setState({ canAdd: canAdd });
  }

  renderFilterLabel() {
    let label = 'Select Filter ';
    if (this.state.filter.filterId) {
      label = 'Filter: ' + this.state.filter.name;
    }
    return label;
  }

  renderAvailableFilters() {
    const markup = this.props.addableFilters.map((t) => {
      const key = 'filter' + t.Id;
      return (
        <li
          key={key}
          className="dropdown-item"
          onClick={this.onFilterChanged.bind(this, t.Id)}
        >
          {t.Name}
        </li>
      );
    });
    return markup;
  }

  renderDefault() {
    return (
      <div className="form-group">
        <input
          id="add-filter-default"
          className="form-control"
          type="text"
          placeholder="Default"
          value={this.state.filter.default}
          onChange={this.onDefaultChanged}
          aria-describedby="defaultHelpBlock"
        />
        <span id="defaultHelpBlock" className="help-block">
          Special characters: [-] Brackets indicate a range eg. [A-Z], *
          Wildcard matches any characters eg. Bob*. <br />
          Placeholders supported: {'{'}UserName{'}'} and {'{'}ContactName{'}'}.
        </span>
      </div>
    );
  }

  renderOperator() {
    if (!this.props.isAdvancedFilterMode) {
      return '';
    }

    const markup = (
      <div className="form-group">
        <ul className="nav" id="addFilterlist">
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              id="dropdownMenuButton"
              className="nav-link dropdown-toggle boldTitle noPaddingLeft"
              data-bs-toggle="dropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
            >
              {this.renderOperatorLabel()}
              <b className="caret"></b>
            </a>
            <ul aria-labelledby="dropdownMenuButton" className="dropdown-menu ddscroll">
              {this.renderOperatorOptions()}
            </ul>
          </li>
        </ul>
      </div>
    );

    return markup;
  }

  renderOperatorLabel() {
    let label = 'Select Operator ';
    if (this.state.filter.operator !== '') {
      label = 'Operator: ' + this.state.filter.operator;
    }
    return label;
  }

  renderOperatorOptions() {
    const operators = ['And', 'Or'];
    const items = operators.map((t) => {
      const key = 'operator' + t;
      return (
        <li
          key={key}
          className="dropdown-item"
          onClick={this.onOperatorChanged.bind(this, t)}
        >
          {t}
        </li>
      );
    });
    return items;
  }

  render() {
    return (
      <div className="sub-form">
        <div className="form-group">
          <ul className="nav" id="addFilterlist">
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                id="dropdownMenuButton"
                className="nav-link dropdown-toggle boldTitle noPaddingLeft"
                data-bs-toggle="dropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                {this.renderFilterLabel()}
                <b className="caret"></b>
              </a>
              <ul aria-labelledby="dropdownMenuButton" className="dropdown-menu ddscroll">
                {this.renderAvailableFilters()}
              </ul>
            </li>
          </ul>
        </div>
        {this.renderDefault()}
        {this.renderOperator()}
        <button
          id="btnAddFilter"
          type="button"
          onClick={this.add}
          className="btn btn-default"
          disabled={!this.state.canAdd}
        >
          Add Filter
        </button>
        <button type="button" onClick={this.cancel} className="btn btn-default">
          Cancel
        </button>
      </div>
    );
  }
}

export default connect()(AddFilter);
