import { Input } from "invevo-react-components"
import { FileImportConfigColumnType, FileImportConfigColumnTypeEnum } from "../../../../types/FileImportConfigColumnType"

type ExportColumnProps = {
    column: FileImportConfigColumnType
    onChange: (column: FileImportConfigColumnType) => any
}

const ExportColumn = ({ column, onChange }: ExportColumnProps) => {
    const onHeaderChange = (event: React.FormEvent<HTMLInputElement>) => {
        const header = event.currentTarget.value

        onChange({
            ...column,
            header
        } as FileImportConfigColumnType)
    }

    const onIndexChange = (event: React.FormEvent<HTMLInputElement>) => {
        const index = parseInt(event.currentTarget.value)
        if (isNaN(index)) return

        onChange({
            ...column,
            index
        } as FileImportConfigColumnType)
    }

    return (
        <div className="d-flex align-items-center w-100 h-100">
            {column.type === FileImportConfigColumnTypeEnum.HEADER ? (
                <div className={`d-flex flex-column flex-grow-1 small`}>
                    <span className="text-uppercase small mb-1 ms-1 text-grey">Header name</span>
                    <Input placeholder="Please enter the column header" value={column.header} onChange={onHeaderChange} />
                </div>
            ) : column.type === FileImportConfigColumnTypeEnum.INDEX ? (
                <div className={`d-flex flex-column flex-grow-1 small`}>
                    <span className="text-uppercase small mb-1 ms-1 text-grey">Column index</span>
                    <Input placeholder="Please enter the column index" value={column.index} onChange={onIndexChange} type="number" />
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default ExportColumn
