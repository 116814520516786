import { AutoComplete } from "invevo-react-components"
import React from "react"
import Checkbox from "../../../../../library/form/Checkbox"
import Input from "../../../../../library/Inputs/Input"
import NumberInput from "../../../../../library/Inputs/NumberInput"
import {
    FieldConfigState,
    FileImportConfigColumnTypeState,
    DataFileImportConfigState,
    FileImportConfigAction,
    FileImportConfigActionEnum
} from "../../../reducers/FileEntityImportConfigReducer"
import { FileType } from "../../../types/FileImportConfig"
import { FileImportConfigColumnTypeEnum } from "../../../types/FileImportConfigColumnType"
import { RejectMandatoryActionEnum } from "../../../types/RejectMandatoryActionEnum"
import { delimiters, lineEndings } from "../../utils"

import classes from "./DataFileImportOptions.module.scss"
import RejectMandatoryActionConfig from "./RejectMandatoryActionConfig"

type DataFileOptionsProps = {
    className?: string
    state: DataFileImportConfigState
    dispatch: React.Dispatch<FileImportConfigAction>
}

const DataFileImportFileConfigOptions = ({ className, state, dispatch }: DataFileOptionsProps) => {
    const onFileNamePatternChange = (pattern: string) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            dataFileImportConfig: {
                ...state,
                fileNamePattern: pattern
            }
        })
    }

    const onDelimiterChange = (option: string) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            dataFileImportConfig: {
                ...state,
                delimiter: option
            }
        })
    }

    const onLineEndingChange = (option: string) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            dataFileImportConfig: {
                ...state,
                lineEnding: option
            }
        })
    }

    const onRejectMandatoryActionChange = (newRejectMandatoryAction: RejectMandatoryActionEnum) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            dataFileImportConfig: {
                ...state,
                rejectMandatoryAction: newRejectMandatoryAction
            }
        })
    }

    const onHasHeaderRowChange = () => {
        const hasHeaderRow = !state.hasHeaderRow
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            dataFileImportConfig: {
                ...state,
                hasHeaderRow: hasHeaderRow,
                mandatoryFieldConfig: {
                    ...state.mandatoryFieldConfig,
                    columns: [
                        ...state.mandatoryFieldConfig.columns.map(
                            (column: FileImportConfigColumnTypeState) =>
                                ({
                                    key: column.key,
                                    type: hasHeaderRow ? FileImportConfigColumnTypeEnum.HEADER : FileImportConfigColumnTypeEnum.INDEX,
                                    header: "",
                                    index: ""
                                } as FileImportConfigColumnTypeState)
                        )
                    ]
                },
                ...(state.type === FileType.TRANSACTION && {
                    mandatoryCustomerFieldConfig: {
                        ...state.mandatoryCustomerFieldConfig,
                        columns: [
                            ...state.mandatoryCustomerFieldConfig.columns.map(
                                (column: FileImportConfigColumnTypeState) =>
                                    ({
                                        key: column.key,
                                        type: hasHeaderRow ? FileImportConfigColumnTypeEnum.HEADER : FileImportConfigColumnTypeEnum.INDEX,
                                        header: "",
                                        index: ""
                                    } as FileImportConfigColumnTypeState)
                            )
                        ]
                    }
                }),
                optionalFieldConfigs: [
                    ...state.optionalFieldConfigs.map((field: FieldConfigState) => ({
                        ...field,
                        columns: [
                            ...field.columns.map(
                                (column: FileImportConfigColumnTypeState) =>
                                    ({
                                        key: column.key,
                                        type: hasHeaderRow ? FileImportConfigColumnTypeEnum.HEADER : FileImportConfigColumnTypeEnum.INDEX,
                                        header: "",
                                        index: ""
                                    } as FileImportConfigColumnTypeState)
                            )
                        ]
                    }))
                ]
            }
        })
    }

    const onHasQuotedFieldsChange = () => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            dataFileImportConfig: {
                ...state,
                hasQuotedFields: !state.hasQuotedFields
            }
        })
    }

    const onIsOpenItemsChange = () => {
        if (state.type === FileType.TRANSACTION)
            dispatch({
                type: FileImportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
                dataFileImportConfig: {
                    ...state,
                    isOpenItems: !state.isOpenItems
                }
            })
    }

    const onOpenItemsThresholdPercentageChange = (value?: string) => {
        if (state.type === FileType.TRANSACTION) {
            dispatch({
                type: FileImportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
                dataFileImportConfig: {
                    ...state,
                    openItemsThresholdPercentage: value
                }
            })
        }
    }

    return (
        <div className={`d-flex flex-column w-100 h-100 ${className ? className : ""}`}>
            <div className="d-flex my-1 mx-3">
                <span className="text-grey fs-4">Data file configuration</span>
            </div>
            <div className={`${classes.horizontalDivider} mx-2`} />
            <div className="d-flex p-3">
                <div className="d-flex flex-column small me-3">
                    <span className="text-uppercase small mb-1 ms-1 text-grey">File name pattern</span>
                    <Input placeholder="Please enter the file name pattern" value={state.fileNamePattern} onChange={onFileNamePatternChange} />
                </div>
                <div className={`d-flex flex-column small me-3`}>
                    <span className="text-uppercase small mb-1 ms-1 text-grey">Delimiter</span>
                    <AutoComplete
                        ariaLabel="delimiter"
                        placeholder="Please select a delimiter"
                        options={delimiters}
                        initialValue={state.delimiter}
                        onChange={onDelimiterChange}
                    />
                </div>
                <div className={`d-flex flex-column small`}>
                    <span className="text-uppercase small mb-1 ms-1 text-grey">Line ending</span>
                    <AutoComplete
                        placeholder="Please select a line ending"
                        options={lineEndings}
                        initialValue={state.lineEnding}
                        onChange={onLineEndingChange}
                    />
                </div>
                <div className="d-flex flex-grow-1 justify-content-end">
                    <div className={`d-flex flex-column small`}>
                        <span className="text-uppercase small mb-1 ms-1 text-grey">Reject action for mandatory fields</span>
                        <RejectMandatoryActionConfig rejectMandatoryAction={state.rejectMandatoryAction} onChange={onRejectMandatoryActionChange} />
                    </div>
                </div>
            </div>
            <div className={`${classes.horizontalDivider} mx-2`} />
            <div className="d-flex align-items-center p-3">
                <Checkbox
                    className="small me-4"
                    isChecked={state.hasHeaderRow}
                    label="Has header row"
                    onClick={onHasHeaderRowChange}
                    ariaLabel="Has header row"
                />
                <Checkbox className="small me-4" isChecked={state.hasQuotedFields} label="Has quoted fields" onClick={onHasQuotedFieldsChange} />
                {state.type === FileType.TRANSACTION && (
                    <Checkbox className="small" isChecked={state.isOpenItems} label="Is open items" onClick={onIsOpenItemsChange} />
                )}
            </div>
            {state.type === FileType.TRANSACTION && state.isOpenItems && (
                <div className="d-flex flex-column">
                    <div className={`${classes.horizontalDivider} mx-2`} />
                    <span className="text-grey fs-4 mx-3 mt-1">Open items configuration</span>
                    <div className="d-flex align-items-center p-3 text-grey">
                        <span>Fail file if over</span>
                        <NumberInput
                            className="mx-2"
                            placeholder="Please enter percentage"
                            value={state.openItemsThresholdPercentage ? `${state.openItemsThresholdPercentage}` : undefined}
                            onChange={onOpenItemsThresholdPercentageChange}
                            suffixIconClasses="far fa-percentage"
                        />
                        <span>of transactions will be closed</span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DataFileImportFileConfigOptions
