import { useState } from "react"
import WorkflowRules from "./workflowRules/WorkflowRules"
import { Loading, useConfig } from "invevo-react-components"
import ScheduledWorkflowSetup from "./workflowSetup/ScheduledWorkflowSetup"
import WorkflowRuns from "./workflowRuns/WorkflowRuns"
import useApiQuery from "../../../hooks/useApiQuery"
import ScheduledWorkflowRule, { convertWorkflowRuleFromDto } from "../types/ScheduledWorkflowRule"
import Lookup from "../../../types/Lookup"
import DataField from "../../../types/DataField"
import classes from "./WorkflowsDashboard.module.scss"
import GetScheduledWorkflowRuleDto from "../types/GetScheduledWorkflowRuleDto"
import { v4 as uuidv4 } from "uuid"
import LegacySmsTemplate from "../types/LegacySmsTemplate"
import WorkflowUserActions from "./workflowSetup/WorkflowUserActions"
import PillButtons from "../../../library/buttons/PillButtons"
import PillButton from "../../../library/buttons/PillButton"
import DataUpdatedWorkflowRule, { convertDataUpdatedWorkflowRuleFromDto } from "../types/DataUpdatedWorkflowRule"
import WorkflowRulesHeader from "./workflowRules/WorkflowRulesHeader"
import FieldType from "../../../types/enums/FieldType"
import DataUpdatedWorkflowSetup from "./workflowSetup/DataUpdatedWorkflowSetup"
import WorkflowRule from "../types/WorkflowRule"
import GetDataUpdatedWorkflowRuleDto from "../types/GetDataUpdatedWorkflowRuleDto"
import EntityConfig from "../../entityConfig/types/EntityConfig"
import { FileEntityExportConfig } from "../../integration/types/fileEntityExport/FileExportConfig"
import { getDataFieldsFromEntityDataFields } from "../../../library/helpers/entityHelpers"
import EntityRelationshipDto from "../../entityConfig/types/EntityRelationshipDto"
import WorkflowFlow from "./workflowFlow/WorkflowFlow"
import useClient from "../../../hooks/useClient"
import { exists } from "../../../library/helpers/tsUtils"
import FileImportConfig from "../types/FileImportConfig"
import { WorkflowTriggerConfig } from "../types/WorkflowTriggerConfigs"

type WorkflowsDashboardProps = {
    entityConfigs: EntityConfig[]
    customerFields: DataField[]
    lookups: Lookup[]
    fileEntityExportConfigs: FileEntityExportConfig[]
    smsTemplates: LegacySmsTemplate[]
    entityRelationships: EntityRelationshipDto[]
    workflowTriggerConfigs: WorkflowTriggerConfig[]
    fileImportConfigs: FileImportConfig[]
    isNewEntityStructureEnabled: boolean
}

const WorkflowsDashboard = ({
    entityConfigs,
    customerFields,
    lookups,
    fileEntityExportConfigs,
    smsTemplates,
    entityRelationships,
    workflowTriggerConfigs,
    fileImportConfigs,
    isNewEntityStructureEnabled
}: WorkflowsDashboardProps) => {
    const [isViewingSetup, setIsViewingSetup] = useState(true)
    const [selectedRuleReference, setSelectedRuleReference] = useState<string>()
    const [ruleType, setRuleType] = useState<"SCHEDULE" | "DATA">("SCHEDULE")

    const [originalScheduledRules, setOriginalScheduledRules] = useState<ScheduledWorkflowRule[]>([])
    const [scheduledRules, setScheduledRules] = useState<ScheduledWorkflowRule[]>([])

    const [originalDataUpdatedRules, setOriginalDataUpdatedRules] = useState<DataUpdatedWorkflowRule[]>([])
    const [dataUpdatedRules, setDataUpdatedRules] = useState<DataUpdatedWorkflowRule[]>([])

    const [originalTriggerConfigs, setOriginalTriggerConfigs] = useState<WorkflowTriggerConfig[]>(workflowTriggerConfigs)
    const [originalWorkflowTriggerConfigsForRule, setOriginalWorkflowTriggerConfigsForRule] = useState<WorkflowTriggerConfig[]>([])
    const [workflowTriggerConfigsForRule, setWorkflowTriggerConfigsForRule] = useState<WorkflowTriggerConfig[]>([])
    const [workflowTriggerConfigsTriggeredByRule, setWorkflowTriggerConfigsTriggeredByRule] = useState<WorkflowTriggerConfig[]>([])

    const [isShowingAsFlow, setIsShowingAsFlow] = useState(false)
    const onShowAsFlowClicked = () => setIsShowingAsFlow(true)
    const onBackFromFlowClicked = () => setIsShowingAsFlow(false)

    const fieldsForEntity: { entity: string; fields: DataField[] }[] = entityConfigs.map(entity => ({
        entity: entity.reference,
        fields: getDataFieldsFromEntityDataFields(entity.fields)
    }))

    const onScheduleTriggeredClicked = () => setRuleType("SCHEDULE")
    const onDataTriggeredClicked = () => setRuleType("DATA")

    const client = useClient()
    const config = useConfig()

    const setSelectedRule = (reference: string) => {
        setSelectedRuleReference(reference)
        setWorkflowTriggerConfigsForRule(
            originalTriggerConfigs
                .filter(config => config.ruleToTriggerReference === reference)
                .sort((a, b) => a.triggeredByName.localeCompare(b.triggeredByName))
        )
        setWorkflowTriggerConfigsTriggeredByRule(
            originalTriggerConfigs
                .filter(config => config.triggeredByReference === reference)
                .sort((a, b) => a.ruleToTriggerName.localeCompare(b.ruleToTriggerName))
        )
        setOriginalWorkflowTriggerConfigsForRule(originalTriggerConfigs.filter(config => config.ruleToTriggerReference === reference))
    }

    const scheduledWorkflowRulesRequest = useApiQuery<GetScheduledWorkflowRuleDto[]>({
        url: `${config.AUTOMATE_API_URL}/api/${client}/workflow-rule`,
        method: "GET",
        onSuccess: rules => {
            const rulePojos = rules.map(rule => {
                const fieldsToUse =
                    rule.entityConfigReference !== undefined
                        ? fieldsForEntity.find(pair => pair.entity === rule.entityConfigReference)?.fields ?? []
                        : customerFields
                return convertWorkflowRuleFromDto(rule, fieldsToUse, lookups)
            })
            setScheduledRules(rulePojos)
            setOriginalScheduledRules(rulePojos)

            if (selectedRuleReference === undefined && rulePojos.length > 0) {
                const firstNonArchivedRule = rulePojos.find(rule => !rule.archived)
                if (firstNonArchivedRule) {
                    setSelectedRule(firstNonArchivedRule.reference)
                    return
                }

                const firstRule = rulePojos[0]
                if (firstRule) {
                    setSelectedRule(firstRule.reference)
                }
            }
        }
    })

    const dataUpdatedWorkflowRulesRequest = useApiQuery<GetDataUpdatedWorkflowRuleDto[]>({
        url: `${config.AUTOMATE_API_URL}/api/${client}/data-updated-workflow-rule`,
        method: "GET",
        onSuccess: rules => {
            const rulePojos = rules.map(rule => convertDataUpdatedWorkflowRuleFromDto(rule, customerFields, lookups))
            setDataUpdatedRules(rulePojos)
            setOriginalDataUpdatedRules(rulePojos)
        }
    })

    const onNewRuleClicked = () => {
        const newRuleReference = uuidv4()
        if (ruleType === "SCHEDULE") {
            setScheduledRules(rules => [
                ...rules,
                {
                    type: "SCHEDULE",
                    reference: newRuleReference,
                    name: "New workflow rule",
                    entityConfigReference: isNewEntityStructureEnabled ? entityConfigs[0]?.reference ?? "" : undefined,
                    archived: false,
                    inPreviewMode: false,
                    trigger: undefined,
                    baseFilters: [],
                    actions: [],
                    modificationHistory: []
                }
            ])
        } else {
            setDataUpdatedRules(rules => [
                ...rules,
                {
                    type: "DATA",
                    reference: newRuleReference,
                    name: "New workflow rule",
                    entityConfigReference: isNewEntityStructureEnabled ? entityConfigs[0]?.reference ?? "" : undefined,
                    archived: false,
                    inPreviewMode: false,
                    dataFieldsType: FieldType.CUSTOMER,
                    matchingFiltersBeforeChange: [],
                    matchingFiltersAfterChange: [],
                    actions: [],
                    modificationHistory: []
                }
            ])
        }
        setSelectedRule(newRuleReference)
    }

    const onRuleDuplicated = (rule: WorkflowRule) => {
        const newRuleReference = uuidv4()
        const updatedRule = {
            ...rule,
            reference: newRuleReference,
            name: `${rule.name} Copy`,
            modificationHistory: []
        }

        if (rule.type === "SCHEDULE") {
            setScheduledRules(rules => [...rules, updatedRule as ScheduledWorkflowRule])
        } else {
            setDataUpdatedRules(rules => [...rules, updatedRule as DataUpdatedWorkflowRule])
        }

        setSelectedRule(newRuleReference)
    }

    const onRuleRemoved = (ruleReference: string) => {
        setScheduledRules(rules => rules.filter(rule => rule.reference !== ruleReference))
        setDataUpdatedRules(rules => rules.filter(rule => rule.reference !== ruleReference))
    }

    const onRuleUpdated = (updatedRule: WorkflowRule) => {
        if (updatedRule.type === "SCHEDULE") {
            setScheduledRules(rules => rules.map(rule => (rule.reference === updatedRule.reference ? updatedRule : rule)))
        } else {
            setDataUpdatedRules(rules => rules.map(rule => (rule.reference === updatedRule.reference ? updatedRule : rule)))
        }
    }

    const onWorkflowTriggerConfigsUpdated = (updatedConfigs: WorkflowTriggerConfig[]) => setWorkflowTriggerConfigsForRule(updatedConfigs)

    const onRuleSaved = (savedRule: WorkflowRule) => {
        if (savedRule.type === "SCHEDULE") {
            setScheduledRules(rules => rules.map(rule => (rule.reference === savedRule.reference ? savedRule : rule)))
            setOriginalScheduledRules(rules => rules.map(rule => (rule.reference === savedRule.reference ? savedRule : rule)))
        } else {
            setDataUpdatedRules(rules => rules.map(rule => (rule.reference === savedRule.reference ? savedRule : rule)))
            setOriginalDataUpdatedRules(rules => rules.map(rule => (rule.reference === savedRule.reference ? savedRule : rule)))
        }
    }

    const onTriggerConfigsSaved = (savedConfigs: WorkflowTriggerConfig[]) => {
        const configsToRemove = originalWorkflowTriggerConfigsForRule.filter(
            config => !savedConfigs.some(savedConfig => savedConfig.reference === config.reference)
        )
        const configsToAdd = savedConfigs.filter(
            savedConfig => !originalWorkflowTriggerConfigsForRule.some(config => config.reference === savedConfig.reference)
        )

        setOriginalTriggerConfigs(originalConfigs =>
            originalConfigs
                .filter(originalConfig => !configsToRemove.some(configToRemove => configToRemove.reference === originalConfig.reference))
                .map(originalConfig => savedConfigs.find(savedConfig => savedConfig.reference === originalConfig.reference) ?? originalConfig)
                .concat(configsToAdd)
        )

        setOriginalWorkflowTriggerConfigsForRule(savedConfigs)
    }

    const onWorkflowSetupClicked = () => setIsViewingSetup(true)
    const onWorkflowRunsClicked = () => setIsViewingSetup(false)

    const selectedRuleModified =
        scheduledRules.find(rule => rule.reference === selectedRuleReference) ?? dataUpdatedRules.find(rule => rule.reference === selectedRuleReference)

    const selectedRuleOriginal =
        originalScheduledRules.find(rule => rule.reference === selectedRuleReference) ??
        originalDataUpdatedRules.find(rule => rule.reference === selectedRuleReference)

    const usingEntityRules = isNewEntityStructureEnabled && exists(selectedRuleModified?.entityConfigReference)

    const fieldsForSelectedRule = usingEntityRules
        ? fieldsForEntity.find(pair => pair.entity === selectedRuleModified?.entityConfigReference)?.fields ?? []
        : customerFields

    const getMiddleContent = () => {
        if (!isViewingSetup) {
            return <WorkflowRuns fields={fieldsForSelectedRule} lookups={lookups} smsTemplates={smsTemplates} selectedRuleReference={selectedRuleReference} />
        }

        if (selectedRuleModified?.type === "SCHEDULE") {
            return (
                <ScheduledWorkflowSetup
                    key={selectedRuleModified.reference}
                    entityConfigFields={fieldsForEntity}
                    lookups={lookups}
                    fileEntityExportConfigs={fileEntityExportConfigs}
                    customerFields={fieldsForSelectedRule}
                    smsTemplates={smsTemplates}
                    selectedRule={selectedRuleModified}
                    onRuleUpdated={onRuleUpdated}
                    onWorkflowTriggerConfigsUpdated={onWorkflowTriggerConfigsUpdated}
                    entityRelationships={entityRelationships}
                    entityConfigs={entityConfigs}
                    isNewEntityStructureEnabled={usingEntityRules}
                    fileImportConfigs={fileImportConfigs}
                    scheduledRules={scheduledRules}
                    workflowTriggerConfigs={workflowTriggerConfigsForRule}
                />
            )
        }

        if (selectedRuleModified?.type === "DATA") {
            return (
                <DataUpdatedWorkflowSetup
                    key={selectedRuleModified.reference}
                    entityConfigFields={fieldsForEntity}
                    lookups={lookups}
                    customerFields={fieldsForSelectedRule}
                    smsTemplates={smsTemplates}
                    selectedRule={selectedRuleModified}
                    onRuleUpdated={onRuleUpdated}
                    entityRelationships={entityRelationships}
                    entityConfigs={entityConfigs}
                    isNewEntityStructureEnabled={usingEntityRules}
                />
            )
        }

        return (
            <div className="d-flex flex-grow-1 justify-content-center mt-4">
                <span className="text-white fs-5">Please select a workflow or add a new one.</span>
            </div>
        )
    }

    return (
        <Loading isLoading={scheduledWorkflowRulesRequest.isFetching || dataUpdatedWorkflowRulesRequest.isFetching} colour="blue">
            <div className={`d-flex flex-column p-3 bg-grey ${classes.container}`}>
                <WorkflowRulesHeader onNewRuleClicked={onNewRuleClicked} />
                {isNewEntityStructureEnabled && (
                    <PillButtons className="mt-3">
                        <PillButton label="Schedule Triggered" isSelected={ruleType === "SCHEDULE"} onClick={onScheduleTriggeredClicked} />
                        <PillButton label="Data Triggered" isSelected={ruleType === "DATA"} onClick={onDataTriggeredClicked} />
                    </PillButtons>
                )}
                <WorkflowRules
                    rules={ruleType === "SCHEDULE" ? scheduledRules : dataUpdatedRules}
                    selectedRuleReference={selectedRuleReference}
                    isNewEntityStructureEnabled={isNewEntityStructureEnabled}
                    entityConfigs={entityConfigs}
                    onRuleSelected={setSelectedRule}
                    onRuleRemoved={onRuleRemoved}
                />
            </div>
            <div className={`d-flex flex-column h-100 w-100 overflow-auto ${classes.background}`}>
                {isShowingAsFlow && selectedRuleModified ? (
                    <WorkflowFlow
                        key={selectedRuleModified.reference}
                        fields={fieldsForSelectedRule}
                        lookups={lookups}
                        smsTemplates={smsTemplates}
                        fileEntityExportConfigs={fileEntityExportConfigs}
                        workflowRule={selectedRuleModified}
                        onBackClicked={onBackFromFlowClicked}
                    />
                ) : (
                    <>
                        <div className="mx-3 mt-3">
                            <PillButtons>
                                <PillButton label="Workflow Setup" isSelected={isViewingSetup} onClick={onWorkflowSetupClicked} />
                                <PillButton label="Workflow Run Results" isSelected={!isViewingSetup} onClick={onWorkflowRunsClicked} />
                            </PillButtons>
                        </div>
                        <div className="d-flex flex-grow-1 overflow-auto">{getMiddleContent()}</div>
                    </>
                )}
            </div>
            {isViewingSetup && !isShowingAsFlow && (
                <WorkflowUserActions
                    entityConfigs={entityConfigs}
                    dataFields={fieldsForSelectedRule}
                    selectedRuleModified={selectedRuleModified}
                    selectedRuleOriginal={selectedRuleOriginal}
                    originalWorkflowTriggerConfigsForRule={originalWorkflowTriggerConfigsForRule}
                    workflowTriggerConfigsForRule={workflowTriggerConfigsForRule}
                    workflowTriggerConfigsTriggeredByRule={workflowTriggerConfigsTriggeredByRule}
                    onRuleUpdated={onRuleUpdated}
                    onRuleSaved={onRuleSaved}
                    onTriggerConfigsSaved={onTriggerConfigsSaved}
                    onRuleDuplicated={onRuleDuplicated}
                    onViewAsFlowClicked={onShowAsFlowClicked}
                />
            )}
        </Loading>
    )
}

export default WorkflowsDashboard
