import { Loading, useClient, useConfig } from "invevo-react-components"
import { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import useApiQuery from "../../../../hooks/useApiQuery"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import { PaymentResultDto } from "../../types/dtos/PaymentResultDto"
import classes from "./TotalProcessingPaymentComplete.module.scss"

const TotalProcessingPaymentComplete = () => {
    const client = useClient()
    const config = useConfig()
    const location = useLocation()
    const history = useHistory()
    const checkoutId = new URLSearchParams(location.search).get("checkoutId")
    const entityId = new URLSearchParams(location.search).get("entityId")

    const postCompletePayment = useApiQuery<PaymentResultDto>({
        url: `${config.CREDIT_CIRCLE_API_URL}/api/complete-recurring-payment`,
        method: "POST",
        isExecutedAutomatically: false
    })

    useEffect(() => {
        postCompletePayment.execute(undefined, {
            checkoutId,
            merchantId: entityId
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onButtonClick = () => {
        history.push(`/home?client=${client}`)
    }

    return (
        <div className={`d-flex w-100 h-100 p-4 ${classes.background}`}>
            <Loading isLoading={postCompletePayment.isFetching} colour="white">
                {
                    <div className="d-flex flex-column bg-white rounded shadow p-4 align-self-start">
                        <i
                            className={`fs-1 mx-auto fa-regular ${
                                postCompletePayment.data?.isSuccess ? "fa-circle-check text-green" : "fa-circle-xmark text-red"
                            }`}
                        />
                        <span className="fs-3">
                            {postCompletePayment.data?.isSuccess ? "Success! Access your credit report below" : "Unfortunately your payment failed"}
                        </span>
                        <StandardButton
                            className="d-flex mx-auto mt-3"
                            iconClasses={postCompletePayment.data?.isSuccess ? "fal fa-right-to-bracket" : "fa-light fa-arrow-rotate-left"}
                            label={postCompletePayment.data?.isSuccess ? "View your credit report" : "Please try again"}
                            onClick={onButtonClick}
                        />
                    </div>
                }
            </Loading>
        </div>
    )
}

export default TotalProcessingPaymentComplete
