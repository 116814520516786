import React, { Component } from 'react';
import { getApiUrl } from '../../../constants';
import { isNil } from 'lodash';
import { GridColumn } from '@progress/kendo-react-grid';
import { ColumnMenuSort } from '../../common/grids/columnMenu';
import StatefulGrid from '../../common/grids/StatefulGrid';

export class EmailTemplateVersionsInnerGrid extends Component {
  static getKey = () => 'task-notes-history-grid';

  state = { selectedItemId: '' };

  isSelectedRow = (dataItem) =>
    this.state.selectedItemId === dataItem.EmailTemplateVersionId;

  onRowClick = (gridRowEvent) => {
    const dataItem = gridRowEvent.dataItem;
    const id = dataItem.EmailTemplateId;
    const versionId = dataItem.EmailTemplateVersionId;
    if (!isNil(id)) {
      this.setState({ selectedItemId: versionId });
      this.props.onRowSelected({
        emailTemplateId: id,
        emailTemplateVersionId: versionId,
        languageId: dataItem.LanguageId,
        usageTypeId: dataItem.UsageTypeId
      });
    }
  };

  gridKey = () => `temp-version-grid-${this.props.dataItem.EmailTemplateId}`;

  render() {
    return (
      <StatefulGrid
        client={this.props.client}
        id={this.gridKey()}
        key={this.gridKey()}
        path={getApiUrl(this.props.client) + 'api/email/templateVersions'}
        columnMenu={ColumnMenuSort}
        sortable
        className={'scrollable-none'}
        pageSize={10}
        pageable={{
          pageSizes: [5, 10, 20, 100],
          buttonCount: 5
        }}
        initialFilter={{
          filters: [
            {
              field: 'EmailTemplateId',
              operator: 'eq',
              value: this.props.dataItem.EmailTemplateId
            }
          ]
        }}
        isSelectedRow={this.isSelectedRow}
        onRowClick={this.onRowClick}
        defaultField={{ field: 'LanguageName' }}
        hideablePaging={true}
        refresh={this.props.refresh}
      >
        <GridColumn field={'UsageTypeDescription'} title={'Usage Type'} />
        <GridColumn field={'LanguageName'} title={'Language'} />
      </StatefulGrid>
    );
  }
}

export default EmailTemplateVersionsInnerGrid;
