import Trigger, { defaultTrigger, ruleTriggerIsEmpty } from "../../types/Trigger"
import { IconButton } from "invevo-react-components"
import ScheduledTriggerSetup from "./triggerSetup/ScheduledTriggerSetup"
import { useState } from "react"
import DropdownOption from "../../../../types/DropdownOptions"
import Dropdown from "../../../../library/dropdowns/Dropdown"
import TriggerFromRuleSetup from "./triggerSetup/TriggerFromRuleSetup"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"
import FileImportConfig from "../../types/FileImportConfig"
import ScheduledWorkflowRule from "../../types/ScheduledWorkflowRule"
import { WorkflowTriggerConfig } from "../../types/WorkflowTriggerConfigs"
import { v4 as uuidv4 } from "uuid"

const triggerTypes = [
    {
        label: "scheduled",
        value: "SCHEDULED" as const,
        element: (
            <div className="d-flex">
                <div className={`d-flex text-blue align-items-center justify-content-center me-2`}>
                    <i className="fal fa-clock" />
                </div>
                Scheduled
            </div>
        )
    },
    {
        label: "Trigger From Rule",
        value: "FROM_RULE" as const,
        element: (
            <div className="d-flex">
                <div className={`d-flex text-blue align-items-center justify-content-center me-2`}>
                    <i className="fal fa-sitemap" />
                </div>
                From Rule or File
            </div>
        )
    }
]

type Props = {
    ruleTrigger: Trigger | undefined
    onRuleTriggerChanged: (triggers: Trigger | undefined, workflowTriggerConfigs: WorkflowTriggerConfig[]) => void
    fileImportConfigs: FileImportConfig[]
    scheduledRules: ScheduledWorkflowRule[]
    selectedRule: ScheduledWorkflowRule
    workflowTriggerConfigs: WorkflowTriggerConfig[]
}

const TriggerSetup = ({ ruleTrigger, onRuleTriggerChanged, fileImportConfigs, scheduledRules, selectedRule, workflowTriggerConfigs }: Props) => {
    const getTriggerType = () => {
        if (workflowTriggerConfigs.length > 0) {
            return "FROM_RULE"
        } else if (ruleTrigger !== undefined && !ruleTriggerIsEmpty(ruleTrigger)) {
            return "SCHEDULED"
        }
        return undefined
    }

    const [triggerType, setTriggerType] = useState<"SCHEDULED" | "FROM_RULE" | undefined>(getTriggerType())

    const { isEnabled: isNewEntityStructureEnabled } = useFeatureToggle("newEntityStructure")

    const onScheduledTriggerChanged = (trigger: Trigger | undefined) => onRuleTriggerChanged(trigger, [])

    const onWorkflowTriggerConfigsChanged = (workflowTriggerConfigs: WorkflowTriggerConfig[]) => onRuleTriggerChanged(undefined, workflowTriggerConfigs)

    const onRemove = () => {
        setTriggerType(undefined)
        onRuleTriggerChanged(undefined, [])
    }

    const addTrigger = (option: DropdownOption<"FROM_RULE" | "SCHEDULED">) => {
        switch (option.value) {
            case "SCHEDULED":
                setTriggerType("SCHEDULED")
                onRuleTriggerChanged(defaultTrigger, [])
                break
            case "FROM_RULE":
                setTriggerType("FROM_RULE")
                onRuleTriggerChanged(undefined, [
                    {
                        reference: uuidv4(),
                        triggeredByType: "WORKFLOW_RULE",
                        triggeredByReference: "",
                        triggeredByName: "",
                        ruleToTriggerReference: selectedRule.reference,
                        ruleToTriggerName: selectedRule.name
                    }
                ])
                break
        }
    }

    const triggerExists = (ruleTrigger !== undefined && !ruleTriggerIsEmpty(ruleTrigger)) || workflowTriggerConfigs.length > 0

    return (
        <div className="d-flex flex-column text-grey" aria-label="trigger">
            <div className="d-flex mb-2">
                <span className="fs-2">Triggers</span>
                {!triggerExists && (
                    <div className="d-flex align-items-center ms-4">
                        <i className="fal fa-exclamation-triangle text-warning me-2 fs-4"></i>
                        <span className="fs-5 me-2">No trigger set for this workflow</span>
                    </div>
                )}
                <div className="d-flex ms-auto">
                    {triggerExists ? (
                        <IconButton iconClasses="fal fa-trash-alt" onClick={onRemove} />
                    ) : isNewEntityStructureEnabled ? (
                        <div className="d-flex flex-column">
                            <Dropdown
                                className="ms-auto"
                                options={triggerTypes}
                                onOptionSelected={addTrigger}
                                selectedOption={undefined}
                                placeholder={
                                    <span className="d-flex align-items-center">
                                        <i className="fal fa-plus fs-5 me-2" />
                                        Add trigger
                                    </span>
                                }
                                textAlign="left"
                                ariaLabel="trigger-type"
                            />
                        </div>
                    ) : (
                        <IconButton iconClasses="fal fa-plus" onClick={() => addTrigger({ value: "SCHEDULED" } as DropdownOption<"FROM_RULE" | "SCHEDULED">)} />
                    )}
                </div>
            </div>
            {triggerType !== undefined && ruleTrigger && triggerType === "SCHEDULED" ? (
                <ScheduledTriggerSetup ruleTrigger={ruleTrigger} onRuleTriggerChanged={onScheduledTriggerChanged} />
            ) : (
                triggerType === "FROM_RULE" && (
                    <TriggerFromRuleSetup
                        fileImportConfigs={fileImportConfigs}
                        scheduledRules={scheduledRules}
                        selectedRule={selectedRule}
                        workflowTriggerConfigs={workflowTriggerConfigs}
                        onWorkflowTriggerConfigsChanged={onWorkflowTriggerConfigsChanged}
                    />
                )
            )}
        </div>
    )
}

export default TriggerSetup
