import React, { Component } from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import ValidatingInput from '../../common/ValidatingInput';
import {
  executeAuthAsyncGet,
  executeAuthAsyncPost
} from '../../../utility/asyncSupport';
import { filter, findIndex, last, orderBy, some, sortBy } from 'lodash';
import {
  showToastSuccessMessage,
  showToastErrorMessage,
  showToastInfoMessage,
  showToastWarningMessage
} from '../../../api/toasterApi';
import { getLookup } from '../../../api/lookupApi';
import Select from 'react-select';

import { receiveLookup } from '../../../actions/lookupActions';

import AddUserFilter from './addUserFilter';
import EditUserFilter from './editUserFilter';
import CrmRoles from './CrmRoles'
import FeatureToggled from '../../../featureToggled/FeatureToggled';

import { hasPermission } from '../../../utility/authZ';
import { authZ_Permissions } from '../../../constants';

class AddUser extends Component {
  constructor(props, context) {
    super(props, context);

    this.onInputChanged = this.onInputChanged.bind(this);
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    this.showAddFilter = this.showAddFilter.bind(this);
    this.addFilterAdd = this.addFilterAdd.bind(this);
    this.addFilterCancel = this.addFilterCancel.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.updateFilterCancel = this.updateFilterCancel.bind(this);
    this.editFilterCallback = this.editFilterCallback.bind(this);
    this.setAvailableRoles = this.setAvailableRoles.bind(this);

    this.onChangeApplicationUser = this.onChangeApplicationUser.bind(this)

    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);

    this.renderLanguages = this.renderLanguages.bind(this);
    this.renderRoleFilters = this.renderRoleFilters.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
    this.renderFilterValues = this.renderFilterValues.bind(this);
    this.renderAddFilter = this.renderAddFilter.bind(this);
    this.filterCounter = 0;

    this.state = this.defaultFormState([]);

    this.enableSave = this.enableSave.bind(this);
    this.disableSave = this.disableSave.bind(this);

    this.createRequest = this.createRequest.bind(this);

    this.enableSaveAppUser = this.enableSaveAppUser.bind(this);
    this.disableSaveAppUser = this.disableSaveAppUser.bind(this);

    this.selectAutomateWorkflowAdminRoleHandler = this.selectAutomateWorkflowAdminRoleHandler.bind(this)
    this.selectConnectSmsSenderRoleHandler = this.selectConnectSmsSenderRoleHandler.bind(this)
    this.selectConnectSmsTemplateWriterRoleHandler = this.selectConnectSmsTemplateWriterRoleHandler.bind(this)
    this.selectConnectSmsTemplateReaderRoleHandler = this.selectConnectSmsTemplateReaderRoleHandler.bind(this)
    this.selectConnectConfigWriterRoleHandler = this.selectConnectConfigWriterRoleHandler.bind(this)
    this.selectConnectConfigReaderRoleHandler = this.selectConnectConfigReaderRoleHandler.bind(this)
    this.selectConnectSmsSenderIdWriterRoleHandler = this.selectConnectSmsSenderIdWriterRoleHandler.bind(this)
    this.selectConnectSmsSenderIdReaderRoleHandler = this.selectConnectSmsSenderIdReaderRoleHandler.bind(this)
    this.selectCustomerRelationshipGroupWriterRoleHandler = this.selectCustomerRelationshipGroupWriterRoleHandler.bind(this)
    this.selectCustomerRelationshipGroupReaderRoleHandler = this.selectCustomerRelationshipGroupReaderRoleHandler.bind(this)
    this.selectPayUserPaymentRoleHandler = this.selectPayUserPaymentRoleHandler.bind(this)
    this.selectOrderWriterRoleHandler = this.selectOrderWriterRoleHandler.bind(this)
    this.selectOrderReaderRoleHandler = this.selectOrderReaderRoleHandler.bind(this)
    this.selectDataImportConfigWriterRoleHandler = this.selectDataImportConfigWriterRoleHandler.bind(this)
    this.selectDataImportConfigReaderRoleHandler = this.selectDataImportConfigReaderRoleHandler.bind(this)
    this.selectIsActiveRoleHandler = this.selectIsActiveRoleHandler.bind(this)
    this.selectRoleReaderRoleHandler = this.selectRoleReaderRoleHandler.bind(this)
    this.selectRoleWriterRoleHandler = this.selectRoleWriterRoleHandler.bind(this)
    this.selectClientUserReaderRoleHandler = this.selectClientUserReaderRoleHandler.bind(this)
    this.selectClientUserWriterRoleHandler = this.selectClientUserWriterRoleHandler.bind(this)
  }

  componentDidMount() {
    this.props.getLookup(this.props.client, 'lookup/filterLookups', 'filterLookups');
    this.props.executeAuthAsyncGet(
      this.props.client,
      'Account/GetUserProfilePage',
      'USER_PROFILE',
      null
    );

    if (this.props.onAdd != null) {
      this.props.onAdd(this.initAddView.bind(this));
    }

    if (this.props.onEdit != null) {
      this.props.onEdit(this.initEditview.bind(this));
    }
  }

  defaultFormState(roles) {
    return {
      user: {
        userId: null,
        contactId: null,
        userName: '',
        contactName: '',
        email: null,
        phone: null,
        password: '',
        confirmPassword: '',
        languageId: null,
        languageDescription: null,
        languageCulture: null,
        unfilteredRoles: [
          //{ id: 1, name: 'Administrator'}
        ],
        filteredRoles: [
          //{ filterIndex: 0, filterType: 'Account', roleId: 26, roleDescription: 'Administrator', filterDefinitionId: 1, name: 'Branch Code', values: ['1001', '1002'] },
        ],
        defaultSecurityAnswer: '',
        canEdit: true,
        automateWorkflowAdminRoleSet: false,
        connectSmsSenderRoleSet: false,
        connectSmsTemplateWriterRoleSet: false,
        connectSmsTemplateReaderRoleSet: false,
        connectConfigWriterRoleSet: false,
        connectConfigReaderRoleSet: false,
        connectSmsSenderIdWriterRoleSet: false,
        connectSmsSenderIdReaderRoleSet: false,
        customerRelationshipGroupWriterRoleSet: false,
        customerRelationshipGroupReaderRoleSet: false,
        payUserPaymentRoleSet: false,
        orderReaderRoleSet: false,
        orderWriterRoleSet: false,
        dataImportConfigWriterRoleSet: false,
        dataImportConfigReaderRoleSet: false,
        isActiveRoleSet: false,
        roleReaderRoleSet: false,
        roleWriterRoleSet: false,
        clientUserReaderRoleSet: false,
        clientUserWriterRoleSet: false,
        applicationUserId: '',
        isApplicationUser: false
      },
      heading: 'Add User',
      rolesLookup: this.getRolesLookup(roles),
      availableRoles: this.getRolesLookup(roles),
      filterRoles: this.getRolesWithFilterDefinitions(roles),
      addingFilter: false,
      editingFilter: false,
      canSave: false,
      canSaveAppUser: false,
      isEditMode: false
    };
  }

  getRolesLookup(roles) {
    if (!roles) {
      return [];
    }

    return roles.map((t) => {
      return { id: t.Id, name: t.Name };
    });
  }

  getRolesWithFilterDefinitions(filterRoles) {
    if (!filterRoles) {
      return [];
    }
    return filter(filterRoles, function (r) {
      return (
        r.AccountFilterDefinitions.length > 0 ||
        r.VirtualAccountFilterDefinitions.length > 0 ||
        r.TransactionFilterDefinitions.length > 0
      );
    });
  }

  initAddView(roles) {
    this.setState(this.defaultFormState(roles));
  }

  initEditview(userId, roles) {
    const that = this;
    const filterRoles = that.getRolesWithFilterDefinitions(roles);

    this.props.executeAuthAsyncGet(
      this.props.client,
      'users',
      'GET_USER',
      { userId: userId },
      function (result) {
        const user = result;

        that.setState(
          {
            user: user,
            heading: 'Edit User',
            rolesLookup: that.getRolesLookup(roles),
            filterRoles: that.getRolesWithFilterDefinitions(filterRoles),
            addingFilter: false,
            editingFilter: false,
            canSave: true,
            canSaveAppId: true,
            isEditMode: true,
            password: '',
            confirmPassword: '',
            defaultSecurityAnswer: '',
            canEdit: true
          },
          that.setAvailableRoles
        );
      },
      function (/*error*/) {
        showToastErrorMessage('Error while loading user.');
      }
    );
  }

  setAvailableRoles() {
    const filteredRoleIds = this.state.user.filteredRoles.map((f) => {
      return f.roleId;
    });
    const availableRoles = filter(
      this.state.rolesLookup,
      (r) => filteredRoleIds.indexOf(r.id) === -1
    );
    this.setState({ availableRoles: availableRoles });
  }

  onInputChanged(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState((previousState) => ({
      ...previousState,
      user: { ...previousState.user, [name]: value }
    }));
  }

  onLanguageChanged(value) {
    const lang = this.props.languages.find((t) => t.Id === value);
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        languageId: lang.Id,
        languageDescription: lang.Description,
        languageCulture: lang.Culture
      }
    }));
  }

  onSelectedRolesChange(roles) {
    if (roles === null) roles = [];
    this.setState((previousState) => ({
      ...previousState,
      user: { ...previousState.user, unfilteredRoles: roles }
    }));
  }

  showAddFilter() {
    this.setState({ addingFilter: true });
  }

  onChangeApplicationUser(event) {
    const isApplicationUser = event.target.checked;
    this.setState((previousState) => ({
      ...previousState,
      user: { ...previousState.user, isApplicationUser: isApplicationUser }
    }));
  }

  addFilterAdd(newFilter) {
    const f = newFilter; //{filterIndex: 0, filterType: 'Account', filterDefinitionId: 1 roleId: '26', roleDescription: 'Administrator', name: 'Branch Code', values: ['1001', '1002']}

    //Check we don't already have the role added
    const userHasRole = some(this.state.user.unfilteredRoles, { id: f.roleId });
    if (userHasRole) {
      showToastWarningMessage(
        'Unable to add filter, user is already assigned to role.'
      );
      return;
    }

    f.filterIndex =
      this.state.user.filteredRoles.length === 0
        ? 0
        : last(sortBy(this.state.user.filteredRoles, 'filterIndex'))
          .filterIndex + 1;

    if (f.filterIndex > 0) {
      //Check we don't already have the filter added
      const userHasFilter = some(this.state.user.filteredRoles, function (a) {
        return (
          a.filterDefinitionId === f.filterDefinitionId &&
          a.filterType === f.filterType &&
          a.roleId === f.roleId
        );
      });
      if (userHasFilter) {
        showToastWarningMessage(
          'Unable to add filter, user is already assigned to ' +
          f.filterType +
          ' filter role for ' +
          f.name
        );
        return;
      }
    }

    this.setState(
      (previousState) => ({
        ...previousState,
        addingFilter: false,
        user: {
          ...previousState.user,
          filteredRoles: previousState.user.filteredRoles.concat(f)
        }
      }),
      this.setAvailableRoles
    );
  }

  addFilterCancel() {
    this.setState({ addingFilter: false });
  }

  updateFilter(filter) {
    const f = filter; //{filterIndex: 0, filterType: 'Account', filterDefinitionId: 1 roleId: '26', roleDescription: 'Administrator', name: 'Branch Code', values: ['1001', '1002']}

    const filteredRoles = this.state.user.filteredRoles.slice();
    const filterIndex = findIndex(filteredRoles, {
      filterIndex: f.filterIndex
    });
    filteredRoles[filterIndex] = filter;

    this.setState((previousState) => ({
      ...previousState,
      editingFilter: false,
      user: {
        ...previousState.user,
        filteredRoles: filteredRoles
      }
    }));
  }

  updateFilterCancel() {
    this.setState({ editingFilter: false });
  }

  onFilterDelete(filterIndex) {
    this.setState(
      (previousState) => ({
        ...previousState,
        editingFilter: false,
        user: {
          ...previousState.user,
          filteredRoles: filter(
            previousState.user.filteredRoles,
            (f) => f.filterIndex !== filterIndex
          )
        }
      }),
      this.setAvailableRoles
    );
  }

  editFilterCallback(editFilterFunction) {
    this.editFilter = editFilterFunction;
  }

  onFilterEdit(filter) {
    this.setState({ editingFilter: true });
    this.editFilter(filter);
  }

  enableSave() {
    this.setState({ canSave: true });
  }

  disableSave() {
    this.setState({ canSave: false });
  }

  enableSaveAppUser() {
    this.setState({ canSaveAppUser: true });
  }

  disableSaveAppUser() {
    this.setState({ canSaveAppUser: false });
  }

  saveEnabledAppUser() {
    return this.state.canSaveAppUser
  }

  saveEnabled() {
    return this.state.canSave && this.state.user.languageId != null;
  }

  createRequest(userDto) {
    return !userDto.isApplicationUser
      ? userDto
      : {
        ...userDto,
        contactName: '',
        email: "",
        phone: "",
        mobile: "",
        password: '',
        confirmPassword: '',
        languageId: 1,
        unfilteredRoles: [],
        filteredRoles: [],
        defaultSecurityAnswer: '',
        isUser: true,
        isActive: true,
      }
  }

  save() {
    this.setState({ canSave: false, canSaveAppUser: false });
    if (!this.state.user.isApplicationUser && this.props.disableUIPermissionsGeneration === true) {
      showToastInfoMessage(
        'User save submitted. Please note, changes to permissions will only take effect overnight.'
      );
    } else {
      showToastInfoMessage('User save submitted.');
    }

    if (this.state.isEditMode) {
      this.props.executeAuthAsyncPost(
        this.props.client,
        'users/update',
        'UPDATE_USER',
        this.createRequest(this.state.user),
        (response) => this.onSaveSuccess(response),
        () => {
          showToastErrorMessage('Error while updating User.');
          this.setState({ canSave: true });
        }
      );
    } else {
      this.props.executeAuthAsyncPost(
        this.props.client,
        'users/create',
        'CREATE_USER',
        this.createRequest(this.state.user),
        (response) => this.onSaveSuccess(response),
        () => {
          showToastErrorMessage('Error while creating User.');
          this.setState({ canSave: true });
        }
      );
    }
  }

  selectAutomateWorkflowAdminRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        automateWorkflowAdminRoleSet: !previousState.user.automateWorkflowAdminRoleSet
      }
    }));
  }
  selectConnectSmsSenderRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        connectSmsSenderRoleSet: !previousState.user.connectSmsSenderRoleSet
      }
    }));
  }
  selectConnectSmsTemplateWriterRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        connectSmsTemplateWriterRoleSet: !previousState.user.connectSmsTemplateWriterRoleSet
      }
    }));
  }
  selectConnectSmsTemplateReaderRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        connectSmsTemplateReaderRoleSet: !previousState.user.connectSmsTemplateReaderRoleSet
      }
    }));
  }
  selectConnectConfigWriterRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        connectConfigWriterRoleSet: !previousState.user.connectConfigWriterRoleSet
      }
    }));
  }
  selectConnectConfigReaderRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        connectConfigReaderRoleSet: !previousState.user.connectConfigReaderRoleSet
      }
    }));
  }
  selectConnectSmsSenderIdWriterRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        connectSmsSenderIdWriterRoleSet: !previousState.user.connectSmsSenderIdWriterRoleSet
      }
    }));
  }
  selectConnectSmsSenderIdReaderRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        connectSmsSenderIdReaderRoleSet: !previousState.user.connectSmsSenderIdReaderRoleSet
      }
    }));
  }
  selectCustomerRelationshipGroupWriterRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        customerRelationshipGroupWriterRoleSet: !previousState.user.customerRelationshipGroupWriterRoleSet
      }
    }));
  }
  selectCustomerRelationshipGroupReaderRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        customerRelationshipGroupReaderRoleSet: !previousState.user.customerRelationshipGroupReaderRoleSet
      }
    }));
  }
  selectPayUserPaymentRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        payUserPaymentRoleSet: !previousState.user.payUserPaymentRoleSet
      }
    }));
  }

  selectOrderWriterRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        orderWriterRoleSet: !previousState.user.orderWriterRoleSet
      }
    }));
  }

  selectOrderReaderRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        orderReaderRoleSet: !previousState.user.orderReaderRoleSet
      }
    }));
  }

  selectDataImportConfigWriterRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        dataImportConfigWriterRoleSet: !previousState.user.dataImportConfigWriterRoleSet
      }
    }));
  }

  selectDataImportConfigReaderRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        dataImportConfigReaderRoleSet: !previousState.user.dataImportConfigReaderRoleSet
      }
    }));
  }


  selectIsActiveRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        isActiveRoleSet: !previousState.user.isActiveRoleSet
      }
    }));
  }

  selectRoleReaderRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        roleReaderRoleSet: !previousState.user.roleReaderRoleSet
      }
    }));
  }

  selectRoleWriterRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        roleWriterRoleSet: !previousState.user.roleWriterRoleSet
      }
    }));
  }

  selectClientUserReaderRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        clientUserReaderRoleSet: !previousState.user.clientUserReaderRoleSet
      }
    }));
  }

  selectClientUserWriterRoleHandler() {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        clientUserWriterRoleSet: !previousState.user.clientUserWriterRoleSet
      }
    }));
  }

  onSaveSuccess = ({ CurrentUser, Error, HasError, ErrorCode }) => {
    const dormantDuplicateErrorCode = 2601;
    if (Error !== '' && CurrentUser === null) {
      if (ErrorCode === dormantDuplicateErrorCode && HasError === true) {
        this.props.executeAuthAsyncPost(
          this.props.client,
          'users/enable',
          'ENABLE_USER',
          this.state.user,
          (response) => this.onSaveSuccess(response),
          () => {
            showToastErrorMessage('Error while re-enabling User.');
            this.setState({ canSave: true });
          }
        );
      } else {
        showToastErrorMessage(Error);
        return;
      }
    } else {
      showToastSuccessMessage('User saved successfully.');

      if (!this.state.user.isApplicationUser &&
        this.state.user.userId === this.props.userProfile.id &&
        this.state.user.languageId !== this.props.userProfile.languageId
      ) {
        const languageCulture = this.state.user.languageCulture;
        this.props.updateLanguageCulture(languageCulture);
      }

      if (this.props.onSaved) {
        this.props.onSaved();
      }
    }
  };

  cancel() {
    if (this.props.onCancelled) {
      this.props.onCancelled();
    }
  }

  renderLanguageLabel(user) {
    let label = 'Select Language/Region ';
    if (user.languageDescription) {
      label = 'Language/Region: ' + user.languageDescription;
    }
    return label;
  }

  renderLanguages() {
    const languages = this.props.languages.map((t) => {
      const key = 'language' + t.Id;
      return (
        <li key={key} className="dropdown-item">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={this.onLanguageChanged.bind(this, t.Id)}>
            {t.Description}
          </a>
        </li>
      );
    });
    return languages;
  }

  renderFilterValues(filter) {
    const filtersCount = filter.values.length;
    if (filtersCount === 0) {
      return '';
    }

    if (filtersCount === 1) {
      return filter.values[0];
    }

    if (filtersCount === 2) {
      return filter.values[0] + ' and ' + filter.values[1];
    }

    let markup = '';
    for (let i = 0; i < Math.min(filtersCount, 12) - 2; i++) {
      markup = markup + filter.values[i] + ', ';
    }

    return filtersCount > 10
      ? markup + '...'
      : markup +
      filter.values[filtersCount - 2] +
      ' and ' +
      filter.values[filtersCount - 1];
  }

  renderFilter(filter) {
    if (!filter) {
      return 'None';
    }

    return (
      <div>
        <span className="filter-name">{filter.name}: </span>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <button
          className="btn btn-link"
          title="Edit filter"
          onClick={this.onFilterEdit.bind(this, filter)}
        >
          {this.renderFilterValues(filter)}
        </button>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <button
          className="btn btn-link filter-remove"
          title="Remove filter"
          onClick={this.onFilterDelete.bind(this, filter.filterIndex)}
        >
          {/* Check icon renders */}
          <i className="fa fa-times-circle"></i>
          {/* <FontAwesomeIcon icon="times-circle" /> */}
        </button>
      </div>
    );
  }

  renderRoleFilters(filters) {
    const filtersOrdered = orderBy(filters, ['roleDescription', 'filterType']);

    const filtersMarkup = filtersOrdered.map((f) => {
      return (
        <div key={f.filterIndex} className="row">
          <div className="col-md-2">
            <p>{f.roleDescription}</p>
          </div>
          <div className="col-md-2">
            <p>{f.filterType}</p>
          </div>
          <div className="col-md-8">{this.renderFilter(f)}</div>
        </div>
      );
    });

    const markup = (
      <div>
        <div className="row">
          <div className="col-md-2">
            <label>Role</label>
          </div>
          <div className="col-md-2">
            <label>Filter Type</label>
          </div>
          <div className="col-md-8">
            <label>Filter</label>
          </div>
        </div>
        {filtersMarkup}
      </div>
    );

    return markup;
  }

  renderAddFilter(state) {
    const button = (
      <button
        id="show-add-account-filter-button"
        className="btn btn-default"
        type="button"
        onClick={this.showAddFilter}
      >
        Add Filtered Role
      </button>
    );

    const addFilterForm = (
      <AddUserFilter
        onAdd={this.addFilterAdd}
        onCancel={this.addFilterCancel}
        roles={this.state.filterRoles}
        userName={this.state.user.userName}
        contactName={this.state.user.contactName}
        filterLookups={this.props.filterLookups}
        client={this.props.client}
      />
    );
    return state.addingFilter ? addFilterForm : button;
  }

  render() {
    const user = this.state.user;

    const passwordInput = this.state.isEditMode ? (
      <ValidatingInput
        id="input-password"
        type={'password'}
        name="password"
        placeholder="Password"
        validations="minLength:1,maxLength:25"
        hideLabel={true}
        validationError={'Password should be between 1 and 25 characters'}
        value={user.password}
        onChange={this.onInputChanged}
      />
    ) : (
      <ValidatingInput
        id="input-password"
        type={'password'}
        name="password"
        placeholder="Password"
        required
        validations="minLength:1,maxLength:25"
        hideLabel={true}
        validationError={'Password should be between 1 and 25 characters'}
        value={user.password}
        onChange={this.onInputChanged}
      />
    );

    const confirmPasswordInput = this.state.isEditMode ? (
      <ValidatingInput
        id="input-confirmPassword"
        type={'password'}
        name="confirmPassword"
        placeholder="Confirm Password"
        hideLabel={true}
        validations={{
          passwordIsConfirmed: function (values, value) {
            return (
              (!value && !values['password']) || value === values['password']
            );
          }
        }}
        validationError={'Confirmation must match password'}
        value={user.confirmPassword}
        onChange={this.onInputChanged}
      />
    ) : (
      <ValidatingInput
        id="input-confirmPassword"
        type={'password'}
        name="confirmPassword"
        placeholder="Confirm Password"
        hideLabel={true}
        required
        validations={{
          passwordIsConfirmed: function (values, value) {
            return value === values['password'];
          }
        }}
        validationError={'Confirmation must match password'}
        value={user.confirmPassword}
        onChange={this.onInputChanged}
      />
    );

    const defaultSecurityAnswer = this.state.isEditMode ? (
      <ValidatingInput
        id="input-defaultSecurityAnswer"
        type={'password'}
        name="defaultSecurityAnswer"
        placeholder="Default Security Question Answer"
        validations="minLength:1,maxLength:25"
        hideLabel={true}
        validationError={'Answer should be between 1 and 25 characters'}
        value={user.defaultSecurityAnswer}
        onChange={this.onInputChanged}
      />
    ) : (
      <ValidatingInput
        id="input-defaultSecurityAnswer"
        type={'password'}
        name="defaultSecurityAnswer"
        placeholder="Default Security Question Answer"
        required
        validations="minLength:1,maxLength:25"
        hideLabel={true}
        validationError={'Answer should be between 1 and 25 characters'}
        value={user.defaultSecurityAnswer}
        onChange={this.onInputChanged}
      />
    );

    const saveButton = this.state.user.canEdit ? (
      <button
        type="button"
        onClick={this.save}
        className="btn btn-default"
        disabled={!this.saveEnabled()}
      >
        Save User
      </button>
    ) : (
      <button type="button" className="btn btn-default" disabled>
        Save User
      </button>
    );

    const saveButtonAppUser = this.state.user.canEdit ? (
      <button
        type="button"
        onClick={this.save}
        className="btn btn-default"
        disabled={!this.saveEnabledAppUser()}
      >
        Save User
      </button>
    ) : (
      <button type="button" className="btn btn-default" disabled>
        Save User
      </button>
    );
    return (
      <>
        <Formsy
          key="add-user-form"
          onValid={!this.state.user.isApplicationUser ? this.enableSave : this.enableSaveAppUser}
          onInvalid={!this.state.user.isApplicationUser ? this.disableSave : this.disableSaveAppUser}
          className="below-grid-form"
          id="adduserform"
        >
          <h3>{this.state.heading}</h3>
          <br />
          {hasPermission(this.props.permissions, [authZ_Permissions.SystemConfiguration]) &&
            <FeatureToggled flagKey="showApplicationUserSettings">
              <div className="my-2">
                <input
                  type="checkbox"
                  checked={this.state.user.isApplicationUser}
                  onChange={this.onChangeApplicationUser}
                />
                <span> Is Application User?</span>
              </div>
            </FeatureToggled>
          }
          <ValidatingInput
            id="input-userName"
            name="userName"
            type="text"
            placeholder="User Name"
            hideLabel={true}
            required
            value={user.userName}
            onChange={this.onInputChanged}
          />
          {this.state.user.isApplicationUser &&
            <ValidatingInput
              id="input-appId"
              name="applicationUserId"
              type="text"
              placeholder="Application ID"
              hideLabel={true}
              required
              value={user.applicationUserId}
              onChange={this.onInputChanged}
            />

          }
          {!this.state.user.isApplicationUser &&
            <>
              <ValidatingInput
                id="input-contactName"
                name="contactName"
                type="text"
                placeholder="Contact Name"
                hideLabel={true}
                required
                value={user.contactName}
                onChange={this.onInputChanged}
              />

              <div className="form-group">
                <input
                  id="input-phone"
                  name="phone"
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  title="Phone Number"
                  value={user.phone || ''}
                  onChange={this.onInputChanged}
                />
              </div>
              <div className="form-group">
                <input
                  id="input-mobile"
                  name="mobile"
                  type="text"
                  className="form-control"
                  placeholder="Mobile Phone Number"
                  title="Mobile Phone Number"
                  value={user.mobile || ''}
                  onChange={this.onInputChanged}
                />
              </div>

              <ValidatingInput
                id="input-email"
                name="email"
                value={user.email}
                placeholder="Email"
                validations="isEmail"
                hideLabel={true}
                validationError={'Email address is not well formed.'}
                onChange={this.onInputChanged}
              />

              {passwordInput}

              {confirmPasswordInput}

              {defaultSecurityAnswer}

              <div className="form-group">
                <ul className="nav" id="languagelist">
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      id="dropdownMenuButton"
                      className="nav-link dropdown-toggle boldTitle noPaddingLeft"
                      data-bs-toggle="dropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      {this.renderLanguageLabel(user)}
                      <b className="caret"></b>
                    </a>
                    <ul aria-labelledby="dropdownMenuButton" className="dropdown-menu ddscroll">
                      {this.renderLanguages()}
                    </ul>
                  </li>
                </ul>
              </div>

              <h4>Unfiltered Roles</h4>

              <div className="form-group" style={{ width: '480px' }}>
                <Select
                  name="user-role-select"
                  isMulti={true}
                  value={this.state.user.unfilteredRoles}
                  options={this.state.availableRoles}
                  onChange={this.onSelectedRolesChange.bind(this)}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  placeholder="Unfiltered Roles"
                />
              </div>

              <h4>Filtered Roles</h4>

              <p>
                The user will be assigned permissions for roles matching the filters
                below:
              </p>
              {user.filteredRoles.length > 0 ? (
                this.renderRoleFilters(user.filteredRoles)
              ) : (
                <p>
                  <i>Currently there are no filters.</i>
                </p>
              )}

              <div styles={{ maxWidth: '150px' }}>
                {this.renderAddFilter(this.state)}
              </div>

              <EditUserFilter
                editFilter={this.editFilterCallback}
                onUpdate={this.updateFilter}
                onCancel={this.updateFilterCancel}
                filterLookups={this.props.filterLookups}
                client={this.props.client}
              />
            </>
          }
          <CrmRoles
            automateWorkflowAdminRoleSet={user.automateWorkflowAdminRoleSet}
            connectSmsSenderRoleSet={user.connectSmsSenderRoleSet}
            connectSmsTemplateWriterRoleSet={user.connectSmsTemplateWriterRoleSet}
            connectSmsTemplateReaderRoleSet={user.connectSmsTemplateReaderRoleSet}
            connectConfigWriterRoleSet={user.connectConfigWriterRoleSet}
            connectConfigReaderRoleSet={user.connectConfigReaderRoleSet}
            connectSmsSenderIdWriterRoleSet={user.connectSmsSenderIdWriterRoleSet}
            connectSmsSenderIdReaderRoleSet={user.connectSmsSenderIdReaderRoleSet}
            customerRelationshipGroupWriterRoleSet={user.customerRelationshipGroupWriterRoleSet}
            customerRelationshipGroupReaderRoleSet={user.customerRelationshipGroupReaderRoleSet}
            payUserPaymentRoleSet={user.payUserPaymentRoleSet}
            orderWriterRoleSet={user.orderWriterRoleSet}
            orderReaderRoleSet={user.orderReaderRoleSet}
            dataImportConfigWriterRoleSet={user.dataImportConfigWriterRoleSet}
            dataImportConfigReaderRoleSet={user.dataImportConfigReaderRoleSet}
            isActiveRoleSet={user.isActiveRoleSet}
            roleReaderRoleSet={user.roleReaderRoleSet}
            roleWriterRoleSet={user.roleWriterRoleSet}
            clientUserReaderRoleSet={user.clientUserReaderRoleSet}
            clientUserWriterRoleSet={user.clientUserWriterRoleSet}

            selectAutomateWorkflowAdminRoleHandler={this.selectAutomateWorkflowAdminRoleHandler}
            selectConnectSmsSenderRoleHandler={this.selectConnectSmsSenderRoleHandler}
            selectConnectSmsTemplateWriterRoleHandler={this.selectConnectSmsTemplateWriterRoleHandler}
            selectConnectSmsTemplateReaderRoleHandler={this.selectConnectSmsTemplateReaderRoleHandler}
            selectConnectConfigWriterRoleHandler={this.selectConnectConfigWriterRoleHandler}
            selectConnectConfigReaderRoleHandler={this.selectConnectConfigReaderRoleHandler}
            selectConnectSmsSenderIdWriterRoleHandler={this.selectConnectSmsSenderIdWriterRoleHandler}
            selectConnectSmsSenderIdReaderRoleHandler={this.selectConnectSmsSenderIdReaderRoleHandler}
            selectCustomerRelationshipGroupWriterRoleHandler={this.selectCustomerRelationshipGroupWriterRoleHandler}
            selectCustomerRelationshipGroupReaderRoleHandler={this.selectCustomerRelationshipGroupReaderRoleHandler}
            selectPayUserPaymentRoleHandler={this.selectPayUserPaymentRoleHandler}
            selectOrderWriterRoleHandler={this.selectOrderWriterRoleHandler}
            selectOrderReaderRoleHandler={this.selectOrderReaderRoleHandler}
            selectDataImportConfigWriterRoleHandler={this.selectDataImportConfigWriterRoleHandler}
            selectDataImportConfigReaderRoleHandler={this.selectDataImportConfigReaderRoleHandler}
            selectIsActiveRoleHandler={this.selectIsActiveRoleHandler}
            selectRoleReaderRoleHandler={this.selectRoleReaderRoleHandler}
            selectRoleWriterRoleHandler={this.selectRoleWriterRoleHandler}
            selectClientUserReaderRoleHandler={this.selectClientUserReaderRoleHandler}
            selectClientUserWriterRoleHandler={this.selectClientUserWriterRoleHandler}
          />

          <div className="mt-3" styles={{ maxWidth: '200px' }}>
            {!this.state.user.isApplicationUser ? saveButton : saveButtonAppUser}

            <button
              type="button"
              onClick={this.cancel}
              className="btn btn-default"
            >
              Cancel
            </button>
          </div>
          <br />
          <br />
        </Formsy>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  languages: state.lookupReducer.availableLanguages.results,
  filterLookups: state.lookupReducer.filterLookups.results,
  disableUIPermissionsGeneration:
    state.lookupReducer.disableUIPermissionsGeneration.results,
  userProfile: state.asyncReducer.USER_PROFILE.result,
  permissions: state.authReducer.permissions
});

const mapDispatchToEvents = (dispatch) => {
  return {
    getLookup: (client, serviceCall, storePropertyName) => {
      dispatch(getLookup(client, serviceCall, storePropertyName));
    },
    executeAuthAsyncGet: (serviceName, name, data, onSuccess, onError) => {
      dispatch(
        executeAuthAsyncGet(serviceName, name, data, onSuccess, onError)
      );
    },
    executeAuthAsyncPost: (serviceName, name, data, onSuccess, onError) => {
      dispatch(
        executeAuthAsyncPost(serviceName, name, data, onSuccess, onError)
      );
    },
    updateLanguageCulture: (languageCulture) => {
      dispatch(receiveLookup(languageCulture, 'userLanguageCulture'));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToEvents)(AddUser);
