export const removeNumberFormatting = (value: string) => {
    // Check for a leading negative sign.
    const isNegative = value && value[0] === "-"
    // Remove all non-digit characters, except decimal point.
    value = value.replace(/[^\d.]/g, "")
    // If a negative sign was present, add it back.
    if (isNegative) {
        value = "-" + value
    }
    // Return the cleaned string as it is.
    return value
}
