import { gridKeys, stateTaskType } from '../constants';
import { isEmpty, orderBy } from 'lodash';
import { process } from '@progress/kendo-data-query';

export const tasksAsDropdowns = (tasks) => {
  if (!(tasks && tasks.length > 0)) {
    return [];
  }
  const result = [{ id: 0, text: 'Select a task type' }];
  return result.concat(tasks.map((t) => ({ id: t.id, text: t.text })));
};

export const hasSelectedTask = (task) => {
  return task !== null;
};

export const isNewTask = (task) => {
  return task && task.id === null && task.isNew;
};

export const isExistingTask = (task) => {
  return task && task.id !== null;
};

export const taskIdInEdit = (task) => {
  return task && task.id > 0 && !task.isCompleted ? task.id : 0;
};

export const taskId = (task) => {
  return task && task.id > 0 ? task.id : 0;
};

export const hasTaskInEdit = (task) => {
  return task && task.id > 0 && !task.isCompleted;
};

export const usersSelectList = (users) => {
  return users.map((u) => ({
    value: u.Id,
    label: u.UserName,
    isDefault: u.IsDefault
  }));
};

export const rolesSelectList = (roles) => {
  return roles.map((r) => ({
    value: r.Id,
    label: r.Name
  }));
};

export const taskTypesSelectList = (taskTypes) => {
  return taskTypes.map((t) => ({
    value: t.id,
    label: t.text,
    isInUse: t.isInUse
  }));
};

export const taskDescriptionsSelectList = (taskDescriptions) => {
  return taskDescriptions.map((t) => ({
    value: t.Id,
    label: t.Description,
    isInUse: t.IsInUse
  }));
};

export const hasTaskTypes = (taskTypes) => {
  return taskTypes && taskTypes.length > 0;
};

export const taskCompletionSelectList = (
  taskCompletions,
  filterOnIsInUse = true
) => {
  return taskCompletions
    .filter(filterOnIsInUse ? (f) => f.IsInUse : () => true)
    .map((c) => ({
      value: c.Id,
      label: c.Title
    }));
};

export const isActiveTaskGrid = (grids) => {
  const userTaskGrid = grids[gridKeys.USER_TASK_GRID_KEY];
  if (
    userTaskGrid &&
    userTaskGrid.lastResponse &&
    userTaskGrid.lastResponse.data &&
    userTaskGrid.lastResponse.data.length > 0
  ) {
    return true;
  }
  return false;
};

export const getUserTaskGridWithDefaults = (grids) => {
  return {
    lastResponse: { data: [], total: 0 },
    gridState: { skip: 0, take: 100 },
    ...getUserTaskGrid(grids)
  };
};

export const getUserTaskGrid = (grids) => {
  return grids[gridKeys.USER_TASK_GRID_KEY] || {};
};

export const getCustomerTaskGrid = (grids) => {
  return grids[gridKeys.CUSTOMER_TASK_GRID_KEY] || {};
};

export const stateTaskTypeSelectId = (
  stateTaskTypeId = null,
  taskTypeId = null
) => {
  if (stateTaskTypeId === null && taskTypeId === null) return null;

  if (stateTaskType[stateTaskTypeId] === undefined) return null;

  if (taskTypeId === null) return `${stateTaskTypeId}`;

  return `${stateTaskTypeId}-${taskTypeId}`;
};

export const stateTaskTypeSelectList = (taskTypes) => {
  const unreadEmail = {
    value: stateTaskTypeSelectId(stateTaskType.UNREAD_EMAIL),
    label: 'Unread Email',
    stateTaskTypeId: stateTaskType.UNREAD_EMAIL
  };

  const awaitCreditLimit = {
    value: stateTaskTypeSelectId(stateTaskType.AWAIT_CREDIT_LIMIT),
    label: 'Awaiting Credit Limit Decisions',
    stateTaskTypeId: stateTaskType.AWAIT_CREDIT_LIMIT
  };

  const newCreditLimit = {
    value: stateTaskTypeSelectId(stateTaskType.NEW_CREDIT_LIMIT),
    label: 'New Credit Limit Decisions',
    stateTaskTypeId: stateTaskType.NEW_CREDIT_LIMIT
  };

  return orderBy(
    [
      unreadEmail,
      awaitCreditLimit,
      newCreditLimit,
      ...taskTypes.map((t) => ({
        value: stateTaskTypeSelectId(stateTaskType.TASK, t.id),
        label: t.text,
        stateTaskTypeId: stateTaskType.TASK,
        taskTypeId: t.id
      }))
    ],
    ['label', 'asc']
  );
};

export const stateTaskTypeSelectListWithCount = (taskTypes, grids) => {
  const selectList = stateTaskTypeSelectList(taskTypes);
  const taskListGridState = getUserTaskGridWithDefaults(grids);

  const { lastResponse } = taskListGridState;
  const data = lastResponse ? lastResponse.data : [];

  const aggragation = process(
    data.map((d) => ({
      compId:
        d.StatefulTaskType === 0
          ? `${d.StatefulTaskType}-${d.TaskTypeId}`
          : `${d.StatefulTaskType}`
    })),
    {
      skip: 0,
      group: [
        {
          field: 'compId'
        }
      ]
    }
  );

  const dictionary = aggragation.data.reduce(
    (a, x) => ({ ...a, [x.value]: x.items.length }),
    {}
  );

  return selectList.map((s) => {
    const count = !isEmpty(dictionary) ? dictionary[s.value] || 0 : null;
    const label = count === null ? s.label : `${s.label} (${count})`;
    return {
      ...s,
      count,
      label
    };
  });
};
