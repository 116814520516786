import { MouseEvent, useEffect, useRef, useState } from "react"
import classes from "./EntityTrendingChart.module.scss"
import Chart from "chart.js/auto"
import { TrendingChartType } from "../../microfrontends/dashboard/types/DashboardConfigState"
import { Dataset } from "./EntityTrendingChart"

type TrendingWidgetData = {
    title: string
    xLabels: string[]
    data: Dataset[]
}

type EntityTrendingSubChartProps = {
    className?: string
    chartType: TrendingChartType
    colour?: "white" | "blue"
    state: TrendingWidgetData
    onClick?: (value: string) => void
}

const EntityTrendingSubChart = ({ className = "", chartType, colour = "white", state, onClick = () => {} }: EntityTrendingSubChartProps) => {
    const { title, data, xLabels } = state

    const canvasRef = useRef<HTMLCanvasElement>(null)
    const realChartType = getChartType(chartType)
    const [chart, setChart] = useState<Chart<typeof realChartType, number[], string> | undefined>(undefined)

    const onCanvasClick = (event: MouseEvent<HTMLCanvasElement>) => {
        const element = chart?.getElementsAtEventForMode(event.nativeEvent, "nearest", { intersect: true }, false)
        if (!element || !chart?.data.labels) return
        if (!element[0]) return

        onClick(chart.data.labels[element[0].index] ?? "")
    }

    useEffect(() => {
        if (!canvasRef.current) return

        const chartColor = colour === "blue" ? "white" : "#0096d9"
        const gridColor = colour === "blue" ? "rgba(255,255,255, 0.1)" : "#0096d9"
        Chart.defaults.color = chartColor

        const chart = new Chart(canvasRef.current, {
            type: realChartType,
            data: {
                labels: xLabels.map(label => label.toUpperCase()),
                datasets: data
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: false,
                        text: ""
                    },
                    legend: {
                        display: false
                    }
                },
                scales: {
                    x: {
                        border: {
                            display: true
                        },
                        grid: {
                            display: true,
                            drawOnChartArea: true,
                            drawTicks: true,
                            color: gridColor
                        }
                    },
                    y: {
                        border: {
                            display: true
                        },
                        grid: {
                            color: gridColor
                        }
                    }
                },
                locale: "en-GB"
            }
        })
        setChart(chart)
        return () => chart?.destroy()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <div className={`${className} d-flex flex-column w-100 p-3 rounded ${classes[`widget-${colour}`]}`}>
            <div className="d-flex justify-content-between">
                <div className={`fs-5 text-uppercase d-block mb-2 ${colour === "blue" ? "text-white" : "text-grey"}`}>{title}</div>
            </div>
            <div className="position-relative d-flex flex-grow-1">
                <canvas ref={canvasRef} onClick={onCanvasClick} />
            </div>
        </div>
    )
}

export default EntityTrendingSubChart

const getChartType = (chartType: TrendingChartType): "line" | "bar" => {
    switch (chartType) {
        case "LINE":
            return "line"
        case "BAR":
            return "bar"
    }
}
