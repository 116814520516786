import { IconButton, Loading } from "invevo-react-components"
import { useCustomer } from "../../../../../contexts/CustomerContext"
import { transactionAggregationTypes } from "../../../enums/transactionAggregationTypes"
import { convertToCurrency } from "../../../../../library/helpers/numberUtils"
import classes from "./AddTransactionFilters.module.scss"
import TransactionReferenceAutoComplete from "./TransactionReferenceAutoComplete"

const AddTransactionFilters = ({ onClose, state, getCurrencyCode, getDisplayNameForLookup }) => {
    const { aggregations, isFetchingGrid, customerRefs } = state
    const [customer, setCustomer] = useCustomer()

    const getCurrentAggregations = (aggregation, label) => {
        if (
            aggregation === transactionAggregationTypes.AGED_DEBT ||
            aggregation === transactionAggregationTypes.FALLING_DUE ||
            aggregation === transactionAggregationTypes.TRANSACTION_STATUS ||
            aggregation === transactionAggregationTypes.TRANSACTION_TYPE ||
            label === "open"
        ) {
            return customer.transactionFilters.filter(f => f.type !== transactionAggregationTypes.RECENT_PAYMENTS && f.label !== "closed")
        }

        if (aggregation === transactionAggregationTypes.RECENT_PAYMENTS || label === "closed") {
            return customer.transactionFilters.filter(
                f =>
                    f.label !== "open" &&
                    f.type !== transactionAggregationTypes.AGED_DEBT &&
                    f.type !== transactionAggregationTypes.FALLING_DUE &&
                    f.type !== transactionAggregationTypes.TRANSACTION_STATUS &&
                    f.type !== transactionAggregationTypes.TRANSACTION_TYPE
            )
        }

        return customer.transactionFilters
    }

    const onAggregationOptionClick = (aggregation, option) => {
        if (isFetchingGrid) return

        if (customer.transactionFilters.some(sa => sa.type === aggregation && sa.label === option.label)) {
            const toRemove = customer.transactionFilters.filter(a => a.type === aggregation && a.label === option.label)
            setCustomer({ transactionFilters: customer.transactionFilters.filter(a => !toRemove.includes(a)) })
            return
        }
        setCustomer({
            transactionFilters: [
                ...getCurrentAggregations(aggregation, option.label),
                {
                    type: aggregation,
                    label: option.label,
                    amountByCurrency: option.totalByCurrency.map(a => {
                        return { ...a, currency: getCurrencyCode(a.currency) }
                    })
                }
            ]
        })
    }

    const isOptionSelected = (aggregation, option) => customer.transactionFilters.some(sa => aggregation === sa.type && option.label === sa.label)

    const onTransactionReferenceSelected = refs => {
        const refsToSearch = refs.filter(
            r =>
                !customer.transactionFilters
                    .filter(t => t.type === "TRANSACTION_REFERENCE")
                    .map(t => t.label)
                    .includes(r)
        )

        setCustomer({
            transactionFilters: [
                ...customer.transactionFilters.filter(t => t.type === "TRANSACTION_REFERENCE"),
                ...refsToSearch.map(r => {
                    return { type: "TRANSACTION_REFERENCE", label: r }
                })
            ]
        })
    }

    const getLookupName = name =>
        name === transactionAggregationTypes.TRANSACTION_STATUS
            ? "transaction status"
            : name === transactionAggregationTypes.TRANSACTION_TYPE
            ? "transaction type"
            : name

    const aggregationDiv = (aggregationType, agg, isLookup = false) => (
        <div
            role="button"
            aria-label={`${agg.label}-aggregation`}
            key={agg.label}
            className="d-flex align-items-center pt-2 no-select"
            onClick={() => onAggregationOptionClick(aggregationType, agg)}
        >
            <div className={`${isOptionSelected(aggregationType, agg) ? classes.selectedOption : classes.option} pointer d-flex px-2 py-1`}>
                <span className="m-auto no-select">{isLookup ? getDisplayNameForLookup(getLookupName(aggregationType), agg.label) : agg.label}</span>
            </div>
            <span className="ms-2">{agg.totalByCurrency.map(a => `${convertToCurrency(a.value.toFixed(2), a.currency)} `)}</span>
        </div>
    )

    return (
        <div className={`bg-grey h-100 ${classes.slide} d-flex flex-column p-3`}>
            <div className="d-flex align-items-center">
                <span className="fs-2">Transaction filters</span>
                <IconButton className="ms-auto" iconClasses="fal fa-close" onClick={onClose} />
            </div>
            <Loading isLoading={isFetchingGrid} colour="blue">
                <div className="d-flex flex-column overflow-auto">
                    <div className="d-flex flex-column mt-3">
                        <span className="fs-5 no-select">Filter by reference</span>
                        <TransactionReferenceAutoComplete customerRefs={customerRefs} onChange={onTransactionReferenceSelected} />
                    </div>
                    <div className="d-flex flex-column mt-3">
                        <span className="fs-5 no-select">Open status</span>
                        {aggregations.openStatusAggregation.map(agg => aggregationDiv("OPEN_STATUS", { ...agg, label: agg.label }))}

                        <span className="fs-5 no-select">Aged debt</span>
                        {aggregations.agedDebtAggregation.map(agg => aggregationDiv(transactionAggregationTypes.AGED_DEBT, agg))}

                        <span className="fs-5 no-select">Falling due</span>
                        {aggregations.fallingDueAggregation.map(agg => aggregationDiv(transactionAggregationTypes.FALLING_DUE, agg))}

                        <span className="fs-5 no-select">Recent payments</span>
                        {aggregations.recentPaymentAggregation.map(agg => aggregationDiv(transactionAggregationTypes.RECENT_PAYMENTS, agg))}

                        <span className="fs-5 no-select">Statuses</span>
                        {aggregations.statusAggregation.map(agg => aggregationDiv(transactionAggregationTypes.TRANSACTION_STATUS, agg, true))}

                        <span className="fs-5 no-select">Types</span>
                        {aggregations.typeAggregation.map(agg => aggregationDiv(transactionAggregationTypes.TRANSACTION_TYPE, agg, true))}
                    </div>
                </div>
            </Loading>
        </div>
    )
}

export default AddTransactionFilters
