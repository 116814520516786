import { useEnvConfig } from "../../../../contexts/EnvironmentConfigContext"
import useApiQuery from "../../../../hooks/useApiQuery"
import useClient from "../../../../hooks/useClient"
import { useDownload } from "../../../../hooks/useDownload"
import { useToaster } from "../../../../hooks/useToaster"
import IconButton from "../../../../library/buttons/IconButton/IconButton"
import RowData from "../../../../library/grid/types/RowData"

type Props = {
    row: RowData
    setSelectedFileImport: (row: RowData) => void
}

const FileImportRowActions = ({ row, setSelectedFileImport }: Props) => {
    const config = useEnvConfig()
    const client = useClient()
    const download = useDownload()
    const toaster = useToaster()

    const postImportFileDownloadRequest = useApiQuery<string>({
        url: `${config.INTEGRATION_API_URL}/api/${client}/import-file`,
        method: "POST",
        isExecutedAutomatically: false
    })

    const onFileDownloadClicked = () => {
        postImportFileDownloadRequest
            .execute(undefined, { filePath: getFullFilePath(row) })
            .then(response => download.fromUrl(response.data, row.fields.textFields.fileName ?? "Unknown file"))
            .catch(() => toaster.error("Failed to download file"))
    }

    const onFileViewClicked = () => setSelectedFileImport(row)

    return (
        <div className="d-flex p-1 gap-2">
            <IconButton
                theme="white-flat"
                size="small"
                onClick={onFileDownloadClicked}
                ariaLabel={`download-file-${row.reference}`}
                icon="text-blue fa fa-file-arrow-down"
                isLoading={postImportFileDownloadRequest.isFetching}
            />
            <IconButton theme="white-flat" size="small" onClick={onFileViewClicked} ariaLabel={`select-file-${row.reference}`} icon="text-blue fa fa-eye" />
        </div>
    )
}

export default FileImportRowActions

const getFullFilePath = (row: RowData) => {
    const fileName = row.fields.textFields.fileName
    const ref = row.reference
    if (!fileName) return ref

    const fileNameParts = fileName.split(".")
    const fileNamePrefix = fileNameParts[0]
    const fileNameExtenstion = fileNameParts[1]
    if (fileNamePrefix === undefined || fileNameExtenstion === undefined || fileNameParts.length !== 2) return ref

    const lengthOfPrefix = fileNamePrefix.length
    const refSuffixAndExtension = ref.substring(lengthOfPrefix)
    return fileNamePrefix + refSuffixAndExtension
}
