import { actionTypes } from "./actionTypes"

export default function userGroupReducer(state, action) {
    switch (action.type) {
        case actionTypes.ROLES_RETRIEVED:
            return { ...state, roles: action.roles }

        case actionTypes.ROLE_ADDED:
            return { ...state, roles: [...state.roles, action.role] }

        case actionTypes.ROLE_TOGGLED:
            const updatedRoles = state.roles.map(r => {
                if (r.id === action.roleId) {
                    return { ...r, isEnabled: !r.isEnabled }
                }
                return r
            })
            return { ...state, roles: updatedRoles }

        case actionTypes.ROLE_ASSIGNED_USERS_UPDATED:
            return {
                ...state, roles: state.roles.map(role => {
                    if (role.id !== action.roleId) {
                        return role
                    }

                    return {
                        ...role, assignedUsers: [...role.assignedUsers.filter(u => {
                            return !action.removedUsers.map(ru => ru.clientUserId).includes(u.clientUserId)
                        }), ...action.newUsers]
                    }
                })
            }

        case actionTypes.ROLE_PERMISSIONS_UPDATED:
            return {
                ...state, roles: state.roles.map(role => role.id !== action.updatedRole.id ? role : action.updatedRole)
            }

        case actionTypes.CLIENT_USERS_RETRIEVED:
            return { ...state, clientUsers: action.clientUsers }

        case actionTypes.CUSTOMER_FIELDS_RETRIEVED:
            return { ...state, customerFields: action.customerFields.map((field, index) => ({ ...field, id: index })) }

        default:
            throw new Error(`Invalid reducer action: ${action.type}`)
    }
}