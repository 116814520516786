import { convertFromDtoToArray } from "../../library/FilterList/FiltersDto"
import EntityRelationshipDto from "./types/EntityRelationshipDto"
import { v4 as uuidv4 } from "uuid"

export const convertEntityRelationshipsFromDto = (entityRelationships: EntityRelationshipDto[]) =>
    entityRelationships.map(entityRelationship => ({
        ...entityRelationship,
        calculations: [
            ...entityRelationship.calculations.map(calculation => ({
                ...calculation,
                filters: convertFromDtoToArray(calculation.filters, [], [])
            }))
        ],
        id: uuidv4()
    }))
