import { useState, useReducer, useEffect } from "react"
import Header from "./Header"
import { Loading, Radios, Tabs, Tab, useApi, useClient, Overlay } from "invevo-react-components"
import RoleSelection from "./RoleSelection"
import userGroupReducer from "../../reducers/userGroupReducer"
import { actionTypes } from '../../reducers/actionTypes'
import UserView from "./UserView"
import RoleView from "./RoleView"
import AddRole from "./forms/AddRole"
import classes from "./UserGroups.module.scss"
import StandardButton from "../../library/buttons/StandardButton/StandardButton"

const UserGroups = () => {
    const [state, dispatch] = useReducer(
        userGroupReducer,
        {
            roles: [],
            clientUsers: [],
            customerFields: []
        }
    )

    const api = useApi()
    const client = useClient()

    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [showAddRoleForm, setShowAddRoleForm] = useState(false)

    const viewOptions = [
        { id: 0, label: "Names", disabled: true },
        { id: 1, label: "Overview", disabled: true },
        { id: 2, label: "Data" },
        { id: 3, label: "Contact Card", disabled: true }
    ]

    useEffect(() => {
        if (isFetching || hasFetched) return

        setIsFetching(true)

        const clientUsersPromise = api.get(`/api/${client}/client-users`)
            .then(response => {
                const clientUsers = response.data
                    .filter(clientUser => !clientUser.username.endsWith("_PortaUser@gmail.com"))
                    .map(u => ({
                        id: u.reference,
                        username: u.username,
                        isActive: u.isActive
                    }
                    ))
                dispatch({ type: actionTypes.CLIENT_USERS_RETRIEVED, clientUsers })
            })

        const customerFieldsPromise = api.get(`/api/${client}/customer-fields`)
            .then(response => dispatch({ type: actionTypes.CUSTOMER_FIELDS_RETRIEVED, customerFields: response.data }))

        const rolesPromise = api.get(`/api/${client}/roles`)
            .then(response => {
                const roles = response.data.map((role, index) => {
                    const isAdmin = role.name.toLowerCase() === "administrator"
                    return {
                        ...role,
                        id: role.reference,
                        isAdmin: isAdmin,
                        isEnabled: isAdmin || index === 0 || index === 1 || index === 2
                    }
                })
                dispatch({ type: actionTypes.ROLES_RETRIEVED, roles })
            })

        Promise.all([clientUsersPromise, customerFieldsPromise, rolesPromise])
            .then(_ => {
                setHasFetched(true)
                setIsFetching(false)
            }).catch(error => console.error(error))

    }, [api, client, dispatch, hasFetched, isFetching])

    const onNewRoleClick = () => setShowAddRoleForm(true)
    const onCloseAddRoleForm = () => setShowAddRoleForm(false)

    const activeClientUsers = state.clientUsers.filter(clientUser => clientUser.isActive)

    return (
        <Loading isLoading={isFetching} colour="blue">
            <div className="d-flex flex-column flex-grow-1">
                <Header className="py-3">
                    <span className="border-1 border-start border-end h-100" />
                    <div className="d-flex mx-4">
                        <Radios options={viewOptions} defaultSelectedOption={viewOptions[2]} onOptionSelected={() => { }} />
                    </div>
                    <span className="border-1 border-start border-end h-100" />
                    <div className="d-flex mx-4 position-relative">
                        <RoleSelection state={state} dispatch={dispatch} />
                    </div>
                    <span className="border-1 border-start border-end h-100" />
                    <div className="d-flex me-4 ms-auto">
                        <StandardButton label="New Role" ariaLabel="New Role" colour="grey" iconClasses="fal fa-plus" onClick={onNewRoleClick} />
                    </div>
                </Header>
                <Tabs className="flex-grow-1 m-3 h-75" contentClassName={classes["overflow-vertical"]}>
                    <Tab title={`User Roles (${state.roles.length})`} isActive={true}>
                        <RoleView state={state} dispatch={dispatch} />
                    </Tab>
                    <Tab title={`Users (${activeClientUsers.length})`}>
                        <UserView clientUsers={activeClientUsers} />
                    </Tab>
                </Tabs>
            </div>
            {
                showAddRoleForm &&
                <Overlay title="Add Role" onCloseClick={onCloseAddRoleForm}>
                    <AddRole onCancel={onCloseAddRoleForm} onAdd={onCloseAddRoleForm} dispatch={dispatch} />
                </Overlay>
            }
        </Loading>
    )
}

export default UserGroups