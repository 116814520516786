import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { executeAuthAsyncGet, executeAsyncResetGet } from '../../utility/asyncSupport';
import { getLookup } from '../../api/lookupApi';
import { CustomerCreditLimitStatusNames, SearchTypeForCustomerOrVirtualAccount } from './creditConstants';
import Request from './request';
import RequestDisplay from './requestDisplay';
import Decision from './decision';
import DecisionDisplay from './decisionDisplay';
import SecondDecision from './secondDecision';
import { useClient } from 'invevo-react-components';

import classes from './creditLimit.module.scss';

const Index = ({
  searchType,
  creditLimitInfo,
  creditLimitStatus,
  getLookup,
  executeAuthAsyncGet,
  executeAsyncResetGet,
  customerId
}) => {

  const client = useClient()

  const refresh = useCallback(() => {
    if (!customerId) return;

    const payload = {
      customerId: customerId,
      search_type: searchType
    };
    executeAuthAsyncGet(
      client,
      'CreditLimit/GetCreditInformation',
      'CREDIT_LIMIT_INFO',
      payload
    );
    executeAuthAsyncGet(
      client,
      'CreditLimit/GetStatus',
      'CREDIT_LIMIT_STATUS',
      payload
    );
    executeAuthAsyncGet(
      client,
      'CreditLimit/GetPendingRequest',
      'GET_PENDING_REQUEST',
      payload
    );
  }, [executeAuthAsyncGet, customerId, searchType, client]);

  useEffect(() => {
    if (!creditLimitInfo.isFetching) {
      refresh();
    }
    getLookup(client, 'lookup/users', 'users');
    getLookup(client, 'lookup/ReasonsForLimitIncrease', 'reasonsLimitIncrease');

    return () => {
      executeAsyncResetGet(client, 'CREDIT_LIMIT_INFO');
      executeAsyncResetGet(client, 'CREDIT_LIMIT_STATUS');
      executeAsyncResetGet(client, 'GET_PENDING_REQUEST');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const creditInfo = creditLimitInfo.result;
  const creditStatus = creditLimitStatus.result;

  const {
    PENDING_FIRST_DECISION_CAN_APPROVE,
    PENDING_FIRST_DECISION_CANNOT_APPROVE,
    PENDING_SECOND_DECISION_CAN_APPROVE,
    PENDING_SECOND_DECISION_CANNOT_APPROVE
  } = CustomerCreditLimitStatusNames;

  let requestComponent = <div></div>;
  if (creditInfo) {
    requestComponent = (
      <Request
        client={client}
        customerId={customerId}
        refreshCallback={refresh}
        searchType={searchType}
      ></Request>
    );
  }

  const requestDisplay = () => (
    <RequestDisplay
      client={client}
      customerId={customerId}
      refreshCallback={refresh}
      searchType={searchType}
    ></RequestDisplay>
  );
  const decisionDisplay = () => (
    <DecisionDisplay
      client={client}
      customerId={customerId}
      refreshCallback={refresh}
      searchType={searchType}
    ></DecisionDisplay>
  );

  switch (creditStatus) {
    case PENDING_FIRST_DECISION_CAN_APPROVE:
      requestComponent = (
        <div>
          {requestDisplay()}
          <Decision
            client={client}
            customerId={customerId}
            refreshCallback={refresh}
            searchType={searchType}
          ></Decision>
        </div>
      );
      break;

    case PENDING_FIRST_DECISION_CANNOT_APPROVE:
      requestComponent = <div>{requestDisplay()}</div>;
      break;
    case PENDING_SECOND_DECISION_CAN_APPROVE:
      requestComponent = (
        <div>
          {requestDisplay()}
          {decisionDisplay()}
          <SecondDecision
            client={client}
            customerId={customerId}
            refreshCallback={refresh}
            searchType={searchType}
          ></SecondDecision>
        </div>
      );
      break;
    case PENDING_SECOND_DECISION_CANNOT_APPROVE:
      requestComponent = (
        <div>
          {requestDisplay()}
          {decisionDisplay()}
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <div className={`credit-limit-pane container-fluid overflow-auto ${classes.container}`}>
      <div className="row">
        <div className="col-12 top20">
          <h2 className="text-white">Credit Limit</h2>
        </div>
        <div className="col-12">{requestComponent}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    asyncReducer: { CREDIT_LIMIT_STATUS, CREDIT_LIMIT_INFO },
    currentSelectionReducer: { selectedAccount }
  } = state;
  return {
    creditLimitStatus: CREDIT_LIMIT_STATUS,
    creditLimitInfo: CREDIT_LIMIT_INFO,
    searchType: selectedAccount.miaAccountIsVirtualAccount
      ? SearchTypeForCustomerOrVirtualAccount.FORVIRTUALACCOUNT
      : SearchTypeForCustomerOrVirtualAccount.FORCUSTOMER,
    customerId:  state.currentSelectionReducer.selectedAccount.miaAccountId
  };
};

export default connect(mapStateToProps, {
  getLookup,
  executeAuthAsyncGet,
  executeAsyncResetGet
})(Index);
