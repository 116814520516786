import update from 'immutability-helper';
import * as types from '../actions/actionTypes';

const initialState = {
    documentType: {},
    showForm: false,
    refreshGrid: false
};

export default function fileImportDocumentTypeReducer(state = initialState, action) {
    switch (action.type) {
        case types.FILE_DOCUMENT_TYPE_UPDATE:
            return update(state, {
                documentType: { $set: action.documentType },
                showForm: { $set: action.showForm },
                refreshGrid: { $set: action.refreshGrid }
            });
        default:
            return state;
    }
}