import { useGrid } from "../Grid"
import { convertToCurrency } from "../../helpers/numberUtils"
import HeaderDefinition from "../types/HeaderDefinition"
import { DataTypeEnum } from "../../../routes/entityConfig/types/DataType"
import classes from "./Rows.module.scss"
import { Dispatch, SetStateAction } from "react"
import { EntityGridAggregationValueDto } from "./Rows"

type HeaderType = "TOTAL" | "SELECTED"

type RowAggregationsProps = {
    sortedHeaders: HeaderDefinition[]
    isPinned: boolean
    setPinned: Dispatch<SetStateAction<boolean>>
    selectableRows?: boolean
    totalAggs: EntityGridAggregationValueDto[]
    totalFetching: boolean
    selectedAggs: EntityGridAggregationValueDto[]
    selectedFetching: boolean
}

const RowAggregations = ({
    sortedHeaders,
    isPinned,
    setPinned,
    selectableRows,
    totalAggs,
    totalFetching,
    selectedAggs,
    selectedFetching
}: RowAggregationsProps) => {
    const grid = useGrid()

    const areRowsSelected = grid.selection.isAllRowsSelected || grid.selection.selectedRefs.length > 0
    const toggleAggsPinned = () => setPinned(v => !v)

    const renderAggregatedCurrencies = (aggs: EntityGridAggregationValueDto | undefined, headerType: HeaderType) => {
        if (headerType === "TOTAL" && totalFetching) return <i className="text-white">Loading...</i>
        if (headerType === "SELECTED" && selectedFetching) return <i className="text-white">Loading...</i>
        if (aggs === undefined) return ""

        return Object.entries(aggs.totalByGroup)
            .map(([key, value]) => `${convertToCurrency(value, key)}`)
            .join(" ")
    }

    const renderAggregationForHeader = (header: HeaderDefinition, headerType: HeaderType, specificAgg: EntityGridAggregationValueDto | undefined) => {
        switch (header.displayType) {
            case DataTypeEnum.MONEY:
                return (
                    <th
                        key={`aggregation-${headerType}-${header.value}`}
                        className={`${classes.aggregationCell} fw-normal`}
                        aria-label={`aggregation-${headerType.toLowerCase()}-${header.value}`}
                    >
                        <strong>{headerType.toString()}</strong>: {renderAggregatedCurrencies(specificAgg, headerType)}
                    </th>
                )
            default:
                return <th key={`aggregation-${headerType}-${header.value}`} />
        }
    }

    const renderAggregationForRow = (headers: HeaderDefinition[], headerType: HeaderType) => {
        if (headers.filter(header => header.displayType === DataTypeEnum.MONEY).length === 0) return
        const aggs = headerType === "TOTAL" ? totalAggs : selectedAggs

        return (
            <tr aria-label={`aggregation-row-${headerType.toLowerCase()}`} className={`${classes.aggregationRow} bg-blue text-white`}>
                {selectableRows && headerType === "TOTAL" && (
                    <th
                        role={"button"}
                        aria-label={"pin-aggregation-rows-button"}
                        className="p-2 pe-auto"
                        rowSpan={areRowsSelected ? 2 : 1}
                        onClick={toggleAggsPinned}
                    >
                        <i className={`${isPinned ? "fa-solid " : "fa-regular "} fa-thumbtack`}></i>
                    </th>
                )}
                {sortedHeaders.map(field =>
                    renderAggregationForHeader(
                        field,
                        headerType,
                        aggs.find(agg => agg.entityFieldReference === field.value)
                    )
                )}
                <th />
            </tr>
        )
    }
    return (
        <>
            {totalAggs && renderAggregationForRow(sortedHeaders, "TOTAL")}
            {areRowsSelected && selectedAggs && renderAggregationForRow(sortedHeaders, "SELECTED")}
        </>
    )
}

export default RowAggregations
