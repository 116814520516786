import React from 'react';
import { dueDateMessage } from '../../utility/dueDateMessage';
import CurrencyFormatter from '../common/CurrencyFormatter';
import { IconButton } from '../common/buttons/iconButton';
import { customerDetailsPane, stateTaskType } from '../../constants';
import { daysSinceMessage } from '../../utility/daysSinceMessage';
import { useInvevoSession } from 'invevo-react-components';
import { useCustomer } from '../../../../contexts/CustomerContext';
import { useEntity } from '../../../../contexts/EntityContext';
import { useFeatureToggle } from '../../../../hooks/useFeatureToggle';


const UserTaskCard = ({
  dataItem,
  onClick
}) => {
  const [, setInvevoSession] = useInvevoSession()
  const [, setCustomer] = useCustomer()
  const [, setEntity] = useEntity()
  const { isEnabled: newEntityStructure } = useFeatureToggle("newEntityStructure")

  const isComplete = dataItem.TaskCompletionId > 0;

  const handleClick = e => {
    e.stopPropagation()
    onClick({ ...e, dataItem })
  }

  const panelClass = dataItem.selected
    ? 'result-selected mia-action'
    : 'result-default mia-action';

  const navToDocuments = event => {
    event.stopPropagation()

    const gridAccountId = dataItem.CustomerId
      ? dataItem.CustomerId
      : dataItem.VirtualAccountId
    const gridAccountIsVirtual = !dataItem.CustomerId
    setInvevoSession({ selectedCustomerTab: customerDetailsPane.files, showTaskFiles: false })

    setCustomer({
      reference: dataItem.AccountCode,
      sqlId: gridAccountId,
      isVirtualAccount: gridAccountIsVirtual
    })
  }

  const navToTransactions = event => {
    event.stopPropagation()

    const gridAccountId = dataItem.CustomerId
      ? dataItem.CustomerId
      : dataItem.VirtualAccountId
    const gridAccountIsVirtual = !dataItem.CustomerId

    setInvevoSession({ selectedCustomerTab: "transactions" })

    newEntityStructure && setEntity({
      reference: dataItem.AccountCode,
      sqlId: gridAccountId,
      entityType: "customer"
    })

    setCustomer({
      reference: dataItem.AccountCode,
      sqlId: gridAccountId,
      isVirtualAccount: gridAccountIsVirtual
    })
  }

  const renderAwaitCreditLimit = () => {
    return (
      <>
        <div className="col-12 top15">
          <h5>
            Awaiting Credit Limit Decision{' '}
            <span className="float-end">
              <IconButton
                onClick={handleClick}
                title="Edit"
                icon="edit"
                className="btn btn-link mt-n2 mr-n3"
              />
            </span>
          </h5>
          <h3>{dataItem.CompanyName}</h3>
          <h4>
            <CurrencyFormatter amount={dataItem.Amount} />{' '}
            {dataItem.CurrencyCode}
          </h4>
          <h4 className="mb-0 shout text text-end">
            {dueDateMessage(dataItem.Date)}
          </h4>
        </div>
      </>
    );
  };

  const renderNewCreditLimit = () => {
    const isApproved = dataItem.CreditLimitDecision === 1;
    const badgeClasses = isApproved
      ? 'shout badge badge-success'
      : 'shout badge bg-danger';
    return (
      <div className={panelClass} onClick={handleClick}>
        <div className="col-12 top15">
          <h5>
            New Credit Limit Decision{' '}
            <span className="float-end">
              <IconButton
                onClick={handleClick}
                title="Edit"
                icon="edit"
                className="btn btn-link mt-n2 mr-n3"
              />
            </span>
          </h5>
          <h3>{dataItem.CompanyName}</h3>
          <h4>
            <span className={badgeClasses}>
              {isApproved ? 'Approved' : 'Declined'}
            </span>{' '}
            <CurrencyFormatter amount={dataItem.Amount} />{' '}
            {dataItem.CurrencyCode}{' '}
          </h4>
          <h4 className="mb-0 shout text text-end">
            <span className="badge bg-secondary">
              {daysSinceMessage(dataItem.Date)}
            </span>
          </h4>
        </div>
      </div>
    );
  };

  const renderUnreadEmail = () => {
    return (
      <div className={panelClass} onClick={handleClick}>
        <div className="col-12 top15">
          <h5>
            {dataItem.UnreadEmailsCount} unread email{' '}
            <span className="float-end">
              <IconButton
                onClick={handleClick}
                title="Edit"
                icon="edit"
                className="btn btn-link mt-n2 mr-n3"
              />
            </span>
          </h5>
          <h3>{dataItem.CompanyName}</h3>
          <h4 className="mb-0 shout text text-end">
            {dueDateMessage(dataItem.Date)}
          </h4>
        </div>
      </div>
    );
  };

  const renderTask = () => {
    return (
      <div className={panelClass} onClick={handleClick}>
        <div className="col-12 top15">
          <h5>
            #{dataItem.TaskId}
            <span className="float-end">
              {!isComplete && (
                <IconButton
                  onClick={handleClick}
                  title="Edit"
                  icon="edit"
                  className="btn btn-link mt-n2 mr-n3"
                />
              )}
            </span>
          </h5>

          <h3>{dataItem.CompanyName}</h3>

          <h4>{dataItem.TaskType || dataItem.TaskTypeId}</h4>
          {dataItem.TaskDescription !== dataItem.TaskType && (
            <h5>{dataItem.TaskDescription}</h5>
          )}
        </div>
        <div className="col-4">
          <div className="btn-group ml-n3">
            {dataItem.DocumentsCount > 0 && (
              <button
                className="btn btn-link"
                title="Documents"
                onClick={navToDocuments}
              >
                {dataItem.DocumentsCount} <i className="fa fa-file-alt" /> {/* Check icon renders */}
              </button>
            )}

            {dataItem.TransactionsCount > 0 && (
              <button
                className="btn btn-link"
                title="Transactions"
                onClick={navToTransactions}
              >
                {dataItem.TransactionsCount} <i className="fa fa-bars" />
              </button>
            )}
          </div>
        </div>
        <div className="col-8">
          <h4 className="mb-0 shout text text-end">
            {dueDateMessage(dataItem.Date)}
          </h4>
        </div>
      </div>
    );
  };

  let cardContent;
  switch (dataItem.StatefulTaskType) {
    case stateTaskType.UNREAD_EMAIL:
      cardContent = renderUnreadEmail(dataItem);
      break;
    case stateTaskType.AWAIT_CREDIT_LIMIT:
      cardContent = renderAwaitCreditLimit(dataItem);
      break;
    case stateTaskType.NEW_CREDIT_LIMIT:
      cardContent = renderNewCreditLimit(dataItem);
      break;
    default:
      cardContent = renderTask(dataItem);
      break;
  }

  return (
    <td className={panelClass} onClick={handleClick}>
      {
        <div className="container-fluid no-tr-grid">
          <div className="row no-tr-grid">{cardContent}</div>
        </div>
      }
    </td>
  );
};

export default UserTaskCard
