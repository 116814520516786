import { Toggle } from "invevo-react-components"
import { convertToCurrency } from "../../../../library/helpers/numberUtils"
import { AccountDto } from "../../types/dtos/AccountDto"
import classes from "./BankAccount.module.scss"

type BankAccountProps = {
    account: AccountDto
    isSelected: boolean
    onSelectionToggled: (account: AccountDto) => void
}

const BankAccount = ({ account, isSelected, onSelectionToggled }: BankAccountProps) => {
    const isAvailableDebit = account.balance.available?.creditDebitIndicator === "Debit"

    const onToggleClick = () => {
        onSelectionToggled(account)
    }

    return (
        <div className={`d-flex align-items-center text-white w-100 p-2 ${classes["mobile-background"]}`}>
            <span>{account.metadata.accountHolderNames}</span>
            <span className="ms-auto fw-bold">
                {isAvailableDebit ? "-" : ""}
                {convertToCurrency(account.balance.available?.amount ?? 0, account.metadata.currencyCode)}
            </span>
            <Toggle className="ms-2" status={isSelected} onStatusChanged={onToggleClick} />
        </div>
    )
}

export default BankAccount
