import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { executeAuthAsyncGet, executeAsyncResetGet} from '../../utility/asyncSupport';
import Dropdown from '../../components/common/dropdown';
import classes from './Post.module.scss'

const TemplateSelector = ({
  client,
  miaAccountId,
  postTemplates,
  executeAsyncResetGet,
  executeAuthAsyncGet,
  onSaveTemplateInfos
}) => {
  const [templateId, setTemplateId] = useState(null);

  useEffect(() => {
    executeAuthAsyncGet(
      client,
      'Post/Templates',
      'POST_TEMPLATES_LIST',
      { customerId: miaAccountId },
      (result) => {
        if (!result || result.length === 0) return;

        const id = result[0].id;
        onTemplateChange(id);
      }
    );
    return executeAsyncResetGet('POST_TEMPLATES_LIST');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [miaAccountId]);

  const onTemplateChange = (id) => {
    setTemplateId(id);
    executeAuthAsyncGet(client, 'Post/Template', 'POST_TEMPLATE', { id }, (result) => {
      onSaveTemplateInfos(result);
    });
  };

  return (
    <Dropdown
      className={`btn dropdown-toggle inv ${classes['btn-custom']}`}
      title="Template"
      selectedItemId={templateId || 0}
      data={postTemplates || []}
      onClick={onTemplateChange}
      scroll={true}
    />
  );
};

const mapStateToProps = (state) => ({
  postTemplates: state.asyncReducer.POST_TEMPLATES_LIST.result || [],
  miaAccountId: state.currentSelectionReducer.selectedAccount.miaAccountId
});

export default connect(mapStateToProps, {
  executeAuthAsyncGet,
  executeAsyncResetGet
})(TemplateSelector);
