import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Task from './task.js';
import CustomerTaskList from './customerTaskList.js';

const CustomerTaskIndex = ({ hasFocusedTask, customerId, isVirtualAccount }) => {
  const [selectedCustomerId, setSelectedCustomerId] = useState("")

  useEffect(() => {
    if (customerId === selectedCustomerId) return

    setSelectedCustomerId(customerId)
  }, [customerId, selectedCustomerId])

  if (!selectedCustomerId) return <h4 className="m-3">Select an account</h4>;

  if (!hasFocusedTask) {
    return <CustomerTaskList
      customerId={selectedCustomerId}
      isVirtualAccount={isVirtualAccount}
    />;
  }

  return <Task
    customerId={selectedCustomerId}
    isVirtualAccount={isVirtualAccount}
  />;
};

const mapStateToProps = (state) => {
  return {
    hasFocusedTask: state.taskReducer.selectedTask !== null,
    customerId: state.currentSelectionReducer.selectedAccount.miaAccountId,
    isVirtualAccount: state.currentSelectionReducer.selectedAccount.miaAccountIsVirtualAccount
  };
};

export default connect(mapStateToProps)(CustomerTaskIndex);