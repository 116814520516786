export const convertToCurrency = (
    value: number | string,
    currencyCode: string = "",
    culture: string = "en-GB",
    hideCurrencySymbol: boolean = false
): string => {
    try {
        return value !== ""
            ? hideCurrencySymbol || !currencyCode
                ? new Intl.NumberFormat("en-GB", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                  }).format(typeof value === "number" ? value : parseFloat(value))
                : new Intl.NumberFormat(culture, {
                      style: "currency",
                      currency: currencyCode,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                  }).format(typeof value === "number" ? value : parseFloat(value))
            : ""
    } catch (_) {
        return `${currencyCode && !hideCurrencySymbol ? currencyCode : ""}${value}`
    }
}

export const isValidNumber = (number: string) => {
    const isValidNumberRegex = /^-?\d+(\.\d+)?$/
    return isValidNumberRegex.test(number)
}

export const isValidPositiveInteger = (number: string) => {
    const isValidIntegerRegex = /^[0-9]*$/
    return isValidIntegerRegex.test(number)
}
