import { useState } from "react"
import classes from "./EntityConfigDataFieldForm.module.scss"
import Input from "../../../library/Inputs/Input"
import GreyButton from "../../../library/buttons/GreyButton/GreyButton"
import Checkbox from "../../../library/form/Checkbox"
import Dropdown from "../../../library/dropdowns/Dropdown"
import {
    DataPrimitiveTypeDropdownOptions,
    DataPrimitiveTypeEnum,
    DataTypeDropdownOptions,
    DataTypeEnum,
    getDataPrimitiveTypeForEntityDataType
} from "../types/DataType"
import DropdownOption from "../../../types/DropdownOptions"
import EntityDataField from "../types/EntityDataField"
import Lookup from "../../../types/Lookup"
import moment from "moment-timezone"
import AutoCompleteDropdown from "../../../library/dropdowns/AutoCompleteDropdown"
import { HardcodedEntityDataFields, MoneyDataTypeOptionDropdownOptions } from "../constants"
import EntityConfig from "../types/EntityConfig"
import { toSnakeCase } from "../../../library/helpers/stringUtils"
import AggregationType from "../types/AggregationType"
import EntityDataFieldDropdown from "../../../library/dropdowns/EntityDataFieldDropdown"
import EntityRelationshipCalculation from "../types/EntityRelationshipCalculation"
import EntityRelationship from "../types/EntityRelationship"
import FilterList from "../../../library/FilterList/FilterList"
import { getDataFieldsFromEntityDataFields } from "../../../library/helpers/entityHelpers"
import GenericFilter from "../../../library/FilterList/filterTypes/GenericFilter"
import IntraEntityCalculationConfigForm from "./IntraEntityCalculationConfigForm"
import IntraEntityCalculation from "../types/IntraEntityCalculation"
import Toggle from "../../../library/toggle/Toggle"
import IntraEntityConcatenationConfigForm from "./IntraEntityConcatenationConfigForm"
import IntraEntityConcatenationPart from "../types/IntraEntityConcatenationPart"
import { exists } from "../../../library/helpers/tsUtils"
import { CalculationType, CalculationTypes } from "../types/CalculationType"

import BooleanInfoElement from "../../../library/cards/BooleanInfoElement"
import DateInfoElement from "../../../library/cards/DateInfoElement"
import LookupInfoElement from "../../../library/cards/LookupInfoElement"
import MoneyInfoElement from "../../../library/cards/MoneyInfoElement"
import NumberInfoElement from "../../../library/cards/NumberInfoElement"
import TextInfoElement from "../../../library/cards/TextInfoElement"
import { DEFAULT_DATE_FORMAT, DEFAULT_TIMEZONE } from "../../../library/helpers/dateUtils"

type Props = {
    allEntityConfigs: EntityConfig[]
    entityConfig: EntityConfig
    entityDataField?: EntityDataField
    relationships: EntityRelationship[]
    isEditing: boolean
    lookups: Lookup[]
    toggleIsShown?: () => void
    onSaveEntityDataField: (
        entityDataField: EntityDataField,
        calculation: EntityRelationshipCalculation | undefined,
        childEntityReference: string | undefined
    ) => void
    showHeader?: boolean
    isCompact?: boolean
    className?: string
}

const EntityConfigDataFieldForm = ({
    allEntityConfigs,
    entityConfig,
    entityDataField,
    relationships,
    isEditing,
    lookups,
    toggleIsShown,
    onSaveEntityDataField,
    showHeader = true,
    isCompact = false,
    className = ""
}: Props) => {
    const [displayName, setDisplayName] = useState<string>(entityDataField?.displayName ?? "")
    const [description, setDescription] = useState<string>(entityDataField?.description ?? "")
    const [isQuickFilter, setIsQuickFilter] = useState<boolean>(entityDataField?.isQuickFilter ?? false)
    const [dataPrimitiveType, setDataPrimitiveType] = useState<DataPrimitiveTypeEnum | undefined>(entityDataField?.dataPrimitiveType)
    const [dataType, setDataType] = useState<DataTypeEnum | undefined>(entityDataField?.dataType.type)
    const [defaultValue, setDefaultValue] = useState<string | undefined>(entityDataField?.defaultValue)
    const [lookupReference, setLookupReference] = useState<string | undefined>(entityDataField?.dataType.lookupReference)
    const [timeZone, setTimeZone] = useState<string | undefined>(entityDataField?.dataType.timeZone)
    const [currencyCode, setCurrencyCode] = useState<string | undefined>(entityDataField?.dataType.currencyCode)
    const [currencyFieldRef, setCurrencyFieldRef] = useState<string | undefined>(entityDataField?.dataType.currencyFieldReference)
    const [useCurrencyCode, setUseCurrencyCode] = useState<boolean>(exists(entityDataField?.dataType.currencyCode))

    const originalRelationship: EntityRelationship | undefined = relationships.find(relationship =>
        relationship.calculations.some(calculation => calculation.destinationFieldName === entityDataField?.fieldName)
    )

    const originalCalculation: EntityRelationshipCalculation | undefined = originalRelationship
        ? originalRelationship.calculations.find(calculation => calculation.destinationFieldName === entityDataField?.fieldName)
        : undefined

    const [aggregationType, setAggregationType] = useState<keyof typeof AggregationType>(originalCalculation?.calculationType ?? AggregationType.SUM)
    const [aggregationChildEntityReference, setAggregationChildEntityReference] = useState<string | undefined>(originalRelationship?.childEntityReference)
    const [aggregationGroupingField, setAggregationGroupingField] = useState<string | undefined>(originalCalculation?.sourceFieldName)
    const [aggregationFilters, setAggregationFilters] = useState<GenericFilter[]>(originalCalculation?.filters ?? [])
    const [intraEntityCalculation, setIntraEntityCalculation] = useState<IntraEntityCalculation | undefined>(entityDataField?.intraEntityCalculation)
    const [intraEntityConcatenation, setIntraEntityConcatenation] = useState<IntraEntityConcatenationPart[] | undefined>(
        entityDataField?.intraEntityConcatenation
    )
    const [trackChanges, setTrackChanges] = useState<boolean>(entityDataField?.trackChanges ?? false)
    const [hasDefaultValue, setHasDefaultValue] = useState<boolean>(exists(entityDataField?.defaultValue))
    const [isCalculation, setIsCalculation] = useState<boolean>(
        exists(originalCalculation) || exists(entityDataField?.intraEntityCalculation) || exists(entityDataField?.intraEntityConcatenation)
    )
    const calculationType = (() => {
        if (exists(intraEntityCalculation)) return CalculationTypes.calculation
        if (exists(intraEntityConcatenation)) return CalculationTypes.concatenation
        return CalculationTypes.aggregation
    })()

    const fieldName =
        isEditing && entityDataField?.fieldName !== undefined && entityDataField?.fieldName !== ""
            ? entityDataField?.fieldName
            : toSnakeCase(`${dataPrimitiveType ?? ""} ${displayName}`)

    const isPartOfIntraCalculation =
        isEditing &&
        (entityConfig.fields.some(field => field.intraEntityCalculation?.baseField === fieldName) ||
            entityConfig.fields.some(field => field.intraEntityCalculation?.operations?.some(operation => operation.fieldName === fieldName)))

    const isDuplicateFieldName = isEditing ? false : entityConfig.fields.some(dataField => dataField.fieldName === fieldName)

    const getCalculationOptionsForDataPrimitiveType = (type: DataPrimitiveTypeEnum | undefined) => {
        switch (type) {
            case DataPrimitiveTypeEnum.TEXT:
                return Array<DropdownOption<CalculationType>>({ value: CalculationTypes.concatenation, label: "Concatenation" })
            case DataPrimitiveTypeEnum.NUMBER:
                return Array<DropdownOption<CalculationType>>(
                    { value: CalculationTypes.aggregation, label: "Roll Up Aggregation" },
                    ...(!isPartOfIntraCalculation ? [{ value: CalculationTypes.calculation, label: "Formula" }] : [])
                )
            case DataPrimitiveTypeEnum.DATE:
                return Array<DropdownOption<CalculationType>>({ value: CalculationTypes.aggregation, label: "Roll Up Aggregation" })
            case DataPrimitiveTypeEnum.BOOLEAN:
            default:
                return Array<DropdownOption<CalculationType>>()
        }
    }

    const calculationTypeDropdownOptions = getCalculationOptionsForDataPrimitiveType(dataPrimitiveType)

    const toggleIsQuickFilter = () => setIsQuickFilter(currentIsQuickFilter => !currentIsQuickFilter)
    const toggleIsCalculation = () => {
        const defaultCalculationOption = getCalculationOptionsForDataPrimitiveType(dataPrimitiveType)[0]
        defaultCalculationOption && onCalculationTypeUpdated(defaultCalculationOption)

        setIsCalculation(currentIsCalculation => !currentIsCalculation)
    }

    const selectedDataPrimitiveType = DataPrimitiveTypeDropdownOptions.find(option => option.value === dataPrimitiveType)
    const onDataPrimitiveTypeUpdated = (option: DropdownOption<DataPrimitiveTypeEnum>) => {
        if (option.value === dataPrimitiveType) return

        setDefaultValue(undefined)
        setCurrencyCode(undefined)
        setCurrencyFieldRef(undefined)
        setLookupReference(undefined)
        setTimeZone(undefined)
        setDateTimeFormat(undefined)
        setIsCalculation(false)
        setDataPrimitiveType(option.value)

        if (option.value === DataPrimitiveTypeEnum.BOOLEAN) {
            setDataType(DataTypeEnum.BOOLEAN)
        } else if (option.value === DataPrimitiveTypeEnum.DATE) {
            setDataType(DataTypeEnum.DATE)
            setDateTimeFormat(DEFAULT_DATE_FORMAT)
        } else {
            setDataType(undefined)
        }

        const defaultCalculationOption = getCalculationOptionsForDataPrimitiveType(option.value)[0]
        defaultCalculationOption && onCalculationTypeUpdated(defaultCalculationOption)
    }

    const availableDataTypeDropdownOptions = DataTypeDropdownOptions.filter(option => getDataPrimitiveTypeForEntityDataType(option.value) === dataPrimitiveType)
    const selectedDataType = DataTypeDropdownOptions.find(option => option.value === dataType)
    const onDataTypeUpdated = (option: DropdownOption<DataTypeEnum>) => {
        setDefaultValue(undefined)
        setCurrencyCode(undefined)
        setCurrencyFieldRef(undefined)
        setLookupReference(undefined)
        setTimeZone(undefined)
        setDateTimeFormat(undefined)
        setDataType(option.value)
    }

    const lookupDropdownOptions: DropdownOption<string>[] = lookups.map(lookup => ({ label: lookup.name, value: lookup.reference }))
    const selectedLookupReference = lookupDropdownOptions.find(option => option.value === lookupReference)
    const onLookupReferenceUpdated = (option: DropdownOption<string>) => {
        setLookupReference(option.value)
    }

    const timeZoneDropdownOptions: DropdownOption<string>[] = moment.tz.names().map(timeZone => ({ value: timeZone, label: timeZone }))
    const selectedTimeZone = timeZoneDropdownOptions.find(option => option.value === timeZone)
    const onTimeZoneUpdated = (option: DropdownOption<string> | undefined) => {
        setTimeZone(option?.value)
    }

    const [dateTimeFormat, setDateTimeFormat] = useState<string | undefined>(
        entityDataField?.dataPrimitiveType === DataPrimitiveTypeEnum.DATE ? entityDataField?.dataType.format ?? DEFAULT_DATE_FORMAT : undefined
    )

    const selectedCurrencyCode = MoneyDataTypeOptionDropdownOptions.find(option => option.value === currencyCode)
    const onCurrencyCodeUpdated = (option: DropdownOption<string> | undefined) => {
        setCurrencyCode(option?.value)
    }

    const currencyFieldDropdownOptions: DropdownOption<string>[] = entityConfig.fields.map(field => ({ label: field.displayName, value: field.fieldName }))
    const selectedCurrencyFieldRef = currencyFieldDropdownOptions.find(option => option.value === currencyFieldRef)
    const onCurrencyFieldRefUpdated = (option: DropdownOption<string> | undefined) => {
        setCurrencyFieldRef(option?.value)
    }

    const toggleUseCurrencyCode = () => {
        setCurrencyCode(undefined)
        setCurrencyFieldRef(undefined)
        setUseCurrencyCode(!useCurrencyCode)
    }

    const onAggregationTypeUpdated = (option: DropdownOption<keyof typeof AggregationType>) => setAggregationType(option.value)

    const onCalculationTypeUpdated = (option: DropdownOption<CalculationType>) => {
        if (option.value === calculationType) return

        setAggregationChildEntityReference(AggregationType.SUM)
        setAggregationChildEntityReference(undefined)
        setAggregationGroupingField(undefined)
        setAggregationFilters([])

        switch (option.value) {
            case CalculationTypes.aggregation:
                setIntraEntityCalculation(undefined)
                setIntraEntityConcatenation(undefined)
                break
            case CalculationTypes.calculation:
                setIntraEntityCalculation({ baseField: "", operations: [] })
                setIntraEntityConcatenation(undefined)
                break
            case CalculationTypes.concatenation:
                setIntraEntityCalculation(undefined)
                setIntraEntityConcatenation([{ ordinal: 1, fieldName: "", hardcodedValue: undefined }])
                break
        }
    }

    const toggleTrackChanges = () => setTrackChanges(trackChanges => !trackChanges)
    const toggleDefaultValue = () => {
        if (hasDefaultValue) setDefaultValue(undefined)
        setHasDefaultValue(hasDefaultValue => !hasDefaultValue)
    }
    const aggregationChildEntityOptions: DropdownOption<string>[] = relationships
        .filter(relationship => relationship.parentEntityReference === entityConfig.reference)
        .map(relationship => ({
            label:
                allEntityConfigs.find(entityConfig => entityConfig.reference === relationship.childEntityReference)?.displayName ??
                relationship.childEntityReference,
            value: relationship.childEntityReference
        }))

    const onAggregationChildEntityReferenceUpdated = (option: DropdownOption<string>) => setAggregationChildEntityReference(option.value)

    const aggregationGroupingFieldOptions = (
        allEntityConfigs.find(entityConfig => entityConfig.reference === aggregationChildEntityReference)?.fields ?? []
    ).filter(field => {
        const canShowNumberField = field.dataPrimitiveType === DataPrimitiveTypeEnum.NUMBER && dataPrimitiveType === DataPrimitiveTypeEnum.NUMBER
        const canShowDateField = field.dataPrimitiveType === DataPrimitiveTypeEnum.DATE && dataPrimitiveType === DataPrimitiveTypeEnum.DATE

        switch (aggregationType) {
            case AggregationType.MAX:
            case AggregationType.MIN:
                return canShowNumberField || canShowDateField
            case AggregationType.AVERAGE:
            case AggregationType.SUM:
                return canShowNumberField
            default:
                return true
        }
    })

    const aggregationFilterFieldOptions = allEntityConfigs.find(entityConfig => entityConfig.reference === aggregationChildEntityReference)?.fields ?? []

    const onAggregationGroupingFieldUpdated = (option: EntityDataField | undefined) => setAggregationGroupingField(option?.fieldName)

    const saveField = () => {
        if (dataPrimitiveType === undefined || dataType === undefined) return

        const entityDataField: EntityDataField = {
            fieldName: fieldName,
            displayName: displayName,
            description: description,
            dataPrimitiveType: dataPrimitiveType,
            dataType: {
                type: dataType,
                lookupReference: lookupReference,
                timeZone: timeZone,
                format: dateTimeFormat,
                currencyCode: currencyCode,
                currencyFieldReference: currencyFieldRef
            },
            defaultValue: defaultValue,
            isQuickFilter: isQuickFilter,
            intraEntityCalculation: showIsCalculationAsTicked ? intraEntityCalculation : undefined,
            intraEntityConcatenation: showIsCalculationAsTicked ? intraEntityConcatenation : undefined,
            trackChanges: trackChanges
        }

        const shouldSaveCalculation =
            isCalculation &&
            calculationType === CalculationTypes.aggregation &&
            dataPrimitiveType !== DataPrimitiveTypeEnum.TEXT &&
            dataPrimitiveType !== DataPrimitiveTypeEnum.BOOLEAN

        const calculation = shouldSaveCalculation
            ? {
                  calculationType: aggregationType,
                  sourceFieldName: aggregationGroupingField ?? "reference",
                  destinationFieldName: fieldName,
                  filters: aggregationFilters
              }
            : undefined

        if (calculation === undefined) {
            onSaveEntityDataField(entityDataField, undefined, undefined)
        } else {
            onSaveEntityDataField(entityDataField, calculation, aggregationChildEntityReference)
        }
        toggleIsShown && toggleIsShown()
    }

    const isDataFieldValid = () => {
        if (fieldName === "" || displayName === "" || dataPrimitiveType === undefined || dataType === undefined || isDuplicateFieldName) {
            return false
        }

        if (isCalculation) {
            switch (calculationType) {
                case CalculationTypes.aggregation:
                    if (aggregationChildEntityReference === undefined) return false
                    if (aggregationType !== AggregationType.COUNT && aggregationGroupingField === undefined) return false
                    break
                case CalculationTypes.calculation:
                    if (intraEntityCalculation?.baseField === undefined) return false
                    if (intraEntityCalculation.operations.some(operation => operation.operation === undefined)) return false
                    if (intraEntityCalculation.operations.some(operation => operation.fieldName === undefined && operation.hardcodedValue === undefined))
                        return false
                    break
                case CalculationTypes.concatenation:
                    if (intraEntityConcatenation === undefined) return false
                    if (intraEntityConcatenation?.length === 0) return false
                    if (intraEntityConcatenation.some(concatenationPart => !exists(concatenationPart.fieldName) && !exists(concatenationPart.hardcodedValue)))
                        return false
                    break
            }
        }

        switch (dataType) {
            case DataTypeEnum.DATE:
                return timeZone !== undefined && dateTimeFormat !== undefined
            case DataTypeEnum.LOOKUP:
                return lookupReference !== undefined
            case DataTypeEnum.MONEY:
                return currencyCode !== undefined || currencyFieldRef !== undefined
            default:
                return true
        }
    }

    const getAggregationTypeOptions = () => {
        switch (dataPrimitiveType) {
            case DataPrimitiveTypeEnum.NUMBER:
                return aggregateToNumberOptions
            case DataPrimitiveTypeEnum.DATE:
                return aggregateToDateOptions
            default:
                return []
        }
    }

    const isHardcodedField = isEditing && HardcodedEntityDataFields.some(hardcodedField => hardcodedField.fieldName === entityDataField?.fieldName)

    const showIsCalculationAsTicked = isCalculation && !isHardcodedField && dataPrimitiveType !== DataPrimitiveTypeEnum.BOOLEAN && exists(dataPrimitiveType)

    const getLookupValues = () => {
        if (dataType !== DataTypeEnum.LOOKUP) return []

        const lookup = lookups.find(lookup => lookup.reference === lookupReference)
        if (lookup === undefined) return []

        return lookup.entries.map(entry => ({ label: entry.name, value: entry.reference }))
    }

    const setDefaultValueForDate = (value: string) =>
        setDefaultValue(
            moment(Number(value))
                .tz(timeZone || DEFAULT_TIMEZONE)
                .format(DEFAULT_VALUE_DATE_FORMAT)
        )

    const renderDefaultValueInput = () => {
        switch (dataType) {
            case undefined:
                return <span className="text-white"> Please select a data and display type first</span>
            case DataTypeEnum.RICH_TEXT:
            case DataTypeEnum.TEXT:
            case DataTypeEnum.EMAIL:
            case DataTypeEnum.PHONE_NUMBER:
                return <TextInfoElement className="w-100" value={defaultValue ?? ""} fieldName={displayName} disabled={false} onChange={setDefaultValue} />
            case DataTypeEnum.DATE:
                return (
                    <DateInfoElement
                        className="w-100"
                        value={moment
                            .tz(defaultValue, timeZone || DEFAULT_TIMEZONE)
                            .valueOf()
                            .toString()}
                        dateFormat={DEFAULT_VALUE_DATE_FORMAT}
                        timezone={timeZone || DEFAULT_TIMEZONE}
                        disabled={false}
                        onChange={setDefaultValueForDate}
                        ariaLabel="default-date-input"
                    />
                )
            case DataTypeEnum.MONEY:
                return (
                    <MoneyInfoElement
                        className="d-flex w-100"
                        value={defaultValue ?? ""}
                        fieldName={displayName}
                        isEditing={true}
                        currency={currencyCode}
                        disabled={false}
                        onChange={setDefaultValue}
                    />
                )
            case DataTypeEnum.INT:
            case DataTypeEnum.DECIMAL:
                return <NumberInfoElement className="w-100" value={defaultValue ?? ""} fieldName={displayName} disabled={false} onChange={setDefaultValue} />
            case DataTypeEnum.BOOLEAN:
                return (
                    <div className="d-flex justify-content-between">
                        <span className="text-white">DEFAULT VALUE:</span>
                        <BooleanInfoElement
                            fieldName={defaultValue ?? ""}
                            status={defaultValue?.toLowerCase() === "true"}
                            disabled={false}
                            onChange={setDefaultValue}
                        />
                    </div>
                )
            case DataTypeEnum.LOOKUP:
                return <LookupInfoElement value={defaultValue ?? ""} lookupValueList={getLookupValues()} disabled={false} onChange={setDefaultValue} />
        }
    }

    return (
        <div className={`d-flex flex-column h-100 bg-blue p-3 ${classes.container} overflow-auto ${className}`} role="form" aria-label="data-field">
            {showHeader && (
                <div className="d-flex align-items-center text-white mb-3">
                    <span className="fs-3 fw-lighter">{isEditing ? "Edit" : "Add"} Data Field</span>
                    {toggleIsShown && (
                        <div className={`d-flex rounded ms-auto pointer ${classes.closeButton}`} onClick={toggleIsShown} role="button">
                            <i className="fa-regular fa-x m-auto"></i>
                        </div>
                    )}
                </div>
            )}
            <div className="h-100 overflow-auto p-1">
                <div className="pe-2">
                    <div className={`d-flex ${isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"} mb-3`}>
                        <span className="text-uppercase mb-1 ms-1 text-white no-select">Field name</span>
                        <span className="text-white ms-1 no-select">{fieldName}</span>
                        {isDuplicateFieldName && (
                            <div className="d-flex mt-2 text-orange">
                                <i className="fal fa-exclamation-circle me-2 align-self-center"></i>
                                <span>A data field already exists with a matching field name</span>
                            </div>
                        )}
                    </div>
                    <div className={`d-flex ${isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"} mb-3`}>
                        <div className="d-flex">
                            <span className="text-uppercase mb-1 ms-1 text-white no-select">Display name</span>
                            <span className="text-light-grey ms-auto fs-6">* Required</span>
                        </div>
                        <Input placeholder="Please enter a display name" value={displayName} onChange={setDisplayName} ariaLabel="displayName" />
                    </div>
                    <div className={`d-flex ${isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"} mb-3`}>
                        <span className="text-uppercase mb-1 ms-1 text-white no-select">Description</span>
                        <Input placeholder="Please enter a description" value={description} onChange={setDescription} ariaLabel="description" />
                    </div>
                    <div className={`d-flex ${isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"} mb-3`}>
                        <div className="d-flex">
                            <span className="text-uppercase mb-1 ms-1 text-white no-select">Data type</span>
                            <span className="text-light-grey ms-auto fs-6">* Required</span>
                        </div>
                        <Dropdown
                            options={DataPrimitiveTypeDropdownOptions}
                            onOptionSelected={onDataPrimitiveTypeUpdated}
                            selectedOption={selectedDataPrimitiveType}
                            textAlign="left"
                            ariaLabel="data-primitive-type"
                            disabled={isEditing && entityDataField?.fieldName !== undefined && entityDataField?.fieldName !== ""}
                        />
                    </div>
                    <div className={`d-flex ${isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"} mb-3`}>
                        <div className="d-flex justify-content-between">
                            <span className="text-uppercase mb-1 ms-1 text-white no-select">Track Changes</span>
                            <Toggle status={trackChanges} onStatusChanged={toggleTrackChanges} ariaLabel="track-field-updates" />
                        </div>
                    </div>
                    {(dataPrimitiveType === DataPrimitiveTypeEnum.TEXT || dataPrimitiveType === DataPrimitiveTypeEnum.NUMBER) && (
                        <div className={`d-flex ${isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"} mb-3`}>
                            <div className="d-flex">
                                <span className="text-uppercase mb-1 ms-1 text-white no-select">Display type</span>
                                <span className="text-light-grey ms-auto fs-6">* Required</span>
                            </div>
                            <Dropdown
                                options={availableDataTypeDropdownOptions}
                                onOptionSelected={onDataTypeUpdated}
                                selectedOption={selectedDataType}
                                textAlign="left"
                                ariaLabel="data-type"
                            />
                        </div>
                    )}
                    {dataType === DataTypeEnum.LOOKUP && (
                        <div className={`d-flex ${isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"} mb-3`}>
                            <div className="d-flex">
                                <span className="text-uppercase mb-1 ms-1 text-white no-select">Lookup field</span>
                                <span className="text-light-grey ms-auto fs-6">* Required</span>
                            </div>
                            <Dropdown
                                options={lookupDropdownOptions}
                                onOptionSelected={onLookupReferenceUpdated}
                                selectedOption={selectedLookupReference}
                                textAlign="left"
                                ariaLabel="lookup"
                            />
                        </div>
                    )}
                    {dataType === DataTypeEnum.DATE && (
                        <div className="d-flex flex-column">
                            <div className={`d-flex ${isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"} mb-3`}>
                                <div className="d-flex">
                                    <span className="text-uppercase mb-1 ms-1 text-white no-select">Time Zone</span>
                                    <span className="text-light-grey ms-auto fs-6">* Required</span>
                                </div>
                                <AutoCompleteDropdown
                                    options={timeZoneDropdownOptions}
                                    onOptionSelected={onTimeZoneUpdated}
                                    selectedOption={selectedTimeZone}
                                    ariaLabel="time-zone"
                                />
                            </div>
                            <div className={`d-flex ${isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"} mb-3`}>
                                <div className="d-flex justify-content-between">
                                    <span className="text-uppercase mb-1 ms-1 text-white no-select">Format</span>
                                    <span className="text-light-grey fs-6">* Required</span>
                                </div>
                                <Input onChange={setDateTimeFormat} value={dateTimeFormat ?? ""} ariaLabel="date-format" />
                            </div>
                        </div>
                    )}
                    {dataType === DataTypeEnum.MONEY && (
                        <>
                            <div className="d-flex mb-2">
                                <span className="ms-1 me-2 text-white text-uppercase align-items-center">Use consistent currency code</span>
                                <Toggle status={useCurrencyCode} onStatusChanged={toggleUseCurrencyCode} ariaLabel="use-consistent-currency-code" />
                            </div>
                            {useCurrencyCode ? (
                                <div className={`d-flex ${isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"} mb-3`}>
                                    <div className="d-flex">
                                        <span className="text-uppercase mb-1 ms-1 text-white no-select">Currency Code</span>
                                        <span className="text-light-grey ms-auto fs-6">* Required</span>
                                    </div>
                                    <AutoCompleteDropdown
                                        options={MoneyDataTypeOptionDropdownOptions}
                                        onOptionSelected={onCurrencyCodeUpdated}
                                        selectedOption={selectedCurrencyCode}
                                        ariaLabel="currency-code"
                                        key="currency-code"
                                    />
                                </div>
                            ) : (
                                <div className={`d-flex ${isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"} mb-3`}>
                                    <div className="d-flex">
                                        <span className="text-uppercase mb-1 ms-1 text-white no-select">Currency Field</span>
                                        <span className="text-light-grey ms-auto fs-6">* Required</span>
                                    </div>
                                    <AutoCompleteDropdown
                                        options={currencyFieldDropdownOptions}
                                        onOptionSelected={onCurrencyFieldRefUpdated}
                                        selectedOption={selectedCurrencyFieldRef}
                                        ariaLabel="currency-field-ref"
                                        key="currency-field-ref"
                                    />
                                </div>
                            )}
                        </>
                    )}
                    <div className="d-flex mb-3">
                        <Checkbox
                            className="ms-1 w-100"
                            isChecked={isQuickFilter}
                            label="Is quick filter"
                            onClick={toggleIsQuickFilter}
                            colour="blue"
                            checkboxClasses="bg-white ms-auto"
                            positionLabelLeft={true}
                            labelColour="white"
                        />
                    </div>
                    <hr />
                    <div className="d-flex mb-3">
                        <Checkbox
                            className="ms-1 w-100"
                            isChecked={showIsCalculationAsTicked}
                            label="Is calculated field"
                            onClick={toggleIsCalculation}
                            colour="blue"
                            checkboxClasses="bg-white ms-auto"
                            positionLabelLeft={true}
                            labelColour="white"
                            disabled={isHardcodedField || dataPrimitiveType === DataPrimitiveTypeEnum.BOOLEAN || !exists(dataPrimitiveType)}
                        />
                    </div>
                    <hr />
                    <div className={`d-flex ${isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"} mb-3`}>
                        <div className="d-flex justify-content-between">
                            <span className="text-uppercase mb-1 ms-1 text-white no-select">Has default value</span>
                            <Toggle status={hasDefaultValue} onStatusChanged={toggleDefaultValue} ariaLabel="has-default-value" />
                        </div>
                    </div>
                    {hasDefaultValue && <div>{renderDefaultValueInput()}</div>}
                    {isCalculation && !isHardcodedField && dataPrimitiveType !== DataPrimitiveTypeEnum.BOOLEAN && exists(dataPrimitiveType) && (
                        <>
                            <div className={`d-flex ${isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"} mb-3`}>
                                <div className="d-flex">
                                    <span
                                        aria-label="calculation-type-title"
                                        className="text-uppercase mb-1 ms-1 text-white no-select"
                                        title={
                                            isPartOfIntraCalculation
                                                ? "Field is part of a formula in another field. Calculation Type: Formula is disabled."
                                                : ""
                                        }
                                    >
                                        Calculation Type {isPartOfIntraCalculation ? <i className="fa-exclamation" /> : <></>}
                                    </span>
                                </div>
                                <Dropdown
                                    options={calculationTypeDropdownOptions}
                                    onOptionSelected={onCalculationTypeUpdated}
                                    selectedOption={calculationTypeDropdownOptions.find(option => option.value === calculationType)}
                                    textAlign="left"
                                    ariaLabel="calculation-type"
                                    disabled={calculationTypeDropdownOptions.length <= 1}
                                />
                            </div>
                            {calculationType === "calculation" && calculationTypeDropdownOptions.find(option => option.value === "calculation") && (
                                <IntraEntityCalculationConfigForm
                                    entityConfig={entityConfig}
                                    intraEntityCalculation={intraEntityCalculation ?? { baseField: "", operations: [] }}
                                    onIntraEntityCalculationUpdated={setIntraEntityCalculation}
                                    selectedEntityDataFieldName={fieldName}
                                />
                            )}
                            {calculationType === "concatenation" && calculationTypeDropdownOptions.find(option => option.value === "concatenation") && (
                                <IntraEntityConcatenationConfigForm
                                    entityConfig={entityConfig}
                                    intraEntityConcatenation={intraEntityConcatenation ?? []}
                                    onIntraEntityConcatenationUpdated={setIntraEntityConcatenation}
                                />
                            )}
                            {calculationType === CalculationTypes.aggregation &&
                                calculationTypeDropdownOptions.find(option => option.value === CalculationTypes.aggregation) && (
                                    <>
                                        <div className={`d-flex ${isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"} mb-3`}>
                                            <div className="d-flex">
                                                <span className="text-uppercase mb-1 ms-1 text-white no-select">Aggregation Type</span>
                                                <span className="text-light-grey ms-auto fs-6">* Required</span>
                                            </div>
                                            <Dropdown
                                                options={getAggregationTypeOptions()}
                                                onOptionSelected={onAggregationTypeUpdated}
                                                selectedOption={getAggregationTypeOptions().find(option => option.value === aggregationType)}
                                                textAlign="left"
                                                ariaLabel="aggregation-type"
                                            />
                                        </div>
                                        <div className={`d-flex ${isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"} mb-3`}>
                                            <div className="d-flex">
                                                <span className="text-uppercase mb-1 ms-1 text-white no-select">Child Entity</span>
                                                <span className="text-light-grey ms-auto fs-6">* Required</span>
                                            </div>
                                            <Dropdown
                                                options={aggregationChildEntityOptions}
                                                onOptionSelected={onAggregationChildEntityReferenceUpdated}
                                                selectedOption={aggregationChildEntityOptions.find(option => option.value === aggregationChildEntityReference)}
                                                textAlign="left"
                                                ariaLabel="aggregation-child-entity"
                                            />
                                        </div>
                                        {aggregationType !== AggregationType.COUNT && (
                                            <div
                                                className={`d-flex ${
                                                    isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"
                                                } mb-3 pe-1`}
                                            >
                                                <div className="d-flex">
                                                    <span className="text-uppercase mb-1 ms-1 text-white no-select">Child Field to Aggregate</span>
                                                    <span className="text-light-grey ms-auto fs-6">* Required</span>
                                                </div>
                                                <EntityDataFieldDropdown
                                                    options={aggregationGroupingFieldOptions}
                                                    onOptionSelected={onAggregationGroupingFieldUpdated}
                                                    selectedOption={aggregationGroupingFieldOptions.find(
                                                        option => option.fieldName === aggregationGroupingField
                                                    )}
                                                    ariaLabel="aggregation-grouping-field"
                                                />
                                            </div>
                                        )}
                                        {aggregationFilterFieldOptions.length > 0 && (
                                            <>
                                                <hr />
                                                <div
                                                    className={`d-flex ${
                                                        isCompact ? "flex-row justify-content-between align-items-center" : "flex-column"
                                                    } m-2`}
                                                    aria-label="aggregation-filters"
                                                >
                                                    <span className="fs-3 text-white">Aggregation Filters</span>
                                                    <FilterList
                                                        fields={getDataFieldsFromEntityDataFields(aggregationFilterFieldOptions)}
                                                        lookups={lookups}
                                                        appliedFilters={aggregationFilters}
                                                        onFiltersApplied={setAggregationFilters}
                                                        autoApply={true}
                                                        isCompact={true}
                                                        colour="blue"
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                        </>
                    )}
                </div>
            </div>
            <div className="d-flex mt-auto justify-content-between pt-4">
                {toggleIsShown && <GreyButton iconClasses="fal fa-times" label="Cancel" onClick={toggleIsShown} disabled={false} />}
                <GreyButton iconClasses="fal fa-check" label="Confirm" onClick={saveField} disabled={!isDataFieldValid()} ariaLabel="confirm-data-field" />
            </div>
        </div>
    )
}

export default EntityConfigDataFieldForm

const aggregateToNumberOptions = [
    {
        label: "Max",
        value: AggregationType.MAX
    },
    {
        label: "Min",
        value: AggregationType.MIN
    },
    {
        label: "Average",
        value: AggregationType.AVERAGE
    },
    {
        label: "Sum",
        value: AggregationType.SUM
    },
    {
        label: "Count",
        value: AggregationType.COUNT
    },
    {
        label: "Cardinality",
        value: AggregationType.CARDINALITY
    }
]

const aggregateToDateOptions = [
    {
        label: "Max",
        value: AggregationType.MAX
    },
    {
        label: "Min",
        value: AggregationType.MIN
    }
]

const DEFAULT_VALUE_DATE_FORMAT = "YYYY-MM-DD"
