import StandardButton from "../../../library/buttons/StandardButton/StandardButton"
import LanguageDropdown from "./LanguageDropdown/LangaugeDropdown"
import classes from "./Onboarding.mobile.module.scss"

const OnboardingMobile = ({ onSignUpClick }) => {
    return (
        <div className="d-flex w-100 h-100 flex-column">
            <div className={`d-flex flex-column text-white ${classes.header} pb-3`}>
                <div className={`${classes.logo}`}>
                    <img src="/Invevo_Logo_White.png" alt="Invevo Logo" />
                </div>
                <span className="fs-5 px-3">Sign up now to view your detailed credit profile</span>
            </div>
            <div className="d-flex bg-grey p-2 shadow">
                <LanguageDropdown className="ms-auto" />
            </div>
            <div className={`d-flex flex-grow-1 p-3 ${classes.body}`}>
                <div className="d-flex flex-column bg-white align-self-start p-3 rounded shadow">
                    <span className="text-uppercase text-purple fs-4">Credit Reports</span>
                    <div className="d-flex">
                        <i className="fa-thin fa-file-certificate text-blue mt-2 me-3 fa-5x"></i>
                        <span>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </span>
                    </div>
                </div>
            </div>
            <div className="bg-white p-3">
                <StandardButton label="Get started using Invevo" iconClasses="fal fa-right-to-bracket" onClick={onSignUpClick} />
            </div>
        </div>
    )
}

export default OnboardingMobile
