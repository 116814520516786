import moment from "moment"

const Comment = ({comment, textColour}) => {
    return (
        <div className={`d-flex flex-column border p-3 text-${textColour}`}>
            <div className="d-flex align-items-center">
                <i className="fs-2 fal fa-user-circle" />
                <div className="d-flex flex-column ms-3">
                    <strong>{comment.createdBy}</strong>
                    <span>{moment(comment.createdDateTime).format("DD-MM-YYYY HH:mm")}</span>
                </div>
            </div>
            <div className="mt-2 w-100 word-wrap">
                {comment.text}
            </div>
        </div>
    )
}

export default Comment