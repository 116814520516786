import * as Sentry from "@sentry/react"
import { Loading, useClient, useUser } from "invevo-react-components"
import { useMemo, useReducer, useState } from "react"
import { Redirect, Route, useLocation } from "react-router-dom"
import { useLayout } from "../../contexts/LayoutContext"
import { VoipProvider } from "../../contexts/VoipContext"
import { useFeatureToggle } from "../../hooks/useFeatureToggle"
import { useIsMobile } from "../../hooks/useIsMobile"
import ConnectRoutes from "../../microfrontends/connect/components/ConnectRoutes"
import PaymentResult from "../../microfrontends/pay/components/PaymentResult"
import homeReducer from "../../reducers/homeReducer"
import FlowSculptor from "../../routes/businessFlow/components/FlowSculptor"
import EntityConfigDashboard from "../../routes/entityConfig/components/EntityConfigDashboard"
import EntitySearch from "../../routes/entitySearch/components/EntitySearch"
import GuestDashboard from "../../routes/guest/components/GuestDashboard"
import TotalProcessingPaymentComplete from "../../routes/guest/components/payments/TotalProcessingPaymentComplete"
import IntegrationRoutes from "../../routes/integration/components/IntegrationRoutes"
import CustomerSearch from "../../routes/search/components/CustomerSearch"
import NewUserProfile from "../../routes/userProfile/components/UserProfile"
import Roles from "../../routes/userRoles/roles/components/Roles"
import UserRolesRoutes from "../../routes/userRoles/UserRolesRoutes"
import ClientUsers from "../../routes/userRoles/users/components/ClientUsers"
import ErrorBoundary from "../ErrorBoundary"
import UserGroups from "../userGroups/UserGroups"
import Automate from "./automate/AutomateWrapper"
import Chat from "./chat/Chat"
import Customer from "./customer/Customer"
import Dashboards from "./dashboards/Dashboards"
import ViewDashboards from "./dashboards/ViewDashboards"
import classes from "./Home.module.scss"
import LegacyDataFields from "./integration/data/DataFields"
import Menu from "./menu/Menu"
import MobileMenu from "./menu/Menu.mobile"
import MyTasks from "./myTasks/MyTasks"
import Reports from "./reports/Reports"
import Search from "./search/Search"
import SettingsRoutes from "./SettingsRoutes"
import SystemSettings from "./systemSettings/SystemSettings"
import UnreadEmails from "./unreadEmails/UnreadEmails"
import UserProfile from "./userProfile/UserProfile"

const Home = ({ store }) => {
    const [state, dispatch] = useReducer(homeReducer, {
        activeMenuItem: ""
    })

    const location = useLocation()

    // This is a hacky way to get EntitySearchList to go to the next entity (with useEffect)
    // (If this is zero, it will not go to the next entity)
    const [nextEntity, setNextEntity] = useState(0)

    const { search, pathname } = useLocation()
    const { isLeftPanelExpanded, isCustomerPanelExpanded, isActionPanelExpanded } = useLayout()

    const user = useUser()
    const client = useClient()
    const isMobile = useIsMobile()

    const isGuestUser = useMemo(() => {
        return client === "creditcircle" && !user.permissions
    }, [user, client])

    const { isEnabled: isChatEnabled } = useFeatureToggle("webChat")
    const { isEnabled: isTeamPerfDashEnabled } = useFeatureToggle("teamPerformanceDashboard")
    const { isEnabled: isNewCustomerSearchEnabled } = useFeatureToggle("newCustomerSearch")
    const { isEnabled: isNewEntityStructureEnabled } = useFeatureToggle("newEntityStructure")
    const { isEnabled: isBusinessFlowEnabled } = useFeatureToggle("businessFlowsFeature")
    const { isEnabled: isHideOldSearchEnabled } = useFeatureToggle("hideOldSearch")

    Sentry.setTags({
        client
    })

    Sentry.setUser({
        username: user.username
    })

    const searchParams = new URLSearchParams(location.search)

    if (searchParams.has("firstLoad")) {
        searchParams.delete("firstLoad")

        if (!isHideOldSearchEnabled && isNewEntityStructureEnabled) {
            return <Redirect to={{ pathname: `${PARENT_ROUTE}/entity-search`, search: `${searchParams.toString()}`}} />
        }
        else {
            window.history.replaceState({}, "", `${location.pathname}?${searchParams.toString()}`)
        }
    }

    const shouldShowCustomer = () => {
        return (
            !isGuestUser &&
            (pathname === `${PARENT_ROUTE}/search` ||
                pathname === `${PARENT_ROUTE}/tasks` ||
                pathname === `${PARENT_ROUTE}/unread-emails` ||
                pathname === `${PARENT_ROUTE}/new-search` ||
                pathname === `${PARENT_ROUTE}/entity-search`)
        )
    }

    const shouldHideOverflow = () => {
        return (
            (pathname === `${PARENT_ROUTE}/new-search` && isLeftPanelExpanded) ||
            (pathname === `${PARENT_ROUTE}/entity-search` && isLeftPanelExpanded) ||
            pathname === `${PARENT_ROUTE}/automate` ||
            pathname === `${PARENT_ROUTE}/entity-configs` ||
            pathname === `${PARENT_ROUTE}/flow-sculptor`
        )
    }

    const goToNextEntity = (reset = false) => {
        if (reset) {
            setNextEntity(0)
        } else {
            setNextEntity(nextEntity + 1)
        }
    }

    return (
        <VoipProvider>
            <div className={`w-100 h-100 d-flex ${isGuestUser && isMobile ? "flex-column" : ""}`}>
                <Loading isLoading={!user.permissions && !isGuestUser} colour="blue">
                    {isMobile && isGuestUser ? (
                        <MobileMenu state={state} isGuestUser={isGuestUser} dispatch={dispatch} />
                    ) : (
                        <Menu state={state} isGuestUser={isGuestUser} dispatch={dispatch} />
                    )}
                    <div
                        className={`d-flex ${shouldShowCustomer() && !isLeftPanelExpanded ? "" : "flex-grow-1"} ${
                            shouldHideOverflow() ? "overflow-hidden" : ""
                        } h-100`}
                    >
                        <Route
                            path={PARENT_ROUTE}
                            render={({ match: { url } }) => (
                                <>
                                    {!isGuestUser && isTeamPerfDashEnabled && (
                                        <Route
                                            path={`${url}/view-dashboards`}
                                            exact
                                            render={() => (
                                                <ErrorBoundary>
                                                    <ViewDashboards />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!isGuestUser && (
                                        <Route
                                            path={`${url}/search`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    {isHideOldSearchEnabled ? (
                                                        isNewEntityStructureEnabled ? (
                                                            <Redirect to={`${url}/entity-search${search}`} />
                                                        ) : (
                                                            <Redirect to={`${url}/new-search${search}`} />
                                                        )
                                                    ) : (
                                                        <Search
                                                            store={store}
                                                            className={isActionPanelExpanded || isCustomerPanelExpanded ? classes.hide : ""}
                                                        />
                                                    )}
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!isGuestUser && isNewCustomerSearchEnabled && (
                                        <Route
                                            path={`${url}/new-search`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <CustomerSearch className={isActionPanelExpanded || isCustomerPanelExpanded ? classes.hide : ""} />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!isGuestUser && isNewEntityStructureEnabled && (
                                        <Route
                                            path={`${url}/entity-search`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <EntitySearch
                                                        className={isActionPanelExpanded || isCustomerPanelExpanded ? classes.hide : ""}
                                                        nextEntity={nextEntity}
                                                        goToNextEntity={goToNextEntity}
                                                    />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!isGuestUser && (
                                        <Route
                                            path={`${url}/tasks`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <MyTasks store={store} className={isActionPanelExpanded || isCustomerPanelExpanded ? classes.hide : ""} />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!isGuestUser && (
                                        <Route
                                            path={`${url}/unread-emails`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <UnreadEmails
                                                        store={store}
                                                        className={isActionPanelExpanded || isCustomerPanelExpanded ? classes.hide : ""}
                                                    />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!isGuestUser && (
                                        <Route
                                            path={`${url}/reporting`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <Reports store={store} />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}

                                    {!isGuestUser && user.permissions && (user.permissions.hasAdminPermission || user.permissions.hasDashboardPermission) && (
                                        <Route
                                            path={`${url}/dashboards`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <Dashboards />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!isGuestUser && user.permissions && (user.permissions.hasAdminPermission || user.permissions.hasAutomatePermission) && (
                                        <Route
                                            path={`${url}/automate`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <Automate />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!isGuestUser && user.permissions && user.permissions.hasAdminPermission && isBusinessFlowEnabled && (
                                        <Route
                                            path={`${url}/flow-sculptor`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <FlowSculptor />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!isGuestUser && user.permissions && (user.permissions.hasAdminPermission || user.permissions.hasDataConfigPermission) && (
                                        <Route
                                            path={`${url}/data-fields`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <LegacyDataFields />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!isGuestUser &&
                                        isNewEntityStructureEnabled &&
                                        user.permissions &&
                                        (user.permissions.hasAdminPermission || user.permissions.hasDataConfigPermission) && (
                                            <Route
                                                path={`${url}/entity-configs`}
                                                render={() => (
                                                    <ErrorBoundary>
                                                        <EntityConfigDashboard />
                                                    </ErrorBoundary>
                                                )}
                                            />
                                        )}
                                    {!isGuestUser && (
                                        <Route
                                            path={`${url}/settings`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <SettingsRoutes />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!isGuestUser && user.permissions && (user.permissions.hasAdminPermission || user.permissions.hasIntegrationPermission) && (
                                        <Route
                                            path={`${url}/integration`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <IntegrationRoutes />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}

                                    {!isGuestUser && user.permissions && (user.permissions.hasAdminPermission || user.permissions.hasConnectPermission) && (
                                        <Route
                                            path={`${url}/connect`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <ConnectRoutes />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!isGuestUser && (
                                        <Route
                                            path={`${url}/system-settings`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <SystemSettings store={store} />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!isGuestUser && user.permissions && (user.permissions.hasAdminPermission || user.permissions.hasUserPermission) && (
                                        <Route
                                            path={`${url}/users`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <UserGroups />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!isGuestUser && (
                                        <Route
                                            path={`${url}/user-roles`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <UserRolesRoutes />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!isGuestUser && user.permissions && (user.permissions.hasAdminPermission || user.permissions.hasUserPermission) && (
                                        <Route
                                            path={`${url}/roles`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <Roles />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!isGuestUser && user.permissions && (user.permissions.hasAdminPermission || user.permissions.hasUserPermission) && (
                                        <Route
                                            path={`${url}/new-users`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <ClientUsers />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}

                                    <Route path={`${url}/ppres/:result?`} component={PaymentResult} />

                                    <Route
                                        path={`${url}/user-profile`}
                                        render={() =>
                                            isGuestUser ? (
                                                <ErrorBoundary>
                                                    <NewUserProfile />
                                                </ErrorBoundary>
                                            ) : (
                                                <ErrorBoundary>
                                                    <UserProfile />
                                                </ErrorBoundary>
                                            )
                                        }
                                    />
                                    <Route
                                        path={`${url}`}
                                        exact
                                        render={() =>
                                            isGuestUser ? (
                                                <GuestDashboard />
                                            ) : (
                                                <ErrorBoundary>
                                                    <Redirect to={`${url}/search${search}`} />
                                                </ErrorBoundary>
                                            )
                                        }
                                    />
                                    {isGuestUser && (
                                        <Route
                                            path={`${url}/payment-complete`}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <TotalProcessingPaymentComplete />
                                                </ErrorBoundary>
                                            )}
                                        />
                                    )}
                                    {!pathname.includes("payment-complete") && isGuestUser && <Redirect from={`${url}/*`} to={`${url}${search}`} />}
                                </>
                            )}
                        />
                    </div>
                    {shouldShowCustomer() && (
                        <div className={`d-flex ${isLeftPanelExpanded ? classes["collapsed-customer"] : "flex-grow-1"} h-100 overflow-hidden`}>
                            <Customer store={store} isNewEntityStructureEnabled={isNewEntityStructureEnabled} goToNextEntity={goToNextEntity} />
                        </div>
                    )}
                    {isChatEnabled && <Chat />}
                </Loading>
            </div>
        </VoipProvider>
    )
}

export const PARENT_ROUTE = "/home"

export default Home
