import { SelectableCard } from 'invevo-react-components'
import { actionTypes } from '../../reducers/actionTypes'
import DueDate from './DueDate'
import classes from './TaskCard.module.scss'

const TaskCard = ({ state, task, dispatch }) => {
    const taskType = state.taskTypes.find(tt => tt.taskTypeReference === task.taskTypeReference)

    const onTaskClick = () => dispatch({ type: actionTypes.TASK_SELECTED, selectedTask: task.id })

    return (
        <SelectableCard
            className={`mx-3 my-1 pointer ${classes["shadow"]}`}
            body={(
                <div>
                    <div className="d-flex mb-1">
                        <span className="user-select-all pe-auto me-auto small" onClick={e => e.stopPropagation()}>#{task.readableId}</span>
                        <h5>{task.customerReference}</h5>
                    </div>
                    <h6>{taskType ? taskType.typeName : "Unknown Task Type"}</h6>
                    <div className="mx-2 my-1">
                        {
                            task?.customerFields && task.customerFields
                                .map(cf => ({
                                    fieldName: state.customerFields.find(f => f.fieldName === cf.fieldName && f.type === cf.fieldType)?.displayValue,
                                    fieldValue: cf.fieldValue
                                }))
                                .filter(cf => cf.fieldName)
                                .map((cf, index) => (
                                    <div key={index} className="d-flex align-items-center justify-content-between my-1">
                                        <span className="small">{cf.fieldName}</span>
                                        <span className="small">{cf.fieldValue}</span>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            )}
            footer={(
                <div className="d-flex justify-content-end mt-2">
                    <DueDate dueDate={task.date} />
                </div>
            )}
            isSelected={false}
            onClick={onTaskClick}
        />
    )
}

export default TaskCard