
import classes from './CustomerActions.module.scss'

const CustomerActions = ({ actions, success = "all", isPreview }) => {

    return (
        actions.length > 0
            ? actions.map((action, index) =>
                <div className={`d-flex flex-column`}>
                    <div
                        key={`action${index}`}
                        className={`${classes.changeingBorder} d-flex flex-column bg-white w-100 px-3`}
                    >
                        <div className="d-flex mt-3">
                            <div className="d-flex">
                                <i className="fal fa-building text-blue me-2 fs-3 pt-1"></i>
                                <p className="pt-1 text-dark-grey fs-5">{action.customerReference}</p>
                            </div>
                            <div className="d-flex ms-auto">
                                <p className={`${classes.disabledText} pt-1`}>Cash to collect:</p>
                                <p className={`${classes.disabledText} pt-1 ms-1`}>{success === "successful" ? '£0,00' : success === "failed" ? '£--' : action.success ? '£0,00' : '£--'}</p>
                                <p className={`${classes.disabledText} pt-1 ms-5`}>Cash collected:</p>
                                <p className={`${classes.disabledText} pt-1 me-5 ms-1`}>{success === "successful" ? '£0,00' : success === "failed" ? '£--' : action.success ? '£0,00' : '£--'}</p>
                                <p className={`${classes.disabledText} pt-1`}>Outstanding:</p>
                                <p className={`${classes.disabledText} pt-1 ms-1`}>{success === "successful" ? '£0,00' : success === "failed" ? '£--' : action.success ? '£0,00' : '£--'}</p>
                            </div>
                        </div>

                        {action.success === "SUCCESS" && <div className={`${classes.coloredBox} mb-3`}></div>}
                        {(action.success === "FAILED" || action.success === "NOT_MATCHED") && <span className={`${classes.warningBox} ps-2 mb-3 fw-bold`}>{action.message}</span>}

                        <div className="d-flex">
                            <div className="d-flex pe-5">
                                <i className="fal fa-mobile text-blue me-1 fs-5"></i>
                                <p className={`${classes.smallText} text-black fw-bold`}>SMS</p>
                            </div>

                            {action.success === "SUCCESS" &&
                                <>
                                    <div className="d-flex pe-5">
                                        <i className="far fa-check text-green me-1 fs-5"></i>
                                        <p className={`${classes.smallText} text-black`}>{action.message}</p>
                                    </div>

                                    {!isPreview &&
                                        <div className="d-flex pe-5">
                                            <i className="far fa-align-justify me-1 fs-5"></i>
                                            <p className={`${classes.smallText} text-black`}>{action.template}</p>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            )
            : <div className={"d-flex align-items-center no-select"}>
                <i className="fal fa-exclamation-triangle text-warning me-2 fs-4"></i>
                <span className="fs-5 me-2">{`No ${success === "all" ? '' : success === "successful" ? 'successful' : success === "failed" ? 'failed' : ''} actions.`}</span>
            </div>
    )
}

export default CustomerActions