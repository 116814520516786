import XmlExportFieldConfig from "./XmlExportFieldConfig"
import { v4 as uuidv4 } from "uuid"
import { StandardButton } from "invevo-react-components"
import {
    FileExportConfigAction,
    FileExportConfigActionEnum,
    XmlEntityExportNodeState,
    XmlEntityExportParentState
} from "../../../../reducers/FileEntityExportConfigReducer"
import { XmlEntityExportNodeEnum } from "../../../../types/fileEntityExport/FileExportConfig"
import { useState } from "react"
import EntityDataField from "../../../../../entityConfig/types/EntityDataField"

type XmlExportFieldConfigsProps = {
    root: XmlEntityExportParentState
    dispatch: React.Dispatch<FileExportConfigAction>
    dataFields: EntityDataField[]
}

const XmlExportFieldConfigs = ({ root, dispatch, dataFields }: XmlExportFieldConfigsProps) => {
    const [editingNode, setEditingNode] = useState<XmlEntityExportNodeState | undefined>(undefined)

    const onChanged = (changedNode: XmlEntityExportNodeState) => {
        dispatch({ type: FileExportConfigActionEnum.XML_NODE_CHANGED, nodeUpdated: changedNode })
    }

    const onDeleted = (changedNode: XmlEntityExportNodeState) => {
        dispatch({ type: FileExportConfigActionEnum.XML_NODE_DELETED, nodeId: changedNode.key, parentId: changedNode.parentKey })
    }

    const onEditingNodeChanged = (changedNode: XmlEntityExportNodeState) => setEditingNode(changedNode)

    const saveEditingConfig = () => {
        if (!editingNode) {
            return
        }
        onChanged(editingNode)
        setEditingNode(undefined)
    }
    return (
        <div className={"d-flex flex-column mt-4"}>
            <div className="d-flex align-items-center">
                <span className="text-grey fs-3">{`Xml nodes`}</span>
                <StandardButton
                    className="ms-auto"
                    label="Add new xml node"
                    colour="white"
                    iconClasses="fal fa-plus"
                    onClick={() => {
                        dispatch({
                            type: FileExportConfigActionEnum.XML_NODE_CHANGED,
                            nodeUpdated: {
                                ...root,
                                children: [
                                    ...root.children,
                                    {
                                        name: "New xml node",
                                        key: uuidv4(),
                                        parentKey: root.key,
                                        type: XmlEntityExportNodeEnum.PARENT,
                                        children: [],
                                        selected: true
                                    }
                                ]
                            }
                        })
                    }}
                    ariaLabel="Add new xml node"
                />
            </div>
            <div className={`d-flex flex-column mt-3`}>
                <div className={"d-flex flex-column"}>
                    {root.children.map((child: XmlEntityExportNodeState) => (
                        <XmlExportFieldConfig
                            key={child.key}
                            state={{ root: child, dataFields, editingNode, onEditingNodeChanged, onChanged, onDeleted, saveEditingConfig }}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default XmlExportFieldConfigs
