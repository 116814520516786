import { Loading, useConfig } from "invevo-react-components"
import useApiQuery from "../../../hooks/useApiQuery"
import StandardButton from "../../../library/buttons/StandardButton/StandardButton"
import { PaymentInitiatedDto } from "../types/dtos/PaymentInitiatedDto"
import MakeTotalProcessingPayment from "./payments/MakeTotalProcessingPayment"

const SetUpSubscription = () => {
    const config = useConfig()

    const createPayment = useApiQuery<PaymentInitiatedDto>({
        url: `${config.CREDIT_CIRCLE_API_URL}/api/recurring-payment`,
        method: "POST",
        isExecutedAutomatically: false
    })

    const initiatePayment = () => {
        createPayment.execute(undefined)
    }

    return (
        <div className="d-flex bg-white rounded shadow p-4">
            {createPayment.hasErrored || (!createPayment.hasFetched && !createPayment.isFetching) ? (
                <StandardButton label="Activate your subscription now" iconClasses="fal fa-right-to-bracket" onClick={initiatePayment} />
            ) : (
                <Loading isLoading={createPayment.isFetching} colour="blue">
                    {
                        createPayment.data ? (
                            <MakeTotalProcessingPayment
                                checkoutId={createPayment.data.totalProcessingCheckoutId}
                                providerUrl={createPayment.data.totalProcessingUrl}
                                redirectUrl={createPayment.data.redirectUrl}
                            />
                        ) : (
                            <span>Something went wrong</span>
                        ) //TODO handle if this fails.
                    }
                </Loading>
            )}
        </div>
    )
}

export default SetUpSubscription
