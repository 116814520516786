import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find, orderBy, filter, split, sortBy } from 'lodash';
import Formsy from 'formsy-react';

import ValidatingInput from '../../common/ValidatingInput';
import Dropdown from '../../common/dropdown';
import GenericInput from '../../common/genericInput';
import CreatableInputOnly from '../../common/createableInputOnly';
import { hasPermission } from '../../../utility/authZ';
import { authZ_Permissions } from '../../../constants';

class RuleConditionForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.onUpdate = this.onUpdate.bind(this);
    this.change = this.change.bind(this);
    this.onChange = this.onChange.bind(this);
    this.enableSave = this.enableSave.bind(this);
    this.disableSave = this.disableSave.bind(this);

    this.state = RuleConditionForm.defaultFormState();
  }

  static defaultFormState() {
    return {
      operations: [
        {
          id: 'GreaterThanOrEqual',
          name: 'Greater than or equal',
          numeric: true
        },
        {
          id: 'LessThanOrEqual',
          name: 'Less than or equal',
          numeric: true
        },
        {
          id: 'GreaterThan',
          name: 'Greater than',
          numeric: true
        },
        {
          id: 'LessThan',
          name: 'Less than',
          numeric: true
        },
        {
          id: 'Equal',
          name: 'Equal',
          numeric: false
        },
        {
          id: 'NotEqual',
          name: 'Not equal',
          numeric: false
        },
        {
          id: 'In',
          name: 'In',
          numeric: false
        },
        {
          id: 'NotIn',
          name: 'Not in',
          numeric: false
        }
      ],
      ruleCondition: {},
      editedValue: {}
    };
  }

  componentDidMount() {
    this.setState({
      ruleCondition: this.props.ruleCondition,
      editedValue: {
        id: this.props.ruleCondition.targetValue,
        description: this.props.ruleCondition.targetValueDisplay
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ruleCondition.name !== this.props.ruleCondition.name)
      this.setState({
        ruleCondition: this.props.ruleCondition,
        editedValue: {
          id: this.props.ruleCondition.targetValue,
          description: this.props.ruleCondition.targetValueDisplay
        }
      });
  }

  enableSave() {
    this.setState({ canSave: true });
  }

  disableSave() {
    this.setState({ canSave: false });
  }

  saveEnabled() {
    return (
      this.state.canSave &&
      this.state.ruleCondition.memberName &&
      this.state.ruleCondition.operation
    );
  }

  onUpdate() {
    this.props.onUpdate({
      ...this.state.ruleCondition,
      targetValue: this.state.editedValue.id,
      targetValueDisplay: this.state.editedValue.description
    });
  }

  onChange(event) {
    const field = event.target.name;
    const value = event.target.value;

    const ruleCondition = {
      ...this.state.ruleCondition,
      [field]: value
    };

    return this.setState({ ruleCondition });
  }

  change(value) {
    const ruleMember = find(
      this.props.ruleMembers,
      (f) => f.name === this.state.ruleCondition.memberName
    );

    if (value && Array.isArray(value)) {
      if (ruleMember.list) {
        const joinedVal = value.map((m) => m.value).join();
        const joinedDisplay = value.map((m) => m.label).join();

        this.setState({
          editedValue: { id: joinedVal, description: joinedDisplay }
        });
      } else {
        const joinedVal = value.map((m) => m.label).join();

        this.setState({
          editedValue: { id: joinedVal, description: joinedVal }
        });
      }
    } else if (value && value.value) {
      this.setState({
        editedValue: { id: value.value, description: value.label }
      });
    } else {
      this.setState({ editedValue: { id: value, description: value } });
    }
  }

  getParentValue(parentList) {
    const ruleMember = find(
      this.props.ruleMembers,
      (f) => f.name === this.state.ruleCondition.memberName
    );
    let parentValue = null;

    if (ruleMember) {
      let priorRules;

      if (this.state.ruleCondition.evaluationOrder) {
        priorRules = filter(
          this.props.ruleGroup.rules,
          (f) => f.evaluationOrder < this.state.ruleCondition.evaluationOrder
        );
      } else {
        priorRules = this.props.ruleGroup.rules;
      }

      priorRules = orderBy(priorRules, ['evaluationOrder'], ['desc']);

      priorRules.forEach((m) => {
        if (parentValue == null) {
          const ruledDefinition = find(
            this.props.ruleMembers,
            (f) => f.name === m.memberName
          );

          if (ruledDefinition && ruledDefinition.list === parentList) {
            parentValue = split(m.targetValue, ',');
          }
        }
      });
    }

    return parentValue;
  }

  renderConditionValue(isDisabled) {
    const ruleMember = find(
      this.props.ruleMembers,
      (f) => f.name === this.state.ruleCondition.memberName
    );
    let lookupParameters = null;

    if (ruleMember) {
      if (
        ruleMember.list === 'taskDescription' ||
        ruleMember.list === 'taskCompletion'
      ) {
        const parentValue = this.getParentValue('taskType');

        if (parentValue) {
          lookupParameters = { taskTypeIds: parentValue };
        }
      } else if (ruleMember.list === 'address') {
        const parentValue = this.getParentValue('customer');

        if (parentValue) {
          lookupParameters = { customerIds: parentValue };
        }
      } else if (ruleMember.list === 'branch') {
        const parentValue = this.getParentValue('brand');

        if (parentValue) {
          lookupParameters = { brandInternalIds: parentValue };
        }
      } else if (ruleMember.list === 'transactionSubStatus') {
        const parentValue = this.getParentValue('transactionStatus');

        if (parentValue) {
          lookupParameters = { transactionStatusIds: parentValue };
        }
      }

      const multi =
        this.state.ruleCondition.operation === 'In' ||
        this.state.ruleCondition.operation === 'NotIn';

      if (isDisabled) {
        return (
          <input
            name="values"
            type="text"
            disabled
            className="form-control"
            value={this.props.ruleCondition.targetValueDisplay}
          />
        );
      } else if (!ruleMember.list && multi) {
        return <CreatableInputOnly change={this.change} value={this.state.editedValue.id} />;
      } else {
        return (
          <GenericInput
            type={ruleMember.type}
            value={this.state.editedValue.id}
            change={this.change}
            valueMode={true}
            list={ruleMember.list}
            multi={multi}
            parameters={lookupParameters}
            nullable={true}
          />
        );
      }
    }
  }

  isSelectedMemberNumeric() {
    if (this.state.ruleCondition.memberName) {
      const selectedMember = find(
        this.props.ruleMembers,
        (f) => f.name === this.state.ruleCondition.memberName
      );

      return (
        selectedMember.type === 'number' || selectedMember.type === 'money'
      );
    }

    return false;
  }

  render() {
    let isDisabled = false;
    const header = this.state.ruleCondition.id
      ? `Edit Rule Condition (Id:${this.state.ruleCondition.id})`
      : 'Add Rule Condition';
    const operations = this.isSelectedMemberNumeric()
      ? this.state.operations
      : this.state.operations.filter((f) => !f.numeric);
    let buttons = (
      <div>
        <button
          type="button"
          className="btn btn-default"
          onClick={this.onUpdate}
          disabled={!this.saveEnabled()}
        >
          OK
        </button>
        <button
          type="button"
          className="btn btn-default"
          onClick={this.props.onCancel}
        >
          Cancel
        </button>
      </div>
    );

    const ruleMembers = sortBy(this.props.ruleMembers, (s) => s.name);

    if (
      !hasPermission(
        this.props.permissions,
        authZ_Permissions.RuleGroupManagementAdmin
      )
    ) {
      isDisabled = true;
      buttons = (
        <div>
          <button
            type="button"
            className="btn btn-default"
            onClick={this.props.onCancel}
          >
            Cancel
          </button>
        </div>
      );
    }

    return (
      <div>
        <Formsy
          key="rule-group-form"
          onValid={this.enableSave}
          onInvalid={this.disableSave}
          className="card below-grid-form"
          id="rulegroupform"
        >
          <h3>{header}</h3>
          <br />
          <div>
            <div
              className="row form-group"
              style={{ marginRight: '-15px', marginLeft: '-15px' }}
            >
              <div className="col-md-4">
                <Dropdown
                  title="Member Name"
                  selectedItemId={this.state.ruleCondition.memberName}
                  data={ruleMembers}
                  idProp="name"
                  textProp="name"
                  onClick={(id) =>
                    this.setState((previousState) => ({
                      ...previousState,
                      ruleCondition: {
                        ...previousState.ruleCondition,
                        memberName: id
                      }
                    }))
                  }
                  disabled={isDisabled}
                  required
                />
              </div>
            </div>
            <div
              className="row form-group"
              style={{ marginRight: '-15px', marginLeft: '-15px' }}
            >
              <div className="col-md-4">
                <Dropdown
                  title="Operations"
                  selectedItemId={this.state.ruleCondition.operation}
                  data={operations}
                  idProp="id"
                  textProp="name"
                  onClick={(id) =>
                    this.setState((previousState) => ({
                      ...previousState,
                      ruleCondition: {
                        ...previousState.ruleCondition,
                        operation: id
                      }
                    }))
                  }
                  disabled={isDisabled}
                  required
                />
              </div>
            </div>
            <div
              className="row form-group"
              style={{ marginRight: '-15px', marginLeft: '-15px' }}
            >
              <div className="col-md-4">
                <div className="form-group">
                  <label>Target Value:</label>
                  <br />
                  {this.renderConditionValue(isDisabled)}
                </div>
              </div>
            </div>
            <div
              className="row form-group"
              style={{ marginRight: '-15px', marginLeft: '-15px' }}
            >
              <div className="col-md-4">
                <ValidatingInput
                  name="evaluationOrder"
                  type="number"
                  required
                  placeholder="Evaluation Order"
                  value={this.state.ruleCondition.evaluationOrder}
                  onChange={this.onChange}
                  disabled={isDisabled}
                  validations={{ isNumeric: true, matchRegexp: /^[0-9.]+$/ }}
                  hideLabel={true}
                  validationError={'Enter a positive whole number.'}
                />
              </div>
            </div>
          </div>
          {buttons}
          <br />
          <br />
        </Formsy>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.authReducer.permissions
  };
}

const mapDispatchToEvents = () => ({});

export default connect(mapStateToProps, mapDispatchToEvents)(RuleConditionForm);
