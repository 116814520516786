import classes from './RoleView.module.scss'
import RoleCard from './cards/RoleCard'
import AdminRoleCard from './cards/AdminRoleCard'
import AdminUserCard from './cards/AdminUserCard'
import RoleUserCard from './cards/RoleUserCard'

const RoleView = ({ state, dispatch }) => {
    const rolesToShow = state.roles.filter(f => f.isEnabled)

    return (
        <div className="d-flex">
            {rolesToShow.filter(r => r.isAdmin).length > 0 &&
                rolesToShow.filter(r => r.isAdmin).map(role =>
                    <div key={role.id} className="d-flex flex-column align-items-center px-4">
                        <AdminRoleCard
                            state={{ role, clientUsers: state.clientUsers }}
                            dispatch={dispatch}
                        />
                        <div className={`d-flex flex-column border-top mt-3 w-100 ${classes["user-cards"]}`}>
                            {
                                role.assignedUsers
                                    .map(user =>
                                        <AdminUserCard key={user.clientUserId} className="mt-3" state={user} />
                                    )
                            }
                        </div>
                    </div>
                )
                    .reduce((prev, curr) => [prev, <span key={`${prev}${curr}`} className="border-start border-1" />, curr])
            }
            <span className="border-start border-1" />
            {rolesToShow.filter(r => !r.isAdmin).length > 0 &&
                rolesToShow.filter(r => !r.isAdmin).map(role =>
                    <div key={role.id} className="d-flex flex-column align-items-center px-4" role="group" aria-label={`role-group-${role.name}`}>
                        <RoleCard
                            state={state}
                            dispatch={dispatch}
                            role={role}
                        />
                        <div className="d-flex flex-column border-top mt-3 w-100">
                            {
                                role.assignedUsers
                                    .map(user =>
                                        <RoleUserCard key={user.clientUserId} state={state} dispatch={dispatch} role={role} user={user} />
                                    )
                            }
                        </div>
                    </div>
                )
                    .reduce((prev, curr) => [prev, <span key={`${prev}${curr}`} className="border-start border-1" />, curr])
            }
        </div>
    )
}

export default RoleView