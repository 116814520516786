import { useGrid } from "../Grid"

type Props = {
    classname?: string
}

const GlobalActions = ({ classname = "" }: Props) => {
    const grid = useGrid()
    return <div id={`globalActionHandle-${grid.reference}`} className={classname} />
}

export default GlobalActions
