import React, { useRef } from 'react';
import CallPhoneButton from '../common/buttons/callPhoneButton';
import { getAcronym } from '../../utility/acronym';
import { connect } from 'react-redux';
import Nav from '../../api/navApi';
import { IconButton } from '../common/buttons/iconButton';
import { useLocation } from 'react-router';

const ContactCard = ({ dataItem, isInternal, onClick, onEditClick, navTo }) => {
  const contactsRole = dataItem.CustomerContactType || dataItem.Role;
  const location = useLocation();

  const baseContact = {
    name: dataItem.Name,
    initials: getAcronym(dataItem.Name),
    role: contactsRole
  };

  const mobileContact = {
    ...baseContact,
    phoneNumber: dataItem.Mobile
  };

  const phoneContact = {
    ...baseContact,
    phoneNumber: dataItem.Phone
  };

  const isSMSpage = window.location.href.includes("/sms")
  const disableForNonSMSRecipient = isSMSpage && (!dataItem.SMSRecipient || !dataItem.Mobile)

  const panelClass = disableForNonSMSRecipient
    ? 'result-disabled'
    : dataItem.selected ? 'result-selected' : 'result-default';

  const onEmailClick = () => {
    if (!dataItem.selected) {
      rowRef.current.click();
    }
    if (!location.pathname.includes('email')) {
      navTo.Email();
    }
  };

  const rowRef = useRef(null);
  const handleClick = (e) => onClick({ ...e, dataItem });

  return (
    <td className={panelClass} ref={rowRef} onClick={disableForNonSMSRecipient ? undefined : handleClick}>
      {
        <div className="container-fluid">
          <div className="row equal">
            <div className="col-3 mt-1 mb-2 col-no-gutters">
              <div className="oval">
                <span className="shout">{getAcronym(dataItem.Name)}</span>
              </div>
            </div>
            <div className="col-9 mt-1 mb-2">
              <h4 className="text-uppercase">
                {dataItem.Name}
                {dataItem.DefaultCustomerContact && !isInternal && (
                  <>
                    {' '}
                    <i className='fa-solid fa-star'></i>
                  </>
                )}

                <span className="float-right">
                  {onEditClick && (
                    <IconButton
                      onClick={(e) => onEditClick(e, dataItem)}
                      title="Edit"
                      icon="edit"
                    />
                  )}
                </span>
              </h4>
              <h5 className="text-muted">{contactsRole}</h5>
              <h5>
                <span className="text-muted">{dataItem.Language}</span>
              </h5>
            </div>
            {mobileContact && (
              <div className="col-12 mt-1">
                <CallPhoneButton contact={mobileContact} disabled={disableForNonSMSRecipient} icon="mobile-alt" />
              </div>
            )}
            {phoneContact && (
              <div className="col-12 mt-1">
                <CallPhoneButton contact={phoneContact} disabled={disableForNonSMSRecipient} />
              </div>
            )}
            {dataItem.Email && (
              <div className="col-12 mt-1">
                <button
                  className="btn btn-default w-100 text-left text-truncate"
                  onClick={onEmailClick}
                  disabled={disableForNonSMSRecipient}
                >
                  <i className='fa-solid fa-at me-2'></i>
                  <span className="text-muted">{dataItem.Email}</span>
                </button>
              </div>
            )}
          </div>
        </div>
      }
    </td>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    navTo: {
      Email: () => dispatch(Nav.Workspace.Email.Index())
    }
  };
};

export default connect(null, mapDispatchToProps)(ContactCard);
