import { Dropup, Loading, useApi, useClient, useConfig, useInvevoSession } from "invevo-react-components"
import { useEffect, useState } from "react"
import { PAGE_SIZE_OPTIONS } from "../../../types/pageSizeOptions"
import PageNavButtons from "../widgets/transactionDisplayWidget/PageNavButtons"
import moment from "moment"
import { useCustomer } from "../../../../../contexts/CustomerContext"
import { useFeatureToggle } from "../../../../../hooks/useFeatureToggle"

const TransactionHistory = ({ transactionRef }) => {
    const { isEnabled: useLocalTimeForNotes } = useFeatureToggle("seeNotesInLocalTime")

    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [items, setItems] = useState([])
    const [total, setTotal] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    const [currentPageSize, setCurrentPageSize] = useState({ value: 50, label: 50 })

    const config = useConfig()
    const client = useClient()
    const api = useApi()
    const [customer] = useCustomer()

    const [invevoSession] = useInvevoSession()

    useEffect(() => {
        if (isFetching || !config.DASHBOARD_API_URL || !client || hasFetched) {
            return
        }

        setIsFetching(true)
        api.post(`${config.DASHBOARD_API_URL}/api/${client}/legacy-transaction-notes`, {
            pageIndex: pageIndex + 1,
            pageSize: currentPageSize.value,
            customerId: customer.sqlId,
            transactionRef
        })
            .then(response => {
                setHasFetched(true)
                setIsFetching(false)
                setTotal(response.data.total)
                setItems(
                    response.data.data.map(item => ({
                        ...item,
                        epochTime: moment.utc(item.dateTime).valueOf()
                    }))
                )
            })
            .catch(error => {
                console.error(error)
                setHasFetched(true)
                setIsFetching(false)
            })
    }, [api, client, config.DASHBOARD_API_URL, currentPageSize, customer.sqlId, hasFetched, isFetching, pageIndex, transactionRef])

    useEffect(() => {
        setHasFetched(false)
    }, [pageIndex, currentPageSize, invevoSession?.stateDeltas?.transactions])

    return (
        <div className="d-flex flex-column h-100 bg-grey">
            <div className="d-flex flex-column flex-grow-1 overflow-auto p-3">
                <Loading isLoading={isFetching || !hasFetched} colour="blue">
                    {items.map((item, index) => (
                        <div key={index} className="d-flex border p-3">
                            <div className="d-flex align-items-center w-50">
                                <i className="fs-2 fal fa-user-circle" />
                                <div className="d-flex ms-3">
                                    <strong>{item.userName}</strong>
                                    <span>
                                        {useLocalTimeForNotes
                                            ? moment(item.epochTime).format("DD-MM-YYYY HH:mm")
                                            : moment.utc(item.epochTime).format("DD-MM-YYYY HH:mm")}
                                        {!useLocalTimeForNotes && " UTC"}
                                    </span>
                                </div>
                            </div>
                            <div className="mt-2 w-100 word-wrap">{item.text}</div>
                        </div>
                    ))}
                    {items.length === 0 && <span className="fs-4">There are no items to display</span>}
                </Loading>
            </div>
            <div className="d-flex w-100 border-top p-3">
                <div className="d-flex align-items-center me-auto">
                    <div className="d-flex align-items-center me-4">
                        <Dropup options={PAGE_SIZE_OPTIONS} onOptionSelected={setCurrentPageSize} selectedOption={currentPageSize} />
                        <span className="ms-2">items per page</span>
                    </div>
                    <span className="me-3">
                        {`Showing results: `}
                        <strong>
                            {Math.min(pageIndex * currentPageSize.value + currentPageSize.value, total) === 0 ? 0 : pageIndex * currentPageSize.value + 1}
                        </strong>
                        {` - `}
                        <strong>{Math.min(pageIndex * currentPageSize.value + currentPageSize.value, total)}</strong>
                        {` of `}
                        <strong>{total}</strong>
                    </span>
                    <PageNavButtons
                        onPageIndexUpdated={setPageIndex}
                        pageIndex={pageIndex}
                        totalPageNumber={Math.ceil(total / currentPageSize.value)}
                        disabled={isFetching}
                    />
                </div>
            </div>
        </div>
    )
}

export default TransactionHistory
