export const MenuItems = {
    VIEW_DASHBOARDS: "VIEW_DASHBOARDS",
    SEARCH: "SEARCH",
    NEW_SEARCH: "NEW_SEARCH",
    ENTITY_SEARCH: "ENTITY_SEARCH",
    ENTITY_SEARCH_NEW: "ENTITY_SEARCH_NEW",
    MY_TASKS: "MY_TASKS",
    UNREAD_EMAILS: "UNREAD_EMAILS",
    REPORTING: "REPORTING",
    DASHBOARDS: "DASHBOARDS",
    AUTOMATE: "AUTOMATE",
    FLOW_SCULPTOR: "FLOW_SCULPTOR",
    DATA_FIELDS: "DATA_FIELDS",
    SETTINGS: "SETTINGS",
    USER_PROFILE: "USER_PROFILE",
    ENTITY_CONFIG: "ENTITY_CONFIG"
}
