import classes from "./ImportFileConfig.module.scss"
import { FileImportConfigAction, FileImportConfigActionEnum, FileImportConfigState } from "../../../reducers/FileEntityImportConfigReducer"
import { FileType } from "../../../types/FileImportConfig"
import Input from "../../../../../library/Inputs/Input"
import DataFileImportFileConfigOptions from "./DataFileImportOptions"
import Dropdown from "../../../../../library/dropdowns/Dropdown"
import EntityConfig from "../../../../entityConfig/types/EntityConfig"

const fileFormats = [{ id: "1", value: "CSV", label: "CSV" }]

type FileImportConfigSetupProps = {
    config: FileImportConfigState
    entities: EntityConfig[]
    dispatch: React.Dispatch<FileImportConfigAction>
}

const ImportFileConfig = ({ config, entities, dispatch }: FileImportConfigSetupProps) => {
    const onConfigNameChange = (newName: string) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            dataFileImportConfig: {
                ...config.dataFileImportConfig,
                configName: newName
            }
        })
    }

    const onFileTypeSelected = (option: any) => {
        dispatch({
            type: FileImportConfigActionEnum.FILE_IMPORT_EDITING_CONFIG_UPDATED,
            editingConfig: {
                ...config,
                fileType: option.value,
                dataFileImportConfig: {
                    ...config.dataFileImportConfig,
                    type: FileType.CUSTOMER,
                    entityType: option.value
                }
            }
        })
        dispatch({
            type: FileImportConfigActionEnum.FILE_IMPORT_ENTITY_SELECTED,
            option: option.value
        })
    }

    const onFileFormatSelected = (option: any) => {
        dispatch({
            type: FileImportConfigActionEnum.FILE_IMPORT_EDITING_CONFIG_UPDATED,
            editingConfig: { ...config, fileFormat: option.value }
        })
    }

    const entityFileTypes = entities.map((entity, index) => ({
        id: `${index}`,
        value: entity.reference,
        label: entity.displayName
    }))
    return (
        <div className={`d-flex ${classes.card} mt-2 pt-2 `}>
            <div className="d-flex px-3 pt-3">
                <i className={`fal fa-grip-lines ${classes.icon}`} />
            </div>
            <div className={`${classes.verticalDivider}`} />
            <div className="d-flex flex-column w-100">
                <div className="d-flex my-1 mx-3">
                    <span className="text-grey fs-4">File details</span>
                </div>
                <div className={`${classes.horizontalDivider} mx-2`} />
                <div className="d-flex flex-column">
                    <div className="d-flex p-3">
                        <div className="d-flex flex-column small me-3">
                            <span className="text-uppercase small mb-1 ms-1 text-grey">Import Config Name</span>
                            <Input
                                placeholder="Please enter the import config name"
                                value={config.dataFileImportConfig.configName}
                                onChange={onConfigNameChange}
                            />
                        </div>

                        <div className={`d-flex flex-column pe-3 small`}>
                            <span className="text-uppercase small mb-1 ms-1 text-grey">File type</span>
                            <Dropdown
                                className="me-3"
                                placeholder={"Please select a file type"}
                                selectedOption={entityFileTypes.find(ft => ft.value === config.dataFileImportConfig.entityType)}
                                options={entityFileTypes}
                                onOptionSelected={onFileTypeSelected}
                                ariaLabel="File Type"
                            />
                        </div>
                        <div className={`d-flex flex-column pe-3 small`}>
                            <span className="text-uppercase small mb-1 ms-1 text-grey">Format</span>
                            <Dropdown
                                className="me-3"
                                placeholder={"Please select format"}
                                selectedOption={fileFormats.find(ft => ft.value === config.fileFormat) || fileFormats[0]}
                                options={fileFormats}
                                onOptionSelected={onFileFormatSelected}
                                ariaLabel="File Format"
                            />
                        </div>
                    </div>
                    <DataFileImportFileConfigOptions state={config.dataFileImportConfig} dispatch={dispatch} />
                </div>
            </div>
        </div>
    )
}

export default ImportFileConfig
