import { SelectableCard } from 'invevo-react-components'

const CustomerGroupCard = ({
    state,
    isSelected,
    onClick
}) => {

    const body = (
        <div className="d-flex align-items-center">
            <i className="fal fa-comment-alt-lines text-blue"></i>
            <h5 className="ms-3 mb-0">{state.name}</h5>
        </div>
    )

    const footer = (
        <div className="d-flex" />
    )

    return (
        <SelectableCard className="w-100 my-2" body={body} footer={footer} isSelected={isSelected} onClick={onClick} />
    )
}

export default CustomerGroupCard;
