import React from 'react';
import { connect } from 'react-redux';
import { getApiUrl, GLOBAL_SETTINGS } from '../../../constants';
import { executeAuthAsyncGet } from '../../../utility/asyncSupport';
import {
  showToastSuccessMessage,
  showToastErrorMessage,
  showToastWarningMessage,
  showToastInfoMessage
} from '../../../api/toasterApi';
import { isNil, map, split, trim, findIndex } from 'lodash';
import { webApiInterface } from '../../../api/webApiInterface';
import LookupValidationResults from './lookupValidationResults';
import { showValidationResults } from '../../../actions/dashboardActions';

export class LookupTransfer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = this.defaultFormState([]);
    this.newExportCsv = this.newExportCsv.bind(this);
    this.newExportXml = this.newExportXml.bind(this);
    this.newExport = this.newExport.bind(this);
    this.onFileSelected = this.onFileSelected.bind(this);
    this.onFileSelectedXml = this.onFileSelectedXml.bind(this);
    this.showValidationResults = this.showValidationResults.bind(this);
    this.open = this.open.bind(this);
    this.generateQuickGuid = this.generateQuickGuid.bind(this);
  }

  defaultFormState() {
    return {
      validationResults: []
    };
  }

  showValidationResults(results) {
    const that = this;
    that.setState(
      {
        validationResults: results
      },
      function () {
        that.open();
      }
    );
  }

  open() {
    const that = this;
    that.props.showValidationModal(true);
  }

  newExportCsv() {
    const that = this;
    that.newExport(1);
  }

  newExportXml() {
    const that = this;
    that.newExport(2);
  }

  generateQuickGuid() {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  }

  newExport(type) {
    const that = this;
    const xhr = new XMLHttpRequest();
    const url =
    getApiUrl(this.props.client) +
      'api/lookupadmin/getfile?lookupId=' +
      that.props.lookupId +
      '&type=' +
      type;

    xhr.open('GET', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(xhr.response);
      a.download =
        type === 1
          ? that.props.tableName + '_' + that.generateQuickGuid() + '.csv'
          : that.props.tableName + '_' + that.generateQuickGuid() + '.xml';
      a.click();
    };

    this.props.executeAuthAsyncGet(this.props.client, 'Lookup/OK', 'CHECK_OK', null, function () {
      webApiInterface.applySecurity(xhr);
      xhr.send(null);
    });
  }

  static addValueToRequest(name, value) {
    return (
      '--blob\r\n' +
      'content-disposition: form-data; name="' +
      name +
      '"\r\n' +
      '\r\n' +
      value +
      '\r\n'
    );
  }

  static addFileContentToRequest(fieldName, fileName, fileType, binaryContent) {
    return (
      '--blob\r\n' +
      'content-disposition: form-data; ' +
      'name="' +
      fieldName +
      '"; ' +
      'filename="' +
      fileName +
      '"\r\n' +
      'Content-Type: ' +
      fileType +
      '\r\n' +
      '\r\n' +
      binaryContent +
      '\r\n'
    );
  }

  onFileSelected(event) {
    const that = this;

    if (event.target.files.length === 0) {
      showToastInfoMessage('File upload cancelled.');
      return;
    }

    const file = event.target.files[0];

    const csvExtension = file.name.indexOf('.csv');

    if (csvExtension === -1) {
      showToastErrorMessage('Please select a valid csv file.');
      return;
    }

    if (!isNil(file)) {
      that.uploadFormConfirmed(file);
    } else {
      showToastErrorMessage('Please select a valid file.');
    }

    that.forceUpdate();
  }

  onFileSelectedXml(event) {
    const that = this;

    if (event.target.files.length === 0) {
      showToastInfoMessage('File upload cancelled.');
      return;
    }

    const file = event.target.files[0];
    const xmlExtension = file.name.indexOf('.xml');

    if (xmlExtension === -1) {
      showToastErrorMessage('Please select a valid xml file.');
      return;
    }

    if (!isNil(file)) {
      that.uploadFormConfirmed(file);
    } else {
      showToastErrorMessage('Please select a valid file.');
    }

    that.forceUpdate();
  }

  uploadFormConfirmed(file) {
    const lookupUploader = this.lookupUploader;

    if (!isNil(file)) {
      const that = this;
      const reader = new FileReader();
      const url = getApiUrl(this.props.client) + 'api/lookupadmin/updatelookup';
      const fileName = file.name;
      const fileSaveName = file.name;
      const fileType = file.type;

      showToastSuccessMessage(
        'Upload of file: ' +
        fileSaveName +
        (fileName !== fileSaveName ? ' (' + fileSaveName + ')' : '') +
        ' started.'
      );

      reader.onload = function (event) {
        if (
          event.currentTarget.result.length >
          GLOBAL_SETTINGS.max_upload_file_size * 1024 * 1024
        ) {
          showToastErrorMessage(
            'File is too big to be uploaded to the server. Please select another file.'
          );
          return;
        }
        if (event.currentTarget.result.length === 0) {
          showToastErrorMessage(
            'File has no content. Please select another file.'
          );
          return;
        }
        const acceptedFileTypes = map(
          split(GLOBAL_SETTINGS.accepted_file_types, ','),
          trim
        ); // Array of extensions
        const fileNameParts = split(fileName, '.');
        const fileExtension = '.' + fileNameParts[fileNameParts.length - 1];
        if (
          findIndex(acceptedFileTypes, (ft) => {
            return ft === fileExtension;
          }) === -1
        ) {
          showToastErrorMessage(
            'File extension is not correct. Please select another file.'
          );
          return;
        }

        const XHR = new XMLHttpRequest();
        const data =
          LookupTransfer.addFileContentToRequest(
            lookupUploader.name,
            fileSaveName,
            fileType,
            event.currentTarget.result
          ) + LookupTransfer.addValueToRequest('lookupId', this.props.lookupId);

        XHR.onreadystatechange = function () {
          if (XHR.readyState === 4) {
            if (XHR.status === 200) {
              const results = JSON.parse(XHR.response);
              if (
                results.validationErrors.length > 0 &&
                results.HasErrors !== true
              ) {
                showToastWarningMessage(
                  'Upload of file: ' +
                  fileName +
                  ' completed with validation errors.'
                );
                that.showValidationResults(results.validationErrors);
              } else {
                if (results.HasErrors === true && results.ErrorCode === 999) {
                  showToastWarningMessage(
                    'Upload of file: ' +
                    fileName +
                    ' could not be completed because the column structure in the file did not match the lookup type.'
                  );
                } else {
                  showToastSuccessMessage(
                    'Upload of file: ' + fileName + ' complete.'
                  );
                }
              }
            } else {
              const err = JSON.parse(XHR.response);
              showToastErrorMessage(
                'Error uploading file: ' +
                fileName +
                '. Error: ' +
                err.Message || XHR.statusText
              );
            }
          }
        };

        XHR.open('POST', url);
        XHR.setRequestHeader(
          'Content-Type',
          'multipart/form-data; boundary=blob'
        );
        XHR.setRequestHeader('Access-Control-Allow-Origin', '*');

        that.props.executeAuthAsyncGet(
          that.props.client,
          'Lookup/OK',
          'CHECK_OK',
          null,
          function () {
            webApiInterface.applySecurity(XHR);
            XHR.send(data + '--blob--');
          }
        );
      }.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  static getKey() {
    return 'admin-lookup-form';
  }

  render() {
    const that = this;
    return (
      <div>
        <div className="dropdown" style={{ display: 'inline', top: '0' }}>
          <button
            id="dropdownMenuButton"
            className="btn btn-default  dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <i className='fas fa-bars' />
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <button
                className="btnDefault btn btn-default"
                onClick={that.newExportCsv.bind(this)}
              >
                Export CSV
              </button>
              <label className="btnDefault btn btn-default">
                Import CSV
                <input
                  type="file"
                  id="lookupUploader"
                  name="lookupUploader"
                  style={{ display: 'none' }}
                  ref={(input) => (that.lookupUploader = input)}
                  onChange={that.onFileSelected}
                  accept=".csv"
                />
              </label>
            </li>
            <li>
              <button
                className="btnDefault btn btn-default"
                onClick={that.newExportXml.bind(this)}
              >
                Export XML
              </button>
              <label className="btnDefault btn btn-default">
                Import XML
                <input
                  type="file"
                  id="lookupUploader"
                  name="lookupUploader"
                  style={{ display: 'none' }}
                  ref={(input) => (that.lookupUploader = input)}
                  onChange={that.onFileSelectedXml}
                  accept=".xml"
                />
              </label>
            </li>
          </ul>
        </div>
        <LookupValidationResults
          validationResults={that.state.validationResults}
          tableName={that.props.tableName}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isVisible: state.dashboardReducer.showLookupValidation
});

const mapDispatchToEvents = (dispatch) => {
  return {
    showValidationModal: (isVisible) =>
      dispatch(showValidationResults(isVisible)),
    executeAuthAsyncGet: (client, serviceName, name, data, onSuccess, onError) => {
      dispatch(
        executeAuthAsyncGet(client, serviceName, name, data, onSuccess, onError)
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToEvents)(LookupTransfer);
