import WorkflowRun from "../../types/WorkflowRunDto"
import classes from "./WorkflowRunCard.module.scss"
import moment from "moment"
import GenericAction, { convertFromActionsDtoToArray } from "../../types/actions/GenericAction"
import { padElementsWith } from "../../../../library/helpers"
import LegacySmsTemplate from "../../types/LegacySmsTemplate"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import ResultCountButton from "./ResultCountButton"
import ActionInfo from "../actionInfo/ActionInfo"
import GenericFilter from "../../../../library/FilterList/filterTypes/GenericFilter"
import FilterTypesByField from "../../../../library/FilterList/FilterTypesByField"
import BooleanFieldIsEqualToFilter from "../../../../library/FilterList/filterTypes/BooleanFieldIsEqualToFilter"
import NumberFieldMatchesOperationFilter from "../../../../library/FilterList/filterTypes/NumberFieldMatchesOperationFilter"

type WorkflowRunCardProps = {
    smsTemplates: LegacySmsTemplate[]
    workflowRun: WorkflowRun
    onNavToWorkflowRunDetails: (workflowRun: WorkflowRun, defaultFilters: GenericFilter[], action?: GenericAction) => void
}

const WorkflowRunCard = ({ smsTemplates, workflowRun, onNavToWorkflowRunDetails }: WorkflowRunCardProps) => {
    const triggerType = getIconAndText(workflowRun)
    const actions = convertFromActionsDtoToArray(workflowRun.rule.actions, [], [])

    const onViewEvaluationClicked = () => onNavToWorkflowRunDetails(workflowRun, [matchedEvaluationFilter])
    const onViewNoActionEvaluationClicked = () => onNavToWorkflowRunDetails(workflowRun, [matchedEvaluationFilter, noActionsFilter])
    const onViewMultipleActionEvaluationClicked = () => onNavToWorkflowRunDetails(workflowRun, [matchedEvaluationFilter, multipleActionsFilter])

    const actionElements = actions.map(action => {
        const resultTotals = workflowRun.actionResults.hasOwnProperty(action.actionReference) ? workflowRun.actionResults[action.actionReference] : undefined

        const onViewActionClicked = () => onNavToWorkflowRunDetails(workflowRun, [matchedActionFilter], action)
        const onViewSuccessfulActionClicked = () => onNavToWorkflowRunDetails(workflowRun, [matchedActionFilter, successfulActionsFilter], action)
        const onViewFailedActionClicked = () => onNavToWorkflowRunDetails(workflowRun, [matchedActionFilter, failedActionsFilter], action)

        return (
            <div key={action.actionReference} className="d-flex align-items-center">
                <div className={classes.infoColumn}>
                    <ActionInfo action={action} smsTemplates={smsTemplates} />
                </div>
                <ResultCountButton
                    colour="green"
                    text="successful"
                    icon="fa-handshake-alt"
                    amount={resultTotals?.successful ?? 0}
                    onClick={onViewSuccessfulActionClicked}
                />
                <ResultCountButton
                    colour="red"
                    text="failed"
                    icon="fa-handshake-alt-slash"
                    amount={resultTotals?.failed ?? 0}
                    onClick={onViewFailedActionClicked}
                />
                <StandardButton iconClasses="far fa-chevron-right" label="View" onClick={onViewActionClicked} small={true} iconRight={true} />
            </div>
        )
    })

    return (
        <div className={`${classes.border} d-flex flex-column no-select px-3 py-2 bg-white text-dark-grey`}>
            <div className="d-flex justify-content-between mb-1">
                <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center me-3">
                        <i className={`fal ${triggerType.icon} text-blue me-1 fs-6`} />
                        <span className="text-blue">{triggerType.text}</span>
                    </div>
                    <span>{workflowRun.triggeredBy}</span>
                </div>

                <div className="d-flex align-items-center">
                    <i className="fal fa-calendar-alt text-blue me-2 fs-3" />
                    <span className="fs-5">{moment(workflowRun.dueAt).format("lll")}</span>
                </div>
            </div>

            <div className="d-flex align-items-center mb-1">
                <div className={`d-flex align-items-center ${classes.infoColumn}`}>
                    <span className="fs-3 me-1">{workflowRun.matchedCount}</span>
                    <span className="fs-5">customers matched evaluation</span>
                </div>
                <ResultCountButton
                    colour="grey"
                    text="with no actions"
                    icon="fa-handshake-alt-slash"
                    amount={workflowRun.matchedCountWithNoActions}
                    onClick={onViewNoActionEvaluationClicked}
                />
                <ResultCountButton
                    colour="grey"
                    text="with multiple actions"
                    icon="fa-clone"
                    amount={workflowRun.matchedCountWithMultipleActions}
                    onClick={onViewMultipleActionEvaluationClicked}
                />
                <StandardButton iconClasses="far fa-chevron-right" label="View" onClick={onViewEvaluationClicked} small={true} iconRight={true} />
            </div>

            <div className={`${classes.coloredBox} mb-2`} />

            {actionElements.length === 0 ? <span className="fst-italic">No actions for this run</span> : padElementsWith(actionElements, "mb-2")}
        </div>
    )
}

export default WorkflowRunCard

const getIconAndText = (workflowRun: WorkflowRun) => {
    if (workflowRun.isPreview) {
        return {
            icon: "fa-eye",
            text: "Preview"
        }
    }

    if (workflowRun.triggeredBy === "Automate") {
        return {
            icon: "fa-clock",
            text: "Scheduled"
        }
    }

    return {
        icon: "fa-hand-paper",
        text: "Manual"
    }
}

const matchedEvaluationFilter: BooleanFieldIsEqualToFilter = {
    id: "0",
    fieldName: "matchedbasefilters",
    type: FilterTypesByField.BOOLEAN_FIELD_IS_EQUAL_TO,
    equalTo: true
}

const noActionsFilter: NumberFieldMatchesOperationFilter = {
    id: "1",
    fieldName: "matchedactioncount",
    type: FilterTypesByField.NUMBER_FIELD_MATCHES_OPERATION,
    values: [
        {
            lessThan: "1"
        }
    ]
}

const multipleActionsFilter: NumberFieldMatchesOperationFilter = {
    id: "1",
    fieldName: "matchedactioncount",
    type: FilterTypesByField.NUMBER_FIELD_MATCHES_OPERATION,
    values: [
        {
            greaterThan: "1"
        }
    ]
}

const matchedActionFilter: BooleanFieldIsEqualToFilter = {
    id: "0",
    fieldName: "matchedactionfilters",
    type: FilterTypesByField.BOOLEAN_FIELD_IS_EQUAL_TO,
    equalTo: true
}

const successfulActionsFilter: BooleanFieldIsEqualToFilter = {
    id: "1",
    fieldName: "wasactionsuccessful",
    type: FilterTypesByField.BOOLEAN_FIELD_IS_EQUAL_TO,
    equalTo: true
}

const failedActionsFilter: BooleanFieldIsEqualToFilter = {
    id: "1",
    fieldName: "wasactionsuccessful",
    type: FilterTypesByField.BOOLEAN_FIELD_IS_EQUAL_TO,
    equalTo: false
}
