import AddComment from "./AddComment"
import Comment from "./Comment"
import { useApi, useClient, Loading, useConfig } from "invevo-react-components"
import { useEffect, useReducer, useState } from "react"
import commentsReducer from "../../reducers/commentsReducer"
import { actionTypes } from "../../reducers/actionTypes"

const Comments = ({ resourceRef, resourceType, theme = "white" }) => {
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [isUnauthorised, setIsUnauthorised] = useState(false)

    const [state, dispatch] = useReducer(
        commentsReducer, {
        comments: []
    })

    const api = useApi()
    const client = useClient()
    const config = useConfig()

    useEffect(() => {
        if (isFetching || hasFetched || !resourceRef || !resourceType || !config.DASHBOARD_API_URL) {
            return
        }

        setIsFetching(true)
        api.get(`${config.DASHBOARD_API_URL}/api/${client}/comments?resourceRef=${resourceRef}&resourceType=${resourceType}`).then((response) => {
            dispatch({ type: actionTypes.COMMENTS_RETRIEVED, comments: response.data })
            setHasFetched(true)
            setIsFetching(false)
        }).catch(error => {
            if (error.response && error.response.status === 401) {
                setHasFetched(true)
                setIsFetching(false)
                setIsUnauthorised(true)
            }
            console.error(error)
        })
    }, [api, client, config.DASHBOARD_API_URL, hasFetched, isFetching, resourceRef, resourceType])

    useEffect(() => {
        setHasFetched(false)
    }, [resourceRef])

    return (
        <div className="d-flex w-100 h-100 flex-column">
            {resourceRef && resourceType &&
                <AddComment resourceReference={resourceRef} resourceType={resourceType} dispatch={dispatch} />
            }
            <Loading isLoading={isFetching} colour={theme === "blue" ? 'white' : 'blue'}>
                {isUnauthorised ?
                    <h4 className={`${theme === "blue" ? 'text-white' : 'text-grey'} p-3`}>You do not have permission to view this content.</h4> :
                    state.comments.length > 0 ?
                        <div className="d-flex flex-column">
                            {
                                state.comments.map(comment =>
                                    <Comment key={comment.guid} comment={comment} textColour={theme === "blue" ? 'white' : 'grey'} />
                                )
                            }
                        </div> :
                        hasFetched ?
                            <h4 className={`${theme === "blue" ? 'text-white' : 'text-grey'} p-3`}>There are no comments to display</h4> :
                            <></>
                }
            </Loading>
        </div>
    )
}

export default Comments