import React from 'react';

const ExportCsvButton = (props) => {
  return (
    <div className="dropdown">
      <button
        id="dropdownMenuButton"
        className="btn btn-default dropdown-toggle"
        data-bs-toggle="dropdown"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
      >
        <i className='fas fa-bars' />
      </button>
      <ul className="dropdown-menu">
        <li>
          <button
            aria-labelledby="dropdownMenuButton"
            className="btnDefault btn btn-default"
            onClick={props.clicked}
          >
            Export CSV
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ExportCsvButton;
