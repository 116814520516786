import Actions from "./actionsSetup/Actions"
import Lookup from "../../../../types/Lookup"
import GenericFilter from "../../../../library/FilterList/filterTypes/GenericFilter"
import GenericAction from "../../types/actions/GenericAction"
import DataField from "../../../../types/DataField"
import LegacySmsTemplate from "../../types/LegacySmsTemplate"
import FilterList from "../../../../library/FilterList/FilterList"
import DataUpdatedWorkflowRule from "../../types/DataUpdatedWorkflowRule"
import EntityRelationshipDto from "../../../entityConfig/types/EntityRelationshipDto"
import EntityConfig from "../../../entityConfig/types/EntityConfig"

type DataUpdatedWorkflowSetupProps = {
    entityConfigFields: { entity: string; fields: DataField[] }[]
    lookups: Lookup[]
    customerFields: DataField[]
    smsTemplates: LegacySmsTemplate[]
    selectedRule: DataUpdatedWorkflowRule
    entityRelationships: EntityRelationshipDto[]
    entityConfigs: EntityConfig[]
    isNewEntityStructureEnabled: boolean
    onRuleUpdated: (updatedRule: DataUpdatedWorkflowRule) => void
}

const DataUpdatedWorkflowSetup = ({
    entityConfigFields,
    lookups,
    customerFields,
    smsTemplates,
    selectedRule,
    entityRelationships,
    entityConfigs,
    isNewEntityStructureEnabled,
    onRuleUpdated
}: DataUpdatedWorkflowSetupProps) => {
    const onRuleBeforeFiltersChanged = (filters: GenericFilter[]) => onRuleUpdated({ ...selectedRule, matchingFiltersBeforeChange: filters })
    const onRuleAfterFiltersChanged = (filters: GenericFilter[]) => onRuleUpdated({ ...selectedRule, matchingFiltersAfterChange: filters })
    const onRuleActionsChanged = (actions: GenericAction[]) => onRuleUpdated({ ...selectedRule, actions })

    const fieldsToUse = isNewEntityStructureEnabled
        ? entityConfigFields.find(pair => pair.entity === selectedRule.entityConfigReference)?.fields ?? []
        : customerFields

    return (
        <div className="d-flex flex-column flex-grow-1 m-3 p-3 bg-grey overflow-auto rounded">
            <div className="d-flex flex-column m-2">
                <div className="d-flex justify-content-between mb-2">
                    <h2 className="text-grey mb-0">Data Trigger</h2>
                </div>
                <span>Evaluate this rule on any customer update</span>
            </div>
            <hr />
            <div className="d-flex flex-column m-2">
                <div className="d-flex justify-content-between mb-2">
                    <h2 className="text-grey mb-0">Matched Before Update</h2>
                </div>
                <span className="mb-2">This rule will only trigger for customers that matched the following filters before the update</span>
                <FilterList
                    key={selectedRule.reference}
                    fields={customerFields}
                    lookups={lookups}
                    appliedFilters={selectedRule.matchingFiltersBeforeChange}
                    onFiltersApplied={onRuleBeforeFiltersChanged}
                    autoApply={true}
                />
            </div>
            <hr />
            <div className="d-flex flex-column m-2">
                <div className="d-flex justify-content-between mb-2">
                    <h2 className="text-grey mb-0">Matches After Update</h2>
                </div>
                <span className="mb-2">This rule will only trigger for customers that now match the following filters after the update</span>
                <FilterList
                    key={selectedRule.reference}
                    fields={fieldsToUse}
                    lookups={lookups}
                    appliedFilters={selectedRule.matchingFiltersAfterChange}
                    onFiltersApplied={onRuleAfterFiltersChanged}
                    autoApply={true}
                />
            </div>
            <hr />
            <div className="m-2">
                <Actions
                    selectedRule={selectedRule}
                    smsTemplates={smsTemplates}
                    fields={fieldsToUse}
                    lookups={lookups}
                    fileEntityExportConfigs={[]}
                    entityRelationships={entityRelationships}
                    entityConfigs={entityConfigs}
                    onRuleActionsChanged={onRuleActionsChanged}
                    isNewEntityStructureEnabled={isNewEntityStructureEnabled}
                />
            </div>
        </div>
    )
}

export default DataUpdatedWorkflowSetup
