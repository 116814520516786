import React, { Component } from 'react';
import { connect } from 'react-redux';
import { executeAuthAsyncGet } from '../../../utility/asyncSupport';
import { sortBy } from 'lodash';
import TagsInput from 'react-tagsinput';
import { showToastErrorMessage } from '../../../api/toasterApi';
import Select from 'react-select';
import { noOptionsMessage, optionStyles } from '../../../utility/selectSupport';
import AsyncSelect from 'react-select/async';
import { filterValueTypes } from '../../../constants';

class EditUserFilter extends Component {
  constructor(props, context) {
    super(props, context);

    this.init = this.init.bind(this);
    this.update = this.update.bind(this);
    this.cancel = this.cancel.bind(this);
    this.onFilterValuesChanged = this.onFilterValuesChanged.bind(this);
    this.onCheckboxFilterTypeChanged = this.onCheckboxFilterTypeChanged.bind(
      this
    );
    this.validateForm = this.validateForm.bind(this);

    this.state = {
      filter: {
        filterType: null,
        roleId: null,
        roleDescription: null,
        filterDefinitionId: null,
        name: null,
        filterValueType: null,
        filterLookupType: null,
        values: []
      },
      filterValuesLookup: [
        // { value: 'One', label: 'One' }, { value: 'Two', label: 'Two' }
      ],
      display: false,
      canUpdate: false
    };
  }

  componentDidMount() {
    if (this.props.editFilter != null) {
      this.props.editFilter(this.init.bind(this));
    }
  }

  init(filter) {
    this.setState((previousState) => {
      let newFilterValuesLookup = { ...previousState.filterValuesLookup };
      if (filter.filterValueType === filterValueTypes.SimpleLookup) {
        newFilterValuesLookup = this.mapSelectValues(
          this.props.filterLookups[filter.filterLookupType]
        );
      }

      return {
        ...previousState,
        filter: Object.assign({}, previousState.filter, filter),
        filterValuesLookup: newFilterValuesLookup,
        display: true,
        canUpdate: true
      };
    });
  }

  mapSelectValues(values) {
    return values.map((v) => {
      return { label: v, value: v };
    });
  }

  onFilterValuesChanged(values) {
    this.setState(
      (previousState) => ({
        ...previousState,
        filter: { ...previousState.filter, values: values }
      }),
      this.validateForm
    );
  }

  onFilterValuesSelectChanged(values) {
    if (values === null) values = [];

    const filterValues = values.map((v) => {
      return v.value;
    });

    this.setState(
      (previousState) => ({
        ...previousState,
        filter: { ...previousState.filter, values: filterValues }
      }),
      this.validateForm
    );
  }

  onCheckboxFilterTypeChanged(event) {
    const values = event.target.checked ? ['True'] : ['False'];
    this.setState(
      (previousState) => ({
        ...previousState,
        filter: { ...previousState.filter, values: values }
      }),
      this.validateForm
    );
  }

  getValuesSearchOptions(input, callback) {
    switch (this.state.filter.filterLookupType) {
      case 'branchCodes':
        this.props.executeAuthAsyncGet(
          this.props.client,
          'branches',
          'SEARCH_BRANCHES',
          { searchTerm: input, searchType: 'Code' },
          function (branches) {
            const options = sortBy(branches, (b) => b.Code).map((b) => {
              return { label: b.Code, value: b.Code };
            });
            callback(options);
          },
          function (/*error*/) {
            callback('Error searching for branches', null);
          }
        );
        break;
      case 'branchNames':
        this.props.executeAuthAsyncGet(
          this.props.client,
          'branches',
          'SEARCH_BRANCHES',
          { searchTerm: input, searchType: 'Name' },
          function (branches) {
            const options = sortBy(branches, (b) => b.Name).map((b) => {
              return { label: b.Name, value: b.Name };
            });
            callback(options);
          },
          function (/*error*/) {
            callback('Error searching for branches', null);
          }
        );
        break;
      case 'countryNames':
        this.props.executeAuthAsyncGet(
          this.props.client,
          'countries',
          'SEARCH_COUNTRIES',
          { searchTerm: input },
          function (countries) {
            const options = sortBy(countries, (c) => c.Name).map((c) => {
              return { label: c.Name, value: c.Name };
            });
            callback(options);
          },
          function (/*error*/) {
            callback('Error searching for countries', null);
          }
        );
        break;
      case 'userNames':
        if (input.length > 1) {
          this.props.executeAuthAsyncGet(
            this.props.client,
            'users',
            'SEARCH_USERS',
            { searchTerm: input },
            function (users) {
              const options = sortBy(users, (u) => u.userName).map((u) => {
                return { label: u.userName, value: u.userName };
              });
              callback(options);
            },
            function (/*error*/) {
              callback('Error searching for users', null);
            }
          );
        } else {
          callback(null, null);
        }
        break;
      default:
        break;
    }
  }

  validateForm() {
    const canUpdate = this.state.filter.values.length > 0;

    this.setState({ canUpdate: canUpdate });
  }

  onNumberFilterTypeValidationReject(/*tags*/) {
    showToastErrorMessage(
      'Filter values must be numbers less than 15 digits long.'
    );
  }

  onDateFilterTypeValidationReject(/*tags*/) {
    showToastErrorMessage('Filter values must be dates in format dd/mm/yyyy.');
  }

  update() {
    this.setState({
      display: false
    });

    if (this.props.onUpdate != null) {
      this.props.onUpdate(this.state.filter);
    }
  }

  cancel() {
    this.setState({
      display: false
    });

    if (this.props.onCancel != null) {
      this.props.onCancel();
    }
  }

  render() {
    return (
      <div>
        {this.state.display ? (
          <div className="sub-form">
            <div className="row form-group">
              <div className="col-12">
                {this.state.filter.filterValueType ===
                  filterValueTypes.FreeText ? (
                  <TagsInput
                    value={this.state.filter.values}
                    onChange={this.onFilterValuesChanged}
                    onlyUnique="true"
                    inputProps={{
                      placeholder:
                        'Add filter values, press enter to add values.'
                    }}
                  />
                ) : (
                  ''
                )}

                {this.state.filter.filterValueType ===
                  filterValueTypes.Number ? (
                  <TagsInput
                    value={this.state.filter.values}
                    onChange={this.onFilterValuesChanged}
                    onlyUnique="true"
                    inputProps={{
                      placeholder:
                        'Add filter numbers, press enter to add values.'
                    }}
                    // eslint-disable-next-line no-useless-escape
                    validationRegex={/^-?[\d+\.?\d]{1,14}$/}
                    onValidationReject={this.onNumberFilterTypeValidationReject}
                  />
                ) : (
                  ''
                )}

                {this.state.filter.filterValueType === filterValueTypes.Date ? (
                  <TagsInput
                    value={this.state.filter.values}
                    onChange={this.onFilterValuesChanged}
                    onlyUnique="true"
                    inputProps={{
                      placeholder:
                        'Add filter dates, press enter to add values.'
                    }}
                    validationRegex={
                      // eslint-disable-next-line no-useless-escape
                      /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/
                    }
                    onValidationReject={this.onDateFilterTypeValidationReject}
                  />
                ) : (
                  ''
                )}

                {this.state.filter.filterValueType ===
                  filterValueTypes.Boolean ? (
                  <label className="my-label">
                    <input
                      type="checkbox"
                      id="checkboxFilterType"
                      name="checkboxFilterType"
                      checked={
                        this.state.filter.values.length > 0 &&
                        this.state.filter.values[0] === 'True'
                      }
                      onChange={this.onCheckboxFilterTypeChanged}
                    />{' '}
                    {this.state.filter.name}
                  </label>
                ) : (
                  ''
                )}

                {this.state.filter.filterValueType ===
                  filterValueTypes.SimpleLookup ? (
                  <Select
                    name="filter-values-select"
                    isMulti
                    value={this.mapSelectValues(this.state.filter.values)}
                    options={this.state.filterValuesLookup}
                    onChange={this.onFilterValuesSelectChanged.bind(this)}
                    placeholder="Select filter values"
                  />
                ) : (
                  ''
                )}

                {this.state.filter.filterValueType ===
                  filterValueTypes.SearchLookup ? (
                  <AsyncSelect
                    name="filter-values-search"
                    isMulti
                    value={this.mapSelectValues(this.state.filter.values)}
                    loadOptions={this.getValuesSearchOptions.bind(this)}
                    onChange={this.onFilterValuesSelectChanged.bind(this)}
                    placeholder="Search for filter values"
                    defaultOptions={[]}
                    noOptionsMessage={noOptionsMessage}
                    styles={{
                      option: optionStyles
                    }}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="row form-group">
              <div className="col-12">
                <button
                  type="button"
                  onClick={this.update}
                  className="btn btn-default"
                  disabled={!this.state.canUpdate}
                >
                  Update
                </button>
                <button
                  type="button"
                  onClick={this.cancel}
                  className="btn btn-default"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default connect(
  null,
  {
    executeAuthAsyncGet
  },
  null,
  { forwardRef: true }
)(EditUserFilter);
