import classes from './SmsSenderActionPanel.module.scss'
import {
    ProgressButton,
    Dropdown,
    Loading,
    useApi,
    useClient,
    useInvevoSession,
    useConfig
} from 'invevo-react-components';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'
import Mobile from '../Mobile'
import { useCustomer } from '../../../../../contexts/CustomerContext';

const SmsSenderActionPanel = () => {
    const api = useApi()
    const client = useClient()
    const config = useConfig()
    const [customer] = useCustomer()

    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [text, setText] = useState("")
    const [sendDisabled, setSendDisabled] = useState(true)
    const [contactList, setContactList] = useState([])
    const [remainingContacts, setRemainingContacts] = useState([{}])
    const [messageWasSent, setMessageWasSent] = useState(false)
    const [smsTemplates, setSmsTemplates] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState()
    const [smsSenderIds, setSmsSenderIds] = useState([])
    const [availableSmsSenderIds, setAvailableSmsSenderIds] = useState([])
    const [senderIdRef, setSenderIdRef] = useState("")
    const [showSenderIds, setShowSenderIds] = useState(false)
    const [invevoSession] = useInvevoSession()

    const templateSelected = useCallback((templateName) => {
        const selectedTemplate = smsTemplates.find(t => t.name === templateName)
        setSelectedTemplate(selectedTemplate)
        setText(selectedTemplate.body)
        setAvailableSmsSenderIds(smsSenderIds.filter(senderId =>
            selectedTemplate.smsSenderIds.includes(senderId.reference)))
        setShowSenderIds(true)
    }, [smsTemplates, smsSenderIds])

    useEffect(() => {
        setContactList(
            (invevoSession.selectedCustomerContacts || []).concat(invevoSession.selectedInternalContacts || [])
                .filter(c => c.SMSRecipient).map(r =>
                    ({ name: r.name, contactRef: r.contactId, selected: true })
                )
        );
    }, [invevoSession.selectedCustomerContacts, invevoSession.selectedInternalContacts]);

    useEffect(() => {
        if (!config.CONNECT_API_URL || !customer.reference || hasFetched || isFetching)
            return
        setIsFetching(true)
        Promise.all([
            api.get(`/api/${client}/customer-groups?customerRef=${customer.reference}`),
            api.get(`${config.CONNECT_API_URL}/api/${client}/sms-sender-id`),
            api.get(`${config.CONNECT_API_URL}/api/${client}/sms-template`)
        ]).then(responses => {
            const customerGroups = responses[0].data.filter(crg => crg.isActive).map(c => c.reference)
            const activeSenderIds = responses[1].data
                .filter(s => s.isActive)
                .filter(senderId =>
                    senderId.customerGroups.some(item => customerGroups.includes(item)))
            setSmsSenderIds(activeSenderIds)

            const activeTemplates = responses[2].data
                .filter(s => s.isActive)
                .filter(template => !template.hasCustomerGroupFilterApplied ||
                    template.customerGroups.some(item => customerGroups.includes(item)))
            setSmsTemplates(activeTemplates)
            setHasFetched(true)
            setIsFetching(false)
        })
            .catch(error => {
                console.error(error)
            })
    }, [api, client, customer.reference, isFetching, hasFetched, config.CONNECT_API_URL])

    useEffect(() => {
        if (!text || (contactList.length === 0) || !senderIdRef) setSendDisabled(true)
        else setSendDisabled(false)
    }, [text, contactList.length, senderIdRef, selectedTemplate])

    useEffect(() => {
        if (smsTemplates.length === 1) templateSelected(smsTemplates[0].name)
    }, [smsTemplates, templateSelected])


    useEffect(() => {
        if (availableSmsSenderIds.length === 1) setSenderIdRef(availableSmsSenderIds[0].reference)
    }, [availableSmsSenderIds])

    useEffect(() => {
        if (remainingContacts.length === 0 && messageWasSent) {
            setTimeout(() => {
                setContactList([])
                setMessageWasSent(false)
            }, 500)
        }
    }, [remainingContacts, messageWasSent])

    const newRequestBody = (contactRefs) => {
        return {
            "senderReference": senderIdRef,
            "to": contactRefs,
            "templateReference": selectedTemplate.reference,
            "customerReference": `${customer.reference}`
        }
    }

    const sendSms = (contactRef = "") => {
        var contactRefs = contactRef !== ""
            ? [contactRef]
            : remainingContacts.map(c => c.contactRef)
        return api.put(`${config.CONNECT_API_URL}/api/${client}/sms/${uuidv4()}`, newRequestBody(contactRefs))
            .then(_ => {
                setMessageWasSent(true)
                if (contactRef === "") {
                    setContactList([])
                    setRemainingContacts([])
                } else {
                    setRemainingContacts(remainingContacts.filter(c => c.contactRef !== contactRef))
                }
            })
            .catch(error => console.error(error))
    }

    return (
        <Loading isLoading={!config.CONNECT_API_URL} colour="blue">
            <div className="d-flex w-100 h-100 bg-blue">
                <div className={`d-flex flex-column ${classes.smsContainer}`}>
                    <h2 className="mt-3 mx-3 text-white">SMS</h2>
                    <Loading isLoading={isFetching} colour="white" >
                        {smsTemplates.length > 0
                            ? <div className={`d-flex mx-4 my-3 ${classes.dropdown}`}>
                                <span className="text-light-blue  me-3">SMS Template:</span>
                                {smsTemplates.length === 1
                                    ? <span className="text-white ">{smsTemplates[0].name}</span>
                                    :
                                    <Dropdown
                                        fullWidthOfParent={true}
                                        placeholder="Select SMS template"
                                        options={smsTemplates.map((t, i) => ({ id: i, value: t.name }))}
                                        onOptionSelected={(template) => templateSelected(template.value)}
                                        colour="blue"
                                    />
                                }
                            </div>
                            : <span className="text-white pt-2">You can only send SMS with a pre-defined template!</span>
                        }

                        {showSenderIds &&
                            <>
                                {availableSmsSenderIds.length > 0
                                    ? <div className="d-flex mx-4">
                                        <span className="text-light-blue me-3">SMS SenderID:</span>
                                        {availableSmsSenderIds.length === 1
                                            ?
                                            <span className="text-white">{availableSmsSenderIds[0].senderId}</span>
                                            :
                                            <Dropdown
                                                fullWidthOfParent={true}
                                                placeholder="Select SMS SenderId"
                                                options={availableSmsSenderIds.map((s, i) => ({ id: i, value: s.senderId }))}
                                                onOptionSelected={(availableSmsSenderId) =>
                                                    setSenderIdRef(availableSmsSenderIds.find(s => s.senderId === availableSmsSenderId.value).reference)
                                                }
                                                colour="blue"
                                            />
                                        }
                                    </div>
                                    : <span className="text-white pt-2">You can only send SMS with a pre-defined sender Id!</span>}
                            </>}
                        <Mobile text={text} disabled={true} />

                        <div className="d-flex w-100">
                            <h5 className="mt-3 mx-3 text-white">To:</h5>
                            <div className="d-flex flex-wrap w-100 mt-3 ms-1 me-4">
                                {contactList.filter(c => c.contactRef && c.contactRef != null).map((c, i) =>
                                    <ProgressButton
                                        className="my-1 mx-1 w-100 align-self-start"
                                        iconClasses="fal fa-paper-plane"
                                        label={c.name}
                                        colour="blue"
                                        key={i}
                                        onClickWithPromise={() => sendSms(c.contactRef)}
                                        succeededText="SMS sent successfully!"
                                        failedText="Failed to send SMS!"
                                        disabled={sendDisabled}
                                        disappearable={true}
                                    />
                                )}
                            </div>
                        </div>
                        <hr className="mx-3 my-4 text-white" />
                        <ProgressButton
                            className="pe-4 ms-5 ps-3"
                            iconClasses="fal fa-paper-plane"
                            label="Send SMS to all"
                            colour="blue"
                            onClickWithPromise={() => sendSms()}
                            succeededText="All SMS sent successfully!"
                            failedText="Failed to send all SMS!"
                            disabled={sendDisabled || remainingContacts.length === 0}
                        />
                    </Loading>
                </div>
            </div>
        </Loading>
    )
}

export default SmsSenderActionPanel;
