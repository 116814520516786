import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import ColumnSelection from './ColumnSelection';
import { getUserConfig, saveUserConfig, createUserConfig } from '../../../api/uiConfigApi';
import { useClient } from 'invevo-react-components';
import { gridColConfig } from '../../../selectors/uiConfig';

const VisibleColumnConfig = ({
  show = false,
  setShow,
  gridId,
  columns,
  setColumns,
  persistedColConfig,
  saveUserConfig,
  createUserConfig,
  getUserConfig
}) => {
  const client = useClient()

  useEffect(() => {
    if (!client) {
      return
    }

    if (!persistedColConfig && show) {
      getUserConfig(client, gridId);
    }
  }, [show, gridId, persistedColConfig, getUserConfig, client]);

  const handleSubmit = (newColumnState) => {
    setShow(false);
    const visibleConfig = newColumnState.map((c) => ({
      field: c.field,
      show: c.show
    }));
    if (persistedColConfig && persistedColConfig.ID) {
      saveUserConfig(client, persistedColConfig.ID, gridId, visibleConfig);
    } else {
      createUserConfig(client, gridId, visibleConfig);
    }
    setColumns(newColumnState);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header>
        <Modal.Title>Edit Columns</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ColumnSelection
          columnsExpanded={show}
          columns={columns}
          onColumnsSubmit={handleSubmit}
        />
      </Modal.Body>
    </Modal>
  );
};
const mapStateToProps = (state, { gridId }) => ({
  persistedColConfig: gridColConfig(state, gridId)
});

export default connect(mapStateToProps, {
  getUserConfig,
  saveUserConfig,
  createUserConfig
})(VisibleColumnConfig);
