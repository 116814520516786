import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { optionStyles } from '../../utility/selectSupport';
import isRequiredIf from 'react-proptype-conditional-require';
import { includes, filter, find } from 'lodash';

const StatefulSelect = ({
  options,
  optionId,
  optionIds,
  onChange,
  placeholder = 'Please select',
  isMulti = false,
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    let found;

    if (isMulti) {
      found = filter(options, (o) => includes(optionIds, o.value));
    } else {
      // undefined is uncontrolled select so default to null
      found = find(options, (o) => o.value === optionId) || null;
    }

    setSelectedOption(found);
  }, [options, optionId, optionIds, isMulti]);

  const onSelectChange = (selected) => {
    setSelectedOption(selected);
    if (selected === null) {
      onChange(isMulti ? [] : null);
      return;
    }

    onChange(isMulti ? selected.map((o) => o.value) : selected.value);
  };

  return (
    <Select
      value={selectedOption}
      options={options}
      onChange={onSelectChange}
      placeholder={placeholder}
      styles={{
        option: optionStyles
      }}
      isMulti={isMulti}
      {...rest}
    />
  );
};

const idProps = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

StatefulSelect.propTypes = {
  options: PropTypes.array.isRequired,
  optionId: isRequiredIf(idProps, (props) => !props.isMulti),
  optionIds: isRequiredIf(PropTypes.arrayOf(idProps), (props) => props.isMulti),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

StatefulSelect.defaultProps = {
  options: []
};

export default StatefulSelect;
