import update from 'immutability-helper';

const getInitialState = () => {
    return {
        messageTypes:{ isFetching: false, isFetched: false, result: []},
        actionDefinitions:{ isFetching: false, isFetched: false, result: []},
        ruleGroup: { isFetching: false, isFetched: false, result: {} },
        ruleGroupValidationResult: {},
        refresh: { update: false }
    };
}

const ruleGroupAdminReducer = (state = getInitialState(), action) => {
    let nState;

    switch (action.type) {
        case 'RECEIVE_MESSAGE_TYPE_LIST':
            nState = update (state, {messageTypes: {result: {$set: action.data}, isFetching:{$set: false}, isFetched:{$set: true}}});

            return nState;
        case 'GET_MESSAGE_TYPE_LIST_ERROR':
            nState = update (state, {messageTypes: {isFetching:{$set: false}, isFetched:{$set: true}, error:{$set: action.error}}});

            return nState;
        case 'RECEIVE_ACTION_DEFINITION_LIST':
            nState = update (state, {actionDefinitions: {result: {$set: action.data}, isFetching:{$set: false}, isFetched:{$set: true}}});

            return nState;
        case 'GET_ACTION_DEFINITION_LIST_ERROR':
            nState = update (state, {actionDefinitions: {isFetching:{$set: false}, isFetched:{$set: true}, error:{$set: action.error}}});

            return nState;
        case 'RECEIVE_RULE_GROUP':
            nState = update (state, {ruleGroup: {result: {$set: action.data}, isFetching:{$set: false}, isFetched:{$set: true}}});

            return nState;
        case 'GET_RULE_GROUP_ERROR':
            nState = update (state, {ruleGroup: {isFetching:{$set: false}, isFetched:{$set: true}, error:{$set: action.error}}});

            return nState;
        case 'SET_RULE_GROUP_VALIDATION':
            nState = update (state, { ruleGroupValidationResult: {$set: action.data} });

            return nState;
        case 'RULE_GROUP_REFRESH':
            nState = update (state, { refresh: {$set: action.refresh} });

            return nState;
        case 'SET_RULE_GROUP':
            nState = update (state, {ruleGroup: {result: {$set: action.data}, isFetching:{$set: false}, isFetched:{$set: true}}});

            return nState;
        default:
            return state;
    }
}

export default ruleGroupAdminReducer;