import Dropdown from "../../../../library/dropdowns/Dropdown"
import EntityConfig from "../../../entityConfig/types/EntityConfig"
import DropdownOption from "../../../../types/DropdownOptions"

type FlowsHeaderProps = {
    entityConfigs: EntityConfig[]
    onNewFlowClicked: (entityConfigReference: string) => void
}

const FlowsHeader = ({ entityConfigs, onNewFlowClicked }: FlowsHeaderProps) => {
    const onEntityConfigSelected = (selectedOption: DropdownOption<string>) => onNewFlowClicked(selectedOption.value)
    return (
        <div className="d-flex flex-column" aria-label="header">
            <div className="d-flex flex-column gap-2 mb-2 justify-content-center">
                <div className="d-flex ">
                    <span className="text-grey no-select fw-light fs-2">Flow Sculptor</span>
                </div>
                <Dropdown
                    className="me-auto"
                    options={entityConfigs.map(config => ({ label: config.displayName, value: config.reference }))}
                    onOptionSelected={onEntityConfigSelected}
                    selectedOption={undefined}
                    placeholder={
                        <span className="d-flex align-items-center">
                            <i className="fal fa-plus fs-5 me-2" />
                            Add Flow
                        </span>
                    }
                    textAlign="left"
                />
            </div>
        </div>
    )
}

export default FlowsHeader
