import { useEffect } from "react"
import { useSessionStorage } from "../../hooks/useSessionStorage"
import { PRE_AUTH_ROUTE_KEY } from "./constants"

const PostAuthLoader = ({children}) => {
    const [preAuthRoute, setPreAuthRoute] = useSessionStorage(PRE_AUTH_ROUTE_KEY)
    
    useEffect(() => {
        if(preAuthRoute) {
            setPreAuthRoute(null)
        }
    }, [preAuthRoute, setPreAuthRoute])
    
    return <>{children}</>
}

export default PostAuthLoader