import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CustomerTasksGridLegacy from './customerTasksGridLegacy';
import CustomerTasksGrid from './customerTasksGrid';
import { IconButton } from '../common/buttons/iconButton';
import { getNewFocusedTask } from '../../actions/taskActions';
import { tasksAsDropdowns, getCustomerTaskGrid } from '../../selectors/task';
import Dropdown, { enumToData } from '../common/dropdown';
import { taskGridFilterValues } from '../../constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getLookup } from '../../api/lookupApi.js';
import { useClient } from "invevo-react-components"

import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import classes from './customerTask.module.scss'

const allTaskTypeIdsSelector = createSelector(
  (state) => state.gridCacheReducer.grids,
  (grids) => {
    const gridCache = getCustomerTaskGrid(grids)

    if (!gridCache || !gridCache.lastResponse) return []

    return gridCache.lastResponse.data.map(task => task.TaskTypeId).filter((value, index, self) => self.indexOf(value) === index)
  }
)

const customerGridTotal = createSelector(
  (state) => state.gridCacheReducer.grids,
  (grids) => {
    const { invevoTaskGridPageSize } = useFlags();
    const gridCache = getCustomerTaskGrid(grids);

    if (!gridCache || !gridCache.lastResponse) return '';

    const { total = 0 } = gridCache.lastResponse;
    const totalText =
      total < invevoTaskGridPageSize ? total : `${invevoTaskGridPageSize}+`;
    return `(${totalText})`;
  }
);
const CustomerTaskList = ({ getNewFocusedTask, taskTypes, getLookup, customerId, isVirtualAccount }) => {
  const [taskIdInput, setTaskIdInput] = useState('');
  const [taskId, setTaskId] = useState(null);
  const totalTasks = useSelector(customerGridTotal)
  const { invevoTaskBulkAction } = useFlags()

  const [showingFilters,] = useState(true)

  const [refreshTaskGrid, setRefreshTaskGrid] = useState(false)
  const [tickedTaskIds, setTickedTaskIds] = useState([])
  const [allTickBoxTicked, setAllTickBoxTicked] = useState(false)

  const [taskTypeId, setTaskTypeId] = useState();
  const [filters, setFilters] = useState([]);

  const visibleTaskTypeIds = useSelector(allTaskTypeIdsSelector)
  const [allTaskTypeIds, setAllTaskTypeIds] = useState([])
  const [statusFilter, setStatusFilter] = useState({
    title: taskGridFilterValues.My_Open,
    isClosed: false,
    isCurrentUserTasks: true
  });

  const onChange = (e) => setTaskIdInput(e.target.value);

  const onChangeTaskType = (id) => {
    setTaskTypeId(id);
  };

  const onClear = () => {
    setTaskIdInput('');
    setTaskId(null);
  };

  const client = useClient()

  useEffect(() => {
    getLookup(client, 'lookup/tasktypes', 'taskTypes');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = () => {
    setTaskId(taskIdInput);
  };

  const onStatusFilterChange = (filter) => {
    let isClosed = null;
    let isCurrentUserTasks = null;

    switch (filter) {
      case taskGridFilterValues.All:
        isClosed = null;
        isCurrentUserTasks = null;
        break;
      case taskGridFilterValues.All_Open:
        isClosed = false;
        isCurrentUserTasks = null;
        break;
      case taskGridFilterValues.Open:
        isClosed = false;
        isCurrentUserTasks = false;
        break;
      case taskGridFilterValues.Closed:
        isClosed = true;
        isCurrentUserTasks = false;
        break;
      case taskGridFilterValues.My_Open:
        isClosed = false;
        isCurrentUserTasks = true;
        break;
      case taskGridFilterValues.My_Closed:
        isClosed = true;
        isCurrentUserTasks = true;
        break;
      default:
        isClosed = null;
        isCurrentUserTasks = null;
        break;
    }

    setStatusFilter({
      title: filter,
      isClosed,
      isCurrentUserTasks
    });
  };

  useEffect(() => {
    const filtersArr = [];
    if (taskId) {
      filtersArr.push({
        field: 'Id',
        operator: 'eq',
        value: taskId
      });
    }

    if (taskTypeId && taskTypeId !== 0) {
      filtersArr.push({
        field: 'TaskTypeId',
        operator: 'eq',
        value: taskTypeId
      });
    }

    setFilters(filtersArr);
  }, [taskId, taskTypeId]);

  const onAddNewTask = () => {
    getNewFocusedTask();
  };

  const removeTaskTypeFilters = () => {
    setFilters([])
    setTaskTypeId(undefined)
  }

  const shouldShowRemoveFilterButton = taskTypeId !== undefined && taskTypeId !== 0 && taskTypeId !== null

  useEffect(() => {
    // If filtered, dont reset filter types
    if (taskTypeId !== 0 && allTaskTypeIds.length !== 0) return
    setAllTaskTypeIds(visibleTaskTypeIds)
  }, [allTaskTypeIds.length, taskTypeId, visibleTaskTypeIds, statusFilter])

  const taskTypesToShow = [{ id: 0, text: "All Task Types" }, ...taskTypes.filter(taskType => allTaskTypeIds.includes(taskType.id))]

  return (
    invevoTaskBulkAction ?
      <div className={`d-flex pl-3 h-100 flex-column ${classes.container} overflow-auto`} id={`tasks-list`}>
        <div className="d-flex flex-column pr-3 pt-3">
          <div className="row" style={{ marginRight: "0" }}>
            <div className="col-auto mt-2">
              <h2 className='text-white'>Customer Tasks {totalTasks}</h2>
            </div>
            {shouldShowRemoveFilterButton &&
              <h2>(Filtered <button type="button" className="btn btn-link p-0 b-0 mb-2" style={{ "fontSize": "30px" }} onClick={removeTaskTypeFilters}>remove filters</button>)</h2>
            }
            <div className="ms-auto me-3 w-auto">
              <button
                className={`btn ${classes['btn-custom']}`}
                onClick={onAddNewTask}
                title={'Add Task'}
              >
                <i className={`far fa-plus pe-1`}></i>
                Add Task
              </button>
            </div>
          </div>
          <div className="row" style={{ marginRight: "0" }}>
            <div className="col-6 top20">
              <Dropdown
                title={statusFilter.title}
                data={enumToData(taskGridFilterValues)}
                onClick={onStatusFilterChange}
                className="btn btn-link text-white"
              />
            </div>
            <div className="col-6 top20">
              <Dropdown
                title={'Filter by type'}
                data={taskTypesToShow}
                selectedItemId={taskTypeId}
                onClick={onChangeTaskType}
                className="btn btn-link text-white"
              />

            </div>
          </div>
          <div className="row pb-3" style={{ marginRight: "0" }}>
            <div className="col-sm-12 top20">
              <div className="input-group" style={{ float: "right" }} >
                <div className="input-group-prepend d-flex">
                  <div className="input-group-text">
                    <i className={`far fa-hashtag`}></i>
                  </div>
                </div>
                <input
                  className="form-control"
                  placeholder="Search by task id"
                  value={taskIdInput}
                  type="number"
                  onChange={onChange}
                />
                <div className="input-group-append d-flex">
                  <IconButton
                    icon={'times-circle'}
                    onClick={onClear}
                    title="Clear search"
                    className={`btn ${classes['btn-custom']}`}
                    isDisabled={!taskIdInput}
                  />
                  <IconButton
                    icon={'search'}
                    title="Search taskId"
                    className={`btn ${classes['btn-custom']}`}
                    isDisabled={!taskIdInput}
                    onClick={onSearch}
                  />
                </div>
              </div>
            </div>
          </div>
          <CustomerTasksGrid
            client={client}
            customerId={customerId}
            isVirtualAccount={isVirtualAccount}
            tickedTaskIds={tickedTaskIds}
            setTickedTaskIds={setTickedTaskIds}
            filters={filters}
            statusFilter={statusFilter}
            setRefresh={setRefreshTaskGrid}
            refresh={refreshTaskGrid}
            isBulkActionSelected={!showingFilters}
            allTickBoxTicked={allTickBoxTicked}
            setAllTickBoxTicked={setAllTickBoxTicked}
          />
        </div>
      </div>
      :
      <div className={`container-fluid ${classes.container}`} id="tasks-list">
        <div className="row">
          <div className="col-12 top20">
            <h2 className="mt-0 text-white">
              Customer Tasks
              <div style={{ float: "right" }}>
                <button
                  className={`btn ${classes['btn-custom']}`}
                  onClick={onAddNewTask}
                  title={'Add Task'}
                >
                  <i className={`far fa-plus pe-1`}></i>
                  Add Task
                </button>
              </div>
            </h2>
          </div>
          <div className="col-6 top20">
            <Dropdown
              title={statusFilter.title}
              data={enumToData(taskGridFilterValues)}
              onClick={onStatusFilterChange}
              className={`btn btn-link text-white`}
            />
          </div>
          <div className="col-6 top20">
            <Dropdown
              title={'Filter by type'}
              data={taskTypes}
              selectedItemId={taskTypeId}
              onClick={onChangeTaskType}
              className={`btn btn-link text-white`}
            />
          </div>
          <div className="col-sm-12 top20">
            <div className="input-group" style={{ float: "right" }}>
              <div className="input-group-prepend d-flex">
                <div className="input-group-text">
                  <i className={`far fa-hashtag`}></i>
                </div>
              </div>
              <input
                className="form-control"
                placeholder="Search by task id"
                value={taskIdInput}
                type="number"
                onChange={onChange}
              />
              <div className="input-group-append d-flex">
                <IconButton
                  icon={'times-circle'}
                  onClick={onClear}
                  title="Clear search"
                  className={`btn ${classes['btn-custom']}`}
                  isDisabled={!taskIdInput}
                />
                <IconButton
                  icon={'search'}
                  title="Search taskId"
                  className={`btn ${classes['btn-custom']}`}
                  isDisabled={!taskIdInput}
                  onClick={onSearch}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <CustomerTasksGridLegacy
              client={client}
              customerId={customerId}
              isVirtualAccount={isVirtualAccount}
              filters={filters}
              statusFilter={statusFilter}
            />
          </div>
        </div>
      </div>
  );
};

const mapStateToProps = (state) => {
  return {
    taskTypes: tasksAsDropdowns(state.lookupReducer.taskTypes.results)
  };
};

export default connect(mapStateToProps, {
  getNewFocusedTask,
  getLookup,
})(CustomerTaskList);