import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getApiUrl } from '../../../constants';

import { ColumnMenuFilter } from '../../common/grids/columnMenu';

import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../../common/grids/StatefulGrid';
import { normaliseFormatDef } from '../../../selectors/lookup';

class FileImportLogErrorsGrid extends Component {
  state = {
    columns: [
      { field: 'Time', title: 'Time' },
      { field: 'LogType', title: 'Log Type' },
      { field: 'LogText', title: 'Log Text', width: '400px' },
      { field: 'ImportRecordId ', title: 'Import Record Id' },
      {
        field: 'ImportFieldMapId',
        title: 'Import Field Map Id'
      },
      { field: 'Id', title: 'Id' }
    ]
  };

  render() {
    return (
      <StatefulGrid
        client={this.props.client}
        path={getApiUrl(this.props.client) + 'api/fileimporterlog/errors'}
        additionalRequestPayload={{
          fileregisterid: this.props.dataItem.Id
        }}
        pageSize={10}
        pageable={{
          pageSizes: [5, 10, 20, 100],
          refresh: true,
          buttonCount: 5
        }}
        hideablePaging={true}
        defaultField={{ field: 'Id', dir: 'asc' }}
        sortable={true}
        className={'scrollable-none'}
        columnMenu={ColumnMenuFilter}
      >
        {this.state.columns.map((column) => (
          <GridColumn key={column.field} {...column} />
        ))}
      </StatefulGrid>
    );
  }
}

const mapStateToProps = (state) => ({
  dateFormat: normaliseFormatDef(
    state.lookupReducer.globalSettings.results.dateFormat
  )
});

export default connect(mapStateToProps, null)(FileImportLogErrorsGrid);
