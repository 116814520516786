import { FileEntityExportConfigState, FileExportConfigAction, FileExportConfigActionEnum } from "../../../reducers/FileEntityExportConfigReducer"
import classes from "./ExportFileConfig.module.scss"
import DataFileExportOptions from "./DataFileExportOptions"
import Dropdown from "../../../../../library/dropdowns/Dropdown"
import { useFeatureToggle } from "../../../../../hooks/useFeatureToggle"
import { FileFormat } from "../../utils"
import EntityConfig from "../../../../entityConfig/types/EntityConfig"

type FileExportConfigSetupProps = {
    config: FileEntityExportConfigState
    entities: EntityConfig[]
    dispatch: React.Dispatch<FileExportConfigAction>
}

const defaultFileFormats = [
    { value: FileFormat.CSV, label: FileFormat.CSV.toString() },
    { value: FileFormat.TEXT, label: FileFormat.TEXT.toString() }
]

const ExportFileConfig = ({ config, entities, dispatch }: FileExportConfigSetupProps) => {
    const { isEnabled: isXMLExportEnabled } = useFeatureToggle("xmlFileExport")
    const fileFormats = isXMLExportEnabled ? [...defaultFileFormats, { value: FileFormat.XML, label: FileFormat.XML.toString() }] : defaultFileFormats

    const onFileTypeSelected = (option: any) =>
        dispatch({
            type: FileExportConfigActionEnum.FILE_EXPORT_ENTITY_SELECTED,
            option: option.value
        })

    const onFileFormatSelected = (option: any) =>
        dispatch({
            type: FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_UPDATED,
            editingConfig: { ...config, fileFormat: option.value }
        })

    const entityFileTypes = entities.map(entity => ({
        value: entity.reference,
        label: entity.displayName
    }))

    return (
        <div className={`d-flex ${classes.card} mt-2 pt-2 `}>
            <div className="d-flex px-3 pt-3">
                <i className={`fal fa-grip-lines ${classes.icon}`} />
            </div>
            <div className={`${classes.verticalDivider}`} />
            <div className="d-flex flex-column w-100">
                <div className="d-flex my-1 mx-3">
                    <span className="text-grey fs-4">File details</span>
                </div>
                <div className={`${classes.horizontalDivider} mx-2`} />
                <div className="d-flex flex-column">
                    <div className="d-flex p-3">
                        <div className={`d-flex flex-column pe-3 small`}>
                            <span className="text-uppercase small mb-1 ms-1 text-grey">File type</span>
                            <Dropdown
                                className="me-3"
                                placeholder={"Please select a file type"}
                                selectedOption={entityFileTypes.find(ft => ft.value === config.entityType)}
                                options={entityFileTypes}
                                onOptionSelected={onFileTypeSelected}
                                ariaLabel="File Type"
                            />
                        </div>
                        <div className={`d-flex flex-column pe-3 small`}>
                            <span className="text-uppercase small mb-1 ms-1 text-grey">Format</span>
                            <Dropdown
                                className="me-3"
                                placeholder={"Please select format"}
                                selectedOption={fileFormats.find(ft => ft.value === config.fileFormat.valueOf()) || fileFormats[0]}
                                options={fileFormats}
                                onOptionSelected={onFileFormatSelected}
                                ariaLabel="File Format"
                            />
                        </div>
                    </div>
                    <DataFileExportOptions state={config} dispatch={dispatch} />
                </div>
            </div>
        </div>
    )
}

export default ExportFileConfig
