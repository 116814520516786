import { FieldDataTypes, useClient, useConfig } from "invevo-react-components"
import { widgetThemes } from "../../../enums/widgetThemes"
import { dataPrimitive } from "../../../../data/enums/dataPrimitive"
import InfoCard from "../../../../../library/cards/InfoCard"
import useApiQuery from "../../../../../hooks/useApiQuery"
import { useCustomer } from "../../../../../contexts/CustomerContext"
import moment from "moment"
import { useDispatch } from "react-redux"
import { isDynamicData } from "./helpers"
import { webApiInterface } from "../../../../legacy/api/webApiInterface"
import { getApiUrl } from "../../../../legacy/constants"
import { useFeatureToggle } from "../../../../../hooks/useFeatureToggle"
import { exists } from "../../../../../library/helpers/tsUtils"

const isNumber = value => !isNaN(value) && !isNaN(parseFloat(value))
const isFloat = value => parseFloat(value) % 1 !== 0

const getStyle = value => (isNumber(value) ? (Number(value) >= 0 ? "good" : "bad") : "info")

const formatNumber = number => (isFloat(number) ? parseFloat(number).toFixed(2) : Math.round((Number(number) + Number.EPSILON) * 100) / 100)
const formatValue = value => (isNumber(value) ? formatNumber(value) : value)

const InfoDisplayWidget = ({ state }) => {
    const { widget, lookups = [], customerFields = [], customerData } = state

    const client = useClient()
    const config = useConfig()
    const [customer, setCustomer] = useCustomer()
    const dispatch = useDispatch()
    const { isEnabled: shouldCallLegacyApi } = useFeatureToggle("callLegacyApiWhenUpdatingKafkaDashboard")
    const { isEnabled: updateApplyCreditCardChargesInSql } = useFeatureToggle("updateApplyCreditCardChargesInSql")
    const { isEnabled: updateEditableTenantCcStatus } = useFeatureToggle("updateEditableTenantCcStatus")

    const updateCustomerRequest = useApiQuery({
        url: `${config.INTEGRATION_API_URL}/api/${client}/customer-v2`,
        method: "PATCH",
        isExecutedAutomatically: false
    })

    const updateCustomer = (dataToUpdate, newValue) => {
        var newData = {}
        let updatedCustomerData = { ...customerData }

        switch (dataToUpdate.type) {
            case "boolean":
                newData = { booleanFields: [{ fieldName: dataToUpdate.fieldName, fieldValue: newValue === "true" }] }
                updatedCustomerData = {
                    ...updatedCustomerData,
                    booleanFields: { ...updatedCustomerData.booleanFields, [dataToUpdate.fieldName]: newValue === "true" }
                }
                break
            case "number":
                newData = { bigDecimalFields: [{ fieldName: dataToUpdate.fieldName, fieldValue: parseFloat(newValue) }] }
                updatedCustomerData = {
                    ...updatedCustomerData,
                    numberFields: { ...updatedCustomerData.numberFields, [dataToUpdate.fieldName]: parseFloat(newValue) }
                }
                break
            case "date":
                newData = { dateTimeFields: [{ fieldName: dataToUpdate.fieldName, fieldValue: moment(Number(newValue)).format() }] }
                updatedCustomerData = { ...updatedCustomerData, datetimeFields: { ...updatedCustomerData.datetimeFields, [dataToUpdate.fieldName]: newValue } }
                break
            default:
                newData = { stringFields: [{ fieldName: dataToUpdate.fieldName, fieldValue: newValue }] }
                updatedCustomerData = { ...updatedCustomerData, stringFields: { ...updatedCustomerData.stringFields, [dataToUpdate.fieldName]: newValue } }
                break
        }

        const updateCustomer = () => {
            updateCustomerRequest
                .execute(updatedCustomerData.reference, { id: updatedCustomerData.reference, ...newData })
                .then(() => setCustomer({ data: updatedCustomerData }))
                .catch(error => console.error(error))
        }
        if (shouldCallLegacyApi) {
            switch (dataToUpdate.fieldName) {
                case "alwaysonstop":
                    callLegacyApi(
                        client,
                        `${getApiUrl(client)}api/AccountStatus/UpdateAlwaysOnStop`,
                        { customerId: customer.sqlId, alwaysOnStop: newValue === "true" },
                        updateCustomer,
                        dispatch
                    )
                    break

                case "neveronstop":
                    callLegacyApi(
                        client,
                        `${getApiUrl(client)}api/AccountStatus/UpdateNeverOnStop`,
                        { customerId: customer.sqlId, neverOnStop: newValue === "true" },
                        updateCustomer,
                        dispatch
                    )
                    break

                case "allcommunicationsonhold":
                    callLegacyApi(
                        client,
                        `${getApiUrl(client)}api/dynamiccolumn/dynamicrecord/updateCommsOnHold`,
                        { customerId: customer.sqlId, value: newValue === "true" },
                        updateCustomer,
                        dispatch
                    )
                    break

                case "iskeyaccount":
                    callLegacyApi(
                        client,
                        `${getApiUrl(client)}api/dynamiccolumn/dynamicrecord/updateKeyAccount`,
                        { customerId: customer.sqlId, value: newValue === "true" },
                        updateCustomer,
                        dispatch
                    )
                    break

                case "status":
                    callLegacyApi(
                        client,
                        `${getApiUrl(client)}api/AccountStatus/UpdateAccountStatus`,
                        { customerId: customer.sqlId, statusCode: newValue },
                        updateCustomer,
                        dispatch
                    )
                    break

                case "applycreditcardcharges":
                    if (updateApplyCreditCardChargesInSql)
                        callLegacyApi(
                            client,
                            `${getApiUrl(client)}api/customer/UpdateApplyCcCharges`,
                            { customerId: customer.sqlId, applyCreditCardCharges: newValue === "true" },
                            updateCustomer,
                            dispatch
                        )
                    else updateCustomer()
                    break

                case "editable_tenant_cc_status":
                    if (updateEditableTenantCcStatus)
                        callLegacyApi(
                            client,
                            `${getApiUrl(client)}api/customer/UpdateCustomerProfession`,
                            { customerId: customer.sqlId, customerProfession: newValue },
                            updateCustomer,
                            dispatch
                        )
                    else updateCustomer()
                    break

                default:
                    updateCustomer()
                    break
            }
        } else {
            updateCustomer()
        }
    }

    const getValue = (fieldName, fieldType) => {
        if (!exists(customerData)) return undefined
        if (isDynamicData(customerData))
            switch (fieldType) {
                case dataPrimitive.LOOKUP:
                    return customerData.stringFields[fieldName]
                case dataPrimitive.BOOLEAN:
                    return customerData.booleanFields[fieldName]
                case dataPrimitive.NUMBER:
                    return customerData.numberFields[fieldName]
                case dataPrimitive.DATE:
                    return customerData.datetimeFields[fieldName]
                default:
                    return customerData.stringFields[fieldName]
            }
        else return customerData[fieldName]
    }

    const getType = type => type.toLowerCase()

    const getLookupValues = (fieldName, fieldType) => {
        return fieldType !== FieldDataTypes.LOOKUP
            ? []
            : lookups
                  .find(lookup => lookup.reference === customerFields.find(customerField => customerField.fieldName === fieldName)?.lookup?.toLowerCase())
                  ?.entries.map(entry => {
                      return { label: entry.name, value: entry.reference }
                  })
    }

    const updatedCustomerFields = customerFields?.map(field => {
        if (field.dataPrimitive === dataPrimitive.LOOKUP) {
            return {
                ...field,
                updatedName: lookups
                    ?.find(l => l.reference === field.lookup)
                    ?.entries?.find(e => getValue(field.fieldName, field.dataPrimitive) === e.reference)?.name
            }
        } else {
            return field
        }
    })

    return (
        <InfoCard
            className="w-100 h-100"
            title={widget.displayName}
            theme={widget.theme === widgetThemes.DEFAULT ? "white" : "blue"}
            data={widget.fields.map(field => ({
                id: field.id,
                fieldName: field.value,
                label: updatedCustomerFields?.find(f => f.fieldName === field.value)?.displayName || field.value,
                value:
                    field.dataType === FieldDataTypes.LOOKUP
                        ? formatValue(
                              updatedCustomerFields?.find(f => field.fieldName === f.fieldName)?.updatedName || getValue(field.fieldName, field.dataType)
                          )
                        : getValue(field.fieldName, field.dataType),
                style: getStyle(getValue(field.fieldName, field.dataType)),
                type: getType(field.dataType),
                isEditable: field.isEditable,
                lookupValueList: getLookupValues(field.value, field.dataType)
            }))}
            onCustomerUpdate={updateCustomer}
        />
    )
}

export default InfoDisplayWidget

const callLegacyApi = (client, url, body, action, dispatch) => {
    webApiInterface
        .authPost(client, url, dispatch, body)
        .then(response => response.json())
        .then(result => {
            if (!result.isError) action()
        })
        .catch(error => console.error(error))
}
