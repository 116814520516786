import { Loading, useConfig } from "invevo-react-components"
import { useState } from "react"
import { convertToCurrency } from "../../../../library/helpers/numberUtils"
import classes from "./QuickFilterGroup.module.scss"
import useApiQuery from "../../../../hooks/useApiQuery"
import Lookup from "../../../../types/Lookup"
import DataField from "../../../../types/DataField"
import CustomerAggregationDto from "../../types/CustomerAggregationDto"
import QuickFilter from "../../types/QuickFilter"
import FiltersDto from "../../../../library/FilterList/FiltersDto"
import useClient from "../../../../hooks/useClient"

type QuickFilterGroupProps = {
    lookups: Lookup[]
    dataField: DataField
    filtersDto: FiltersDto
    disabled: boolean
    selectedQuickFilters: QuickFilter[]
    onSelectedQuickFiltersUpdated: (updatedFilters: QuickFilter[]) => void
}

const QuickFilterGroup = ({ lookups, dataField, filtersDto, disabled, selectedQuickFilters, onSelectedQuickFiltersUpdated }: QuickFilterGroupProps) => {
    const config = useConfig()
    const client = useClient()

    const [isExpanded, setIsExpanded] = useState(false)
    const [quickFilterAggregations, setQuickFilterAggregations] = useState<CustomerAggregationDto[]>([])

    const isSelected = (quickFilterLabel: string) => selectedQuickFilters.some(f => f.filter.label === quickFilterLabel && f.field.value === dataField.value)

    const toggleIsExpanded = () => {
        if (!isExpanded) {
            getCustomerAggregations()
        }

        setIsExpanded(!isExpanded)
    }

    const getDisplayNameForLookup = (lookupRef: string, data: string) =>
        lookups.find(l => l.reference === lookupRef)?.entries.find(e => e.reference.toLowerCase() === data?.toLowerCase())?.name || data

    const toggleQuickFilter = (aggregation: CustomerAggregationDto) => {
        if (disabled) return

        isSelected(aggregation.label)
            ? onSelectedQuickFiltersUpdated(selectedQuickFilters.filter(f => f.filter.label !== aggregation.label))
            : onSelectedQuickFiltersUpdated([...selectedQuickFilters, { field: dataField, filter: aggregation }])
    }

    const getCustomerAggregationsRequest = useApiQuery<{ aggregations: CustomerAggregationDto[] }>({
        url: `${config.AUTOMATE_API_URL}/api/${client}/customer-aggregations`,
        method: "POST",
        isExecutedAutomatically: false
    })

    const getCustomerAggregations = () =>
        getCustomerAggregationsRequest.execute(undefined, { fieldName: dataField.value, dataPrimitive: dataField.type, filters: filtersDto }).then(response => {
            setQuickFilterAggregations(response.data.aggregations)
        })

    return (
        <div className="d-flex flex-column flex-grow-1 w-100">
            <div className={`mx-3 my-2 ${classes.container} rounded`}>
                <div
                    className={`d-flex justify-content-between align-items-center py-2 px-3 no-select pointer w-100 ${isExpanded ? classes.expanded : ""}`}
                    onClick={toggleIsExpanded}
                    role="button"
                    aria-label={dataField.value}
                >
                    <h5 className="mb-0 text-white text-uppercase">{dataField.label}</h5>
                    <i className={`fa-regular ${isExpanded ? "fa-minus" : "fa-plus"} text-white`}></i>
                </div>
                {isExpanded && (
                    <div>
                        <Loading isLoading={getCustomerAggregationsRequest.isFetching} colour="white">
                            <div className={`${classes.optionsContainer} px-2 pb-2 h-100`}>
                                {quickFilterAggregations.map(aggregation => (
                                    <div className="d-flex align-items-center justify-content-between pt-2 no-select" key={aggregation.label}>
                                        <div
                                            key={aggregation.label}
                                            className={`pointer d-flex px-2 py-1 ${isSelected(aggregation.label) ? classes.selectedOption : classes.option}`}
                                            onClick={() => toggleQuickFilter(aggregation)}
                                            role="button"
                                            aria-label={aggregation.label}
                                        >
                                            {getDisplayNameForLookup(dataField.lookup ?? "", aggregation.label)}
                                        </div>
                                        <div className="ms-2 w-50 d-flex flex-column text-white">
                                            {aggregation.totalByCurrency.map(a => (
                                                <span key={a.currency}>{convertToCurrency(a.value.toFixed(2), a.currency)}</span>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                                {quickFilterAggregations.length === 0 && getCustomerAggregationsRequest.hasFetched && (
                                    <h4 className="d-flex justify-content-center pt-3 text-white">No filters available</h4>
                                )}
                            </div>
                        </Loading>
                    </div>
                )}
            </div>
        </div>
    )
}

export default QuickFilterGroup
