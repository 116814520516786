import classes from "./Toggle.module.scss"
import { MouseEvent } from "react"

type Props = {
    status: boolean
    onStatusChanged: (status: boolean) => void
    className?: string
    disabled?: boolean
    onText?: string
    offText?: string
    colour?: "blue" | "purple" | "white" | "black" | "grey" | "red" | "orange"
    toggleColour?: "blue" | "purple"
    ariaLabel?: string
}

const Toggle = ({
    status,
    onStatusChanged,
    className = "",
    disabled = false,
    onText,
    offText,
    colour = "grey",
    toggleColour = "blue",
    ariaLabel = ""
}: Props) => {
    const onChange = () => {
        if (disabled) return
        onStatusChanged(!status)
    }

    const onClick = (event: MouseEvent) => event.stopPropagation()

    return (
        <div className={`d-flex align-items-center ${className}`}>
            {offText && <span className={`text-${colour} me-2 ${disabled ? classes.disabled : ""}`}>{offText}</span>}
            <label
                className={`${classes.switch} ${disabled ? `${classes.disabled} not-allowed` : "pointer"} mb-0`}
                role="switch"
                aria-label={ariaLabel}
                aria-checked={status}
            >
                <input type="checkbox" checked={status} onChange={onChange} onClick={onClick} />
                <span className={`${classes.slider} ${classes[toggleColour]}`} />
            </label>
            {onText && <span className={`text-${colour} ms-2 ${disabled ? classes.disabled : ""}`}>{onText}</span>}
        </div>
    )
}

export default Toggle
