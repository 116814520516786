import UserDashboards from "../../../microfrontends/dashboard/components/userDashboards/UserDashboards"

const ViewDashboards = () => {
    return (
        <div className="d-flex w-100 h-100">
            <UserDashboards />
        </div>
    )
}

export default ViewDashboards