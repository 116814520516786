import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const EditNote = ({
  initNoteText,
  show = false,
  setShow,
  onUpdate,
  onDelete = null
}) => {
  const [note, setNote] = useState('');

  useEffect(() => setNote(initNoteText), [initNoteText]);

  const handleOnChange = (e) => setNote(e.target.value);

  return (
    <Modal size="sm" show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        <h3>{onDelete ? 'Edit' : 'Create'} Note</h3>
        <textarea
          className={'w-100'}
          rows={5}
          value={note}
          onChange={handleOnChange}
        />
        <div className="d-flex justify-content-between">
          {onDelete && (
            <button className="btn btn-default text-left" onClick={onDelete}>
              <i className="fa-regular fa-trash-alt me-2" />
              <span className="text-muted">Remove note</span>
            </button>
          )}
          <button
            className="btn btn-default text-left"
            onClick={() => onUpdate(note)}
            disabled={note.length === 0}
          >
            <i className="fa-regular fa-save me-2" />
            <span className="text-muted">Save note</span>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditNote;
