import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as lodash from 'lodash';

import { authZ_Permissions, getApiUrl } from '../../../constants';
import { webApiInterface } from '../../../api/webApiInterface';
import { executeAuthAsyncGet } from '../../../utility/asyncSupport';
import ExportCsvButton from './exportCsvButton';
import { hasPermission } from '../../../utility/authZ';

import EditDeleteCommandCell from '../../common/grids/editDeleteCommandCell';
import { ConfrimDialog } from '../../common/confirmDialog';
import { ColumnMenuFilter } from '../../common/grids/columnMenu';

import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../../common/grids/StatefulGrid';

class ImportQueueGrid extends Component {
  constructor(props) {
    super(props);

    this.onEditClick = this.onEditClick.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);

    this.exportCsv = this.exportCsv.bind(this);

    this.state = {
      refresh: 0,
      showDeleteDialog: false,
      itemToDelete: null
    };

    this.EditDeleteCommandCell = EditDeleteCommandCell({
      onEditClick: this.onEditClick,
      onDeleteClick: this.onDeleteClick
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.fileImportQueue.success ||
      nextProps.fileImportQueue.deleted ||
      nextProps.refresh.update
    ) {
      this.refresh();
    }
  }

  refresh = () => {
    this.setState({ refresh: this.state.refresh + 1 });
  };

  onEditClick = (e, dataItem) => {
    e.preventDefault();
    const importQueueId = dataItem.id;
    this.props.onAdd(importQueueId);
  };

  onAddClick() {
    this.props.onAdd();
  }

  onDeleteClick = (e, dataItem) => {
    e.preventDefault();
    this.setState({
      showDeleteDialog: true,
      itemToDelete: dataItem
    });
  };

  deleteItem = () => {
    if (this.state.itemToDelete !== null) {
      this.props.onDelete(this.state.itemToDelete);
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.setState({
      showDeleteDialog: false
    });
  };

  getColumns() {
    const columns = [
      { field: 'id', title: 'Id' },
      { field: 'queueName', title: 'Queue Name' },
      { field: 'importFileType', title: 'File Type' },
      { field: 'fileWriteThresholdItems', title: 'File Write Threshold Items' },
      {
        field: 'fileWriteThresholdSeconds',
        title: 'File Write Threshold Secs'
      },
      { field: 'fileNameTemplate', title: 'Filename Template' },
      {
        field: 'isInUse',
        title: 'Is In Use',
        type: 'boolean',
        filter: 'boolean'
      }
    ];

    return columns;
  }

  exportCsv() {
    const xhr = new XMLHttpRequest();
    const url =
      getApiUrl(this.props.client) +
      'api/import-configuration/export-csv?exportType=2&fileTypeId=';

    xhr.open('GET', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(xhr.response);
      a.download = 'ImportQueues.csv';
      a.click();
    };

    this.props.executeAuthAsyncGet(this.props.client, 'Lookup/OK', 'CHECK_OK', null, function () {
      webApiInterface.applySecurity(xhr);
      xhr.send(null);
    });
  }

  static getKey() {
    return 'import-queue-grid';
  }

  render() {
    const button = hasPermission(
      this.props.permissions,
      authZ_Permissions.FileImportAdminUpdate
    ) ? (
      <button
        className="btnDefault btn btn-default float-end"
        onClick={this.onAddClick}
      >
        New Queue
      </button>
    ) : (
      ''
    );

    return (
      <div className="clearfix">
        <h3>Import Queues</h3>
        <div className="float-left">
          <ExportCsvButton clicked={this.exportCsv} />
        </div>
        {button}

        <StatefulGrid
          client={this.props.client}
          id={ImportQueueGrid.getKey()}
          key={ImportQueueGrid.getKey()}
          path={getApiUrl(this.props.client) + 'api/import-configuration/queues'}
          sortable
          className={'scrollable-none float-left'}
          pageSize={10}
          pageable={{
            pageSizes: [10, 25],
            buttonCount: 5
          }}
          columnMenu={ColumnMenuFilter}
          defaultField={{ field: 'id', dir: 'asc' }}
          refresh={this.state.refresh}
        >
          {this.getColumns().map((column) => (
            <GridColumn key={column.field} {...column} />
          ))}

          {hasPermission(
            this.props.permissions,
            authZ_Permissions.FileImportAdminUpdate
          ) && <GridColumn cell={this.EditDeleteCommandCell} width={'200px'} />}
        </StatefulGrid>
        <ConfrimDialog
          show={this.state.showDeleteDialog}
          message={'Are you sure you want to delete this record?'}
          onConfirm={this.deleteItem}
          onClose={this.closeDialog}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let fileImportQueue = {
    success: false
  };

  let refresh = {
    update: false
  };

  if (state.fileImportQueues.length > 0) {
    fileImportQueue = lodash.last(state.fileImportQueues);
  }

  if (state.refresh.length > 0) {
    refresh = lodash.last(state.refresh);
  }

  return {
    permissions: state.authReducer.permissions,
    fileImportQueue: fileImportQueue,
    refresh: refresh
  };
}

export default connect(mapStateToProps, {
  executeAuthAsyncGet
})(ImportQueueGrid);
