import { useState, useCallback, ChangeEvent, useEffect } from "react"
import classes from "./DataFieldDropdown.module.scss"
import { Input } from "invevo-react-components"
import { padElementsWith } from "../helpers"
import DataField from "../../types/DataField"
import useClickOutsideRef from "../../hooks/useClickOutsideRef"
import { getBackgroundColourForDataType, getForegroundColourForDataType } from "../../types/DataPrimitive"

type DataFieldDropdownProps = {
    options: DataField[]
    onOptionSelected: (option: DataField | undefined) => void
    selectedOption: DataField | undefined

    className?: string
    ariaLabel?: string
    disabled?: boolean
    showFieldType?: boolean
}

const DataFieldDropdown = ({
    options,
    onOptionSelected,
    selectedOption,

    className,
    ariaLabel = undefined,
    disabled = false,
    showFieldType = false
}: DataFieldDropdownProps) => {
    const [inputValue, setInputValue] = useState<string>(selectedOption?.label || selectedOption?.value || "")
    const [showingOptions, setShowingOptions] = useState(false)
    const ref = useClickOutsideRef(() => {
        setShowingOptions(false)
        setInputValue(selectedOption?.label || selectedOption?.value || "")
    })

    useEffect(() => {
        setInputValue(selectedOption?.label || selectedOption?.value || "")
    }, [setInputValue, selectedOption])

    const onOptionClicked = useCallback(
        (option: DataField) => {
            setInputValue(option.label || "")

            if (selectedOption?.value !== option.value) {
                onOptionSelected(option)
                setShowingOptions(false)
            }
        },
        [onOptionSelected, selectedOption?.value]
    )

    const doesOptionMatchValue = (option: DataField, value: string) =>
        option.value.toLowerCase().includes(value.toLowerCase()) || option.label.toLowerCase().includes(value.toLowerCase())

    const matchedOptions = options
        .filter(option => doesOptionMatchValue(option, inputValue))
        .sort((a, b) => {
            if (a.type === b.type) return a.label > b.label ? 1 : -1
            return a.type > b.type ? 1 : -1
        })

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputString = event.target.value
        setInputValue(inputString)

        if (inputString === "") {
            onOptionSelected(undefined)
        }
    }

    const onInputClick = () => {
        if (disabled) return
        setShowingOptions(true)
    }

    const getMatchedOptionValue = (option: DataField) => {
        const matchingIndex = option.label.toLowerCase().indexOf(inputValue.toLowerCase())
        const firstPart = option.label.substring(0, matchingIndex)
        const matchingPart = option.label.substring(matchingIndex, matchingIndex + inputValue.length)
        const lastPart = option.label.substring(matchingIndex + inputValue.length, option.label.length)

        return (
            <span>
                {firstPart}
                <strong>{matchingPart}</strong>
                {lastPart}
                {` (${option.value})`}
            </span>
        )
    }

    const matchedOptionElements = matchedOptions.map((option, index) => (
        <div key={index} role="listitem" className="d-flex py-2 px-1 pointer align-items-center no-select" onClick={() => onOptionClicked(option)}>
            {showFieldType && (
                <span className={`${classes.label} text-grey px-2 me-1 text-capitalize ${classes[`${option.fieldType?.toLowerCase()}Label`]}`}>
                    {`${option.fieldType?.toLowerCase()}`}
                </span>
            )}
            <span className={`${classes.label} text-white px-2 text-capitalize`} style={{ backgroundColor: getForegroundColourForDataType(option.type) }}>
                {`${option.type.toLowerCase()}`}
            </span>
            <span className="ms-1 d-block flex-grow-1 text-grey text-truncate" data-bs-toggle="tooltip" data-bs-placement="bottom" title={option.label}>
                {getMatchedOptionValue(option)}
            </span>
        </div>
    ))

    return (
        <div ref={ref} className={`d-flex position-relative flex-column ${className ? className : ""}`}>
            <Input
                className={`w-100 ${matchedOptions.length > 0 && showingOptions && classes["input-with-matches"]}`}
                placeholder="Please enter field name"
                value={inputValue}
                onChange={onInputChange}
                onClick={onInputClick}
                color={selectedOption ? getBackgroundColourForDataType(selectedOption.type) : "white"}
                ariaLabel={ariaLabel}
                disabled={disabled}
            />
            <div className="d-flex w-100">
                {matchedOptions.length > 0 && showingOptions && (
                    <div className={`position-absolute w-100 ${classes.options} border text-grey shadow bg-grey ${classes.scrollable}`}>
                        {padElementsWith(matchedOptionElements, "w-100 border-top")}
                    </div>
                )}
            </div>
        </div>
    )
}

export default DataFieldDropdown
