import { IconButton } from "invevo-react-components"
import { ReactNode } from "react"
import ShadowBorder from "../../../../../library/borders/ShadowBorder"
import DataField from "../../../../../types/DataField"
import Lookup from "../../../../../types/Lookup"
import ActionType from "../../../types/actions/ActionType"
import GenericAction from "../../../types/actions/GenericAction"
import LegacySmsTemplate from "../../../types/LegacySmsTemplate"
import ActionInfo from "../../actionInfo/ActionInfo"
import AsyncCommActionComponent from "./AsyncCommActionComponent"
import UpdateDataFieldActionComponent from "./UpdateDataFieldActionComponent"
import Dropdown from "../../../../../library/dropdowns/Dropdown"
import { FileEntityExportConfig } from "../../../../integration/types/fileEntityExport/FileExportConfig"
import ExportAction from "../../../types/actions/ExportAction"
import DropdownOption from "../../../../../types/DropdownOptions"
import UpdateEntityActionComponent from "./UpdateChildEntityActionComponent"
import EntityRelationshipDto from "../../../../entityConfig/types/EntityRelationshipDto"
import WorkflowRule from "../../../types/WorkflowRule"
import EntityConfig from "../../../../entityConfig/types/EntityConfig"

type ActionProps = {
    action: GenericAction
    smsTemplates: LegacySmsTemplate[]
    fields: DataField[]
    lookups: Lookup[]
    fileEntityExportConfigs: FileEntityExportConfig[]
    entityRelationships: EntityRelationshipDto[]
    selectedRule: WorkflowRule
    entityConfigs: EntityConfig[]
    onActionUpdated: (updated: GenericAction) => void
    onActionRemoved: (action: GenericAction) => void
}

const Action = ({
    action,
    smsTemplates,
    fields,
    lookups,
    fileEntityExportConfigs,
    entityRelationships,
    selectedRule,
    entityConfigs,
    onActionUpdated,
    onActionRemoved
}: ActionProps) => {
    const onRemoveClicked = () => onActionRemoved(action)

    const onFileEntityExportConfigSelected = (option: DropdownOption<string>) =>
        onActionUpdated({
            ...action,
            fileEntityExportConfigReference: fileEntityExportConfigs.find(fg => fg.reference === option.value)?.reference
        } as ExportAction)

    switch (action.type) {
        case ActionType.ASYNC_COMM_ACTION:
            return (
                <ActionWrapper key={action.actionReference} action={action} onRemoveClicked={onRemoveClicked}>
                    <AsyncCommActionComponent
                        action={action}
                        onActionUpdated={onActionUpdated}
                        onActionRemoved={onActionRemoved}
                        smsTemplates={smsTemplates}
                        customerFields={fields}
                        lookups={lookups}
                    />
                </ActionWrapper>
            )
        case ActionType.UPDATE_DATA_FIELD:
            return (
                <ActionWrapper key={action.actionReference} action={action} onRemoveClicked={onRemoveClicked}>
                    <UpdateDataFieldActionComponent action={action} onActionUpdated={onActionUpdated} customerFields={fields} lookups={lookups} />
                </ActionWrapper>
            )
        case ActionType.UPDATE_CHILD_ENTITY:
            return (
                <ActionWrapper key={action.actionReference} action={action} onRemoveClicked={onRemoveClicked}>
                    <UpdateEntityActionComponent
                        action={action}
                        onActionUpdated={onActionUpdated}
                        parentEntityFields={fields}
                        lookups={lookups}
                        entityRelationships={entityRelationships}
                        entityConfigs={entityConfigs}
                        selectedRule={selectedRule}
                    />
                </ActionWrapper>
            )
        case ActionType.EXPORT_ACTION: {
            const exportOptions = fileEntityExportConfigs
                .filter(fileconfig => fileconfig.entityType === selectedRule.entityConfigReference)
                .map(fileConfig => ({ label: fileConfig.filename, value: fileConfig.reference }))
            return (
                <ActionWrapper key={action.actionReference} action={action} onRemoveClicked={onRemoveClicked}>
                    {exportOptions.length > 0 ? (
                        <div className="d-flex">
                            <Dropdown
                                options={exportOptions}
                                onOptionSelected={onFileEntityExportConfigSelected}
                                selectedOption={exportOptions.find(o => o.value === action.fileEntityExportConfigReference)}
                            />
                        </div>
                    ) : (
                        <div>No file export configurations found for this entity</div>
                    )}
                </ActionWrapper>
            )
        }
        default:
            return <></>
    }
}

export default Action

type ActionWrapperProps = {
    children: ReactNode
    action: GenericAction
    onRemoveClicked: () => void
}

const ActionWrapper = ({ children, action, onRemoveClicked }: ActionWrapperProps) => (
    <div className="mt-3">
        <ShadowBorder className="d-flex flex-column p-3">
            <div className="d-flex align-items-center mb-3">
                <ActionInfo action={action} smsTemplates={[]} />
                <IconButton className="ms-auto" iconClasses="fal fa-trash" onClick={onRemoveClicked} />
            </div>
            <div>{children}</div>
        </ShadowBorder>
    </div>
)
