import { useState, useEffect } from 'react'
import { Input, ProgressButton, useApi, useClient, CheckboxList, useConfig, Loading } from 'invevo-react-components'

const SmsSenderIdDetails = ({
    customerGroups,
    smsSenderId,
    onSaveSucceded
}) => {
    const api = useApi()
    const client = useClient()
    const config = useConfig()
    
    const [saveDisabled, setSaveDisabled] = useState(false)
    const [selectedCustomerGroups, setSelectedCustomerGroups] = useState([])
    const [senderIdName, setSenderIdName] = useState("")
    const [isSenderIdValid, setIsSenderIdValid] = useState(false)
    const [showSenderIdValidationMessage, setShowSenderIdValidationMessage] = useState(false)
    const [allGroupsSelected, setAllGroupsSelected] = useState(true)

    useEffect(() => {
        setSelectedCustomerGroups(customerGroups.filter(g => g.isActive).map(rg => {
            return {
                id: rg.reference,
                label: rg.name,
                isSelected: smsSenderId && smsSenderId.customerGroups && smsSenderId.customerGroups.includes(rg.reference)
            }
        }))

        if (smsSenderId && smsSenderId.customerGroups) {
            setSenderIdName(smsSenderId.senderId)
            setAllGroupsSelected(smsSenderId.customerGroups.length === customerGroups.length ? true : false)
        }
    }, [customerGroups, smsSenderId])

    useEffect(() => {
        if (senderIdName.length > 1) {
            setIsSenderIdValid(true)
            setShowSenderIdValidationMessage(false)
            setSaveDisabled(false)
        } else {
            setIsSenderIdValid(false)
            setShowSenderIdValidationMessage(true)
            setSaveDisabled(true)
        }
    }, [senderIdName])

    const onSenderIdChange = (event) => {
        setSenderIdName(event.target.value)
    }

    const onCustomerGroupSelected = (checkedGroup) => {
        setSelectedCustomerGroups(selectedCustomerGroups.map(group => {
            if (group.id !== checkedGroup.id) {
                return group
            }

            return {
                ...group,
                isSelected: !group.isSelected
            }
        }))
    }

    const saveSmsSenderIds = () => {
        const promise = new Promise((resolve, reject) => {
            api.put(`${config.CONNECT_API_URL}/api/${client}/sms-sender-id/${smsSenderId.reference}`,
                {
                    senderId: senderIdName,
                    isActive: smsSenderId.isActive,
                    customerGroups:
                        selectedCustomerGroups.filter(rg => allGroupsSelected || rg.isSelected).map(rg => rg.id)
                }

            ).then(_ => {
                resolve()
            })
                .catch(error => {
                    console.error(error)
                    reject()
                })
        })
        return promise
    }

    return (
        <Loading isLoading={!config.CONNECT_API_URL} colour="blue">
            <div className="d-flex flex-column flex-grow-1 px-4">
                <Input className="mb-3" placeholder="Please enter name" label="SMS Sender ID" value={senderIdName ? senderIdName : ""} onChange={onSenderIdChange} />

                {!isSenderIdValid && showSenderIdValidationMessage &&
                    <div className="d-flex text-white my-1 pb-2 align-items-center">
                        <i className="fal fa-exclamation-circle me-2"></i>
                        <span>SMS senderId must be at least 2 characters in length</span>
                    </div>
                }
                {selectedCustomerGroups && selectedCustomerGroups.length > 0 && <div className="d-flex flex-column">
                    <span className="text-light-blue text-uppercase">Select customer groups:</span>
                    <div className="d-flex mt-2 w-100">
                        <CheckboxList
                            className="w-100"
                            items={selectedCustomerGroups}
                            onItemChecked={onCustomerGroupSelected}
                            allOptionEnabled={true}
                            allChecked={allGroupsSelected}
                            onAllChecked={() => setAllGroupsSelected(!allGroupsSelected)} />
                    </div>
                </div>
                }
                <div className="d-flex flex-column pt-4 mt-auto">
                    <ProgressButton
                        className="my-4"
                        iconClasses="fal fa-save"
                        label="Save SMS Sender Ids"
                        colour="blue"
                        onClickWithPromise={() => {
                            const promise = new Promise((resolve, reject) => {
                                saveSmsSenderIds()
                                    .then(_ => {
                                        onSaveSucceded({ ...smsSenderId, senderId: senderIdName, customerGroups: selectedCustomerGroups })
                                        resolve()
                                    },
                                        _ => reject())
                                    .catch(_ => reject())
                            })
                            return promise
                        }}
                        succeededText={`Saved successfully!`}
                        failedText={`Failed to save!`}
                        disabled={saveDisabled}
                    />
                </div>
            </div>
        </Loading>
    )
}

export default SmsSenderIdDetails