import classes from "./ContactCard.module.scss"

type ContactCardProps = {
    name: string
    phoneNumber: string
    contactId?: string
    contactType?: string
    contactRole?: string
    onContactSelected: (phoneNumber: string, contactId?: string) => void
}

const ContactCard = ({ name, phoneNumber, contactId, contactType, contactRole, onContactSelected }: ContactCardProps) => {
    const selectContact = () => onContactSelected(phoneNumber, contactId)

    return (
        <div className={`d-flex flex-column mb-4 ${classes.container}`}>
            <div className="d-flex flex-row justify-content-between mt-2">
                <span className="fs-6 ms-2">{name}</span>
                {contactType && <span className="me-2">{contactType}</span>}
            </div>
            {contactRole && <span className="fs-6 ms-2 mb-3 text-grey">{contactRole}</span>}

            <div className="d-flex" role="button" onClick={selectContact}>
                <div className={`d-flex mx-2 mb-2 w-100 ${classes.button} pointer no-select align-items-center`}>
                    <div className={`d-flex ${classes.icon} h-100`}>
                        <i className={`m-auto fa-solid fa-arrow-right px-2`}></i>
                    </div>
                    <div className={`d-flex ${classes.label} w-100 h-100 align-items-center py-1`}>
                        <span className="fw-normal mx-2 mb-0">{phoneNumber}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactCard
