import React from 'react';
import DatePicker from 'react-datepicker';
import LookupList from './lookupList';
import TextInput from './textInput';
import GuidInput from './guidInput';
import NumberInput from './numberInput';
import FilterList from './filterList';
import CustomSwitch from '../buttons/customSwitch';

const GenericInput = ({
  key,
  value,
  change,
  tabIndex,
  multi,
  list,
  type,
  maxLength,
  nullable,
  parameters
}) => {
  switch (type) {
    case 'date':
      return (
        <DatePicker
          selected={value}
          onChange={change}
          dateFormat="dd/MM/yyyy"
          tabIndex={tabIndex}
        />
      );
    case 'list':
      return (
        <LookupList
          value={value}
          change={change}
          multi={multi}
          list={list}
          nullable={nullable}
          parameters={parameters}
          tabIndex={tabIndex}
        />
      );
    case 'filterList':
      return (
        <FilterList
          value={value}
          change={change}
          multi={multi}
          list={list}
          nullable={nullable}
          parameters={parameters}
          tabIndex={tabIndex}
        />
      );
    case 'bool':
      return (
        <CustomSwitch
          id={`${key}.switch`}
          checked={value}
          onChange={change}
          type="yesno"
          tabIndex={tabIndex}
        />
      );
    case 'money':
      return (
        <NumberInput
          value={value}
          change={change}
          maxLength={maxLength}
          tabIndex={tabIndex}
        />
      );
    case 'guid':
      return (
        <GuidInput
          nullable={nullable}
          value={value}
          change={change}
          tabIndex={tabIndex}
        />
      );
    default:
      return (
        <TextInput
          nullable={nullable}
          value={value}
          change={change}
          maxLength={maxLength}
          tabIndex={tabIndex}
        />
      );
  }
};

export default GenericInput;
