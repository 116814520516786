import moment from "moment/moment"
import { DataTypeEnum } from "../../routes/entityConfig/types/DataType"
import EntityDataField from "../../routes/entityConfig/types/EntityDataField"
import DataField from "../../types/DataField"
import DataPrimitive from "../../types/DataPrimitive"
import FieldType from "../../types/enums/FieldType"
import Lookup from "../../types/Lookup"
import HeaderDefinition from "../grid/types/HeaderDefinition"
import RowData from "../grid/types/RowData"
import { getValueForHeader } from "../grid/util"
import { DEFAULT_DATE_FORMAT, DEFAULT_TIMEZONE } from "./dateUtils"

export const getDataFieldsFromEntityDataFields = (entityDataFields: EntityDataField[]): DataField[] =>
    entityDataFields.map(field => ({
        value: field.fieldName,
        label: field.displayName,
        type: toDataPrimitive(field.dataType.type),
        lookup: field.dataType.type === DataTypeEnum.LOOKUP ? field.dataType.lookupReference : undefined,
        isQuickFilter: field.isQuickFilter,
        fieldType: FieldType.ENTITY
    }))

export const toDataPrimitive = (dataType: DataTypeEnum) => {
    switch (dataType) {
        case DataTypeEnum.TEXT:
        case DataTypeEnum.EMAIL:
        case DataTypeEnum.PHONE_NUMBER:
        case DataTypeEnum.RICH_TEXT:
            return DataPrimitive.TEXT
        case DataTypeEnum.MONEY:
        case DataTypeEnum.INT:
        case DataTypeEnum.DECIMAL:
        case DataTypeEnum.PERCENTAGE:
            return DataPrimitive.NUMBER
        case DataTypeEnum.DATE:
            return DataPrimitive.DATE
        case DataTypeEnum.BOOLEAN:
            return DataPrimitive.BOOLEAN
        case DataTypeEnum.LOOKUP:
            return DataPrimitive.LOOKUP
    }
}

export const convertToCSV = (data: RowData[], headers: HeaderDefinition[], lookups: Lookup[]) => {
    if (data.length === 0) {
        return ""
    }
    const headerLabels = headers.map(header => header.label)
    const csvRows = []
    csvRows.push(["reference", ...headerLabels])

    data.forEach((row: RowData) => {
        const convertedCsvRow: [string | number | boolean | undefined] = [row.reference]

        headers.forEach(header => {
            const value = getValueForHeader(row, header)
            if (header.displayType === DataTypeEnum.DATE && value) {
                const timestamp = Number(value)
                const betterFormattedDate = moment.tz(moment(timestamp), header.timezone ?? DEFAULT_TIMEZONE).format(header.format ?? DEFAULT_DATE_FORMAT)
                convertedCsvRow.push(betterFormattedDate)
            }
            else if (header.displayType === DataTypeEnum.LOOKUP && value) {
                const lookup = lookups.find(l => l.reference === header.lookupReference)
                const lookupEntry = lookup?.entries.find(e => e.reference === value.toString().toLowerCase())
                convertedCsvRow.push(typeof lookupEntry?.name === "string" ? sanitizeValueIfRequired( lookupEntry.name) : "") 
            } else {
                convertedCsvRow.push(typeof value === "string" ? sanitizeValueIfRequired(value) : value)
            }
        })

        csvRows.push(convertedCsvRow)
    })

    return csvRows.map(row => row.join(",")).join("\n")
}

const sanitizeValueIfRequired = (value: string) => {
    let result = value
    if (result.includes(",")) {
        result = `"${result}"`
    }
    if (result.includes("\n")) {
        result = result.replace(/\n/g, " ")
    }
    return result 
}

export const getTypeFromEntityDataTypeEnum = (type: DataTypeEnum | DataPrimitive) => {
    switch (type) {
        case DataTypeEnum.DATE:
            return DataPrimitive.DATE
        case DataTypeEnum.MONEY:
        case DataTypeEnum.INT:
        case DataTypeEnum.DECIMAL:
        case "NUMBER":
            return DataPrimitive.NUMBER
        case DataTypeEnum.LOOKUP:
            return DataPrimitive.LOOKUP
        case DataTypeEnum.BOOLEAN:
            return DataPrimitive.BOOLEAN
        case DataTypeEnum.TEXT:
        case DataTypeEnum.EMAIL:
        case DataTypeEnum.PHONE_NUMBER:
        default:
            return DataPrimitive.TEXT
    }
}
