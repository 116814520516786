import React, { createContext, useReducer } from 'react';
import { typeIds } from '../components/repaymentPlan/repaymentConstants';
import {
  createFromExisting,
  setSourceInvoices,
  initaliseRepaymentPlan,
  setInterest,
  setStartDate,
  setFormParameters
} from '../components/repaymentPlan/repaymentPlanCalculator';
import {
  termToInstalments,
  paymentToInstalments
} from '../components/repaymentPlan/repaymentPlanUtility';

const RepaymentPlanContext = createContext();

const { Provider } = RepaymentPlanContext;

function createPlanFromParams(
  {
    amount,
    interestRate,
    type,
    term,
    instalments,
    paymentsAmount,
    startDate,
    frequencyId,
    firstPayment,
    paymentDayId,
    lastPayment
  },
  repaymentPlan
) {
  let normalisedInstalments;
  switch (type) {
    case typeIds.instalments:
      normalisedInstalments = instalments;
      break;
    case typeIds.payments:
      normalisedInstalments = paymentToInstalments(amount, paymentsAmount);
      break;
    default:
      normalisedInstalments = termToInstalments(frequencyId, startDate, term);
      break;
  }

  return setFormParameters(
    interestRate,
    frequencyId,
    startDate,
    paymentDayId,
    normalisedInstalments,
    firstPayment,
    lastPayment,
    repaymentPlan
  );
}

function RepaymentPlanProvider({ children }) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'CREATE_FROM_EXISTING':
          return {
            ...state,
            repaymentPlan: createFromExisting(action.repaymentPlan),
            status: 'existing'
          };
        case 'UPDATE_INVOICES':
          return {
            ...state,
            repaymentPlan: setSourceInvoices(
              action.invoices,
              state.repaymentPlan
            ),
            status: 'new'
          };
        case 'SET_INTEREST':
          return {
            ...state,
            repaymentPlan: setInterest(action.interest, state.repaymentPlan),
            status: 'new'
          };

        case 'SET_START_DATE':
          return {
            ...state,
            repaymentPlan: setStartDate(action.startDate, state.repaymentPlan),
            status: 'new'
          };
        case 'CREATE':
          return {
            ...state,
            repaymentPlan: createPlanFromParams(
              action.params,
              state.repaymentPlan
            ),
            status: 'new'
          };

        case 'INIT_PLAN':
          return {
            repaymentPlan: initaliseRepaymentPlan(),
            status: 'idle'
          };
        default:
          return state;
      }
    },
    {
      repaymentPlan: initaliseRepaymentPlan(),
      status: 'idle'
    }
  );

  const value = [state, dispatch];
  return <Provider value={value}>{children}</Provider>;
}

function useRepaymentPlan() {
  const context = React.useContext(RepaymentPlanContext);
  if (context === undefined) {
    throw new Error(
      `useRepaymentPlan must be used within a RepaymentPlanProvider`
    );
  }
  return context;
}

function createPlan(dispatch, params) {
  return dispatch({ type: 'CREATE', params });
}

function createPlanFromExisting(dispatch, repaymentPlan) {
  return dispatch({ type: 'CREATE_FROM_EXISTING', repaymentPlan });
}

function initPlanFromInvoices(dispatch, invoices) {
  return dispatch({ type: 'INIT_FROM_INVOICES', invoices });
}

function updatePlanInvoices(dispatch, invoices) {
  return dispatch({ type: 'UPDATE_INVOICES', invoices });
}

function setPlanInterest(dispatch, interest) {
  return dispatch({ type: 'SET_INTEREST', interest });
}

function setPlanStartDate(dispatch, startDate) {
  return dispatch({ type: 'SET_START_DATE', startDate });
}

function initPlan(dispatch) {
  return dispatch({ type: 'INIT_PLAN' });
}

export {
  RepaymentPlanProvider,
  useRepaymentPlan,
  createPlanFromExisting,
  initPlanFromInvoices,
  updatePlanInvoices,
  setPlanInterest,
  setPlanStartDate,
  createPlan,
  initPlan
};
