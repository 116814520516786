import { Loading, useClient, useConfig } from "invevo-react-components"
import { useEffect, useReducer, useState } from "react"
import useApiQuery from "../../../../hooks/useApiQuery"
import FileExportConfigReducer, { FileExportConfigActionEnum } from "../../reducers/FileExportConfigReducer"
import { mapDataFieldsDtoToDomain, mapDomainToPutFileExportConfigDto, mapGetFileExportConfigDtoToDomain } from "../utils"
import { DataFieldDto } from "../../../../types/dtos/DataFieldDto"
import { GetFileExportConfigDto } from "../../types/dtos/FileExportConfigDto"
import { FileExportConfig } from "../../types/FileExportConfig"
import { PutFileImportConfigDto } from "../../types/dtos/FileImportConfigDto"
import classes from "./FileExportConfiguration.module.scss"
import ProgressButton from "../../../../library/buttons/ProgressButton/ProgressButton"
import FileExportConfigSetup from "./fileExportConfigSetup/FileExportConfigSetup"
import FileExportConfigs from "./fileExportConfigs/FileExportConfigs"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"

const FileExportConfiguration = () => {
    const [state, dispatch] = useReducer(FileExportConfigReducer, {
        configs: [],
        dataFields: [],
        editingConfig: undefined
    })

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

    const client = useClient()
    const config = useConfig()

    const fileExportConfigRequest = useApiQuery<GetFileExportConfigDto[]>({
        url: `${config.INTEGRATION_API_URL}/api/${client}/file-export-config`,
        method: "get",
        onSuccess: data =>
            dispatch({
                type: FileExportConfigActionEnum.FILE_EXPORT_CONFIGS_RETRIEVED,
                configs: mapGetFileExportConfigDtoToDomain(data)
            })
    })

    const dataFieldsRequest = useApiQuery({
        url: `${config.DATA_API_URL}/api/${client}/data-field`,
        method: "get",
        onSuccess: (data: DataFieldDto[]) =>
            dispatch({
                type: FileExportConfigActionEnum.DATA_FIELDS_RETRIEVED,
                dataFields: mapDataFieldsDtoToDomain(data)
            })
    })

    const saveFileExportConfigRequest = useApiQuery<PutFileImportConfigDto>({
        url: `${config.INTEGRATION_API_URL}/api/${client}/file-export-config`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    useEffect(() => {
        const originalConfig = state.configs.find(config => config.reference === state.editingConfig?.reference)
        if (!originalConfig) {
            setHasUnsavedChanges(false)
            return
        }

        const hasChanges = JSON.stringify(originalConfig) !== JSON.stringify(state.editingConfig)
        setHasUnsavedChanges(hasChanges)
    }, [state.configs, state.editingConfig])

    const onDiscardChangesClick = () => {
        dispatch({
            type: FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_DISCARDED
        })
    }
    const onSaveChangesClick = () => {
        const dto = mapDomainToPutFileExportConfigDto(state.editingConfig as FileExportConfig)
        return saveFileExportConfigRequest.execute(state.editingConfig?.reference, dto).then(res => {
            dispatch({
                type: FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_SAVED
            })

            return res
        })
    }

    return (
        <div className="d-flex w-100 h-100">
            <Loading isLoading={fileExportConfigRequest.isFetching || dataFieldsRequest.isFetching} colour="blue">
                <FileExportConfigs state={state} dispatch={dispatch} />
                <div className="d-flex flex-column flex-grow-1">
                    <div className="flex-grow-1 p-4 overflow-auto">
                        {state.editingConfig ? (
                            <FileExportConfigSetup state={{ editingConfig: state.editingConfig, dataFields: state.dataFields }} dispatch={dispatch} />
                        ) : (
                            <span className="text-grey fs-4">Please select a configuration for editing or create a new one</span>
                        )}
                    </div>
                    {state.editingConfig && (
                        <div className={`d-flex bg-white p-4 mt-auto ${classes.footer} align-items-center border-top border-2`}>
                            <StandardButton
                                className="ms-auto"
                                iconClasses="far fa-undo"
                                label="Discard changes"
                                onClick={onDiscardChangesClick}
                                disabled={!hasUnsavedChanges}
                            />
                            <ProgressButton
                                className="ms-3"
                                iconClasses="far fa-save"
                                label="Save changes"
                                onClickWithPromise={onSaveChangesClick}
                                disabled={!hasUnsavedChanges}
                            />
                        </div>
                    )}
                </div>
            </Loading>
        </div>
    )
}

export default FileExportConfiguration
