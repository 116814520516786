import { DragEvent, useState } from "react"
import NodeOption from "./NodeOption"
import FlowNodeType from "../../types/FlowNodeType"
import Input from "../../../../library/Inputs/Input"
import classes from "./NodeSelection.module.scss"
import { Node } from "reactflow"
import NodeState from "../../../../library/flow/types/NodeState"
import FlowSculptorNode from "../../types/FlowSculptorNode"

type NodeSelectionProps = {
    flowName: string
    nodes: Node<NodeState<FlowSculptorNode>>[]
    onFlowNameChanged: (newName: string) => void
}

const NodeSelection = ({ flowName, nodes, onFlowNameChanged }: NodeSelectionProps) => {
    const [isEditingName, setIsEditingName] = useState(false)
    const [updatedFlowName, setUpdatedFlowName] = useState<string>(flowName)
    const onNodeDragStart = (event: DragEvent<HTMLDivElement>, nodeType: FlowNodeType) => {
        event.dataTransfer.setData("nodeType", JSON.stringify(nodeType))
        event.dataTransfer.effectAllowed = "move"
    }

    const onEditStart = () => setIsEditingName(true)
    const onEditEnd = () => {
        setIsEditingName(false)
        onFlowNameChanged(updatedFlowName)
    }

    const hasStartNode = nodes.some(node => node.data.state.nodeType === "entity created")

    const allNodeOptions: FlowNodeType[] = hasStartNode ? nodeOptions : ["entity created", ...nodeOptions]

    return (
        <div className="d-flex flex-column gap-2">
            {isEditingName ? (
                <form onSubmit={onEditEnd} className={`d-flex rounded bg-grey p-2 mb-2 align-items-center justify-content-between ${classes.flowName}`}>
                    <Input onChange={setUpdatedFlowName} value={updatedFlowName} />
                    <div role="button" className="ms-auto px-2" onClick={onEditEnd} aria-label="flow-name-confirm">
                        <i className="fal fa-check" />
                    </div>
                </form>
            ) : (
                <div className={`d-flex rounded bg-grey p-2 mb-2 align-items-center justify-content-between ${classes.flowName}`}>
                    <span className="ms-3 fw-bold">{flowName}</span>
                    <div role="button" className="ms-auto px-2" onClick={onEditStart} aria-label="flow-name-edit">
                        <i className="fal fa-pencil" />
                    </div>
                </div>
            )}
            {allNodeOptions.map((nodeOption, index) => (
                <NodeOption key={index} nodeType={nodeOption} onNodeDragStart={onNodeDragStart} />
            ))}
        </div>
    )
}

export default NodeSelection

const nodeOptions: FlowNodeType[] = [
    "wait for condition",
    "filters",
    "send email action",
    "send sms action",
    "send post action",
    "update fields action",
    "export action",
    "outbound ivr action",
    "fetch external data action",
    "undefined process"
]
