import { IconButton, Loading, useApi, useClient, useConfig } from "invevo-react-components"
import moment from "moment"
import { useState } from "react"
import { useCustomer } from "../../../../../../contexts/CustomerContext"
import ColumnConfigButton from "../../ColumnConfigButton"
import classes from "./GlobalActions.module.scss"

const GlobalActions = ({
    selectedTransactions,
    getTransactionsForExportDto,
    transactionFields,
    columnConfig,
    clientUserDashboardConfigs,
    dispatch
}) => {
    const api = useApi()
    const client = useClient()
    const config = useConfig()
    const [customer] = useCustomer()

    const [isDownloading, setIsDownloading] = useState(false)
    const [isExporting, setIsExporting] = useState(false)

    const onDownloadClicked = () => {
        if (isDownloading || selectedTransactions.length === 0 || selectedTransactions.length > 10) {
            return
        }

        setIsDownloading(true)
        api.post(`${config.DASHBOARD_API_URL}/api/${client}/legacy-documents-for-transactions`, {
            transactionRefs: [...selectedTransactions.map(t => t.transactionRef)],
            customerId: customer.sqlId
        }, {
            responseType: 'blob'
        })
            .then(response => {
                if (response.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement("a")
                    let filename = `${customer.reference}_transactions.pdf`
                    const disposition = response.headers['content-disposition']
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        const filenameRegex = /filename[^=\n]*=((['"]).*?\2|[^\n]*)/
                        const matches = filenameRegex.exec(disposition)
                        if (matches !== null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '')
                        }
                    }

                    link.href = url
                    link.setAttribute(
                        "download",
                        filename
                    )
                    document.body.appendChild(link)
                    link.click()

                    link.parentNode.removeChild(link)
                }
                setIsDownloading(false)
            }).catch(error => {
                console.error(error)
            })
    }

    const onExportClicked = () => {
        if (isExporting) {
            return
        }

        setIsExporting(true)
        api.post(`${config.SEARCH_API_URL}/api/${client}/transactions-export-to-csv`, getTransactionsForExportDto())
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement("a")
                link.href = url
                link.setAttribute(
                    "download",
                    `TransactionExport_Customer_${customer.reference}_${moment().format("YYYY-MM-DD_HHmmss")}.csv`
                )
                document.body.appendChild(link)
                link.click()

                link.parentNode.removeChild(link)
                setIsExporting(false)
            }).catch(error => {
                console.error(error)
            })
    }

    return (
        <div className={`d-flex justify-content-end p-2 pe-3`}>
            <div className="ms-auto d-flex align-items-center">
                <Loading isLoading={isExporting} size="small" colour="white">
                    <IconButton
                        buttonClasses={classes.actionsButton}
                        colour="blue"
                        iconClasses="fa-light fa-file-csv"
                        onClick={onExportClicked}
                    />
                </Loading>
                <Loading isLoading={isDownloading} size="small" colour="white">
                    <IconButton
                        buttonClasses={classes.actionsButton}
                        colour="blue"
                        className="ms-2"
                        iconClasses="fa-light fa-file-arrow-down" onClick={onDownloadClicked}
                        disabled={customer.selectedTransactions.length === 0}
                        ariaLabel="download-transaction-document"
                    />
                </Loading>
                <ColumnConfigButton
                    className="ms-2"
                    state={{ transactionFields, columnConfig, clientUserDashboardConfigs }}
                    dispatch={dispatch}
                    buttonClasses={classes.actionsButton}
                />
            </div>
        </div>
    )
}

export default GlobalActions