import { useState, useEffect } from 'react'
import SelectPaymentCard from './SelectPaymentCard'
import PreviewPayment from './PreviewPayment'
import PaymentProvider from './PaymentProvider'

const PaymentSelection = ({ customerId, isCustomerVirtualAccount, cardProviders, transactions }) => {
    const [selectedPaymentCard, setSelectedPaymentCard] = useState()
    const [selectedPaymentProvider, setSelectedPaymentProvider] = useState()
    const [totalAmounts, setTotalAmounts] = useState([])
    const [paymentCards, setPaymentCards] = useState([])

    useEffect(() => {
        setPaymentCards(cardProviders.map(cc => {
            const totalAmount = totalAmounts.find(ta => ta.currency === cc.currencyCode)
            if (totalAmount) {
                return <SelectPaymentCard key={`${cc.cardId}${cc.currencyCode}`}
                    brand={cc.brand}
                    amount={totalAmounts.find(ta => ta.currency === cc.currencyCode)}
                    onPaymentCardSelected={(amount) => setSelectedPaymentCard({ amount, ...cc, })} />
            }
            return ''
        })
            .filter(e => e !== ''))
    }, [cardProviders, totalAmounts])

    useEffect(() => {
        const invoicesGroupedByCurrency = groupByKey(transactions, 'currencyCode')
        const totalAmounts = []
        for (const currencyCodeKey in invoicesGroupedByCurrency) {
            const invoices = invoicesGroupedByCurrency[currencyCodeKey]
            totalAmounts.push({
                currency: currencyCodeKey,
                invoices: [...invoices],
                total: invoices.map(i => i.amountDue).reduce((total, i) => total + i)
            })
        }
        setTotalAmounts(totalAmounts)
    }, [transactions])

    const continueToPaymentProvider = (charges, invoicesToPay, selectedPaymentCard) => {
        setSelectedPaymentCard();
        setSelectedPaymentProvider({ charges, invoicesToPay, selectedPaymentCard })
    }

    return (
        selectedPaymentCard ?
            <PreviewPayment
                customerId={customerId}
                selectedPaymentCard={selectedPaymentCard}
                onBackClicked={() => setSelectedPaymentCard()}
                onPaymentProviderSelected={continueToPaymentProvider} />
            : selectedPaymentProvider ?
                <PaymentProvider
                    charges={selectedPaymentProvider.charges}
                    invoices={selectedPaymentProvider.invoicesToPay}
                    paymentCard={selectedPaymentProvider.selectedPaymentCard} />
                :
                <div className="text-white flex-grow-1 overflow-auto">
                    <div className="d-flex flex-column py-4">
                        {
                            paymentCards.length === 0 ? <></> : paymentCards
                                .reduce((prev, curr) => [prev, <div key={`${prev.key}${curr.key}`} className="my-2" />, curr])
                        }
                    </div>
                </div>
    )
}
const groupByKey = (list, key) => list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {})

export default PaymentSelection