import { getApiUrl } from '../constants';
import { webApiInterface } from './webApiInterface';

export function getFileImportLocation(client, fileImportLocationId, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/location?importLocationId=' + fileImportLocationId;

    return webApiInterface.authFetch(client, url, dispatch)
        .then(function (response) {
            return response.json();
        });
}

export function updateFileImportLocation(client, fileImportLocation, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/location/update';

    return webApiInterface.authPost(client, url, dispatch, fileImportLocation, 'POST');
}

export function createFileImportLocation(client, fileImportLocation, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/location/create';

    return webApiInterface.authPost(client, url, dispatch, fileImportLocation, 'POST');
}

export function deleteFileImportLocation(client, fileImportLocationId, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/location/delete';

    return webApiInterface.authDelete(client, url, dispatch, fileImportLocationId);
}