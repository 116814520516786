import React, { Component } from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import GenericInput from '../common/genericInput';
import {
  registerForIntl,
  provideIntlService
} from '@progress/kendo-react-intl';
import { IconButton } from '../common/buttons/iconButton';
import { classNames } from '@progress/kendo-react-common';
import CustomSwitch from '../common/buttons/customSwitch';
import displayError from '../../utility/error';

class AccountValue extends Component {
  state = {
    id: null,
    editable: false,
    editing: false,
    name: null,
    display: null,
    value: null,
    type: null,
    list: null,
    nullable: null,
    maxLength: null,
    editedValue: null,
    editedDisplay: null,
    oldEditedValue: null,
    parameters: null
  };

  componentDidMount() {
    this.setState({
      id: this.props.detail.id,
      editable: this.props.detail.edit && !this.props.intiForceReadonly,
      name: this.props.detail.title,
      display: this.props.detail.displayValue,
      value: this.props.detail.value,
      type: this.props.detail.type,
      list: this.props.detail.list,
      multi: this.props.detail.multi,
      nullable: this.props.detail.nullable,
      maxLength:
        this.props.detail.maxLength > 0
          ? this.props.detail.maxLength
          : undefined,
      parameters: this.props.detail.parameters
    });
  }

  componentDidUpdate(prevProps) {
    const prevDetail = prevProps.detail;
    const currDetail = this.props.detail;

    const hasChanged =
      currDetail.value !== prevDetail.value ||
      currDetail.displayValue !== prevDetail.displayValue ||
      currDetail.parameters !== prevDetail.parameters;

    if (hasChanged) {
      this.setState({
        value: this.props.detail.value,
        display: this.props.detail.displayValue,
        parameters: this.props.detail.parameters
      });
    }
  }

  edit = () => {
    let editedValue = this.state.value;

    if (this.state.type === 'number' || this.state.type === 'money') {
      editedValue = this.convertNumberToString(this.state.value);
    }

    this.setState({
      editing: true,
      editedValue: editedValue,
      editedDisplay: this.state.display,
      oldEditedValue: this.state.display
    });
  };

  cancel = () => {
    this.setState({ editing: false, editedValue: null });
  };

  save = () => {
    this.setState({ editing: false });

    let editedValue = this.state.editedValue;
    if (Array.isArray(this.state.editedValue)) {
      editedValue = map(this.state.editedValue, (m) => m.value).join(',');
    } else if (this.state.editedValue && this.state.editedValue.value) {
      editedValue = this.state.editedValue.value;
    }
    const { updateUi } = this.props;
    if (updateUi) {
      updateUi(this.state.editedValue);
    }
    this.props
      .save(
        this.props.selectedAccount.miaAccountId,
        this.state.id,
        editedValue,
        this.state.editedDisplay
      )
      .then((result) => {
        this.setState({ display: result.value, value: this.state.editedValue });
      })
      .catch(() => {
        displayError(`Oops - there's been a problem saving`);
        //rollback ui
        if (updateUi) {
          updateUi(this.state.oldEditedValue);
        }
      });
  };

  change = (option) => {
    if (Array.isArray(option)) {
      const descriptions = map(option, (m) => m.label).join(',');

      this.setState({ editedValue: option, editedDisplay: descriptions });
    } else if (option && option.value) {
      this.setState({
        editedValue: option,
        editedDisplay: option.label
      });
    } else {
      this.setState({ editedValue: option, editedDisplay: option });
    }
  };

  onBoolChange = (value) => {
    this.setState(
      {
        editedValue: value,
        editedDisplay: `${value}`
      },
      () => {
        this.save();
      }
    );
  };

  convertNumberToString = (value) => {
    const number = parseFloat(value);
    let result;

    if (value === null || value === '') {
      result = '';
    } else if (isNaN(number)) {
      result = String(value);
    } else {
      // No more than 2 dp.
      result = String(Math.round(number * 100) / 100);
    }

    return result;
  };

  isValid = () => {
    if (!this.state.nullable && !this.state.editedValue) {
      return false;
    }

    // eslint-disable-next-line no-useless-escape
    const re = /^-?[0-9]+([\.,\,][0-9]{1,2})?$/;

    if (
      this.state.type === 'money' &&
      this.state.editedValue &&
      !String(this.state.editedValue).match(re)
    ) {
      return false;
    }

    return true;
  };

  renderInput() {
    const {
      type,
      editedValue,
      multi,
      list,
      nullable,
      parameters,
      maxLength
    } = this.state;

    const onChange = type === 'bool' ? this.onBoolChange : this.change;

    const input = (
      <GenericInput
        type={type}
        value={editedValue}
        multi={multi}
        maxLength={maxLength}
        change={onChange}
        list={list}
        nullable={nullable}
        parameters={parameters}
        tabIndex={2}
      />
    );

    switch (type) {
      case 'list':
        return (
          <div className="w-100" style={{ overflow: 'visible' }}>
            {input}
          </div>
        );
      case 'bool':
        return input;
      default:
        return <div className="w-100">{input}</div>;
    }
  }

  renderDisplay() {
    const { type, name, id, value, display } = this.state;

    switch (type) {
      case 'date': {
        let displayValue;

        if (!!value) {
          displayValue = provideIntlService(this).formatDate(
            new Date(value),
            this.props.dateFormat
          );
        } else {
          displayValue = '';
        }

        return <div>{displayValue}</div>;
      }
      case 'bool': {
        return (
          <CustomSwitch
            checked={value}
            onChange={this.onBoolChange}
            id={`${name}.${id}.displaySwitch`}
            type="yesno"
          />
        );

        // let displayValue;

        // if (typeof this.state.value === typeof true) {
        //   if (this.state.value) {
        //     displayValue = 'Yes';
        //   } else {
        //     displayValue = 'No';
        //   }
        // } else {
        //   if (!this.state.value) {
        //     displayValue = '';
        //   } else if (
        //     this.state.value.toLowerCase &&
        //     this.state.value.toLowerCase() === 'true'
        //   ) {
        //     displayValue = 'Yes';
        //   } else {
        //     displayValue = 'No';
        //   }
        // }

        // return <div>{displayValue}</div>;
      }
      case 'money': {
        let displayValue = '';

        if (!!value) {
          displayValue =
            this.props.globalCurrencyCode +
            ' ' +
            provideIntlService(this).formatDate(
              parseFloat(value),
              this.props.currencyFormat
            );
        }

        return <div>{displayValue}</div>;
      }
      default:
        return <div className="text-break text-right">{display || value}</div>;
    }
  }

  render() {
    const { editing, type, name, id, value, editable } = this.state;
    const key = `${name}-${id}`;
    let buttons = null;
    let valueJsx;

    if (editing) {
      buttons = editable ? (
        <>
          <IconButton
            isDisabled={!this.isValid()}
            onClick={this.save}
            title={'Save'}
            icon={'check'}
            tabIndex={1}
          />
          <IconButton
            onClick={this.cancel}
            title={'Cancel'}
            icon={'times'}
            tabIndex={3}
          />
        </>
      ) : null;
      valueJsx = this.renderInput();
    } else if (type === 'bool') {
      buttons = null;
      valueJsx = (
        <CustomSwitch
          checked={value}
          disabled={!editable}
          onChange={this.onBoolChange}
          id={`${key}.displaySwitch`}
          type="yesno"
        />
      );
    } else {
      buttons = editable ? (
        <IconButton onClick={this.edit} title={'Edit'} icon={'edit'} />
      ) : null;
      valueJsx = this.renderDisplay();
    }

    const valueClasses = classNames('flex-grow-1 my-1', {
      'w-100': type !== 'bool',
      'w-50': type === 'bool'
    });

    return editing ? (
      <div className="d-flex flex-wrap justify-content-between" key={key}>
        <div className={valueClasses}>{name}</div>
        <div className="flex-grow-1">{valueJsx}</div>
        {buttons && <div className="ml-2">{buttons}</div>}
      </div>
    ) : (
      <div className="d-flex justify-content-between" key={key}>
        <div className="flex-wrap my-1">{name}</div>
        <div className="flex-wrap ml-2 my-1">{valueJsx}</div>
        {buttons && (
          <div className="ml-2 flex-shrink-0 flex-grow-0">{buttons}</div>
        )}
      </div>
    );
  }

  static defaultProps = {
    intiForceReadonly: false
  };
}

const mapStateToProps = (state) => {
  return {
    selectedAccount: state.currentSelectionReducer.selectedAccount,
    currencyFormat: state.lookupReducer.globalSettings.results.currencyFormat,
    dateFormat: state.lookupReducer.globalSettings.results.dateFormat,
    globalCurrencyCode:
      state.lookupReducer.globalSettings.results.globalCurrencyCode
  };
};

registerForIntl(AccountValue);

export default connect(mapStateToProps, {})(AccountValue);
