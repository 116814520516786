import { Toggle } from 'invevo-react-components'

type BooleanInfoElementProps = {
    fieldName: string
    status: boolean
    disabled: boolean
    onChange: (value: string) => void
}

const BooleanInfoElement = ({ fieldName, status, disabled, onChange }: BooleanInfoElementProps) => {
    const onToggleValueChange = () => onChange(status ? "false" : "true")

    return (
        <div className="fs-4">
            <Toggle
                status={status}
                ariaLabel={`${fieldName}-toggle`}
                onStatusChanged={onToggleValueChange}
                disabled={disabled}
            />
        </div>
    )
}

export default BooleanInfoElement
