import { isArray, forEach, forOwn, includes, isNil, isObject } from 'lodash'

export default function buildParameterString(parameters, enableKendoAware = false, includeNilParams = false) {
  const result = [];

  forOwn(parameters, (value, key) => {
    const nullEmptyArrayValue = (isArray(value) && value.length === 0) || isNil(value)
      ? null
      : value;

    if (nullEmptyArrayValue !== null || includeNilParams) {
      const propertyName = encodeURIComponent(key),
        isKendoProp = enableKendoAware && isKendoGridProp(propertyName);

      if (isArray(nullEmptyArrayValue)) {
        forEach(nullEmptyArrayValue, (element) => {
          const pair = makeUriPair(propertyName, isNil(element) ? '' : element);

          isKendoProp
            ? result.unshift(pair)
            : result.push(pair);
        });
      } else if (isObject(nullEmptyArrayValue)) {
        forOwn(nullEmptyArrayValue, (nestedValue, nestedProp) => {
          const pair = makeUriPair(propertyName + '.' + nestedProp, nestedValue);

          isKendoProp
            ? result.unshift(pair)
            : result.push(pair);
        });
      } else {
        const urlFriendlyValue = isNil(nullEmptyArrayValue)
          ? ''
          : nullEmptyArrayValue;

        const pair = makeUriPair(propertyName, urlFriendlyValue);

        isKendoProp
          ? result.unshift(pair)
          : result.push(pair);
      }
    }
  });

  return result.join('&');
}

const makeUriPair = (propName, propValue) => {
  return propName + '=' + encodeURIComponent(propValue);
};

const isKendoGridProp = (propName) => {
  return includes(['page', 'pageSize', 'sort', 'filter', 'group'], propName);
};
