import GenericFilter from "../../../library/FilterList/filterTypes/GenericFilter"
import GenericAction, { convertFromActionsArrayToDto, convertFromActionsDtoToArray } from "./actions/GenericAction"
import { convertFromArrayToDto, convertFromDtoToArray } from "../../../library/FilterList/FiltersDto"
import Lookup from "../../../types/Lookup"
import DataField from "../../../types/DataField"
import PutDataUpdatedWorkflowRuleDto from "./PutDataUpdatedWorkflowRuleDto"
import GetDataUpdatedWorkflowRuleDto from "./GetDataUpdatedWorkflowRuleDto"

type DataUpdatedWorkflowRule = {
    type: "DATA"
    reference: string
    name: string
    entityConfigReference: string | undefined
    archived: boolean
    inPreviewMode: boolean
    matchingFiltersBeforeChange: GenericFilter[]
    matchingFiltersAfterChange: GenericFilter[]
    actions: GenericAction[]
    modificationHistory: {
        modifiedAt: number
        modifiedBy: string
    }[]
}

export default DataUpdatedWorkflowRule

export const convertDataUpdatedWorkflowRuleToDto = (rule: DataUpdatedWorkflowRule, fields: DataField[]): PutDataUpdatedWorkflowRuleDto => ({
    name: rule.name,
    archived: rule.archived,
    inPreviewMode: rule.inPreviewMode,
    entityConfigReference: rule.entityConfigReference ?? "customer",
    matchingFiltersBeforeChange: convertFromArrayToDto(rule.matchingFiltersBeforeChange, true),
    matchingFiltersAfterChange: convertFromArrayToDto(rule.matchingFiltersAfterChange, true),
    actions: convertFromActionsArrayToDto(rule.actions, fields)
})

export const convertDataUpdatedWorkflowRuleFromDto = (
    rule: GetDataUpdatedWorkflowRuleDto,
    fields: DataField[],
    lookups: Lookup[]
): DataUpdatedWorkflowRule => ({
    ...rule,
    type: "DATA",
    entityConfigReference: rule.entityConfigReference,
    matchingFiltersBeforeChange: convertFromDtoToArray(rule.matchingFiltersBeforeChange, fields, lookups),
    matchingFiltersAfterChange: convertFromDtoToArray(rule.matchingFiltersAfterChange, fields, lookups),
    actions: convertFromActionsDtoToArray(rule.actions, fields, lookups)
})
