import classes from './Menu.module.scss'
import { PARENT_ROUTE } from '../Home'
import { useHistory, useLocation } from 'react-router-dom'
import { useState } from 'react'

const Menu = () => {
    const { pathname, search } = useLocation()
    const history = useHistory()
    const [isMenuShown, setIsMenuShown] = useState(false)

    const setCurrentRoute = (route: string) => {
        const newRoute = `${PARENT_ROUTE}${route}`
        if (pathname === newRoute) {
            return
        }

        history.push(`${newRoute}${search}`)
    }

    const onMenuClick = () => {
        setIsMenuShown(true)
    }

    const onCloseMenu = () => {
        setIsMenuShown(false)
    }

    const onLogoClick = () => {
        setIsMenuShown(false)
        setCurrentRoute("")
    }

    const onUserProfileClick = () => {
        setIsMenuShown(false)
        setCurrentRoute("/user-profile")
    }

    const onLogoutClick = () => {
        setIsMenuShown(false)
        history.push("/?logout=true")
    }

    return (
        <>
            <div className={`d-flex ${classes['mobile-menu']} align-items-center justify-content-between shadow`}>
                <div className={`${classes['mobile-logo']} pointer`} onClick={onLogoClick}>
                    <img src="/Invevo_Logo_White.png" alt="Invevo Logo" />
                </div>
                <div className='text-white fs-1 me-4 pointer' onClick={onMenuClick}>
                    <i className='fas fa-bars' />
                </div>
            </div>
        { isMenuShown && 
             <div className={classes['mobile-overlay']}>
                <div className={`d-flex flex-column ms-auto text-white ${classes['mobile-menu-items']} ${classes['mobile-shadow']}`}>
                    <div className="d-flex justify-content-between align-items-center p-4">
                        <span className="text-uppercase fs-2">menu</span>
                        <span className="fs-1 pointer" onClick={onCloseMenu}><i className="fal fa-times"></i></span>
                    </div>
                    <div className="d-flex align-items-center mt-2 p-4 pointer" onClick={onLogoClick}>
                        <span className='fs-1 me-3'><i className='fas fa-file-chart-column'></i></span>
                        <span className='fs-2'>Credit circle report</span>
                    </div>
                    <div className="d-flex align-items-center mt-2 p-4 pointer" onClick={onUserProfileClick}>
                        <span className='fs-1 me-3'><i className='fas fa-user-gear'></i></span>
                        <span className='fs-2'>Your profile</span>
                    </div>
                    <div className="d-flex align-items-center mt-2 p-4 pointer" onClick={onLogoutClick}>
                        <span className='fs-1 me-3'><i className='fas fa-right-from-bracket'></i></span>
                        <span className='fs-2'>Sign out</span>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default Menu