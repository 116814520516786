import classes from "./GreyButton.module.scss"

type GreyButtonProps = {
    className?: string
    iconClasses: string
    disabled?: boolean
    label: string
    onClick: () => void
    ariaLabel?: string
    size?: "standard" | "small"
}

const GreyButton = ({ className = "", iconClasses, disabled = false, label, onClick, ariaLabel = "submit", size = "standard" }: GreyButtonProps) => {
    const onButtonClicked = () => {
        !disabled && onClick()
    }

    return (
        <div className={`${className} d-flex`} onClick={onButtonClicked} role="button" aria-label={ariaLabel}>
            <div className={`d-flex ${classes.button} ${disabled ? `${classes.disabled} not-allowed` : "pointer"}`}>
                <div className={`d-flex ${size === "standard" ? classes.icon : classes.iconSmall} p-1`}>
                    <i className={`m-auto ${iconClasses} ${size === "standard" ? "fs-5" : "fs-6"}`} role="img" />
                </div>
                <div className={`d-flex bg-grey ${classes.label} p-1 no-select`}>
                    <span className={`m-auto ${size === "standard" ? "fs-5 px-2" : "fs-6"}`}>{label}</span>
                </div>
            </div>
        </div>
    )
}

export default GreyButton
