import moment from "moment"
import { DataPrimitiveTypeEnum } from "../routes/entityConfig/types/DataType"
import { DataTypeEnum, getDataPrimitiveTypeForEntityDataType } from "../routes/entityConfig/types/DataType"
import EntityData from "../types/EntityData"
import { doesEntityMatchFilters } from "./FilterList/FiltersDto"
import DataUpdateRule from "./dataGridV2/types/DataUpdateRule"
import UpdateDataField from "./dataGridV2/types/UpdateDataField"

export type FieldsUpdate = {
    reference: string
    entityTypeReference: string
    booleanFields: BooleanFieldUpdate[]
    stringFields: StringFieldUpdate[]
    numberFields: NumberFieldsUpdate[]
    dateTimeFields: DateTimeFieldUpdates[]
}

type BooleanFieldUpdate = {
    fieldName: string
    fieldValue: boolean | undefined
}

type StringFieldUpdate = {
    fieldName: string
    fieldValue: string | undefined
}

type NumberFieldsUpdate = {
    fieldName: string
    fieldValue: number | undefined
}

type DateTimeFieldUpdates = {
    fieldName: string
    fieldValue: string | undefined
}

export const buildFieldUpdates = (
    originalEntity: EntityData,
    fieldToUpdate: {
        displayType: DataTypeEnum
        fieldName: string
    },
    updatedValue: string,
    dataUpdateRules: DataUpdateRule[]
): FieldsUpdate => {
    const updatedEntity = updateEntityDataField(originalEntity, fieldToUpdate, updatedValue)

    const matchedRuleActions = dataUpdateRules
        .filter(rule => !rule.inPreviewMode && !rule.archived)
        .filter(rule => rule.entityConfigReference === originalEntity.entityTypeReference)
        .filter(
            rule =>
                doesEntityMatchFilters(originalEntity, rule.matchingFiltersBeforeChange) &&
                doesEntityMatchFilters(updatedEntity, rule.matchingFiltersAfterChange)
        )
        .flatMap(rule => rule.actions.updateDataFieldActions ?? [])
        .filter(action => doesEntityMatchFilters(updatedEntity, action.filters))

    const allFieldUpdatesFromRules = matchedRuleActions.map(action => buildFieldUpdatesForAction(updatedEntity, action))

    const dataPrimitiveUpdated = getDataPrimitiveTypeForEntityDataType(fieldToUpdate.displayType)

    const fieldUpdateFromUser: FieldsUpdate = {
        reference: originalEntity.reference,
        entityTypeReference: originalEntity.entityTypeReference,
        booleanFields:
            dataPrimitiveUpdated === DataPrimitiveTypeEnum.BOOLEAN
                ? [{ fieldName: fieldToUpdate.fieldName, fieldValue: updatedValue.toLowerCase() === "true" }]
                : [],
        stringFields: dataPrimitiveUpdated === DataPrimitiveTypeEnum.TEXT ? [{ fieldName: fieldToUpdate.fieldName, fieldValue: updatedValue }] : [],
        numberFields:
            dataPrimitiveUpdated === DataPrimitiveTypeEnum.NUMBER ? [{ fieldName: fieldToUpdate.fieldName, fieldValue: parseFloat(updatedValue) }] : [],
        dateTimeFields:
            dataPrimitiveUpdated === DataPrimitiveTypeEnum.DATE
                ? [{ fieldName: fieldToUpdate.fieldName, fieldValue: moment(Number(updatedValue)).format() }]
                : []
    }

    const fieldsUpdatedByRules = [
        ...allFieldUpdatesFromRules.flatMap(update => update.booleanFields.map(field => field.fieldName)),
        ...allFieldUpdatesFromRules.flatMap(update => update.stringFields.map(field => field.fieldName)),
        ...allFieldUpdatesFromRules.flatMap(update => update.numberFields.map(field => field.fieldName)),
        ...allFieldUpdatesFromRules.flatMap(update => update.dateTimeFields.map(field => field.fieldName))
    ]
    const haveRulesOverwrittenUserUpdate = fieldsUpdatedByRules.some(field => field === fieldToUpdate.fieldName)

    const allFieldUpdates = haveRulesOverwrittenUserUpdate ? allFieldUpdatesFromRules : [...allFieldUpdatesFromRules, fieldUpdateFromUser]

    const mergedFieldUpdates = mergeFieldUpdates(allFieldUpdates)

    return mergedFieldUpdates
}

export const mergeFieldsUpdateIntoEntityData = (originalEntity: EntityData, fieldUpdates: FieldsUpdate): EntityData => ({
    ...originalEntity,
    entityFields: {
        booleanFields: fieldUpdates.booleanFields.reduce(
            (acc, val) => addOrReplaceFieldWith(acc, val.fieldName, val.fieldValue),
            originalEntity.entityFields.booleanFields
        ),
        numberFields: fieldUpdates.numberFields.reduce(
            (acc, val) => addOrReplaceFieldWith(acc, val.fieldName, val.fieldValue),
            originalEntity.entityFields.numberFields
        ),
        dateFields: fieldUpdates.dateTimeFields.reduce(
            (acc, val) => addOrReplaceFieldWith(acc, val.fieldName, val.fieldValue === undefined ? undefined : moment(val.fieldValue).valueOf()),
            originalEntity.entityFields.dateFields
        ),
        textFields: fieldUpdates.stringFields.reduce(
            (acc, val) => addOrReplaceFieldWith(acc, val.fieldName, val.fieldValue),
            originalEntity.entityFields.textFields
        )
    }
})

const updateEntityDataField = (
    originalEntity: EntityData,
    fieldToUpdate: {
        fieldName: string
        displayType: DataTypeEnum
    },
    updatedValue: string
): EntityData => {
    const dataPrimitive = getDataPrimitiveTypeForEntityDataType(fieldToUpdate.displayType)

    return {
        ...originalEntity,
        entityFields: {
            booleanFields:
                dataPrimitive === DataPrimitiveTypeEnum.BOOLEAN
                    ? addOrReplaceFieldWith(originalEntity.entityFields.booleanFields, fieldToUpdate.fieldName, updatedValue.toLowerCase() === "true")
                    : originalEntity.entityFields.booleanFields,
            numberFields:
                dataPrimitive === DataPrimitiveTypeEnum.NUMBER
                    ? addOrReplaceFieldWith(originalEntity.entityFields.numberFields, fieldToUpdate.fieldName, parseFloat(updatedValue))
                    : originalEntity.entityFields.numberFields,
            dateFields:
                dataPrimitive === DataPrimitiveTypeEnum.DATE
                    ? addOrReplaceFieldWith(originalEntity.entityFields.dateFields, fieldToUpdate.fieldName, parseInt(updatedValue))
                    : originalEntity.entityFields.dateFields,
            textFields:
                dataPrimitive === DataPrimitiveTypeEnum.TEXT
                    ? addOrReplaceFieldWith(originalEntity.entityFields.textFields, fieldToUpdate.fieldName, updatedValue)
                    : originalEntity.entityFields.textFields
        }
    }
}

const addOrReplaceFieldWith = <T>(
    fields: {
        name: string
        value: T
    }[],
    fieldToUpdate: string,
    updatedValue: T | undefined
): {
    name: string
    value: T
}[] => {
    const existingField = fields.find(field => field.name === fieldToUpdate)
    if (existingField === undefined) {
        if (updatedValue === undefined) {
            return fields
        }

        return [
            ...fields,
            {
                name: fieldToUpdate,
                value: updatedValue
            }
        ]
    }

    if (updatedValue === undefined) {
        return fields.filter(field => field.name !== fieldToUpdate)
    }

    return fields
        .filter(field => field.name !== fieldToUpdate)
        .concat({
            ...existingField,
            value: updatedValue
        })
}

const buildFieldUpdatesForAction = (entityData: EntityData, action: UpdateDataField): FieldsUpdate => ({
    reference: entityData.reference,
    entityTypeReference: entityData.entityTypeReference,
    booleanFields: [
        ...Object.keys(action.booleanFieldUpdates ?? []).map(updatedFieldName => ({
            fieldName: updatedFieldName,
            fieldValue: action.booleanFieldUpdates[updatedFieldName]!
        })),
        ...(action.booleanFromEntityFieldUpdates ?? []).map(fromEntity => ({
            fieldName: fromEntity.targetFieldName,
            fieldValue: entityData.entityFields.booleanFields.find(el => el.name === fromEntity.sourceFieldName)?.value
        }))
    ],
    stringFields: [
        ...Object.keys(action.stringFieldUpdates ?? []).map(updatedFieldName => ({
            fieldName: updatedFieldName,
            fieldValue: action.stringFieldUpdates[updatedFieldName]!
        })),
        ...(action.stringFromEntityFieldUpdates ?? []).map(fromEntity => ({
            fieldName: fromEntity.targetFieldName,
            fieldValue: entityData.entityFields.textFields.find(el => el.name === fromEntity.sourceFieldName)?.value
        }))
    ],
    numberFields: [
        ...Object.keys(action.numberFieldUpdates ?? []).map(updatedFieldName => ({
            fieldName: updatedFieldName,
            fieldValue: action.numberFieldUpdates[updatedFieldName]!
        })),
        ...Object.keys(action.numberFieldIncrements ?? []).map(updatedFieldName => ({
            fieldName: updatedFieldName,
            fieldValue:
                (entityData.entityFields.numberFields.find(field => field.name === updatedFieldName)?.value ?? 0) +
                (action.numberFieldIncrements[updatedFieldName] ?? 0)
        })),
        ...(action.numberFromEntityFieldUpdates ?? []).map(fromEntity => ({
            fieldName: fromEntity.targetFieldName,
            fieldValue: entityData.entityFields.numberFields.find(el => el.name === fromEntity.sourceFieldName)?.value
        }))
    ],
    dateTimeFields: [
        ...Object.keys(action.datetimeFieldUpdates ?? []).map(updatedFieldName => ({
            fieldName: updatedFieldName,
            fieldValue: action.datetimeFieldUpdates[updatedFieldName]!
        })),
        ...(action.dateTimeFromEntityFieldUpdates ?? []).map(fromEntity => ({
            fieldName: fromEntity.targetFieldName,
            fieldValue: entityData.entityFields.dateFields.find(el => el.name === fromEntity.sourceFieldName)?.value.toString()
        })),
        ...(action.relativeDateTimeUpdates ?? []).map(update => ({
            fieldName: update.fieldName,
            fieldValue: moment().add(update.amountOfDaysRelativeToNow, "days").format()
        }))
    ]
})

const mergeFieldUpdates = (fieldUpdates: FieldsUpdate[]): FieldsUpdate => ({
    reference: fieldUpdates[0]!.reference,
    entityTypeReference: fieldUpdates[0]!.entityTypeReference,
    booleanFields: fieldUpdates.reduce((acc, val) => acc.concat(val.booleanFields), Array<BooleanFieldUpdate>()),
    stringFields: fieldUpdates.reduce((acc, val) => acc.concat(val.stringFields), Array<StringFieldUpdate>()),
    numberFields: fieldUpdates.reduce((acc, val) => acc.concat(val.numberFields), Array<NumberFieldsUpdate>()),
    dateTimeFields: fieldUpdates.reduce((acc, val) => acc.concat(val.dateTimeFields), Array<DateTimeFieldUpdates>())
})

export const groupUpdatesByReference = (allUpdates: FieldsUpdate[], entityTypeReference: string): FieldsUpdate[] => {
    const allRefsBeingUpdated = [...new Set(allUpdates.map(update => update.reference))]

    return allRefsBeingUpdated.map(ref => {
        const updatesForThisRef = allUpdates.filter(update => update.reference === ref)
        return {
            reference: ref,
            entityTypeReference,
            booleanFields: updatesForThisRef.flatMap(update => update.booleanFields),
            numberFields: updatesForThisRef.flatMap(update => update.numberFields),
            dateTimeFields: updatesForThisRef.flatMap(update => update.dateTimeFields),
            stringFields: updatesForThisRef.flatMap(update => update.stringFields)
        }
    })
}
