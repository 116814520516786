import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { createSearchList, pauseSearchList } from '../../actions/searchActions';
import { GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../common/grids/columnMenu';
import { customerDetailsPane, getApiUrl, UserConfigViewType } from '../../constants';
import useUserConfigColumns from '../../hooks/useUserConfigColumns';
import ResultCard from './ResultCard';
import { normaliseFormatDef } from '../../selectors/lookup';
import StatefulGrid from '../common/grids/StatefulGrid';
import { getUserConfig } from '../../api/uiConfigApi';
import { gridColConfig } from '../../selectors/uiConfig';
import VisibleColumnConfig from '../common/grids/VisibleColumnConfig';
import { getFocusedAccount } from '../../api/currentSelectionApi';
import { isActivityList, isActivityListPaused } from '../../selectors/search';
import SearchListToolbar from './SearchListToolbar';
import { useInvevoSession } from 'invevo-react-components';
import { useLayout } from '../../../../contexts/LayoutContext';
import { useCustomer } from '../../../../contexts/CustomerContext';

const SearchResultsGrid = ({
  client,
  selectedAccount,
  searchResultGridColConfig,
  searchCriteria,
  isActivityList,
  isActivityListPaused,
  getFocusedAccount,
  createSearchList,
  pauseSearchList,
  onBack,
  getUserConfig
}) => {
  const searchResultRef = useRef();

  const [invevoSession, setInvevoSession] = useInvevoSession()
  const { isLeftPanelExpanded } = useLayout()
  const [customer, setCustomer] = useCustomer()

  const [showColConfig, setShowColConfig] = useState(false);

  const [columns, setColumns] = useUserConfigColumns(
    UserConfigViewType.searchResultGridColConfig
  );

  const onStateChange = (data) => {
    const isColumnActive = (field) => {
      return GridColumnMenuFilter.active(field, data.filter);
    };

    setColumns(
      columns.map((c) => ({
        ...c,
        headerClassName: isColumnActive(c.field) ? 'active' : ''
      }))
    );
  };

  useEffect(() => {
    if (!searchResultGridColConfig) {
      getUserConfig(client, UserConfigViewType.searchResultGridColConfig);
    }
  }, [searchResultGridColConfig, getUserConfig, client]);

  const getAccountId = (dataItem) => {
    if (dataItem.CustomerId) {
      return dataItem.CustomerId;
    }
    return dataItem.VirtualAccountId;
  };

  const onRowClick = (gridRowEvent) => {
    const dataItem = gridRowEvent.dataItem;

    if (dataItem && !dataItem.selected) {
      const accountId = getAccountId(dataItem);

      if (
        isActivityList &&
        !isActivityListPaused &&
        accountId !== selectedAccount.miaAccountId
      ) {
        const pausedIsVirtual = selectedAccount.miaAccountIsVirtualAccount;
        const pausedAccountId = selectedAccount.miaAccountId;
        // about to navigate outside of list so put it on pause
        pauseSearchList(
          pausedIsVirtual ? '' : pausedAccountId,
          pausedIsVirtual ? pausedAccountId : ''
        );
      }

      getFocusedAccount(client, accountId, !dataItem.CustomerId);
    }
  };

  useEffect(() => {
    if (!client || !selectedAccount || !selectedAccount.miaAccountCode || selectedAccount.miaAccountCode === "") {
      return
    }

    if (customer.reference === selectedAccount.miaAccountCode) {
      return
    }

    if (invevoSession.changeFromHistoryGrid) {
      getFocusedAccount(client, customer.reference, customer.isVirtualAccount)
    } else {
      setInvevoSession({
        changeFromHistoryGrid: false,
        selectedCustomerTab: customerDetailsPane.summary
      })
      setCustomer({
        reference: selectedAccount.miaAccountCode,
        sqlId: selectedAccount.miaAccountId,
        isVirtualAccount: selectedAccount.miaAccountIsVirtualAccount
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer.reference, selectedAccount, invevoSession.changeFromHistoryGrid, client, setCustomer, customer.isVirtualAccount])

  useEffect(() => {
    setInvevoSession({ changeFromHistoryGrid: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invevoSession.changeFromHistoryGrid])


  const isSelectedRow = dataItem => customer.reference === getAccountId(dataItem);

  if (isEmpty(searchCriteria.type)) {
    return <h3 className="text-center">Enter search criteria</h3>;
  }

  const url = `api/search/${searchCriteria.type}`;

  const resultCard = (e) => (
    <ResultCard dataItem={e.dataItem} isSelected={e.dataItem.AccountCode === selectedAccount.miaAccountCode} onRowClick={onRowClick} />
  );

  const onMakeList = () => {
    const total = searchResultRef.current.state.total;
    const gridState = searchResultRef.current.state.dataState;
    createSearchList(searchCriteria, gridState, total);

    const grid = searchResultRef.current.state.data;
    if (grid && grid.length > 0) {
      const dataItem = grid[0];
      const accountId = getAccountId(dataItem);

      getFocusedAccount(client, accountId, !dataItem.CustomerId);
    }
  };

  return (
    <>
      <div className="container-fluid">
        {isActivityList && <SearchListToolbar />}
        <div className="row">
          <div className="d-flex col-12 top10">
            <button className="btn btn-default" onClick={onBack}>
              <i className='fa-solid fa-chevron-left'></i> Refine Search
            </button>
            {isLeftPanelExpanded &&
              <button
                className="btn btn-default ms-2"
                onClick={() => setShowColConfig(true)}
              >
                Edit Columms <i className='fa-regular fa-line-columns' />
              </button>
            }

            <button
              className="btn btn-default ms-auto"
              onClick={onMakeList}
            >
              {isActivityList ? 'Refresh List' : 'Make List'}{' '}
              <i className='fa-solid fa-tasks'></i>
            </button>
          </div>
        </div>
      </div>

      <StatefulGrid
        client={client}
        path={getApiUrl(client) + url}
        pageSize={20}
        pageable={{
          buttonCount: 2,
          info: true,
          type: 'numeric',
          pageSizes: false,
          previousNext: false
        }}
        sortable
        onRowClick={onRowClick}
        isSelectedRow={isSelectedRow}
        onStateChange={onStateChange}
        additionalRequestPayload={searchCriteria}
        className={
          isLeftPanelExpanded ?
            'custom-grid scrollable-none top10' :
            'hide-header single-cell scrollable-none top10'
        }
        defaultField={{ field: 'Balance', dir: 'desc' }}
        hideablePaging={true}
        ref={searchResultRef}
      >
        {isLeftPanelExpanded ?
          columns
            .filter((f) => f.show)
            .map((c) => (
              <GridColumn {...c} key={c.field} columnMenu={ColumnMenu} />
            )) :
          <GridColumn cell={resultCard} />
        }
      </StatefulGrid>
      <VisibleColumnConfig
        gridId={UserConfigViewType.searchResultGridColConfig}
        columns={columns}
        setColumns={setColumns}
        show={isLeftPanelExpanded && showColConfig}
        setShow={setShowColConfig}
      />
    </>
  );
};

SearchResultsGrid.propTypes = {
  onBack: PropTypes.func.isRequired,
  searchCriteria: PropTypes.shape({
    type: PropTypes.oneOf(['quick', 'advanced']).isRequired
  })
};

const mapStateToProps = (state) => {
  return {
    isActivityList: isActivityList(state.searchReducer.activityList),
    isActivityListPaused: isActivityListPaused(
      state.searchReducer.activityList
    ),
    searchCriteria: state.searchReducer.search.criteria,
    selectedAccount: state.currentSelectionReducer.selectedAccount,
    searchGridColumns: state.lookupReducer.searchGridColumns.results,
    currencyFormat: normaliseFormatDef(
      state.lookupReducer.globalSettings.results.currencyFormat
    ),
    dateFormat: normaliseFormatDef(
      state.lookupReducer.globalSettings.results.dateFormat
    ),
    searchResultGridColConfig: gridColConfig(
      state,
      UserConfigViewType.searchResultGridColConfig
    )
  };
};

export default connect(mapStateToProps, {
  createSearchList,
  pauseSearchList,
  getFocusedAccount,
  getUserConfig
})(SearchResultsGrid);
