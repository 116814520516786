import moment from "moment"
import DatePickerWithInput from "../../../../library/Inputs/DatePickerWithInput"
import EntityDataFieldDropdown from "../../../../library/dropdowns/EntityDataFieldDropdown"
import { isValidNumber } from "../../../../library/helpers"
import DropdownOption from "../../../../types/DropdownOptions"
import Lookup from "../../../../types/Lookup"
import { DataPrimitiveTypeEnum, DataTypeEnum } from "../../../entityConfig/types/DataType"
import EntityDataField from "../../../entityConfig/types/EntityDataField"
import Dropdown from "../../../../library/dropdowns/Dropdown"
import UpdateDataField from "../../types/UpdateDataField"
import Input from "../../../../library/Inputs/Input"

type ActionUpdateFieldProps = {
    reference: string
    dropDownOptions: DropdownOption<"set" | "increment">[]
    dataFields: EntityDataField[]
    lookups: Lookup[]
    selectedField: UpdateDataField
    onDataFieldDefinitionUpdated: (update: UpdateDataField) => void
    onDataFieldDefinitionRemoved: (reference: string) => void
}

const ActionUpdateField = ({
    reference,
    dropDownOptions,
    dataFields,
    lookups,
    selectedField,
    onDataFieldDefinitionUpdated,
    onDataFieldDefinitionRemoved
}: ActionUpdateFieldProps) => {
    const onUpdateActionTypeDropDownChanged = (selectedOption: DropdownOption<"set" | "increment">) =>
        onDataFieldDefinitionUpdated({ ...selectedField, type: selectedOption.value })

    const onDataFieldChanged = (dataField?: EntityDataField) =>
        onDataFieldDefinitionUpdated({
            ...selectedField,
            fieldName: dataField?.fieldName ?? "",
            type: dataField?.dataPrimitiveType === DataPrimitiveTypeEnum.NUMBER ? selectedField.type : "set"
        })

    const onSelectedValueChanged = (selectedOption: DropdownOption<string>) =>
        onDataFieldDefinitionUpdated({ ...selectedField, updateValue: selectedOption.value })
    const onDateValueChanged = (date: Date) => onDataFieldDefinitionUpdated({ ...selectedField, updateValue: moment(date).format() })
    const onInputValueChanged = (value: string) => onDataFieldDefinitionUpdated({ ...selectedField, updateValue: value })

    const onRemoveClicked = () => onDataFieldDefinitionRemoved(reference)

    const getValueComponent = (selectedDataField: EntityDataField | undefined) => {
        if (selectedDataField?.dataType.type === DataTypeEnum.LOOKUP) {
            const lookupEntries = lookups.find(lookup => lookup.reference === selectedDataField.fieldName)?.entries ?? []
            const options = lookupEntries.map(lookup => ({
                label: lookup.name,
                value: lookup.reference
            }))
            return (
                <Dropdown
                    className="w-100"
                    options={options}
                    onOptionSelected={onSelectedValueChanged}
                    selectedOption={options.find(o => o.value === selectedDataField.fieldName)}
                />
            )
        }

        if (selectedDataField?.dataPrimitiveType === DataPrimitiveTypeEnum.BOOLEAN) {
            const options = [
                { label: "True", value: "true" },
                { label: "False", value: "false" }
            ]
            return (
                <Dropdown
                    className="w-100"
                    options={options}
                    onOptionSelected={onSelectedValueChanged}
                    selectedOption={options.find(o => o.value === selectedField.reference)}
                />
            )
        }

        if (selectedDataField?.dataPrimitiveType === DataPrimitiveTypeEnum.DATE) {
            const initialValue = selectedField.updateValue === "" ? new Date() : new Date(selectedField.updateValue)
            return <DatePickerWithInput date={initialValue} onDateChange={onDateValueChanged} />
        }

        return <Input className="w-100" value={selectedField.updateValue} onChange={onInputValueChanged} placeholder="Please enter value" />
    }

    const selectedDataField = dataFields.find(field => field.fieldName === selectedField.fieldName)

    return (
        <div className="d-flex flex-column align-items-between gap-2 w-100">
            <div className="d-flex flex-column gap-2 me-auto w-100">
                <Dropdown
                    options={dropDownOptions.filter(opt => selectedDataField?.dataPrimitiveType === DataPrimitiveTypeEnum.NUMBER || opt.value === "set")}
                    onOptionSelected={onUpdateActionTypeDropDownChanged}
                    selectedOption={dropDownOptions.find(v => v.value === selectedField.type)}
                    textAlign="left"
                />
                <EntityDataFieldDropdown options={dataFields} onOptionSelected={onDataFieldChanged} selectedOption={selectedDataField} />
                {selectedField && (
                    <div className="d-flex gap-2 flex-grow-1">
                        <span className="align-self-center text-grey text-uppercase">{selectedField.type === "set" ? "to" : "by"}</span>
                        <div className="d-flex flex-grow-1">{getValueComponent(selectedDataField)}</div>
                    </div>
                )}
                {!isValidNumber(selectedField.updateValue) && selectedField.type === "increment" && (
                    <div className="d-flex align-items-center text-danger no-select">
                        <i className="fal fa-exclamation-triangle me-2"></i>
                        <span>Please specify a valid number</span>
                    </div>
                )}
            </div>
            <div role="button" className="ms-auto px-2" onClick={onRemoveClicked} aria-label="filter-remove">
                <i className="fal fa-trash-alt" />
            </div>
        </div>
    )
}

export default ActionUpdateField
