import { useEffect } from 'react'
import classes from './AreaChart.module.scss'
import './C3Overrides.scss'
import c3 from 'c3'
import { generateHtmlId } from "../helpers"

const formatTick = d => d

const AreaChart = ({ className = '', colour = "white", title, data, tickFormat }) => {
    const chartId = generateHtmlId()

    useEffect(() => {
        let sum = 0
        let numberOfData = 0
        let colours = {}
        let types = {}

        data.data.forEach((d, index) => {
            let seriesNumbers = [...d]
            const seriesName = seriesNumbers.shift()
            sum = sum + (seriesNumbers.length > 0 ? seriesNumbers.reduce((a, b) => a + b) : 0)
            numberOfData = numberOfData + seriesNumbers.length

            types[seriesName] = 'area'

            if (index === 0)
                colours[seriesName] = '#74c7ab'

            if (index === 1)
                colours[seriesName] = '#5d9cd6'
        })

        const average = sum / numberOfData

        let formatFunction = formatTick

        if (typeof tickFormat === 'function') {
            formatFunction = tickFormat
        }

        c3.generate({
            bindto: `#${chartId}`,
            data: {
                types: types,
                columns: data.data,
                colors: colours
            },
            axis: {
                x: {
                    type: 'category',
                    categories: data.xlabels
                },
                y: {
                    tick: {
                        format: formatFunction
                    }
                }
            },
            legend: {
                show: data.data.length < 1 ? false : true,
                position: 'bottom'
            },
            point: {
                show: false
            },
            grid: {
                x: {
                    show: true
                },
                y: {
                    show: true,
                    lines: data.data.length > 1 ? [{ value: average, class: `${classes.averageLine}` }] : []
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={`${className} d-flex flex-column w-100 p-3 rounded ${classes[`widget-${colour}`]}`}>
            <div className="d-flex justify-content-between">
                <div className={`fs-5 text-uppercase d-block mb-2 ${colour === 'blue' ? "text-white" : "text-grey"}`}>{title}</div>
                <div className={`d-flex align-items-center fs-6 text-uppercase ${colour === 'white' ? 'text-grey' : 'text-white'} align-self-end flex-grow-1`}>
                    <div className={`ms-auto ${classes['average-line-legend']} w-25`}></div>
                    <span className="ms-2">Average</span>
                </div>
            </div>
            <div id={chartId} className={`mt-3 ${classes[`chart-${colour}`]} ${classes.c3}`}></div>
        </div>
    )
}

export default AreaChart
