import { FileImportConfigColumnType } from "./FileImportConfigColumnType"
import { FileImportConfigFieldDataType, TextFormatter } from "./FileImportConfigFieldDataType"
import { FileImportConfigFieldDefaultValue } from "./FileImportConfigFieldDefaultValue"
import { FileImportConfigFieldTrimOption } from "./FileImportConfigTrimOption"
import { RejectActionEnum } from "./RejectActionEnum"
import { RejectMandatoryActionEnum } from "./RejectMandatoryActionEnum"
import { FileFormat } from "../components/utils"

export type FileImportConfig = {
    reference: string
    fileType: FileType
    fileFormat: FileFormat
    dataFileImportConfig: DataFileImportConfig
}

export type DataFileImportConfig = TransactionFileImportConfig | CustomerFileImportConfig

export type CommonFileImportConfig = {
    configName: string
    fileNamePattern: string
    delimiter: string
    lineEnding: string
    hasHeaderRow: boolean
    hasQuotedFields: boolean
    rejectMandatoryAction: RejectMandatoryActionEnum
    entityType?: string
    mandatoryFieldConfig: ReferenceFieldConfig
    optionalFieldConfigs: FieldConfig[]
}

export type CustomerFileImportConfig = CommonFileImportConfig & {
    type: FileType.CUSTOMER
}

export type TransactionFileImportConfig = CommonFileImportConfig & {
    type: FileType.TRANSACTION
    isOpenItems: boolean
    openItemsThresholdPercentage?: number | string
    mandatoryCustomerFieldConfig: ReferenceFieldConfig
}

export type ReferenceFieldConfig = {
    columns: FileImportConfigColumnType[]
    trimOptions: FileImportConfigFieldTrimOption[]
}

export type FieldConfig = {
    columns: FileImportConfigColumnType[]
    dataFieldReference: string
    dataType: FileImportConfigFieldDataType
    defaultValue?: FileImportConfigFieldDefaultValue
    canBeEmpty: boolean
    rejectAction: RejectActionEnum
    trimOptions: FileImportConfigFieldTrimOption[]
    formatter?: keyof typeof TextFormatter
}

export enum FileType {
    CUSTOMER = "CUSTOMER",
    TRANSACTION = "TRANSACTION"
}
