import { useMemo, useState } from "react"
import Dropdown from "../../../../../../library/dropdowns/Dropdown"
import EntityDataFieldDropdown from "../../../../../../library/dropdowns/EntityDataFieldDropdown"
import EntityDataField from "../../../../../../routes/entityConfig/types/EntityDataField"
import DropdownOption from "../../../../../../types/DropdownOptions"
import { actionTypes } from "../../../../reducers/actionTypes"
import { DashboardConfigAction, DashboardConfigEntityConfig } from "../../../../reducers/dashboardConfigsReducer"
import { EntityChartConfigWidgetState } from "../../../../types/DashboardConfigState"
import WidgetInput from "../../forms/WidgetInput"
import classes from "./EntityChartWidget.module.scss"
import Lookup from "../../../../../../types/Lookup"
import GenericFilter from "../../../../../../library/FilterList/filterTypes/GenericFilter"
import FilterList from "../../../../../../library/FilterList/FilterList"
import EntityChartWidgetMultiField from "./EntityChartWidgetMultiField"

type EntityChartWidgetProps = {
    className?: string
    state: {
        widget: EntityChartConfigWidgetState
        entityConfigs: DashboardConfigEntityConfig[]
        lookups: Lookup[]
        showValidationErrors: boolean
        editingConfigEntityReference: string
    }
    dispatch: React.Dispatch<DashboardConfigAction>
}
const themeOptions: DropdownOption<"DEFAULT" | "BLUE">[] = [
    {
        label: "Default",
        value: "DEFAULT"
    },
    {
        label: "Blue",
        value: "BLUE"
    }
]
const chartTypeOptions: DropdownOption<"AREA" | "BAR" | "LINE" | "PIE">[] = [
    {
        label: "Area",
        value: "AREA"
    },
    {
        label: "Bar",
        value: "BAR"
    },
    {
        label: "Line",
        value: "LINE"
    },
    {
        label: "Pie",
        value: "PIE"
    }
]

const groupingTypeOptions: DropdownOption<"SINGLE" | "MULTI">[] = [
    {
        label: "Single Grouping",
        value: "SINGLE"
    },
    {
        label: "Multiple Fields",
        value: "MULTI"
    }
]
const aggregationTypeOptions: DropdownOption<"SUM" | "AVERAGE" | "MIN" | "MAX" | "CARDINALITY" | "COUNT">[] = [
    {
        label: "Sum",
        value: "SUM"
    },
    {
        label: "Average",
        value: "AVERAGE"
    },
    {
        label: "Min",
        value: "MIN"
    },
    {
        label: "Max",
        value: "MAX"
    },
    {
        label: "Count",
        value: "COUNT"
    }
]

const EntityChartWidget = ({ className, state, dispatch }: EntityChartWidgetProps) => {
    const { widget, entityConfigs, lookups, showValidationErrors, editingConfigEntityReference } = state
    const [showFilters, setShowFilters] = useState(false)

    const entityConfigDropdownOptions = entityConfigs.map(config => {
        return { value: config.reference, label: config.displayName }
    })

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        dispatch({ type: actionTypes.ENTITY_CHART_WIDGET_NAME_CHANGED, widget, newName: event.target.value })
    const onRemoveClick = () => dispatch({ type: actionTypes.WIDGET_REMOVED, widget })

    const onThemeChange = (themeOption: DropdownOption<"DEFAULT" | "BLUE">) =>
        dispatch({ type: actionTypes.ENTITY_CHART_WIDGET_THEME_CHANGED, widget, newTheme: themeOption.value })
    const onChartTypeChange = (typeOption: DropdownOption<"AREA" | "BAR" | "LINE" | "PIE">) =>
        dispatch({ type: actionTypes.ENTITY_CHART_WIDGET_CHART_TYPE_CHANGED, widget, newChartType: typeOption.value })
    const onGroupingTypeChange = (groupingTypeOptions: DropdownOption<"SINGLE" | "MULTI">) =>
        dispatch({ type: actionTypes.ENTITY_CHART_WIDGET_GROUPING_TYPE_CHANGED, widget, newGroupingType: groupingTypeOptions.value })
    const onAggregationTypeChange = (aggregationTypeOptions: DropdownOption<"SUM" | "AVERAGE" | "MIN" | "MAX" | "CARDINALITY" | "COUNT">) =>
        dispatch({ type: actionTypes.ENTITY_CHART_WIDGET_AGGREGATION_TYPE_CHANGED, widget, newAggregationType: aggregationTypeOptions.value })
    const onGroupingFieldChange = (groupingField: EntityDataField | undefined) => {
        if (!groupingField) {
            return
        }
        dispatch({ type: actionTypes.ENTITY_CHART_WIDGET_GROUPING_FIELD_CHANGED, widget, newGroupingField: groupingField.fieldName })
    }
    const onAggregationFieldChange = (aggregationField: EntityDataField | undefined) => {
        if (!aggregationField) {
            return
        }
        dispatch({
            type: actionTypes.ENTITY_CHART_WIDGET_AGGREGATION_FIELD_CHANGED,
            widget,
            newAggregationField: aggregationField.fieldName,
            newAggregationFieldType: aggregationField.dataPrimitiveType
        })
    }

    const onFiltersChanged = (filters: GenericFilter[]) => dispatch({ type: actionTypes.ENTITY_CHART_WIDGET_FILTERS_CHANGED, widget, filters: filters })

    const onEntityConfigValueChange = (option: { value: string; label: string }) =>
        dispatch({ type: actionTypes.ENTITY_CHART_WIDGET_ENTITY_CONFIG_CHANGED, widget, entityConfigReference: option.value })

    const fields = useMemo(
        () => entityConfigs.find(config => config.reference === widget.entityConfigReference)?.fields ?? [],
        [entityConfigs, widget.entityConfigReference]
    )

    const toggleShowFilters = () => setShowFilters(sF => !sF)

    const aggregation = widget.aggregation

    const displayHeader = () => {
        return <>
            <div className="d-flex align-items-center border-bottom pb-2">
                <i className="fal fa-chart-bar me-2"></i>
                <WidgetInput
                    className="flex-grow-1"
                    value={widget.displayName}
                    placeholder="Please enter name"
                    onChange={onNameChange}
                    isValid={!showValidationErrors || widget.displayName !== ""}
                />
                <i className="fal fa-times ms-3 me-1 pointer" onClick={onRemoveClick}></i>
            </div>
            <div className="d-flex flex-column">
                <div className="d-flex ms-3 align-items-center border-bottom pb-2">
                    <span className="text-grey flex-grow-1">Entity Type:</span>
                    <Dropdown
                        ariaLabel="grid-entity-config-dropdown"
                        className="ms-2"
                        options={entityConfigDropdownOptions.filter(config => config.value !== editingConfigEntityReference)}
                        onOptionSelected={onEntityConfigValueChange}
                        selectedOption={entityConfigDropdownOptions.find(o => o.value === widget.entityConfigReference)}
                        textAlign="left"
                        colour="white"
                    />
                </div>

                <div
                    className={`d-flex ms-3 px-2 h-100 rounded align-self-end pointer align-items-center text-blue ${classes["filter-button"]} ${showFilters ? classes["selected-filter-button"] : ""
                        }`}
                    role="button"
                    aria-label="show-grid-filters"
                    onClick={toggleShowFilters}
                >
                    <i className="fal fa-filter me-1"></i>
                    Filters
                    <div className={`ms-2 px-2 bg-blue text-white ${classes["filter-count-label"]}`}>{widget.filters.length}</div>
                </div>
            </div>
        </>
    }

    return (
        <div className={`${className ? className : ""} d-flex flex-column ${classes.widget} bg-white p-2 text-grey overflow-auto`}>
            {displayHeader()}
            <div className="d-flex flex-column mt-2 px-2">
                <div className="d-flex flex-column">
                    {showFilters && (
                        <div className={`mt-2 p-3 rounded ${classes["filters-container"]}`} aria-label="filters-container">
                            <FilterList
                                isCompact={true}
                                fields={fields}
                                lookups={lookups}
                                appliedFilters={widget.filters}
                                onFiltersApplied={onFiltersChanged}
                                autoApply={true}
                            />
                        </div>
                    )}
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="me-2 text-uppercase small">Theme:</span>
                        <Dropdown
                            ariaLabel="widgetThemeDropdown"
                            options={themeOptions}
                            selectedOption={themeOptions.find(o => o.value === widget.theme)}
                            onOptionSelected={onThemeChange}
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="me-2 text-uppercase small">Chart Type:</span>
                        <Dropdown
                            ariaLabel="chartTypeDropdown"
                            options={chartTypeOptions}
                            selectedOption={chartTypeOptions.find(o => o.value === widget.chartType)}
                            onOptionSelected={onChartTypeChange}
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="me-2 text-uppercase small">Aggregation Type:</span>
                        <Dropdown
                            ariaLabel="aggregationTypeDropdown"
                            options={aggregationTypeOptions}
                            selectedOption={aggregationTypeOptions.find(o => o.value === widget.aggregationType)}
                            onOptionSelected={onAggregationTypeChange}
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="me-2 text-uppercase small">Grouping Type:</span>
                        <Dropdown
                            ariaLabel="groupingTypeDropdown"
                            options={groupingTypeOptions}
                            selectedOption={groupingTypeOptions.find(o => o.value === widget.aggregation.type)}
                            onOptionSelected={onGroupingTypeChange}
                        />
                    </div>
                    {aggregation.type === "SINGLE" && widget.aggregationType !== "COUNT" && (
                        <div className="d-flex flex-column mb-2">
                            <span className="me-2 text-uppercase small pb-1">Grouping Field:</span>
                            <EntityDataFieldDropdown
                                ariaLabel="groupingFieldDropdown"
                                options={fields}
                                selectedOption={fields.find(f => f.fieldName === aggregation.groupingField)}
                                onOptionSelected={onGroupingFieldChange}
                            />
                        </div>
                    )}
                    {aggregation.type === "SINGLE" && (
                        <div className="d-flex flex-column">
                            <span className="me-2 text-uppercase small pb-1">
                                {widget.aggregationType === "COUNT" ? "Grouping Field:" : "Aggregation Field:"}
                            </span>
                            <EntityDataFieldDropdown
                                ariaLabel="aggregationFieldDropdown"
                                options={fields.filter(field => widget.aggregationType === "COUNT" || field.dataPrimitiveType === "NUMBER")}
                                selectedOption={fields.find(f => f.fieldName === aggregation.aggregationField)}
                                onOptionSelected={onAggregationFieldChange}
                            />
                        </div>
                    )}
                    {aggregation.type === "MULTI" && (
                        <EntityChartWidgetMultiField widget={widget} aggregationFields={aggregation.fields} fields={fields} dispatch={dispatch} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default EntityChartWidget
