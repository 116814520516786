import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AccountDetail from './accountDetail';
import { executeAuthAsyncPost } from '../../utility/asyncSupport';
import { showToastErrorMessage } from '../../api/toasterApi';
import AccountCard from './accountCard';
import TextareaAutosize from 'react-textarea-autosize';
import { useClient } from 'invevo-react-components';

const GeneralNoteCard = ({
  accountId,
  isVirtualAccount,
  initNoteText,
  executeAuthAsyncPost
}) => {
  const client = useClient()
  const [isDirty, setIsDirty] = useState(false);
  const [addNewNote, setAddNewNote] = useState(false);
  const [inFlight, setInFlight] = useState(false);
  const [noteText, setNoteText] = useState();

  useEffect(() => {
    setNoteText(initNoteText);
  }, [initNoteText]);

  const onNoteChange = (event) => {
    setIsDirty(true);

    setNoteText(event.target.value);
  };

  const onSaveNote = () => {
    setInFlight(true);
    executeAuthAsyncPost(
      client,
      'accountSummary/updateNote',
      'UPDATE_GENERAL_NOTE',
      {
        accountId,
        isVirtualAccount,
        noteText
      },
      () => {
        setAddNewNote(false);
        setIsDirty(false);
        setInFlight(false);
      },
      () => {
        setAddNewNote(false);
        setInFlight(false);
        showToastErrorMessage('Error while updating note');
      }
    );
  };

  const hasNote = !!noteText;
  const showNote = hasNote || addNewNote;

  return (
    <AccountCard title={'General Note'}>
      <AccountDetail>
        {showNote ? (
          <div className="d-flex mt-1">
            <div className="flex-grow-1 flex-shrink-1 mr-2">
              <TextareaAutosize
                value={noteText}
                onChange={onNoteChange}
                className={'w-100 p-1'}
              />
            </div>
            <div className="align-self-end ms-1 mb-2">
              <button
                onClick={onSaveNote}
                disabled={!isDirty || inFlight}
                className="btn btn-default float-right"
                type="button"
              >
                Update
              </button>
            </div>
          </div>
        ) : (
          <button
            onClick={() => setAddNewNote(true)}
            className="btn btn-default mt-1 float-right"
            type="button"
          >
            Add
          </button>
        )}
      </AccountDetail>
    </AccountCard>
  );
};

const mapStateToProps = (state) => {
  return {
    accountId: state.currentSelectionReducer.selectedAccount.miaAccountId,
    isVirtualAccount:
      state.currentSelectionReducer.selectedAccount.miaAccountIsVirtualAccount
  };
};

export default connect(mapStateToProps, {
  executeAuthAsyncPost
})(GeneralNoteCard);
