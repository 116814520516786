import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  api,
  gridKeys,
  userTaskDateType,
  stateTaskType,
  getApiUrl,
  customerDetailsPane
} from '../../constants';
import { GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import UserTaskCard from './userTaskCard';
import { setUserTaskGridIndex } from '../../actions/taskActions';
import { destroySearchList } from '../../actions/searchActions';
import StatefulGrid from '../common/grids/StatefulGrid';
import moment from 'moment';
import { ColumnMenu } from '../common/grids/columnMenu';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useClient, useInvevoSession } from 'invevo-react-components';
import { useLayout } from '../../../../contexts/LayoutContext';
import { useCustomer } from '../../../../contexts/CustomerContext';
import { getFocusedAccount } from '../../api/currentSelectionApi';
import { ActionStripItems, useActionStrip } from '../../../../contexts/ActionStripContext';
import { useEntity } from '../../../../contexts/EntityContext';

const UserEmailTaskGrid = ({
  columns,
  setColumns,
  accountId,
  setUserTaskGridIndex,
  destroySearchList,
  getFocusedAccount
}) => {
  const client = useClient()
  const [, setInvevoSession] = useInvevoSession()
  const { isLeftPanelExpanded } = useLayout()
  const [, setCustomer] = useCustomer()
  const [, setEntity] = useEntity()
  const { navToAction } = useActionStrip()

  const userEmailTaskGridRef = useRef();
  const { invevoTaskFeature, invevoTaskGridPageSize, newEntityStructure } = useFlags();

  const resetIndex = () => {
    setUserTaskGridIndex();
  };

  useEffect(() => {
    // destroy search list before setting up new task list
    destroySearchList();
  }, [destroySearchList]);

  const onRowClick = (gridRowEvent) => {
    const dataItem = gridRowEvent.dataItem
    const grid = userEmailTaskGridRef.current.state.data
    const index = grid.findIndex(
      (d) =>
        d.CustomerId === dataItem.CustomerId &&
        d.TaskId === dataItem.TaskId &&
        d.StatefulTaskType === dataItem.StatefulTaskType
    );

    setUserTaskGridIndex(index);

    const gridAccountId = dataItem.CustomerId
      ? dataItem.CustomerId
      : dataItem.VirtualAccountId
    const gridAccountIsVirtual = !dataItem.CustomerId

    const isCurrentAccount = accountId === gridAccountId

    setInvevoSession({
      selectedCustomerTab: customerDetailsPane.history
    })

    navToAction(ActionStripItems.HISTORY)

    if (!isCurrentAccount) {
      getFocusedAccount(
        client,
        gridAccountId,
        gridAccountIsVirtual,
        dataItem.TaskId,
        false
      )

      newEntityStructure && setEntity({
        reference: dataItem.AccountCode,
        sqlId: gridAccountId,
        entityType: "customer"
      })
      setCustomer({
        reference: dataItem.AccountCode,
        sqlId: gridAccountId,
        isVirtualAccount: gridAccountIsVirtual,
      })
    }
  }

  const onStateChange = (data) => {
    const isColumnActive = (field) => {
      return GridColumnMenuFilter.active(field, data.filter);
    };

    setColumns((prevColumns) =>
      prevColumns.map((c) => ({
        ...c,
        headerClassName: isColumnActive(c.field) ? 'active' : ''
      }))
    );
  };

  const renderCard = (e) => (
    <UserTaskCard dataItem={e.dataItem} onClick={onRowClick} />
  );

  const additionalRequestPayload = () => {
    const payload = {
      taskTypeId: '',
      statefulTaskTypes: stateTaskType.UNREAD_EMAIL,
      startDate: '',
      endDate: moment().local(true).endOf('day').toISOString()
    };

    return payload;
  };

  let url = getApiUrl(client) + 'api/statefultaskfeed';
  if (invevoTaskFeature) {
    url = api.taskUrl + 'task';
  }

  return (
    <StatefulGrid
      client={client}
      ref={userEmailTaskGridRef}
      stateKey={gridKeys.USER_TASK_GRID_KEY} // share cached key with userTaskGrid email tasks can be used in toolbar
      method={'GET'}
      path={url}
      sortable
      pageSize={invevoTaskGridPageSize}
      pageable={{
        pageSizes: [50, 100, 200, 500],
        refresh: true,
        buttonCount: 5
      }}
      onStateChange={onStateChange}
      onRowClick={onRowClick}
      additionalRequestPayload={additionalRequestPayload()}
      afterReceivedFn={resetIndex}
      hideablePaging={true}
      className={
        isLeftPanelExpanded
          ? 'custom-grid scrollable-none'
          : 'hide-header single-cell scrollable-none'
      }
    >
      {isLeftPanelExpanded ?
        columns
          .filter((f) => f.show)
          .map((c) => (
            <GridColumn {...c} key={c.field} columnMenu={ColumnMenu} />
          )) :
        <GridColumn cell={renderCard} />}
    </StatefulGrid>
  );
};

function areEqual(prevProps, nextProps) {
  if (
    nextProps.dateType === userTaskDateType.RANGE &&
    (!nextProps.startDate || !nextProps.endDate)
  )
    return true;
  return false;
}
const MemoUserTaskGrid = React.memo(UserEmailTaskGrid, areEqual);

const mapStateToProps = (state) => {
  return {
    accountId: state.currentSelectionReducer.selectedAccount.miaAccountId,
    selectedTask: state.taskReducer.selectedTask || {},
    startDate: state.taskReducer.userTaskList.startDate,
    endDate: state.taskReducer.userTaskList.endDate,
    dateType: state.taskReducer.userTaskList.dateType,
    stateTaskTypeId: state.taskReducer.userTaskList.stateTaskTypeId,
    taskTypeId: state.taskReducer.userTaskList.taskTypeId
  };
};

export default connect(mapStateToProps, {
  setUserTaskGridIndex,
  destroySearchList,
  getFocusedAccount
})(MemoUserTaskGrid);
