import classes from "./DashboardStripSubItem.module.scss"
import useHover from "../../../hooks/useHover"

const DashboardStripSubItem = ({ label, isActive, renderIcon, onClick }) => {
    const [hoverRef, isHovering] = useHover()
    return (
        <div className="w-100">
            <div ref={hoverRef} className={`${classes["menu-item"]} d-flex w-100 pointer`} onClick={onClick} role="button" aria-label={label}>
                <div className={`d-flex ${isActive ? `${classes.active} p-2` : ""} m-auto`}>{renderIcon}</div>
            </div>
            {isHovering && (
                <div className={`position-absolute d-flex ${classes.label}`}>
                    <span className="m-auto text-nowrap fs-5 p-3 fw-lighter no-select">{label}</span>
                </div>
            )}
        </div>
    )
}

export default DashboardStripSubItem
