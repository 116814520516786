import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import moment from 'moment';

import { doSearch } from '../../actions/searchActions';
import { getLookup } from "../../api/lookupApi";
import { isNil } from 'lodash';
import DateRange from '../common/DateRange';
import Drawer from '../common/Drawer';
import NumericRange from '../common/NumericRange';
import MultiCheckboxSet from '../common/MultiCheckboxSet';

class AdvancedSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = AdvancedSearch.defaultFormState();
    this.advanceSearchFormRef = new React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.immediateSearch.searchNow && this.props.immediateSearch.query !== prevProps.immediateSearch.query) {
      this.setState({
        canSubmit: true,
        search: this.props.immediateSearch.query
      });
      return
    }

    if (this.props.immediateSearch.searchNow && this.props.isNew) {
      this.search()
    }
    else if (this.props.isNew && !prevProps.isNew) {
      this.clearForm();
    }
  }

  componentDidMount() {
    if (this.props.immediateSearch.searchNow) {
      this.setState({
        canSubmit: true,
        search: this.props.immediateSearch.query
      });
    }
  }

  clearForm = () => {
    this.setState(AdvancedSearch.defaultFormState());
    if (this.advanceSearchFormRef.current)
      this.advanceSearchFormRef.current.reset();
  };

  loadAgedDebt = () => {
    if (this.props.agedDebtLookup.results.length === 0) {
      this.props.getLookup(this.props.client, 'lookup/ageranges', 'agedDebt');
    }
  };

  loadTransType = () => {
    if (this.props.transTypeLookup.results.length === 0) {
      this.props.getLookup(this.props.client, 'lookup/TransactionTypes', 'transType');
    }
  };

  loadTransStatus = () => {
    if (this.props.transStatusLookup.results.length === 0) {
      this.props.getLookup(this.props.client, 'lookup/TransactionStatuses', 'transStatus');
    }
  };

  onChange = (name, value) => {
    this.setState({
      search: {
        ...this.state.search,
        [name]: value
      }
    });
  };

  onMultiSelectChange = (source, ids) => {
    const intVals = [];
    for (let i = 0; i < ids.length; i++) {
      intVals.push(parseInt(ids[i]));
    }
    this.setState({
      search: {
        ...this.state.search,
        [source]: intVals
      }
    });
  };

  search = () => {
    const convertDateSet = ({ to, from }) => {
      return {
        to: to ? moment(to, 'YYYY-MM-DD').format() : null,
        from: from ? moment(from, 'YYYY-MM-DD').format() : null
      };
    };

    const convertNumberSet = ({ to, from }) => {
      return {
        to: to ? parseFloat(to) : null,
        from: from ? parseFloat(from) : null
      };
    };

    if (this.canSearch()) {
      const search = this.state.search;
      this.props.doSearch({
        ...search,
        transactionDate: convertDateSet(search.transactionDate),
        transactionDueDate: convertDateSet(search.transactionDueDate),
        accountBalance: convertNumberSet(search.accountBalance),
        overdueBalance: convertNumberSet(search.overdueBalance),
        transactionValue: convertNumberSet(search.transactionValue),
        agedDebt: search.agedDebt.join(','),
        transStatus: search.transStatus.join(','),
        transType: search.transType.join(','),
        accountStatus: search.accountStatus.join(',')
      });
      this.props.onSearch();
    }
  };

  enableSearch = () => {
    if (!this.state.canSubmit) {
      this.setState({
        canSubmit: true
      });
    }
  };

  disableSearch = () => {
    if (this.state.canSubmit) {
      this.setState({
        canSubmit: false
      });
    }
  };

  static defaultFormState = () => {
    return {
      search: {
        transactionDate: { from: null, to: null },
        transactionDueDate: { from: null, to: null },
        accountBalance: {},
        overdueBalance: {},
        transactionValue: {},
        agedDebt: [],
        transStatus: [],
        transType: [],
        accountStatus: [],
        type: 'advanced'
      },
      canSubmit: false
    };
  };

  canSearch = () => {
    const searchCriteria = this.state.search;
    const searchCriteriaHasValue =
      !isNil(searchCriteria.accountBalance.from) ||
      !isNil(searchCriteria.accountBalance.to) ||
      !isNil(searchCriteria.overdueBalance.from) ||
      !isNil(searchCriteria.overdueBalance.to) ||
      !isNil(searchCriteria.transactionValue.from) ||
      !isNil(searchCriteria.transactionValue.to) ||
      !isNil(searchCriteria.transactionDate.from) ||
      !isNil(searchCriteria.transactionDate.to) ||
      !isNil(searchCriteria.transactionDueDate.from) ||
      !isNil(searchCriteria.transactionDueDate.to) ||
      searchCriteria.agedDebt.length > 0 ||
      searchCriteria.transStatus.length > 0 ||
      searchCriteria.transType.length > 0 ||
      searchCriteria.accountStatus.length > 0;
    return searchCriteriaHasValue && this.state.canSubmit;
  };

  render() {
    const searchFoundSomething = true,
      numericRangeError = 'Enter either a single value or ascending numbers.',
      dateRangeError = 'Enter either a single date or consecutive dates.';

    const search = this.state.search;
    return (
      <Formsy
        onValid={this.enableSearch}
        onInvalid={this.disableSearch}
        ref={this.advanceSearchFormRef}
        className="container-fluid"
      >
        <div className="row">
          <div className="col-12">
            <Drawer name="dateRangeDrawer" title="Transaction Date Range">
              <DateRange
                name="transactionDate"
                label="Date"
                value={{
                  from: search.transactionDate.from,
                  to: search.transactionDate.to
                }}
                onChange={this.onChange.bind(this, 'transactionDate')}
                validationError={dateRangeError}
              />

              <DateRange
                name="transactionDueDate"
                label="Due&nbsp;Date"
                value={{
                  from: search.transactionDueDate.from,
                  to: search.transactionDueDate.to
                }}
                onChange={this.onChange.bind(this, 'transactionDueDate')}
                validationError={dateRangeError}
              />
            </Drawer>
            <Drawer name="valueRangeDrawer" title="Value Range">
              <NumericRange
                id="balance"
                name="rangeBalance"
                label="Account Balance"
                value={{
                  from: search.accountBalance.from,
                  to: search.accountBalance.to
                }}
                onChange={this.onChange.bind(this, 'accountBalance')}
                validationError={numericRangeError}
              />
              <NumericRange
                id="overdue"
                name="rangeOverdue"
                label="Overdue Balance"
                value={{
                  from: search.overdueBalance.from,
                  to: search.overdueBalance.to
                }}
                onChange={this.onChange.bind(this, 'overdueBalance')}
                validationError={numericRangeError}
              />
              <NumericRange
                id="range"
                name="rangeValue"
                label="Transaction Value"
                value={{
                  from: search.transactionValue.from,
                  to: search.transactionValue.to
                }}
                onChange={this.onChange.bind(this, 'transactionValue')}
                validationError={numericRangeError}
              />
            </Drawer>
            <Drawer
              name="agedDebtDrawer"
              title="Aged Debt"
              onClick={this.loadAgedDebt}
            >
              <MultiCheckboxSet
                name="agedDebt"
                prefixText="Aged debt"
                onChange={this.onMultiSelectChange}
                data={this.props.agedDebtLookup.results}
                selected={search.agedDebt}
              />
            </Drawer>
            <Drawer
              name="transStatusDrawer"
              title="Transaction Status"
              onClick={this.loadTransStatus}
            >
              <MultiCheckboxSet
                name="transStatus"
                onChange={this.onMultiSelectChange}
                data={this.props.transStatusLookup.results}
                selected={search.transStatus}
              />
            </Drawer>
            <Drawer
              name="transactionTypeDrawer"
              title="Transaction Type"
              onClick={this.loadTransType}
            >
              <MultiCheckboxSet
                name="transType"
                onChange={this.onMultiSelectChange}
                data={this.props.transTypeLookup.results}
                selected={search.transType}
              />
            </Drawer>
            <Drawer
              name="accountStatusDrawer"
              title="Account Status"
              onClick={this.loadTransType}
            >
              <MultiCheckboxSet
                name="accountStatus"
                onChange={this.onMultiSelectChange}
                data={this.props.accountStatusLookup.results}
                selected={search.accountStatus}
              />
            </Drawer>
            <div className="row">
              <div className="col-md-10">
                <label
                  id="searchFormError"
                  className="help-inline inline-label"
                >
                  {searchFoundSomething === false ? 'No results found!' : ''}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="row my-3">
          <div className="col-6">
            <button
              data-cy="adv-clear-search"
              type="button"
              onClick={this.clearForm}
              disabled={!this.canSearch()}
              className="btn btn-default"
            >
              Clear
            </button>
          </div>
          <div className="col-6 d-flex">
            <button
              data-cy="adv-search"
              type="button"
              onClick={this.search}
              disabled={!this.canSearch()}
              className="btn btn-primary ms-auto"
            >
              Search
            </button>
          </div>
        </div>
      </Formsy>
    );
  }
}

AdvancedSearch.propTypes = {
  doSearch: PropTypes.func.isRequired,
  getLookup: PropTypes.func.isRequired,
  agedDebtLookup: PropTypes.object.isRequired,
  transTypeLookup: PropTypes.object.isRequired,
  transStatusLookup: PropTypes.object.isRequired,
  accountStatusLookup: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
  immediateSearch: PropTypes.object.isRequired,
  foundData: PropTypes.bool
};

AdvancedSearch.defaultProps = {
  foundData: false,
  immediateSearch: {
    query: {},
    searchNow: false
  }
};

const mapStateToProps = (state) => ({
  agedDebtLookup: state.lookupReducer.agedDebt,
  transTypeLookup: state.lookupReducer.transType,
  transStatusLookup: state.lookupReducer.transStatus,
  accountStatusLookup: state.lookupReducer.accStat,
  immediateSearch: state.searchReducer.immediateSearch,

  foundData: state.searchReducer.search.foundData
});

export default connect(mapStateToProps, {
  doSearch,
  getLookup
})(AdvancedSearch);
