import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { dismissFocusedTask } from '../../actions/taskActions.js';
import TaskForm from './taskForm';
import ViewTask from './viewTask';
import classes from './customerTask.module.scss'
import { useClient, useInvevoSession } from "invevo-react-components";
import { getTaskDocuments } from '../../actions/documentActions';

const Task = ({ task, customerId, isVirtualAccount, taskDocuments }) => {
  const dispatch = useDispatch()
  const client = useClient()
  const [invevoSession, setInvevoSession] = useInvevoSession()

  useEffect(() => {
    dispatch(getTaskDocuments(client, task.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!task) return <h4 className="m-3">Select a task</h4>;
  if (!customerId) return <h4 className="m-3">Select an account</h4>;

  const { isNew, isCompleted, id } = task;

  const reloadList = () => {
    dispatch(dismissFocusedTask())
    setInvevoSession({ reloadList: invevoSession.reloadList + 1 })
  }

  return (
    <section className={`task-pane container-fluid overflow-auto ${classes.container}`}>
      <div className="row">
        <div className="col-12 header top10">
          <h2 className='text-white'>
            Task # {isNew ? 'NEW' : id}
            <button
              type="button"
              onClick={reloadList}
              className={`btn btn-link text-white`}
              title="cancel"
              style={{ float: "right" }}
            >
              <i className={`fa-solid fa-times`}></i>
            </button>
          </h2>
        </div>
        <div className="col-12">
          {isCompleted && (
            <>
              <i className={`fa-solid fa-check-circle mia-success`}></i>
              {' '}
              <span>Completed</span>
            </>
          )}
          {isNew && (
            <>
              <i className={`far fa-circle pe-1`}></i>
              {' '}
              <span>Creating...</span>
            </>
          )}
          {!(isNew || isCompleted) && (
            <>
              <i className={`far fa-times pe-1`}></i>
              <span>Todo</span>
            </>
          )}
        </div>

        {!isCompleted && <TaskForm customerId={customerId} isVirtualAccount={isVirtualAccount} documentCount={taskDocuments.length} />}
        {isCompleted && <ViewTask task={task} customerId={customerId} isVirtualAccount={isVirtualAccount} />}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    task: state.taskReducer.selectedTask,
    taskDocuments: state.documentReducer.taskDocuments
  };
};

export default connect(mapStateToProps, {
  dismissFocusedTask
})(Task);
