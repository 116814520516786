import classes from "./LookupEntries.module.scss"
import LookupEntry from "./LookupEntry"
import LookupField from "./LookupField"
import { v4 as uuidv4 } from "uuid"
import { dataPrimitive } from "../../../enums/dataPrimitive"
import { actionTypes } from "../../../reducers/actionTypes"
import StandardButton from "../../../../../library/buttons/StandardButton/StandardButton"

export const ENTRY_PAGE_SIZE = 200

const LookupEntries = ({ selectedOriginalLookup, selectedUpdatedLookup, onLookupChange, dispatch, currentPage }) => {
    const onLookupFieldAdded = () =>
        onLookupChange({
            ...selectedUpdatedLookup,
            fields: [...selectedUpdatedLookup.fields, { field: "", dataPrimitive: dataPrimitive.TEXT, editable: true, id: uuidv4() }]
        })

    const onLookupEntryAdded = () => {
        onLookupChange({
            ...selectedUpdatedLookup,
            entries: [
                ...selectedUpdatedLookup.entries,
                {
                    reference: "",
                    name: "",
                    strings: Object.fromEntries(
                        selectedUpdatedLookup.fields
                            .filter(f => f.dataPrimitive === "TEXT" && f.field !== "reference" && f.field !== "name")
                            .map(f => [f.field, ""])
                    ),
                    bigDecimals: Object.fromEntries(selectedUpdatedLookup.fields.filter(f => f.dataPrimitive === "NUMBER").map(f => [f.field, 0])),
                    booleans: Object.fromEntries(selectedUpdatedLookup.fields.filter(f => f.dataPrimitive === "BOOLEAN").map(f => [f.field, false])),
                    dateTimes: Object.fromEntries(selectedUpdatedLookup.fields.filter(f => f.dataPrimitive === "DATE").map(f => [f.field, ""])),
                    editable: true,
                    id: uuidv4()
                }
            ]
        })
        dispatch({
            type: actionTypes.DATA_ENTRY_PAGE_SIZE_CHANGED,
            pageSize: ENTRY_PAGE_SIZE * Math.floor((selectedUpdatedLookup.entries.length + 1) / ENTRY_PAGE_SIZE)
        })
    }

    const removeLookupEntry = entryId => {
        onLookupChange({
            ...selectedUpdatedLookup,
            entries: selectedUpdatedLookup.entries.filter(e => e.id !== entryId)
        })
        dispatch({
            type: actionTypes.DATA_ENTRY_PAGE_SIZE_CHANGED,
            pageSize: ENTRY_PAGE_SIZE * Math.floor((selectedUpdatedLookup.entries.length - 1) / ENTRY_PAGE_SIZE)
        })
    }

    const removeField = fieldId =>
        onLookupChange({
            ...selectedUpdatedLookup,
            fields: selectedUpdatedLookup.fields.filter(f => f.id !== fieldId)
        })

    const onNextPage = () => dispatch({ type: actionTypes.DATA_ENTRY_PAGE_SIZE_CHANGED, pageSize: currentPage + ENTRY_PAGE_SIZE })

    const onPreviousPage = () => dispatch({ type: actionTypes.DATA_ENTRY_PAGE_SIZE_CHANGED, pageSize: currentPage - ENTRY_PAGE_SIZE })

    const getNextPageEnd = () =>
        selectedUpdatedLookup.entries.length >= currentPage + 2 * ENTRY_PAGE_SIZE - 1
            ? currentPage + 2 * ENTRY_PAGE_SIZE - 1
            : selectedUpdatedLookup.entries.length

    const getCurrentPageEnd = () =>
        selectedUpdatedLookup.entries.length >= currentPage + ENTRY_PAGE_SIZE - 1 ? currentPage + ENTRY_PAGE_SIZE - 1 : selectedUpdatedLookup.entries.length

    const nextButtonIsVisible = () =>
        selectedUpdatedLookup.entries.length > ENTRY_PAGE_SIZE && selectedUpdatedLookup.entries.length > currentPage + ENTRY_PAGE_SIZE
    const prevButtonIsVisible = () => currentPage > 0

    return (
        <div className={`d-flex flex-column h-100 w-100 ${classes.main} bg-white m-2 overflow-auto`}>
            {selectedUpdatedLookup && (
                <>
                    <div className={`d-flex ${classes.addField} mb-2 small`}>
                        <StandardButton label="Add column" size="small" iconClasses="fal fa-plus m-auto" onClick={onLookupFieldAdded} />
                        <StandardButton
                            className="ms-2"
                            label="Add entry"
                            size="small"
                            iconClasses="fal fa-plus"
                            ariaLabel="add-entry-button"
                            onClick={onLookupEntryAdded}
                        />
                    </div>
                    <div className={`${classes.tableWrapper} overflow-auto me-2`}>
                        <table className="p-2 table table-bordered">
                            <thead>
                                <tr className={`${classes.head} bg-grey`}>
                                    {selectedUpdatedLookup.fields.map(field => (
                                        <th key={field.id} className={`${classes.label} fw-normal`} scope="col">
                                            <LookupField
                                                selectedLookup={selectedUpdatedLookup}
                                                field={field}
                                                onLookupChange={onLookupChange}
                                                removeField={removeField}
                                            />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {selectedUpdatedLookup.entries.slice(currentPage, currentPage + ENTRY_PAGE_SIZE).map(entry => (
                                    <tr key={entry.id}>
                                        {selectedUpdatedLookup.fields.map(field => (
                                            <th key={field.id} scope="row">
                                                <LookupEntry
                                                    field={field}
                                                    entry={entry}
                                                    removeLookupEntry={removeLookupEntry}
                                                    selectedLookup={selectedUpdatedLookup}
                                                    onLookupChange={onLookupChange}
                                                />
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="d-flex">
                            <div className="d-flex ms-auto">
                                {prevButtonIsVisible() && (
                                    <div className={`d-flex pt-2 small`}>
                                        <StandardButton
                                            label={`Previous (${currentPage - ENTRY_PAGE_SIZE} - ${currentPage - 1})`}
                                            size="small"
                                            iconClasses="fal fa-arrow-left"
                                            ariaLabel="previous-page-button"
                                            onClick={onPreviousPage}
                                        />
                                    </div>
                                )}
                                {nextButtonIsVisible() && (
                                    <div className={`d-flex pt-2 ps-4 ms-0 small`}>
                                        <StandardButton
                                            label={`Next (${currentPage + ENTRY_PAGE_SIZE} - ${getNextPageEnd()})`}
                                            size="small"
                                            iconClasses="fal fa-arrow-right"
                                            ariaLabel="next-page-button"
                                            onClick={onNextPage}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        {(prevButtonIsVisible() || nextButtonIsVisible()) && (
                            <span className="text-blue ms-auto pt-2">{`Currently showing ${currentPage} - ${getCurrentPageEnd()} out of ${
                                selectedUpdatedLookup.entries.length
                            }`}</span>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default LookupEntries
