import "./scss/app.scss"
import { Route, Switch } from "react-router-dom"
import SearchDashboard from "./components/legacy_dashboard/searchDashboard/SearchDashboard"
import DataConfiguration from "./components/dataConfig/DataConfiguration"
import CustomerGroupsDashboard from "./components/customerGroups/CustomerGroupsDashboard"
import UserGroups from "./components/userGroups/UserGroups"
import Home from "./components/home/Home"
import LandingPage from "./components/landingPage/LandingPage"
import CacheBuster from "./components/CacheBuster"
import { LayoutProvider } from "./contexts/LayoutContext"
import { ActionStripProvider } from "./contexts/ActionStripContext"
import { CustomerProvider } from "./contexts/CustomerContext"
import { ToastContainer } from "react-toastify"
import NotFound from "./NotFound"
import RingFenceOfLegacyCode from "./components/RingFenceOfLegacyCode"
import Onboarding from "./routes/onboarding/components/Onboarding"
import { AnonymousRoutes } from "./routes/anonymousRoutes"
import { TranslationProvider } from "./contexts/TranslationContext"
import { EntityProvider } from "./contexts/EntityContext"
import { CompatRouter } from "react-router-dom-v5-compat"
import { UserProvider } from "./contexts/UserContext"
import ClientRoutes from "./routes/client/ClientRoutes"
import { useFeatureToggle } from "./hooks/useFeatureToggle"
import { OverlayProvider } from "./contexts/overlay/OverlayContext"
import SelectedEntitiesProvider from "./contexts/SelectedEntitiesContext"
import { EventProvider } from "./contexts/EventContext"

const App = ({ store }: { store: any }) => {
    const { isEnabled: isNewCustomerSearchEnabled } = useFeatureToggle("newCustomerSearch")
    const { isEnabled: isNewRoutingEnabled } = useFeatureToggle("newRouting")
    const { isEnabled: isNewEntityStructureEnabled } = useFeatureToggle("newEntityStructure")

    const invevoRoutes = () => (
        <Switch>
            <Route exact path="/" render={() => <LandingPage />} />
            <Route path="/home" render={() => <Home store={store} />} />
            {isNewRoutingEnabled && <Route path="/client/:client" render={() => <ClientRoutes />} />}
            <Route path="/dashboard" render={() => <SearchDashboard />} />
            <Route path="/data-import-config" render={() => <DataConfiguration />} />
            <Route path="/data-configuration" render={() => <DataConfiguration />} />
            <Route path="/customer-groups" render={() => <CustomerGroupsDashboard />} />
            <Route path="/user-groups" render={() => <UserGroups />} />
            <Route path={AnonymousRoutes.SIGN_UP} render={() => <Onboarding />} />
            {/* @ts-ignore */}
            <Route path="/*" render={props => <NotFound url={props.match.params[0]} />} />
        </Switch>
    )

    return (
        <CacheBuster>
            <LayoutProvider>
                <TranslationProvider>
                    <ActionStripProvider>
                        <SelectedEntitiesProvider>
                            <CustomerProvider>
                                <EntityProvider>
                                    <CompatRouter>
                                        <UserProvider>
                                            <OverlayProvider>
                                                <ToastContainer />
                                                <EventProvider>
                                                    {isNewCustomerSearchEnabled || isNewEntityStructureEnabled ? (
                                                        <RingFenceOfLegacyCode store={store}>{invevoRoutes()}</RingFenceOfLegacyCode>
                                                    ) : (
                                                        invevoRoutes()
                                                    )}
                                                </EventProvider>
                                            </OverlayProvider>
                                        </UserProvider>
                                    </CompatRouter>
                                </EntityProvider>
                            </CustomerProvider>
                        </SelectedEntitiesProvider>
                    </ActionStripProvider>
                </TranslationProvider>
            </LayoutProvider>
        </CacheBuster>
    )
}

export default App
