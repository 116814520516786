import { FileImportConfig, FieldConfig, FileType, ReferenceFieldConfig, CustomerFileImportConfig, TransactionFileImportConfig } from "../types/FileImportConfig"
import { v4 as uuidv4 } from "uuid"
import { FileImportConfigFieldTrimOption, FileImportConfigFieldTrimOptionEnum } from "../types/FileImportConfigTrimOption"
import { FileImportConfigFieldDataTypeEnum } from "../types/FileImportConfigFieldDataType"
import { FileImportConfigColumnType } from "../types/FileImportConfigColumnType"
import { RejectActionEnum } from "../types/RejectActionEnum"
import { RejectMandatoryActionEnum } from "../types/RejectMandatoryActionEnum"
import EntityConfig from "../../entityConfig/types/EntityConfig"
import EntityDataField from "../../entityConfig/types/EntityDataField"
import { FileFormat } from "../components/utils"
import Lookup from "../../../types/Lookup"

export type FileImportConfigDashboardState = {
    configs: FileImportConfigState[]
    entities: EntityConfig[]
    editingDataFields: EntityDataField[]
    lookups: Lookup[]
    editingConfig?: FileImportConfigState
}

export type FieldConfigState = FieldConfig & {
    key: string
    trimOptions: FileImportConfigFieldTrimOptionState[]
    columns: FileImportConfigColumnTypeState[]
}

export type ReferenceFieldConfigState = ReferenceFieldConfig & {
    trimOptions: FileImportConfigFieldTrimOptionState[]
    columns: FileImportConfigColumnTypeState[]
}

export type FileImportConfigState = FileImportConfig & {
    dataFileImportConfig: DataFileImportConfigState
}

export type DataFileImportConfigState = TransactionFileImportConfigState | CustomerFileImportConfigState

export type TransactionFileImportConfigState = TransactionFileImportConfig & {
    mandatoryFieldConfig: ReferenceFieldConfigState
    mandatoryCustomerFieldConfig: ReferenceFieldConfigState
    optionalFieldConfigs: FieldConfigState[]
}

export type CustomerFileImportConfigState = CustomerFileImportConfig & {
    mandatoryFieldConfig: ReferenceFieldConfigState
    optionalFieldConfigs: FieldConfigState[]
}

export type FileImportConfigFieldTrimOptionState = FileImportConfigFieldTrimOption & {
    key: string
}

export type FileImportConfigColumnTypeState = FileImportConfigColumnType & {
    key: string
}

export enum FileImportConfigActionEnum {
    FILE_IMPORT_CONFIGS_RETRIEVED,
    FILE_IMPORT_EDITING_CONFIG_UPDATED,
    FILE_IMPORT_EDITING_CONFIG_DISCARDED,
    FILE_IMPORT_EDITING_CONFIG_SAVED,
    FILE_IMPORT_CONFIG_ADDED,
    FILE_IMPORT_CONFIG_SELECTED,
    FILE_IMPORT_CONFIG_DELETED,
    DATA_FILE_CONFIG_UPDATED,
    DATA_FILE_CONFIG_REFERENCE_FIELD_CONFIG_UPDATED,
    DATA_FILE_CONFIG_CUSTOMER_REFERENCE_FIELD_CONFIG_UPDATED,
    DATA_FILE_CONFIG_REFERENCE_FIELD_CONFIG_TRIM_OPTION_ADDED,
    DATA_FILE_CONFIG_CUSTOMER_REFERENCE_FIELD_CONFIG_TRIM_OPTION_ADDED,
    DATA_FILE_CONFIG_FIELD_CONFIG_ADDED,
    DATA_FILE_CONFIG_FIELD_CONFIGS_UPLOADED,
    DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
    DATA_FILE_CONFIG_FIELD_CONFIG_REMOVED,
    DATA_FILE_CONFIG_FIELD_CONFIG_TRIM_OPTION_ADDED,
    ENTITIES_RETRIEVED,
    FILE_IMPORT_ENTITY_SELECTED,
    LOOKUPS_RETRIEVED
}

type RetrievedConfigsAction = {
    type: FileImportConfigActionEnum.FILE_IMPORT_CONFIGS_RETRIEVED
    configs: FileImportConfig[]
}

type LookupRetrievedAction = {
    type: FileImportConfigActionEnum.LOOKUPS_RETRIEVED
    lookups: Lookup[]
}

type RetrievedEntitiesAction = {
    type: FileImportConfigActionEnum.ENTITIES_RETRIEVED
    entities: EntityConfig[]
}
type EntitySelectedAction = {
    type: FileImportConfigActionEnum.FILE_IMPORT_ENTITY_SELECTED
    option: string
}

type AddConfigAction = {
    type: FileImportConfigActionEnum.FILE_IMPORT_CONFIG_ADDED
}

type SelectConfigAction = {
    type: FileImportConfigActionEnum.FILE_IMPORT_CONFIG_SELECTED
    reference: string
}

type DeleteConfigAction = {
    type: FileImportConfigActionEnum.FILE_IMPORT_CONFIG_DELETED
    reference: string
}

type EditingConfigAction = {
    type: FileImportConfigActionEnum.FILE_IMPORT_EDITING_CONFIG_UPDATED
    editingConfig: FileImportConfigState
}

type EditingConfigDiscardedAction = {
    type: FileImportConfigActionEnum.FILE_IMPORT_EDITING_CONFIG_DISCARDED
}

type EditingConfigSavedAction = {
    type: FileImportConfigActionEnum.FILE_IMPORT_EDITING_CONFIG_SAVED
}

type DataFileConfigAction = {
    type: FileImportConfigActionEnum.DATA_FILE_CONFIG_UPDATED
    dataFileImportConfig: DataFileImportConfigState
}

type DataFileConfigFieldConfigsAction = {
    type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED
    fieldConfig: FieldConfigState
}

type DataFileConfigReferenceFieldConfigAction = {
    type: FileImportConfigActionEnum.DATA_FILE_CONFIG_REFERENCE_FIELD_CONFIG_UPDATED
    fieldConfig: ReferenceFieldConfigState
}

type DataFileConfigCustomerReferenceFieldConfigAction = {
    type: FileImportConfigActionEnum.DATA_FILE_CONFIG_CUSTOMER_REFERENCE_FIELD_CONFIG_UPDATED
    fieldConfig: ReferenceFieldConfigState
}

type DataFileConfigFieldConfigAddedAction = {
    type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_ADDED
}

type DataFileConfigFieldConfigsUploadedAction = {
    type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIGS_UPLOADED
    fieldConfigs: FieldConfigState[]
}

type DataFileConfigFieldConfigsRemovedAction = {
    type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_REMOVED
    fieldConfigKey: string
}

type DataFileConfigFieldConfigTrimOptionAction = {
    type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_TRIM_OPTION_ADDED
    fieldConfigKey: string
}

type DataFileConfigReferenceFieldConfigTrimOptionAction = {
    type: FileImportConfigActionEnum.DATA_FILE_CONFIG_REFERENCE_FIELD_CONFIG_TRIM_OPTION_ADDED
}

type DataFileConfigCustomerReferenceFieldConfigTrimOptionAction = {
    type: FileImportConfigActionEnum.DATA_FILE_CONFIG_CUSTOMER_REFERENCE_FIELD_CONFIG_TRIM_OPTION_ADDED
}

export type FileImportConfigAction =
    | RetrievedConfigsAction
    | LookupRetrievedAction
    | EditingConfigAction
    | EditingConfigDiscardedAction
    | EditingConfigSavedAction
    | AddConfigAction
    | SelectConfigAction
    | DeleteConfigAction
    | DataFileConfigAction
    | DataFileConfigFieldConfigAddedAction
    | DataFileConfigFieldConfigsAction
    | DataFileConfigReferenceFieldConfigAction
    | DataFileConfigCustomerReferenceFieldConfigAction
    | DataFileConfigFieldConfigsUploadedAction
    | DataFileConfigFieldConfigsRemovedAction
    | DataFileConfigFieldConfigTrimOptionAction
    | DataFileConfigReferenceFieldConfigTrimOptionAction
    | DataFileConfigCustomerReferenceFieldConfigTrimOptionAction
    | RetrievedEntitiesAction
    | EntitySelectedAction

export default function FileImportConfigReducer(state: FileImportConfigDashboardState, action: FileImportConfigAction): FileImportConfigDashboardState {
    switch (action.type) {
        case FileImportConfigActionEnum.FILE_IMPORT_CONFIGS_RETRIEVED: {
            return {
                ...state,
                configs: action.configs.map(config => ({
                    ...config,
                    dataFileImportConfig: {
                        ...config.dataFileImportConfig,
                        mandatoryFieldConfig: {
                            ...config.dataFileImportConfig.mandatoryFieldConfig,
                            columns: config.dataFileImportConfig.mandatoryFieldConfig.columns.map(column => ({
                                ...column,
                                key: uuidv4()
                            })),
                            trimOptions: config.dataFileImportConfig.mandatoryFieldConfig.trimOptions.map(trimOption => ({
                                ...trimOption,
                                key: uuidv4()
                            }))
                        },
                        mandatoryCustomerFieldConfig:
                            config.dataFileImportConfig.type === FileType.TRANSACTION
                                ? {
                                    ...config.dataFileImportConfig.mandatoryCustomerFieldConfig,
                                    columns: config.dataFileImportConfig.mandatoryCustomerFieldConfig.columns.map(column => ({ ...column, key: uuidv4() })),
                                    trimOptions: config.dataFileImportConfig.mandatoryCustomerFieldConfig.trimOptions.map(trimOption => ({
                                        ...trimOption,
                                        key: uuidv4()
                                    }))
                                }
                                : { columns: [], trimOptions: [] },
                        optionalFieldConfigs: config.dataFileImportConfig.optionalFieldConfigs.map(fieldConfig => ({
                            ...fieldConfig,
                            key: uuidv4(),
                            columns: fieldConfig.columns.map(column => ({ ...column, key: uuidv4() })),
                            trimOptions: fieldConfig.trimOptions.map(trimOption => ({
                                ...trimOption,
                                key: uuidv4()
                            }))
                        }))
                    }
                }))
            }
        }

        case FileImportConfigActionEnum.LOOKUPS_RETRIEVED: {
            return {
                ...state,
                lookups: action.lookups
            }
        }

        case FileImportConfigActionEnum.ENTITIES_RETRIEVED: {
            return {
                ...state,
                entities: action.entities
            }
        }
        case FileImportConfigActionEnum.FILE_IMPORT_ENTITY_SELECTED: {
            const fields = state.entities.find(entity => entity.reference === action.option)?.fields

            return {
                ...state,
                editingDataFields: fields ? fields : []
            }
        }
        case FileImportConfigActionEnum.FILE_IMPORT_EDITING_CONFIG_UPDATED: {
            return {
                ...state,
                editingConfig: {
                    ...action.editingConfig
                }
            }
        }

        case FileImportConfigActionEnum.FILE_IMPORT_EDITING_CONFIG_DISCARDED: {
            const originalConfig = state.configs.find(c => c.reference === state.editingConfig?.reference)
            return {
                ...state,
                editingConfig: { ...originalConfig } as FileImportConfigState
            }
        }

        case FileImportConfigActionEnum.FILE_IMPORT_EDITING_CONFIG_SAVED: {
            return {
                ...state,
                configs: state.configs.map(config => {
                    if (config.reference !== state.editingConfig?.reference) {
                        return config
                    }

                    return {
                        ...state.editingConfig
                    }
                })
            }
        }

        case FileImportConfigActionEnum.FILE_IMPORT_CONFIG_ADDED: {
            const newCustomer: CustomerFileImportConfigState = {
                type: FileType.CUSTOMER,
                configName: "",
                fileNamePattern: "",
                delimiter: "",
                lineEnding: "",
                hasHeaderRow: false,
                hasQuotedFields: false,
                rejectMandatoryAction: RejectMandatoryActionEnum.FAIL_ROW,
                mandatoryFieldConfig: {
                    columns: [],
                    trimOptions: []
                },
                optionalFieldConfigs: []
            }
            const newConfig = {
                reference: uuidv4(),
                fileType: FileType.CUSTOMER,
                fileFormat: FileFormat.CSV,
                dataFileImportConfig: newCustomer
            }

            return {
                ...state,
                configs: [newConfig, ...state.configs],
                editingConfig: newConfig
            }
        }

        case FileImportConfigActionEnum.FILE_IMPORT_CONFIG_SELECTED: {
            const selectConfig = state.configs.find(config => config.reference === action.reference)
            const fields = state.entities.find(entity => entity.reference === selectConfig?.dataFileImportConfig.entityType)?.fields
            return {
                ...state,
                editingConfig: selectConfig,
                editingDataFields: fields ? fields : []
            }
        }

        case FileImportConfigActionEnum.FILE_IMPORT_CONFIG_DELETED: {
            return {
                ...state,
                configs: state.configs.filter(config => config.reference !== action.reference),
                editingConfig: undefined,
                editingDataFields: []
            }
        }

        case FileImportConfigActionEnum.DATA_FILE_CONFIG_UPDATED: {
            return {
                ...state,
                editingConfig: state.editingConfig
                    ? {
                        ...state.editingConfig,
                        dataFileImportConfig: {
                            ...action.dataFileImportConfig
                        }
                    }
                    : state.editingConfig
            }
        }
        case FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIGS_UPLOADED: {
            return {
                ...state,
                editingConfig: state.editingConfig
                    ? {
                        ...state.editingConfig,
                        dataFileImportConfig: {
                            ...state.editingConfig.dataFileImportConfig,
                            optionalFieldConfigs: [...state.editingConfig.dataFileImportConfig.optionalFieldConfigs, ...action.fieldConfigs]
                        }
                    }
                    : state.editingConfig
            }
        }

        case FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_ADDED: {
            return {
                ...state,
                editingConfig: state.editingConfig
                    ? {
                        ...state.editingConfig,
                        dataFileImportConfig: {
                            ...state.editingConfig.dataFileImportConfig,
                            optionalFieldConfigs: [
                                {
                                    key: uuidv4(),
                                    columns: [],
                                    dataFieldReference: "",
                                    dataType: { type: FileImportConfigFieldDataTypeEnum.TEXT },
                                    defaultValue: undefined,
                                    canBeEmpty: false,
                                    rejectAction: RejectActionEnum.FAIL_FIELD,
                                    trimOptions: []
                                },
                                ...state.editingConfig.dataFileImportConfig.optionalFieldConfigs
                            ]
                        }
                    }
                    : state.editingConfig
            }
        }

        case FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_REMOVED: {
            return {
                ...state,
                editingConfig: state.editingConfig
                    ? {
                        ...state.editingConfig,
                        dataFileImportConfig: {
                            ...state.editingConfig.dataFileImportConfig,
                            optionalFieldConfigs: state.editingConfig.dataFileImportConfig.optionalFieldConfigs.filter(
                                (fc: FieldConfigState) => fc.key !== action.fieldConfigKey
                            )
                        }
                    }
                    : state.editingConfig
            }
        }

        case FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED: {
            return {
                ...state,
                editingConfig: state.editingConfig
                    ? {
                        ...state.editingConfig,
                        dataFileImportConfig: {
                            ...state.editingConfig.dataFileImportConfig,
                            optionalFieldConfigs: state.editingConfig.dataFileImportConfig.optionalFieldConfigs.map((fieldConfig: FieldConfigState) => {
                                if (fieldConfig.key !== action.fieldConfig.key) return fieldConfig
                                return {
                                    ...fieldConfig,
                                    ...action.fieldConfig
                                }
                            })
                        }
                    }
                    : state.editingConfig
            }
        }

        case FileImportConfigActionEnum.DATA_FILE_CONFIG_REFERENCE_FIELD_CONFIG_UPDATED: {
            return {
                ...state,
                editingConfig: state.editingConfig
                    ? {
                        ...state.editingConfig,
                        dataFileImportConfig: {
                            ...state.editingConfig.dataFileImportConfig,
                            mandatoryFieldConfig: {
                                ...action.fieldConfig
                            }
                        }
                    }
                    : state.editingConfig
            }
        }

        case FileImportConfigActionEnum.DATA_FILE_CONFIG_CUSTOMER_REFERENCE_FIELD_CONFIG_UPDATED: {
            return {
                ...state,
                editingConfig:
                    state.editingConfig && state.editingConfig.dataFileImportConfig.type === FileType.TRANSACTION
                        ? {
                            ...state.editingConfig,
                            dataFileImportConfig: {
                                ...state.editingConfig.dataFileImportConfig,
                                mandatoryCustomerFieldConfig: {
                                    ...action.fieldConfig
                                }
                            }
                        }
                        : state.editingConfig
            }
        }

        case FileImportConfigActionEnum.DATA_FILE_CONFIG_REFERENCE_FIELD_CONFIG_TRIM_OPTION_ADDED: {
            return {
                ...state,
                editingConfig: state.editingConfig
                    ? {
                        ...state.editingConfig,
                        dataFileImportConfig: {
                            ...state.editingConfig.dataFileImportConfig,
                            mandatoryFieldConfig: {
                                ...state.editingConfig.dataFileImportConfig.mandatoryFieldConfig,
                                trimOptions: [
                                    {
                                        key: uuidv4(),
                                        type: FileImportConfigFieldTrimOptionEnum.REMOVE_FIRST_N_CHARACTERS,
                                        take: 1
                                    },
                                    ...state.editingConfig.dataFileImportConfig.mandatoryFieldConfig.trimOptions
                                ]
                            }
                        }
                    }
                    : state.editingConfig
            }
        }

        case FileImportConfigActionEnum.DATA_FILE_CONFIG_CUSTOMER_REFERENCE_FIELD_CONFIG_TRIM_OPTION_ADDED: {
            return {
                ...state,
                editingConfig:
                    state.editingConfig && state.editingConfig.dataFileImportConfig.type === FileType.TRANSACTION
                        ? {
                            ...state.editingConfig,
                            dataFileImportConfig: {
                                ...state.editingConfig.dataFileImportConfig,
                                mandatoryCustomerFieldConfig: {
                                    ...state.editingConfig.dataFileImportConfig.mandatoryCustomerFieldConfig,
                                    trimOptions: [
                                        {
                                            key: uuidv4(),
                                            type: FileImportConfigFieldTrimOptionEnum.REMOVE_FIRST_N_CHARACTERS,
                                            take: 1
                                        },
                                        ...state.editingConfig.dataFileImportConfig.mandatoryCustomerFieldConfig.trimOptions
                                    ]
                                }
                            }
                        }
                        : state.editingConfig
            }
        }

        case FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_TRIM_OPTION_ADDED: {
            return {
                ...state,
                editingConfig: state.editingConfig
                    ? {
                        ...state.editingConfig,
                        dataFileImportConfig: {
                            ...state.editingConfig.dataFileImportConfig,
                            optionalFieldConfigs: state.editingConfig.dataFileImportConfig.optionalFieldConfigs.map((fieldConfig: FieldConfigState) => {
                                if (fieldConfig.key !== action.fieldConfigKey) return fieldConfig
                                return {
                                    ...fieldConfig,
                                    trimOptions: [
                                        {
                                            key: uuidv4(),
                                            type: FileImportConfigFieldTrimOptionEnum.REMOVE_FIRST_N_CHARACTERS as FileImportConfigFieldTrimOptionEnum.REMOVE_FIRST_N_CHARACTERS,
                                            take: 1
                                        },
                                        ...fieldConfig.trimOptions
                                    ]
                                }
                            })
                        }
                    }
                    : state.editingConfig
            }
        }
    }
}
