import { Dropdown, Input, FormLabel, IconButton, AutoComplete, Checkbox, useFeatureToggle } from 'invevo-react-components'
import { useCallback, useEffect, useState } from 'react'
import { dataPrimitive } from '../../enums/dataPrimitive'
import { dataPrimitiveOptions } from '../../enums/dataPrimitiveOptions'
import classes from './DataField.module.scss'
import { fieldType } from '../../enums/fieldType'
import DataPrimitive from '../../../../types/DataPrimitive'
import moment from "moment-timezone";
import { useUser } from "../../../../contexts/UserContext";

const autoCompleteTransactionFields = [
    { id: 1, dataType: dataPrimitive.DATE, value: "eventdate" },
    { id: 2, dataType: dataPrimitive.DATE, value: "paymentpromisedate" },
    { id: 3, dataType: dataPrimitive.DATE, value: "statusdate" },
    { id: 4, dataType: dataPrimitive.TEXT, value: "customerreference" },
    { id: 5, dataType: dataPrimitive.TEXT, value: "analysisperiod" },
    { id: 6, dataType: dataPrimitive.TEXT, value: "assignment" },
    { id: 7, dataType: dataPrimitive.TEXT, value: "batchnumber" },
    { id: 8, dataType: dataPrimitive.TEXT, value: "branch" },
    { id: 9, dataType: dataPrimitive.TEXT, value: "buyerpurchasername" },
    { id: 10, dataType: dataPrimitive.TEXT, value: "buyerreference" },
    { id: 11, dataType: dataPrimitive.TEXT, value: "buyinggrouppayername" },
    { id: 12, dataType: dataPrimitive.TEXT, value: "candidatetype" },
    { id: 13, dataType: dataPrimitive.TEXT, value: "comments" },
    { id: 14, dataType: dataPrimitive.TEXT, value: "contractorname" },
    { id: 15, dataType: dataPrimitive.TEXT, value: "costcentre" },
    { id: 16, dataType: dataPrimitive.TEXT, value: "customercontact" },
    { id: 17, dataType: dataPrimitive.TEXT, value: "deliverynotenumber" },
    { id: 18, dataType: dataPrimitive.TEXT, value: "distributionchannel" },
    { id: 19, dataType: dataPrimitive.TEXT, value: "eventname" },
    { id: 20, dataType: dataPrimitive.TEXT, value: "externalreferenceid" },
    { id: 21, dataType: dataPrimitive.TEXT, value: "formeraccno" },
    { id: 22, dataType: dataPrimitive.TEXT, value: "headofficecode" },
    { id: 23, dataType: dataPrimitive.TEXT, value: "installmentnumber" },
    { id: 24, dataType: dataPrimitive.TEXT, value: "internalaccountmanagername" },
    { id: 25, dataType: dataPrimitive.TEXT, value: "internalsalespersonname" },
    { id: 26, dataType: dataPrimitive.TEXT, value: "invoiceaccountcode" },
    { id: 27, dataType: dataPrimitive.TEXT, value: "itemref" },
    { id: 28, dataType: dataPrimitive.TEXT, value: "itemreference" },
    { id: 29, dataType: dataPrimitive.TEXT, value: "mainref" },
    { id: 30, dataType: dataPrimitive.TEXT, value: "mamconssalesperson" },
    { id: 31, dataType: dataPrimitive.TEXT, value: "netcurrentvalue" },
    { id: 32, dataType: dataPrimitive.TEXT, value: "newinvoicecontactname" },
    { id: 33, dataType: dataPrimitive.TEXT, value: "payername" },
    { id: 34, dataType: dataPrimitive.TEXT, value: "paymentreason" },
    { id: 35, dataType: dataPrimitive.TEXT, value: "ponumber" },
    { id: 36, dataType: dataPrimitive.TEXT, value: "postingkey" },
    { id: 37, dataType: dataPrimitive.TEXT, value: "productcode" },
    { id: 38, dataType: dataPrimitive.TEXT, value: "productdescription" },
    { id: 39, dataType: dataPrimitive.TEXT, value: "productdivision" },
    { id: 40, dataType: dataPrimitive.TEXT, value: "productgroup" },
    { id: 41, dataType: dataPrimitive.TEXT, value: "productname" },
    { id: 42, dataType: dataPrimitive.TEXT, value: "purchaseordernumber" },
    { id: 43, dataType: dataPrimitive.TEXT, value: "purchasingdocument" },
    { id: 44, dataType: dataPrimitive.TEXT, value: "reference1" },
    { id: 45, dataType: dataPrimitive.TEXT, value: "reference3" },
    { id: 46, dataType: dataPrimitive.TEXT, value: "remotebranch" },
    { id: 47, dataType: dataPrimitive.TEXT, value: "returnnote" },
    { id: 48, dataType: dataPrimitive.TEXT, value: "revenuetype" },
    { id: 49, dataType: dataPrimitive.TEXT, value: "salesarea" },
    { id: 50, dataType: dataPrimitive.TEXT, value: "salescode" },
    { id: 51, dataType: dataPrimitive.TEXT, value: "soldtocustomername" },
    { id: 52, dataType: dataPrimitive.TEXT, value: "taxcode" },
    { id: 53, dataType: dataPrimitive.TEXT, value: "taxnumber1" },
    { id: 54, dataType: dataPrimitive.TEXT, value: "ticketnumber" },
    { id: 55, dataType: dataPrimitive.TEXT, value: "transactioncategory10name" },
    { id: 56, dataType: dataPrimitive.TEXT, value: "transactioncategory11name" },
    { id: 57, dataType: dataPrimitive.TEXT, value: "transactioncategory6name" },
    { id: 58, dataType: dataPrimitive.TEXT, value: "transactioncategory7name" },
    { id: 59, dataType: dataPrimitive.TEXT, value: "transactionref" },
    { id: 60, dataType: dataPrimitive.TEXT, value: "vatcurrentvalue" },
    { id: 61, dataType: dataPrimitive.TEXT, value: "weekendingdate" }
]

const autoCompleteCustomerFields = [
    { id: 1, dataType: dataPrimitive.BOOLEAN, value: "buyerreferencerequired" },
    { id: 2, dataType: dataPrimitive.BOOLEAN, value: "charityregistration" },
    { id: 3, dataType: dataPrimitive.BOOLEAN, value: "epodavailable" },
    { id: 4, dataType: dataPrimitive.BOOLEAN, value: "issupplier" },
    { id: 5, dataType: dataPrimitive.BOOLEAN, value: "porequired" },
    { id: 6, dataType: dataPrimitive.BOOLEAN, value: "siteaddressmandatoryoninvoices" },
    { id: 7, dataType: dataPrimitive.BOOLEAN, value: "statementaccountonly" },
    { id: 8, dataType: dataPrimitive.DATE, value: "accountcloseddate" },
    { id: 9, dataType: dataPrimitive.DATE, value: "customerdateofbirth" },
    { id: 10, dataType: dataPrimitive.DATE, value: "guaranteedate" },
    { id: 11, dataType: dataPrimitive.DATE, value: "guaranteeexpirydate" },
    { id: 12, dataType: dataPrimitive.DATE, value: "timeascustomer" },
    { id: 13, dataType: dataPrimitive.DATE, value: "transactionseventdate" },
    { id: 14, dataType: dataPrimitive.NUMBER, value: "balance" },
    { id: 15, dataType: dataPrimitive.NUMBER, value: "clientdepositbillingvalue" },
    { id: 16, dataType: dataPrimitive.NUMBER, value: "clientdepositreportingvalue" },
    { id: 17, dataType: dataPrimitive.NUMBER, value: "depositvalue" },
    { id: 18, dataType: dataPrimitive.NUMBER, value: "disputedoverduepercentage" },
    { id: 19, dataType: dataPrimitive.NUMBER, value: "fallingdue" },
    { id: 20, dataType: dataPrimitive.NUMBER, value: "guaranteevalue" },
    { id: 21, dataType: dataPrimitive.NUMBER, value: "guaranteevalue1" },
    { id: 22, dataType: dataPrimitive.NUMBER, value: "highestdunningage" },
    { id: 23, dataType: dataPrimitive.NUMBER, value: "ordervalue" },
    { id: 24, dataType: dataPrimitive.NUMBER, value: "overdue" },
    { id: 25, dataType: dataPrimitive.NUMBER, value: "turnover" },
    { id: 26, dataType: dataPrimitive.TEXT, value: "billtocustomercode" },
    { id: 27, dataType: dataPrimitive.TEXT, value: "billtocustomername" },
    { id: 28, dataType: dataPrimitive.TEXT, value: "bookpointacctno" },
    { id: 29, dataType: dataPrimitive.TEXT, value: "brokerpayername" },
    { id: 30, dataType: dataPrimitive.TEXT, value: "buyerpurchasername" },
    { id: 31, dataType: dataPrimitive.TEXT, value: "buyerreference" },
    { id: 32, dataType: dataPrimitive.TEXT, value: "buyinggrouppayername" },
    { id: 33, dataType: dataPrimitive.TEXT, value: "collectorname" },
    { id: 34, dataType: dataPrimitive.TEXT, value: "companycode" },
    { id: 35, dataType: dataPrimitive.TEXT, value: "companyname" },
    { id: 36, dataType: dataPrimitive.TEXT, value: "costcentre" },
    { id: 37, dataType: dataPrimitive.TEXT, value: "countryext" },
    { id: 38, dataType: dataPrimitive.TEXT, value: "creditcontroller" },
    { id: 39, dataType: dataPrimitive.TEXT, value: "creditrepgroup" },
    { id: 40, dataType: dataPrimitive.TEXT, value: "custno" },
    { id: 41, dataType: dataPrimitive.TEXT, value: "customerextension1" },
    { id: 42, dataType: dataPrimitive.TEXT, value: "customerkeyword1" },
    { id: 43, dataType: dataPrimitive.TEXT, value: "customerkeyword2" },
    { id: 44, dataType: dataPrimitive.TEXT, value: "customerkeyword3" },
    { id: 45, dataType: dataPrimitive.TEXT, value: "customerkeyword4" },
    { id: 46, dataType: dataPrimitive.TEXT, value: "customerkeyword5" },
    { id: 47, dataType: dataPrimitive.TEXT, value: "customerprofession" },
    { id: 48, dataType: dataPrimitive.TEXT, value: "debtorcategory9st" },
    { id: 49, dataType: dataPrimitive.TEXT, value: "debtorcategory9string" },
    { id: 50, dataType: dataPrimitive.TEXT, value: "dunningstatus" },
    { id: 51, dataType: dataPrimitive.TEXT, value: "dunningstep" },
    { id: 52, dataType: dataPrimitive.TEXT, value: "ebillingstatus" },
    { id: 53, dataType: dataPrimitive.TEXT, value: "eventstartdate" },
    { id: 54, dataType: dataPrimitive.TEXT, value: "externalcreditprovidercode" },
    { id: 55, dataType: dataPrimitive.TEXT, value: "externalreferenceid" },
    { id: 56, dataType: dataPrimitive.TEXT, value: "formeraccno" },
    { id: 57, dataType: dataPrimitive.TEXT, value: "globaldivision" },
    { id: 58, dataType: dataPrimitive.TEXT, value: "globalparentcode" },
    { id: 59, dataType: dataPrimitive.TEXT, value: "globalparentname" },
    { id: 60, dataType: dataPrimitive.TEXT, value: "guaranteenotes" },
    { id: 61, dataType: dataPrimitive.TEXT, value: "guaranteetype" },
    { id: 62, dataType: dataPrimitive.TEXT, value: "guarantorname" },
    { id: 63, dataType: dataPrimitive.TEXT, value: "guarantorname1" },
    { id: 64, dataType: dataPrimitive.TEXT, value: "headofficecode" },
    { id: 65, dataType: dataPrimitive.TEXT, value: "headofficename" },
    { id: 66, dataType: dataPrimitive.TEXT, value: "iban" },
    { id: 67, dataType: dataPrimitive.TEXT, value: "insegna" },
    { id: 68, dataType: dataPrimitive.TEXT, value: "insuredlimit" },
    { id: 69, dataType: dataPrimitive.TEXT, value: "internalaccountdirectorname" },
    { id: 70, dataType: dataPrimitive.TEXT, value: "internalaccountmanagername" },
    { id: 71, dataType: dataPrimitive.TEXT, value: "internalcustomerservicename" },
    { id: 72, dataType: dataPrimitive.TEXT, value: "internalsalespersonname" },
    { id: 73, dataType: dataPrimitive.TEXT, value: "keyword" },
    { id: 74, dataType: dataPrimitive.TEXT, value: "kunnr" },
    { id: 75, dataType: dataPrimitive.TEXT, value: "kverm" },
    { id: 76, dataType: dataPrimitive.TEXT, value: "legalstatus" },
    { id: 77, dataType: dataPrimitive.TEXT, value: "name" },
    { id: 78, dataType: dataPrimitive.TEXT, value: "nationaldivision" },
    { id: 79, dataType: dataPrimitive.TEXT, value: "oldaccountcode" },
    { id: 80, dataType: dataPrimitive.TEXT, value: "originalaccountcode" },
    { id: 81, dataType: dataPrimitive.TEXT, value: "parentcompanycode" },
    { id: 82, dataType: dataPrimitive.TEXT, value: "partnername" },
    { id: 83, dataType: dataPrimitive.TEXT, value: "paymentmethod" },
    { id: 84, dataType: dataPrimitive.TEXT, value: "preferredlanguage" },
    { id: 85, dataType: dataPrimitive.TEXT, value: "product" },
    { id: 86, dataType: dataPrimitive.TEXT, value: "productcode" },
    { id: 87, dataType: dataPrimitive.TEXT, value: "productdivision" },
    { id: 88, dataType: dataPrimitive.TEXT, value: "productgroup" },
    { id: 89, dataType: dataPrimitive.TEXT, value: "productname" },
    { id: 90, dataType: dataPrimitive.TEXT, value: "profileclass" },
    { id: 91, dataType: dataPrimitive.TEXT, value: "propertycode" },
    { id: 92, dataType: dataPrimitive.TEXT, value: "revenuetype" },
    { id: 93, dataType: dataPrimitive.TEXT, value: "riskcategory" },
    { id: 94, dataType: dataPrimitive.TEXT, value: "risklowmediumhigh" },
    { id: 95, dataType: dataPrimitive.TEXT, value: "salesarea" },
    { id: 96, dataType: dataPrimitive.TEXT, value: "salesgroup" },
    { id: 97, dataType: dataPrimitive.TEXT, value: "salesregion" },
    { id: 98, dataType: dataPrimitive.TEXT, value: "salesrepcode" },
    { id: 99, dataType: dataPrimitive.TEXT, value: "salesrepid" },
    { id: 100, dataType: dataPrimitive.TEXT, value: "salesservicegroupcode" },
    { id: 101, dataType: dataPrimitive.TEXT, value: "salesservicegroupname" },
    { id: 102, dataType: dataPrimitive.TEXT, value: "shiptocustomercode" },
    { id: 103, dataType: dataPrimitive.TEXT, value: "shortcode" },
    { id: 104, dataType: dataPrimitive.TEXT, value: "shortname" },
    { id: 105, dataType: dataPrimitive.TEXT, value: "siccode" },
    { id: 106, dataType: dataPrimitive.TEXT, value: "sourcesystem" },
    { id: 107, dataType: dataPrimitive.TEXT, value: "specialinstructions1" },
    { id: 108, dataType: dataPrimitive.TEXT, value: "specialinstructions2" },
    { id: 109, dataType: dataPrimitive.TEXT, value: "specialinstructions3" },
    { id: 110, dataType: dataPrimitive.TEXT, value: "statementaccountcode" },
    { id: 111, dataType: dataPrimitive.TEXT, value: "swift" },
    { id: 112, dataType: dataPrimitive.TEXT, value: "tenantstatus" },
    { id: 113, dataType: dataPrimitive.TEXT, value: "tradetype" },
    { id: 114, dataType: dataPrimitive.TEXT, value: "transactioncategory2name" },
    { id: 115, dataType: dataPrimitive.TEXT, value: "transactionseventname" },
    { id: 116, dataType: dataPrimitive.TEXT, value: "ultimateparentname" },
    { id: 117, dataType: dataPrimitive.TEXT, value: "unitaddress" },
    { id: 118, dataType: dataPrimitive.TEXT, value: "unitcode" },
    { id: 119, dataType: dataPrimitive.TEXT, value: "vat" },
    { id: 120, dataType: dataPrimitive.TEXT, value: "vatregistrationnumber" },
    { id: 121, dataType: dataPrimitive.TEXT, value: "vendornumber" },
    { id: 122, dataType: dataPrimitive.TEXT, value: "workflowcode" },
    { id: 123, dataType: dataPrimitive.TEXT, value: "zamir" }
]

const hardcodedFields = [
    { "fieldName": "customerreference", "displayName": "Account Code", "fieldType": "TRANSACTION", "dataPrimitive": "TEXT" },
    { "fieldName": "reference", "displayName": "Account Code", "fieldType": "CUSTOMER", "dataPrimitive": "TEXT" },
    { "fieldName": "allcommunicationsonhold", "displayName": "All Communications On Hold", "fieldType": "CUSTOMER", "dataPrimitive": "BOOLEAN" },
    { "fieldName": "allowautodecreasetradelimit", "displayName": "Allow Auto Decrease Trade Limit", "fieldType": "CUSTOMER", "dataPrimitive": "BOOLEAN" },
    { "fieldName": "allowautoincreasetradelimit", "displayName": "Allow Auto Increase Trade Limit", "fieldType": "CUSTOMER", "dataPrimitive": "BOOLEAN" },
    { "fieldName": "alwaysonstop", "displayName": "Always On Stop", "fieldType": "CUSTOMER", "dataPrimitive": "BOOLEAN" },
    { "fieldName": "balance", "displayName": "Balance", "fieldType": "CUSTOMER", "dataPrimitive": "NUMBER" },
    { "fieldName": "billingcurrency", "displayName": "Billing Currency", "fieldType": "TRANSACTION", "dataPrimitive": "LOOKUP", "lookup": "currency" },
    { "fieldName": "branch", "displayName": "Branch", "fieldType": "CUSTOMER", "dataPrimitive": "LOOKUP", "lookup": "branch" },
    { "fieldName": "closed", "displayName": "Closed", "fieldType": "TRANSACTION", "dataPrimitive": "BOOLEAN" },
    { "fieldName": "closeddate", "displayName": "Closed Date", "fieldType": "TRANSACTION", "dataPrimitive": "DATE" },
    { "fieldName": "collectormanagedaccount", "displayName": "Collector Managed Account", "fieldType": "CUSTOMER", "dataPrimitive": "BOOLEAN" },
    { "fieldName": "comments", "displayName": "Reporting Note", "fieldType": "TRANSACTION", "dataPrimitive": "TEXT" },
    { "fieldName": "companyregistration", "displayName": "Company Registration", "fieldType": "CUSTOMER", "dataPrimitive": "TEXT" },
    { "fieldName": "country", "displayName": "Country", "fieldType": "CUSTOMER", "dataPrimitive": "LOOKUP", "lookup": "country" },
    { "fieldName": "currencycode", "displayName": "Currency Code", "fieldType": "CUSTOMER", "dataPrimitive": "LOOKUP", "lookup": "currency" },
    { "fieldName": "currentbillingvalue", "displayName": "Current Billing Value", "fieldType": "TRANSACTION", "dataPrimitive": "NUMBER" },
    { "fieldName": "currentreportingvalue", "displayName": "Current Reporting Value", "fieldType": "TRANSACTION", "dataPrimitive": "NUMBER" },
    { "fieldName": "customercontact", "displayName": "Reporting Contact", "fieldType": "TRANSACTION", "dataPrimitive": "TEXT" },
    { "fieldName": "daysoverdue", "displayName": "Days Overdue", "fieldType": "TRANSACTION", "dataPrimitive": "NUMBER" },
    { "fieldName": "duedate", "displayName": "Due Date", "fieldType": "TRANSACTION", "dataPrimitive": "DATE" },
    { "fieldName": "iskeyaccount", "displayName": "Is Key Account", "fieldType": "CUSTOMER", "dataPrimitive": "BOOLEAN" },
    { "fieldName": "issueddate", "displayName": "Issued Date", "fieldType": "TRANSACTION", "dataPrimitive": "DATE" },
    { "fieldName": "itemref", "displayName": "Item Ref", "fieldType": "TRANSACTION", "dataPrimitive": "TEXT" },
    { "fieldName": "ledger", "displayName": "Ledger", "fieldType": "CUSTOMER", "dataPrimitive": "LOOKUP", "lookup": "ledger" },
    { "fieldName": "name", "displayName": "Name", "fieldType": "CUSTOMER", "dataPrimitive": "TEXT" },
    { "fieldName": "neveronstop", "displayName": "Never On Stop", "fieldType": "CUSTOMER", "dataPrimitive": "BOOLEAN" },
    { "fieldName": "openingbillingvalue", "displayName": "Opening Billing Value", "fieldType": "TRANSACTION", "dataPrimitive": "NUMBER" },
    { "fieldName": "openingreportingvalue", "displayName": "Opening Reporting Value", "fieldType": "TRANSACTION", "dataPrimitive": "NUMBER" },
    { "fieldName": "overdue", "displayName": "Overdue", "fieldType": "CUSTOMER", "dataPrimitive": "NUMBER" },
    { "fieldName": "paymentpromisedate", "displayName": "Promised Date", "fieldType": "TRANSACTION", "dataPrimitive": "DATE" },
    { "fieldName": "paymentterms", "displayName": "Payment Terms", "fieldType": "CUSTOMER", "dataPrimitive": "LOOKUP", "lookup": "payment terms" },
    { "fieldName": "reference", "displayName": "Reference", "fieldType": "TRANSACTION", "dataPrimitive": "TEXT" },
    { "fieldName": "reportingcurrency", "displayName": "Reporting Currency", "fieldType": "TRANSACTION", "dataPrimitive": "LOOKUP", "lookup": "currency" },
    { "fieldName": "status", "displayName": "Status", "fieldType": "TRANSACTION", "dataPrimitive": "LOOKUP", "lookup": "transaction status" },
    { "fieldName": "status", "displayName": "Status", "fieldType": "CUSTOMER", "dataPrimitive": "LOOKUP", "lookup": "customer status" },
    { "fieldName": "substatus", "displayName": "Sub Status", "fieldType": "TRANSACTION", "dataPrimitive": "LOOKUP", "lookup": "transaction substatus" },
    { "fieldName": "type", "displayName": "Type", "fieldType": "TRANSACTION", "dataPrimitive": "LOOKUP", "lookup": "transaction type" }
]

const DataField = ({ dataField, onDataFieldChange, onDataFieldRemoved, lookups }) => {
    const { isEnabled: isNewCustomerSearchEnabled } = useFeatureToggle("newCustomerSearch")

    const [selectedField, setSelectedField] = useState()
    const [selectedDataType, setSelectedDataType] = useState()
    const [fieldOptions, setFieldOptions] = useState([])

    const user = useUser()
    const isUserAdmin = user.permissions.hasAdminPermission

    const timezones = moment.tz.names().map((tz, index) => ({
        id: index,
        value: tz,
        label: tz
    }))

    const lookupOptions = useCallback(() =>
        lookups.map((lookup, index) => ({ id: 5 + index, value: lookup.reference ? lookup.reference : undefined }))
            .filter(l => l.value !== undefined), [lookups])

    useEffect(() => {
        const options = dataField.fieldType === fieldType.CUSTOMER
            ? autoCompleteCustomerFields
            : dataField.fieldType === fieldType.TRANSACTION ? autoCompleteTransactionFields : []
        setFieldOptions(options)
    }, [dataField])

    useEffect(() => {
        const selectedOptionFromList = fieldOptions.find(option => option.value === selectedField)
        if (selectedOptionFromList) {
            setSelectedDataType(dataPrimitiveOptions.find(o => o.value === selectedOptionFromList.dataType))
        } else {
            setSelectedDataType(retrieveOption(dataField, lookupOptions))
        }
    }, [selectedField, fieldOptions, dataField, lookupOptions])

    const onDataTypeChange = selectedOption => onDataFieldChange({
        ...dataField,
        dataPrimitive: dataPrimitiveOptions.includes(selectedOption) ? selectedOption.value : dataPrimitive.LOOKUP,
        lookup: dataPrimitiveOptions.includes(selectedOption) ? undefined : selectedOption.value
    })

    const onDisplayNameChange = event => onDataFieldChange({
        ...dataField,
        displayName: event.target.value
    })

    const onFieldNameChange = (option) => {
        onDataFieldChange({ ...dataField, fieldName: option })
        setSelectedField(option)
    }

    const removeField = id => onDataFieldRemoved(id)

    const toggleIsQuickFilter = () => onDataFieldChange({ ...dataField, isQuickFilter: !dataField.isQuickFilter })

    const onTimezoneChange = timezone => onDataFieldChange({ ...dataField, timezone })

    const isDataTypeChangeable = isUserAdmin || (dataField.editable && dataField.isFromFile)

    return (
        <div className="d-flex flex-grow-1 bg-grey p-3 pb-6 mb-2 text-grey">
            <div className={`d-flex flex-column pe-3 ${classes.box}`}>
                <div className="d-flex">
                    <i className="fal fa-database mt-1 me-1" />
                    <span className="text-uppercase small">Data field name</span>
                </div>
                {dataField.editable || dataField.isFromFile ?
                    <AutoComplete
                        className={`mt-1 ${classes.input}`}
                        placeholder="Please enter value"
                        initialValue={dataField.fieldName}
                        options={dataField.fieldType === fieldType.CUSTOMER
                            ? autoCompleteCustomerFields
                            : dataField.fieldType === fieldType.TRANSACTION ? autoCompleteTransactionFields : []
                        }
                        onChange={onFieldNameChange}
                        ariaLabel={`field-name-autocomplete`}
                    /> : <FormLabel className={`mt-1 ${classes.input}`} value={dataField.fieldName} enableTooltip={true} />
                }
            </div>

            <div className={`d-flex flex-column pe-3 ${classes.box}`}>
                <span className="text-uppercase small">Field data type</span>
                {lookupOptions() &&
                    <Dropdown
                        className={`mt-1 ${classes.dropdown}`}
                        options={dataPrimitiveOptions.concat(lookupOptions())}
                        onOptionSelected={onDataTypeChange}
                        defaultOption={selectedDataType}
                        disabled={!isDataTypeChangeable}
                        ariaLabel={`field-data-type-dropdown-${dataField.fieldName}`}
                    />
                }
            </div>

            <div className={`d-flex pe-3 ${classes.box}`}>
                <div className='d-flex flex-column pe-3'>
                    <span className="text-uppercase small">display name</span>
                    <Input
                        className={`mt-1 ${classes.input}`}
                        placeholder="Please enter field name"
                        value={dataField.displayName}
                        onChange={onDisplayNameChange}
                        ariaLabel={`field-display-name-input-${dataField.fieldName}`}
                    />
                </div>
                {dataField.dataPrimitive === DataPrimitive.DATE &&
                    <div className="d-flex flex-column pe-3">
                        <span className="text-uppercase small">timezone</span>
                        <AutoComplete
                            className={`mt-1 ${classes.timezone}`}
                            placeholder="Please select a timezone"
                            options={timezones}
                            initialValue={dataField.timezone}
                            onChange={onTimezoneChange}
                        />
                    </div>
                }

            </div>
            <div className={`d-flex pe-3  justify-content-end`}>
                {isNewCustomerSearchEnabled && dataField.fieldType === fieldType.CUSTOMER &&
                    <div className="d-flex flex-column pe-3">
                        <span className="text-uppercase small">Quick filter</span>
                        <Checkbox
                            className="mt-2"
                            isChecked={dataField.isQuickFilter}
                            onClick={toggleIsQuickFilter}
                            ariaLabel={`is-quick-filter-${dataField.fieldName}`}
                        />
                    </div>
                }
                {(!hardcodedFields.some(f => f.fieldName === dataField.fieldName && f.fieldType === dataField.fieldType)
                    || dataField.editable || dataField.isFromFile) &&
                    <div className="d-flex  align-items-center small">
                        <IconButton
                            className="w-100"
                            iconClasses="fal fa-trash-alt"
                            onClick={() => removeField(dataField.id)}
                            ariaLabel={`delete-field-${dataField.fieldName}`}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

const retrieveOption = (dataField, lookupOptions) => {
    const option = lookupOptions().find(l => dataField.lookup === l.value)
    if (dataField.dataPrimitive === dataPrimitive.LOOKUP)
        return option !== undefined ? option : { id: undefined }
    else
        return dataPrimitiveOptions.find(o => o.value === dataField.dataPrimitive)
}

export default DataField