import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { useClient } from 'invevo-react-components';
import { loadTransactionIds } from '../../actions/functionActions';
import { executeAuthAsyncPost } from '../../utility/asyncSupport';
import { noOptionsMessage, multiStyles } from '../../utility/selectSupport';

const TransactionRefAutoComplete = ({
  miaAccountId,
  executeAuthAsyncPost,
  refresh,
  onChange
}) => {
  const client = useClient()
  
  const [transactions, setTransactions] = useState([]);
  useEffect(() => {
    setTransactions([]);
  }, [refresh]);

  const onLoadOptions = (input, callback) => {
    let splitChar = '';

    if (input.indexOf(',') > -1) {
      splitChar = ',';
    } else if (input.indexOf(' ') > -1) {
      splitChar = ' ';
    } else if (input.indexOf('\r\n') > -1) {
      splitChar = '\r\n';
    } else if (input.indexOf('\n') > -1) {
      splitChar = '\n';
    }

    let inputArray = [input];
    let onSuccess = (transactions) => {
      const options = transactions.map((t) => ({
        label: t.transactionReference,
        value: t.id
      }));
      callback(options);
    };
    const onError = () => {
      callback('Error searching for branches', null);
    };

    if (splitChar) {
      inputArray = input.split(splitChar).map((s) => s.trim());
      onSuccess = (transactions) => {
        const options = transactions.map((t) => ({
          label: t.transactionReference,
          value: t.id
        }));
        onSelectChanged(options);
      };
    }

    executeAuthAsyncPost(
      client,
      'Transactions',
      'SEARCH_TRANSACTIONS',
      {
        customerId: miaAccountId,
        searchTerms: inputArray,
        searchType: 'Reference'
      },
      onSuccess,
      onError
    );
  };

  const onSelectChanged = (transactions) => {
    setTransactions(transactions || []);
    onChange(transactions[0] ? transactions[0].value : "");
  };

  return (
    <div className="d-flex">
      <div className="flex-grow-1">
        <AsyncSelect
          name="transactions-select"
          value={transactions}
          onChange={onSelectChanged}
          loadOptions={onLoadOptions}
          placeholder="Find Invoice"
          defaultOptions={[]}
          noOptionsMessage={noOptionsMessage}
          styles={{ ...multiStyles, width: '80%' }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    miaAccountId: state.currentSelectionReducer.selectedAccount.miaAccountId
  };
};

export default connect(
  mapStateToProps,
  {
    executeAuthAsyncPost,
    loadTransactionIds
  },
  null,
  {
    forwardRef: true
  }
)(TransactionRefAutoComplete);
