import { convertToCurrency } from "../../../../library/helpers/numberUtils"
import { DirectIdAffordabilityDto } from "../../types/dtos/DirectIdAffordabilityDto"

type EstimatedAffordabilityProps = {
    className: string
    affordability: DirectIdAffordabilityDto
    currencyCode: string
}

const EstimatedAffordability = ({ className, affordability, currencyCode }: EstimatedAffordabilityProps) => {
    return (
        <div className={`d-flex border-1 border rounded ${className} p-3`}>
            <div className={"d-flex flex-column flex-grow-1 me-4"}>
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <span className="fw-bold fs-5 me-2">Calculated Income</span>
                            <i className="far fa-arrow-trend-up text-green"></i>
                        </div>
                        <span className="text-grey mx-auto fs-4">{convertToCurrency(affordability.estimatedCalculatedIncome, currencyCode)}</span>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <span className="fw-bold fs-5 me-2">Estimated Debt Fees</span>
                            <i className="far fa-arrow-trend-down text-red"></i>
                        </div>
                        <span className="text-grey mx-auto fs-4">{convertToCurrency(affordability.estimatedDebtFees, currencyCode)}</span>
                    </div>
                </div>
                <hr />
                <div className="d-flex">
                    <div className="d-flex flex-column mx-auto">
                        <div className="d-flex align-items-center">
                            <span className="fw-bold fs-5 me-2">Estimated Fixed Costs</span>
                            <i className="far fa-coins text-purple"></i>
                        </div>
                        <span className="text-grey mx-auto fs-4">{convertToCurrency(affordability.estimatedFixedCosts, currencyCode)}</span>
                    </div>
                    <div className="d-flex flex-column mx-auto">
                        <div className="d-flex align-items-center">
                            <span className="fw-bold fs-5 me-2">Estimated Flexible Costs</span>
                            <i className="far fa-coins text-purple"></i>
                        </div>
                        <span className="text-grey mx-auto fs-4">{convertToCurrency(affordability.estimatedFlexibleCosts, currencyCode)}</span>
                    </div>
                </div>
            </div>
            <div className="d-flex border-start ps-4">
                <div className="d-flex flex-column m-auto">
                    <div className="d-flex align-items-center align-self-center">
                        <span className="fw-bold fs-5 me-2">Estimated Monthly Affordability</span>
                        <i className="far fa-star text-warning"></i>
                    </div>
                    <span className="text-grey mx-auto fw-bold fs-1">{convertToCurrency(affordability.estimatedAffordability, currencyCode)}</span>
                </div>
            </div>
        </div>
    )
}

export default EstimatedAffordability
