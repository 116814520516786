import { useReducer, useEffect } from 'react'
import customerGroupReducer from '../../reducers/customerGroupReducer'
import CustomerGroups from './CustomerGroups'
import CustomerGroupForm from './customerGroupForm/CustomerGroupForm'
import CustomerGroup from './customerGroup/CustomerGroup'

const CustomerGroupsDashboard = () => {
    const [state, dispatch] = useReducer(customerGroupReducer, { customerGroups: [], selectedCustomerGroup: { filters: [], creditFilters: [] } })

    useEffect(() => {
        window.parent.postMessage("rendered", "*")
    }, [])

    return (
        <div className="d-flex w-100 h-100">
            <CustomerGroups
                state={state}
                dispatch={dispatch}
            />
            <CustomerGroup
                state={state.selectedCustomerGroup}
                dispatch={dispatch}
                isConfigReader={false}
            />
            <CustomerGroupForm
                state={state}
                dispatch={dispatch}
            />
        </div>
    )
}

export default CustomerGroupsDashboard
