import { useCallback, useEffect, useState } from 'react'
import { useClient, useApi, Loading } from 'invevo-react-components'
import FieldConfig from './FieldConfig'
import { actionTypes } from '../../reducers/actionTypes'
import { fieldDataTypes } from '../../enums/fieldDataType'

const CustomerFieldConfigs = ({ state, dispatch }) => {
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const client = useClient()
    const api = useApi()

    const getDataConfiguration = useCallback(() => {
        return new Promise((resolve, reject) => {
            api.get(`/api/${client}/data-import-config`)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    if (error.response.status === 404) resolve({ data: { fields: [] } })
                    else reject(error)
                })
        })
    }, [api, client])

    const onFieldConfigChange = (newConfig) => {
        dispatch({ type: actionTypes.CUSTOMER_FIELD_CONFIG_UPDATED, customerFieldConfig: newConfig })
    }

    const createDefaultConfig = (fields) => {
        const newConfig = {
            fields: fields.map(f => {
                return {
                    fieldName: f,
                    fieldDataType: "TEXT",
                    isAvailableInGroups: true,
                    isAvailableInAutoComplete: true,
                    isTemplatePlaceholder: false,
                    isAvailableAsUserDataFilter: false,
                    isAvailableInSearch: false,
                    isAvailableInAdvancedSearch: false
                }
            })
        }
        return newConfig
    }

    useEffect(() => {
        if (isFetching || hasFetched) {
            return
        }
        setIsFetching(true)

        Promise.all([
            api.get(`/api/${client}/customerfields`),
            getDataConfiguration()
        ]).then(responses => {
            var dataConfiguration = responses[1].data
            const customerFields = responses[0].data
            if (dataConfiguration.fields.length === 0) {
                dataConfiguration = createDefaultConfig(customerFields)
            }

            dispatch({ type: actionTypes.CUSTOMER_DATA_CONFIGURATION_RETRIEVED, fields: customerFields, config: dataConfiguration })
            setHasFetched(true)
            setIsFetching(false)
        })
            .catch(error => {
                console.log(error)
            })

    }, [api, client, dispatch, getDataConfiguration, hasFetched, isFetching])

    const getTotalAutoCompleteFieldsCount = () => {
        return state.editableCustomerDataConfiguration.customerFieldConfigs.filter(config => config.isAvailableInAutoComplete && config.fieldDataType === fieldDataTypes.TEXT).length
    }

    return (
        <div className="d-flex flex-grow-1 flex-column h-100">
            <Loading isLoading={isFetching} colour="blue">
                {state.editableCustomerDataConfiguration.customerFieldConfigs.length > 0 && state.editableCustomerDataConfiguration.customerFieldConfigs.map(config => {
                    return <FieldConfig key={config.fieldName} state={{ config, autoCompleteSelectedCount: getTotalAutoCompleteFieldsCount() }} onFieldConfigChange={onFieldConfigChange} />
                }
                ).reduce((prev, next) => [prev, <span key={`${prev}${next}`} className="my-1" />, next])}
            </Loading>
        </div>
    )
}


export default CustomerFieldConfigs