export const requestCloneToVirtualParentAndAddParent = () => ({
  type: 'REQUEST_CLONE_TO_VP'
});
export const receiveCloneToVirtualParentAndAddParent = (json) => ({
  type: 'RECEIVE_CLONE_TO_VP',
  json
});
export const errorCloneToVirtualParentAndAddParent = (err) => ({
  type: 'ERROR_CLONE_TO_VP',
  err
});

export const requestUpdateVirtuaParentType = () => ({
  type: 'REQUEST_UPDATE_VP_TYPE'
});
export const receiveUpdateVirtuaParentType = (json) => ({
  type: 'RECEIVE_UPDATE_VP_TYPE',
  json
});
export const errorUpdateVirtuaParentType = (err) => ({
  type: 'ERROR_UPDATE_VP_TYPE',
  err
});

export const requestGetVPTypes = () => ({ type: 'REQUEST_VP_TYPES' });
export const receiveGetVPTypes = (data) => ({ type: 'RECEIVE_VP_TYPES', data });
export const errorGetVPTypes = (err) => ({ type: 'ERROR_VP_TYPES', err });
export const setVATypeId = (id) => ({ type: 'SET_VA_TYPE_ID', id });
export const resetVACloned = () => ({ type: 'RESET_VA_CLONED' });
export const requestRemoveParent = () => ({ type: 'REQUEST_REMOVE_PARENT' });
export const receiveRemoveParent = () => ({ type: 'RECEIVE_REMOVE_PARENT' });
export const errorRemoveParent = (err) => ({
  type: 'ERROR_REMOVE_PARENT',
  err
});
export const resetVARemoved = () => ({ type: 'RESET_VA_REMOVED' });
export const requestAddParent = () => ({ type: 'REQUEST_ADD_PARENT' });
export const receiveAddParent = (json) => ({
  type: 'RECEIVE_ADD_PARENT',
  json
});
export const errorAddParent = (err) => ({ type: 'ERROR_ADD_PARENT', err });
export const resetVAAdded = () => ({ type: 'RESET_VA_ADDED' });
export const setVirtualAccountMode = (virtualAccountId, name, account) => ({
  type: 'SET_VIRTUAL_ACCOUNT_MODE',
  virtualAccountId,
  name,
  account
});
export const resetVirtualAccountMode = () => ({
  type: 'RESET_VIRTUAL_ACCOUNT_MODE'
});
export const requestUpdateVirtualAccount = (data) => ({
  type: 'REQUEST_UPD_VIRTUAL_ACCOUNT',
  data
});
export const receiveUpdateVirtualAccount = (json) => ({
  type: 'RECEIVE_UPD_VIRTUAL_ACCOUNT',
  json
});
export const errorUpdateVirtualAccount = (err) => ({
  type: 'ERROR_UPD_VIRTUAL_ACCOUNT',
  err
});
export const requestGetParentDataById = (id) => ({
  type: 'REQUEST_GET_VIRTUAL_ACCOUNT',
  id
});
export const receiveGetParentDataById = (json) => ({
  type: 'RECEIVE_GET_VIRTUAL_ACCOUNT',
  json
});
export const errorGetParentDataById = (err) => ({
  type: 'ERROR_GET_VIRTUAL_ACCOUNT',
  err
});
export const receiveGetBranches = (json) => ({
  type: 'RECEIVE_GET_BRANCHES',
  json
});
export const refreshVpHierarchy = () => ({
  type: 'REFRESH_VP_HIERARCHY'
});
