export const noOptionsMessage = () => 'Type to search';
export const optionStyles = (styles) => ({ ...styles, color: '#000' });
export const widthStyle = (width) => (styles) => ({ ...styles, width });
export const multiValueStyles = (styles) => ({
  ...styles,
  backgroundColor: '#0096d9'
});
export const multiValueLabelStyles = (styles) => ({
  ...styles,
  color: '#fff'
});
export const multiValueRemoveStyles = (styles) => ({
  ...styles,
  color: '#fff',
  ':hover': {
    backgroundColor: '#0078ad'
  }
});

export const multiStyles = {
  option: optionStyles,
  multiValue: multiValueStyles,
  multiValueLabel: multiValueLabelStyles,
  multiValueRemove: multiValueRemoveStyles
};
