import HeaderDefinition from "./../types/HeaderDefinition"
import RowData from "./../types/RowData"
import { getValueForHeader } from "./../util"
import { DataTypeEnum } from "../../../routes/entityConfig/types/DataType"
import { useGrid } from "./../Grid"
import classes from "./DataCard.module.scss"
import DataCell from "./DataCell"
import { useFeatureToggle } from "../../../hooks/useFeatureToggle"
import { filterLookupEntriesByParent } from "../../../routes/entitySearch/helpers"
import { formatDateFromNumber } from "../../helpers/dateUtils"
import IconButton from "../../buttons/IconButton/IconButton"
import EntityConfig from "../../../routes/entityConfig/types/EntityConfig"

const mainHeaderValue = "text_name"

type Props = {
    cardData: RowData
    optimisticData: RowData | undefined
    isSelectable: boolean
    onNavigateToClicked?: (data: RowData) => void
    headers: HeaderDefinition[]
    colour?: "blue" | "grey"
    entityConfig?: EntityConfig
    onCardSelected?: (data: RowData) => void
    toggleSelectSingle: (reference: string) => void
}

const DataCard = ({
    cardData,
    optimisticData,
    isSelectable,
    onNavigateToClicked,
    headers,
    colour = "grey",
    entityConfig,
    onCardSelected,
    toggleSelectSingle
}: Props) => {
    const grid = useGrid()

    const isSelected = grid.selection.selectedRefs.includes(cardData.reference)

    const mainHeader = headers.find(header => header.value === mainHeaderValue)

    const onClick = () => {
        if (!isSelectable) return
        toggleSelectSingle(cardData.reference)
        onCardSelected?.(cardData)
    }

    const navigateToClicked = () => {
        if (!onNavigateToClicked) return

        onNavigateToClicked(cardData)
    }

    const theme = colour === "blue" ? `${classes.containerBlue} bg-blue text-white` : `${classes.containerGrey} bg-white text-grey`

    const entityFields = grid.displayedHeaders.map(field => ({
        fieldName: field.value,
        dataType: {
            lookupReference: field.lookupReference
        }
    }))

    const filterLookupsFeatureToggle = useFeatureToggle("filterLookupEntriesOnLookupParents")
    const filteredLookups = filterLookupsFeatureToggle.isEnabled
        ? filterLookupEntriesByParent(grid.lookups, entityFields, cardData.fields.textFields)
        : grid.lookups

    return (
        <div
            className={`${theme} ${isSelected ? classes.selected : ""} d-flex flex-column px-2 pb-2 pt-1 rounded ${isSelectable ? "pointer" : ""}`}
            onClick={onClick}
            role="row"
            aria-label={cardData.reference}
            aria-selected={isSelected}
        >
            {mainHeader && (
                <>
                    <div className="d-flex justify-content-between  align-items-center ">
                        <span className="fs-5">{getDisplayValue(cardData, mainHeader)}</span>
                        {onNavigateToClicked && (
                            <IconButton
                                size="small"
                                icon="fa fa-eye"
                                onClick={navigateToClicked}
                                ariaLabel={`select-entity-${cardData.reference}`}
                                theme="white-flat"
                            />
                        )}
                    </div>
                    <div className="border my-1" />
                </>
            )}
            {headers
                .filter(header => header.value !== mainHeaderValue)
                .slice(0, 3)
                .map(header => {
                    const optimisticValue = optimisticData ? getValueForHeader(optimisticData, header)?.toString() : undefined
                    return (
                        <div key={`${cardData.reference}-${header.value}`} className={`d-flex gap-1 ${colour === "grey" ? "justify-content-between" : ""}`}>
                            <span className="text-nowrap">{header.label}:</span>
                            <DataCell
                                rowReference={cardData.reference}
                                fieldValue={getValueForHeader(cardData, header)?.toString() ?? ""}
                                optimisticFieldValue={header.value === "reference" ? undefined : optimisticValue}
                                header={header}
                                filteredLookups={filteredLookups}
                                theme={colour === "grey" ? "text-blue" : "text-white"}
                                isEditable={header.isEditable ?? false}
                                canWrap={true}
                                entityConfig={entityConfig}
                            />
                        </div>
                    )
                })}
        </div>
    )
}

export default DataCard

const getDisplayValue = (cardData: RowData, fieldDefinition: HeaderDefinition) => {
    const fieldValue = getValueForHeader(cardData, fieldDefinition) ?? ""
    if (typeof fieldValue === "string") {
        return fieldValue
    }

    if (typeof fieldValue === "boolean") {
        return fieldValue ? "True" : "False"
    }

    if (fieldDefinition.displayType === DataTypeEnum.DATE) {
        return formatDateFromNumber(fieldValue)
    }

    return fieldValue.toString()
}
