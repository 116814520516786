import { useApi, useClient, useConfig, useInvevoSession } from "invevo-react-components"
import { useEffect, useReducer, useState } from "react"
import { actionTypes } from "../../reducers/actionTypes"
import customerDashboardsReducer from "../../reducers/customerDashboardsReducer"
import { StripItems } from "./dashboardStrip/StripItems"
import { mapDashboardConfigFromGetDto } from "../../helpers/dashboardMapping"
import { expandablePanels, useLayout } from "../../../../contexts/LayoutContext"
import Dashboard from "../customerDashboards/Dashboard"
import { useEntity } from "../../../../contexts/EntityContext"
import EntityGroupPanel from "./groups/EntityGroupPanel"
import ActionStrip from "./ActionStrip"
import { DashboardTypeEnum } from "../../../../routes/entitySearch/types/DashboardConfig"
import useApiQuery from "../../../../hooks/useApiQuery"
import DocumentsPanel from "../../../legacy/components/documents/documentsPanel"
import ContactsPanel from "../../../legacy/components/contacts"
import TransactionsPanel from "../../../legacy/components/transactions"
import Ero57IntlProvider from "../../../legacy/components/ero57IntlProvider"
import LegacyLoader from "../../../legacy/LegacyLoader"
import { convertDtoFieldsToDatafields } from "../../../../types/EntityData"
import { convertFromArrayToDto, doesEntityMatchFilters } from "../../../../library/FilterList/FiltersDto"
import { useUser } from "../../../../contexts/UserContext"
import classes from "../customerDashboards/CustomerDashboard.module.scss"
import stripClasses from "../customer/dashboardStrip/DashboardStrip.module.scss"
import DashboardMenu from "../customerDashboards/DashboardMenu"
import ErrorBoundary from "../../../../components/ErrorBoundary"
import AccountInfoPanel from "../../../legacy/components/accountInfo"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"
import VirtualParent from "../../../legacy/components/virtualParent"

const SelectedEntity = ({ goToNextEntity, store }) => {
    const { isEnabled: showLegacyCustomerDashboard } = useFeatureToggle("permShowSqlAccountSummaryDashboardSqlToEntity")
    const { isEnabled: isLegacyDashboardsInVerticalStripEnabled } = useFeatureToggle("showLegacyDashboardsInVerticalStrip")

    const [lastFetchedEntityRef, setLastFetchedEntityRef] = useState()
    const [lastFetchedEntityType, setLastFetchedEntityType] = useState()
    const [isFetching, setIsFetching] = useState(false)
    const { setExpandedPanel, isCustomerPanelExpanded, isLeftPanelExpanded } = useLayout()
    const [activeStripItem, setActiveStripItem] = useState(StripItems.LEGACY_CUSTOMER_SUMMARY)
    const [showingGroups, setShowingGroups] = useState(false)
    const [invevoSession, setInvevoSession] = useInvevoSession()
    const [filteredDashboards, setFilteredDashboards] = useState([])
    const { isEnabled: permShowVpHierarchySqlToEntity } = useFeatureToggle("permShowVpHierarchySqlToEntity")

    const api = useApi()
    const client = useClient()
    const currentUser = useUser()
    const config = useConfig()
    const [entity, setEntity] = useEntity()

    const [state, dispatch] = useReducer(customerDashboardsReducer, {
        dashboards: [],
        selectedDashboard: null,
        lookups: [],
        entityConfigs: [],
        entityRelationships: []
    })

    const getFilterOnReferenceData = reference => {
        return {
            textFieldIsOneOf: [
                {
                    fieldName: "reference",
                    notOneOf: false,
                    values: [reference]
                }
            ],
            textFieldStartsWith: [],
            booleanFieldIsEqualTo: [],
            numberFieldIsOneOf: [],
            numberFieldMatchesOperation: [],
            dateFieldIsOneOf: [],
            dateFieldMatchesOperation: [],
            dateFieldIsRelativeTo: [],
            fieldExists: []
        }
    }

    useEffect(() => {
        switch (invevoSession.selectedCustomerTab) {
            case "transactions":
                const transactionDashboard = filteredDashboards.find(d => d.gridWidgets.some(a => a.entityConfigReference.toLowerCase() === "transaction"))
                if (transactionDashboard) dispatch({ type: actionTypes.CUSTOMER_DASHBOARD_SELECTED, dashboard: transactionDashboard })
                setInvevoSession({ selectedCustomerTab: "entity_transactions" })
                break
            case "contacts":
                setActiveStripItem(StripItems.LEGACY_CONTACTS)
                dispatch({ type: actionTypes.SELECTED_CUSTOMER_DASHBOARD_CLEARED })
                break
            case "files":
                setActiveStripItem(StripItems.LEGACY_FILES)
                dispatch({ type: actionTypes.SELECTED_CUSTOMER_DASHBOARD_CLEARED })
                break
            default:
                break
        }
    }, [invevoSession.selectedCustomerTab, setInvevoSession, filteredDashboards, isFetching])

    useEffect(() => {
        if (!entity.reference || isFetching || !config.SEARCH_API_URL || !config.DATA_API_URL || !config.ENTITY_CONFIG_API_URL) return
        if (lastFetchedEntityRef?.toLowerCase() === entity.reference.toLowerCase() && lastFetchedEntityType?.toLowerCase() === entity.entityType.toLowerCase())
            return
        setIsFetching(true)
        setLastFetchedEntityRef(entity.reference)
        setLastFetchedEntityType(entity.entityType)

        const getLookups = api.get(`${config.DATA_API_URL}/api/${client}/lookups`).then(response => {
            dispatch({ type: actionTypes.LOOKUPS_RETRIEVED, data: response.data })
        })

        const getEntityConfigs = api
            .get(`${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-config`)
            .then(response => dispatch({ type: actionTypes.ENTITY_CONFIGS_RETRIEVED, entityConfigs: response.data }))

        const getEntityRelationships = api.get(`${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-relationships`).then(response =>
            dispatch({
                type: actionTypes.ENTITY_RELATIONSHIPS_RETRIEVED,
                entityRelationships: response.data
            })
        )

        const getEntity = api
            .post(`${config.SEARCH_API_URL}/api/${client}/entities`, {
                pageSize: 1,
                searchAfterId: null,
                sortBy: null,
                filters: getFilterOnReferenceData(entity.reference),
                entityTypeReference: entity.entityType
            })
            .then(response => {
                const entityFields = convertDtoFieldsToDatafields(response.data.pagedEntity[0].entityFields)

                api.get(`${config.DATA_API_URL}/api/${client}/user-dashboard-configs`).then(response => {
                    const dashboards = mapDashboardConfigFromGetDto(response.data, true).filter(
                        d => d.entityConfigReference === entity.entityType && d.type !== DashboardTypeEnum.AGGREGATED
                    )
                    const filteredDashboardsFromApi = entity.reference
                        ? dashboards.filter(config =>
                              doesEntityMatchFilters(
                                  {
                                      entityTypeReference: entity.entityType,
                                      reference: entity.reference,
                                      entityFields: entityFields
                                  },
                                  convertFromArrayToDto(config.filters)
                              )
                          )
                        : []

                    setFilteredDashboards(filteredDashboardsFromApi)
                    dispatch({ type: actionTypes.CUSTOMER_DASHBOARDS_RETRIEVED, dashboards: filteredDashboardsFromApi })

                    const dashboard =
                        invevoSession.selectedCustomerTab === "transactions"
                            ? filteredDashboardsFromApi.find(d => d.gridWidgets.some(a => a.entityConfigReference.toLowerCase() === "transaction"))
                            : filteredDashboardsFromApi.find(d => d.name.toLowerCase() === "summary")

                    setInvevoSession({ selectedCustomerTab: "" })
                    if (dashboard) {
                        dispatch({ type: actionTypes.CUSTOMER_DASHBOARD_SELECTED, dashboard })
                    } else {
                        dispatch({
                            type: actionTypes.CUSTOMER_DASHBOARD_SELECTED,
                            dashboard: filteredDashboardsFromApi[0]
                        })
                    }
                })
                setEntity({ entityFields: entityFields })
            })

        Promise.all([getLookups, getEntityConfigs, getEntity, getEntityRelationships])
            .then(() => {
                setIsFetching(false)
            })
            .catch(error => {
                setIsFetching(false)
                console.error(error)
            })
    }, [
        api,
        client,
        lastFetchedEntityRef,
        lastFetchedEntityType,
        isFetching,
        currentUser.username,
        activeStripItem,
        setEntity,
        setInvevoSession,
        invevoSession.selectedCustomerTab,
        config.SEARCH_API_URL,
        config.DATA_API_URL,
        config.ENTITY_CONFIG_API_URL,
        entity
    ])

    const transactionDashboardListRefs = filteredDashboards
        .filter(d => d.gridWidgets.some(a => a.entityConfigReference.toLowerCase() === "transaction"))
        .map(d => d.reference)

    const onStripItemSelected = activeStripItem => {
        setActiveStripItem(activeStripItem)

        if (activeStripItem === StripItems.LEGACY_CUSTOMER_SUMMARY) {
            setInvevoSession({ selectedCustomerTab: "" })
            dispatch({ type: actionTypes.SELECTED_CUSTOMER_DASHBOARD_CLEARED })
            return
        }

        if (activeStripItem === StripItems.LEGACY_FILES) {
            setInvevoSession({ selectedCustomerTab: "files" })
            dispatch({ type: actionTypes.SELECTED_CUSTOMER_DASHBOARD_CLEARED })
            return
        }

        if (activeStripItem === StripItems.LEGACY_CONTACTS) {
            setInvevoSession({ selectedCustomerTab: "contacts" })
            dispatch({ type: actionTypes.SELECTED_CUSTOMER_DASHBOARD_CLEARED })
            return
        }
        if (activeStripItem === StripItems.LEGACY_TRANSACTIONS) {
            setInvevoSession({ selectedCustomerTab: "transactions" })
            dispatch({ type: actionTypes.SELECTED_CUSTOMER_DASHBOARD_CLEARED })
            return
        }
        setInvevoSession({ selectedCustomerTab: "" })

        const dashboard = state.dashboards.find(d => d.id === activeStripItem)
        if (dashboard) {
            dispatch({ type: actionTypes.CUSTOMER_DASHBOARD_SELECTED, dashboard })
        }

        if (transactionDashboardListRefs.includes(dashboard.reference)) {
            setInvevoSession({ selectedCustomerTab: "entity_transactions" })
        }
    }

    const onShowGroupsToggled = () => setShowingGroups(currentlyShowingGroups => !currentlyShowingGroups)

    const dataUpdateRulesRequest = useApiQuery({
        url: `${config.AUTOMATE_API_URL}/api/${client}/data-updated-workflow-rule`,
        method: "GET"
    })

    const onSelectedCustomerContactsUpdated = selectedCustomerContacts => {
        setInvevoSession({ selectedCustomerContacts })
    }

    const onSelectedInternalContactsUpdated = selectedInternalContacts => {
        setInvevoSession({ selectedInternalContacts })
    }

    const selectedDashboard = state.selectedDashboard?.id || activeStripItem
    const isLegacyDashboard = ["LEGACY_CUSTOMER_SUMMARY", "LEGACY_FILES", "LEGACY_CONTACTS", "LEGACY_TRANSACTIONS", "DASHBOARDS"].includes(selectedDashboard)

    const displayedContent = () => {
        if (state.dashboards.length === 0) {
            return <span className={`m-3 ${isLegacyDashboard ? "text-grey" : "text-white"} fs-3`}>No dashboards available</span>
        } else {
            if (state.selectedDashboard) {
                return (
                    <div className="d-flex flex-grow-1 overflow-auto flex-column ">
                        <Dashboard
                            state={{
                                entityData: entity,
                                entityConfigs: state.entityConfigs,
                                entityRelationships: state.entityRelationships,
                                dashboard: state.selectedDashboard,
                                lookups: state.lookups,
                                showingGroups: showingGroups,
                                dataUpdateRules: dataUpdateRulesRequest.data ?? []
                            }}
                            dispatch={dispatch}
                            fullScreenButton={fullScreenButton()}
                            onShowGroupsToggled={onShowGroupsToggled}
                        />
                    </div>
                )
            }
        }

        if (activeStripItem === StripItems.LEGACY_CUSTOMER_SUMMARY && (isLegacyDashboardsInVerticalStripEnabled || showLegacyCustomerDashboard)) {
            return (
                <div className="d-flex flex-grow-1 overflow-auto p-3">
                    <Ero57IntlProvider>
                        <LegacyLoader>
                            <AccountInfoPanel />
                        </LegacyLoader>
                    </Ero57IntlProvider>
                </div>
            )
        }

        if (activeStripItem === StripItems.LEGACY_FILES) {
            return (
                <div className="d-flex flex-grow-1 overflow-auto p-3">
                    <DocumentsPanel selectTaskTab={invevoSession.showTaskFiles} />
                </div>
            )
        }
        if (activeStripItem === StripItems.LEGACY_CONTACTS) {
            return (
                <div className="d-flex flex-grow-1 overflow-auto p-3">
                    <ContactsPanel
                        client={client}
                        onSelectedCustomerContactsUpdated={onSelectedCustomerContactsUpdated}
                        onSelectedInternalContactsUpdated={onSelectedInternalContactsUpdated}
                    />
                </div>
            )
        }
        if (activeStripItem === StripItems.LEGACY_TRANSACTIONS) {
            return (
                <div className="d-flex flex-grow-1 overflow-auto mt-5 p-3">
                    <div className="w-100 h-100">
                        <Ero57IntlProvider>
                            <LegacyLoader>
                                <TransactionsPanel client={client} />
                            </LegacyLoader>
                        </Ero57IntlProvider>
                    </div>
                </div>
            )
        }
    }

    const onSetEntityViewFullScreen = () => setExpandedPanel(isCustomerPanelExpanded ? expandablePanels.DEFAULT : expandablePanels.CUSTOMER_PANEL)

    const fullScreenButton = () => (
        <i
            className={`fs-4 pointer fa-light pt-2 ${
                isCustomerPanelExpanded ? "fa-down-left-and-up-right-to-center" : "fa-up-right-and-down-left-from-center"
            }`}
            onClick={onSetEntityViewFullScreen}
        ></i>
    )

    const onGroupingCloseClicked = () => setShowingGroups(false)
    const onExpandCollapseCustomerClick = () => setExpandedPanel(isLeftPanelExpanded ? expandablePanels.DEFAULT : expandablePanels.LEFT_PANEL)

    return (
        <div className="d-flex w-100 h-100">
            <div className={`d-flex flex-column ${stripClasses.strip}`}>
                {!isLeftPanelExpanded && (
                    <i
                        className={`mx-auto fs-4 fa-light ${
                            isLeftPanelExpanded ? "fa-arrow-left-long-to-line" : "fa-arrow-right-long-to-line"
                        } pointer mt-4 text-grey`}
                        onClick={onExpandCollapseCustomerClick}
                        role="button"
                        aria-label="toggle-left-panel-expanded-button"
                    ></i>
                )}
                <i className={`fa-solid fa-building mx-auto my-5 fs-3 ${stripClasses["strip-header"]}`}></i>
            </div>
            <ErrorBoundary>
                {!isLeftPanelExpanded && (
                    <>
                        <div className="d-flex flex-column flex-grow-1 overflow-auto">
                            {entity.reference && entity.reference !== undefined ? (
                                <div className={`d-flex flex-column flex-fill-1 flex-grow-1 top-0 ${isLegacyDashboard ? "" : classes.dashboard}`}>
                                    {permShowVpHierarchySqlToEntity && (
                                        <div className={`d-flex w-100 ${classes.virtualParentContainer}`}>
                                            <Ero57IntlProvider>
                                                <LegacyLoader>
                                                    <VirtualParent />
                                                </LegacyLoader>
                                            </Ero57IntlProvider>
                                        </div>
                                    )}
                                    <DashboardMenu
                                        entityData={entity}
                                        dashboards={state.dashboards}
                                        onStripItemSelected={onStripItemSelected}
                                        selectedDashboard={selectedDashboard}
                                        isNewEntityStructureEnabled={true}
                                        showingGroups={showingGroups}
                                        onShowGroupsToggled={onShowGroupsToggled}
                                        fullScreenButton={fullScreenButton()}
                                        hasLightBackground={isLegacyDashboard}
                                    />
                                    <div className="d-flex flex-column overflow-auto text-grey flex-grow-1">{displayedContent()}</div>
                                </div>
                            ) : (
                                <span className="m-3 text-grey fs-3">No entity selected</span>
                            )}
                        </div>
                        {showingGroups && <EntityGroupPanel onClose={onGroupingCloseClicked} />}
                        {entity.reference && state.entityConfigs.length > 0 && (
                            <ActionStrip
                                customerRef={entity.reference}
                                store={store}
                                entityConfig={state.entityConfigs.find(e => e.reference === entity.entityType)}
                                goToNextEntity={goToNextEntity}
                                entityConfigs={state.entityConfigs}
                                entityRelationships={state.entityRelationships}
                                lookups={state.lookups}
                            />
                        )}
                    </>
                )}
            </ErrorBoundary>
        </div>
    )
}

export default SelectedEntity
