import { OutboundIvrActionFlowNode, UndefinedProcessFlowNode } from "../../types/FlowSculptorNode"
import { v4 as uuid } from "uuid"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import Input from "../../../../library/Inputs/Input"

type CustomOutputsFormProps = {
    node: OutboundIvrActionFlowNode | UndefinedProcessFlowNode
    onNodeUpdated: (node: OutboundIvrActionFlowNode | UndefinedProcessFlowNode) => void
}

const CustomOutputsForm = ({ node, onNodeUpdated }: CustomOutputsFormProps) => {
    const onOutputAdded = () => onNodeUpdated({ ...node, outputs: [...node.outputs, { reference: uuid(), displayName: "" }] })

    return (
        <div className="d-flex flex-column h-100 p-2 gap-2 w-100">
            {node.outputs.map(output => {
                const onOutputChanged = (value: string) =>
                    onNodeUpdated({ ...node, outputs: node.outputs.map(o => (o.reference === output.reference ? { ...o, displayName: value } : o)) })

                const onOutputDeleted = () => onNodeUpdated({ ...node, outputs: node.outputs.filter(o => o.reference !== output.reference) })

                return (
                    <div key={output.reference} className="d-flex align-items-center">
                        <Input className="w-100" value={output.displayName} onChange={onOutputChanged} />
                        <div role="button" className="ms-2 px-2" onClick={onOutputDeleted} aria-label="filter-remove">
                            <i className="fal fa-trash-alt" />
                        </div>
                    </div>
                )
            })}
            <StandardButton className="mt-2" iconClasses="fal fa-plus" label="Add Output" colour="grey" onClick={onOutputAdded} />
        </div>
    )
}

export default CustomOutputsForm
