import React from 'react';
import { connect } from 'react-redux';
import { getApiUrl, GLOBAL_SETTINGS } from '../../../constants';
import { webApiInterface } from '../../../api/webApiInterface';
import { executeAuthAsyncGet } from '../../../utility/asyncSupport';
import {
  showToastSuccessMessage,
  showToastErrorMessage,
  showToastWarningMessage
} from '../../../api/toasterApi.js';
import { isNil, map, split, trim, findIndex } from 'lodash';
import LookupValidationResults from './lookupValidationResults';
import { showValidationResults } from '../../../actions/dashboardActions';

export class LookupTransferAll extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = this.defaultFormState([]);
    this.newExportCsv = this.newExportCsv.bind(this);
    this.onFileSelected = this.onFileSelected.bind(this);
    this.showValidationResults = this.showValidationResults.bind(this);
    this.open = this.open.bind(this);
  }

  defaultFormState() {
    return {
      validationResults: []
    };
  }

  showValidationResults(results) {
    const that = this;
    that.setState(
      {
        validationResults: results
      },
      function () {
        that.open();
      }
    );
  }

  open() {
    const that = this;
    that.props.showValidationModal(true);
  }

  newExportCsv(event) {
    event.preventDefault();
    const that = this;
    const xhr = new XMLHttpRequest();
    const url = getApiUrl(this.props.client) + 'api/lookupadmin/getalllookups';

    xhr.open('GET', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(xhr.response);
      a.download = 'AllLookups.xml';
      a.click();
    };

    that.props.executeAuthAsyncGet(that.props.client, 'Lookup/OK', 'CHECK_OK', null, function () {
      webApiInterface.applySecurity(xhr);
      xhr.send(null);
    });
  }

  static addValueToRequest(name, value) {
    return (
      '--blob\r\n' +
      'content-disposition: form-data; name="' +
      name +
      '"\r\n' +
      '\r\n' +
      value +
      '\r\n'
    );
  }

  static addFileContentToRequest(fieldName, fileName, fileType, binaryContent) {
    return (
      '--blob\r\n' +
      'content-disposition: form-data; ' +
      'name="' +
      fieldName +
      '"; ' +
      'filename="' +
      fileName +
      '"\r\n' +
      'Content-Type: ' +
      fileType +
      '\r\n' +
      '\r\n' +
      binaryContent +
      '\r\n'
    );
  }

  onFileSelected(event) {
    event.preventDefault();
    const that = this;
    const file = event.target.files[0];

    const xmlExtension = file.name.indexOf('.xml');

    if (xmlExtension === -1) {
      showToastErrorMessage('Please select a valid xml file.');
      return;
    }

    if (!isNil(file)) {
      that.uploadFormConfirmed(file);
    } else {
      showToastErrorMessage('Please select a valid file.');
    }
  }

  uploadFormConfirmed(file) {
    const allLookupsUploader = this.allLookupsUploader;

    if (!isNil(file)) {
      const that = this;
      const reader = new FileReader();
      const url = getApiUrl(this.props.client) + 'api/lookupadmin/updatealllookups';
      const fileName = file.name;
      const fileSaveName = file.name;
      const fileType = file.type;

      showToastSuccessMessage(
        'Upload of file: ' +
        fileSaveName +
        (fileName !== fileSaveName ? ' (' + fileSaveName + ')' : '') +
        ' started.'
      );

      reader.onload = function (event) {
        if (
          event.currentTarget.result.length >
          GLOBAL_SETTINGS.max_upload_file_size * 1024 * 1024
        ) {
          showToastErrorMessage(
            'File is too big to be uploaded to the server. Please select another file.'
          );
          return;
        }
        if (event.currentTarget.result.length === 0) {
          showToastErrorMessage(
            'File has no content. Please select another file.'
          );
          return;
        }
        const acceptedFileTypes = map(
          split(GLOBAL_SETTINGS.accepted_file_types, ','),
          trim
        ); // Array of extensions
        const fileNameParts = split(fileName, '.');
        const fileExtension = '.' + fileNameParts[fileNameParts.length - 1];
        if (
          findIndex(acceptedFileTypes, (ft) => {
            return ft === fileExtension;
          }) === -1
        ) {
          showToastErrorMessage(
            'File extension is not correct. Please select another file.'
          );
          return;
        }

        const XHR = new XMLHttpRequest();
        const data = LookupTransferAll.addFileContentToRequest(
          allLookupsUploader.name,
          fileSaveName,
          fileType,
          event.currentTarget.result
        );

        XHR.onreadystatechange = function () {
          if (XHR.readyState === 4) {
            if (XHR.status === 200) {
              const results = JSON.parse(XHR.response);
              if (results.validationErrors.length > 0) {
                showToastWarningMessage(
                  'Upload of file: ' +
                  fileName +
                  ' completed with validation errors.'
                );
                that.showValidationResults(results.validationErrors);
              } else {
                showToastSuccessMessage(
                  'Upload of file: ' + fileName + ' complete.'
                );
              }
            } else {
              const err = JSON.parse(XHR.response);
              showToastErrorMessage(
                'Error uploading file: ' +
                fileName +
                '. Error: ' +
                err.Message || XHR.statusText
              );
            }
          }
        };
        XHR.timeout = 1000000; //Set a large timeout as big batch updates can take a while

        XHR.open('POST', url);
        XHR.setRequestHeader(
          'Content-Type',
          'multipart/form-data; boundary=blob'
        );
        XHR.setRequestHeader('Access-Control-Allow-Origin', '*');
        that.props.executeAuthAsyncGet(
          that.props.client,
          'Lookup/OK',
          'CHECK_OK',
          null,
          function () {
            webApiInterface.applySecurity(XHR);
            XHR.send(data + '--blob--');
          }
        );
      };

      reader.readAsBinaryString(file);
      that.setState(that.state);
    }
  }

  static getKey() {
    return 'admin-upload-form';
  }

  render() {
    const that = this;

    return (
      <div>
        <button
          className="btnDefault btn btn-default"
          onClick={this.newExportCsv.bind(this)}
        >
          Export All To XML
        </button>
        <label className="btnDefault btn btn-default">
          Import All From XML
          <input
            type="file"
            id="allLookupsUploader"
            name="allLookupsUploader"
            style={{ display: 'none' }}
            ref={(input) => (this.allLookupsUploader = input)}
            onChange={this.onFileSelected}
            accept=".xml"
          />
        </label>
        <LookupValidationResults
          validationResults={that.state.validationResults}
          tableName={that.props.tableName}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isVisible: state.dashboardReducer.showLookupValidation
});

const mapDispatchToEvents = (dispatch) => {
  return {
    showValidationModal: (isVisible) =>
      dispatch(showValidationResults(isVisible)),
    executeAuthAsyncGet: (client, serviceName, name, data, onSuccess, onError) => {
      dispatch(
        executeAuthAsyncGet(client, serviceName, name, data, onSuccess, onError)
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToEvents)(LookupTransferAll);
