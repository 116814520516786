import { Node } from "reactflow"

type FlowNode = {
    reference: string
    displayName: string
    description: string
    x: number
    y: number
    collapsed?: boolean
}

export default FlowNode

export const mapFlowNodesToNodes = <T extends FlowNode>(
    nodes: T[] = [],
    onNodeEditClick: (reference: string) => void,
    onNodeDeleteClick: (reference: string) => void,
    onNodeCollapseToggle: (reference: string) => void = () => {}
): Node[] => {
    return nodes.map(node => ({
        id: node.reference,
        type: "Node",
        position: { x: node.x, y: node.y },
        data: {
            state: node,
            onNodeEditClick,
            onNodeCollapseToggle,
            onNodeDeleteClick
        }
    }))
}
