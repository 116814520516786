import FiltersDto from "../../../library/FilterList/FiltersDto"
import { DataPrimitiveTypeEnum } from "../../../routes/entityConfig/types/DataType"
import DataPrimitive from "../../../types/DataPrimitive"
import { transactionAggregationTypes } from "../enums/transactionAggregationTypes"
import { widgetThemes } from "../enums/widgetThemes"
import { TrendingChartType, TrendingPeriod } from "./DashboardConfigState"
import { dashboardSubTypes } from "./DashboardTypes"

export type PutDashboardConfigDto = {
    name: string
    type: "CUSTOMER" | "TRANSACTION" | "AGGREGATED" | null
    subType: dashboardSubTypes | null
    entityConfigReference: string | null
    infoWidgets: InfoWidgetDto[]
    valueWidgets: ValueWidgetDto[]
    chartWidgets: ChartWidgetDto[]
    entityValueWidgets: EntityValueWidgetDto[]
    entityChartWidgets: EntityChartWidgetDto[]
    gridWidgets: GridWidgetDto[]
    trendingWidgets: TrendingWidgetDto[]
    roleReferences: string[]
    filters?: FiltersDto
}

export type GetDashboardConfigDto = {
    reference: string
    name: string
    type?: "CUSTOMER" | "TRANSACTION" | "AGGREGATED"
    subType?: dashboardSubTypes
    entityConfigReference?: string
    infoWidgets: InfoWidgetDto[]
    valueWidgets: ValueWidgetDto[]
    chartWidgets: ChartWidgetDto[]
    entityValueWidgets: EntityValueWidgetDto[]
    entityChartWidgets: EntityChartWidgetDto[]
    gridWidgets: GridWidgetDto[]
    trendingWidgets: TrendingWidgetDto[]
    roleReferences: string[]
    filters?: FiltersDto
}

export type InfoWidgetDto = {
    ordinal: number
    cellX: number
    cellY: number
    cellWidth: number
    cellHeight: number
    displayName: string
    fields: WidgetFieldDto[]
    isFieldLabelInline: boolean
    theme: widgetThemes
}

export type ValueWidgetDto = {
    ordinal: number
    gridCellX: number
    gridCellY: number
    gridCellWidth: number
    gridCellHeight: number
    displayName: string
    field: WidgetFieldDto
    theme: widgetThemes
}

export type ChartWidgetDto = {
    ordinal: number
    gridCellX: number
    gridCellY: number
    gridCellWidth: number
    gridCellHeight: number
    displayName: string
    theme: widgetThemes
    type: ChartTypesDto
    bucketChartType: transactionAggregationTypes
}

export type EntityValueWidgetDto = {
    ordinal: number
    gridCellX: number
    gridCellY: number
    gridCellWidth: number
    gridCellHeight: number
    displayName: string
    field: WidgetFieldDto
    theme: widgetThemes
    entityConfigReference: string
}

export type EntityChartWidgetDto = {
    ordinal: number
    gridCellX: number
    gridCellY: number
    gridCellWidth: number
    gridCellHeight: number
    displayName: string
    theme: "DEFAULT" | "BLUE"
    type: "BAR" | "AREA" | "LINE" | "PIE" | "DONUT"
    aggregationType: "SUM" | "AVERAGE" | "MIN" | "MAX" | "COUNT" | "CARDINALITY"
    aggregation: AggregationDto
    entityConfigReference: string
    filters: FiltersDto
}

export type GridWidgetDto = {
    reference: string
    ordinal: number
    gridCellX: number
    gridCellY: number
    gridCellWidth: number
    gridCellHeight: number
    displayName: string
    columns: GridColumnDto[]
    type: GridType | null
    entityConfigReference: string | null
    filters?: FiltersDto
    groupingField: string | null
    draggable: boolean
}

export type TrendingWidgetDto = {
    reference: string
    ordinal: number
    gridCellX: number
    gridCellY: number
    gridCellWidth: number
    gridCellHeight: number
    chartType: TrendingChartType
    entityConfigReference?: string
    filters?: FiltersDto
    groupingField?: string
    comparisonField?: string
    period: TrendingPeriod
    showValues: boolean
}

export type WidgetFieldDto = {
    fieldName: string
    dataType: DataPrimitive
    isEditable?: boolean
}

export enum GridType {
    TRANSACTION = "TRANSACTION",
    TABLE = "TABLE",
    KANBAN = "KANBAN"
}

type GridColumnDto = {
    fieldName: string
    dataType: DataPrimitive
    isEditable?: boolean
}

enum ChartTypesDto {
    PIE = "PIE",
    DONUT = "DONUT",
    BAR = "BAR",
    AREA = "AREA",
    LINE = "LINE"
}

export type AggregationDto = {
    type: "SINGLE" | "MULTI"
    groupingField?: string
    aggregationField?: string
    aggregationFieldType?: DataPrimitiveTypeEnum
    fields?: string[]
}
