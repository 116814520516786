import { keys } from 'lodash';

export const isActivityList = (activityList) => {
  return (
    keys(activityList.criteria).length > 0 &&
    !activityList.next.endOfList &&
    activityList.next.total > 0
  );
};

export const isActivityListPaused = (activityList) => {
  return activityList.next.paused !== null;
};
