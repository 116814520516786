import { get, includes } from 'lodash';

export function roundAmount(amount, decimals) {
  return Math.round(amount * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export function getDisplayAttribute(b) {
  return b ? '' : 'none';
}

export const decimalKeyInput = (inputKeyCode) => {
  const validChars = [
    46, //decimal point
    8, //backspace
    127 //del
  ];

  for (let i = 48; i < 58; i++) {
    validChars.push(i);
  }

  return includes(validChars, inputKeyCode);
};

export const valueFromObject = (obj, prop) => get(obj, prop, 0) || 0;
