import { useState } from "react"
import classes from "./ClientButton.module.scss"

const fallBackImage = "logos/invevo.png"

const ClientButton = ({ client, onClick }) => {
    const [isHovering, setIsHovering] = useState(false)

    const onButtonClick = () => {
        onClick(client)
    }

    const onMouseOver = () => {
        setIsHovering(true)
    }

    const onMouseLeave = () => {
        setIsHovering(false)
    }

    const clientDisplayName = () => {
        if (client === "ubm") return "informa oracle"
        if (client === "informa") return "informa sap"
        if (client === "nps") return "nec"
        if (client === "ukwdpreprod") return "ukwdg"
        if (client === "goplantpreprod") return "goplant"
        return client
    }

    const onImageError = event => {
        if (event.target.src === fallBackImage) return
        event.target.src = fallBackImage
    }

    return (
        <div
            className={`d-flex flex-column m-4 ${classes.container}`}
            role="button"
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onClick={onButtonClick}
        >
            <div className={`d-flex justify-content-center flex-grow-1 ${classes.iconContainer} ${isHovering && classes.hovering}`}>
                <img className={`p-3 w-100 ${classes.objectContain}`} src={`logos/${client}.png`} alt={client} onError={onImageError} />
            </div>
            <div className={`d-flex justify-content-center align-items-center px-3 ${classes.textContainer} ${isHovering && classes.hovering}`}>
                <span className="mb-0 text-center text-uppercase">{clientDisplayName()}</span>
            </div>
        </div>
    )
}

export default ClientButton
