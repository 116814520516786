import FiltersDto from "../../../library/FilterList/FiltersDto"
import GridColumnConfigDto from "../../../library/dataGridV2/types/GridColumnConfigDto"
import { EntityChartWidgetDto, GridType, TrendingWidgetDto } from "../../../microfrontends/dashboard/types/DashboardConfigDto"
import { EntityChartConfigWidgetState, TrendingWidgetState } from "../../../microfrontends/dashboard/types/DashboardConfigState"
import DataPrimitive from "../../../types/DataPrimitive"

export type DashboardConfigDto = {
    reference: string
    name: string
    type?: DashboardTypeEnum
    subType?: CustomerDashboardSubTypeEnum
    entityConfigReference: string
    infoWidgets: InfoWidget[]
    valueWidgets: ValueWidget[]
    chartWidgets: ChartWidget[]
    gridWidgets: GridWidget[]
    entityChartWidgets: EntityChartWidgetDto[]
    trendingWidgets: TrendingWidgetDto[]
    roleReferences: string[]
    filters?: FiltersDto
}

export type DashboardConfig = {
    reference: string
    name: string
    type?: DashboardTypeEnum
    subType?: CustomerDashboardSubTypeEnum
    entityConfigReference: string
    infoWidgets: InfoWidget[]
    valueWidgets: ValueWidget[]
    chartWidgets: ChartWidget[]
    gridWidgets: GridWidget[]
    entityChartWidgets: EntityChartConfigWidgetState[]
    trendingWidgets: TrendingWidgetState[]
    roleReferences: string[]
    filters?: FiltersDto
}

export type InfoWidget = {
    ordinal: number
    cellX: number
    cellY: number
    cellWidth: number
    cellHeight: number
    displayName: string
    fields: WidgetField[]
    isFieldLabelInline: boolean
    theme: WidgetThemeEnum
}

export type ValueWidget = {
    ordinal: number
    gridCellX: number
    gridCellY: number
    gridCellWidth: number
    gridCellHeight: number
    displayName: string
    field: WidgetField
    theme: WidgetThemeEnum
}

export type ChartWidget = {
    ordinal: number
    gridCellX: number
    gridCellY: number
    gridCellWidth: number
    gridCellHeight: number
    displayName: string
    theme: WidgetThemeEnum
    type: ChartTypeEnum
    bucketChartType: BucketChartTypeEnum
}

export type GridWidget = {
    reference: string
    ordinal: number
    gridCellX: number
    gridCellY: number
    gridCellWidth: number
    gridCellHeight: number
    displayName: string
    columns: GridColumnConfigDto[]
    entityConfigReference?: string
    filters?: FiltersDto
    groupingField: string | null
    draggable: boolean
    type: GridType | null
    defaultSort?: GridSortDto | null
}

export type GridSortDto = {
    fieldName: string
    dataPrimitive: DataPrimitive
    direction: "ASC" | "DESC"
    searchAfter?: string
}

export type WidgetField = {
    fieldName: string
    dataType: DataPrimitive
    isEditable: boolean
}

export enum DashboardTypeEnum {
    AGGREGATED = "AGGREGATED",
    CUSTOMER = "CUSTOMER",
    TRANSACTION = "TRANSACTION"
}

export enum CustomerDashboardSubTypeEnum {
    SUMMARY = "SUMMARY",
    CONTACTS = "CONTACTS",
    FILES = "FILES",
    TRANSACTIONS = "TRANSACTIONS"
}

export enum WidgetThemeEnum {
    DEFAULT = "DEFAULT",
    BLUE = "BLUE"
}

export enum ChartTypeEnum {
    PIE = "PIE",
    DONUT = "DONUT",
    BAR = "BAR",
    AREA = "AREA",
    LINE = "LINE"
}

export enum BucketChartTypeEnum {
    AGED_DEBT = "AGED_DEBT",
    FALLING_DUE = "FALLING_DUE",
    RECENT_PAYMENTS = "RECENT_PAYMENTS",
    TRANSACTION_STATUS = "TRANSACTION_STATUS",
    TRANSACTION_TYPE = "TRANSACTION_TYPE"
}
