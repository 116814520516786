import { useEffect } from "react"
import { useLayout, expandablePanels } from "../../../contexts/LayoutContext"
import useApiQuery from "../../../hooks/useApiQuery"
import Lookup from "../../../types/Lookup"
import EntityConfig from "../../entityConfig/types/EntityConfig"
import { DashboardConfig, DashboardConfigDto, DashboardTypeEnum } from "../types/DashboardConfig"
import { useUser } from "../../../contexts/UserContext"
import EntitySearchModularDashboard from "./EntitySearchModularDashboard"
import useClient from "../../../hooks/useClient"
import { useEnvConfig } from "../../../contexts/EnvironmentConfigContext"
import Loading from "../../../library/Loading/Loading"
import { widgetTypes } from "../../../microfrontends/dashboard/enums/widgetTypes"
import { convertAggregationDtoToState } from "../../../microfrontends/dashboard/helpers/dashboardMapping"
import { convertFromDtoToArray } from "../../../library/FilterList/FiltersDto"
import { getDataFieldsFromEntityDataFields } from "../../../library/helpers/entityHelpers"
import { EntityChartConfigWidgetState } from "../../../microfrontends/dashboard/types/DashboardConfigState"
import EntityRelationshipDto from "../../entityConfig/types/EntityRelationshipDto"

type EntitySearchProps = {
    className?: string
    nextEntity?: number
    goToNextEntity: ({ reset }: { reset?: boolean }) => void
}

const EntitySearch = ({ className = "", nextEntity, goToNextEntity }: EntitySearchProps) => {
    const client = useClient()
    const config = useEnvConfig()
    const user = useUser()

    const { setExpandedPanel } = useLayout()

    useEffect(() => {
        setExpandedPanel(expandablePanels.LEFT_PANEL)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getLookupsRequest = useApiQuery<Lookup[]>({
        url: `${config.DATA_API_URL}/api/${client}/lookups`,
        method: "GET"
    })

    const getEntityConfigsRequest = useApiQuery<EntityConfig[]>({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-config`,
        method: "GET"
    })

    const getEntityRelationshipsRequest = useApiQuery<EntityRelationshipDto[]>({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-relationships`,
        method: "GET"
    })

    const getDashboardConfigsRequest = useApiQuery<DashboardConfigDto[]>({
        url: `${config.DATA_API_URL}/api/${client}/user-dashboard-configs`,
        method: "GET"
    })

    const aggregatedDashboardConfigs: DashboardConfig[] =
        getEntityConfigsRequest.data !== undefined && getLookupsRequest.data !== undefined
            ? getDashboardConfigsRequest.data
                  ?.filter(config => config.type === DashboardTypeEnum.AGGREGATED && config.entityConfigReference !== null)
                  .map(config => ({
                      ...config,
                      entityChartWidgets: (config.entityChartWidgets ?? []).map(
                          widget =>
                              ({
                                  ...widget,
                                  widgetType: widgetTypes.ENTITY_CHART_WIDGET,
                                  aggregation: convertAggregationDtoToState(widget.aggregation),
                                  chartType: widget.type,
                                  filters: convertFromDtoToArray(
                                      widget.filters,
                                      getDataFieldsFromEntityDataFields(
                                          getEntityConfigsRequest.data?.find(entityConfig => entityConfig.reference === widget.entityConfigReference)?.fields ??
                                              []
                                      ),
                                      getLookupsRequest.data ?? []
                                  )
                              } as EntityChartConfigWidgetState)
                      ),
                      trendingWidgets: (config.trendingWidgets ?? []).map(widget => {
                          const entityConfig = getEntityConfigsRequest.data?.find(e => e.reference === widget.entityConfigReference)
                          return {
                              ...widget,
                              widgetType: widgetTypes.ENTITY_TRENDING as widgetTypes.ENTITY_TRENDING,
                              filters: convertFromDtoToArray(
                                  widget.filters,
                                  getDataFieldsFromEntityDataFields(entityConfig?.fields ?? []),
                                  getLookupsRequest.data ?? []
                              ),
                              groupingField: entityConfig?.fields.find(f => f.fieldName === widget.groupingField),
                              comparisonField: entityConfig?.fields.find(f => f.fieldName === widget.comparisonField)
                          }
                      })
                  }))
                  .sort((a, b) => (a.name > b.name ? 1 : 0)) ?? []
            : []

    const enabledRoles = user.userRoles.filter(r => r.enabled).map(r => r.reference)
    const adminRoleRef = user.userRoles.find(r => r.roleName.toLowerCase() === "admin" || r.roleName.toLowerCase() === "administrator")?.reference ?? ""

    const entityRelationships = getEntityRelationshipsRequest.data ?? []
    const entityConfigs = getEntityConfigsRequest.data ?? []
    const userAggregatedDashboardConfigs = aggregatedDashboardConfigs.filter(
        config => config.roleReferences.length === 0 || config.roleReferences.some(r => enabledRoles.includes(r)) || enabledRoles.includes(adminRoleRef)
    )

    const isFetching = getEntityConfigsRequest.isFetching || getLookupsRequest.isFetching || getDashboardConfigsRequest.isFetching
    const hasFetched = getEntityConfigsRequest.hasFetched && getLookupsRequest.hasFetched && getDashboardConfigsRequest.hasFetched

    return (
        <Loading isLoading={isFetching} colour="blue">
            {!isFetching && hasFetched && userAggregatedDashboardConfigs.length > 0 && entityConfigs.length > 0 && (
                <EntitySearchModularDashboard
                    className={className}
                    entityConfigs={entityConfigs}
                    entityRelationships={entityRelationships}
                    lookups={getLookupsRequest.data ?? []}
                    dashboardConfigs={userAggregatedDashboardConfigs}
                />
            )}
        </Loading>
    )
}

export default EntitySearch
