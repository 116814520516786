import moment from "moment"
import { v4 as uuid } from "uuid"

export const generateHtmlId = () => `a${uuid().replace(/-/g, "")}`

export const padElementsWith = (elements: JSX.Element[], paddingClassName: string): JSX.Element[] =>
    elements.length === 0
        ? []
        : elements
              .map(e => [e, <div key={generateHtmlId()} className={paddingClassName} />])
              .reduce((prev, curr) => [...prev, ...curr])
              .splice(0, elements.length * 2 - 1)

/**
 * @deprecated use isValidNumber from library/helpers/numberUtils.ts.
 */
export const isValidNumber = (number: string) => {
    const isValidNumberRegex = /^-?\d+(\.\d+)?$/
    return isValidNumberRegex.test(number)
}

/**
 * @deprecated use isValidDate from library/helpers/dateUtils.ts.
 */
export const isValidDate = (date: string) => date !== "" && moment(date).isValid()

export const capitaliseFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)
