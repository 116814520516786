import { AutoComplete, Input } from "invevo-react-components"
import { FileImportConfigFieldDataType, FileImportConfigFieldDataTypeEnum } from "../../../../types/FileImportConfigFieldDataType"
import moment from "moment-timezone"

type DataTypeConfigProps = {
    config: FileImportConfigFieldDataType
    onChange: (option: FileImportConfigFieldDataType) => any
}

const DataTypeConfig = ({ config, onChange }: DataTypeConfigProps) => {
    const timezones = moment.tz.names().map((tz, index) => ({
        id: index,
        value: tz,
        label: tz
    }))

    const onDateFormatChange = (event: React.FormEvent<HTMLInputElement>) => {
        const format = event.currentTarget.value
        switch (config.type) {
            case FileImportConfigFieldDataTypeEnum.DATE:
                onChange({
                    ...config,
                    format
                })
        }
    }

    const onTimezoneChange = (timezone: any) => {
        switch (config.type) {
            case FileImportConfigFieldDataTypeEnum.DATE:
                onChange({
                    ...config,
                    timezone
                })
        }
    }

    const onNumberCultureChange = (event: React.FormEvent<HTMLInputElement>) => {
        const culture = event.currentTarget.value
        switch (config.type) {
            case FileImportConfigFieldDataTypeEnum.NUMBER:
                onChange({
                    ...config,
                    culture
                })
        }
    }

    return (
        <div className="d-flex">
            {config.type === FileImportConfigFieldDataTypeEnum.DATE ? (
                <>
                    <div className={`d-flex flex-column small me-3`}>
                        <span className="text-uppercase small mb-1 ms-1 text-grey">Date format</span>
                        <Input placeholder="Please enter the value" value={config.format} onChange={onDateFormatChange} />
                    </div>
                    <div className={`d-flex flex-column small`}>
                        <span className="text-uppercase small mb-1 ms-1 text-grey">Timezone</span>
                        <AutoComplete placeholder="Please select a timezone" options={timezones} initialValue={config.timezone} onChange={onTimezoneChange} />
                    </div>
                </>
            ) : config.type === FileImportConfigFieldDataTypeEnum.NUMBER ? (
                <div className={`d-flex flex-column small`}>
                    <span className="text-uppercase small mb-1 ms-1 text-grey">Culture</span>
                    <Input placeholder="Please enter the culture" value={config.culture} onChange={onNumberCultureChange} />
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default DataTypeConfig
