import update from 'immutability-helper';

const getInitialState = () => {
    return {
        reportDetails:{ isFetching: false, isFetched: false, error: null, result: {}}
    }
}

const reportReducer = (state = getInitialState(), action) => {
    let nState;

    switch (action.type) {
        case 'GET_REPORT_DETAILS':
            nState = update (state, {reportDetails: {result: {$set: {}}, isFetching:{$set: true}, isFetched:{$set: false}, error: {$set: null}}});
            return nState;
        case 'RECEIVE_REPORT_DETAILS':
            nState = update (state, {reportDetails: {result: {$set: action.data}, isFetching:{$set: false}, isFetched:{$set: true}, error: {$set: null}}});
            return nState;
        case 'RECEIVE_REPORT_DETAILS_ERROR':
            nState = update (state, {reportDetails: {result: {$set: {}}, isFetching:{$set: false}, isFetched:{$set: true}, error:{$set: action.err}}});
            return nState;
        default:
        return state;
    }
}

export default reportReducer;