import React from 'react';
import moment from 'moment';

export const dueDateMessage = (date) => {
  if (!date) return null;

  if (typeof date === 'string') date = moment(new Date(date));
  else if (date instanceof Date) date = moment(date);

  const dueDate = date.startOf('date');
  const today = moment().startOf('date');
  const daysDiff = dueDate.diff(today, 'days');

  // conditional switch
  switch (true) {
    case daysDiff < -1:
      return (
        <span className="badge bg-danger">
          {`Overdue ${Math.abs(daysDiff)} days`}
        </span>
      );

    case daysDiff === -1:
      return <span className="badge bg-danger">Due yesterday</span>;

    case daysDiff === 0:
      return <span className="badge bg-secondary">Due today</span>;

    case daysDiff === 1:
      return <span className="badge bg-secondary">Due tomorrow</span>;

    case daysDiff > 1 && daysDiff < 7:
      return (
        <span className="badge bg-secondary">{`${daysDiff} days time`}</span>
      );

    default:
      return (
        <span className="badge bg-secondary">{`Due ${dueDate.format(
          'ddd ll'
        )}`}</span>
      );
  }
};
