import React from 'react';

const EmailUnread = () => {
  return (
    <div className="success">
      <div className="speech-bubble">
        <div className="mia-logo"></div>
        <p>Mia</p>
      </div>
      <h2>You have an unread email</h2>
    </div>
  );
};

export default EmailUnread;
