import * as types from '../actions/actionTypes';

export default function fileTypeReducer(state = [], action) {
    switch (action.type) {
        case types.LOAD_FILE_TYPE_SUCCESS:
        case types.NEW_FILE_TYPE_SUCCESS:
        case types.CREATE_FILE_TYPE_SUCCESS:
        case types.UPDATE_FILE_TYPE_SUCCESS:
        case types.DELETE_FILE_TYPE_SUCCESS:
        case types.FILE_TYPE_CANCEL:
            return [...state, Object.assign({}, action.fileType)];
        default:
            return state;
    }
}