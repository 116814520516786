import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import TemplateSelector from "./templateSelector"
import EmailPreview from "./emailPreview"
import EmailSuccess from "./emailSuccess"
import EmailUnread from "./emailUnread"
import EmailReply from "./emailReply"
import usePrevious from "../../hooks/usePrevious"
import { useInvevoSession } from "invevo-react-components"
import classes from "./Email.module.scss"
import { ActionStripItems, useActionStrip } from "../../../../contexts/ActionStripContext"

const Email = ({ selectedAccount }) => {
    const [, setInvevoSession] = useInvevoSession()
    const { navToAction, lastNavigationParams } = useActionStrip()
    const [emailTemplate, setEmailTemplate] = useState({
        masterTemplateContent: "",
        clientTemplateContent: "",
        htmlFragments: [],
        masterVariables: [],
        clientVariables: [],
        subject: "",
        clientTemplateId: "",
        customerId: "",
        userId: ""
    })

    const accountId = selectedAccount.miaAccountId
    const prevAccountId = usePrevious(selectedAccount.miaAccountId)
    useEffect(() => {
        if (accountId !== prevAccountId && lastNavigationParams.emailMode !== "email-unread") {
            setInvevoSession({ selectedCustomerContacts: [], selectedInternalContacts: [], selectedDocuments: [], selectedTransactionRefs: [] })
        }
    }, [prevAccountId, accountId, lastNavigationParams.emailMode, setInvevoSession])

    const [contentTypes, setContentTypes] = useState({
        showMessage: false,
        showTrans: false,
        showTransAttachments: false
    })

    const onSaveTemplateInfos = infos => {
        setEmailTemplate({
            masterTemplateContent: infos.masterTemplateContent,
            clientTemplateContent: infos.clientTemplateContent,
            htmlFragments: infos.htmlFragments,
            masterVariables: infos.masterVariables,
            clientVariables: infos.clientVariables,
            subject: infos.subject,
            clientTemplateId: infos.clientTemplateId,
            customerId: selectedAccount.miaAccountId,
            userId: infos.userId
        })

        const requiresContent = propName => infos.clientVariables.find(c => c === propName) !== undefined

        setContentTypes({
            showMessage: requiresContent("personalized-content"),
            showTrans: requiresContent("transaction-rows") || requiresContent("transactions-portal-link"),
            showTransAttachments: requiresContent("transaction-rows-attached")
        })

        navToAction(ActionStripItems.EMAIL, { emailMode: "email-preview" })
    }

    const onEmailSentSuccess = () => navToAction(ActionStripItems.EMAIL, { emailMode: "email-success" })

    let contentPage
    switch (lastNavigationParams.emailMode) {
        case "email-preview":
            contentPage = <EmailPreview contentTypes={contentTypes} emailTemplate={emailTemplate} onEmailSentSuccess={onEmailSentSuccess} />
            break
        case "email-reply":
            contentPage = <EmailReply onEmailSentSuccess={onEmailSentSuccess} />
            break
        case "email-success":
            contentPage = <EmailSuccess />
            break
        case "email-unread":
            contentPage = <EmailUnread />
            break
        default:
            contentPage = <TemplateSelector onSaveTemplateInfos={onSaveTemplateInfos} />
    }

    return (
        <div className={`d-flex overflow-auto ${classes.container} w-100`}>
            <div id="email-pane" className="top20 w-100">
                {contentPage}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        selectedAccount: state.currentSelectionReducer.selectedAccount,
        replyToEmail: state.functionReducer.replyToEmail
    }
}

export default connect(mapStateToProps, {})(Email)
