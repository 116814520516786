import { useClient } from 'invevo-react-components';
import { find, forEach, orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getUserConfig } from '../api/uiConfigApi';
import { UserConfigViewType } from '../constants';
import { normaliseFormatDef } from '../selectors/lookup';

const formatSelector = createSelector(
  (state) => state.lookupReducer.globalSettings.results,
  (settings) => ({
    currencyFormat: normaliseFormatDef(settings.currencyFormat),
    dateFormat: normaliseFormatDef(settings.dateFormat)
  })
);

const userColConfigSelector = () =>
  createSelector(
    (state) => state.uiConfigReducer.configs,
    (_, columnSetId) => columnSetId,
    (configs, columnSetId) => configs[columnSetId]
  );

const defaultColumnsSelector = () =>
  createSelector(
    (state) => state.lookupReducer.userTaskGridColumns,
    (state) => state.lookupReducer.searchGridColumns,
    (_, columnSetId) => columnSetId,
    (userTaskGridColumns, searchGridColumns, columnSetId) => {
      switch (columnSetId) {
        case UserConfigViewType.userTaskGridColConfig:
          return orderBy(userTaskGridColumns.results, ['Ordinal']);
        case UserConfigViewType.searchResultGridColConfig:
          return orderBy(searchGridColumns.results, ['Ordinal']);
        default:
          return [];
      }
    }
  );

function useUserConfigColumns(columnSetId) {
  const selectDefaultColumns = useMemo(defaultColumnsSelector, []);
  const defaultColumns = useSelector((state) =>
    selectDefaultColumns(state, columnSetId)
  );

  const selectUserColConfig = useMemo(userColConfigSelector, []);
  const userColConfig = useSelector((state) =>
    selectUserColConfig(state, columnSetId)
  );

  const format = useSelector(formatSelector);

  const buildColumns = useCallback(() => {
    const result = [];

    const userColumns =
      userColConfig && userColConfig.Content ? userColConfig.Content : [];

    forEach(defaultColumns, (column) => {
      const found = find(userColumns, (f) => f.field === column.Field);
      const show = found ? found.show : column.DisplayColumn;

      if (column.Type === 'date') {
        result.push({
          field: column.Field,
          title: column.Title,
          format: format.dateFormat,
          filter: 'date',
          type: 'date',
          show
        });
      } else {
        switch (column.Field) {
          case 'Balance':
          case 'Overdue':
          case 'Amount':
          case 'SelectedBalance':
            result.push({
              field: column.Field,
              title: column.Title,
              format: format.currencyFormat,
              attributes: { class: 'text-end' },
              filter: 'numeric',
              type: 'numeric',
              show
            });
            break;
          default:
            result.push({
              field: column.Field,
              title: column.Title,
              show
            });
        }
      }
    });

    return result;
  }, [format.dateFormat, format.currencyFormat, defaultColumns, userColConfig]);

  const dispatch = useDispatch();

  const [columns, setColumns] = useState(() => buildColumns());
  useEffect(() => {
    setColumns(buildColumns());
  }, [buildColumns]);

  const hasConfig = !!userColConfig;

  const client = useClient()

  useEffect(() => {
    if (!client) { return }
    if (!hasConfig) dispatch(getUserConfig(client, columnSetId));
  }, [hasConfig, columnSetId, dispatch, client]);

  return [columns, setColumns];
}

export default useUserConfigColumns;
