import React, { useState, useEffect } from 'react';

const ColumnSelection = ({ columns, onColumnsSubmit, onCloseMenu }) => {
  const [columnsForm, setColumnsForm] = useState(columns || []);

  useEffect(() => {
    setColumnsForm(columns);
  }, [columns]);

  const onToggleColumn = (id) => {
    setColumnsForm(
      columnsForm.map((col, idx) =>
        idx === id ? { ...col, show: !col.show } : col
      )
    );
  };

  const onReset = (event) => {
    if (event) event.preventDefault();
    setColumnsForm(columns);
  };

  const onSubmit = (event) => {
    if (event) event.preventDefault();
    onColumnsSubmit(columnsForm);
    if (onCloseMenu) onCloseMenu();
  };

  const oneVisibleColumn = columnsForm.filter((c) => c.show).length === 1;

  return (
    <div className={'k-column-list-wrapper'}>
      <form onSubmit={onSubmit} onReset={onReset}>
        <div className={'k-column-list'}>
          {columnsForm.map((column, idx) => (
            <div key={column.field} className={'k-column-list-item'}>
              <span>
                <input
                  id={`column-visiblity-show-${idx}`}
                  className="form-check-input me-1"
                  type="checkbox"
                  readOnly={true}
                  disabled={column.show && oneVisibleColumn}
                  checked={column.show}
                  onClick={() => {
                    onToggleColumn(idx);
                  }}
                />
                <label
                  htmlFor={`column-visiblity-show-${idx}`}
                  className="form-check-label"
                  style={{ userSelect: 'none' }}
                >
                  {column.title || column.field}
                </label>
              </span>
            </div>
          ))}
        </div>
        <div className={'k-columnmenu-actions'}>
          <button type={'reset'} className={'k-button'}>
            Reset
          </button>
          <button className={'k-button k-primary'} type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ColumnSelection;
