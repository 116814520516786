import { useEffect, useMemo, useRef, useState } from "react"
import classes from "./SmallPieChart.module.scss"
import PieChartDetails from "./PieChartDetails"
import Chart from "chart.js/auto"
import { Colors } from "chart.js/auto"
import type { MouseEvent } from "react"
import { EntityChartDisplayWidgetData } from "../../microfrontends/dashboard/components/customerDashboards/widgets/EntityChartDisplayWidget"
import { ChartColours } from "./ChartColours"

type EntitySmallPieChartProps = {
    className?: string
    colour: "white" | "blue"
    title: string
    state: EntityChartDisplayWidgetData
    headerButtons?: React.ReactNode
    onClick: (value: string) => void
}

const EntitySmallPieChart = ({ className = "", title, state, colour = "white", headerButtons = <></>, onClick }: EntitySmallPieChartProps) => {
    const { data, xLabels } = state
    const canvasRef = useRef<HTMLCanvasElement>(null)

    const [chart, setChart] = useState<Chart<"pie", number[], string> | undefined>(undefined)

    const onMouseOver = (label: string) => () => {
        chart?.setActiveElements([
            {
                datasetIndex: 0,
                index: xLabels.findIndex(l => l === label) ?? 0
            }
        ])
        chart?.update()
    }

    const onMouseOut = () => {
        chart?.setActiveElements([])
        chart?.update()
    }

    const onCanvasClick = (event: MouseEvent<HTMLCanvasElement>) => {
        const element = chart?.getElementsAtEventForMode(event.nativeEvent, "nearest", { intersect: true }, false)
        if (!element || !chart?.data.labels) return
        if (!element[0]) return

        onClick(chart.data.labels[element[0].index] ?? "")
    }

    useEffect(() => {
        if (!canvasRef.current) return
        Chart.register(Colors)

        const chart = new Chart(canvasRef.current, {
            type: "pie",
            data: {
                labels: xLabels.map(label => label.toUpperCase()),
                datasets: [
                    {
                        data: data,
                        backgroundColor: ChartColours
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: false,
                        text: title
                    },
                    legend: {
                        display: false
                    }
                },
                locale: "en-GB"
            }
        })
        setChart(chart)
        return () => chart?.destroy()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const valueColours = useMemo(() => chart?.getDatasetMeta(0).data.map(value => value.options.backgroundColor) ?? [], [chart])

    return (
        <div className={`${className} d-flex flex-column w-100 p-3 rounded ${classes[`widget-${colour}`]}`}>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div className={`text-truncate fs-5 text-uppercase d-block ${colour === "blue" ? "text-white" : "text-grey"}`}>{title}</div>
                {headerButtons}
            </div>
            <div className={`${classes["pie-grid"]} pt-2`}>
                <div className={`${classes["chart-container"]}`}>
                    <canvas ref={canvasRef} onClick={onCanvasClick} />
                </div>
                <div className={`${classes["pie-details"]}`}>
                    <div className="d-flex flex-column justify-content-center ps-2">
                        {xLabels.map((item, index) =>
                            state.type === "SINGLE" ? (
                                <PieChartDetails
                                    key={index}
                                    detail={{ label: item, value: data[index] ?? 0, displayField: state.displayField }}
                                    textColour={colour}
                                    dotColour={valueColours[index] ?? "black"}
                                    onMouseOver={onMouseOver}
                                    onMouseOut={onMouseOut}
                                    onClick={onClick}
                                />
                            ) : (
                                <PieChartDetails
                                    key={index}
                                    detail={{ label: item, value: data[index] ?? 0, displayField: state.displayFields[index] }}
                                    textColour={colour}
                                    dotColour={valueColours[index] ?? "black"}
                                    onMouseOver={onMouseOver}
                                    onMouseOut={onMouseOut}
                                    onClick={onClick}
                                />
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EntitySmallPieChart
