import update from 'immutability-helper';
import { lookup } from '../constants';

const lookupReducer = (state = lookup.initialState, action) => {
  switch (action.type) {
    case lookup.ERROR: {
      return update(state, {
        [action.lookupName]: {
          isFetching: { $set: false },
          error: { $set: action.err },
          fetched: { $set: false },
          results: { $set: [] }
        }
      });
    }
    case lookup.REQUEST_LOOKUP: {
      return update(state, {
        [action.lookupName]: {
          isFetching: { $set: true },
          fetched: { $set: false },
          results: { $set: [] }
        }
      });
    }
    case lookup.RECEIVE_LOOKUP: {
      // handles bootstraped items in api not yet in client
      if (state[action.lookupName] === undefined) {
        return state;
      }

      return update(state, {
        [action.lookupName]: {
          isFetching: { $set: false },
          error: { $set: null },
          fetched: { $set: true },
          results: { $set: action.results }
        }
      });
    }
    case lookup.CLEAR_LOOKUP: {
      return update(state, {
        [action.lookupName]: {
          isFetching: { $set: false },
          error: { $set: null },
          fetched: { $set: false },
          results: { $set: [] }
        }
      });
    }
    default: {
      return state;
    }
  }
};

export default lookupReducer;
