import { Tabs, Tab } from "invevo-react-components"
import { useEffect, useReducer, useState } from "react"
import { actionTypes } from "../../reducers/actionTypes"
import dashboardConfigsReducer, { DashboardConfigEntityConfig } from "../../reducers/dashboardConfigsReducer"
import EntityDashboardConfigs from "./EntityDashboardConfigs"
import { dashboardTypes } from "../../types/DashboardTypes"
import Header from "./Header"
import { mapDashboardConfigFromGetDto } from "../../helpers/dashboardMapping"
import { v4 as uuidv4 } from "uuid"
import useApiQuery from "../../../../hooks/useApiQuery"
import { toDataPrimitive } from "../../../../library/helpers/entityHelpers"
import { DataPrimitiveTypeEnum, DataTypeEnum } from "../../../../routes/entityConfig/types/DataType"
import { fieldDataTypes } from "../../enums/fieldDataTypes"
import { GetDashboardConfigDto } from "../../types/DashboardConfigDto"
import EntityConfig from "../../../../routes/entityConfig/types/EntityConfig"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"
import useClient from "../../../../hooks/useClient"
import { useEnvConfig } from "../../../../contexts/EnvironmentConfigContext"
import Lookup from "../../../../types/Lookup"
import Role from "../../../../routes/userRoles/roles/types/Role"
import FieldType from "../../../../types/enums/FieldType"
import { DataFieldDto } from "../../../../types/dtos/DataFieldDto"
import DataPrimitive from "../../../../types/DataPrimitive"
import Loading from "../../../../library/Loading/Loading"

const DashboardConfigs = () => {
    const [state, dispatch] = useReducer(dashboardConfigsReducer, {
        editingConfig: {
            infoWidgets: [],
            valueWidgets: [],
            smallBarChartWidgets: [],
            areaChartWidgets: [],
            lineChartWidgets: [],
            smallPieChartWidgets: [],
            transactionsWidgets: [],
            gridWidgets: [],
            roleReferences: [],
            reference: "",
            name: "",
            entityValueWidgets: [],
            entityChartWidgets: [],
            trendingWidgets: []
        },
        originalConfig: undefined,
        customerFields: [],
        transactionFields: [],
        dashboardConfigs: [],
        entityConfigs: [],
        showValidationErrors: false,
        selectedDashboardType: dashboardTypes.ENTITY,
        roles: [],
        lookups: []
    })

    const [isUnauthorised, setIsUnauthorised] = useState(false)

    const { isEnabled: isNewEntityStructureEnabled } = useFeatureToggle("newEntityStructure")
    const { isEnabled: useEntityDashboardsWhenSqlFirst } = useFeatureToggle("useEntityDashboardsWhenSqlFirst")
    const { isEnabled: isSqlFirst } = useFeatureToggle("sqlFirst")
    const [selectedTabDashboard, setSelectedTabDashboard] = useState<"customer" | "customer aggregated" | "entity aggregated" | undefined>(undefined)

    const client = useClient()
    const config = useEnvConfig()

    const entityTabClicked = () => {
        dispatch({ type: actionTypes.DASHBOARD_TYPE_CHANGED, dashboardType: dashboardTypes.ENTITY })
    }
    const customerTabClicked = () => {
        setSelectedTabDashboard("customer")
        dispatch({ type: actionTypes.DASHBOARD_TYPE_CHANGED, dashboardType: dashboardTypes.ENTITY })
    }
    const customerAggregatedTabClicked = () => {
        setSelectedTabDashboard("customer aggregated")
        dispatch({ type: actionTypes.DASHBOARD_TYPE_CHANGED, dashboardType: dashboardTypes.AGGREGATED })
    }
    const aggregatedTabClicked = () => {
        setSelectedTabDashboard("entity aggregated")
        dispatch({ type: actionTypes.DASHBOARD_TYPE_CHANGED, dashboardType: dashboardTypes.AGGREGATED })
    }

    const getLookupsRequest = useApiQuery<Lookup[]>({
        url: `${config.DATA_API_URL}/api/${client}/lookups`,
        method: "GET",
        onSuccess: response => dispatch({ type: actionTypes.LOOKUPS_RETRIEVED, lookups: response })
    })

    const getDashboardConfigs = useApiQuery<GetDashboardConfigDto[]>({
        url: `${config.DATA_API_URL}/api/${client}/dashboard-configs`,
        method: "GET",
        onError: error => {
            if (error.response?.status === 401) {
                setIsUnauthorised(true)
            }
        }
    })

    const getRoles = useApiQuery<Role[]>({
        url: `${config.PLATFORM_API_URL}/api/${client}/roles`,
        method: "GET",
        onSuccess: response => dispatch({ type: actionTypes.ROLES_RETRIEVED, roles: response }),
        onError: error => {
            if (error.response?.status === 401) {
                setIsUnauthorised(true)
            }
        }
    })

    const getEntityConfigs = useApiQuery<EntityConfig[]>({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-config`,
        method: "GET",
        onSuccess: response => dispatch({ type: actionTypes.ENTITY_CONFIGS_RETRIEVED, entityConfigs: mapToEntityDataFields(response) }),
        onError: error => {
            if (error.response?.status === 401) {
                setIsUnauthorised(true)
            }
        }
    })
    const dataPrimitiveToDataTypeEnum = (field: DataPrimitive): DataTypeEnum => {
        switch (field) {
            case DataPrimitive.NUMBER:
                return DataTypeEnum.DECIMAL
            case DataPrimitive.TEXT:
                return DataTypeEnum.TEXT
            case DataPrimitive.DATE:
                return DataTypeEnum.DATE
            case DataPrimitive.BOOLEAN:
                return DataTypeEnum.BOOLEAN
            case DataPrimitive.LOOKUP:
                return DataTypeEnum.LOOKUP
        }
    }
    const dataPrimitiveToDataPrimitiveTypeEnum = (field: DataPrimitive): DataPrimitiveTypeEnum => {
        switch (field) {
            case DataPrimitive.NUMBER:
                return DataPrimitiveTypeEnum.NUMBER
            case DataPrimitive.LOOKUP:
            case DataPrimitive.TEXT:
                return DataPrimitiveTypeEnum.TEXT
            case DataPrimitive.DATE:
                return DataPrimitiveTypeEnum.DATE
            case DataPrimitive.BOOLEAN:
                return DataPrimitiveTypeEnum.BOOLEAN
        }
    }
    const getDataFieldsRequest = useApiQuery<DataFieldDto[]>({
        url: `${config.DATA_API_URL}/api/${client}/data-field`,
        method: "GET",
        onSuccess: response => {
            const fields = response.map(field => ({
                id: uuidv4(),
                ...field,
                dataType: field.fieldType,
                type: field.dataPrimitive,
                value: field.fieldName,
                label: field.displayName
            }))

            const customerFieldsAsEntityFields = fields
                .filter(f => f.dataType === fieldDataTypes.CUSTOMER)
                .map(field => ({
                    ...field,
                    fieldName: field.value,
                    displayName: field.label,
                    description: "",
                    dataType: {
                        type: dataPrimitiveToDataTypeEnum(field.type),
                        lookupReference: field.lookup
                    },
                    dataPrimitiveType: dataPrimitiveToDataPrimitiveTypeEnum(field.type),
                    isQuickFilter: field.isQuickFilter
                }))

            dispatch({
                type: actionTypes.AUTOCOMPLETE_CUSTOMER_FIELDS_RETRIEVED,
                fields: customerFieldsAsEntityFields
            })
            dispatch({
                type: actionTypes.AUTOCOMPLETE_TRANSACTION_FIELDS_RETRIEVED,
                fields: fields.filter(f => f.dataType === fieldDataTypes.TRANSACTION)
            })
        },
        onError: error => console.error(error)
    })

    useEffect(() => {
        if (getDashboardConfigs.data === undefined || getLookupsRequest.data === undefined || getEntityConfigs.data === undefined) {
            return
        }

        dispatch({
            type: actionTypes.DASHBOARD_CONFIGS_RETRIEVED,
            dashboardConfigs: mapDashboardConfigFromGetDto(
                getDashboardConfigs.data,
                isNewEntityStructureEnabled,
                getLookupsRequest.data,
                mapToEntityDataFields(getEntityConfigs.data)
            )
        })
    }, [getDashboardConfigs.data, getLookupsRequest.data, getEntityConfigs.data, isNewEntityStructureEnabled])

    const allFetched = getDashboardConfigs.hasFetched && getRoles.hasFetched && getEntityConfigs.hasFetched && getLookupsRequest.hasFetched

    const mapToEntityDataFields = (entityConfigs: EntityConfig[]): DashboardConfigEntityConfig[] =>
        entityConfigs.map(config => ({
            id: uuidv4(),
            ...config,
            fields: config.fields.map(field => ({
                ...field,
                // Required for FilterList to work
                value: field.fieldName,
                type: toDataPrimitive(field.dataType.type),
                lookup: field.dataType.type === DataTypeEnum.LOOKUP ? field.dataType.lookupReference : undefined,
                label: field.displayName,
                fieldType: FieldType.ENTITY
            }))
        }))

    const customerAggregatedDashboardConfigs = state.dashboardConfigs
        .filter(config => config.type === dashboardTypes.AGGREGATED)
        .filter(config => config.entityConfigReference === null)
    const entityAggregatedDashboardConfigs = state.dashboardConfigs
        .filter(config => config.type === dashboardTypes.AGGREGATED)
        .filter(config => config.entityConfigReference !== null)
    const aggregatedDashboardConfigs = state.dashboardConfigs.filter(config => config.type === dashboardTypes.AGGREGATED)
    const entityDashboardConfigs = state.dashboardConfigs.filter(config => config.type !== dashboardTypes.AGGREGATED)

    return (
        <div className="d-flex flex-column w-100 h-100">
            <Header
                state={state}
                dispatch={dispatch}
                isNewEntityStructureEnabled={
                    !isSqlFirst || useEntityDashboardsWhenSqlFirst ? isNewEntityStructureEnabled : selectedTabDashboard === "entity aggregated"
                }
            />
            <div className="mt-3 px-3 flex-grow-1 overflow-auto">
                <Loading colour="blue" isLoading={!allFetched || getDataFieldsRequest.isFetching}>
                    {isUnauthorised ? (
                        <h4 className="text-grey">You do not have permission to view this content.</h4>
                    ) : isSqlFirst && !useEntityDashboardsWhenSqlFirst ? (
                        <Tabs className="h-100">
                            <Tab
                                title={"Customer"}
                                isActive={selectedTabDashboard === undefined || selectedTabDashboard === "customer"}
                                onClick={customerTabClicked}
                                key="customer-dashboard-configs"
                            >
                                <EntityDashboardConfigs
                                    state={state}
                                    dashboardConfigs={entityDashboardConfigs}
                                    dispatch={dispatch}
                                    isNewEntityStructureEnabled={false}
                                />
                            </Tab>
                            <Tab
                                title={"Customer Aggregated"}
                                isActive={selectedTabDashboard === "customer aggregated"}
                                onClick={customerAggregatedTabClicked}
                                key="customer-aggregated-dashboard-configs"
                            >
                                <EntityDashboardConfigs
                                    state={state}
                                    dashboardConfigs={customerAggregatedDashboardConfigs}
                                    dispatch={dispatch}
                                    isNewEntityStructureEnabled={false}
                                />
                            </Tab>

                            <Tab
                                title="Entity Aggregated"
                                isActive={selectedTabDashboard === "entity aggregated"}
                                onClick={aggregatedTabClicked}
                                key="aggregated-dashboard-configs"
                            >
                                <EntityDashboardConfigs
                                    state={state}
                                    dashboardConfigs={entityAggregatedDashboardConfigs}
                                    dispatch={dispatch}
                                    isNewEntityStructureEnabled={isNewEntityStructureEnabled}
                                />
                            </Tab>
                        </Tabs>
                    ) : (
                        <Tabs className="h-100">
                            <Tab
                                title={(isNewEntityStructureEnabled && !isSqlFirst) || useEntityDashboardsWhenSqlFirst ? "Entity" : "Customer"}
                                isActive={true}
                                onClick={entityTabClicked}
                                key="entity-dashboard-configs"
                            >
                                <EntityDashboardConfigs
                                    state={state}
                                    dashboardConfigs={entityDashboardConfigs}
                                    dispatch={dispatch}
                                    isNewEntityStructureEnabled={isNewEntityStructureEnabled}
                                />
                            </Tab>
                            <Tab title="Aggregated" onClick={aggregatedTabClicked} key="aggregated-dashboard-configs">
                                <EntityDashboardConfigs
                                    state={state}
                                    dashboardConfigs={aggregatedDashboardConfigs}
                                    dispatch={dispatch}
                                    isNewEntityStructureEnabled={isNewEntityStructureEnabled}
                                />
                            </Tab>
                        </Tabs>
                    )}
                </Loading>
            </div>
        </div>
    )
}

export default DashboardConfigs
