import { Alert, GreyButton, TextArea, useApi, useClient, useUser, useConfig, Loading } from "invevo-react-components"
import moment from "moment"
import { useState } from "react"
import { actionTypes } from "../../reducers/actionTypes"

const AddComment = ({ resourceReference, resourceType, dispatch }) => {
    const [isSaving, setIsSaving] = useState(false)
    const [isValid, setIsValid] = useState(true)
    const [text, setText] = useState("")

    const api = useApi()
    const client = useClient()
    const currentUser = useUser()
    const config = useConfig()

    const onTextChange = (event) => {
        setText(event.target.value)
        setIsValid(true)
    }

    const onSave = () => {
        if (!text || text.length === 0 || text.length > 2000) {
            setIsValid(false)
            return
        }

        setIsSaving(true)
        const dto = {
            resourceReference,
            resourceType,
            text
        }

        api.post(`${config.DASHBOARD_API_URL}/api/${client}/comments`, dto)
            .then(() => {
                dispatch({ type: actionTypes.COMMENT_ADDED, text, createdBy: currentUser.username, createdDateTime: moment().toISOString() })
                setIsSaving(false)
                setText("")
            })
            .catch(error => console.error(error))
    }

    return (
        <Loading isLoading={!config.DASHBOARD_API_URL} colour="blue">
            <div className="d-flex flex-column p-3 border">
                <TextArea className="w-100" placeholder="Add a comment" value={text} onChange={onTextChange} />
                <div className="d-flex mb-3 ms-auto">
                    <GreyButton iconClasses="fal fa-comment-check" label="Submit" onClick={onSave} disabled={isSaving} />
                </div>
                {!isValid && <Alert text="Please provide a comment between 1 and 2000 characters" iconClasses="fal fa-info-circle" type="error" />}
            </div>
        </Loading>
    )
}

export default AddComment