import Toggle from "../../toggle/Toggle"
import HeaderDefinition from "./../types/HeaderDefinition"

type Props = {
    dataField: HeaderDefinition
    isEnabled: boolean
    onStateToggled: (field: HeaderDefinition) => void
}

const HeaderConfigFieldOption = ({ dataField, isEnabled, onStateToggled }: Props) => {
    const onToggleClicked = () => onStateToggled(dataField)

    return (
        <div className="d-flex align-items-center p-2">
            <Toggle toggleColour="blue" status={isEnabled} onStatusChanged={onToggleClicked} ariaLabel={`${dataField.value}-toggle`} />
            <span
                className="ms-2 text-truncate text-capitalize fw-light no-select"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                aria-label={`header-config-${dataField.value}-value`}
                role="listitem"
                title={dataField.label}
            >
                {dataField.label}
            </span>
        </div>
    )
}

export default HeaderConfigFieldOption
