export type FileImportConfigFieldTrimOption =
    | RemoveFirstNCharacters
    | RemoveLastNCharacters
    | RemoveString
    | RemoveStringFromStart
    | RemoveStringFromEnd
    | TakeFirstNCharacters
    | TakeLastNCharacters
    | TakeAfterLastString
    | TakeBeforeFirstString

export enum FileImportConfigFieldTrimOptionEnum {
    REMOVE_STRING = "REMOVE_STRING",
    REMOVE_FIRST_N_CHARACTERS = "REMOVE_FIRST_N_CHARACTERS",
    REMOVE_LAST_N_CHARACTERS = "REMOVE_LAST_N_CHARACTERS",
    REMOVE_STRING_FROM_START = "REMOVE_STRING_FROM_START",
    REMOVE_STRING_FROM_END = "REMOVE_STRING_FROM_END",
    TAKE_FIRST_N_CHARACTERS = "TAKE_FIRST_N_CHARACTERS",
    TAKE_LAST_N_CHARACTERS = "TAKE_LAST_N_CHARACTERS",
    TAKE_AFTER_LAST_STRING = "TAKE_AFTER_LAST_STRING",
    TAKE_BEFORE_FIRST_STRING = "TAKE_BEFORE_FIRST_STRING"
}

export type RemoveFirstNCharacters = {
    type: FileImportConfigFieldTrimOptionEnum.REMOVE_FIRST_N_CHARACTERS
    take: number
}

export type RemoveLastNCharacters = {
    type: FileImportConfigFieldTrimOptionEnum.REMOVE_LAST_N_CHARACTERS
    take: number
}

export type RemoveString = {
    type: FileImportConfigFieldTrimOptionEnum.REMOVE_STRING
    characters: string
}

export type RemoveStringFromStart = {
    type: FileImportConfigFieldTrimOptionEnum.REMOVE_STRING_FROM_START
    characters: string
}

export type RemoveStringFromEnd = {
    type: FileImportConfigFieldTrimOptionEnum.REMOVE_STRING_FROM_END
    characters: string
}

export type TakeFirstNCharacters = {
    type: FileImportConfigFieldTrimOptionEnum.TAKE_FIRST_N_CHARACTERS
    take: number
}

export type TakeLastNCharacters = {
    type: FileImportConfigFieldTrimOptionEnum.TAKE_LAST_N_CHARACTERS
    take: number
}

export type TakeAfterLastString = {
    type: FileImportConfigFieldTrimOptionEnum.TAKE_AFTER_LAST_STRING
    characters: string
}

export type TakeBeforeFirstString = {
    type: FileImportConfigFieldTrimOptionEnum.TAKE_BEFORE_FIRST_STRING
    characters: string
}
