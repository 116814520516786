import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { saveNote } from '../../api/functionApi';
import { resetMsg } from '../../actions/functionActions';
import { showToastSuccessMessage } from '../../api/toasterApi';
import { useClient, TextArea } from 'invevo-react-components';
import StandardButton from '../../../../library/buttons/StandardButton/StandardButton';

const NewAddNote = ({
  className,
  selectedAccount,
  saveNote,
  resetMsg,
  setRefresh,
  msg,
}) => {
  const client = useClient()
  const [value, setValue] = useState("")

  useEffect(() => {
    if (msg === 'saved') {
      showToastSuccessMessage('Updated successfully');
      resetMsg();
      setRefresh((refresh) => refresh + 1)
    }
  }, [msg, resetMsg, setRefresh]);

  const addNote = () => {
    if(!value) {
      return
    }

    const text = value;
    const keys = [];

    //Hint: NoteTypeId = 2 => Transaction; 1 => Customer; 6 => Virtual Parent
    let noteType = 1;
    keys.push(selectedAccount.miaAccountId);
    if (selectedAccount.miaAccountIsVirtualAccount) {
      noteType = 6;
    }

    const notes = {
      noteTypeId: noteType,
      noteText: text,
      noteTypeKeysId: keys,
      allTrSelected: false,
      followUpDate: null,
      ptpDate: null,
      statusId: null,
      subStatusId: null,
      contactId: null,
      GroupId: null
    };
    saveNote(client, notes);
    setValue('');
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  }

  return (
    <div className={`d-flex flex-column ${className} shadow border border-4 border-grey bg-white p-2`}>
      <span className='mb-2 text-grey fs-5'>Add new note</span>
      <TextArea className="me-2 w-100" placeholder="Enter note text" value={value} onChange={handleChange} />
      <StandardButton className="ms-auto align-self-start" iconClasses="far fa-save" label="Save" onClick={addNote} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    msg: state.functionReducer.msg,
    selectedAccount: state.currentSelectionReducer.selectedAccount
  };
};

export default connect(mapStateToProps, {
  saveNote,
  resetMsg
})(NewAddNote);
