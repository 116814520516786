import { actionTypes } from '../../../reducers/actionTypes'
import { Checkbox } from 'invevo-react-components'
import WidgetInput from '../forms/WidgetInput'
import classes from './InformationWidget.module.scss'
import { v4 as uuidv4 } from 'uuid'
import { useState } from 'react'
import { widgetThemeDropdownOptions, widgetThemes } from '../../../enums/widgetThemes'
import DataFieldDropdown from '../../../../../library/dropdowns/DataFieldDropdown'
import InformationFieldName from './InformationFieldName'
import InformationEntityFieldName from './InformationEntityFieldName'
import EntityDataFieldDropdown from '../../../../../library/dropdowns/EntityDataFieldDropdown'
import { DashboardConfigAction } from '../../../reducers/dashboardConfigsReducer'
import { InfoWidgetState } from '../../../types/DashboardConfigState'
import EntityDataField from '../../../../../routes/entityConfig/types/EntityDataField'
import DataField from '../../../../../types/DataField'
import Dropdown from '../../../../../library/dropdowns/Dropdown'
import DropdownOption from '../../../../../types/DropdownOptions'

type InformationWidgetProps = {
    className?: string
    state: { customerFields: DataField[] | EntityDataField[]
    widget: InfoWidgetState
    showValidationErrors: boolean
    isNewEntityStructureEnabled: boolean
    }
    dispatch: React.Dispatch<DashboardConfigAction>
}
const InformationWidget = ({ className, state, dispatch }: InformationWidgetProps) => {
    const { customerFields, widget, showValidationErrors, isNewEntityStructureEnabled } = state

    const [isAddingNewField, setIsAddingNewField] = useState(false)

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: actionTypes.INFO_WIDGET_NAME_CHANGED, widget, newName: event.target.value })
    }

    const onFieldNameChange = (field: DataField | EntityDataField, option: DataField | EntityDataField | undefined) => {
        dispatch({ type: actionTypes.INFO_WIDGET_FIELD_NAME_CHANGED, widget: widget, newField: option, previousField: field })
    }

    const onFieldRemoveClick = (field: DataField | EntityDataField) => {
        dispatch({ type: actionTypes.INFO_WIDGET_FIELD_NAME_REMOVED, widget, field: field })
    }

    const onAddNewFieldClick = () => {
        setIsAddingNewField(true)
    }

    const onNewFieldNameChange = (option: DataField | EntityDataField | undefined) => {
        if (!option) {
            return
        }

        setIsAddingNewField(false)
        dispatch({ 
            type: actionTypes.INFO_WIDGET_NEW_FIELD_ADDED,
            widget,
            newField: isNewEntityStructureEnabled ? { 
                id: uuidv4(), ...(option), isEditable: false
            } : { 
                id: uuidv4(), ...(option), isEditable: false } 
            }
        )
    }

    const onRemoveClick = () => {
        dispatch({ type: actionTypes.WIDGET_REMOVED, widget })
    }


    const onIsFieldLabelInlineClicked = () => {
        if (widget.isFieldLabelInline) {
            dispatch({ type: actionTypes.INFO_WIDGET_IS_FIELD_LABEL_INLINE_DISABLED, widget })
            return
        }
        dispatch({ type: actionTypes.INFO_WIDGET_IS_FIELD_LABEL_INLINE_ENABLED, widget })
    }

    const onIsFieldEditableClicked = (field: any) => {
        if (field.isEditable) {
            dispatch({ type: actionTypes.INFO_WIDGET_FIELD_EDITABLE_DISABLED, widget, fieldId: field.id })
            return
        }
        dispatch({ type: actionTypes.INFO_WIDGET_FIELD_EDITABLE_ENABLED, widget, fieldId: field.id })
    }

    const onThemeChange = (themeOption: DropdownOption<widgetThemes>) => dispatch({ type: actionTypes.INFO_WIDGET_THEME_CHANGED, widget, newTheme: themeOption.value })

    const onMoveFieldUp = (fieldIndex: number) => {
        dispatch({ type: actionTypes.INFO_WIDGET_FIELD_MOVED_UP, widget, fieldIndexToMove: fieldIndex })
     }

    const onMoveFieldDown = (fieldIndex: number) => {
        dispatch({ type: actionTypes.INFO_WIDGET_FIELD_MOVED_DOWN, widget, fieldIndexToMove: fieldIndex })
     }

    return (
        <div className={`${className ? className : ''} d-flex flex-column ${classes.widget} bg-white p-2 text-grey overflow-auto`}>
            <div className="d-flex align-items-center border-bottom pb-2">
                <i className="fal fa-info-circle me-2"></i>
                <WidgetInput className="flex-grow-1" value={widget.displayName} placeholder="Please enter name" onChange={onNameChange} isValid={!showValidationErrors || widget.displayName !== ""} />
                <i className="fal fa-times ms-3 me-1 pointer" onClick={onRemoveClick}></i>
            </div>
            <div className='d-flex mt-3 align-items-center px-2'>
                <Checkbox isChecked={widget.isFieldLabelInline} onClick={onIsFieldLabelInlineClicked} />
                <span className={`small ms-2 ${classes.label}`}>Show field label inline</span>
            </div>
            <div className='d-flex align-items-center justify-content-between my-2 px-2'>
                <span className='me-2 text-uppercase small'>Theme:</span>
                <Dropdown
                    ariaLabel="widgetThemeDropdown"
                    options={widgetThemeDropdownOptions}
                    selectedOption={widgetThemeDropdownOptions.find(o => o.value === widget.theme)}
                    onOptionSelected={onThemeChange}
                />
            </div>
            <div className="d-flex flex-column mt-3 px-2">
                <div className="d-flex flex-column">
                    {widget.fields.map((field, index) =>
                        isNewEntityStructureEnabled ? 
                            <InformationEntityFieldName
                                key={field.id || uuidv4()}
                                index={index}
                                isFirst={index === 0} 
                                isLast={index === widget.fields.length - 1} 
                                field={{ field: field, isEditable: field.isEditable }}
                                fields={customerFields as EntityDataField[]}
                                ariaLabel={field.fieldName ? `${field.fieldName}-info-widget-input` : 'editing-info-widget-input'}
                                onFieldNameChanged={onFieldNameChange}
                                onFieldRemoveClicked={onFieldRemoveClick}
                                onIsFieldEditableClicked={onIsFieldEditableClicked}
                                onMoveFieldUp={onMoveFieldUp}
                                onMoveFieldDown={onMoveFieldDown}
                            />
                            : <InformationFieldName
                                key={field.id}
                                index={index}
                                field={field}
                                customerFields={customerFields as DataField[]}
                                onFieldNameChanged={onFieldNameChange}
                                onFieldRemoveClicked={onFieldRemoveClick}
                                onIsFieldEditableClicked={onIsFieldEditableClicked}
                            /> 
                    )}
                    <div className="d-flex align-items-center">
                        <span className={`text-uppercase small mb-1 ${classes.label}`}>Add new field name</span>
                        <i className={`ms-auto fas fa-plus-circle pointer text-blue`} onClick={onAddNewFieldClick}></i>
                    </div>
                    {isAddingNewField && (
                        isNewEntityStructureEnabled
                            ? <EntityDataFieldDropdown
                                className="flex-grow-1"
                                options={customerFields as EntityDataField[]}
                                onOptionSelected={onNewFieldNameChange}
                                selectedOption={undefined}
                            />
                            : <DataFieldDropdown
                                className="flex-grow-1"
                                options={customerFields as DataField[]}
                                onOptionSelected={onNewFieldNameChange}
                                selectedOption={undefined}
                            />
                    )}
                </div>
            </div>
        </div>
    )
}

export default InformationWidget