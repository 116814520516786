import { useEffect, useState } from "react"
import StandardButton from "../../../../../library/buttons/StandardButton/StandardButton"
import FilterList from "../../../../../library/FilterList/FilterList"
import GenericFilter from "../../../../../library/FilterList/filterTypes/GenericFilter"
import { getDataFieldsFromEntityDataFields } from "../../../../../library/helpers/entityHelpers"
import EntityConfig from "../../../../../routes/entityConfig/types/EntityConfig"
import Lookup from "../../../../../types/Lookup"
import DataField from "../../../../../types/DataField"

type EntityWidgetFiltersProps = {
    chartTitle: string
    fields?: DataField[]
    filters: GenericFilter[]
    onFiltersUpdated: (filters: GenericFilter[]) => void
    onResetFilters: () => void
    entityConfig?: EntityConfig
    lookups: Lookup[]
}
const EntityWidgetFilters = ({ chartTitle, fields, filters, onFiltersUpdated, onResetFilters, entityConfig, lookups }: EntityWidgetFiltersProps) => {
    const [updatedFilters, setUpdatedFilters] = useState<GenericFilter[]>(filters)
    useEffect(() => {
        setUpdatedFilters(filters)
    }, [filters])
    const onSave = () => onFiltersUpdated(updatedFilters)
    return (
        <div className="w-100 h-100 d-flex flex-column gap-3 align-items-center">
            <span className="fs-4 text-center">
                Filters: {chartTitle} ({updatedFilters.length})
            </span>
            <div className="w-100 h-100 p-3 d-flex overflow-auto">
                <FilterList
                    fields={fields ? fields : getDataFieldsFromEntityDataFields(entityConfig?.fields ?? [])}
                    lookups={lookups}
                    appliedFilters={filters}
                    onFiltersApplied={setUpdatedFilters}
                    autoApply={true}
                    showRelativeDateOptions
                    showFieldIsOneOfOptions
                    showClearFiltersButton={false}
                />
            </div>
            <div className="w-100 d-flex flex-row justify-content-between align-items-center">
                <StandardButton iconClasses="fal fa-times" label="Reset" colour="red" onClick={onResetFilters} ariaLabel="reset-widget-filters" />
                <StandardButton iconClasses="fal fa-check" label="Save" colour="blue" onClick={onSave} ariaLabel="save-widget-filters" />
            </div>
        </div>
    )
}

export default EntityWidgetFilters
