import update from 'immutability-helper';

const init = {
  grids: {}
};

const gridCacheReducer = (state = init, action) => {
  switch (action.type) {
    case 'STORE_GRID_STATE':
      const gridStateUpdate = {
        gridState: action.gridState
      };

      if (action.lastResponse) {
        gridStateUpdate.lastResponse = action.lastResponse;
        gridStateUpdate.lastResponseDateTime = new Date();
      } else {
        gridStateUpdate.lastResponse = state.grids[action.gridKey]
          ? state.grids[action.gridKey].lastResponse
          : null;
        gridStateUpdate.lastResponseDateTime = state.grids[action.gridKey]
          ? state.grids[action.gridKey].lastResponseDateTime
          : null;
      }

      return update(state, {
        grids: {
          [action.gridKey]: {
            $set: gridStateUpdate
          }
        }
      });

    case 'RESET_GRID_STATE': {
      return update(state, {
        grids: {
          $unset: [action.gridKey]
        }
      });
    }

    default:
      return state;
  }
};

export default gridCacheReducer;
