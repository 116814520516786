import DropdownOption from "../../../../types/DropdownOptions"

export enum RoleTypeEnum {
    ADMIN = "ADMIN",
    STANDARD = "STANDARD",
    GUEST = "GUEST",
    CLIENT = "CLIENT"
}

export const RoleTypeDropdownOptions: DropdownOption<RoleTypeEnum>[] = [
    { value: RoleTypeEnum.ADMIN, label: "Admin" },
    { value: RoleTypeEnum.STANDARD, label: "Standard" },
    { value: RoleTypeEnum.GUEST, label: "Guest" },
    { value: RoleTypeEnum.CLIENT, label: "Client" }
]
