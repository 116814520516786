import DataField from "../../types/DataField"
import SortDirection from "../../types/enums/SortDirection"
import classes from "./ColumnHeading.module.scss"
import { useDrag, useDrop } from "react-dnd"
import { useRef, useState } from "react"

type ColumnHeadingProps = {
    field: DataField
    displayedFields: DataField[]
    selectedSortDirection: SortDirection | undefined
    onToggleSort: (field: DataField) => void
    onColumnOrderChanged: (fields: DataField[]) => void
}

const ColumnHeading = ({ field, displayedFields, selectedSortDirection, onToggleSort, onColumnOrderChanged }: ColumnHeadingProps) => {
    const [draggedOverStyle, setDraggedOverStyle] = useState("")

    const toggleSort = () => onToggleSort(field)

    const [{ isDragging }, dragRef] = useDrag({
        type: "field",
        item: () => field,
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    })

    const [{ isOver }, dropRef] = useDrop({
        accept: "field",
        drop: (draggedField: DataField) => {
            const updatedFields = [...displayedFields]
            updatedFields.splice(updatedFields.indexOf(field), 0, updatedFields.splice(updatedFields.indexOf(draggedField), 1)[0]!)
            onColumnOrderChanged(
                updatedFields.map(field => ({ ...field, ordinal: updatedFields.indexOf(field) })).sort((a, b) => (a.ordinal > b.ordinal ? 1 : 0))
            )
        },
        hover: (draggedField: DataField) => {
            const draggedFieldIndex = displayedFields.indexOf(draggedField)
            const currentFieldIndex = displayedFields.indexOf(field)

            if (draggedFieldIndex === currentFieldIndex) {
                setDraggedOverStyle("")
                return
            }

            if (draggedFieldIndex > currentFieldIndex) {
                setDraggedOverStyle(`${classes.borderLeft}`)
            } else if (draggedFieldIndex < currentFieldIndex) {
                setDraggedOverStyle(`${classes.borderRight}`)
            }

            return
        },
        collect: monitor => ({
            isOver: monitor.isOver()
        })
    })

    const ref = useRef<HTMLTableCellElement>(null)
    dragRef(ref)
    dropRef(ref)

    const iconClasses = (() => {
        if (selectedSortDirection === undefined) return `${classes.noSort} fas fa-sort`
        if (selectedSortDirection === SortDirection.DESC) return "fa-duotone fa-sort fa-swap-opacity"
        return "fa-duotone fa-sort"
    })()

    return (
        <th className={`ps-2 ${isOver ? draggedOverStyle : ""}`} ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <div className="d-flex align-items-center pointer no-select me-3 text-uppercase text-truncate fw-normal" onClick={toggleSort}>
                <i className={`fa fa-grip-dots-vertical me-2 ${classes.dragHandle}`}></i>
                {field.label}
                <i className={`${iconClasses} p-2`}></i>
            </div>
        </th>
    )
}

export default ColumnHeading
