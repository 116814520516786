import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLookup } from '../../api/lookupApi';
import StatefulSelect from '../common/statefulSelect';
import { useClient } from 'invevo-react-components';

const RequestReasonSelect = ({ id, onChange, getLookup, reasons }) => {
  const client = useClient();
  useEffect(() => {
    if (!reasons || reasons.length === 0)
      getLookup(client, 'lookup/ReasonsForLimitIncrease', 'reasonsLimitIncrease');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <StatefulSelect options={reasons} optionId={id} onChange={onChange} />;
};

RequestReasonSelect.propTypes = {
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  reasons: state.lookupReducer.reasonsLimitIncrease.results
    .filter((t) => t.IsInUse)
    .map((m) => ({ value: m.Id, label: m.Description }))
    .sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    })
});

export default connect(mapStateToProps, { getLookup })(RequestReasonSelect);
