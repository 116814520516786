import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { remove } from 'lodash';
import {
  showToastWarningMessage,
  showToastErrorMessage
} from '../../api/toasterApi.js';
import { selectDocument } from '../../actions/functionActions';
import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../common/grids/StatefulGrid';
import DocumentCard from './documentCard';
import { getApiUrl } from '../../constants.js';
import { useClient, useInvevoSession } from 'invevo-react-components';
import { downloadDocuments } from '../../api/documentApi';

const DocuemntsGrid = ({
  selectedAccount,
  downloadDocuments,
  selectDocument,
  filterByText,
  refresh = 0
}) => {
  const client = useClient()
  const [, setInvevoSession] = useInvevoSession()
  const gridRef = useRef(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [lastSelectIndex, setLastSelectIndex] = useState();
  const { miaAccountId, miaAccountIsVirtualAccount } = selectedAccount;

  const [additionalRequestPayload, setAdditionalRequestPayload] = useState({
    customerid: miaAccountId,
    isVirtualAccount: miaAccountIsVirtualAccount
  });

  useEffect(() => {
    setAdditionalRequestPayload({
      customerid: miaAccountId,
      isVirtualAccount: miaAccountIsVirtualAccount
    });
  }, [miaAccountId, miaAccountIsVirtualAccount]);

  const isSelectedRow = (dataItem) =>
    !!selectedItems.find((item) => item.Id === dataItem.Id);

  const onRowClick = (gridRowEvent) => {
    const dataItem = gridRowEvent.dataItem;
    const grid = gridRef.current.state.data;
    let newSelectedRowStatus = !isSelectedRow(dataItem);
    let items = [...selectedItems];

    let last = lastSelectIndex;
    const current = grid.findIndex((d) => d.Id === dataItem.Id);

    if (!gridRowEvent.nativeEvent.shiftKey) {
      last = current;
      setLastSelectIndex(last);
    }

    if (!gridRowEvent.nativeEvent.ctrlKey) {
      newSelectedRowStatus = true;
      items = [];
    }

    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      const gridDataItem = grid[i];
      const gridItemIsSelected = !!items.find(
        (item) => item.Id === gridDataItem.Id
      );

      if (gridItemIsSelected !== newSelectedRowStatus) {
        if (gridItemIsSelected) {
          remove(items, (item) => gridDataItem.Id === item.Id);
        } else {
          items.push({
            Id: gridDataItem.Id,
            DocumentName: gridDataItem.DocumentName,
            FileName: gridDataItem.FileName
          });
        }
      }
    }
    if (selectDocument) {
      const docs = items.map((doc) => ({
        id: doc.Id,
        fileName: doc.DocumentName
      }));
      selectDocument(docs);
      setInvevoSession({ selectedDocuments: docs})
    }    

    setSelectedItems(items);
  };

  const onDownloadDocuments = (gridRowEvent) => {
    const dataItem = gridRowEvent.dataItem;

    // take all selectedItems
    const downloadRows = [...selectedItems];

    // and clicked item if not already selected
    if (!downloadRows.find((item) => item.Id === dataItem.Id)) {
      downloadRows.push({
        Id: dataItem.Id,
        DocumentName: dataItem.DocumentName,
        FileName: dataItem.FileName
      });
    }

    const onStartDownlod = () =>
      showToastWarningMessage(
        'Download has started in background; please wait and follow indications on screen.'
      );

    const onError = (error) => {
      showToastErrorMessage(error);
    };

    downloadDocuments(client, downloadRows, onStartDownlod, onError);
  };

  const rowRender = (trElement) => {
    return React.cloneElement(
      trElement,
      { className: 'col-lg-4 col-6 top15' },
      trElement.props.children
    );
  };

  const resultCard = (e) => (
    <DocumentCard
      dataItem={e.dataItem}
      onClick={onRowClick}
      onDownloadClick={onDownloadDocuments}
    />
  );

  const textFilters =
    filterByText !== ''
      ? {
          logic: 'or',
          filters: [
            {
              field: 'FileName',
              operator: 'contains',
              value: filterByText,
              ignoreCase: true
            },
            {
              field: 'DocumentName',
              operator: 'contains',
              value: filterByText,
              ignoreCase: true
            }
          ]
        }
      : null;

  return (
    <div style={{ userSelect: 'none' }}>
      <StatefulGrid
        client={client}
        path={getApiUrl(client) + 'api/grid/documents'}
        sortable
        defaultField={{ field: 'DocumentDate', dir: 'desc' }}
        pageable={{
          pageSizes: [50, 100, 200],
          buttonCount: 5
        }}
        className={'hide-header single-cell scrollable-none row row-sm-gutters'}
        hideablePaging={true}
        onRowClick={onRowClick}
        additionalRequestPayload={additionalRequestPayload}
        isSelectedRow={isSelectedRow}
        refresh={refresh}
        ref={gridRef}
        rowRender={rowRender}
        filter={textFilters}
      >
        <GridColumn cell={resultCard} />
      </StatefulGrid>
    </div>
  );
};

DocuemntsGrid.propTypes = {
  refresh: PropTypes.number.isRequired,
  filterByText: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    selectedAccount: state.currentSelectionReducer.selectedAccount
  };
};

export default connect(mapStateToProps, {
  selectDocument,
  downloadDocuments
})(DocuemntsGrid);
