import HeaderDefinition from "./types/HeaderDefinition"
import { useEffect } from "react"
import { useGrid } from "./Grid"
import RowData from "./types/RowData"
import { getValueForHeader } from "./util"
import ExportToCsvButton from "./actions/ExportToCsvButton"
import HeaderConfigButton from "./actions/HeaderConfigButton"

type Props = {
    data: RowData[]
    defaultHeaders: HeaderDefinition[]
    children: JSX.Element
    onRowsUpdated?: (updates: { rowReference: string; header: HeaderDefinition; newValue: string }[]) => void
}

const DataTable = ({ data, defaultHeaders, children, onRowsUpdated = () => {} }: Props) => {
    const grid = useGrid()

    useEffect(() => {
        grid.setPotentialHeaders(defaultHeaders)
        grid.setDefaultHeaders(defaultHeaders)
        grid.setOnRowsUpdated(() => onRowsUpdated)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setData = grid.setData
    useEffect(() => {
        const filteredData =
            grid.quickSearchValue === ""
                ? data
                : data.filter(
                      row =>
                          Object.values(row.fields.textFields).some(field => field.toLowerCase().includes(grid.quickSearchValue.toLowerCase())) ||
                          Object.values(row.fields.numberFields).some(field => field.toString().includes(grid.quickSearchValue.toLowerCase())) ||
                          Object.values(row.fields.booleanFields).some(field => field.toString().includes(grid.quickSearchValue.toLowerCase()))
                  )

        const sortBais = grid.sorting?.direction === "ASC" ? 1 : -1
        const sortedData = grid.sorting
            ? [...filteredData].sort(
                  (a, b) => ((getValueForHeader(a, grid.sorting.header) ?? "") > (getValueForHeader(b, grid.sorting.header) ?? "") ? 1 : -1) * sortBais
              )
            : filteredData

        const lastItemOnPageCount = Math.min(grid.paging.pageIndex * grid.paging.pageSize + grid.paging.pageSize, data.length)
        const firstItemOnPageIndex = lastItemOnPageCount === 0 ? 0 : grid.paging.pageIndex * grid.paging.pageSize
        const paginatedData = sortedData.slice(firstItemOnPageIndex, lastItemOnPageCount)

        setData({
            rows: paginatedData,
            optimisticallyUpdatedRows: [],
            totalAcrossPages: data.length,
            isLoading: false
        })
    }, [data, grid.paging.pageIndex, grid.paging.pageSize, grid.quickSearchValue, grid.sorting, setData])

    const isGridBlue = grid.colour === "blue"
    return (
        <div className="position-relative d-flex flex-grow-1 overflow-auto h-100 w-100">
            {grid.globalActionsPortal(
                <div className="d-flex align-items-center gap-1">
                    <ExportToCsvButton />
                    <HeaderConfigButton />
                </div>
            )}
            {grid.shouldLoadData && grid.data.rows.length === 0 && (
                <div className={`top-0 bottom-0 start-0 end-0 ${isGridBlue ? "bg-blue" : "bg-grey"}`}>
                    <h5 className={`d-flex h-100 px-2 justify-content-center align-items-center ${isGridBlue ? "text-white" : "text-blue"}`}>
                        No results found matching your search criteria
                    </h5>
                </div>
            )}
            <div className="top-0 bottom-0 start-0 end-0 overflow-auto w-100">{children}</div>
        </div>
    )
}

export default DataTable
