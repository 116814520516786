import classes from "./NodeEditor.module.scss"
import { getBackgroundForType, getIconForType } from "../../types/FlowNodeType"
import Input from "../../../../library/Inputs/Input"
import Dropdown from "../../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../../types/DropdownOptions"
import ExportConfig from "../../types/ExportConfig"
import FiltersNodeForm from "./FiltersNodeForm"
import Lookup from "../../../../types/Lookup"
import ActionUpdateFieldsForm from "./ActionUpdateFieldsForm"
import EntityDataField from "../../../entityConfig/types/EntityDataField"
import CustomOutputsForm from "./CustomOutputsForm"
import FlowSculptorNode from "../../types/FlowSculptorNode"

type NodeEditorProps = {
    flowNode: FlowSculptorNode
    communicationTemplates: { reference: string; name: string }[]
    exportConfigs: ExportConfig[]
    dataFields: EntityDataField[]
    lookups: Lookup[]
    onFlowNodeUpdated: (flowNode: FlowSculptorNode) => void
}

const NodeEditor = ({ flowNode, communicationTemplates, exportConfigs, dataFields, lookups, onFlowNodeUpdated }: NodeEditorProps) => {
    const onDisplayNameChange = (value: string) => onFlowNodeUpdated({ ...flowNode, displayName: value })
    const onDescriptionChange = (value: string) => onFlowNodeUpdated({ ...flowNode, description: value })

    const communicationTemplateOptions = communicationTemplates.map(template => ({ value: template.reference, label: template.name }))
    const exportConfigOptions = exportConfigs.map(config => ({ value: config.reference, label: config.filename }))

    const getContentForType = () => {
        switch (flowNode.nodeType) {
            case "update fields action":
                return <ActionUpdateFieldsForm updateFieldsNode={flowNode} dataFields={dataFields} lookups={lookups} onUpdateNodeUpdated={onFlowNodeUpdated} />
            case "wait for condition":
            case "filters":
                return <FiltersNodeForm filterNode={flowNode} dataFields={dataFields} lookups={lookups} onFilterNodeUpdated={onFlowNodeUpdated} />
            case "send email action":
            case "send sms action":
            case "send post action":
                const onCommunicationTemplateSelected = (template: DropdownOption<string>) =>
                    onFlowNodeUpdated({ ...flowNode, templateReference: template.value })
                return (
                    <div className="d-flex align-items-center justify-content-between p-2">
                        <span>Template:</span>
                        <Dropdown
                            options={communicationTemplateOptions}
                            onOptionSelected={onCommunicationTemplateSelected}
                            selectedOption={communicationTemplateOptions.find(template => flowNode.templateReference === template.value)}
                        />
                    </div>
                )
            case "export action":
                const onExportConfigSelected = (config: DropdownOption<string>) => onFlowNodeUpdated({ ...flowNode, exportConfigReference: config.value })
                return (
                    <div className="d-flex align-items-center justify-content-between p-2">
                        <span>Export config:</span>
                        <Dropdown
                            options={exportConfigOptions}
                            onOptionSelected={onExportConfigSelected}
                            selectedOption={exportConfigOptions.find(config => flowNode.exportConfigReference === config.value)}
                        />
                    </div>
                )
            case "outbound ivr action":
            case "undefined process":
                return <CustomOutputsForm node={flowNode} onNodeUpdated={onFlowNodeUpdated} />
            default:
                return undefined
        }
    }

    const formContent = getContentForType()

    return (
        <div className="d-flex flex-column p-2 h-100 overflow-auto">
            <div className={`${getBackgroundForType(flowNode.nodeType)} ${classes.nodeTopBorder} rounded-top`} />
            <div className={`d-flex flex-column flex-grow-1 overflow-auto bg-grey rounded-bottom no-select ${classes.node}`}>
                <div className="d-flex align-items-center p-2">
                    <i className={`${getIconForType(flowNode.nodeType)} text-blue mx-1 fs-5`} />
                    <Input
                        className="ms-1 me-auto"
                        placeholder="Please enter a display name"
                        value={flowNode.displayName}
                        onChange={onDisplayNameChange}
                        ariaLabel="displayName"
                    />
                </div>
                <div className={`${classes.separator} d-flex bg-dark-gradient`} />
                <Input className="p-2" placeholder="No description set" value={flowNode.description} onChange={onDescriptionChange} ariaLabel="description" />
                {formContent && (
                    <>
                        <div className={`${classes.separator} d-flex bg-dark-gradient`} />
                        {formContent}
                    </>
                )}
            </div>
        </div>
    )
}

export default NodeEditor
