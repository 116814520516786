import { KeyboardEvent, useState } from "react"
import classes from "./MultiInputWithOptions.module.scss"
import useClickOutsideRef from "../../hooks/useClickOutsideRef"
import { padElementsWith } from "../helpers"
import DataPrimitive, { getBackgroundColourForDataType } from "../../types/DataPrimitive"

export type InputOption = {
    value: string
    label: string
    type?: DataPrimitive
}

type MultiInputWithOptionsProps = {
    selectedOptions: InputOption[]
    options: InputOption[]
    onOptionsChanged: (options: InputOption[]) => void
    disabled?: boolean
}

const MultiInputWithOptions = ({ selectedOptions, options, onOptionsChanged, disabled = false }: MultiInputWithOptionsProps) => {
    const [lastLabel, setLastLabel] = useState("")
    const [showingOptions, setShowingOptions] = useState(false)
    const ref = useClickOutsideRef(() => setShowingOptions(false))

    const availableOptions: InputOption[] = options.filter(o => !selectedOptions.some(so => so.value === o.value))

    const matchedOptions = availableOptions.filter(
        o => o.label.toLowerCase().includes(lastLabel.toLowerCase()) || o.label.toLowerCase().includes(lastLabel.toLowerCase())
    )

    const onValueAppended = (optionValue: string) => {
        const option = availableOptions.find(v => v.value === optionValue)
        if (option === undefined) return

        onOptionsChanged([...selectedOptions, option])
        setLastLabel("")
    }

    const onValueRemoved = (optionValue: string) => onOptionsChanged(selectedOptions.filter(v => v.value !== optionValue))

    const handleKeyPress = (event: KeyboardEvent) => {
        if (!showingOptions || ref === null || ref.current === null) return

        if (event.key === "Enter" && matchedOptions.length > 0) {
            onValueAppended(matchedOptions[0]!.value)
            return
        }

        if (event.key === "Backspace" && lastLabel === "" && selectedOptions.length > 0) {
            onValueRemoved(selectedOptions[selectedOptions.length - 1]!.value)
            return
        }
    }

    const getMatchedOptionValue = (option: string) => {
        const matchingIndex = option.toLowerCase().indexOf(lastLabel.toLowerCase())
        const firstPart = option.substring(0, matchingIndex)
        const matchingPart = option.substring(matchingIndex, matchingIndex + lastLabel.length)
        const lastPart = option.substring(matchingIndex + lastLabel.length, option.length)

        return (
            <span>
                {firstPart}
                <strong>{matchingPart}</strong>
                {lastPart}
            </span>
        )
    }

    const matchedOptionElements = matchedOptions.map(option => (
        <div key={option.value} className="d-flex p-2 pointer" onClick={() => onValueAppended(option.value)}>
            <span className="noselect">{getMatchedOptionValue(option.label)}</span>
        </div>
    ))

    return (
        <div ref={ref} className="d-flex flex-column bg-white">
            <div className={`d-flex align-items-center p-1 ${classes.parameters}`} aria-label="multi-input-selected-options">
                {selectedOptions.map(option => (
                    <div
                        key={option.value}
                        className={`d-flex align-items-center ps-2 pe-1 me-1 text-nowrap ${classes.tag}`}
                        style={option.type ? { backgroundColor: getBackgroundColourForDataType(option.type) } : { backgroundColor: "lightgrey" }}
                    >
                        <span className="mb-1 no-select">{option.label}</span>
                        {!disabled && (
                            <i
                                role="button"
                                aria-label="remove"
                                className={`ms-1 pointer ${classes.tagDelete} fas fa-times-circle`}
                                onClick={() => onValueRemoved(option.value)}
                            />
                        )}
                    </div>
                ))}
                <input
                    className={`d-flex p-0 ${classes.input}`}
                    value={lastLabel}
                    onChange={event => setLastLabel(event.target.value)}
                    onClick={() => setShowingOptions(true)}
                    onKeyUp={handleKeyPress}
                    disabled={disabled}
                    aria-label="multi-input"
                />
            </div>
            <div className="d-flex">
                {matchedOptions.length > 0 && showingOptions && (
                    <div className={`position-absolute ${classes.options} ${classes.optionsBelow} border text-grey shadow bg-grey`}>
                        {padElementsWith(matchedOptionElements, "border-top")}
                    </div>
                )}
            </div>
        </div>
    )
}

export default MultiInputWithOptions
