import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'
import { Collapsable, IconButton, Loading, useApi, useClient } from 'invevo-react-components';
import { actionTypes } from '../../reducers/actionTypes';
import CustomerGroupCard from './customerGroupCard/CustomerGroupCard';
import classes from './CustomerGroups.module.scss'

const CustomerGroups = ({
    state,
    dispatch
}) => {
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const api = useApi()
    const client = useClient()

    useEffect(() => {
        if (isFetching || hasFetched) {
            return
        }

        setIsFetching(true)
        api.get(`/api/${client}/customer-groups`)
            .then(response => {
                setHasFetched(true)
                dispatch({ type: actionTypes.CUSTOMER_GROUPS_RETRIEVED, data: response.data })
                setIsFetching(false)
            })
            .catch(error => console.log(error))
    }, [api, client, dispatch, hasFetched, isFetching])

    const onCreateClicked = () => {
        dispatch({
            type: actionTypes.CUSTOMER_GROUPS_SELECTED, data: {
                reference: uuidv4(),
                name: "",
                filters: [],
                creditFilters: [],
                isActive: true,
                isNew: true
            }
        })
    }

    const onCustomerGroupSelected = (group) => {
        dispatch({ type: actionTypes.CUSTOMER_GROUPS_SELECTED, data: group })
    }

    return (
        <div className={`d-flex flex-column py-3 ${classes.container}`}>
            <div className="d-flex w-100 pb-4">
                <h3 className="text-grey no-select px-3">Customer groups</h3>
                <IconButton className="me-3 ms-auto" iconClasses="fal fa-plus" onClick={onCreateClicked} />
            </div>

            <Loading isLoading={isFetching} colour="blue">
                <div className="d-flex flex-column px-3">
                    <Collapsable title="ACTIVE CUSTOMER GROUPS" open={true} id="activeCustomerGroups">
                        <div className="d-flex flex-column">
                            {state.customerGroups.filter(r => r.isActive).map(rg =>
                                <CustomerGroupCard key={rg.reference} state={rg} isSelected={rg.reference === state.selectedCustomerGroup.reference} onClick={() => onCustomerGroupSelected(rg)} />
                            )}
                            {state.customerGroups.filter(r => r.isActive).length === 0 &&
                                <span className="text-grey">You have no active Customer Groups</span>
                            }
                        </div>
                    </Collapsable>

                    <div className="d-flex mt-2">
                        <Collapsable title="ARCHIVED CUSTOMER GROUPS" id="archivedCustomerGroups">

                            <div className="d-flex flex-column">
                                {state.customerGroups.filter(r => !r.isActive).map(rg =>
                                    <CustomerGroupCard key={rg.reference} state={rg} isSelected={rg.reference === state.selectedCustomerGroup.reference} onClick={() => onCustomerGroupSelected(rg)} />
                                )}
                                {state.customerGroups.filter(r => !r.isActive).length === 0 &&
                                    <span className="text-grey">You have no archived Customer Groups</span>
                                }
                            </div>
                        </Collapsable>
                    </div>
                </div>
            </Loading>
        </div>
    )
}

export default CustomerGroups;
