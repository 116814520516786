import { Modal } from "react-bootstrap"
import Dropdown from "../../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../../types/DropdownOptions"
import React, { useEffect, useState } from "react"
import useApiQuery from "../../../../hooks/useApiQuery"
import { useEnvConfig } from "../../../../contexts/EnvironmentConfigContext"
import { Permissions, useUser } from "../../../../contexts/UserContext"
import ProgressButton from "../../../../library/buttons/ProgressButton/ProgressButton"
import WorkflowRule from "../../types/WorkflowRule"
import ScheduledWorkflowRule, { convertWorkflowRuleToDto } from "../../types/ScheduledWorkflowRule"
import { v4 as uuidv4 } from "uuid"
import DataUpdatedWorkflowRule, { convertDataUpdatedWorkflowRuleToDto } from "../../types/DataUpdatedWorkflowRule"
import DataField from "../../../../types/DataField"
import Input from "../../../../library/Inputs/Input"
import * as Sentry from "@sentry/react"
import Checkbox from "../../../../library/form/Checkbox"
import useClient from "../../../../hooks/useClient"

type DuplicateRuleModalProps = {
    show: boolean
    onHide: () => void
    selectedRule: WorkflowRule | undefined
    fields: DataField[]
}

const DuplicateRuleModal = ({ show, selectedRule, onHide, fields }: DuplicateRuleModalProps) => {
    const config = useEnvConfig()
    const client = useClient()
    const user = useUser()
    const clientOptions = user.accessibleClients
        .sort()
        .filter(c => c !== client)
        .map(client => ({
            value: client,
            label: client
        }))

    const [selectedOption, setSelectedOption] = useState<DropdownOption<string> | undefined>(undefined)
    const [validPermissionsForSelectedClient, setValidPermissionsForSelectedClient] = useState<boolean | undefined>(undefined)
    const [loadingPermissions, setLoadingPermissions] = useState(false)
    const [newName, setNewName] = useState(selectedRule?.name ?? "")
    const [archivedOnTarget, setArchivedOnTarget] = useState(true)

    const permissionsRequest = useApiQuery<Permissions>({
        url: `${config.PLATFORM_API_URL}/api/${selectedOption?.value}/client-user-permissions`,
        method: "GET",
        isExecutedAutomatically: false
    })

    const scheduledWorkflowRuleSaveRequest = useApiQuery({
        url: `${config.AUTOMATE_API_URL}/api/${selectedOption?.value}/workflow-rule`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    const dataUpdatedWorkflowRuleSaveRequest = useApiQuery({
        url: `${config.AUTOMATE_API_URL}/api/${selectedOption?.value}/data-updated-workflow-rule`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    const onOptionSelected = (option: DropdownOption<string>) => {
        setSelectedOption(option)
    }

    const onDuplicateClicked = () => {
        if (selectedRule === undefined || selectedOption === undefined) return Promise.reject()

        const duplicatedRule: WorkflowRule = {
            ...selectedRule,
            reference: uuidv4(),
            name: newName !== "" ? newName : selectedRule.name,
            archived: archivedOnTarget
        }

        if (selectedRule.type === "SCHEDULE") {
            return scheduledWorkflowRuleSaveRequest.execute(duplicatedRule.reference, convertWorkflowRuleToDto(duplicatedRule as ScheduledWorkflowRule, fields))
        } else {
            return dataUpdatedWorkflowRuleSaveRequest.execute(
                duplicatedRule.reference,
                convertDataUpdatedWorkflowRuleToDto(duplicatedRule as DataUpdatedWorkflowRule, fields)
            )
        }
    }

    const toggleArchivedOnTarget = () => {
        setArchivedOnTarget(!archivedOnTarget)
    }

    useEffect(() => {
        if (selectedOption === undefined) return

        setLoadingPermissions(true)
        permissionsRequest
            .execute()
            .then(response => {
                const validPermissions = response.data.hasAutomatePermission || response.data.hasAdminPermission
                setValidPermissionsForSelectedClient(validPermissions)
                setLoadingPermissions(false)
            })
            .catch(err => {
                setValidPermissionsForSelectedClient(false)
                Sentry.captureException(err)
            })
            .finally(() => {
                setLoadingPermissions(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption])

    useEffect(() => {
        setNewName(selectedRule?.name ?? "")
        setArchivedOnTarget(selectedRule?.archived ?? true)
    }, [selectedRule])

    const permissionText = () => {
        if (loadingPermissions) {
            return <div className="text-muted">Checking permissions...</div>
        }

        if (selectedOption === undefined) {
            return <div className="text-muted">Select a client</div>
        }

        if (validPermissionsForSelectedClient) {
            return <div className="text-success">Valid permissions</div>
        } else {
            return <div className="text-danger">You do not have permission to duplicate to this client</div>
        }
    }

    return (
        <Modal size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Duplicate workflow rule to another client</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                You are duplicating rule <strong>"{selectedRule?.name}"</strong> from <strong>{client}</strong> to:
                <div className={`d-flex flex-row align-items-center my-2`}>
                    <span className="text-uppercase m-2 no-select">Client</span>
                    <Dropdown
                        options={clientOptions}
                        onOptionSelected={onOptionSelected}
                        selectedOption={selectedOption}
                        ariaLabel={"duplicate-destination-client"}
                    />
                </div>
                <div className={`d-flex flex-row justify-content-between align-items-center my-2`}>
                    <span className="text-uppercase m-2 no-select">Name</span>
                    <Input value={newName} onChange={setNewName} ariaLabel={"duplicated-rule-name"} />
                </div>
                <Checkbox
                    className="small mt-3"
                    isChecked={archivedOnTarget}
                    label="Archived on target client"
                    onClick={toggleArchivedOnTarget}
                    ariaLabel="archived-on-target-checkbox"
                />
            </Modal.Body>
            <Modal.Footer>
                <div className="text-muted">{permissionText()}</div>
                <ProgressButton
                    className="w-25"
                    iconClasses="fal fa-save"
                    label="Duplicate"
                    colour="white"
                    succeededText="Duplicated successfully"
                    failedText="Failed to duplicate"
                    onClickWithPromise={onDuplicateClicked}
                    disabled={!validPermissionsForSelectedClient || loadingPermissions || selectedOption === undefined}
                />
            </Modal.Footer>
        </Modal>
    )
}

export default DuplicateRuleModal
