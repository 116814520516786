import { Loading, useClient, useInvevoSession } from "invevo-react-components";
import moment from "moment";
import 'moment/locale/es';
import 'moment/locale/en-gb';
import { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { checkCurrencyConfig, dispatchBootstrapMiaResults, loadInitialData } from "../legacy/api/lookupApi";
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import enGb from 'date-fns/locale/en-GB';
import { useMsal } from "@azure/msal-react"
import { externalLogin } from "../legacy/api/loginApi";
import '../legacy/styles/app.scss';
import { IntlProvider, load } from '@progress/kendo-react-intl';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import gbNumbers from 'cldr-numbers-full/main/en-GB/numbers.json';
import gbLocalCurrency from 'cldr-numbers-full/main/en-GB/currencies.json';
import gbCaGregorian from 'cldr-dates-full/main/en-GB/ca-gregorian.json';
import gbDateFields from 'cldr-dates-full/main/en-GB/dateFields.json';

import usNumbers from 'cldr-numbers-full/main/en/numbers.json';
import usLocalCurrency from 'cldr-numbers-full/main/en/currencies.json';
import usCaGregorian from 'cldr-dates-full/main/en/ca-gregorian.json';
import usDateFields from 'cldr-dates-full/main/en/dateFields.json';

import deNumbers from 'cldr-numbers-full/main/de/numbers.json';
import deLocalCurrency from 'cldr-numbers-full/main/de/currencies.json';
import deCaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
import deDateFields from 'cldr-dates-full/main/de/dateFields.json';

import nlNumbers from 'cldr-numbers-full/main/nl/numbers.json';
import nlLocalCurrency from 'cldr-numbers-full/main/nl/currencies.json';
import nlCaGregorian from 'cldr-dates-full/main/nl/ca-gregorian.json';
import nlDateFields from 'cldr-dates-full/main/nl/dateFields.json';

import nlBeNumbers from 'cldr-numbers-full/main/nl-BE/numbers.json';
import nlBeLocalCurrency from 'cldr-numbers-full/main/nl-BE/currencies.json';
import nlBeCaGregorian from 'cldr-dates-full/main/nl-BE/ca-gregorian.json';
import nlBeDateFields from 'cldr-dates-full/main/nl-BE/dateFields.json';

import frNumbers from 'cldr-numbers-full/main/fr/numbers.json';
import frLocalCurrency from 'cldr-numbers-full/main/fr/currencies.json';
import frCaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import frDateFields from 'cldr-dates-full/main/fr/dateFields.json';

import frBeNumbers from 'cldr-numbers-full/main/fr-BE/numbers.json';
import frBeLocalCurrency from 'cldr-numbers-full/main/fr-BE/currencies.json';
import frBeCaGregorian from 'cldr-dates-full/main/fr-BE/ca-gregorian.json';
import frBeDateFields from 'cldr-dates-full/main/fr-BE/dateFields.json';

import itNumbers from 'cldr-numbers-full/main/it/numbers.json';
import itLocalCurrency from 'cldr-numbers-full/main/it/currencies.json';
import itCaGregorian from 'cldr-dates-full/main/it/ca-gregorian.json';
import itDateFields from 'cldr-dates-full/main/it/dateFields.json';

import esNumbers from 'cldr-numbers-full/main/es/numbers.json';
import esLocalCurrency from 'cldr-numbers-full/main/es/currencies.json';
import esCaGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import esDateFields from 'cldr-dates-full/main/es/dateFields.json';

import trNumbers from 'cldr-numbers-full/main/tr/numbers.json';
import trLocalCurrency from 'cldr-numbers-full/main/tr/currencies.json';
import trCaGregorian from 'cldr-dates-full/main/tr/ca-gregorian.json';
import trDateFields from 'cldr-dates-full/main/tr/dateFields.json';

import plNumbers from 'cldr-numbers-full/main/pl/numbers.json';
import plLocalCurrency from 'cldr-numbers-full/main/pl/currencies.json';
import plCaGregorian from 'cldr-dates-full/main/pl/ca-gregorian.json';
import plDateFields from 'cldr-dates-full/main/pl/dateFields.json';

import zhNumbers from 'cldr-numbers-full/main/zh/numbers.json';
import zhLocalCurrency from 'cldr-numbers-full/main/zh/currencies.json';
import zhCaGregorian from 'cldr-dates-full/main/zh/ca-gregorian.json';
import zhDateFields from 'cldr-dates-full/main/zh/dateFields.json';

import csNumbers from 'cldr-numbers-full/main/cs/numbers.json';
import csLocalCurrency from 'cldr-numbers-full/main/cs/currencies.json';
import csCaGregorian from 'cldr-dates-full/main/cs/ca-gregorian.json';
import csDateFields from 'cldr-dates-full/main/cs/dateFields.json';

import ruNumbers from 'cldr-numbers-full/main/ru/numbers.json';
import ruLocalCurrency from 'cldr-numbers-full/main/ru/currencies.json';
import ruCaGregorian from 'cldr-dates-full/main/ru/ca-gregorian.json';
import ruDateFields from 'cldr-dates-full/main/ru/dateFields.json';

import heNumbers from 'cldr-numbers-full/main/he/numbers.json';
import heLocalCurrency from 'cldr-numbers-full/main/he/currencies.json';
import heCaGregorian from 'cldr-dates-full/main/he/ca-gregorian.json';
import heDateFields from 'cldr-dates-full/main/he/dateFields.json';

import nbNumbers from 'cldr-numbers-full/main/nb/numbers.json';
import nbLocalCurrency from 'cldr-numbers-full/main/nb/currencies.json';
import nbCaGregorian from 'cldr-dates-full/main/nb/ca-gregorian.json';
import nbDateFields from 'cldr-dates-full/main/nb/dateFields.json';
import { WorkspaceLayoutProvider } from "../legacy/contexts/workspaceLayoutContext";
import { RepaymentPlanProvider } from "../legacy/contexts/repaymentPlanContext";

load(
    likelySubtags,
    currencyData,
    weekData,

    gbNumbers,
    gbLocalCurrency,
    gbCaGregorian,
    gbDateFields,

    usNumbers,
    usLocalCurrency,
    usCaGregorian,
    usDateFields,

    deNumbers,
    deLocalCurrency,
    deCaGregorian,
    deDateFields,

    nlNumbers,
    nlLocalCurrency,
    nlCaGregorian,
    nlDateFields,

    nlBeNumbers,
    nlBeLocalCurrency,
    nlBeCaGregorian,
    nlBeDateFields,

    frNumbers,
    frLocalCurrency,
    frCaGregorian,
    frDateFields,

    frBeNumbers,
    frBeLocalCurrency,
    frBeCaGregorian,
    frBeDateFields,

    itNumbers,
    itLocalCurrency,
    itCaGregorian,
    itDateFields,

    esNumbers,
    esLocalCurrency,
    esCaGregorian,
    esDateFields,

    trNumbers,
    trLocalCurrency,
    trCaGregorian,
    trDateFields,

    plNumbers,
    plLocalCurrency,
    plCaGregorian,
    plDateFields,

    zhNumbers,
    zhLocalCurrency,
    zhCaGregorian,
    zhDateFields,

    csNumbers,
    csLocalCurrency,
    csCaGregorian,
    csDateFields,

    ruNumbers,
    ruLocalCurrency,
    ruCaGregorian,
    ruDateFields,

    heNumbers,
    heLocalCurrency,
    heCaGregorian,
    heDateFields,

    nbNumbers,
    nbLocalCurrency,
    nbCaGregorian,
    nbDateFields
);

registerLocale('es', es);
registerLocale('en-gb', enGb);

const LegacyLoader = ({
    children,
    userLanguageCulture,
    userLanguageCultureFetched,
    externalLogin,
    isAuthenticated
}) => {
    const dispatch = useDispatch();

    const [invevoSession, setInvevoSession] = useInvevoSession()

    const [cultureConfigured, setCultureConfigured] = useState(false);
    const [bootstrapping, setBootstrapping] = useState(false);
    const [bootstrapped, setBootstraped] = useState(false);

    const client = useClient()

    const { instance, accounts } = useMsal()

    const getToken = useCallback(async () => {
        const accessTokenRequest = {
            scopes: [],
            account: accounts[0]
        }
        return await instance.acquireTokenSilent(accessTokenRequest)
    }, [accounts, instance])

    useEffect(() => {
        if (!client || isAuthenticated) {
            return
        }

        getToken().then(tokenResponse => {
            externalLogin(client, 'azureSso', {
                tokenId: tokenResponse.idToken,
            });
        })

    }, [client, externalLogin, getToken, isAuthenticated])

    const intialLoad = useCallback(() => {
        if (!client || !isAuthenticated) {
            return
        }

        if (!bootstrapped && !bootstrapping) {
            if (!invevoSession.bootstrapmia) {
                setBootstrapping(true)
                dispatch(loadInitialData(client, json => {
                    setBootstraped(true)
                    setBootstrapping(false)
                    setInvevoSession({ bootstrapmia: json })
                }));
            } else {
                dispatchBootstrapMiaResults(dispatch, invevoSession.bootstrapmia)
                setBootstraped(true)
            }
        }

        dispatch(() => checkCurrencyConfig(client));
    }, [bootstrapped, bootstrapping, client, dispatch, invevoSession.bootstrapmia, isAuthenticated, setInvevoSession]);

    useEffect(() => {
        intialLoad();
    }, [intialLoad]);

    useEffect(() => {
        moment.locale(userLanguageCulture.toLowerCase());
        setDefaultLocale(userLanguageCulture.toLowerCase());
        setCultureConfigured(true);
    }, [userLanguageCulture, userLanguageCultureFetched]);

    const canRender = cultureConfigured && bootstrapped;

    return (
        <IntlProvider locale={userLanguageCulture}>
            <RepaymentPlanProvider>
                <DndProvider backend={HTML5Backend}>
                    <WorkspaceLayoutProvider>
                        <div className="d-flex w-100 h-100">
                            <Loading isLoading={!canRender} colour="blue">
                                {children}
                            </Loading>
                        </div>
                    </WorkspaceLayoutProvider>
                </DndProvider>
            </RepaymentPlanProvider>
        </IntlProvider>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.authReducer.isAuthenticated,
    userLanguageCulture:
        state.lookupReducer.userLanguageCulture.results.length > 0
            ? state.lookupReducer.userLanguageCulture.results
            : 'en-GB',
    userLanguageCultureFetched: state.lookupReducer.userLanguageCulture.fetched
});

export default connect(mapStateToProps, { externalLogin })(LegacyLoader);