import { useClient, useConfig } from "invevo-react-components"
import { useState } from "react"
import useApiQuery from "../../../../hooks/useApiQuery"
import BlueButton from "../../../../library/buttons/BlueButton/BlueButton"
import GreyButton from "../../../../library/buttons/GreyButton/GreyButton"
import ProgressButton from "../../../../library/buttons/ProgressButton/ProgressButton"
import Checkbox from "../../../../library/form/Checkbox"
import Input from "../../../../library/Inputs/Input"
import Role from "../../roles/types/Role"
import { UserRole } from "../types/UserRole"
import { v4 as uuidv4 } from "uuid"
import classes from "./ClientUserForm.module.scss"
import ClientUserRoleConfig from "./ClientUserRoleConfig"
import { ClientUserDto } from "../types/ClientUserResultsDto"
import { ClientUsersAction, ClientUsersActionEnum } from "../reducers/ClientUserReducer"
import { mapToDataRow } from "../helpers"
import FiltersDto from "../../../../library/FilterList/FiltersDto"
import DataField from "../../../../types/DataField"
import Lookup from "../../../../types/Lookup"
import EntityConfig from "../../../entityConfig/types/EntityConfig"

type ClientUserFormProps = {
    toggleIsShown: () => void
    userData?: ClientUserDto
    roles: Role[]
    isEditing: boolean
    dispatch: React.Dispatch<ClientUsersAction>
    customerDataFields: DataField[]
    lookups: Lookup[]
    entityConfigs: EntityConfig[]
}

const ClientUserForm = ({ toggleIsShown, userData, roles, isEditing, dispatch, customerDataFields, lookups, entityConfigs }: ClientUserFormProps) => {
    const client = useClient()
    const config = useConfig()

    const [username, setUsername] = useState<string>(userData?.username ?? "")
    const [displayName, setDisplayName] = useState<string>(userData?.displayName ?? "")
    const [emailAddress, setEmailAddress] = useState<string>(userData?.emailAddress ?? "")
    const [isActive, setIsActive] = useState<boolean>(userData?.isActive ?? false)
    const [userRoles, setUserRoles] = useState<UserRole[]>(
        userData?.roles.map(userRole => ({
            id: userRole.roleReference,
            role: roles.find(role => role.reference === userRole.roleReference),
            filters: userRole.filters,
            entityFilters: userRole.entityFilters ?? []
        })) ?? []
    )

    const toggleIsActive = () => setIsActive(currentIsActive => !currentIsActive)

    const saveClientUserRequest = useApiQuery({
        url: `${config.USER_ROLES_API_URL}/api/${client}/client-user`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    const saveClientUser = () =>
        saveClientUserRequest
            .execute(username, {
                displayName: displayName,
                emailAddress: emailAddress,
                isActive: isActive,
                roles: userRoles.map(userRole => ({
                    roleReference: userRole.role?.reference,
                    filters: userRole.filters,
                    entityFilters: userRole.entityFilters
                }))
            })
            .then(() => {
                if (isEditing) {
                    const updatedClientUser: ClientUserDto = {
                        username: username,
                        displayName: displayName,
                        emailAddress: emailAddress,
                        isActive: isActive,
                        roles: userRoles.map(userRole => ({
                            roleReference: userRole.role?.reference as string,
                            filters: userRole.filters as FiltersDto,
                            entityFilters: userRole.entityFilters
                        }))
                    }

                    dispatch({ type: ClientUsersActionEnum.CLIENT_USER_UPDATED, clientUserDataRow: mapToDataRow(updatedClientUser, roles) })
                }
            })

    const addUserRole = () => setUserRoles([...userRoles, { id: uuidv4(), role: undefined, filters: {}, entityFilters: [] }])

    const onUserRoleUpdated = (userRole: UserRole) =>
        setUserRoles(originalUserRoles => originalUserRoles.map(originalUserRole => (originalUserRole.id === userRole.id ? userRole : originalUserRole)))

    const onUserRoleRemoved = (userRole: UserRole) =>
        setUserRoles(originalUserRoles => [...originalUserRoles.filter(originalUserRole => originalUserRole.id !== userRole.id)])

    return (
        <div className={`d-flex flex-column bg-blue overflow-hidden p-3 h-100 ${classes.container}`} role="form" aria-label="add-user">
            <div className="d-flex align-items-center text-white mb-3">
                <span className="fs-3  fw-lighter">{isEditing ? "Edit" : "Add"} User</span>
                <div className={`d-flex rounded ms-auto pointer ${classes.closeButton}`} onClick={toggleIsShown} role="button" aria-label="close-add-user-form">
                    <i className="fa-regular fa-x m-auto"></i>
                </div>
            </div>

            <div className="pe-3 h-100 overflow-auto">
                <div className="d-flex flex-column mb-4">
                    <div className="d-flex">
                        <span className="text-uppercase mb-1 ms-1 text-white no-select">Username</span>
                        <span className="text-light-grey ms-auto fs-6">* Required</span>
                    </div>
                    <Input
                        placeholder="Please enter a username"
                        value={username}
                        onChange={setUsername}
                        disabled={saveClientUserRequest.isFetching || isEditing}
                        ariaLabel="username"
                    />
                </div>

                <div className="d-flex flex-column mb-4">
                    <div className="d-flex">
                        <span className="text-uppercase mb-1 ms-1 text-white no-select">Display name</span>
                        <span className="text-light-grey ms-auto fs-6">* Required</span>
                    </div>
                    <Input
                        placeholder="Please enter a display name"
                        value={displayName}
                        onChange={setDisplayName}
                        disabled={saveClientUserRequest.isFetching}
                        ariaLabel="display-name"
                    />
                </div>

                <div className="d-flex mb-4">
                    <Checkbox
                        className="ms-1"
                        isChecked={isActive}
                        label="Is active"
                        onClick={toggleIsActive}
                        colour="blue"
                        checkboxClasses="bg-white"
                        positionLabelLeft={true}
                        labelColour="white"
                        disabled={saveClientUserRequest.isFetching}
                    />
                </div>

                <div className="d-flex flex-column">
                    <span className="text-uppercase mb-1 ms-1 text-white no-select">Email address</span>
                    <Input
                        placeholder="Please enter an email address"
                        value={emailAddress}
                        onChange={setEmailAddress}
                        disabled={saveClientUserRequest.isFetching}
                        ariaLabel="email-address"
                    />
                </div>

                <hr className="text-white my-4" />

                <div className="d-flex flex-column">
                    <div className="d-flex mb-3">
                        <span className="text-uppercase mb-1 ms-1 text-white no-select my-auto">User Roles</span>
                        <BlueButton className="text-white ms-auto" iconClasses="fa fa-plus" label="Add role" onClick={addUserRole} ariaLabel="add-role" />
                    </div>
                    {userRoles.map(userRole => (
                        <div className="mb-2" key={userRole.id}>
                            <ClientUserRoleConfig
                                roles={roles}
                                userRoles={userRoles}
                                userRole={userRole}
                                onUserRoleUpdated={onUserRoleUpdated}
                                onRoleRemoved={onUserRoleRemoved}
                                disabled={saveClientUserRequest.isFetching}
                                customerDataFields={customerDataFields}
                                lookups={lookups}
                                entityConfigs={entityConfigs}
                            />
                        </div>
                    ))}
                </div>

                <hr className="text-white mt-4" />
            </div>
            <div className="d-flex justify-content-between pt-4">
                <GreyButton iconClasses="fal fa-times" label="Cancel" onClick={toggleIsShown} disabled={saveClientUserRequest.isFetching} />
                <ProgressButton
                    iconClasses="fal fa-save"
                    label="Save user"
                    colour="grey"
                    succeededText="Saved!"
                    failedText="Failed to save"
                    onClickWithPromise={saveClientUser}
                    disabled={username === "" || displayName === ""}
                />
            </div>
        </div>
    )
}

export default ClientUserForm
