import { useState, useEffect } from "react"
import { Loading, useApi, useClient, useConfig, useInvevoSession } from "invevo-react-components"
import { useCustomer } from "../../../contexts/CustomerContext"
import TotalProcessing from "./TotalProcessing"

const PaymentProvider = ({ charges, paymentCard, invoices }) => {
    const [isFetching, setIsFetching] = useState(false)
    const [paymentProviderDetails, setPaymentProviderDetails] = useState()
    const [paymentProvider, setPaymentProvider] = useState(<h3 className="mt-3 font-weight-bold">No valid payment provider configured. Please contact support</h3>)
    const api = useApi()
    const client = useClient()
    const config = useConfig()
    const [customer] = useCustomer()
    const [, setInvevoSession] = useInvevoSession()

    useEffect(() => {
        setInvevoSession({
            reference: customer.reference,
            sqlId: customer.sqlId,
            isVirtualAccount: customer.isVirtualAccount
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (paymentProviderDetails || !config.PAY_API_URL) {
            return
        }

        const content = {
            charges: charges,
            contactDetails: {},
            creditCardId: paymentCard.cardId,
            currency: paymentCard.currencyCode,
            customerId: customer.sqlId,
            isVirtualAccount: customer.isVirtualAccount ? customer.isVirtualAccount : false,
            languageId: 1,
            partial: isPartialPayment(invoices),
            paymentProviderId: paymentCard.paymentProviderId,
            subTotal: invoices.map(i => i.amountToPay).reduce((total, i) => total + i),
            total: invoices.map(i => i.amountToPay).reduce((total, i) => total + i) + charges,
            token: "",
            transactions: invoices.map(i => ({ amount: i.amountToPay, transactionId: i.id, transactionDate: i.transactionDate, invoiceReference: i.transactionRef })),
            redirect: `${window.location.origin}/home`
        }

        setIsFetching(true)
        api.post(`${config.PAY_API_URL}/api/${client}/legacy-encode-for-payment-new-nav`, content).then(response => {
            setPaymentProviderDetails(response.data)
            setIsFetching(false)
        }, error => { throw error })
    }, [api, charges, client, config.PAY_API_URL, customer.sqlId, invoices, customer.isVirtualAccount, paymentCard.cardId, paymentCard.currencyCode, paymentCard.paymentProviderId, paymentProviderDetails])

    useEffect(() => {
        if (!paymentProviderDetails) {
            return
        }

        switch (paymentProviderDetails.providerId) {
            case paymentProviders.totalProcessing:
                setPaymentProvider(
                    <TotalProcessing 
                        brand={paymentCard.brand} 
                        checkoutId={paymentProviderDetails.checkoutId} 
                        providerUrl={paymentProviderDetails.url} 
                        redirectUrl={paymentProviderDetails.redirectUrl}
                        languageCode="en"
                    />
                )
                break
            default:
                setPaymentProvider(<h3 className="mt-3 font-weight-bold">No valid payment provider configured. Please contact support</h3>)
        }
    }, [paymentCard.brand, paymentProviderDetails])

    return (
        <div className="text-white flex-grow-1 overflow-auto p-4">
            <Loading isLoading={isFetching} colour="white">
                <div className="mt-4">
                    {
                        paymentProvider
                    }
                </div>
            </Loading>
        </div>
    )
}

const paymentProviders = {
    dataCash: 1,
    sagePay: 2,
    cybersource: 3,
    totalProcessing: 4
};

const isPartialPayment = (invoices) => {
    const totalAmount = invoices.map(i => i.amount).reduce((total, i) => total + i)
    const totalAmountToPay = invoices.map(i => i.amountToPay).reduce((total, i) => total + i)
    return totalAmountToPay < totalAmount
}

export default PaymentProvider