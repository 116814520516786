import { useState } from "react"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import FilterList from "../../../../library/FilterList/FilterList"
import FiltersDto, { convertFromArrayToDto, convertFromDtoToArray } from "../../../../library/FilterList/FiltersDto"
import GenericFilter from "../../../../library/FilterList/filterTypes/GenericFilter"
import DataField from "../../../../types/DataField"
import DataPrimitive from "../../../../types/DataPrimitive"
import FieldType from "../../../../types/enums/FieldType"
import Lookup from "../../../../types/Lookup"
import GenericAction from "../../types/actions/GenericAction"
import LegacySmsTemplate from "../../types/LegacySmsTemplate"
import QuickFilter from "../../types/QuickFilter"
import WorkflowRun from "../../types/WorkflowRunDto"
import ActionInfo from "../actionInfo/ActionInfo"
import CustomerEvaluationsGrid from "./CustomerEvaluationsGrid"
import GridHeader from "./GridHeader"
import QuickFilters from "./QuickFilters"
import classes from "./WorkflowRunDetails.module.scss"

type WorkflowRunDetailsProps = {
    fields: DataField[]
    lookups: Lookup[]
    smsTemplates: LegacySmsTemplate[]
    workflowRun: WorkflowRun
    action: GenericAction | undefined
    defaultAdvancedFilters: GenericFilter[]
    onGoBackClicked: () => void
}

const WorkflowRunDetails = ({ fields, lookups, smsTemplates, workflowRun, action, defaultAdvancedFilters, onGoBackClicked }: WorkflowRunDetailsProps) => {
    const [isGridLoading, setIsGridLoading] = useState(false)

    const [showEvaluationFilters, setShowEvaluationFilters] = useState(false)

    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
    const [advancedFilters, setAdvancedFilters] = useState<GenericFilter[]>(defaultAdvancedFilters)

    const [showQuickFilters, setShowQuickFilters] = useState(true)
    const [quickFilters, setQuickFilters] = useState<QuickFilter[]>([])

    const onToggleShowEvaluationFilters = () => {
        setShowAdvancedFilters(false)
        setShowEvaluationFilters(!showEvaluationFilters)
    }
    const onToggleShowAdvancedFilters = () => {
        setShowEvaluationFilters(false)
        setShowAdvancedFilters(!showAdvancedFilters)
    }
    const onToggleShowQuickFilters = () => setShowQuickFilters(!showQuickFilters)

    const allFields = [...fields, ...evaluationFields, ...(action === undefined ? [] : actionFields)]

    const runFilters = convertFromDtoToArray(workflowRun.rule.baseFilters, fields, lookups)

    const renderHeader = () => (
        <GridHeader
            lookups={lookups}
            isLoading={isGridLoading}
            quickFilters={quickFilters}
            onQuickFiltersUpdated={setQuickFilters}
            showingQuickFilters={showQuickFilters}
            onQuickFiltersToggled={onToggleShowQuickFilters}
        />
    )

    const filtersDto = combineFilters(advancedFilters, quickFilters, workflowRun.runId)

    return (
        <div className="d-flex flex-grow-1 h-100 overflow-auto">
            <div className="d-flex flex-column h-100 w-100 overflow-auto p-3">
                <div className="d-flex">
                    <StandardButton
                        className={showAdvancedFilters || showEvaluationFilters ? "mb-2" : ""}
                        iconClasses="far fa-chevron-double-left"
                        label="Back to all runs"
                        onClick={onGoBackClicked}
                    />
                    {action && (
                        <div
                            className={`d-flex align-items-center ms-2 me-2 px-2 rounded bg-white 
                            ${showAdvancedFilters || showEvaluationFilters ? "mb-2" : ""}`}
                        >
                            <span className="fs-5 text-grey me-2">Action:</span>
                            <ActionInfo action={action} smsTemplates={smsTemplates} />
                        </div>
                    )}
                    <div className="d-flex ms-auto">
                        <div
                            role="button"
                            className={`position-relative d-flex align-items-center bg-grey text-grey px-2 bg-white no-select me-2
                            ${showAdvancedFilters ? "mb-2" : ""} 
                            ${showEvaluationFilters ? "rounded-top pb-2" : "rounded"} `}
                            onClick={onToggleShowEvaluationFilters}
                        >
                            <i className="text-blue fal fa-filter" />
                            <span className="fs-4 mx-2">Evaluation Filters</span>
                            {runFilters.length > 0 && (
                                <span className={`position-absolute text-white text-center fs-5 rounded-circle bg-blue ${classes.filterCount}`}>
                                    {runFilters.length}
                                </span>
                            )}
                        </div>
                        <div
                            role="button"
                            className={`position-relative d-flex align-items-center bg-grey text-grey px-2 bg-white no-select 
                            ${showEvaluationFilters ? "mb-2" : ""} 
                            ${showAdvancedFilters ? "rounded-top pb-2" : "rounded"} `}
                            onClick={onToggleShowAdvancedFilters}
                        >
                            <i className="text-blue fal fa-filter" />
                            <span className="fs-4 mx-2">Advanced Filters</span>
                            {advancedFilters.length > 0 && (
                                <span className={`position-absolute text-white text-center fs-5 rounded-circle bg-blue ${classes.filterCount}`}>
                                    {advancedFilters.length}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                {showEvaluationFilters && (
                    <div className={`d-flex bg-white rounded p-3`} aria-label="evaluation-filters">
                        <FilterList fields={fields} lookups={lookups} appliedFilters={runFilters} disabled={true} />
                    </div>
                )}
                {showAdvancedFilters && (
                    <div className={`d-flex bg-white rounded p-3 ${classes.advancedFiltersContainer}`}>
                        <FilterList fields={allFields} lookups={lookups} appliedFilters={advancedFilters} onFiltersApplied={setAdvancedFilters} />
                    </div>
                )}
                <div className="d-flex flex-grow-1 overflow-auto mt-2">
                    <CustomerEvaluationsGrid
                        fields={allFields}
                        lookups={lookups}
                        filtersDto={filtersDto}
                        action={action}
                        onGridLoadingChanged={setIsGridLoading}
                        renderHeader={renderHeader}
                    />
                </div>
            </div>
            {showQuickFilters && (
                <div className="me-3 my-3">
                    <QuickFilters
                        fields={allFields}
                        lookups={lookups}
                        filtersDto={filtersDto}
                        disabled={isGridLoading}
                        selectedQuickFilters={quickFilters}
                        onSelectedQuickFiltersUpdated={setQuickFilters}
                        onShowQuickFiltersToggled={onToggleShowQuickFilters}
                    />
                </div>
            )}
        </div>
    )
}
export default WorkflowRunDetails

const evaluationFields = [
    {
        value: "runid",
        label: "Run ID",
        type: DataPrimitive.TEXT,
        fieldType: FieldType.CUSTOMER
    },
    {
        value: "ispreview",
        label: "Is Preview",
        type: DataPrimitive.BOOLEAN,
        fieldType: FieldType.CUSTOMER
    },
    {
        value: "matchedbasefilters",
        label: "Matched Base Filters",
        type: DataPrimitive.BOOLEAN,
        fieldType: FieldType.CUSTOMER
    },
    {
        value: "matchedactioncount",
        label: "Matched Action Count",
        type: DataPrimitive.NUMBER,
        fieldType: FieldType.CUSTOMER
    }
]

const actionFields = [
    {
        value: "matchedactionfilters",
        label: "Matched Action Filters",
        type: DataPrimitive.BOOLEAN,
        fieldType: FieldType.CUSTOMER
    },
    {
        value: "wasactionsuccessful",
        label: "Was Action Successful",
        type: DataPrimitive.BOOLEAN,
        fieldType: FieldType.CUSTOMER
    },
    {
        value: "actionresult",
        label: "Action Result",
        type: DataPrimitive.TEXT,
        fieldType: FieldType.CUSTOMER
    }
]

const combineFilters = (advancedFilterList: GenericFilter[], quickFilterList: QuickFilter[], runId: string): FiltersDto => {
    const filters = convertFromArrayToDto(advancedFilterList, true)
    const quickFilters = {
        textFieldQuickFilters: [
            ...getQuickFilterDtosForType(quickFilterList, DataPrimitive.TEXT),
            ...getQuickFilterDtosForType(quickFilterList, DataPrimitive.LOOKUP)
        ],
        booleanFieldQuickFilters: getQuickFilterDtosForBoolean(quickFilterList),
        numberFieldQuickFilters: getQuickFilterDtosForType(quickFilterList, DataPrimitive.NUMBER),
        dateFieldQuickFilters: getQuickFilterDtosForType(quickFilterList, DataPrimitive.DATE)
    }

    return {
        textFieldIsOneOf: [
            ...(filters.textFieldIsOneOf ?? []),
            ...quickFilters.textFieldQuickFilters,
            {
                fieldName: "runId",
                values: [runId],
                notOneOf: false
            }
        ],
        textFieldStartsWith: filters.textFieldStartsWith,
        booleanFieldIsEqualTo: [...(filters.booleanFieldIsEqualTo ?? []), ...quickFilters.booleanFieldQuickFilters],
        numberFieldIsOneOf: [...(filters.numberFieldIsOneOf ?? []), ...quickFilters.numberFieldQuickFilters],
        numberFieldMatchesOperation: filters.numberFieldMatchesOperation,
        dateFieldIsOneOf: [...(filters.dateFieldIsOneOf ?? []), ...quickFilters.dateFieldQuickFilters],
        dateFieldMatchesOperation: [...(filters.dateFieldMatchesOperation ?? [])],
        fieldExists: filters.fieldExists
    }
}

const getQuickFilterDtosForBoolean = (quickFilterList: QuickFilter[]) =>
    quickFilterList.filter(f => f.field.type === DataPrimitive.BOOLEAN).map(f => ({ fieldName: f.field.value, equalTo: f.filter.label === "true" }))

const getQuickFilterDtosForType = (quickFilterList: QuickFilter[], dataPrimitive: DataPrimitive) => {
    const filtersForType = quickFilterList.filter(f => f.field.type === dataPrimitive)
    const quickFilterDtos: any[] = []
    if (filtersForType.length > 0) {
        filtersForType.forEach(f => {
            if (quickFilterDtos.some(qf => qf.fieldName === f.field.value)) {
                quickFilterDtos.find(qf => qf.fieldName === f.field.value)?.values.push(f.filter.label)
            } else {
                quickFilterDtos.push({
                    fieldName: f.field.value,
                    values: [f.filter.label],
                    notOneOf: false
                })
            }
        })
    }
    return quickFilterDtos
}
