import { Dropdown, Input } from "invevo-react-components"
import { FileImportConfigFieldDefaultValue, FileImportConfigFieldDefaultValueEnum } from "../../../../types/FileImportConfigFieldDefaultValue"

type DefaultValueConfigProps = {
    config: FileImportConfigFieldDefaultValue
    onChange: (option: FileImportConfigFieldDefaultValue) => any
}

const DefaultValueConfig = ({ config, onChange }: DefaultValueConfigProps) => {
    const booleanOptions = [
        { id: 1, value: true, label: "TRUE" },
        { id: 2, value: false, label: "FALSE" }
    ]

    const onStringValueChange = (event: React.FormEvent<HTMLInputElement>) => {
        const stringValue = event.currentTarget.value

        switch (config.type) {
            case FileImportConfigFieldDefaultValueEnum.STRING:
                onChange({
                    ...config,
                    stringValue
                })
                return
        }
    }

    const onNumberValueChange = (event: React.FormEvent<HTMLInputElement>) => {
        const numberValue = event.currentTarget.value

        switch (config.type) {
            case FileImportConfigFieldDefaultValueEnum.NUMBER:
                onChange({
                    ...config,
                    numberValue
                })
                return
        }
    }

    const onBooleanValueChange = (option: any) => {
        switch (config.type) {
            case FileImportConfigFieldDefaultValueEnum.BOOLEAN:
                onChange({
                    ...config,
                    booleanValue: option.value
                })
                return
        }
    }

    return (
        <div className="d-flex w-100 h-100">
            {config.type === FileImportConfigFieldDefaultValueEnum.STRING ? (
                <Input placeholder="Please enter the value" value={config.stringValue} onChange={onStringValueChange} />
            ) : config.type === FileImportConfigFieldDefaultValueEnum.NUMBER ? (
                <Input placeholder="Please enter the value" value={config.numberValue} onChange={onNumberValueChange} type="number" />
            ) : (
                <Dropdown
                    placeholder={"Please select value"}
                    defaultOption={booleanOptions.find(bo => bo.value === config.booleanValue)}
                    options={booleanOptions}
                    onOptionSelected={onBooleanValueChange}
                />
            )}
        </div>
    )
}

export default DefaultValueConfig
