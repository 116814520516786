import React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IconButton } from '../common/buttons/iconButton';

const AccountDetail = ({
  title,
  value,
  highlight = 'neutral',
  layout = 'standard',
  children = null,
  copyToClipboard = false,
  onClick = null
}) => {
  const isCopyable = typeof value !== 'object' && !value;
  const isHero = layout === 'hero';

  const copyTo =
    copyToClipboard && isCopyable ? (
      <CopyToClipboard text={value}>
        <IconButton icon={['far', 'paste']}></IconButton>
      </CopyToClipboard>
    ) : null;

  if (children) {
    return (
      <div className="row bg-white rounded my-1" onClick={onClick}>
        <div className="col-12 p-1 text-muted">{children}</div>
      </div>
    );
  }

  if (isHero) {
    return (
      <div
        className="row bg-white rounded my-1"
        style={{ minHeight: '71px' }}
        onClick={onClick}
      >
        <div className="col-12 p-1 text-muted">{title}</div>
        <div className={`col-12 text-center px-1 pb-1 hero ${highlight}`}>
          {isCopyable ? (
            <h2>
              {value} {copyTo}
            </h2>
          ) : (
            value
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className="row bg-white rounded my-1 justify-content-between"
      style={{ minHeight: '29px' }}
      onClick={onClick}
    >
      <div className="col-auto p-1 text-muted">{title}</div>
      <div className={`col-auto text-right p-1 ${highlight}`}>{value}</div>
    </div>
  );
};

AccountDetail.propTypes = {
  highlight: PropTypes.oneOf(['neutral', 'positive', 'negitive']),
  layout: PropTypes.oneOf(['standard', 'hero'])
};

export default AccountDetail;
