import { useState } from "react"
import classes from "./VoipNumberPad.module.scss"

const ROW1 = ["1","2","3"]
const ROW2 = ["4","5","6"]
const ROW3 = ["7","8","9"]
const ROW4 = ["*","0","#"]
const DIGITS = [ROW1, ROW2, ROW3, ROW4]

const VoipNumberPad = (props: {onButtonPress: (digit: string) => void}) => {
	const [pressedKeys, setPressedKeys] = useState<string[]>([])

	const calculateMaxCharsForDisplay = () => document.getElementById("numpad-display")!.offsetWidth / 10 - 1

	const onButtonPress = (digit: string) => {
		const maxLength = calculateMaxCharsForDisplay()
		const reachedLengthLimit = pressedKeys.length >= maxLength
		let newPressedKeys = reachedLengthLimit ? pressedKeys.slice(-maxLength) : pressedKeys
		if(reachedLengthLimit){
			newPressedKeys.unshift("...")
		}
		setPressedKeys([...newPressedKeys, digit])
		props.onButtonPress(digit)
	}

	const onClearKeys = () => setPressedKeys([])

	return (
		<div className={`d-flex flex-column w-50 mt-4 mx-auto rounded align-items-center ${classes.fullShadow}`}>
			<div className="d-flex w-100 align-items-center p-3">
				<input disabled={true} id="numpad-display" value={pressedKeys.length === 0 ? "" : pressedKeys.join("")} className="w-100 rounded justify-self-left no-select" aria-label="numpad-display" />
				<button className={`ms-2 pl-1 btn ${classes.darkShadow} text-white fs-6 justify-self-right`} onClick={onClearKeys} aria-label="numpad-clear">
					<i className="fal fa-trash-alt"/>
				</button>
			</div>
			<div className="container-fluid">
				{DIGITS.map((row, i) => 
					<div key={i.toString()} className="row mb-2 d-flex flex-row align-items-center py-1">
						{row.map((digit, j) => <NumpadButton digit={digit} key={i.toString() + j} onClick={onButtonPress} /> )}
					</div>
				)}
			</div>
		</div>
	)
}

export default VoipNumberPad

const NumpadButton = (props: {digit: string, onClick: (digit: string) => void}) => {
	const onButtonPress = () => props.onClick(props.digit)
	return <button className={`col btn ${classes.darkShadow} text-white fs-5 mx-2`} onClick={onButtonPress} aria-label={`numpad-${props.digit}`}>{props.digit}</button>
}
