import { IconButton } from 'invevo-react-components';
import React, { useState } from 'react';
import { showToastErrorMessage } from '../../api/toasterApi';
import EditNote from './editNote';

const AddStaticNote = ({ addNote }) => {
  const [show, setShow] = useState(false);

  const onUpdate = (noteText) => {
    if (noteText === '') {
      showToastErrorMessage('No blank notes.');
    } else {
      addNote(noteText);
      setShow(false);
    }
  };

  const onAdd = () => setShow(true);

  return (
    <>
      <IconButton onClick={onAdd} iconClasses="fa-regular fa-plus" colour="blue" />
      {show && (
        <EditNote
          show={show}
          setShow={setShow}
          initNoteText={''}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};
export default AddStaticNote;
