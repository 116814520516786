import { getApiUrl } from "../constants";
import { webApiInterface } from "./webApiInterface";

export function insertConfiguration(configuration, dispatch, client) {
    const url = getApiUrl(client) + 'api/system-configuration';

    return webApiInterface.authPost(client, url, dispatch, configuration, 'POST');
}

export function updateConfiguration(configuration, dispatch, client) {
    const url = getApiUrl(client) + 'api/system-configuration';

    return webApiInterface.authPost(client, url, dispatch, configuration, 'PUT');
}

export function getConfiguration(systemConfigurationId, dispatch, client) {
    const url = getApiUrl(client) + 'api/system-configuration/configuration?systemConfigurationId=' + systemConfigurationId;

    return webApiInterface.authFetch(client, url, dispatch)
        .then(function (response) {
            return response.json();
        });
}

export function getGlobalConfiguration(dispatch, client) {
    const url = getApiUrl(client) + 'api/client-configuration';

    return webApiInterface.authFetch(client, url, dispatch)
        .then(function (response) {
            return response.json();
        });
}