import { StandardButton } from 'invevo-react-components'

const Footer = ({ selectionCount, onSearchClick }) => {
    return (
        <div className="d-flex align-items-center w-100 px-2 pt-1 pb-2 mt-auto bg-white">
            <div className="d-flex ms-auto">
                <h5 className="text-grey no-select fw-light mb-0 mx-3">{selectionCount} item{selectionCount === 1 ? "" : "s"} selected</h5>
            </div>
            <div className="d-flex ">
                <StandardButton
                    className="align-self-start"
                    iconClasses="fal fa-search"
                    label="Search"
                    colour="blue"
                    onClick={onSearchClick} />
            </div>
        </div>
    )
}

export default Footer