import { AutoComplete, Input } from 'invevo-react-components'
import classes from './FilterRuleInputList.module.scss'

const getListFromValue = values => values.split(',').filter(v => v !== null && v !== "")

const InputList = ({
    className,
    rule,
    ruleIndex,
    onChange,
    readonly,
    inputType = "normal",
    autoCompleteUrl = "",
    enableTooltip = false,
    scrollable = false,
    customerFieldType = false
}) => {
    const values = readonly ? getListFromValue(rule.value) : [...getListFromValue(rule.value), ""]
    var i = 0

    return (
        <div className={`d-flex flex-column ${className ? className : ''}`}>
            {values.map((value, key = i++) => {
                return (inputType === "normal" ?
                    <Input
                        disabled={readonly}
                        className={`mb-1 ${value === "" && key !== 0 ? classes.unusedInput : ""}`}
                        key={key}
                        value={value}
                        onChange={(event) => onChange(event.target.value, key, rule, ruleIndex)}
                    />
                    :
                    <AutoComplete
                        disabled={readonly}
                        className={`mb-1 ${value === "" && key !== 0 ? classes.unusedInput : ""}`}
                        url={autoCompleteUrl}
                        fieldName={customerFieldType ? rule.fieldName : rule.field}
                        fieldType={customerFieldType ? rule.type : ""}
                        initialValue={value}
                        key={key}
                        onChange={(value) => onChange(value, key, rule, ruleIndex)}
                        enableTooltip={enableTooltip}
                        scrollable={scrollable}
                    />
                )
            })}
        </div>
    )
}

export default InputList