import { useFeatureToggle, useInvevoSession, useUser } from "invevo-react-components"
import { useCallback, useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { expandablePanels, useLayout } from "../../../contexts/LayoutContext"
import { actionTypes } from "../../../reducers/actionTypes"
import { PARENT_ROUTE } from "../Home"
import classes from "./Menu.module.scss"
import MenuDivider from "./MenuDivider"
import MenuItem from "./MenuItem"
import { MenuItems } from "./MenuItems"
import RoleOverlay from "./RoleOverlay"
import { useUser as useNewUser } from "../../../contexts/UserContext"

const Menu = ({ state, isGuestUser, dispatch }) => {
    const { pathname, search } = useLocation()
    const history = useHistory()
    const user = useUser()
    const newUser = useNewUser()
    const [invevoSession] = useInvevoSession()
    const { setExpandedPanel, isLeftPanelExpanded } = useLayout()
    const [viewRoles, setViewRoles] = useState(false)

    const { isEnabled: canContolRoles } = useFeatureToggle("userCanControlRoles")
    const { isEnabled: isTeamPerfDashEnabled } = useFeatureToggle("teamPerformanceDashboard")
    const { isEnabled: isNewCustomerSearchEnabled } = useFeatureToggle("newCustomerSearch")
    const { isEnabled: isHideOldSearchEnabled } = useFeatureToggle("hideOldSearch")
    const { isEnabled: isNewEntityStructureEnabled } = useFeatureToggle("newEntityStructure")
    const { isEnabled: isBusinessFlowEnabled } = useFeatureToggle("businessFlowsFeature")
    const { isEnabled: isLegacyTasksHidden } = useFeatureToggle("hideLegacyTasksFromMenuAndActionStrip")

    const setActiveMenuItem = useCallback(
        menuItem => {
            if (state.activeMenuItem === menuItem) {
                return
            }

            dispatch({ type: actionTypes.MENU_ITEM_CLICKED, menuItem })
        },
        [dispatch, state.activeMenuItem]
    )

    const setCurrentRoute = route => {
        const newRoute = `${PARENT_ROUTE}${route}`
        if (pathname === newRoute) {
            return
        }

        history.push(`${newRoute}${search}`)
    }

    useEffect(() => {
        switch (pathname) {
            case `${PARENT_ROUTE}/view-dashboards`:
                setActiveMenuItem(MenuItems.VIEW_DASHBOARDS)
                break

            case `${PARENT_ROUTE}/search`:
                setActiveMenuItem(MenuItems.SEARCH)
                break

            case `${PARENT_ROUTE}/new-search`:
                setActiveMenuItem(MenuItems.NEW_SEARCH)
                break

            case `${PARENT_ROUTE}/entity-search`:
                setActiveMenuItem(MenuItems.ENTITY_SEARCH)
                break

            case `${PARENT_ROUTE}/tasks`:
                setActiveMenuItem(MenuItems.MY_TASKS)
                break

            case `${PARENT_ROUTE}/unread-emails`:
                setActiveMenuItem(MenuItems.UNREAD_EMAILS)
                break

            case `${PARENT_ROUTE}/reporting`:
                setActiveMenuItem(MenuItems.REPORTING)
                break

            case `${PARENT_ROUTE}/dashboards`:
                setActiveMenuItem(MenuItems.DASHBOARDS)
                break

            case `${PARENT_ROUTE}/automate`:
                setActiveMenuItem(MenuItems.AUTOMATE)
                break

            case `${PARENT_ROUTE}/flow-sculptor`:
                setActiveMenuItem(MenuItems.FLOW_SCULPTOR)
                break

            case `${PARENT_ROUTE}/data-fields`:
                setActiveMenuItem(MenuItems.DATA_FIELDS)
                break

            case `${PARENT_ROUTE}/entity-configs`:
                setActiveMenuItem(MenuItems.ENTITY_CONFIG)
                break

            case `${PARENT_ROUTE}/settings`:
                setActiveMenuItem(MenuItems.SETTINGS)
                break

            case `${PARENT_ROUTE}/user-profile`:
                setActiveMenuItem(MenuItems.USER_PROFILE)
                break

            default:
                break
        }
        // can't re-run based on subMenuClick changing.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, setActiveMenuItem])

    const onLogoClick = () => setCurrentRoute("")

    const onMenuItemClick = menuItem => {
        switch (menuItem) {
            case MenuItems.VIEW_DASHBOARDS:
                setCurrentRoute("/view-dashboards")
                break

            case MenuItems.SEARCH:
                setCurrentRoute("/search")
                if (!isLeftPanelExpanded) {
                    setExpandedPanel(expandablePanels.DEFAULT)
                }
                break

            case MenuItems.NEW_SEARCH:
                setCurrentRoute("/new-search")
                if (!isLeftPanelExpanded) {
                    setExpandedPanel(expandablePanels.LEFT_PANEL)
                }
                break

            case MenuItems.ENTITY_SEARCH:
                setCurrentRoute("/entity-search")
                if (!isLeftPanelExpanded) {
                    setExpandedPanel(expandablePanels.LEFT_PANEL)
                }
                break

            case MenuItems.MY_TASKS:
                setCurrentRoute("/tasks")
                if (isLeftPanelExpanded) {
                    setExpandedPanel(expandablePanels.DEFAULT)
                }
                break

            case MenuItems.UNREAD_EMAILS:
                setCurrentRoute("/unread-emails")
                if (!isLeftPanelExpanded) {
                    setExpandedPanel(expandablePanels.DEFAULT)
                }
                break

            case MenuItems.REPORTING:
                setCurrentRoute("/reporting")
                break

            case MenuItems.DASHBOARDS:
                setCurrentRoute("/dashboards")
                break

            case MenuItems.AUTOMATE:
                setCurrentRoute("/automate")
                break

            case MenuItems.FLOW_SCULPTOR:
                setCurrentRoute("/flow-sculptor")
                break

            case MenuItems.DATA_FIELDS:
                setCurrentRoute("/data-fields")
                break

            case MenuItems.ENTITY_CONFIG:
                setCurrentRoute("/entity-configs")
                break

            case MenuItems.SETTINGS:
                setCurrentRoute("/settings")
                break

            case MenuItems.USER_PROFILE:
                setCurrentRoute("/user-profile")
                break

            default:
                break
        }
    }

    const onMyInvevoClick = () => {
        window.open("https://invevo.myportfolio.com/", "_blank").focus()
    }

    const onLogoutClick = () => {
        history.push("/?logout=true")
    }

    const canSeeAdvancedFeatures = !user.permissions
        ? false
        : user.permissions.hasAdminPermission ||
          user.permissions.hasDashboardPermission ||
          user.permissions.hasBrandPermission ||
          user.permissions.hasAutomatePermission

    return (
        <div className={`d-flex flex-column ${classes.menu} align-items-center`}>
            <div className={`${classes.logo} pointer`} onClick={onLogoClick}>
                <img src="/Invevo_Icon_Gradient.svg" alt="Invevo Logo" />
            </div>
            {!isGuestUser && (
                <>
                    {isTeamPerfDashEnabled && (
                        <MenuItem
                            className="mt-2"
                            label="Dashboards"
                            iconClasses="fa-solid fa-gauge-simple-high"
                            isActive={state.activeMenuItem === MenuItems.VIEW_DASHBOARDS}
                            onClick={() => onMenuItemClick(MenuItems.VIEW_DASHBOARDS)}
                        />
                    )}
                    {!isHideOldSearchEnabled && (
                        <MenuItem
                            className={isTeamPerfDashEnabled ? "" : "mt-2"}
                            label="Search"
                            iconClasses="fa-solid fa-search"
                            isActive={state.activeMenuItem === MenuItems.SEARCH}
                            onClick={() => onMenuItemClick(MenuItems.SEARCH)}
                        />
                    )}
                    {isNewCustomerSearchEnabled && (
                        <MenuItem
                            className={isNewCustomerSearchEnabled ? "" : "mt-2"}
                            label="New Search"
                            iconClasses="fa-solid fa-magnifying-glass-plus"
                            isActive={state.activeMenuItem === MenuItems.NEW_SEARCH}
                            onClick={() => onMenuItemClick(MenuItems.NEW_SEARCH)}
                        />
                    )}
                    {isNewEntityStructureEnabled && (
                        <MenuItem
                            className={isTeamPerfDashEnabled ? "" : "mt-2"}
                            label="Entity Search"
                            iconClasses="fa-solid fa-compass"
                            isActive={state.activeMenuItem === MenuItems.ENTITY_SEARCH}
                            onClick={() => onMenuItemClick(MenuItems.ENTITY_SEARCH)}
                        />
                    )}
                    {!isLegacyTasksHidden && (
                        <MenuItem
                            label="My tasks"
                            iconClasses="fa-solid fa-list"
                            isActive={state.activeMenuItem === MenuItems.MY_TASKS}
                            onClick={() => onMenuItemClick(MenuItems.MY_TASKS)}
                        />
                    )}
                    <MenuItem
                        label="Unread emails"
                        iconClasses="fa-solid fa-envelope-dot"
                        isActive={state.activeMenuItem === MenuItems.UNREAD_EMAILS}
                        onClick={() => onMenuItemClick(MenuItems.UNREAD_EMAILS)}
                    />
                    <MenuItem
                        label="Reporting"
                        iconClasses="fa-solid fa-file-chart-column"
                        isActive={state.activeMenuItem === MenuItems.REPORTING}
                        onClick={() => onMenuItemClick(MenuItems.REPORTING)}
                    />

                    <MenuDivider />

                    {canSeeAdvancedFeatures && (user.permissions.hasAdminPermission || user.permissions.hasDashboardPermission) && (
                        <MenuItem
                            label="Dashboards"
                            iconClasses="fa-solid fa-table-columns"
                            isActive={state.activeMenuItem === MenuItems.DASHBOARDS}
                            onClick={() => onMenuItemClick(MenuItems.DASHBOARDS)}
                        />
                    )}

                    {canSeeAdvancedFeatures && (user.permissions.hasAdminPermission || user.permissions.hasAutomatePermission) && (
                        <MenuItem
                            label="Automate"
                            iconClasses="fa-light fa-sitemap"
                            isActive={state.activeMenuItem === MenuItems.AUTOMATE}
                            onClick={() => onMenuItemClick(MenuItems.AUTOMATE)}
                        />
                    )}

                    {isBusinessFlowEnabled && canSeeAdvancedFeatures && user.permissions.hasAdminPermission && (
                        <MenuItem
                            label="Flow Sculptor"
                            iconClasses="fa-light fa-diagram-project"
                            isActive={state.activeMenuItem === MenuItems.FLOW_SCULPTOR}
                            onClick={() => onMenuItemClick(MenuItems.FLOW_SCULPTOR)}
                        />
                    )}

                    {user.permissions && (user.permissions.hasAdminPermission || user.permissions.hasDataConfigPermission) && (
                        <MenuItem
                            label="Data Fields"
                            iconClasses="fa-solid fa-file-spreadsheet"
                            isActive={state.activeMenuItem === MenuItems.DATA_FIELDS}
                            onClick={() => onMenuItemClick(MenuItems.DATA_FIELDS)}
                        />
                    )}

                    {isNewEntityStructureEnabled && user.permissions && (user.permissions.hasAdminPermission || user.permissions.hasDataConfigPermission) && (
                        <MenuItem
                            label="Entity Config"
                            iconClasses="fa-solid fa-table"
                            isActive={state.activeMenuItem === MenuItems.ENTITY_CONFIG}
                            onClick={() => onMenuItemClick(MenuItems.ENTITY_CONFIG)}
                        />
                    )}

                    <MenuItem
                        label="Settings"
                        iconClasses="fa-light fa-sliders"
                        isActive={state.activeMenuItem === MenuItems.SETTINGS}
                        onClick={() => onMenuItemClick(MenuItems.SETTINGS)}
                    />

                    <MenuDivider />

                    <MenuItem label="MyInvevo" iconClasses="fa-regular fa-lightbulb-on" onClick={onMyInvevoClick} />
                    {canContolRoles && (
                        <>
                            <MenuDivider />
                            <MenuItem label="Roles" iconClasses="fa-regular fa-toggle-on" onClick={() => setViewRoles(true)} />
                        </>
                    )}
                    <MenuDivider />
                </>
            )}

            {isGuestUser && (
                <MenuItem
                    className="mt-auto"
                    label="Your profile"
                    iconClasses="fa-solid fa-user-gear"
                    isActive={state.activeMenuItem === MenuItems.USER_PROFILE}
                    onClick={() => onMenuItemClick(MenuItems.USER_PROFILE)}
                />
            )}
            {viewRoles && <RoleOverlay roles={newUser.userRoles} onCloseClick={() => setViewRoles(false)} setRoles={newUser.setUserRoles} />}

            {invevoSession.clients && invevoSession.clients.length > 1 ? (
                <MenuItem label="Landing page" iconClasses="fa-solid fa-right-from-bracket" onClick={onLogoutClick} />
            ) : (
                <MenuItem label="Logout" iconClasses="fa-solid fa-right-from-bracket" onClick={onLogoutClick} />
            )}
        </div>
    )
}

export default Menu
