import { Route, Switch } from "react-router-dom"
import Roles from "./roles/components/Roles"
import UserRolesNav from "./UserRolesNav"
import ClientUsers from "./users/components/ClientUsers"

const UserRolesRoutes = () => {
    return (
        <div className="d-flex w-100 h-100">
            <Switch>
                <Route path="/home/user-roles" exact render={() => <UserRolesNav />} />
                <Route path="/home/roles" exact render={() => <ClientUsers />} />
                <Route path="/home/new-users" exact render={() => <Roles />} />
            </Switch>
        </div>
    )
}

export default UserRolesRoutes
