import { Toggle } from "invevo-react-components"
import Role from "../types/Role"
import classes from "./RoleConfig.module.scss"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"
import EntityConfig from "../../../entityConfig/types/EntityConfig"
import Dropdown from "../../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../../types/DropdownOptions"
import EntityPermission from "../types/EntityPermission"
import EntityPermissionConfig from "./EntityPermissionsConfig"

type RoleConfigProps = {
    role: Role
    entityConfigs: EntityConfig[]
    onRoleUpdated: (role: Role) => void
}

const RoleConfig = ({ role, entityConfigs, onRoleUpdated }: RoleConfigProps) => {
    const { isEnabled: isNewEntityStructureEnabled } = useFeatureToggle("newEntityStructure")

    const onCustomerPermissionToggled = () => onRoleUpdated({ ...role, customerPermissions: !role.customerPermissions })
    const onAutomateAdminPermissionToggled = () => onRoleUpdated({ ...role, automateAdmin: !role.automateAdmin })
    const onConnectAdminPermissionToggled = () => onRoleUpdated({ ...role, connectAdmin: !role.connectAdmin })
    const onDashboardAdminPermissionToggled = () => onRoleUpdated({ ...role, dashboardAdmin: !role.dashboardAdmin })
    const onDataConfigAdminPermissionToggled = () => onRoleUpdated({ ...role, dataConfigAdmin: !role.dataConfigAdmin })
    const onIntegrationPermissionToggled = () => onRoleUpdated({ ...role, integrationAdmin: !role.integrationAdmin })
    const onUserAdminPermissionToggled = () => onRoleUpdated({ ...role, userAdmin: !role.userAdmin })

    const onAddEntityPermission = (option: DropdownOption<EntityConfig>) => {
        const newEntityPermission: EntityPermission = { entityTypeReference: option.value.reference, isEnabled: true }
        onRoleUpdated({ ...role, entityPermissions: [...role.entityPermissions, newEntityPermission] })
    }

    const onRemoveEntityPermission = (entityTypeReference: String) => {
        onRoleUpdated({
            ...role,
            entityPermissions: role.entityPermissions.filter(entityPermission => entityPermission.entityTypeReference !== entityTypeReference)
        })
    }

    const entityConfigOptions: DropdownOption<EntityConfig>[] = entityConfigs
        .filter(entityConfig => !role.entityPermissions.some(entityPermission => entityPermission.entityTypeReference === entityConfig.reference))
        .map(entityConfig => {
            return {
                label: entityConfig.displayName,
                value: entityConfig
            }
        })

    return (
        <div className="d-flex flex-column w-100 m-3">
            <div className={`${classes.container} d-flex flex-column w-100 h-100 rounded p-3 text-grey overflow-auto`}>
                {isNewEntityStructureEnabled && (
                    <div className="mb-3">
                        <div className="d-flex mb-2">
                            <h3 className="my-auto text-grey">Entity Permissions</h3>
                            <Dropdown
                                className="ms-auto"
                                ariaLabel="entity-type-dropdown"
                                fixedSize={true}
                                options={entityConfigOptions}
                                onOptionSelected={onAddEntityPermission}
                                selectedOption={undefined}
                                textAlign="left"
                                placeholder={
                                    <span className="d-flex align-items-center me-2">
                                        <i className="fal fa-plus fs-5 me-2" />
                                        Add Entity Permission
                                    </span>
                                }
                            />
                        </div>
                        {role.entityPermissions.length === 0 ? (
                            <div className="d-flex my-4">
                                <span className="fs-6 mx-auto">No entity permissions for the current role</span>
                            </div>
                        ) : (
                            role.entityPermissions.map(entityPermission => (
                                <EntityPermissionConfig
                                    entityPermission={entityPermission}
                                    entityConfigs={entityConfigs}
                                    onEntityPermissionRemoved={onRemoveEntityPermission}
                                    key={entityPermission.entityTypeReference}
                                />
                            ))
                        )}
                    </div>
                )}
                <h3 className="text-grey">Admin Permissions</h3>
                <div className="d-flex flex-column bg-grey p-2 mt-2 rounded">
                    <div className="d-flex flex-grow-1 align-items-center">
                        <span className="ms-2 text-nowrap">Customer Permissions</span>
                        <Toggle
                            className="ms-auto"
                            status={role.customerPermissions}
                            onStatusChanged={onCustomerPermissionToggled}
                            ariaLabel="customer-permissions"
                        />
                    </div>
                </div>
                <div className="d-flex flex-column bg-grey p-2 mt-2 rounded">
                    <div className="d-flex flex-grow-1 align-items-center">
                        <span className="ms-2 text-nowrap">Automate admin</span>
                        <Toggle className="ms-auto" status={role.automateAdmin} onStatusChanged={onAutomateAdminPermissionToggled} ariaLabel="automate-admin" />
                    </div>
                </div>
                <div className="d-flex flex-column bg-grey p-2 mt-2 rounded">
                    <div className="d-flex flex-grow-1 align-items-center">
                        <span className="ms-2 text-nowrap">Connect admin</span>
                        <Toggle className="ms-auto" status={role.connectAdmin} onStatusChanged={onConnectAdminPermissionToggled} ariaLabel="connect-admin" />
                    </div>
                </div>
                <div className="d-flex flex-column bg-grey p-2 mt-2 rounded">
                    <div className="d-flex flex-grow-1 align-items-center">
                        <span className="ms-2 text-nowrap">Dashboard admin</span>
                        <Toggle
                            className="ms-auto"
                            status={role.dashboardAdmin}
                            onStatusChanged={onDashboardAdminPermissionToggled}
                            ariaLabel="dashboard-admin"
                        />
                    </div>
                </div>
                <div className="d-flex flex-column bg-grey p-2 mt-2 rounded">
                    <div className="d-flex flex-grow-1 align-items-center">
                        <span className="ms-2 text-nowrap">Data config admin</span>
                        <Toggle
                            className="ms-auto"
                            status={role.dataConfigAdmin}
                            onStatusChanged={onDataConfigAdminPermissionToggled}
                            ariaLabel="data-config-admin"
                        />
                    </div>
                </div>
                <div className="d-flex flex-column bg-grey p-2 mt-2 rounded">
                    <div className="d-flex flex-grow-1 align-items-center">
                        <span className="ms-2 text-nowrap">Integration admin</span>
                        <Toggle
                            className="ms-auto"
                            status={role.integrationAdmin}
                            onStatusChanged={onIntegrationPermissionToggled}
                            ariaLabel="integration-admin"
                        />
                    </div>
                </div>
                <div className="d-flex flex-column bg-grey p-2 mt-2 rounded">
                    <div className="d-flex flex-grow-1 align-items-center">
                        <span className="ms-2 text-nowrap">User admin</span>
                        <Toggle className="ms-auto" status={role.userAdmin} onStatusChanged={onUserAdminPermissionToggled} ariaLabel="user-admin" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoleConfig
