import classes from "./GridColumnConfig.module.scss"
import EntityDataFieldDropdown from "../../../../../library/dropdowns/EntityDataFieldDropdown"
import EntityDataField from "../../../../../routes/entityConfig/types/EntityDataField"
import Checkbox from "../../../../../library/form/Checkbox"
import { GridWidgetColumnState } from "../../../types/DashboardConfigState"

const NONEDITABLE_FIELDS = ["client", "reference", "entity_type"]

type GridColumnConfigProps = {
    index: number
    fields: EntityDataField[]
    column: GridWidgetColumnState
    isFirst: boolean
    isLast: boolean
    isEditable: boolean
    onColumnEditableToggle: (column: GridWidgetColumnState) => void
    onColumnRemoveClick: (column: GridWidgetColumnState) => void
    onColumnNameChange: (option: EntityDataField | undefined, previousColumn: string) => void
    onMoveColumnUp: (column: number) => void
    onMoveColumnDown: (column: number) => void
}

const GridColumnConfig = ({
    index,
    fields,
    column,
    isFirst,
    isLast,
    isEditable,
    onColumnEditableToggle,
    onColumnRemoveClick,
    onColumnNameChange,
    onMoveColumnUp,
    onMoveColumnDown
}: GridColumnConfigProps) => {
    const onIsEditableClick = () => {
        onColumnEditableToggle(column)
    }
    const onRemoveClick = () => {
        onColumnRemoveClick(column)
    }
    const onEntityFieldSelected = (option: EntityDataField | undefined) => onColumnNameChange(option, column.id)

    const onMoveColumnUpClick = () => {
        if (isFirst) return

        onMoveColumnUp(index)
    }

    const onMoveColumnDownClick = () => {
        if (isLast) return

        onMoveColumnDown(index)
    }

    return (
        <div className="d-flex align-items-center">
            <div className="d-flex flex-column align-items-center me-2">
                <div className="d-flex pointer w-100" onClick={onMoveColumnUpClick}>
                    <i className={`fal fa-caret-up m-auto ${isFirst ? "text-light-grey" : ""}`} />
                </div>
                <i className="fal fa-horizontal-rule" />
                <div className="d-flex pointer w-100" onClick={onMoveColumnDownClick}>
                    <i className={`fal fa-caret-down m-auto ${isLast ? "text-light-grey" : ""}`} />
                </div>
            </div>
            <div className="d-flex flex-column flex-grow-1">
                <div className="d-flex align-items-center">
                    <span className={`text-uppercase small mb-1 text-grey fw-bold`}>column {index + 1}</span>
                    <div className={`d-flex align-items-center ms-auto`}>
                        {isEditable && (
                            <div className="d-flex align-items-center me-2">
                                <Checkbox
                                    isChecked={column.isEditable}
                                    onClick={onIsEditableClick}
                                    disabled={NONEDITABLE_FIELDS.includes(column.fieldName)}
                                    ariaLabel={`${column.fieldName}-is-editable-checkbox`}
                                    label="Is Editable?"
                                    labelUpperCase={false}
                                />
                            </div>
                        )}
                        <i className={`fas fa-times-circle pointer ${classes["remove-field-icon"]}`} onClick={onRemoveClick}></i>
                    </div>
                </div>
                <EntityDataFieldDropdown
                    className="flex-grow-1 mb-2"
                    ariaLabel={`${column.fieldName}-grid-widget-input`}
                    options={fields}
                    selectedOption={fields.find(cf => cf.fieldName === column.fieldName) || undefined}
                    onOptionSelected={onEntityFieldSelected}
                />
            </div>
        </div>
    )
}

export default GridColumnConfig
