import { Loading, useConfig } from "invevo-react-components"
import useApiQuery from "../../../hooks/useApiQuery"
import OpenBankingReport from "./reports/OpenBankingReport"
import MobileOpenBankingReport from "./reports/OpenBankingReport.mobile"
import SetUpSubscription from "./SetUpSubscription"
import classes from "./GuestDashboard.module.scss"
import { CreditCircleCustomerDto } from "../types/dtos/CreditCircleCustomerDto"
import { useIsMobile } from "../../../hooks/useIsMobile"
import { useEffect, useMemo } from "react"
import { useSessionStorage } from "../../../hooks/useSessionStorage"
import { EXTERNAL_AFFILIATE_KEY } from "../../../library/auth/constants"

const GuestDashboard = () => {
    const config = useConfig()
    const isMobile = useIsMobile()
    const [externalAffiliate, setExternalAffiliate] = useSessionStorage(EXTERNAL_AFFILIATE_KEY)

    const customerQuery = useApiQuery<CreditCircleCustomerDto>({
        url: `${config.CREDIT_CIRCLE_API_URL}/api/creditcircle/credit-circle-customer`,
        method: "GET",
        headers: { affiliate: externalAffiliate?.affiliate ?? "invevo" },
        isExecutedAutomatically: false
    })

    useEffect(() => {
        customerQuery.execute().then(() => setExternalAffiliate(""))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const hasActiveSubscription = useMemo(() => {
        return customerQuery.data?.subscriptionDto?.activeSubscription ?? false
    }, [customerQuery.data])

    return (
        <div className={`d-flex w-100 h-100 ${isMobile ? "p-2" : "p-4"} ${classes.background}`}>
            <Loading isLoading={customerQuery.isFetching} colour="white">
                <div className="d-flex flex-column w-100">
                    {!hasActiveSubscription && <SetUpSubscription />}
                    {hasActiveSubscription &&
                        (isMobile ? (
                            <MobileOpenBankingReport
                                accounts={customerQuery.data?.bankingDto?.accounts ?? []}
                                invevoCustomerReference={customerQuery.data?.invevoCustomerReference ?? ""}
                            />
                        ) : (
                            <OpenBankingReport
                                accounts={customerQuery.data?.bankingDto?.accounts ?? []}
                                invevoCustomerReference={customerQuery.data?.invevoCustomerReference ?? ""}
                            />
                        ))}
                </div>
            </Loading>
        </div>
    )
}

export default GuestDashboard
