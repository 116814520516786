import { FileImportConfigFieldDataType, TextFormatter } from "../../../../types/FileImportConfigFieldDataType"
import { FileImportConfigFieldDefaultValue, FileImportConfigFieldDefaultValueEnum } from "../../../../types/FileImportConfigFieldDefaultValue"
import classes from "./ExportFieldConfig.module.scss"
import { EntityExportFieldConfigState, FileExportConfigAction, FileExportConfigActionEnum } from "../../../../reducers/FileEntityExportConfigReducer"
import { FileImportConfigColumnType } from "../../../../types/FileImportConfigColumnType"
import ExportColumn from "./ExportColumn"
import { getDataTypeFromDataField, getDefaultValueTypeFromDataField } from "../../../importConfigs/fileImportConfigSetup/importFieldConfig/ImportFieldConfig"
import DataTypeConfig from "../../../importConfigs/fileImportConfigSetup/importFieldConfig/DataTypeConfig"
import DefaultValueConfig from "../../../importConfigs/fileImportConfigSetup/importFieldConfig/DefaultValueConfig"
import EntityDataField from "../../../../../entityConfig/types/EntityDataField"
import EntityDataFieldDropdown from "../../../../../../library/dropdowns/EntityDataFieldDropdown"
import { dataTypeEnumToDataPrimitive } from "../../../utils"
import Toggle from "../../../../../../library/toggle/Toggle"
import IconButton from "../../../../../../library/buttons/IconButton/IconButton"

type ExportFieldConfigProps = {
    state: { config: EntityExportFieldConfigState; dataFields: EntityDataField[]; hasHeaderRow: Boolean; ordinal: number }
    isFirst: boolean
    isLast: boolean
    index: number
    onMoveColumnUp: (columnIndex: number) => void
    onMoveColumnDown: (columnIndex: number) => void
    dispatch: React.Dispatch<FileExportConfigAction>
    onRemoveClick: (fieldConfig: EntityExportFieldConfigState) => any
}

const ExportFieldConfig = ({ state, isFirst, isLast, index, onMoveColumnUp, onMoveColumnDown, dispatch, onRemoveClick }: ExportFieldConfigProps) => {
    const onColumnChange = (column: FileImportConfigColumnType) =>
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                ordinal: index,
                column
            }
        })

    const onMoveColumnUpClick = () => {
        if (isFirst) return

        onMoveColumnUp(index)
    }

    const onMoveColumnDownClick = () => {
        if (isLast) return

        onMoveColumnDown(index)
    }

    const onDataFieldChange = (option?: EntityDataField | undefined) => {
        if (!option) return
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                dataFieldReference: option.fieldName,
                dataType: {
                    ...state.config.dataType,
                    type: getDataTypeFromDataField(dataTypeEnumToDataPrimitive(option.dataType.type))
                } as FileImportConfigFieldDataType,
                ordinal: index,
                defaultValue: state.config.defaultValue
                    ? ({
                          ...state.config.defaultValue,
                          type: getDefaultValueTypeFromDataField(dataTypeEnumToDataPrimitive(option.dataType.type))
                      } as FileImportConfigFieldDefaultValue)
                    : undefined
            }
        })
    }

    const onFormatChange = (formatter: keyof typeof TextFormatter) => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                formatter
            }
        })
    }

    const onDataTypeChange = (dataType: FileImportConfigFieldDataType) => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                ordinal: index,
                dataType
            }
        })
    }

    const onDefaultValueChange = (defaultValue: FileImportConfigFieldDefaultValue) => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                ordinal: index,
                defaultValue
            }
        })
    }

    const onShowDefaultValueConfigChange = () => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                ordinal: index,
                defaultValue: state.config.defaultValue
                    ? undefined
                    : {
                          type: FileImportConfigFieldDefaultValueEnum.STRING,
                          stringValue: ""
                      }
            }
        })
    }

    const onRemoveFieldConfigClick = () => onRemoveClick(state.config)

    const getDataField = () => state.dataFields.find(df => df.fieldName === state.config.dataFieldReference)

    return (
        <div className={`d-flex w-100 ${classes.card}`}>
            <div className="d-flex flex-column p-4 w-100">
                <div className={`d-flex flex-grow-1 flex-column small`}>
                    <div className="d-flex align-items-center mb-2">
                        <span className="fs-4 text-grey">Column</span>
                    </div>
                    <ExportColumn column={state.config.column} onChange={onColumnChange} />
                </div>
                <div className={`${classes.horizontalDivider} m-2`} />
                <div className="d-flex flex-grow-1">
                    <div className="d-flex flex-grow-1 flex-column small me-3">
                        <span className="text-uppercase small mb-1 ms-1 text-grey">Invevo data field</span>
                        <EntityDataFieldDropdown
                            className="flex-grow-1 mb-2"
                            options={state.dataFields}
                            selectedOption={getDataField()}
                            onOptionSelected={onDataFieldChange}
                            ariaLabel={"Add invevo data field"}
                        />
                    </div>
                    {getDataField() && (
                        <DataTypeConfig
                            config={state.config.dataType}
                            formatter={state.config.formatter}
                            onChange={onDataTypeChange}
                            onFormatterChange={onFormatChange}
                        />
                    )}
                </div>
                <div className={`${classes.horizontalDivider} m-2`} />
                {getDataField() && (
                    <>
                        <div className={`${classes.horizontalDivider} m-2`} />
                        <div className={`d-flex flex-column`}>
                            <div className="d-flex align-items-center">
                                <span className="fs-4 text-grey">Default value if empty</span>
                                <Toggle
                                    className="ms-auto"
                                    status={state.config.defaultValue !== undefined}
                                    onStatusChanged={onShowDefaultValueConfigChange}
                                    toggleColour="blue"
                                    ariaLabel="Add default value"
                                />
                            </div>
                            <div className={`${classes.horizontalDivider} m-2`} />
                            {state.config.defaultValue && <DefaultValueConfig config={state.config.defaultValue} onChange={onDefaultValueChange} />}
                        </div>
                    </>
                )}
                <div className={`${classes.horizontalDivider} m-2`} />
            </div>
            <div className="d-flex flex-column justify-content-between border-start">
                <div className="d-flex flex-column align-items-center p-3 border-2">
                    <IconButton
                        size="small"
                        theme="white-flat"
                        icon="far fa-caret-up"
                        ariaLabel="move-upwards"
                        disabled={isFirst}
                        onClick={onMoveColumnUpClick}
                    />
                    <span className="fal fa-horizontal-rule" />
                    <IconButton
                        size="small"
                        theme="white-flat"
                        icon="far fa-caret-down"
                        ariaLabel="move-downwards"
                        disabled={isLast}
                        onClick={onMoveColumnDownClick}
                    />
                </div>
                <div className="p-3">
                    <IconButton size="large" theme="blue-flat" icon="far fa-trash" onClick={onRemoveFieldConfigClick} />
                </div>
            </div>
        </div>
    )
}

export default ExportFieldConfig
