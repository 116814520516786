import LegacyApp from "../../../microfrontends/legacy/LegacyApp"
import LegacySearchContainer from "../../../microfrontends/legacy/components/customerSearch/LegacySearchContainer"
import classes from "./Search.module.scss"
import { useLayout } from "../../../contexts/LayoutContext"

const Search = ({ className, store }) => {
    const { isLeftPanelExpanded } = useLayout()

    return (
        <div className={`${className ? className : ''} d-flex h-100 ${isLeftPanelExpanded ? 'w-100' : classes.sidebar} ${classes.search} bg-grey`}>
            <LegacyApp className="d-flex w-100 h-100" store={store}>
                <div className="d-flex w-100 h-100">
                    <LegacySearchContainer />
                </div>
            </LegacyApp>
        </div>
    )
}

export default Search