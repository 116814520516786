import {
  executeAuthAsyncGet,
  executeAsyncResetGet
} from '../utility/asyncSupport';
import { showToastErrorMessage } from '../api/toasterApi';

export const setTaskDocuments = (taskDocuments) => ({
  type: 'TASK_DOCUMENTS',
  taskDocuments
});

export const clearTaskDocuments = () => ({
  type: 'TASK_DOCUMENTS',
  taskDocuments: []
});

export const getTaskDocuments = (client, taskId) => (dispatch) => {
  if (!taskId) return dispatch(executeAsyncResetGet(['TASK_DOCUMENTS']));

  dispatch(
    executeAuthAsyncGet(
      client,
      'document/GetTaskDocuments',
      'TASK_DOCUMENTS',
      { taskId },
      (documents) => dispatch(setTaskDocuments(documents)),
      () => showToastErrorMessage('Error while loading Task documents.')
    )
  );
};
