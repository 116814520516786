import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import { cloneToVirtualParentAndAddParent, createVirtualParentAndAddParent, getVPTypes } from '../../api/vpApi';
import { setVATypeId, resetVACloned } from '../../actions/vpActions';
import { showToastSuccessMessage } from '../../api/toasterApi';
import StatefulSelect from '../common/statefulSelect';
import { vaTypesList } from '../../selectors/virtualParent';
import { useClient, useFeatureToggle } from 'invevo-react-components';

const CloneAsVP = ({
  selectedAccount,
  vaTypes,
  vaTypeId,
  vaCloned,
  vaClonedMsg,
  setVATypeId,
  getVPTypes,
  resetVACloned,
  cloneToVirtualParentAndAddParent,
  createVirtualParentAndAddParent,
  onComplete
}) => {
  const client = useClient()
  const [isFetching, setIsFetching] = useState(false)
  const [vaName, setVaName] = useState('')
  const [showVaNameInput, setShowVaNameInput] = useState(false)
  const { isEnabled: showCreateVpButton } = useFeatureToggle("createVirtualParentWithName")

  useEffect(() => {
    if (!client || isFetching) {
      if (vaTypes.length > 0) {
        setIsFetching(false)
      }

      return
    }

    if (isNil(vaTypes) || vaTypes.length === 0) {
      setIsFetching(true)
      getVPTypes(client);
    }
  }, [vaTypes, client, getVPTypes, isFetching]);

  useEffect(() => {
    if (vaCloned) {
      showToastSuccessMessage(vaClonedMsg);
      resetVACloned();
      onComplete();
    }
  }, [vaClonedMsg, vaCloned, resetVACloned, onComplete]);

  const cloneToVPAndAddParent = () => {
    const id = selectedAccount.miaAccountId;
    cloneToVirtualParentAndAddParent(
      client,
      id,
      selectedAccount.miaAccountIsVirtualAccount,
      vaTypeId
    );
  };

  const createVPAndAddParent = () => {
    const id = selectedAccount.miaAccountId;
    setVaName('')
    setShowVaNameInput(false)
    createVirtualParentAndAddParent(
      client,
      id,
      selectedAccount.miaAccountIsVirtualAccount,
      vaTypeId,
      vaName
    );
  };

  const canSubmit = () => !!vaTypeId;

  const onCreateVPClicked = () => setShowVaNameInput(!showVaNameInput)

  return (
    <div className="mx-1 my-2">
      <h3 className="mb-2">Create Parent</h3>
      <div className="d-flex justify-content-between">
        <div className="align-self-end">
          <h5 className="text-uppercase">Virtual parent type</h5>
          <StatefulSelect
            className="relationship"
            options={vaTypes}
            optionId={vaTypeId}
            onChange={setVATypeId}
            menuPlacement={'top'}
          />
        </div>
        <div className="align-self-end">
          <button
            onClick={cloneToVPAndAddParent}
            className="btn btn-default"
            disabled={!canSubmit}
          >
            <i className='fa-solid fa-plus me-2'></i>
            {/* <FontAwesomeIcon icon="plus" className="mr-2" /> */}
            Create VP as clone of Child
          </button>
        </div>
        {showCreateVpButton &&
          <>
            <div className="align-self-end">
              <button
                onClick={onCreateVPClicked}
                className="btn btn-default ms-2"
                disabled={!canSubmit}
              >
                <i className='fa-solid fa-plus me-2'></i>
                Create VP
              </button>
            </div>


            {showVaNameInput &&
              <div className="align-self-end">
                <input
                  type="text"
                  value={vaName}
                  onChange={event => setVaName(event.target.value)}
                  placeholder="Virtual Parent name"
                />
                <button
                  onClick={createVPAndAddParent}
                  className="btn btn-default ms-2"
                  disabled={!canSubmit}
                >
                  <i className='fa-solid fa-plus me-2'></i>
                  Save
                </button>
              </div>
            }
          </>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    vaTypes: vaTypesList(state.vpReducer.vaTypes.data),
    vaTypeId: state.vpReducer.vaTypeId,
    vaCloned: state.vpReducer.vaCloned,
    vaClonedMsg: state.vpReducer.vaClonedMsg,
    selectedAccount: state.currentSelectionReducer.selectedAccount
  };
};

export default connect(mapStateToProps, {
  cloneToVirtualParentAndAddParent,
  createVirtualParentAndAddParent,
  getVPTypes,
  setVATypeId,
  resetVACloned
})(CloneAsVP);
