const AggregationType = {
    MAX: "MAX",
    MIN: "MIN",
    AVERAGE: "AVERAGE",
    SUM: "SUM",
    COUNT: "COUNT",
    CARDINALITY: "CARDINALITY"
} as const

export default AggregationType
