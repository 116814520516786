import moment from 'moment';
import { frequencyIds } from './repaymentConstants';
import { roundAmount } from '../../utility/generalFunctions.js';

export function dateDifferenceInDays(startDate, endDate) {
  const mStartDate = moment(startDate);
  const mEndDate = moment(endDate);

  return mEndDate.diff(mStartDate, 'days');
}

export function findEndDate(
  startDate,
  frequencyId = frequencyIds.WEEKLY,
  numOfInstalments = 12
) {
  switch (frequencyId) {
    case frequencyIds.BIWEEKLY: {
      return moment(startDate).add((numOfInstalments - 1) * 2, 'w');
    }
    case frequencyIds.MONTHLY: {
      return moment(startDate).add(numOfInstalments - 1, 'M');
    }
    case frequencyIds.QUARTERLY: {
      return moment(startDate).add((numOfInstalments - 1) * 3, 'M');
    }
    default:
      return moment(startDate).add(numOfInstalments - 1, 'w');
  }
}

export function getInterestForThePeriod(
  annualInterest,
  frequencyId = frequencyIds.WEEKLY
) {
  switch (frequencyId) {
    case frequencyIds.BIWEEKLY: {
      return annualInterest / 26;
    }
    case frequencyIds.MONTHLY: {
      return annualInterest / 12;
    }
    case frequencyIds.QUARTERLY: {
      return annualInterest / 3;
    }
    default:
      return annualInterest / 52;
  }
}

export function calculateInterestBetweenTwoDates(
  amount,
  yearlyInterestRate,
  startDate,
  endDate
) {
  // TODO -  Do we have to consider compound interests?
  return Math.max(
    amount *
      (yearlyInterestRate / 365) *
      dateDifferenceInDays(startDate, endDate),
    0
  );
}

// Formulas taken from https://it.wikipedia.org/wiki/Ammortamento_a_rate_costanti
export function getConstantInstalment(
  amount,
  interestRateForPeriod,
  noOfPayments
) {
  if (interestRateForPeriod <= 0) {
    return amount / noOfPayments;
  }

  return (
    amount *
    (interestRateForPeriod /
      (1 - 1 / Math.pow(1 + interestRateForPeriod, noOfPayments)))
  );
}

export function PMT(rate, periods, present, future, type) {
  future = future || 0;
  type = type || 0;

  // Return payment
  var result;
  if (rate === 0) {
    result = (present + future) / periods;
  } else {
    var term = Math.pow(1 + rate, periods);
    if (type === 1) {
      result =
        ((future * rate) / (term - 1) + (present * rate) / (1 - 1 / term)) /
        (1 + rate);
    } else {
      result = (future * rate) / (term - 1) + (present * rate) / (1 - 1 / term);
    }
  }
  return -result;
}

export function IPMT(rate, period, periods, present, future, type) {
  future = future || 0;
  type = type || 0;

  // Compute payment
  var payment = PMT(rate, periods, present, future, type);

  // Compute interest
  var interest;
  if (period === 1) {
    if (type === 1) {
      interest = 0;
    } else {
      interest = -present;
    }
  } else {
    if (type === 1) {
      interest = FV(rate, period - 2, payment, present, 1) - payment;
    } else {
      interest = FV(rate, period - 1, payment, present, 0);
    }
  }

  // Return interest
  return interest * rate;
}

export function PPMT(rate, period, periods, present, future, type) {
  future = future || 0;
  type = type || 0;

  return (
    PMT(rate, periods, present, future, type) -
    IPMT(rate, period, periods, present, future, type)
  );
}

function FV(rate, periods, payment, value, type) {
  value = value || 0;
  type = type || 0;

  // Return future value
  var result;
  if (rate === 0) {
    result = value + payment * periods;
  } else {
    var term = Math.pow(1 + rate, periods);
    if (type === 1) {
      result = value * term + (payment * (1 + rate) * (term - 1)) / rate;
    } else {
      result = value * term + (payment * (term - 1)) / rate;
    }
  }
  return -result;
}

export function residualDebitAtInstalment(
  instalmentAmount,
  interestRateForPeriod,
  noOfPayments,
  currentInstalment
) {
  if (interestRateForPeriod <= 0) {
    return instalmentAmount * (noOfPayments - currentInstalment);
  }

  return (
    (instalmentAmount / interestRateForPeriod) *
    (1 -
      1 / Math.pow(1 + interestRateForPeriod, noOfPayments - currentInstalment))
  );
} // For currentInstalment = 1, 2, ..., noOfPayments - 1

export function residualInterestAtInstalment(
  instalment,
  interestRateForPeriod,
  noOfPayments,
  currentInstalment
) {
  if (interestRateForPeriod <= 0) {
    return 0;
  }

  return (
    instalment *
    (1 -
      1 /
        Math.pow(
          1 + interestRateForPeriod,
          noOfPayments - currentInstalment + 1
        ))
  );
} // For currentInstalment = 1, 2, ..., noOfPayments - 1

export function roundCurrency(amount) {
  return roundAmount(amount, 2);
}

export function shiftWeekDay(startDate, weekday) {
  const mstart = moment(startDate);

  if (mstart.isoWeekday() <= weekday) {
    const d = mstart.isoWeekday(weekday);

    return d.toDate();
  } else {
    const mnext = mstart.add(1, 'weeks');
    const isoday = mnext.isoWeekday(weekday);

    return isoday.toDate();
  }
}
export function shiftMonthDay(startDate, requestedDay) {
  const mstart = moment(startDate);
  const day = mstart.date();

  if (requestedDay <= 28) {
    if (day <= requestedDay) {
      const d = mstart.date(requestedDay);
      return d.toDate();
    } else {
      const d = mstart.add(1, 'months');
      const d2 = d.date(requestedDay);
      return d2.toDate();
    }
  } else {
    const d = mstart.endOf('month');
    return d.toDate();
  }
}

export function shiftDate(
  frequencyId = frequencyIds.WEEKLY,
  startDate,
  paymentDayId
) {
  switch (frequencyId) {
    case frequencyIds.BIWEEKLY: {
      return shiftWeekDay(startDate, paymentDayId);
    }
    case frequencyIds.MONTHLY: {
      return shiftMonthDay(startDate, paymentDayId);
    }
    case frequencyIds.QUARTERLY: {
      return shiftMonthDay(startDate, paymentDayId);
    }
    default:
      return shiftWeekDay(startDate, paymentDayId);
  }
}

export function termToInstalments(
  frequencyId = frequencyIds.WEEKLY,
  startDate,
  termMonths
) {
  const mStart = moment(startDate);
  const mEnd = moment(startDate).add(termMonths, 'months');
  const weeks = Math.ceil(mEnd.diff(mStart, 'w', true));

  switch (frequencyId) {
    case frequencyIds.BIWEEKLY: {
      if (weeks % 2 === 0) {
        return weeks / 2;
      } else {
        return (weeks + 1) / 2;
      }
    }
    case frequencyIds.MONTHLY: {
      return termMonths;
    }
    case frequencyIds.QUARTERLY: {
      return termMonths / 3;
    }
    default:
      return weeks;
  }
}

export function paymentToInstalments(amount, paymentAmount) {
  throw new Error('todo');
}
