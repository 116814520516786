import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"
import { StripItems } from "../customer/dashboardStrip/StripItems"
import { Entity, useEntity } from "../../../../contexts/EntityContext"
import { useUser } from "../../../../contexts/UserContext"
import { PutDashboardConfigDto } from "../../types/DashboardConfigDto"
import DropdownOption from "../../../../types/DropdownOptions"
import { ReactNode } from "react"
import Toggle from "../../../../library/toggle/Toggle"
import classes from "./CustomerDashboard.module.scss"
import Dropdown from "../../../../library/dropdowns/Dropdown"

type Dashboard = PutDashboardConfigDto & { id: string }

type DashboardMenuProps = {
    entityData: Entity
    onStripItemSelected: (selectedItem: string) => void
    dashboards?: Dashboard[]
    selectedDashboard?: string
    isNewEntityStructureEnabled: boolean
    showingGroups: boolean
    onShowGroupsToggled: () => void
    fullScreenButton: ReactNode
    hasLightBackground: boolean
}

const DashboardMenu = ({
    entityData,
    onStripItemSelected,
    dashboards,
    selectedDashboard,
    isNewEntityStructureEnabled = false,
    showingGroups,
    onShowGroupsToggled,
    fullScreenButton,
    hasLightBackground
}: DashboardMenuProps) => {
    const { isEnabled: isOldUxEnabled } = useFeatureToggle("showOldUx")
    const { isEnabled: isLegacyDashboardsInVerticalStripEnabled } = useFeatureToggle("showLegacyDashboardsInVerticalStrip")
    const { isEnabled: showLegacyTransactionsGrid } = useFeatureToggle("showLegacyTransactionsGrid")
    const { isEnabled: showLegacyCustomerDashboard } = useFeatureToggle("permShowSqlAccountSummaryDashboardSqlToEntity")
    const { isEnabled: permShowEntityGroupNavSqlToEntity } = useFeatureToggle("permShowEntityGroupNavSqlToEntity")

    const [entity] = useEntity()
    const user = useUser()
    const enabledRoles = user.userRoles.filter(r => r.enabled).map(r => r.reference)
    const adminRoleRef = user.userRoles.find(r => r.roleName.toLowerCase() === "admin" || r.roleName.toLowerCase() === "administrator")?.reference ?? ""

    const dashboardsToShow =
        dashboards?.filter(
            config =>
                config.roleReferences.length === 0 || config.roleReferences.some((r: string) => enabledRoles.includes(r)) || enabledRoles.includes(adminRoleRef)
        ) ?? []

    const isCustomerEntity = !!entity.sqlId
    if (isCustomerEntity && entity.entityType !== "customer" && entity.entityType !== "virtual_parent") {
        console.warn("Entity has sqlId but is not a customer entity type")
    }

    const isCompatibleWithLegacyDashboards = isCustomerEntity || !isNewEntityStructureEnabled
    const shouldShowCustomerDashboard = !isLegacyDashboardsInVerticalStripEnabled && showLegacyCustomerDashboard
    const getLegacyDashboardsToDisplay = () => {
        if (!isCompatibleWithLegacyDashboards) return []
        if (isLegacyDashboardsInVerticalStripEnabled) {
            return [
                { label: "Customer Summary", value: StripItems.LEGACY_CUSTOMER_SUMMARY },
                { label: "Files", value: StripItems.LEGACY_FILES },
                { label: "Contacts", value: StripItems.LEGACY_CONTACTS },
                ...(showLegacyTransactionsGrid ? [{ label: "Transactions", value: StripItems.LEGACY_TRANSACTIONS }] : [])
            ]
        }
        if (shouldShowCustomerDashboard) {
            return [
                { label: "Customer Summary", value: StripItems.LEGACY_CUSTOMER_SUMMARY },
                ...(showLegacyTransactionsGrid ? [{ label: "Transactions", value: StripItems.LEGACY_TRANSACTIONS }] : [])
            ]
        }
        return []
    }

    const dashboardOptionsNewUx: DropdownOption<string>[] = [...getLegacyDashboardsToDisplay(), ...dashboardsToShow.map(d => ({ label: d.name, value: d.id }))]
    const dashboardOptionsOldUx = [{ label: "Customer Summary", value: StripItems.LEGACY_CUSTOMER_SUMMARY }]
    const dashboardOptions =
        !isNewEntityStructureEnabled && (isOldUxEnabled || !isLegacyDashboardsInVerticalStripEnabled) ? dashboardOptionsOldUx : dashboardOptionsNewUx

    const onDropdownOptionSelected = (opt?: DropdownOption<string>) => onStripItemSelected(opt?.value ?? "")

    return (
        <div
            className={`d-flex flex-row flex-grow-1 mx-4 my-2 fs-3 ${hasLightBackground ? "text-grey" : "text-white"} fw-lighter align-items-center ${
                classes.header
            }`}
        >
            <div className="d-flex flex-row gap-2 align-items-center">
                <span className="mx-2">{(entityData.entityFields?.textFields as any)?.text_name ?? entityData.reference}</span>
                {dashboardOptions.length > 0 && (
                    <Dropdown
                        options={dashboardOptions}
                        onOptionSelected={onDropdownOptionSelected}
                        selectedOption={dashboardOptions.find(o => o.value === selectedDashboard)}
                        ariaLabel="dashboard-options"
                    />
                )}
            </div>
            {permShowEntityGroupNavSqlToEntity && (
                <div className="d-flex align-items-center ms-auto me-3">
                    <Toggle className="ms-4" status={showingGroups} onStatusChanged={onShowGroupsToggled} ariaLabel="show-groups" />
                    <span className="ms-2 fs-6">Show Groups</span>
                </div>
            )}
            {fullScreenButton}
        </div>
    )
}

export default DashboardMenu
