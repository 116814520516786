import { FieldsUpdate } from "../../../library/common"

export type WorkflowTriggerConfig = {
    reference: string
    triggeredByReference: string
    triggeredByName: string
    triggeredByType: "WORKFLOW_RULE" | "FILE_IMPORT"
    ruleToTriggerReference: string
    ruleToTriggerName: string
}

export const getWorkflowTriggerConfigDtos = (originalConfigs: WorkflowTriggerConfig[], updatedConfigs: WorkflowTriggerConfig[]): FieldsUpdate[] => {
    const configDtos = updatedConfigs.map(updatedConfig => ({
        reference: updatedConfig.reference,
        entityTypeReference: "config_workflow_trigger",
        booleanFields: [{ fieldName: "boolean_archived", fieldValue: false }],
        stringFields: [
            { fieldName: "text_triggered_by_job_type", fieldValue: updatedConfig.triggeredByType },
            { fieldName: "text_triggered_by_reference", fieldValue: updatedConfig.triggeredByReference },
            { fieldName: "text_triggered_by_name", fieldValue: updatedConfig.triggeredByName },
            { fieldName: "text_rule_to_trigger_reference", fieldValue: updatedConfig.ruleToTriggerReference },
            { fieldName: "text_rule_to_trigger_name", fieldValue: updatedConfig.ruleToTriggerName }
        ],
        numberFields: [],
        dateTimeFields: []
    }))

    const archivedConfigDtos = originalConfigs
        .filter(originalConfig => !updatedConfigs.some(updatedConfig => updatedConfig.reference === originalConfig.reference))
        .map(archivedConfig => ({
            reference: archivedConfig.reference,
            entityTypeReference: "config_workflow_trigger",
            booleanFields: [{ fieldName: "boolean_archived", fieldValue: true }],
            stringFields: [],
            numberFields: [],
            dateTimeFields: []
        }))

    return [...configDtos, ...archivedConfigDtos]
}