import { FileImportConfigColumnTypeState } from "../../../../reducers/FileImportConfigReducer"
import Column from "./ImportColumn"

type ColumnTypeConfigProps = {
    columns: FileImportConfigColumnTypeState[]
    onChange: (columns: FileImportConfigColumnTypeState[]) => any
}

const ColumnTypeConfig = ({ columns, onChange }: ColumnTypeConfigProps) => {
    const onColumnChange = (updatedColumn: FileImportConfigColumnTypeState) => {
        onChange(
            columns.map(column => {
                if (column.key !== updatedColumn.key) {
                    return column
                }

                return {
                    ...updatedColumn
                }
            })
        )
    }

    const onColumnDelete = (columnToDelete: FileImportConfigColumnTypeState) => {
        onChange(columns.filter(column => column.key !== columnToDelete.key))
    }

    return (
        <div className="d-flex w-100 h-100">
            <div className="d-flex flex-column flex-grow-1">
                {columns.length > 0 &&
                    columns
                        .map(column => <Column key={column.key} column={column} onChange={onColumnChange} onDelete={onColumnDelete} />)
                        .reduce((prev, curr) => (
                            <>
                                {prev}
                                <span className="my-2" />
                                {curr}
                            </>
                        ))}
            </div>
        </div>
    )
}

export default ColumnTypeConfig
