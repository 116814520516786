import { useLayoutEffect, useRef, useState } from "react"
import { EntityGroupDto } from "./types/EntityGroupDto"
import classes from "./EntityGroupHierarchy.module.scss"
import { useEntity } from "../../../../../contexts/EntityContext"
import { convertDtoFieldsToDatafields } from "../../../../../types/EntityData"

type EntityGroupHierarchyProps = {
    entityGroup: EntityGroupDto
    selectedEntityReference: string
}

type EntityGroupProps = {
    entityGroup: EntityGroupDto
    selectedEntityReference: string
    className?: string | undefined
}

const ROOT_ENTITY_TYPE_NAME = "ROOT"
const ROOT_ENTITY_REFERENCE = "ROOT"

const EntityGroup = ({ className, entityGroup, selectedEntityReference }: EntityGroupProps) => {
    const [isExpanded, setIsExpanded] = useState(true)
    const { entityReference, entityTypeName, entity, children } = entityGroup
    const topOfEntityGroupChildrenRef = useRef<HTMLDivElement>(null)
    const lastChildInGroupRef = useRef<HTMLDivElement>(null)
    const [verticalLineDistance, setVerticalLineDistance] = useState(0)
    const [, setEntity] = useEntity()
    const childEntityTypes = entityGroup.children.map(child => child.entityTypeName).filter((value, index, array) => array.indexOf(value) === index)
    const [selectedChildEntityType, setSelectedChildEntityType] = useState<string | undefined>()

    const setVerticalLine = () => {
        const distance = getVerticalDistanceForHierarchyLine(topOfEntityGroupChildrenRef.current, lastChildInGroupRef.current)
        setVerticalLineDistance(distance + 1) // plus 1 here due to the horizontal line border of the last child entity group
    }

    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded)
        setVerticalLine()
    }

    const onSelectEntityClick = () => {
        if (selectedEntityReference === entityReference) {
            return
        }

        setEntity({ reference: entityReference, entityType: entity.entityTypeReference, entityFields: convertDtoFieldsToDatafields(entity.entityFields) })
        setVerticalLine()
    }

    useLayoutEffect(() => {
        setVerticalLine()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topOfEntityGroupChildrenRef.current, lastChildInGroupRef.current])

    //fix bug here as selectedChildEntity is undefined to start with... so if undefined then set currentIndex to be ZERO
    const onPreviousEntityTypeClick = () => {
        const currentIndex = childEntityTypes.findIndex(value => value === selectedChildEntityType)
        if (currentIndex > 0) {
            setSelectedChildEntityType(childEntityTypes[currentIndex - 1])
            return
        }

        setSelectedChildEntityType(childEntityTypes[childEntityTypes.length - 1])
        setVerticalLine()
    }

    const onNextEntityTypeClick = () => {
        const currentIndex = childEntityTypes.findIndex(value => value === selectedChildEntityType)
        if (currentIndex < childEntityTypes.length - 1) {
            setSelectedChildEntityType(childEntityTypes[currentIndex + 1])
            return
        }

        setSelectedChildEntityType(childEntityTypes[0])
        setVerticalLine()
    }

    const getSelectedChildEntityType = () => {
        return selectedChildEntityType ?? childEntityTypes[0]
    }

    return (
        <div className={`d-flex flex-column ${className ? className : ""}`}>
            {entityReference !== ROOT_ENTITY_REFERENCE && entityTypeName !== ROOT_ENTITY_TYPE_NAME && (
                <div className="d-flex align-items-center">
                    <div className="d-flex flex-column align-self-stretch">
                        <div className="flex-grow-1" />
                        {children.length > 0 && (
                            <div
                                className={`d-flex bg-blue ps-2 pe-1 py-1 ${classes.expander} pointer ${children.length > 0 ? "" : "invisible"}`}
                                onClick={toggleIsExpanded}
                            >
                                <i className={`far ${isExpanded ? "fa-minus" : "fa-plus"} text-white m-auto`}></i>
                            </div>
                        )}
                        <div className={`flex-grow-1 ${isExpanded && children.length > 0 ? classes["expander-line"] : ""}`} />
                    </div>
                    <div className="d-flex py-1 flex-grow-1">
                        <div
                            className={`d-flex p-2 bg-white flex-grow-1 pointer align-items-center text-grey ${
                                selectedEntityReference === entityReference ? classes["selected-border"] : ""
                            }`}
                            onClick={onSelectEntityClick}
                        >
                            {children.length === 0 && (
                                <div className="d-flex bg-grey p-1 me-2">
                                    <i className="fa-regular fa-building"></i>
                                </div>
                            )}
                            <span className="fw-bold fs-6">
                                {entityTypeName}: {entity.entityFields.textFields["text_name"] ?? entity.reference}
                            </span>
                            {children.length > 0 && <i className="ms-auto fas fa-layer-group"></i>}
                        </div>
                    </div>
                </div>
            )}
            {isExpanded && children.length > 0 && (
                <div className="d-flex align-self-stretch flex-column">
                    {childEntityTypes.length > 1 && (
                        <div className="d-flex my-1">
                            <div className={`d-flex bg-blue p-2 ${classes["left-arrow-button"]} pointer`} onClick={onPreviousEntityTypeClick}>
                                <i className="m-auto fal fa-arrow-left text-white"></i>
                            </div>
                            <div className="d-flex flex-grow-1 bg-white">
                                <span className="d-flex m-auto">{getSelectedChildEntityType()}</span>
                            </div>
                            <div className={`d-flex bg-blue p-2 ${classes["right-arrow-button"]} pointer`} onClick={onNextEntityTypeClick}>
                                <i className="m-auto fal fa-arrow-right text-white"></i>
                            </div>
                        </div>
                    )}
                    <div ref={topOfEntityGroupChildrenRef} className="d-flex align-self-stretch">
                        {entityReference !== ROOT_ENTITY_REFERENCE && entityTypeName !== ROOT_ENTITY_TYPE_NAME && (
                            <div className={`${classes["vertical-line"]}`} style={{ height: `${verticalLineDistance}px` }} />
                        )}
                        <div className="d-flex flex-column flex-grow-1">
                            {children
                                .filter(c => c.entityTypeName === getSelectedChildEntityType())
                                .map((child, index) => (
                                    <div key={child.entity.reference} className="d-flex flex-column">
                                        <div className={`d-flex align-items-center`}>
                                            {entityReference !== ROOT_ENTITY_REFERENCE && entityTypeName !== ROOT_ENTITY_TYPE_NAME && (
                                                <div
                                                    ref={
                                                        index === children.filter(c => c.entityTypeName === getSelectedChildEntityType()).length - 1
                                                            ? lastChildInGroupRef
                                                            : null
                                                    }
                                                    className={`d-flex align-items-center ${child.children.length > 0 ? "align-self-start mt-4" : ""}`}
                                                >
                                                    <div className={`${classes["horizontal-line"]} `} />
                                                </div>
                                            )}
                                            <EntityGroup className="flex-grow-1" entityGroup={child} selectedEntityReference={selectedEntityReference} />
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const getVerticalDistanceForHierarchyLine = (topElement: HTMLDivElement | null, bottomElement: HTMLDivElement | null) => {
    if (!topElement || !bottomElement) {
        return 0
    }
    const rect1 = topElement.getBoundingClientRect()
    const rect2 = bottomElement.getBoundingClientRect()
    const centerY2 = rect2.y + rect2.height / 2
    const dy = rect1.y - centerY2
    return Math.abs(dy)
}

const EntityGroupHierarchy = ({ entityGroup, selectedEntityReference }: EntityGroupHierarchyProps) => {
    return <EntityGroup entityGroup={entityGroup} selectedEntityReference={selectedEntityReference} />
}

export default EntityGroupHierarchy
