import { Input, useApi, useClient, useConfig, Collapsable } from "invevo-react-components"
import classes from "./ActionPanel.module.scss"
import Widgets from "./widgets/Widgets"
import { actionTypes } from "../../reducers/actionTypes"
import { mapDashboardConfigToPutDto } from "../../helpers/dashboardMapping"
import { dashboardSubTypes } from "../../types/DashboardTypes"
import Dropdown from "../../../../library/dropdowns/Dropdown"
import ProgressButton from "../../../../library/buttons/ProgressButton/ProgressButton"
import FilterList from "../../../../library/FilterList/FilterList"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import { DashboardConfigAction, DashboardConfigCustomerEntityConfig, DashboardConfigEntityConfig } from "../../reducers/dashboardConfigsReducer"
import DashboardConfigState from "../../types/DashboardConfigState"
import Lookup from "../../../../types/Lookup"
import DropdownOption from "../../../../types/DropdownOptions"
import GenericFilter from "../../../../library/FilterList/filterTypes/GenericFilter"
import { useOverlay } from "../../../../contexts/overlay/OverlayContext"
import DuplicateDashboardConfigForm from "./DuplicateDashboardConfig"

type ActionPanelProps = {
    className?: string
    state: {
        editingConfig: DashboardConfigState
        originalConfig: DashboardConfigState | undefined
        showValidationErrors: boolean
        entityConfigs: DashboardConfigEntityConfig[]
        dashboardConfigs: DashboardConfigState[]
        isNewEntityStructureEnabled: boolean
        isAggregatedDashboard: boolean
        lookups: Lookup[]
        customerFields: DashboardConfigCustomerEntityConfig[]
    }
    dispatch: React.Dispatch<DashboardConfigAction>
    onSave: () => void
    onDelete: () => void
    onDuplicate: (editingConfig: DashboardConfigState) => void
}

const ActionPanel = ({ className, state, dispatch, onSave, onDelete, onDuplicate }: ActionPanelProps) => {
    const {
        editingConfig,
        originalConfig,
        showValidationErrors,
        entityConfigs,
        dashboardConfigs,
        isNewEntityStructureEnabled,
        isAggregatedDashboard,
        lookups,
        customerFields
    } = state

    const api = useApi()
    const client = useClient()
    const config = useConfig()
    const overlay = useOverlay()

    const dashboardSubtypeDropdownOptions = [
        ...Object.keys(dashboardSubTypes).map(d => {
            return { value: d, label: d } as DropdownOption<dashboardSubTypes>
        })
    ]

    const entityConfigDropdownOptions = entityConfigs.map(config => {
        return { value: config.reference, label: config.displayName }
    })

    const canSave = JSON.stringify(editingConfig) !== JSON.stringify(originalConfig)

    const canDelete = dashboardConfigs.some(config => config.reference === editingConfig.reference)

    const canDuplicate = dashboardConfigs.some(config => config.reference === editingConfig.reference)

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: actionTypes.DASHBOARD_NAME_CHANGED, newName: event.target.value })

    const onDropdownValueChange = (option: { value: dashboardSubTypes }) => dispatch({ type: actionTypes.DASHBOARD_SUBTYPE_CHANGED, newSubType: option.value })

    const onEntityConfigValueChange = (option: { value: string }) => {
        dispatch({ type: actionTypes.DASHBOARD_ENTITY_CONFIG_CHANGED, entityConfigReference: option.value })
    }

    const onFiltersChange = (filterList: GenericFilter[]) => {
        dispatch({ type: actionTypes.DASHBOARD_FILTERS_CHANGED, filters: filterList })
    }

    const onDeleteClick = () => api.delete(`${config.DATA_API_URL}/api/${client}/dashboard-config/${editingConfig.reference}`).then(onDelete)

    const onSaveClick = () => {
        const isValid = isValidConfig(editingConfig)
        if (!isValid) {
            dispatch({ type: actionTypes.DASHBOARD_CHANGES_ARE_INVALID })
            return Promise.reject()
        }

        dispatch({ type: actionTypes.DASHBOARD_CHANGES_ARE_VALID })

        const dto = mapDashboardConfigToPutDto(editingConfig, isNewEntityStructureEnabled, isAggregatedDashboard)
        return api.put(`${config.DATA_API_URL}/api/${client}/dashboard-config/${editingConfig.reference}`, dto).then(onSave())
    }

    const onDashboardConfigDuplicated = (dashboardConfig: DashboardConfigState | undefined) => dashboardConfig && onDuplicate(dashboardConfig)

    const onDuplicateClick = () => {
        overlay.showOverlay(
            <DuplicateDashboardConfigForm
                dashboardConfig={editingConfig}
                isDuplicatingAll={false}
                existingDashboardConfigNames={dashboardConfigs.map(config => config.name)}
                isNewEntityStructureEnabled={isNewEntityStructureEnabled}
                isAggregatedDashboard={isAggregatedDashboard}
                onDashboardConfigDuplicated={onDashboardConfigDuplicated}
                onClose={overlay.closeOverlay}
            />
        )
    }

    const getFilterFields = () => {
        if (!isNewEntityStructureEnabled) return customerFields

        return entityConfigs.find(config => config.reference === state.editingConfig.entityConfigReference)?.fields ?? []
    }

    return (
        <div className={`d-flex ${className ? className : ""} h-100 bg-blue`}>
            <div className={`d-flex ${classes.panel} flex-column h-100 p-3`}>
                <Input
                    label="dashboard name"
                    placeholder="Please enter name"
                    value={state.editingConfig.name}
                    onChange={onNameChange}
                    isValid={!showValidationErrors || state.editingConfig.name !== ""}
                />
                {isNewEntityStructureEnabled ? (
                    <Dropdown
                        ariaLabel="context-entity-config-dropdown"
                        className="my-3"
                        options={entityConfigDropdownOptions}
                        onOptionSelected={onEntityConfigValueChange}
                        selectedOption={entityConfigDropdownOptions.find(o => o.value === state.editingConfig.entityConfigReference)}
                        textAlign="left"
                        colour="blue"
                    />
                ) : (
                    <Dropdown
                        className="mt-3"
                        options={dashboardSubtypeDropdownOptions}
                        onOptionSelected={onDropdownValueChange}
                        selectedOption={
                            dashboardSubtypeDropdownOptions.find(o => o.value === state.editingConfig.subType) ||
                            dashboardSubtypeDropdownOptions.find(o => o.value === dashboardSubTypes.NONE)
                        }
                        textAlign="left"
                        colour="blue"
                    />
                )}

                <div className="overflow-auto h-100 pb-3">
                    {!isAggregatedDashboard && isNewEntityStructureEnabled && (
                        <Collapsable title="Entity Filters" colour="blue" >
                            <div className={`p-3 ${classes["filter-container"]}`}>
                                <FilterList
                                    fields={getFilterFields()}
                                    lookups={lookups}
                                    disabled={!state.editingConfig.entityConfigReference && isNewEntityStructureEnabled}
                                    appliedFilters={state.editingConfig.filters}
                                    onFiltersApplied={onFiltersChange}
                                    isCompact={true}
                                    autoApply={true}
                                    colour="blue"
                                    showRelativeDateOptions={true}
                                />
                            </div>
                        </Collapsable>
                    )}
                    <Widgets className="mb-3 overflow-auto" dispatch={dispatch} isAggregatedDashboard={isAggregatedDashboard} isNewEntityStructureEnabled={isNewEntityStructureEnabled} />

                </div>
                <div className="d-flex flex-column mt-auto">
                    <StandardButton
                        className="mb-3"
                        colour="blue"
                        label="Duplicate dashboard"
                        iconClasses="fal fa-clone"
                        ariaLabel="duplicate-dashboard"
                        onClick={onDuplicateClick}
                        disabled={!canDuplicate}
                    />
                    <ProgressButton
                        colour="blue"
                        label="Delete dashboard"
                        iconClasses="fal fa-trash-alt"
                        onClickWithPromise={onDeleteClick}
                        succeededText="Deleted successfully"
                        disabled={!canDelete}
                    />
                    <ProgressButton
                        className="mt-3"
                        colour="blue"
                        label="Save dashboard"
                        iconClasses="fal fa-save"
                        onClickWithPromise={onSaveClick}
                        succeededText="Saved successfully"
                        disabled={!canSave}
                    />
                </div>
            </div>
        </div>
    )
}

const isValidConfig = (config: DashboardConfigState) => {
    return (
        config.name !== "" &&
        config.infoWidgets.every(widget => widget.displayName !== "" && widget.fields.length > 0 && widget.fields.every((f: any) => f.fieldName !== "")) &&
        config.valueWidgets.every((widget: any) => widget.displayName !== "" && widget.field.fieldName !== "") &&
        config.transactionsWidgets.every(widget => widget.displayName !== "" && widget.columns.every(c => c.fieldName !== "")) &&
        config.smallBarChartWidgets.every(widget => widget.displayName !== "") &&
        config.areaChartWidgets.every(widget => widget.displayName !== "") &&
        config.lineChartWidgets.every(widget => widget.displayName !== "") &&
        config.smallPieChartWidgets.every(widget => widget.displayName !== "") &&
        config.entityChartWidgets.every(widget => widget.displayName !== "")
    )
}

export default ActionPanel
