import { toast } from 'react-toastify';

export const showToastSuccessMessage = (text, options) =>
  toast.success(text, options);
export const showToastErrorMessage = (text, options) =>
  toast.error(text, options);
export const showToastInfoMessage = (text, options) =>
  toast.info(text, options);
export const showToastWarningMessage = (text, options) =>
  toast.warn(text, options);
