import { useEffect } from "react"
import ActionPanel from "./ActionPanel"
import DashboardEditor from "./DashboardEditor"
import { v4 as uuidv4 } from "uuid"
import { actionTypes } from "../../reducers/actionTypes"
import { dashboardTypes } from "../../types/DashboardTypes"
import { DashboardConfigAction, DashboardConfigsState } from "../../reducers/dashboardConfigsReducer"

type EntityDashboardConfigProps = {
    state: DashboardConfigsState
    dispatch: React.Dispatch<DashboardConfigAction>
    onDeleted: ()=> void
    isNewEntityStructureEnabled: boolean
}

const EntityDashboardConfig = ({ state, dispatch, onDeleted, isNewEntityStructureEnabled }: EntityDashboardConfigProps) => {
    useEffect(() => {
        if (state.selectedDashboardType === dashboardTypes.AGGREGATED) {
            dispatch({ type: actionTypes.NEW_AGGREGATED_DASHBOARD_CONFIG_INITIALISED, reference: uuidv4() })
        } else {
            dispatch({ type: actionTypes.NEW_DASHBOARD_CONFIG_INITIALISED, reference: uuidv4() })
        }
    }, [dispatch, state.selectedDashboardType])

    const onSave = () => {
        if (state.dashboardConfigs.some(config => config.reference === state.editingConfig.reference)) {
            dispatch({ type: actionTypes.DASHBOARD_CONFIG_SAVED, dashboardConfig: state.editingConfig })
            return
        }

        dispatch({ type: actionTypes.DASHBOARD_CONFIG_ADDED, dashboardConfig: state.editingConfig })
    }

    const onDelete = () => {
        dispatch({ type: actionTypes.DASHBOARD_CONFIG_DELETED, dashboardConfigReference: state.editingConfig.reference })
        onDeleted()
    }

    const isAggregatedDashboard = state.selectedDashboardType === dashboardTypes.AGGREGATED

    return (
        <div className="d-flex h-100 overflow-auto" key={state.editingConfig.reference}>
            <DashboardEditor
                state={{
                    editingConfig: state.editingConfig,
                    customerFields: state.customerFields,
                    transactionFields: state.transactionFields,
                    showValidationErrors: state.showValidationErrors,
                    selectedContextEntityConfig: state.entityConfigs.find(config => config.reference === state.editingConfig.entityConfigReference),
                    entityConfigs: state.entityConfigs,
                    isNewEntityStructureEnabled: isNewEntityStructureEnabled,
                    isAggregatedDashboard: isAggregatedDashboard,
                    lookups: state.lookups
                }}
                dispatch={dispatch}
            />
            <ActionPanel
                state={{
                    editingConfig: state.editingConfig,
                    originalConfig: state.originalConfig,
                    showValidationErrors: state.showValidationErrors,
                    dashboardConfigs: state.dashboardConfigs,
                    entityConfigs: state.entityConfigs,
                    isNewEntityStructureEnabled: isNewEntityStructureEnabled,
                    isAggregatedDashboard: isAggregatedDashboard,
                    lookups: state.lookups,
                    customerFields: state.customerFields
                }}
                dispatch={dispatch}
                onSave={onSave}
                onDelete={onDelete}
                onDuplicate={()=> {}}
            />
        </div>
    )
}

export default EntityDashboardConfig
