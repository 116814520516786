import NavigationButton from "../../library/buttons/NavigationButton/NavigationButton"

const UserRolesNav = () => {
    return (
        <div className="d-flex justify-content-center align-items-center w-100">
            <NavigationButton route="/home/roles" label="Roles" icon="fa fa-id-card-clip" ariaLabel="roles" colour="#ebe996" />
            <NavigationButton route="/home/new-users" label="Client users" icon="fa fa-users" ariaLabel="client-users" colour="#b3dfe6" />
        </div>
    )
}

export default UserRolesNav
