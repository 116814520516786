import React, { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLookup } from '../../api/lookupApi.js';
import StatefulSelect from '../common/statefulSelect';
import { usersSelectList } from '../../selectors/task';
import { useClient } from 'invevo-react-components';

const UsersSelect = ({
    ids,
    onChange,
    getLookup,
    customerId, isVirtualAccount,
    roleIds,
    users,
    lookupDefault = false
}) => {
    const client = useClient()
    useDeepCompareEffect(() => {
        if (customerId)
            getLookup(client, 'lookup/users', 'users', {
                accountId: customerId,
                isvirtualAccount: isVirtualAccount,
                roleIds
            });
    }, [customerId, isVirtualAccount, roleIds]);

    const [initDefault, setInitDefault] = useState(false);
    useDeepCompareEffect(() => {
        if (lookupDefault && ids.length === 0 && users.length > 0 && !initDefault) {
            const defaultUser = users.find((u) => u.isDefault === true);
            if (defaultUser) {
                onChange([defaultUser.value]);
            }
            setInitDefault(true);
        }
    }, [lookupDefault, users, ids, getLookup]);

    return (
        <StatefulSelect
            options={users}
            optionIds={ids}
            onChange={onChange}
            isMulti={true}
            placeholder={'Please select user(s)'}
        />
    );
};

UsersSelect.propTypes = {
    id: PropTypes.number,
    roleIds: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    users: usersSelectList(state.lookupReducer.users.results),
});

export default connect(mapStateToProps, { getLookup })(UsersSelect);
