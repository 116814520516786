import classes from "./GridDisplayWidget.module.scss"
import Lookup from "../../../../../../types/Lookup"
import EntityConfig from "../../../../../../routes/entityConfig/types/EntityConfig"
import { convertDtoFieldsToDatafields } from "../../../../../../types/EntityData"
import GridColumnHeaderConfig from "../../../../../../library/dataGridV2/types/GridColumnHeaderConfig"
import GenericFilter from "../../../../../../library/FilterList/filterTypes/GenericFilter"
import FiltersDto, { convertFromArrayToDto, convertFromDtoToArray } from "../../../../../../library/FilterList/FiltersDto"
import { Entity } from "../../../../../../contexts/EntityContext"
import EntityRelationship from "../../../../../../routes/entityConfig/types/EntityRelationship"
import Grid from "../../../../../../library/grid/Grid"
import RowData from "../../../../../../library/grid/types/RowData"
import { useEntity } from "../../../../../../contexts/EntityContext"
import { GridType } from "../../../../types/DashboardConfigDto"
import { GridSortDto } from "../../../../../../routes/entitySearch/types/DashboardConfig"
import { useDownload } from "../../../../../../hooks/useDownload"
import useApiQuery from "../../../../../../hooks/useApiQuery"
import { useEnvConfig } from "../../../../../../contexts/EnvironmentConfigContext"
import useClient from "../../../../../../hooks/useClient"
import { useToaster } from "../../../../../../hooks/useToaster"
import { getDataFieldsFromEntityDataFields } from "../../../../../../library/helpers/entityHelpers"
import { useFeatureToggle } from "../../../../../../hooks/useFeatureToggle"

type GridDiplayWidgetProps = {
    gridReference: string
    widgetColumns: GridColumnHeaderConfig[]
    widgetDefaultSort?: GridSortDto | null
    gridEntityConfig: EntityConfig
    entityRelationship: EntityRelationship
    lookups: Lookup[]
    advancedFilters: GenericFilter[]
    type: GridType
    groupingField: string
    isCachingEnabled: boolean
}

const GridDisplayWidget = ({
    widgetColumns,
    widgetDefaultSort,
    lookups,
    gridEntityConfig,
    entityRelationship,
    gridReference,
    advancedFilters,
    type,
    groupingField,
    isCachingEnabled = false
}: GridDiplayWidgetProps) => {
    const [entity, setEntity] = useEntity()

    const config = useEnvConfig()
    const download = useDownload()
    const client = useClient()
    const toaster = useToaster()
    const { isEnabled: permHideEyeOnTransactions } = useFeatureToggle("permHideEyeOnTransactions")

    const onEntitySelected = (data: RowData) => {
        setEntity({
            reference: data.reference,
            entityType: gridEntityConfig.reference,
            entityFields: convertDtoFieldsToDatafields(data.fields)
        })
    }

    const onChildSelected = (selectedRefs: string[]) => {
        const selectedChildEntities = entity.selectedChildEntities?.filter(e => e.entityType !== gridEntityConfig.reference) ?? []

        setEntity({
            ...entity,
            selectedChildEntities: [...selectedChildEntities, { entityType: gridEntityConfig.reference, references: selectedRefs }]
        })
    }

    const getEntityDocumentUrlRequest = useApiQuery<string>({
        url: `${config.INTEGRATION_API_URL}/api/${client}/entity-document-url`,
        method: "GET",
        isExecutedAutomatically: false
    })

    const onFileDownloadClicked = (data: RowData) => {
        getEntityDocumentUrlRequest
            .execute(`?fileName=${data.fields.textFields.text_name}` ?? "")
            .then(response => download.fromUrl(response.data, data.fields.textFields.text_name ?? ""))
            .catch(() => toaster.error("Failed to download file"))
    }

    const renderRowActions = (rowData: RowData) => {
        if (gridEntityConfig.reference === "document") {
            return (
                <Grid.ActionButton
                    rowData={rowData}
                    onClick={() => onFileDownloadClicked(rowData)}
                    icon="fa fa-download"
                    ariaLabelPrefix="download-document"
                    selectOnClick={false}
                />
            )
        } else if (permHideEyeOnTransactions && gridEntityConfig.reference === "transaction") return <></>
        else return <Grid.ActionButton rowData={rowData} onClick={() => onEntitySelected(rowData)} icon="fa fa-eye" ariaLabelPrefix="select-entity" />
    }

    const groupingFieldData = gridEntityConfig.fields.find(field => field.fieldName === groupingField)
    const kanbanGroupingField = groupingFieldData && {
        value: groupingFieldData.fieldName,
        label: groupingFieldData.displayName,
        ordinal: 0,
        defaultOrdinal: 0,
        displayType: groupingFieldData.dataType.type,
        isEditable: false,
        lookupReference: groupingFieldData.dataType.lookupReference,
        currencyFieldReference: groupingFieldData.dataType.currencyFieldReference,
        currencyCode: groupingFieldData.dataType.currencyCode,
        format: groupingFieldData.dataType.format
    }

    return (
        <div className={`${classes.container} d-flex h-100 w-100 overflow-auto rounded`}>
            <Grid
                reference={gridReference}
                lookups={lookups}
                defaultPageSize={type === GridType.KANBAN ? 1000 : 20}
                defaultSort={widgetDefaultSort ?? undefined}
                onSelected={onChildSelected}
                ariaLabel="entity-children-grid"
                isCachingEnabled={isCachingEnabled}
                parentReference={entity.reference}
            >
                <Grid.Header>
                    <Grid.AutoCompleteEntitySearch entityConfig={gridEntityConfig} filters={getFilters(entity, entityRelationship, advancedFilters)} />
                    <div className="m-2 h-100 border-start border-white" />
                    <Grid.GlobalActions classname="ms-auto" />
                </Grid.Header>
                <Grid.EntityTable
                    entityConfig={gridEntityConfig}
                    filters={getFilters(entity, entityRelationship, advancedFilters)}
                    headerConfigs={widgetColumns}
                >
                    {type === GridType.KANBAN && kanbanGroupingField ? (
                        <Grid.Kanban groupingField={kanbanGroupingField} entityConfig={gridEntityConfig} />
                    ) : (
                        <Grid.Rows
                            selectableRows={true}
                            renderRowActions={renderRowActions}
                            entityConfig={gridEntityConfig}
                            filters={convertFromDtoToArray(
                                getFilters(entity, entityRelationship, advancedFilters),
                                getDataFieldsFromEntityDataFields(gridEntityConfig?.fields ?? []),
                                lookups
                            )}
                        />
                    )}
                </Grid.EntityTable>
                {(type !== GridType.KANBAN || kanbanGroupingField === undefined) && (
                    <Grid.Footer>
                        <Grid.Selection />
                        <Grid.Pagination />
                    </Grid.Footer>
                )}
            </Grid>
        </div>
    )
}

export default GridDisplayWidget

const getFilters = (entityData: Entity, entityRelationship: EntityRelationship, advancedFilters: GenericFilter[]): FiltersDto => {
    const filters = advancedFilters
        ? convertFromArrayToDto(advancedFilters)
        : {
              textFieldIsOneOf: [],
              textFieldStartsWith: [],
              booleanFieldIsEqualTo: [],
              numberFieldIsOneOf: [],
              numberFieldMatchesOperation: [],
              dateFieldIsOneOf: [],
              dateFieldMatchesOperation: [],
              dateFieldIsRelativeTo: [],
              fieldExists: []
          }
    const groupingField =
        entityData.legacyChildren && entityData.legacyChildren.length > 0
            ? {
                  fieldName: entityRelationship.groupingFieldName,
                  notOneOf: false,
                  values: entityData.legacyChildren
              }
            : {
                  fieldName: entityRelationship.groupingFieldName,
                  notOneOf: false,
                  values: [entityData.reference]
              }
    return {
        textFieldIsOneOf: [...(filters.textFieldIsOneOf ?? []), groupingField],
        textFieldStartsWith: filters.textFieldStartsWith ?? [],
        booleanFieldIsEqualTo: filters.booleanFieldIsEqualTo ?? [],
        numberFieldIsOneOf: filters.numberFieldIsOneOf ?? [],
        numberFieldMatchesOperation: filters.numberFieldMatchesOperation ?? [],
        dateFieldIsOneOf: filters.dateFieldIsOneOf ?? [],
        dateFieldMatchesOperation: filters.dateFieldMatchesOperation ?? [],
        fieldExists: filters.fieldExists ?? []
    }
}
