import classes from "./ShadowBorder.module.scss"

type ShadowBorderProps = {
    children: React.ReactNode
    className?: String
}

const ShadowBorder = ({ children, className = "" }: ShadowBorderProps) => (
    <div className={`rounded border border-1 border-white ${classes.container} ${className}`}>{children}</div>
)

export default ShadowBorder
