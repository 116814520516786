import DataField from "../../types/DataField"
import Lookup from "../../types/Lookup"
import GenericFilter from "./filterTypes/GenericFilter"
import StandardButton from "../buttons/StandardButton/StandardButton"
import ShadowBorder from "../borders/ShadowBorder"
import { useOverlay } from "../../contexts/overlay/OverlayContext"
import EntityWidgetFilters from "../../microfrontends/dashboard/components/customerDashboards/widgets/EntityWidgetFilter"
import Filter from "./Filter"
import { useState } from "react"
import { FilterGroupWithId } from "../../routes/entitySearch/components/EntitySearchModularDashboard"

type CompressedFilterListProps = {
    fields: DataField[]
    groupId: string
    lookups: Lookup[]
    appliedFilters?: FilterGroupWithId
    filtersFromQuickSearch?: GenericFilter[]
    onFiltersApplied?: (filterGroup: FilterGroupWithId) => void
    onResetGroup?: (filterGroupId: string) => void
    onDeleteGroup?: (filterGroupId: string) => void
    autoApply?: boolean
    disabled?: boolean
    showFieldType?: boolean
    isCompact?: boolean
    colour?: "blue" | "grey"
    showRelativeDateOptions?: boolean
    showFieldIsOneOfOptions?: boolean
    showClearFiltersButton?: boolean
}

const CompressedFilterList = ({
    fields,
    groupId,
    lookups,
    appliedFilters = { id: "", filterGroup: [] },
    onFiltersApplied = () => {},
    onResetGroup = () => {},
    onDeleteGroup = () => {},
    autoApply = false,
    disabled = false,
    showFieldType = false,
    isCompact = false,
    colour = "grey",
    showRelativeDateOptions = false,
    showFieldIsOneOfOptions = false,
    showClearFiltersButton = true
}: CompressedFilterListProps) => {
    const overlay = useOverlay()

    const onSaveFiltersClicked = (filters: GenericFilter[]) => {
        const filterGroupWithId = { id: groupId, filterGroup: filters }
        onFiltersApplied(filterGroupWithId)
        overlay.closeOverlay()
    }

    const onResetFiltersClicked = () => {
        onResetGroup(groupId)
        overlay.closeOverlay()
    }

    const onDeleteGroupClicked = () => {
        onDeleteGroup(groupId)
    }

    const onEditFiltersClicked = () => {
        overlay.showOverlay(
            <EntityWidgetFilters
                chartTitle="Filter Group"
                fields={fields}
                lookups={lookups}
                filters={appliedFilters.filterGroup}
                onFiltersUpdated={onSaveFiltersClicked}
                onResetFilters={onResetFiltersClicked}
            />,
            "medium"
        )
    }

    const [listExpanded, setListExpanded] = useState(false)

    const onListExpanded = () => {
        setListExpanded(currentValue => !currentValue)
    }

    const disabledFilterList = () => {}

    return (
        <div>
            <ShadowBorder className={`p-2 m-1`}>
                <div className="d-flex justify-content-between">
                    <div role="button" className={`d-flex m-1 px-2 ${isCompact ? "ms-auto" : ""}`} onClick={onListExpanded} aria-label="expand-filters">
                        {appliedFilters.filterGroup.length > 0 && (
                            <i className={`ms-1 fal ${listExpanded ? `fa-chevron-down` : `fa-chevron-right`} text-black me-3`} />
                        )}
                        <h3 className="fw-bold">Filter Group ({appliedFilters.filterGroup.length})</h3>
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                        <StandardButton
                            className="me-2"
                            iconClasses="fal fa-edit"
                            label="Edit"
                            ariaLabel="Edit Group"
                            colour="blue"
                            onClick={onEditFiltersClicked}
                        />
                        <div role="button" className={`m-1 px-2 ${isCompact ? "ms-auto" : ""}`} onClick={onDeleteGroupClicked} aria-label="filter-remove">
                            <i className={`ms-1 fal fa-trash-alt text-black`} />
                        </div>
                    </div>
                </div>
                <div>
                    {listExpanded &&
                        appliedFilters.filterGroup.map(filter => {
                            return (
                                <div key={filter.id} className="form-control-sm p-0">
                                    <Filter
                                        filter={filter}
                                        fields={fields}
                                        lookups={lookups}
                                        disabled={true}
                                        onFilterUpdated={disabledFilterList}
                                        onFilterDeleted={disabledFilterList}
                                        showFieldType={showFieldType}
                                        isCompact={isCompact}
                                        colour={colour}
                                    />
                                </div>
                            )
                        })}
                </div>
            </ShadowBorder>
        </div>
    )
}

export default CompressedFilterList
