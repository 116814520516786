import DatePickerWithInput from "../Inputs/DatePickerWithInput"
import Input from "../Inputs/Input"
import Dropdown from "../dropdowns/Dropdown"
import DateFieldRelativeFilter from "./filterTypes/DateFieldRelativeFilter"
import DropdownOption from "../../types/DropdownOptions"
import { useLayoutEffect } from "react"
import DataFieldDropdown from "../dropdowns/DataFieldDropdown"
import DataField from "../../types/DataField"
import DataPrimitive from "../../types/DataPrimitive"
import moment from "moment"
import { isValidPositiveInteger } from "../helpers/numberUtils"

type RelativeDatePickerProps = {
    filter: DateFieldRelativeFilter
    disabled?: boolean
    onFilterChange: (newFilter: DateFieldRelativeFilter) => void
    fields: DataField[]
    isCompact?: boolean
}

type RelativeToOptions = {
    type: RelativeToOptionsEnum
    isBefore: boolean
}

enum RelativeToOptionsEnum {
    RELATIVE_TO_TODAY,
    RELATIVE_TO_DATE,
    RELATIVE_TO_FIELD
}
const options = [
    { label: "After date", value: { type: RelativeToOptionsEnum.RELATIVE_TO_DATE, isBefore: false } },
    { label: "Before date", value: { type: RelativeToOptionsEnum.RELATIVE_TO_DATE, isBefore: true } },
    { label: "After today", value: { type: RelativeToOptionsEnum.RELATIVE_TO_TODAY, isBefore: false } },
    { label: "Before today", value: { type: RelativeToOptionsEnum.RELATIVE_TO_TODAY, isBefore: true } },
    { label: "After field", value: { type: RelativeToOptionsEnum.RELATIVE_TO_FIELD, isBefore: false } },
    { label: "Before field", value: { type: RelativeToOptionsEnum.RELATIVE_TO_FIELD, isBefore: true } }
]

const getRelativeToOptionEnum = (filter: DateFieldRelativeFilter): RelativeToOptionsEnum => {
    if (filter.isRelativeToToday) {
        return RelativeToOptionsEnum.RELATIVE_TO_TODAY
    } else if (filter.referenceFieldName || !filter.relativeToDate) {
        return RelativeToOptionsEnum.RELATIVE_TO_FIELD
    } else {
        return RelativeToOptionsEnum.RELATIVE_TO_DATE
    }
}

export const RelativeDatePicker = ({ filter, disabled = false, onFilterChange, fields, isCompact }: RelativeDatePickerProps) => {
    const selectedBeforeOption = options.find(o => o.value.isBefore === filter.isBefore && getRelativeToOptionEnum(filter) === o.value.type) ?? {
        label: "After date",
        value: { type: RelativeToOptionsEnum.RELATIVE_TO_DATE, isBefore: false }
    }

    useLayoutEffect(() => {
        isBeforeChanged(selectedBeforeOption)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onOffsetChanged = (offset: string) => {
        if (offset === "") offset = "0"
        if (isValidPositiveInteger(offset)) {
            onFilterChange({ ...filter, numberOfDays: parseInt(offset) })
        }
    }

    const onDateChanged = (date: Date) => {
        onFilterChange({ ...filter, relativeToDate: date.toISOString() })
    }

    const onFieldChanged = (field: DataField | undefined) => {
        onFilterChange({ ...filter, referenceFieldName: field?.value })
    }

    const isBeforeChanged = (selectedOption: DropdownOption<RelativeToOptions>) => {
        switch (selectedOption.value.type) {
            case RelativeToOptionsEnum.RELATIVE_TO_DATE:
                onFilterChange({
                    ...filter,
                    isBefore: selectedOption.value.isBefore,
                    relativeToDate: filter.relativeToDate ?? moment().format(),
                    isRelativeToToday: false,
                    referenceFieldName: undefined
                })
                break
            case RelativeToOptionsEnum.RELATIVE_TO_TODAY:
                onFilterChange({
                    ...filter,
                    isBefore: selectedOption.value.isBefore,
                    isRelativeToToday: true,
                    referenceFieldName: undefined,
                    relativeToDate: undefined
                })
                break
            case RelativeToOptionsEnum.RELATIVE_TO_FIELD:
                onFilterChange({
                    ...filter,
                    isBefore: selectedOption.value.isBefore,
                    isRelativeToToday: false,
                    referenceFieldName: filter.referenceFieldName ?? "",
                    relativeToDate: undefined
                })
                break
        }
    }

    const renderAdditional = () => {
        switch (selectedBeforeOption.value.type) {
            case RelativeToOptionsEnum.RELATIVE_TO_DATE:
                return (
                    <DatePickerWithInput
                        date={filter.relativeToDate ? new Date(filter.relativeToDate) : new Date()}
                        onDateChange={onDateChanged}
                        disabled={disabled}
                    />
                )
            case RelativeToOptionsEnum.RELATIVE_TO_FIELD:
                return (
                    <DataFieldDropdown
                        options={fields.filter(f => f.type === DataPrimitive.DATE)}
                        disabled={disabled}
                        selectedOption={fields.find(f => f.value === filter.referenceFieldName)}
                        onOptionSelected={onFieldChanged}
                        showFieldType={false}
                        ariaLabel={"relative-date-relative-to-field-dropdown"}
                    />
                )
            default:
                return <></>
        }
    }

    return (
        <div className={`d-flex gap-2 align-items-center ${isCompact ? "flex-column" : "flex-row"}`}>
            <div className={"d-flex flex-row w-100 gap-2 align-items-center"}>
                <Input
                    onChange={onOffsetChanged}
                    placeholder={"0"}
                    value={filter.numberOfDays.toString()}
                    ariaLabel={"relative-date-number-of-days"}
                    type={"number"}
                />
                <div>days</div>
            </div>
            <div className={"d-flex flex-row justify-content-between w-100 gap-2 align-items-center"}>
                <Dropdown
                    options={options}
                    onOptionSelected={isBeforeChanged}
                    selectedOption={options.find(o => o.label === selectedBeforeOption.label)}
                    ariaLabel={"relative-date-relative-type-dropdown"}
                    textAlign="left"
                />
                {renderAdditional()}
            </div>
        </div>
    )
}

export default RelativeDatePicker
