import { IconButton } from 'invevo-react-components'
import classes from './SetupCard.module.scss'

const SetupCard = ({ className = "", title, customData, addButton = true, onClick}) => {
    return (
        <div className={`d-flex ${className} ${classes.card} my-2 pt-2 `}>
            <div className="d-flex px-3 pt-3">
                <i className={`fal fa-grip-lines ${classes.icon}`}></i>
            </div>
            <div className={`${classes.verticalDivider}`}></div>
            <div className="d-flex flex-column w-100">
                <div className="d-flex pt-2 mx-3">
                    <div className="d-flex align-items-center">
                        <i className={`fal fa-arrow-circle-right me-3 ${classes.icon}`}></i>
                        <span className="fs-5">{title}</span>
                    </div>
                    
                    {addButton ?
                        <div className="d-flex ms-auto">
                            <IconButton iconClasses="fal fa-plus" onClick={onClick} />
                        </div>
                        : <div className="d-flex ms-auto">
                            <IconButton iconClasses="fal fa-trash-alt" onClick={onClick} />
                        </div>
                    }
                </div>
                <div className={`${classes.horizontalDivider} mx-2`}></div>
                <div className="d-flex p-3">{customData}</div>
            </div>
        </div>
    )
}

export default SetupCard