import React, { useEffect } from 'react';
import AccountCard from './accountCard';
import AccountDetail from './accountDetail';
import { jsxArrayJoin } from '../common/jsxArrayJoin';
import { useDispatch, useSelector } from 'react-redux';
import { last } from 'lodash';
import { useState } from 'react';
import { useInternationalization } from '@progress/kendo-react-intl';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  refreshCustomerTED,
  getCustomerTED,
  clearTedSuccess,
  clearTedError
} from '../../actions/tedActions';
import { showToastErrorMessage, showToastSuccessMessage } from '../../api/toasterApi';
import Money from '../common/money';
import { useClient } from 'invevo-react-components';

const ExposureCard = ({ title, summary, accountId, open, setOpen }) => {
  const client = useClient()
  const showTED = useSelector((state) => {
    let configuration = {};
    if (state.globalConfiguration && state.globalConfiguration.length > 0) {
      configuration = last(state.globalConfiguration);
    }
    return configuration.showTED;
  });

  const dispatch = useDispatch();
  const dateFormat = useSelector(
    (state) => state.lookupReducer.globalSettings.results.dateFormat
  );

  const globalCurrencyCode = useSelector(
    (state) => state.lookupReducer.globalSettings.results.globalCurrencyCode
  );
  const tedRefresh = useSelector((state) => state.tedReducer.tedRefresh);

  const tedData = useSelector((state) => ({
    tedLastUpdated: state.tedReducer.tedLastUpdated || 0,
    salesLedgerValue: state.tedReducer.salesLedgerValue,
    pendingOpenOrders: state.tedReducer.pendingOpenOrders,
    toolHireValue: state.tedReducer.toolHireValue,
    pendingAccountCredits: state.tedReducer.pendingAccountCredits,
    pendingPayments: state.tedReducer.pendingPayments,
    relatedConsolidatedInvoices: state.tedReducer.relatedConsolidatedInvoices,
    tedValue: state.tedReducer.tedValue,
    creditLimitValue: state.tedReducer.creditLimitValue
  }));

  const tedSuccess = useSelector((state) => state.tedReducer.tedSuccess);
  const tedError = useSelector((state) => state.tedReducer.tedError);

  const [tedReq, setTedReq] = useState(false);
  const internationalization = useInternationalization();

  const refreshTEDClick = () => {
    setTedReq(true);
    dispatch(refreshCustomerTED(client, accountId));
  };

  useDeepCompareEffect(() => {
    if (!tedRefresh) return;

    setTedReq(false);
  }, [tedRefresh]);

  useDeepCompareEffect(() => {
    if (tedError.length > 0) {
      showToastErrorMessage(tedError);
      dispatch(clearTedError());
    }

    if (tedSuccess.length > 0) {
      showToastSuccessMessage(tedSuccess);
      dispatch(clearTedSuccess());
    }
  }, [tedError, tedSuccess, dispatch]);

  useEffect(() => {
    if (showTED) {
      dispatch(getCustomerTED(client, accountId));
    }
  }, [dispatch, accountId, showTED, client]);

  return (
    <AccountCard title={title} open={open} setOpen={setOpen}>
      <AccountDetail
        title="Overdue Balance"
        value={jsxArrayJoin(summary.Overdue)}
        layout="hero"
        highlight="negitive"
      />
      <AccountDetail
        title="Account Balance"
        value={jsxArrayJoin(summary.AccountBalance)}
      />

      {showTED && (
        <>
          <AccountDetail
            title="Sales ledger value"
            value={
              <Money
                number={tedData.salesLedgerValue}
                currencyCode={globalCurrencyCode}
              />
            }
          />
          <AccountDetail
            title="Pending open orders"
            value={
              <Money
                number={tedData.pendingOpenOrders}
                currencyCode={globalCurrencyCode}
              />
            }
          />
          <AccountDetail
            title="Tool hire"
            value={
              <Money
                number={tedData.toolHireValue}
                currencyCode={globalCurrencyCode}
              />
            }
          />

          <AccountDetail
            title="Pending account credits"
            value={
              <Money
                number={tedData.pendingAccountCredits}
                currencyCode={globalCurrencyCode}
              />
            }
          />
          <AccountDetail
            title="Pending Payments"
            value={
              <Money
                number={tedData.pendingPayments}
                currencyCode={globalCurrencyCode}
              />
            }
          />
          <AccountDetail
            title="Related consolidated invoices"
            value={
              <Money
                number={tedData.relatedConsolidatedInvoices}
                currencyCode={globalCurrencyCode}
              />
            }
          />

          <AccountDetail
            title="Total Exposure"
            value={
              <Money
                number={tedData.tedValue}
                currencyCode={globalCurrencyCode}
              />
            }
          />
          <AccountDetail
            title="Credit limit value"
            value={
              <Money
                number={tedData.creditLimitValue}
                currencyCode={globalCurrencyCode}
              />
            }
          />
          <AccountDetail
            title="TED Last Updated"
            value={
              tedData.tedLastUpdated
                ? internationalization.formatDate(
                    new Date(tedData.tedLastUpdated),
                    dateFormat
                  )
                : null
            }
          />
          <AccountDetail>
            <button
              disabled={tedReq}
              onClick={refreshTEDClick}
              className="btn btn-default btn-sm"
            >
              Refresh TED
            </button>
          </AccountDetail>
        </>
      )}
    </AccountCard>
  );
};

export default ExposureCard;
