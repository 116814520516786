import { useState } from "react"
import classes from "./EntityTrendingChart.module.scss"
import DropdownOption from "../../types/DropdownOptions"
import EntityDataField from "../../routes/entityConfig/types/EntityDataField"
import MultiSelectDropdown from "../dropdowns/MultiSelectDropdown"
import { convertFromArrayToDto } from "../FilterList/FiltersDto"
import useApiQuery from "../../hooks/useApiQuery"
import GenericFilter from "../FilterList/filterTypes/GenericFilter"
import FilterList from "../FilterList/FilterList"
import Lookup from "../../types/Lookup"
import { DataPrimitiveTypeEnum, DataTypeEnum } from "../../routes/entityConfig/types/DataType"
import { useEnvConfig } from "../../contexts/EnvironmentConfigContext"
import useClient from "../../hooks/useClient"
import DateFieldRelativeFilter from "../FilterList/filterTypes/DateFieldRelativeFilter"
import FilterTypesByField from "../FilterList/FilterTypesByField"
import { v4 } from "uuid"
import moment from "moment"
import { TrendingPeriod, TrendingWidgetState } from "../../microfrontends/dashboard/types/DashboardConfigState"
import EntityConfig from "../../routes/entityConfig/types/EntityConfig"
import { getDataFieldsFromEntityDataFields } from "../helpers/entityHelpers"
import Spinner from "../Loading/Spinner"
import { exists } from "../helpers/tsUtils"
import EntityTrendingSubChart from "./EntityTrendingSubChart"
import { ChartColours } from "./ChartColours"

type ChartColour = typeof ChartColours[number]

export type Dataset = {
    label: string
    data: number[]
    borderColor: ChartColour
    backgroundColor: ChartColour
}

type DatasetDto = {
    label: string
    data: { [date: string]: number[] }
}

type EntityTrendingChartProps = {
    className?: string
    colour?: "white" | "blue"
    title: string
    entityConfigReference: string
    entityConfigs: EntityConfig[]
    lookups: Lookup[]
    widget: TrendingWidgetState
    advancedFilters: GenericFilter[]
}

const EntityTrendingChart = ({ className, colour, entityConfigReference, advancedFilters, entityConfigs, lookups, widget }: EntityTrendingChartProps) => {
    const config = useEnvConfig()
    const client = useClient()

    const entityConfig = entityConfigs.find(c => c.reference === entityConfigReference)

    const [isShowingFilters, setShowingFilters] = useState(false)
    const toggleShowFilters = () => setShowingFilters(f => !f)
    const [filters, setFilters] = useState<GenericFilter[]>(widget.filters)

    const showingValues = widget.showValues && widget.comparisonField?.dataPrimitiveType === DataPrimitiveTypeEnum.NUMBER

    const xLabels = getLabels(widget.period)
    const [datasets, setDatasets] = useState<Dataset[]>([])

    const splitOptions = datasets.map(d => ({
        label: d.label,
        value: d.label,
        element: <span className={`text-${d.borderColor}`}>{d.label}</span>
    }))
    const [selectedSplits, setSelectedSplits] = useState<string[]>(splitOptions.map(o => o.value))

    const onDataRecieved = (res: DatasetDto[]) => {
        const datasets = getDataFromDatasets(xLabels, res, showingValues, lookups, widget.groupingField)
        setDatasets(datasets)
        setSelectedSplits(datasets.map(d => d.label))
    }

    const getTrendingDataRequest = useApiQuery<DatasetDto[]>({
        url: `${config.SEARCH_API_URL}/api/${client}/updates/${entityConfig?.reference ?? entityConfigReference}`,
        method: "POST",
        dto: {
            filters: convertFromArrayToDto([...filters, ...advancedFilters, getRelativeDateFilterForStartDate(widget.period)]),
            groupingField: widget.groupingField?.fieldName,
            comparisonField: widget.comparisonField?.fieldName,
            comparisonFieldType: widget.comparisonField?.dataPrimitiveType,
            showValues: widget.showValues
        },
        onSuccess: onDataRecieved
    })

    const onMultiSelectChange = (opts: DropdownOption<string>[]) => setSelectedSplits(opts.map(o => o.value))

    const filteredData = datasets.filter(d => selectedSplits.includes(d.label))

    const entityFieldsAsDataFields = getDataFieldsFromEntityDataFields(entityConfig?.fields ?? [])
    const allNumbers: number[] = showingValues
        ? filteredData.flatMap(d => d.data[d.data.length - 1]).filter(exists)
        : filteredData.filter(d => d.data.length > 0).flatMap(d => d.data.reduce((agg, n) => agg + n, 0))
    const countOrLastSum = (showingValues ? allNumbers.at(allNumbers.length - 1) : allNumbers.reduce((agg, n) => agg + n, 0)) ?? 0
    const avg = countOrLastSum / allNumbers.length || 0

    const textColour = colour === "blue" ? "text-white" : "text-blue"

    return (
        <div className={`${className} d-flex flex-column flex-grow-1 flex-fill h-100 w-100 p-3 gap-3 rounded ${classes[`widget-${colour}`]} ${textColour}`}>
            <div className="d-flex flex-row gap-3 flex-grow-1 flex-fill">
                {getTrendingDataRequest.isFetching ? (
                    <Spinner className="position-relative w-100 h-100 p-5" />
                ) : (
                    <EntityTrendingSubChart
                        colour={colour === "white" ? "blue" : "white"}
                        chartType={widget.chartType}
                        state={{
                            title: "",
                            xLabels,
                            data: filteredData
                        }}
                    />
                )}
                <div className="d-flex flex-column gap-3 w-25">
                    <div className="d-flex flex-column gap-2">
                        <span className="fs-3">{showingValues ? "CURRENT SUM" : "NUMBER OF CHANGES"}</span>
                        <span className="fs-5">{countOrLastSum}</span>
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <span className="fs-3">AVERAGE</span>
                        <span className="fs-5">{avg}</span>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row gap-3 w-100 align-items-center justify-content-between">
                <div className="d-flex flex-row gap-3 align-items-center w-25">
                    <span>Showing:</span>
                    <MultiSelectDropdown
                        options={splitOptions}
                        onSelectedOptionsChanged={onMultiSelectChange}
                        selectedOptions={splitOptions.filter(o => selectedSplits.includes(o.value))}
                        className="w-100"
                    />
                </div>
                <div
                    className={`d-flex p-2 rounded pointer align-items-center justify-content-center text-blue ${classes["filter-button"]} ${
                        isShowingFilters ? classes["selected-filter-button"] : ""
                    } gap-2`}
                    role="button"
                    aria-label="show-grid-filters"
                    onClick={toggleShowFilters}
                >
                    <i className="fal fa-filter"></i>
                    Additional Filters
                    <div className={`px-2 ${colour === "blue" ? "bg-blue" : "bg-white"} ${textColour} ${classes["filter-count-label"]}`}>{filters.length}</div>
                </div>
            </div>
            {isShowingFilters && (
                <FilterList colour="blue" fields={entityFieldsAsDataFields} lookups={lookups} appliedFilters={filters} onFiltersApplied={setFilters} />
            )}
        </div>
    )
}

export default EntityTrendingChart

const getOffset = (startDate: TrendingPeriod): number => {
    switch (startDate) {
        case "TODAY":
            return 0
        case "SEVEN_DAYS":
            return 7
        case "THIRTY_DAYS":
            return 30
    }
}

const getRelativeDateFilterForStartDate = (startDate: TrendingPeriod): DateFieldRelativeFilter => ({
    type: FilterTypesByField.DATE_FIELD_RELATIVE,
    id: v4(),
    fieldName: "date_last_update_time",
    numberOfDays: getOffset(startDate),
    isBefore: true,
    isRelativeToToday: true,
    relativity: "AT_MOST"
})

const getLabels = (timeOpt: TrendingPeriod) => {
    const dateFormat = "yyyy-MM-DD"
    const startDate = moment().startOf("day")
    const labels = [startDate.format(dateFormat)]
    for (let i = 1; i < getOffset(timeOpt); i++) {
        labels.unshift(startDate.clone().subtract(i, "days").format(dateFormat))
    }
    return labels
}

const getDataFromDatasets = (
    labels: string[],
    datasets: DatasetDto[],
    showValues: boolean = false,
    lookups: Lookup[],
    groupingField?: EntityDataField
): Dataset[] =>
    datasets.map((d, i) => {
        const colour = ChartColours[i % (ChartColours.length - 1)] ?? ChartColours[0]
        const dataPoints = showValues
            ? labels.reduce<number[]>((arr, l) => {
                  const valuesForDate = d.data[l]
                  const nextVal = valuesForDate?.at(-1) ?? arr.at(-1) ?? 0
                  return [...arr, nextVal]
              }, [])
            : labels.map(l => d.data[l]?.length ?? 0)
        return {
            borderColor: colour,
            backgroundColor: colour,
            label:
                groupingField?.dataType.type === DataTypeEnum.LOOKUP
                    ? lookups.find(l => l.reference === groupingField.dataType.lookupReference)?.entries.find(e => e.reference === d.label)?.name ?? d.label
                    : d.label,
            data: dataPoints
        }
    })
