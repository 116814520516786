import { Input, 
         ProgressButton, 
         useClient, 
         useApi, 
         Loading, 
         Dropdown,
         useFeatureToggle, useConfig } from 'invevo-react-components';
import { useEffect, useState } from 'react';

const SmsConfiguration = () => {
    const { isEnabled: allowCustomSinchConfig } = useFeatureToggle("connectCustomSinchConfig")
    
    const redirectOptionsList = [
        { id: 1, value: "Do not send SMS", serverValue: "DO_NOT_SEND_SMS" },
        { id: 2, value: "Send to number", serverValue: "SEND_TO_REDIRECT_NUMBER" },
        { id: 3, value: "Send normally", serverValue: "SEND_SMS" }
    ]

    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    
    const api = useApi()
    const client = useClient()
    const config = useConfig()
  
    const [redirectOptions] = useState(redirectOptionsList)
    const [selectedRedirectOption, setSelectedRedirectOption] = useState({})

    const [showRedirectNumberInput, setShowRedirectNumberInput] = useState(false)
    const [redirectPhoneNumber, setRedirectPhoneNumber] = useState("")
    
    const [sinchClientId, setSinchClientId] = useState("")
    const [sinchApiSecret, setSinchApiSecret] = useState("")

    useEffect(() => {
        if (selectedRedirectOption.id === 2) setShowRedirectNumberInput(true)
        else setShowRedirectNumberInput(false)
    }, [selectedRedirectOption])

    const onRedirectOptionSelected = (option) => {
        setSelectedRedirectOption(redirectOptions.find(o => o.id === option.id))
    }

    const onRedirectPhoneNumberChange = event => {
        setRedirectPhoneNumber(event.target.value)
    }
    
    const onSinchClientIdChange = event => {
        setSinchClientId(event.target.value)
    }
    
    const onSinchApiSecretChange = event => {
        setSinchApiSecret(event.target.value)
    }

    const newSmsConfigRequest = () => {
        const request = {
            smsRedirectOption: selectedRedirectOption.serverValue
        }
        
        if (selectedRedirectOption.id === 2) {
            request.redirectNumber = redirectPhoneNumber
        }
        
        if(allowCustomSinchConfig) {
            if(sinchClientId && sinchApiSecret) {
                request.smsProviderConfig = {
                    key: sinchClientId,
                    secret: sinchApiSecret
                }
            }
        }
        
        return request
    }

    const saveConfig = () => {
        const promise = new Promise((resolve, reject) => {
           api.put(`${config.CONNECT_API_URL}/api/${client}/connect-config`, newSmsConfigRequest())
               .then(_ => {
                    resolve()
                })
                .catch(error => {
                    console.error(error)
                    reject()
                })
        })
        return promise
    }

    useEffect(() => {
        if(hasFetched || isFetching || !config.CONNECT_API_URL) return

        setIsFetching(true)
        api.get(`${config.CONNECT_API_URL}/api/${client}/connect-config`)
        .then(response => {
            setSelectedRedirectOption(redirectOptions.find(o => o.serverValue === response.data.smsRedirectOption))
            setRedirectPhoneNumber(response.data.redirectNumber || "")
            
            if(response.data.smsProviderConfig) {
                setSinchClientId(response.data.smsProviderConfig.key || "")
                setSinchApiSecret(response.data.smsProviderConfig.secret || "")
            }
            
            setHasFetched(true)
            setIsFetching(false)
        })
    }, [api, client, isFetching, hasFetched, redirectOptions, config.CONNECT_API_URL])

    return (
        <div className="d-flex flex-column flex-grow-1 w-50">
            <Loading isLoading={isFetching || !config.CONNECT_API_URL} colour="white">
                <div className="d-flex flex-column mt-3 mb-4">
                    <h3 className="text-grey">Redirect options</h3>
                    <hr />
                    <div className="w-100">
                        <Dropdown placeholder="Please select a redirect option" defaultOption={selectedRedirectOption} options={redirectOptions} onOptionSelected={onRedirectOptionSelected}/>
                    </div>
                    {showRedirectNumberInput &&
                        <div className="d-flex flex-column">
                            <Input
                                className="mt-3 w-100"
                                label="Redirect Number"
                                labelColour="grey"
                                placeholder="Please enter a redirect number"
                                value={redirectPhoneNumber}
                                onChange={onRedirectPhoneNumberChange}
                                
                            />
                        </div>
                    }
                    {allowCustomSinchConfig &&
                        <div className="d-flex flex-column mt-5">
                            <h3 className="text-grey">Sinch API configuration</h3>
                            <hr />
                            <Input
                                className="w-100"
                                label="Client Id"
                                labelColour="grey"
                                placeholder="Please enter the Client Id"
                                value={sinchClientId}
                                onChange={onSinchClientIdChange}
                                
                            />
                            <Input
                                className="mt-3 w-100"
                                label="API Secret"
                                labelColour="grey"
                                placeholder="Please enter the API secret"
                                value={sinchApiSecret}
                                onChange={onSinchApiSecretChange}
                                
                            />
                        </div>
                    }
                </div>
                
                <div className="d-flex mt-5 ms-auto">
                    <ProgressButton
                        iconClasses="fal fa-save"
                        label="Save SMS configuration"
                        onClickWithPromise={() => {
                            const promise = new Promise((resolve, reject) => {
                                saveConfig()
                                    .then(_ => {
                                        resolve()
                                    },
                                        _ => reject())
                                    .catch(_ => reject())
                            })
                            return promise
                        }}
                        succeededText={`Saved config successfully!`}
                        failedText={`Failed to save config!`}
                    />
                </div>                
            </Loading>
        </div>
    )
}

export default SmsConfiguration