import CompressedFilterList from "./CompressedFilterList"
import DataField from "../../types/DataField"
import Lookup from "../../types/Lookup"
import StandardButton from "../buttons/StandardButton/StandardButton"
import { useState, SetStateAction, Dispatch, useEffect } from "react"
import { FilterGroupWithId } from "../../routes/entitySearch/components/EntitySearchModularDashboard"
import { v4 as uuidv4 } from "uuid"

type FilterGroupListProps = {
    fields: DataField[]
    lookups: Lookup[]
    appliedGroupFilters?: FilterGroupWithId[]
    onGroupFiltersApplied?: Dispatch<SetStateAction<FilterGroupWithId[]>>
    autoApply?: boolean
    disabled?: boolean
    showFieldType?: boolean
    isCompact?: boolean
    colour?: "blue" | "grey"
    showRelativeDateOptions?: boolean
    showFieldIsOneOfOptions?: boolean
    showClearFiltersButton?: boolean
}

const FilterGroupList = ({
    fields,
    lookups,
    appliedGroupFilters = [],
    onGroupFiltersApplied = () => {},
    autoApply = false,
    disabled = false,
    showFieldType = false,
    isCompact = false,
    colour = "grey",
    showRelativeDateOptions = false,
    showFieldIsOneOfOptions = false,
    showClearFiltersButton = true
}: FilterGroupListProps) => {
    const [filterGroups, setFilterGroups] = useState(appliedGroupFilters)

    useEffect(() => {
        setFilterGroups(appliedGroupFilters)
    }, [appliedGroupFilters])

    const onNewGroupClicked = () => {
        setFilterGroups(groups => [...groups, { id: uuidv4(), filterGroup: [] }])
    }

    const onClearGroupClicked = () => {
        onGroupFiltersApplied([])
    }

    const onFiltersApplied = (updatedFilterGroup: FilterGroupWithId) => {
        const updatingFilters = (filterGroups: FilterGroupWithId[]): FilterGroupWithId[] =>
            filterGroups.map(group => (group.id === updatedFilterGroup.id ? updatedFilterGroup : group))
        onGroupFiltersApplied(groups => {
            if (groups.some(g => g.id === updatedFilterGroup.id)) {
                return updatingFilters(groups)
            } else {
                return [...groups, updatedFilterGroup]
            }
        })
    }

    const onResetGroup = (updatedFilterGroupId: string) => {
        const updatingFilters = (filterGroups: FilterGroupWithId[]) =>
            filterGroups.map(group => (group.id === updatedFilterGroupId ? { ...group, filterGroup: [] } : group))
        onGroupFiltersApplied(updatingFilters)
    }

    const onDeleteGroup = (updatedFilterGroupId: string) => {
        const updatingFilters = (filterGroups: FilterGroupWithId[]) => filterGroups.filter(group => group.id !== updatedFilterGroupId)
        onGroupFiltersApplied(updatingFilters)
    }

    return (
        <div className="d-flex flex-grow-1 flex-column">
            {filterGroups.map((filterGroup, index) => (
                <div key={filterGroup.id}>
                    <CompressedFilterList
                        fields={fields}
                        groupId={filterGroup.id}
                        lookups={lookups}
                        appliedFilters={filterGroup}
                        onFiltersApplied={onFiltersApplied}
                        onResetGroup={onResetGroup}
                        onDeleteGroup={onDeleteGroup}
                        autoApply={autoApply}
                        disabled={true}
                        showFieldType={showFieldType}
                        isCompact={isCompact}
                        colour={colour}
                        showRelativeDateOptions={showRelativeDateOptions}
                        showFieldIsOneOfOptions={showFieldIsOneOfOptions}
                        showClearFiltersButton={showClearFiltersButton}
                    />
                    <h2 className="d-flex justify-content-center">{index === filterGroups.length - 1 ? "" : "or"}</h2>
                </div>
            ))}
            {filterGroups.length === 0 ? (
                <div className={`d-flex align-items-center ${colour === "grey" ? "text-grey" : "text-white"} p-2`}>
                    <i className="me-2 fal fa-exclamation-circle" />
                    <h6 className="mb-0">You have no groups defined</h6>
                </div>
            ) : (
                <div className="d-flex flex-column"></div>
            )}
            <div className="d-flex">
                <StandardButton
                    className="border-end me-3"
                    iconClasses="fal fa-plus"
                    label={isCompact ? "Add" : "Add New Group"}
                    ariaLabel="Add New Group"
                    colour={colour}
                    onClick={onNewGroupClicked}
                />
                <StandardButton
                    iconClasses="fal fa-times"
                    label={isCompact ? "Clear" : "Clear All Groups"}
                    ariaLabel="Clear Groups"
                    colour={colour}
                    onClick={onClearGroupClicked}
                />
            </div>
        </div>
    )
}

export default FilterGroupList
