import { useClient, useConfig } from "invevo-react-components"
import { FileExportConfigAction, FileExportConfigActionEnum, FileExportConfigState } from "../../../reducers/FileExportConfigReducer"
import { FileType } from "../../../types/FileImportConfig"
import classes from "./ExportFileConfig.module.scss"
import DataFileExportOptions from "./DataFileExportOptions"
import ProgressButton from "../../../../../library/buttons/ProgressButton/ProgressButton"
import useApiQuery from "../../../../../hooks/useApiQuery"
import Dropdown from "../../../../../library/dropdowns/Dropdown"
import { useFeatureToggle } from "../../../../../hooks/useFeatureToggle"
import { FileFormat } from "../../utils"

const fileTypes = [
    { id: "1", value: FileType.CUSTOMER, label: FileType.CUSTOMER },
    { id: "2", value: FileType.TRANSACTION, label: FileType.TRANSACTION }
]

type FileExportConfigSetupProps = {
    config: FileExportConfigState
    dispatch: React.Dispatch<FileExportConfigAction>
}

const ExportFileConfig = ({ config, dispatch }: FileExportConfigSetupProps) => {
    const client = useClient()
    const globalConfig = useConfig()

    const exportToFileRequest = useApiQuery({
        url: `${globalConfig.INTEGRATION_API_URL}/api/${client}/data-file-export`,
        method: "POST",
        isExecutedAutomatically: false
    })

    const { isEnabled: isXMLExportEnabled } = useFeatureToggle("xmlFileExport")
    const fileFormats = isXMLExportEnabled
        ? [
              { id: "1", value: FileFormat.CSV, label: FileFormat.CSV },
              { id: "2", value: FileFormat.XML, label: FileFormat.XML }
          ]
        : [{ id: "1", value: FileFormat.CSV, label: FileFormat.CSV }]

    const onFileExportClick = () => {
        return exportToFileRequest.execute(config.reference).then(res => {
            return res
        })
    }

    const onFileTypeSelected = (option: any) => {
        dispatch({
            type: FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_UPDATED,
            editingConfig: { ...config, fileType: option.value, dataFileExportConfig: { ...config.dataFileExportConfig } }
        })
    }

    const onFileFormatSelected = (option: any) => {
        dispatch({
            type: FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_UPDATED,
            editingConfig: {
                ...config,
                fileFormat: option.value,
                dataFileExportConfig: { ...config.dataFileExportConfig, hasHeaderRow: option.value === FileFormat.XML }
            }
        })
    }

    return (
        <div className={`d-flex ${classes.card} mt-2 pt-2 `}>
            <div className="d-flex px-3 pt-3">
                <i className={`fal fa-grip-lines ${classes.icon}`} />
            </div>
            <div className={`${classes.verticalDivider}`} />
            <div className="d-flex flex-column w-100">
                <div className="d-flex my-1 mx-3">
                    <span className="text-grey fs-4">File details</span>
                    <ProgressButton
                        className="ms-auto my-1"
                        iconClasses="far fa-file-export"
                        label="Trigger export now"
                        onClickWithPromise={onFileExportClick}
                    />
                </div>
                <div className={`${classes.horizontalDivider} mx-2`} />
                <div className="d-flex flex-column">
                    <div className="d-flex p-3">
                        <div className={`d-flex flex-column pe-3 small`}>
                            <span className="text-uppercase small mb-1 ms-1 text-grey">File type</span>
                            <Dropdown
                                className="me-3"
                                placeholder={"Please select a file type"}
                                selectedOption={fileTypes.find(ft => ft.value === config.fileType) || fileTypes[0]}
                                options={fileTypes}
                                onOptionSelected={onFileTypeSelected}
                                ariaLabel="File Type"
                            />
                        </div>
                        <div className={`d-flex flex-column pe-3 small`}>
                            <span className="text-uppercase small mb-1 ms-1 text-grey">Format</span>
                            <Dropdown
                                className="me-3"
                                placeholder={"Please select format"}
                                selectedOption={fileFormats.find(ft => ft.value === config.fileFormat.valueOf()) || fileFormats[0]}
                                options={fileFormats}
                                onOptionSelected={onFileFormatSelected}
                                ariaLabel="File Format"
                            />
                        </div>
                    </div>
                    {config.fileType === FileType.CUSTOMER || config.fileType === FileType.TRANSACTION ? (
                        <DataFileExportOptions state={config.dataFileExportConfig} fileFormat={config.fileFormat} dispatch={dispatch} />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ExportFileConfig
