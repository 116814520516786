import Grid, { GridState } from "../../../library/grid/Grid"
import Lookup from "../../../types/Lookup"
import { GridWidget } from "../types/DashboardConfig"
import FiltersDto, { convertFromArrayToDto, convertFromDtoToArray } from "../../../library/FilterList/FiltersDto"
import GenericFilter from "../../../library/FilterList/filterTypes/GenericFilter"
import RowData from "../../../library/grid/types/RowData"
import EntityConfig from "../../entityConfig/types/EntityConfig"
import { getDataFieldsFromEntityDataFields } from "../../../library/helpers/entityHelpers"
import { GridType } from "../../../microfrontends/dashboard/types/DashboardConfigDto"
import { useEnvConfig } from "../../../contexts/EnvironmentConfigContext"
import { useDownload } from "../../../hooks/useDownload"
import useClient from "../../../hooks/useClient"
import { useToaster } from "../../../hooks/useToaster"
import useApiQuery from "../../../hooks/useApiQuery"
import EntityRelationshipDto from "../../entityConfig/types/EntityRelationshipDto"
import { useState } from "react"
import { getSortFromGridState } from "../../../library/grid/util"

type GridSearchDisplayWidgetProps = {
    entityConfigs: EntityConfig[]
    entityRelationships: EntityRelationshipDto[] | undefined
    widget: GridWidget
    lookups: Lookup[]
    isLeftPanelExpanded: boolean
    advancedFilters: GenericFilter[]
    dashboardFilterGroups: GenericFilter[][]
    dashboardAppliedRoles: string[]
    shouldLoadData?: boolean
    dashboardReference?: string
    isCachingEnabled?: boolean
    setExpandedPanel: (panel: "DEFAULT" | "LEFT_PANEL" | "CUSTOMER_PANEL" | "ACTION_PANEL") => void
    setGroupingFilter: (filter: { fieldName: string; fieldValue: string }) => void
    onEntitySelected: (data: RowData, widget: GridWidget, state?: GridState) => void
}
const GridSearchDisplayWidget = ({
    entityConfigs,
    widget,
    lookups,
    isLeftPanelExpanded,
    advancedFilters,
    dashboardFilterGroups,
    dashboardAppliedRoles,
    setExpandedPanel,
    shouldLoadData = true,
    dashboardReference,
    isCachingEnabled = false,
    setGroupingFilter,
    onEntitySelected
}: GridSearchDisplayWidgetProps) => {
    const selectedEntityType = entityConfigs.find(entityConfig => entityConfig.reference === widget.entityConfigReference) ?? entityConfigs[0]!

    const config = useEnvConfig()
    const download = useDownload()
    const client = useClient()
    const toaster = useToaster()

    const entityConfigDataFields = getDataFieldsFromEntityDataFields(
        entityConfigs.find(entityConfig => entityConfig.reference === widget.entityConfigReference)?.fields ?? []
    )
    const getSelectedConfigFilters = () => (widget.filters !== undefined ? convertFromDtoToArray(widget.filters, entityConfigDataFields, lookups) : [])

    const headerConfigs =
        widget.columns.map((column: any, index: number) => ({
            ...column,
            ordinal: column.ordinal ?? index
        })) ?? []

    const groupingField = selectedEntityType.fields?.find(field => field.fieldName === widget.groupingField)
    const kanbanGroupingField = groupingField && {
        value: groupingField.fieldName,
        label: groupingField.displayName,
        ordinal: 0,
        defaultOrdinal: 0,
        displayType: groupingField.dataType.type,
        isEditable: false,
        lookupReference: groupingField.dataType.lookupReference,
        currencyFieldReference: groupingField.dataType.currencyFieldReference,
        currencyCode: groupingField.dataType.currencyCode,
        format: groupingField.dataType.format
    }

    const getEntityDocumentUrlRequest = useApiQuery<string>({
        url: `${config.INTEGRATION_API_URL}/api/${client}/entity-document-url`,
        method: "GET",
        isExecutedAutomatically: false
    })

    const onFileDownloadClicked = (data: RowData) => {
        getEntityDocumentUrlRequest
            .execute(`?fileName=${data.fields.textFields.text_name}` ?? "")
            .then(response => download.fromUrl(response.data, data.fields.textFields.text_name ?? ""))
            .catch(() => toaster.error("Failed to download file"))
    }

    const [selectedGridState, setSelectedGridState] = useState<GridState | undefined>(undefined)

    const renderRowActions = (rowData: RowData) => {
        if (selectedEntityType.reference === "document") {
            return (
                <Grid.ActionButton
                    rowData={rowData}
                    onClick={() => onFileDownloadClicked(rowData)}
                    icon="fa fa-download"
                    ariaLabelPrefix="download-document"
                    selectOnClick={false}
                />
            )
        } else {
            return (
                <Grid.ActionButton
                    rowData={rowData}
                    onClick={() => onEntitySelected(rowData, widget, selectedGridState)}
                    icon="fa fa-eye"
                    ariaLabelPrefix="select-entity"
                />
            )
        }
    }

    const onNavigateToGroupClicked = (group: string, firstInGroup: RowData) => {
        if (!kanbanGroupingField) return

        setGroupingFilter({
            fieldName: groupingField.fieldName,
            fieldValue: group
        })

        onEntitySelected(firstInGroup, widget, selectedGridState)

        setExpandedPanel("DEFAULT")
    }

    const combinedFilters = combineFilters(advancedFilters, getSelectedConfigFilters())

    const filterGroupsToDto = (filterGroups: GenericFilter[][]): FiltersDto[] => {
        return filterGroups.map(filterGroup => convertFromArrayToDto(filterGroup))
    }

    const dashboardFilters = filterGroupsToDto(dashboardFilterGroups)
    const sort = getSortFromGridState(selectedGridState) ?? widget.defaultSort ?? undefined

    return (
        <Grid
            reference={widget.reference}
            lookups={lookups}
            defaultPageSize={widget.type === GridType.KANBAN ? 1000 : 20}
            defaultSort={sort}
            colour={isLeftPanelExpanded ? "blue" : "grey"}
            ariaLabel="entities"
            setGlobalState={(state: GridState) => setSelectedGridState(state)}
            shouldLoadData={shouldLoadData}
            dashboardReference={dashboardReference}
            isCachingEnabled={isCachingEnabled}
        >
            <Grid.Header>
                <Grid.AutoCompleteEntitySearch entityConfig={selectedEntityType} filters={combinedFilters} />
                <Grid.GlobalActions classname="ms-auto" />
            </Grid.Header>
            <Grid.EntityTable
                entityConfig={selectedEntityType}
                filters={combinedFilters}
                dashboardFilterGroups={dashboardFilters}
                dashboardAppliedRoles={dashboardAppliedRoles}
                headerConfigs={headerConfigs}
            >
                {widget.type === GridType.KANBAN && kanbanGroupingField ? (
                    <Grid.Kanban groupingField={kanbanGroupingField} onNavigateToGroupClicked={onNavigateToGroupClicked} entityConfig={selectedEntityType} />
                ) : (
                    <Grid.Rows
                        selectableRows={true}
                        renderRowActions={renderRowActions}
                        entityConfig={selectedEntityType}
                        filters={convertFromDtoToArray(combinedFilters, entityConfigDataFields, lookups)}
                        dashboardFilterGroups={dashboardFilters}
                        dashboardAppliedRoles={dashboardAppliedRoles}
                    />
                )}
            </Grid.EntityTable>
            {(widget.type !== GridType.KANBAN || kanbanGroupingField === undefined) && (
                <Grid.Footer>
                    <Grid.Selection />
                    <Grid.Pagination isCompact={!isLeftPanelExpanded} />
                </Grid.Footer>
            )}
        </Grid>
    )
}

const combineFilters = (advancedFilterList: GenericFilter[], widgetFilters: GenericFilter[]): FiltersDto => {
    const firstFilters = convertFromArrayToDto(advancedFilterList)
    const secondFilters = convertFromArrayToDto(widgetFilters)

    return {
        textFieldIsOneOf: [...(firstFilters.textFieldIsOneOf ?? []), ...(secondFilters.textFieldIsOneOf ?? [])],
        textFieldStartsWith: [...(firstFilters.textFieldStartsWith ?? []), ...(secondFilters.textFieldStartsWith ?? [])],
        booleanFieldIsEqualTo: [...(firstFilters.booleanFieldIsEqualTo ?? []), ...(secondFilters.booleanFieldIsEqualTo ?? [])],
        numberFieldIsOneOf: [...(firstFilters.numberFieldIsOneOf ?? []), ...(secondFilters.numberFieldIsOneOf ?? [])],
        numberFieldMatchesOperation: [...(firstFilters.numberFieldMatchesOperation ?? []), ...(secondFilters.numberFieldMatchesOperation ?? [])],
        dateFieldIsOneOf: [...(firstFilters.dateFieldIsOneOf ?? []), ...(secondFilters.dateFieldIsOneOf ?? [])],
        dateFieldMatchesOperation: [...(firstFilters.dateFieldMatchesOperation ?? []), ...(secondFilters.dateFieldMatchesOperation ?? [])],
        dateFieldIsRelativeTo: [...(firstFilters.dateFieldIsRelativeTo ?? []), ...(secondFilters.dateFieldIsRelativeTo ?? [])],
        fieldExists: [...(firstFilters.fieldExists ?? []), ...(secondFilters.fieldExists ?? [])]
    }
}

export default GridSearchDisplayWidget
