import ListCard from "../../../../library/listCards/ListCard"
import classes from "./FlowCard.module.scss"
import Flow from "../../types/Flow"
import { useClient, useConfig } from "invevo-react-components"
import useApiQuery from "../../../../hooks/useApiQuery"
import IconButton from "../../../../library/buttons/IconButton/IconButton"

type FlowCardProps = {
    flow: Flow
    entityConfigDisplayName: string
    isSelected: boolean
    onFlowSelected: (flowReference: string) => void
    onFlowRemoved: (flowReference: string) => void
}

const FlowCard = ({ flow, entityConfigDisplayName, isSelected, onFlowSelected, onFlowRemoved }: FlowCardProps) => {
    const config = useConfig()
    const client = useClient()
    const deleteFlowRequest = useApiQuery({
        url: `${config.FLOW_API_URL}/api/${client}/flow`,
        method: "DELETE",
        isExecutedAutomatically: false
    })

    const onCardClicked = () => onFlowSelected(flow.reference)
    const onRemoveClicked = () => deleteFlowRequest.execute(flow.reference).then(() => onFlowRemoved(flow.reference))

    return (
        <ListCard isSelected={isSelected} onSelect={onCardClicked}>
            <div className="d-flex align-items-center my-2">
                <i className="fa-regular fa-diagram-project fa-2x text-white" />
                <span className="ms-3 fs-5">{flow.displayName}</span>
                <IconButton
                    className="ms-auto me-2"
                    icon="fal fa-trash"
                    theme={isSelected ? "mid-blue" : "blue"}
                    size="small"
                    isLoading={deleteFlowRequest.isFetching}
                    onClick={onRemoveClicked}
                />
            </div>
            <div className={`${classes.separator} d-flex flex-row my-2 bg-grey`} />
            <div className="d-flex">
                <span>{entityConfigDisplayName}</span>
                <span className="ms-auto">{flow.nodes.length} Nodes</span>
            </div>
        </ListCard>
    )
}

export default FlowCard
