import { ProgressButton, Overlay, CheckboxList } from 'invevo-react-components'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

const WorkflowDuplicationOverlay = ({ selectedWorkflowRule, onDuplicate, onClose }) => {
    const [filterOptions, setFilterOptions] = useState([])
    const [actionOptions, setActionOptions] = useState([])
    const [allFiltersChecked, setAllFiltersChecked] = useState(false)
    const [allActionsChecked, setAllActionsChecked] = useState(false)

    const getFilterOptions = (filters) => {
        var result = filters.length > 0 ? filters.map(filter => {
            return {
                id: filter.id,
                label: `${filter.fieldName} - ${filter.operator ? filter.operator.value : ''} - ${filter.value}`,
                isSelected: false
            }
        }) : []

        return result
    }

    const getActionOptions = (actions) => {
        var result = actions.length > 0 ? actions.map(action => {
            return {
                id: action.reference,
                label: `SMS Action - ${action.template ? action.template.value : ''} - Filters: ${action.customerFilters.length}`,
                isSelected: false
            }
        }) : []

        return result
    }

    useEffect(() => {
        setFilterOptions(getFilterOptions(selectedWorkflowRule.customerFilters))
        setActionOptions(getActionOptions(selectedWorkflowRule.smsSendActions))
    }, [selectedWorkflowRule])

    const onFilterOptionChecked = (checkedItem) => {
        setFilterOptions(filterOptions.map(item => {
            if (item.id !== checkedItem.id) return item

            return { ...item, isSelected: !item.isSelected }
        }))
    }

    const onActionOptionChecked = (checkedItem) => {
        setActionOptions(actionOptions.map(item => {
            if (item.id !== checkedItem.id) return item

            return { ...item, isSelected: !item.isSelected }
        }))
    }

    const onDuplicateWorkflow = () => {
        var filters = filterOptions.filter(option => allFiltersChecked ? true : option.isSelected)
            .map(selectedOption => selectedWorkflowRule.customerFilters.filter(filter => filter.id === selectedOption.id)[0])

        var actions = actionOptions.filter(option => allActionsChecked ? true : option.isSelected)
            .map(selectedOption => selectedWorkflowRule.smsSendActions.filter(action => action.reference === selectedOption.id)[0])
            .map(action => { return { ...action, reference: uuidv4() } })

        var workflowRule = {
            ...selectedWorkflowRule,
            reference: uuidv4(),
            name: `Copy of ${selectedWorkflowRule.name}`,
            isNew: true,
            customerFilters: filters,
            smsSendActions: actions
        }

        return onDuplicate(true, workflowRule)
    }

    const onCloseOverlay = () => {
        setFilterOptions(filterOptions.map(option => { return { ...option, isSelected: false } }))
        setAllFiltersChecked(false)
        setActionOptions(actionOptions.map(option => { return { ...option, isSelected: false } }))
        setAllActionsChecked(false)
        onClose()
    }

    return (
        <div className={`d-flex`}>
            <Overlay title="Please select data to copy" onCloseClick={() => onCloseOverlay()} isSizable={true}>
                {filterOptions.length > 0 &&
                    <CheckboxList
                        className="p-4 bg-blue"
                        items={filterOptions}
                        onItemChecked={onFilterOptionChecked}
                        allOptionEnabled={true}
                        allOptionLabel="All filters"
                        allChecked={allFiltersChecked}
                        onAllChecked={() => setAllFiltersChecked(!allFiltersChecked)}
                    />}
                {actionOptions.length > 0 &&
                    <CheckboxList
                        className="p-4 bg-blue"
                        items={actionOptions}
                        onItemChecked={onActionOptionChecked}
                        allOptionEnabled={true}
                        allOptionLabel="All actions"
                        allChecked={allActionsChecked}
                        onAllChecked={() => setAllActionsChecked(!allActionsChecked)}
                    />}
                <ProgressButton
                    className="w-100 my-1"
                    iconClasses="fas fa-clone"
                    label="Duplicate"
                    colour="blue"
                    succeededText="Success"
                    failedText="Failed to duplicate workflow"
                    onClickWithPromise={() => {
                        const promise = new Promise((resolve, reject) => {
                            onDuplicateWorkflow(false)
                                .then(_ => resolve(), _ => reject())
                                .catch(_ => reject())
                        })
                        return promise
                    }}
                    disabled={selectedWorkflowRule.isNew}
                />
            </Overlay>
        </div>
    )
}

export default WorkflowDuplicationOverlay