import classes from "./AppliedFilter.module.scss"

const AppliedFilter = ({ text, selectedAggregation, ariaLabel, showIcon, onAggregationRemoved }) => {

    const onRemoveFilterClicked = () => onAggregationRemoved(selectedAggregation)

    return (
        <div className={`ms-2 ${classes.selectedFilter} px-2 py-1 mb-2 d-flex align-items-center`} aria-label={ariaLabel}>
            <span>{text}</span>
            {showIcon && <i className="ms-2 fa-light fa-times-circle pointer" role="button" aria-label={`${selectedAggregation.label}-remove`} onClick={onRemoveFilterClicked}></i>}
        </div>
    )
}

export default AppliedFilter