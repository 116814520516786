import { Dispatch, ReactNode, ReactPortal, SetStateAction, createContext, useContext, useEffect, useState } from "react"
import HeaderDefinition from "./types/HeaderDefinition"
import Lookup from "../../types/Lookup"
import { DataTypeEnum, getDataPrimitiveTypeForEntityDataType } from "../../routes/entityConfig/types/DataType"
import RowData from "./types/RowData"
import DataTable from "./DataTable"
import EntityTable from "./EntityTable"
import Pagination from "./actions/Pagination"
import QuickFilters from "./actions/QuickFilters"
import classes from "./Grid.module.scss"
import Search from "./actions/Search"
import Rows from "./display/Rows"
import Cards from "./display/Cards"
import useApiQuery from "../../hooks/useApiQuery"
import { useEnvConfig } from "../../contexts/EnvironmentConfigContext"
import useClient from "../../hooks/useClient"
import { useUser } from "../../contexts/UserContext"
import PreviousNextButtons from "./actions/PreviousNextButtons"
import ActionButton from "./actions/ActionButton"
import Kanban from "./display/Kanban"
import { GridSortDto } from "../../routes/entitySearch/types/DashboardConfig"
import { createPortal } from "react-dom"
import GlobalActions from "./actions/GlobalActions"
import DataPrimitive from "../../types/DataPrimitive"
import Selection from "./actions/Selection"
import FiltersDto from "../FilterList/FiltersDto"
import AutoCompleteEntitySearch from "./actions/AutoCompleteEntitySearch"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import { useEvent } from "../../contexts/EventContext"
import Entity from "./types/Entity"

type GridContextValue = {
    reference: string
    colour: "blue" | "grey"
    potentialHeaders: HeaderDefinition[]
    defaultHeaders: HeaderDefinition[]
    displayedHeaders: HeaderDefinition[]
    lookups: Lookup[]
    sorting: { header: HeaderDefinition; direction: "ASC" | "DESC" }
    paging: { pageIndex: number; pageSize: number }
    quickSearchValue: string
    data: { rows: RowData[]; optimisticallyUpdatedRows: RowData[]; totalAcrossPages: number; isLoading: boolean }
    selection: { selectedRefs: string[]; isAllRowsSelected: boolean }
    onRowsUpdated: (updates: { rowReference: string; header: HeaderDefinition; newValue: string }[]) => void
    setPotentialHeaders: Dispatch<SetStateAction<HeaderDefinition[]>>
    setDefaultHeaders: (headers: HeaderDefinition[]) => void
    setUserConfiguredHeaders: Dispatch<SetStateAction<HeaderDefinition[] | undefined>>
    toggleSort: (headerValue: string) => void
    setPaging: Dispatch<SetStateAction<{ pageIndex: number; pageSize: number }>>
    setQuickSearchValue: Dispatch<SetStateAction<string>>
    setData: Dispatch<SetStateAction<{ rows: RowData[]; optimisticallyUpdatedRows: RowData[]; totalAcrossPages: number; isLoading: boolean }>>
    setFilters: Dispatch<SetStateAction<FiltersDto>>
    resetFilters: () => void
    onSelect: (selection: { selectedRefs: string[]; isAllRowsSelected: boolean }, shouldApplySelect?: boolean) => void
    setOnRowsUpdated: Dispatch<SetStateAction<(updates: { rowReference: string; header: HeaderDefinition; newValue: string }[]) => void>>
    globalActionsPortal: (component: ReactNode) => ReactPortal | undefined
    filters?: FiltersDto
    isFilteredToSelection: boolean
    setIsFilteredToSelection: Dispatch<SetStateAction<boolean>>
    searchAfterIds: { [pageNumber: number]: Entity }
    setSearchAfterIds: Dispatch<SetStateAction<{ [pageNumber: number]: Entity }>>
    shouldLoadData: boolean
}

const GridContext = createContext<GridContextValue | undefined>(undefined)

export type GridState = {
    sorting: { header: HeaderDefinition; direction: "ASC" | "DESC" }
    paging: { pageIndex: number; pageSize: number }
    filters?: FiltersDto
    searchAfterIds: { [pageNumber: number]: Entity }
}

const DEFAULT_GRID_SORT = {
    header: {
        value: "reference",
        label: "Reference",
        ordinal: 0,
        defaultOrdinal: 0,
        displayType: DataTypeEnum.TEXT,
        isEditable: false
    },
    direction: "DESC"
} as const

type Props = {
    reference: string
    children: ReactNode
    lookups?: Lookup[]
    ariaLabel?: string
    defaultPageSize?: number
    defaultSort?: GridSortDto
    colour?: "blue" | "grey"
    onSelected?: (selectedRefs: string[]) => void
    initialSelection?: string[]
    initialState?: GridState
    setGlobalState?: (state: GridState) => void
    shouldLoadData?: boolean
    dashboardReference?: string
    isCachingEnabled?: boolean
    parentReference?: string
}

const Grid = ({
    reference,
    children,
    lookups = [],
    ariaLabel,
    defaultPageSize = 1000,
    defaultSort = {
        fieldName: DEFAULT_GRID_SORT.header.value,
        dataPrimitive: DataPrimitive[getDataPrimitiveTypeForEntityDataType(DEFAULT_GRID_SORT.header.displayType) ?? "TEXT"],
        direction: DEFAULT_GRID_SORT.direction
    },
    colour = "blue",
    onSelected,
    initialSelection = [],
    initialState,
    setGlobalState,
    shouldLoadData = true,
    dashboardReference = "grid",
    parentReference,
    isCachingEnabled = false
}: Props) => {
    const config = useEnvConfig()
    const client = useClient()
    const currentUser = useUser()

    const [potentialHeaders, setPotentialHeaders] = useState<HeaderDefinition[]>([])
    const [defaultHeaders, setDefaultHeaders] = useState<HeaderDefinition[]>([])
    const [userConfiguredHeaders, setUserConfiguredHeaders] = useState<HeaderDefinition[] | undefined>()
    const [sorting, setSorting, resetSorting, isSortingLoaded] = useLocalStorage<{ header: HeaderDefinition; direction: "ASC" | "DESC" }>(
        `${client}/entitySearch/${dashboardReference}/${reference}/sorting`,
        initialState?.sorting ?? DEFAULT_GRID_SORT,
        isCachingEnabled
    )

    const onDefaultHeadersSet = (headers: HeaderDefinition[]) => {
        setDefaultHeaders(headers)

        const headerFieldName = isCachingEnabled && initialState ? sorting.header.value : defaultSort.fieldName
        const header = (userConfiguredHeaders ?? headers).find(header => header.value === headerFieldName)

        if (!header) return

        const direction = isCachingEnabled && initialState ? sorting.direction : defaultSort.direction
        setSorting({ header, direction })
    }

    const [paging, setPaging] = useState<{ pageIndex: number; pageSize: number }>(initialState?.paging ?? { pageIndex: 0, pageSize: defaultPageSize })
    const [searchAfterIds, setSearchAfterIds] = useState<{ [pageNumber: number]: Entity }>(initialState?.searchAfterIds ?? {})
    const [quickSearchValue, setQuickSearchValue] = useState<string>("")
    const [data, setData] = useState<{ rows: RowData[]; optimisticallyUpdatedRows: RowData[]; totalAcrossPages: number; isLoading: boolean }>({
        rows: [],
        optimisticallyUpdatedRows: [],
        totalAcrossPages: 0,
        isLoading: false
    })
    const [filters, setFilters, resetFilters, areFiltersLoaded] = useLocalStorage<FiltersDto>(
        `${client}/entitySearch/${dashboardReference}/${reference}/filters`,
        {},
        isCachingEnabled
    )

    const [selection, setSelection, resetSelection, isSelectionLoaded] = useLocalStorage<{ selectedRefs: string[]; isAllRowsSelected: boolean }>(
        parentReference
            ? `${client}/entitySearch/${dashboardReference}/${parentReference}/${reference}/selection`
            : `${client}/entitySearch/${dashboardReference}/${reference}/selection`,
        { selectedRefs: initialSelection, isAllRowsSelected: false },
        isCachingEnabled
    )

    const [isFilteredToSelection, setIsFilteredToSelection, resetFilteredToSelection, isFilteredToSelectionLoaded] = useLocalStorage(
        `${client}/entitySearch/${dashboardReference}/${reference}/filteredToSelection`,
        false,
        isCachingEnabled
    )

    useEffect(() => {
        if (!setGlobalState) return
        if (!areFiltersLoaded || !isSelectionLoaded || !isFilteredToSelectionLoaded || !isSortingLoaded) return

        const filtersToApply = isFilteredToSelection
            ? {
                  ...filters,
                  textFieldIsOneOf: [
                      ...(filters.textFieldIsOneOf ?? []),
                      {
                          fieldName: "reference",
                          values: selection.selectedRefs,
                          notOneOf: false
                      }
                  ]
              }
            : filters
        setGlobalState({
            sorting,
            paging,
            filters: filtersToApply,
            searchAfterIds
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        paging,
        searchAfterIds,
        sorting,
        selection,
        isSelectionLoaded,
        filters,
        areFiltersLoaded,
        isFilteredToSelection,
        isFilteredToSelectionLoaded,
        isSortingLoaded
    ])

    const { subscribe, unsubscribe } = useEvent()
    useEffect(() => {
        const callback = (_: string) => {
            resetFilters()
            resetSelection()
            resetFilteredToSelection()
            resetSorting()
        }
        if (dashboardReference) subscribe("clear_dashboard_cache", { key: dashboardReference, callback })

        return () => {
            if (dashboardReference) unsubscribe("clear_dashboard_cache", dashboardReference)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [onRowsUpdated, setOnRowsUpdated] = useState<(updates: { rowReference: string; header: HeaderDefinition; newValue: string }[]) => void>(
        () => () => {}
    )
    const getGridConfigRequest = useApiQuery<{ fieldName: string; ordinal: number }[]>({
        url: `${config.DATA_API_URL}/api/${client}/user-grid-config/${currentUser.username}?gridReference=${reference}`,
        method: "GET"
    })

    useEffect(() => {
        if (!getGridConfigRequest.data) return
        if (getGridConfigRequest.data.length === 0) return

        const configuredHeaders = getGridConfigRequest.data
            .map(config => {
                const field = potentialHeaders.find(header => header.value === config.fieldName)
                if (!field) return undefined

                return {
                    ...field,
                    ordinal: config.ordinal
                }
            })
            .filter(Boolean)

        setUserConfiguredHeaders(configuredHeaders)
    }, [getGridConfigRequest.data, potentialHeaders])

    const toggleSort = (headerValue: string) => {
        const sortedHeader = (userConfiguredHeaders ?? defaultHeaders).find(header => header.value === headerValue)
        if (!sortedHeader) return

        if (sorting?.header?.value === headerValue) {
            setSorting(sorting => ({ header: sortedHeader, direction: sorting.direction === "ASC" ? "DESC" : "ASC" }))
            return
        }

        setSorting({ header: sortedHeader, direction: "ASC" })
        setPaging(current => ({ pageIndex: 0, pageSize: current.pageSize, searchAfterIds: {} }))
    }

    const onSelect = (selection: { selectedRefs: string[]; isAllRowsSelected: boolean }, shouldApplySelect?: boolean) => {
        setSelection(selection)
        if (onSelected && shouldApplySelect) onSelected(selection.selectedRefs)
    }

    const globalActionsPortal = (component: ReactNode) => {
        const globalActionHandle = document.getElementById(`globalActionHandle-${reference}`)
        if (!globalActionHandle) return
        return createPortal(component, globalActionHandle)
    }

    const value = {
        reference,
        colour,
        potentialHeaders,
        defaultHeaders,
        displayedHeaders: (userConfiguredHeaders ?? defaultHeaders).filter((header, index, self) => self.findIndex(h => h.value === header.value) === index),
        lookups,
        sorting,
        paging,
        quickSearchValue,
        data: {
            rows: data.rows,
            optimisticallyUpdatedRows: data.optimisticallyUpdatedRows,
            totalAcrossPages: data.totalAcrossPages,
            isLoading: data.isLoading || getGridConfigRequest.isFetching
        },
        selection,
        onRowsUpdated,
        setPotentialHeaders,
        setDefaultHeaders: onDefaultHeadersSet,
        setUserConfiguredHeaders,
        toggleSort,
        setPaging,
        setQuickSearchValue,
        setData,
        setFilters,
        resetFilters,
        onSelect,
        setOnRowsUpdated,
        globalActionsPortal,
        filters,
        isFilteredToSelection,
        setIsFilteredToSelection,
        searchAfterIds,
        setSearchAfterIds,
        shouldLoadData: shouldLoadData && areFiltersLoaded && isSelectionLoaded && isFilteredToSelectionLoaded && isSortingLoaded
    }

    return (
        <GridContext.Provider value={value}>
            <div className={`d-flex flex-grow-1 flex-column overflow-auto rounded h-100 bg-${colour}`} role="grid" aria-label={ariaLabel}>
                {children}
            </div>
        </GridContext.Provider>
    )
}

export const useGrid = () => {
    const context = useContext(GridContext)

    if (!context) throw new Error(`useGrid must be used within a <GridProvider>`)
    return context
}

Grid.DataTable = DataTable
Grid.EntityTable = EntityTable
Grid.Pagination = Pagination
Grid.Selection = Selection
Grid.QuickFilters = QuickFilters
Grid.Search = Search
Grid.AutoCompleteEntitySearch = AutoCompleteEntitySearch
Grid.GlobalActions = GlobalActions
Grid.PreviousNextButtons = PreviousNextButtons
Grid.ActionButton = ActionButton
Grid.Rows = Rows
Grid.Cards = Cards
Grid.Kanban = Kanban
Grid.Footer = (props: { children: ReactNode }) => <div className="d-flex align-items-center bg-grey p-2 rounded-bottom">{props.children}</div>
Grid.Header = (props: { children: ReactNode }) => <div className={`d-flex align-items-center p-2 gap-2 rounded-top ${classes.header}`}>{props.children}</div>

export default Grid
