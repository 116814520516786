import classes from './Header.module.scss'

const Header = ({className, children}) => {
    return (
        <div className={`d-flex align-items-center ${classes.header} px-3 bg-grey ${className ? className : ''}`}>
            <h2 className={`text-grey no-select mb-0 ${children ? 'me-4' : ''}`}>User Groups</h2>
            {children}
        </div>
    )
}

export default Header