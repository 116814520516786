import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getApiUrl } from '../../constants';
import { storeGridState } from '../../actions/gridCacheActions';
import { selectInternalContacts } from '../../actions/functionActions';
import { executeAuthAsyncPost } from '../../utility/asyncSupport';
import { GridColumn } from '@progress/kendo-react-grid';
import { remove, map } from 'lodash';
import StatefulGrid from '../common/grids/StatefulGrid';
import ContactCard from './contactCard';
import existential from '../../utility/existential';

class InternalContactsGrid extends React.Component {
  lastSelectIndex = 0;

  constructor(props, context) {
    super(props, context);
    const selectedItems = props.internalContacts.map((c) => ({
      ContactId: c.contactId,
      Email: c.email,
      Name: c.name,
      Mobile: c.mobile,
      SMSRecipient: c.SMSRecipient
    }));

    this.state = {
      refresh: 0,
      selectedItems
    };

    this.internalContactsGridRef = new React.createRef();
  }

  refresh = () => {
    this.setState({ refresh: this.state.refresh + 1 });
  };

  componentDidMount() {
    if (this.props.methodCallback != null) {
      this.props.methodCallback(this.refresh.bind(this));
    }
  }

  onStateChange = (data) => {
    this.props.storeGridState(InternalContactsGrid.getKey(), data);
  };

  isSelectedRow = (dataItem) =>
    !!this.state.selectedItems.find(
      (item) => item.ContactId === dataItem.ContactId
    );

  onRowClick = (gridRowEvent) => {
    const dataItem = gridRowEvent.dataItem;
    const grid = this.internalContactsGridRef.current.state.data;
    let newSelectedRowStatus = !this.isSelectedRow(dataItem);
    let selectedItems = [...this.state.selectedItems];

    let last = this.lastSelectIndex;
    const current = grid.findIndex((d) => d.ContactId === dataItem.ContactId);

    if (!gridRowEvent.nativeEvent.shiftKey) {
      this.lastSelectIndex = last = current;
    }

    if (!gridRowEvent.nativeEvent.ctrlKey) {
      newSelectedRowStatus = true;
      selectedItems = [];
    }

    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      const gridDataItem = grid[i];

      const gridItemIsSelected = !!selectedItems.find(
        (item) => item.ContactId === gridDataItem.ContactId
      );

      if (gridItemIsSelected !== newSelectedRowStatus) {
        if (gridItemIsSelected) {
          remove(
            selectedItems,
            (item) => gridDataItem.ContactId === item.ContactId
          );
        } else {
          selectedItems.push({
            ContactId: gridDataItem.ContactId,
            Email: gridDataItem.Email,
            Name: gridDataItem.Name,
            Mobile: gridDataItem.Mobile,
            SMSRecipient: gridDataItem.SMSRecipient
          });
        }
      }
    }

    if (this.props.onRowSelection) {
      if (selectedItems && selectedItems.length > 0) {
        const item = selectedItems[0];
        this.props.onRowSelection(item.ContactId, item.Email, item.Name);
      } else {
        this.props.onRowSelection('', '', '');
      }
    }

    if (this.props.selectInternalContacts) {
      this.props.selectInternalContacts(
        map(selectedItems, (r) => ({
          contactId: r.ContactId,
          email: r.Email,
          name: r.Name,
          mobile: r.Mobile,
          SMSRecipient: r.SMSRecipient
        }))
      );
    }
    
    this.props.onSelectedInternalContactsUpdated(map(selectedItems, (r) => ({
      contactId: r.ContactId,
      email: r.Email,
      name: r.Name,
      mobile: r.Mobile,
      SMSRecipient: r.SMSRecipient
    })))

    this.setState({
      selectedItems
    });
  };

  additionalRequestPayload = () => {
    if (this.props.selectedAccount.miaAccountIsVirtualAccount)
      return {
        id: this.props.selectedAccount.miaAccountId,
        contactType: this.props.customerType
      };

    return {
      customerid: this.props.selectedAccount.miaAccountId,
      contactType: this.props.customerType
    };
  };

  static getKey() {
    return 'internal-contacts-grid-key';
  }

  resultCard = (e) => (
    <ContactCard
      dataItem={e.dataItem}
      isInternal={true}
      onClick={this.onRowClick}
    />
  );

  rowRender(trElement) {
    const trProps = { className: 'col-lg-4 col-6 top15' };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  }

  render() {
    const apiUrl =
      getApiUrl(this.props.client) +
      (this.props.selectedAccount.miaAccountIsVirtualAccount
        ? 'api/virtualaccount/vacontactgrid'
        : 'api/grid/contacts');

    const nameFilter =
      this.props.filterByName !== ''
        ? {
          filters: [
            {
              field: 'Name',
              operator: 'contains',
              value: this.props.filterByName
            }
          ]
        }
        : null;

    return (
      <StatefulGrid
        client={this.props.client}
        id={InternalContactsGrid.getKey()}
        key={InternalContactsGrid.getKey()}
        path={apiUrl}
        sortable
        pageable={{
          pageSizes: [50, 100, 200],
          refresh: true,
          buttonCount: 5
        }}
        onStateChange={this.onStateChange}
        onRowClick={this.onRowClick}
        additionalRequestPayload={this.additionalRequestPayload()}
        defaultField={{ field: 'Name', dir: 'asc' }}
        isSelectedRow={this.isSelectedRow}
        hideablePaging={true}
        className={'hide-header single-cell scrollable-none row row-sm-gutters'}
        filter={nameFilter}
        refresh={this.state.refresh}
        ref={this.internalContactsGridRef}
        rowRender={this.rowRender}
      >
        <GridColumn cell={this.resultCard} />
        
      </StatefulGrid>
    );
  }
}

InternalContactsGrid.propTypes = {
  filterByName: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  return {
    gridState: existential(
      state.gridCacheReducer.grids[InternalContactsGrid.getKey()],
      'gridState',
      {}
    ),
    selectedAccount: state.currentSelectionReducer.selectedAccount,
    internalContacts: state.functionReducer.internalContacts
  };
};

export default connect(mapStateToProps, {
  storeGridState,
  executeAuthAsyncPost,
  selectInternalContacts
})(InternalContactsGrid);
