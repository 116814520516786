import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLookup } from '../../legacy/api/lookupApi';

import UserGrid from '../../legacy/components/admin/users/userGrid';
import AddUser from '../../legacy/components/admin/users/addUser.js';
import { Collapse } from 'react-bootstrap';

class UserManagementContainer extends Component {
  _mounted = false;

  constructor(props, context) {
    super(props, context);

    this.userSaved = this.userSaved.bind(this);
    this.addUserCancelled = this.addUserCancelled.bind(this);
    this.userGridRefreshCallback = this.userGridRefreshCallback.bind(this);
    this.addUserCallback = this.addUserCallback.bind(this);
    this.editUserCallback = this.editUserCallback.bind(this);
    this.onEditUser = this.onEditUser.bind(this);

    this.state = {
      showAdd: false,
      showGrid: true
    };
  }

  componentDidMount() {
    this.props.getLookup(this.props.client, 'lookup/roles', 'roles');
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  onAddNewUser() {
    this.setState({
      showAdd: true,
      showGrid: false
    });
    this.addUser(this.props.roles);
  }

  addUserCallback(addUserFunction) {
    this.addUser = addUserFunction;
  }

  editUserCallback(editUserFunction) {
    this.editUser = editUserFunction;
  }

  onEditUser(userId) {
    this.setState({
      showAdd: true,
      showGrid: false
    });
    this.editUser(userId, this.props.roles);
  }

  userGridRefreshCallback(refreshGrid) {
    this.refreshGrid = refreshGrid;
  }

  userSaved() {
    if (this._mounted) {
      this.setState({
        showAdd: false,
        showGrid: true
      });
      this.refreshGrid();
      this.setState({ showAddUserButton: true });
    }
  }

  addUserCancelled() {
    if (this._mounted) {
      this.setState({
        showAdd: false,
        showGrid: true
      });
      this.refreshGrid();
    }
  }

  render() {
    return (
      <div>
        <h2>User Management</h2>
        <Collapse in={this.state.showGrid}>
          <div>
            <UserGrid
              client={this.props.client}
              refreshCallback={this.userGridRefreshCallback}
              onEdit={this.onEditUser}
            />

            <button
              id="btn-add-user"
              className="btn btn-default"
              type="button"
              onClick={this.onAddNewUser.bind(this)}
            >
              Add New User
            </button>
          </div>
        </Collapse>
        <Collapse in={this.state.showAdd}>
          <div>
            <AddUser
              client={this.props.client}
              onSaved={this.userSaved}
              onCancelled={this.addUserCancelled}
              onAdd={this.addUserCallback}
              onEdit={this.editUserCallback}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.lookupReducer.roles.results
});

export default connect(
  mapStateToProps,
  {
    getLookup
  },
  null,
  { forwardRef: true }
)(UserManagementContainer);
