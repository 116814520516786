import classes from "./NoteCard.module.scss"
import moment from "moment"
import Note from "./types/Note"
import { useState } from "react"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"

type Props = {
    note: Note
    isParentNote: boolean
}

const typeColours = {
    comment: "bg-yellow",
    "field update": "bg-green",
    payment: "bg-purple",
    communication: "bg-blue",
    customer: "bg-yellow",
    "virtual account": "bg-yellow",
    task: "bg-pink",
    transaction: "bg-green"
} as const

const NoteCard = ({ note, isParentNote }: Props) => {
    const { isEnabled: useLocalTimeForNotes } = useFeatureToggle("seeNotesInLocalTime")

    const showChildText = (note.noteType === "field update" || note.noteType === "comment") && !isParentNote
    const concatChildren = note.noteIds.length > 1
    const [isChildrenExpanded, setIsChildrenExpanded] = useState(false)
    const toggleChildrenExpanded = () => setIsChildrenExpanded(e => !e)
    return (
        <div className={`d-flex w-100 flex-column border border-white shadow border-4 text-grey ${classes[typeColours[note.noteType]]}`}>
            <div className="d-flex flex-column p-2 gap-1 border-bottom border-white border-1">
                <div className="d-flex gap-3">
                    <span className="flex-grow-1 text-uppercase">
                        {note.noteType === "field update" || note.noteType === "comment" ? <strong>{`${note.entityDisplayName} `}</strong> : ""}
                        <small>{note.noteType}</small>
                    </span>
                    <strong className="text-uppercase text-truncate">{note.userName}</strong>
                    <strong className="text-nowrap" aria-label={"note-date"}>
                        {useLocalTimeForNotes
                            ? moment(note.noteEpochTime).format("DD MMM yyyy HH:mm")
                            : moment.utc(note.noteEpochTime).format("DD MMM yyyy HH:mm")}
                        {!useLocalTimeForNotes && " UTC"}
                    </strong>
                </div>
                {showChildText && (
                    <div className="d-flex flex-row align-items-center gap-1 small">
                        <em className="d-flex gap-1">On {concatChildren ? `${note.noteIds.length} children` : `child ${note.noteId}`}</em>
                        {concatChildren &&
                            (isChildrenExpanded ? (
                                <div className="d-flex flex-row gap-1">
                                    <strong role="button" className="user-select-none" onClick={toggleChildrenExpanded}>
                                        (Hide)
                                    </strong>
                                    <em>{note.noteIds.join(", ")}</em>
                                </div>
                            ) : (
                                <strong role="button" className="user-select-none" onClick={toggleChildrenExpanded}>
                                    (Show)
                                </strong>
                            ))}
                    </div>
                )}
            </div>
            <div className="d-flex flex-column p-2">{note.note}</div>
        </div>
    )
}

export default NoteCard
