import React, { Component } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { connect } from 'react-redux';
import { CommandCell } from '../../common/grids/commandCell';
import { ConfrimDialog } from '../../common/confirmDialog';

class DocumentsGrid extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showDeleteDialog: false,
      itemToDeleteId: ''
    };

    this.CommandCell = CommandCell({
      onCommandClick: this.onDeleteRowClicked,
      cssTag: 'Delete',
      buttonText: 'Delete'
    });
  }

  parseDocuments = () =>
    this.props.templateList.map(template => {
      var actBrand = this.props.brands.filter(b => b.Id === template.brandInternalId)[0]
      return template.documents.map(d => {
        return { ...d, ...actBrand }
      })
    }).reduce((a, b) => a.concat(b))

  onDeleteRowClicked = (e, dataItem) => {
    e.preventDefault();
    const deleteId = dataItem.id;
    this.setState({
      showDeleteDialog: true,
      itemToDeleteId: deleteId
    });
  };

  deleteItem = () => {
    if (this.state.itemToDeleteId !== '') {
      this.props.onDeleteDocument(this.state.itemToDeleteId);
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.setState({
      showDeleteDialog: false
    });
  };

  getGridKey() {
    return 'grid-files';
  }

  render() {
    const grid =
      this.props.templateList && this.props.templateList.length > 0 ?
        <Grid
          ref={(grid) => (this.grid = grid)}
          key={this.getGridKey()}
          data={this.parseDocuments()}
        >
          <GridColumn field={'languageName'} title={'Language'} />
          <GridColumn field={'Name'} title={'Brand'} />
          <GridColumn field={'originalName'} title={'Template Document'} />

          <GridColumn cell={this.CommandCell} width={'250px'} />
        </Grid>
        :
        <div className="col-12 text-center">
          <div className="form-group">
            <i className="fa fa-sync" />
          </div>
        </div>

    return (
      <div id="documents-grid-container">
        {grid}

        <ConfrimDialog
          show={this.state.showDeleteDialog}
          message={'Are you sure you want to delete this record?'}
          onConfirm={this.deleteItem}
          onClose={this.closeDialog}
        />
      </div>
    );
  }
}

export default connect()(DocumentsGrid);
