export const showAdvSearch = (isVisible) => ({
    type: 'SHOW_ADV_SEARCH',
    isVisible
  });
  export const showQuickSearch = (isVisible) => ({
    type: 'SHOW_QUICK_SEARCH',
    isVisible
  });
  export const showValidationResults = (isVisible) => ({
    type: 'SHOW_VALIDATION_RESULTS',
    isVisible
  });
  export const userNavigation = (navigationType) => ({
    type: 'USER_NAVIGATION',
    navigationType
  });
  export const setDashboardDisplay = (
    isMainDashboardMin,
    isCustomerDashboardMin,
    sourceType
  ) => ({
    type: 'SET_DASHBOARD_DISPLAY',
    isMainDashboardMin,
    isCustomerDashboardMin,
    sourceType
  });
  export const dragToPanelContainer = (
    panelTypeId,
    description,
    hasGraphics
  ) => ({
    type: 'DRAG_TO_PANEL_CONTAINER',
    panelTypeId,
    description,
    hasGraphics
  });
  export const movePanelItem = (idSource, idTarget) => ({
    type: 'MOVE_PANEL_ITEM',
    idSource,
    idTarget
  });
  export const dragPanelItemToTrash = (id) => ({
    type: 'MOVE_PANELITEM_TO_TRASH',
    idPanelItem: id
  });
  export const maximiseItem = (idPanelItem) => ({
    type: 'MAXIMISE_PANEL',
    idPanelItem
  });
  export const minimiseItem = (idPanelItem) => ({
    type: 'MINIMISE_PANEL',
    idPanelItem
  });
  export const selectItem = (idPanelItem) => ({
    type: 'SELECT_PANEL',
    idPanelItem
  });
  export const dragToPanelList = (idMenu, idSubMenu) => ({
    type: 'DRAG_TO_PANEL_LIST',
    idMenu,
    idSubMenu
  });
  export const dragPanelListItemToTrash = (idPanel, idMenu, idSubMenu) => ({
    type: 'MOVE_LIST_ITEM_TO_TRASH',
    idPanel,
    idMenu,
    idSubMenu
  });
  export const selectItemCollapsedDashboard = (idPanelItem, sourceType) => ({
    type: 'SELECT_PANEL_MIN_DASH',
    idPanelItem,
    sourceType
  });
  export const requestPanelContentData = (serviceName, id, source) => ({
    type: 'REQUEST_PANEL_CONTENT_DATA',
    serviceName,
    id,
    source
  });
  export const receivePanelContentData = (results, serviceName, id, source) => ({
    type: 'RECEIVE_PANEL_CONTENT_DATA',
    serviceName,
    results,
    id,
    source
  });
  export const errorPanelContentData = (err, serviceName) => ({
    type: 'ERROR_PANEL_CONTENT_DATA',
    serviceName,
    err
  });
  export const endPanelGlimpse = (idPanelItem) => ({
    type: 'END_PANEL_GLIMPSE',
    idPanelItem
  });
  export const selectGridFunction = (sourceType) => ({
    type: 'SELECT_GRID_FN',
    sourceType
  });
  export const configDefaultReceived = (data, viewType, fromHome) => ({
    type: 'GET_DEFAULT_DASHBOARD',
    data,
    viewType,
    fromHome
  });
  export const moveOptionItem = (idPanel, idSource, idTarget) => ({
    type: 'MOVE_OPTION_ITEM',
    idPanel,
    idSource,
    idTarget
  });
  export const receiveMenu = (data, menu) => ({
    type: 'RECEIVE_MENU',
    data,
    menu
  });
  export const doSaveDefault = (save) => ({
    type: 'DO_SAVE_DEFAULT',
    doSave: save
  });
  export const navigateHome = () => ({
    type: 'NAVIGATE_HOME'
  });
  