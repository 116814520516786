import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { executeAuthAsyncGet } from '../../utility/asyncSupport';
import { showToastErrorMessage } from '../../api/toasterApi';
import { getApiUrl } from '../../constants';
import { webApiInterface } from '../../api/webApiInterface';
import useDimensions from 'react-use-dimensions';
import { Document, Page, pdfjs } from 'react-pdf';
import { Loading } from 'invevo-react-components';
import axios from 'axios'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PostPreview = ({
  client,
  executeAuthAsyncGet,
  postTemplate,
  contacts,
  embeddedTransactionIds,
  documents,
  personalisedContent
}) => {
  const [previousTemplateId, setPreviousTemplateId] = useState(0);
  const [previousContactId, setPreviousContactId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [pdf, setPdf] = useState();
  const [numPages, setNumPages] = useState(null);

  const { templateId, customerId, isVirtualAccount } = postTemplate;
  const contactIds = contacts.map((c) => c.contactId);
  const documentIds = documents.map((d) => d.id);

  useEffect(() => {
    const hasNewContacts =
      previousContactId !== null && !contactIds.includes(previousContactId);

    const shouldGeneratePreview =
      templateId &&
      !isLoading &&
      (templateId !== previousTemplateId || hasNewContacts);

    if (shouldGeneratePreview) {
      setIsLoading(true);
      const model = {
        templateId,
        customerId,
        embeddedTransactionRowIds: embeddedTransactionIds,
        attachedTransactionIds: embeddedTransactionIds,
        attachedDocumentIds: documentIds,
        personalisedContent: personalisedContent,
        contactIds: contactIds.length > 0 ? [contactIds[0]] : [],
        isVirtualAccount: isVirtualAccount,
        printMode: false,
        saveNote: false,
        isPreview: true
      };

      const url = getApiUrl(client) + 'api/Post/NewGenerate'
      const authToken = webApiInterface.token()
      axios.post(`${url}`, model, getAxiosConfig(authToken, { responseType: 'blob' }))
        .then(response => {
          setPreviousTemplateId(templateId);
          setPreviousContactId(contactIds.length > 0 ? contactIds[0] : null);

          switch (response.status) {
            case 200:
              setPdf(response.data);
              break;
            case 204:
              // no content
              setPdf(null);
              break;
            default:
              showToastErrorMessage(
                'Unable to show post preview - check the templates are configured correctly.'
              );
              break;
          }
          setIsLoading(false)
        })
        .catch(_ => {
          setPdf(null);
          setPreviousTemplateId(templateId);
          setPreviousContactId(contactIds.length > 0 ? contactIds[0] : null);

          showToastErrorMessage(
            'Unable to show post preview - check the templates are configured correctly.'
          );

          setIsLoading(false)
        })
    }
  }, [
    client,
    executeAuthAsyncGet,
    contactIds,
    embeddedTransactionIds,
    documentIds,
    personalisedContent,
    previousContactId,
    previousTemplateId,
    templateId,
    customerId,
    isVirtualAccount,
    isLoading
  ]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [containerRef, { width }] = useDimensions();
  const renderWidth = width - 32;

  return (
    <div className="px-3" ref={containerRef}>
      <Loading isLoading={isLoading} colour="white">
        <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <React.Fragment key={`container_${index + 1}`}>
              <Page
                key={`page_${index + 1}`}
                width={renderWidth}
                pageNumber={index + 1}
              />
              {index + 1 !== numPages ? <hr /> : null}
            </React.Fragment>
          ))}
        </Document>
      </Loading>
    </div>
  );
};

const getAxiosConfig = (token, optionalConfig) => {
  return {
    ...optionalConfig,
    headers: {
      ...optionalConfig?.headers,
      Authorization: `Bearer ${token}`
    }
  }
}

export default connect(null, {
  executeAuthAsyncGet
})(PostPreview);
