import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { storeGridState } from '../../actions/gridCacheActions';
import { selectCustomerContacts } from '../../actions/functionActions';
import { map, remove } from 'lodash';
import { executeAuthAsyncPost } from '../../utility/asyncSupport';

import { getLookup } from '../../api/lookupApi';

import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../common/grids/StatefulGrid';

import ContactCard from './contactCard';
import { getApiUrl } from '../../constants';
import existential from '../../utility/existential';

class CustomerContactsGrid extends React.Component {
  lastSelectIndex = 0;

  constructor(props, context) {
    super(props, context);

    const selectedItems = props.customerContacts.map((c) => ({
      ContactId: c.contactId,
      Email: c.email,
      Name: c.name,
      AddressId: c.addressId,
      Mobile: c.mobile,
      SMSRecipient: c.SMSRecipient
    }));

    this.state = {
      refresh: 0,
      selectedItems
    };

    this.customerContactsGridRef = new React.createRef();
  }

  componentDidMount() {
    if (this.props.methodCallback != null) {
      this.props.methodCallback(this.refresh.bind(this));
    }
  }

  refresh = () => {
    this.setState({
      refresh: this.state.refresh + 1
    });

    this.props.getLookup(
      this.props.client,
      'contact/externalcontactsvalidatedlist?customerId=' +
      this.props.selectedAccount.miaAccountId,
      'validatedContactIds'
    );
  };

  resetSelectedItems = () =>
    this.setState({
      selectedItems: []
    });

  onStateChange = (data) => {
    this.props.storeGridState(CustomerContactsGrid.getKey(), data);
  };

  onEditRowClicked = (e, dataItem) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.onEditRequest != null) {
      const editId = dataItem.ContactId;
      this.props.onEditRequest(editId);
    }
  };

  isSelectedRow = (dataItem) =>
    !!this.state.selectedItems.find(
      (item) => item.ContactId === dataItem.ContactId
    );

  onRowClick = (gridRowEvent) => {
    const dataItem = gridRowEvent.dataItem;
    const grid = this.customerContactsGridRef.current.state.data;
    let newSelectedRowStatus = !this.isSelectedRow(dataItem);
    let selectedItems = [...this.state.selectedItems];

    let last = this.lastSelectIndex;
    const current = grid.findIndex((d) => d.ContactId === dataItem.ContactId);

    if (!gridRowEvent.nativeEvent.shiftKey) {
      this.lastSelectIndex = last = current;
    }

    if (!gridRowEvent.nativeEvent.ctrlKey) {
      newSelectedRowStatus = true;
      selectedItems = [];
    }

    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      const gridDataItem = grid[i];
      const gridItemIsSelected = !!selectedItems.find(
        (item) => item.ContactId === gridDataItem.ContactId
      );

      if (gridItemIsSelected !== newSelectedRowStatus) {
        if (gridItemIsSelected) {
          remove(
            selectedItems,
            (item) => gridDataItem.ContactId === item.ContactId
          );
        } else {
          selectedItems.push({
            ContactId: gridDataItem.ContactId,
            Email: gridDataItem.Email,
            Name: gridDataItem.Name,
            AddressId: gridDataItem.AddressId,
            Mobile: gridDataItem.Mobile,
            SMSRecipient: gridDataItem.SMSRecipient
          });
        }
      }
    }

    if (this.props.onRowSelection) {
      if (selectedItems && selectedItems.length > 0) {
        const item = selectedItems[0];
        this.props.onRowSelection(
          item.ContactId,
          item.Email,
          item.Name,
          item.AddressId,
          item.Mobile,
          item.SMSRecipient
        );
      } else {
        this.props.onRowSelection('', '', '', '');
      }
    }

    if (this.props.selectCustomerContacts) {
      this.props.selectCustomerContacts(
        map(selectedItems, (r) => ({
          contactId: r.ContactId,
          email: r.Email,
          name: r.Name,
          addressId: r.AddressId,
          mobile: r.Mobile,
          SMSRecipient: r.SMSRecipient
        }))
      );
    }
      
    this.props.onSelectedCustomerContactsUpdated(map(selectedItems, (r) => ({
      contactId: r.ContactId,
      email: r.Email,
      name: r.Name,
      addressId: r.AddressId,
      mobile: r.Mobile,
      SMSRecipient: r.SMSRecipient
    })))

    this.setState({
      selectedItems
    });
  };

  additionalRequestPayload = () => {
    if (this.props.selectedAccount.miaAccountIsVirtualAccount)
      return {
        id: this.props.selectedAccount.miaAccountId,
        contactType: this.props.customerType
      };

    return {
      customerid: this.props.selectedAccount.miaAccountId,
      contactType: this.props.customerType
    };
  };

  static getKey() {
    return 'customer-contacts-grid-key';
  }

  resultCard = (e) => (
    <ContactCard
      dataItem={e.dataItem}
      isInternal={false}
      onClick={this.onRowClick}
      onEditClick={this.onEditRowClicked}
    />
  );

  rowRender(trElement) {
    const trProps = { className: 'col-lg-4 col-6 top15' };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  }

  render() {
    const apiUrl =
      getApiUrl(this.props.client) +
      (this.props.selectedAccount.miaAccountIsVirtualAccount
        ? 'api/virtualaccount/vacontactgrid'
        : 'api/grid/contacts');

    const nameFilter =
      this.props.filterByText !== ''
        ? {
          filters: [
            {
              field: 'Name',
              operator: 'contains',
              value: this.props.filterByText
            }
          ]
        }
        : null;

    return (
      <StatefulGrid
        client={this.props.client}
        id={CustomerContactsGrid.getKey()}
        key={CustomerContactsGrid.getKey()}
        path={apiUrl}
        sortable
        pageable={{
          pageSizes: [50, 100, 200],
          buttonCount: 5
        }}
        onStateChange={this.onStateChange}
        onRowClick={this.onRowClick}
        additionalRequestPayload={this.additionalRequestPayload()}
        defaultField={{ field: 'Name', dir: 'asc' }}
        isSelectedRow={this.isSelectedRow}
        hideablePaging={true}
        className={'hide-header single-cell scrollable-none row row-sm-gutters'}
        filter={nameFilter}
        refresh={this.state.refresh}
        ref={this.customerContactsGridRef}
        rowRender={this.rowRender}
      >
        <GridColumn cell={this.resultCard} />
      </StatefulGrid>
    );
  }
}

CustomerContactsGrid.propTypes = {
  filterByText: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  return {
    gridState: existential(
      state.gridCacheReducer.grids[CustomerContactsGrid.getKey()],
      'gridState',
      {}
    ),
    selectedAccount: state.currentSelectionReducer.selectedAccount,
    customerContacts: state.functionReducer.customerContacts
  };
};

export default connect(mapStateToProps, {
  getLookup,
  storeGridState,
  executeAuthAsyncPost,
  selectCustomerContacts
})(CustomerContactsGrid);
