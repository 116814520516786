import React from 'react';
import { connect } from 'react-redux';
import { getApiUrl, GLOBAL_SETTINGS } from '../../../constants';
import { getLookup } from '../../../api/lookupApi';
import { showToastSuccessMessage } from '../../../api/toasterApi';
import { webApiInterface } from '../../../api/webApiInterface';
import { findIndex, isNil, map, some, split, trim, isString } from 'lodash';
import displayError from '../../../utility/error';
import { executeAuthAsyncGet } from '../../../utility/asyncSupport';
import { ConfrimDialog } from '../../common/confirmDialog';

export class MappingUpload extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.onFileSelected = this.onFileSelected.bind(this);
    this.uploadForm = this.uploadForm.bind(this);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.canSubmit = this.canSubmit.bind(this);
    this.setErr = this.setErr.bind(this);
    this.nameChange = this.nameChange.bind(this);

    this.state = MappingUpload.defaultState();

    this.documentUploader = null;
    this.uploadName = null;
  }

  static defaultState() {
    return {
      uploadErr: '',
      size: 0,
      containsHeader: false,
      nameOk: true,
      showOverwriteDialog: false
    };
  }

  closeDialog = () => {
    this.setState({
      showOverwriteDialog: false
    });
  };

  nameChange(name) {
    let nameOk = true;

    if (this.props.mappingTableNames.length > 0) {
      const nameSansExtension = split(
        isString(name) ? name : name.target.value,
        '.'
      )[0];

      nameOk =
        !isNil(nameSansExtension) &&
        !some(
          this.props.mappingTableNames,
          (e) => e.Text === nameSansExtension
        );
    }

    const uploadErr = nameOk ? '' : 'That name already exists';

    this.setState({
      uploadErr,
      nameOk
    });
  }

  onFileSelected(event) {
    const file = event.target.files[0];

    if (!isNil(file)) {
      this.uploadName.value = file.name;
      this.nameChange(file.name);

      const size = file.size;

      this.setState({
        documentToUpload: file,
        size
      });
    }
  }

  setErr(msg) {
    this.setState({
      uploadErr: msg
    });
  }

  toggleCheckbox() {
    this.setState({
      containsHeader: !this.state.containsHeader
    });
  }

  canSubmit() {
    return this.state.nameOk && this.state.size > 0;
  }

  static addValueToRequest(name, value) {
    return (
      '--blob\r\n' +
      'content-disposition: form-data; name="' +
      name +
      '"\r\n' +
      '\r\n' +
      value +
      '\r\n'
    );
  }

  static addFileContentToRequest(fieldName, fileName, fileType, binaryContent) {
    return (
      '--blob\r\n' +
      'content-disposition: form-data; ' +
      'name="' +
      fieldName +
      '"; ' +
      'filename="' +
      fileName +
      '"\r\n' +
      'Content-Type: ' +
      fileType +
      '\r\n' +
      '\r\n' +
      binaryContent +
      '\r\n'
    );
  }

  uploadForm(event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.props.selectedTableId === -1) {
      this.uploadFormConfirmed();
    } else {
      this.setState({ showOverwriteDialog: true });
    }
  }

  uploadFormConfirmed() {
    this.closeDialog();

    const documentUploader = this.documentUploader;
    const file = documentUploader.files[0];

    if (!isNil(file)) {
      const that = this;
      const reader = new FileReader();
      const url = getApiUrl(this.props.client) + 'api/mapping/upload';
      const fileName = file.name;
      const fileSaveName = this.uploadName.value;
      this.uploadName.value = '';
      this.btnSave.disabled = true;
      const fileType = file.type;

      reader.onload = function (event) {
        if (
          event.currentTarget.result.length >
          GLOBAL_SETTINGS.max_upload_file_size * 1024 * 1024
        ) {
          this.setErr(
            'File is too big to be uploaded to the server. Please select another file.'
          );
          return;
        }
        if (event.currentTarget.result.length === 0) {
          this.setErr('File has no content. Please select another file.');
          return;
        }

        showToastSuccessMessage(
          'Upload of file: ' +
          fileSaveName +
          (fileName !== fileSaveName ? ' (' + fileSaveName + ')' : '') +
          ' started.'
        );

        const acceptedFileTypes = map(
          split(GLOBAL_SETTINGS.accepted_file_types, ','),
          trim
        ); // Array of extensions
        const fileNameParts = split(fileName, '.');
        const fileExtension = '.' + fileNameParts[fileNameParts.length - 1];
        if (findIndex(acceptedFileTypes, (ft) => ft === fileExtension) === -1) {
          this.setErr(
            'File extension is not correct. Please select another file.'
          );
          return;
        }

        const XHR = new XMLHttpRequest();
        const containsHeader = this.state.containsHeader;
        const data =
          MappingUpload.addFileContentToRequest(
            documentUploader.name,
            fileSaveName,
            fileType,
            event.currentTarget.result
          ) +
          MappingUpload.addValueToRequest('containsHeader', containsHeader) +
          MappingUpload.addValueToRequest(
            'tableId',
            this.props.selectedTableId
          );

        XHR.onreadystatechange = function () {
          if (XHR.readyState === 4) {
            if (XHR.status === 200) {
              that.props.getLookup(this.props.client, 'mapping/tablenames', 'mappingTableNames');
              showToastSuccessMessage(
                'Upload of file: ' + fileName + ' complete.'
              );
              that.setState(MappingUpload.defaultState());
            } else {
              const err = JSON.parse(XHR.response);
              displayError(
                'Error uploading file: ' +
                fileName +
                '. Error: ' +
                err.Message || XHR.statusText
              );
            }
          }
        };

        XHR.open('POST', url);
        XHR.setRequestHeader(
          'Content-Type',
          'multipart/form-data; boundary=blob'
        );
        XHR.setRequestHeader('Access-Control-Allow-Origin', '*');
        that.props.executeAuthAsyncGet(
          this.props.client,
          'Lookup/OK',
          'CHECK_OK',
          null,
          function () {
            webApiInterface.applySecurity(XHR);
            XHR.send(data + '--blob--');
          }
        );
      }.bind(this);

      reader.readAsBinaryString(this.state.documentToUpload);
    }
  }

  static getKey() {
    return 'mapping-upload';
  }

  render() {
    const formData = this.state;

    return (
      <div className="borderContainerNoTop">
        <form
          key="upload-document-form"
          id="mapping-form"
          className="form-group well"
          style={{ width: '580px', marginTop: '1px' }}
          encType="multipart/form-data"
          onSubmit={this.uploadForm}
        >
          <div className="row">
            <div className="col-sm-8">
              <h3>Upload File</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
              CSV contains header row?{' '}
              <input
                type="checkbox"
                id="csv-header"
                checked={this.state.containsHeader}
                onChange={this.toggleCheckbox}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
              <br />
              <label className="btn btn-default btn-file">
                Browse
                <input
                  type="file"
                  id="documentUploader"
                  name="documentUploader"
                  style={{ display: 'none' }}
                  ref={(input) => (this.documentUploader = input)}
                  onChange={this.onFileSelected}
                  accept=".csv"
                />
              </label>
              <input
                className="form-control"
                type="text"
                ref={(input) => (this.uploadName = input)}
                onChange={this.nameChange}
              />
              <br />
              <span style={{ color: 'red' }}>{formData.uploadErr}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <br />
              <button
                ref={(input) => (this.btnSave = input)}
                type="submit"
                disabled={!this.canSubmit()}
                className="btnDefault btn btn-default"
              >
                Upload
              </button>
            </div>
          </div>
        </form>
        <ConfrimDialog
          show={this.state.showOverwriteDialog}
          message={'Are you sure you want overwrite the data for this table?'}
          onConfirm={this.uploadFormConfirmed}
          onClose={this.closeDialog}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mappingTableNames: state.lookupReducer.mappingTableNames.results,
    selectedTableId: state.mappingReducer.selectedTableId
  };
};

export default connect(mapStateToProps, {
  getLookup,
  executeAuthAsyncGet
})(MappingUpload);
