import ImportFieldConfigs from "./importFieldConfig/ImportFieldConfigs"
import ImportFileConfig from "./ImportFileConfig"
import { Loading } from "invevo-react-components"
import {
    FileImportConfigAction,
    FileImportConfigActionEnum,
    FileImportConfigColumnTypeState,
    FileImportConfigFieldTrimOptionState,
    FileImportConfigState
} from "../../../reducers/FileEntityImportConfigReducer"
import ReferenceFieldConfig from "./importFieldConfig/ReferenceFieldConfig"
import EntityConfig from "../../../../entityConfig/types/EntityConfig"
import EntityDataField from "../../../../entityConfig/types/EntityDataField"
import Lookup from "../../../../../types/Lookup"

type FileImportConfigSetupProps = {
    state: { editingConfig: FileImportConfigState; entities: EntityConfig[]; dataFields: EntityDataField[]; lookups: Lookup[] }
    dispatch: React.Dispatch<FileImportConfigAction>
}

const FileImportConfigSetup = ({ state, dispatch }: FileImportConfigSetupProps) => {
    const onReferenceConfigColumnsChange = (columns: FileImportConfigColumnTypeState[]) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_REFERENCE_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.editingConfig.dataFileImportConfig.mandatoryFieldConfig,
                columns
            }
        })
    }

    const onReferenceConfigAddTrimOption = () => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_REFERENCE_FIELD_CONFIG_TRIM_OPTION_ADDED
        })
    }

    const onReferenceConfigTrimOptionChange = (trimOption: FileImportConfigFieldTrimOptionState) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_REFERENCE_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.editingConfig.dataFileImportConfig.mandatoryFieldConfig,
                trimOptions: state.editingConfig.dataFileImportConfig.mandatoryFieldConfig.trimOptions.map((to: FileImportConfigFieldTrimOptionState) => {
                    if (to.key !== trimOption.key) return to
                    return trimOption
                })
            }
        })
    }

    const onReferenceConfigTrimOptionDelete = (trimOption: FileImportConfigFieldTrimOptionState) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_REFERENCE_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.editingConfig.dataFileImportConfig.mandatoryFieldConfig,
                trimOptions: state.editingConfig.dataFileImportConfig.mandatoryFieldConfig.trimOptions.filter(
                    (to: FileImportConfigFieldTrimOptionState) => to.key !== trimOption.key
                )
            }
        })
    }

    return (
        <Loading isLoading={!state} colour="blue">
            <div className="d-flex flex-column w-100 h-100">
                <ImportFileConfig config={state.editingConfig} entities={state.entities} dispatch={dispatch} />

                <div className="d-flex flex-column">
                    <ReferenceFieldConfig
                        label="Reference Field (unique identifier for entity)"
                        state={{
                            config: state.editingConfig.dataFileImportConfig.mandatoryFieldConfig,
                            hasHeaderRow: state.editingConfig.dataFileImportConfig.hasHeaderRow
                        }}
                        onColumnsChange={onReferenceConfigColumnsChange}
                        onAddTrimOption={onReferenceConfigAddTrimOption}
                        onTrimOptionChange={onReferenceConfigTrimOptionChange}
                        onTrimOptionDelete={onReferenceConfigTrimOptionDelete}
                    />
                    <ImportFieldConfigs
                        state={{
                            fieldConfigs: state.editingConfig.dataFileImportConfig.optionalFieldConfigs,
                            dataFields: state.dataFields,
                            lookups: state.lookups,
                            hasHeaderRow: state.editingConfig.dataFileImportConfig.hasHeaderRow,
                            type: state.editingConfig.dataFileImportConfig.type
                        }}
                        dispatch={dispatch}
                    />
                </div>
            </div>
        </Loading>
    )
}

export default FileImportConfigSetup
