import { useState, useReducer, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import SmsTemplates from './smsTemplates/SmsTemplates';
import SmsTemplateWorkspace from './smsWorkspace/SmsTemplateWorkspace';
import SmsTemplateDetails from './smsTemplateDetails/SmsTemplateDetails';
import smsTemplateReducer from '../../../../reducers/smsTemplateReducer';

const SmsTemplatesDashboard = ({ className, customerGroups, senderIds }) => {

    const [smsTemplate, setSmsTemplate] = useState({})
    const [selectedTemplate, setSelectedTemplate] = useState({})
    const [smsTemplateState, smsTemplateDispatch] = useReducer(smsTemplateReducer, { smsTemplates: [] })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSmsTemplateSelected = (smsTemplate) => {
        setSmsTemplate(smsTemplate)
        setSelectedTemplate(smsTemplate)
        smsTemplateDispatch({ type: 'SMS_TEMPLATE_SELECTED', smsTemplate: smsTemplate })
    }

    useEffect(() => {
        if ((smsTemplateState.smsTemplates.length > 0) && (!smsTemplate.name) && (!smsTemplate.isNew))
            onSmsTemplateSelected(smsTemplateState.smsTemplates[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [smsTemplateState.smsTemplates])

    const onCreateClicked = () => {
        const newTemplate = newSmsTemplate()
        setSmsTemplate(newTemplate)
        setSelectedTemplate(newTemplate)
        smsTemplateDispatch({ type: 'SMS_TEMPLATE_SELECTED', smsTemplate: {} })
    }

    return (
        <div className={`d-flex w-100 h-100 ${className ? className : ''}`}>
            <SmsTemplates
                templates={smsTemplateState.smsTemplates}
                onSmsTemplateSelected={onSmsTemplateSelected}
                onCreateClicked={onCreateClicked}
                dispatch={smsTemplateDispatch}
            />
            <SmsTemplateWorkspace
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                initialSmsBody={smsTemplate.body}
            />
            <SmsTemplateDetails
                customerGroups={customerGroups}
                senderIds={senderIds}
                smsTemplate={smsTemplate}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                dispatch={smsTemplateDispatch}
            />
        </div>
    )
}

export default SmsTemplatesDashboard

const newSmsTemplate = () => {
    return {
        reference: uuidv4(),
        name: "",
        body: "",
        isActive: true,
        isSelected: true,
        isNew: true
    }
}
