import { useState } from "react"
import EntityConfig from "../../routes/entityConfig/types/EntityConfig"
import Input from "../Inputs/Input"
import classes from "./AddEntityOverlayForm.module.scss"
import { v4 as uuidv4 } from "uuid"
import StandardButton from "../buttons/StandardButton/StandardButton"
import { useOverlay } from "../../contexts/overlay/OverlayContext"

type Props = {
    entityConfig: EntityConfig
    onAddClick: (reference: string) => void
}

const AddEntityOverlayForm = ({ entityConfig, onAddClick }: Props) => {
    const overlay = useOverlay()

    const [defaultGuid] = useState(uuidv4)
    const [referenceValue, setReferenceValue] = useState(defaultGuid)

    const onClick = () => {
        onAddClick(referenceValue)
        overlay.closeOverlay()
    }

    return (
        <div className="d-flex flex-column bg-white text-grey p-3 rounded gap-2">
            <div className="d-flex align-items-center justify-content-between">
                <span className="fs-3">Create new '{entityConfig.displayName}'</span>
                <div role="button" className={`d-flex align-items-center justify-content-center ${classes.iconButton} fs-4`} onClick={overlay.closeOverlay}>
                    <i className="fal fa-times" />
                </div>
            </div>
            <div className="d-flex align-items-center gap-2">
                <span>Reference:</span>
                <Input className="w-100" value={referenceValue} onChange={setReferenceValue} />
            </div>
            <div className="d-flex align-items-center">
                {defaultGuid !== referenceValue && (
                    <div className="d-flex align-items-center text-danger no-select text-center">
                        <i className="fal fa-exclamation-triangle me-2" />
                        <span>Reference might not be unique</span>
                    </div>
                )}
                <StandardButton className="ms-auto" iconClasses="fal fa-plus" label="Create" onClick={onClick} colour="blue" />
            </div>
        </div>
    )
}

export default AddEntityOverlayForm
