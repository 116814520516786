import useClickOutsideRef from "../../../hooks/useClickOutsideRef"
import classes from "./RoleOverlay.module.scss"
import UserRole from "../../../types/UserRole"
import RoleToggle from "./RoleToggle"
import { Toggle } from "invevo-react-components"
type Props = {
    roles: UserRole[]
    onCloseClick: () => void
    setRoles: (roles: UserRole[]) => void
}

const RoleOverlay = ({ roles, onCloseClick, setRoles }: Props) => {
    const ref = useClickOutsideRef(onCloseClick)

    const allRolesEnabled = roles.every(r => r.enabled)

    const toggleRole = (reference: string) => setRoles(roles.map(r => (r.reference === reference ? { ...r, enabled: !r.enabled } : r)))

    const toggleAllRoles = () => setRoles(roles.map(r => ({ ...r, enabled: !allRolesEnabled })))

    return (
        <div className={`${classes.background} position-fixed w-100 h-100`}>
            <div className="d-flex justify-content-center">
                <div ref={ref} className={`${classes.overlay} d-flex flex-column bg-white text-grey p-3 rounded gap-2 w-25`}>
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="fs-3">Select roles</span>
                        <div role="button" className={`d-flex align-items-center justify-content-center ${classes.iconButton} fs-4`} onClick={onCloseClick}>
                            <i className="fal fa-times" />
                        </div>
                    </div>
                    {roles.length > 0 ? (
                        <>
                            <div className="d-flex p-2 pointer align-items-center border-bottom">
                                <Toggle className="mt-1" status={allRolesEnabled} onStatusChanged={toggleAllRoles} />
                                <span className="d-flex flex-grow-1 mt-1 ms-2">All Roles</span>
                            </div>
                            <div className="d-flex flex-column overflow-auto">
                                {roles.map(r => (
                                    <RoleToggle role={r} onToggle={toggleRole} />
                                ))}
                            </div>
                        </>
                    ) : (
                        <div className="d-flex">
                            <span className="mx-auto mt-3">No roles available</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default RoleOverlay
