import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getApiUrl } from '../../constants';
import { storeGridState } from '../../actions/gridCacheActions';
import EMailNotesHistoryDisplay from './eMailNotesHistoryDisplay';
import { isNil, max, last, forEach } from 'lodash';
import { executeAuthAsyncGet } from '../../utility/asyncSupport';
import { webApiInterface } from '../../api/webApiInterface';
import Nav from '../../api/navApi';
import { toDataSourceRequest } from '@progress/kendo-data-query';
import { CustomColumnMenu } from '../common/grids/columnMenu';
import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../common/grids/StatefulGrid';
import { selectReplyToEmail } from '../../actions/functionActions';
import { normaliseFormatDef } from '../../selectors/lookup';
import existential from '../../utility/existential';

function EmailCommandCell({ onEmailClick }) {
  return ({dataItem}) => {
    return (
      <td className="k-command-cell">
        {dataItem.EmailTrackingId !== null && (
          <button
            className={'k-button k-grid-ViewEmail'}
            onClick={(e) => onEmailClick(e, dataItem, 'view')}
          >
            View Email
          </button>
        )}
        {dataItem.Inbound && (
          <>
            <button
              className={'k-button k-grid-Reply'}
              onClick={(e) => onEmailClick(e, dataItem, 'reply')}
            >
              Reply
            </button>
            <button
              className={'k-button k-grid-ReplyAll'}
              onClick={(e) => onEmailClick(e, dataItem, 'reply-all')}
            >
              Reply All
            </button>
          </>
        )}
      </td>
    );
  }
}

class NotesHistoryGrid extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      emailTrackingId: null,
      noteId: null,
      emailDate: null,
      customerId: null,
      unreadNoteIds: [],
      columns: this.getColumns(),
      dataState: null
    };

    this.CommandCell = EmailCommandCell({
      onEmailClick: this.onEmailClick
    });
  }

  onEmailClick = (e, dataItem, type) => {
    e.preventDefault();
    if (!isNil(dataItem)) {
      if (type === 'reply' || type === 'reply-all') {
        const emailToReply = {
          emailTrackingId: dataItem.EmailTrackingId,
          noteId: dataItem.NoteId,
          emailDate: dataItem.NoteDate,
          customerId: dataItem.CustomerId,
          type: type
        }
        this.props.selectReplyToEmail(emailToReply);
        this.props.navToEmailReply(emailToReply);
      } else {
        this.setState({
          emailTrackingId: dataItem.EmailTrackingId,
          noteId: dataItem.NoteId,
          emailDate: dataItem.NoteDate,
          customerId: dataItem.CustomerId,
          initialViewType: type,
          canReply: dataItem.Inbound
        });
      }
    }
  };

  onClose = () => {
    this.setState({ emailTrackingId: null });
  };

  getVisibleGridColumnsToExport() {
    return this.state.columns
      .filter((c) => c.show)
      .map((c) => ({ Name: c.field }));
  }

  newExportCsv = () => {
    const xhr = new XMLHttpRequest();
    const url = `${getApiUrl(this.props.client)}api/customers/${this.props.selectedAccount.miaAccountId}/notes/export`;

    const state = toDataSourceRequest(this.state.dataState);
    const params = {
      filter: state.filter,
      sort: state.sort,
      columns: this.getVisibleGridColumnsToExport()
    };

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(xhr.response);
      a.download = 'notesHistory.csv';
      a.click();
    };

    this.props.executeAuthAsyncGet(this.props.client, 'Lookup/OK', 'CHECK_OK', null, function () {
      webApiInterface.applySecurity(xhr);
      xhr.send(JSON.stringify(params));
    });
  };

    getColumns = () => {
    return [
      { show: false, field: 'GroupId', title: 'Id', width: 40 },
      {
        field: 'CustomerName',
        title: 'Customer',
        width: 100,
        show: false
      },
      {
        field: 'TaskDescription',
        title: 'Description',
        width: 100,
        show: false
      },
      { field: 'NoteId', title: 'Note Number', width: 100, show: false },
      {
        field: 'TransactionRef',
        title: 'Transaction Ref',
        width: 150,
        show: false
      },
      {
        field: 'FollowUpDate',
        title: 'Follow Up Date',
        format: this.props.dateTimeFormat,
        show: false
      },
      {
        show: true,
        field: 'NoteDate',
        title: 'Note Date',
        format: this.props.dateTimeFormat
      },
      { show: true, field: 'Note', title: 'Note', width: 450 },
      { show: true, field: 'NoteType', title: 'Note Type' },
      { show: true, field: 'UserName', title: 'User Name' },
      { show: false, field: 'ContactName', title: 'Contact Name' },
      { show: false, field: 'FollowupType', title: 'Follow-up Type' },
      { show: true, field: 'TaskType', title: 'Task Type' },
      { show: true, field: 'TaskCompletion', title: 'Task Completion' }
    ];
  };

  static getKey() {
    return 'grid-trans-notes';
  }

  onColumnsSubmit = (columnsState) => {
    this.setState({
      columns: columnsState
    });
  };

  highlightUnreadRows = (data) => {
    const unreadNoteIds = [];
    forEach(data, (dataItem) => {
      if (dataItem.ReadByUserId === null && dataItem.Inbound) {
        unreadNoteIds.push(dataItem.NoteId);
      }
    });
    this.setState({ unreadNoteIds });
  };

  isSelectedRow = (dataItem) =>
    this.state.unreadNoteIds.indexOf(dataItem.NoteId) !== -1;

  onStateChange = (data) => {
    this.setState({ dataState: data });
    this.props.onStoreGridState(NotesHistoryGrid.getKey(), data);
  };

  additionalRequestPayload = () => {
    return {
      transactionids:
        this.props.selectedTransactionIds && this.props.selectedTransactionIds.length <=
         max(~~this.props.globalConfiguration.transactionNoteLimit, 50)
          ? this.props.selectedTransactionIds
          : null,
      customerid:
        this.props.customerId || this.props.selectedAccount.miaAccountId,
      allTransactionsSelected: this.props.allTransactionsSelected
    };
  };

  render() {
    let content;

    const url =
      getApiUrl(this.props.client) +
      (this.props.selectedAccount.miaAccountIsVirtualAccount
        ? 'api/note/GetHistoryNotesVirtualAccount'
        : 'api/note/history');

    if (this.state.emailTrackingId != null) {
      content = (
        <EMailNotesHistoryDisplay
          client={this.props.client}
          emailTrackingId={this.state.emailTrackingId}
          emailDate={this.state.emailDate}
          customerId={this.state.customerId}
          noteId={this.state.noteId}
          initialViewType={this.state.initialViewType}
          onClose={this.onClose}
          canReply={this.state.canReply}
          navToEmailReply={this.props.navToEmailReply}
        />
      );
    } else {
      content = (
        <div>
          <div className="gridCustomToolbar d-flex py-2">
            <button
              className="btnDefault btn btn-default ms-auto"
              onClick={this.newExportCsv}
            >
              Export as CSV
            </button>
          </div>
          <StatefulGrid
            client={this.props.client}
            key={NotesHistoryGrid.getKey()}
            id={NotesHistoryGrid.getKey()}
            path={url}
            method={'POST'}
            sortable
            pageable={{
              pageSizes: [50, 100, 200],
              refresh: true,
              buttonCount: 5
            }}
            onStateChange={this.onStateChange}
            isSelectedRow={this.isSelectedRow}
            afterReceivedFn={this.highlightUnreadRows}
            additionalRequestPayload={this.additionalRequestPayload()}
            className={'scrollable-none custom-grid'}
            defaultField={{ field: 'NoteDate', dir: 'desc' }}
            refresh={this.props.refresh}
          >
            {
              [...this.state.columns.map(
              (column, idx) =>
                column.show && (
                  <GridColumn
                    key={idx}
                    field={column.field}
                    title={column.title}
                    filter={column.filter}
                    format={column.format}
                    width={column.width}
                    columnMenu={(props) => (
                      <CustomColumnMenu
                        {...props}
                        columns={this.state.columns}
                        onColumnsSubmit={this.onColumnsSubmit}
                      />
                    )}
                  />
                )
              ), <GridColumn
                key={this.state.columns ? this.state.columns.length : 0}
                field={'EmailLink'}
                title={'Email Link'}
                cell={this.CommandCell}
              />]
            }
          </StatefulGrid>
        </div>
      );
    }

    return <>{content}</>;
  }
}

NotesHistoryGrid.propTypes = {
  selectedTransactionIds: PropTypes.array.isRequired,
  allTransactionsSelected: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  let configuration = {};

  if (state.globalConfiguration && state.globalConfiguration.length > 0) {
    configuration = last(state.globalConfiguration);
  }

  return {
    gridState: existential(
      state.gridCacheReducer.grids[NotesHistoryGrid.getKey()],
      'gridState',
      {}
    ),
    selectedAccount: state.currentSelectionReducer.selectedAccount,
    dateTimeFormat: normaliseFormatDef(
      state.lookupReducer.globalSettings.results.dateTimeFormat
    ),
    globalConfiguration: configuration,
    permissions: state.authReducer.permissions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    navTo: {
      EmailReply: () => dispatch(Nav.Workspace.Email.Reply())
    },
    onStoreGridState: (key, data) => dispatch(storeGridState(key, data)),
    executeAuthAsyncGet: (
      client,
      serviceName,
      name,
      data,
      onSuccess,
      onError,
      signal
    ) => {
      dispatch(
        executeAuthAsyncGet(client, serviceName, name, data, onSuccess, onError, signal)
      );
    },
    selectReplyToEmail: (replyToEmail) =>
      dispatch(selectReplyToEmail(replyToEmail))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesHistoryGrid);
