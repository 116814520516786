import Input from "../../../library/Inputs/Input"
import BlueButton from "../../../library/buttons/BlueButton/BlueButton"
import Dropdown from "../../../library/dropdowns/Dropdown"
import EntityDataFieldDropdown from "../../../library/dropdowns/EntityDataFieldDropdown"
import DropdownOption from "../../../types/DropdownOptions"
import EntityConfig from "../types/EntityConfig"
import IntraEntityConcatenationPart from "../types/IntraEntityConcatenationPart"
import { exists } from "../../../library/helpers/tsUtils"
import EntityDataField from "../types/EntityDataField"

const valueTypeOptions = [
    { value: "field", label: "Entity Field" },
    { value: "hardcoded", label: "Hardcoded Value" }
]

type Props = {
    entityConfig: EntityConfig
    intraEntityConcatenation: IntraEntityConcatenationPart[]
    onIntraEntityConcatenationUpdated: (intraEntityConcatenation: IntraEntityConcatenationPart[]) => void
}

const IntraEntityConcatenationConfigForm = ({ entityConfig, intraEntityConcatenation, onIntraEntityConcatenationUpdated }: Props) => {
    const onAddClicked = () =>
        onIntraEntityConcatenationUpdated([...intraEntityConcatenation, { ordinal: Math.max(...intraEntityConcatenation.map(part => part.ordinal)) + 1 }])

    const onConcatenationPartUpdated = (ordinal: number, updatedConcatenationPart: Partial<IntraEntityConcatenationPart>) =>
        onIntraEntityConcatenationUpdated(
            intraEntityConcatenation.map(concatenationPart =>
                concatenationPart.ordinal === ordinal ? { ...concatenationPart, ...updatedConcatenationPart } : concatenationPart
            )
        )

    return (
        <div className="d-flex flex-column gap-3">
            {intraEntityConcatenation
                .sort((a, b) => a.ordinal - b.ordinal)
                .map(concatenationPart => {
                    const isField = exists(concatenationPart.fieldName)

                    const onOperationValueTypeUpdated = (option: DropdownOption<typeof valueTypeOptions[number]["value"]>) => {
                        onConcatenationPartUpdated(
                            concatenationPart.ordinal,
                            option.value === "field" ? { fieldName: "", hardcodedValue: undefined } : { fieldName: undefined, hardcodedValue: "" }
                        )
                    }

                    const onEntityFieldUpdated = (option: EntityDataField | undefined) =>
                        onConcatenationPartUpdated(concatenationPart.ordinal, { fieldName: option?.fieldName ?? "" })

                    const onHardcodedValueUpdated = (value: string) => onConcatenationPartUpdated(concatenationPart.ordinal, { hardcodedValue: value })

                    const onRemoveClicked = () =>
                        onIntraEntityConcatenationUpdated(intraEntityConcatenation.filter(part => part.ordinal !== concatenationPart.ordinal))

                    return (
                        <div key={concatenationPart.ordinal} className="d-flex">
                            <Dropdown
                                className="me-2"
                                options={valueTypeOptions}
                                onOptionSelected={onOperationValueTypeUpdated}
                                selectedOption={isField ? valueTypeOptions[0] : valueTypeOptions[1]}
                                textAlign="left"
                                ariaLabel={`${concatenationPart.ordinal}-value-type`}
                            />
                            {isField ? (
                                <EntityDataFieldDropdown
                                    className="me-2"
                                    options={entityConfig.fields}
                                    onOptionSelected={onEntityFieldUpdated}
                                    selectedOption={entityConfig.fields.find(field => field.fieldName === concatenationPart.fieldName)}
                                    ariaLabel={`${concatenationPart.ordinal}-entity-field`}
                                />
                            ) : (
                                <Input
                                    className="me-2"
                                    placeholder="Enter hardcoded value"
                                    value={concatenationPart.hardcodedValue ?? ""}
                                    onChange={onHardcodedValueUpdated}
                                    ariaLabel={`${concatenationPart.ordinal}-hardcoded-value`}
                                />
                            )}
                            <i
                                className="far fa-trash-can my-auto ms-auto text-light-grey pointer"
                                onClick={onRemoveClicked}
                                aria-label={`${concatenationPart.ordinal}-remove`}
                            />
                        </div>
                    )
                })}
            <div className="d-flex m-1 text-white">
                <BlueButton className="ms-auto" iconClasses="fa fa-plus" label="Add" onClick={onAddClicked} ariaLabel="add" />
            </div>
        </div>
    )
}

export default IntraEntityConcatenationConfigForm
