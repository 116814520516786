import { showToastErrorMessage } from "../api/toasterApi";
import { executeAuthAsyncGet } from "../utility/asyncSupport";

export const setUserTaskDateType = (dateType) => ({
  type: 'SET_USER_TASK_DATE_TYPE',
  dateType
});

export const setUserTaskFilterStartDate = (startDate) => ({
  type: 'SET_USER_TASK_FILTER_START_DATE',
  startDate
});

export const setUserTaskFilterEndDate = (endDate) => ({
  type: 'SET_USER_TASK_FILTER_END_DATE',
  endDate
});

export const setUserTaskFilterStateTaskType = (
  stateTaskTypeId = null,
  taskTypeId = null
) => ({
  type: 'SET_USER_TASK_FILTER_STATE_TASK_TYPE',
  stateTaskTypeId,
  taskTypeId
});

export const setUserTaskGridIndex = (index) => ({
  type: 'SET_USER_TASK_GRID_INDEX',
  index
});

// thunks

export const dismissFocusedTask = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SELECTED_TASK'
  });
};

export const getNewFocusedTask = () => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_TASK',
    selectedTask: {
      id: null,
      isCompleted: false,
      isLoading: false,
      isNew: true
    }
  });
};

export const setFocusedTask = (task) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_TASK',
    selectedTask: {
      ...task,
      isCompleted: !!task.taskCompletionId,
      isLoading: false,
      isNew: false
    }
  });
};

export const getFocusedTask = (id, isCompleted, client) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_TASK',
    selectedTask: {
      id,
      isCompleted,
      isLoading: true,
      isNew: false
    }
  });

  dispatch(
    executeAuthAsyncGet(
      client,
      'task/getnewtask',
      'TASK',
      { taskId: id },
      (result) => {
        // not a dataItem so lowercase prop names
        const task = result;
        dispatch({
          type: 'SET_SELECTED_TASK',
          selectedTask: {
            ...task,
            isCompleted: !!task.taskCompletionId,
            isLoading: false,
            isNew: false
          }
        });
      },
      (err) => {
        showToastErrorMessage(
          `Could not load task #${id}
          Error: ${err.message}`
        );
      }
    )
  );
};
