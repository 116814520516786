import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { includes, filter } from 'lodash';
import { getLookup } from '../../api/lookupApi.js';
import { getFocusedTask } from '../../actions/taskActions';
import TaskNotesHistoryGrid from './taskNotesHistoryGrid';
import Nav from '../../api/navApi';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { getTaskDocuments } from '../../actions/documentActions';
import {
  usersSelectList,
  rolesSelectList,
  taskTypesSelectList,
  taskCompletionSelectList
} from '../../selectors/task';
import { useClient } from 'invevo-react-components';
import { webApiInterface } from "../../api/webApiInterface";
import { getApiUrl } from "../../constants";

const ViewTask = ({
  task,
  hideTaskChainLinks = false,
  selectedAccount,
  users,
  roles,
  customerId, isVirtualAccount,
  taskTypes,
  taskDocuments,
  getTaskDocuments,
  getLookup,
  getFocusedTask,
  navToDocs
}) => {
  const [selectedUsers, setSelectedUsers] = useState('');
  const [selectedRoles, setSelectedRoles] = useState('');
  const [taskCompletionTypes, setTaskCompletionTypes] = useState([]);
  const client = useClient()
  const dispatch = useDispatch()

  const { id, taskTypeId } = task;

  useEffect(() => {
    getLookup(client, 'lookup/roles', 'roles', {
      accountId: customerId,
      isvirtualAccount: isVirtualAccount
    });

    getLookup(client, 'lookup/tasktypes', 'taskTypes');

    if (taskTypeId) {
      webApiInterface
          .authFetch(client, `${getApiUrl(client)}api/lookup/TaskCompletionsByTaskType?taskTypeId=${taskTypeId}`, dispatch)
          .then((response) => response.json())
          .then(jsonResponse => setTaskCompletionTypes(taskCompletionSelectList(jsonResponse)))
          .catch(error => console.error(error))
    }

    getTaskDocuments(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    client,
    id,
    taskTypeId,
    customerId,
    isVirtualAccount,
    getLookup,
    getTaskDocuments
  ]);

  useDeepCompareEffect(() => {
    setSelectedUsers(
      filter(users, (u) => includes(task.assignedToUserIds, u.value))
        .map((m) => m.label)
        .join(', ')
    );
  }, [task.assignedToUserIds, users]);

  useDeepCompareEffect(() => {
    setSelectedRoles(
      filter(roles, (r) => includes(task.assignedToRoleIds, r.value))
        .map((m) => m.label)
        .join(', ')
    );
  }, [task.assignedToRoles, roles]);

  const onPrevious = () => {
    getFocusedTask(task.originatingTaskId, task.originatingTaskIsComplete, client);
  };

  const onNext = () => {
    getFocusedTask(task.successorTaskId, task.successorTaskIsComplete, client);
  };

  const selectedTaskType =
    taskTypes.find((t) => t.value === task.taskTypeId) || {};

  const selectedTaskCompletion =
      taskCompletionTypes.find((c) => c.value === task.taskCompletionId) || {};

  return (
    <div className="mia-action col-12 top20">
      <div className="top20">
        <div className="form-group">
          <span>Task Type: {selectedTaskType.label}</span>
        </div>
        <div className="form-group">
          <span>Created by: {task.createdUserName}</span>
        </div>
        <div className="form-group">
          <span>Closed by: {task.completedUserName}</span>
        </div>
        <div className="form-group">
          <span>Closed date: {moment(task.lastUpdated).format("DD MMM yyyy")}</span>
        </div>
        <div className="form-group">
          <span>
            Assigned to: {selectedRoles}
            {selectedRoles.length > 0 && selectedUsers.length > 0 && ', '}
            {selectedUsers}
          </span>
        </div>
        <div className="form-group">
          <span>Task Date: {moment(task.taskDate).format("DD MMM yyyy")}</span>
        </div>
        <div className="form-group">
          <span>Task Completion: {selectedTaskCompletion.label}</span>
        </div>
        <div className="form-group">
          <div>Notes</div>
          <TaskNotesHistoryGrid client={client} dataItem={{ Id: task.id }} />
        </div>
        {/* TODO - why not working...??? */}
        {taskDocuments.length > 0 && !hideTaskChainLinks && (
          <button className="btn btn-default" onClick={navToDocs}>
            Linked Docs ({taskDocuments.length})
          </button>
        )}
        {task.originatingTaskId && !hideTaskChainLinks && (
          <button className="btn btn-default" onClick={onPrevious}>
            Previous Task #{task.originatingTaskId}
          </button>
        )}
        {task.successorTaskId && !hideTaskChainLinks && (
          <button className="btn btn-default" onClick={onNext}>
            Next Task #{task.successorTaskId}
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const lookup = state.lookupReducer;
  return {
    selectedAccount: state.currentSelectionReducer.selectedAccount,
    users: usersSelectList(lookup.users.results),
    roles: rolesSelectList(lookup.roles.results),
    taskTypes: taskTypesSelectList(lookup.taskTypes.results),
    taskDocuments: state.documentReducer.taskDocuments
  };
};

export default connect(mapStateToProps, {
  getLookup,
  getTaskDocuments,
  getFocusedTask,
  navToDocs: Nav.Workspace.RightPane('task', 'files', [
    { key: 'd', value: 'tasks' }
  ])
})(ViewTask);
