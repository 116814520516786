import { Link } from "react-router-dom-v5-compat"
import useIsHovering from "../../../hooks/useIsHovering"
import MenuItemLabel from "./MenuItemLabel"
import classes from "./MenuSubItem.module.scss"
import useClient from "../../../hooks/useClient"

type MenuSubItemProps = {
    label: string
    iconClasses: string
    route: string
}

const MenuSubItem = ({ label, iconClasses, route }: MenuSubItemProps) => {
    const [hoverableRef, isHovering] = useIsHovering()
    const client = useClient()

    return (
        <div ref={hoverableRef} className={`h-100 ${classes.option}`}>
            <Link
                className="d-flex w-100 h-100 align-items-center justify-content-center text-decoration-none"
                to={`/client/${client}/${route}?client=${client}`}
            >
                <i className={`${iconClasses} fs-5 text-blue`} />
                {isHovering && <MenuItemLabel label={label} location="above" />}
            </Link>
        </div>
    )
}

export default MenuSubItem
