import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useInternationalization } from '@progress/kendo-react-intl';
import AccountCard from './accountCard';
import AccountDetail from './accountDetail';
import AccountPreferencesCard from './accountPreferencesCard';
import AccountStatusCard from './accountStatusCard';
import AddressCard from './addressCard';
import Money from '../common/money';
import { findIndex, find, isEmpty } from 'lodash';
import AccountSection from './accountSection';
import { roundAmount } from '../../utility/generalFunctions';
import useDeepCompareEffect from 'use-deep-compare-effect';
import GeneralNoteCard from './generalNoteCard';
import CreditLimitHistoryCard from './creditLimitHistoryCard';
import VirtualParentEditCard from './virtualParentEditCard';
import AgedDebtCard from './agedDebtCard';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useAppLayout from '../../hooks/useAppLayout';
import { jsxArrayJoin } from '../common/jsxArrayJoin';
import useSummaryConfig from '../../hooks/useSummaryConfig';
import InternalContactsCard from './internalContactsCard';
import CustomerContactsCard from './customerContactsCard';
import FallingDueCard from './fallingDueCard';
import RecentPaymentsCard from './recentPaymentsCard';
import ExposureCard from './exposureCard';

const AccountCards = ({
  accountId,
  isVirtualAccount,
  initSummary,
  accountSummarySections,
  dateFormat,
  globalCurrencyCode
}) => {
  const [cachedSummary, setCachedSummary] = useState(initSummary || {});
  const [sections, setSections] = useState();

  const internationalization = useInternationalization();

  const {
    invevoAgedDebtSummaryFeature,
    invevoFallingDueFeature,
    invevoRecentPaymentsFeature
  } = useFlags();

  // reset cache
  useDeepCompareEffect(() => {
    setCachedSummary(initSummary);
  }, [initSummary]);

  const onUpdateAutoChange = (
    AllowAutoIncreaseTradeLimit,
    AllowAutoDecreaseTradeLimit
  ) => {
    setCachedSummary((prevState) => ({
      ...prevState,
      AllowAutoIncreaseTradeLimit,
      AllowAutoDecreaseTradeLimit
    }));
  };

  const onUpdateAccount = (AccountStatus) => {
    setCachedSummary((prevState) => ({
      ...prevState,
      AccountStatus
    }));
  };

  const [summaryConfigItem, setSummaryConfigItem] = useSummaryConfig();

  let tempLimit = '';
  let tempLimitExpiry = '';
  let currentLimitUtilisation = '';
  let vpTradeCreditLimit = '';
  let vpTradeLimitAllocated = '';
  let vpTradeLimitAvailable = '';
  let vpCreditPercentageUsed = '';

  const hasTempTradeLimit = cachedSummary && !!cachedSummary.TempTradeLimit;

  if (!isEmpty(cachedSummary)) {
    tempLimit = (
      <Money
        number={cachedSummary.TempTradeLimit}
        currencyCode={globalCurrencyCode}
      />
    );
    tempLimitExpiry = cachedSummary.TempTradeLimitExpiryDate
      ? internationalization.formatDate(
          new Date(cachedSummary.TempTradeLimitExpiryDate),
          dateFormat
        )
      : '';

    currentLimitUtilisation =
      roundAmount(cachedSummary.CurrentLimitUtilisation, 0) + '%';

    vpCreditPercentageUsed =
      roundAmount(cachedSummary.VPCreditPercentageUsed, 0) + '%';

    vpTradeCreditLimit = (
      <Money
        number={cachedSummary.VPTradeCreditLimit}
        currencyCode={globalCurrencyCode}
      />
    );

    vpTradeLimitAllocated = (
      <Money
        number={cachedSummary.VPTradeLimitAllocated}
        currencyCode={globalCurrencyCode}
      />
    );
    vpTradeLimitAvailable = (
      <Money
        number={cachedSummary.VPTradeLimitAvailable}
        currencyCode={globalCurrencyCode}
      />
    );
  }

  const updateUi = (section, name, value) => {
    setSections((state) => {
      const index = findIndex(
        sections,
        (item) => item.section === section && item.title === name
      );

      if (index === -1) return state;

      return {
        ...state,
        [index]: {
          ...state[index],
          value: value
        }
      };
    });
  };

  const { DynamicRecordSections } = cachedSummary;

  const sectionsToRender = accountSummarySections
    ? accountSummarySections
    : DynamicRecordSections || [];
  const sectionsWithFields = DynamicRecordSections || [];

  const renderedSetions = [];

  sectionsToRender.forEach((section) => {
    if (isVirtualAccount) {
      // vp only setions
      switch (section.SectionType) {
        case 'credit-info':
          renderedSetions.push(
            <AccountCard
              title={section.Name}
              key={section.SectionType}
              open={summaryConfigItem(section.SectionType)}
              setOpen={(newOpen) =>
                setSummaryConfigItem(section.SectionType, newOpen)
              }
            >
              <AccountDetail
                title="VP Trade Limit"
                value={vpTradeCreditLimit}
              />
              <AccountDetail
                title="VP Limit Allocated"
                value={vpTradeLimitAllocated}
                highlight="negitive"
              />
              <AccountDetail
                title="VP Limit Unallocated"
                value={vpTradeLimitAvailable}
                highlight="positive"
              />
              <AccountDetail
                title="VP Percentage Allocated"
                value={vpCreditPercentageUsed}
                layout="hero"
              />
            </AccountCard>
          );
          break;
        case 'exposure':
          renderedSetions.push(
            <AccountCard
              title={section.Name}
              key={section.SectionType}
              open={summaryConfigItem(section.SectionType)}
              setOpen={(newOpen) =>
                setSummaryConfigItem(section.SectionType, newOpen)
              }
            >
              <AccountDetail
                title="Members Overdue Balance"
                value={jsxArrayJoin(
                  cachedSummary.VPAllChildrenOverdueDebtForDisplay
                )}
                layout="hero"
                highlight="negitive"
              />
              <AccountDetail
                title="Members Account Balance"
                value={jsxArrayJoin(
                  cachedSummary.VPAllChildrenBalanceForDisplay
                )}
              />
              <AccountDetail
                title="Credit Members Overdue Balance"
                value={jsxArrayJoin(
                  cachedSummary.VPCreditChildrenOverdueDebtForDisplay
                )}
                layout="hero"
                highlight="negitive"
              />
              <AccountDetail
                title="Credit Members Account Balance"
                value={jsxArrayJoin(
                  cachedSummary.VPCreditChildrenBalanceForDisplay
                )}
              />
            </AccountCard>
          );
          break;
        case 'edit-vp':
          renderedSetions.push(
            <VirtualParentEditCard
              key={section.SectionType}
              title={section.Name}
              open={summaryConfigItem(section.SectionType)}
              setOpen={(newOpen) =>
                setSummaryConfigItem(section.SectionType, newOpen)
              }
            />
          );
          break;
        default:
          break;
      }
    } else {
      // non-vp sections
      switch (section.SectionType) {
        case 'credit-info':
          renderedSetions.push(
            <AccountCard
              title={section.Name}
              key={section.SectionType}
              open={summaryConfigItem(section.SectionType)}
              setOpen={(newOpen) =>
                setSummaryConfigItem(section.SectionType, newOpen)
              }
            >
              {hasTempTradeLimit && (
                <AccountDetail
                  title="Temp Trade Limit"
                  value={
                    <div className="text-align-centre">
                      <h2>{tempLimit}</h2>
                      <p className="font-italic">Expires {tempLimitExpiry}</p>
                    </div>
                  }
                  layout="hero"
                />
              )}
              <AccountDetail
                title="Trade Limit"
                value={cachedSummary.CreditLimitToDisplay}
              />
              {/* <AccountDetail
                title="Current Limit Used"
                value={''}
                highlight="negitive"
              /> */}
              <AccountDetail
                title="Limit Available"
                value={cachedSummary.ActiveAvailableCreditToDisplay}
                highlight={
                  cachedSummary.AvailableCredit > 0 ? 'positive' : 'negitive'
                }
              />
              <AccountDetail
                title="Percentage Used"
                value={currentLimitUtilisation}
                layout="hero"
              />
              <AccountDetail
                title={
                  <p className="mb-10">
                    <span className="text-hide">Mia</span>
                    Max Limit <span className="mia-logo mia-logo-xs"></span>
                  </p>
                }
                value={cachedSummary.MiaRecommendedCreditLimitToDisplay}
              />
              {cachedSummary.ExternalCreditLimit && (
                <AccountDetail
                  title="External Credit Limit"
                  value={
                    <Money
                      number={cachedSummary.ExternalCreditLimit}
                      currencyCode={globalCurrencyCode}
                    />
                  }
                />
              )}
            </AccountCard>
          );
          break;
        case 'exposure':
          renderedSetions.push(
            <ExposureCard
              title={section.Name}
              key={section.SectionType}
              open={summaryConfigItem(section.SectionType)}
              setOpen={(newOpen) =>
                setSummaryConfigItem(section.SectionType, newOpen)
              }
              summary={cachedSummary}
              accountId={accountId}
            />
          );

          break;
        case 'credit-rating':
          renderedSetions.push(
            <AccountCard
              title={section.Name}
              key={section.SectionType}
              open={summaryConfigItem(section.SectionType)}
              setOpen={(newOpen) =>
                setSummaryConfigItem(section.SectionType, newOpen)
              }
            >
              <AccountDetail
                title="Credit Score"
                value={cachedSummary.CreditScore}
              />
              <AccountDetail
                title="Mia Credit Score"
                value={cachedSummary.MiaCreditScore}
              />
              <AccountDetail
                title="Credit Rating"
                value={cachedSummary.ExternalCreditScore}
                layout="hero"
              />
            </AccountCard>
          );
          break;
        case 'collected':
          renderedSetions.push(
            <AccountCard
              title={section.Name}
              key={section.SectionType}
              open={summaryConfigItem(section.SectionType)}
              setOpen={(newOpen) =>
                setSummaryConfigItem(section.SectionType, newOpen)
              }
            >
              <AccountDetail title="Forecast" value={cachedSummary.Forecast} />
              <AccountDetail
                title="Collected"
                value={cachedSummary.Collected}
                highlight="negitive"
              />
              <AccountDetail
                title="Disputed"
                value={jsxArrayJoin(cachedSummary.Disputed)}
              />
              <AccountDetail
                title="Dispute %"
                value={jsxArrayJoin(cachedSummary.DisputedPercentage)}
              />
              <AccountDetail
                title="Promised"
                value={jsxArrayJoin(cachedSummary.Promised)}
                highlight="positive"
              />
              <AccountDetail
                title="Promised %"
                value={jsxArrayJoin(cachedSummary.PromisedPercentage)}
              />
            </AccountCard>
          );
          break;
        case 'payment-performance':
          renderedSetions.push(
            <AccountCard
              title={section.Name}
              key={section.SectionType}
              open={summaryConfigItem(section.SectionType)}
              setOpen={(newOpen) =>
                setSummaryConfigItem(section.SectionType, newOpen)
              }
            >
              <AccountDetail title="DSO" value={cachedSummary.DSO} />
              <AccountDetail title="DBT" value={cachedSummary.DBT} />
            </AccountCard>
          );
          break;
        case 'account-info':
          renderedSetions.push(
            <AccountCard
              title={section.Name}
              key={'account-info'}
              open={summaryConfigItem(section.SectionType)}
              setOpen={(newOpen) =>
                setSummaryConfigItem(section.SectionType, newOpen)
              }
            >
              <AccountDetail
                title="Account Open Date"
                value={
                  cachedSummary.AccountOpenedDate
                    ? internationalization.formatDate(
                        new Date(cachedSummary.AccountOpenedDate),
                        dateFormat
                      )
                    : null
                }
              />
              <AccountDetail
                title="Payment Terms"
                value={cachedSummary.PaymentTerms}
              />
              <AccountDetail
                title="Key Customer"
                value={cachedSummary.IsKeyAccount ? 'Yes' : 'No'}
              />
              <AccountDetail
                title="Business Type"
                value={cachedSummary.BusinessType}
              />
              <AccountDetail title="Turnover" value={cachedSummary.Turnover} />
              <AccountDetail title="Region" value={cachedSummary.SalesRegion} />
              <AccountDetail
                title="Parent Branch ID"
                value={cachedSummary.ParentBranchId}
              />
            </AccountCard>
          );
          break;
        case 'account-status':
          renderedSetions.push(
            <AccountStatusCard
              key={section.SectionType}
              title={section.Name}
              accountStatus={cachedSummary.AccountStatus}
              onUpdate={onUpdateAccount}
            />
          );
          break;
        case 'account-pref':
          renderedSetions.push(
            <AccountPreferencesCard
              key={section.SectionType}
              title={section.Name}
              accountId={accountId}
              isVirtual={isVirtualAccount}
              allowAutoIncrease={cachedSummary.AllowAutoIncreaseTradeLimit}
              allowAutoDecrease={cachedSummary.AllowAutoDecreaseTradeLimit}
              onUpdateAutoChange={onUpdateAutoChange}
              open={summaryConfigItem(section.SectionType)}
              setOpen={(newOpen) =>
                setSummaryConfigItem(section.SectionType, newOpen)
              }
            />
          );
          break;
        case 'aged-debt':
          if (invevoAgedDebtSummaryFeature) {
            renderedSetions.push(
              <AgedDebtCard
                key={section.SectionType}
                title={section.Name}
                accountId={accountId}
                isVirtualAccount={isVirtualAccount}
                open={summaryConfigItem(section.SectionType)}
                setOpen={(newOpen) =>
                  setSummaryConfigItem(section.SectionType, newOpen)
                }
              />
            );
          }
          break;
        case 'falling-due':
          if (invevoFallingDueFeature) {
            renderedSetions.push(
              <FallingDueCard
                key={section.SectionType}
                title={section.Name}
                accountId={accountId}
                isVirtualAccount={isVirtualAccount}
                open={summaryConfigItem(section.SectionType)}
                setOpen={(newOpen) =>
                  setSummaryConfigItem(section.SectionType, newOpen)
                }
              />
            );
          }

          break;
        case 'recent-payments':
          if (invevoRecentPaymentsFeature) {
            renderedSetions.push(
              <RecentPaymentsCard
                key={section.SectionType}
                title={section.Name}
                accountId={accountId}
                isVirtualAccount={isVirtualAccount}
                open={summaryConfigItem(section.SectionType)}
                setOpen={(newOpen) =>
                  setSummaryConfigItem(section.SectionType, newOpen)
                }
              />
            );
          }

          break;
        default:
          if (!section.SectionType) {
            const sectionFields = (
              find(sectionsWithFields, (d) => section.Id === d.Id) || {
                DynamicRecordField: undefined
              }
            ).DynamicRecordField;

            const sectionKey = `Section.${section.Name}`;

            renderedSetions.push(
              <AccountSection
                key={`Section.${section.Name}`}
                sectionName={section.Name}
                accountDetails={sectionFields || []}
                updateUi={updateUi}
                open={summaryConfigItem(sectionKey)}
                setOpen={(newOpen) => setSummaryConfigItem(sectionKey, newOpen)}
              />
            );
          } // else requires implimentation...
          break;
      }
    }

    // all
    switch (section.SectionType) {
      case 'customer-contacts':
        if (
          cachedSummary.CustomerContacts &&
          cachedSummary.CustomerContacts.length > 0
        ) {
          renderedSetions.push(
            <CustomerContactsCard
              key={section.SectionType}
              title={section.Name}
              contacts={cachedSummary.CustomerContacts}
              open={summaryConfigItem(section.SectionType)}
              setOpen={(newOpen) =>
                setSummaryConfigItem(section.SectionType, newOpen)
              }
            />
          );
        }
        break;
      case 'internal-contacts':
        if (
          cachedSummary.InternalContacts &&
          cachedSummary.InternalContacts.length > 0
        ) {
          renderedSetions.push(
            <InternalContactsCard
              key={section.SectionType}
              title={section.Name}
              contacts={cachedSummary.InternalContacts}
              open={summaryConfigItem(section.SectionType)}
              setOpen={(newOpen) =>
                setSummaryConfigItem(section.SectionType, newOpen)
              }
            />
          );
        }
        break;
      case 'billing-address':
        renderedSetions.push(
          <AddressCard
            key={section.SectionType}
            title={section.Name}
            address={cachedSummary.BillingAddress}
            open={summaryConfigItem(section.SectionType)}
            setOpen={(newOpen) =>
              setSummaryConfigItem(section.SectionType, newOpen)
            }
          />
        );
        break;

      case 'credit-limit-history':
        renderedSetions.push(
          <CreditLimitHistoryCard
            title={section.Name}
            key={section.SectionType}
            history={cachedSummary.DecisionList || []}
            open={summaryConfigItem(section.SectionType)}
            setOpen={(newOpen) =>
              setSummaryConfigItem(section.SectionType, newOpen)
            }
          />
        );
        break;
      default:
        break;
    }
  });

  const hasGeneralNote = sectionsToRender.find(
    (s) => s.IsInUse && s.SectionType === 'general-note'
  );

  const { rightPaneBreakPoint } = useAppLayout();
  let columnCount;
  switch (rightPaneBreakPoint) {
    case 'sm':
      columnCount = '3';
      break;
    case 'lg':
      columnCount = '5';
      break;
    default:
      columnCount = '4';
      break;
  }

  return (
    <div className={'container-fluid mt-3'}>
      <div className="row">
        {hasGeneralNote && (
          <div className="col-12 px-1">
            <GeneralNoteCard initNoteText={cachedSummary.GeneralNote} />
          </div>
        )}
        <div className="col-12 mt-2 px-1">
          <div className={'card-columns'} style={{ columnCount: columnCount }}>
            {renderedSetions.map((renderedSetion) => renderedSetion)}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accountId: state.currentSelectionReducer.selectedAccount.miaAccountId,
    isVirtualAccount:
      state.currentSelectionReducer.selectedAccount.miaAccountIsVirtualAccount,
    dateFormat: state.lookupReducer.globalSettings.results.dateFormat,
    globalCurrencyCode:
      state.lookupReducer.globalSettings.results.globalCurrencyCode,
    accountSummarySections: state.lookupReducer.accountSummarySections.results
  };
};

export default connect(mapStateToProps)(AccountCards);
