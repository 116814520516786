import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as lodash from 'lodash';

import { authZ_Permissions, getApiUrl } from '../../../constants';
import { webApiInterface } from '../../../api/webApiInterface';
import { executeAuthAsyncGet } from '../../../utility/asyncSupport';
import ExportCsvButton from './exportCsvButton';
import { hasPermission } from '../../../utility/authZ';

import { CommandCell } from '../../common/grids/commandCell';
import EditDeleteCommandCell from '../../common/grids/editDeleteCommandCell';
import { ConfrimDialog } from '../../common/confirmDialog';
import { ColumnMenuFilter } from '../../common/grids/columnMenu';

import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../../common/grids/StatefulGrid';

class FileTypeGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onEditClick = this.onEditClick.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);

    this.exportCsv = this.exportCsv.bind(this);

    this.state = {
      refresh: 0,
      showDeleteDialog: false,
      itemToDelete: null
    };

    this.EditDeleteCommandCell = EditDeleteCommandCell({
      onEditClick: this.onEditClick,
      onDeleteClick: this.onDeleteClick
    });

    this.ViewCommandCell = CommandCell({
      onCommandClick: this.onEditClick,
      cssTag: 'Edit',
      buttonText: 'Edit'
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.fileType.success ||
      nextProps.fileType.deleted ||
      nextProps.refresh.update
    ) {
      this.refresh();
    }
  }

  refresh = () => {
    this.setState({ refresh: this.state.refresh + 1 });
  };

  onEditClick = (e, dataItem) => {
    e.preventDefault();
    const fileTypeId = dataItem.id;
    this.props.onAdd(fileTypeId);
  };

  onAddClick() {
    this.props.onAdd();
  }

  onDeleteClick = (e, dataItem) => {
    e.preventDefault();
    this.setState({
      showDeleteDialog: true,
      itemToDelete: dataItem
    });
  };

  deleteItem = () => {
    if (this.state.itemToDelete !== null) {
      this.props.onDelete(this.state.itemToDelete);
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.setState({
      showDeleteDialog: false
    });
  };

  getColumns() {
    const columns = [
      { field: 'id', title: 'Id' },
      { field: 'name', title: 'Name' },
      { field: 'importSourceType', title: 'Source Type' },
      { field: 'importLocation', title: 'Location' },
      { field: 'fileNameSpecification', title: 'FName' },
      { field: 'regularExpressionMatch', title: 'FName RegEx' },
      { field: 'fileExtension', title: 'File Ext' },
      {
        field: 'resolutionOrder',
        title: 'Order',
        filter: 'numeric',
        type: 'numeric'
      },
      { field: 'schemaFileName', title: 'XSD' },
      { field: 'recordXPath', title: 'XPath' },
      { field: 'xmlElementName', title: 'XML Element' },
      { field: 'csvDelimiter', title: 'CSV Delimiter' },
      {
        field: 'csvQuoted',
        title: 'CSV Quoted',
        type: 'boolean',
        filter: 'boolean'
      },
      {
        field: 'csvHasHeaders',
        title: 'CSV Headers',
        type: 'boolean',
        filter: 'boolean'
      },
      {
        field: 'isActive',
        title: 'Is Active',
        type: 'boolean',
        filter: 'boolean'
      }
    ];

    return columns;
  }

  exportCsv() {
    const xhr = new XMLHttpRequest();
    const url =
      getApiUrl(this.props.client) +
      'api/import-configuration/export-csv?exportType=3&fileTypeId=';

    xhr.open('GET', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(xhr.response);
      a.download = 'FileTypes.csv';
      a.click();
    };

    this.props.executeAuthAsyncGet(this.props.client, 'Lookup/OK', 'CHECK_OK', null, function () {
      webApiInterface.applySecurity(xhr);
      xhr.send(null);
    });
  }

  static getKey() {
    return 'file-type-grid';
  }

  render() {
    const button = hasPermission(
      this.props.permissions,
      authZ_Permissions.FileImportAdminUpdate
    ) ? (
      <button
        className="btnDefault btn btn-default float-end"
        onClick={this.onAddClick}
      >
        New File Type
      </button>
    ) : (
      ''
    );

    return (
      <div className="clearfix">
        <h3>Import File Type</h3>
        <div className="float-left">
          <ExportCsvButton clicked={this.exportCsv} />
        </div>
        {button}
        <StatefulGrid
          client={this.props.client}
          id={FileTypeGrid.getKey()}
          key={FileTypeGrid.getKey()}
          path={getApiUrl(this.props.client) + 'api/import-configuration/file-types'}
          sortable
          className={'scrollable-none float-left'}
          pageSize={10}
          pageable={{
            pageSizes: [10, 25],
            buttonCount: 5
          }}
          columnMenu={ColumnMenuFilter}
          defaultField={{ field: 'id', dir: 'asc' }}
          refresh={this.state.refresh}
        >
          {this.getColumns().map((column) => (
            <GridColumn key={column.field} {...column} />
          ))}

          {hasPermission(
            this.props.permissions,
            authZ_Permissions.FileImportAdminUpdate
          ) && <GridColumn cell={this.EditDeleteCommandCell} width={'200px'} />}

          {hasPermission(
            this.props.permissions,
            authZ_Permissions.FileImportAdminRead
          ) && <GridColumn cell={this.ViewCommandCell} width={'100px'} />}
        </StatefulGrid>

        <ConfrimDialog
          show={this.state.showDeleteDialog}
          message={'Are you sure you want to delete this record?'}
          onConfirm={this.deleteItem}
          onClose={this.closeDialog}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let fileType = {
    success: false
  };

  let refresh = {
    update: false
  };

  if (state.fileTypes.length > 0) {
    fileType = lodash.last(state.fileTypes);
  }

  if (state.refresh.length > 0) {
    refresh = lodash.last(state.refresh);
  }

  return {
    permissions: state.authReducer.permissions,
    fileType: fileType,
    refresh: refresh
  };
}

export default connect(mapStateToProps, {
  executeAuthAsyncGet
})(FileTypeGrid);
