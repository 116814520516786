import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as lodash from 'lodash';

import ConfigurationGrid from '../../legacy/components/admin/configuration/configurationGrid';
import EditConfiguration from '../../legacy/components/admin/configuration/editConfiguration';
import * as configurationActions from '../../legacy/actions/configurationActions';

class SystemConfigurationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      configuration: {
        showForm: false,
        showGrid: true
      }
    };

    this.showConfigurationForm = this.showConfigurationForm.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      configuration: Object.assign({}, nextProps.configuration)
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    // reset the forms state on leaving
    this.props.actions.cancel();
  }

  showConfigurationForm(
    systemConfigurationId,
    order,
    name,
    systemConfigurationTypeId
  ) {
    this.props.actions.loadConfiguration(
      systemConfigurationId,
      order,
      name,
      systemConfigurationTypeId,
      this.props.client
    );
  }

  cancel() {
    this.props.actions.cancel();
  }

  save(configuration) {
    if (
      !configuration.systemConfigurationId ||
      configuration.systemConfigurationId === 0
    ) {
      this.props.actions.insertConfiguration(configuration, this.props.client);
    } else {
      this.props.actions.updateConfiguration(configuration, this.props.client);
    }
  }

  renderConfigurationForm() {
    if (this.state.configuration.showForm) {
      return (
        <div>
          <EditConfiguration
            onCancel={this.cancel}
            onSave={this.save}
            configuration={this.state.configuration}
            isClient={false}
          />
        </div>
      );
    }
  }

  renderGrid() {
    if (this.state.configuration.showGrid) {
      return (
        <div>
          <ConfigurationGrid
            client={this.props.client}
            isClient={false}
            onEdit={this.showConfigurationForm}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <h2>System Configuration</h2>
        {this.renderGrid()}
        {this.renderConfigurationForm()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let configuration = {
    systemConfigurationId: '',
    systemConfigurationTypeId: '',
    name: '',
    ordinal: '',
    clientConfigurable: '',
    values: '',
    originalValues: '',
    showGrid: true
  };

  if (state.configurations.length > 0) {
    configuration = lodash.last(state.configurations);
  }

  return {
    permissions: state.authReducer.permissions,
    configuration: configuration
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(configurationActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemConfigurationContainer);
