import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authZ_Permissions, getApiUrl } from '../../../constants';
import { storeGridState } from '../../../actions/gridCacheActions';
import { refresh } from '../../../actions/ruleGroupAdminActions';

import { hasPermission } from '../../../utility/authZ';

import { CommandCell } from '../../common/grids/commandCell';
import EditDeleteCopyCommandCell from '../../common/grids/editDeleteCopyCommandCell';
import { ConfrimDialog } from '../../common/confirmDialog';
import { IsEnabled } from '../../common/grids/gridCells';
import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../../common/grids/StatefulGrid';
import existential from '../../../utility/existential';

class RuleGroupManagementGrid extends Component {
  constructor(props, context) {
    super(props, context);

    this.onEditRowClicked = this.onEditRowClicked.bind(this);
    this.onCopyRowClicked = this.onCopyRowClicked.bind(this);
    this.onDeleteRowClicked = this.onDeleteRowClicked.bind(this);

    this.state = {
      refresh: 0,
      showDeleteDialog: false,
      itemToDeleteId: null,
      columns: [
        { field: 'ruleGroup', title: 'Name' },
        { field: 'description', title: 'Description' },
        { field: 'messageType', title: 'Message Type' },
        {
          field: 'eventConditionsDisplay',
          title: 'Event Conditions'
        },
        {
          field: 'enabled',
          cell: IsEnabled,
          title: 'Enabled',
          width: 90,
          filter: 'boolean',
          type: 'boolean'
        },
        { field: 'validDisplay', title: 'Valid', width: 90 }
      ]
    };

    this.EditDeleteCopyCommandCell = EditDeleteCopyCommandCell({
      onEditClick: this.onEditRowClicked,
      onDeleteClick: this.onDeleteRowClicked,
      onCopyClick: this.onCopyRowClicked
    });

    this.ViewCommandCell = CommandCell({
      onCommandClick: this.onEditRowClicked,
      cssTag: 'View',
      buttonText: 'View'
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shouldRefresh.update) {
      this.refresh();
    }
  }

  refresh = () => {
    this.setState({ refresh: this.state.refresh + 1 });
  };
  // onStoreGridState(RuleGroupManagementGrid.getKey(), state)

  onEditRowClicked = (e, dataItem) => {
    e.preventDefault();
    this.props.onEdit(dataItem.id);
  };

  onCopyRowClicked = (e, dataItem) => {
    e.preventDefault();
    this.props.onCopy(dataItem.id);
  };

  onDeleteRowClicked = (e, dataItem) => {
    e.preventDefault();
    this.setState({
      showDeleteDialog: true,
      itemToDeleteId: dataItem.id
    });
  };

  deleteItem = () => {
    if (this.state.itemToDeleteId !== '') {
      this.props.onDelete(this.state.itemToDeleteId);
    }
  };

  closeDialog = () => {
    this.setState({
      showDeleteDialog: false
    });
  };

  static getKey() {
    return 'rule-group-management-grid-key';
  }

  render() {
    const adminPermissions = hasPermission(
      this.props.permissions,
      authZ_Permissions.RuleGroupManagementAdmin
    );
    return (
      <>
        <StatefulGrid
          client={this.props.client}
          id={RuleGroupManagementGrid.getKey()}
          key={RuleGroupManagementGrid.getKey()}
          path={getApiUrl(this.props.client) + 'api/rule/list'}
          sortable
          className={'scrollable-none'}
          pageable={{
            pageSizes: [10, 50, 100],
            buttonCount: 5
          }}
          hideablePaging={true}
          defaultField={{ field: 'Id', dir: 'desc' }}
          refresh={this.state.refresh}
          filterable
        >
          {this.state.columns.map((column) => (
            <GridColumn key={column.field} {...column} />
          ))}

          {adminPermissions && (
            <GridColumn
              cell={this.EditDeleteCopyCommandCell}
              width={250}
              filterable={false}
            />
          )}

          {!adminPermissions && (
            <GridColumn
              cell={this.ViewCommandCell}
              width={100}
              filterable={false}
            />
          )}
        </StatefulGrid>

        <ConfrimDialog
          show={this.state.showDeleteDialog}
          message={'Are you sure you want to delete this rule group?'}
          onConfirm={this.deleteItem}
          onClose={this.closeDialog}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gridState: existential(
      state.gridCacheReducer.grids[RuleGroupManagementGrid.getKey()],
      'gridState',
      {}
    ),
    dateTimeFormat: state.lookupReducer.globalSettings.results.dateTimeFormat,
    shouldRefresh: state.ruleGroupAdminReducer.refresh,
    permissions: state.authReducer.permissions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onStoreGridState: (grid, state) => {
      dispatch(storeGridState(grid, state));
    },
    refresh: (update) => {
      dispatch(refresh(update));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RuleGroupManagementGrid);
