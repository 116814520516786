import classes from "./UserCard.module.scss"

const UserCard = ({state}) => {
    const user = state
    
    return (
        <div className={`d-flex flex-column no-select p-2 ${classes.card} ${classes.shadow}`}>
            <div className="d-flex align-items-center">
                <div className={`d-flex ${classes["profile-picture"]}`}>
                    <i className="fas fa-user fa-2x pb-1 mx-2 my-auto text-grey" />
                </div>
                <h5 className="mb-0 mx-2 text-grey text-truncate">{user.username}</h5>
            </div>
        </div>
    )
}

export default UserCard