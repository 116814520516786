import classes from "./StandardButton.module.scss"

type StandardButtonProps = {
    className?: string
    iconClasses: string
    label: string
    colour?: "white" | "blue" | "grey" | "red"
    border?: "blue" | "grey"
    disabled?: boolean
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    small?: boolean
    ariaLabel?: string
    iconRight?: boolean
}

const StandardButton = ({
    className,
    iconClasses,
    label,
    colour = "white",
    border,
    disabled = false,
    onClick,
    small = false,
    ariaLabel = label,
    iconRight = false
}: StandardButtonProps) => {
    const icon = (
        <div className={`d-flex ${classes.icon} ${iconRight ? classes.iconRight : classes.iconLeft} h-100`}>
            <i className={`m-auto ${iconClasses} ${small ? `${classes.smallButton} px-2` : "py-2 px-3 fs-5"}`} />
        </div>
    )

    const buttonLabel = (
        <div className={`d-flex ${classes.label} ${iconRight ? classes.labelLeft : classes.labelRight} w-100 h-100 align-items-center py-1`}>
            <span className={`${small ? classes.smallButton : "fs-5"} ${colour === "grey" ? "ms-3" : small ? "mx-2" : "mx-3"} fw-normal mb-0`}>{label}</span>
        </div>
    )
    return (
        <div
            className={`${className} d-flex`}
            role="button"
            aria-label={ariaLabel}
            onClick={e => {
                !disabled && onClick(e)
            }}
        >
            <div
                className={`${border ? classes[`border-${border}`] : ""} d-flex w-100 ${classes.button} ${classes[`button-${colour}`]} ${
                    disabled ? `${classes.disabled} not-allowed` : "pointer"
                } no-select align-items-center `}
            >
                {colour === "grey" || iconRight ? (
                    <>
                        {buttonLabel}
                        {icon}
                    </>
                ) : (
                    <>
                        {icon}
                        {buttonLabel}
                    </>
                )}
            </div>
        </div>
    )
}

export default StandardButton
