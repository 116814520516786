import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { doSearch } from '../../actions/searchActions';
import { forEach, map } from 'lodash';
import { showToastWarningMessage } from '../../api/toasterApi';
import ValidatingInput from '../common/ValidatingInput';
class QuickSearch extends React.Component {
  // eslint-disable-next-line no-use-before-define
  state = QuickSearch.defaultFormState();

  componentDidUpdate(prevProps) {
    if (this.props.isNew && !prevProps.isNew) {
      this.clearForm();
    }
  }

  clearForm = () => {
    this.setState(QuickSearch.defaultFormState());
  };

  onChange = (event) => {
    const field = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      search: { ...prevState.search, [field]: value }
    }));
  };

  search = () => {
    if (this.canSearch()) {
      // Send an array of search field text prepended by the SortOrder and a tilde:
      // [ '0~text1', '1~text2', '2~text3', '3~text4', '4~text5', ... ]
      const fields = [];
      forEach(this.state.search, (value, key) => {
        if (value) fields.push(`${key}~${value}`);
      });

      this.props.doSearch({ fields: fields.toString(), type: 'quick' });

      this.props.onSearch();

      return true;
    } else {
      return false;
    }
  };

  enableSearch = () => {
    this.setState({
      canSubmit: true
    });
  };

  disableSearch = () => {
    this.setState({
      canSubmit: false
    });
  };

  static defaultFormState = () => {
    return {
      search: {},
      canSubmit: false
    };
  };

  canSearch = () => {
    const searchCriteria = [];

    forEach(this.state.search, (value) => {
      if (value.length > 2) {
        searchCriteria.push(value);
      }
    });

    return searchCriteria.length > 0 && this.state.canSubmit;
  };

  static parseValidator(str) {
    try {
      if (str === 'isEmail') {
        return str;
      }
      return JSON.parse(str);
    } catch (e) {
      showToastWarningMessage(
        `${str} is not valid JSON for Quick Search and has not been parsed. Please check the database.`
      );
      return false;
    }
  }

  render() {
    const stateSearch = this.state.search;
    let searchComponents = '';

    if (this.props && this.props.quickSearchFields) {
      searchComponents = map(this.props.quickSearchFields, (x) => {
        const uniqueIndex = `${x.SortOrder}`;
        const parsed = QuickSearch.parseValidator(x.ValidationJson);
        return (
          <div className="row" key={uniqueIndex}>
            <div className="col-12 mt-2">
              <ValidatingInput
                name={uniqueIndex}
                value={stateSearch[uniqueIndex]}
                placeholder={x.PlaceHolderText}
                hideFormGroupClass={true}
                hideLabel={true}
                validations={parsed}
                validationError={x.ValidationErrorMessage}
                onChange={this.onChange}
              />
            </div>
          </div>
        );
      });
    }

    return (
      <Formsy
        onValid={this.enableSearch}
        onInvalid={this.disableSearch}
        onSubmit={this.search}
        className="container-fluid"
      >
        {searchComponents}

        <div className="row mt-3">
          <div className="col-6">
            <button
              data-cy="quick-clear-search"
              type="button"
              onClick={this.clearForm}
              disabled={!this.canSearch()}
              className="btn btn-default pointer"
            >
              Clear
            </button>
          </div>
          <div className="d-flex col-6">
            <button
              data-cy="quick-search"
              type="submit"
              disabled={!this.canSearch()}
              className="btn btn-primary ms-auto pointer"
            >
              Search
            </button>
          </div>
        </div>
      </Formsy>
    );
  }
}

QuickSearch.propTypes = {
  doSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  foundData: PropTypes.bool
};

QuickSearch.defaultProps = {
  foundData: false
};

const mapStateToProps = (state) => {
  return {
    foundData: state.searchReducer.search.foundData,
    quickSearchFields: state.lookupReducer.quickSearchFields.results
  };
};

export default connect(mapStateToProps, {
  doSearch
})(QuickSearch);
