import Filter from "./Filter"
import { useEffect, useState } from "react"
import { v4 as uuid } from "uuid"
import DataField from "../../types/DataField"
import Lookup from "../../types/Lookup"
import GenericFilter from "./filterTypes/GenericFilter"
import FilterTypesByField from "./FilterTypesByField"
import { padElementsWith } from "../helpers"
import StandardButton from "../buttons/StandardButton/StandardButton"
import ShadowBorder from "../borders/ShadowBorder"
import { useFeatureToggle } from "../../hooks/useFeatureToggle"

type FilterListProps = {
    fields: DataField[]
    lookups: Lookup[]
    appliedFilters?: GenericFilter[]
    filtersFromQuickSearch?: GenericFilter[]
    onFiltersApplied?: (filters: GenericFilter[]) => void
    autoApply?: boolean
    disabled?: boolean
    showFieldType?: boolean
    isCompact?: boolean
    colour?: "blue" | "grey"
    showRelativeDateOptions?: boolean
    showFieldIsOneOfOptions?: boolean
    showClearFiltersButton?: boolean
}

const FilterList = ({
    fields,
    lookups,
    appliedFilters = [],
    filtersFromQuickSearch = [],
    onFiltersApplied = () => {},
    autoApply = false,
    disabled = false,
    showFieldType = false,
    isCompact = false,
    colour = "grey",
    showRelativeDateOptions = false,
    showFieldIsOneOfOptions = false,
    showClearFiltersButton = true
}: FilterListProps) => {
    const { isEnabled: isNewEntityStructureEnabled } = useFeatureToggle("newEntityStructure")

    const shouldShowRelativeDateOptions = showRelativeDateOptions && isNewEntityStructureEnabled
    const shouldShowFieldIsOneOfOptions = showFieldIsOneOfOptions && isNewEntityStructureEnabled

    const [filters, setFilters] = useState(appliedFilters)

    useEffect(() => {
        setFilters(appliedFilters)
    }, [appliedFilters])

    const onFilterUpdated = (filter: GenericFilter) => {
        const updatedFilters = filters.map(f => (f.id === filter.id ? filter : f))

        setFilters(updatedFilters)

        if (autoApply) {
            onFiltersApplied(updatedFilters)
        }
    }

    const onFilterDeleted = (filter: GenericFilter) => {
        const updatedFilters = filters.filter(f => f.id !== filter.id)

        setFilters(updatedFilters)

        if (autoApply) {
            onFiltersApplied(updatedFilters)
        }
    }

    const onNewFilterClicked = () => setFilters(filters => [...filters, { type: FilterTypesByField.NO_TYPE, id: uuid(), fieldName: "" }])

    const onFiltersAppliedClicked = () => onFiltersApplied(filters)

    const onFiltersClearedClicked = () => {
        setFilters([])
        onFiltersApplied([])
    }

    const hasUnappliedFilters = JSON.stringify(appliedFilters) !== JSON.stringify(filters)

    const showActionButtons = !disabled && onFiltersApplied !== undefined
    const filterElements = filters.map(filter => (
        <ShadowBorder key={filter.id} className={`d-flex p-2 ${isCompact ? "flex-column" : "align-items-center"}`}>
            <Filter
                filter={filter}
                fields={fields}
                lookups={lookups}
                disabled={disabled}
                onFilterUpdated={onFilterUpdated}
                onFilterDeleted={onFilterDeleted}
                showFieldType={showFieldType}
                isCompact={isCompact}
                colour={colour}
                showRelativeDateOptions={shouldShowRelativeDateOptions}
                showFieldIsOneOfOptions={shouldShowFieldIsOneOfOptions}
            />
        </ShadowBorder>
    ))

    const quickFilterElements = filtersFromQuickSearch.map(filter => (
        <ShadowBorder key={filter.id} className={`d-flex p-2 ${isCompact ? "flex-column" : "align-items-center"}`}>
            <Filter
                filter={filter}
                fields={fields}
                lookups={lookups}
                disabled={true}
                onFilterUpdated={onFilterUpdated}
                onFilterDeleted={onFilterDeleted}
                showFieldType={showFieldType}
                isCompact={isCompact}
                colour={colour}
                showRelativeDateOptions={shouldShowRelativeDateOptions}
                showFieldIsOneOfOptions={shouldShowFieldIsOneOfOptions}
            />
        </ShadowBorder>
    ))

    return (
        <div className="d-flex flex-grow-1 flex-column">
            {filters.length === 0 && filtersFromQuickSearch.length === 0 ? (
                <div className={`d-flex align-items-center ${colour === "grey" ? "text-grey" : "text-white"} p-2`}>
                    <i className="me-2 fal fa-exclamation-circle" />
                    <h6 className="mb-0">You have no filters defined</h6>
                </div>
            ) : (
                <div className="d-flex flex-column">
                    {padElementsWith(filterElements, "my-2")} {padElementsWith(quickFilterElements, "my-2")}
                </div>
            )}
            {showActionButtons && (
                <div className="d-flex justify-content-between gap-2 mt-3">
                    <div className="mt-auto">
                        <StandardButton
                            iconClasses="fal fa-plus"
                            label={isCompact ? "Add" : "Add New Filter"}
                            ariaLabel="Add New Filter"
                            colour={colour}
                            onClick={onNewFilterClicked}
                        />
                    </div>
                    <div className="d-flex">
                        {showClearFiltersButton && (
                            <div className="mt-auto">
                                <StandardButton
                                    iconClasses="fal fa-times"
                                    label={isCompact ? "Clear" : "Clear Filters"}
                                    onClick={onFiltersClearedClicked}
                                    colour="blue"
                                />
                            </div>
                        )}
                        {!autoApply && (
                            <>
                                <div className="mx-1" />
                                <div className="d-flex flex-column align-items-center">
                                    {hasUnappliedFilters && (
                                        <div className="d-flex align-items-center text-grey mb-2">
                                            <i className="me-2 fal fa-exclamation-circle" />
                                            <h6 className="mb-0">You have unapplied changes</h6>
                                        </div>
                                    )}
                                    <StandardButton iconClasses="fal fa-check" label="Apply Filters" onClick={onFiltersAppliedClicked} colour="blue" />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default FilterList
