import { AutoComplete, useClient, useConfig } from "invevo-react-components"
import DataPrimitive from "../../types/DataPrimitive"
import classes from "./InputList.module.scss"

type InputListProps = {
    fieldName: string
    fieldType: DataPrimitive
    values: string[]
    onValuesChanged: (values: string[]) => void
    disabled?: boolean
}

const InputList = ({ fieldName, fieldType, values, onValuesChanged, disabled = false }: InputListProps) => {
    const config = useConfig()
    const client = useClient()

    const onValueChange = (value: string, index: number) => {
        const newValues = [...values]
        newValues[index] = value
        onValuesChanged(newValues.filter(value => value !== ""))
    }

    const valuesToRender = disabled ? values : [...values, ""]

    return (
        <div className="d-flex flex-column">
            {valuesToRender.map((value, index) => (
                <AutoComplete
                    key={index}
                    className={`${index !== valuesToRender.length - 1 ? "mb-1" : ""} 
                    ${value === "" ? classes.unusedInput : ""}`}
                    url={`${config.AUTOMATE_API_URL}/api/${client}/autocomplete-field-values`}
                    fieldName={fieldName}
                    fieldType={fieldType}
                    initialValue={value}
                    onChange={(value: string) => onValueChange(value, index)}
                    enableTooltip={true}
                    disabled={disabled}
                    ariaLabel={`filter-input-value`}
                />
            ))}
        </div>
    )
}

export default InputList
