import {
    CustomerFileImportConfigDto,
    FieldConfigDto,
    FileImportConfigColumnTypeDto,
    FileImportConfigFieldDataTypeDto,
    FileImportConfigFieldDefaultValueDto,
    FileImportConfigFieldTrimOptionDto,
    GetFileImportConfigDto,
    PutFileImportConfigDto,
    ReferenceFieldConfigDto,
    TransactionFileImportConfigDto
} from "../types/dtos/FileImportConfigDto"
import {
    CustomerFileImportConfig,
    DataFileImportConfig,
    FieldConfig,
    FileImportConfig,
    FileType,
    ReferenceFieldConfig,
    TransactionFileImportConfig
} from "../types/FileImportConfig"
import { FileImportConfigFieldDataType, FileImportConfigFieldDataTypeEnum, TextFormatter } from "../types/FileImportConfigFieldDataType"
import { FileImportConfigColumnType, FileImportConfigColumnTypeEnum } from "../types/FileImportConfigColumnType"
import { FileImportConfigFieldDefaultValue, FileImportConfigFieldDefaultValueEnum } from "../types/FileImportConfigFieldDefaultValue"
import { FileImportConfigFieldTrimOption, FileImportConfigFieldTrimOptionEnum } from "../types/FileImportConfigTrimOption"
import { DataFieldDto } from "../../../types/dtos/DataFieldDto"
import { CommonFileExportConfigDto, ExportFieldConfigDto, GetFileExportConfigDto, PutFileExportConfigDto } from "../types/dtos/FileExportConfigDto"
import { CommonFileExportConfig, ExportFieldConfig, FileExportConfig } from "../types/FileExportConfig"
import { convertFromArrayToDto, convertFromDtoToArray } from "../../../library/FilterList/FiltersDto"
import DataPrimitive from "../../../types/DataPrimitive"
import { DataTypeEnum } from "../../entityConfig/types/DataType"
import {
    CsvEntityExportFieldConfigDto,
    GetFileEntityExportConfigDto,
    PutFileEntityExportConfigDto,
    XmlAggregatedEntityExportNodeDto,
    XmlAggregatedEntityExportNodeEnumDto,
    XmlEntityExportNodeDto,
    XmlEntityExportNodeEnumDto
} from "../types/dtos/FileEntityExportConfigDto"
import {
    EntityExportFieldConfig,
    FileEntityExportConfig,
    XmlAggregatedEntityExportNode,
    XmlAggregatedEntityExportParent,
    XmlEntityExportNode,
    XmlEntityExportNodeEnum,
    XmlEntityExportParent
} from "../types/fileEntityExport/FileExportConfig"
import { v4 as uuidv4 } from "uuid"

export const delimiters = [
    { id: "1", value: "|" },
    { id: "2", value: "," },
    { id: "3", value: ";" },
    { id: "4", value: "\\t" }
]

export const lineEndings = [
    { id: "1", value: "CR" },
    { id: "2", value: "LF" },
    { id: "3", value: "CRLF" }
]

export enum FileFormat {
    CSV = "CSV",
    TEXT = "TEXT",
    XML = "XML"
}

export const mapGetFileImportConfigDtoToDomain = (dto: GetFileImportConfigDto[]): FileImportConfig[] =>
    dto.map(config => ({
        reference: config.reference,
        fileType: config.fileType,
        fileFormat: config.fileFormat,
        dataFileImportConfig:
            config.fileType === FileType.CUSTOMER
                ? mapCustomerFileImportConfigDtoToDomain(config.dataFileImportConfig as CustomerFileImportConfigDto)
                : mapTransactionFileImportConfigDtoToDomain(config.dataFileImportConfig as TransactionFileImportConfigDto)
    }))

const mapCustomerFileImportConfigDtoToDomain = (dto: CustomerFileImportConfigDto): CustomerFileImportConfig => ({
    type: FileType.CUSTOMER,
    configName: dto.configName,
    fileNamePattern: dto.fileNamePattern,
    delimiter: dto.delimiter === "\t" ? "\\t" : dto.delimiter,
    lineEnding: dto.lineEnding,
    hasHeaderRow: dto.hasHeaderRow,
    hasQuotedFields: dto.hasQuotedFields,
    rejectMandatoryAction: dto.rejectMandatoryAction,
    entityType: dto.entityType,
    mandatoryFieldConfig: mapReferenceFieldConfigDtoToDomain(dto.mandatoryReferenceFieldConfig),
    optionalFieldConfigs: dto.optionalFieldConfigs ? dto.optionalFieldConfigs.map(field => mapFileImportFieldConfigDtoToDomain(field)) : []
})

const mapTransactionFileImportConfigDtoToDomain = (dto: TransactionFileImportConfigDto): TransactionFileImportConfig => ({
    type: FileType.TRANSACTION,
    configName: dto.configName,
    fileNamePattern: dto.fileNamePattern,
    delimiter: dto.delimiter === "\t" ? "\\t" : dto.delimiter,
    lineEnding: dto.lineEnding,
    hasHeaderRow: dto.hasHeaderRow,
    hasQuotedFields: dto.hasQuotedFields,
    rejectMandatoryAction: dto.rejectMandatoryAction,
    entityType: dto.entityType,
    isOpenItems: dto.isOpenItems,
    openItemsThresholdPercentage: dto.openItemsThresholdPercentage,
    mandatoryFieldConfig: mapReferenceFieldConfigDtoToDomain(dto.mandatoryReferenceFieldConfig),
    mandatoryCustomerFieldConfig: mapReferenceFieldConfigDtoToDomain(dto.mandatoryCustomerReferenceFieldConfig),
    optionalFieldConfigs: dto.optionalFieldConfigs ? dto.optionalFieldConfigs.map(field => mapFileImportFieldConfigDtoToDomain(field)) : []
})

const mapReferenceFieldConfigDtoToDomain = (dto: ReferenceFieldConfigDto | undefined): ReferenceFieldConfig =>
    dto
        ? {
              columns: dto.columns.map(column => mapFileImportConfigColumnTypeDtoToDomain(column)),
              trimOptions: dto.trimOptions?.map(option => mapFileImportConfigFieldTrimOptionDtoToDomain(option)) || []
          }
        : { columns: [], trimOptions: [] }

const mapFileImportFieldConfigDtoToDomain = (dto: FieldConfigDto): FieldConfig => ({
    columns: dto.columns.map(column => mapFileImportConfigColumnTypeDtoToDomain(column)),
    dataFieldReference: dto.dataFieldReference,
    dataType: mapFileImportConfigFieldDataTypeDtoToDomain(dto.dataType),
    defaultValue: dto.defaultValue ? mapFileImportConfigFieldDefaultValueDtoToDomain(dto.defaultValue) : undefined,
    canBeEmpty: dto.canBeEmpty,
    rejectAction: dto.rejectAction,
    trimOptions: dto.trimOptions?.map(option => mapFileImportConfigFieldTrimOptionDtoToDomain(option)) || []
})

const mapFileImportConfigColumnTypeDtoToDomain = (dto: FileImportConfigColumnTypeDto): FileImportConfigColumnType => {
    switch (dto.type) {
        case FileImportConfigColumnTypeEnum.HEADER:
            return {
                type: dto.type,
                header: dto.header || ""
            }
        case FileImportConfigColumnTypeEnum.INDEX:
            return {
                type: dto.type,
                index: dto.index || 1
            }
    }
}

const mapFileImportConfigFieldDataTypeDtoToDomain = (dto: FileImportConfigFieldDataTypeDto): FileImportConfigFieldDataType => {
    switch (dto.type) {
        case FileImportConfigFieldDataTypeEnum.BOOLEAN:
            return {
                type: dto.type
            }
        case FileImportConfigFieldDataTypeEnum.DATE:
            return {
                type: dto.type,
                format: dto.format || "",
                timezone: dto.timezone || ""
            }
        case FileImportConfigFieldDataTypeEnum.NUMBER:
            return {
                type: dto.type,
                culture: dto.culture || ""
            }
        case FileImportConfigFieldDataTypeEnum.TEXT:
            return {
                type: dto.type
            }
    }
}

const mapFileImportConfigFieldDefaultValueDtoToDomain = (dto: FileImportConfigFieldDefaultValueDto): FileImportConfigFieldDefaultValue => {
    switch (dto.type) {
        case FileImportConfigFieldDefaultValueEnum.BOOLEAN:
            return {
                type: dto.type,
                booleanValue: dto.booleanValue || false
            }
        case FileImportConfigFieldDefaultValueEnum.NUMBER:
            return {
                type: dto.type,
                numberValue: dto.numberValue || ""
            }
        case FileImportConfigFieldDefaultValueEnum.STRING:
            return {
                type: dto.type,
                stringValue: dto.stringValue || ""
            }
    }
}

const mapFileImportConfigFieldTrimOptionDtoToDomain = (dto: FileImportConfigFieldTrimOptionDto): FileImportConfigFieldTrimOption => {
    switch (dto.type) {
        case FileImportConfigFieldTrimOptionEnum.REMOVE_FIRST_N_CHARACTERS:
            return {
                type: FileImportConfigFieldTrimOptionEnum.REMOVE_FIRST_N_CHARACTERS,
                take: dto.take || 1
            }
        case FileImportConfigFieldTrimOptionEnum.REMOVE_LAST_N_CHARACTERS:
            return {
                type: FileImportConfigFieldTrimOptionEnum.REMOVE_LAST_N_CHARACTERS,
                take: dto.take || 1
            }
        case FileImportConfigFieldTrimOptionEnum.REMOVE_STRING:
            return {
                type: FileImportConfigFieldTrimOptionEnum.REMOVE_STRING,
                characters: dto.characters || ""
            }
        case FileImportConfigFieldTrimOptionEnum.REMOVE_STRING_FROM_END:
            return {
                type: FileImportConfigFieldTrimOptionEnum.REMOVE_STRING_FROM_END,
                characters: dto.characters || ""
            }
        case FileImportConfigFieldTrimOptionEnum.REMOVE_STRING_FROM_START:
            return {
                type: FileImportConfigFieldTrimOptionEnum.REMOVE_STRING_FROM_START,
                characters: dto.characters || ""
            }
        case FileImportConfigFieldTrimOptionEnum.TAKE_FIRST_N_CHARACTERS:
            return {
                type: FileImportConfigFieldTrimOptionEnum.TAKE_FIRST_N_CHARACTERS,
                take: dto.take || 1
            }
        case FileImportConfigFieldTrimOptionEnum.TAKE_LAST_N_CHARACTERS:
            return {
                type: FileImportConfigFieldTrimOptionEnum.TAKE_LAST_N_CHARACTERS,
                take: dto.take || 1
            }
        case FileImportConfigFieldTrimOptionEnum.TAKE_AFTER_LAST_STRING:
            return {
                type: FileImportConfigFieldTrimOptionEnum.TAKE_AFTER_LAST_STRING,
                characters: dto.characters || ""
            }
        case FileImportConfigFieldTrimOptionEnum.TAKE_BEFORE_FIRST_STRING:
            return {
                type: FileImportConfigFieldTrimOptionEnum.TAKE_BEFORE_FIRST_STRING,
                characters: dto.characters || ""
            }
    }
}

export const mapDomainToPutFileImportConfigDto = (domain: FileImportConfig): PutFileImportConfigDto => {
    return {
        fileType: domain.fileType,
        fileFormat: domain.fileFormat,
        dataFileImportConfig:
            domain.fileType === FileType.CUSTOMER
                ? mapDomainToCustomerFileImportConfig(domain.dataFileImportConfig)
                : mapDomainToTransactionFileImportConfig(domain.dataFileImportConfig as TransactionFileImportConfig)
    }
}

const mapDomainToCustomerFileImportConfig = (domain: DataFileImportConfig): CustomerFileImportConfigDto => ({
    configName: domain.configName,
    fileNamePattern: domain.fileNamePattern,
    delimiter: domain.delimiter.replace("\\t", "\t"),
    lineEnding: domain.lineEnding,
    hasHeaderRow: domain.hasHeaderRow,
    rejectMandatoryAction: domain.rejectMandatoryAction,
    entityType: domain.entityType,
    hasQuotedFields: domain.hasQuotedFields,
    mandatoryReferenceFieldConfig: mapDomainToReferenceFieldConfig(domain.mandatoryFieldConfig),
    optionalFieldConfigs: domain.optionalFieldConfigs.map(field => mapDomainToFieldConfig(field))
})

const mapDomainToTransactionFileImportConfig = (domain: TransactionFileImportConfig): TransactionFileImportConfigDto => ({
    configName: domain.configName,
    fileNamePattern: domain.fileNamePattern,
    delimiter: domain.delimiter.replace("\\t", "\t"),
    lineEnding: domain.lineEnding,
    hasHeaderRow: domain.hasHeaderRow,
    hasQuotedFields: domain.hasQuotedFields,
    rejectMandatoryAction: domain.rejectMandatoryAction,
    entityType: domain.entityType,
    isOpenItems: domain.isOpenItems,
    openItemsThresholdPercentage: domain.openItemsThresholdPercentage,
    mandatoryReferenceFieldConfig: mapDomainToReferenceFieldConfig(domain.mandatoryFieldConfig),
    mandatoryCustomerReferenceFieldConfig: mapDomainToReferenceFieldConfig(domain.mandatoryCustomerFieldConfig),
    optionalFieldConfigs: domain.optionalFieldConfigs.map(field => mapDomainToFieldConfig(field))
})

const mapDomainToReferenceFieldConfig = (domain: ReferenceFieldConfig): ReferenceFieldConfigDto => ({
    columns: domain.columns.map(column => mapDomainToFileImportConfigColumnType(column)),
    trimOptions: domain.trimOptions.map(option => mapDomainToFileImportConfigFieldTrimOption(option))
})

const mapDomainToFieldConfig = (domain: FieldConfig): FieldConfigDto => ({
    columns: domain.columns.map(column => mapDomainToFileImportConfigColumnType(column)),
    dataFieldReference: domain.dataFieldReference,
    dataType: domain.dataType,
    defaultValue: domain.defaultValue,
    applyOnlyFirstUpdate: false,
    canBeEmpty: domain.canBeEmpty,
    rejectAction: domain.rejectAction,
    trimOptions: domain.trimOptions.map(option => mapDomainToFileImportConfigFieldTrimOption(option))
})

const mapDomainToFileImportConfigColumnType = (domain: FileImportConfigColumnType): FileImportConfigColumnTypeDto => {
    switch (domain.type) {
        case FileImportConfigColumnTypeEnum.HEADER:
            return {
                type: FileImportConfigColumnTypeEnum.HEADER,
                header: domain.header
            }
        case FileImportConfigColumnTypeEnum.INDEX:
            return {
                type: FileImportConfigColumnTypeEnum.INDEX,
                index: domain.index
            }
    }
}

const mapDomainToFileImportConfigFieldTrimOption = (domain: FileImportConfigFieldTrimOption): FileImportConfigFieldTrimOptionDto => {
    switch (domain.type) {
        case FileImportConfigFieldTrimOptionEnum.REMOVE_STRING:
            return {
                type: FileImportConfigFieldTrimOptionEnum.REMOVE_STRING,
                characters: domain.characters
            }
        case FileImportConfigFieldTrimOptionEnum.REMOVE_FIRST_N_CHARACTERS:
            return {
                type: FileImportConfigFieldTrimOptionEnum.REMOVE_FIRST_N_CHARACTERS,
                take: domain.take
            }
        case FileImportConfigFieldTrimOptionEnum.REMOVE_LAST_N_CHARACTERS:
            return {
                type: FileImportConfigFieldTrimOptionEnum.REMOVE_LAST_N_CHARACTERS,
                take: domain.take
            }
        case FileImportConfigFieldTrimOptionEnum.REMOVE_STRING_FROM_START:
            return {
                type: FileImportConfigFieldTrimOptionEnum.REMOVE_STRING_FROM_START,
                characters: domain.characters
            }
        case FileImportConfigFieldTrimOptionEnum.REMOVE_STRING_FROM_END:
            return {
                type: FileImportConfigFieldTrimOptionEnum.REMOVE_STRING_FROM_END,
                characters: domain.characters
            }
        case FileImportConfigFieldTrimOptionEnum.TAKE_FIRST_N_CHARACTERS:
            return {
                type: FileImportConfigFieldTrimOptionEnum.TAKE_FIRST_N_CHARACTERS,
                take: domain.take
            }
        case FileImportConfigFieldTrimOptionEnum.TAKE_LAST_N_CHARACTERS:
            return {
                type: FileImportConfigFieldTrimOptionEnum.TAKE_LAST_N_CHARACTERS,
                take: domain.take
            }
        case FileImportConfigFieldTrimOptionEnum.TAKE_AFTER_LAST_STRING:
            return {
                type: FileImportConfigFieldTrimOptionEnum.TAKE_AFTER_LAST_STRING,
                characters: domain.characters
            }
        case FileImportConfigFieldTrimOptionEnum.TAKE_BEFORE_FIRST_STRING:
            return {
                type: FileImportConfigFieldTrimOptionEnum.TAKE_BEFORE_FIRST_STRING,
                characters: domain.characters
            }
    }
}

export const mapGetFileExportConfigDtoToDomain = (dto: GetFileExportConfigDto[]): FileExportConfig[] =>
    dto.map(config => ({
        reference: config.reference,
        fileType: config.fileType,
        fileFormat: config.fileFormat,
        dataFileExportConfig: mapCustomerFileExportConfigDtoToDomain(config.dataFileExportConfig)
    }))

export const mapGetFileEntityExportConfigsDtoToDomain = (dto: GetFileEntityExportConfigDto[]): FileEntityExportConfig[] =>
    dto.map(config => mapGetFileEntityExportConfigDtoToDomain(config))

const mapGetFileEntityExportConfigDtoToDomain = (config: GetFileEntityExportConfigDto): FileEntityExportConfig => {
    switch (config.fileFormat) {
        case FileFormat.TEXT:
        case FileFormat.CSV:
            return {
                reference: config.reference,
                entityType: config.entityType,
                fileFormat: config.fileFormat,
                filename: config.filename,
                delimiter: config.delimiter === "\t" ? "\\t" : config.delimiter,
                lineEnding: config.lineEnding,
                hasHeaderRow: config.hasHeaderRow,
                quoteFieldsWith: config.quoteFieldsWith,
                filters: convertFromDtoToArray(config.filters, [], []),
                dateFormat: config.dateFormat,
                optionalFieldConfigs: config.optionalFieldConfigs
                    ? config.optionalFieldConfigs.map((field, index) => mapFileEntityExportFieldConfigDtoToDomain(field, index))
                    : [],
                subdirectoryPath: config.subdirectoryPath,
                rootNodeFieldsConfig: {
                    type: XmlEntityExportNodeEnum.PARENT,
                    id: uuidv4(),
                    name: "Root Name",
                    children: []
                },
                headersNodeFieldsConfig: [],
                footersNodeFieldsConfig: []
            } as FileEntityExportConfig
        case FileFormat.XML:
            return {
                reference: config.reference,
                entityType: config.entityType,
                fileFormat: FileFormat.XML,
                filename: config.filename,
                lineEnding: config.lineEnding,
                quoteFieldsWith: config.quoteFieldsWith,
                filters: convertFromDtoToArray(config.filters, [], []),
                dateFormat: config.dateFormat,
                rootNodeFieldsConfig: mapFileEntityExportNodeDtoToDomain(config.rootNodeFieldsConfig) as XmlEntityExportParent,
                headersNodeFieldsConfig: config.headersNodeFieldsConfig.map(
                    header => mapFileEntityAggregatedExportNodeDtoToDomain(header) as XmlAggregatedEntityExportParent
                ),
                footersNodeFieldsConfig: config.footersNodeFieldsConfig.map(
                    footer => mapFileEntityAggregatedExportNodeDtoToDomain(footer) as XmlAggregatedEntityExportParent
                ),
                subdirectoryPath: config.subdirectoryPath,
                delimiter: "",
                hasHeaderRow: false,
                optionalFieldConfigs: []
            } as FileEntityExportConfig
    }
}

const mapFileEntityExportNodeDtoToDomain = (dto: XmlEntityExportNodeDto): XmlEntityExportNode => {
    switch (dto.type) {
        case XmlEntityExportNodeEnumDto.PARENT:
            return {
                type: XmlEntityExportNodeEnum.PARENT,
                name: dto.parent?.name ?? "",
                children: dto.parent?.children.map(child => mapFileEntityExportNodeDtoToDomain(child)) || []
            }
        case XmlEntityExportNodeEnumDto.FIELD_CONFIG:
            return {
                name: dto.fieldConfig?.name ?? "",
                type: XmlEntityExportNodeEnum.FIELD_CONFIG,
                dataFieldReference: dto.fieldConfig?.dataFieldReference ?? "",
                dataType: dto.fieldConfig?.dataType
                    ? mapFileImportConfigFieldDataTypeDtoToDomain(dto.fieldConfig.dataType)
                    : { type: FileImportConfigFieldDataTypeEnum.TEXT },
                defaultValue: dto.fieldConfig?.defaultValue ? mapFileImportConfigFieldDefaultValueDtoToDomain(dto.fieldConfig!.defaultValue) : undefined,
                formatter: dto.fieldConfig?.formatter || TextFormatter.NONE
            }
    }
}

const mapFileEntityAggregatedExportNodeDtoToDomain = (dto: XmlAggregatedEntityExportNodeDto): XmlAggregatedEntityExportNode => {
    switch (dto.type) {
        case XmlAggregatedEntityExportNodeEnumDto.PARENT:
            return {
                type: "parent",
                name: dto.parent?.name ?? "",
                children: dto.parent?.children.map(child => mapFileEntityAggregatedExportNodeDtoToDomain(child)) || []
            }
        case XmlAggregatedEntityExportNodeEnumDto.VALUE:
            return {
                name: dto.valueFieldConfig?.name ?? "",
                type: "value",
                value: dto.valueFieldConfig?.value ?? ""
            }
        case XmlAggregatedEntityExportNodeEnumDto.AGGREGATION:
            return {
                name: dto.aggregationFieldConfig?.name ?? "",
                type: "aggregation",
                aggregationType: dto.aggregationFieldConfig?.aggregationType ?? "SUM",
                dataFieldReference: dto.aggregationFieldConfig?.dataFieldReference ?? ""
            }
        case XmlAggregatedEntityExportNodeEnumDto.TIMESTAMP:
            return {
                name: dto.timestampFieldConfig?.name ?? "",
                type: "timestamp",
                dateFormat: dto.timestampFieldConfig?.dateFormat ?? ""
            }
    }
}

const mapCustomerFileExportConfigDtoToDomain = (dto: CommonFileExportConfigDto): CommonFileExportConfig => ({
    filename: dto.filename,
    delimiter: dto.delimiter === "\t" ? "\\t" : dto.delimiter,
    lineEnding: dto.lineEnding,
    hasHeaderRow: dto.hasHeaderRow,
    quoteFieldsWith: dto.quoteFieldsWith,
    filters: convertFromDtoToArray(dto.filters, [], []),
    dateFormat: dto.dateFormat,
    optionalFieldConfigs: dto.optionalFieldConfigs ? dto.optionalFieldConfigs.map(field => mapFileExportFieldConfigDtoToDomain(field)) : []
})

const mapFileExportFieldConfigDtoToDomain = (dto: ExportFieldConfigDto): ExportFieldConfig => ({
    column: mapFileImportConfigColumnTypeDtoToDomain(dto.column),
    dataFieldReference: dto.dataFieldReference,
    dataType: mapFileImportConfigFieldDataTypeDtoToDomain(dto.dataType),
    fieldType: dto.fieldType,
    defaultValue: dto.defaultValue ? mapFileImportConfigFieldDefaultValueDtoToDomain(dto.defaultValue) : undefined
})

const mapFileEntityExportFieldConfigDtoToDomain = (dto: CsvEntityExportFieldConfigDto, index: number): EntityExportFieldConfig => ({
    column: mapFileImportConfigColumnTypeDtoToDomain(dto.column),
    dataFieldReference: dto.dataFieldReference,
    dataType: mapFileImportConfigFieldDataTypeDtoToDomain(dto.dataType),
    defaultValue: dto.defaultValue ? mapFileImportConfigFieldDefaultValueDtoToDomain(dto.defaultValue) : undefined,
    ordinal: index,
    formatter: dto.formatter
})

export const mapDomainToPutFileExportConfigDto = (domain: FileExportConfig): PutFileExportConfigDto => {
    return {
        fileType: domain.fileType,
        fileFormat: domain.fileFormat,
        dataFileExportConfig: mapDomainToCustomerFileExportConfig(domain.dataFileExportConfig, domain.fileFormat)
    }
}
export const mapDomainToPutFileEntityExportConfigDto = (domain: FileEntityExportConfig): PutFileEntityExportConfigDto => {
    switch (domain.fileFormat) {
        case FileFormat.TEXT:
        case FileFormat.CSV:
            return {
                entityType: domain.entityType,
                fileFormat: domain.fileFormat,
                filename: domain.filename,
                delimiter: domain.delimiter.replace("\\t", "\t"),
                lineEnding: domain.lineEnding,
                hasHeaderRow: domain.hasHeaderRow,
                quoteFieldsWith: domain.quoteFieldsWith,
                filters: convertFromArrayToDto(domain.filters),
                dateFormat: domain.dateFormat,
                optionalFieldConfigs: domain.optionalFieldConfigs.map(field => mapDomainToEntityExportFieldConfig(field)),
                subdirectoryPath: domain.subdirectoryPath
            }
        case FileFormat.XML:
            return {
                entityType: domain.entityType,
                fileFormat: domain.fileFormat,
                filename: domain.filename,
                lineEnding: domain.lineEnding,
                quoteFieldsWith: domain.quoteFieldsWith,
                filters: convertFromArrayToDto(domain.filters),
                dateFormat: domain.dateFormat,
                rootNodeFieldsConfig: mapFileEntityExportNodeDomainToDto(domain.rootNodeFieldsConfig),
                headersNodeFieldsConfig: domain.headersNodeFieldsConfig.map(config => mapXmlAggregatedEntityExportNodeToDto(config)),
                footersNodeFieldsConfig: domain.footersNodeFieldsConfig.map(config => mapXmlAggregatedEntityExportNodeToDto(config)),
                subdirectoryPath: domain.subdirectoryPath
            }
    }
}

const mapFileEntityExportNodeDomainToDto = (domain: XmlEntityExportNode): XmlEntityExportNodeDto => {
    switch (domain.type) {
        case XmlEntityExportNodeEnum.PARENT:
            return {
                type: XmlEntityExportNodeEnumDto.PARENT,
                parent: {
                    name: domain.name,
                    children: domain.children.map(child => mapFileEntityExportNodeDomainToDto(child)),
                    attributes: new Map()
                }
            }
        case XmlEntityExportNodeEnum.FIELD_CONFIG:
            return {
                type: XmlEntityExportNodeEnumDto.FIELD_CONFIG,
                fieldConfig: {
                    name: domain.name,
                    dataFieldReference: domain.dataFieldReference,
                    dataType: domain.dataType,
                    defaultValue: domain.defaultValue,
                    attributes: new Map(),
                    formatter: domain.formatter === TextFormatter.NONE ? undefined : domain.formatter
                }
            }
    }
}

const mapXmlAggregatedEntityExportNodeToDto = (node: XmlAggregatedEntityExportNode): XmlAggregatedEntityExportNodeDto => {
    switch (node.type) {
        case "parent":
            return {
                type: XmlAggregatedEntityExportNodeEnumDto.PARENT,
                parent: {
                    name: node.name,
                    children: node.children.map(child => mapXmlAggregatedEntityExportNodeToDto(child)),
                    attributes: new Map()
                }
            }
        case "value":
            return {
                type: XmlAggregatedEntityExportNodeEnumDto.VALUE,
                valueFieldConfig: {
                    name: node.name,
                    value: node.value,
                    attributes: new Map()
                }
            }
        case "aggregation":
            return {
                type: XmlAggregatedEntityExportNodeEnumDto.AGGREGATION,
                aggregationFieldConfig: {
                    name: node.name,
                    attributes: new Map(),
                    aggregationType: node.aggregationType,
                    dataFieldReference: node.dataFieldReference
                }
            }
        case "timestamp":
            return {
                type: XmlAggregatedEntityExportNodeEnumDto.TIMESTAMP,
                timestampFieldConfig: {
                    name: node.name,
                    attributes: new Map(),
                    dateFormat: node.dateFormat
                }
            }
    }
}
const mapDomainToCustomerFileExportConfig = (domain: CommonFileExportConfig, fileFormat: String): CommonFileExportConfigDto => ({
    filename: domain.filename,
    delimiter: fileFormat === "XML" ? "," : domain.delimiter.replace("\\t", "\t"),
    lineEnding: fileFormat === "XML" ? "CR" : domain.lineEnding,
    hasHeaderRow: domain.hasHeaderRow,
    quoteFieldsWith: domain.quoteFieldsWith,
    filters: convertFromArrayToDto(domain.filters),
    dateFormat: domain.dateFormat,
    optionalFieldConfigs: domain.optionalFieldConfigs.map(field => mapDomainToExportFieldConfig(field))
})

const mapDomainToExportFieldConfig = (domain: ExportFieldConfig): ExportFieldConfigDto => ({
    column: mapDomainToFileImportConfigColumnType(domain.column),
    dataFieldReference: domain.dataFieldReference,
    dataType: domain.dataType,
    fieldType: domain.fieldType,
    defaultValue: domain.defaultValue
})

const mapDomainToEntityExportFieldConfig = (domain: EntityExportFieldConfig): CsvEntityExportFieldConfigDto => ({
    column: mapDomainToFileImportConfigColumnType(domain.column),
    dataFieldReference: domain.dataFieldReference,
    dataType: domain.dataType,
    ordinal: domain.ordinal,
    defaultValue: domain.defaultValue,
    formatter: domain.formatter === TextFormatter.NONE ? undefined : domain.formatter
})
export const mapDataFieldsDtoToDomain = (dtos: DataFieldDto[]) =>
    dtos.map(dto => ({
        value: dto.fieldName,
        label: dto.displayName,
        type: dto.dataPrimitive,
        fieldType: dto.fieldType
    }))

export const dataTypeEnumToDataPrimitive = (dte: DataTypeEnum): DataPrimitive => {
    switch (dte) {
        case DataTypeEnum.DATE:
            return DataPrimitive.DATE
        case DataTypeEnum.BOOLEAN:
            return DataPrimitive.BOOLEAN
        case DataTypeEnum.MONEY:
        case DataTypeEnum.INT:
        case DataTypeEnum.DECIMAL:
            return DataPrimitive.NUMBER
        case DataTypeEnum.TEXT:
        case DataTypeEnum.EMAIL:
        case DataTypeEnum.LOOKUP:
        case DataTypeEnum.PHONE_NUMBER:
        default:
            return DataPrimitive.TEXT
    }
}
