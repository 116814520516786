import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react"
import Entity from "../library/grid/types/Entity"
import Prettify from "../types/utils/Prettify"

type SelectedEntitiesContextValue = {
    selectedEntity?: Prettify<Omit<Entity, "client">>
    setSelectedEntity: Dispatch<SetStateAction<Prettify<Omit<Entity, "client">> | undefined>>
    selectedRefs: { [entityType: string]: string[] }
    setSelectedRefs: (entityType: string, childRefs: string[]) => void
}

const SelectedEntitiesContext = createContext<SelectedEntitiesContextValue | undefined>(undefined)

type ProviderProps = {
    initialEntity?: Prettify<Omit<Entity, "client">>
    initialSelectedRefs?: { [entityType: string]: string[] }
    children: ReactNode
}

const SelectedEntitiesProvider = ({ initialEntity, initialSelectedRefs = {}, children }: ProviderProps) => {
    const [selectedEntity, setSelectedEntity] = useState<Prettify<Omit<Entity, "client">> | undefined>(initialEntity)
    const [selectedRefs, setSelectedRefs] = useState<{ [entityType: string]: string[] }>(initialSelectedRefs)

    const setSelectedChildrenForEntityType = (entityType: string, childRefs: string[]) => 
        setSelectedRefs(r => ({ ...r, [entityType]: childRefs }))

    return (
        <SelectedEntitiesContext.Provider
            value={{
                selectedEntity,
                setSelectedEntity,
                selectedRefs,
                setSelectedRefs: setSelectedChildrenForEntityType
            }}
        >
            {children}
        </SelectedEntitiesContext.Provider>
    )
}

export const useSelectedEntities = () => {
    const context = useContext(SelectedEntitiesContext)
    if (context === undefined) {
        throw new Error("useSelectedEntity must be used within a SelectedEntityProvider")
    }
    return context
}

export default SelectedEntitiesProvider
