import React from 'react';
import { connect } from 'react-redux';
import { storeGridState } from '../../actions/gridCacheActions';

import { last, isNil } from 'lodash';

import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../common/grids/StatefulGrid';
import { CommandCell } from '../common/grids/commandCell';
import { getApiUrl } from '../../constants';
import { CustomColumnMenu } from '../common/grids/columnMenu';

class CustomerAddressesGrid extends React.Component {
  constructor(props, context) {
    super(props, context);

    const showAddress =
      props.globalConfiguration.addressGridConfigurationLines1to4;

    this.state = {
      refresh: 0,
      selectedItemId: '',
      columns: [
        { field: 'Type', title: 'Type', show: true },
        { field: 'AddressLine1', title: 'Address Line 1', show: true },
        { field: 'AddressLine2', title: 'Address Line 2', show: true },
        {
          field: 'AddressLine3',
          title: 'Address Line 3',
          show: showAddress ? true : false
        },
        {
          field: 'AddressLine4',
          title: 'Address Line 4',
          show: showAddress ? true : false
        },
        { field: 'AddressLine5', title: 'Address Line 5', show: false },
        { field: 'AddressLine6', title: 'Address Line 6', show: false },
        {
          field: 'AddressLine7',
          title: 'Address Line 7',
          show: showAddress ? false : true
        }
      ]
    };

    this.CommandCell = CommandCell({
      onCommandClick: this.onEditRowClicked,
      cssTag: 'Edit',
      buttonText: 'Edit'
    });
  }

  componentDidMount() {
    if (this.props.methodCallback != null) {
      this.props.methodCallback(this.refresh.bind(this));
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.selectedAddressId !== null &&
      props.selectedAddressId !== state.selectedItemId
    ) {
      return {
        selectedItemId: props.selectedAddressId
      };
    }
    return null;
  }

  refresh = () => {
    this.setState({ refresh: this.state.refresh + 1 });
  };

  onColumnsSubmit = (columnsState) => {
    this.setState({
      columns: columnsState
    });
  };

  onRowClick = (gridRowEvent) => {
    const dataItem = gridRowEvent.dataItem;

    if (dataItem && !dataItem.selected) {
      this.setState({ selectedItemId: dataItem.AddressId });
    } else if (gridRowEvent.nativeEvent.ctrlKey) {
      this.setState({ selectedItemId: '' });
    }
  };

  onEditRowClicked = (e, dataItem) => {
    e.preventDefault();
    if (!isNil(dataItem) && this.props.onEditRequest != null) {
      this.props.onEditRequest(dataItem.AddressId);
    }
  };

  isSelectedRow = (dataItem) =>
    dataItem.AddressId === this.state.selectedItemId;

  onStateChange = (data) => {
    this.props.onStoreGridState(CustomerAddressesGrid.getKey(), data);
  };

  additionalRequestPayload = () => {
    return {
      accountId: this.props.selectedAccount.miaAccountId,
      isVirtualAccount: this.props.selectedAccount.miaAccountIsVirtualAccount
    };
  };

  static getKey() {
    return 'grid-customer-addresses';
  }

  render() {
    const showAddressEdit = this.props.globalConfiguration.showAddressEdit;

    return (
      <StatefulGrid
        client={this.props.client}
        key={CustomerAddressesGrid.getKey()}
        id={CustomerAddressesGrid.getKey()}
        path={getApiUrl(this.props.client) + 'api/grid/addresses'}
        sortable
        pageable={{
          pageSizes: [50, 100, 200],
          buttonCount: 5
        }}
        onStateChange={this.onStateChange}
        onRowClick={this.onRowClick}
        additionalRequestPayload={this.additionalRequestPayload()}
        defaultField={{ field: 'AddressLine1', dir: 'desc' }}
        className={'scrollable-none custom-grid'}
        hideablePaging={true}
        isSelectedRow={this.isSelectedRow}
        refresh={this.state.refresh}
      >
        {this.state.columns.map(
          (column, idx) =>
            column.show && (
              <GridColumn
                key={idx}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                filterable={column.filterable}
                cell={column.cell}
                columnMenu={(props) => (
                  <CustomColumnMenu
                    {...props}
                    columns={this.state.columns}
                    onColumnsSubmit={this.onColumnsSubmit}
                  />
                )}
              />
            )
        )}
         {showAddressEdit && (
          <GridColumn cell={this.CommandCell} width={'100px'} />
        )}
      </StatefulGrid>
    );
  }
}

CustomerAddressesGrid.propTypes = {};

const mapStateToProps = (state) => {
  let configuration = {};

  if (state.globalConfiguration && state.globalConfiguration.length > 0) {
    configuration = last(state.globalConfiguration);
  }

  return {
    selectedAccount: state.currentSelectionReducer.selectedAccount,
    globalConfiguration: configuration
  };
};

export default connect(mapStateToProps, {
  onStoreGridState: storeGridState
})(CustomerAddressesGrid);
