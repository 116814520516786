import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import ContactsPanel from '../contacts'
import DocumentsPanel from '../documents/documentsPanel'
import HistoryPanel from '../history'
import TransactionsPanel from '../transactions'
import { selectedContactCount } from '../../selectors/function'
import { customerDetailsPane } from '../../constants'
import Nav from '../../api/navApi'
import { Loading, useClient, useInvevoSession } from 'invevo-react-components'
import AccountInfoPanel from '../accountInfo'
import {
  hasSelectedAccount,
  isLoadingNextAccount
} from '../../selectors/currentSelection'

const LegacyCustomerSummary = ({
  hasAccount,
  isLoadingNextAccount,
  showPane,
  navTo,
  leftPaneKey,
  transactionCount,
  documentCount,
  contactCount
}) => {
  const client = useClient()
  const [invevoSession, setInvevoSession] = useInvevoSession()
  const incomingPane =
    customerDetailsPane[showPane] !== undefined
      ? customerDetailsPane[showPane]
      : null

  const [selected, setSelected] = useState(incomingPane || 0)

  const handleSelect = (e) => {
    setInvevoSession({ selectedCustomerTab: customerDetailsPane[e.selected], showTaskFiles: false })
    setSelected(e.selected)
    navTo(leftPaneKey, customerDetailsPane[e.selected])
  }

  useEffect(() => {
    if (!invevoSession.selectedCustomerTab || customerDetailsPane[invevoSession.selectedCustomerTab] === selected) {
      return
    }

    if(invevoSession.selectedCustomerTab === "transactions") {
      setSelected(customerDetailsPane.transactions)
      return 
    }

    setSelected(invevoSession.selectedCustomerTab)
  }, [invevoSession.selectedCustomerTab, selected])

  useEffect(() => {
    if (incomingPane !== null && incomingPane !== selected) {
      setSelected(incomingPane)
    }
  }, [incomingPane, selected])

  const onSelectedCustomerContactsUpdated = (selectedCustomerContacts) => {
    setInvevoSession({ selectedCustomerContacts })
  }

  const onSelectedInternalContactsUpdated = (selectedInternalContacts) => {
    setInvevoSession({ selectedInternalContacts })
  }

  return (
    <Loading isLoading={isLoadingNextAccount} colour="blue">
      <TabStrip
        selected={selected}
        onSelect={handleSelect}
        // some components below remount via selected check
        keepTabsMounted={true}
        className={'material top-nav w-100'}
      >
        <TabStripTab
          title={
            <div className="tab-title d-flex align-items-center">
              <div className="notify">
                <i className="fa-solid fa-info-circle"></i>
              </div>
              <span className='ms-2'>Summary</span>
            </div>
          }
        >
          {hasAccount && selected === customerDetailsPane.summary && (
            <AccountInfoPanel />
          )}
        </TabStripTab>
        <TabStripTab
          title={
            <div className="tab-title d-flex align-items-center">
              <div className="notify">
                <i className="fa-solid fa-folder-open"></i>
                {documentCount > 0 && (
                  <span className="notify-count">{documentCount}</span>
                )}
              </div>

              <span className="ms-2">Files</span>
            </div>
          }
        >
          {hasAccount && selected === customerDetailsPane.files && (<DocumentsPanel selectTaskTab={invevoSession.showTaskFiles} />)}
        </TabStripTab>
        <TabStripTab
          title={
            <div className="tab-title d-flex align-items-center">
              <div className="notify">
                <i className="fa-solid fa-users"></i>
                {contactCount > 0 && (
                  <span className="ms-2 notify-count">({contactCount})</span>
                )}
              </div>

              <span className="ms-2">Contacts</span>
            </div>
          }
        >
          {hasAccount && selected === customerDetailsPane.contacts && (
            <ContactsPanel client={client} onSelectedCustomerContactsUpdated={onSelectedCustomerContactsUpdated} onSelectedInternalContactsUpdated={onSelectedInternalContactsUpdated} />
          )}
        </TabStripTab>
        <TabStripTab
          title={
            <div className="tab-title d-flex align-items-center">
              <div className="notify">
                <i className="fa-solid fa-credit-card"></i>

                {transactionCount > 0 && (
                  <span className="ms-2 notify-count">({transactionCount})</span>
                )}
              </div>

              <span className='ms-2'>Transaction</span>
            </div>
          }
        >
          {hasAccount && selected === customerDetailsPane.transactions && (
            <TransactionsPanel client={client} />
          )}
        </TabStripTab>
        <TabStripTab
          title={
            <div className="tab-title d-flex align-items-center">
              <div className="notify">
                <i className="fa-solid fa-history"></i>
              </div>

              <span className="ms-2">History</span>
            </div>
          }
        >
          {hasAccount && selected === customerDetailsPane.history && (
            <HistoryPanel />
          )}
        </TabStripTab>
      </TabStrip>
    </Loading>
  )
}

const mapStateToProps = (state) => {
  return {
    hasAccount: hasSelectedAccount(
      state.currentSelectionReducer.selectedAccount
    ),
    isLoadingNextAccount: isLoadingNextAccount(
      state.currentSelectionReducer.selectedAccount
    ),
    selectedAccount: state.currentSelectionReducer.selectedAccount,
    transactionCount:
      (state.functionReducer.transactionIds &&
        state.functionReducer.transactionIds.length) ||
      0,
    selectedTransactions: state.functionReducer.transactionIds || [],
    documentCount:
      (state.functionReducer.documents &&
        state.functionReducer.documents.length) ||
      0,
    contactCount: selectedContactCount(state.functionReducer)
  }
}

export default connect(mapStateToProps, {
  navTo: (left, right) => Nav.Workspace.RightPane(left, right)()
})(LegacyCustomerSummary)
