import React from 'react'
import { IconButton, SelectableCard } from 'invevo-react-components'

const WorkflowCard = ({
    onWorkflowSelected,
    workflowRule,
    isSelected,
    isNew,
    onNewWorkflowRuleClose
}) => {

    const body = (
        <div className="d-flex align-items-center">
            <i className="fal fa-sitemap fa-2x text-white"></i>
            <h5 className="ms-3 mb-0">{workflowRule.name}</h5>
            {isNew && isSelected && <IconButton className="ms-auto" iconClasses="fal fa-trash" size="small" onClick={onNewWorkflowRuleClose} />}
        </div>
    )

    const footer = (
        <div className="d-flex">
            <div className="d-flex align-items-center">
                {workflowRule.outputs.includes("scorecard") &&
                    <i className={`fal fa-bullseye-arrow text-blue me-4`}></i>
                }
                {workflowRule.outputs.includes("alpha_rating") &&
                    <i className={`fal fa-sort-alpha-down text-blue me-4 `}></i>
                }
                {workflowRule.outputs.includes("create_task") &&
                    <i className={`fal fa-list-ul text-blue`}></i>
                }
            </div>

            <div className="d-flex align-items-center ms-auto">
                <i className={`fal fa-calendar-alt text-white me-2`}></i>
                <span>{workflowRule.creationDate}</span>
            </div>
        </div>
    )

    return (
        <SelectableCard
            className="w-100 my-2"
            body={body}
            footer={footer}
            isSelected={isSelected}
            onClick={() => onWorkflowSelected(workflowRule)} />
    )
}

export default WorkflowCard
