import { IconButton } from 'invevo-react-components';
import { useEffect, useState } from 'react';
import classes from './SmsSenderIds.module.scss'

const SmsSenderId = ({
    smsSenderId,
    index,
    iconClass,
    onIconClicked,
    setShowFloatInContainer,
    setSelectedSenderId
}) => {

    const [senderIdText, setSenderIdText] = useState(smsSenderId.senderId)

    useEffect(() => {
        setSenderIdText(smsSenderId.senderId)
    }, [smsSenderId])

    const editClicked = () => {
        setSelectedSenderId(smsSenderId)
        setShowFloatInContainer(true)
    }

    return (
        <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
                <div className="d-flex flex-column flex-grow-1 me-2">
                    <span className={`${classes.label} ps-3 py-1`}>{senderIdText}</span>
                </div>

                <IconButton
                    className="ms-3"
                    size="small"
                    colour="blue"
                    iconClasses={iconClass}
                    onClick={() => onIconClicked(index)} />

                <IconButton
                    className="ms-3"
                    size="small"
                    colour="blue"
                    iconClasses="fal fa-pen"
                    onClick={editClicked} />
            </div>
        </div>
    )
}

export default SmsSenderId;