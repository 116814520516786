import DataCard from "./DataCard"
import { useGrid } from "./../Grid"
import HeaderDefinition from "./../types/HeaderDefinition"
import { formatFieldValueForHeader, getValueForHeader, mergeOptimisticUpdatesIntoEntities } from "./../util"
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd"
import classes from "./Kanban.module.scss"
import { DataTypeEnum } from "../../../routes/entityConfig/types/DataType"
import RowData from "./../types/RowData"
import IconButton from "../../buttons/IconButton/IconButton"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import EntityConfig from "../../../routes/entityConfig/types/EntityConfig"

const NO_GROUP_ID = "_no-group"

const sortingField = {
    value: "number_ordinal",
    label: "Ordinal",
    ordinal: 0,
    defaultOrdinal: 0,
    displayType: DataTypeEnum.INT,
    isEditable: false
}

type Props = {
    groupingField: HeaderDefinition
    entityConfig?: EntityConfig
    onNavigateToClicked?: (data: RowData) => void
    onNavigateToGroupClicked?: (group: string, firstInGroup: RowData) => void
}

const Kanban = ({ groupingField, entityConfig, onNavigateToClicked, onNavigateToGroupClicked }: Props) => {
    const [shouldShowNoGroup, setShouldShowNoGroup] = useState(false)
    const grid = useGrid()

    useEffect(() => {
        grid.onSelect({ selectedRefs: [], isAllRowsSelected: false })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const sortedHeaders = grid.displayedHeaders.sort((a, b) => a.ordinal - b.ordinal)

    const onDragEnd = (result: DropResult) => {
        const destinationField = result.destination?.droppableId
        const destinationIndex = result.destination?.index
        if (
            destinationIndex === undefined ||
            destinationField === undefined ||
            (result.source.droppableId === destinationField && result.source.index === destinationIndex) ||
            destinationField === NO_GROUP_ID
        )
            return

        const groupingToUpdate = dataEntitiesGrouped[destinationField]
        if (groupingToUpdate === undefined) return

        const otherRowsInGroupUpdates = groupingToUpdate
            .filter(row => row.reference !== result.draggableId)
            .map((row, index) => ({
                rowReference: row.reference,
                header: sortingField,
                newValue: (destinationIndex <= index ? index + 1 : index).toString()
            }))

        const indexUpdate = {
            rowReference: result.draggableId,
            header: sortingField,
            newValue: destinationIndex.toString()
        }

        if (result.source.droppableId === destinationField) {
            grid.onRowsUpdated([...otherRowsInGroupUpdates, indexUpdate])
            return
        }

        if (result.source.droppableId === NO_GROUP_ID) {
            setShouldShowNoGroup(true)
        }

        grid.onRowsUpdated([
            ...otherRowsInGroupUpdates,
            indexUpdate,
            {
                rowReference: result.draggableId,
                header: groupingField,
                newValue: destinationField
            }
        ])
    }

    const getValue = (data: RowData, header: HeaderDefinition) => {
        const optimisticData = grid.data.optimisticallyUpdatedRows.find(row => row.reference === data.reference)

        if (optimisticData) {
            const optimisticValue = getValueForHeader(optimisticData, header)?.toString()
            if (optimisticValue !== undefined) {
                return optimisticValue
            }
        }

        return getValueForHeader(data, groupingField)?.toString()
    }

    const dataEntities = mergeOptimisticUpdatesIntoEntities(grid.data.rows, grid.data.optimisticallyUpdatedRows)

    const groups = (() => {
        if (groupingField.displayType === DataTypeEnum.BOOLEAN) return ["true", "false"]
        if (groupingField.displayType === DataTypeEnum.LOOKUP) {
            return grid.lookups.find(lookup => lookup.reference === groupingField.lookupReference)?.entries?.map(entry => entry.reference) ?? []
        }

        return grid.data.rows
            .reduce((acc, row) => {
                const field = getValueForHeader(row, groupingField)?.toString() ?? NO_GROUP_ID

                if (acc.includes(field)) return acc

                acc.push(field)
                return acc
            }, Array<string>())
            .sort()
    })().map(group => group.toLowerCase())

    const emptyGrouping: Record<string, RowData[]> = [NO_GROUP_ID, ...groups].reduce<Record<string, RowData[]>>((acc, group) => {
        acc[group] = []
        return acc
    }, {})

    const dataEntitiesGrouped = dataEntities.reduce((acc, row) => {
        const field = getValue(row, groupingField)?.toLowerCase() ?? NO_GROUP_ID

        if (acc[field] === undefined) {
            acc[field] = []
        }

        acc[field]?.push(row)

        return acc
    }, emptyGrouping)

    const dataEntitiesGroupedAndSorted = Object.keys(dataEntitiesGrouped).reduce<Record<string, RowData[]>>((acc, group) => {
        acc[group] = dataEntitiesGrouped[group]?.sort(byOrdinal) ?? []
        return acc
    }, {})

    const groupsToShow = Object.keys(dataEntitiesGroupedAndSorted).filter(
        g => g !== NO_GROUP_ID || shouldShowNoGroup || (dataEntitiesGroupedAndSorted[NO_GROUP_ID]?.length ?? 0) > 0
    )

    return (
        <div className="d-flex align-content-start gap-3 p-3 w-100">
            <DragDropContext onDragEnd={onDragEnd}>
                {groupsToShow.map(group => (
                    <Droppable key={group} droppableId={group}>
                        {provided => {
                            const firstInGroup = (dataEntitiesGroupedAndSorted[group] ?? [])[0]
                            const onListButtonClicked = () => onNavigateToGroupClicked && firstInGroup && onNavigateToGroupClicked(group, firstInGroup)
                            return (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className={`d-flex flex-column flex-grow-1 ${classes.droppable} px-3 pb-3 rounded bg-grey`}
                                    aria-label={`group-${group}`}
                                >
                                    <div className="d-flex justify-content-between align-items-center text-grey my-3">
                                        <span className="fs-3 text-capitalize fw-bold">
                                            {group === NO_GROUP_ID ? "No Group" : formatFieldValueForHeader(group, groupingField, grid.lookups)}
                                        </span>
                                        {onNavigateToGroupClicked && (
                                            <IconButton
                                                icon="fa fa-list"
                                                theme="blue-flat"
                                                onClick={onListButtonClicked}
                                                animatedHover={false}
                                                ariaLabel={`group-to-list-${group}`}
                                            />
                                        )}
                                    </div>
                                    <div className="d-flex flex-column gap-2">
                                        {(dataEntitiesGroupedAndSorted[group] ?? []).map((rowData, index) => (
                                            <Draggable key={rowData.reference} draggableId={rowData.reference} index={index}>
                                                {(provided, snapshot) => {
                                                    const onNavToClicked = onNavigateToClicked === undefined ? undefined : () => onNavigateToClicked(rowData)
                                                    const draggableCard = (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={`rounded ${snapshot.isDragging ? classes.dragging : ""}`}
                                                        >
                                                            <DataCard
                                                                cardData={rowData}
                                                                optimisticData={grid.data.optimisticallyUpdatedRows.find(
                                                                    row => row.reference === rowData.reference
                                                                )}
                                                                isSelectable={false}
                                                                onNavigateToClicked={onNavToClicked}
                                                                headers={sortedHeaders}
                                                                colour="grey"
                                                                toggleSelectSingle={() => {}}
                                                                entityConfig={entityConfig}
                                                            />
                                                        </div>
                                                    )

                                                    const draggablePositionIsFixed =
                                                        provided.draggableProps.style !== undefined &&
                                                        "position" in provided.draggableProps.style &&
                                                        provided.draggableProps.style.position === "fixed"

                                                    const root = document.getElementById("root")

                                                    return draggablePositionIsFixed && root ? createPortal(draggableCard, root) : draggableCard
                                                }}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )
                        }}
                    </Droppable>
                ))}
            </DragDropContext>
        </div>
    )
}

export default Kanban

const byOrdinal = (rowA: RowData, rowB: RowData) => {
    const aOrdinal = rowA.fields.numberFields[sortingField.value]
    const bOrdinal = rowB.fields.numberFields[sortingField.value]

    if (aOrdinal === undefined) return -1
    if (bOrdinal === undefined) return 1

    return aOrdinal - bOrdinal
}
