import { StandardButton, Tabs, Tab } from "invevo-react-components"
import WorkflowRunOutcomes from "./outcomes/WorkflowRunOutcomes"
import WorkflowEvaluationDashboard from "./evaluations/WorkflowEvaluationDashboard"

const WorkflowRunDetails = ({ selectedWorkflowRun, actionSuccess, dispatch }) => {
    return (
        <div className="d-flex flex-column px-4">
            <StandardButton
                iconClasses="far fa-chevron-double-left"
                label="Back to all workflow runs"
                className="pb-3 w-25"
                onClick={() => dispatch(null)}
            />
            <Tabs className="flex-grow-1">
                <Tab title="Evaluations" isActive={true}>
                    <WorkflowEvaluationDashboard
                        selectedWorkflowRun={selectedWorkflowRun}
                        dispatch={() => dispatch({ type: 'WORKFLOW_RUN_SELECTED', selectedWorkflowRun: null })}
                    />
                </Tab>
                <Tab title="Outcomes">
                    <WorkflowRunOutcomes
                        workflowRun={selectedWorkflowRun}
                        actionSuccess={actionSuccess}
                    />
                </Tab>
            </Tabs>
        </div>
    )
}
export default WorkflowRunDetails