import update from 'immutability-helper';

const init = {
  accountStatusList: [],
  accountStatusData: {
    customerId: null,
    statusId: null,
    isActive: false,
    AOS: false,
    NOS: false,
    isLoading: false,
    msg: ''
  }
};
const accountStatusReducer = (state = init, action) => {
  switch (action.type) {
    case 'RECEIVE_ACCOUNT_STATUS_LIST':
      return update(state, {
        accountStatusList: { $set: action.json }
      });
    case 'RECEIVE_ACCOUNT_STATUS_DATA':
      const json = action.json;
      return update(state, {
        accountStatusData: {
          customerId: { $set: json.CustomerId },
          statusId: { $set: json.CustomerStatusId },
          isActive: { $set: json.IsActive },
          AOS: { $set: json.AlwaysOnStop },
          NOS: { $set: json.NeverOnStop },
          isLoading: { $set: false },
          msg: { $set: '' }
        }
      });
    case 'REQUEST_SET_ACCOUNT_STATUS':
      return update(state, {
        accountStatusData: {
          isLoading: { $set: true },
          msg: { $set: '' }
        }
      });
    case 'RECEIVE_SET_ACCOUNT_STATUS':
      return update(state, {
        accountStatusData: {
          isLoading: { $set: false },
          msg: { $set: action.json }
        }
      });
    case 'ERROR_SET_ACCOUNT_STATUS':
      return update(state, {
        accountStatusData: {
          isLoading: { $set: false },
          msg: { $set: action.err }
        }
      });
    default:
      return state;
  }
};
export default accountStatusReducer;
