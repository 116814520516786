import { useState } from "react"
import { connect } from "react-redux"
import { selectReplyToEmail } from "../../actions/functionActions"
import { getApiUrl } from "../../constants"
import { webApiInterface } from "../../api/webApiInterface"
import { executeAuthAsyncGet } from "../../utility/asyncSupport"
import NewEmailNotesHistoryDisplay from "./NewEmailNotesHistoryDisplay"
import { useEntity } from "../../../../contexts/EntityContext"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"
import useApiQuery from "../../../../hooks/useApiQuery"
import Loading from "../../../../library/Loading/Loading"
import useClient from "../../../../hooks/useClient"
import { useEnvConfig } from "../../../../contexts/EnvironmentConfigContext"
import EntityAndLegacyNotesHistory from "../../../../routes/entitySearch/components/notes/EntityAndLegacyNotesHistory"

const NewNotesHistoryGrid = ({ selectedAccount, allTransactionsSelected, selectReplyToEmail, navToEmailReply, executeAuthAsyncGet }) => {
    const [emailToView, setEmailToView] = useState(null)
    const [entity] = useEntity()
    const { isEnabled: newEntityStructure } = useFeatureToggle("newEntityStructure")

    const client = useClient()
    const config = useEnvConfig()

    const onReplyToEmailClick = email => {
        selectReplyToEmail(email)
        navToEmailReply(email)
    }

    const onViewEmailClick = email => {
        setEmailToView(email)
    }

    const onCloseEmailClick = () => {
        setEmailToView(null)
    }

    const onExportClick = () => {
        const xhr = new XMLHttpRequest()
        const url = `${getApiUrl(client)}api/customers/${newEntityStructure ? entity.sqlId : selectedAccount.miaAccountId}/notes/export`
        const params = {
            sort: "NoteDate-desc",
            columns: [{ Name: "NoteDate" }, { Name: "Note" }, { Name: "NoteType" }, { Name: "UserName" }, { Name: "TaskType" }, { Name: "TaskCompletion" }]
        }

        xhr.open("POST", url, true)
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.responseType = "blob"
        xhr.onload = function () {
            const a = document.createElement("a")
            a.href = URL.createObjectURL(xhr.response)
            a.download = "notesHistory.csv"
            a.click()
        }

        executeAuthAsyncGet(client, "Lookup/OK", "CHECK_OK", null, function () {
            webApiInterface.applySecurity(xhr)
            xhr.send(JSON.stringify(params))
        })
    }

    const getEntityConfigs = useApiQuery({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-config`,
        method: "GET"
    })

    const getEntityRelationships = useApiQuery({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-relationships`,
        method: "GET"
    })

    const getLookups = useApiQuery({
        url: `${config.DATA_API_URL}/api/${client}/lookups`,
        method: "GET"
    })

    return (
        <div className="d-flex w-100 h-100 pt-3">
            {emailToView ? (
                <NewEmailNotesHistoryDisplay
                    client={client}
                    emailTrackingId={emailToView.emailTrackingId}
                    emailDate={emailToView.emailDate}
                    customerId={emailToView.customerId}
                    noteId={emailToView.noteId}
                    initialViewType={emailToView.initialViewType}
                    onClose={onCloseEmailClick}
                    canReply={emailToView.canReply}
                    navToEmailReply={navToEmailReply}
                />
            ) : (
                <Loading
                    colour="white"
                    isLoading={
                        getEntityConfigs.isFetching ||
                        getEntityConfigs.data === undefined ||
                        getEntityRelationships.isFetching ||
                        getEntityRelationships.data === undefined ||
                        getLookups.isFetching ||
                        getLookups.data === undefined
                    }
                >
                    <EntityAndLegacyNotesHistory
                        selectedAccount={selectedAccount}
                        entityConfigs={getEntityConfigs.data ?? []}
                        entityRelationships={getEntityRelationships.data ?? []}
                        lookups={getLookups.data ?? []}
                        onExportClick={onExportClick}
                        onReplyToEmailClick={onReplyToEmailClick}
                        onViewEmailClick={onViewEmailClick}
                    />
                </Loading>
            )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        selectedAccount: state.currentSelectionReducer.selectedAccount
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectReplyToEmail: replyToEmail => dispatch(selectReplyToEmail(replyToEmail)),
        executeAuthAsyncGet: (client, serviceName, name, data, onSuccess, onError, signal) => {
            dispatch(executeAuthAsyncGet(client, serviceName, name, data, onSuccess, onError, signal))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewNotesHistoryGrid)
