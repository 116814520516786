import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from "react"
import { useEnvConfig } from "../../../../../contexts/EnvironmentConfigContext"
import useApiQuery from "../../../../../hooks/useApiQuery"
import useClient from "../../../../../hooks/useClient"
import FiltersDto, { convertFromArrayToDto } from "../../../../../library/FilterList/FiltersDto"
import { AggregationDto } from "../../../types/DashboardConfigDto"
import EntityAreaChart from "../../../../../library/charts/EntityAreaChart"
import EntityLineChart from "../../../../../library/charts/EntityLineChart"
import EntitySmallBarChart from "../../../../../library/charts/EntitySmallBarChart"
import EntitySmallPieChart from "../../../../../library/charts/EntitySmallPieChart"
import EntityDataField from "../../../../../routes/entityConfig/types/EntityDataField"
import GenericFilter from "../../../../../library/FilterList/filterTypes/GenericFilter"
import Lookup from "../../../../../types/Lookup"
import EntityConfig from "../../../../../routes/entityConfig/types/EntityConfig"
import { DataTypeEnum } from "../../../../../routes/entityConfig/types/DataType"
import { EntityChartConfigWidgetState, EntityChartWidgetSingleAggregationState } from "../../../types/DashboardConfigState"
import { convertStateToAggregationDto } from "../../../helpers/dashboardMapping"
import FilterTypesByField from "../../../../../library/FilterList/FilterTypesByField"
import { v4 as uuidv4 } from "uuid"
import { formatFieldValueForDataType } from "../../../../../library/grid/util"
import { useFeatureToggle } from "../../../../../hooks/useFeatureToggle"
import { useOverlay } from "../../../../../contexts/overlay/OverlayContext"
import EntityWidgetFilters from "./EntityWidgetFilter"
import { toSnakeCase } from "../../../../../library/helpers/stringUtils"
import { FilterGroupWithId } from "../../../../../routes/entitySearch/components/EntitySearchModularDashboard"
import { useLocalStorage } from "../../../../../hooks/useLocalStorage"
import { useEvent } from "../../../../../contexts/EventContext"
import { exists } from "../../../../../library/helpers/tsUtils"

type EntityChartDisplayWidgetProps = {
    chartConfig: EntityChartConfigWidgetState
    advancedFilters: GenericFilter[]
    dashboardFilterGroups?: FilterGroupWithId[]
    dashboardAppliedRoles?: string[]
    lookups: Lookup[]
    entityConfigs: EntityConfig[]
    shouldLoadData?: boolean
    dashboardReference: string
    isCachingEnabled: boolean
    setDashboardFilterGroups: Dispatch<SetStateAction<FilterGroupWithId[]>>
    setAdvancedFilters: (filters: GenericFilter[]) => void
}

type EntityWidgetAggregationsDto = {
    entityTypeReference: string
    aggregation: AggregationDto
    type: "SUM" | "AVERAGE" | "MIN" | "MAX" | "COUNT" | "CARDINALITY"
    filtersDto?: FiltersDto
    matchSomeFiltersDto?: FiltersDto[]
}

export type EntityChartDisplayWidgetData =
    | {
          type: "SINGLE"
          xLabels: string[]
          data: number[]
          displayField: EntityDataField | undefined
      }
    | {
          type: "MULTI"
          xLabels: string[]
          data: number[]
          displayFields: (EntityDataField | undefined)[]
      }

const EntityChartDisplayWidget = ({
    chartConfig,
    advancedFilters,
    dashboardFilterGroups,
    dashboardAppliedRoles,
    lookups,
    entityConfigs,
    shouldLoadData = true,
    dashboardReference,
    isCachingEnabled = false,
    setDashboardFilterGroups,
    setAdvancedFilters
}: EntityChartDisplayWidgetProps) => {
    const config = useEnvConfig()
    const client = useClient()
    const overlay = useOverlay()
    const { isEnabled: hideCurrency } = useFeatureToggle("removeCurrencySymbols")
    const { isEnabled: useDashboardAppliedRoles } = useFeatureToggle("dashboardAppliedRoles")
    const { subscribe, unsubscribe } = useEvent()

    const fields = useMemo(
        () => entityConfigs.find(entityConfig => entityConfig.reference === chartConfig.entityConfigReference)?.fields ?? [],
        [chartConfig.entityConfigReference, entityConfigs]
    )

    const [widgetFilters, setWidgetFilters, resetWidgetFilters, widgetFiltersLoaded] = useLocalStorage<GenericFilter[]>(
        `${client}/entitySearch/${dashboardReference}/${chartConfig.displayName}-${chartConfig.entityConfigReference}-${chartConfig.chartType}-${chartConfig.aggregationType}/filters`,
        [],
        isCachingEnabled
    )

    useEffect(() => {
        const callback = (_: string) => resetWidgetFilters()
        subscribe("clear_dashboard_cache", { key: dashboardReference, callback })

        return () => {
            unsubscribe("clear_dashboard_cache", dashboardReference)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const dashboardFilterGroupsWithoutId = dashboardFilterGroups?.map(group => group.filterGroup)
    const dashboardFiltersGroupsDto = filterGroupsToDto(dashboardFilterGroupsWithoutId ?? [])

    const getLookupFromDisplayName = useCallback(
        (lookupRef: string, value: string) => {
            const lookup = lookups.find(l => l.reference === lookupRef)
            return lookup?.entries.find(e => e.name.toLowerCase() === value.toLowerCase())?.reference
        },
        [lookups]
    )

    const aggregationsQuery = useApiQuery<{ results: { [myKey: string]: number } }>({
        url: `${config.SEARCH_API_URL}/api/${client}/entity-widget-aggregations`,
        method: "POST",
        dto: {
            entityTypeReference: chartConfig.entityConfigReference,
            aggregation: convertStateToAggregationDto(chartConfig.aggregation, chartConfig.aggregationType),
            type: chartConfig.aggregationType,
            filtersDto: convertFromArrayToDto([...advancedFilters, ...chartConfig.filters, ...widgetFilters]),
            matchSomeFiltersDto: dashboardFiltersGroupsDto,
            ...(useDashboardAppliedRoles ? { dashboardAppliedRolesNames: dashboardAppliedRoles } : {})
        } as EntityWidgetAggregationsDto,
        isExecutedAutomatically: shouldLoadData && widgetFiltersLoaded
    })

    const defaultState: EntityChartDisplayWidgetData = { type: "MULTI", xLabels: [], data: [], displayFields: [] }
    const state: EntityChartDisplayWidgetData = useMemo<EntityChartDisplayWidgetData>(() => {
        const aggregations = aggregationsQuery.data
        if (!exists(aggregations)) return defaultState
        const aggregation = chartConfig.aggregation
        const getDisplayName = (field: EntityDataField, value: string): string => formatFieldValueForDataType(value, field.dataType, lookups, hideCurrency)
        const newState =
            aggregation.type === "SINGLE"
                ? getNewStateForSingleAggregation(fields, aggregation, aggregations, getDisplayName)
                : getNewStateForMultiAggregation(fields, aggregations)
        if (exists(newState)) return newState
        return defaultState
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aggregationsQuery.data, chartConfig.aggregation, fields, lookups, hideCurrency])

    const addGroupingToDashboardFilterGroups = (filterByGroupingValue: GenericFilter) =>
        setDashboardFilterGroups(
            dashboardFilterGroups
                ? dashboardFilterGroups.length > 0
                    ? dashboardFilterGroups.map(group => ({ ...group, filterGroup: [...group.filterGroup, filterByGroupingValue] }))
                    : [{ id: uuidv4(), filterGroup: [filterByGroupingValue] }]
                : []
        )

    const onChartClick = useCallback(
        (value: string) => {
            const aggregation = chartConfig.aggregation
            if (aggregation.type === "MULTI") return
            const label = state.xLabels.find(label => label.toUpperCase() === value.toUpperCase())
            if (!label) return
            const field = fields.find(f => f.fieldName === aggregation.groupingField)
            if (!field) return
            if (field.dataType.type === DataTypeEnum.LOOKUP) {
                if (field.dataType.lookupReference === undefined) return
                addGroupingToDashboardFilterGroups({
                    type: FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF,
                    id: uuidv4(),
                    fieldName: aggregation.groupingField,
                    values: [getLookupFromDisplayName(field.dataType.lookupReference, label) ?? label],
                    notOneOf: false
                })
            } else {
                addGroupingToDashboardFilterGroups({
                    type: FilterTypesByField.TEXT_FIELD_IS_ONE_OF,
                    id: uuidv4(),
                    fieldName: aggregation.groupingField,
                    values: [label],
                    notOneOf: false
                })
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            advancedFilters,
            dashboardFilterGroups,
            chartConfig.aggregation,
            fields,
            getLookupFromDisplayName,
            setDashboardFilterGroups,
            setAdvancedFilters,
            state.xLabels
        ]
    )

    const currentEntityConfig = entityConfigs.find(entityConfig => entityConfig.reference === chartConfig.entityConfigReference)

    const onFiltersUpdated = (filters: GenericFilter[]) => {
        setWidgetFilters(filters)
        overlay.closeOverlay()
    }
    const resetFilters = () => {
        resetWidgetFilters()
        overlay.closeOverlay()
    }
    const filtersModal = (
        <EntityWidgetFilters
            chartTitle={chartConfig.displayName}
            filters={widgetFilters}
            entityConfig={currentEntityConfig}
            lookups={lookups}
            onFiltersUpdated={onFiltersUpdated}
            onResetFilters={resetFilters}
        />
    )

    const showFilterModal = () => overlay.showOverlay(filtersModal, "medium")

    const chartColour = chartConfig.theme === "DEFAULT" ? "white" : "blue"

    const headerButtons = (
        <EntityChartHeaderButton
            colour={chartColour === "white" || chartConfig.chartType === "BAR" ? "blue" : "white"}
            filterCount={widgetFilters.length}
            onFilterClicked={showFilterModal}
            chartRef={toSnakeCase(chartConfig.displayName)}
        />
    )

    const getWidgetByChartType = () => {
        switch (chartConfig.chartType) {
            case "AREA":
                return (
                    <EntityAreaChart
                        className="w-100 h-100"
                        title={chartConfig.displayName}
                        colour={chartColour}
                        state={state}
                        headerButtons={headerButtons}
                        onClick={onChartClick}
                    />
                )
            case "PIE" || "DONUT":
                return (
                    <EntitySmallPieChart
                        className="w-100 h-100"
                        title={chartConfig.displayName}
                        colour={chartColour}
                        state={state}
                        headerButtons={headerButtons}
                        onClick={onChartClick}
                    />
                )
            case "BAR":
                return (
                    <EntitySmallBarChart
                        className="w-100 h-100"
                        title={chartConfig.displayName}
                        colour={chartColour}
                        state={state}
                        headerButtons={headerButtons}
                        onClick={onChartClick}
                    />
                )
            case "LINE":
                return (
                    <EntityLineChart
                        className="w-100 h-100"
                        title={chartConfig.displayName}
                        colour={chartColour}
                        state={state}
                        headerButtons={headerButtons}
                        onClick={onChartClick}
                    />
                )
        }
    }
    return <div className="w-100 h-100">{getWidgetByChartType()}</div>
}
export default EntityChartDisplayWidget

type EntityChartHeaderProps = {
    colour: "white" | "blue"
    filterCount: number
    onFilterClicked?: () => void
    chartRef: string
}
const EntityChartHeaderButton = ({ colour, filterCount, onFilterClicked = () => {}, chartRef }: EntityChartHeaderProps) => {
    return (
        <div className="d-flex flex-row gap-2">
            <div role="button" className="d-flex flex-row align-items-center" onClick={onFilterClicked} aria-label={`${chartRef}-filters`}>
                <i className={`fa-regular fa-filter text-${colour}`}></i>
                {filterCount !== 0 && <span className={`badge rounded-circle text-${colour === "white" ? "blue" : "white"} bg-${colour}`}>{filterCount}</span>}
            </div>
        </div>
    )
}

const filterGroupsToDto = (filterGroups: GenericFilter[][]): FiltersDto[] => {
    return filterGroups.map(filterGroup => convertFromArrayToDto(filterGroup))
}
const getNewStateForSingleAggregation = (
    fields: EntityDataField[],
    aggregation: EntityChartWidgetSingleAggregationState,
    aggregations: { results: { [myKey: string]: number } },
    getDisplayName: (groupingField: EntityDataField, value: string) => string
) => {
    const groupingField = fields.find(f => f.fieldName === aggregation.groupingField)
    if (!groupingField) return
    const xLabels = Object.keys(aggregations.results).map(value => getDisplayName(groupingField, value))
    const aggregationField = fields.find(f => f.fieldName === aggregation.aggregationField)
    const state: EntityChartDisplayWidgetData = {
        type: "SINGLE",
        xLabels: xLabels,
        data: Object.values(aggregations.results),
        displayField: aggregationField
    }
    return state
}
const getNewStateForMultiAggregation = (fields: EntityDataField[], aggregations: { results: { [myKey: string]: number } }) => {
    const xLabels = Object.keys(aggregations.results).map(r => fields.find(f => f.fieldName === r)?.displayName ?? "")
    const displayFields = Object.keys(aggregations.results).map(r => fields.find(f => f.fieldName === r))
    const state: EntityChartDisplayWidgetData = {
        type: "MULTI",
        xLabels: xLabels,
        data: Object.values(aggregations.results),
        displayFields: displayFields
    }
    return state
}
