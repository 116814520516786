import DataField from "../../../types/DataField"
import { CommonFileExportConfig, ExportFieldConfig, FileExportConfig } from "../types/FileExportConfig"
import { v4 as uuidv4 } from "uuid"
import { FileType } from "../types/FileImportConfig"
import { FileImportConfigFieldDataTypeEnum } from "../types/FileImportConfigFieldDataType"
import { FileImportConfigColumnTypeEnum } from "../types/FileImportConfigColumnType"
import FieldType from "../../../types/enums/FieldType"
import { FileFormat } from "../components/utils"

export type FileExportConfigDashboardState = {
    configs: FileExportConfigState[]
    dataFields: DataField[]
    editingConfig?: FileExportConfigState
}
export type CommonFileExportConfigState = CommonFileExportConfig & {
    optionalFieldConfigs: ExportFieldConfigState[]
}

export type FileExportConfigState = FileExportConfig & {
    dataFileExportConfig: CommonFileExportConfigState
}

export type ExportFieldConfigState = ExportFieldConfig & {
    key: string
}

export enum FileExportConfigActionEnum {
    FILE_EXPORT_CONFIGS_RETRIEVED,
    FILE_EXPORT_EDITING_CONFIG_UPDATED,
    FILE_EXPORT_EDITING_CONFIG_DISCARDED,
    FILE_EXPORT_EDITING_CONFIG_SAVED,
    FILE_EXPORT_CONFIG_ADDED,
    FILE_EXPORT_CONFIG_SELECTED,
    FILE_EXPORT_CONFIG_DELETED,
    DATA_FILE_CONFIG_UPDATED,
    DATA_FILE_CONFIG_FIELD_CONFIG_ADDED,
    DATA_FILE_CONFIG_FIELD_CONFIGS_UPLOADED,
    DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
    DATA_FILE_CONFIG_FIELD_CONFIG_REMOVED,
    DATA_FIELDS_RETRIEVED
}

type RetrievedConfigsAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_CONFIGS_RETRIEVED
    configs: FileExportConfig[]
}

type RetrievedDataFieldsAction = {
    type: FileExportConfigActionEnum.DATA_FIELDS_RETRIEVED
    dataFields: DataField[]
}

type EditingConfigSavedAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_SAVED
}

type EditingConfigDiscardedAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_DISCARDED
}

type EditingConfigAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_UPDATED
    editingConfig: FileExportConfigState
}

type AddConfigAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_CONFIG_ADDED
}

type SelectConfigAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_CONFIG_SELECTED
    reference: string
}

type DeleteConfigAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_CONFIG_DELETED
    reference: string
}

type DataFileExportConfigAction = {
    type: FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED
    dataFileExportConfig: CommonFileExportConfigState
}

type DataFileConfigFieldConfigsAction = {
    type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED
    fieldConfig: ExportFieldConfigState
}

type DataFileConfigFieldConfigAddedAction = {
    type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_ADDED
}

type DataFileConfigFieldConfigsUploadedAction = {
    type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIGS_UPLOADED
    fieldConfigs: ExportFieldConfigState[]
}

type DataFileConfigFieldConfigsRemovedAction = {
    type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_REMOVED
    fieldConfigKey: string
}

export type FileExportConfigAction =
    | RetrievedConfigsAction
    | RetrievedDataFieldsAction
    | EditingConfigDiscardedAction
    | EditingConfigSavedAction
    | EditingConfigAction
    | DataFileExportConfigAction
    | AddConfigAction
    | SelectConfigAction
    | DeleteConfigAction
    | DataFileConfigFieldConfigsAction
    | DataFileConfigFieldConfigAddedAction
    | DataFileConfigFieldConfigsUploadedAction
    | DataFileConfigFieldConfigsRemovedAction

export default function FileExportConfigReducer(state: FileExportConfigDashboardState, action: FileExportConfigAction): FileExportConfigDashboardState {
    switch (action.type) {
        case FileExportConfigActionEnum.FILE_EXPORT_CONFIGS_RETRIEVED: {
            return {
                ...state,
                configs: action.configs.map(config => ({
                    ...config,
                    dataFileExportConfig: {
                        ...config.dataFileExportConfig,
                        optionalFieldConfigs: config.dataFileExportConfig.optionalFieldConfigs.map(fieldConfig => ({
                            ...fieldConfig,
                            key: uuidv4()
                        }))
                    }
                }))
            }
        }

        case FileExportConfigActionEnum.DATA_FIELDS_RETRIEVED: {
            return {
                ...state,
                dataFields: action.dataFields
            }
        }

        case FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_UPDATED: {
            return {
                ...state,
                editingConfig: {
                    ...action.editingConfig
                }
            }
        }

        case FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_DISCARDED: {
            const originalConfig = state.configs.find(c => c.reference === state.editingConfig?.reference)
            return {
                ...state,
                editingConfig: { ...originalConfig } as FileExportConfigState
            }
        }

        case FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_SAVED: {
            return {
                ...state,
                configs: state.configs.map(config => {
                    if (config.reference !== state.editingConfig?.reference) {
                        return config
                    }

                    return {
                        ...state.editingConfig
                    }
                })
            }
        }

        case FileExportConfigActionEnum.FILE_EXPORT_CONFIG_ADDED: {
            const newDataFileExportConfig: CommonFileExportConfigState = {
                filename: "",
                delimiter: "",
                lineEnding: "",
                hasHeaderRow: false,
                quoteFieldsWith: "",
                filters: [],
                optionalFieldConfigs: []
            }
            const newConfig = {
                reference: uuidv4(),
                fileType: FileType.CUSTOMER,
                fileFormat: FileFormat.CSV,
                dataFileExportConfig: newDataFileExportConfig
            }

            return {
                ...state,
                configs: [newConfig, ...state.configs],
                editingConfig: newConfig
            }
        }

        case FileExportConfigActionEnum.FILE_EXPORT_CONFIG_SELECTED: {
            const selectConfig = state.configs.find(config => config.reference === action.reference)
            return {
                ...state,
                editingConfig: selectConfig
            }
        }

        case FileExportConfigActionEnum.FILE_EXPORT_CONFIG_DELETED: {
            return {
                ...state,
                configs: state.configs.filter(config => config.reference !== action.reference),
                editingConfig: undefined
            }
        }

        case FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED: {
            return {
                ...state,
                editingConfig: state.editingConfig
                    ? {
                          ...state.editingConfig,
                          dataFileExportConfig: {
                              ...action.dataFileExportConfig
                          }
                      }
                    : state.editingConfig
            }
        }

        case FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_ADDED: {
            return {
                ...state,
                editingConfig: state.editingConfig
                    ? {
                          ...state.editingConfig,
                          dataFileExportConfig: {
                              ...state.editingConfig.dataFileExportConfig,
                              optionalFieldConfigs: [
                                  {
                                      key: uuidv4(),
                                      column: state.editingConfig.dataFileExportConfig.hasHeaderRow
                                          ? {
                                                type: FileImportConfigColumnTypeEnum.HEADER,
                                                header: ""
                                            }
                                          : {
                                                type: FileImportConfigColumnTypeEnum.INDEX,
                                                index: 1
                                            },
                                      dataFieldReference: "",
                                      dataType: { type: FileImportConfigFieldDataTypeEnum.TEXT },
                                      fieldType: FieldType.CUSTOMER,
                                      defaultValue: undefined
                                  },
                                  ...state.editingConfig.dataFileExportConfig.optionalFieldConfigs
                              ]
                          }
                      }
                    : state.editingConfig
            }
        }

        case FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIGS_UPLOADED: {
            return {
                ...state,
                editingConfig: state.editingConfig
                    ? {
                          ...state.editingConfig,
                          dataFileExportConfig: {
                              ...state.editingConfig.dataFileExportConfig,
                              optionalFieldConfigs: [...state.editingConfig.dataFileExportConfig.optionalFieldConfigs, ...action.fieldConfigs]
                          }
                      }
                    : state.editingConfig
            }
        }

        case FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_REMOVED: {
            return {
                ...state,
                editingConfig: state.editingConfig
                    ? {
                          ...state.editingConfig,
                          dataFileExportConfig: {
                              ...state.editingConfig.dataFileExportConfig,
                              optionalFieldConfigs: state.editingConfig.dataFileExportConfig.optionalFieldConfigs.filter(
                                  (fc: ExportFieldConfigState) => fc.key !== action.fieldConfigKey
                              )
                          }
                      }
                    : state.editingConfig
            }
        }

        case FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED: {
            return {
                ...state,
                editingConfig: state.editingConfig
                    ? {
                          ...state.editingConfig,
                          dataFileExportConfig: {
                              ...state.editingConfig.dataFileExportConfig,
                              optionalFieldConfigs: state.editingConfig.dataFileExportConfig.optionalFieldConfigs.map((fieldConfig: ExportFieldConfigState) => {
                                  if (fieldConfig.key !== action.fieldConfig.key) return fieldConfig
                                  return {
                                      ...fieldConfig,
                                      ...action.fieldConfig
                                  }
                              })
                          }
                      }
                    : state.editingConfig
            }
        }
    }
}
