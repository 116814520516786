import FlowsHeader from "./flows/FlowsHeader"
import classes from "./FlowDashboard.module.scss"
import Flows from "./flows/Flows"
import NodeSelection from "./nodeSelection/NodeSelection"
import FlowEditor from "../../../library/flow/flowEditor/FlowEditor"
import Flow from "../types/Flow"
import { useCallback, useState } from "react"
import { v4 as uuid } from "uuid"
import { useEdgesState, useNodesState, Node, Edge, useOnSelectionChange } from "reactflow"
import ProgressButton from "../../../library/buttons/ProgressButton/ProgressButton"
import NodeEditor from "./nodeEditor/NodeEditor"
import StandardButton from "../../../library/buttons/StandardButton/StandardButton"
import ExportConfig from "../types/ExportConfig"
import EntityConfig from "../../entityConfig/types/EntityConfig"
import Lookup from "../../../types/Lookup"
import FlowSculptorNode, { getDefaultStateForType, getOutputsForFlowNode } from "../types/FlowSculptorNode"
import FlowNodeType, { getBackgroundForType, getDefaultTitleForType, getIconForType } from "../types/FlowNodeType"
import { useConfig, useClient } from "invevo-react-components"
import useApiQuery from "../../../hooks/useApiQuery"
import PutFlowDto from "../types/PutFlowDto"
import ReactFlowNode from "../../../library/flow/flowEditor/ReactFlowNode"
import NodeState from "../../../library/flow/types/NodeState"
import { mapFlowEdgesToEdges } from "../../../library/flow/FlowEdge"
import { mapFlowNodesToNodes } from "../../../library/flow/FlowNode"

type FlowDashboardProps = {
    originalFlows: Flow[]
    communicationTemplates: { reference: string; name: string }[]
    exportConfigs: ExportConfig[]
    entityConfigs: EntityConfig[]
    lookups: Lookup[]
}

const FlowDashboard = ({ originalFlows, communicationTemplates, exportConfigs, entityConfigs, lookups }: FlowDashboardProps) => {
    const [flows, setFlows] = useState<Flow[]>(originalFlows)
    const [selectedFlowReference, setSelectedFlowReference] = useState<string | undefined>(undefined)
    const [editingNodeReference, setEditingNodeReference] = useState<string | undefined>(undefined)
    const [entityConfigReference, setEntityConfigReference] = useState<string | undefined>()
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false)

    const toggleFullscreen = () => setIsFullscreen(!isFullscreen)

    useOnSelectionChange({
        onChange: ({ nodes }) => {
            if (nodes.length !== 1) {
                setEditingNodeReference(undefined)
                return
            }

            setEditingNodeReference(nodes[0]!.data.state.reference)
        }
    })

    const onAddFlow = (entityConfigReference: string) =>
        setFlows(flows => [
            ...flows,
            {
                reference: uuid(),
                displayName: "New flow",
                entityConfigReference,
                edges: [],
                nodes: [
                    {
                        nodeType: "entity created",
                        reference: uuid(),
                        displayName: getDefaultTitleForType("entity created"),
                        description: "",
                        x: 0,
                        y: 0
                    }
                ]
            }
        ])

    const config = useConfig()
    const client = useClient()

    const putFlowRequest = useApiQuery({
        url: `${config.FLOW_API_URL}/api/${client}/flow`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    const onSaveClick = () => {
        const selectedFlow = flows.find(flow => flow.reference === selectedFlowReference)

        if (!selectedFlow || !entityConfigReference) return Promise.reject()

        const updatedFlow = updateFlow(selectedFlow, nodes, edges)

        return putFlowRequest
            .execute(selectedFlowReference, {
                displayName: updatedFlow.displayName,
                entityConfigReference: updatedFlow.entityConfigReference,
                edges: JSON.stringify(updatedFlow.edges),
                nodes: JSON.stringify(updatedFlow.nodes)
            } as PutFlowDto)
            .then(() => onUpdateFlow(updatedFlow))
    }

    const onRemoveFlow = (reference: string) => {
        reference === selectedFlowReference && setSelectedFlowReference(undefined)
        setFlows(flows => flows.filter(flow => flow.reference !== reference))
    }

    const onUpdateFlow = (flow: Flow) => setFlows(flows => flows.map(f => (f.reference === flow.reference ? flow : f)))

    const onFlowNameChanged = (newName: string) =>
        setFlows(flows => flows.map(f => (f.reference === selectedFlowReference ? { ...f, displayName: newName } : f)))

    const [nodes, setNodes, onNodesChange] = useNodesState<NodeState<FlowSculptorNode>>([])
    const [edges, setEdges, onEdgesChange] = useEdgesState([])

    const onFlowSelected = (reference: string) => {
        const selectedFlow = flows.find(flow => flow.reference === reference)

        const flowEdges: Edge[] = mapFlowEdgesToEdges(selectedFlow?.edges)
        const flowNodes: Node[] = mapFlowNodesToNodes(selectedFlow?.nodes, onNodeEditClick, onNodeDeleteClick)

        setSelectedFlowReference(reference)

        const selectedEntityConfig = entityConfigs.find(config => config.reference === selectedFlow?.entityConfigReference)?.reference
        setEntityConfigReference(selectedEntityConfig)

        setNodes(flowNodes)
        setEdges(flowEdges)
    }

    const onNodeEditClick = useCallback((reference: string) => {
        setEditingNodeReference(reference)
    }, [])

    const onNodeDeleteClick = useCallback(
        (reference: string) => {
            setEditingNodeReference(undefined)
            setEdges(es => es.filter(edge => edge.source !== reference && edge.target !== reference))
            setNodes(ns => ns.filter(node => node.id !== reference))
        },
        [setEdges, setNodes]
    )

    const editingNode = nodes.find(node => node.id === editingNodeReference)

    const onFlowNodeUpdated = (flowNode: FlowSculptorNode) => {
        if (editingNode === undefined) return

        const updatedNode = {
            ...editingNode,
            data: {
                ...editingNode.data,
                state: flowNode
            }
        }

        const outputs = getOutputsForFlowNode(flowNode).map(output => output.reference)
        setEdges(es => es.filter(edge => edge.source !== editingNode.id || outputs.includes(edge.sourceHandle ?? "")))

        setNodes(ns => ns.map(node => (node.id === editingNode.id ? updatedNode : node)))
    }

    const onBackClicked = () => {
        setNodes(ns => ns.map(node => ({ ...node, selected: false })))
        setEditingNodeReference(undefined)
    }

    const selectedFlow = flows.find(flow => flow.reference === selectedFlowReference)

    return (
        <div className="d-flex flex-grow-1 position-relative">
            {!isFullscreen && (
                <div className={`d-flex flex-column p-3 gap-2 bg-grey ${classes.flowsContainer}`}>
                    <FlowsHeader entityConfigs={entityConfigs} onNewFlowClicked={onAddFlow} />
                    <Flows
                        flows={flows}
                        selectedFlowReference={selectedFlowReference}
                        entityConfigDisplayNames={entityConfigs.map(config => ({ reference: config.reference, displayName: config.displayName }))}
                        onFlowSelected={onFlowSelected}
                        onFlowRemoved={onRemoveFlow}
                    />
                </div>
            )}
            <div className="d-flex justify-content-center h-100 w-100 bg-dark-gradient">
                {selectedFlowReference === undefined || selectedFlow === undefined ? (
                    <span className="text-white fs-5 mt-4">Please select an existing flow or add a new one</span>
                ) : (
                    <>
                        <FlowEditor<FlowSculptorNode, FlowNodeType>
                            key={selectedFlowReference}
                            nodeTypes={nodeTypes}
                            nodes={nodes}
                            setNodes={setNodes}
                            onNodesChange={onNodesChange}
                            edges={edges}
                            setEdges={setEdges}
                            onEdgesChange={onEdgesChange}
                            onNodeDeleteClick={onNodeDeleteClick}
                            getDefaultNodeState={getDefaultStateForType}
                        />
                        <div className={`d-flex h-100 ${classes.rightContainer}`}>
                            <div role="button" className="mb-auto ms-auto p-4 fs-4 text-white" onClick={toggleFullscreen} aria-label="fullscreen-toggle">
                                <i
                                    className={isFullscreen ? "fa-light fa-down-left-and-up-right-to-center" : "fa-light fa-up-right-and-down-left-from-center"}
                                />
                            </div>
                            {!isFullscreen && (
                                <div className={` d-flex flex-column gap-2 bg-blue p-2 ${editingNode ? classes.editingContainer : classes.nodesContainer}`}>
                                    {editingNode === undefined ? (
                                        <>
                                            <NodeSelection flowName={selectedFlow.displayName} nodes={nodes} onFlowNameChanged={onFlowNameChanged} />
                                            <ProgressButton
                                                className="mt-auto mx-2 mb-2"
                                                iconClasses="fal fa-save"
                                                label="Save config"
                                                colour="blue"
                                                succeededText="Saved!"
                                                failedText="Failed to save"
                                                onClickWithPromise={onSaveClick}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <NodeEditor
                                                flowNode={editingNode.data.state}
                                                communicationTemplates={communicationTemplates}
                                                exportConfigs={exportConfigs}
                                                dataFields={entityConfigs.find(config => entityConfigReference === config.reference)?.fields ?? []}
                                                lookups={lookups}
                                                onFlowNodeUpdated={onFlowNodeUpdated}
                                            />
                                            <StandardButton
                                                className="mt-auto mx-2 mb-2"
                                                iconClasses="far fa-chevron-double-left"
                                                label="Back"
                                                colour="blue"
                                                onClick={onBackClicked}
                                            />
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default FlowDashboard

const nodeTypes = {
    Node: (props: {
        data: {
            state: FlowSculptorNode
            onNodeDeleteClick: (reference: string) => void
        }
    }) => (
        <ReactFlowNode
            state={props.data.state}
            colour={getBackgroundForType(props.data.state.nodeType)}
            icon={getIconForType(props.data.state.nodeType)}
            showMainInputHandle={props.data.state.nodeType !== "entity created"}
            showDeleteButton={props.data.state.nodeType !== "entity created"}
            outputs={getOutputsForFlowNode(props.data.state)}
            onNodeDeleteClick={props.data.onNodeDeleteClick}
        />
    )
}

const updateFlow = (flow: Flow, nodes: Node<{ state: FlowSculptorNode }>[], edges: Edge[]): Flow => ({
    ...flow,
    edges: edges.map(edge => ({
        sourceNodeReference: edge.source,
        sourceNodeHandleReference: edge.sourceHandle ?? "",
        targetNodeReference: edge.target
    })),
    nodes: nodes.map(node => ({
        ...node.data.state,
        x: node.position.x,
        y: node.position.y
    }))
})
