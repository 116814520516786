import { useState } from "react"
import Dropdown from "../../../library/dropdowns/Dropdown"
import EntityDataFieldDropdown from "../../../library/dropdowns/EntityDataFieldDropdown"
import DropdownOption from "../../../types/DropdownOptions"
import EntityDataField from "../types/EntityDataField"
import IntraEntityCalculationOperation from "../types/IntraEntityCalculationOperation"
import { IntraEntityCalculationOperationEnum, IntraEntityCalculationOperationsDropdownOptions } from "../types/IntraEntityCalculationOperationEnum"
import Input from "../../../library/Inputs/Input"

enum OperationValueTypeEnum {
    ENTITY_FIELD = "ENTITY_FIELD",
    HARDCODED = "HARDCODED"
}

type IntraEntityCalculationOperationConfigProps = {
    availableDataFields: EntityDataField[]
    intraEntityCalculationOperation: IntraEntityCalculationOperation
    onIntraEntityCalculationOperationUpdated: (intraEntityCalculationOperation: IntraEntityCalculationOperation) => void
    onIntraEntityCalculationOperationRemoved: (intraEntityCalculationOperation: IntraEntityCalculationOperation) => void
}

const IntraEntityCalculationOperationConfig = ({
    availableDataFields,
    intraEntityCalculationOperation,
    onIntraEntityCalculationOperationUpdated,
    onIntraEntityCalculationOperationRemoved
}: IntraEntityCalculationOperationConfigProps) => {
    const [operationValueType, setOperationValueType] = useState<OperationValueTypeEnum>(
        intraEntityCalculationOperation.hardcodedValue ? OperationValueTypeEnum.HARDCODED : OperationValueTypeEnum.ENTITY_FIELD
    )

    const onEntityFieldUpdated = (entityDataField: EntityDataField | undefined) =>
        onIntraEntityCalculationOperationUpdated({ ...intraEntityCalculationOperation, fieldName: entityDataField?.fieldName })

    const onOperationUpdated = (option: DropdownOption<IntraEntityCalculationOperationEnum>) =>
        onIntraEntityCalculationOperationUpdated({ ...intraEntityCalculationOperation, operation: option.value })

    const onOperationValueTypeUpdated = (option: DropdownOption<OperationValueTypeEnum>) => setOperationValueType(option.value)

    const operationValueTypeOptions: DropdownOption<OperationValueTypeEnum>[] = [
        { value: OperationValueTypeEnum.ENTITY_FIELD, label: "Entity Field" },
        { value: OperationValueTypeEnum.HARDCODED, label: "Hardcoded Value" }
    ]

    const onHardcodedValueUpdated = (value: string) => onIntraEntityCalculationOperationUpdated({ ...intraEntityCalculationOperation, hardcodedValue: value })

    const onRemoveOperation = () => onIntraEntityCalculationOperationRemoved(intraEntityCalculationOperation)

    return (
        <div>
            <div className="d-flex mb-1">
                <Dropdown
                    className="me-2"
                    options={IntraEntityCalculationOperationsDropdownOptions}
                    onOptionSelected={onOperationUpdated}
                    selectedOption={IntraEntityCalculationOperationsDropdownOptions.find(option => option.value === intraEntityCalculationOperation.operation)}
                    displayElementAsLabel={true}
                    textAlign="left"
                    ariaLabel={`${intraEntityCalculationOperation.ordinal}-operation`}
                />
                <Dropdown
                    className="me-2"
                    options={operationValueTypeOptions}
                    onOptionSelected={onOperationValueTypeUpdated}
                    selectedOption={operationValueTypeOptions.find(option => option.value === operationValueType)}
                    textAlign="left"
                    ariaLabel={`${intraEntityCalculationOperation.ordinal}-value-type`}
                />
                {operationValueType === OperationValueTypeEnum.ENTITY_FIELD && (
                    <EntityDataFieldDropdown
                        options={availableDataFields}
                        onOptionSelected={onEntityFieldUpdated}
                        selectedOption={availableDataFields.find(field => field.fieldName === intraEntityCalculationOperation.fieldName)}
                        ariaLabel={`${intraEntityCalculationOperation.ordinal}-entity-field`}
                    />
                )}
                {operationValueType === OperationValueTypeEnum.HARDCODED && (
                    <Input
                        placeholder="Enter hardcoded value"
                        value={intraEntityCalculationOperation.hardcodedValue ?? ""}
                        onChange={onHardcodedValueUpdated}
                        ariaLabel={`${intraEntityCalculationOperation.ordinal}-hardcoded-value`}
                    />
                )}
                <i className="far fa-trash-can my-auto mx-2 text-light-grey pointer" onClick={onRemoveOperation} />
            </div>
        </div>
    )
}

export default IntraEntityCalculationOperationConfig
