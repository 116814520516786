import { each, isNil } from 'lodash';

export const api = {
  servicesUrl: '/',
  automateUrl: '',
  platformUrl: '',
  payUrl: '',
  calculateUrl: '',
  connectUrl: '',
  collectUrl: '',
  dashboardUrl: '',
  dataConfigUrl: '',
  taskUrl: '',
  integrationUrl: '',
  twilioUrl: '',
  applicationInsightsEnabled: false,
  applicaitonInsightConfig: {},
  launchDarklyProviderConfigBase: {},
  captchaApi: '',
  isDev: false
};

try {
  switch (process.env.NODE_ENV) {
    case 'production':
      const miaGlobalConfig = window.MiaGlobalConfig;

      if (isNil(miaGlobalConfig)) {
        alert(
          'BAD CONFIG ERROR (globalConstants.js) - Contact an Administrator!'
        );
      }

      api.servicesUrl = miaGlobalConfig.servicesUrl;
      api.automateUrl = miaGlobalConfig.automateUrl;
      api.platformUrl = miaGlobalConfig.platformUrl;
      api.calculateUrl = miaGlobalConfig.calculateUrl;
      api.connectUrl = miaGlobalConfig.connectUrl;
      api.collectUrl = miaGlobalConfig.collectUrl;
      api.payUrl = miaGlobalConfig.payUrl;
      api.dashboardUrl = miaGlobalConfig.dashboardUrl;
      api.dataConfigUrl = miaGlobalConfig.dataConfigUrl;
      api.taskUrl = miaGlobalConfig.taskUrl;
      api.integrationUrl = miaGlobalConfig.integrationUrl;
      api.captchaApi = miaGlobalConfig.captchaApi;
      api.twilioUrl = miaGlobalConfig.twilioUrl;
      api.applicaitonInsightConfig = miaGlobalConfig.applicaitonInsightConfig;
      api.applicationInsightsEnabled =
        miaGlobalConfig.applicationInsightsEnabled;
      api.launchDarklyProviderConfigBase =
        miaGlobalConfig.launchDarklyProviderConfigBase;

      break;
    case 'development':
      api.servicesUrl = process.env.REACT_APP_SERVICES_URL;
      api.taskUrl = process.env.REACT_APP_TASK_URL;
      api.automateUrl = process.env.REACT_APP_AUTOMATE_URL;
      api.platformUrl = process.env.REACT_APP_PLATFORM_URL;
      api.calculateUrl = process.env.REACT_APP_CALCULATE_URL;
      api.connectUrl = process.env.REACT_APP_CONNECT_URL;
      api.collectUrl = process.env.REACT_APP_COLLECT_URL;
      api.payUrl = process.env.REACT_APP_PAY_URL;
      api.dashboardUrl = process.env.REACT_APP_DASHBOARD_URL;
      api.dataConfigUrl = process.env.REACT_APP_DATACONFIG_URL;
      api.taskUrl = process.env.REACT_APP_TASK_URL;
      api.integrationUrl = process.env.REACT_APP_INTEGRATION_URL;

      api.applicaitonInsightConfig = {
        instrumentationKey: '2377541e-c0f5-45ce-83f0-0e43eedf6b62', // mia-dev-application-insights
        enableAutoRouteTracking: true,
        accountId: process.env.REACT_APP_DEV_ID || 'bob-the-developer'
        //enableDebug: false
      };
      api.captchaApi = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'; // ABI Test license
      api.isDev = true;
      api.twilioUrl = 'https://straw-horse-2322.twil.io';
      api.launchDarklyProviderConfigBase = {
        user: { key: process.env.REACT_APP_DEV_ID || 'local' },
        clientSideID: "5e4e67f1fac24c0814e96f43"
      };
      break;
    default:
      break;
  }
  api.version = JSON.stringify(require('../../../package.json').version) || '';
} catch (err) {
  // We are in test
}

export function init(arr) {
  each(arr, (e) => {
    this[e] = e;
  });
}

export function getApiUrl(client) {
  return `https://${client}-api.${api.servicesUrl}`
}

export const genericAction = {};
init.call(genericAction, [
  'FAKE_ACTION', // ABI To test the 'default' behaviour of a reducer
  'RESET_TO_INITIAL_STATE' // ABI To reset state for example in case of a logout
]);

export const authZ_Permissions = {};
init.call(authZ_Permissions, [
  'AutomateWorkflowAdmin',
  'ConnectSmsSender',
  'ConnectSmsTemplateWriter',
  'ConnectSmsTemplateReader',
  'ConnectConfigWriter',
  'ConnectConfigReader',
  'ConnectSmsSenderIdWriter',
  'ConnectSmsSenderIdReader',
  'CustomerRelationshipGroupWriter',
  'CustomerRelationshipGroupReader',
  'DashboardReader',
  'DataConfigReader',
  'ImportAdmin',
  'TemplateAdmin',
  'Reports',
  'CreditApproval',
  'FullSearchDataAccess',
  'CustomerAcquisition',
  'AcceptCustomerAcquisitionFromReferOrDecline',
  'CustomerAcquisitionAllTypes',
  'ChangeAccountStatus',
  'TempLimitCreditApproval',
  'RepaymentPlan',
  'Dashboard',
  'DataImportConfigReader',
  'DataImportConfigWriter',
  'RoleReader',
  'RoleWriter',
  'ClientUserReader',
  'ClientUserWriter',
  'VirtualAccountChildClone',
  'MappingAdmin',
  'TestingCreditLimitEngine',
  'PinNotes',
  'RequestCreditAgencyReport',
  'RoleAdmin',
  'UserAdmin',
  'ApiRecoveryAdmin',
  'LookupAdmin',
  'LookupReview',
  'CustomerEdit',
  'ScoreCardEngineAdmin',
  'FileImportAdminRead',
  'FileImportAdminUpdate',
  'SystemConfiguration',
  'ClientConfiguration',
  'ReportingByLedger',
  'ManagementReporting',
  'RuleGroupManagementAdmin',
  'RuleGroupManagementReview',
  'UXBeta'
]);

export const uiConfigAction = {};
init.call(uiConfigAction, [
  'SAVECONFIG_REQUEST',
  'SAVECONFIG_SUCCESS',
  'SAVECONFIG_FAILURE',
  'LOADDEFAULTCONFIG_REQUEST',
  'LOADDEFAULTCONFIG_SUCCESS',
  'LOADDEFAULTCONFIG_FAILURE'
]);

export const firstTimeUserAction = {};
init.call(firstTimeUserAction, [
  'FIRSTTIMEUSER_VALIDATELINK_REQUEST',
  'FIRSTTIMEUSER_VALIDATELINK_SUCCESS',
  'FIRSTTIMEUSER_VALIDATELINK_FAILURE'
]);

export const forgotPasswordAction = {};
init.call(forgotPasswordAction, [
  'FORGOTPASSWORD_SEND_LINK_REQUEST',
  'FORGOTPASSWORD_SEND_LINK_SUCCESS',
  'FORGOTPASSWORD_SEND_LINK_FAILURE',
  'FORGOTPASSWORD_VALIDATELINK_REQUEST',
  'FORGOTPASSWORD_VALIDATELINK_SUCCESS',
  'FORGOTPASSWORD_VALIDATELINK_FAILURE',
  'FORGOTPASSWORD_SEND_CONFIRMATION_REQUEST',
  'FORGOTPASSWORD_SEND_CONFIRMATION_SUCCESS',
  'FORGOTPASSWORD_SEND_CONFIRMAION_FAILURE',
  'FORGOTPASSWORD_RESET_SUCCESS',
  'FORGOTPASSWORD_RESET_COMPLETE'
]);

export const forgotUsernameAction = {};
init.call(forgotUsernameAction, [
  'FORGOTUSERNAME_REQUEST',
  'FORGOTUSERNAME_SUCCESS',
  'FORGOTUSERNAME_FAILURE'
]);

export const lookup = {
  initialState: {},

  init: function () {
    each(
      [
        'users',
        'taskCompletions',
        'taskDescriptions',
        'taskTypes',
        'currentlyUsedTaskTypes',
        'documentTypes',
        'customer',
        'customerContacts',
        'internalContacts',
        'availableLanguages',
        'agedDebt',
        'transType',
        'transStatus',
        'transSubStatus',
        'quickSearchFields',
        'mappingTableNames',
        'tableSchema',
        'customerColumns',
        'transactionColumns',
        'ApprovalIDVReport',
        'custTransactionDynamicColumns',
        'version',
        'accStat',
        'roles',
        'approvalExternalCreditReport',
        'ApprovalPGReport',
        'reasonsForDecision',
        'reasonsLimitIncrease',
        'vaContacts',
        'validatedContactIds',
        'followUpReasons',
        'permissions',
        'authFilters',
        'filterLookups',
        'customerAcquisitionLookups',
        'country',
        'branches',
        'regions',
        'repcodes',
        'tradetypes',
        'recoveryTypes',
        'reminderTypes',
        'adminLookups',
        'adminLookupDefinitions',
        'disableUIPermissionsGeneration',
        'globalSettings',
        'userLanguageCulture',
        'brands',
        'agreements',
        'fileTypes',
        'fileNameTemplates',
        'fileImportLocations',
        'destinations',
        'sourceTypes',
        'locations',
        'foreignKeys',
        'destinationFields',
        'fileTypeNames',
        'fileTypeRegex',
        'queueNames',
        'scorecardLookups',
        'stateMachineActionDateFields',
        'addresses',
        'contactTypeExternals',
        'contactTypes',
        'contactTypeInternals',
        'customerTypes',
        'customerStatuses',
        'eBillingStatuses',
        'guaranteeTypes',
        'legalStatuses',
        'paymentTerms',
        'ledgers',
        'tradeClassifications',
        'currencies',
        'transactionClosingTypes',
        'transactionDisputeStates',
        'addressTypes',
        'mailEventTypes',
        'followUpSources',
        'followUpTypes',
        'noteTypes',
        'transactionLists',
        'contactLists',
        'tradeLimitSearches',
        'emailTemplates',
        'postTemplates',
        'paymentMethods',
        'countries',
        'virtualAccountTypes',
        'dormantMethods',
        'smsTemplates',
        'accountBalanceModes',
        'documentTypes',
        'ubmEventMappingIds',
        'cardPaymentStatuses',
        'regionCodes',
        'searchGridColumns',
        'userTaskGridColumns',
        'panelGridColumns',
        'vaGridColumns',
        'accountSummarySections'
      ],
      (e) => {
        this.initialState[e] = {
          isFetching: false,
          fetched: false,
          results: []
        };
      }
    );

    return this;
  }
}.init();
init.call(lookup, [
  'REQUEST_LOOKUP',
  'RECEIVE_LOOKUP',
  'CLEAR_LOOKUP',
  'ERROR'
]);

export const NavigationType = {
  default: 0,
  header_search: 1,
  user_my_account: 2,
  user_diary: 3,
  customer_aged_debt: 4,
  select_customer: 5,
  header_adv_search: 6,
  user_trans_status: 7,
  user_aged_debt: 8,
  customer_trans_status: 9,
  user_transaction_type: 10,
  customer_transaction_type: 11,
  user_trans_substatus: 12,
  user_task: 13,
  customer_task: 14,
  acc_stat_pan: 15,
  awaiting_credit_limit_decision: 16,
  new_credit_limit_decisions: 17,
  user_followup: 18,
  customer_followup: 19,
  unread_email: 20,
  customer_falling_due: 21,
  customer_recent_payments: 22
};

export const CUSTOMER_TYPE = {
  INTERNAL: 1,
  EXTERNAL: 2
};

export const GLOBAL_SETTINGS = {
  // ABI this list must be kept in sync with the 'GetAttachmentType' function in the server source
  accepted_file_types: '.csv, .doc, .docx, .gif, .htm, .html, .jpeg, .mp3, .mp4, .msg, .odf, .ods, .pdf, .png, .rtf, .tif, .txt, .wav, .xhtml, .xls, .xlsx, .xlt, .xml',
  accepted_file_types_excluding_csv: '.doc, .docx, .gif, .htm, .html, .jpeg, .mp3, .mp4, .msg, .odf, .ods, .pdf, .png, .rtf, .tif, .txt, .wav, .xhtml, .xls, .xlsx, .xlt, .xml',
  max_upload_file_size: 9, // in Megabytes
  max_upload_bytes: 9437184 // 9 * 1024 * 1024
};

export const kendoGridOptions = {
  selectable: 'multiple',
  scrollable: 'scrollable',
  sortable: true,
  reorderable: true,
  resizable: true,
  pageable: {
    pageSizes: [50, 100, 200],
    refresh: true,
    buttonCount: 5
  },
  filterable: true
};

export const kendoGridDataSource = {
  serverPaging: true,
  serverSorting: true,
  serverFiltering: true,
  pageSize: 50,
  filterable: true,
  type: 'aspnetmvc-ajax'
};

export const clr = {
  intMaxValue: 2147483647
};

export const taskGridFilterValues = {
  My_Open: 'Assigned to me',
  My_Closed: 'Assigned to me - Closed',
  Open: 'Assigned to others',
  Closed: 'Assigned to others - Closed',
  All_Open: 'All Open',
  All: 'All'
};

export const lookupIsInUseValues = {
  Is_InUse: 'InUse',
  Is_NotInUse: 'NotInUse',
  All: 'All'
};

export const filterTypes = {
  Account: 'Account',
  VirtualAccount: 'Virtual Account',
  Transaction: 'Transaction'
};

export const filterValueTypes = {
  FreeText: 'FreeText',
  SimpleLookup: 'SimpleLookup',
  SearchLookup: 'SearchLookup',
  Number: 'Number',
  Date: 'Date',
  Boolean: 'Boolean'
};

export const editorTools = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'justifyLeft',
  'justifyCenter',
  'justifyRight',
  'justifyFull',
  'insertUnorderedList',
  'insertOrderedList',
  'indent',
  'outdent',
  'createLink',
  'unlink',
  'insertImage',
  'insertFile',
  'subscript',
  'superscript',
  'createTable',
  'addRowAbove',
  'addRowBelow',
  'addColumnLeft',
  'addColumnRight',
  'deleteRow',
  'deleteColumn',
  'formatting',
  'cleanFormatting',
  'fontName',
  'fontSize',
  'foreColor',
  'backColor',
  'viewHtml'
];

export const actionSource = {
  Unknown: 0,
  UI: 255,
  Portal: 128,
  StateMachine: 155,
  FileImporter: 55,
  ExternalAPI: 5
};

export const customerDetailsPane = {
  summary: 0,
  files: 1,
  contacts: 2,
  transactions: 3,
  history: 4,

  0: 'summary',
  1: 'files',
  2: 'contacts',
  3: 'transactions',
  4: 'history'
};

export const stateTaskType = {
  TASK: 0,
  UNREAD_EMAIL: 1,
  AWAIT_CREDIT_LIMIT: 2,
  NEW_CREDIT_LIMIT: 3,

  0: 'TASK',
  1: 'UNREAD_EMAIL',
  2: 'AWAIT_CREDIT_LIMIT',
  3: 'NEW_CREDIT_LIMIT'
};

export const userTaskDateType = {
  TODAY: 0,
  TOMORROW: 1,
  ALL: 2,
  RANGE: 3,

  0: 'TODAY',
  1: 'TOMORROW',
  2: 'ALL',
  3: 'RANGE'
};

export const userTaskGroupResultType = {
  all: 0,
  customer: 1,
  taskType: 2,

  0: 'all',
  1: 'customer',
  2: 'taskType'
};

export const layoutConstants = {
  MAIN_NAV_WIDTH: 50,
  MAIN_BODY_MIN_WIDTH: 1100,

  ACTION_MENU_WIDTH: 60,
  DOUBLE_CLICK_WAIT_TIME: 150,

  LEFT_PANE_MIN_WIDTH: 350,
  LEFT_PANE_MAX_WIDTH: 500,

  RIGHT_PANE_MIN_WIDTH: 819,

  RIGHT_PANE_LG_WIDTH: 1220
};

export const gridKeys = {
  USER_TASK_GRID_KEY: 'user-task-grid',
  CUSTOMER_TASK_GRID_KEY: 'customer-task-grid'
};

export const sectionTypes = [
  { value: 'credit-info', label: 'Credit Information' },
  { value: 'vp-info', label: 'VP Credit Info' },
  { value: 'credit-rating', label: 'Credit Rating' },
  { value: 'credit-limit-history', label: 'Credit Limit History' },
  { value: 'exposure', label: 'Exposure' },
  { value: 'monthly-sales', label: 'Monthly Sales' },
  { value: 'aged-debt', label: 'Aged Debt' },
  { value: 'collected', label: 'Collected' },
  { value: 'payment-performance', label: 'Payment Performance' },
  { value: 'falling-due', label: 'Falling Due' },
  { value: 'recent-payments', label: 'Recent Payments' },
  { value: 'account-info', label: 'Account Info' },
  { value: 'billing-address', label: 'Billing Address' },
  { value: 'recent-alerts', label: 'Recent Alerts' },
  { value: 'account-status', label: 'Account Status' },
  { value: 'account-pref', label: 'Account Pref' }
];

export const transactionFilterTypes = {
  agedDebt: 0,
  fallingDue: 1,
  recentPayment: 2,
  transactionStatus: 3,
  transactionType: 4,

  0: 'agedDebt',
  1: 'fallingDue',
  2: 'recentPayment',
  3: 'transactionStatus',
  4: 'transactionType'
};

// This defines what kind of dashboard state is being persisted.
export const UserConfigViewType = {
  // legacy dashboard types
  user: 1,
  activityList: 2,
  customer: 3,
  // user config types
  transactionGridColConfig: 4,
  summaryCardConfig: 5,
  searchResultGridColConfig: 6,
  userTaskGridColConfig: 7,
  hierarchyGridColConfig: 8
};
