import { useCallback, useEffect, useState } from 'react'
import { ProgressButton, useClient, useApi, getConfig } from 'invevo-react-components'
import classes from './Actions.module.scss'
import { actionTypes } from '../../reducers/actionTypes'
import { fieldDataTypes } from '../../enums/fieldDataType'

const Actions = ({ className, state, dispatch, hasCreditData }) => {
    const [isSaveDisabled, setIsSaveDisabled] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const client = useClient()
    const api = useApi()
    
    const hasDataChanged = useCallback(() => {
        return (JSON.stringify(state.originalCustomerDataConfiguration) !== JSON.stringify(state.editableCustomerDataConfiguration))
            || (JSON.stringify(state.originalCreditDataConfiguration) !== JSON.stringify(state.editableCreditDataConfiguration))
    }, [state.originalCustomerDataConfiguration, state.originalCreditDataConfiguration, state.editableCreditDataConfiguration, state.editableCustomerDataConfiguration])

    useEffect(() => {
        if (isSaving || hasDataChanged()) {
            setIsSaveDisabled(false)
            return
        }

        setIsSaveDisabled(true)
    }, [isSaving, hasDataChanged])

    const saveDataConfiguration = () => {
        setIsSaving(true)
        const promise = new Promise((resolve, reject) => {
            getConfig().then(config => {
                Promise.all([
                    api.put(`/api/${client}/data-import-config`, newRequestBody(state.editableCustomerDataConfiguration.customerFieldConfigs)),
                    hasCreditData && api.put(`${config.ASSESS_API_URL}/api/${client}/credit-data-import-config`, newRequestBody(state.editableCreditDataConfiguration.creditFieldConfigs))
                ]).then(_ => {
                    setIsSaving(false)
                    dispatch({ type: actionTypes.DATA_CONFIGURATION_UPDATED, data: { ...state.editableCustomerDataConfiguration } })
                    resolve()
                })
                    .catch(error => {
                        console.error(error)
                        reject()
                    })
            })
        })
        return promise
    }

    const newRequestBody = (updatedConfig) => {
        return {
            fields: updatedConfig.filter(f => f.fieldDataType).map(config => {
                return { ...config, isAvailableInAutoComplete: config.fieldDataType === fieldDataTypes.TEXT && config.isAvailableInAutoComplete }
            })
        }
    }

    return (
        <div className={`d-flex flex-column py-4 px-3 ${classes.container} ${className ? className : ''}`}>
            <h3 className="text-white">Data configuration</h3>

            <span className="mt-3 text-uppercase text-light-blue">Glossary</span>
            <div className={`d-flex flex-column mt-2 p-3 ${classes.glossary}`}>
                <span className="text-uppercase text-light-blue">Data Field name</span>
                <p className={`${classes['glossary-text']}`}>This is the name of the data field stored.</p>
                <span className="mt-2 text-uppercase text-light-blue">Field type</span>
                <p className={`${classes['glossary-text']}`}>This is type of data. Such as Date, Number or Text.</p>
                <span className="mt-2 text-uppercase text-light-blue">Auto Complete</span>
                <p className={`${classes['glossary-text']}`}>This toggles whether to display the field in auto complete dropdowns. Please note this is only available for Text field types.</p>
                <span className="mt-2 text-uppercase text-light-blue">UI field name</span>
                <p className={`${classes['glossary-text']}`}>This is the display name of the field as it will appear in the user's UI. It is the same as the Data Field Name by default but can be set to anything.</p>
            </div>

            <div className="d-flex mt-auto">
                <ProgressButton
                    className="w-100"
                    iconClasses="fal fa-save"
                    label="Save changes"
                    colour="blue"
                    succeededText="Saved successfully"
                    failedText="Failed to save"
                    onClickWithPromise={() => {
                        const promise = new Promise((resolve, reject) => {
                            saveDataConfiguration()
                                .then(_ => {
                                    resolve()
                                },
                                    _ => reject())
                                .catch(_ => reject())
                        })
                        return promise
                    }}
                    disabled={isSaveDisabled} />
            </div>
        </div>
    )
}

export default Actions