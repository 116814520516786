import { ReadonlyFilters, Table, useClient, ProgressButton, useApi, useConfig, Loading } from "invevo-react-components"
import { useEffect, useState } from 'react'
import classes from './WorkflowRunEvaluation.module.scss'
import { getFilters } from '../../../utils/Utils'

const WorkflowRunEvaluation = ({ runReference, customerEvaluations, matched, matchedCount, notMatchedCount, syncedCustomerCount, filterRules }) => {
    const [stillGeneratingReport, setStillGeneratingReport] = useState(syncedCustomerCount < matchedCount + notMatchedCount)
    const [countOfCustomers, setCountOfCustomers] = useState(matched ? matchedCount : notMatchedCount)

    const api = useApi()
    const client = useClient()
    const config = useConfig()

    useEffect(() => {
        setStillGeneratingReport(syncedCustomerCount < matchedCount + notMatchedCount)
    }, [matchedCount, notMatchedCount, syncedCustomerCount])

    useEffect(() => {
        setCountOfCustomers(matched ? matchedCount : notMatchedCount)
    }, [matched, matchedCount, notMatchedCount])

    const newRequestBody = (ismatched) => {
        let filterNames = []
        getAppliedFilters(filterRules).forEach(element => filterNames.push(element.field))

        return {
            filters: filterNames,
            isMatched: ismatched
        }
    }

    const createWorkflowEvaluations = (ismatched) => {
        const promise = new Promise((resolve, reject) => {
            api.post(`${config.AUTOMATE_API_URL}/api/${client}/create-workflow-run-file/${runReference}`,
                newRequestBody(ismatched)
            ).then(_ => {
                downloadWorkflowEvaluations()
                resolve()
            })
                .catch(error => {
                    console.error(error)
                    reject()
                })
        })
        return promise
    }

    const downloadWorkflowEvaluations = () => {
        api.get(`${config.AUTOMATE_API_URL}/api/${client}/download-workflow-run/${runReference}`)
            .then(response => {
                var hiddenElement = document.createElement('a');
                hiddenElement.href = response.data;
                hiddenElement.click();
            })
            .catch(error => {
                console.error(error)
            })
    }

    const getAppliedFilters = (rules) => {
        return getFilters(rules).map(filter => { return { ...filter, operator: filter.operator.value, field: filter.fieldName } })
    }

    const [filters, setFilters] = useState(getAppliedFilters(filterRules))
    const [tableHeaders, setTableHeaders] = useState(getDistinctHeaders(filters))

    useEffect(() => {
        var filters = getAppliedFilters(filterRules)
        setFilters(filters)
        setTableHeaders(getDistinctHeaders(filters))
    }, [filterRules])

    return (
        <Loading isLoading={!config.AUTOMATE_API_URL} colour="blue">
            <div className="d-flex flex-column">
                <div className="d-flex flex-column w-100 h-100">
                    <ReadonlyFilters title="Applied Evaluation Filters:" rules={filters} />
                    {stillGeneratingReport &&
                        <div className={"d-flex align-items-center no-select mt-3"}>
                            <i className="fal fa-exclamation-triangle text-warning me-2 fs-4"></i>
                            <span className="fs-5 me-2">Still generating report. Processed</span>
                            <span className="fs-5 fw-bold me-2">{`${syncedCustomerCount}/${matchedCount + notMatchedCount}`}</span>
                        </div>
                    }
                    <div className="mt-3 flex-grow-1">
                        <div className={`accordion w-100 ${classes.container} p-3`} id="workflowRunCollapsableId">
                            <h2 className="accordion-header w-100" id="heading">
                                <button
                                    className={`accordion-button collapsed p-0 ${classes.title}`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#workflowRunCollapsableItemId"
                                    aria-expanded={true}
                                    aria-controls="collapseOne"
                                >
                                    <div className="d-flex flex-column">
                                        <div className={`d-flex align-items-center`}>
                                            <i className="fal fa-check-circle text-success me-2 fs-4"></i>
                                            <span className="fs-5 me-2">Customers these rules {matched ? '' : 'do not'} apply to:</span>
                                            <span className="text-black fw-bold fs-5">{countOfCustomers} customers</span>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="workflowRunCollapsableItemId" className={`accordion-collapse collapse show w-100`} data-bs-parent="#workflowRunCollapsableId">
                                <div className="accordion-body pt-3 pb-0 px-0 w-100">
                                    <div className={`flex-grow-1 overflow-auto ${classes["workflow-run-result-table"]}`}>
                                        {runReference &&
                                            <Table
                                                columns={tableHeaders}
                                                rows={mapToCustomerResults(customerEvaluations, tableHeaders).results}
                                                filterable={true}
                                                url={`${config.AUTOMATE_API_URL}/api/${client}/filter-workflow-run-evaluations`}
                                                workflowRunReference={runReference}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProgressButton
                    iconClasses="fal fa-file-csv"
                    label="Export results"
                    className="w-25 pt-3"
                    onClickWithPromise={() => {
                        const promise = new Promise((resolve, reject) => {
                            createWorkflowEvaluations(matched)
                                .then(_ => resolve(), _ => reject())
                                .catch(_ => reject())
                        })
                        return promise
                    }}
                    disabled={stillGeneratingReport}
                />
            </div>
        </Loading>
    )
}

const mapToCustomerResults = (data, tableHeaders) => {
    return {
        results: data.map(result => {
            return {
                ...getColumnValues(result, tableHeaders)
            }
        })
    }
}

const getColumnValues = (customerResult, tableHeaders) => {
    var obj = {}
    tableHeaders.forEach(header => {
        obj = { ...obj, [header.fieldName]: customerResult.data[header.fieldName] }
    })

    return obj
}

const getColumn = (name) => {
    return {
        fieldName: name, label: name
    }
}

const getDistinctHeaders = (filterRules) => {
    var headers = [getColumn("accountcode"), getColumn("name"), ...filterRules.map(f => getColumn(f.field))]
    const uniqueHeaderFields = [...new Set(headers.map(item => item.fieldName))]
    return uniqueHeaderFields.map(fieldName => headers.filter(header => header.fieldName === fieldName)[0])
}

export default WorkflowRunEvaluation