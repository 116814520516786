import { IconButton, Toggle } from "invevo-react-components"
import Checkbox from "../../../../../../library/form/Checkbox"
import {
    FileImportConfigAction,
    FileImportConfigActionEnum,
    FileImportConfigColumnTypeState,
    FileImportConfigFieldTrimOptionState,
    FieldConfigState
} from "../../../../reducers/FileEntityImportConfigReducer"
import { FileImportConfigColumnTypeEnum } from "../../../../types/FileImportConfigColumnType"
import { FileImportConfigFieldDataType, FileImportConfigFieldDataTypeEnum } from "../../../../types/FileImportConfigFieldDataType"
import { FileImportConfigFieldDefaultValue, FileImportConfigFieldDefaultValueEnum } from "../../../../types/FileImportConfigFieldDefaultValue"
import ColumnTypeConfig from "./ImportColumnTypeConfig"
import DataTypeConfig from "./DataTypeConfig"
import DefaultValueConfig from "./DefaultValueConfig"
import classes from "./ImportFieldConfig.module.scss"
import TrimOptionConfig from "./TrimOptionConfig"
import { v4 as uuidv4 } from "uuid"
import DataPrimitive from "../../../../../../types/DataPrimitive"
import RejectActionConfig from "./RejectActionConfig"
import { RejectActionEnum } from "../../../../types/RejectActionEnum"
import { FileType } from "../../../../types/FileImportConfig"
import EntityDataField from "../../../../../entityConfig/types/EntityDataField"
import EntityDataFieldDropdown from "../../../../../../library/dropdowns/EntityDataFieldDropdown"
import { dataTypeEnumToDataPrimitive } from "../../../utils"
import Lookup from "../../../../../../types/Lookup"

type FieldConfigProps = {
    state: { config: FieldConfigState; dataFields: EntityDataField[]; lookups: Lookup[]; hasHeaderRow: Boolean; type: FileType }
    dispatch: React.Dispatch<FileImportConfigAction>
    onRemoveClick: (fieldConfig: FieldConfigState) => any
}

const FieldConfig = ({ state, dispatch, onRemoveClick }: FieldConfigProps) => {
    const onColumnsChange = (columns: FileImportConfigColumnTypeState[]) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                columns
            }
        })
    }

    const onDataFieldChange = (option?: EntityDataField | undefined) => {
        if (!option) return
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                dataFieldReference: option.fieldName,
                dataType: {
                    ...state.config.dataType,
                    type: getDataTypeFromDataField(dataTypeEnumToDataPrimitive(option.dataType.type))
                } as FileImportConfigFieldDataType,
                defaultValue: state.config.defaultValue
                    ? ({
                        ...state.config.defaultValue,
                        type: getDefaultValueTypeFromDataField(dataTypeEnumToDataPrimitive(option.dataType.type))
                    } as FileImportConfigFieldDefaultValue)
                    : undefined
            }
        })
    }

    const onCanBeEmptyChange = () => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                canBeEmpty: !state.config.canBeEmpty
            }
        })
    }

    const onRejectActionChange = (newRejectAction: RejectActionEnum) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                rejectAction: newRejectAction
            }
        })
    }

    const onDataTypeChange = (dataType: FileImportConfigFieldDataType) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                dataType
            }
        })
    }

    const onDefaultValueChange = (defaultValue: FileImportConfigFieldDefaultValue) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                defaultValue
            }
        })
    }

    const onShowDefaultValueConfigChange = () => {
        const dataField = getDataField()
        if (!dataField) return
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                defaultValue: state.config.defaultValue
                    ? undefined
                    : ({
                        type: getDefaultValueTypeFromDataField(dataTypeEnumToDataPrimitive(dataField.dataType.type)),
                        stringValue: "",
                        booleanValue: true,
                        numberValue: "0"
                    } as FileImportConfigFieldDefaultValue)
            }
        })
    }

    const onAddTrimOption = () => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_TRIM_OPTION_ADDED,
            fieldConfigKey: state.config.key
        })
    }

    const onTrimOptionChange = (trimOption: FileImportConfigFieldTrimOptionState) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                trimOptions: state.config.trimOptions.map((to: FileImportConfigFieldTrimOptionState) => {
                    if (to.key !== trimOption.key) return to
                    return trimOption
                })
            }
        })
    }

    const onTrimOptionDelete = (trimOption: FileImportConfigFieldTrimOptionState) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                trimOptions: state.config.trimOptions.filter((to: FileImportConfigFieldTrimOptionState) => to.key !== trimOption.key)
            }
        })
    }

    const onRemoveFieldConfigClick = () => onRemoveClick(state.config)

    const onAddColumnClick = () => {
        onColumnsChange([
            ...state.config.columns,
            {
                key: uuidv4(),
                type: state.hasHeaderRow ? FileImportConfigColumnTypeEnum.HEADER : FileImportConfigColumnTypeEnum.INDEX,
                header: "",
                index: ""
            } as FileImportConfigColumnTypeState
        ])
    }

    const getDataField = () => state.dataFields.find(df => df.fieldName === state.config.dataFieldReference)

    return (
        <div className={`d-flex w-100 ${classes.card}`}>
            <div className="d-flex flex-column p-4 w-100">
                <div className={`d-flex flex-grow-1 flex-column small`}>
                    <div className="d-flex align-items-center mb-2">
                        <span className="fs-4 text-grey">Column(s)</span>
                        <IconButton className="ms-auto" iconClasses="far fa-plus" onClick={onAddColumnClick} ariaLabel="Add new column for optional field" />
                    </div>
                    <ColumnTypeConfig columns={state.config.columns} onChange={onColumnsChange} />
                </div>
                <div className={`${classes.horizontalDivider} m-2`} />
                <div className="d-flex flex-grow-1">
                    <div className={`d-flex flex-grow-1 flex-column small me-3`}>
                        <span className="text-uppercase small mb-1 ms-1 text-grey">Invevo data field</span>
                        <EntityDataFieldDropdown
                            className="flex-grow-1 mb-2"
                            options={state.dataFields}
                            selectedOption={getDataField()}
                            onOptionSelected={onDataFieldChange}
                            ariaLabel={"Add invevo data field"}
                        />
                    </div>
                    {getDataField() && <DataTypeConfig config={state.config.dataType} onChange={onDataTypeChange} />}
                </div>
                <div className={`${classes.horizontalDivider} m-2`} />
                <div className="d-flex align-items-center">
                    <Checkbox className="small me-4" isChecked={state.config.canBeEmpty} label="Can be empty" onClick={onCanBeEmptyChange} />

                    <RejectActionConfig rejectAction={state.config.rejectAction} onChange={onRejectActionChange} />
                </div>
                {getDataField() && state.config.canBeEmpty && state.config.dataType.type !== FileImportConfigFieldDataTypeEnum.DATE && (
                    <>
                        <div className={`${classes.horizontalDivider} m-2`} />
                        <div className={`d-flex flex-column`}>
                            <div className="d-flex align-items-center">
                                <span className="fs-4 text-grey">Default value if empty</span>
                                <Toggle
                                    className="ms-auto"
                                    status={state.config.defaultValue !== undefined}
                                    onStatusChanged={onShowDefaultValueConfigChange}
                                    toggleColour="blue"
                                    size="small"
                                    ariaLabel="Add default value"
                                />
                            </div>
                            <div className={`${classes.horizontalDivider} m-2`} />
                            {state.config.defaultValue && (
                                <DefaultValueConfig
                                    config={state.config.defaultValue}
                                    lookup={state.lookups.find(l => l.reference === getDataField()?.dataType.lookupReference)}
                                    onChange={onDefaultValueChange}
                                />
                            )}
                        </div>
                    </>
                )}
                <div className={`${classes.horizontalDivider} m-2`} />
                <div className="d-flex align-items-center">
                    <span className="fs-4 text-grey">Trim options</span>
                    <IconButton className="ms-auto" iconClasses="far fa-plus" onClick={onAddTrimOption} ariaLabel="Add new trim option for optional field" />
                </div>
                <div className={`d-flex flex-column mt-2`}>
                    {state.config.trimOptions.length > 0 &&
                        state.config.trimOptions
                            .map((trimOption: FileImportConfigFieldTrimOptionState) => (
                                <TrimOptionConfig key={trimOption.key} trimOption={trimOption} onChange={onTrimOptionChange} onDelete={onTrimOptionDelete} />
                            ))
                            .reduce((curr, prev) => (
                                <>
                                    {curr}
                                    <span className="my-2" />
                                    {prev}
                                </>
                            ))}
                </div>
            </div>
            <IconButton className="ms-3 p-4 border-2 border-start" iconClasses="far fa-trash" onClick={onRemoveFieldConfigClick} />
        </div>
    )
}

export const getDataTypeFromDataField = (dataPrimitive: DataPrimitive) => {
    switch (dataPrimitive) {
        case DataPrimitive.DATE: {
            return FileImportConfigFieldDataTypeEnum.DATE
        }
        case DataPrimitive.BOOLEAN: {
            return FileImportConfigFieldDataTypeEnum.BOOLEAN
        }
        case DataPrimitive.LOOKUP: {
            return FileImportConfigFieldDataTypeEnum.TEXT
        }
        case DataPrimitive.NUMBER: {
            return FileImportConfigFieldDataTypeEnum.NUMBER
        }
        case DataPrimitive.TEXT: {
            return FileImportConfigFieldDataTypeEnum.TEXT
        }
    }
}

export const getDefaultValueTypeFromDataField = (dataPrimitive: DataPrimitive) => {
    switch (dataPrimitive) {
        case DataPrimitive.DATE: {
            return FileImportConfigFieldDefaultValueEnum.STRING
        }
        case DataPrimitive.BOOLEAN: {
            return FileImportConfigFieldDefaultValueEnum.BOOLEAN
        }
        case DataPrimitive.LOOKUP: {
            return FileImportConfigFieldDefaultValueEnum.STRING
        }
        case DataPrimitive.NUMBER: {
            return FileImportConfigFieldDefaultValueEnum.NUMBER
        }
        case DataPrimitive.TEXT: {
            return FileImportConfigFieldDefaultValueEnum.STRING
        }
    }
}
export default FieldConfig
