import FilterRuleInputList from './FilterRuleInputList'
import FilterRuleInputRange from './FilterRuleInputRange'
import { filterTypes, textFilterTypes, numericFilterTypes, dateFilterTypes } from './Filters'
import classes from './FilterRules.module.scss'
import { AutoComplete, CustomerFieldDropdown, Dropdown, FieldDataTypes, IconButton, Input, LogicOperatorOptions } from 'invevo-react-components'

const FilterRules = ({
    rules,
    onRuleChange,
    onRemoveRule,
    fieldOptions,
    fieldsAvailableForAutoComplete,
    inputType,
    autoCompleteUrl,
    readonly = false,
    enableTooltip = false,
    scrollable = false
}) => {
    const dateValueOptions = [
        { id: 1, label: "Before today", value: LogicOperatorOptions.DATE_DAYS_BEFORE_TODAY },
        { id: 2, label: "After today", value: LogicOperatorOptions.DATE_DAYS_AFTER_TODAY }
    ]

    const onLogicOperatorSelected = (option, rule, ruleIndex) => {
        var newOperator = option
        if (option.value === LogicOperatorOptions.DATE_IS_IN) {
            newOperator = { ...option, value: LogicOperatorOptions.DATE_DAYS_BEFORE_TODAY }
        }
        const newRule = { ...rule, operator: newOperator, value: "" }
        onRuleChange(newRule, ruleIndex)
    }

    const onDateValueOptionSelected = (option, rule, ruleIndex) => {
        const newRule = { ...rule, operator: { ...rule.operator, value: option.value } }
        onRuleChange(newRule, ruleIndex)
    }

    const onMinRangeValueChange = (newValue, rule, ruleIndex) => {
        if (newValue.includes(',')) {
            return
        }

        const rangeParts = rule.value.split(',')
        if (rangeParts.length === 2) {
            const newRule = { ...rule, value: `${newValue},${rangeParts[1]}` }
            onRuleChange({ ...newRule, isValid: isRuleValid(newRule) }, ruleIndex)
            return
        }

        const newRule = { ...rule, value: `${newValue},` }
        onRuleChange({ ...newRule, isValid: isRuleValid(newRule) }, ruleIndex)
    }

    const onMaxRangeValueChange = (newValue, rule, ruleIndex) => {
        if (newValue.includes(',')) {
            return
        }

        const rangeParts = rule.value.split(',')
        if (rangeParts.length >= 1) {
            const newRule = { ...rule, value: `${rangeParts[0]},${newValue}` }
            onRuleChange({ ...newRule, isValid: isRuleValid(newRule) }, ruleIndex)
            return
        }

        const newRule = { ...rule, value: `,${newValue}` }
        onRuleChange({ ...newRule, isValid: isRuleValid(newRule) }, ruleIndex)
    }

    const onListValueChange = (newValue, valueIndex, rule, ruleIndex) => {
        if (newValue.includes(',')) {
            return
        }

        const listParts = rule.value.split(',')
        listParts[valueIndex] = newValue

        const finalList = listParts.filter(v => v !== null && v !== "").join(",")

        const newRule = { ...rule, value: finalList }
        onRuleChange({ ...newRule, isValid: isRuleValid(newRule) }, ruleIndex)
    }

    const onValueChange = (value, rule, ruleIndex) => {
        const newRule = { ...rule, value }
        onRuleChange({ ...newRule, isValid: isRuleValid(newRule) }, ruleIndex)
    }

    const onFieldChange = (field, rule, ruleIndex) => {
        const newRule = { ...rule, field }
        onRuleChange(newRule, ruleIndex)
    }

    const onCustomerFieldChange = (field, rule, ruleIndex) => {
        if (!field) {
            const newRule = { ...rule, fieldName: "", displayName: "", type: "", fieldDataType: "" }
            onRuleChange(newRule, ruleIndex)
            return
        }

        const newRule = { ...rule, fieldName: field.fieldName, displayName: field.displayName, type: field.type }
        onRuleChange(newRule, ruleIndex)
    }

    const isRuleValid = (rule) => {
        if (rule.operator.value === LogicOperatorOptions.EQUALS) {
            return true
        }

        if (rule.operator.value === LogicOperatorOptions.NOT_IN || rule.operator.value === LogicOperatorOptions.IN) {
            const listParts = rule.value.split(',')

            if (listParts.filter(item => item !== item.trim()).length !== 0) {
                return false
            }
            return rule.value !== ""
        }

        if (rule.operator.value === LogicOperatorOptions.RANGE) {
            const rangeParts = rule.value.split(',')
            if (rangeParts.length !== 2) {
                return false
            }

            return isValidNumber(rangeParts[0]) &&
                isValidNumber(rangeParts[1]) &&
                parseFloat(rangeParts[0]) < parseFloat(rangeParts[1])
        }

        if (rule.operator.value === LogicOperatorOptions.DATE_DAYS_AFTER_TODAY || rule.operator.value === LogicOperatorOptions.DATE_DAYS_BEFORE_TODAY) {
            const listParts = rule.value.split(',')

            if (listParts.filter(item => item !== item.trim()).length !== 0) {
                return false
            }

            return listParts.every(p => isValidInteger(p))
        }

        return isValidNumber(rule.value)
    }

    const isValidNumber = (number) => {
        const isValidNumberRegex = /^\d+(\.\d+)?$/
        return isValidNumberRegex.test(number)
    }

    const isValidInteger = (number) => {
        const isValidIntegerRegex = /^\d+?$/
        return isValidIntegerRegex.test(number)
    }

    const getInputtype = (rule, fieldsAvailableForAutoComplete) => {
        return (
            (rule.fieldName && fieldsAvailableForAutoComplete.map(f => f.toLowerCase()).includes(rule.fieldName.toLowerCase()))
            || (rule.field && fieldsAvailableForAutoComplete.map(f => f.toLowerCase()).includes(rule.field.toLowerCase()))
        ) ? "autocomplete" : "normal"
    }

    const renderSwitch = (rule, ruleIndex) => {
        switch (rule.operator.value) {
            case LogicOperatorOptions.IN:
            case LogicOperatorOptions.NOT_IN:
                return <FilterRuleInputList
                    readonly={readonly}
                    rule={rule}
                    ruleIndex={ruleIndex}
                    onChange={onListValueChange}
                    inputType={getInputtype(rule, fieldsAvailableForAutoComplete)}
                    autoCompleteUrl={autoCompleteUrl}
                    customerFieldType={rule.type ? true : false}
                    enableTooltip={enableTooltip}
                    scrollable={scrollable}
                />
            case LogicOperatorOptions.RANGE:
                return <FilterRuleInputRange
                    readonly={readonly}
                    rule={rule}
                    ruleIndex={ruleIndex}
                    onMinChange={onMinRangeValueChange}
                    onMaxChange={onMaxRangeValueChange}
                    isValidNumber={isValidNumber}
                    enableTooltip={enableTooltip}
                    scrollable={scrollable}
                />
            case LogicOperatorOptions.DATE_DAYS_AFTER_TODAY:
            case LogicOperatorOptions.DATE_DAYS_BEFORE_TODAY:
                return (
                    <div className="d-flex flex-column">
                        <div className="d-flex me-3 align-items-center">
                            <FilterRuleInputList
                                readonly={readonly}
                                rule={rule}
                                ruleIndex={ruleIndex}
                                onChange={onListValueChange}
                                enableTooltip={enableTooltip}
                                scrollable={scrollable}
                            />
                            <div className="d-flex align-self-start align-items-center ms-3">
                                <span className="text-uppercase text-grey me-3">days</span>
                                <Dropdown
                                    options={dateValueOptions}
                                    onOptionSelected={(option) => onDateValueOptionSelected(option, rule, ruleIndex)}
                                    defaultOption={dateValueOptions.find(o => o.value === rule.operator.value)}
                                    disabled={readonly}
                                />
                            </div>
                        </div>
                        {rule.value && !rule.isValid &&
                            <div className="mt-2 d-flex align-items-center text-danger">
                                <i className="fal fa-exclamation-triangle me-2"></i>
                                <span>Please specify a valid number</span>
                            </div>
                        }
                    </div>
                )
            default:
                return (
                    <div className="d-flex flex-column">
                        <div className="d-flex me-3">
                            <Input
                                disabled={readonly}
                                value={rule.value}
                                onChange={(event) => onValueChange(event.target.value, rule, ruleIndex)}
                                isValid={rule.operator.value === LogicOperatorOptions.EQUALS || (!rule.value || isValidNumber(rule.value))}
                            />
                        </div>
                        {rule.value && !rule.isValid &&
                            <div className="mt-2 d-flex align-items-center text-danger">
                                <i className="fal fa-exclamation-triangle me-2"></i>
                                <span>Please specify a valid number</span>
                            </div>
                        }
                    </div>
                )
        }
    }

    const getSelectedFilterType = (filterType) => {
        if (filterType && (filterType.value === LogicOperatorOptions.DATE_DAYS_AFTER_TODAY || filterType.value === LogicOperatorOptions.DATE_DAYS_BEFORE_TODAY)) {
            return filterTypes.find(f => f.value === LogicOperatorOptions.DATE_IS_IN)
        }

        return filterType
    }

    const getDropdownOptions = (filterType, field) => {
        if (filterType !== "customerfielddropdown") return filterTypes
        else {
            var currentFieldValue = fieldOptions.filter(option => option.type === field.type && option.fieldName === field.fieldName)[0]
            if (!currentFieldValue) return filterTypes
            switch (currentFieldValue.fieldDataType) {
                case FieldDataTypes.TEXT: return textFilterTypes
                case FieldDataTypes.NUMBER: return numericFilterTypes
                case FieldDataTypes.DATE: return dateFilterTypes
                default: return filterTypes
            }
        }
    }

    return (
        <div className="d-flex flex-grow-1 flex-column">
            {rules.length > 0 && rules.map((rule, ruleIndex) => {
                const selectedFilterType = getSelectedFilterType(rule.operator)
                return (
                    <div key={ruleIndex} className="d-flex">
                        <div className="d-flex flex-column ms-2 me-3">
                            <div className="d-flex">
                                {inputType === "autocomplete" ?
                                    <AutoComplete
                                        placeholder="Enter field name"
                                        options={fieldOptions}
                                        initialValue={rule.field}
                                        onChange={(value) => onFieldChange(value, rule, ruleIndex)}
                                        disabled={readonly}
                                        enableTooltip={enableTooltip}
                                        scrollable={scrollable}
                                    /> :
                                    inputType === "customerfielddropdown" ?
                                        <CustomerFieldDropdown
                                            onChange={(value) => onCustomerFieldChange(value, rule, ruleIndex)}
                                            options={fieldOptions}
                                            initialValue={rule.fieldName}
                                            initialType={rule.type}
                                        /> :
                                        <Input
                                            disabled={readonly}
                                            value={rule.field}
                                            placeholder="Enter field name"
                                            onChange={(event) => onFieldChange(event.target.value, rule, ruleIndex)}
                                        />
                                }
                            </div>

                            {(!rule.fieldName && !rule.field) && rule.operator &&
                                <div className="mt-2 d-flex align-items-center text-danger">
                                    <i className="fal fa-exclamation-triangle me-2"></i>
                                    <span>Please enter a field name</span>
                                </div>
                            }
                            {rule.type && rule.type === "incorrect" &&
                                <div className="mt-2 d-flex align-items-center text-danger">
                                    <i className="fal fa-exclamation-triangle me-2"></i>
                                    <span>Please select a valid field name from the list</span>
                                </div>
                            }
                        </div>
                        <Dropdown
                            disabled={readonly}
                            className="align-self-start me-3"
                            placeholder="Select filter type"
                            defaultOption={selectedFilterType}
                            options={getDropdownOptions(inputType, rule)}
                            onOptionSelected={(option) => onLogicOperatorSelected(option, rule, ruleIndex)}
                        />

                        {rule.operator && renderSwitch(rule, ruleIndex)}
                        {!readonly &&
                            <div className={`d-flex align-self-start ms-auto ${classes['remove-rule-button']}`}>
                                <IconButton className="ps-3" iconClasses="fal fa-minus" onClick={() => onRemoveRule(rule, ruleIndex)} />
                            </div>
                        }
                    </div>
                )
            }).reduce((prev, current) => [prev, <span key={`${prev.key}${current.key}`} className="my-2"></span>, current])}
        </div>
    )
}

export default FilterRules