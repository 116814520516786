import { Route, Switch } from 'react-router-dom'
import { useFeatureToggle } from "invevo-react-components"
import NavigationButton from '../../library/buttons/NavigationButton/NavigationButton'

const SettingsRoutes = () => {
    const { isEnabled: isNewUserRolesEnabled } = useFeatureToggle("newUserRoles")

    return (
        <div className="d-flex w-100 h-100">
            <Switch>
                <Route path="/home/settings" exact render={() => 
                    <div className="d-flex justify-content-center align-items-center w-100">
                        <NavigationButton route="/home/integration" label="Integration" icon="fa-solid fa-file-import" ariaLabel="integration" colour="#ebe996" />
                        <NavigationButton route="/home/connect" label="Connect" icon="fa-solid fa-user-headset" ariaLabel="connect" colour="#b3dfe6" />
                        <NavigationButton route="/home/system-settings" label="System" icon="fa-solid fa-gears" ariaLabel="system-settings" colour="#e13814" />
                        <NavigationButton route="/home/users" label="Users" icon="fa-solid fa-users" ariaLabel="users" colour="#89d406" />
                        {isNewUserRolesEnabled && <NavigationButton route="/home/user-roles" label="User roles" icon="fa fa-user-gear" ariaLabel="user-roles" colour="#06c8d4" />}
                    </div>
                } />
            </Switch>
        </div>
    )
}

export default SettingsRoutes