import { useReducer } from 'react'
import workflowRulesReducer from '../../reducers/workflowRulesReducer'
import WorkflowDetails from '../workflowDetails/WorkflowDetails'
import Workflows from '../workflows/Workflows'
import { Tabs, Tab, useFeatureToggle } from 'invevo-react-components';
import WorkflowSetup from '../workflowSetup/WorkflowSetup'
import WorkflowRunsDashboard from '../workflowRuns/WorkflowRunsDashboard'
import { ComingSoon } from 'invevo-react-components'

const WorkflowsDashboard = () => {
    const { isEnabled: showAutomateWorklowRuns } = useFeatureToggle("showAutomateWorklowRuns")
    const [workflowRuleState, workflowRuleDispatch] = useReducer(
        workflowRulesReducer,
        {
            workflowRules: [],
            selectedWorkflowRule: {
                smsSendActions: [],
                customerFilters: []
            },
            customerData: { fieldConfigs: [], hasFetched: false },
            assessData: { hasCreditData: false, creditFields: [], dataConfiguration: [], hasFetched: false },
            collectData: { customerFields: [], dataConfiguration: [], hasFetched: false },
            calculateData: { calculateFields: [], hasFetched: false },
            smsTemplates: [],
            workflowRuns: [],
            selectedWorkflowRun: null,
            previewRequested: false,
            actionSuccess: "all",
            isNewRequested: false
        }
    )

    return (
        <div className="d-flex w-100 h-100">
            <Workflows
                state={workflowRuleState}
                dispatch={workflowRuleDispatch}
            />
            <Tabs className="flex-grow-1 p-4">
                <Tab title="Workflow Setup" isActive={true}>
                    <WorkflowSetup
                        state={workflowRuleState}
                        dispatch={workflowRuleDispatch}
                    />
                </Tab>
                <Tab title="Workflow Runs">
                    {showAutomateWorklowRuns
                        ?
                        <WorkflowRunsDashboard
                            state={workflowRuleState}
                            dispatch={workflowRuleDispatch}
                        />
                        :
                        <ComingSoon
                            className="w-75 mx-auto"
                            preview={<img className="img-fluid" src="/Workflow_Results_Preview.png" alt="Workflow results preview" />}
                        />
                    }
                </Tab>
            </Tabs>
            <WorkflowDetails
                state={workflowRuleState}
                dispatch={workflowRuleDispatch}
            />
        </div>
    )
}

export default WorkflowsDashboard