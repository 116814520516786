import React from 'react';
import { connect } from 'react-redux';
import { getApiUrl } from '../../legacy/constants';
import { getLookup } from '../../legacy/api/lookupApi';
import Select from 'react-select';
import { addFileContentToRequest, addValueToRequest } from '../../legacy/utility/upload';
import { showToastSuccessMessage, showToastErrorMessage } from '../../legacy/api/toasterApi';
import { webApiInterface } from '../../legacy/api/webApiInterface';
import { executeAuthAsyncGet } from '../../legacy/utility/asyncSupport';
import { widthStyle } from '../../legacy/utility/selectSupport';
import { some, isNil, find } from 'lodash';

export class CustomerAgreementTemplate extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = CustomerAgreementTemplate.defaultState();
  }

  static defaultState = () => ({
    canUpload: true,
    Id: null,
    overwrite: false,
    file: null,
    fileName: null
  });

  componentDidMount() {
    this.props.getLookup(this.props.client, 'lookup/brands', 'brands');
    this.props.getLookup(this.props.client, 'document/agreements', 'agreements');
  }

  updateState = (obj) => this.setState({ ...obj, canUpload: true });

  onFormSubmit = (e) => {
    e.preventDefault();

    const { Id, overwrite } = this.state;
    const agreementExists = this.agreementExistsForBrand(Id);

    if (!this.canSubmit(agreementExists, overwrite)) {
      return;
    }

    this.setState({ canUpload: false });
    const { file, fileName, fileType } = this.state;
    const reader = new FileReader();

    reader.onload = (e) => {
      const fileContents = e.currentTarget.result;
      if (fileContents.length > 9 * 1024 * 1024) {
        showToastErrorMessage('File is too large to upload.');
        return;
      }
      if (fileContents.length === 0) {
        showToastErrorMessage('File has no content.');
        return;
      }

      const data =
        addFileContentToRequest(
          'documentUploader',
          fileName,
          fileType,
          fileContents
        ) +
        addValueToRequest('brandId', Id) +
        addValueToRequest('fileName', fileName) +
        '--blob--';
      const XHR = new XMLHttpRequest();

      XHR.onreadystatechange = () => {
        this.form.reset();
        this.setState(CustomerAgreementTemplate.defaultState());

        if (XHR.readyState === 4) {
          if (XHR.status === 200) {
            showToastSuccessMessage('Uploaded.');
            this.props.getLookup(this.props.client, 'document/agreements', 'agreements');
          } else {
            const err = JSON.parse(XHR.response);
            showToastErrorMessage(
              'Error uploading file: ' + fileName + '. Error: ' + err.Message ||
              XHR.statusText
            );
          }
        }
      };

      XHR.open('POST', getApiUrl(this.props.client) + 'api/document/UploadAgreement');
      XHR.setRequestHeader(
        'Content-Type',
        'multipart/form-data; boundary=blob'
      );
      XHR.setRequestHeader('Access-Control-Allow-Origin', '*');
      this.props.executeAuthAsyncGet(
        this.props.client,
        'Lookup/OK',
        'CHECK_OK',
        null,
        function () {
          webApiInterface.applySecurity(XHR);
          XHR.send(data);
        }
      );
    };
    reader.readAsBinaryString(file);
  };

  agreementExistsForBrand = (brandId) => {
    const { agreements } = this.props;
    return some(
      agreements,
      (a) => (isNil(a.BrandId) && isNil(brandId)) || a.BrandId === brandId
    );
  };

  canSubmit = (agreementExists, overwrite) => {
    const { canUpload, fileName } = this.state;

    return !isNil(fileName) && canUpload && (!agreementExists || overwrite);
  };

  render() {
    const { brands } = this.props;
    const { Id, overwrite, selectedItem } = this.state;
    const agreementExists = this.agreementExistsForBrand(Id);
    const brandName = isNil(Id)
      ? 'No Brand'
      : find(brands, (b) => b.Id === Id).Name;

    return (
      <div>
        <h2>Upload Customer Agreement</h2>
        <form
          onSubmit={this.onFormSubmit}
          className="form-group well"
          ref={(me) => (this.form = me)}
        >
          <div>
            <p style={{ color: 'black' }}>Brand:</p>{' '}
            <Select
              name="brands-select"
              isMulti={false}
              value={selectedItem}
              options={brands.map((r) => ({
                label: r.Name,
                value: r.Id
              }))}
              onChange={(brand) =>
                this.updateState({
                  selectedItem: brand,
                  Id: (brand && brand.Id) || null
                })
              }
              isClearable
              styles={{ control: widthStyle(200) }}
            />
          </div>
          <div style={{ paddingTop: '12px' }}>
            Template:{' '}
            <input
              type="file"
              id="documentUploader"
              name="documentUploader"
              className="btn btn-default"
              onChange={({ target }) =>
                this.updateState({
                  file: target.files[0],
                  fileName: target.files[0].name,
                  fileType: target.files[0].type
                })
              }
              accept=".doc, .docx"
            />
          </div>
          <div>
            <label
              style={{
                color: 'black',
                visibility: agreementExists ? 'visible' : 'hidden'
              }}
            >
              Agreement exists for '{brandName}'. Check to overwrite:
            </label>
            <input
              style={{ visibility: agreementExists ? 'visible' : 'hidden' }}
              type="checkbox"
              checked={overwrite}
              onChange={() => this.updateState({ overwrite: !overwrite })}
            />
          </div>
          <div>
            <button
              type="submit"
              disabled={!this.canSubmit(agreementExists, overwrite)}
              className="btn btn-default"
            >
              Upload
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    brands: state.lookupReducer.brands.results,
    agreements: state.lookupReducer.agreements.results
  }),
  {
    getLookup,
    executeAuthAsyncGet
  }
)(CustomerAgreementTemplate);
