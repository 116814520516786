import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { addParent } from '../../api/vpApi';
import { showToastErrorMessage } from '../../api/toasterApi';
import { CommandCell } from '../common/grids/commandCell';
import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../common/grids/StatefulGrid';
import { getApiUrl } from '../../constants';
import { useClient } from 'invevo-react-components';
import { normaliseFormatDef } from '../../selectors/lookup';

const SearchChild = ({
  selectedAccount,
  currencyFormat,
  onChange,
  addParent,
  childRemoved,
  childAdded,
  childAddedErrMsg
}) => {
  const [searchBox, setSearchBox] = useState('');
  const [accountCode, setAccountCode] = useState('');
  
  const client = useClient();

  useEffect(() => {
    if (childRemoved || childAdded) {
      setSearchBox('');
      setAccountCode('');
    }
  }, [childRemoved, childAdded]);

  useEffect(() => {
    if (childAddedErrMsg) {
      showToastErrorMessage(childAddedErrMsg);
    }
  }, [childAddedErrMsg]);

  const onAddChild = (e, dataItem) => {
    e.preventDefault();
    addParent(client, dataItem.Id, selectedAccount.miaAccountId, false).then(() => {
      onChange();
      setSearchBox('');
      setAccountCode('');
    });
  };

  const searchChild = () => {
    setAccountCode(searchBox);
    setSearchBox('');
  };

  const additionalRequestPayload = {
    id: selectedAccount.miaAccountId,
    isVirtualParent: selectedAccount.miaAccountIsVirtualAccount,
    accountCode:
      accountCode === undefined || accountCode === '' ? '.' : accountCode
  };

  const onSearchChange = (e) => setSearchBox(e.target.value);

  const showResults = accountCode !== '';

  return (
    <div>
      <h3 className="m-2">Search for child</h3>
      <div className="m-1 form-row">
        <div>
          <input
            className="form-control"
            type="text"
            size="20"
            maxLength="100"
            value={searchBox}
            onChange={onSearchChange}
          />
        </div>
        <div>
          <button onClick={searchChild} className="btn btn-default ml-2">
            Search Account Code
          </button>
        </div>
      </div>

      {showResults && (
        <StatefulGrid
          client={client}
          path={getApiUrl(client) + 'api/VirtualAccount/GetChildByAccountCode'}
          additionalRequestPayload={additionalRequestPayload}
          defaultField={{ field: 'Date' }}
          pageable={{
            pageSizes: [50, 100, 200],
            buttonCount: 5
          }}
          hideablePaging={true}
          className={'custom-grid scrollable-none'}
          sortable={true}
        >
          <GridColumn field={'Name'} title={'Name'} />
          <GridColumn field={'AccountCode'} title={'Account Code'} />
          <GridColumn
            field={'CreditLimit'}
            title={'Credit Limit'}
            format={currencyFormat}
            className="text-right"
          />
          <GridColumn field={'CreditLimitCurrency'} title={'Currency'} />
          <GridColumn
            cell={CommandCell({
              onCommandClick: onAddChild,
              cssTag: 'hierarchy',
              buttonText: <i className='fa-solid fa-plus'></i>
            })}
            title={'Add'}
            width={'60px'}
          />
        </StatefulGrid>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    childRemoved: state.vpReducer.childRemoved,
    childAdded: state.vpReducer.childAdded,
    childAddedErrMsg: state.vpReducer.childAddedErrMsg,
    selectedAccount: state.currentSelectionReducer.selectedAccount,
    currencyFormat: normaliseFormatDef(
      state.lookupReducer.globalSettings.results.currencyFormat
    )
  };
};

export default connect(mapStateToProps, {
  addParent
})(SearchChild);
