import moment from "moment"
import IconButton from "../../buttons/IconButton/IconButton"
import { convertToCSV } from "../../helpers/entityHelpers"
import { useGrid } from "../Grid"
import { mergeOptimisticUpdatesIntoEntities } from "../util"
import { useDownload } from "../../../hooks/useDownload"

const ExportToCsvButton = () => {
    const grid = useGrid()
    const download = useDownload()

    const onExportToCsvClicked = () => {
        const currentData = mergeOptimisticUpdatesIntoEntities(grid.data.rows, grid.data.optimisticallyUpdatedRows)
        const csv = convertToCSV(currentData, grid.displayedHeaders, grid.lookups)
        download.fromStringToCsv(csv, `Extract_${moment().format("x")}.csv`)
    }

    return <IconButton ariaLabel="export-to-csv" tooltip="Export" icon="fa-light fa-file-csv" theme="blue-flat" onClick={onExportToCsvClicked} />
}

export default ExportToCsvButton
