import { Call } from "@twilio/voice-sdk"
import React, { useEffect, useState } from "react"

interface IVoipContext {
    inCall: Boolean
    setInCall: React.Dispatch<React.SetStateAction<Boolean>>
    call?: Call
    setCall: React.Dispatch<React.SetStateAction<Call | undefined>>
    autoDial: Boolean
    setAutoDial: React.Dispatch<React.SetStateAction<Boolean>>
}

const VoipContext = React.createContext<IVoipContext>({} as IVoipContext)

type VoipProviderProps = {
    children: JSX.Element | JSX.Element[]
}

const VoipProvider = ({ children }: VoipProviderProps) => {
    const [inCall, setInCall] = useState<Boolean>(false)
    const [call, setCall] = useState<Call>()
    const [autoDial, setAutoDial] = useState<Boolean>(false)

    useEffect(() => {
        if (!call) return

        const disconnect = () => {   
            call.disconnect()
        }

        return disconnect
    }, [call])

    return <VoipContext.Provider value={{ inCall, call, setInCall, setCall, autoDial, setAutoDial }}>{children}</VoipContext.Provider>
}

const useVoip = () => {
    const context = React.useContext(VoipContext)
    if (!context) {
        throw new Error(`useVoip must be used within a <VoipProvider />`)
    }
    return context
}

export { VoipProvider, useVoip }
