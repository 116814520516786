import * as types from '../actions/actionTypes';

export default function fileImportLocationReducer(state = [], action) {
    switch (action.type) {
        case types.LOAD_FILE_IMPORT_LOCATION_SUCCESS:
        case types.NEW_FILE_IMPORT_LOCATION_SUCCESS:
        case types.CREATE_FILE_IMPORT_LOCATION_SUCCESS:
        case types.UPDATE_FILE_IMPORT_LOCATION_SUCCESS:
        case types.DELETE_FILE_IMPORT_LOCATION_SUCCESS:
        case types.FILE_IMPORT_LOCATION_CANCEL:
            return [...state, Object.assign({}, action.fileImportLocation)];
        default:
            return state;
    }
}