import { FileImportRowDto } from "../../../types/dtos/FileImportRowDto"
import FailedField from "./FailedField"
import RowStatus from "./RowStatus"
import SuccessField from "./SuccessField"

type FileImportRowDetailsProps = {
    row: FileImportRowDto
}

const FileImportRowDetails = ({ row }: FileImportRowDetailsProps) => {
    return (
        <div className=" d-flex flex-column no-select px-3 my-2 py-2 bg-white text-dark-grey rounded">
            <div className="d-flex align-items-center me-3">
                <i className={`fal fa-align-justify text-blue me-2 fs-6`} />
                <span className="text-dark-grey fw-bold me-2">DATA ROW FROM FILE</span>
                <span> {row.rowData}</span>
            </div>

            <RowStatus status={row.status} />
            <hr className="my-0" />

            {row.fields.map(field => (
                <div className="d-flex w-100 align-items-center me-3" key={field.fieldName}>
                    {field.success ? (
                        <SuccessField fieldName={field.fieldName} value={field.value} />
                    ) : (
                        <FailedField fieldName={field.fieldName} value={field.value} />
                    )}
                </div>
            ))}
        </div>
    )
}

export default FileImportRowDetails
