import React from 'react';

export const IsDefaultRole = (props) => {
  return <td>{props.dataItem.IsDefaultRole && <span>X</span>}</td>;
};

export const IsFinancialRole = (props) => {
  return <td>{props.dataItem.IsFinancialRole && <span>X</span>}</td>;
};

export const HasTransactionFilters = (props) => {
  return <td>{props.dataItem.HasTransactionFilters && <span>X</span>}</td>;
};

export const FullCustomerView = (props) => {
  return <td>{props.dataItem.FullCustomerView && <span>X</span>}</td>;
};

export const DisplayAsInternalContact = (props) => {
  return <td>{props.dataItem.DisplayAsInternalContact && <span>X</span>}</td>;
};

export const IsDashboardContact = (props) => {
  return <td>{props.dataItem.IsDashboardContact && <span>X</span>}</td>;
};

export const UpperFinancialLimit = (props) => {
  const message = props.dataItem.upperFinancialLimit
    ? props.dataItem.upperFinancialLimit
    : 'N/A';

  return (
    <td>{props.dataItem.isFinancialPermission && <span>{message}</span>}</td>
  );
};

export const LowerFinancialLimit = (props) => {
  const message = props.dataItem.lowerFinancialLimit
    ? props.dataItem.lowerFinancialLimit
    : 'N/A';

  return (
    <td>{props.dataItem.isFinancialPermission && <span>{message}</span>}</td>
  );
};

export const DefaultCustomer = (props) => {
  return <td>{props.dataItem.DefaultCustomerContact && <span>X</span>}</td>;
};

export const EmailCustomer = ({ dataItem: { Email }, onEmailClick, tag }) => {
  return React.createElement(
    tag,
    null,
    <button title={Email} onClick={onEmailClick}>
      {'@'}
    </button>
  );
};

export const AssignedUser = (props) => {
  const user = props.dataItem.AssignedUserName;
  const multipleUsers =
    props.dataItem.AssignedToCount != null &&
    props.dataItem.AssignedToCount > 1;
  return (
    <td>
      {user}
      {multipleUsers && '...'}
    </td>
  );
};

export const IsUser = (props) => {
  return <td>{props.dataItem.isUser && <span>X</span>}</td>;
};

export const ClientConfigurable = (props) => {
  return <td>{props.dataItem.clientConfigurable && <span>X</span>}</td>;
};

export const IsInUse = (props) => {
  return <td>{props.dataItem.isInUse && <span>X</span>}</td>;
};

export const SystemRequired = (props) => {
  return <td>{props.dataItem.systemRequired && <span>X</span>}</td>;
};

export const IsEnabled = (props) => {
  return <td>{props.dataItem.enabled && <span>X</span>}</td>;
};
