import classes from "./SelectionFilters.module.scss"
import BlueButton from "../../../../../../library/buttons/BlueButton/BlueButton"
import { transactionAggregationTypes } from "../../../../enums/transactionAggregationTypes"
import { convertToCurrency } from "../../../../../../library/helpers/numberUtils"
import { useCustomer } from "../../../../../../contexts/CustomerContext"
import AppliedFilter from "./AppliedFilter"
import { useEffect, useState } from "react"

const SelectionFilters = ({ setIsApplyingFilters, aggregations, getCurrencyCode }) => {
    const onAddFilterClick = () => setIsApplyingFilters(true)
    const [customer, setCustomer] = useCustomer()

    const createOpenAggFilter = aggs => {
        const openAggAmount = aggs?.openStatusAggregation?.find(agg => agg.label === "open")?.totalByCurrency
        return {
            type: "OPEN_STATUS",
            label: "open",
            amountByCurrency: openAggAmount
                ? openAggAmount.map(a => {
                      return { ...a, currency: getCurrencyCode(a.currency) }
                  })
                : 0
        }
    }

    const [defaultAggregation, setDefaultAggregation] = useState(createOpenAggFilter(aggregations))

    useEffect(() => {
        setDefaultAggregation(createOpenAggFilter(aggregations))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aggregations, customer])

    const clearFilters = () => {
        setCustomer({
            transactionFilters: [createOpenAggFilter(aggregations)],
            selectedTransactions: []
        })
    }

    const filterSelected = () => {
        setCustomer({
            transactionFilters: customer.selectedTransactions.map(t => {
                return { type: "UNIQUE_TRANSACTION_REFERENCE", label: t.transactionRef }
            })
        })
    }

    const getDisplayNameFromAggregation = sa => {
        switch (sa.type) {
            case "OPEN_STATUS":
                return "Open status"
            case transactionAggregationTypes.AGED_DEBT:
                return "Aged debt"
            case transactionAggregationTypes.FALLING_DUE:
                return "Falling due"
            case transactionAggregationTypes.RECENT_PAYMENTS:
                return "Recent payments"
            case transactionAggregationTypes.TRANSACTION_STATUS:
                return "Statuses"
            case transactionAggregationTypes.TRANSACTION_TYPE:
                return "Types"
            case "TRANSACTION_REFERENCE":
            case "UNIQUE_TRANSACTION_REFERENCE":
                return "Reference"
            default:
                return sa.fieldName
        }
    }

    const getDisplayTextFromAggregation = sa => {
        if (sa.type === "TRANSACTION_REFERENCE" || sa.type === "UNIQUE_TRANSACTION_REFERENCE") {
            return `${getDisplayNameFromAggregation(sa)} | ${sa.label.toUpperCase()}`
        }
        if (sa.type === "OPEN_STATUS") {
            const openAggAmount = aggregations?.openStatusAggregation?.find(agg => agg.label === sa.label)?.totalByCurrency

            return `${getDisplayNameFromAggregation(sa)} | ${sa.label}${
                openAggAmount 
                    ? `: ${openAggAmount?.map(a => convertToCurrency(a.value.toFixed(2), a.currency)) 
                        ?? ""}`
                    : ""
            }`
        }
        return `${getDisplayNameFromAggregation(sa)} | ${sa.label}${
            sa.amountByCurrency ? `: ${sa.amountByCurrency.map(a => convertToCurrency(a.value.toFixed(2), a.currency))}` : ""
        }`
    }

    const onAggregationRemoved = aggregationToRemove => {
        const openAggregation = createOpenAggFilter(aggregations)
        const filteredAggregations = customer.transactionFilters.filter(a => a !== aggregationToRemove)
        setCustomer({
            transactionFilters:
                filteredAggregations.length !== 0
                    ? filteredAggregations
                    : JSON.stringify(openAggregation) === JSON.stringify(aggregationToRemove)
                    ? []
                    : [openAggregation]
        })
    }

    return (
        <div className={`w-100 rounded-top d-flex align-items-start p-1 ${classes.filters} overflow-auto`}>
            <div className={`d-flex align-items-center p-2 border-primary border-end`}>
                <span className="text-uppercase text-white me-2 no-select">Filters</span>
                <i className="text-white fal fa-filter"></i>
            </div>

            <div className="d-flex flex-wrap flex-grow-1 pt-2">
                {customer.transactionFilters.length > 0 ? (
                    customer.transactionFilters.map(sa => (
                        <AppliedFilter
                            key={`${sa.type}${sa.label}`}
                            ariaLabel="transaction-filter"
                            selectedAggregation={sa}
                            text={getDisplayTextFromAggregation(sa)}
                            showIcon={true}
                            onAggregationRemoved={onAggregationRemoved}
                        />
                    ))
                ) : (
                    <AppliedFilter
                        key={`${defaultAggregation.type}${defaultAggregation.label}`}
                        ariaLabel="transaction-filter"
                        selectedAggregation={defaultAggregation}
                        text={getDisplayTextFromAggregation(defaultAggregation)}
                        showIcon={true}
                        onAggregationRemoved={onAggregationRemoved}
                    />
                )}
            </div>
            <div className="d-flex m-2">
                {customer.selectedTransactions.length > 0 && (
                    <BlueButton className="ms-auto me-2" label="Filter on Selected" onClick={filterSelected} ariaLabel="filter-selected-button" />
                )}
                <BlueButton className="ms-auto me-2" label="Clear Filter" onClick={clearFilters} ariaLabel="clear-filters-button" />
                <BlueButton className="ms-auto" label="Filter By" onClick={onAddFilterClick} ariaLabel="add-filters-button" />
            </div>
        </div>
    )
}

export default SelectionFilters
