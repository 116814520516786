import classes from "./SearchInput.module.scss"

const SearchInput = ({ value, onChange }) => {
    return (
        <div className={`d-flex align-items-center justify-content-between form-control ${classes["input-wrapper"]}`}>
            <input
                type="text"
                className={`${classes.input} w-100`}
                value={value}
                onChange={event => onChange(event.target.value)}
                placeholder="Search"
            />
            <i className="fal fa-search" />
        </div>
    )
}

export default SearchInput