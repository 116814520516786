import { useEffect, useState } from "react"
import { useApi, useClient, Loading, DashboardTable } from "invevo-react-components"

const AccountStatus = ({ accountStatusGroups, setAccountStatusGroups }) => {
    const api = useApi()
    const client = useClient()
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)

    useEffect(() => {
        if (isFetching || hasFetched) {
            return
        }
        setIsFetching(true)
        api.get(`/api/${client}/account-status`)
            .then(response => {
                setHasFetched(true)
                setAccountStatusGroups(response.data.map(d => { return { ...d, isSelected: false, name: d.accountStatus } }))
                setIsFetching(false)
            })
            .catch(error => console.error(error))
    }, [api, client, hasFetched, isFetching, setAccountStatusGroups])

    return (
        <div className="d-flex w-100 h-100">
            <Loading isLoading={isFetching} colour="blue">
                <DashboardTable
                    data={accountStatusGroups}
                    setData={setAccountStatusGroups}
                    isFetching={isFetching}
                    contentType="accountStatus"
                    title="Account Status"
                />
            </Loading>
        </div>
    )
}

export default AccountStatus
