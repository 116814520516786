/*eslint no-console:0 */
import { isNil, isObject, isString } from 'lodash';
import { api } from '../constants';
import { showToastErrorMessage } from '../api/toasterApi';

const defaultErrorMessage = 'Unknown Error';

const devLog = (m) => {
  if (api.isDev && window.console) {
    console.log(m);
  }
};

// handles strings, json and javascript Error and normalises to { name: '', message: '' }
export const normaliseResponse = (response, callback) => {
  if (isString(response)) {
    devLog(response);

    return callback({ message: response });
  }

  if (response instanceof Error) {
    // JavaScript Error object.

    devLog(response.stack);

    return callback(response);
  }

  if (isObject(response) && !isNil(response.json)) {
    response
      .json()
      .then((payload) => {
        const errorMessage = payload.Message || defaultErrorMessage;
        const errorType = payload.ExceptionType || '';

        devLog(
          `${payload.ExceptionMessage || 'No ExceptionMessage'} ${payload.StackTrace || 'No StackTrace'
          }`
        );

        return callback({ name: errorType, message: errorMessage });
      })
      .catch((e) => {
        devLog(e);

        return callback({ message: defaultErrorMessage });
      });
  } else {
    devLog(response);

    return callback({ message: defaultErrorMessage });
  }
};

export const displayErrorMessage = (err) => {
  if (isObject(err) && err.message) {
    showToastErrorMessage(
      `${err.message} ${err.name ? '. Error code - ' + err.name : ''}`.trim()
    );
  } else {
    showToastErrorMessage(defaultErrorMessage);
  }
};

const displayError = (response) =>
  normaliseResponse(response, displayErrorMessage);

export default displayError;
