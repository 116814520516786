import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { sortBy, filter, includes } from 'lodash';

import { executeAuthAsyncGet } from '../../../utility/asyncSupport';
import { noOptionsMessage, optionStyles } from '../../../utility/selectSupport';
import { useClient } from 'invevo-react-components';

const FilterList = ({
  list,
  change,
  multi,
  value,
  tabIndex,
  getLookup,
  repcodes,
  executeAuthAsyncGet
}) => {
  const client = useClient()
  useEffect(() => {
    switch (list) {
      case 'salesRep':
        getLookup(client, 'lookup/RepCodes', 'repcodes');
        break;
      default:
        break;
    }
  }, [list, getLookup, client]);

  const select = (option) => {
    if (option) {
      change(option);
    } else {
      change(null);
    }
  };

  const getValuesSearchOptions = (input, callback) => {
    switch (list) {
      case 'customer':
        executeAuthAsyncGet(
          client,
          'customers',
          'SEARCH_CUSTOMERS',
          { searchTerm: input },
          function (customers) {
            const options = sortBy(customers, (c) => c.name).map((c) => {
              return { label: c.accountCode + ' - ' + c.name, value: c.id };
            });
            callback(null, { options: options });
          },
          function (/*error*/) {
            callback('Error searching for customers', null);
          }
        );
        break;
      case 'virtualAccount':
        executeAuthAsyncGet(
          client,
          'virtualaccounts',
          'SEARCH_VIRTUAL_ACCOUNTS',
          { searchTerm: input },
          function (virtualAccount) {
            const options = sortBy(virtualAccount, (c) => c.name).map((c) => {
              return { label: c.accountCode + ' - ' + c.name, value: c.id };
            });
            callback(null, { options: options });
          },
          function (/*error*/) {
            callback('Error searching for virtual accounts', null);
          }
        );
        break;
      case 'user':
        executeAuthAsyncGet(
          'users',
          'SEARCH_USERS',
          { searchTerm: input },
          function (user) {
            const options = sortBy(user, (c) => c.userName).map((c) => {
              return { label: c.userName, value: c.id };
            });
            callback(null, { options: options });
          },
          function (/*error*/) {
            callback('Error searching for users', null);
          }
        );
        break;
      case 'salesRep':
        {
          const options = sortBy(
            filter(repcodes, (f) => includes(f, input)),
            (c) => c.Description
          ).map((c) => {
            return { label: c.Description, value: c.Id };
          });

          callback(null, { options: options });
        }
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <AsyncSelect
        name="filter-values-search"
        isMulti={multi}
        value={value}
        loadOptions={getValuesSearchOptions}
        onChange={select}
        placeholder="Search for filter values"
        defaultOptions={[]}
        noOptionsMessage={noOptionsMessage}
        styles={{
          option: optionStyles
        }}
        tabIndex={tabIndex}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    repcodes: state.lookupReducer.repcodes
  };
};

export default connect(
  mapStateToProps,
  {
    executeAuthAsyncGet
  },
  null,
  { forwardRef: true }
)(FilterList);
