import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLookup } from '../../api/lookupApi.js';
import StatefulSelect from '../common/statefulSelect';
import { taskCompletionSelectList } from '../../selectors/task';
import { useClient } from 'invevo-react-components';

const TaskCompletionSelect = ({
  id,
  onChange,
  getLookup,
  taskTypeId = 0,
  taskComps
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const client = useClient()
  useEffect(() => {
    if (taskTypeId)
      getLookup(client, 'lookup/TaskCompletionsByTaskType', 'taskCompletions', {
        taskTypeId
      });
  }, [taskTypeId, getLookup, client]);

  return (
    <StatefulSelect options={taskComps} optionId={id} onChange={onChange} />
  );
};

TaskCompletionSelect.propTypes = {
  id: PropTypes.number,
  taskTypeId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  taskComps: taskCompletionSelectList(
    state.lookupReducer.taskCompletions.results
  )
});

export default connect(mapStateToProps, { getLookup })(TaskCompletionSelect);
