import { DragEvent } from "react"
import FlowNodeType, { getBackgroundForType, getDefaultTitleForType, getIconForType } from "../../types/FlowNodeType"
import classes from "./NodeOption.module.scss"

type NodeOptionProps = {
    nodeType: FlowNodeType
    onNodeDragStart: (event: DragEvent<HTMLDivElement>, nodeType: FlowNodeType) => void
}

const NodeOption = ({ nodeType, onNodeDragStart }: NodeOptionProps) => {
    const onDragStart = (event: DragEvent<HTMLDivElement>) => onNodeDragStart(event, nodeType)

    return (
        <div className="d-flex flex-column" draggable onDragStart={onDragStart}>
            <div className={`bg-${getBackgroundForType(nodeType).toLowerCase()} ${classes.nodeTopBorder} rounded-top`} />
            <div className={`d-flex align-items-center p-2 bg-grey rounded-bottom no-select ${classes.node}`}>
                <i className={`${classes.icon} ${getIconForType(nodeType)} text-center text-blue me-1 fs-5`} />
                <span className="text-black fw-bold small text-capitalize mx-1">{getDefaultTitleForType(nodeType)}</span>
            </div>
        </div>
    )
}

export default NodeOption
