import classes from "./Checkbox.module.scss"

type CheckboxProps = {
    isChecked: boolean
    onClick: () => void

    className?: string
    label?: string
    colour?: string
    disabled?: boolean
    checkboxClasses?: string
    ariaLabel?: string
    positionLabelLeft?: boolean
    labelColour?: string
    labelUpperCase?: boolean
}

const Checkbox = ({
    isChecked,
    onClick,
    className = "",
    label,
    colour = "grey",
    disabled = false,
    checkboxClasses = "",
    ariaLabel = label,
    positionLabelLeft = false,
    labelColour = "",
    labelUpperCase = true,
}: CheckboxProps) => {
    const onCheckboxClick = () => {
        if (disabled) return
        onClick()
    }

    const labelElement = (
        <span className={`no-select text-nowrap ${labelColour ? `text-${labelColour}` : ""} ${labelUpperCase ? "text-uppercase" : ""}`} aria-label={ariaLabel}>
            {label}
        </span>
    )

    return (
        <div
            className={`d-flex ${className ? className : ""} align-items-center  text-${colour} ${disabled ? `not-allowed ${classes.disabled}` : "pointer"}`}
            role="checkbox"
            aria-label={ariaLabel}
            aria-checked={isChecked}
            onClick={onCheckboxClick}
        >
            {label && positionLabelLeft && <div className="me-2">{labelElement}</div>}
            <div className={`${classes.checkbox} d-flex align-items-center ${checkboxClasses}`}>
                {isChecked && (
                    <span className="mx-auto">
                        <i className="fa fa-check"></i>
                    </span>
                )}
            </div>
            {label && !positionLabelLeft && <div className="ms-2">{labelElement}</div>}
        </div>
    )
}

export default Checkbox
