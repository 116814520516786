import { ComingSoon } from "invevo-react-components"

const UserProfile = () => {
    return (
        <div className="d-flex w-100 h-100 p-4">
            <ComingSoon />
        </div>
    )
}

export default UserProfile