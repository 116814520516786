import DataConfiguration from "../../../../microfrontends/data/components/dataFields/DataConfiguration"


const DataFields= () => {
    return (
        <div className="d-flex w-100 h-100">
            <DataConfiguration />
        </div>
    )
}

export default DataFields