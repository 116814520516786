import DataFieldDropdown from "../../../../../library/dropdowns/DataFieldDropdown"
import DataField from "../../../../../types/DataField"
import DataPrimitive from "../../../../../types/DataPrimitive"
import { useState } from "react"
import FieldType from "../../../../../types/enums/FieldType"

type UpdateEntityDefinitionProps = {
    id: string
    parentEntityFields: DataField[]
    selectedParentValue: string
    parentRuleReference: string
    childEntityFields: DataField[]
    selectedChildField: DataField | undefined
    selectedChildDropdown: { label: string; value: string }
    onDataFieldDefinitionUpdated: (id: string, field: DataField | undefined, value: string) => void
    onDataFieldDefinitionRemoved: (id: string) => void
}

const UpdateEntityDefinition = ({
    id,
    parentEntityFields,
    selectedParentValue,
    parentRuleReference,
    childEntityFields,
    selectedChildField,
    selectedChildDropdown,
    onDataFieldDefinitionUpdated,
    onDataFieldDefinitionRemoved
}: UpdateEntityDefinitionProps) => {
    const getInputValues = () => {
        return parentEntityFields.find(dataField => dataField.value === selectedParentValue)
    }

    const [parentDropdown, setParentDropdown] = useState<DataField | undefined>(getInputValues())

    const onFieldSelected = (field: DataField | undefined) => {
        onDataFieldDefinitionUpdated(id, field, parentDropdown?.value || "")
    }

    const emptyDataField: DataField = {
        value: "",
        label: "",
        type: DataPrimitive.TEXT,
        fieldType: FieldType.ENTITY
    }

    const setParentEntityDropdown = (field: DataField | undefined) => {
        setParentDropdown(field)
        onDataFieldDefinitionUpdated(id, emptyDataField, parentDropdown?.value || "")
    }

    const onRemoveClicked = () => onDataFieldDefinitionRemoved(id)

    const getType = (type: DataPrimitive) => {
        if (
            (parentDropdown?.type === DataPrimitive.TEXT || parentDropdown?.type === DataPrimitive.LOOKUP) &&
            (type === DataPrimitive.TEXT || type === DataPrimitive.LOOKUP)
        ) {
            return true
        } else {
            return type === parentDropdown?.type
        }
    }

    const removeDefaultFields = (fields: DataField[]) => {
        return fields.filter(field => field.value !== "client" && field.value !== "entity_type" && field.value !== "reference" && field.value !== "archived")
    }

    return (
        <div className="d-flex align-items-between gap-2">
            <div className="d-flex gap-2 align-items-center me-auto">
                <span>
                    <b>{parentRuleReference}</b> field
                </span>
                <DataFieldDropdown
                    ariaLabel="parent-field-dropdown"
                    options={removeDefaultFields(parentEntityFields)}
                    onOptionSelected={setParentEntityDropdown}
                    selectedOption={parentDropdown}
                />
                <span className="text-grey mx-2">
                    rolls down to <b>{selectedChildDropdown.label}</b> field
                </span>
                <DataFieldDropdown
                    ariaLabel="child-field-dropdown"
                    options={removeDefaultFields(childEntityFields.filter(field => getType(field.type)))}
                    onOptionSelected={onFieldSelected}
                    selectedOption={selectedChildField}
                />
            </div>
            <div className="d-flex gap-2 align-items-center">
                <div className="ms-auto ps-2 me-3 border-end align-self-stretch" />
                <div role="button" className="px-2" onClick={onRemoveClicked} aria-label="filter-remove">
                    <i className="fal fa-trash-alt" />
                </div>
            </div>
        </div>
    )
}

export default UpdateEntityDefinition
