import { useState, useEffect, useReducer } from 'react'
import { useApi, useClient, Loading, Alert, useConfig } from 'invevo-react-components'
import taskListReducer from '../../../reducers/taskListReducer'
import { actionTypes } from '../../../reducers/actionTypes'
import CustomerTaskList from './CustomerTaskList'
import CreateTaskForm from '../CreateTaskForm'
import { useHistory } from 'react-router-dom'

const TaskConfigDashboard = () => {
    const api = useApi()
    const client = useClient()
    const history = useHistory()
    const config = useConfig()

    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [hasErrored, setHasErrored] = useState(false)

    const [isCreatingTask, setIsCreatingTask] = useState(false)

    const [state, dispatch] = useReducer(
        taskListReducer,
        {
            tasks: [],
            taskTypes: [],
            taskCompletionTypes: [],
            users: [],
            roles: [],
            customerFields: [],
            hasFetchedTasks: false,
            selectedTask: null
        }
    )

    const customerRef = new URLSearchParams(history.location.search).get('customerRef')

    useEffect(() => {
        if (isFetching || hasFetched || hasErrored || !config.TASK_API_URL) return

        setIsFetching(true)

        const taskTypesPromise = api.get(`${config.TASK_API_URL}/api/${client}/task-types`)
            .then(response => dispatch({ type: actionTypes.TYPES_RETRIEVED, taskTypes: response.data }))

        const taskCompletionTypesPromise = api.get(`${config.TASK_API_URL}/api/${client}/task-completion-types`)
            .then(response => dispatch({ type: actionTypes.COMPLETION_TYPES_RETRIEVED, taskCompletionTypes: response.data }))

        const usersPromise = api.get(`${config.TASK_API_URL}/api/${client}/client-users`)
            .then(response => dispatch({ type: actionTypes.USERS_RETRIEVED, users: response.data }))

        const rolesPromise = api.get(`${config.TASK_API_URL}/api/${client}/roles`)
            .then(response => dispatch({ type: actionTypes.ROLES_RETRIEVED, roles: response.data }))

        const customerFields = api.get(`${config.TASK_API_URL}/api/${client}/autocomplete-customer-fields`)
            .then(response => dispatch({ type: actionTypes.CUSTOMER_FIELDS_RETRIEVED, customerFields: response.data }))

        Promise.all([taskTypesPromise, taskCompletionTypesPromise, usersPromise, rolesPromise, customerFields])
            .then(_ => {
                setHasFetched(true)
                setIsFetching(false)
                setHasErrored(false)
            }).catch(error => {
                setHasFetched(true)
                setIsFetching(false)
                setHasErrored(true)
                console.error(error)
            })
    }, [api, client, config.TASK_API_URL, hasErrored, hasFetched, isFetching])

    return (
        <div className="d-flex h-100 w-100">
            <Loading isLoading={isFetching || !hasFetched} colour="blue">
                <div className="d-flex flex-grow-1 flex-column p-3 text-grey">
                    {
                        hasErrored ?
                            <Alert className="m-3" type="error" iconClasses="fal fa-exclamation-triangle" text="Failed to get customer tasks. You might not have the correct permissions" /> :
                            isCreatingTask ?
                                <CreateTaskForm customerRef={customerRef} users={state.users} roles={state.roles} taskTypes={state.taskTypes} setIsCreatingTask={setIsCreatingTask} dispatch={dispatch} /> :
                                <CustomerTaskList state={state} dispatch={dispatch} customerRef={customerRef} setIsCreatingTask={setIsCreatingTask} />
                    }
                </div>
            </Loading>
        </div>
    )
}

export default TaskConfigDashboard