import LineChart from "../../../../../library/charts/LineChart"
import { widgetThemes } from "../../../enums/widgetThemes"
import { getBucketChartTypeFromAggs, getTitleFromWidgetConfig } from "./helpers"

const LineChartDisplayWidget = ({ state }) => {
    const { widget, aggregations } = state

    const data = getBucketChartTypeFromAggs(aggregations, widget.bucketChartType)

    return (
        <LineChart
            className="w-100 h-100"
            title={widget.displayName}
            colour={widget.theme === widgetThemes.DEFAULT ? "white" : "blue"}
            data={{
                xlabels: data.map(a => a.label),
                data: [
                    [getTitleFromWidgetConfig(widget.bucketChartType), ...data.map(a => a.total)]
                ]
            }}
        />
    )
}

export default LineChartDisplayWidget