import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  executeAuthAsyncGet,
  executeAuthAsyncPost
} from '../../utility/asyncSupport';
import { getApiUrl } from '../../constants';
import { webApiInterface } from '../../api/webApiInterface';
import { selectReplyToEmail } from '../../actions/functionActions';
import Nav from '../../api/navApi';
import { showToastErrorMessage } from '../../api/toasterApi';
import { provideIntlService } from '@progress/kendo-react-intl';
import moment from "moment";

const AbortController = window.AbortController;

class EMailNotesHistoryDisplay extends Component {
  controller = new AbortController();

  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
      from: '',
      to: '',
      cc: '',
      subject: '',
      body: '',
      attachments: [],
      theHeight: 300
    };
  }

  componentDidMount() {
    const parameters = {
      noteId: this.props.noteId,
      customerId: this.props.selectedAccount.miaAccountId,
      emailTrackingId: this.props.emailTrackingId,
      markAsRead: true
    };

    this.props.executeAuthAsyncGet(
      this.props.client,
      'Note/GetEmailTracking',
      'GET_EMAIL_TRACKING',
      parameters,
      (result) => {
        this.setState({
          loading: false,
          from: result.from,
          to: result.to,
          cc: result.cc,
          subject: result.subject,
          body: result.body,
          attachments: result.attachments,
          read: true
        });

        const content = result.body;
        this.frame.contentWindow.document.write(content);

        setTimeout(() => {
          const body = this.frame.contentWindow.document.body;
          if (body !== null)
            this.setState({ theHeight: body.scrollHeight + 2 });
        }, 10);
      },
      () => {
        showToastErrorMessage(`Error while loading email`);
      },
      this.controller.signal
    );
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  markAsUnread = () => {
    this.props.executeAuthAsyncPost(
      this.props.client,
      'Note/MarkEmailAsUnread',
      'POST_MARK_EMAIL_AS_UNREAD',
      this.props.emailTrackingId,
      () => {
        this.setState({
          read: false
        });
      }
    );
  };

  createMarkup() {
    return {
      __html: this.state.body
    };
  }

  downloadAttachment(attachment) {
    const url =
      getApiUrl(this.props.client) +
      'api/Note/DownloadEmailTrackingAttachment?noteId=' +
      this.props.noteId +
      '&customerId=' +
      this.props.selectedAccount.miaAccountId +
      '&emailTrackingId=' +
      this.props.emailTrackingId +
      '&filename=' +
      attachment.filename;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const a = document.createElement('a');
      a.style = 'display: none';
      const url = URL.createObjectURL(xhr.response);
      a.href = url;
      a.download = attachment.originalname;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 100);
    };

    this.props.executeAuthAsyncGet(this.props.client, 'Lookup/OK', 'CHECK_OK', null, function () {
      webApiInterface.applySecurity(xhr);
      xhr.send(null);
    });
  }

  onClickReply = (type) => () => {
    const formattedDate = moment.utc(this.props.noteEpochTime).format("YYYY-MM-DD HH:mm:ss.SSS")

    this.props.selectReplyToEmail({
      emailTrackingId: this.props.emailTrackingId,
      noteId: this.props.noteId,
      emailDate: formattedDate,
      customerId: this.props.customerId,
      type: type
    });

    this.props.navToEmailReply({
      emailTrackingId: this.props.emailTrackingId,
      noteId: this.props.noteId,
      emailDate: formattedDate,
      customerId: this.props.customerId,
      type: type
    });
  };

  renderButtons() {
    const buttons = [
      {
        id: 'back',
        text: 'Back',
        onClick: this.props.onClose
      }
    ];

    if (this.props.canReply) {
      buttons.push({
        id: 'reply',
        text: 'Reply',
        onClick: this.onClickReply('reply')
      });
      buttons.push({
        id: 'reply-all',
        text: 'Reply All',
        onClick: this.onClickReply('reply-all')
      });
    }

    if (this.props.canReply && this.state.read) {
      buttons.push({
        id: 'unread',
        text: 'Mark As Unread',
        onClick: this.markAsUnread
      });
    }

    return buttons.map((b) => (
      <button
        onClick={b.onClick}
        key={b.id}
        className="btnDefault btn btn-default"
      >
        {b.text}
      </button>
    ));
  }

  renderArchiveHeader(includeAttachemnets = false) {
    let attachmentDetails = '';
    if (includeAttachemnets) {
      let taskAttachmentsListWrapper = <div>No attachments.</div>;
      const attachments = this.state.attachments;

      if (attachments.length > 0) {
        const taskAttachmentsList = attachments.map((a) => {
          return (
            <div key={a.filename}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={this.downloadAttachment.bind(this, a)}>
                {a.originalname}
              </a>
            </div>
          );
        });

        taskAttachmentsListWrapper = (
          <div id="task-document-list">{taskAttachmentsList}</div>
        );
      }

      attachmentDetails = (
        <div>
          <div className="boldTitle">Attachments</div>
          <div className="text-info">{taskAttachmentsListWrapper}</div>
        </div>
      );
    }

    return (
      <div className="well">
        <div className="boldTitle">Date</div>
        <div className="text-info">
          {provideIntlService(this).formatDate(this.props.emailDate, 'F')}
        </div>
        <div className="boldTitle">Subject</div>
        <div className="text-info">{this.state.subject}</div>
        <div className="boldTitle">From</div>
        <div className="text-info">{this.state.from}</div>
        <div className="boldTitle">To</div>
        <div className="text-info">{this.state.to}</div>
        {this.state.cc && (
          <div>
            <div className="boldTitle">Cc</div>
            <div className="text-info">{this.state.cc}</div>
          </div>
        )}
        {attachmentDetails}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderButtons()}

        <h2>Email Archive</h2>

        {this.renderArchiveHeader(true)}

        <iframe
          title="email"
          ref={(me) => (this.frame = me)}
          className="email-display-iframe"
          style={{ height: this.state.theHeight + 'px' }}
        ></iframe>
      </div>
    );
  }
}
EMailNotesHistoryDisplay.propTypes = {
  emailDate: PropTypes.instanceOf(Date).isRequired,
  onClose: PropTypes.func.isRequired,
  canReply: PropTypes.bool
};

EMailNotesHistoryDisplay.defaultProps = {
  canReply: false
};

function mapStateToProps(state) {
  return {
    selectedAccount: state.currentSelectionReducer.selectedAccount,
    permissions: state.authReducer.permissions
  };
}

const mapDispatchToEvents = (dispatch) => {
  return {
    navTo: {
      EmailReply: () => dispatch(Nav.Workspace.Email.Reply())
    },
    executeAuthAsyncGet: function () {
      return dispatch(executeAuthAsyncGet.apply(null, arguments));
    },
    executeAuthAsyncPost: function () {
      return dispatch(executeAuthAsyncPost.apply(null, arguments));
    },
    selectReplyToEmail: (replyToEmail) =>
      dispatch(selectReplyToEmail(replyToEmail))
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToEvents)(EMailNotesHistoryDisplay)
);
