import { useMemo, useState } from "react"
import TransactionRow from "./TransactionRow"
import classes from "./Transactions.module.scss"
import { DirectIdTransactionDto } from "../../types/dtos/CreditCircleTransactionsDto"
import moment from "moment"

type TransactionsProps = {
    transactions: DirectIdTransactionDto[]
}

const Transactions = ({ transactions }: TransactionsProps) => {
    const [filterOptions, setFilterOptions] = useState([
        { label: "12 Months", isSelected: true, months: 12 },
        { label: "6 Months", isSelected: false, months: 6 },
        { label: "3 Months", isSelected: false, months: 3 },
        { label: "Last Month", isSelected: false, months: 1 }
    ])

    const selectOption = (option: any) => {
        const updatedFilterOptions = filterOptions.map(item => {
            if (item.label === option.label) {
                return {
                    ...item,
                    isSelected: true
                }
            }

            return {
                ...item,
                isSelected: false
            }
        })

        setFilterOptions(updatedFilterOptions)
    }

    const columns = [
        { key: "payee", label: "Payee" },
        { key: "category", label: "Category" },
        { key: "amount", label: "Amount" },
        { key: "dateTime", label: "Date" }
    ]

    const numberOfMonthsToDisplay = useMemo(() => {
        const selectedFilterOption = filterOptions.find(fo => fo.isSelected)
        return selectedFilterOption?.months ?? 0
    }, [filterOptions])

    const transactionsForPeriod = useMemo(() => {
        const startOfPeriod = moment().subtract(numberOfMonthsToDisplay, "months")
        const transactionsToReturn = transactions
        return transactionsToReturn.filter((transaction: DirectIdTransactionDto) => {
            if (!transaction.bookingDate) {
                return true
            }

            return moment(transaction.bookingDate).isAfter(startOfPeriod)
        })
    }, [transactions, numberOfMonthsToDisplay])

    return (
        <div className="d-flex flex-column w-100 h-100">
            <div className={`d-flex w-100 mb-4 rounded ${classes.periods}`}>
                {filterOptions.map(option => (
                    <div
                        className={`flex-grow-1 py-2 text-center pointer no-select ${option.isSelected ? classes.isSelected : ""} rounded`}
                        key={option.label}
                        onClick={_event => selectOption(option)}
                    >
                        <span className="text-black font-weight-bold">{option.label}</span>
                    </div>
                ))}
            </div>
            <div className="w-100 mt-3 flex-grow-1 table-responsive overflow-none">
                <table className="table">
                    <thead className="bg-grey border-0 position-sticky top-0">
                        <tr className="text-grey">
                            <th className="border-0"></th>
                            {columns.map((column, index) => {
                                return (
                                    <th key={column.key} className={`${index === 0 ? "ps-3" : ""} border-0`}>
                                        <span>{column.label}</span>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {transactionsForPeriod.map(transaction => (
                            <TransactionRow key={transaction.transactionId} transaction={transaction} currency={"EUR"} />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Transactions
