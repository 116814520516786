import { AutoComplete } from "invevo-react-components"
import React from "react"
import Checkbox from "../../../../../library/form/Checkbox"
import Input from "../../../../../library/Inputs/Input"
import {
    CommonFileExportConfigState,
    ExportFieldConfigState,
    FileExportConfigAction,
    FileExportConfigActionEnum
} from "../../../reducers/FileExportConfigReducer"
import { FileImportConfigColumnType, FileImportConfigColumnTypeEnum } from "../../../types/FileImportConfigColumnType"
import { FileFormat, delimiters, lineEndings } from "../../utils"
import classes from "./DataFileExportOptions.module.scss"

type DataFileExportOptionsProps = {
    className?: string
    state: CommonFileExportConfigState
    fileFormat: String
    dispatch: React.Dispatch<FileExportConfigAction>
}

const DataFileExportOptions = ({ className, state, fileFormat, dispatch }: DataFileExportOptionsProps) => {
    const isXmlExport = fileFormat === FileFormat.XML

    const onFilenameChange = (filename: string) => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            dataFileExportConfig: {
                ...state,
                filename
            }
        })
    }

    const onDelimiterChange = (option: string) => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            dataFileExportConfig: {
                ...state,
                delimiter: option
            }
        })
    }

    const onLineEndingChange = (option: string) => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            dataFileExportConfig: {
                ...state,
                lineEnding: option
            }
        })
    }

    const onHasHeaderRowChange = () => {
        const hasHeaderRow = isXmlExport || !state.hasHeaderRow
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            dataFileExportConfig: {
                ...state,
                hasHeaderRow: hasHeaderRow,
                optionalFieldConfigs: [
                    ...state.optionalFieldConfigs.map((field: ExportFieldConfigState) => ({
                        ...field,
                        column: {
                            ...field.column,
                            ...(hasHeaderRow
                                ? {
                                      type: FileImportConfigColumnTypeEnum.HEADER,
                                      header: ""
                                  }
                                : {
                                      type: FileImportConfigColumnTypeEnum.INDEX,
                                      index: ""
                                  })
                        } as FileImportConfigColumnType
                    }))
                ]
            }
        })
    }

    const onQuoteFieldsWithChange = (quoteFieldsWith: string) => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            dataFileExportConfig: {
                ...state,
                quoteFieldsWith
            }
        })
    }

    return (
        <div className={`d-flex flex-column w-100 h-100 ${className ? className : ""}`}>
            <div className="d-flex my-1 mx-3">
                <span className="text-grey fs-4">Data file configuration</span>
            </div>
            <div className={`${classes.horizontalDivider} mx-2`} />
            <div className="d-flex p-3">
                <div className="d-flex flex-column small me-3">
                    <span className="text-uppercase small mb-1 ms-1 text-grey">Filename </span>
                    <Input placeholder="Please enter the filename" value={state.filename} onChange={onFilenameChange} />
                </div>
                {!isXmlExport && (
                    <div className={`d-flex flex-column small me-3`}>
                        <span className="text-uppercase small mb-1 ms-1 text-grey">Delimiter</span>
                        <AutoComplete
                            ariaLabel="delimiter"
                            placeholder="Please select a delimiter"
                            options={delimiters}
                            initialValue={state.delimiter}
                            onChange={onDelimiterChange}
                        />
                    </div>
                )}
                {!isXmlExport && (
                    <div className={`d-flex flex-column small me-3`}>
                        <span className="text-uppercase small mb-1 ms-1 text-grey">Line ending</span>
                        <AutoComplete
                            placeholder="Please select a line ending"
                            options={lineEndings}
                            initialValue={state.lineEnding}
                            onChange={onLineEndingChange}
                        />
                    </div>
                )}
                {!isXmlExport && (
                    <div className="d-flex flex-column small">
                        <span className="text-uppercase small mb-1 ms-1 text-grey"> Quote Fields With </span>
                        <Input
                            className="small me-4"
                            placeholder="(Optional) Quote fields with"
                            value={state.quoteFieldsWith ?? ""}
                            onChange={onQuoteFieldsWithChange}
                        />
                    </div>
                )}
            </div>
            <div className={`${classes.horizontalDivider} mx-2`} />
            <div className="d-flex align-items-center p-3">
                <Checkbox
                    className="small me-4"
                    isChecked={isXmlExport || state.hasHeaderRow}
                    label="Has header row"
                    onClick={onHasHeaderRowChange}
                    ariaLabel="Has header row"
                    disabled={isXmlExport}
                />
            </div>
        </div>
    )
}

export default DataFileExportOptions
