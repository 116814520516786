import { map, groupBy, toPairs } from 'lodash';
import React from 'react';
import AccountCard from './accountCard';
import AccountDetail from './accountDetail';

const InternalContactsCard = ({ contacts, title, open, setOpen }) => {
  return (
    <AccountCard title={title} open={open} setOpen={setOpen}>
      {map(toPairs(groupBy(contacts, 'Role')), (contactByRole, j) => (
        <AccountDetail key={'contact-role-' + j}>
          <h6>{contactByRole[0]} Contacts</h6>

          {map(contactByRole[1], (c, i) => (
            <div
              key={'contact-role-' + j + i}
              className="d-flex flex-wrap justify-content-between"
            >
              <div>{c.Name}</div>
              <div className="text-right">{c.Phone}</div>
            </div>
          ))}
        </AccountDetail>
      ))}
    </AccountCard>
  );
};

export default InternalContactsCard;
