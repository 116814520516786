import moment from "moment"
import { useState } from "react"
import { DATE_FORMAT_WITH_TIME, DEFAULT_TIMEZONE } from "../helpers/dateUtils"
import useClickOutsideRef from "../../hooks/useClickOutsideRef"
import classes from "./DatePickerWithInput.module.scss"
import { DatePicker } from "invevo-react-components"
import { useFeatureToggle } from "../../hooks/useFeatureToggle"

type DatePickerWithInputProps = {
    date?: Date
    format?: string
    onDateChange: (date: Date) => void
    disabled?: boolean
    ariaLabel?: string
    timezone?: string
}

export const DatePickerWithInput = ({
    date,
    onDateChange,
    disabled = false,
    format = "DD MMMM YYYY",
    ariaLabel = "",
    timezone = DEFAULT_TIMEZONE
}: DatePickerWithInputProps) => {
    const [isSelected, setIsSelected] = useState(false)
    const ref = useClickOutsideRef(() => setIsSelected(false))

    const { isEnabled: clearDateFieldToEntity } = useFeatureToggle("permClearDateFieldSqlToEntity")
    const { isEnabled: isEntityEnabled } = useFeatureToggle("newEntityStructure")

    const shouldClearDateField = clearDateFieldToEntity && isEntityEnabled

    const [inputDate, setInputDate] = useState(
        date ? (shouldClearDateField && Math.max(moment(date).valueOf(), 0) === 0 ? "" : moment(date)?.tz(timezone).format(format)) : ""
    )

    const onFocus = () => {
        setIsSelected(true)
        if (shouldClearDateField && (date === undefined || Math.max(moment(date).valueOf(), 0) === 0)) {
            const today = new Date()
            onDateChange(today)
            setInputDate(moment(today).tz(timezone).format(format))
        }
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const valueDate = moment(value, format, undefined, true)

        if (valueDate.isValid()) {
            onDateChange(valueDate.toDate())
            setInputDate(event.target.value)
            return
        }

        setInputDate(event.target.value)
    }

    const onChangeClick = (date: Date) => {
        onDateChange(date)

        setInputDate(moment.tz(moment(date).format(DATE_FORMAT_WITH_TIME), timezone).format(format))
        setIsSelected(false)
    }

    const datePassedToPicker = date ? new Date(moment(date)?.tz(timezone).format(format)) : undefined

    const onRemove = () => {
        onDateChange(new Date(moment(0).format()))
        setInputDate("")
    }

    return (
        <div className="d-flex">
            <div ref={ref} className="position-relative" onFocus={onFocus}>
                <input
                    className={`form-control ${classes.input}`}
                    role="button"
                    value={inputDate}
                    onChange={onChange}
                    disabled={disabled}
                    aria-label={ariaLabel}
                />

                {isSelected && (
                    <div className={`position-absolute ${classes.onTop}`}>
                        <DatePicker selectedDate={datePassedToPicker} onChange={onChangeClick} />
                    </div>
                )}
            </div>

            {shouldClearDateField && datePassedToPicker && (
                <button
                    className={`text-nowrap d-flex fs-6 px-2 align-items-center align-middle text-white `}
                    onClick={onRemove}
                    aria-label="remove-date-value"
                >
                    <i aria-label="remove" className={`mx-1 pointer fas fa-times-circle`} />
                </button>
            )}
        </div>
    )
}

export default DatePickerWithInput
