import { actionTypes } from '../../../reducers/actionTypes'
import { FieldDataTypes } from 'invevo-react-components'
import WidgetInput from '../forms/WidgetInput'
import classes from './InformationWidget.module.scss'
import { widgetThemeDropdownOptions, widgetThemes } from '../../../enums/widgetThemes'
import EntityDataFieldDropdown from '../../../../../library/dropdowns/EntityDataFieldDropdown'
import { DataTypeEnum } from '../../../../../routes/entityConfig/types/DataType'
import { DashboardConfigAction } from '../../../reducers/dashboardConfigsReducer'
import EntityDataField from '../../../../../routes/entityConfig/types/EntityDataField'
import { ValueWidgetState } from '../../../types/DashboardConfigState'
import DataField from '../../../../../types/DataField'
import DataFieldDropdown from '../../../../../library/dropdowns/DataFieldDropdown'
import Dropdown from '../../../../../library/dropdowns/Dropdown'
import DropdownOption from '../../../../../types/DropdownOptions'

type ValueWidgetProps = {
    state: { 
        customerFields: DataField[] | EntityDataField[]
        widget: ValueWidgetState
        showValidationErrors: boolean
        isNewEntityStructureEnabled: boolean
    }
    className?: string
    dispatch: React.Dispatch<DashboardConfigAction>
}
const ValueWidget = ({ className, state, dispatch }: ValueWidgetProps) => {
    const { customerFields, widget, showValidationErrors, isNewEntityStructureEnabled } = state

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: actionTypes.VALUE_WIDGET_NAME_CHANGED, widget, newName: event.target.value })

    const onFieldNameChange = (option: DataField | EntityDataField | undefined) => {
        if (!option) return

        dispatch({ type: actionTypes.VALUE_WIDGET_FIELD_NAME_SET, widget, newField: option })
    }

    const onThemeChange = (themeOption: DropdownOption<widgetThemes>) => dispatch({ type: actionTypes.VALUE_WIDGET_THEME_CHANGED, widget, newTheme: themeOption.value })

    const onRemoveClick = () => dispatch({ type: actionTypes.WIDGET_REMOVED, widget })

    const getInitialOption = (field: any) => field.value ? field : undefined

    const numericAndDateCustomerFields = (customerFields as DataField[]).filter(f => (f.type === FieldDataTypes.NUMBER || f.type === FieldDataTypes.DATE))
    const numericAndDateEntityFields = (customerFields as EntityDataField[]).filter(
        f => f.dataType.type === DataTypeEnum.DECIMAL ||
            f.dataType.type === DataTypeEnum.MONEY ||
            f.dataType.type === DataTypeEnum.INT ||
            f.dataType.type === DataTypeEnum.DATE
    )
    const widgetField = widget.field
    return (
        <div className={`${className ? className : ''} d-flex flex-column ${classes.widget} bg-white p-2 text-grey overflow-auto`}>
            <div className="d-flex align-items-center border-bottom pb-2">
                <i className="fal fa-percent me-2"></i>
                <WidgetInput
                    className="flex-grow-1"
                    value={widget.displayName}
                    placeholder="Please enter name"
                    onChange={onNameChange}
                    isValid={!showValidationErrors || widget.displayName !== ""}
                />
                <i className="fal fa-times ms-3 me-1 pointer" onClick={onRemoveClick}></i>
            </div>
            <div className='d-flex align-items-center justify-content-between my-2 px-2'>
                <span className='me-2 text-uppercase small'>Theme:</span>
                <Dropdown
                    ariaLabel="widgetThemeDropdown"
                    options={widgetThemeDropdownOptions}
                    selectedOption={widgetThemeDropdownOptions.find(o => o.value === widget.theme)}
                    onOptionSelected={onThemeChange}
                />
            </div>

            {isNewEntityStructureEnabled ?
                <EntityDataFieldDropdown
                    className="flex-grow-1"
                    ariaLabel={`${widgetField.fieldName}-value-widget-input`}
                    options={numericAndDateEntityFields}
                    onOptionSelected={onFieldNameChange}
                    selectedOption={numericAndDateEntityFields.find(cf => cf.fieldName === widgetField.fieldName) || undefined}
                />
                : <DataFieldDropdown
                    className="d-flex mt-3 px-2"
                    options={numericAndDateCustomerFields}
                    selectedOption={numericAndDateCustomerFields.find(cf => cf.value === widgetField.value) || getInitialOption(widgetField)}
                    onOptionSelected={onFieldNameChange}
                />
            }

        </div>
    )
}

export default ValueWidget