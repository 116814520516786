import React from 'react';

const UserTaskBulkResult = ({ results, onClose }) => {
  const sucessfulResultCount = results.filter(result => result.success).length
  const failedResults = results.filter(result => !result.success)

  return (
    <div className="mt-3">
      <div className="speech-bubble">
        <div className="mia-logo"></div>
        <p>Mia</p>
      </div>
      {sucessfulResultCount > 0 && <h1 className="text-center text-white">Successfully updated {sucessfulResultCount} task{sucessfulResultCount > 1 ? "s" : ""}!</h1>}
      {sucessfulResultCount > 0 && failedResults.length > 0 && <hr />}
      {failedResults.length > 0 && <div className="alert alert-danger text-align-centre" role="alert">
        Failed to update tasks:
        <hr />
        {failedResults.map(result => {
          const errorMessage = result.message && result.message !== "" ? result.message : "Unknown error"
          return (<p>Task {result.taskId}: {errorMessage}</p>)
        })}
      </div>}
      <div className="row m-2 justify-content-center">
        <button type="button" onClick={onClose} className="btn btn-primary">Close</button>
      </div>
    </div>
  );
};

export default UserTaskBulkResult;
