import { AutoComplete } from "invevo-react-components"
import { FileImportConfigFieldDataType, FileImportConfigFieldDataTypeEnum, TextFormatter } from "../../../../types/FileImportConfigFieldDataType"
import moment from "moment-timezone"
import Input from "../../../../../../library/Inputs/Input"
import Dropdown from "../../../../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../../../../types/DropdownOptions"
import { capitaliseFirstLetters } from "../../../../../../library/helpers/stringUtils"

type DataTypeConfigProps = {
    config: FileImportConfigFieldDataType
    formatter?: keyof typeof TextFormatter
    onChange: (option: FileImportConfigFieldDataType) => void
    onFormatterChange?: (formatter: keyof typeof TextFormatter) => void
}

const timezones = moment.tz.names().map(tz => ({
    value: tz,
    label: tz
}))

const DataTypeConfig = ({ config, formatter, onChange, onFormatterChange }: DataTypeConfigProps) => {
    if (config.type === FileImportConfigFieldDataTypeEnum.DATE) {
        const onDateFormatChange = (format: string) => onChange({ ...config, format })
        const onTimezoneChange = (timezone: string) => onChange({ ...config, timezone })

        return (
            <div className="d-flex gap-3">
                <div className="d-flex flex-column small">
                    <span className="text-uppercase small mb-1 ms-1 text-grey">Date format</span>
                    <Input placeholder="Please enter the value" value={config.format} onChange={onDateFormatChange} />
                </div>
                <div className="d-flex flex-column small">
                    <span className="text-uppercase small mb-1 ms-1 text-grey">Timezone</span>
                    <AutoComplete placeholder="Please select a timezone" options={timezones} initialValue={config.timezone} onChange={onTimezoneChange} />
                </div>
            </div>
        )
    }

    if (config.type === FileImportConfigFieldDataTypeEnum.NUMBER) {
        const onNumberCultureChange = (culture: string) => onChange({ ...config, culture })

        return (
            <div className="d-flex flex-column small">
                <span className="text-uppercase small mb-1 ms-1 text-grey">Culture</span>
                <Input placeholder="Please enter the culture" value={config.culture} onChange={onNumberCultureChange} />
            </div>
        )
    }

    if (config.type === FileImportConfigFieldDataTypeEnum.TEXT && onFormatterChange) {
        const onFormatChange = (formatter: DropdownOption<keyof typeof TextFormatter>) => onFormatterChange(formatter.value)

        const options = Object.values(TextFormatter).map(value => ({ value: value, label: capitaliseFirstLetters(value.toLowerCase()) }))

        return (
            <div className="d-flex flex-column small">
                <span className="text-uppercase small mb-1 ms-1 text-grey">Format</span>
                <Dropdown
                    ariaLabel="text-format"
                    placeholder="No Format"
                    options={options}
                    selectedOption={options.find(o => o.value === formatter) ?? options.find(o => o.value === TextFormatter.NONE)}
                    onOptionSelected={onFormatChange}
                />
            </div>
        )
    }

    return <></>
}

export default DataTypeConfig
