export const FILE_DOCUMENT_TYPE_UPDATE = 'FILE_DOCUMENT_TYPE_UPDATE';

export const LOAD_FILE_IMPORT_LOCATION_SUCCESS = 'LOAD_FILE_IMPORT_LOCATION_SUCCESS';
export const NEW_FILE_IMPORT_LOCATION_SUCCESS = 'NEW_FILE_IMPORT_LOCATION_SUCCESS';
export const UPDATE_FILE_IMPORT_LOCATION_SUCCESS = 'UPDATE_FILE_IMPORT_LOCATION_SUCCESS';
export const CREATE_FILE_IMPORT_LOCATION_SUCCESS = 'CREATE_FILE_IMPORT_LOCATION_SUCCESS';
export const DELETE_FILE_IMPORT_LOCATION_SUCCESS = 'DELETE_FILE_IMPORT_LOCATION_SUCCESS';

export const LOAD_FILE_IMPORT_QUEUE_SUCCESS = 'LOAD_FILE_IMPORT_QUEUE_SUCCESS';
export const NEW_FILE_IMPORT_QUEUE_SUCCESS = 'NEW_FILE_IMPORT_QUEUE_SUCCESS';
export const UPDATE_FILE_IMPORT_QUEUE_SUCCESS = 'UPDATE_FILE_IMPORT_QUEUE_SUCCESS';
export const CREATE_FILE_IMPORT_QUEUE_SUCCESS = 'CREATE_FILE_IMPORT_QUEUE_SUCCESS';
export const DELETE_FILE_IMPORT_QUEUE_SUCCESS = 'DELETE_FILE_IMPORT_QUEUE_SUCCESS';

export const LOAD_FILE_TYPE_SUCCESS = 'LOAD_FILE_TYPE_SUCCESS';
export const NEW_FILE_TYPE_SUCCESS = 'NEW_FILE_TYPE_SUCCESS';
export const UPDATE_FILE_TYPE_SUCCESS = 'UPDATE_FILE_TYPE_SUCCESS';
export const CREATE_FILE_TYPE_SUCCESS = 'CREATE_FILE_TYPE_SUCCESS';
export const DELETE_FILE_TYPE_SUCCESS = 'DELETE_FILE_TYPE_SUCCESS';

export const LOAD_FILE_TYPE_TABLE_SUCCESS = 'LOAD_FILE_TYPE_TABLE_SUCCESS';
export const NEW_FILE_TYPE_TABLE_SUCCESS = 'NEW_FILE_TYPE_TABLE_SUCCESS';
export const UPDATE_FILE_TYPE_TABLE_SUCCESS = 'UPDATE_FILE_TYPE_TABLE_SUCCESS';
export const CREATE_FILE_TYPE_TABLE_SUCCESS = 'CREATE_FILE_TYPE_TABLE_SUCCESS';
export const DELETE_FILE_TYPE_TABLE_SUCCESS = 'DELETE_FILE_TYPE_TABLE_SUCCESS';
export const CANCEL_FILE_TYPE_TABLE_SUCCESS = 'CANCEL_FILE_TYPE_TABLE_SUCCESS';

export const LOAD_FIELD_MAP_SUCCESS = 'LOAD_FIELD_MAP_SUCCESS';
export const NEW_FIELD_MAP_SUCCESS = 'NEW_FIELD_MAP_SUCCESS';
export const UPDATE_FIELD_MAP_SUCCESS = 'UPDATE_FIELD_MAP_SUCCESS';
export const CREATE_FIELD_MAP_SUCCESS = 'CREATE_FIELD_MAP_SUCCESS';
export const DELETE_FIELD_MAP_SUCCESS = 'DELETE_FIELD_MAP_SUCCESS';
export const CANCEL_FIELD_MAP_SUCCESS = 'CANCEL_FIELD_MAP_SUCCESS';

export const LOAD_IMPORT_FILE_TYPE_SUCCESS = 'LOAD_IMPORT_FILE_TYPE_SUCCESS';

export const FILE_IMPORT_LOCATION_CANCEL = 'FILE_IMPORT_LOCATION_CANCEL';
export const FILE_IMPORT_QUEUE_CANCEL = 'FILE_IMPORT_QUEUE_CANCEL';
export const FILE_TYPE_CANCEL = 'FILE_TYPE_CANCEL';
export const FILE_TYPE_TABLE_CANCEL = 'FILE_TYPE_TABLE_CANCEL';
export const SHOW_GRID = 'SHOW_GRID';

export const REFRESH = 'REFRESH';

export const REFRESH_PROCESS = 'REFRESH_PROCESS';
export const LOAD_REFRESH_PROCESS = 'LOAD_REFRESH_PROCESS';

export const CANCEL_CONFIGURATION = 'CANCEL_CONFIGURATION';
export const LOAD_CONFIGURATION = 'LOAD_CONFIGURATION';
export const UPDATE_CONFIGURATION = 'UDPATE_CONFIGURATION';

export const LOAD_GLOBAL_CONFIGURATION = 'LOAD_GLOBAL_CONFIGURATION';