import { useFeatureToggle } from "../../../../../hooks/useFeatureToggle"
import { expandablePanels, useLayout } from "../../../../../contexts/LayoutContext"
import classes from "./DashboardStrip.module.scss"
import DashboardStripItem from "./DashboardStripItem"
import DashboardStripSubItem from "./DashboardStripSubItem"
import { StripItems } from "./StripItems"
import { useEntity } from "../../../../../contexts/EntityContext"
import { useUser } from "../../../../../contexts/UserContext"
import { useLocation } from "react-router-dom"

const DashboardStrip = ({ activeItem, onStripItemSelected, dashboards, selectedDashboard, isNewEntityStructureEnabled = false }) => {
    const { setExpandedPanel, isLeftPanelExpanded } = useLayout()
    const location = useLocation()
    const { isEnabled: isOldUxEnabled } = useFeatureToggle("showOldUx")
    const { isEnabled: isLegacyDashboardsInVerticalStripEnabled } = useFeatureToggle("showLegacyDashboardsInVerticalStrip")
    const { isEnabled: showLegacyTransactionsGrid } = useFeatureToggle("showLegacyTransactionsGrid")
    const { isEnabled: isSqlFirst } = useFeatureToggle("sqlFirst")

    const [entity] = useEntity()
    const user = useUser()
    const enabledRoles = user.userRoles.filter(r => r.enabled).map(r => r.reference)
    const adminRoleRef = user.userRoles.find(r => r.roleName.toLowerCase() === "admin" || r.roleName.toLowerCase() === "administrator")?.reference ?? ""

    const dashboardsToShow = dashboards.filter(
        config => config.roleReferences.length === 0 || config.roleReferences.some(r => enabledRoles.includes(r)) || enabledRoles.includes(adminRoleRef)
    )

    const onExpandCollapseCustomerClick = () => setExpandedPanel(isLeftPanelExpanded ? expandablePanels.DEFAULT : expandablePanels.LEFT_PANEL)

    const isDashboardsItemActive = () => {
        return isLegacyDashboardsInVerticalStripEnabled || activeItem === StripItems.DASHBOARDS || dashboards.some(d => d.id === activeItem)
    }

    const isCustomerEntity = !!entity.sqlId
    if (isCustomerEntity && entity.entityType !== "customer" && !isSqlFirst) {
        console.warn("Entity has sqlId but is not a customer entity type")
    }

    const shouldShowLegacyDashboards = (isCustomerEntity || !isNewEntityStructureEnabled || isSqlFirst) && isLegacyDashboardsInVerticalStripEnabled

    const selectedItem = selectedDashboard || activeItem

    const renderIcon = iconClasses => <i className={`m-auto ${iconClasses} text-blue fs-5 `}></i>
    const renderDoubleDigitsIcon = index => (
        <>
            <i className={`m-auto fa-solid fa-${index.charAt(0)} text-blue fs-5 `}></i>
            <i className={`m-auto fa-solid fa-${index.charAt(1)} text-blue fs-5 `}></i>
        </>
    )

    return (
        <div className={`d-flex flex-column ${classes.strip}`}>
            {location.pathname !== "/home/entity-search" && (
                <i
                    className={`mx-auto fs-4 fa-light ${
                        isLeftPanelExpanded ? "fa-arrow-left-long-to-line" : "fa-arrow-right-long-to-line"
                    } pointer mt-4 text-grey`}
                    onClick={onExpandCollapseCustomerClick}
                    role="button"
                    aria-label="toggle-left-panel-expanded-button"
                ></i>
            )}
            <i className={`fa-solid fa-building mx-auto my-5 fs-3 ${classes["strip-header"]}`}></i>
            {(!isNewEntityStructureEnabled || isSqlFirst) && (isOldUxEnabled || !isLegacyDashboardsInVerticalStripEnabled) && (
                <DashboardStripItem
                    isActive={selectedItem === StripItems.LEGACY_CUSTOMER_SUMMARY}
                    onClick={() => onStripItemSelected(StripItems.LEGACY_CUSTOMER_SUMMARY)}
                    iconClasses="fa-solid fa-table-columns"
                    label="Customer summary"
                />
            )}
            {!isOldUxEnabled && dashboards && dashboards.length > 0 && (
                <DashboardStripItem
                    isActive={isDashboardsItemActive()}
                    iconClasses="fas fa-tachometer"
                    label="Customer dashboards"
                    onClick={() => onStripItemSelected(StripItems.LEGACY_CUSTOMER_SUMMARY)}
                >
                    {shouldShowLegacyDashboards && (
                        <>
                            <DashboardStripSubItem
                                isActive={selectedItem === StripItems.LEGACY_FILES}
                                onClick={() => onStripItemSelected(StripItems.LEGACY_FILES)}
                                renderIcon={renderIcon("fa-solid fa-folder-open")}
                                label="Files"
                            />
                            <DashboardStripSubItem
                                isActive={selectedItem === StripItems.LEGACY_CONTACTS}
                                onClick={() => onStripItemSelected(StripItems.LEGACY_CONTACTS)}
                                renderIcon={renderIcon("fa-solid fa-users")}
                                label="Contacts"
                            />
                            {showLegacyTransactionsGrid && (
                                <DashboardStripSubItem
                                    isActive={selectedItem === StripItems.LEGACY_TRANSACTIONS}
                                    onClick={() => onStripItemSelected(StripItems.LEGACY_TRANSACTIONS)}
                                    renderIcon={renderIcon("fa-solid fa-credit-card")}
                                    label="Transactions"
                                />
                            )}
                        </>
                    )}
                    {dashboardsToShow &&
                        dashboardsToShow.map((dashboard, index) => (
                            <DashboardStripSubItem
                                key={index}
                                isActive={selectedItem?.id === dashboard.id}
                                renderIcon={index < 9 ? renderIcon(`fa-solid fa-${index + 1}`) : renderDoubleDigitsIcon((index + 1).toString())}
                                label={dashboard.name}
                                onClick={() => onStripItemSelected(dashboard.id)}
                            ></DashboardStripSubItem>
                        ))}
                </DashboardStripItem>
            )}
        </div>
    )
}

export default DashboardStrip
