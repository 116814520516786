export default function customerGroupReducer(state, action) {
    switch (action.type) {
        case 'CUSTOMER_GROUPS_RETRIEVED': {
            const mappedGroups = action.data.map(rg => {
                return {
                    ...rg,
                    filters: rg.filters.map(f => {
                        return { ...f, isValid: true }
                    }),
                    creditFilters: rg.creditFilters.map(f => {
                        return { ...f, isValid: true }
                    }),
                }
            })

            if (mappedGroups.filter(rg => rg.isActive).length > 0) {
                const activeGroup = mappedGroups.filter(rg => rg.isActive)[0]
                return {
                    ...state,
                    customerGroups: mappedGroups,
                    selectedCustomerGroup: activeGroup
                }
            }

            return { ...state, customerGroups: mappedGroups }
        }
        case 'CUSTOMER_GROUPS_SELECTED': {
            return { ...state, selectedCustomerGroup: action.data }
        }
        case 'CUSTOMER_GROUPS_UPDATED': {
            const updatedCustomerGroups = state.customerGroups.map((item) => {
                if (item.reference === action.data.reference) {
                    return action.data
                }
                return item
            })
            if (action.data.isNew)
                return {
                    ...state,
                    customerGroups: [{ ...action.data, isNew: false }, ...updatedCustomerGroups]
                }
            return { ...state, customerGroups: updatedCustomerGroups }
        }
        case 'SELECTED_CUSTOMER_GROUP_UPDATED': {
            const updatedCustomerGroup = { ...state.selectedCustomerGroup, ...action.data }
            return { ...state, selectedCustomerGroup: updatedCustomerGroup }
        }
        default:
            throw new Error(`Invalid reducer action: ${action.type}`)
    }
}