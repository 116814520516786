import { IconButton, TextArea, useUser } from 'invevo-react-components'
import { useState, useEffect, useRef } from 'react'
import classes from './Chat.module.scss'
import { useMsal } from "@azure/msal-react"

const Chat = () => {
    const [active, setActive] = useState(false)
    const [messages, setMessages] = useState([])
    const [userMessage, setUserMessage] = useState("")
    const { instance, accounts } = useMsal()
    const [socket, setSocket] = useState()
    const onUserMessageChange = (message) => setUserMessage(message)
    const currentUser = useUser()

    const lastMessage = useRef(null)

    const scroll = () => lastMessage?.current?.scrollIntoView() 

    const onSend = () => {
        setUserMessage("")
        socket.send(
            JSON.stringify({
                message: userMessage
              })
        );
    }

    const accessTokenRequest = {
        scopes: [],
        account: accounts[0]
    }
   
    const getToken = async () => {
        return await instance.acquireTokenSilent(accessTokenRequest)
    }

    const onMessage = (event) => {
        if(event.data['size'] === 0 || (String(event.data).includes("Ping"))) {
            return
        }
        setMessages(messages => { 
            return [...messages, JSON.parse(event.data)]
        })
        scroll()

    }

    useEffect(() => {
        if(socket) {
            return
        }
        getToken().then(tokenResponse => {
            const token = tokenResponse.idToken

            let socketBuilder = new WebSocket(`wss://integration.invevo-qa.com:443/ws/webchat/${token}`);


            socketBuilder.onopen = function (e) {
                console.log("[open] Connection established");
                console.log("Sending to server");
            };
    
            socketBuilder.onmessage = onMessage
    
            socketBuilder.onclose = function (event) {
                if (event.wasClean) {
                    console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
                } else {
                    // e.g. server process killed or network down
                    // event.code is usually 1006 in this case
                     console.log('[close] Connection died');
                    // socketBuilder = new WebSocket(`ws://localhost:9000/webchat/${token}`);
                    // console.log('reconnected');
                }
            };
    
            socketBuilder.onerror = function (error) {
                console.log(`[error] ${error.message}`);
            };
            setSocket(socketBuilder)
        }
        )
        
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getToken, socket])


    const userColor = (userId) => currentUser.username === userId ? classes['currentUser'] : classes['user']

    return (
        !active ? <div className={`${classes.chat} border rounded-top d-flex flex-column`} >
            <div className={`fs-3 flex-grow-1 d-flex align-items-center justify-content-center text-white no-select pointer`} onClick={() => setActive(true)}> Invevo Chat </div>
            </div>
            :
            <div className={`${classes.chat} ${classes.active} border rounded-top d-flex flex-column`} >
                <div className={`${classes.title} d-flex align-items-center justify-content-center text-white no-select pointer`} onClick={() => setActive(false)}> Invevo Chat </div>
                <div className={`p-2 bg-white flex-grow-1 d-flex flex-column border-bottom overflow-auto ${classes.messages}`} >
                    <>
                        {messages.map((message, index) =>
                            <div key={index} className='d-flex flex-column pt-2'>
                                <span className={`${classes.mex} p-1 text-white d-flex ${userColor(message.userId)}`}> {message.userId.split("@")[0]} </span>
                                <span className={`${classes.mex} mt-1 ms-2 p-1 bg-grey`}> {message.userMessage} </span>
                            </div>
                        )
                        }
                        <div ref={lastMessage}></div> 
                    </>
                    
                </div>
                <div className={`bg-white ${classes.textbox} p-1 d-flex justify-content-between`}> 
                    <TextArea value={userMessage} onChange={(event) => onUserMessageChange(event.target.value)} className={'flex-grow-1 pe-1'} />
                    <IconButton 
                        className="align-self-center"
                        iconClasses="fal fa-arrow-left"
                        colour="blue"
                        onClick={onSend}/>
                </div>
        </div>
    )
}

export default Chat