type NotFoundProps = {
    url?: string
}

const NotFound = ({ url }: NotFoundProps) => {
    return (
        <div className="h-100 w-100 d-flex align-items-center bg-blue">
            <div className="w-100 d-flex flex-column align-items-center">
                {url && <h1 className="text-white">Error url: {url}</h1>}
                <h1 className="text-white">Page not found</h1>
            </div>
        </div>
    )
}

export default NotFound
