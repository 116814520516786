import moment from "moment"
import { convertToCurrency } from "../../../../library/helpers/numberUtils"
import classes from "./TransactionRow.module.scss"
import { DirectIdTransactionDto } from "../../types/dtos/CreditCircleTransactionsDto"

type TransactionRowProps = {
    transaction: DirectIdTransactionDto
    currency: string
}

const TransactionRow = ({ transaction, currency }: TransactionRowProps) => {
    const getPaymentTypeIcon = (paymentType: string | undefined) => {
        switch (paymentType) {
            case "Direct Debit":
                return "far fa-repeat text-purple"

            case "Debit card":
                return "far fa-credit-card text-warning"

            case "Debit card refund":
                return "far fa-rotate-left text-green"

            case "Contactless":
                return "far fa-signal-stream text-blue"

            default:
                return "far fa-credit-card text-warning"
        }
    }

    const getCreditDebitIcon = (isCredit: boolean) => {
        if (isCredit) {
            return "far fa-arrow-trend-up text-green"
        }

        return "far fa-arrow-trend-down text-red"
    }

    return (
        <tr className={`text-grey fw-bold ${classes.row}`}>
            <td className="ps-3">
                <i className={getPaymentTypeIcon(transaction.enrichedData.category.name)}></i>
            </td>
            <td>{transaction.merchantDetails.merchantName ?? "n/a"}</td>
            <td>{transaction.enrichedData.category.name}</td>
            <td>
                <div className="d-flex align-items-center">
                    <span className="me-2">{convertToCurrency(transaction.amount, currency)}</span>
                    <i className={getCreditDebitIcon(transaction.creditDebitIndicator === "Credit")}></i>
                </div>
            </td>
            <td>{moment(transaction.bookingDate).format("Do MMM y HH:mm")}</td>
        </tr>
    )
}

export default TransactionRow
