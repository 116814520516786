import { Dropdown } from 'invevo-react-components';
import SetupCard from '../SetupCard'
import TriggerValuesInput from './TriggerValuesInput';
import { Weekdays, EverWeekendDayOption, EveryWorkingDayOption } from '../../../utils/Utils';
import { useState } from 'react';

const Trigger = ({ selectedRule, dispatch }) => {
    const availableTriggerDetails = [
        { id: 0, value: "EVERY_DAY", label: "Everyday", values: [] },
        { id: 1, value: "WEEKDAY", label: "Weekday", values: [...Weekdays, EveryWorkingDayOption, EverWeekendDayOption] },
        { id: 2, value: "DATE_OF_MONTH", label: "Date(s) of month", values: Array.from({length: 31}, (_, i) => `${i + 1}`) }
    ]

    const [selectedTriggerValues, setSelectedTriggerValues] = useState(selectedRule.trigger ? availableTriggerDetails.filter(v => v.value === selectedRule.trigger.type)[0].values : [])

    const onTriggerChange = (newTrigger) => {
        dispatch({ type: "SELECTED_WORKFLOW_RULE_UPDATED", workflowRule: { ...selectedRule, trigger: newTrigger } })
    }

    const onTriggerTypeSelected = (selectedType) => {
        onTriggerChange({ ...selectedRule.trigger, type: selectedType.value, values: [] })
        setSelectedTriggerValues(availableTriggerDetails.filter(v => v.value === selectedType.value)[0].values)
    }

    const onTriggerValuesChanged = (values) => {
        onTriggerChange({
            ...selectedRule.trigger,
            values: values.includes(EveryWorkingDayOption)
                ? [EveryWorkingDayOption]
                : values.includes(EverWeekendDayOption) ? [EverWeekendDayOption] : values
        })
    }

    const calculateHour = (hour) => {
        var hours = hour.value === 24 ? 0 : hour.value
        onTriggerChange({ ...selectedRule.trigger, hourToTrigger: hours, hour: hour })
    }

    var hourOptions = Array.from({ length: 24 }, (_, i) => { return { id: i, value: `${i}` } })

    const onTriggerClick = () => {
        if (selectedRule.trigger) {
             dispatch({ type: "SELECTED_WORKFLOW_RULE_UPDATED", workflowRule: { ...selectedRule, trigger: null } })
        } else {
            dispatch({
                type: "SELECTED_WORKFLOW_RULE_UPDATED", workflowRule: {
                    ...selectedRule, trigger: {
                        type: "EVERY_DAY",
                        values: [],
                        hour: { id: 1, value: `1` },
                        hourToTrigger: 1
                    }
                }
            })
        }
    }

    const children = (
        selectedRule.trigger
            ? <div className="d-flex align-items-center">
                <Dropdown
                    placeholder={availableTriggerDetails.filter(triggerType => triggerType.value === selectedRule.trigger.type)[0].label}
                    options={availableTriggerDetails}
                    onOptionSelected={(option) => onTriggerTypeSelected(option)}
                />
    
                {selectedRule.trigger.type !== "EVERY_DAY" && 
                    <TriggerValuesInput
                        className="d-flex flex-column ms-3 bg-white"
                        selectedValues={selectedRule.trigger.values}
                        values={selectedTriggerValues}
                        onValuesChange={onTriggerValuesChanged}
                    />
                }
                
                <span className="text-grey mx-3 text-uppercase">at</span>

                <Dropdown
                    className="me-3"
                    defaultOption={selectedRule.trigger.hour}
                    options={hourOptions}
                    onOptionSelected={(option) => calculateHour(option)}
                    contentHeight={300}
                />
                <span className="text-grey ms-3 text-uppercase">utc</span>
            </div>
            : <div className={"d-flex align-items-center no-select"}>
                <i className="fal fa-exclamation-triangle text-warning me-2 fs-4"></i>
                <span className="fs-5 me-2">No trigger set for this workflow</span>
            </div>
    )

    return (
        <div className="d-flex flex-column mb-3">
            <SetupCard title="Scheduled" customData={children} addButton={selectedRule.trigger ? false : true} onClick={onTriggerClick}/>
        </div>
    )
}

export default Trigger
