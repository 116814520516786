import { SpeechBubble } from 'invevo-react-components';
import classes from './Mobile.module.scss'

const Mobile = ({ className, text, setText, placeholder = "Select from template options above", disabled = false }) => {

    return (
        <div className={`${classes.phoneFrame} ${className} d-flex flex-column mt-3 mx-3 px-4 pt-4 pb-5`}>
            <SpeechBubble text={text} placeholder={placeholder} disabled={disabled} onTextChange={disabled ? {} : setText} />
        </div>
    )
}

export default Mobile