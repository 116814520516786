import { useClient } from 'invevo-react-components'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { showToastErrorMessage } from '../../api/toasterApi'
import { executeAuthAsyncGet } from '../../utility/asyncSupport'
import AccountCards from './accountCards'

const AbortController = window.AbortController

const AccountInfoPanel = ({
  accountId,
  isVirtualAccount,
  executeAuthAsyncGet,
  creditLimitChanged
}) => {
  const client = useClient()
  const [accountInfo, setAccountInfo] = useState({
    status: 'idle',
    summary: {}
  })

  useEffect(() => {
    setAccountInfo((a) => ({ ...a, status: 'pending' }))

    const controller = new AbortController()

    if (accountId === null || accountId === "") return
    executeAuthAsyncGet(
      client,
      'accountsummary/extended',
      'ACCOUNT_SUMMARY',
      {
        accountId,
        isVirtualAccount
      },
      (result) => {
        setAccountInfo({
          status: 'resolved',
          summary: result
        })
      },
      () => {
        setAccountInfo((a) => ({
          ...a,
          status: 'rejected'
        }))
        showToastErrorMessage('Error while getting account summary.')
      },
      controller.signal
    )
  }, [accountId, isVirtualAccount, creditLimitChanged, executeAuthAsyncGet, client])

  const { summary, status } = accountInfo

  return (<AccountCards initSummary={summary} loadingStatus={status} />)
}

const mapStateToProps = (state) => {
  return {
    accountId: state.currentSelectionReducer.selectedAccount.miaAccountId,
    isVirtualAccount:
      state.currentSelectionReducer.selectedAccount.miaAccountIsVirtualAccount,
    creditLimitChanged:
      state.asyncReducer.INSERT_FIRST_DECISION.isExecuted ||
      state.asyncReducer.INSERT_SECOND_DECISION.isExecuted
  }
}

export default connect(mapStateToProps, {
  executeAuthAsyncGet
})(AccountInfoPanel)
