import classes from './LookupCard.module.scss'
import { Input, IconButton } from "invevo-react-components"

const LookupCard = ({ lookup, selectedLookupId, onLookupChange, onSelectedLookupChanged, removeLookup }) => {
    const isActive = lookup => selectedLookupId === lookup.id

    const onLookupNameChange = event => onLookupChange({
        ...lookup,
        name: event.target.value
    })

    const onLookupRemoved = () => removeLookup(lookup.id)
    const onLookupCardClicked = () => onSelectedLookupChanged(lookup.id)

    return (
        <div className={`d-flex justify-content-between p-2 mb-3 ${classes.card} ${isActive(lookup) ? classes.active : ''}`} onClick={onLookupCardClicked} role="region" aria-label={`${lookup.reference}-lookup-card`}>
            <div className="d-flex align-items-center">
                <i className="fal fa-database me-2" />
                {lookup.editable ?
                    <Input
                        placeholder="Please enter field name"
                        value={lookup.name}
                        onChange={onLookupNameChange}
                    />
                    : <h3 className="mb-0">{lookup.name}</h3>}
            </div>
            {lookup.editable &&
                <div className="d-flex align-items-center small">
                    <IconButton
                        className="w-100"
                        buttonClasses={classes.resetBoxShadow}
                        iconClasses="fal fa-trash-alt"
                        onClick={onLookupRemoved}
                    />
                </div>
            }
        </div>
    )
}

export default LookupCard