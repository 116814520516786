import { useClient } from "invevo-react-components"
import ReportMenuForm from "../../../microfrontends/legacy/components/reports/ReportMenuForm"
import LegacyApp from "../../../microfrontends/legacy/LegacyApp"

const Reports = ({store}) => {
    const client = useClient()

    return (
        <div className="d-flex w-100 h-100 p-4">
            <LegacyApp className="d-flex w-100 h-100" store={store}>
                <div className="d-flex w-100 h-100">
                     <ReportMenuForm client={client} />
                </div>
            </LegacyApp>
        </div>
    )
}

export default Reports