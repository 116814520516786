import { ReactNode } from "react"
import classes from "./Radio.module.scss"

type RadioProps = {
    children: ReactNode
    className?: string
    lightBackground?: boolean
}

const Radio = ({ children, className = "", lightBackground = false }: RadioProps) => {
    return <div className={`${className} ${lightBackground ? "bg-grey" : ""} d-flex rounded ${classes.options}`}>{children}</div>
}

type OptionProps = {
    onSelect: () => void
    isSelected: boolean
    children: ReactNode
    className?: string
}

const Option = ({ onSelect, isSelected, children, className = "" }: OptionProps) => {
    const conditionalClasses = isSelected ? `bg-blue text-white ${classes.isSelected}` : "text-grey"
    return (
        <div className={`${className} d-flex align-items-center justify-content-center p-2 pointer no-select rounded ${conditionalClasses}`} onClick={onSelect}>
            {children}
        </div>
    )
}

Radio.Option = Option

export default Radio
