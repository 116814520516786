import { Input, useClient, useConfig } from "invevo-react-components"
import { useMemo } from "react"
import useApiQuery from "../../../../hooks/useApiQuery"
import ProgressButton from "../../../../library/buttons/ProgressButton/ProgressButton"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import Dropdown from "../../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../../types/DropdownOptions"
import Role from "../types/Role"
import { RoleTypeDropdownOptions, RoleTypeEnum } from "../types/RoleTypeEnum"
import classes from "./RoleActions.module.scss"
import { useOverlay } from "../../../../contexts/overlay/OverlayContext"

type RoleActionsProps = {
    role: Role
    originalRole: Role | undefined
    onRoleUpdated: (role: Role) => void
    onRoleSaved: (role: Role) => void
    onRoleDeleted: (role: Role) => void
    onRoleDuplicated: (role: Role) => void
}

const RoleActions = ({ role, originalRole, onRoleUpdated, onRoleSaved, onRoleDeleted, onRoleDuplicated }: RoleActionsProps) => {
    const client = useClient()
    const config = useConfig()

    const saveRoleRequest = useApiQuery<Role>({
        url: `${config.USER_ROLES_API_URL}/api/${client}/role`,
        method: "PUT",
        onSuccess: () => onRoleSaved(role),
        isExecutedAutomatically: false
    })

    const deleteRoleRequest = useApiQuery({
        url: `${config.USER_ROLES_API_URL}/api/${client}/role`,
        method: "DELETE",
        onSuccess: () => onRoleDeleted(role),
        isExecutedAutomatically: false
    })

    const confirmDeleteSwalHtml = (
        <div className="fs-4 text-grey">
            Are you sure you want to delete the role
            <span className="fw-bold"> {role.name}</span>?
        </div>
    )

    const onNameUpdated = (event: React.ChangeEvent<HTMLInputElement>) => onRoleUpdated({ ...role, name: event.target.value })
    const onRoleTypeUpdated = (option: DropdownOption<RoleTypeEnum>) => onRoleUpdated({ ...role, roleType: option.value })

    const overlay = useOverlay()
    const deleteRole = () => overlay.showConfirmDeletionOverlay("Role", confirmDeleteSwalHtml, () => deleteRoleRequest.execute(role.reference))
    const duplicateRole = () => onRoleDuplicated(role)

    const saveRole = () =>
        saveRoleRequest.execute(role.reference, {
            name: role.name,
            roleType: role.roleType,
            customerPermissions: role.customerPermissions,
            automateAdmin: role.automateAdmin,
            connectAdmin: role.connectAdmin,
            userAdmin: role.userAdmin,
            dashboardAdmin: role.dashboardAdmin,
            integrationAdmin: role.integrationAdmin,
            dataConfigAdmin: role.dataConfigAdmin,
            entityPermissions: role.entityPermissions
        })

    const undoChanges = () => {
        if (originalRole === undefined) return
        onRoleUpdated({ ...originalRole })
    }

    const selectedRoleTypeDropDownOption = RoleTypeDropdownOptions.find(o => o.value === role?.roleType)
    const hasRoleChanged = useMemo(() => JSON.stringify(role) !== JSON.stringify(originalRole), [role, originalRole])
    const isNewRole = originalRole === undefined
    const isRoleNameValid = role && role.name.length > 1 && role.name.length < 257

    return (
        <div className={`d-flex flex-column bg-blue p-3 ${classes.container}`}>
            <Input className="mb-3" placeholder="Please enter name" label="Role name" value={role?.name} onChange={onNameUpdated} ariaLabel="role-name" />
            {!isRoleNameValid && (
                <div className="d-flex mb-2 text-white">
                    <i className="fal fa-exclamation-circle me-2 align-self-center"></i>
                    <span>Name must be between 1 and 256 characters in length</span>
                </div>
            )}
            <label className="form-label mb-1">
                <span className="text-uppercase text-light-blue">Role Type</span>
            </label>
            <Dropdown
                options={RoleTypeDropdownOptions}
                onOptionSelected={onRoleTypeUpdated}
                selectedOption={selectedRoleTypeDropDownOption}
                textAlign="left"
                ariaLabel="role-type"
            />
            <div className="d-flex flex-column mt-auto mb-2">
                <StandardButton
                    className="w-100 mb-3"
                    iconClasses="fal fa-clone"
                    label="Duplicate role"
                    colour="blue"
                    onClick={duplicateRole}
                    ariaLabel="duplicate-role"
                />
                <StandardButton
                    className="w-100 mb-3"
                    iconClasses="fal fa-trash-can"
                    label="Delete role"
                    colour="blue"
                    onClick={deleteRole}
                    ariaLabel="Delete role"
                />
                <ProgressButton
                    className="w-100 mb-3"
                    iconClasses="fal fa-save"
                    label="Save role"
                    colour="blue"
                    succeededText="Saved successfully"
                    failedText="Failed to save"
                    onClickWithPromise={saveRole}
                    disabled={!hasRoleChanged || !isRoleNameValid}
                />
                <StandardButton
                    className="w-100"
                    iconClasses="fal fa-rotate-left"
                    label="Undo changes"
                    colour="blue"
                    onClick={undoChanges}
                    disabled={!hasRoleChanged || isNewRole}
                    ariaLabel="undo-role-changes"
                />
            </div>
        </div>
    )
}

export default RoleActions
