import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { withFormsy } from 'formsy-react';

class DateRange extends Component {
  componentDidMount() {
    this.props.setValue({ from: null, to: null });
  }

  dtFrom = (dt) => {
    const newState = { from: dt, to: this.props.value.to };
    this.props.setValue(newState);
    this.setState(newState);
    this.props.onChange(newState);
  };

  dtTo = (dt) => {
    const newState = { from: this.props.value.from, to: dt };
    this.props.setValue(newState);
    this.setState(newState);
    this.props.onChange(newState);
  };

  validate() {
    const s = this.state;
    return s === null || s.from == null || s.to == null || s.from <= s.to;
  }

  render() {
    const { errorMessage } = this.props;
    return (
      <div className="form-group date-range">
        <label htmlFor={this.props.name} className="col-form-label-sm mb-0">
          {this.props.label}
        </label>
        <div className="form-row">
          <div className="col">
            <DatePicker
              selected={this.props.value ? this.props.value.from : null}
              dateFormat="dd MMMM yyyy"
              placeholderText="--/--/----"
              onChange={this.dtFrom}
            />
          </div>
          <div className="col">
            <DatePicker
              selected={this.props.value ? this.props.value.to : null}
              dateFormat="dd MMMM yyyy"
              placeholderText="--/--/----"
              onChange={this.dtTo}
              popperPlacement="bottom-end"
            />
          </div>
        </div>
        <div className="validation-error">{errorMessage}</div>
      </div>
    );
  }
}

DateRange.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default withFormsy(DateRange);