import { getApiUrl } from '../constants';
import { webApiInterface } from './webApiInterface';

export function getFieldMap(client, fieldMapId, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/field-map?fieldMapId=' + fieldMapId;

    return webApiInterface.authFetch(client, url, dispatch)
        .then(function (response) {
            return response.json();
        });
}

export function updateFieldMap(client, fieldMap, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/field-map/update';

    return webApiInterface.authPost(client, url, dispatch, fieldMap, 'POST');
}

export function createFieldMap(client, fieldMap, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/field-map/create';

    return webApiInterface.authPost(client, url, dispatch, fieldMap, 'POST');
}

export function deleteFieldMap(client, fieldMapId, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/field-map/delete';

    return webApiInterface.authDelete(client, url, dispatch, fieldMapId);
}