export const actionTypes = {
    //data config
    CUSTOMER_DATA_CONFIGURATION_RETRIEVED: 'CUSTOMER_DATA_CONFIGURATION_RETRIEVED',
    CUSTOMER_FIELD_CONFIG_UPDATED: 'CUSTOMER_FIELD_CONFIG_UPDATED',
    CREDIT_DATA_CONFIGURATION_RETRIEVED: 'CREDIT_DATA_CONFIGURATION_RETRIEVED',
    CREDIT_FIELD_CONFIG_UPDATED: 'CREDIT_FIELD_CONFIG_UPDATED',
    DATA_CONFIGURATION_UPDATED: 'DATA_CONFIGURATION_UPDATED',

    //customer groups
    CUSTOMER_GROUPS_RETRIEVED: 'CUSTOMER_GROUPS_RETRIEVED',
    CUSTOMER_GROUPS_SELECTED: 'CUSTOMER_GROUPS_SELECTED',
    CUSTOMER_GROUPS_UPDATED: 'CUSTOMER_GROUPS_UPDATED',
    SELECTED_CUSTOMER_GROUP_UPDATED: 'SELECTED_CUSTOMER_GROUP_UPDATED',

    //user groups
    ROLES_RETRIEVED: 'ROLES_RETRIEVED',
    ROLE_ADDED: 'ROLE_ADDED',
    ROLE_TOGGLED: 'ROLE_TOGGLED',
    ROLE_ASSIGNED_USERS_UPDATED: 'ROLE_ASSIGNED_USERS_UPDATED',
    ROLE_PERMISSIONS_UPDATED: 'ROLE_PERMISSIONS_UPDATED',
    CLIENT_USERS_RETRIEVED: 'CLIENT_USERS_RETRIEVED',
    CUSTOMER_FIELDS_RETRIEVED: 'CUSTOMER_FIELDS_RETRIEVED',

    //main site
    MENU_ITEM_CLICKED: 'MENU_ITEM_CLICKED',
    USER_PERMISSIONS_RETRIEVED: 'USER_PERMISSIONS_RETRIEVED'
}