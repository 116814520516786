import React from 'react';
import CurrencyFormatter from '../common/CurrencyFormatter';

const ResultCard = ({ dataItem, isSelected, onRowClick }) => {
  
  const accountStatusClass = (status) => {
    switch (status) {
      case 'Trade':
        return 'badge bg-success';
      case 'Payment Plan':
        return 'badge bg-warning';
      default:
        return 'badge bg-danger';
    }
  };

  return (
    <td onClick={(e) => onRowClick({ ...e, dataItem })}>
      <div className={isSelected ? 'result-selected' : 'result-default'}>
        <div className="container-fluid text-grey">
          <div className="row no-gutters">
            <div className="col-6">
              <h5 className="shout tight text-black">{dataItem.AccountCode}</h5>
            </div>
            <div className="d-flex col-6">
              <h5 className="shout tight ms-auto text-black">
                {dataItem.BrandName}
                {dataItem.BranchCode && ` | ${dataItem.BranchCode}`}
              </h5>
            </div>
            <div className="col-12 ">
              <h6 className={accountStatusClass(dataItem.AccountStatus)}>
                {dataItem.AccountStatus}
              </h6>
            </div>
            <div className="col-12">
              <h4 className="tight">{dataItem.Name}</h4>
            </div>
          </div>
          <div className="row no-gutters d-flex">
            <div className="col-6">
              <h6 className="tight">Balance {dataItem.Currency}</h6>
              <div className='d-flex text-black'><CurrencyFormatter amount={dataItem.Balance} /></div>
            </div>
            <div className="d-flex flex-column col-6 ms-auto">
              <h6 className="tight ms-auto">Overdue {dataItem.Currency}</h6>
              <div className='d-flex ms-auto text-black'><CurrencyFormatter amount={dataItem.Overdue} /></div>
            </div>
          </div>
        </div>
      </div>
    </td>
  );
};

export default ResultCard;
