import { Loading } from 'invevo-react-components';
import React from 'react';
import ReactDOM from 'react-dom';

const ComponentLoader = ({ compRef = {} }) => {
  const loadingPanel = (
    <Loading isLoading={true} colour="blue"></Loading>
  );
  const componentContent = compRef.current;
  return componentContent
    ? ReactDOM.createPortal(loadingPanel, componentContent)
    : loadingPanel;
};

export default ComponentLoader;
