import { Input, IconButton, Dropdown } from "invevo-react-components"
import { dataPrimitiveOptions } from "../../../enums/dataPrimitiveOptions"
import { dataPrimitive } from "../../../enums/dataPrimitive"

const LookupField = ({ selectedLookup, field, onLookupChange, removeField }) => {
    const onLookupFieldNameChange = event => {
        const index = selectedLookup.fields.findIndex(f => f.id === field.id)
        onLookupChange({
            ...selectedLookup,
            fields: [
                ...selectedLookup.fields.slice(0, index),
                {
                    ...selectedLookup.fields.find(f => f.id === field.id),
                    field: event.target.value
                },
                ...selectedLookup.fields.slice(index + 1)
            ]
        })
    }

    const getFieldName = () => {
        if (field.field === "reference") return "code"
        else if (field.field === "name") return "display name"
        else return field.field
    }

    const onLookupFieldTypeChange = selectedOption => {
        const index = selectedLookup.fields.findIndex(l => l.id === field.id)
        onLookupChange({
            ...selectedLookup,
            fields: [
                ...selectedLookup.fields.slice(0, index),
                {
                    ...selectedLookup.fields.find(f => f.id === field.id),
                    dataPrimitive: selectedOption.value
                },
                ...selectedLookup.fields.slice(index + 1)
            ]
        })
    }
    const onLookupFieldRemoved = () => removeField(field.id)

    return (
        <>
            {field.editable ? (
                <Input className="mt-1" placeholder="Please field name" value={field.field} onChange={onLookupFieldNameChange} />
            ) : (
                <h3 className="text-truncate text-uppercase pe-2">{getFieldName()}</h3>
            )}
            <div className="d-flex align-items-center small mt-1">
                <Dropdown
                    className="mt-1"
                    options={dataPrimitiveOptions.filter(o => o.value !== dataPrimitive.LOOKUP)}
                    defaultOption={dataPrimitiveOptions.find(o => o.value === field.dataPrimitive)}
                    disabled={!field.editable}
                    onOptionSelected={onLookupFieldTypeChange}
                />

                {field.editable && <IconButton className="w-100 justify-content-end" iconClasses="fal fa-trash-alt" onClick={onLookupFieldRemoved} />}
            </div>
        </>
    )
}

export default LookupField
