import { webApiInterface } from './webApiInterface';
import { getApiUrl } from '../constants';

export function getCustomerTED(client, dispatch, customerId) {
  const url = getApiUrl(client) + 'api/customers/' + customerId + '/ted';

  return webApiInterface.authFetch(client, url, dispatch).then(function (response) {
    return response.json();
  });
}

export function refreshCustomerTED(client, dispatch, customerId) {
  const url = getApiUrl(client) + 'api/customers/' + customerId + '/ted/refresh';

  return webApiInterface.authFetch(client, url, dispatch).then(function (response) {
    return response.json();
  });
}
