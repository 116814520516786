import React, { Component } from 'react';
import { connect } from 'react-redux';
import { executeAuthAsyncGet, executeAuthAsyncPost } from '../../utility/asyncSupport';
import { showToastSuccessMessage, showToastErrorMessage } from '../../api/toasterApi';
import moment from 'moment';
import { creditLimitDecisions, creditLimitDecisionNames } from './creditConstants';
import { decimalKeyInput } from '../../utility/generalFunctions';
import RenderRow from './renderRow';
import { displayErrorMessage } from '../../utility/error';
import { find } from 'lodash';
import { registerForIntl, provideIntlService } from '@progress/kendo-react-intl';
import { ConfrimDialog } from '../common/confirmDialog';
import StatefulSelect from '../common/statefulSelect';
import DecisionReasonSelect from './decisionReasonSelect';
import ComponentLoader from '../common/ComponentLoader';
import { refreshVpHierarchy } from '../../actions/vpActions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Decision extends Component {
  state = {
    showDialog: false,
    decisionId: null,
    approved: false,
    reasonForDecisionId: null,
    amountApproved: '',
    amountApprovedNumber: null,
    expirationDateApproved: moment().add(1, 'months'),
    requestCancelled: false,
    noteText: '',
    isLoading: false
  };


  onDecisionChanged = (value) => {
    const descision = find(
      creditLimitDecisions,
      (item) => item.value === value
    );
    const req = this.props.pendingRequest;

    this.setState({
      decisionId: value,
      approved: descision.Approved,
      reasonForDecisionId: null,
      amountApproved:
        provideIntlService(this).formatNumber(
          req.AmountRequested,
          this.props.currencyFormat
        ) || '',
      amountApprovedNumber: req.AmountRequested,
      noteText: ''
    });
  };
  onChangeExpirationDateApproved = (value) => {
    this.setState({
      expirationDateApproved: value != null ? new Date(value) : null
    });
  };

  onChangeAmountApproved = (event) => {
    const amountApproved = parseFloat(event.target.value);
    const amountApprovedString = isNaN(amountApproved)
      ? null
      : event.target.value;
    this.setState({
      amountApproved: amountApprovedString,
      amountApprovedNumber: amountApproved
    });
  };

  onKeyPressAmountRequested = (event) => {
    const inputKeyCode = event.which;

    if (!decimalKeyInput(inputKeyCode)) event.preventDefault();
  };

  onChangeNote = (event) => {
    if (event.target.value.length > 4000) {
      this.setState({ noteText: event.target.value });
      showToastErrorMessage('Credit note text cannot excced 4000 characters.');
    } else {
      this.setState({ noteText: event.target.value });
    }
  };

  onFocusAmountApproved = () => {
    this.setState({ amountApproved: this.state.amountApprovedNumber });
  };
  onFocusOutAmountApproved = () => {
    this.setState({
      amountApproved: provideIntlService(this).formatNumber(
        this.state.amountApproved,
        this.props.currencyFormat
      )
    });
  };

  onReasonForDecisionChanged = (value) => {
    this.setState({ reasonForDecisionId: value });
  };

  render() {
    const s = this.state;

    const req = this.props.pendingRequest;
    if (req == null) {
      return <div></div>;
    }

    const usersListItems = [...req.FirstUserIds]
      .sort((a, b) => {
        if (a.Description.toUpperCase() < b.Description.toUpperCase())
          return -1;
        if (a.Description.toUpperCase() > b.Description.toUpperCase()) return 1;
        return 0;
      })
      .map((u) => (
        <option value={u.Id} key={u.Id}>
          {u.Description}
        </option>
      ));

    const { APPROVED, DECLINED } = creditLimitDecisionNames;

    const isDecisionApproved = this.state.decisionId === APPROVED;
    const isDecisionDeclined = this.state.decisionId === DECLINED;
    const isDecisionTaken = isDecisionApproved || isDecisionDeclined;

    const cond1 = isDecisionTaken;
    const cond2 =
      isDecisionDeclined || (isDecisionApproved && s.amountApprovedNumber > 0);
    const cond3 = s.reasonForDecisionId > 0;
    const cond4 = s.noteText.length < 4000;
    const approvalEnabled = cond1 && cond2 && cond3 && cond4;

    const decisions = creditLimitDecisions;

    return (
      <div className="top30">
        <div>
          <h3 className='text-white'>Decision</h3>
          <hr />
          <RenderRow
            leftContent="Decision"
            rightContent={
              <StatefulSelect
                options={decisions}
                optionId={this.state.decisionId}
                onChange={this.onDecisionChanged}
              />
            }
            leftWidth={4}
            rightWidth={8}
          />
          {isDecisionTaken && (
            <RenderRow
              leftContent="Reason"
              rightContent={
                <DecisionReasonSelect
                  decisionId={this.state.decisionId}
                  id={this.state.reasonForDecisionId}
                  onChange={this.onReasonForDecisionChanged}
                />
              }
              leftWidth={4}
              rightWidth={8}
            />
          )}
          {isDecisionApproved && (
            <RenderRow
              leftContent="Amount"
              rightContent={
                <input
                  className="form-control"
                  value={this.state.amountApproved}
                  onChange={this.onChangeAmountApproved}
                  onFocus={this.onFocusAmountApproved}
                  onBlur={this.onFocusOutAmountApproved}
                  onKeyPress={this.onKeyPressAmountRequested}
                />
              }
              leftWidth={4}
              rightWidth={8}
            />
          )}
          {req.SecondDecisionNeeded && (
            <RenderRow
              leftContent="Assigned to"
              rightContent={
                <select size="4" disabled>
                  {usersListItems}
                </select>
              }
              leftWidth={4}
              rightWidth={8}
            />
          )}

          <RenderRow
            leftContent="Add a note"
            rightContent={
              <textarea
                name="note"
                value={this.state.noteText}
                onChange={this.onChangeNote}
                rows={4}
                style={{ width: '100%' }}
              ></textarea>
            }
            leftWidth={4}
            rightWidth={8}
          />

          <div className="clearfix">
            <button
              className="btn btn-primary float-right"
              onClick={this.onApprovalClicked}
              disabled={!approvalEnabled}
            >
              Submit Decision
            </button>
          </div>

          <ConfrimDialog
            show={this.state.showDialog}
            message={
              this.state.approved === false
                ? 'Are you sure you want to decline this request?'
                : 'Are you sure you want to approve this request?'
            }
            onConfirm={this.actionDecision}
            onClose={this.closeDialog}
          />

          <ToastContainer />

        </div>
        {this.state.isLoading && <ComponentLoader />}
      </div>
    );
  }

  actionDecision = () => {
    this.setState({ isLoading: true });
    const s = this.state;
    const confirmToastMessage =
      s.approved === false ? 'Request Declined.' : 'Decision saved correctly.';

    if (s.requestCancelled === true) {
      s.noteText = '';
      s.decisionId = 0;
      s.amountApprovedNumber = 0.0;
      s.reasonForDecisionId = 999;
    }

    const decisionObject = {
      Id: this.props.pendingRequest.Id,
      CustomerId: this.props.pendingRequest.CustomerId,
      VirtualAccountId: this.props.pendingRequest.VirtualAccountId,
      DecisionOneAmount: s.amountApprovedNumber,
      DecisionOneType: s.decisionId,
      DecisionOneReasonId: s.reasonForDecisionId,
      NoteTextDecisionOne: s.noteText
    };

    this.props.executeAuthAsyncPost(
      this.props.client,
      'CreditLimit/InsertFirstDecision',
      'INSERT_FIRST_DECISION',
      decisionObject,
      () => {
        showToastSuccessMessage(confirmToastMessage);

        this.props.refreshCallback();
        this.props.refreshVpHierarchy();
        this.closeDialog();
        this.setState({ isLoading: false });
      },
      (error) => {
        displayErrorMessage(error);

        this.closeDialog();
        this.setState({ isLoading: false });
      }
    );
  };

  onApprovalClicked = (e) => {
    e.preventDefault();
    this.setState({
      showDialog: true
    });
  };

  closeDialog = () => {
    this.setState({
      showDialog: false
    });
  };
}

const mapStateToProps = (state) => {
  return {
    reasonsForDecisionLookup: state.lookupReducer.reasonsForDecision,
    pendingRequest: state.asyncReducer.GET_PENDING_REQUEST.result,
    userName: state.authReducer.userName,
    globalCurrencyCode:
      state.lookupReducer.globalSettings.results.globalCurrencyCode,
    currencyFormat: state.lookupReducer.globalSettings.results.currencyFormat
  };
};

registerForIntl(Decision);

export default connect(mapStateToProps, {
  executeAuthAsyncGet,
  executeAuthAsyncPost,
  refreshVpHierarchy
})(Decision);
