const ExportActionInfo = ({ bigTitle }: { bigTitle: boolean }) => {
    return (
        <div className="d-flex align-items-center gap-2 me-3">
            <i className="fal fa-file-export text-blue fs-5" />
            <span className={`text-black fw-bold small ${bigTitle && "fs-5"}`}>Trigger Export File</span>
        </div>
    )
}

export default ExportActionInfo
