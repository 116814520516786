const UpdateDataFieldActionInfo = ({ bigTitle }: { bigTitle: boolean }) => {
    return (
        <div className="d-flex align-items-center gap-2 me-3">
            <i className="fal fa-pen-field text-blue mb-1 fs-5" />
            <span className={`text-black fw-bold small me-1 text-capitalize ${bigTitle && "fs-5"}`}>Update Data Fields</span>
        </div>
    )
}

export default UpdateDataFieldActionInfo
