import * as types from '../actions/actionTypes';

export default function configurationReducer(state = [], action) {
    switch (action.type) {
        case types.CANCEL_CONFIGURATION:
        case types.LOAD_CONFIGURATION:
        case types.UPDATE_CONFIGURATION:
            return [...state, Object.assign({}, action.configuration)];
        default:
            return state;
    }
}