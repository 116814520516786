import { useState, useEffect } from 'react';
import CustomerFilters from './CustomerFilters';
import CreditFilters from './CreditFilters';
import { getConfig, useApi, useClient } from 'invevo-react-components'

const CustomerGroup = ({ state: customerGroup, dispatch, isConfigReader }) => {
    const [hasCreditData, setHasCreditData] = useState(false)
    const api = useApi()
    const client = useClient()

    useEffect(() => {
        getConfig().then(config => {
            api.get(`${config.ASSESS_API_URL}/api/${client}/has-credit-data`)
                .then(response => {
                    setHasCreditData(response.data.hasCreditData)
                })
                .catch(error => {
                    console.log(error)
                })
        })
    }, [api, client])

    return (
        <div className="d-flex flex-column w-100 p-4">
            <CustomerFilters state={customerGroup} dispatch={dispatch} isConfigReader={isConfigReader} />
            {hasCreditData &&
                <CreditFilters state={customerGroup} dispatch={dispatch} isConfigReader={isConfigReader} />
            }
        </div>
    )
}

export default CustomerGroup