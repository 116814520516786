import { ReactFlowColour } from "../../../library/flow/flowEditor/ReactFlowNode"

export type FlowNodeType =
    | "entity created"
    | "wait for condition"
    | "filters"
    | "send email action"
    | "send sms action"
    | "send post action"
    | "update fields action"
    | "export action"
    | "outbound ivr action"
    | "fetch external data action"
    | "undefined process"

export default FlowNodeType

export const getBackgroundForType = (nodeType: FlowNodeType): ReactFlowColour => {
    switch (nodeType) {
        case "entity created":
            return "success"
        case "wait for condition":
            return "danger"
        case "filters":
            return "warning"
        case "undefined process":
            return "secondary"
        default:
            return "primary"
    }
}

export const getIconForType = (nodeType: FlowNodeType): string => {
    switch (nodeType) {
        case "entity created":
            return "fa-solid fa-play text-success"
        case "wait for condition":
            return "fa-solid fa-clock text-danger"
        case "filters":
            return "fa-solid fa-filter text-warning"
        case "send email action":
            return "fa-solid fa-at text-primary"
        case "send sms action":
            return "fa-solid fa-sms text-primary"
        case "send post action":
            return "fa-solid fa-envelope text-primary"
        case "update fields action":
            return "fa-solid fa-pen-field text-primary"
        case "export action":
            return "fa-solid fa-file-export text-primary"
        case "outbound ivr action":
            return "fa-solid fa-phone text-primary"
        case "fetch external data action":
            return "fa-solid fa-cloud-download text-primary"
        case "undefined process":
            return "fa-solid fa-circle-question text-secondary"
    }
}

export const getDefaultTitleForType = (nodeType: FlowNodeType): string => {
    switch (nodeType) {
        case "entity created":
            return "Trigger: Entity Created"
        case "wait for condition":
            return "Trigger: Wait for Condition"
        case "filters":
            return "Filters: Match First"
        case "send email action":
            return "Action: Send Email"
        case "send sms action":
            return "Action: Send SMS"
        case "send post action":
            return "Action: Send Post"
        case "update fields action":
            return "Action: Update Fields"
        case "export action":
            return "Action: Export"
        case "outbound ivr action":
            return "Action: Outbound IVR"
        case "fetch external data action":
            return "Action: Fetch External Data"
        case "undefined process":
            return "Undefined Process"
    }
}
