import { useCallback } from "react"
import { useStore, getStraightPath, Position, Node, EdgeProps } from "reactflow"

const FloatingEdge = ({ id, source, target, markerEnd, style }: EdgeProps) => {
    const sourceNode = useStore(useCallback(store => store.nodeInternals.get(source), [source]))
    const targetNode = useStore(useCallback(store => store.nodeInternals.get(target), [target]))

    if (!sourceNode || !targetNode) {
        return null
    }

    const { sx, sy, tx, ty }: { sx: number; sy: number; tx: number; ty: number } = getEdgeParams(sourceNode, targetNode)

    const [edgePath] = getStraightPath({
        sourceX: sx,
        sourceY: sy,
        targetX: tx,
        targetY: ty
    })

    return <path id={id} d={edgePath} markerEnd={markerEnd} style={style} />
}

export default FloatingEdge

const getEdgeParams = (source: Node, target: Node) => {
    const sourceIntersectionPoint = getNodeIntersection(source, target)
    const targetIntersectionPoint = getNodeIntersection(target, source)

    const sourcePos = getEdgePosition(source, sourceIntersectionPoint)
    const targetPos = getEdgePosition(target, targetIntersectionPoint)

    return {
        sx: sourceIntersectionPoint.x,
        sy: sourceIntersectionPoint.y,
        tx: targetIntersectionPoint.x,
        ty: targetIntersectionPoint.y,
        sourcePos,
        targetPos
    }
}

const getNodeIntersection = (intersectionNode: Node, targetNode: Node): { x: number; y: number } => {
    const { width: intersectionNodeWidth, height: intersectionNodeHeight, positionAbsolute: intersectionNodePosition } = intersectionNode
    const targetPosition = targetNode.positionAbsolute

    if (
        intersectionNodePosition === undefined ||
        targetPosition === undefined ||
        typeof intersectionNodeWidth !== "number" ||
        typeof intersectionNodeHeight !== "number"
    ) {
        return { x: 0, y: 0 }
    }

    const w = intersectionNodeWidth / 2
    const h = intersectionNodeHeight / 2

    const x2 = intersectionNodePosition.x + w
    const y2 = intersectionNodePosition.y + h
    const x1 = targetPosition.x + w
    const y1 = targetPosition.y + h

    const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h)
    const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h)
    const a = 1 / (Math.abs(xx1) + Math.abs(yy1))
    const xx3 = a * xx1
    const yy3 = a * yy1
    const x = w * (xx3 + yy3) + x2
    const y = h * (-xx3 + yy3) + y2

    return { x, y }
}

const getEdgePosition = (node: Node, intersectionPoint: { x: number; y: number }) => {
    const n = { ...node.positionAbsolute, ...node }

    const x = n.x
    const y = n.y
    const width = n.width ?? undefined
    const height = n.height ?? undefined

    if (x === undefined || y === undefined || width === undefined || height === undefined) return Position.Top

    const nx = Math.round(x)
    const ny = Math.round(y)
    const px = Math.round(intersectionPoint.x)
    const py = Math.round(intersectionPoint.y)

    if (px <= nx + 1) {
        return Position.Left
    }
    if (px >= nx + width - 1) {
        return Position.Right
    }
    if (py <= ny + 1) {
        return Position.Top
    }
    if (py >= y + height - 1) {
        return Position.Bottom
    }

    return Position.Top
}
