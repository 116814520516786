import { useEffect } from 'react';
import { isEmpty, last } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { loadGlobalConfiguration } from '../actions/configurationActions';
import { useClient } from 'invevo-react-components';

const useGlobalConfig = () => {
  const client = useClient()
  const dispatch = useDispatch();

  const globalConfiguration = useSelector((state) => {
    let configuration = {};
    
    if (state.globalConfiguration.length > 0) {
      configuration = last(state.globalConfiguration);
    }
    return configuration;
  });

  useEffect(() => {
    if(!client) {
      return
    }
    if (isEmpty(globalConfiguration)) {
      dispatch(loadGlobalConfiguration(client));
    }
  }, [globalConfiguration, dispatch, client]);

  return globalConfiguration;
};

export default useGlobalConfig;
