import React from 'react';
import { connect } from 'react-redux';
import { setAccountValue } from '../../api/customerApi';
import AccountValue from './accountValue';
import AccountCard from './accountCard';
import AccountDetail from './accountDetail';
import { authZ_Permissions } from '../../constants';
import { hasPermission } from '../../utility/authZ';
import { useClient } from 'invevo-react-components';

const AccountSection = ({
  accountDetails,
  setAccountValue,
  sectionName,
  open,
  setOpen,
  myPermissions,
  updateUi
}) => {
  const client = useClient()
  const updateSectionUi = (section, name) => (value) =>
    updateUi(section, name, value);
  const hasCustomerEditPerm = hasPermission(
    myPermissions,
    authZ_Permissions.CustomerEdit
  );

  return (
    <AccountCard
      title={sectionName}
      id={`card-${sectionName}`}
      open={open}
      setOpen={setOpen}
      style={{ minHieght: '120px' }}
    >
      {accountDetails &&
        accountDetails.length > 0 &&
        accountDetails.map((item) => {
          return (
            <AccountDetail key={`detail-${item.section}-${item.id}`}>
              <AccountValue
                intiForceReadonly={!hasCustomerEditPerm}
                detail={item}
                alt={false}
                save={(customerId, fieldId, value) => setAccountValue(client, customerId, fieldId, value)}
                updateUi={updateSectionUi(item.section, item.title)}
              />
            </AccountDetail>
          );
        })}
    </AccountCard>
  );
};

const mapStateToProps = (state) => {
  return {
    myPermissions: state.authReducer.permissions
  };
};

export default connect(mapStateToProps, { setAccountValue })(AccountSection);
