import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { compact } from 'lodash';

import { getLookup } from '../../../api/lookupApi';
import { showToastErrorMessage } from '../../../api/toasterApi';
import { widthStyle } from '../../../utility/selectSupport';

class AddDocumentType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      positions: [
        { Id: 1, Description: '1' },
        { Id: 2, Description: '2' },
        { Id: 3, Description: '3' },
        { Id: 4, Description: '4' },
        { Id: 5, Description: '5' },
        { Id: 6, Description: '6' },
        { Id: 7, Description: '7' },
        { Id: 8, Description: '8' },
        { Id: 9, Description: '9' },
        { Id: 10, Description: '10' },
        { Id: 11, Description: '11' },
        { Id: 12, Description: '12' }
      ],
      documentType: Object.assign(
        {},
        props.fileImportDocumentType.documentType
      ),
      isValid: false,
      validFormats: [
        'dd-MM-yyyy',
        'dd-MMM-yy',
        'dd-MM-yy',
        'dd-MMM-yyyy',
        'MM-dd-yyyy',
        'MM-dd-yy',
        'MMM-dd-yy',
        'MMM-dd-yyyy',
        'yyyy-MM-dd',
        'yy-MM-dd',
        'ddMMyyyy',
        'ddMMMyy',
        'ddMMyy',
        'ddMMMyyyy',
        'MMddyyyy',
        'MMddyy',
        'MMMddyy',
        'MMMddyyyy',
        'yyyyMMdd',
        'yyMMdd',
        'dd/MM/yyyy',
        'dd/MMM/yy',
        'dd/MM/yy',
        'dd/MMM/yy',
        'dd/MMM/yyyy',
        'MM/dd/yyyy',
        'MM/dd/yy',
        'MMM/dd/yy',
        'yyyy.MM.dd',
        'yyyy/MM/dd'
      ]
    };
  }

  componentDidMount() {
    this.props.getLookup(this.props.client, 'import-configuration/location/lookup', 'locations');
    this.props.getLookup(this.props.client, 'lookup/documenttypes', 'documentTypes');
    this.validateForm();
  }

  onChange = (event) => {
    const field = event.target.name;
    const documentType = Object.assign({}, this.state.documentType);

    documentType[field] = event.target.value;

    return this.setState({ documentType: documentType }, () =>
      this.validateForm()
    );
  };

  onChecked = (event) => {
    const field = event.target.name;
    const documentType = Object.assign({}, this.state.documentType);

    documentType[field] = event.target.checked;

    return this.setState({ documentType: documentType }, () =>
      this.validateForm()
    );
  };

  onSelectedPositions = (propertyName, positions) => {
    if (positions === null) positions = [];

    const documentType = Object.assign({}, this.state.documentType);
    documentType[propertyName] = positions;
    return this.setState(
      {
        documentType: documentType
      },
      () => this.validateForm()
    );
  };

  onSelectedImportLocation = (location) => {
    const documentType = Object.assign({}, this.state.documentType);
    documentType.importLocation = location;
    return this.setState({ documentType: documentType }, () =>
      this.validateForm()
    );
  };

  onSelectedFixedDocumentType = (fixedDocumentType) => {
    const documentType = Object.assign({}, this.state.documentType);
    documentType.fixedDocumentType = fixedDocumentType;
    return this.setState({ documentType: documentType }, () =>
      this.validateForm()
    );
  };

  validateForm = () => {
    const {
      delimiter,
      filenamePattern,
      importLocation,
      name,
      order,
      workflowQueueName,
      documentDateFormat
    } = this.state.documentType;

    const isValid =
      !!name &&
      name !== '' &&
      !!filenamePattern &&
      filenamePattern !== '' &&
      !!importLocation &&
      importLocation.id > 0 &&
      !!delimiter &&
      delimiter !== '' &&
      order !== '' &&
      order > -1 &&
      !!workflowQueueName &&
      workflowQueueName !== '' &&
      (!documentDateFormat ||
        this.state.validFormats.includes(documentDateFormat));

    this.setState({ isValid: isValid });
  };

  onSave = () => {
    const {
      accountCodePositions,
      documentDateFormat,
      documentDatePositions,
      documentTypePositions,
      fixedDocumentType,
      uniqueTransactionRefPositions,
      useSystemDate,
      customerIdPositions,
      transactionRefPositions,
      transactionIdPositions
    } = this.state.documentType;

    const hasAccountCodePosition =
      !!accountCodePositions && accountCodePositions.length > 0;
    const hasCustomerIdPosition =
      !!customerIdPositions && customerIdPositions.length > 0;
    const hasUniqueTransactionRefPosition =
      !!uniqueTransactionRefPositions &&
      uniqueTransactionRefPositions.length > 0;
    const hasTransactionRefPosition =
      !!transactionRefPositions && transactionRefPositions.length > 0;
    const hasTransactionIdPosition =
      !!transactionIdPositions && transactionIdPositions.length > 0;
    const hasDocumentTypePosition =
      !!documentTypePositions && documentTypePositions.length > 0;
    const hasFixedDocumentType =
      !!fixedDocumentType && fixedDocumentType.Id > 0;
    const hasDocumentDatePosition =
      !!documentDatePositions && documentDatePositions.length > 0;
    const hasDateFormat = !!documentDateFormat && documentDateFormat !== '';

    if (
      !hasAccountCodePosition &&
      !hasUniqueTransactionRefPosition &&
      !hasCustomerIdPosition &&
      !hasTransactionRefPosition &&
      !hasTransactionIdPosition
    ) {
      showToastErrorMessage(
        'Please enter one of Account Code Positions, MIA Customer ID Positions, Unique Transaction Ref Positions, Transaction Ref Positions, Transaction ID Positions.'
      );
      return;
    }

    if (
      compact([
        hasAccountCodePosition,
        hasCustomerIdPosition,
        hasUniqueTransactionRefPosition,
        hasTransactionRefPosition,
        hasTransactionIdPosition
      ]).length > 1
    ) {
      showToastErrorMessage(
        'Please enter one of Account Code Positions, MIA Customer ID Positions, Unique Transaction Ref Positions, Transaction Ref Positions, Transaction ID Positions.'
      );
      return;
    }

    if (!hasDocumentTypePosition && !hasFixedDocumentType) {
      showToastErrorMessage(
        'Please enter Document Type Positions or a Fixed Document Type.'
      );
      return;
    }

    if (hasDocumentTypePosition && hasFixedDocumentType) {
      showToastErrorMessage(
        'Please remove Document Type Positions or a Fixed Document Type.'
      );
      return;
    }

    if (!hasDocumentDatePosition && !useSystemDate) {
      // eslint-disable-next-line quotes
      showToastErrorMessage(
        `Please enter 'Document Date Positions' or 'Use System Date'.`
      );
      return;
    }

    if (hasDocumentDatePosition && useSystemDate) {
      // eslint-disable-next-line quotes
      showToastErrorMessage(
        `Please remove 'Document Date Positions' or 'Use System Date'.`
      );
      return;
    }

    if (hasDocumentDatePosition && !hasDateFormat) {
      // eslint-disable-next-line quotes
      showToastErrorMessage(`Please enter a 'Document Date Format'.`);
      return;
    }

    this.props.onSave(this.state.documentType);
  };

  render() {
    const {
      accountCodePositions,
      delimiter,
      documentDateFormat,
      documentDatePositions,
      documentTypePositions,
      filenamePattern,
      fixedDocumentType,
      id,
      importLocation,
      name,
      order,
      isActive,
      uniqueTransactionRefPositions,
      useSystemDate,
      workflowQueueName,
      customerIdPositions,
      transactionRefPositions,
      transactionIdPositions
    } = this.state.documentType;
    const { documentTypes, locations } = this.props;
    const header = id
      ? `Edit Import Document Type ${id}`
      : 'Add Import Document Type';

    return (
      <div>
        <div
          key="import-document-type-form"
          className="card below-grid-form multi-column"
        >
          <h3>{header}</h3>

          <div className="form-group">
            <div className="form-control-feedback-wrapper warning">
              <span className="form-control-feedback">
                <i className="fa fa-asterisk" />
              </span>
            </div>
            <input
              name="name"
              type="text"
              placeholder="Name"
              className="form-control"
              value={name || ''}
              onChange={this.onChange}
              title="Name"
            />
          </div>
          <div className="form-group">
            <div className="form-control-feedback-wrapper warning">
              <span className="form-control-feedback">
                <i className="fa fa-asterisk" />
              </span>
            </div>
            <input
              name="filenamePattern"
              type="text"
              placeholder="Filename Pattern"
              className="form-control"
              value={filenamePattern || ''}
              onChange={this.onChange}
              title="Filename Pattern"
            />
          </div>
          <div className="form-group required" title="Import location">
            <div className="form-control-feedback-wrapper warning">
              <span className="form-control-feedback">
                <i className="fa fa-asterisk" />
              </span>
            </div>
            <Select
              name="import-location-select"
              isMulti={false}
              value={importLocation}
              options={locations.results}
              getOptionLabel={(o) => o.name}
              getOptionValue={(o) => o.id}
              onChange={this.onSelectedImportLocation}
              placeholder="Import location"
              styles={{ control: widthStyle(480) }}
            />
          </div>
          <div className="form-group">
            <div className="form-control-feedback-wrapper warning">
              <span className="form-control-feedback">
                <i className="fa fa-asterisk" />
              </span>
            </div>
            <input
              name="delimiter"
              type="text"
              placeholder="Delimiter"
              className="form-control"
              value={delimiter || ''}
              onChange={this.onChange}
              title="Delimiter"
            />
          </div>
          <div className="form-group">
            <div className="form-control-feedback-wrapper warning">
              <span className="form-control-feedback">
                <i className="fa fa-asterisk" />
              </span>
            </div>
            <input
              name="workflowQueueName"
              type="text"
              placeholder="Workflow Queue Name"
              className="form-control"
              value={workflowQueueName || ''}
              onChange={this.onChange}
              title="Workflow Queue Name (Examples: EventQueueLow, EventQueueHigh)"
            />
          </div>
          <div className="form-group">
            <div className="form-control-feedback-wrapper warning">
              <span className="form-control-feedback">
                <i className="fa fa-asterisk" />
              </span>
            </div>
            <input
              name="order"
              type="number"
              placeholder="Order"
              className="form-control"
              value={order || ''}
              onChange={this.onChange}
              title="Order"
            />
          </div>
          <div className="form-group">
            <label>Account / Transaction:</label>
          </div>
          <div className="row form-group">
            <div className="col-md-4" title="Account code positions">
              <Select
                name="import-account-code-positions"
                isMulti
                value={accountCodePositions}
                options={this.state.positions}
                getOptionLabel={(o) => o.Description}
                getOptionValue={(o) => o.Id}
                onChange={(e) =>
                  this.onSelectedPositions('accountCodePositions', e)
                }
                placeholder="Account code positions"
              />
            </div>
            <div className="col-md-4" title="MIA Customer Id Positions">
              <Select
                name="import-cs-id-positions"
                isMulti
                value={customerIdPositions}
                options={this.state.positions}
                getOptionLabel={(o) => o.Description}
                getOptionValue={(o) => o.Id}
                onChange={(e) =>
                  this.onSelectedPositions('customerIdPositions', e)
                }
                placeholder="MIA Customer Id positions"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-4" title="Unique Transaction Ref Positions">
              <Select
                name="import-u-tx-ref-positions"
                isMulti
                value={uniqueTransactionRefPositions}
                options={this.state.positions}
                getOptionLabel={(o) => o.Description}
                getOptionValue={(o) => o.Id}
                onChange={(e) =>
                  this.onSelectedPositions('uniqueTransactionRefPositions', e)
                }
                placeholder="Unique Transaction Ref Positions"
              />
            </div>
            <div className="col-md-4" title="Transaction Ref Positions">
              <Select
                name="import-tx-ref-positions"
                isMulti
                value={transactionRefPositions}
                options={this.state.positions}
                getOptionLabel={(o) => o.Description}
                getOptionValue={(o) => o.Id}
                onChange={(e) =>
                  this.onSelectedPositions('transactionRefPositions', e)
                }
                placeholder="Transaction Ref Positions"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-8" title="MIA TransactionID Positions">
              <Select
                name="import-tx-id-positions"
                isMulti
                value={transactionIdPositions}
                options={this.state.positions}
                getOptionLabel={(o) => o.Description}
                getOptionValue={(o) => o.Id}
                onChange={(e) =>
                  this.onSelectedPositions('transactionIdPositions', e)
                }
                placeholder="MIA TransactionID Positions"
              />
              <span id="helpBlock" className="help-block text-warning">
                Select Account Code Positions or MIA Customer ID Positions or
                Unique Transaction Ref Positions or Transaction Ref positions or
                MIA Transaction ID positions.
              </span>
            </div>
          </div>
          <div className="form-group">
            <label>Document Type:</label>
          </div>
          <div className="row form-group">
            <div className="col-md-4" title="Document Type Positions">
              <Select
                name="import-doc-type-positions"
                isMulti
                value={documentTypePositions}
                options={this.state.positions}
                getOptionLabel={(o) => o.Description}
                getOptionValue={(o) => o.Id}
                onChange={(e) =>
                  this.onSelectedPositions('documentTypePositions', e)
                }
                placeholder="Document Type Positions"
              />
              <span id="helpBlock" className="help-block text-warning">
                Select Document Type Positions or a Fixed Document Type.
              </span>
            </div>
            <div className="col-md-4" title="Fixed Document Type">
              <Select
                name="fixed-document-type-select"
                isMulti={false}
                value={fixedDocumentType}
                options={documentTypes.results}
                getOptionLabel={(o) => o.Name}
                getOptionValue={(o) => o.Id}
                onChange={this.onSelectedFixedDocumentType}
                placeholder="Fixed Document Type"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-3" title="Document Date Positions">
              <Select
                name="import-doc-date-positions"
                isMulti
                value={documentDatePositions}
                options={this.state.positions}
                getOptionLabel={(o) => o.Description}
                getOptionValue={(o) => o.Id}
                onChange={(e) =>
                  this.onSelectedPositions('documentDatePositions', e)
                }
                placeholder="Document Date Positions"
              />
              <span id="helpBlock" className="help-block text-warning">
                Select Document Date Positions or Use System Date.
              </span>
            </div>
            <div className="col-md-3">
              <input
                name="documentDateFormat"
                type="text"
                placeholder="Document Date Format"
                className="form-control"
                value={documentDateFormat || ''}
                onChange={this.onChange}
                title="Source Format (valid entries dd-MM-yyyy, dd-MMM-yy, dd-MM-yy, dd-MMM-yyyy, MM-dd-yyyy, MM-dd-yy, MMM-dd-yy, MMM-dd-yyyy, yyyy-MM-dd, yy-MM-dd, ddMMyyyy, ddMMMyy, ddMMyy, ddMMMyyyy, MMddyyyy, MMddyy, MMMddyy, MMMddyyyy, yyyyMMdd, yyMMdd, dd/MM/yyyy, dd/MMM/yy, dd/MM/yy, dd/MMM/yy, dd/MMM/yyyy, MM/dd/yyyy, MM/dd/yy, MMM/dd/yy, yyyy.MM.dd, yyyy/MM/dd)"
              />
            </div>
            <div className="col-md-2">
              <div className="checkbox">
                <label>
                  <input
                    name="useSystemDate"
                    type="checkbox"
                    checked={useSystemDate || false}
                    onChange={this.onChecked}
                  />{' '}
                  Use System Date
                </label>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="checkbox">
              <label>
                <input
                  name="isActive"
                  type="checkbox"
                  checked={isActive || false}
                  onChange={this.onChecked}
                />{' '}
                Is Active
              </label>
            </div>
          </div>
          <div className="form-group">
            <button
              className="btnDefault btn btn-default"
              disabled={!this.state.isValid}
              onClick={this.onSave}
            >
              Save
            </button>
            <button className="btn btn-default" onClick={this.props.onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fileImportDocumentType: state.fileImportDocumentType,
    locations: state.lookupReducer.locations,
    documentTypes: state.lookupReducer.documentTypes
  };
}

export default connect(mapStateToProps, { getLookup })(AddDocumentType);
