import { useClient, useConfig } from "invevo-react-components"
import { useEffect, useState } from "react"
import { useTranslation } from "../../contexts/TranslationContext"
import useApiQuery from "../../hooks/useApiQuery"
import { TranslationRequestDto } from "./types/TranslationRequestDto"
import { TranslationResultDto } from "./types/TranslationResultDto"

type TranslateProps = {
    children: String
}

const Translate = ({ children } : TranslateProps) => {
    const [translationState, setTranslationState] = useTranslation()
    const [translatedText, setTranslatedText] = useState(children)

    const client = useClient()
    const config = useConfig()

    const api = useApiQuery<TranslationResultDto>({
        isAnonymous: true,
        url: `${config.PLATFORM_API_URL}/api/${client}/translate`,
        method: "POST",
        isExecutedAutomatically: false
    })

    useEffect(() => {
        if(!translationState.preferredLanguageCode || translationState.preferredLanguageCode === "en") {
            setTranslatedText(children)
            return
        }

        const dictionary = translationState.dictionaries && translationState.dictionaries[translationState.preferredLanguageCode]
        if(dictionary && dictionary[children.toLowerCase()]) {
            setTranslatedText(dictionary[children.toLowerCase()])
            return
        }   

        const dto: TranslationRequestDto = {
            languageCode: translationState.preferredLanguageCode,
            textToTranslate: children
        }

        api.execute(undefined, dto)
            .then(res => {
                const text = res.data.result
                setTranslatedText(text)
                const updatedDictionaries = { 
                    ...translationState.dictionaries, 
                    [translationState.preferredLanguageCode]: {
                        ...dictionary, 
                        [children.toLowerCase()]: text
                    }}
                setTranslationState({...translationState, dictionaries: updatedDictionaries})
            })
            .catch(error => console.error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translationState.preferredLanguageCode])

    return (
        <>
            {translatedText}
        </>
    )    
}

export default Translate