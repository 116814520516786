import { convertFromArrayToDto, convertFromDtoToArray } from "../../../../library/FilterList/FiltersDto"
import DataField from "../../../../types/DataField"
import DataPrimitive from "../../../../types/DataPrimitive"
import Lookup from "../../../../types/Lookup"
import ActionsDto from "./ActionsDto"
import ActionType from "./ActionType"
import AsyncCommAction from "./AsyncCommAction"
import ExportAction, { ExportActionState } from "./ExportAction"
import SimpleAction from "./SimpleAction"
import UpdateDataFieldAction from "./UpdateDataFieldAction"
import { v4 as uuidv4 } from "uuid"
import { UpdateChildEntityAction, FieldUpdate } from "./UpdateChildEntityAction"

type GenericAction = SimpleAction | AsyncCommAction | UpdateDataFieldAction | ExportActionState | UpdateChildEntityAction

export default GenericAction

export const convertFromActionsArrayToDto = (actions: GenericAction[], fields: DataField[]): ActionsDto => ({
    simpleActions: actions
        .filter(action => action.type === ActionType.SIMPLE_ACTION)
        .map(action => action as SimpleAction)
        .map(action => ({
            actionReference: action.actionReference,
            filters: convertFromArrayToDto(action.filters, true),
            rateLimiter: action.rateLimiter,
            actionType: "RefreshOpenBankingData"
        })),
    asyncCommActions: actions
        .filter(action => action.type === ActionType.ASYNC_COMM_ACTION)
        .map(action => action as AsyncCommAction)
        .map(action => ({
            actionReference: action.actionReference,
            filters: convertFromArrayToDto(action.filters, true),
            rateLimiter: action.rateLimiter,
            communicationType: action.communicationType,
            communicationTemplateReference: action.communicationTemplateReference,
            contactSelectionType: action.contactSelectionType,
            contactFilters: convertFromArrayToDto(action.contactFilters, true)
        })),
    updateDataFieldActions: actions
        .filter(action => action.type === ActionType.UPDATE_DATA_FIELD)
        .map(action => action as UpdateDataFieldAction)
        .map(action => ({
            actionReference: action.actionReference,
            filters: convertFromArrayToDto(action.filters, true),
            fieldType: action.fieldType,
            booleanFieldUpdates: convertValuesToNullableBoolean(filterByDataPrimitiveAndType(action.fieldUpdates, fields, DataPrimitive.BOOLEAN, "set")),
            numberFieldUpdates: convertValuesToNullableNumber(filterByDataPrimitiveAndType(action.fieldUpdates, fields, DataPrimitive.NUMBER, "set")),
            datetimeFieldUpdates: convertValuesToNullableString(filterByDataPrimitiveAndType(action.fieldUpdates, fields, DataPrimitive.DATE, "set")),
            stringFieldUpdates: convertValuesToNullableString({
                ...filterByDataPrimitiveAndType(action.fieldUpdates, fields, DataPrimitive.TEXT, "set"),
                ...filterByDataPrimitiveAndType(action.fieldUpdates, fields, DataPrimitive.LOOKUP, "set")
            }),
            numberFieldIncrements: convertValuesToNullableNumber(filterByDataPrimitiveAndType(action.fieldUpdates, fields, DataPrimitive.NUMBER, "increment")),
            booleanFromEntityFieldUpdates: action.fieldUpdates
                .filter(
                    fieldUpdate => fieldUpdate.sourceType === "field" && fields.find(field => field.value === fieldUpdate.field)?.type === DataPrimitive.BOOLEAN
                )
                .map(fieldUpdate => ({
                    targetFieldName: fieldUpdate.field,
                    sourceFieldName: fieldUpdate.value
                })),
            numberFromEntityFieldUpdates: action.fieldUpdates
                .filter(
                    fieldUpdate => fieldUpdate.sourceType === "field" && fields.find(field => field.value === fieldUpdate.field)?.type === DataPrimitive.NUMBER
                )
                .map(fieldUpdate => ({
                    targetFieldName: fieldUpdate.field,
                    sourceFieldName: fieldUpdate.value
                })),
            dateTimeFromEntityFieldUpdates: action.fieldUpdates
                .filter(
                    fieldUpdate => fieldUpdate.sourceType === "field" && fields.find(field => field.value === fieldUpdate.field)?.type === DataPrimitive.DATE
                )
                .map(fieldUpdate => ({
                    targetFieldName: fieldUpdate.field,
                    sourceFieldName: fieldUpdate.value
                })),
            stringFromEntityFieldUpdates: action.fieldUpdates
                .filter(
                    fieldUpdate =>
                        fieldUpdate.sourceType === "field" &&
                        (fields.find(field => field.value === fieldUpdate.field)?.type === DataPrimitive.TEXT ||
                            fields.find(field => field.value === fieldUpdate.field)?.type === DataPrimitive.LOOKUP)
                )
                .map(fieldUpdate => ({
                    targetFieldName: fieldUpdate.field,
                    sourceFieldName: fieldUpdate.value
                })),
            relativeDateTimeUpdates: action.fieldUpdates
                .filter(fieldUpdate => fieldUpdate.sourceType === "now")
                .map(fieldUpdate => ({
                    fieldName: fieldUpdate.field,
                    amountOfDaysRelativeToNow: Number(fieldUpdate.value)
                })),
            generateGuidStringFields: action.fieldUpdates.filter(fieldUpdate => fieldUpdate.sourceType === "unique id").map(fieldUpdate => fieldUpdate.field)
        })),
    updateChildEntityActions: actions
        .filter(action => action.type === ActionType.UPDATE_CHILD_ENTITY)
        .map(action => action as UpdateChildEntityAction)
        .map(action => ({
            actionReference: action.actionReference,
            targetEntityFilters: convertFromArrayToDto(action.targetEntityFilters, true),
            entityTypeReference: action.entityTypeReference,
            booleanFromEntityFieldUpdates: action.fieldUpdates
                .filter((fieldUpdate: FieldUpdate) => fieldUpdate.primitive === DataPrimitive.BOOLEAN)
                .map((fieldUpdate: FieldUpdate) => ({
                    targetFieldName: fieldUpdate.field,
                    sourceFieldName: fieldUpdate.value
                })),
            numberFromEntityFieldUpdates: action.fieldUpdates
                .filter((fieldUpdate: FieldUpdate) => fieldUpdate.primitive === DataPrimitive.NUMBER)
                .map((fieldUpdate: FieldUpdate) => ({
                    targetFieldName: fieldUpdate.field,
                    sourceFieldName: fieldUpdate.value
                })),
            dateTimeFromEntityFieldUpdates: action.fieldUpdates
                .filter((fieldUpdate: FieldUpdate) => fieldUpdate.primitive === DataPrimitive.DATE)
                .map((fieldUpdate: FieldUpdate) => ({
                    targetFieldName: fieldUpdate.field,
                    sourceFieldName: fieldUpdate.value
                })),
            stringFromEntityFieldUpdates: action.fieldUpdates
                .filter((fieldUpdate: FieldUpdate) => fieldUpdate.primitive === DataPrimitive.TEXT || fieldUpdate.primitive === DataPrimitive.LOOKUP)
                .map((fieldUpdate: FieldUpdate) => ({
                    targetFieldName: fieldUpdate.field,
                    sourceFieldName: fieldUpdate.value
                }))
        })),
    exportActions: actions
        .filter(action => action.type === ActionType.EXPORT_ACTION)
        .map(action => action as ExportAction)
        .map(action => ({
            actionReference: action.actionReference,
            fileEntityExportConfigReference: action.fileEntityExportConfigReference
        }))
})

export const convertFromActionsDtoToArray = (actions: ActionsDto, fields: DataField[], lookups: Lookup[]): GenericAction[] => [
    ...((actions.simpleActions ?? []).map(action => ({
        ...action,
        filters: convertFromDtoToArray(action.filters, fields, lookups),
        type: ActionType.SIMPLE_ACTION
    })) as SimpleAction[]),
    ...((actions.asyncCommActions ?? []).map(action => ({
        ...action,
        filters: convertFromDtoToArray(action.filters, fields, lookups),
        contactFilters: convertFromDtoToArray(action.contactFilters, fields, lookups),
        type: ActionType.ASYNC_COMM_ACTION
    })) as AsyncCommAction[]),
    ...((actions.updateDataFieldActions ?? []).map(action => ({
        type: ActionType.UPDATE_DATA_FIELD,
        actionReference: action.actionReference,
        filters: convertFromDtoToArray(action.filters, fields, lookups),
        fieldType: action.fieldType,
        fieldUpdates: [
            ...Object.keys(action.booleanFieldUpdates ?? {}).map(key => ({
                id: uuidv4(),
                field: key,
                value: action.booleanFieldUpdates[key]?.toString() ?? "",
                type: "set",
                sourceType: "value"
            })),
            ...Object.keys(action.numberFieldUpdates ?? {}).map(key => ({
                id: uuidv4(),
                field: key,
                value: action.numberFieldUpdates[key]?.toString() ?? "",
                type: "set",
                sourceType: "value"
            })),
            ...Object.keys(action.datetimeFieldUpdates ?? {}).map(key => ({
                id: uuidv4(),
                field: key,
                value: action.datetimeFieldUpdates[key]?.toString() ?? "",
                type: "set",
                sourceType: "value"
            })),
            ...Object.keys(action.stringFieldUpdates ?? {}).map(key => ({
                id: uuidv4(),
                field: key,
                value: action.stringFieldUpdates[key]?.toString() ?? "",
                type: "set",
                sourceType: "value"
            })),
            ...Object.keys(action.numberFieldIncrements ?? {}).map(key => ({
                id: uuidv4(),
                field: key,
                value: action.numberFieldIncrements[key]?.toString() ?? "",
                type: "increment",
                sourceType: "value"
            })),
            ...(action.numberFromEntityFieldUpdates?.map(value => ({
                id: uuidv4(),
                field: value.targetFieldName,
                value: value.sourceFieldName,
                type: "set",
                sourceType: "field"
            })) ?? []),
            ...(action.stringFromEntityFieldUpdates?.map(value => ({
                id: uuidv4(),
                field: value.targetFieldName,
                value: value.sourceFieldName,
                type: "set",
                sourceType: "field"
            })) ?? []),
            ...(action.dateTimeFromEntityFieldUpdates?.map(value => ({
                id: uuidv4(),
                field: value.targetFieldName,
                value: value.sourceFieldName,
                type: "set",
                sourceType: "field"
            })) ?? []),
            ...(action.booleanFromEntityFieldUpdates?.map(value => ({
                id: uuidv4(),
                field: value.targetFieldName,
                value: value.sourceFieldName,
                type: "set",
                sourceType: "field"
            })) ?? []),
            ...(action.relativeDateTimeUpdates?.map(value => ({
                id: uuidv4(),
                field: value.fieldName,
                value: value.amountOfDaysRelativeToNow.toString(),
                type: "set",
                sourceType: "now"
            })) ?? []),
            ...(action.generateGuidStringFields?.map(value => ({
                id: uuidv4(),
                field: value,
                value: "",
                type: "set",
                sourceType: "unique id"
            })) ?? [])
        ]
    })) as UpdateDataFieldAction[]),
    ...((actions.exportActions ?? []).map(action => ({
        ...action,
        type: ActionType.EXPORT_ACTION
    })) as ExportAction[]),
    ...((actions.updateChildEntityActions ?? []).map(action => ({
        type: ActionType.UPDATE_CHILD_ENTITY,
        actionReference: action.actionReference,
        targetEntityFilters: convertFromDtoToArray(action.targetEntityFilters, fields, lookups),
        entityTypeReference: action.entityTypeReference,
        fieldUpdates: [
            ...action.booleanFromEntityFieldUpdates.map(value => ({
                id: uuidv4(),
                field: value.targetFieldName,
                value: value.sourceFieldName,
                primitive: DataPrimitive.BOOLEAN
            })),
            ...action.dateTimeFromEntityFieldUpdates.map(value => ({
                id: uuidv4(),
                field: value.targetFieldName,
                value: value.sourceFieldName,
                primitive: DataPrimitive.DATE
            })),
            ...action.numberFromEntityFieldUpdates.map(value => ({
                id: uuidv4(),
                field: value.targetFieldName,
                value: value.sourceFieldName,
                primitive: DataPrimitive.NUMBER
            })),
            ...action.stringFromEntityFieldUpdates.map(value => ({
                id: uuidv4(),
                field: value.targetFieldName,
                value: value.sourceFieldName,
                primitive: DataPrimitive.TEXT
            }))
        ]
    })) as UpdateChildEntityAction[])
]

const convertValuesToNullableString = (values: { [key: string]: string }): { [key: string]: string | null } => {
    const result: { [key: string]: string | null } = {}
    for (const key in values) {
        if (values[key] === "") {
            result[key] = null
        } else {
            result[key] = values[key]!
        }
    }
    return result
}

const convertValuesToNullableNumber = (values: { [key: string]: string }): { [key: string]: number | null } => {
    const result: { [key: string]: number | null } = {}
    for (const key in values) {
        const value = Number(values[key])
        if (isNaN(value)) {
            result[key] = null
        } else {
            result[key] = value
        }
    }
    return result
}

const convertValuesToNullableBoolean = (values: { [key: string]: string }): { [key: string]: boolean | null } => {
    const result: { [key: string]: boolean | null } = {}
    for (const key in values) {
        if (values[key] === "true") {
            result[key] = true
        } else if (values[key] === "false") {
            result[key] = false
        } else {
            result[key] = null
        }
    }
    return result
}

const filterByDataPrimitiveAndType = (
    fieldUpdates: {
        field: string
        value: string
        type: "set" | "increment"
        sourceType: "field" | "value" | "now" | "unique id"
    }[],
    fields: DataField[],
    dataPrimitive: DataPrimitive,
    type: "set" | "increment"
): { [key: string]: string } => {
    const result: { [key: string]: string } = {}
    fieldUpdates.forEach(fieldUpdate => {
        const field = fields.find(field => field.value === fieldUpdate.field && fieldUpdate.sourceType === "value")
        if (field?.type === dataPrimitive && fieldUpdate.type === type) {
            result[fieldUpdate.field] = fieldUpdate.value
        }
    })
    return result
}
