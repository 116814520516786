import { isNil, mapKeys } from 'lodash';
import { receiveMenu } from '../actions/dashboardActions';
import { errorLookup, receiveLookup, requestLookup } from '../actions/lookupActions';
import { uiConfigLoadDefaultConfigRequestReceived } from '../actions/uiConfigActions';
import buildParameterString from '../utility/buildParameterString';
import { api, getApiUrl, UserConfigViewType } from '../constants';
import displayError from '../utility/error';
import { authReducerStates } from '../reducers/auth_reducer';
import { webApiInterface } from './webApiInterface';
import {
  selectTransaction,
  setGroupId,
  foundMatchingGroup
} from '../actions/functionActions';

export const isGroup = (client, customerId, transactionIds) => {
  return (dispatch) => {
    webApiInterface
      .authPost(client, getApiUrl(client) + 'api/note/isGroup', dispatch, {
        customerId: customerId,
        transactionIds: transactionIds
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(foundMatchingGroup(client, json));
      })
      .catch((err) => {
        displayError(err);
      });
  };
};

export const getGroupTransactions = (client, groupId) => {
  return (dispatch) => {
    webApiInterface
      .authFetch(
        client,
        getApiUrl(client) + 'api/note/groupTransactions?groupId=' + groupId,
        dispatch
      )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(selectTransaction(json));
        dispatch(setGroupId(groupId));
      })
      .catch((err) => {
        displayError(err);
      });
  };
};

export const getLookup = (client, serviceName, lookupName, data) => {
  if (!client) {
    throw Error("client not provided in getLookup")
  }

  return (dispatch) => {
    let url = getApiUrl(client) + 'api/' + serviceName;
    if (data) {
      url += '?' + buildParameterString(data);
    }

    dispatch(requestLookup(lookupName));
    webApiInterface
      .authFetch(client, url, dispatch)
      .then(response => response.json())
      .then(json => dispatch(receiveLookup(json, lookupName)))
      .catch((err) => dispatch(errorLookup(err, lookupName)));
  };
};

export const postLookup = (client, serviceName, lookupName, data) => {
  return (dispatch) => {
    const url = getApiUrl(client) + 'api/' + serviceName;

    dispatch(requestLookup(lookupName));
    webApiInterface
      .authPost(client, url, dispatch, data, 'POST')
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(receiveLookup(json, lookupName));
      })
      .catch((err) => {
        displayError(err);
        dispatch(errorLookup(err, lookupName));
      });
  };
};

export const loadInitialData = (client, callback) => {
  return (dispatch) => {
    webApiInterface
      .authFetch(client, `${getApiUrl(client)}api/lookup/bootstrapmia`, dispatch)
      .then((response) => {
        return response.json();
      })
      .then(json => dispatchBootstrapMiaResults(dispatch, json))
      .then(json => callback(json))
      .catch((err) => {
        displayError(err);
      });
  };
};

export const dispatchBootstrapMiaResults = (dispatch, json) => {
  const lookups = json.lookup;
  if (!isNil(lookups)) {
    mapKeys(lookups, (value, key) => {
      dispatch(receiveLookup(value, key));

      // dispatch actions to update panelDescriptors in dashboard_reducer
      switch (key) {
        case 'transStatus':
          dispatch(receiveMenu(value, 'STATUS_MENU'));
          break;
        case 'transSubStatus':
          dispatch(receiveMenu(value, 'SUBSTATUS_MENU'));
          break;
        case 'transType':
          dispatch(receiveMenu(value, 'TRANSACTION_TYPES_MENU'));
          break;
        case 'taskTypes':
          dispatch(receiveMenu(value, 'TASK_TYPES_MENU'));
          break;
        case 'followUpReasons':
          dispatch(receiveMenu(value, 'FOLLOWUP_REASONS_MENU'));
          break;
        case 'accStat':
          dispatch(receiveMenu(value, 'ACCOUNT_STATUS_MENU'));
          break;
        default:
          break;
      }
    });
  }

  const {
    summaryConfig,
    transactionGridConfig,
    permissions,
    version,
    disableUIPermissionsGeneration,
    globalSettings,
    userLanguageCulture,
    invevoPermissions
  } = json;

  if (transactionGridConfig) {
    dispatch(
      uiConfigLoadDefaultConfigRequestReceived(
        UserConfigViewType.transactionGridColConfig,
        transactionGridConfig
      )
    );
  }

  if (summaryConfig) {
    dispatch(
      uiConfigLoadDefaultConfigRequestReceived(
        UserConfigViewType.summaryCardConfig,
        summaryConfig
      )
    );
  }

  dispatch(authReducerStates.SET_PERMISSIONS.action(invevoPermissions.concat(permissions)));
  dispatch(
    receiveLookup(
      {
        db: version.db,
        api: version.api,
        client: api.version
      },
      'version'
    )
  );
  dispatch(
    receiveLookup(
      disableUIPermissionsGeneration,
      'disableUIPermissionsGeneration'
    )
  );
  dispatch(receiveLookup(globalSettings, 'globalSettings'));
  //Add to a global variable so it is accessible in Kendo templates.
  window.miaGlobalSettings = globalSettings;

  dispatch(receiveLookup(userLanguageCulture, 'userLanguageCulture'));

  return json
}

export const checkCurrencyConfig = (client) => {
  return (dispatch) => {
    webApiInterface
      .authFetch(client, getApiUrl(client) + 'api/lookup/verifyCurrencyConfig', dispatch)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        const isCurrencyOk = json.isCurrencyOk;
        const errorMessage = json.errorMessage;

        if (!isCurrencyOk) {
          displayError(errorMessage);
        }
      })
      .catch((err) => {
        displayError(err);
      });
  };
};
