import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import {
  api,
  gridKeys,
  userTaskDateType,
  stateTaskType,
  getApiUrl
} from '../../constants'
import { GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid'
import UserTaskCard from './userTaskCard'
import { dismissFocusedTask, getFocusedTask, setUserTaskGridIndex } from '../../actions/taskActions'
import { destroySearchList } from '../../actions/searchActions'
import { getFocusedAccountAndTask, getFocusedAccount } from '../../api/currentSelectionApi'
import Nav from '../../api/navApi'
import StatefulGrid from '../common/grids/StatefulGrid'
import moment from 'moment'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ColumnMenu } from '../common/grids/columnMenu'
import { getUserTaskGrid } from '../../selectors/task'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { useClient, useInvevoSession } from 'invevo-react-components'
import Task from '../customerTasks/task'
import { useLayout } from '../../../../contexts/LayoutContext'
import { ActionStripItems, useActionStrip } from '../../../../contexts/ActionStripContext'
import { useCustomer } from '../../../../contexts/CustomerContext'
import { useEntity } from '../../../../contexts/EntityContext'

const allTaskIdsSelector = createSelector(
  (state) => state.gridCacheReducer.grids,
  (grids) => {
    const gridCache = getUserTaskGrid(grids)

    if (!gridCache || !gridCache.lastResponse) return []

    return gridCache.lastResponse.data.map(task => task.TaskId)
  }
)

const UserTaskGrid = ({
  accountId,
  tickedTaskIds,
  setTickedTaskIds,
  columns,
  setColumns,
  selectedTask,
  dateType,
  startDate,
  endDate,
  stateTaskTypeId,
  taskTypeId,
  getFocusedTask,
  setUserTaskGridIndex,
  destroySearchList,
  refresh = 0,
  isBulkActionSelected,
  allTickBoxTicked,
  setAllTickBoxTicked,
  getFocusedAccount,
  isGridView
}) => {
  const client = useClient()
  const [invevoSession, setInvevoSession] = useInvevoSession()
  const { navToAction } = useActionStrip()
  const { isLeftPanelExpanded } = useLayout()
  const [customer, setCustomer] = useCustomer()
  const [, setEntity] = useEntity()
  const userTaskGridRef = useRef()
  const {
    invevoTaskFeature,
    invevoStandaloneEmailTasks,
    invevoTaskGridPageSize,
    invevoTaskBulkAction,
    newEntityStructure
  } = useFlags()

  const [selectedTaskFullData, setSelectedTaskFullData] = useState({})

  const resetIndex = useCallback(() => setUserTaskGridIndex(), [setUserTaskGridIndex])

  useEffect(() => {
    // destroy search list before setting up new task list
    destroySearchList()
  }, [destroySearchList])

  useEffect(() => {
    if (!invevoTaskBulkAction) return

    // reset selected tasks if the filtered task type ever changes
    setTickedTaskIds([])
    setAllTickBoxTicked(false)
  }, [taskTypeId, setTickedTaskIds, invevoTaskBulkAction, setAllTickBoxTicked])

  const allTaskIds = useSelector(allTaskIdsSelector)

  const onHeaderTickBoxClick = useCallback(() => {
    const newAllTickBoxTickedState = !allTickBoxTicked
    setAllTickBoxTicked(newAllTickBoxTickedState)
    if (newAllTickBoxTickedState) {
      setTickedTaskIds(allTaskIds)
    } else {
      setTickedTaskIds([])
    }
  }, [allTaskIds, allTickBoxTicked, setAllTickBoxTicked, setTickedTaskIds])

  const onRowTickBoxClick = useCallback(event => {
    const selectedTaskId = event.dataItem.TaskId
    const newTickedTaskIds = tickedTaskIds.includes(selectedTaskId) ?
      tickedTaskIds.filter(taskId => taskId !== selectedTaskId) :
      [...tickedTaskIds, selectedTaskId]

    setTickedTaskIds(newTickedTaskIds)
    setAllTickBoxTicked(allTaskIds.every(v => newTickedTaskIds.includes(v)))
  }, [allTaskIds, setAllTickBoxTicked, setTickedTaskIds, tickedTaskIds])

  const onRowClick = useCallback(gridRowEvent => {
    const dataItem = gridRowEvent.dataItem
    setSelectedTaskFullData(dataItem)
    const grid = userTaskGridRef.current.state.data
    const index = grid.findIndex(
      (d) =>
        d.CustomerId === dataItem.CustomerId &&
        d.TaskId === dataItem.TaskId &&
        d.StatefulTaskType === dataItem.StatefulTaskType
    )

    setUserTaskGridIndex(index)

    const gridAccountId = dataItem.CustomerId
      ? dataItem.CustomerId
      : dataItem.VirtualAccountId
    const gridAccountIsVirtual = !dataItem.CustomerId

    const isCurrentAccount = accountId === gridAccountId
    if (!isCurrentAccount) {
      getFocusedAccount(
        client,
        gridAccountId,
        gridAccountIsVirtual,
        dataItem.TaskId,
        false
      )

      setInvevoSession({
        reloadList: invevoSession.reloadList ? invevoSession.reloadList : 0
      })


      newEntityStructure && setEntity({
        reference: dataItem.AccountCode,
        sqlId: gridAccountId,
        entityType: "customer"
      })

      setCustomer({
        reference: dataItem.AccountCode,
        sqlId: gridAccountId,
        isVirtualAccount: gridAccountIsVirtual,
        taskId: dataItem.TaskId
      })

      if (dataItem.StatefulTaskType === stateTaskType.AWAIT_CREDIT_LIMIT || dataItem.StatefulTaskType === stateTaskType.NEW_CREDIT_LIMIT) {
        navToAction(ActionStripItems.CREDIT_LIMIT)
      }
    } else if (selectedTask.id !== dataItem.TaskId && dataItem.StatefulTaskType !== stateTaskType.UNREAD_EMAIL) {
      getFocusedTask(dataItem.TaskId, false, client)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, client, getFocusedAccount, getFocusedTask, invevoSession.reloadList, selectedTask.id, setInvevoSession, setUserTaskGridIndex])

  const onStateChange = useCallback(data => {
    const isColumnActive = field => {
      return GridColumnMenuFilter.active(field, data.filter)
    }

    setColumns((prevColumns) =>
      prevColumns.map((c) => ({
        ...c,
        headerClassName: isColumnActive(c.field) ? 'active' : ''
      }))
    )
  }, [setColumns])

  const renderCard = useCallback(task => {
    return selectedTask.id === task.TaskId ?
      <td className="p-0">
        <Task
          customerId={selectedTaskFullData.CustomerId ? selectedTaskFullData.CustomerId : selectedTaskFullData.VirtualAccountId}
          isVirtualAccount={customer.isVirtualAccount}
          documentCount={selectedTaskFullData.DocumentsCount}
        />
      </td> :
      <UserTaskCard dataItem={task} onClick={onRowClick} />
  }, [customer.isVirtualAccount, onRowClick, selectedTask.id, selectedTaskFullData])

  const additionalRequestPayload = useCallback(() => {
    const payload = {
      taskTypeId: '',
      statefulTaskTypes: '',
      startDate: '',
      endDate: ''
    }

    if (invevoStandaloneEmailTasks) {
      payload.omitUnreadEmails = true
    }

    if (taskTypeId && taskTypeId > 0) {
      payload.taskTypeId = taskTypeId
    }

    if (stateTaskType[stateTaskTypeId] !== undefined) {
      payload.statefulTaskTypes = stateTaskTypeId
    }

    switch (dateType) {
      case userTaskDateType.RANGE:
        payload.startDate = moment(startDate)
          .local(true)
          .startOf('day')
          .toISOString()
        payload.endDate = moment(endDate)
          .local(true)
          .endOf('day')
          .toISOString()
        break
      case userTaskDateType.TOMORROW:
        payload.startDate = moment()
          .add(1, 'days')
          .local(true)
          .startOf('day')
          .toISOString()
        payload.endDate = moment()
          .add(1, 'days')
          .local(true)
          .endOf('day')
          .toISOString()
        break
      case userTaskDateType.TODAY:
        payload.endDate = moment().local(true).endOf('day').toISOString()
        break
      default:
        break
    }

    return payload
  }, [dateType, endDate, invevoStandaloneEmailTasks, startDate, stateTaskTypeId, taskTypeId])

  let url = getApiUrl(client) + 'api/statefultaskfeed'
  if (invevoTaskFeature) {
    url = api.taskUrl + 'task'
  }

  const showTickBoxes = isLeftPanelExpanded && invevoTaskBulkAction && isBulkActionSelected && taskTypeId !== 0

  const statefulGridElement = useMemo(() =>
    <StatefulGrid
      client={client}
      ref={userTaskGridRef}
      stateKey={gridKeys.USER_TASK_GRID_KEY}
      method={'GET'}
      path={url}
      sortable
      pageSize={invevoTaskGridPageSize}
      pageable={{
        pageSizes: [50, 100, 200, 500],
        refresh: true,
        buttonCount: 5
      }}
      onStateChange={onStateChange}
      // TODO vir scroll maybe?
      // https://www.telerik.com/kendo-react-ui/components/grid/scroll-modes/virtual/
      onRowClick={onRowClick}
      isSelectedRow={showTickBoxes ? task => tickedTaskIds.includes(task.TaskId) : undefined}
      checkableRow={showTickBoxes ? { headerSelectionValue: allTickBoxTicked } : undefined}
      onSelectionChange={showTickBoxes ? onRowTickBoxClick : undefined}
      onHeaderSelectionChange={showTickBoxes ? onHeaderTickBoxClick : undefined}
      additionalRequestPayload={additionalRequestPayload()}
      afterReceivedFn={resetIndex}
      hideablePaging={true}
      taskGrid={true}
      className={
        isGridView
          ? 'custom-grid scrollable-none'
          : 'hide-header single-cell scrollable-none'
      }
      refresh={refresh}
    >
      {isGridView ?
        columns
          .filter((f) => f.show)
          .map((c) => (
            <GridColumn {...c} key={c.field} columnMenu={ColumnMenu} />
          )) :
        <GridColumn cell={event => renderCard(event.dataItem)} />
      }
    </StatefulGrid>,
    [additionalRequestPayload, allTickBoxTicked, client, columns, invevoTaskGridPageSize, isGridView, onHeaderTickBoxClick, onRowClick, onRowTickBoxClick, onStateChange, refresh, renderCard, resetIndex, showTickBoxes, tickedTaskIds, url])

  return statefulGridElement
}

function areEqual(prevProps, nextProps) {
  if (
    nextProps.dateType === userTaskDateType.RANGE &&
    (!nextProps.startDate || !nextProps.endDate)
  )
    return true
  return false
}
const MemoUserTaskGrid = React.memo(UserTaskGrid, areEqual)

const mapStateToProps = (state) => {
  return {
    accountId: state.currentSelectionReducer.selectedAccount.miaAccountId,
    selectedTask: state.taskReducer.selectedTask || {},
    startDate: state.taskReducer.userTaskList.startDate,
    endDate: state.taskReducer.userTaskList.endDate,
    dateType: state.taskReducer.userTaskList.dateType,
    stateTaskTypeId: state.taskReducer.userTaskList.stateTaskTypeId,
    taskTypeId: state.taskReducer.userTaskList.taskTypeId
  }
}

export default connect(mapStateToProps, {
  getFocusedTask,
  getFocusedAccount,
  getFocusedAccountAndTask,
  setUserTaskGridIndex,
  destroySearchList,
  navToTask: Nav.Workspace.RightPane('task', 'summary'),
  navToHistory: Nav.Workspace.RightPane('email-unread', 'history'),
  navToCreditLimitAndSummary: Nav.Workspace.RightPane('credit-limit', 'summary'),
  dismissFocusedTask
})(MemoUserTaskGrid)
