import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useCustomer } from "../../../../contexts/CustomerContext"
import { useEntity } from "../../../../contexts/EntityContext"
import { useEnvConfig } from "../../../../contexts/EnvironmentConfigContext"
import useApiQuery from "../../../../hooks/useApiQuery"
import useClient from "../../../../hooks/useClient"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"
import GreyButton from "../../../../library/buttons/GreyButton/GreyButton"
import IconButton from "../../../../library/buttons/IconButton/IconButton"
import MultiSelectDropdown from "../../../../library/dropdowns/MultiSelectDropdown"
import Loading from "../../../../library/Loading/Loading"
import { webApiInterface } from "../../../../microfrontends/legacy/api/webApiInterface"
import NewAddNote from "../../../../microfrontends/legacy/components/history/NewAddNote"
import NewNote from "../../../../microfrontends/legacy/components/history/NewNote"
import { getApiUrl } from "../../../../microfrontends/legacy/constants"
import DropdownOption from "../../../../types/DropdownOptions"
import Lookup from "../../../../types/Lookup"
import EntityConfig from "../../../entityConfig/types/EntityConfig"
import EntityRelationshipDto from "../../../entityConfig/types/EntityRelationshipDto"
import { groupEntityNotes } from "./common"
import NoteCard from "./NoteCard"
import Pagination from "./Pagination"
import { EntityResultWithPreviousStateDto } from "./types/EntityResultWithPreviousStateDto"
import Note, { convertSqlNoteToNote } from "./types/Note"

type Props = {
    selectedAccount?: any
    entityConfigs: EntityConfig[]
    entityRelationships: EntityRelationshipDto[]
    lookups: Lookup[]
    onExportClick: () => void
    onViewEmailClick: () => void
    onReplyToEmailClick: () => void
}

const EntityAndLegacyNotesHistory = ({
    selectedAccount,
    entityConfigs,
    entityRelationships,
    lookups,
    onExportClick,
    onViewEmailClick,
    onReplyToEmailClick
}: Props) => {
    const config = useEnvConfig()
    const client = useClient()
    const [entity] = useEntity()
    const { isEnabled: newEntityStructure } = useFeatureToggle("newEntityStructure")
    const { isEnabled: tempPaginateNotes } = useFeatureToggle("tempPaginateNotes")

    const [childEntityTypes, setChildEntityTypes] = useState<string[]>(
        entityRelationships.filter(r => r.parentEntityReference === entity.entityType).map(r => r.childEntityReference)
    )
    const trackedChildFields = entityConfigs
        .filter(c => childEntityTypes.includes(c.reference) || entity?.entityType === c.reference)
        .map(c => ({
            [c.reference]: c.fields.filter(f => f.trackChanges).map(f => ({ fieldName: f.fieldName, dataType: f.dataPrimitiveType }))
        }))

    const getNotesRequest = useApiQuery<EntityResultWithPreviousStateDto[]>({
        url: `${config.SEARCH_API_URL}/api/${client}/notes`,
        method: "POST",
        dto: {
            entityReference: entity.reference,
            parentEntityType: entity.entityTypeReference ?? entity.entityType ?? "",
            childEntityTypes: childEntityTypes,
            entityTrackedFields: Object.assign({}, ...trackedChildFields)
        },
        isExecutedAutomatically: newEntityStructure
    })

    const entityNotes = groupEntityNotes(getNotesRequest.data ?? [], entityConfigs, lookups, true)
    const [sqlNotes, setSqlNotes] = useState<Note[]>([])

    const id = entity.sqlId ?? selectedAccount?.miaAccountId
    const dispatch = useDispatch()
    const [customer] = useCustomer()
    const [legacyFetching, setLegacyFetching] = useState(false)
    const [legacyFetched, setLegacyFetched] = useState(false)
    const [legacyErrored, setLegacyErrored] = useState(false)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(20)

    useEffect(() => {
        if (id === undefined) return
        if (legacyFetching || legacyFetched) return

        const legacyNotesUrl = selectedAccount?.miaAccountIsVirtualAccount ? `${getApiUrl(client)}api/note/GetHistoryNotesVirtualAccount` : `${getApiUrl(client)}api/note/history`

        setLegacyFetching(true)
        webApiInterface
            .authPost(client, legacyNotesUrl, dispatch, {
                transactionids: null,
                customerid: id,
                allTransactionsSelected: false,
                pageSize: tempPaginateNotes? 2000: 200,
                page: 1,
                sort: "NoteDate-desc"
            })
            .then(response => response.json() as { Data: any[] })
            .then(r => {
                setSqlNotes(r.Data.map(convertSqlNoteToNote))
            })
            .then(_ => {
                setLegacyFetched(true)
                setLegacyFetching(false)
                setLegacyErrored(false)
            })
            .catch(_ => {
                setLegacyFetched(true)
                setLegacyFetching(false)
                setLegacyErrored(true)
                setSqlNotes([])
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, dispatch, entity.sqlId, customer.selectedTransactions, legacyFetched, legacyFetching])

    const totalNotes = sqlNotes.length + entityNotes.length

    const setRefresh = () => {
        setLegacyFetched(false)
        setLegacyFetching(false)
        newEntityStructure && getNotesRequest.execute()
    }

    const entityRelationshipLookups: DropdownOption<string>[] = (entityRelationships ?? [])
        .filter(r => r.parentEntityReference === entity.entityType)
        .map(r => ({
            label: entityConfigs?.find(c => c.reference === r.childEntityReference)?.displayName ?? r.childEntityReference,
            value: r.childEntityReference
        }))

    const onSelectedChildrenChanged = (options: DropdownOption<string>[]) => setChildEntityTypes(options.map(o => o.value))

    const notes = [
        ...sqlNotes.map(note => ({
            note: note,
            card: (
                <NewNote
                    key={note.noteId + note.noteEpochTime + note.userName}
                    isParentNote={note.noteId === entity.reference}
                    note={note}
                    onReplyToEmail={onReplyToEmailClick}
                    onViewEmail={onViewEmailClick}
                />
            )
        })),
        ...entityNotes.map(note => ({
            note: note,
            card: <NoteCard key={note.noteId + note.noteEpochTime + note.userName} note={note} isParentNote={note.noteId === entity.reference} />
        }))
    ].sort((a: { note: Note; card: JSX.Element }, b: { note: Note; card: JSX.Element }) => b.note.noteEpochTime - a.note.noteEpochTime)

    const selectedChildren = entityRelationshipLookups.filter(r => childEntityTypes.includes(r.value))

    const notesToShow =  tempPaginateNotes ? notes.slice(page * pageSize, page * pageSize + pageSize) : notes

    return (
        <div className="d-flex flex-column w-100 position-relative  gap-2">
            <div className="d-flex flex-row w-100 gap-5">
                {newEntityStructure && (
                    <div className="d-flex w-100 justify-content-between align-items-center">
                        <span className="fs-5">Showing Children:</span>
                        <MultiSelectDropdown
                            className="w-50"
                            options={entityRelationshipLookups}
                            onSelectedOptionsChanged={onSelectedChildrenChanged}
                            selectedOptions={selectedChildren}
                        />
                    </div>
                )}
                {(newEntityStructure || legacyErrored) && <IconButton icon="fal fa-refresh" theme="blue" onClick={setRefresh} ariaLabel="refresh-notes" />}
            </div>
            {id && !legacyErrored && <NewAddNote className="mb-3" setRefresh={setRefresh} />}
            <div className="d-flex flex-column w-100 h-100 position-relative">
                <Loading colour="white" isLoading={legacyFetching || getNotesRequest.isFetching}>
                    {notes.length === 0 && getNotesRequest.hasFetched ? (
                        <span className="fs-3 text white">{`There is no history for ${
                            selectedChildren.length > 0
                                ? `these ${newEntityStructure ? "entities" : "customers"}`
                                : `this ${newEntityStructure ? "entity" : "customer"}`
                        } `}</span>
                    ) : (
                        <>
                            {id && !legacyErrored && (
                                <>
                                    <GreyButton
                                        disabled={notes.length === 0 || getNotesRequest.hasErrored}
                                        className="ms-auto"
                                        label="Export to CSV"
                                        iconClasses="far fa-file-csv"
                                        onClick={onExportClick}
                                    />
                                    <div className="border-top border-1 border-white my-3" />
                                </>
                            )}
                            <div className="d-flex flex-column w-100 gap-2 align-items-center">{notesToShow.map(note => note.card)}</div>
                            {tempPaginateNotes &&
                                <Pagination
                                    page={page}
                                    pageSize={pageSize}
                                    totalAcrossPages={totalNotes}
                                    setPage={setPage}
                                    setPageSize={setPageSize}/>
                            }
                        </>
                    )}
                </Loading>
            </div>
        </div>
    )
}

export default EntityAndLegacyNotesHistory
