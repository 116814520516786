import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useGrid } from "./Grid"
import EntityConfig from "../../routes/entityConfig/types/EntityConfig"
import useClient from "../../hooks/useClient"
import useApiQuery from "../../hooks/useApiQuery"
import EntityResults from "./types/EntityResults"
import { DataPrimitiveTypeEnum, DataTypeEnum, getDataPrimitiveTypeForEntityDataType } from "../../routes/entityConfig/types/DataType"
import Entity from "./types/Entity"
import FiltersDto, { convertFromArrayToDto, convertFromDtoToArray } from "../FilterList/FiltersDto"
import { useEnvConfig } from "../../contexts/EnvironmentConfigContext"
import Loading from "../Loading/Loading"
import classes from "./EntityTable.module.scss"
import HeaderConfig from "./types/HeaderConfig"
import HeaderDefinition from "./types/HeaderDefinition"
import RowData from "./types/RowData"
import EntityData, { convertDtoFieldsToDatafields } from "../../types/EntityData"
import { FieldsUpdate, buildFieldUpdates, groupUpdatesByReference } from "../common"
import { getValueForHeader, mergeOptimisticUpdatesIntoEntities } from "./util"
import DataUpdateRule from "../dataGridV2/types/DataUpdateRule"
import moment from "moment"
import IconButton from "../buttons/IconButton/IconButton"
import HeaderConfigButton from "./actions/HeaderConfigButton"
import { useOverlay } from "../../contexts/overlay/OverlayContext"
import AddEntityOverlayForm from "./AddEntityOverlayForm"
import { useSelectedEntities } from "../../contexts/SelectedEntitiesContext"
import ExportToCsvButton from "./actions/ExportToCsvButton"
import { showToastErrorMessage, showToastSuccessMessage } from "../../microfrontends/legacy/api/toasterApi"
import { useFeatureToggle } from "../../hooks/useFeatureToggle"
import EntityWidgetFilters from "../../microfrontends/dashboard/components/customerDashboards/widgets/EntityWidgetFilter"
import { getDataFieldsFromEntityDataFields } from "../helpers/entityHelpers"
import GenericFilter from "../FilterList/filterTypes/GenericFilter"
import { useEvent } from "../../contexts/EventContext"
import { v4 } from "uuid"
import { useCustomer } from "../../contexts/CustomerContext"
import { useLayout } from "../../contexts/LayoutContext"

export type LegacyTransactionStatusUpdate = {
    transactionReferences: string[]
    status: string
    subStatus: string
    paymentPromiseDate?: string
}

type Props = {
    entityConfig: EntityConfig
    onEntitiesUpdated?: (entities: Entity[]) => void
    filters?: FiltersDto
    dashboardFilterGroups?: FiltersDto[]
    dashboardAppliedRoles?: string[]
    headerConfigs?: HeaderConfig[]
    children: JSX.Element
}

const EntityTable = ({
    entityConfig,
    onEntitiesUpdated,
    filters = {},
    dashboardFilterGroups = [],
    dashboardAppliedRoles = [],
    headerConfigs,
    children
}: Props) => {
    const grid = useGrid()
    const config = useEnvConfig()
    const client = useClient()
    const overlay = useOverlay()
    const layout = useLayout()

    const [customer] = useCustomer()
    const { setSelectedRefs } = useSelectedEntities()
    const { subscribe, unsubscribe } = useEvent()

    const [isDownloading, setIsDownloading] = useState(false)

    const { isEnabled: useDashboardAppliedRoles } = useFeatureToggle("dashboardAppliedRoles")
    const { isEnabled: allowCreateNewEntity } = useFeatureToggle("permShowOrHidePlusButtonInEntityGrids")
    const { isEnabled: downloadTransactionDocuments } = useFeatureToggle("permDownloadTransactionDocuments")

    const combinedFilters: FiltersDto = useMemo(() => {
        const emptySelectionFilter = { fieldName: "reference", values: [], notOneOf: false }
        const selectionFilter = !grid.isFilteredToSelection ? [] : [{ ...emptySelectionFilter, values: grid.selection.selectedRefs }]
        return {
            booleanFieldIsEqualTo: [...(filters.booleanFieldIsEqualTo ?? []), ...(grid.filters?.booleanFieldIsEqualTo ?? [])],
            dateFieldIsOneOf: [...(filters.dateFieldIsOneOf ?? []), ...(grid.filters?.dateFieldIsOneOf ?? [])],
            dateFieldIsRelativeTo: [...(filters.dateFieldIsRelativeTo ?? []), ...(grid.filters?.dateFieldIsRelativeTo ?? [])],
            dateFieldMatchesOperation: [...(filters.dateFieldMatchesOperation ?? []), ...(grid.filters?.dateFieldMatchesOperation ?? [])],
            fieldExists: [...(filters.fieldExists ?? []), ...(grid.filters?.fieldExists ?? [])],
            numberFieldIsOneOf: [...(filters.numberFieldIsOneOf ?? []), ...(grid.filters?.numberFieldIsOneOf ?? [])],
            numberFieldMatchesOperation: [...(filters.numberFieldMatchesOperation ?? []), ...(grid.filters?.numberFieldMatchesOperation ?? [])],
            textFieldIsOneOf: [...(filters.textFieldIsOneOf ?? []), ...(grid.filters?.textFieldIsOneOf ?? []), ...selectionFilter],
            textFieldStartsWith: [...(filters.textFieldStartsWith ?? []), ...(grid.filters?.textFieldStartsWith ?? [])]
        }
    }, [filters, grid.filters, grid.selection.selectedRefs, grid.isFilteredToSelection])

    const bulkUpdateEntityRequest = useApiQuery({
        url: `${config.INTEGRATION_API_URL}/api/${client}/entities`,
        method: "PATCH",
        isExecutedAutomatically: false,
        onSuccess: () => {
            showToastSuccessMessage("Entities updated successfully", { position: "bottom-right" })
        },
        onError: error => {
            showToastErrorMessage("Error: " + error.message, { position: "bottom-right" })
        }
    })

    const dataUpdateRulesRequest = useApiQuery<DataUpdateRule[]>({
        url: `${config.AUTOMATE_API_URL}/api/${client}/data-updated-workflow-rule`,
        method: "GET"
    })

    const onRowsUpdated = useCallback(
        (updates: { rowReference: string; header: HeaderDefinition; newValue: string }[]) => {
            const convertRowToEntity = (row: RowData): EntityData => ({
                entityTypeReference: entityConfig.reference,
                reference: row.reference,
                entityFields: convertDtoFieldsToDatafields(row.fields)
            })

            grid.setData(current => {
                const allUpdates = updates.flatMap(update => {
                    const convertedHeader = {
                        displayType: update.header.displayType,
                        fieldName: update.header.value
                    }

                    const entitiesToUpdate = current.rows.filter(
                        entity =>
                            grid.selection.isAllRowsSelected ||
                            grid.selection.selectedRefs.includes(entity.reference) ||
                            entity.reference === update.rowReference
                    )

                    const entitiesToUpdateWithOptimisticValues = mergeOptimisticUpdatesIntoEntities(entitiesToUpdate, current.optimisticallyUpdatedRows)

                    return entitiesToUpdateWithOptimisticValues.map(row =>
                        buildFieldUpdates(convertRowToEntity(row), convertedHeader, update.newValue, dataUpdateRulesRequest.data ?? [])
                    )
                })

                const allUpdatesGroupedByRef = groupUpdatesByReference(allUpdates, entityConfig.reference)

                bulkUpdateEntityRequest.execute(undefined, allUpdatesGroupedByRef)

                const entitiesToUpdateRefs = allUpdatesGroupedByRef.map(entity => entity.reference)
                return {
                    ...current,
                    optimisticallyUpdatedRows: [
                        ...current.optimisticallyUpdatedRows.filter(row => !entitiesToUpdateRefs.includes(row.reference)),
                        ...getOptimisticValuesFromUpdates(allUpdatesGroupedByRef, current.optimisticallyUpdatedRows)
                    ]
                }
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [grid.data.rows, dataUpdateRulesRequest.data, grid.selection]
    )

    useEffect(() => {
        grid.setOnRowsUpdated(() => onRowsUpdated)
    }, [grid, onRowsUpdated])

    useEffect(() => {
        const entityHeaders = entityConfig.fields.map((field, index) => {
            const config = headerConfigs?.find(config => config.fieldName === field.fieldName)
            return {
                value: field.fieldName,
                label: field.displayName,
                ordinal: config?.ordinal ?? index,
                defaultOrdinal: index,
                dataPrimitiveType: field.dataPrimitiveType,
                displayType: field.dataType.type,
                isEditable: config?.isEditable ?? false,
                lookupReference: field.dataType.lookupReference,
                currencyFieldReference: field.dataType.currencyFieldReference,
                currencyCode: field.dataType.currencyCode,
                format: field.dataType.format,
                timezone: field.dataType.timeZone
            }
        })

        const defaultHeaders = headerConfigs
            ? headerConfigs.map(config => entityHeaders.find(header => header.value === config.fieldName)).filter(Boolean)
            : entityHeaders

        grid.setPotentialHeaders(entityHeaders)
        grid.setDefaultHeaders(defaultHeaders)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (entityConfig.reference !== "transaction") return

        subscribe("select_transactions", {
            key: grid.reference,
            callback: ({ selection, filterToSelected }: { selection: string[]; filterToSelected: boolean }) => {
                grid.onSelect({ selectedRefs: selection, isAllRowsSelected: false }, false)

                if (filterToSelected) {
                    grid.setPaging({ ...grid.paging, pageIndex: 0 })
                    grid.setIsFilteredToSelection(true)
                }
            }
        })

        return () => unsubscribe("select_transactions", grid.reference)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSearchAfterValue = () => {
        const entity = grid.searchAfterIds[grid.paging.pageIndex - 1]

        if (entity) {
            return grid.sorting.header.value === "reference"
                ? entity.reference
                : getValueForHeader({ reference: entity.reference, fields: entity.entityFields }, grid.sorting.header)?.toString() ?? null
        } else {
            return null
        }
    }

    const getSortByValue = () => {
        const searchAfterValue = getSearchAfterValue()

        if (searchAfterValue === null && grid.searchAfterIds[grid.paging.pageIndex - 1]?.reference) {
            return {
                fieldName: "reference",
                dataPrimitive: DataPrimitiveTypeEnum.TEXT,
                direction: grid.sorting.direction,
                searchAfter: grid.searchAfterIds[grid.paging.pageIndex - 1]?.reference ?? null
            }
        } else {
            return {
                fieldName: grid.sorting.header.value,
                dataPrimitive: getDataPrimitiveTypeForEntityDataType(grid.sorting.header.displayType),
                direction: grid.sorting.direction,
                searchAfter: searchAfterValue
            }
        }
    }
    const entityConfigTextHeaders = entityConfig.fields
        .filter(
            field =>
                field.dataType.type === DataTypeEnum.TEXT ||
                field.dataType.type === DataTypeEnum.LOOKUP ||
                field.dataType.type === DataTypeEnum.EMAIL ||
                field.dataType.type === DataTypeEnum.PHONE_NUMBER ||
                field.dataType.type === DataTypeEnum.RICH_TEXT
        )
        .map(field => field.fieldName)

    const getEntitiesRequest = useApiQuery<EntityResults>({
        url: `${config.SEARCH_API_URL}/api/${client}/entities`,
        method: "POST",
        dto: {
            pageSize: grid.paging.pageSize,
            searchAfterId: grid.searchAfterIds[grid.paging.pageIndex - 1]?.reference ?? null,
            sortBy: getSortByValue(),
            filters: combinedFilters,
            matchSomeFilters: dashboardFilterGroups,
            quickSearchValue: grid.quickSearchValue,
            quickSearchFields: grid.quickSearchValue !== "" ? entityConfigTextHeaders : undefined,
            entityTypeReference: entityConfig.reference,
            ...(useDashboardAppliedRoles ? { dashboardAppliedRolesNames: dashboardAppliedRoles } : {})
        },
        onSuccess: entityResults => {
            if (onEntitiesUpdated) {
                onEntitiesUpdated(entityResults.pagedEntity)
            }
            grid.setData({
                rows: entityResults.pagedEntity.map(entity => ({ reference: entity.reference, fields: entity.entityFields })),
                optimisticallyUpdatedRows: [],
                totalAcrossPages: entityResults.totalCount,
                isLoading: false
            })
            if (entityResults.pagedEntity.length > 0) {
                grid.setSearchAfterIds(ids => ({
                    ...ids,
                    [grid.paging.pageIndex]: entityResults.pagedEntity.at(-1) ?? entityResults.pagedEntity.at(0)!
                }))
            }
        },
        isExecutedAutomatically: grid.shouldLoadData && (grid.defaultHeaders.length > 0 || grid.displayedHeaders.length > 0) //only make request once headers have been initialised
    })

    useLayoutEffect(() => {
        if (entityConfig.reference !== "transaction") return

        const key = v4()
        subscribe("reload_transaction", {
            key,
            callback: (data: LegacyTransactionStatusUpdate) => {
                const statusHeader = grid.potentialHeaders.find(header => header.value === "text_status")
                const subStatusHeader = grid.potentialHeaders.find(header => header.value === "text_substatus")
                const paymentPromiseDateHeader = grid.potentialHeaders.find(header => header.value === "date_paymentpromisedate")

                const transactionUpdates = data.transactionReferences
                    .map(reference => {
                        return [
                            ...((statusHeader && [{ rowReference: reference, header: statusHeader, newValue: data.status }]) ?? []),
                            ...((subStatusHeader && [{ rowReference: reference, header: subStatusHeader, newValue: data.subStatus }]) ?? []),
                            ...((paymentPromiseDateHeader && [
                                { rowReference: reference, header: paymentPromiseDateHeader, newValue: data.paymentPromiseDate ?? "" }
                            ]) ??
                                [])
                        ]
                    })
                    .flatMap(update => update)
                    .filter(update => update !== undefined && update.newValue !== "")

                grid.onRowsUpdated(transactionUpdates)
            }
        })

        return () => {
            unsubscribe("reload_transaction", key)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid.potentialHeaders, grid.onRowsUpdated])

    useEffect(() => {
        grid.setData(data => ({
            rows: data.rows,
            optimisticallyUpdatedRows: [],
            totalAcrossPages: data.totalAcrossPages,
            isLoading: getEntitiesRequest.isFetching
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getEntitiesRequest.isFetching])

    const addNewEntity = (reference: string) => {
        const newEntity = {
            reference: reference,
            entityTypeReference: entityConfig.reference,
            booleanFields: [],
            stringFields: [],
            numberFields: [],
            dateTimeFields: []
        }

        bulkUpdateEntityRequest.execute(undefined, [newEntity]).then(() => {
            grid.setData(current => ({
                ...current,
                rows: [
                    ...current.rows,
                    {
                        reference: newEntity.reference,
                        fields: {
                            booleanFields: {},
                            numberFields: {},
                            dateFields: {},
                            textFields: {}
                        }
                    }
                ]
            }))
        })
    }

    const onAddNewEntityClicked = () => overlay.showOverlay(<AddEntityOverlayForm entityConfig={entityConfig} onAddClick={addNewEntity} />)

    useEffect(() => {
        setSelectedRefs(entityConfig.reference, grid.selection.selectedRefs)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid.selection])

    useEffect(
        () => () => {
            setSelectedRefs(entityConfig.reference, [])
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const onShowSelectedClicked = () => {
        if (grid.isFilteredToSelection) {
            grid.onSelect({ selectedRefs: [], isAllRowsSelected: false }, false)
        }
        grid.setPaging({ ...grid.paging, pageIndex: 0 })
        grid.setIsFilteredToSelection(v => !v)
    }

    const onFiltersUpdated = (updated: GenericFilter[]) => {
        grid.setFilters(convertFromArrayToDto(updated))
        overlay.closeOverlay()
    }
    const onReset = () => {
        grid.resetFilters()
        overlay.closeOverlay()
    }

    const filtersArray = convertFromDtoToArray(grid.filters, getDataFieldsFromEntityDataFields(entityConfig.fields), grid.lookups)

    const onFiltersSelected = () => {
        overlay.showOverlay(
            <EntityWidgetFilters
                chartTitle={`${entityConfig.displayName} Grid`}
                filters={filtersArray}
                entityConfig={entityConfig}
                lookups={grid.lookups}
                onFiltersUpdated={onFiltersUpdated}
                onResetFilters={onReset}
            />,
            "medium",
            grid.reference
        )
    }

    const getTransactionDocuments = useApiQuery<Blob>({
        url: `${config.DASHBOARD_API_URL}/api/${client}/legacy-documents-for-transactions`,
        method: "POST",
        dto: {
            transactionRefs: grid.selection.selectedRefs,
            customerId: customer.sqlId
        },
        onSuccess: (data, headers) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement("a")
            let filename = `${customer.reference}_transactions.pdf`

            if (!headers || !headers["content-disposition"]) {
                showToastErrorMessage("No transaction documents found.", { position: "bottom-right" })
                setIsDownloading(false)
                return
            }
            const disposition = headers["content-disposition"]

            if (disposition && disposition.indexOf("attachment") !== -1) {
                const filenameRegex = /filename[^=\n]*=((['"]).*?\2|[^\n]*)/
                const matches = filenameRegex.exec(disposition)
                if (matches !== null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "")
                }
            }

            link.href = url
            link.setAttribute("download", filename)
            document.body.appendChild(link)
            link.click()

            link.parentNode?.removeChild(link)

            setIsDownloading(false)
        },
        onError: e => {
            showToastErrorMessage("No transaction documents found.", { position: "bottom-right" })

            setIsDownloading(false)
        },
        isExecutedAutomatically: false,
        returnHeaders: true,
        responseType: "blob"
    })

    const onDownloadClicked = () => {
        if (isDownloading || grid.selection.selectedRefs.length === 0 || grid.selection.selectedRefs.length > 10) {
            return
        }
        setIsDownloading(true)
        getTransactionDocuments.execute()
    }

    return (
        <div className="position-relative d-flex flex-grow-1 h-100">
            {grid.globalActionsPortal(
                <div className="d-flex align-items-center gap-1 ms-auto">
                    <IconButton
                        ariaLabel={`${entityConfig.reference}-grid-filters`}
                        tooltip="Filters"
                        icon="fa-light fa-filter"
                        theme={overlay.isOverlayVisible(grid.reference) || filtersArray.length > 0 ? "white-flat" : "blue-flat"}
                        onClick={onFiltersSelected}
                        count={filtersArray.length}
                    />
                    <IconButton
                        ariaLabel="selection"
                        tooltip="Selected"
                        icon="fa-light fa-check-square"
                        theme={grid.isFilteredToSelection ? "white-flat" : "blue-flat"}
                        onClick={onShowSelectedClicked}
                    />
                    <IconButton ariaLabel="refresh" tooltip="Refresh" icon="fa-light fa-sync" theme="blue-flat" onClick={getEntitiesRequest.execute} />
                    {allowCreateNewEntity && (
                        <IconButton ariaLabel="add-row" tooltip="New entity" icon="fa-light fa-plus" theme="blue-flat" onClick={onAddNewEntityClicked} />
                    )}
                    <ExportToCsvButton />
                    {entityConfig.reference === "transaction" && downloadTransactionDocuments && !layout.isLeftPanelExpanded && (
                        <Loading isLoading={isDownloading} colour={grid.colour === "blue" ? "white" : "blue"} size="small">
                            <IconButton
                                ariaLabel="download-transaction-docs"
                                tooltip={grid.selection.selectedRefs.length > 0 ? "Download transactions" : "Select transactions before downloading"}
                                icon="fa-light fa-file-arrow-down"
                                theme="blue-flat"
                                onClick={onDownloadClicked}
                                disabled={grid.selection.selectedRefs.length === 0}
                            ></IconButton>
                        </Loading>
                    )}
                    <HeaderConfigButton />
                </div>
            )}
            {grid.data.isLoading && (
                <div className={`position-absolute top-0 bottom-0 start-0 end-0 bg-${grid.colour} ${classes.loading}`}>
                    <Loading isLoading={true} colour={grid.colour === "blue" ? "white" : "blue"}>
                        <div className="d-flex flex-grow-1 p-5" />
                    </Loading>
                </div>
            )}
            {grid.data.rows.length === 0 && !grid.data.isLoading && (
                <div className="position-absolute top-0 bottom-0 start-0 end-0 bg-blue">
                    <h5 className="d-flex h-100 justify-content-center align-items-center text-white">No results found matching your search criteria</h5>
                </div>
            )}
            <div className="position-absolute top-0 bottom-0 start-0 end-0 overflow-auto">{children}</div>
        </div>
    )
}

export default EntityTable

const getOptimisticValuesFromUpdates = (allUpdates: FieldsUpdate[], existingOptimisticallyUpdatedRows: RowData[]) =>
    allUpdates.map(update => {
        const currentOptimisticFields = existingOptimisticallyUpdatedRows.find(r => r.reference === update.reference)?.fields ?? {
            booleanFields: {},
            numberFields: {},
            dateFields: {},
            textFields: {}
        }

        update.booleanFields.forEach(field => {
            if (field.fieldValue === undefined) {
                delete currentOptimisticFields.booleanFields[field.fieldName]
            } else {
                currentOptimisticFields.booleanFields[field.fieldName] = field.fieldValue
            }
        })

        update.numberFields.forEach(field => {
            if (field.fieldValue === undefined) {
                delete currentOptimisticFields.numberFields[field.fieldName]
            } else {
                currentOptimisticFields.numberFields[field.fieldName] = field.fieldValue
            }
        })

        update.dateTimeFields.forEach(field => {
            if (field.fieldValue === undefined) {
                delete currentOptimisticFields.dateFields[field.fieldName]
            } else {
                currentOptimisticFields.dateFields[field.fieldName] = moment(field.fieldValue).valueOf()
            }
        })

        update.stringFields.forEach(field => {
            if (field.fieldValue === undefined) {
                delete currentOptimisticFields.textFields[field.fieldName]
            } else {
                currentOptimisticFields.textFields[field.fieldName] = field.fieldValue
            }
        })

        return {
            reference: update.reference,
            fields: currentOptimisticFields
        }
    })
