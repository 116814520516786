import EntityConfig from "../../../entityConfig/types/EntityConfig"
import EntityPermission from "../types/EntityPermission"

type EntityPermissionConfigProps = {
    entityPermission: EntityPermission
    entityConfigs: EntityConfig[]
    onEntityPermissionRemoved: (entityTypeReference: String) => void
}

const EntityPermissionConfig = ({ entityPermission, entityConfigs, onEntityPermissionRemoved }: EntityPermissionConfigProps) => {
    const getEntityTypeConfigDisplayName = (reference: String) => entityConfigs.find(config => config.reference === reference)?.displayName

    const removeEntityPermission = () => onEntityPermissionRemoved(entityPermission.entityTypeReference)

    return (
        <div className="d-flex flex-column bg-grey p-2 mt-2 rounded">
            <div className="d-flex flex-grow-1 align-items-center">
                <span className="ms-2 text-nowrap">{getEntityTypeConfigDisplayName(entityPermission.entityTypeReference)}</span>
                <span
                    className={`ms-auto px-2 me-2 pointer`}
                    onClick={removeEntityPermission}
                    role="button"
                    aria-label={`remove-${entityPermission.entityTypeReference}`}
                >
                    <i className="fa fa-times"></i>
                </span>
            </div>
        </div>
    )
}

export default EntityPermissionConfig
