import { useClient, useConfig } from "invevo-react-components"
import { widgetThemes } from "../../../enums/widgetThemes"
import InfoCard from "../../../../../library/cards/InfoCard"
import useApiQuery from "../../../../../hooks/useApiQuery"
import { DataTypeEnum } from "../../../../../routes/entityConfig/types/DataType"
import { Entity, useEntity } from "../../../../../contexts/EntityContext"
import DataPrimitive from "../../../../../types/DataPrimitive"
import { useFeatureToggle } from "../../../../../hooks/useFeatureToggle"
import { filterLookupEntriesByParent } from "../../../../../routes/entitySearch/helpers"
import Lookup from "../../../../../types/Lookup"
import EntityDataField from "../../../../../routes/entityConfig/types/EntityDataField"
import { buildFieldUpdates, mergeFieldsUpdateIntoEntityData } from "../../../../../library/common"
import DataUpdateRule from "../../../../../library/dataGridV2/types/DataUpdateRule"
import EntityData, { convertFromDataFields } from "../../../../../types/EntityData"
import { webApiInterface } from "../../../../legacy/api/webApiInterface"
import { getApiUrl } from "../../../../legacy/constants"
import { useDispatch } from "react-redux"
import { showToastErrorMessage, showToastSuccessMessage } from "../../../../legacy/api/toasterApi"
import { DEFAULT_DATE_FORMAT } from "../../../../../library/helpers/dateUtils"

const isNumber = (value: any) => !isNaN(value) && !isNaN(parseFloat(value))
const isFloat = (value: string) => parseFloat(value) % 1 !== 0

const getStyle = (value: string | number | boolean) => (isNumber(value) ? (Number(value) >= 0 ? "good" : "bad") : "info")

const formatNumber = (number: string) => (isFloat(number) ? parseFloat(number).toFixed(2) : Math.round((Number(number) + Number.EPSILON) * 100) / 100)
const formatValue = (value: string) => (isNumber(value) ? formatNumber(value) : value)

type EntityInfoDisplayWidgetProps = {
    widget: {
        ordinal: number
        cellX: number
        cellY: number
        cellWidth: number
        cellHeight: number
        displayName: string
        fields: {
            id: string
            fieldName: string
            value: string
            dataType: DataPrimitive
            isEditable: boolean
        }[]
        isFieldLabelInline: boolean
        theme: string
        widgetType: string
    }
    lookups: Lookup[]
    entityFields: EntityDataField[]
    dataUpdateRules: DataUpdateRule[]
}

const EntityInfoDisplayWidget = ({ widget, lookups = [], entityFields = [], dataUpdateRules }: EntityInfoDisplayWidgetProps) => {
    const { isEnabled: shouldFilterLookups } = useFeatureToggle("filterLookupEntriesOnLookupParents")
    const { isEnabled: shouldCallLegacyApi } = useFeatureToggle("callLegacyApiWhenUpdatingKafkaDashboard")

    const client = useClient()
    const config = useConfig()
    const [entity, setEntity] = useEntity()
    const dispatch = useDispatch()

    const bulkUpdateEntityRequest = useApiQuery({
        url: `${config.INTEGRATION_API_URL}/api/${client}/entities`,
        method: "PATCH",
        isExecutedAutomatically: false,
        onSuccess: () => {
            showToastSuccessMessage("Entities updated successfully", { position: "bottom-right" })
        },
        onError: error => {
            showToastErrorMessage("Error: " + error.message, { position: "bottom-right" })
        }
    })

    const updateEntity = (dataToUpdate: { type: string; fieldName: string }, newValue: string) => {
        const entityInDataForm: EntityData = {
            entityTypeReference: entity.entityType ?? "customer",
            reference: entity.reference,
            entityFields: entity.entityFields ?? { textFields: [], numberFields: [], booleanFields: [], dateFields: [] }
        }

        const entityUpdate = buildFieldUpdates(
            entityInDataForm,
            {
                displayType: DataTypeEnum[dataToUpdate.type as keyof typeof DataTypeEnum],
                fieldName: dataToUpdate.fieldName
            },
            newValue,
            dataUpdateRules
        )
        const updateEntity = () => {
            bulkUpdateEntityRequest.execute(undefined, [entityUpdate]).then(() => {
                const updatedEntity = mergeFieldsUpdateIntoEntityData(entityInDataForm, entityUpdate)

                setEntity({
                    ...updatedEntity
                })
            })
        }
        if (entity.sqlId && shouldCallLegacyApi) {
            switch (dataToUpdate.fieldName) {
                case "boolean_alwaysonstop":
                    webApiInterface
                        .authPost(client, `${getApiUrl(client)}api/AccountStatus/UpdateAlwaysOnStop`, dispatch, {
                            customerId: entity.sqlId,
                            alwaysOnStop: newValue === "true"
                        })
                        .then(response => response.json())
                        .then(result => {
                            if (!result.isError) {
                                updateEntity()
                            }
                        })
                        .catch(error => console.error(error))
                    break
                case "boolean_neveronstop":
                    webApiInterface
                        .authPost(client, `${getApiUrl(client)}api/AccountStatus/UpdateNeverOnStop`, dispatch, {
                            customerId: entity.sqlId,
                            neverOnStop: newValue === "true"
                        })
                        .then(response => response.json())
                        .then(result => {
                            if (!result.isError) {
                                updateEntity()
                            }
                        })
                        .catch(error => console.error(error))
                    break
                case "boolean_allcommunicationsonhold":
                    webApiInterface
                        .authPost(client, `${getApiUrl(client)}api/dynamiccolumn/dynamicrecord/updateCommsOnHold`, dispatch, {
                            customerId: entity.sqlId,
                            value: newValue === "true"
                        })
                        .then(response => response.json())
                        .then(result => {
                            if (!result.isError) {
                                updateEntity()
                            }
                        })
                        .catch(error => console.error(error))
                    break
                case "boolean_iskeyaccount":
                    webApiInterface
                        .authPost(client, `${getApiUrl(client)}api/dynamiccolumn/dynamicrecord/updateKeyAccount`, dispatch, {
                            customerId: entity.sqlId,
                            value: newValue === "true"
                        })
                        .then(response => response.json())
                        .then(result => {
                            if (!result.isError) {
                                updateEntity()
                            }
                        })
                        .catch(error => console.error(error))
                    break
                case "text_status":
                    webApiInterface
                        .authPost(client, `${getApiUrl(client)}api/AccountStatus/UpdateAccountStatus`, dispatch, {
                            customerId: entity.sqlId,
                            statusCode: newValue
                        })
                        .then(response => response.json())
                        .then(result => {
                            if (!result.isError) {
                                updateEntity()
                            }
                        })
                        .catch(error => console.error(error))
                    break
                case "boolean_applycreditcardcharges":
                    webApiInterface
                        .authPost(client, `${getApiUrl(client)}api/dynamiccolumn/dynamicrecord/updateApplyCreditCardCharges`, dispatch, {
                            customerId: entity.sqlId,
                            value: newValue === "true"
                        })
                        .then(response => response.json())
                        .then(result => {
                            if (!result.isError) {
                                updateEntity()
                            }
                        })
                        .catch(error => console.error(error))
                    break
                default:
                    updateEntity()
                    break
            }
        } else updateEntity()
    }

    const getValue = (fieldName: string, fieldType: DataPrimitive) => {
        if (fieldName === "reference") return entity.reference

        switch (fieldType) {
            case DataPrimitive.BOOLEAN:
                return entity?.entityFields?.booleanFields?.find(f => f.name === fieldName)?.value
            case DataPrimitive.DATE:
                return entity?.entityFields?.dateFields?.find(f => f.name === fieldName)?.value
            case DataPrimitive.NUMBER:
                return entity?.entityFields?.numberFields?.find(f => f.name === fieldName)?.value
            default:
                return entity?.entityFields?.textFields?.find(f => f.name === fieldName)?.value
        }
    }

    const updatedEntityFields = entityFields?.map(field => {
        if (field.dataType.type === DataTypeEnum.LOOKUP) {
            return {
                ...field,
                updatedName: lookups
                    ?.find(l => l.reference === field.dataType.lookupReference)
                    ?.entries?.find(e => entity?.entityFields?.textFields.find(f => f.name === field.fieldName)?.value === e.reference)?.name
            }
        } else {
            return field
        }
    })

    const getEntityFieldConfig = (fieldName: string) => updatedEntityFields?.find(f => f.fieldName === fieldName)

    const getLookupValues = (fieldName: string) => {
        const entityField = getEntityFieldConfig(fieldName)

        if (entityField?.dataType?.type !== DataTypeEnum.LOOKUP) return []

        const lookupsToUse = shouldFilterLookups
            ? filterLookupEntriesByParent(lookups, entityFields, convertFromDataFields(entity?.entityFields?.textFields ?? []))
            : lookups

        return lookupsToUse
            .find(lookup => lookup.reference === entityField?.dataType?.lookupReference)
            ?.entries.map(entry => ({ label: entry.name, value: entry.reference }))
    }

    const getEntityDataFieldType = (fieldName: string) => getEntityFieldConfig(fieldName)?.dataType.type ?? DataTypeEnum.TEXT

    return (
        <InfoCard
            className="w-100 h-100"
            title={widget.displayName}
            theme={widget.theme === widgetThemes.DEFAULT ? "white" : "blue"}
            data={widget.fields.map(field => {
                const fieldConfig = getEntityFieldConfig(field.fieldName)
                const currency = getCurrencyCodeForField(fieldConfig, entity)
                return {
                    id: field.id,
                    fieldName: field.value,
                    label: getEntityFieldConfig(field.fieldName)?.displayName ?? field.fieldName,
                    value:
                        field.dataType === DataPrimitive.LOOKUP
                            ? formatValue((getEntityFieldConfig(field.fieldName) as any)?.updatedName ?? getValue(field.fieldName, field.dataType) ?? "")
                            : getValue(field.fieldName, field.dataType) ?? "",
                    style: getStyle(getValue(field.fieldName, field.dataType) ?? ""),
                    type: getEntityDataFieldType(field.fieldName),
                    isEditable: field.isEditable,
                    lookupValueList: getLookupValues(field.fieldName),
                    dateFormat:
                        field.dataType === DataPrimitive.DATE ? getEntityFieldConfig(field.fieldName)?.dataType.format ?? DEFAULT_DATE_FORMAT : undefined,
                    timezone: field.dataType === DataPrimitive.DATE ? getEntityFieldConfig(field.fieldName)?.dataType.timeZone : undefined,
                    currency
                }
            })}
            onCustomerUpdate={updateEntity}
        />
    )
}

export default EntityInfoDisplayWidget
function getCurrencyCodeForField(fieldConfig: EntityDataField | undefined, entity: Entity) {
    const currencyFieldName = fieldConfig?.dataType?.currencyFieldReference
    const currency =
        fieldConfig?.dataType?.currencyCode ??
        (currencyFieldName !== undefined ? entity.entityFields?.textFields.find(field => field.name === currencyFieldName)?.value : undefined)
    return currency
}
