import { connect } from 'react-redux'
import NewNotesHistoryGrid from './NewNotesHistoryGrid'
import { useClient } from 'invevo-react-components'
import { ActionStripItems, useActionStrip } from '../../../../contexts/ActionStripContext'

const NewHistory = (props) => {
  const client = useClient()
  const { navToAction } = useActionStrip()

  const onNavToEmailReply = email => navToAction(ActionStripItems.EMAIL, { emailMode: "email-reply", emailToReply: email })

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
            <NewNotesHistoryGrid
                client={client}
                allTransactionsSelected={props.allTransactionsSelected}
                navToEmailReply={onNavToEmailReply}
            />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allTransactionsSelected: state.functionReducer.allTransactionsSelected
  };
};

export default connect(mapStateToProps)(NewHistory);
