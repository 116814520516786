import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';

const components = {
  DropdownIndicator: null
};

const createOption = (label) => ({
  label,
  value: label
});

export default class CreatableInputOnly extends Component {
  state = {
    inputValue: '',
    value: []
  };

  componentDidMount() {
    this.setState({
      inputValue: '',
      value: this.props.value.split(',').map(a => { return { label: a, value: a } })
    });
  }

  handleChange = (value) => {
    this.setState({ value });
    this.props.change(value)
  };
  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };
  handleKeyDown = (event) => {
    const inputValue = this.state.inputValue;
    let value = this.state.value;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        value = [...value, createOption(inputValue)];
        this.props.change(value);
        this.setState({
          inputValue: '',
          value: value
        });
        event.preventDefault();
        break;
      default:
        break;
    }
  };
  render() {
    const { inputValue, value } = this.state;
    return (
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onInputKeyDown={this.handleKeyDown}
        placeholder="Type something and press enter..."
        noResultsText=""
        value={value}
      />
    );
  }
}
