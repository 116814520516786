import Input from "../Inputs/Input"

type NumberInfoElementProps = {
    className: string
    value: string
    fieldName: string
    disabled: boolean
    onChange: (value: string) => void
}

const NumberInfoElement = ({ className, value, fieldName, disabled, onChange }: NumberInfoElementProps) => {
    const onNumberValueChange = (value: string) => onChange(value)

    return (
        <div className={`fs-4 ${className}`}>
            <Input type="number" className="w-100" ariaLabel={`${fieldName}-number-input`} value={value} onChange={onNumberValueChange} disabled={disabled} />
        </div>
    )
}

export default NumberInfoElement
