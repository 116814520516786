import FilterOperators from "./FilterOperators"
import InputList from "./InputList"
import DatePickerWithInput from "../Inputs/DatePickerWithInput"
import moment from "moment"
import MultiInputWithOptions, { InputOption } from "../Inputs/MultiInputWithOptions"
import DataField from "../../types/DataField"
import GenericFilter from "./filterTypes/GenericFilter"
import Lookup from "../../types/Lookup"
import DataPrimitive from "../../types/DataPrimitive"
import FilterTypesByField from "./FilterTypesByField"
import { convertFilterToNewOperator, getOperationFieldFromOperator, isFilterValid } from "./helpers"
import NumberFieldMatchesOperationFilter from "./filterTypes/NumberFieldMatchesOperationFilter"
import DateFieldMatchesOperationFilter from "./filterTypes/DateFieldMatchesOperationFilter"
import DataFieldDropdown from "../dropdowns/DataFieldDropdown"
import Dropdown from "../dropdowns/Dropdown"
import Input from "../Inputs/Input"
import RelativeDatePicker from "./RelativeDatePicker"
import DateFieldRelativeFilter from "./filterTypes/DateFieldRelativeFilter"

type FilterProps = {
    filter: GenericFilter
    fields: DataField[]
    lookups: Lookup[]
    disabled: boolean
    onFilterUpdated: (filter: GenericFilter) => void
    onFilterDeleted: (filter: GenericFilter) => void
    showFieldType: boolean
    isCompact?: boolean
    textOnly?: boolean
    colour?: "grey" | "blue"
    showRelativeDateOptions?: boolean
    showFieldIsOneOfOptions?: boolean
}

const Filter = ({
    filter,
    fields,
    lookups,
    disabled,
    onFilterUpdated,
    onFilterDeleted,
    showFieldType,
    isCompact = false,
    textOnly = false,
    colour = "grey",
    showRelativeDateOptions = false,
    showFieldIsOneOfOptions = false
}: FilterProps) => {
    const selectedField = fields.find(f => f.value === filter.fieldName)

    const filterTypeDropdownOptions = [
        ...(selectedField ? getOperatorOptions(selectedField.type, showRelativeDateOptions, showFieldIsOneOfOptions) : []),
        ...FieldExistsDropdownOptions
    ].map((option, index) => ({
        id: index,
        ...option
    }))

    const onFilterDeletedClick = () => onFilterDeleted(filter)

    const onFieldChange = (field: DataField | undefined) => {
        if (!field) {
            onFilterUpdated({ ...filter, fieldName: "" })
            return
        }

        if (field.type !== selectedField?.type) {
            onFilterUpdated({ type: FilterTypesByField.NO_TYPE, id: filter.id, fieldName: field.value })
            return
        }

        onFilterUpdated({ ...filter, fieldName: field.value })
    }

    const onFieldOperatorChange = (operatorType: { value: string | number }) => {
        if (!selectedField) return

        const newFilter = convertFilterToNewOperator(filter, operatorType.value as FilterOperators, selectedField.type)
        onFilterUpdated(newFilter)
    }

    const onInputListChanged = (values: string[]) => {
        if (
            filter.type !== FilterTypesByField.TEXT_FIELD_IS_ONE_OF &&
            filter.type !== FilterTypesByField.NUMBER_FIELD_IS_ONE_OF &&
            filter.type !== FilterTypesByField.TEXT_FIELD_STARTS_WITH &&
            filter.type !== FilterTypesByField.TEXT_FIELD_IS_ONE_OF_FIELD &&
            filter.type !== FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF_FIELD &&
            filter.type !== FilterTypesByField.BOOLEAN_FIELD_IS_ONE_OF_FIELD &&
            filter.type !== FilterTypesByField.NUMBER_FIELD_IS_ONE_OF_FIELD &&
            filter.type !== FilterTypesByField.DATE_FIELD_IS_ONE_OF_FIELD
        )
            return

        onFilterUpdated({ ...filter, values: values })
    }

    const selectedFilterOperator = getFilterOperatorForFilter(filter)

    const onInputChange = (value: string) => {
        if (!selectedField) return

        if (filter.type === FilterTypesByField.TEXT_FIELD_STARTS_WITH) {
            onFilterUpdated({ ...filter, values: [value] })
            return
        }

        if (filter.type === FilterTypesByField.NUMBER_FIELD_MATCHES_OPERATION) {
            if (!selectedFilterOperator) return

            const operationName = getOperationFieldFromOperator(selectedFilterOperator)
            if (!operationName) return

            onFilterUpdated({
                ...filter,
                values: [
                    {
                        [operationName]: value
                    }
                ]
            })
        }
    }

    const onLookupFieldChanged = (options: InputOption[]) => {
        if (filter.type !== FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF) return

        onFilterUpdated({ ...filter, values: options.map(option => option.value) })
    }
    const onFieldIsOneOfFieldChanged = (options: InputOption[]) => {
        if (
            filter.type !== FilterTypesByField.TEXT_FIELD_IS_ONE_OF_FIELD &&
            filter.type !== FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF_FIELD &&
            filter.type !== FilterTypesByField.NUMBER_FIELD_IS_ONE_OF_FIELD &&
            filter.type !== FilterTypesByField.BOOLEAN_FIELD_IS_ONE_OF_FIELD &&
            filter.type !== FilterTypesByField.DATE_FIELD_IS_ONE_OF_FIELD
        ) return
        onFilterUpdated({ ...filter, values: options.map(option => option.value) })
    }

    const onDateChanged = (date: Date) => {
        if (filter.type === FilterTypesByField.DATE_FIELD_IS_ONE_OF) {
            onFilterUpdated({ ...filter, values: [moment(date).format()] })
            return
        }

        if (filter.type === FilterTypesByField.DATE_FIELD_MATCHES_OPERATION) {
            if (!selectedFilterOperator) return

            const operationName = getOperationFieldFromOperator(selectedFilterOperator)
            if (!operationName) return

            onFilterUpdated({
                ...filter,
                values: [
                    {
                        [operationName]: moment(date).format()
                    }
                ]
            })
        }
    }

    const onRelativeDateFilterChanged = (newFilter: DateFieldRelativeFilter) => {
        if (filter.type !== FilterTypesByField.DATE_FIELD_RELATIVE) return

        onFilterUpdated(newFilter)
    }

    const activeFilterField = fields.find(field => field.value === filter.fieldName)

    const lookup = lookups.find(l => l.reference === activeFilterField?.lookup)
    const lookupOptions = lookup === undefined ? [] : lookup.entries.map(entry => ({ value: entry.reference, label: entry.name }))

    const filterValueComponent = () => {
        if (selectedField === undefined) return <></>

        if (textOnly && "values" in filter) {
            return <>{filter.values.join(", ")}</>
        } else if (textOnly && filter.type === FilterTypesByField.DATE_FIELD_RELATIVE) {
            return (
                <>
                    {filter.numberOfDays} days {filter.isBefore ? "before" : "after"}{" "}
                    {filter.isRelativeToToday
                        ? "today"
                        : filter.referenceFieldName
                            ? `field: ${fields.find(f => f.value === filter.referenceFieldName)?.label ?? filter.referenceFieldName}`
                            : filter.relativeToDate
                                ? moment(filter.relativeToDate).format("dd MMMM yyyy")
                                : ""}
                </>
            )
        } else if (textOnly) return <></>

        switch (filter.type) {
            case FilterTypesByField.NUMBER_FIELD_IS_ONE_OF:
            case FilterTypesByField.TEXT_FIELD_IS_ONE_OF:
            case FilterTypesByField.TEXT_FIELD_STARTS_WITH:
                return (
                    <InputList
                        fieldName={filter.fieldName}
                        fieldType={selectedField.type}
                        values={filter.values}
                        onValuesChanged={onInputListChanged}
                        disabled={disabled}
                    />
                )
            case FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF:
                return (
                    <MultiInputWithOptions
                        options={lookupOptions}
                        selectedOptions={lookupOptions.filter(option => filter.values.includes(option.value))}
                        onOptionsChanged={onLookupFieldChanged}
                        disabled={disabled}
                    />
                )

            case FilterTypesByField.TEXT_FIELD_IS_ONE_OF_FIELD:
                return (
                    <MultiInputWithOptions
                        options={fields.filter(f => f.type === DataPrimitive.TEXT && f.value !== filter.fieldName)}
                        selectedOptions={fields.filter(option => filter.values.includes(option.value))}
                        onOptionsChanged={onFieldIsOneOfFieldChanged}
                        disabled={disabled}
                    />
                )
            case FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF_FIELD:
                return (
                    <MultiInputWithOptions
                        options={fields.filter(f => f.type === DataPrimitive.LOOKUP && f.value !== filter.fieldName)}
                        selectedOptions={fields.filter(option => filter.values.includes(option.value))}
                        onOptionsChanged={onFieldIsOneOfFieldChanged}
                        disabled={disabled}
                    />
                )
            case FilterTypesByField.BOOLEAN_FIELD_IS_ONE_OF_FIELD:
                return (
                    <MultiInputWithOptions
                        options={fields.filter(f => f.type === DataPrimitive.BOOLEAN && f.value !== filter.fieldName)}
                        selectedOptions={fields.filter(option => filter.values.includes(option.value))}
                        onOptionsChanged={onFieldIsOneOfFieldChanged}
                        disabled={disabled}
                    />
                )
            case FilterTypesByField.NUMBER_FIELD_IS_ONE_OF_FIELD:
                return (
                    <MultiInputWithOptions
                        options={fields.filter(f => f.type === DataPrimitive.NUMBER && f.value !== filter.fieldName)}
                        selectedOptions={fields.filter(option => filter.values.includes(option.value))}
                        onOptionsChanged={onFieldIsOneOfFieldChanged}
                        disabled={disabled}
                    />
                )
            case FilterTypesByField.DATE_FIELD_IS_ONE_OF_FIELD:
                return (
                    <MultiInputWithOptions
                        options={fields.filter(f => f.type === DataPrimitive.DATE && f.value !== filter.fieldName)}
                        selectedOptions={fields.filter(option => filter.values.includes(option.value))}
                        onOptionsChanged={onFieldIsOneOfFieldChanged}
                        disabled={disabled}
                    />
                )
            case FilterTypesByField.NUMBER_FIELD_MATCHES_OPERATION:
                return (
                    <Input
                        isValid={isFilterValid(filter)}
                        value={getOperationValueFromFilter(filter)}
                        onChange={onInputChange}
                        disabled={disabled}
                        ariaLabel="input-number-field-matches"
                    />
                )
            case FilterTypesByField.DATE_FIELD_IS_ONE_OF:
                return <DatePickerWithInput date={new Date(filter.values[0]!)} onDateChange={onDateChanged} disabled={disabled} />
            case FilterTypesByField.DATE_FIELD_MATCHES_OPERATION:
                return <DatePickerWithInput date={new Date(getOperationValueFromFilter(filter))} onDateChange={onDateChanged} disabled={disabled} />
            case FilterTypesByField.DATE_FIELD_RELATIVE:
                return (
                    <RelativeDatePicker
                        filter={filter}
                        disabled={disabled}
                        onFilterChange={onRelativeDateFilterChanged}
                        fields={fields}
                        isCompact={isCompact}
                    />
                )
            case FilterTypesByField.BOOLEAN_FIELD_IS_EQUAL_TO:
            case FilterTypesByField.FIELD_EXISTS:
            case FilterTypesByField.NO_TYPE:
            default:
                return <></>
        }
    }

    if (textOnly) {
        if (!selectedField) return <></>
        const fieldOperator = selectedFilterOperator ? (
            <span className="text-lowercase">{FilterOperators[selectedFilterOperator].replaceAll("_", " ")}</span>
        ) : (
            <span className="text-lowercase text-danger">(NO OPERATOR SET)</span>
        )
        return (
            <div className="d-flex gap-1">
                <span className="fw-bold">{selectedField.label}</span>
                {fieldOperator}
                <span className="fw-bold">{filterValueComponent()}</span>
            </div>
        )
    }

    return (
        <div className={`d-flex w-100 ${isCompact ? "flex-column" : "align-items-center"} ${colour === "grey" ? "text-grey" : "text-white"}`}>
            <div aria-label="filter-field">
                <DataFieldDropdown
                    options={fields}
                    disabled={disabled}
                    selectedOption={selectedField}
                    onOptionSelected={onFieldChange}
                    showFieldType={showFieldType}
                    ariaLabel="filter-field-dropdown"
                />
            </div>
            <div className={isCompact ? "my-2" : "mx-2"} aria-label="filter-type">
                {selectedField && (
                    <Dropdown
                        options={filterTypeDropdownOptions}
                        onOptionSelected={onFieldOperatorChange}
                        selectedOption={filterTypeDropdownOptions.find(o => o.value === selectedFilterOperator)}
                        disabled={disabled}
                        textAlign="left"
                        placeholder="Select filter type"
                    />
                )}
            </div>
            <div aria-label="filter-value">{selectedField && filterValueComponent()}</div>
            {!isFilterValid(filter) && filter.type === FilterTypesByField.NUMBER_FIELD_MATCHES_OPERATION && getOperationValueFromFilter(filter) !== "" && (
                <div className="ms-2 d-flex align-items-center text-danger no-select">
                    <i className="fal fa-exclamation-triangle me-2"></i>
                    <span>Please specify a valid number</span>
                </div>
            )}
            {!disabled && (
                <div className="d-flex ms-auto">
                    <div className="ms-auto ps-2 me-3 border-end align-self-stretch" />
                    <div role="button" className={`px-2 ${isCompact ? "ms-auto" : ""}`} onClick={onFilterDeletedClick} aria-label="filter-remove">
                        {" "}
                        <i className={`fal fa-trash-alt ${colour === "grey" ? "text-grey" : "text-white"}`} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Filter

const getOperationValueFromFilter = (filter: NumberFieldMatchesOperationFilter | DateFieldMatchesOperationFilter): string => {
    if (filter.values.length === 0) return ""

    const operation = filter.values[0]!
    return operation.greaterThan || operation.lessThan || operation.greaterThanOrEqualTo || operation.lessThanOrEqualTo || ""
}

const FieldExistsDropdownOptions = [
    {
        label: "Does exist",
        value: FilterOperators.EXISTS
    },
    {
        label: "Does not exist",
        value: FilterOperators.NOT_EXISTS
    }
]

const getOperatorOptions = (fieldType: DataPrimitive, showRelativeDateOptions: boolean, showFieldIsOneOfOptions: boolean) => {
    switch (fieldType) {
        case DataPrimitive.BOOLEAN:
            return [
                {
                    label: "Is true",
                    value: FilterOperators.TRUE
                },
                {
                    label: "Is false",
                    value: FilterOperators.FALSE
                }
            ].concat(
                showFieldIsOneOfOptions ? [{
                    label: "Is one of field",
                    value: FilterOperators.ONE_OF_FIELD
                },
                {
                    label: "Is not one of field",
                    value: FilterOperators.NOT_ONE_OF_FIELD
                }] : []
            )
        case DataPrimitive.DATE:
            return [
                {
                    label: "Is",
                    value: FilterOperators.EQUAL_TO
                },
                {
                    label: "Is greater than",
                    value: FilterOperators.GREATER_THAN
                },
                {
                    label: "Is less than",
                    value: FilterOperators.LESS_THAN
                },
                {
                    label: "Is greater than or equal to",
                    value: FilterOperators.GREATER_THAN_OR_EQUAL_TO
                },
                {
                    label: "Is less than or equal to",
                    value: FilterOperators.LESS_THAN_OR_EQUAL_TO
                }
            ].concat(
                showRelativeDateOptions
                    ? [
                        {
                            label: "Is at most (relative)",
                            value: FilterOperators.AT_MOST
                        },
                        {
                            label: "Is at least (relative)",
                            value: FilterOperators.AT_LEAST
                        },
                        {
                            label: "Is exactly (relative)",
                            value: FilterOperators.EXACTLY
                        }
                    ]
                    : []
            ).concat(
                showFieldIsOneOfOptions ? [{
                    label: "Is one of field",
                    value: FilterOperators.ONE_OF_FIELD
                },
                {
                    label: "Is not one of field",
                    value: FilterOperators.NOT_ONE_OF_FIELD
                }] : []
            )
        case DataPrimitive.NUMBER:
            return [
                {
                    label: "Is one of",
                    value: FilterOperators.ONE_OF
                },
                {
                    label: "Is not one of",
                    value: FilterOperators.NOT_ONE_OF
                },
                {
                    label: "Is greater than",
                    value: FilterOperators.GREATER_THAN
                },
                {
                    label: "Is less than",
                    value: FilterOperators.LESS_THAN
                },
                {
                    label: "Is greater than or equal to",
                    value: FilterOperators.GREATER_THAN_OR_EQUAL_TO
                },
                {
                    label: "Is less than or equal to",
                    value: FilterOperators.LESS_THAN_OR_EQUAL_TO
                }
            ].concat(
                showFieldIsOneOfOptions ? [{
                    label: "Is one of field",
                    value: FilterOperators.ONE_OF_FIELD
                },
                {
                    label: "Is not one of field",
                    value: FilterOperators.NOT_ONE_OF_FIELD
                }] : []
            )
        case DataPrimitive.TEXT:
            return [
                {
                    label: "Is one of",
                    value: FilterOperators.ONE_OF
                },
                {
                    label: "Starts with",
                    value: FilterOperators.STARTS_WITH
                }
            ].concat(
                showFieldIsOneOfOptions ? [{
                    label: "Is one of field",
                    value: FilterOperators.ONE_OF_FIELD
                },
                {
                    label: "Is not one of field",
                    value: FilterOperators.NOT_ONE_OF_FIELD
                }] : []
            )
        case DataPrimitive.LOOKUP:
            return [
                {
                    label: "Is one of",
                    value: FilterOperators.ONE_OF
                },
                {
                    label: "Is not one of",
                    value: FilterOperators.NOT_ONE_OF
                }
            ].concat(
                showFieldIsOneOfOptions ? [{
                    label: "Is one of field",
                    value: FilterOperators.ONE_OF_FIELD
                },
                {
                    label: "Is not one of field",
                    value: FilterOperators.NOT_ONE_OF_FIELD
                }] : []
            )
        default:
            return []
    }
}

const checkIfTruthyIncludingEmpty = (property: undefined | string): Boolean => !!property || property === ""

const getOperatatorFromOperationFilter = (filter: NumberFieldMatchesOperationFilter | DateFieldMatchesOperationFilter): FilterOperators => {
    const operation = filter.values[0]!
    if (checkIfTruthyIncludingEmpty(operation.greaterThan)) return FilterOperators.GREATER_THAN
    if (checkIfTruthyIncludingEmpty(operation.lessThan)) return FilterOperators.LESS_THAN
    if (checkIfTruthyIncludingEmpty(operation.greaterThanOrEqualTo)) return FilterOperators.GREATER_THAN_OR_EQUAL_TO
    if (checkIfTruthyIncludingEmpty(operation.lessThanOrEqualTo)) return FilterOperators.LESS_THAN_OR_EQUAL_TO

    return FilterOperators.GREATER_THAN
}

const getFilterOperatorForFilter = (filter: GenericFilter) => {
    switch (filter.type) {
        case FilterTypesByField.TEXT_FIELD_IS_ONE_OF:
            return filter.notOneOf ? FilterOperators.NOT_ONE_OF : FilterOperators.ONE_OF
        case FilterTypesByField.TEXT_FIELD_IS_ONE_OF_FIELD:
        case FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF_FIELD:
        case FilterTypesByField.BOOLEAN_FIELD_IS_ONE_OF_FIELD:
        case FilterTypesByField.NUMBER_FIELD_IS_ONE_OF_FIELD:
        case FilterTypesByField.DATE_FIELD_IS_ONE_OF_FIELD:
            return filter.notOneOf ? FilterOperators.NOT_ONE_OF_FIELD : FilterOperators.ONE_OF_FIELD
        case FilterTypesByField.TEXT_FIELD_STARTS_WITH:
            return FilterOperators.STARTS_WITH
        case FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF:
            return filter.notOneOf ? FilterOperators.NOT_ONE_OF : FilterOperators.ONE_OF
        case FilterTypesByField.BOOLEAN_FIELD_IS_EQUAL_TO:
            return filter.equalTo ? FilterOperators.TRUE : FilterOperators.FALSE
        case FilterTypesByField.NUMBER_FIELD_IS_ONE_OF:
            return filter.notOneOf ? FilterOperators.NOT_ONE_OF : FilterOperators.ONE_OF
        case FilterTypesByField.NUMBER_FIELD_MATCHES_OPERATION:
        case FilterTypesByField.DATE_FIELD_MATCHES_OPERATION:
            return getOperatatorFromOperationFilter(filter)
        case FilterTypesByField.DATE_FIELD_IS_ONE_OF:
            return FilterOperators.EQUAL_TO
        case FilterTypesByField.DATE_FIELD_RELATIVE:
            return getFilterOperatorForRelativeDate(filter)
        case FilterTypesByField.FIELD_EXISTS:
            return filter.notExists ? FilterOperators.NOT_EXISTS : FilterOperators.EXISTS
        case FilterTypesByField.NO_TYPE:
        default:
            return undefined
    }
}

const getFilterOperatorForRelativeDate = (filter: DateFieldRelativeFilter) => {
    switch (filter.relativity) {
        case "AT_LEAST":
            return FilterOperators.AT_LEAST
        case "AT_MOST":
            return FilterOperators.AT_MOST
        case "EXACTLY":
            return FilterOperators.EXACTLY
    }
}
