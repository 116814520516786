import UserCard from "./cards/UserCard"
import classes from './UserView.module.scss'

const UserView = ({ clientUsers }) => {
    return (
        <div className={classes["grid-container"]}>
            {clientUsers.length > 0 &&
                clientUsers.map(clientUser =>
                    <div key={clientUser.id} className="m-2">
                        <UserCard state={clientUser} />
                    </div>
                )
            }
        </div>
    )
}

export default UserView