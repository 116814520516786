import { ReactNode, useState } from "react"
import useClickOutsideRef from "../../hooks/useClickOutsideRef"
import DropdownOption from "../../types/DropdownOptions"
import classes from "./Dropdown.module.scss"
import MultiSelectDropOption from "./MultiSelectDropOption"

type DropdownProps<T> = {
    options: DropdownOption<T>[]
    onSelectedOptionsChanged: (options: DropdownOption<T>[]) => void
    selectedOptions: DropdownOption<T>[] | undefined
    className?: string
    disabled?: boolean
    direction?: "down" | "up"
    textAlign?: "left" | "center"
    placeholder?: ReactNode
    ariaLabel?: string
    fixedSize?: boolean
    colour?: "white" | "blue"
    displayElementAsLabel?: boolean
}

const MultiSelectDropdown = <T,>({
    options,
    onSelectedOptionsChanged,
    selectedOptions = [],
    className = "",
    disabled = false,
    direction = "down",
    textAlign = "left",
    placeholder = "Please select",
    ariaLabel = "dropdown",
    fixedSize = false,
    colour = "white",
    displayElementAsLabel = false
}: DropdownProps<T>) => {
    const ref = useClickOutsideRef(() => setIsOpen(false))
    const [isOpen, setIsOpen] = useState(false)

    const areAllOptionsSelected = options.every(o => selectedOptions.includes(o))

    const onOptionsClicked = (option: DropdownOption<T>) => {
        const newSelected = selectedOptions?.includes(option)
            ? selectedOptions.filter(o => o.value !== option.value && o.label !== option.label)
            : [...selectedOptions, option]
        onSelectedOptionsChanged(newSelected)
    }

    const toggleOpen = () => {
        if (disabled) return
        setIsOpen(open => !open)
    }

    const longestOptionInSpaces = options.length > 0 ? "#".repeat(options.map(o => o.label).reduce((a, b) => (a.length > b.length ? a : b)).length) : ""

    const minimumSizeStretchingDiv = (
        <div className="px-2 overflow-hidden fs-6" style={{ height: "0px" }}>
            {longestOptionInSpaces}
            <i className="fal fa-chevron-right ms-2" />
        </div>
    )

    const fixedSizeStrechingDiv = (
        <div className={`px-2 overflow-hidden ${classes.fixedSize}`} style={{ height: "0px" }}>
            <i className="fal fa-chevron-right ms-2" />
        </div>
    )

    const showingOptions = isOpen && options.length > 0 && !disabled

    const getDropdownDisplayValue = (): JSX.Element => {
        if (selectedOptions.length === 0) return <>NONE</>
        if (areAllOptionsSelected) return <>ALL</>
        if (options.every(o => !selectedOptions.includes(o))) return <>NONE</>
        return displayElementAsLabel ? (
            <>{selectedOptions.map(o => o.element ?? o.label)}</>
        ) : (
            <>{selectedOptions.map(o => o.label).join(", ") ?? placeholder}</>
        )
    }

    const onSelectAllClicked = () => {
        if (areAllOptionsSelected) {
            onSelectedOptionsChanged([])
            return
        }
        onSelectedOptionsChanged(options)
    }

    return (
        <div ref={ref} className={`d-flex position-relative no-select ${className} ${disabled ? "not-allowed" : "pointer"}`}>
            <div
                className={`d-flex flex-column position-absolute overflow-auto w-100
                ${classes.options}
                ${classes[`${colour}Background`]}
                ${showingOptions ? classes.selectedOptions : ""} 
                ${direction === "down" ? classes.downwardsOptions : classes.upwardsOptions}`}
            >
                {fixedSize ? fixedSizeStrechingDiv : minimumSizeStretchingDiv}
                {showingOptions && [
                    <MultiSelectDropOption
                        key={-1}
                        option={{ label: "SELECT ALL", value: "" }}
                        isSelectedOption={areAllOptionsSelected}
                        onOptionClicked={onSelectAllClicked}
                        sticky={true}
                    />,
                    ...options.map((option, index) => (
                        <MultiSelectDropOption
                            key={index}
                            option={option}
                            isSelectedOption={areAllOptionsSelected || selectedOptions?.includes(option)}
                            textAlign={textAlign}
                            onOptionClicked={onOptionsClicked}
                        />
                    ))
                ]}
            </div>
            <div className="d-flex flex-column w-100">
                <div
                    className={`d-flex px-2 py-1 fs-5
                        ${textAlign === "center" ? "align-items-center text-center justify-content-around" : ""} 
                        ${classes.button}
                        ${classes[`${colour}Background`]}
                        ${disabled ? classes.disabled : ""}
                        ${showingOptions ? (direction === "down" ? classes.selectedDownwardsButton : classes.selectedUpwardsButton) : ""}
                        ${direction === "up" ? classes.onTop : ""}
                        ${fixedSize && classes.fixedSize}
                    `}
                    role={disabled ? undefined : "button"}
                    aria-label={ariaLabel}
                    onClick={toggleOpen}
                >
                    <span
                        className={`d-flex flex-grow-1 text-truncate fs-6 my-auto ${textAlign === "center" ? "justify-content-center" : ""}`}
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                    >
                        {getDropdownDisplayValue()}
                    </span>
                    <i
                        className={`fal fa-chevron-right my-auto
                        ${classes.chevron} 
                        ${showingOptions ? (direction === "down" ? classes.selectedDownwardsChevron : classes.selectedUpwardsChevron) : ""}`}
                    />
                </div>
                {fixedSize ? fixedSizeStrechingDiv : minimumSizeStretchingDiv}
            </div>
        </div>
    )
}

export default MultiSelectDropdown
