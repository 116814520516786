import { StandardButton } from "invevo-react-components"
import FileUploadButton from "../../../../../../library/buttons/FileUploadButton/FileUploadButton"
import { EntityExportFieldConfigState, FileExportConfigAction, FileExportConfigActionEnum } from "../../../../reducers/FileEntityExportConfigReducer"
import {
    FileImportConfigColumnHeader,
    FileImportConfigColumnIndex,
    FileImportConfigColumnType,
    FileImportConfigColumnTypeEnum
} from "../../../../types/FileImportConfigColumnType"
import {
    FileImportConfigFieldDataType,
    FileImportConfigFieldDataTypeBoolean,
    FileImportConfigFieldDataTypeDate,
    FileImportConfigFieldDataTypeEnum,
    FileImportConfigFieldDataTypeNumber,
    FileImportConfigFieldDataTypeText
} from "../../../../types/FileImportConfigFieldDataType"
import { v4 as uuidv4 } from "uuid"
import {
    FileImportConfigFieldDefaultBooleanValue,
    FileImportConfigFieldDefaultNumberValue,
    FileImportConfigFieldDefaultStringValue,
    FileImportConfigFieldDefaultValueEnum
} from "../../../../types/FileImportConfigFieldDefaultValue"
import EntityDataField from "../../../../../entityConfig/types/EntityDataField"
import ExportFieldConfig from "./ExportFieldConfig"
import { useToaster } from "../../../../../../hooks/useToaster"

const EXPORT_FIELD_FILE_HEADERS = ["dataFieldReference", "dataType", "defaultValue", "column"]

type ExportFieldConfigsProps = {
    state: { fieldConfigs: EntityExportFieldConfigState[]; dataFields: EntityDataField[]; hasHeaderRow: Boolean }
    dispatch: React.Dispatch<FileExportConfigAction>
}

const ExportFieldConfigs = ({ state, dispatch }: ExportFieldConfigsProps) => {
    const toaster = useToaster()

    const onAddFieldClick = () => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_ADDED
        })
    }

    const onFieldRemoveClick = (fieldConfig: EntityExportFieldConfigState) => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_REMOVED,
            fieldConfigKey: fieldConfig.key
        })
    }

    const onExportFieldsFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0]
        const reader: FileReader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
            const lines = e?.target?.result?.toString()?.split("\r\n") || []
            const header = lines[0]!.split(",")
            const content = lines.slice(1)

            if (EXPORT_FIELD_FILE_HEADERS.filter(f => !header.includes(f)).length !== 0) {
                toaster.error("Uploaded import fields didn't contain all necessary columns")
                return
            }
            let newFields: EntityExportFieldConfigState[] = []
            content.forEach(line => {
                const newLine = recogniseTextBetweenSpeachmarks(line)
                const fields = newLine.split(",")
                const dataField = getDataField(header, fields)
                if (dataField === undefined) {
                    toaster.error(
                        `Invalid field with type ${
                            fields[header.indexOf("dataType")]
                        }. Valid data types are NUMBER, TEXT, DATE, BOOLEAN, MONEY, INT, DECIMAL, PERCENTAGE, EMAIL, PHONE_NUMBER, LOOKUP, RICH_TEXT,`
                    )
                    return
                }
                newFields = [...newFields, dataField]
            })
            dispatch({
                type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIGS_UPLOADED,
                fieldConfigs: newFields
            })
        }
        file && reader.readAsText(file)
    }

    const recogniseTextBetweenSpeachmarks = (line: string) => line.replace(/"[^"]+"/g, match => match.replace(/,/g, "")).replace(/"/g, "")

    const getDataField = (header: string[], fields: string[]): EntityExportFieldConfigState | undefined => {
        const dataType = getDataType(fields, header)
        if (dataType === undefined) return
        return {
            key: uuidv4(),
            dataFieldReference: fields[header.indexOf("dataFieldReference")]!,
            dataType: dataType,
            defaultValue: getDefaultValue(dataType, fields[header.indexOf("defaultValue")]!),
            column: getColumn(fields[header.indexOf("column")]!) as FileImportConfigColumnType,
            ordinal: 0
        }
    }

    const getDataType = (fields: string[], header: string[]) => {
        switch (fields[header.indexOf("dataType")]) {
            case "NUMBER":
            case "MONEY":
            case "INT":
            case "DECIMAL":
            case "PERCENTAGE":
                return {
                    type: FileImportConfigFieldDataTypeEnum.NUMBER,
                    culture: fields[header.indexOf("culture")] || "gb"
                } as FileImportConfigFieldDataTypeNumber
            case "DATE":
                return {
                    type: FileImportConfigFieldDataTypeEnum.DATE,
                    format: fields[header.indexOf("dateFormat")],
                    timezone: fields[header.indexOf("timezone")]
                } as FileImportConfigFieldDataTypeDate
            case "BOOLEAN":
                return { type: FileImportConfigFieldDataTypeEnum.BOOLEAN } as FileImportConfigFieldDataTypeBoolean
            case "TEXT":
            case "EMAIL":
            case "PHONE_NUMBER":
            case "LOOKUP":
            case "RICH_TEXT":
                return { type: FileImportConfigFieldDataTypeEnum.TEXT } as FileImportConfigFieldDataTypeText
            default:
                return
        }
    }

    const getDefaultValue = (dataType: FileImportConfigFieldDataType, defaultValue: string) => {
        if (defaultValue.length === 0) return undefined
        else
            switch (dataType.type) {
                case FileImportConfigFieldDataTypeEnum.NUMBER:
                    return { type: FileImportConfigFieldDefaultValueEnum.NUMBER, numberValue: defaultValue } as FileImportConfigFieldDefaultNumberValue
                case FileImportConfigFieldDataTypeEnum.BOOLEAN:
                    return {
                        type: FileImportConfigFieldDefaultValueEnum.BOOLEAN,
                        booleanValue: defaultValue.toLowerCase() === "true"
                    } as FileImportConfigFieldDefaultBooleanValue
                default:
                    return { type: FileImportConfigFieldDefaultValueEnum.STRING, stringValue: defaultValue } as FileImportConfigFieldDefaultStringValue
            }
    }

    const getColumn = (columnField: string) => {
        if (state.hasHeaderRow)
            return {
                type: FileImportConfigColumnTypeEnum.HEADER,
                header: columnField
            } as FileImportConfigColumnHeader
        else
            return {
                type: FileImportConfigColumnTypeEnum.INDEX,
                index: parseInt(columnField)
            } as FileImportConfigColumnIndex
    }

    const onMoveColumnUp = (columnIndex: number) => {
        dispatch({ type: FileExportConfigActionEnum.FILE_EXPORT_CONFIG_MOVED_UP, configs: state.fieldConfigs, columnIndexToMove: columnIndex })
    }

    const onMoveColumnDown = (columnIndex: number) => {
        dispatch({ type: FileExportConfigActionEnum.FILE_EXPORT_CONFIG_MOVED_DOWN, configs: state.fieldConfigs, columnIndexToMove: columnIndex })
    }

    return (
        <div className="d-flex flex-column mt-4">
            <div className="d-flex align-items-center">
                <span className="text-grey fs-3">{`Fields (${state.fieldConfigs.length})`}</span>
                <StandardButton
                    className="ms-auto"
                    label="Add new field"
                    colour="white"
                    iconClasses="fal fa-plus"
                    onClick={onAddFieldClick}
                    ariaLabel="Add new field"
                />
                <FileUploadButton className="ms-2" label="Import data fields" onFileUpload={onExportFieldsFileSelected} ariaLabel="import-data-fields" />
            </div>

            <div className="d-flex flex-column mt-2 gap-2">
                {state.fieldConfigs.length > 0 &&
                    state.fieldConfigs
                        .sort((a, b) => a.ordinal - b.ordinal)
                        .map((fieldConfig, index) => (
                            <ExportFieldConfig
                                key={fieldConfig.key}
                                state={{
                                    config: fieldConfig,
                                    dataFields: state.dataFields,
                                    hasHeaderRow: state.hasHeaderRow,
                                    ordinal: index
                                }}
                                isFirst={index === 0}
                                isLast={index === state.fieldConfigs.length - 1}
                                index={index}
                                onMoveColumnUp={onMoveColumnUp}
                                onMoveColumnDown={onMoveColumnDown}
                                dispatch={dispatch}
                                onRemoveClick={onFieldRemoveClick}
                            />
                        ))}
            </div>
        </div>
    )
}

export default ExportFieldConfigs
