import React from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

const TooltipButton = ({ children, title, disabled = false, ...rest }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={(props) => <Tooltip {...props}>{title}</Tooltip>}
    >
      <span className="d-inline-block">
        <Button
          {...rest}
          disabled={disabled}
          style={disabled ? { pointerEvents: 'none' } : null}
        >
          {children}
        </Button>
      </span>
    </OverlayTrigger>
  );
};

export default TooltipButton;
