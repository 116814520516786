import { IconButton } from "invevo-react-components"

type WorkflowRulesHeaderProps = {
    onNewRuleClicked: () => void
}

const WorkflowRulesHeader = ({ onNewRuleClicked }: WorkflowRulesHeaderProps) => {
    return (
        <div className="d-flex flex-column" aria-label="header">
            <div className="d-flex align-items-center">
                <div className="d-flex ">
                    <h2 className="text-grey no-select fw-light mb-0">Workflow Rules</h2>
                </div>
                <div className="d-flex ms-auto">
                    <IconButton iconClasses="fal fa-plus" onClick={onNewRuleClicked} />
                </div>
            </div>
        </div>
    )
}

export default WorkflowRulesHeader
