import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { optionStyles } from '../../utility/selectSupport';
import { getAccountStatusList } from '../../api/accountStatusApi';
import { find } from 'lodash';
import { useClient } from 'invevo-react-components';

const AccountStatusSelect = ({
  id,
  onChange,
  getAccountStatusList,
  accountStatuses
}) => {
  const client = useClient()
  
  useEffect(() => {
    if (!accountStatuses || accountStatuses.length === 0)
      getAccountStatusList(client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedOption, setSelectedOption] = useState(null);
  useEffect(() => {
    const found = find(accountStatuses, (o) => o.value === id);

    if (found) {
      setSelectedOption(found);
    }
  }, [accountStatuses, id]);

  const onSelectChange = (selected) => {
    setSelectedOption(selected);
    onChange(selected.value, selected.isActive, selected.label);
  };

  return (
    <Select
      value={selectedOption}
      options={accountStatuses}
      onChange={onSelectChange}
      placeholder={'Please select'}
      styles={{
        option: optionStyles
      }}
    />
  );
};

AccountStatusSelect.propTypes = {
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  accountStatuses: state.accountStatusReducer.accountStatusList
    .filter((a) => a.isInUse === true)
    .map((a) => ({
      isActive: a.isActive,
      label: a.clientName,
      value: a.id
    }))
});

export default connect(mapStateToProps, { getAccountStatusList })(
  AccountStatusSelect
);
