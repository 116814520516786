export const ChartColours = [
    "#ef627a",
    "#5bb5b7",
    "#f49745",
    "#8765f7",
    "#f9c75b",
    "#c2c4c8",
    "#F4E8C1",
    "#A0C1B9",
    "#7D84B2",
    "#70A0AF",
    "#8AA29E",
    "#D57A66",
    "#72A276",
    "#EDC79B",
    "#63A375",
    "#F7A9A8",
    "#97B1A6",
    "#E8985E",
    "#9FC490",
    "#967D69",
    "#A9B18F",
    "#C19875",
    "#A2E3C4",
    "#CA907E",
    "#E3D081"
] as const
