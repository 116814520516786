export type FileImportConfigColumnType = FileImportConfigColumnHeader | FileImportConfigColumnIndex

export enum FileImportConfigColumnTypeEnum {
    HEADER = "HEADER",
    INDEX = "INDEX"
}

export type FileImportConfigColumnHeader = {
    type: FileImportConfigColumnTypeEnum.HEADER
    header: string
}

export type FileImportConfigColumnIndex = {
    type: FileImportConfigColumnTypeEnum.INDEX
    index: number
}
