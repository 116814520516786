import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { map, find, isEqual, isEmpty } from 'lodash';
import Formsy from 'formsy-react';
import { authZ_Permissions, actionSource } from '../../../constants';
import Dropdown from '../../common/dropdown';
import ValidatingInput from '../../common/ValidatingInput';
import { hasPermission } from '../../../utility/authZ';
import RuleConditionGrid from './ruleConditionsGrid';
import RuleActionsGrid from './ruleActionsGrid';

class RuleGroupForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.enableSave = this.enableSave.bind(this);
    this.disableSave = this.disableSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeActionSource = this.onChangeActionSource.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onSave = this.onSave.bind(this);

    this.state = RuleGroupForm.defaultFormState();
  }

  static defaultFormState() {
    return {
      ruleGroup: {
        messageType: '',
        enabled: false,
        description: ''
      },
      canSave: false,
      validationMessage: ''
    };
  }

  componentDidMount() {
    if (!isEmpty(this.props.ruleGroup))
      this.setState({
        ruleGroup: this.props.ruleGroup
      });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.ruleGroupValidationResult.items !==
      prevProps.ruleGroupValidationResult.items
    ) {
      let validationMessage = '';

      if (this.props.ruleGroupValidationResult.items) {
        validationMessage = this.props.ruleGroupValidationResult.items
          .map((m) => m.message)
          .join('\r\n');
      }

      this.setState({ validationMessage: validationMessage });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      !isEmpty(props.ruleGroup) &&
      !isEqual(props.ruleGroup, state.ruleGroup)
    ) {
      return { ruleGroup: props.ruleGroup };
    }
    return null;
  }

  rebindGrids() { }

  enableSave() {
    this.setState({ canSave: true });
  }

  disableSave() {
    this.setState({ canSave: false });
  }

  onChange(event) {
    const field = event.target.name;
    const ruleGroup = Object.assign({}, this.state.ruleGroup);

    ruleGroup[field] = event.target.value;

    this.props.onUpdateRuleGroup(ruleGroup);
  }

  onChangeActionSource(event) {
    const ruleGroup = Object.assign({}, this.state.ruleGroup);

    ruleGroup.actionSource = event.target.checked
      ? actionSource.Portal
      : actionSource.Unknown;

    this.props.onUpdateRuleGroup(ruleGroup);
  }

  onChangeCheckbox(event) {
    const field = event.target.name;
    const ruleGroup = Object.assign({}, this.state.ruleGroup);

    ruleGroup[field] = event.target.checked;

    this.props.onUpdateRuleGroup(ruleGroup);
  }

  onSave() {
    this.props.onSave(this.state.ruleGroup);
  }

  onEventConditionsChanged(values) {
    if (values === null) values = [];

    const filterValues = values.map((v) => {
      return v.value;
    });
    const ruleGroup = Object.assign({}, this.state.ruleGroup);

    ruleGroup.eventConditions = {
      Columns: filterValues
    };

    this.props.onUpdateRuleGroup(ruleGroup);
  }

  render() {
    let isDisabled = false;

    const header = this.state.ruleGroup.id
      ? `Edit Rule Group (Id:${this.state.ruleGroup.id})`
      : 'Add Rule Group';
    const messageType = find(
      this.props.messageTypes.result,
      (f) => f.name === this.state.ruleGroup.messageType
    );
    const eventConditions = messageType
      ? map(messageType.eventConditions, (m) => {
        return { label: m, value: m };
      })
      : [];
    const selectedEventConditions = this.state.ruleGroup.eventConditions
      ? map(this.state.ruleGroup.eventConditions.Columns, (m) => {
        return { label: m, value: m };
      })
      : [];
    let buttons = (
      <div>
        <button
          type="button"
          className="btn btn-default"
          disabled={!this.state.canSave}
          onClick={this.onSave}
        >
          Save
        </button>
        <button className="btn btn-default" onClick={this.props.onCancel}>
          Cancel
        </button>
      </div>
    );
    let newConditionButton = (
      <button
        id="btnNewRuleCondition"
        type="button"
        className="btn btn-default"
        disabled={!this.state.ruleGroup.messageType}
        onClick={this.props.onNewCondition}
      >
        New Rule Condition
      </button>
    );
    let newActionButton = (
      <button
        id="btnRuleAction"
        type="button"
        className="btn btn-default"
        disabled={!this.state.ruleGroup.messageType}
        onClick={this.props.onNewAction}
      >
        New Rule Action
      </button>
    );

    if (
      !hasPermission(
        this.props.permissions,
        authZ_Permissions.RuleGroupManagementAdmin
      )
    ) {
      isDisabled = true;
      buttons = (
        <div>
          <button className="btn btn-default" onClick={this.props.onCancel}>
            Cancel
          </button>
        </div>
      );
      newConditionButton = <span />;
      newActionButton = <span />;
    }

    return (
      <Formsy
        key="rule-group-form"
        onValid={this.enableSave}
        onInvalid={this.disableSave}
        className="card below-grid-form"
        id="rulegroupform"
      >
        <h3>{header}</h3>
        <div
          className="row form-group"
          style={{ marginRight: '-15px', marginLeft: '-15px' }}
        >
          <div className="col-md-4">
            <Dropdown
              title="Message Types"
              selectedItemId={this.state.ruleGroup.messageType}
              data={this.props.messageTypes.result}
              idProp="name"
              textProp="name"
              onClick={(id) => {
                this.onChange({ target: { name: 'messageType', value: id } });
              }}
              disabled={isDisabled}
            />
          </div>
          <div className="col-md-4">
            <Select
              name="event-conditions-select"
              isMulti
              value={selectedEventConditions}
              options={eventConditions}
              isClearable
              placeholder="Event Conditions"
              onChange={this.onEventConditionsChanged.bind(this)}
              disabled={isDisabled}
            />
          </div>
        </div>
        <div
          className="row form-group"
          style={{ marginRight: '-15px', marginLeft: '-15px' }}
        >
          <div className="col-md-4">
            <ValidatingInput
              id="ruleGroup"
              name="ruleGroup"
              type="text"
              placeholder="Rule Group Name"
              hideLabel={true}
              onChange={this.onChange}
              required
              value={this.state.ruleGroup.ruleGroup}
              maxLength="50"
              disabled={isDisabled}
            />
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>
                <input
                  name="actionSource"
                  type="checkbox"
                  checked={
                    this.state.ruleGroup.actionSource === actionSource.Portal
                  }
                  onChange={this.onChangeActionSource}
                  disabled={isDisabled}
                />{' '}
                Portal Specific Rule
              </label>
            </div>
          </div>
        </div>
        <div
          className="row form-group"
          style={{ marginRight: '-15px', marginLeft: '-15px' }}
        >
          <div className="col-md-4">
            <textarea
              rows="4"
              maxLength="500"
              name="description"
              placeholder="Description"
              onChange={this.onChange}
              value={this.state.ruleGroup.description}
              style={{ width: '100%' }}
              disabled={isDisabled}
            />
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>
                <input
                  name="enabled"
                  type="checkbox"
                  checked={this.state.ruleGroup.enabled}
                  onChange={this.onChangeCheckbox}
                  disabled={isDisabled}
                />{' '}
                Enabled
              </label>
            </div>
          </div>
        </div>
        <div
          className="row form-group"
          style={{ marginRight: '-15px', marginLeft: '-15px' }}
        >
          <div className="col-md-4">
            <textarea
              readOnly
              rows="4"
              placeholder={this.state.ruleGroup.valid ? 'Valid' : 'Invalid'}
              name="valid"
              style={{ width: '100%' }}
              value={this.state.validationMessage}
            />
          </div>
        </div>
        <div
          className="row form-group"
          style={{ marginRight: '-15px', marginLeft: '-15px' }}
        >
          <div className="col-12">
            <label>Rule Conditions:</label>
            <RuleConditionGrid
              id="gridRuleConditions"
              key="gridRuleConditions"
              ruleGroup={this.state.ruleGroup}
              permissions={this.props.permissions}
              onUpdateRuleGroup={this.props.onUpdateRuleGroup}
              onEditCondition={this.props.onEditCondition}
            />
            {newConditionButton}
          </div>
        </div>
        <div
          className="row form-group"
          style={{ marginRight: '-15px', marginLeft: '-15px' }}
        >
          <div className="col-12">
            <label>Rule Actions:</label>
            <RuleActionsGrid
              id="gridRuleActions"
              key="gridRuleActions"
              ruleGroup={this.state.ruleGroup}
              permissions={this.props.permissions}
              onUpdateRuleGroup={this.props.onUpdateRuleGroup}
              onEditAction={this.props.onEditAction}
            />
            {newActionButton}
          </div>
        </div>
        <div
          className="row form-group"
          style={{ marginRight: '-15px', marginLeft: '-15px' }}
        >
          <div className="col-md-8">{buttons}</div>
        </div>
        <br />
        <br />
      </Formsy>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.authReducer.permissions
  };
}

const mapDispatchToEvents = () => ({});

export default connect(mapStateToProps, mapDispatchToEvents)(RuleGroupForm);
