import { useState } from "react"
import classes from "./Search.module.scss"
import { useGrid } from "../Grid"
import Input from "../../Inputs/Input"
import { DataPrimitiveTypeEnum, DataTypeEnum } from "../../../routes/entityConfig/types/DataType"
import FiltersDto from "../../FilterList/FiltersDto"
import useApiQuery from "../../../hooks/useApiQuery"
import EntityResults from "../types/EntityResults"
import useClient from "../../../hooks/useClient"
import EntityConfig from "../../../routes/entityConfig/types/EntityConfig"
import { useEnvConfig } from "../../../contexts/EnvironmentConfigContext"
import { padElementsWith } from "../../helpers"
import useClickOutsideRef from "../../../hooks/useClickOutsideRef"

type Props = {
    entityConfig: EntityConfig
    filters: FiltersDto
}

type AutoCompleteList = {
    fieldName: string
    displayName: string
    fieldValue: string
}

const AutoCompleteEntitySearch = ({ filters, entityConfig }: Props) => {
    const grid = useGrid()
    const client = useClient()
    const config = useEnvConfig()

    const fields = entityConfig.fields
        .filter(
            field =>
                field.dataType.type === DataTypeEnum.TEXT ||
                field.dataType.type === DataTypeEnum.LOOKUP ||
                field.dataType.type === DataTypeEnum.EMAIL ||
                field.dataType.type === DataTypeEnum.PHONE_NUMBER ||
                field.dataType.type === DataTypeEnum.RICH_TEXT
        )
        .map(field => field.fieldName)

    const [showingOptions, setShowingOptions] = useState(false)

    const ref = useClickOutsideRef(() => {
        setShowingOptions(false)
    })

    const getDisplayName = (value: string): string => entityConfig?.fields.find(field => field.fieldName === value)?.displayName ?? value
    const [searchValue, setSearchValue] = useState("")
    const getEntitiesAutocompleteRequest = useApiQuery<EntityResults>({
        url: `${config.SEARCH_API_URL}/api/${client}/entities`,
        method: "POST",
        isExecutedAutomatically: false
    })

    const setAutoCompleteList = () => {
        const listFromApi: AutoCompleteList[] = []
        getEntitiesAutocompleteRequest.data?.pagedEntity.forEach(entity => {
            if (entity.reference.toLowerCase().includes(searchValue.toLowerCase())) {
                listFromApi.push({ fieldName: "reference", fieldValue: entity.reference, displayName: getDisplayName("reference") })
            }

            Object.keys(entity.entityFields.textFields).forEach(key => {
                if (
                    entity.entityFields.textFields[key]?.toLowerCase()?.includes(searchValue.toLowerCase()) &&
                    !listFromApi.some(x => x.fieldName === key && x.fieldValue === entity.entityFields.textFields[key])
                ) {
                    listFromApi.push({ fieldName: key, fieldValue: entity.entityFields.textFields[key] ?? "", displayName: getDisplayName(key) })
                }
            })
        })
        listFromApi.sort((a, b) => a.displayName.localeCompare(b.displayName))
        return listFromApi
    }
    const autoCompleteList = searchValue.length > 2 ? setAutoCompleteList() : []

    const onSearchValueChanged = (value: string) => {
        if (value.length > 2) {
            getEntitiesAutocompleteRequest.execute(undefined, {
                pageSize: 50,
                searchAfterId: null,
                sortBy: {
                    fieldName: "reference",
                    dataPrimitive: DataPrimitiveTypeEnum.TEXT,
                    direction: "ASC",
                    searchAfter: null
                },
                filters: filters,
                quickSearchValue: value,
                entityTypeReference: entityConfig?.reference,
                fields: {
                    booleanFields: [],
                    dateFields: [],
                    numberFields: [],
                    textFields: fields
                },
                quickSearchFields: fields
            })
            setShowingOptions(true)
        } else {
            setShowingOptions(false)
        }
        setSearchValue(value)
    }

    const onApplyQuickSearchClicked = () => {
        if (searchValue.length === 0) return

        grid.setQuickSearchValue(searchValue)
    }

    const onClearFiltersClicked = () => {
        grid.setFilters({})
        grid.setQuickSearchValue("")
        setSearchValue("")
    }

    const onClearInputClicked = () => {
        setSearchValue("")
        grid.setQuickSearchValue("")
        setShowingOptions(false)
    }

    const onInputClick = () => setShowingOptions(true)

    const onOptionClicked = (option: AutoCompleteList) => {
        const existingTextFieldIsOneOfForField = grid.filters?.textFieldIsOneOf?.find(
            filter => filter.fieldName === option.fieldName && filter.notOneOf === false
        )

        grid.setQuickSearchValue("")

        grid.setFilters({
            ...grid.filters,
            textFieldIsOneOf: [
                ...(grid.filters?.textFieldIsOneOf?.filter(filter => filter.fieldName !== option.fieldName) ?? []),
                { fieldName: option.fieldName, values: [...(existingTextFieldIsOneOfForField?.values ?? []), option.fieldValue], notOneOf: false }
            ]
        })

        setSearchValue("")
        setShowingOptions(false)
    }

    const getMatchedOptionValue = (option: AutoCompleteList) => {
        const matchingIndex = option.fieldValue.toLowerCase().indexOf(searchValue.toLowerCase())
        const firstPart = option.fieldValue.substring(0, matchingIndex)
        const matchingPart = option.fieldValue.substring(matchingIndex, matchingIndex + searchValue.length)
        const lastPart = option.fieldValue.substring(matchingIndex + searchValue.length, option.fieldValue.length)

        return (
            <span>
                {firstPart}
                <strong>{matchingPart}</strong>
                {lastPart}
            </span>
        )
    }

    const matchedOptionElements = autoCompleteList.map((option, index) => (
        <div key={index} role="listitem" className="d-flex py-2 px-1 pointer align-items-center no-select" onClick={() => onOptionClicked(option)}>
            <span className={`${classes.label} px-2 text-capitalize`}>{`${option.displayName}`}</span>
            <span className="ms-1 d-block flex-grow-1 text-grey text-truncate" data-bs-toggle="tooltip" data-bs-placement="bottom" title={option.fieldValue}>
                {getMatchedOptionValue(option)}
            </span>
        </div>
    ))
    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            grid.setQuickSearchValue(searchValue)
            setShowingOptions(false)
        }
    }

    return (
        <div ref={ref} className={`d-flex ${classes.container} w-100 me-3`}>
            <div className="d-flex position-relative flex-column w-100">
                <Input
                    value={searchValue}
                    onClick={onInputClick}
                    onChange={onSearchValueChanged}
                    onKeyDown={onKeyDown}
                    placeholder="Search field..."
                    ariaLabel="quick-search"
                    rounding="left"
                />

                <div className="d-flex w-100">
                    {autoCompleteList.length > 0 && showingOptions && (
                        <div className={`position-absolute w-100 ${classes.options} border text-grey shadow bg-grey ${classes.scrollable}`}>
                            {padElementsWith(matchedOptionElements, "w-100 border-top")}
                        </div>
                    )}
                </div>
            </div>
            {searchValue.length > 0 && (
                <button
                    className={`text-nowrap d-flex fs-6 px-2 align-items-center align-middle ${classes.blueButton}`}
                    onClick={onClearInputClicked}
                    aria-label="clear-search-input"
                >
                    <i aria-label="remove" className={`mx-1 pointer fas fa-times-circle`} />
                </button>
            )}
            <button
                className={`text-nowrap d-flex fs-6 px-2 align-items-center ${classes.blueButton}`}
                onClick={onApplyQuickSearchClicked}
                aria-label="apply-quick-search"
            >
                Add quick search
            </button>
            <button
                className={`text-nowrap d-flex fs-6 px-2 align-items-center ${classes.blueButton}`}
                onClick={onClearFiltersClicked}
                aria-label="clear-filters"
            >
                Clear Filters
            </button>
        </div>
    )
}

export default AutoCompleteEntitySearch
