import { useState, useEffect } from 'react'
import { Loading, useApi, useClient, useConfig, useFeatureToggle } from 'invevo-react-components'
import PaymentSelection from './PaymentSelection'
import { useCustomer } from '../../../contexts/CustomerContext'
import PayOverdueAccountBalances from './PayOverdueAccountBalances'

const PayActionPanel = () => {
    const [selectedTransactionIds, setSelectedTransactionIds] = useState([])
    const [allTransactions, setAllTransactions] = useState([])
    const [selectedTransactions, setSelectedTransactions] = useState([])
    const [cardProviders, setCardProviders] = useState([])
    const [hasFetchedInvoices, setHasFetchedInvoices] = useState(false)
    const [isFetchingCardProviders, setIsFetchingCardProviders] = useState(false)

    const { isEnabled: isCanPayWithoutTransactionsEnabled } = useFeatureToggle("payWithoutSpecifyingTransactions")
    
    const client = useClient()
    const api = useApi()
    const config = useConfig()
    const [customer] = useCustomer()

    useEffect(() => {
        if (!allTransactions) {
            return
        }

        setSelectedTransactions(
            allTransactions
                .filter(t => selectedTransactionIds.includes(t.id))
                .filter(t => t.amountDue !== 0)
        )
    }, [allTransactions, selectedTransactionIds])

    useEffect(() => {
        if (!customer.sqlId || !customer.selectedTransactions || !config.PAY_API_URL) {
            return
        }

        const body = {
            customerId: customer.sqlId,
            transactionRefs: customer.selectedTransactions.map(t => t.transactionRef)
        }
        api.post(`${config.PAY_API_URL}/api/${client}/legacy-transaction-ids-from-transaction-refs`, body)
            .then((response) => {
                setSelectedTransactionIds(response.data)
            })
            .catch((error) => console.error(error))
    }, [api, client, config.PAY_API_URL, customer.sqlId, customer.selectedTransactions])

    useEffect(() => {
        if (hasFetchedInvoices || !customer.sqlId || !config.PAY_API_URL) {
            return
        }

        const isVirtualAccount = customer.isVirtualAccount ? customer.isVirtualAccount : false
        api.get(`${config.PAY_API_URL}/api/${client}/legacy-portal-transactions?customerId=${customer.sqlId}&isVirtualAccount=${isVirtualAccount}`).then(response => {
            setAllTransactions(mapTransactionsFromApi(response.data))
            setHasFetchedInvoices(true)
        }, error => { throw error })
    }, [hasFetchedInvoices, client, customer.sqlId, customer.isVirtualAccount, api, config.PAY_API_URL])

    useEffect(() => {
        if (isFetchingCardProviders || allTransactions.length === 0 || cardProviders.length > 0 || !config.PAY_API_URL) {
            return
        }

        const invoicesGroupedByCurrency = groupByKey(allTransactions, 'currencyCode')
        const currencyCodes = []
        for (const currencyCodeKey in invoicesGroupedByCurrency) {
            currencyCodes.push(currencyCodeKey)
        }

        setIsFetchingCardProviders(true)
        const getCardProviders = currencyCodes.map(cc => api.get(`${config.PAY_API_URL}/api/${client}/legacy-card-providers?customerId=${customer.sqlId}&currency=${cc}`))
        Promise.all(getCardProviders).then((responses) => {
            let cardProviders = [];
            responses.forEach(r => {
                const currencyCode = [...r.config.url.split('=')].pop()
                cardProviders = [...cardProviders, ...r.data.cardProviders.map(provider => ({
                    cardId: provider.cardId,
                    brand: provider.cardType,
                    paymentProviderId: provider.paymentProviderId,
                    currencyCode: currencyCode
                }))]
            })

            setCardProviders(cardProviders)
            setIsFetchingCardProviders(false)
        }, error => { throw error });
    }, [allTransactions, api, cardProviders.length, client, config.PAY_API_URL, customer.sqlId, isFetchingCardProviders])

    const mapTransactionsFromApi = (data) => {
        return data.rows.map(t => {
            return {
                id: t.transactionId,
                transactionRef: t.transactionReference,
                currencyCode: t.billingCurrencyCode,
                amountDue: t.currentBillingValue,
                transactionDate: t.transactionDate,
                invoiceReference: t.transactionRef
            }
        })
    }

    const groupByKey = (list, key) => list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {})

    return (
        <div className="d-flex flex-column w-100 h-100 bg-blue p-4">
            {selectedTransactions.length === 1 ?
                <h5 className="text-white">Pay {selectedTransactions[0].transactionRef} transaction</h5> : selectedTransactions.length > 1 ?
                    <h5 className="text-white">Pay {selectedTransactions.length} selected transactions</h5> : hasFetchedInvoices ?
                    isCanPayWithoutTransactionsEnabled ?
                        <Loading colour="white" isLoading={!hasFetchedInvoices || isFetchingCardProviders}>
                            <PayOverdueAccountBalances 
                                customerId={customer.sqlId} 
                                isCustomerVirtualAccount={customer.isCustomerVirtualAccount}
                                cardProviders={cardProviders}
                                overdue={customer.data.numberFields.overdue}
                                balance={customer.data.numberFields.balance}
                                currencyCode={customer.data.stringFields.currencycode}
                            />
                        </Loading> :
                        <h5 className="text-white">Please select a transaction with an amount due to pay</h5> :
                        <></>
            }
            {selectedTransactionIds.length > 0 &&
                <Loading colour="white" isLoading={!hasFetchedInvoices || isFetchingCardProviders}>
                    <PaymentSelection
                        customerId={customer.sqlId}
                        isCustomerVirtualAccount={customer.isVirtualAccount}
                        cardProviders={cardProviders}
                        transactions={selectedTransactions} />
                </Loading>
            }
        </div>
    )
}

export default PayActionPanel