import React, { Component } from 'react';
import { connect } from 'react-redux';

import Dropdown from '../../common/dropdown';
import { getLookup } from '../../../api/lookupApi';
import * as lodash from 'lodash';

class AddImportQueue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileImportQueue: {
        id: '',
        queueName: '',
        importFileType: '',
        importFileTypeId: -1,
        fileWriteThresholdItems: '',
        fileWriteThresholdSeconds: '',
        fileNameTemplate: '',
        isInUse: ''
      }
    };

    this.onChange = this.onChange.bind(this);
    this.onChecked = this.onChecked.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      fileImportQueue: Object.assign({}, nextProps.fileImportQueue)
    });
  }

  componentDidMount() {
    this.props.getLookup(this.props.client, 'import-configuration/file-types/lookup', 'fileTypes');
    this.props.getLookup(
      this.props.client,
      'import-configuration/queue/file-templates',
      'fileNameTemplates'
    );
    this.props.getLookup(this.props.client, 'import-configuration/queue/name', 'queueNames');
  }

  onChange(event) {
    const field = event.target.name;
    const fileImportQueue = Object.assign({}, this.state.fileImportQueue);

    fileImportQueue[field] = event.target.value;

    return this.setState({ fileImportQueue: fileImportQueue });
  }

  onSelectChange(name, value) {
    const fileImportQueue = Object.assign({}, this.state.fileImportQueue);

    fileImportQueue[name] = value;

    return this.setState({ fileImportQueue: fileImportQueue });
  }

  onChecked(event) {
    const field = event.target.name;
    const fileImportQueue = Object.assign({}, this.state.fileImportQueue);

    fileImportQueue[field] = event.target.checked;

    return this.setState({ fileImportQueue: fileImportQueue });
  }

  onSave() {
    this.props.onSave(this.state.fileImportQueue);
  }

  isFormValid() {
    const {
      queueName,
      importFileTypeId,
      fileWriteThresholdItems,
      fileWriteThresholdSeconds,
      fileNameTemplate
    } = this.state.fileImportQueue;
    const existingFileTemplates = this.props.fileNameTemplates.results;
    const existingQueueNames = this.props.queueNames.results;

    if (queueName.length === 0) {
      return false;
    }

    if (importFileTypeId && importFileTypeId.length === 0) {
      return false;
    }

    if (
      Number(fileWriteThresholdItems) < 100 ||
      Number(fileWriteThresholdItems) > 50000
    ) {
      return false;
    }

    if (
      Number(fileWriteThresholdSeconds) < 10 ||
      Number(fileWriteThresholdSeconds) > 120
    ) {
      return false;
    }

    if (fileNameTemplate.length === 0 || !fileNameTemplate.includes('#')) {
      return false;
    }

    if (this.props.fileImportQueue.fileNameTemplate.length > 0) {
      if (
        fileNameTemplate.toLowerCase() !==
        this.props.fileImportQueue.fileNameTemplate.toLowerCase() &&
        existingFileTemplates.includes(fileNameTemplate.toLowerCase())
      ) {
        return false;
      }
    } else if (existingFileTemplates.includes(fileNameTemplate.toLowerCase())) {
      return false;
    }

    if (this.props.fileImportQueue.queueName.length > 0) {
      if (
        queueName.toLowerCase() !==
        this.props.fileImportQueue.queueName.toLowerCase() &&
        existingQueueNames.includes(queueName.toLowerCase())
      ) {
        return false;
      }
    } else if (existingQueueNames.includes(queueName.toLowerCase())) {
      return false;
    }

    return true;
  }

  render() {
    const {
      id,
      queueName,
      importFileTypeId,
      fileWriteThresholdItems,
      fileWriteThresholdSeconds,
      fileNameTemplate,
      isInUse
    } = this.state.fileImportQueue;
    const header = id ? `Edit File Queue (Id:${id})` : 'Add File Queue';

    const isValid = this.isFormValid();

    return (
      <div>
        <div key="import-queue-form" className="card below-grid-form">
          <h3>{header}</h3>

          <div className="form-group" style={{ width: '480px' }}>
            <div className="form-control-feedback-wrapper warning">
              <span className="form-control-feedback">
                <i className="fa fa-asterisk" />
              </span>
            </div>
            <input
              name="queueName"
              type="text"
              placeholder="Queue Name (must be unique)"
              className="form-control"
              value={queueName}
              onChange={this.onChange}
              title="Queue Name (must be unique)"
            />
          </div>

          <div className="form-group" style={{ width: '480px' }}>
            <Dropdown
              required
              scroll={true}
              title="File Type"
              idProp="id"
              selectedItemId={importFileTypeId}
              textProp="name"
              data={this.props.fileTypes.results}
              name="importFileTypeId"
              onClick={(id) => this.onSelectChange('importFileTypeId', id)}
            />
          </div>
          <div className="form-group" style={{ width: '480px' }}>
            <div className="form-control-feedback-wrapper warning">
              <span className="form-control-feedback">
                <i className="fa fa-asterisk" />
              </span>
            </div>
            <input
              name="fileWriteThresholdItems"
              min="100"
              max="50000"
              type="number"
              className="form-control"
              placeholder="File Write Threshold Items (100-50000)"
              value={fileWriteThresholdItems.toString()}
              onChange={this.onChange}
              title="File Write Threshold Items (100-50000)"
            />
          </div>
          <div className="form-group" style={{ width: '480px' }}>
            <div className="form-control-feedback-wrapper warning">
              <span className="form-control-feedback">
                <i className="fa fa-asterisk" />
              </span>
            </div>
            <input
              name="fileWriteThresholdSeconds"
              min="10"
              max="120"
              type="number"
              className="form-control"
              placeholder="File Write Threshold Seconds (10-120)"
              value={fileWriteThresholdSeconds.toString()}
              onChange={this.onChange}
              title="File Write Threshold Seconds (10-120)"
            />
          </div>
          <div className="form-group" style={{ width: '480px' }}>
            <div className="form-control-feedback-wrapper warning">
              <span className="form-control-feedback">
                <i className="fa fa-asterisk" />
              </span>
            </div>
            <input
              name="fileNameTemplate"
              type="text"
              placeholder="File Name Template (must be unique and contain '#' character)"
              className="form-control"
              value={fileNameTemplate}
              onChange={this.onChange}
              title="File Name Template (must be unique and contain '#' character)"
            />
          </div>
          <div className="form-group">
            <div className="checkbox">
              <label>
                <input
                  name="isInUse"
                  type="checkbox"
                  checked={isInUse}
                  onChange={this.onChecked}
                />{' '}
                Is In Use
              </label>
            </div>
          </div>
          <div className="form-group">
            <button
              className="btnDefault btn btn-default"
              disabled={!isValid}
              onClick={this.onSave}
            >
              Save
            </button>
            <button className="btn btn-default" onClick={this.props.onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let fileImportQueue = {
    id: '',
    queueName: '',
    importFileType: '',
    fileWriteThresholdItems: '',
    fileWriteThresholdSeconds: '',
    fileNameTemplate: '',
    isInUse: ''
  };

  if (state.fileImportQueues.length > 0) {
    fileImportQueue = lodash.last(state.fileImportQueues);
  }

  return {
    fileImportQueue: fileImportQueue,
    fileTypes: state.lookupReducer.fileTypes,
    fileNameTemplates: state.lookupReducer.fileNameTemplates,
    queueNames: state.lookupReducer.queueNames
  };
}

export default connect(mapStateToProps, { getLookup })(AddImportQueue);
