import { IconButton } from "invevo-react-components"
import ListCard from "../../../../library/listCards/ListCard"
import Role from "../types/Role"
import classes from "./RoleList.module.scss"

type RoleCardProps = {
    role: Role
    isSelected: boolean
    isNewRole: boolean
    onRoleSelected: (reference: string) => void
    onRoleRemoved: (reference: string) => void
}

const RoleCard = ({ role, isSelected, isNewRole, onRoleSelected, onRoleRemoved }: RoleCardProps) => {
    const selectRole = () => onRoleSelected(role.reference)

    const removeRoll = () => onRoleRemoved(role.reference)

    return (
        <ListCard className="mb-3" isSelected={isSelected} onSelect={selectRole} ariaLabel={role.name}>
            <div className="d-flex justify-content-between">
                <h3 className="text-white font-weight-bold m-2">
                    <i className="me-1 fa-light fa-id-card-alt me-2"></i>
                    {role.name}
                </h3>
                {isNewRole && (
                    <IconButton iconClasses="fal fa-trash-can" onClick={removeRoll} className={classes.smallIconButton} size="small" ariaLabel="remove-role" />
                )}
            </div>
            <div className={`${classes.separator} d-flex flex-row my-2 bg-grey`} />
            <div className="d-flex flex-row m-2">
                <div className="ms-auto">
                    <h5 className="mb-1 text-white">{role.roleType}</h5>
                </div>
            </div>
        </ListCard>
    )
}

export default RoleCard
