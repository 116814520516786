import { IconButton, useClient, useConfig } from "invevo-react-components"
import useApiQuery from "../../../../../hooks/useApiQuery"
import Loading from "../../../../../library/Loading/Loading"
import classes from "./EntityGroupPanel.module.scss"
import { EntityGroupDto } from "./types/EntityGroupDto"
import EntityGroupHierarchy from "./EntityGroupHierarchy"
import { useEntity } from "../../../../../contexts/EntityContext"

type EntityGroupPanelProps = {
    onClose: () => void
}

const EntityGroupPanel = ({ onClose }: EntityGroupPanelProps) => {
    const config = useConfig()
    const client = useClient()
    const [entity] = useEntity()

    const entityGroupsRequest = useApiQuery<EntityGroupDto>({
        url: `${config.SEARCH_API_URL}/api/${client}/groups-for-entity/${entity.reference}?entityType=${entity.entityType}`,
        method: "GET"
    })

    return (
        <div className={`d-flex flex-column p-4 bg-grey ${classes.container} overflow-auto`}>
            <div className="d-flex justify-content-between mb-4">
                <span className="fs-3 fw-lighter text-grey me-4 text-capitalize">Groups</span>
                <IconButton iconClasses="fal fa-close" onClick={onClose} />
            </div>
            <Loading isLoading={entityGroupsRequest.data === undefined || entityGroupsRequest.isFetching} colour="blue">
                {entityGroupsRequest.data && <EntityGroupHierarchy entityGroup={entityGroupsRequest.data} selectedEntityReference={entity.reference} />}
            </Loading>
        </div>
    )
}

export default EntityGroupPanel
