import { useTranslation } from "../../../../contexts/TranslationContext"
import Dropdown from "./Dropdown"
import classes from "./LanguageDropdown.module.scss"

type LanguageDropdownProps = {
    className?: string
}

const LanguageDropdown = ({ className }: LanguageDropdownProps) => {
    const languages = [
        { id: 1, value: "en", label: "English" },
        { id: 2, value: "fr", label: "Français" },
        { id: 3, value: "de", label: "Deutsch" },
        { id: 4, value: "es", label: "Español" },
        { id: 5, value: "nl", label: "Nederlands" },
        { id: 6, value: "it", label: "Italiano" },
        { id: 7, value: "pt", label: "Português" },
        { id: 8, value: "sv", label: "Svenska" },
        { id: 9, value: "ja", label: "日本" },
        { id: 10, value: "zh-CN", label: "中国人" }
    ]

    const [translation, setTranslation] = useTranslation()

    const onLanguageOptionSelected = (language: any) => {
        setTranslation({ ...translation, preferredLanguageCode: language.value })
    }

    return (
        <div className={`d-flex align-items-center ${className ? className : ""} ${classes.dropdown}`}>
            <i className="text-grey fal fa-language me-2 fs-3"></i>
            <Dropdown
                placeholder="Change translation"
                option={languages.find(l => l.value === translation.preferredLanguageCode)}
                options={languages}
                onOptionChange={onLanguageOptionSelected}
            />
        </div>
    )
}

export default LanguageDropdown
