import { Route, Switch } from 'react-router-dom'
import ConnectNav from './ConnectNav'
import SmsConfig from './sms/smsModule/SmsConfig'
import VoipConfig from './voip/config/VoipConfig'

const ConnectRoutes = () => {
    return (
        <div className="d-flex w-100 h-100">
            <Switch>
                <Route path="/home/connect" exact render={() => <ConnectNav />} />
                <Route path="/home/connect/sms" render={() => (<SmsConfig />)} />
                <Route path="/home/connect/voip" render={() => (<VoipConfig />)} />
            </Switch>
        </div>
    )
}

export default ConnectRoutes