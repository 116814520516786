import FeatureToggled from '../../../featureToggled/FeatureToggled';
import SetCrmRoles from './SetCrmRoles';
import classes from './SetCrmRoles.module.css';
import { useFlags } from 'launchdarkly-react-client-sdk';

const CrmRoles = ({
    automateWorkflowAdminRoleSet,
    connectSmsSenderRoleSet,
    connectSmsTemplateWriterRoleSet,
    connectSmsTemplateReaderRoleSet,
    connectConfigWriterRoleSet,
    connectConfigReaderRoleSet,
    connectSmsSenderIdWriterRoleSet,
    connectSmsSenderIdReaderRoleSet,
    customerRelationshipGroupWriterRoleSet,
    customerRelationshipGroupReaderRoleSet,
    payUserPaymentRoleSet,
    orderWriterRoleSet,
    orderReaderRoleSet,
    dataImportConfigWriterRoleSet,
    dataImportConfigReaderRoleSet,
    isActiveRoleSet,
    roleReaderRoleSet,
    roleWriterRoleSet,
    clientUserReaderRoleSet,
    clientUserWriterRoleSet,

    selectAutomateWorkflowAdminRoleHandler,
    selectConnectSmsSenderRoleHandler,
    selectConnectSmsTemplateWriterRoleHandler,
    selectConnectSmsTemplateReaderRoleHandler,
    selectConnectConfigWriterRoleHandler,
    selectConnectConfigReaderRoleHandler,
    selectConnectSmsSenderIdWriterRoleHandler,
    selectConnectSmsSenderIdReaderRoleHandler,
    selectCustomerRelationshipGroupWriterRoleHandler,
    selectCustomerRelationshipGroupReaderRoleHandler,
    selectPayUserPaymentRoleHandler,
    selectOrderWriterRoleHandler,
    selectOrderReaderRoleHandler,
    selectDataImportConfigWriterRoleHandler,
    selectDataImportConfigReaderRoleHandler,
    selectIsActiveRoleHandler,
    selectRoleReaderRoleHandler,
    selectRoleWriterRoleHandler,
    selectClientUserReaderRoleHandler,
    selectClientUserWriterRoleHandler
}) => {
    const { automateModule,
        invevoSms,
        connectModule,
        collectModule,
        payInvevoUserCanMakePayments,
        orderModule,
        platformDataImportConfig,
        isActivePermission,
        platformUserRoleManagement } = useFlags();

    return (
        (automateModule || invevoSms || connectModule || collectModule || payInvevoUserCanMakePayments || orderModule || platformDataImportConfig || isActivePermission || platformUserRoleManagement) &&
        <div className={`d-flex flex-column my-3 ${classes['no-select']}`}>
            <h4 className="my-3">CRM Roles</h4>
            <FeatureToggled flagKey="isActivePermission">
                <div className="my-2">
                    <SetCrmRoles
                        roleSet={isActiveRoleSet}
                        selectRoleHandler={selectIsActiveRoleHandler}
                        labelName="Is Active"
                    />
                </div>
            </FeatureToggled>
            <FeatureToggled flagKey="automateModule">
                <div className="my-2">
                    <span>Automate module</span>
                    <SetCrmRoles
                        roleSet={automateWorkflowAdminRoleSet}
                        selectRoleHandler={selectAutomateWorkflowAdminRoleHandler}
                        labelName="Automate Workflow Admin"
                    />
                </div>
            </FeatureToggled>

            <FeatureToggled flagKey="invevoSms">
                <div className="my-2">
                    <span>Sms sending module</span>
                    <SetCrmRoles
                        roleSet={connectSmsSenderRoleSet}
                        selectRoleHandler={selectConnectSmsSenderRoleHandler}
                        labelName="Connect Sms Sender"
                    />
                </div>
            </FeatureToggled>

            <FeatureToggled flagKey="connectModule">
                <div className="my-2">
                    <span>Connect module</span>
                    <SetCrmRoles
                        roleSet={connectSmsTemplateWriterRoleSet}
                        selectRoleHandler={selectConnectSmsTemplateWriterRoleHandler}
                        labelName="Connect Template Writer"
                    />
                    <SetCrmRoles
                        roleSet={connectSmsTemplateReaderRoleSet}
                        selectRoleHandler={selectConnectSmsTemplateReaderRoleHandler}
                        labelName="Connect Template Reader"
                    />
                    <SetCrmRoles
                        roleSet={connectConfigWriterRoleSet}
                        selectRoleHandler={selectConnectConfigWriterRoleHandler}
                        labelName="Connect Configuration Writer"
                    />
                    <SetCrmRoles
                        roleSet={connectConfigReaderRoleSet}
                        selectRoleHandler={selectConnectConfigReaderRoleHandler}
                        labelName="Connect Configuration Reader"
                    />
                    <SetCrmRoles
                        roleSet={connectSmsSenderIdWriterRoleSet}
                        selectRoleHandler={selectConnectSmsSenderIdWriterRoleHandler}
                        labelName="Connect Sms SenderId Writer"
                    />
                    <SetCrmRoles
                        roleSet={connectSmsSenderIdReaderRoleSet}
                        selectRoleHandler={selectConnectSmsSenderIdReaderRoleHandler}
                        labelName="Connect Sms SenderId Reader"
                    />
                </div>
            </FeatureToggled>

            <FeatureToggled flagKey="collectModule">
                <div className="my-2">
                    <span>Collect module</span>
                    <SetCrmRoles
                        roleSet={customerRelationshipGroupWriterRoleSet}
                        selectRoleHandler={selectCustomerRelationshipGroupWriterRoleHandler}
                        labelName="Customer Relationship Groups Writer"
                    />
                    <SetCrmRoles
                        roleSet={customerRelationshipGroupReaderRoleSet}
                        selectRoleHandler={selectCustomerRelationshipGroupReaderRoleHandler}
                        labelName="Customer Relationship Groups Reader"
                    />
                </div>
            </FeatureToggled>

            <FeatureToggled flagKey="payInvevoUserCanMakePayments">
                <div className="my-2">
                    <span>Pay module</span>
                    <SetCrmRoles
                        roleSet={payUserPaymentRoleSet}
                        selectRoleHandler={selectPayUserPaymentRoleHandler}
                        labelName="User payment"
                    />
                </div>
            </FeatureToggled>

            <FeatureToggled flagKey="orderModule">
                <div className="my-2">
                    <span>Order module</span>
                    <SetCrmRoles
                        roleSet={orderReaderRoleSet}
                        selectRoleHandler={selectOrderReaderRoleHandler}
                        labelName="Order Reader"
                    />
                    <SetCrmRoles
                        roleSet={orderWriterRoleSet}
                        selectRoleHandler={selectOrderWriterRoleHandler}
                        labelName="Order Writer"
                    />
                </div>
            </FeatureToggled>

            <div className="my-2">
                <span>Platform module</span>
                <FeatureToggled flagKey="platformDataImportConfig">
                    <SetCrmRoles
                        roleSet={dataImportConfigReaderRoleSet}
                        selectRoleHandler={selectDataImportConfigReaderRoleHandler}
                        labelName="Data Configuration Reader"
                    />
                    <SetCrmRoles
                        roleSet={dataImportConfigWriterRoleSet}
                        selectRoleHandler={selectDataImportConfigWriterRoleHandler}
                        labelName="Data Configuration Writer"
                    />
                </FeatureToggled>
                <FeatureToggled flagKey="platformUserRoleManagement">

                    <SetCrmRoles
                        roleSet={roleReaderRoleSet}
                        selectRoleHandler={selectRoleReaderRoleHandler}
                        labelName="Role Reader"
                    />
                    <SetCrmRoles
                        roleSet={roleWriterRoleSet}
                        selectRoleHandler={selectRoleWriterRoleHandler}
                        labelName="Role Writer"
                    />
                    <SetCrmRoles
                        roleSet={clientUserReaderRoleSet}
                        selectRoleHandler={selectClientUserReaderRoleHandler}
                        labelName="Client User Reader"
                    />
                    <SetCrmRoles
                        roleSet={clientUserWriterRoleSet}
                        selectRoleHandler={selectClientUserWriterRoleHandler}
                        labelName="Client User Writer"
                    />
                </FeatureToggled>
            </div>
        </div>
    )
}

export default CrmRoles