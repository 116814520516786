import update from 'immutability-helper';

const getInitialState = () => {
    return {
        smsTemplateId: null,
        smsTemplates: { isFetching: false, isFetched: false, error: null, result: [] },
        smsUsageTypes: { isFetching: false, isFetched: false, error: null, result: [] },
        canSave: false,
        formInputsCanSave: false,
        smsEditTemplate: { smsTemplateUsageTypeId: null, smsTemplateContent: '', smsTemplateName: '', smsBrandInternalId: null, showForm: false, showAddContents: false },
        customerBrands: { isFetching: false, isFetched: false, error: null, result: [] },
        savedSMSTemplate: false,
        saveSMSTemplateError: ''
    };
}

const smsTemplateReducer = (state = getInitialState(), action) => {
    let nState;

    switch (action.type) {
        case 'RECEIVE_SMS_TEMPLATE_LIST':
            nState = update(state, { smsTemplates: { result: { $set: action.data }, isFetching: { $set: false }, isFetched: { $set: true } } });

            return nState;
        case 'GET_SMS_TEMPLATE_LIST_ERROR':
            nState = update(state, { smsTemplates: { isFetching: { $set: false }, isFetched: { $set: true }, error: { $set: action.error } } });

            return nState;
        case 'RECEIVE_SMS_TEMPLATE_USAGE_TYPE_LIST':
            nState = update(state, { smsUsageTypes: { result: { $set: action.data }, isFetching: { $set: false }, isFetched: { $set: true } } });

            return nState;
        case 'GET_SMS_TEMPLATE_USAGE_TYPE_LIST_ERROR':
            nState = update(state, { smsUsageTypes: { isFetching: { $set: false }, isFetched: { $set: true }, error: { $set: action.error } } });

            return nState;
        case 'CHANGE_SMS_TEMPLATE':
            nState = update(state, { smsTemplateId: { $set: action.smsTemplateId } });

            return nState;
        case 'RECEIVE_SMS_TEMPLATE':
            nState = update(state, {
                smsTemplateId: { $set: action.data.id },
                smsEditTemplate: {
                    smsTemplateUsageTypeId: { $set: action.data.templateUsageTypeId },
                    smsTemplateName: { $set: action.data.name },
                    smsBrandInternalId: { $set: action.data.brandInternalId },
                    templateContents: { $set: action.data.templateContents },
                    showForm: { $set: true },
                    showAddContents: { $set: false }
                },
                canSave: { $set: true }
            });

            return nState;
        case 'EDIT_SMS_TEMPLATE':
            let canSave = false;

            if (action.smsTemplate.smsTemplateUsageTypeId != null &&
                action.smsTemplate.templateContents != null && action.smsTemplate.templateContents.length > 0) {
                canSave = true;
            }

            nState = update(state, {
                smsEditTemplate: {
                    smsTemplateUsageTypeId: { $set: action.smsTemplate.smsTemplateUsageTypeId },
                    smsTemplateName: { $set: action.smsTemplate.smsTemplateName },
                    smsBrandInternalId: { $set: action.smsTemplate.smsBrandInternalId },
                    templateContents: { $set: action.smsTemplate.templateContents },
                    showForm: { $set: action.smsTemplate.showForm },
                    showAddContents: { $set: action.smsTemplate.showAddContents }
                },
                canSave: { $set: canSave }
            });

            return nState;
        case 'RECIEVE_CUSTOMER_BRANDS':
            nState = update(state, { customerBrands: { result: { $set: action.data }, isFetching: { $set: false }, isFetched: { $set: true } } });

            return nState;
        case 'GET_CUSTOMER_BRANDS_ERROR':
            nState = update(state, { customerBrands: { isFetching: { $set: false }, isFetched: { $set: true }, error: { $set: action.error } } });

            return nState;
        case 'CHANGE_FORM_INPUTS_CAN_SAVE':
            nState = update(state, { formInputsCanSave: { $set: action.formInputsCanSave } });

            return nState;
        case 'SAVED_SMS_TEMPLATE':
            nState = update(state, { savedSMSTemplate: { $set: action.savedSMSTemplate } });

            return nState;
        case 'SAVED_SMS_TEMPLATE_ERROR':
            nState = update(state, { saveSMSTemplateError: { $set: action.err } });

            return nState;
        case 'RESET_STATE':
            nState = getInitialState();

            return nState;
        default:
            return state;
    }
};

export default smsTemplateReducer;