import React from 'react';

const PostSuccess = () => {
  return (
    <div className="success">
      <div className="speech-bubble">
        <div className="mia-logo"></div>
        <p>Mia</p>
      </div>
      <h2>Success!</h2>
    </div>
  );
};

export default PostSuccess;
