export const DnD = {
  MENU_ITEM: 'menu_item',
  LIST_ITEM: 'list_item',
  PANEL_ITEM: 'panel_item'
};

export const DashboardData = {
  DIARY_PANEL_CONTENT: 'userdashboard/GetDiaryPanelData',
  MYACCOUNTS_PANEL_CONTENT: 'userdashboard/GetMyAccountPanelData',
  TRANSACTION_SUBSTATUS_PANEL_CONTENT:
    'userdashboard/GetTransactionSubStatusPanelData',
  USER_TASK: 'userdashboard/tasks',
  AWAITING_CREDIT_LIMIT_DECISION: 'userdashboard/AwaitingCreditLimitDecisions',
  NEW_CREDIT_LIMIT_DECISIONS: 'userdashboard/GetNewCreditLimitDecisions',
  USER_FOLLOWUP: 'userdashboard/followups',
  UNREAD_EMAIL: 'userdashboard/unreademail',
  CUSTOMERAGEDDEBT_PANEL_CONTENT: 'panelcontent/GetAgedDebtPanelData',
  CUSTOMER_APPROVAL_CRITERIA_PANEL_CONTENT:
    'panelcontent/GetApprovalCriteriaPanelData',
  CUSTOMER_CONSUMER_INFORMATION_PANEL_CONTENT:
    'panelcontent/GetConsumerInformationPanelData',
  CUSTOMERDETAILS_PANEL_CONTENT: 'panelcontent/GetCustomerDetailsPanelData',
  CUSTOMER_COMMUNICATION_SETTINGS_PANEL_CONTENT:
    'panelcontent/GetCommunicationSettingsPanelData',
  CUSTOMER_FINANCIAL_PANEL_CONTENT: 'panelcontent/GetFinancialPanelData',
  CUSTOMER_SEGMENTATION_PANEL_CONTENT: 'panelcontent/GetSegmentationPanelData',
  CUSTOMER_TRANSACTIONSTATUS_PANEL_CONTENT: 'panelcontent/TransactionStatus',
  CUSTOMER_TRANSACTIONSUBSTATUS_PANEL_CONTENT:
    'panelcontent/GetTransactionSubStatusPanelData',
  CUSTOMER_TRANSACTION_TYPE_PANEL_CONTENT:
    'panelcontent/GetTransactionTypePanelData',
  CUSTOMER_TASK: 'task/customerpanel',
  CUSTOMER_FOLLOWUP: 'followup/customerpanel',
  CUSTOMER_FALLING_DUE_BY_MONTH_PANEL_CONTENT:
    'panelcontent/GetFallingDueByMonthPanelData',
  CUSTOMER_RECENT_PAYMENTS_PANEL_CONTENT:
    'panelcontent/GetRecentPaymentsPanelData'
};

export const DashboardType = {
  MAIN: 0,
  CUSTOMER: 1
};

export const SourceType = {
  NONE: -1,
  GRID: 0,
  PIN_NOTE: 1,
  TRAN_NOTES: 3,
  CONTACTS: 4,
  EMAIL_FORM: 5,
  CUSTOMER_DOCUMENTS: 6,
  CREDIT_CARD: 7,
  CUSTOMER_DASHBOARD: 8,
  TASK: 9,
  VP: 10,
  SMS: 11,
  REPAYMENT_PLAN: 12,
  VPEDIT: 14,
  TED: 15,
  ACCOUNT_STATUS: 16,
  CREDIT_LIMIT: 17,
  POST_FORM: 18,
  CUSTOMER_EDIT_FORM: 19,
  SUMMARY: 20
};

export const PanelTypeId = {
  DIARY_PANEL: 6,
  MY_ACCOUNTS_PANEL: 11,
  USER_TASK: 20,
  AWAITING_CREDIT_LIMIT_DECISION: 21,
  NEW_CREDIT_LIMIT_DECISIONS: 22,
  USER_FOLLOWUP: 23,
  UNREAD_EMAIL: 24,
  CUSTOMER_DETAILS_PANEL: 101,
  CUSTOMER_AGED_DEBT_PANEL: 102,
  CUSTOMER_TRANSACTION_STATUS_PANEL: 103,
  CUSTOMER_TRANSACTION_TYPE_PANEL: 104,
  CUSTOMER_TRANSACTION_SUBSTATUS_PANEL: 105,
  CUSTOMER_TASK: 106,
  CUSTOMER_FINANCIAL: 107,
  CUSTOMER_APPROVAL_CRITERIA: 108,
  CUSTOMER_COMMUNICATION_SETTINGS: 109,
  CUSTOMER_SEGMENTATION: 110,
  CUSTOMER_CONSUMER_INFORMATION: 111,
  CUSTOMER_FOLLOWUP: 112,
  CUSTOMER_FALLING_DUE_BY_MONTH_PANEL: 113,
  CUSTOMER_RECENT_PAYMENTS_PANEL: 114
};

//This object must be kept in sync with the corresponding enum in server code
export const subItemsConstants = {
  NEW_CREDIT_LIMIT_DECISIONS: {
    APPROVED: 750,
    DECLINED: 751,
    BOTH: 752
  }
};
