import { useState } from "react"
import EntityDataFieldDropdown from "../../../../../../library/dropdowns/EntityDataFieldDropdown"
import EntityDataField from "../../../../../../routes/entityConfig/types/EntityDataField"
import { DashboardConfigAction, DashboardConfigEntityConfigFields } from "../../../../reducers/dashboardConfigsReducer"
import { EntityChartConfigWidgetState, EntityChartWidgetMultiFieldState } from "../../../../types/DashboardConfigState"
import { actionTypes } from "../../../../reducers/actionTypes"
import { v4 as uuidv4 } from "uuid"
import classes from "./EntityChartWidget.module.scss"

const EntityChartWidgetMultiField = ({
    widget,
    aggregationFields,
    fields,
    dispatch
}: {
    widget: EntityChartConfigWidgetState
    aggregationFields: EntityChartWidgetMultiFieldState[]
    fields: DashboardConfigEntityConfigFields[] & EntityDataField[]
    dispatch: React.Dispatch<DashboardConfigAction>
}) => {
    const [isAddingNewField, setIsAddingNewField] = useState(false)

    const onMultiFieldNameChange = (option: EntityDataField | undefined, previousField: EntityChartWidgetMultiFieldState) => {
        if (!option) {
            return
        }

        dispatch({ type: actionTypes.ENTITY_CHART_WIDGET_NEW_MULTI_FIELD_CHANGED, widget, newField: option.fieldName, previousField: previousField })
    }
    const onAddNewFieldClick = () => {
        setIsAddingNewField(true)
    }
    const onNewFieldNameChange = (option: EntityDataField | undefined) => {
        if (!option) {
            return
        }

        setIsAddingNewField(false)
        dispatch({ type: actionTypes.ENTITY_CHART_WIDGET_NEW_MULTI_FIELD_ADDED, widget, newField: { field: option.fieldName, id: uuidv4() } })
    }
    const onRemoveClick = (id: string) => {
        dispatch({ type: actionTypes.ENTITY_CHART_WIDGET_NEW_MULTI_FIELD_REMOVED, widget, id })
    }

    return (
        <div className="d-flex flex-column mt-3 px-2">
            <div className="d-flex flex-column">
                {aggregationFields.map(field => (
                    <div key={field.id} className="d-flex">
                        <EntityDataFieldDropdown
                            ariaLabel="multiFieldDropdown"
                            selectedOption={fields.find(f => f.fieldName === field.field)}
                            className="flex-grow-1 mb-2"
                            options={fields.filter(field => field.dataPrimitiveType === "NUMBER")}
                            onOptionSelected={option => onMultiFieldNameChange(option, field)}
                        />
                        <i
                            className={`ps-2 fas fa-times-circle pointer ${classes["remove-field-icon"]}`}
                            role="button"
                            aria-label="removeMultiField"
                            onClick={() => onRemoveClick(field.id)}
                        ></i>
                    </div>
                ))}

                <div className="d-flex align-items-center">
                    <span className={`text-uppercase small mb-1 ${classes.label}`}>Add new field</span>
                    <i className={`ms-auto fas fa-plus-circle pointer text-blue`} aria-label="addNewMultiField" onClick={onAddNewFieldClick}></i>
                </div>
                {isAddingNewField && (
                    <EntityDataFieldDropdown
                        ariaLabel="multiFieldDropdown"
                        selectedOption={undefined}
                        className="flex-grow-1"
                        options={fields.filter(field => field.dataPrimitiveType === "NUMBER")}
                        onOptionSelected={onNewFieldNameChange}
                    />
                )}
            </div>
        </div>
    )
}

export default EntityChartWidgetMultiField
