import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// import blockReducer from './reducers/block_reducer';
// import forgotDetailsReducer from './reducers/forgot_details_reducer';
// import userProfileReducer from './reducers/user_profile_reducer';
// import firstTimeLoginReducer from './reducers/first_time_login_reducer';
import lookupReducer from './lookup_reducer';
import dashboardReducer from './dashboard_reducer';
import { authReducer } from './auth_reducer';
import uiConfigReducer from './uiconfig_reducer';
import functionReducer from './function_reducer';
import { asyncReducer } from '../utility/asyncSupport';
// import panelReducer from './reducers/panel_reducer';
import currentSelectionReducer from './current_selection_reducer';
import vpReducer from './vp_reducer';
import smsTemplateReducer from './sms_template_reducer';
// import smsSendTemplateReducer from './reducers/sms_send_template_reducer';
import tedReducer from './ted_reducer';
import mappingReducer from './mapping_reducer';
import accountStatusReducer from './accountStatusReducer';
// import customerAcquisitionReducer from './reducers/customerAcquisition_reducer';
// import addressReducer from './reducers/address_reducer';
// import customerReducer from './reducers/customer_reducer';
import reportReducer from './report_reducer';
import fileImportDocumentType from './file_import_document_type_reducer';
import fileImportLocations from './file_import_location_reducer';
import fileImportQueues from './file_import_queue_reducer';
import fileTypes from './file_type_reducer';
import fileTypeTables from './file_type_table_reducer';
import fileImportGridDisplay from './file_import_grid_display_reducer';
import ruleGroupAdminReducer from './rule_group_admin_reducer';
// import customerDocumentReducer from './reducers/customerDocument_reducer';
import fieldMaps from './field_map_reducer';
import refresh from './refresh_reducer';
import refreshProcesses from './refresh_process_reducer';
import configurations from './configuration_reducer';
import globalConfiguration from './global_configuration_reducer';

import taskReducer from './taskReducer';
import documentReducer from './documentReducer';
import searchReducer from './search_reducer';
import gridCacheReducer from './gridCacheReducer';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    authReducer,
    // forgotDetails: forgotDetailsReducer,
    // userProfileReducer,
    // firstTimeLogin: firstTimeLoginReducer,
    lookupReducer,
    dashboardReducer,
    vpReducer,
    functionReducer,
    uiConfigReducer,
    asyncReducer,
    // panelReducer,
    currentSelectionReducer,
    smsTemplateReducer,
    // smsSendTemplateReducer,
    tedReducer,
    mappingReducer,
    accountStatusReducer,
    // customerAcquisitionReducer,
    // addressReducer,
    // blockReducer,
    // customerReducer,
    reportReducer,
    fileImportLocations,
    fileImportDocumentType,
    fileImportQueues,
    fileTypes,
    fileTypeTables,
    fileImportGridDisplay,
    ruleGroupAdminReducer,
    // customerDocumentReducer,
    fieldMaps,
    refresh,
    refreshProcesses,
    configurations,
    globalConfiguration,
    taskReducer,
    documentReducer,
    searchReducer,
    gridCacheReducer
  });

export default reducers;
