import { ChangeEvent, useEffect, useMemo, useReducer, useState } from "react"
import { useClient, useConfig, Loading } from "invevo-react-components"
import { useLayout, expandablePanels } from "../../../contexts/LayoutContext"
import CustomerGrid from "./CustomerGrid"
import classes from "./CustomerSearch.module.scss"
import CustomerSearchList from "./CustomerSearchList"
import FiltersDto, { convertFromArrayToDto, convertFromDtoToArray } from "../../../library/FilterList/FiltersDto"
import useApiQuery from "../../../hooks/useApiQuery"
import FilterList from "../../../library/FilterList/FilterList"
import CustomerQuickFilters from "./CustomerQuickFilters"
import DataPrimitive from "../../../types/DataPrimitive"
import Lookup from "../../../types/Lookup"
import DataField from "../../../types/DataField"
import FieldType from "../../../types/enums/FieldType"
import { DataFieldDto } from "../../../types/dtos/DataFieldDto"
import GenericFilter from "../../../library/FilterList/filterTypes/GenericFilter"
import SortDirection from "../../../types/enums/SortDirection"
import CustomerSearchReducer, { CustomerSearchActionEnum, CustomerSearchState } from "../reducers/CustomerSearchReducer"
import QuickFilter from "../../automate/types/QuickFilter"
import DataRow from "../../../library/dataGrid/types/DataRow"
import { CustomerResultsDto, convertCustomerToDataRow } from "../types/CustomerResultsDto"
import Dropdown from "../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../types/DropdownOptions"
import { DashboardConfig, DashboardTypeEnum } from "../../entitySearch/types/DashboardConfig"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { useFeatureToggle } from "../../../hooks/useFeatureToggle"
import BlueButton from "../../../library/buttons/BlueButton/BlueButton"
import { useCustomer } from "../../../contexts/CustomerContext"

type CustomerSearchProps = {
    className: string
}

const CustomerSearch = ({ className }: CustomerSearchProps) => {
    const client = useClient()
    const config = useConfig()

    const { isEnabled: useCaching } = useFeatureToggle("searchDashboardCaching")

    const [selectedDashboardRef, setSelectedDashboardRef, , isSelectedDashboardLoaded] = useLocalStorage<string | undefined>(
        client + "/customerSearch/dashboard",
        undefined,
        useCaching
    )
    const getDashboardConfigsRequest = useApiQuery<DashboardConfig[]>({
        url: `${config.DATA_API_URL}/api/${client}/user-dashboard-configs`,
        method: "GET",
        onSuccess: (configs: DashboardConfig[]) => {
            const initialDashboard =
                useCaching && selectedDashboardRef
                    ? configs.find(c => selectedDashboardRef === c.reference)
                    : configs.filter(config => config.type === DashboardTypeEnum.AGGREGATED && config.entityConfigReference === null)[0]

            if (!initialDashboard) return

            onDashboardSelected({
                value: initialDashboard,
                label: initialDashboard.name
            })
        },
        isExecutedAutomatically: isSelectedDashboardLoaded
    })
    const selectedDashboardConfig = useMemo(
        () =>
            isSelectedDashboardLoaded && selectedDashboardRef
                ? (getDashboardConfigsRequest.data ?? []).find(c => selectedDashboardRef === c.reference)
                : (getDashboardConfigsRequest.data ?? []).filter(
                      config => config.type === DashboardTypeEnum.AGGREGATED && config.entityConfigReference === null
                  )[0],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedDashboardRef, getDashboardConfigsRequest.data]
    )

    const defaultState: CustomerSearchState = {
        pageIndex: 0,
        pageSize: 20,
        sortByField: { value: "balance", label: "Balance", type: DataPrimitive.NUMBER, fieldType: FieldType.CUSTOMER },
        sortDirection: SortDirection.DESC,
        selectedQuickFilters: [],
        showQuickFilters: true
    }
    const [localState, setLocalState, clearLocalState, isLocalStateLoaded] = useLocalStorage<CustomerSearchState>(
        client + "/customerSearch/dashboard/" + selectedDashboardConfig?.reference + "/state",
        defaultState,
        useCaching
    )
    const [state, dispatch] = useReducer(CustomerSearchReducer, isLocalStateLoaded ? localState : defaultState)
    useEffect(() => {
        if (isLocalStateLoaded && JSON.stringify(state) !== JSON.stringify(localState)) {
            dispatch({ type: CustomerSearchActionEnum.UPDATE_GRID_STATE, state: localState })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedDashboardConfig?.reference, localState, isLocalStateLoaded])
    useEffect(() => {
        if (isLocalStateLoaded && JSON.stringify(state) !== JSON.stringify(localState)) {
            setLocalState(state)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setLocalState, state])

    const [customers, setCustomers] = useState<DataRow[]>([])
    const [totalCustomerCount, setTotalCustomerCount] = useState<number>(0)
    const [totalCurrentBalances, setTotalCurrentBalances] = useState<{ currency: string; value: number }[]>([])

    const getLookupsRequest = useApiQuery<Lookup[]>({
        url: `${config.DATA_API_URL}/api/${client}/lookups`,
        method: "GET"
    })

    const getSelectedConfigFilters = () =>
        selectedDashboardConfig?.gridWidgets[0]?.filters === undefined
            ? []
            : convertFromDtoToArray(selectedDashboardConfig.gridWidgets[0].filters, customerDataFields, getLookupsRequest.data ?? [])
    const [customerDataFields, setCustomerDataFields] = useState<DataField[]>([])
    const [advancedFilters, setAdvancedFilters, resetFilters, areFiltersLoaded] = useLocalStorage<GenericFilter[]>(
        client + "/customerSearch/dashboard/" + selectedDashboardConfig?.reference + "/filters",
        getSelectedConfigFilters,
        useCaching
    )

    const [lastCustomerForPage, setLastCustomerForPage] = useState<{ [key: number]: DataRow | undefined }>({})
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
    const [quickSearchValue, setQuickSearchValue] = useState("")
    const [appliedQuickSearchValue, setAppliedQuickSearchValue] = useState("")
    const { isLeftPanelExpanded, setExpandedPanel } = useLayout()

    useEffect(() => {
        setExpandedPanel(expandablePanels.LEFT_PANEL)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDataFieldsRequest = useApiQuery<DataFieldDto[]>({
        url: `${config.DATA_API_URL}/api/${client}/data-field`,
        method: "GET",
        onSuccess: (fields: DataFieldDto[]) =>
            setCustomerDataFields(
                fields
                    .filter(field => field.fieldType === FieldType.CUSTOMER)
                    .map(field => ({
                        value: field.fieldName,
                        label: field.displayName,
                        type: field.dataPrimitive,
                        lookup: field.dataPrimitive === DataPrimitive.LOOKUP ? field.lookup : undefined,
                        isQuickFilter: field.isQuickFilter,
                        fieldType: field.fieldType
                    }))
            )
    })

    useEffect(() => {
        dispatch({ type: CustomerSearchActionEnum.PAGE_INDEX_UPDATED, pageIndex: 0 })
        setLastCustomerForPage({})
    }, [state.sortByField, state.sortDirection])

    const getCustomersRequest = useApiQuery<CustomerResultsDto>({
        url: `${config.SEARCH_API_URL}/api/${client}/customer`,
        method: "POST",
        dto: {
            pageSize: state.pageSize,
            searchAfterId: lastCustomerForPage[state.pageIndex - 1]?.id ?? null,
            sortBy: {
                fieldName: state.sortByField.value,
                dataPrimitive: state.sortByField.type,
                direction: state.sortDirection,
                searchAfter: lastCustomerForPage[state.pageIndex - 1]?.data[state.sortByField.value] ?? null
            },
            filters: combineFilters(advancedFilters, state.selectedQuickFilters ?? []),
            quickSearchValue: appliedQuickSearchValue
        },
        onSuccess: customerResults => {
            const customerDataRows = customerResults.pagedCustomers.map(customer => convertCustomerToDataRow(customer))

            setCustomers(customerDataRows)
            setTotalCustomerCount(customerResults.totalCount)
            setTotalCurrentBalances(customerResults.totalCurrentBalances)
            setLastCustomerForPage({
                ...lastCustomerForPage,
                [state.pageIndex]: customerDataRows.at(-1)
            })
        },
        isExecutedAutomatically: areFiltersLoaded && isLocalStateLoaded
    })

    const onToggleShowAdvancedFilters = () => setShowAdvancedFilters(!showAdvancedFilters)

    const onQuickSearchValueChanged = (event: ChangeEvent<HTMLInputElement>) => setQuickSearchValue(event.target.value)

    const applyQuickSearch = () => setAppliedQuickSearchValue(quickSearchValue)

    const clearQuickSearch = () => {
        setAppliedQuickSearchValue("")
        setQuickSearchValue("")
    }

    const onDashboardSelected = (option: DropdownOption<DashboardConfig>) => {
        clearQuickSearch()
        setSelectedDashboardRef(option.value.reference)
        setCustomers([])
    }

    const aggregatedDashboardConfigs =
        getDashboardConfigsRequest.data
            ?.filter(config => config.type === DashboardTypeEnum.AGGREGATED && config.entityConfigReference === null)
            .sort((a, b) => (a.name > b.name ? 1 : 0)) ?? []
    const configuredDashboardOptions: DropdownOption<DashboardConfig>[] = aggregatedDashboardConfigs.map(config => ({ value: config, label: config.name }))
    const selectedDashboardOption = configuredDashboardOptions.find(option => option.value.reference === selectedDashboardConfig?.reference)

    const hasFetched =
        !getLookupsRequest.isFetching &&
        getLookupsRequest.hasFetched &&
        !getDataFieldsRequest.isFetching &&
        getDataFieldsRequest.hasFetched &&
        !getDashboardConfigsRequest.isFetching &&
        getDashboardConfigsRequest.hasFetched

    const [selectedCustomer, setCustomer, clearCustomer] = useCustomer()
    const [selectedCustomerReference, setSelectedCustomerReference, resetSelectedCustomerReference] = useLocalStorage<string | undefined>(
        client + "/customerSearch/dashboard/" + selectedDashboardConfig?.reference + "/selectedCustomer",
        undefined,
        useCaching
    )
    useEffect(() => {
        if (!selectedCustomerReference) {
            clearCustomer()
            return
        }
        if (selectedCustomerReference === selectedCustomer.reference) return
        const c = customers.find(customer => customer.id === selectedCustomerReference)
        if (!c) {
            clearCustomer()
            return
        }
        setCustomer({ reference: c.id, data: c.data })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomerReference])

    const resetCachedStateForDashboard = () => {
        clearLocalState()
        resetFilters()
        resetSelectedCustomerReference()
    }

    return (
        <div
            className={`${className ? className : ""} ${classes.container} d-flex h-100 bg-grey ${
                isLeftPanelExpanded ? `w-100 ${classes.gridBackground}` : classes.sidebar
            }`}
        >
            <div className={`d-flex flex-column h-100 w-100 overflow-auto ${isLeftPanelExpanded ? "ms-3 mt-3 me-3" : ""}`}>
                <Loading isLoading={!hasFetched} colour="blue">
                    <div className="d-flex justify-content-between fs-3 fw-lighter">
                        {isLeftPanelExpanded ? (
                            <span className="text-light-grey">Customer Search</span>
                        ) : (
                            <span className="ps-3 pt-3 text-grey">Customer Search Results</span>
                        )}
                        {isLeftPanelExpanded && aggregatedDashboardConfigs.length > 0 && (
                            <div className="ms-2 my-auto">
                                <Dropdown
                                    options={configuredDashboardOptions}
                                    onOptionSelected={onDashboardSelected}
                                    selectedOption={selectedDashboardOption}
                                    textAlign="left"
                                    ariaLabel="dashboard-config"
                                />
                            </div>
                        )}
                        {isLeftPanelExpanded && (
                            <div className="d-flex flex-grow-1">
                                <div className={`d-flex ms-3 ${classes.quickSearchContainer}`}>
                                    <input
                                        className={`form-control ${classes.quickSearchInput}`}
                                        value={quickSearchValue}
                                        onChange={onQuickSearchValueChanged}
                                        placeholder="Search..."
                                        aria-label="quick-search"
                                    />
                                    <div
                                        className={`d-flex fs-6 px-2 align-items-center pointer ${classes.quickSearchInputButton}`}
                                        onClick={applyQuickSearch}
                                        role="button"
                                        aria-label="apply-quick-search"
                                    >
                                        Apply
                                    </div>
                                    <div
                                        className={`d-flex fs-6 px-2 align-items-center pointer ${classes.quickSearchInputButton}`}
                                        onClick={clearQuickSearch}
                                        role="button"
                                        aria-label="clear-quick-search"
                                    >
                                        Clear
                                    </div>
                                </div>
                                <div
                                    role="button"
                                    className={`position-relative d-flex align-items-center bg-grey text-grey ms-auto px-2 bg-white no-select ${
                                        showAdvancedFilters ? "rounded-top" : "rounded"
                                    } `}
                                    onClick={onToggleShowAdvancedFilters}
                                >
                                    <i className="text-blue fal fa-filter"></i>
                                    <span className="fs-4 mx-2">Advanced Filters</span>
                                    {advancedFilters.length > 0 && (
                                        <span className={`position-absolute text-white text-center fs-5 rounded-circle mb-0 bg-blue ${classes.filterCount}`}>
                                            {advancedFilters.length}
                                        </span>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    {showAdvancedFilters && isLeftPanelExpanded && (
                        <div className={`d-flex bg-white rounded p-3 mb-1 ${classes.advancedFiltersContainer}`}>
                            <FilterList
                                fields={customerDataFields}
                                lookups={getLookupsRequest.data ?? []}
                                appliedFilters={advancedFilters}
                                onFiltersApplied={setAdvancedFilters}
                            />
                        </div>
                    )}

                    <div className="mt-4 px-3 d-inline-flex align-items-center gap-5">
                        {isLeftPanelExpanded && useCaching && (
                            <BlueButton
                                label="Reset Grid State"
                                iconClasses="fa-regular fa-shower"
                                className="w-100"
                                ariaLabel="reset-grid-state-button"
                                onClick={resetCachedStateForDashboard}
                            />
                        )}
                    </div>

                    {isLeftPanelExpanded && hasFetched ? (
                        <div className="d-flex flex-grow-1 overflow-auto pt-3">
                            <div className="h-100 w-100 pb-4 overflow-auto">
                                <CustomerGrid
                                    gridReference={
                                        selectedDashboardConfig === undefined ? "customer-grid" : `customer-grid-${selectedDashboardConfig.reference}`
                                    }
                                    customerDataFields={customerDataFields}
                                    defaultFields={selectedDashboardConfig === undefined ? undefined : selectedDashboardConfig.gridWidgets[0]!.columns}
                                    customers={customers}
                                    totalCustomerCount={totalCustomerCount}
                                    totalCurrentBalances={totalCurrentBalances}
                                    pageIndex={state.pageIndex}
                                    pageSize={state.pageSize}
                                    sortByField={state.sortByField}
                                    sortDirection={state.sortDirection}
                                    lookups={getLookupsRequest.data ?? []}
                                    selectedQuickFilters={state.selectedQuickFilters}
                                    showQuickFilters={state.showQuickFilters}
                                    setSelectedCustomerReference={setSelectedCustomerReference}
                                    dispatch={dispatch}
                                    isLoadingCustomerData={getCustomersRequest.isFetching}
                                />
                            </div>
                        </div>
                    ) : (
                        <CustomerSearchList
                            customers={customers}
                            totalCustomerCount={totalCustomerCount}
                            pageIndex={state.pageIndex}
                            pageSize={state.pageSize}
                            isLoadingCustomerData={getCustomersRequest.isFetching}
                            selectedCustomerReference={selectedCustomerReference}
                            setSelectedCustomerReference={setSelectedCustomerReference}
                            dispatch={dispatch}
                        />
                    )}
                </Loading>
            </div>
            {isLeftPanelExpanded && state.showQuickFilters && (
                <div className="d-flex">
                    <CustomerQuickFilters
                        customerDataFields={customerDataFields}
                        lookups={getLookupsRequest.data ?? []}
                        selectedQuickFilters={state.selectedQuickFilters}
                        isLoadingCustomerData={getCustomersRequest.isFetching}
                        isLoadingDataFields={getLookupsRequest.isFetching || getDataFieldsRequest.isFetching}
                        dispatch={dispatch}
                    />
                </div>
            )}
        </div>
    )
}

export default CustomerSearch

const combineFilters = (advancedFilterList: GenericFilter[], quickFilterList: QuickFilter[]): FiltersDto => {
    const filters = convertFromArrayToDto(advancedFilterList)
    const quickFilters = {
        textFieldQuickFilters: [
            ...getQuickFilterDtosForType(quickFilterList, DataPrimitive.TEXT),
            ...getQuickFilterDtosForType(quickFilterList, DataPrimitive.LOOKUP)
        ],
        booleanFieldQuickFilters: getQuickFilterDtosForBoolean(quickFilterList),
        numberFieldQuickFilters: getQuickFilterDtosForType(quickFilterList, DataPrimitive.NUMBER),
        dateFieldQuickFilters: getQuickFilterDtosForType(quickFilterList, DataPrimitive.DATE)
    }

    return {
        textFieldIsOneOf: [...(filters.textFieldIsOneOf ?? []), ...quickFilters.textFieldQuickFilters],
        textFieldStartsWith: filters.textFieldStartsWith,
        booleanFieldIsEqualTo: [...(filters.booleanFieldIsEqualTo ?? []), ...quickFilters.booleanFieldQuickFilters],
        numberFieldIsOneOf: [...(filters.numberFieldIsOneOf ?? []), ...quickFilters.numberFieldQuickFilters],
        numberFieldMatchesOperation: filters.numberFieldMatchesOperation,
        dateFieldIsOneOf: [...(filters.dateFieldIsOneOf ?? []), ...quickFilters.dateFieldQuickFilters],
        dateFieldMatchesOperation: [...(filters.dateFieldMatchesOperation ?? [])],
        fieldExists: filters.fieldExists
    }
}

const getQuickFilterDtosForBoolean = (quickFilterList: QuickFilter[]) =>
    quickFilterList.filter(f => f.field.type === DataPrimitive.BOOLEAN).map(f => ({ fieldName: f.field.value, equalTo: f.filter.label === "true" }))

const getQuickFilterDtosForType = (quickFilterList: QuickFilter[], dataPrimitive: DataPrimitive) => {
    const filtersForType = quickFilterList.filter(f => f.field.type === dataPrimitive)
    const quickFilterDtos: any[] = []
    if (filtersForType.length > 0) {
        filtersForType.forEach(f => {
            if (quickFilterDtos.some(qf => qf.fieldName === f.field.value)) {
                quickFilterDtos.find(qf => qf.fieldName === f.field.value)?.values.push(f.filter.label)
            } else {
                quickFilterDtos.push({
                    fieldName: f.field.value,
                    values: [f.filter.label],
                    notOneOf: false
                })
            }
        })
    }
    return quickFilterDtos
}
