import classes from "./ImportFileConfig.module.scss"
import { FileImportConfigAction, FileImportConfigActionEnum, FileImportConfigState } from "../../../reducers/FileImportConfigReducer"
import { FileType } from "../../../types/FileImportConfig"
import Input from "../../../../../library/Inputs/Input"
import DataFileImportFileConfigOptions from "./DataFileImportOptions"
import Dropdown from "../../../../../library/dropdowns/Dropdown"

const fileTypes = [
    { id: "1", value: FileType.CUSTOMER, label: FileType.CUSTOMER },
    { id: "2", value: FileType.TRANSACTION, label: FileType.TRANSACTION }
]
const fileFormats = [{ id: "1", value: "CSV", label: "CSV" }]

type FileImportConfigSetupProps = {
    config: FileImportConfigState
    dispatch: React.Dispatch<FileImportConfigAction>
}

const ImportFileConfig = ({ config, dispatch }: FileImportConfigSetupProps) => {
    const onConfigNameChange = (newName: string) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            dataFileImportConfig: {
                ...config.dataFileImportConfig,
                configName: newName
            }
        })
    }

    const onFileTypeSelected = (option: any) => {
        dispatch({
            type: FileImportConfigActionEnum.FILE_IMPORT_EDITING_CONFIG_UPDATED,
            editingConfig: {
                ...config,
                fileType: option.value,
                dataFileImportConfig: {
                    ...config.dataFileImportConfig,
                    type: option.value,
                    ...(option.value === FileType.TRANSACTION && { isOpenItems: false, mandatoryCustomerFieldConfig: { columns: [], trimOptions: [] } })
                }
            }
        })
    }

    const onFileFormatSelected = (option: any) => {
        dispatch({
            type: FileImportConfigActionEnum.FILE_IMPORT_EDITING_CONFIG_UPDATED,
            editingConfig: { ...config, fileFormat: option.value }
        })
    }

    return (
        <div className={`d-flex ${classes.card} mt-2 pt-2 `}>
            <div className="d-flex px-3 pt-3">
                <i className={`fal fa-grip-lines ${classes.icon}`} />
            </div>
            <div className={`${classes.verticalDivider}`} />
            <div className="d-flex flex-column w-100">
                <div className="d-flex my-1 mx-3">
                    <span className="text-grey fs-4">File details</span>
                </div>
                <div className={`${classes.horizontalDivider} mx-2`} />
                <div className="d-flex flex-column">
                    <div className="d-flex p-3">
                        <div className="d-flex flex-column small me-3">
                            <span className="text-uppercase small mb-1 ms-1 text-grey">Import Config Name</span>
                            <Input
                                placeholder="Please enter the import config name"
                                value={config.dataFileImportConfig.configName}
                                onChange={onConfigNameChange}
                            />
                        </div>

                        <div className={`d-flex flex-column pe-3 small`}>
                            <span className="text-uppercase small mb-1 ms-1 text-grey">File type</span>
                            <Dropdown
                                className="me-3"
                                placeholder={"Please select a file type"}
                                selectedOption={fileTypes.find(ft => ft.value === config.fileType) || fileTypes[0]}
                                options={fileTypes}
                                onOptionSelected={onFileTypeSelected}
                                ariaLabel="File Type"
                            />
                        </div>
                        <div className={`d-flex flex-column pe-3 small`}>
                            <span className="text-uppercase small mb-1 ms-1 text-grey">Format</span>
                            <Dropdown
                                className="me-3"
                                placeholder={"Please select format"}
                                selectedOption={fileFormats.find(ft => ft.value === config.fileFormat) || fileFormats[0]}
                                options={fileFormats}
                                onOptionSelected={onFileFormatSelected}
                                ariaLabel="File Format"
                            />
                        </div>
                    </div>
                    {config.fileType === FileType.CUSTOMER || config.fileType === FileType.TRANSACTION ? (
                        <DataFileImportFileConfigOptions state={config.dataFileImportConfig} dispatch={dispatch} />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ImportFileConfig
