import { getApiUrl } from '../constants';
import { webApiInterface } from './webApiInterface';

export function getMessageTypes(client, dispatch) {
    const url = getApiUrl(client) + 'api/rule/messagetypes';

    return webApiInterface.authFetch(client, url, dispatch).then(function (response) {
        return response.json();
    });
}

export function getActionDefinitions(client, dispatch) {
    const url = getApiUrl(client) + 'api/rule/actiondefinitions';

    return webApiInterface.authFetch(client, url, dispatch).then(function (response) {
        return response.json();
    });
}

export function getRuleGroup(client, dispatch, ruleGroupId) {
    const url = getApiUrl(client) + `api/rule?ruleGroupId=${ruleGroupId}`;

    return webApiInterface.authFetch(client, url, dispatch).then(function (response) {
        return response.json();
    });
}

export function saveRuleGroup(client, dispatch, ruleGroup) {
    const url = getApiUrl(client) + 'api/rule';

    return webApiInterface.authPost(client, url, dispatch, ruleGroup).then(function (response) {
        return response.json();
    });
}

export function validateRuleGroups(client, dispatch) {
    const url = getApiUrl(client) + 'api/rule/validate';

    return webApiInterface.authPost(client, url, dispatch).then(function () {

    });
}

export function copyRuleGroup(client, dispatch, ruleGroupId) {
    const url = getApiUrl(client) + `api/rule/copy?ruleGroupId=${ruleGroupId}`;

    return webApiInterface.authPost(client, url, dispatch).then(function (response) {
        return response.json();
    });
}

export function deleteRuleGroup(client, dispatch, ruleGroupId) {
    const url = getApiUrl(client) + `api/rule/delete?ruleGroupId=${ruleGroupId}`;

    return webApiInterface.authPost(client, url, dispatch).then(function () {
    });
}