import React, { useState } from "react"
import classes from "./Tabs.module.scss"
import { generateHtmlId } from "../helpers"
import "./Tabs.scss"

const Tabs = ({ className, contentClassName, children, bubbled = false, isDarkTheme = false }) => {
    const tabs = React.Children.map(children, child => {
        return { ...child.props, id: generateHtmlId(), contentId: generateHtmlId() }
    })
    var initialTabActivity = tabs.map(tab => {
        return { tabTitle: tab.title, active: tab.isActive }
    })
    const [tabActivity, setTabActivity] = useState(initialTabActivity)

    const pillsTabId = generateHtmlId()
    const pillsTabContentId = generateHtmlId()

    const tabClicked = (bubbled, tabTitle) => {
        if (bubbled) {
            var updatedActivity = tabActivity.map(tab => {
                if (tab.tabTitle === tabTitle) return { tabTitle: tab.tabTitle, active: true }
                else return { tabTitle: tab.tabTitle, active: false }
            })
            setTabActivity(updatedActivity)
        }
    }

    return (
        <div className={`d-flex flex-column ${className ? className : ""}`} aria-label="tabs">
            <div className="list-group list-group-horizontal" id={pillsTabId} role="tablist">
                {tabs.map(tab => {
                    return (
                        !tab.hide && (
                            <button
                                key={`tabsButton${tab.title}`}
                                className={`text-center
                                        ${
                                            bubbled
                                                ? `me-2 px-4 ${classes.bubbleTab} ${
                                                      tabActivity.filter(activity => activity.tabTitle === tab.title)[0].active
                                                          ? `${classes.activeTab} 'active'`
                                                          : ""
                                                  }`
                                                : `list-group-item list-group-item-action ${classes["tab-button"]} ${tab.isActive ? "active" : ""} ${
                                                      isDarkTheme ? "dark-theme-tabs" : ""
                                                  }`
                                        }
                                    `}
                                id={tab.id}
                                data-bs-toggle="pill"
                                data-bs-target={`#${tab.contentId}`}
                                type="button"
                                role="tab"
                                aria-label={tab.title}
                                aria-controls={tab.contentId}
                                aria-selected="true"
                                onClick={() => {
                                    tabClicked(bubbled, tab.title)
                                    tab.onClick && tab.onClick()
                                }}
                            >
                                <span className="fs-5">{tab.title}</span>
                            </button>
                        )
                    )
                })}
            </div>
            {!isDarkTheme && <hr className={`${classes.separator}`} />}
            <div className={`d-flex h-100 tab-content ${contentClassName ? contentClassName : ""}`} id={pillsTabContentId}>
                {tabs.map(tab => {
                    return (
                        <div
                            key={`tabsContent${tab.title}`}
                            className={`flex-grow-1 tab-pane fade ${tab.isActive ? "show active" : ""} ${tab.className ? tab.className : ""}`}
                            id={tab.contentId}
                            role="tabpanel"
                            aria-labelledby={tab.id}
                        >
                            {tab.children}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default Tabs
