import { Overlay } from 'invevo-react-components'
import { useState } from 'react'
import { actionTypes } from '../../../reducers/actionTypes'
import EditCustomerDashboardConfig from '../overlays/EditDashboardConfig'
import AssignRolesToCustomerDashboard from '../overlays/AssignRolesToDashboard'
import classes from './EntityDashboardCard.module.scss'
import { widgetTypes } from '../../../enums/widgetTypes'
import DashboardConfigState from '../../../types/DashboardConfigState'
import { DashboardConfigAction, DashboardConfigCustomerEntityConfig, DashboardConfigEntityConfig, DashboardConfigTransactionFields } from '../../../reducers/dashboardConfigsReducer'
import Role from '../../../../../routes/userRoles/roles/types/Role'
import Lookup from '../../../../../types/Lookup'

type EntityDashboardCardProps = {
    state: {
        originalConfig: DashboardConfigState
        editingConfig: DashboardConfigState
        customerFields: DashboardConfigCustomerEntityConfig[]
        transactionFields: DashboardConfigTransactionFields[]
        showValidationErrors: boolean
        dashboardConfigs: DashboardConfigState[]
        entityConfigs: DashboardConfigEntityConfig[]
        roles: Role[]
        lookups: Lookup[]
        isNewEntityStructureEnabled: boolean
    }
    dispatch: React.Dispatch<DashboardConfigAction>
    className?: string
}

const EntityDashboardCard = ({ className, state, dispatch } : EntityDashboardCardProps) => {
    const {
        originalConfig,
        editingConfig,
        customerFields,
        transactionFields,
        entityConfigs,
        showValidationErrors,
        dashboardConfigs,
        roles,
        lookups,
        isNewEntityStructureEnabled
    } = state
    const [showEditDashboardOverlay, setShowEditDashboardOverlay] = useState(false)
    const [showAssignRolesOverlay, setShowAssignRolesOverlay] = useState(false)

    const onEditDashboardClick = () => {
        dispatch({ type: actionTypes.DASHBOARD_EDITED, config: originalConfig })
        setShowEditDashboardOverlay(true)
    }

    const onCloseEditDashboardClick = () => {
        setShowEditDashboardOverlay(false)
    }

    const onConfigDeleted = () => {
        setShowEditDashboardOverlay(false)
    }

    const onAssignRolesClick = () => {
        dispatch({ type: actionTypes.DASHBOARD_EDITED, config: originalConfig })
        setShowAssignRolesOverlay(true)
    }

    const onCloseAssignRolesClick = () => {
        setShowAssignRolesOverlay(false)
    }

    const onAssignRolesSaved = () => {
        setShowAssignRolesOverlay(false)
    }

    const getIconForWidget = (type: widgetTypes) => {
        switch (type) {
            case widgetTypes.INFORMATION:
                return "fal fa-info-circle"
            case widgetTypes.VALUE:
                return "fal fa-percent"
            case widgetTypes.SMALL_BAR_CHART:
                return "fal fa-chart-bar"
            case widgetTypes.AREA_CHART:
                return "fal fa-chart-area"
            case widgetTypes.LINE_CHART:
                return "fal fa-chart-line"
            case widgetTypes.SMALL_PIE_CHART:
                return "fal fa-chart-pie-alt"
            case widgetTypes.TRANSACTIONS_GRID:
            case widgetTypes.GRID:
                return "fal fa-list"
            default:
                return "fal fa-list"
        }
    }

    const widgetIcons = sortWidgets([
        ...originalConfig.infoWidgets,
        ...originalConfig.valueWidgets,
        ...originalConfig.transactionsWidgets,
        ...originalConfig.smallBarChartWidgets,
        ...originalConfig.areaChartWidgets,
        ...originalConfig.lineChartWidgets,
        ...originalConfig.smallPieChartWidgets,
        ...originalConfig.entityChartWidgets,
        ...originalConfig.gridWidgets
    ])

    return (
        <>
            <div className={`${className ? className : ''} d-flex flex-column align-self-start ${classes.card} w-100 p-2`}>
                <div className="d-flex align-items-center p-1 fs-5">
                    <i className="fal fa-tachometer-fast fs-3 text-blue me-2"></i>
                    <span className="text-grey text-uppercase">{originalConfig.name}</span>
                    <div className="d-flex ms-auto pointer text-blue" onClick={onAssignRolesClick}>
                        <i className="fas fa-id-card-alt"></i>
                        <i className={`fas fa-plus ${classes['assign-roles-icon']} pt-1`}></i>
                    </div>
                </div>
                <div className="d-flex flex-column mt-2 text-grey">
                    <span className="small"><span className="text-uppercase small">dashboard view</span></span>
                    <span>Chart</span>
                </div>
                {isNewEntityStructureEnabled &&
                    <div className="d-flex flex-column mt-2 text-grey">
                        <span className="small"><span className="text-uppercase small">entity type</span></span>
                        <span>{entityConfigs.find(config => config.reference === originalConfig.entityConfigReference)?.displayName}</span>
                    </div>
                }
                <div className={`mt-2 d-flex flex-wrap align-content-start border-bottom p-2 ${classes['widget-list']} overflow-auto`}>
                    {
                        widgetIcons.length === 0 ?
                            <span className="text-grey">No widgets configured</span> :
                            widgetIcons.map(widget =>
                                <div key={`${widget.displayName}_${widget.ordinal}`} className={`d-flex text-grey align-self-start align-items-center mb-2 ${classes['widget-name']}`}>
                                    <i className={getIconForWidget(widget.widgetType)}></i>
                                    <span className="ms-2 me-4 text-truncate">{widget.displayName}</span>
                                </div>
                            )
                    }
                </div>
                <div className="d-flex flex-grow-1 mt-2">
                    <div role="button" aria-label="Edit dashboard" className={`d-flex w-100 align-self-start py-1 ${classes.button} pointer`} onClick={onEditDashboardClick}>
                        <span className="mx-auto text-grey fs-5">View / edit dashboard</span>
                    </div>
                </div>

            </div>
            {showEditDashboardOverlay &&
                <Overlay title={originalConfig.name} isFullScreen={true} onCloseClick={onCloseEditDashboardClick}>
                    <EditCustomerDashboardConfig state={{ originalConfig, editingConfig, customerFields, transactionFields, showValidationErrors, entityConfigs, dashboardConfigs, lookups, isNewEntityStructureEnabled }} dispatch={dispatch} onDeleted={onConfigDeleted} />
                </Overlay>
            }
            {showAssignRolesOverlay &&
                <Overlay title="Assign roles" onCloseClick={onCloseAssignRolesClick}>
                    <AssignRolesToCustomerDashboard state={{ originalRoleSelection: originalConfig.roleReferences, editingConfig, roles, isNewEntityStructureEnabled }} dispatch={dispatch} onSaved={onAssignRolesSaved} onCancel={onCloseAssignRolesClick} />
                </Overlay>
            }
        </>
    )
}

const sortWidgets = (widgets: { ordinal: number, displayName: string, widgetType: widgetTypes }[]) => [...widgets].sort((a, b) => a.ordinal > b.ordinal ? 1 : -1)

export default EntityDashboardCard