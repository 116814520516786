import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showToastWarningMessage } from '../../../api/toasterApi';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { CommandCell } from '../../common/grids/commandCell';
import { ConfrimDialog } from '../../common/confirmDialog';

class FilterGrid extends Component {
  constructor(props, context) {
    super(props, context);

    this.gridProgressive = 0;

    this.state = {
      showDeleteDialog: false,
      itemToDelete: ''
    };

    this.CommandCell = CommandCell({
      onCommandClick: this.onDeleteRowClicked,
      cssTag: 'Remove',
      buttonText: 'Remove'
    });
  }

  closeDialog = () => {
    this.setState({
      showDeleteDialog: false,
      itemToDeleteId: ''
    });
  };

  onDeleteRowClicked = (e, dataItem) => {
    e.preventDefault();

    if (!dataItem.canRemove) {
      showToastWarningMessage(
        'Filter definition can not be removed as it is already in use.'
      );
      return;
    }

    this.setState({
      itemToDelete: dataItem.filterId,
      showDeleteDialog: true
    });
  };

  deleteItem = () => {
    if (this.props.onDeleteFilter) {
      this.props.onDeleteFilter(this.state.itemToDelete);
    }
    this.closeDialog();
  };

  getGridKey() {
    return 'grid-account-filter' + this.gridProgressive++; //Increment the key to ensure the grid gets rerendered on state changes
  }

  render() {
    let grid = '';
    if (this.props.filters.length > 0) {
      grid = (
        <Grid
          key={this.getGridKey()}
          data={this.props.filters}
          sort={[{ field: 'name', dir: 'asc' }]}
          scrollable={'none'}
        >
          <GridColumn field={'name'} title={'Filter'} />
          <GridColumn field={'default'} title={'Default Value'} />

          {this.props.isAdvancedFilterMode && (
            <GridColumn field={'operator'} title={'Operator'} />
          )}

          <GridColumn cell={this.CommandCell} width={'250px'} />
        </Grid>
      );
    }

    return (
      <div id="account-filters-grid-container">
        {grid}
        <ConfrimDialog
          show={this.state.showDeleteDialog}
          message={'Are you sure you want to remove this filter?'}
          onConfirm={this.deleteItem}
          onClose={this.closeDialog}
        />
      </div>
    );
  }
}

export default connect()(FilterGrid);
