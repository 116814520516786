import { uiConfigLoadDefaultConfigRequest, uiConfigLoadDefaultConfigRequestFailed, uiConfigLoadDefaultConfigRequestReceived, uiConfigSaveConfigRequest, uiConfigSaveConfigRequestFailed, uiConfigSaveConfigRequestReceived } from "../actions/uiConfigActions";
import { getApiUrl } from "../constants";
import displayError from "../utility/error";
import { webApiInterface } from "./webApiInterface";

export function getUserConfig(client, userConfigTypeId) {
  return (dispatch) => {
    dispatch(uiConfigLoadDefaultConfigRequest(userConfigTypeId));
    return webApiInterface
      .authFetch(
        client,
        getApiUrl(client) +
        'api/uiconfig/GetDefaultUIConfig?viewTypeId=' +
        userConfigTypeId,
        dispatch
      )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        dispatch(
          uiConfigLoadDefaultConfigRequestReceived(userConfigTypeId, data)
        );
      })
      .catch((err) => {
        displayError(err);
        dispatch(
          uiConfigLoadDefaultConfigRequestFailed(
            'unknown error when requesting default config'
          )
        );
      });
  };
}

function sendUserConfig(client, payload) {
  return (dispatch) => {
    dispatch(uiConfigSaveConfigRequest());
    webApiInterface
      .authPost(client, getApiUrl(client) + 'api/uiconfig/SetUIConf', dispatch, payload)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        dispatch(uiConfigSaveConfigRequestReceived(payload.viewTypeId, data));
      })
      .catch((err) => {
        displayError(err);
        dispatch(
          uiConfigSaveConfigRequestFailed(
            'unknown error when saving in uiconfig'
          )
        );
      });
  };
}

export function createUserConfig(client, userConfigTypeId, configToPersist) {
  const payload = {
    name: `config - ${userConfigTypeId}`,
    viewTypeId: userConfigTypeId,
    isDefault: true, // hangover from legacy usage
    newValue: JSON.stringify(configToPersist)
  };
  return sendUserConfig(client, payload);
}

export function saveUserConfig(client, id, userConfigTypeId, configToPersist) {
  const payload = {
    guid: id,
    name: `config - ${userConfigTypeId}`,
    viewTypeId: userConfigTypeId,
    isDefault: true, // hangover from legacy usage
    newValue: JSON.stringify(configToPersist)
  };
  return sendUserConfig(client, payload);
}

// maybe useful at some point
// export function deleteConfig(details) {
//   return (dispatch) => {
//     dispatch(uiConfigDeleteConfigRequest(details));
//     webApiInterface
//       .authPost(api.baseUrl + 'api/uiconfig/DeleteUIConf', dispatch, details)
//       .then(function (response) {
//         if (!response.ok) {
//           throw Error(response.statusText);
//         }
//         return response.json();
//       })
//       .then(function (data) {
//         dispatch(uiConfigSaveConfigRequestReceived(data));
//       })
//       .catch(function (err) {
//         displayError(err);
//         dispatch(
//           uiConfigDeleteConfigRequestFailed(
//             'unknown error when saving in uiconfig'
//           )
//         );
//       });
//   };
// }
