import Input from "../../../library/Inputs/Input"
import EntityConfig from "../types/EntityConfig"
import EntityDataFieldsGridLegacy from "./EntityDataFieldsGridLegacy"
import useApiQuery from "../../../hooks/useApiQuery"
import ProgressButton from "../../../library/buttons/ProgressButton/ProgressButton"
import EntityConfigDataFieldForm from "./EntityConfigDataFieldForm"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import EntityDataField from "../types/EntityDataField"
import Lookup from "../../../types/Lookup"
import EntityRelationship from "./EntityRelationship"
import Dropdown from "../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../types/DropdownOptions"
import { toSnakeCase } from "../../../library/helpers/stringUtils"
import classes from "./EntityConfigDetails.module.scss"
import { v4 as uuidv4 } from "uuid"
import EntityParentGrouping from "../types/EntityParentGrouping"
import EntityRelationshipCalculation from "../types/EntityRelationshipCalculation"
import { convertFromArrayToDto } from "../../../library/FilterList/FiltersDto"
import EntityRelationshipType from "../types/EntityRelationship"
import { useFeatureToggle } from "../../../hooks/useFeatureToggle"
import Grid from "../../../library/grid/Grid"
import BlueButton from "../../../library/buttons/BlueButton/BlueButton"
import RowData from "../../../library/grid/types/RowData"
import { DefaultDataFieldHeaders, DefaultDataFieldLookups, HardcodedEntityDataFields } from "../constants"
import HeaderDefinition from "../../../library/grid/types/HeaderDefinition"
import { useEnvConfig } from "../../../contexts/EnvironmentConfigContext"
import useClient from "../../../hooks/useClient"
import StandardButton from "../../../library/buttons/StandardButton/StandardButton"
import { useOverlay } from "../../../contexts/overlay/OverlayContext"
import DuplicateEntityConfigForm from "./DuplicateEntityConfigForm"

type EntityConfigDetailsProps = {
    entityRelationships: EntityRelationshipType[]
    allEntityConfigs: EntityConfig[]
    entityConfig: EntityConfig
    lookups: Lookup[]
    onEntityConfigUpdated: (entityConfig: EntityConfig) => void
    onNewEntityConfigSaved: (entityConfig: EntityConfig) => void
    onEntityConfigDeleted: (entityConfig: EntityConfig) => void
    onEntityConfigDuplicated: (entityConfig: EntityConfig) => void
    onEntityRelationshipsUpdated: React.Dispatch<React.SetStateAction<EntityRelationshipType[]>>
    isAddingNewEntityConfig: boolean
}

const EntityConfigDetails = ({
    entityRelationships,
    allEntityConfigs,
    entityConfig,
    lookups,
    onEntityConfigUpdated,
    onNewEntityConfigSaved,
    onEntityConfigDeleted,
    onEntityConfigDuplicated,
    onEntityRelationshipsUpdated,
    isAddingNewEntityConfig
}: EntityConfigDetailsProps) => {
    const client = useClient()
    const config = useEnvConfig()

    const [showDataFieldForm, setShowDataFieldForm] = useState<boolean>(false)
    const [entityDataField, setEntityDataField] = useState<EntityDataField>()
    const [isEditingDataField, setIsEditingDataField] = useState<boolean>(false)

    useEffect(() => {
        setShowDataFieldForm(false)
    }, [entityConfig.reference])

    const onDisplayNameUpdated = (value: string) => onEntityConfigUpdated({ ...entityConfig, displayName: value })

    const entityConfigReference = isAddingNewEntityConfig ? toSnakeCase(entityConfig.displayName) : entityConfig.reference

    const relationshipsForThisEntity = entityRelationships.filter(
        relationship => relationship.parentEntityReference === entityConfig.reference || relationship.childEntityReference === entityConfig.reference
    )

    const saveEntityConfigRequest = useApiQuery({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-config`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    const deleteEntityConfigRequest = useApiQuery({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-config`,
        method: "DELETE",
        isExecutedAutomatically: false
    })

    const saveEntityRelationshipsRequest = useApiQuery({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-relationships`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    const saveEntityConfig = () => {
        const relationshipsWhereEntityIsChild: EntityRelationshipType[] = entityRelationships.filter(
            relationship => relationship.childEntityReference === entityConfig.reference
        )
        const relationshipsAsParentGrouping: EntityParentGrouping[] = relationshipsWhereEntityIsChild.map(relationship => ({
            parentEntityConfigReference: relationship.parentEntityReference,
            childGroupingField: relationship.groupingFieldName
        }))

        const saveConfigPromise = saveEntityConfigRequest
            .execute(entityConfigReference, {
                displayName: entityConfig.displayName,
                fields: entityConfig.fields,
                parents: relationshipsAsParentGrouping
            })
            .then(() => {
                if (isAddingNewEntityConfig) {
                    onNewEntityConfigSaved({ ...entityConfig, reference: entityConfigReference })
                } else {
                    onEntityConfigUpdated(entityConfig)
                }
            })

        const saveRelationshipsPromise = saveEntityRelationshipsRequest.execute(
            undefined,
            entityRelationships.map(relationship => ({
                childEntityReference: relationship.childEntityReference,
                parentEntityReference: relationship.parentEntityReference,
                groupingFieldName: relationship.groupingFieldName,
                calculations: relationship.calculations.map(calculation => ({
                    ...calculation,
                    filters: convertFromArrayToDto(calculation.filters)
                }))
            }))
        )

        return Promise.all([saveConfigPromise, saveRelationshipsPromise])
    }

    const overlay = useOverlay()
    const onDeleteEntityConfigClick = () =>
        overlay.showConfirmDeletionOverlay(
            "Entity config",
            <div className="fs-4 text-grey">
                Are you sure you want to delete the entity config
                <span className="fw-bold"> {entityConfig.displayName}</span>?
            </div>,
            () => deleteEntityConfigRequest.execute(entityConfigReference).then(() => onEntityConfigDeleted(entityConfig))
        )

    const existingRelationships = entityRelationships
        .filter(relationship => relationship.parentEntityReference === entityConfig.reference)
        .map(relationship => ({
            childEntityReference: relationship.childEntityReference,
            parentEntityReference: relationship.parentEntityReference,
            groupingFieldName: relationship.groupingFieldName,
            calculations: relationship.calculations.map(calculation => ({
                ...calculation,
                filters: convertFromArrayToDto(calculation.filters)
            }))
        }))
    const onDuplicateEntityConfigClick = () =>
        overlay.showOverlay(
            <DuplicateEntityConfigForm
                entityConfig={entityConfig}
                existingRelationships={existingRelationships}
                existingEntityConfigRefs={allEntityConfigs.map(c => c.reference)}
                onEntityConfigDuplicated={onEntityConfigDuplicated}
                onEntityRelationshipsUpdated={onEntityRelationshipsUpdated}
                onClose={overlay.closeOverlay}
            />
        )

    const onAddNewDataField = () => {
        setEntityDataField(undefined)
        setIsEditingDataField(false)
        setShowDataFieldForm(true)
    }

    const onEditEntityDataField = (entityDataField: EntityDataField) => {
        setEntityDataField(entityDataField)
        setIsEditingDataField(true)
        setShowDataFieldForm(true)
    }

    const onDeleteEntityDataField = (entityDataField: EntityDataField) => {
        onEntityConfigUpdated({ ...entityConfig, fields: entityConfig.fields.filter(field => field.fieldName !== entityDataField.fieldName) })
        removeCalculationFromRelationships(entityDataField, entityConfig, entityRelationships, onEntityRelationshipsUpdated)
    }

    const onSaveEntityDataField = (
        newEntityDataField: EntityDataField,
        newCalculation: EntityRelationshipCalculation | undefined,
        calculationChildEntityReference: string | undefined
    ) => {
        if (entityConfig.fields.find(field => field.fieldName === newEntityDataField.fieldName) === undefined) {
            onEntityConfigUpdated({ ...entityConfig, fields: [...entityConfig.fields, newEntityDataField] })
        } else {
            onEntityConfigUpdated({
                ...entityConfig,
                fields: entityConfig.fields.map(originalField =>
                    originalField.fieldName === newEntityDataField.fieldName ? newEntityDataField : originalField
                )
            })
        }

        const relationshipForCalculation = entityRelationships.find(
            relationship =>
                relationship.parentEntityReference === entityConfig.reference &&
                (relationship.childEntityReference === calculationChildEntityReference || calculationChildEntityReference === undefined)
        )

        if (relationshipForCalculation === undefined) return

        const existingCalculations = relationshipForCalculation.calculations
            .filter(calculation => calculation.destinationFieldName !== newCalculation?.destinationFieldName)
            .filter(calculation => calculation.destinationFieldName !== newEntityDataField.fieldName)

        const updatedRelationship: EntityRelationshipType = {
            ...relationshipForCalculation,
            calculations: newCalculation === undefined ? existingCalculations : [...existingCalculations, newCalculation]
        }

        onEntityRelationshipsUpdated(entityRelationships =>
            entityRelationships.map(relationship => {
                if (relationship.id === updatedRelationship.id) {
                    return updatedRelationship
                }

                if (relationship.parentEntityReference === updatedRelationship.parentEntityReference && newCalculation !== undefined) {
                    return removeCalculationFromRelationship(relationship, newCalculation)
                }

                return relationship
            })
        )
    }

    const removeCalculationFromRelationship = (relationship: EntityRelationshipType, calculationToRemove: EntityRelationshipCalculation) => ({
        ...relationship,
        calculations: relationship.calculations.filter(calculation => calculation.destinationFieldName !== calculationToRemove?.destinationFieldName)
    })

    const toggleShowDataFieldForm = () => setShowDataFieldForm(!showDataFieldForm)

    const relationshipOptions = allEntityConfigs
        .map(entity => ({
            label: entity.displayName,
            value: entity
        }))
        .filter(entity => relationshipsForThisEntity.find(relationship => relationship.parentEntityReference === entity.value.reference) === undefined)
        .filter(entity => relationshipsForThisEntity.find(relationship => relationship.childEntityReference === entity.value.reference) === undefined)
        .concat({
            label: entityConfig.displayName,
            value: entityConfig
        })
        .filter(
            entity =>
                relationshipsForThisEntity.find(
                    relationship =>
                        relationship.parentEntityReference === entity.value.reference && relationship.childEntityReference === entity.value.reference
                ) === undefined
        )
        .sort((a, b) => a.label.localeCompare(b.label))

    const onRelationshipAdded = (option: DropdownOption<EntityConfig>) =>
        onEntityRelationshipsUpdated(entityRelationships => [
            ...entityRelationships,
            {
                id: uuidv4(),
                childEntityReference: option.value.reference,
                parentEntityReference: entityConfig.reference,
                groupingFieldName: "",
                calculations: []
            }
        ])

    const onRelationshipUpdated = (updatedRelationship: EntityRelationshipType) =>
        onEntityRelationshipsUpdated(entityRelationships =>
            entityRelationships.map(existingRelationship => (existingRelationship.id === updatedRelationship.id ? updatedRelationship : existingRelationship))
        )

    const onRelationshipDeleted = (deletedRelationship: EntityRelationshipType) => {
        onEntityRelationshipsUpdated(entityRelationships =>
            entityRelationships.filter(existingRelationship => existingRelationship.id !== deletedRelationship.id)
        )

        onEntityConfigUpdated({
            ...entityConfig,
            parents: entityConfig.parents.filter(parent => parent.parentEntityConfigReference !== deletedRelationship.parentEntityReference)
        })
    }

    const modularGridToggle = useFeatureToggle("entityConfigUsesModularGrid")

    const dataFields = entityConfig.fields
        .filter(field => field.fieldName !== "client" && field.fieldName !== "entity_type")
        .map(field => mapEntityDataFieldToRowData(field))

    const renderRowActions = (row: RowData) => {
        const entityDataField = entityConfig.fields.find(field => field.fieldName === row.reference)
        if (entityDataField === undefined) return <></>

        const onEditClick = () => onEditEntityDataField(entityDataField)
        const onDeleteClick = () => onDeleteEntityDataField(entityDataField)

        return (
            <div className="d-flex gap-2">
                <Grid.ActionButton
                    rowData={row}
                    onClick={onEditClick}
                    selectOnClick={false}
                    ariaLabelPrefix="edit-field"
                    icon="text-blue fa-solid fa-pen-to-square"
                />
                {!HardcodedEntityDataFields.some(hardcodedField => hardcodedField.fieldName === row.reference) ||
                ["text_name", "boolean_archived"].includes(row.reference) ? (
                    <Grid.ActionButton
                        rowData={row}
                        onClick={onDeleteClick}
                        selectOnClick={false}
                        ariaLabelPrefix="delete-field"
                        icon="text-blue far fa-trash-can"
                    />
                ) : (
                    <div className={`${classes.actionButtonPlaceholder}`} />
                )}
            </div>
        )
    }

    const onRowsUpdated = (updates: { rowReference: string; header: HeaderDefinition; newValue: string }[]) =>
        updates.forEach(update => {
            onEntityConfigUpdated({
                ...entityConfig,
                fields: entityConfig.fields.map(field => {
                    if (field.fieldName !== update.rowReference) return field

                    const dataType = {
                        type: field.dataType.type,
                        timeZone: update.header.value === "timezone" ? update.newValue : field.dataType.timeZone,
                        lookupReference: update.header.value === "lookupreference" ? update.newValue : field.dataType.lookupReference,
                        currencyFieldReference: field.dataType.currencyFieldReference,
                        currencyCode: update.header.value === "currencycode" ? update.newValue : field.dataType.currencyCode,
                        format: field.dataType.format
                    }

                    return {
                        fieldName: update.rowReference,
                        displayName: update.header.value === "displayname" ? update.newValue : field.displayName,
                        description: update.header.value === "description" ? update.newValue : field.description,
                        dataType,
                        dataPrimitiveType: field.dataPrimitiveType,
                        isQuickFilter: update.header.value === "isquickfilter" ? update.newValue === "true" : field.isQuickFilter,
                        intraEntityCalculation: field.intraEntityCalculation,
                        trackChanges: update.header.value === "trackchanges" ? update.newValue === "true" : field.trackChanges
                    }
                })
            })
        })

    return (
        <div className="d-flex h-100 w-100 overflow-auto bg-dark-gradient">
            <div className="d-flex flex-column h-100 w-100 overflow-auto p-3" key={entityConfig.reference}>
                <span className="text-light-grey fs-3 mb-2">Entity Config</span>
                <div className="bg-grey rounded p-3 mb-3">
                    <div className="d-flex">
                        <div className="d-flex flex-column">
                            <div className="d-flex mb-1">
                                <span className="text-uppercase my-auto me-3 text-grey no-select">Entity Name</span>
                                <span className="text-grey ms-1 no-select">{entityConfigReference}</span>
                            </div>
                            <div className="d-flex">
                                <span className="text-uppercase my-auto me-2 text-grey no-select text-nowrap">Display Name</span>
                                <Input
                                    placeholder="Please enter a name"
                                    ariaLabel="entity-config-display-name"
                                    value={entityConfig.displayName}
                                    onChange={onDisplayNameUpdated}
                                />
                            </div>
                        </div>
                        <div className="d-flex gap-2 ms-auto mb-auto">
                            <Dropdown
                                ariaLabel="entity-config-relationship-dropdown"
                                fixedSize={true}
                                options={relationshipOptions}
                                onOptionSelected={onRelationshipAdded}
                                selectedOption={undefined}
                                placeholder={
                                    <span className="d-flex align-items-center me-2">
                                        <i className="fal fa-plus fs-5 me-2" />
                                        Add Relationship
                                    </span>
                                }
                                textAlign="left"
                            />
                            <StandardButton
                                iconClasses="fal fa-trash-can"
                                label="Delete"
                                ariaLabel="delete-entity-config"
                                colour="red"
                                onClick={onDeleteEntityConfigClick}
                                disabled={entityConfig.displayName === ""}
                            />
                            <StandardButton
                                iconClasses="fal fa-clone"
                                label="Duplicate"
                                ariaLabel="duplicate-entity-config"
                                colour="blue"
                                onClick={onDuplicateEntityConfigClick}
                                disabled={entityConfig.displayName === ""}
                            />
                            <ProgressButton
                                iconClasses="fal fa-save"
                                label="Save"
                                colour="blue"
                                succeededText="Saved!"
                                failedText="Failed to save"
                                onClickWithPromise={saveEntityConfig}
                                disabled={entityConfig.displayName === ""}
                            />
                        </div>
                    </div>
                    <div className={`${classes.configContainer} pb-3 overflow-auto`}>
                        {relationshipsForThisEntity.map(relationship => (
                            <div key={relationship.id} className="d-flex mt-4">
                                <EntityRelationship
                                    entityConfigs={allEntityConfigs}
                                    relationship={relationship}
                                    onRelationshipUpdated={onRelationshipUpdated}
                                    onRelationshipDeleted={onRelationshipDeleted}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                {modularGridToggle.isEnabled ? (
                    <Grid reference={`entity-config_${entityConfig.reference}`} lookups={DefaultDataFieldLookups} ariaLabel="entity-config-data-fields">
                        <Grid.Header>
                            <Grid.Search autoApply={true} />
                            <BlueButton
                                className="ms-auto"
                                iconClasses="fa fa-plus text-white"
                                label="Add Data Field"
                                onClick={onAddNewDataField}
                                ariaLabel="show-add-data-field"
                            />
                            <Grid.GlobalActions />
                        </Grid.Header>
                        <Grid.DataTable data={dataFields} defaultHeaders={DefaultDataFieldHeaders} onRowsUpdated={onRowsUpdated}>
                            <Grid.Rows selectableRows={false} renderRowActions={renderRowActions} />
                        </Grid.DataTable>
                        <Grid.Footer>
                            <Grid.Pagination />
                        </Grid.Footer>
                    </Grid>
                ) : (
                    <EntityDataFieldsGridLegacy
                        entityConfig={entityConfig}
                        onAddNewEntityDataField={onAddNewDataField}
                        onEditEntityDataField={onEditEntityDataField}
                        onDeleteEntityDataField={onDeleteEntityDataField}
                    />
                )}
            </div>
            {showDataFieldForm && (
                <div key={entityDataField?.fieldName || ""}>
                    <EntityConfigDataFieldForm
                        allEntityConfigs={allEntityConfigs}
                        entityConfig={entityConfig}
                        entityDataField={entityDataField}
                        relationships={relationshipsForThisEntity}
                        isEditing={isEditingDataField}
                        lookups={lookups}
                        toggleIsShown={toggleShowDataFieldForm}
                        onSaveEntityDataField={onSaveEntityDataField}
                    />
                </div>
            )}
        </div>
    )
}

export default EntityConfigDetails

const mapEntityDataFieldToRowData = (field: EntityDataField): RowData => ({
    reference: field.fieldName,
    fields: {
        booleanFields: {
            isquickfilter: field.isQuickFilter
        },
        numberFields: {},
        textFields: {
            fieldname: field.fieldName,
            displayname: field.displayName,
            description: field.description,
            datatype: field.dataType.type === "RICH_TEXT" ? "LARGE_TEXT" : field.dataType.type,
            lookupreference: field.dataType.lookupReference ?? "",
            timezone: field.dataType.timeZone ?? "",
            currencyfield: field.dataType.currencyFieldReference ?? "",
            currencycode: field.dataType.currencyCode ?? "",
            defaultvalue: field.defaultValue ?? ""
        },
        dateFields: {}
    }
})

export const removeCalculationFromRelationships = (
    entityDataField: EntityDataField,
    entityConfig: EntityConfig,
    entityRelationships: EntityRelationshipType[],
    onEntityRelationshipsUpdated: Dispatch<SetStateAction<EntityRelationshipType[]>>
) => {
    const relevantRelationships = entityRelationships.filter(
        r =>
            (r.childEntityReference === entityConfig.reference && r.calculations.find(c => c.sourceFieldName === entityDataField.fieldName)) ||
            (r.parentEntityReference === entityConfig.reference && r.calculations.find(c => c.destinationFieldName === entityDataField.fieldName))
    )
    if (relevantRelationships.length > 0) {
        onEntityRelationshipsUpdated(relationships =>
            relationships.map(r =>
                relevantRelationships.includes(r)
                    ? {
                          ...r,
                          calculations: r.calculations.filter(c =>
                              r.childEntityReference === entityConfig.reference
                                  ? c.sourceFieldName !== entityDataField.fieldName
                                  : c.destinationFieldName !== entityDataField.fieldName
                          )
                      }
                    : r
            )
        )
    }
}
