export const gridColConfig = (state, gridId) => {
    if (
      state &&
      state.uiConfigReducer &&
      state.uiConfigReducer.configs &&
      state.uiConfigReducer.configs[gridId]
    ) {
      return state.uiConfigReducer.configs[gridId];
    }
    return null;
  };
  