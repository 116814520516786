import { ReactNode, createContext, useContext, useState } from "react"
import { EntityFields, convertDataFieldsToDtoFields } from "../types/EntityData"
import { useSelectedEntities } from "./SelectedEntitiesContext"

export type Entity = {
    entityTypeReference?: string
    reference: string
    displayName?: string
    sqlId?: string
    entityType?: string
    entityFields?: EntityFields
    legacyChildren?: string[]
    selectedChildEntities?: {
        entityType: string
        references: string[]
    }[]
}

const defaultEntity: Entity = {
    entityTypeReference: undefined,
    reference: "",
    displayName: undefined,
    sqlId: undefined,
    entityType: undefined,
    entityFields: undefined,
    selectedChildEntities: []
}

const EntityContext = createContext<[Entity, (entity: Entity) => void, () => void] | undefined>(undefined)

type EntityProviderProps = { children: ReactNode }

export const EntityProvider = ({ children }: EntityProviderProps) => {
    const [entity, setEntity] = useState(defaultEntity)
    const { setSelectedEntity } = useSelectedEntities()

    const onEntityUpdated = (newEntity: Entity) => {
        setSelectedEntity({
            reference: newEntity.reference ?? entity.reference,
            entityTypeReference: newEntity.entityType ?? newEntity.entityTypeReference ?? entity.entityType ?? entity.entityTypeReference ?? "",
            entityFields: convertDataFieldsToDtoFields(newEntity.entityFields ?? entity.entityFields)
        })
        if ((newEntity.reference && entity.reference !== newEntity.reference) || (newEntity.entityType && newEntity.entityType !== entity.entityType)) {
            setEntity({
                ...defaultEntity,
                ...newEntity
            })
        } else {
            setEntity(existingEntity => {
                return {
                    ...defaultEntity,
                    ...existingEntity,
                    ...newEntity
                }
            })
        }
    }

    const clearEntity = () => {
        setEntity(defaultEntity)
        setSelectedEntity(undefined)
    }

    return <EntityContext.Provider value={[entity, onEntityUpdated, clearEntity]}>{children}</EntityContext.Provider>
}

export const useEntity = () => {
    const context = useContext(EntityContext)

    if (!context) {
        throw new Error(`useEntity must be used within a <EntityProvider />`)
    }
    return context
}
