import classes from "./CustomerFilters.module.scss"
import { Toggle } from "invevo-react-components"
import BlueButton from "../../../library/buttons/BlueButton/BlueButton"
import Lookup from "../../../types/Lookup"
import QuickFilter from "../../automate/types/QuickFilter"
import { CustomerSearchAction, CustomerSearchActionEnum } from "../reducers/CustomerSearchReducer"

type CustomerFiltersProps = {
    lookups: Lookup[]
    selectedQuickFilters: QuickFilter[]
    showQuickFilters: boolean
    dispatch: React.Dispatch<CustomerSearchAction>
    isLoadingCustomerData: boolean
}

const CustomerFilters = ({ lookups, selectedQuickFilters, showQuickFilters, dispatch, isLoadingCustomerData }: CustomerFiltersProps) => {
    const removeQuickFilter = (quickFilter: QuickFilter) => {
        if (isLoadingCustomerData) return

        dispatch({ type: CustomerSearchActionEnum.REMOVE_QUICK_FILTER, removedQuickFilter: quickFilter, selectedQuickFilters })
    }

    const getDisplayNameForLookup = (lookupReference: string | undefined, label: string): string =>
        lookups.find(l => l.reference === lookupReference)?.entries.find(e => e.reference.toLowerCase() === label?.toLowerCase())?.name ?? label

    const toggleShowQuickFilters = () => dispatch({ type: CustomerSearchActionEnum.TOGGLE_SHOW_QUICK_FILTERS })

    const clearFilters = () => dispatch({ type: CustomerSearchActionEnum.CLEAR_QUICK_FILTERS })

    return (
        <div className={`w-100 rounded-top d-flex align-items-start p-1 bg-blue ${classes.container}`}>
            <div className="d-flex align-items-center p-2 w-100">
                <span className="text-uppercase text-white me-2 no-select">Filters</span>
                <i className="text-white fal fa-filter"></i>
                {selectedQuickFilters &&
                    selectedQuickFilters.map(quickFilter => (
                        <div className={`ms-2 ${classes.selectedFilter} px-2 py-1 d-flex align-items-center`} key={quickFilter.filter.label}>
                            <span>
                                {quickFilter.field.label} | {getDisplayNameForLookup(quickFilter.field.lookup, quickFilter.filter.label)}
                                <i
                                    className="ms-2 fa-light fa-times-circle pointer"
                                    role="button"
                                    aria-label={"remove-filter-" + quickFilter.filter.label}
                                    onClick={() => removeQuickFilter(quickFilter)}
                                />
                            </span>
                        </div>
                    ))}
                {selectedQuickFilters.length > 0 && (
                    <BlueButton
                        className={`ms-auto ${classes.clearFiltersButton}`}
                        label="Clear filters"
                        onClick={clearFilters}
                        ariaLabel="clear-filters-button"
                    />
                )}
                <div className={`${selectedQuickFilters.length > 0 ? "ms-2" : "ms-auto"} d-flex align-items-center py-1 px-2 text-white`}>
                    <Toggle status={showQuickFilters} onStatusChanged={toggleShowQuickFilters} ariaLabel="show-quick-filters" />
                    <span className="ms-2">Show Quick Filters</span>
                </div>
            </div>
        </div>
    )
}

export default CustomerFilters
