import { actionTypes } from "./actionTypes"

export default function homeReducer(state, action) {
    switch (action.type) {
        case actionTypes.MENU_ITEM_CLICKED:
            return {
                ...state,
                activeMenuItem: action.menuItem
            }
        // case actionTypes.CUSTOMER_CREDIT_REPORT_RETRIEVED:

        // return{
        //     ...state,
        //     creditReport
        // }

        default:
            throw new Error(`Invalid reducer action: ${action.type}`)
    }
}