enum FilterTypesByField {
    NO_TYPE = 0,
    TEXT_FIELD_IS_ONE_OF = 1,
    TEXT_FIELD_STARTS_WITH = 2,
    LOOKUP_FIELD_IS_ONE_OF = 3,
    BOOLEAN_FIELD_IS_EQUAL_TO = 4,
    NUMBER_FIELD_IS_ONE_OF = 5,
    NUMBER_FIELD_MATCHES_OPERATION = 6,
    DATE_FIELD_IS_ONE_OF = 7,
    DATE_FIELD_MATCHES_OPERATION = 8,
    FIELD_EXISTS = 9,
    DATE_FIELD_RELATIVE = 10,
    TEXT_FIELD_IS_ONE_OF_FIELD = 11,
    NUMBER_FIELD_IS_ONE_OF_FIELD = 12,
    DATE_FIELD_IS_ONE_OF_FIELD = 13,
    BOOLEAN_FIELD_IS_ONE_OF_FIELD = 14,
    LOOKUP_FIELD_IS_ONE_OF_FIELD = 15,
}

export default FilterTypesByField
