import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'
import TaskCompletionSelect from './taskCompletionSelect'
import { executeAuthAsyncPost } from '../../utility/asyncSupport'
import UserTaskDate from './userTaskDate'
import StateTaskTypesSelect from './stateTaskTypesSelect'
import UserTaskBulkResult from './userTaskBulkResult'
import Select from 'react-select';
import { optionStyles } from '../../utility/selectSupport';
import { find } from 'lodash';
import usePrevious from '../../hooks/usePrevious';
import { getCustomerTaskGrid } from '../../selectors/task';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Loading, useClient } from 'invevo-react-components'

const allTaskTypeIdsSelector = createSelector(
    (state) => state.gridCacheReducer.grids,
    (grids) => {
        const gridCache = getCustomerTaskGrid(grids)

        if (!gridCache || !gridCache.lastResponse) return []

        return gridCache.lastResponse.data.map(task => task.TaskTypeId).filter((value, index, self) => self.indexOf(value) === index)
    }
)

const UserTaskControl = ({
    className,
    tickedTaskIds = [],
    taskTypeId,
    setRefresh,
    refresh,
    setTickedTaskIds,
    taskTypes,
    onChangeTaskTypesFilter,
    selectedTaskType,
    customerTask,
    statusFilter,
    showingFilters,
    setShowingFilters,
    setAllTickBoxTicked
}) => {
    const client = useClient()

    const [taskDate, setTaskDate] = useState()
    const [taskCompletionId, setTaskCompletionId] = useState()
    const [taskNotes, setTaskNotes] = useState("")

    const [isUpdating, setIsUpdating] = useState(false)
    const [bulkUpdateResult, setBulkUpdateResult] = useState([])

    const visibleTaskTypeIds = useSelector(allTaskTypeIdsSelector)
    const [allTaskTypeIds, setAllTaskTypeIds] = useState([])

    useEffect(() => {
        // If filtered, dont reset filter types
        if ((taskTypeId !== 0 || selectedTaskType !== 0) && allTaskTypeIds.length !== 0) return
        setAllTaskTypeIds(visibleTaskTypeIds)
    }, [taskTypeId, selectedTaskType, allTaskTypeIds.length, visibleTaskTypeIds, statusFilter])

    const dispatch = useDispatch()

    const prevTickedTaskIds = usePrevious(tickedTaskIds)
    const prevShowFilterPage = usePrevious(showingFilters)

    useEffect(() => {
        if (!prevTickedTaskIds) return

        if (showingFilters !== prevShowFilterPage) {
            if (showingFilters) {
                tickedTaskIds.length > 0 &&
                    tickedTaskIds.forEach(id =>
                        dispatch(
                            executeAuthAsyncPost(
                                client,
                                'task/releaseownership',
                                'task_releaseownership',
                                { taskId: id }
                            )))
            } else {
                tickedTaskIds.length > 0 &&
                    tickedTaskIds.forEach(id =>
                        dispatch(executeAuthAsyncPost(
                            client,
                            'task/setownership',
                            'task_setownership',
                            { taskId: id },
                            () => { },
                            () => { }
                        )))
            }
        }

        const addOwnership = !showingFilters && tickedTaskIds.filter(t => !prevTickedTaskIds.includes(t))
        addOwnership.length > 0 &&
            addOwnership.forEach(id =>
                dispatch(executeAuthAsyncPost(
                    client,
                    'task/setownership',
                    'task_setownership',
                    { taskId: id },
                    () => { },
                    () => { }
                )))

        const removeOwnership = !showingFilters && prevTickedTaskIds.filter(t => !tickedTaskIds.includes(t))
        removeOwnership.length > 0 &&
            removeOwnership.forEach(id =>
                dispatch(executeAuthAsyncPost(
                    client,
                    'task/releaseownership',
                    'task_releaseownership',
                    { taskId: id }
                )))


    }, [showingFilters, tickedTaskIds, prevShowFilterPage, prevTickedTaskIds, dispatch, client])


    const onSaveTasks = () => {
        setIsUpdating(true)

        let payload = {
            "ids": tickedTaskIds,
            "taskNotes": ""
        }

        if (taskDate) {
            payload.taskDate = taskDate
        }
        if (taskCompletionId) {
            payload.taskCompletionId = taskCompletionId
        }
        if (taskNotes) {
            payload.taskNotes = taskNotes
        }

        dispatch(
            executeAuthAsyncPost(
                client,
                'task/updatebulk',
                'CREATE_TASK',
                payload,
                result => {
                    setBulkUpdateResult(tickedTaskIds.map(taskId => {
                        if (result.tasksUpdated.includes(taskId)) {
                            return { taskId: taskId, success: true }
                        }
                        if (result.ownedByOtherUsers.includes(taskId)) {
                            return { taskId: taskId, success: false, message: "Owned by another user" }
                        }
                        return { taskId: taskId, success: false, message: "Failed to update task" }
                    }))
                    setRefresh(!refresh)
                    resetState()
                },
                ({ message } = {}) => {
                    setBulkUpdateResult(tickedTaskIds.map(taskId => {
                        return { taskId: taskId, success: false, message: message }
                    }))
                    resetState()
                }
            )
        )
    }

    const resetState = useCallback(() => {
        setIsUpdating(false)
        setTaskDate()
        setTaskCompletionId()
        setTaskNotes("")
        setTickedTaskIds([])
        setAllTickBoxTicked(false)
    }, [setTickedTaskIds, setAllTickBoxTicked])

    useEffect(() => resetState(), [taskTypeId, setTickedTaskIds, resetState, selectedTaskType])

    const convertTaskTypesToStatefulTypes = taskTypes => {
        let currentId = 0
        const result = taskTypes.filter(type => allTaskTypeIds.includes(type.id)).map(type => {
            return {
                $id: currentId++,
                value: type.id,
                label: type.text
            }
        })
        return [{ $id: 0, value: 0, label: "All Task Types" }, ...result]
    }
    const hasNoTaskType = customerTask ? (selectedTaskType === 0 || selectedTaskType === undefined) : (taskTypeId === 0 || taskTypeId === null)

    const isSaveDisabled = tickedTaskIds.length === 0 || (!taskDate && !taskCompletionId && taskNotes === "")

    return (
        <Loading isLoading={isUpdating} colour="blue">
            <div className={className} style={{ "backgroundColor": showingFilters || hasNoTaskType ? "#ffffff" : "#0096d9", "width": "350px" }}>

                {bulkUpdateResult.length !== 0 ?
                    <UserTaskBulkResult results={bulkUpdateResult} onClose={() => setBulkUpdateResult([])} /> :
                    <>
                        <div className="row top10 justify-content-center">
                            <div className="btn-group m-3 w-100">
                                <button
                                    className={`py-2 
                                    ${showingFilters
                                            ? "btn btn-primary-invevo"
                                            : hasNoTaskType ? "btn btn-default-invevo" : "btn btn-default text-white border border-white"}`
                                    }
                                    onClick={() => setShowingFilters(true)}
                                    title={"Task Filters"}
                                >
                                    Task Filters
                                </button>
                                <button
                                    className={
                                        showingFilters
                                            ? "btn btn-default-invevo"
                                            : hasNoTaskType ? "btn btn-primary-invevo" : "btn btn-light border border-white"
                                    }
                                    onClick={() => setShowingFilters(false)}
                                    title={'Bulk Actions'}
                                >
                                    Bulk Actions
                                </button>
                            </div>
                        </div>
                        {showingFilters ?
                            <div>
                                {!customerTask &&
                                    <div className="row top10">
                                        <div className="col-12">
                                            <UserTaskDate invevoColourScheme={true} />
                                        </div>
                                    </div>}
                                <div className="row top10 mb-3">
                                    <div className="col-12">
                                        {onChangeTaskTypesFilter && taskTypes
                                            ? <Select
                                                value={find(convertTaskTypesToStatefulTypes(taskTypes), (o) => o.value === selectedTaskType) || null}
                                                options={convertTaskTypesToStatefulTypes(taskTypes)}
                                                onChange={taskType => onChangeTaskTypesFilter(taskType.value)}
                                                placeholder={'Filter by Task Type'}
                                                styles={{ option: optionStyles }}
                                                isMulti={false} />
                                            : <StateTaskTypesSelect />}
                                    </div>
                                </div>
                            </div> :
                            hasNoTaskType ?
                                <div className="d-flex flex-column align-items-center m-3">
                                    <i className="fal fa-exclamation-triangle fa-4x text-light-grey mb-1" />
                                    <span className="text-grey text-center pt-3">Please filter tasks by task type to perform bulk actions</span>
                                </div> :
                                <div>
                                    <div className="row top10 text-white m-2">
                                        {tickedTaskIds.length} Task{tickedTaskIds.length === 1 ? "" : "s"} selected
                                    </div>
                                    <div className="row top10 m-2">
                                        <div className="form-group mb-0 w-100">
                                            <h6 className="text-white">DATE</h6>
                                            <DatePicker
                                                name="taskDate"
                                                selected={taskDate}
                                                className="form-control w-100"
                                                dateFormat="dd MMMM yyyy"
                                                popperPlacement="bottom-end"
                                                style={{ width: '100%' }}
                                                onChange={newTaskDate => setTaskDate(newTaskDate)}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row top10 m-2">
                                        <div className="form-group mb-0 w-100">
                                            <h6 className="text-white">TASK COMPLETION</h6>
                                            <TaskCompletionSelect
                                                id={taskCompletionId}
                                                onChange={newTaskCompletionId => { setTaskCompletionId(newTaskCompletionId) }}
                                                taskTypeId={customerTask ? selectedTaskType : taskTypeId}
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row top10 m-2">
                                        <h6 className="text-white">ADD COMMENT</h6>
                                        <textarea
                                            name="taskNotes"
                                            className="w-100 rounded"
                                            style={{ border: 'none', resize: 'none' }}
                                            onChange={event => setTaskNotes(event.target.value)}
                                            maxLength="4000"
                                        />
                                    </div>
                                    <div className="row m-2 pt-3">
                                        <button type="button" onClick={onSaveTasks} disabled={isSaveDisabled} className={`button-invevo pointer w-100 py-1 text-white ${isSaveDisabled ? "disabled" : ""}`}>
                                            Save
                                        </button>
                                    </div>
                                </div>
                        }
                    </>
                }
            </div>
        </Loading>
    )
}


const mapStateToProps = (state) => {
    return {
        taskTypeId: state.taskReducer.userTaskList.taskTypeId
    }
}

export default connect(mapStateToProps)(UserTaskControl)