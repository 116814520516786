export enum widgetThemes {
    DEFAULT = 'DEFAULT',
    BLUE = 'BLUE'
}

const getLabelForWidgetTheme = (theme: widgetThemes) => {
    switch (theme) {
        case widgetThemes.DEFAULT:
            return "Default"

        case widgetThemes.BLUE:
            return "Blue"

        default:
            return "Default"
    }
}
export type WidgetThemeState = {
    id: number
    value: widgetThemes
    label: string
}
export const widgetThemeDropdownOptions = Object.entries(widgetThemes).map(([, v], index) => ({ id: index, value: v, label: getLabelForWidgetTheme(v) })) as WidgetThemeState[]