import { useFeatureToggle } from "../../../hooks/useFeatureToggle"
import WorkflowsDashboardLegacy from "../../../microfrontends/automateLegacy/components/dashboards/WorkflowsDashboard"
import Automate from "../../../routes/automate/components/Automate"

const AutomateWrapper = () => {
    const { isEnabled: automateUseNewFilters } = useFeatureToggle("automateUseNewFilters")

    return <div className="d-flex w-100 h-100">{automateUseNewFilters ? <Automate /> : <WorkflowsDashboardLegacy />}</div>
}

export default AutomateWrapper
