// Credit Limit Request Type
export const creditLimitRequestTypes = [
  { value: 1, label: 'Credit Limit' },
  { value: 2, label: 'Temporary Credit Limit' }
];
export const creditLimitRequestTypeNames = {
  CREDIT_LIMIT: 1,
  TEMPORARY_CREDIT_LIMIT: 2
}; // Keep aligned with the corresponding server-side definition

// Credit Limit Decision (manually sorted)
export const creditLimitDecisions = [
  { value: 1, label: 'Approved', Approved: true },
  { value: 0, label: 'Declined', Approved: false }
];
export const creditLimitDecisionNames = {
  DECLINED: 0,
  APPROVED: 1
}; // Keep aligned with the corresponding server-side definition

export const CustomerCreditLimitStatus = {
  NO_PENDING: 0,
  PENDING_FIRST_DECISION: 1,
  PENDING_SECOND_DECISION: 2
};

export const CustomerCreditLimitStatusNames = {
  NO_PENDING: 'NO_PENDING',
  PENDING_FIRST_DECISION_CAN_APPROVE: 'PENDING_FIRST_DECISION_CAN_APPROVE',
  PENDING_FIRST_DECISION_CANNOT_APPROVE:
    'PENDING_FIRST_DECISION_CANNOT_APPROVE',
  PENDING_SECOND_DECISION_CAN_APPROVE: 'PENDING_SECOND_DECISION_CAN_APPROVE',
  PENDING_SECOND_DECISION_CANNOT_APPROVE:
    'PENDING_SECOND_DECISION_CANNOT_APPROVE'
};

export const SearchTypeForCustomerOrVirtualAccount = {
  FORCUSTOMER: 1,
  FORVIRTUALACCOUNT: 2
};
