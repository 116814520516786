import classes from "./DataRowField.module.scss"

type FailedFieldProps = {
    fieldName: string
    value: string
}

const FailedField = ({ fieldName, value }: FailedFieldProps) => {
    return (
        <div className="d-flex w-100 mt-2">
            <div className="d-flex flex-column text-red w-25">
                <div className="d-flex ">
                    <i className="fal fa-pen-field me-1 mb-1 fs-5" />
                    <span className=" d-flex text-black fw-bold small me-1 text-capitalize">Field Name</span>
                </div>
                <span className={`${classes.field} ps-2 py-1`}> {fieldName}</span>
            </div>
            <div className="d-flex flex-column w-100 text-red ms-3">
                <div className="d-flex">
                    <i className="fal fa-exclamation-triangle me-1 mb-1 fs-5" />
                    <span className="text-black fw-bold small me-1 text-capitalize">Failure Reason</span>
                </div>
                <span className={`${classes.field} w-100 ps-2 py-1`}> {value}</span>
            </div>
        </div>
    )
}

export default FailedField
