import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

export const ConfrimDialog = ({ show, message, onConfirm, onClose }) => {
  const [submitted, setSubmitted] = useState(false);

  const handleOnClick = () => {
    if (!submitted) {
      onConfirm();
      setSubmitted(true);
    }
  };

  useEffect(() => {
    if (show) {
      setSubmitted(false);
    }
  }, [show]);

  //TODO Fix styling 04/05/22
  return (
    <>
      {show && (
        <Dialog onClose={onClose}>
          <div style={{ margin: '25px', textAlign: 'center' }}>{message}</div>
          <DialogActionsBar>
            <button
              className="k-button btn-primary"
              onClick={handleOnClick}
              disabled={submitted}
            >
              OK
            </button>
            <button className="k-button" onClick={onClose} disabled={submitted}>
              Cancel
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

ConfrimDialog.defaultProps = {
  onConfirm: () => { }
};
ConfrimDialog.propTypes = {
  onConfirm: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired
  ])
};
