import { genericActionsResetToInitialState } from "../actions/genericActions";
import { refreshUser } from "./loginApi";
import { authReducerStates } from "../reducers/auth_reducer";
import { showToastErrorMessage } from "./toasterApi";

export const webApiInterface = {
  abortHandler: (err) => {
    if (err.name !== 'AbortError') {
      throw err;
    }
  },
  setUseLocalStorage(useLocal = false) {
    // this is set via feature switch on start - we cannot access feature switch directly as needed outside of app context
    const currentUseLocal = this.useLocalStorage();
    const switchingFromSessionToLocal =
      currentUseLocal === false && useLocal === true;
    const switchingFromLocalToSession =
      currentUseLocal === true && useLocal === false;

    if (switchingFromSessionToLocal) {
      // move from session to local for first time so copy
      localStorage.setItem('_token', sessionStorage.getItem('_token'));
      localStorage.setItem('_username', sessionStorage.getItem('_username'));
      localStorage.setItem('_refresh', sessionStorage.getItem('_refresh'));

      localStorage.setItem('_useLocal', useLocal);

      // tidy up unused session vars
      sessionStorage.removeItem('_token');
      sessionStorage.removeItem('_username');
      sessionStorage.removeItem('_refresh');
    } else if (switchingFromLocalToSession) {
      sessionStorage.setItem('_token', localStorage.getItem('_token'));
      sessionStorage.setItem('_username', localStorage.getItem('_username'));
      sessionStorage.setItem('_refresh', localStorage.getItem('_refresh'));

      localStorage.setItem('_useLocal', useLocal);

      // tidy up unused local vars
      localStorage.removeItem('_token');
      localStorage.removeItem('_username');
      localStorage.removeItem('_refresh');
    }
  },
  useLocalStorage() {
    return localStorage.getItem('_useLocal') === 'true';
  },
  token: function () {
    if (this.useLocalStorage()) {
      return localStorage.getItem('_token');
    } else {
      return sessionStorage.getItem('_token');
    }
  },
  username: function () {
    if (this.useLocalStorage()) {
      return localStorage.getItem('_username');
    } else {
      return sessionStorage.getItem('_username');
    }
  },
  refresh: function () {
    if (this.useLocalStorage()) {
      return localStorage.getItem('_refresh');
    } else {
      return sessionStorage.getItem('_refresh');
    }
  },
  saveSecurity: function (token, userName, refresh) {
    if (this.useLocalStorage()) {
      localStorage.setItem('_token', token);
      localStorage.setItem('_username', userName);
      localStorage.setItem('_refresh', refresh);
    } else {
      sessionStorage.setItem('_token', token);
      sessionStorage.setItem('_username', userName);
      sessionStorage.setItem('_refresh', refresh);
    }
  },
  applySecurity: function (request) {
    request.setRequestHeader('Authorization', 'Bearer ' + this.token());
  },
  refreshPromise: null,
  authFetch2: function (
    client,
    url,
    dispatch,
    init,
    resolve,
    reject,
    rejectOnlyInternalServerErrors = false
  ) {
    if (!client) {
      throw Error("client not provided in authFetch2")
    }
    const that = this;
    fetch(url, init)
      .then(function (response) {
        if (!response.ok) {
          if (response.status === 401) {
            if (that.refreshPromise == null) {
              that.refreshPromise = refreshUser(client);
            }
            that.refreshPromise
              .then(function () {
                const token = 'Bearer ' + that.token();
                init.headers.set('Authorization', token);
                fetch(url, init)
                  .then(function (response) {
                    if (!response.ok) {
                      if (response.status === 401) {
                        showToastErrorMessage(
                          'Session has timed out: please log in again.',
                          { toastId: 'session-timeout' }
                        );
                        dispatch(genericActionsResetToInitialState());
                        dispatch(authReducerStates.UNAUTHENTICATED.action());
                      } else {
                        if (
                          rejectOnlyInternalServerErrors &&
                          response.status !== 500
                        ) {
                          resolve(response);
                        } else {
                          reject(response);
                        }
                      }
                    } else {
                      resolve(response);
                    }
                    that.refreshPromise = null;
                  })
                  .catch(that.abortHandler)
                  .catch(function (error) {
                    reject(error);
                    that.refreshPromise = null;
                  });
              })
              .catch(that.abortHandler)
              .catch(function () {
                if (that.token() !== null) {
                  showToastErrorMessage(
                    'Session has timed out: please log in again.',
                    { toastId: 'session-timeout' }
                  );
                }
                dispatch(genericActionsResetToInitialState());
                dispatch(authReducerStates.UNAUTHENTICATED.action());
                that.refreshPromise = null;
              });
          } else {
            if (rejectOnlyInternalServerErrors && response.status !== 500) {
              resolve(response);
            } else {
              reject(response);
            }
          }
        } else {
          resolve(response);
        }
      })
      .catch(that.abortHandler);
  },
  authFetch: function (client, url, dispatch, signal = null) {
    if (!client) {
      throw Error("client not provided in authFetch")
    }
    const that = this;
    const token = 'Bearer ' + this.token();

    const h = new Headers();
    h.append('Content-Type', 'application/json');
    h.append('Authorization', token);

    const init = {
      method: 'GET',
      headers: h,
      cache: 'default'
    };

    if (signal) init.signal = signal;

    return new Promise(function (resolve, reject) {
      that.authFetch2(client, url, dispatch, init, resolve, reject);
    });
  },
  authPost: function (client, url, dispatch, data, method = 'POST', signal = null) {
    if (!client) {
      throw Error("client not provided in authPost")
    }
    const that = this;
    const token = 'Bearer ' + this.token();
    const h = new Headers();
    h.append('Content-Type', 'application/json');
    h.append('Authorization', token);

    const init = {
      method,
      headers: h,
      cache: 'default',
      body: JSON.stringify(data)
    };

    if (signal) init.signal = signal;

    return new Promise(function (resolve, reject) {
      that.authFetch2(client, url, dispatch, init, resolve, reject);
    });
  },
  authPost2: function (url, dispatch, data) {
    const that = this;
    const token = 'Bearer ' + this.token();
    const h = new Headers();
    h.append('Content-Type', 'application/json');
    h.append('Authorization', token);
    const init = {
      method: 'POST',
      headers: h,
      cache: 'default',
      body: JSON.stringify(data)
    };
    return new Promise(function (resolve, reject) {
      that.authFetch2(url, dispatch, init, resolve, reject, true);
    });
  },
  authDelete: function (client, url, dispatch, data) {
    const that = this;
    const token = 'Bearer ' + this.token();
    const h = new Headers();
    h.append('Content-Type', 'application/json');
    h.append('Authorization', token);
    const init = {
      method: 'DELETE',
      headers: h,
      cache: 'default',
      body: JSON.stringify(data)
    };
    return new Promise(function (resolve, reject) {
      that.authFetch2(client, url, dispatch, init, resolve, reject);
    });
  }
};
