export const getReportDetails = () => ({
    type: 'GET_REPORT_DETAILS'
  });

export const receiveReportDetails = data => ({
    type: 'RECEIVE_REPORT_DETAILS',
    data
});

export const receiveReportDetailsError = err => ({
    type: 'RECEIVE_REPORT_DETAILS_ERROR',
    err
});