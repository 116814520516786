import axios, { Method, AxiosError, ResponseType } from "axios"
import { useCallback, useEffect, useRef, useState } from "react"
import { useAuth } from "../contexts/AuthContext"

type useApiQueryProps<T> = {
    url: string
    method: Method
    dto?: unknown
    headers?: any
    isAnonymous?: boolean
    isExecutedAutomatically?: boolean
    onSuccess?: (data: T, headers?: Record<string, string>) => void
    onError?: (error: AxiosError) => void
    returnHeaders?: boolean
    responseType?: ResponseType
}

const useApiQuery = <T>(props: useApiQueryProps<T>) => {
    const { getTokenF } = useAuth()

    const [lastFetchedUrl, setLastFetchedUrl] = useState<string>()
    const [lastFetchedDto, setLastFetchedDto] = useState<unknown>(undefined)
    const [data, setData] = useState<T>()
    const [hasErrored, setHasErrored] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)

    const canRun = props.isExecutedAutomatically ?? true
    const [couldRun, setCouldRun] = useState(canRun)

    const mounted = useRef(false)

    useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const callApi = useCallback(
        (reference: string | undefined = undefined, dto: unknown = props.dto) => {
            setIsFetching(true)
            setHasErrored(false)
            setHasFetched(false)
            setLastFetchedUrl(props.url)
            setLastFetchedDto(dto)

            if (props.isAnonymous ?? false) {
                return axios
                    .request<T>({
                        url: reference ? `${props.url}/${reference}` : props.url,
                        method: props.method,
                        data: dto
                    })
                    .then(res => {
                        if (props.onSuccess) {
                            props.onSuccess(res.data)
                        }

                        if (!mounted.current) {
                            return res
                        }

                        setHasFetched(true)
                        setIsFetching(false)
                        setData(res.data)

                        return res
                    })
                    .catch(error => {
                        console.error(error)
                        setHasErrored(true)
                        setHasFetched(true)
                        setIsFetching(false)

                        if (props.onError) {
                            props.onError(error)
                        }
                        throw error
                    })
            } else {
                return getTokenF().then(token => {
                    const headers = {
                        Authorization: `Bearer ${token}`,
                        ...props.headers
                    }
                    return axios
                        .request<T>({
                            url: reference ? `${props.url}/${reference}` : props.url,
                            method: props.method,
                            headers,
                            data: dto,
                            responseType: props.responseType as ResponseType
                        })
                        .then(res => {
                            if (props.onSuccess && props.returnHeaders) {
                                props.onSuccess(res.data, res.headers)
                            } else if (props.onSuccess) {
                                props.onSuccess(res.data)
                            }

                            if (!mounted.current) {
                                return res
                            }

                            setHasFetched(true)
                            setIsFetching(false)
                            setData(res.data)

                            return res
                        })
                        .catch(error => {
                            console.error(error)
                            setHasErrored(true)
                            setHasFetched(true)
                            setIsFetching(false)

                            if (props.onError) {
                                props.onError(error)
                            }
                            throw error
                        })
                })
            }
        },
        [getTokenF, props]
    )

    useEffect(() => {
        if (!couldRun && canRun) {
            setLastFetchedUrl(undefined)
            setData(undefined)
            setIsFetching(false)
            setHasErrored(false)
            setHasFetched(false)
        }
        setCouldRun(canRun)
    }, [canRun, couldRun])

    useEffect(() => {
        const hasUrlChanged = lastFetchedUrl !== props.url
        const hasDtoChanged = JSON.stringify(lastFetchedDto) !== JSON.stringify(props.dto)

        if (!hasUrlChanged && !hasDtoChanged && (hasErrored || isFetching || hasFetched)) return

        if (!canRun) return

        callApi(undefined, props.dto).catch(() => {})
    }, [callApi, canRun, hasErrored, hasFetched, isFetching, lastFetchedDto, lastFetchedUrl, props.dto, props.url])

    return { data, isFetching, hasErrored, hasFetched, execute: callApi }
}

export default useApiQuery
