export enum dashboardTypes {
    ENTITY = "ENTITY",
    AGGREGATED = "AGGREGATED"
}

export enum dashboardSubTypes {
    SUMMARY = "SUMMARY",
    CONTACTS = "CONTACTS",
    FILES = "FILES",
    TRANSACTIONS = "TRANSACTIONS",
    NONE = "NONE"
}