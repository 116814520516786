import { getApiUrl } from '../constants';
import { webApiInterface } from './webApiInterface';
import { getLookup } from './lookupApi'
import { selectTable } from '../actions/mappingActions'
import displayError from '../utility/error'
import { showToastSuccessMessage } from './toasterApi'

export const deleteMappingTable = (tableId, client) => {
  return (dispatch) => {
    webApiInterface.authPost(client, getApiUrl(client) + 'api/mapping/deletetable?tableId=' + tableId, dispatch)
      .then((response) => {
        return response.json();
      }).then(() => {
        showToastSuccessMessage('Table deleted.');
        dispatch(selectTable(-1));
        dispatch(getLookup(client, 'mapping/tablenames', 'mappingTableNames'));
      }).catch((err) => {
        displayError(err);
      })
  };
};
