import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AccountCard from './accountCard';
import AccountDetail from './accountDetail';
import { useInternationalization } from '@progress/kendo-react-intl';
import {
  executeAuthAsyncGet,
  executeAsyncResetGet
} from '../../utility/asyncSupport';
import moment from 'moment';
import { getCustomerTransactionsIds } from '../../api/functionApi';
import { selectTransaction } from '../../actions/functionActions';
import { useClient } from 'invevo-react-components';

function getHighlight(amount) {
  if (amount > 0) return 'negitive';
  if (amount < 0) return 'positive';
  return 'neutral';
}

const FallingDueCard = ({
  title,
  accountId,
  isVirtualAccount,
  open,
  setOpen,
  currencyFormat,
  dateFormat,
  fallingDue,
  executeAuthAsyncGet,
  executeAsyncResetGet,
  goToTransactions
}) => {
  const client = useClient()
  const internationalization = useInternationalization();

  useEffect(() => {
    const controller = new AbortController();
    if (isVirtualAccount) return;

    executeAuthAsyncGet(
      client, 
      'panelcontent/GetFallingDueByMonthPanelData',
      'FALLING_DUE',
      {
        customerId: accountId
      },
      null,
      null,
      controller.signal
    );

    return () => {
      if (isVirtualAccount) return;
      controller.abort();
      executeAsyncResetGet('FALLING_DUE');
    };
  }, [accountId, isVirtualAccount, executeAuthAsyncGet, executeAsyncResetGet, client]);

  if (isVirtualAccount) return null;

  let content = (
    <AccountDetail key="recent-payments-loading" style={{ minHeight: '161px' }}>
      Loading...
    </AccountDetail>
  );
  if (fallingDue.isFetched && fallingDue.result && fallingDue.result.length > 0) {
    content = fallingDue.result
      .filter((d) => d.currency !== 'ALL')
      .map((d) => {
        const title = moment(d.text, 'YYYY-MM-DD').format('MMM YY');
        const value = `${d.currency} ${internationalization.formatNumber(
          d.amount,
          currencyFormat
        )}`;

        return (
          <AccountDetail
            key={`falling-due-${d.id}`}
            title={title}
            value={value}
            highlight={getHighlight(d.amount)}
            onClick={() =>
              goToTransactions(client, accountId, isVirtualAccount, d.currency, d.group)
            }
          />
        );
      });
  } else if (fallingDue.isFetched && fallingDue.result && fallingDue.result.length === 0) {
    content = <AccountDetail title="None" key="falling-due-none" />;
  }

  return (
    <AccountCard title={title} open={open} setOpen={setOpen}>
      {content}
    </AccountCard>
  );
};

const mapStateToProps = (state) => ({
  currencyFormat: state.lookupReducer.globalSettings.results.currencyFormat,
  dateFormat: state.lookupReducer.globalSettings.results.dateFormat,
  fallingDue: state.asyncReducer.FALLING_DUE
});

export default connect(mapStateToProps, {
  executeAuthAsyncGet,
  executeAsyncResetGet,
  goToTransactions: (client, customerid, isVirtualAccount, currency, dateType) => (
    dispatch
  ) => {
    dispatch(selectTransaction([]));
    dispatch(
      getCustomerTransactionsIds(client, {
        customerid,
        isVirtualAccount,
        gridType: 2,
        isClosed: false,
        currency,
        dateType
      })
    );
  }
})(FallingDueCard);
