import React from "react"

export function CommandCell({ cssTag, buttonText, buttonTextFn = null, onCommandClick, ariaLabel = "", showFn = () => true }) {
    return props => {
        const { dataItem } = props

        let btnText = buttonText
        if (buttonTextFn !== null) btnText = buttonTextFn(dataItem)

        //TODO Fix button styling 04/05/22
        return (
            <td className="k-command-cell">
                {showFn(dataItem) && (
                    <button className={`k-button k-grid-${cssTag}`} aria-label={ariaLabel} onClick={e => onCommandClick(e, dataItem)}>
                        {btnText}
                    </button>
                )}
            </td>
        )
    }
}
