import React from 'react';
import {
  GridColumnMenuSort,
  GridColumnMenuFilter,
  GridColumnMenuItemGroup,
  GridColumnMenuItem,
  GridColumnMenuItemContent
} from '@progress/kendo-react-grid';
import ColumnSelection from './ColumnSelection';

export const ColumnMenu = (props) => {
  return (
    <div>
      <GridColumnMenuSort {...props} />
      <GridColumnMenuFilter {...props} />
    </div>
  );
};

export const ColumnMenuSort = (props) => {
  return (
    <div>
      <GridColumnMenuSort {...props} />
    </div>
  );
};

export const ColumnMenuFilter = (props) => {
  return (
    <div>
      <GridColumnMenuFilter {...props} />
    </div>
  );
};

export class CustomColumnMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columnsExpanded: false,
      filterExpanded: false
    };
  }

  onMenuItemClick = () => {
    const value = !this.state.columnsExpanded;
    this.setState({
      columnsExpanded: value,
      filterExpanded: value ? false : this.state.filterExpanded
    });
  };

  onFilterExpandChange = (value) => {
    this.setState({
      filterExpanded: value,
      columnsExpanded: value ? false : this.state.columnsExpanded
    });
  };

  render() {
    return (
      <div>
        <GridColumnMenuSort {...this.props} />
        <GridColumnMenuFilter
          {...this.props}
          onExpandChange={this.onFilterExpandChange}
          expanded={this.state.filterExpanded}
        />
        <GridColumnMenuItemGroup>
          <GridColumnMenuItem
            title={'Columns'}
            iconClass={'k-i-columns'}
            onClick={this.onMenuItemClick}
          />
          <GridColumnMenuItemContent show={this.state.columnsExpanded}>
            <ColumnSelection
              columns={this.props.columns}
              onColumnsSubmit={this.props.onColumnsSubmit}
              onCloseMenu={this.props.onCloseMenu}
            />
          </GridColumnMenuItemContent>
        </GridColumnMenuItemGroup>
      </div>
    );
  }
}
