import { push } from 'connected-react-router';
import { resetImmediateSearchQuery } from '../actions/searchActions';

const Nav = {
  Login: () => (dispatch) => dispatch(push('/auth/login')),

  SetRouteQuery: (key, value) => (dispatch) =>
    dispatch(Nav.SetRouteQueries([{ key, value }])),
  SetRouteQueries: (queries = []) => (dispatch) => {
    const searchItems = new URLSearchParams(window.location.search);

    queries.forEach((q) => searchItems.set(q.key, q.value));

    dispatch(
      push({
        pathname: window.location.pathname,
        search: searchItems.toString()
      })
    );
  },
  RemoveRouteQuery: (key) => (dispatch) => {
    const searchItems = new URLSearchParams(window.location.search);

    searchItems.delete(key);

    dispatch(
      push({
        pathname: window.location.pathname,
        search: searchItems.toString()
      })
    );
  },
  ResetRouteQuery: () => (dispatch) =>
    dispatch(push({ pathname: window.location.pathname, search: '' })),

  GoToLocation: (location) => (dispatch) => {
    dispatch(push(location));
  },
  Workspace: {
    Index: () => (dispatch) => {
      const search = window.location.search &&
        window.location.search.startsWith("?token=") ? '' : window.location.search

      dispatch(
        push({ pathname: '/workspace/index', search: search })
      );
      dispatch(Nav.SetRouteQuery('w', 't'));
    },
    Inbox: () => (dispatch) => {
      dispatch(
        push({ pathname: '/workspace/inbox', search: window.location.search })
      );
      dispatch(Nav.SetRouteQuery('w', 'i'));
    },
    Search: (isNew = true, isImmediateSearch = false) => (dispatch) => {
      if (!isImmediateSearch) {
        dispatch(resetImmediateSearchQuery())
      }
      dispatch(
        push({
          pathname: '/workspace/search',
          search: window.location.search
        })
      );
      dispatch(Nav.SetRouteQuery('w', 's'));
      dispatch(Nav.SetRouteQuery('new', isNew));
    },
    Email: {
      Index: () => (dispatch) =>
        dispatch(
          push({ pathname: `/workspace/email`, search: window.location.search })
        ),
      Preview: () => (dispatch) =>
        dispatch(
          push({
            pathname: `/workspace/email-preview`,
            search: window.location.search
          })
        ),
      Reply: () => (dispatch) =>
        dispatch(
          push({
            pathname: `/workspace/email-reply`,
            search: window.location.search
          })
        ),
      Success: () => (dispatch) =>
        dispatch(
          push({
            pathname: `/workspace/email-success`,
            search: window.location.search
          })
        ),
      Unread: () => (dispatch) =>
        dispatch(
          push({
            pathname: `/workspace/email-unread`,
            search: window.location.search
          })
        )
    },
    Task: {
      Index: () => (dispatch) =>
        dispatch(
          push({ pathname: '/workspace/task', search: window.location.search })
        )
    },
    Phone: {
      Index: () => (dispatch) =>
        dispatch(
          push({ pathname: '/workspace/phone', search: window.location.search })
        )
    },
    Post: {
      Index: () => (dispatch) =>
        dispatch(
          push({ pathname: '/workspace/post', search: window.location.search })
        ),
      Success: () => (dispatch) =>
        dispatch(
          push({
            pathname: `/workspace/post-success`,
            search: window.location.search
          })
        )
    },
    Sms: {
      Index: () => (dispatch) =>
        dispatch(
          push({ pathname: '/workspace/sms', search: window.location.search })
        )
    },
    TransactionStatusUpdate: {
      Index: () => (dispatch) =>
        dispatch(
          push({
            pathname: '/workspace/transaction-status-update',
            search: window.location.search
          })
        )
    },
    RepaymentPlan: {
      Index: () => (dispatch) =>
        dispatch(
          push({
            pathname: '/workspace/repayment-plan',
            search: window.location.search
          })
        )
    },
    CreditLimit: {
      Index: () => (dispatch) =>
        dispatch(
          push({
            pathname: '/workspace/credit-limit',
            search: window.location.search
          })
        )
    },
    PinNotes: {
      Index: () => (dispatch) =>
        dispatch(
          push({
            pathname: '/workspace/pin-notes',
            search: window.location.search
          })
        )
    },
    MakePayment: {
      Index: () => (dispatch) =>
        dispatch(
          push({
            pathname: '/workspace/make-payment',
            search: window.location.search
          })
        )
    },
    Comments: {
      Index: () => (dispatch) =>
        dispatch(
          push({ pathname: '/workspace/comments', search: window.location.search })
        )
    },
    RightPane: (
      leftPane = 'index',
      rightPane = 'index',
      queries = []
    ) => () => (dispatch) => {
      dispatch(
        push({
          pathname: `/workspace/${leftPane}/${rightPane}`,
          search: window.location.search
        })
      );

      if (queries.length === 0) return;

      dispatch(Nav.SetRouteQueries(queries));
    }
  },

  SysConfig: {
    Index: client => dispatch => dispatch(push(`/system-configuration/index?client=${client}`)),
    UserAdmin: client => dispatch => dispatch(push(`/system-configuration/user-management?client=${client}`)),
    RoleAdmin: client => dispatch => dispatch(push(`/system-configuration/role-management?client=${client}`)),
    Mapping: client => dispatch => dispatch(push(`/system-configuration/mapping-template?client=${client}`)),
    LanguageVariables: () => (dispatch) =>
      dispatch(push('/system-configuration/languagevariablesmanager')),
    EMailMasterTemplate: client => dispatch =>
      dispatch(push(`/system-configuration/email-master-template-manager?client=${client}`)),
    EMailTemplate: client => dispatch => dispatch(push(`/system-configuration/email-template-manager?client=${client}`)),
    PostTemplate: client => dispatch =>
      dispatch(push(`/system-configuration/post-template-manager?client=${client}`)),
    FileImporterLog: client => dispatch =>
      dispatch(push(`/system-configuration/file-importer-log?client=${client}`)),
    APIRecovery: () => (dispatch) => dispatch(push('/system-configuration/apiRecovery')),
    LookupAdmin: client => dispatch => dispatch(push(`/system-configuration/lookup-admin?client=${client}`)),
    ScoreCard: () => (dispatch) => dispatch(push('/system-configuration/scoreCard')),
    ScoreCardDefinition: () => (dispatch) =>
      dispatch(push('/system-configuration/scoreCardDefinition')),
    CustomerAgreementTemplate: client => dispatch =>
      dispatch(push(`/system-configuration/customer-agreement-template?client=${client}`)),
    FileConfigurationContainer: client => dispatch =>
      dispatch(push(`/system-configuration/file-importer-configuration?client=${client}`)),
    RuleGroupManagementContainer: client => dispatch =>
      dispatch(push(`/system-configuration/rule-group-management?client=${client}`)),
    SystemConfigurationContainer: client => dispatch =>
      dispatch(push(`/system-configuration/system-configuration?client=${client}`)),
    ClientConfigurationContainer: client => dispatch =>
      dispatch(push(`/system-configuration/client-configuration?client=${client}`))
  },

  Platform: {
    Dashboard: () => (dispatch) => {
      dispatch(push('/platform/dashboard'))
      dispatch(resetImmediateSearchQuery())
    },
    DataConfiguration: () => (dispatch) => dispatch(push('/platform/data-configuration')),
    CustomerGroups: () => (dispatch) => dispatch(push('/platform/customer-groups')),
    UserGroups: () => (dispatch) => dispatch(push('/platform/user-groups')),
  },

  Dashboard: {
    DashboardsConfig: () => (dispatch) => dispatch(push('/dashboard/dashboards-config'))
  },

  Automate: {
    Index: () => (dispatch) => dispatch(push('/automate')),
  },

  Connect: {
    Sms: () => (dispatch) => dispatch(push('/connect/sms')),
  },

  Calculate: {
    CalculatedFields: () => (dispatch) => {
      dispatch(push('/calculate/calculated-fields'))
    }
  },

  Integration: {
    ImportFileConfig: () => (dispatch) => dispatch(push('/import-file-config'))
  },

  DataConfig: () => (dispatch) => dispatch(push('/dataconfig')),
  TaskConfig: () => (dispatch) => dispatch(push('/task-config')),

  UserProfileSettings: () => (dispatch) =>
    dispatch(push('/user-profile-settings')),
  UserProfile: () => (dispatch) => dispatch(push('/user-profile')),
  Reports: () => (dispatch) => dispatch(push('/reports')),
  Prototypes: () => (dispatch) => dispatch(push('/prototypes')),

  CustomerAcquisition: {
    Grid: () => (dispatch) =>
      dispatch(push('/customerAcquisition/applicationsGrid')),
    StageN: (n) => (dispatch) =>
      dispatch(push('/customerAcquisition/application/stage' + n))
  },

  CustomerApproval: {
    StageN: (n) => (dispatch) =>
      dispatch(push('/customerAcquisition/approval/stage' + n)),
    Decision: () => (dispatch) =>
      dispatch(push('/customerAcquisition/approval/stage5'))
  }
};

export default Nav;
