import { padElementsWith } from "../../../../library/helpers"
import { DashboardConfigAction, DashboardConfigsState } from "../../reducers/dashboardConfigsReducer"
import DashboardConfigState from "../../types/DashboardConfigState"
import classes from "./EntityDashboardConfigs.module.scss"
import EntityDashboardCard from "./cards/EntityDashboardCard"
import RoleCard from "./cards/RoleCard"

type EntityDashboardConfigsProps = {
    state: DashboardConfigsState
    dispatch: React.Dispatch<DashboardConfigAction>
    isNewEntityStructureEnabled: boolean
    dashboardConfigs: DashboardConfigState[]
}
const EntityDashboardConfigs = ({ state, dispatch, isNewEntityStructureEnabled, dashboardConfigs }: EntityDashboardConfigsProps) => {
    const getRolesForConfig = (config: DashboardConfigState) => {
        return [...state.roles.filter(role => config.roleReferences.includes(role.reference)).sort((a, b) => (a.name > b.name ? 1 : -1))]
    }

    return (
        <div className="d-flex flex-wrap w-100 h-100 px-2">
            {dashboardConfigs.length > 0 ? (
                padElementsWith(
                    dashboardConfigs.map(config => (
                        <div key={config.reference} className={`d-flex flex-column ${classes["dashboard-column"]}`}>
                            <div className="pb-3 border-bottom w-100">
                                <EntityDashboardCard
                                    state={{
                                        originalConfig: config,
                                        editingConfig: state.editingConfig,
                                        customerFields: state.customerFields,
                                        transactionFields: state.transactionFields,
                                        showValidationErrors: state.showValidationErrors,
                                        dashboardConfigs: state.dashboardConfigs,
                                        entityConfigs: state.entityConfigs,
                                        roles: state.roles,
                                        lookups: state.lookups,
                                        isNewEntityStructureEnabled: isNewEntityStructureEnabled
                                    }}
                                    dispatch={dispatch}
                                />
                            </div>
                            {getRolesForConfig(config).map(role => (
                                <RoleCard
                                    key={role.reference}
                                    className="mt-3"
                                    state={{ role, config }}
                                    dispatch={dispatch}
                                    isNewEntityStructureEnabled={isNewEntityStructureEnabled}
                                />
                            ))}
                        </div>
                    )),
                    `border-start ${classes.separator}`
                )
            ) : (
                <h4 className="text-grey">You have no dashboards configured</h4>
            )}
        </div>
    )
}

export default EntityDashboardConfigs
