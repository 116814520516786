import { useState } from "react"
import classes from "./AddPermissionToRole.module.scss"
import { Loading, Overlay, StandardButton, Toggle, useApi, useClient } from "invevo-react-components"
import { convertRoleToDto } from "../helpers"
import { actionTypes } from "../../../reducers/actionTypes"
import { defaultCustomerPermission } from "../permissions/CustomerPermission"
import { defaultUserPermission } from "../permissions/UserPermission"
import { defaultAutomatePermission } from "../permissions/AutomatePermission"
import { defaultBrandPermission } from "../permissions/BrandPermission"
import { defaultCalculatePermission } from "../permissions/CalculatePermission"
import { defaultConnectPermission } from "../permissions/ConnectPermission"
import { defaultDashboardPermission } from "../permissions/DashboardPermission"
import { defaultDataConfigPermission } from "../permissions/DataConfigPermission"
import { defaultIntegrationPermission } from "../permissions/IntegrationPermission"
import { defaultPayPermission } from "../permissions/PayPermission"
import { defaultTaskPermission } from "../permissions/TaskPermission"
import { defaultMakeCallPermission } from "../permissions/MakeCallPermission"
import { toast } from 'react-toastify'

const AddPermissionToRole = ({ state, dispatch, onClose }) => {
    const { role } = state

    const [isSaving, setIsSaving] = useState(false)
    const [isCustomerPermissionChecked, setIsCustomerPermissionChecked] = useState(role.customerPermission ? true : false)
    const [isUserPermissionChecked, setIsUserPermissionChecked] = useState(role.userPermission ? true : false)
    const [isAutomatePermissionChecked, setIsAutomatePermissionChecked] = useState(role.automatePermission ? true : false)
    const [isBrandPermissionChecked, setIsBrandPermissionChecked] = useState(role.brandPermission ? true : false)
    const [isCalculatePermissionChecked, setIsCalculatePermissionChecked] = useState(role.calculatePermission ? true : false)
    const [isConnectPermissionChecked, setIsConnectPermissionChecked] = useState(role.connectPermission ? true : false)
    const [isDashboardPermissionChecked, setIsDashboardPermissionChecked] = useState(role.dashboardPermission ? true : false)
    const [isDataConfigPermissionChecked, setIsDataConfigPermissionChecked] = useState(role.dataConfigPermission ? true : false)
    const [isIntegrationPermissionChecked, setIsIntegrationPermissionChecked] = useState(role.integrationPermission ? true : false)
    const [isPayPermissionChecked, setIsPayPermissionChecked] = useState(role.payPermission ? true : false)
    const [isTaskPermissionChecked, setIsTaskPermissionChecked] = useState(role.taskPermission ? true : false)
    const [isMakeCallPermissionChecked, setIsMakeCallPermissionChecked] = useState(role.makeCallPermission ? true : false)

    const api = useApi()
    const client = useClient()

    const onSave = () => {
        setIsSaving(true)

        const updatedRole = {
            ...role,
            customerPermission: isCustomerPermissionChecked && (role.customerPermission || defaultCustomerPermission),
            userPermission: isUserPermissionChecked && (role.userPermission || defaultUserPermission),
            automatePermission: isAutomatePermissionChecked && (role.automatePermission || defaultAutomatePermission),
            brandPermission: isBrandPermissionChecked && (role.brandPermission || defaultBrandPermission),
            calculatePermission: isCalculatePermissionChecked && (role.calculatePermission || defaultCalculatePermission),
            connectPermission: isConnectPermissionChecked && (role.connectPermission || defaultConnectPermission),
            dashboardPermission: isDashboardPermissionChecked && (role.dashboardPermission || defaultDashboardPermission),
            dataConfigPermission: isDataConfigPermissionChecked && (role.dataConfigPermission || defaultDataConfigPermission),
            integrationPermission: isIntegrationPermissionChecked && (role.integrationPermission || defaultIntegrationPermission),
            payPermission: isIntegrationPermissionChecked && (role.payPermission || defaultPayPermission),
            taskPermission: isTaskPermissionChecked && (role.taskPermission || defaultTaskPermission),
            makeCallPermission: isMakeCallPermissionChecked && (role.makeCallPermission || defaultMakeCallPermission),
            assignedUsers: [...role.assignedUsers.map(user => ({
                ...user,
                permissions: {
                    customerPermission: isCustomerPermissionChecked && (user.permissions.customerPermission || defaultCustomerPermission),
                }
            }))]
        }

        const dto = convertRoleToDto(updatedRole)
        api.put(`/api/${client}/roles/${role.id}`, dto)
            .then(_ => {
                dispatch({ type: actionTypes.ROLE_PERMISSIONS_UPDATED, updatedRole: { id: role.id, ...updatedRole } })
                setIsSaving(false)
                onClose()
            })
            .catch(error => {
                console.error(error)
                toast.error("Error while updating role")
                setIsSaving(false)
            })
    }

    return (
        <Overlay title="Add admin permissions to role" onCloseClick={onClose}>
            <div className="d-flex flex-column flex-grow-1 overflow-auto">
                <div className={`d-flex flex-column p-3 flex-grow-1 text-grey ${classes["scrolling-form"]} ${classes.container}`}>
                    <Loading isLoading={isSaving} colour="blue">
                        <div className={`d-flex align-items-center py-1 px-2 mt-3 ${classes.rounded} bg-grey`}>
                            <Toggle status={isCustomerPermissionChecked} onStatusChanged={() => setIsCustomerPermissionChecked(!isCustomerPermissionChecked)} ariaLabel="customer-permission" />
                            <span className="ms-2">Customer</span>
                        </div>
                        <div className={`d-flex align-items-center py-1 px-2 mt-3 ${classes.rounded} bg-grey`}>
                            <Toggle status={isUserPermissionChecked} onStatusChanged={() => setIsUserPermissionChecked(!isUserPermissionChecked)} ariaLabel="user-permission" />
                            <span className="ms-2">User</span>
                        </div>
                        <div className={`d-flex align-items-center py-1 px-2 mt-3 ${classes.rounded} bg-grey`}>
                            <Toggle status={isAutomatePermissionChecked} onStatusChanged={() => setIsAutomatePermissionChecked(!isAutomatePermissionChecked)} ariaLabel="automate-permission" />
                            <span className="ms-2">Automate</span>
                        </div>
                        <div className={`d-flex align-items-center py-1 px-2 mt-3 ${classes.rounded} bg-grey`}>
                            <Toggle status={isBrandPermissionChecked} onStatusChanged={() => setIsBrandPermissionChecked(!isBrandPermissionChecked)} ariaLabel="brand-permission" />
                            <span className="ms-2">Brand</span>
                        </div>
                        <div className={`d-flex align-items-center py-1 px-2 mt-3 ${classes.rounded} bg-grey`}>
                            <Toggle status={isCalculatePermissionChecked} onStatusChanged={() => setIsCalculatePermissionChecked(!isCalculatePermissionChecked)} ariaLabel="calculate-permission" />
                            <span className="ms-2">Calculate</span>
                        </div>
                        <div className={`d-flex align-items-center py-1 px-2 mt-3 ${classes.rounded} bg-grey`}>
                            <Toggle status={isConnectPermissionChecked} onStatusChanged={() => setIsConnectPermissionChecked(!isConnectPermissionChecked)} ariaLabel="connect-permission" />
                            <span className="ms-2">Connect</span>
                        </div>
                        <div className={`d-flex align-items-center py-1 px-2 mt-3 ${classes.rounded} bg-grey`}>
                            <Toggle status={isDashboardPermissionChecked} onStatusChanged={() => setIsDashboardPermissionChecked(!isDashboardPermissionChecked)} ariaLabel="dashboard-permission" />
                            <span className="ms-2">Dashboard</span>
                        </div>
                        <div className={`d-flex align-items-center py-1 px-2 mt-3 ${classes.rounded} bg-grey`}>
                            <Toggle status={isDataConfigPermissionChecked} onStatusChanged={() => setIsDataConfigPermissionChecked(!isDataConfigPermissionChecked)} ariaLabel="data-config-permission" />
                            <span className="ms-2">Data Config</span>
                        </div>
                        <div className={`d-flex align-items-center py-1 px-2 mt-3 ${classes.rounded} bg-grey`}>
                            <Toggle status={isIntegrationPermissionChecked} onStatusChanged={() => setIsIntegrationPermissionChecked(!isIntegrationPermissionChecked)} ariaLabel="integration-permission" />
                            <span className="ms-2">Integration</span>
                        </div>
                        <div className={`d-flex align-items-center py-1 px-2 mt-3 ${classes.rounded} bg-grey`}>
                            <Toggle status={isPayPermissionChecked} onStatusChanged={() => setIsPayPermissionChecked(!isPayPermissionChecked)} ariaLabel="pay-permission" />
                            <span className="ms-2">Pay</span>
                        </div>
                        <div className={`d-flex align-items-center py-1 px-2 mt-3 ${classes.rounded} bg-grey`}>
                            <Toggle status={isTaskPermissionChecked} onStatusChanged={() => setIsTaskPermissionChecked(!isTaskPermissionChecked)} ariaLabel="task-permission" />
                            <span className="ms-2">Task</span>
                        </div>
                        <div className={`d-flex align-items-center py-1 px-2 mt-3 ${classes.rounded} bg-grey`}>
                            <Toggle status={isMakeCallPermissionChecked} onStatusChanged={() => setIsMakeCallPermissionChecked(!isMakeCallPermissionChecked)} ariaLabel="make-call-permission" />
                            <span className="ms-2">Make call</span>
                        </div>
                    </Loading>
                </div>
                <div className="mt-auto d-flex p-3 bg-grey">
                    <StandardButton className="ms-auto" label="Cancel" colour="grey" iconClasses="text-blue fal fa-times" border="blue" onClick={onClose} disabled={isSaving} />
                    <StandardButton className="ms-3" label="Save" colour="grey" iconClasses="text-blue fal fa-save" border="blue" onClick={onSave} disabled={isSaving} />
                </div>
            </div>
        </Overlay>
    )
}

export default AddPermissionToRole