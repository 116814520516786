import { Loading, LogicOperatorOptions } from "invevo-react-components"
import LogicOperatorButton from "./LogicOperatorButton"
import classes from "./FilterRow.module.scss"

const FilterRow = ({ fieldName, fieldValue, useAsNotIn, onEditClicked, onDeleteClicked, isDeleting }) => {
    return (
        <div className={`d-flex flex-grow-1 align-items-center p-1 mb-2 bg-white ${classes.rounded} ${classes.shadow}`}>
            <span className="ms-2 text-truncate">{fieldName}</span>
            <div className="mx-2">
                <LogicOperatorButton operator={useAsNotIn ? LogicOperatorOptions.NOT_IN : LogicOperatorOptions.IN} />
            </div>
            <span className="text-truncate">{fieldValue}</span>
            <div className="d-flex ms-auto">
                <Loading isLoading={isDeleting} colour="blue" size="small">
                    <i className="fal fa-pen pointer text-blue" onClick={onEditClicked} />
                    <i className="ms-2 me-1 fal fa-trash pointer text-blue" onClick={onDeleteClicked} />
                </Loading>
            </div>
        </div>
    )
}

export default FilterRow