import { IconButton, StandardButton } from 'invevo-react-components'
import FilterRules from './FilterRules'
import classes from './InlineFilters.module.scss'

const InlineFilters = ({
    className,
    rules,
    label = "Filters",
    onRuleChange,
    onAddRule,
    onRemoveRule,
    onRemoveAll,
    fieldOptions = [],
    inputType = "normal",
    fieldsAvailableForAutoComplete = [],
    autoCompleteUrl = "",
    readonly = false,
    enableTooltip = false,
    scrollable = false
}) => {
    return (
        <div className={`d-flex w-100 ${className ? className : ''} ${classes.container}`}>
            <div className="d-flex bg-white flex-grow-1">
                <div className="d-flex flex-grow-1 flex-column">
                    <div className={`d-flex flex-grow-1 my-2 ms-2 me-3 pb-2 ${classes.header}`}>
                        <div className="d-flex ms-2 align-items-center">
                            <i className={`fal fa-filter me-3 fs-5 ${classes['header-icon']}`}></i>
                            <span className="text-black fs-5">{label}</span>
                        </div>
                        {!readonly &&
                            <div className="d-flex ms-auto">
                                <StandardButton className={`${classes['add-rule-button']} py-2 pe-3`} iconClasses="fal fa-plus" label="ADD RULE" colour="grey" onClick={onAddRule} />
                                <IconButton className="py-2 ms-3" iconClasses="fal fa-trash-alt" onClick={onRemoveAll} />
                            </div>
                        }
                    </div>
                    <div className="d-flex flex-column mt-2 mb-3 ms-2 me-3">
                        {rules.length === 0 &&
                            <div className="d-flex align-items-center text-grey">
                                <i className="mx-2 fal fa-exclamation-circle"></i>
                                <h6 className="mb-0">You currently have no rules defined</h6>
                            </div>
                        }

                        <FilterRules
                            rules={rules}
                            onRuleChange={onRuleChange}
                            onRemoveRule={onRemoveRule}
                            readonly={readonly}
                            fieldOptions={fieldOptions}
                            inputType={inputType}
                            fieldsAvailableForAutoComplete={fieldsAvailableForAutoComplete}
                            autoCompleteUrl={autoCompleteUrl}
                            enableTooltip={enableTooltip}
                            scrollable={scrollable}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InlineFilters