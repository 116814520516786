import React, { Component } from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import {
  executeAuthAsyncGet,
  executeAuthAsyncPost,
  executeAsyncResetGet
} from '../../../utility/asyncSupport';
import { findIndex, filter, isObject, some } from 'lodash';
import {
  showToastSuccessMessage,
  showToastErrorMessage,
  showToastWarningMessage,
  showToastInfoMessage
} from '../../../api/toasterApi';

import AddPermission from './addPermission';
import PermissionGrid from './permissionGrid';
import FilterGrid from './filterGrid';
import AddFilter from './addFilter';

class AddRole extends Component {
  constructor(props, context) {
    super(props, context);

    this.onRoleNameChanged = this.onRoleNameChanged.bind(this);
    this.onChangeIsDefault = this.onChangeIsDefault.bind(this);
    this.onChangeFullCustomerView = this.onChangeFullCustomerView.bind(this);
    this.onChangeDisplayAsInternalContact = this.onChangeDisplayAsInternalContact.bind(
      this
    );

    this.onDeletePermssion = this.onDeletePermssion.bind(this);
    this.renderAddPermission = this.renderAddPermission.bind(this);
    this.showAddPermission = this.showAddPermission.bind(this);
    this.addPermissionAdd = this.addPermissionAdd.bind(this);
    this.addPermissionCancel = this.addPermissionCancel.bind(this);

    this.onDeleteAccountFilter = this.onDeleteAccountFilter.bind(this);
    this.renderAddAccountFilter = this.renderAddAccountFilter.bind(this);
    this.showAddAccountFilter = this.showAddAccountFilter.bind(this);
    this.addAccountFilterAdd = this.addAccountFilterAdd.bind(this);
    this.addAccountFilterCancel = this.addAccountFilterCancel.bind(this);
    this.onChangeIsAdvancedFilterMode = this.onChangeIsAdvancedFilterMode.bind(
      this
    );

    this.onDeleteVirtualAccountFilter = this.onDeleteVirtualAccountFilter.bind(
      this
    );
    this.renderAddVirtualAccountFilter = this.renderAddVirtualAccountFilter.bind(
      this
    );
    this.showAddVirtualAccountFilter = this.showAddVirtualAccountFilter.bind(
      this
    );
    this.addVirtualAccountFilterAdd = this.addVirtualAccountFilterAdd.bind(
      this
    );
    this.addVirtualAccountFilterCancel = this.addVirtualAccountFilterCancel.bind(
      this
    );

    this.renderTxFilters = this.renderTxFilters.bind(this);
    this.onChangeHasTransactionFilters = this.onChangeHasTransactionFilters.bind(
      this
    );
    this.onDeleteTransactionFilter = this.onDeleteTransactionFilter.bind(this);
    this.renderAddTxFilter = this.renderAddTxFilter.bind(this);
    this.showAddTransactionFilter = this.showAddTransactionFilter.bind(this);
    this.addTransactionFilterAdd = this.addTransactionFilterAdd.bind(this);
    this.addTransactionFilterCancel = this.addTransactionFilterCancel.bind(
      this
    );
    this.onChangeIsDashboardContact = this.onChangeIsDashboardContact.bind(
      this
    );

    this.save = this.save.bind(this);
    this.onSaveSuccess = this.onSaveSuccess.bind(this);
    this.cancel = this.cancel.bind(this);

    this.state = this.defaultFormState();
  }

  defaultFormState() {
    return {
      role: {
        id: null,
        name: '',
        isDefault: false,
        isFinancialRole: false,
        hasTransactionFilters: false,
        isAdvancedFilterMode: false,
        fullCustomerView: false,
        displayAsInternalContact: true,
        isDashboardContact: false,
        permissions: [
          //{ permissionId: 1, name: 'Import admin', upperFinancialLimit: 100.00, lowerFinancialLimit: 50.00 },
        ],
        accountFilters: [
          //{ filterId: 1, name: 'Branch Name', default: 'London', operator: 'And' }
        ],
        virtualAccountFilters: [
          //{ filterId: 1, name: 'Branch Name', default: 'London', operator: 'And' }
        ],
        transactionFilters: [
          //{ filterId: 1, name: 'Branch Name', default: 'London', operator: 'And' }
        ]
      },
      heading: 'Add Role',
      addingPermission: false,
      addingAccountFilter: false,
      addingVirtualAccountFilter: false,
      addingTransactionFilter: false,
      canSave: false,
      isEditMode: false
    };
  }

  edit(roleId) {
    const that = this;
    this.props.executeAuthAsyncGet(
      this.props.client,
      'roles',
      'GET_ROLE',
      { roleId: roleId },
      function (result) {
        const role = result;
        that.setState({
          role: role,
          heading: "Edit '" + role.name + "' role",
          isEditMode: true,
          canSave: true
        });
      },
      function (/*error*/) {
        showToastErrorMessage('Error while loading role.');
      }
    );
  }

  componentDidMount() {
    if (this.props.onEdit != null) {
      this.props.onEdit(this.edit.bind(this));
    }
  }

  showAddPermission() {
    this.setState({ addingPermission: true });
  }

  addPermissionAdd(permission) {
    if (
      some(this.state.role.permissions, {
        permissionId: permission.permissionId
      })
    ) {
      showToastWarningMessage(
        "Role already has permission '" + permission.name + "'."
      );
      return;
    }
    const permissions = this.state.role.permissions.concat(permission);

    this.setState((prevState) => ({
      ...prevState,
      addingPermission: false,
      role: {
        ...prevState.role,
        isFinancialRole: some(permissions, {
          isFinancialPermission: true
        }),
        permissions
      }
    }));
  }

  addPermissionCancel() {
    this.setState({ addingPermission: false });
  }

  showAddAccountFilter() {
    this.setState({ addingAccountFilter: true });
  }

  addAccountFilterAdd(filter) {
    if (some(this.state.role.accountFilters, { filterId: filter.filterId })) {
      showToastWarningMessage(
        "Role already has account filter '" + filter.name + "'."
      );
      return;
    }

    const f = filter;

    this.setState((prevState) => ({
      ...prevState,
      role: {
        ...prevState.role,
        accountFilters: prevState.role.accountFilters.concat(f)
      },
      addingAccountFilter: false
    }));
  }

  addAccountFilterCancel() {
    this.setState({ addingAccountFilter: false });
  }

  showAddVirtualAccountFilter() {
    this.setState({ addingVirtualAccountFilter: true });
  }

  addVirtualAccountFilterAdd(filter) {
    const f = filter;

    this.setState((prevState) => ({
      ...prevState,
      role: {
        ...prevState.role,
        virtualAccountFilters: prevState.role.virtualAccountFilters.concat(f)
      },
      addingVirtualAccountFilter: false
    }));
  }

  addVirtualAccountFilterCancel() {
    this.setState({ addingVirtualAccountFilter: false });
  }

  showAddTransactionFilter() {
    this.setState({ addingTransactionFilter: true });
  }

  addTransactionFilterAdd(filter) {
    const f = filter;

    this.setState((prevState) => ({
      ...prevState,
      role: {
        ...prevState.role,
        transactionFilters: prevState.role.transactionFilters.concat(f)
      },
      addingTransactionFilter: false
    }));
  }

  addTransactionFilterCancel() {
    this.setState({ addingTransactionFilter: false });
  }

  onRoleNameChanged(e) {
    const name = e.target.value;

    this.setState(
      (prevState) => ({
        ...prevState,
        role: { ...prevState.role, name }
      }),
      this.validateForm
    );
  }

  validateForm() {
    const canSave = this.state.role.name !== '';
    this.setState({ canSave: canSave });
  }

  onChangeIsDefault(event) {
    const isDefault = event.target.checked;

    this.setState((prevState) => ({
      ...prevState,
      role: { ...prevState.role, isDefault }
    }));
  }

  onChangeFullCustomerView(event) {
    const fullCustomerView = event.target.checked;

    this.setState((prevState) => ({
      ...prevState,
      role: { ...prevState.role, fullCustomerView }
    }));
  }

  onChangeDisplayAsInternalContact(event) {
    const isDisplayAsInternalContact = event.target.checked;

    this.setState((prevState) => ({
      ...prevState,
      role: {
        ...prevState.role,
        displayAsInternalContact: isDisplayAsInternalContact
      }
    }));
  }

  onChangeIsDashboardContact(event) {
    const isDashboardContact = event.target.checked;

    this.setState((prevState) => ({
      ...prevState,
      role: { ...prevState.role, isDashboardContact }
    }));
  }

  onChangeIsAdvancedFilterMode(event) {
    const val = event.target.checked;

    this.setState((prevState) => ({
      ...prevState,
      role: { ...prevState.role, isAdvancedFilterMode: val }
    }));
  }

  onChangeHasTransactionFilters(event) {
    const val = event.target.checked;

    this.setState((prevState) => ({
      ...prevState,
      role: { ...prevState.role, hasTransactionFilters: val }
    }));
  }

  onDeletePermssion(permissionId) {
    const index = findIndex(this.state.role.permissions, {
      permissionId: permissionId
    });
    const permissions = this.state.role.permissions.filter(
      (_, i) => i !== index
    );
    const isFinancialRole = some(permissions, { isFinancialPermission: true });

    this.setState((prevState) => ({
      ...prevState,
      role: { ...prevState.role, isFinancialRole: isFinancialRole, permissions }
    }));
  }

  onDeleteAccountFilter(filterId) {
    const index = findIndex(this.state.role.accountFilters, {
      filterId: filterId
    });

    this.setState((prevState) => ({
      ...prevState,
      role: {
        ...prevState.role,
        accountFilters: prevState.role.accountFilters.filter(
          (_, i) => i !== index
        )
      },
      hasTransactionFilters: prevState.role.transactionFilters.length === 0
    }));
  }

  onDeleteVirtualAccountFilter(filterId) {
    const index = findIndex(this.state.role.virtualAccountFilters, {
      filterId: filterId
    });

    this.setState((prevState) => ({
      ...prevState,
      role: {
        ...prevState.role,
        virtualAccountFilters: prevState.role.virtualAccountFilters.filter(
          (_, i) => i !== index
        )
      }
    }));
  }

  onDeleteTransactionFilter(filterId) {
    const index = findIndex(this.state.role.transactionFilters, {
      filterId: filterId
    });

    this.setState((prevState) => ({
      ...prevState,
      role: {
        ...prevState.role,
        transactionFilters: prevState.role.transactionFilters.filter(
          (_, i) => i !== index
        )
      },
      hasTransactionFilters: prevState.role.transactionFilters.length === 0
    }));
  }

  save() {
    const that = this;

    this.setState({ canSave: false });

    if (that.state.isEditMode) {
      showToastInfoMessage(
        'Role update submitted. Please note, changes to permissions and filters only take effect overnight and when a user is updated.'
      );

      that.props.executeAuthAsyncPost(
        this.props.client,
        'roles/update',
        'UPDATE_ROLE',
        that.state.role,
        that.onSaveSuccess,
        function (/*error*/) {
          showToastErrorMessage('Error while updating Role.');
          this.setState({ canSave: true });
        }
      );
    } else {
      showToastInfoMessage('Role save submitted.');

      that.props.executeAuthAsyncPost(
        this.props.client,
        'roles/create',
        'CREATE_ROLE',
        that.state.role,
        that.onSaveSuccess,
        function (/*error*/) {
          showToastErrorMessage('Error while creating Role.');
          this.setState({ canSave: true });
        }
      );
    }
  }

  onSaveSuccess(response) {
    if (!isObject(response) && response !== '') {
      showToastErrorMessage(response);
    } else {
      showToastSuccessMessage('Role saved successfully.');
      this.setState(this.defaultFormState());
      if (this.props.onSaved != null) {
        this.props.onSaved();
      }
    }
  }

  cancel() {
    this.setState(this.defaultFormState());
    if (this.props.onCancelled != null) {
      this.props.onCancelled();
    }
  }

  renderAddPermission(state) {
    const button = (
      <button
        className="btn btn-default"
        type="button"
        onClick={this.showAddPermission}
      >
        Add Permission
      </button>
    );

    const addablePermissions = filter(
      this.props.permissionsLookup.results,
      function (p) {
        return findIndex(state.role.permissions, { id: p.Id }) === -1;
      }
    );

    const addPermissionForm = (
      <div id="add-permission-container">
        <AddPermission
          addablePermissions={addablePermissions}
          isFinancialRole={state.role.isFinancialRole}
          onAdd={this.addPermissionAdd}
          onCancel={this.addPermissionCancel}
        />
      </div>
    );
    return state.addingPermission ? addPermissionForm : button;
  }

  renderAddAccountFilter(state) {
    const button = (
      <button
        id="show-add-account-filter-button"
        className="btn btn-default"
        type="button"
        onClick={this.showAddAccountFilter}
      >
        Add Account Filter
      </button>
    );

    const addableFilters = filter(
      this.props.authFiltersLookup.results,
      function (p) {
        return (
          p.IsAccountFilter === true &&
          findIndex(state.role.accountFilters, { filterId: p.Id }) === -1
        );
      }
    );

    const addFilterForm = (
      <div id="add-account-filter-container">
        <AddFilter
          addableFilters={addableFilters}
          isAdvancedFilterMode={
            state.role.isAdvancedFilterMode &&
            state.role.accountFilters.length > 0
          }
          onAdd={this.addAccountFilterAdd}
          onCancel={this.addAccountFilterCancel}
        />
      </div>
    );
    return state.addingAccountFilter ? addFilterForm : button;
  }

  renderAddVirtualAccountFilter(state) {
    const button = (
      <button
        id="show-add-va-filter-button"
        className="btn btn-default"
        type="button"
        onClick={this.showAddVirtualAccountFilter}
      >
        Add Virtual Account Filter
      </button>
    );

    const addableFilters = filter(
      this.props.authFiltersLookup.results,
      function (p) {
        return (
          p.IsVirtualAccountFilter === true &&
          findIndex(state.role.virtualAccountFilters, { filterId: p.Id }) === -1
        );
      }
    );

    const addFilterForm = (
      <div id="add-va-filter-container">
        <AddFilter
          addableFilters={addableFilters}
          isAdvancedFilterMode={false}
          onAdd={this.addVirtualAccountFilterAdd}
          onCancel={this.addVirtualAccountFilterCancel}
        />
      </div>
    );
    return state.addingVirtualAccountFilter ? addFilterForm : button;
  }

  renderAddTxFilter(state) {
    const button = (
      <button
        id="show-add-tx-filter-button"
        className="btn btn-default"
        type="button"
        onClick={this.showAddTransactionFilter}
      >
        Add Transaction Filter
      </button>
    );

    const addableFilters = filter(
      this.props.authFiltersLookup.results,
      function (p) {
        return (
          p.IsTransactionFilter === true &&
          findIndex(state.role.transactionFilters, { filterId: p.Id }) === -1
        );
      }
    );

    const addFilterForm = (
      <div id="add-tx-filter-container">
        <AddFilter
          addableFilters={addableFilters}
          isAdvancedFilterMode={false}
          onAdd={this.addTransactionFilterAdd}
          onCancel={this.addTransactionFilterCancel}
        />
      </div>
    );
    return state.addingTransactionFilter ? addFilterForm : button;
  }

  renderTxFilters(state) {
    if (state.role.hasTransactionFilters === false) {
      return '';
    }

    const markup = (
      <div>
        <h4>Transaction Filters</h4>
        <div className="form-group">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={state.role.fullCustomerView}
                onChange={this.onChangeFullCustomerView}
              />{' '}
              Full Customer View
            </label>
          </div>
        </div>
        <FilterGrid
          filters={state.role.transactionFilters}
          isAdvancedFilterMode={false}
          onDeleteFilter={this.onDeleteTransactionFilter}
        />
        {this.renderAddTxFilter(state)}
        <br />
        <br />
      </div>
    );

    return markup;
  }

  render() {
    const role = this.state.role;

    return (
      <div>
        <Formsy key="add-role-form" className="card below-grid-form">
          <h3>{this.state.heading}</h3>
          <br />
          {this.state.isEditMode ? (
            ''
          ) : (
            <div className="form-group">
              <input
                id="roleName"
                type="text"
                className="form-control"
                placeholder="Role Name"
                value={role.name}
                onChange={this.onRoleNameChanged}
              />
            </div>
          )}
          <div className="form-group">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={role.isDefault}
                  onChange={this.onChangeIsDefault}
                />{' '}
                Default Role
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={role.isFinancialRole}
                  disabled="disabled"
                />{' '}
                Financial Role
              </label>
            </div>
          </div>

          <div className="form-group">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={role.displayAsInternalContact}
                  onChange={this.onChangeDisplayAsInternalContact}
                />{' '}
                Display As Internal Contact
              </label>
            </div>
          </div>

          <div className="form-group">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={role.isDashboardContact}
                  onChange={this.onChangeIsDashboardContact}
                />{' '}
                Dashboard Contact
              </label>
            </div>
          </div>

          <h4>Permissions</h4>
          <PermissionGrid
            permissions={role.permissions}
            isFinancialRole={role.isFinancialRole}
            onDeletePermission={this.onDeletePermssion}
            isAdministrator={role.name === 'Administrator'}
          />
          {this.renderAddPermission(this.state)}
          <br />
          <br />

          <h4>Account Filters</h4>
          {/*
                    <div className='form-group'>
                        <div className="checkbox">
                            <label><input type="checkbox" checked={role.isAdvancedFilterMode} onChange={this.onChangeIsAdvancedFilterMode} /> Advanced Filter Mode</label>
                        </div>
                    </div>
                    */}
          <FilterGrid
            key="accountFiltersGrid"
            filters={role.accountFilters}
            isAdvancedFilterMode={role.isAdvancedFilterMode}
            onDeleteFilter={this.onDeleteAccountFilter}
          />
          {this.renderAddAccountFilter(this.state)}
          <br />
          <br />

          <h4>Virtual Account Filters</h4>
          <FilterGrid
            key="virtualAccountFiltersGrid"
            filters={role.virtualAccountFilters}
            isAdvancedFilterMode={false}
            onDeleteFilter={this.onDeleteVirtualAccountFilter}
          />
          {this.renderAddVirtualAccountFilter(this.state)}
          <br />
          <br />

          <div className="form-group">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={role.hasTransactionFilters}
                  onChange={this.onChangeHasTransactionFilters}
                />{' '}
                Has Transaction Filters
              </label>
            </div>
          </div>
          {this.renderTxFilters(this.state)}

          <button
            type="button"
            onClick={this.save}
            className="btn btn-default"
            disabled={!this.state.canSave}
          >
            Save Role
          </button>
          <button
            type="button"
            onClick={this.cancel}
            className="btn btn-default"
          >
            Cancel
          </button>
          <br />
          <br />
        </Formsy>
      </div>
    );
  }
}

//const mapStateToProps = (state) => ({});

export default connect(
  null,
  {
    executeAuthAsyncGet,
    executeAuthAsyncPost,
    executeAsyncResetGet
  },
  null,
  { forwardRef: true }
)(AddRole);
