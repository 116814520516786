import { LogLevel } from "@azure/msal-browser"

const tpClients = ["tp", "travis", "tptest", "tpfinuat"]
const phClients = ["ph", "city", "cityplumbing"]
const adeccoClients = ["adeccouk", "adeccoglobal", "adecco"]

export const getB2cPolicy = history => {
    return {
        authorities: {
            signUpSignIn: {
                authority: getSignUpSignInAuthority(history)
            }
        },
        authorityDomain: "login.invevo.com"
    }
}

const getSignUpSignInAuthority = history => {
    const clientFromUrl = getClientFromDomain(history)
    if (tpClients.includes(clientFromUrl)) {
        return "https://login.invevo.com/InvevoB2C.onmicrosoft.com/B2C_1_signup_signin_tp_and_ph_google"
    }

    if (phClients.includes(clientFromUrl)) {
        return "https://login.invevo.com/InvevoB2C.onmicrosoft.com/B2C_1_City_Plumbing"
    }

    if (adeccoClients.includes(clientFromUrl)) {
        return "https://login.invevo.com/InvevoB2C.onmicrosoft.com/B2C_1A_Adecco_signup_signin"
    }

    return "https://login.invevo.com/InvevoB2C.onmicrosoft.com/B2C_1_invevo_signup_signin"
}

const getClientFromDomain = history => {
    const clientFromSearch = new URLSearchParams(history.location.search).get("client")

    if (!clientFromSearch) {
        const clientFromSubdomain = window.location.href.split(".")[0].replace("https://", "")
        if (clientFromSubdomain === "www") return undefined
        return clientFromSubdomain
    }

    return clientFromSearch
}

export const getMsalConfig = (history, preAuthRoute) => {
    return {
        auth: {
            clientId: "1b5459aa-bee1-40e2-92ba-b6bc6c5c45c8",
            authority: getB2cPolicy(history).authorities.signUpSignIn.authority,
            knownAuthorities: [getB2cPolicy(history).authorityDomain],
            redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
            postLogoutRedirectUri: "/",
            navigateToLoginRequestUrl: false
        },
        cache: {
            storeAuthStateInCookie: false
        },
        system: {
            allowRedirectInIframe: true,
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message)
                            return
                        case LogLevel.Info:
                        case LogLevel.Verbose:
                            return
                        default:
                            console.warn(message)
                            return
                    }
                }
            }
        }
    }
}
