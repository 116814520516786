import { getMsalConfig } from './config';
import { PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import PostAuthLoader from './PostAuthLoader';
import PerformLogin from './PerformLogin';
import { useHistory } from 'react-router-dom';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { PRE_AUTH_ROUTE_KEY } from './constants';

const Authenticated = ({ children }) => {
    const history = useHistory()
    const [preAuthRoute] = useSessionStorage(PRE_AUTH_ROUTE_KEY)

    const msalInstance = new PublicClientApplication(getMsalConfig(history, preAuthRoute))

    return (
        <MsalProvider instance={msalInstance}>
            <AuthenticatedTemplate>
                <PostAuthLoader>{children}</PostAuthLoader>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <PerformLogin msalInstance={msalInstance}>
                    {children}
                </PerformLogin>
            </UnauthenticatedTemplate>
        </MsalProvider>
    )
}

export default Authenticated