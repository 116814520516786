import classes from "./GridHeader.module.scss"
import { Toggle } from "invevo-react-components"
import BlueButton from "../../../../library/buttons/BlueButton/BlueButton"
import Lookup from "../../../../types/Lookup"
import QuickFilter from "../../types/QuickFilter"

type GridHeaderProps = {
    lookups: Lookup[]
    isLoading: boolean
    quickFilters: QuickFilter[]
    onQuickFiltersUpdated: (filters: QuickFilter[]) => void
    showingQuickFilters: boolean
    onQuickFiltersToggled: () => void
}

const GridHeader = ({ lookups, isLoading, quickFilters, onQuickFiltersUpdated, showingQuickFilters, onQuickFiltersToggled }: GridHeaderProps) => {
    const removeQuickFilter = (quickFilter: QuickFilter) => {
        if (isLoading) return

        onQuickFiltersUpdated(quickFilters.filter(f => f !== quickFilter))
    }

    const getDisplayNameForLookup = (lookupRef: string, data: string) =>
        lookups.find(l => l.reference === lookupRef)?.entries.find(e => e.reference.toLowerCase() === data?.toLowerCase())?.name || data

    const clearFilters = () => onQuickFiltersUpdated([])

    return (
        <div className={`w-100 rounded-top d-flex align-items-start px-2 bg-blue `}>
            <div className={`d-flex align-items-center p-2 w-100 ${classes.container}`}>
                <span className="text-uppercase text-white me-2 no-select">Filters</span>
                <i className="text-white fal fa-filter"></i>
                {quickFilters &&
                    quickFilters.map(quickFilter => (
                        <div className={`ms-2 ${classes.selectedFilter} px-2 py-1 d-flex align-items-center`} key={quickFilter.filter.label}>
                            <span>
                                {quickFilter.field.label} | {getDisplayNameForLookup(quickFilter.field.lookup ?? "", quickFilter.filter.label)}
                                <i
                                    className="ms-2 fa-light fa-times-circle pointer"
                                    role="button"
                                    aria-label={"remove-filter-" + quickFilter.filter.label}
                                    onClick={() => removeQuickFilter(quickFilter)}
                                />
                            </span>
                        </div>
                    ))}
                {quickFilters.length > 0 && (
                    <BlueButton
                        className={`ms-auto ${classes.clearFiltersButton}`}
                        label="Clear filters"
                        onClick={clearFilters}
                        ariaLabel="clear-filters-button"
                    />
                )}
                <div className={`${quickFilters.length > 0 ? "ms-2" : "ms-auto"} d-flex align-items-center py-1 px-2 text-white`}>
                    <Toggle status={showingQuickFilters} onStatusChanged={onQuickFiltersToggled} ariaLabel="show-quick-filters" />
                    <span className="ms-2">Show Quick Filters</span>
                </div>
            </div>
        </div>
    )
}

export default GridHeader
