import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { find, isNil, map, reduce } from 'lodash';

class Dropdown extends Component {
  renderControl() {
    const disabled = this.props.disabled || false;
    const idPropName = this.props.idProp || 'id';
    const textPropName = this.props.textProp || 'text';
    const buttonClass =
      this.props.className || 'btn btn-default dropdown-toggle inv';
    const title = this.props.title || '';
    let found;
    const titleText =
      !isNil(this.props.selectedItemId) &&
        (found = find(
          this.props.data,
          (o) => o[idPropName] === this.props.selectedItemId
        ))
        ? found[textPropName]
        : this.props.title;
    const cssClassNames =
      'dropdown-menu ' + (this.props.scroll ? ` dropdown-scroll` : '');

    return (
      <div>
        <button
          id="dropdownMenuButton"
          className={buttonClass}
          disabled={disabled}
          type="button"
          data-bs-toggle="dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
          title={title}
        >
          {titleText} <b className="caret" />
        </button>
        {this.props.required ? (
          <span className="form-control-feedback">
            <i className='fa-solid fa-asterisk'></i>
          </span>
        ) : (
          ''
        )}
        <ul className={cssClassNames} aria-labelledby="dropdownMenuButton">
          {map(this.props.data, (row) => {
            const id = row[idPropName];
            const text = row[textPropName];
            return (
              <li
                key={id}
                className="dropdown-item"
                onClick={() => this.props.onClick(id, text)}
              >
                {text}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  renderRequired() {
    return (
      <div className="form-control-feedback-wrapper warning">
        {this.renderControl()}
      </div>
    );
  }

  render() {
    return (
      <div className="dropdown">
        {this.props.required ? this.renderRequired() : this.renderControl()}
      </div>
    );
  }
}

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  idProp: PropTypes.string,
  textProp: PropTypes.string,
  selectedItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  scroll: PropTypes.bool,
  required: PropTypes.bool
};

Dropdown.defaultProps = {
  data: []
};

export default Dropdown;

export const enumToData = (e) =>
  reduce(
    e,
    (result, value) => {
      result.push({ id: value, text: value });

      return result;
    },
    []
  );
