import React from 'react';
import { connect } from 'react-redux';
import { getApiUrl } from '../../../constants';
import { executeAuthAsyncPost } from '../../../utility/asyncSupport';
import {
  showToastSuccessMessage,
  showToastErrorMessage
} from '../../../api/toasterApi.js';

import {
  IsDefaultRole,
  IsFinancialRole,
  HasTransactionFilters,
  FullCustomerView,
  DisplayAsInternalContact,
  IsDashboardContact
} from '../../common/grids/gridCells';
import EditDeleteCommandCell from '../../common/grids/editDeleteCommandCell';
import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../../common/grids/StatefulGrid';
import { ConfrimDialog } from '../../common/confirmDialog';

export class RoleGrid extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      refresh: 0,
      showDeleteDialog: false,
      itemToDelete: ''
    };

    this.EditDeleteCommand = EditDeleteCommandCell({
      onEditClick: this.onEditRowClicked,
      onDeleteClick: this.onDeleteRowClicked
    });
  }

  componentDidMount() {
    if (this.props.refreshCallback != null) {
      this.props.refreshCallback(this.refresh);
    }
  }

  refresh = () => {
    this.setState({ refresh: this.state.refresh + 1 });
  };

  onEditRowClicked = (e, dataItem) => {
    e.preventDefault();
    const roleId = dataItem.Id;
    this.props.onEdit(roleId);
  };

  onDeleteRowClicked = (e, dataItem) => {
    e.preventDefault();

    if (dataItem.IsDefaultRole) {
      showToastErrorMessage(
        'Unable to delete the default role, please set another role as default first'
      );
    } else {
      this.setState({ showDeleteDialog: true, itemToDelete: dataItem.Id });
    }
  };

  deleteItem = () => {
    this.closeDialog();
    this.props.executeAuthAsyncPost(
      this.props.client,
      'roles/delete',
      'DELETE_ROLE',
      this.state.itemToDelete,
      (result) => {
        this.onDeleteSuccess(result);
      }
    );
  };

  closeDialog = () => {
    this.setState({
      showDeleteDialog: false
    });
  };

  onDeleteSuccess = (response) => {
    if (response.IsDeleted) {
      showToastSuccessMessage('Role Deleted');
      this.closeDialog();
      this.refresh();
    } else {
      showToastErrorMessage(response.Error);
    }
  };

  static getKey() {
    return 'role-grid';
  }

  render() {
    return (
      <>
        <StatefulGrid
          client={this.props.client}
          id={RoleGrid.getKey()}
          key={RoleGrid.getKey()}
          path={getApiUrl(this.props.client) + 'api/roles'}
          pageSize={10}
          sortable
          filterable
          pageable={{
            pageSizes: [10, 50, 100],
            buttonCount: 5
          }}
          onRowClick={this.onRowClick}
          defaultField={{ field: 'Name', dir: 'asc' }}
          refresh={this.state.refresh}
        >
          <GridColumn field={'Name'} title={'Role'} />
          <GridColumn
            field={'IsDefaultRole'}
            title={'Default'}
            cell={IsDefaultRole}
            filter={'boolean'}
          />
          <GridColumn
            field={'IsFinancialRole'}
            title={'Financial Role'}
            cell={IsFinancialRole}
            filter={'boolean'}
          />
          <GridColumn
            field={'HasTransactionFilters'}
            title={'Has Transaction Filters'}
            cell={HasTransactionFilters}
            filter={'boolean'}
          />
          <GridColumn
            field={'FullCustomerView'}
            title={'Full Customer View'}
            cell={FullCustomerView}
            filter={'boolean'}
          />
          <GridColumn
            field={'DisplayAsInternalContact'}
            title={'Display As Internal Contact'}
            cell={DisplayAsInternalContact}
            filter={'boolean'}
          />
          <GridColumn
            field={'IsDashboardContact'}
            title={'Dashboard Contact'}
            cell={IsDashboardContact}
            filter={'boolean'}
          />
          <GridColumn
            cell={this.EditDeleteCommand}
            width={'250px'}
            filterable={false}
          />
        </StatefulGrid>
        <ConfrimDialog
          show={this.state.showDeleteDialog}
          message={'Are you sure you want to delete this role?'}
          onConfirm={this.deleteItem}
          onClose={this.closeDialog}
        />
      </>
    );
  }
}

export default connect(null, {
  executeAuthAsyncPost
})(RoleGrid);
