import classes from './FieldConfig.module.scss'
import { Checkbox, Dropdown, FormLabel, Input, Toggle } from 'invevo-react-components'
import { fieldDataTypes } from '../../enums/fieldDataType'

const FieldConfig = ({ state, className = "", onFieldConfigChange }) => {
    const dataTypeOptions = [
        { id: 1, value: fieldDataTypes.TEXT }, { id: 2, value: fieldDataTypes.NUMBER }, { id: 3, value: fieldDataTypes.DATE }
    ]

    const onDataFieldTypeChange = (dataType) => {
        onFieldConfigChange({ ...state.config, fieldDataType: dataType.value, isAvailableInAutoComplete: dataType.value === fieldDataTypes.TEXT && state.config.isAvailableInAutoComplete })
    }

    const onAutoCompleteChange = () => {
        onFieldConfigChange({ ...state.config, isAvailableInAutoComplete: !state.config.isAvailableInAutoComplete })
    }

    const onUiFieldChange = (event) => {
    }

    const onIsAvailableInGroupsChange = () => {
        onFieldConfigChange({ ...state.config, isAvailableInGroups: !state.config.isAvailableInGroups })
    }

    const onIsAvailableAsUserDataFilterChange = () => {
        onFieldConfigChange({ ...state.config, isAvailableAsUserDataFilter: !state.config.isAvailableAsUserDataFilter })
    }

    const onIsTemplatePlaceholderChange = () => {
        onFieldConfigChange({ ...state.config, isTemplatePlaceholder: !state.config.isTemplatePlaceholder })
    }

    const onIsAvailableInSearchChange = () => {
        onFieldConfigChange({ ...state.config, isAvailableInSearch: !state.config.isAvailableInSearch })
    }

    const onIsAvailableInUiChange = () => {
        onFieldConfigChange({ ...state.config, isAvailableInUi: !state.config.isAvailableInUi })
    }

    const onIsAvailableForDataExportChange = () => {
        onFieldConfigChange({ ...state.config, isAvailableForDataExport: !state.config.isAvailableForDataExport })
    }

    const onIsAvailableInPortalChange = () => {
        onFieldConfigChange({ ...state.config, isAvailableInPortal: !state.config.isAvailableInPortal })
    }

    const isAutoCompleteSelected = () => {
        return state.config.fieldDataType === fieldDataTypes.TEXT && state.config.isAvailableInAutoComplete
    }

    return (
        <div className={`d-flex ${className}`}>
            <div className="bg-grey w-100 p-3">
                <div className="d-flex mb-2">
                    <i className={`${classes.icon} fal fa-database mt-1`} />
                    <div className="d-flex flex-grow-1 text-grey">

                        <div className={`d-flex flex-column pe-3 ${classes['config-item']} small`}>
                            <span className="text-uppercase small">Data field name</span>
                            <FormLabel className="mt-1" value={state.config.fieldName} enableTooltip={true} />
                        </div>

                        <div className={`d-flex flex-column pe-3 ${classes['config-item']} small`}>
                            <span className="text-uppercase small">Field data type</span>
                            <Dropdown
                                className="mt-1"
                                options={dataTypeOptions}
                                onOptionSelected={onDataFieldTypeChange}
                                defaultOption={dataTypeOptions.find(o => o.value === state.config.fieldDataType)}
                            />
                        </div>

                        <div className={`d-flex flex-column pe-3 ${classes['config-item']} small`}>
                            <span className="text-uppercase small">UI field name</span>
                            <Input
                                className="mt-1"
                                placeholder="Please enter field name"
                                value={state.config.fieldName}
                                onChange={onUiFieldChange}
                            />
                        </div>
                    </div>

                    <div className={`d-flex flex-column pe-3 ${classes['config-item']} small  ms-auto`}>
                        <span className="text-uppercase small">Auto complete</span>
                        <Toggle
                            className="mt-2 "
                            status={isAutoCompleteSelected()}
                            onStatusChanged={onAutoCompleteChange}
                            onText="On"
                            offText="Off"
                            disabled={state.config.fieldDataType !== fieldDataTypes.TEXT}
                        />
                    </div>
                </div>

                <div className="pt-3 d-flex flex-grow-1 border-top">
                    <i className={`${classes.icon}`} />
                    <div className="d-flex flex-column text-grey flex-grow-1">
                        <div className="d-flex w-100 small">
                            <Checkbox
                                className="w-25 small"
                                isChecked={state.config.isAvailableInGroups}
                                label="Available in groups"
                                onClick={onIsAvailableInGroupsChange}
                            />
                            <Checkbox
                                className="w-25 small"
                                isChecked={state.config.isAvailableAsUserDataFilter}
                                label="Available as user data filter"
                                onClick={onIsAvailableAsUserDataFilterChange}
                                disabled={true}
                            />
                            <Checkbox
                                className="w-25 small"
                                isChecked={state.config.isTemplatePlaceholder}
                                label="Is template placeholder"
                                onClick={onIsTemplatePlaceholderChange}
                                disabled={true}
                            />
                            <Checkbox
                                className="w-25 small"
                                isChecked={state.config.isAvailableInSearch}
                                label="Available in search"
                                onClick={onIsAvailableInSearchChange}
                                disabled={true}
                            />
                        </div>
                        <div className="d-flex w-100 mt-2 small">
                            <Checkbox
                                className="w-25 small"
                                isChecked={state.config.something}
                                label="Available in ui"
                                onClick={onIsAvailableInUiChange}
                                disabled={true}
                            />
                            <Checkbox
                                className="w-25 small"
                                isChecked={state.config.something}
                                label="Available for data export"
                                onClick={onIsAvailableForDataExportChange}
                                disabled={true}
                            />
                            <Checkbox
                                className="w-25 small"
                                isChecked={state.config.something}
                                label="Available in portal"
                                onClick={onIsAvailableInPortalChange}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FieldConfig