export enum actionTypes {
    AUTOCOMPLETE_CUSTOMER_FIELDS_RETRIEVED = "AUTOCOMPLETE_CUSTOMER_FIELDS_RETRIEVED",
    AUTOCOMPLETE_TRANSACTION_FIELDS_RETRIEVED = "AUTOCOMPLETE_TRANSACTION_FIELDS_RETRIEVED",

    DASHBOARD_CONFIGS_RETRIEVED = "DASHBOARD_CONFIGS_RETRIEVED",
    DASHBOARD_EDITED = "DASHBOARD_EDITED",
    DASHBOARD_CONFIG_SAVED = "DASHBOARD_CONFIG_SAVED",
    DASHBOARD_CHANGES_ARE_VALID = "DASHBOARD_CHANGES_ARE_VALID",
    DASHBOARD_CHANGES_ARE_INVALID = "DASHBOARD_CHANGES_ARE_INVALID",
    DASHBOARD_CONFIG_ADDED = "DASHBOARD_CONFIG_ADDED",
    NEW_DASHBOARD_CONFIG_INITIALISED = "NEW_DASHBOARD_CONFIG_INITIALISED",
    NEW_AGGREGATED_DASHBOARD_CONFIG_INITIALISED = "NEW_AGGREGATED_DASHBOARD_CONFIG_INITIALISED",
    DASHBOARD_CONFIG_DELETED = "DASHBOARD_CONFIG_DELETED",
    DASHBOARD_NAME_CHANGED = "DASHBOARD_NAME_CHANGED",
    DASHBOARD_SUBTYPE_CHANGED = "DASHBOARD_SUBTYPE_CHANGED",
    DASHBOARD_ENTITY_CONFIG_CHANGED = "DASHBOARD_ENTITY_CONFIG_CHANGED",
    DASHBOARD_FILTERS_CHANGED = "DASHBOARD_FILTERS_CHANGED",

    DASHBOARD_TYPE_CHANGED = "DASHBOARD_TYPE_CHANGED",

    ROLES_RETRIEVED = "ROLES_RETRIEVED",

    LOOKUPS_RETRIEVED = "LOOKUPS_RETRIEVED",
    ENTITY_CONFIGS_RETRIEVED = "ENTITY_CONFIGS_RETRIEVED",
    ENTITY_RELATIONSHIPS_RETRIEVED = "ENTITY_RELATIONSHIPS_RETRIEVED",

    DASHBOARD_ROLE_ASSIGNMENT_ADDED = "DASHBOARD_ROLE_ASSIGNMENT_ADDED",
    DASHBOARD_ROLE_ASSIGNMENT_REMOVED = "DASHBOARD_ROLE_ASSIGNMENT_REMOVED",

    INFO_WIDGET_IS_FIELD_LABEL_INLINE_DISABLED = "INFO_WIDGET_IS_FIELD_LABEL_INLINE_DISABLED",
    INFO_WIDGET_IS_FIELD_LABEL_INLINE_ENABLED = "INFO_WIDGET_IS_FIELD_LABEL_INLINE_ENABLED",
    INFO_WIDGET_ADDED = "INFO_WIDGET_ADDED",
    INFO_WIDGET_NAME_CHANGED = "INFO_WIDGET_NAME_CHANGED",
    INFO_WIDGET_FIELD_NAME_REMOVED = "INFO_WIDGET_FIELD_NAME_REMOVED",
    INFO_WIDGET_FIELD_NAME_CHANGED = "INFO_WIDGET_FIELD_NAME_CHANGED",
    INFO_WIDGET_FIELD_MOVED_UP = "INFO_WIDGET_FIELD_MOVED_UP",
    INFO_WIDGET_FIELD_MOVED_DOWN = "INFO_WIDGET_FIELD_MOVED_DOWN",
    INFO_WIDGET_NEW_FIELD_ADDED = "INFO_WIDGET_NEW_FIELD_ADDED",
    INFO_WIDGET_FIELD_EDITABLE_DISABLED = "INFO_WIDGET_FIELD_EDITABLE_DISABLED",
    INFO_WIDGET_FIELD_EDITABLE_ENABLED = "INFO_WIDGET_FIELD_EDITABLE_ENABLED",
    INFO_WIDGET_THEME_CHANGED = "INFO_WIDGET_THEME_CHANGED",

    VALUE_WIDGET_ADDED = "VALUE_WIDGET_ADDED",
    VALUE_WIDGET_NAME_CHANGED = "VALUE_WIDGET_NAME_CHANGED",
    VALUE_WIDGET_FIELD_NAME_SET = "VALUE_WIDGET_FIELD_NAME_SET",
    VALUE_WIDGET_THEME_CHANGED = "VALUE_WIDGET_THEME_CHANGED",

    SMALL_BAR_CHART_WIDGET_ADDED = "SMALL_BAR_CHART_WIDGET_ADDED",
    SMALL_BAR_CHART_WIDGET_NAME_CHANGED = "SMALL_BAR_CHART_WIDGET_NAME_CHANGED",
    SMALL_BAR_CHART_WIDGET_THEME_CHANGED = "SMALL_BAR_CHART_WIDGET_THEME_CHANGED",
    SMALL_BAR_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED = "SMALL_BAR_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED",

    AREA_CHART_WIDGET_ADDED = "AREA_CHART_WIDGET_ADDED",
    AREA_CHART_WIDGET_NAME_CHANGED = "AREA_CHART_WIDGET_NAME_CHANGED",
    AREA_CHART_WIDGET_THEME_CHANGED = "AREA_CHART_WIDGET_THEME_CHANGED",
    AREA_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED = "AREA_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED",

    LINE_CHART_WIDGET_ADDED = "LINE_CHART_WIDGET_ADDED",
    LINE_CHART_WIDGET_NAME_CHANGED = "LINE_CHART_WIDGET_NAME_CHANGED",
    LINE_CHART_WIDGET_THEME_CHANGED = "LINE_CHART_WIDGET_THEME_CHANGED",
    LINE_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED = "LINE_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED",

    SMALL_PIE_CHART_WIDGET_ADDED = "SMALL_PIE_CHART_WIDGET_ADDED",
    SMALL_PIE_CHART_WIDGET_NAME_CHANGED = "SMALL_PIE_CHART_WIDGET_NAME_CHANGED",
    SMALL_PIE_CHART_WIDGET_FIELD_NAME_CHANGED = "SMALL_PIE_CHART_WIDGET_FIELD_NAME_CHANGED",
    SMALL_PIE_CHART_WIDGET_FIELD_NAME_REMOVED = "SMALL_PIE_CHART_WIDGET_FIELD_NAME_REMOVED",
    SMALL_PIE_CHART_WIDGET_NEW_FIELD_ADDED = "SMALL_PIE_CHART_WIDGET_NEW_FIELD_ADDED",
    SMALL_PIE_CHART_WIDGET_THEME_CHANGED = "SMALL_PIE_CHART_WIDGET_THEME_CHANGED",
    SMALL_PIE_CHART_WIDGET_TYPE_CHANGED = "SMALL_PIE_CHART_WIDGET_TYPE_CHANGED",
    SMALL_PIE_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED = "SMALL_PIE_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED",

    TRANSACTIONS_GRID_WIDGET_ADDED = "TRANSACTIONS_GRID_WIDGET_ADDED",
    TRANSACTIONS_GRID_WIDGET_COLUMN_REMOVED = "TRANSACTIONS_GRID_WIDGET_COLUMN_REMOVED",
    TRANSACTIONS_GRID_WIDGET_COLUMN_CHANGED = "TRANSACTIONS_GRID_WIDGET_COLUMN_CHANGED",
    TRANSACTIONS_GRID_WIDGET_NEW_COLUMN_ADDED = "TRANSACTIONS_GRID_WIDGET_NEW_COLUMN_ADDED",

    GRID_WIDGET_ADDED = "GRID_WIDGET_ADDED",
    GRID_WIDGET_COLUMN_REMOVED = "GRID_WIDGET_COLUMN_REMOVED",
    GRID_WIDGET_COLUMN_CHANGED = "GRID_WIDGET_COLUMN_CHANGED",
    GRID_WIDGET_COLUMN_MOVED_UP = "GRID_WIDGET_COLUMN_MOVED_UP",
    GRID_WIDGET_COLUMN_MOVED_DOWN = "GRID_WIDGET_COLUMN_MOVED_DOWN",
    GRID_WIDGET_NEW_COLUMN_ADDED = "GRID_WIDGET_NEW_COLUMN_ADDED",
    GRID_WIDGET_ENTITY_CONFIG_CHANGED = "GRID_WIDGET_ENTITY_CONFIG_CHANGED",
    GRID_WIDGET_FILTERS_CHANGED = "GRID_WIDGET_FILTERS_CHANGED",
    GRID_WIDGET_TYPE_CHANGED = "GRID_WIDGET_TYPE_CHANGED",
    GRID_WIDGET_GROUPING_FIELD_CHANGED = "GRID_WIDGET_GROUPING_FIELD_CHANGED",
    GRID_WIDGET_DRAGGABLE_TOGGLED = "GRID_WIDGET_DRAGGABLE_TOGGLED",

    WIDGET_REMOVED = "WIDGET_REMOVED",
    WIDGET_LAYOUT_CHANGED = "WIDGET_LAYOUT_CHANGED",

    CUSTOMER_DASHBOARDS_RETRIEVED = "CUSTOMER_DASHBOARDS_RETRIEVED",
    CUSTOMER_DASHBOARD_SELECTED = "CUSTOMER_DASHBOARD_SELECTED",
    SELECTED_CUSTOMER_DASHBOARD_CLEARED = "SELECTED_CUSTOMER_DASHBOARD_CLEARED",
    CUSTOMER_DASHBOARD_OPTIONS_RETRIEVED = "CUSTOMER_DASHBOARD_OPTIONS_RETRIEVED",
    CUSTOMER_FIELD_VALUE_UPDATED = "CUSTOMER_FIELD_VALUE_UPDATED",
    CLIENT_USER_DASHBOARDS_CONFIG_RETRIEVED = "CLIENT_USER_DASHBOARDS_CONFIG_RETRIEVED",
    TOGGLE_DISPLAYED_COLUMN_IN_TRANSACTION_GRID = "TOGGLE_DISPLAYED_COLUMN_IN_TRANSACTION_GRID",
    RESET_DISPLAYED_COLUMN_IN_TRANSACTION_GRID = "RESET_DISPLAYED_COLUMN_IN_TRANSACTION_GRID",

    TRANSACTION_DATA_RETRIEVED = "TRANSACTION_DATA_RETRIEVED",
    TRANSACTION_DATA_UPDATED = "TRANSACTION_DATA_UPDATED",
    TRANSACTIONS_DATA_SORTED = "TRANSACTIONS_DATA_SORTED",
    TRANSACTIONS_DATA_PAGE_CHANGED = "TRANSACTIONS_DATA_PAGE_CHANGED",
    TRANSACTION_FIELDS_RETRIEVED = "TRANSACTION_FIELDS_RETRIEVED",
    TRANSACTION_AGGREGATIONS_RETRIEVED = "TRANSACTION_AGGREGATIONS_RETRIEVED",

    CUSTOMER_FIELDS_RETRIEVED = "CUSTOMER_FIELDS_RETRIEVED",
    COMMENTS_RETRIEVED = "COMMENTS_RETRIEVED",
    COMMENT_ADDED = "COMMENT_ADDED",

    ENTITY_GRID_CHART_WIDGET_ADDED = "ENTITY_GRID_CHART_WIDGET_ADDED",

    ENTITY_CHART_WIDGET_NAME_CHANGED = "ENTITY_CHART_WIDGET_NAME_CHANGED",
    ENTITY_CHART_WIDGET_THEME_CHANGED = "ENTITY_CHART_WIDGET_THEME_CHANGED",
    ENTITY_CHART_WIDGET_CHART_TYPE_CHANGED = "ENTITY_CHART_WIDGET_CHART_TYPE_CHANGED",
    ENTITY_CHART_WIDGET_GROUPING_TYPE_CHANGED = "ENTITY_CHART_WIDGET_GROUPING_TYPE_CHANGED",
    ENTITY_CHART_WIDGET_AGGREGATION_TYPE_CHANGED = "ENTITY_CHART_WIDGET_AGGREGATION_TYPE_CHANGED",
    ENTITY_CHART_WIDGET_GROUPING_FIELD_CHANGED = "ENTITY_CHART_WIDGET_GROUPING_FIELD_CHANGED",
    ENTITY_CHART_WIDGET_AGGREGATION_FIELD_CHANGED = "ENTITY_CHART_WIDGET_AGGREGATION_FIELD_CHANGED",
    ENTITY_CHART_WIDGET_NEW_MULTI_FIELD_CHANGED = "ENTITY_CHART_WIDGET_NEW_MULTI_FIELD_CHANGED",
    ENTITY_CHART_WIDGET_NEW_MULTI_FIELD_ADDED = "ENTITY_CHART_WIDGET_NEW_MULTI_FIELD_ADDED",
    ENTITY_CHART_WIDGET_NEW_MULTI_FIELD_REMOVED = "ENTITY_CHART_WIDGET_NEW_MULTI_FIELD_REMOVED",
    ENTITY_CHART_WIDGET_FILTERS_CHANGED = "ENTITY_CHART_WIDGET_FILTERS_CHANGED",
    ENTITY_CHART_WIDGET_ENTITY_CONFIG_CHANGED = "ENTITY_CHART_WIDGET_ENTITY_CONFIG_CHANGED",
    ENITTY_CHART_WIDGET_SORT_FIELD_CHANGED = "ENITTY_CHART_WIDGET_SORT_FIELD_CHANGED",
    ENITTY_CHART_WIDGET_SORT_DIR_CHANGED = "ENITTY_CHART_WIDGET_SORT_DIR_CHANGED",

    TRENDING_WIDGET_ADDED = "TRENDING_WIDGET_ADDED",
    TRENDING_WIDGET_ENTITY_CONFIG_CHANGED = "TRENDING_WIDGET_ENTITY_CONFIG_CHANGED",
    TRENDING_WIDGET_CHART_TYPE_CHANGED = "TRENDING_WIDGET_CHART_TYPE_CHANGED",
    TRENDING_WIDGET_GROUPING_FIELD_CHANGED = "TRENDING_WIDGET_GROUPING_FIELD_CHANGED",
    TRENDING_WIDGET_COMPARISON_FIELD_CHANGED = "TRENDING_WIDGET_COMPARISON_FIELD_CHANGED",
    TRENDING_WIDGET_FILTERS_CHANGED = "TRENDING_WIDGET_FILTERS_CHANGED",
    TRENDING_WIDGET_PERIOD_CHANGED = "TRENDING_WIDGET_PERIOD_CHANGED",
    TRENDING_WIDGET_SHOW_VALUES_CHANGED = "TRENDING_WIDGET_SHOW_VALUES_CHANGED"
}
