import { IconButton } from "invevo-react-components"
import Role from "../types/Role"
import RoleCard from "./RoleCard"
import classes from "./RoleList.module.scss"

type RoleListProps = {
    roles: Role[]
    originalRoles: Role[]
    selectedRoleReference: string | undefined
    onRoleSelected: (reference: string) => void
    onNewRoleAdded: () => void
    onRoleRemoved: (reference: string) => void
}

const RoleList = ({ roles, originalRoles, selectedRoleReference, onRoleSelected, onNewRoleAdded, onRoleRemoved }: RoleListProps) => {
    return (
        <div className={`d-flex flex-column bg-grey h-100 overflow-hidden ${classes.container}`} role="list" aria-label="roles">
            <div className="d-flex align-items-center justify-content-between px-3 pt-3 mb-2">
                <h2 className="text-grey no-select fw-light mb-0">Roles</h2>
                <IconButton iconClasses="fal fa-plus" onClick={onNewRoleAdded} className={classes.iconButton} ariaLabel="add-role" />
            </div>
            <div className="d-flex flex-column h-100 p-3 overflow-auto">
                {roles.map(role => (
                    <RoleCard
                        role={role}
                        isSelected={selectedRoleReference === role.reference}
                        isNewRole={
                            role.reference === selectedRoleReference && originalRoles.find(role => role.reference === selectedRoleReference) === undefined
                        }
                        onRoleSelected={onRoleSelected}
                        onRoleRemoved={onRoleRemoved}
                        key={role.reference}
                    />
                ))}
            </div>
        </div>
    )
}

export default RoleList
