import { Loading, useClient, useConfig, useUser } from "invevo-react-components"
import { ReactNode, useState } from "react"
import DataField from "../../types/DataField"
import SortDirection from "../../types/enums/SortDirection"
import Lookup from "../../types/Lookup"
import ColumnHeading from "./ColumnHeading"
import classes from "./DataGrid.module.scss"
import GridRow from "./GridRow"
import DataRow from "./types/DataRow"
import DataGridState from "./types/DataGridState"
import Footer from "./Footer"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import ColumnConfigButton from "./ColumnConfigButton"
import useApiQuery from "../../hooks/useApiQuery"
import GridColumnConfigDto from "./types/GridColumnConfigDto"
import { defaultDisplayedEntityFields, defaultDisplayedFields } from "./Constants"
import { useFeatureToggle } from "../../hooks/useFeatureToggle"

type DataGridProps = {
    gridReference: string
    ariaLabel?: string
    fields: DataField[]
    defaultFields?: GridColumnConfigDto[]
    lookups: Lookup[]
    state: DataGridState
    onStateChanged?: (state: DataGridState) => void
    renderHeader?: () => ReactNode
    renderFooter?: () => ReactNode
    renderGlobalActions?: () => ReactNode
    renderRowActions?: (dataRow: DataRow) => ReactNode
}

/**
 * @deprecated Data grid is deprecated. Replace data grid v2.
 */

const DataGrid = ({
    gridReference,
    ariaLabel = "",
    fields,
    defaultFields,
    lookups,
    state,
    onStateChanged,
    renderHeader,
    renderGlobalActions,
    renderRowActions,
    renderFooter
}: DataGridProps) => {
    const { isEnabled: isNewEntityStructureEnabled } = useFeatureToggle("newEntityStructure")
    const defaultFieldsWithFallbackDefault = defaultFields ?? (isNewEntityStructureEnabled ? defaultDisplayedEntityFields : defaultDisplayedFields)

    const [displayedFields, setDisplayedFields] = useState<DataField[]>([])

    const currentUser = useUser()
    const client = useClient()
    const config = useConfig()

    const getGridConfigRequest = useApiQuery<GridColumnConfigDto[]>({
        url: `${config.DATA_API_URL}/api/${client}/user-grid-config/${currentUser.username}?gridReference=${gridReference}`,
        method: "GET",
        onSuccess: data => {
            const fieldValues = data.length === 0 ? defaultFieldsWithFallbackDefault : data
            const displayedFields: DataField[] = mapToDataFields(fieldValues)
            setDisplayedFields(displayedFields)
        },
        onError: () => {
            const displayedFields: DataField[] = mapToDataFields(defaultFieldsWithFallbackDefault)
            setDisplayedFields(displayedFields)
        }
    })

    const saveGridConfigRequest = useApiQuery({
        url: `${config.DATA_API_URL}/api/${client}/user-grid-config/${currentUser.username}`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    const mapToDataFields = (gridConfigDtos: GridColumnConfigDto[]) =>
        gridConfigDtos
            .map(gridConfigDto => {
                const matchingField = fields.find(dataField => dataField.value === gridConfigDto.fieldName) as DataField
                if (matchingField !== undefined) {
                    return { ...matchingField, ordinal: gridConfigDto.ordinal }
                }

                return { ...(matchingField as DataField), value: gridConfigDto.fieldName, ordinal: gridConfigDto.ordinal }
            })
            .filter(field => field !== undefined)
            .sort((a, b) => (a.ordinal > b.ordinal ? 1 : 0))

    const onToggleSort = (field: DataField) => {
        if (state.sortField?.value === field.value) {
            onStateChanged &&
                onStateChanged({
                    ...state,
                    sortDirection: state.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
                })
        } else {
            onStateChanged &&
                onStateChanged({
                    ...state,
                    sortField: field,
                    sortDirection: SortDirection.ASC
                })
        }
    }

    const onCurrentPageSizeChanged = (pageSize: number) =>
        onStateChanged &&
        onStateChanged({
            ...state,
            pageSize
        })

    const onPageIndexChanged = (pageIndex: number) =>
        onStateChanged &&
        onStateChanged({
            ...state,
            pageIndex
        })

    const onColumnOrderChanged = (updatedDisplayedFields: DataField[]) => {
        setDisplayedFields(updatedDisplayedFields)

        saveGridConfigRequest.execute(undefined, {
            gridReference: gridReference,
            displayedColumns: updatedDisplayedFields.map(field => {
                return { fieldName: field.value, ordinal: field.ordinal }
            })
        })
    }

    const distinctDisplayedFields = displayedFields.filter((field, index, self) => self.findIndex(f => f.value === field.value) === index)

    return (
        <div className="d-flex flex-column overflow-auto rounded h-100 w-100" role="grid" aria-label={ariaLabel}>
            {renderHeader && renderHeader()}
            <div className="d-flex flex-grow-1 overflow-auto h-100">
                <table className={`w-100 bg-blue px-2 ${classes.table}`}>
                    <thead>
                        <tr className={`${classes.head} bg-blue`}>
                            <DndProvider backend={HTML5Backend}>
                                {distinctDisplayedFields.map(field => (
                                    <ColumnHeading
                                        key={field.value}
                                        field={field}
                                        displayedFields={distinctDisplayedFields}
                                        selectedSortDirection={field.value === state.sortField?.value ? state.sortDirection : undefined}
                                        onToggleSort={onToggleSort}
                                        onColumnOrderChanged={onColumnOrderChanged}
                                    />
                                ))}
                            </DndProvider>
                            <th className={`${classes.stickRight} ${classes.headSide}`}>
                                <div className="d-flex justify-content-end pe-1">
                                    <ColumnConfigButton
                                        gridReference={gridReference}
                                        fields={fields}
                                        displayedFields={distinctDisplayedFields}
                                        onDisplayedFieldsChanged={setDisplayedFields}
                                        defaultFields={defaultFieldsWithFallbackDefault}
                                    />
                                    {renderGlobalActions && <>{renderGlobalActions()}</>}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.isLoading || getGridConfigRequest.isFetching ? (
                            <tr>
                                <td colSpan={fields.length + 1}>
                                    <Loading isLoading={true} colour="white">
                                        <div className="d-flex flex-grow-1 p-5" />
                                    </Loading>
                                </td>
                            </tr>
                        ) : (
                            state.dataRows.map(dataRow => (
                                <GridRow
                                    key={dataRow.id}
                                    dataRow={dataRow}
                                    dataFields={distinctDisplayedFields}
                                    lookups={lookups}
                                    renderRowActions={renderRowActions}
                                />
                            ))
                        )}
                        <tr>
                            <td className="h-100" colSpan={distinctDisplayedFields.length + 1}>
                                {state.dataRows.length === 0 && !state.isLoading && (
                                    <h3 className="d-flex justify-content-center text-white">No results found matching your search criteria</h3>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Footer
                isLoading={state.isLoading}
                totalRowCount={state.totalRowCount}
                currentPageSize={state.pageSize}
                onCurrentPageSizeChanged={onCurrentPageSizeChanged}
                pageIndex={state.pageIndex}
                onPageIndexChanged={onPageIndexChanged}
                renderFooter={renderFooter}
            />
        </div>
    )
}

export default DataGrid
