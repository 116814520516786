import classes from "./EditLogicOperator.module.scss"
import { LogicOperatorOptions } from "invevo-react-components"
import LogicOperatorButton from "./LogicOperatorButton"

const EditLogicOperator = ({ operator, onOperatorChange }) => {
    return (
        <div className="d-flex position-relative">
            <div className="invisible"><LogicOperatorButton /></div>
            <div className={`position-absolute d-flex flex-column flex-grow-1 bg-white ${classes.container} shadow`}>

                <div className="d-flex px-1 align-items-center pointer" onClick={() => onOperatorChange(LogicOperatorOptions.IN)}>
                    <i className="fal fa-equals mx-1 text-blue" />
                    <span className="ms-2 text-nowrap">Is</span>
                    {operator === LogicOperatorOptions.IN && <i className="mx-1 text-blue far fa-check" />}
                </div>

                <div className="border border-1"></div>

                <div className="d-flex ps-1 pe-2 align-items-center pointer" onClick={() => onOperatorChange(LogicOperatorOptions.NOT_IN)}>
                    <i className="fal fa-not-equal mx-1 text-blue" />
                    <span className="ms-2 text-nowrap">Is not</span>
                    {operator === LogicOperatorOptions.NOT_IN && <i className="mx-1 text-blue far fa-check" />}
                </div>

            </div>
        </div>
    )
}

export default EditLogicOperator