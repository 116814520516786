import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import DatePicker from "react-datepicker"
import { isNil } from "lodash"
import { useEffect } from "react"
import useDeepCompareEffect from "use-deep-compare-effect"
import { executeAuthAsyncGet, executeAuthAsyncPost, executeAsyncResetPost } from "../../utility/asyncSupport"
import { frequencyIds, typeIds, paymentDayMonthDays, paymentDayDays, MIN_INSTALLMENTS } from "./repaymentConstants"
import { useRepaymentPlan, setPlanInterest, setPlanStartDate, updatePlanInvoices } from "../../contexts/repaymentPlanContext"
import StatefulSelect from "../common/statefulSelect"
import { authZ_Permissions } from "../../constants"
import Money from "../common/money"
import { useClient } from "invevo-react-components"
import classes from "./repaymentPlan.module.scss"
import { useCustomer } from "../../../../contexts/CustomerContext"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"
import { useSelectedEntities } from "../../../../contexts/SelectedEntitiesContext"

function normaliseNumber(n) {
    return n === "" ? null : Number(n)
}

const PlanForm = ({ onCreate, isValid, setIsValid, formState, setFormState }) => {
    const client = useClient()
    const [status, setStatus] = useState("idle")

    const [transactionRefs, setTransactionRefs] = useState([])
    const [customer] = useCustomer()
    const { selectedRefs } = useSelectedEntities()

    const { isEnabled: repaymentPlanTermsValue } = useFeatureToggle("repaymentPlanTermsValue")
    const { isEnabled: newEntityStructure } = useFeatureToggle("newEntityStructure")

    const accountId = useSelector(state => state.currentSelectionReducer.selectedAccount.miaAccountId)

    const repaymentPlanLimitForUser = useSelector(state => state.asyncReducer.REPAYMENT_PLAN_LIMIT_FOR_USER_COMMIT.result)

    const dispatch = useDispatch()

    const [{ repaymentPlan }, repaymentPlanDispatch] = useRepaymentPlan()

    const showMonth = formState.frequencyId === 3 || formState.frequencyId === 4

    const invoiceTotal = repaymentPlan.sourceInvoicesTotal
    useEffect(() => {
        setFormState(formState => ({
            ...formState,
            amount: invoiceTotal > 0 ? invoiceTotal.toFixed(2) : null
        }))
    }, [invoiceTotal, setFormState])

    useEffect(() => {
        if (newEntityStructure && selectedRefs["transaction"] && selectedRefs["transaction"].length > 0) {
            setTransactionRefs(selectedRefs["transaction"] ?? [])
        } else {
            setTransactionRefs(customer.selectedTransactions.map(t => t.transactionRef) || [])
        }
    }, [customer.selectedTransactions, newEntityStructure, selectedRefs])

    useEffect(() => {
        dispatch(
            executeAuthAsyncGet(client, "AuthZ/GetLimitForUserAndCustomer", "REPAYMENT_PLAN_LIMIT_FOR_USER_COMMIT", {
                customerId: accountId,
                permission: authZ_Permissions.RepaymentPlan
            })
        )
    }, [accountId, client, dispatch])

    // detect changes to trasaction refs
    useEffect(() => {
        setStatus("pending")
        dispatch(executeAsyncResetPost("INVOICES_FOR_REPAYMENT_PLAN"))

        const body = {
            customerId: accountId,
            transactionRefs: transactionRefs
        }
        dispatch(
            executeAuthAsyncPost(
                client,
                "RepaymentPlan/BuildRepaymentPlanInvoicesNew",
                "INVOICES_FOR_REPAYMENT_PLAN",
                body,
                invoicesResult => {
                    setStatus("resolved")
                    updatePlanInvoices(repaymentPlanDispatch, invoicesResult)
                },
                () => setStatus("rejected")
            )
        )
    }, [accountId, client, dispatch, repaymentPlanDispatch, transactionRefs])

    useDeepCompareEffect(() => {
        let newIsValid = false
        switch (formState.type) {
            case typeIds.payments:
                if (formState.paymentsAmount > 0) newIsValid = true
                break
            case typeIds.instalments:
                if (formState.instalments >= MIN_INSTALLMENTS) newIsValid = true
                break
            default:
                if (formState.term > 0) newIsValid = true
                break
        }

        if (newIsValid) {
            newIsValid =
                status !== "pending" &&
                formState.amount > 0 &&
                formState.amount < repaymentPlanLimitForUser &&
                formState.startDate &&
                Number.isFinite(formState.interestRate) &&
                formState.interestRate >= 0 &&
                formState.paymentDayId > 0
        }
        setIsValid(newIsValid)
    }, [formState, setFormState])

    const onChangeType = e => {
        const type = e.target.value
        setFormState(formState => ({ ...formState, type }))
    }

    const handleCreate = () => {
        if (isValid) onCreate(formState)
    }

    const onPaymentDayIdChange = paymentDayId => {
        setFormState(formState => ({
            ...formState,
            paymentDayId
        }))
    }

    return (
        <div className="container-fluid">
            <div className={`${classes["form-row"]}`}>
                <div className="form-group col-8 px-1">
                    <label htmlFor="repaymentPlanValue" className={"text-uppercase mb-0 mt-2"}>
                        Plan Value{" "}
                    </label>
                    <div className="input-group">
                        <div className="input-group-prepend d-flex">
                            <span className="input-group-text" id="basic-addon1">
                                {repaymentPlan.currency}
                            </span>
                        </div>
                        <input
                            type="number"
                            className={`form-control ${formState.amount > repaymentPlanLimitForUser && "text-danger"}`}
                            id="repaymentPlanValue"
                            aria-describedby="valueHelp"
                            readOnly={true}
                            placeholder="Select transactions..."
                            value={isNil(formState.amount) ? "" : formState.amount}
                            min={0.01}
                            onChange={e => {
                                const a = e.target.value
                                setFormState(formState => ({
                                    ...formState,
                                    amount: normaliseNumber(a)
                                }))
                            }}
                        />
                    </div>
                    <small id="valueHelp" className="form-text text-white">
                        {repaymentPlanLimitForUser > 0 && (
                            <span>
                                Max limit <Money number={repaymentPlanLimitForUser} />
                            </span>
                        )}
                    </small>
                </div>
                <div className="form-group col-4 px-1">
                    <label htmlFor="repaymentPlanValue" className={"text-uppercase mb-0 mt-2"}>
                        Interest
                    </label>
                    <div className="input-group">
                        <input
                            type="number"
                            className="form-control"
                            id="repaymentPlanValue"
                            aria-describedby="valueHelp"
                            placeholder=""
                            value={isNil(formState.interestRate) ? "" : formState.interestRate}
                            min={0}
                            onChange={e => {
                                const i = e.target.value
                                setPlanInterest(repaymentPlanDispatch, normaliseNumber(i))
                                setFormState(formState => ({
                                    ...formState,
                                    interestRate: normaliseNumber(i)
                                }))
                            }}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text">%</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${classes["form-row"]}`}>
                <div className="form-group col-12">
                    <label htmlFor="repaymentPlanValue" className={"text-uppercase"}>
                        Schedule
                    </label>
                    <select
                        className="form-control"
                        value={formState.frequencyId}
                        onChange={e => {
                            const frequencyId = normaliseNumber(e.target.value)
                            setFormState(formState => ({
                                ...formState,
                                frequencyId,
                                paymentDayId: 0
                            }))
                        }}
                    >
                        <option value={frequencyIds.WEEKLY}>Weekly</option>
                        <option value={frequencyIds.BIWEEKLY}>Bi-Weekly</option>
                        <option value={frequencyIds.MONTHLY}>Monthly</option>
                        {/* not used for now
            <option value={frequencyIds.QUARTERLY}>Quarterly</option> 
            */}
                    </select>
                </div>
            </div>

            <div className={`${classes["form-row"]}`}>
                <div className="col-12">
                    <hr className="my-1" />
                </div>
            </div>

            <div className={`${classes["form-row"]}`} onClick={() => onChangeType({ target: { value: "term" } })}>
                <div className="col-1">
                    <label>&nbsp;</label>
                </div>
                <div className="col-11">
                    <label htmlFor="term" className={"text-uppercase mb-0 mt-2"}>
                        Term
                    </label>
                </div>
            </div>

            <div className={`${classes["form-row"]}`} onClick={() => onChangeType({ target: { value: "term" } })}>
                <div className="col-1">
                    <input type="radio" value="term" name="plan-type" checked={formState.type === "term"} onChange={onChangeType} />
                </div>
                <div className="col-11">
                    <select
                        aria-label="repayment-plan-term"
                        disabled={formState.type !== "term"}
                        className="form-control"
                        value={formState.type === "term" ? formState.term : 0}
                        onChange={e => {
                            const t = e.target.value
                            setFormState(formState => ({
                                ...formState,
                                term: normaliseNumber(t)
                            }))
                        }}
                    >
                        <option value="0">Please select</option>
                        {Array.from({ length: repaymentPlanTermsValue ?? 24 }, (_, i) => (
                            <option key={i} value={i + 1}>
                                {i + 1} months
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className={`${classes["form-row"]}`} onClick={() => onChangeType({ target: { value: "instalments" } })}>
                <div className="col-1">
                    <label>&nbsp;</label>
                </div>
                <div className="col-11">
                    <label htmlFor="instalments" className={"text-uppercase mb-0 mt-2"}>
                        Number of instalments
                    </label>
                </div>
            </div>

            <div className={`${classes["form-row"]}`} onClick={() => onChangeType({ target: { value: "instalments" } })}>
                <div className="col-1">
                    <input
                        type="radio"
                        className="mt-3"
                        value="instalments"
                        name="plan-type"
                        checked={formState.type === "instalments"}
                        onChange={onChangeType}
                    />
                </div>
                <div className="col-11">
                    <select
                        disabled={formState.type !== "instalments"}
                        className="form-control"
                        value={formState.type === "instalments" ? formState.instalments : 0}
                        onChange={e => {
                            const n = e.target.value
                            setFormState(formState => ({
                                ...formState,
                                instalments: normaliseNumber(n)
                            }))
                        }}
                    >
                        <option value="0">Please select</option>
                        {Array.from({ length: repaymentPlanTermsValue ?? 24 }, (_, i) => (
                            <option key={i} value={i + 1}>
                                {i + 1}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className={`${classes["form-row"]}`}>
                <div className="col-12 my-3">
                    <hr className="my-1" />
                </div>
            </div>

            <div className="row">
                <div className="col-6 text-uppercase">First Payment Date</div>
                <div className="col-6 text-uppercase">Taken on</div>
            </div>
            <div className="row">
                <div className="col-6">
                    <label style={{ width: "150px" }}>
                        <DatePicker
                            name="startDate"
                            className="form-control"
                            dateFormat="dd MMMM yyyy"
                            minDate={new Date()}
                            selected={formState.startDate}
                            placeholderText="Please select"
                            popperPlacement="bottom-start"
                            onChange={startDate => {
                                setPlanStartDate(repaymentPlanDispatch, startDate)
                                setFormState(formState => ({
                                    ...formState,
                                    startDate
                                }))
                            }}
                        />
                        <i className="fa-regular fa-calender-alt right-icon" />
                    </label>
                </div>
                <div className="col-6">
                    <StatefulSelect
                        options={showMonth ? paymentDayMonthDays : paymentDayDays}
                        optionId={formState.paymentDayId}
                        onChange={onPaymentDayIdChange}
                        aria-label="repayment-plan-payment-day"
                    />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-12">
                    <label htmlFor="firstPayment" className={"text-uppercase mb-0 mt-2"}>
                        First payment amount
                    </label>
                    <div className="input-group">
                        <div className="input-group-prepend d-flex">
                            <span className="input-group-text" id="basic-addon1">
                                {repaymentPlan.currency}
                            </span>
                        </div>
                        <input
                            type="number"
                            className="form-control"
                            id="firstPayment"
                            min={0}
                            placeholder=""
                            value={isNil(formState.firstPayment) ? "" : formState.firstPayment}
                            onChange={e => {
                                const fp = e.target.value
                                setFormState(formState => ({
                                    ...formState,
                                    firstPayment: normaliseNumber(fp)
                                }))
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <button className={`btn ${classes["btn-custom"]} `} onClick={handleCreate} disabled={!isValid} aria-label="repayment-plan-create">
                        <i className="fa-solid fa-plus me-2" />
                        Create plan
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PlanForm
