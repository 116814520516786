import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GLOBAL_SETTINGS } from '../../constants';
import { uploadDocument, uploadTaskDocument } from '../../api/documentApi';
import { useDropzone } from 'react-dropzone';
import DocumentTypeSelect from '../common/documentTypeSelect';
import { showToastSuccessMessage, showToastErrorMessage } from '../../api/toasterApi';
import { Modal, Button } from 'react-bootstrap';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useClient } from 'invevo-react-components';
import TransactionRefAutocomplete from '../transactions/transactionRefAutoComplete';

const AddDocument = ({
  selectedAccount,
  onDocumentUploaded,
  onCancel,
  selectedTaskEditId,
  transactionIds,
  uploadDocument,
  uploadTaskDocument
}) => {
  const client = useClient()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    documentName: '',
    documentTypeId: 0
  });
  const [selectedTransactionId, setSelectedTransactionId] = useState()

  const { doNotAcceptCsvAsValidFileType } = useFlags();

  const {
    isDragActive,
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: doNotAcceptCsvAsValidFileType ? GLOBAL_SETTINGS.accepted_file_types_excluding_csv : GLOBAL_SETTINGS.accepted_file_types,
    maxSize: GLOBAL_SETTINGS.max_upload_bytes
  });

  const canSubmit = () =>
    formData.documentName &&
    formData.documentTypeId > 0 &&
    acceptedFiles &&
    acceptedFiles.length === 1 &&
    !isSubmitting;

  const onSubmit = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsSubmitting(true);

    const file = acceptedFiles[0];

    const onSuccess = () => {
      setIsSubmitting(false);
      const fileName = file.name;
      onDocumentUploaded();
      showToastSuccessMessage(`${fileName}`);
    };

    const onFail = (errorText) => {
      setIsSubmitting(false);
      const fileName = file.name;
      showToastErrorMessage(`Failed to upload ${fileName}: ${errorText}`);
    };

    const { documentName, documentTypeId } = formData;
    if (selectedTaskEditId) {
      uploadTaskDocument(
        client,
        file,
        onSuccess,
        onFail,
        selectedAccount,
        documentName,
        documentTypeId,
        selectedTaskEditId
      );
    } else {
      uploadDocument(
        client,
        file,
        onSuccess,
        onFail,
        selectedAccount,
        documentName,
        documentTypeId,
        selectedTransactionId ? [selectedTransactionId] : transactionIds
      );
    }
  };

  const onDocIdChange = (documentTypeId) =>
    setFormData({ ...formData, documentTypeId });

  const onChangeName = (e) =>
    setFormData({ ...formData, documentName: e.target.value });
  
  const onChange = (option) => {
    setSelectedTransactionId(option)
  }
  
  return (
    <Modal show={true} onHide={onCancel}>
      <Modal.Header>
        <Modal.Title>
          Add Document{' '}
          {selectedTaskEditId > 0 && <span>to Task #{selectedTaskEditId}</span>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <input
            value={formData.documentName}
            onChange={onChangeName}
            className={'form-control'}
            placeholder="Document Name"
            name="documentName"
            required
          />
        </div>

        <div className="form-group">
          <DocumentTypeSelect id={formData.documentTypeId} onChange={onDocIdChange} />
        </div>

        {!selectedTaskEditId &&
          <div className="form-group">
            <TransactionRefAutocomplete
              id="transacionSelect"
              miaAccountId={selectedAccount.miaAccountId}
              onChange={option => onChange(option)}
            />
          </div>
        }

        <div className="form-group">
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <>
                <p className="text-center">
                  Drag 'n' drop
                  <br />
                  or
                  <br />
                  Click to select
                </p>
                {acceptedFiles.map((file) => (
                  <div
                    key={file.name}
                    className="alert alert-info"
                    role="alert"
                  >
                    <h5>Selected: {file.name}</h5>
                  </div>
                ))}
                {fileRejections.map(({ file, errors }) => (
                  <div
                    key={file.name}
                    className="alert alert-danger"
                    role="alert"
                  >
                    <h5>File rejected</h5>
                    {errors.map((e) => (
                      <p key={e.code}>{e.message}</p>
                    ))}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSubmit} disabled={!canSubmit()}>
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddDocument.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDocumentUploaded: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  selectedTaskEditId:
    state.taskReducer.selectedTask &&
    !state.taskReducer.selectedTask.isCompleted
      ? state.taskReducer.selectedTask.id
      : 0,
  documentTypesLookup: state.lookupReducer.documentTypes,
  selectedAccount: state.currentSelectionReducer.selectedAccount,
  transactionIds: state.functionReducer.transactionIds
});
export default connect(mapStateToProps, { uploadDocument, uploadTaskDocument })(
  AddDocument
);
