import { isNil } from 'lodash';
import React from 'react';

const TextInputField = ({
  name,
  placeholder,
  value = null,
  onChange = () => {}
}) => (
  <input
    type="text"
    id={name}
    name={name}
    className="form-control"
    placeholder={placeholder}
    value={isNil(value) ? '' : value}
    onChange={onChange}
  />
);

export default TextInputField;
