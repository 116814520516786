import { FileEntityExportConfigState, FileExportConfigAction } from "../../../reducers/FileEntityExportConfigReducer"
import ExportFileConfig from "./ExportFileConfig"
import ExportFieldConfigs from "./exportFieldConfig/ExportFieldConfigs"
import EntityConfig from "../../../../entityConfig/types/EntityConfig"
import EntityDataField from "../../../../entityConfig/types/EntityDataField"
import { FileFormat } from "../../utils"
import XmlExportFieldConfigs from "./xmlExportFieldConfig/XmlExportFieldConfigs"
import XmlExportAggregatedFieldConfigs from "./xmlExportFieldConfig/aggregated/XmlExportAggregatedFieldConfigs"

type FileExportConfigSetupProps = {
    state: { editingConfig: FileEntityExportConfigState; entities: EntityConfig[]; dataFields: EntityDataField[] }
    dispatch: React.Dispatch<FileExportConfigAction>
}

const FileExportConfigSetup = ({ state, dispatch }: FileExportConfigSetupProps) => {
    return (
        <div className="d-flex flex-column w-100 h-100">
            <ExportFileConfig config={state.editingConfig} entities={state.entities} dispatch={dispatch} />
            <div className="d-flex flex-column">
                <div className="d-flex bg-grey p-2 mt-2"></div>
                {state.editingConfig.fileFormat === FileFormat.XML ? (
                    <div className="d-flex flex-column mt-4">
                        <XmlExportAggregatedFieldConfigs
                            type="header"
                            root={state.editingConfig.headersNodeFieldsConfig}
                            dispatch={dispatch}
                            dataFields={state.dataFields}
                        />
                        <XmlExportFieldConfigs root={state.editingConfig.rootNodeFieldsConfig} dispatch={dispatch} dataFields={state.dataFields} />
                        <XmlExportAggregatedFieldConfigs
                            type="footer"
                            root={state.editingConfig.footersNodeFieldsConfig}
                            dispatch={dispatch}
                            dataFields={state.dataFields}
                        />
                    </div>
                ) : (
                    <ExportFieldConfigs
                        state={{
                            fieldConfigs: state.editingConfig.optionalFieldConfigs,
                            dataFields: state.dataFields,
                            hasHeaderRow: state.editingConfig.hasHeaderRow
                        }}
                        dispatch={dispatch}
                    />
                )}
            </div>
        </div>
    )
}

export default FileExportConfigSetup
