import classes from "./MenuItemLabel.module.scss"

type MenuItemLabelProps = {
    label: string
    location: "right" | "above"
}

const MenuItemLabel = ({ label, location }: MenuItemLabelProps) => {
    return (
        <div className={`d-flex position-absolute ${location === "right" ? "top-0 start-100" : "bottom-100"} align-items-center h-100 ${classes.label}`}>
            <span className="text-nowrap fs-5 p-3 fw-lighter no-select">{label}</span>
        </div>
    )
}

export default MenuItemLabel
