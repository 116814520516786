import { useState } from "react"
import { useEnvConfig } from "../../../../contexts/EnvironmentConfigContext"
import useApiQuery from "../../../../hooks/useApiQuery"
import useClient from "../../../../hooks/useClient"
import HistoryResultDto from "./HistoryResultDto"
import FileImportRowList from "./importRows/FileImportRowList"
import classes from "./FileImportHistory.module.scss"
import Grid from "../../../../library/grid/Grid"
import Loading from "../../../../library/Loading/Loading"
import RowData from "../../../../library/grid/types/RowData"
import moment from "moment"
import HeaderDefinition from "../../../../library/grid/types/HeaderDefinition"
import { DataTypeEnum } from "../../../entityConfig/types/DataType"
import DataPrimitive from "../../../../types/DataPrimitive"
import FileImportRowActions from "./FileImportRowActions"

const FileImportHistory = () => {
    const [selectedFileImport, setSelectedFileImport] = useState<RowData>()

    const config = useEnvConfig()
    const client = useClient()

    const getHistoryRequest = useApiQuery<HistoryResultDto[]>({
        url: `${config.INTEGRATION_API_URL}/api/${client}/file-import-history`,
        method: "GET"
    })

    const removeSelectedImport = () => setSelectedFileImport(undefined)

    const renderRowActions = (row: RowData) => <FileImportRowActions row={row} setSelectedFileImport={setSelectedFileImport} />

    return (
        <div className={`${classes.container} d-flex flex-grow-1`}>
            <div className={`${classes.gridBackground} d-flex flex-column p-3 gap-3 ${selectedFileImport ? classes.sidebar : `w-100`}`}>
                <div className="d-flex fs-3 fw-lighter">
                    <span className="text-light-grey">File Import History</span>
                </div>
                <Loading isLoading={getHistoryRequest.isFetching}>
                    <Grid
                        reference="file-history-grid"
                        defaultSort={{ fieldName: "importStartDate", dataPrimitive: DataPrimitive.DATE, direction: "DESC" }}
                        defaultPageSize={100}
                    >
                        <Grid.Header>
                            <Grid.Search autoApply />
                        </Grid.Header>
                        <Grid.DataTable defaultHeaders={gridHeaders} data={mapResultToRows(getHistoryRequest.data ?? [])}>
                            <Grid.Rows renderRowActions={renderRowActions} />
                        </Grid.DataTable>
                        <Grid.Footer>
                            <Grid.Pagination />
                        </Grid.Footer>
                    </Grid>
                </Loading>
            </div>
            {selectedFileImport && (
                <FileImportRowList
                    importReference={selectedFileImport.reference}
                    importFileName={selectedFileImport.fields.textFields.fileName ?? "Failed to find file"}
                    rejectedRowCount={selectedFileImport.fields.numberFields.rejectedRowCount ?? 0}
                    deselectFileImport={removeSelectedImport}
                />
            )}
        </div>
    )
}

export default FileImportHistory

const gridHeaders: HeaderDefinition[] = [
    { value: "importConfigName", label: "Import config name", ordinal: 0, defaultOrdinal: 0, isEditable: false, displayType: DataTypeEnum.TEXT },
    { value: "fileType", label: "File type", ordinal: 1, defaultOrdinal: 1, isEditable: false, displayType: DataTypeEnum.TEXT },
    { value: "fileName", label: "File name", ordinal: 2, defaultOrdinal: 2, isEditable: false, displayType: DataTypeEnum.TEXT },
    { value: "fileMoveDate", label: "File moved at", ordinal: 3, defaultOrdinal: 3, isEditable: false, displayType: DataTypeEnum.DATE, format: "DD MMM yyyy hh:mm:ss" },
    { value: "importStartDate", label: "Import started at", ordinal: 4, defaultOrdinal: 4, isEditable: false, displayType: DataTypeEnum.DATE, format: "DD MMM yyyy hh:mm:ss" },
    { value: "importCompleteDate", label: "Import completed at", ordinal: 5, defaultOrdinal: 5, isEditable: false, displayType: DataTypeEnum.DATE, format: "DD MMM yyyy hh:mm:ss" },
    { value: "totalRowCount", label: "Total row count", ordinal: 6, defaultOrdinal: 6, isEditable: false, displayType: DataTypeEnum.INT },
    { value: "importedRowCount", label: "Imported row count", ordinal: 7, defaultOrdinal: 7, isEditable: false, displayType: DataTypeEnum.INT },
    {
        value: "partiallyImportedRowCount",
        label: "Partially imported row count",
        ordinal: 8,
        defaultOrdinal: 8,
        isEditable: false,
        displayType: DataTypeEnum.INT
    },
    { value: "rejectedRowCount", label: "Rejected row count", ordinal: 9, defaultOrdinal: 9, isEditable: false, displayType: DataTypeEnum.INT },
    { value: "failureReason", label: "File failure reason", ordinal: 10, defaultOrdinal: 10, isEditable: false, displayType: DataTypeEnum.TEXT }
]

const mapResultToRows = (result: HistoryResultDto[]): RowData[] =>
    result.map(row => ({
        reference: row.reference,
        fields: {
            booleanFields: {},
            dateFields: {
                fileMoveDate: moment(row.fileMovedAt).valueOf(),
                importStartDate: moment(row.importStartedAt).valueOf(),
                importCompleteDate: moment(row.importCompletedAt).valueOf()
            },
            numberFields: {
                totalRowCount: row.totalRowCount,
                importedRowCount: row.importedRowCount,
                partiallyImportedRowCount: row.partiallyImportedRowCount,
                rejectedRowCount: row.rejectedRowCount
            },
            textFields: {
                importConfigName: row.importConfigName,
                fileType: row.fileType,
                fileName: row.fileName,
                failureReason: row.failureReason ?? ""
            }
        }
    }))
