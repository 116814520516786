import moment from "moment"
import { convertToCurrency } from "../../../../library/helpers/numberUtils"
import { DirectIdTransactionDto } from "../../types/dtos/CreditCircleTransactionsDto"

type TransactionRowProps = {
    transaction: DirectIdTransactionDto
    currency: string
    onTransactionClick: (transaction: DirectIdTransactionDto) => void
}

const TransactionRow = ({ transaction, currency, onTransactionClick }: TransactionRowProps) => {
    const onClick = () => {
        onTransactionClick(transaction)
    }

    return (
        <div className="d-flex w-100 bg-white rounded shadow p-3 pointer" onClick={onClick}>
            <div className="d-flex align-items-center w-100">
                <span className="text-grey me-3">{transaction.merchantDetails.merchantName}</span>
                <span className="text-grey">{moment(transaction.bookingDate).format("DD MMM yyyy")}</span>
                <span className="ms-auto text-black me-3">{convertToCurrency(transaction.amount, currency)}</span>
                <i className="text-blue fas fa-chevron-right"></i>
            </div>
        </div>
    )
}

export default TransactionRow
