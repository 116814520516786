import {
  requestStaticNotesByCustomerId,
  receiveStaticNotesByCustomerId,
  errorStaticNotesByCustomerId,
  requestSaveStaticNote,
  receiveSaveStaticNote,
  errorSaveStaticNote,
  requestDeleteStaticNote,
  receiveDeleteStaticNote,
  errorDeleteStaticNote,
  requestSaveNote,
  receiveSaveNote,
  errorSaveNote,
  requestTransactionLineSaveNote,
  receiveTransactionLineSaveNote,
  errorTransactionLineSaveNote,
  requestStatusSubStatus,
  receiveStatusSubStatus,
  errorStatusSubStatus,
  receiveTransactionsAggregate,
  requestTransactionLineStatusSubStatus,
  receiveTransactionLineStatusSubStatus,
  errorTransactionLineStatusSubStatus,
  loadTransactionIds,
  loadTransactionRefs,
  errorLoadTransactionIds
} from '../actions/functionActions';
import { webApiInterface } from './webApiInterface';
import buildParameterString from '../utility/buildParameterString';
import displayError from '../utility/error';
import { assign } from 'lodash';
import { getApiUrl } from '../constants';

export function getStaticNotesByCustomerId(client, customerId, isVirtualMode) {
  return function (dispatch) {
    dispatch(requestStaticNotesByCustomerId(customerId));
    webApiInterface
      .authFetch(
        client,
        getApiUrl(client) +
        'api/staticNote/GetStaticNotesByCustomer?customerid=' +
        customerId +
        '&isVirtualMode=' +
        isVirtualMode,
        dispatch
      )
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(receiveStaticNotesByCustomerId(json, customerId));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorStaticNotesByCustomerId(err));
      });
  };
}
export function saveStaticNote(client, note, isVirtualMode) {
  return function (dispatch) {
    dispatch(requestSaveStaticNote(note));
    const payload = assign(note, { isVirtualMode: isVirtualMode });
    webApiInterface
      .authPost(
        client,
        getApiUrl(client) + 'api/staticNote/SaveStaticNote',
        dispatch,
        payload
      )
      .then(function (/*response*/) {
        dispatch(receiveSaveStaticNote());
        dispatch(getStaticNotesByCustomerId(client, note.customerId, isVirtualMode));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorSaveStaticNote(err));
      });
  };
}
export function deleteStaticNote(client, note, isVirtualMode) {
  return function (dispatch) {
    const noteId = { staticNoteId: note.staticNoteId };
    dispatch(requestDeleteStaticNote(note.staticNoteId));
    webApiInterface
      .authPost(
        client,
        getApiUrl(client) + 'api/staticNote/DeleteStaticNote',
        dispatch,
        noteId
      )
      .then(function () {
        dispatch(receiveDeleteStaticNote());
        dispatch(getStaticNotesByCustomerId(client, note.customerId, isVirtualMode));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorDeleteStaticNote(err));
      });
  };
}
export function updateStaticNotesPosition(client, notes, isVirtualMode) {
  return function (dispatch) {
    const cId = notes[0].customerId;
    dispatch(requestDeleteStaticNote(1));
    webApiInterface
      .authPost(
        client,
        getApiUrl(client) + 'api/staticNote/UpdateStaticNotePosition',
        dispatch,
        notes
      )
      .then(function () {
        dispatch(receiveDeleteStaticNote());
        dispatch(getStaticNotesByCustomerId(client, cId, isVirtualMode));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorDeleteStaticNote(err));
      });
  };
}
export function saveNote(client, note, onSuccess) {
  return function (dispatch) {
    dispatch(requestSaveNote(note));
    return webApiInterface
      .authPost(client, getApiUrl(client) + `${note.useNewEndpoint ? 'api/Note/NewSaveNote' : 'api/Note/SaveNote'}`, dispatch, note)
      .then(function () {
        dispatch(receiveSaveNote());
        onSuccess && onSuccess()
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorSaveNote(err));
      });
  };
}
export function saveTransactionLineNote(client, note) {
  return function (dispatch) {
    dispatch(requestTransactionLineSaveNote(note));
    webApiInterface
      .authPost(
        client,
        getApiUrl(client) + 'api/transactionline/updatestatus',
        dispatch,
        note
      )
      .then(function () {
        dispatch(receiveTransactionLineSaveNote());
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorTransactionLineSaveNote(err));
      });
  };
}

export function updateTransactionComments(client, transactionComments) {
  return function (dispatch) {
    webApiInterface
      .authPost(
        client,
        getApiUrl(client) + 'api/Transaction/UpdateTransactionComments',
        dispatch,
        transactionComments
      )
      .then(function () {
        dispatch(receiveSaveNote());
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorSaveNote(err));
      });
  };
}
export function getStatusSubStatus(client) {
  return function (dispatch) {
    dispatch(requestStatusSubStatus());
    webApiInterface
      .authFetch(client, getApiUrl(client) + 'api/Note/GetStatusSubStatus', dispatch)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(receiveStatusSubStatus(json));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorStatusSubStatus(err));
      });
  };
}
export function getTransactionLineStatusSubStatus(client) {
  return function (dispatch) {
    dispatch(requestTransactionLineStatusSubStatus());
    webApiInterface
      .authFetch(
        client,
        getApiUrl(client) + 'api/Note/GetTransactionLineStatusSubStatus',
        dispatch
      )
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(receiveTransactionLineStatusSubStatus(json));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorTransactionLineStatusSubStatus(err));
      });
  };
}
export function getTransactionsAggregate(client, serviceParameters) {
  return function (dispatch) {
    webApiInterface
      .authPost(
        client,
        getApiUrl(client) + 'api/grid/transactionsAggregate',
        dispatch,
        serviceParameters
      )
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(receiveTransactionsAggregate(json));
      })
      .catch(function (err) {
        displayError(err);
      });
  };
}

export function saveContact(client, contact, fok, fko) {
  return function (dispatch) {
    webApiInterface
      .authPost(client, getApiUrl(client) + 'api/contact/add', dispatch, contact)
      .then(function (response) {
        fok && fok(response);
      })
      .catch(function (err) {
        fko && fko(err);
      });
  };
}
export function saveVAContact(client, contact, fok, fko) {
  return function (dispatch) {
    webApiInterface
      .authPost(
        client,
        getApiUrl(client) + 'api/virtualaccount/addvacontact',
        dispatch,
        contact
      )
      .then(function (response) {
        fok && fok(response);
      })
      .catch(function (err) {
        fko && fko(err);
      });
  };
}
export function updateContact(client, contact, fok, fko) {
  return function (dispatch) {
    webApiInterface
      .authPost(client, getApiUrl(client) + 'api/contact/updatecontact', dispatch, contact)
      .then(function (response) {
        fok && fok(response);
      })
      .catch(function (err) {
        fko && fko(err);
      });
  };
}
export function updateVAContact(client, contact, fok, fko) {
  return function (dispatch) {
    webApiInterface
      .authPost(
        client,
        getApiUrl(client) + 'api/virtualaccount/updatevacontact',
        dispatch,
        contact
      )
      .then(function (response) {
        fok && fok(response);
      })
      .catch(function (err) {
        fko && fko(err);
      });
  };
}
export function updateCustomerAddress(client, address, fok, fko) {
  return function (dispatch) {
    webApiInterface
      .authPost(client, getApiUrl(client) + 'api/contact/updateAddress', dispatch, address)
      .then(function (response) {
        fok && fok(response);
      })
      .catch(function (err) {
        fko && fko(err);
      });
  };
}
export function getCustomerTransactionsIds(client, filter) {
  return function (dispatch) {
    const pfUrl =
      getApiUrl(client) +
      'api/panelcontent/getcustomertransactionsids?' +
      buildParameterString(filter);
    const fUrl = filter.isClosed === null ? pfUrl + '&isClosed=' : pfUrl;
    webApiInterface
      .authFetch(client, fUrl, dispatch)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(loadTransactionIds(json));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorLoadTransactionIds(err));
      });
  };
}
export function getCustomerTransactionsRefs(client, setCustomer, filter) {
  return function (dispatch) {
    const pfUrl =
      getApiUrl(client) +
      'api/panelcontent/getcustomertransactionsrefs?' +
      buildParameterString(filter);
    const fUrl = filter.isClosed === null ? pfUrl + '&isClosed=' : pfUrl;
    webApiInterface
      .authFetch(client, fUrl, dispatch)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(loadTransactionIds(json));
        setCustomer({
          transactionFilters: json.map(ref => { return { type: "UNIQUE_TRANSACTION_REFERENCE", label: ref.toLowerCase() } })
        })
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorLoadTransactionIds(err));
      });
  };
}
export function getCustomerTransactionsRefsFromTransGrid(client, setCustomer, filter) {
  return function (dispatch) {
    const pfUrl =
      getApiUrl(client) +
      'api/panelcontent/getcustomertransactionsrefs?' +
      buildParameterString(filter);
    const fUrl = filter.isClosed === null ? pfUrl + '&isClosed=' : pfUrl;
    webApiInterface
      .authFetch(client, fUrl, dispatch)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(loadTransactionRefs(json));
        setCustomer({
          transactionFilters: json.map(ref => { return { type: "UNIQUE_TRANSACTION_REFERENCE", label: ref.toLowerCase() } })
        })
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorLoadTransactionIds(err));
      });
  };
}
export function getRecentPaymentTransactionsIds(client, transactionId, customerId) {
  return function (dispatch) {
    const pfUrl =
      getApiUrl(client) +
      'api/panelcontent/getrecentpaymenttransactionids?transactionId=' +
      transactionId +
      '&customerId=' +
      customerId;
    webApiInterface
      .authFetch(client, pfUrl, dispatch)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(loadTransactionIds(json));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorLoadTransactionIds(err));
      });
  };
}
export function sendQuickReply(client, quickReply, fok, fko) {
  return function (dispatch) {
    webApiInterface
      .authPost(client, getApiUrl(client) + 'api/email/sendquickreply', dispatch, quickReply)
      .then(function (response) {
        fok && fok(response);
      })
      .catch(function (err) {
        fko && fko(err);
      });
  };
}

export function sendReply(client, reply, fok, fko) {
  return function (dispatch) {
    webApiInterface
      .authPost(client, getApiUrl(client) + 'api/email/sendreply', dispatch, reply)
      .then(function (response) {
        fok && fok(response);
      })
      .catch(function (err) {
        fko && fko(err);
      });
  };
}
