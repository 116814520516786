import { createContext, ReactNode, useContext, useState } from "react"

const eventTypes = ["reload_transaction" , "test_event" , "clear_dashboard_cache", "select_transactions"] as const
export type EventType = (typeof eventTypes)[number]

export type EventCallback<T = any> = {
    key: string
    callback: (data: T) => void
}

type EventListeners = {
    [key in EventType]: EventCallback[]
}

interface EventContextType {
    subscribe: (event: EventType, eventCallback: EventCallback) => void
    unsubscribe: (event: EventType, callbackKey: string) => void
    triggerEvent: <T = any>(event: EventType, data: T) => void
}

const EventContext = createContext<EventContextType>({
    subscribe: () => {},
    unsubscribe: () => {},
    triggerEvent: () => {}
})

type EventProviderProps = {
    children: ReactNode
}

const EventProvider = ({ children }: EventProviderProps) => {
    const [listeners, setListeners] = useState<EventListeners>(() => {
        return Object.values(eventTypes).reduce((acc: EventListeners, event: EventType) => {
            acc[event] = []
            return acc
        }, {} as EventListeners)
    })

    const subscribe = (event: EventType, callback: EventCallback) => {
        setListeners(prev => ({
            ...prev,
            [event]: [...(prev[event] ?? []), callback]
        }))
    }

    const unsubscribe = (event: EventType, callbackKey: string) => {
        setListeners(prev => ({
            ...prev,
            [event]: prev[event]?.filter(cb => cb.key !== callbackKey) ?? []
        }))
    }

    const triggerEvent = <T = any>(event: EventType, data: T) => {
        listeners[event]?.forEach(eventCallback => eventCallback.callback(data))
    }

    return <EventContext.Provider value={{ subscribe, unsubscribe, triggerEvent }}>{children}</EventContext.Provider>
}

const useEvent = () => {
    const context = useContext(EventContext)

    if (!context) {
        throw new Error("useEvent must be used within an EventProvider")
    }

    return context
}

export default EventContext
export { useEvent, EventProvider }
