import React from 'react';
import MappingGridSelect from '../../legacy/components/admin/mapping/mappingGridSelect';
import MappingUpload from '../../legacy/components/admin/mapping/mappingUpload';
import MappingGridFrame from '../../legacy/components/admin/mapping/mappingGridFrame';

const gridKey = 'mapping-grid-';

const MappingTemplate = ({ client }) => (
  <div className="d-flex flex-column flex-grow-1">
    <h2>Mapping</h2>
    <MappingGridSelect client={client} gridKey={gridKey} />
    <hr />
    <MappingUpload client={client} />
    <hr />
    <MappingGridFrame client={client} gridKey={gridKey} />
  </div>
);

export default MappingTemplate;
