import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import { editorTools } from '../../legacy/constants';
import existential from '../../legacy/utility/existential';
import { getLookup } from '../../legacy/api/lookupApi';
import Dropdown from '../../legacy/components/common/dropdown';
import MasterTemplateVersionsGrid from '../../legacy/components/admin/email/masterTemplateVersionsGrid';
import {
  executeAuthAsyncGet,
  executeAuthAsyncPost,
  executeAsyncResetGet
} from '../../legacy/utility/asyncSupport';
import { showToastSuccessMessage } from '../../legacy/api/toasterApi';

import { EmailEditor } from '../../legacy/components/admin/email/emailEditor';

class EmailMasterTemplateManager extends Component {
  constructor(props) {
    super(props);

    this.editorRef = null;

    this.setEditor = this.setEditor.bind(this);
    this.somethingChanged = this.somethingChanged.bind(this);
    this.saveEmailMasterTemplate = this.saveEmailMasterTemplate.bind(this);

    this.state = EmailMasterTemplateManager.defaultState();
  }

  static defaultState() {
    return {
      canSaveMaster: false,
      canAddTemplate: false,
      canUpdateTemplate: false,
      toSave: {
        masterTemplateId: null,
        masterTemplateVersionId: null,
        LanguageId: null,
        BrandInternalId: null,
        MasterTemplateName: ''
      }
    };
  }

  static getEditorOptions() {
    return {
      resizable: {
        content: true,
        toolbar: true
      },
      tools: editorTools
    };
  }

  componentDidMount() {
    this.setEditor('');
    this.props.executeAuthAsyncGet(
      this.props.client,
      'email/MasterTemplates',
      'EMAIL_MASTER_TEMPLATES'
    );
    this.props.executeAuthAsyncGet(
      this.props.client,
      'email/CustomerBrands',
      'CUSTOMER_BRANDS_LIST'
    );
  }

  componentWillUnmount() {
    this.setState(EmailMasterTemplateManager.defaultState());
    this.setEditor('');
    this.props.executeAsyncResetGet([
      'EMAIL_MASTER_TEMPLATE_VERSION',
      'EMAIL_MASTER_TEMPLATES',
      'CUSTOMER_BRANDS_LIST',
      'SAVE_EMAIL_MASTER_TEMPLATE'
    ]);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const masterTemplateVersionData = existential(
      nextProps.EMAIL_MASTER_TEMPLATE_VERSION,
      'result'
    );

    if (!isNil(masterTemplateVersionData)) {
      const {
        ContentHtml,
        MasterTemplateName,
        Language,
        Brand,
        BrandInternalId,
        LanguageId
      } = masterTemplateVersionData;
      this.setEditor(ContentHtml);
      this.somethingChanged({
        ContentHtml,
        MasterTemplateName,
        Language,
        Brand,
        BrandInternalId,
        LanguageId
      });
    }
  }

  somethingChanged(changedPropertiesObject) {
    const toSave = Object.assign(
      {},
      this.state.toSave,
      changedPropertiesObject
    );
    const canAddTemplate =
      !isNil(toSave.masterTemplateId) &&
      !isNil(toSave.LanguageId) &&
      !isNil(toSave.BrandInternalId);
    const canUpdateTemplate =
      canAddTemplate && !isNil(toSave.masterTemplateVersionId);
    this.setState({
      canAddTemplate,
      canUpdateTemplate,
      toSave
    });
  }

  setEditor(content) {
    this.editorRef.setHtml(content);
  }

  onMasterTemplateNameChanged(e) {
    const masterTemplateName = e.target.value;
    this.setState({ canSaveMaster: !!masterTemplateName });
    this.somethingChanged({
      MasterTemplateName: masterTemplateName
    });
  }

  saveEmailMasterTemplate() {
    const htmlContent = this.editorRef.getHtml();

    const toSave = Object.assign(
      {},
      this.props.EMAIL_MASTER_TEMPLATE_VERSION.result || {},
      this.state.toSave,
      {
        content: htmlContent,
        name: this.state.toSave.MasterTemplateName
      }
    );

    this.props.executeAuthAsyncPost(
      this.props.client,
      'email/SaveMasterTemplate',
      'SAVE_EMAIL_MASTER_TEMPLATE',
      toSave,
      (resultText) => {
        this.grid && this.grid.rebind();
        this.somethingChanged({
          masterTemplateVersionId: null,
          LanguageId: null,
          BrandInternalId: null
        });
        this.props.executeAsyncResetGet([
          'EMAIL_MASTER_TEMPLATE_VERSION',
          'EMAIL_MASTER_TEMPLATES'
        ]);
        this.props.executeAuthAsyncGet(
          this.props.client,
          'email/MasterTemplates',
          'EMAIL_MASTER_TEMPLATES'
        );
        showToastSuccessMessage(resultText);
      }
    );
  }

  onMasterTemplateSelected = (id, text) => {
    this.setEditor('');
    this.somethingChanged({
      masterTemplateId: id,
      MasterTemplateName: text
    });
  };

  onRowSelected = (id, name) => {
    this.setEditor('');
    this.props.executeAsyncResetGet('EMAIL_MASTER_TEMPLATE_VERSION');
    this.props.executeAuthAsyncGet(
      this.props.client,
      'email/MasterTemplateVersion',
      'EMAIL_MASTER_TEMPLATE_VERSION',
      { masterTemplateVersionId: id }
    );
    this.somethingChanged({
      masterTemplateVersionId: id,
      MasterTemplateName: name
    });
  };

  render() {
    const {
      MasterTemplateName,
      LanguageId,
      BrandInternalId,
      masterTemplateId
    } = this.state.toSave;
    const masterTemplateVersionsGrid = isNil(masterTemplateId) ? (
      ''
    ) : (
      <div>
        <label style={{ paddingLeft: '10px' }}>
          "{MasterTemplateName}" versions:
        </label>
        <MasterTemplateVersionsGrid
          client={this.props.client}
          emailMasterTemplateId={masterTemplateId}
          onRowSelected={this.onRowSelected}
          ref={(me) => {
            this.grid = me;
          }}
        />
      </div>
    );

    return (
      <div style={{ color: 'black' }}>
        <div className="row">
          <div className="col-sm-2" style={{ padding: '30px' }}>
            <Dropdown
              title="Master Template"
              selectedItemId={masterTemplateId}
              data={this.props.EMAIL_MASTER_TEMPLATES.result}
              onClick={this.onMasterTemplateSelected}
            />
          </div>
          <div className="col-sm-2" style={{ padding: '30px' }}>
            <button
              className="btnDefault btn btn-default"
              onClick={() =>
                this.setState((prevState) => {
                  return {
                    ...prevState,
                    toSave: { ...prevState.toSave, masterTemplateId: null }
                  };
                }, this.saveEmailMasterTemplate)
              }
              disabled={!this.state.canSaveMaster}
            >
              Add New Master Template
            </button>
          </div>
          <div className="col-sm-2" style={{ padding: '30px' }}>
            <label>Master template name:</label>
            <input
              id="masterTemplateName"
              style={{ width: '100%' }}
              value={MasterTemplateName || ''}
              onChange={this.onMasterTemplateNameChanged.bind(this)}
            />
          </div>
        </div>
        {masterTemplateVersionsGrid}
        <br />
        <div className="row">
          <div className="col-sm-9" style={{ padding: '30px' }}>
            <EmailEditor
              ref={(editor) => (this.editorRef = editor)}
            ></EmailEditor>
          </div>
          <div className="col-sm-3" style={{ padding: '30px' }}>
            <div className="well">
              <Dropdown
                title="Language"
                idProp="Id"
                textProp="Description"
                selectedItemId={LanguageId}
                disabled={!masterTemplateId}
                data={this.props.availableLanguages.results}
                onClick={(id) => this.somethingChanged({ LanguageId: id })}
              />
              <Dropdown
                title="Brand"
                idProp="Id"
                textProp="Name"
                selectedItemId={BrandInternalId}
                disabled={!masterTemplateId}
                data={this.props.CUSTOMER_BRANDS_LIST.result}
                onClick={(id) => this.somethingChanged({ BrandInternalId: id })}
              />
              <br />
              <br />
              <button
                className="btnDefault btn btn-default"
                onClick={() => {
                  this.setState((prevState) => {
                    return {
                      ...prevState,
                      toSave: {
                        ...prevState.toSave,
                        masterTemplateVersionId: null
                      }
                    };
                  }, this.saveEmailMasterTemplate);
                }}
                disabled={!this.state.canAddTemplate}
              >
                Add New Master Template Version
              </button>
              <button
                className="btnDefault btn btn-default"
                onClick={this.saveEmailMasterTemplate}
                disabled={!this.state.canUpdateTemplate}
              >
                Update Master Template Version
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { asyncReducer, lookupReducer } = state;
  const {
    EMAIL_MASTER_TEMPLATES,
    EMAIL_MASTER_TEMPLATE_VERSION,
    CUSTOMER_BRANDS_LIST
  } = asyncReducer;
  const { availableLanguages } = lookupReducer;
  return {
    EMAIL_MASTER_TEMPLATES,
    EMAIL_MASTER_TEMPLATE_VERSION,
    CUSTOMER_BRANDS_LIST,
    availableLanguages
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    executeAuthAsyncGet,
    executeAuthAsyncPost,
    executeAsyncResetGet,
    getLookup
  })(EmailMasterTemplateManager)
);
