import { useFeatureToggle, useUser } from "invevo-react-components"
import { commentResourceTypes } from "../../enums/commentResourceTypes"
import Comments from "../comments/Comments"
import classes from "./ActionStrip.module.scss"
import ActionStripItem from "./ActionStripItem"
import CustomerTasksDashboard from "../../../task/components/taskList/customerTasks/CustomerTasksDashboard"
import CustomerTasksIndex from "../../../legacy/components/customerTasks/CustomerTasksIndex"
import Email from "../../../legacy/components/email/Email"
import PostIndex from "../../../legacy/components/post/PostIndex"
import SmsSenderActionPanel from "../../../connect/components/sms/smsSender/SmsSenderActionPanel"
import RepaymentPlan from "../../../legacy/components/repaymentPlan/RepaymentPlan"
import TransactionStatus from "../../../legacy/components/transactions/TransactionStatus"
import CreditLimit from "../../../legacy/components/creditLimit"
import FridgeNotes from "../../../legacy/components/pinNotes/FridgeNotes"
import PayActionPanel from "../../../pay/components/PayActionPanel"
import { useLayout } from "../../../../contexts/LayoutContext"
import { ActionStripItems, useActionStrip } from "../../../../contexts/ActionStripContext"
import CreditReport from "../../../../components/creditReport/CreditReport"
import VoipContainer from "../../../connect/components/voip/VoipContainer"
import React, { Children } from "react"
import NewHistory from "../../../legacy/components/history/NewHistory"
import History from "../../../legacy/components/history"
import LegacyApp from "../../../legacy/LegacyApp"
import EntityPayActionPanel from "../../../pay/components/EntityPayActionPanel"
import EntityNotes from "../../../../routes/entitySearch/components/notes/EntityNotes"
import ErrorBoundary from "../../../../components/ErrorBoundary"

const ActionStrip = ({ customerRef, store, entityConfig = undefined, goToNextEntity, entityConfigs, entityRelationships, lookups }) => {
    const { currentlySelectedAction, navToAction } = useActionStrip()
    const { isEnabled: isTaskModuleEnabled } = useFeatureToggle("taskModule")
    const { isEnabled: isCreditReportEnabled } = useFeatureToggle("creditReport")
    const { isEnabled: isSmsEnabled } = useFeatureToggle("invevoSms")
    const { isEnabled: isVoipEnabled } = useFeatureToggle("invevoVoip")
    const { isEnabled: isPayEnabled } = useFeatureToggle("invevoPay")
    const { isEnabled: isRepaymentPlanEnabled } = useFeatureToggle("invevoRepaymentPlan")
    const { isEnabled: isPostEnabled } = useFeatureToggle("invevoPost")
    const { isEnabled: isLegacyDashboardsInVerticalStripEnabled } = useFeatureToggle("showLegacyDashboardsInVerticalStrip")
    const { isEnabled: isOldUxEnabled } = useFeatureToggle("showOldUx")
    const { isEnabled: newHistoryView } = useFeatureToggle("newHistoryView")
    const { isEnabled: newEntityStructure } = useFeatureToggle("newEntityStructure")
    const { isEnabled: isEmailEnabled } = useFeatureToggle("emailEnabled")
    const { isEnabled: isCommentsEnabled } = useFeatureToggle("commentsEnabled")
    const { isEnabled: isTransactionEditEnabled } = useFeatureToggle("transactionEditEnabled")
    const { isEnabled: isCreditLimitEnabled } = useFeatureToggle("creditLimitEnabled")
    const { isEnabled: isLegacyNotesEnabled } = useFeatureToggle("legacyNotesEnabled")
    const { isEnabled: isTasksEnabled } = useFeatureToggle("tasksEnabled")
    const { isEnabled: isHistoryEnabled } = useFeatureToggle("historyEnabled")
    const { isEnabled: isSqlFirst } = useFeatureToggle("sqlFirst")
    const { isEnabled: isLegacyTasksHidden } = useFeatureToggle("hideLegacyTasksFromMenuAndActionStrip")
    const { isEnabled: useEntityDashboardsWhenSqlFirst } = useFeatureToggle("useEntityDashboardsWhenSqlFirst")

    const { isActionPanelExpanded } = useLayout()

    const user = useUser()

    const onActionItemClick = actionItem => navToAction(actionItem, { emailMode: "email-index" })

    const onCloseAction = () => navToAction(ActionStripItems.NONE)
    const isActionStripItemActive = actionStripItem => isActionPanelExpanded && currentlySelectedAction === actionStripItem

    const Items = ({ children }) => {
        const itemHeight = `${100 / Children.count(children)}vh`

        return Children.toArray(children).map(child => {
            return React.cloneElement(child, { style: { height: itemHeight } })
        })
    }

    const renderHistory = () => {
        if (!isSqlFirst && !newHistoryView && newEntityStructure) {
            return <EntityNotes entityConfigs={entityConfigs ?? []} entityRelationships={entityRelationships ?? []} lookups={lookups ?? []} />
        }

        return (
            <ErrorBoundary>
                <LegacyApp className="w-100 h-100" store={store}>
                    {newHistoryView ? <NewHistory /> : <History />}
                </LegacyApp>
            </ErrorBoundary>
        )
    }

    return (
        <div className={`d-flex ${classes.container}`}>
            <div className={`d-flex flex-column ${classes.strip}`}>
                <Items>
                    {isLegacyDashboardsInVerticalStripEnabled && !isOldUxEnabled && (
                        <ActionStripItem
                            label="History"
                            isActive={isActionStripItemActive(ActionStripItems.HISTORY)}
                            iconClasses="fa-solid fa-clock-rotate-left"
                            onClick={() => onActionItemClick(ActionStripItems.HISTORY)}
                            disabled={!isHistoryEnabled}
                        />
                    )}
                    {!isLegacyTasksHidden && (
                        <ActionStripItem
                            label="Tasks"
                            isActive={isActionStripItemActive(ActionStripItems.TASKS)}
                            iconClasses="fa-solid fa-list"
                            onClick={() => onActionItemClick(ActionStripItems.TASKS)}
                            disabled={!isTasksEnabled}
                        />
                    )}
                    <ActionStripItem
                        label="Phone"
                        isActive={isActionStripItemActive(ActionStripItems.PHONE)}
                        iconClasses="fa-solid fa-phone"
                        onClick={() => onActionItemClick(ActionStripItems.PHONE)}
                        disabled={!isVoipEnabled || (!user.permissions.hasMakeCallPermission && !user.permissions.hasAdminPermission)}
                    />
                    <ActionStripItem
                        label="Email"
                        isActive={isActionStripItemActive(ActionStripItems.EMAIL)}
                        iconClasses="fa-solid fa-at"
                        onClick={() => onActionItemClick(ActionStripItems.EMAIL)}
                        disabled={!isEmailEnabled}
                    />
                    <ActionStripItem
                        label="Sms"
                        isActive={isActionStripItemActive(ActionStripItems.SMS)}
                        iconClasses="fa-solid fa-sms"
                        onClick={() => onActionItemClick(ActionStripItems.SMS)}
                        disabled={!isSmsEnabled || (!user.permissions.hasConnectPermission && !user.permissions.hasAdminPermission) || newEntityStructure}
                    />
                    <ActionStripItem
                        label="Post"
                        isActive={isActionStripItemActive(ActionStripItems.POST)}
                        iconClasses="fa-solid fa-envelope"
                        onClick={() => onActionItemClick(ActionStripItems.POST)}
                        disabled={!isPostEnabled}
                    />
                    <ActionStripItem
                        label="Comments"
                        isActive={isActionStripItemActive(ActionStripItems.COMMENTS)}
                        iconClasses="fa-solid fa-comments"
                        onClick={() => onActionItemClick(ActionStripItems.COMMENTS)}
                        disabled={!isCommentsEnabled}
                    />
                    {(!newEntityStructure || (newEntityStructure && useEntityDashboardsWhenSqlFirst) || isSqlFirst) && isTransactionEditEnabled && (
                        <ActionStripItem
                            label="Transaction Edit"
                            isActive={isActionStripItemActive(ActionStripItems.TRANSACTION_FIELD_EDIT)}
                            iconClasses="fa-solid fa-pen-to-square"
                            onClick={() => onActionItemClick(ActionStripItems.TRANSACTION_FIELD_EDIT)}
                            disabled={!isTransactionEditEnabled}
                        />
                    )}
                    {(!newEntityStructure || isSqlFirst) && isRepaymentPlanEnabled && (
                        <ActionStripItem
                            label="Repayment plan"
                            isActive={isActionStripItemActive(ActionStripItems.REPAYMENT_PLAN)}
                            iconClasses="fa-solid fa-handshake"
                            onClick={() => onActionItemClick(ActionStripItems.REPAYMENT_PLAN)}
                            disabled={!isRepaymentPlanEnabled}
                        />
                    )}
                    <ActionStripItem
                        label="Pay"
                        isActive={isActionStripItemActive(ActionStripItems.PAY)}
                        iconClasses="fa-solid fa-credit-card"
                        onClick={() => onActionItemClick(ActionStripItems.PAY)}
                        disabled={!isPayEnabled}
                    />
                    <ActionStripItem
                        label="Credit Limit"
                        isActive={isActionStripItemActive(ActionStripItems.CREDIT_LIMIT)}
                        iconClasses="fa-solid fa-message-dollar"
                        onClick={() => onActionItemClick(ActionStripItems.CREDIT_LIMIT)}
                        disabled={!isCreditLimitEnabled}
                    />
                    <ActionStripItem
                        label="Notes"
                        isActive={isActionStripItemActive(ActionStripItems.LEGACY_NOTES)}
                        iconClasses="fa-solid fa-note"
                        onClick={() => onActionItemClick(ActionStripItems.LEGACY_NOTES)}
                        disabled={!isLegacyNotesEnabled}
                    />
                    {isCreditReportEnabled && (
                        <ActionStripItem
                            label="Credit Report"
                            isActive={isActionStripItemActive(ActionStripItems.CREDIT_REPORT)}
                            iconClasses="fa-solid fa-file-chart-column"
                            onClick={() => onActionItemClick(ActionStripItems.CREDIT_REPORT)}
                        />
                    )}
                </Items>
            </div>
            <div className={`d-flex ${isActionPanelExpanded ? `${classes.show} p-3` : classes.hide} bg-blue text-white ${classes.action}`}>
                {isActionPanelExpanded && (
                    <div className="d-flex flex-column w-100">
                        <div className={`d-flex ms-auto pointer ${classes.close}`} onClick={onCloseAction} role="button" aria-label="close-action-strip">
                            <i className="fs-4 m-auto fa-solid fa-xmark"></i>
                        </div>
                        <div className="d-flex flex-grow-1 overflow-auto mt-3">
                            {isHistoryEnabled &&
                                isLegacyDashboardsInVerticalStripEnabled &&
                                !isOldUxEnabled &&
                                isActionStripItemActive(ActionStripItems.HISTORY) &&
                                renderHistory()}
                            {isTasksEnabled && isActionStripItemActive(ActionStripItems.TASKS) ? (
                                isTaskModuleEnabled ? (
                                    <ErrorBoundary>
                                        <CustomerTasksDashboard />
                                    </ErrorBoundary>
                                ) : (
                                    <ErrorBoundary>
                                        <LegacyApp className="w-100 h-100" store={store}>
                                            <CustomerTasksIndex />
                                        </LegacyApp>
                                    </ErrorBoundary>
                                )
                            ) : (
                                <></>
                            )}
                            {isCommentsEnabled && isActionStripItemActive(ActionStripItems.COMMENTS) && (
                                <ErrorBoundary>
                                    <Comments resourceRef={customerRef} resourceType={commentResourceTypes.CUSTOMER} theme="blue" />
                                </ErrorBoundary>
                            )}
                            {isEmailEnabled && isActionStripItemActive(ActionStripItems.EMAIL) && (
                                <ErrorBoundary>
                                    <LegacyApp className="w-100 h-100" store={store}>
                                        <Email />
                                    </LegacyApp>
                                </ErrorBoundary>
                            )}
                            {isPostEnabled && isActionStripItemActive(ActionStripItems.POST) && (
                                <ErrorBoundary>
                                    <LegacyApp className="w-100 h-100" store={store}>
                                        <PostIndex />
                                    </LegacyApp>
                                </ErrorBoundary>
                            )}
                            {isSmsEnabled && isActionStripItemActive(ActionStripItems.SMS) && (
                                <ErrorBoundary>
                                    <SmsSenderActionPanel />
                                </ErrorBoundary>
                            )}
                            {isVoipEnabled && isActionStripItemActive(ActionStripItems.PHONE) && (
                                <ErrorBoundary>
                                    <VoipContainer entityConfig={entityConfig} goToNextEntity={goToNextEntity} />
                                </ErrorBoundary>
                            )}
                            {isRepaymentPlanEnabled && (!newEntityStructure || isSqlFirst) && isActionStripItemActive(ActionStripItems.REPAYMENT_PLAN) && (
                                <ErrorBoundary>
                                    <LegacyApp className="w-100 h-100" store={store}>
                                        <RepaymentPlan />
                                    </LegacyApp>
                                </ErrorBoundary>
                            )}
                            {isTransactionEditEnabled &&
                                (!newEntityStructure || isSqlFirst) &&
                                isActionStripItemActive(ActionStripItems.TRANSACTION_FIELD_EDIT) && (
                                    <ErrorBoundary>
                                        <LegacyApp className="w-100 h-100" store={store}>
                                            <TransactionStatus />
                                        </LegacyApp>
                                    </ErrorBoundary>
                                )}
                            {isPayEnabled && isActionStripItemActive(ActionStripItems.PAY) && (!newEntityStructure || isSqlFirst) && (
                                <ErrorBoundary>
                                    <PayActionPanel />
                                </ErrorBoundary>
                            )}
                            {isPayEnabled && isActionStripItemActive(ActionStripItems.PAY) && newEntityStructure && !isSqlFirst && (
                                <ErrorBoundary>
                                    <EntityPayActionPanel entityConfig={entityConfig} />
                                </ErrorBoundary>
                            )}
                            {isCreditLimitEnabled && isActionStripItemActive(ActionStripItems.CREDIT_LIMIT) && (
                                <ErrorBoundary>
                                    <LegacyApp className="w-100 h-100" store={store}>
                                        <CreditLimit />
                                    </LegacyApp>
                                </ErrorBoundary>
                            )}
                            {isLegacyNotesEnabled && isActionStripItemActive(ActionStripItems.LEGACY_NOTES) && (
                                <ErrorBoundary>
                                    <LegacyApp className="w-100 h-100" store={store}>
                                        <FridgeNotes />
                                    </LegacyApp>
                                </ErrorBoundary>
                            )}
                            {isCreditReportEnabled && isActionStripItemActive(ActionStripItems.CREDIT_REPORT) && (
                                <ErrorBoundary>
                                    <CreditReport customerRef={customerRef} />
                                </ErrorBoundary>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ActionStrip
