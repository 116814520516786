export const actionTypes = {
    DATA_FIELDS_RETRIEVED: "DATA_FIELDS_RETRIEVED",
    DATA_FIELDS_SAVED: "DATA_FIELDS_SAVED",
    DATA_TRANSACTION_FIELD_UPDATED: "DATA_TRANSACTION_FIELD_UPDATED",
    DATA_TRANSACTION_FIELD_ADDED: "DATA_TRANSACTION_FIELD_ADDED",
    DATA_TRANSACTION_FIELD_REMOVED: "DATA_TRANSACTION_FIELD_REMOVED",
    DATA_CUSTOMER_FIELD_UPDATED: "DATA_CUSTOMER_FIELD_UPDATED",
    DATA_CUSTOMER_FIELD_ADDED: "DATA_CUSTOMER_FIELD_ADDED",
    DATA_CUSTOMER_FIELD_REMOVED: "DATA_CUSTOMER_FIELD_REMOVED",
    LOOKUPS_RETRIEVED: "LOOKUPS_RETRIEVED",
    DATA_LOOKUP_ADDED: "DATA_LOOKUP_ADDED",
    DATA_LOOKUP_UPDATED: "DATA_LOOKUP_UPDATED",
    DATA_LOOKUP_REPLACED: "DATA_LOOKUP_REPLACED",
    DATA_LOOKUP_REMOVED: "DATA_LOOKUP_REMOVED",
    DATA_TAB_CHANGED: "DATA_TAB_CHANGED",
    DATA_ENTRY_PAGE_SIZE_CHANGED: "DATA_ENTRY_PAGE_SIZE_CHANGED",
    DATA_CUSTOMER_FIELDS_UPLOADED: "DATA_CUSTOMER_FIELDS_UPLOADED"
}