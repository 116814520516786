import FilterList from "../../../../../library/FilterList/FilterList"
import { padElementsWith } from "../../../../../library/helpers"
import DataField from "../../../../../types/DataField"
import Lookup from "../../../../../types/Lookup"
import UpdateDataFieldAction from "../../../types/actions/UpdateDataFieldAction"
import UpdateDataFieldDefinition from "./UpdateDataFieldDefinition"
import classes from "./UpdateDataFieldActionComponent.module.scss"
import GenericFilter from "../../../../../library/FilterList/filterTypes/GenericFilter"
import { v4 as uuidv4 } from "uuid"
import StandardButton from "../../../../../library/buttons/StandardButton/StandardButton"
import { useFeatureToggle } from "../../../../../hooks/useFeatureToggle"
import UpdateDataFieldDefinitionEntity from "./UpdateDataFieldDefinitionEntity"

type UpdateDataFieldActionComponentProps = {
    action: UpdateDataFieldAction
    onActionUpdated: (action: UpdateDataFieldAction) => void
    customerFields: DataField[]
    lookups: Lookup[]
    isShowFilters?: boolean
}

const UpdateDataFieldActionComponent = ({ action, onActionUpdated, customerFields, lookups, isShowFilters = true }: UpdateDataFieldActionComponentProps) => {
    const filteredCustomerFields = customerFields.filter(field => !action.fieldUpdates.map(field => field.field).includes(field.value))

    const { isEnabled: newEntityStructureForAutomateEvaluation } = useFeatureToggle("newEntityStructureForAutomateEvaluation")

    const onDataFieldDefinitionUpdated = (
        id: string,
        field: DataField | undefined,
        value: string,
        updateActionType: "set" | "increment",
        sourceActionDropDownOptions: "value" | "field" | "now" | "unique id" = "value"
    ) => {
        const newFieldUpdates = action.fieldUpdates.map(update =>
            update.id === id ? { id, field: field?.value ?? "", value, type: updateActionType, sourceType: sourceActionDropDownOptions } : update
        )

        onActionUpdated({
            ...action,
            fieldUpdates: newFieldUpdates
        })
    }

    const onCustomerFiltersUpdated = (filters: GenericFilter[]) => onActionUpdated({ ...action, filters })

    const onDataFieldDefinitionRemoved = (id: string) =>
        onActionUpdated({
            ...action,
            fieldUpdates: action.fieldUpdates.filter(update => update.id !== id)
        })

    const onNewFieldUpdateClicked = () =>
        onActionUpdated({
            ...action,
            fieldUpdates: [...action.fieldUpdates, { id: uuidv4(), field: "", value: "", type: "set", sourceType: "value" }]
        })

    const fieldUpdateComponents = action.fieldUpdates.map(update =>
        newEntityStructureForAutomateEvaluation ? (
            <UpdateDataFieldDefinitionEntity
                key={update.id}
                filteredEntityFields={filteredCustomerFields}
                entityFields={customerFields}
                lookups={lookups}
                id={update.id}
                selectedField={customerFields.find(f => f.value === update.field)}
                selectedValue={update.value}
                selectedUpdateActionType={update.type}
                selectedSourceActionType={update.sourceType}
                onDataFieldDefinitionUpdated={onDataFieldDefinitionUpdated}
                onDataFieldDefinitionRemoved={onDataFieldDefinitionRemoved}
            />
        ) : (
            <UpdateDataFieldDefinition
                key={update.id}
                customerFields={filteredCustomerFields}
                lookups={lookups}
                id={update.id}
                selectedField={customerFields.find(f => f.value === update.field)}
                selectedValue={update.value}
                selectedUpdateActionType={update.type}
                onDataFieldDefinitionUpdated={onDataFieldDefinitionUpdated}
                onDataFieldDefinitionRemoved={onDataFieldDefinitionRemoved}
            />
        )
    )

    return (
        <div className="d-flex flex-column">
            {padElementsWith(fieldUpdateComponents, "mt-2")}
            <StandardButton className="me-auto mt-2" iconClasses="fal fa-plus" label="Add Field Update" colour="grey" onClick={onNewFieldUpdateClicked} />
            {isShowFilters && (
                <div className={`mt-3 p-3 ${classes.container}`}>
                    <span className="fs-5">Entity Filters</span>
                    <div className="mt-2">
                        <FilterList
                            key={action.actionReference}
                            fields={customerFields}
                            lookups={lookups}
                            appliedFilters={action.filters}
                            onFiltersApplied={onCustomerFiltersUpdated}
                            autoApply={true}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default UpdateDataFieldActionComponent
