enum FilterOperators {
    EXISTS = "EXISTS",
    NOT_EXISTS = "NOT_EXISTS",
    TRUE = "TRUE",
    FALSE = "FALSE",
    ONE_OF = "ONE_OF",
    ONE_OF_FIELD = "ONE_OF_FIELD",
    NOT_ONE_OF = "NOT_ONE_OF",
    NOT_ONE_OF_FIELD = "NOT_ONE_OF_FIELD",
    STARTS_WITH = "STARTS_WITH",
    EQUAL_TO = "EQUAL_TO",
    GREATER_THAN = "GREATER_THAN",
    LESS_THAN = "LESS_THAN",
    GREATER_THAN_OR_EQUAL_TO = "GREATER_THAN_OR_EQUAL_TO",
    LESS_THAN_OR_EQUAL_TO = "LESS_THAN_OR_EQUAL_TO",
    AT_MOST = "AT_MOST",
    AT_LEAST = "AT_LEAST",
    EXACTLY = "EXACTLY"
}

export default FilterOperators
