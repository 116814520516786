import { UpdateFieldsActionFlowNode } from "../../types/FlowSculptorNode"
import Lookup from "../../../../types/Lookup"
import DropdownOption from "../../../../types/DropdownOptions"
import EntityDataField from "../../../entityConfig/types/EntityDataField"
import { v4 as uuid } from "uuid"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import ActionUpdateField from "./ActionUpdateField"
import UpdateDataField from "../../types/UpdateDataField"

type ActionUpdateDataFieldsFormProps = {
    updateFieldsNode: UpdateFieldsActionFlowNode
    dataFields: EntityDataField[]
    lookups: Lookup[]
    onUpdateNodeUpdated: (entityUpdatedNode: UpdateFieldsActionFlowNode) => void
}

const ActionUpdateFieldsForm = ({ updateFieldsNode, dataFields, lookups, onUpdateNodeUpdated }: ActionUpdateDataFieldsFormProps) => {
    const defaultFieldUpdate: () => UpdateDataField = () => ({
        reference: uuid(),
        fieldName: "",
        updateValue: "",
        type: "set"
    })
    const onActionUpdateChanged = (update: UpdateDataField) =>
        onUpdateNodeUpdated({ ...updateFieldsNode, fields: updateFieldsNode.fields.map(field => (field.reference === update.reference ? update : field)) })
    const onActionUpdateRemove = (reference: string) =>
        onUpdateNodeUpdated({ ...updateFieldsNode, fields: updateFieldsNode.fields.filter(field => field.reference !== reference) })
    const onNewFieldUpdateClicked = () => onUpdateNodeUpdated({ ...updateFieldsNode, fields: [...updateFieldsNode.fields, defaultFieldUpdate()] })
    const dropdownOptions: DropdownOption<"set" | "increment">[] = [
        { label: "SET", value: "set" },
        { label: "INCREMENT", value: "increment" }
    ]

    return (
        <div className="d-flex flex-column h-100 p-2 gap-2 w-100">
            {updateFieldsNode.fields.map(update => (
                <ActionUpdateField
                    key={update.reference}
                    reference={update.reference}
                    dropDownOptions={dropdownOptions}
                    dataFields={dataFields}
                    lookups={lookups}
                    selectedField={update}
                    onDataFieldDefinitionUpdated={onActionUpdateChanged}
                    onDataFieldDefinitionRemoved={onActionUpdateRemove}
                />
            ))}
            <StandardButton className="mt-2" iconClasses="fal fa-plus" label="Add Field Update" colour="grey" onClick={onNewFieldUpdateClicked} />
        </div>
    )
}

export default ActionUpdateFieldsForm
