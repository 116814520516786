export default function smsTemplateReducer(state, action) {
    switch (action.type) {
        case 'SMS_TEMPLATES_RETRIEVED': {
            if (action.smsTemplates.length !== 0) action.smsTemplates[0].isSelected = true
            return { ...state, smsTemplates: action.smsTemplates }
        }
        case 'SMS_TEMPLATE_SELECTED': {
            const checkedSmsTemplates = state.smsTemplates.map((item) => {
                if (item.name !== action.smsTemplate.name) {
                    return {
                        ...item,
                        isSelected: false
                    }
                }

                return {
                    ...item,
                    isSelected: true
                }
            })

            return { ...state, smsTemplates: checkedSmsTemplates }
        }
        case 'SMS_TEMPLATE_UPDATED': {
            const updatedSmsTemplates = state.smsTemplates.map((item) => {
                if (item.reference === action.smsTemplate.reference) {
                    return action.smsTemplate
                }
                return item
            })
            if (action.smsTemplate.isNew)
                return {
                    ...state,
                    smsTemplates: [{ ...action.smsTemplate, isNew: false }, ...updatedSmsTemplates]
                }
            return { ...state, smsTemplates: updatedSmsTemplates }
        }
        case 'SMS_TEMPLATE_DELETED': {
            return { ...state, smsTemplates: state.smsTemplates.filter(t => t.name !== action.smsTemplate.name) }
        }
        default:
            throw new Error(`Invalid reducer action: ${action.type}`)
    }
}