import { actionTypes } from '../../../reducers/actionTypes'
import WidgetInput from '../forms/WidgetInput'
import classes from './InformationWidget.module.scss'
import { widgetThemeDropdownOptions, widgetThemes } from '../../../enums/widgetThemes'
import { pieChartTypes } from '../../../enums/pieChartTypes'
import { bucketChartTypeDropdownOptions, transactionAggregationTypes } from '../../../enums/transactionAggregationTypes'
import { DashboardConfigAction } from '../../../reducers/dashboardConfigsReducer'
import { SmallPieChartWidgetState } from '../../../types/DashboardConfigState'
import Dropdown from '../../../../../library/dropdowns/Dropdown'
import DropdownOption from '../../../../../types/DropdownOptions'

type SmallPieChartWidgetProps = {
    className?: string
    state: {
        widget: SmallPieChartWidgetState
        showValidationErrors: boolean
    }
    dispatch: React.Dispatch<DashboardConfigAction>
}

const SmallPieChartWidget = ({ className, state, dispatch }: SmallPieChartWidgetProps) => {
    const { widget, showValidationErrors } = state

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: actionTypes.SMALL_PIE_CHART_WIDGET_NAME_CHANGED, widget, newName: event.target.value })
    }

    const onRemoveClick = () => dispatch({ type: actionTypes.WIDGET_REMOVED, widget })

    const onThemeChange = (themeOption: DropdownOption<widgetThemes>) => dispatch({ type: actionTypes.SMALL_PIE_CHART_WIDGET_THEME_CHANGED, widget, newTheme: themeOption.value })
    const onTypeChange = (typeOption: DropdownOption<pieChartTypes>) => dispatch({ type: actionTypes.SMALL_PIE_CHART_WIDGET_TYPE_CHANGED, widget, newType: typeOption.value })
    const onBucketChartTypeChange = (typeOption: DropdownOption<transactionAggregationTypes>) => dispatch({ type: actionTypes.SMALL_PIE_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED, widget, newType: typeOption.value })

    const typeOptions = Object.entries(pieChartTypes).map(([, v], index) => ({ id: index, value: v, label: getLabelForTypeOption(v) }))

    return (
        <div className={`${className ? className : ''} d-flex flex-column ${classes.widget} bg-white p-2 text-grey overflow-auto`}>
            <div className="d-flex align-items-center border-bottom pb-2">
                <i className="fal fa-chart-pie-alt me-2"></i>
                <WidgetInput className="flex-grow-1" value={widget.displayName} placeholder="Please enter name" onChange={onNameChange} isValid={!showValidationErrors || widget.displayName !== ""} />
                <i className="fal fa-times ms-3 me-1 pointer" onClick={onRemoveClick}></i>
            </div>
            <div className="d-flex flex-column mt-2 px-2">
                <div className="d-flex flex-column">
                    <div className='d-flex align-items-center justify-content-between mb-2'>
                        <span className='me-2 text-uppercase small'>Theme:</span>
                        <Dropdown
                            ariaLabel="widgetThemeDropdown"
                            options={widgetThemeDropdownOptions}
                            selectedOption={widgetThemeDropdownOptions.find(o => o.value === widget.theme)}
                            onOptionSelected={onThemeChange}
                        />
                    </div>
                    <div className='d-flex align-items-center justify-content-between mb-2'>
                        <span className='me-2 text-uppercase small'>Type:</span>
                        <Dropdown
                            ariaLabel="bucketChartTypeDropdown"
                            options={typeOptions}
                            selectedOption={typeOptions.find(o => o.value === widget.type)}
                            onOptionSelected={onTypeChange}
                        />
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='me-2 text-uppercase small'>Aggregation:</span>
                        <Dropdown
                            options={bucketChartTypeDropdownOptions}
                            selectedOption={bucketChartTypeDropdownOptions.find(o => o.value === widget.bucketChartType)}
                            onOptionSelected={onBucketChartTypeChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const getLabelForTypeOption = (type: pieChartTypes) => {
    switch (type) {
        case pieChartTypes.PIE:
            return "Pie"

        case pieChartTypes.DONUT:
            return "Donut"

        default:
            return "Pie"
    }
}

export default SmallPieChartWidget