import React from 'react';
import { connect } from 'react-redux';
import {
  GLOBAL_SETTINGS,
  authZ_Permissions,
  getApiUrl
} from '../../../constants';
import { webApiInterface } from '../../../api/webApiInterface';
import { executeAuthAsyncGet } from '../../../utility/asyncSupport';
import {
  showToastSuccessMessage,
  showToastErrorMessage
} from '../../../api/toasterApi.js';
import { isNil, map, split, trim, findIndex } from 'lodash';
import { showValidationResults } from '../../../actions/dashboardActions';
import { validateRuleGroups, refresh } from '../../../actions/ruleGroupAdminActions';
import { hasPermission } from '../../../utility/authZ';

export class RuleGroupTransferAll extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = this.defaultFormState([]);
    this.onFileSelected = this.onFileSelected.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.newExport = this.newExport.bind(this);
    this.onNewRuleGroup = this.onNewRuleGroup.bind(this);
  }

  defaultFormState() {
    return {
      validationResults: []
    };
  }

  onValidate() {
    this.props.validateRuleGroups(this.props.client);
  }

  newExport(event, fileType, contentType, filename) {
    event.preventDefault();

    const xhr = new XMLHttpRequest();
    const url = getApiUrl(this.props.client) + `api/rule/export?fileType=${fileType}`;

    xhr.open('GET', url, true);
    xhr.setRequestHeader('Content-Type', contentType);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(xhr.response);
      a.download = filename;
      a.click();
    };

    this.props.executeAuthAsyncGet(this.props.client, 'Lookup/OK', 'CHECK_OK', null, function () {
      webApiInterface.applySecurity(xhr);
      xhr.send(null);
    });
  }

  onNewRuleGroup() {
    this.props.onNewRuleGroup();
  }

  static addValueToRequest(name, value) {
    return (
      '--blob\r\n' +
      'content-disposition: form-data; name="' +
      name +
      '"\r\n' +
      '\r\n' +
      value +
      '\r\n'
    );
  }

  static addFileContentToRequest(fieldName, fileName, fileType, binaryContent) {
    return (
      '--blob\r\n' +
      'content-disposition: form-data; ' +
      'name="' +
      fieldName +
      '"; ' +
      'filename="' +
      fileName +
      '"\r\n' +
      'Content-Type: ' +
      fileType +
      '\r\n' +
      '\r\n' +
      binaryContent +
      '\r\n'
    );
  }

  onFileSelected(event) {
    event.preventDefault();
    const that = this;
    const file = event.target.files[0];

    const xmlExtension = file.name.indexOf('.xml');

    if (xmlExtension === -1) {
      showToastErrorMessage('Please select a valid xml file.');
      return;
    }

    if (!isNil(file)) {
      that.uploadFormConfirmed(file);
    } else {
      showToastErrorMessage('Please select a valid file.');
    }
  }

  uploadFormConfirmed(file) {
    const allRuleGroupsLoader = this.allRuleGroupsLoader;

    if (!isNil(file)) {
      const that = this;
      const reader = new FileReader();
      const url = getApiUrl(this.props.client) + 'api/rule/import';
      const fileName = file.name;
      const fileSaveName = file.name;
      const fileType = file.type;

      showToastSuccessMessage(
        'Upload of file: ' +
        fileSaveName +
        (fileName !== fileSaveName ? ' (' + fileSaveName + ')' : '') +
        ' started.'
      );

      reader.onload = function (event) {
        if (
          event.currentTarget.result.length >
          GLOBAL_SETTINGS.max_upload_file_size * 1024 * 1024
        ) {
          showToastErrorMessage(
            'File is too big to be uploaded to the server. Please select another file.'
          );
          return;
        }
        if (event.currentTarget.result.length === 0) {
          showToastErrorMessage(
            'File has no content. Please select another file.'
          );
          return;
        }
        const acceptedFileTypes = map(
          split(GLOBAL_SETTINGS.accepted_file_types, ','),
          trim
        ); // Array of extensions
        const fileNameParts = split(fileName, '.');
        const fileExtension = '.' + fileNameParts[fileNameParts.length - 1];
        if (
          findIndex(acceptedFileTypes, (ft) => {
            return ft === fileExtension;
          }) === -1
        ) {
          showToastErrorMessage(
            'File extension is not correct. Please select another file.'
          );
          return;
        }

        const XHR = new XMLHttpRequest();
        const data = RuleGroupTransferAll.addFileContentToRequest(
          allRuleGroupsLoader.name,
          fileSaveName,
          fileType,
          event.currentTarget.result
        );

        XHR.onreadystatechange = function () {
          if (XHR.readyState === 4) {
            if (XHR.status === 200) {
              showToastSuccessMessage(
                'Upload of file: ' + fileName + ' complete.'
              );
              that.props.refresh(true);
            } else {
              const err = JSON.parse(XHR.response);
              showToastErrorMessage(
                'Error uploading file: ' +
                fileName +
                '. Error: ' +
                err.Message || XHR.statusText
              );
            }
          }
        };

        XHR.open('POST', url);
        XHR.setRequestHeader(
          'Content-Type',
          'multipart/form-data; boundary=blob'
        );
        XHR.setRequestHeader('Access-Control-Allow-Origin', '*');
        that.props.executeAuthAsyncGet(
          that.props.client,
          'Lookup/OK',
          'CHECK_OK',
          null,
          function () {
            webApiInterface.applySecurity(XHR);
            XHR.send(data + '--blob--');
          }
        );
      };

      reader.readAsBinaryString(file);
      //that.setState(that.state);
    }
  }

  static getKey() {
    return 'admin-upload-form';
  }

  render() {
    const isAdmin = hasPermission(
      this.props.permissions,
      authZ_Permissions.RuleGroupManagementAdmin
    );

    const xmlImportButton = isAdmin ? (
      <label className="btnDefault btn btn-default">
        Import All From XML
        <input
          type="file"
          id="allRuleGroupsLoader"
          name="allRuleGroupsLoader"
          style={{ display: 'none' }}
          ref={(input) => (this.allRuleGroupsLoader = input)}
          onChange={this.onFileSelected}
          accept=".xml"
        />
      </label>
    ) : (
      <span />
    );
    const validateButton = isAdmin ? (
      <button className="btnDefault btn btn-default" onClick={this.onValidate}>
        Validate
      </button>
    ) : (
      <span />
    );
    const newRuleGroupButton = isAdmin ? (
      <button
        className="btnDefault btn btn-default"
        onClick={this.onNewRuleGroup}
      >
        New Rule Group
      </button>
    ) : (
      <span />
    );

    return (
      <div>
        <button
          className="btnDefault btn btn-default"
          onClick={(event) =>
            this.newExport(event, 'xml', 'xml', 'StateEngineRules.xml')
          }
        >
          Export All To XML
        </button>
        <button
          className="btnDefault btn btn-default"
          onClick={(event) =>
            this.newExport(event, 'csv', 'json', 'StateEngineRules.csv')
          }
        >
          Export All To CSV
        </button>
        {xmlImportButton}
        {validateButton}
        {newRuleGroupButton}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isVisible: state.dashboardReducer.showLookupValidation,
  permissions: state.authReducer.permissions
});

const mapDispatchToEvents = (dispatch) => {
  return {
    showValidationModal: (isVisible) =>
      dispatch(showValidationResults(isVisible)),
    executeAuthAsyncGet: (serviceName, name, data, onSuccess, onError) => {
      dispatch(
        executeAuthAsyncGet(serviceName, name, data, onSuccess, onError)
      );
    },
    validateRuleGroups: (client) => dispatch(validateRuleGroups(client)),
    refresh: (update) => {
      dispatch(refresh(update));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToEvents
)(RuleGroupTransferAll);
