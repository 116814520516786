export const useDownload = () => {
    const fromUrl = (url: string, filename: string) => {
        const anchor = document.createElement("a")
        document.body.appendChild(anchor)
        anchor.href = url
        anchor.download = filename
        anchor.target = "_blank"

        anchor.click()
        document.body.removeChild(anchor)
    }

    const fromStringToCsv = (csv: string, filename: string) => {
        const blob = new Blob([csv], { type: "text/csv" })
        const url = window.URL.createObjectURL(blob)

        fromUrl(url, filename)

        window.URL.revokeObjectURL(url)
    }

    return {
        fromUrl,
        fromStringToCsv
    }
}
