import Dropdown from "../../../../library/dropdowns/Dropdown"
import DataField from "../../../../types/DataField"
import Lookup from "../../../../types/Lookup"
import { FileEntityExportConfig } from "../../../integration/types/fileEntityExport/FileExportConfig"
import LegacySmsTemplate from "../../types/LegacySmsTemplate"
import ActionType from "../../types/actions/ActionType"
import GenericAction from "../../types/actions/GenericAction"
import ActionInfo from "../actionInfo/ActionInfo"
import AsyncCommActionComponent from "../workflowSetup/actionsSetup/AsyncCommActionComponent"
import UpdateDataFieldDefinition from "../workflowSetup/actionsSetup/UpdateDataFieldDefinition"
import classes from "./ScheduledTriggerNode.module.scss"

type ActionNodeProps = {
    actionType: ActionType
    action: GenericAction
    fields: DataField[]
    lookups: Lookup[]
    smsTemplates: LegacySmsTemplate[]
    fileEntityExportConfigs: FileEntityExportConfig[]
}

const ActionNode = ({ actionType, action, fields, lookups, smsTemplates, fileEntityExportConfigs }: ActionNodeProps) => {
    const fileExportConfigOptions = fileEntityExportConfigs.map(fileConfig => ({ label: fileConfig.filename, value: fileConfig.reference }))

    const getActionPanel = () => {
        switch (action.type) {
            case ActionType.ASYNC_COMM_ACTION:
                return (
                    <AsyncCommActionComponent
                        action={action}
                        onActionUpdated={() => {}}
                        onActionRemoved={() => {}}
                        smsTemplates={smsTemplates}
                        customerFields={fields}
                        lookups={lookups}
                        isShowFilters={false}
                        isTextOnly={true}
                    />
                )
            case ActionType.UPDATE_DATA_FIELD:
                const filteredCustomerFields = fields.filter(field => !action.fieldUpdates.map(field => field.field).includes(field.value))
                return (
                    <>
                        {action.fieldUpdates.map(update => (
                            <UpdateDataFieldDefinition
                                key={update.id}
                                customerFields={filteredCustomerFields}
                                lookups={lookups}
                                id={update.id}
                                selectedField={fields.find(f => f.value === update.field)}
                                selectedValue={update.value}
                                selectedUpdateActionType={update.type}
                                onDataFieldDefinitionUpdated={() => {}}
                                onDataFieldDefinitionRemoved={() => {}}
                                isTextOnly={true}
                            />
                        ))}
                    </>
                )
            case ActionType.EXPORT_ACTION:
                return (
                    <Dropdown
                        options={fileExportConfigOptions}
                        onOptionSelected={() => {}}
                        selectedOption={fileExportConfigOptions.find(o => o.value === action.fileEntityExportConfigReference)}
                    />
                )
            default:
                return <></>
        }
    }

    return (
        <div className={`bg-grey p-2 border-top border-4 border-primary rounded-1`}>
            <ActionInfo action={action} smsTemplates={smsTemplates} isBigTitle={true} />
            <div className={`${classes.separator} d-flex flex-row my-2 bg-dark-gradient`} />
            {getActionPanel()}
        </div>
    )
}

export default ActionNode
