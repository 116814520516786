import * as types from '../actions/actionTypes';

export default function fileImportQueueReducer(state = [], action) {
    switch (action.type) {
        case types.LOAD_FILE_IMPORT_QUEUE_SUCCESS:
        case types.NEW_FILE_IMPORT_QUEUE_SUCCESS:
        case types.CREATE_FILE_IMPORT_QUEUE_SUCCESS:
        case types.UPDATE_FILE_IMPORT_QUEUE_SUCCESS:
        case types.DELETE_FILE_IMPORT_QUEUE_SUCCESS:
        case types.FILE_IMPORT_QUEUE_CANCEL:
            return [...state, Object.assign({}, action.fileImportQueue)];
        default:
            return state;
    }
}