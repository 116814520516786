import { IconButton, useConfig } from "invevo-react-components"
import { ReactNode, useEffect, useState } from "react"
import useApiQuery from "../../../../hooks/useApiQuery"
import DataGrid from "../../../../library/dataGrid/DataGrid"
import DataGridState from "../../../../library/dataGrid/types/DataGridState"
import DataRow from "../../../../library/dataGrid/types/DataRow"
import FiltersDto from "../../../../library/FilterList/FiltersDto"
import DataField from "../../../../types/DataField"
import SortDirection from "../../../../types/enums/SortDirection"
import Lookup from "../../../../types/Lookup"
import GenericAction from "../../types/actions/GenericAction"
import CustomerEvaluation from "../../types/CustomerEvaluation"
import CustomerEvaluationResultDto from "../../types/CustomerEvaluationResultDto"
import classes from "./WorkflowRunDetails.module.scss"
import useClient from "../../../../hooks/useClient"

type WorkflowRunDetailsProps = {
    fields: DataField[]
    lookups: Lookup[]
    filtersDto: FiltersDto
    action: GenericAction | undefined
    onGridLoadingChanged: (isLoading: boolean) => void
    renderHeader: (isLoading: boolean) => ReactNode
}

const CustomerEvaluationsGrid = ({ fields, lookups, filtersDto, action, onGridLoadingChanged, renderHeader }: WorkflowRunDetailsProps) => {
    const [sortField, setSortField] = useState<DataField | undefined>(undefined)
    const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(undefined)
    const [pageIndex, setPageIndex] = useState(0)
    const [pageSize, setPageSize] = useState(20)

    const [lastCustomerForPage, setLastCustomerForPage] = useState<{ [key: number]: DataRow | undefined }>({})
    const [customerEvaluations, setCustomerEvaluations] = useState<DataRow[]>([])

    const config = useConfig()
    const client = useClient()

    const customerEvaluationRequest = useApiQuery<CustomerEvaluationResultDto>({
        url: `${config.AUTOMATE_API_URL}/api/${client}/customer-evaluations-query`,
        method: "POST",
        dto: {
            pageSize: pageSize,
            searchAfterId: lastCustomerForPage[pageIndex - 1]?.id ?? null,
            sortBy: sortField
                ? {
                      fieldName: sortField.value,
                      dataPrimitive: sortField.type,
                      direction: sortDirection,
                      searchAfter: lastCustomerForPage[pageIndex - 1]?.data[sortField.value] ?? null
                  }
                : null,
            filters: filtersDto,
            actionReference: action ? action.actionReference : null
        },
        onSuccess: response => {
            const updatedRows = response.pagedCustomerEvaluations.map(customer => convertCustomerToDataRow(customer))

            setCustomerEvaluations(updatedRows)

            setLastCustomerForPage({
                ...lastCustomerForPage,
                [pageIndex]: updatedRows.at(-1)
            })
        }
    })

    useEffect(() => {
        onGridLoadingChanged(customerEvaluationRequest.isFetching)
    }, [customerEvaluationRequest.isFetching, onGridLoadingChanged])

    const onStateChanged = (state: DataGridState) => {
        if (state.pageIndex !== pageIndex) {
            setPageIndex(state.pageIndex)
            return
        }

        if (state.sortField !== sortField) {
            setSortField(state.sortField)
        }

        if (state.sortDirection !== sortDirection) {
            setSortDirection(state.sortDirection)
        }

        if (state.pageSize !== pageSize) {
            setPageSize(state.pageSize)
        }

        setLastCustomerForPage({})
        setPageIndex(0)
    }

    const gridState: DataGridState = {
        dataRows: customerEvaluations,
        totalRowCount: customerEvaluationRequest.data?.totalCount ?? 0,
        sortField: sortField,
        sortDirection: sortDirection,
        pageIndex: pageIndex,
        pageSize: pageSize,
        isLoading: customerEvaluationRequest.isFetching
    }

    const renderFooter = () => (
        <div className="d-flex">
            <span className="me-2">Total matched in results:</span>
            <span className="me-2">{customerEvaluationRequest.data?.totalMatched ?? 0}</span>
        </div>
    )

    const renderRowActions = (row: DataRow) => (
        <IconButton
            size="small"
            buttonClasses={classes.rowActionButton}
            iconClasses="ms-auto text-blue fa fa-eye pointer"
            onClick={() => {}}
            ariaLabel={`select-customer-${row.id}`}
        />
    )

    const renderHeaderWithLoading = () => renderHeader(customerEvaluationRequest.isFetching)

    return (
        <DataGrid
            gridReference="automate-customer-evaluations-grid"
            fields={fields}
            lookups={lookups}
            state={gridState}
            onStateChanged={onStateChanged}
            renderHeader={renderHeaderWithLoading}
            renderRowActions={renderRowActions}
            renderFooter={renderFooter}
        />
    )
}
export default CustomerEvaluationsGrid

const convertCustomerToDataRow = (customer: CustomerEvaluation): DataRow => ({
    id: customer.id,
    data: {
        reference: customer.reference,
        runid: customer.runId,
        ispreview: customer.isPreview.toString(),
        matchedbasefilters: customer.matchedBaseFilters.toString(),
        matchedactioncount: customer.matchedActionCount.toString(),
        matchedactionfilters: customer.matchedActionFilters?.toString() ?? "",
        wasactionsuccessful: customer.wasActionSuccessful?.toString() ?? "",
        actionresult: customer.actionResult ? customer.actionResult : "",
        ...customer.stringFields,
        ...convertValuesToString(customer.numberFields),
        ...convertValuesToString(customer.datetimeFields),
        ...convertValuesToString(customer.booleanFields)
    }
})

const convertValuesToString = (obj: { [key: string]: number | boolean }) => {
    const result: { [key: string]: string } = {}
    Object.keys(obj).forEach(key => {
        result[key] = obj[key]!.toString()
    })
    return result
}
