import { useConfig } from "invevo-react-components"
import useApiQuery from "../../../hooks/useApiQuery"
import { useIsMobile } from "../../../hooks/useIsMobile"
import StandardButton from "../../../library/buttons/StandardButton/StandardButton"
import Loading from "../../../library/Loading/Loading"
import { CreditCircleCustomerDto } from "../../guest/types/dtos/CreditCircleCustomerDto"
import classes from "./UserProfile.module.scss"
import { useMemo } from "react"

const UserProfile = () => {
    const config = useConfig()
    const isMobile = useIsMobile()

    const customer = useApiQuery<CreditCircleCustomerDto>({
        url: `${config.CREDIT_CIRCLE_API_URL}/api/creditcircle/credit-circle-customer`,
        method: "GET"
    })

    const hasActiveSubscription = useMemo(() => {
        return customer.data?.subscriptionDto?.activeSubscription ?? false
    }, [customer.data])

    const cancelSubscription = useApiQuery({
        url: `${config.CREDIT_CIRCLE_API_URL}/api/recurring-payment`,
        method: "DELETE",
        isExecutedAutomatically: false
    })

    const onCancelSubscriptionClick = () => {
        cancelSubscription.execute().then(() => customer.execute())
    }

    return (
        <div className={`d-flex w-100 h-100 ${classes.background} p-3`}>
            <div className={`d-flex align-self-start bg-white rounded shadow p-3 ${isMobile ? "w-100" : ""}`}>
                {hasActiveSubscription ? (
                    <Loading isLoading={cancelSubscription.isFetching || customer.isFetching} colour="blue">
                        <StandardButton
                            className={isMobile ? "w-100" : ""}
                            label="Cancel your subscription"
                            iconClasses="fal fa-power-off"
                            onClick={onCancelSubscriptionClick}
                        />
                    </Loading>
                ) : (
                    <span className="text-grey">You have no active subscription</span>
                )}
            </div>
        </div>
    )
}

export default UserProfile
