import { useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { getFocusedAccount } from "../microfrontends/legacy/api/currentSelectionApi"
import { getApiUrl } from "../microfrontends/legacy/constants"
import { webApiInterface } from "../microfrontends/legacy/api/webApiInterface"
import { useCustomer } from "../contexts/CustomerContext"
import { useEntity } from "../contexts/EntityContext"
import { useFeatureToggle } from "../hooks/useFeatureToggle"
import useClient from "../hooks/useClient"
import useApiQuery from "../hooks/useApiQuery"
import { useEnvConfig } from "../contexts/EnvironmentConfigContext"

const RingFenceWrapper = ({ children, getFocusedAccount }) => {
    const dispatch = useDispatch()

    const [customer, setCustomer] = useCustomer()
    const [entity, setEntity] = useEntity()
    const client = useClient()
    const config = useEnvConfig()
    const { isEnabled: newEntityStructure } = useFeatureToggle("newEntityStructure")
    const { isEnabled: isSqlFirst } = useFeatureToggle("sqlFirst")
    const { isEnabled: useEntityDashboardsWhenSqlFirst } = useFeatureToggle("useEntityDashboardsWhenSqlFirst")
    const { isEnabled: permShowVpHierarchySqlToEntity } = useFeatureToggle("permShowVpHierarchySqlToEntity")

    const getLegacyChildren = useApiQuery({
        url: `${config.SEARCH_API_URL}/api/${client}/legacy-virtual-account-children`,
        method: "GET",
        isExecutedAutomatically: false
    })

    useEffect(() => {
        if (client === undefined) return
        if (
            (!newEntityStructure && (!customer || !customer.reference)) ||
            (newEntityStructure && (!isSqlFirst || useEntityDashboardsWhenSqlFirst) && (!entity || !entity.reference))
        )
            return
        const url = `${getApiUrl(client)}api/customer/byref`
        webApiInterface
            .authPost(client, url, dispatch, {
                reference: newEntityStructure && (!isSqlFirst || useEntityDashboardsWhenSqlFirst) ? entity.reference : customer.reference
            })
            .then(response => response.json())
            .then(customerFromApi => {
                getFocusedAccount(client, customerFromApi.id, false, customer.taskId)
                setCustomer({
                    sqlId: customerFromApi.id
                })
                setEntity({
                    sqlId: customerFromApi.id
                })
            })
            .catch(() => console.error("Failed to fetch customer"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer.reference, entity.reference, newEntityStructure, isSqlFirst])

    useEffect(() => {
        if (client === undefined || !permShowVpHierarchySqlToEntity || !entity.sqlId || entity.entityTypeReference !== "virtual_parent") return
        getLegacyChildren
            .execute(entity.sqlId)
            .then(response => setEntity({ legacyChildren: response.data.map(child => child.accountCode) }))
            .catch(() => console.error("Failed to fetch legacy children"))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity.sqlId, newEntityStructure, isSqlFirst])

    return children
}

export default connect(null, {
    getFocusedAccount
})(RingFenceWrapper)
