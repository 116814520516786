import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CUSTOMER_TYPE } from '../../constants';
import {
  executeAuthAsyncGet,
  executeAuthAsyncPost,
  executeAsyncResetGet
} from '../../utility/asyncSupport';
import CustomerContactsCardGrid from './customerContactsCardGrid';
import InternalContactsGrid from './internalContactsCardGrid';
import CustomerAddressesGrid from './customerAddressesGrid';
import AddEditCustomerContact from './addEditCustomerContact';
import EditCustomerAddress from './editCustomerAddress';
import { IconButton } from '../common/buttons/iconButton';

class ContactsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAddressId: null,
      search: ''
    };

    this.addEditCustomerContactRef = new React.createRef();
    this.editCustomerAddressRef = new React.createRef();
  }

  handleCustomerContactsGridCallback = (refreshGrid) => {
    this.handleCustomerContactsRefreshGrid = refreshGrid;
  };

  handleInternalContactsGridCallback = (refreshGrid) => {
    this.handleInternalContactsRefreshGrid = refreshGrid;
  };

  handleCustomerAddressesGridCallback = (refreshGrid) => {
    this.handleCustomerAddressesRefreshGrid = refreshGrid;
  };

  componentDidMount() {
    this.props.executeAuthAsyncGet(
      this.props.client,
      'Contact/CustomerContactTypes',
      'CUSTOMER_CONTACT_TYPES'
    );
    this.props.executeAuthAsyncGet(
      this.props.client,
      'Contact/InternalContactTypes',
      'INTERNAL_CONTACT_TYPES'
    );
    this.props.executeAuthAsyncGet(
      this.props.client,
      'Contact/CustomerAddressTypes',
      'CUSTOMER_ADDRESS_TYPES'
    );
    this.props.executeAuthAsyncGet(
      this.props.client,
      'Contact/CustomerAddresses',
      'CUSTOMER_ADDRESSES',
      {
        customerId: this.props.selectedAccount.miaAccountId,
        isVirtualAccount: this.props.selectedAccount.miaAccountIsVirtualAccount
      }
    );
  }

  refreshGrids = () => {
    this.handleCustomerContactsRefreshGrid();
    this.handleInternalContactsRefreshGrid();
    this.handleCustomerAddressesRefreshGrid();
  };

  onEditCustomerContactRequest = (guid) => {
    this.addEditCustomerContactRef.current.edit(guid);
  };

  onEditCustomerAddressRequest = (guid) => {
    this.editCustomerAddressRef.current.edit(guid);
  };

  onLocalCustomerContactGridRowSel = (id, email, name, addressId) => {
    this.setState({ selectedAddressId: addressId });
    if (this.props.onCustomerContactGridRowSel != null) {
      this.props.onCustomerContactGridRowSel(id, email, name, addressId);
    }
  };

  onAddCustomerContact = () => {
    this.addEditCustomerContactRef.current.add();
  };

  onChange = (e) => {
    this.setState({ search: e.target.value });
  };
  onClear = () => this.setState({ search: '' });

  render() {
    return (
      <div className="container-fluid" id="contact-panel">
        <div className="row ">
          <div className="col-4 mt-3">
            <div className="input-group">
              <input
                className="form-control"
                placeholder="Search by name"
                value={this.state.search}
                onChange={this.onChange}
              />
              {this.state.search === '' && (
                <IconButton
                  icon={'search'}
                  title="Search"
                  className="input-group-text btn btn-default"
                  isDisabled={true}
                />
              )}
              {this.state.search !== '' && (
                <IconButton
                  icon={'times-circle'}
                  onClick={this.onClear}
                  title="Clear search"
                  className="input-group-text btn btn-default"
                />
              )}
            </div>
          </div>
          <div className="col-8 mt-3">
            <button
              className="btn btn-default float-right h-100"
              onClick={this.onAddCustomerContact}
            >
              <i className='fa-solid fa-plus me-2'></i>
              Create New Contact
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <h3>Customer Contacts</h3>
            <CustomerContactsCardGrid
              client={this.props.client}
              showEdit={true}
              methodCallback={this.handleCustomerContactsGridCallback}
              onRowSelection={this.onLocalCustomerContactGridRowSel}
              customerType={CUSTOMER_TYPE.EXTERNAL}
              onEditRequest={this.onEditCustomerContactRequest}
              filterByText={this.state.search}
              onSelectedCustomerContactsUpdated={this.props.onSelectedCustomerContactsUpdated}
            />
            <AddEditCustomerContact
              client={this.props.client}
              ref={this.addEditCustomerContactRef}
              onContactSaved={this.refreshGrids}
            />
          </div>
          <div className="col-12  mt-3">
            <h3>Internal Contacts</h3>
            <InternalContactsGrid
              client={this.props.client}
              showEdit={true}
              methodCallback={this.handleInternalContactsGridCallback}
              customerType={CUSTOMER_TYPE.INTERNAL}
              filterByName={this.state.search}
              onSelectedInternalContactsUpdated={this.props.onSelectedInternalContactsUpdated}
            />
          </div>
          <div className="col-12 top20">
            <h3>Customer Addresses</h3>
            <CustomerAddressesGrid
              client={this.props.client}
              showEdit={true}
              methodCallback={this.handleCustomerAddressesGridCallback}
              onEditRequest={this.onEditCustomerAddressRequest}
              selectedAddressId={this.state.selectedAddressId}
            />

            <EditCustomerAddress
              client={this.props.client}
              ref={this.editCustomerAddressRef}
              onCustomerAddressSaved={this.refreshGrids}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedAccount: state.currentSelectionReducer.selectedAccount
});

export default connect(mapStateToProps, {
  executeAuthAsyncGet,
  executeAuthAsyncPost,
  executeAsyncResetGet
})(ContactsPanel);
