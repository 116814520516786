import { matchRoutes, useLocation } from "react-router-dom-v5-compat"
import useIsHovering from "../../../hooks/useIsHovering"
import classes from "./MenuMultiRouteItem.module.scss"
import MenuSubItem from "./MenuSubItem"
import RouteArea from "../types/RouteArea"

type MenuMultiRouteItemProps = {
    iconClasses: string
    options: {
        label: string
        iconClasses: string
        route: RouteArea
    }[]
}

const MenuMultiRouteItem = ({ iconClasses, options }: MenuMultiRouteItemProps) => {
    const [hoverableParentRef, isHoveringParent] = useIsHovering(100)
    const [hoverableChildRef, isHoveringChild] = useIsHovering()

    const location = useLocation()
    const allRoutes = options.map(option => ({ path: `/client/:client/${option.route}` }))
    const isActive = matchRoutes(allRoutes, location) != null

    return (
        <div className={`d-flex flex-column justify-content-center w-100 h-100 ${classes.boundHeight}`}>
            <div className="d-flex position-relative h-100">
                <div ref={hoverableParentRef} className={`${classes.menuItem} ${isActive ? classes.active : ""} d-flex w-100 pointer`}>
                    <i className={`m-auto ${iconClasses} text-white fs-5`} />
                </div>
                {(isHoveringParent || isHoveringChild) && (
                    <div ref={hoverableChildRef} className={`d-flex position-absolute top-0 start-100 align-items-center h-100 ${classes.options}`}>
                        {options.map(option => (
                            <MenuSubItem key={option.route} {...option} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default MenuMultiRouteItem
