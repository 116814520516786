import { useCallback, useEffect, useState } from 'react'
import { useClient, useApi, Loading, getConfig } from 'invevo-react-components'
import FieldConfig from './FieldConfig'
import { actionTypes } from '../../reducers/actionTypes'
import { fieldDataTypes } from '../../enums/fieldDataType'

const CreditFieldConfigs = ({ state, dispatch }) => {
    const [assessApiUrl, setAssessApiUrl] = useState()
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)

    const client = useClient()
    const api = useApi()

    useEffect(() => {
        getConfig().then(config => {
            setAssessApiUrl(config.ASSESS_API_URL)
        })
    }, [])

    const getDataConfig = useCallback(() => {
        return new Promise((resolve, reject) => {
            api.get(`${assessApiUrl}/api/${client}/credit-data-import-config`)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    if (error.response.status === 404) resolve({ data: { fields: [] } })
                    else reject(error)
                })
        })
    }, [api, client, assessApiUrl])

    const onFieldConfigChange = (newConfig) => {
        dispatch({ type: actionTypes.CREDIT_FIELD_CONFIG_UPDATED, creditFieldConfig: newConfig })
    }

    const createDefaultConfig = (fields) => {
        const newConfig = {
            fields: fields.map(f => {
                return {
                    fieldName: f,
                    fieldDataType: "TEXT",
                    isAvailableInGroups: true,
                    isAvailableInAutoComplete: true,
                    isTemplatePlaceholder: false,
                    isAvailableAsUserDataFilter: false,
                    isAvailableInSearch: false,
                    isAvailableInAdvancedSearch: false
                }
            })
        }
        return newConfig
    }

    useEffect(() => {
        if (isFetching || hasFetched || !assessApiUrl) {
            return
        }
        setIsFetching(true)

        Promise.all([
            api.get(`${assessApiUrl}/api/${client}/creditfields`),
            getDataConfig()
        ]).then(responses => {

            var dataConfiguration = responses[1].data
            const creditFields = responses[0].data
            if (dataConfiguration.fields.length === 0) {
                dataConfiguration = createDefaultConfig(creditFields)
            }

            dispatch({ type: actionTypes.CREDIT_DATA_CONFIGURATION_RETRIEVED, fields: creditFields, config: dataConfiguration })
            setHasFetched(true)
            setIsFetching(false)
        })
            .catch(error => {
                console.log(error)
            })

    }, [api, client, dispatch, getDataConfig, hasFetched, isFetching, assessApiUrl])

    const getTotalAutoCompleteFieldsCount = () => {
        return state.editableCreditDataConfiguration.creditFieldConfigs.filter(config => config.isAvailableInAutoComplete && config.fieldDataType === fieldDataTypes.TEXT).length
    }

    return (
        <div className="d-flex flex-grow-1 flex-column h-100">
            <Loading isLoading={isFetching} colour="blue">
                {state.editableCreditDataConfiguration.creditFieldConfigs.length > 0 && state.editableCreditDataConfiguration.creditFieldConfigs.map(config => {
                    return <FieldConfig key={config.fieldName} state={{ config, autoCompleteSelectedCount: getTotalAutoCompleteFieldsCount() }} onFieldConfigChange={onFieldConfigChange} />
                }
                ).reduce((prev, next) => [prev, <span key={`${prev}${next}`} className="my-1" />, next])}
            </Loading>
        </div>
    )
}


export default CreditFieldConfigs