import { get, has } from 'lodash'

/*
  Implementation of CoffeeScript's existential operator or the C#  safe property navigation operator: (?.).

  Example usage:
  existential(myObj, 'prop1.subProp1.anotherProp.value1')
   =>
  returns undefined if myObj is missing any in the property chain or 'value1' otherwise.
 */
const existential = (object, properties, defaultValue) => {
  return has(object, properties)
    ? get(object, properties)
    : defaultValue;
};

export default existential;
