import React from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { useSelector } from 'react-redux';
import { normaliseFormat, normaliseFormatDef } from '../../selectors/lookup';
import { useInternationalization } from '@progress/kendo-react-intl';
import Money from '../common/money';
import { classNames } from '@progress/kendo-react-common';
import { positionType } from './repaymentConstants';

const TrackedPaymentValueCell = (actualBalance) => ({ dataItem }) => {
  const diff = actualBalance - dataItem.ProjectedBalance;
  const cl = classNames('', {
    'highlight-green':
      dataItem.PositionType <= positionType.current && diff <= 0,
    'highlight-red': dataItem.PositionType === positionType.past && diff > 0
  });
  return (
    <td className={'text-right'}>
      <div className={cl}>
        <Money number={dataItem.PaymentValue} />
      </div>
    </td>
  );
};

const PlanGrid = ({ repaymentPlan }) => {
  const internationalization = useInternationalization();

  const dateFormat = useSelector((state) =>
    normaliseFormatDef(state.lookupReducer.globalSettings.results.dateFormat)
  );

  const currencyFormat = useSelector((state) =>
    normaliseFormat(state.lookupReducer.globalSettings.results.currencyFormat)
  );

  const currencyFormatDef = `{0:${currencyFormat}}`;

  const TotalCell = ({ field, style, colSpan }) => {
    const total = repaymentPlan.scheduleRows
      ? repaymentPlan.scheduleRows.reduce(
          (acc, current) => acc + current[field],
          0
        )
      : 0;

    return (
      <td
        colSpan={colSpan}
        style={style}
        className={'text-right pr-2 font-weight-normal'}
      >
        {repaymentPlan.currency}{' '}
        {internationalization.formatNumber(total, currencyFormat)}
      </td>
    );
  };

  const TotalTextCell = () => {
    return <td className="text-right">Total</td>;
  };

  const actualBalance = repaymentPlan.currentPosition
    ? repaymentPlan.currentPosition.Actual
    : 0;

  return (
    <Grid
      data={repaymentPlan.scheduleRows}
      className={'scrollable-none custom-grid custom-grid-action mt-2'}
    >
      <GridColumn field="Name" title="#" width={'35px'} />
      <GridColumn
        field="PrincipalSum"
        title={`Principal ${repaymentPlan.currency}`}
        className="text-right"
        format={currencyFormatDef}
        type={'numeric'}
      />
      <GridColumn
        field="Interest"
        title={'Interest'}
        width={'70px'}
        className="text-right"
        format={currencyFormatDef}
        footerCell={TotalTextCell}
        type={'numeric'}
      />
      <GridColumn
        field="PaymentValue"
        cell={TrackedPaymentValueCell(actualBalance)}
        title={`Payment ${repaymentPlan.currency}`}
        footerCell={TotalCell}
      />
      <GridColumn
        field="PaymentDate"
        title="Payment Date"
        width="130px"
        className="text-right"
        type="date"
        format={dateFormat}
      />
    </Grid>
  );
};

export default PlanGrid;
