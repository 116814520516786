import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getApiUrl } from '../../constants';

import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../common/grids/StatefulGrid.js';
import moment from 'moment';
import { normaliseFormat } from '../../selectors/lookup';
import classes from './customerTask.module.scss';

class TaskNotesHistoryGrid extends Component {
  static getKey = () => 'task-notes-history-grid';

  renderCard = (e) => {
    const noteText = e.dataItem.NoteText;
    const date = e.dataItem.Date;

    const isThisYear = moment().isSame(date, 'year');

    let dateFormat = 'DD MMM yyyy';

    if (isThisYear) {
      dateFormat = 'D MMM';
    }

    return (
      <td className="container-fluid no-tr-grid mia-action">
        <div className="row no-tr-grid">
          <div className="col-auto" style={{ color: 'white' }}>
            <b>{moment(date).format(dateFormat)}: </b>
            <span>{noteText}</span>
          </div>
        </div>
      </td>
    );
  };

  render() {
    return (
      <StatefulGrid
        client={this.props.client}
        id={TaskNotesHistoryGrid.getKey()}
        key={TaskNotesHistoryGrid.getKey()}
        path={getApiUrl(this.props.client) + 'api/task/gettasknoteshistory'}
        additionalRequestPayload={{
          taskId: this.props.dataItem.Id
        }}
        reverse={false}
        pageSize={100}
        pageable={{
          pageSizes: [5, 10, 20, 100],
          refresh: true,
          buttonCount: 5
        }}
        hideablePaging={true}
        defaultField={{ field: 'Date' }}
        sortable={true}
        filterable={false}
        refresh={this.props.refresh}
        className={`hide-header single-cell scrollable-none ${classes.container}`}
      >
        <GridColumn cell={this.renderCard} />
      </StatefulGrid>
    );
  }
}

const mapStateToProps = (state) => ({
  dateFormat: normaliseFormat(
    state.lookupReducer.globalSettings.results.dateFormat
  )
});

export default connect(mapStateToProps)(TaskNotesHistoryGrid);
