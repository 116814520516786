import { useInvevoSession } from "invevo-react-components"
import classes from "./Menu.module.scss"
import { useUser } from "../../../contexts/UserContext"
import { Link } from "react-router-dom-v5-compat"
import useClient from "../../../hooks/useClient"
import MenuSingleRouteItem from "./MenuSingleRouteItem"
import RouteArea from "../types/RouteArea"
import MenuMultiRouteItem from "./MenuMultiRouteItem"

type MenuOption = {
    label: string
    iconClasses: string
    route: RouteArea
}

const Menu = () => {
    const user = useUser()
    const client = useClient()
    const [invevoSession] = useInvevoSession()

    const getMultiButton = (iconClasses: string, options: MenuOption[]) => {
        const viewableOptions = options.filter(option => user.canAccess(option.route))

        if (viewableOptions.length === 1) {
            return <MenuSingleRouteItem label={viewableOptions[0]!.label} route={viewableOptions[0]!.route} iconClasses={viewableOptions[0]!.iconClasses} />
        }

        if (viewableOptions.length > 1) {
            return <MenuMultiRouteItem iconClasses={iconClasses} options={viewableOptions} />
        }
    }

    const topMenuItems = [
        user.canAccess("dashboards") && <MenuSingleRouteItem label="Dashboards" route="dashboards" iconClasses="fa-solid fa-gauge-simple-high" />,
        user.canAccess("search") && <MenuSingleRouteItem label="Search" route="search" iconClasses="fa-solid fa-magnifying-glass-plus" />
    ].filter(element => element)

    const middleMenuItems = [
        user.canAccess("dashboard-config") && <MenuSingleRouteItem label="Dashboard Config" route="dashboard-config" iconClasses="fa-solid fa-table-columns" />,
        user.canAccess("automate") && <MenuSingleRouteItem label="Automate" route="automate" iconClasses="fa-light fa-sitemap" />,
        user.canAccess("flow-sculptor") && <MenuSingleRouteItem label="Flow Sculptor" route="flow-sculptor" iconClasses="fa-light fa-diagram-project" />,
        getMultiButton("fa-solid fa-plug", integrationOptions),
        getMultiButton("fa-solid fa-tower-cell", connectOptions),
        getMultiButton("fa-solid fa-cogs", systemOptions)
    ].filter(element => element)

    return (
        <div className={`d-flex flex-column align-items-center ${classes.menu}`}>
            <Link className="m-3 p-1 pointer text-decoration-none" to={`/client/${client}/search?client=${client}`}>
                <img src="/Invevo_Icon_Gradient.svg" alt="Invevo Logo" />
            </Link>

            {topMenuItems}
            {topMenuItems.length > 0 && <div className="w-100 border-top" />}

            {middleMenuItems}
            {middleMenuItems.length > 0 && <div className="w-100 border-top" />}

            {user.canAccess("myinvevo") && (
                <>
                    <MenuSingleRouteItem
                        label="My Invevo"
                        iconClasses="fa-regular fa-lightbulb-on"
                        route="https://invevo.myportfolio.com/"
                        isClientRoute={false}
                        opensInNewTab={true}
                    />
                    <div className="w-100 border-top" />
                </>
            )}

            <div className="d-flex flex-grow-1" />

            {user.canAccess("user-profile") && <MenuSingleRouteItem label="Your profile" route="user-profile" iconClasses="fa-solid fa-user-gear" />}
            <MenuSingleRouteItem
                label={invevoSession.clients && invevoSession.clients.length > 1 ? "Landing page" : "Logout"}
                iconClasses="fa-solid fa-right-from-bracket"
                route="/?logout=true"
                isClientRoute={false}
            />
        </div>
    )
}

export default Menu

const integrationOptions: MenuOption[] = [
    {
        label: "Data Fields",
        iconClasses: "fa-solid fa-file-spreadsheet",
        route: "data-fields"
    },
    {
        label: "Entity Config",
        iconClasses: "fa-solid fa-table",
        route: "entity-config"
    },
    {
        label: "File Config",
        iconClasses: "fa-solid fa-file",
        route: "file-config"
    },
    {
        label: "File History",
        iconClasses: "fa-solid fa-clock-rotate-left",
        route: "file-history"
    }
]

const connectOptions: MenuOption[] = [
    {
        label: "SMS",
        iconClasses: "fa-solid fa-sms",
        route: "sms"
    },
    {
        label: "VOIP",
        iconClasses: "fa-solid fa-phone",
        route: "voip"
    }
]

const systemOptions: MenuOption[] = [
    {
        label: "Users",
        iconClasses: "fa-solid fa-users",
        route: "users"
    },
    {
        label: "Roles",
        iconClasses: "fa-solid fa-id-card-clip",
        route: "roles"
    }
]
