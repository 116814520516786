import { createContext, useCallback, useContext, useState } from "react"
import { expandablePanels, useLayout } from "./LayoutContext"

const ActionStripContext = createContext()

export const ActionStripItems = {
    NONE: 'NONE',
    HISTORY: 'HISTORY',
    TASKS: 'TASKS',
    PHONE: 'PHONE',
    EMAIL: 'EMAIL',
    SMS: 'SMS',
    POST: 'POST',
    COMMENTS: 'COMMENTS',
    TRANSACTION_FIELD_EDIT: 'TRANSACTION_FIELD_EDIT',
    REPAYMENT_PLAN: 'REPAYMENT_PLAN',
    PAY: 'PAY',
    CREDIT_LIMIT: 'CREDIT_LIMIT',
    LEGACY_NOTES: 'LEGACY_NOTES',
    CREDIT_REPORT: 'CREDIT_REPORT'
}

export const ActionStripProvider = ({ children }) => {
    const { setExpandedPanel, isActionPanelExpanded } = useLayout()

    const [currentlySelectedAction, setCurrentlySelectedAction] = useState(ActionStripItems.NONE)
    const [lastNavigationParams, setLastNavigationParams] = useState({})

    const navToAction = useCallback((actionToSelect, actionParams = {}) => {
        if (JSON.stringify(lastNavigationParams) !== JSON.stringify(actionParams)) {
            setLastNavigationParams(actionParams)
        }

        setCurrentlySelectedAction(actionToSelect)

        if (actionToSelect !== ActionStripItems.NONE) {
            setExpandedPanel(expandablePanels.ACTION_PANEL)
        }

        if (actionToSelect === ActionStripItems.NONE && isActionPanelExpanded) {
            setExpandedPanel(expandablePanels.DEFAULT)
        }
    }, [isActionPanelExpanded, lastNavigationParams, setExpandedPanel])

    return <ActionStripContext.Provider value={{
        currentlySelectedAction,
        navToAction,
        lastNavigationParams
    }}>
        {children}
    </ActionStripContext.Provider>
}

export const useActionStrip = () => {
    const context = useContext(ActionStripContext)
    if (!context) {
        throw new Error(`useActionStrip must be used within an <ActionStripProvider />`)
    }
    return context
}