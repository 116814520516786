import { webApiInterface } from './webApiInterface';
import { getApiUrl } from '../constants';
import {
  executeAuthAsyncGet,
  executeAuthAsyncPost
} from '../utility/asyncSupport';
import moment from 'moment';
import { join, map } from 'lodash';
const addFileContentToRequest = (fileName, fileType, binaryContent) => {
  return (
    '--blob\r\n' +
    'content-disposition: form-data; name="fileContents"; ' +
    'filename="' +
    fileName +
    '"\r\n' +
    'Content-Type: ' +
    fileType +
    '\r\n' +
    '\r\n' +
    binaryContent +
    '\r\n'
  );
};

const addValueToRequest = (name, value) => {
  return (
    '--blob\r\n' +
    'content-disposition: form-data; name="' +
    name +
    '"\r\n' +
    '\r\n' +
    value +
    '\r\n'
  );
};

const uploader = (client, url, formData, file, onSuccess, onFail, dispatch) => {
  const reader = new FileReader();

  const { name, type } = file;

  const onLoadXHR = (event) => {
    if (event.currentTarget.status !== 200) {
      onFail(event.currentTarget.responseText);
    } else {
      onSuccess();
    }
  };

  reader.onload = (event) => {
    const XHR = new XMLHttpRequest();

    let data =
      addFileContentToRequest(name, type, event.currentTarget.result) +
      addValueToRequest('fileName', name) +
      addValueToRequest('fileType', type);

    formData.forEach((value, key) => {
      data += addValueToRequest(key, value);
    });

    data += '--blob--';

    XHR.addEventListener('load', onLoadXHR);
    XHR.addEventListener('error', () => alert('Oups! Something gone wrong.'));
    XHR.open('POST', url);
    XHR.setRequestHeader('Content-Type', 'multipart/form-data; boundary=blob');
    XHR.setRequestHeader('Access-Control-Allow-Origin', '*');

    webApiInterface.applySecurity(XHR);
    XHR.send(data);
  };

  // check token
  dispatch(
    executeAuthAsyncGet(client, 'Lookup/OK', 'CHECK_OK', null, () => {
      reader.readAsBinaryString(file);
    })
  );
};

export const uploadDocument = (
  client,
  file,
  onSuccess,
  onFail,
  selectedAccount,
  documentName,
  documentTypeId,
  transactionIds = []
) => (dispatch) => {
  const url = getApiUrl(client) + 'api/document/uploaddocument';
  const { miaAccountIsVirtualAccount, miaAccountId } = selectedAccount;

  const payload = new Map([
    ['customerId', miaAccountIsVirtualAccount ? '' : miaAccountId],
    ['virtualAccountId', miaAccountIsVirtualAccount ? miaAccountId : ''],
    ['customerContactId', null],
    ['description', documentName],
    ['documentDate', moment().utc().format()],
    ['documentName', documentName],
    ['documentTypeId', documentTypeId],
    ['transactions', join(transactionIds)]
  ]);

  uploader(client, url, payload, file, onSuccess, onFail, dispatch);
};

export const uploadTaskDocument = (
  client,
  file,
  onSuccess,
  onFail,
  selectedAccount,
  documentName,
  documentTypeId,
  selectedTaskId = 0
) => (dispatch) => {
  const url = getApiUrl(client) + 'api/document/uploadtaskdocument';
  const { miaAccountIsVirtualAccount, miaAccountId } = selectedAccount;

  const payload = new Map([
    ['taskId', selectedTaskId],
    ['description', documentName],
    ['documentDate', moment().utc().format()],
    ['documentName', documentName],
    ['documentTypeId', documentTypeId],
    ['customerId', miaAccountId],
    ['isVirtualAccount', miaAccountIsVirtualAccount]
  ]);

  uploader(client, url, payload, file, onSuccess, onFail, dispatch);
};

export const downloadDocuments = (client, documents, onStartDownload, onError) => (
  dispatch
) => {
  if (!documents && documents.length === 0)
    return onError(
      'No document(s) have been selected. Please select a document.'
    );

  let fileName = '';
  if (documents.length === 1) {
    fileName = documents[0].FileName;
  } else {
    fileName = 'pack.zip';
  }

  onStartDownload();

  const paramlist = map(documents, ({ Id }) => `documentIdList=${Id}`);
  const sparams = paramlist.join('&');
  const url = `${getApiUrl(client)}api/document/downloaddocuments?${sparams}&fileName=${fileName}`;
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    const a = document.createElement('a');
    a.style = 'display: none';
    const url = URL.createObjectURL(xhr.response);
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 100);
  };

  dispatch(
    executeAuthAsyncGet(client, 'Lookup/OK', 'CHECK_OK', null, function () {
      webApiInterface.applySecurity(xhr);
      xhr.send(null);
    })
  );
};

export const importTransactionDocuments = (
  client,
  transactionIds,
  customerId,
  onSuccess
) => (dispatch) => {
  const params = {
    documentType: 1,
    // dateFrom: null,
    // dateTo: null,
    transactionIds,
    customerId
  };

  function localSuccess(res) {
    if (res.Count === 0) {
      onSuccess('No documents imported');
    } else {
      onSuccess(`${res.Count} imported`);
    }
  }

  dispatch(
    executeAuthAsyncPost(
      client,
      'customerportal/FindAndImportExternalDocs',
      'IMPORT_EXTERNAL_DOCS',
      params,
      localSuccess
    )
  );
};

export const downloadTransactionDocuments = (
  client,
  transactionIds,
  customerId,
  onStartDownload,
  onError,
  documentTypes = null
) => (dispatch) => {

  var params = {};
  var url = '';
  if (documentTypes !== null) {
    params = documentTypes.map(dt => {
      return {
        documentType: dt,
        // dateFrom: null,
        // dateTo: null,
        transactionIds,
        customerId
      }
    });
    url = `${getApiUrl(client)}api/customerportal/FindAndDownloadExternalDocsNew`;
  } else {
    params = {
      documentType: 1,
      // dateFrom: null,
      // dateTo: null,
      transactionIds,
      customerId
    }
    url = `${getApiUrl(client)}api/customerportal/FindAndDownloadExternalDocs`;
  }
  onStartDownload();

  const xhr = new XMLHttpRequest();
  xhr.open('POST', url, true);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (xhr.status === 200) {
      let filename;
      const disposition = xhr.getResponseHeader('Content-Disposition');

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches !== null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      const a = document.createElement('a');
      a.style = 'display: none';
      const url = URL.createObjectURL(xhr.response);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 100);
    } else {
      onError('Documents not found');
    }
  };

  dispatch(
    executeAuthAsyncGet(client, 'Lookup/OK', 'CHECK_OK', null, function () {
      webApiInterface.applySecurity(xhr);
      xhr.send(JSON.stringify(params));
    })
  );
};
