import { LogicOperatorOptions, useApi, useClient, Loading, getConfig } from 'invevo-react-components'
import { useEffect, useState, useCallback } from 'react';
import { actionTypes } from '../../../reducers/actionTypes'
import { Filters, filterTypes } from '../filters/Filters';

const CreditFilters = ({ state: customerGroup, dispatch, isConfigReader }) => {
    const api = useApi()
    const client = useClient()

    const [assessApiUrl, setAssessApiUrl] = useState()
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [creditFields, setCreditFields] = useState([])
    const [creditFieldValuesForAutoComplete, setCreditFieldValuesForAutoComplete] = useState([])

    useEffect(() => {
        getConfig().then(config => {
            setAssessApiUrl(config.ASSESS_API_URL)
        })
    }, [])

    const getDataConfiguration = useCallback(() => {
        return new Promise((resolve, reject) => {
            api.get(`${assessApiUrl}/api/${client}/credit-data-import-config`)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    if (error.response.status === 404) resolve({ data: { fields: [] } })
                    else reject(error)
                })
        })
    }, [api, client, assessApiUrl])

    const getCreditFields = useCallback(() => {
        return new Promise((resolve, reject) => {
            api.get(`${assessApiUrl}/api/${client}/creditfields`)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }, [api, client, assessApiUrl])

    useEffect(() => {
        if (isFetching || hasFetched || !isConfigReader || !assessApiUrl) return
        setIsFetching(true)
        getDataConfiguration()
            .then(response => {
                setHasFetched(true)
                const dataConfiguration = response.data
                if (dataConfiguration.fields.length > 0) {
                    setCreditFields(
                        dataConfiguration.fields.filter(f => f.isAvailableInGroups)
                            .map((field, index) => {
                                return {
                                    id: index,
                                    value: field.fieldName
                                }
                            }))
                    setCreditFieldValuesForAutoComplete(dataConfiguration.fields.filter(f => f.isAvailableInAutoComplete).map(f => f.fieldName))
                }
                else {
                    getCreditFields().then(response => {
                        setCreditFields(response.data.map((field, index) => {
                            return {
                                id: index,
                                value: field
                            }
                        }))
                        setCreditFieldValuesForAutoComplete(response.data)
                    })
                }
                setIsFetching(false)
            })
            .catch(error => {
                console.log(error)
            })

    }, [api, client, creditFields, getDataConfiguration, isFetching, hasFetched, isConfigReader, assessApiUrl, getCreditFields])


    const onRuleChange = (rule, ruleIndex) => {
        const updatedFilters = customerGroup.creditFilters.map((filter, filterIndex) => {
            if (filterIndex !== ruleIndex) {
                return filter
            }

            let operatorValue = ""
            if (rule.operator) {
                operatorValue = rule.operator.value
            }

            return {
                ...filter, ...{
                    name: rule.field, value: rule.value, operator: operatorValue, isValid: rule.isValid
                }
            }
        })

        dispatch({ type: actionTypes.SELECTED_CUSTOMER_GROUP_UPDATED, data: { ...customerGroup, creditFilters: updatedFilters } })
    }

    const onAddRule = () => {
        dispatch({
            type: actionTypes.SELECTED_CUSTOMER_GROUP_UPDATED,
            data: {
                ...customerGroup,
                creditFilters: [...customerGroup.creditFilters, { name: "", value: "" }]
            }
        })
    }

    const onRemoveRule = (_, ruleIndex) => {
        dispatch({
            type: actionTypes.SELECTED_CUSTOMER_GROUP_UPDATED,
            data: {
                ...customerGroup,
                creditFilters: customerGroup.creditFilters.filter((_, index) => index !== ruleIndex)
            }
        })
    }

    const onRemoveAll = () => {
        dispatch({
            type: actionTypes.SELECTED_CUSTOMER_GROUP_UPDATED,
            data: {
                ...customerGroup,
                creditFilters: customerGroup.creditFilters.slice(0, 0)
            }
        })
    }

    const getFilterTypeFromOperator = (operator) => {
        if (operator === LogicOperatorOptions.DATE_DAYS_AFTER_TODAY || operator === LogicOperatorOptions.DATE_DAYS_BEFORE_TODAY) {
            const dateFilterType = filterTypes.find(ft => ft.value === LogicOperatorOptions.DATE_IS_IN)
            return { ...dateFilterType, value: operator }
        }

        return filterTypes.find(ft => ft.value === operator)
    }

    const getRulesFromFilters = (filters) => {
        return filters.map(f => {
            return {
                field: f.name,
                value: f.value,
                operator: getFilterTypeFromOperator(f.operator),
                isValid: f.isValid
            }
        })
    }
    return (
        <div className="d-flex flex-column w-100 p-4">
            <Loading isLoading={isFetching} colour="blue">
                <Filters
                    rules={getRulesFromFilters(customerGroup.creditFilters)}
                    onRuleChange={onRuleChange}
                    onAddRule={onAddRule}
                    onRemoveRule={onRemoveRule}
                    onRemoveAll={onRemoveAll}
                    inputType={isConfigReader ? "autocomplete" : "normal"}
                    fieldOptions={creditFields}
                    fieldsAvailableForAutoComplete={creditFieldValuesForAutoComplete}
                    autoCompleteUrl={`${assessApiUrl}/api/${client}/credit-field-values-autocomplete`}
                    title="Credit Filters"
                    enableTooltip={true}
                    scrollable={true}
                />
            </Loading>
        </div>
    )
}

export default CreditFilters