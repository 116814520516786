import DataField from "../../../../../../types/DataField"
import { FileImportConfigFieldDataType } from "../../../../types/FileImportConfigFieldDataType"
import { FileImportConfigFieldDefaultValue, FileImportConfigFieldDefaultValueEnum } from "../../../../types/FileImportConfigFieldDefaultValue"
import classes from "./ExportFieldConfig.module.scss"
import { ExportFieldConfigState, FileExportConfigAction, FileExportConfigActionEnum } from "../../../../reducers/FileExportConfigReducer"
import { FileImportConfigColumnType } from "../../../../types/FileImportConfigColumnType"
import DataFieldDropdown from "../../../../../../library/dropdowns/DataFieldDropdown"
import ExportColumn from "./ExportColumn"
import { IconButton, Toggle } from "invevo-react-components"
import { getDataTypeFromDataField, getDefaultValueTypeFromDataField } from "../../../importConfigs/fileImportConfigSetup/importFieldConfig/ImportFieldConfig"
import DataTypeConfig from "../../../importConfigs/fileImportConfigSetup/importFieldConfig/DataTypeConfig"
import DefaultValueConfig from "../../../importConfigs/fileImportConfigSetup/importFieldConfig/DefaultValueConfig"

type ExportFieldConfigProps = {
    state: { config: ExportFieldConfigState; dataFields: DataField[]; hasHeaderRow: Boolean }
    dispatch: React.Dispatch<FileExportConfigAction>
    onRemoveClick: (fieldConfig: ExportFieldConfigState) => any
}

const ExportFieldConfig = ({ state, dispatch, onRemoveClick }: ExportFieldConfigProps) => {
    const onColumnChange = (column: FileImportConfigColumnType) =>
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                column
            }
        })

    const onDataFieldChange = (option?: DataField | undefined) => {
        if (!option) return
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                dataFieldReference: option?.value,
                dataType: {
                    ...state.config.dataType,
                    type: getDataTypeFromDataField(option?.type)
                } as FileImportConfigFieldDataType,
                fieldType: option.fieldType,
                defaultValue: state.config.defaultValue
                    ? ({
                          ...state.config.defaultValue,
                          type: getDefaultValueTypeFromDataField(option?.type)
                      } as FileImportConfigFieldDefaultValue)
                    : undefined
            }
        })
    }

    const onDataTypeChange = (dataType: FileImportConfigFieldDataType) => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                dataType
            }
        })
    }

    const onDefaultValueChange = (defaultValue: FileImportConfigFieldDefaultValue) => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                defaultValue
            }
        })
    }

    const onShowDefaultValueConfigChange = () => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
            fieldConfig: {
                ...state.config,
                defaultValue: state.config.defaultValue
                    ? undefined
                    : {
                          type: FileImportConfigFieldDefaultValueEnum.STRING,
                          stringValue: ""
                      }
            }
        })
    }

    const onRemoveFieldConfigClick = () => onRemoveClick(state.config)

    const getDataField = () => state.dataFields.find(df => df.value === state.config.dataFieldReference)

    return (
        <div className={`d-flex w-100 ${classes.card}`}>
            <div className="d-flex flex-column p-4 w-100">
                <div className={`d-flex flex-grow-1 flex-column small`}>
                    <div className="d-flex align-items-center mb-2">
                        <span className="fs-4 text-grey">Column</span>
                    </div>
                    <ExportColumn column={state.config.column} onChange={onColumnChange} />
                </div>
                <div className={`${classes.horizontalDivider} m-2`} />
                <div className="d-flex flex-grow-1">
                    <div className={`d-flex flex-grow-1 flex-column small me-3`}>
                        <span className="text-uppercase small mb-1 ms-1 text-grey">Invevo data field</span>
                        <DataFieldDropdown
                            className="flex-grow-1"
                            options={state.dataFields}
                            selectedOption={getDataField()}
                            onOptionSelected={onDataFieldChange}
                            ariaLabel={"Add invevo data field"}
                        />
                    </div>
                    {getDataField() && <DataTypeConfig config={state.config.dataType} onChange={onDataTypeChange} />}
                </div>
                <div className={`${classes.horizontalDivider} m-2`} />
                {getDataField() && (
                    <>
                        <div className={`${classes.horizontalDivider} m-2`} />
                        <div className={`d-flex flex-column`}>
                            <div className="d-flex align-items-center">
                                <span className="fs-4 text-grey">Default value if empty</span>
                                <Toggle
                                    className="ms-auto"
                                    status={state.config.defaultValue !== undefined}
                                    onStatusChanged={onShowDefaultValueConfigChange}
                                    toggleColour="blue"
                                    size="small"
                                    ariaLabel="Add default value"
                                />
                            </div>
                            <div className={`${classes.horizontalDivider} m-2`} />
                            {state.config.defaultValue && <DefaultValueConfig config={state.config.defaultValue} onChange={onDefaultValueChange} />}
                        </div>
                    </>
                )}
                <div className={`${classes.horizontalDivider} m-2`} />
            </div>
            <IconButton className="ms-3 p-4 border-2 border-start" iconClasses="far fa-trash" onClick={onRemoveFieldConfigClick} />
        </div>
    )
}

export default ExportFieldConfig
