import { convertToCurrency } from "../../../../library/helpers/numberUtils"
import { AccountDto } from "../../types/dtos/AccountDto"

type CurrentBalanceProps = {
    className: string
    account: AccountDto
}

const CurrentBalance = ({ className, account }: CurrentBalanceProps) => {
    const isCurrentDebit = account.balance.current?.creditDebitIndicator === "Debit"
    const isAvailableDebit = account.balance.available?.creditDebitIndicator === "Debit"
    return (
        <div className={`d-flex border-1 border rounded ${className} p-3`}>
            <div className="d-flex flex-column m-auto align-items-center">
                <span className="fw-bold fs-5">Current Balance</span>
                <span className="text-grey mb-2 fs-4">
                    {isCurrentDebit ? "-" : ""}
                    {convertToCurrency(account.balance.current?.amount ?? 0, account.metadata.currencyCode)}
                </span>

                <span className="fw-bold fs-5">Available Balance</span>
                <span className="text-grey mb-2 fs-4">
                    {isAvailableDebit ? "-" : ""}
                    {convertToCurrency(account.balance.available?.amount ?? 0, account.metadata.currencyCode)}
                </span>
            </div>
        </div>
    )
}

export default CurrentBalance
