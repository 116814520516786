import { useEffect } from "react";
import { useFeatureToggle } from "invevo-react-components"
import "./TotalProcessingOverrides.scss"

const TotalProcessing = ({ checkoutId, providerUrl, redirectUrl, brand, languageCode }) => {
  const { isEnabled: showEmailFieldCardPayment } = useFeatureToggle("showEmailFieldCardPayment")
  useEffect(() => {
    const jqueryScript = document.createElement('script');
    jqueryScript.src = 'https://code.jquery.com/jquery.js'
    jqueryScript.type = 'text/javascript'
    document.body.appendChild(jqueryScript);

    const optionsScript = document.createElement('script');
    optionsScript.text = `
        var wpwlOptions = {
          locale: "${languageCode}",
          style:"card", 
          onReady: function(e){
            ${showEmailFieldCardPayment && `
            $(".wpwl-group-cvv").after( '<div class="wpwl-group wpwl-group-email wpwl-clearfix"><div class="wpwl-label wpwl-label-email" name="wpwl-label-customer.email">Email (optional)</div><div class="wpwl-wrapper wpwl-wrapper-email"><input type="email" name="customer.email" value="" class="wpwl-control wpwl-control-email" placeholder="Email address for receipt"></div></div>' );
            `}
            $('.wpwl-form-card').find('.wpwl-button-pay').on('click', function(e){
              validateHolder(e);
              validateEmail(e);
            });
          },
          onBeforeSubmitCard: function(e){
            return validateHolder(e) && validateEmail(e);
          }
        }
        function validateHolder(e){
          var holder = $('.wpwl-control-cardHolder').val();
          if (holder.trim().length < 2){
            $('.wpwl-control-cardHolder').addClass('wpwl-has-error').after('<div class="wpwl-hint wpwl-hint-cardHolderError">Invalid card holder</div>');
            return false;
          }
          return true;
        }
        function validateEmail(e){
          if (${!showEmailFieldCardPayment}) return true
          var email = $('.wpwl-control-email').val();
          if (email.length > 0 && !validateEmailAddress(email)){
            $('.wpwl-control-email').addClass('wpwl-has-error').after('<div class="wpwl-hint wpwl-hint-emailError">Invalid Email address</div>');
            return false;
          }
          return true;
        }
        function validateEmailAddress(email){
          var re = /^[^\\s@]+@[^\\s@]+.[^\\s@]$/;
          return re.test(email);
        }
      `;
    document.body.appendChild(optionsScript);

    const paymentWidgetsScript = document.createElement('script');

    paymentWidgetsScript.src = `${providerUrl}v1/paymentWidgets.js?checkoutId=${checkoutId}`;
    paymentWidgetsScript.async = true;
    document.body.appendChild(paymentWidgetsScript);

    return () => {
      document.body.removeChild(jqueryScript);
      document.body.removeChild(optionsScript);
      document.body.removeChild(paymentWidgetsScript);
    }
  }, [checkoutId, languageCode, providerUrl, showEmailFieldCardPayment]);

  return (
    <form action={redirectUrl} className="paymentWidgets" data-brands={getSupportedBrandName(brand)}></form>
  )
}

const getSupportedBrandName = (brand) => {
  switch (brand.toLowerCase()) {
    case 'visa':
      return 'VISA'
    case 'amex':
      return 'AMEX'
    case 'mastercard':
      return 'MASTER'
    default:
      return 'VISA'
  }
}

export default TotalProcessing;