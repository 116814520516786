import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { executeAuthAsyncPost } from '../../utility/asyncSupport';
import { showToastErrorMessage } from '../../api/toasterApi';
import AccountDetail from './accountDetail';
import AccountCard from './accountCard';
import usePrevious from '../../hooks/usePrevious';
import CustomSwitch from '../common/buttons/customSwitch';
import { useClient } from 'invevo-react-components';
import { setAccountValue } from '../../api/customerApi';

const AbortController = window.AbortController;

const AccountPreferencesCard = ({
  title,
  accountId,
  isVirtual,
  allowAutoIncrease,
  allowAutoDecrease,
  commsOnHold,
  open,
  setOpen,
  onUpdateAutoChange,
  onUpdateCommsOnHold,
  setAccountValue,
  executeAuthAsyncPost
}) => {
  const client = useClient()
  const [reqAllowAutoIncrease, setReqAllowAutoIncrease] = useState(null);
  const [reqAllowAutoDecrease, setReqAllowAutoDecrease] = useState(null);
  const [reqCommsOnHold, setReqCommsOnHold] = useState(null);

  const lastAccountId = usePrevious(accountId);

  useEffect(() => {
    const abortController = new AbortController();

    const resetReq = () => {
      setReqAllowAutoIncrease(null);
      setReqAllowAutoDecrease(null);
    };

    if (accountId !== lastAccountId) {
      return resetReq();
    }

    if (reqAllowAutoIncrease === null && reqAllowAutoDecrease === null) {
      return;
    }

    const allowAutoIncreaseTradeLimit =
      reqAllowAutoIncrease === null ? allowAutoIncrease : reqAllowAutoIncrease;
    const allowAutoDecreaseTradeLimit =
      reqAllowAutoDecrease === null ? allowAutoDecrease : reqAllowAutoDecrease;

    const onSuccess = () => {
      onUpdateAutoChange(
        allowAutoIncreaseTradeLimit,
        allowAutoDecreaseTradeLimit
      );
      resetReq();
    };

    const onError = () => {
      resetReq();
      showToastErrorMessage('There was an error saving the update.');
    };

    if (isVirtual) {
      executeAuthAsyncPost(
        client, 
        'virtualaccount/updatevaallowautochange',
        'UPDATE_VIRTUAL_ACCOUNT_ALLOW_AUTO_CHANGE',
        {
          virtualAccountId: accountId,
          allowAutoIncreaseTradeLimit,
          allowAutoDecreaseTradeLimit
        },
        onSuccess,
        onError,
        abortController.signal
      );
    } else {
      executeAuthAsyncPost(
        client,
        'Customer/UpdateAllowAutoChange',
        'UPDATE_CUSTOMER_ALLOW_AUTO_CHANGE',
        {
          customerId: accountId,
          allowAutoIncreaseTradeLimit,
          allowAutoDecreaseTradeLimit
        },
        onSuccess,
        onError,
        abortController.signal
      );
    }

    return () => {
      abortController.abort();
    };
  }, [lastAccountId, accountId, isVirtual, allowAutoIncrease, reqAllowAutoIncrease, reqAllowAutoDecrease, allowAutoDecrease, onUpdateAutoChange, executeAuthAsyncPost, client]);

  useEffect(() => {
    if (reqCommsOnHold === null) return;

    setAccountValue(client, accountId, 'AllCommunicationsOnHold', reqCommsOnHold).then(
      (result) => {
        if (accountId !== lastAccountId) {
          return setReqCommsOnHold(null);
        }

        onUpdateCommsOnHold(result.value);
        setReqCommsOnHold(null);
      }
    );
  }, [lastAccountId, accountId, reqCommsOnHold, commsOnHold, setAccountValue, onUpdateCommsOnHold, client]);

  return (
    <AccountCard title={title} open={open} setOpen={setOpen}>
      <AccountDetail
        title="Allow auto increase"
        value={
          <CustomSwitch
            id="autoIncreaseSwitch"
            checked={
              reqAllowAutoIncrease !== null
                ? reqAllowAutoIncrease
                : allowAutoIncrease
            }
            onChange={(checked) => setReqAllowAutoIncrease(checked)}
          />
        }
      />
      <AccountDetail
        title="Allow auto decrease"
        value={
          <CustomSwitch
            id="autoDecreaseSwitch"
            checked={
              reqAllowAutoDecrease !== null
                ? reqAllowAutoDecrease
                : allowAutoDecrease
            }
            onChange={(checked) => setReqAllowAutoDecrease(checked)}
          />
        }
      />
      {/* <Detail
        title="Comms on hold"
        value={
          <CustomSwitch
          id="commsOnHold"
            checked={reqCommsOnHold !== null ? reqCommsOnHold : commsOnHold}
            onChange={(checked) => setReqCommsOnHold(checked)}
          />
        }
      /> */}
    </AccountCard>
  );
};

export default connect(null, {
  executeAuthAsyncPost,
  setAccountValue
})(AccountPreferencesCard);
