import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Formsy from 'formsy-react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { showValidationResults } from '../../../actions/dashboardActions';

export class LookupValidationResults extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.close = this.close.bind(this);
    this.close = this.close.bind(this);
  }

  close() {
    const that = this;
    that.props.showValidationModal(false);
  }

  static getKey() {
    return 'validation-grid';
  }

  render() {
    const isVisible = this.props.isVisible;
    return (
      <div>
        <Modal
          show={isVisible}
          onHide={this.close}
          id="lookup-validation-modal"
          width="100%"
        >
          <Modal.Header closeButton>
            <button className="btnDefault btn btn-default">
              {this.props.tableName}
            </button>
          </Modal.Header>
          <Formsy className="form-group">
            <Modal.Body>
              <Grid
                id={LookupValidationResults.getKey()}
                key={LookupValidationResults.getKey()}
                data={this.props.validationResults}
                scrollable={'none'}
              >
                <GridColumn field={'recordId'} title={'Record Id'} />
                <GridColumn field={'lineData'} title={'CSV Data'} />
                <GridColumn field={'errorMessage'} title={'Error Message'} />
              </Grid>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={this.close}
                className="btnDefault btn btn-default"
              >
                Close
              </button>
            </Modal.Footer>
          </Formsy>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isVisible: state.dashboardReducer.showLookupValidation
});

const mapDispatchToEvents = (dispatch) => {
  return {
    showValidationModal: (isVisible) =>
      dispatch(showValidationResults(isVisible))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToEvents
)(LookupValidationResults);
