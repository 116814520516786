import { useMemo, useState } from "react"
import { padElementsWith } from "../../../../library/helpers"
import Dropdown from "./Dropdown"
import TransactionOverlay from "./TransactionOverlay"
import TransactionRow from "./TransactionRow.mobile"
import moment from "moment"
import { DirectIdTransactionDto } from "../../types/dtos/CreditCircleTransactionsDto"

type TransactionsProps = {
    transactions: DirectIdTransactionDto[]
}

const periodOptions = [
    { label: "12 Months", value: "12", months: 12 },
    { label: "6 Months", value: "6", months: 6 },
    { label: "3 Months", value: "3", months: 3 },
    { label: "Last Month", value: "1", months: 1 }
]

const Transactions = ({ transactions }: TransactionsProps) => {
    const [selectedOption, setSelectedOption] = useState(periodOptions[0])
    const [selectedTransaction, setSelectedTransaction] = useState<DirectIdTransactionDto>()

    const onPeriodChange = (newOption: any) => {
        setSelectedOption(newOption)
    }

    const onTransactionClick = (transaction: DirectIdTransactionDto) => {
        setSelectedTransaction(transaction)
    }

    const onTransactionOverlayClose = () => {
        setSelectedTransaction(undefined)
    }

    const transactionsForPeriod = useMemo(() => {
        const startOfPeriod = moment().subtract(selectedOption?.months, "months")
        const transactionsToReturn = transactions
        return transactionsToReturn.filter((transaction: DirectIdTransactionDto) => {
            if (!transaction.bookingDate) {
                return true
            }

            return moment(transaction.bookingDate).isAfter(startOfPeriod)
        })
    }, [transactions, selectedOption?.months])

    return (
        <div className="d-flex w-100 h-100">
            <div className="w-100">
                <Dropdown options={periodOptions} option={selectedOption} onOptionChange={onPeriodChange} />
                <div className="d-flex flex-column mt-3">
                    {transactionsForPeriod.length === 0 ? (
                        <span className="text-white">There are no transactions to display</span>
                    ) : (
                        padElementsWith(
                            transactionsForPeriod.map(transaction => (
                                <TransactionRow
                                    key={transaction.transactionId}
                                    transaction={transaction}
                                    currency={"EUR"}
                                    onTransactionClick={onTransactionClick}
                                />
                            )),
                            "my-1"
                        )
                    )}
                </div>
            </div>
            {selectedTransaction && <TransactionOverlay transaction={selectedTransaction} onClose={onTransactionOverlayClose} />}
        </div>
    )
}

export default Transactions
