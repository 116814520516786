import { connect } from "react-redux"
import SystemConfigurationButton from "./SystemConfigurationButton"
import { hasPermission } from "../utility/authZ"
import { authZ_Permissions } from '../constants'
import { injectIntl } from 'react-intl'

const SystemConfiguration = ({ baseRoute, permissions, version }) => {
    return (
        <div className="d-flex flex-column flex-grow-1 align-items-center">
            <div className="d-flex justify-content-center">
                {
                    (hasPermission(permissions, authZ_Permissions.LookupAdmin) || hasPermission(permissions, authZ_Permissions.LookupReview)) &&
                    <SystemConfigurationButton baseRoute={baseRoute} title="Lookup Admin" page="lookup-admin" iconsClasses="fa-regular fa-bee" colour="#ebe996" />
                }
                {
                    hasPermission(permissions, authZ_Permissions.SystemConfiguration) &&
                    <SystemConfigurationButton baseRoute={baseRoute} title="System Config" page="system-configuration" iconsClasses="fa-regular fa-cat-space" colour="#dba7a7" />
                }
                {
                    hasPermission(permissions, authZ_Permissions.ClientConfiguration) &&
                    <SystemConfigurationButton baseRoute={baseRoute} title="Client Config" page="client-configuration" iconsClasses="fa-regular fa-otter" colour="#b3dfe6" />
                }
                {
                    hasPermission(permissions, authZ_Permissions.UserAdmin) &&
                    <SystemConfigurationButton baseRoute={baseRoute} title="User Management" page="user-management" iconsClasses="fa-regular fa-crow" colour="#2d2d2e" />
                }
                {
                    hasPermission(permissions, authZ_Permissions.RoleAdmin) &&
                    <SystemConfigurationButton baseRoute={baseRoute} title="Role Management" page="role-management" iconsClasses="fa-regular fa-monkey" colour="#d4f0ad" />
                }
            </div>
            <div className="d-flex justify-content-center">
                {
                    (hasPermission(permissions, authZ_Permissions.RuleGroupManagementReview) || hasPermission(permissions, authZ_Permissions.RuleGroupManagementAdmin)) &&
                    <SystemConfigurationButton baseRoute={baseRoute} title="Rule Group Management" page="rule-group-management" iconsClasses="fa-regular fa-unicorn" colour="#f59af0" />
                }
                {
                    hasPermission(permissions, authZ_Permissions.FileImportAdminUpdate) &&
                    <SystemConfigurationButton baseRoute={baseRoute} title="File Importer Config" page="file-importer-configuration" iconsClasses="fa-regular fa-whale" colour="#e1e1e3" />
                }
                {
                    hasPermission(permissions, authZ_Permissions.ImportAdmin) &&
                    <SystemConfigurationButton baseRoute={baseRoute} title="File Importer Log" page="file-importer-log" iconsClasses="fa-regular fa-crab" colour="#d18c43" />
                }
                {
                    hasPermission(permissions, authZ_Permissions.MappingAdmin) &&
                    <SystemConfigurationButton baseRoute={baseRoute} title="Mapping" page="mapping-template" iconsClasses="fa-regular fa-hippo" colour="#cbb8de" />
                }
            </div>
            {
                hasPermission(permissions, authZ_Permissions.TemplateAdmin) &&
                <div className="d-flex justify-content-center">
                    <SystemConfigurationButton baseRoute={baseRoute} title="Email Master Template Manager" page="email-master-template-manager" iconsClasses="fa-regular fa-dragon" colour="#eba396" />
                    <SystemConfigurationButton baseRoute={baseRoute} title="Email Template Manager" page="email-template-manager" iconsClasses="fa-regular fa-squid" colour="#adf0cb" />
                    <SystemConfigurationButton baseRoute={baseRoute} title="Post Template Manager" page="post-template-manager" iconsClasses="fa-regular fa-dog" colour="#baaa9b" />
                    <SystemConfigurationButton baseRoute={baseRoute} title="Customer Agreement Template" page="customer-agreement-template" iconsClasses="fa-regular fa-kiwi-bird" colour="#7ccf23" />
                </div>
            }
            <div className="d-flex flex-column align-items-center p-2 ms-auto mt-auto">
                <span>Database: {version?.db}</span>
                <span>API: {version?.api}</span>
                <span>Client: {version?.client?.replace(/"/g, "")}</span>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    permissions: state.authReducer.permissions,
    version: state.lookupReducer.version.results
})

export default injectIntl(connect(mapStateToProps)(SystemConfiguration))