import SmallBarChart from "../../../../../library/charts/SmallBarChart"
import { widgetThemes } from "../../../enums/widgetThemes"
import { getBucketChartTypeFromAggs } from "./helpers"

const BarChartDisplayWidget = ({ state }) => {
    const { widget, aggregations } = state

    const data = getBucketChartTypeFromAggs(aggregations, widget.bucketChartType)

    return (
        <SmallBarChart
            className="w-100 h-100"
            title={widget.displayName}
            colour={widget.theme === widgetThemes.DEFAULT ? "white" : "blue"}
            xLabels={data.map(a => a.label)}
            data={data.map(a => a.total)}
        />
    )
}

export default BarChartDisplayWidget