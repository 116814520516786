import classes from "./WorkflowUserActions.module.scss"
import { useConfig } from "invevo-react-components"
import { convertWorkflowRuleToDto } from "../../types/ScheduledWorkflowRule"
import useApiQuery from "../../../../hooks/useApiQuery"
import { convertFromArrayToDto } from "../../../../library/FilterList/FiltersDto"
import { convertFromActionsArrayToDto } from "../../types/actions/GenericAction"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import Input from "../../../../library/Inputs/Input"
import ProgressButton from "../../../../library/buttons/ProgressButton/ProgressButton"
import DataField from "../../../../types/DataField"
import WorkflowRule from "../../types/WorkflowRule"
import { convertDataUpdatedWorkflowRuleToDto } from "../../types/DataUpdatedWorkflowRule"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"
import Dropdown from "../../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../../types/DropdownOptions"
import EntityConfig from "../../../entityConfig/types/EntityConfig"
import useClient from "../../../../hooks/useClient"
import { exists } from "../../../../library/helpers/tsUtils"
import { useState } from "react"
import DuplicateRuleModal from "./DuplicateRuleModal"
import { useUser } from "../../../../contexts/UserContext"
import { WorkflowTriggerConfig, getWorkflowTriggerConfigDtos } from "../../types/WorkflowTriggerConfigs"
import { useToaster } from "../../../../hooks/useToaster"
import { useOverlay } from "../../../../contexts/overlay/OverlayContext"

const KAFKA_CONFIG_NAME = "KAFKA"

type WorkflowUserActionsProps = {
    entityConfigs: EntityConfig[]
    dataFields: DataField[]
    selectedRuleModified: WorkflowRule | undefined
    selectedRuleOriginal: WorkflowRule | undefined
    originalWorkflowTriggerConfigsForRule: WorkflowTriggerConfig[]
    workflowTriggerConfigsForRule: WorkflowTriggerConfig[]
    workflowTriggerConfigsTriggeredByRule: WorkflowTriggerConfig[]
    onRuleUpdated: (rule: WorkflowRule) => void
    onRuleSaved: (rule: WorkflowRule) => void
    onTriggerConfigsSaved: (triggerConfigs: WorkflowTriggerConfig[]) => void
    onRuleDuplicated: (rule: WorkflowRule) => void
    onViewAsFlowClicked: () => void
}

const WorkflowUserActions = ({
    entityConfigs,
    dataFields,
    selectedRuleModified,
    selectedRuleOriginal,
    originalWorkflowTriggerConfigsForRule,
    workflowTriggerConfigsForRule,
    workflowTriggerConfigsTriggeredByRule,
    onRuleUpdated,
    onRuleSaved,
    onTriggerConfigsSaved,
    onRuleDuplicated,
    onViewAsFlowClicked
}: WorkflowUserActionsProps) => {
    const client = useClient()
    const config = useConfig()
    const user = useUser()
    const toaster = useToaster()

    const [systemDuplicationModalOpen, setSystemDuplicationModalOpen] = useState(false)

    const { isEnabled: isWorkflowFlowViewEnabled } = useFeatureToggle("workflowFlowView")
    const { isEnabled: entityAndLegacyAutomate } = useFeatureToggle("entityAndLegacyAutomate")
    const { isEnabled: newEntityStructure } = useFeatureToggle("newEntityStructure")

    const hasRuleChanged =
        JSON.stringify(selectedRuleModified) !== JSON.stringify(selectedRuleOriginal) ||
        JSON.stringify(originalWorkflowTriggerConfigsForRule) !== JSON.stringify(workflowTriggerConfigsForRule)

    const workflowRunRequest = useApiQuery({
        url: `${config.AUTOMATE_API_URL}/api/${client}/workflow-run`,
        method: "POST",
        isExecutedAutomatically: false
    })

    const scheduledWorkflowRuleSaveRequest = useApiQuery({
        url: `${config.AUTOMATE_API_URL}/api/${client}/workflow-rule`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    const dataUpdatedWorkflowRuleSaveRequest = useApiQuery({
        url: `${config.AUTOMATE_API_URL}/api/${client}/data-updated-workflow-rule`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    const workflowRuleTriggersSaveRequest = useApiQuery({
        url: `${config.INTEGRATION_API_URL}/api/${client}/entities`,
        method: "PATCH",
        isExecutedAutomatically: false
    })

    const onSaveClicked = () => {
        if (selectedRuleModified === undefined) return Promise.reject()

        if (selectedRuleModified.type === "SCHEDULE") {
            const saveRule = scheduledWorkflowRuleSaveRequest
                .execute(selectedRuleModified.reference, convertWorkflowRuleToDto(selectedRuleModified, dataFields))
                .then(() => onRuleSaved(selectedRuleModified))

            const workflowTriggerConfigDtos = getWorkflowTriggerConfigDtos(originalWorkflowTriggerConfigsForRule, workflowTriggerConfigsForRule)
            const saveRuleTriggers =
                workflowTriggerConfigDtos.length > 0
                    ? workflowRuleTriggersSaveRequest
                          .execute(undefined, workflowTriggerConfigDtos)
                          .then(() => onTriggerConfigsSaved(workflowTriggerConfigsForRule))
                    : Promise.resolve()

            return Promise.all([saveRule, saveRuleTriggers])
                .then(() => toaster.success("Workflow rule saved successfully"))
                .catch(() => toaster.error("Failed to save workflow rule"))
        } else {
            return dataUpdatedWorkflowRuleSaveRequest
                .execute(selectedRuleModified.reference, convertDataUpdatedWorkflowRuleToDto(selectedRuleModified, dataFields))
                .then(() => onRuleSaved(selectedRuleModified))
        }
    }

    const overlay = useOverlay()

    const confirmRunOverlayContent = () => (
        <div className="fs-4 text-grey d-flex flex-column">
            <span className="mb-2">Running this workflow may cause the following rules to be triggered:</span>
            {workflowTriggerConfigsTriggeredByRule.map(triggerConfig => (
                <span className="fw-bold" key={triggerConfig.ruleToTriggerReference}>
                    {triggerConfig.ruleToTriggerName}
                </span>
            ))}
            <span className="mt-2">Do you want to run it anyway?</span>
        </div>
    )

    const onRunNowClicked = () => {
        if (selectedRuleModified === undefined) return Promise.reject()
        if (selectedRuleModified.type === "DATA") return Promise.reject()

        const executeRunWorkflowRequest = () =>
            workflowRunRequest.execute(undefined, {
                isPreview: false,
                ruleReference: selectedRuleModified.reference,
                entityConfigReference: selectedRuleModified.entityConfigReference,
                filters: convertFromArrayToDto(selectedRuleModified.baseFilters, true),
                actions: convertFromActionsArrayToDto(selectedRuleModified.actions, dataFields)
            })

        if (workflowTriggerConfigsTriggeredByRule.length === 0) {
            return executeRunWorkflowRequest()
        } else {
            overlay.showConfirmActionOverlay("Run workflow", confirmRunOverlayContent(), executeRunWorkflowRequest)
            return Promise.resolve()
        }
    }

    const onGeneratePreviewClicked = () => {
        if (selectedRuleModified === undefined) return Promise.reject()
        if (selectedRuleModified.type === "DATA") return Promise.reject()

        return workflowRunRequest.execute(undefined, {
            isPreview: true,
            ruleReference: selectedRuleModified.reference,
            entityConfigReference: selectedRuleModified.entityConfigReference,
            filters: convertFromArrayToDto(selectedRuleModified.baseFilters, true),
            actions: convertFromActionsArrayToDto(selectedRuleModified.actions, dataFields)
        })
    }

    const onArchiveClicked = () => {
        if (selectedRuleModified === undefined) return Promise.reject()
        const updatedRule = { ...selectedRuleModified, archived: !selectedRuleModified.archived }

        if (updatedRule.type === "SCHEDULE") {
            const archiveRule = scheduledWorkflowRuleSaveRequest
                .execute(selectedRuleModified.reference, convertWorkflowRuleToDto(updatedRule, dataFields))
                .then(() => onRuleUpdated(updatedRule))

            const archiveWorkflowTriggerConfigDtos = getWorkflowTriggerConfigDtos(originalWorkflowTriggerConfigsForRule, [])
            const archiveRuleTriggers =
                archiveWorkflowTriggerConfigDtos.length > 0
                    ? workflowRuleTriggersSaveRequest
                          .execute(undefined, archiveWorkflowTriggerConfigDtos)
                          .then(() => onTriggerConfigsSaved(workflowTriggerConfigsForRule))
                    : Promise.resolve()

            return Promise.all([archiveRule, archiveRuleTriggers])
                .then(() => toaster.success("Workflow rule archived successfully"))
                .catch(() => toaster.error("Failed to archive workflow rule"))
        } else {
            return dataUpdatedWorkflowRuleSaveRequest
                .execute(selectedRuleModified.reference, convertDataUpdatedWorkflowRuleToDto(updatedRule, dataFields))
                .then(() => onRuleUpdated(updatedRule))
        }
    }

    const onNameUpdated = (value: string) => {
        if (selectedRuleModified === undefined) return
        onRuleUpdated({ ...selectedRuleModified, name: value })
    }

    const onDuplicateRuleClick = () => {
        if (selectedRuleModified === undefined) return
        onRuleDuplicated(selectedRuleModified)
    }

    const onDuplicateToAnotherSystemClick = () => {
        setSystemDuplicationModalOpen(true)
    }

    const onDuplicateToAnotherSystemModalHide = () => {
        setSystemDuplicationModalOpen(false)
    }

    const entityTypeOptions = entityConfigs.map(entityConfig => ({
        label: entityConfig.displayName,
        value: entityConfig.reference
    }))

    const entityConfigOptions =
        selectedRuleModified?.type === "SCHEDULE" && entityAndLegacyAutomate
            ? [...entityTypeOptions, { label: "Kafka Customer", value: KAFKA_CONFIG_NAME }]
            : entityTypeOptions

    const onEntityConfigUpdated = (option: DropdownOption<string>) => {
        if (selectedRuleModified === undefined) return
        onRuleUpdated({
            ...selectedRuleModified,
            entityConfigReference: option.value !== KAFKA_CONFIG_NAME ? option.value : undefined
        })
    }

    const isNameValid = selectedRuleModified && selectedRuleModified.name.length >= 2 && selectedRuleModified.name.length <= 128

    return (
        <>
            <div className={`d-flex flex-column bg-blue ${classes.container}`}>
                <div className="d-flex flex-column p-3" style={{ height: "100%" }}>
                    <div className="d-flex">
                        <span className="text-uppercase mb-1 ms-1 text-white no-select">Rule name</span>
                        <span className="text-light-grey ms-auto fs-6">* Required</span>
                    </div>
                    <Input
                        className="mb-3"
                        placeholder="Please enter name"
                        value={selectedRuleModified?.name ?? ""}
                        onChange={onNameUpdated}
                        disabled={selectedRuleModified === undefined}
                    />
                    {selectedRuleModified && (selectedRuleModified.name.length < 2 || selectedRuleModified.name.length > 128) && (
                        <div className="d-flex mb-3">
                            <div className="d-flex text-white mb-3">
                                <i className="fal fa-exclamation-circle me-2 pt-1"></i>
                                <span>Name must be between 2 and 128 characters in length</span>
                            </div>
                        </div>
                    )}
                    <div className="d-flex flex-column mt-2">
                        <div className="d-flex">
                            <span className="text-uppercase mb-1 ms-1 text-white no-select">Entity</span>
                            <span className="text-light-grey ms-auto fs-6">* Required</span>
                        </div>
                        {newEntityStructure ? (
                            <Dropdown
                                options={entityConfigOptions}
                                onOptionSelected={onEntityConfigUpdated}
                                selectedOption={entityConfigOptions.find(option =>
                                    selectedRuleModified && exists(selectedRuleModified?.entityConfigReference)
                                        ? selectedRuleModified.entityConfigReference === option.value
                                        : entityAndLegacyAutomate
                                        ? option.value === KAFKA_CONFIG_NAME
                                        : undefined
                                )}
                                ariaLabel={"entity-config-options"}
                            />
                        ) : (
                            <Dropdown
                                options={[{ label: "Customer", value: KAFKA_CONFIG_NAME }]}
                                onOptionSelected={onEntityConfigUpdated}
                                selectedOption={{ label: "Customer", value: KAFKA_CONFIG_NAME }}
                                disabled={true}
                            />
                        )}
                    </div>
                    <div className="d-flex flex-column mt-auto mb-1">
                        {isWorkflowFlowViewEnabled && (
                            <StandardButton
                                className="w-100 mb-3"
                                iconClasses="fas fa-chart-network"
                                label="View as flow"
                                colour="blue"
                                onClick={onViewAsFlowClicked}
                            />
                        )}
                        <StandardButton
                            className="w-100 mb-3"
                            iconClasses="fas fa-clone"
                            label="Duplicate workflow rule"
                            colour="blue"
                            disabled={selectedRuleModified === undefined}
                            onClick={onDuplicateRuleClick}
                        />
                        {user.accessibleClients.length > 1 && (
                            <StandardButton
                                className="w-100 mb-3"
                                iconClasses="fas fa-clone"
                                label="Duplicate to another system"
                                colour="blue"
                                disabled={selectedRuleModified === undefined}
                                onClick={onDuplicateToAnotherSystemClick}
                            />
                        )}
                        <ProgressButton
                            className="w-100 mb-3"
                            iconClasses="fas fa-play"
                            label="Run now"
                            colour="blue"
                            onClickWithPromise={onRunNowClicked}
                            disabled={selectedRuleModified === undefined || selectedRuleModified.type === "DATA"}
                        />
                        <ProgressButton
                            className="w-100 mb-3"
                            iconClasses="fal fa-shapes"
                            label="Generate preview"
                            colour="blue"
                            succeededText="Success"
                            failedText="Failed to generate preview"
                            onClickWithPromise={onGeneratePreviewClicked}
                            disabled={selectedRuleModified === undefined || selectedRuleModified.type === "DATA"}
                        />
                        <ProgressButton
                            className="w-100 mb-3"
                            iconClasses="fal fa-archive"
                            label={selectedRuleModified && selectedRuleModified.archived ? "Restore workflow rule" : "Archive workflow rule"}
                            colour="blue"
                            onClickWithPromise={onArchiveClicked}
                            succeededText={selectedRuleModified && selectedRuleModified.archived ? "Restored successfully" : "Archived successfully"}
                            failedText="Failed to archive"
                            disabled={selectedRuleModified === undefined}
                        />
                        <ProgressButton
                            className="w-100"
                            iconClasses="fal fa-save"
                            label="Save workflow changes"
                            colour="blue"
                            succeededText="Saved successfully"
                            failedText="Failed to save"
                            onClickWithPromise={onSaveClicked}
                            disabled={selectedRuleModified === undefined || !isNameValid || !hasRuleChanged}
                        />
                    </div>
                </div>
            </div>
            <DuplicateRuleModal
                show={systemDuplicationModalOpen}
                onHide={onDuplicateToAnotherSystemModalHide}
                selectedRule={selectedRuleOriginal}
                fields={dataFields}
            />
        </>
    )
}

export default WorkflowUserActions
