import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createRootReducer from '../legacy/reducers/reducers'
export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const middlewares = [routerMiddleware(history), thunk];

  if (process.env.NODE_ENV === 'development')
    middlewares.push(require('redux-immutable-state-invariant').default());

  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  return store;
}
