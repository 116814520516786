import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import { getApiUrl } from '../../../constants';
import { GridColumn } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../common/grids/columnMenu';
import StatefulGrid from '../../common/grids/StatefulGrid';

import EmailTemplateVersionsInnerGrid from './emailTemplateVersionsInnerGrid';

export class EmailTemplateVersionsGrid extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.editorRef = null;

    this.state = { refresh: 0, expandedItemId: '' };
  }

  static getColumns() {
    return [
      { field: 'Name', title: 'Email Template Name' },
      { field: 'EmailTemplateVersionCount', title: 'Template Versions Count' }
    ];
  }

  rebind = () => {
    this.setState({ refresh: this.state.refresh + 1 });
  };

  onRowClick = (gridRowEvent) => {
    const dataItem = gridRowEvent.dataItem;
    const id = dataItem.EmailTemplateId;
    if (!isNil(id)) {
      this.props.onRowSelected({
        emailTemplateId: id
      });
    }
  };

  innerGrid = (props) => (
    <EmailTemplateVersionsInnerGrid
      {...props}
      refresh={this.state.refresh}
      client={this.props.client}
      onRowSelected={this.props.onRowSelected}
    />
  );

  gridKey() {
    return 'emailTemplateVersionsGrid' + this.props.emailMasterTemplateId;
  }

  render() {
    return (
      <div id="mapping-grid" className="borderContainerNoTop">
        <StatefulGrid
          client={this.props.client}
          id={this.gridKey()}
          key={this.gridKey()}
          path={getApiUrl(this.props.client) + 'api/email/Templates'}
          sortable
          className={'scrollable-none'}
          pageSize={10}
          pageable={{
            pageSizes: [5, 10, 20, 100],
            buttonCount: 5
          }}
          hideablePaging={true}
          onRowClick={this.onRowClick}
          additionalRequestPayload={{
            emailMasterTemplateId: this.props.emailMasterTemplateId
          }}
          expandableRow={{
            detail: this.innerGrid,
            id: 'EmailTemplateId',
            type: 'multiple'
          }}
          defaultField={{ field: 'EmailTemplateId', dir: 'asc' }}
          refresh={this.state.refresh}
        >
          <GridColumn
            field={'Name'}
            title={'Email Template Name'}
            columnMenu={ColumnMenu}
          />
          <GridColumn
            field={'EmailTemplateVersionCount'}
            title={'Template Versions Count'}
            columnMenu={ColumnMenu}
          />
        </StatefulGrid>
      </div>
    );
  }
}

EmailTemplateVersionsGrid.propTypes = {
  emailMasterTemplateId: PropTypes.string.isRequired,
  onRowSelected: PropTypes.func.isRequired
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  EmailTemplateVersionsGrid
);
