import { Dropdown, LogicOperatorOptions } from 'invevo-react-components'
import { filterTypes } from '../../../filters/Filters'
import InlineFilters from '../../../filters/InlineFilters'
import classes from './SmsSendActionContactFilters.module.scss'

const SmsSendActionContactFilters = ({ className, state: contactConfig, onChange }) => {
    const sendToOptions = [
        { id: "1", value: "All contacts", serverValue: smsContactConfigOptions.ALL },
        { id: "2", value: "Any contact", serverValue: smsContactConfigOptions.ANY },
        { id: "3", value: "All contacts matching criteria", serverValue: smsContactConfigOptions.ALL_WITH_FILTERS },
        { id: "4", value: "Any contact matching criteria", serverValue: smsContactConfigOptions.ANY_WITH_FILTERS }
    ]

    const onSendToOptionSelected = (option) => {
        onChange({ ...contactConfig, option: option.serverValue })
    }

    const onFilterChange = (changedFilter, changedFilterIndex) => {
        const updatedFilters = contactConfig.contactFilters.fieldsAreInValues.map((filter, filterIndex) => {
            if (changedFilterIndex !== filterIndex) {
                return filter
            }

            let operatorValue = {}
            if (changedFilter.operator) {
                operatorValue = changedFilter.operator
            }

            return {
                ...filter, ...{
                    field: { fieldName: changedFilter.field }, value: changedFilter.value, operator: operatorValue, isValid: changedFilter.isValid
                }
            }
        })

        onChange({ ...contactConfig, contactFilters: { fieldsAreInValues: updatedFilters } })
    }

    const onAddRule = () => {
        onChange({ ...contactConfig, contactFilters: { fieldsAreInValues: [...contactConfig.contactFilters.fieldsAreInValues, { field: { fieldName: "" }, value: "" }] } })
    }

    const onRemoveRule = (rule, ruleIndex) => {
        onChange({ ...contactConfig, contactFilters: { fieldsAreInValues: contactConfig.contactFilters.fieldsAreInValues.filter((_, index) => index !== ruleIndex) } })
    }

    const onRemoveAll = () => {
        onChange({ ...contactConfig, contactFilters: { fieldsAreInValues: contactConfig.contactFilters.fieldsAreInValues.slice(0, 0) } })
    }

    const selectedSendOption = sendToOptions.find(o => o.serverValue === contactConfig.option)

    const showFilters = selectedSendOption && (selectedSendOption.serverValue === smsContactConfigOptions.ALL_WITH_FILTERS || selectedSendOption.serverValue === smsContactConfigOptions.ANY_WITH_FILTERS)
    const labelText = showFilters && selectedSendOption.serverValue === smsContactConfigOptions.ALL_WITH_FILTERS ? "Send to all contacts matching the following criteria" :
        showFilters && selectedSendOption.serverValue === smsContactConfigOptions.ANY_WITH_FILTERS ? "Send to one contact matching the following criteria" : ""

    const getFilterTypeFromOperator = (operator) => {
        if (operator === LogicOperatorOptions.DATE_DAYS_AFTER_TODAY || operator === LogicOperatorOptions.DATE_DAYS_BEFORE_TODAY) {
            const dateFilterType = filterTypes.find(ft => ft.value === LogicOperatorOptions.DATE_IS_IN)
            return { ...dateFilterType, value: operator }
        }

        return filterTypes.find(ft => ft.value === operator)
    }

    const getRulesFromFilters = (filters) => {
        return filters.map(f => {
            return {
                field: f.field.fieldName,
                value: f.value,
                operator: f.useAsNotIn ? getFilterTypeFromOperator("NOT_IN") : getFilterTypeFromOperator("IN"),
                isValid: f.isValid
            }
        })
    }

    return (
        <div className={`${className ? className : ''} d-flex ${classes.container} w-100`}>
            <div className="d-flex flex-column w-100">
                <div className="d-flex mt-3 align-items-center">
                    <span className="text-grey text-uppercase me-3">Send to</span>
                    <div className="d-flex">
                        <Dropdown
                            className="me-3"
                            defaultOption={selectedSendOption}
                            options={sendToOptions}
                            onOptionSelected={onSendToOptionSelected}
                        />
                    </div>
                </div>
                {
                    showFilters && <InlineFilters
                        className="mt-3"
                        label={labelText}
                        rules={getRulesFromFilters(contactConfig.contactFilters.fieldsAreInValues)}
                        onRuleChange={onFilterChange}
                        onAddRule={onAddRule}
                        onRemoveRule={onRemoveRule}
                        onRemoveAll={onRemoveAll} />
                }
            </div>
        </div>
    )
}

export const smsContactConfigOptions = {
    ALL: "ALL",
    ANY: "ANY",
    ALL_WITH_FILTERS: "ALL_WITH_FILTERS",
    ANY_WITH_FILTERS: "ANY_WITH_FILTERS"
}

export default SmsSendActionContactFilters