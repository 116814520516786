import classes from './WorkflowRuns.module.scss'
import moment from 'moment'

const WorkflowRuns = ({ workflowRuns, onClick }) => {
    return (
        workflowRuns.length > 0
            ?
            workflowRuns.map((workflowRun, index) =>
                <div className={`d-flex flex-column`}>
                    <div
                        key={`workflowRun${index}`}
                        className={`${classes.changeingBorder} d-flex flex-column bg-white w-100 px-3`}
                        onClick={() => onClick(workflowRun, "all")}
                    >
                        {workflowRun.isPreview
                            ?
                            <div className="d-flex pt-2">
                                <i className="fal fa-eye text-blue me-1 fs-6 pt-1"></i>
                                <p className="text-blue">Preview</p>
                            </div>
                            : workflowRun.triggeredBy !== "Automate"
                                ?
                                <div className="d-flex pt-2">
                                    <i className="fal fa-hand-paper text-blue me-1 fs-6 pt-1"></i>
                                    <p className="text-blue">Manual</p>
                                </div>
                                :
                                <div className="d-flex pt-2">
                                    <i className="fal fa-clock text-blue me-1 fs-6 pt-1"></i>
                                    <p className="text-blue">Scheduled</p>
                                </div>

                        }

                        <div className="d-flex">
                            <div className="d-flex">
                                <i className="fal fa-calendar-alt text-blue me-2 fs-3 pt-1"></i>
                                <p className="pt-1 text-dark-grey fs-5">{moment(workflowRun.evaluationStart).format('lll')}</p>
                                <span className="fs-5 mx-1 pt-1 text-dark-grey">&#8226;</span>
                                <p className="pt-2 text-dark-grey">{workflowRun.triggeredBy}</p>
                            </div>
                            <div className="d-flex ms-auto">
                                <p className={`${classes.disabledText} pt-1`}>Cash to collect:</p>
                                <p className={`${classes.disabledText} pt-1 ms-1`}>£0,00</p>
                                <p className={`${classes.disabledText} pt-1 ms-5`}>Cash collected:</p>
                                <p className={`${classes.disabledText} pt-1 me-5 ms-1`}>£0,00</p>
                                <p className={`${classes.disabledText} pt-1`}>Outstanding:</p>
                                <p className={`${classes.disabledText} pt-1 ms-1`}>£0,00</p>
                            </div>
                        </div>

                        <div className={`${classes.coloredBox} my-1`}></div>

                        <div className="d-flex">
                            <div className="d-flex pt-3 pe-5">
                                <i className="fal fa-mobile text-blue me-1 fs-5"></i>
                                <p className={`${classes.smallText} text-black fw-bold`}>SMS</p>
                            </div>

                            <div className={`position-relative d-flex px-5`} onClick={() => console.log('coming soon')}>
                                <div className={`d-flex ${classes.onHover} pt-3 px-2`}>
                                    <i className={`fal fa-handshake-alt text-green me-2 fs-5`}></i>
                                    <p className={`${classes.smallText} text-black fw-bold me-1 rounded`}>{workflowRun.totalSuccessfulActions}</p>
                                    <p className={`${classes.smallText} text-grey`}>successful</p>
                                </div>
                            </div>

                            <div className={`position-relative d-flex px-5`} onClick={() => console.log('coming soon')}>
                                <div className={`d-flex ${classes.onHover} pt-3 px-2`}>
                                    <i className={`fal fa-handshake-alt-slash text-red me-2 fs-5`}></i>
                                    <p className={`${classes.smallText} text-black fw-bold me-1 rounded`}>{workflowRun.totalFailedActions}</p>
                                    <p className={`${classes.smallText} text-grey`}>failed</p>
                                </div>
                            </div>
                            <div className={`position-relative d-flex px-5`} onClick={() => console.log('coming soon')}>
                                <div className={`d-flex ${classes.onHover} pt-3 px-2`}>
                                    <i className={`fal fa-handshake-alt-slash text-grey me-2 fs-5`}></i>
                                    <p className={`${classes.smallText} text-black fw-bold me-1 rounded`}>{workflowRun.totalNotMatchedActions}</p>
                                    <p className={`${classes.smallText} text-grey`}>not matched</p>
                                </div>
                            </div>
                            <div className={`position-relative d-flex px-5`} onClick={() => console.log('coming soon')}>
                                <div className={`d-flex ${classes.onHover} pt-3 px-2`}>
                                    <i className={`fal fa-clone text-grey me-2 fs-5`}></i>
                                    <p className={`${classes.smallText} text-black fw-bold me-1 rounded`}>{workflowRun.totalDuplicatedActions}</p>
                                    <p className={`${classes.smallText} text-grey`}>duplicates</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            :
            <div className={"d-flex align-items-center no-select"}>
                <i className="fal fa-exclamation-triangle text-warning me-2 fs-4"></i>
                <span className="fs-5 me-2">No historical workflow runs</span>
            </div>
    )
}

export default WorkflowRuns