import LegacySmsTemplate from "../../types/LegacySmsTemplate"

const EmailActionInfo = ({ template, bigTitle }: { template: LegacySmsTemplate | undefined; bigTitle: boolean }) => {
    return (
        <div className="d-flex align-items-center gap-2 me-3">
            <i className="fal fa-at text-blue fs-5" />
            <span className={`text-black fw-bold small ${bigTitle && "fs-5"}`}>Send Email</span>
            {template && (
                <span className="d-flex small">
                    with template
                    <span className={`d-block text-black fw-bold text-truncate ms-1 ${bigTitle && "fs-5"}`}>{template.name}</span>
                </span>
            )}
        </div>
    )
}

export default EmailActionInfo
