import {
  residualDebitAtInstalment,
  residualInterestAtInstalment,
  calculateInterestBetweenTwoDates,
  findEndDate,
  shiftDate,
  roundCurrency,
  getInterestForThePeriod,
  getConstantInstalment
  //pmt,
  // PMT,
  // PPMT,
  // IPMT
} from './repaymentPlanUtility';
import { MIN_INSTALLMENTS } from './repaymentConstants';

const defaultRepaymentPlan = {
  actual: [],
  sourceInvoices: [],

  sourceInvoicesTotalAmount: 0,
  sourceInvoicesTotalInterests: 0,
  sourceInvoicesTotal: 0,

  id: null,
  interest: null,
  interestNumber: null,
  startDate: new Date(),
  frequencyId: 1, //weekly
  numberOfPayments: null,
  endDate: null,
  paymentDayId: 0,

  totalOfRepaymentPlan: 0,
  planStatus: 1, // Open

  scheduleRows: [],

  currency: null,

  currentPosition: null
};

const calculatedEndDate = (
  startDate,
  frequencyId,
  numberOfPayments,
  paymentDayId = 0
) => {
  if (startDate && frequencyId > 0 && numberOfPayments != null) {
    const endDate = findEndDate(
      startDate,
      frequencyId,
      numberOfPayments
    ).toDate();

    return paymentDayId === 0
      ? endDate
      : shiftDate(frequencyId, endDate, paymentDayId);
  }

  return null;
};

const safeDate = (dateString) => (dateString ? new Date(dateString) : null);

const updateSourceInvoices = ({
  interestNumber,
  startDate,
  sourceInvoices,
  ...rest
}) => {
  const hasInterest = Number.isFinite(interestNumber) && startDate != null;
  const rows = sourceInvoices.map((item) => ({
    ...item,
    InvoiceDate: safeDate(item.InvoiceDate),
    DueDate: safeDate(item.DueDate),
    TotalInterest: hasInterest
      ? calculateInterestBetweenTwoDates(
          item.AmountInLocalCurrency,
          interestNumber / 100,
          item.DueDate,
          startDate
        )
      : 0
  }));

  let sourceInvoicesTotalAmount = 0;
  let sourceInvoicesTotalInterests = 0;
  rows.forEach((t) => {
    sourceInvoicesTotalAmount += t.AmountInLocalCurrency;
    sourceInvoicesTotalInterests += t.TotalInterest;
  });
  const sourceInvoicesTotal =
    sourceInvoicesTotalAmount + sourceInvoicesTotalInterests;

  const currencies = sourceInvoices.map((item) => item.Currency);
  const currency = currencies
    ? currencies.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })[0]
    : null;

  return {
    ...rest,
    interestNumber,
    startDate,
    sourceInvoices: rows,
    sourceInvoicesTotalAmount,
    sourceInvoicesTotalInterests,
    sourceInvoicesTotal,
    currency
  };
};

const userSetPaymentValues = (
  initPrincipalSum,
  firstPayment,
  lastPayment,
  interestForPeriod
) => {
  const hasFirstPayment = firstPayment > 0;
  const firstPaymentInterest = initPrincipalSum * interestForPeriod;

  const secondPrincipalSum = hasFirstPayment
    ? initPrincipalSum + firstPaymentInterest - firstPayment
    : initPrincipalSum;

  const hasLastPayment = lastPayment > 0;
  const lastPrincipalSum = lastPayment / (interestForPeriod + 1);
  const lastPaymentInterest = lastPrincipalSum * interestForPeriod;

  const principalSumForFixed = secondPrincipalSum - lastPrincipalSum;

  return {
    hasFirstPayment,
    firstPaymentInterest,
    principalSumForFixed,
    hasLastPayment,
    lastPrincipalSum,
    lastPaymentInterest
  };
};

const updatePlanRows = ({
  startDate,
  frequencyId,
  numberOfPayments,
  interestNumber,
  paymentDayId,
  sourceInvoicesTotal,
  currency,
  firstPayment = 0,
  lastPayment = 0,
  ...rest
}) => {
  const scheduleRows = [];
  let totalDebt = 0;
  if (
    startDate != null &&
    frequencyId > 0 &&
    numberOfPayments >= MIN_INSTALLMENTS &&
    Number.isFinite(interestNumber) &&
    paymentDayId > 0
  ) {
    const interestForPeriod = getInterestForThePeriod(
      interestNumber / 100,
      frequencyId
    );

    const {
      hasFirstPayment,
      firstPaymentInterest,
      principalSumForFixed,
      hasLastPayment,
      lastPrincipalSum,
      lastPaymentInterest
    } = userSetPaymentValues(
      sourceInvoicesTotal,
      firstPayment,
      lastPayment,
      interestForPeriod
    );

    const numberOfFixedIntalments =
      numberOfPayments - Number(hasFirstPayment) - Number(hasLastPayment);

    const fixedInstalmentAmount = getConstantInstalment(
      principalSumForFixed,
      interestForPeriod,
      numberOfFixedIntalments
    );

    const totalFixedInstalments =
      fixedInstalmentAmount * numberOfFixedIntalments;
    totalDebt = firstPayment + totalFixedInstalments + lastPayment;
    const totalInstalmentsRounded = roundCurrency(totalFixedInstalments);
    const instalmentAmountRounded = roundCurrency(fixedInstalmentAmount);
    const lastInstalmentDifference = roundCurrency(
      totalInstalmentsRounded -
        roundCurrency(instalmentAmountRounded * numberOfFixedIntalments)
    );

    let decreasingDebt = totalDebt;

    for (let i = 0; i < numberOfPayments; i++) {
      const isFirst = i === 0;
      const isLast = i === numberOfPayments - 1;

      const end = findEndDate(startDate, frequencyId, i + 1).toDate();
      const paymentDate = shiftDate(frequencyId, end, paymentDayId);

      if (hasFirstPayment && isFirst) {
        decreasingDebt -= firstPayment; // TODO based on below but I think is wrong as take no interest into account;
        scheduleRows.push({
          Name: (i + 1).toString(),
          PrincipalSum: sourceInvoicesTotal,
          Interest: firstPaymentInterest,
          PaymentValue: firstPayment,
          PaymentDate: paymentDate,
          Currency: currency,
          Balance: decreasingDebt, // Is this needed?
          CurrentPosition: '',
          DateChanged: ''
        });
      } else if (hasLastPayment && isLast) {
        decreasingDebt -= lastPayment;
        if (decreasingDebt < 0 && decreasingDebt > -0.01) {
          decreasingDebt = 0;
        }

        scheduleRows.push({
          Name: (i + 1).toString(),
          PrincipalSum: lastPrincipalSum,
          Interest: lastPaymentInterest,
          PaymentValue: lastPayment,
          PaymentDate: paymentDate,
          Currency: currency,
          Balance: decreasingDebt, // Is this needed?
          CurrentPosition: '',
          DateChanged: ''
        });
      } else {
        const isLastFixedInstalment =
          hasLastPayment && i === numberOfPayments - 2;
        const paymentValue =
          isLast || isLastFixedInstalment
            ? instalmentAmountRounded + lastInstalmentDifference
            : instalmentAmountRounded;

        decreasingDebt -= paymentValue;

        if (decreasingDebt < 0 && decreasingDebt > -0.01) {
          decreasingDebt = 0;
        }

        const fixedInstalmentPosition = i - Number(hasFirstPayment);

        scheduleRows.push({
          Name: (i + 1).toString(),
          PrincipalSum: residualDebitAtInstalment(
            fixedInstalmentAmount,
            interestForPeriod,
            numberOfFixedIntalments,
            fixedInstalmentPosition
          ),
          Interest: residualInterestAtInstalment(
            fixedInstalmentAmount,
            interestForPeriod,
            numberOfFixedIntalments,
            fixedInstalmentPosition + 1
          ),
          PaymentValue: paymentValue,
          PaymentDate: paymentDate,
          Currency: currency,
          Balance: decreasingDebt,
          CurrentPosition: '',
          DateChanged: ''
        });
      }
    }
  }

  return {
    ...rest,
    startDate,
    frequencyId,
    numberOfPayments,
    interestNumber,
    paymentDayId,
    sourceInvoicesTotal,
    currency,
    totalOfRepaymentPlan: totalDebt,
    scheduleRows
  };
};

const createExistingPlanRows = ({ instalments, actual, ...rest }) => {
  const scheduleRows = instalments.map((instalment, index) => {
    const actualRow = actual[index] ? actual[index] : {};

    return {
      Name: (index + 1).toString(),
      PrincipalSum: instalment.AmountInLocalCurrency,
      Interest: instalment.InterestToPlanDate,
      PaymentValue: instalment.PaymentValue,
      PaymentDate: safeDate(instalment.InstalmentDate),
      Currency: instalment.Currency,
      ProjectedBalance: actualRow.ProjectedBalance,
      ActualBalance: actualRow.ActualBalance,
      LastActivityDate: safeDate(actualRow.LastActivityDate),
      PositionType: actualRow.PositionType
    };
  });

  return {
    ...rest,
    instalments,
    actual,
    scheduleRows
  };
};

export const initaliseRepaymentPlan = () => ({ ...defaultRepaymentPlan });

export const createFromExisting = (rrp) => {
  const startDate = safeDate(rrp.StartDate);
  const frequencyId = rrp.InterestPeriod;
  const numberOfPayments = rrp.NumberPayments;
  const paymentDayId = rrp.DayPaymentTaken;

  const invoiceAdjusted = updateSourceInvoices({
    ...defaultRepaymentPlan,
    actual: rrp.Actual.map((item) => ({
      ...item,
      InstallmentDate: safeDate(item.InstallmentDate),
      LastActivityDate: safeDate(item.LastActivityDate)
    })),
    currentPosition: rrp.CurrentPosition,
    id: rrp.Id,
    interest: rrp.InterestRatePercent.toString(),
    interestNumber: rrp.InterestRatePercent,
    startDate,
    frequencyId,
    numberOfPayments,
    paymentDayId,
    endDate: calculatedEndDate(
      startDate,
      frequencyId,
      numberOfPayments,
      paymentDayId
    ),
    instalments: rrp.Instalments,
    currency: rrp.Currency,
    sourceInvoices: rrp.Invoices,
    totalOfRepaymentPlan: rrp.TotalOfRepaymentPlan,
    planStatus: rrp.PlanStatus,
    sourceInvoicesTotalAmount: rrp.TotalAmountLocalCurrency,
    sourceInvoicesTotalInterests: rrp.TotalInterest,
    sourceInvoicesTotal: rrp.TotalAmountLocalCurrency + rrp.TotalInterest
  });

  return createExistingPlanRows(invoiceAdjusted);
};

export const setSourceInvoices = (
  invoices,
  repaymentPlan = defaultRepaymentPlan
) => {
  return updateSourceInvoices({ ...repaymentPlan, sourceInvoices: invoices });
};

export const setInterest = (interest, repaymentPlan = defaultRepaymentPlan) => {
  const interestNumber = parseFloat(interest);

  const repaymentPlanWithInterest =
    interest > 1000
      ? {
          ...repaymentPlan,
          interestNumber: 1000,
          interest: '1000'
        }
      : {
          ...repaymentPlan,
          interestNumber,
          interest
        };

  return updatePlanRows(updateSourceInvoices(repaymentPlanWithInterest));
};

export const setStartDate = (
  startDate,
  repaymentPlan = defaultRepaymentPlan
) => {
  const repaymentWithStartDateUpdate = {
    ...repaymentPlan,
    startDate,
    endDate: calculatedEndDate(
      startDate,
      repaymentPlan.frequencyId,
      repaymentPlan.numberOfPayments,
      repaymentPlan.paymentDayId
    )
  };

  return updatePlanRows(updateSourceInvoices(repaymentWithStartDateUpdate));
};

export const setFormParameters = (
  interestRate,
  frequencyId,
  startDate,
  paymentDayId,
  numberOfPayments,
  firstPayment,
  lastPayment,
  repaymentPlan
) => {
  const updatedRepaymentPlan = {
    ...repaymentPlan,
    startDate,
    frequencyId,
    numberOfPayments,
    firstPayment,
    lastPayment,
    paymentDayId,
    endDate: calculatedEndDate(
      startDate,
      frequencyId,
      numberOfPayments,
      paymentDayId
    )
  };

  return setInterest(interestRate, updatedRepaymentPlan);
};

export const setPaymentDayId = (
  paymentDayId,
  repaymentPlan = defaultRepaymentPlan
) => {
  const repaymentPlanWithPaymentDay = {
    ...repaymentPlan,
    paymentDayId
  };

  return updatePlanRows(repaymentPlanWithPaymentDay);
};

export const showCalculatedInterests = (
  repaymentPlan = defaultRepaymentPlan
) => {
  return (
    Number.isFinite(repaymentPlan.interestNumber) &&
    repaymentPlan.startDate !== null
  );
};
export const showWeek = (repaymentPlan = defaultRepaymentPlan) => {
  return repaymentPlan.frequencyId === 1 || repaymentPlan.frequencyId === 2;
};
export const showMonth = (repaymentPlan = defaultRepaymentPlan) => {
  return repaymentPlan.frequencyId === 3 || repaymentPlan.frequencyId === 4;
};
export const showPlan = (repaymentPlan = defaultRepaymentPlan) => {
  return (
    Number.isFinite(repaymentPlan.interestNumber) &&
    repaymentPlan.startDate != null &&
    repaymentPlan.frequencyId > 0 &&
    repaymentPlan.numberOfPayments != null &&
    repaymentPlan.paymentDayId > 0
  );
};

export const isReadOnly = (repaymentPlan = defaultRepaymentPlan) => {
  return repaymentPlan.id != null;
};
