import React, { createContext, useState } from 'react';
import { layoutConstants } from '../constants';

const { LEFT_PANE_MIN_WIDTH, RIGHT_PANE_MIN_WIDTH } = layoutConstants;

const workspaceLayoutContext = createContext();

const { Provider } = workspaceLayoutContext;

const WorkspaceLayoutProvider = ({ children }) => {
  const [layoutState, setLayoutState] = useState({
    lastRightPaneKey: null,
    leftPaneWidth: LEFT_PANE_MIN_WIDTH,
    rightPaneWidth: RIGHT_PANE_MIN_WIDTH,
    collapsed: ''
  });

  return (
    <Provider
      value={{
        layoutState,
        setLayoutState
      }}
    >
      {children}
    </Provider>
  );
};

export { workspaceLayoutContext, WorkspaceLayoutProvider };
