import classes from "./DataUpdatedTriggerNode.module.scss"
import GenericFilter from "../../../../library/FilterList/filterTypes/GenericFilter"
import DataField from "../../../../types/DataField"
import Lookup from "../../../../types/Lookup"
import Filter from "../../../../library/FilterList/Filter"

type DataUpdatedTriggerNodeProps = {
    matchedBeforeUpdateFilters: GenericFilter[]
    matchedAfterUpdateFilters: GenericFilter[]
    fields: DataField[]
    lookups: Lookup[]
}

const DataUpdatedTriggerNode = ({ matchedBeforeUpdateFilters, matchedAfterUpdateFilters, fields, lookups }: DataUpdatedTriggerNodeProps) => {
    const emptyFunc = () => {}

    return (
        <div className="d-flex flex-column bg-grey p-2 border-top border-3 border-danger">
            <div className="d-flex align-items-center gap-2 me-3">
                <i className="fal fa-clock text-blue fs-5" />
                <span className="text-black fw-bold small fs-5 text-capitalize">Trigger</span>
            </div>
            <div className={`${classes.separator} d-flex flex-row my-2 bg-dark-gradient`} />
            <span className="mb-1">Matched Before Update:</span>
            <div className="d-flex flex-grow-1 flex-column gap-2">
                {matchedBeforeUpdateFilters.map(filter => (
                    <Filter
                        key={filter.id}
                        filter={filter}
                        fields={fields}
                        lookups={lookups}
                        disabled={true}
                        onFilterUpdated={emptyFunc}
                        onFilterDeleted={emptyFunc}
                        showFieldType={false}
                        textOnly={true}
                    />
                ))}
            </div>
            <span className="mt-3 mb-1">Matches After Update:</span>
            <div className="d-flex flex-grow-1 flex-column gap-2">
                {matchedAfterUpdateFilters.map(filter => (
                    <Filter
                        key={filter.id}
                        filter={filter}
                        fields={fields}
                        lookups={lookups}
                        disabled={true}
                        onFilterUpdated={emptyFunc}
                        onFilterDeleted={emptyFunc}
                        showFieldType={false}
                        textOnly={true}
                    />
                ))}
            </div>
        </div>
    )
}

export default DataUpdatedTriggerNode
