import { Overlay } from "invevo-react-components"
import { useState } from "react"
import EntityDashboardConfig from "./EntityDashboardConfig"
import classes from "./Header.module.scss"
import { DashboardConfigAction, DashboardConfigsState } from "../../reducers/dashboardConfigsReducer"
import { dashboardTypes } from "../../types/DashboardTypes"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import { useOverlay } from "../../../../contexts/overlay/OverlayContext"
import DuplicateDashboardConfigForm from "./DuplicateDashboardConfig"
import DashboardConfigState from "../../types/DashboardConfigState"
import { actionTypes } from "../../reducers/actionTypes"

type HeaderProps = {
    className?: string
    state: DashboardConfigsState
    dispatch: React.Dispatch<DashboardConfigAction>,
    isNewEntityStructureEnabled: boolean
}

const Header = ({ className, state, dispatch, isNewEntityStructureEnabled }: HeaderProps) => {
    const [showAddDashboardForm, setShowAddDashboardForm] = useState(false)
    const overlay = useOverlay()
    const onAddDashboardClick = () => {
        setShowAddDashboardForm(true)
    }

    const onDuplicate = (duplicateDashboards: DashboardConfigState[] | undefined) =>
        duplicateDashboards?.forEach(duplicateDashboard => {
            dispatch({ type: actionTypes.DASHBOARD_CONFIG_ADDED, dashboardConfig: duplicateDashboard })
        })

    const onDuplicateClick = () => {
        overlay.showOverlay(
            <DuplicateDashboardConfigForm
                dashboardConfigs={state.dashboardConfigs}
                existingDashboardConfigNames={state.dashboardConfigs.map(config => config.name)}
                isNewEntityStructureEnabled={isNewEntityStructureEnabled}
                isAggregatedDashboard={false}
                onDashboardConfigsDuplicated={onDuplicate}
                onClose={overlay.closeOverlay}
                isDuplicatingAll={true} />
        )
    }

    const onCloseAddDashboardClick = () => {
        setShowAddDashboardForm(false)
    }

    const onConfigDeleted = () => {
        setShowAddDashboardForm(false)
    }

    const getOverlayBody = (selectedDashboardType: dashboardTypes) => {
        switch (selectedDashboardType) {
            case dashboardTypes.ENTITY:
            case dashboardTypes.AGGREGATED: {
                return (
                    <EntityDashboardConfig
                        state={state}
                        dispatch={dispatch}
                        onDeleted={onConfigDeleted}
                        isNewEntityStructureEnabled={isNewEntityStructureEnabled}
                    />
                )
            }
            default:
                return <></>
        }
    }

    return (
        <>
            <div className={`d-flex align-items-center ${classes.header} px-3 bg-grey ${className ? className : ""}`}>
                <h2 className="text-grey no-select mb-0">Dashboards</h2>
                <div className="d-flex ms-auto">
                    <StandardButton className="me-3" label="Duplicate all dashboards" iconClasses="fal fa-plus" colour="grey" onClick={onDuplicateClick} />
                    <StandardButton label="Add dashboard" iconClasses="fal fa-plus" colour="grey" onClick={onAddDashboardClick} />
                </div>
            </div>
            {showAddDashboardForm && (
                <Overlay title="New Dashboard" isFullScreen={true} onCloseClick={onCloseAddDashboardClick}>
                    {getOverlayBody(state.selectedDashboardType)}
                </Overlay>
            )}
        </>
    )
}

export default Header
