import FileImportConfig from "../../../types/FileImportConfig"
import ScheduledWorkflowRule from "../../../types/ScheduledWorkflowRule"
import TriggerFromJobConfig from "./TriggerFromJobConfig"
import GreyButton from "../../../../../library/buttons/GreyButton/GreyButton"
import { WorkflowTriggerConfig } from "../../../types/WorkflowTriggerConfigs"
import { v4 as uuidv4 } from "uuid"

type TriggerFromRuleSetupProps = {
    fileImportConfigs: FileImportConfig[]
    scheduledRules: ScheduledWorkflowRule[]
    selectedRule: ScheduledWorkflowRule
    workflowTriggerConfigs: WorkflowTriggerConfig[]
    onWorkflowTriggerConfigsChanged: (workflowTriggerConfigs: WorkflowTriggerConfig[]) => void
}

const TriggerFromRuleSetup = ({
    fileImportConfigs,
    scheduledRules,
    selectedRule,
    workflowTriggerConfigs,
    onWorkflowTriggerConfigsChanged
}: TriggerFromRuleSetupProps) => {
    const getDefaultTriggerConfig = () => ({
        reference: uuidv4(),
        triggeredByType: "WORKFLOW_RULE" as const,
        triggeredByReference: "",
        triggeredByName: "",
        ruleToTriggerReference: selectedRule.reference,
        ruleToTriggerName: selectedRule.name
    })

    const addTriggerConfig = () => onWorkflowTriggerConfigsChanged([...workflowTriggerConfigs, getDefaultTriggerConfig()])

    const onTriggerConfigUpdated = (triggerConfig: WorkflowTriggerConfig) =>
        onWorkflowTriggerConfigsChanged(workflowTriggerConfigs.map(config => (config.reference === triggerConfig.reference ? triggerConfig : config)))

    const onDeleteTriggerConfig = (triggerConfig: WorkflowTriggerConfig) =>
        onWorkflowTriggerConfigsChanged(workflowTriggerConfigs.filter(config => config.reference !== triggerConfig.reference))

    return (
        <div className="d-flex flex-column text-grey">
            <div className="d-flex flex-column gap-2">
                <span className="fs-5">Trigger on completion of all:</span>
                {workflowTriggerConfigs.map(triggerConfig => (
                    <TriggerFromJobConfig
                        key={triggerConfig.reference}
                        fileImportConfigs={fileImportConfigs}
                        scheduledRules={scheduledRules}
                        triggerConfig={triggerConfig}
                        onTriggerConfigUpdated={onTriggerConfigUpdated}
                        onDeleteTriggerConfig={onDeleteTriggerConfig}
                        workflowTriggerConfigs={workflowTriggerConfigs}
                    />
                ))}
                <div className="me-auto mt-2">
                    <GreyButton iconClasses="fal fa-plus" label="Add Trigger" onClick={addTriggerConfig} size="small" ariaLabel="add-job-trigger" />
                </div>
            </div>
        </div>
    )
}

export default TriggerFromRuleSetup
