import { v4 as uuidv4 } from "uuid"
import { FileImportConfigFieldDataTypeEnum } from "../types/FileImportConfigFieldDataType"
import { FileImportConfigColumnTypeEnum } from "../types/FileImportConfigColumnType"
import EntityConfig from "../../entityConfig/types/EntityConfig"
import EntityDataField from "../../entityConfig/types/EntityDataField"
import {
    CsvFileEntityExportConfig,
    EntityExportFieldConfig,
    FileEntityExportConfig,
    XmlAggregatedEntityExportNode,
    XmlAggregatedEntityExportParent,
    XmlEntityAggregationExportFieldConfig,
    XmlEntityExportFieldConfig,
    XmlEntityExportNode,
    XmlEntityExportNodeEnum,
    XmlEntityExportParent,
    XmlEntityTimestampExportFieldConfig,
    XmlEntityValueExportFieldConfig,
    XmlFileEntityExportConfig
} from "../types/fileEntityExport/FileExportConfig"
import { FileFormat } from "../components/utils"

export type FileExportConfigDashboardState = {
    configs: FileEntityExportConfigState[]
    entities: EntityConfig[]
    editingDataFields: EntityDataField[]
    editingConfig?: FileEntityExportConfigState
}

export type FileEntityExportConfigState = CsvFileEntityExportConfigState | XmlFileEntityExportConfigState

export type CsvFileEntityExportConfigState = CsvFileEntityExportConfig & {
    optionalFieldConfigs: EntityExportFieldConfigState[]
}

export type XmlFileEntityExportConfigState = XmlFileEntityExportConfig & {
    rootNodeFieldsConfig: XmlEntityExportParentState
    headersNodeFieldsConfig: XmlEntityAggregatedExportParentState[]
    footersNodeFieldsConfig: XmlEntityAggregatedExportParentState[]
}

export type EntityExportFieldConfigState = EntityExportFieldConfig & {
    key: string
}

type XmlEntityExportFieldConfigState = XmlEntityExportFieldConfig & {
    key: string
    parentKey: string
}
export type XmlEntityExportParentState = XmlEntityExportParent & {
    key: string
    parentKey: string
    children: XmlEntityExportNodeState[]
    selected: boolean
}
export type XmlEntityAggregatedExportParentState = XmlAggregatedEntityExportParent & {
    key: string
    parentKey: string
    children: XmlEntityAggregatedExportNodeState[]
    selected: boolean
    isRoot: boolean
}
type XmlEntityAggregationExportFieldConfigState = XmlEntityAggregationExportFieldConfig & {
    key: string
    parentKey: string
}

type XmlEntityValueExportFieldConfigState = XmlEntityValueExportFieldConfig & {
    key: string
    parentKey: string
}

type XmlEntityTimestampExportFieldConfigState = XmlEntityTimestampExportFieldConfig & {
    key: string
    parentKey: string
}

export type XmlEntityExportNodeState = XmlEntityExportParentState | XmlEntityExportFieldConfigState

export type XmlEntityAggregatedExportNodeState =
    | XmlEntityAggregatedExportParentState
    | XmlEntityValueExportFieldConfigState
    | XmlEntityAggregationExportFieldConfigState
    | XmlEntityTimestampExportFieldConfigState

export enum FileExportConfigActionEnum {
    FILE_EXPORT_CONFIGS_RETRIEVED,
    FILE_EXPORT_EDITING_CONFIG_UPDATED,
    FILE_EXPORT_EDITING_CONFIG_DISCARDED,
    FILE_EXPORT_EDITING_CONFIG_SAVED,
    FILE_EXPORT_CONFIG_ADDED,
    FILE_EXPORT_CONFIG_SELECTED,
    FILE_EXPORT_CONFIG_DELETED,
    DATA_FILE_CONFIG_UPDATED,
    DATA_FILE_CONFIG_FIELD_CONFIG_ADDED,
    DATA_FILE_CONFIG_FIELD_CONFIGS_UPLOADED,
    DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED,
    DATA_FILE_CONFIG_FIELD_CONFIG_REMOVED,
    ENTITIES_RETRIEVED,
    FILE_EXPORT_ENTITY_SELECTED,
    XML_NODE_CHANGED,
    XML_HEADER_NODE_ADDED,
    XML_FOOTER_NODE_ADDED,
    XML_HEADER_NODE_CHANGED,
    XML_FOOTER_NODE_CHANGED,
    XML_HEADER_NODE_DELETED,
    XML_FOOTER_NODE_DELETED,
    XML_NODE_DELETED,
    FILE_EXPORT_CONFIG_MOVED_UP,
    FILE_EXPORT_CONFIG_MOVED_DOWN
}

type FileExportConfigMovedUpAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_CONFIG_MOVED_UP
    configs: EntityExportFieldConfigState[]
    columnIndexToMove: number
}

type FileExportConfigMovedDownAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_CONFIG_MOVED_DOWN
    configs: EntityExportFieldConfigState[]
    columnIndexToMove: number
}

type RetrievedConfigsAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_CONFIGS_RETRIEVED
    configs: FileEntityExportConfig[]
}

type RetrievedDataFieldsAction = {
    type: FileExportConfigActionEnum.ENTITIES_RETRIEVED
    entities: EntityConfig[]
}

type EntitySelectedAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_ENTITY_SELECTED
    option: string
}

type EditingConfigSavedAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_SAVED
}

type EditingConfigDiscardedAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_DISCARDED
}

type EditingConfigAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_UPDATED
    editingConfig: FileEntityExportConfigState
}

type AddConfigAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_CONFIG_ADDED
}

type SelectConfigAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_CONFIG_SELECTED
    reference: string
}

type DeleteConfigAction = {
    type: FileExportConfigActionEnum.FILE_EXPORT_CONFIG_DELETED
    reference: string
}

type DataFileExportConfigAction = {
    type: FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED
    FileEntityExportConfig: FileEntityExportConfigState
}

type DataFileConfigFieldConfigsAction = {
    type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED
    fieldConfig: EntityExportFieldConfigState
}

type DataFileConfigFieldConfigAddedAction = {
    type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_ADDED
}

type DataFileConfigFieldConfigsUploadedAction = {
    type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIGS_UPLOADED
    fieldConfigs: EntityExportFieldConfigState[]
}

type DataFileConfigFieldConfigsRemovedAction = {
    type: FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_REMOVED
    fieldConfigKey: string
}

type XmlNodeChangedAction = {
    type: FileExportConfigActionEnum.XML_NODE_CHANGED
    nodeUpdated: XmlEntityExportNodeState
}

type XmlNodeDeletedAction = {
    type: FileExportConfigActionEnum.XML_NODE_DELETED
    parentId: string
    nodeId: string
}

type XmlHeaderNodeAddedAction = {
    type: FileExportConfigActionEnum.XML_HEADER_NODE_ADDED
    node: XmlEntityAggregatedExportParentState
}

type XmlFooterNodeAddedAction = {
    type: FileExportConfigActionEnum.XML_FOOTER_NODE_ADDED
    node: XmlEntityAggregatedExportParentState
}

type XmlHeaderNodeChangedAction = {
    type: FileExportConfigActionEnum.XML_HEADER_NODE_CHANGED
    key: string
    nodeUpdated: XmlEntityAggregatedExportNodeState
}

type XmlFooterNodeChangedAction = {
    type: FileExportConfigActionEnum.XML_FOOTER_NODE_CHANGED
    key: string
    nodeUpdated: XmlEntityAggregatedExportNodeState
}

type XmlNodeHeaderDeletedAction = {
    type: FileExportConfigActionEnum.XML_HEADER_NODE_DELETED
    key: string
    parentId: string
    nodeId: string
}

type XmlNodeFooterDeletedAction = {
    type: FileExportConfigActionEnum.XML_FOOTER_NODE_DELETED
    key: string
    parentId: string
    nodeId: string
}

export type FileExportConfigAction =
    | RetrievedConfigsAction
    | RetrievedDataFieldsAction
    | EditingConfigDiscardedAction
    | EditingConfigSavedAction
    | EditingConfigAction
    | DataFileExportConfigAction
    | AddConfigAction
    | SelectConfigAction
    | DeleteConfigAction
    | DataFileConfigFieldConfigsAction
    | DataFileConfigFieldConfigAddedAction
    | DataFileConfigFieldConfigsUploadedAction
    | DataFileConfigFieldConfigsRemovedAction
    | EntitySelectedAction
    | XmlNodeChangedAction
    | XmlNodeDeletedAction
    | FileExportConfigMovedUpAction
    | FileExportConfigMovedDownAction
    | XmlHeaderNodeAddedAction
    | XmlFooterNodeAddedAction
    | XmlHeaderNodeChangedAction
    | XmlFooterNodeChangedAction
    | XmlNodeHeaderDeletedAction
    | XmlNodeFooterDeletedAction

export default function FileExportConfigReducer(state: FileExportConfigDashboardState, action: FileExportConfigAction): FileExportConfigDashboardState {
    switch (action.type) {
        case FileExportConfigActionEnum.FILE_EXPORT_CONFIGS_RETRIEVED: {
            const getConfig = (config: FileEntityExportConfig): FileEntityExportConfigState => {
                switch (config.fileFormat) {
                    case FileFormat.TEXT:
                    case FileFormat.CSV:
                        return {
                            ...config,
                            optionalFieldConfigs: config.optionalFieldConfigs.map(fieldConfig => ({
                                ...fieldConfig,
                                key: uuidv4(),
                                ordinal: fieldConfig.ordinal ? fieldConfig.ordinal : 0
                            }))
                        }
                    case FileFormat.XML:
                        return {
                            ...config,
                            rootNodeFieldsConfig: mapXmlEntityExportNodesToState(config.rootNodeFieldsConfig, uuidv4()) as XmlEntityExportParentState,
                            headersNodeFieldsConfig: config.headersNodeFieldsConfig.map((config, index) =>
                                mapXmlEntityAggregatedExportNodesToState(config, uuidv4(), index === 0)
                            ) as XmlEntityAggregatedExportParentState[],
                            footersNodeFieldsConfig: config.footersNodeFieldsConfig.map((config, index) =>
                                mapXmlEntityAggregatedExportNodesToState(config, uuidv4(), index === 0)
                            ) as XmlEntityAggregatedExportParentState[]
                        }
                }
            }

            return {
                ...state,
                configs: action.configs.map(config => getConfig(config))
            }
        }
        case FileExportConfigActionEnum.FILE_EXPORT_CONFIG_MOVED_UP: {
            return {
                ...state,
                editingConfig:
                    state.editingConfig && state.editingConfig.fileFormat !== FileFormat.XML
                        ? {
                              ...state.editingConfig,
                              optionalFieldConfigs: state.editingConfig.optionalFieldConfigs.map((fieldConfig: EntityExportFieldConfigState) => {
                                  if (fieldConfig.ordinal === action.columnIndexToMove) {
                                      return {
                                          ...fieldConfig,
                                          ordinal: fieldConfig.ordinal - 1
                                      }
                                  } else if (fieldConfig.ordinal === action.columnIndexToMove - 1) {
                                      return {
                                          ...fieldConfig,
                                          ordinal: fieldConfig.ordinal + 1
                                      }
                                  } else {
                                      return fieldConfig
                                  }
                              })
                          }
                        : state.editingConfig
            }
        }
        case FileExportConfigActionEnum.FILE_EXPORT_CONFIG_MOVED_DOWN: {
            return {
                ...state,
                editingConfig:
                    state.editingConfig && state.editingConfig.fileFormat !== FileFormat.XML
                        ? {
                              ...state.editingConfig,
                              optionalFieldConfigs: state.editingConfig.optionalFieldConfigs.map((fieldConfig: EntityExportFieldConfigState) => {
                                  if (fieldConfig.ordinal === action.columnIndexToMove) {
                                      return {
                                          ...fieldConfig,
                                          ordinal: fieldConfig.ordinal + 1
                                      }
                                  } else if (fieldConfig.ordinal === action.columnIndexToMove + 1) {
                                      return {
                                          ...fieldConfig,
                                          ordinal: fieldConfig.ordinal - 1
                                      }
                                  } else {
                                      return fieldConfig
                                  }
                              })
                          }
                        : state.editingConfig
            }
        }

        case FileExportConfigActionEnum.ENTITIES_RETRIEVED: {
            return {
                ...state,
                entities: action.entities
            }
        }

        case FileExportConfigActionEnum.FILE_EXPORT_ENTITY_SELECTED: {
            const fields = state.entities.find(entity => entity.reference === action.option)?.fields

            return {
                ...state,
                editingConfig: state.editingConfig
                    ? {
                          ...state.editingConfig,
                          entityType: action.option
                      }
                    : state.editingConfig,
                editingDataFields: fields ? fields : []
            }
        }

        case FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_UPDATED: {
            return {
                ...state,
                editingConfig: {
                    ...action.editingConfig
                }
            }
        }

        case FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_DISCARDED: {
            const originalConfig = state.configs.find(c => c.reference === state.editingConfig?.reference)
            return {
                ...state,
                editingConfig: { ...originalConfig } as FileEntityExportConfigState
            }
        }

        case FileExportConfigActionEnum.FILE_EXPORT_EDITING_CONFIG_SAVED: {
            return {
                ...state,
                configs: state.configs.map(config => {
                    if (config.reference !== state.editingConfig?.reference) {
                        return config
                    }

                    return {
                        ...state.editingConfig
                    }
                })
            }
        }

        case FileExportConfigActionEnum.FILE_EXPORT_CONFIG_ADDED: {
            const newConfig = {
                reference: uuidv4(),
                entityType: "",
                fileFormat: FileFormat.CSV,
                filename: "",
                delimiter: "",
                lineEnding: "",
                hasHeaderRow: false,
                quoteFieldsWith: "",
                filters: [],
                optionalFieldConfigs: [],
                rootNodeFieldsConfig: {
                    type: XmlEntityExportNodeEnum.PARENT,
                    id: uuidv4(),
                    name: "Root Name",
                    children: []
                },
                headersNodeFieldsConfig: [],
                footersNodeFieldsConfig: [],
                subdirectoryPath: ""
            } as FileEntityExportConfigState

            return {
                ...state,
                configs: [newConfig, ...state.configs],
                editingConfig: newConfig
            }
        }

        case FileExportConfigActionEnum.FILE_EXPORT_CONFIG_SELECTED: {
            const selectConfig = state.configs.find(config => config.reference === action.reference)
            const fields = state.entities.find(entity => entity.reference === selectConfig?.entityType)?.fields

            return {
                ...state,
                editingConfig: selectConfig,
                editingDataFields: fields ? fields : []
            }
        }

        case FileExportConfigActionEnum.FILE_EXPORT_CONFIG_DELETED: {
            return {
                ...state,
                configs: state.configs.filter(config => config.reference !== action.reference),
                editingConfig: undefined,
                editingDataFields: []
            }
        }

        case FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED: {
            return {
                ...state,
                editingConfig: state.editingConfig
                    ? {
                          ...state.editingConfig,
                          ...action.FileEntityExportConfig
                      }
                    : state.editingConfig
            }
        }

        case FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_ADDED: {
            return {
                ...state,
                editingConfig:
                    state.editingConfig && state.editingConfig.fileFormat !== FileFormat.XML
                        ? {
                              ...state.editingConfig,
                              optionalFieldConfigs: [
                                  ...state.editingConfig.optionalFieldConfigs.map((fieldConfig: EntityExportFieldConfigState) => ({
                                      ...fieldConfig
                                  })),
                                  {
                                      key: uuidv4(),
                                      column: state.editingConfig.hasHeaderRow
                                          ? {
                                                type: FileImportConfigColumnTypeEnum.HEADER,
                                                header: ""
                                            }
                                          : {
                                                type: FileImportConfigColumnTypeEnum.INDEX,
                                                index: 1
                                            },
                                      dataFieldReference: "",
                                      dataType: { type: FileImportConfigFieldDataTypeEnum.TEXT },
                                      defaultValue: undefined,
                                      ordinal: state.editingConfig.optionalFieldConfigs.length
                                  }
                              ]
                          }
                        : state.editingConfig
            }
        }

        case FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIGS_UPLOADED: {
            return {
                ...state,
                editingConfig:
                    state.editingConfig && state.editingConfig.fileFormat !== FileFormat.XML
                        ? {
                              ...state.editingConfig,
                              optionalFieldConfigs: [...state.editingConfig.optionalFieldConfigs, ...action.fieldConfigs]
                          }
                        : state.editingConfig
            }
        }

        case FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_REMOVED: {
            return {
                ...state,
                editingConfig:
                    state.editingConfig && state.editingConfig.fileFormat !== FileFormat.XML
                        ? {
                              ...state.editingConfig,
                              optionalFieldConfigs: state.editingConfig.optionalFieldConfigs
                                  .filter((fc: EntityExportFieldConfigState) => fc.key !== action.fieldConfigKey)
                                  .map((fieldConfig: EntityExportFieldConfigState, index: number) => ({
                                      ...fieldConfig,
                                      ordinal: index
                                  }))
                          }
                        : state.editingConfig
            }
        }

        case FileExportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_UPDATED: {
            return {
                ...state,
                editingConfig:
                    state.editingConfig && state.editingConfig.fileFormat !== FileFormat.XML
                        ? {
                              ...state.editingConfig,
                              optionalFieldConfigs: state.editingConfig.optionalFieldConfigs.map((fieldConfig: EntityExportFieldConfigState) => {
                                  if (fieldConfig.key !== action.fieldConfig.key) return fieldConfig
                                  return {
                                      ...fieldConfig,
                                      ...action.fieldConfig
                                  }
                              })
                          }
                        : state.editingConfig
            }
        }
        case FileExportConfigActionEnum.XML_NODE_CHANGED: {
            if (!state.editingConfig || state.editingConfig.fileFormat !== FileFormat.XML) {
                return state
            }
            const inner = (node: XmlEntityExportNodeState): XmlEntityExportNodeState => {
                if (node.key === action.nodeUpdated.key) {
                    return action.nodeUpdated
                } else if (node.type === XmlEntityExportNodeEnum.PARENT && node.children.length > 0) {
                    return {
                        ...node,
                        children: [...node.children.map((child: XmlEntityExportNodeState) => inner(child))]
                    }
                } else {
                    return node
                }
            }
            const newRootNode = inner(state.editingConfig.rootNodeFieldsConfig)

            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    rootNodeFieldsConfig: newRootNode as XmlEntityExportParentState
                }
            }
        }
        case FileExportConfigActionEnum.XML_HEADER_NODE_ADDED: {
            if (!state.editingConfig || state.editingConfig.fileFormat !== FileFormat.XML) {
                return state
            }

            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    headersNodeFieldsConfig: [...state.editingConfig.headersNodeFieldsConfig, action.node]
                }
            }
        }
        case FileExportConfigActionEnum.XML_FOOTER_NODE_ADDED: {
            if (!state.editingConfig || state.editingConfig.fileFormat !== FileFormat.XML) {
                return state
            }

            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    footersNodeFieldsConfig: [...state.editingConfig.footersNodeFieldsConfig, action.node]
                }
            }
        }
        case FileExportConfigActionEnum.XML_HEADER_NODE_CHANGED: {
            if (!state.editingConfig || state.editingConfig.fileFormat !== FileFormat.XML) {
                return state
            }
            const index = state.editingConfig.headersNodeFieldsConfig.findIndex((config: XmlEntityAggregatedExportParentState) => config.key === action.key)
            const node = state.editingConfig.headersNodeFieldsConfig[index]
            if (node === undefined) {
                return state
            }

            const newRootNode = xmlNodeChanged(action)(node)
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    headersNodeFieldsConfig: [
                        ...state.editingConfig.headersNodeFieldsConfig.slice(0, index),
                        newRootNode,
                        ...state.editingConfig.headersNodeFieldsConfig.slice(index + 1)
                    ] as XmlEntityAggregatedExportParentState[]
                }
            }
        }
        case FileExportConfigActionEnum.XML_FOOTER_NODE_CHANGED: {
            if (!state.editingConfig || state.editingConfig.fileFormat !== FileFormat.XML) {
                return state
            }
            const index = state.editingConfig.footersNodeFieldsConfig.findIndex((config: XmlEntityAggregatedExportParentState) => config.key === action.key)
            const node = state.editingConfig.footersNodeFieldsConfig[index]
            if (node === undefined) {
                return state
            }

            const newRootNode = xmlNodeChanged(action)(node)
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    footersNodeFieldsConfig: [
                        ...state.editingConfig.footersNodeFieldsConfig.slice(0, index),
                        newRootNode,
                        ...state.editingConfig.footersNodeFieldsConfig.slice(index + 1)
                    ] as XmlEntityAggregatedExportParentState[]
                }
            }
        }
        case FileExportConfigActionEnum.XML_NODE_DELETED: {
            if (!state.editingConfig || state.editingConfig.fileFormat !== FileFormat.XML) {
                return state
            }
            const inner = (node: XmlEntityExportNodeState): XmlEntityExportNodeState => {
                if (node.type === XmlEntityExportNodeEnum.FIELD_CONFIG) {
                    return node
                }
                if (node.key === action.parentId) {
                    return {
                        ...node,
                        children: [...node.children.filter((child: XmlEntityExportNodeState) => child.key !== action.nodeId)]
                    }
                } else {
                    return {
                        ...node,
                        children: [...node.children.map((child: XmlEntityExportNodeState) => inner(child))]
                    }
                }
            }
            const newRootNode = inner(state.editingConfig.rootNodeFieldsConfig)

            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    rootNodeFieldsConfig: newRootNode as XmlEntityExportParentState
                }
            }
        }
        case FileExportConfigActionEnum.XML_HEADER_NODE_DELETED: {
            if (!state.editingConfig || state.editingConfig.fileFormat !== FileFormat.XML) {
                return state
            }
            const index = state.editingConfig.headersNodeFieldsConfig.findIndex((config: XmlEntityAggregatedExportParentState) => config.key === action.key)
            const node = state.editingConfig.headersNodeFieldsConfig[index]
            if (node === undefined) {
                return state
            }
            if (node.key === action.nodeId) {
                return {
                    ...state,
                    editingConfig: {
                        ...state.editingConfig,
                        headersNodeFieldsConfig: state.editingConfig.headersNodeFieldsConfig.filter(
                            (config: XmlEntityAggregatedExportParentState) => config.key !== node.key
                        )
                    }
                }
            }

            const newRootNode = xmlNodeDeleted(action)(node)

            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    headersNodeFieldsConfig: [
                        ...state.editingConfig.headersNodeFieldsConfig.slice(0, index),
                        newRootNode,
                        ...state.editingConfig.headersNodeFieldsConfig.slice(index + 1)
                    ] as XmlEntityAggregatedExportParentState[]
                }
            }
        }
        case FileExportConfigActionEnum.XML_FOOTER_NODE_DELETED: {
            if (!state.editingConfig || state.editingConfig.fileFormat !== FileFormat.XML) {
                return state
            }
            const index = state.editingConfig.footersNodeFieldsConfig.findIndex((config: XmlEntityAggregatedExportParentState) => config.key === action.key)
            const node = state.editingConfig.footersNodeFieldsConfig[index]
            if (node === undefined) {
                return state
            }
            if (node.key === action.nodeId) {
                return {
                    ...state,
                    editingConfig: {
                        ...state.editingConfig,
                        footersNodeFieldsConfig: state.editingConfig.footersNodeFieldsConfig.filter(
                            (config: XmlEntityAggregatedExportParentState) => config.key !== node.key
                        )
                    }
                }
            }

            const newRootNode = xmlNodeDeleted(action)(node)

            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    footersNodeFieldsConfig: [
                        ...state.editingConfig.footersNodeFieldsConfig.slice(0, index),
                        newRootNode,
                        ...state.editingConfig.footersNodeFieldsConfig.slice(index + 1)
                    ] as XmlEntityAggregatedExportParentState[]
                }
            }
        }
    }
}

const mapXmlEntityExportNodesToState = (node: XmlEntityExportNode, nodeKey: string): XmlEntityExportNodeState => {
    const key = uuidv4()
    switch (node.type) {
        case XmlEntityExportNodeEnum.PARENT:
            return {
                ...node,
                children: node.children.map(child => mapXmlEntityExportNodesToState(child, key)),
                key: key,
                parentKey: nodeKey,
                selected: false
            } as XmlEntityExportParentState
        case XmlEntityExportNodeEnum.FIELD_CONFIG:
            return {
                ...node,
                key: key,
                parentKey: nodeKey
            } as XmlEntityExportFieldConfigState
    }
}

const mapXmlEntityAggregatedExportNodesToState = (
    node: XmlAggregatedEntityExportNode,
    nodeKey: string,
    isRoot: boolean
): XmlEntityAggregatedExportNodeState => {
    const key = uuidv4()
    switch (node.type) {
        case "parent":
            return {
                ...node,
                children: node.children.map(child => mapXmlEntityAggregatedExportNodesToState(child, key, false)),
                key: key,
                parentKey: nodeKey,
                selected: false,
                isRoot: isRoot
            }
        case "aggregation":
        case "timestamp":
        case "value":
            return {
                ...node,
                key: key,
                parentKey: nodeKey
            }
    }
}
const xmlNodeDeleted = (action: { parentId: string; nodeId: string }) => {
    const xmlNodeDeletedInner = (node: XmlEntityAggregatedExportNodeState): XmlEntityAggregatedExportNodeState => {
        if (node.type === "aggregation" || node.type === "timestamp" || node.type === "value") {
            return node
        }
        if (node.key === action.parentId) {
            return {
                ...node,
                children: [...node.children.filter((child: XmlEntityAggregatedExportNodeState) => child.key !== action.nodeId)]
            }
        } else {
            return {
                ...node,
                children: [...node.children.map((child: XmlEntityAggregatedExportNodeState) => xmlNodeDeletedInner(child))]
            }
        }
    }
    return xmlNodeDeletedInner
}

const xmlNodeChanged = (action: { nodeUpdated: XmlEntityAggregatedExportNodeState }) => {
    const xmlNodeChangedInner = (node: XmlEntityAggregatedExportNodeState): XmlEntityAggregatedExportNodeState => {
        if (node.key === action.nodeUpdated.key) {
            return action.nodeUpdated
        } else if (node.type === "parent" && node.children.length > 0) {
            return {
                ...node,
                children: [...node.children.map((child: XmlEntityAggregatedExportNodeState) => xmlNodeChangedInner(child))]
            }
        } else {
            return node
        }
    }
    return xmlNodeChangedInner
}
