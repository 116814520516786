import React from 'react';
import { connect } from 'react-redux';
import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../common/grids/StatefulGrid';
import { getApiUrl } from '../../constants';
import { useClient } from 'invevo-react-components';
import { normaliseFormatDef } from '../../selectors/lookup';

const TransactionNotesGrid = ({ transactionId, dateFormat }) => {
  const client = useClient()
  const key = 'transaction-notes-grid';

  return (
    <StatefulGrid
      client={client}
      id={key}
      key={key}
      path={getApiUrl(client) + 'api/panelcontent/transactionNotes'}
      defaultField={{ field: 'NoteDateTime', dir: 'desc' }}
      pageSize={10}
      filterable
      initialFilter={{
        filters: [
          {
            field: 'TransactionId',
            operator: 'eq',
            value: transactionId
          }
        ]
      }}
      pageable={{
        pageSizes: [5, 10, 20, 100],
        refresh: true,
        buttonCount: 5
      }}
      sortable={true}
      hideablePaging={true}
      className={'hide-header scrollable-none custom-grid'}
    >
      <GridColumn
        field={'NoteDateTime'}
        title={'Date'}
        format={dateFormat}
        width={'120px'}
      />
      <GridColumn field={'NoteType'} width={'100px'} />
      <GridColumn field={'ID'} width={'80px'} />
      <GridColumn field={'UserName'} width={'120px'} />
      <GridColumn field={'Completion'} width={'180px'} />
      <GridColumn field={'NoteText'} />
    </StatefulGrid>
  );
};

const mapStateToProps = (state) => ({
  dateFormat: normaliseFormatDef(
    state.lookupReducer.globalSettings.results.dateFormat
  )
});

export default connect(mapStateToProps)(TransactionNotesGrid);
