import { actionTypes } from "./actionTypes";

export default function customerDashboardsReducer(state, action) {
    switch (action.type) {
        case actionTypes.CUSTOMER_DASHBOARDS_RETRIEVED: {
            return {
                ...state,
                dashboards: action.dashboards
            }
        }

        case actionTypes.CUSTOMER_DASHBOARD_SELECTED: {
            return {
                ...state,
                selectedDashboard: action.dashboard
            }
        }

        case actionTypes.SELECTED_CUSTOMER_DASHBOARD_CLEARED: {
            return {
                ...state,
                selectedDashboard: null
            }
        }

        case actionTypes.CUSTOMER_DASHBOARD_OPTIONS_RETRIEVED: {
            return {
                ...state,
                dashboardsOptions: action.options
            }
        }

        case actionTypes.TRANSACTION_AGGREGATIONS_RETRIEVED: {
            return {
                ...state,
                transactionAggregations: action.transactionAggregations
            }
        }

        case actionTypes.CUSTOMER_FIELD_VALUE_UPDATED: {
            return {
                ...state,
                dashboards: [...state.dashboards.map(dashboard => {
                    return {
                        ...dashboard,
                        infoWidgets: [...dashboard.infoWidgets.map(infoWidget => {
                            return {
                                ...infoWidget,
                                fields: [...infoWidget.fields.map(field => {
                                    if (field.type !== action.field.type || field.fieldName !== action.field.fieldName) {
                                        return field
                                    }

                                    return {
                                        ...field,
                                        value: action.newValue
                                    }
                                })]
                            }
                        })]
                    }
                })],
                selectedDashboard: {
                    ...state.selectedDashboard,
                    infoWidgets: [...state.selectedDashboard.infoWidgets.map(infoWidget => {
                        return {
                            ...infoWidget,
                            fields: [...infoWidget.fields.map(field => {
                                if (field.type !== action.field.type || field.fieldName !== action.field.fieldName) {
                                    return field
                                }

                                return {
                                    ...field,
                                    value: action.newValue
                                }
                            })]
                        }
                    })]
                }
            }
        }

        case actionTypes.CLIENT_USER_DASHBOARDS_CONFIG_RETRIEVED: {
            return {
                ...state,
                clientUserDashboardsConfig: action.clientUserDashboardsConfig
            }
        }

        case actionTypes.RESET_DISPLAYED_COLUMN_IN_TRANSACTION_GRID: {
            return {
                ...state,
                clientUserDashboardsConfig: {
                    ...state.clientUserDashboardsConfig,
                    transactionsGridConfigs: [...state.clientUserDashboardsConfig.transactionsGridConfigs.filter((config) => config.transactionsGridRef !== action.transactionsGridRef)]
                }
            }
        }

        case actionTypes.TRANSACTION_DATA_RETRIEVED: {
            return {
                ...state,
                selectedDashboard: {
                    ...state.selectedDashboard,
                    transactionsWidgets: [...state.selectedDashboard.transactionsWidgets.map(transactionsWidget => {
                        if (transactionsWidget.ordinal !== action.widgetOrdinal) {
                            return transactionsWidget
                        }
                        return {
                            ...transactionsWidget,
                            data: [...action.data.rows],
                            totalCount: transactionsWidget.pageIndex === 0 ? action.data.totalCount : transactionsWidget.totalCount
                        }
                    })]
                }
            }
        }

        case actionTypes.TRANSACTION_DATA_UPDATED: {
            return {
                ...state,
                selectedDashboard: {
                    ...state.selectedDashboard,
                    transactionsWidgets: [...state.selectedDashboard.transactionsWidgets.map(transactionsWidget => {
                        if (transactionsWidget.ordinal !== action.widgetOrdinal) {
                            return transactionsWidget
                        }
                        return {
                            ...transactionsWidget,
                            data: [...transactionsWidget.data.map(t => {
                                const updatedTransaction = action.data.find(ut => ut.transactionRef === t.transactionRef)
                                if (!updatedTransaction) {
                                    return t
                                }

                                return {
                                    ...t,
                                    data: {
                                        ...t.data,
                                        ...updatedTransaction
                                    }
                                }
                            }
                            )],
                        }
                    })]
                }
            }
        }

        case actionTypes.TRANSACTIONS_DATA_SORTED: {
            return {
                ...state,
                selectedDashboard: {
                    ...state.selectedDashboard,
                    transactionsWidgets: [...state.selectedDashboard.transactionsWidgets.map(transactionsWidget => {
                        if (transactionsWidget.ordinal !== action.widgetOrdinal) {
                            return transactionsWidget
                        }
                        return {
                            ...transactionsWidget,
                            sortByField: action.sortByField,
                            sortDirection: action.sortDirection,
                            pageIndex: 0
                        }
                    })]
                }
            }
        }

        case actionTypes.TRANSACTIONS_DATA_PAGE_CHANGED: {
            return {
                ...state,
                selectedDashboard: {
                    ...state.selectedDashboard,
                    transactionsWidgets: [...state.selectedDashboard.transactionsWidgets.map(transactionsWidget => {
                        if (transactionsWidget.ordinal !== action.widgetOrdinal) {
                            return transactionsWidget
                        }
                        return {
                            ...transactionsWidget,
                            pageIndex: action.pageIndex
                        }
                    })]
                }
            }
        }

        case actionTypes.TRANSACTION_FIELDS_RETRIEVED: {
            return {
                ...state,
                transactionFields: action.fields
            }
        }

        case actionTypes.CUSTOMER_FIELDS_RETRIEVED: {
            return {
                ...state,
                customerFields: action.fields
            }
        }

        case actionTypes.TOGGLE_DISPLAYED_COLUMN_IN_TRANSACTION_GRID: {
            const updatedDisplayedColumns = action.columnConfig.displayedColumns.includes(action.column) ?
                [
                    ...action.columnConfig.displayedColumns.filter((c) => c !== action.column)
                ] :
                [
                    ...action.columnConfig.displayedColumns,
                    action.column
                ]

            return {
                ...state,
                clientUserDashboardsConfig: {
                    ...state.clientUserDashboardsConfig,
                    transactionsGridConfigs: [...state.clientUserDashboardsConfig.transactionsGridConfigs.filter((config) => config.transactionsGridRef !== action.columnConfig.transactionsGridRef), {
                        ...action.columnConfig,
                        displayedColumns: updatedDisplayedColumns
                    }
                    ]
                }
            }
        }

        case actionTypes.LOOKUPS_RETRIEVED: {
            return { ...state, lookups: action.data }
        }

        case actionTypes.ENTITY_CONFIGS_RETRIEVED: {
            return {
                ...state,
                entityConfigs: action.entityConfigs
            }
        }

        case actionTypes.ENTITY_RELATIONSHIPS_RETRIEVED: {
            return {
                ...state,
                entityRelationships: [...action.entityRelationships]
            }
        }

        default:
            throw new Error(`Invalid reducer action: ${action.type}`)
    }
}