import { useState } from "react"
import { IconButton, Loading, GreyButton, useClient, useConfig, useUser } from "invevo-react-components"
import classes from "./ColumnConfigButton.module.scss"
import { padElementsWith } from "../helpers"
import DataField from "../../types/DataField"
import useApiQuery from "../../hooks/useApiQuery"
import GridColumnConfig from "./types/GridColumnConfigDto"
import ColumnConfigFieldOption from "./ColumnConfigFieldOption"
import GridColumnConfigDto from "./types/GridColumnConfigDto"

type ColumnConfigButtonProps = {
    gridReference: string
    fields: DataField[]
    displayedFields: DataField[]
    onDisplayedFieldsChanged: (displayedFields: DataField[]) => void
    defaultFields: GridColumnConfigDto[]
}

const ColumnConfigButton = ({ gridReference, fields, displayedFields, onDisplayedFieldsChanged, defaultFields }: ColumnConfigButtonProps) => {
    const [showColumnConfig, setShowColumnConfig] = useState(false)

    const currentUser = useUser()
    const client = useClient()
    const config = useConfig()

    const onColumnConfigClicked = () => setShowColumnConfig(!showColumnConfig)

    const getTogglesState = (field: DataField) => displayedFields.map(f => f.value).includes(field.value)

    const onToggleState = (field: DataField) => {
        const newDisplayedFields = getTogglesState(field) ? displayedFields.filter(dataField => dataField.value !== field.value) : [...displayedFields, field]
        newDisplayedFields.forEach((field, index) => (field.ordinal = index))
        onDisplayedFieldsChanged(newDisplayedFields)
    }

    const saveConfigRequest = useApiQuery({
        url: `${config.DATA_API_URL}/api/${client}/user-grid-config/${currentUser.username}`,
        method: "PUT",
        onSuccess: () => setShowColumnConfig(false),
        isExecutedAutomatically: false
    })

    const saveConfig = (columnConfigs: GridColumnConfig[]) =>
        saveConfigRequest.execute(undefined, {
            gridReference: gridReference,
            displayedColumns: columnConfigs
        })

    const onReset = () => {
        saveConfig(defaultFields)
        onDisplayedFieldsChanged(defaultFields.map(field => fields.find(dataField => dataField.value === field.fieldName) as DataField))
    }

    const onSave = () =>
        saveConfig(
            displayedFields.map((field, index) => {
                return { fieldName: field.value, ordinal: index }
            })
        )

    const options = fields
        .sort((a, b) => (a.label > b.label ? 1 : -1))
        .map(dataField => (
            <ColumnConfigFieldOption key={dataField.value} dataField={dataField} isEnabled={getTogglesState(dataField)} onToggleState={onToggleState} />
        ))

    return (
        <div className={`d-flex position-relative flex-column`}>
            <IconButton
                ariaLabel="column-config"
                buttonClasses={classes.actionButton}
                iconClasses="fa-light fa-line-columns"
                colour="blue"
                className={`${showColumnConfig ? classes.button : ""}`}
                onClick={onColumnConfigClicked}
            />
            {showColumnConfig && (
                <div className="d-flex" style={{ zIndex: 1 }} role="dialog" aria-label="column-config">
                    <div className={`position-absolute bg-blue ${classes.columns}`}>
                        <div className={`${classes.header}`}></div>
                        <div className={`w-100 d-flex flex-column bg-grey ${classes.body}`}>{padElementsWith(options, "w-100 border-top")}</div>
                        <div className={`d-flex align-items-center p-3 fw-light ${classes.footer}`}>
                            <Loading isLoading={saveConfigRequest.isFetching} colour="white">
                                <GreyButton label="Save" iconClasses="fa-light fa-save" onClick={onSave} ariaLabel="save-config" />
                                <GreyButton className="ms-3" label="Reset" iconClasses="fa-light fa-redo" onClick={onReset} ariaLabel="reset-config" />
                            </Loading>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ColumnConfigButton
