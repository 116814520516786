import { useEffect, useState } from "react"
import axios from 'axios'

const useConfigLegacy = () => {
    const [state, setState] = useState({})

    useEffect(() => {
        axios.get(`${window.location.origin}/config.json`).then(res => res.data).then(setState)
    }, [])

    return state
}

export { useConfigLegacy }