import { useEffect, useState } from "react"
import classes from "./SmallPieChart.module.scss"
import "./C3Overrides.scss"
import c3 from "c3"
import { generateHtmlId } from "../helpers"
import PieChartDetails from "./PieChartDetails"

const valueColours = ["#5D9CD6", "#ADCDEB", "#255E93", "#DEEBF7", "#183E62", "#081521"]

const convertMoneyToNumber = value => Number(value.replace(/[^\d.-]/g, ''))

const SmallPieChart = ({ className = '', title, data, colour = "white", type = "pie" }) => {
    const chartId = generateHtmlId()

    const graphData = data.map(item => [item.label, convertMoneyToNumber(item.value)])
    const c3Colours = Object.assign({}, ...data.map((item, index) => ({ [item.label]: valueColours[index] })))
    const [chart, setChart] = useState(null)

    const onMouseOver = (id) => () => chart?.focus(id)

    const onMouseOut = () => chart?.revert()

    useEffect(() => {
        setChart(c3.generate({
            bindto: `#${chartId}`,
            data: {
                columns: graphData,
                colors: c3Colours,
                type
            },
            legend: {
                show: false
            },
            pie: {
                label: {
                    show: false
                }
            },
            donut: {
                label: {
                    show: false
                },
                width: 30
            }
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <div className={`${className} d-flex flex-column w-100 p-3 rounded ${classes[`widget-${colour}`]}`}>
            <div className={`fs-5 text-uppercase d-block mb-2 ${colour === 'blue' ? "text-white" : "text-grey"}`}>{title}</div>

            <div className="d-flex mw-50 mh-50 flex-shrink-1 h-100 w-100 pb-5 justify-content-between">
                <div className={`d-flex text-grey p-1 ${classes.square}`}>
                    <div id={chartId} className={`flex-shrink-1 ${classes.chart} mw-50 mh-50`} />
                </div>
                <div className="d-flex flex-column justify-content-center mb-2">
                    {data.map((item, index) => (
                        <PieChartDetails
                            key={index}
                            detail={item}
                            textColour={colour}
                            dotColour={valueColours[index]}
                            onMouseOver={onMouseOver}
                            onMouseOut={onMouseOut}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default SmallPieChart