import React, { useEffect } from "react"
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { PRE_AUTH_ROUTE_KEY } from './constants';
import { useLocation } from 'react-router-dom';
import { AnonymousRoutes } from "../../routes/anonymousRoutes";
import { Loading } from 'invevo-react-components';

const PerformLogin = ({ msalInstance, children }) => {
    const [, setPreAuthRoute] = useSessionStorage(PRE_AUTH_ROUTE_KEY)
    const location = useLocation()
    
    const isAnonymousRoute = () => location.pathname === AnonymousRoutes.SIGN_UP

    useEffect(() => {
        if(isAnonymousRoute()) return
        
        const newPreAuthPath = `${location.pathname}${location.search}`
        setPreAuthRoute(newPreAuthPath)

        msalInstance.loginRedirect({ scopes: [] })
            .catch((error) => {
                console.log("SSO error")
                console.log(error)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [msalInstance])

    return isAnonymousRoute() ? children : 
    <Loading colour="blue" isLoading={true}>
        <React.Fragment />
    </Loading>
}

export default PerformLogin