import { useEffect, useMemo } from "react"
import { generateHtmlId } from "../../../../library/helpers"
import c3 from "c3"
import { DirectIdMonthAffordability } from "../../types/dtos/DirectIdAffordabilityDto"
import moment from "moment"
import { convertToCurrency } from "../../../../library/helpers/numberUtils"

type AffordabilityProps = {
    className: string
    monthAffordabilities: DirectIdMonthAffordability[]
    currencyCode: string
}

const Affordability = ({ className, monthAffordabilities, currencyCode }: AffordabilityProps) => {
    const chartId = generateHtmlId()

    const formatXTick = (x: number | Date) => {
        return moment(x).format("MMM-yy")
    }

    const formatYTick = (y: number) => {
        return convertToCurrency(round(y), currencyCode)
    }

    const chartData = useMemo(() => {
        const sorted = [...monthAffordabilities].sort((a, b) => moment(a.monthDateTime).diff(moment(b.monthDateTime)))
        return {
            months: sorted.map(a => moment(a.monthDateTime).toDate()),
            incomes: sorted.map(a => a.calculatedIncome),
            fixedCosts: sorted.map(a => a.fixedCosts),
            flexibleCosts: sorted.map(a => a.flexibleCosts),
            debtFees: sorted.map(a => a.debtFees),
            affordability: sorted.map(a => a.affordability)
        }
    }, [monthAffordabilities])

    useEffect(() => {
        c3.generate({
            bindto: `#${chartId}`,
            data: {
                x: "x",
                columns: [
                    ["x", ...chartData.months],
                    ["Affordability", ...chartData.affordability],
                    ["Incomes", ...chartData.incomes],
                    ["Fixed Costs", ...chartData.fixedCosts],
                    ["Flexible Costs", ...chartData.flexibleCosts],
                    ["Debt Fees", ...chartData.debtFees]
                ],
                type: "spline"
            },
            axis: {
                x: {
                    type: "timeseries",
                    tick: {
                        format: formatXTick,
                        count: 12
                    }
                },
                y: {
                    min: roundDown(
                        Math.min(
                            ...[...chartData.incomes, ...chartData.fixedCosts, ...chartData.flexibleCosts, ...chartData.debtFees, ...chartData.affordability]
                        )
                    ),
                    max: roundUp(
                        Math.max(
                            ...[...chartData.incomes, ...chartData.fixedCosts, ...chartData.flexibleCosts, ...chartData.debtFees, ...chartData.affordability]
                        )
                    ),
                    tick: {
                        count: 5,
                        format: formatYTick
                    }
                }
            },
            padding: {
                top: 20
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={`d-flex flex-column border-1 border rounded ${className} p-3`}>
            <span className="mx-auto">Trending Affordability</span>

            <div id={chartId} className="mt-3"></div>
        </div>
    )
}

const round = (value: number) => value.toFixed(2)
const roundUp = (value: number) => Math.ceil(value / 1000) * 1000
const roundDown = (value: number) => Math.floor(value / 1000) * 1000

export default Affordability
