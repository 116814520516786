import { convertToCurrency } from "../../../../library/helpers/numberUtils"
import { DirectIdAffordabilityDto } from "../../types/dtos/DirectIdAffordabilityDto"

type EstimatedAffordabilityProps = {
    affordability: DirectIdAffordabilityDto
    currencyCode: string
}

const EstimatedAffordability = ({ affordability, currencyCode }: EstimatedAffordabilityProps) => {
    return (
        <div className="d-flex flex-column bg-white rounded p-3">
            <span className="fs-6">Estimated Affordability</span>
            <span className="fw-bold fs-1 text-success">{convertToCurrency(affordability.estimatedAffordability, currencyCode)}</span>
        </div>
    )
}

export default EstimatedAffordability
