import { Input, Loading, useApi, useClient } from 'invevo-react-components';
import { useEffect, useState } from 'react';
import { useConfigLegacy } from '../../../../../hooks/useConfigLegacy';
import ProgressButton from '../../../../../library/buttons/ProgressButton/ProgressButton';

const MIN_ACCOUNT_SID_LENGTH = 1
const MAX_ACCOUNT_SID_LENGTH = 128

const VoipConfig = () => {
    const api = useApi()
    const config = useConfigLegacy()
    const client = useClient()

    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)

    const [accountSid, setAccountSid] = useState("")
    const [authToken, setAuthToken] = useState("")
    const [isSaveDisabled, setIsSaveDisabled] = useState(true)

    const saveVoipConfig = () =>
        api.put(`${config.CONNECT_API_URL}/api/${client}/voip-config`, { twilioAccountSid: accountSid, twilioAuthToken: authToken })

    const onAccountSidChanged = event => setAccountSid(event.target.value)
    const onAuthTokenChanged = event => setAuthToken(event.target.value)

    useEffect(() => {
        if (isFetching || hasFetched || !config.CONNECT_API_URL || (!isFetching && !hasFetched && (accountSid || authToken))) {
            return
        }

        setIsFetching(true)
        api.get(`${config.CONNECT_API_URL}/api/${client}/voip-config`)
            .then(response => {
                setHasFetched(true)
                setIsFetching(false)
                setAccountSid(response.data.twilioAccountSid)
                setAuthToken(response.data.twilioAuthToken)
            })
            .catch(error => {
                if (error.response.status === 404) {
                    setHasFetched(true)
                    setIsFetching(false)
                    setAccountSid("")
                    setAuthToken("")
                } else {
                    console.error(error)
                }
            }
            )
    }, [api, client, config.CONNECT_API_URL, hasFetched, isFetching, accountSid, authToken])

    useEffect(() => {
        setIsSaveDisabled(!accountSid || accountSid.length < MIN_ACCOUNT_SID_LENGTH || accountSid.length > MAX_ACCOUNT_SID_LENGTH ||
            !authToken || authToken.length < MIN_ACCOUNT_SID_LENGTH || authToken.length > MAX_ACCOUNT_SID_LENGTH)
    }, [accountSid, authToken])

    return (
        <div className="d-flex flex-column w-50 h-100 px-4 pt-4">
            <Loading isLoading={isFetching} colour="blue">
                <h1 className='pb-5'>Voip Configurations</h1>
                <Input
                    className="w-100 pb-4"
                    label="Account SID"
                    labelColour="grey"
                    placeholder="Please enter the Account SID"
                    value={accountSid}
                    onChange={onAccountSidChanged}
                    ariaLabel="voip-config-account-sid"
                />
                <Input
                    className="w-100 pb-4"
                    label="Auth token"
                    labelColour="grey"
                    placeholder="Please enter the Auth token"
                    value={authToken}
                    onChange={onAuthTokenChanged}
                    ariaLabel="voip-config-auth-token"
                />
                <ProgressButton
                    className="pt-3 ms-auto"
                    iconClasses="fal fa-save"
                    label="Save VOIP configuration"
                    onClickWithPromise={saveVoipConfig}
                    succeededText={`Saved config successfully!`}
                    failedText={`Failed to save config!`}
                    disabled={isSaveDisabled}
                />
            </Loading>
        </div>
    )
}

export default VoipConfig