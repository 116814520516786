import React, { useEffect, useState, useContext, useRef } from "react"
import { connect } from "react-redux"
import { Collapse } from "react-bootstrap"
import HierarchyGrid from "./hierarchyGrid"
import SearchVirtualParent from "./searchVirtualParent"
import SearchChild from "./searchChild"
import CloneAsVP from "./cloneAsVP"
import EditRelationship from "./editRelationship"
import usePrevious from "../../hooks/usePrevious"
import { hasSelectedAccount } from "../../selectors/currentSelection"
import { workspaceLayoutContext } from "../../contexts/workspaceLayoutContext"
import { refreshVpHierarchy } from "../../actions/vpActions"
import { hasVPEditPermission } from "../../selectors/virtualParent"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"

const defaultShow = {
    main: false,
    childSearch: false,
    parentSearch: false,
    addParent: false,
    edit: false
}

const VirtualParent = ({ hasAccount, selectedAccount, refreshVpHierarchy, hasVpEditPermission }) => {
    const [show, setShow] = useState(defaultShow)
    const [editDataItem, setEditDataItem] = useState()
    const { layoutState, setLayoutState } = useContext(workspaceLayoutContext)
    const hierarchGridRef = useRef()
    const { isEnabled: isVpNavigationEnabled } = useFeatureToggle("allowLegacyVirtualParentNavigation")

    const { invevoExportVpGridFeature, invevoVpGridBrand, invevoVpGridTed } = useFlags()

    const minWidth = 1000 + (invevoVpGridBrand && 100) + (invevoVpGridTed && 100)

    const title = (
        <>
            {selectedAccount.miaAccountName} <span className="font-weight-bold">#</span> {selectedAccount.miaAccountVirtualLevel}{" "}
            {selectedAccount.miaAccountCode}
        </>
    )

    const prevMiaAccountId = usePrevious(selectedAccount.miaAccountId)
    useEffect(() => {
        if (prevMiaAccountId !== selectedAccount.miaAccountId) {
            setShow({
                ...defaultShow,
                main: show.main
            })
        }
    }, [prevMiaAccountId, selectedAccount.miaAccountId, show.main])

    const onClose = () => {
        setShow(defaultShow)
    }

    const onExport = () => hierarchGridRef.current.exportExcel()

    const toggleMainView = () => {
        if (!isVpNavigationEnabled) return

        const newShow = !show.main
        setShow({
            ...defaultShow,
            main: newShow
        })
        if (newShow) {
            refreshVpHierarchy()

            if (layoutState.collapsed === "" && layoutState.rightPaneWidth < minWidth) {
                setLayoutState({ ...layoutState, collapsed: "left" })
            }
        }
    }

    const toggleSearchParent = () => {
        setShow({
            ...defaultShow,
            main: true,
            parentSearch: !show.parentSearch
        })
    }

    const toggleSearchChild = () => {
        setShow({
            ...defaultShow,
            main: true,
            childSearch: !show.childSearch
        })
    }

    const toggleAddParent = () => {
        setShow({
            ...defaultShow,
            main: true,
            addParent: !show.addParent
        })
    }

    const toggleEdit = () => {
        setShow({
            ...defaultShow,
            main: true,
            edit: !show.edit
        })
    }

    const onEdit = recordToEdit => {
        toggleEdit()
        setEditDataItem(recordToEdit)
    }

    const onCompleteEdit = () => {
        refreshVpHierarchy()
        setEditDataItem(null)
        toggleEdit()
    }

    const onAddParentComplete = () => {
        refreshVpHierarchy()
        toggleAddParent()
    }

    const onChangeVirtualParent = () => {
        refreshVpHierarchy()
        toggleSearchParent()
    }

    const onChangeChild = () => {
        refreshVpHierarchy()
        toggleSearchChild()
    }

    return hasAccount ? (
        <div id="vp-button" aria-label="vp-button" className={show.main ? "show mt-3" : "text-truncate mt-2"}>
            <h1 className="ms-0 py-1 my-0" onClick={toggleMainView}>
                <i className="fa-solid fa-building"></i> {title}{" "}
                <span className={"hierarchy"}>
                    {show.main ? " Family Structure " : ""}
                    {isVpNavigationEnabled && <i className="fa-solid fa-sitemap"></i>}
                </span>
            </h1>

            <Collapse in={show.main}>
                <div>
                    <HierarchyGrid onEdit={onEdit} passedInRef={hierarchGridRef} />
                    <div>
                        <Collapse in={show.childSearch}>
                            <div className="border-top mt-1 pt-1">
                                <SearchChild onChange={onChangeChild} />
                            </div>
                        </Collapse>
                        <Collapse in={show.parentSearch}>
                            <div className="border-top mt-1 pt-1">
                                <SearchVirtualParent onChange={onChangeVirtualParent} />
                            </div>
                        </Collapse>
                        <Collapse in={show.addParent}>
                            <div className="border-top mt-1 pt-1">
                                <CloneAsVP onComplete={onAddParentComplete} />
                            </div>
                        </Collapse>
                        <Collapse in={show.edit}>
                            <div className="border-top mt-1 pt-1">
                                {editDataItem && <EditRelationship dataItem={editDataItem} onCompleteEdit={onCompleteEdit} />}
                            </div>
                        </Collapse>
                    </div>

                    <div className="d-flex mx-1 my-2 border-top pt-2">
                        {selectedAccount.miaAccountIsVirtualAccount ? (
                            <div className="flex-shirk-1 flex-grow-1">
                                <button className="btn btn-default" onClick={toggleSearchChild}>
                                    <i className="fa-solid fa-search me-2"></i>
                                    Search for child
                                </button>
                            </div>
                        ) : (
                            <>
                                {hasVpEditPermission && (
                                    <div className="flex-shirk-1 me-2">
                                        <button className="btn btn-default" onClick={toggleAddParent}>
                                            <i className="fa-solid fa-plus me-2"></i>
                                            Create parent
                                        </button>
                                    </div>
                                )}
                                <div className="flex-shirk-1 me-2">
                                    <button className="btn btn-default" onClick={toggleSearchParent}>
                                        <i className="fa-solid fa-search me-2"></i>
                                        Search virtual parent
                                    </button>
                                </div>
                            </>
                        )}
                        {invevoExportVpGridFeature && (
                            <div className="flex-shirk-1 me-2">
                                <button className="btn btn-default" onClick={onExport}>
                                    <i className="fa-regular fa-file-excel me-2"></i>
                                    Export to Excel
                                </button>
                            </div>
                        )}

                        <div className="flex-grow-1">
                            <button className="btn btn-default float-right" onClick={onClose}>
                                <i className="fa-solid fa-times me-2"></i>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    ) : null
}
const mapStateToProps = state => {
    return {
        hasAccount: hasSelectedAccount(state.currentSelectionReducer.selectedAccount),
        selectedAccount: state.currentSelectionReducer.selectedAccount,
        hasVpEditPermission: hasVPEditPermission(state.authReducer.permissions)
    }
}

export default connect(mapStateToProps, {
    refreshVpHierarchy
})(VirtualParent)
