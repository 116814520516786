import { genericAction } from "../constants";

export const genericActionsFake = () => ({
  type: genericAction.FAKE_ACTION
});

export const genericActionsResetToInitialState = () => ({
  type: genericAction.RESET_TO_INITIAL_STATE
});

export const setReportOrigin = (isFromMainMenu) => ({
  type: 'SET_REPORT_ORIGIN',
  isFromMainMenu
});
