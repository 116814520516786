import SmallPieChart from "../../../../../library/charts/SmallPieChart"
import { getBucketChartTypeFromAggs } from './helpers'

const PieChartDisplayWidget = ({ state }) => {
    const { widget, aggregations } = state

    const data = getBucketChartTypeFromAggs(aggregations, widget.bucketChartType)

    return (
        <SmallPieChart
            className="w-100 h-100"
            title={widget.displayName}
            data={data.map(bucket => ({ label: bucket.label, value: bucket.total.toString() }))}
            colour={widget.theme === "DEFAULT" ? "white" : "blue"}
            type={widget.type.toLowerCase()}
        />
    )
}

export default PieChartDisplayWidget