import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { showToastErrorMessage } from '../../api/toasterApi';
import { ConfrimDialog } from '../common/confirmDialog';
import { IconButton } from '../common/buttons/iconButton';
import EditNote from './editNote';

export const dragSpec = {
  beginDrag(props) {
    return { id: props.note.id };
  },
  endDrag(props, monitor) {
    const dropResult = monitor.getDropResult() || {};
    const target = dropResult.targetId;
    if (target && props.note.id !== target) {
      props.moveStaticNote(props.note.id, target);
    }
  }
};

export const dropSpec = {
  drop(props) {
    return { targetId: props.note.id };
  }
};

export const collectDrag = (connect) => {
  return {
    connectDragSource: connect.dragSource()
  };
};

export const collectDrop = (connect) => {
  return {
    connectDropTarget: connect.dropTarget()
  };
};

class StaticNote extends Component {
  constructor(props) {
    super(props);
    this.state = { isEdit: false };
  }

  onEdit = () => this.setIsEdit(true);

  onUpdate = (noteText) => {
    if (noteText === '') {
      showToastErrorMessage('The note cannot be blank');
    } else {
      this.setState({ note: { ...this.state.note, noteText }, isEdit: false });
      this.props.saveStaticNote(this.props.note.id, noteText);
    }
  };

  onDelete = () => {
    try {
      this.props.deleteStaticNote(this.props.note.id);
      this.setState({ isEdit: false });
    } catch {
      showToastErrorMessage('Could not delete note');
    }
  };

  setIsEdit = (show) => this.setState({ isEdit: show });

  render() {
    const { note, connectDragSource, connectDropTarget } = this.props;
    const ctrl = (
      <div style={{ float: 'right' }}>
        <IconButton onClick={this.onEdit} icon={'ellipsis-v'} />
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        <div className="postit" key={note.id}>
          {ctrl}
          <p onClick={this.onEdit}>{note.noteText}</p>
          <EditNote
            show={this.state.isEdit}
            setShow={this.setIsEdit}
            initNoteText={this.props.note.noteText}
            onUpdate={this.onUpdate}
            onDelete={this.onDelete}
          />
          <ConfrimDialog
            show={this.state.showDialog}
            message={`Are you sure you want to delete note: ${this.props.note.noteText}?`}
            onConfirm={this.actionDeleteNote}
            onClose={this.closeDialog}
          />
        </div>
      )
    );
  }
}

StaticNote.propTypes = {
  note: PropTypes.object.isRequired,
  saveStaticNote: PropTypes.func.isRequired,
  deleteStaticNote: PropTypes.func.isRequired
};

const dragStaticNote = DragSource(
  'DND_STATIC_NOTE',
  dragSpec,
  collectDrag
)(StaticNote);
const dragDropStaticNote = DropTarget(
  'DND_STATIC_NOTE',
  dropSpec,
  collectDrop
)(dragStaticNote);

export default dragDropStaticNote;
