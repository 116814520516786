import DataField from "./DataField"
import { actionTypes } from "../../reducers/actionTypes"
import { memo } from 'react'

const MemoizedCustomerDataField = memo(({ dataField, dispatch, lookups }) => {
    const onDataFieldChange = updatedDataField =>
        dispatch({
            type: actionTypes.DATA_CUSTOMER_FIELD_UPDATED,
            updatedDataField,
        })
    
    const onDataFieldRemoved = (id) => dispatch({type: actionTypes.DATA_CUSTOMER_FIELD_REMOVED, id})

    return (
        <DataField
            dataField={dataField}
            onDataFieldChange={onDataFieldChange}
            onDataFieldRemoved={onDataFieldRemoved}
            lookups={lookups}
        />
    )
})

export default MemoizedCustomerDataField