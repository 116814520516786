import PropTypes from 'prop-types';
import React from 'react';

const MultiCheckboxSet = ({
  name,
  prefixText,
  data = [],
  selected,
  onChange
}) => {
  const handleClick = (e) => {
    const id = e.target.name;

    const checked = e.target.checked;
    const foundIndex = selected.findIndex((s) => `${s}` === `${id}`);

    let newSelected = selected;
    if (foundIndex === -1 && checked) {
      newSelected = [...selected, `${id}`];
    } else if (foundIndex !== -1 && !checked) {
      newSelected = [
        ...selected.slice(0, foundIndex),
        ...selected.slice(foundIndex + 1)
      ];
    }

    onChange(name, newSelected);
  };
  
  const onCheckChange = () => {}

  return (
    <fieldset className="form-group">
      {data.map((m) => {
        const found = selected.find((f) => f === m.id);
        const key = `${name}-${m.id}`;
        return (
          <div className="form-check" key={key}>
            <input
              type="checkbox"
              className="form-check-input"
              id={key}
              name={m.id}
              checked={found !== undefined}
              onClick={handleClick}
              onChange={onCheckChange}
            />
            <label className="form-check-label text-muted" htmlFor={key}>
              {prefixText ? `${prefixText} ` : ''}
              {m.text}
            </label>
          </div>
        );
      })}
    </fieldset>
  );
};
MultiCheckboxSet.propTypes = {
  data: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired
};

export default MultiCheckboxSet;
