import { Toggle } from "invevo-react-components"
import UserRole from "../../../types/UserRole"

type Props = {
    role: UserRole,
    onToggle: (reference: string) => void
}

const RoleToggle = ({ role, onToggle }: Props) => {

    const onClick = () => { onToggle(role.reference) }
    return (
        <div key={role.reference} className="d-flex p-2 pointer align-items-center">
            <Toggle
                className="mt-1"
                status={role.enabled}
                onStatusChanged={onClick}
            />
            <span className="d-flex flex-grow-1 mt-1 ms-2">{role.roleName}</span>
        </div>
    )
}

export default RoleToggle