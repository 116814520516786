import classes from "./FileUploadButton.module.scss"

type FileUploadButtonProps = {
    label: string
    onFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void
    className?: string
    colour?: string
    ariaLabel?: string
}

const FileUploadButton = ({
    label,
    onFileUpload,
    className = "",
    ariaLabel = "",
    colour = "white",
}: FileUploadButtonProps) => {
    return (
        <div className={`${className} d-flex`}>
            <div className={`${classes.button} d-flex w-100 pointer no-select align-items-center `}>
                <div className={`d-flex ${classes.icon} h-100`}>
                    <i className="m-auto fal fa-upload py-2 px-3 fs-5" />
                </div>
                <div className={`d-flex ${classes[`label-${colour}`]} w-100 h-100 align-items-center py-1`}>
                    <label className="fs-5 ms-3 mx-3 fw-normal mb-0" htmlFor="documentUploader" aria-label={ariaLabel}>
                        {label}
                    </label>
                    <input
                        type="file"
                        className="d-none"
                        id="documentUploader"
                        name="documentUploader"
                        onChange={onFileUpload}
                        accept=".csv"
                    />
                </div>
            </div>
        </div>
    )
}

export default FileUploadButton
