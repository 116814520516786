import Flow from "../../types/Flow"
import FlowCard from "./FlowCard"

type FlowsProps = {
    flows: Flow[]
    entityConfigDisplayNames: { reference: string; displayName: string }[]
    selectedFlowReference: string | undefined
    onFlowSelected: (flowReference: string) => void
    onFlowRemoved: (flowReference: string) => void
}

const Flows = ({ flows, entityConfigDisplayNames, selectedFlowReference, onFlowSelected, onFlowRemoved }: FlowsProps) => {
    return (
        <div className="d-flex flex-column gap-2">
            {flows.map(flow => (
                <FlowCard
                    key={flow.reference}
                    flow={flow}
                    entityConfigDisplayName={
                        entityConfigDisplayNames.find(mapping => flow.entityConfigReference === mapping.reference)?.displayName ?? "MISSING ENTITY CONFIG"
                    }
                    isSelected={flow.reference === selectedFlowReference}
                    onFlowSelected={onFlowSelected}
                    onFlowRemoved={onFlowRemoved}
                />
            ))}
            {flows.length === 0 && <span className="text-grey mt-2">You have no business flows</span>}
        </div>
    )
}

export default Flows
