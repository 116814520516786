import { useEffect, useState } from "react"
import Loading from "../../../library/Loading/Loading"
import EntityConfig from "../types/EntityConfig"
import EntityConfigList from "./EntityConfigList"
import useApiQuery from "../../../hooks/useApiQuery"
import EntityConfigDetails from "./EntityConfigDetails"
import { v4 as uuidv4 } from "uuid"
import { HardcodedEntityDataFields } from "../constants"
import Lookup from "../../../types/Lookup"
import EntityRelationship from "../types/EntityRelationship"
import EntityRelationshipDto from "../types/EntityRelationshipDto"
import EntityConfigDiagram from "./erd/EntityConfigDiagram"
import { ReactFlowProvider } from "reactflow"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { useFeatureToggle } from "../../../hooks/useFeatureToggle"
import { useEnvConfig } from "../../../contexts/EnvironmentConfigContext"
import useClient from "../../../hooks/useClient"
import { convertEntityRelationshipsFromDto } from "../util"

const EntityConfigDashboard = () => {
    const client = useClient()
    const config = useEnvConfig()

    const { isEnabled: allowDiagramView } = useFeatureToggle("entityConfigDiagramView")
    const [diagramView, setDiagramView] = useLocalStorage<boolean>("entityConfig/diagram", false)

    const [entityConfigs, setEntityConfigs] = useState<EntityConfig[]>([])
    const [originalEntityConfigs, setOriginalEntityConfigs] = useState<EntityConfig[]>([])
    const [selectedEntityConfigRef, setSelectedEntityConfigRef] = useState<string | undefined>(undefined)

    const [entityRelationships, setEntityRelationships] = useState<EntityRelationship[]>([])

    const entityConfigsRequest = useApiQuery<EntityConfig[]>({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-config`,
        method: "GET",
        onSuccess: (entityConfigs: EntityConfig[]) => {
            setEntityConfigs(entityConfigs)
            setOriginalEntityConfigs(entityConfigs)
        }
    })

    const getLookupsRequest = useApiQuery<Lookup[]>({
        url: `${config.DATA_API_URL}/api/${client}/lookups`,
        method: "GET"
    })

    const entityRelationshipsRequest = useApiQuery<EntityRelationshipDto[]>({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-relationships`,
        method: "GET",
        onSuccess: entityRelationships => setEntityRelationships(convertEntityRelationshipsFromDto(entityRelationships))
    })

    const [newEntityConfig, setNewEntityConfig] = useState(getDefaultNewEntityConfig)

    const onEntityConifgUpdated = (updatedEntityConfig: EntityConfig) => {
        setEntityConfigs(entityConfigs =>
            entityConfigs.map(entityConfig =>
                entityConfig.reference === updatedEntityConfig.reference || entityConfig.reference === newEntityConfig.reference
                    ? updatedEntityConfig
                    : entityConfig
            )
        )
    }

    const onNewEntityConfigSaved = (newEntityConfig: EntityConfig) => {
        setOriginalEntityConfigs(originalEntityConfigs => [...originalEntityConfigs, newEntityConfig])
        setEntityConfigs(entityConfigs =>
            entityConfigs.map(entityConfig => (entityConfig.displayName === newEntityConfig.displayName ? newEntityConfig : entityConfig))
        )
        setSelectedEntityConfigRef(newEntityConfig.reference)
    }

    const onEntityConfigDuplicated = (duplicatedEntityConfig: EntityConfig) => {
        setOriginalEntityConfigs(originalEntityConfigs => [...originalEntityConfigs, duplicatedEntityConfig])
        setEntityConfigs(entityConfigs => [...entityConfigs, duplicatedEntityConfig])
        setSelectedEntityConfigRef(duplicatedEntityConfig.reference)
    }

    useEffect(() => {
        setNewEntityConfig(getDefaultNewEntityConfig)
    }, [originalEntityConfigs])

    const onNewEntityConfigAdded = () => {
        setEntityConfigs(entityConfigs => [...entityConfigs, newEntityConfig])
        setSelectedEntityConfigRef(newEntityConfig.reference)
    }

    const onEntityConfigDeleted = (deletedEntityConfig: EntityConfig) => {
        setEntityConfigs(entityConfigs => entityConfigs.filter(entityConfig => entityConfig.reference !== deletedEntityConfig.reference))
        setOriginalEntityConfigs(originalEntityConfigs =>
            originalEntityConfigs.filter(entityConfig => entityConfig.reference !== deletedEntityConfig.reference)
        )
        setSelectedEntityConfigRef(entityConfigs[0]?.reference)
    }

    const selectedEntityConfig = entityConfigs.find(entityConfig => entityConfig.reference === selectedEntityConfigRef)

    const isNewEntityConfigSelected = originalEntityConfigs.every(entityConfig => entityConfig.reference !== selectedEntityConfigRef)

    const disableDiagramView = () => setDiagramView(false)
    const enableDiagramView = () => setDiagramView(allowDiagramView)

    return (
        <div className="d-flex flex-grow-1 overflow-hidden h-100">
            <div className="d-flex w-100 h-100">
                <Loading isLoading={entityConfigsRequest.isFetching || entityRelationshipsRequest.isFetching} colour="blue">
                    {diagramView && allowDiagramView ? (
                        <ReactFlowProvider>
                            <EntityConfigDiagram
                                entityConfigs={entityConfigs}
                                entityRelationships={entityRelationships}
                                lookups={getLookupsRequest.data ?? []}
                                newEntityConfig={newEntityConfig}
                                onEntityConfigUpdated={onEntityConifgUpdated}
                                onNewEntityConfigAdded={onNewEntityConfigAdded}
                                onNewEntityConfigSaved={onNewEntityConfigSaved}
                                onEntityRelationshipsUpdated={setEntityRelationships}
                                disableDiagramView={disableDiagramView}
                            />
                        </ReactFlowProvider>
                    ) : (
                        <>
                            <EntityConfigList
                                entityConfigs={entityConfigs}
                                selectedEntityConfig={selectedEntityConfig}
                                onEntityConfigSelected={setSelectedEntityConfigRef}
                                onNewEntityClicked={onNewEntityConfigAdded}
                                enableDiagramView={enableDiagramView}
                            />
                            {selectedEntityConfig === undefined ? (
                                <div className="d-flex flex-grow-1 justify-content-center bg-dark-gradient">
                                    <span className="text-white fs-5 mt-4">Please select an existing entity or add a new one</span>
                                </div>
                            ) : (
                                <EntityConfigDetails
                                    entityRelationships={entityRelationships}
                                    allEntityConfigs={entityConfigs}
                                    entityConfig={selectedEntityConfig}
                                    onEntityConfigUpdated={onEntityConifgUpdated}
                                    onNewEntityConfigSaved={onNewEntityConfigSaved}
                                    onEntityConfigDeleted={onEntityConfigDeleted}
                                    onEntityConfigDuplicated={onEntityConfigDuplicated}
                                    onEntityRelationshipsUpdated={setEntityRelationships}
                                    lookups={getLookupsRequest.data ?? []}
                                    isAddingNewEntityConfig={isNewEntityConfigSelected}
                                />
                            )}
                        </>
                    )}
                </Loading>
            </div>
        </div>
    )
}

export default EntityConfigDashboard

export const getDefaultNewEntityConfig = (): EntityConfig => ({
    reference: uuidv4(),
    displayName: "",
    fields: HardcodedEntityDataFields,
    parents: []
})
