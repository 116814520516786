import { useState } from "react"
import classes from "./AddRole.module.scss"
import { Input, Loading, useApi, useClient } from "invevo-react-components"
import { actionTypes } from "../../../reducers/actionTypes"
import { v4 as uuidv4 } from 'uuid'
import { defaultCustomerPermission } from "../permissions/CustomerPermission"
import { defaultUserPermission } from "../permissions/UserPermission"
import { defaultAutomatePermission } from "../permissions/AutomatePermission"
import { defaultBrandPermission } from "../permissions/BrandPermission"
import { defaultCalculatePermission } from "../permissions/CalculatePermission"
import { defaultConnectPermission } from "../permissions/ConnectPermission"
import { defaultDashboardPermission } from "../permissions/DashboardPermission"
import { defaultDataConfigPermission } from "../permissions/DataConfigPermission"
import { defaultIntegrationPermission } from "../permissions/IntegrationPermission"
import { defaultPayPermission } from "../permissions/PayPermission"
import { defaultTaskPermission } from "../permissions/TaskPermission"
import { defaultMakeCallPermission } from "../permissions/MakeCallPermission"
import { toast } from 'react-toastify'
import StandardButton from "../../../library/buttons/StandardButton/StandardButton";

const AddRole = ({ onCancel, onAdd, dispatch }) => {
    const api = useApi()
    const client = useClient()

    const [name, setName] = useState("")
    const [isSaving, setIsSaving] = useState(false)

    const onChange = (event) => {
        setName(event.target.value)
    }

    const onAddRole = () => {
        const roleId = uuidv4()

        const dto = {
            name,
            customerPermission: defaultCustomerPermission,
            userPermission: defaultUserPermission,
            automatePermission: defaultAutomatePermission,
            brandPermission: defaultBrandPermission,
            calculatePermission: defaultCalculatePermission,
            connectPermission: defaultConnectPermission,
            dashboardPermission: defaultDashboardPermission,
            dataConfigPermission: defaultDataConfigPermission,
            integrationPermission: defaultIntegrationPermission,
            payPermission: defaultPayPermission,
            taskPermission: defaultTaskPermission,
            makeCallPermission: defaultMakeCallPermission,
            assignedUsers: []
        }

        setIsSaving(true)

        api.put(`/api/${client}/roles/${roleId}`, dto)
            .then(_ => {
                dispatch({
                    type: actionTypes.ROLE_ADDED, role: {
                        ...dto,
                        id: roleId,
                        isAdmin: dto.name.toLowerCase() === "administrator",
                        isEnabled: true
                    }
                })
                setIsSaving(false)
                onAdd()
            })
            .catch(error => {
                console.error(error)
                toast.error("Error while updating role")
                setIsSaving(false)
            })
    }

    return (
        <div className={`d-flex flex-column flex-grow-1 ${classes.form}`}>
            <Loading isLoading={isSaving} colour="blue">
                <div className="d-flex p-3">
                    <Input className="mb-3 flex-grow-1" placeholder="Please enter a role name" label="ROLE NAME" labelColour="grey" value={name} onChange={onChange} />
                </div>
            </Loading>
            <div className="d-flex p-3 mt-auto bg-grey">
                <StandardButton className="ms-auto" label="Cancel" colour="grey" iconClasses="text-blue fal fa-times" border="blue" onClick={onCancel} disabled={isSaving} />
                <StandardButton className="ms-3" label="Add role" colour="grey" iconClasses="text-blue fal fa-plus" border="blue" onClick={onAddRole} disabled={isSaving} />
            </div>
        </div>
    )
}

export default AddRole