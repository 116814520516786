import classes from "./Loading.module.scss"
import Spinner from "./Spinner"

type LoadingProps = {
    isLoading: boolean
    children: React.ReactNode
    colour?: "blue" | "white"
    size?: "small" | "medium"
}

const Loading = ({ isLoading, children, colour = "blue", size = "medium" }: LoadingProps) => {
    if (isLoading) {
        return (
            <div className={`${classes.loading} w-100 h-100`}>
                <Spinner className={classes.spinner} size={size} colour={colour} />
                <div style={{ visibility: "hidden" }}>{children}</div>
            </div>
        )
    }

    return <>{children}</>
}

export default Loading
