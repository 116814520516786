export const storeGridState = (gridKey, gridState, lastResponse = null) => ({
    type: 'STORE_GRID_STATE',
    gridKey,
    gridState,
    lastResponse
  });
  
  export const resetGridState = (gridKey) => ({
    type: 'RESET_GRID_STATE',
    gridKey
  });
  