import React, { Component } from 'react';
import { connect } from 'react-redux';
import { executeAuthAsyncGet, executeAuthAsyncPost } from '../../utility/asyncSupport';
import { showToastSuccessMessage, showToastErrorMessage } from '../../api/toasterApi';
import { creditLimitRequestTypeNames } from './creditConstants';
import { decimalKeyInput } from '../../utility/generalFunctions';
import TradeLimitExpirationDate from './tradeLimitExpirationDate';
import { last } from 'lodash';
import { registerForIntl, provideIntlService } from '@progress/kendo-react-intl';
import { ConfrimDialog } from '../common/confirmDialog';
import RequestReasonSelect from './requestReasonSelect';
import ComponentLoader from '../common/ComponentLoader';
import StatefulSelect from '../common/statefulSelect';
import { creditLimitRequestTypes, SearchTypeForCustomerOrVirtualAccount } from './creditConstants';
import { displayErrorMessage } from '../../utility/error';

import classes from './creditLimit.module.scss';

class Request extends Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState();
  }

  defaultState = () => ({
    requestTypeId: null,
    expirationDate: null,
    amountRequested: null,
    amountRequestedNumber: null,
    reasonId: null,
    noteText: '',
    showDialog: false,
    dialogMessage: '',
    dialogFn: null,
    isLoading: false
  });

  componentDidMount() {
    if (
      this.props.searchType ===
      SearchTypeForCustomerOrVirtualAccount.FORVIRTUALACCOUNT
    ) {
      this.setState({
        requestTypeId: creditLimitRequestTypeNames.CREDIT_LIMIT
      });
    }
  }

  onRequestTypeChanged = (value) => {
    this.setState({ requestTypeId: value });
  };
  onChangeExpirationDate = (value) => {
    let expirationDate = null;

    if (value != null) {
      expirationDate = new Date(value);
      expirationDate.setHours(0, 0, 0, 0);
    }

    this.setState({ expirationDate: expirationDate });
  };
  onReasonChange = (value) => {
    this.setState({ reasonId: value });
  };

  onKeyPressAmountRequested = (event) => {
    const inputKeyCode = event.which;

    if (!decimalKeyInput(inputKeyCode)) event.preventDefault();
  };

  onChangeAmountRequested = (event) => {
    const amountRequested = parseFloat(event.target.value);
    const amountRequestedString = isNaN(amountRequested)
      ? null
      : event.target.value;
    this.setState({
      amountRequested: amountRequestedString,
      amountRequestedNumber: amountRequested
    });
  };
  onChangeNote = (event) => {
    if (event.target.value.length > 4000) {
      this.setState({ noteText: event.target.value });
      showToastErrorMessage('Credit note text cannot excced 4000 characters.');
    } else {
      this.setState({ noteText: event.target.value });
    }
  };

  onFocusAmountRequested = () => {
    this.setState({ amountRequested: this.state.amountRequestedNumber });
  };
  onFocusOutAmountRequested = () => {
    this.setState({
      amountRequested: provideIntlService(this).formatNumber(
        this.state.amountRequested,
        this.props.currencyFormat
      )
    });
  };

  showExpireDate = () => {
    return (
      this.state.requestTypeId ===
      creditLimitRequestTypeNames.TEMPORARY_CREDIT_LIMIT
    );
  };

  enabledRequest = () => {
    const s = this.state;
    const {
      CREDIT_LIMIT,
      TEMPORARY_CREDIT_LIMIT
    } = creditLimitRequestTypeNames;

    const cond1 =
      s.requestTypeId === CREDIT_LIMIT ||
      (s.requestTypeId === TEMPORARY_CREDIT_LIMIT && s.expirationDate != null);
    const cond2 = s.amountRequestedNumber > 0;
    const cond3 = s.reasonId > 0;
    let cond4 = false;
    const cond5 = s.noteText.length < 4000;

    if (s.requestTypeId === TEMPORARY_CREDIT_LIMIT) {
      if (s.expirationDate != null) {
        const dt = new Date();

        dt.setUTCHours(0, 0, 0, 0);

        let tradeLimitMaxExpiration = null;
        const tradeLimitMinExpiration = new Date();

        tradeLimitMinExpiration.setUTCHours(0, 0, 0, 0);

        if (this.props.temporaryCreditLimitExpirationMax != null) {
          dt.setDate(
            dt.getDate() + this.props.temporaryCreditLimitExpirationMax
          );
          tradeLimitMaxExpiration = dt;
        }

        if (s.expirationDate >= tradeLimitMinExpiration) {
          if (
            tradeLimitMaxExpiration == null ||
            s.expirationDate <= tradeLimitMaxExpiration
          ) {
            cond4 = true;
          }
        }
      }
    } else {
      cond4 = true;
    }
    return cond1 && cond2 && cond3 && cond4 && cond5;
  };

  render() {
    const showExpireDate = this.showExpireDate();

    const { FORCUSTOMER } = SearchTypeForCustomerOrVirtualAccount;

    return (
      <div>
        <div>
          <h3 className="text-white top20">Request</h3>

          <hr />

          <div className={`${classes['form-row']} align-items-center top10`}>
            <div className="col-4">Amount</div>
            <div className="col-8">
              <input
                className="form-control"
                value={this.state.amountRequested || ''}
                onChange={this.onChangeAmountRequested}
                onFocus={this.onFocusAmountRequested}
                onBlur={this.onFocusOutAmountRequested}
                onKeyPress={this.onKeyPressAmountRequested}
              />
            </div>
          </div>

          {FORCUSTOMER === this.props.searchType && (
            <div className={`${classes['form-row']} align-items-center top10`}>
              <div className="col-4">
                <h3 className='text-white'>Type</h3>
              </div>
              <div className="col-8">
                <StatefulSelect
                  options={creditLimitRequestTypes}
                  optionId={this.state.requestTypeId}
                  onChange={this.onRequestTypeChanged}
                />
              </div>
            </div>
          )}

          {showExpireDate && (
            <div className={`${classes['form-row']} align-items-center top10`}>
              <div className="col-4">
                <h3 className='text-white'>Expiry Date</h3>
              </div>
              <div className="col-8">
                <TradeLimitExpirationDate
                  onChange={this.onChangeExpirationDate}
                  value={this.state.expirationDate}
                />
              </div>
            </div>
          )}

          <div className={`${classes['form-row']} align-items-center top10`}>
            <div className="col-4">
              <h3 className='text-white'>Reason</h3>
            </div>
            <div className="col-8">
              <RequestReasonSelect
                id={this.state.reasonId}
                onChange={this.onReasonChange}
              />
            </div>
          </div>

          <div className={`${classes['form-row']} align-items-center top10`}>
            <div className="col-4">
              <h3 className='text-white'>Add a note</h3>
            </div>
            <div className="col-8">
              <textarea
                value={this.state.noteText}
                name="note"
                onChange={this.onChangeNote}
                rows={3}
                style={{ width: '100%' }}
              ></textarea>
            </div>
          </div>

          <div className="form-group top10 d-flex col-12 justify-content-between">
            <button
              className={`btn ${classes['btn-custom']} `}
              onClick={this.onRequestCancelClicked}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={this.onRequestApprovalClicked}
              disabled={!this.enabledRequest()}
            >
              Request Approval
            </button>
          </div>

          <ConfrimDialog
            show={this.state.showDialog}
            message={this.state.dialogMessage}
            onConfirm={this.state.dialogFn}
            onClose={this.closeDialog}
          />
        </div>
        {this.state.isLoading && <ComponentLoader />}
      </div>
    );
  }

  closeDialog = () => {
    this.setState({
      showDialog: false
    });
  };

  onRequestCancelClicked = () => {
    this.setState({
      showDialog: true,
      dialogMessage: 'Are you sure you want to cancel this request?',
      dialogFn: this.actionRequestCancel
    });
  };

  actionRequestCancel = () => {
    showToastSuccessMessage('Request cancelled.');
    this.setState(this.defaultState());
  };

  onRequestApprovalClicked = () => {
    this.setState({
      showDialog: true,
      dialogMessage: 'Are you sure you want to request this update?',
      dialogFn: this.actionRequestApproval
    });
  };

  actionRequestApproval = () => {
    this.setState({ isLoading: true });
    const s = this.state;
    const { FORVIRTUALACCOUNT } = SearchTypeForCustomerOrVirtualAccount;

    const bVirtual = this.props.searchType === FORVIRTUALACCOUNT;

    const confirmToastMessage = 'Request saved correctly.';
    const requestObject = {
      CustomerId: bVirtual ? null : this.props.customerId,
      VirtualAccountId: bVirtual
        ? this.props.customerId
        : null,
      RequestAmount: s.amountRequestedNumber,
      RequestExpiryDate: s.expirationDate,
      RequestTypeId: s.requestTypeId,
      RequestReasonId: s.reasonId,
      NoteTextRequest: s.noteText
    };
    this.props.executeAuthAsyncPost(
      this.props.client,
      'CreditLimit/InsertNewCreditLimitRequest',
      'INSERT_NEW_CREDIT_LIMIT_REQUEST',
      requestObject,
      () => {
        showToastSuccessMessage(confirmToastMessage);
        this.closeDialog();
        this.props.refreshCallback();
        this.setState({ isLoading: false });
      },
      (error) => {
        displayErrorMessage(error);

        this.closeDialog();
        this.setState({ isLoading: false });
      }
    );
  };
}

const mapStateToProps = (state) => {
  let configuration = {};

  if (state.globalConfiguration.length > 0) {
    configuration = last(state.globalConfiguration);
  }

  return {
    currencyFormat: state.lookupReducer.globalSettings.results.currencyFormat,
    temporaryCreditLimitExpirationMax:
      configuration.temporaryCreditLimitExpirationMax
  };
};

registerForIntl(Request);

export default connect(mapStateToProps, {
  executeAuthAsyncGet,
  executeAuthAsyncPost
})(Request);
