import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as lodash from 'lodash';

import {
  GLOBAL_SETTINGS,
  authZ_Permissions,
  getApiUrl
} from '../../legacy/constants';
import { webApiInterface } from '../../legacy/api/webApiInterface';
import {
  showToastSuccessMessage,
  showToastErrorMessage
} from '../../legacy/api/toasterApi.js';
import { executeAuthAsyncGet } from '../../legacy/utility/asyncSupport';
import { hasPermission } from '../../legacy/utility/authZ';
import ImportLocationGrid from '../../legacy/components/admin/fileImportConfig/importLocationGrid';
import AddDocumentType from '../../legacy/components/admin/fileImportConfig/addDocumentType';
import AddImportLocation from '../../legacy/components/admin/fileImportConfig/addImportLocation';
import AddImportQueue from '../../legacy/components/admin/fileImportConfig/addImportQueue';
import AddFileType from '../../legacy/components/admin/fileImportConfig/addFileType';
import ImportQueueGrid from '../../legacy/components/admin/fileImportConfig/importQueueGrid';
import FileTypeGrid from '../../legacy/components/admin/fileImportConfig/fileTypeGrid';
import DocumentTypeGrid from '../../legacy/components/admin/fileImportConfig/documentTypeGrid';
import * as importConfigurationActions from '../../legacy/actions/importConfigurationActions';

class FileImporterConfigurationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileImportLocation: {
        showForm: false
      },
      fileImportQueue: {
        showForm: false
      },
      fileType: {
        showForm: false
      },
      showGrid: {
        display: true
      },
      refreshProcesses: []
    };

    this.showImportLocationForm = this.showImportLocationForm.bind(this);
    this.showImportQueueForm = this.showImportQueueForm.bind(this);
    this.showFileTypeForm = this.showFileTypeForm.bind(this);
    this.importLocationSave = this.importLocationSave.bind(this);
    this.importLocationDelete = this.importLocationDelete.bind(this);
    this.fileTypeSave = this.fileTypeSave.bind(this);
    this.importQueueSave = this.importQueueSave.bind(this);
    this.importQueueDelete = this.importQueueDelete.bind(this);
    this.fileTypeDelete = this.fileTypeDelete.bind(this);
    this.cancel = this.cancel.bind(this);
    this.export = this.export.bind(this);
    this.onFileSelected = this.onFileSelected.bind(this);
    this.renderImportLocationForm = this.renderImportLocationForm.bind(this);
    this.forceRefresh = this.forceRefresh.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      fileImportLocation: Object.assign({}, nextProps.fileImportLocation)
    });
    this.setState({
      fileImportQueue: Object.assign({}, nextProps.fileImportQueue)
    });
    this.setState({ fileType: Object.assign({}, nextProps.fileType) });
    this.setState({ showGrid: Object.assign({}, nextProps.showGrid) });
    this.setState({
      refreshProcesses: Object.assign({}, nextProps.refreshProcesses)
    });
  }

  componentDidMount() {
    this.props.actions.loadRefreshProcesses(this.props.client);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    // reset the forms state on leaving
    this.props.actions.cancel();
  }

  showImportLocationForm(importLocationId) {
    if (importLocationId) {
      this.props.actions.loadFileImportLocation(this.props.client, importLocationId);
    } else {
      this.props.actions.newFileImportLocation();
    }
  }

  showImportQueueForm(importQueueId) {
    if (importQueueId) {
      this.props.actions.loadFileImportQueue(this.props.client, importQueueId);
    } else {
      this.props.actions.newFileImportQueue();
    }
  }

  showFileTypeForm(fileTypeId) {
    if (fileTypeId) {
      this.props.actions.loadFileType(this.props.client, fileTypeId);
    } else {
      this.props.actions.newFileType();
    }
  }

  showDocumentTypeForm = (documentTypeId) => {
    if (documentTypeId) {
      this.props.actions.loadDocumentType(this.props.client, documentTypeId);
    } else {
      this.props.actions.newDocumentType();
    }
  };

  importQueueSave(fileImportQueue) {
    if (fileImportQueue.id) {
      this.props.actions.updateFileImportQueue(this.props.client, fileImportQueue);
    } else {
      this.props.actions.createFileImportQueue(this.props.client, fileImportQueue);
    }
  }

  cancel() {
    this.props.actions.cancel();
  }

  importLocationSave(fileImportLocation) {
    if (fileImportLocation.id) {
      this.props.actions.updateFileImportLocation(this.props.client, fileImportLocation);
    } else {
      this.props.actions.createFileImportLocation(this.props.client, fileImportLocation);
    }
  }

  documentTypeSave = (documentType) => {
    if (documentType.id) {
      this.props.actions.updateImportDocumentType(this.props.client, documentType);
    } else {
      this.props.actions.createImportDocumentType(this.props.client, documentType);
    }
  };

  fileTypeSave(fileType) {
    if (fileType.id) {
      this.props.actions.updateFileType(this.props.client, fileType);
    } else {
      this.props.actions.createFileType(this.props.client, fileType);
    }
  }

  importLocationDelete(fileImportLocation) {
    this.props.actions.deleteFileImportLocation(this.props.client, fileImportLocation);
  }

  importQueueDelete(importQueue) {
    this.props.actions.deleteFileImportQueue(this.props.client, importQueue);
  }

  fileTypeDelete(fileType) {
    this.props.actions.deleteFileType(this.props.client, fileType);
  }

  documentTypeDelete = (documentType) => {
    this.props.actions.deleteDocumentType(this.props.client, documentType);
  };

  forceRefresh() {
    this.props.actions.setRefreshProcess(this.props.client, this.state.refreshProcess);
  }

  export() {
    const xhr = new XMLHttpRequest();
    const url = getApiUrl(this.props.client) + 'api/import-configuration/export-all';

    xhr.open('GET', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(xhr.response);
      a.download = 'AdminFile.json';
      a.click();
    };

    this.props.executeAuthAsyncGet(this.props.client, 'Lookup/OK', 'CHECK_OK', null, function () {
      webApiInterface.applySecurity(xhr);
      xhr.send(null);
    });
  }

  onFileSelected(event) {
    const self = this;
    event.preventDefault();

    const file = event.target.files[0];
    const jsonExtension = file.name.indexOf('.json');

    if (jsonExtension === -1) {
      showToastErrorMessage('Please select a valid json file');
      return;
    }

    const reader = new FileReader();
    const url = getApiUrl(this.props.client) + 'api/import-configuration/import-all';

    showToastSuccessMessage('Upload of file: ' + file.name + ' started');

    reader.onload = function (event) {
      if (
        event.currentTarget.result.length >
        GLOBAL_SETTINGS.max_upload_file_size * 1024 * 1024
      ) {
        showToastErrorMessage(
          'File is too big to be uploaded to the server. Please select another file.'
        );
        return;
      }

      if (event.currentTarget.result.length === 0) {
        showToastErrorMessage(
          'File has no content. Please select another file.'
        );
        return;
      }

      const XHR = new XMLHttpRequest();
      const data = FileImporterConfigurationContainer.addFileContentToRequest(
        'importAll',
        file.name,
        file.type,
        event.currentTarget.result
      );

      XHR.onreadystatechange = function () {
        if (XHR.readyState === 4) {
          if (XHR.status === 200) {
            const response = JSON.parse(XHR.response);

            if (response.result.length > 0) {
              showToastErrorMessage('Error uploading file');
            } else {
              showToastSuccessMessage('File uploaded');
              self.props.actions.refresh();
            }
          }
        }
      };

      XHR.open('POST', url);
      XHR.setRequestHeader(
        'Content-Type',
        'multipart/form-data; boundary=blob'
      );
      XHR.setRequestHeader('Access-Control-Allow-Origin', '*');
      this.props.executeAuthAsyncGet(
        this.props.client,
        'Lookup/OK',
        'CHECK_OK',
        null,
        function () {
          webApiInterface.applySecurity(XHR);
          XHR.send(data + '--blob--');
        }
      );
    }.bind(this);

    reader.readAsBinaryString(file);
  }

  static addFileContentToRequest(fieldName, fileName, fileType, binaryContent) {
    return (
      '--blob\r\n' +
      'content-disposition: form-data; ' +
      'name="' +
      fieldName +
      '"; ' +
      'filename="' +
      fileName +
      '"\r\n' +
      'Content-Type: ' +
      fileType +
      '\r\n' +
      '\r\n' +
      binaryContent +
      '\r\n'
    );
  }

  renderDocumentTypeForm() {
    if (this.props.fileImportDocumentType.showForm) {
      return (
        <div>
          <AddDocumentType
            client={this.props.client}
            onCancel={this.cancel}
            onSave={this.documentTypeSave}
          />
        </div>
      );
    }
  }

  renderImportLocationForm() {
    if (this.state.fileImportLocation.showForm) {
      return (
        <div>
          <AddImportLocation
            client={this.props.client}
            onCancel={this.cancel}
            onSave={this.importLocationSave}
          />
        </div>
      );
    }
  }

  renderImportQueueForm() {
    if (this.state.fileImportQueue.showForm) {
      return (
        <div>
          <AddImportQueue
            client={this.props.client}
            onCancel={this.cancel}
            onSave={this.importQueueSave}
          />
        </div>
      );
    }
  }

  renderFileTypeForm() {
    if (this.state.fileType.showForm) {
      return (
        <div>
          <AddFileType client={this.props.client} onCancel={this.cancel} onSave={this.fileTypeSave} />
        </div>
      );
    }
  }

  getRefreshProcessListItem(refreshProcess) {
    return (
      <li
        key={refreshProcess.id}
        className="list-group-item dropdown-item"
        style={{ color: '#000', fontSize: '11px' }}
      >
        <strong>{refreshProcess.name}</strong> Refreshed (
        {refreshProcess.processModified})
        {this.getRefreshProcessLastRequested(refreshProcess.lastRequested)}
      </li>
    );
  }

  getRefreshProcessLastRequested(lastRequested) {
    if (lastRequested.length > 0) {
      return <span> Requested ({lastRequested})</span>;
    }

    return '';
  }

  renderGrids() {
    const refreshes = Object.values(this.state.refreshProcesses);

    if (this.state.showGrid.display) {
      let buttons;

      if (
        hasPermission(
          this.props.permissions,
          authZ_Permissions.FileImportAdminUpdate
        )
      ) {
        buttons = (
          <div className="d-flex">
            <button
              className="btnDefault btn btn-default"
              onClick={this.export}
            >
              Export All
            </button>
            <label className="btnDefault btn btn-default">
              Import All
              <input
                type="file"
                name="importAll"
                onChange={this.onFileSelected}
                accept=".json"
                style={{ display: 'none' }}
              />
            </label>

            <div className="ms-auto">
              <button
                className="btnDefault btn btn-default"
                onClick={this.forceRefresh}
              >
                Force Refresh
              </button>
            </div>
            <div className="dropdown">
              <button
                id="dropdownMenuButton"
                className="btn btn-default dropdown-toggle"
                data-bs-toggle="dropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <i className="fa fa-bars" />
              </button>
              <ul
                aria-labelledby="dropdownMenuButton"
                className="dropdown-menu dropdown-menu-right"
                style={{ whiteSpace: 'nowrap', padding: '0' }}
              >
                {refreshes.map((r) => this.getRefreshProcessListItem(r))}
              </ul>
            </div>
          </div>
        );
      } else if (
        hasPermission(
          this.props.permissions,
          authZ_Permissions.FileImportAdminRead
        )
      ) {
        buttons = (
          <div className="d-flex">
            <button
              className="btnDefault btn btn-default"
              onClick={this.export}
            >
              Export All
            </button>
            <div className="ms-auto">
              <div className="dropdown">
                <button
                  id="dropdownMenuButton"
                  className="btn btn-default dropdown-toggle"
                  data-bs-toggle="dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <i className="fa fa-bars" />
                </button>
                <ul
                  aria-labelledby="dropdownMenuButton"
                  className="dropdown-menu dropdown-menu-right list-group"
                  style={{ whiteSpace: 'nowrap', padding: '0' }}
                >
                  {refreshes.map((r) => this.getRefreshProcessListItem(r))}
                </ul>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div>
          {buttons}

          <hr />

          <ImportLocationGrid
            client={this.props.client}
            onAdd={this.showImportLocationForm}
            onDelete={this.importLocationDelete}
          />

          <hr />

          <ImportQueueGrid
            client={this.props.client}
            onAdd={this.showImportQueueForm}
            onDelete={this.importQueueDelete}
          />

          <hr />

          <FileTypeGrid
            client={this.props.client}
            onAdd={this.showFileTypeForm}
            onDelete={this.fileTypeDelete}
          />

          <hr />

          <DocumentTypeGrid
            client={this.props.client}
            onAdd={this.showDocumentTypeForm}
            onDelete={this.documentTypeDelete}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="d-flex flex-column flex-grow-1">
        <h2>File Import Location / Queues and File Types</h2>
        {this.renderGrids()}
        {this.renderImportLocationForm()}
        {this.renderImportQueueForm()}
        {this.renderFileTypeForm()}
        {this.renderDocumentTypeForm()}
      </div>
    );
  }
}

// ownProps contains the url information function mapStateToProps(state, ownProps) {
function mapStateToProps(state) {
  let fileImportLocation = {
    id: '',
    importFileLocation: '',
    instance: '',
    isActive: ''
  };

  let fileImportQueue = {
    id: '',
    queueName: '',
    importFileType: '',
    fileWriteThresholdItems: '',
    fileWriteThresholdSeconds: '',
    fileNameTemplate: '',
    isInUse: ''
  };

  let fileType = {
    id: '',
    importSourceTypeId: '',
    importSourceType: '',
    importLocationId: '',
    importLocation: '',
    name: '',
    fileNameSpecification: '',
    fileExtension: '',
    regularExpressionMatch: '',
    resolutionOrder: '',
    schemaFileName: '',
    recordXPath: '',
    xmlElementName: '',
    destinationTableId: '',
    isActive: '',
    csvDelimiter: '',
    csvQuoted: '',
    csvHasHeaders: ''
  };

  let showGrid = {
    display: true
  };

  let refreshProcesses = [];

  if (state.fileImportLocations.length > 0) {
    fileImportLocation = lodash.last(state.fileImportLocations);
  }

  if (state.fileImportQueues.length > 0) {
    fileImportQueue = lodash.last(state.fileImportQueues);
  }

  if (state.fileTypes.length > 0) {
    fileType = lodash.last(state.fileTypes);
  }

  if (state.fileImportGridDisplay.length > 0) {
    showGrid = lodash.last(state.fileImportGridDisplay);
  }

  if (state.refreshProcesses.length > 0) {
    refreshProcesses = lodash.last(state.refreshProcesses);
  }

  return {
    permissions: state.authReducer.permissions,
    fileImportLocation: fileImportLocation,
    fileImportQueue: fileImportQueue,
    fileType: fileType,
    showGrid: showGrid,
    refreshProcesses: refreshProcesses,
    fileImportDocumentType: state.fileImportDocumentType
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(importConfigurationActions, dispatch),
    executeAuthAsyncGet: (client, serviceName, name, data, onSuccess, onError) => {
      dispatch(
        executeAuthAsyncGet(client, serviceName, name, data, onSuccess, onError)
      );
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileImporterConfigurationContainer);
