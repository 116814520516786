import { ProgressButton, ReadonlyFilters, Tab, Tabs, Table, useFeatureToggle, useApi, useClient, useConfig, Loading } from "invevo-react-components"
import CustomerActions from "./CustomerActions"
import classes from './CustomerActions.module.scss'
import { getFilters } from '../../../utils/Utils'

const WorkflowRunOutcomes = ({ workflowRun, actionSuccess }) => {
    const client = useClient()
    const api = useApi()
    const config = useConfig()

    const successActions = workflowRun.actions.filter(a => a.success === "SUCCESS")
    const faliedActions = workflowRun.actions.filter(a => a.success === "FAILED")
    const notMatchedActions = workflowRun.actions.filter(a => a.success === "NOT_MATCHED")
    const duplicates = getDuplicates(successActions.concat(faliedActions))
    const { isEnabled: outcomesAsTable } = useFeatureToggle("automateOutcomesAsTable")
    const columns = getDistinctHeaders()
    const rows = mapToCustomerResults(workflowRun.actions, columns)

    const getAppliedFilters = (rules) => {
        const inValues = rules.map(r => r.fieldsAreInValues).reduce((a, b) => [...a, ...b])
        const inRanges = rules.map(r => r.fieldsAreInNumberRanges).reduce((a, b) => [...a, ...b])
        const comparisons = rules.map(r => r.fieldsComparison).reduce((a, b) => [...a, ...b])

        return getFilters({ fieldsAreInValues: inValues, fieldsAreInNumberRanges: inRanges, fieldsComparison: comparisons })
            .map(filter => { return { ...filter, operator: filter.operator.value, field: filter.fieldName } })
    }

    const createWorkflowActionsFile = () => {
        const promise = new Promise((resolve, reject) => {
            api.post(`${config.AUTOMATE_API_URL}/api/${client}/create-workflow-run-actions-file/${workflowRun.runReference}`).then(_ => {
                downloadWorkflowActionsFile()
                resolve()
            })
                .catch(error => {
                    console.error(error)
                    reject()
                })
        })
        return promise
    }

    const downloadWorkflowActionsFile = () => {
        api.get(`${config.AUTOMATE_API_URL}/api/${client}/download-workflow-run-actions-csv/${workflowRun.runReference}`)
            .then(response => {
                var hiddenElement = document.createElement('a');
                hiddenElement.href = response.data;
                hiddenElement.click();
            })
            .catch(error => {
                console.error(error)
            })
    }

    return (
        <Loading isLoading={!config.AUTOMATE_API_URL} colour="blue">
            <div className="d-flex flex-column">
                <ReadonlyFilters title="Applied Action Filters:" rules={getAppliedFilters(workflowRun.actionFilters)} className="mb-3" />

                <div className={`accordion w-100 ${classes.container} p-3`} id="workflowRunCollapsableId">
                    <div className="accordion-body pt-3 pb-0 px-0 w-100">
                        {outcomesAsTable
                            ? <div className="d-flex flex-column w-100 h-100">
                                <div className={`flex-grow-1 overflow-auto ${classes["workflow-outcomes-result-table"]}`}>
                                    <Tabs className="flex-grow-1" bubbled={true}>
                                        <Tab title={`All (${workflowRun.actions.length})`} isActive={actionSuccess === "all"}>
                                            <Table
                                                columns={columns}
                                                rows={rows}
                                                filterable={true}
                                                url={`${config.AUTOMATE_API_URL}/api/${client}/filter-workflow-run-actions`}
                                                workflowRunReference={workflowRun.runReference}
                                                onlyDuplicateCustomers={false}
                                            />
                                        </Tab>
                                        <Tab title={`Successful (${successActions.length})`} isActive={actionSuccess === "successful"}>
                                            <Table
                                                columns={getFilteredDistinctHeaders()}
                                                rows={rows.filter(r => r.success === "SUCCESS")}
                                                filterable={true}
                                                url={`${config.AUTOMATE_API_URL}/api/${client}/filter-workflow-run-actions`}
                                                workflowRunReference={workflowRun.runReference}
                                                filters={[{ name: "success", value: "SUCCESS" }]}
                                                onlyDuplicateCustomers={false}
                                            />
                                        </Tab>
                                        <Tab title={`Failed (${faliedActions.length})`} isActive={actionSuccess === "failed"}>
                                            <Table
                                                columns={getFilteredDistinctHeaders()}
                                                rows={rows.filter(r => r.success === "FAILED")}
                                                filterable={true}
                                                url={`${config.AUTOMATE_API_URL}/api/${client}/filter-workflow-run-actions`}
                                                workflowRunReference={workflowRun.runReference}
                                                filters={[{ name: "success", value: "FAILED" }]}
                                                onlyDuplicateCustomers={false}
                                            />
                                        </Tab>
                                        <Tab title={`Not Matched (${notMatchedActions.length})`} isActive={actionSuccess === "notMatched"}>
                                            <Table
                                                columns={getFilteredDistinctHeaders()}
                                                rows={rows.filter(r => r.success === "NOT_MATCHED")}
                                                filterable={true}
                                                url={`${config.AUTOMATE_API_URL}/api/${client}/filter-workflow-run-actions`}
                                                workflowRunReference={workflowRun.runReference}
                                                filters={[{ name: "success", value: "NOT_MATCHED" }]}
                                                onlyDuplicateCustomers={false}
                                            />
                                        </Tab>
                                        <Tab title={`Duplicates (${duplicates.length})`} isActive={actionSuccess === "duplicates"}>
                                            <Table
                                                columns={getDistinctHeaders()}
                                                rows={getDuplicates(rows)}
                                                filterable={true}
                                                url={`${config.AUTOMATE_API_URL}/api/${client}/filter-workflow-run-actions`}
                                                workflowRunReference={workflowRun.runReference}
                                                onlyDuplicateCustomers={true}
                                            />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                            : <Tabs className="flex-grow-1" bubbled={true}>
                                <Tab title={`All (${workflowRun.actions.length})`} isActive={actionSuccess === "all"}>
                                    <CustomerActions
                                        actions={workflowRun.actions}
                                        success="all"
                                        isPreview={workflowRun.isPreview}
                                    />
                                </Tab>
                                <Tab title={`Successful (${successActions.length})`} isActive={actionSuccess === "successful"}>
                                    <CustomerActions
                                        actions={successActions}
                                        success="successful"
                                        isPreview={workflowRun.isPreview}
                                    />
                                </Tab>
                                <Tab title={`Failed (${faliedActions.length})`} isActive={actionSuccess === "failed"}>
                                    <CustomerActions
                                        actions={faliedActions}
                                        success="failed"
                                        isPreview={workflowRun.isPreview}
                                    />
                                </Tab>
                                <Tab title={`Not Matched (${notMatchedActions.length})`} isActive={actionSuccess === "notMatched"}>
                                    <CustomerActions
                                        actions={notMatchedActions}
                                        success="notMatched"
                                        isPreview={workflowRun.isPreview}
                                    />
                                </Tab>
                                <Tab title={`Duplicates (${duplicates.length})`} isActive={actionSuccess === "duplicates"}>
                                    <CustomerActions
                                        actions={duplicates}
                                        success="duplicates"
                                        isPreview={workflowRun.isPreview}
                                    />
                                </Tab>
                            </Tabs>
                        }
                    </div>
                </div>
                <ProgressButton
                    iconClasses="fal fa-file-csv"
                    label="Export action results"
                    className="w-25 pt-3"
                    onClickWithPromise={() => {
                        const promise = new Promise((resolve, reject) => {
                            createWorkflowActionsFile()
                                .then(_ => resolve(), _ => reject())
                                .catch(_ => reject())
                        })
                        return promise
                    }}
                />
            </div>
        </Loading>
    )
}

export default WorkflowRunOutcomes


const getDuplicates = (actionList) => {
    const customerRefList = actionList.map(a => a.customerReference)
    const duplicateCustomerRefs = customerRefList.filter((item, index) => customerRefList.indexOf(item) !== index)
    const duplicateActions = actionList.filter(a => duplicateCustomerRefs.includes(a.customerReference))
    return duplicateActions.sort((a, b) => a.customerReference.localeCompare(b.customerReference))
}

const getDistinctHeaders = () => [
    { fieldName: "customerReference", label: "accountcode" },
    { fieldName: "communicationType", label: "action" },
    { fieldName: "message", label: "message" },
    { fieldName: "success", label: "result" }
]

const getFilteredDistinctHeaders = () => [
    { fieldName: "customerReference", label: "accountcode" },
    { fieldName: "communicationType", label: "action" },
    { fieldName: "message", label: "message" }
]

const mapToCustomerResults = (actions, tableHeaders) => {
    return actions.map(a => {
        return {
            ...getColumnValues(a, tableHeaders)
        }
    })
}

const getColumnValues = (customerResult, tableHeaders) => {
    var obj = {}
    tableHeaders.forEach(header => {
        obj = { ...obj, [header.fieldName]: customerResult[header.fieldName] }
    })

    return obj
}
