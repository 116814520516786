import { Dropup } from "invevo-react-components"
import { PAGE_SIZE_OPTIONS } from "../../../../types/pageSizeOptions"
import classes from "./TransactionGridInfoBar.module.scss"
import PageNavButtons from "./PageNavButtons"
import { convertToCurrency } from "../../../../../../library/helpers/numberUtils"
import { useFeatureToggle } from "../../../../../../hooks/useFeatureToggle"

const TransactionGridInfoBar = ({
    currentPageSize,
    setCurrentPageSize,
    isFetchingGrid,
    selectedTransactionsSummary,
    transactionCount,
    pageIndex,
    onPageIndexUpdated
}) => {
    const { isEnabled: defaultToThousand } = useFeatureToggle("defaultTransactionGridTo1000")
    const options = defaultToThousand ? PAGE_SIZE_OPTIONS.concat([{ value: 1000, label: "1000" }]) : PAGE_SIZE_OPTIONS

    return (
        <div className={`${classes.footer} p-2`}>
            <div className="h-100 d-flex align-items-center">
                <span className="mx-2">
                    <strong>{selectedTransactionsSummary.count}</strong> transactions selected
                </span>
                <span className="mx-2">
                    Total value selected:{" "}
                    <strong>{selectedTransactionsSummary.totalByCurrency.map(a => `${convertToCurrency(a.value.toFixed(2), a.currency)} `)}</strong>
                </span>
                <div className="d-flex align-items-center ms-auto">
                    <Dropup
                        options={options}
                        onOptionSelected={a => setCurrentPageSize(a.value)}
                        selectedOption={{ label: currentPageSize, value: currentPageSize }}
                    />
                    <span className="ms-2">items per page</span>
                </div>
                <span className="mx-2">
                    {`Showing results: `}
                    <strong>{Math.min(pageIndex * currentPageSize + currentPageSize, transactionCount) === 0 ? 0 : pageIndex * currentPageSize + 1}</strong>
                    {` - `}
                    <strong>{Math.min(pageIndex * currentPageSize + currentPageSize, transactionCount)}</strong>
                    {` of `}
                    <strong>{transactionCount}</strong>
                </span>
                <PageNavButtons
                    onPageIndexUpdated={onPageIndexUpdated}
                    disabled={isFetchingGrid}
                    pageIndex={pageIndex}
                    totalPageNumber={Math.ceil(transactionCount / currentPageSize)}
                />
            </div>
        </div>
    )
}

export default TransactionGridInfoBar
