import classes from "./TeamPerformanceSummary.module.scss"

const TeamPerformanceSummary = ({ totalCallsMade, totalCallDurationInMinutes, totalUsers }) => {
    return (
        <div className={`d-flex flew-row pb-2 mb-tiny ${classes["container"]}`}>
            <div className="d-flex flex-column me-3 w-100">
                <h3 className={`d-flex align-items-center px-3 text-dark-grey w-100 ${classes["container-header"]}`}>
                    <i className="fa-light fa-xl fa-users me-3"></i>
                    Users
                </h3>
                <div className="d-flex flex-row m-2 justify-content-between">
                    <div className={`d-flex flex-column align-items-center justify-content-center ${classes["summary-item"]}`}>
                        <div className={`text-uppercase small-text ${classes["summary-item-label"]}`}>Users</div>
                        <h2 className="text-dark-grey">{totalUsers}</h2>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column w-100 me-3">
                <h3 className={`d-flex align-items-center px-3 text-dark-grey w-100 ${classes["container-header"]}`}>
                    <i className="fa-light fa-xl fa-sack-dollar me-3"></i>
                    Calls Made
                </h3>
                <div className="d-flex flex-row m-2 justify-content-between">
                    <div className={`d-flex flex-column justify-content-center align-items-center ${classes["summary-target"]} ${classes["blue"]}`}>
                        <div className={`text-uppercase ${classes["text"]}`}>Total</div>
                        <h2 className="text-dark-grey mb-0">{totalCallsMade}</h2>
                        <div className={`text-uppercase ${classes["text"]}`}>Calls made</div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column w-100">
                <h3 className={`d-flex align-items-center px-3 text-dark-grey w-100 ${classes["container-header"]}`}>
                    <i className="fa-light fa-xl fa-phone me-3"></i>
                    Call Duration
                </h3>
                <div className="d-flex flex-row m-2 justify-content-between">
                    <div className={`d-flex flex-column justify-content-center align-items-center ${classes["summary-target"]} ${classes["blue"]}`}>
                        <div className={`text-uppercase ${classes["text"]}`}>Total</div>
                        <h2 className="text-dark-grey mb-0">{totalCallDurationInMinutes}</h2>
                        <div className={`text-uppercase ${classes["text"]}`}>minutes</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamPerformanceSummary
