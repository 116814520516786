import { Route, Switch } from "react-router-dom"
import FileEntityConfiguration from "./FileEntityConfiguration"
import FileConfiguration from "./FileConfiguration"
import FileImportHistory from "./importHistory/FileImportHistory"
import IntegrationHome from "./IntegrationNav"
import IntegrationOptions from "./IntegrationOptions"
import { useFeatureToggle } from "../../../hooks/useFeatureToggle"

const IntegrationRoutes = () => {
    const { isEnabled: isNewDynamicDataStructure } = useFeatureToggle("newEntityStructure")

    return (
        <Switch>
            <Route path="/home/integration" exact render={() => <IntegrationHome />} />
            <Route
                path={`/home/integration/${IntegrationOptions.FILE_CONFIGS}`}
                render={() => (isNewDynamicDataStructure ? <FileEntityConfiguration /> : <FileConfiguration />)}
            />
            <Route path={`/home/integration/${IntegrationOptions.HISTORY}`} render={() => <FileImportHistory />} />
        </Switch>
    )
}

export default IntegrationRoutes
