import { padElementsWith } from "../helpers"
import classes from "./PillButtons.module.scss"

type PillButtonsProps = {
    children: JSX.Element[]
    className?: string
}

const PillButtons = ({ children, className = "" }: PillButtonsProps) => (
    <div className={`d-flex align-items-stretch ${classes.menuButtons} bg-grey ${className}`}>{padElementsWith(children, classes.buttonPadding)}</div>
)

export default PillButtons
