import React, { Component } from 'react';
import { connect } from 'react-redux';
import ValidatingInput from '../../common/ValidatingInput';

class AddPermission extends Component {
  constructor(props, context) {
    super(props, context);

    this.add = this.add.bind(this);
    this.cancel = this.cancel.bind(this);
    this.onUpperFinancialLimitChanged = this.onUpperFinancialLimitChanged.bind(
      this
    );
    this.onLowerFinancialLimitChanged = this.onLowerFinancialLimitChanged.bind(
      this
    );
    this.onPermissionChanged = this.onPermissionChanged.bind(this);
    this.renderPermissionLabel = this.renderPermissionLabel.bind(this);
    this.renderAvailablePermissions = this.renderAvailablePermissions.bind(
      this
    );
    this.renderFinancialLimitControls = this.renderFinancialLimitControls.bind(
      this
    );
    this.validateForm = this.validateForm.bind(this);
    this.isLimitsValid = this.isLimitsValid.bind(this);

    this.state = {
      permission: {
        permissionId: null,
        name: '',
        upperFinancialLimit: '',
        lowerFinancialLimit: '',
        isFinancialPermission: false
      },
      canAdd: false
    };
  }

  add() {
    if (this.props.onAdd != null) {
      this.props.onAdd(this.state.permission);
    }
  }

  cancel() {
    if (this.props.onCancel != null) {
      this.props.onCancel();
    }
  }

  onUpperFinancialLimitChanged(e) {
    const limit = e.target.value;
    this.setState(
      (previousState) => ({
        ...previousState,
        permission: {
          ...previousState.permission,
          upperFinancialLimit: limit
        }
      }),
      this.validateForm
    );
  }

  onLowerFinancialLimitChanged(e) {
    const limit = e.target.value;
    this.setState(
      (previousState) => ({
        ...previousState,
        permission: { ...previousState.permission, lowerFinancialLimit: limit }
      }),
      this.validateForm
    );
  }

  onPermissionChanged(value) {
    const p = this.props.addablePermissions.find((t) => t.Id === value);

    this.setState(
      (previousState) => ({
        ...previousState,
        permission: {
          ...previousState.permission,

          permissionId: p.Id,
          name: p.Name,
          upperFinancialLimit: p.IsFinancialPermission ? '' : '0',
          lowerFinancialLimit: p.IsFinancialPermission ? '' : '0',
          isFinancialPermission: p.IsFinancialPermission
        }
      }),
      this.validateForm
    );
  }

  validateForm() {
    let canAdd = this.state.permission.permissionId != null;
    if (this.state.permission.isFinancialPermission === true) {
      canAdd =
        this.state.permission.permissionId != null &&
        this.isLimitsValid();
    }
    this.setState({ canAdd: canAdd });
  }

  isLimitsValid() {
    const lower = parseFloat(this.state.permission.lowerFinancialLimit);
    const upper = parseFloat(this.state.permission.upperFinancialLimit);
    if (isNaN(lower) || isNaN(upper)) {
      return true; //Don't bother checking if user hasn't entered numbers yet, will be caught be standard validation
    }
    return upper > lower
      ? true
      : 'Upper limit must be greater than lower limit';
  }

  renderPermissionLabel() {
    let label = 'Select Permission ';
    if (this.state.permission) {
      label = 'Permission: ' + this.state.permission.name;
    }
    return label;
  }

  renderAvailablePermissions() {
    const permissions = this.props.addablePermissions.map((t) => {
      const key = 'permission' + t.Id;
      return (
        <li
          key={key}
          className="dropdown-item"
          onClick={this.onPermissionChanged.bind(this, t.Id)}
        >
          {t.Name}
        </li>
      );
    });
    return permissions;
  }

  renderFinancialLimitControls() {
    if (this.state.permission.isFinancialPermission === false) {
      return '';
    }
    return (
      <div>
        <div className="form-group">
          <ValidatingInput
            id="add-lower-financial-limit"
            name="add-lower-financial-limit"
            title="Lower Financial Limit"
            value={this.state.permission.lowerFinancialLimit}
            placeholder="Lower Financial Limit"
            validations="isNumeric,maxLength:14"
            hideLabel={true}
            validationError={'Enter a number less than 15 digits.'}
            onChange={this.onLowerFinancialLimitChanged}
            required
          />
        </div>
        <div className="form-group">
          <ValidatingInput
            id="add-upper-financial-limit"
            name="add-upper-financial-limit"
            title="Upper Financial Limit"
            value={this.state.permission.upperFinancialLimit}
            placeholder="Upper Financial Limit"
            hideLabel={true}
            validations={{
              isNumeric: true,
              maxLength: 14,
              customValidation: this.isLimitsValid
            }}
            validationError={'Enter a number less than 15 digits.'}
            onChange={this.onUpperFinancialLimitChanged}
            required
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="sub-form">
        <div className="form-group">
          <ul className="nav" id="addPermissionlist">
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                id="dropdownMenuButton"
                className="nav-link dropdown-toggle boldTitle noPaddingLeft"
                data-bs-toggle="dropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                {this.renderPermissionLabel()}
                <b className="caret"></b>
              </a>
              <ul aria-labelledby="dropdownMenuButton" className="dropdown-menu ddscroll">
                {this.renderAvailablePermissions()}
              </ul>
            </li>
          </ul>
        </div>
        {this.renderFinancialLimitControls()}
        <button
          type="button"
          onClick={this.add}
          className="btn btn-default"
          disabled={!this.state.canAdd}
        >
          Add Permission
        </button>
        <button type="button" onClick={this.cancel} className="btn btn-default">
          Cancel
        </button>
      </div>
    );
  }
}

export default connect()(AddPermission);
