import { useLocation } from 'react-router-dom';

export const useSearchQuery = () => {
  const { search } = useLocation();
  const searchMap = new URLSearchParams(search);

  const newSearch = searchMap.get('new');

  return !!newSearch;
};
