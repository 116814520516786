import { useClient } from 'invevo-react-components';
import React from 'react';
import FileImporterLogGrid from '../../legacy/components/admin/fileImporter/FileImporterLogGrid';

const FileImporterContainer = () => {
  const client = useClient()
  return (
    <div>
      <h3>Import Log</h3>
      <FileImporterLogGrid client={client} />
    </div>
  )
}

export default FileImporterContainer;