import classes from "./GridWidget.module.scss"
import { useState } from "react"
import { actionTypes } from "../../../reducers/actionTypes"
import { v4 as uuidv4 } from "uuid"
import EntityDataFieldDropdown from "../../../../../library/dropdowns/EntityDataFieldDropdown"
import Dropdown from "../../../../../library/dropdowns/Dropdown"
import GridColumnConfig from "./GridColumnConfig"
import FilterList from "../../../../../library/FilterList/FilterList"
import { GridWidgetColumnState, GridWidgetState } from "../../../types/DashboardConfigState"
import { DashboardConfigAction, DashboardConfigCustomerEntityConfig, DashboardConfigEntityConfig } from "../../../reducers/dashboardConfigsReducer"
import Lookup from "../../../../../types/Lookup"
import GenericFilter from "../../../../../library/FilterList/filterTypes/GenericFilter"
import EntityDataField from "../../../../../routes/entityConfig/types/EntityDataField"
import Radio from "../../../../../library/radio/Radio"
import { GridType } from "../../../types/DashboardConfigDto"
import Toggle from "../../../../../library/toggle/Toggle"
import DropdownOption from "../../../../../types/DropdownOptions"
import DataPrimitive from "../../../../../types/DataPrimitive"
import { getTypeFromEntityDataTypeEnum } from "../../../../../library/helpers/entityHelpers"

type GridWidgetProps = {
    className?: string
    widget: GridWidgetState
    dispatch: React.Dispatch<DashboardConfigAction>
    entityConfigs: DashboardConfigEntityConfig[]
    customerFields: DashboardConfigCustomerEntityConfig[]
    isAggregatedDashboard: boolean
    isNewEntityStructureEnabled: boolean
    editingConfigEntityReference: string
    lookups: Lookup[]
}
const GridWidget = ({
    className,
    widget,
    dispatch,
    entityConfigs,
    customerFields,
    isAggregatedDashboard,
    isNewEntityStructureEnabled,
    lookups,
    editingConfigEntityReference
}: GridWidgetProps) => {
    const [isAddingNewColumn, setIsAddingNewColumn] = useState(false)
    const [showFilters, setShowFilters] = useState(false)

    const entityConfigDropdownOptions = entityConfigs.map(config => {
        return { value: config.reference, label: config.displayName }
    })

    const onRemoveClick = () => {
        dispatch({ type: actionTypes.WIDGET_REMOVED, widget })
    }

    const onColumnRemoveClick = (column: GridWidgetColumnState) => {
        dispatch({ type: actionTypes.GRID_WIDGET_COLUMN_REMOVED, widget, column })
    }

    const onAddNewColumnClick = () => {
        setIsAddingNewColumn(true)
    }

    const onColumnNameChange = (option: EntityDataField | undefined, column: string) => {
        if (!option) {
            return
        }

        dispatch({ type: actionTypes.GRID_WIDGET_COLUMN_CHANGED, widget, newColumn: option, previousColumn: column })
    }

    const onMoveColumnUp = (columnIndex: number) => {
        dispatch({ type: actionTypes.GRID_WIDGET_COLUMN_MOVED_UP, widget, columnIndexToMove: columnIndex })
    }

    const onMoveColumnDown = (columnIndex: number) => {
        dispatch({ type: actionTypes.GRID_WIDGET_COLUMN_MOVED_DOWN, widget, columnIndexToMove: columnIndex })
    }

    const onNewColumnNameChange = (option: EntityDataField | undefined) => {
        if (!option) {
            return
        }

        setIsAddingNewColumn(false)
        dispatch({ type: actionTypes.GRID_WIDGET_NEW_COLUMN_ADDED, widget, newColumn: { id: uuidv4(), ...option } })
    }

    const onColumnEditableToggle = (column: GridWidgetColumnState) => {
        dispatch({
            type: actionTypes.GRID_WIDGET_COLUMN_CHANGED,
            widget,
            newColumn: {
                ...column,
                isEditable: !column.isEditable
            },
            previousColumn: column
        })
    }

    const onEntityConfigValueChange = (option: { value: string; label: string }) =>
        dispatch({ type: actionTypes.GRID_WIDGET_ENTITY_CONFIG_CHANGED, widget, entityConfigReference: option.value })

    const onFiltersChanged = (filters: GenericFilter[]) => dispatch({ type: actionTypes.GRID_WIDGET_FILTERS_CHANGED, widget, filters: filters })

    const entityConfigFields = entityConfigs.find(config => config.reference === widget.entityConfigReference)?.fields

    const fields = isNewEntityStructureEnabled ? entityConfigFields ?? [] : customerFields

    const getFilterFields = () => {
        if (!isNewEntityStructureEnabled) return customerFields

        return entityConfigFields ?? []
    }

    const toggleShowFilters = () => setShowFilters(!showFilters)

    const onTableSelected = () => dispatch({ type: actionTypes.GRID_WIDGET_TYPE_CHANGED, widget, gridType: GridType.TABLE })
    const onKanbanSelected = () => dispatch({ type: actionTypes.GRID_WIDGET_TYPE_CHANGED, widget, gridType: GridType.KANBAN })

    const onGroupingFieldChange = (option: EntityDataField | undefined) =>
        dispatch({ type: actionTypes.GRID_WIDGET_GROUPING_FIELD_CHANGED, widget, groupingField: option })

    const onDraggableToggle = () => dispatch({ type: actionTypes.GRID_WIDGET_DRAGGABLE_TOGGLED, widget })

    const sortFieldOptions = [
        ...(widget.columns.find(c => c.fieldName === "reference")
            ? []
            : [{ label: "Reference", value: { fieldName: "reference", dataPrimitive: DataPrimitive.TEXT } }]),
        ...(widget?.columns?.map(c => ({
            label: entityConfigFields?.find(f => f.fieldName === c.fieldName)?.displayName ?? c.fieldName,
            value: { fieldName: c.fieldName, dataPrimitive: getTypeFromEntityDataTypeEnum(c.dataType.type || c.dataType) }
        })) ?? [])
    ]

    const sortDirectionOptions: DropdownOption<"ASC" | "DESC">[] = [
        { label: "ASC", value: "ASC" },
        { label: "DESC", value: "DESC" }
    ]

    const onSortFieldChanged = (opt: DropdownOption<{ fieldName: string; dataPrimitive: DataPrimitive }>) =>
        opt &&
        dispatch({ type: actionTypes.ENITTY_CHART_WIDGET_SORT_FIELD_CHANGED, widget, fieldName: opt.value.fieldName, dataPrimitive: opt.value.dataPrimitive })

    const onSortDirectionChanged = (opt: DropdownOption<"ASC" | "DESC">) =>
        opt && dispatch({ type: actionTypes.ENITTY_CHART_WIDGET_SORT_DIR_CHANGED, widget, direction: opt.value })

    const filterUsedFields = (fields: EntityDataField[]) => {
        return fields.filter(f => !widget.columns.some(c => c.fieldName === f.fieldName))
    }

    return (
        <div className={`${className ? className : ""} d-flex flex-column ${classes.widget} bg-white p-2 text-grey overflow-auto`}>
            <div className="d-flex border-bottom pb-2">
                <div className="d-flex align-items-center">
                    <i className="fal fa-list me-2"></i>
                    <span className="text-grey flex-grow-1">Data</span>
                </div>
                {!isAggregatedDashboard && (
                    <div className="d-flex ms-3 align-items-center">
                        <span className="text-grey flex-grow-1">Entity Type:</span>
                        <Dropdown
                            ariaLabel="grid-entity-config-dropdown"
                            className="ms-2"
                            options={entityConfigDropdownOptions.filter(config => config.value !== editingConfigEntityReference)}
                            onOptionSelected={onEntityConfigValueChange}
                            selectedOption={entityConfigDropdownOptions.find(o => o.value === widget.entityConfigReference)}
                            textAlign="left"
                            colour="white"
                        />
                    </div>
                )}
                <Radio className="ms-3">
                    <Radio.Option onSelect={onTableSelected} isSelected={widget.type !== GridType.KANBAN}>
                        <i className="fal fa-table" />
                        <span className="ms-2">Table</span>
                    </Radio.Option>
                    <Radio.Option onSelect={onKanbanSelected} isSelected={widget.type === GridType.KANBAN}>
                        <i className="fal fa-square-kanban" />
                        <span className="ms-2">Kanban</span>
                    </Radio.Option>
                </Radio>
                {widget.type !== GridType.KANBAN && isNewEntityStructureEnabled && (
                    <div className="d-flex flex-row gap-2 ms-3 align-items-center ">
                        <span>Default Sort:</span>
                        <Dropdown
                            options={sortFieldOptions}
                            onOptionSelected={onSortFieldChanged}
                            selectedOption={sortFieldOptions.find(o => o.value.fieldName === widget.defaultSort?.fieldName)}
                            ariaLabel={"grid-sort-field-dropdown"}
                        />
                        <Dropdown
                            options={sortDirectionOptions}
                            onOptionSelected={onSortDirectionChanged}
                            selectedOption={
                                sortDirectionOptions.find(o => o.value === widget.defaultSort?.direction) || sortDirectionOptions.find(o => o.value === "ASC")
                            }
                            ariaLabel={"grid-sort-direction-dropdown"}
                        />
                    </div>
                )}
                <div
                    className={`d-flex ms-3 px-2 rounded pointer align-items-center text-blue ${classes["filter-button"]} ${
                        showFilters ? classes["selected-filter-button"] : ""
                    }`}
                    role="button"
                    aria-label="show-grid-filters"
                    onClick={toggleShowFilters}
                >
                    <i className="fal fa-filter me-1"></i>
                    Filters
                    <div className={`ms-2 px-2 bg-blue text-white ${classes["filter-count-label"]}`}>{widget.filters.length}</div>
                </div>
                <i className="fal fa-times ms-auto me-1 pointer" onClick={onRemoveClick}></i>
            </div>
            {showFilters && (
                <div className={`mt-2 p-3 rounded ${classes["filters-container"]}`} aria-label="filters-container">
                    <FilterList
                        fields={getFilterFields()}
                        lookups={lookups}
                        appliedFilters={widget.filters}
                        onFiltersApplied={onFiltersChanged}
                        autoApply={true}
                    />
                </div>
            )}
            {widget.type === GridType.KANBAN && (
                <div className="d-flex align-items-center mt-3 ms-2 gap-2" aria-label="pete">
                    Grouped by
                    <EntityDataFieldDropdown
                        selectedOption={[...fields].find(f => f.value === widget.groupingField)}
                        options={fields}
                        onOptionSelected={onGroupingFieldChange}
                        ariaLabel="grid-grouping-field-dropdown"
                    />
                </div>
            )}
            <div className="d-flex align-items-center mt-3 ms-2 gap-2">
                Draggable
                <Toggle status={widget.draggable} onStatusChanged={onDraggableToggle} ariaLabel="grid-draggable-toggle" />
            </div>
            <div className="d-flex flex-column mt-3 px-2">
                <div className="d-flex flex-column">
                    {widget.columns.map((column, index) => (
                        <GridColumnConfig
                            key={column.id}
                            index={index}
                            fields={fields}
                            column={column}
                            isFirst={index === 0}
                            isLast={index === widget.columns.length - 1}
                            isEditable={isNewEntityStructureEnabled}
                            onColumnEditableToggle={onColumnEditableToggle}
                            onColumnRemoveClick={onColumnRemoveClick}
                            onColumnNameChange={onColumnNameChange}
                            onMoveColumnUp={onMoveColumnUp}
                            onMoveColumnDown={onMoveColumnDown}
                        />
                    ))}
                    <div className="d-flex align-items-center">
                        <span className={`text-uppercase small mb-1 ${classes.label}`}>Add new column</span>
                        <i
                            className={`ms-auto fas fa-plus-circle pointer text-blue`}
                            aria-label="add-new-column-button"
                            role="button"
                            onClick={onAddNewColumnClick}
                        ></i>
                    </div>
                    {isAddingNewColumn && (
                        <EntityDataFieldDropdown
                            selectedOption={undefined}
                            className="flex-grow-1"
                            options={filterUsedFields(fields)}
                            onOptionSelected={onNewColumnNameChange}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default GridWidget
