import { useState } from "react"
import { GreyButton, Loading, useApi, useClient, useConfig } from "invevo-react-components"
import classes from "../creditReport/CreditReport.module.scss"
import { Document, Page } from "react-pdf"
import moment from "moment"
import { useCustomer } from "../../contexts/CustomerContext"
import BlueButton from "../../library/buttons/BlueButton/BlueButton"
import { useEntity } from "../../contexts/EntityContext"
import { useFeatureToggle } from "../../hooks/useFeatureToggle"
import useApiQuery from "../../hooks/useApiQuery"

const CreditReport = () => {
    const [isFetchingSafeNo, setIsFetchingSafeNo] = useState(false)
    const [hasFetchedSafeNo, setHasFetchedSafeNo] = useState(false)

    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [pdf, setPdf] = useState()
    const [numPages, setNumPages] = useState(null)
    const [hasReportFound, setHasReportFound] = useState(true)
    const { isEnabled: shouldUseSafeNo } = useFeatureToggle("creditSafeShouldUseSafeNo")
    const { isEnabled: allowApiUpdate } = useFeatureToggle("creditsafeApiUpdate")
    const { isEnabled: isEntityEnabled } = useFeatureToggle("newEntityStructure")

    const [showReport, setShowReport] = useState(false)

    const config = useConfig()
    const api = useApi()
    const client = useClient()
    const [customer] = useCustomer()
    const [entity] = useEntity()

    const creditSafeId = isEntityEnabled
        ? entity.entityFields.textFields.find(field => field.name === "text_externalcreditprovidercode")?.value
        : customer.data?.stringFields?.externalcreditprovidercode
    const reference = isEntityEnabled ? entity.reference : customer.reference

    const getReportBySafeNo = () => {
        if (isFetchingSafeNo || hasFetchedSafeNo) {
            return
        }

        if (creditSafeId !== undefined && creditSafeId !== "") {
            setIsFetchingSafeNo(true)
            api.get(`${config.INTEGRATION_API_URL}/api/${client}/safeno-companyid?companySafeNo=${creditSafeId}`)
                .then(response => {
                    const externalCode = response.data.companies[0].id
                    setHasFetchedSafeNo(true)
                    setIsFetchingSafeNo(false)

                    setIsFetching(true)
                    api.get(`${config.INTEGRATION_API_URL}/api/${client}/credit-report?companyId=${externalCode}`, { responseType: "blob" })
                        .then(response => {
                            setHasFetched(true)
                            setIsFetching(false)
                            setPdf(response.data)

                            setHasReportFound(true)
                        })
                        .catch(error => {
                            console.error(error)
                            setHasReportFound(false)
                        })
                })
                .catch(error => {
                    console.error(error)
                })
        } else {
            setHasReportFound(false)
        }
    }

    const getReportByCompanyId = () => {
        if (isFetching || hasFetched || isFetchingSafeNo) {
            return
        }

        const externalCode =
            customer?.data?.stringFields?.externalcreditprovidercode ||
            entity.entityFields?.textFields?.find(field => field.name === "text_externalcreditprovidercode")?.value

        if (externalCode) {
            setIsFetching(true)
            api.get(`${config.INTEGRATION_API_URL}/api/${client}/credit-report?companyId=${externalCode}`, { responseType: "blob" })
                .then(response => {
                    setHasFetched(true)
                    setIsFetching(false)
                    setPdf(response.data)

                    setHasReportFound(true)
                })
                .catch(error => {
                    console.error(error)
                    setHasReportFound(false)
                })
        } else {
            setHasReportFound(false)
        }
    }

    const downloadCreditReport = () => {
        var hiddenElement = document.createElement("a")
        hiddenElement.href = window.URL.createObjectURL(pdf)
        hiddenElement.download = `${customer.reference}_${moment().format("YYYY-MM-DD")}_credit_report.pdf`
        hiddenElement.click()
    }

    const requestCreditReport = () => {
        setShowReport(true)
        if (shouldUseSafeNo) getReportBySafeNo()
        else getReportByCompanyId()
    }

    const apiUpdateRequest = useApiQuery({
        url: `${config.INTEGRATION_API_URL}/api/${client}/credit-report-update`,
        method: "POST",
        dto: {
            entityType: entity.entityType ?? entity.entityTypeReference ?? "customer",
            reference,
            connectId: creditSafeId
        },
        isExecutedAutomatically: false
    })

    const requestApiUpdate = () => apiUpdateRequest.execute()

    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages)

    return (
        <div className={`d-flex flex-column py-3 ${classes.container}`}>
            <div className="d-flex flex-row justify-content-centre pb-3">
                <h2 className="text-white no-select px-10">Credit Report</h2>
                <GreyButton
                    className="me-3 ms-auto"
                    label="Download"
                    iconClasses="fa-solid fa-file-arrow-down"
                    onClick={downloadCreditReport}
                    disabled={!showReport}
                />
            </div>
            {(hasReportFound && showReport) || apiUpdateRequest.isFetching ? (
                <Loading isLoading={isFetchingSafeNo || isFetching || apiUpdateRequest.isFetching} colour="white">
                    <div className="d-flex flex-grow-1 justify-content-center">
                        <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                            {Array.from(new Array(numPages), (_, index) => <Page key={index} pageNumber={index + 1} />).reduce((prev, curr) => [
                                prev,
                                <hr />,
                                curr
                            ])}
                        </Document>
                    </div>
                </Loading>
            ) : showReport ? (
                <div>
                    {" "}
                    Error: externalcreditprovidercode not found, please provide a connectID for this customer within the externalcreditprovidercode field.{" "}
                </div>
            ) : (
                <div className="d-flex flex-column gap-2 w-100">
                    <BlueButton
                        className={`${classes.requestButton}`}
                        label="Request PDF Report"
                        ariaLabel="requestReport"
                        iconClasses="fa-solid fa-mailbox"
                        onClick={requestCreditReport}
                    />
                    {allowApiUpdate && (
                        <BlueButton
                            className={`${classes.requestButton}`}
                            label="Request Data Update"
                            ariaLabel="requestDataUpdate"
                            iconClasses="fa-solid fa-mailbox"
                            onClick={requestApiUpdate}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default CreditReport
