
const InformationPanel = ({
    firstDataLabel,
    firstDataValue,
    secondDataLabel,
    secondDataValue,
}) => {

    return (
        <div className="d-flex">
            <div className="d-flex flex-column">
                <span className="text-uppercase text-light-blue">{firstDataLabel}</span>
                <span className="text-white">{firstDataValue}</span>
            </div>
            <div className="d-flex flex-column ms-auto">
                <span className="text-uppercase text-light-blue">{secondDataLabel}</span>
                <span className="text-white">{secondDataValue}</span>
            </div>
        </div>
    )
}


export default InformationPanel