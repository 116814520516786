import React from 'react';
import classes from './creditLimit.module.scss'
const RenderRow = ({
  leftContent,
  rightContent,
  leftWidth = 5,
  rightWidth = 7
}) => (
  <div className={`${classes['form-row']} align-items-center top10`}>
    <div className={'col-' + leftWidth}>{leftContent}</div>
    <div className={'col-' + rightWidth}>{rightContent}</div>
  </div>
);

export default RenderRow;
