import classes from "./InfoCard.module.scss"
import DropdownOption from "../../types/DropdownOptions"
import InfoCardFields from "./InfoCardFields"

export type InfoCardData = {
    id: string
    fieldName: string
    label: string
    value: string | number | boolean
    style: string
    type: string
    isEditable: boolean
    dateFormat?: string
    timezone?: string
    culture?: string
    currency?: string
    lookupValueList?: DropdownOption<string>[]
}

type InfoCardProps = {
    className?: string
    title: string
    theme: string
    data: InfoCardData[]
    onCustomerUpdate?: (dataToUpdate: InfoCardData, newValue: string) => void
}

const InfoCard = ({ className = "", title, theme, data, onCustomerUpdate }: InfoCardProps) => {
    if (theme !== "blue" && theme !== "white") {
        throw Error("Theme should be blue or white")
    }

    return (
        <div
            role="group"
            aria-label={title}
            className={`d-flex flex-column gap-1 p-3 overflow-auto rounded fit-content ${className} ${classes[`widget-${theme}`]}`}
        >
            <span className={`fs-5 text-uppercase d-block mb-2 ${theme === "blue" ? "text-white" : "text-grey"}`}>{title}</span>
            {data.map(row => (
                <InfoCardFields key={row.id} data={row} theme={theme} onCustomerUpdate={onCustomerUpdate} />
            ))}
        </div>
    )
}

export default InfoCard
