import { useEffect } from "react"

type MakeTotalProcessingPaymentProps = {
    checkoutId: string
    providerUrl: string
    redirectUrl: string
}

const MakeTotalProcessingPayment = ({ checkoutId, providerUrl, redirectUrl }: MakeTotalProcessingPaymentProps) => {
    useEffect(() => {
        const jqueryScript = document.createElement("script")
        jqueryScript.src = "https://code.jquery.com/jquery.js"
        jqueryScript.type = "text/javascript"
        document.body.appendChild(jqueryScript)

        const optionsScript = document.createElement("script")
        optionsScript.text = `
            var wpwlOptions = {
              locale: "en",
              style:"card", 
              onReady: function(e){
                $('.wpwl-form-card').find('.wpwl-button-pay').on('click', function(e){
                  validateHolder(e);
                });
              },
              onBeforeSubmitCard: function(e){
                return validateHolder(e);
              }
            }
            function validateHolder(e){
              var holder = $('.wpwl-control-cardHolder').val();
              if (holder.trim().length < 2){
                $('.wpwl-control-cardHolder').addClass('wpwl-has-error').after('<div class="wpwl-hint wpwl-hint-cardHolderError">Invalid card holder</div>');
                return false;
              }
              return true;
            }
          `
        document.body.appendChild(optionsScript)

        const paymentWidgetsScript = document.createElement("script")

        paymentWidgetsScript.src = `${providerUrl}/v1/paymentWidgets.js?checkoutId=${checkoutId}`
        paymentWidgetsScript.async = true
        document.body.appendChild(paymentWidgetsScript)

        return () => {
            document.body.removeChild(jqueryScript)
            document.body.removeChild(optionsScript)
            document.body.removeChild(paymentWidgetsScript)
        }
    }, [checkoutId, providerUrl])

    return <form action={redirectUrl} className="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
}

export default MakeTotalProcessingPayment
