import { injectIntl } from 'react-intl';
import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { connect } from 'react-redux';
import Dropdown from '../../common/dropdown';
import { isNil } from 'lodash';
import { getApiUrl } from '../../../constants';
import {
  showToastSuccessMessage,
  showToastErrorMessage
} from '../../../api/toasterApi';
import {
  addFileContentToRequest,
  addValueToRequest
} from '../../../utility/upload';
import { webApiInterface } from '../../../api/webApiInterface';
import { executeAuthAsyncGet } from '../../../utility/asyncSupport';

class AddDocument extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      documentId: !props.postDocument ? null : props.postDocument.id,
      languageId: !props.postDocument ? null : props.postDocument.languageId,
      originalName: !props.postDocument
        ? null
        : props.postDocument.originalName,
      template: !props.postDocument ? null : props.postDocument.template,
      showFileInput: true,
      brandId: null,
      fileType: null,
      languages: this.props.languages
    };
  }

  onCancel = (e) => {
    e.preventDefault();
    this.props.onCancel();
  };

  onFormSubmit = () => {
    const that = this;
    this.setState({ canUpload: false });
    const { templateName } = this.props;
    const {
      template,
      originalName,
      fileType,
      languageId,
      documentId,
      brandId
    } = this.state;

    const reader = new FileReader();

    reader.onload = (e) => {
      const fileContents = e.currentTarget.result;
      if (fileContents.length > 9 * 1024 * 1024) {
        showToastErrorMessage('File is too large to upload.');
        return;
      }
      if (fileContents.length === 0) {
        showToastErrorMessage('File has no content.');
        return;
      }

      const templateId = this.props.decideTemplate(brandId, templateName).id;

      const data =
        addFileContentToRequest(
          'documentUploader',
          originalName,
          fileType,
          fileContents
        ) +
        (!!documentId ? addValueToRequest('documentId', documentId) : '') +
        addValueToRequest('templateId', templateId) +
        addValueToRequest('originalName', originalName) +
        addValueToRequest('languageId', languageId) +
        '--blob--';

      const XHR = new XMLHttpRequest();

      XHR.onreadystatechange = () => {
        document.forms.addDocForm.reset();

        if (XHR.readyState === 4) {
          if (XHR.status === 200) {
            showToastSuccessMessage('Uploaded.');
            that.props.onUpdated();
          } else {
            const err = JSON.parse(XHR.response);
            showToastErrorMessage(
              'Error uploading file: ' +
              originalName +
              '. Error: ' +
              err.Message || XHR.statusText
            );
          }
        }
      };

      XHR.open('POST', getApiUrl(this.props.client) + 'api/post/savefile');
      XHR.setRequestHeader(
        'Content-Type',
        'multipart/form-data; boundary=blob'
      );
      XHR.setRequestHeader('Access-Control-Allow-Origin', '*');
      that.props.executeAuthAsyncGet(
        this.props.client,
        'Lookup/OK',
        'CHECK_OK',
        null,
        function () {
          webApiInterface.applySecurity(XHR);
          XHR.send(data);
        }
      );
    };
    reader.readAsBinaryString(template);
  };

  onDdlChangedLanguageId(value) {
    this.setState({
      languageId: value
    });
  }

  onChangedBrandId(value) {
    this.setState({
      brandId: value
    });
    const template = this.props.decideTemplate(value, this.props.templateName);
    const existingDocLanguages = template.documents.map(d => d.languageId);
    const addableLanuages = this.props.languages.filter(
      (l) => !existingDocLanguages.some((d) => d === l.Id));
    this.setState({ languages: addableLanuages });
  }

  changeTemplate = () => {
    this.setState({ showFileInput: true });
  };

  onChangeTemplateFile = (e) => {
    if (e.target.files.length > 0) {
      this.setState({
        template: e.target.files[0],
        originalName: e.target.files[0].name,
        fileType: e.target.files[0].type
      });
    }
  };

  canSaveForm() {
    const haslanguage =
      !isNil(this.state.languageId) && this.state.languageId > -1;
    const hasDoc = !isNil(this.state.template);
    const hasBrand = !isNil(this.state.brandId);
    return haslanguage && hasDoc && hasBrand;
  }

  render() {
    return (
      <div id="add-doc-container">
        <h5>
          <strong>Add Template Document</strong>
        </h5>
        <Formsy
          id="addDocForm"
          onSubmit={this.onFormSubmit}
          ref={(me) => (this.postForm = me)}
        >
          <div className="form-group">
            <Dropdown
              title="Brand"
              idProp="Id"
              textProp="Name"
              selectedItemId={this.state.brandId}
              data={this.props.brands}
              onClick={(i) => this.onChangedBrandId(i)}
              required={true}
            />
          </div>
          <div className="form-group">
            <Dropdown
              title="Language"
              idProp="Id"
              textProp="Description"
              selectedItemId={this.state.languageId}
              data={this.state.languages}
              onClick={(i) => this.onDdlChangedLanguageId(i)}
              required={true}
            />
          </div>

          {!this.state.showFileInput ? (
            <div className="form-group">
              <p className="help-block">
                Current Template:{' '}
                {!this.state.originalName ? (
                  <i>None</i>
                ) : (
                  this.state.originalName
                )}{' '}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="link" onClick={this.changeTemplate}>
                  Change template
                </a>
              </p>
            </div>
          ) : (
            <div className="form-group">
              <input
                type="file"
                id="documentUploader"
                name="documentUploader"
                className="btn btn-default"
                onChange={this.onChangeTemplateFile}
                accept=".doc, .docx"
              />
            </div>
          )}

          <div className="form-group">
            <button
              className="btnDefault btn btn-default"
              type="submit"
              disabled={!this.canSaveForm()}
            >
              Save Document
            </button>
            <button
              className="btnDefault btn btn-default"
              type="button"
              onClick={this.onCancel}
            >
              Cancel
            </button>
          </div>
        </Formsy>
      </div>
    );
  }
}

export default injectIntl(
  connect(null, {
    executeAuthAsyncGet
  })(AddDocument)
);
