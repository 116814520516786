import DataField from "./DataField"
import { actionTypes } from "../../reducers/actionTypes"
import { memo } from 'react'

const MemoizedTransactionDataField = memo(({ dataField, dispatch, lookups }) => {
    const onDataFieldChange = updatedDataField =>
        dispatch({
            type: actionTypes.DATA_TRANSACTION_FIELD_UPDATED,
            updatedDataField,
        })
    
    const onDataFieldRemoved = (id) => dispatch({type: actionTypes.DATA_TRANSACTION_FIELD_REMOVED, id})

    return(
        <DataField
            dataField={dataField}
            onDataFieldChange={onDataFieldChange}
            onDataFieldRemoved={onDataFieldRemoved}
            lookups={lookups}
            />
    )
})

export default MemoizedTransactionDataField