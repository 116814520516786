import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLookup } from '../../api/lookupApi';
import StatefulSelect from '../common/statefulSelect';
import { findIndex } from 'lodash';
import { useClient } from 'invevo-react-components';

const DesisionReasonSelect = ({
  id,
  onChange,
  getLookup,
  reasons,
  decisionId
}) => {
  const options = reasons.filter((u) => decisionId === u.decisionId);
  const validId = findIndex(options, (item) => item.value === id) !== -1;
  const client = useClient()
  useEffect(() => {
    if (!reasons || reasons.length === 0)
      getLookup(client, 'lookup/ReasonsForDecision', 'reasonsForDecision');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!validId) onChange(null);
  }, [decisionId, validId, onChange]);

  return <StatefulSelect options={options} optionId={id} onChange={onChange} />;
};

DesisionReasonSelect.propTypes = {
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  decisionId: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
  reasons: state.lookupReducer.reasonsForDecision.results
    .filter((u) => u.Item4)
    .map((u) => ({ value: u.Item1, label: u.Item3, decisionId: u.Item2 }))
    .sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    })
});
export default connect(mapStateToProps, { getLookup })(DesisionReasonSelect);
