import React, { Component } from 'react';
import {
  Editor,
  EditorUtils,
  EditorTools,
  ProseMirror
} from '@progress/kendo-react-editor';
import { extendTableNodes } from './schemaNodes';

const {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignRight,
  AlignCenter,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  Link,
  Unlink,
  InsertImage,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  FormatBlock,
  FontName,
  FontSize,
  ViewHtml
} = EditorTools;

const { Schema, EditorView, EditorState } = ProseMirror;

class EmailEditor extends Component {
  constructor(props) {
    super(props);

    this.editorRef = null;
  }

  onExecute = ({ transaction, state }) => {
    const { doc, selection } = transaction;

    if (doc.eq(state.doc)) return;

    if (this.props.onChange) {
      const nextState = EditorState.create({
        doc,
        selection
      });

      const editorValue = EditorUtils.getHtml(nextState);

      this.props.onChange.call(undefined, editorValue);
    }
  };

  onMount = (event) => {
    const { viewProps } = event;
    const schema = viewProps.state.schema;
    const plugins = viewProps.state.plugins.filter(
      (p) => p.key.indexOf('selectingCells') !== 0
    );
    const tableNodes = extendTableNodes();
    const marks = schema.spec.marks;

    // update built-in schema nodes
    let nodes = schema.spec.nodes;
    for (const nodeName in tableNodes) {
      if (nodeName) {
        nodes = nodes.update(nodeName, tableNodes[nodeName]);
      }
    }
    const mySchema = new Schema({ nodes, marks });

    // Create an empty document to load the schema.
    const doc = EditorUtils.createDocument(mySchema, '');

    // Return the custom EditorView object that will be used by Editor.
    return new EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state: EditorState.create({ doc, plugins })
      }
    );
  };

  setHtml = (content) => {
    if (!this.editorRef.view) return;

    const view = this.editorRef.view;
    EditorUtils.setHtml(view, content);
  };

  getHtml = () => {
    if (!this.editorRef.view) return '';

    const view = this.editorRef.view;
    const content = EditorUtils.getHtml(view.state);
    return content;
  };

  render() {
    return (
      <Editor
        ref={(editor) => (this.editorRef = editor)}
        onExecute={this.onExecute}
        contentStyle={{ height: 600 }}
        tools={[
          [Bold, Italic, Underline],
          [Undo, Redo],
          [Link, Unlink],
          [AlignLeft, AlignCenter, AlignRight],
          [OrderedList, UnorderedList, Indent, Outdent],
          [Link, Unlink, InsertImage],
          [InsertTable],
          [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
          [DeleteRow, DeleteColumn, DeleteTable],
          [ViewHtml],
          FontSize,
          FontName,
          FormatBlock
        ]}
        defaultEditMode="div"
        onMount={this.onMount}
      />
    );
  }
}

export { EmailEditor };
