import Filter from "../../../../library/FilterList/Filter"
import GenericFilter from "../../../../library/FilterList/filterTypes/GenericFilter"
import DataField from "../../../../types/DataField"
import Lookup from "../../../../types/Lookup"
import classes from "./ScheduledTriggerNode.module.scss"

type FiltersNodeProps = {
    filterType: "base" | "action"
    filters: GenericFilter[]
    fields: DataField[]
    lookups: Lookup[]
}

const FiltersNode = ({ filterType, filters, fields, lookups }: FiltersNodeProps) => {
    const emptyFunc = () => {}

    return (
        <div className="bg-grey p-2 border-top border-4 rounded-1 border-warning">
            <div className="d-flex align-items-center gap-2 me-3">
                <i className="fal fa-filter text-blue fs-5" />
                <span className="text-black fw-bolder fs-5 text-capitalize">{filterType === "base" ? "Evaluation" : "Action"} Filters</span>
            </div>
            <div className={`${classes.separator} d-flex flex-row my-2 bg-dark-gradient`} />
            <div className="d-flex flex-grow-1 flex-column gap-2">
                {filters.map(filter => (
                    <Filter
                        key={filter.id}
                        filter={filter}
                        fields={fields}
                        lookups={lookups}
                        disabled={true}
                        onFilterUpdated={emptyFunc}
                        onFilterDeleted={emptyFunc}
                        showFieldType={false}
                        textOnly={true}
                    />
                ))}
            </div>
        </div>
    )
}

export default FiltersNode
