import { Loading } from "invevo-react-components"
import { FileType } from "../../../types/FileImportConfig"
import DataField from "../../../../../types/DataField"
import FieldType from "../../../../../types/enums/FieldType"
import { FileExportConfigAction, FileExportConfigActionEnum, FileExportConfigState } from "../../../reducers/FileExportConfigReducer"
import ExportFileConfig from "./ExportFileConfig"
import ExportFieldConfigs from "./exportFieldConfig/ExportFieldConfigs"
import { useMemo } from "react"
import FilterList from "../../../../../library/FilterList/FilterList"
import GenericFilter from "../../../../../library/FilterList/filterTypes/GenericFilter"

type FileExportConfigSetupProps = {
    state: { editingConfig: FileExportConfigState; dataFields: DataField[] }
    dispatch: React.Dispatch<FileExportConfigAction>
}

const FileExportConfigSetup = ({ state, dispatch }: FileExportConfigSetupProps) => {
    const getDataFields = useMemo(() => {
        return state.dataFields.filter(df => {
            if (state.editingConfig.fileType === FileType.CUSTOMER) {
                return df.fieldType === FieldType.CUSTOMER
            }

            return true
        })
    }, [state.editingConfig.fileType, state.dataFields])

    const onFiltersChanged = (filters: GenericFilter[]) => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            dataFileExportConfig: {
                ...state.editingConfig.dataFileExportConfig,
                filters
            }
        })
    }

    return (
        <Loading isLoading={!state} colour="blue">
            <div className="d-flex flex-column w-100 h-100 overflow-auto">
                <ExportFileConfig config={state.editingConfig} dispatch={dispatch} />
                {state.editingConfig.fileType === FileType.CUSTOMER || state.editingConfig.fileType === FileType.TRANSACTION ? (
                    <div className="d-flex flex-column">
                        <div className="d-flex bg-grey p-2 mt-2">
                            <FilterList
                                key={state.editingConfig.reference}
                                fields={getDataFields}
                                lookups={[]}
                                appliedFilters={state.editingConfig.dataFileExportConfig.filters}
                                onFiltersApplied={onFiltersChanged}
                                autoApply={true}
                                showFieldType={true}
                            />
                        </div>
                        <ExportFieldConfigs
                            state={{
                                fieldConfigs: state.editingConfig.dataFileExportConfig.optionalFieldConfigs,
                                dataFields: getDataFields,
                                hasHeaderRow: state.editingConfig.dataFileExportConfig.hasHeaderRow
                            }}
                            dispatch={dispatch}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </Loading>
    )
}

export default FileExportConfigSetup
