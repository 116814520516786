import { lowerCaseKeys } from "../../library/helpers/objectHelpers"
import DataPrimitive from "../../types/DataPrimitive"
import Lookup from "../../types/Lookup"

type EntityField = {
    fieldName: string
    dataType: {
        lookupReference?: string
    }
}

export const filterLookupEntriesByParent = (lookups: Lookup[], entityConfigFields: EntityField[], entityTextFields: Record<string, string>) => {
    const filteredLookups = lookups.map(lookup => {
        const potentialParentLookupNames = lookup.columns
            .filter(column => column.dataPrimitive === DataPrimitive.TEXT && column.name !== "name" && column.name !== "reference" && column.name !== "isinuse")
            .map(column => column.name.toLowerCase())

        const parentLookups = lookups
            .filter(lookup => potentialParentLookupNames.includes(lookup.name.toLowerCase()))
            .map(lookup => lookup.reference.toLowerCase())

        const parentLookupEntityFieldValues = parentLookups
            .map(parentLookup => {
                const fieldForThisParentLookup = entityConfigFields.find(fieldConfig => fieldConfig.dataType.lookupReference?.toLowerCase() === parentLookup)

                if (fieldForThisParentLookup === undefined) return undefined

                return {
                    parentLookup,
                    entityValue: entityTextFields[fieldForThisParentLookup.fieldName.toLowerCase()]?.toLowerCase()
                }
            })
            .filter(Boolean)

        const filteredEntries = lookup.entries.filter(entry =>
            parentLookupEntityFieldValues.every(parentValue => {
                const strings = lowerCaseKeys(entry.strings ?? {})
                return strings[parentValue.parentLookup]?.toLowerCase() === parentValue.entityValue?.toLowerCase()
            })
        )

        const filteredLookup: Lookup = {
            ...lookup,
            entries: filteredEntries
        }

        return filteredLookup
    })

    return filteredLookups
}
