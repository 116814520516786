import classes from "./Input.module.scss"

type InputProps = {
    className?: string
    placeholder?: string
    value: string
    onChange?: (value: string) => void
    onPaste?: (value: string) => void
    onKeyDown?: (evt: React.KeyboardEvent<HTMLInputElement>) => void
    onClick?: () => void
    isValid?: boolean
    disabled?: boolean
    ariaLabel?: string
    type?: "text" | "number"
    rounding?: "full" | "left" | "right" | "none"
}

const Input = ({
    className = "",
    placeholder,
    value,
    onChange,
    onPaste,
    onKeyDown,
    onClick,
    isValid = true,
    disabled = false,
    ariaLabel = "",
    type = "text",
    rounding = "full"
}: InputProps) => {
    const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) onChange(e.target.value)
    }

    const onInputPasted = (e: React.ClipboardEvent<HTMLInputElement>) => {
        if (onPaste) {
            onPaste(e.clipboardData.getData("text/plain"))
            e.preventDefault()
        }
    }

    const getRoundingClassName = () => {
        if (rounding === "full") return classes.rounded
        if (rounding === "left") return classes.roundedLeft
        if (rounding === "right") return classes.roundedRight
        return ""
    }

    return (
        <input
            placeholder={placeholder}
            disabled={disabled}
            className={`${className} ${getRoundingClassName()} form-control ${classes.input} ${isValid ? "" : `${classes.invalid}`}`}
            value={value}
            onChange={onInputChanged}
            onPaste={onInputPasted}
            onKeyDown={onKeyDown}
            onClick={onClick}
            aria-label={ariaLabel}
            type={type}
        />
    )
}

export default Input
