import { getApiUrl } from '../constants';
import { webApiInterface } from './webApiInterface';

export function getFileType(client, fileTypeId, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/file-type?importFileTypeId=' + fileTypeId;

    return webApiInterface.authFetch(client, url, dispatch)
        .then(function (response) {
            return response.json();
        });
}

export function updateFileType(client, fileType, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/file-type/update';

    return webApiInterface.authPost(client, url, dispatch, fileType, 'POST');
}

export function createFileType(client, fileType, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/file-type/create';

    return webApiInterface.authPost(client, url, dispatch, fileType, 'POST');
}

export function deleteFileType(client, fileTypeId, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/file-type/delete';

    return webApiInterface.authDelete(client, url, dispatch, fileTypeId);
}