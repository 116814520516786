import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';

const Drawer = ({ name, onClick, title, children }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
    if (onClick) onClick();
  };

  return (
    <div className="border border-primary rounded mt-2">
      <h4 onClick={handleClick} className="py-2 px-3 m-0 d-flex pointer">
        {title}
        <i className={`ms-auto fa-regular ${open ? 'fa-minus' : 'fa-plus'}`}></i>
      </h4>
      <Collapse in={open}>
        <div id={name} className="py-1 px-3">
          {children}
        </div>
      </Collapse>
    </div>
  );
};

Drawer.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  title: PropTypes.string
};

export default Drawer;
