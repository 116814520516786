const DropdownOption = ({ option, lastLabel, onOptionSelected }) => {

    const onOptionSelectedClicked = () => onOptionSelected(option)

    const getMatchedOptionValue = optionValue => {
        const optionValueUppercased = optionValue.toUpperCase()
        const matchingIndex = optionValueUppercased.indexOf(lastLabel.toUpperCase())
        const firstPart = optionValueUppercased.substring(0, matchingIndex)
        const matchingPart = optionValueUppercased.substring(matchingIndex, matchingIndex + lastLabel.length)
        const lastPart = optionValueUppercased.substring(matchingIndex + lastLabel.length, optionValueUppercased.length)

        return <span>{firstPart}<strong>{matchingPart}</strong>{lastPart}</span>
    }

    return (
        <div className="d-flex p-2 pointer" role="button" aria-label={`${option.value}-reference`} onClick={onOptionSelectedClicked}>
            <span
                className="d-block w-100 text-grey text-truncate"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom">
                {getMatchedOptionValue(option.value)}
            </span>
        </div>
    )
}

export default DropdownOption