import { fieldDataTypes } from "../../dashboard/enums/fieldDataTypes"
import { dataPrimitive } from "../enums/dataPrimitive"
import { actionTypes } from "./actionTypes"
import { v4 as uuidv4 } from 'uuid'

export default function dataConfigurationReducer(state, action) {
    switch (action.type) {
        case actionTypes.DATA_FIELDS_RETRIEVED:
            const customerDataFields = action.dataFields.filter(field => field.fieldType === fieldDataTypes.CUSTOMER).map((field) => (
                {
                    ...field,
                    id: uuidv4(),
                    editable: false
                }
            ))
            const transactionsDataFields = action.dataFields.filter(field => field.fieldType === fieldDataTypes.TRANSACTION).map((field) => (
                {
                    ...field,
                    id: uuidv4(),
                    editable: false
                }
            ))
            return {
                ...state,
                originalCustomerDataFields: customerDataFields,
                updatedCustomerDataFields: customerDataFields,
                originalTransactionDataFields: transactionsDataFields,
                updatedTransactionDataFields: transactionsDataFields
            }

        case actionTypes.LOOKUPS_RETRIEVED:
            const fields = action.lookups.map((field) => (
                {
                    ...field,
                    id: uuidv4(),
                    editable: false,
                    fields: field.columns.map((column) => ({ field: column.name, dataPrimitive: column.dataPrimitive, editable: false, id: uuidv4() })),
                    columns: undefined,
                    entries: field.entries.map((e) => ({ ...e, editable: false, id: uuidv4() }))
                }
            ))
            return {
                ...state,
                originalLookupDataFields: fields,
                updatedLookupDataFields: fields
            }

        case actionTypes.DATA_FIELDS_SAVED:
            const updatedCustomerDataFields = state.updatedCustomerDataFields.map(f => ({ ...f, editable: false }))
            const updatedTransactionDataFields = state.updatedTransactionDataFields.map(f => ({ ...f, editable: false }))
            const updatedLookupDataFields = state.updatedLookupDataFields.map(l => (
                {
                    ...l,
                    editable: false,
                    fields: l.fields.map(f => ({ ...f, editable: false })),
                    entries: l.entries.map(e => ({ ...e, editable: false }))
                }))

            return {
                ...state,
                originalCustomerDataFields: updatedCustomerDataFields,
                updatedCustomerDataFields,
                originalTransactionDataFields: updatedTransactionDataFields,
                updatedTransactionDataFields,
                originalLookupDataFields: updatedLookupDataFields,
                updatedLookupDataFields
            }

        case actionTypes.DATA_CUSTOMER_FIELD_UPDATED:
            {
                var customerFieldIndex = state.updatedCustomerDataFields.findIndex((f) => f.id === action.updatedDataField.id)

                if (customerFieldIndex === -1) {
                    customerFieldIndex = state.newCustomerDataFields.findIndex((f) => f.id === action.updatedDataField.id)
                    return {
                        ...state,
                        newCustomerDataFields: [
                            ...state.newCustomerDataFields.slice(0, customerFieldIndex),
                            action.updatedDataField,
                            ...state.newCustomerDataFields.slice(customerFieldIndex + 1)
                        ]
                    }
                } else {
                    return {
                        ...state,
                        updatedCustomerDataFields: [
                            ...state.updatedCustomerDataFields.slice(0, customerFieldIndex),
                            action.updatedDataField,
                            ...state.updatedCustomerDataFields.slice(customerFieldIndex + 1)
                        ]
                    }
                }
            }

        case actionTypes.DATA_CUSTOMER_FIELDS_UPLOADED:
            {
                return {
                    ...state,
                    updatedCustomerDataFields: [
                        ...state.updatedCustomerDataFields.filter(df => !action.dataFields.some(ndf => ndf.fieldName === df.fieldName)),
                        ...action.dataFields
                    ]
                }
            }

        case actionTypes.DATA_TRANSACTION_FIELD_UPDATED:
            {
                var transactionFieldIndex = state.updatedTransactionDataFields.findIndex((f) => f.id === action.updatedDataField.id)

                if (transactionFieldIndex === -1) {
                    transactionFieldIndex = state.newTransactionDataFields.findIndex((f) => f.id === action.updatedDataField.id)
                    return {
                        ...state,
                        newTransactionDataFields: [
                            ...state.newTransactionDataFields.slice(0, transactionFieldIndex),
                            action.updatedDataField,
                            ...state.newTransactionDataFields.slice(transactionFieldIndex + 1)
                        ]
                    }
                } else {
                    return {
                        ...state,
                        updatedTransactionDataFields: [
                            ...state.updatedTransactionDataFields.slice(0, transactionFieldIndex),
                            action.updatedDataField,
                            ...state.updatedTransactionDataFields.slice(transactionFieldIndex + 1)
                        ]
                    }
                }
            }

        case actionTypes.DATA_CUSTOMER_FIELD_ADDED:
            return {
                ...state,
                newCustomerDataFields: [...state.newCustomerDataFields, {
                    fieldName: "",
                    dataPrimitive: dataPrimitive.TEXT,
                    displayName: "",
                    id: uuidv4(),
                    editable: true,
                    fieldType: fieldDataTypes.CUSTOMER,
                    isQuickFilter: false
                }]
            }

        case actionTypes.DATA_CUSTOMER_FIELD_REMOVED:
            return {
                ...state,
                updatedCustomerDataFields: [...state.updatedCustomerDataFields.filter(f => f.id !== action.id)],
                newCustomerDataFields: [...state.newCustomerDataFields.filter(f => f.id !== action.id)]
            }

        case actionTypes.DATA_TRANSACTION_FIELD_ADDED:
            return {
                ...state,
                newTransactionDataFields: [...state.newTransactionDataFields, {
                    fieldName: "",
                    dataPrimitive: dataPrimitive.TEXT,
                    displayName: "",
                    id: uuidv4(),
                    editable: true,
                    fieldType: fieldDataTypes.TRANSACTION,
                    isQuickFilter: false
                }]
            }

        case actionTypes.DATA_TRANSACTION_FIELD_REMOVED:
            return {
                ...state,
                updatedTransactionDataFields: [...state.updatedTransactionDataFields.filter(f => f.id !== action.id)],
                newTransactionDataFields: [...state.newTransactionDataFields.filter(f => f.id !== action.id)]
            }

        case actionTypes.DATA_LOOKUP_ADDED:
            return {
                ...state,
                updatedLookupDataFields: [...state.updatedLookupDataFields, {
                    name: "",
                    fields: [
                        { field: "reference", dataPrimitive: dataPrimitive.TEXT, editable: false, id: uuidv4() },
                        { field: "name", dataPrimitive: dataPrimitive.TEXT, editable: false, id: uuidv4() },
                        { field: "isinuse", dataPrimitive: dataPrimitive.BOOLEAN, editable: false, id: uuidv4() },
                    ],
                    entries: [],
                    id: uuidv4(),
                    editable: true,
                }]
            }

        case actionTypes.DATA_LOOKUP_UPDATED:
            {
                const index = state.updatedLookupDataFields.findIndex((l) => l.id === action.updatedLookup.id)
                return {
                    ...state,
                    updatedLookupDataFields: [
                        ...state.updatedLookupDataFields.slice(0, index),
                        action.updatedLookup,
                        ...state.updatedLookupDataFields.slice(index + 1)
                    ]
                }
            }

        case actionTypes.DATA_LOOKUP_REPLACED:
            {
                const index = state.updatedLookupDataFields.findIndex((l) => l.id === action.lookupId)
                return {
                    ...state,
                    updatedLookupDataFields: [
                        ...state.updatedLookupDataFields.slice(0, index),
                        { ...state.updatedLookupDataFields[index], entries: action.entries },
                        ...state.updatedLookupDataFields.slice(index + 1)
                    ],
                    currentLookupEntryPage: 0
                }
            }

        case actionTypes.DATA_LOOKUP_REMOVED:
            return {
                ...state,
                updatedLookupDataFields: [...state.updatedLookupDataFields.filter(f => f.id !== action.id)]
            }

        case actionTypes.DATA_TAB_CHANGED:
            return {
                ...state,
                activeTab: action.tab
            }

        case actionTypes.DATA_ENTRY_PAGE_SIZE_CHANGED:
            return {
                ...state,
                currentLookupEntryPage: action.pageSize
            }

        default:
            throw new Error(`Invalid reducer action: ${action.type}`)
    }
}