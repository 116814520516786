import { actionTypes } from "../../../reducers/actionTypes"
import DraggableWidget from "./DraggableWidget"
import classes from "./Widgets.module.scss"
import { widgetSizes } from "../../../enums/widgetSizes"
import { v4 as uuidv4 } from "uuid"
import { DashboardConfigAction } from "../../../reducers/dashboardConfigsReducer"

type WidgetsProps = {
    className?: string
    dispatch: React.Dispatch<DashboardConfigAction>
    isNewEntityStructureEnabled: boolean
    isAggregatedDashboard: boolean
}

const Widgets = ({ className, dispatch, isNewEntityStructureEnabled, isAggregatedDashboard }: WidgetsProps) => {
    const onInformationClick = () => dispatch({ type: actionTypes.INFO_WIDGET_ADDED })

    const onValueClick = () => dispatch({ type: actionTypes.VALUE_WIDGET_ADDED })

    const onSmallBarChartClick = () =>
        isNewEntityStructureEnabled
            ? dispatch({ type: actionTypes.ENTITY_GRID_CHART_WIDGET_ADDED, chartType: "BAR" })
            : dispatch({ type: actionTypes.SMALL_BAR_CHART_WIDGET_ADDED })

    const onAreaChartClick = () =>
        isNewEntityStructureEnabled
            ? dispatch({ type: actionTypes.ENTITY_GRID_CHART_WIDGET_ADDED, chartType: "AREA" })
            : dispatch({ type: actionTypes.AREA_CHART_WIDGET_ADDED })

    const onLineChartClick = () =>
        isNewEntityStructureEnabled
            ? dispatch({ type: actionTypes.ENTITY_GRID_CHART_WIDGET_ADDED, chartType: "LINE" })
            : dispatch({ type: actionTypes.LINE_CHART_WIDGET_ADDED })

    const onSmallPieChartClick = () =>
        isNewEntityStructureEnabled
            ? dispatch({ type: actionTypes.ENTITY_GRID_CHART_WIDGET_ADDED, chartType: "PIE" })
            : dispatch({ type: actionTypes.SMALL_PIE_CHART_WIDGET_ADDED })

    const onTransactionsGridClick = () => dispatch({ type: actionTypes.TRANSACTIONS_GRID_WIDGET_ADDED, reference: uuidv4() })

    const onGridClick = () => dispatch({ type: actionTypes.GRID_WIDGET_ADDED, reference: uuidv4() })

    const onTrendingClick = () => dispatch({ type: actionTypes.TRENDING_WIDGET_ADDED, reference: uuidv4() })

    return (
        <div className={`d-flex flex-column ${className ? className : ""} ${classes.widgets} pt-2 pb-4 px-3`}>
            {!isAggregatedDashboard && (
                <DraggableWidget
                    className="mt-2"
                    name="Information"
                    description="For displaying general data"
                    iconClasses="fal fa-info-circle"
                    size={widgetSizes.ONE}
                    onClick={onInformationClick}
                />
            )}
            {!isAggregatedDashboard && (
                <DraggableWidget
                    className="mt-2"
                    name="Value History"
                    description="For displaying a fields current and previous value"
                    iconClasses="fal fa-percent"
                    size={widgetSizes.ONE}
                    onClick={onValueClick}
                />
            )}
            {(isNewEntityStructureEnabled || !isAggregatedDashboard) && (
                <DraggableWidget
                    className="mt-2"
                    name="Bar Chart"
                    description="For displaying a bucketed field"
                    iconClasses="fal fa-chart-bar"
                    size={widgetSizes.ONE}
                    onClick={onSmallBarChartClick}
                />
            )}

            {(isNewEntityStructureEnabled || !isAggregatedDashboard) && (
                <DraggableWidget
                    className="mt-2"
                    name="Area Chart"
                    description="For comparing data over time"
                    iconClasses="fal fa-chart-area"
                    size={widgetSizes.ONE}
                    onClick={onAreaChartClick}
                />
            )}
            {(isNewEntityStructureEnabled || !isAggregatedDashboard) && (
                <DraggableWidget
                    className="mt-2"
                    name="Line Chart"
                    description="For comparing data over time"
                    iconClasses="fal fa-chart-line"
                    size={widgetSizes.ONE}
                    onClick={onLineChartClick}
                />
            )}
            {(isNewEntityStructureEnabled || !isAggregatedDashboard) && (
                <DraggableWidget
                    className="mt-2"
                    name="Pie Chart"
                    description="For comparing data"
                    iconClasses="far fa-chart-pie-alt"
                    size={widgetSizes.ONE}
                    onClick={onSmallPieChartClick}
                />
            )}
            {isNewEntityStructureEnabled || isAggregatedDashboard ? (
                <DraggableWidget
                    className="mt-2"
                    name="Data Grid"
                    description="For displaying data of selected entity"
                    iconClasses="fal fa-list"
                    size={widgetSizes.FULL}
                    onClick={onGridClick}
                />
            ) : (
                <DraggableWidget
                    className="mt-2"
                    name="Transactions Grid"
                    description="For displaying customer transactions"
                    iconClasses="fal fa-list"
                    size={widgetSizes.FULL}
                    onClick={onTransactionsGridClick}
                />
            )}
            {isNewEntityStructureEnabled && isAggregatedDashboard && (
                <DraggableWidget
                    className="mt-2"
                    name="Trending"
                    description="View changes in data"
                    iconClasses="far fa-chart"
                    size={widgetSizes.FULL}
                    onClick={onTrendingClick}
                />
            )}
        </div>
    )
}

export default Widgets
