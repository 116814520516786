import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getApiUrl, gridKeys } from '../../constants';
import { storeGridState } from '../../actions/gridCacheActions';
import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../common/grids/StatefulGrid.js';
import { getFocusedTask, dismissFocusedTask } from '../../actions/taskActions';
import { ColumnMenu } from '../common/grids/columnMenu';
import TaskCard from './taskCard';
import existential from '../../utility/existential';
import usePrevious from '../../hooks/usePrevious';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useAppLayout from '../../hooks/useAppLayout';
import { getCustomerTaskGrid } from '../../selectors/task';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { dueDateMessage } from '../../utility/dueDateMessage'
import { useClient } from 'invevo-react-components';

const allTaskIdsSelector = createSelector(
  (state) => state.gridCacheReducer.grids,
  (grids) => {
    const gridCache = getCustomerTaskGrid(grids)

    if (!gridCache || !gridCache.lastResponse) return []

    return gridCache.lastResponse.data.map(task => task.Id)
  }
)

const CustomerTasksGrid = ({
  customerId,
  isVirtualAccount,
  tickedTaskIds,
  setTickedTaskIds,
  filters,
  statusFilter,
  gridState,
  selectedTask,
  onStoreGridState,
  getFocusedTask,
  dismissFocusedTask,
  setRefresh,
  refresh = false,
  taskTypeId,
  isBulkActionSelected,
  allTickBoxTicked,
  setAllTickBoxTicked
}) => {
  const client = useClient()
  const key = 'tasks-grid-key'
  const columns = [
    { field: "Id", title: "Task Id" },
    { field: "TaskType", title: "Task Type" },
    { field: "TaskDescription", title: "Description" },
    { field: "TaskDate", title: "Task Date", format: "{0:dd MMM yyyy}", filter: "date", type: "date" },
    { field: "Attachment", title: "Attachment" }
  ]
  const { invevoTaskBulkAction } = useFlags();
  const { isFullScreenLeft } = useAppLayout();

  useEffect(() => {
    if (!invevoTaskBulkAction) return

    // reset selected tasks if the filtered task type ever changes
    setTickedTaskIds([])
    setAllTickBoxTicked(false)
  }, [taskTypeId, setTickedTaskIds, invevoTaskBulkAction, setAllTickBoxTicked])

  const prevAccountId = usePrevious(customerId)
  useEffect(() => {
    if (customerId !== prevAccountId) {
      const newGridState = { ...gridState, page: 1 }

      onStoreGridState(
        key + customerId,
        newGridState
      )
    }
  }, [gridState, onStoreGridState, prevAccountId, customerId])

  const selectedTaskId = selectedTask.id
  const selectedTaskLastUpdated = selectedTask.lastUpdated
  const prevSelectedTaskId = usePrevious(selectedTask.id)
  const prevSelectedTaskLastUpdated = usePrevious(selectedTask.lastUpdated)
  useEffect(() => {
    if (selectedTaskId !== prevSelectedTaskId || selectedTaskLastUpdated !== prevSelectedTaskLastUpdated) {
      setRefresh(!refresh)
    }
  }, [prevSelectedTaskId, prevSelectedTaskLastUpdated, refresh, selectedTaskId, selectedTaskLastUpdated, setRefresh])

  const allTaskIds = useSelector(allTaskIdsSelector)

  const onHeaderTickBoxClick = event => {
    const newAllTickBoxTickedState = !allTickBoxTicked
    setAllTickBoxTicked(newAllTickBoxTickedState)
    if (newAllTickBoxTickedState) {
      setTickedTaskIds(allTaskIds)
    } else {
      setTickedTaskIds([])
    }
  }

  const onRowTickBoxClick = event => {
    const selectedTaskId = event.dataItem.Id
    const newTickedTaskIds = tickedTaskIds.includes(selectedTaskId) ?
      tickedTaskIds.filter(taskId => taskId !== selectedTaskId) :
      [...tickedTaskIds, selectedTaskId]

    setTickedTaskIds(newTickedTaskIds)
    setAllTickBoxTicked(allTaskIds.every(v => newTickedTaskIds.includes(v)))
  }

  const onRowClick = (gridRowEvent) => {
    const dataItem = gridRowEvent.dataItem;

    if (dataItem && !dataItem.selected) {
      getFocusedTask(dataItem.Id, !!dataItem.TaskCompletionId, client)
    } else if (gridRowEvent.nativeEvent.ctrlKey) {
      dismissFocusedTask();
    }
  }

  const onStateChange = (data) => {
    onStoreGridState(
      key + customerId,
      data
    )
  }

  const additionalRequestPayload = () => {
    return {
      accountid: customerId,
      isvirtualaccount: isVirtualAccount,
      isclosed: statusFilter.isClosed,
      onlycurrentusertasks: statusFilter.isCurrentUserTasks
    }
  }

  const taskCard = e => (
    <TaskCard dataItem={e.dataItem} onClick={onRowClick} />
  )

  const dueDate = e => {
    return (
      e.dataItem.TaskCompletionId > 0
        ? <td>
          <i className={`fa-solid fa-check-circle mia-success`}></i>
          {' '} Completed by {e.dataItem.CompletedUserName}
        </td>
        : <td><h4 className="py-3 mb-0" > {dueDateMessage(e.dataItem.TaskDate)}</h4></td>
    )
  }

  const attachment = e => {
    return (
      <td>
        {e.dataItem.HasDocuments &&
          <i className={`fa-solid fa-file-alt mr-2 text-blue`}></i>
        }
        {e.dataItem.TaskTransactionRefs &&
          e.dataItem.TaskTransactionRefs.length > 0 &&
          <span className="ml-2 text-blue">{e.dataItem.TaskTransactionRefs.length} {' '}
            <i className={`fa-solid fa-bars`}></i>
          </span>
        }
      </td>)
  }

  const filter =
    filters && filters.length > 0
      ? { logic: 'and', filters: filters }
      : null

  const showTickBoxes = isFullScreenLeft && invevoTaskBulkAction && isBulkActionSelected && filters.length !== 0

  return (
    <StatefulGrid
      client={client}
      path={getApiUrl(client) + 'api/task/list'}
      stateKey={gridKeys.CUSTOMER_TASK_GRID_KEY}
      sortable
      pageable={{
        pageSizes: [50, 100, 200],
        refresh: true,
        buttonCount: 5
      }}
      onStateChange={onStateChange}
      onRowClick={onRowClick}
      isSelectedRow={showTickBoxes ? task => tickedTaskIds.includes(task.Id) : undefined}
      checkableRow={showTickBoxes ? { headerSelectionValue: allTickBoxTicked } : undefined}
      onSelectionChange={showTickBoxes ? onRowTickBoxClick : undefined}
      onHeaderSelectionChange={showTickBoxes ? onHeaderTickBoxClick : undefined}
      additionalRequestPayload={additionalRequestPayload()}
      defaultField={{ field: 'TaskDate', dir: 'asc' }}
      hideablePaging={true}
      filter={filter}
      taskGrid={true}
      refresh={refresh}
      className={
        isFullScreenLeft
          ? 'custom-grid scrollable-none'
          : 'hide-header single-cell scrollable-none'
      }
    >
      {isFullScreenLeft ? (
        columns.map((c) => (
          c.field === "TaskDate" && !invevoTaskBulkAction
            ? <GridColumn {...c} key={c.field} columnMenu={ColumnMenu} cell={dueDate} />
            : c.field === "Attachment"
              ? <GridColumn {...c} key={c.field} columnMenu={ColumnMenu} cell={attachment} sortable={false} />
              : <GridColumn {...c} key={c.field} columnMenu={ColumnMenu} />
        ))
      ) : (
        <GridColumn cell={taskCard} />
      )}
    </StatefulGrid>
  )
}

const mapStateToProps = (state) => {
  return {
    gridState: existential(state.gridCacheReducer.grids, 'gridState', {}),
    selectedTask: state.taskReducer.selectedTask || {},
    taskTypeId: state.taskReducer.userTaskList.taskTypeId
  };
};

export default connect(mapStateToProps, {
  onStoreGridState: storeGridState,
  getFocusedTask,
  dismissFocusedTask
})(CustomerTasksGrid);
