import GridColumnConfigDto from "../../library/dataGrid/types/GridColumnConfigDto"
import HeaderDefinition from "../../library/grid/types/HeaderDefinition"
import DataField from "../../types/DataField"
import DataPrimitive from "../../types/DataPrimitive"
import DropdownOption from "../../types/DropdownOptions"
import FieldType from "../../types/enums/FieldType"
import { DataPrimitiveTypeEnum, DataTypeEnum } from "./types/DataType"
import EntityDataField from "./types/EntityDataField"
import Lookup from "../../types/Lookup"
import moment from "moment"

export const MoneyDataTypeOptionDropdownOptions: DropdownOption<string>[] = [
    { value: "USD", label: "United States Dollar (USD)" },
    { value: "EUR", label: "Euro (EUR)" },
    { value: "JPY", label: "Japanese Yen (JPY)" },
    { value: "GBP", label: "Pound Sterling (GBP)" },
    { value: "AUD", label: "Australian Dollar (AUD)" },
    { value: "CAD", label: "Canadian Dollar (CAD)" },
    { value: "CHF", label: "Swiss Franc (CHF)" },
    { value: "CNY", label: "Chinese Yuan Renminbi (CNY)" },
    { value: "HKD", label: "Hong Kong Dollar (HKD)" },
    { value: "NZD", label: "New Zealand Dollar (NZD)" },
    { value: "SEK", label: "Swedish Krona (SEK)" },
    { value: "KRW", label: "South Korean Won (KRW)" },
    { value: "SGD", label: "Singapore Dollar (SGD)" },
    { value: "NOK", label: "Norwegian Krone (NOK)" },
    { value: "MXN", label: "Mexican Peso (MXN)" },
    { value: "INR", label: "Indian Rupee (INR)" },
    { value: "RUB", label: "Russian Ruble (RUB)" },
    { value: "ZAR", label: "South African Rand (ZAR)" },
    { value: "TRY", label: "Turkish Lira (TRY)" },
    { value: "BRL", label: "Brazilian Real (BRL)" }
]

export const HardcodedEntityDataFields: EntityDataField[] = [
    {
        fieldName: "client",
        displayName: "Client",
        description: "",
        dataType: { type: DataTypeEnum.TEXT },
        dataPrimitiveType: DataPrimitiveTypeEnum.TEXT,
        isQuickFilter: false
    },
    {
        fieldName: "reference",
        displayName: "Reference",
        description: "",
        dataType: { type: DataTypeEnum.TEXT },
        dataPrimitiveType: DataPrimitiveTypeEnum.TEXT,
        isQuickFilter: false
    },
    {
        fieldName: "entity_type",
        displayName: "Entity Type",
        description: "",
        dataType: { type: DataTypeEnum.TEXT },
        dataPrimitiveType: DataPrimitiveTypeEnum.TEXT,
        isQuickFilter: false
    },
    {
        fieldName: "boolean_archived",
        displayName: "Archived",
        description: "",
        dataType: { type: DataTypeEnum.BOOLEAN },
        dataPrimitiveType: DataPrimitiveTypeEnum.BOOLEAN,
        isQuickFilter: false,
        trackChanges: true
    },
    {
        fieldName: "text_comment",
        displayName: "Comment",
        description: "",
        dataType: { type: DataTypeEnum.RICH_TEXT },
        dataPrimitiveType: DataPrimitiveTypeEnum.TEXT,
        isQuickFilter: false,
        trackChanges: true
    },
    {
        fieldName: "text_name",
        displayName: "Name",
        description: "",
        dataType: { type: DataTypeEnum.TEXT },
        dataPrimitiveType: DataPrimitiveTypeEnum.TEXT,
        isQuickFilter: false,
        trackChanges: true
    }
]

export const EntityDataFieldsGridDataFieldsLegacy: DataField[] = [
    { value: "fieldname", label: "Field Name", type: DataPrimitive.TEXT, fieldType: FieldType.ENTITY, ordinal: 0 },
    { value: "displayname", label: "Display Name", type: DataPrimitive.TEXT, fieldType: FieldType.ENTITY, ordinal: 1 },
    { value: "description", label: "Description", type: DataPrimitive.TEXT, fieldType: FieldType.ENTITY, ordinal: 2 },
    { value: "datatype", label: "Data Type", type: DataPrimitive.TEXT, fieldType: FieldType.ENTITY, ordinal: 3 },
    { value: "isquickfilter", label: "Is Quick Filter", type: DataPrimitive.BOOLEAN, fieldType: FieldType.ENTITY, ordinal: 4 },
    { value: "timezone", label: "Time Zone", type: DataPrimitive.TEXT, fieldType: FieldType.ENTITY, ordinal: 5 },
    { value: "lookupreference", label: "Lookup", type: DataPrimitive.TEXT, fieldType: FieldType.ENTITY, ordinal: 6 },
    { value: "currencyfield", label: "Currency Field", type: DataPrimitive.TEXT, fieldType: FieldType.ENTITY, ordinal: 7 },
    { value: "currencycode", label: "Currency Code", type: DataPrimitive.TEXT, fieldType: FieldType.ENTITY, ordinal: 8 }
]

export const DefaultDataFieldsGridConfigLegacy: GridColumnConfigDto[] = EntityDataFieldsGridDataFieldsLegacy.map(dataField => {
    return {
        fieldName: dataField.value,
        ordinal: dataField.ordinal!!
    }
})

export const DefaultDataFieldHeaders: HeaderDefinition[] = [
    {
        value: "fieldname",
        label: "Field Name",
        ordinal: 0,
        defaultOrdinal: 0,
        displayType: DataTypeEnum.TEXT,
        isEditable: false
    },
    {
        value: "displayname",
        label: "Display Name",
        ordinal: 1,
        defaultOrdinal: 1,
        displayType: DataTypeEnum.TEXT,
        isEditable: true
    },
    {
        value: "description",
        label: "Description",
        ordinal: 2,
        defaultOrdinal: 2,
        displayType: DataTypeEnum.TEXT,
        isEditable: true
    },
    {
        value: "datatype",
        label: "Data Type",
        ordinal: 3,
        defaultOrdinal: 3,
        displayType: DataTypeEnum.LOOKUP,
        isEditable: false,
        lookupReference: "datatypes"
    },
    {
        value: "isquickfilter",
        label: "Is Quick Filter",
        ordinal: 4,
        defaultOrdinal: 4,
        displayType: DataTypeEnum.BOOLEAN,
        isEditable: true
    },
    {
        value: "timezone",
        label: "Time Zone",
        ordinal: 5,
        defaultOrdinal: 5,
        displayType: DataTypeEnum.LOOKUP,
        isEditable: true,
        lookupReference: "timezones"
    },
    {
        value: "lookupreference",
        label: "Lookup",
        ordinal: 6,
        defaultOrdinal: 6,
        displayType: DataTypeEnum.TEXT,
        isEditable: false
    },
    {
        value: "currencyfield",
        label: "Currency Field",
        ordinal: 7,
        defaultOrdinal: 7,
        displayType: DataTypeEnum.TEXT,
        isEditable: false
    },
    {
        value: "currencycode",
        label: "Currency Code",
        ordinal: 8,
        defaultOrdinal: 8,
        displayType: DataTypeEnum.LOOKUP,
        isEditable: true,
        lookupReference: "currencycodes"
    },
    {
        value: "trackchanges",
        label: "Track Changes",
        ordinal: 9,
        defaultOrdinal: 9,
        displayType: DataTypeEnum.BOOLEAN,
        isEditable: true
    },
    {
        value: "defaultvalue",
        label: "Default Value",
        ordinal: 10,
        defaultOrdinal: 10,
        displayType: DataTypeEnum.TEXT,
        isEditable: false
    }
]

export const DefaultDataFieldLookups: Lookup[] = [
    {
        name: "Data Types",
        reference: "datatypes",
        columns: [
            {
                name: "reference",
                dataPrimitive: DataPrimitive.TEXT
            },
            {
                name: "name",
                dataPrimitive: DataPrimitive.TEXT
            }
        ],
        entries: [
            {
                name: "TEXT",
                reference: "TEXT"
            },
            {
                name: "EMAIL",
                reference: "EMAIL"
            },
            {
                name: "PHONE_NUMBER",
                reference: "PHONE_NUMBER"
            },
            {
                name: "DATE",
                reference: "DATE"
            },
            {
                name: "BOOLEAN",
                reference: "BOOLEAN"
            },
            {
                name: "MONEY",
                reference: "MONEY"
            },
            {
                name: "INT",
                reference: "INT"
            },
            {
                name: "DECIMAL",
                reference: "DECIMAL"
            },
            {
                name: "LOOKUP",
                reference: "LOOKUP"
            }
        ]
    },
    {
        name: "Time Zones",
        reference: "timezones",
        columns: [
            {
                name: "reference",
                dataPrimitive: DataPrimitive.TEXT
            },
            {
                name: "name",
                dataPrimitive: DataPrimitive.TEXT
            }
        ],
        entries: moment.tz?.names()?.map(timeZone => ({ name: timeZone, reference: timeZone })) ?? []
    },
    {
        name: "Currency Codes",
        reference: "currencycodes",
        columns: [
            {
                name: "reference",
                dataPrimitive: DataPrimitive.TEXT
            },
            {
                name: "name",
                dataPrimitive: DataPrimitive.TEXT
            }
        ],
        entries: MoneyDataTypeOptionDropdownOptions.map(option => ({
            name: option.value,
            reference: option.value
        }))
    }
]
