import { ReactNode, createContext, useContext, useState } from "react"
import classes from "./OverlayContext.module.scss"
import Swal from "sweetalert2"
import { renderToString } from "react-dom/server"
import useClickOutsideRef from "../../hooks/useClickOutsideRef"
import "./SwalOverrides.scss"

type OverlaySize = "small" | "medium"
type OverlayContextValue = {
    showOverlay: (content: ReactNode, size?: OverlaySize, source?: string) => void
    closeOverlay: () => void
    showConfirmDeletionOverlay: (resourceType: string, element: JSX.Element, deleteRequest: () => Promise<unknown>) => void
    showCreatedSuccessfullyOverlay: (resourceType: string) => void
    showConfirmActionOverlay: (resourceType: string, element: JSX.Element, actionRequest: () => Promise<unknown>) => void
    isOverlayVisible: (source?: string) => boolean
}

const OverlayContext = createContext<OverlayContextValue | undefined>(undefined)

type Props = {
    children: ReactNode
}

const OverlayProvider = ({ children }: Props) => {
    const [overlaidContent, setOverlaidContent] = useState<ReactNode>(undefined)
    const closeOverlay = () => setOverlaidContent(undefined)
    const ref = useClickOutsideRef(closeOverlay)
    const [overlaySize, setOverlaySize] = useState<OverlaySize>("small")
    const [source, setSource] = useState<string>("")

    const showOverlay = (content: ReactNode, size: OverlaySize = "small", source: string = "") => {
        setSource(source)
        setOverlaySize(size)
        setOverlaidContent(content)
    }

    const isOverlayVisible = (src: string = "") => overlaidContent !== undefined && source === src

    const value = {
        showOverlay,
        closeOverlay,
        showConfirmDeletionOverlay,
        showCreatedSuccessfullyOverlay,
        showConfirmActionOverlay,
        isOverlayVisible
    }

    return (
        <OverlayContext.Provider value={value}>
            <>
                {overlaidContent !== undefined && (
                    <div className={`${classes.background} d-flex justify-content-center position-fixed w-100 h-100`}>
                        <div
                            className={`${classes.overlay} d-flex flex-column bg-white text-grey p-3 rounded gap-2 w-${getWidthFromOverlaySize(
                                overlaySize
                            )} h-${getHeightFromOverlaySize(overlaySize)}
                            } mb-auto`}
                            ref={ref}
                        >
                            {overlaidContent}
                        </div>
                    </div>
                )}
                {children}
            </>
        </OverlayContext.Provider>
    )
}

const useOverlay = () => {
    const context = useContext(OverlayContext)

    if (!context) throw new Error(`useOverlay must be used within a <OverlayProvider>`)

    return context
}

export { useOverlay, OverlayProvider }

const showConfirmActionOverlay = (resourceType: string, element: JSX.Element, actionRequest: () => Promise<unknown>) => {
    Swal.fire({
        icon: "warning",
        html: renderToString(element),
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#0096d9",
        cancelButtonColor: "#ee4056",
        confirmButtonText: "Yes, run it!",
        confirmButtonAriaLabel: "confirm-action",
        cancelButtonAriaLabel: "cancel-action",
        heightAuto: false,
        showLoaderOnConfirm: true,
        preConfirm: () =>
            actionRequest().then(() => {
                Swal.fire({
                    title: "Ran successully!",
                    text: `${resourceType} action has been executed.`,
                    icon: "success",
                    timer: 2000,
                    timerProgressBar: true,
                    confirmButtonAriaLabel: "confirm-action-success",
                    heightAuto: false
                })
            })
    })
}

const showConfirmDeletionOverlay = (resourceType: string, element: JSX.Element, deleteRequest: () => Promise<unknown>) => {
    Swal.fire({
        icon: "warning",
        html: renderToString(element),
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#0096d9",
        cancelButtonColor: "#ee4056",
        confirmButtonText: "Yes, delete it!",
        confirmButtonAriaLabel: "confirm-delete",
        cancelButtonAriaLabel: "cancel-delete",
        heightAuto: false,
        showLoaderOnConfirm: true,
        preConfirm: () =>
            deleteRequest().then(() => {
                Swal.fire({
                    title: "Deleted!",
                    text: `${resourceType} has been deleted.`,
                    icon: "success",
                    timer: 2000,
                    timerProgressBar: true,
                    confirmButtonAriaLabel: "confirm-delete-success",
                    heightAuto: false
                })
            })
    })
}

export const showCreatedSuccessfullyOverlay = (resourceType: string) => {
    Swal.fire({
        title: "Created!",
        text: `${resourceType} has been created.`,
        icon: "success",
        timer: 2000,
        timerProgressBar: true,
        confirmButtonAriaLabel: "confirm-create-success",
        heightAuto: false
    })
}

const getWidthFromOverlaySize = (overlaySize: OverlaySize) => {
    switch (overlaySize) {
        case "small":
            return "25"
        case "medium":
            return "50"
    }
}

const getHeightFromOverlaySize = (overlaySize: OverlaySize) => {
    switch (overlaySize) {
        case "small":
            return "25"
        case "medium":
            return "50"
    }
}
