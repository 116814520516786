import { useState } from "react"
import classes from "./HeaderConfigButton.module.scss"
import { padElementsWith } from "../../helpers"
import useApiQuery from "../../../hooks/useApiQuery"
import HeaderConfigFieldOption from "./HeaderConfigFieldOption"
import { useGrid } from "./../Grid"
import HeaderDefinition from "./../types/HeaderDefinition"
import { useUser } from "../../../contexts/UserContext"
import useClient from "../../../hooks/useClient"
import { useEnvConfig } from "../../../contexts/EnvironmentConfigContext"
import Loading from "../../Loading/Loading"
import GreyButton from "../../buttons/GreyButton/GreyButton"
import IconButton from "../../buttons/IconButton/IconButton"
import Input from "../../Inputs/Input"
import useClickOutsideRef from "../../../hooks/useClickOutsideRef"

const HeaderConfigButton = () => {
    const grid = useGrid()
    const [showHeaderConfig, setShowHeaderConfig] = useState(false)
    const [searchValue, setSearchValue] = useState("")

    const currentUser = useUser()
    const client = useClient()
    const config = useEnvConfig()

    const onHeaderConfigClicked = () =>
        setShowHeaderConfig(current => {
            setSearchValue("")
            return !current
        })

    const getToggleState = (field: HeaderDefinition) => grid.displayedHeaders.map(f => f.value).includes(field.value)

    const onStateToggled = (field: HeaderDefinition) => {
        const newFields = getToggleState(field) ? grid.displayedHeaders.filter(header => header.value !== field.value) : [...grid.displayedHeaders, field]
        const newFieldsWithOrdinal = newFields.map((field, index) => ({ ...field, ordinal: index }))

        grid.setUserConfiguredHeaders(newFieldsWithOrdinal)
    }

    const saveConfigRequest = useApiQuery({
        url: `${config.DATA_API_URL}/api/${client}/user-grid-config/${currentUser.username}`,
        method: "PUT",
        onSuccess: () => setShowHeaderConfig(false),
        isExecutedAutomatically: false
    })

    const saveConfig = (headers: HeaderDefinition[]) =>
        saveConfigRequest.execute(undefined, {
            gridReference: grid.reference,
            displayedColumns: headers.map(field => ({ fieldName: field.value, ordinal: field.ordinal }))
        })

    const onResetClicked = () => {
        saveConfig(grid.defaultHeaders)
        grid.setUserConfiguredHeaders(undefined)
    }

    const onSaveClicked = () => saveConfig(grid.displayedHeaders)
    const ref = useClickOutsideRef(() => setShowHeaderConfig(false))

    const options = grid.potentialHeaders
        .filter(field => field.label.toLowerCase().includes(searchValue.toLowerCase()))
        .sort((a, b) => a.label.localeCompare(b.label))
        .map(field => <HeaderConfigFieldOption key={field.label} dataField={field} isEnabled={getToggleState(field)} onStateToggled={onStateToggled} />)

    return (
        <div ref={ref} className="d-flex position-relative flex-column">
            <IconButton ariaLabel="header-config" tooltip="Configuration" icon="fa-light fa-line-columns" onClick={onHeaderConfigClicked} theme="blue-flat" />
            {showHeaderConfig && (
                <div className={`d-flex ${classes.dialog}`} role="dialog" aria-label="header-config">
                    <div className={`position-absolute bg-blue ${classes.columns}`}>
                        <div className={`${classes.header} p-2`}>
                            <Input value={searchValue} onChange={setSearchValue} placeholder="Search..." ariaLabel="header-search" />
                        </div>
                        <div className={`w-100 d-flex flex-column bg-grey ${classes.body}`}>
                            {options.length === 0 ? (
                                <span className="d-flex align-items-center justify-content-center p-2">No matching headers</span>
                            ) : (
                                padElementsWith(options, "w-100 border-top")
                            )}
                        </div>
                        <div className={`d-flex align-items-center justify-content-between p-2 ${classes.footer}`}>
                            <Loading isLoading={saveConfigRequest.isFetching} colour="white">
                                <GreyButton label="Save" iconClasses="fa-light fa-save" onClick={onSaveClicked} ariaLabel="save-config" disabled={false} />
                                <GreyButton
                                    className="ms-3"
                                    label="Reset"
                                    iconClasses="fa-light fa-redo"
                                    onClick={onResetClicked}
                                    ariaLabel="reset-config"
                                    disabled={false}
                                />
                            </Loading>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default HeaderConfigButton
