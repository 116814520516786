import update from 'immutability-helper';

const getInitialState = () => {
  return {
    tedLastUpdated: null,
    salesLedgerValue: 0,
    pendingOpenOrders: 0,
    toolHireValue: 0,
    pendingAccountCredits: 0,
    pendingPayments: 0,
    relatedConsolidatedInvoices: 0,
    tedValue: 0,
    creditLimitValue: 0,
    tedError: '',
    tedSuccess: '',
    tedRefresh: { isSuccess: false, isFailed: false, error: null }
  };
};

const tedReducer = (state = getInitialState(), action) => {
  let nState;

  switch (action.type) {
    case 'RECEIVE_CUSTOMER_TED':
      nState = update(state, {
        tedLastUpdated: { $set: action.data.tedLastUpdated },
        salesLedgerValue: { $set: action.data.salesLedgerValue },
        pendingOpenOrders: { $set: action.data.pendingOpenOrders },
        toolHireValue: { $set: action.data.toolHireValue },
        pendingAccountCredits: { $set: action.data.pendingAccountCredits },
        pendingPayments: { $set: action.data.pendingPayments },
        relatedConsolidatedInvoices: {
          $set: action.data.relatedConsolidatedInvoices
        },
        tedValue: { $set: action.data.tedValue },
        creditLimitValue: { $set: action.data.creditLimitValue }
      });

      return nState;
    case 'TED_ERROR':
      nState = update(state, { tedError: { $set: action.tedError } });

      return nState;
    case 'TED_SUCCESS':
      nState = update(state, { tedSuccess: { $set: action.tedSuccess } });

      return nState;
    case 'TED_REFRESH_REQUEST':
      nState = update(state, {
        tedRefresh: {
          isSuccess: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        }
      });
      return nState;
    case 'TED_REFRESH_SUCCESS':
      nState = update(state, {
        tedLastUpdated: { $set: action.data.tedLastUpdated },
        salesLedgerValue: { $set: action.data.salesLedgerValue },
        pendingOpenOrders: { $set: action.data.pendingOpenOrders },
        toolHireValue: { $set: action.data.toolHireValue },
        pendingAccountCredits: { $set: action.data.pendingAccountCredits },
        pendingPayments: { $set: action.data.pendingPayments },
        relatedConsolidatedInvoices: {
          $set: action.data.relatedConsolidatedInvoices
        },
        tedValue: { $set: action.data.tedValue },
        creditLimitValue: { $set: action.data.creditLimitValue },
        tedRefresh: {
          isSuccess: { $set: true },
          isFailed: { $set: false },
          error: { $set: null }
        }
      });
      return nState;
    case 'TED_REFRESH_ERROR':
      nState = update(state, {
        tedRefresh: {
          isSuccess: { $set: false },
          isFailed: { $set: true },
          error: { $set: action.err }
        }
      });
      return nState;
    case 'TED_REFRESH_RESET':
      nState = update(state, {
        tedRefresh: {
          isSuccess: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        }
      });
      return nState;
    default:
      return state;
  }
};

export default tedReducer;
