import { createContext, useContext, useState } from "react"

const CustomerContext = createContext()

const defaultCustomer = {
    reference: undefined,
    sqlId: undefined,
    isVirtualAccount: undefined,
    data: undefined,
    grouping: {},
    selectedTransactions: [],
    transactionFilters: [],
    advancedTransactionFilters: []
}

export const CustomerProvider = ({ children }) => {
    const [customer, setCustomer] = useState(defaultCustomer)

    const onCustomerUpdated = newCustomer => {
        if (newCustomer.reference && customer.reference !== newCustomer.reference)
            setCustomer({
                grouping: {},
                ...defaultCustomer,
                ...newCustomer
            })
        else
            setCustomer(existingCustomer => {
                return {
                    grouping: {},
                    ...defaultCustomer,
                    ...existingCustomer,
                    ...newCustomer
                }
            })
    }

    const clearCustomer = () => setCustomer(defaultCustomer)

    return <CustomerContext.Provider value={[customer, onCustomerUpdated, clearCustomer]}>{children}</CustomerContext.Provider>
}

export const useCustomer = () => {
    const context = useContext(CustomerContext)

    if (!context) {
        throw new Error(`useCustomer must be used within a <CustomerProvider />`)
    }
    return context
}
