import React, { Component } from 'react';
import { connect } from 'react-redux';
import { executeAuthAsyncGet } from '../../utility/asyncSupport';
import ViewTask from './viewTask';

class PreviousTaskHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tasks: new Map(),
      prevPropsTaskId: props.taskId
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.taskId !== state.prevPropsTaskId) {
      return {
        tasks: new Map(),
        prevPropsTaskId: props.taskId
      };
    }
    return null;
  }

  loadTask = (taskId) => {
    this.props.executeAuthAsyncGet(
      this.props.client,
      'task/getnewtask',
      'TASK',
      { taskId },
      (task) => {
        const clone = new Map(this.state.tasks);
        clone.set(task.id, task);
        this.setState({ tasks: clone });
      }
    );
  };

  onPreviousTaskClick = (taskId) => () => this.loadTask(taskId);

  showTaskHistory = (id) => {
    if (!id) return <h3>End of linked tasks</h3>;

    if (!this.state.tasks.has(id)) {
      return (
        <button
          className="btn btn-link top20 text-white"
          onClick={this.onPreviousTaskClick(id)}
        >
          Show Task #{id}
        </button>
      );
    }

    const task = this.state.tasks.get(id);

    return (
      <div>
        <div>
          <h3 className="top20">Task #{task.id}</h3>
          <ViewTask task={task} hideTaskChainLinks={true} />
        </div>
        {this.showTaskHistory(task.originatingTaskId)}
      </div>
    );
  };

  render() {
    if (!this.props.taskId) return <></>;

    return (
      <div>
        <h3>Previous Task History</h3>
        {this.showTaskHistory(this.props.taskId)}
      </div>
    );
  }
}

export default connect(null, { executeAuthAsyncGet })(PreviousTaskHistory);
