import { includes, values } from 'lodash';

export const EMailSupport = {
  availableClientVariables: {
    CONTACT_NAME: 'contact-name',
    CUSTOMER_ACCOUNT: 'customer-account',
    CUSTOMER_NAME: 'customer-name',
    TRANSACTION_ROWS: 'transaction-rows',
    TRANSACTION_ROWS_ATTACHED: 'transaction-rows-attached',
    USER_EMAIL_ADDRESS: 'user-email-address',
    USER_TELEPHONE: 'user-telephone',
    USER_MOBILE: 'user-mobile',
    USER_ROLE: 'user-role',
    USER_NAME: 'user-name',
    ACCOUNT_BALANCE: 'account-balance',
    TED_VALUE: 'ted-value',
    GUARANTEE_EXPIRY_DATE: 'guarantee-expiry-date',
    GUARANTOR: 'guarantor',
    OVERDUE_BALANCE: 'overdue-balance',
    PAYMENT_PROMISE_DATE: 'payment-promise-date',
    PAYMENT_PROMISE_VALUE: 'payment-promise-value',
    CREDIT_LIMIT: 'credit-limit',
    CREDIT_LIMIT_REMAINING: 'credit-limit-remaining',
    TEMP_LIMIT_EXPIRY_DATE: 'temp-limit-expiry-date',
    VENDOR_NUMBER: 'vendor-number',
    PERSONALIZED_CONTENT: 'personalized-content',
    REPAYMENTPLAN_INVOICES: 'repaymentplan-invoices',
    REPAYMENTPLAN_INSTALLMENTS: 'repaymentplan-installments',
    REPAYMENTPLAN_POSITION: 'repaymentplan-position',
    BRANCH_NAME: 'branch-name',
    DOCUMENTS_PORTAL_LINK: 'documents-portal-link',
    TRANSACTIONS_PORTAL_LINK: 'transactions-portal-link',
    TRANSACTION_ROWS_EMBEDDED: 'transaction-rows-embedded',
    portal1: 'customer-portal-link',
    portal2: 'creditcard-portal-link',
    portal3: 'dispute-portal-link',
    portal4: 'promise-portal-link',
    CUSTOMER_ACCOUNT_NUMBER: 'customer-account-number',
    CUSTOMER_IBAN: 'customer-iban',
    CUSTOMER_SORTCODE: 'customer-sortcode',
    CUSTOMER_SWIFT: 'customer-swift',
    CUSTOMER_VAT: 'customer-vat',
    CUSTOMER_COMPANY_REGISTRATION: 'customer-company-registration',
    CUSTOMER_BUYINGGROUP_PAYERNAME: 'customer-buyinggroup-payername',
    BRANDINTERNAL_NAME: 'brandinternal-name',
    LEDGER_NAME: 'ledger-name',
    EMAIL_THREAD: 'email-thread'
  },

  availablePartialClientVariables: {
    ADDRESS_LINE1: 'addressline1-',
    ADDRESS_LINE2: 'addressline2-',
    ADDRESS_LINE3: 'addressline3-',
    ADDRESS_LINE4: 'addressline4-',
    ADDRESS_LINE5: 'addressline5-',
    ADDRESS_LINE6: 'addressline6-',
    ADDRESS_LINE7: 'addressline7-',
    COUNTRY: 'country-',
    CUSTOMER_EXTENSION: 'CustomerExtension-'
  },

  extractVariablesFromContent(content) {
    const results = [];
    const re = /{{([^}]+)}}/g;
    let text;
    while ((text = re.exec(content))) {
      if (!includes(results, text[1], 0)) {
        results.push(text[1]);
      }
    }

    if (
      includes(results, this.availableClientVariables.TRANSACTION_ROWS, 0) ||
      includes(
        results,
        this.availableClientVariables.TRANSACTIONS_PORTAL_LINK,
        0
      )
    ) {
      results.push('transaction-rows-embedded');
    }

    return results;
  },

  mergeVariablesIntoHtml(html, fragments) {
    if (html != null) {
      const variables = this.extractVariablesFromContent(html);
      variables.forEach(function (e) {
        let fragment = fragments[e] || '';
        if (e.endsWith('-attached')) {
          fragment = '';
        }
        html = html.replace('{{' + e + '}}', fragment);
      });
      return html;
    }
    return '';
  },

  validateHtml(htmlText) {
    return new Promise(function (resolve, reject) {
      resolve();
      const http = new XMLHttpRequest();
      http.open('POST', 'https://validator.w3.org/nu/?out=json', true);
      http.setRequestHeader('content-type', 'text/html; charset=utf-8');

      const errorsOnly = function (message) {
        return message.type === 'error';
      };

      http.onreadystatechange = function () {
        if (http.readyState === 4 && http.status === 200) {
          const result = JSON.parse(http.responseText);
          if (result.messages.filter(errorsOnly).length === 0) {
            resolve();
          } else {
            reject(result.messages.filter(errorsOnly));
          }
        }
      };
      http.send(htmlText);
    });
  },

  validateClientVariables(htmlContent) {
    // Names used in the template must match existing available variables
    const clientVariables = EMailSupport.extractVariablesFromContent(
      htmlContent
    );
    const availableVariableNames = values(
      EMailSupport.availableClientVariables
    );

    const partialVariableNames = values(
      EMailSupport.availablePartialClientVariables
    );
    return clientVariables.every(function (o) {
      return (
        availableVariableNames.indexOf(o) > -1 ||
        partialVariableNames.some((p) => o.indexOf(p) > -1)
      );
    });
  }
};
