import Dropdown from "../dropdowns/Dropdown"
import DropdownOption from "../../types/DropdownOptions"

type LookupInfoElementProps = {
    className?: string
    value: string
    disabled: boolean
    lookupValueList: DropdownOption<string>[]
    onChange: (value: string) => void
}

const LookupInfoElement = ({ className, value, disabled, lookupValueList, onChange }: LookupInfoElementProps) => {
    const onDropdownValueChange = (option: DropdownOption<string>) => onChange(option.value.toString())

    return (
        <div className={`d-flex ${className}`}>
            {lookupValueList.length > 0 ? (
                <Dropdown
                    className="w-100"
                    options={lookupValueList}
                    onOptionSelected={onDropdownValueChange}
                    selectedOption={lookupValueList.find(
                        v => v.label.toLowerCase() === value.toLowerCase() || v.value.toString().toLowerCase() === value.toLowerCase()
                    )}
                    disabled={disabled}
                    fixedSize={false}
                    textAlign="left"
                />
            ) : (
                <span className="fs-6 text-grey">No Valid Option</span>
            )}
        </div>
    )
}

export default LookupInfoElement
