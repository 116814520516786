import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import Select from 'react-select';
import { optionStyles } from '../../utility/selectSupport';
import { taskDescriptionsSelectList } from '../../selectors/task';

const TaskDescriptionSelect = ({
  id,
  onChange,
  taskDescriptions,
  isDisabled = false
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const hasIdChanged = id !== (selectedOption || {}).value;

  const taskDescriptionsList = taskDescriptionsSelectList(taskDescriptions);

  useEffect(() => {
    if (id && hasIdChanged) {
      const found = find(taskDescriptionsList, (o) => o.value === id);
      if (found) setSelectedOption(found);
    }
  }, [taskDescriptionsList, id, hasIdChanged]);

  const onSelectChange = (selected) => {
    setSelectedOption(selected);
    if (selected === null) {
      return onChange(null);
    }

    onChange(selected.value);
  };

  if (isDisabled) {
    return <h3 className='text-white'>{selectedOption ? selectedOption.label : ''}</h3>;
  }

  return (
    <Select
      value={selectedOption}
      options={taskDescriptionsList.filter((f) => f.isInUse)}
      onChange={onSelectChange}
      placeholder={'Please select'}
      styles={{
        option: optionStyles
      }}
    />
  );
};

TaskDescriptionSelect.propTypes = {
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default TaskDescriptionSelect;
