import { ReactNode, useState } from "react"
import useClickOutsideRef from "../../hooks/useClickOutsideRef"
import DropdownOption from "../../types/DropdownOptions"
import classes from "./Dropdown.module.scss"
import DropOption from "./DropOption"

type DropdownProps<T> = {
    options: DropdownOption<T>[]
    onOptionSelected: (option: DropdownOption<T>) => void
    selectedOption: DropdownOption<T> | undefined
    className?: string
    disabled?: boolean
    direction?: "down" | "up"
    textAlign?: "left" | "center"
    placeholder?: ReactNode
    ariaLabel?: string
    fixedSize?: boolean
    colour?: "white" | "blue"
    displayElementAsLabel?: boolean
}

const Dropdown = <T,>({
    options,
    onOptionSelected,
    selectedOption,
    className = "",
    disabled = false,
    direction = "down",
    textAlign = "center",
    placeholder = "Please select",
    ariaLabel = "dropdown",
    fixedSize = false,
    colour = "white",
    displayElementAsLabel = false
}: DropdownProps<T>) => {
    const ref = useClickOutsideRef(() => setIsOpen(false))
    const [isOpen, setIsOpen] = useState(false)

    const onOptionsClicked = (option: DropdownOption<T>) => {
        setIsOpen(false)
        onOptionSelected(option)
    }

    const toggleOpen = () => {
        if (disabled) return
        setIsOpen(!isOpen)
    }

    const longestOptionInSpaces = options.length > 0 ? "#".repeat(options.map(o => o.label).reduce((a, b) => (a.length > b.length ? a : b)).length) : ""

    const minimumSizeStretchingDiv = (
        <div className="px-2 overflow-hidden fs-6" style={{ height: "0px" }}>
            {longestOptionInSpaces}
            <i className="fal fa-chevron-right ms-2" />
        </div>
    )

    const fixedSizeStrechingDiv = (
        <div className={`px-2 overflow-hidden ${classes.fixedSize}`} style={{ height: "0px" }}>
            <i className="fal fa-chevron-right ms-2" />
        </div>
    )

    const showingOptions = isOpen && options.length > 0 && !disabled

    const displayValue = displayElementAsLabel && selectedOption?.element !== undefined ? selectedOption.element : selectedOption?.label ?? placeholder

    return (
        <div ref={ref} className={`d-flex position-relative no-select ${className} ${disabled ? "not-allowed" : "pointer"}`}>
            <div
                className={`d-flex flex-column position-absolute overflow-auto w-100
                ${classes.options}
                ${classes[`${colour}Background`]}
                ${showingOptions ? classes.selectedOptions : ""} 
                ${direction === "down" ? classes.downwardsOptions : classes.upwardsOptions}`}
            >
                {fixedSize ? fixedSizeStrechingDiv : minimumSizeStretchingDiv}
                {showingOptions &&
                    options.map((option, index) => (
                        <DropOption
                            key={index}
                            option={option}
                            isSelectedOption={JSON.stringify(selectedOption?.value) === JSON.stringify(option.value)}
                            textAlign={textAlign}
                            onOptionClicked={onOptionsClicked}
                        />
                    ))}
            </div>
            <div className="d-flex flex-column w-100">
                <div
                    className={`d-flex px-2 py-1 fs-5
                        ${textAlign === "center" ? "align-items-center text-center justify-content-around" : ""} 
                        ${classes.button}
                        ${classes[`${colour}Background`]}
                        ${disabled ? classes.disabled : ""}
                        ${showingOptions ? (direction === "down" ? classes.selectedDownwardsButton : classes.selectedUpwardsButton) : ""}
                        ${direction === "up" ? classes.onTop : ""}
                        ${fixedSize ? classes.fixedSize : ""}
                    `}
                    role={disabled ? undefined : "button"}
                    aria-label={ariaLabel}
                    onClick={toggleOpen}
                >
                    <span
                        className={`d-flex flex-grow-1 text-truncate fs-6 my-auto ${textAlign === "center" ? "justify-content-center" : ""}`}
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                    >
                        {displayValue}
                    </span>
                    <i
                        className={`fal fa-chevron-right my-auto
                        ${classes.chevron} 
                        ${showingOptions ? (direction === "down" ? classes.selectedDownwardsChevron : classes.selectedUpwardsChevron) : ""}`}
                    />
                </div>
                {fixedSize ? fixedSizeStrechingDiv : minimumSizeStretchingDiv}
            </div>
        </div>
    )
}

export default Dropdown
