import classes from "./CustomerSearchList.module.scss"
import PageNavButtons from "../../../microfrontends/dashboard/components/customerDashboards/widgets/transactionDisplayWidget/PageNavButtons"
import { Loading } from "invevo-react-components"
import CustomerCard from "./CustomerCard"
import StandardButton from "../../../library/buttons/StandardButton/StandardButton"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { CustomerSearchAction, CustomerSearchActionEnum } from "../reducers/CustomerSearchReducer"
import DataRow from "../../../library/dataGrid/types/DataRow"

type CustomerSearchListProps = {
    customers: DataRow[]
    totalCustomerCount: number
    pageIndex: number
    pageSize: number
    isLoadingCustomerData: boolean
    selectedCustomerReference?: string
    setSelectedCustomerReference: (c: string) => void
    dispatch: React.Dispatch<CustomerSearchAction>
}

const CustomerSearchList = ({
    customers,
    totalCustomerCount,
    pageIndex,
    pageSize,
    isLoadingCustomerData,
    selectedCustomerReference,
    setSelectedCustomerReference,
    dispatch
}: CustomerSearchListProps) => {
    const ref = useRef<HTMLDivElement>(null)

    const [goToNextCustomer, setGoToNextCustomer] = useState(false)
    const [goToPreviousCustomer, setGoToPreviousCustomer] = useState(false)

    const isFirstCustomer = () => pageIndex === 0 && customers[0]?.id === selectedCustomerReference
    const isLastCustomer = () => pageIndex === Math.ceil(totalCustomerCount / pageSize) - 1 && customers[customers.length - 1]?.id === selectedCustomerReference

    const onCustomerSelected = (customer: DataRow) => {
        setSelectedCustomerReference(customer.id)
    }

    const scrollToSelectedCustomer = (ref: React.RefObject<HTMLDivElement>) => {
        ref.current?.scrollIntoView({ behavior: "smooth", block: "center" })
    }

    const onPageIndexUpdated = (newPageIndex: number) => {
        dispatch({ type: CustomerSearchActionEnum.PAGE_INDEX_UPDATED, pageIndex: newPageIndex })
    }

    const selectNextCustomer = () => {
        const currentCustomer = customers.find(c => c.id === selectedCustomerReference) as DataRow
        const currentCustomerIndex = customers.indexOf(currentCustomer)
        const isLastCustomerOnCurrentPage = currentCustomerIndex === customers.length - 1

        if (isLastCustomerOnCurrentPage) {
            onPageIndexUpdated(pageIndex + 1)
            setGoToNextCustomer(true)
        } else {
            const nextCustomer = customers[currentCustomerIndex + 1]!
            setSelectedCustomerReference(nextCustomer.id)
        }
    }

    const selectPreviousCustomer = () => {
        const currentCustomer = customers.find(c => c.id === selectedCustomerReference) as DataRow
        const currentCustomerIndex = customers.indexOf(currentCustomer)
        const isFirstCustomerOnCurrentPage = currentCustomerIndex === 0

        if (isFirstCustomerOnCurrentPage) {
            onPageIndexUpdated(pageIndex - 1)
            setGoToPreviousCustomer(true)
        } else {
            const nextCustomer = customers[currentCustomerIndex - 1]!
            setSelectedCustomerReference(nextCustomer.id)
        }
    }

    useLayoutEffect(() => {
        ref.current && scrollToSelectedCustomer(ref)
    }, [selectedCustomerReference])

    useEffect(() => {
        if (!goToNextCustomer || isLoadingCustomerData || !selectedCustomerReference || customers.map(c => c.id).includes(selectedCustomerReference)) return

        setSelectedCustomerReference(customers[0]!.id)
        setGoToNextCustomer(false)
    }, [customers, goToNextCustomer, isLoadingCustomerData, selectedCustomerReference, setSelectedCustomerReference])

    useEffect(() => {
        if (!goToPreviousCustomer || isLoadingCustomerData || !selectedCustomerReference || customers.map(c => c.id).includes(selectedCustomerReference)) return

        setSelectedCustomerReference(customers[pageSize - 1]!.id)
        setGoToPreviousCustomer(false)
    }, [customers, goToPreviousCustomer, isLoadingCustomerData, pageSize, selectedCustomerReference, setSelectedCustomerReference])

    return (
        <div className="d-flex flex-column overflow-hidden" role="list" aria-label="customers">
            <Loading isLoading={isLoadingCustomerData} colour="blue">
                <div className="d-flex px-3 py-2 justify-content-between text-grey">
                    <StandardButton
                        iconClasses="far fa-chevron-left"
                        label="Previous"
                        onClick={selectPreviousCustomer}
                        small={true}
                        disabled={isFirstCustomer()}
                        ariaLabel="previous-customer"
                    />
                    <StandardButton
                        iconClasses="far fa-chevron-right"
                        label="Next"
                        onClick={selectNextCustomer}
                        small={true}
                        iconRight={true}
                        disabled={isLastCustomer()}
                        ariaLabel="next-customer"
                    />
                </div>
                <div className="d-flex flex-column h-100 p-3 overflow-auto">
                    {customers.map(customer => (
                        <div ref={selectedCustomerReference && selectedCustomerReference === customer.id ? ref : null} key={customer.id}>
                            <CustomerCard customer={customer} onClick={onCustomerSelected} isSelected={selectedCustomerReference === customer.id} />
                        </div>
                    ))}
                </div>
            </Loading>
            <div className={`d-flex ${classes.footer} justify-content-between p-2 bg-grey`}>
                <div>
                    {Math.min(pageIndex * pageSize + pageSize, totalCustomerCount) === 0 ? 0 : pageIndex * pageSize + 1}
                    {` - `}
                    {Math.min(pageIndex * pageSize + pageSize, totalCustomerCount)}
                    {` of `}
                    {totalCustomerCount}
                </div>
                <PageNavButtons
                    onPageIndexUpdated={onPageIndexUpdated}
                    pageIndex={pageIndex}
                    totalPageNumber={Math.ceil(totalCustomerCount / pageSize)}
                    disabled={isLoadingCustomerData}
                />
            </div>
        </div>
    )
}

export default CustomerSearchList
