import { useState, useEffect } from 'react'
import { Input, ProgressButton, Loading, useApi, useClient } from 'invevo-react-components'
import classes from './CustomerGroupForm.module.scss'
import { actionTypes } from '../../../reducers/actionTypes'

const CustomerGroupForm = ({
    state,
    dispatch
}) => {
    const api = useApi()
    const client = useClient()
    const [saveDisabled, setSaveDisabled] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isNameValid, setIsNameValid] = useState(false)
    const [showNameValidationMessage, setShowNameValidationMessage] = useState(false)
    const [isFiltersValid, setIsFiltersValid] = useState(false)

    const onNameChange = (event) => {
        setShowNameValidationMessage(true)
        dispatch({ type: actionTypes.SELECTED_CUSTOMER_GROUP_UPDATED, data: { ...state.selectedCustomerGroup, name: event.target.value } })
    }

    useEffect(() => {
        const { name } = state.selectedCustomerGroup
        if (name && name.length >= 3 && name.length <= 500) {
            setIsNameValid(true)
            return
        }

        setIsNameValid(false)
    }, [state.selectedCustomerGroup, state.selectedCustomerGroup.name])

    useEffect(() => {
        const creditFiltersValid = state.selectedCustomerGroup.creditFilters.length > 0 &&
            state.selectedCustomerGroup.creditFilters.every(f => f.isValid)
        const customerFiltersValid = state.selectedCustomerGroup.filters.length > 0 &&
            state.selectedCustomerGroup.filters.every(f => f.isValid)

        setIsFiltersValid(creditFiltersValid || customerFiltersValid)
    }, [state.selectedCustomerGroup.filters, state.selectedCustomerGroup.creditFilters])

    useEffect(() => {
        if (!state.selectedCustomerGroup.reference) {
            return
        }

        if (state.selectedCustomerGroup.isNew && isNameValid && isFiltersValid) {
            setSaveDisabled(false)
            return
        }

        const originalGroup = state.customerGroups.find(rg => rg.reference === state.selectedCustomerGroup.reference)
        if (JSON.stringify(originalGroup) !== JSON.stringify(state.selectedCustomerGroup) && isNameValid && isFiltersValid) {
            setSaveDisabled(false)
            return
        }

        setSaveDisabled(true)
    }, [isFiltersValid, isNameValid, state.customerGroups, state.selectedCustomerGroup])

    const newRequestBody = (group) => {
        return {
            "name": group.name,
            "filters": group.filters.map(f => {
                return {
                    name: f.name,
                    value: f.value,
                    operator: f.operator
                }
            }),
            "creditFilters": group.creditFilters.map(f => {
                return {
                    name: f.name,
                    value: f.value,
                    operator: f.operator
                }
            }),
            "isActive": group.isActive
        }
    }

    const saveCustomerGroup = () => {
        setIsLoading(true)
        const promise = new Promise((resolve, reject) => {
            api.put(`/api/${client}/customer-groups/${state.selectedCustomerGroup.reference}`, newRequestBody(state.selectedCustomerGroup))
                .then(_ => {
                    setIsLoading(false)
                    dispatch({ type: actionTypes.CUSTOMER_GROUPS_UPDATED, data: { ...state.selectedCustomerGroup } })
                    resolve()
                })
                .catch(error => {
                    console.log(error)
                    reject()
                })
        })
        return promise
    }

    const changeIsActiveStatus = (isActiveNow) => {
        const promise = new Promise((resolve, reject) => {
            api.put(`/api/${client}/customer-groups/${state.selectedCustomerGroup.reference}`, newRequestBody({ ...state.selectedCustomerGroup, isActive: isActiveNow }))
                .then(_ => {
                    dispatch({ type: actionTypes.SELECTED_CUSTOMER_GROUP_UPDATED, data: { ...state.selectedCustomerGroup, isActive: isActiveNow } })
                    dispatch({ type: actionTypes.CUSTOMER_GROUPS_UPDATED, data: { ...state.selectedCustomerGroup, isActive: isActiveNow } })
                    resolve()
                })
                .catch(error => {
                    console.log(error)
                    reject()
                })
        })
        return promise
    }

    return (
        <div className={`d-flex flex-column bg-blue ${classes.container} p-3`}>
            <h3 className="text-white no-select">Group details</h3>
            {state.selectedCustomerGroup.reference ?
                <div className="d-flex flex-column flex-grow-1">
                    <Loading isLoading={isLoading} colour="white">
                        <Input
                            className="mb-2"
                            placeholder="Please enter name"
                            label="Name"
                            value={state.selectedCustomerGroup.name}
                            onChange={onNameChange}
                        />
                        {!isNameValid && showNameValidationMessage &&
                            <div className="d-flex text-white mb-3 align-items-center">
                                <i className="fal fa-exclamation-circle me-2"></i>
                                <span>Name must be between 3 and 500 characters in length</span>
                            </div>
                        }
                    </Loading>

                    <div className="d-flex flex-column mt-auto">
                        <ProgressButton
                            className="mb-3 w-100"
                            iconClasses="fal fa-save"
                            label="Save group"
                            colour="blue"
                            onClickWithPromise={() => {
                                const promise = new Promise((resolve, reject) => {
                                    saveCustomerGroup()
                                        .then(_ => {
                                            resolve()
                                        },
                                            _ => reject())
                                        .catch(_ => reject())
                                })
                                return promise
                            }}
                            succeededText="Save successfully!"
                            failedText="Failed to save group!"
                            disabled={saveDisabled || isLoading}
                            disappearable={false}
                        />

                        <ProgressButton
                            className="w-100"
                            iconClasses="fal fa-trash"
                            label={state.selectedCustomerGroup.isActive ? "Archive group" : "Restore Group"}
                            colour="blue"
                            onClickWithPromise={() => {
                                const promise = new Promise((resolve, reject) => {
                                    const result = state.selectedCustomerGroup.isActive
                                        ? changeIsActiveStatus(false)
                                        : changeIsActiveStatus(true)
                                    result
                                        .then(_ => {
                                            resolve()
                                        },
                                            _ => reject())
                                        .catch(_ => reject())
                                })
                                return promise
                            }}
                            succeededText="Success"
                            failedText="Failed!"
                            disabled={state.selectedCustomerGroup.isNew || isLoading}
                            disappearable={false}
                        />
                    </div>
                </div> :
                <h5 className="mt-3 text-white">Please select or create a customer group</h5>
            }
        </div>
    )
}

export default CustomerGroupForm;