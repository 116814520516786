import { useFlags } from 'launchdarkly-react-client-sdk';

const FeatureToggleDisabled = ({className, children, flagKey}) => {
    const flags = useFlags()    
    const isEnabled = flags[flagKey]
    
    return (
        !isEnabled ?
            <div className={className}>
                {children}
            </div>
            :
            <></>
    )
}

export default FeatureToggleDisabled