import DataField from "../../../types/DataField"
import DataPrimitive from "../../../types/DataPrimitive"
import FieldType from "../../../types/enums/FieldType"
import GridColumnConfigDto from "../../../library/dataGrid/types/GridColumnConfigDto"
import { ClientUserDto } from "./types/ClientUserResultsDto"
import Role from "../roles/types/Role"
import DataRow from "../../../library/dataGrid/types/DataRow"

export const ClientUserDataFields: DataField[] = [
    { value: "username", label: "Username", type: DataPrimitive.TEXT, fieldType: FieldType.CLIENT_USER, ordinal: 0 },
    { value: "displayname", label: "Display Name", type: DataPrimitive.TEXT, fieldType: FieldType.CLIENT_USER, ordinal: 1 },
    { value: "active", label: "Is Active", type: DataPrimitive.BOOLEAN, fieldType: FieldType.CLIENT_USER, ordinal: 2 },
    { value: "emailaddress", label: "Email Address", type: DataPrimitive.TEXT, fieldType: FieldType.CLIENT_USER, ordinal: 3 },
    { value: "roles", label: "Roles", type: DataPrimitive.TEXT, fieldType: FieldType.CLIENT_USER, ordinal: 4 }
]

export const DefaultClientUsersGridConfig: GridColumnConfigDto[] = ClientUserDataFields.map(dataField => {
    return {
        fieldName: dataField.value,
        ordinal: dataField.ordinal!!
    }
})

export const DefaultSortByDataField: DataField = { value: "username", label: "Username", type: DataPrimitive.TEXT, fieldType: FieldType.CLIENT_USER }

export const mapToDataRow = (dto: ClientUserDto, roles: Role[]): DataRow => ({
    id: dto.username,
    data: {
        username: dto.username,
        displayname: dto.displayName,
        active: dto.isActive ? "true" : "false",
        emailaddress: dto.emailAddress ?? "",
        roles: dto.roles.map(id => roles.find(role => role.reference === id.roleReference)?.name ?? "").join(", ")
    }
})

export const getDefaultClientUser = (): ClientUserDto => ({
    username: "",
    displayName: "",
    emailAddress: "",
    isActive: false,
    roles: []
})
