import classes from "../XmlExportFieldConfig.module.scss"
import { XmlEntityAggregatedExportNodeState, XmlEntityAggregatedExportParentState } from "../../../../../reducers/FileEntityExportConfigReducer"
import EntityDataField from "../../../../../../entityConfig/types/EntityDataField"
import EntityDataFieldDropdown from "../../../../../../../library/dropdowns/EntityDataFieldDropdown"
import {
    XmlEntityAggregationExportFieldConfig,
    XmlEntityTimestampExportFieldConfig,
    XmlEntityValueExportFieldConfig
} from "../../../../../types/fileEntityExport/FileExportConfig"
import Dropdown from "../../../../../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../../../../../types/DropdownOptions"
import { v4 as uuidv4 } from "uuid"
import { DataPrimitiveTypeEnum } from "../../../../../../entityConfig/types/DataType"
import IconButton from "../../../../../../../library/buttons/IconButton/IconButton"
import Input from "../../../../../../../library/Inputs/Input"

type XmlExportAggregatedFieldConfigCardProps = {
    state: {
        config: XmlEntityAggregatedExportNodeState
        dataFields: EntityDataField[]
        onEditingNodeChanged: (changedNode: XmlEntityAggregatedExportNodeState) => void
        saveEditingConfig: () => void
    }
}

const aggregationTypeOptions: DropdownOption<"SUM" | "AVERAGE" | "MIN" | "MAX" | "CARDINALITY">[] = [
    {
        label: "Sum",
        value: "SUM"
    },
    {
        label: "Average",
        value: "AVERAGE"
    },
    {
        label: "Min",
        value: "MIN"
    },
    {
        label: "Max",
        value: "MAX"
    },
    {
        label: "Unique Count",
        value: "CARDINALITY"
    }
]

const dropdownOptions: DropdownOption<"aggregation" | "timestamp" | "parent" | "value">[] = [
    {
        label: "Aggregation",
        value: "aggregation"
    },
    {
        label: "Timestamp",
        value: "timestamp"
    },
    {
        label: "Value",
        value: "value"
    },
    {
        label: "Nested Field",
        value: "parent"
    }
]
const XmlExportAggregatedFieldConfigCard = ({ state }: XmlExportAggregatedFieldConfigCardProps) => {
    const { config, dataFields, onEditingNodeChanged, saveEditingConfig } = state

    const onDropdownOptionChange = (option: DropdownOption<"aggregation" | "timestamp" | "parent" | "value">) => {
        onEditingNodeChanged({
            ...config,
            type: option.value
        } as XmlEntityAggregatedExportNodeState)
    }

    const onNameChange = (value: string) => {
        onEditingNodeChanged({
            ...config,
            name: value
        })
    }
    const onDataFieldChange = (option?: EntityDataField | undefined) => {
        if (!option || config.type !== "aggregation") return
        onEditingNodeChanged({
            ...config,
            dataFieldReference: option.fieldName
        })
    }

    const onAggregationTypeChange = (aggregationTypeOptions: DropdownOption<"SUM" | "AVERAGE" | "MIN" | "MAX" | "CARDINALITY">) => {
        if (config.type !== "aggregation") return
        onEditingNodeChanged({
            ...config,
            aggregationType: aggregationTypeOptions.value
        })
    }

    const onValueChange = (value: string) => {
        if (config.type !== "value") return

        onEditingNodeChanged({
            ...config,
            value
        })
    }
    const onDateFormatChange = (value: string) => {
        if (config.type !== "timestamp") return

        onEditingNodeChanged({
            ...config,
            dateFormat: value
        })
    }

    const isRoot = config.type === "parent" && config.isRoot

    const addChildNode = () => {
        if (config.type !== "parent") return

        onEditingNodeChanged({
            ...config,
            children: [
                ...config.children,
                {
                    name: "New xml node",
                    key: uuidv4(),
                    parentKey: config.key,
                    type: "parent",
                    children: [],
                    selected: false,
                    isRoot: false,
                    value: "",
                    dataFormat: "",
                    dataFieldReference: "",
                    aggregationType: "SUM"
                } as XmlEntityAggregatedExportNodeState
            ]
        })
    }

    const getDataField = () => {
        if (config.type === "aggregation") {
            return dataFields.find(df => df.fieldName === config.dataFieldReference)
        }
    }
    const renderAggregationField = (fieldConfig: XmlEntityAggregationExportFieldConfig) => (
        <div>
            <div className="d-flex flex-grow-1">
                <div className={`d-flex flex-grow-1 flex-column small me-3`}>
                    <span className="text-uppercase small mb-1 ms-1 text-grey">Aggregation type</span>
                    <Dropdown
                        className="mb-2"
                        ariaLabel="aggregationTypeDropdown"
                        options={aggregationTypeOptions}
                        selectedOption={aggregationTypeOptions.find(o => o.value === fieldConfig.aggregationType)}
                        onOptionSelected={onAggregationTypeChange}
                    />
                    <span className="text-uppercase small mb-1 ms-1 text-grey">Aggregation data field</span>
                    <EntityDataFieldDropdown
                        className="flex-grow-1 mb-2"
                        options={state.dataFields.filter(field => field.dataPrimitiveType === DataPrimitiveTypeEnum.NUMBER)}
                        selectedOption={getDataField()}
                        onOptionSelected={onDataFieldChange}
                        ariaLabel={"Add aggregation data field"}
                    />
                </div>
            </div>
        </div>
    )

    const renderParent = (parentConfig: XmlEntityAggregatedExportParentState) => (
        <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
                <span className="text-grey">Add child node </span>
                <span className="text-grey">current children: {parentConfig.children.length}</span>
            </div>
            <IconButton ariaLabel="Add child node" theme="grey" icon="far fa-add" onClick={addChildNode} />
        </div>
    )

    const renderValueField = (config: XmlEntityValueExportFieldConfig) => (
        <div>
            <div className="d-flex flex-grow-1">
                <div className={`d-flex flex-grow-1 flex-column small me-3`}>
                    <div className="d-flex align-items-center mb-2">
                        <span className="fs-4 text-grey">Value</span>
                    </div>

                    <Input className="" placeholder="Please enter a value" value={config.value} onChange={onValueChange} />
                </div>
            </div>
        </div>
    )
    const renderTimestampField = (config: XmlEntityTimestampExportFieldConfig) => (
        <div>
            <div className="d-flex flex-grow-1">
                <div className={`d-flex flex-grow-1 flex-column small me-3`}>
                    <div className="d-flex align-items-center mb-2">
                        <span className="fs-4 text-grey">Date Format</span>
                    </div>

                    <Input className="" placeholder="Please enter a value" value={config.dateFormat} onChange={onDateFormatChange} />
                </div>
            </div>
        </div>
    )
    const renderCard = () => {
        switch (config.type) {
            case "parent":
                return renderParent(config)
            case "value":
                return renderValueField(config)
            case "aggregation":
                return renderAggregationField(config)
            case "timestamp":
                return renderTimestampField(config)
        }
    }
    return (
        <div className={`d-flex w-100 ${classes.card}`}>
            <div className="d-flex flex-column p-4 w-100">
                <div className={`d-flex flex-grow-1 flex-column small`}>
                    <div className="d-flex align-items-center mb-2">
                        <span className="fs-4 text-grey">Node name</span>
                    </div>

                    <Input className="" placeholder="Please enter the field name" value={config.name} onChange={onNameChange} />
                </div>
                <div className={`${classes.horizontalDivider} m-2`} />
                {renderCard()}
            </div>
            <div className={"d-flex flex-column align-content-end justify-content-between m-2"}>
                {!isRoot && (
                    <Dropdown
                        options={dropdownOptions}
                        onOptionSelected={onDropdownOptionChange}
                        selectedOption={dropdownOptions.find(option => option.value === config.type)}
                        direction={"up"}
                    />
                )}
                <div className="d-flex justify-content-end">
                    <IconButton ariaLabel="Save node" theme="grey" icon="far fa-check" onClick={saveEditingConfig} />
                </div>
            </div>
        </div>
    )
}

export default XmlExportAggregatedFieldConfigCard
