import React, { Component } from 'react';
import { connect } from 'react-redux';
import { executeAuthAsyncGet, executeAuthAsyncPost } from '../../utility/asyncSupport';
import { showToastSuccessMessage } from '../../api/toasterApi';
import {  creditLimitRequestTypeNames, creditLimitRequestTypes} from './creditConstants';
import { getLookup } from '../../api/lookupApi';
import { registerForIntl, provideIntlService } from '@progress/kendo-react-intl';
import RenderRow from './renderRow';
import { trim } from 'lodash';
import { ConfrimDialog } from '../common/confirmDialog';
import ComponentLoader from '../common/ComponentLoader';
import { displayErrorMessage } from '../../utility/error';

class RequestDisplay extends Component {
  state = {
    showDialog: false,
    isLoading: false
  };

  componentDidMount() {
    if (
      !this.props.reasonsLimitIncreaseLookup ||
      this.props.reasonsLimitIncreaseLookup.length === 0
    )
      this.props.getLookup(
        this.props.client,
        'lookup/ReasonsForLimitIncrease',
        'reasonsLimitIncrease'
      );
  }

  render() {
    const req = this.props.pendingRequest.result;

    if (req === null) {
      return <div></div>;
    }

    const requestTypeId =
      req.RequestExpiryDate == null
        ? creditLimitRequestTypeNames.CREDIT_LIMIT
        : creditLimitRequestTypeNames.TEMPORARY_CREDIT_LIMIT;
    const visibleExpirationDate = req.RequestExpiryDate != null;

    let reason = '';
    const reasonObj = this.props.reasonsLimitIncreaseLookup.find(
      (o) => o.Id === req.RequestReasonId
    );
    if (reasonObj) {
      reason = reasonObj.Description;
    }

    let type = '';
    const typeObj = creditLimitRequestTypes.find(
      (o) => o.value === requestTypeId
    );
    if (typeObj) {
      type = typeObj.label;
    }

    const requestNote = req.NoteTextRequest;

    const usersListItems = [...req.FirstUserIds]
      .sort((a, b) => {
        if (a.Description.toUpperCase() < b.Description.toUpperCase())
          return -1;
        if (a.Description.toUpperCase() > b.Description.toUpperCase()) return 1;
        return 0;
      })
      .map((u) => (
        <option value={u.Id} key={u.Id}>
          {trim(u.Description)}
        </option>
      ));

    return (
      <div className="top30">
        <div>
          <h3 className='text-white'>Request</h3>

          <hr />

          <RenderRow
            leftContent="Amount"
            rightContent={
              <span>
                {this.props.globalCurrencyCode}{' '}
                {provideIntlService(this).formatNumber(
                  req.RequestAmount,
                  this.props.currencyFormat
                )}
              </span>
            }
            leftWidth={4}
            rightWidth={8}
          />
          <RenderRow
            leftContent="User"
            rightContent={<span>{req.RequestUserName}</span>}
            leftWidth={4}
            rightWidth={8}
          />

          <RenderRow
            leftContent="Type"
            rightContent={<span>{type}</span>}
            leftWidth={4}
            rightWidth={8}
          />

          {visibleExpirationDate && (
            <RenderRow
              leftContent="Expiry Date"
              rightContent={
                <span>
                  {provideIntlService(this).formatDate(
                    new Date(req.RequestExpiryDate),
                    this.props.dateFormat
                  )}
                </span>
              }
              leftWidth={4}
              rightWidth={8}
            />
          )}

          <RenderRow
            leftContent="Reason"
            rightContent={<span>{reason}</span>}
            leftWidth={4}
            rightWidth={8}
          />
          {requestNote &&
            <RenderRow
              leftContent="Request note"
              rightContent={
                <span>
                  {requestNote}
                </span>
              }
              leftWidth={4}
              rightWidth={8}
            />
          }
          <RenderRow
            leftContent="Assigned to"
            rightContent={
              <select size="4" disabled style={{ width: '100%' }}>
                {usersListItems}
              </select>
            }
            leftWidth={4}
            rightWidth={8}
          />

          <div className="clearfix">
            <button
              className="btn btn-primary float-right"
              onClick={this.onRequestDisplayCancelClicked}
            >
              Cancel
            </button>
          </div>

          <ConfrimDialog
            show={this.state.showDialog}
            message={'Are you sure you want to cancel this request?'}
            onConfirm={this.actionDisplayCancel}
            onClose={this.closeDialog}
          />
        </div>
        {this.state.isLoading && <ComponentLoader />}
      </div>
    );
  }

  onRequestDisplayCancelClicked = (e) => {
    e.preventDefault();
    this.setState({
      showDialog: true
    });
  };

  closeDialog = () => {
    this.setState({
      showDialog: false
    });
  };

  actionDisplayCancel = () => {
    this.setState({ isLoading: true });
    const request = this.props.pendingRequest.result;

    const decisionObject = {
      Id: request.Id,
      CustomerId: request.CustomerId,
      VirtualAccountId: request.VirtualAccountId,
      DecisionOneAmount: request.RequestAmount,
      DecisionOneType: 0,
      DecisionOneReasonId: 999,
      NoteTextDecisionOne: ''
    };
    this.props.executeAuthAsyncPost(
      this.props.client,
      'CreditLimit/InsertFirstDecision',
      'INSERT_FIRST_DECISION',
      decisionObject,
      () => {
        showToastSuccessMessage('Request Cancelled.');

        this.props.refreshCallback();
        this.closeDialog();
        this.setState({ isLoading: false });
      },
      (error) => {
        displayErrorMessage(error);

        this.closeDialog();
        this.setState({ isLoading: false });
      }
    );
  };
}

const mapStateToProps = (state) => {
  const { lookupReducer, asyncReducer } = state;
  const { reasonsLimitIncrease } = lookupReducer;
  const { GET_PENDING_REQUEST } = asyncReducer;
  return {
    reasonsLimitIncreaseLookup: reasonsLimitIncrease.results,
    pendingRequest: GET_PENDING_REQUEST,
    globalCurrencyCode:
      state.lookupReducer.globalSettings.results.globalCurrencyCode,
    currencyFormat: state.lookupReducer.globalSettings.results.currencyFormat,
    dateFormat: state.lookupReducer.globalSettings.results.dateFormat
  };
};

registerForIntl(RequestDisplay);

export default connect(mapStateToProps, {
  getLookup,
  executeAuthAsyncGet,
  executeAuthAsyncPost
})(RequestDisplay);
