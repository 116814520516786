import classes from './DraggableWidget.module.scss'
import { widgetSizes } from '../../../enums/widgetSizes'

type DraggableWidgetProps = {
    className?: string
    name: string
    description: string
    iconClasses?: string
    size: widgetSizes
    disabled?: boolean
    onClick: () => void
}
const DraggableWidget = ({className, name, description, iconClasses, size, disabled=false, onClick}: DraggableWidgetProps) => {
    
    const onWidgetClick = () => {
        if (disabled) {
            return
        }

        onClick && onClick()
    }
    
    const getSquares = () => {
        switch(size) {
            case widgetSizes.ONE:
                return (
                    <div className={`d-flex h-100 ${classes.size} bg-light-blue`}>
                        <div className="h-50 w-50 pe-1 pb-1">
                            <div className="w-100 h-100 bg-white"></div>
                        </div>
                    </div>
                )
            case widgetSizes.FOUR:
                return (
                    <div className={`d-flex flex-column h-100 ${classes.size} bg-light-blue`}>
                        <div className="d-flex pb-1 h-50">
                            <div className="w-50 pe-1">
                                <div className="w-100 h-100 bg-white"></div>
                            </div>
                            <div className="w-50 ps-1">
                                <div className="w-100 h-100 bg-white" />
                            </div>
                        </div>
                        <div className="d-flex pt-1 h-50">
                            <div className="w-50 pe-1">
                                <div className="w-100 h-100 bg-white"></div>
                            </div>
                            <div className="w-50 ps-1">
                                <div className="w-100 h-100 bg-white" />
                            </div>
                        </div>
                    </div>
                )
            default:
                return (
                    <div className={`h-100 ${classes.size} bg-white`}>
                        
                    </div>
                )
        }
    }
    
    return (
        <div className={`${className ? className : ''} d-flex bg-blue no-select p-2 ${disabled ? `not-allowed ${classes.disabled}` : 'pointer'} ${classes.widget}`} onClick={onWidgetClick}>
            <div className="d-flex flex-column">
                <div className="d-flex align-items-center text-white">
                    <i className={iconClasses}></i>
                    <span className="ms-1">{name}</span>
                </div>
                <span className="text-light-blue me-1">{description}</span>
            </div>
            <div className="d-flex ms-auto">
                { getSquares() }
            </div>
        </div>
    )
}

export default DraggableWidget