import React from 'react';

export default function EditDeleteCommandCell({ onEditClick, onDeleteClick }) {
  return (props) => {
    const { dataItem } = props;
    const showDelete =
      dataItem.CanDelete === undefined || dataItem.CanDelete === true;

    return (
      <td className="k-command-cell">
        <button
          className={'k-button k-grid-ViewEmail'}
          onClick={e => onEditClick(e, dataItem)}
        >
          Edit
        </button>

        {showDelete && (
          <button
            className={'k-button k-grid-QuickReply'}
            onClick={e => onDeleteClick(e, dataItem)}
          >
            Delete
          </button>
        )}
      </td>
    );
  }
}
