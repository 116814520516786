import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { addParent } from '../../api/vpApi';
import { showToastErrorMessage } from '../../api/toasterApi';
import { CommandCell } from '../common/grids/commandCell';
import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../common/grids/StatefulGrid';
import { getApiUrl } from '../../constants';
import { useClient } from 'invevo-react-components';
import { normaliseFormatDef } from '../../selectors/lookup';

const SearchVirtualParent = ({
  selectedAccount,
  addParent,
  currencyFormat,
  vaCloned,
  vaRemoved,
  vaAdded,
  vaAddedErrMsg,
  onChange
}) => {
  const [searchBox, setSearchBox] = useState('');
  const [accountCode, setAccountCode] = useState('');
  
  const client = useClient()

  useEffect(() => {
    if (vaCloned || vaRemoved || vaAdded) {
      setSearchBox('');
      setAccountCode('');
    }
  }, [vaCloned, vaRemoved, vaAdded]);

  useEffect(() => {
    if (vaAddedErrMsg) {
      showToastErrorMessage(vaAddedErrMsg);
    }
  }, [vaAddedErrMsg]);

  const onAddParent = (e, dataItem) => {
    e.preventDefault();
    addParent(
      client,
      selectedAccount.miaAccountId,
      dataItem.Id,
      selectedAccount.miaAccountIsVirtualAccount
    ).then(() => {
      onChange();
      setSearchBox('');
      setAccountCode('');
    });
  };

  const searchVP = () => {
    setAccountCode(searchBox);
    setSearchBox('');
  };

  const additionalRequestPayload = {
    id: selectedAccount.miaAccountId,
    isVirtualParent: selectedAccount.miaAccountIsVirtualAccount,
    accountCode:
      accountCode === undefined || accountCode === '' ? '.' : accountCode
  };

  const onSearchChange = (e) => setSearchBox(e.target.value);

  const showResults = accountCode !== '';

  return (
    <div>
      <h3 className="m-2">Search virtual parent</h3>
      <div className="m-1 form-row">
        <div>
          <input
            className="form-control"
            type="text"
            size="20"
            maxLength="100"
            value={searchBox}
            onChange={onSearchChange}
          />
        </div>
        <div>
          <button onClick={searchVP} className="btn btn-default ml-2">
            Search Account Code
          </button>
        </div>
      </div>

      {showResults && (
        <StatefulGrid
          client={client}
          path={
            getApiUrl(client) + 'api/VirtualAccount/GetVirtualParentsByAccountCode'
          }
          additionalRequestPayload={additionalRequestPayload}
          defaultField={{ field: 'Date' }}
          pageable={{
            pageSizes: [50, 100, 200],
            buttonCount: 5
          }}
          hideablePaging={true}
          className={'custom-grid scrollable-none'}
          sortable={true}
        >
          <GridColumn field={'Name'} title={'Name'} />
          <GridColumn field={'AccountCode'} title={'Account Code'} />
          <GridColumn field={'VirtualLevel'} title={'Virtual Level'} />
          <GridColumn
            field={'CreditLimit'}
            title={'Credit Limit'}
            format={currencyFormat}
            className="text-right"
          />
          <GridColumn field={'CreditLimitCurrency'} title={'Currency'} />
          <GridColumn field={'IsCreditLimitHolder'} title={'Credit Holder'} />
          <GridColumn
            cell={CommandCell({
              onCommandClick: onAddParent,
              cssTag: 'hierarchy',
              buttonText: <i className='fa-solid fa-plus'></i>
            })}
            title={'Add'}
            width={'60px'}
          />
        </StatefulGrid>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    vaCloned: state.vpReducer.vaCloned,
    vaRemoved: state.vpReducer.vaRemoved,
    vaAdded: state.vpReducer.vaAdded,
    vaAddedErrMsg: state.vpReducer.vaAddedErrMsg,
    selectedAccount: state.currentSelectionReducer.selectedAccount,
    currencyFormat: normaliseFormatDef(
      state.lookupReducer.globalSettings.results.currencyFormat
    )
  };
};

export default connect(mapStateToProps, {
  addParent
})(SearchVirtualParent);
