import { useFeatureToggle } from "../../hooks/useFeatureToggle"
import { DataTypeEnum } from "../../routes/entityConfig/types/DataType"
import EntityDataField from "../../routes/entityConfig/types/EntityDataField"
import { convertToCurrency } from "../helpers/numberUtils"
import classes from "./PieChartDetails.module.scss"

type PieChartDetail = {
    label: string
    value: number
    displayField: EntityDataField | undefined
}

type PieChartDetailProps = {
    detail: PieChartDetail
    textColour: string
    dotColour: string
    onMouseOver: (item: string) => React.MouseEventHandler<HTMLDivElement>
    onMouseOut: () => void
    onClick: (value: string) => void
}

const getDisplayValue = (field: EntityDataField, value: number, hideCurrency: boolean = false): string => {
    switch (field.dataType.type) {
        case DataTypeEnum.MONEY:
            return convertToCurrency(value, field.dataType.currencyCode, undefined, hideCurrency)
        case DataTypeEnum.INT:
            return parseInt(value.toString()).toFixed(0)
        case DataTypeEnum.DECIMAL:
            return parseFloat(value.toString()).toFixed(2)
        default:
            return value.toString()
    }
}

const PieChartDetails = ({ detail, textColour, dotColour, onMouseOver, onMouseOut, onClick }: PieChartDetailProps) => {
    const { isEnabled: hideCurrency } = useFeatureToggle("removeCurrencySymbols")
    const onDetailsClick = () => onClick(detail.label)

    return (
        <div className="d-flex flex-column p-2 pointer flex-grow-1" onMouseOver={onMouseOver(detail.label)} onMouseOut={onMouseOut} onClick={onDetailsClick}>
            <div className="d-flex align-items-center">
                <div className={classes["legend-colour-dot"]} style={{ backgroundColor: dotColour }} />
                <div className={`text-truncate ms-2 ${textColour === "white" ? classes["label-white"] : "text-white"}`}>{detail.label}</div>
            </div>
            <div className={`text-truncate fs-3 ${classes["item-value"]} ${textColour === "white" ? "text-grey" : "text-white"}`} aria-label={detail.label}>
                {detail.displayField ? getDisplayValue(detail.displayField, detail.value, hideCurrency) : detail.value}
            </div>
        </div>
    )
}

export default PieChartDetails
