import { useState } from 'react'
import classes from './RoleCard.module.scss'
import AssignUsersToRole from '../forms/AssignUsersToRole'
import AddPermissionToRole from '../forms/AddPermissionToRole'
import UserPermission from '../permissions/UserPermission'
import CustomerPermission from '../permissions/CustomerPermission'
import AutomatePermission from '../permissions/AutomatePermission'
import BrandPermission from '../permissions/BrandPermission'
import CalculatePermission from '../permissions/CalculatePermission'
import ConnectPermission from '../permissions/ConnectPermission'
import DashboardPermission from '../permissions/DashboardPermission'
import DataConfigPermission from '../permissions/DataConfigPermission'
import IntegrationPermission from '../permissions/IntegrationPermission'
import PayPermission from '../permissions/PayPermission'
import TaskPermission from '../permissions/TaskPermission'
import MakeCallPermission from '../permissions/MakeCallPermission'
import { convertRoleToDto } from '../helpers'
import { useApi, useClient } from 'invevo-react-components'
import { actionTypes } from '../../../reducers/actionTypes'

const RoleCard = ({ state, dispatch, role }) => {
    const client = useClient()
    const api = useApi()

    const [showAssignClientUsersForm, setShowAssignClientUsersForm] = useState(false)
    const [showAddAdminPermissionsForm, setShowAddAdminPermissionsForm] = useState(false)

    const hasPermissions =
        role.customerPermission ||
        role.userPermission ||
        role.automatePermission ||
        role.brandPermission ||
        role.calculatePermission ||
        role.connectPermission ||
        role.dashboardPermission ||
        role.dataConfigPermission ||
        role.integrationPermission ||
        role.payPermission ||
        role.taskPermission ||
        role.makeCallPermission

    const putUpdatedPermissionToApi = (permissionType, permission, callback) => {
        const updatedRole = {
            ...role,
            [permissionType]: permission
        }

        const dto = convertRoleToDto(updatedRole)
        api.put(`/api/${client}/roles/${role.id}`, dto)
            .then(_ => {
                dispatch({ type: actionTypes.ROLE_PERMISSIONS_UPDATED, updatedRole: { id: role.id, ...updatedRole } })
                callback()
            })
            .catch(error => {
                console.error(error)
            })
    }

    return (
        <div className={`d-flex flex-column no-select text-grey ${classes["role-card"]} ${classes.shadow}`} role="group" aria-label={`role-card-${role.name}`}>
            <div className={`d-flex flex-row align-items-center p-2 mt-2 pb-3 ${classes.header}`}>
                <i className="text-blue fal fa-id-card-alt fa-2x mx-2" />
                <h5 className="mb-0 me-3 text-uppercase text-truncate">{role.name}</h5>
                <div className="ms-auto d-flex align-items-center text-blue pointer" role="button" aria-label={`assign-users-${role.name}`} onClick={() => setShowAssignClientUsersForm(true)}>
                    <span className="text-uppercase me-1 text-nowrap">assign users</span>
                    <i className="fas fa-user-plus"></i>
                </div>
            </div>

            <div className="d-flex flex-column text-grey my-2 mx-2">
                <div className={`d-flex align-items-center py-1 px-3 ${classes["bg-darker-grey"]}`}>
                    <span className="text-uppercase">Permissions</span>
                    <div className="ms-auto d-flex align-items-center text-blue pointer" role="button" aria-label="Add permissions" onClick={() => setShowAddAdminPermissionsForm(true)}>
                        <span className="text-uppercase me-1 text-nowrap">Add</span>
                        <i className="fas fa-plus-circle"></i>
                    </div>
                </div>
                {
                    !hasPermissions &&
                    <span className="mt-2 ms-3 fst-italic" role="textbox" aria-label='no-permissions-added'>No permissions added</span>
                }
                {hasPermissions &&
                    <div className="d-flex flex-column">
                        {role.customerPermission &&
                            <CustomerPermission
                                permission={role.customerPermission}
                                role={role}
                                dispatch={dispatch}
                                isOnRole={true}
                                customerFields={state.customerFields}
                                onPermissionUpdated={(permission, callback) => putUpdatedPermissionToApi('customerPermission', permission, callback)}
                            />}
                        {role.userPermission && <UserPermission permission={role.userPermission} role={role} dispatch={dispatch} isOnRole={true} />}
                        {role.automatePermission && <AutomatePermission permission={role.automatePermission} role={role} dispatch={dispatch} isOnRole={true} />}
                        {role.brandPermission && <BrandPermission permission={role.brandPermission} role={role} dispatch={dispatch} isOnRole={true} />}
                        {role.calculatePermission && <CalculatePermission permission={role.calculatePermission} role={role} dispatch={dispatch} isOnRole={true} />}
                        {role.connectPermission && <ConnectPermission permission={role.connectPermission} role={role} dispatch={dispatch} isOnRole={true} />}
                        {role.dashboardPermission && <DashboardPermission permission={role.dashboardPermission} role={role} dispatch={dispatch} isOnRole={true} />}
                        {role.dataConfigPermission && <DataConfigPermission permission={role.dataConfigPermission} role={role} dispatch={dispatch} isOnRole={true} />}
                        {role.integrationPermission && <IntegrationPermission permission={role.integrationPermission} role={role} dispatch={dispatch} isOnRole={true} />}
                        {role.payPermission && <PayPermission permission={role.payPermission} role={role} dispatch={dispatch} isOnRole={true} />}
                        {role.taskPermission && <TaskPermission permission={role.taskPermission} role={role} dispatch={dispatch} isOnRole={true} />}
                        {role.makeCallPermission && <MakeCallPermission permission={role.makeCallPermission} role={role} dispatch={dispatch} isOnRole={true} />}
                    </div>
                }
            </div>
            {
                showAssignClientUsersForm &&
                <AssignUsersToRole state={{ role, users: state.clientUsers }} dispatch={dispatch} onClose={() => setShowAssignClientUsersForm(false)} />
            }
            {
                showAddAdminPermissionsForm &&
                <AddPermissionToRole state={{ role }} dispatch={dispatch} onClose={() => setShowAddAdminPermissionsForm(false)} />
            }
        </div>
    )
}

export default RoleCard