import { IconButton, Input } from "invevo-react-components"
import React from "react"
import Dropdown from "../../../../../../library/dropdowns/Dropdown"
import { FileImportConfigFieldTrimOptionState } from "../../../../reducers/FileImportConfigReducer"
import { FileImportConfigFieldTrimOption, FileImportConfigFieldTrimOptionEnum } from "../../../../types/FileImportConfigTrimOption"
import { isValidPositiveInteger } from "../../../../../../library/helpers/numberUtils"

type TrimOptionConfigProps = {
    trimOption: FileImportConfigFieldTrimOptionState
    onChange: (option: FileImportConfigFieldTrimOptionState) => any
    onDelete: (option: FileImportConfigFieldTrimOptionState) => any
}

const TrimOptionConfig = ({ trimOption, onChange, onDelete }: TrimOptionConfigProps) => {
    const trimOptionTypes = [
        { id: 1, value: FileImportConfigFieldTrimOptionEnum.REMOVE_STRING, label: "Remove matching text from anywhere" },
        { id: 2, value: FileImportConfigFieldTrimOptionEnum.REMOVE_STRING_FROM_START, label: "Remove matching text from start" },
        { id: 3, value: FileImportConfigFieldTrimOptionEnum.REMOVE_STRING_FROM_END, label: "Remove matching text from end" },
        { id: 4, value: FileImportConfigFieldTrimOptionEnum.REMOVE_FIRST_N_CHARACTERS, label: "Remove first X characters" },
        { id: 5, value: FileImportConfigFieldTrimOptionEnum.REMOVE_LAST_N_CHARACTERS, label: "Remove last X characters" },
        { id: 6, value: FileImportConfigFieldTrimOptionEnum.TAKE_FIRST_N_CHARACTERS, label: "Take first X characters" },
        { id: 7, value: FileImportConfigFieldTrimOptionEnum.TAKE_LAST_N_CHARACTERS, label: "Take last X characters" },
        { id: 8, value: FileImportConfigFieldTrimOptionEnum.TAKE_BEFORE_FIRST_STRING, label: "Take before first matching text" },
        { id: 9, value: FileImportConfigFieldTrimOptionEnum.TAKE_AFTER_LAST_STRING, label: "Take after last matching text" }
    ]

    const onTrimOptionTypeChange = (trimOptionType: any) => {
        onChange({
            ...trimOption,
            type: trimOptionType.value,
            characters: "",
            take: 1
        })
    }

    const onTakeChange = (event: React.FormEvent<HTMLInputElement>) => {
        if (!isValidPositiveInteger(event.currentTarget.value)) {
            return
        }

        const take = parseInt(event.currentTarget.value)

        switch (trimOption.type) {
            case FileImportConfigFieldTrimOptionEnum.REMOVE_FIRST_N_CHARACTERS:
            case FileImportConfigFieldTrimOptionEnum.REMOVE_LAST_N_CHARACTERS:
            case FileImportConfigFieldTrimOptionEnum.TAKE_FIRST_N_CHARACTERS:
            case FileImportConfigFieldTrimOptionEnum.TAKE_LAST_N_CHARACTERS:
                onChange({
                    ...trimOption,
                    take
                })
                return
            default:
                console.error("Invalid trim option type")
        }
    }

    const onCharactersChange = (event: React.FormEvent<HTMLInputElement>) => {
        const characters = event.currentTarget.value
        switch (trimOption.type) {
            case FileImportConfigFieldTrimOptionEnum.REMOVE_STRING:
            case FileImportConfigFieldTrimOptionEnum.REMOVE_STRING_FROM_END:
            case FileImportConfigFieldTrimOptionEnum.REMOVE_STRING_FROM_START:
            case FileImportConfigFieldTrimOptionEnum.TAKE_AFTER_LAST_STRING:
            case FileImportConfigFieldTrimOptionEnum.TAKE_BEFORE_FIRST_STRING:
                onChange({
                    ...trimOption,
                    characters
                })
                return
            default:
                console.error("Invalid trim option type")
        }
    }

    const onRemoveTrimOptionClick = () => onDelete(trimOption)

    const getTrimInputByType = (trimOption: FileImportConfigFieldTrimOption) => {
        switch (trimOption.type) {
            case FileImportConfigFieldTrimOptionEnum.REMOVE_FIRST_N_CHARACTERS:
            case FileImportConfigFieldTrimOptionEnum.REMOVE_LAST_N_CHARACTERS:
            case FileImportConfigFieldTrimOptionEnum.TAKE_FIRST_N_CHARACTERS:
            case FileImportConfigFieldTrimOptionEnum.TAKE_LAST_N_CHARACTERS:
                return <Input placeholder="Please enter the value for the trim option" value={trimOption.take} onChange={onTakeChange} type="number" />
            case FileImportConfigFieldTrimOptionEnum.TAKE_AFTER_LAST_STRING:
            case FileImportConfigFieldTrimOptionEnum.TAKE_BEFORE_FIRST_STRING:
            case FileImportConfigFieldTrimOptionEnum.REMOVE_STRING:
            case FileImportConfigFieldTrimOptionEnum.REMOVE_STRING_FROM_END:
            case FileImportConfigFieldTrimOptionEnum.REMOVE_STRING_FROM_START:
                return <Input placeholder="Please enter the value for the trim option" value={trimOption.characters} onChange={onCharactersChange} />
        }
    }

    return (
        <div className="d-flex w-100 h-100">
            <Dropdown
                className="me-3"
                placeholder={"Please select trim option"}
                selectedOption={trimOptionTypes.find(tot => tot.value === trimOption.type)}
                options={trimOptionTypes}
                onOptionSelected={onTrimOptionTypeChange}
                ariaLabel="Please select trim option"
            />
            {getTrimInputByType(trimOption)}
            <IconButton className="ms-auto" iconClasses="far fa-trash" onClick={onRemoveTrimOptionClick} />
        </div>
    )
}

export default TrimOptionConfig
