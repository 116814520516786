import React from 'react';
import { connect } from 'react-redux';
import AccountCard from './accountCard';
import AccountDetail from './accountDetail';
import { useInternationalization } from '@progress/kendo-react-intl';

const CreditLimitHistoryCard = ({
  title,
  history,
  open,
  setOpen,
  dateFormat,
  currencyFormat,
  globalCurrencyCode
}) => {
  const internationalization = useInternationalization();

  return (
    <AccountCard title={title} open={open} setOpen={setOpen}>
      {history && history.length > 0 ? (
        history.map((h) => (
          <AccountDetail key={`decision-${h.$id}`}>
            {globalCurrencyCode}{' '}
            {internationalization.formatNumber(h.Amount, currencyFormat)}{' '}
            {internationalization.formatDate(
              new Date(h.LatestDecisionDateTime),
              dateFormat
            )}{' '}
            {h.Approved ? (
              <span className={'positive'}>Approved</span>
            ) : (
              <span className={'negitive'}>Declined</span>
            )}
          </AccountDetail>
        ))
      ) : (
        <AccountDetail title="None" />
      )}
    </AccountCard>
  );
};

const mapStateToProps = (state) => ({
  dateFormat: state.lookupReducer.globalSettings.results.dateFormat,
  currencyFormat: state.lookupReducer.globalSettings.results.currencyFormat,
  globalCurrencyCode:
    state.lookupReducer.globalSettings.results.globalCurrencyCode
});
export default connect(mapStateToProps)(CreditLimitHistoryCard);
