import { useEffect, useState } from "react"
import Mobile from "../../../Mobile"
import classes from './SmsTemplateWorkspace.module.scss'

const SmsTemplateWorkspace = ({
    initialSmsBody,
    selectedTemplate,
    setSelectedTemplate
}) => {
    const [text, setText] = useState("")

    useEffect(() => {
        setText(initialSmsBody)
    }, [initialSmsBody])

    useEffect(() => {
        setSelectedTemplate({ ...selectedTemplate, body: text })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text])

    return (
        <div className={`d-flex flex-column w-100 h-100`}>
            <div className={`d-flex flex-column bg-blue h-100 ${classes['mobile-container']}`}>
                <Mobile text={text} setText={setText} placeholder="Create your SMS template" />
            </div>
        </div>
    )
}

export default SmsTemplateWorkspace