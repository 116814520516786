import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { executeAuthAsyncPost } from '../../../utility/asyncSupport';
import { showToastErrorMessage } from '../../../api/toasterApi';
import { getApiUrl } from '../../../constants';
import { storeGridState } from '../../../actions/gridCacheActions';
import { ColumnMenu } from '../../common/grids/columnMenu';
import buildParameterString from '../../../utility/buildParameterString';
import { forEach } from 'lodash';
import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../../common/grids/StatefulGrid';

export class MappingGrid extends React.Component {
  state = { refresh: 0 };

  getColumns() {
    const columns = [];

    forEach(this.props.tableSchema, (column) => {
      columns.push({ field: column.columnName, title: column.columnTitle });
    });

    return columns;
  }

  refresh = () => {
    this.setState({ refresh: this.state.refresh + 1 });
  };

  getValues = (dataItem) => {
    const values = [];

    forEach(this.props.tableSchema, (column) => {
      values.push(dataItem[column.columnName]);
    });

    return values;
  };

  createUrl = (dataItem) => {
    return (
      'mapping/data/create?' +
      buildParameterString({
        tableId: this.props.selectedTableId,
        addedValues: this.getValues(dataItem)
      })
    );
  };

  updateUrl = (dataItem) => {
    return (
      'mapping/data/update?' +
      buildParameterString({
        tableId: this.props.selectedTableId,
        rowId: dataItem.RowId,
        editedValues: this.getValues(dataItem)
      })
    );
  };

  onAddFn = (dataItem, sucessCallback) =>
    this.props.executeAuthAsyncPost(
      this.props.client,
      this.createUrl(dataItem),
      'CREATE_GRIDWITHSTATE',
      null,
      () => {
        this.refresh();
      },
      () => {
        showToastErrorMessage('Error adding row');
      }
    );

  onUpdateFn = (dataItem, sucessCallback) => {
    this.props.executeAuthAsyncPost(
      this.props.client,
      this.updateUrl(dataItem),
      'UPDATE_GRIDWITHSTATE',
      null,
      () => {
        sucessCallback();
      },
      () => {
        showToastErrorMessage('Error updating row');
      }
    );
  };

  onDeleteFn = (dataItem, sucessCallback) => {
    this.props.executeAuthAsyncPost(
      this.props.client,
      `mapping/data/delete?rowId=${dataItem.RowId}`,
      'DELETE_GRIDWITHSTATE',
      null,
      () => {
        sucessCallback();
      },
      () => {
        showToastErrorMessage('Error deleting row');
      }
    );
  };

  onStateChange = (data) => {
    this.props.storeGridState(this.props.gridKey, data);
  };

  getKey() {
    return this.props.gridKey + this.props.selectedTableId;
  }

  render() {
    return (
      <div
        key={'mappingGrid' + this.props.selectedTableId}
        id="mapping-grid"
        className="borderContainerNoTop"
      >
        <StatefulGrid
          client={this.props.client}
          id={this.getKey()}
          key={this.getKey()}
          path={getApiUrl(this.props.client) + 'api/mapping/tabledata'}
          className={'scrollable-none'}
          pageSize={50}
          pageable={{
            pageSizes: [5, 10, 20, 50, 100],
            buttonCount: 5
          }}
          sortable
          hideablePaging={true}
          additionalRequestPayload={{
            tableId: this.props.selectedTableId
          }}
          onStateChange={this.onStateChange}
          columnMenu={ColumnMenu}
          editableRow={{
            id: 'RowId',
            addFn: this.onAddFn,
            updateFn: this.onUpdateFn,
            deleteFn: this.onDeleteFn
          }}
          refresh={this.state.refresh}
        >
          {this.getColumns().map((column) => (
            <GridColumn key={column.field} {...column} />
          ))}
        </StatefulGrid>
      </div>
    );
  }
}

MappingGrid.propTypes = {
  selectedTableId: PropTypes.number.isRequired,
  tableSchema: PropTypes.array.isRequired,
  gridKey: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    gridKey: ownProps.gridKey,
    tableSchema: ownProps.tableSchema,
    selectedTableId: state.mappingReducer.selectedTableId
  };
};

export default connect(mapStateToProps, {
  executeAuthAsyncPost,
  storeGridState
})(MappingGrid);
