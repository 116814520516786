import { useApi, useClient, useConfig, useInvevoSession, useUser } from "invevo-react-components"
import { useEffect, useReducer, useState } from "react"
import { actionTypes } from "../../reducers/actionTypes"
import customerDashboardsReducer from "../../reducers/customerDashboardsReducer"
import CustomerDashboard from "../customerDashboards/CustomerDashboard"
import ActionStrip from "./ActionStrip"
import DashboardStrip from "./dashboardStrip/DashboardStrip"
import { StripItems } from "./dashboardStrip/StripItems"
import classes from "./Customer.module.scss"
import LegacyApp from "../../../legacy/LegacyApp"
import { mapDashboardConfigFromGetDto } from "../../helpers/dashboardMapping"
import VirtualParent from "../../../legacy/components/virtualParent"
import AccountInfoPanel from "../../../legacy/components/accountInfo"
import DocumentsPanel from "../../../legacy/components/documents/documentsPanel"
import ContactsPanel from "../../../legacy/components/contacts"
import TransactionsPanel from "../../../legacy/components/transactions"
import { expandablePanels, useLayout } from "../../../../contexts/LayoutContext"
import { useCustomer } from "../../../../contexts/CustomerContext"
import { fieldDataTypes } from "../../enums/fieldDataTypes"
import ErrorBoundary from "../../../../components/ErrorBoundary"
import LegacyCustomerSummary from "../../../legacy/components/customerSummary/CustomerSummary"
import { customerDetailsPane } from "../../../legacy/constants"
import CustomerSummary from "../../../../routes/search/components/customerSummary/CustomerSummary"
import { DashboardTypeEnum } from "../../../../routes/entitySearch/types/DashboardConfig"
import { dashboardSubTypes, dashboardTypes } from "../../types/DashboardTypes"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"

const Customer = ({ store }) => {
    const [lastFetchedCustomerRef, setLastFetchedCustomerRef] = useState()
    const [lastFetchedCustomerWasVirtualAccount, setLastFetchedCustomerWasVirtualAccount] = useState()
    const [isFetching, setIsFetching] = useState(false)
    const [invevoSession, setInvevoSession] = useInvevoSession()
    const { setExpandedPanel, isCustomerPanelExpanded, isLeftPanelExpanded } = useLayout()
    const [activeStripItem, setActiveStripItem] = useState(StripItems.LEGACY_CUSTOMER_SUMMARY)
    const [isFetchingAggs, setIsFetchingAggs] = useState(false)
    const [hasFetchedAggs, setHasFetchedAggs] = useState(false)

    const { isEnabled: isOldUxEnabled } = useFeatureToggle("showOldUx")
    const { isEnabled: isLegacyDashboardsInVerticalStripEnabled } = useFeatureToggle("showLegacyDashboardsInVerticalStrip")
    const { isEnabled: isNewSummaryEnabled } = useFeatureToggle("showNewCustomerSummary")
    const { isEnabled: isNewGroupNavEnabled } = useFeatureToggle("newGroupNavigation")
    const { isEnabled: showLegacyTransactionsGrid } = useFeatureToggle("showLegacyTransactionsGrid")

    const api = useApi()
    const client = useClient()
    const currentUser = useUser()
    const config = useConfig()
    const [customer, setCustomer] = useCustomer()

    const [selectedCustomerRefs, setSelectedCustomerRefs] = useState([])

    const [state, dispatch] = useReducer(customerDashboardsReducer, {
        dashboards: [],
        selectedDashboard: null,
        dashboardsOptions: [],
        transactionFields: [],
        customerFields: [],
        clientUserDashboardsConfig: {
            transactionsGridConfigs: []
        },
        transactionAggregations: null,
        lookups: []
    })

    useEffect(() => {
        if (isLegacyDashboardsInVerticalStripEnabled) {
            if (customerDetailsPane[invevoSession.selectedCustomerTab] === "files" || invevoSession.selectedCustomerTab === "files") {
                setActiveStripItem(StripItems.LEGACY_FILES)
                dispatch({ type: actionTypes.SELECTED_CUSTOMER_DASHBOARD_CLEARED })
                return
            }

            if (customerDetailsPane[invevoSession.selectedCustomerTab] === "contacts" || invevoSession.selectedCustomerTab === "contacts") {
                setActiveStripItem(StripItems.LEGACY_CONTACTS)
                dispatch({ type: actionTypes.SELECTED_CUSTOMER_DASHBOARD_CLEARED })
                return
            }
            if (
                showLegacyTransactionsGrid &&
                (customerDetailsPane[invevoSession.selectedCustomerTab] === "transactions" || invevoSession.selectedCustomerTab === "transactions")
            ) {
                setActiveStripItem(StripItems.LEGACY_TRANSACTIONS)
                dispatch({ type: actionTypes.SELECTED_CUSTOMER_DASHBOARD_CLEARED })
                return
            }
        }

        if (isOldUxEnabled || (invevoSession.selectedCustomerTab !== "transactions" && invevoSession.selectedCustomerTab !== "") || customer.isVirtualAccount) {
            setActiveStripItem(StripItems.LEGACY_CUSTOMER_SUMMARY)
            dispatch({ type: actionTypes.SELECTED_CUSTOMER_DASHBOARD_CLEARED })
            return
        }

        if (invevoSession.selectedCustomerTab === "transactions") {
            const dashboardWithGrid = state.dashboards.find(d => d.transactionsWidgets.length > 0)
            if (dashboardWithGrid) {
                setActiveStripItem(dashboardWithGrid.id)
                dispatch({ type: actionTypes.CUSTOMER_DASHBOARD_SELECTED, dashboard: dashboardWithGrid })
            }
        }
    }, [
        invevoSession.selectedCustomerTab,
        customer.isVirtualAccount,
        state.dashboards,
        isLegacyDashboardsInVerticalStripEnabled,
        isOldUxEnabled,
        showLegacyTransactionsGrid
    ])

    useEffect(() => {
        if (isFetching || !customer.reference || !config.SEARCH_API_URL || !config.DATA_API_URL) {
            return
        }

        if (lastFetchedCustomerRef?.toLowerCase() === customer.reference.toLowerCase() && lastFetchedCustomerWasVirtualAccount === customer.isVirtualAccount) {
            return
        }

        setIsFetching(true)
        setLastFetchedCustomerWasVirtualAccount(customer.isVirtualAccount)
        setLastFetchedCustomerRef(customer.reference)
        setCustomer({ transactionFilters: [], selectedTransactions: [] })
        setInvevoSession({ stateDeltas: {}, virtualAccountCustomers: [] })

        const getClientUserDashboardsConfig = api
            .get(`${config.DATA_API_URL}/api/${client}/client-user-dashboards-config/${currentUser.username}`)
            .then(response => {
                dispatch({ type: actionTypes.CLIENT_USER_DASHBOARDS_CONFIG_RETRIEVED, clientUserDashboardsConfig: response.data })
            })

        const getDashboards = api.get(`${config.DATA_API_URL}/api/${client}/user-dashboard-configs`).then(response => {
            const dashboards = mapDashboardConfigFromGetDto(response.data, false).filter(
                d => d.type === DashboardTypeEnum.CUSTOMER && d.entityConfigReference === null
            )

            dispatch({ type: actionTypes.CUSTOMER_DASHBOARDS_RETRIEVED, dashboards })

            const dashboard = dashboards.find(d => d.id === activeStripItem)
            if (dashboard) {
                dispatch({ type: actionTypes.CUSTOMER_DASHBOARD_SELECTED, dashboard })
            }
        })

        const getDataFields = api.get(`${config.DATA_API_URL}/api/${client}/data-field`).then(response => {
            const transactionFields = response.data
                .filter(field => field.fieldType === fieldDataTypes.TRANSACTION)
                .map((field, index) => ({
                    ...field,
                    id: index,
                    type: field.dataPrimitive
                }))

            const customerFields = response.data.filter(field => field.fieldType === fieldDataTypes.CUSTOMER)

            dispatch({ type: actionTypes.TRANSACTION_FIELDS_RETRIEVED, fields: transactionFields })
            dispatch({ type: actionTypes.CUSTOMER_FIELDS_RETRIEVED, fields: customerFields })
        })

        const getCustomer =
            Object.keys(customer.grouping).length === 0
                ? api
                      .get(`${config.SEARCH_API_URL}/api/${client}/customer/${customer.reference}`)
                      .then(response => {
                          setCustomer({ data: response.data })
                      })
                      .catch(error => {
                          if (error.response.status === 404) {
                              setCustomer({ data: null })
                          } else {
                              console.error(error)
                          }
                      })
                : Promise.DEFAULT

        const getLookups = api.get(`${config.DATA_API_URL}/api/${client}/lookups`).then(response => {
            dispatch({ type: actionTypes.LOOKUPS_RETRIEVED, data: response.data })
        })

        const getVirtualAccountChildren = new Promise((resolve, reject) => {
            if (customer.isVirtualAccount) {
                api.get(`${config.SEARCH_API_URL}/api/${client}/legacy-virtual-account-children/${customer.sqlId}`)
                    .then(response => {
                        setSelectedCustomerRefs([...response.data.map(d => d.accountCode), customer.reference])
                        setInvevoSession({ virtualAccountCustomers: response.data })
                        resolve()
                    })
                    .catch(reject)
            } else if (Object.keys(customer.grouping).length > 0) {
                api.post(`${config.SEARCH_API_URL}/api/${client}/customer`, {
                    pageSize: 10000,
                    searchAfterId: null,
                    sortBy: null,
                    quickSearchValue: null,
                    filters: {
                        textFieldIsOneOf: Object.keys(customer.grouping)
                            .filter(value => value !== "client")
                            .map(fieldName => ({
                                fieldName,
                                values: [customer.grouping[fieldName]],
                                notOneOf: false
                            }))
                    }
                })
                    .then(response => {
                        setSelectedCustomerRefs(response.data.pagedCustomers.map(d => d.reference))
                        resolve()
                    })
                    .catch(reject)
            } else {
                setSelectedCustomerRefs([customer.reference])
                resolve()
            }
        })

        Promise.all([getClientUserDashboardsConfig, getDashboards, getCustomer, getLookups, getDataFields, getVirtualAccountChildren])
            .then(() => {
                setIsFetching(false)
            })
            .catch(error => {
                setIsFetching(false)
                console.error(error)
            })
    }, [
        api,
        client,
        isFetching,
        currentUser.username,
        activeStripItem,
        config.SEARCH_API_URL,
        config.DATA_API_URL,
        customer.sqlId,
        customer.reference,
        customer.isVirtualAccount,
        setInvevoSession,
        selectedCustomerRefs,
        lastFetchedCustomerRef,
        lastFetchedCustomerWasVirtualAccount,
        setCustomer,
        customer.grouping
    ])

    useEffect(() => {
        if (hasFetchedAggs || isFetchingAggs || !config.SEARCH_API_URL || selectedCustomerRefs.length === 0) return
        setIsFetchingAggs(true)

        const payments =
            state.lookups
                .find(l => l.reference.toLowerCase() === "transaction type")
                ?.entries.filter(e => e.booleans.ispayment)
                .map(a => a.reference) || []

        api.post(`${config.SEARCH_API_URL}/api/${client}/transactions-aggregations`, {
            customerRefs: selectedCustomerRefs,
            paymentTransactionTypes: payments
        })
            .then(response => {
                dispatch({ type: actionTypes.TRANSACTION_AGGREGATIONS_RETRIEVED, transactionAggregations: response.data })
                setHasFetchedAggs(true)
                setIsFetchingAggs(false)
            })
            .catch(error => {
                console.error(error)
                setHasFetchedAggs(true)
                setIsFetchingAggs(false)
            })
    }, [selectedCustomerRefs, api, client, config.SEARCH_API_URL, state.lookups, hasFetchedAggs, isFetchingAggs])

    useEffect(() => {
        setHasFetchedAggs(false)
    }, [selectedCustomerRefs])

    const onStripItemSelected = activeStripItem => {
        setActiveStripItem(activeStripItem)
        if (activeStripItem === StripItems.LEGACY_CUSTOMER_SUMMARY) {
            setInvevoSession({ selectedCustomerTab: "" })
            dispatch({ type: actionTypes.SELECTED_CUSTOMER_DASHBOARD_CLEARED })
            return
        }

        if (activeStripItem === StripItems.LEGACY_FILES) {
            setInvevoSession({ selectedCustomerTab: "files" })
            dispatch({ type: actionTypes.SELECTED_CUSTOMER_DASHBOARD_CLEARED })
            return
        }

        if (activeStripItem === StripItems.LEGACY_CONTACTS) {
            setInvevoSession({ selectedCustomerTab: "contacts" })

            dispatch({ type: actionTypes.SELECTED_CUSTOMER_DASHBOARD_CLEARED })
            return
        }
        if (activeStripItem === StripItems.LEGACY_TRANSACTIONS) {
            setInvevoSession({ selectedCustomerTab: "transactions" })
            dispatch({ type: actionTypes.SELECTED_CUSTOMER_DASHBOARD_CLEARED })
            return
        }
        setInvevoSession({ selectedCustomerTab: "" })

        const dashboard = state.dashboards.find(d => d.id === activeStripItem)
        if (dashboard) {
            dispatch({ type: actionTypes.CUSTOMER_DASHBOARD_SELECTED, dashboard })
        }
    }

    const onSelectedCustomerContactsUpdated = selectedCustomerContacts => {
        setInvevoSession({ selectedCustomerContacts })
    }

    const onSelectedInternalContactsUpdated = selectedInternalContacts => {
        setInvevoSession({ selectedInternalContacts })
    }

    const displayedContent = () => {
        if (state.selectedDashboard && state.transactionAggregations) {
            return (
                <div className="d-flex flex-grow-1 overflow-auto">
                    <CustomerDashboard
                        state={{
                            dashboard: state.selectedDashboard,
                            customerRefs: selectedCustomerRefs,
                            transactionFields: state.transactionFields,
                            customerFields: state.customerFields,
                            clientUserDashboardsConfig: state.clientUserDashboardsConfig,
                            transactionAggregations: state.transactionAggregations,
                            lookups: state.lookups
                        }}
                        dispatch={dispatch}
                        fullScreenButton={fullScreenButton()}
                    />
                </div>
            )
        }

        if (activeStripItem === StripItems.LEGACY_CUSTOMER_SUMMARY) {
            if (isOldUxEnabled || !isLegacyDashboardsInVerticalStripEnabled) {
                return (
                    <div className="d-flex flex-grow-1 overflow-auto p-3">
                        <LegacyApp className="w-100 h-100" store={store}>
                            <LegacyCustomerSummary />
                        </LegacyApp>
                    </div>
                )
            } else {
                return (
                    !isFetching &&
                    (isNewSummaryEnabled ? (
                        state.dashboards.find(d => d.subType === dashboardSubTypes.SUMMARY) ? (
                            state.transactionAggregations && (
                                <CustomerSummary
                                    dashboard={state.dashboards.find(d => d.subType === dashboardSubTypes.SUMMARY)}
                                    customerFields={state.customerFields}
                                    transactionAggregations={state.transactionAggregations}
                                    lookups={state.lookups}
                                    fullScreenButton={fullScreenButton()}
                                    isFetchingCustomer={isFetching && isFetchingAggs}
                                />
                            )
                        ) : (
                            <span className="m-3 text-grey fs-3">No Customer Summary dashboard available</span>
                        )
                    ) : (
                        customer.sqlId && (
                            <div className="d-flex flex-grow-1 overflow-auto p-3">
                                <LegacyApp className="w-100 h-100" store={store}>
                                    <AccountInfoPanel />
                                </LegacyApp>
                            </div>
                        )
                    ))
                )
            }
        }
        if (activeStripItem === StripItems.LEGACY_FILES) {
            return (
                <div className="d-flex flex-grow-1 overflow-auto p-3">
                    <LegacyApp className="w-100 h-100" store={store}>
                        <DocumentsPanel selectTaskTab={invevoSession.showTaskFiles} />
                    </LegacyApp>
                </div>
            )
        }
        if (activeStripItem === StripItems.LEGACY_CONTACTS) {
            return (
                <div className="d-flex flex-grow-1 overflow-auto p-3">
                    <LegacyApp className="w-100 h-100" store={store}>
                        <ContactsPanel
                            client={client}
                            onSelectedCustomerContactsUpdated={onSelectedCustomerContactsUpdated}
                            onSelectedInternalContactsUpdated={onSelectedInternalContactsUpdated}
                        />
                    </LegacyApp>
                </div>
            )
        }
        if (activeStripItem === StripItems.LEGACY_TRANSACTIONS) {
            return (
                <div className="d-flex flex-grow-1 overflow-auto mt-5 p-3">
                    <LegacyApp className="w-100 h-100" store={store}>
                        <TransactionsPanel client={client} />
                    </LegacyApp>
                </div>
            )
        }
        return (
            <div className="d-flex flex-grow-1 overflow-auto p-3">
                <LegacyApp className="w-100 h-100" store={store}>
                    <AccountInfoPanel />
                </LegacyApp>
            </div>
        )
    }

    const isLegacyDashboardSelected = () =>
        (!isNewSummaryEnabled && activeStripItem === StripItems.LEGACY_CUSTOMER_SUMMARY) ||
        activeStripItem === StripItems.LEGACY_FILES ||
        activeStripItem === StripItems.LEGACY_CONTACTS

    const onSetCustomerViewFullScreen = () => setExpandedPanel(isCustomerPanelExpanded ? expandablePanels.DEFAULT : expandablePanels.CUSTOMER_PANEL)

    const fullScreenButton = () => (
        <i
            className={`fs-4 pointer fa-light pt-2 ${
                isCustomerPanelExpanded ? "fa-down-left-and-up-right-to-center" : "fa-up-right-and-down-left-from-center"
            }`}
            onClick={onSetCustomerViewFullScreen}
        ></i>
    )

    return (
        <div className="d-flex w-100 h-100">
            {!isFetchingAggs && (
                <DashboardStrip
                    dashboards={state.dashboards.filter(d => d.subType !== dashboardSubTypes.SUMMARY && d.type !== dashboardTypes.AGGREGATED)}
                    activeItem={activeStripItem}
                    onStripItemSelected={onStripItemSelected}
                    selectedDashboard={state.selectedDashboard}
                />
            )}
            <ErrorBoundary>
                {!isLeftPanelExpanded && (
                    <>
                        <div className="d-flex flex-column flex-grow-1 overflow-auto">
                            {isLegacyDashboardSelected() && (
                                <div className={`d-flex pt-3 pe-4 ${!state.selectedDashboard ? "text-grey" : classes["dark-header"]}`}>
                                    {fullScreenButton()}
                                </div>
                            )}
                            {customer.reference ? (
                                <div className="d-flex flex-column overflow-auto text-grey flex-grow-1">
                                    {!isNewGroupNavEnabled && (
                                        <LegacyApp className="ms-2" store={store}>
                                            <div
                                                className={
                                                    isLegacyDashboardSelected() || (!isNewSummaryEnabled && !state.selectedDashboard)
                                                        ? classes["virtual-parent-container"]
                                                        : ""
                                                }
                                            >
                                                <VirtualParent />
                                            </div>
                                        </LegacyApp>
                                    )}
                                    {displayedContent()}
                                </div>
                            ) : (
                                <span className="m-3 text-grey fs-3">No customer selected</span>
                            )}
                        </div>
                        {customer.reference && <ActionStrip customerRef={customer.reference} store={store} />}
                    </>
                )}
            </ErrorBoundary>
        </div>
    )
}

export default Customer
