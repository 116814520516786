import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  getApiUrl,
  transactionFilterTypes,
  UserConfigViewType
} from '../../constants';
import existential from '../../utility/existential';
import {
  assign,
  forEach,
  isEqual,
  isNil,
  sortBy,
  remove,
  difference,
  map,
  concat
} from 'lodash';
import {
  getTransactionsAggregate,
  getCustomerTransactionsIds,
  getCustomerTransactionsRefsFromTransGrid,
  getRecentPaymentTransactionsIds
} from '../../api/functionApi';
import {
  resetAggregate,
  selectTransaction,
  selectTransactionRefs,
  selectAllTransactions,
  setSelected
} from '../../actions/functionActions';
import { storeGridState } from '../../actions/gridCacheActions';
import { getLookup } from '../../api/lookupApi';
import { getVirtualAccountTransactionsAggregate } from '../../api/vpApi';
import { executeAuthAsyncGet } from '../../utility/asyncSupport';
import {
  registerForIntl,
  provideIntlService
} from '@progress/kendo-react-intl';
import FeatureToggled from '../featureToggled/FeatureToggled';
import FeatureToggleDisabled from '../featureToggled/FeatureToggleDisabled';

import TransactionNotesGrid from './transactionNotesGrid';
import { ColumnMenu } from '../common/grids/columnMenu';
import { GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import StatefulGrid from '../common/grids/StatefulGrid';
import TransactionSearch from './transactionSearch';
import { normaliseFormat, normaliseFormatDef } from '../../selectors/lookup';
import TransactionFilter from './transactionFilter';
import { getUserConfig } from '../../api/uiConfigApi';
import { gridColConfig } from '../../selectors/uiConfig';
import VisibleColumnConfig from '../common/grids/VisibleColumnConfig';
import TooltipButton from '../common/buttons/tooltipButton';
import {
  importTransactionDocuments,
  downloadTransactionDocuments
} from '../../api/documentApi';
import {
  showToastErrorMessage,
  showToastSuccessMessage,
  showToastWarningMessage
} from '../../api/toasterApi';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { withRouter } from 'react-router';
import Nav from '../../api/navApi';
import { webApiInterface } from '../../api/webApiInterface';

class TransactionsGrid extends Component {
  lastSelectIndex = 0;

  constructor(props) {
    super(props);

    const transactionColumns = props.transactionColumns.results;
    let columns = [],
      firstLoad = true;

    if (!isNil(transactionColumns) && transactionColumns.length > 0) {
      columns = this.getColumns(
        transactionColumns,
        props.transactionGridColConfig
      );
      firstLoad = false;
    }

    const searchMap = new URLSearchParams(this.props.location.search);
    const tranStatus = parseInt(searchMap.get('transtatus')) || 0;
    if (!isNaN(tranStatus) && tranStatus >= 0 && tranStatus <= 3) {
      props.removeRouteQuery('transtatus');
    }

    this.state = {
      refresh: 0,
      tranStatus,
      selectAllChecked: false,
      isFromCustomerPanel: false,
      isLoaded: false,
      refreshingToken: false,
      selectedItemIds: [],
      selectedItemRefs: [],
      firstLoad,
      columns,
      showColumnsConfig: false,
      tableState: {},
      totalRowsVisible: 0,
      totalCurrentBillingValue: 0,
      totalOpeningBillingValue: 0,
      totalAllocatedBillingValue: 0
    };

    this.customerTransactionsRef = new React.createRef();
    this.trasnactionFilterRef = new React.createRef();

    if (!this.props.transactionGridColConfig) {
      this.props.getUserConfig(this.props.client, UserConfigViewType.transactionGridColConfig);
    }
  }

  toggleShowColumnsConfig = () =>
    this.setState((prevState) => ({
      showColumnsConfig: !prevState.showColumnsConfig
    }));

  updateTransactionColWithUserConfig = (columns, transactionGridColConfig) => {
    if (!transactionGridColConfig) return columns;

    return columns.map((c) => {
      const found = transactionGridColConfig.Content.find(
        (f) => f.field === c.field
      );

      if (found) {
        return { ...c, show: found.show };
      }
      return { ...c };
    });
  };


  componentDidMount() {
    this.props.getLookup(this.props.client, 'lookup/documenttypes', 'documentTypes')
  }

  componentDidUpdate(prevProps) {
    let forceRefreshGrid = false;

    if (prevProps.noteSaved !== this.props.noteSaved) {
      forceRefreshGrid = true;
    }

    const prevTransIds = prevProps.transactionIds.slice(0);
    prevTransIds.sort();

    const prevTransRefs = prevProps.transactionRefs.slice(0);
    prevTransRefs.sort();

    const currTransIds = this.props.transactionIds.slice(0);
    currTransIds.sort();

    const currTransRefs = this.props.transactionRefs.slice(0);
    currTransRefs.sort();

    const selectedTransIds = this.state.selectedItemIds.slice(0);
    selectedTransIds.sort();

    const selectedTransRefs = this.state.selectedItemRefs.slice(0);
    selectedTransRefs.sort();

    if (!isEqual(currTransIds, prevTransIds) || !isEqual(currTransIds, selectedTransIds)) {
      this.setState({
        isFromCustomerPanel: false,
        selectedItemIds: currTransIds
      });

      this.loadAggregate(currTransIds, false, this.props.statusId);
    }

    if (!isEqual(currTransRefs, prevTransRefs) || !isEqual(currTransRefs, selectedTransRefs)) {
      this.setState({
        isFromCustomerPanel: false,
        selectedItemRefs: currTransRefs
      });

      const newTransactionsRefs = currTransRefs.map(ref => ({
        transactionRef: ref, customerRef: this.props.customer.reference
      }))

      this.props.setCustomer({ selectedTransactions: newTransactionsRefs })
    }

    if (
      !isEqual(
        this.props.transactionGridColConfig,
        prevProps.transactionGridColConfig
      )
    ) {
      this.setState((prevState) => ({
        columns: this.updateTransactionColWithUserConfig(
          prevState.columns,
          this.props.transactionGridColConfig
        )
      }));
    }

    if (this.props.msg !== prevProps.msg) {
      this.setState({ tranStatus: 0 });
      forceRefreshGrid = true;
    }

    if (
      !isNil(this.props.transactionColumns.results) &&
      this.props.transactionColumns.results.length > 0 &&
      !this.state.isLoaded
    ) {
      this.setState({
        isLoaded: true,
        columns: this.getColumns(
          this.props.transactionColumns.results,
          this.props.transactionGridColConfig
        )
      });

      forceRefreshGrid = true;
    }

    if (forceRefreshGrid) {
      this.refresh();
    }
  }

  refresh = () => {
    this.setState({ refresh: this.state.refresh + 1 });
  };

  footerCountCell = ({ colSpan, style }) => {
    const hasTransactions =
      this.props.transactionIds && this.props.transactionIds.length > 0;

    const count = (hasTransactions && this.state.totalRowsVisible) || 0;

    return (
      <td colSpan={colSpan} style={style}>
        {hasTransactions ? `No. of rows: ${count}` : ''}
      </td>
    );
  };

  getSelectedRows = (rows) => {
    let totalRows = 0
    let openingBillingValue = 0
    let currentBillingValue = 0
    rows.forEach(x => {
      if (this.isSelectedRow(x)) {
        totalRows++
        openingBillingValue += x.OpeningBillingValue
        currentBillingValue += x.CurrentBillingValue
      }
    })

    return { totalRows, openingBillingValue, currentBillingValue }
  }

  footerValueCell = ({ field, colSpan, style }) => {
    const aggregateData = existential(
      this.props,
      'transactionsAggregate',
      null
    );
    const currencyFormat = this.props.currencyFormat;

    let text = '';

    if (
      aggregateData !== null &&
      aggregateData.CurrencyCodes &&
      aggregateData.CurrencyCodes.length === 1
    ) {
      let value;
      switch (field) {
        case 'CurrentBillingValue':
          value = this.state.totalCurrentBillingValue;
          break;
        case 'OpeningBillingValue':
          value = this.state.totalOpeningBillingValue;
          break;
        case 'AllocatedBillingValue':
          value = this.state.totalAllocatedBillingValue;
          break;
        default:
          value = 0;
          break;
      }
      text = provideIntlService(this).formatNumber(value, currencyFormat);
    }

    return (
      <td colSpan={colSpan} style={style}>
        {text}
      </td>
    );
  };

  isColumnActive = (field) => {
    if (!this.state) return false;
    return GridColumnMenuFilter.active(field, this.state.tableState.filter);
  };

  updateFilterActive = () => {
    this.setState({
      columns: this.state.columns.map((c) => {
        return {
          ...c,
          headerClassName: this.isColumnActive(c.field) ? 'active' : ''
        };
      })
    });
  };

  getColumns = (transactionColumns, transactionGridColConfig) => {
    const gridColumns = sortBy(transactionColumns, [(o) => o.Ordinal]);

    const columns = [];
    forEach(gridColumns, (column) => {
      const baseColumn = {
        field: column.Field,
        title: column.Title,
        show: column.IsDefaultColumn,
        headerClassName: this.isColumnActive(column.Field) ? 'active' : ''
      };

      if (column.Field !== 'TransactionId') {
        if (column.Type === 'date') {
          columns.push({
            ...baseColumn,
            format: this.props.dateFormat,
            filter: 'date',
            type: 'date'
          });
        } else {
          const currencyFormat = `{0:${this.props.currencyFormat}}`;

          switch (column.Field) {
            case 'TransactionReference':
              columns.push({
                ...baseColumn,
                footerCell: this.footerCountCell
              });
              break;
            case 'CurrentBillingValue':
            case 'OpeningBillingValue':
            case 'AllocatedBillingValue':
              columns.push({
                ...baseColumn,
                format: currencyFormat,
                footerCell: this.footerValueCell,
                attributes: { class: 'text-right' },
                filter: 'numeric',
                type: 'numeric'
              });
              break;
            case 'CurrentLedgerValue':
            case 'OpeningLedgerValue':
              columns.push({
                ...baseColumn,
                format: currencyFormat,
                attributes: { class: 'text-right' },
                filter: 'numeric',
                type: 'numeric'
              });
              break;
            case 'DaysOverdue':
              columns.push({
                ...baseColumn,
                filter: 'numeric',
                type: 'numeric'
              });
              break;
            default:
              columns.push(baseColumn);
          }
        }
      }
    });

    return this.updateTransactionColWithUserConfig(
      columns,
      transactionGridColConfig
    );
  };

  newExportCsv = () => {
    const xhr = new XMLHttpRequest();
    const url = getApiUrl(this.props.client) + 'api/FileExport/ExportTransactionsCSV';
    const searchCriteria = this.props.searchCriteria;

    const params = {
      customerId: this.props.selectedAccount.miaAccountId,
      columns: this.getVisibleGridColumns(),
      isVirtualAccount: this.props.selectedAccount.miaAccountIsVirtualAccount,
      selectedTransactions: this.props.transactionIds
    };
    if (this.state.tranStatus === 0) {
      params.isClosed = false;
    }
    if (this.state.tranStatus === 1) {
      params.isClosed = true;
    }

    if (!isNil(searchCriteria)) {
      params.currency = searchCriteria.currency;
    }

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(xhr.response);
      a.download = 'export.csv';
      a.click();
    };

    this.props.executeAuthAsyncGet(this.props.client, 'Lookup/OK', 'CHECK_OK', null, function () {
      webApiInterface.applySecurity(xhr);
      xhr.send(JSON.stringify(params));
    });
  };

  getVisibleGridColumns() {
    const visibleColumns = [];

    forEach(this.state.columns, (item) => {
      if (item.show) {
        visibleColumns.push({ Name: item.field });
      }
    });

    return visibleColumns;
  }

  changeFilter = (filter) => {
    this.setState({ tranStatus: filter });
    this.refresh();
  };

  loadAggregate(transactionIds, allTransactionsSelected, closedStatus) {
    // Aggregations are of *selected* rows - so only calculate if something is selected!
    if (transactionIds.length > 0 || allTransactionsSelected === true) {
      const gridFilters = this.customerTransactionsRef.current.state.dataState
        .filter;

      let params = assign({
        tIds: transactionIds,
        customerId: this.props.selectedAccount.miaAccountId,
        isClosed: closedStatus,
        allTransactionsSelected,
        filters: JSON.stringify(gridFilters)
      });
      if (this.props.isSelected) {
        params = assign(params, this.props.searchCriteria);
      }
      if (this.state.selectAllChecked) {
        if (this.props.isSelected) {
          params = assign(params, this.props.searchCriteria);
        }
      }
      if (this.props.selectedAccount.miaAccountIsVirtualAccount) {
        this.props.getVirtualAccountTransactionsAggregate(this.props.client, params);
      } else {
        //HACK DT Temporary limit on aggregation calculation until performance work completed.
        if (params.tIds.length) {
          this.props.getTransactionsAggregate(this.props.client, params);
        }
      }
    } else {
      this.props.resetAggregate();
    }
  }

  static getKey() {
    return 'customer-transactions';
  }

  isSelectedRow = (dataItem) =>
    this.state.selectedItemIds.indexOf(dataItem.TransactionId) !== -1 ||
    this.state.selectedItemRefs.indexOf(dataItem.UniqueTransactionRef) !== -1;

  onSelectionChange = ({ dataItem }) => {
    const selectedItemIds = [...this.state.selectedItemIds];
    const selectedItemRefs = [...this.state.selectedItemRefs];
    const grid = this.customerTransactionsRef.current.state.data;

    if (this.isSelectedRow(dataItem)) {
      remove(selectedItemIds, (id) => dataItem.TransactionId === id);
      remove(selectedItemRefs, (ref) => dataItem.UniqueTransactionRef === ref);
    } else {
      selectedItemIds.push(dataItem.TransactionId);
      selectedItemRefs.push(dataItem.UniqueTransactionRef);
    }

    if (this.props.allTransactionsSelected) {
      this.props.selectAllTransactions(false);
      this.setState({
        selectedItemIds: [dataItem.TransactionId],
        selectedItemRefs: [dataItem.UniqueTransactionRef]
      });
    }

    // "Select All" is a binary condition.  Selecting rows disables it!
    if (this.props.allTransactionsSelected) {
      this.props.selectAllTransactions(false);
      this.setState({
        selectedItemIds: [dataItem.TransactionId],
        selectedItemRefs: [dataItem.UniqueTransactionRef]
      });
    }

    if (this.props.selectTransaction && this.props.selectTransactionRefs) {
      const unSelectedIds = difference(
        map(grid, 'TransactionId'),
        selectedItemIds
      );
      const unSelectedRefs = difference(
        map(grid, 'UniqueTransactionRef'),
        selectedItemRefs
      );
      const newSelectedIds = concat(
        difference(this.props.selectedTransactionIds, unSelectedIds), // Current Ids in state that have not been deselected
        difference(selectedItemIds, this.props.selectedTransactionIds) // new selected Ids not previously selected
      );
      const newSelectedRefs = concat(
        difference(this.props.selectedTransactionRefs, unSelectedRefs), // Current Ids in state that have not been deselected
        difference(selectedItemRefs, this.props.selectedTransactionRefs) // new selected Ids not previously selected
      );
      this.props.selectTransaction(newSelectedIds);
      this.props.selectTransactionRefs(newSelectedRefs);
      this.setState({ selectAllChecked: false });
      this.refresh();
    }

    this.setState({
      selectedItemIds,
      selectedItemRefs
    });
  };

  onHeaderSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    this.props.selectAllTransactions(checked);
    this.setState({ selectAllChecked: checked, isFromCustomerPanel: false });
    if (!checked) {
      this.props.selectTransaction([]);
      this.props.selectTransactionRefs([]);
    }
    this.refresh();
  };

  onRowClick = (gridRowEvent) => {
    const dataItem = gridRowEvent.dataItem;
    const grid = this.customerTransactionsRef.current.state.data;
    let newSelectedRowStatus = !this.isSelectedRow(dataItem);
    let selectedItemIds = [...this.state.selectedItemIds];
    let selectedItemRefs = [...this.state.selectedItemRefs];

    let last = this.lastSelectIndex;
    const current = grid.findIndex(
      (d) => d.TransactionId === dataItem.TransactionId
    );

    if (!gridRowEvent.nativeEvent.shiftKey) {
      this.lastSelectIndex = last = current;
    }

    if (!gridRowEvent.nativeEvent.ctrlKey) {
      newSelectedRowStatus = true;
      selectedItemIds = [];
      selectedItemRefs = []
    }

    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      const gridDataItem = grid[i];
      const xId = gridDataItem.TransactionId;
      const xRef = gridDataItem.UniqueTransactionRef;
      const gridItemIsSelected =
        selectedItemIds.indexOf(gridDataItem.TransactionId) !== -1;

      if (gridItemIsSelected !== newSelectedRowStatus) {
        if (gridItemIsSelected) {
          remove(selectedItemIds, (id) => xId === id);
          remove(selectedItemRefs, (ref) => xRef === ref);
        } else {
          selectedItemIds.push(xId);
          selectedItemRefs.push(xRef);
        }
      }
    }

    // "Select All" is a binary condition.  Selecting rows disables it!
    if (this.props.allTransactionsSelected) {
      this.props.selectAllTransactions(false);
      this.setState({
        selectedItemIds: [dataItem.TransactionId],
        selectedItemRefs: [dataItem.UniqueTransactionRef]
      });
    }

    if (this.props.selectTransaction && this.props.selectTransactionRefs) {
      const unSelectedIds = difference(
        map(grid, 'TransactionId'),
        selectedItemIds
      );
      const unSelectedRefs = difference(
        map(grid, 'UniqueTransactionRef'),
        selectedItemRefs
      );
      const newSelectedIds = concat(
        difference(this.props.selectedTransactionIds, unSelectedIds), // Current Ids in state that have not been deselected
        difference(selectedItemIds, this.props.selectedTransactionIds) // new selected Ids not previously selected
      );
      const newSelectedRefs = concat(
        difference(this.props.selectedTransactionRefs, unSelectedRefs), // Current Ids in state that have not been deselected
        difference(selectedItemRefs, this.props.selectedTransactionRefs) // new selected Ids not previously selected
      );
      this.props.selectTransaction(newSelectedIds);
      this.props.selectTransactionRefs(newSelectedRefs);
      this.setState({ selectAllChecked: false });
      this.refresh();
    }

    this.setState({
      selectedItemIds,
      selectedItemRefs
    });
  };

  onColumnsSubmit = (newColumnsState) => {
    this.setState({
      columns: newColumnsState
    });
  };

  onStateChange = (data) => {
    this.setState({ tableState: data }, this.updateFilterActive);

    this.props.storeGridState(
      TransactionsGrid.getKey() + this.props.selectedAccount.miaAccountId,
      data
    );
  };

  additionalRequestPayload = () => {
    let selectedTransactionIds = null;
    let closed = null;
    switch (this.state.tranStatus) {
      case 0: // open
        closed = false;
        break;
      case 1: // closed
        closed = true;
        break;
      case 2: // all
        break;
      case 3: // selected
        selectedTransactionIds = this.props.transactionIds;
        break;
      default:
        break;
    }

    const dtoData = assign({
      customerid: this.props.selectedAccount.miaAccountId,
      gridType: 2,
      isClosed: closed,
      transactionIds: selectedTransactionIds,
      isVirtualAccount: this.props.selectedAccount.miaAccountIsVirtualAccount
    });


    return {
      data: dtoData,
      beforeSendFn: () => {
        if (this.state.selectAllChecked) {
          this.props.getCustomerTransactionsIds(this.props.client, dtoData);
          this.props.getCustomerTransactionsRefsFromTransGrid(this.props.client, this.props.setCustomer, dtoData);
        } else if (this.state.isFromCustomerPanel) {
          const dtoDataWithSearchCriteria = assign(
            dtoData,
            this.props.searchCriteria
          );
          this.props.getCustomerTransactionsIds(this.props.client, dtoDataWithSearchCriteria);
          this.props.getCustomerTransactionsRefsFromTransGrid(this.props.client, this.props.setCustomer, dtoDataWithSearchCriteria);
        }
      }
    };
  };

  innerGrid = (props) => (
    <TransactionNotesGrid transactionId={props.dataItem.TransactionId} />
  );

  onClearClick = () => {
    this.setState((prevState) => ({
      refresh: prevState.refresh + 1,
      selectAllChecked: false,
      isFromCustomerPanel: false
    }));
    this.props.selectTransaction([]);
    this.props.selectTransactionRefs([]);
    this.clearTransactionFilter();
  };

  onTransactionFilterChange = (option) => {
    if (option && option.parentId === transactionFilterTypes.recentPayment) {
      this.setState({ tranStatus: 2, isFromCustomerPanel: true });
    } else if (option) {
      this.setState({ tranStatus: 0, isFromCustomerPanel: true });
    }
  };

  clearTransactionFilter = () => {
    if (this.trasnactionFilterRef.current) {
      this.trasnactionFilterRef.current.clear();
    }
  };

  onImportDocuments = () => {
    if (this.props.transactionIds && this.props.transactionIds.length > 0) {
      showToastWarningMessage(
        'The import has started in background; please wait and follow indications on screen.'
      );

      this.props.importTransactionDocuments(
        this.props.client,
        this.props.transactionIds,
        this.props.selectedAccount.miaAccountId,
        showToastSuccessMessage
      );
    }
  };

  onDownloadDocuments = () => {
    if (this.props.transactionIds && this.props.transactionIds.length > 0) {
      showToastWarningMessage(
        'The download has started in background; please wait and follow indications on screen.'
      );
      this.props.downloadTransactionDocuments(
        this.props.client,
        this.props.transactionIds,
        this.props.selectedAccount.miaAccountId,
        showToastSuccessMessage,
        showToastErrorMessage
      );
    }
  };

  onDownloadDocumentsWithDocumentTypes = () => {
    if (this.props.transactionIds && this.props.transactionIds.length > 0) {
      showToastWarningMessage(
        'The download has started in background; please wait and follow indications on screen.'
      );
      this.props.downloadTransactionDocuments(
        this.props.client,
        this.props.transactionIds,
        this.props.selectedAccount.miaAccountId,
        showToastSuccessMessage,
        showToastErrorMessage,
        this.props.documentTypes.map(d => d.Id)
      );
    }
  };


  addUserSelectAllClass = (field) => {
    let selectableFields = ["UniqueTransactionRef", "TransactionReference"];
    return selectableFields.includes(field) ? "k-userselect-all" : "";
  };

  render() {
    const hasTransactions =
      this.props.transactionIds && this.props.transactionIds.length > 0;
    const hasTrasnactionsForImport =
      hasTransactions && this.props.transactionIds.length <= 10;
    return (
      <div className={'transaction-grid'}>
        <div className="trans-grid-actions d-flex justify-content-between flex-wrap">
          <div
            className="btn-group flex-grow-1 flex-shrink-1"
            role="group"
            aria-label="Grid filter"
          >
            <button
              className={
                this.state.tranStatus === 0
                  ? 'btn btn-primary'
                  : 'btn btn-default'
              }
              onClick={() => this.changeFilter(0)}
            >
              Open
            </button>
            <button
              className={
                this.state.tranStatus === 1
                  ? 'btn btn-primary'
                  : 'btn btn-default'
              }
              onClick={() => this.changeFilter(1)}
            >
              Closed
            </button>
            <button
              className={
                this.state.tranStatus === 3
                  ? 'btn btn-primary'
                  : 'btn btn-default'
              }
              onClick={() => this.changeFilter(3)}
            >
              Selected
            </button>
            <button
              className={
                this.state.tranStatus === 2
                  ? 'btn btn-primary'
                  : 'btn btn-default'
              }
              onClick={() => this.changeFilter(2)}
            >
              All
            </button>
          </div>
          <div className="w-25 mt-2 ml-2">
            <TransactionSearch
              refresh={this.state.refresh}
              onChange={this.clearTransactionFilter}
            />
          </div>
          {!this.props.selectedAccount.miaAccountIsVirtualAccount && (
            <div className="flex-grow-1 mt-2 ml-3">
              <TransactionFilter
                passedInRef={this.trasnactionFilterRef}
                onChange={this.onTransactionFilterChange}
                refresh={this.state.refresh}
              />
            </div>
          )}

          <div className="flex-shrink-1 mt-2 ml-2">
            <div className="grid-actions">
              <TooltipButton
                title="Clear Selection"
                onClick={this.onClearClick}
                variant="default"
                disabled={!hasTransactions}
              >
                <i className='fa-regular fa-times'></i>
              </TooltipButton>
              <TooltipButton
                title="Export to Excel"
                onClick={this.newExportCsv}
                variant="default"
              >
                <i className='fa-regular fa-file-excel'></i>
              </TooltipButton>
              {this.props.flags.invevoImportTransDocs && (
                <TooltipButton
                  title="Import external documents (max 10)"
                  onClick={this.onImportDocuments}
                  variant="default"
                  disabled={!hasTrasnactionsForImport}
                >
                  <i className='fa-solid fa-file-download'></i>
                </TooltipButton>
              )}
              {this.props.flags.invevoDownloadTransDocs && (
                <>
                  <FeatureToggled flagKey="useCorrectDocumentType" className="d-inline-block">
                    <TooltipButton
                      title="Download external documents (max 10)"
                      onClick={this.onDownloadDocumentsWithDocumentTypes}
                      variant="default"
                      disabled={!hasTrasnactionsForImport}
                    >
                      <i className='fa-solid fa-file-download'></i>
                    </TooltipButton>
                  </FeatureToggled>
                  <FeatureToggleDisabled flagKey="useCorrectDocumentType" className="d-inline-block">
                    <TooltipButton
                      title="Download external documents (max 10)"
                      onClick={this.onDownloadDocuments}
                      variant="default"
                      disabled={!hasTrasnactionsForImport}
                    >
                      <i className='fa-solid fa-file-download'></i>
                    </TooltipButton>
                  </FeatureToggleDisabled>
                </>
              )}
              <TooltipButton
                title="Configure columns"
                onClick={this.toggleShowColumnsConfig}
                variant="default"
              >
                <i className='fa-regular fa-line-columns'></i>
              </TooltipButton>
            </div>
          </div>
        </div>

        <div style={{ userSelect: 'none' }}>
          <StatefulGrid
            client={this.props.client}
            id={TransactionsGrid.getKey()}
            key={TransactionsGrid.getKey()}
            path={getApiUrl(this.props.client) + 'api/dynamiccolumn/transactiongriddata'}
            method={'POST'}
            sortable
            defaultToThousand={this.props.defaultToThousand}
            pageable={{
              pageSizes: [50, 100, 200],
              buttonCount: 5
            }}
            onStateChange={this.onStateChange}
            isSelectedRow={this.isSelectedRow}
            onRowClick={this.onRowClick}
            expandableRow={{
              detail: this.innerGrid,
              id: 'TransactionId',
              type: 'multiple',
              isAtRowEnd: true
            }}
            checkableRow={{
              headerSelectionValue: this.props.allTransactionsSelected
            }}
            onSelectionChange={this.onSelectionChange}
            onHeaderSelectionChange={this.onHeaderSelectionChange}
            hideablePaging={true}
            className={'scrollable-none custom-grid'}
            additionalRequestPayload={this.additionalRequestPayload().data}
            beforeSendFn={this.additionalRequestPayload().beforeSendFn}
            defaultField={{ field: 'DueDate', dir: 'asc' }}
            refresh={this.state.refresh}
            ref={this.customerTransactionsRef}
            afterReceivedFn={(data) => {
              const { totalRows, openingBillingValue, currentBillingValue } = this.getSelectedRows(data)
              const totalOpeningBillingValue = openingBillingValue || 0
              const totalCurrentBillingValue = currentBillingValue || 0
              this.setState({ totalRowsVisible: totalRows, totalOpeningBillingValue, totalCurrentBillingValue })
            }
            }
          >
            {this.state.columns.map(
              (column, idx) =>
                column.show && (
                  <GridColumn key={idx} className={this.addUserSelectAllClass(column.field)} {...column} columnMenu={ColumnMenu} />
                )
            )}
          </StatefulGrid>
          <VisibleColumnConfig
            gridId={UserConfigViewType.transactionGridColConfig}
            columns={this.state.columns}
            setColumns={this.onColumnsSubmit}
            show={this.state.showColumnsConfig}
            setShow={this.toggleShowColumnsConfig}
          />
        </div>
      </div>
    );
  }
}

TransactionsGrid.propTypes = {
  customerId: PropTypes.string,
  selectedTransactionIds: PropTypes.array,
  allTransactionsSelected: PropTypes.bool,
  transactionsAggregate: PropTypes.object,
  searchCriteria: PropTypes.object, // HACK.  Used to get params sent from panel row selection.
  expandedGrid: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    searchCriteria: state.searchReducer.search.criteria,
    transactionIds: state.functionReducer.transactionIds,
    transactionRefs: state.functionReducer.transactionRefs,
    allTransactionsSelected: state.functionReducer.allTransactionsSelected,
    msg: state.functionReducer.msg,
    transactionsAggregate: state.functionReducer.transactionsAggregate,
    isSelected: state.functionReducer.isSelected,
    noteSaved: state.functionReducer.noteSaved,
    gridState: existential(state.gridCacheReducer.grids, 'gridState', {}),
    transactionColumns: state.lookupReducer.transactionColumns,
    selectedAccount: state.currentSelectionReducer.selectedAccount,
    currencyFormat: normaliseFormat(
      state.lookupReducer.globalSettings.results.currencyFormat
    ),
    dateFormat: normaliseFormatDef(
      state.lookupReducer.globalSettings.results.dateFormat
    ),
    transactionGridColConfig: gridColConfig(
      state,
      UserConfigViewType.transactionGridColConfig
    ),
    documentTypes: state.lookupReducer.documentTypes.results.filter(d => d.IsInUse && d.APIName !== null && d.IncludeAPIAndDocumentStore)
  };
};

registerForIntl(TransactionsGrid);

export default withLDConsumer()(
  injectIntl(
    connect(mapStateToProps, {
      selectAllTransactions,
      getTransactionsAggregate,
      resetAggregate,
      storeGridState,
      setSelected,
      selectTransaction,
      selectTransactionRefs,
      getCustomerTransactionsIds,
      getCustomerTransactionsRefsFromTransGrid,
      getRecentPaymentTransactionsIds,
      getLookup,
      getVirtualAccountTransactionsAggregate,
      executeAuthAsyncGet,
      getUserConfig,
      importTransactionDocuments,
      downloadTransactionDocuments,
      removeRouteQuery: Nav.RemoveRouteQuery
    })(withRouter(TransactionsGrid))
  )
);
