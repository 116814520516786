import { AccountInfo } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import { createContext, useCallback, useContext, useMemo } from "react"

type AuthContextValue = {
    getTokenF: () => Promise<string>
    accounts: AccountInfo[]
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined)

type Props = {
    children: JSX.Element | JSX.Element[]
    getTokenOverrideF?: () => Promise<string>
}

export const AuthProvider = ({ children, getTokenOverrideF }: Props) => {
    const { instance, accounts } = useMsal()

    const accessTokenRequest = useMemo(
        () => ({
            scopes: [],
            account: accounts[0]
        }),
        [accounts]
    )

    const getToken = useCallback(async () => await instance.acquireTokenSilent(accessTokenRequest).then(token => token.idToken), [accessTokenRequest, instance])
    const getTokenF = getTokenOverrideF || getToken

    return <AuthContext.Provider value={{ getTokenF, accounts }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    const context = useContext(AuthContext)

    if (!context) {
        throw new Error(`useAuth must be used within a <AuthProvider />`)
    }
    return context
}
