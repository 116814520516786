import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  stepSearchList,
  destroySearchList,
  resumeSearchList
} from '../../actions/searchActions';
import { getNextSearchList } from '../../api/searchListApi';
import { getFocusedAccount } from '../../api/currentSelectionApi';
import { toDataSourceRequest } from '@progress/kendo-data-query';
import { useClient } from "invevo-react-components";

const SearchListToolbar = ({
  activityListNext: {
    isLoading,
    customerId,
    virtualAccountId,
    total,
    page,
    paused
  },
  gridState,
  increment,
  searchCriteria,
  miaAccountId,
  searchListPos,
  getNextSearchList,
  getFocusedAccount,
  resumeSearchList,
  stepSearchList,
  destroySearchList
}) => {
    const client = useClient()
  useEffect(() => {
    if (
      !isLoading &&
      !customerId &&
      !virtualAccountId &&
      (total == null || total > 0)
    ) {
      const serialisedState = toDataSourceRequest(gridState);
      const newPageIndex = page + increment;
      const requestPayload = Object.assign({}, gridState, {
        page: newPageIndex > total ? 1 : newPageIndex,
        pageSize: 1,
        total,
        sort: serialisedState.sort,
        filter: serialisedState.filter,
        ...searchCriteria,
        navType: searchCriteria.type === 'quick' ? 1 : 6,
        quickSearchStartsNewResultsList: false
      });

      getNextSearchList(client, requestPayload, searchCriteria.type, miaAccountId);
    }
  });

  const canGoNext = !isLoading && (customerId || virtualAccountId);

  const onClickNext = () => {
    if (paused) {
      const resumeIsVirtual = !paused.customerId;
      const resumeAccountId = resumeIsVirtual
        ? paused.virtualAccountId
        : paused.customerId;
      getFocusedAccount(client, resumeAccountId, resumeIsVirtual);
      resumeSearchList();
    } else {
      const isVirtual = !customerId;
      const newAccountId = isVirtual ? virtualAccountId : customerId;
      getFocusedAccount(client, newAccountId, isVirtual);
      stepSearchList(page);
    }
  };

  return (
    <div className="search-list-toolbar clearfix">
      <h3 className="float-left">
        Search Result <span className="font-weight-bold">{searchListPos}</span>{' '}
        of <span className="font-weight-bold">{total}</span>
      </h3>
      <div
        className="btn-group float-right"
        role="group"
        aria-label="Search list management"
      >
        <button className="btn btn-default" onClick={destroySearchList}>
          <i className='fa-solid fa-times'></i>
        </button>
        <button
          className="btn btn-default"
          title="Next customer"
          disabled={!canGoNext}
          onClick={onClickNext}
        >
          {paused ? 'Resume' : <i className='fa-solid fa-chevron-right'></i>}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchListPos: state.searchReducer.activityList.searchListPos,
    activityListNext: state.searchReducer.activityList.next,
    increment: state.searchReducer.activityList.increment,
    activityListCriteria: state.searchReducer.activityList.criteria,
    gridState: state.searchReducer.activityList.gridState,
    searchCriteria: state.searchReducer.activityList.criteria,
    myPermissions: state.authReducer.permissions,
    miaAccountId: state.currentSelectionReducer.selectedAccount.miaAccountId
  };
};

export default connect(mapStateToProps, {
  getNextSearchList,
  getFocusedAccount,
  stepSearchList,
  destroySearchList,
  resumeSearchList
})(SearchListToolbar);
