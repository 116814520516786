import FilterList from "../../../../library/FilterList/FilterList"
import { convertFromDtoToArray, convertFromArrayToDto } from "../../../../library/FilterList/FiltersDto"
import { FiltersFlowNode, WaitForConditionFlowNode } from "../../types/FlowSculptorNode"
import { useState } from "react"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import { v4 as uuid } from "uuid"
import Collapsable from "../../../../library/collapsable/Collapsable"
import ShadowBorder from "../../../../library/borders/ShadowBorder"
import Input from "../../../../library/Inputs/Input"
import Lookup from "../../../../types/Lookup"
import EntityDataField from "../../../entityConfig/types/EntityDataField"
import { getDataFieldsFromEntityDataFields } from "../../../../library/helpers/entityHelpers"
import GenericFilter from "../../../../library/FilterList/filterTypes/GenericFilter"

const DEFAULT_FILTER_BRANCH = "Didn't Match"

type FiltersNodeFormProps = {
    filterNode: FiltersFlowNode | WaitForConditionFlowNode
    dataFields: EntityDataField[]
    lookups: Lookup[]
    onFilterNodeUpdated: (filterNode: FiltersFlowNode | WaitForConditionFlowNode) => void
}

const FiltersNodeForm = ({ filterNode, dataFields, lookups, onFilterNodeUpdated }: FiltersNodeFormProps) => {
    const [selectedFilterGroup, setSelectedFilterGroup] = useState<string | undefined>(undefined)

    const onFilterGroupAdded = () => {
        const newFilterGroup = {
            reference: uuid(),
            displayName: "",
            filters: {}
        }

        const updatedFilterNode = {
            ...filterNode,
            filterGroups: [...filterNode.filterGroups, newFilterGroup]
        }

        onFilterNodeUpdated(updatedFilterNode)
    }

    const onFilterGroupUpdated = (filters: GenericFilter[]) => {
        const filtersDto = convertFromArrayToDto(filters)
        onFilterNodeUpdated({
            ...filterNode,
            filterGroups: filterNode.filterGroups.map(filterGroup =>
                filterGroup.reference === selectedFilterGroup ? { ...filterGroup, filters: filtersDto } : filterGroup
            )
        })
    }

    const onDefaultBranchNameUpdated = (name: string) => {
        if (filterNode.nodeType !== "filters") {
            return
        }
        const updatedFilterNode: FiltersFlowNode = {
            ...filterNode,
            defaultDisplayName: name || DEFAULT_FILTER_BRANCH
        }
        onFilterNodeUpdated(updatedFilterNode)
    }

    return (
        <div className="d-flex flex-column p-2 gap-2">
            {filterNode.nodeType === "filters" && (
                <div className="d-flex gap-2 flex-grow-1">
                    <span className="align-self-center text-grey">Default Branch:</span>
                    <div className="d-flex flex-grow-1">
                        <Input
                            className="w-100"
                            value={filterNode.defaultDisplayName === DEFAULT_FILTER_BRANCH ? "" : filterNode.defaultDisplayName}
                            onChange={onDefaultBranchNameUpdated}
                            placeholder={DEFAULT_FILTER_BRANCH}
                        />
                    </div>
                </div>
            )}
            {filterNode.filterGroups.map(filterGroup => {
                const onCollapseToggle = () => setSelectedFilterGroup(selected => (selected === filterGroup.reference ? undefined : filterGroup.reference))

                const onDisplayNameChange = (value: string) =>
                    onFilterNodeUpdated({
                        ...filterNode,
                        filterGroups: filterNode.filterGroups.map(group =>
                            group.reference === filterGroup.reference ? { ...group, displayName: value } : group
                        )
                    })

                const onDeleteClick = () =>
                    onFilterNodeUpdated({
                        ...filterNode,
                        filterGroups: filterNode.filterGroups.filter(group => group.reference !== filterGroup.reference)
                    })

                const title = (
                    <div className="d-flex align-items-center">
                        <Input className="p-2" placeholder="New filter group" value={filterGroup.displayName} onChange={onDisplayNameChange} />
                        <i className="fa-solid fa-trash-can text-grey ms-2 fs-6" role="button" onClick={onDeleteClick} />
                    </div>
                )

                return (
                    <ShadowBorder key={filterGroup.reference}>
                        <Collapsable title={title} isCollapsed={selectedFilterGroup !== filterGroup.reference} onCollapseToggle={onCollapseToggle}>
                            <div className="mx-2 mt-1 mb-3">
                                <FilterList
                                    fields={getDataFieldsFromEntityDataFields(dataFields)}
                                    lookups={lookups}
                                    appliedFilters={convertFromDtoToArray(filterGroup.filters, getDataFieldsFromEntityDataFields(dataFields), lookups)}
                                    onFiltersApplied={onFilterGroupUpdated}
                                    autoApply={true}
                                    isCompact={true}
                                    colour="grey"
                                />
                            </div>
                        </Collapsable>
                    </ShadowBorder>
                )
            })}
            <StandardButton className="mt-2" iconClasses="far fa-plus" label="Add filter group" colour="grey" onClick={onFilterGroupAdded} />
        </div>
    )
}

export default FiltersNodeForm
