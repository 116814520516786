import { getApiUrl } from '../constants';
import { webApiInterface } from './webApiInterface';

export function getFileImportQueue(client, fileImportQueueId, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/queue?importQueueId=' + fileImportQueueId;

    return webApiInterface.authFetch(client, url, dispatch)
        .then(function (response) {
            return response.json();
        });
}

export function updateFileImportQueue(client, fileImportQueue, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/queue/update';

    return webApiInterface.authPost(client, url, dispatch, fileImportQueue, 'POST');
}

export function createFileImportQueue(client, fileImportQueue, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/queue/create';

    return webApiInterface.authPost(client, url, dispatch, fileImportQueue, 'POST');
}

export function deleteFileImportQueue(client, fileImportQueueId, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/queue/delete';

    return webApiInterface.authDelete(client, url, dispatch, fileImportQueueId);
}