import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useSessionStorage } from "../../hooks/useSessionStorage"
import { PRE_AUTH_ROUTE_KEY } from "./constants"

const RedirectRouteLoader = ({children}) => {
    const history = useHistory()
    const [preAuthRoute] = useSessionStorage(PRE_AUTH_ROUTE_KEY)
    
   useEffect(() => {
        if(preAuthRoute) {
            history.push(preAuthRoute)
        }
        
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
    
    return <>{children}</>
}

export default RedirectRouteLoader