import Dropdown from "../../../../../../library/dropdowns/Dropdown"
import { RejectActionEnum } from "../../../../types/RejectActionEnum"

type RejectActionConfigProps = {
    rejectAction: RejectActionEnum
    onChange: (option: RejectActionEnum) => any
}

const RejectActionConfig = ({ rejectAction, onChange }: RejectActionConfigProps) => {
    const rejectActionOptions = [
        { id: 1, value: RejectActionEnum.FAIL_FIELD, label: "Skip field only if invalid" },
        { id: 2, value: RejectActionEnum.FAIL_ROW, label: "Skip whole row if field invalid" },
        { id: 3, value: RejectActionEnum.FAIL_FILE, label: "Skip whole file if field invalid" }
    ]

    const onRejectActionOptionChange = (rejectActionOption: any) => {
        onChange(rejectActionOption.value)
    }

    return (
        <div className="d-flex w-100 h-100">
            <Dropdown
                className="me-3"
                placeholder={"Please select a reject action"}
                selectedOption={rejectActionOptions.find(rao => rao.value === rejectAction)}
                options={rejectActionOptions}
                onOptionSelected={onRejectActionOptionChange}
                ariaLabel="Please select a reject action"
            />
        </div>
    )
}

export default RejectActionConfig
