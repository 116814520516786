import React from 'react';
import { connect } from 'react-redux';
import {
  getStaticNotesByCustomerId,
  saveStaticNote,
  deleteStaticNote,
  updateStaticNotesPosition
} from '../../api/functionApi';
import StaticNote from './staticNote';
import AddStaticNote from './addStaticNote';
import { isNil, map, max } from 'lodash';
import { useEffect } from 'react';
import { useClient } from 'invevo-react-components';

const FridgeNotes = ({
  accountId,
  isVirtual,
  notes,
  saveNote,
  deleteNote,
  updateNotePosition,
  getNotes
}) => {
  const client = useClient()
    
  useEffect(() => {
    getNotes(client, accountId, isVirtual);
  }, [accountId, isVirtual, getNotes, client]);

  const onAdd = (text) => {
    const len = notes.length === 0 ? 1 : max(map(notes, (v) => v.position)) + 1;
    const item = {
      id: '',
      customerId: accountId,
      noteText: text,
      position: len
    };
    saveNote(client, item, isVirtual);
  };

  const onSave = (id, text) => {
    const item = {
      id,
      customerId: accountId,
      noteText: text,
      position: -1
    };
    saveNote(client, item, isVirtual);
  };

  const onDelete = (id) => {
    const item = {
      staticNoteId: id,
      customerId: accountId,
      noteText: '',
      position: -1
    };
    deleteNote(client, item, isVirtual);
  };

  const onMoveNote = (source, target) => {
    const updatedNotes = notes.slice();
    const sourceIndex = updatedNotes.findIndex((o) => o.id === source);
    const targetIndex = updatedNotes.findIndex((o) => o.id === target);
    const tmp = updatedNotes[sourceIndex];
    updatedNotes[sourceIndex] = updatedNotes[targetIndex];
    updatedNotes[targetIndex] = tmp;
    for (let i = 0; i < updatedNotes.length; i++) {
      updatedNotes[i] = { ...updatedNotes[i], position: i + 1 };
    }
    updateNotePosition(client, updatedNotes, isVirtual);
  };

  return (
    <div className="d-flex w-100 h-100 bg-blue overflow-auto">
      <div className={`d-flex flex-column w-100 pt-3`}>
        <div className="d-flex justify-content-between">
          <div>
            <h2 className="text-white">Notes</h2>
          </div>
          <div>
            <AddStaticNote addNote={onAdd} />
          </div>
        </div>
        <div className="notes-container w-100 align-self-center d-flex justify-content-between align-content-start flex-wrap flex-row">
          {isNil(notes)
            ? null
            : map(notes, (item) => {
                return (
                  <StaticNote
                    key={item.id}
                    note={item}
                    saveStaticNote={onSave}
                    deleteStaticNote={onDelete}
                    moveStaticNote={onMoveNote}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    notes: state.functionReducer.notes,
    accountId: state.currentSelectionReducer.selectedAccount.miaAccountId,
    isVirtual:
      state.currentSelectionReducer.selectedAccount.miaAccountIsVirtualAccount
  };
};

export default connect(mapStateToProps, {
  getNotes: getStaticNotesByCustomerId,
  saveNote: saveStaticNote,
  deleteNote: deleteStaticNote,
  updateNotePosition: updateStaticNotesPosition
})(FridgeNotes);
