import CustomerPermission from '../permissions/CustomerPermission'
import UserPermission from '../permissions/UserPermission'
import AutomatePermission from '../permissions/AutomatePermission'
import BrandPermission from '../permissions/BrandPermission'
import CalculatePermission from '../permissions/CalculatePermission'
import ConnectPermission from '../permissions/ConnectPermission'
import DashboardPermission from '../permissions/DashboardPermission'
import DataConfigPermission from '../permissions/DataConfigPermission'
import IntegrationPermission from '../permissions/IntegrationPermission'
import PayPermission from '../permissions/PayPermission'
import TaskPermission from '../permissions/TaskPermission'
import MakeCallPermission from '../permissions/MakeCallPermission'
import classes from './RoleUserCard.module.scss'
import { convertRoleToDto } from '../helpers'
import { useApi, useClient, useConfig, useFeatureToggle } from 'invevo-react-components'
import { actionTypes } from '../../../reducers/actionTypes'
import useApiQuery from '../../../hooks/useApiQuery'

const RoleUserCard = ({ state, dispatch, role, user }) => {
    const client = useClient()
    const api = useApi()
    const config = useConfig()

    const { isEnabled: isRolesAndUsersInElasticEnabled } = useFeatureToggle("storeRolesAndUsersInElastic")

    const putUserRoleAssignments = useApiQuery({
        url: `${config.PLATFORM_API_URL}/api/${client}/user-role-assignment`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    const hasPermissions =
        role.customerPermission ||
        role.userPermission ||
        role.automatePermission ||
        role.brandPermission ||
        role.calculatePermission ||
        role.connectPermission ||
        role.dashboardPermission ||
        role.dataConfigPermission ||
        role.integrationPermission ||
        role.payPermission ||
        role.taskPermission || 
        role.makeCallPermission

    const putUpdatedPermissionToApi = (permissionType, permission, callback) => {
        const updatedRole = {
            ...role,
            assignedUsers: role.assignedUsers.map(u => {
                if (u.clientUserId !== user.clientUserId) return u
                return {
                    ...u,
                    permissions: {
                        ...u.permissions,
                        [permissionType]: permission
                    }
                }
            })
        }

        if(isRolesAndUsersInElasticEnabled) {
            const dto = {
                username: user.clientUsername,
                roleReference: role.reference,
                userRolePermissions: {
                    ...user.permissions,
                    [permissionType]: permission
                }
            }
            return putUserRoleAssignments.execute(undefined, dto)
                .then(_ => {
                    dispatch({ type: actionTypes.ROLE_PERMISSIONS_UPDATED, updatedRole: { id: role.id, ...updatedRole } })
                    callback()
                })
                .catch(error => {
                    console.error(error)
                })
        } else {
            return oldSaveCall(updatedRole, callback)
        }
    }

    const oldSaveCall = (updatedRole, callback) => {
        const dto = convertRoleToDto(updatedRole)
        return api.put(`/api/${client}/roles/${role.id}`, dto)
            .then(_ => {
                dispatch({ type: actionTypes.ROLE_PERMISSIONS_UPDATED, updatedRole: { id: role.id, ...updatedRole } })
                callback()
            })
            .catch(error => {
                console.error(error)
            })
    }

    return (
        <div className={`d-flex flex-column no-select mt-3 ${classes.card} ${classes.shadow}`}>
            <div className="d-flex align-items-center mt-2 mx-2">
                <div className={`d-flex justify-content-center ${classes["profile-picture"]}`}>
                    <i className="fas fa-user fa-2x pb-1 mx-2 my-auto text-grey" />
                </div>
                <h5 className="mb-0 mx-2 text-grey text-truncate" role="textbox" aria-label={`assigned-user-name-${user.clientUsername}`}>{user.clientUsername}</h5>
            </div>

            <div className="d-flex flex-column text-grey my-2 mx-2">
                {hasPermissions &&
                    <>
                        <div className={`d-flex align-items-center py-1 px-3 ${classes["bg-darker-grey"]}`}>
                            <span className="text-uppercase">Permissions</span>
                        </div>
                        {user.permissions.customerPermission &&
                            <CustomerPermission
                                permission={user.permissions.customerPermission}
                                role={role}
                                dispatch={dispatch}
                                isOnRole={false}
                                customerFields={state.customerFields}
                                onPermissionUpdated={(permission, callback) => putUpdatedPermissionToApi('customerPermission', permission, callback)}
                            />}
                        {role.userPermission && <UserPermission permission={role.userPermission} role={role} dispatch={dispatch} isOnRole={false} />}
                        {role.automatePermission && <AutomatePermission permission={role.automatePermission} role={role} dispatch={dispatch} isOnRole={false} />}
                        {role.brandPermission && <BrandPermission permission={role.brandPermission} role={role} dispatch={dispatch} isOnRole={false} />}
                        {role.calculatePermission && <CalculatePermission permission={role.calculatePermission} role={role} dispatch={dispatch} isOnRole={false} />}
                        {role.connectPermission && <ConnectPermission permission={role.connectPermission} role={role} dispatch={dispatch} isOnRole={false} />}
                        {role.dashboardPermission && <DashboardPermission permission={role.dashboardPermission} role={role} dispatch={dispatch} isOnRole={false} />}
                        {role.dataConfigPermission && <DataConfigPermission permission={role.dataConfigPermission} role={role} dispatch={dispatch} isOnRole={false} />}
                        {role.integrationPermission && <IntegrationPermission permission={role.integrationPermission} role={role} dispatch={dispatch} isOnRole={false} />}
                        {role.payPermission && <PayPermission permission={role.payPermission} role={role} dispatch={dispatch} isOnRole={false} />}
                        {role.taskPermission && <TaskPermission permission={role.taskPermission} role={role} dispatch={dispatch} isOnRole={false} />}
                        {role.makeCallPermission && <MakeCallPermission permission={role.makeCallPermission} role={role} dispatch={dispatch} isOnRole={true} />}
                    </>
                }
            </div>
        </div>
    )
}

export default RoleUserCard