import { IconButton } from "invevo-react-components"
import { FileImportConfigColumnTypeState, FileImportConfigFieldTrimOptionState, ReferenceFieldConfigState } from "../../../../reducers/FileImportConfigReducer"
import ColumnTypeConfig from "./ImportColumnTypeConfig"
import classes from "./ReferenceFieldConfig.module.scss"
import TrimOptionConfig from "./TrimOptionConfig"
import { v4 as uuidv4 } from "uuid"
import { FileImportConfigColumnTypeEnum } from "../../../../types/FileImportConfigColumnType"

type ReferenceFieldConfigProps = {
    label: string
    state: { config: ReferenceFieldConfigState; hasHeaderRow: Boolean }
    onColumnsChange: (columns: FileImportConfigColumnTypeState[]) => void
    onAddTrimOption: () => void
    onTrimOptionChange: (trimOption: FileImportConfigFieldTrimOptionState) => void
    onTrimOptionDelete: (trimOption: FileImportConfigFieldTrimOptionState) => void
}

const ReferenceFieldConfig = ({ label, state, onColumnsChange, onTrimOptionChange, onAddTrimOption, onTrimOptionDelete }: ReferenceFieldConfigProps) => {
    const onAddColumnClick = () => {
        onColumnsChange([
            ...state.config.columns,
            {
                key: uuidv4(),
                type: state.hasHeaderRow ? FileImportConfigColumnTypeEnum.HEADER : FileImportConfigColumnTypeEnum.INDEX,
                header: "",
                index: ""
            } as FileImportConfigColumnTypeState
        ])
    }

    return (
        <div className="d-flex flex-column mt-4">
            <span className="text-grey fs-3">{label}</span>

            <div className={`d-flex flex-column mt-3`}>
                <div className={`d-flex w-100 ${classes.card}`}>
                    <div className="d-flex flex-column p-4 w-100">
                        <div className={`d-flex flex-grow-1 flex-column small`}>
                            <div className="d-flex align-items-center mb-2">
                                <span className="fs-4 text-grey">Column(s)</span>
                                <IconButton
                                    className="ms-auto"
                                    iconClasses="far fa-plus"
                                    onClick={onAddColumnClick}
                                    ariaLabel={`Add new column for ${label}`}
                                />
                            </div>
                            <ColumnTypeConfig columns={state.config.columns} onChange={onColumnsChange} />
                        </div>
                        <div className={`${classes.horizontalDivider} m-2`} />
                        <div className="d-flex align-items-center">
                            <span className="fs-4 text-grey">Trim options</span>
                            <IconButton
                                className="ms-auto"
                                iconClasses="far fa-plus"
                                onClick={onAddTrimOption}
                                ariaLabel={`Add new trim option for ${label}`}
                            />
                        </div>
                        <div className={`d-flex flex-column mt-2`}>
                            {state.config.trimOptions.length > 0 &&
                                state.config.trimOptions
                                    .map((trimOption: FileImportConfigFieldTrimOptionState) => (
                                        <TrimOptionConfig
                                            key={trimOption.key}
                                            trimOption={trimOption}
                                            onChange={onTrimOptionChange}
                                            onDelete={onTrimOptionDelete}
                                        />
                                    ))
                                    .reduce((curr, prev) => (
                                        <>
                                            {curr}
                                            <span className="my-2" />
                                            {prev}
                                        </>
                                    ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReferenceFieldConfig
