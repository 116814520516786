import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import TextInput from '../common/textInput';
import { updateCustomerAddress } from '../../api/functionApi';
import { getLookup } from '../../api/lookupApi';
import { api } from '../../constants';
import {
  executeAuthAsyncGet,
  executeAuthAsyncPost,
  executeAsyncResetGet
} from '../../utility/asyncSupport';
import { showToastSuccessMessage, showToastErrorMessage } from '../../api/toasterApi';
import { Collapse } from 'react-bootstrap';

export class EditCustomerAddress extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.update = this.update.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onUpdateCustomerAddress = this.onUpdateCustomerAddress.bind(this);
    this.manualValidate = this.manualValidate.bind(this);
    this.onCustomerAddressLoaded = this.onCustomerAddressLoaded.bind(this);
    this.edit = this.edit.bind(this);

    this.state = this.defaultFormState();
  }

  defaultFormState() {
    return {
      customerAddress: {
        id: null,
        customerId: this.props.selectedAccount.miaAccountId,
        addressTypeId: 0,
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        addressLine4: '',
        addressLine5: '',
        addressLine6: '',
        addressLine7: ''
      },
      canSubmit: true,
      showAddressForm: false
    };
  }

  manualValidate() {
    let submitOn = true;
    if (this.state.customerAddress.addressTypeId <= 0) {
      submitOn = false;
    }
    this.setState({ canSubmit: submitOn });
  }

  onChange(event) {
    const field = event.target.name;
    const value = event.target.value;

    this.setState({
      ...this.state,
      customerAddress: {
        ...this.state.customerAddress,
        [field]: value
      }
    });
    this.manualValidate();
  }

  onAddressTypeChange(name, value) {
    this.setState({
      ...this.state,
      customerAddress: {
        ...this.state.customerAddress,
        [name]: value
      }
    });

    this.manualValidate();
  }

  edit(guid) {
    this.setState({ customerAddressId: guid });
    this.props.executeAuthAsyncGet(
      this.props.client,
      'Customer/GetCustomerAddress',
      'CUSTOMER_ADDRESS',
      { customerAddressId: guid },
      this.onCustomerAddressLoaded
    );
  }

  onCustomerAddressLoaded() {
    const customerAddress = this.props.CUSTOMER_ADDRESS.result;
    this.setState({
      customerAddress: {
        id: customerAddress.id,
        customerId: customerAddress.customerId,
        addressTypeId: customerAddress.addressTypeId,
        addressLine1: customerAddress.addressLine1,
        addressLine2: customerAddress.addressLine2,
        addressLine3: customerAddress.addressLine3,
        addressLine4: customerAddress.addressLine4,
        addressLine5: customerAddress.addressLine5,
        addressLine6: customerAddress.addressLine6,
        addressLine7: customerAddress.addressLine7
      }
    });
    this.showComponent();
  }

  showComponent() {
    this.setState({ showAddressForm: true });
  }

  update() {
    const that = this;

    if (
      !that.canSaveCustomerAddress() &&
      api.customerAddressValidation === true
    ) {
      showToastErrorMessage(
        'Saving multiple entries for this customer address type is not allowed'
      );
      return;
    }

    that.props.updateCustomerAddress(
      this.props.client,
      that.state.customerAddress,
      function (/*json*/) {
        showToastSuccessMessage('Customer address saved correctly.');
        that.setState(that.defaultFormState());
        if (that.props.onCustomerAddressSaved != null) {
          that.props.onCustomerAddressSaved();
        }
      },
      function (/*err*/) {
        showToastErrorMessage('Customer address NOT saved.');
      }
    );
  }

  cancel() {
    this.setState({ showAddressForm: false });
  }

  canSaveCustomerAddress() {
    return true;
  }

  onUpdateCustomerAddress() {
    this.setState(this.defaultFormState());
  }

  render() {
    const formData = this.state.customerAddress;

    const cctId = this.state.customerAddress['addressTypeId'];
    let cctTitle = 'Customer Address Type';
    let customerAddressTypes = [];
    const ccts = this.props.CUSTOMER_ADDRESS_TYPES.result;
    if (ccts && ccts.length > 0) {
      const foundType = ccts.find((t) => t.id === cctId);
      if (foundType) {
        cctTitle = 'Customer Address Type: ' + foundType.text;
      }
      customerAddressTypes = this.props.CUSTOMER_ADDRESS_TYPES.result.map(
        (t) => {
          return (
            <li
              key={t.id}
              className="dropdown-item"
              onClick={this.onAddressTypeChange.bind(
                this,
                'addressTypeId',
                t.id
              )}
            >
              {t.text}
            </li>
          );
        }
      );
    }

    return (
      <Collapse>
        <div>
          <Formsy
            key="edit-customercontact-form"
            className="form-group well below-grid-form"
            id="editcustomeraddressform"
            style={{ width: '580px', marginTop: '1px' }}
          >
            <h2>Edit Address</h2>
            <TextInput
              name="addressLine1"
              placeholder="Address Line 1"
              label=""
              value={formData.addressLine1}
              onChange={this.onChange}
            />
            <TextInput
              name="addressLine2"
              placeholder="Address Line 2"
              label=""
              value={formData.addressLine2}
              onChange={this.onChange}
            />
            <TextInput
              name="addressLine3"
              placeholder="Address Line 3"
              label=""
              value={formData.addressLine3}
              onChange={this.onChange}
            />
            <TextInput
              name="addressLine4"
              placeholder="Address Line 4"
              label=""
              value={formData.addressLine4}
              onChange={this.onChange}
            />
            <TextInput
              name="addressLine5"
              placeholder="Address Line 5"
              label=""
              value={formData.addressLine5}
              onChange={this.onChange}
            />
            <TextInput
              name="addressLine6"
              placeholder="Address Line 6"
              label=""
              value={formData.addressLine6}
              onChange={this.onChange}
            />
            <TextInput
              name="addressLine7"
              placeholder="Address Line 7"
              label=""
              value={formData.addressLine7}
              onChange={this.onChange}
            />
            <div className="form-group">
              <ul className="nav" id="customerAddressTypesLst">
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    id="dropdownMenuButton"
                    className="nav-link dropdown-toggle boldTitle noPaddingLeft"
                    data-bs-toggle="dropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    {cctTitle}
                    <b className="caret"></b>
                  </a>
                  <ul aria-labelledby="dropdownMenuButton" className="dropdown-menu multi-level ddscroll">
                    {customerAddressTypes}
                  </ul>
                </li>
              </ul>
            </div>

            <button
              type="button"
              onClick={this.update}
              disabled={!this.state.canSubmit}
              className="btn btn-default"
            >
              Update
            </button>
            <button
              type="button"
              onClick={this.cancel}
              className="btn btn-default"
            >
              Cancel
            </button>
          </Formsy>
        </div>
      </Collapse>
    );
  }
}

EditCustomerAddress.propTypes = {};

const mapStateToProps = (state) => {
  const { asyncReducer, lookupReducer, currentSelectionReducer } = state;
  const { CUSTOMER_ADDRESS, CUSTOMER_ADDRESS_TYPES } = asyncReducer;
  const { availableLanguages, validatedContactIds } = lookupReducer;
  const { selectedAccount } = currentSelectionReducer;
  return {
    availableLanguagesLookup: availableLanguages,
    validatedContactIdsLookup: validatedContactIds,
    CUSTOMER_ADDRESS,
    CUSTOMER_ADDRESS_TYPES,
    selectedAccount
  };
};

export default connect(
  mapStateToProps,
  {
    getLookup,
    updateCustomerAddress,
    executeAuthAsyncGet,
    executeAuthAsyncPost,
    executeAsyncResetGet
  },
  null,
  { forwardRef: true }
)(EditCustomerAddress);
