import classes from "./BlueButton.module.scss"

type Props = {
    className?: string
    label: string
    onClick: () => void
    iconClasses?: string
    ariaLabel?: string
}

const BlueButton = ({ className = "", label, onClick, iconClasses = "", ariaLabel = "" }: Props) => {
    return (
        <div className={`${className} ${classes.button} d-flex px-2 py-1 pointer`} onClick={onClick} role="button" aria-label={ariaLabel}>
            {iconClasses && <i className={`${iconClasses} me-1 my-auto`} />}
            <span className="text-white m-auto no-select text-nowrap">{label}</span>
        </div>
    )
}

export default BlueButton
