import { Loading, useConfig } from "invevo-react-components"
import { useState } from "react"
import useApiQuery from "../../../hooks/useApiQuery"
import DataField from "../../../types/DataField"
import DataPrimitive from "../../../types/DataPrimitive"
import { DataFieldDto } from "../../../types/dtos/DataFieldDto"
import FieldType from "../../../types/enums/FieldType"
import Lookup from "../../../types/Lookup"
import LegacySmsTemplate from "../types/LegacySmsTemplate"
import WorkflowsDashboard from "./WorkflowsDashboard"
import EntityConfig from "../../entityConfig/types/EntityConfig"
import { GetFileEntityExportConfigDto } from "../../integration/types/dtos/FileEntityExportConfigDto"
import { FileEntityExportConfig } from "../../integration/types/fileEntityExport/FileExportConfig"
import { mapGetFileEntityExportConfigsDtoToDomain } from "../../integration/components/utils"
import EntityRelationshipDto from "../../entityConfig/types/EntityRelationshipDto"
import useClient from "../../../hooks/useClient"
import { useFeatureToggle } from "../../../hooks/useFeatureToggle"
import { CommonFileImportConfigDto, GetFileImportConfigDto } from "../../integration/types/dtos/FileImportConfigDto"
import EntityResults from "../../../library/grid/types/EntityResults"

const Automate = () => {
    const { isEnabled: isNewEntityStructure } = useFeatureToggle("newEntityStructure")

    const [dataFields, setDataFields] = useState<DataField[]>([])
    const [fileEntityExportConfigs, setFileEntityExportConfigs] = useState<FileEntityExportConfig[]>([])

    const client = useClient()
    const config = useConfig()

    const dataFieldsRequest = useApiQuery<DataFieldDto[]>({
        url: `${config.DATA_API_URL}/api/${client}/data-field`,
        method: "GET",
        onSuccess: fields =>
            setDataFields(
                fields
                    .filter(field => field.fieldType === FieldType.CUSTOMER)
                    .map(field => ({
                        value: field.fieldName,
                        label: field.displayName,
                        type: field.dataPrimitive,
                        lookup: field.dataPrimitive === DataPrimitive.LOOKUP ? field.lookup : undefined,
                        isQuickFilter: field.isQuickFilter,
                        fieldType: field.fieldType
                    }))
            )
    })

    const lookupsRequest = useApiQuery<Lookup[]>({
        url: `${config.DATA_API_URL}/api/${client}/lookups`,
        method: "GET"
    })

    const smsTemplateRequest = useApiQuery<LegacySmsTemplate[]>({
        url: `${config.CONNECT_API_URL}/api/${client}/sms-template`,
        method: "GET"
    })

    const entityConfigRequest = useApiQuery<EntityConfig[]>({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-config`,
        method: "GET",
        isExecutedAutomatically: isNewEntityStructure
    })

    const fileExportConfigRequest = useApiQuery<GetFileEntityExportConfigDto[]>({
        url: `${config.INTEGRATION_API_URL}/api/${client}/file-export-config`,
        method: "GET",
        isExecutedAutomatically: isNewEntityStructure,
        onSuccess: data => setFileEntityExportConfigs(mapGetFileEntityExportConfigsDtoToDomain(data))
    })

    const entityRelationshipsRequest = useApiQuery<EntityRelationshipDto[]>({
        url: `${config.ENTITY_CONFIG_API_URL}/api/${client}/entity-relationships`,
        method: "GET",
        isExecutedAutomatically: isNewEntityStructure
    })

    const fileImportConfigRequest = useApiQuery<GetFileImportConfigDto[]>({
        url: `${config.INTEGRATION_API_URL}/api/${client}/file-import-config`,
        method: "GET",
        isExecutedAutomatically: isNewEntityStructure
    })

    const getWorkflowTriggerConfigsRequest = useApiQuery<EntityResults>({
        url: `${config.SEARCH_API_URL}/api/${client}/entities`,
        method: "POST",
        dto: {
            pageSize: 1000,
            searchAfterId: null,
            sortBy: null,
            filters: { booleanFieldIsEqualTo: [{ fieldName: "boolean_archived", equalTo: false }] },
            entityTypeReference: "config_workflow_trigger"
        },
        isExecutedAutomatically: isNewEntityStructure
    })

    const fileImportConfigs = (fileImportConfigRequest.data ?? []).map(config => {
        return {
            reference: config.reference,
            name: (config.dataFileImportConfig as CommonFileImportConfigDto).configName
        }
    })

    const workflowTriggerConfigs = (getWorkflowTriggerConfigsRequest.data?.pagedEntity ?? []).map(entity => {
        return {
            reference: entity.reference,
            triggeredByReference: entity.entityFields.textFields["text_triggered_by_reference"] ?? "",
            triggeredByName: entity.entityFields.textFields["text_triggered_by_name"] ?? "",
            triggeredByType: (entity.entityFields.textFields["text_triggered_by_job_type"] ?? "WORKFLOW_RULE") as "WORKFLOW_RULE" | "FILE_IMPORT",
            ruleToTriggerReference: entity.entityFields.textFields["text_rule_to_trigger_reference"] ?? "",
            ruleToTriggerName: entity.entityFields.textFields["text_rule_to_trigger_name"] ?? ""
        }
    })

    const isFetching =
        dataFieldsRequest.isFetching ||
        lookupsRequest.isFetching ||
        smsTemplateRequest.isFetching ||
        entityConfigRequest.isFetching ||
        fileExportConfigRequest.isFetching ||
        entityRelationshipsRequest.isFetching ||
        fileImportConfigRequest.isFetching ||
        getWorkflowTriggerConfigsRequest.isFetching
    const hasFetched =
        dataFieldsRequest.hasFetched &&
        lookupsRequest.hasFetched &&
        smsTemplateRequest.hasFetched &&
        ((entityConfigRequest.hasFetched &&
            fileExportConfigRequest.hasFetched &&
            entityRelationshipsRequest.hasFetched &&
            fileImportConfigRequest.hasFetched &&
            getWorkflowTriggerConfigsRequest.hasFetched &&
            getWorkflowTriggerConfigsRequest.data !== undefined) ||
            !isNewEntityStructure)

    return (
        <Loading isLoading={isFetching || !hasFetched} colour="blue">
            {!isFetching && hasFetched && (
                <WorkflowsDashboard
                    entityConfigs={entityConfigRequest.data ?? []}
                    customerFields={dataFields}
                    lookups={lookupsRequest.data ?? []}
                    fileEntityExportConfigs={fileEntityExportConfigs}
                    smsTemplates={smsTemplateRequest.data?.filter(template => template.isActive) ?? []}
                    entityRelationships={entityRelationshipsRequest.data ?? []}
                    fileImportConfigs={fileImportConfigs}
                    workflowTriggerConfigs={workflowTriggerConfigs}
                    isNewEntityStructureEnabled={isNewEntityStructure}
                />
            )}
        </Loading>
    )
}

export default Automate
