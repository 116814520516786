import Input from "../Inputs/Input"
import Textbox from "../Inputs/Textbox"

type TextInfoElementProps = {
    className: string
    value: string
    fieldName: string
    disabled: boolean
    onChange: (value: string) => void
    useTextArea?: boolean
}

const TextInfoElement = ({ className, value, fieldName, disabled, onChange, useTextArea = false }: TextInfoElementProps) => {

    return (
        <div className={`fs-4 ${className}`}>
            {useTextArea ? (
                <Textbox
                    className="w-100"
                    ariaLabel={`${fieldName}-text-input`}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />
            ) : (
                <Input
                    className="w-100"
                    ariaLabel={`${fieldName}-text-input`}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />
            )}
        </div>
    )
}

export default TextInfoElement
