import DataRow from "../../../library/dataGrid/types/DataRow"
import classes from "./CustomerCard.module.scss"
import { convertToCurrency } from "../../../library/helpers/numberUtils"
import { useFeatureToggle } from "../../../hooks/useFeatureToggle"

type CustomerCardProps = {
    customer: DataRow
    onClick: (customer: DataRow) => void
    isSelected: boolean
}

const CustomerCard = ({ customer, onClick, isSelected }: CustomerCardProps) => {
    const onCustomerSelected = () => onClick(customer)
    const { isEnabled: hideCurrency } = useFeatureToggle("removeCurrencySymbols")

    return (
        <div
            className={`${classes.customerCard} ${isSelected ? classes.selected : ""} p-2 mb-3 bg-blue text-white pointer no-select`}
            onClick={onCustomerSelected}
            role="row"
            aria-label={`customer-card-${customer.id}`}
            aria-selected={isSelected}
        >
            <div className="d-flex flex-row justify-content-between">
                <h5 className="text-white font-weight-bold mb-1">{customer.id}</h5>
                <h5 className="text-white font-weight-bold mb-1">{customer.data["brand"]}</h5>
            </div>
            <div className="d-flex flex-row justify-content-between">
                <h4 className="text-white font-weight-bold mb-0">
                    <i className="me-1 fa-light fa-building"></i>
                    {customer.data["name"]}
                </h4>
            </div>
            <div className={`${classes.separator} d-flex flex-row my-2 bg-grey`}></div>
            <div className="d-flex flex-row justify-content-between">
                <div>
                    <h6 className="mb-1 text-white">BALANCE</h6>
                    <h5 className="text-white mb-0">{convertToCurrency(customer.data["balance"]!, customer.data["currencycode"], undefined, hideCurrency)}</h5>
                </div>
                <div>
                    <h6 className="mb-1 text-white">OVERDUE</h6>
                    <h5 className="text-white mb-0">{convertToCurrency(customer.data["overdue"]!, customer.data["currencycode"], undefined, hideCurrency)}</h5>
                </div>
            </div>
        </div>
    )
}

export default CustomerCard
