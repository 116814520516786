import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { IntlProvider } from 'react-intl';
import enMessages from '../translations/en-GB.json';
import esMessages from '../translations/en-ES.json';

const hasIntl = !!Intl;

const hasPluralRules = Intl && !!Intl.PluralRules;
if (!hasPluralRules && hasIntl) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/en');
  require('@formatjs/intl-pluralrules/locale-data/es');
}

const hasRelativeTimeFormat = Intl && !!Intl.RelativeTimeFormat;
if (!hasRelativeTimeFormat && hasIntl) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/locale-data/en');
  require('@formatjs/intl-relativetimeformat/locale-data/es');
}

export const langConstants = {
  PANEL_TITLE: 'PANEL_TITLE',
  MSG: 'MSG',
  TODAYS_DATE: 'TODAYS_DATE',
  PAY_TITLE: 'PAY_TITLE',
  CONTEXTUAL_PHRASE: 'CONTEXTUAL_PHRASE',
  SEC_QUESTION_1: 'SEC_QUESTION_1',
  SEC_QUESTION_2: 'SEC_QUESTION_2',
  SEC_QUESTION_3: 'SEC_QUESTION_3',
  SEC_QUESTION_4: 'SEC_QUESTION_4',
  USERNAME: 'USERNAME',
  PASSWORD: 'PASSWORD',
  FORGOT_USERNAME: 'FORGOT_USERNAME',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  LOGIN: 'LOGIN',
  CREDENTIALS_NOT_VALID: 'CREDENTIALS_NOT_VALID',
  NEW_PASSWORD: 'NEW_PASSWORD',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
  PHONE_VALIDATION: 'PHONE_VALIDATION',
  PASSWORD_VALIDATION: 'PASSWORD_VALIDATION',
  CONFIRM_VALIDATION: 'CONFIRM_VALIDATION'
};

class Ero57IntlProvider extends Component {
  getMessages(lang) {
    switch (lang) {
      case 'es-ES':
        return esMessages;
      default:
        return enMessages;
    }
  }

  render() {
    return (
      <IntlProvider
        locale={this.props.selectedLanguage}
        messages={this.getMessages(this.props.selectedLanguage)}
      >
        {this.props.children}
      </IntlProvider>
    );
  }
}

Ero57IntlProvider.propTypes = {
  selectedLanguage: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  const { authReducer } = state;
  const { selectedLanguage } = authReducer;
  return {
    selectedLanguage
  };
}

export default connect(mapStateToProps)(Ero57IntlProvider);
