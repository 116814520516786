import { useClient } from 'invevo-react-components';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { executeAuthAsyncPost } from '../../utility/asyncSupport';
import { creditLimitDecisionNames } from './creditConstants';
import RenderRow from './renderRow';
import { displayErrorMessage } from '../../utility/error';
import { showToastSuccessMessage } from '../../api/toasterApi';
import { useInternationalization } from '@progress/kendo-react-intl';
import { ConfrimDialog } from '../common/confirmDialog';

const DecisionDisplay = ({
  pendingRequest,
  globalCurrencyCode,
  currencyFormat,
  reasonsForDecisionLookup,
  refreshCallback,
  executeAuthAsyncPost
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const client = useClient()

  const closeDialog = () => {
    setShowDialog(false);
  };

  const actionDecisionDisplayCancel = () => {
    const request = pendingRequest.result; //TODO check this

    const decisionObject = {
      Id: request.Id,
      CustomerId: request.CustomerId,
      VirtualAccountId: request.VirtualAccountId,
      DecisionOneAmount: request.RequestAmount,
      DecisionOneType: 0,
      DecisionOneReasonId: 999,
      NoteTextDecisionOne: ''
    };
    executeAuthAsyncPost(
      client,
      'CreditLimit/InsertFirstDecision',
      'INSERT_FIRST_DECISION',
      decisionObject,
      () => {
        showToastSuccessMessage('Request Cancelled.');

        refreshCallback();
        closeDialog();
      },
      (error) => {
        displayErrorMessage(error);

        closeDialog();
      }
    );
  };

  const internationService = useInternationalization();
  const req = pendingRequest;
  if (!req) {
    return <div></div>;
  }

  let decision = '';
  let reason = '';
  if (reasonsForDecisionLookup != null) {
    const d = reasonsForDecisionLookup.results.find(
      (o) => o.Item1 === req.DecisionOneReasonId
    );
    if (d) {
      decision =
        d.Item2 === creditLimitDecisionNames.APPROVED ? 'Approved' : 'Declined';
      reason = d.Item3;
    }
  }

  const users = [...req.SecondUserIds].sort((a, b) => {
    if (a.Description.toUpperCase() < b.Description.toUpperCase()) return -1;
    if (a.Description.toUpperCase() > b.Description.toUpperCase()) return 1;
    return 0;
  });
  const usersListItems = users.map((u) => (
    <option value={u.Id} key={u.Id}>
      {u.Description}
    </option>
  ));

  return (
    <div>
      <h3>1st Decision</h3>

      <hr />

      <RenderRow
        leftContent="Decision"
        rightContent={<span>{decision}</span>}
        leftWidth={4}
        rightWidth={8}
      />

      <RenderRow
        leftContent="User"
        rightContent={<span>{req.DecisionOneUserName}</span>}
        leftWidth={4}
        rightWidth={8}
      />

      <RenderRow
        leftContent="Reason"
        rightContent={<span>{reason}</span>}
        leftWidth={4}
        rightWidth={8}
      />

      <RenderRow
        leftContent="Amount"
        rightContent={
          <span>
            {globalCurrencyCode}{' '}
            {internationService.formatNumber(
              req.DecisionOneAmount,
              currencyFormat
            )}
          </span>
        }
        leftWidth={4}
        rightWidth={8}
      />
      {req.SecondDecisionNeeded && (
        <RenderRow
          leftContent="Assigned to"
          rightContent={
            <select size="4" disabled style={{ width: '100%' }}>
              {usersListItems}
            </select>
          }
          leftWidth={4}
          rightWidth={8}
        />
      )}

      <ConfrimDialog
        show={showDialog}
        message={'Are you sure you want to cancel this request?'}
        onConfirm={actionDecisionDisplayCancel}
        onClose={closeDialog}
      />
    </div>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  const { lookupReducer, asyncReducer, authReducer } = state;
  const { reasonsForDecision } = lookupReducer;
  const { GET_PENDING_REQUEST } = asyncReducer;
  const { userName } = authReducer;
  return {
    reasonsForDecisionLookup: reasonsForDecision,
    pendingRequest: GET_PENDING_REQUEST.result,
    userName: userName,
    globalCurrencyCode:
      state.lookupReducer.globalSettings.results.globalCurrencyCode,
    currencyFormat: state.lookupReducer.globalSettings.results.currencyFormat
  };
};

export default connect(mapStateToProps, {
  executeAuthAsyncPost
})(DecisionDisplay);
