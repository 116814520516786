import { StandardButton } from "invevo-react-components"
import {
    FileImportConfigAction,
    FileImportConfigActionEnum,
    FieldConfigState,
    FileImportConfigColumnTypeState,
    FileImportConfigFieldTrimOptionState
} from "../../../../reducers/FileEntityImportConfigReducer"
import { FileType } from "../../../../types/FileImportConfig"
import FieldConfig from "./ImportFieldConfig"
import FileUploadButton from "../../../../../../library/buttons/FileUploadButton/FileUploadButton"
import { toast } from "react-toastify"
import {
    FileImportConfigFieldDataType,
    FileImportConfigFieldDataTypeText,
    FileImportConfigFieldDataTypeEnum,
    FileImportConfigFieldDataTypeBoolean,
    FileImportConfigFieldDataTypeDate,
    FileImportConfigFieldDataTypeNumber
} from "../../../../types/FileImportConfigFieldDataType"
import { RejectActionEnum } from "../../../../types/RejectActionEnum"
import {
    FileImportConfigFieldDefaultBooleanValue,
    FileImportConfigFieldDefaultNumberValue,
    FileImportConfigFieldDefaultStringValue,
    FileImportConfigFieldDefaultValueEnum
} from "../../../../types/FileImportConfigFieldDefaultValue"
import { v4 as uuidv4 } from "uuid"
import { FileImportConfigColumnHeader, FileImportConfigColumnIndex, FileImportConfigColumnTypeEnum } from "../../../../types/FileImportConfigColumnType"
import EntityDataField from "../../../../../entityConfig/types/EntityDataField"
import Lookup from "../../../../../../types/Lookup"

const IMPORT_FIELD_FILE_HEADERS = ["dataFieldReference", "dataType", "rejectAction", "defaultValue", "canBeEmpty", "column"]

type FieldConfigsProps = {
    state: { fieldConfigs: FieldConfigState[]; dataFields: EntityDataField[]; lookups: Lookup[]; hasHeaderRow: Boolean; type: FileType }
    dispatch: React.Dispatch<FileImportConfigAction>
}

const ImportFieldConfigs = ({ state, dispatch }: FieldConfigsProps) => {
    const onAddFieldClick = () => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_ADDED
        })
    }

    const onFieldRemoveClick = (fieldConfig: FieldConfigState) => {
        dispatch({
            type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIG_REMOVED,
            fieldConfigKey: fieldConfig.key
        })
    }

    const onImportFiledsFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0]
        const reader: FileReader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
            const lines = e?.target?.result?.toString()?.split("\r\n") || []
            const header = lines[0]!.split(",")
            const content = lines.slice(1)

            if (IMPORT_FIELD_FILE_HEADERS.filter(f => !header.includes(f)).length !== 0) {
                toast.error("Uploaded import fields didn't contain all necessary columns")
                return
            }
            let newFields: FieldConfigState[] = []
            content.forEach(line => {
                const newLine = recogniseTextBetweenSpeachmarks(line)
                const fields = newLine.split(",")
                newFields = [...newFields, getDataField(header, fields)]
            })

            dispatch({
                type: FileImportConfigActionEnum.DATA_FILE_CONFIG_FIELD_CONFIGS_UPLOADED,
                fieldConfigs: newFields
            })
        }
        file && reader.readAsText(file)
    }

    const recogniseTextBetweenSpeachmarks = (line: string) => line.replace(/"[^"]+"/g, match => match.replace(/,/g, "")).replace(/"/g, "")

    const getDataField = (header: string[], fields: string[]): FieldConfigState => {
        const dataType = getDataType(fields, header)
        return {
            key: uuidv4(),
            trimOptions: [] as FileImportConfigFieldTrimOptionState[],
            dataFieldReference: fields[header.indexOf("dataFieldReference")]!,
            dataType: dataType,
            rejectAction: fields[header.indexOf("rejectAction")]!.toString() as RejectActionEnum,
            defaultValue: getDefaultValue(dataType, fields[header.indexOf("defaultValue")]!),
            canBeEmpty: fields[header.indexOf("canBeEmpty")]!.toLowerCase() === "true",
            columns: getColumns(fields, header) as FileImportConfigColumnTypeState[]
        }
    }

    const getDataType = (fields: string[], header: string[]) => {
        switch (fields[header.indexOf("dataType")]) {
            case FileImportConfigFieldDataTypeEnum.NUMBER:
                return {
                    type: FileImportConfigFieldDataTypeEnum.NUMBER,
                    culture: fields[header.indexOf("culture")] || "gb"
                } as FileImportConfigFieldDataTypeNumber
            case FileImportConfigFieldDataTypeEnum.DATE:
                return {
                    type: FileImportConfigFieldDataTypeEnum.DATE,
                    format: fields[header.indexOf("dateFormat")],
                    timezone: fields[header.indexOf("timezone")]
                } as FileImportConfigFieldDataTypeDate
            case FileImportConfigFieldDataTypeEnum.BOOLEAN:
                return { type: FileImportConfigFieldDataTypeEnum.BOOLEAN } as FileImportConfigFieldDataTypeBoolean
            default:
                return { type: FileImportConfigFieldDataTypeEnum.TEXT } as FileImportConfigFieldDataTypeText
        }
    }

    const getDefaultValue = (dataType: FileImportConfigFieldDataType, defaultValue: string) => {
        if (defaultValue.length === 0) return undefined
        else
            switch (dataType.type) {
                case FileImportConfigFieldDataTypeEnum.NUMBER:
                    return { type: FileImportConfigFieldDefaultValueEnum.NUMBER, numberValue: defaultValue } as FileImportConfigFieldDefaultNumberValue
                case FileImportConfigFieldDataTypeEnum.BOOLEAN:
                    return {
                        type: FileImportConfigFieldDefaultValueEnum.BOOLEAN,
                        booleanValue: defaultValue.toLowerCase() === "true"
                    } as FileImportConfigFieldDefaultBooleanValue
                default:
                    return { type: FileImportConfigFieldDefaultValueEnum.STRING, stringValue: defaultValue } as FileImportConfigFieldDefaultStringValue
            }
    }

    const getColumns = (fields: string[], header: string[]) => {
        const columnFields = header.filter(h => h.includes("column"))

        return columnFields
            .map(columnField => {
                if (state.hasHeaderRow)
                    return (
                        fields[header.indexOf(columnField)] &&
                        fields[header.indexOf(columnField)]!.length > 0 &&
                        ({
                            key: uuidv4(),
                            type: FileImportConfigColumnTypeEnum.HEADER,
                            header: fields[header.indexOf(columnField)]
                        } as FileImportConfigColumnHeader)
                    )
                else
                    return (
                        fields[header.indexOf(columnField)] &&
                        fields[header.indexOf(columnField)]!.length > 0 &&
                        ({
                            key: uuidv4(),
                            type: FileImportConfigColumnTypeEnum.INDEX,
                            index: parseInt(fields[header.indexOf(columnField)]!)
                        } as FileImportConfigColumnIndex)
                    )
            })
            .filter(a => a)
    }

    return (
        <div className="d-flex flex-column mt-4">
            <div className="d-flex align-items-center">
                <span className="text-grey fs-3">{`Fields (${state.fieldConfigs.length})`}</span>
                <StandardButton
                    className="ms-auto"
                    label="Add new field"
                    colour="white"
                    iconClasses="fal fa-plus"
                    onClick={onAddFieldClick}
                    ariaLabel="Add new field"
                />

                <FileUploadButton className={`ms-2`} label="Import data fields" onFileUpload={onImportFiledsFileSelected} ariaLabel="import-data-fields" />
            </div>
            <div className={`d-flex flex-column mt-3`}>
                {state.fieldConfigs.length > 0 &&
                    state.fieldConfigs
                        .map(fieldConfig => (
                            <FieldConfig
                                key={fieldConfig.key}
                                state={{
                                    config: fieldConfig,
                                    dataFields: state.dataFields,
                                    lookups: state.lookups,
                                    hasHeaderRow: state.hasHeaderRow,
                                    type: state.type
                                }}
                                dispatch={dispatch}
                                onRemoveClick={onFieldRemoveClick}
                            />
                        ))
                        .reduce((prev, curr) => (
                            <>
                                {prev}
                                <span key={`${prev}${curr}`} className="my-2" />
                                {curr}
                            </>
                        ))}
            </div>
        </div>
    )
}

export default ImportFieldConfigs
