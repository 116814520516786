import { useFeatureToggle } from "invevo-react-components"
import LegacyApp from "../../../microfrontends/legacy/LegacyApp"
import UserTaskContainer from "../../../microfrontends/legacy/components/userTask/userTaskContainer"
import UserTasksDashboard from "../../../microfrontends/task/components/taskList/userTasks/UserTasksDashboard"
import classes from "./MyTasks.module.scss"
import { useLayout } from "../../../contexts/LayoutContext"


const MyTasks = ({ className, store }) => {
    const { isEnabled } = useFeatureToggle("taskModule")

    const { isLeftPanelExpanded } = useLayout()

    return (
        <div className={`${className ? className : ''} d-flex h-100 ${isLeftPanelExpanded ? 'w-100' : classes.sidebar} ${classes.tasks} bg-grey`}>
            {isEnabled ?
                <UserTasksDashboard /> :
                <LegacyApp className="d-flex w-100 h-100" store={store}>
                    <div className="w-100 h-100 px-3">
                        <UserTaskContainer />
                    </div>
                </LegacyApp>
            }
        </div>
    )
}

export default MyTasks