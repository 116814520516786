import { transactionAggregationTypes } from "../enums/transactionAggregationTypes"
import { pieChartTypes } from "../enums/pieChartTypes"
import { widgetThemes } from "../enums/widgetThemes"
import { widgetTypes } from "../enums/widgetTypes"
import { actionTypes } from "./actionTypes"
import { dashboardSubTypes, dashboardTypes } from "../types/DashboardTypes"
import DashboardConfig, {
    AreaChartWidgetState,
    EntityChartConfigWidgetState,
    EntityChartWidgetAggregationState,
    EntityChartWidgetMultiFieldState,
    GridWidgetColumnState,
    GridWidgetState,
    InfoWidgetState,
    LineChartWidgetState,
    SmallBarChartWidgetState,
    SmallPieChartWidgetState,
    TransactionWidgetColumnState,
    TransactionWidgetState,
    TrendingChartType,
    TrendingPeriod,
    TrendingWidgetState,
    ValueWidgetState
} from "../types/DashboardConfigState"
import DashboardConfigState from "../types/DashboardConfigState"
import EntityConfig from "../../../routes/entityConfig/types/EntityConfig"
import Role from "../../../routes/userRoles/roles/types/Role"
import Lookup from "../../../types/Lookup"
import DataPrimitive from "../../../types/DataPrimitive"
import FieldType from "../../../types/enums/FieldType"
import EntityDataField from "../../../routes/entityConfig/types/EntityDataField"
import GenericFilter from "../../../library/FilterList/filterTypes/GenericFilter"
import DataField from "../../../types/DataField"
import { DataPrimitiveTypeEnum, DataTypeEnum } from "../../../routes/entityConfig/types/DataType"
import { GridType } from "../types/DashboardConfigDto"

export type DashboardConfigsState = {
    editingConfig: DashboardConfigState
    originalConfig: DashboardConfigState | undefined
    customerFields: DashboardConfigCustomerEntityConfig[]
    transactionFields: DashboardConfigTransactionFields[]
    dashboardConfigs: DashboardConfigState[]
    entityConfigs: DashboardConfigEntityConfig[]
    showValidationErrors: boolean
    selectedDashboardType: dashboardTypes
    roles: Role[]
    lookups: Lookup[]
}
export type DashboardConfigCustomerEntityConfig = {
    type: DataPrimitive
    value: string
    label: string
    fieldType: FieldType
    dataPrimitive: DataPrimitive
    lookup?: string | undefined
    isQuickFilter: boolean
    fieldName: string
    displayName: string
    description: string
    dataType: {
        type: DataTypeEnum
        lookupReference?: string
    }
    dataPrimitiveType: DataPrimitiveTypeEnum
    id: string
}

export type DashboardConfigEntityConfig = {
    id: string
    fields: DashboardConfigEntityConfigFields[]
} & EntityConfig

export type DashboardConfigEntityConfigFields = {
    value: string
    type: DataPrimitive
    lookup?: string
    label: string
    fieldType: FieldType
} & EntityDataField

export type DashboardConfigTransactionFields = {
    dataType: FieldType
    type: DataPrimitive
    value: string
    label: string
    fieldName: string
    displayName: string
    fieldType: FieldType
    dataPrimitive: DataPrimitive
    isQuickFilter: boolean
    lookup?: string | undefined
    id: string
}

export type DndWidget = {
    id: string
    x: number
    y: number
    w: number
    h: number
}

export type WidgetState =
    | InfoWidgetState
    | ValueWidgetState
    | SmallBarChartWidgetState
    | AreaChartWidgetState
    | LineChartWidgetState
    | SmallPieChartWidgetState
    | TransactionWidgetState
    | GridWidgetState
    | EntityChartConfigWidgetState
    | TrendingWidgetState

export type DashboardConfigAction =
    | DashboardNameChangedAction
    | DashboardSubTypeChangedAction
    | DashboardEntityConfigChangedAction
    | DashboardFiltersChangedAction
    | DashboardInfoWidgetAddedAction
    | DashboardConfigsRetrievedAction
    | DashboardTypeChangedAction
    | LookupsRetrievedAction
    | DashboardValueWidgetAddedAction
    | RolesRetrievedAction
    | EntityConfigsRetrievedAction
    | AutoCompleteCustomerFieldsRetrievedAction
    | AutoCompleteTransactionFieldsRetrievedAction
    | DashboardEditedAction
    | DashboardConfigSavedAction
    | DashboardConfigAddedAction
    | DashboardConfigDeletedAction
    | WidgetLayoutChangedAction
    | GridWidgetEntityConfigChangedAction
    | DashboardChangesAreInvalidAction
    | DashboardChangesAreValidAction
    | NewAggregatedDashboardConfigInitialisedAction
    | NewDashboardConfigInitialisedAction
    | DashboardRoleAssignementRemovedAction
    | DashboardRoleAssignementAddedAction
    | WidgetRemovedAction
    | TransactionsGridWidgetColumnRemovedAction
    | TransactionsGridWidgetColumnChangedAction
    | TransactionsGridWidgeNewColumnAddedAction
    | SmallBarChartWidgetAddedAction
    | AreaChartWidgetAddedAction
    | LineChartWidgetAddedAction
    | SmallPieChartWidgetAddedAction
    | TransactionsGridWidgetAddedAction
    | GridWidgetAdded
    | AreaChartWidgetNameChangedAction
    | AreaChartWidgetThemeChangedAction
    | AreaChartWidgetBucketChartChangedAction
    | GridWidgetColumnRemovedAction
    | GridWidgetColumnChangedAction
    | GridWidgetColumnMovedUpAction
    | GridWidgetColumnMovedDownAction
    | GridWidgetNewColumnAddedAction
    | GridWidgetFiltersChangedAction
    | GridWidgetTypeChangedAction
    | GridWidgetGroupingFieldChangedAction
    | GridWidgetDraggableToggledAction
    | InfoWidgetNameChangedAction
    | InfoWidgetFieldNameChangedAction
    | InfoWidgetFieldNameRemovedAction
    | InfoWidgetNewFieldAddedAction
    | InfoWidgetIsFieldLabelInlineDisabledAction
    | InfoWidgetIsFieldLabelInlineEnabledAction
    | InfoWidgetFieldEditableDisabledAction
    | InfoWidgetFieldEditableEnabledAction
    | InfoWidgetThemeChangedAction
    | InfoWidgetFieldMovedUpAction
    | InfoWidgetFieldMovedDownAction
    | ValueWidgetFieldNameChangedAction
    | ValueWidgetFieldNameSetAction
    | ValueWidgetThemeChangedAction
    | LineChartWidgetNameChangedAction
    | LineChartWidgetThemeChangedAction
    | LineChartWidgetBucketChartTypeChangedAction
    | SmallBarChartWidgetNameChangedAction
    | SmallBarChartWidgetThemeChangedAction
    | SmallBarChartWidgetBucketChartTypeChangedAction
    | SmallPieChartWidgetNameChangedAction
    | SmallPieChartWidgetThemeChangedAction
    | SmallPieChartWidgetTypeChangedAction
    | SmallPieChartWidgetBucketChangedAction
    | EntityGridChartWidgetAddedAction
    | EntityGridChardWidgetNameChangedAction
    | EntityGridChardWidgetThemeChangedAction
    | EntityGridChardWidgetChartTypeChangedAction
    | EntityGridChardWidgetGroupingTypeChangedAction
    | EntityGridChardWidgetAggregationTypeChangedAction
    | EntityGridChardWidgetGroupingFieldChangedAction
    | EntityGridChardWidgetAggregationFieldChangedAction
    | EntityGridChardWidgetNewMultiFieldChangedAction
    | EntityGridChardWidgetNewMultiFieldAddedAction
    | EntityGridChardWidgetNewMultiFieldRemovedAction
    | EntityGridChardWidgetFiltersChangedAction
    | EntityGridChartWidgetDefaultSortFieldChangedAction
    | EntityGridChartWidgetDefaultSortDirectionChangedAction
    | EntityChartWidgetEntityConfigChangedAction
    | TrendingWidgetAddedAction
    | TrendingWidgetChartTypeChangedAction
    | TrendingWidgetGroupingFieldChangedAction
    | TrendingWidgetComparisonFieldChangedAction
    | TrendingWidgetFiltersChangedAction
    | TrendingWidgetPeriodChangedAction
    | TrendingWidgetShowValuesChangedAction
    | TrendingWidgetEntityConfigChangedAction

type DashboardNameChangedAction = {
    type: actionTypes.DASHBOARD_NAME_CHANGED
    newName: string
}
type DashboardSubTypeChangedAction = {
    type: actionTypes.DASHBOARD_SUBTYPE_CHANGED
    newSubType: dashboardSubTypes
}
type DashboardEntityConfigChangedAction = {
    type: actionTypes.DASHBOARD_ENTITY_CONFIG_CHANGED
    entityConfigReference: string
}
type DashboardInfoWidgetAddedAction = {
    type: actionTypes.INFO_WIDGET_ADDED
}
type DashboardValueWidgetAddedAction = {
    type: actionTypes.VALUE_WIDGET_ADDED
}
type DashboardConfigsRetrievedAction = {
    type: actionTypes.DASHBOARD_CONFIGS_RETRIEVED
    dashboardConfigs: DashboardConfig[]
}
type DashboardTypeChangedAction = {
    type: actionTypes.DASHBOARD_TYPE_CHANGED
    dashboardType: dashboardTypes
}
type LookupsRetrievedAction = {
    type: actionTypes.LOOKUPS_RETRIEVED
    lookups: Lookup[]
}
type RolesRetrievedAction = {
    type: actionTypes.ROLES_RETRIEVED
    roles: Role[]
}
type EntityConfigsRetrievedAction = {
    type: actionTypes.ENTITY_CONFIGS_RETRIEVED
    entityConfigs: DashboardConfigEntityConfig[]
}
type AutoCompleteCustomerFieldsRetrievedAction = {
    type: actionTypes.AUTOCOMPLETE_CUSTOMER_FIELDS_RETRIEVED
    fields: DashboardConfigCustomerEntityConfig[]
}
type AutoCompleteTransactionFieldsRetrievedAction = {
    type: actionTypes.AUTOCOMPLETE_TRANSACTION_FIELDS_RETRIEVED
    fields: DashboardConfigTransactionFields[]
}
type DashboardEditedAction = {
    type: actionTypes.DASHBOARD_EDITED
    config: DashboardConfigState
}
type DashboardConfigSavedAction = {
    type: actionTypes.DASHBOARD_CONFIG_SAVED
    dashboardConfig: DashboardConfigState
}
type DashboardConfigAddedAction = {
    type: actionTypes.DASHBOARD_CONFIG_ADDED
    dashboardConfig: DashboardConfigState
}
type DashboardConfigDeletedAction = {
    type: actionTypes.DASHBOARD_CONFIG_DELETED
    dashboardConfigReference: string
}
type NewAggregatedDashboardConfigInitialisedAction = {
    type: actionTypes.NEW_AGGREGATED_DASHBOARD_CONFIG_INITIALISED
    reference: string
}
type NewDashboardConfigInitialisedAction = {
    type: actionTypes.NEW_DASHBOARD_CONFIG_INITIALISED
    reference: string
}
type WidgetLayoutChangedAction = {
    type: actionTypes.WIDGET_LAYOUT_CHANGED
    newLayout: DndWidget[]
}
type GridWidgetEntityConfigChangedAction = {
    type: actionTypes.GRID_WIDGET_ENTITY_CONFIG_CHANGED
    widget: WidgetState
    entityConfigReference: string
}
type DashboardFiltersChangedAction = {
    type: actionTypes.DASHBOARD_FILTERS_CHANGED
    filters: GenericFilter[]
}
type DashboardChangesAreInvalidAction = {
    type: actionTypes.DASHBOARD_CHANGES_ARE_INVALID
}
type DashboardChangesAreValidAction = {
    type: actionTypes.DASHBOARD_CHANGES_ARE_VALID
}
type DashboardRoleAssignementRemovedAction = {
    type: actionTypes.DASHBOARD_ROLE_ASSIGNMENT_REMOVED
    roleReference: string
}
type DashboardRoleAssignementAddedAction = {
    type: actionTypes.DASHBOARD_ROLE_ASSIGNMENT_ADDED
    roleReference: string
}
type WidgetRemovedAction = {
    type: actionTypes.WIDGET_REMOVED
    widget: WidgetState
}
type TransactionsGridWidgetColumnRemovedAction = {
    type: actionTypes.TRANSACTIONS_GRID_WIDGET_COLUMN_REMOVED
    widget: TransactionWidgetState
    column: TransactionWidgetColumnState
}
type TransactionsGridWidgetColumnChangedAction = {
    type: actionTypes.TRANSACTIONS_GRID_WIDGET_COLUMN_CHANGED
    widget: TransactionWidgetState
    newColumn: TransactionWidgetColumnState
    previousColumn: TransactionWidgetColumnState
}
type TransactionsGridWidgeNewColumnAddedAction = {
    type: actionTypes.TRANSACTIONS_GRID_WIDGET_NEW_COLUMN_ADDED
    widget: TransactionWidgetState
    newColumn: TransactionWidgetColumnState
}
type SmallBarChartWidgetAddedAction = {
    type: actionTypes.SMALL_BAR_CHART_WIDGET_ADDED
}
type AreaChartWidgetAddedAction = { type: actionTypes.AREA_CHART_WIDGET_ADDED }
type LineChartWidgetAddedAction = { type: actionTypes.LINE_CHART_WIDGET_ADDED }
type SmallPieChartWidgetAddedAction = { type: actionTypes.SMALL_PIE_CHART_WIDGET_ADDED }
type TransactionsGridWidgetAddedAction = { type: actionTypes.TRANSACTIONS_GRID_WIDGET_ADDED; reference: string }
type GridWidgetAdded = { type: actionTypes.GRID_WIDGET_ADDED; reference: string }

type AreaChartWidgetNameChangedAction = {
    type: actionTypes.AREA_CHART_WIDGET_NAME_CHANGED
    widget: AreaChartWidgetState
    newName: string
}
type AreaChartWidgetThemeChangedAction = {
    type: actionTypes.AREA_CHART_WIDGET_THEME_CHANGED
    widget: WidgetState
    newTheme: widgetThemes
}
type AreaChartWidgetBucketChartChangedAction = {
    type: actionTypes.AREA_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED
    widget: WidgetState
    newType: transactionAggregationTypes
}
type GridWidgetColumnRemovedAction = {
    type: actionTypes.GRID_WIDGET_COLUMN_REMOVED
    widget: GridWidgetState
    column: GridWidgetColumnState
}
type GridWidgetColumnChangedAction = {
    type: actionTypes.GRID_WIDGET_COLUMN_CHANGED
    widget: GridWidgetState
    newColumn: any
    previousColumn: any
}

type GridWidgetColumnMovedUpAction = {
    type: actionTypes.GRID_WIDGET_COLUMN_MOVED_UP
    widget: GridWidgetState
    columnIndexToMove: number
}
type GridWidgetColumnMovedDownAction = {
    type: actionTypes.GRID_WIDGET_COLUMN_MOVED_DOWN
    widget: GridWidgetState
    columnIndexToMove: number
}
type GridWidgetNewColumnAddedAction = {
    type: actionTypes.GRID_WIDGET_NEW_COLUMN_ADDED
    widget: GridWidgetState
    newColumn: any
}
type GridWidgetFiltersChangedAction = {
    type: actionTypes.GRID_WIDGET_FILTERS_CHANGED
    widget: GridWidgetState
    filters: GenericFilter[]
}
type GridWidgetTypeChangedAction = {
    type: actionTypes.GRID_WIDGET_TYPE_CHANGED
    widget: GridWidgetState
    gridType: GridType
}
type GridWidgetGroupingFieldChangedAction = {
    type: actionTypes.GRID_WIDGET_GROUPING_FIELD_CHANGED
    widget: GridWidgetState
    groupingField: EntityDataField | undefined
}
type GridWidgetDraggableToggledAction = {
    type: actionTypes.GRID_WIDGET_DRAGGABLE_TOGGLED
    widget: GridWidgetState
}

type InfoWidgetNameChangedAction = {
    type: actionTypes.INFO_WIDGET_NAME_CHANGED
    widget: InfoWidgetState
    newName: string
}
type InfoWidgetFieldNameChangedAction = {
    type: actionTypes.INFO_WIDGET_FIELD_NAME_CHANGED
    widget: InfoWidgetState
    newField: any
    previousField: any
}
type InfoWidgetFieldNameRemovedAction = {
    type: actionTypes.INFO_WIDGET_FIELD_NAME_REMOVED
    widget: InfoWidgetState
    field: any
}
type InfoWidgetNewFieldAddedAction = {
    type: actionTypes.INFO_WIDGET_NEW_FIELD_ADDED
    widget: InfoWidgetState
    newField: any
}
type InfoWidgetIsFieldLabelInlineDisabledAction = {
    type: actionTypes.INFO_WIDGET_IS_FIELD_LABEL_INLINE_DISABLED
    widget: InfoWidgetState
}
type InfoWidgetIsFieldLabelInlineEnabledAction = {
    type: actionTypes.INFO_WIDGET_IS_FIELD_LABEL_INLINE_ENABLED
    widget: InfoWidgetState
}
type InfoWidgetFieldEditableDisabledAction = {
    type: actionTypes.INFO_WIDGET_FIELD_EDITABLE_DISABLED
    widget: InfoWidgetState
    fieldId: string
}
type InfoWidgetFieldEditableEnabledAction = {
    type: actionTypes.INFO_WIDGET_FIELD_EDITABLE_ENABLED
    widget: InfoWidgetState
    fieldId: string
}
type InfoWidgetThemeChangedAction = {
    type: actionTypes.INFO_WIDGET_THEME_CHANGED
    widget: InfoWidgetState
    newTheme: widgetThemes
}
type InfoWidgetFieldMovedUpAction = {
    type: actionTypes.INFO_WIDGET_FIELD_MOVED_UP
    widget: InfoWidgetState
    fieldIndexToMove: number
}
type InfoWidgetFieldMovedDownAction = {
    type: actionTypes.INFO_WIDGET_FIELD_MOVED_DOWN
    widget: InfoWidgetState
    fieldIndexToMove: number
}
type ValueWidgetFieldNameChangedAction = {
    type: actionTypes.VALUE_WIDGET_NAME_CHANGED
    widget: ValueWidgetState
    newName: string
}
type ValueWidgetFieldNameSetAction = {
    type: actionTypes.VALUE_WIDGET_FIELD_NAME_SET
    widget: ValueWidgetState
    newField: DataField | EntityDataField | undefined
}
type ValueWidgetThemeChangedAction = {
    type: actionTypes.VALUE_WIDGET_THEME_CHANGED
    widget: ValueWidgetState
    newTheme: widgetThemes
}
type LineChartWidgetNameChangedAction = {
    type: actionTypes.LINE_CHART_WIDGET_NAME_CHANGED
    widget: LineChartWidgetState
    newName: string
}
type LineChartWidgetThemeChangedAction = {
    type: actionTypes.LINE_CHART_WIDGET_THEME_CHANGED
    widget: LineChartWidgetState
    newTheme: widgetThemes
}
type LineChartWidgetBucketChartTypeChangedAction = {
    type: actionTypes.LINE_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED
    widget: LineChartWidgetState
    newType: transactionAggregationTypes
}
type SmallBarChartWidgetNameChangedAction = {
    type: actionTypes.SMALL_BAR_CHART_WIDGET_NAME_CHANGED
    widget: SmallBarChartWidgetState
    newName: string
}
type SmallBarChartWidgetThemeChangedAction = {
    type: actionTypes.SMALL_BAR_CHART_WIDGET_THEME_CHANGED
    widget: SmallBarChartWidgetState
    newTheme: widgetThemes
}
type SmallBarChartWidgetBucketChartTypeChangedAction = {
    type: actionTypes.SMALL_BAR_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED
    widget: SmallBarChartWidgetState
    newType: transactionAggregationTypes
}
type SmallPieChartWidgetNameChangedAction = {
    type: actionTypes.SMALL_PIE_CHART_WIDGET_NAME_CHANGED
    widget: SmallPieChartWidgetState
    newName: string
}
type SmallPieChartWidgetThemeChangedAction = {
    type: actionTypes.SMALL_PIE_CHART_WIDGET_THEME_CHANGED
    widget: SmallPieChartWidgetState
    newTheme: widgetThemes
}
type SmallPieChartWidgetTypeChangedAction = {
    type: actionTypes.SMALL_PIE_CHART_WIDGET_TYPE_CHANGED
    widget: SmallPieChartWidgetState
    newType: pieChartTypes
}
type SmallPieChartWidgetBucketChangedAction = {
    type: actionTypes.SMALL_PIE_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED
    widget: SmallPieChartWidgetState
    newType: transactionAggregationTypes
}
type EntityGridChartWidgetAddedAction = {
    type: actionTypes.ENTITY_GRID_CHART_WIDGET_ADDED
    chartType: "BAR" | "AREA" | "LINE" | "PIE" | "DONUT"
}
type EntityGridChardWidgetNameChangedAction = {
    type: actionTypes.ENTITY_CHART_WIDGET_NAME_CHANGED
    widget: EntityChartConfigWidgetState
    newName: string
}
type EntityGridChardWidgetThemeChangedAction = {
    type: actionTypes.ENTITY_CHART_WIDGET_THEME_CHANGED
    widget: EntityChartConfigWidgetState
    newTheme: "DEFAULT" | "BLUE"
}
type EntityGridChardWidgetChartTypeChangedAction = {
    type: actionTypes.ENTITY_CHART_WIDGET_CHART_TYPE_CHANGED
    widget: EntityChartConfigWidgetState
    newChartType: "BAR" | "AREA" | "LINE" | "PIE" | "DONUT"
}
type EntityGridChardWidgetGroupingTypeChangedAction = {
    type: actionTypes.ENTITY_CHART_WIDGET_GROUPING_TYPE_CHANGED
    widget: EntityChartConfigWidgetState
    newGroupingType: "SINGLE" | "MULTI"
}
type EntityGridChardWidgetAggregationTypeChangedAction = {
    type: actionTypes.ENTITY_CHART_WIDGET_AGGREGATION_TYPE_CHANGED
    widget: EntityChartConfigWidgetState
    newAggregationType: "SUM" | "AVERAGE" | "MIN" | "MAX" | "CARDINALITY" | "COUNT"
}
type EntityGridChardWidgetGroupingFieldChangedAction = {
    type: actionTypes.ENTITY_CHART_WIDGET_GROUPING_FIELD_CHANGED
    widget: EntityChartConfigWidgetState
    newGroupingField: string
}
type EntityGridChardWidgetAggregationFieldChangedAction = {
    type: actionTypes.ENTITY_CHART_WIDGET_AGGREGATION_FIELD_CHANGED
    widget: EntityChartConfigWidgetState
    newAggregationField: string
    newAggregationFieldType: DataPrimitiveTypeEnum
}
type EntityGridChardWidgetNewMultiFieldChangedAction = {
    type: actionTypes.ENTITY_CHART_WIDGET_NEW_MULTI_FIELD_CHANGED
    widget: EntityChartConfigWidgetState
    newField: string
    previousField: EntityChartWidgetMultiFieldState
}
type EntityGridChardWidgetNewMultiFieldAddedAction = {
    type: actionTypes.ENTITY_CHART_WIDGET_NEW_MULTI_FIELD_ADDED
    widget: EntityChartConfigWidgetState
    newField: EntityChartWidgetMultiFieldState
}
type EntityGridChardWidgetNewMultiFieldRemovedAction = {
    type: actionTypes.ENTITY_CHART_WIDGET_NEW_MULTI_FIELD_REMOVED
    widget: EntityChartConfigWidgetState
    id: string
}
type EntityGridChardWidgetFiltersChangedAction = {
    type: actionTypes.ENTITY_CHART_WIDGET_FILTERS_CHANGED
    widget: EntityChartConfigWidgetState
    filters: GenericFilter[]
}
type EntityGridChartWidgetDefaultSortFieldChangedAction = {
    type: actionTypes.ENITTY_CHART_WIDGET_SORT_FIELD_CHANGED
    widget: GridWidgetState
    fieldName: string
    dataPrimitive: DataPrimitive
}
type EntityGridChartWidgetDefaultSortDirectionChangedAction = {
    type: actionTypes.ENITTY_CHART_WIDGET_SORT_DIR_CHANGED
    widget: GridWidgetState
    direction: "ASC" | "DESC"
}
type EntityChartWidgetEntityConfigChangedAction = {
    type: actionTypes.ENTITY_CHART_WIDGET_ENTITY_CONFIG_CHANGED
    widget: EntityChartConfigWidgetState
    entityConfigReference: string
}
type TrendingWidgetAddedAction = {
    type: actionTypes.TRENDING_WIDGET_ADDED
    reference: string
}
type TrendingWidgetChartTypeChangedAction = {
    type: actionTypes.TRENDING_WIDGET_CHART_TYPE_CHANGED
    widget: TrendingWidgetState
    chartType: TrendingChartType
}
type TrendingWidgetGroupingFieldChangedAction = {
    type: actionTypes.TRENDING_WIDGET_GROUPING_FIELD_CHANGED
    widget: TrendingWidgetState
    groupingField: EntityDataField
}
type TrendingWidgetComparisonFieldChangedAction = {
    type: actionTypes.TRENDING_WIDGET_COMPARISON_FIELD_CHANGED
    widget: TrendingWidgetState
    comparisonField: EntityDataField
}
type TrendingWidgetFiltersChangedAction = {
    type: actionTypes.TRENDING_WIDGET_FILTERS_CHANGED
    widget: TrendingWidgetState
    filters: GenericFilter[]
}
type TrendingWidgetPeriodChangedAction = {
    type: actionTypes.TRENDING_WIDGET_PERIOD_CHANGED
    widget: TrendingWidgetState
    period: TrendingPeriod
}
type TrendingWidgetShowValuesChangedAction = {
    type: actionTypes.TRENDING_WIDGET_SHOW_VALUES_CHANGED
    widget: TrendingWidgetState
    value: boolean
}
type TrendingWidgetEntityConfigChangedAction = {
    type: actionTypes.TRENDING_WIDGET_ENTITY_CONFIG_CHANGED
    widget: TrendingWidgetState
    entityConfigReference: string
}

export default function dashboardConfigsReducer(state: DashboardConfigsState, action: DashboardConfigAction): DashboardConfigsState {
    switch (action.type) {
        case actionTypes.DASHBOARD_NAME_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    name: action.newName
                }
            }
        }

        case actionTypes.DASHBOARD_SUBTYPE_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    subType: action.newSubType
                }
            }
        }

        case actionTypes.DASHBOARD_ENTITY_CONFIG_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    entityConfigReference: action.entityConfigReference,
                    entityChartWidgets: state.editingConfig.entityChartWidgets.map(e => ({ ...e, entityConfigReference: action.entityConfigReference })),
                    filters: []
                }
            }
        }

        case actionTypes.DASHBOARD_FILTERS_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    filters: action.filters
                }
            }
        }

        case actionTypes.INFO_WIDGET_ADDED: {
            const currentOrdinal = getMaxOrdinal(state.editingConfig)
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    infoWidgets: [
                        ...state.editingConfig.infoWidgets,
                        {
                            ordinal: currentOrdinal + 1,
                            cellX: 0,
                            cellY: 0,
                            cellWidth: 1,
                            cellHeight: 1,
                            displayName: "",
                            fields: [],
                            isFieldLabelInline: false,
                            widgetType: widgetTypes.INFORMATION,
                            theme: widgetThemes.DEFAULT
                        }
                    ]
                }
            }
        }

        case actionTypes.VALUE_WIDGET_ADDED: {
            const currentOrdinal = getMaxOrdinal(state.editingConfig)
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    valueWidgets: [
                        ...state.editingConfig.valueWidgets,
                        {
                            ordinal: currentOrdinal + 1,
                            gridCellX: 0,
                            gridCellY: 0,
                            gridCellWidth: 1,
                            gridCellHeight: 1,
                            displayName: "",
                            field: {},
                            widgetType: widgetTypes.VALUE,
                            theme: widgetThemes.DEFAULT
                        }
                    ]
                }
            }
        }

        case actionTypes.SMALL_BAR_CHART_WIDGET_ADDED: {
            const currentOrdinal = getMaxOrdinal(state.editingConfig)
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    smallBarChartWidgets: [
                        ...state.editingConfig.smallBarChartWidgets,
                        {
                            ordinal: currentOrdinal + 1,
                            gridCellX: 0,
                            gridCellY: 0,
                            gridCellWidth: 1,
                            gridCellHeight: 1,
                            displayName: "",
                            theme: widgetThemes.DEFAULT,
                            type: transactionAggregationTypes.AGED_DEBT,
                            widgetType: widgetTypes.SMALL_BAR_CHART,
                            bucketChartType: transactionAggregationTypes.AGED_DEBT
                        }
                    ]
                }
            }
        }

        case actionTypes.AREA_CHART_WIDGET_ADDED: {
            const currentOrdinal = getMaxOrdinal(state.editingConfig)
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    areaChartWidgets: [
                        ...state.editingConfig.areaChartWidgets,
                        {
                            ordinal: currentOrdinal + 1,
                            gridCellX: 0,
                            gridCellY: 0,
                            gridCellWidth: 1,
                            gridCellHeight: 1,
                            displayName: "",
                            theme: widgetThemes.DEFAULT,
                            type: transactionAggregationTypes.AGED_DEBT,
                            widgetType: widgetTypes.AREA_CHART,
                            bucketChartType: transactionAggregationTypes.AGED_DEBT
                        }
                    ]
                }
            }
        }

        case actionTypes.LINE_CHART_WIDGET_ADDED: {
            const currentOrdinal = getMaxOrdinal(state.editingConfig)
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    lineChartWidgets: [
                        ...state.editingConfig.lineChartWidgets,
                        {
                            ordinal: currentOrdinal + 1,
                            gridCellX: 0,
                            gridCellY: 0,
                            gridCellWidth: 1,
                            gridCellHeight: 1,
                            displayName: "",
                            theme: widgetThemes.DEFAULT,
                            type: transactionAggregationTypes.AGED_DEBT,
                            widgetType: widgetTypes.LINE_CHART,
                            bucketChartType: transactionAggregationTypes.AGED_DEBT
                        }
                    ]
                }
            }
        }

        case actionTypes.SMALL_PIE_CHART_WIDGET_ADDED: {
            const currentOrdinal = getMaxOrdinal(state.editingConfig)
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    smallPieChartWidgets: [
                        ...state.editingConfig.smallPieChartWidgets,
                        {
                            ordinal: currentOrdinal + 1,
                            gridCellX: 0,
                            gridCellY: 0,
                            gridCellWidth: 1,
                            gridCellHeight: 1,
                            displayName: "",
                            theme: widgetThemes.DEFAULT,
                            type: pieChartTypes.PIE,
                            fields: [],
                            widgetType: widgetTypes.SMALL_PIE_CHART,
                            bucketChartType: transactionAggregationTypes.AGED_DEBT
                        }
                    ]
                }
            }
        }
        case actionTypes.ENTITY_GRID_CHART_WIDGET_ADDED: {
            const currentOrdinal = getMaxOrdinal(state.editingConfig)
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    entityChartWidgets: [
                        ...state.editingConfig.entityChartWidgets,
                        {
                            ordinal: currentOrdinal + 1,
                            gridCellX: 0,
                            gridCellY: 0,
                            gridCellWidth: 1,
                            gridCellHeight: 1,
                            displayName: "",
                            theme: "DEFAULT",
                            chartType: action.chartType,
                            aggregation: {
                                type: "SINGLE",
                                groupingField: "",
                                aggregationField: "",
                                aggregationFieldType: DataPrimitiveTypeEnum.NUMBER,
                                fields: []
                            } as EntityChartWidgetAggregationState,
                            aggregationType: "SUM",
                            entityConfigReference: state.editingConfig.entityConfigReference ?? "",
                            widgetType: widgetTypes.ENTITY_CHART_WIDGET,
                            filters: []
                        }
                    ]
                }
            }
        }
        case actionTypes.ENTITY_CHART_WIDGET_NAME_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    entityChartWidgets: state.editingConfig.entityChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            displayName: action.newName
                        }
                    })
                }
            }
        }
        case actionTypes.ENTITY_CHART_WIDGET_THEME_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    entityChartWidgets: state.editingConfig.entityChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            theme: action.newTheme
                        }
                    })
                }
            }
        }
        case actionTypes.ENTITY_CHART_WIDGET_CHART_TYPE_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    entityChartWidgets: state.editingConfig.entityChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            chartType: action.newChartType
                        }
                    })
                }
            }
        }
        case actionTypes.ENTITY_CHART_WIDGET_GROUPING_TYPE_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    entityChartWidgets: state.editingConfig.entityChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            aggregation: { ...widget.aggregation, type: action.newGroupingType }
                        } as EntityChartConfigWidgetState
                    })
                }
            }
        }
        case actionTypes.ENTITY_CHART_WIDGET_AGGREGATION_TYPE_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    entityChartWidgets: state.editingConfig.entityChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            aggregationType: action.newAggregationType
                        }
                    })
                }
            }
        }
        case actionTypes.ENTITY_CHART_WIDGET_GROUPING_FIELD_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    entityChartWidgets: state.editingConfig.entityChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            aggregation: {
                                ...widget.aggregation,
                                groupingField: action.newGroupingField
                            }
                        }
                    })
                }
            }
        }
        case actionTypes.ENTITY_CHART_WIDGET_AGGREGATION_FIELD_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    entityChartWidgets: state.editingConfig.entityChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            aggregation: {
                                ...widget.aggregation,
                                aggregationField: action.newAggregationField,
                                aggregationFieldType: action.newAggregationFieldType
                            }
                        }
                    })
                }
            }
        }
        case actionTypes.ENTITY_CHART_WIDGET_NEW_MULTI_FIELD_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    entityChartWidgets: state.editingConfig.entityChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal || widget.aggregation.type === "SINGLE") {
                            return widget
                        }

                        return {
                            ...widget,
                            aggregation: {
                                ...widget.aggregation,
                                fields: [
                                    ...widget.aggregation.fields.filter(f => f.id === action.previousField.id),
                                    { ...action.previousField, field: action.newField }
                                ]
                            }
                        }
                    })
                }
            }
        }
        case actionTypes.ENTITY_CHART_WIDGET_NEW_MULTI_FIELD_ADDED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    entityChartWidgets: state.editingConfig.entityChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal || widget.aggregation.type === "SINGLE") {
                            return widget
                        }

                        return {
                            ...widget,
                            aggregation: {
                                ...widget.aggregation,
                                fields: [...widget.aggregation.fields, action.newField]
                            }
                        }
                    })
                }
            }
        }
        case actionTypes.ENTITY_CHART_WIDGET_NEW_MULTI_FIELD_REMOVED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    entityChartWidgets: state.editingConfig.entityChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal || widget.aggregation.type === "SINGLE") {
                            return widget
                        }
                        return {
                            ...widget,
                            aggregation: {
                                ...widget.aggregation,
                                fields: widget.aggregation.fields.filter(w => w.id !== action.id)
                            }
                        }
                    })
                }
            }
        }
        case actionTypes.ENTITY_CHART_WIDGET_FILTERS_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    entityChartWidgets: state.editingConfig.entityChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            filters: action.filters
                        }
                    })
                }
            }
        }
        case actionTypes.ENTITY_CHART_WIDGET_ENTITY_CONFIG_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    entityChartWidgets: state.editingConfig.entityChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            entityConfigReference: action.entityConfigReference,
                            columns: []
                        }
                    })
                }
            }
        }
        case actionTypes.TRANSACTIONS_GRID_WIDGET_ADDED: {
            const currentOrdinal = getMaxOrdinal(state.editingConfig)
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    transactionsWidgets: [
                        ...state.editingConfig.transactionsWidgets,
                        {
                            reference: action.reference,
                            gridCellX: 0,
                            gridCellY: 0,
                            gridCellWidth: 1,
                            gridCellHeight: 1,
                            ordinal: currentOrdinal + 1,
                            displayName: "Transactions",
                            columns: [],
                            widgetType: widgetTypes.TRANSACTIONS_GRID
                        }
                    ]
                }
            }
        }

        case actionTypes.GRID_WIDGET_ADDED: {
            const currentOrdinal = getMaxOrdinal(state.editingConfig)
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    gridWidgets: [
                        ...state.editingConfig.gridWidgets,
                        {
                            reference: action.reference,
                            gridCellX: 0,
                            gridCellY: 0,
                            gridCellWidth: 3,
                            gridCellHeight: 6,
                            ordinal: currentOrdinal + 1,
                            displayName: "Data Grid",
                            columns: [],
                            widgetType: widgetTypes.GRID,
                            filters: [],
                            entityConfigReference: state.editingConfig.entityConfigReference,
                            groupingField: null,
                            draggable: false
                        }
                    ]
                }
            }
        }

        case actionTypes.WIDGET_REMOVED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    infoWidgets: [...state.editingConfig.infoWidgets.filter(widget => widget.ordinal !== action.widget.ordinal)],
                    valueWidgets: [...state.editingConfig.valueWidgets.filter(widget => widget.ordinal !== action.widget.ordinal)],
                    smallBarChartWidgets: [...state.editingConfig.smallBarChartWidgets.filter(widget => widget.ordinal !== action.widget.ordinal)],
                    areaChartWidgets: [...state.editingConfig.areaChartWidgets.filter(widget => widget.ordinal !== action.widget.ordinal)],
                    lineChartWidgets: [...state.editingConfig.lineChartWidgets.filter(widget => widget.ordinal !== action.widget.ordinal)],
                    smallPieChartWidgets: [...state.editingConfig.smallPieChartWidgets.filter(widget => widget.ordinal !== action.widget.ordinal)],
                    entityChartWidgets: [...state.editingConfig.entityChartWidgets.filter(widget => widget.ordinal !== action.widget.ordinal)],
                    transactionsWidgets: [...state.editingConfig.transactionsWidgets.filter(widget => widget.ordinal !== action.widget.ordinal)],
                    gridWidgets: [...state.editingConfig.gridWidgets.filter(widget => widget.ordinal !== action.widget.ordinal)],
                    trendingWidgets: [...state.editingConfig.trendingWidgets.filter(widget => widget.ordinal !== action.widget.ordinal)]
                }
            }
        }

        case actionTypes.WIDGET_LAYOUT_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    infoWidgets: [
                        ...state.editingConfig.infoWidgets.map(widget => {
                            const correspondingLayout = action.newLayout.find(l => l.id === `${widget.ordinal}`)
                            if (!correspondingLayout) {
                                return widget
                            }

                            return {
                                ...widget,
                                cellX: correspondingLayout.x,
                                cellY: correspondingLayout.y,
                                cellWidth: correspondingLayout.w,
                                cellHeight: correspondingLayout.h
                            }
                        })
                    ],
                    valueWidgets: [
                        ...state.editingConfig.valueWidgets.map(widget => {
                            const correspondingLayout = action.newLayout.find(l => l.id === `${widget.ordinal}`)
                            if (!correspondingLayout) {
                                return widget
                            }

                            return {
                                ...widget,
                                gridCellX: correspondingLayout.x,
                                gridCellY: correspondingLayout.y,
                                gridCellWidth: correspondingLayout.w,
                                gridCellHeight: correspondingLayout.h
                            }
                        })
                    ],
                    smallBarChartWidgets: [
                        ...state.editingConfig.smallBarChartWidgets.map(widget => {
                            const correspondingLayout = action.newLayout.find(l => l.id === `${widget.ordinal}`)
                            if (!correspondingLayout) {
                                return widget
                            }

                            return {
                                ...widget,
                                gridCellX: correspondingLayout.x,
                                gridCellY: correspondingLayout.y,
                                gridCellWidth: correspondingLayout.w,
                                gridCellHeight: correspondingLayout.h
                            }
                        })
                    ],
                    areaChartWidgets: [
                        ...state.editingConfig.areaChartWidgets.map(widget => {
                            const correspondingLayout = action.newLayout.find(l => l.id === `${widget.ordinal}`)
                            if (!correspondingLayout) {
                                return widget
                            }

                            return {
                                ...widget,
                                gridCellX: correspondingLayout.x,
                                gridCellY: correspondingLayout.y,
                                gridCellWidth: correspondingLayout.w,
                                gridCellHeight: correspondingLayout.h
                            }
                        })
                    ],
                    lineChartWidgets: [
                        ...state.editingConfig.lineChartWidgets.map(widget => {
                            const correspondingLayout = action.newLayout.find(l => l.id === `${widget.ordinal}`)
                            if (!correspondingLayout) {
                                return widget
                            }

                            return {
                                ...widget,
                                gridCellX: correspondingLayout.x,
                                gridCellY: correspondingLayout.y,
                                gridCellWidth: correspondingLayout.w,
                                gridCellHeight: correspondingLayout.h
                            }
                        })
                    ],
                    smallPieChartWidgets: [
                        ...state.editingConfig.smallPieChartWidgets.map(widget => {
                            const correspondingLayout = action.newLayout.find(l => l.id === `${widget.ordinal}`)
                            if (!correspondingLayout) {
                                return widget
                            }

                            return {
                                ...widget,
                                gridCellX: correspondingLayout.x,
                                gridCellY: correspondingLayout.y,
                                gridCellWidth: correspondingLayout.w,
                                gridCellHeight: correspondingLayout.h
                            }
                        })
                    ],
                    entityChartWidgets: [
                        ...state.editingConfig.entityChartWidgets.map(widget => {
                            const correspondingLayout = action.newLayout.find(l => l.id === `${widget.ordinal}`)
                            if (!correspondingLayout) {
                                return widget
                            }

                            return {
                                ...widget,
                                gridCellX: correspondingLayout.x,
                                gridCellY: correspondingLayout.y,
                                gridCellWidth: correspondingLayout.w,
                                gridCellHeight: correspondingLayout.h
                            }
                        })
                    ],
                    transactionsWidgets: [
                        ...state.editingConfig.transactionsWidgets.map(widget => {
                            const correspondingLayout = action.newLayout.find(l => l.id === `${widget.ordinal}`)
                            if (!correspondingLayout) {
                                return widget
                            }

                            return {
                                ...widget,
                                gridCellX: correspondingLayout.x,
                                gridCellY: correspondingLayout.y,
                                gridCellWidth: correspondingLayout.w,
                                gridCellHeight: correspondingLayout.h
                            }
                        })
                    ],
                    gridWidgets: [
                        ...state.editingConfig.gridWidgets.map(widget => {
                            const correspondingLayout = action.newLayout.find(l => l.id === `${widget.ordinal}`)
                            if (!correspondingLayout) {
                                return widget
                            }

                            return {
                                ...widget,
                                gridCellX: correspondingLayout.x,
                                gridCellY: correspondingLayout.y,
                                gridCellWidth: correspondingLayout.w,
                                gridCellHeight: correspondingLayout.h
                            }
                        })
                    ],
                    trendingWidgets: [
                        ...(state.editingConfig.trendingWidgets?.map(widget => {
                            const correspondingLayout = action.newLayout.find(l => l.id === `${widget.ordinal}`)
                            if (!correspondingLayout) {
                                return widget
                            }

                            return {
                                ...widget,
                                gridCellX: correspondingLayout.x,
                                gridCellY: correspondingLayout.y,
                                gridCellWidth: correspondingLayout.w,
                                gridCellHeight: correspondingLayout.h
                            }
                        }) ?? [])
                    ]
                }
            }
        }

        case actionTypes.INFO_WIDGET_NAME_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    infoWidgets: state.editingConfig.infoWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            displayName: action.newName
                        }
                    })
                }
            }
        }

        case actionTypes.INFO_WIDGET_FIELD_MOVED_UP: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    infoWidgets: state.editingConfig.infoWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        const updatedFields = [...widget.fields]
                        const temp = updatedFields[action.fieldIndexToMove]
                        updatedFields[action.fieldIndexToMove] = updatedFields[action.fieldIndexToMove - 1]!!
                        updatedFields[action.fieldIndexToMove - 1] = temp!!

                        return {
                            ...widget,
                            fields: updatedFields
                        }
                    })
                }
            }
        }

        case actionTypes.INFO_WIDGET_FIELD_MOVED_DOWN: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    infoWidgets: state.editingConfig.infoWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        const updatedFields = [...widget.fields]
                        const temp = updatedFields[action.fieldIndexToMove]
                        updatedFields[action.fieldIndexToMove] = updatedFields[action.fieldIndexToMove + 1]!!
                        updatedFields[action.fieldIndexToMove + 1] = temp!!

                        return {
                            ...widget,
                            fields: updatedFields
                        }
                    })
                }
            }
        }

        case actionTypes.VALUE_WIDGET_NAME_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    valueWidgets: state.editingConfig.valueWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            displayName: action.newName
                        }
                    })
                }
            }
        }

        case actionTypes.SMALL_BAR_CHART_WIDGET_NAME_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    smallBarChartWidgets: state.editingConfig.smallBarChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            displayName: action.newName
                        }
                    })
                }
            }
        }

        case actionTypes.AREA_CHART_WIDGET_NAME_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    areaChartWidgets: state.editingConfig.areaChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            displayName: action.newName
                        }
                    })
                }
            }
        }

        case actionTypes.LINE_CHART_WIDGET_NAME_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    lineChartWidgets: state.editingConfig.lineChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            displayName: action.newName
                        }
                    })
                }
            }
        }

        case actionTypes.INFO_WIDGET_THEME_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    infoWidgets: state.editingConfig.infoWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            theme: action.newTheme
                        }
                    })
                }
            }
        }

        case actionTypes.VALUE_WIDGET_THEME_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    valueWidgets: state.editingConfig.valueWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            theme: action.newTheme
                        }
                    })
                }
            }
        }

        case actionTypes.SMALL_BAR_CHART_WIDGET_THEME_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    smallBarChartWidgets: state.editingConfig.smallBarChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            theme: action.newTheme
                        }
                    })
                }
            }
        }

        case actionTypes.AREA_CHART_WIDGET_THEME_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    areaChartWidgets: state.editingConfig.areaChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            theme: action.newTheme
                        }
                    })
                }
            }
        }

        case actionTypes.LINE_CHART_WIDGET_THEME_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    lineChartWidgets: state.editingConfig.lineChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            theme: action.newTheme
                        }
                    })
                }
            }
        }

        case actionTypes.SMALL_PIE_CHART_WIDGET_THEME_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    smallPieChartWidgets: state.editingConfig.smallPieChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            theme: action.newTheme
                        }
                    })
                }
            }
        }

        case actionTypes.SMALL_BAR_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    smallBarChartWidgets: state.editingConfig.smallBarChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            bucketChartType: action.newType
                        }
                    })
                }
            }
        }

        case actionTypes.AREA_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    areaChartWidgets: state.editingConfig.areaChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            bucketChartType: action.newType
                        }
                    })
                }
            }
        }

        case actionTypes.LINE_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    lineChartWidgets: state.editingConfig.lineChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            bucketChartType: action.newType
                        }
                    })
                }
            }
        }

        case actionTypes.SMALL_PIE_CHART_WIDGET_TYPE_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    smallPieChartWidgets: state.editingConfig.smallPieChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            type: action.newType
                        }
                    })
                }
            }
        }

        case actionTypes.SMALL_PIE_CHART_WIDGET_BUCKET_CHART_TYPE_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    smallPieChartWidgets: state.editingConfig.smallPieChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            bucketChartType: action.newType
                        }
                    })
                }
            }
        }

        case actionTypes.SMALL_PIE_CHART_WIDGET_NAME_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    smallPieChartWidgets: state.editingConfig.smallPieChartWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            displayName: action.newName
                        }
                    })
                }
            }
        }

        case actionTypes.INFO_WIDGET_FIELD_NAME_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    infoWidgets: state.editingConfig.infoWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            fields: widget.fields.map(field => {
                                if (field.id !== action.previousField.id) {
                                    return field
                                }

                                if (!action.newField?.fieldName) return {}

                                return {
                                    id: action.previousField.id,
                                    ...action.newField,
                                    isEditable: false
                                }
                            })
                        } as InfoWidgetState
                    })
                }
            }
        }

        case actionTypes.VALUE_WIDGET_FIELD_NAME_SET: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    valueWidgets: state.editingConfig.valueWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            field: action.newField
                        } as ValueWidgetState
                    })
                }
            }
        }

        case actionTypes.TRANSACTIONS_GRID_WIDGET_COLUMN_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    transactionsWidgets: state.editingConfig.transactionsWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            columns: widget.columns.map(column => {
                                if (column.id !== action.previousColumn.id) {
                                    return column
                                }

                                return {
                                    ...action.newColumn
                                }
                            })
                        }
                    })
                }
            }
        }

        case actionTypes.GRID_WIDGET_COLUMN_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    gridWidgets: state.editingConfig.gridWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            columns: widget.columns.map(column => {
                                if (column.id !== action.previousColumn.id) {
                                    return column
                                }

                                return {
                                    ...action.newColumn,
                                    id: action.previousColumn.id
                                }
                            })
                        }
                    })
                }
            }
        }

        case actionTypes.GRID_WIDGET_COLUMN_MOVED_UP: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    gridWidgets: state.editingConfig.gridWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        const updatedColumns = [...widget.columns]
                        const temp = updatedColumns[action.columnIndexToMove]
                        updatedColumns[action.columnIndexToMove] = updatedColumns[action.columnIndexToMove - 1]!!
                        updatedColumns[action.columnIndexToMove - 1] = temp!!

                        return {
                            ...widget,
                            columns: updatedColumns
                        }
                    })
                }
            }
        }

        case actionTypes.GRID_WIDGET_COLUMN_MOVED_DOWN: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    gridWidgets: state.editingConfig.gridWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        const updatedColumns = [...widget.columns]
                        const temp = updatedColumns[action.columnIndexToMove]
                        updatedColumns[action.columnIndexToMove] = updatedColumns[action.columnIndexToMove + 1]!!
                        updatedColumns[action.columnIndexToMove + 1] = temp!!

                        return {
                            ...widget,
                            columns: updatedColumns
                        }
                    })
                }
            }
        }

        case actionTypes.GRID_WIDGET_ENTITY_CONFIG_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    gridWidgets: state.editingConfig.gridWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            entityConfigReference: action.entityConfigReference,
                            columns: []
                        }
                    })
                }
            }
        }

        case actionTypes.GRID_WIDGET_TYPE_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    gridWidgets: state.editingConfig.gridWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            type: action.gridType
                        }
                    })
                }
            }
        }

        case actionTypes.GRID_WIDGET_GROUPING_FIELD_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    gridWidgets: state.editingConfig.gridWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            groupingField: action.groupingField?.fieldName ?? null
                        }
                    })
                }
            }
        }

        case actionTypes.GRID_WIDGET_DRAGGABLE_TOGGLED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    gridWidgets: state.editingConfig.gridWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            draggable: !widget.draggable
                        }
                    })
                }
            }
        }

        case actionTypes.INFO_WIDGET_FIELD_NAME_REMOVED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    infoWidgets: state.editingConfig.infoWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            fields: [...widget.fields.filter(field => field.id !== action.field?.id)]
                        }
                    })
                }
            }
        }

        case actionTypes.TRANSACTIONS_GRID_WIDGET_COLUMN_REMOVED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    transactionsWidgets: state.editingConfig.transactionsWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            columns: [...widget.columns.filter(column => column.id !== action.column.id)]
                        }
                    })
                }
            }
        }

        case actionTypes.GRID_WIDGET_COLUMN_REMOVED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    gridWidgets: state.editingConfig.gridWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            columns: [...widget.columns.filter(column => column.id !== action.column.id)]
                        }
                    })
                }
            }
        }

        case actionTypes.INFO_WIDGET_NEW_FIELD_ADDED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    infoWidgets: state.editingConfig.infoWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            fields: [...widget.fields, action.newField]
                        }
                    })
                }
            }
        }

        case actionTypes.TRANSACTIONS_GRID_WIDGET_NEW_COLUMN_ADDED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    transactionsWidgets: state.editingConfig.transactionsWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            columns: [...widget.columns, action.newColumn]
                        }
                    })
                }
            }
        }

        case actionTypes.GRID_WIDGET_NEW_COLUMN_ADDED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    gridWidgets: state.editingConfig.gridWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            columns: [...widget.columns, action.newColumn]
                        }
                    })
                }
            }
        }

        case actionTypes.INFO_WIDGET_IS_FIELD_LABEL_INLINE_DISABLED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    infoWidgets: state.editingConfig.infoWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }
                        return {
                            ...widget,
                            isFieldLabelInline: false
                        }
                    })
                }
            }
        }

        case actionTypes.INFO_WIDGET_IS_FIELD_LABEL_INLINE_ENABLED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    infoWidgets: state.editingConfig.infoWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }
                        return {
                            ...widget,
                            isFieldLabelInline: true
                        }
                    })
                }
            }
        }

        case actionTypes.INFO_WIDGET_FIELD_EDITABLE_DISABLED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    infoWidgets: state.editingConfig.infoWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            fields: [
                                ...widget.fields.map(field => {
                                    if (field.id !== action.fieldId) {
                                        return field
                                    }
                                    return {
                                        ...field,
                                        isEditable: false
                                    }
                                })
                            ]
                        }
                    })
                }
            }
        }

        case actionTypes.INFO_WIDGET_FIELD_EDITABLE_ENABLED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    infoWidgets: state.editingConfig.infoWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            fields: [
                                ...widget.fields.map(field => {
                                    if (field.id !== action.fieldId) {
                                        return field
                                    }
                                    return {
                                        ...field,
                                        isEditable: true
                                    }
                                })
                            ]
                        }
                    })
                }
            }
        }

        case actionTypes.AUTOCOMPLETE_CUSTOMER_FIELDS_RETRIEVED: {
            return {
                ...state,
                customerFields: action.fields
            }
        }

        case actionTypes.AUTOCOMPLETE_TRANSACTION_FIELDS_RETRIEVED: {
            return {
                ...state,
                transactionFields: action.fields
            }
        }

        case actionTypes.DASHBOARD_CONFIGS_RETRIEVED: {
            return {
                ...state,
                dashboardConfigs: [
                    ...action.dashboardConfigs.map(config => ({
                        ...config,
                        id: config.reference
                    }))
                ]
            }
        }

        case actionTypes.DASHBOARD_EDITED: {
            return {
                ...state,
                editingConfig: {
                    ...action.config
                }
            }
        }

        case actionTypes.DASHBOARD_CONFIG_SAVED: {
            return {
                ...state,
                dashboardConfigs: [
                    ...state.dashboardConfigs.map(config => {
                        if (config.reference !== action.dashboardConfig.reference) {
                            return config
                        }
                        return { ...action.dashboardConfig }
                    })
                ]
            }
        }

        case actionTypes.DASHBOARD_CHANGES_ARE_INVALID: {
            return {
                ...state,
                showValidationErrors: true
            }
        }

        case actionTypes.DASHBOARD_CHANGES_ARE_VALID: {
            return {
                ...state,
                showValidationErrors: false
            }
        }

        case actionTypes.DASHBOARD_CONFIG_ADDED: {
            return {
                ...state,
                dashboardConfigs: [...state.dashboardConfigs, action.dashboardConfig]
            }
        }

        case actionTypes.DASHBOARD_TYPE_CHANGED: {
            return {
                ...state,
                selectedDashboardType: action.dashboardType
            }
        }

        case actionTypes.NEW_DASHBOARD_CONFIG_INITIALISED: {
            return {
                ...state,
                originalConfig: undefined,
                editingConfig: {
                    reference: action.reference,
                    name: "",
                    type: undefined,
                    infoWidgets: [],
                    valueWidgets: [],
                    transactionsWidgets: [],
                    gridWidgets: [],
                    smallBarChartWidgets: [],
                    areaChartWidgets: [],
                    lineChartWidgets: [],
                    smallPieChartWidgets: [],
                    roleReferences: [],
                    filters: [],
                    entityValueWidgets: [],
                    entityChartWidgets: [],
                    trendingWidgets: []
                },
                showValidationErrors: false
            }
        }
        case actionTypes.NEW_AGGREGATED_DASHBOARD_CONFIG_INITIALISED: {
            return {
                ...state,
                originalConfig: undefined,
                editingConfig: {
                    reference: action.reference,
                    name: "",
                    type: dashboardTypes.AGGREGATED,
                    infoWidgets: [],
                    valueWidgets: [],
                    transactionsWidgets: [],
                    gridWidgets: [],
                    smallBarChartWidgets: [],
                    areaChartWidgets: [],
                    lineChartWidgets: [],
                    smallPieChartWidgets: [],
                    roleReferences: [],
                    entityValueWidgets: [],
                    entityChartWidgets: [],
                    trendingWidgets: []
                },
                showValidationErrors: false
            }
        }

        case actionTypes.DASHBOARD_CONFIG_DELETED: {
            return {
                ...state,
                dashboardConfigs: [...state.dashboardConfigs.filter(config => config.reference !== action.dashboardConfigReference)]
            }
        }

        case actionTypes.ROLES_RETRIEVED: {
            return {
                ...state,
                roles: action.roles
            }
        }

        case actionTypes.DASHBOARD_ROLE_ASSIGNMENT_ADDED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    roleReferences: [...state.editingConfig.roleReferences, action.roleReference]
                }
            }
        }

        case actionTypes.DASHBOARD_ROLE_ASSIGNMENT_REMOVED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    roleReferences: [...state.editingConfig.roleReferences.filter(reference => reference !== action.roleReference)]
                }
            }
        }

        case actionTypes.ENTITY_CONFIGS_RETRIEVED: {
            return {
                ...state,
                entityConfigs: [...action.entityConfigs]
            }
        }

        case actionTypes.LOOKUPS_RETRIEVED: {
            return {
                ...state,
                lookups: action.lookups
            }
        }

        case actionTypes.GRID_WIDGET_FILTERS_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    gridWidgets: state.editingConfig.gridWidgets.map(widget => {
                        if (widget.ordinal !== action.widget.ordinal) {
                            return widget
                        }

                        return {
                            ...widget,
                            filters: action.filters
                        }
                    })
                }
            }
        }

        case actionTypes.ENITTY_CHART_WIDGET_SORT_FIELD_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    gridWidgets: state.editingConfig.gridWidgets.map(w => {
                        if (w.ordinal !== action.widget.ordinal) return w
                        return {
                            ...w,
                            defaultSort: {
                                fieldName: action.fieldName,
                                dataPrimitive: action.dataPrimitive,
                                direction: w.defaultSort?.direction ?? "ASC"
                            }
                        }
                    })
                }
            }
        }

        case actionTypes.ENITTY_CHART_WIDGET_SORT_DIR_CHANGED: {
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    gridWidgets: state.editingConfig.gridWidgets.map(w => {
                        if (w.ordinal !== action.widget.ordinal) return w
                        return {
                            ...w,
                            defaultSort: {
                                fieldName: w.defaultSort?.fieldName ?? "reference",
                                dataPrimitive: w.defaultSort?.dataPrimitive ?? DataPrimitive.TEXT,
                                direction: action.direction
                            }
                        }
                    })
                }
            }
        }

        case actionTypes.TRENDING_WIDGET_ADDED: {
            const currentOrdinal = getMaxOrdinal(state.editingConfig)
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    trendingWidgets: [
                        ...state.editingConfig.trendingWidgets,
                        {
                            reference: action.reference,
                            entityConfigReference: state.editingConfig.entityConfigReference,
                            gridCellX: 0,
                            gridCellY: 0,
                            gridCellWidth: 1,
                            gridCellHeight: 1,
                            ordinal: currentOrdinal + 1,
                            widgetType: widgetTypes.ENTITY_TRENDING,
                            filters: [],
                            groupingField: undefined,
                            comparisonField: undefined,
                            period: "SEVEN_DAYS",
                            chartType: "LINE",
                            showValues: false
                        }
                    ]
                }
            }
        }

        case actionTypes.TRENDING_WIDGET_CHART_TYPE_CHANGED:
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    trendingWidgets: state.editingConfig.trendingWidgets.map(w => {
                        if (w.ordinal !== action.widget.ordinal) return w
                        return {
                            ...w,
                            chartType: action.chartType
                        }
                    })
                }
            }
        case actionTypes.TRENDING_WIDGET_GROUPING_FIELD_CHANGED:
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    trendingWidgets: state.editingConfig.trendingWidgets.map(w => {
                        if (w.ordinal !== action.widget.ordinal) return w
                        return {
                            ...w,
                            groupingField: action.groupingField
                        }
                    })
                }
            }
        case actionTypes.TRENDING_WIDGET_COMPARISON_FIELD_CHANGED:
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    trendingWidgets: state.editingConfig.trendingWidgets.map(w => {
                        if (w.ordinal !== action.widget.ordinal) return w
                        return {
                            ...w,
                            comparisonField: action.comparisonField,
                            showValues: action.comparisonField.dataPrimitiveType === DataPrimitiveTypeEnum.NUMBER ? w.showValues : false
                        }
                    })
                }
            }
        case actionTypes.TRENDING_WIDGET_FILTERS_CHANGED:
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    trendingWidgets: state.editingConfig.trendingWidgets.map(w => {
                        if (w.ordinal !== action.widget.ordinal) return w
                        return {
                            ...w,
                            filters: action.filters
                        }
                    })
                }
            }
        case actionTypes.TRENDING_WIDGET_PERIOD_CHANGED:
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    trendingWidgets: state.editingConfig.trendingWidgets.map(w => {
                        if (w.ordinal !== action.widget.ordinal) return w
                        return {
                            ...w,
                            period: action.period
                        }
                    })
                }
            }
        case actionTypes.TRENDING_WIDGET_SHOW_VALUES_CHANGED:
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    trendingWidgets: state.editingConfig.trendingWidgets.map(w => {
                        if (w.ordinal !== action.widget.ordinal) return w
                        return {
                            ...w,
                            showValues: action.value
                        }
                    })
                }
            }
        case actionTypes.TRENDING_WIDGET_ENTITY_CONFIG_CHANGED:
            return {
                ...state,
                editingConfig: {
                    ...state.editingConfig,
                    trendingWidgets: state.editingConfig.trendingWidgets.map(w => {
                        if (w.ordinal !== action.widget.ordinal) return w
                        return {
                            ...w,
                            entityConfigReference: action.entityConfigReference
                        }
                    })
                }
            }
    }
}

const getMaxOrdinal = (editingConfig: DashboardConfigState): number => {
    const widgets = [
        ...editingConfig.infoWidgets,
        ...editingConfig.valueWidgets,
        ...editingConfig.smallBarChartWidgets,
        ...editingConfig.areaChartWidgets,
        ...editingConfig.lineChartWidgets,
        ...editingConfig.smallPieChartWidgets,
        ...editingConfig.entityChartWidgets,
        ...editingConfig.transactionsWidgets,
        ...editingConfig.gridWidgets,
        ...editingConfig.trendingWidgets
    ]

    if (widgets.length === 0) {
        return 0
    }

    return widgets.map(w => w.ordinal).sort((a, b) => (a > b ? 1 : -1))[widgets.length - 1] ?? 0
}
