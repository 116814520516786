import React, { Component } from 'react';
import { connect } from 'react-redux';

import Dropdown from '../../common/dropdown';
import { getLookup } from '../../../api/lookupApi';
import * as lodash from 'lodash';

class AddFileTypeTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileTypeTable: {
        id: '',
        importTableId: '',
        insertOnly: '',
        updateOnly: '',
        resolutionOrder: '',
        treatEmptyStringAsNotSupplied: '',
        isMasterTable: '',
        masterTableKeyFieldName: ''
      }
    };

    this.onChange = this.onChange.bind(this);
    this.onChecked = this.onChecked.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onInsertUpdateChecked = this.onInsertUpdateChecked.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      fileTypeTable: Object.assign({}, nextProps.fileTypeTable)
    });
  }

  componentDidMount() {
    this.props.getLookup(
      this.props.client,
      'import-configuration/destinations/lookup',
      'destinations'
    );
  }

  onChange(event) {
    const field = event.target.name;
    const fileTypeTable = Object.assign({}, this.state.fileTypeTable);

    fileTypeTable[field] = event.target.value;

    return this.setState({ fileTypeTable: fileTypeTable });
  }

  onSelectChange(name, value) {
    const fileTypeTable = Object.assign({}, this.state.fileTypeTable);

    fileTypeTable[name] = value;

    return this.setState({ fileTypeTable: fileTypeTable });
  }

  onChecked(event) {
    const field = event.target.name;
    const fileTypeTable = Object.assign({}, this.state.fileTypeTable);

    fileTypeTable[field] = event.target.checked;

    return this.setState({ fileTypeTable: fileTypeTable });
  }

  onInsertUpdateChecked(event) {
    const field = event.target.name;
    const fileTypeTable = Object.assign({}, this.state.fileTypeTable);

    fileTypeTable[field] = event.target.checked;

    if (event.target.checked) {
      if (field === 'insertOnly') {
        fileTypeTable['updateOnly'] = false;
      } else {
        fileTypeTable['insertOnly'] = false;
      }
    }

    return this.setState({ fileTypeTable: fileTypeTable });
  }

  onSave() {
    this.props.onSave(this.state.fileTypeTable);
  }

  isFormValid() {
    if (
      !this.state.fileTypeTable.importTableId &&
      Number(this.state.fileTypeTable.importTableId) < 1
    ) {
      return false;
    }

    if (
      !this.state.fileTypeTable.resolutionOrder &&
      Number(this.state.fileTypeTable.resolutionOrder) > -1 &&
      Number(this.state.fileTypeTable.resolutionOrder) < 99999
    ) {
      return false;
    }

    return true;
  }

  render() {
    const {
      id,
      importTableId,
      insertOnly,
      updateOnly,
      resolutionOrder,
      treatEmptyStringAsNotSupplied,
      isMasterTable,
      masterTableKeyFieldName
    } = this.state.fileTypeTable;
    const header = id
      ? `Edit File Type Table (Id:${id})`
      : 'Add File Type Table';
    const isValid = this.isFormValid();

    return (
      <div>
        <div key="file-type-table-form" className="card below-grid-form">
          <h3>{header}</h3>

          <div className="form-group">
            <Dropdown
              required
              scroll={true}
              title="Import Table"
              idProp="id"
              selectedItemId={importTableId}
              textProp="name"
              data={this.props.destinations.results}
              name="importTableId"
              onClick={(id) => this.onSelectChange('importTableId', id)}
            />
          </div>
          <div className="form-group" style={{ width: '280px' }}>
            <div className="form-control-feedback-wrapper warning">
              <span className="form-control-feedback" style={{ width: '15px' }}>
                <i className="fa fa-asterisk" />
              </span>
            </div>
            <input
              type="number"
              name="resolutionOrder"
              value={resolutionOrder}
              placeholder="Resolution Order"
              onChange={this.onChange}
              className="form-control"
              title="Resolution Order"
            />
          </div>
          <div className="form-group">
            <div className="checkbox">
              <label>
                <input
                  name="insertOnly"
                  type="checkbox"
                  checked={insertOnly}
                  onChange={this.onInsertUpdateChecked}
                />{' '}
                Insert Only
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="checkbox">
              <label>
                <input
                  name="updateOnly"
                  type="checkbox"
                  checked={updateOnly}
                  onChange={this.onInsertUpdateChecked}
                />{' '}
                Update Only
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="checkbox">
              <label>
                <input
                  name="treatEmptyStringAsNotSupplied"
                  type="checkbox"
                  checked={treatEmptyStringAsNotSupplied}
                  onChange={this.onChecked}
                />{' '}
                Treat Empty String as Not Supplied
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="checkbox">
              <label>
                <input
                  name="isMasterTable"
                  type="checkbox"
                  checked={isMasterTable}
                  onChange={this.onChecked}
                />{' '}
                Is Master Table
              </label>
            </div>
          </div>
          <div className="form-group" style={{ width: '480px' }}>
            <input
              name="masterTableKeyFieldName"
              type="text"
              placeholder="Master Table Key Field Name"
              className="form-control"
              value={masterTableKeyFieldName}
              onChange={this.onChange}
              title="Master Table Key Field Name"
            />
          </div>
          <div className="form-group">
            <button
              className="btnDefault btn btn-default"
              disabled={!isValid}
              onClick={this.onSave}
            >
              Save
            </button>
            <button className="btn btn-default" onClick={this.props.onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let fileTypeTable = {
    id: '',
    importTableId: '',
    insertOnly: '',
    updateOnly: '',
    resolutionOrder: '',
    treatEmptyStringAsNotSupplied: '',
    isMasterTable: '',
    masterTableKeyFieldName: ''
  };

  if (state.fileTypeTables.length > 0) {
    fileTypeTable = lodash.last(state.fileTypeTables);
  }

  return {
    fileTypeTable: fileTypeTable,
    destinations: state.lookupReducer.destinations
  };
}

export default connect(mapStateToProps, { getLookup })(AddFileTypeTable);
