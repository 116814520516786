//Polyfill
import 'whatwg-fetch';

import { genericActionsResetToInitialState, setReportOrigin } from '../actions/genericActions';
import { getApiUrl } from '../constants';
import Nav from './navApi';
import { authReducerStates } from '../reducers/auth_reducer';
import { webApiInterface } from './webApiInterface';

export function loginFirstTimeUser(client, creds) {
  const config = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: `grant_type=password&username=${creds.username}&password=${creds.password}&guid=${creds.uniqueGuid}`
  };

  return (dispatch) => {
    dispatch(authReducerStates.LOGIN_REQUEST.action(creds.username));
    return fetch(getApiUrl(client) + 'token', config)
      .then((response) => response.json().then((user) => ({ user, response })))
      .then(({ user, response }) => {
        if (!response.ok) {
          dispatch(authReducerStates.LOGIN_REQUEST_FAILURE.action(user.error));
          return Promise.reject(user);
        } else {
          webApiInterface.saveSecurity(
            user.access_token,
            creds.username,
            user.refresh_token
          );
          dispatch(
            authReducerStates.LOGIN_REQUEST_SUCCESS.action(user.access_token)
          );
          localStorage.setItem('ero57-language', creds.languageCode);
          Nav.UserProfile()(dispatch);
        }
      })
      .catch((err) => {
        dispatch(
          authReducerStates.LOGIN_REQUEST_FAILURE.action(err.error_description)
        );
      });
  };
}

export function externalLogin(client, grantType, creds, dashboardModule = false) {
  const config = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: `grant_type=${grantType}&token=${creds.tokenId}`
  };

  return (dispatch) => {
    dispatch(authReducerStates.LOGIN_REQUEST.action(creds.email));
    return fetch(getApiUrl(client) + 'token', config)
      .then((response) => response.json().then((user) => ({ user, response })))
      .then(({ user, response }) => {
        if (!response.ok) {
          dispatch(authReducerStates.LOGIN_REQUEST_FAILURE.action(user.error));
          return Promise.reject(user);
        } else {
          webApiInterface.saveSecurity(
            user.access_token,
            user.userName,
            user.refresh_token
          );
          dispatch(
            authReducerStates.LOGIN_REQUEST_SUCCESS.action(user.access_token)
          );
          dispatch(authReducerStates.SET_USERNAME.action(user.userName));
          localStorage.setItem('ero57-language', creds.languageCode);
          // if (creds.successRedirect) {
          //   Nav.GoToLocation(creds.successRedirect)(dispatch);
          // } else {
          //   dashboardModule ? Nav.Platform.Dashboard()(dispatch) : Nav.Workspace.Index()(dispatch);
          // }
        }
      })
      .catch((err) => {
        dispatch(
          authReducerStates.LOGIN_REQUEST_FAILURE.action(err.error_description)
        );
      });
  };
}

export function loginUser(creds, dashboardModule = false) {
  const config = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: `grant_type=password&username=${creds.username}&password=${creds.password}&answer=${creds.answer}&qid=${creds.qid}`
  };

  // ABI Exception!
  // const foo = {};
  // const bar = foo.bar();

  return (client, dispatch) => {
    dispatch(authReducerStates.LOGIN_REQUEST.action(creds.username));
    return fetch(getApiUrl(client) + 'token', config)
      .then((response) => response.json().then((user) => ({ user, response })))
      .then(({ user, response }) => {
        if (!response.ok) {
          dispatch(
            authReducerStates.LOGIN_REQUEST_FAILURE.action(user.message)
          );
          return Promise.reject(user);
        } else {
          dispatch(
            authReducerStates.LOGIN_REQUEST_SUCCESS.action(user.access_token)
          );
          webApiInterface.saveSecurity(
            user.access_token,
            creds.username,
            user.refresh_token
          );
          localStorage.setItem('ero57-language', creds.languageCode);
          if (creds.successRedirect) {
            Nav.GoToLocation(creds.successRedirect)(dispatch);
          } else {
            dashboardModule ? Nav.Platform.Dashboard()(dispatch) : Nav.Workspace.Index()(dispatch);
          }
        }
      });
  };
}

export const goReports = (isFromMainMenu) => {
  return (dispatch) => {
    dispatch(setReportOrigin(isFromMainMenu));

    Nav.Reports()(dispatch);
  };
};

export function refreshUser(client) {
  const config = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: `grant_type=refresh_token&refresh_token=${webApiInterface.refresh()}`
  };

  return fetch(getApiUrl(client) + 'token', config)
    .then((response) => response.json().then((user) => ({ user, response })))
    .then(({ user, response }) => {
      if (!response.ok) {
        return Promise.reject(user);
      } else {
        webApiInterface.saveSecurity(
          user.access_token,
          webApiInterface.username(),
          user.refresh_token
        );
      }
    });
}

export function logoutUser(token, client) {
  return (dispatch) => {
    dispatch(authReducerStates.LOGOUT_REQUEST.action());

    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`
      },
      body: `refreshToken=${webApiInterface.refresh()}`
    };
    fetch(getApiUrl(client) + 'LogOut', config)
      .then(() => {
        localStorage.removeItem('ero57-logincookie');
        dispatch(authReducerStates.LOGOUT_REQUEST_SUCCESS.action());
        dispatch(genericActionsResetToInitialState());
      })
      .then(() => {
        Nav.Login()(dispatch);
      })
      .catch(() => {
        Nav.Login()(dispatch);
      });

    webApiInterface.saveSecurity(null, null, null);
  };
}

export function getSecurityQuestion(client) {
  return (dispatch) => {
    dispatch(authReducerStates.SECURITY_QUESTION_REQUEST.action());
    fetch(getApiUrl(client) + 'api/Account/getRandomSecurityQuestion')
      .then((response) => response.json())
      .then((json) =>
        dispatch(
          authReducerStates.SECURITY_QUESTION_SUCCESS.action(json.ID, json.Text)
        )
      )
      .catch((error) =>
        dispatch(
          authReducerStates.SECURITY_QUESTION_ERROR.action(error.message)
        )
      );
  };
}

export function dispatchLanguageChange(newLang) {
  localStorage.setItem('ero57-language', newLang);
  return (dispatch) => {
    dispatch(authReducerStates.CHANGE_LANGUAGE.action(newLang));
  };
}

export function clearAuth() {
  return (dispatch) => {
    webApiInterface.saveSecurity(null, null, null);
    dispatch(authReducerStates.LOGOUT_REQUEST.action());
  };
}

export function setToken(token, username, refresh) {
  return (dispatch) => {
    webApiInterface.saveSecurity(token, username, refresh);
    dispatch(authReducerStates.LOGIN_REQUEST_SUCCESS.action(token));
  };
}
