import { useState } from "react"
import BlueButton from "../../../library/buttons/BlueButton/BlueButton"
import DataGrid from "../../../library/dataGrid/DataGrid"
import GridRowActionButton from "../../../library/dataGrid/GridRowActionButton"
import DataRow from "../../../library/dataGrid/types/DataRow"
import { DefaultDataFieldsGridConfigLegacy, EntityDataFieldsGridDataFieldsLegacy, HardcodedEntityDataFields } from "../constants"
import EntityConfig from "../types/EntityConfig"
import EntityDataField from "../types/EntityDataField"
import classes from "./EntityDataFieldsGrid.module.scss"
import DataField from "../../../types/DataField"
import SortDirection from "../../../types/enums/SortDirection"
import DataGridState from "../../../library/dataGrid/types/DataGridState"

type EntityDataFieldsGridProps = {
    entityConfig: EntityConfig
    onAddNewEntityDataField: () => void
    onEditEntityDataField: (entityDataField: EntityDataField) => void
    onDeleteEntityDataField: (entityDataField: EntityDataField) => void
}

const EntityDataFieldsGrid = ({ entityConfig, onAddNewEntityDataField, onEditEntityDataField, onDeleteEntityDataField }: EntityDataFieldsGridProps) => {
    const [pageSize, setPageSize] = useState<number>(20)
    const [pageIndex, setPageIndex] = useState<number>(0)
    const [sortByField, setSortByField] = useState<DataField>(EntityDataFieldsGridDataFieldsLegacy[0]!)
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC)

    const entityDataFields = entityConfig?.fields

    const mapEntityDataFieldToDataRow = (field: EntityDataField): DataRow => ({
        id: field.fieldName,
        data: {
            fieldname: field.fieldName,
            displayname: field.displayName,
            description: field.description,
            datatype: field.dataType.type,
            isquickfilter: field.isQuickFilter?.toString() ?? "false",
            lookupreference: field.dataType.lookupReference ?? "",
            timezone: field.dataType.timeZone ?? "",
            currencyfield: field.dataType.currencyFieldReference ?? "",
            currencycode: field.dataType.currencyCode ?? ""
        }
    })

    const entityDataFieldDataRows = entityDataFields
        .filter(field => field.fieldName !== "client" && field.fieldName !== "entity_type")
        .map(field => mapEntityDataFieldToDataRow(field))
        .sort((a: DataRow, b: DataRow) => {
            if (sortDirection === SortDirection.ASC) {
                return a.data[sortByField.value]! > b.data[sortByField.value]! ? 1 : 0
            } else {
                return a.data[sortByField.value]! < b.data[sortByField.value]! ? 1 : 0
            }
        })
        .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)

    const gridState = {
        dataRows: entityDataFieldDataRows,
        sortField: sortByField,
        sortDirection: sortDirection,
        totalRowCount: entityDataFields.length,
        isLoading: false,
        pageIndex: pageIndex,
        pageSize: pageSize
    }

    const onStateChanged = (state: DataGridState) => {
        if (state.pageIndex !== pageIndex) {
            setPageIndex(state.pageIndex)
        }

        if (state.sortField !== sortByField) {
            setPageIndex(0)
            setSortByField(state.sortField as DataField)
        }

        if (state.sortDirection !== sortDirection) {
            setPageIndex(0)
            setSortDirection(state.sortDirection as SortDirection)
        }

        if (state.pageSize !== pageSize) {
            setPageIndex(0)
            setPageSize(state.pageSize)
        }
    }

    const editEntityDataField = (entityDataFieldDataRow: DataRow) => {
        const entityDataField = entityConfig.fields.find(field => field.fieldName === entityDataFieldDataRow.id)

        if (entityDataField === undefined) return

        onEditEntityDataField(entityDataField)
    }

    const deleteEntityDataField = (entityDataFieldDataRow: DataRow) => {
        const entityDataField = entityConfig.fields.find(field => field.fieldName === entityDataFieldDataRow.id)

        if (entityDataField === undefined) return

        onDeleteEntityDataField(entityDataField)
    }

    const isHardcodedField = (entityDataFieldDataRow: DataRow) =>
        HardcodedEntityDataFields.some(hardcodedField => hardcodedField.fieldName === entityDataFieldDataRow.id)

    const renderGridHeader = () => (
        <div className={`w-100 rounded-top d-flex align-items-start p-1 bg-blue ${classes.gridHeader}`}>
            <div className="d-flex align-items-center p-2 w-100">
                <span className="text-uppercase text-white me-2 no-select">Entity data fields</span>
                <div className="d-flex align-items-center py-1 px-2 text-white ms-auto">
                    <BlueButton iconClasses="fa fa-plus" label="Add Data Field" onClick={onAddNewEntityDataField} ariaLabel="show-add-data-field" />
                </div>
            </div>
        </div>
    )

    const renderRowActions = (row: DataRow) => {
        return (
            <div className="d-flex gap-2">
                <GridRowActionButton
                    dataRow={row}
                    onClick={editEntityDataField}
                    ariaLabel={`edit-field-${row.id}`}
                    iconClasses="ms-auto text-blue fa-solid fa-pen-to-square"
                />
                {!isHardcodedField(row) ? (
                    <GridRowActionButton
                        dataRow={row}
                        onClick={deleteEntityDataField}
                        ariaLabel={`delete-field-${row.id}`}
                        iconClasses="ms-auto text-blue far fa-trash-can pointer"
                    />
                ) : (
                    <div className={`${classes.actionButtonPlaceholder}`} />
                )}
            </div>
        )
    }

    return (
        <div className="d-flex flex-column h-100 w-100 overflow-auto">
            <div className="h-100">
                <DataGrid
                    ariaLabel="entity-config-data-fields"
                    gridReference="data-fields-grid"
                    fields={EntityDataFieldsGridDataFieldsLegacy}
                    defaultFields={DefaultDataFieldsGridConfigLegacy}
                    lookups={[]}
                    state={gridState}
                    onStateChanged={onStateChanged}
                    renderHeader={renderGridHeader}
                    renderRowActions={renderRowActions}
                />
            </div>
        </div>
    )
}

export default EntityDataFieldsGrid
