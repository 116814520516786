import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Nav from '../../api/navApi';
import { EmailEditor } from '../admin/email/emailEditor';
import { showToastErrorMessage } from '../../api/toasterApi.js';
import { sendReply } from '../../api/functionApi';
import { executeAuthAsyncGet } from '../../utility/asyncSupport';
import moment from 'moment';
import { setState } from 'expect/build/jestMatchersObject';
import { Loading, useClient, useInvevoSession } from 'invevo-react-components';
import classes from './Email.module.scss'
import { customerDetailsPane } from '../../constants';
import { useActionStrip } from '../../../../contexts/ActionStripContext';

const EmailReply = ({
  selectedAccount,
  sendReply,
  onEmailSentSuccess,
  executeAuthAsyncGet
}) => {
  const client = useClient()
  const editorRef = useRef();
  const emailThreadRef = useRef();
  const [invevoSession, setInvevoSession] = useInvevoSession()
  const { lastNavigationParams } = useActionStrip()
  const [contacts, setContacts] = useState([])
  const [attachmentSummary, setAttachmentSummary] = useState([])

  const [blockUi, setBlockUi] = useState(false);

  const [emailTracking, setEmailTracking] = useState({
    from: [],
    to: [],
    cc: [],
    subject: '',
    body: ''
  });

  useEffect(() => {
    let emailContacts = emailTracking.from.slice();
    if (lastNavigationParams.emailToReply && lastNavigationParams.emailToReply.type === 'reply-all') {
      emailContacts = emailContacts.concat(emailTracking.to);
      emailContacts = emailContacts.concat(emailTracking.cc);
    }

    setContacts(emailContacts.concat(
      (invevoSession.selectedCustomerContacts || [])
        .concat(invevoSession.selectedInternalContacts || [])
        .map((c) => c.email.toLowerCase())
        .filter(
          (c) =>
            emailContacts.filter((e) => e.toLowerCase().includes(c)).length === 0
        )
    ))
  }, [emailTracking.cc, emailTracking.from, emailTracking.to, lastNavigationParams.emailToReply, invevoSession.selectedCustomerContacts, invevoSession.selectedInternalContacts]);

  useEffect(() => {
    let docsToAttach = []
    if (invevoSession.selectedDocuments && invevoSession.selectedDocuments.length > 0) {
      invevoSession.selectedDocuments.map((d) =>
        docsToAttach.push(
          <div key={d.id}>
            <i className='fa-solid fa-file'></i> {d.fileName}
          </div>
        )
      );
    }

    setAttachmentSummary(docsToAttach)
  }, [invevoSession.selectedDocuments])

  useEffect(() => {
    if (!lastNavigationParams.emailToReply) {
      return
    }
    const controller = new window.AbortController();

    const parameters = {
      noteId: lastNavigationParams.emailToReply.noteId,
      customerId: selectedAccount.miaAccountId,
      emailTrackingId: lastNavigationParams.emailToReply.emailTrackingId,
      markAsRead: true
    };

    // eslint-disable-next-line no-lone-blocks
    const tempRegex = /,(?!\s)/g;

    executeAuthAsyncGet(
      client,
      'Note/GetEmailTracking',
      'GET_EMAIL_TRACKING',
      parameters,
      (result) => {
        setState();
        setEmailTracking({
          from: [result.from],
          to: result.to.split(tempRegex),
          cc: result.cc ? result.cc.split(tempRegex) : [],
          subject: result.subject,
          body: result.body
        });

        const content = result.body;
        emailThreadRef.current.contentWindow.document.write(content);

        setTimeout(() => {
          const body = emailThreadRef.current.contentWindow.document.body;
          if (body !== null) setThreadHeight(body.scrollHeight + 2);
        }, 10);
        //scroll();
      },
      () => {
        showToastErrorMessage(`Error while loading email`);
      },
      controller.signal
    );

    return () => controller.abort();
  }, [lastNavigationParams.emailToReply, executeAuthAsyncGet, selectedAccount.miaAccountId, client]);

  const [threadHeight, setThreadHeight] = useState('');

  const onSendReply = () => {
    const htmlContent = editorRef.current.getHtml();
    setBlockUi(true);
    sendReply(
      client,
      {
        emailtrackingid: lastNavigationParams.emailToReply.emailTrackingId,
        content: htmlContent,
        thread: emailTracking.body,
        customerid: selectedAccount.miaAccountId,
        recipients: contacts,
        attachmentIds: (invevoSession.selectedDocuments || []).map((d) => d.id)
      },
      () => {
        setBlockUi(false);
        onEmailSentSuccess();
      },
      () => {
        setBlockUi(false);
        showToastErrorMessage('Reply NOT sent');
      }
    );
  };

  const navToContacts = () => {
    setInvevoSession({ selectedCustomerTab: customerDetailsPane.contacts })
  }

  const navToDocuments = () => {
    setInvevoSession({ selectedCustomerTab: customerDetailsPane.files, showTaskFiles: false })
  }

  return (
    <Loading isLoading={blockUi} colour="blue">
      <div className="row">
        <div className="col-12">
          <h2>Reply</h2>
        </div>
        <div className="col-12">
          <button
            className={`btn ${classes['btn-custom']}`}
            type="button"
            onClick={navToContacts}
          >
            Add Recipients {contacts.length > 0 && `(${contacts.length})`}
          </button>
          <button
            className={`btn ${classes['btn-custom']}`}
            type="button"
            onClick={navToDocuments}
          >
            Attach Documents {(invevoSession.selectedDocuments || []).length > 0 && `(${invevoSession.selectedDocuments.length})`}
          </button>

          <div style={{ float: "right" }}>
            <button
              className={`btn ${classes['btn-custom']}`}
              type="button"
              disabled={contacts.length === 0}
              onClick={onSendReply}
            >
              Send
            </button>
          </div>
        </div>
        <div className="col-12">
          <hr />
        </div>
        <div className="col-12">
          <h3>Subject</h3>
          <div>RE: {emailTracking.subject}</div>
          <h3>To</h3>
          <div>{contacts.join('; ')}</div>
          {attachmentSummary.length > 0 && (
            <div className="box">
              <h3>Attachements</h3>
              {attachmentSummary}
            </div>
          )}
        </div>
        <div className="col-12 top10">
          <EmailEditor
            id="reply-contents"
            style={{ width: '100%', height: '600px' }}
            ref={editorRef}
          ></EmailEditor>
        </div>
        <div className="col-12 top10">
          <div className="card">
            <div className="form-group">
              <label>Date</label>
              <p>{lastNavigationParams.emailToReply ? moment(new Date(lastNavigationParams.emailToReply.emailDate)).format('LLLL') : ''}</p>
            </div>
            <div className="form-group">
              <label>Subject</label>
              <p>{emailTracking.subject}</p>
            </div>
            <div className="form-group">
              <label>From</label>
              <p>{emailTracking.from}</p>
            </div>
            <div className="form-group">
              <label>To</label>
              <p>{emailTracking.to}</p>
            </div>
          </div>
        </div>
        <div className="col-12 top10">
          <iframe
            title="email"
            ref={emailThreadRef}
            className="email-display-iframe"
            style={{ height: threadHeight + 'px' }}
          ></iframe>
        </div>
      </div>
    </Loading>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedAccount: state.currentSelectionReducer.selectedAccount
  };
};

export default connect(mapStateToProps, {
  navToEmailSuccess: Nav.Workspace.Email.Success,
  sendReply,
  executeAuthAsyncGet
})(EmailReply);
