import classes from './InformationWidget.module.scss'
import Checkbox from '../../../../../library/form/Checkbox'
import EntityDataFieldDropdown from '../../../../../library/dropdowns/EntityDataFieldDropdown'
import EntityDataField from '../../../../../routes/entityConfig/types/EntityDataField'

const REFERENCE_FIELD_NAME = "reference"


type InformationEntityFieldNameProps = {
    index: number
    isFirst: boolean
    isLast: boolean
    field: {
        field: any
        isEditable: boolean
    }
    fields: EntityDataField[]
    ariaLabel?: string
    onFieldNameChanged: (field: EntityDataField, option: EntityDataField | undefined) => void
    onFieldRemoveClicked: (field: EntityDataField) => void
    onIsFieldEditableClicked: (field: any) => void
    onMoveFieldUp: (index: number) => void
    onMoveFieldDown: (index: number) => void
}

const InformationEntityFieldName = ({
    index,
    isFirst,
    isLast,
    field,
    fields,
    ariaLabel = undefined,
    onFieldNameChanged,
    onFieldRemoveClicked,
    onIsFieldEditableClicked,
    onMoveFieldUp,
    onMoveFieldDown
}: InformationEntityFieldNameProps) => {
    const onOptionSelected = (option: EntityDataField | undefined) => onFieldNameChanged(field.field, option)
    const onFieldRemoveClick = () => onFieldRemoveClicked(field.field)
    const onFieldEditableClicked = () => onIsFieldEditableClicked(field.field)

    const onMoveFieldUpClick = () => {
        if(isFirst) return

        onMoveFieldUp(index)
    }

    const onMoveFieldDownClick = () => {
        if(isLast) return

        onMoveFieldDown(index)
    }

    return (
        <div className="d-flex align-items-center">
            <div className="d-flex flex-column align-items-center me-2">
                <div className="d-flex pointer w-100" onClick={onMoveFieldUpClick}>
                    <i className={`fal fa-caret-up m-auto ${isFirst ? 'text-light-grey': ''}`} />
                </div>
                <i className="fal fa-horizontal-rule" />
                <div className="d-flex pointer w-100" onClick={onMoveFieldDownClick}>
                    <i className={`fal fa-caret-down m-auto ${isLast ? 'text-light-grey': ''}`} />
                </div>
            </div>

            <div className="d-flex flex-column flex-grow-1">
                <div className="d-flex align-items-center">
                    <span className={`text-uppercase small mb-1 ${classes.label}`}>field name {index + 1}</span>
                    <div className={`d-flex align-items-center ms-auto`}>

                        <div className='d-flex align-items-center me-2'>
                            <Checkbox
                                isChecked={field.isEditable}
                                onClick={onFieldEditableClicked}
                                disabled={field.field.fieldName === REFERENCE_FIELD_NAME}
                                label="Is Editable?"
                                labelUpperCase={false}
                            />
                        </div>
                        <i className={`fas fa-times-circle pointer ${classes['remove-field-icon']}`} onClick={onFieldRemoveClick}></i>
                    </div>
                </div>
                <EntityDataFieldDropdown
                    className="flex-grow-1 mb-2"
                    options={fields}
                    ariaLabel={ariaLabel}
                    selectedOption={fields.find(cf => cf.fieldName === field.field.fieldName) || undefined}
                    onOptionSelected={onOptionSelected}
                />
            </div>
        </div>
    )
}

export default InformationEntityFieldName