import { useState } from "react"
import EntityConfig from "../../entityConfig/types/EntityConfig"
import { FieldsUpdate, groupUpdatesByReference } from "../../../library/common"
import Textbox from "../../../library/Inputs/Textbox"
import Dropdown from "../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../types/DropdownOptions"
import { useSelectedEntities } from "../../../contexts/SelectedEntitiesContext"
import StandardButton from "../../../library/buttons/StandardButton/StandardButton"
import { useOverlay } from "../../../contexts/overlay/OverlayContext"
import EntityDataField from "../../entityConfig/types/EntityDataField"
import { DataPrimitiveTypeEnum, DataTypeEnum, getDataPrimitiveTypeForEntityDataType } from "../../entityConfig/types/DataType"
import ProgressButton from "../../../library/buttons/ProgressButton/ProgressButton"
import Prettify from "../../../types/utils/Prettify"
import Entity from "../../../library/grid/types/Entity"

type CommentTarget = { entityType: string; refs: string[] }

type UpdateFieldProps = {
    entityConfigs: EntityConfig[]
    currentEntityType?: string
    currentEntityRef?: string
    fieldTypeName?: string
    field: { fieldName: string; dataType: DataTypeEnum }
    initialValue?: string
    onUpdateSubmit?: (updates: FieldsUpdate[]) => Promise<any>
}

const MAX_TEXT_LENGTH = 2048

const UpdateField = ({
    entityConfigs,
    currentEntityType,
    currentEntityRef,
    fieldTypeName = "Field",
    field,
    initialValue = "",
    onUpdateSubmit = async () => {}
}: UpdateFieldProps) => {
    const overlay = useOverlay()
    const { selectedEntity, selectedRefs } = useSelectedEntities()

    const entityConfig =
        currentEntityType !== undefined
            ? entityConfigs.find(c => c.reference === currentEntityType)
            : entityConfigs.find(c => c.reference === selectedEntity?.entityTypeReference)

    const [newText, setNewText] = useState<string>(initialValue)
    const [showMaxTextLengthError, setShowMaxTextLengthError] = useState(false)
    const onTextChange = (value: string) => {
        if (value.length >= MAX_TEXT_LENGTH) {
            setShowMaxTextLengthError(true)
            setNewText(value.substring(0, MAX_TEXT_LENGTH))
            return
        }
        setShowMaxTextLengthError(false)
        setNewText(value)
    }

    //IMPORTANT: when isCommentDialog === true, all updates are pushed to "text_comment" even if it doesn't exist in the entity config
    const isCommentDialog = field.fieldName === "text_comment" && field.dataType === DataTypeEnum.RICH_TEXT

    const isValidField = (f: EntityDataField) => field !== undefined && f.dataType.type === field.dataType && f.fieldName === field.fieldName

    const selectedEntityOptions = Object.keys(selectedRefs)
        .filter(
            entityType =>
                (selectedRefs[entityType] ?? []).length > 0 &&
                ((entityConfigs.find(e => e.reference === entityType)?.fields ?? []).some(isValidField) ||
                    (isCommentDialog && entityType === selectedEntity?.entityTypeReference))
        )
        .map(entityType => ({
            label: entityConfigs.find(e => e.reference === entityType)?.displayName ?? entityType,
            value: { entityType: entityType, refs: selectedRefs[entityType] ?? [] }
        }))
    const entityOptions = getAllEntityOptions(selectedEntity, selectedEntityOptions, entityConfig, currentEntityRef)

    const [entityTarget, setEntityTarget] = useState<DropdownOption<CommentTarget> | undefined>(
        entityOptions.find(o => o.value.entityType === entityConfig?.reference) ?? entityOptions.at(0)
    )
    const validEntityTarget =
        entityTarget !== undefined && entityOptions.find(o => o.value.entityType === entityTarget.value.entityType) ? entityTarget : entityOptions.at(0)

    const updates = createUpdatesFromComment(newText, field.fieldName, field.dataType, validEntityTarget?.value)

    const onSubmit = async () => {
        await onUpdateSubmit(updates)
        overlay.closeOverlay()
    }
    const fieldDataPrimitiveType = getDataPrimitiveTypeForEntityDataType(field.dataType)

    const entityHasField = isCommentDialog || entityConfig?.fields.find(isValidField) !== undefined

    return (
        <div className="d-flex flex-column px-2 gap-4 rounded">
            <span className="d-flex flex-row fs-5 gap-2 align-items-center justify-content-center ">
                {"Update "}
                {entityOptions.length <= 1 ? (
                    validEntityTarget?.label
                ) : (
                    <Dropdown
                        options={entityOptions}
                        onOptionSelected={setEntityTarget}
                        selectedOption={validEntityTarget}
                        disabled={entityOptions.length < 2}
                        ariaLabel="update-entity-dropdown"
                        fixedSize
                    />
                )}
                {" " + fieldTypeName + ":"}
            </span>
            {validEntityTarget === undefined && (
                <span className="d-flex w-100 text-danger align-items-center justify-content-center">No entities have been selected</span>
            )}
            {validEntityTarget !== undefined && !entityHasField && (
                <span className="d-flex w-100 text-danger align-items-center justify-content-center">
                    The selected entity type doesn't contain the desired field ({field.fieldName})
                </span>
            )}
            {fieldDataPrimitiveType === DataPrimitiveTypeEnum.TEXT && <Textbox value={newText} onChange={onTextChange} ariaLabel="update-text-field" />}
            {showMaxTextLengthError && (
                <div aria-label="update-field-text-warning">
                    <span className="d-flex w-100 text-danger align-items-center justify-content-center">
                        Text has reached limit of {MAX_TEXT_LENGTH} characters
                    </span>
                </div>
            )}
            <div className="d-flex flex-row justify-content-between">
                <StandardButton iconClasses="fal fa-cancel" colour="red" onClick={overlay.closeOverlay} label="Cancel" ariaLabel="cancel-update" />
                <ProgressButton
                    iconClasses="fal fa-check"
                    colour="blue"
                    className="ms-auto"
                    onClickWithPromise={onSubmit}
                    label="Submit"
                    ariaLabel="submit-update"
                    disabled={validEntityTarget === undefined || !entityHasField}
                />
            </div>
        </div>
    )
}

export default UpdateField

const createUpdatesFromComment = <T extends boolean | string | number>(
    value: T,
    commentTargetField?: string,
    targetFieldType?: DataTypeEnum,
    commentTarget?: CommentTarget
): FieldsUpdate[] => {
    if (commentTarget === undefined || commentTargetField === undefined || targetFieldType === undefined) return []
    const updates = getUpdates(value, commentTargetField, targetFieldType, commentTarget)
    return groupUpdatesByReference(updates, commentTarget.entityType)
}

const getUpdates = (
    value: boolean | string | number,
    commentTargetField: string,
    targetFieldType: DataTypeEnum,
    commentTarget: CommentTarget
): FieldsUpdate[] => {
    const dataPrimitive = getDataPrimitiveTypeForEntityDataType(targetFieldType)
    switch (typeof value) {
        case "string":
            return commentTarget.refs.map(t => ({
                reference: t,
                entityTypeReference: commentTarget.entityType,
                booleanFields: [],
                stringFields: dataPrimitive === DataPrimitiveTypeEnum.TEXT ? [{ fieldName: commentTargetField, fieldValue: value }] : [],
                numberFields: [],
                dateTimeFields: dataPrimitive === DataPrimitiveTypeEnum.DATE ? [{ fieldName: commentTargetField, fieldValue: value }] : []
            }))
        case "number":
            return commentTarget.refs.map(t => ({
                reference: t,
                entityTypeReference: commentTarget.entityType,
                booleanFields: [],
                stringFields: [],
                numberFields: dataPrimitive === DataPrimitiveTypeEnum.NUMBER ? [{ fieldName: commentTargetField, fieldValue: value }] : [],
                dateTimeFields: []
            }))
        case "boolean":
            return commentTarget.refs.map(t => ({
                reference: t,
                entityTypeReference: commentTarget.entityType,
                booleanFields: dataPrimitive === DataPrimitiveTypeEnum.BOOLEAN ? [{ fieldName: commentTargetField, fieldValue: value }] : [],
                stringFields: [],
                numberFields: [],
                dateTimeFields: []
            }))
    }
}

const getAllEntityOptions = (
    selectedEntity: Prettify<Omit<Entity, "client">> | undefined,
    selectedEntityOptions: { label: string; value: { entityType: string; refs: string[] } }[],
    entityConfig: EntityConfig | undefined,
    entityReference: string | undefined
) => {
    const entityType = selectedEntity?.entityTypeReference ?? entityConfig?.reference
    const entityRef = selectedEntity?.reference ?? entityReference
    if (
        entityRef === undefined ||
        entityType === undefined ||
        selectedEntityOptions.some(opt => opt.value.entityType === entityType && opt.value.refs.includes(entityRef))
    ) {
        return selectedEntityOptions
    }

    if (!selectedEntityOptions.some(opt => opt.value.entityType === entityType)) {
        return [
            ...selectedEntityOptions,
            {
                label: entityConfig?.displayName ?? entityType,
                value: { entityType, refs: [entityRef] }
            }
        ]
    }

    return selectedEntityOptions.map(opt =>
        opt.value.entityType === entityType ? { ...opt, value: { ...opt.value, refs: [...opt.value.refs, entityRef] } } : opt
    )
}
