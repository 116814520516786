import React from 'react';

export const IconButton = ({
  icon,
  tabIndex,
  isDisabled = false,
  className = 'btn btn-link',
  onClick = null,
  title = null
}) => (
  <button
    className={className}
    onClick={onClick}
    disabled={isDisabled}
    title={title}
    tabIndex={tabIndex}
  >
    <i className={`fa-solid fa-${icon}`}></i>
  </button>
);
