import { Input } from "invevo-react-components"

const getMinFromValue = (value) => {
    const rangeParts = value.split(',')
    if (rangeParts.length === 2) {
        return rangeParts[0]
    }

    return ""
}

const getMaxFromValue = (value) => {
    const rangeParts = value.split(',')
    if (rangeParts.length === 2) {
        return rangeParts[1]
    }

    return ""
}

const FilterRuleInputRange = ({ rule, ruleIndex, onMinChange, onMaxChange, isValidNumber, readonly }) => {
    const rangeMinValue = getMinFromValue(rule.value)
    const rangeMaxValue = getMaxFromValue(rule.value)
    return (
        <div className="d-flex flex-column">
            <div className="d-flex align-items-center me-3">
                <div className="d-flex">
                    <Input
                        disabled={readonly}
                        value={rangeMinValue}
                        placeholder="From"
                        onChange={(event) => onMinChange(event.target.value, rule, ruleIndex)}
                        isValid={!rangeMinValue || isValidNumber(rangeMinValue)}
                    />
                </div>
                <i className="mx-2 text-grey fal fa-arrow-right"></i>
                <div className="d-flex">
                    <Input
                        disabled={readonly}
                        value={rangeMaxValue}
                        placeholder="To"
                        onChange={(event) => onMaxChange(event.target.value, rule, ruleIndex)}
                        isValid={!rangeMaxValue || isValidNumber(rangeMaxValue)}
                    />
                </div>
            </div>
            {rule.value && !rule.isValid &&
                <div className="mt-2 d-flex align-items-center text-danger">
                    <i className="fal fa-exclamation-triangle me-2"></i>
                    {isValidNumber(rangeMinValue) && isValidNumber(rangeMaxValue) && parseFloat(rangeMinValue) >= parseFloat(rangeMaxValue) ?
                        <span>Please specify a valid range with <b>From</b> value begin less than <b>To</b> value</span> :
                        <span>Please specify a valid number</span>
                    }
                </div>
            }
        </div>
    )
}

export default FilterRuleInputRange