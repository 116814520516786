import { DataTypeEnum } from "../../../../routes/entityConfig/types/DataType"
import EntityConfig from "../../../../routes/entityConfig/types/EntityConfig"
import EntityData from "../../../../types/EntityData"
import ContactCard from "./ContactCard"

type EntityVoipContactsProps = {
    selectedEntity: EntityData
    entityConfig: EntityConfig
    onEntityContactSelected: (phoneNumber: string) => void
}

const EntityVoipContacts = ({ selectedEntity, entityConfig, onEntityContactSelected }: EntityVoipContactsProps) => {
    const phoneNumberFields = entityConfig.fields.filter(field => field.dataType.type === DataTypeEnum.PHONE_NUMBER)

    return (
        <div className="d-flex flex-column w-100">
            {phoneNumberFields.length > 0 && (
                <>
                    <h3 className="text-white ms-3 mt-4 mb-3">Available Contacts</h3>
                    <div className="d-flex flex-row justify-content-between flex-wrap mx-4">
                        {phoneNumberFields.map(field => {
                            // @ts-ignore
                            return selectedEntity.entityFields.textFields[field.fieldName] ? (
                                <ContactCard
                                    key={field.fieldName}
                                    name={field.displayName}
                                    //@ts-ignore
                                    phoneNumber={selectedEntity.entityFields.textFields[field.fieldName]}
                                    onContactSelected={onEntityContactSelected}
                                />
                            ) : (
                                <></>
                            )
                        })}
                    </div>
                </>
            )}
        </div>
    )
}

export default EntityVoipContacts
