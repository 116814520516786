import { getApiUrl } from '../constants';
import { webApiInterface } from './webApiInterface';

export function getImportDocumentType(client, documentTypeId, dispatch) {
    const url = getApiUrl(client) + 'api/import-document-type?id=' + documentTypeId;

    return webApiInterface.authFetch(client, url, dispatch)
        .then(function (response) {
            return response.json();
        });
}

export function deleteImportDocumentType(client, id, dispatch) {
    const url = getApiUrl(client) + 'api/import-document-type/delete';

    return webApiInterface.authDelete(client, url, dispatch, id);
}


export function updateImportDocumentType(client, documentType, dispatch) {
    const url = getApiUrl(client) + 'api/import-document-type/update';

    return webApiInterface.authPost(client, url, dispatch, documentType, 'POST');
}

export function createImportDocumentType(client, documentType, dispatch) {
    const url = getApiUrl(client) + 'api/import-document-type/create';

    return webApiInterface.authPost(client, url, dispatch, documentType, 'POST');
}