import { useEffect, useState } from 'react';
import { executeAuthAsyncPost } from '../utility/asyncSupport';
import { useDispatch } from 'react-redux';

const useTaskOwnership = (client, taskId, ownedByUserId = null) => {
  const dispatch = useDispatch();
  const [isTaskOwned, setIsTaskOwned] = useState(false);
  const [forcedCount, setForcedCount] = useState(0);

  useEffect(() => {
    if (!taskId) {
      setIsTaskOwned(false);
      return;
    }

    dispatch(
      executeAuthAsyncPost(
        client,
        'task/setownership',
        'task_setownership',
        { taskId },
        () => setIsTaskOwned(false),
        () => setIsTaskOwned(true)
      )
    );

    return () => {
      if (!taskId) return;

      dispatch(
        executeAuthAsyncPost(client, 'task/releaseownership', 'task_releaseownership', {
          taskId
        })
      );
    };
  }, [taskId, ownedByUserId, forcedCount, dispatch, client]);

  const forceTaskOwnershipRelease = () => {
    if (!taskId) return;

    dispatch(
      executeAuthAsyncPost(
        client,
        'task/forcereleaseownership',
        'task_forcereleaseownership',
        {
          taskId
        },
        () => setForcedCount(forcedCount + 1)
      )
    );
  };

  return { isTaskOwned, forceTaskOwnershipRelease };
};

export default useTaskOwnership;
