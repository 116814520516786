import { useState } from "react"
import Input from "../../../../library/Inputs/Input"
import EntityConfig from "../../types/EntityConfig"
import IconButton from "../../../../library/buttons/IconButton/IconButton"
import EntityDataField from "../../types/EntityDataField"
import Collapsable from "../../../../library/collapsable/Collapsable"
import EntityConfigDataFieldForm from "../EntityConfigDataFieldForm"
import EntityRelationship from "../../types/EntityRelationship"
import Lookup from "../../../../types/Lookup"
import ShadowBorder from "../../../../library/borders/ShadowBorder"
import { DataPrimitiveTypeEnum, DataTypeEnum } from "../../types/DataType"
import { toSnakeCase } from "../../../../library/helpers/stringUtils"

type EntityConfigEditorProps = {
    entityConfig: EntityConfig
    allEntityConfigs: EntityConfig[]
    entityRelationships: EntityRelationship[]
    lookups: Lookup[]
    isNewEntityConfig: boolean
    onSaveEntityConfig: (config: EntityConfig) => void
    onSaveNewEntityConfig: (config: EntityConfig) => void
}

const EntityConfigDiagramEditor = ({
    entityConfig: originalEntityConfig,
    allEntityConfigs,
    entityRelationships,
    lookups,
    isNewEntityConfig,
    onSaveEntityConfig,
    onSaveNewEntityConfig
}: EntityConfigEditorProps) => {
    const [entityConfig, setEntityConfig] = useState<EntityConfig>(originalEntityConfig)
    const [errorText, setErrorText] = useState("")

    const onDisplayNameChanged = (newDisplayName: string) => {
        setEntityConfig({
            ...originalEntityConfig,
            reference: isNewEntityConfig ? toSnakeCase(newDisplayName) : originalEntityConfig.reference,
            displayName: newDisplayName
        })
    }

    const onSaveClicked = () => {
        if (entityConfig.displayName === "") {
            setErrorText("You must give the entity a display name and reference")
            return
        }
        setErrorText("")
        onSaveEntityConfig(entityConfig)
        isNewEntityConfig && onSaveNewEntityConfig(entityConfig)
    }

    const [expandedField, setExpandedField] = useState<string>()

    const onFieldChanged = (field: EntityDataField) => {
        const originalField = entityConfig.fields.find(f => f.fieldName === field.fieldName)
        const entityHasNewField = entityConfig.fields.some(f => f.fieldName === "")
        if ((!field || !originalField) && !entityHasNewField) return
        const newEntityConfig = entityHasNewField
            ? {
                  ...entityConfig,
                  fields: entityConfig.fields.map(f => (f.fieldName === "" ? field : f))
              }
            : {
                  ...entityConfig,
                  fields: entityConfig.fields.map(f => (f.fieldName === field.fieldName ? field : f))
              }
        setEntityConfig(newEntityConfig)
    }

    const onEntityFieldAdded = () =>
        setEntityConfig(c => ({
            ...c,
            fields: [
                ...c.fields,
                {
                    fieldName: "",
                    displayName: "",
                    description: "",
                    dataType: { type: DataTypeEnum.TEXT },
                    dataPrimitiveType: DataPrimitiveTypeEnum.TEXT,
                    isQuickFilter: false
                }
            ]
        }))

    const onFieldDeleted = (fieldName: string) =>
        setEntityConfig(c => ({
            ...c,
            fields: c.fields.filter(f => f.fieldName !== fieldName)
        }))

    return (
        <div className="w-100 h-100 p-3 bg-blue text-white d-flex flex-column gap-2">
            <span className="h3">EDIT: {entityConfig.displayName.toUpperCase()}</span>
            <div className="w-100 d-flex flex-column overflow-auto p-2 gap-3">
                <div className="d-flex flex-row align-items-center justify-content-between w-100">
                    <span className="h5">Ref:</span>
                    <span>{entityConfig.reference}</span>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between w-100">
                    <span className="h5">Display name:</span>
                    <Input value={entityConfig.displayName} onChange={onDisplayNameChanged} />
                </div>
                <div className="d-flex flex-column gap-2">
                    {entityConfig.fields.map(f => (
                        <FieldDropdown
                            entityConfig={entityConfig}
                            allEntityConfigs={allEntityConfigs}
                            entityRelationships={entityRelationships}
                            lookups={lookups}
                            field={f}
                            isCollapsed={expandedField !== f.fieldName}
                            onFieldExpanded={setExpandedField}
                            onFieldChanged={onFieldChanged}
                            onFieldDeleted={onFieldDeleted}
                        />
                    ))}
                </div>
            </div>
            <div className="d-flex flex-row mt-auto justify-content-between align-items-center ">
                <IconButton theme="blue" icon={"fa-solid fa-check"} onClick={onSaveClicked} dynamicShadow={true} />
                <span className="text-danger">{errorText}</span>
                <IconButton theme="blue" icon={"fa-solid fa-plus"} onClick={onEntityFieldAdded} dynamicShadow={true} />
            </div>
        </div>
    )
}

export default EntityConfigDiagramEditor

type FieldDropdownProps = {
    allEntityConfigs: EntityConfig[]
    entityConfig: EntityConfig
    entityRelationships: EntityRelationship[]
    lookups: Lookup[]
    field: EntityDataField
    isCollapsed: boolean
    onFieldExpanded: (fieldName?: string) => void
    onFieldChanged: (field: EntityDataField) => void
    onFieldDeleted: (fieldName: string) => void
}

const FieldDropdown = ({
    allEntityConfigs,
    entityConfig,
    entityRelationships,
    lookups,
    field,
    isCollapsed,
    onFieldExpanded,
    onFieldChanged,
    onFieldDeleted
}: FieldDropdownProps) => {
    const toggleCollapsed = () => onFieldExpanded(isCollapsed ? field.fieldName : undefined)
    const onFieldDeleteClick = () => onFieldDeleted(field.fieldName)
    const title = (
        <div className="d-flex flex-row gap-2">
            <IconButton theme="blue" size="small" icon={"fa-thin fa-trash"} onClick={onFieldDeleteClick} dynamicShadow={true} />
            {field.displayName}
        </div>
    )
    return (
        <ShadowBorder>
            <Collapsable title={title} isCollapsed={isCollapsed} classname="px-2 py-2 rounded" background="blue" onCollapseToggle={toggleCollapsed}>
                <EntityConfigDataFieldForm
                    className="mt-2 rounded"
                    allEntityConfigs={allEntityConfigs}
                    entityConfig={entityConfig}
                    relationships={entityRelationships}
                    isEditing={true}
                    lookups={lookups}
                    entityDataField={field}
                    onSaveEntityDataField={onFieldChanged}
                    isCompact={true}
                    showHeader={false}
                />
            </Collapsable>
        </ShadowBorder>
    )
}
