import { useEffect, useState } from "react"
import { useApi, useClient, Loading, DashboardTable, getConfig } from "invevo-react-components"

const AgedDebt = ({ agedDebtGroups, setAgedDebtGroups }) => {
    const api = useApi()
    const client = useClient()
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)

    useEffect(() => {
        if (isFetching || hasFetched) {
            return
        }
        setIsFetching(true)
        getConfig().then(config => {
            api.get(`${config.COLLECT_API_URL}/api/${client}/aged-debt`)
                .then(response => {
                    setHasFetched(true)
                    setAgedDebtGroups(mapData(response.data).map(d => { return { ...d, name: d.debtPeriod } }))
                    setIsFetching(false)
                })
                .catch(error => console.error(error))
        })
    }, [api, client, hasFetched, isFetching, setAgedDebtGroups])

    return (
        <div className="d-flex h-100 w-100">
            <Loading isLoading={isFetching} colour="blue">
                <DashboardTable
                    data={agedDebtGroups}
                    setData={setAgedDebtGroups}
                    isFetching={isFetching}
                    contentType="agedDebt"
                    title="Aged Debt"
                />
            </Loading>
        </div>
    )
}

const mapData = (data) => {
    const baseData = [
        {
            index: 0,
            debtPeriod: "Current",
            customers: 0,
            value: 0,
            currency: "",
            isSelected: false
        },
        {
            index: 1,
            debtPeriod: "Aged debt 0-30",
            customers: 0,
            value: 0,
            currency: "",
            isSelected: false
        },
        {
            index: 2,
            debtPeriod: "Aged debt 31-60",
            customers: 0,
            value: 0,
            currency: "",
            isSelected: false
        },
        {
            index: 3,
            debtPeriod: "Aged debt 61-90",
            customers: 0,
            value: 0,
            currency: "",
            isSelected: false
        },
        {
            index: 4,
            debtPeriod: "Aged debt 90+",
            customers: 0,
            value: 0,
            currency: "",
            isSelected: false
        },
    ]

    data.forEach(d => {
        const index = baseData.findIndex(bd => bd.debtPeriod === d.debtPeriod)
        baseData[index] = { ...d, index: index }
    })
    return baseData
}

export default AgedDebt