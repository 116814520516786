import LegacySmsTemplate from "../../types/LegacySmsTemplate"

const SmsActionInfo = ({ template, bigTitle }: { template: LegacySmsTemplate | undefined; bigTitle: boolean }) => {
    return (
        <div className="d-flex align-items-center gap-2 me-3">
            <i className="fal fa-mobile text-blue me-1 fs-5" />
            <span className={`text-black fw-bold small me-1 ${bigTitle && "fs-5"}`}>Send SMS</span>
            {template && (
                <span className={`d-flex small ${bigTitle && "fs-5"}`}>
                    with template
                    <span className="d-block text-black fw-bold text-truncate ms-1">{template.name}</span>
                </span>
            )}
        </div>
    )
}

export default SmsActionInfo
