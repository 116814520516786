import FilterList from "../../../../../library/FilterList/FilterList"
import { padElementsWith } from "../../../../../library/helpers"
import DataField from "../../../../../types/DataField"
import Lookup from "../../../../../types/Lookup"
import classes from "./UpdateDataFieldActionComponent.module.scss"
import GenericFilter from "../../../../../library/FilterList/filterTypes/GenericFilter"
import { v4 as uuidv4 } from "uuid"
import { FieldUpdate, UpdateChildEntityAction } from "../../../types/actions/UpdateChildEntityAction"
import UpdateEntityDefinition from "./UpdateChildEntityDefinition"
import EntityConfig from "../../../../entityConfig/types/EntityConfig"
import { getDataFieldsFromEntityDataFields } from "../../../../../library/helpers/entityHelpers"
import { useState } from "react"
import EntityRelationshipDto from "../../../../entityConfig/types/EntityRelationshipDto"
import WorkflowRule from "../../../types/WorkflowRule"
import DropdownOption from "../../../../../types/DropdownOptions"
import ActionType from "../../../types/actions/ActionType"
import Dropdown from "../../../../../library/dropdowns/Dropdown"
import StandardButton from "../../../../../library/buttons/StandardButton/StandardButton"

type UpdateChildEntityActionComponentProps = {
    action: UpdateChildEntityAction
    onActionUpdated: (action: UpdateChildEntityAction) => void
    entityRelationships: EntityRelationshipDto[]
    parentEntityFields: DataField[]
    lookups: Lookup[]
    entityConfigs: EntityConfig[]
    selectedRule: WorkflowRule
}

const UpdateChildEntityActionComponent = ({
    action,
    onActionUpdated,
    entityRelationships,
    parentEntityFields,
    lookups,
    entityConfigs,
    selectedRule
}: UpdateChildEntityActionComponentProps) => {
    const getEntityTypeName = (reference?: string | null) => {
        const entity = entityConfigs.find(entity => entity.reference === reference)
        return entity?.displayName || ""
    }

    const [selectedDropdown, setSelectedDropdown] = useState({
        label: getEntityTypeName(action.entityTypeReference),
        value: action.entityTypeReference
    })

    const entityConfig = entityConfigs.find(entity => entity.reference === selectedDropdown.value)

    const entityChildrenReferences = entityRelationships
        .filter(entity => entity.parentEntityReference === selectedRule.entityConfigReference)
        .map(entity => entity.childEntityReference)

    const entityRuleReference = getEntityTypeName(selectedRule.entityConfigReference)

    const entityDropdownOptions = (): DropdownOption<string>[] => {
        return entityConfigs
            .filter(entity => entityChildrenReferences.includes(entity.reference))
            .map(child => ({
                label: child?.displayName || "",
                value: child?.reference || ""
            }))
    }

    const filteredEntityFields = entityConfig
        ? getDataFieldsFromEntityDataFields(entityConfig.fields).filter(field => !action.fieldUpdates.map(field => field.field).includes(field.value))
        : undefined

    const onDropdownValueChanged = (option: DropdownOption<string>) => {
        if (action.type !== ActionType.UPDATE_CHILD_ENTITY) {
            return
        }
        setSelectedDropdown({ label: option.label, value: option.value })
        onActionUpdated({
            ...action,
            entityTypeReference: option.value
        })
    }
    const onDataFieldDefinitionUpdated = (id: string, field: DataField | undefined, value: string) => {
        const newFieldUpdates = action.fieldUpdates.find(fieldUpdates => fieldUpdates.id === id)
            ? action.fieldUpdates.map(update =>
                  update.id === id
                      ? ({
                            id,
                            field: field?.value ?? "",
                            value,
                            primitive: field?.type
                        } as FieldUpdate)
                      : update
              )
            : [
                  ...action.fieldUpdates,
                  {
                      id,
                      field: field?.value ?? "",
                      value,
                      primitive: field?.type
                  } as FieldUpdate
              ]

        onActionUpdated({
            ...action,
            fieldUpdates: newFieldUpdates
        })
    }

    const onCustomerFiltersUpdated = (filters: GenericFilter[]) =>
        onActionUpdated({
            ...action,
            targetEntityFilters: filters
        })

    const onNewFieldUpdateClicked = () =>
        onActionUpdated({
            ...action,
            fieldUpdates: [
                ...action.fieldUpdates,
                {
                    id: uuidv4(),
                    field: "",
                    value: ""
                }
            ]
        })
    const onDataFieldDefinitionRemoved = (id: string) =>
        onActionUpdated({
            ...action,
            fieldUpdates: action.fieldUpdates.filter(update => update.id !== id)
        })

    const hasChildEntities: boolean = entityDropdownOptions().length === 0

    const fieldUpdateComponents = action.fieldUpdates.map(update => (
        <UpdateEntityDefinition
            key={update.id}
            id={update.id}
            parentEntityFields={parentEntityFields}
            selectedParentValue={update.value}
            parentRuleReference={entityRuleReference}
            childEntityFields={filteredEntityFields ?? []}
            selectedChildField={entityConfig ? getDataFieldsFromEntityDataFields(entityConfig.fields).find(f => f.value === update.field) : undefined}
            selectedChildDropdown={selectedDropdown}
            onDataFieldDefinitionUpdated={onDataFieldDefinitionUpdated}
            onDataFieldDefinitionRemoved={onDataFieldDefinitionRemoved}
        />
    ))

    return (
        <div>
            {hasChildEntities ? (
                <div>
                    <b>{entityRuleReference}</b> contains no children to update
                </div>
            ) : (
                <div>
                    <span className="mb-3 d-flex align-items-center">
                        Choose which child you would like to update:
                        <Dropdown
                            className="ms-2"
                            options={entityDropdownOptions()}
                            onOptionSelected={onDropdownValueChanged}
                            selectedOption={selectedDropdown}
                            ariaLabel={"child-entity-dropdown"}
                        />
                    </span>
                    <div className="d-flex flex-column">
                        {padElementsWith(fieldUpdateComponents, "mt-2")}
                        <StandardButton
                            className="me-auto mt-2"
                            iconClasses="fal fa-plus"
                            label="Add Field Update"
                            colour="grey"
                            onClick={onNewFieldUpdateClicked}
                        />
                        <div className={`mt-3 p-3 ${classes.container}`}>
                            <span className="fs-5">{selectedDropdown.label} Filters</span>
                            <div className="mt-2">
                                <FilterList
                                    key={action.actionReference}
                                    fields={getDataFieldsFromEntityDataFields(entityConfig?.fields ?? [])}
                                    lookups={lookups}
                                    appliedFilters={action.targetEntityFilters}
                                    onFiltersApplied={onCustomerFiltersUpdated}
                                    autoApply={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default UpdateChildEntityActionComponent
