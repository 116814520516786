import classes from './SetCrmRoles.module.css';

const SetCrmRoles = ({ roleSet, selectRoleHandler, labelName }) => {
    return (
        <div className={`d-flex align-items-center mx-3 ${classes.pointer}`} onClick={selectRoleHandler}>
            {/* Check font awesome icon renders */}
            <i className={`far ${roleSet ? 'fa-check-square' : 'fa-square'}`}></i>
            {/* <FontAwesomeIcon icon={['far', roleSet ? 'check-square' : 'square']}></FontAwesomeIcon> */}
            <span className="ml-2">{labelName}</span>
        </div>
    )
}

export default SetCrmRoles