import { useState } from "react"
import useApiQuery from "../../../../../hooks/useApiQuery"
import Footer from "../../../../../library/dataGrid/Footer"
import { FileImportRowDto, FileImportRowsDto } from "../../../types/dtos/FileImportRowDto"
import FileImportRowDetails from "./FileImportRowDetails"
import classes from "./FileImportRowList.module.scss"
import { useEnvConfig } from "../../../../../contexts/EnvironmentConfigContext"
import useClient from "../../../../../hooks/useClient"
import Loading from "../../../../../library/Loading/Loading"

type FileImportRowListProps = {
    importReference: string
    importFileName: string
    rejectedRowCount: number
    deselectFileImport: () => void
}

const FileImportRowList = ({ importReference, importFileName, rejectedRowCount, deselectFileImport }: FileImportRowListProps) => {
    const client = useClient()
    const config = useEnvConfig()

    const [importRows, setImportRows] = useState<FileImportRowDto[]>([])
    const [lastRowForPage, setLastRowForPage] = useState<{ [key: number]: FileImportRowDto | undefined }>({})
    const [pageSize, setCurrentPageSize] = useState(10)
    const [pageIndex, setPageIndex] = useState(0)

    const getRowsRequest = useApiQuery<FileImportRowsDto>({
        url: `${config.INTEGRATION_API_URL}/api/${client}/file-import-rows`,
        method: "POST",
        dto: {
            uniqueFileName: importReference,
            pageSize: pageSize,
            searchAfterId: lastRowForPage[pageIndex - 1]?.reference ?? null,
            sortBy: null
        },
        onSuccess: rowsResults => {
            setImportRows(rowsResults.pagedRows)
            setLastRowForPage({
                ...lastRowForPage,
                [pageIndex]: rowsResults.pagedRows.at(-1)
            })
        }
    })

    return (
        <div className="d-flex flex-column h-100 w-100 bg-grey px-4 pb-4">
            <Loading isLoading={getRowsRequest.isFetching}>
                <div className="d-flex w-100 pt-3 text-grey">
                    <span className="fs-3 fw-lighter">Row details of</span>
                    <span className="fs-3 fw-bold ms-2">{importFileName}</span>

                    <div
                        className={`d-flex rounded ms-auto pointer ${classes.closeButton}`}
                        role="button"
                        aria-label="close-details"
                        onClick={deselectFileImport}
                    >
                        <i className="fa-regular fa-x m-auto"></i>
                    </div>
                </div>
                <div className="d-flex flex-column overflow-auto mb-4 rounded">
                    {importRows.length > 0 && importRows.map(row => <FileImportRowDetails key={row.reference} row={row} />)}
                </div>
            </Loading>
            <div className="mt-auto">
                <Footer
                    isLoading={getRowsRequest.isFetching}
                    totalRowCount={rejectedRowCount}
                    currentPageSize={pageSize}
                    onCurrentPageSizeChanged={setCurrentPageSize}
                    pageIndex={pageIndex}
                    onPageIndexChanged={setPageIndex}
                />
            </div>
        </div>
    )
}

export default FileImportRowList
