import { Collapsable, Dropdown, InlineFilters, useFeatureToggle, useClient, useConfig, Loading } from 'invevo-react-components'
import SetupCard from '../SetupCard'
import SmsSendActionContactFilters from './filters/SmsSendActionContactFilters'
import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

const Action = ({
    action,
    index,
    onRemoveAction,
    smsTemplates,
    onActionChange,
    customerFields,
    customerDataConfiguration
}) => {
    const client = useClient()
    const config = useConfig()
    const { isEnabled: demoShims } = useFeatureToggle("demoShims")
    const [smsTemplate, setSmsTemplate] = useState()

    useEffect(() => {
        setSmsTemplate(action.template)
    }, [action.template])

    const messageOptions = [
        { id: "1", value: "10", intValue: 10 },
        { id: "2", value: "100", intValue: 100 },
        { id: "3", value: "1,000", intValue: 1000 },
        { id: "4", value: "10,000", intValue: 10000 },
        { id: "5", value: "100,000", intValue: 100000 },
        { id: "6", value: "unlimited", intValue: 999999 },
    ]

    const perMinuteOptions = [
        { id: "1", value: "1", intValue: 1 },
        { id: "2", value: "10", intValue: 10 },
        { id: "3", value: "100", intValue: 100 },
        { id: "4", value: "1,000", intValue: 1000 },
        { id: "5", value: "10,000", intValue: 10000 },
        { id: "6", value: "100,000", intValue: 100000 },
        { id: "7", value: "unlimited", intValue: 999999 },
    ]
    const communicationType = "SendSms"

    const onFilterChange = (changedFilter, changedFilterIndex) => {
        const updatedFilters = action.customerFilters.map((filter, filterIndex) => {
            if (changedFilterIndex !== filterIndex) {
                return filter
            }

            let operatorValue = {}
            if (changedFilter.operator) {
                operatorValue = changedFilter.operator
            }

            return {
                ...filter, ...{
                    fieldName: changedFilter.fieldName,
                    type: changedFilter.type,
                    value: changedFilter.value,
                    operator: operatorValue,
                    isValid: changedFilter.isValid
                }
            }
        })

        onActionChange({ ...action, customerFilters: updatedFilters })
    }

    const onAddFilter = () => {
        onActionChange({ ...action, customerFilters: [...action.customerFilters, { fieldName: "", value: "" }] })
    }

    const onRemoveFilter = (_, filterIndex) => {
        onActionChange({ ...action, customerFilters: action.customerFilters.filter((_, index) => index !== filterIndex) })
    }

    const onRemoveAll = () => {
        onActionChange({ ...action, customerFilters: action.customerFilters.slice(0, 0) })
    }

    const children = (
        <div className="d-flex flex-column w-100">
            <div className="d-flex align-items-center">
                <span className="text-grey me-3 text-uppercase">template</span>
                <Dropdown
                    className="me-3"
                    defaultOption={action.template}
                    options={smsTemplates}
                    onOptionSelected={(option) => {
                        setSmsTemplate(option)
                        onActionChange({ ...action, templateRef: option.reference }, index)
                    }}
                />
                <span className="text-grey me-3 text-uppercase">to send up to</span>
                <Dropdown
                    className="me-3"
                    placeholder={action.messageCountOption}
                    options={messageOptions}
                    onOptionSelected={(option) => onActionChange({ ...action, messageCountOption: option.intValue }, index)}
                />
                <span className="text-grey me-3 text-uppercase">messages, limit to</span>
                <Dropdown
                    className="me-3"
                    placeholder={action.perMinuteOption}
                    options={perMinuteOptions.filter(o => o.intValue <= action.messageCountOption)}
                    onOptionSelected={(option) => onActionChange({ ...action, perMinuteOption: option.intValue }, index)}
                    disabled={perMinuteOptions.filter(o => o.intValue <= action.perMinuteOption).length === 0}
                />
                <span className="text-grey me-3 text-uppercase">message(s) per minute</span>
            </div>
            <SmsSendActionContactFilters
                className="mt-3"
                state={action.smsContactConfig}
                onChange={(contactConfig) => onActionChange({ ...action, smsContactConfig: { ...action.smsContactConfig, ...contactConfig } }, index)}
            />

            <InlineFilters
                className="mt-3"
                rules={action.customerFilters}
                onRuleChange={onFilterChange}
                onAddRule={onAddFilter}
                onRemoveRule={onRemoveFilter}
                onRemoveAll={onRemoveAll}
                inputType="customerfielddropdown"
                fieldOptions={customerFields}
                fieldsAvailableForAutoComplete={customerDataConfiguration}
                autoCompleteUrl={`${config.AUTOMATE_API_URL}/api/${client}/autocomplete-field-values`}
                enableTooltip={true}
                scrollable={true}
            />
        </div>
    )

    return (
        <Loading isLoading={!config.AUTOMATE_API_URL} colour="blue">
            <Collapsable
                className="mt-3"
                title={`SMS ACTION ${smsTemplate ? `- ${smsTemplate.value.toUpperCase()}` : ""} - APPLIED FILTERS: ${action.customerFilters.length}`}
                id={`action-${uuidv4()}`}>
                <SetupCard title={demoShims ? '' : communicationType} customData={children} addButton={false} onClick={() => onRemoveAction(index)} />
            </Collapsable>
        </Loading>
    )
}

export default Action