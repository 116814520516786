type RowStatusProps = {
    status: string
}

const RowStatus = ({ status }: RowStatusProps) => {
    const getStatus = (status: string) => {
        switch (status) {
            case "IMPORTED":
                return (
                    <div className="d-flex align-items-center me-3 my-2 text-green">
                        <i className="fal fa-check-double me-2 fs-6" />
                        <span className="fw-bold">IMPORTED</span>
                    </div>
                )
            case "PARTIALLY_IMPORTED":
                return (
                    <div className="d-flex align-items-center me-3 my-2 text-light-green">
                        <i className="fal fa-comment-alt-check me-2 fs-6" />
                        <span className="fw-bold">PARTIALLY IMPORTED</span>
                    </div>
                )
            case "REJECTED":
                return (
                    <div className="d-flex align-items-center me-3 my-2 text-red">
                        <i className="fal fa-times-square me-2 fs-6" />
                        <span className="fw-bold">REJECTED</span>
                    </div>
                )
        }
    }

    return <>{getStatus(status)}</>
}

export default RowStatus
