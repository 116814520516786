import React, { Component } from 'react';
import { connect } from 'react-redux';

import { executeAuthAsyncGet } from '../../../utility/asyncSupport';
import { getApiUrl } from '../../../constants';
import { storeGridState } from '../../../actions/gridCacheActions';

import { GridColumn } from '@progress/kendo-react-grid';
import { CommandCell } from '../../../components/common/grids/commandCell';
import { ClientConfigurable } from '../../../components/common/grids/gridCells';
import StatefulGrid from '../../../components/common/grids/StatefulGrid';
import existential from '../../../utility/existential';

class ConfigurationGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { field: 'systemConfigurationId', title: 'Id', width: '100px' },
        {
          field: 'ordinal',
          title: 'Order',
          width: '210px',
          filter: 'numeric',
          type: 'numeric'
        },
        { field: 'name', title: 'Type', width: '300px' },
        { field: 'values', title: 'Value(s)', width: '500px' },
        { field: 'description', title: 'Description' },
        {
          field: 'clientConfigurable',
          title: 'Client configurable',
          hidden: this.props.isClient,
          cell: ClientConfigurable
        }
      ]
    };

    this.CommandCell = CommandCell({
      onCommandClick: this.onEditClick,
      cssTag: 'Edit',
      buttonText: 'Edit'
    });
  }

  onStateChange = (dataState) =>
    this.props.storeGridState(ConfigurationGrid.getKey(), dataState);

  onEditClick = (e, dataItem) => {
    const systemConfigurationId = dataItem.systemConfigurationId;
    this.props.onEdit(
      systemConfigurationId,
      dataItem.ordinal,
      dataItem.name,
      dataItem.systemConfigurationTypeId
    );
  };

  static getKey() {
    return 'configuration-grid';
  }

  render() {
    return (
      <div>
        <StatefulGrid
          client={this.props.client}
          id={ConfigurationGrid.getKey()}
          key={ConfigurationGrid.getKey()}
          path={getApiUrl(this.props.client) + 'api/system-configuration'}
          defaultField={{ field: 'ordinal', dir: 'asc' }}
          pageSize={250}
          additionalRequestPayload={{ isClient: this.props.isClient }}
          className={'scrollable-none'}
          pageable={{
            pageSizes: [10, 25, 50, 100, 250],
            buttonCount: 5
          }}
          onStateChange={this.onStateChange}
          sortable
          filterable
        >
          {this.state.columns.map((column) => (
            <GridColumn key={column.field} {...column} />
          ))}
          <GridColumn
            cell={this.CommandCell}
            filterable={false}
            width={'100px'}
          />
        </StatefulGrid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gridState: existential(
      state.gridCacheReducer.grids[ConfigurationGrid.getKey()],
      'gridState',
      {}
    )
  };
};

export default connect(mapStateToProps, {
  executeAuthAsyncGet,
  storeGridState
})(ConfigurationGrid);
