import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import EntityConfig from "../../types/EntityConfig"
import EntityRelationship from "../../types/EntityRelationship"
import { mapFlowEdgesToEdges } from "../../../../library/flow/FlowEdge"
import { mapFlowNodesToNodes } from "../../../../library/flow/FlowNode"
import classes from "./EntityConfigDiagram.module.scss"
import ReactFlowNode from "../../../../library/flow/flowEditor/ReactFlowNode"
import FlowEditor from "../../../../library/flow/flowEditor/FlowEditor"
import { Connection, XYPosition, useEdgesState, useNodesState, useOnSelectionChange, Node, Edge } from "reactflow"
import NodeState from "../../../../library/flow/types/NodeState"
import { v4 as uuid } from "uuid"
import { CustomEdge } from "../../../../library/flow/types/CustomEdge"
import Lookup from "../../../../types/Lookup"
import EntityConfigDiagramOverlay from "./EntityConfigDiagramOverlay"
import EntityRelationshipCalculation from "../../types/EntityRelationshipCalculation"
import { useLocalStorage } from "../../../../hooks/useLocalStorage"
import EntityConfigFlowNode, { CalculationFlowNode, EntityConfigFlowNodeType, EntityFlowNode, EntityFlowNodePosition } from "./types/EntityConfigFlowNode"
import EntityConfigFlowEdge from "./types/EntityConfigFlowEdge"
import {
    isValidEntityConfigFieldToDisplay,
    mapCalculationsToFlowNodesAndEdges,
    mapEntityConfigsToFlowNodes,
    mapEntityRelationshipsToFlowEdges
} from "./mapping"
import EntityDataField from "../../types/EntityDataField"
import { DataPrimitiveTypeEnum } from "../../types/DataType"
import AggregationType from "../../types/AggregationType"
import { removeCalculationFromRelationships } from "../EntityConfigDetails"
import { IntraEntityCalculationOperationEnum } from "../../types/IntraEntityCalculationOperationEnum"
import { capitaliseFirstLetter } from "../../../../library/helpers"

type EntityConfigDiagramControllerProps = {
    entityConfigs: EntityConfig[]
    entityRelationships: EntityRelationship[]
    lookups: Lookup[]
    newEntityConfig: EntityConfig
    onEntityConfigUpdated: (updatedEntityConfig: EntityConfig) => void
    onNewEntityConfigAdded: () => void
    onNewEntityConfigSaved: (newEntityConfig: EntityConfig) => void
    onEntityRelationshipsUpdated: Dispatch<SetStateAction<EntityRelationship[]>>
    disableDiagramView: () => void
}

const EntityConfigDiagram = ({
    entityConfigs,
    entityRelationships,
    lookups,
    newEntityConfig,
    onEntityConfigUpdated,
    onNewEntityConfigAdded,
    onNewEntityConfigSaved,
    onEntityRelationshipsUpdated,
    disableDiagramView
}: EntityConfigDiagramControllerProps) => {
    const [nodes, setNodes, onNodesChange] = useNodesState<NodeState<EntityConfigFlowNode>>([])
    const [edges, setEdges, onEdgesChange] = useEdgesState<EntityConfigFlowEdge>([])
    const [nodePositions, setNodePositions, , positionsLoaded] = useLocalStorage<EntityFlowNodePosition[]>(`entityConfig/diagram/nodePositions`, [])

    const getNodesFromConfigs = () =>
        mapFlowNodesToNodes(
            [
                ...mapEntityConfigsToFlowNodes(entityConfigs, nodePositions, collapsedNodes),
                ...mapCalculationsToFlowNodesAndEdges(entityConfigs, entityRelationships, nodePositions, collapsedNodes).nodes
            ],
            setSelected,
            () => {},
            onCollapseNodeToggle
        )

    useEffect(() => {
        if (!positionsLoaded) return
        setNodes(getNodesFromConfigs())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [positionsLoaded])

    const onSaveNodePositions = () => {
        setNodePositions(
            nodes.map(n => ({
                nodeRef: n.data.state.reference,
                x: n.position.x,
                y: n.position.y
            }))
        )
    }

    const getCollapsedNodeRefs = (): string[] => nodes.filter(n => n.data.state.collapsed ?? false).map(n => n.data.state.reference)

    const [selected, setSelected] = useState<string | undefined>(undefined)
    const [collapsedNodes, setCollapsedNodes] = useState<string[]>(getCollapsedNodeRefs())

    const onNodeDeleted = useCallback((ref: string) => setNodes(nodes => nodes.filter(n => n.id !== ref)), [setNodes])

    const onCollapseNodeToggle = useCallback(
        (nodeRef: string) => {
            setNodes(nodes =>
                nodes.map(n => {
                    return n.data.state.reference === nodeRef
                        ? { ...n, data: { ...n.data, state: { ...n.data.state, collapsed: !n.data.state.collapsed } } }
                        : n
                })
            )
        },
        [setNodes]
    )

    useOnSelectionChange({
        onChange: ({ nodes: selectedNodes }) => {
            if (selectedNodes.length > 1 || selectedNodes.length === 0) {
                setSelected(undefined)
                return
            }
            const selectedNodeRef = selectedNodes.at(0)?.data.state.reference
            if (selectedNodeRef !== undefined && selected !== selectedNodeRef) {
                setSelected(selectedNodeRef)
                return
            }
            setSelected(undefined)
        }
    })

    const getAllEdges = useCallback(
        (): EntityConfigFlowEdge[] => [
            ...mapEntityRelationshipsToFlowEdges(entityRelationships),
            ...mapCalculationsToFlowNodesAndEdges(entityConfigs, entityRelationships, nodePositions).edges
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [entityConfigs, entityRelationships]
    )

    const getCustomEdgeForType = (edge: EntityConfigFlowEdge) => (edge.type === "calculation" ? customCalculationEdge : undefined)

    useEffect(() => {
        setNodes(getNodesFromConfigs())
        setEdges(mapFlowEdgesToEdges(getAllEdges(), getCustomEdgeForType))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityConfigs, entityRelationships])

    useEffect(() => {
        //add any deleted nodes back in if they should not have been removed
        const entityNodeRefs = nodes.filter(n => n.data.state.type === "entity").map(n => n.id)
        if (!entityConfigs.map(e => e.reference).every(r => entityNodeRefs.includes(r))) setNodes(getNodesFromConfigs())

        setCollapsedNodes(getCollapsedNodeRefs())
        // setEdges(mapFlowEdgesToEdges(getAllEdges(), getCustomEdgeForType))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodes])

    const toggleCollapseNodes = () => {
        if (collapsedNodes.length <= 1) {
            setNodes(nodes => nodes.map(n => ({ ...n, data: { ...n.data, state: { ...n.data.state, collapsed: true } } })))
        } else {
            setNodes(nodes => nodes.map(n => ({ ...n, data: { ...n.data, state: { ...n.data.state, collapsed: false } } })))
        }
    }

    const onSaveEntityConfig = (entityConfig: EntityConfig, calculation?: EntityRelationshipCalculation, relationship?: EntityRelationship) => {
        onEntityConfigUpdated(entityConfig)
        calculation && onSaveInterEntityCalculation(calculation)
        relationship && onSaveEntityRelationship(relationship)
    }

    const onSaveInterEntityCalculation = (calculation: EntityRelationshipCalculation) => {
        const relationshipToUpdate = entityRelationships.find(
            r => r.childEntityReference === calculation.sourceFieldName && r.parentEntityReference === calculation.destinationFieldName
        )
        if (!relationshipToUpdate) return
        const updatedRelationship = {
            ...relationshipToUpdate,
            calculations: relationshipToUpdate.calculations.map(c =>
                c.destinationFieldName === calculation.destinationFieldName && c.sourceFieldName === calculation.sourceFieldName ? calculation : c
            )
        }
        onSaveEntityRelationship(updatedRelationship)
    }

    const onSaveEntityRelationship = (relationship: EntityRelationship) => {
        onEntityRelationshipsUpdated(
            entityRelationships.map(r =>
                r.childEntityReference === relationship.childEntityReference && r.parentEntityReference === relationship.parentEntityReference
                    ? relationship
                    : r
            )
        )
    }

    const onConnectionAdded = (conn: Connection) => {
        const removeEdge = () => {
            setEdges(edges =>
                edges.filter(
                    e => !(conn.source === e.source && conn.sourceHandle === e.sourceHandle && conn.target === e.target && conn.targetHandle === e.targetHandle)
                )
            )
        }

        if (
            conn.source === null ||
            conn.sourceHandle === null ||
            conn.target === null ||
            conn.targetHandle === null ||
            conn.target.startsWith("CALCULATION") ||
            conn.source.startsWith("CALCULATION") ||
            conn.sourceHandle.endsWith("-left")
        ) {
            removeEdge()
            return
        }

        const sourceEntityRef = conn.source
        const sourceFieldName = conn.sourceHandle
        const targetEntityRef = conn.target
        const targetFieldName = conn.targetHandle.substring(0, conn.targetHandle.indexOf("-left"))
        const isIntraCalc = sourceEntityRef === targetEntityRef
        const configToUpdate = entityConfigs.find(c => c.reference === targetEntityRef)
        if (configToUpdate === undefined || (targetFieldName === "" && targetEntityRef !== conn.targetHandle)) {
            removeEdge()
            return
        }
        if (targetFieldName === "" && targetEntityRef === conn.targetHandle) {
            if (!entityRelationships.some(r => r.childEntityReference === sourceEntityRef && r.parentEntityReference === targetEntityRef)) {
                onEntityRelationshipsUpdated(relationships => [
                    ...relationships,
                    {
                        id: uuid(),
                        childEntityReference: sourceEntityRef,
                        parentEntityReference: targetEntityRef,
                        groupingFieldName: sourceFieldName,
                        calculations: []
                    }
                ])
            } else {
                removeEdge()
            }
            return
        }
        const entityField = configToUpdate.fields.find(f => f.fieldName === targetFieldName)
        if (entityField === undefined) {
            removeEdge()
            return
        }

        const isInvalidCalculation = (sourceField: EntityDataField) =>
            sourceField.dataPrimitiveType === DataPrimitiveTypeEnum.TEXT ||
            sourceField.dataPrimitiveType === DataPrimitiveTypeEnum.BOOLEAN ||
            sourceField.dataPrimitiveType !== entityField.dataPrimitiveType
        if (isIntraCalc) {
            const sourceField = configToUpdate.fields.find(f => f.fieldName === sourceFieldName)
            if (sourceField === undefined || isInvalidCalculation(sourceField)) {
                removeEdge()
                return
            }
            const updatedConfig = {
                ...configToUpdate,
                fields: configToUpdate.fields.map(f => {
                    const existingOperations = f.intraEntityCalculation?.operations ?? []
                    return f.fieldName === targetFieldName
                        ? {
                              ...f,
                              intraEntityCalculation: {
                                  baseField: sourceFieldName,
                                  operations: [
                                      ...existingOperations,
                                      {
                                          ordinal: existingOperations.length + 1,
                                          operation: IntraEntityCalculationOperationEnum.MINUS,
                                          fieldName: sourceFieldName
                                      }
                                  ]
                              }
                          }
                        : f
                })
            }
            onEntityConfigUpdated(updatedConfig)
            removeCalculationFromRelationships(entityField, configToUpdate, entityRelationships, onEntityRelationshipsUpdated)
        } else {
            const relationshipToUpdate = entityRelationships.find(
                r => r.childEntityReference === sourceEntityRef && r.parentEntityReference === targetEntityRef
            )
            if (relationshipToUpdate === undefined) {
                removeEdge()
                return
            }
            const sourceField = entityConfigs.find(c => c.reference === sourceEntityRef)?.fields.find(f => f.fieldName === sourceFieldName)
            if (sourceField === undefined || isInvalidCalculation(sourceField)) {
                removeEdge()
                return
            }

            const calculationType: keyof typeof AggregationType = sourceField.dataPrimitiveType === DataPrimitiveTypeEnum.NUMBER ? "SUM" : "MAX"
            const updatedRelationship = {
                ...relationshipToUpdate,
                calculations: [
                    ...relationshipToUpdate.calculations,
                    {
                        sourceFieldName: sourceFieldName,
                        destinationFieldName: targetFieldName,
                        calculationType,
                        filters: []
                    }
                ]
            }
            onEntityRelationshipsUpdated(entityRelationships.map(r => (r.id === updatedRelationship.id ? updatedRelationship : r)))
            if (entityField.intraEntityCalculation !== undefined) {
                const updatedConfig = {
                    ...configToUpdate,
                    fields: configToUpdate.fields.map(f => (f.fieldName === targetFieldName ? { ...f, intraEntityCalculation: undefined } : f))
                }
                onEntityConfigUpdated(updatedConfig)
            }
        }
    }

    const onSetSelectedFromOverlay = (ref?: string) => {
        if (ref === undefined) {
            setNodes(nodes => nodes.map(n => ({ ...n, selected: false })))
            return
        }
        const foundNode = nodes.find(n => n.data.state.reference === ref)
        if (!foundNode) {
            setNodes(nodes => nodes.map(n => ({ ...n, selected: false })))
            return
        }
        setNodes(nodes => nodes.map(n => (n.data.state.reference === ref ? { ...n, selected: true } : { ...n, selected: false })))
    }

    // only allow calculation nodes to be deleted, and remove calculation when deleted
    const onNodesDeleted = (deletedNodes: Node<NodeState<EntityConfigFlowNode>>[]) => {
        if (deletedNodes.some(n => n.data.state.type === "entity")) {
            return //fixing this is handled in a useEffect because of bad timing with this function being called
        }
        deletedNodes.forEach(n => {
            const node = n.data.state
            const refFields = node.reference.split("~")
            const targetField = refFields.pop()
            const targetEntity = refFields.pop()
            const isIntra = refFields.pop() === "intra"
            if (node.type !== "calculation") return
            node.calculations.forEach(c => {
                if (isIntra) {
                    const configToUpdate = entityConfigs.find(e => e.reference === targetEntity)
                    if (configToUpdate === undefined) return
                    onEntityConfigUpdated({
                        ...configToUpdate,
                        fields: configToUpdate.fields.map(f => (f.fieldName === targetField ? { ...f, intraEntityCalculation: undefined } : f))
                    })
                } else {
                    onEntityRelationshipsUpdated(rels =>
                        rels.map(r =>
                            r.parentEntityReference === targetEntity && r.childEntityReference === c.sourceEntity
                                ? {
                                      ...r,
                                      calculations: r.calculations.filter(
                                          calc => !(c.sourceField === calc.sourceFieldName && targetField === calc.destinationFieldName)
                                      )
                                  }
                                : r
                        )
                    )
                }
            })
        })
    }

    // only allow relationship edges to be deleted, and remove relationships when deleted
    const onEdgesDeleted = (deletedEdges: Edge<EntityConfigFlowEdge>[]) => {
        if (deletedEdges.some(e => e.target.startsWith("CALCULATION") || e.source.startsWith("CALCULATION"))) {
            setEdges(mapFlowEdgesToEdges(getAllEdges(), getCustomEdgeForType))
        } else {
            deletedEdges.forEach(e =>
                onEntityRelationshipsUpdated(rels => rels.filter(r => r.childEntityReference !== e.source && r.parentEntityReference !== e.target))
            )
        }
    }

    return (
        <div className={`d-flex flex-column h-100 w-100 bg-dark-gradient ${classes.container}`}>
            <EntityConfigDiagramOverlay
                entityConfigs={entityConfigs}
                entityRelationships={entityRelationships}
                lookups={lookups}
                selected={selected}
                setSelected={onSetSelectedFromOverlay}
                newEntityConfig={newEntityConfig}
                disableDiagramView={disableDiagramView}
                onNewNodeAdded={onNewEntityConfigAdded}
                collapsedNodes={collapsedNodes}
                toggleCollapseNodes={toggleCollapseNodes}
                onSaveEntityConfig={onSaveEntityConfig}
                onNewEntityConfigSaved={onNewEntityConfigSaved}
                onSaveEntityCalculation={onSaveInterEntityCalculation}
                onSaveNodePositions={onSaveNodePositions}
            />
            <FlowEditor<EntityConfigFlowNode, EntityConfigFlowNodeType>
                nodeTypes={nodeTypes}
                nodes={nodes}
                setNodes={setNodes}
                onNodesChange={onNodesChange}
                onNodesDelete={onNodesDeleted}
                edges={edges}
                setEdges={setEdges}
                onEdgesChange={onEdgesChange}
                onEdgesDelete={onEdgesDeleted}
                onNodeCollapseToggle={onCollapseNodeToggle}
                onNodeDeleteClick={onNodeDeleted}
                getDefaultNodeState={getDefaultNodeState}
                allowMultipleTargetNodes={true}
                allowIntraNodeConnections={true}
                dragForNewNode={false}
                onConnectionAdded={onConnectionAdded}
            />
        </div>
    )
}

export default EntityConfigDiagram

const defaultEntityNodeState: EntityFlowNode = {
    type: "entity",
    reference: uuid(),
    displayName: "New Entity",
    description: "",
    fields: [],
    parents: [],
    x: 0,
    y: 0,
    relationshipFields: [],
    collapsed: false
}

const defaultCalcNodeState: CalculationFlowNode = {
    type: "calculation",
    reference: uuid(),
    displayName: "New Entity",
    description: "",
    targetField: "",
    x: 0,
    y: 0,
    collapsed: false,
    calculations: []
}

const getDefaultNodeState = (type: "entity" | "calculation", position: XYPosition = { x: 0, y: 0 }) => {
    if (type === "calculation") return { ...defaultCalcNodeState, x: position.x, y: position.y }
    return { ...defaultEntityNodeState, x: position.x, y: position.y }
}

const customCalculationEdge: CustomEdge = {
    style: { strokeWidth: 4, strokeDasharray: "5,5" }
}

const nodeTypes = {
    Node: (props: {
        data: {
            state: EntityConfigFlowNode
            onNodeCollapseToggle: (reference: string) => void
            onNodeDeleteClick: (reference: string) => void
        }
    }) => {
        const isIntraEntityCalculation = props.data.state.type === "calculation" && props.data.state.calculations.every(c => c.type === "intra")
        const showInputHandle = (label: string) =>
            !(
                props.data.state.type === "calculation" &&
                props.data.state.calculations.find(c => c.type === "intra" && c.label === label)?.hardcodedValue !== undefined
            )
        return (
            <ReactFlowNode
                state={props.data.state}
                colour={props.data.state.type === "entity" ? "primary" : isIntraEntityCalculation ? "warning" : "success"}
                showMainInputHandle={props.data.state.type === "entity" || props.data.state.collapsed}
                showMainOutputHandle={props.data.state.type === "calculation" || props.data.state.collapsed}
                showInputHandles={showInputHandle}
                showOutputHandles={props.data.state.type === "entity"}
                showDeleteButton={false}
                showCollapseButton={true}
                swapHandles={isIntraEntityCalculation}
                outputs={
                    props.data.state.type === "entity"
                        ? props.data.state.fields
                              .filter(f => isValidEntityConfigFieldToDisplay(f.fieldName))
                              .map(f => ({ reference: f.fieldName, displayName: `(${capitaliseFirstLetter(f.dataType.type.toLowerCase())}) ` + f.displayName }))
                        : props.data.state.calculations.map(c => ({ reference: c.ref, displayName: c.label }))
                }
                relationshipFields={
                    props.data.state.type === "entity"
                        ? props.data.state.relationshipFields.concat(
                              ...(props.data.state.collapsed ? props.data.state.fields.filter(f => f.intraEntityCalculation).map(f => f.fieldName) : [])
                          )
                        : props.data.state.calculations.filter(c => c.hardcodedValue === undefined).map(c => c.ref + "-left")
                }
                onNodeCollapseToggle={props.data.onNodeCollapseToggle}
                onNodeDeleteClick={props.data.onNodeDeleteClick}
            />
        )
    }
}
