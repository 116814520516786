import DashboardConfigs from "../../../microfrontends/dashboard/components/dashboardConfigs/DashboardConfigs"

const Dashboards = () => {
    return (
        <div className="d-flex w-100 h-100">
            <DashboardConfigs />
        </div>
    )
}

export default Dashboards