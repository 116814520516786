import { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useClient } from 'invevo-react-components'
import ContactCard from './ContactCard'
import { webApiInterface } from '../../../legacy/api/webApiInterface';
import { getApiUrl } from '../../../legacy/constants';
import { Loading } from 'invevo-react-components'

type Contact = {
    id: string,
    contactId: string,
    name: string,
    phoneNumber: string,
    type: string,
    role: string
}

type ContactFromApi = {
    ContactId: string,
    Name: string,
    Phone: string,
    Mobile: string,
    Type: number,
    Role: string
}

type VoipContactsProps = {
    selectedAccountId: string,
    entitySqlId?: string,
    onSelectContact: (phoneNumber: string, contactId?: string) => void
}


const VoipContacts = ({ selectedAccountId, entitySqlId, onSelectContact }: VoipContactsProps) => {
    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [customerContacts, setCustomerContacts] = useState<Contact[]>([])
    const [internalContacts, setInternalContacts] = useState<Contact[]>([])

    const client = useClient()
    const dispatch = useDispatch()

    useEffect(() => {
        if (isFetching || hasFetched) {
            return
        }

        const idToUse = entitySqlId ?? selectedAccountId
        const getCustomerContacts = webApiInterface
            .authFetch(client, `${getApiUrl(client)}api/grid/contacts?customerid=${idToUse}&contactType=2`, dispatch)
            .then(response => response.json())
            .then(jsonResponse => setCustomerContacts(contactsList(jsonResponse.Data)))
            .catch(error => console.error(error))

        const getInternalContacts = webApiInterface
            .authFetch(client, `${getApiUrl(client)}api/grid/contacts?customerid=${idToUse}&contactType=1`, dispatch)
            .then(response => response.json())
            .then(jsonResponse => setInternalContacts(contactsList(jsonResponse.Data)))
            .catch(error => console.error(error))

        setIsFetching(true)
        Promise.all([getCustomerContacts, getInternalContacts])
            .then(() => {
                setHasFetched(true)
                setIsFetching(false)
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    setHasFetched(true)
                    setIsFetching(false)
                } else {
                    console.error(error)
                }
            })

    }, [client, selectedAccountId, entitySqlId, hasFetched, isFetching, dispatch])

    return (
        <div className="d-flex flex-column w-100">
            <Loading isLoading={isFetching} colour="white">
                {customerContacts.length > 0 &&
                    <>
                        <h3 className='text-white ms-3 mt-4 mb-3'>Customer Contacts</h3>
                        <div className="d-flex flex-row justify-content-between flex-wrap mx-4">
                            {customerContacts.map(contact => {
                                return (
                                    <ContactCard key={contact.id}
                                        name={contact.name}
                                        phoneNumber={contact.phoneNumber}
                                        contactId={contact.id}
                                        contactType={contact.type}
                                        contactRole={contact.role}
                                        onContactSelected={onSelectContact}
                                    />
                                )
                            })}
                        </div>
                    </>
                }
                {internalContacts.length > 0 &&
                    <>
                        <h3 className='text-white ms-3 mt-4 mb-3'>Internal Contacts</h3>
                        <div className="d-flex flex-row justify-content-between flex-wrap mx-4">
                            {internalContacts.map(contact => {
                                return (
                                    <ContactCard
                                        name={contact.name}
                                        phoneNumber={contact.phoneNumber}
                                        contactId={contact.id}
                                        contactType={contact.type}
                                        contactRole={contact.role}
                                        onContactSelected={onSelectContact}
                                    />
                                )
                            })}
                        </div>
                    </>
                }
            </Loading>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        selectedAccountId: state.currentSelectionReducer.selectedAccount.miaAccountId
    };
};

export default connect(mapStateToProps)(VoipContacts);

export const contactsList = (contacts: ContactFromApi[]): Contact[] => {
    if (!contacts || contacts.length === 0) return []

    const phoneContacts = contacts.filter(contact => contact.Phone !== "" && contact.Phone !== null).map(contact => {
        return {
            id: `phone-${contact.ContactId}`,
            contactId: contact.ContactId,
            name: contact.Name,
            role: contact.Role,
            phoneNumber: contact.Phone,
            type: "Phone"
        }
    })

    const mobileContacts = contacts.filter(contact => contact.Mobile !== "" && contact.Mobile !== null).map(contact => {
        return {
            id: `mobile-${contact.ContactId}`,
            contactId: contact.ContactId,
            name: contact.Name,
            role: contact.Role,
            phoneNumber: contact.Mobile,
            type: "Mobile"
        }
    })
    return phoneContacts.concat(mobileContacts)
};