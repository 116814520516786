import React from 'react';
import { connect } from 'react-redux';
import Nav from '../../api/navApi';
import { dueDateMessage } from '../../utility/dueDateMessage';
import { IconButton } from '../common/buttons/iconButton';

const TaskCard = ({ dataItem, onClick, navTo }) => {
  const isComplete = dataItem.TaskCompletionId > 0;

  const handleClick = (e) => onClick({ ...e, dataItem });

  const onTransactionClick = () => {
    navTo.Transactions();
  };

  const onDocumentClick = () => {
    navTo.Documents();
  };

  const hasDocs = dataItem.HasDocuments;

  const panelClass = dataItem.selected
    ? 'result-selected mia-action'
    : 'result-default mia-action';

  return (
    <td className={panelClass} onClick={handleClick}>
      {
        <div className="container-fluid no-tr-grid">
          <div className="row no-tr-grid">
            <div className="col-12 top15">
              <h5>
                #{dataItem.Id}
                <span className="float-right">
                  {!isComplete && (
                    <IconButton
                      onClick={handleClick}
                      title="Edit"
                      icon="edit"
                      className="btn btn-link mt-n2 mr-n3"
                    />
                  )}
                </span>
              </h5>
              <h4>{dataItem.TaskType}</h4>
              {dataItem.TaskDescription !== dataItem.TaskType && (
                <h5>{dataItem.TaskDescription}</h5>
              )}
              {isComplete && <h5>{dataItem.TaskCompletion}</h5>}
            </div>
            <div className="col-4">
              <div className="btn-group ml-n3">
                {hasDocs &&
                  (
                  <div>
                    <button
                      className="btn btn-link"
                      title="Documents"
                      onClick={onDocumentClick}
                    >
                      {dataItem.DocumentCount} <i className="fa-solid fa-file-alt" />
                    </button>
                  </div>
                )}

                {dataItem.TaskTransactionRefs &&
                  dataItem.TaskTransactionRefs.length > 0 && (
                    <button
                      className="btn btn-link"
                      title="Transactions"
                      onClick={onTransactionClick}
                    >
                    {dataItem.TaskTransactionRefs.length}{' '}
                    <i className={`fa-solid fa-bars`}></i>
                    </button>
                  )}
              </div>
            </div>

            {isComplete && (
              <div className="col-8">
                <div className="float-right">
                  <span>
                    <i className={`fa-solid fa-check-circle mia-success`}></i>
                    {' '}
                    Completed by {dataItem.CompletedUserName}
                  </span>
                </div>
              </div>
            )}
            {!isComplete && (
              <div className="col-8 shout align-self-center">
                <div className="float-right">
                  <h4 className="mb-0">{dueDateMessage(dataItem.TaskDate)}</h4>
                </div>
              </div>
            )}
          </div>
        </div>
      }
    </td>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    navTo: {
      Transactions: Nav.Workspace.RightPane('task', 'transactions'),
      Documents: Nav.Workspace.RightPane('task', 'files')
    }
  };
};

export default connect(null, mapDispatchToProps)(TaskCard);
