import { useEffect, useRef } from "react"
import classes from "./Input.module.scss"

type TextboxProps = {
    className?: string
    placeholder?: string
    value: string
    onChange?: (value: string) => void
    isValid?: boolean
    disabled?: boolean
    ariaLabel?: string
}

const Textbox = ({ className, placeholder, value, onChange, isValid = true, disabled = false, ariaLabel = "" }: TextboxProps) => {
    const ref = useRef<HTMLTextAreaElement | null>(null)

    const updateHeight = () => {
        const element = ref.current
        if (!element) return
        element.style.height = element.scrollHeight + 2 + "px"
    }

    const onInputChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange && onChange(e.target.value)
        updateHeight()
    }

    useEffect(() => {
        updateHeight()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={`d-flex flex-column ${className ? className : ""}`} role="form">
            <div className="d-flex w-100 position-relative">
                <textarea
                    ref={ref}
                    placeholder={placeholder}
                    disabled={disabled}
                    className={`form-control ${classes.input} ${isValid ? "" : `${classes.invalid}`} ${classes.textbox}`}
                    value={value}
                    onChange={onInputChanged}
                    aria-label={ariaLabel}
                />
            </div>
        </div>
    )
}

export default Textbox
