import { useState } from "react"
import AutoCompleteDropdown from "../../../library/dropdowns/AutoCompleteDropdown"
import Dropdown from "../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../types/DropdownOptions"
import EntityConfig from "../types/EntityConfig"
import EntityDataField from "../types/EntityDataField"
import EntityRelationshipType from "../types/EntityRelationship"

type EntityRelationshipProps = {
    entityConfigs: EntityConfig[]
    relationship: EntityRelationshipType
    onRelationshipUpdated: (relationship: EntityRelationshipType) => void
    onRelationshipDeleted: (relationship: EntityRelationshipType) => void
}

const EntityRelationship = ({ entityConfigs, relationship, onRelationshipUpdated, onRelationshipDeleted }: EntityRelationshipProps) => {
    const [isChildOf, setIsChildOf] = useState<boolean>(false)

    const selectedChildConfig = entityConfigs.find(config => config.reference === relationship.childEntityReference)
    const selectedParentConfig = entityConfigs.find(config => config.reference === relationship.parentEntityReference)

    const fields = selectedChildConfig?.fields ?? []
    const selectedField = fields.find(field => field.fieldName === relationship.groupingFieldName)

    const onFieldSelected = (selectedField: DropdownOption<string> | undefined) =>
        onRelationshipUpdated({
            ...relationship,
            groupingFieldName: selectedField?.value ?? ""
        })

    const onDeleted = () => onRelationshipDeleted(relationship)

    const onChildParentDropdownUpdated = (updatedIsChildOf: DropdownOption<boolean>) => {
        if (updatedIsChildOf.value === undefined || updatedIsChildOf.value === isChildOf) return

        setIsChildOf(updatedIsChildOf.value)
        onRelationshipUpdated({
            ...relationship,
            childEntityReference: relationship.parentEntityReference,
            parentEntityReference: relationship.childEntityReference
        })
    }

    const firstEntityDisplayName = isChildOf ? selectedChildConfig?.displayName : selectedParentConfig?.displayName
    const secondEntityDisplayName = isChildOf ? selectedParentConfig?.displayName : selectedChildConfig?.displayName

    return (
        <div className="d-flex align-items-center">
            <span className="text-uppercase me-2 text-grey no-select">
                <span className="fw-bold"> {firstEntityDisplayName ?? "Unknown Entity"} </span>is a
            </span>
            <Dropdown
                className="me-2"
                options={childParentDropdownOptions}
                onOptionSelected={onChildParentDropdownUpdated}
                selectedOption={childParentDropdownOptions.find(option => option.value === isChildOf)}
                textAlign="left"
                ariaLabel="child-or-parent-dropdown"
            />
            <span className="text-uppercase me-2 text-grey no-select">
                of<span className="fw-bold"> {secondEntityDisplayName ?? "Unknown Entity"} </span>Grouped by field
            </span>
            <AutoCompleteDropdown
                className="me-3"
                options={fields.map(fieldToDropdownOption)}
                onOptionSelected={onFieldSelected}
                selectedOption={selectedField ? fieldToDropdownOption(selectedField) : undefined}
                ariaLabel="entity-config-child-grouping-field"
            />
            <div className="ms-auto ps-2 me-3 border-end h-100" />
            <div role="button" className="px-2" onClick={onDeleted} aria-label="parent-remove">
                <i className="fal fa-trash-alt" />
            </div>
        </div>
    )
}

const fieldToDropdownOption = (config: EntityDataField) => ({
    label: config.displayName,
    value: config.fieldName
})

const childParentDropdownOptions = [
    {
        label: "Child",
        value: true
    },
    {
        label: "Parent",
        value: false
    }
]

export default EntityRelationship
