import update from 'immutability-helper';

const mappingReducer = (state = { selectedTableId: -1 }, action) => {
  switch (action.type) {
    case 'SELECT_TABLE':
    {
      return update(
        state,
        {
          selectedTableId: {$set: action.selectedTableId}
        });
    }
    default:
    {
      return state;
    }
  }
};

export default mappingReducer;
