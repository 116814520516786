import moment from "moment"
import GenericFilter from "./filterTypes/GenericFilter"
import FilterOperators from "./FilterOperators"
import DataPrimitive from "../../types/DataPrimitive"
import FilterTypesByField from "./FilterTypesByField"
import NumberFieldMatchesOperationFilter from "./filterTypes/NumberFieldMatchesOperationFilter"
import DateFieldIsOneOfFilter from "./filterTypes/DateFieldIsOneOfFilter"
import DateFieldMatchesOperationFilter from "./filterTypes/DateFieldMatchesOperationFilter"
import TextFieldStartsWithFilter from "./filterTypes/TextFieldStartsWithFilter"
import TextFieldIsOneOfFilter from "./filterTypes/TextFieldIsOneOfFilter"
import LookupFieldIsOneOfFilter from "./filterTypes/LookupFieldIsOneOfFilter"
import { isValidDate, isValidNumber } from "../helpers"
import { Relativity } from "./filterTypes/DateFieldRelativeFilter"
import TextFieldIsOneOfFieldFilter from "./filterTypes/TextFieldIsOneOfFieldFilter"
import LookupFieldIsOneOfFieldFilter from "./filterTypes/LookupFieldIsOneOfFieldFilter"
import BooleanFieldIsOneOfFieldFilter from "./filterTypes/BooleanFieldIsOneOfFieldFilter"
import NumberFieldIsOneOfFieldFilter from "./filterTypes/NumberFieldIsOneOfFieldFilter"
import DateFieldIsOneOfFieldFilter from "./filterTypes/DateFieldIsOneOfFieldFilter"

export const isFilterValid = (filter: GenericFilter): boolean => {
    switch (filter.type) {
        case FilterTypesByField.TEXT_FIELD_IS_ONE_OF:
            const textFieldIsOneOf = filter as TextFieldIsOneOfFilter
            return textFieldIsOneOf.values.length > 0
        case FilterTypesByField.TEXT_FIELD_STARTS_WITH:
            const textFieldStartsWith = filter as TextFieldStartsWithFilter
            return textFieldStartsWith.values.every(v => v !== "")
        case FilterTypesByField.TEXT_FIELD_IS_ONE_OF_FIELD:
            const textFieldIsOneOfField = filter as TextFieldIsOneOfFieldFilter
            return textFieldIsOneOfField.values.length > 0
        case FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF:
            const lookupFieldIsOneOf = filter as LookupFieldIsOneOfFilter
            return lookupFieldIsOneOf.values.length > 0
        case FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF_FIELD:
            const lookupFieldIsOneOfField = filter as LookupFieldIsOneOfFieldFilter
            return lookupFieldIsOneOfField.values.length > 0
        case FilterTypesByField.BOOLEAN_FIELD_IS_EQUAL_TO:
            return true
        case FilterTypesByField.BOOLEAN_FIELD_IS_ONE_OF_FIELD:
            const booleanFieldIsOneOfField = filter as BooleanFieldIsOneOfFieldFilter
            return booleanFieldIsOneOfField.values.length > 0
        case FilterTypesByField.NUMBER_FIELD_MATCHES_OPERATION:
            const numberFieldMatchesOperation = filter as NumberFieldMatchesOperationFilter
            return numberFieldMatchesOperation.values.every(
                v =>
                    (v.greaterThan === undefined || isValidNumber(v.greaterThan)) &&
                    (v.lessThan === undefined || isValidNumber(v.lessThan)) &&
                    (v.greaterThanOrEqualTo === undefined || isValidNumber(v.greaterThanOrEqualTo)) &&
                    (v.lessThanOrEqualTo === undefined || isValidNumber(v.lessThanOrEqualTo))
            )
        case FilterTypesByField.NUMBER_FIELD_IS_ONE_OF_FIELD:
            const numberFieldIsOneOfField = filter as NumberFieldIsOneOfFieldFilter
            return numberFieldIsOneOfField.values.length > 0
        case FilterTypesByField.DATE_FIELD_IS_ONE_OF:
            const dateFieldIsOneOf = filter as DateFieldIsOneOfFilter
            return dateFieldIsOneOf.values.every(d => isValidDate(d))
        case FilterTypesByField.DATE_FIELD_MATCHES_OPERATION:
            const dateFieldMatchesOperation = filter as DateFieldMatchesOperationFilter
            return dateFieldMatchesOperation.values.every(
                v =>
                    (v.greaterThan === undefined || isValidDate(v.greaterThan)) &&
                    (v.lessThan === undefined || isValidDate(v.lessThan)) &&
                    (v.greaterThanOrEqualTo === undefined || isValidDate(v.greaterThanOrEqualTo)) &&
                    (v.lessThanOrEqualTo === undefined || isValidDate(v.lessThanOrEqualTo))
            )
        case FilterTypesByField.DATE_FIELD_IS_ONE_OF_FIELD:
            const dateFieldIsOneOfField = filter as DateFieldIsOneOfFieldFilter
            return dateFieldIsOneOfField.values.length > 0
        case FilterTypesByField.FIELD_EXISTS:
            return true
        case FilterTypesByField.NO_TYPE:
        default:
            return false
    }
}

export const convertFilterToNewOperator = (filter: GenericFilter, operator: FilterOperators, fieldType: DataPrimitive): GenericFilter => {
    const newType = getFilterForFilterOperator(operator, fieldType)

    switch (newType) {
        case FilterTypesByField.TEXT_FIELD_IS_ONE_OF:
            return {
                type: FilterTypesByField.TEXT_FIELD_IS_ONE_OF,
                id: filter.id,
                fieldName: filter.fieldName,
                values: filter.type === FilterTypesByField.TEXT_FIELD_IS_ONE_OF ? filter.values : [],
                notOneOf: operator === FilterOperators.NOT_ONE_OF
            }
        case FilterTypesByField.TEXT_FIELD_STARTS_WITH:
            return {
                type: FilterTypesByField.TEXT_FIELD_STARTS_WITH,
                id: filter.id,
                fieldName: filter.fieldName,
                values: filter.type === FilterTypesByField.TEXT_FIELD_STARTS_WITH ? filter.values : []
            }
        case FilterTypesByField.TEXT_FIELD_IS_ONE_OF_FIELD:
            return {
                type: FilterTypesByField.TEXT_FIELD_IS_ONE_OF_FIELD,
                id: filter.id,
                fieldName: filter.fieldName,
                values: filter.type === FilterTypesByField.TEXT_FIELD_IS_ONE_OF_FIELD ? filter.values : [],
                notOneOf: operator === FilterOperators.NOT_ONE_OF_FIELD
            }
        case FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF:
            return {
                type: FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF,
                id: filter.id,
                fieldName: filter.fieldName,
                values: filter.type === FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF ? filter.values : [],
                notOneOf: operator === FilterOperators.NOT_ONE_OF
            }
        case FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF_FIELD:
            return {
                type: FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF_FIELD,
                id: filter.id,
                fieldName: filter.fieldName,
                values: filter.type === FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF_FIELD ? filter.values : [],
                notOneOf: operator === FilterOperators.NOT_ONE_OF_FIELD
            }
        case FilterTypesByField.BOOLEAN_FIELD_IS_EQUAL_TO:
            return {
                type: FilterTypesByField.BOOLEAN_FIELD_IS_EQUAL_TO,
                id: filter.id,
                fieldName: filter.fieldName,
                equalTo: operator === FilterOperators.TRUE
            }
        case FilterTypesByField.BOOLEAN_FIELD_IS_ONE_OF_FIELD:
            return {
                type: FilterTypesByField.BOOLEAN_FIELD_IS_ONE_OF_FIELD,
                id: filter.id,
                fieldName: filter.fieldName,
                values: filter.type === FilterTypesByField.BOOLEAN_FIELD_IS_ONE_OF_FIELD ? filter.values : [],
                notOneOf: operator === FilterOperators.NOT_ONE_OF_FIELD
            }
        case FilterTypesByField.NUMBER_FIELD_MATCHES_OPERATION:
            const numberOperatorText = getOperationFieldFromOperator(operator)
            return {
                type: FilterTypesByField.NUMBER_FIELD_MATCHES_OPERATION,
                id: filter.id,
                fieldName: filter.fieldName,
                values: numberOperatorText === undefined ? [] : [{ [numberOperatorText]: "0" }]
            }
        case FilterTypesByField.NUMBER_FIELD_IS_ONE_OF:
            return {
                type: FilterTypesByField.NUMBER_FIELD_IS_ONE_OF,
                id: filter.id,
                fieldName: filter.fieldName,
                values: filter.type === FilterTypesByField.NUMBER_FIELD_IS_ONE_OF ? filter.values : [],
                notOneOf: operator === FilterOperators.NOT_ONE_OF
            }
        case FilterTypesByField.NUMBER_FIELD_IS_ONE_OF_FIELD:
            return {

                type: FilterTypesByField.NUMBER_FIELD_IS_ONE_OF_FIELD,
                id: filter.id,
                fieldName: filter.fieldName,
                values: filter.type === FilterTypesByField.NUMBER_FIELD_IS_ONE_OF_FIELD ? filter.values : [],
                notOneOf: operator === FilterOperators.NOT_ONE_OF_FIELD
            }
        case FilterTypesByField.DATE_FIELD_IS_ONE_OF:
            return {
                type: FilterTypesByField.DATE_FIELD_IS_ONE_OF,
                id: filter.id,
                fieldName: filter.fieldName,
                values: [moment().startOf("day").format()],
                notOneOf: operator !== FilterOperators.EQUAL_TO
            }
        case FilterTypesByField.DATE_FIELD_MATCHES_OPERATION:
            const dateOperatorText = getOperationFieldFromOperator(operator)
            return {
                type: FilterTypesByField.DATE_FIELD_MATCHES_OPERATION,
                id: filter.id,
                fieldName: filter.fieldName,
                values: dateOperatorText === undefined ? [] : [{ [dateOperatorText]: moment().startOf("day").format() }]
            }
        case FilterTypesByField.DATE_FIELD_RELATIVE:
            return {
                type: FilterTypesByField.DATE_FIELD_RELATIVE,
                id: filter.id,
                fieldName: filter.fieldName,
                numberOfDays: 0,
                isBefore: false,
                relativeToDate: moment().startOf("day").format(),
                isRelativeToToday: false,
                referenceFieldName: undefined,
                relativity: relativeDateOperatorToRelativity(operator)
            }
        case FilterTypesByField.DATE_FIELD_IS_ONE_OF_FIELD:
            return {
                type: FilterTypesByField.DATE_FIELD_IS_ONE_OF_FIELD,
                id: filter.id,
                fieldName: filter.fieldName,
                values: filter.type === FilterTypesByField.DATE_FIELD_IS_ONE_OF_FIELD ? filter.values : [],
                notOneOf: operator === FilterOperators.NOT_ONE_OF_FIELD
            }
        case FilterTypesByField.FIELD_EXISTS:
            return {
                type: FilterTypesByField.FIELD_EXISTS,
                id: filter.id,
                fieldName: filter.fieldName,
                dataPrimitive: fieldType,
                notExists: operator === FilterOperators.NOT_EXISTS
            }
        case FilterTypesByField.NO_TYPE:
        default:
            return {
                type: FilterTypesByField.NO_TYPE,
                id: filter.id,
                fieldName: filter.fieldName
            }
    }
}

const getFilterForFilterOperator = (operation: FilterOperators, fieldType: DataPrimitive): FilterTypesByField => {
    switch (operation) {
        case FilterOperators.TRUE:
        case FilterOperators.FALSE:
            return FilterTypesByField.BOOLEAN_FIELD_IS_EQUAL_TO
        case FilterOperators.EXISTS:
        case FilterOperators.NOT_EXISTS:
            return FilterTypesByField.FIELD_EXISTS
        case FilterOperators.ONE_OF:
        case FilterOperators.NOT_ONE_OF:
            switch (fieldType) {
                case DataPrimitive.TEXT:
                    return FilterTypesByField.TEXT_FIELD_IS_ONE_OF
                case DataPrimitive.LOOKUP:
                    return FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF
                case DataPrimitive.NUMBER:
                    return FilterTypesByField.NUMBER_FIELD_IS_ONE_OF
                case DataPrimitive.DATE:
                    return FilterTypesByField.DATE_FIELD_MATCHES_OPERATION
                case DataPrimitive.BOOLEAN:
                    return FilterTypesByField.BOOLEAN_FIELD_IS_EQUAL_TO
            }
            break
        case FilterOperators.ONE_OF_FIELD:
        case FilterOperators.NOT_ONE_OF_FIELD:
            switch (fieldType) {
                case DataPrimitive.TEXT:
                    return FilterTypesByField.TEXT_FIELD_IS_ONE_OF_FIELD
                case DataPrimitive.LOOKUP:
                    return FilterTypesByField.LOOKUP_FIELD_IS_ONE_OF_FIELD
                case DataPrimitive.NUMBER:
                    return FilterTypesByField.NUMBER_FIELD_IS_ONE_OF_FIELD
                case DataPrimitive.DATE:
                    return FilterTypesByField.DATE_FIELD_IS_ONE_OF_FIELD
                case DataPrimitive.BOOLEAN:
                    return FilterTypesByField.BOOLEAN_FIELD_IS_ONE_OF_FIELD
            }
            break
        case FilterOperators.STARTS_WITH:
            return FilterTypesByField.TEXT_FIELD_STARTS_WITH
        case FilterOperators.EQUAL_TO:
            return fieldType === DataPrimitive.NUMBER ? FilterTypesByField.NUMBER_FIELD_MATCHES_OPERATION : FilterTypesByField.DATE_FIELD_IS_ONE_OF
        case FilterOperators.GREATER_THAN:
        case FilterOperators.LESS_THAN:
        case FilterOperators.GREATER_THAN_OR_EQUAL_TO:
        case FilterOperators.LESS_THAN_OR_EQUAL_TO:
            return fieldType === DataPrimitive.NUMBER ? FilterTypesByField.NUMBER_FIELD_MATCHES_OPERATION : FilterTypesByField.DATE_FIELD_MATCHES_OPERATION
        case FilterOperators.AT_MOST:
        case FilterOperators.AT_LEAST:
        case FilterOperators.EXACTLY:
            return FilterTypesByField.DATE_FIELD_RELATIVE
    }
    return FilterTypesByField.NO_TYPE
}

export const getOperationFieldFromOperator = (operator: FilterOperators) => {
    switch (operator) {
        case FilterOperators.GREATER_THAN:
            return "greaterThan"
        case FilterOperators.LESS_THAN:
            return "lessThan"
        case FilterOperators.GREATER_THAN_OR_EQUAL_TO:
            return "greaterThanOrEqualTo"
        case FilterOperators.LESS_THAN_OR_EQUAL_TO:
            return "lessThanOrEqualTo"
        default:
            return undefined
    }
}

const relativeDateOperatorToRelativity = (operator: FilterOperators): Relativity => {
    switch (operator) {
        case FilterOperators.AT_MOST:
            return "AT_MOST"
        case FilterOperators.AT_LEAST:
            return "AT_LEAST"
        case FilterOperators.EXACTLY:
            return "EXACTLY"
        default:
            return "AT_MOST"
    }
}
