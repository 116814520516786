import { ReactNode } from "react"
import classes from "./LegacyReactFlowNode.module.scss"
import { Handle, Position } from "reactflow"

type ReactFlowNodeProps = {
    children: ReactNode
}

const ReactFlowNode = ({ children }: ReactFlowNodeProps) => {
    return (
        <div className={`rounded-1 ${classes.node}`}>
            <Handle className={`${classes.customHandle} ${classes.zeroSizeHandle}`} position={Position.Right} type="target" />
            <Handle className={`${classes.customHandle} ${classes.zeroSizeHandle}`} position={Position.Right} type="source" />
            {children}
        </div>
    )
}

export default ReactFlowNode
