export enum widgetTypes {
    INFORMATION = "INFORMATION",
    VALUE = "VALUE",
    TRANSACTIONS_GRID = "TRANSACTIONS_GRID",
    GRID = "GRID",
    SMALL_BAR_CHART = "SMALL_BAR_CHART",
    AREA_CHART = "AREA_CHART",
    LINE_CHART = "LINE_CHART",
    SMALL_PIE_CHART = "SMALL_PIE_CHART",
    ENTITY_CHART_WIDGET = "ENTITY_CHART_WIDGET",
    ENTITY_TRENDING = "ENTITY_TRENDING"
}
