import update from 'immutability-helper';

const initialState = {
  errMsg: '',
  msg: '',
  isLoading: false,
  notes: '',
  transactionLineNotes: '',
  reportingNotes: '',
  reportingContact: '',
  formInputsCanSave: false,
  followUpDate: null,
  ptpDate: null,
  statusId: null,
  subStatusId: null,
  contactId: null,
  transactionIds: [],
  transactionRefs: [],
  transactionLineIds: [],
  allTransactionsSelected: false,
  customerContactId: null,
  customerContactEmail: '',
  internalContactId: null,
  internalContactEmail: '',
  statusSubStatus: null,
  transactionLineStatusId: null,
  transactionLineSubStatusId: null,
  transactionLineStatusSubStatus: null,
  transactionsAggregate: { Count: 0, CurrencyCodes: [] },
  customerContact: {},
  noteSaved: false,
  groupId: null,
  customerGridId: null,
  isSelected: false,
  foundGroupIdOrNull: false,
  documents: [],
  customerContacts: [],
  internalContacts: [],
  replyToEmail: {}
};
const functionReducer = (state = initialState, action) => {
  switch (action.type) {
    /////////////////////////
    // StaticNotesByCustomerId
    /////////////////////////
    case 'REQUEST_STATIC_NOTES_BY_CUSTOMERID': {
      return update(state, {
        errMsg: { $set: '' },
        isLoading: { $set: true }
      });
    }
    case 'RECEIVE_STATIC_NOTES_BY_CUSTOMERID': {
      return update(state, {
        errMsg: { $set: '' },
        isLoading: { $set: false },
        notes: { $set: action.data }
      });
    }
    case 'ERROR_STATIC_NOTES_BY_CUSTOMERID': {
      return update(state, {
        errMsg: { $set: action.err },
        isLoading: { $set: false },
        notes: { $set: null }
      });
    }
    /////////////////////////
    // SaveStaticNote
    /////////////////////////
    case 'REQUEST_SAVE_STATIC_NOTE': {
      return update(state, {
        errMsg: { $set: '' },
        isLoading: { $set: true }
      });
    }
    case 'RECEIVE_SAVE_STATIC_NOTE': {
      return update(state, {
        errMsg: { $set: '' },
        isLoading: { $set: false }
      });
    }
    case 'ERROR_SAVE_STATIC_NOTE': {
      return update(state, {
        errMsg: { $set: action.err },
        isLoading: { $set: false }
      });
    }
    case 'REQUEST_DELETE_STATIC_NOTE': {
      return update(state, {
        errMsg: { $set: '' },
        isLoading: { $set: true }
      });
    }
    case 'RECEIVE_DELETE_STATIC_NOTE': {
      return update(state, {
        errMsg: { $set: '' },
        isLoading: { $set: false }
      });
    }
    case 'ERROR_DELETE_STATIC_NOTE': {
      return update(state, {
        errMsg: { $set: action.err },
        isLoading: { $set: false }
      });
    }
    /////////////////////////
    // Transaction Line SaveNote
    /////////////////////////
    case 'REQUEST_TRANSACTION_LINE_SAVE_NOTE': {
      return update(state, {
        errMsg: { $set: '' },
        msg: { $set: 'LineItemUpdating' },
        isLoading: { $set: true }
      });
    }
    case 'RECEIVE_TRANSACTION_LINE_SAVE_NOTE': {
      return update(state, {
        errMsg: { $set: '' },
        msg: { $set: 'LineItemsUpdated' },
        isLoading: { $set: false },
        noteSaved: { $apply: (x) => !x }
      });
    }
    case 'ERROR_TRANSACTION_LINE_SAVE_NOTE': {
      return update(state, {
        errMsg: { $set: action.err },
        msg: { $set: '' },
        isLoading: { $set: false }
      });
    }
    /////////////////////////
    // SaveNote
    /////////////////////////
    case 'REQUEST_SAVE_NOTE': {
      return update(state, {
        errMsg: { $set: '' },
        msg: { $set: 'saving...' },
        isLoading: { $set: true }
      });
    }
    case 'RECEIVE_SAVE_NOTE': {
      return update(state, {
        errMsg: { $set: '' },
        msg: { $set: 'saved' },
        isLoading: { $set: false },
        noteSaved: { $apply: (x) => !x }
      });
    }
    case 'ERROR_SAVE_NOTE': {
      return update(state, {
        errMsg: { $set: action.err },
        msg: { $set: '' },
        isLoading: { $set: false }
      });
    }
    case 'FOLLOW_UP_SAVED': {
      return update(state, {
        msg: { $set: 'follow up saved' }
      });
    }
    case 'TASK_SAVED': {
      return update(state, {
        msg: { $set: 'task saved' }
      });
    }
    /////////////////////////
    // Select/Unselect Transaction
    /////////////////////////
    case 'SELECT_TRANSACTION': {
      return update(state, {
        transactionIds: { $set: action.transactionIds },
        customerGridId: { $set: null },
        msg: { $set: '' }
      });
    }
    case 'SELECT_TRANSACTION_REFS': {
      return update(state, {
        transactionRefs: { $set: action.transactionRefs },
        customerGridId: { $set: null },
        msg: { $set: '' }
      });
    }
    case 'SELECT_TRANSACTION_LINE': {
      return update(state, {
        transactionLineIds: { $set: action.transactionLineIds },
        msg: { $set: '' }
      });
    }
    case 'RECEIVE_TRANSACTIONS_AGGREGATE': {
      return update(state, {
        transactionsAggregate: { $set: action.data }
      });
    }
    case 'RESET_AGGREGATE': {
      return update(state, {
        transactionsAggregate: { $set: { Count: 0, CurrencyCodes: [] } }
      });
    }
    case 'SELECT_ALL_TRANSACTIONS': {
      return update(state, {
        allTransactionsSelected: { $set: action.allTransactionsSelected },
        msg: { $set: '' }
      });
    }
    /////////////////////////
    // Select Contact
    /////////////////////////
    case 'SELECT_CUSTOMER_CONTACT': {
      return update(state, {
        customerContactId: { $set: action.id },
        customerContactEmail: { $set: action.email },
        customerContactAddressId: { $set: action.addressId },
        msg: { $set: '' }
      });
    }
    case 'SELECT_INTERNAL_CONTACT': {
      return update(state, {
        internalContactId: { $set: action.id },
        internalCntactEmail: { $set: action.email },
        msg: { $set: '' }
      });
    }
    /////////////////////////
    // Followup Date
    /////////////////////////
    case 'SET_FOLLOWUP_DATE': {
      const fud =
        action.followUpDate === 'Invalid date' ? null : action.followUpDate;
      return update(state, {
        followUpDate: { $set: fud },
        msg: { $set: '' }
      });
    }
    case 'SET_TRANSACTION_NOTE_PARAMETERS': {
      const fud =
        action.followUpDate === 'Invalid date' ? null : action.followUpDate;
      const ptp = action.ptpDate === 'Invalid date' ? null : action.ptpDate;
      return update(state, {
        msg: { $set: '' },
        followUpDate: { $set: fud },
        ptpDate: { $set: ptp },
        statusId: { $set: action.statusId },
        subStatusId: { $set: action.subStatusId },
        contactId: { $set: action.contactId }
      });
    }
    case 'SET_TRANSACTION_STATUS_NOTE_PARAMETERS': {
      const ptp =
        action.data.ptpDate === 'Invalid date' ? null : action.data.ptpDate;
      return update(state, {
        ptpDate: { $set: ptp },
        statusId: { $set: action.data.statusId },
        subStatusId: { $set: action.data.subStatusId },
        notes: { $set: action.data.notes },
        reportingNotes: { $set: action.data.reportingNotes },
        reportingContact: { $set: action.data.reportingContact },
        formInputsCanSave: { $set: action.data.formInputsCanSave }
      });
    }
    case 'SET_TRANSACTION_LINE_STATUS_NOTE_PARAMETERS': {
      return update(state, {
        transactionLineStatusId: { $set: action.data.statusId },
        transactionLineSubStatusId: { $set: action.data.subStatusId },
        transactionLineNotes: { $set: action.data.notes }
      });
    }
    case 'REQUEST_STATUS_SUBSTATUS': {
      return update(state, {
        errMsg: { $set: '' },
        isLoading: { $set: true },
        statusSubStatus: { $set: null },
        followUpDate: { $set: null },
        ptpDate: { $set: null },
        statusId: { $set: null },
        subStatusId: { $set: null }
      });
    }
    case 'RECEIVE_STATUS_SUBSTATUS': {
      return update(state, {
        errMsg: { $set: '' },
        isLoading: { $set: false },
        statusSubStatus: { $set: action.data }
      });
    }
    case 'ERROR__STATUS_SUBSTATUS': {
      return update(state, {
        errMsg: { $set: action.err },
        isLoading: { $set: false },
        statusSubStatus: { $set: null }
      });
    }
    case 'REQUEST_TRANSACTION_LINE_STATUS_SUBSTATUS': {
      return update(state, {
        errMsg: { $set: '' },
        isLoading: { $set: true },
        transactionLineStatusSubStatus: { $set: null },
        transactionLineStatusId: { $set: null },
        transactionLineSubStatusId: { $set: null }
      });
    }
    case 'RECEIVE_TRANSACTION_LINE_STATUS_SUBSTATUS': {
      return update(state, {
        errMsg: { $set: '' },
        isLoading: { $set: false },
        transactionLineStatusSubStatus: { $set: action.data }
      });
    }
    case 'ERROR_TRANSACTION_LINE_STATUS_SUBSTATUS': {
      return update(state, {
        errMsg: { $set: action.err },
        isLoading: { $set: false },
        transactionLineStatusSubStatus: { $set: null }
      });
    }
    case 'SET_GROUP_ID': {
      return update(state, {
        groupId: { $set: action.gId }
      });
    }
    case 'FOUND_MATCHING_GROUP': {
      return update(state, {
        foundGroupIdOrNull: { $set: action.foundGroupIdOrNull }
      });
    }
    case 'SET_CUSTOMER_GRID_ID': {
      return update(state, {
        groupId: { $set: null },
        transactionIds: { $set: [] },
        customerGridId: { $set: action.customerId }
      });
    }
    case 'SET_SELECTED_FILTER': {
      return update(state, {
        isSelected: { $set: action.isSelected }
      });
    }
    case 'LOAD_TRANSACTION_IDS': {
      return update(state, {
        transactionIds: { $set: action.tIds }
      });
    }
    case 'LOAD_TRANSACTION_REFS': {
      return update(state, {
        transactionRefs: { $set: action.tRefs }
      });
    }
    case 'ERROR_LOAD_TRANSACTION_IDS': {
      return state;
    }
    case 'REST_MSG':
      return update(state, {
        msg: { $set: '' }
      });
    case 'SELECT_DOCUMENT': {
      return update(state, {
        documents: { $set: action.documents }
      });
    }
    case 'SELECT_CUSTOMER_CONTACTS': {
      return update(state, {
        customerContacts: { $set: action.customerContacts }
      });
    }
    case 'SELECT_INTERNAL_CONTACTS': {
      return update(state, {
        internalContacts: { $set: action.internalContacts }
      });
    }
    case 'SELECT_REPLY_TO_EMAIL': {
      return update(state, {
        replyToEmail: { $set: action.replyToEmail }
      });
    }
    default:
      return state;
  }
};
export default functionReducer;
