import React, { useState, useEffect } from 'react';
import DocumentsGrid from './documentsGrid';
import TaskDocumentsGrid from './taskDocumentsGrid';
import AddDocument from './addDocument';
import { IconButton } from '../common/buttons/iconButton';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { useDocumentQuery } from '../../hooks/useDocumentQuery';
import { connect } from 'react-redux';
import Nav from '../../api/navApi';
import { useInvevoSession } from 'invevo-react-components';

const DocumentsPanel = ({ removeRouteQuery, selectTaskTab = false }) => {
  const [refresh, setRefresh] = useState(0);
  const [invevoSession, setInvevoSession] = useInvevoSession()

  const [showAddDoc, setShowAddDoc] = useState(false);
  const closeAddDoc = () => {
    setInvevoSession({ showAddDoc: false })
    setShowAddDoc(false)
  }
  const openAddDoc = () => setShowAddDoc(true);

  const documentQuery = useDocumentQuery();
  useEffect(() => {
    if (!showAddDoc && (documentQuery === 'new' || invevoSession.showAddDoc)) {
      setShowAddDoc(true);
    }

    if (documentQuery) {
      setSelected(0);
      removeRouteQuery('d');
    }
  }, [showAddDoc, documentQuery, removeRouteQuery, invevoSession.showAddDoc]);

  const handleAddDoc = () => {
    setRefresh((refresh) => refresh + 1);
    closeAddDoc();
  };

  const [selected, setSelected] = useState(!selectTaskTab ? 1 : 0);
  const handleSelect = (e) => setSelected(e.selected);
  const [search, setSearch] = useState('');
  const onClearSearch = () => setSearch('');
  const onChange = (e) => setSearch(e.target.value);

  return (
    <div className="container-fluid" id="documents-panel">
      <div className="row">
        <div className="col-4 top20">
          <div className="input-group">
            <input
              className="form-control"
              placeholder="Search by name"
              value={search}
              onChange={onChange}
            />
            {search === '' && (
              <IconButton
                icon={'search'}
                title="Search"
                className="input-group-text btn btn-default"
                isDisabled={true}
              />
            )}
            {search !== '' && (
              <IconButton
                icon={'times-circle'}
                onClick={onClearSearch}
                title="Clear search"
                className="input-group-text btn btn-default"
              />
            )}
          </div>
        </div>
        <div className="col-8 top20">
          <div className="d-flex h-100">
            <button
              className="btn btn-default"
              onClick={openAddDoc}
              data-cy="add-doc-btn"
            >
              <i className='fa-solid fa-plus me-2'></i>
              Documents{' '}
            </button>
          </div>
        </div>
        <div className="col-12">
          <TabStrip
            selected={selected}
            onSelect={handleSelect}
            className="material"
          >
            <TabStripTab title="Task">
              <TaskDocumentsGrid refresh={refresh} filterByText={search} />
            </TabStripTab>
            <TabStripTab title="ALL">
              <DocumentsGrid refresh={refresh} filterByText={search} />
            </TabStripTab>
          </TabStrip>
        </div>
      </div>
      {showAddDoc && (
        <AddDocument onDocumentUploaded={handleAddDoc} onCancel={closeAddDoc} />
      )}
    </div>
  );
};

export default connect(null, {
  removeRouteQuery: Nav.RemoveRouteQuery
})(DocumentsPanel);
