const UpdateChildEntityActionInfo = () => {
    return (
        <div className="d-flex align-items-center">
            <i className="fal fa-pen-field text-blue me-1 mb-1 fs-5" />
            <span className="text-black fw-bold small me-1 text-capitalize">Update Children</span>
        </div>
    )
}

export default UpdateChildEntityActionInfo
