import { AutoComplete } from "invevo-react-components"
import React from "react"
import Checkbox from "../../../../../library/form/Checkbox"
import Input from "../../../../../library/Inputs/Input"
import {
    EntityExportFieldConfigState,
    FileEntityExportConfigState,
    FileExportConfigAction,
    FileExportConfigActionEnum
} from "../../../reducers/FileEntityExportConfigReducer"
import { FileImportConfigColumnType, FileImportConfigColumnTypeEnum } from "../../../types/FileImportConfigColumnType"
import { FileFormat, delimiters, lineEndings } from "../../utils"
import classes from "./DataFileExportOptions.module.scss"

type DataFileExportOptionsProps = {
    state: FileEntityExportConfigState
    dispatch: React.Dispatch<FileExportConfigAction>
}

const DataFileExportOptions = ({ state, dispatch }: DataFileExportOptionsProps) => {
    const isXmlExport = state.fileFormat === FileFormat.XML

    const onFilenameChange = (filename: string) => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            FileEntityExportConfig: {
                ...state,
                filename
            }
        })
    }

    const onSubdirectoryPathChage = (subdirectoryPath: string) => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            FileEntityExportConfig: {
                ...state,
                subdirectoryPath: subdirectoryPath
            }
        })
    }

    const onDelimiterChange = (option: string) => {
        if (isXmlExport) {
            return
        }
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            FileEntityExportConfig: {
                ...state,
                delimiter: option
            }
        })
    }

    const onLineEndingChange = (option: string) => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            FileEntityExportConfig: {
                ...state,
                lineEnding: option
            }
        })
    }

    const onHasHeaderRowChange = () => {
        if (isXmlExport) {
            return
        }
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            FileEntityExportConfig: {
                ...state,
                hasHeaderRow: !state.hasHeaderRow,
                optionalFieldConfigs: [
                    ...state.optionalFieldConfigs.map((field: EntityExportFieldConfigState) => ({
                        ...field,
                        column: {
                            ...field.column,
                            ...(!state.hasHeaderRow
                                ? {
                                      type: FileImportConfigColumnTypeEnum.HEADER,
                                      header: ""
                                  }
                                : {
                                      type: FileImportConfigColumnTypeEnum.INDEX,
                                      index: ""
                                  })
                        } as FileImportConfigColumnType,
                        ordinal: field.ordinal
                    }))
                ]
            }
        })
    }

    const onQuoteFieldsWithChange = (quoteFieldsWith: string) => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            FileEntityExportConfig: {
                ...state,
                quoteFieldsWith
            }
        })
    }

    const onXmlRootNameChange = (name: string) => {
        if (!isXmlExport) return
        dispatch({
            type: FileExportConfigActionEnum.XML_NODE_CHANGED,
            nodeUpdated: {
                ...state.rootNodeFieldsConfig,
                name
            }
        })
    }

    const onDateFormatChange = (dateFormat: string) => {
        dispatch({
            type: FileExportConfigActionEnum.DATA_FILE_CONFIG_UPDATED,
            FileEntityExportConfig: {
                ...state,
                dateFormat
            }
        })
    }

    return (
        <div className="d-flex flex-column w-100 h-100">
            <div className="d-flex my-1 mx-3">
                <span className="text-grey fs-4">Data file configuration</span>
            </div>
            <div className={`${classes.horizontalDivider} mx-2`} />
            <div className="d-flex p-3 gap-3">
                <div className="d-flex flex-column small">
                    <span className="text-uppercase small mb-1 ms-1 text-grey">Filename </span>
                    <Input placeholder="Please enter the filename" value={state.filename} onChange={onFilenameChange} />
                </div>
                <div className="d-flex flex-column small">
                    <span className="text-uppercase small mb-1 ms-1 text-grey">Subdirectory Path</span>
                    <Input placeholder="Please enter the subdirectory path" value={state.subdirectoryPath ?? ""} onChange={onSubdirectoryPathChage} />
                </div>
                <div className="d-flex flex-column small">
                    <span className="text-uppercase small mb-1 ms-1 text-grey"> Filename Date Format </span>
                    <Input
                        ariaLabel="dateformat"
                        className="small me-4"
                        placeholder="Please select a date format"
                        value={state.dateFormat ?? "yyyyMMdd"}
                        onChange={onDateFormatChange}
                    />
                </div>
                {isXmlExport ? (
                    <div className="d-flex flex-column small">
                        <span className="text-uppercase small mb-1 ms-1 text-grey">Xml root name </span>
                        <Input placeholder="Please enter the root name" value={state.rootNodeFieldsConfig.name} onChange={onXmlRootNameChange} />
                    </div>
                ) : (
                    <>
                        <div className="d-flex flex-column small">
                            <span className="text-uppercase small mb-1 ms-1 text-grey">Delimiter</span>
                            <AutoComplete
                                ariaLabel="delimiter"
                                placeholder="Please select a delimiter"
                                options={delimiters}
                                initialValue={state.delimiter}
                                onChange={onDelimiterChange}
                            />
                        </div>
                    </>
                )}
            </div>
            {!isXmlExport && (
                <>
                    <div className="d-flex p-3 gap-3">
                        <div className="d-flex flex-column small">
                            <span className="text-uppercase small mb-1 ms-1 text-grey">Line ending</span>
                            <AutoComplete
                                placeholder="Please select a line ending"
                                options={lineEndings}
                                initialValue={state.lineEnding}
                                onChange={onLineEndingChange}
                            />
                        </div>
                        <div className="d-flex flex-column small">
                            <span className="text-uppercase small mb-1 ms-1 text-grey"> Quote Fields With </span>
                            <Input
                                className="small me-4"
                                placeholder="(Optional) Quote fields with"
                                value={state.quoteFieldsWith ?? ""}
                                onChange={onQuoteFieldsWithChange}
                            />
                        </div>
                    </div>
                    <div className={`${classes.horizontalDivider} mx-2`} />
                    <div className="d-flex align-items-center p-3">
                        <Checkbox
                            className="small me-4"
                            isChecked={state.hasHeaderRow}
                            label="Has header row"
                            onClick={onHasHeaderRowChange}
                            ariaLabel="Has header row"
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export default DataFileExportOptions
