import { lookup } from "../constants";

export const requestLookup = (lookupName) => ({
  type: lookup.REQUEST_LOOKUP,
  lookupName
});

export const receiveLookup = (results, lookupName) => ({
  type: lookup.RECEIVE_LOOKUP,
  results,
  lookupName
});

export const errorLookup = (err, lookupName) => ({
  type: lookup.ERROR,
  isFetching: false,
  err,
  lookupName
});

export const clearLookup = (lookupName) => ({
  type: lookup.CLEAR_LOOKUP,
  lookupName
});
