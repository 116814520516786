import { Route, Routes } from "react-router-dom-v5-compat"
import DataConfiguration from "../../components/dataConfig/DataConfiguration"
import AutomateWrapper from "../../components/home/automate/AutomateWrapper"
import ViewDashboards from "../../components/home/dashboards/ViewDashboards"
import SmsConfig from "../../microfrontends/connect/components/sms/smsModule/SmsConfig"
import VoipConfig from "../../microfrontends/connect/components/voip/config/VoipConfig"
import DashboardConfigs from "../../microfrontends/dashboard/components/dashboardConfigs/DashboardConfigs"
import PaymentResult from "../../microfrontends/pay/components/PaymentResult"
import FlowSculptor from "../businessFlow/components/FlowSculptor"
import EntityConfigDashboard from "../entityConfig/components/EntityConfigDashboard"
import EntitySearch from "../entitySearch/components/EntitySearch"
import GuestDashboard from "../guest/components/GuestDashboard"
import TotalProcessingPaymentComplete from "../guest/components/payments/TotalProcessingPaymentComplete"
import FileEntityConfiguration from "../integration/components/FileEntityConfiguration"
import FileImportHistory from "../integration/components/importHistory/FileImportHistory"
import UserProfile from "../userProfile/components/UserProfile"
import Roles from "../userRoles/roles/components/Roles"
import ClientUsers from "../userRoles/users/components/ClientUsers"
import { useUser } from "../../contexts/UserContext"
import { Redirect } from "react-router-dom"
import useClient from "../../hooks/useClient"
import Client from "./components/Client"

const ClientRoutes = () => {
    const user = useUser()
    const client = useClient()

    return (
        <Routes>
            <Route path="client/:client" element={<Client />}>
                <Route index element={user.isGuest ? <GuestDashboard /> : <Redirect to={`/client/${client}/search?client=${client}`} />} />
                <Route path="payment-complete" element={<TotalProcessingPaymentComplete />} />
                <Route path="ppres/:result?" element={<PaymentResult />} />

                {user.canAccess("dashboards") && <Route path="dashboards" element={<ViewDashboards />} />}
                {user.canAccess("search") && <Route path="search" element={<EntitySearch nextEntity={0} goToNextEntity={() => {}} />} />}
                {user.canAccess("dashboard-config") && <Route path="dashboard-config" element={<DashboardConfigs />} />}
                {user.canAccess("automate") && <Route path="automate" element={<AutomateWrapper />} />}
                {user.canAccess("flow-sculptor") && <Route path="flow-sculptor" element={<FlowSculptor />} />}
                {user.canAccess("data-fields") && <Route path="data-fields" element={<DataConfiguration />} />}
                {user.canAccess("entity-config") && <Route path="entity-config" element={<EntityConfigDashboard />} />}
                {user.canAccess("file-config") && <Route path="file-config" element={<FileEntityConfiguration />} />}
                {user.canAccess("file-history") && <Route path="file-history" element={<FileImportHistory />} />}
                {user.canAccess("sms") && <Route path="sms" element={<SmsConfig />} />}
                {user.canAccess("voip") && <Route path="voip" element={<VoipConfig />} />}
                {user.canAccess("roles") && <Route path="roles" element={<Roles />} />}
                {user.canAccess("users") && <Route path="users" element={<ClientUsers />} />}
                {user.canAccess("user-profile") && <Route path="user-profile" element={<UserProfile />} />}

                <Route path="*" element={<Redirect to={`/client/${client}`} />} />
            </Route>
        </Routes>
    )
}

export default ClientRoutes
