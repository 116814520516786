import { widgetTypes } from "../enums/widgetTypes"
import { v4 as uuidv4 } from "uuid"
import { chartTypes } from "../enums/chartTypes"
import { fieldDataTypes } from "../enums/fieldDataTypes"
import { convertFromArrayToDto, convertFromDtoToArray } from "../../../library/FilterList/FiltersDto"
import { AggregationDto, ChartWidgetDto, GetDashboardConfigDto, GridType, GridWidgetDto, PutDashboardConfigDto } from "../types/DashboardConfigDto"
import Lookup from "../../../types/Lookup"
import {
    EntityChartWidgetAggregationState,
    EntityChartConfigWidgetState,
    GridWidgetState,
    InfoWidgetState,
    TransactionWidgetState
} from "../types/DashboardConfigState"
import DashboardConfigState from "../types/DashboardConfigState"
import { transactionAggregationTypes } from "../enums/transactionAggregationTypes"
import { DashboardConfigEntityConfig } from "../reducers/dashboardConfigsReducer"
import { dashboardSubTypes } from "../types/DashboardTypes"
import { getTypeFromEntityDataTypeEnum } from "../../../library/helpers/entityHelpers"
import { DataPrimitiveTypeEnum } from "../../../routes/entityConfig/types/DataType"

export const mapDashboardConfigFromGetDto = (
    dtos: GetDashboardConfigDto[],
    isNewEntityStructureEnabled: boolean,
    lookups: Lookup[] = [],
    entityConfigs: DashboardConfigEntityConfig[] = []
): DashboardConfigState[] => {
    const configs = dtos.map(dto => ({
        ...dto,
        id: dto.reference,
        type: dto.type,
        infoWidgets: dto.infoWidgets.map(
            widget =>
                ({
                    ...widget,
                    fields: widget.fields.map(
                        field =>
                            ({
                                ...field,
                                id: uuidv4(),
                                value: field.fieldName,
                                type: field.dataType
                            } as any)
                    ),
                    widgetType: widgetTypes.INFORMATION
                } as InfoWidgetState)
        ),
        valueWidgets: dto.valueWidgets.map(
            widget =>
                ({
                    ...widget,
                    widgetType: widgetTypes.VALUE,
                    field: {
                        ...widget.field,
                        value: widget.field.fieldName,
                        type: widget.field.dataType
                    }
                } as any)
        ),
        smallBarChartWidgets: dto.chartWidgets
            .filter(widget => widget.type === chartTypes.BAR)
            .map(
                widget =>
                    ({
                        ...widget,
                        widgetType: widgetTypes.SMALL_BAR_CHART
                    } as any)
            ),
        areaChartWidgets: dto.chartWidgets
            .filter(widget => widget.type === chartTypes.AREA)
            .map(
                widget =>
                    ({
                        ...widget,
                        widgetType: widgetTypes.AREA_CHART
                    } as any)
            ),
        lineChartWidgets: dto.chartWidgets
            .filter(widget => widget.type === chartTypes.LINE)
            .map(
                widget =>
                    ({
                        ...widget,
                        widgetType: widgetTypes.LINE_CHART
                    } as any)
            ),
        smallPieChartWidgets: dto.chartWidgets
            .filter(widget => widget.type === chartTypes.PIE)
            .map(
                widget =>
                    ({
                        ...widget,
                        widgetType: widgetTypes.SMALL_PIE_CHART
                    } as any)
            ),
        entityChartWidgets: (dto.entityChartWidgets ?? []).map(
            widget =>
                ({
                    ...widget,
                    widgetType: widgetTypes.ENTITY_CHART_WIDGET,
                    aggregation: convertAggregationDtoToState(widget.aggregation),
                    chartType: widget.type,
                    filters: convertFromDtoToArray(
                        widget.filters,
                        entityConfigs.find(entityConfig => entityConfig.reference === widget.entityConfigReference)?.fields ?? [],
                        lookups
                    )
                } as EntityChartConfigWidgetState)
        ),
        transactionsWidgets: dto.gridWidgets
            .filter(widget => widget.type === fieldDataTypes.TRANSACTION)
            .map(
                widget =>
                    ({
                        ...widget,
                        widgetType: widgetTypes.TRANSACTIONS_GRID,
                        columns: widget.columns.map(column => ({
                            id: uuidv4(),
                            fieldName: column.fieldName,
                            value: column.fieldName,
                            type: column.dataType
                        }))
                    } as TransactionWidgetState)
            ),
        gridWidgets:
            isNewEntityStructureEnabled || dto.type === "AGGREGATED"
                ? dto.gridWidgets
                      .filter(widget => widget.type !== GridType.TRANSACTION)
                      .map(
                          widget =>
                              ({
                                  ...widget,
                                  widgetType: widgetTypes.GRID,
                                  columns: widget.columns.map(
                                      column =>
                                          ({
                                              id: uuidv4(),
                                              fieldName: column.fieldName,
                                              dataType: column.dataType,
                                              isEditable: column.isEditable ?? false
                                          } as any)
                                  ),
                                  filters: convertFromDtoToArray(
                                      widget.filters,
                                      entityConfigs.find(entityConfig => entityConfig.reference === widget.entityConfigReference)?.fields ?? [],
                                      lookups
                                  )
                              } as GridWidgetState)
                      )
                : [],
        trendingWidgets:
            isNewEntityStructureEnabled && dto.type === "AGGREGATED"
                ? dto.trendingWidgets?.map(widget => {
                      const entityConfig = entityConfigs.find(e => e.reference === widget.entityConfigReference)
                      return {
                          ...widget,
                          widgetType: widgetTypes.ENTITY_TRENDING as widgetTypes.ENTITY_TRENDING,
                          filters: convertFromDtoToArray(widget.filters, entityConfig?.fields ?? [], lookups),
                          groupingField: entityConfig?.fields.find(f => f.fieldName === widget.groupingField),
                          comparisonField: entityConfig?.fields.find(f => f.fieldName === widget.comparisonField)
                      }
                  })
                : [],
        filters: convertFromDtoToArray(
            dto.filters,
            entityConfigs.find(entityConfig => entityConfig.reference === dto.gridWidgets[0]?.entityConfigReference)?.fields ?? [],
            lookups
        )
    }))

    return configs
}

export const mapDashboardConfigToPutDto = (
    config: DashboardConfigState,
    isNewEntityStructureEnabled: boolean,
    isAggregatedDashboard?: boolean
): PutDashboardConfigDto => ({
    name: config.name,
    type: (() => {
        if (isNewEntityStructureEnabled) return config.type ?? null
        return isAggregatedDashboard ? "AGGREGATED" : "CUSTOMER"
    })(),
    subType: config.subType !== dashboardSubTypes.NONE ? config.subType ?? null : null,
    entityConfigReference: isNewEntityStructureEnabled ? config.entityConfigReference ?? null : null,
    infoWidgets: config.infoWidgets.map(widget => ({
        ordinal: widget.ordinal,
        cellX: widget.cellX,
        cellY: widget.cellY,
        cellWidth: widget.cellWidth,
        cellHeight: widget.cellHeight,
        displayName: widget.displayName,
        isFieldLabelInline: widget.isFieldLabelInline,
        theme: widget.theme,
        fields: widget.fields.map(field => ({
            fieldName: isNewEntityStructureEnabled ? field.fieldName : field.value,
            dataType: isNewEntityStructureEnabled ? getTypeFromEntityDataTypeEnum(field.dataType.type || field.dataType) : field.type,
            isEditable: field.isEditable
        }))
    })),
    valueWidgets: config.valueWidgets.map(widget => ({
        ordinal: widget.ordinal,
        gridCellX: widget.gridCellX,
        gridCellY: widget.gridCellY,
        gridCellWidth: widget.gridCellWidth,
        gridCellHeight: widget.gridCellHeight,
        displayName: widget.displayName,
        theme: widget.theme,
        field: {
            fieldName: isNewEntityStructureEnabled ? widget.field.fieldName : widget.field.value,
            dataType: isNewEntityStructureEnabled ? getTypeFromEntityDataTypeEnum(widget.field.dataType.type || widget.field.dataType) : widget.field.type,
            isEditable: false
        }
    })),
    chartWidgets: config.smallPieChartWidgets
        .map(widget => ({ ...widget, type: chartTypes.PIE }))
        .concat(config.areaChartWidgets.map(widget => ({ ...widget, type: chartTypes.AREA } as any)))
        .concat(config.lineChartWidgets.map(widget => ({ ...widget, type: chartTypes.LINE } as any)))
        .concat(config.smallBarChartWidgets.map(widget => ({ ...widget, type: chartTypes.BAR } as any)))
        .map(
            widget =>
                ({
                    ordinal: widget.ordinal,
                    gridCellX: widget.gridCellX,
                    gridCellY: widget.gridCellY,
                    gridCellWidth: widget.gridCellWidth,
                    gridCellHeight: widget.gridCellHeight,
                    displayName: widget.displayName,
                    theme: widget.theme,
                    type: widget.type,
                    bucketChartType: transactionAggregationTypes.AGED_DEBT
                } as ChartWidgetDto)
        ),
    entityChartWidgets: config.entityChartWidgets.map(widget => ({
        ordinal: widget.ordinal,
        gridCellX: widget.gridCellX,
        gridCellY: widget.gridCellY,
        gridCellWidth: widget.gridCellWidth,
        gridCellHeight: widget.gridCellHeight,
        displayName: widget.displayName,
        theme: widget.theme,
        type: widget.chartType,
        aggregationType: widget.aggregationType,
        aggregation: convertStateToAggregationDto(widget.aggregation, widget.aggregationType),
        entityConfigReference: widget.entityConfigReference,
        filters: convertFromArrayToDto(widget.filters)
    })),
    gridWidgets:
        isNewEntityStructureEnabled || isAggregatedDashboard
            ? config.gridWidgets.map(widget => ({
                  reference: widget.reference,
                  ordinal: widget.ordinal,
                  gridCellX: widget.gridCellX,
                  gridCellY: widget.gridCellY,
                  gridCellWidth: widget.gridCellWidth,
                  gridCellHeight: widget.gridCellHeight,
                  displayName: widget.displayName,
                  type: widget.type ?? null,
                  entityConfigReference: isNewEntityStructureEnabled ? widget.entityConfigReference ?? null : "customer",
                  columns: widget.columns.map((column: any) => ({
                      fieldName: column.fieldName,
                      dataType: getTypeFromEntityDataTypeEnum(column.dataType.type || column.dataType),
                      isEditable: column.isEditable ?? false
                  })),
                  filters: convertFromArrayToDto(widget.filters),
                  groupingField: widget.groupingField,
                  draggable: widget.draggable,
                  defaultSort: widget.defaultSort
              }))
            : config.transactionsWidgets.map(
                  widget =>
                      ({
                          reference: widget.reference,
                          ordinal: widget.ordinal,
                          gridCellX: widget.gridCellX,
                          gridCellY: widget.gridCellY,
                          gridCellWidth: widget.gridCellWidth,
                          gridCellHeight: widget.gridCellHeight,
                          displayName: widget.displayName,
                          type: "TRANSACTION",
                          entityConfigReference: null,
                          columns: widget.columns.map(column => ({
                              fieldName: column.value,
                              dataType: column.type
                          })),
                          groupingField: null,
                          draggable: false
                      } as GridWidgetDto)
              ),
    trendingWidgets:
        isNewEntityStructureEnabled && isAggregatedDashboard
            ? config.trendingWidgets.map(w => ({
                  ...w,
                  groupingField: w.groupingField?.fieldName,
                  comparisonField: w.comparisonField?.fieldName,
                  filters: convertFromArrayToDto(w.filters)
              }))
            : [],
    roleReferences: config.roleReferences,
    filters: isNewEntityStructureEnabled && config.filters && !isAggregatedDashboard ? convertFromArrayToDto(config.filters) : undefined,
    entityValueWidgets: []
})

export const convertAggregationDtoToState = (dto: AggregationDto): EntityChartWidgetAggregationState => {
    switch (dto.type) {
        case "SINGLE": {
            return {
                type: "SINGLE",
                groupingField: dto.groupingField ?? "",
                aggregationField: dto.aggregationField ?? "",
                aggregationFieldType: dto.aggregationFieldType ?? DataPrimitiveTypeEnum.NUMBER
            }
        }
        case "MULTI": {
            return {
                type: "MULTI",
                fields: dto.fields?.map(f => ({ field: f, id: uuidv4() })) ?? []
            }
        }
    }
}
export const convertStateToAggregationDto = (
    aggregation: EntityChartWidgetAggregationState,
    aggregationType: "SUM" | "AVERAGE" | "MIN" | "MAX" | "COUNT" | "CARDINALITY"
): AggregationDto => {
    switch (aggregation.type) {
        case "SINGLE": {
            return {
                type: "SINGLE",
                groupingField: aggregationType === "COUNT" ? aggregation.aggregationField : aggregation.groupingField,
                aggregationField: aggregation.aggregationField,
                aggregationFieldType: aggregation.aggregationFieldType,
                fields: undefined
            }
        }
        case "MULTI": {
            return {
                type: "MULTI",
                fields: aggregation.fields.map(f => f.field)
            }
        }
    }
}
