import { ReactNode } from "react"
import Dropdown from "../dropdowns/Dropdown"
import classes from "./Footer.module.scss"
import PageNavButtons from "./PageNavButtons"

type FooterProps = {
    isLoading: boolean
    totalRowCount: number
    currentPageSize: number
    onCurrentPageSizeChanged: (pageSize: number) => void
    pageIndex: number
    onPageIndexChanged: (newPageIndex: number) => void
    renderFooter?: () => ReactNode
}

const Footer = ({ isLoading, totalRowCount, currentPageSize, onCurrentPageSizeChanged, pageIndex, onPageIndexChanged, renderFooter }: FooterProps) => {
    const onPageSizeOptionSelected = (option: { value: number | string }) => {
        if (typeof option.value === "string") {
            onCurrentPageSizeChanged(parseFloat(option.value))
            return
        }
        onCurrentPageSizeChanged(option.value)
    }

    return (
        <div className={`${classes.footer} p-2 rounded-bottom`}>
            <div className="h-100 d-flex align-items-center">
                {renderFooter && renderFooter()}
                <div className="d-flex align-items-center ms-auto">
                    <Dropdown
                        options={PageSizeOptions}
                        onOptionSelected={onPageSizeOptionSelected}
                        selectedOption={{ label: currentPageSize.toString(), value: currentPageSize }}
                        direction="up"
                        ariaLabel="Page size"
                    />
                    <span className="ms-2 me-4">items per page</span>
                </div>
                <span className="mx-2">
                    {`Showing results: `}
                    <strong>{Math.min(pageIndex * currentPageSize + currentPageSize, totalRowCount) === 0 ? 0 : pageIndex * currentPageSize + 1}</strong>
                    {` - `}
                    <strong>{Math.min(pageIndex * currentPageSize + currentPageSize, totalRowCount)}</strong>
                    {` of `}
                    <strong>{totalRowCount}</strong>
                </span>
                <PageNavButtons
                    onPageIndexChanged={onPageIndexChanged}
                    disabled={isLoading}
                    pageIndex={pageIndex}
                    totalPageNumber={Math.ceil(totalRowCount / currentPageSize)}
                />
            </div>
        </div>
    )
}

export default Footer

const PageSizeOptions = [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 250, label: "250" },
    { value: 1000, label: "1000" }
]
