import { useState } from "react"
import { DataTypeEnum } from "../../routes/entityConfig/types/DataType"
import BooleanInfoElement from "./BooleanInfoElement"
import DateInfoElement from "./DateInfoElement"
import { InfoCardData } from "./InfoCard"
import classes from "./InfoCard.module.scss"
import LookupInfoElement from "./LookupInfoElement"
import MoneyInfoElement from "./MoneyInfoElement"
import NumberInfoElement from "./NumberInfoElement"
import TextInfoElement from "./TextInfoElement"
import EditIcons from "./EditIcons"
import { DEFAULT_DATE_FORMAT, DEFAULT_TIMEZONE } from "../helpers/dateUtils"

type InfoCardFieldsProps = {
    data: InfoCardData
    theme: string
    onCustomerUpdate?: (dataToUpdate: InfoCardData, newValue: string) => void
}

const InfoCardFields = ({ data, theme, onCustomerUpdate }: InfoCardFieldsProps) => {
    const [isEditing, setIsEditing] = useState(false)
    const [updatedValue, setUpdatedValue] = useState<string>()

    const originalValue = data.value ? data.value.toString() : ""
    const currentValue = updatedValue ?? originalValue

    const onChange = (value: string) => {
        setIsEditing(true)
        setUpdatedValue(value)
    }

    const renderSwitch = () => {
        const themeStyle = ["good", "bad", "info"].includes(data.style) ? `text-${theme}-${data.style}` : `text-${theme}-info`

        switch (data.type) {
            case "text":
            case DataTypeEnum.TEXT:
            case DataTypeEnum.RICH_TEXT:
                return (
                    <TextInfoElement
                        className={classes[themeStyle]}
                        value={currentValue}
                        fieldName={data.fieldName}
                        disabled={!data.isEditable}
                        onChange={onChange}
                        useTextArea={true}
                    />
                )
            case DataTypeEnum.EMAIL:
            case DataTypeEnum.PHONE_NUMBER:
                return (
                    <TextInfoElement
                        className={classes[themeStyle]}
                        value={currentValue}
                        fieldName={data.fieldName}
                        disabled={!data.isEditable}
                        onChange={onChange}
                    />
                )
            case "date":
            case DataTypeEnum.DATE:
                return (
                    <DateInfoElement
                        className={classes[themeStyle]}
                        value={currentValue}
                        dateFormat={data.dateFormat || DEFAULT_DATE_FORMAT}
                        timezone={data.timezone || DEFAULT_TIMEZONE}
                        disabled={!data.isEditable}
                        onChange={onChange}
                    />
                )
            case "number":
            case DataTypeEnum.MONEY:
                return (
                    <MoneyInfoElement
                        className={classes[themeStyle]}
                        value={currentValue}
                        fieldName={data.fieldName}
                        isEditing={isEditing}
                        culture={data.culture}
                        currency={data.currency}
                        disabled={!data.isEditable}
                        onChange={onChange}
                    />
                )
            case DataTypeEnum.INT:
            case DataTypeEnum.DECIMAL:
            case DataTypeEnum.PERCENTAGE:
                return (
                    <NumberInfoElement
                        className={classes[themeStyle]}
                        value={currentValue}
                        fieldName={data.fieldName}
                        disabled={!data.isEditable}
                        onChange={onChange}
                    />
                )
            case "boolean":
            case DataTypeEnum.BOOLEAN:
                return <BooleanInfoElement fieldName={data.fieldName} status={currentValue === "true"} disabled={!data.isEditable} onChange={onChange} />
            case "lookup":
            case DataTypeEnum.LOOKUP:
                return (
                    <LookupInfoElement
                        className={classes[themeStyle]}
                        value={currentValue}
                        lookupValueList={data.lookupValueList || []}
                        disabled={!data.isEditable}
                        onChange={onChange}
                    />
                )
            default:
                return null
        }
    }

    const onEditSave = () => {
        setIsEditing(false)

        var value = currentValue
        if (data.type === "number") {
            var formattedValue = removeNumberFormatting(currentValue)!
            formattedValue.length !== 0 ? (value = formattedValue) : (value = parseFloat("0").toString())
        }

        onCustomerUpdate?.(data, value)
    }

    const onEditCancel = () => {
        setIsEditing(false)
        setUpdatedValue(undefined)
    }

    const dataIsText = data.type === "text" || data.type === DataTypeEnum.TEXT || data.type === DataTypeEnum.RICH_TEXT

    return (
        <div className="d-flex">
            <div className={`d-flex px-2 py-1 flex-grow-1 ${classes[`wrapper-${theme}`]} ${classes["zero-min-width"]}`}>
                <span className={`fs-5 align-self-center ${theme === "blue" ? "text-grey" : "text-white"} me-2`}>{data.label}</span>
                <div
                    className={`ms-auto align-self-center ${classes["zero-min-width"]} ${dataIsText ? "flex-fill" : ""}`}
                    aria-label={`${data.fieldName}-input-wrapper`}
                >
                    {renderSwitch()}
                </div>
            </div>
            <div className="d=flex pt-3">
                <EditIcons
                    className={classes.hide}
                    theme={theme === "blue" ? "text-white" : "text-blue"}
                    isHidden={!isEditing}
                    onSave={onEditSave}
                    onCancel={onEditCancel}
                />
            </div>
        </div>
    )
}

export default InfoCardFields

const removeNumberFormatting = (value: string) => {
    if (value === "" || !/\d/.test(value)) return "0.0"

    var commonFormat = value.replaceAll(",", ".").replace(/([^0-9.])/g, "")
    var numberParts = commonFormat.split(".")

    if (numberParts.length === 1) return numberParts[0]

    var decimals = numberParts[numberParts.length - 1]!
    var integer = numberParts.slice(0, numberParts.length - 1).join("")

    return integer.concat(".").concat(decimals)
}
