import { useState, useCallback, ChangeEvent, useEffect, memo } from "react"
import classes from "./EntityDataFieldDropdown.module.scss"
import { Input } from "invevo-react-components"
import { padElementsWith } from "../helpers"
import useClickOutsideRef from "../../hooks/useClickOutsideRef"
import EntityDataField from "../../routes/entityConfig/types/EntityDataField"
import { getBackgroundColourForEntityDataType, getForegroundColourForEntityDataType } from "../../routes/entityConfig/types/DataType"

type EntityDataFieldDropdownProps = {
    options: EntityDataField[]
    onOptionSelected: (option: EntityDataField | undefined) => void
    selectedOption: EntityDataField | undefined

    className?: string
    ariaLabel?: string
    disabled?: boolean
}

const EntityDataFieldDropdown = memo(
    ({ options, onOptionSelected, selectedOption, className, ariaLabel = undefined, disabled = false }: EntityDataFieldDropdownProps) => {
        const [inputValue, setInputValue] = useState<string>(selectedOption?.displayName || selectedOption?.fieldName || "")
        const [showingOptions, setShowingOptions] = useState(false)
        const ref = useClickOutsideRef(() => {
            setShowingOptions(false)
            setInputValue(selectedOption?.displayName || selectedOption?.fieldName || "")
        })

        useEffect(() => {
            setInputValue(selectedOption?.displayName || selectedOption?.fieldName || "")
        }, [setInputValue, selectedOption])

        const onOptionClicked = useCallback(
            (option: EntityDataField) => {
                setInputValue(option.displayName || "")

                if (selectedOption?.fieldName !== option.fieldName) {
                    onOptionSelected(option)
                    setShowingOptions(false)
                }
            },
            [onOptionSelected, selectedOption?.fieldName]
        )

        const doesOptionMatchValue = (option: EntityDataField, value: string) =>
            option.fieldName.toLowerCase().includes(value.toLowerCase()) || option.displayName.toLowerCase().includes(value.toLowerCase())

        const matchedOptions = options
            .filter(option => doesOptionMatchValue(option, inputValue))
            .sort((a, b) => {
                if (a.dataType.type === b.dataType.type) return a.displayName > b.displayName ? 1 : -1
                return a.dataType.type > b.dataType.type ? 1 : -1
            })

        const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
            const inputString = event.target.value
            setInputValue(inputString)

            if (inputString === "") {
                onOptionSelected(undefined)
            }
        }

        const onInputClick = () => {
            if (disabled) return
            setShowingOptions(true)
        }

        const getMatchedOptionValue = (option: EntityDataField) => {
            const matchingIndex = option.displayName.toLowerCase().indexOf(inputValue.toLowerCase())
            const firstPart = option.displayName.substring(0, matchingIndex)
            const matchingPart = option.displayName.substring(matchingIndex, matchingIndex + inputValue.length)
            const lastPart = option.displayName.substring(matchingIndex + inputValue.length, option.displayName.length)

            return (
                <span>
                    {firstPart}
                    <strong>{matchingPart}</strong>
                    {lastPart}
                    {` (${option.fieldName})`}
                </span>
            )
        }

        const matchedOptionElements = matchedOptions.map((option, index) => (
            <div
                key={index}
                role="listitem"
                aria-label={option.fieldName}
                className="d-flex py-2 px-1 pointer align-items-center no-select"
                onClick={() => onOptionClicked(option)}
            >
                <span
                    className={`${classes.label} text-white px-2 text-capitalize`}
                    style={{ backgroundColor: getForegroundColourForEntityDataType(option.dataType.type) }}
                >
                    {`${option.dataType.type === "RICH_TEXT" ? "large text" : option.dataType.type.toLowerCase()}`}
                </span>
                <span
                    className="ms-1 d-block flex-grow-1 text-grey text-truncate"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={option.displayName}
                >
                    {getMatchedOptionValue(option)}
                </span>
            </div>
        ))

        return (
            <div ref={ref} className={`d-flex position-relative flex-column ${className ? className : ""}`}>
                <Input
                    className={`w-100 ${matchedOptions.length > 0 && showingOptions && classes["input-with-matches"]}`}
                    placeholder="Please enter field name"
                    value={inputValue}
                    onChange={onInputChange}
                    onClick={onInputClick}
                    color={selectedOption ? getBackgroundColourForEntityDataType(selectedOption.dataType.type) : "white"}
                    ariaLabel={ariaLabel}
                    disabled={disabled}
                />
                <div className="d-flex w-100">
                    {matchedOptions.length > 0 && showingOptions && (
                        <div className={`position-absolute ${classes.options} border text-grey shadow bg-grey ${classes.scrollable}`}>
                            {padElementsWith(matchedOptionElements, "w-100 border-top")}
                        </div>
                    )}
                </div>
            </div>
        )
    }
)

export default EntityDataFieldDropdown
