import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLookup } from '../../legacy/api/lookupApi';

import AddRole from '../../legacy/components/admin/roles/addRole';
import RoleGrid from '../../legacy/components/admin/roles/roleGrid';
import { Collapse } from 'react-bootstrap';

class RoleManagementContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.roleSaved = this.roleSaved.bind(this);
    this.roleCancelled = this.roleCancelled.bind(this);
    this.roleGridRefreshCallback = this.roleGridRefreshCallback.bind(this);
    this.editRoleCallback = this.editRoleCallback.bind(this);
    this.onEditRole = this.onEditRole.bind(this);

    this.state = {
      showAdd: false,
      showGrid: true
    };
  }

  componentDidMount() {
    this.props.getLookup(this.props.client, 'lookup/authFilters', 'authFilters');
    this.props.getLookup(this.props.client, 'lookup/permissions', 'permissions');
  }

  onAddNewRole() {
    this.setState({
      showAdd: true,
      showGrid: false
    });
  }

  editRoleCallback(editRoleFunction) {
    this.editRole = editRoleFunction;
  }

  onEditRole(roleId) {
    this.editRole(roleId);
    this.setState({
      showAdd: true,
      showGrid: false
    });
  }

  roleGridRefreshCallback(refreshGrid) {
    this.refreshGrid = refreshGrid;
  }

  roleSaved() {
    this.refreshGrid();
    this.setState({
      showAdd: false,
      showGrid: true
    });
  }

  roleCancelled() {
    this.refreshGrid();
    this.setState({
      showAdd: false,
      showGrid: true
    });
  }

  render() {
    return (
      <div className="d-flex flex-column flex-grow-1">
        <div>
          <h2>Role Management</h2>
        </div>
        <Collapse in={this.state.showGrid}>
          <div>
            <RoleGrid
              client={this.props.client}
              refreshCallback={this.roleGridRefreshCallback}
              onEdit={this.onEditRole}
            />
            <button
              id="btn-add-role"
              className="btn btn-default"
              type="button"
              onClick={this.onAddNewRole.bind(this)}
            >
              Add New Role
            </button>
          </div>
        </Collapse>
        <Collapse in={this.state.showAdd}>
          <div>
            <AddRole
              client={this.props.client}
              onSaved={this.roleSaved}
              onCancelled={this.roleCancelled}
              onEdit={this.editRoleCallback}
              authFiltersLookup={this.props.authFiltersLookup}
              permissionsLookup={this.props.permissionsLookup}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authFiltersLookup: state.lookupReducer.authFilters,
  permissionsLookup: state.lookupReducer.permissions
});

export default connect(
  mapStateToProps,
  {
    getLookup
  },
  null,
  { forwardRef: true }
)(RoleManagementContainer);
