type EditIconsProps = {
    className?: string
    theme: string
    isHidden: boolean
    onSave: () => void
    onCancel: () => void
}

const EditIcons = ({ className, theme, isHidden, onSave, onCancel }: EditIconsProps) => {
    return (
        <div className="d-flex flex-column ms-auto pointer">
            <i className={`far fa-check fs-6 ${theme} ${isHidden && className}`} onClick={onSave} aria-label="edit-save" />
            <i className={`far fa-times fs-6 pt-1 ${theme} ${isHidden && className}`} onClick={onCancel} aria-label="edit-cancel" />
        </div>
    )
}

export default EditIcons
