import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
    Collapsable,
    StandardButton,
    useApi,
    useClient,
    useConfig,
    FloatInContainer,
    Loading
} from 'invevo-react-components'
import classes from './SmsSenderIds.module.scss'
import SmsSenderId from './smsSenderId'
import SmsSenderIdDetails from './smsSenderIdDetails'

const SmsSenderIds = ({
    customerGroups,
    activeSmsSenderIds,
    setActiveSmsSenderIds,
    archivedSmsSenderIds,
    setArchivedSmsSenderIds
}) => {
    const [showFloatIncontainer, setShowFloatInContainer] = useState(false)
    const [selectedSenderId, setSelectedSenderId] = useState({})
    const api = useApi()
    const client = useClient()
    const config = useConfig()

    const getActiveSenderIds = () => { return activeSmsSenderIds.filter(s => s.isActive) }

    const onCloseFloatInSidebar = () => {
        setShowFloatInContainer(false)
        setSelectedSenderId({})
    }

    const onAddNewSenderIdClicked = () => {
        setSelectedSenderId({
            reference:
                uuidv4(),
            senderId: "",
            isActive: true,
            customerGroups: [],
            isNew: true
        })
        setShowFloatInContainer(true)
    }

    const onSaveSucceded = (senderId) => {
        if (senderId.isNew) {
            const newList = [...activeSmsSenderIds, {
                ...senderId,
                isNew: false
            }]
            setActiveSmsSenderIds(newList)
        } else {
            if (senderId.isActive) {
                const listWithoutOld = activeSmsSenderIds.filter(s => s.reference !== senderId.reference)
                const newList = [...listWithoutOld, { ...senderId }]
                setActiveSmsSenderIds(newList)
            }
            else {
                const listWithoutOld = archivedSmsSenderIds.filter(s => s.reference !== senderId.reference)
                const newList = [...listWithoutOld, { ...senderId }]
                setArchivedSmsSenderIds(newList)
            }

        }
    }

    const onRemoveSenderIdClicked = (index) => {
        const toArchive = activeSmsSenderIds[index]
        if (toArchive.isNew) {
            const newList = activeSmsSenderIds.filter((_, i) => i !== index)
            setActiveSmsSenderIds(newList)
        } else {
            const newList = [...activeSmsSenderIds]
            newList[index] = { ...toArchive, isActive: false }
            setActiveSmsSenderIds(newList)
            setArchivedSmsSenderIds([...archivedSmsSenderIds, { ...toArchive, isActive: false }])
            api.put(`${config.CONNECT_API_URL}/api/${client}/sms-sender-id/${toArchive.reference}`,
                { senderId: toArchive.senderId, isActive: false, customerGroups: toArchive.customerGroups }
            )

        }
    }

    const onRestoreSenderIdClicked = (index) => {
        const senderId = archivedSmsSenderIds[index]
        setActiveSmsSenderIds([...activeSmsSenderIds, { ...senderId, isActive: true }])
        setArchivedSmsSenderIds(archivedSmsSenderIds.filter(s => s.reference !== senderId.reference))
        api.put(`${config.CONNECT_API_URL}/api/${client}/sms-sender-id/${senderId.reference}`, { senderId: senderId.senderId, isActive: true, customerGroups: senderId.customerGroups })
    }

    return (
        <Loading isLoading={!config.CONNECT_API_URL} colour="blue">
            <div className={`d-flex flex-column w-100 h-100 bg-blue ${classes.parentContainer}`}>
                <div className={`d-flex justify-content-center h-100 align-self-center ${classes.contentContainer}`}>
                    <div className={`d-flex flex-column w-100 px-3  ${classes.container}`}>

                        <h5 className="text-blue no-select text-uppercase my-4">SMS Sender Ids</h5>
                        {(!getActiveSenderIds() || getActiveSenderIds().length === 0) &&
                            <div className="d-flex align-items-center text-blue mt-2">
                                <i className="fal fa-exclamation-circle"></i>
                                <span className="ms-2">Please add a new SMS SenderId</span>
                            </div>
                        }

                        {getActiveSenderIds().length > 0 && getActiveSenderIds().map((s, i) =>
                            <SmsSenderId
                                key={i}
                                smsSenderId={s}
                                index={i}
                                iconClass="fal fa-trash"
                                onIconClicked={onRemoveSenderIdClicked}
                                setShowFloatInContainer={setShowFloatInContainer}
                                setSelectedSenderId={setSelectedSenderId}
                            />
                        ).reduce((prev, curr) => [prev, <span className="my-1"></span>, curr])}

                        <hr className="text-blue my-4" />

                        {archivedSmsSenderIds.length > 0 &&
                            <Collapsable colour="white" title="ARCHIVED SMS SENDER IDs" id="archivedSmsSenderIds">
                                <div className="d-flex flex-column">
                                    {archivedSmsSenderIds.map((s, i) =>
                                        <SmsSenderId
                                            key={i}
                                            smsSenderId={s}
                                            index={i}
                                            archived={true}
                                            iconClass="fal fa-archive"
                                            onIconClicked={onRestoreSenderIdClicked}
                                            setShowFloatInContainer={setShowFloatInContainer}
                                            setSelectedSenderId={setSelectedSenderId}
                                        />).reduce((prev, curr) => [prev, <span className="my-1"></span>, curr])}
                                </div>
                            </Collapsable>
                        }
                        <div className="d-flex flex-column mt-auto ms-auto pt-4">
                            <StandardButton
                                className="my-4"
                                iconClasses="fal fa-plus"
                                label="Add SMS Sender Id"
                                colour="blue"
                                onClick={onAddNewSenderIdClicked}
                            />
                        </div>
                    </div>
                </div>

                <div className={`d-flex h-100 ${classes.detailsContainer}`}>
                    {selectedSenderId &&
                        <FloatInContainer
                            title="SMS Sender ID details"
                            toShow={showFloatIncontainer}
                            onClose={onCloseFloatInSidebar}
                        >
                            <SmsSenderIdDetails
                                smsSenderId={selectedSenderId}
                                customerGroups={customerGroups}
                                onSaveSucceded={onSaveSucceded}
                            />
                        </FloatInContainer>
                    }
                </div>
            </div>
        </Loading>
    )
}

export default SmsSenderIds