export const toSnakeCase = (value: string): string => {
    if (value.trim() === "") {
        return ""
    }

    return value
        .replace(/[^a-zA-Z0-9\s_]/g, "")
        .trim()
        .replace(/\s+/g, "_")
        .replace(/([a-z])([A-Z])/g, "$1_$2")
        .toLowerCase()
}

export const capitaliseFirstLetters = (value: string): string => {
    if (value.trim() === "") {
        return ""
    }

    return value
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
}
