import { CSSProperties } from "react"
import { EdgeMarkerType, MarkerType } from "reactflow"

export type CustomEdge = {
    style?: CSSProperties
    markerEnd?: EdgeMarkerType
    markerStart?: EdgeMarkerType
}

const customEdge: CustomEdge = {
    style: { strokeWidth: 4 },
    markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10
    }
}

export default customEdge
