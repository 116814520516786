import { Collapsable } from "invevo-react-components"
import classes from "./CountryCode.module.scss"
import { v4 as uuid } from "uuid"

const countries = [
    { name: "United States", phoneNumberCode: "1", countryCode: "us" },
    { name: "Great Britain", phoneNumberCode: "44", countryCode: "gb" },
    { name: "Belgium", phoneNumberCode: "32", countryCode: "be" },
    { name: "Brazil", phoneNumberCode: "55", countryCode: "br" },
    { name: "Colombia", phoneNumberCode: "57", countryCode: "co" },
    { name: "Ecuador", phoneNumberCode: "593", countryCode: "ec" },
    { name: "Estonia", phoneNumberCode: "372", countryCode: "ee" },
    { name: "Germany", phoneNumberCode: "49", countryCode: "de" },
    { name: "Hong Kong", phoneNumberCode: "852", countryCode: "hk" },
    { name: "Ireland", phoneNumberCode: "353", countryCode: "ie" },
    { name: "Singapore", phoneNumberCode: "65", countryCode: "sg" },
    { name: "Spain", phoneNumberCode: "34", countryCode: "es" },
    { name: "Romania", phoneNumberCode: "40", countryCode: "ro" }
]

const generateHtmlId = () => `a${uuid().replace(/-/g, "")}`

type CountryCode = {
    name: string
    phoneNumberCode: string
    countryCode: string
}

type CountryCodesProps = {
    className?: string
    countryCode: CountryCode
    setCountryCode: (option: CountryCode) => void
}

const CountryCodes = ({ className, countryCode, setCountryCode }: CountryCodesProps) => {
    const dropdownId = generateHtmlId()
    const dropdownContentId = generateHtmlId()

    const selectOption = (option: CountryCode) => {
        if (countryCode.countryCode === option.countryCode) return

        if (countryCode.countryCode !== option.countryCode) {
            setCountryCode(option)
        }

        closeDropdown(dropdownId, "button")
    }

    const closeDropdown = (parentElementId: string, childElementType: string) => {
        const parentElement = document.getElementById(parentElementId)?.getElementsByTagName(childElementType)[0] as HTMLElement
        parentElement.click()
    }

    return (
        <div
            id={dropdownId}
            className={`d-flex flex-column position-relative pointer ps-3 justify-content-center ${classes.dropdown} ${className ? className : ""}`}
            role="listbox"
            aria-label="countrycodes"
        >
            <Collapsable
                title={countryCode.name !== "" ? `+ ${countryCode.phoneNumberCode}` : ""}
                colour="blue"
                type="button"
                onChange={setCountryCode}
            >
                <div id={dropdownContentId} className={`position-absolute ${classes["dropdown-content"]}`}>
                    {countries.map(o => {
                        return (
                            <div
                                id={o.countryCode}
                                key={o.countryCode}
                                className={`d-flex no-select align-items-center border-top px-3 py-1 ${classes.option}`}
                                onClick={() => selectOption(o)}
                                role="option"
                                aria-label={o.countryCode}
                                aria-selected={countryCode.countryCode === o.countryCode}
                            >
                                <span className="ps-2">
                                    {" "}
                                    {o.name} (+ {o.phoneNumberCode}){" "}
                                </span>
                                {countryCode.countryCode === o.countryCode ? <i className="fal fa-check ps-2"></i> : <></>}
                            </div>
                        )
                    })}
                </div>
            </Collapsable>
        </div>
    )
}

export default CountryCodes
