import { Loading } from "invevo-react-components"
import classes from './ParameterTag.module.scss'


const ParameterTag = ({ parameter, onDeleteClick, isLoading = false }) => {
    const canBeDeleted = onDeleteClick !== undefined

    return (
        <div className={`d-flex align-items-center ps-2 pe-${canBeDeleted ? "1" : "2"} me-1 text-nowrap ${classes.tag}`} style={{"backgroundColor": "lightgrey"}}>
            <span className="mb-1">{parameter}</span>
            {
                canBeDeleted &&
                <Loading isLoading={isLoading} colour="blue" size="small">
                    <i className={`ms-1 pointer ${classes["tag-delete"]} fas fa-times-circle`} onClick={() => onDeleteClick(parameter)} />
                </Loading>
            }
        </div>
    )
}

export default ParameterTag