import {
  requestSetSelectedAccount,
  receiveSetSelectedAccount,
  errorSetSelectedAccount,
  resetCurrentCustomerState,
  customerChanged
} from '../actions/currentSelectionActions';
import {
  selectTransaction,
  selectDocument,
  selectCustomerContacts,
  selectInternalContacts,
  resetAggregate
} from '../actions/functionActions';

import { getApiUrl } from "../constants";
import displayError from '../utility/error';
import Nav from "./navApi";
import { webApiInterface } from "./webApiInterface";
import { dismissFocusedTask, getFocusedTask } from '../actions/taskActions';

export const getFocusedAccount = (client, accountId, isVirtualAccount, taskId = null) => 
  accountId !== ""
      ? getFocusedAccountAndTask(client, accountId, isVirtualAccount, taskId)
        : resetCurrentCustomer();

export function getFocusedAccountAndTask(
  client,
  accountId,
  isVirtualAccount,
  taskId = null,
  taskCompleted = false
) {
  return function (dispatch) {
    dispatch(requestSetSelectedAccount(accountId, isVirtualAccount));
    webApiInterface
      .authFetch(
        client,
        getApiUrl(client) +
        'api/currentSelection/GetSelectedAccountInfo?accountId=' +
        accountId +
        '&isVirtualAccount=' +
        isVirtualAccount,
        dispatch
      )
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(receiveSetSelectedAccount(json));
        dispatch(selectTransaction([]));
        dispatch(selectDocument([]));
        dispatch(selectCustomerContacts([]));
        dispatch(selectInternalContacts([]));
        dispatch(resetAggregate());

        if (taskId) {
          dispatch(getFocusedTask(taskId, taskCompleted, client));
        } else {
          dispatch(dismissFocusedTask());
        }

        const { id, isVirtualAccount } = json;
        dispatch(Nav.RemoveRouteQuery(isVirtualAccount ? 'a' : 'v'));
        dispatch(Nav.SetRouteQuery(isVirtualAccount ? 'v' : 'a', id));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorSetSelectedAccount(err));
        dispatch(Nav.RemoveRouteQuery('v'));
        dispatch(Nav.RemoveRouteQuery('a'));
      });
  };
}

export function resetCurrentCustomer() {
  return function (dispatch) {
    dispatch(resetCurrentCustomerState());
  };
}

export function PreUpdateAccount(accountId, isVirtualAccount) {
  return function (dispatch) {
    dispatch(customerChanged(accountId, isVirtualAccount));
  };
}
