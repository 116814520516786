import displayError from '../utility/error';
import {
  requestCloneToVirtualParentAndAddParent,
  receiveCloneToVirtualParentAndAddParent,
  errorCloneToVirtualParentAndAddParent,
  requestUpdateVirtuaParentType,
  receiveUpdateVirtuaParentType,
  errorUpdateVirtuaParentType,
  requestGetVPTypes,
  receiveGetVPTypes,
  errorGetVPTypes,
  requestRemoveParent,
  receiveRemoveParent,
  errorRemoveParent,
  requestAddParent,
  receiveAddParent,
  errorAddParent,
  requestUpdateVirtualAccount,
  receiveUpdateVirtualAccount,
  errorUpdateVirtualAccount,
  requestGetParentDataById,
  receiveGetParentDataById,
  errorGetParentDataById,
  receiveGetBranches
} from '../actions/vpActions';
import {
  loadTransactionIds,
  errorLoadTransactionIds,
  receiveTransactionsAggregate
} from '../actions/functionActions';
import { assign } from 'lodash';
import { getApiUrl } from '../constants';
import { webApiInterface } from './webApiInterface';

export function cloneToVirtualParentAndAddParent(
  client,
  id,
  isVirtualParent,
  vpTypeId
) {
  if (!client) {
    throw Error("CLIENT MUST BE PROVIDED");
  }
  return function (dispatch) {
    dispatch(requestCloneToVirtualParentAndAddParent());
    const url =
      getApiUrl(client) + 'api/VirtualAccount/CloneToVirtualParentAndAddParent';
    const payload = assign({ id, isVirtualParent, vpTypeId });
    webApiInterface
      .authPost(client, url, dispatch, payload)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(receiveCloneToVirtualParentAndAddParent(json));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorCloneToVirtualParentAndAddParent(err));
      });
  };
}

export function createVirtualParentAndAddParent(
  client,
  id,
  isVirtualParent,
  vpTypeId,
  vpName,
) {
  if (!client) {
    throw Error("CLIENT MUST BE PROVIDED");
  }
  return function (dispatch) {
    dispatch(requestCloneToVirtualParentAndAddParent());
    const url =
      getApiUrl(client) + 'api/VirtualAccount/CreateVirtualParentAndAddParent';
    const payload = assign({ id, isVirtualParent, vpTypeId, vpName });
    webApiInterface
      .authPost(client, url, dispatch, payload)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(receiveCloneToVirtualParentAndAddParent(json));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorCloneToVirtualParentAndAddParent(err));
      });
  };
}


export function setChildToParentCreditLimit(client,
  customerId,
  virtualParentId,
  isLinked
) {
  if (!client) {
    throw Error("client not provided in setChildToParentCreditLimit")
  }
  return function (dispatch) {
    const url =
      getApiUrl(client) + 'api/VirtualAccount/SetChildToParentCreditLimitLink';
    const payload = {
      customerId,
      virtualParentId,
      isLinked
    };

    return webApiInterface
      .authPost(client, url, dispatch, payload)
      .then(function (response) {
        return response.json();
      });
  };
}

export function updateVritualParentType(client, id, vpTypeId) {
  if (!client) {
    throw Error("client not provided in updateVritualParentType")
  }
  return function (dispatch) {
    dispatch(requestUpdateVirtuaParentType());
    const url = getApiUrl(client) + 'api/VirtualAccount/UpdateVirtualParentType';
    const payload = { id, vpTypeId };
    return webApiInterface
      .authPost(client, url, dispatch, payload)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(receiveUpdateVirtuaParentType(json));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorUpdateVirtuaParentType(err));
      });
  };
}

export function getVPTypes(client) {
  if (!client) {
    throw Error("client not provided in getVPTypes")
  }
  return function (dispatch) {
    dispatch(requestGetVPTypes());
    const url = getApiUrl(client) + 'api/VirtualAccount/GetVirtualAccountTypes';
    webApiInterface
      .authFetch(client, url, dispatch)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(receiveGetVPTypes(json));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorGetVPTypes(err));
      });
  };
}
export function removeParent(client, id, idParent, isVirtualParent) {
  if (!client) {
    throw Error("client not provided in removeParent")
  }
  return function (dispatch) {
    dispatch(requestRemoveParent());
    const url = getApiUrl(client) + 'api/VirtualAccount/RemoveParent';
    const payload = assign({ id, idParent, isVirtualParent });
    return webApiInterface
      .authPost(client, url, dispatch, payload)
      .then(function () {
        dispatch(receiveRemoveParent());
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorRemoveParent(err));
      });
  };
}
export function addParent(client, id, idParent, isVirtualParent) {
  if (!client) {
    throw Error("client not provided in addParent")
  }
  return function (dispatch) {
    dispatch(requestAddParent());
    const url = getApiUrl(client) + 'api/VirtualAccount/AddParent';
    const payload = assign({ id, idParent, isVirtualParent });
    return webApiInterface
      .authPost(client, url, dispatch, payload)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(receiveAddParent(json));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorAddParent(err));
      });
  };
}

export function updateVirtualAccount(client, data) {
  if (!client) {
    throw Error("client not provided in updateVirtualAccount")
  }
  return function (dispatch) {
    dispatch(requestUpdateVirtualAccount(data));
    const url = getApiUrl(client) + 'api/VirtualAccount/UpdateParentData';
    webApiInterface
      .authPost(client, url, dispatch, data)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(receiveUpdateVirtualAccount(json));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorUpdateVirtualAccount(err));
      });
  };
}
export function getParentDataById(client, id) {
  if (!client) {
    throw Error("client not provided in getParentDataById")
  }
  return function (dispatch) {
    dispatch(requestGetParentDataById(id));
    const url =
      getApiUrl(client) + 'api/VirtualAccount/GetParentDataById?parentId=' + id;
    webApiInterface
      .authFetch(client, url, dispatch)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(receiveGetParentDataById(json));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorGetParentDataById(err));
      });
  };
}
export function getVirtualAccountTransactionsIds(client, filter) {
  if (!client) {
    throw Error("client not provided in getVirtualAccountTransactionsIds")
  }
  return function (dispatch) {
    const pfUrl =
      getApiUrl(client) +
      'api/virtualaccount/getvirtualaccounttransactionsids?parentId=' +
      filter.customerid;
    const fUrl = filter.isClosed === null ? pfUrl + '&isClosed=' : pfUrl;
    webApiInterface
      .authFetch(client, fUrl, dispatch)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(loadTransactionIds(json));
      })
      .catch(function (err) {
        displayError(err);
        dispatch(errorLoadTransactionIds(err));
      });
  };
}
export function getVirtualAccountTransactionsAggregate(client, serviceParameters) {
  if (!client) {
    throw Error("client not provided in getVirtualAccountTransactionsAggregate")
  }
  return function (dispatch) {
    webApiInterface
      .authPost(
        client,
        getApiUrl(client) + 'api/virtualaccount/virtualaccountTransactionsAggregate',
        dispatch,
        serviceParameters
      )
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(receiveTransactionsAggregate(json));
      })
      .catch(function (err) {
        displayError(err);
      });
  };
}
export function getBranches(client) {
  if (!client) {
    throw Error("client not provided in getBranches")
  }
  return function (dispatch) {
    const url = getApiUrl(client) + 'api/VirtualAccount/GetBranches';
    webApiInterface
      .authFetch(client, url, dispatch)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        dispatch(receiveGetBranches(json));
      })
      .catch(function (err) {
        displayError(err);
      });
  };
}
