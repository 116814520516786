import React, { Component } from 'react';
import { connect } from 'react-redux';
import { first, find, findIndex, map, union, isEqual, isEmpty } from 'lodash';

import {
  getRuleGroup,
  getMessageTypes,
  getActionDefinitions,
  saveRuleGroup,
  setRuleGroupValidation,
  copyRuleGroup,
  deleteRuleGroup,
  setRuleGroup
} from '../../legacy/actions/ruleGroupAdminActions';
import RuleGroupManagementGrid from '../../legacy/components/admin/ruleGroup/ruleGroupManagementGrid';
import RuleGroupForm from '../../legacy/components/admin/ruleGroup/ruleGroupForm';
import RuleGroupTransferAll from '../../legacy/components/admin/ruleGroup/ruleGroupTransferAll';
import RuleConditionForm from '../../legacy/components/admin/ruleGroup/ruleConditionForm';
import RuleActionForm from '../../legacy/components/admin/ruleGroup/ruleActionForm';
import { generateTemporaryId } from '../../legacy/utility/random';
import { Collapse } from 'react-bootstrap';

class RuleGroupManagementContainer extends Component {
  constructor(props) {
    super(props);

    this.state = RuleGroupManagementContainer.defaultFormState();

    this.onEditRuleGroup = this.onEditRuleGroup.bind(this);
    this.onCopyRuleGroup = this.onCopyRuleGroup.bind(this);
    this.onDeleteRuleGroup = this.onDeleteRuleGroup.bind(this);
    this.onCancelRuleGroup = this.onCancelRuleGroup.bind(this);
    this.onNewAction = this.onNewAction.bind(this);
    this.onEditAction = this.onEditAction.bind(this);
    this.onNewCondition = this.onNewCondition.bind(this);
    this.onEditCondition = this.onEditCondition.bind(this);
    this.onUpdateRuleAction = this.onUpdateRuleAction.bind(this);
    this.onUpdateRuleCondition = this.onUpdateRuleCondition.bind(this);
    this.onSaveRuleGroup = this.onSaveRuleGroup.bind(this);
    this.onRuleConditionCancel = this.onRuleConditionCancel.bind(this);
    this.onRuleActionCancel = this.onRuleActionCancel.bind(this);
    this.onNewRuleGroup = this.onNewRuleGroup.bind(this);
    this.onUpdateRuleGroup = this.onUpdateRuleGroup.bind(this);
  }

  static defaultFormState() {
    return {
      ruleGroup: {},
      condition: {},
      action: {},
      ruleActions: [],
      ruleGroupValidationResult: {},
      show: {
        grid: true,
        group: false,
        condition: false,
        action: false
      }
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.ruleGroup) && !isEqual(props.ruleGroup, state.ruleGroup))
      return { ruleGroup: Object.assign({}, props.ruleGroup) };
    return null;
  }

  componentDidUpdate() {
    if (this.props.ruleGroupValidationResult.valid === true) {
      this.props.setRuleGroupValidation({});

      this.setState({
        ruleGroupValidationResult: {},
        show: {
          grid: true,
          group: false,
          condition: false,
          action: false
        }
      });
    } else if (this.props.ruleGroupValidationResult.valid === false) {
      this.setState({
        ruleGroupValidationResult: Object.assign(
          {},
          this.props.ruleGroupValidationResult
        )
      });

      this.props.setRuleGroupValidation({});
    }
  }

  onNewCondition() {
    const newCondition = { id: generateTemporaryId() };
    this.setState({
      condition: newCondition,
      show: {
        grid: false,
        group: false,
        condition: true,
        action: false
      }
    });
  }

  onEditCondition(ruleConditionId) {
    const condition = find(
      this.state.ruleGroup.result.rules,
      (f) => f.id === ruleConditionId
    );

    this.setState({
      condition: condition,

      show: {
        grid: false,
        group: false,
        condition: true,
        action: false
      }
    });
  }

  onNewAction() {
    const newAction = { id: generateTemporaryId(), payloadItems: [] };
    const messageType = find(
      this.props.messageTypes.result,
      (f) => f.name === this.state.ruleGroup.result.messageType
    );
    const intermediateActions = map(messageType.intermediateActions, (m) => {
      return { name: m, intermediate: true };
    });
    const finalActions = map(messageType.finalActions, (m) => {
      return { name: m, intermediate: false };
    });

    this.setState({
      action: newAction,
      ruleActions: union(intermediateActions, finalActions),
      show: {
        grid: false,
        group: false,
        condition: false,
        action: true
      }
    });
  }

  onEditAction(ruleActionId) {
    const action = Object.assign(
      {},
      find(this.state.ruleGroup.result.actions, (f) => f.id === ruleActionId)
    );
    const messageType = find(
      this.props.messageTypes.result,
      (f) => f.name === this.state.ruleGroup.result.messageType
    );
    const intermediateActions = map(messageType.intermediateActions, (m) => {
      return { name: m, intermediate: true };
    });
    const finalActions = map(messageType.finalActions, (m) => {
      return { name: m, intermediate: false };
    });

    this.setState({
      action: action,
      ruleActions: union(intermediateActions, finalActions),
      show: {
        grid: false,
        group: false,
        condition: false,
        action: true
      }
    });
  }

  onEditRuleGroup(ruleGroupId) {
    this.props.getMessageTypes(this.props.client);
    this.props.getActionDefinitions(this.props.client);
    this.props.getRuleGroup(this.props.client, ruleGroupId);

    this.setState({
      show: {
        grid: false,
        group: true,
        condition: false,
        action: false
      }
    });
  }

  onNewRuleGroup() {
    this.props.getMessageTypes(this.props.client);
    this.props.getActionDefinitions(this.props.client);
    this.props.setRuleGroup({ rules: [], actions: [] });

    this.setState({
      show: {
        grid: false,
        group: true,
        condition: false,
        action: false
      }
    });
  }

  onCopyRuleGroup(ruleGroupId) {
    this.props.copyRuleGroup(this.props.client, ruleGroupId);
  }

  onDeleteRuleGroup(ruleGroupId) {
    this.props.deleteRuleGroup(this.props.client, ruleGroupId);
  }

  onSaveRuleGroup(ruleGroup) {
    this.props.saveRuleGroup(this.props.client, ruleGroup);
  }

  onUpdateRuleGroup(ruleGroup) {
    this.props.setRuleGroup(ruleGroup);
  }

  onCancelRuleGroup() {
    this.setState(RuleGroupManagementContainer.defaultFormState());
  }

  onRuleActionCancel() {
    this.setState({
      action: {},
      ruleActions: [],
      show: {
        grid: false,
        group: true,
        condition: false,
        action: false
      }
    });
  }

  onRuleConditionCancel() {
    this.setState({
      condition: {},
      show: {
        grid: false,
        group: true,
        condition: false,
        action: false
      }
    });
  }

  onUpdateRuleAction(ruleAction) {
    let ruleGroup;
    const actionIndex = findIndex(
      this.state.ruleGroup.result.actions,
      (f) => f.id === ruleAction.id
    );

    if (actionIndex >= 0) {
      ruleGroup = {
        ...this.state.ruleGroup.result,
        actions: [
          ...this.state.ruleGroup.result.actions.slice(0, actionIndex),
          ruleAction,
          ...this.state.ruleGroup.result.actions.slice(actionIndex + 1)
        ]
      };
    } else {
      ruleGroup = {
        ...this.state.ruleGroup.result,
        actions: [...this.state.ruleGroup.result.actions, ruleAction]
      };
    }

    this.props.setRuleGroup(ruleGroup);
    this.setState({
      action: {},
      ruleActions: [],
      show: {
        grid: false,
        group: true,
        condition: false,
        action: false
      }
    });
  }

  onUpdateRuleCondition(ruleCondition) {
    let ruleGroup;
    const conditionIndex = findIndex(
      this.state.ruleGroup.result.rules,
      (f) => f.id === ruleCondition.id
    );

    if (conditionIndex >= 0) {
      ruleGroup = {
        ...this.state.ruleGroup.result,
        rules: [
          ...this.state.ruleGroup.result.rules.slice(0, conditionIndex),
          ruleCondition,
          ...this.state.ruleGroup.result.rules.slice(conditionIndex + 1)
        ]
      };
    } else {
      ruleGroup = {
        ...this.state.ruleGroup.result,
        rules: [...this.state.ruleGroup.result.rules, ruleCondition]
      };
    }

    this.props.setRuleGroup(ruleGroup);
    this.setState({
      condition: {},

      show: {
        grid: false,
        group: true,
        condition: false,
        action: false
      }
    });
  }

  renderRuleGroupForm() {
    if (this.props.messageTypes.isFetched) {
      return (
        <div>
          <RuleGroupForm
            ruleGroup={this.state.ruleGroup.result}
            messageTypes={this.props.messageTypes}
            onNewCondition={this.onNewCondition}
            onEditCondition={this.onEditCondition}
            onSave={this.onSaveRuleGroup}
            onCancel={this.onCancelRuleGroup}
            onNewAction={this.onNewAction}
            onEditAction={this.onEditAction}
            ruleGroupValidationResult={this.state.ruleGroupValidationResult}
            onUpdateRuleGroup={this.onUpdateRuleGroup}
          />
        </div>
      );
    } else {
      return <span />;
    }
  }

  renderRuleActionForm() {
    if (this.state.action.id) {
      return (
        <div>
          <RuleActionForm
            ruleAction={this.state.action}
            ruleActions={this.state.ruleActions}
            ruleGroup={this.state.ruleGroup.result}
            actionDefinitions={this.props.actionDefinitions.result}
            onUpdate={this.onUpdateRuleAction}
            onCancel={this.onRuleActionCancel}
          />
        </div>
      );
    } else {
      return <span />;
    }
  }

  renderRuleConditionForm() {
    if (this.state.condition.id) {
      const messageType = find(
        this.props.messageTypes.result,
        (f) => f.name === this.state.ruleGroup.result.messageType
      );
      const ruleMembers = [...messageType.ruleMembers];
      const intermediateActions = this.state.ruleGroup.result.actions
        .filter(
          (f) =>
            f.rule &&
            (!this.state.condition.evaluationOrder ||
              f.rule < this.state.condition.evaluationOrder)
        )
        .map((m) => {
          return m.name;
        });

      intermediateActions.forEach((x) => {
        const actionDefinition = first(
          this.props.actionDefinitions.result.filter((f) => f.name === x)
        );

        if (actionDefinition.allowedConditions) {
          actionDefinition.allowedConditions.forEach((y) => {
            if (!ruleMembers.some((s) => s.name === y.name)) {
              ruleMembers.push(y);
            }
          });
        }
      });

      return (
        <div>
          <RuleConditionForm
            ruleCondition={this.state.condition}
            ruleGroup={this.state.ruleGroup.result}
            ruleMembers={ruleMembers}
            onUpdate={this.onUpdateRuleCondition}
            onCancel={this.onRuleConditionCancel}
          />
        </div>
      );
    } else {
      return <span />;
    }
  }

  render() {
    return (
      <div>
        <h2>Rule Group Management</h2>
        <Collapse in={this.state.show.grid}>
          <div>
            <RuleGroupTransferAll client={this.props.client} onNewRuleGroup={this.onNewRuleGroup} />
            <br />
            <RuleGroupManagementGrid
              client={this.props.client}
              onEdit={this.onEditRuleGroup}
              onCopy={this.onCopyRuleGroup}
              onDelete={this.onDeleteRuleGroup}
            />
          </div>
        </Collapse>
        <Collapse in={this.state.show.group}>
          {this.renderRuleGroupForm()}
        </Collapse>
        <Collapse in={this.state.show.condition}>
          {this.renderRuleConditionForm()}
        </Collapse>
        <Collapse in={this.state.show.action}>
          {this.renderRuleActionForm()}
        </Collapse>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ruleGroup: state.ruleGroupAdminReducer.ruleGroup,
    messageTypes: state.ruleGroupAdminReducer.messageTypes,
    actionDefinitions: state.ruleGroupAdminReducer.actionDefinitions,
    ruleGroupValidationResult:
      state.ruleGroupAdminReducer.ruleGroupValidationResult
  };
}

export default connect(mapStateToProps, {
  getRuleGroup,
  getMessageTypes,
  getActionDefinitions,
  saveRuleGroup,
  copyRuleGroup,
  deleteRuleGroup,
  setRuleGroupValidation,
  setRuleGroup
})(RuleGroupManagementContainer);
