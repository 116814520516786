import { AutoComplete, CustomerFieldDropdown, Loading, LogicOperatorOptions, useClient } from "invevo-react-components"
import { useState } from "react"
import Permission from "./Permission"
import classes from "./Permission.module.scss"
import EditLogicOperator from "../EditLogicOperator"
import LogicOperatorButton from "../LogicOperatorButton"
import FilterRow from "../FilterRow"

export const defaultCustomerPermission = {
    read: true,
    filters: {
        fieldsAreInValues: [],
        fieldsAreInNumberRanges: [],
        fieldsComparison: []
    }
}

const convertFiltersToFilterRows = filters =>
    filters.fieldsAreInValues
        .map(filter =>
            filter.values.map(value => ({
                fieldName: filter.field.fieldName,
                fieldValue: value,
                fieldType: filter.field.type,
                useAsNotIn: filter.useAsNotIn
            })))
        .reduce((acc, curr) => [...acc, ...curr], [])
        .map((filter, index) => ({ id: index, ...filter }))

const convertFilterFromFilterRows = filterRows => {
    const filters = []
    filterRows.forEach(filterRow => {
        const filter = filters.find(filter =>
            filter.field.fieldName === filterRow.fieldName &&
            filter.field.type === filterRow.fieldType &&
            filter.useAsNotIn === filterRow.useAsNotIn
        )
        if (filter) {
            filter.values = [...filter.values, filterRow.fieldValue]
        } else {
            filters.push({
                field: {
                    fieldName: filterRow.fieldName,
                    type: filterRow.fieldType
                },
                values: [filterRow.fieldValue],
                useAsNotIn: filterRow.useAsNotIn
            })
        }
    })

    return {
        fieldsAreInValues: filters,
        fieldsAreInNumberRanges: [],
        fieldsComparison: []
    }
}

const CustomerPermission = ({ permission, role, dispatch, isOnRole, customerFields, onPermissionUpdated }) => {
    const client = useClient()

    const [filters, setFilters] = useState(convertFiltersToFilterRows(permission.filters))

    const [currentlyEditingFilterId, setCurrentlyEditingFilterId] = useState()
    const [isEditingFilter, setIsEditingFilter] = useState(false)
    const [isSelectingFilterType, setIsSelectingFilterType] = useState(false)

    const [isSavingNewFilter, setIsSavingNewFilter] = useState(false)
    const [isDeletingFilter, setIsDeletingFilter] = useState(false)
    const [deletingFilterId, setDeletingFilterId] = useState()

    const onEditFilterClick = filterId => {
        setCurrentlyEditingFilterId(filterId)
        setIsSelectingFilterType(false)
        setIsEditingFilter(true)
    }

    const onAddNewFilterClick = () => {
        setFilters([...filters, { id: filters.length, fieldName: "", fieldValue: "", fieldType: "", useAsNotIn: false }])
        setCurrentlyEditingFilterId(filters.length)
        setIsEditingFilter(true)
    }

    const onFilterSave = () => {
        if (filters[currentlyEditingFilterId].fieldName === "") return
        if (filters[currentlyEditingFilterId].fieldValue === "") return

        setIsEditingFilter(false)
        setCurrentlyEditingFilterId()

        setIsSavingNewFilter(true)

        onPermissionUpdated(
            { ...permission, filters: convertFilterFromFilterRows(filters) },
            () => setIsSavingNewFilter(false)
        )
    }

    const onDeleteFilter = id => {
        setIsEditingFilter(false)
        setCurrentlyEditingFilterId()

        setIsDeletingFilter(true)
        setDeletingFilterId(id)

        const filtersAfterDelete = filters.filter(filter => filter.id !== id)

        onPermissionUpdated(
            { ...permission, filters: convertFilterFromFilterRows(filtersAfterDelete) },
            () => {
                setFilters(filtersAfterDelete)

                setIsDeletingFilter(false)
                setDeletingFilterId()
            }
        )
    }

    const onCustomerFilterValueChange = value => setFilters(filters.map(filter =>
        filter.id !== currentlyEditingFilterId ? filter : { ...filter, fieldValue: value }
    ))

    const onCustomerFilterNameChange = value => setFilters(filters.map(filter =>
        filter.id !== currentlyEditingFilterId ? filter : {
            ...filter,
            fieldName: value ? value.fieldName : "",
            fieldType: value ? value.type : ""
        }
    ))

    const onOperatorClicked = value => {
        setFilters(filters.map(filter =>
            filter.id !== currentlyEditingFilterId ? filter : { ...filter, useAsNotIn: value === LogicOperatorOptions.NOT_IN }
        ))
        setIsSelectingFilterType(false)
    }

    return (
        <Permission
            label="Customer"
            value="customerPermission"
            role={role}
            dispatch={dispatch}
            isOnRole={isOnRole}
            permissionIcons={<i className={`fas fa-glasses mx-1 ${permission.read ? "text-blue" : classes["icon-inactive"]}`} />}
        >
            {
                filters.slice(0, isEditingFilter ? currentlyEditingFilterId : filters.length).map(filter =>
                    <FilterRow
                        key={filter.id}
                        fieldName={filter.fieldName}
                        fieldValue={filter.fieldValue}
                        useAsNotIn={filter.useAsNotIn}
                        onEditClicked={() => onEditFilterClick(filter.id)}
                        onDeleteClicked={() => onDeleteFilter(filter.id)}
                        isDeleting={isDeletingFilter && deletingFilterId === filter.id}
                    />
                )
            }
            {
                isEditingFilter &&
                <div className="d-flex align-items-center mx-1 mb-2">
                    <div className="d-flex flex-grow-1 me-1">
                        <CustomerFieldDropdown
                            className="d-flex flex-grow-1"
                            initialValue={filters[currentlyEditingFilterId].fieldName}
                            initialType={filters[currentlyEditingFilterId].fieldType}
                            options={customerFields}
                            onChange={onCustomerFilterNameChange}
                        />
                    </div>
                    {
                        filters[currentlyEditingFilterId].fieldName !== "" &&
                        <>
                            <div className="me-1">
                                {
                                    isSelectingFilterType ?
                                        <EditLogicOperator
                                            operator={filters[currentlyEditingFilterId].useAsNotIn ? LogicOperatorOptions.NOT_IN : LogicOperatorOptions.IN}
                                            onOperatorChange={onOperatorClicked}
                                        /> :
                                        <LogicOperatorButton
                                            operator={filters[currentlyEditingFilterId].useAsNotIn ? LogicOperatorOptions.NOT_IN : LogicOperatorOptions.IN}
                                            onClick={() => setIsSelectingFilterType(true)}
                                        />
                                }
                            </div>
                            <AutoComplete
                                className="me-2"
                                placeholder="Field value"
                                url={`/api/${client}/customer-field-values-autocomplete`}
                                initialValue={filters[currentlyEditingFilterId].fieldValue}
                                fieldName={filters[currentlyEditingFilterId].fieldName}
                                onChange={onCustomerFilterValueChange}
                                enableTooltip={true}
                                scrollable={true}
                            />
                        </>
                    }
                    <div className="d-flex text-blue">
                        <Loading isLoading={isSavingNewFilter} colour="blue" size="small">
                            <div className="d-flex align-items-center me-1">
                                <i className="fal fa-check pointer" onClick={onFilterSave} />
                                <i className="ms-2 fal fa-trash pointer" onClick={() => onDeleteFilter(currentlyEditingFilterId)} />
                            </div>
                        </Loading>
                    </div>
                </div>
            }
            {
                isEditingFilter &&
                filters.slice(currentlyEditingFilterId + 1).map(filter =>
                    <FilterRow
                        key={filter.id}
                        fieldName={filter.fieldName}
                        fieldValue={filter.fieldValue}
                        useAsNotIn={filter.useAsNotIn}
                        onEditClicked={() => onEditFilterClick(filter.id)}
                        onDeleteClicked={() => onDeleteFilter(filter.id)}
                        isDeleting={isDeletingFilter && deletingFilterId === filter.id}
                    />
                )
            }
            {
                !isEditingFilter &&
                <div className="d-flex">
                    <div className={`d-flex align-items-center text-blue px-2 bg-grey pointer ${classes.rounded}`} onClick={onAddNewFilterClick}>
                        <span>Add Filter</span>
                        <i className="ms-1 fas fa-plus-circle" />
                    </div>
                </div>
            }
        </Permission>
    )
}

export default CustomerPermission