import DropdownOption from "../../../types/DropdownOptions"

export enum DataPrimitiveTypeEnum {
    TEXT = "TEXT",
    NUMBER = "NUMBER",
    BOOLEAN = "BOOLEAN",
    DATE = "DATE"
}

export const DataPrimitiveTypeDropdownOptions: DropdownOption<DataPrimitiveTypeEnum>[] = [
    { value: DataPrimitiveTypeEnum.TEXT, label: "Text" },
    { value: DataPrimitiveTypeEnum.NUMBER, label: "Number" },
    { value: DataPrimitiveTypeEnum.DATE, label: "Date" },
    { value: DataPrimitiveTypeEnum.BOOLEAN, label: "Boolean" }
]

export enum DataTypeEnum {
    TEXT = "TEXT",
    EMAIL = "EMAIL",
    PHONE_NUMBER = "PHONE_NUMBER",
    DATE = "DATE",
    BOOLEAN = "BOOLEAN",
    MONEY = "MONEY",
    INT = "INT",
    DECIMAL = "DECIMAL",
    LOOKUP = "LOOKUP",
    PERCENTAGE = "PERCENTAGE",
    RICH_TEXT = "RICH_TEXT"
}

export const DataTypeDropdownOptions: DropdownOption<DataTypeEnum>[] = [
    { value: DataTypeEnum.TEXT, label: "Text" },
    { value: DataTypeEnum.EMAIL, label: "Email" },
    { value: DataTypeEnum.PHONE_NUMBER, label: "Phone number" },
    { value: DataTypeEnum.DATE, label: "Date" },
    { value: DataTypeEnum.BOOLEAN, label: "Boolean" },
    { value: DataTypeEnum.MONEY, label: "Money" },
    { value: DataTypeEnum.INT, label: "Int" },
    { value: DataTypeEnum.DECIMAL, label: "Decimal" },
    { value: DataTypeEnum.LOOKUP, label: "Lookup" },
    { value: DataTypeEnum.PERCENTAGE, label: "Percentage" },
    { value: DataTypeEnum.RICH_TEXT, label: "Large Text" }
]

export const getDataPrimitiveTypeForEntityDataType = (type: DataTypeEnum | undefined) => {
    switch (type) {
        case DataTypeEnum.BOOLEAN:
            return DataPrimitiveTypeEnum.BOOLEAN
        case DataTypeEnum.DATE:
            return DataPrimitiveTypeEnum.DATE
        case DataTypeEnum.INT:
        case DataTypeEnum.DECIMAL:
        case DataTypeEnum.MONEY:
        case DataTypeEnum.PERCENTAGE:
            return DataPrimitiveTypeEnum.NUMBER
        case DataTypeEnum.TEXT:
        case DataTypeEnum.EMAIL:
        case DataTypeEnum.PHONE_NUMBER:
        case DataTypeEnum.LOOKUP:
        case DataTypeEnum.RICH_TEXT:
            return DataPrimitiveTypeEnum.TEXT
        default:
            return undefined
    }
}

export const getBackgroundColourForEntityDataType = (type: DataTypeEnum) => {
    switch (type) {
        case DataTypeEnum.DATE:
            return "#e3e6ff"
        case DataTypeEnum.MONEY:
        case DataTypeEnum.INT:
        case DataTypeEnum.DECIMAL:
        case DataTypeEnum.PERCENTAGE:
            return "#ffddfc"
        case DataTypeEnum.LOOKUP:
            return "#cdf7e5"
        case DataTypeEnum.BOOLEAN:
            return "#ffccd3"
        case DataTypeEnum.TEXT:
        case DataTypeEnum.PHONE_NUMBER:
        case DataTypeEnum.EMAIL:
        case DataTypeEnum.RICH_TEXT:
            return "#d9f7ff"
        default:
            return "white"
    }
}

export const getForegroundColourForEntityDataType = (type: DataTypeEnum) => {
    switch (type) {
        case DataTypeEnum.DATE:
            return "#4754cc"
        case DataTypeEnum.MONEY:
        case DataTypeEnum.INT:
        case DataTypeEnum.DECIMAL:
            return "#b83aad"
        case DataTypeEnum.LOOKUP:
            return "#73bf9d"
        case DataTypeEnum.BOOLEAN:
            return "#ee4057"
        case DataTypeEnum.TEXT:
        case DataTypeEnum.RICH_TEXT:
        case DataTypeEnum.PHONE_NUMBER:
        case DataTypeEnum.EMAIL:
        default:
            return "#47a5bd"
    }
}
