import DropdownOption from "../../../types/DropdownOptions"

export enum IntraEntityCalculationOperationEnum {
    ADD = "ADD",
    MINUS = "MINUS",
    MULTIPLY = "MULTIPLY",
    DIVIDE = "DIVIDE"
}

export const IntraEntityCalculationOperationsDropdownOptions: DropdownOption<IntraEntityCalculationOperationEnum>[] = [
    { value: IntraEntityCalculationOperationEnum.ADD, label: "add", element: <i className="px-2 fa-solid fa-plus"></i> },
    { value: IntraEntityCalculationOperationEnum.MINUS, label: "minus", element: <i className="px-2 fa-solid fa-minus"></i> },
    { value: IntraEntityCalculationOperationEnum.MULTIPLY, label: "multiply", element: <i className="px-2 fa-solid fa-times"></i> },
    { value: IntraEntityCalculationOperationEnum.DIVIDE, label: "divide", element: <i className="px-2 fa-solid fa-divide"></i> }
]
