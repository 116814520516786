import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getStatusSubStatus } from '../../api/functionApi';
import StatefulSelect from '../common/statefulSelect';
import { subStatus } from '../../selectors/transactionStatus';
import { useClient } from 'invevo-react-components';

const SubStatusSelect = ({
  id,
  statusId,
  onChange,
  getStatusSubStatus,
  status
}) => {
  const client = useClient()
  useEffect(() => {
    if (!status || status.length === 0) getStatusSubStatus(client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subStatus = status.filter((f) => f.statusId === statusId);
  
  const handleOnChange = (newId) => {
    const selectedSubStatus = subStatus.find((s) => s.value === newId);
    onChange(selectedSubStatus.value, selectedSubStatus);
  }

  return (
    <StatefulSelect options={subStatus} optionId={id} onChange={handleOnChange} />
  );
};

SubStatusSelect.propTypes = {
  id: PropTypes.number,
  statusId: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  status: subStatus(state.functionReducer.statusSubStatus)
});

export default connect(mapStateToProps, { getStatusSubStatus })(
  SubStatusSelect
);
