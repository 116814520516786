import { EntityFieldType } from "../routes/entitySearch/types/EntityResultsDto"

type EntityData = {
    entityTypeReference: string
    reference: string
    entityFields: EntityFields
}

export type EntityFields = {
    booleanFields: { name: string; value: boolean }[]
    numberFields: { name: string; value: number }[]
    dateFields: { name: string; value: number }[]
    textFields: { name: string; value: string }[]
}

export default EntityData

export const defaultEntityData = {
    booleanFields: [],
    numberFields: [],
    dateFields: [],
    textFields: []
}

export const convertDtoFieldsToDatafields = (fields: EntityFieldType): EntityFields => ({
    booleanFields: convertToDataFields(fields.booleanFields),
    numberFields: convertToDataFields(fields.numberFields),
    dateFields: convertToDataFields(fields.dateFields),
    textFields: convertToDataFields(fields.textFields)
})
const convertToDataFields = <T>(obj: { [key: string]: T }): { name: string; value: T }[] => Object.keys(obj).map(key => ({ name: key, value: obj[key]! }))

export const convertDataFieldsToDtoFields = (entityFields?: EntityFields): EntityFieldType => {
    if (entityFields === undefined) {
        return {
            booleanFields: {},
            numberFields: {},
            dateFields: {},
            textFields: {}
        }
    }
    return {
        booleanFields: convertFromDataFields(entityFields.booleanFields),
        numberFields: convertFromDataFields(entityFields.numberFields),
        dateFields: convertFromDataFields(entityFields.dateFields),
        textFields: convertFromDataFields(entityFields.textFields)
    }
}

export const convertFromDataFields = <T>(fields: { name: string; value: T }[]): { [key: string]: T } =>
    Object.assign({}, ...fields.map(f => ({ [f.name]: f.value })))
