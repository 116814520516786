import FileImportConfig from "../../../types/FileImportConfig"
import ScheduledWorkflowRule from "../../../types/ScheduledWorkflowRule"
import Dropdown from "../../../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../../../types/DropdownOptions"
import AutoCompleteDropdown from "../../../../../library/dropdowns/AutoCompleteDropdown"
import { WorkflowTriggerConfig } from "../../../types/WorkflowTriggerConfigs"
import IconButton from "../../../../../library/buttons/IconButton/IconButton"

const jobTypeOptions = [
    {
        label: "Workflow Rule",
        value: "WORKFLOW_RULE" as const
    },
    {
        label: "File Import",
        value: "FILE_IMPORT" as const
    }
]

type TriggerFromRuleSetupProps = {
    fileImportConfigs: FileImportConfig[]
    scheduledRules: ScheduledWorkflowRule[]
    triggerConfig: WorkflowTriggerConfig
    onTriggerConfigUpdated: (triggerConfig: WorkflowTriggerConfig) => void
    onDeleteTriggerConfig: (triggerConfig: WorkflowTriggerConfig) => void
    workflowTriggerConfigs: WorkflowTriggerConfig[]
}

const TriggerFromJobConfig = ({
    fileImportConfigs,
    scheduledRules,
    triggerConfig,
    onTriggerConfigUpdated,
    onDeleteTriggerConfig,
    workflowTriggerConfigs
}: TriggerFromRuleSetupProps) => {
    const selectJobType = (option: DropdownOption<"WORKFLOW_RULE" | "FILE_IMPORT">) => {
        onTriggerConfigUpdated({ ...triggerConfig, triggeredByType: option.value, triggeredByName: "", triggeredByReference: "" })
    }

    const selectedJobType = jobTypeOptions.find(option => option.value === triggerConfig.triggeredByType)

    const fileImportConfigDropdownOptions = fileImportConfigs.map(config => ({
        label: config.name,
        value: config.reference
    }))

    const workflowRuleDropdownOptions = scheduledRules
        .filter(rule => !rule.archived)
        .map(rule => ({
            label: rule.name,
            value: rule.reference
        }))

    const jobDropdownOptions =
        triggerConfig.triggeredByType === "FILE_IMPORT"
            ? fileImportConfigDropdownOptions.filter(config =>
                  workflowTriggerConfigs.every(triggerConfig => triggerConfig.triggeredByReference !== config.value)
              )
            : workflowRuleDropdownOptions.filter(rule => workflowTriggerConfigs.every(triggerConfig => triggerConfig.triggeredByReference !== rule.value))

    const selectTriggeredByJob = (option: DropdownOption<string> | undefined) => {
        if (option === undefined) return

        onTriggerConfigUpdated({ ...triggerConfig, triggeredByReference: option.value, triggeredByName: option.label })
    }

    const selectedJobOption =
        fileImportConfigDropdownOptions.find(config => config.value === triggerConfig.triggeredByReference) ??
        workflowRuleDropdownOptions.find(rule => rule.value === triggerConfig.triggeredByReference)

    const deleteTriggerConfig = () => onDeleteTriggerConfig(triggerConfig)

    return (
        <div className="d-flex flex-row" aria-label="trigger-from-job-config">
            <Dropdown
                className="ms-2"
                options={jobTypeOptions}
                onOptionSelected={selectJobType}
                selectedOption={selectedJobType}
                textAlign="left"
                ariaLabel="job-type-dropdown"
            />
            <AutoCompleteDropdown
                className="ms-2 w-50"
                options={jobDropdownOptions}
                onOptionSelected={selectTriggeredByJob}
                selectedOption={selectedJobOption}
            />
            <IconButton className="ms-2" icon="fal fa-trash-alt" onClick={deleteTriggerConfig} theme="icon-only" ariaLabel="delete-job-trigger" />
        </div>
    )
}

export default TriggerFromJobConfig
