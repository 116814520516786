export const uniqueStatus = (statusSubStatus) => {
  const all = statusSubStatus
    ? statusSubStatus
        .filter((s) => s.isInUse)
        .map((s) => ({
          value: s.id,
          label: s.status,
          showDate: s.requiresPromiseDate,
          code: s.statusCode
        }))
    : [];

  return all.reduce(
    (unique, item) =>
      unique.find((f) => item.value === f.value) ? unique : [...unique, item],
    []
  );
};

export const subStatus = (statusSubStatus) => {
  return statusSubStatus
    ? statusSubStatus
        .filter((s) => s.isInUse)
        .map((s) => ({
          statusId: s.id,
          value: s.subStatusId,
          label: s.subStatus,
          code: s.subStatusCode
        }))
    : [];
};
