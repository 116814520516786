import { actionTypes } from "./actionTypes";
import { v4 as uuidv4 } from 'uuid'

export default function commentsReducer(state, action) {
    switch (action.type) {
        case actionTypes.COMMENTS_RETRIEVED: {
            return {
                 ...state,
                comments: action.comments
            }
        }
        
        case actionTypes.COMMENT_ADDED: {
            return {
                 ...state,
                comments: [{
                    guid: uuidv4(),
                    text: action.text,
                    createdBy: action.createdBy,
                    createdDateTime: action.createdDateTime
                }, ...state.comments]
            }
        }
        
        default:
            throw new Error(`Invalid reducer action: ${action.type}`)
    }
}