import { getApiUrl } from '../constants';
import { webApiInterface } from './webApiInterface';

export function getFileTypeTable(client, fileTypeTableId, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/file-type-table?importFileTypeTableId=' + fileTypeTableId;

    return webApiInterface.authFetch(client, url, dispatch)
        .then(function (response) {
            return response.json();
        });
}

export function updateFileTypeTable(client, fileTypeTable, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/file-type-table/update';

    return webApiInterface.authPost(client, url, dispatch, fileTypeTable, 'POST');
}

export function createFileTypeTable(client, fileTypeTable, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/file-type-table/create';

    return webApiInterface.authPost(client, url, dispatch, fileTypeTable, 'POST');
}

export function deleteFileTypeTable(client, fileTypeTableId, dispatch) {
    const url = getApiUrl(client) + 'api/import-configuration/file-type-table/delete';

    return webApiInterface.authDelete(client, url, dispatch, fileTypeTableId);
}