import Permission from "./Permission"
import classes from "./Permission.module.scss"

export const defaultConnectPermission = {
    read: true
}

const ConnectPermission = ({ permission, role, dispatch, isOnRole }) => {
    return (
        <Permission
            label="Connect"
            value="connectPermission"
            role={role}
            dispatch={dispatch}
            isOnRole={isOnRole}
            permissionIcons={<i className={`fas fa-glasses mx-1 ${permission.read ? "text-blue" : classes["icon-inactive"]}`} />}
        />
    )
}

export default ConnectPermission