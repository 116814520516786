import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  UpperFinancialLimit,
  LowerFinancialLimit
} from '../../common/grids/gridCells';

import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { CommandCell } from '../../common/grids/commandCell';
import { ConfrimDialog } from '../../common/confirmDialog';

class PermissionGrid extends Component {
  constructor(props, context) {
    super(props, context);

    this.gridProgressive = 0;

    this.state = {
      showDeleteDialog: false,
      itemToDelete: ''
    };

    this.CommandCell = CommandCell({
      onCommandClick: this.onDeleteRowClicked,
      cssTag: 'Remove',
      buttonText: 'Remove'
    });
  }

  onDeleteRowClicked = (e, dataItem) => {
    e.preventDefault();
    this.setState({
      itemToDelete: dataItem.permissionId,
      showDeleteDialog: true
    });
  };

  deleteItem = () => {
    if (this.props.onDeletePermission) {
      this.props.onDeletePermission(this.state.itemToDelete);
    }
    this.closeDialog();
  };

  closeDialog = () => {
    this.setState({
      showDeleteDialog: false,
      itemToDeleteId: ''
    });
  };

  getGridKey() {
    return 'grid-permisson-' + this.gridProgressive++;
  }

  render() {
    let grid = '';
    if (this.props.permissions.length > 0) {
      grid = (
        <Grid
          key={this.getGridKey()}
          id={this.getGridKey()}
          data={this.props.permissions}
          sort={[{ field: 'Name', dir: 'asc' }]}
          scrollable={'none'}
        >
          <GridColumn field={'name'} title={'Permission'} />

          {this.props.isFinancialRole && (
            <GridColumn
              field={'upperFinancialLimit'}
              title={'Upper Financial Limit'}
              cell={UpperFinancialLimit}
            />
          )}

          {this.props.isFinancialRole && (
            <GridColumn
              field={'lowerFinancialLimit'}
              title={'Lower Financial Limit'}
              cell={LowerFinancialLimit}
            />
          )}
          {!this.props.isAdministrator && (
            <GridColumn cell={this.CommandCell} width={'250px'} />
          )}
        </Grid>
      );
    }

    return (
      <div id="permissions-grid-container">
        {grid}
        <ConfrimDialog
          show={this.state.showDeleteDialog}
          message={'Are you sure you want to delete this permission?'}
          onConfirm={this.deleteItem}
          onClose={this.closeDialog}
        />
      </div>
    );
  }
}

export default connect()(PermissionGrid);
