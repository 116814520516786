import { FileImportConfigFieldDefaultValue, FileImportConfigFieldDefaultValueEnum } from "../../../../types/FileImportConfigFieldDefaultValue"
import Lookup from "../../../../../../types/Lookup"
import Input from "../../../../../../library/Inputs/Input"
import Dropdown from "../../../../../../library/dropdowns/Dropdown"

type DefaultValueConfigProps = {
    config: FileImportConfigFieldDefaultValue
    lookup?: Lookup
    onChange: (option: FileImportConfigFieldDefaultValue) => any
}

const DefaultValueConfig = ({ config, onChange, lookup }: DefaultValueConfigProps) => {
    const booleanOptions = [
        { id: 1, value: true, label: "TRUE" },
        { id: 2, value: false, label: "FALSE" }
    ]

    const onStringValueChange = (value: string) => {
        if (config.type !== FileImportConfigFieldDefaultValueEnum.STRING) return
        onChange({
            ...config,
            stringValue: value
        })
    }

    const onNumberValueChange = (value: string) => {
        if (config.type !== FileImportConfigFieldDefaultValueEnum.NUMBER) return
        onChange({
            ...config,
            numberValue: value
        })
    }

    const onBooleanValueChange = (option: any) => {
        if (config.type !== FileImportConfigFieldDefaultValueEnum.BOOLEAN) return
        onChange({
            ...config,
            booleanValue: option.value
        })
    }

    const lookupOptions = lookup?.entries?.map(lookup => ({
        label: lookup.name,
        value: lookup.reference
    }))

    const onLookupSelected = (option: { label: string; value: string }) => onStringValueChange(option.value)

    return (
        <div className="d-flex w-100 h-100">
            {config.type === FileImportConfigFieldDefaultValueEnum.STRING ? (
                lookupOptions ? (
                    <Dropdown
                        placeholder="Please enter the value"
                        onOptionSelected={onLookupSelected}
                        options={lookupOptions}
                        selectedOption={lookupOptions.find(o => o.value === config.stringValue)}
                        fixedSize={true}
                    />
                ) : (
                    <Input placeholder="Please enter the value" value={config.stringValue} onChange={onStringValueChange} />
                )
            ) : config.type === FileImportConfigFieldDefaultValueEnum.NUMBER ? (
                <div className="d-flex">
                    <Input placeholder="Please enter the value" value={config.numberValue} onChange={onNumberValueChange} type="number" />
                </div>
            ) : (
                <Dropdown
                    placeholder={"Please select value"}
                    selectedOption={booleanOptions.find(bo => bo.value === config.booleanValue)}
                    options={booleanOptions}
                    onOptionSelected={onBooleanValueChange}
                />
            )}
        </div>
    )
}

export default DefaultValueConfig
