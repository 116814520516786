import React, { useState } from 'react';

import { connect } from 'react-redux'
import UserTaskDate from './userTaskDate';
import UserTaskGrid from './userTaskGrid';
import StateTaskTypesSelect from './stateTaskTypesSelect';
import useUserConfigColumns from '../../hooks/useUserConfigColumns';
import { createSelector } from 'reselect';
import VisibleColumnConfig from '../common/grids/VisibleColumnConfig';
import { getUserTaskGrid } from '../../selectors/task';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UserConfigViewType, stateTaskType } from '../../constants';
import UserTaskControl from './userTaskControl';
import { setUserTaskFilterStateTaskType } from '../../actions/taskActions';
import { useInvevoSession } from 'invevo-react-components';
import classes from './userTaskContainer.module.scss'
import { useLayout } from '../../../../contexts/LayoutContext';

const userGridTotal = createSelector(
  (state) => state.gridCacheReducer.grids,
  (grids) => {
    const { invevoTaskGridPageSize } = useFlags();
    const gridCache = getUserTaskGrid(grids);

    if (!gridCache || !gridCache.lastResponse) return '';

    const { total = 0 } = gridCache.lastResponse;
    const totalText =
      total < invevoTaskGridPageSize ? total : `${invevoTaskGridPageSize}+`;
    return `(${totalText})`;
  }
);

const UserTaskContainer = ({ statefulTaskTypeId, setUserTaskFilterStateTaskType }) => {
  const [showColConfig, setShowColConfig] = useState(false)
  const [refreshTaskGrid, setRefreshTaskGrid] = useState(0)
  const totalTasks = useSelector(userGridTotal)

  const [tickedTaskIds, setTickedTaskIds] = useState([])
  const [allTickBoxTicked, setAllTickBoxTicked] = useState(false)

  const { invevoTaskGridFeature, invevoTaskBulkAction } = useFlags()
  const [invevoSession] = useInvevoSession()
  const { isLeftPanelExpanded } = useLayout()

  const [showingFilters, setShowingFilters] = useState(true)

  const [columns, setColumns] = useUserConfigColumns(
    UserConfigViewType.userTaskGridColConfig
  )

  const removeTaskTypeFilters = () => {
    setUserTaskFilterStateTaskType(stateTaskType.TASK, 0)
  }

  const isGridView = invevoTaskGridFeature && isLeftPanelExpanded

  const shouldShowRemoveFilterButton = isLeftPanelExpanded && statefulTaskTypeId !== undefined && statefulTaskTypeId !== 0 && statefulTaskTypeId !== null

  return (
    invevoTaskBulkAction ?
      <div className={`d-flex w-100 pl-3 h-100 ${isLeftPanelExpanded ? "" : "flex-column"}`} id="user-task-list">
        <div className={`d-flex flex-column h-100 pt-3 k-pane ${classes["overflow-y"]} ${isGridView ? 'k-scrollable' : ''} `}>
          <div className="d-flex mb-3">
            <span className="mx-3 text-grey fs-3">My Tasks {totalTasks}</span>
            {shouldShowRemoveFilterButton &&
              <span className="text-grey fs-3">(Filtered <span className="pointer text-blue" onClick={removeTaskTypeFilters}>remove filters</span>)</span>
            }
            <div className="ms-auto">
              {isGridView && (
                <button
                  className="btn btn-default me-2"
                  onClick={() => setShowColConfig(true)}
                >
                  Edit Columms <i className="fa-regular fa-line-columns" />
                </button>
              )}
            </div>
          </div>

          {!isLeftPanelExpanded && <UserTaskDate />}

          {!isLeftPanelExpanded &&
            <div className="w-100 row top10">
              <div className="w-100 col-12">
                <StateTaskTypesSelect />
              </div>
            </div>
          }

          <div className="row top10">
            <div className="col-12">
              <UserTaskGrid
                columns={columns}
                setColumns={setColumns}
                tickedTaskIds={tickedTaskIds}
                setTickedTaskIds={setTickedTaskIds}
                refresh={invevoSession.reloadList}
                isBulkActionSelected={!showingFilters}
                allTickBoxTicked={allTickBoxTicked}
                setAllTickBoxTicked={setAllTickBoxTicked}
                isGridView={isGridView}
              />
            </div>
          </div>
          <VisibleColumnConfig
            gridId={UserConfigViewType.userTaskGridColConfig}
            columns={columns}
            setColumns={setColumns}
            show={isLeftPanelExpanded && showColConfig}
            setShow={setShowColConfig}
          />
        </div>
        {isLeftPanelExpanded &&
          <div className="flex-grow-1">
            <UserTaskControl
              className="px-3 h-100"
              tickedTaskIds={tickedTaskIds}
              setRefresh={setRefreshTaskGrid}
              refresh={refreshTaskGrid}
              setTickedTaskIds={setTickedTaskIds}
              customerTask={false}
              showingFilters={showingFilters}
              setShowingFilters={setShowingFilters}
              setAllTickBoxTicked={setAllTickBoxTicked}
            />
          </div>}
      </div> :
      <div className={`d-flex w-100 pl-3 h-100 ${isLeftPanelExpanded ? "" : "flex-column"}`} id="user-task-list">
        <div className={`d-flex flex-column h-100 pt-3 k-pane ${classes["overflow-y"]} ${isGridView ? 'k-scrollable' : ''} `}>
          <div className="p-0 mt-2">
            <h2>My Tasks {totalTasks}</h2>
          </div>
          <div className="d-flex col-auto">
            {isGridView && (
              <button
                className="btn btn-default ms-auto"
                onClick={() => setShowColConfig(true)}
              >
                Edit Columms <i className="fa-regular fa-line-columns" />
              </button>
            )}
          </div>

          {!isLeftPanelExpanded && <UserTaskDate />}

          {!isLeftPanelExpanded &&
            <div className="row top10" style={{ marginRight: "0" }}>
              <div className="col-12">
                <StateTaskTypesSelect />
              </div>
            </div>
          }

          <div className="row top10">
            <div className="col-12">
              <UserTaskGrid
                columns={columns}
                setColumns={setColumns}
                tickedTaskIds={tickedTaskIds}
                setTickedTaskIds={setTickedTaskIds}
                refresh={invevoSession.reloadList}
                isBulkActionSelected={!showingFilters}
                allTickBoxTicked={allTickBoxTicked}
                setAllTickBoxTicked={setAllTickBoxTicked}
                isGridView={isGridView}
              />
            </div>
          </div>
          <VisibleColumnConfig
            gridId={UserConfigViewType.userTaskGridColConfig}
            columns={columns}
            setColumns={setColumns}
            show={isLeftPanelExpanded && showColConfig}
            setShow={setShowColConfig}
          />
        </div>
      </div>
  );
};

const mapStateToProps = (state) => {
  return {
    statefulTaskTypeId: state.taskReducer.userTaskList.taskTypeId
  }
}

export default connect(mapStateToProps, { setUserTaskFilterStateTaskType })(UserTaskContainer)