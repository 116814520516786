const init = {
  taskDocuments: []
};

const documentReducer = (state = init, action) => {
  switch (action.type) {
    case 'TASK_DOCUMENTS':
      return { ...state, taskDocuments: action.taskDocuments };
    default:
      return state;
  }
};

export default documentReducer;
