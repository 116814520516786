import { Edge } from "reactflow"
import customEdge, { CustomEdge } from "./types/CustomEdge"

type FlowEdge = {
    sourceNodeReference: string
    sourceNodeHandleReference: string
    targetNodeReference: string
    targetNodeHandleReference?: string
    label?: string
}

export default FlowEdge

export const mapFlowEdgesToEdges = <T extends FlowEdge>(
    flowEdges: T[] = [],
    customEdgeDefinition: CustomEdge | ((edge: T) => CustomEdge | undefined) = {}
): Edge[] =>
    flowEdges.map(edge => ({
        ...customEdge,
        ...(customEdgeDefinition instanceof Function ? customEdgeDefinition(edge) || {} : customEdgeDefinition),
        id: `${edge.sourceNodeReference}-${edge.targetNodeReference}-${edge.sourceNodeHandleReference}-${edge.targetNodeHandleReference}`,
        source: edge.sourceNodeReference,
        sourceHandle: edge.sourceNodeHandleReference,
        target: edge.targetNodeReference,
        targetHandle: edge.targetNodeHandleReference,
        label: edge.label
    }))
