import { v4 as uuidv4 } from "uuid"
import { StandardButton } from "invevo-react-components"
import {
    FileExportConfigAction,
    FileExportConfigActionEnum,
    XmlEntityAggregatedExportNodeState,
    XmlEntityAggregatedExportParentState
} from "../../../../../reducers/FileEntityExportConfigReducer"
import { useState } from "react"
import EntityDataField from "../../../../../../entityConfig/types/EntityDataField"
import XmlExportAggregatedFieldConfig from "./XmlExportAggregatedFieldConfig"

type XmlExportAggregatedFieldConfigsProps = {
    type: "header" | "footer"
    root: XmlEntityAggregatedExportParentState[]
    dispatch: React.Dispatch<FileExportConfigAction>
    dataFields: EntityDataField[]
}

const XmlExportAggregatedFieldConfigs = ({ type, root, dispatch, dataFields }: XmlExportAggregatedFieldConfigsProps) => {
    const [editingNode, setEditingNode] = useState<XmlEntityAggregatedExportNodeState | undefined>(undefined)

    const onChanged = (key: string, changedNode: XmlEntityAggregatedExportNodeState) => {
        dispatch({
            type: type === "header" ? FileExportConfigActionEnum.XML_HEADER_NODE_CHANGED : FileExportConfigActionEnum.XML_FOOTER_NODE_CHANGED,
            key,
            nodeUpdated: changedNode
        })
    }

    const onDeleted = (key: string, changedNode: XmlEntityAggregatedExportNodeState) => {
        dispatch({
            type: type === "header" ? FileExportConfigActionEnum.XML_HEADER_NODE_DELETED : FileExportConfigActionEnum.XML_FOOTER_NODE_DELETED,
            key,
            nodeId: changedNode.key,
            parentId: changedNode.parentKey
        })
    }

    const onEditingNodeChanged = (changedNode: XmlEntityAggregatedExportNodeState) => setEditingNode(changedNode)

    const saveEditingConfig = (key: string) => {
        if (!editingNode) {
            return
        }
        onChanged(key, editingNode)
        setEditingNode(undefined)
    }

    const title = type === "header" ? "Header" : "Footer"

    return (
        <div className={"d-flex flex-column mt-4"}>
            <div className="d-flex align-items-center">
                <span className="text-grey fs-3">{title} nodes</span>
                <StandardButton
                    className="ms-auto"
                    label={`Add new ${title} node`}
                    colour="white"
                    iconClasses="fal fa-plus"
                    onClick={() => {
                        dispatch({
                            type: type === "header" ? FileExportConfigActionEnum.XML_HEADER_NODE_ADDED : FileExportConfigActionEnum.XML_FOOTER_NODE_ADDED,
                            node: {
                                name: "New xml node",
                                key: uuidv4(),
                                parentKey: uuidv4(),
                                type: "parent",
                                children: [],
                                selected: true,
                                isRoot: true
                            }
                        })
                    }}
                    ariaLabel="Add new xml node"
                />
            </div>
            <div className={`d-flex flex-column mt-3`}>
                <div className={"d-flex flex-column"}>
                    {root.map((child: XmlEntityAggregatedExportNodeState) => (
                        <XmlExportAggregatedFieldConfig
                            key={child.key}
                            state={{
                                root: child,
                                dataFields,
                                editingNode,
                                onEditingNodeChanged,
                                onChanged: node => {
                                    onChanged(child.key, node)
                                },
                                onDeleted: node => {
                                    onDeleted(child.key, node)
                                },
                                saveEditingConfig: () => {
                                    saveEditingConfig(child.key)
                                }
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default XmlExportAggregatedFieldConfigs
