import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { executeAuthAsyncPost, executeAuthAsyncGet } from '../../utility/asyncSupport';
import { showToastErrorMessage, showToastSuccessMessage } from '../../api/toasterApi';
import { getApiUrl } from '../../constants';
import { webApiInterface } from '../../api/webApiInterface';
import Nav from '../../api/navApi';

const PostSendButtons = ({
  client,
  setIsShowingSuccess,
  executeAuthAsyncPost,
  executeAuthAsyncGet,
  contacts,
  postTemplate,
  embeddedTransactionIds,
  documents,
  personalisedContent,
  navToPostSuccess
}) => {
  const sendAllPost = async (shouldPrintLocally) => {
    const model = {
      templateId: postTemplate.templateId,
      contactIds: [],
      customerId: postTemplate.customerId,
      isVirtualAccount: postTemplate.isVirtualAccount,
      embeddedTransactionRowIds: embeddedTransactionIds,
      attachedTransactionIds: embeddedTransactionIds,
      personalisedContent: personalisedContent,
      attachedDocumentIds: documents.map((d) => d.id),
      printMode: !shouldPrintLocally,
      saveNote: true
    };

    if (!contacts || contacts.length === 0) {
      if (!(await sendPost(model, shouldPrintLocally))) {
        return;
      }
    }

    for (let i = 0; i < contacts.length; i++) {
      model.contactIds = [contacts[i].contactId];
      if (!(await sendPost(model, shouldPrintLocally))) {
        return;
      }
    }

    showToastSuccessMessage(
      shouldPrintLocally
        ? 'Post downloaded successfully'
        : 'Post sent to batch successfully'
    );

    setIsShowingSuccess(true);
  };

  const sendPost = async (model, shouldPrintLocally) => {
    try {
      await sendSinglePostFromModel(model, shouldPrintLocally);
      return true;
    } catch (error) {
      showToastErrorMessage(error.message);
      return false;
    }
  };

  const sendSinglePostFromModel = (model, shouldPrintLocally) => {
    return new Promise((resolve, reject) => {
      const url = 'Post/NewGenerate'

      if (!shouldPrintLocally) {
        executeAuthAsyncPost(
          client,
          url,
          'GENERATE_POST',
          model,
          () => {
            resolve();
          },
          () => reject(new Error('Failed to generate post document'))
        );
      } else {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', getApiUrl(client) + 'api/' + url, true);

        xhr.responseType = 'blob';
        xhr.onload = () => {
          const { status } = xhr;

          if (status === 200) {
            const a = document.createElement('a');
            a.style = 'display: none';
            const url = URL.createObjectURL(xhr.response);
            a.href = url;
            a.download = 'post.pdf';
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
              resolve();
            }, 100);
          } else {
            reject(
              new Error(
                'Unable to download PDF - check the templates are configured correctly.'
              )
            );
          }
        };

        executeAuthAsyncGet(
          client,
          'Lookup/OK',
          'CHECK_OK',
          null,
          () => {
            webApiInterface.applySecurity(xhr);
            xhr.setRequestHeader(
              'Content-Type',
              'application/json; charset=utf-8'
            );
            xhr.send(JSON.stringify(model));
          },
          () => reject(new Error('Failed to generate post document'))
        );
      }
    });
  };

  return (
    <div className="d-flex ms-auto">
      <button
        className="btn btn-primary mr-2"
        type="button"
        disabled={!postTemplate.templateId}
        onClick={async () => await sendAllPost(true)}
      >
        Download now
      </button>
      <button
        className="btn btn-primary"
        type="button"
        disabled={!postTemplate.templateId}
        onClick={async () => await sendAllPost(false)}
      >
        Send to batch
      </button>
    </div>
  );
};

export default injectIntl(
  connect(null, {
    executeAuthAsyncGet,
    executeAuthAsyncPost,
    navToPostSuccess: Nav.Workspace.Post.Success
  })(PostSendButtons)
);
