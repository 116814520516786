import moment from 'moment';

export const daysSinceMessage = (date) => {
  if (!date) return null;

  if (typeof date === 'string') date = moment(new Date(date));
  else if (date instanceof Date) date = moment(date);

  const dueDate = date.startOf('date');
  const today = moment().startOf('date');
  const daysDiff = today.diff(dueDate, 'days');
  const weeksDiff = Math.round(today.diff(dueDate, 'weeks', true));
  const monthsDiff = Math.round(today.diff(dueDate, 'months', true));

  // conditional switch
  switch (true) {
    case monthsDiff > 1:
      return `Added ${monthsDiff} months ago`;

    case weeksDiff > 2:
      return `Added ${weeksDiff} weeks ago`;

    case daysDiff === 1:
      return `Added 1 day ago`;

    case daysDiff === 0:
      return `Added today`;

    default:
      return `Added ${daysDiff} days ago`;
  }
};
