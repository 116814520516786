import EntityDataFieldDropdown from "../../../library/dropdowns/EntityDataFieldDropdown"
import { DataPrimitiveTypeEnum } from "../types/DataType"
import EntityConfig from "../types/EntityConfig"
import IntraEntityCalculation from "../types/IntraEntityCalculation"
import EntityDataField from "../types/EntityDataField"
import IntraEntityCalculationOperationConfig from "./IntraEntityCalculationOperationConfig"
import BlueButton from "../../../library/buttons/BlueButton/BlueButton"
import IntraEntityCalculationOperation from "../types/IntraEntityCalculationOperation"
import { exists } from "../../../library/helpers/tsUtils"

const MAX_OPERATIONS = 5

type IntraEntityCalculationConfigFormProps = {
    entityConfig: EntityConfig
    intraEntityCalculation: IntraEntityCalculation
    onIntraEntityCalculationUpdated: (intraEntityCalculation: IntraEntityCalculation) => void
    selectedEntityDataFieldName: string
}

const IntraEntityCalculationConfigForm = ({
    entityConfig,
    intraEntityCalculation,
    onIntraEntityCalculationUpdated,
    selectedEntityDataFieldName
}: IntraEntityCalculationConfigFormProps) => {
    const selectedBaseField = entityConfig.fields.find(field => field.fieldName === intraEntityCalculation?.baseField)

    const availableDataFields = entityConfig.fields.filter(
        field =>
            field.dataPrimitiveType === DataPrimitiveTypeEnum.NUMBER && !exists(field.intraEntityCalculation) && field.fieldName !== selectedEntityDataFieldName
    )

    const onBaseFieldUpdated = (dataField: EntityDataField | undefined) =>
        onIntraEntityCalculationUpdated({ ...intraEntityCalculation, baseField: dataField?.fieldName ?? "" })

    const onAddOperation = () => {
        onIntraEntityCalculationUpdated({
            ...intraEntityCalculation,
            operations: [
                ...intraEntityCalculation.operations,
                {
                    ordinal: intraEntityCalculation.operations.length + 1
                }
            ]
        })
    }

    const onIntraEntityCalculationOperationUpdated = (updatedOperation: IntraEntityCalculationOperation) =>
        onIntraEntityCalculationUpdated({
            ...intraEntityCalculation,
            operations: intraEntityCalculation.operations.map(operation => (operation.ordinal === updatedOperation.ordinal ? updatedOperation : operation))
        })

    const onIntraEntityCalculationOperationRemoved = (removedOperation: IntraEntityCalculationOperation) =>
        onIntraEntityCalculationUpdated({
            ...intraEntityCalculation,
            operations: intraEntityCalculation.operations.filter(operation => operation !== removedOperation)
        })

    return (
        <div>
            <div className="d-flex flex-column mb-3">
                <div className="d-flex">
                    <span className="mb-1 ms-1 text-white no-select">The field value is calculated from:</span>
                </div>
                <EntityDataFieldDropdown
                    options={availableDataFields}
                    onOptionSelected={onBaseFieldUpdated}
                    selectedOption={selectedBaseField}
                    ariaLabel="base-field"
                />
            </div>
            {intraEntityCalculation.operations.map(intraEntityCalculationOperation => (
                <div className="d-flex mb-3" key={intraEntityCalculationOperation.ordinal}>
                    <IntraEntityCalculationOperationConfig
                        availableDataFields={availableDataFields}
                        intraEntityCalculationOperation={intraEntityCalculationOperation}
                        onIntraEntityCalculationOperationUpdated={onIntraEntityCalculationOperationUpdated}
                        onIntraEntityCalculationOperationRemoved={onIntraEntityCalculationOperationRemoved}
                    />
                </div>
            ))}
            {intraEntityCalculation.operations.length < MAX_OPERATIONS && (
                <div className="d-flex m-1 text-white">
                    <BlueButton className="ms-auto" iconClasses="fa fa-plus" label="Add Operation" onClick={onAddOperation} ariaLabel="add-operation" />
                </div>
            )}
        </div>
    )
}

export default IntraEntityCalculationConfigForm
