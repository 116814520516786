import Lookup from "../../../../types/Lookup"
import EntityConfig from "../../../entityConfig/types/EntityConfig"
import { EntityResultWithPreviousStateDto } from "./types/EntityResultWithPreviousStateDto"
import Note, { NoteType } from "./types/Note"
import { ReactNode } from "react"
import { formatFieldValueForDataType } from "../../../../library/grid/util"
import { exists } from "../../../../library/helpers/tsUtils"
import { v4 as uuid } from "uuid"
import * as Sentry from "@sentry/react"

export const groupEntityNotes = (res: EntityResultWithPreviousStateDto[], entityConfigs: EntityConfig[], lookups: Lookup[], showPreviousValues: boolean) => {
    const groupedNotes: { [correlationId: string]: Note[] } = res.reduce<{ [requestId: string]: Note[] }>((groups, update) => {
        const requestId = update.entityFields.textFields.text_invevo_request_id ?? uuid()
        const hasOtherNotesForGrouping = (res?.filter(u => u.entityFields.textFields.text_invevo_request_id === requestId) ?? []).length > 1
        groups[requestId] = groups[requestId] ?? []
        const note = convertFieldUpdatedDtoToNote(update, entityConfigs, lookups, showPreviousValues && !hasOtherNotesForGrouping)
        const notes = groups[requestId]
        if (note !== undefined && notes !== undefined) notes.push(note)
        return groups
    }, {})
    const notes: Note[] = Object.values(groupedNotes)
        .map(notes => {
            const note = notes.at(0)
            if (notes.length === 0 || note === undefined) return undefined
            if (notes.length === 1) return notes[0]
            const references = notes.map(n => n.noteId)
            return { ...note, noteIds: references }
        })
        .filter(exists)
    return notes
}

export const convertFieldUpdatedDtoToNote = (
    dto: EntityResultWithPreviousStateDto,
    entityConfigs: EntityConfig[],
    lookups?: Lookup[],
    showPreviousValues: boolean = true
): Note | undefined => {
    const updateSource = dto.entityFields.textFields.text_last_update_source
    const entityConfig = entityConfigs.find(c => c.reference === dto.entityTypeReference)
    const updates = [
        ...stringifyFieldUpdates(showPreviousValues, dto.entityFields.textFields, dto.previousEntityFields.textFields, entityConfig, lookups),
        ...stringifyFieldUpdates(showPreviousValues, dto.entityFields.numberFields, dto.previousEntityFields.numberFields, entityConfig, lookups),
        ...stringifyFieldUpdates(showPreviousValues, dto.entityFields.dateFields, dto.previousEntityFields.dateFields, entityConfig, lookups),
        ...stringifyFieldUpdates(showPreviousValues, dto.entityFields.booleanFields, dto.previousEntityFields.booleanFields, entityConfig, lookups)
    ]
    const commentUpdates = stringifyFieldUpdates(
        showPreviousValues,
        reduceToCommentFields(dto.entityFields.textFields),
        reduceToCommentFields(dto.previousEntityFields.textFields),
        entityConfig,
        lookups
    )
    const noteType: NoteType = commentUpdates.length === updates.length ? "comment" : "field update"
    if (updates.length === 0) return undefined

    if (!dto.entityFields.dateFields.date_last_update_time) Sentry.captureMessage(`Entity field update had no date_last_update_time on entity reference ${dto.reference}`, "warning")

    return {
        noteType: noteType,
        entityDisplayName: entityConfig?.displayName ?? "",
        noteId: dto.reference,
        noteIds: [dto.reference],
        noteEpochTime: dto.entityFields.dateFields.date_last_update_time ?? 0,
        note: (
            <div className="d-flex flex-column">
                {updateSource && <em>Updated via {updateSource}</em>}
                {updates}
            </div>
        ),
        userName: dto.entityFields.textFields.text_last_update_by ?? ""
    }
}

const stringifyFieldUpdates = <T extends string | number | boolean>(
    showPreviousValues: boolean,
    fields: Record<string, T>,
    previousFields: Record<string, T>,
    entityConfig?: EntityConfig,
    lookups?: Lookup[]
): ReactNode[] =>
    Object.keys(fields)
        .filter(key => !magicFields.includes(key) && fields[key] !== previousFields[key] && entityConfig?.fields.find(f => f.fieldName === key)?.trackChanges)
        .map((key, index) => {
            const configField = entityConfig?.fields.find(f => f.fieldName === key)

            const newVal = fields[key]
            const currentValue =
                newVal !== undefined && configField !== undefined
                    ? formatFieldValueForDataType(newVal.toString(), configField.dataType, lookups ?? [])
                    : newVal ?? "null"
            const prevVal = previousFields[key]
            const previousValue =
                prevVal !== undefined && configField !== undefined
                    ? formatFieldValueForDataType(prevVal.toString(), configField.dataType, lookups ?? [])
                    : prevVal ?? "null"

            const isCommentUpdate = key.startsWith("text_") && key.includes("comment")
            const shouldShowPreviousValue = showPreviousValues && !isCommentUpdate

            return (
                <span key={index}>
                    <strong>{configField?.displayName ?? key}</strong>
                    {" updated to "}
                    <strong>{currentValue}</strong>
                    {shouldShowPreviousValue && (
                        <>
                            {" from "}
                            <strong>{previousValue}</strong>
                        </>
                    )}
                </span>
            )
        })
        .filter(exists)

const reduceToCommentFields = (fields: { [key: string]: string }): { [key: string]: string } =>
    Object.entries(fields).reduce<Record<string, string>>((obj, val) => {
        if (val[0] === "text_comment") obj[val[0]] = val[1]
        return obj
    }, {})

const magicFields = [
    "date_last_update_time",
    "text_last_update_by",
    "text_last_update_source",
    "text_last_update_note",
    "text_invevo_request_id",
    "text_correlation_id"
] as const
