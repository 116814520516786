import * as types from './actionTypes';
import * as importDocumentTypeApi from '../api/fileImportDocumentTypeApi';
import * as importLocationApi from '../api/fileImportLocationApi';
import * as importQueueApi from '../api/fileImportQueueApi';
import * as importFileTypeApi from '../api/fileImportFileTypeApi';
import * as importFileTypeTableApi from '../api/fileImportFileTypeTableApi';
import * as importFieldMapApi from '../api/fileImportFieldMapApi';
import * as refreshProcessApi from '../api/refreshProcessApi';
import displayError from '../utility/error';
import { showToastSuccessMessage } from '../api/toasterApi';

export const loadRefreshProcesses = client => dispatch => {
    refreshProcessApi.getRefreshProcesses(client, dispatch)
        .then(data => {
            dispatch({
                type: types.LOAD_REFRESH_PROCESS,
                refreshProcesses: data
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const setRefreshProcess = (client, refreshProcess) => dispatch => {
    refreshProcessApi.setRefreshProcess(client, dispatch)
        .then(() => {
            showToastSuccessMessage('Refresh requested');
            dispatch(loadRefreshProcesses());
            dispatch({
                type: types.REFRESH_PROCESS,
                refreshProcess: refreshProcess
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const loadDocumentType = (client, documentTypeId) => dispatch => {
    importDocumentTypeApi.getImportDocumentType(client, documentTypeId, dispatch)
        .then(data => {
            dispatch({
                type: types.FILE_DOCUMENT_TYPE_UPDATE,
                documentType: data,
                showForm: true
            });

            dispatch({
                type: types.SHOW_GRID,
                showGrid: { display: false }
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const createImportDocumentType = (client, documentType) => dispatch => {
    importDocumentTypeApi.createImportDocumentType(client, documentType, dispatch)
        .then(() => {
            showToastSuccessMessage('Record created');

            dispatch({
                type: types.FILE_DOCUMENT_TYPE_UPDATE,
                showForm: false
            });

            dispatch({
                type: types.SHOW_GRID,
                showGrid: { display: true }
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const updateImportDocumentType = (client, documentType) => dispatch => {
    importDocumentTypeApi.updateImportDocumentType(client, documentType, dispatch)
        .then(() => {
            showToastSuccessMessage('Record updated');

            dispatch({
                type: types.FILE_DOCUMENT_TYPE_UPDATE,
                showForm: false
            });

            dispatch({
                type: types.SHOW_GRID,
                showGrid: { display: true }
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export function newDocumentType() {
    return function (dispatch) {
        dispatch({
            type: types.FILE_DOCUMENT_TYPE_UPDATE,
            showForm: true
        });

        dispatch({
            type: types.SHOW_GRID,
            showGrid: { display: false }
        });
    }
}

export const deleteDocumentType = (client, documentType) => dispatch => {
    importDocumentTypeApi.deleteImportDocumentType(client, documentType.id, dispatch)
        .then(() => {
            showToastSuccessMessage('Record deleted');
            dispatch({
                type: types.FILE_DOCUMENT_TYPE_UPDATE,
                showForm: false,
                refreshGrid: true
            });
            dispatch({
                type: types.SHOW_GRID,
                showGrid: { display: true }
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const loadFileImportLocation = (client, fileImportLocationId) => dispatch => {
    importLocationApi.getFileImportLocation(client, fileImportLocationId, dispatch)
        .then(data => {
            data.showForm = true;

            dispatch({
                type: types.LOAD_FILE_IMPORT_LOCATION_SUCCESS,
                fileImportLocation: data
            });

            dispatch({
                type: types.SHOW_GRID,
                showGrid: { display: false }
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export function newFileImportLocation() {
    return function (dispatch) {
        const fileImportLocation = {
            id: '',
            importFileLocation: '',
            instance: 'NORMAL',
            isActive: '',
            showForm: true
        };

        dispatch({
            type: types.NEW_FILE_IMPORT_LOCATION_SUCCESS,
            fileImportLocation: fileImportLocation
        });

        dispatch({
            type: types.SHOW_GRID,
            showGrid: { display: false }
        });
    }
}

export const updateFileImportLocation = (client, fileImportLocation) => dispatch => {
    importLocationApi.updateFileImportLocation(client, fileImportLocation, dispatch)
        .then(() => {
            fileImportLocation.success = true;
            fileImportLocation.showForm = false;
            showToastSuccessMessage('Record updated');

            dispatch({
                type: types.UPDATE_FILE_IMPORT_LOCATION_SUCCESS,
                fileImportLocation: fileImportLocation
            });

            dispatch({
                type: types.SHOW_GRID,
                showGrid: { display: true }
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const createFileImportLocation = (client, fileImportLocation) => dispatch => {
    importLocationApi.createFileImportLocation(client, fileImportLocation, dispatch)
        .then(() => {
            fileImportLocation.success = true;
            fileImportLocation.showForm = false;
            showToastSuccessMessage('Record created');

            dispatch({
                type: types.CREATE_FILE_IMPORT_LOCATION_SUCCESS,
                fileImportLocation: fileImportLocation
            });

            dispatch({
                type: types.SHOW_GRID,
                showGrid: { display: true }
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const deleteFileImportLocation = (client, fileImportLocation) => dispatch => {
    importLocationApi.deleteFileImportLocation(client, fileImportLocation.id, dispatch)
        .then(() => {
            fileImportLocation.deleted = true;
            fileImportLocation.showForm = false;
            showToastSuccessMessage('Record deleted');

            dispatch({
                type: types.DELETE_FILE_IMPORT_LOCATION_SUCCESS,
                fileImportLocation: fileImportLocation
            });

            dispatch({
                type: types.SHOW_GRID,
                showGrid: { display: true }
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const loadFileImportQueue = (client, fileImportQueueId) => dispatch => {
    importQueueApi.getFileImportQueue(client, fileImportQueueId, dispatch)
        .then(data => {
            data.showForm = true;

            dispatch({
                type: types.LOAD_FILE_IMPORT_QUEUE_SUCCESS,
                fileImportQueue: data
            });

            dispatch({
                type: types.SHOW_GRID,
                showGrid: { display: false }
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export function newFileImportQueue() {
    return function (dispatch) {
        const fileImportQueue = {
            id: '',
            queueName: '',
            importFileType: '',
            fileWriteThresholdItems: '',
            fileWriteThresholdSeconds: '',
            fileNameTemplate: '',
            isInUse: '',
            showForm: true
        };

        dispatch({
            type: types.NEW_FILE_IMPORT_QUEUE_SUCCESS,
            fileImportQueue: fileImportQueue
        });

        dispatch({
            type: types.SHOW_GRID,
            showGrid: { display: false }
        });
    }
}

export const updateFileImportQueue = (client, fileImportQueue) => dispatch => {
    importQueueApi.updateFileImportQueue(client, fileImportQueue, dispatch)
        .then(() => {
            fileImportQueue.success = true;
            fileImportQueue.showForm = false;
            showToastSuccessMessage('Record updated');

            dispatch({
                type: types.UPDATE_FILE_IMPORT_QUEUE_SUCCESS,
                fileImportQueue: fileImportQueue
            });

            dispatch({
                type: types.SHOW_GRID,
                showGrid: { display: true }
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const createFileImportQueue = (client, fileImportQueue) => dispatch => {
    importQueueApi.createFileImportQueue(client, fileImportQueue, dispatch)
        .then(() => {
            fileImportQueue.success = true;
            fileImportQueue.showForm = false;
            showToastSuccessMessage('Record created');

            dispatch({
                type: types.CREATE_FILE_IMPORT_QUEUE_SUCCESS,
                fileImportQueue: fileImportQueue
            });

            dispatch({
                type: types.SHOW_GRID,
                showGrid: { display: true }
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const deleteFileImportQueue = (client, fileImportQueue) => dispatch => {
    importQueueApi.deleteFileImportQueue(client, fileImportQueue.id, dispatch)
        .then(() => {
            fileImportQueue.deleted = true;
            fileImportQueue.showForm = false;
            showToastSuccessMessage('Record deleted');

            dispatch({
                type: types.DELETE_FILE_IMPORT_QUEUE_SUCCESS,
                fileImportQueue: fileImportQueue
            });

            dispatch({
                type: types.SHOW_GRID,
                showGrid: { display: true }
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const loadFileType = (client, fileTypeId) => dispatch => {
    importFileTypeApi.getFileType(client, fileTypeId, dispatch)
        .then(data => {
            data.showForm = true;
            data.showGrids = true;

            dispatch({
                type: types.LOAD_FILE_TYPE_SUCCESS,
                fileType: data
            });

            dispatch({
                type: types.SHOW_GRID,
                showGrid: { display: false }
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export function newFileType() {
    return function (dispatch) {
        const fileType = {
            id: '',
            importSourceTypeId: '',
            importSourceType: '',
            importLocationId: '',
            importLocation: '',
            name: '',
            fileNameSpecification: '',
            fileExtension: '',
            regularExpressionMatch: '',
            resolutionOrder: '',
            schemaFileName: '',
            recordXPath: '',
            xmlElementName: '',
            destinationTableId: '',
            isActive: '',
            csvDelimiter: '',
            csvQuoted: '',
            csvHasHeaders: '',
            showForm: true,
            showGrids: true
        };

        dispatch({
            type: types.NEW_FILE_TYPE_SUCCESS,
            fileType: fileType
        });

        dispatch({
            type: types.SHOW_GRID,
            showGrid: { display: false }
        });
    }
}

export const updateFileType = (client, fileType) => dispatch => {
    importFileTypeApi.updateFileType(client, fileType, dispatch)
        .then(() => {
            fileType.success = true;
            fileType.showForm = false;
            showToastSuccessMessage('Record updated');

            dispatch({
                type: types.UPDATE_FILE_TYPE_SUCCESS,
                fileType: fileType
            });

            dispatch({
                type: types.SHOW_GRID,
                showGrid: { display: true }
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const createFileType = (client, fileType) => dispatch => {
    importFileTypeApi.createFileType(client, fileType, dispatch)
        .then(() => {
            fileType.success = true;
            fileType.showForm = false;
            showToastSuccessMessage('Record created');

            dispatch({
                type: types.CREATE_FILE_TYPE_SUCCESS,
                fileType: fileType
            });

            dispatch({
                type: types.SHOW_GRID,
                showGrid: { display: true }
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const deleteFileType = (client, fileType) => dispatch => {
    importFileTypeApi.deleteFileType(client, fileType.id, dispatch)
        .then(() => {
            fileType.deleted = true;
            fileType.showForm = false;
            showToastSuccessMessage('Record deleted');

            dispatch({
                type: types.DELETE_FILE_TYPE_SUCCESS,
                fileType: fileType
            });

            dispatch({
                type: types.SHOW_GRID,
                showGrid: { display: true }
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export function cancelFileTypeTable(fileTypeTable) {
    return function (dispatch) {
        fileTypeTable.showGrid = true;
        fileTypeTable.showForm = false;

        dispatch({
            type: types.CANCEL_FILE_TYPE_TABLE_SUCCESS,
            fileTypeTable: fileTypeTable
        });
    }
}

export const loadFileTypeTable = (client, fileTypeTableId) => dispatch => {
    importFileTypeTableApi.getFileTypeTable(client, fileTypeTableId, dispatch)
        .then(data => {
            data.showForm = true;
            data.showGrid = false;

            dispatch({
                type: types.LOAD_FILE_TYPE_TABLE_SUCCESS,
                fileTypeTable: data
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export function newFileTypeTable() {
    return function (dispatch) {
        const fileTypeTable = {
            id: '',
            importTableId: '',
            insertOnly: '',
            updateOnly: '',
            resolutionOrder: '',
            treatEmptyStringAsNotSupplied: '',
            isMasterTable: '',
            showForm: true,
            showGrid: false
        };

        dispatch({
            type: types.NEW_FILE_TYPE_TABLE_SUCCESS,
            fileTypeTable: fileTypeTable
        });
    }
}

export const updateFileTypeTable = (client, fileTypeTable) => dispatch => {
    importFileTypeTableApi.updateFileTypeTable(client, fileTypeTable, dispatch)
        .then(() => {
            fileTypeTable.success = true;
            fileTypeTable.showForm = false;
            fileTypeTable.showGrid = true;
            showToastSuccessMessage('Record updated');

            dispatch({
                type: types.UPDATE_FILE_TYPE_TABLE_SUCCESS,
                fileTypeTable: fileTypeTable
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const createFileTypeTable = (client, fileTypeTable) => dispatch => {
    importFileTypeTableApi.createFileTypeTable(client, fileTypeTable, dispatch)
        .then(() => {
            fileTypeTable.success = true;
            fileTypeTable.showForm = false;
            fileTypeTable.showGrid = true;
            showToastSuccessMessage('Record created');

            dispatch({
                type: types.CREATE_FILE_TYPE_TABLE_SUCCESS,
                fileTypeTable: fileTypeTable
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const deleteFileTypeTable = (client, fileTypeTable) => dispatch => {
    importFileTypeTableApi.deleteFileTypeTable(client, fileTypeTable.id, dispatch)
        .then(() => {
            fileTypeTable.deleted = true;
            fileTypeTable.showForm = false;
            fileTypeTable.showGrid = true;
            showToastSuccessMessage('Record deleted');

            dispatch({
                type: types.DELETE_FILE_TYPE_TABLE_SUCCESS,
                fileTypeTable: fileTypeTable
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export function cancelFieldMap(fieldMap) {
    return function (dispatch) {
        fieldMap.showGrid = true;
        fieldMap.showForm = false;

        dispatch({
            type: types.CANCEL_FIELD_MAP_SUCCESS,
            fieldMap: fieldMap
        });
    }
}

export const loadFieldMap = (client, fieldMapId) => dispatch => {
    importFieldMapApi.getFieldMap(client, fieldMapId, dispatch)
        .then(data => {
            data.showForm = true;
            data.showGrid = false;

            dispatch({
                type: types.LOAD_FIELD_MAP_SUCCESS,
                fieldMap: data
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export function newFieldMap() {
    return function (dispatch) {
        const fieldMap = {
            id: '',
            fileTypeId: '',
            recordInstance: '',
            destinationFieldId: '',
            sourceFieldNameOverride: '',
            sourceXPath: '',
            sourceFormat: '',
            staticValue: '',
            defaultValue: '',
            useDefaultForInsertOnly: '',
            foreignKeyLookupId: '',
            allowCascadeInsert: '',
            allowEmptyString: '',
            prohibitUpdates: '',
            stripCharactersToCompare: '',
            isNativeKey: '',
            isBaseRecordId: '',
            isContent: '',
            isExtensionField: '',
            isMandatory: '',
            isActive: '',
            concatenationDelimiter: '',
            concatenationOrder: '',
            processMultipleLookupResults: '',
            showForm: true,
            showGrid: false
        };

        dispatch({
            type: types.NEW_FIELD_MAP_SUCCESS,
            fieldMap: fieldMap
        });
    }
}

export const updateFieldMap = (client, fieldMap) => dispatch => {
    importFieldMapApi.updateFieldMap(client, fieldMap, dispatch)
        .then(() => {
            fieldMap.success = true;
            fieldMap.showForm = false;
            fieldMap.showGrid = true;
            showToastSuccessMessage('Record updated');

            dispatch({
                type: types.UPDATE_FIELD_MAP_SUCCESS,
                fieldMap: fieldMap
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const createFieldMap = (client, fieldMap) => dispatch => {
    importFieldMapApi.createFieldMap(client, fieldMap, dispatch)
        .then(() => {
            fieldMap.success = true;
            fieldMap.showForm = false;
            fieldMap.showGrid = true;
            showToastSuccessMessage('Record created');

            dispatch({
                type: types.CREATE_FIELD_MAP_SUCCESS,
                fieldMap: fieldMap
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export const deleteFieldMap = (client, fieldMap) => dispatch => {
    importFieldMapApi.deleteFieldMap(client, fieldMap.id, dispatch)
        .then(() => {
            fieldMap.deleted = true;
            fieldMap.showForm = false;
            fieldMap.showGrid = true;
            showToastSuccessMessage('Record deleted');

            dispatch({
                type: types.DELETE_FIELD_MAP_SUCCESS,
                fieldMap: fieldMap
            });
        }).catch(function (err) {
            displayError(err);
        });
}

export function refresh() {
    return function (dispatch) {
        const refresh = {
            update: true
        };

        dispatch({
            type: types.REFRESH,
            refresh: refresh
        });
    }
}

export function cancel() {
    return function (dispatch) {
        const fileImportLocation = {
            id: '',
            importFileLocation: '',
            instance: 'NORMAL',
            isActive: '',
            showForm: false
        };

        dispatch({
            type: types.FILE_IMPORT_LOCATION_CANCEL,
            fileImportLocation: fileImportLocation
        });

        const fileImportQueue = {
            id: '',
            queueName: '',
            importFileType: '',
            fileWriteThresholdItems: '',
            fileWriteThresholdSeconds: '',
            fileNameTemplate: '',
            isInUse: '',
            showForm: false
        };

        dispatch({
            type: types.FILE_IMPORT_QUEUE_CANCEL,
            fileImportQueue: fileImportQueue
        });

        const fileType = {
            id: '',
            importSourceTypeId: '',
            importSourceType: '',
            importLocationId: '',
            importLocation: '',
            name: '',
            fileNameSpecification: '',
            fileExtension: '',
            regularExpressionMatch: '',
            resolutionOrder: '',
            schemaFileName: '',
            recordXPath: '',
            xmlElementName: '',
            destinationTableId: '',
            isActive: '',
            csvDelimiter: '',
            csvQuoted: '',
            csvHasHeaders: '',
            showForm: false
        };

        dispatch({
            type: types.FILE_TYPE_CANCEL,
            fileType: fileType
        });

        dispatch({
            type: types.FILE_DOCUMENT_TYPE_UPDATE,
            showForm: false
        });

        dispatch({
            type: types.SHOW_GRID,
            showGrid: { display: true },
            showDocumentTypeForm: false
        });
    }
}
