
const OutputIcon = ({ iconName, title }) => {
    return (
        <div className={`text-white pt-3`} style={{ fontSize: "19px" }}>
            <i className={`${iconName} me-3 mt-2`}></i>
            {title}
        </div>
    )
}

export default OutputIcon