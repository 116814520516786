import React, { Component } from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import ValidatingInput from '../../common/ValidatingInput';
import { filter, forEach } from 'lodash';
import {
  executeAuthAsyncGet,
  executeAuthAsyncPost,
  executeAsyncResetGet
} from '../../../utility/asyncSupport';
import {
  showToastSuccessMessage,
  showToastErrorMessage,
  showToastInfoMessage
} from '../../../api/toasterApi';

import DatePicker from 'react-datepicker';

class AddLookup extends Component {
  constructor(props, context) {
    super(props, context);

    this.onInputChanged = this.onInputChanged.bind(this);
    this.onLookupCheckboxChanged = this.onLookupCheckboxChanged.bind(this);
    this.onLookupDropdownChanged = this.onLookupDropdownChanged.bind(this);

    this.save = this.save.bind(this);
    this.onSaveSuccess = this.onSaveSuccess.bind(this);
    this.cancel = this.cancel.bind(this);

    this.state = this.defaultFormState([]);

    this.enableSave = this.enableSave.bind(this);
    this.disableSave = this.disableSave.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.isControlDisabled = this.isControlDisabled.bind(this);
  }

  componentDidMount() {
    if (this.props.onAdd != null) {
      this.props.onAdd(this.initAddView.bind(this));
    }

    if (this.props.onEdit != null) {
      this.props.onEdit(this.initEditview.bind(this));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const that = this;

    if (nextProps !== this.props) {
      that.setState((previousState) => ({
        ...previousState,
        heading: `Add ${nextProps.Lookup.Name} (Id:${nextProps.LookupLineItemId})`,
        isEditMode: nextProps.IsEditMode
      }));
    }

    if (nextProps.LookupLineItemId !== null && nextProps !== this.props) {
      that.setState((previousState) => ({
        ...previousState,
        lookupItem: {
          ...previousState.lookupItem,
          LookupItemLineItem: that.getLookupItem(nextProps)
        },
        heading: `Add ${nextProps.Lookup.Name} (Id:${nextProps.LookupLineItemId})`,
        isEditMode: nextProps.IsEditMode
      }));
    }

    if (nextProps.LookupLineItemId === null && nextProps !== this.props) {
      that.setState((previousState) => ({
        ...previousState,
        lookupItem: {
          ...previousState.lookupItem,
          LookupItemLineItem: that.getDefaultLookupItem(nextProps)
        },
        heading: `Add ${nextProps.Lookup.Name} (Id:${nextProps.LookupLineItemId})`,
        isEditMode: nextProps.IsEditMode
      }));
    }
  }

  defaultFormState() {
    const that = this;

    return {
      lookupItem: {
        LookupItemLineItem: that.getDefaultLookupItem(this.props),
        canEdit: true
      },
      heading: 'Add ' + this.props.Lookup.Name,
      canSave: false,
      isEditMode: this.props.IsEditMode
    };
  }

  getDefaultLookupItem(props) {
    const that = this;
    const lookupItem = {};
    const currentProps =
      props === null || props === undefined ? that.props : props;
    const isScoreCard = props.Lookup.Name === 'ScoreCard';

    const lookupColumnDefinition = currentProps.Lookup.LookupItems;

    forEach(lookupColumnDefinition, (lookupColumn) => {
      if (lookupColumn.Name === 'Id' && isScoreCard) {
        lookupItem[lookupColumn.Name] = {
          name: lookupColumn.Name,
          value: '',
          required: true
        };
      }

      if (
        lookupColumn.Name !== 'Id' &&
        lookupColumn.Name !== 'CreatedDateTime' &&
        lookupColumn.Name !== 'ModifiedDateTime'
      ) {
        if (lookupColumn.MemberType === 'boolean') {
          lookupItem[lookupColumn.Name] = {
            name: lookupColumn.Name,
            value: false,
            required: lookupColumn.IsRequired
          };
        } else {
          lookupItem[lookupColumn.Name] = {
            name: lookupColumn.Name,
            value: '',
            required: lookupColumn.IsRequired
          };
        }
      }
    });
    return lookupItem;
  }

  getLookupItem(props) {
    const that = this;
    const lookupItem = {};
    const currentProps =
      props === null || props === undefined ? this.props : props;
    const lookupLineItem = that.getLookupLineValues(
      currentProps.LookupLineItemId
    );
    const isScoreCard = props.Lookup.Name === 'ScoreCard';

    const lookupColumnDefinition = currentProps.Lookup.LookupItems;

    forEach(lookupColumnDefinition, (lookupColumn) => {
      if (lookupColumn.Name === 'Id' && isScoreCard) {
        lookupItem[lookupColumn.Name] = {
          name: lookupColumn.Name,
          value: lookupLineItem[0][lookupColumn.Name],
          required: true
        };
      }

      if (
        lookupColumn.Name !== 'Id' &&
        lookupColumn.Name !== 'CreatedDateTime' &&
        lookupColumn.Name !== 'ModifiedDateTime'
      ) {
        lookupItem[lookupColumn.Name] = {
          name: lookupColumn.Name,
          value: lookupLineItem[0][lookupColumn.Name],
          required: lookupColumn.IsRequired
        };
      }
    });
    return lookupItem;
  }

  initAddView() {
    const that = this;
    that.setState(this.defaultFormState());
  }

  initEditview() {
    const that = this;
    const hasEditItem =
      that.props.LookupLineItemId !== undefined &&
      that.props.LookupLineItemId !== null;

    if (hasEditItem) {
      that.setState((previousState) => ({
        ...previousState,
        lookupItem: {
          ...previousState.lookupItem,
          LookupItemLineItem: that.getLookupItem(that.props)
        },
        canSave: true
      }));
    }
  }

  enableSave() {
    this.setState({ canSave: true });
  }

  disableSave() {
    this.setState({ canSave: false });
  }

  saveEnabled() {
    const that = this;
    let result = false;

    if (that.props.Lookup.Name === 'ScheduledDataExport') {
      result = that.checkSheduledExportCase();
      return result;
    }

    if (that.props.Lookup.Name === 'GlobalConfigurationActive') {
      result = that.checkDropDownValues();
      return result;
    }

    if (that.checkForOnlyDropDownItems() && that.checkDropDownValues()) {
      result = true;
      return result;
    }

    result = that.checkValues() && that.checkDropDownValues();
    return result;
  }

  checkValues() {
    const that = this;
    let result = true;
    const currentLookupValues = that.state.lookupItem.LookupItemLineItem;

    forEach(currentLookupValues, (item) => {
      const lineItem = filter(that.props.Lookup.LookupItems, function (items) {
        return items.Name === item.name;
      });
      const required = lineItem[0].IsRequired;
      const type = lineItem[0].MemberType;
      const maxLength = lineItem[0].MaxLength;

      if (type !== 'boolean') {
        if (item.value !== null) {
          if (maxLength !== null) {
            if (
              ((item.value.length === 0 && required === true) ||
                item.value.length > maxLength) &&
              result !== false
            ) {
              result = false;
            }
          } else {
            if (
              item.value.length === 0 &&
              required === true &&
              result !== false
            ) {
              result = false;
            }
          }
        }
      }
    });

    return result;
  }

  isUUID(s) {
    return s.matches(
      '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
    );
  }

  checkSheduledExportCase() {
    const that = this;
    let result = true;
    const currentLookupValues = that.state.lookupItem.LookupItemLineItem;

    forEach(currentLookupValues, (item) => {
      const lineItem = filter(that.props.Lookup.LookupItems, function (items) {
        return items.Name === item.name;
      });
      if (item.name !== 'LastRunDateTime' && item.name !== 'Id') {
        if (lineItem[0].IsRequired === true) {
          result = item.value !== null && item.value !== '' ? true : false;

          if (result === false) {
            return result;
          }
        }
      }
    });

    return result;
  }

  checkForOnlyDropDownItems() {
    const that = this;

    const dropDownItems = filter(
      that.props.Lookup.LookupItems,
      function (dropDownItems) {
        return dropDownItems.ForeignKeyTableName !== null;
      }
    );
    const formItems = filter(
      that.props.Lookup.LookupItems,
      function (formItem) {
        return formItem.Name !== 'Id';
      }
    );

    return dropDownItems.length === formItems.length;
  }

  collectSaveValues() {
    const that = this;
    const saveValues = [];
    const currentLookupValues = that.state.lookupItem.LookupItemLineItem;

    forEach(currentLookupValues, (item) => {
      if (item.value !== null && item.value !== '') {
        saveValues.push({
          columnName: item.name,
          columnValue: item.value.toString()
        });
      } else {
        saveValues.push({ columnName: item.name, columnValue: 'null' });
      }
    });
    return saveValues;
  }

  collectEditValues() {
    const currentLookupValues = this.state.lookupItem.LookupItemLineItem;

    /* eslint-disable no-unused-vars */
    return Object.entries(currentLookupValues).reduce(
      (acc, [_, { name, value }]) => {
        acc.push({ columnName: name, columnValue: value });
        return acc;
      },
      []
    );
  }

  save() {
    const that = this;
    const saveValues = {};

    saveValues['tableId'] = that.props.Lookup.AdminLookupId;
    saveValues['rowId'] = that.props.LookupLineItemId;
    saveValues['rowvalues'] = that.state.isEditMode
      ? that.collectEditValues()
      : that.collectSaveValues();

    this.setState({ canSave: false });
    showToastInfoMessage('Lookup value submitted.');

    if (that.state.isEditMode) {
      that.props.executeAuthAsyncPost(
        that.props.client,
        'lookupadmin/data/update',
        'UPDATE_LOOKUPITEM',
        saveValues,
        that.onSaveSuccess,
        function (/*error*/) {
          showToastErrorMessage('Error while updating lookup value.');
          this.setState({ canSave: true });
        }
      );
    } else {
      that.props.executeAuthAsyncPost(
        that.props.client,
        'lookupadmin/data/create',
        'CREATE_LOOKUPITEM',
        saveValues,
        that.onSaveSuccess,
        function (/*error*/) {
          showToastErrorMessage('Error while creating lookup value.');
          this.setState({ canSave: true });
        }
      );
    }
  }

  onSaveSuccess(response) {
    if (response.HasError !== false) {
      switch (response.ErrorCode) {
        case 999:
          showToastErrorMessage(response.Error);
          break;
        case 998:
          showToastErrorMessage('Cannot insert duplicates into this table');
          break;
        case 997:
          showToastErrorMessage(
            'Numeric value is out of bounds for this record'
          );
          break;
        case 996:
          showToastErrorMessage('ScoreCard Id is an invalid Guid');
          break;
        default:
          showToastErrorMessage('Error while processing lookup item.');
      }
    } else {
      showToastSuccessMessage('Lookup item processed successfully.');
      this.setState(this.defaultFormState([]));
      if (this.props.onSaved != null) {
        this.props.onSaved();
      }
    }
  }

  cancel() {
    this.setState(this.defaultFormState([]));
    if (this.props.onCancelled != null) {
      this.props.onCancelled();
    }
  }

  checkDropDownValues() {
    let result = true;
    const that = this;

    const dropDownItems = filter(
      that.props.Lookup.LookupItems,
      function (dropDownItems) {
        return dropDownItems.ForeignKeyTableName !== null;
      }
    );
    const lineItem = that.state.lookupItem;

    if (dropDownItems !== null) {
      forEach(dropDownItems, (item) => {
        if (
          item.IsRequired &&
          lineItem.LookupItemLineItem !== null &&
          lineItem.LookupItemLineItem !== undefined
        ) {
          if (
            that.isBlank(lineItem.LookupItemLineItem[item.Name].value) &&
            result !== false
          ) {
            result = false;
          }
        }
      });
    }
    return result;
  }

  isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  onLookupDropdownChanged(value, name, fKeyTableValues) {
    const dropDownValue = fKeyTableValues.find((t) => t.Id === value);

    this.setState((previousState) => ({
      ...previousState,
      lookupItem: {
        ...previousState.lookupItem,
        LookupItemLineItem: {
          ...previousState.lookupItem.LookupItemLineItem,
          [name]: {
            ...previousState.lookupItem.LookupItemLineItem[name],
            value: dropDownValue.Id
          }
        }
      }
    }));
  }

  onLookupCheckboxChanged(event) {
    const that = this;
    const value = event.target.checked;
    const name = event.target.name;

    that.setState((previousState) => ({
      ...previousState,
      lookupItem: {
        ...previousState.lookupItem,
        LookupItemLineItem: {
          ...previousState.lookupItem.LookupItemLineItem,
          [name]: {
            ...previousState.lookupItem.LookupItemLineItem[name],
            value: value
          }
        }
      }
    }));
  }

  onInputChanged(event) {
    const that = this;
    let value = event.target.value;
    const name = event.target.name;

    const type = filter(that.props.Lookup.LookupItems, function (item) {
      return item.Name === name;
    });

    if (type[0].MemberType !== 'boolean') {
      value = value.toString();
    }

    this.setState((previousState) => ({
      ...previousState,
      lookupItem: {
        ...previousState.lookupItem,
        LookupItemLineItem: {
          ...previousState.lookupItem.LookupItemLineItem,
          [name]: {
            ...previousState.lookupItem.LookupItemLineItem[name],
            value: value
          }
        }
      }
    }));
  }

  onDateChange(name, dt) {
    const that = this;
    if (dt !== null) {
      this.setState((previousState) => ({
        ...previousState,
        lookupItem: {
          ...previousState.lookupItem,
          LookupItemLineItem: {
            ...previousState.lookupItem.LookupItemLineItem,
            [name]: {
              ...previousState.lookupItem.LookupItemLineItem[name],
              value: that.formatDate(dt)
            }
          }
        }
      }));
    }
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  getLookupLineItemColumValue(columnName) {
    const that = this;
    const lookupLineItem = that.getLookupLineValues(
      this.props.LookupLineItemId
    );
    return lookupLineItem[columnName].value;
  }

  getLookupLineValues(lookupItemId) {
    const that = this;
    if (lookupItemId !== null) {
      return filter(that.props.Lookup.TableValues, function (itemValue) {
        return itemValue.Id === lookupItemId;
      });
    }
    return null;
  }

  getInputType(memberType) {
    let returnInputType = '';
    switch (memberType) {
      case 'number':
        returnInputType = 'number';
        break;
      case 'decimal':
        returnInputType = 'number';
        break;
      case 'time':
        returnInputType = 'time';
        break;
      default:
        returnInputType = 'text';
    }

    return returnInputType;
  }

  isControlDisabled(propertyName) {
    if (propertyName === 'LastRunDateTime') {
      return true;
    }

    const isSystemRequiredField = this.state.lookupItem.LookupItemLineItem[
      'SystemRequired'
    ];
    if (isSystemRequiredField && isSystemRequiredField.value) {
      return propertyName !== 'IsInUse';
    }
    return false;
  }

  renderLookUpItemTextFieldRequired(lookupItem) {
    const input = 'input-' + lookupItem.Name;
    const validationText =
      lookupItem.MaxLength !== null
        ? lookupItem.Name +
        ' should be between 1 and ' +
        lookupItem.MaxLength +
        ' characters'
        : '';
    const validationsLengthText =
      lookupItem.MemberType !== 'number'
        ? 'minLength:1,maxLength:' + lookupItem.MaxLength
        : 'minLength:1';

    const type = this.getInputType(lookupItem.MemberType);

    const value =
      this.state.lookupItem.LookupItemLineItem !== undefined &&
        this.state.lookupItem.LookupItemLineItem !== null
        ? this.state.lookupItem.LookupItemLineItem[lookupItem.Name].value
        : '';
    const min = lookupItem.MemberType === 'decimal' ? '0.00' : '';
    const step = lookupItem.MemberType === 'decimal' ? '0.01' : '';
    const disabled = this.isControlDisabled(lookupItem.Name);

    return (
      <ValidatingInput
        id={input}
        key={input}
        name={lookupItem.Name}
        type={type}
        placeholder={lookupItem.Name}
        hideLabel={true}
        required
        disabled={disabled}
        value={value}
        onChange={this.onInputChanged}
        validationError={validationText}
        validations={validationsLengthText}
        min={min}
        step={step}
      />
    );
  }

  renderLookUpItemTextField(lookupItem) {
    const input = 'input-' + lookupItem.Name;
    const value =
      this.state.lookupItem.LookupItemLineItem !== undefined &&
        this.state.lookupItem.LookupItemLineItem !== null
        ? this.state.lookupItem.LookupItemLineItem[lookupItem.Name].value
        : '';
    const type = this.getInputType(lookupItem.MemberType);
    const min = lookupItem.MemberType === 'decimal' ? '0.00' : '';
    const step = lookupItem.MemberType === 'decimal' ? '0.01' : '';
    const disabled = this.isControlDisabled(lookupItem.Name);

    return (
      <div className="form-group" key={'div-' + input}>
        <input
          id={input}
          key={input}
          name={lookupItem.Name}
          type={type}
          className="form-control"
          placeholder={lookupItem.Name}
          title={lookupItem.Name}
          value={value}
          onChange={this.onInputChanged}
          disabled={disabled}
          style={{ width: 480 }}
          maxLength={lookupItem.MaxLength}
          min={min}
          step={step}
        />
      </div>
    );
  }

  renderLookupItemCheckboxField(lookupItem) {
    const value =
      this.state.lookupItem.LookupItemLineItem !== undefined &&
        this.state.lookupItem.LookupItemLineItem !== null
        ? this.state.lookupItem.LookupItemLineItem[lookupItem.Name].value
        : false;
    const disabled = this.isControlDisabled(lookupItem.Name);
    const key = `from-group-${lookupItem.Name}`;
    return (
      <div className="form-group" key={key}>
        <label className="my-label">
          <input
            type="checkbox"
            id={lookupItem.Name}
            name={lookupItem.Name}
            checked={value}
            onChange={this.onLookupCheckboxChanged}
            disabled={disabled}
          />{' '}
          {lookupItem.Name}
        </label>
      </div>
    );
  }

  renderLookupDropDownItems(lookupItem) {
    const listItems = lookupItem.ForeignKeyTableValues.map((t) => {
      const key = lookupItem.ForeignKeyTableName + t.Id;

      switch (lookupItem.Name) {
        case 'TableId':
          return (
            <li
              key={key}
              className="dropdown-item"
              onClick={this.onLookupDropdownChanged.bind(
                this,
                t.Id,
                lookupItem.Name,
                lookupItem.ForeignKeyTableValues
              )}
            >
              {t.TableName}
            </li>
          );
        case 'CreditCardTypeId':
          return (
            <li
              key={key}
              className="dropdown-item"
              onClick={this.onLookupDropdownChanged.bind(
                this,
                t.Id,
                lookupItem.Name,
                lookupItem.ForeignKeyTableValues
              )}
            >
              {t.Type}
            </li>
          );
        default:
          return (
            <li
              key={key}
              className="dropdown-item"
              onClick={this.onLookupDropdownChanged.bind(
                this,
                t.Id,
                lookupItem.Name,
                lookupItem.ForeignKeyTableValues
              )}
            >
              {t.Name}
            </li>
          );
      }
    });
    return listItems;
  }

  renderLookupDropDownItemsLabel(lookupItem) {
    let label = 'Select ' + lookupItem.Name;
    const value =
      this.state.lookupItem.LookupItemLineItem !== undefined
        ? this.state.lookupItem.LookupItemLineItem[lookupItem.Name].value
        : 0;
    const itemValues = filter(
      lookupItem.ForeignKeyTableValues,
      function (itemValue) {
        return itemValue.Id === value;
      }
    );

    if (itemValues.length !== 0) {
      switch (lookupItem.Name) {
        case 'TableId':
          label = lookupItem.Name + ': ' + itemValues[0].TableName;
          break;
        case 'CreditCardTypeId':
          label = lookupItem.Name + ': ' + itemValues[0].Type;
          break;
        default:
          label = lookupItem.Name + ': ' + itemValues[0].Name;
      }
    }
    return label;
  }

  renderLookupDropDownListField(lookupItem) {
    const key = `form-group-${lookupItem.Name}`;
    return (
      <div className="form-group" key={key}>
        <ul className="nav" id={lookupItem.Name}>
          <li>
            {this.isControlDisabled(lookupItem.Name) ? (
              <b>{this.renderLookupDropDownItemsLabel(lookupItem)}</b>
            ) : (
              <>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  id="dropdownMenuButton"
                  className="nav-link dropdown-toggle boldTitle noPaddingLeft"
                  data-bs-toggle="dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  {this.renderLookupDropDownItemsLabel(lookupItem)}
                  <b className="caret"></b>
                </a>
              </>
            )}
            <ul aria-labelledby="dropdownMenuButton" className="dropdown-menu ddscroll">
              {this.renderLookupDropDownItems(lookupItem)}
            </ul>
          </li>
        </ul>
      </div>
    );
  }

  renderDateField(lookupItem) {
    const value =
      this.state.lookupItem.LookupItemLineItem[lookupItem.Name].value === ''
        ? null
        : new Date(
          this.state.lookupItem.LookupItemLineItem[lookupItem.Name].value
        );
    const disabled = this.isControlDisabled(lookupItem.Name);

    return (
      <div className="form-group">
        <DatePicker
          key={lookupItem.Name}
          name={lookupItem.Name}
          className="form-control"
          selected={value}
          placeholderText={lookupItem.Name}
          onChange={(e) => this.onDateChange(lookupItem.Name, e)}
          disabled={disabled}
        />
      </div>
    );
  }

  renderFormItems() {
    const that = this;
    const formItems = [];
    const lookupColumnDefinition = that.props.Lookup.LookupItems;
    const isScoreCard = that.props.Lookup.Name === 'ScoreCard';

    if (lookupColumnDefinition.length > 0) {
      forEach(lookupColumnDefinition, (lookupColumn) => {
        if (lookupColumn.Name === 'Id' && isScoreCard) {
          formItems.push(that.renderLookUpItemTextFieldRequired(lookupColumn));
        }

        if (
          lookupColumn.Name !== 'Id' &&
          lookupColumn.Name !== 'CreatedDateTime' &&
          lookupColumn.Name !== 'ModifiedDateTime'
        ) {
          //dropdown list
          if (lookupColumn.MatchingProperty !== '') {
            formItems.push(that.renderLookupDropDownListField(lookupColumn));
          } else {
            //date field
            if (
              lookupColumn.MemberType === 'date' &&
              lookupColumn.Name !== 'LastRunDateTime'
            ) {
              formItems.push(that.renderDateField(lookupColumn));
            }

            //text box
            if (
              lookupColumn.MemberType === 'number' ||
              lookupColumn.MemberType === 'decimal' ||
              lookupColumn.MemberType === 'time' ||
              lookupColumn.MemberType === 'string' ||
              lookupColumn.Name === 'LastRunDateTime'
            ) {
              if (lookupColumn.IsRequired === true) {
                formItems.push(
                  that.renderLookUpItemTextFieldRequired(lookupColumn)
                );
              } else {
                formItems.push(that.renderLookUpItemTextField(lookupColumn));
              }
            }
            //check box
            if (lookupColumn.MemberType === 'boolean') {
              formItems.push(that.renderLookupItemCheckboxField(lookupColumn));
            }
          }
        }
      });
    }

    return formItems;
  }

  render() {
    const saveButton = this.state.lookupItem.canEdit ? (
      <button
        type="button"
        onClick={this.save}
        className="btn btn-default"
        disabled={!this.saveEnabled()}
      >
        Save Lookup Value
      </button>
    ) : (
      <button type="button" className="btn btn-default" disabled>
        Save Lookup Value
      </button>
    );

    return (
      <div>
        <Formsy
          key="add-lookup-form"
          onValid={this.enableSave}
          onInvalid={this.disableSave}
          className="below-grid-form"
          id="addlookupform"
        >
          <h3>{this.state.heading}</h3>
          <br />
          {this.renderFormItems()}
          {saveButton}
          <button
            type="button"
            onClick={this.cancel}
            className="btn btn-default"
          >
            Cancel
          </button>
          <br />
          <br />
        </Formsy>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permissions: state.authReducer.permissions
});

export default connect(mapStateToProps, {
  executeAuthAsyncGet,
  executeAuthAsyncPost,
  executeAsyncResetGet
})(AddLookup);
