import { ReactNode, useRef } from "react"
import ReactFlow, { MarkerType, useEdgesState, useNodesState } from "reactflow"
import FloatingEdge from "./FloatingEdge"
import ConnectionLine from "./ConnectionLine"
import ReactFlowNode from "./LegacyReactFlowNode"
import "reactflow/dist/style.css"

const nodeTypes = {
    invevoNode: (props: { data: ReactNode }) => <ReactFlowNode>{props.data}</ReactFlowNode>
}

const edgeTypes = {
    floating: FloatingEdge
}

const invevoDarkGrey = "#4a4a4a"

const defaultEdgeOptions = {
    style: { strokeWidth: 2, stroke: invevoDarkGrey },
    type: "floating",
    markerEnd: {
        type: MarkerType.ArrowClosed,
        color: invevoDarkGrey
    }
}

type FlowGraphProps = {
    nodes: Node[]
    edges: Edge[]
}

type Node = {
	id: string
	position: { x: number; y: number }
	children: ReactNode
}

type Edge = {
	sourceNodeId: string
	targetNodeId: string
}

const FlowGraph = ({ nodes, edges }: FlowGraphProps) => {
    const flowNodes = nodes.map(node => ({
        id: node.id,
        position: node.position,
        data: node.children,
        type: "invevoNode"
    }))

    const flowEdges = edges.map(edge => ({
        id: `${edge.sourceNodeId}-${edge.targetNodeId}`,
        source: edge.sourceNodeId,
        target: edge.targetNodeId,
        type: "floating"
    }))

    const [renderNodes, , onRenderNodesChange] = useNodesState(flowNodes)
    const [renderEdges, , onRenderEdgesChange] = useEdgesState(flowEdges)

    const reactFlowWrapper = useRef<HTMLDivElement>(null)

    return (
        <div className="w-100 h-100" ref={reactFlowWrapper}>
            <ReactFlow
                nodes={renderNodes}
                edges={renderEdges}
                onNodesChange={onRenderNodesChange}
                onEdgesChange={onRenderEdgesChange}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                defaultEdgeOptions={defaultEdgeOptions}
                connectionLineComponent={ConnectionLine}
                fitView
            />
        </div>
    )
}

export default FlowGraph
