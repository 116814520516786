import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AccountCard from './accountCard';
import AccountDetail from './accountDetail';
import { useInternationalization } from '@progress/kendo-react-intl';
import {
  executeAuthAsyncGet,
  executeAsyncResetGet
} from '../../utility/asyncSupport';
import { useClient } from 'invevo-react-components';

function getHighlight(amount) {
  if (amount > 0) return 'negitive';
  if (amount < 0) return 'positive';
  return 'neutral';
}

const AgedDebtCard = ({
  title,
  accountId,
  isVirtualAccount,
  open,
  setOpen,
  currencyFormat,
  agedDebtLookup,
  executeAuthAsyncGet,
  executeAsyncResetGet
}) => {
  const client = useClient()
  const internationalization = useInternationalization();

  useEffect(() => {
    const controller = new AbortController();
    if (isVirtualAccount) return;

    executeAuthAsyncGet(
      client, 
      'panelcontent/GetAgedDebtPanelData',
      'ACCOUNT_AGED_DEBT',
      {
        customerId: accountId,
        options: '40,41,42,43,44'
      },
      null,
      null,
      controller.signal
    );

    return () => {
      if (isVirtualAccount) return;
      controller.abort();
      executeAsyncResetGet('ACCOUNT_AGED_DEBT');
    };
  }, [accountId, isVirtualAccount, executeAuthAsyncGet, executeAsyncResetGet, client]);

  if (isVirtualAccount) return null;

  let content = (
    <AccountDetail key="aged-debt-loading" style={{ minHeight: '161px' }}>
      Loading...
    </AccountDetail>
  );
  if (agedDebtLookup.isFetched && agedDebtLookup.result && agedDebtLookup.result.length > 0) {
    content = agedDebtLookup.result
      .filter((d) => d.currency !== 'ALL' || d.amount === 0)
      .map((d) => {
        const title = d.group === '40' ? d.text : `${d.text} days`;
        const value = `${
          d.currency === 'ALL' ? '' : d.currency
        } ${internationalization.formatNumber(d.amount, currencyFormat)}`;

        return (
          <AccountDetail
            key={`aged-debt-${d.id}`}
            title={title}
            value={value}
            highlight={getHighlight(d.amount)}
          />
        );
      });
  } else if (agedDebtLookup.isFetched && agedDebtLookup.result && agedDebtLookup.result.length === 0) {
    content = <AccountDetail title="None" key="aged-debt-none" />;
  }

  return (
    <AccountCard title={title} open={open} setOpen={setOpen}>
      {content}
    </AccountCard>
  );
};

const mapStateToProps = (state) => ({
  currencyFormat: state.lookupReducer.globalSettings.results.currencyFormat,
  agedDebtLookup: state.asyncReducer.ACCOUNT_AGED_DEBT
});
export default connect(mapStateToProps, {
  executeAuthAsyncGet,
  executeAsyncResetGet
})(AgedDebtCard);
