import Dropdown from "../../dropdowns/Dropdown"
import { useGrid } from "./../Grid"
import classes from "./Pagination.module.scss"
import DropdownOption from "../../../types/DropdownOptions"

type Props = {
    pageSizeOptions?: number[]
    isCompact?: boolean
}

const Pagination = ({ pageSizeOptions = [10, 20, 50, 100, 250, 1000], isCompact = false }: Props) => {
    const grid = useGrid()

    const pageIndex = grid.paging.pageIndex
    const pageSize = grid.paging.pageSize
    const totalAcrossPages = grid.data.totalAcrossPages
    const totalPageNumber = Math.ceil(totalAcrossPages / pageSize)

    const lastItemOnPageCount = Math.min(pageIndex * pageSize + pageSize, totalAcrossPages)
    const firstItemOnPageCount = lastItemOnPageCount === 0 ? 0 : pageIndex * pageSize + 1

    const trySetPageIndex = (newPageIndex: number) => {
        if (grid.data.isLoading) return
        if (newPageIndex < 0 || newPageIndex > totalPageNumber - 1 || newPageIndex === pageIndex) return
        onPageIndexChanged(newPageIndex)
    }

    const pageSizeDropdownOptions = pageSizeOptions.map(pageSizeOption => ({ value: pageSizeOption, label: pageSizeOption.toString() }))

    const onPageIndexChanged = (newPageIndex: number) => grid.setPaging({ ...grid.paging, pageIndex: newPageIndex })
    const onPageSizeOptionSelected = (option: DropdownOption<number>) => grid.setPaging({ pageIndex: 0, pageSize: option.value })
    const onGoToFirstPageClicked = () => trySetPageIndex(0)
    const onGoToNextPageClicked = () => trySetPageIndex(pageIndex + 1)
    const onGoToPreviousPageClicked = () => trySetPageIndex(pageIndex - 1)

    return (
        <div className={`d-flex flex-grow-1 align-items-center ${isCompact ? "justify-content-between" : "ms-auto"} gap-2`}>
            {!isCompact && (
                <div className="d-flex align-items-center ms-auto">
                    <Dropdown
                        options={pageSizeDropdownOptions}
                        onOptionSelected={onPageSizeOptionSelected}
                        selectedOption={{ label: pageSize.toString(), value: pageSize }}
                        direction="up"
                        ariaLabel="Page size"
                    />
                    <span className="ms-2 me-4">items per page</span>
                </div>
            )}
            <span>
                {!isCompact && `Showing results: `}
                <strong>{firstItemOnPageCount}</strong>
                {` - `}
                <strong>{lastItemOnPageCount}</strong>
                {` of `}
                <strong>{totalAcrossPages}</strong>
            </span>
            <div className="d-flex align-items-center border rounded">
                <i
                    className={`fal fa-chevron-double-left px-2 py-1 border-end ${grid.data.isLoading || pageIndex === 0 ? classes.disabled : "pointer"}`}
                    aria-label="first"
                    onClick={onGoToFirstPageClicked}
                />
                <i
                    className={`fal fa-chevron-left px-2 py-1 border-end ${grid.data.isLoading || pageIndex === 0 ? classes.disabled : "pointer"}`}
                    aria-label="previous"
                    onClick={onGoToPreviousPageClicked}
                />
                <span className="mx-2">
                    <strong>{pageIndex + 1}</strong> of <strong>{totalPageNumber > 0 ? totalPageNumber : 1}</strong>
                </span>
                <i
                    className={`fal fa-chevron-right px-2 py-1 border-start ${
                        grid.data.isLoading || pageIndex === totalPageNumber - 1 ? classes.disabled : "pointer"
                    }`}
                    aria-label="next"
                    onClick={onGoToNextPageClicked}
                />
            </div>
        </div>
    )
}

export default Pagination
