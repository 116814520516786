export type FileImportConfigFieldDefaultValue =
    | FileImportConfigFieldDefaultStringValue
    | FileImportConfigFieldDefaultNumberValue
    | FileImportConfigFieldDefaultBooleanValue

export enum FileImportConfigFieldDefaultValueEnum {
    STRING = "STRING",
    NUMBER = "NUMBER",
    BOOLEAN = "BOOLEAN"
}

export type FileImportConfigFieldDefaultStringValue = {
    type: FileImportConfigFieldDefaultValueEnum.STRING
    stringValue: string
}

export type FileImportConfigFieldDefaultNumberValue = {
    type: FileImportConfigFieldDefaultValueEnum.NUMBER
    numberValue: string
}

export type FileImportConfigFieldDefaultBooleanValue = {
    type: FileImportConfigFieldDefaultValueEnum.BOOLEAN
    booleanValue: boolean
}
