import classes from "./NumberInput.module.scss"

type NumberInputProps = {
    className?: string
    placeholder?: string
    value?: string
    onChange: (value?: string) => void
    isValid?: boolean
    disabled?: boolean
    suffixIconClasses?: string
}

const NumberInput = ({ className, value, placeholder, onChange, isValid = true, disabled = false, suffixIconClasses }: NumberInputProps) => {
    const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!e.target.value || e.target.value.trim() === "") {
            onChange(undefined)
            return
        }

        const validNumberRegex = /^[+-]?\d+[.]?(\d+)?$/;
        const isNumber = validNumberRegex.test(e.target.value)
        if(isNumber) { 
            onChange(e.target.value)
            return
        }
    }

    return (
        <div className={`d-flex flex-column ${className ? className : ''}`} role="form">
            <div className="d-flex  w-100 position-relative input-group">
                <input
                    placeholder={placeholder}
                    disabled={disabled}
                    className={`form-control ${classes.input} ${isValid ? "" : `${classes.invalid}`}`}
                    value={value ?? ""}
                    onChange={onInputChanged}
                />
                { suffixIconClasses &&
                    <span className="d-flex input-group-text" aria-label="number-input-addon">
                        <i className={`my-auto ${suffixIconClasses}`} />
                    </span>
                }
            </div>
        </div>
    )
}

export default NumberInput
