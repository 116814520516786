import classes from './TransactionsGridWidget.module.scss'
import { useState } from 'react'
import { actionTypes } from '../../../reducers/actionTypes'
import { DataFieldDropdown } from 'invevo-react-components'
import { v4 as uuidv4 } from 'uuid'
import { DashboardConfigAction, DashboardConfigTransactionFields } from '../../../reducers/dashboardConfigsReducer'
import { TransactionWidgetColumnState, TransactionWidgetState } from '../../../types/DashboardConfigState'

type TransactionsGridWidgetProps = {
    className?: string
    state: { 
        transactionFields: DashboardConfigTransactionFields[]
        widget: TransactionWidgetState
    }
    dispatch: React.Dispatch<DashboardConfigAction>
}

const TransactionsGridWidget = ({ className, state, dispatch }: TransactionsGridWidgetProps) => {
    const { transactionFields, widget } = state

    const [isAddingNewColumn, setIsAddingNewColumn] = useState(false)

    const onRemoveClick = () => {
        dispatch({ type: actionTypes.WIDGET_REMOVED, widget })
    }

    const onColumnRemoveClick = (column: TransactionWidgetColumnState) => {
        dispatch({ type: actionTypes.TRANSACTIONS_GRID_WIDGET_COLUMN_REMOVED, widget, column })
    }

    const onAddNewColumnClick = () => {
        setIsAddingNewColumn(true)
    }

    const onColumnNameChange = (option: TransactionWidgetColumnState, column: TransactionWidgetColumnState) => {
        if (!option) {
            return
        }

        dispatch({ type: actionTypes.TRANSACTIONS_GRID_WIDGET_COLUMN_CHANGED, widget, newColumn: option, previousColumn: column })
    }

    const onNewColumnNameChange = (option: TransactionWidgetColumnState) => {
        if (!option) {
            return
        }

        setIsAddingNewColumn(false)
        dispatch({ type: actionTypes.TRANSACTIONS_GRID_WIDGET_NEW_COLUMN_ADDED, widget, newColumn: { ...option, id: uuidv4() } })
    }

    return (
        <div className={`${className ? className : ''} d-flex flex-column ${classes.widget} bg-white p-2 text-grey overflow-auto`}>
            <div className="d-flex align-items-center border-bottom pb-2">
                <i className="fal fa-list me-2"></i>
                <span className='text-grey flex-grow-1'>Transactions</span>
                <i className="fal fa-times ms-auto me-1 pointer" onClick={onRemoveClick}></i>
            </div>
            <div className="d-flex flex-column mt-3 px-2">
                <div className="d-flex flex-column">
                    {
                        widget.columns.map((column, index) =>
                            <div key={column.id} className="d-flex flex-column">
                                <div className='d-flex align-items-center'>
                                    <span className={`text-uppercase small mb-1 text-grey fw-bold`}>column {index + 1}</span>
                                    <div className={`d-flex align-items-center ms-auto`}>
                                        <i className={`fas fa-times-circle pointer ${classes['remove-field-icon']}`} onClick={() => onColumnRemoveClick(column)}></i>
                                    </div>
                                </div>
                                <DataFieldDropdown
                                    className="flex-grow-1 mb-2"
                                    options={transactionFields}
                                    initialOption={transactionFields.find(tf => tf.value === column.value) || column}
                                    onChange={(option: TransactionWidgetColumnState) => onColumnNameChange(option, column)}
                                />
                            </div>
                        )
                    }
                    <div className="d-flex align-items-center">
                        <span className={`text-uppercase small mb-1 ${classes.label}`}>Add new column</span>
                        <i className={`ms-auto fas fa-plus-circle pointer text-blue`} onClick={onAddNewColumnClick}></i>
                    </div>
                    {isAddingNewColumn &&
                        <DataFieldDropdown
                            className="flex-grow-1"
                            options={transactionFields}
                            onChange={onNewColumnNameChange}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default TransactionsGridWidget