import { useState } from 'react'
import classes from './AdminRoleCard.module.scss'
import AssignUsersToRole from '../forms/AssignUsersToRole'

const AdminRoleCard = ({ state, dispatch }) => {
    const { role, clientUsers } = state
    const [showAssignClientUsersForm, setShowAssignClientUsersForm] = useState(false)

    const onAssignClientUsersClick = () => {
        setShowAssignClientUsersForm(true)
    }

    const onCloseAssignClientUsersForm = () => {
        setShowAssignClientUsersForm(false)
    }

    return (
        <div className={`d-flex flex-column no-select text-grey w-100 ${classes["role-card"]} ${classes.shadow}`}>
            <div className={`d-flex flex-row align-items-center px-2 mt-3 pb-3 text-purple ${classes.header}`}>
                <i className="fal fa-user-crown fa-2x mx-2" />
                <h5 className="mb-0 me-3 text-uppercase">Administrator</h5>
                <div className="ms-auto d-flex align-items-center text-purple pointer" onClick={onAssignClientUsersClick}>
                    <span className="text-uppercase me-1">assign users</span>
                    <i className="fas fa-user-plus"></i>
                </div>
            </div>

            <div className="d-flex flex-column text-grey my-2 mx-2">
                <div className={`d-flex align-items-center py-1 px-3 ${classes["bg-darker-grey"]}`}>
                    <span className="text-uppercase">Permissions</span>
                </div>
                <div className={`d-flex align-items-center bg-grey p-1 mt-2 ${classes.rounded}`}>
                    <div className="d-flex align-items-center">
                        <i className="fal fa-globe ms-1" />
                        <span className="ms-2 text-nowrap">All permissions</span>
                    </div>
                </div>
            </div>

            {
                showAssignClientUsersForm &&
                <AssignUsersToRole state={{ role, users: clientUsers }} dispatch={dispatch} onClose={onCloseAssignClientUsersForm} />
            }
        </div>
    )
}

export default AdminRoleCard