import FiltersDto from "../../../../library/FilterList/FiltersDto"
import { FileImportConfigColumnTypeDto, FileImportConfigFieldDataTypeDto, FileImportConfigFieldDefaultValueDto } from "./FileImportConfigDto"
import { FileFormat } from "../../components/utils"
import { TextFormatter } from "../FileImportConfigFieldDataType"

export type GetFileEntityExportConfigDto = GetCsvFileEntityExportConfigDto | GetXmlFileEntityExportConfigDto

export type GetXmlFileEntityExportConfigDto = {
    reference: string
    entityType: string
    fileFormat: FileFormat.XML
    filename: string
    lineEnding: string
    quoteFieldsWith?: string
    filters: FiltersDto
    dateFormat?: string
    rootNodeFieldsConfig: XmlEntityExportNodeDto
    headersNodeFieldsConfig: XmlAggregatedEntityExportNodeDto[]
    footersNodeFieldsConfig: XmlAggregatedEntityExportNodeDto[]
    subdirectoryPath?: string
}

export type GetCsvFileEntityExportConfigDto = {
    reference: string
    entityType: string
    fileFormat: FileFormat.CSV | FileFormat.TEXT
    filename: string
    delimiter: string
    lineEnding: string
    hasHeaderRow: boolean
    quoteFieldsWith?: string
    filters: FiltersDto
    dateFormat?: string
    optionalFieldConfigs: CsvEntityExportFieldConfigDto[]
    subdirectoryPath?: string
}

export type PutFileEntityExportConfigDto = PutCsvFileEntityExportConfigDto | PutXmlFileEntityExportConfigDto

export type PutXmlFileEntityExportConfigDto = {
    entityType: string
    fileFormat: FileFormat.XML
    filename: string
    lineEnding: string
    quoteFieldsWith?: string
    filters: FiltersDto
    dateFormat?: string
    rootNodeFieldsConfig: XmlEntityExportNodeDto
    headersNodeFieldsConfig: XmlAggregatedEntityExportNodeDto[]
    footersNodeFieldsConfig: XmlAggregatedEntityExportNodeDto[]
    subdirectoryPath?: string
}

export type PutCsvFileEntityExportConfigDto = {
    entityType: string
    fileFormat: FileFormat.CSV | FileFormat.TEXT
    filename: string
    delimiter: string
    lineEnding: string
    hasHeaderRow: boolean
    quoteFieldsWith?: string
    filters: FiltersDto
    dateFormat?: string
    optionalFieldConfigs: CsvEntityExportFieldConfigDto[]
    subdirectoryPath?: string
}

export type XmlEntityExportNodeDto = {
    type: XmlEntityExportNodeEnumDto
    fieldConfig?: XmlEntityExportFieldConfigDto
    parent?: XmlEntityExportParentDto
}

export type XmlEntityExportFieldConfigDto = {
    name: string
    dataFieldReference: string
    dataType: FileImportConfigFieldDataTypeDto
    defaultValue?: FileImportConfigFieldDefaultValueDto
    attributes: Map<string, string>
    formatter?: typeof TextFormatter.UPPERCASE | typeof TextFormatter.LOWERCASE
}

export type XmlEntityExportParentDto = {
    name: string
    children: XmlEntityExportNodeDto[]
    attributes: Map<string, string>
}

export enum XmlEntityExportNodeEnumDto {
    PARENT = "PARENT",
    FIELD_CONFIG = "FIELD_CONFIG"
}

export type XmlAggregatedEntityExportNodeDto = {
    type: XmlAggregatedEntityExportNodeEnumDto
    parent?: XmlAggregatedEntityExportParentDto
    valueFieldConfig?: XmlAggregatedExportValueFieldConfigDto
    aggregationFieldConfig?: XmlAggregatedExportAggregationFieldConfigDto
    timestampFieldConfig?: XmlAggregatedExportTimestampFieldConfigDto
}

export type XmlAggregatedEntityExportParentDto = {
    name: string
    children: XmlAggregatedEntityExportNodeDto[]
    attributes: Map<string, string>
}

export type XmlAggregatedExportValueFieldConfigDto = {
    name: string
    value: string
    attributes: Map<string, string>
}

export type XmlAggregatedExportAggregationFieldConfigDto = {
    name: string
    attributes: Map<string, string>
    aggregationType: "SUM" | "AVERAGE" | "MIN" | "MAX" | "CARDINALITY"
    dataFieldReference: string
}

export type XmlAggregatedExportTimestampFieldConfigDto = {
    name: string
    attributes: Map<string, string>
    dateFormat: string
}

export enum XmlAggregatedEntityExportNodeEnumDto {
    PARENT = "PARENT",
    VALUE = "VALUE",
    TIMESTAMP = "TIMESTAMP",
    AGGREGATION = "AGGREGATION"
}

export type CsvEntityExportFieldConfigDto = {
    column: FileImportConfigColumnTypeDto
    dataFieldReference: string
    dataType: FileImportConfigFieldDataTypeDto
    ordinal: number
    defaultValue?: FileImportConfigFieldDefaultValueDto
    formatter?: typeof TextFormatter.UPPERCASE | typeof TextFormatter.LOWERCASE
}
