import { Checkbox, GreyButton, Loading, useApi, useClient, useConfig } from "invevo-react-components"
import { useEffect, useState } from "react"
import { actionTypes } from "../../../reducers/actionTypes"
import { mapDashboardConfigToPutDto } from "../../../helpers/dashboardMapping"
import classes from "./AssignRolesToDashboard.module.scss"
import Role from "../../../../../routes/userRoles/roles/types/Role"
import DashboardConfigState from "../../../types/DashboardConfigState"
import { DashboardConfigAction } from "../../../reducers/dashboardConfigsReducer"

type AssignRolesToDashboardProps = {
    state: { 
        originalRoleSelection: string[]
        editingConfig: DashboardConfigState
        roles: Role[]
        isNewEntityStructureEnabled: boolean
    } 
    dispatch: React.Dispatch<DashboardConfigAction>
    onSaved: () => void
    onCancel: () => void
}

const AssignRolesToDashboard = ({ state, dispatch, onSaved, onCancel }: AssignRolesToDashboardProps ) => {
    const { originalRoleSelection, editingConfig, roles, isNewEntityStructureEnabled } = state

    const [isSaving, setIsSaving] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [rolesToDisplay, setRolesToDisplay] = useState([...roles].sort((a, b) => a.name > b.name ? 1 : -1))

    const api = useApi()
    const client = useClient()
    const config = useConfig()

    useEffect(() => {
        setRolesToDisplay(
            [...roles]
                .filter(r => r.name.toLowerCase().includes(searchTerm.toLowerCase()))
                .sort((a, b) => a.name > b.name ? 1 : -1)
        )
    }, [roles, searchTerm])

    const onSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value)
    }

    const onSave = () => {
        if (isSaving) {
            return
        }

        setIsSaving(true)

        const dto = mapDashboardConfigToPutDto(editingConfig, isNewEntityStructureEnabled)
        api.put(`${config.DATA_API_URL}/api/${client}/dashboard-config/${editingConfig.reference}`, dto)
            .then(() => {
                dispatch({ type: actionTypes.DASHBOARD_CONFIG_SAVED, dashboardConfig: editingConfig })
                setIsSaving(false)
            })
            .catch((error: any) => console.error(error))
        onSaved()
    }

    const isRoleSelected = (role: Role) => editingConfig.roleReferences.includes(role.reference)

    const checkRole = (role: Role) => {
        if (isRoleSelected(role)) {
            dispatch({ type: actionTypes.DASHBOARD_ROLE_ASSIGNMENT_REMOVED, roleReference: role.reference })
            return
        }

        dispatch({ type: actionTypes.DASHBOARD_ROLE_ASSIGNMENT_ADDED, roleReference: role.reference })
    }

    return (
        <Loading isLoading={!config.DATA_API_URL} colour="blue">
            <div className={`d-flex flex-column ${classes.container} flex-grow-1`}>
                <div className="p-3 border-bottom w-100">
                    <div className={`d-flex w-100 align-items-center border p-1 ${classes.search}`}>
                        <input className="flex-grow-1 form-control" placeholder="Search for roles" onChange={onSearchTermChange} />
                        <i className="text-grey fal fa-search fs-5 me-1"></i>
                    </div>
                </div>
                <Loading colour="blue" isLoading={isSaving}>
                    <div className={`p-3 ${classes.roles}`}>
                        {rolesToDisplay.map(role =>
                            <div key={role.reference} className=" d-flex flex-column text-grey">
                                <div className="d-flex align-items-center mb-2 pointer">
                                    <Checkbox className={`me-2 ${isRoleSelected(role) ? classes['selected-checkbox'] : classes['unselected-checkbox']}`} isChecked={isRoleSelected(role)} onClick={() => checkRole(role)} />
                                    <span className="fs-5 no-select" onClick={() => checkRole(role)}>{role.name}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </Loading>
                <div className="d-flex bg-grey border-top p-3 mt-auto">
                    <div className="d-flex ms-auto">
                        <GreyButton className="me-3" iconClasses="fal fa-times" label="Cancel" onClick={onCancel} />
                        <GreyButton iconClasses="fal fa-save" label="Save" onClick={onSave} disabled={isSaving || JSON.stringify(originalRoleSelection) === JSON.stringify(editingConfig.roleReferences)} />
                    </div>
                </div>
            </div>
        </Loading>
    )
}

export default AssignRolesToDashboard