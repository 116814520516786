import update from 'immutability-helper';
import { getApiUrl } from '../constants';
import { webApiInterface } from '../api/webApiInterface';
import { each, isArray } from 'lodash';
import buildParameterString from './buildParameterString';
import { displayErrorMessage, normaliseResponse } from './error';

const initialState = {
  globalRunning: false
};

// ABI - A 'register' function appears NOT working because it happens too late, compared to when a component binds this object
// so we have to register all the stateObjects here

// DT There's no need to prepend these identifiers with the HTTP Verb GET or to append LIST.
// Simple identifier names singular or plural should suffice...

// GET Methods
each(
  [
    'ACCOUNT_SUMMARY',
    'SECURITY_ANSWERS',
    'AVAILABLE_LANGUAGES',
    'CUSTOMER_BRANDS_LIST',
    'EMAIL_MASTER_TEMPLATES',
    'CUSTOMER_TEMPLATES', // EMAIL_TEMPLATES_LIST
    'EMAIL_TEMPLATE',
    'POST_TEMPLATES_LIST',
    'POST_TEMPLATE',
    'USER_PROFILE',
    'CONTACT',
    'VACONTACT',
    'CUSTOMER_ADDRESS',
    'CUSTOMER_CONTACT_TYPES',
    'INTERNAL_CONTACT_TYPES',
    'CUSTOMER_ADDRESS_TYPES',
    'CUSTOMER_ADDRESSES',
    'EMAIL_TEMPLATE_USAGE_TYPES',
    'POST_TEMPLATE_USAGE_TYPES',
    'TASK',
    'REPAYMENT_PLAN_FOR_CUSTOMER',
    'REPAYMENT_PLAN_LIMIT_FOR_USER_COMMIT',
    'TASK_DOCUMENTS',
    'CREDIT_LIMIT_INFO',
    'CREDIT_LIMIT_STATUS',
    'GET_PENDING_REQUEST',
    'USERS_FOR_CUSTOMER_CREDIT_APPROVAL',
    'CREDIT_LIMIT_INFOS',
    'CUSTOMER_INFOS_FROM_ACCOUNT_CODE',
    'GET_DIRECT_CREDIT_HOLDER_FOR_CUSTOMER',
    'GET_EMAIL_TRACKING',
    'FOLLOWUP_REMOVE_OWNER',
    'FOLLOWUP_DOCUMENTS',
    'EMAIL_MASTER_TEMPLATE_VERSION',
    'EMAIL_MASTER_TEMPLATE_VERSIONS',
    'EMAIL_TEMPLATE_VERSION',
    'EMAIL_FULL_TEMPLATE',
    'GET_ROLE',
    'CHECK_OK',
    'GET_USER',
    'SEARCH_BRANCHES',
    'SEARCH_COUNTRIES',
    'SEARCH_USERS',
    'SEARCH_CUSTOMERS',
    'SCORECARDENGINE_GET',
    'LOOKUP_DEFINITIONS',
    'EXPORT_LOOKUPITEM',
    'LOOKUP_GETVALUES',
    'SEARCH_TRANSACTIONS',
    'SEARCH_VIRTUAL_ACCOUNTS',
    'GET_SCORECARDCUSTOMER',
    'GET_SCORECARD',
    'GET_SCORECARDNEWID',
    'GET_LOOKUP_TABLE',
    'TRANSACTION_FILTERS',
    'ACCOUNT_AGED_DEBT',
    'RECENT_PAYMENTS',
    'FALLING_DUE',
    'GET_REALTIME_NOTIFICATIONS',
    'GET_DAILY_NOTIFICATIONS'
  ],
  (e) => {
    initialState[e] = {
      isFetching: false,
      isFetched: false,
      error: null,
      result: null
    };
  }
);

// POST Methods
each(
  [
    'task_setownership',
    'task_releaseownership',
    'task_forcereleaseownership',
    'SAVE_EMAIL_MASTER_TEMPLATE',
    'SAVE_EMAIL_TEMPLATE',
    'SAVE_POST_TEMPLATE',
    'DELETE_POST_TEMPLATE',
    'UPDATE_USER_PROFILE',
    'SEND_EMAIL',
    'GENERATE_POST',
    'CREATE_TEMPLATE_EMAIL',
    'DELETE_CONTACT',
    'CREATE_TASK',
    'UPDATE_TASK',
    'INVOICES_FOR_REPAYMENT_PLAN',
    'COMMIT_REPAYMENT_PLAN_REQUEST',
    'CLOSE_REPAYMENT_PLAN',
    'INSERT_NEW_CREDIT_LIMIT_REQUEST',
    'INSERT_FIRST_DECISION',
    'INSERT_SECOND_DECISION',
    'SET_EXTERNAL_CODE_TO_CUSTOMER',
    'REQUEST_EXTERNAL_SCORE_AND_CREDIT_LIMIT',
    'UPDATE_CREDIT_SCORE',
    'UPDATE_CUSTOMER_TRADE_LIMIT',
    'UPDATE_CUSTOMER_TEMP_TRADE_LIMIT',
    'UPDATE_VIRTUAL_ACCOUNT_TRADE_LIMIT',
    'FOLLOWUP',
    'CREATE_FOLLOWUP',
    'UPDATE_FOLLOWUP',
    'OWN_FOLLOWUP',
    'BRUTAL_TEST',
    'UPDATE_VIRTUAL_ACCOUNT_ALLOW_AUTO_CHANGE',
    'UPDATE_CUSTOMER_ALLOW_AUTO_CHANGE',
    'CREATE_ROLE',
    'UPDATE_ROLE',
    'DELETE_ROLE',
    'CREATE_USER',
    'UPDATE_USER',
    'DELETE_USER',
    'ENABLE_USER',
    'CREATE_CUSTOMERACQUISITION',
    'EMAIL_CUSTOMERACQUISITION',
    'ACCOUNT_STATUS',
    'TRANSACTION_STATUS',
    'SCORECARDENGINE_START',
    'SCORECARDENGINE_RUNTASK',
    'DELETE_LOOKUPITEM',
    'UPDATE_LOOKUPITEM',
    'CREATE_LOOKUPITEM',
    'UPDATE_GENERAL_NOTE',
    'UPDATE_SCORECARDCUSTOMER',
    'CREATE_SCORECARDCUSTOMER',
    'DELETE_SCORECARDCUSTOMER',
    'UPDATE_SCORECARD',
    'CREATE_SCORECARD',
    'DELETE_SCORECARD',
    'CREATE_GRIDWITHSTATE',
    'DELETE_GRIDWITHSTATE',
    'UPDATE_GRIDWITHSTATE',
    'POST_MARK_EMAIL_AS_UNREAD',
    'IMPORT_EXTERNAL_DOCS',
    'UPDATE_REALTIME_NOTIFICATIONS',
    'UPDATE_DAILY_NOTIFICATIONS'
  ],
  (e) => {
    initialState[e] = {
      isExecuting: false,
      isExecuted: false,
      error: null,
      result: null
    };
  }
);

const requestAsyncGetAction = (name) => ({
  type: 'ASYNC_GET_ACTION_REQUEST',
  name
});
const receiveAsyncGetAction = (name, result) => ({
  type: 'ASYNC_GET_ACTION_RECEIVE',
  name,
  result
});
const resetAsyncGetAction = (name) => ({
  type: 'ASYNC_GET_RESET_ACTION',
  name
});
const errorAsyncGetAction = (name, error) => ({
  type: 'ASYNC_GET_ACTION_ERROR',
  name,
  error
});
const requestAsyncPostAction = (name) => ({
  type: 'ASYNC_POST_ACTION_REQUEST',
  name
});
const receiveAsyncPostAction = (name, result) => ({
  type: 'ASYNC_POST_ACTION_RECEIVE',
  name,
  result
});
const resetAsyncPostAction = (name) => ({
  type: 'ASYNC_POST_RESET_ACTION',
  name
});
const errorAsyncPostAction = (name, error) => ({
  type: 'ASYNC_POST_ACTION_ERROR',
  name,
  error
});

export const asyncReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ASYNC_GET_ACTION_REQUEST':
      return update(state, {
        [action.name]: {
          isFetching: { $set: true },
          isFetched: { $set: false },
          error: { $set: null },
          result: { $set: null }
        },
        globalRunning: { $set: true }
      });
    case 'ASYNC_GET_ACTION_RECEIVE':
      return update(state, {
        [action.name]: {
          isFetching: { $set: false },
          isFetched: { $set: true },
          error: { $set: null },
          result: { $set: action.result }
        },
        globalRunning: { $set: false }
      });
    case 'ASYNC_GET_ACTION_ERROR':
      return update(state, {
        [action.name]: {
          isFetching: { $set: false },
          isFetched: { $set: true },
          error: { $set: action.error },
          result: { $set: null }
        },
        globalRunning: { $set: false }
      });
    case 'ASYNC_GET_RESET_ACTION':
      return update(state, {
        [action.name]: {
          isFetching: { $set: false },
          isFetched: { $set: false },
          error: { $set: null },
          result: { $set: null }
        },
        globalRunning: { $set: false }
      });

    case 'ASYNC_POST_ACTION_REQUEST':
      return update(state, {
        [action.name]: {
          isExecuting: { $set: true },
          isExecuted: { $set: false },
          error: { $set: null },
          result: { $set: null }
        },
        globalRunning: { $set: true }
      });
    case 'ASYNC_POST_ACTION_RECEIVE':
      return update(state, {
        [action.name]: {
          isExecuting: { $set: false },
          isExecuted: { $set: true },
          error: { $set: null },
          result: { $set: action.result }
        },
        globalRunning: { $set: false }
      });
    case 'ASYNC_POST_ACTION_ERROR':
      return update(state, {
        [action.name]: {
          isExecuting: { $set: false },
          isExecuted: { $set: false },
          error: { $set: action.error },
          result: { $set: null }
        },
        globalRunning: { $set: false }
      });
    case 'ASYNC_POST_RESET_ACTION':
      return update(state, {
        [action.name]: {
          isExecuting: { $set: false },
          isExecuted: { $set: false },
          error: { $set: null },
          result: { $set: null }
        },
        globalRunning: { $set: false }
      });

    default:
      return state;
  }
};

export function executeAuthAsyncGet(
  client,
  serviceName,
  name,
  data,
  onSuccess,
  onError,
  signal
) {
  return function (dispatch) {
    dispatch(requestAsyncGetAction(name));

    let url = getApiUrl(client) + 'api/' + serviceName;
    if (data) {
      url += '?' + buildParameterString(data);
    }
    webApiInterface
      .authFetch(client, url, dispatch, signal)
      .then(function (response) {
        return response.status !== 204 ? response.json() : null; // 204: No content
      })
      .then(function (json) {
        dispatch(receiveAsyncGetAction(name, json));

        if (onSuccess) {
          onSuccess(json);
        }
      })
      .catch(function (errorResponse) {
        normaliseResponse(errorResponse, (normalisedResponse) => {
          dispatch(errorAsyncGetAction(name, normalisedResponse.message));

          if (onError) {
            onError(normalisedResponse);
          } else {
            displayErrorMessage(normalisedResponse);
          }
        });
      });
  };
}

export function executeAuthAsyncPost(
  client,
  serviceName,
  name,
  data,
  onSuccess,
  onError,
  signal
) {
  return function (dispatch) {
    dispatch(requestAsyncPostAction(name));

    webApiInterface
      .authPost(
        client,
        getApiUrl(client) + 'api/' + serviceName,
        dispatch,
        data,
        'POST',
        signal
      )
      .then(function (response) {
        return response.status !== 204 ? response.json() : null; // 204: No content
      })
      .then(function (json) {
        dispatch(receiveAsyncPostAction(name, json));

        if (onSuccess) {
          onSuccess(json);
        }
      })
      .catch(function (errorResponse) {
        normaliseResponse(errorResponse, (normalisedResponse) => {
          dispatch(errorAsyncPostAction(name, normalisedResponse.message));

          if (onError) {
            onError(normalisedResponse);
          } else {
            displayErrorMessage(normalisedResponse);
          }
        });
      });
  };
}

// This code seems to duplicates what React/Redux already does with connected components!
// It also potentially introduces exceptions into the React render function which is bad.
export function contentForAsyncCall(
  stateObject,
  contentForInitialState,
  contentForWaiting,
  contentForGoodResult,
  contentForErrorResult
) {
  if (stateObject.isFetched === false && stateObject.isFetching === false) {
    return contentForInitialState;
  }

  if (stateObject.isFetching) {
    return contentForWaiting;
  }

  if (stateObject.isFetched === true && stateObject.error === null) {
    return contentForGoodResult;
  }

  return contentForErrorResult;
}

export function checkNested(obj /*, level1, level2, ... levelN*/) {
  const args = Array.prototype.slice.call(arguments, 1);
  for (let i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;
}

export function executeAsyncResetGet(name) {
  return function (dispatch) {
    const names = isArray(name) ? name : [name];

    each(names, (n) => dispatch(resetAsyncGetAction(n)));
  };
}

export function executeAsyncResetPost(name) {
  return function (dispatch) {
    const names = isArray(name) ? name : [name];

    each(names, (n) => dispatch(resetAsyncPostAction(n)));
  };
}
