const isDueToday = date => {
    const today = new Date()
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
}

const isOverdue = date => {
    const today = new Date()
    return date.getDate() < today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
}

const dayOfTheWeek = date => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    return days[date.getDay()]
}

const monthOfTheYear = date => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    return months[date.getMonth()]
}

const DueDate = ({ dueDate }) => {
    const date = new Date(dueDate.split('T')[0])

    if (isOverdue(date)) {
        const overdueDays = Math.floor((new Date() - date) / (1000 * 60 * 60 * 24))
        return (
            <span className="text-uppercase px-1 bg-danger rounded small">
                <strong>Overdue {overdueDays} day{overdueDays > 1 ? "s" : ""}</strong>
            </span>
        )
    } else if (isDueToday(date)) {
        return (
            <span className="text-uppercase px-1 bg-secondary rounded small">
                <strong>Due Today</strong>
            </span>
        )
    } else {
        return (
            <span className="text-uppercase px-1 bg-secondary rounded small">
                <strong>Due {dayOfTheWeek(date)} {date.getDate()} {monthOfTheYear(date)} {date.getFullYear()}</strong>
            </span>
        )
    }
}

export default DueDate