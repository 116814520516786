import { useEffect, useState } from 'react'
import classes from './WorkflowDetails.module.scss'
import { Loading, Toggle, Input, LogicOperatorOptions, ProgressButton, useApi, useClient, StandardButton, useConfig } from 'invevo-react-components'
import InformationPanel from './InformationPanel'
import OutputIcon from './OutputIcon'
import moment from 'moment'
import { smsContactConfigOptions } from '../workflowSetup/actions/filters/SmsSendActionContactFilters'
import WorkflowDuplicationOverlay from './WorkflowDuplicationOverlay'
import { WorkingDays, Weekend, EveryWorkingDayOption, EverWeekendDayOption } from '../../utils/Utils'

const WorkflowDetails = ({
    state,
    dispatch,
}) => {
    const api = useApi()
    const client = useClient()
    const config = useConfig()
    
    const [isNameValid, setIsNameValid] = useState(true)
    const [saveDisabled, setSaveDisabled] = useState(false)
    const [isSmsContactConfigValid, setIsSmsContactConfigValid] = useState(true)
    const [showValidationMessage, setShowValidationMessage] = useState(false)
    const [showDuplicateOverlay, setShowDuplicateOverlay] = useState(false)
    const isLoading = !state.selectedWorkflowRule.reference

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkIsValid = (name) => setIsNameValid(name && name.length >= 2 && name.length <= 128)

    const onNameChange = (event) => {
        setShowValidationMessage(true)
        checkIsValid(event.target.value)
        dispatch({ type: "SELECTED_WORKFLOW_RULE_UPDATED", workflowRule: { ...state.selectedWorkflowRule, name: event.target.value } })
    }

    useEffect(() => {
        if (state.selectedWorkflowRule.smsSendActions.length === 0) {
            setIsSmsContactConfigValid(true)
            return
        }

        setIsSmsContactConfigValid(state.selectedWorkflowRule.smsSendActions.every(conf => checkIfSmsContactConfigValid(conf.smsContactConfig) && conf.templateRef))

    }, [state.selectedWorkflowRule.smsSendActions])

    const checkIfSmsContactConfigValid = (contactConfig) => {
        if (contactConfig.option === smsContactConfigOptions.ANY_WITH_FILTERS || contactConfig.option === smsContactConfigOptions.ALL_WITH_FILTERS) {
            return (contactConfig.contactFilters.fieldsAreInValues.length > 0 &&
                contactConfig.contactFilters.fieldsAreInValues.every(f => f.isValid)
            )
        }
        else return true
    }

    useEffect(() => {
        if (!state.selectedWorkflowRule.reference) return

        if (state.selectedWorkflowRule.isNew && isNameValid && isSmsContactConfigValid) {
            setSaveDisabled(false)
            return
        }

        const originalRule = state.workflowRules.find(rg => rg.reference === state.selectedWorkflowRule.reference)
        if (JSON.stringify(originalRule) !== JSON.stringify(state.selectedWorkflowRule) && isNameValid && isSmsContactConfigValid) {
            setSaveDisabled(false)
            return
        }

        setSaveDisabled(true)
    }, [isNameValid, state.workflowRules, state.selectedWorkflowRule, isSmsContactConfigValid])

    const onStatusSwitch = () => {
        const newStatus = state.selectedWorkflowRule.status === "on" ? "off" : "on"
        dispatch({ type: "SELECTED_WORKFLOW_RULE_UPDATED", workflowRule: { ...state.selectedWorkflowRule, status: newStatus } })
    }

    const changeStatus = (status) => {
        const promise = new Promise((resolve, reject) => {
            api.put(`${config.AUTOMATE_API_URL}/api/${client}/workflow-rules/${state.selectedWorkflowRule.reference}`,
                newRequestBody({ ...state.selectedWorkflowRule, status: status }))
                .then(_ => {
                    dispatch({ type: 'WORKFLOW_RULE_UPDATED', workflowRule: { ...state.selectedWorkflowRule, status: status } })
                    dispatch({ type: 'SELECTED_WORKFLOW_RULE_UPDATED', workflowRule: { ...state.selectedWorkflowRule, status: status } })
                    resolve()
                })
                .catch(error => {
                    console.error(error)
                    reject()
                })
        })
        return promise
    }

    const onRunWorkflow = (isPreview) => {
        return new Promise((resolve, reject) => {
            api.post(`${config.AUTOMATE_API_URL}/api/${client}/workflow-rule-runs/`, { workflowRuleRef: state.selectedWorkflowRule.reference, isPreview: isPreview })
                .then(response => {
                    dispatch({ type: "NEW_WORKFLOW_PREVIEW_REQUESTED", reference: response.data })
                    resolve()
                })
                .catch(error => {
                    console.error(error)
                    reject()
                })
        })
    }

    const onWorkflowSave = (isDuplication, workflowToDuplicate) => {
        var workflowRule = isDuplication ? workflowToDuplicate : state.selectedWorkflowRule
        const promise = new Promise((resolve, reject) => {
            api.put(`${config.AUTOMATE_API_URL}/api/${client}/workflow-rules/${workflowRule.reference}`, newRequestBody(workflowRule))
                .then(_ => {
                    if (workflowRule.isNew) {
                        api.get(`${config.AUTOMATE_API_URL}/api/${client}/workflow-rules/${workflowRule.reference}`)
                            .then(response => {
                                dispatch({
                                    type: 'WORKFLOW_RULE_UPDATED', workflowRule: {
                                        ...workflowRule,
                                        createdBy: response.data.createdBy,
                                        creationDate: moment(response.data.createdDate).format('DD MMMM YYYY')
                                    }
                                })
                                dispatch({
                                    type: 'SELECTED_WORKFLOW_RULE_UPDATED', workflowRule: {
                                        ...workflowRule,
                                        isNew: false,
                                        createdBy: response.data.createdBy,
                                        creationDate: moment(response.data.createdDate).format('DD MMMM YYYY')
                                    }
                                })
                                const newRuleList = [{
                                    ...workflowRule,
                                    isNew: false,
                                    createdBy: response.data.createdBy,
                                    creationDate: moment(response.data.createdDate).format('DD MMMM YYYY')
                                }].concat(state.workflowRules.filter(rule => rule.name !== "New workflow rule" && !rule.isNew))
                                dispatch({ type: 'NEW_WORKFLOW_RULE_CLOSED', workflowRules: newRuleList })
                                resolve()
                            })
                    } else {
                        setSaveDisabled(true)
                        dispatch({ type: 'WORKFLOW_RULE_UPDATED', workflowRule: { ...workflowRule } })
                        dispatch({ type: 'SELECTED_WORKFLOW_RULE_UPDATED', workflowRule: { ...workflowRule, isNew: false } })
                        resolve()
                    }
                })
                .catch(error => {
                    console.error(error)
                    reject()
                })
        })
        return promise
    }

    const onCloseOverlay = () => setShowDuplicateOverlay(false)

    const mapFilters = (filters) => {
        var inValues = []
        var inRanges = []
        var comparisons = []

        filters.forEach(filter => {
            switch (filter.operator.value) {
                case LogicOperatorOptions.IN:
                    inValues.push(
                        {
                            field: { fieldName: filter.fieldName, type: filter.type },
                            values: filter.value.split(','),
                            useAsNotIn: false
                        }
                    )
                    break
                case LogicOperatorOptions.NOT_IN:
                    inValues.push(
                        {
                            field: { fieldName: filter.fieldName, type: filter.type },
                            values: filter.value.split(','),
                            useAsNotIn: true
                        }
                    )
                    break
                case LogicOperatorOptions.RANGE:
                    inRanges.push(
                        {
                            field: { fieldName: filter.fieldName, type: filter.type },
                            rangeStart: filter.value.split(',')[0],
                            rangeEnd: filter.value.split(',')[1]
                        }
                    )
                    break
                default:
                    comparisons.push(
                        {
                            field: { fieldName: filter.fieldName, type: filter.type },
                            value: filter.value,
                            comparisonType: filter.operator.value
                        }
                    )
                    break
            }
        })

        return { fieldsAreInValues: inValues, fieldsAreInNumberRanges: inRanges, fieldsComparison: comparisons }
    }

    const newRequestBody = (workflowRule) => {
        return {
            name: workflowRule.name,
            status: setWorkflowStatusForApi(workflowRule.status),
            createdBy: workflowRule.createdBy,
            trigger: workflowRule.trigger ? {
                type: workflowRule.trigger.type,
                values: workflowRule.trigger.values.includes(EveryWorkingDayOption)
                    ? WorkingDays
                    : workflowRule.trigger.values.includes(EverWeekendDayOption) ? Weekend : workflowRule.trigger.values,
                hourToTrigger: parseInt(workflowRule.trigger.hourToTrigger)
            }
                : null,
            customerFilters: mapFilters(workflowRule.customerFilters),
            smsSendActions: workflowRule.smsSendActions.map(a => {
                const hasContactFilters = a.smsContactConfig.option === smsContactConfigOptions.ANY_WITH_FILTERS || a.smsContactConfig.option === smsContactConfigOptions.ALL_WITH_FILTERS
                const contactFieldsAreInValuesFilters = hasContactFilters ? a.smsContactConfig.contactFilters.fieldsAreInValues.map(f => {
                    return {
                        field: f.field,
                        values: f.value.split(','),
                        useAsNotIn: false
                    }
                }) : []

                return {
                    reference: a.reference,
                    templateRef: a.templateRef,
                    messageCountOption: a.messageCountOption || null,
                    perMinuteOption: a.perMinuteOption || null,
                    smsContactConfig: { ...a.smsContactConfig, contactFilters: { fieldsAreInValues: contactFieldsAreInValuesFilters } },
                    customerFilters: mapFilters(a.customerFilters)
                }
            }),
        }
    }

    return (
        <div className="pt-4 pe-4">
            <div className={`d-flex flex-column ${classes.container}`}>
                <div className="d-flex flex-column p-3" style={{ height: "100%" }}>
                    <h3 className="text-white no-select">Workflow Details</h3>
                    <Loading isLoading={isLoading || !config.AUTOMATE_API_URL} colour="white">
                        <Input
                            className={`${!isNameValid && !showValidationMessage ? 'mb-3' : 'mb-1'}`}
                            placeholder="Please enter name"
                            label="Workflow name"
                            value={state.selectedWorkflowRule.name}
                            onChange={onNameChange} />
                        {!isNameValid && showValidationMessage &&
                            <div className="d-flex mb-3">
                                <div className="d-flex text-white mb-3">
                                    <i className="fal fa-exclamation-circle me-2 pt-1"></i>
                                    <span>Name must be between 2 and 128 characters in length</span>
                                </div>
                            </div>}
                        {!state.selectedWorkflowRule.isNew &&
                            <>
                                <InformationPanel
                                    firstDataLabel="created by"
                                    firstDataValue={state.selectedWorkflowRule.createdBy}
                                    secondDataLabel="creation date"
                                    secondDataValue={state.selectedWorkflowRule.creationDate}
                                />
                                <div className={`${classes.divider}`} />
                            </>
                        }

                        {(state.selectedWorkflowRule.outputs && state.selectedWorkflowRule.outputs.includes("scorecard")) &&
                            <>
                                <InformationPanel
                                    firstDataLabel="total score"
                                    firstDataValue="100"  //{workflowSelected.totalScore}
                                    secondDataLabel="total weighting"
                                    secondDataValue="100%" //{workflowSelected.totalWeighting}
                                />
                                <div className={`${classes.divider}`} />
                            </>
                        }

                        {state.selectedWorkflowRule.status !== "archived" &&
                            <>
                                <span className="text-uppercase text-light-blue mb-2">WORKFLOW RULE STATUS</span>
                                <Toggle offText="off" onText="on" status={state.selectedWorkflowRule.status === "on"} onStatusChanged={onStatusSwitch} />
                                <div className={`${classes.divider}`} />
                            </>
                        }
                        {(state.selectedWorkflowRule.outputs && state.selectedWorkflowRule.outputs.length !== 0) &&
                            <div className="d-flex flex-column mt-auto">
                                <span className="text-uppercase text-light-blue mb-2">OUTPUTS</span>
                                {state.selectedWorkflowRule.outputs.includes("scorecard") &&
                                    <OutputIcon iconName="fal fa-bullseye-arrow" title="Scorecard" />
                                }
                                {state.selectedWorkflowRule.outputs.includes("alpha_rating") &&
                                    <OutputIcon iconName="fal fa-sort-alpha-down-alt" title="Customer Alpha Rating" />
                                }
                                {state.selectedWorkflowRule.outputs.includes("create_task") &&
                                    <OutputIcon iconName="fal fa-list-ul" title="Create Task" />
                                }
                            </div>
                        }

                        <div className="d-flex flex-column mt-auto mb-1">
                            <StandardButton
                                className="w-100 mb-3"
                                iconClasses="fas fa-clone"
                                label="Duplicate workflow rule"
                                colour="blue"
                                disabled={state.selectedWorkflowRule.isNew}
                                onClick={() => setShowDuplicateOverlay(true)}
                            />
                            {showDuplicateOverlay &&
                                <WorkflowDuplicationOverlay
                                    selectedWorkflowRule={state.selectedWorkflowRule}
                                    onDuplicate={onWorkflowSave}
                                    onClose={onCloseOverlay}
                                />
                            }
                            <ProgressButton
                                className="w-100 mb-3"
                                iconClasses="fas fa-play"
                                label="Run now"
                                colour="blue"
                                succeededText="Success"
                                failedText="Failed to run workflow"
                                onClickWithPromise={() => {
                                    const promise = new Promise((resolve, reject) => {
                                        onRunWorkflow(false)
                                            .then(_ => resolve(), _ => reject())
                                            .catch(_ => reject())
                                    })
                                    return promise
                                }}
                                disabled={state.selectedWorkflowRule.isNew}
                            />
                            <ProgressButton
                                className="w-100 mb-3"
                                iconClasses="fal fa-shapes"
                                label="Generate preview"
                                colour="blue"
                                succeededText="Success"
                                failedText="Failed to generate preview"
                                onClickWithPromise={() => {
                                    const promise = new Promise((resolve, reject) => {
                                        onRunWorkflow(true)
                                            .then(_ => resolve(), _ => reject())
                                            .catch(_ => reject())
                                    })
                                    return promise
                                }}
                                disabled={state.selectedWorkflowRule.isNew}
                            />
                            <ProgressButton
                                className="w-100 mb-3"
                                iconClasses="fal fa-archive"
                                label={state.selectedWorkflowRule.status === "archived" ? "Restore workflow rule" : "Archive workflow rule"}
                                colour="blue"
                                onClickWithPromise={() => {
                                    const promise = new Promise((resolve, reject) => {
                                        const result = state.selectedWorkflowRule.status === "archived" ? changeStatus("off") : changeStatus("archived")

                                        result
                                            .then(_ => resolve(), _ => reject())
                                            .catch(_ => reject())
                                    })
                                    return promise
                                }}
                                succeededText={state.selectedWorkflowRule.status === "archived" ? "Archived successfully" : "Restored successfully"}
                                failedText="Failed to archive"
                                disabled={state.selectedWorkflowRule.isNew} />
                            <ProgressButton
                                className="w-100"
                                iconClasses="fal fa-save"
                                label="Save workflow changes"
                                colour="blue"
                                succeededText="Saved successfully"
                                failedText="Failed to save"
                                onClickWithPromise={() => {
                                    const promise = new Promise((resolve, reject) => {
                                        if (isNameValid) {
                                            onWorkflowSave()
                                                .then(_ => resolve(), _ => reject())
                                                .catch(_ => reject())
                                            return
                                        }
                                        reject()
                                    })
                                    return promise
                                }}
                                disabled={saveDisabled} />
                        </div>
                    </Loading>
                </div>
            </div>
        </div>
    )
}

export default WorkflowDetails

const setWorkflowStatusForApi = (workflowStatus) => {
    switch (workflowStatus) {
        case 'on': return 0
        case 'off': return 1
        default: return 2
    }
}