import { uiConfigAction } from "../constants";

///////////////////
// SaveConfig
///////////////////

export const uiConfigSaveConfigRequest = () => ({
  type: uiConfigAction.SAVECONFIG_REQUEST
});

export const uiConfigSaveConfigRequestReceived = (viewTypeId, config) => ({
  type: uiConfigAction.SAVECONFIG_SUCCESS,
  viewTypeId,
  config
});

export const uiConfigSaveConfigRequestFailed = (validationMessage) => ({
  type: uiConfigAction.SAVECONFIG_FAILURE,
  validationMessage
});

///////////////////
// LoadDefaultConfig
///////////////////

export const uiConfigLoadDefaultConfigRequest = () => ({
  type: uiConfigAction.LOADDEFAULTCONFIG_REQUEST
});

export const uiConfigLoadDefaultConfigRequestReceived = (
  viewTypeId,
  config
) => ({
  type: uiConfigAction.LOADDEFAULTCONFIG_SUCCESS,
  viewTypeId,
  config
});

export const uiConfigLoadDefaultConfigRequestFailed = (validationMessage) => ({
  type: uiConfigAction.LOADDEFAULTCONFIG_FAILURE,
  validationMessage
});
