import { Loading, useConfig } from "invevo-react-components"
import { useEffect, useState } from "react"
import useApiQuery from "../../../../hooks/useApiQuery"
import GenericFilter from "../../../../library/FilterList/filterTypes/GenericFilter"
import DataField from "../../../../types/DataField"
import Lookup from "../../../../types/Lookup"
import GenericAction from "../../types/actions/GenericAction"
import LegacySmsTemplate from "../../types/LegacySmsTemplate"
import WorkflowRun from "../../types/WorkflowRunDto"
import WorkflowRunDetails from "../workflowRunDetails/WorkflowRunDetails"
import WorkflowRunsList from "./WorkflowRunsList"
import useClient from "../../../../hooks/useClient"

type WorkflowRunsProps = {
    fields: DataField[]
    lookups: Lookup[]
    smsTemplates: LegacySmsTemplate[]
    selectedRuleReference: string | undefined
}

const WorkflowRuns = ({ fields, lookups, smsTemplates, selectedRuleReference }: WorkflowRunsProps) => {
    const [selectedWorkflowRun, setSelectedWorkflowRun] = useState<WorkflowRun | undefined>(undefined)
    const [selectedWorkflowRunAction, setSelectedWorkflowRunAction] = useState<GenericAction | undefined>(undefined)
    const [defaultAdvancedFilters, setDefaultAdvancedFilters] = useState<GenericFilter[]>([])

    const client = useClient()
    const config = useConfig()

    useEffect(() => {
        setSelectedWorkflowRun(undefined)
        setDefaultAdvancedFilters([])
    }, [selectedRuleReference])

    const endpoint = selectedRuleReference === undefined ? "workflow-run" : `workflow-run?rule=${selectedRuleReference}`

    const workflowRunsRequest = useApiQuery<WorkflowRun[]>({
        url: `${config.AUTOMATE_API_URL}/api/${client}/${endpoint}`,
        method: "GET"
    })

    const onDetailsGoBackClicked = () => setSelectedWorkflowRun(undefined)

    const onNavToWorkflowRunDetails = (workflowRun: WorkflowRun, defaultFilters: GenericFilter[], action?: GenericAction) => {
        setSelectedWorkflowRun(workflowRun)
        setDefaultAdvancedFilters(defaultFilters)
        setSelectedWorkflowRunAction(action)
    }

    return (
        <Loading isLoading={workflowRunsRequest.isFetching} colour="blue">
            {selectedWorkflowRun ? (
                <WorkflowRunDetails
                    fields={fields}
                    lookups={lookups}
                    smsTemplates={smsTemplates}
                    workflowRun={selectedWorkflowRun}
                    action={selectedWorkflowRunAction}
                    defaultAdvancedFilters={defaultAdvancedFilters}
                    onGoBackClicked={onDetailsGoBackClicked}
                />
            ) : (
                <WorkflowRunsList
                    smsTemplates={smsTemplates}
                    workflowRuns={workflowRunsRequest.data ?? []}
                    onNavToWorkflowRunDetails={onNavToWorkflowRunDetails}
                />
            )}
        </Loading>
    )
}

export default WorkflowRuns
