import { useEffect } from 'react'
import classes from './SmallBarChart.module.scss'
import './C3Overrides.scss'
import c3 from "c3"
import { generateHtmlId } from "../helpers"

const SmallBarChart = ({ className = '', colour = "white", title, xLabels, data }) => {
    const chartId = generateHtmlId()
    useEffect(() => {
        c3.generate({
            bindto: `#${chartId}`,
            data: {
                columns: [[title, ...data]],
                type: "bar",
                colors: {
                    [title]: colour === "white" ? '#0096d9' : '#ffffff'
                }
            },
            axis: {
                x: {
                    type: 'category',
                    categories: [...xLabels.map(label => [label.toUpperCase()])]
                }
            },
            legend: {
                show: false
            },
            grid: {
                x: {
                    show: true
                },
                y: {
                    show: true,
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <div className={`${className} d-flex flex-column w-100 p-3 rounded ${classes[`widget-${colour}`]}`}>
            <div className="d-flex justify-content-between">
                <div className={`fs-5 text-uppercase d-block mb-2 ${colour === 'blue' ? "text-white" : "text-grey"}`}>{title}</div>
            </div>
            <div id={chartId} className={`mt-3 ${classes.chart} ${classes[`chart-${colour}`]}`} />
        </div>
    )
}

export default SmallBarChart