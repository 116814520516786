export default function dataConfigurationReducer(state, action) {
    switch (action.type) {
        case 'CUSTOMER_DATA_CONFIGURATION_RETRIEVED':
            const customerFieldConfigs = action.fields
                .reduce((acc, fieldName) =>
                    ([...acc, getConfigForField(fieldName, action.config)]), [])

            return { ...state, originalCustomerDataConfiguration: { customerFieldConfigs }, editableCustomerDataConfiguration: { customerFieldConfigs } }

        case 'CUSTOMER_FIELD_CONFIG_UPDATED': {
            const updatedConfig = {
                customerFieldConfigs: state.editableCustomerDataConfiguration.customerFieldConfigs.map(
                    config => {
                        if (config.fieldName === action.customerFieldConfig.fieldName) {
                            return { ...config, ...action.customerFieldConfig }
                        }

                        return config
                    }
                )
            }

            return { ...state, editableCustomerDataConfiguration: { ...state.editableCustomerDataConfiguration, ...updatedConfig } }
        }

        case 'CREDIT_DATA_CONFIGURATION_RETRIEVED':
            const creditFieldConfigs = action.fields
                .reduce((acc, fieldName) =>
                    ([...acc, getConfigForField(fieldName, action.config)]), [])

            return { ...state, originalCreditDataConfiguration: { creditFieldConfigs }, editableCreditDataConfiguration: { creditFieldConfigs } }

        case 'CREDIT_FIELD_CONFIG_UPDATED': {
            const updatedConfig = {
                creditFieldConfigs: state.editableCreditDataConfiguration.creditFieldConfigs.map(
                    config => {
                        if (config.fieldName === action.creditFieldConfig.fieldName) {
                            return { ...config, ...action.creditFieldConfig }
                        }

                        return config
                    }
                )
            }

            return { ...state, editableCreditDataConfiguration: { ...state.editableCreditDataConfiguration, ...updatedConfig } }
        }

        case 'DATA_CONFIGURATION_UPDATED':
            return {
                ...state,
                originalCustomerDataConfiguration: { ...state.editableCustomerDataConfiguration },
                originalCreditDataConfiguration: { ...state.editableCreditDataConfiguration }
            }

        default:
            throw new Error(`Invalid reducer action: ${action.type}`)
    }
}

function getConfigForField(fieldName, config) {
    if (config) {
        const fieldConfig = config.fields.find(config => config.fieldName === fieldName)
        if (fieldConfig) return fieldConfig
        else return getDefaultConfig(fieldName)
    } else return getDefaultConfig(fieldName)
}

function getDefaultConfig(fieldName) {
    return {
        fieldName: fieldName,
        fieldDataType: "TEXT",
        isAvailableInGroups: false,
        isAvailableInAutoComplete: false,
        isTemplatePlaceholder: false,
        isAvailableAsUserDataFilter: false,
        isAvailableInSearch: false,
        isAvailableInAdvancedSearch: false
    }
}