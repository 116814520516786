import classes from './CardLabel.module.scss'

const CardLabel = ({ className, value, text, colour }) => {
    return (
        <div className={`d-flex flex-column ${classes["label"]} ${classes[colour]} ${className}`}>
            <h3 className="d-flex justify-content-center mb-1 text-dark-grey">{value}</h3>
            <div className={`text-uppercase d-flex justify-content-center ${classes["text"]}`}>{text}</div>
        </div>
    )
}

export default CardLabel