import { useEffect, useState } from "react"
import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import Tab from "../../../../library/Tabs/Tab"
import Tabs from "../../../../library/Tabs/Tabs"
import { AccountDto } from "../../types/dtos/AccountDto"
import { CreditCircleTransactionsDto, DirectIdTransactionDto } from "../../types/dtos/CreditCircleTransactionsDto"
import BankAccount from "./BankAccount"
import Insights from "./Insights"
import Transactions from "./Transactions"
import { padElementsWith } from "../../../../library/helpers"
import useApiQuery from "../../../../hooks/useApiQuery"
import { useConfig } from "invevo-react-components"
import Loading from "../../../../library/Loading/Loading"
import { DirectIdAffordabilityDto } from "../../types/dtos/DirectIdAffordabilityDto"

export type OpenBankingReportProps = {
    accounts: AccountDto[]
    invevoCustomerReference: String
}

const OpenBankingReport = ({ accounts, invevoCustomerReference }: OpenBankingReportProps) => {
    const [selectedAccount, setSelectedAccount] = useState<AccountDto | undefined>(accounts.length > 0 ? accounts[0] : undefined)
    const [transactions, setTransactions] = useState<DirectIdTransactionDto[]>([])
    const [affordability, setAffordability] = useState<DirectIdAffordabilityDto[]>([])
    const [hasFetchedTransactions, setHasFetchedTransactions] = useState(false)
    const [hasFetchedAffordability, setHasFetchedAffordability] = useState(false)

    const config = useConfig()

    const transactionsQuery = useApiQuery<CreditCircleTransactionsDto>({
        url: `${config.CREDIT_CIRCLE_API_URL}/api/creditcircle/credit-circle-transactions`,
        method: "POST",
        isExecutedAutomatically: false
    })

    const affordabilityQuery = useApiQuery<DirectIdAffordabilityDto[]>({
        url: `${config.CREDIT_CIRCLE_API_URL}/api/creditcircle/credit-circle-affordability`,
        method: "POST",
        isExecutedAutomatically: false
    })

    useEffect(() => {
        if (!selectedAccount || transactionsQuery.isFetching || hasFetchedTransactions) {
            return
        }

        const getTransactionsDto = {
            consentId: selectedAccount.metadata.consentId,
            accountId: selectedAccount.metadata.accountId
        }

        transactionsQuery.execute(undefined, getTransactionsDto).then(result => {
            setHasFetchedTransactions(true)
            setTransactions(result.data.data)
        })
    }, [selectedAccount, transactionsQuery, hasFetchedTransactions])

    useEffect(() => {
        if (!selectedAccount || affordabilityQuery.isFetching || hasFetchedAffordability) {
            return
        }

        const getAffordabilityDto = {
            consentId: selectedAccount.metadata.consentId,
            accountId: selectedAccount.metadata.accountId
        }

        affordabilityQuery.execute(undefined, getAffordabilityDto).then(result => {
            setHasFetchedAffordability(true)
            setAffordability(result.data)
        })
    }, [selectedAccount, affordabilityQuery, hasFetchedAffordability])

    useEffect(() => {
        setHasFetchedTransactions(false)
    }, [selectedAccount])

    useEffect(() => {
        setHasFetchedAffordability(false)
    }, [selectedAccount])

    const onSetUpConsentClick = () => {
        window.open(`https://connect.direct.id/?client_id=60b625fe-3150-4971-8991-a14356864afb&customer_ref=${invevoCustomerReference}`)
    }

    const onAccountSelectionToggled = (account: AccountDto) => {
        if (account.metadata.accountId === selectedAccount?.metadata.accountId) {
            return
        }

        setSelectedAccount(account)
    }

    const isAccountSelected = (account: AccountDto) => account.metadata.accountId === selectedAccount?.metadata.accountId

    return (
        <>
            {accounts.length > 0 ? (
                <div className="d-flex rounded shadow bg-white p-3 w-100 h-100 overflow-auto">
                    <div className="d-flex flex-column w-100 h-100">
                        {padElementsWith(
                            accounts.map((account, index) => (
                                <BankAccount
                                    key={`${account.metadata.accountNumber}_${index}`}
                                    account={account}
                                    isSelected={isAccountSelected(account)}
                                    onSelectionToggled={onAccountSelectionToggled}
                                />
                            )),
                            "my-1"
                        )}
                        <div className="d-flex ms-auto mt-3">
                            <StandardButton iconClasses="far fa-bank" label="Add bank account" onClick={onSetUpConsentClick} />
                        </div>
                        <Tabs className="mt-4 h-100" contentClassName="h-100">
                            <Tab title="Insights" isActive={true}>
                                <Loading isLoading={transactionsQuery.isFetching}>
                                    {selectedAccount ? (
                                        <Insights account={selectedAccount} affordability={affordability} />
                                    ) : (
                                        <span>Please add a bank account</span>
                                    )}
                                </Loading>
                            </Tab>
                            <Tab title="Transactions">
                                <Loading isLoading={transactionsQuery.isFetching}>
                                    <Transactions transactions={transactions} />
                                </Loading>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            ) : (
                <div className="d-flex rounded shadow bg-white p-3">
                    <StandardButton iconClasses="far fa-bank" label="Set up open banking data" onClick={onSetUpConsentClick} />
                </div>
            )}
        </>
    )
}

export default OpenBankingReport
