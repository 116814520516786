import { useEffect, useState } from "react"
import { IconButton } from "invevo-react-components"
import AutoCompleteDropdown from "../../../../library/dropdowns/AutoCompleteDropdown"
import DropdownOption from "../../../../types/DropdownOptions"
import Role from "../../roles/types/Role"
import { EntityFilter, UserRole } from "../types/UserRole"
import classes from "./ClientUserRoleConfig.module.scss"
import DataField from "../../../../types/DataField"
import Lookup from "../../../../types/Lookup"
import { v4 as uuidv4 } from "uuid"
import GenericFilter from "../../../../library/FilterList/filterTypes/GenericFilter"
import { convertFromArrayToDto, convertFromDtoToArray } from "../../../../library/FilterList/FiltersDto"
import Filter from "../../../../library/FilterList/Filter"
import BlueButton from "../../../../library/buttons/BlueButton/BlueButton"
import FilterTypesByField from "../../../../library/FilterList/FilterTypesByField"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"
import EntityConfig from "../../../entityConfig/types/EntityConfig"
import Dropdown from "../../../../library/dropdowns/Dropdown"
import EntityFilterConfig from "./EntityFilterConfig"

type ClientUserRoleConfigProps = {
    roles: Role[]
    userRole: UserRole
    userRoles: UserRole[]
    onUserRoleUpdated: (userRole: UserRole) => void
    onRoleRemoved: (userRole: UserRole) => void
    disabled: boolean
    customerDataFields: DataField[]
    lookups: Lookup[]
    entityConfigs: EntityConfig[]
}

const ClientUserRoleConfig = ({
    roles,
    userRole,
    userRoles,
    onUserRoleUpdated,
    onRoleRemoved,
    disabled,
    customerDataFields,
    lookups,
    entityConfigs
}: ClientUserRoleConfigProps) => {
    const [roleOptions, setRoleOptions] = useState<DropdownOption<string>[]>([])
    const [filters, setFilters] = useState<GenericFilter[]>(userRole.filters ? convertFromDtoToArray(userRole.filters, customerDataFields, lookups) : [])

    const { isEnabled: isNewEntityStructureEnabled } = useFeatureToggle("newEntityStructure")
    const { isEnabled: isSqlFirstEnabled } = useFeatureToggle("sqlFirst")

    useEffect(() => {
        const availableRoles = roles
            .filter(role => userRoles.every(userRole => userRole.role?.reference !== role.reference) || role.reference === userRole.role?.reference)
            .map(role => ({ value: role.reference, label: role.name }))

        setRoleOptions(availableRoles)
    }, [roles, userRole, userRoles])

    const selectedOption = roleOptions.find(o => o.value === userRole.role?.reference)

    const onRoleSelected = (selectedOption: DropdownOption<string> | undefined) => {
        onUserRoleUpdated({
            ...userRole,
            role: roles.find(r => r.reference === selectedOption?.value) as Role
        })
    }

    const removeRole = () => onRoleRemoved(userRole)

    const onNewFilterClicked = () => setFilters(filters => [...filters, { type: FilterTypesByField.NO_TYPE, id: uuidv4(), fieldName: "" }])

    const onFilterUpdated = (filter: GenericFilter) => updateUserRoleFilters(filters.map(f => (f.id === filter.id ? filter : f)))

    const onFilterDeleted = (id: string) => updateUserRoleFilters(filters.filter(filter => filter.id !== id))

    const updateUserRoleFilters = (updatedFilters: GenericFilter[]) => {
        setFilters(updatedFilters)
        onUserRoleUpdated({
            ...userRole,
            filters: convertFromArrayToDto(updatedFilters)
        })
    }

    const updateEntityFilter = (updatedEntityFilter: EntityFilter) => {
        onUserRoleUpdated({
            ...userRole,
            entityFilters: userRole.entityFilters.map(entityFilter =>
                entityFilter.entityTypeReference === updatedEntityFilter.entityTypeReference ? updatedEntityFilter : entityFilter
            )
        })
    }

    const entityConfigOptions: DropdownOption<string>[] = entityConfigs
        .filter(entityConfig => userRole.role?.entityPermissions.some(entityPermission => entityPermission.entityTypeReference === entityConfig.reference))
        .filter(entityConfig => !userRole.entityFilters.some(entityFilter => entityFilter.entityTypeReference === entityConfig.reference))
        .map(entityConfig => {
            return {
                label: entityConfig.displayName,
                value: entityConfig.reference
            }
        })

    const onAddEntityFilters = (option: DropdownOption<string>) => {
        onUserRoleUpdated({
            ...userRole,
            entityFilters: [...userRole.entityFilters, { entityTypeReference: option.value, filters: {} }]
        })
    }

    const onRemoveEntityFilter = (entityTypeReference: string) => {
        onUserRoleUpdated({
            ...userRole,
            entityFilters: userRole.entityFilters.filter(entityFilter => entityFilter.entityTypeReference !== entityTypeReference)
        })
    }

    return (
        <div className={`${classes.container} d-flex flex-column p-2`} role="form" aria-label="role-config">
            <div className="d-flex mb-1">
                <span className="text-uppercase mb-1 ms-1 text-white no-select">Role</span>
                <IconButton
                    size="small"
                    className="ms-auto"
                    buttonClasses={classes.removeRoleButton}
                    iconClasses="far fa-trash-can"
                    onClick={removeRole}
                    ariaLabel="remove-role"
                />
            </div>
            <AutoCompleteDropdown
                options={roleOptions}
                onOptionSelected={onRoleSelected}
                selectedOption={selectedOption}
                className="mb-3"
                disabled={disabled}
                ariaLabel="role"
            />
            {(!isNewEntityStructureEnabled || isSqlFirstEnabled) && userRole.role?.customerPermissions && (
                <div className="d-flex flex-column mb-1">
                    <div className="d-flex mb-1">
                        <span className="text-uppercase mb-1 ms-1 text-white no-select">Customer filters</span>
                        <BlueButton
                            className="text-white ms-auto"
                            iconClasses="fa fa-plus"
                            label="Add filter"
                            onClick={onNewFilterClicked}
                            ariaLabel="add-filter"
                        />
                    </div>
                    {filters.length === 0 && (
                        <div className="d-flex my-2">
                            <span className="mx-auto text-white">No customer filters added</span>
                        </div>
                    )}
                    {filters.map(filter => (
                        <div className="bg-white rounded mb-1" key={filter.id}>
                            <Filter
                                key={filter.id}
                                filter={filter}
                                fields={customerDataFields}
                                lookups={lookups}
                                disabled={disabled}
                                onFilterUpdated={onFilterUpdated}
                                onFilterDeleted={() => onFilterDeleted(filter.id)}
                                showFieldType={false}
                            />
                        </div>
                    ))}
                </div>
            )}
            {isNewEntityStructureEnabled && userRole.role && userRole.role.entityPermissions.length > 0 && (
                <div className="d-flex flex-column mt-2">
                    <div className="d-flex mb-2">
                        <span className="text-uppercase ms-1 text-white no-select">Entity filters</span>
                        <Dropdown
                            className="ms-auto"
                            ariaLabel="entity-type-dropdown"
                            fixedSize={true}
                            options={entityConfigOptions}
                            onOptionSelected={onAddEntityFilters}
                            selectedOption={undefined}
                            textAlign="left"
                            placeholder={
                                <span className="d-flex align-items-center me-2">
                                    <i className="fal fa-plus fs-5 me-2" />
                                    Add Entity Filters
                                </span>
                            }
                        />
                    </div>
                    {userRole.entityFilters.map(entityFilter => (
                        <EntityFilterConfig
                            entityFilter={entityFilter}
                            entityConfigs={entityConfigs}
                            lookups={lookups}
                            onEntityFilterUpdated={updateEntityFilter}
                            onEntityFilterRemoved={onRemoveEntityFilter}
                            key={entityFilter.entityTypeReference}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default ClientUserRoleConfig
