import { useEffect, useRef, useState } from "react"
import classes from "./ProgressButton.module.scss"
import TextTransition, { presets } from "react-text-transition"
import { Loading } from "invevo-react-components"

type Props = {
    className?: string
    iconClasses: string
    label: string
    colour?: "white" | "blue" | "grey"
    disabled?: boolean
    onClickWithPromise: () => Promise<unknown>
    failedText?: string
    succeededText?: string
    disappearable?: boolean
    ariaLabel?: string
}

const ProgressButton = ({
    className = "",
    iconClasses,
    label,
    colour = "white",
    disabled = false,
    onClickWithPromise,
    failedText = "Something went wrong",
    succeededText = "Success",
    disappearable = false,
    ariaLabel
}: Props) => {
    const [inProgress, setInProgress] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isFail, setIsFail] = useState(false)
    const [disappear, setDisappear] = useState(false)
    const [timers, setTimers] = useState<NodeJS.Timeout[]>([])
    const mounted = useRef(false)

    const labelText = isFail ? failedText : isSuccess ? succeededText : label

    useEffect(() => {
        mounted.current = true
        return () => {
            timers.map(timer => clearTimeout(timer))
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const clickSucceeded = () => {
        if (!mounted.current) return
        setIsSuccess(true)
        setInProgress(false)
        const timer = setTimeout(() => {
            if (!mounted.current) return
            setIsSuccess(false)
            if (disappearable) setDisappear(true)
        }, 2000)

        setTimers(current => [...current, timer])
    }

    const clickedFailed = () => {
        if (!mounted.current) return
        setIsFail(true)
        setInProgress(false)
        const timer = setTimeout(() => {
            if (!mounted.current) return
            setIsFail(false)
        }, 2000)

        setTimers(current => [...current, timer])
    }

    const onClick = () => {
        if (disabled || isSuccess || isFail || inProgress) {
            return
        }
        setInProgress(true)

        onClickWithPromise()
            .then(
                _ => clickSucceeded(),
                _ => clickedFailed()
            )
            .catch(_ => clickedFailed())
    }

    return (
        <div role="button" aria-label={ariaLabel ?? label} className={`${className} d-flex`} onClick={onClick}>
            <div
                className={`d-flex w-100 ${classes.button} ${classes[`button-${colour}`]} 
                 ${inProgress || isSuccess || isFail ? `${classes.submitting}` : ""} 
                 ${disabled ? `${classes.disabled} not-allowed` : "pointer"} 
                 ${disappear ? `${classes.disappear}` : ""} 
                 no-select align-items-center`}
            >
                <div className={`d-flex ${classes.icon} h-100`}>
                    <i className={`m-auto ${iconClasses} py-2 px-3 fs-5`}></i>
                </div>
                <div className={`d-flex ${classes.label} w-100 h-100 align-items-center py-1`}>
                    <Loading isLoading={inProgress} colour={colour === "blue" ? "white" : "blue"} size="small">
                        <TextTransition className={`fs-5 mx-3 fw-normal mb-0 w-100`} springConfig={presets.wobbly} text={labelText} noOverflow={true} />
                    </Loading>
                </div>
            </div>
        </div>
    )
}

export default ProgressButton
