import { DndGrid, Loading } from "invevo-react-components"
import React, { useEffect, useState } from "react"
import { widgetTypes } from "../../../../microfrontends/dashboard/enums/widgetTypes"
import InfoDisplayWidget from "../../../../microfrontends/dashboard/components/customerDashboards/widgets/InfoDisplayWidget"
import classes from "./CustomerSummary.module.scss"
import PieChartDisplayWidget from "../../../../microfrontends/dashboard/components/customerDashboards/widgets/PieChartDisplayWidget"
import BarChartDisplayWidget from "../../../../microfrontends/dashboard/components/customerDashboards/widgets/BarChartDisplayWidget"
import ValueDisplayWidget from "../../../../microfrontends/dashboard/components/customerDashboards/widgets/ValueDisplayWidget"
import AreaChartDisplayWidget from "../../../../microfrontends/dashboard/components/customerDashboards/widgets/AreaChartDisplayWidget"
import LineChartDisplayWidget from "../../../../microfrontends/dashboard/components/customerDashboards/widgets/LineChartDisplayWidget"
import { useCustomer } from "../../../../contexts/CustomerContext"

const CustomerSummary = ({ dashboard, customerFields, transactionAggregations, lookups, fullScreenButton, isFetchingCustomer }) => {
    const [customer] = useCustomer()

    const [sortedWidgets, setSortedWidgets] = useState(
        sortWidgets([
            ...dashboard.infoWidgets,
            ...dashboard.valueWidgets,
            ...dashboard.transactionsWidgets,
            ...dashboard.smallBarChartWidgets,
            ...dashboard.areaChartWidgets,
            ...dashboard.lineChartWidgets,
            ...dashboard.smallPieChartWidgets
        ])
    )

    useEffect(() => {
        setSortedWidgets(
            sortWidgets([
                ...dashboard.infoWidgets,
                ...dashboard.valueWidgets,
                ...dashboard.transactionsWidgets,
                ...dashboard.smallBarChartWidgets,
                ...dashboard.areaChartWidgets,
                ...dashboard.lineChartWidgets,
                ...dashboard.smallPieChartWidgets
            ])
        )
    }, [dashboard])

    return (
        <div className={`d-flex flex-column h-100 w-100 overflow-auto  ${classes.dashboard}`}>
            <div className="d-flex ms-auto mt-3 me-4 fs-3 text-white fw-lighter">
                <span className="pe-3">{dashboard.name}</span>
                {fullScreenButton}
            </div>
            <div className="h-100 px-4 pb-4 overflow-auto">
                <Loading isLoading={isFetchingCustomer} colour="blue">
                    {transactionAggregations === undefined || !customer.data || customer?.data === null ? (
                        <></>
                    ) : (
                        sortedWidgets.some(widget => widget.widgetType !== widgetTypes.TRANSACTIONS_GRID) && (
                            <DndGrid isReadOnly={true}>
                                {sortedWidgets.map(widget => {
                                    switch (widget.widgetType) {
                                        case widgetTypes.INFORMATION:
                                            return customer.isVirtualAccount ? (
                                                <></>
                                            ) : (
                                                <div
                                                    key={`${widget.ordinal}`}
                                                    id={`${widget.ordinal}`}
                                                    x={widget.cellX}
                                                    y={widget.cellY}
                                                    w={widget.cellWidth}
                                                    h={widget.cellHeight}
                                                    minW={4}
                                                    minH={2}
                                                >
                                                    <div className="w-100 h-100 p-2">
                                                        <InfoDisplayWidget state={{ widget, lookups, customerFields, customerData: customer.data }} />
                                                    </div>
                                                </div>
                                            )
                                        case widgetTypes.VALUE:
                                            return customer.isVirtualAccount ? (
                                                <></>
                                            ) : (
                                                <div
                                                    key={`${widget.ordinal}`}
                                                    id={`${widget.ordinal}`}
                                                    x={widget.gridCellX}
                                                    y={widget.gridCellY}
                                                    w={widget.gridCellWidth}
                                                    h={widget.gridCellHeight}
                                                    minW={2}
                                                    minH={2}
                                                >
                                                    <div className="w-100 h-100 p-2">
                                                        <ValueDisplayWidget state={{ widget, customerData: customer.data }} />
                                                    </div>
                                                </div>
                                            )
                                        case widgetTypes.SMALL_PIE_CHART:
                                            return (
                                                <div
                                                    key={`${widget.ordinal}`}
                                                    id={`${widget.ordinal}`}
                                                    x={widget.gridCellX}
                                                    y={widget.gridCellY}
                                                    w={widget.gridCellWidth}
                                                    h={widget.gridCellHeight}
                                                    minW={4}
                                                    minH={4}
                                                >
                                                    <div className="w-100 h-100 p-2">
                                                        <PieChartDisplayWidget state={{ widget, aggregations: transactionAggregations }} />
                                                    </div>
                                                </div>
                                            )
                                        case widgetTypes.AREA_CHART:
                                            return (
                                                <div
                                                    key={`${widget.ordinal}`}
                                                    id={`${widget.ordinal}`}
                                                    x={widget.gridCellX}
                                                    y={widget.gridCellY}
                                                    w={widget.gridCellWidth}
                                                    h={widget.gridCellHeight}
                                                    minW={4}
                                                    minH={4}
                                                >
                                                    <div className="w-100 h-100 p-2">
                                                        <AreaChartDisplayWidget state={{ widget, aggregations: transactionAggregations }} />
                                                    </div>
                                                </div>
                                            )
                                        case widgetTypes.LINE_CHART:
                                            return (
                                                <div
                                                    key={`${widget.ordinal}`}
                                                    id={`${widget.ordinal}`}
                                                    x={widget.gridCellX}
                                                    y={widget.gridCellY}
                                                    w={widget.gridCellWidth}
                                                    h={widget.gridCellHeight}
                                                    minW={4}
                                                    minH={4}
                                                >
                                                    <div className="w-100 h-100 p-2">
                                                        <LineChartDisplayWidget state={{ widget, aggregations: transactionAggregations }} />
                                                    </div>
                                                </div>
                                            )
                                        case widgetTypes.SMALL_BAR_CHART:
                                            return (
                                                <div
                                                    key={`${widget.ordinal}`}
                                                    id={`${widget.ordinal}`}
                                                    x={widget.gridCellX}
                                                    y={widget.gridCellY}
                                                    w={widget.gridCellWidth}
                                                    h={widget.gridCellHeight}
                                                    minW={4}
                                                    minH={4}
                                                >
                                                    <div className="w-100 h-100 p-2">
                                                        <BarChartDisplayWidget state={{ widget, aggregations: transactionAggregations }} />
                                                    </div>
                                                </div>
                                            )
                                        default:
                                            return <React.Fragment key={widget.ordinal}></React.Fragment>
                                    }
                                })}
                            </DndGrid>
                        )
                    )}
                </Loading>
            </div>
        </div>
    )
}

const sortWidgets = widgets => [...widgets].sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1))

export default CustomerSummary
