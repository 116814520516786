import React, { useState, useEffect, useCallback } from "react"
import { connect } from "react-redux"
import { executeAuthAsyncGet, executeAuthAsyncPost, executeAsyncResetGet } from "../../utility/asyncSupport"
import { Dropdown } from "invevo-react-components"
import { isNil } from "lodash"
import { EMailSupport } from "../../utility/eMailSupport"
import existential from "../../utility/existential"
import { useClient } from "invevo-react-components"

const TemplateSelector = ({ executeAsyncResetGet, executeAuthAsyncGet, selectedAccount, CUSTOMER_TEMPLATES, EMAIL_FULL_TEMPLATE, onSaveTemplateInfos }) => {
    const client = useClient()
    const { miaAccountId, miaAccountIsVirtualAccount } = selectedAccount
    const [templateId, setTemplateId] = useState(null)

    const onSelectTemplate = useCallback(
        value => {
            setTemplateId(value.id ? value.id : value)

            if (!isNil(value)) {
                executeAuthAsyncGet(client, "email/InferFulltemplate", "EMAIL_FULL_TEMPLATE", {
                    emailTemplateId: value.id ? value.id : value,
                    customerId: miaAccountId,
                    isVirtualAccount: miaAccountIsVirtualAccount
                })
            } else {
                executeAsyncResetGet("EMAIL_FULL_TEMPLATE")
            }
        },
        [client, executeAsyncResetGet, executeAuthAsyncGet, miaAccountId, miaAccountIsVirtualAccount]
    )

    useEffect(() => {
        executeAsyncResetGet(["CUSTOMER_TEMPLATES", "EMAIL_FULL_TEMPLATE"])
        executeAuthAsyncGet(client, "email/CustomerTemplates", "CUSTOMER_TEMPLATES", {
            customerId: miaAccountId,
            isVirtualAccount: miaAccountIsVirtualAccount
        })
    }, [client, executeAsyncResetGet, executeAuthAsyncGet, miaAccountId, miaAccountIsVirtualAccount])

    useEffect(() => {
        if (templateId === null && CUSTOMER_TEMPLATES.result && CUSTOMER_TEMPLATES.result.length > 0) onSelectTemplate(CUSTOMER_TEMPLATES.result[0].id)
    }, [CUSTOMER_TEMPLATES, onSelectTemplate, templateId])

    const onNext = () => {
        if (!isNil(existential(EMAIL_FULL_TEMPLATE, "result"))) {
            const { master, template } = EMAIL_FULL_TEMPLATE.result
            let html = master.ContentHtml
            const data = JSON.parse(template.ContentJson)
            const masterVariables = EMailSupport.extractVariablesFromContent(html)
            html = EMailSupport.mergeVariablesIntoHtml(master.ContentHtml, data.fragments)
            const clientVariables = EMailSupport.extractVariablesFromContent(html)
            onSaveTemplateInfos({
                masterTemplateContent: master.ContentHtml,
                clientTemplateContent: html,
                htmlFragments: data.fragments,
                masterVariables,
                clientVariables,
                subject: data.subject,
                clientTemplateId: templateId
            })
        }
    }

    const generateNewHtml = () => {
        if (!isNil(existential(EMAIL_FULL_TEMPLATE, "result"))) {
            const { master, template } = EMAIL_FULL_TEMPLATE.result
            const data = JSON.parse(template.ContentJson)

            if (!master) return ""

            return EMailSupport.mergeVariablesIntoHtml(master.ContentHtml, data.fragments)
        }

        return ""
    }

    const createMarkup = () => ({
        __html: generateNewHtml()
    })

    const template = existential(EMAIL_FULL_TEMPLATE, "result.template")
    const subject = template != null ? JSON.parse(template.ContentJson).subject : ""

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <h2 className="text-white">Email</h2>
                </div>
                <div className="col-6">
                    <Dropdown
                        placeholder={CUSTOMER_TEMPLATES.result ? CUSTOMER_TEMPLATES?.result[0]?.text : "Template"}
                        options={CUSTOMER_TEMPLATES.result ? CUSTOMER_TEMPLATES?.result?.map(r => ({ id: r.id, value: r.text })) : []}
                        onOptionSelected={onSelectTemplate}
                        colour="blue"
                        scroll={true}
                    />
                </div>
                <div className="col-6 d-flex">
                    <div className="ms-auto">
                        <button className="btn btn-primary inv" type="button" onClick={onNext} aria-label="email-next">
                            Next
                        </button>
                    </div>
                </div>

                <div className="col-12">
                    <hr />
                </div>
                <div className="col-12">
                    <h4 className="text-white">Subject: {subject}</h4>
                </div>
                <div className="col-12">
                    <div className="preview" dangerouslySetInnerHTML={createMarkup()}></div>
                </div>
            </div>

            <br />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        CUSTOMER_TEMPLATES: state.asyncReducer.CUSTOMER_TEMPLATES,
        EMAIL_FULL_TEMPLATE: state.asyncReducer.EMAIL_FULL_TEMPLATE,
        selectedAccount: state.currentSelectionReducer.selectedAccount
    }
}

export default connect(mapStateToProps, {
    executeAuthAsyncGet,
    executeAuthAsyncPost,
    executeAsyncResetGet
})(TemplateSelector)
