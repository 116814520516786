import GenericFilter from "../../../../library/FilterList/filterTypes/GenericFilter"
import { padElementsWith } from "../../../../library/helpers"
import GenericAction from "../../types/actions/GenericAction"
import LegacySmsTemplate from "../../types/LegacySmsTemplate"
import WorkflowRun from "../../types/WorkflowRunDto"
import WorkflowRunCard from "./WorkflowRunCard"

type WorkflowRunsListProps = {
    smsTemplates: LegacySmsTemplate[]
    workflowRuns: WorkflowRun[]
    onNavToWorkflowRunDetails: (workflowRun: WorkflowRun, defaultFilters: GenericFilter[], action?: GenericAction) => void
}

const WorkflowRunsList = ({ smsTemplates, workflowRuns, onNavToWorkflowRunDetails }: WorkflowRunsListProps) => {
    if (workflowRuns.length === 0) {
        return (
            <div className="d-flex flex-grow-1 justify-content-center align-items-center no-select m-4 mb-auto">
                <i className="fal fa-exclamation-triangle text-warning me-2 fs-4" />
                <span className="fs-5 me-2 text-white">No historical workflow runs</span>
            </div>
        )
    }

    const runElements = workflowRuns
        .sort((a, b) => (a.dueAt > b.dueAt ? -1 : 1))
        .map((run, index) => (
            <WorkflowRunCard key={index} smsTemplates={smsTemplates} workflowRun={run} onNavToWorkflowRunDetails={onNavToWorkflowRunDetails} />
        ))

    return <div className="d-flex flex-column w-100 m-3">{padElementsWith(runElements, "my-1")}</div>
}

export default WorkflowRunsList
