import { Alert, GreyButton, Loading, useApi, useClient, useConfig } from "invevo-react-components"
import { useEffect, useState } from "react"
import { actionTypes } from "../../../reducers/actionTypes"
import TaskCard from "../TaskCard"
import TaskCardExpanded from "../TaskCardExpanded"

const TaskList = ({ state, dispatch, customerRef, setIsCreatingTask }) => {
    const api = useApi()
    const client = useClient()
    const config = useConfig()

    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [hasErrored, setHasErrored] = useState(false)

    useEffect(() => {
        if (isFetching || hasFetched || hasErrored || !config.TASK_API_URL) return

        if (state.hasFetchedTasks) return

        if (!customerRef || customerRef === "") return

        setIsFetching(true)

        api.get(`${config.TASK_API_URL}/api/${client}/customer-tasks/${customerRef}`)
            .then(response => {
                setHasFetched(true)
                dispatch({ type: actionTypes.TASKS_RETRIEVED, tasks: response.data })
                setIsFetching(false)
                setHasErrored(false)
            }).catch(error => {
                setHasFetched(true)
                setIsFetching(false)
                setHasErrored(true)
                console.error(error)
            })
    }, [api, client, config.TASK_API_URL, customerRef, dispatch, hasErrored, hasFetched, isFetching, state.hasFetchedTasks, state.tasks.length])

    return (
        <Loading isLoading={isFetching || !state.hasFetchedTasks} colour="blue">
            <div className="d-flex mb-2 align-items-center">
                <h2 className="no-select me-2 mb-0">{`Customer Tasks (${state.tasks.length})`}</h2>
                <GreyButton
                    className="ms-auto"
                    label="Add Task"
                    iconClasses="fal fa-plus"
                    onClick={() => setIsCreatingTask(true)}
                />
            </div>
            <div className="d-flex border-bottom my-2" />
            {
                hasErrored ?
                    <Alert className="m-3" type="error" iconClasses="fal fa-exclamation-triangle" text="Failed to get customer tasks. You might not have the correct permissions" /> :
                    state.tasks.length === 0 ?
                        <div className="d-flex justify-content-center">
                            <h4 className="m-3 text-white">No tasks for this customer</h4>
                        </div> :
                        <div className="d-flex flex-column align-items-stretch py-2 overflow-auto">
                            {
                                state.tasks.map(t =>
                                    state.selectedTask === t.id ?
                                        <TaskCardExpanded key={t.id} state={state} taskId={t.id} taskReadableId={t.readableId} taskRef={t.taskReference} dispatch={dispatch} /> :
                                        <TaskCard key={t.id} state={state} task={t} dispatch={dispatch} />
                                )
                            }
                        </div>
            }
        </Loading>
    )
}

export default TaskList