import { useLayoutEffect, useRef } from "react"
import MemoizedCustomerDataField from "./MemoizedCustomerDataField"

const CustomerDataFieldConfigList = ({ fields, newFields, dispatch, lookups }) => {
    const ref = useRef()

    const scrollDownToNewField = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" })
    }

    useLayoutEffect(() => {
        ref.current && scrollDownToNewField(ref)
    }, [newFields.length])

    if (fields.length === 0 && newFields.length === 0) {
        return (
            <div className="d-flex flex-grow-1 justify-content-center" >
                <span>No fields found</span>
            </div>
        )
    }

    return (
        <div className="d-flex flex-grow-1 flex-column" >
            {
                fields.map(dataField =>
                    <div className="pb-3" key={`CUSTOMER_${dataField.fieldType}_${dataField.id}`}>
                        <MemoizedCustomerDataField
                            dataField={dataField}
                            dispatch={dispatch}
                            lookups={lookups}
                        />
                    </div>
                )
            }
            {
                newFields.map((newDataField, index) =>
                    <div className="pb-3" key={`CUSTOMER_${newDataField.fieldType}_${newDataField.id}`} ref={index === newFields.length - 1 ? ref : null} >
                        <MemoizedCustomerDataField
                            dataField={newDataField}
                            dispatch={dispatch}
                            lookups={lookups}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default CustomerDataFieldConfigList