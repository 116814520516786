import { createContext, useCallback, useContext, useState } from "react"

const LayoutContext = createContext()

export const expandablePanels = {
    DEFAULT: "DEFAULT",
    LEFT_PANEL: "LEFT_PANEL",
    CUSTOMER_PANEL: "CUSTOMER_PANEL",
    ACTION_PANEL: "ACTION_PANEL"
}

export const LayoutProvider = ({ children }) => {
    const [currentlyExpandedPanel, setCurrentlyExpandedPanel] = useState(expandablePanels.DEFAULT)
    const [lastExpandedPanel, setLastExpandedPanel] = useState(expandablePanels.DEFAULT)

    const setExpandedPanel = useCallback(panelToExpand => {

        if (panelToExpand === expandablePanels.DEFAULT && currentlyExpandedPanel === expandablePanels.CUSTOMER_PANEL) {
            setCurrentlyExpandedPanel(lastExpandedPanel)
        } else {
            setCurrentlyExpandedPanel(panelToExpand)
        }

        setLastExpandedPanel(currentlyExpandedPanel)

    }, [currentlyExpandedPanel, lastExpandedPanel])

    return <LayoutContext.Provider value={{
        setExpandedPanel,
        isLeftPanelExpanded: currentlyExpandedPanel === expandablePanels.LEFT_PANEL,
        isCustomerPanelExpanded: currentlyExpandedPanel === expandablePanels.CUSTOMER_PANEL,
        isActionPanelExpanded: currentlyExpandedPanel === expandablePanels.ACTION_PANEL,
    }}>
        {children}
    </LayoutContext.Provider>
}

export const useLayout = () => {
    const context = useContext(LayoutContext)
    if (!context) {
        throw new Error(`useLayout must be used within a <LayoutProvider />`)
    }
    return context
}