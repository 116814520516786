import DropdownOption from "../../types/DropdownOptions"

type OptionProps<T> = {
    option: DropdownOption<T>
    onOptionClicked: (option: DropdownOption<T>) => void
    isSelectedOption: boolean
    textAlign?: "left" | "center"
}

const DropOption = <T,>({ option, isSelectedOption, textAlign = "center", onOptionClicked }: OptionProps<T>) => {
    const optionClicked = () => onOptionClicked(option)

    return (
        <span
            className={`d-flex px-2 py-1 ${textAlign === "center" ? "text-center" : ""} border-bottom fs-6 ${isSelectedOption ? "fw-bold" : ""}`}
            onClick={optionClicked}
            role="option"
            aria-selected={isSelectedOption}
            aria-label={option.label}
        >
            {option.element ?? option.label}
            {isSelectedOption && <i className="fa fa-check ms-auto my-auto" />}
        </span>
    )
}

export default DropOption
