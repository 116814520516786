import { actionTypes } from "./actionTypes"

const customerTaskReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.TASKS_RETRIEVED:
            return {
                ...state,
                hasFetchedTasks: true,
                tasks: action.tasks.map((t, index) => ({
                    ...t,
                    id: index
                }))
            }
        case actionTypes.TASKS_INVALIDATED:
            return {
                ...state,
                hasFetchedTasks: false
            }
        case actionTypes.TYPES_RETRIEVED:
            return {
                ...state,
                taskTypes: action.taskTypes.map((t, index) => ({
                    ...t,
                    id: index
                }))
            }
        case actionTypes.COMPLETION_TYPES_RETRIEVED:
            return {
                ...state,
                taskCompletionTypes: action.taskCompletionTypes.map((t, index) => ({
                    ...t,
                    id: index
                }))
            }
        case actionTypes.USERS_RETRIEVED:
            return {
                ...state,
                users: action.users.map(u => ({
                    value: u.userRef,
                    label: u.userName
                }))
            }
        case actionTypes.ROLES_RETRIEVED:
            return {
                ...state,
                roles: action.roles.map(r => ({
                    value: r.reference,
                    label: r.name
                }))
            }
        case actionTypes.CUSTOMER_FIELDS_RETRIEVED:
            return {
                ...state,
                customerFields: action.customerFields
            }
        case actionTypes.TASK_SELECTED:
            return {
                ...state,
                selectedTask: action.selectedTask
            }
        case actionTypes.TASK_SAVED:
            return {
                ...state,
                tasks: [
                    ...state.tasks.slice(0, action.updatedTask.id),
                    action.updatedTask,
                    ...state.tasks.slice(action.updatedTask.id + 1)
                ]
            }
        default:
            throw new Error(`Invalid reducer action: ${action.type}`)
    }
}

export default customerTaskReducer