import { useEffect, useReducer, useState } from 'react'
import CustomerFieldConfigs from "./CustomerFieldConfigs"
import Actions from "./Actions"
import dataConfigurationReducer from "../../reducers/dataConfigurationReducer"
import { Tabs, Tab, ComingSoon, useApi, useClient, getConfig } from 'invevo-react-components'
import CreditFieldConfigs from './CreditFieldConfigs'

const DataConfiguration = () => {
    const api = useApi()
    const client = useClient()
    const [hasCreditData, setHasCreditData] = useState(false)
    const [state, dispatch] = useReducer(
        dataConfigurationReducer,
        {
            originalCustomerDataConfiguration: { customerFieldConfigs: [] },
            editableCustomerDataConfiguration: { customerFieldConfigs: [] },
            originalCreditDataConfiguration: { creditFieldConfigs: [] },
            editableCreditDataConfiguration: { creditFieldConfigs: [] }
        }
    )

    useEffect(() => {
        getConfig().then(config => {
            api.get(`${config.ASSESS_API_URL}/api/${client}/has-credit-data`)
                .then(response => {
                    setHasCreditData(response.data.hasCreditData)
                })
                .catch(error => {
                    console.log(error)
                })
        })
    }, [api, client])

    return (
        <div className="d-flex h-100 w-100 pt-4">
            <Tabs className="w-100 h-100 px-4">
                <Tab title="Customer" isActive={true}>
                    <CustomerFieldConfigs state={state} dispatch={dispatch} />
                </Tab>
                <Tab title="Credit"
                    hide={!hasCreditData}>
                    {hasCreditData &&
                        <CreditFieldConfigs state={state} dispatch={dispatch} />}
                </Tab>
                <Tab title="Transaction">
                    <ComingSoon />
                </Tab>
                <Tab title="Contact">
                    <ComingSoon />
                </Tab>
                <Tab title="User">
                    <ComingSoon />
                </Tab>
            </Tabs>
            <Actions className="ms-auto" state={state} dispatch={dispatch} hasCreditData={hasCreditData} />
        </div>
    )
}

export default DataConfiguration