import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive'

const useIsMobile = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 1300px)' })
    const [state, setState] = useState()

    useEffect(() => {
        setState(isMobile)
    }, [isMobile])

    return state
}

export { useIsMobile }