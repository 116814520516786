import { Tabs, Tab } from "invevo-react-components"
import WorkflowRunEvaluation from "./WorkflowRunEvaluation"

const WorkflowEvaluationDashboard = ({ selectedWorkflowRun }) => {
    return (
        <div className="d-flex flex-column px-4">
            <Tabs className="flex-grow-1">
                <Tab title="Matched customers" isActive={true}>
                    <WorkflowRunEvaluation
                        runReference={selectedWorkflowRun.runReference}
                        customerEvaluations={selectedWorkflowRun.customersMatched}
                        matched={true}
                        matchedCount={selectedWorkflowRun.totalCustomersThatMatch}
                        notMatchedCount={selectedWorkflowRun.totalCustomersThatDoNotMatch}
                        syncedCustomerCount={selectedWorkflowRun.totalSyncedCustomers}
                        filterRules={selectedWorkflowRun.customerFilters}
                    />
                </Tab>
                <Tab title="Not matched customers">
                    <WorkflowRunEvaluation
                        runReference={selectedWorkflowRun.runReference}
                        customerEvaluations={selectedWorkflowRun.customersNotMatched}
                        matched={false}
                        matchedCount={selectedWorkflowRun.totalCustomersThatMatch}
                        notMatchedCount={selectedWorkflowRun.totalCustomersThatDoNotMatch}
                        syncedCustomerCount={selectedWorkflowRun.totalSyncedCustomers}
                        filterRules={selectedWorkflowRun.customerFilters}
                    />
                </Tab>
            </Tabs>
        </div>
    )
}
export default WorkflowEvaluationDashboard