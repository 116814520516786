import classes from "./PillButtons.module.scss"

type PillButtonProps = {
    label: string
    isSelected: boolean
    onClick: () => void
}

const PillButton = ({ label, isSelected, onClick }: PillButtonProps) => (
    <div className={`d-flex flex-grow-1 justify-content-center ${isSelected ? classes.selectedButton : ""}`} role="button" onClick={onClick}>
        <span className="fs-5 p-2">{label}</span>
    </div>
)

export default PillButton
