import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment'

import { connect } from 'react-redux';
import { getLookup } from '../../api/lookupApi';
import StatefulSelect from '../common/statefulSelect';
import { hasTaskTypes } from '../../selectors/task';
import { setUserTaskFilterStateTaskType } from '../../actions/taskActions';
import { find } from 'lodash';
import { stateTaskType, userTaskDateType } from '../../constants';
import { useClient } from 'invevo-react-components';

const StateTaskTypesSelect = ({
  stateTaskTypes,
  id,
  getLookup,
  setUserTaskFilterStateTaskType,
  rangeStartDate,
  rangeEndDate,
  dateType
}) => {
  const client = useClient()

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  useEffect(() => {
    switch (dateType) {
      case userTaskDateType.RANGE:
        setStartDate(moment(rangeStartDate)
          .local(true)
          .startOf('day')
          .toISOString())
        setEndDate(moment(rangeEndDate)
          .local(true)
          .endOf('day')
          .toISOString())
        break;
      case userTaskDateType.TOMORROW:
        setStartDate(moment()
          .add(1, 'days')
          .local(true)
          .startOf('day')
          .toISOString())
        setEndDate(moment()
          .add(1, 'days')
          .local(true)
          .endOf('day')
          .toISOString())
        break;
      case userTaskDateType.TODAY:
        setStartDate(null)
        setEndDate(moment().local(true).endOf('day').toISOString())
        break;
      case userTaskDateType.ALL:
        setStartDate(null)
        setEndDate(null)
        break;
      default:
        break;
    }
  }, [rangeEndDate, rangeStartDate, dateType])

  useEffect(() => {
    getLookup(client, `task/gettasktypes?startdate=${startDate}&enddate=${endDate}`, 'currentlyUsedTaskTypes');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stateTaskTypesWithResults = [{ value: 0, label: "All Tasks" }, ...stateTaskTypes.map(tt => { return { value: tt.Id, label: tt.Name } })]

  const handleOnChange = useCallback(
    (newId) => {
      const found = find(stateTaskTypesWithResults, (o) => o.value === newId) || {
        stateTaskTypeId: null,
        taskTypeId: null
      };

      let stateTaskTypeId = stateTaskType.TASK;
      if (found.label.includes('Unread Email')) stateTaskTypeId = stateTaskType.UNREAD_EMAIL
      if (found.label.includes('Awaiting Credit Limit Decisions')) stateTaskTypeId = stateTaskType.AWAIT_CREDIT_LIMIT
      if (found.label.includes('New Credit Limit Decisions')) stateTaskTypeId = stateTaskType.NEW_CREDIT_LIMIT
      setUserTaskFilterStateTaskType(stateTaskTypeId, found.value,);
    },
    [setUserTaskFilterStateTaskType, stateTaskTypesWithResults]
  );

  const taskTypeCount = stateTaskTypes.length;
  const taskTypeWithResultsCount = stateTaskTypesWithResults.length;
  useEffect(() => {
    if (taskTypeCount > 0 && taskTypeWithResultsCount === 0 && id) {
      // were about to show user nothing in drop down so clear select
      handleOnChange(null);
    }
  }, [taskTypeCount, taskTypeWithResultsCount, id, handleOnChange]);

  return (
    <StatefulSelect
      options={stateTaskTypesWithResults}
      optionId={id}
      onChange={handleOnChange}
      placeholder={'Filter by Task Type'}
    />
  );
};

const mapStateToProps = (state) => ({
  hasTaskTypes: hasTaskTypes(state.lookupReducer.currentlyUsedTaskTypes.results),
  stateTaskTypes: state.lookupReducer.currentlyUsedTaskTypes.results,
  grids: state.gridCacheReducer.grids,
  id: state.taskReducer.userTaskList.taskTypeId,
  rangeStartDate: state.taskReducer.userTaskList.startDate,
  rangeEndDate: state.taskReducer.userTaskList.endDate,
  dateType: state.taskReducer.userTaskList.dateType,
});

export default connect(mapStateToProps, {
  getLookup,
  setUserTaskFilterStateTaskType
})(StateTaskTypesSelect);
