import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLookup } from '../../api/lookupApi';
import StatefulSelect from './statefulSelect';
import { useClient } from 'invevo-react-components';

const DocumentTypeSelect = ({ id, onChange, getLookup, documentTypes }) => {
  const client = useClient()  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getLookup(client, 'lookup/documenttypes', 'documentTypes'), []);

  return (
    <StatefulSelect options={documentTypes} optionId={id} onChange={onChange} />
  );
};

DocumentTypeSelect.propTypes = {
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  documentTypes: state.lookupReducer.documentTypes.results
    .filter((t) => t.IsInUse)
    .map((t) => ({
      value: t.Id,
      label: t.Name
    }))
});

export default connect(mapStateToProps, { getLookup })(DocumentTypeSelect);
