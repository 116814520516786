import ListCard from "../../../library/listCards/ListCard"
import EntityConfig from "../types/EntityConfig"
import classes from "./EntityConfigCard.module.scss"

type EntityConfigCardProps = {
    entityConfig: EntityConfig
    isSelected: boolean
    onEntityConfigSelected: (entityConfigRef: string) => void
}

const EntityConfigCard = ({ entityConfig, isSelected, onEntityConfigSelected }: EntityConfigCardProps) => {
    const onCardClicked = () => onEntityConfigSelected(entityConfig.reference)

    return (
        <ListCard isSelected={isSelected} onSelect={onCardClicked} ariaLabel={entityConfig.displayName}>
            <div>
                <h3 className="text-white m-2">
                    <i className="fal fa-sitemap me-2"></i>
                    {entityConfig.displayName}
                </h3>
                <div className={`${classes.separator} d-flex flex-row my-2 bg-grey`} />
                <div className="d-flex flex-row m-2">
                    <div className="ms-auto">
                        <h5 className="mb-1 text-white text-uppercase">Data fields: {entityConfig.fields.length}</h5>
                    </div>
                </div>
            </div>
        </ListCard>
    )
}

export default EntityConfigCard
