import Grid, { GridState } from "../../../library/grid/Grid"
import Lookup from "../../../types/Lookup"
import { GridWidget } from "../types/DashboardConfig"
import FiltersDto, { convertFromArrayToDto, convertFromDtoToArray } from "../../../library/FilterList/FiltersDto"
import GenericFilter from "../../../library/FilterList/filterTypes/GenericFilter"
import RowData from "../../../library/grid/types/RowData"
import { useEntity } from "../../../contexts/EntityContext"
import EntityConfig from "../../entityConfig/types/EntityConfig"
import { getDataFieldsFromEntityDataFields } from "../../../library/helpers/entityHelpers"
import PreviousNextButtons from "../../../library/grid/actions/PreviousNextButtons"
import { GridType } from "../../../microfrontends/dashboard/types/DashboardConfigDto"
import { getSortFromGridState } from "../../../library/grid/util"

type LeftPanelDisplayWidgetProps = {
    entityConfigs: EntityConfig[]
    widget: GridWidget
    lookups: Lookup[]
    advancedFilters: GenericFilter[]
    dashboardFilterGroups: GenericFilter[][]
    dashboardAppliedRoles: string[]
    selectedGridState: GridState | undefined
    shouldLoadData?: boolean
    dashboardReference?: string
    setExpandedPanel: (panel: "DEFAULT" | "LEFT_PANEL" | "CUSTOMER_PANEL" | "ACTION_PANEL") => void
    onEntitySelected: (data: RowData, widget?: GridWidget, state?: GridState) => void
    selectedTransactionRef?: string
}
const LeftPanelDisplayWidget = ({
    entityConfigs,
    widget,
    lookups,
    advancedFilters,
    dashboardFilterGroups,
    dashboardAppliedRoles,
    selectedGridState,
    shouldLoadData = true,
    dashboardReference,
    onEntitySelected,
    selectedTransactionRef
}: LeftPanelDisplayWidgetProps) => {
    const selectedEntityType = entityConfigs.find(entityConfig => entityConfig.reference === widget.entityConfigReference) ?? entityConfigs[0]!
    const [entity] = useEntity()

    const entityConfigDataFields = getDataFieldsFromEntityDataFields(
        entityConfigs.find(entityConfig => entityConfig.reference === widget.entityConfigReference)?.fields ?? []
    )
    const getSelectedConfigFilters = () => (widget.filters !== undefined ? convertFromDtoToArray(widget.filters, entityConfigDataFields, lookups) : [])

    const headerConfigs =
        widget.columns.map((column: any, index: number) => ({
            ...column,
            ordinal: column.ordinal ?? index
        })) ?? []

    const groupingField = selectedEntityType.fields?.find(field => field.fieldName === widget.groupingField)
    const kanbanGroupingField = groupingField && {
        value: groupingField.fieldName,
        label: groupingField.displayName,
        ordinal: 0,
        defaultOrdinal: 0,
        displayType: groupingField.dataType.type,
        isEditable: false,
        lookupReference: groupingField.dataType.lookupReference,
        currencyFieldReference: groupingField.dataType.currencyFieldReference,
        currencyCode: groupingField.dataType.currencyCode,
        format: groupingField.dataType.format
    }

    const getInitialGridState = () => {
        if (!selectedGridState) return undefined
        if (widget.type !== GridType.KANBAN) return selectedGridState

        return {
            ...selectedGridState,
            paging: {
                pageIndex: 0,
                pageSize: 1000,
                searchAfterIds: {}
            }
        }
    }

    const combinedFilters = combineFilters(advancedFilters, getSelectedConfigFilters())

    const filterGroupsToDto = (filterGroups: GenericFilter[][]): FiltersDto[] => {
        return filterGroups.map(filterGroup => convertFromArrayToDto(filterGroup))
    }

    const dashboardFilters = filterGroupsToDto(dashboardFilterGroups)
    const sort = getSortFromGridState(selectedGridState) ?? widget.defaultSort ?? undefined

    return (
        <Grid
            reference={widget.reference}
            lookups={lookups}
            defaultPageSize={widget.type === GridType.KANBAN ? 1000 : 20}
            defaultSort={sort}
            colour="grey"
            ariaLabel="entities"
            initialState={getInitialGridState()}
            initialSelection={[selectedTransactionRef ?? entity.reference]}
            shouldLoadData={shouldLoadData}
            dashboardReference={dashboardReference}
            isCachingEnabled={false}
        >
            <Grid.Header>
                <PreviousNextButtons onSelected={onEntitySelected} />
            </Grid.Header>
            <Grid.EntityTable
                entityConfig={selectedEntityType}
                filters={combinedFilters}
                dashboardFilterGroups={dashboardFilters}
                dashboardAppliedRoles={dashboardAppliedRoles}
                headerConfigs={headerConfigs}
            >
                <Grid.Cards
                    selectableCards={true}
                    colour="blue"
                    onCardSelected={onEntitySelected}
                    entityConfig={selectedEntityType}
                    multiSelect={false}
                    ariaLabel="entities"
                />
            </Grid.EntityTable>
            {(widget.type !== GridType.KANBAN || kanbanGroupingField === undefined) && (
                <Grid.Footer>
                    <Grid.Selection />
                    <Grid.Pagination isCompact />
                </Grid.Footer>
            )}
        </Grid>
    )
}

const combineFilters = (advancedFilterList: GenericFilter[], widgetFilters: GenericFilter[]): FiltersDto => {
    const firstFilters = convertFromArrayToDto(advancedFilterList)
    const secondFilters = convertFromArrayToDto(widgetFilters)

    return {
        textFieldIsOneOf: [...(firstFilters.textFieldIsOneOf ?? []), ...(secondFilters.textFieldIsOneOf ?? [])],
        textFieldStartsWith: [...(firstFilters.textFieldStartsWith ?? []), ...(secondFilters.textFieldStartsWith ?? [])],
        booleanFieldIsEqualTo: [...(firstFilters.booleanFieldIsEqualTo ?? []), ...(secondFilters.booleanFieldIsEqualTo ?? [])],
        numberFieldIsOneOf: [...(firstFilters.numberFieldIsOneOf ?? []), ...(secondFilters.numberFieldIsOneOf ?? [])],
        numberFieldMatchesOperation: [...(firstFilters.numberFieldMatchesOperation ?? []), ...(secondFilters.numberFieldMatchesOperation ?? [])],
        dateFieldIsOneOf: [...(firstFilters.dateFieldIsOneOf ?? []), ...(secondFilters.dateFieldIsOneOf ?? [])],
        dateFieldMatchesOperation: [...(firstFilters.dateFieldMatchesOperation ?? []), ...(secondFilters.dateFieldMatchesOperation ?? [])],
        dateFieldIsRelativeTo: [...(firstFilters.dateFieldIsRelativeTo ?? []), ...(secondFilters.dateFieldIsRelativeTo ?? [])],
        fieldExists: [...(firstFilters.fieldExists ?? []), ...(secondFilters.fieldExists ?? [])]
    }
}

export default LeftPanelDisplayWidget
