import { getApiUrl } from '../constants';
import { webApiInterface } from './webApiInterface';
import displayError from '../utility/error';
import {
  receiveGetAccountStatusList,
  receiveGetAccountStatus,
  requestSetAccountStatus,
  receiveSetAccountStatus,
  errorSetAccountStatus
} from '../actions/accountStatusActions';

export const getAccountStatusList = (client) => (dispatch) => {
  const url = `${getApiUrl(client)}api/AccountStatus/GetAccountStatus`;
  webApiInterface
    .authFetch(client, url, dispatch)
    .then((response) => response.json())
    .then((json) => dispatch(receiveGetAccountStatusList(json)))
    .catch((err) => displayError(err));
};

export const getAccountStatus = (client, id) => (dispatch) => {
  const url = `${getApiUrl(client)}api/AccountStatus/GetAccountStatusData?customerId=${id}`;
  return webApiInterface
    .authFetch(client, url, dispatch)
    .then((response) => response.json())
    .then((json) => dispatch(receiveGetAccountStatus(json)))
    .catch((err) => displayError(err));
};

export const setAccountStatus = (client, data) => (dispatch) => {
  dispatch(requestSetAccountStatus());
  const url = `${getApiUrl(client)}api/AccountStatus/Update`;
  return webApiInterface
    .authPost(client, url, dispatch, data)
    .then((response) => response.json())
    .then((json) => {
      dispatch(receiveSetAccountStatus(json));
      return json;
    })
    .catch((err) => {
      dispatch(errorSetAccountStatus(err));
      displayError(err);
    });
};
