import React from 'react';
import { connect } from 'react-redux';
import {
  lookupIsInUseValues,
  authZ_Permissions
} from '../../../constants';
import {
  executeAuthAsyncGet,
  executeAuthAsyncPost,
  executeAsyncResetGet
} from '../../../utility/asyncSupport';
import {
  showToastSuccessMessage,
  showToastErrorMessage
} from '../../../api/toasterApi.js';
import { forEach, orderBy } from 'lodash';
import Dropdown, { enumToData } from '../../common/dropdown';
import { hasPermission } from '../../../utility/authZ'
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { CommandCell } from '../../common/grids/commandCell';
import { ConfrimDialog } from '../../common/confirmDialog';
import { process } from '@progress/kendo-data-query';
import { GridColumnMenuFilter } from '@progress/kendo-react-grid';
import dropdownFilterCell from '../../common/grids/dropdownFilterCell';

export class LookupAdminGrid extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = this.defaultFormState([]);

    this.EditCommandCell = CommandCell({
      onCommandClick: this.onEditRowClicked,
      cssTag: 'Edit',
      buttonText: 'Edit'
    });

    this.DeleteCommandCell = CommandCell({
      onCommandClick: this.onDeleteRowClicked,
      showFn: this.showDeleteBtn,
      cssTag: 'Delete',
      buttonText: 'Delete'
    });
  }

  showDeleteBtn = (dataItem) => !dataItem.SystemRequired;

  componentDidMount() {
    this.getLookupDefinition(this.props.lookupId);

    if (this.props.lookupGridRefreshCallback != null) {
      this.props.lookupGridRefreshCallback(this.refresh);
    }
  }

  refresh = () => {
    this.getLookupDefinition(this.props.lookupId);
    this.setState({ refresh: this.state.refresh + 1 });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.lookupId !== prevProps.lookupId ||
      this.props.refreshCallback !== prevProps.refreshCallback
    ) {
      this.getLookupDefinition(this.props.lookupId);
      this.setState({ tableState: this.defaultFormState().tableState });
    }
  }

  defaultFormState() {
    return {
      Lookup: null,
      tableState: {},
      isInUseFilter: lookupIsInUseValues.All,
      row: {
        tableId: 0,
        rowId: '',
        rowvalues: []
      },
      refresh: 0,
      showDeleteDialog: false,
      itemToDelete: null
    };
  }

  updateRowValues = (tableId, rowId, items) => {
    this.setState((previousState) => ({
      ...previousState,
      row: {
        ...previousState.row,
        tableId: tableId,
        rowId: rowId,
        rowvalues: items
      }
    }));
  };

  getLookupDefinition = (lookupId) => {
    this.props.executeAuthAsyncGet(
      this.props.client,
      'lookupadmin/getdefinitions',
      'LOOKUP_DEFINITIONS',
      { lookupId },
      (result) => {
        this.setState({ Lookup: result[0] });
      },
      (error) => {
        showToastErrorMessage(
          'Error while loading lookup definition.' + error.message
        );
      }
    );
  };

  handleDataStateChange = (e) => {
    this.setState({ tableState: e.dataState });
  };

  getFkValues(column, tableValues) {
    const values = [];

    forEach(tableValues, (tableValue) => {
      if (column === 'CreditCardTypeId') {
        values.push({ text: tableValue.Type, value: tableValue.Id });
      } else if (column === 'TableId') {
        values.push({
          text: tableValue.TableName,
          value: tableValue.Id
        });
      } else {
        values.push({ text: tableValue.Name, value: tableValue.Id });
      }
    });

    return orderBy(values, ['text'], ['asc']);
  }

  fkLookupCell = (dataItem, columnName, lookupValues) => {
    const fkValue = lookupValues.find(
      (kvp) => kvp.value === dataItem[columnName]
    );

    return <td>{fkValue && fkValue.text}</td>;
  };

  isColumnActive = (field) => {
    return GridColumnMenuFilter.active(field, this.state.tableState.filter);
  };

  getColumns = () => {
    const columns = [];
    const columndefintions = this.state.Lookup.LookupItems;
    const isScoreCard = this.state.Lookup.Name === 'ScoreCard';
    const isExtensionAttribute =
      this.state.Lookup.Name === 'ExtensionAttribute';

    forEach(columndefintions, (column) => {
      if (
        column.Name !== 'CreatedDateTime' &&
        column.Name !== 'ModifiedDateTime'
      ) {
        const columnToAdd = {
          field: column.Name,
          title: column.Name,
          headerClassName: this.isColumnActive(column.Name) ? 'active' : ''
        };

        if (column.ForeignKeyTableName) {
          const values = this.getFkValues(
            column.Name,
            column.ForeignKeyTableValues
          );
          columnToAdd.hidden = false;
          columnToAdd.cell = (cell) =>
            this.fkLookupCell(cell.dataItem, column.Name, values);
          columnToAdd.filterCell = dropdownFilterCell(values, 'Select');
        } else {
          columnToAdd.type = column.MemberType;

          if (column.MemberType === 'date') {
            columnToAdd.format = '{0:' + this.props.dateFormat + '}';
            columnToAdd.filter = column.MemberType;
          } else {
            if (column.Name === 'Id' && column.MemberType) {
              columnToAdd.hidden = !isScoreCard && !isExtensionAttribute;
            } else {
              if (
                column.Name === 'IsInUse' ||
                column.Name === 'SystemRequired'
              ) {
                columnToAdd.hidden = false;
              }

              if (column.MemberType === 'boolean') {
                columnToAdd.filter = 'boolean';
              }

              if (column.MemberType === 'number') {
                columnToAdd.hidden = false;
                columnToAdd.filter = 'numeric';
                columnToAdd.filterable = {
                  operators: { string: { eq: 'Equal to' } }
                };
              }

              if (
                column.MemberType !== 'number' &&
                column.Name !== 'IsInUse' &&
                column.Name !== 'SystemRequired'
              ) {
                columnToAdd.hidden = false;
              }
            }
          }
        }
        columns.push(columnToAdd);
      }
    });

    return columns;
  };

  onEditRowClicked = (e, dataItem) => {
    e.preventDefault();
    this.props.onEdit(dataItem.Id);
  };

  onDeleteRowClicked = (e, dataItem) => {
    e.preventDefault();
    this.setState({
      showDeleteDialog: true,
      itemToDelete: dataItem
    });
  };

  deleteItem = () => {
    if (this.state.itemToDelete !== null) {
      this.deleteLookupItem(this.state.itemToDelete);
      this.getLookupDefinition(this.props.lookupId);
    }
  };

  closeDialog = () => {
    this.setState({
      showDeleteDialog: false
    });
  };

  deleteLookupItem = (dataItem) => {
    if (dataItem.SystemRequired === true) {
      this.getLookupDefinition(this.props.lookupId);
      showToastErrorMessage(
        'Cannot delete this record as it is required by the system.'
      );
      this.setState({
        showDeleteDialog: false
      });
    } else {
      this.props.executeAuthAsyncPost(
        this.props.client,
        'lookupadmin/data/delete?tableId=' +
        this.props.lookupId +
        '&rowId=' +
        dataItem.Id,
        'DELETE_LOOKUPITEM',
        null,
        this.onSuccess,
        function (/*error*/) {
          showToastErrorMessage('Error while processing lookup item.');
          this.setState({
            showDeleteDialog: false
          });
        }
      );
    }
  };

  onSuccess = (response) => {
    if (response.HasError !== false) {
      if (response.ErrorCode === 999) {
        this.getLookupDefinition(this.props.lookupId);
        showToastErrorMessage(
          'Cannot delete lookup item as it is being used by an existing table.'
        );
      } else {
        showToastErrorMessage('Error while processing lookup item.');
      }
    } else {
      this.getLookupDefinition(this.props.lookupId);
      showToastSuccessMessage('Lookup item processed successfully.');
    }
    this.setState({
      showDeleteDialog: false
    });
  };

  updateIsInUseLabel = (value) => {
    let filterType = lookupIsInUseValues.All;

    switch (value) {
      case 'InUse':
        filterType = lookupIsInUseValues.Is_InUse;
        break;
      case 'NotInUse':
        filterType = lookupIsInUseValues.Is_NotInUse;
        break;
      default:
        filterType = lookupIsInUseValues.All;
    }

    this.setState({
      isInUseFilter: filterType
    });
  };

  setIsInUseFilter = (value) => {
    this.updateIsInUseLabel(value);
    switch (value) {
      case 'InUse':
        this.onIsInUseChanged(1);
        break;
      case 'NotInUse':
        this.onIsInUseChanged(2);
        break;
      default:
        this.onIsInUseChanged(3);
    }
  };

  onIsInUseChanged = (value) => {
    const columnList = this.getColumns();

    const tableState = {
      ...this.state.tableState
    };
    forEach(columnList, (column) => {
      if (column.field === 'IsInUse') {
        switch (value) {
          case 1:
            tableState.filter = {
              filters: [
                {
                  field: 'IsInUse',
                  operator: 'eq',
                  value: true
                }
              ],
              logic: 'and'
            };
            break;

          case 2:
            tableState.filter = {
              filters: [
                {
                  field: 'IsInUse',
                  operator: 'eq',
                  value: false
                }
              ],
              logic: 'and'
            };
            break;
          default:
            break;
        }
        this.setState({
          tableState
        });
      }
    });
  };

  renderIsInUseDropdown() {
    return (
      <Dropdown
        title={'Grid View (In Use) ' + this.state.isInUseFilter}
        data={enumToData(lookupIsInUseValues)}
        onClick={(i) => this.setIsInUseFilter(i)}
      />
    );
  }

  static getKey() {
    return 'lookupAdmin-grid';
  }

  render() {
    if (this.state.Lookup) {
      const lookupData = process(
        this.state.Lookup.TableValues,
        this.state.tableState
      );
      return (
        <div>
          {this.renderIsInUseDropdown()}

          <Grid
            id={LookupAdminGrid.getKey()}
            key={LookupAdminGrid.getKey()}
            sortable
            filterable
            scrollable={'none'}
            reorderable
            data={lookupData.data}
            total={lookupData.total}
            defaultField={{ field: 'Name', dir: 'asc' }}
            filter={this.state.tableState.filter}
            sort={this.state.tableState.sort}
            onDataStateChange={this.handleDataStateChange}
          >
            {this.getColumns().map(
              (column, idx) =>
                !column.hidden && <GridColumn key={idx} {...column} />
            )}

            {hasPermission(
              this.props.permissions,
              authZ_Permissions.LookupAdmin
            ) && (
                <GridColumn
                  cell={this.EditCommandCell}
                  width={'80px'}
                  title=""
                  filterable={false}
                />
              )}

            {hasPermission(
              this.props.permissions,
              authZ_Permissions.LookupAdmin
            ) && (
                <GridColumn
                  cell={this.DeleteCommandCell}
                  width={'80px'}
                  title=""
                  filterable={false}
                />
              )}
          </Grid>

          <ConfrimDialog
            show={this.state.showDeleteDialog}
            message={'Are you sure you want to delete this record?'}
            onConfirm={this.deleteItem}
            onClose={this.closeDialog}
          />
        </div>
      );
    } else {
      return <span />;
    }
  }
}

export default connect(
  (state) => ({
    permissions: state.authReducer.permissions,
    dateFormat: state.lookupReducer.globalSettings.results.dateFormat
  }),
  {
    executeAuthAsyncGet,
    executeAuthAsyncPost,
    executeAsyncResetGet
  }
)(LookupAdminGrid);
