import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import { isNil, isNumber } from 'lodash';
import TextInputField from './TextInputField';

class NumericRange extends Component {
  componentDidMount() {
    this.props.setValue({ from: null, to: null });
  }

  nFrom = (e) => {
    const value = e.target.value;
    const n = value !== '' ? parseFloat(value) : null;
    if (isNaN(n)) return;
    const newState = { from: value, to: this.props.value.to };
    this.props.setValue(newState);
    this.setState(newState);
    this.props.onChange(newState);
  };
  nTo = (e) => {
    const value = e.target.value;
    const n = value !== '' ? parseFloat(value) : null;
    if (isNaN(n)) return;
    const newState = { from: this.props.value.from, to: value };
    this.props.setValue(newState);
    this.setState(newState);
    this.props.onChange(newState);
  };
  validate() {
    const s = this.state;

    if (isNil(s)) return true;

    const fromHasValue = !isNil(s.from);
    const toHasValue = !isNil(s.to);

    if (
      (fromHasValue && !isNumber(parseFloat(s.from))) ||
      (toHasValue && !isNumber(parseFloat(s.to)))
    )
      return false;

    return !fromHasValue || !toHasValue || s.from <= s.to;
  }
  render() {
    const { errorMessage } = this.props;
    return (
      <div>
        <div className="form-group value-range">
          <label htmlFor={this.props.name} className="col-form-label-sm mb-0">
            {this.props.label}
          </label>
          <div className="form-row">
            <div className="col ">
              <TextInputField
                name={this.props.id + '_from'}
                placeholder="From"
                onChange={this.nFrom}
                value={this.props.value ? this.props.value.from : null}
              />
            </div>
            <div className="col">
              <TextInputField
                name={this.props.id + '_to'}
                placeholder="To"
                onChange={this.nTo}
                value={this.props.value ? this.props.value.to : null}
              />
            </div>
          </div>
          <div className="validation-error">{errorMessage}</div>
        </div>
      </div>
    );
  }
}
NumericRange.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

export default withFormsy(NumericRange);
