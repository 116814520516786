import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { saveNote } from '../../api/functionApi';
import { resetMsg } from '../../actions/functionActions';
import { showToastSuccessMessage } from '../../api/toasterApi';
import AccountCard from '../accountInfo/accountCard';
import AccountDetail from '../accountInfo/accountDetail';
import TextareaAutosize from 'react-textarea-autosize';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useClient } from 'invevo-react-components';

const AddNote = ({
  selectedAccount,
  saveNote,
  resetMsg,
  setRefresh,
  msg,
}) => {
  const client = useClient()
  const [value, setValue] = useState("")
  const { invevoCustomerNotePlacementToTop } = useFlags()

  useEffect(() => {
    if (msg === 'saved') {
      showToastSuccessMessage('Updated successfully');
      resetMsg();
      setRefresh((refresh) => refresh + 1)
    }
  }, [msg, resetMsg, setRefresh]);

  const addNote = () => {
    const text = value;
    const keys = [];

    //Hint: NoteTypeId = 2 => Transaction; 1 => Customer; 6 => Virtual Parent
    let noteType = 1;
    keys.push(selectedAccount.miaAccountId);
    if (selectedAccount.miaAccountIsVirtualAccount) {
      noteType = 6;
    }

    const notes = {
      noteTypeId: noteType,
      noteText: text,
      noteTypeKeysId: keys,
      allTrSelected: false,
      followUpDate: null,
      ptpDate: null,
      statusId: null,
      subStatusId: null,
      contactId: null,
      GroupId: null
    };
    saveNote(client, notes);
    setValue('');
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  }

  return invevoCustomerNotePlacementToTop ? (
    <AccountCard title={'Notes'}>
      <AccountDetail>
        <div className="d-flex mt-1">
          <div className="flex-grow-1 flex-shrink-1 mr-2">
            <TextareaAutosize
              value={value}
              onChange={handleChange}
              className={'w-100 p-1'}
            />
          </div>
          <div className="align-self-end mb-2 ms-1">
            <button
              onClick={addNote}
              className="btnDefault btn btn-default"
              type="button"
              disabled={!value}
            >
              Update
            </button>
          </div>
        </div>
      </AccountDetail>
    </AccountCard>
  ) : (
    <div
      className="card text-info"
      id="notes-panel"
      style={{ height: 'auto', marginTop: '30px', marginBottom: '20px' }}
    >
      <div className="row">
        <div className="col-12 boldTitle">Notes</div>
      </div>
      <div className="row">
        <div className="col-12">
          <textarea
            className="w-100"
            rows="10"
            cols="100"
            value={value}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-10">
          <button
            onClick={addNote}
            className="btnDefault btn btn-default"
            type="button"
            disabled={!value}
          >
            Update
          </button>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    msg: state.functionReducer.msg,
    selectedAccount: state.currentSelectionReducer.selectedAccount
  };
};

export default connect(mapStateToProps, {
  saveNote,
  resetMsg
})(AddNote);
