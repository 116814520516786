import React from 'react';
import { debounce } from 'lodash';

const PersonalisedContentForm = ({
  onPersonalisedContentChange,
  debounceOnChange
}) => {
  const onChange = (event) => {
    event.persist();

    if (!debounceOnChange) {
      debounceOnChange = debounce(() => {
        const value = event.target.value;
        onPersonalisedContentChange(value);
      }, 500);
    }

    debounceOnChange();
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="form-group w-100 mb-0">
        <textarea
          rows="5"
          name="personalisedContentForm"
          style={{ width: '100%', resize: 'none' }}
          maxLength="500"
          placeholder="Personalised Content"
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default PersonalisedContentForm;
