import classes from "./FieldHeader.module.scss"
import { useDrag, useDrop } from "react-dnd"
import { useRef, useState } from "react"
import HeaderDefinition from "./../types/HeaderDefinition"
import { useGrid } from "./../Grid"
import useApiQuery from "../../../hooks/useApiQuery"
import { useEnvConfig } from "../../../contexts/EnvironmentConfigContext"
import { useUser } from "../../../contexts/UserContext"
import useClient from "../../../hooks/useClient"

const FieldHeader = ({ value, label, ordinal, defaultOrdinal }: HeaderDefinition) => {
    const grid = useGrid()

    const [draggedOverStyle, setDraggedOverStyle] = useState("")

    const toggleSort = () => grid.toggleSort(value)

    const currentUser = useUser()
    const client = useClient()
    const config = useEnvConfig()
    const saveConfigRequest = useApiQuery({
        url: `${config.DATA_API_URL}/api/${client}/user-grid-config/${currentUser.username}`,
        method: "PUT",
        isExecutedAutomatically: false
    })

    const [{ isDragging }, dragRef] = useDrag({
        type: "field",
        item: () => ({ value, label, ordinal, defaultOrdinal }),
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    })

    const [{ isOver }, dropRef] = useDrop({
        accept: "field",
        drop: (draggedField: HeaderDefinition) => {
            const updatedHeaders = grid.displayedHeaders.map(field => {
                if (field.value === draggedField.value) return { ...field, ordinal }
                if (field.value === value) return { ...field, ordinal: field.ordinal > draggedField.ordinal ? ordinal - 1 : ordinal + 1 }
                return {
                    ...field,
                    ordinal: field.ordinal > ordinal ? field.ordinal + 1 : field.ordinal - 1
                }
            })

            const orderedHeaders = updatedHeaders.sort((a, b) => a.ordinal - b.ordinal).map((header, index) => ({ ...header, ordinal: index }))

            saveConfigRequest.execute(undefined, {
                gridReference: grid.reference,
                displayedColumns: orderedHeaders.map(field => ({ fieldName: field.value, ordinal: field.ordinal }))
            })
            grid.setUserConfiguredHeaders(orderedHeaders.map((header, index) => ({ ...header, ordinal: index + 1 })))
        },
        hover: (draggedField: HeaderDefinition) => {
            if (draggedField.ordinal === ordinal) {
                setDraggedOverStyle("")
                return
            }

            if (draggedField.ordinal > ordinal) {
                setDraggedOverStyle(`${classes.borderLeft}`)
            } else if (draggedField.ordinal < ordinal) {
                setDraggedOverStyle(`${classes.borderRight}`)
            }

            return
        },
        collect: monitor => ({
            isOver: monitor.isOver()
        })
    })

    const ref = useRef<HTMLTableCellElement>(null)
    dragRef(ref)
    dropRef(ref)

    const getIconClasses = () => {
        if (!grid.sorting || grid.sorting.header.value !== value) return `${classes.noSort} fa fa-sort`
        return grid.sorting.direction === "ASC" ? "fa-duotone fa-sort" : "fa-duotone fa-sort fa-swap-opacity"
    }

    return (
        <th className={`ps-2 ${isOver ? draggedOverStyle : ""}`} ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <div
                className={`d-flex align-items-center pointer no-select me-3 text-uppercase text-truncate fw-normal ${classes.fieldHeader}`}
                onClick={toggleSort}
                aria-label={`${value}-sort`}
            >
                <i className={`fa fa-grip-dots-vertical me-2 ${classes.dragHandle}`} />
                {label}
                <i className={`${getIconClasses()} p-2`} />
            </div>
        </th>
    )
}

export default FieldHeader
