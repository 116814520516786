import React from 'react';
import { connect } from 'react-redux';
import { webApiInterface } from '../../../api/webApiInterface';
import { toDataSourceRequest } from '@progress/kendo-data-query';
import buildParameterString from '../../../utility/buildParameterString';
import { getApiUrl, clr } from '../../../constants';
import MappingGrid from './mappingGrid';
import { executeAuthAsyncGet } from '../../../utility/asyncSupport';

export class MappingGridFrame extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.exportCsv = this.exportCsv.bind(this);
  }

  exportCsv() {
    const xhr = new XMLHttpRequest();
    const serialisedState = toDataSourceRequest(this.props.gridState);
    const queryParameters = Object.assign({}, this.props.gridState, {
      page: 1,
      pageSize: clr.intMaxValue,
      filter: serialisedState.filter,
      tableId: this.props.selectedTableId
    });

    const url =
      getApiUrl(this.props.client) +
      'api/mapping/export?' +
      buildParameterString(queryParameters, true, true);

    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(xhr.response);
      a.download = 'export.csv';
      a.click();
    };

    this.props.executeAuthAsyncGet(this.props.client, 'Lookup/OK', 'CHECK_OK', null, function () {
      webApiInterface.applySecurity(xhr);
      xhr.send(null);
    });
  }

  static getKey() {
    return 'mapping-template';
  }

  render() {
    const hasTable = this.props.selectedTableId !== -1;
    const showGrid = this.props.tableSchema.results.length > 0 && hasTable;

    return (
      <div id="mapping-grid-frame" className="borderContainerNoTop">
        {showGrid ? (
          <div className="form-group well">
            <div className="row">
              <div className="col-12">
                <div style={{ padding: '10px' }}>
                  <button
                    onClick={this.exportCsv}
                    disabled={!hasTable}
                    className="btnDefault btn btn-default"
                  >
                    Download as csv
                  </button>
                </div>
                <MappingGrid
                  client={this.props.client}
                  selectedTableId={this.props.selectedTableId}
                  tableSchema={this.props.tableSchema.results}
                  gridKey={this.props.gridKey}
                />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    gridKey: ownProps.gridKey,
    selectedTableId: state.mappingReducer.selectedTableId,
    mappingTableNames: state.lookupReducer.mappingTableNames,
    tableSchema: state.lookupReducer.tableSchema,
    gridState: state.gridCacheReducer.grids[ownProps.gridKey]
  };
};

export default connect(mapStateToProps, {
  executeAuthAsyncGet
})(MappingGridFrame);
