import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import { getApiUrl } from '../../../constants';

import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../../common/grids/StatefulGrid';

class MasterTemplateVersionsGrid extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = { refresh: 0, selectedItemId: '' };
  }

  rebind = () => {
    this.setState({ refresh: this.state.refresh + 1 });
  };

  onRowClick = (gridRowEvent) => {
    const id = gridRowEvent.dataItem.EmailMasterTemplateVersionId;
    if (!isNil(id)) {
      this.setState({ selectedItemId: id });
      this.props.onRowSelected(id);
    }
  };

  isSelectedRow = (dataItem) =>
    this.state.selectedItemId === dataItem.EmailMasterTemplateVersionId;

  gridKey() {
    return 'masterTemplateVersionsGrid' + this.props.emailMasterTemplateId;
  }

  render() {
    return (
      <div
        key={this.gridKey()}
        id="mapping-grid"
        className="borderContainerNoTop"
      >
        <StatefulGrid
          client={this.props.client}
          id={this.gridKey()}
          key={this.gridKey() + 'grid'}
          path={getApiUrl(this.props.client) + 'api/email/MasterTemplateVersions'}
          filterable={false}
          pageable={{
            pageSizes: [50, 100, 200],
            buttonCount: 5
          }}
          onRowClick={this.onRowClick}
          additionalRequestPayload={{
            emailMasterTemplateId: this.props.emailMasterTemplateId
          }}
          defaultField={{ field: 'BrandInternalName' }}
          isSelectedRow={this.isSelectedRow}
          refresh={this.state.refresh}
          ref={(me) => (this.grid = me)}
        >
          <GridColumn field={'BrandInternalName'} title={'Brand'} />
          <GridColumn field={'LanguageName'} title={'Language'} />
        </StatefulGrid>
      </div>
    );
  }
}

MasterTemplateVersionsGrid.propTypes = {
  emailMasterTemplateId: PropTypes.string.isRequired,
  onRowSelected: PropTypes.func.isRequired
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  MasterTemplateVersionsGrid
);
