import TeamPerformanceSummary from "./TeamPerformanceSummary"
import UserRow from "./UserRow"
import moment from "moment"
import useApiQuery from "../../../../../hooks/useApiQuery"
import { useClient, useConfig, Loading } from "invevo-react-components"
import { useEffect, useState } from "react"
import Dropdown from "../../../../../library/dropdowns/Dropdown"
import GreyButton from "../../../../../library/buttons/GreyButton/GreyButton"

const TeamPerformance = () => {
    const client = useClient()
    const config = useConfig()

    const dateFilterDropdownOptions = [
        { value: "today", label: "Today" },
        { value: "last 7 days", label: "Last 7 Days" },
        { value: "this month", label: "This Month" },
        { value: "last month", label: "Last Month" }
    ]

    const [userCallMetricAggregations, setUserCallMetricAggregations] = useState([])
    const [totalCallsMade, setTotalCallsMade] = useState()
    const [totalCallDurationInMinutes, setTotalCallDurationInMinutes] = useState()
    const [selectedDateRangeFilter, setSelectedDateRangeFilter] = useState(dateFilterDropdownOptions[2])
    const [filterFrom, setFilterFrom] = useState(moment().startOf("month").toISOString())
    const [filterTo, setFilterTo] = useState(moment().endOf("month").toISOString())

    const POLL_INTERVAL_IN_MS = 60_000

    const getCallMetricAggregationsRequest = useApiQuery({
        url: `${config.VOIP_API_URL}/api/${client}/call-metric-aggregations`,
        method: "POST",
        dto: {
            fromDate: filterFrom,
            toDate: filterTo
        },
        onSuccess: data => {
            setUserCallMetricAggregations(data.metricsPerUser)
            setTotalCallsMade(data.totalCalls)
            setTotalCallDurationInMinutes(Math.ceil(data.totalCallDurationInSeconds / 60))
        }
    })

    useEffect(() => {
        const interval = setInterval(() => {
            getCallMetricAggregationsRequest.execute(undefined, {
                fromDate: filterFrom,
                toDate: filterTo
            })
        }, POLL_INTERVAL_IN_MS)

        return () => {
            clearInterval(interval)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterFrom, filterTo])

    const updateDateRangeFilter = selectedOption => {
        setSelectedDateRangeFilter(selectedOption)

        switch (selectedOption.value) {
            case "today":
                setFilterFrom(moment().startOf("day").toISOString())
                setFilterTo(moment().endOf("day").toISOString())
                break
            case "this month":
                setFilterFrom(moment().startOf("month").toISOString())
                setFilterTo(moment().endOf("month").toISOString())
                break
            case "last 7 days":
                setFilterFrom(moment().subtract(7, "days").startOf("day").toISOString())
                setFilterTo(moment().endOf("day").toISOString())
                break
            case "last month":
                setFilterFrom(moment().subtract(1, "months").startOf("month").toISOString())
                setFilterTo(moment().subtract(1, "months").endOf("month").toISOString())
                break
            default:
                break
        }
    }

    const exportMetricsToCsv = () => {
        const headers = "Username,Calls Made,Call Duration (Minutes)"
        const content = userCallMetricAggregations
            .map(userMetrics => `${userMetrics.username},${userMetrics.totalCalls},${Math.ceil(userMetrics.totalCallDurationInSeconds / 60)}`)
            .join("\r\n")

        const url = window.URL.createObjectURL(new Blob([headers.concat("\r\n").concat(content)]))
        const link = document.createElement("a")
        const filename = `voip_metrics_${moment(filterFrom).format("YYYY-MM-DD")}_to_${moment(filterTo).format("YYYY-MM-DD")}.csv`

        link.href = url
        link.setAttribute("download", filename)
        document.body.appendChild(link)
        link.click()

        link.parentNode.removeChild(link)
    }

    return (
        <div className="p-3 w-100 h-100 overflow-auto">
            <div className="d-flex flex-column mb-3">
                <span className="fs-2 text-grey">Team Performance</span>
                <div className="d-flex">
                    <Dropdown
                        options={dateFilterDropdownOptions}
                        onOptionSelected={updateDateRangeFilter}
                        selectedOption={selectedDateRangeFilter}
                        textAlign="left"
                        ariaLabel="date-filter"
                    />
                    <GreyButton
                        iconClasses="fal fa-arrow-down-to-line"
                        label="Export to CSV"
                        onClick={exportMetricsToCsv}
                        className="ms-auto"
                        ariaLabel="export-to-csv"
                    />
                </div>
            </div>
            <div className="d-flex flex-grow-1">
                <Loading isLoading={getCallMetricAggregationsRequest.isFetching && !getCallMetricAggregationsRequest.hasFetched} colour="blue">
                    <div className="d-flex flex-column w-100">
                        <TeamPerformanceSummary
                            totalCallsMade={totalCallsMade}
                            totalCallDurationInMinutes={totalCallDurationInMinutes}
                            totalUsers={userCallMetricAggregations.length}
                        />
                        {userCallMetricAggregations.map(userMetrics => (
                            <UserRow className="d-flex mb-tiny" key={userMetrics.username} userMetrics={userMetrics} />
                        ))}
                    </div>
                </Loading>
            </div>
        </div>
    )
}

export default TeamPerformance
