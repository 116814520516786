import {
  searchListNextLoading,
  searchListNextLoaded,
  searchListEnd
} from '../actions/searchActions';
import buildParameterString from '../utility/buildParameterString';
import { getApiUrl } from '../constants';
import displayError from '../utility/error';
import { showToastWarningMessage } from './toasterApi';
import { webApiInterface } from './webApiInterface';

export function getNextSearchList(
  client,
  requestPayload,
  searchType,
  previousAccountId
) {
  const payloadUrl = buildParameterString(requestPayload, true);
  const baseUrl = getApiUrl(client);
  const isAdvancedSearch = searchType === 'advanced';

  let url;

  if (isAdvancedSearch) {
    url = `${baseUrl}api/${'search/stepadvanced?'}${payloadUrl}`;
  } else {
    url = `${baseUrl}api/${'search/stepquick?'}${payloadUrl}&previousAccountId=${previousAccountId}`;
  }

  return function (dispatch) {
    dispatch(searchListNextLoading());

    webApiInterface
      .authFetch(client, url, dispatch)
      .then((response) => response.json())
      .then((json) => {
        if (json.EndOfList === true) {
          dispatch(searchListEnd());

          showToastWarningMessage(
            'You are already at the end of the activity list!'
          );
        } else {
          dispatch(
            searchListNextLoaded(
              json.customerId,
              json.virtualAccountId,
              json.page,
              json.total
            )
          );
        }
      })
      .catch((err) => displayError(err));
  };
}
