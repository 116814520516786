import classes from "./CustomerQuickFilters.module.scss"
import QuickFilterGroup from "./QuickFilterGroup"
import { Loading } from "invevo-react-components"
import DataField from "../../../types/DataField"
import Lookup from "../../../types/Lookup"
import QuickFilter from "../../automate/types/QuickFilter"
import { CustomerSearchAction, CustomerSearchActionEnum } from "../reducers/CustomerSearchReducer"

type CustomerQuickFiltersProps = {
    customerDataFields: DataField[]
    lookups: Lookup[]
    selectedQuickFilters: QuickFilter[]
    isLoadingCustomerData: boolean
    isLoadingDataFields: boolean
    dispatch: React.Dispatch<CustomerSearchAction>
}

const CustomerQuickFilters = ({
    customerDataFields,
    lookups,
    selectedQuickFilters,
    isLoadingCustomerData,
    isLoadingDataFields,
    dispatch
}: CustomerQuickFiltersProps) => {
    const quickFilterDataFields = customerDataFields.filter(field => field.isQuickFilter)

    const toggleShowQuickFilters = () => dispatch({ type: CustomerSearchActionEnum.TOGGLE_SHOW_QUICK_FILTERS })

    return (
        <div className={`bg-blue h-100 ${classes.slide} d-flex flex-column overflow-auto`}>
            <div className="d-flex align-items-center pt-3 px-3 text-white">
                <span className="fs-3  fw-lighter">Quick filters</span>
                <div
                    className={`d-flex rounded ms-auto pointer ${classes.closeButton}`}
                    role="button"
                    aria-label="close-quick-filters"
                    onClick={toggleShowQuickFilters}
                >
                    <i className="fa-regular fa-x m-auto"></i>
                </div>
            </div>
            <div className={`d-flex flex-column w-100 mt-3 overflow-auto ${classes.container}`}>
                <Loading isLoading={isLoadingDataFields} colour="white">
                    {quickFilterDataFields.length === 0 && <div className="align-self-center text-white">No quick filters available</div>}
                    {quickFilterDataFields.map(dataField => (
                        <QuickFilterGroup
                            lookups={lookups}
                            selectedQuickFilters={selectedQuickFilters}
                            dataField={dataField}
                            isLoadingCustomerData={isLoadingCustomerData}
                            dispatch={dispatch}
                            key={dataField.value}
                        />
                    ))}
                </Loading>
            </div>
        </div>
    )
}

export default CustomerQuickFilters
