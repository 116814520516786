import { ConnectionLineComponentProps, getStraightPath } from "reactflow"

const invevoDarkGrey = "#4a4a4a"

const ConnectionLine = ({ fromX, fromY, toX, toY }: ConnectionLineComponentProps) => {
    const [edgePath] = getStraightPath({
        sourceX: fromX,
        sourceY: fromY,
        targetX: toX,
        targetY: toY
    })

    return (
        <g>
            <path
                style={{
                    strokeWidth: 2,
                    stroke: invevoDarkGrey
                }}
                fill="none"
                d={edgePath}
            />
            <circle cx={toX} cy={toY} fill={invevoDarkGrey} r={2} stroke={invevoDarkGrey} strokeWidth={1} />
        </g>
    )
}

export default ConnectionLine
