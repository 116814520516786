import { useState, useRef, useEffect, useLayoutEffect } from 'react'
import { useApi, useClient, StandardButton, useConfig } from 'invevo-react-components'
import EditAmountButton from './EditAmountButton'

const PreviewPayment = ({customerId, selectedPaymentCard, onBackClicked, onPaymentProviderSelected}) => {
    const [charges, setCharges] = useState(0)
    const [isFetchingCharges, setIsFetchingCharges] = useState(false)
    const [invoicesToPay, setInvoicesToPay] = useState(selectedPaymentCard.amount.invoices.map(i => ({...i, amountToPay: i.amountDue})))
    const [totalToPay, setTotalToPay] = useState(0)
    const invoicesContainerRef = useRef()
    const invoicePaymentSummaryRef = useRef()
    const client = useClient()
    const api = useApi()
    const config = useConfig()
    
    useEffect(() => {
        setTotalToPay(invoicesToPay.map(i => i.amountToPay).reduce((total, i) => total + i) + charges)
    }, [charges, invoicesToPay])
    
    useEffect(() => {
        if(!config.PAY_API_URL) {
            return
        }
        
        const content = {
            cardId: selectedPaymentCard.cardId,
            currencyCode: selectedPaymentCard.currencyCode,
            customerId: customerId,
            transactionIds: invoicesToPay.map(i => ({
                amount: i.amountToPay,
                transactionId: i.id
            }))
        }
        
        setIsFetchingCharges(true)
        api.post(`${config.PAY_API_URL}/api/${client}/legacy-credit-card-charges`, content).then(response => {
           setCharges(response.data.charges)
           setIsFetchingCharges(false)
          }, error => { throw error })
    }, [api, client, config.PAY_API_URL, customerId, invoicesToPay, selectedPaymentCard.cardId, selectedPaymentCard.currencyCode])
    
    useLayoutEffect(() => {
        window.addEventListener('resize', recalcInvoicesContainerHeight)
        setTimeout(recalcInvoicesContainerHeight, 100)
        
        return () => {
            window.removeEventListener('resize', recalcInvoicesContainerHeight)
        }
    }, [])
    
    const recalcInvoicesContainerHeight = () => {
        const top = invoicesContainerRef.current.getBoundingClientRect().top
        let footerHeight = invoicePaymentSummaryRef.current.style.offsetHeight
        if(isNaN(footerHeight)) {
            footerHeight = invoicePaymentSummaryRef.current.getBoundingClientRect().height 
        }
        const newContainerHeight = window.innerHeight - top - footerHeight
        invoicesContainerRef.current.style.height = `${newContainerHeight}px`
    }
    
    const updateInvoice = (invoiceId, amount) => 
        setInvoicesToPay(invoicesToPay.map((invoice) => {
            if (invoice.id !== invoiceId) {
              return invoice
            }
        
            return {
              ...invoice,
              amountToPay: amount
            }
        }))
    
    return (
        <div className="text-white pb-4 pe-4">
            <div className="d-flex my-4">
                <StandardButton 
                    className="flex-grow-1"
                    iconClasses="fal fa-arrow-left" 
                    label="Payment options" 
                    colour="blue"
                    onClick={onBackClicked} />
            </div>
            <div ref={invoicesContainerRef} className="d-flex flex-column overflow-auto">
                { invoicesToPay.map(i => 
                    <div key={i.invoiceNumber} className="d-flex justify-content-between py-3 border-bottom">
                        <span className="align-self-center font-weight-bold">{i.transactionRef}</span>
                        <EditAmountButton 
                            onEditAmountChanged={(newAmount) => updateInvoice(i.id, newAmount)} 
                            originalAmount={i.amount} 
                            amount={i.amountToPay}
                            currencyCode={selectedPaymentCard.currencyCode}/>
                    </div>
                )}
            </div>
            <div ref={invoicePaymentSummaryRef} className="d-flex flex-column">
                <div className="d-flex justify-content-between py-2 border-bottom">
                    { client === "ubm" ?
                        <span className="text-uppercase">UNEARNED DISCOUNT</span>:
                        <span className="text-uppercase">CHARGE</span>
                    }
                    <span>{formatCurrencyAmount(charges, "en-GB", selectedPaymentCard.currencyCode)}</span>
                    </div>
                    <div className="d-flex justify-content-between py-2">
                        <span className="text-uppercase">TOTAL</span>
                        <span className="font-weight-bold">{formatCurrencyAmount(totalToPay, "en-GB", selectedPaymentCard.currencyCode)}</span>
                    </div>
                    { totalToPay <= 0 ? 
                        <div className="d-flex ml-auto align-items-center">
                            <i className="fal fa-exclamation-triangle mr-2"></i>
                            <span className="font-weight-bold">Total amount to pay must be greater then zero</span>
                        </div> : ''
                    }
                <StandardButton 
                    className="my-4" 
                    onClick={() => onPaymentProviderSelected(charges, [...invoicesToPay], selectedPaymentCard)}
                    disabled={totalToPay <= 0 || isFetchingCharges}
                    iconClasses="fal fa-check" 
                    label={`Pay now with ${selectedPaymentCard.brand}`}
                    colour="blue" />
            </div>
        </div>
    )
}
const formatCurrencyAmount = (value, culture, currencySymbol) => new Intl.NumberFormat(culture, { style: 'currency', currency: currencySymbol }).format(value)

export default PreviewPayment