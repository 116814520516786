import classes from "./TimeInput.module.scss"

type TimeInputProps = {
    dateTime?: {
        hour: number
        minute: number
        timeZone: string
    }
    ariaLabel?: string
    onDateTimeChange: (date: { hour: number, minute: number, timeZone: string }) => void
}

export const TimeInput = ({ dateTime, ariaLabel, onDateTimeChange }: TimeInputProps) => {
    const onHourChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const validNumberRegex = /^[+-]?\d+[.]?(\d+)?$/;
        const isNumber = validNumberRegex.test(e.target.value)
        if (isNumber) {
            const hour = parseInt(e.target.value ?? '0')
            if (hour >= 24)
                onDateTimeChange({ hour: 23, minute: dateTime?.minute ?? 0, timeZone: dateTime?.timeZone ?? "Z" })
            else
                onDateTimeChange({ hour: parseInt(e.target.value ?? '0'), minute: dateTime?.minute ?? 0, timeZone: dateTime?.timeZone ?? "Z" })
        }
        else if (e.target.value === "")
            onDateTimeChange({ hour: 0, minute: dateTime?.minute ?? 0, timeZone: dateTime?.timeZone ?? "Z" })
    }

    const onMinuteChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const validNumberRegex = /^[+-]?\d+[.]?(\d+)?$/;
        const isNumber = validNumberRegex.test(e.target.value)
        if (isNumber) {
            const minute = parseInt(e.target.value ?? '0' ?? '0')
            if (minute >= 60)
                onDateTimeChange({ hour: dateTime?.hour ?? 0, minute: 59, timeZone: dateTime?.timeZone ?? "Z" })
            else
                onDateTimeChange({ hour: dateTime?.hour ?? 0, minute: minute, timeZone: dateTime?.timeZone ?? "Z" })
        }
        else if (e.target.value === "")
            onDateTimeChange({ hour: dateTime?.hour ?? 0, minute: 0, timeZone: dateTime?.timeZone ?? "Z" })
    }

    return (
        <div className="d-flex">
            <input
                aria-label={`${ariaLabel}-hour`}
                className={`form-control bg-grey p-1 ${classes.input}`}
                value={`${dateTime?.hour ?? '00'}`}
                onChange={onHourChanged}
            />
            <span className="text-black fs-5 pt-1 mx-2">:</span>
            <input
                aria-label={`${ariaLabel}-minute`}
                className={`form-control bg-grey p-1 ${classes.input}`}
                value={`${dateTime?.minute ?? '00'}`}
                onChange={onMinuteChanged}
            />
            <span className="ms-2 pt-1">UTC</span>
        </div>
    )
}

export default TimeInput
