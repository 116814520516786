import LegacyApp from "../../../microfrontends/legacy/LegacyApp"
import UserEmailTaskContainer from "../../../microfrontends/legacy/components/userTask/userEmailTaskContainer"
import classes from './UnreadEmails.module.scss'
import { useLayout } from "../../../contexts/LayoutContext"

const UnreadEmails = ({ className, store }) => {
    const { isLeftPanelExpanded } = useLayout()

    return (
        <div className={`${className ? className : ''} d-flex h-100 ${isLeftPanelExpanded ? 'w-100' : classes.sidebar} ${classes['unread-emails']} bg-grey`}>
            <LegacyApp className="d-flex w-100 h-100" store={store}>
                <div className="d-flex w-100 h-100">
                    <UserEmailTaskContainer />
                </div>
            </LegacyApp>
        </div>
    )
}

export default UnreadEmails