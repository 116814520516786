import DataRow from "../../../library/dataGrid/types/DataRow"
import moment from "moment"

export type CustomerResultsDto = {
    pagedCustomers: CustomerResultDto[]
    totalCount: number
    totalCurrentBalances: { currency: string; value: number }[]
}

export type CustomerResultDto = {
    reference: string
    client: string
    booleanFields: { [key: string]: boolean }
    numberFields: { [key: string]: number }
    datetimeFields: { [key: string]: number }
    stringFields: { [key: string]: string }
}

export const convertCustomerToDataRow = (customer: CustomerResultDto): DataRow => ({
    id: customer.reference,
    data: {
        reference: customer.reference,
        ...customer.stringFields,
        ...convertValuesToString(customer.numberFields),
        ...convertDateFieldsToString(customer.datetimeFields),
        ...convertValuesToString(customer.booleanFields)
    }
})

const convertValuesToString = (obj: { [key: string]: number | boolean }) => {
    const result: { [key: string]: string } = {}
    Object.keys(obj).forEach(key => {
        result[key] = obj[key]!.toString()
    })
    return result
}

const convertDateFieldsToString = (obj: { [key: string]: number }) => {
    const result: { [key: string]: string } = {}
    Object.keys(obj).forEach(key => {
        result[key] = moment(obj[key]).format()
    })
    return result
}
