import update from 'immutability-helper';

const init = {
  selectedAccount: {
    miaAccountId: '',
    miaAccountName: '',
    miaAccountIsVirtualAccount: false,
    miaAccountVirtualLevel: '',
    miaAccountCode: '',
    miaAccountLoading: false
  },
  isReportNavigationFromMainMenu: true
};

const currentSelectionReducer = (state = init, action) => {
  switch (action.type) {
    case 'SET_REPORT_ORIGIN':
      return update(state, {
        isReportNavigationFromMainMenu: { $set: action.isFromMainMenu }
      });

    case 'CUSTOMER_LOADED':
      const a = action.customer;
      if (a.id) {
        const id = a.id;
        const accountCode = a.accountCode;
        const name = a.name;
        const virtualLevel = a.virtualLevel === null ? '' : a.virtualLevel;
        const isVirtualAccount = virtualLevel !== '';
        return update(state, {
          selectedAccount: {
            miaAccountId: { $set: id },
            miaAccountName: { $set: name },
            miaAccountIsVirtualAccount: { $set: isVirtualAccount },
            miaAccountVirtualLevel: { $set: virtualLevel },
            miaAccountCode: { $set: accountCode },
            miaAccountLoading: { $set: false }
          }
        });
      } else {
        let isVirtualAccount = false;
        if (a.customerId.length === 0) {
          isVirtualAccount = true;
        }
        const id = isVirtualAccount ? a.virtualAccountId : a.customerId;
        const accountCode = a.accountCode;
        const name = a.name;
        const virtualLevel = a.virtualLevel;
        return update(state, {
          selectedAccount: {
            miaAccountId: { $set: id },
            miaAccountName: { $set: name },
            miaAccountIsVirtualAccount: { $set: isVirtualAccount },
            miaAccountVirtualLevel: { $set: virtualLevel },
            miaAccountCode: { $set: accountCode },
            miaAccountLoading: { $set: false }
          }
        });
      }
    case 'CUSTOMER_CHANGED':
    case 'CSR_REQUEST_SET_SELECTED_ACCOUNT':
      return update(state, {
        selectedAccount: {
          miaAccountId: { $set: action.accountId },
          miaAccountName: { $set: '' },
          miaAccountIsVirtualAccount: { $set: action.isVirtualAccount },
          miaAccountVirtualLevel: { $set: '' },
          miaAccountCode: { $set: '' },
          miaAccountLoading: { $set: true }
        }
      });

    case 'CSR_RECEIVE_SET_SELECTED_ACCOUNT':
      return update(state, {
        selectedAccount: {
          miaAccountId: { $set: action.data.id },
          miaAccountName: { $set: action.data.name },
          miaAccountIsVirtualAccount: { $set: action.data.isVirtualAccount },
          miaAccountVirtualLevel: { $set: action.data.virtualLevel },
          miaAccountCode: { $set: action.data.code },
          miaAccountLoading: { $set: false }
        }
      });

    case 'RESET_CURRENT_CUSTOMER_STATE':
      return init

    case 'CSR_ERROR_SET_SELECTED_ACCOUNT':
      return { ...state, selectedAccount: { ...init.selectedAccount } };

    default:
      return state;
  }
};
export default currentSelectionReducer;
