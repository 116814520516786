import Input from "../Inputs/Input"
import { useFeatureToggle } from "../../hooks/useFeatureToggle"
import { convertToCurrency } from "../helpers/numberUtils"
import { removeNumberFormatting } from "../helpers/removeNumberFormatting"

type MoneyInfoElementProps = {
    className: string
    value: string
    fieldName: string
    isEditing: boolean
    currency?: string
    culture?: string
    disabled: boolean
    onChange: (value: string) => void
}

const MoneyInfoElement = ({ className, value, fieldName, isEditing, currency, culture, disabled, onChange }: MoneyInfoElementProps) => {
    const onNumberValueChange = (value: string) => onChange(value)
    const { isEnabled: removeCurrencySymbols } = useFeatureToggle("removeCurrencySymbols")

    return (
        <div className={`fs-4 ${className}`}>
            <Input
                className="w-100"
                ariaLabel={`${fieldName}-money-input`}
                value={isEditing ? value : convertToCurrency(removeNumberFormatting(value), currency, culture, removeCurrencySymbols)}
                onChange={onNumberValueChange}
                disabled={disabled}
            />
        </div>
    )
}

export default MoneyInfoElement
