import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find, cloneDeep, split, isNil, sortBy } from 'lodash';
import Formsy from 'formsy-react';

import ValidatingInput from '../../common/ValidatingInput';
import Dropdown from '../../common/dropdown';
import AccountValue from '../../accountInfo/accountValue';
import { hasPermission } from '../../../utility/authZ';
import { authZ_Permissions } from '../../../constants';

class RuleActionForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.onChange = this.onChange.bind(this);
    this.onChangeRuleAction = this.onChangeRuleAction.bind(this);
    this.updatePayloadItem = this.updatePayloadItem.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.enableSave = this.enableSave.bind(this);
    this.disableSave = this.disableSave.bind(this);

    this.state = RuleActionForm.defaultFormState();
  }

  static defaultFormState() {
    return {
      ruleAction: {
        executionOrder: '',
        inhibitForDays: ''
      },
      canSave: false,
      showInhibitorError: false
    };
  }

  componentDidMount() {
    this.setState({ ruleAction: Object.assign({}, this.props.ruleAction) });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ruleAction.name !== this.props.ruleAction.name)
      this.setState({
        ruleAction: this.props.ruleAction
      });
  }

  onChange(event) {
    const field = event.target.name;
    const value = event.target.value;

    const ruleAction = {
      ...this.state.ruleAction,
      [field]: value
    };

    return this.setState({ ruleAction: ruleAction });
  }

  onUpdate() {
    this.props.onUpdate(this.state.ruleAction);
  }

  onCancel() {
    this.setState({ ruleAction: this.props.ruleAction });

    this.props.onCancel();
  }

  enableSave() {
    this.setState({ canSave: true });
  }

  disableSave() {
    this.setState({ canSave: false });
  }

  saveEnabled() {
    return (
      this.state.canSave &&
      this.validInhibitorValues() &&
      this.state.ruleAction.name
    );
  }

  validInhibitorValues() {
    return (
      (isNil(this.state.ruleAction.inhibitorType) ||
        this.state.ruleAction.inhibitorType === '') ===
      (isNil(this.state.ruleAction.inhibitForDays) ||
        this.state.ruleAction.inhibitForDays === '')
    );
  }

  onChangeRuleAction(name) {
    const ruleAction = cloneDeep(this.state.ruleAction);
    const actionDefinition = find(
      this.props.actionDefinitions,
      (f) => f.name === name
    );

    ruleAction.name = name;
    ruleAction.webMethod = actionDefinition.webMethod;
    ruleAction.requestType = actionDefinition.requestType;

    this.setState({ ruleAction });
  }

  updatePayloadItem(customerId, fieldId, value, display) {
    const ruleAction = cloneDeep(this.state.ruleAction);
    const payloadItem = find(ruleAction.payloadItems, (f) => f.key === fieldId);

    if (payloadItem) {
      payloadItem.value = value;
      payloadItem.displayValue = display || value;
    } else {
      const actionDefinition = find(
        this.props.actionDefinitions,
        (f) => f.name === ruleAction.name
      );
      const actionParameter = find(
        actionDefinition.parameters,
        (f) => f.name === fieldId
      );

      ruleAction.payloadItems.push({
        key: fieldId,
        value: value,
        displayValue: display || value,
        type: actionParameter.type,
        list: actionParameter.list
      });
    }

    this.setState({ ruleAction });

    return Promise.resolve({ value: display || value });
  }

  getParentValue(parentList) {
    const actionDefinition = find(
      this.props.actionDefinitions,
      (f) => f.name === this.state.ruleAction.name
    );
    let parentValue = null;

    if (actionDefinition) {
      this.state.ruleAction.payloadItems.forEach((m) => {
        const parameterDefinition = find(
          actionDefinition.parameters,
          (f) => f.name === m.key
        );

        if (
          parameterDefinition &&
          parameterDefinition.list === parentList &&
          parentValue == null
        ) {
          parentValue = split(m.value, ',');
        }
      });
    }

    return parentValue;
  }

  setLookupParameters(actionDefinitionParameter) {
    let lookupParameters = null;

    if (
      actionDefinitionParameter.type === 'list' ||
      actionDefinitionParameter.type === 'filterList'
    ) {
      if (actionDefinitionParameter.list === 'taskDescription') {
        const parentValue = this.getParentValue('taskType');

        if (parentValue) {
          lookupParameters = { taskTypeIds: parentValue };
        }
      } else if (actionDefinitionParameter.list === 'taskCompletion') {
        const parentValue = this.getParentValue('taskType');

        if (parentValue) {
          lookupParameters = { taskTypeIds: parentValue };
        }
      } else if (
        actionDefinitionParameter.list === 'stateMachineActionDateField'
      ) {
        lookupParameters = {
          action: this.state.ruleAction,
          ruleGroup: this.props.ruleGroup
        };
      } else if (actionDefinitionParameter.list === 'transactionSubStatus') {
        const parentValue = this.getParentValue('transactionStatus');

        if (parentValue) {
          lookupParameters = { transactionStatusIds: parentValue };
        }
      } else if (actionDefinitionParameter.list === 'address') {
        const parentValue = this.getParentValue('customer');

        if (parentValue) {
          lookupParameters = { customerIds: parentValue };
        }
      } else if (actionDefinitionParameter.list === 'branch') {
        const parentValue = this.getParentValue('brand');

        if (parentValue) {
          lookupParameters = { brandInternalIds: parentValue };
        }
      }
    }

    return lookupParameters;
  }

  renderParameters(canEdit) {
    let sectionContent = '';
    let alt = true;

    if (this.props.actionDefinitions) {
      const actionDefinition = find(
        this.props.actionDefinitions,
        (f) => f.name === this.state.ruleAction.name
      );

      if (actionDefinition && actionDefinition.parameters) {
        sectionContent = actionDefinition.parameters.map((m) => {
          alt = !alt;

          const payloadItem = find(
            this.state.ruleAction.payloadItems,
            (f) => f.key === m.name
          );
          const val = payloadItem ? String(payloadItem.value) : null;
          const displayVal = payloadItem ? payloadItem.displayValue : val;

          const item = {
            id: m.name,
            edit: canEdit,
            title: m.name,
            displayValue: displayVal,
            value: val,
            type: m.type,
            list: m.list,
            multi: m.multi,
            nullable:
              m.nullable /*,
                            maxLength: this.props.detail.maxLength
                            */
          };

          item.parameters = this.setLookupParameters(m);

          return (
            <AccountValue
              key={'Value.' + item.id}
              detail={item}
              alt={alt}
              save={this.updatePayloadItem}
            />
          );
        });
      }
    }

    return <div className="w-50">{sectionContent}</div>;
  }

  render() {
    let isDisabled = false;
    const header = this.state.ruleAction.id
      ? `Edit Rule Action (Id:${this.state.ruleAction.id})`
      : 'Add Rule Action';
    const inhibitorTypes = [
      { id: '', name: 'None' },
      { id: 'CST', name: 'Customer' },
      { id: 'GRP', name: 'Group' },
      { id: 'TRN', name: 'Transaction' }
    ];
    const associatedRuleConditions = this.props.ruleGroup.rules.slice(0);

    associatedRuleConditions.unshift({ evaluationOrder: null });

    let buttons = (
      <div>
        <button
          type="button"
          className="btn btn-default"
          onClick={this.onUpdate}
          disabled={!this.saveEnabled()}
        >
          OK
        </button>
        <button
          type="button"
          className="btn btn-default"
          onClick={this.onCancel}
        >
          Cancel
        </button>
      </div>
    );

    if (
      !hasPermission(
        this.props.permissions,
        authZ_Permissions.RuleGroupManagementAdmin
      )
    ) {
      isDisabled = true;
      buttons = (
        <div>
          <button
            type="button"
            className="btn btn-default"
            onClick={this.onCancel}
          >
            Cancel
          </button>
        </div>
      );
    }

    let inhibitorError = '';

    if (!this.validInhibitorValues()) {
      inhibitorError = 'Inhibitor type and days must both be specified';
    }

    const ruleActions = sortBy(this.props.ruleActions, (s) => s.name);

    return (
      <div>
        <Formsy
          key="rule-group-form"
          onValid={this.enableSave}
          onInvalid={this.disableSave}
          className="below-grid-form"
          id="rulegroupform"
        >
          <h3>{header}</h3>
          <br />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <ValidatingInput
                    name="executionOrder"
                    type="number"
                    required
                    placeholder="Execution Order"
                    value={this.state.ruleAction.executionOrder}
                    onChange={this.onChange}
                    disabled={isDisabled}
                    validations="isInt"
                    hideLabel={true}
                    validationError={'Enter a number.'}
                  />
                </div>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <Dropdown
                  name="associatedRuleCondition"
                  title="Associated Rule Condition"
                  data={associatedRuleConditions}
                  selectedItemId={this.state.ruleAction.rule}
                  idProp="evaluationOrder"
                  textProp="evaluationOrder"
                  onClick={(id) => {
                    this.onChange({ target: { name: 'rule', value: id } });
                  }}
                  disabled={isDisabled}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <Dropdown
                  name="actionName"
                  title="Action"
                  data={ruleActions}
                  selectedItemId={this.state.ruleAction.name}
                  idProp="name"
                  textProp="name"
                  onClick={this.onChangeRuleAction}
                  disabled={isDisabled}
                  required
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <Dropdown
                  name="inhibitorType"
                  title="Inihibitor Type"
                  data={inhibitorTypes}
                  selectedItemId={this.state.ruleAction.inhibitorType}
                  idProp="id"
                  textProp="name"
                  onClick={(id) =>
                    this.setState((previousState) => ({
                      ...previousState,
                      ruleAction: {
                        ...previousState.ruleAction,
                        inhibitorType: id
                      }
                    }))
                  }
                  disabled={isDisabled}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <input
                  name="inhibitForDays"
                  type="number"
                  placeholder="Inhibitor Days"
                  className="form-control"
                  value={this.state.ruleAction.inhibitForDays}
                  onChange={this.onChange}
                  disabled={isDisabled}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <div className="validation-error">{inhibitorError}</div>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-12">
                <h3 className="font-weight-bold">Parameters</h3>
                {this.renderParameters(!isDisabled)}
              </div>
            </div>
          </div>
          {buttons}
          <br />
          <br />
        </Formsy>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.authReducer.permissions
  };
}

const mapDispatchToEvents = () => ({});

export default connect(mapStateToProps, mapDispatchToEvents)(RuleActionForm);
