import { ReactNode } from "react"
import classes from "./Collapsable.module.scss"

type CollapsableProps = {
    children: ReactNode
    classname?: string
    title: ReactNode
    isCollapsed: boolean
    background?: BackgroundColour
    onCollapseToggle: () => void
}

type BackgroundColour = "grey" | "blue" | "white"

const Collapsable = ({ children, classname = "", title, isCollapsed, background = "grey", onCollapseToggle }: CollapsableProps) => {
    const chevronColour = getChevronColourForBackground(background)
    const textColour = getTextColourForBackground(background)
    return (
        <div className={`${classname} d-flex flex-column bg-${background} ${isCollapsed ? "overflow-hidden" : "overflow-visible"}`}>
            <div className={`d-flex align-items-center text-${textColour} pointer no-select`} onClick={onCollapseToggle}>
                <div className="me-auto fs-5">{title}</div>
                <i className={`fa-solid fa-chevron-right fa-lg p-1 text-${chevronColour} mx-1 ${classes.chevron} ${isCollapsed ? "" : classes.rotated}`} />
            </div>
            <div className={`${classes.collapsable} ${isCollapsed ? classes.collapsed : "overflow-visible"}`}>{children}</div>
        </div>
    )
}

export default Collapsable

const getTextColourForBackground = (colour: BackgroundColour): BackgroundColour => {
    switch (colour) {
        case "blue":
            return "white"
        case "grey":
            return "grey"
        case "white":
            return "grey"
    }
}

const getChevronColourForBackground = (colour: BackgroundColour): BackgroundColour => {
    switch (colour) {
        case "blue":
            return "grey"
        case "grey":
            return "blue"
        case "white":
            return "blue"
    }
}
