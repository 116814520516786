import { useEffect, useState } from "react"
import useClickOutsideRef from "../../../hooks/useClickOutsideRef"
import { DataPrimitiveTypeEnum, DataTypeEnum, getDataPrimitiveTypeForEntityDataType } from "../../../routes/entityConfig/types/DataType"
import TextInfoElement from "../../cards/TextInfoElement"
import EditIcons from "../../cards/EditIcons"
import classes from "./DataCell.module.scss"
import DateInfoElement from "../../cards/DateInfoElement"
import LookupInfoElement from "../../cards/LookupInfoElement"
import MoneyInfoElement from "../../cards/MoneyInfoElement"
import NumberInfoElement from "../../cards/NumberInfoElement"
import BooleanInfoElement from "../../cards/BooleanInfoElement"
import HeaderDefinition from "./../types/HeaderDefinition"
import Lookup from "../../../types/Lookup"
import { useGrid } from "./../Grid"
import { formatFieldValueForHeader } from "./../util"
import { useOverlay } from "../../../contexts/overlay/OverlayContext"
import UpdateField from "../../../routes/entitySearch/components/UpdateField"
import { FieldsUpdate } from "../../common"
import EntityConfig from "../../../routes/entityConfig/types/EntityConfig"
import { useFeatureToggle } from "../../../hooks/useFeatureToggle"
import { DEFAULT_DATE_FORMAT, DEFAULT_TIMEZONE } from "../../helpers/dateUtils"

type Props = {
    rowReference: string
    fieldValue: string
    optimisticFieldValue?: string
    header: HeaderDefinition
    entityConfig?: EntityConfig
    filteredLookups: Lookup[]
    theme?: "text-blue" | "text-white"
    isEditable: boolean
    canWrap?: boolean
}

const DataCell = ({
    rowReference,
    fieldValue,
    optimisticFieldValue,
    header,
    entityConfig,
    filteredLookups,
    theme = "text-blue",
    isEditable,
    canWrap = false
}: Props) => {
    const [isEditing, setIsEditing] = useState(false)
    const [inputValue, setInputValue] = useState<string>(fieldValue)
    const overlay = useOverlay()

    const { isEnabled: clearDateFieldToEntity } = useFeatureToggle("permClearDateFieldSqlToEntity")
    const { isEnabled: isEntityEnabled } = useFeatureToggle("newEntityStructure")

    const shouldClearDateField = clearDateFieldToEntity && isEntityEnabled

    useEffect(() => {
        const value = optimisticFieldValue ?? fieldValue
        setInputValue(value)
    }, [fieldValue, optimisticFieldValue])

    const ref = useClickOutsideRef(() => {
        if (header.displayType === DataTypeEnum.LOOKUP || header.displayType === DataTypeEnum.DATE) {
            return
        }
        setIsEditing(false)
        setInputValue(fieldValue)
    })

    const onUpdatesSavedFromOverlay = async (updates: FieldsUpdate[]) => {
        const firstUpdate = updates[0]
        if (firstUpdate === undefined) return
        const dataPrimitiveType = getDataPrimitiveTypeForEntityDataType(header.displayType)
        switch (dataPrimitiveType) {
            case DataPrimitiveTypeEnum.BOOLEAN:
                onUpdate(Object.values(firstUpdate.booleanFields)[0]?.fieldValue ? "true" : "false")
                break
            case DataPrimitiveTypeEnum.DATE:
                onUpdate(Object.values(firstUpdate.dateTimeFields)[0]?.fieldValue)
                break
            case DataPrimitiveTypeEnum.NUMBER:
                onUpdate(Object.values(firstUpdate.numberFields)[0]?.fieldValue?.toString())
                break
            case DataPrimitiveTypeEnum.TEXT:
                onUpdate(Object.values(firstUpdate.stringFields)[0]?.fieldValue)
                break
        }
        return Promise.resolve()
    }

    const onClick = () => {
        if (!isEditable || isEditing) return
        if (header.displayType === DataTypeEnum.RICH_TEXT && entityConfig !== undefined) {
            const field = entityConfig.fields.find(f => f.fieldName === header.value)
            if (field === undefined) return
            overlay.showOverlay(
                <UpdateField
                    entityConfigs={[entityConfig]}
                    currentEntityType={entityConfig.reference}
                    currentEntityRef={rowReference}
                    fieldTypeName={field.displayName}
                    field={{ fieldName: field.fieldName, dataType: field.dataType.type }}
                    onUpdateSubmit={onUpdatesSavedFromOverlay}
                    initialValue={optimisticFieldValue ?? fieldValue}
                />
            )
        } else {
            setIsEditing(true)
        }
    }

    const grid = useGrid()

    const onUpdate = (value?: string) => {
        if (value === undefined) return
        grid.onRowsUpdated([
            {
                rowReference,
                header,
                newValue: value
            }
        ])
        setInputValue(value)
    }

    const onEditSave = () => {
        onUpdate(inputValue)
        setIsEditing(false)
    }

    const getLookupValues = () => {
        if (header.displayType !== DataTypeEnum.LOOKUP) return []

        const lookup = filteredLookups.find(lookup => lookup.reference === header.lookupReference)
        if (lookup === undefined) return []

        return lookup.entries.map(entry => ({ label: entry.name, value: entry.reference }))
    }

    const onEditCancel = () => {
        setInputValue(optimisticFieldValue ?? fieldValue)
        setIsEditing(false)
    }

    const currencyField = header.currencyFieldReference
    const currencyCode =
        header.displayType !== DataTypeEnum.MONEY
            ? undefined
            : header.currencyCode ??
              (currencyField !== undefined ? grid.data.rows.find(row => row.reference === rowReference)?.fields?.textFields[currencyField] : undefined)

    const renderSwitch = () => {
        switch (header.displayType) {
            case DataTypeEnum.RICH_TEXT:
            case DataTypeEnum.TEXT:
            case DataTypeEnum.EMAIL:
            case DataTypeEnum.PHONE_NUMBER:
                return <TextInfoElement className="w-100" value={inputValue} fieldName={header.value} disabled={false} onChange={setInputValue} />
            case DataTypeEnum.DATE:
                return (
                    <DateInfoElement
                        className="w-100"
                        value={inputValue}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        timezone={header.timezone || DEFAULT_TIMEZONE}
                        disabled={false}
                        onChange={setInputValue}
                    />
                )
            case DataTypeEnum.MONEY:
                return (
                    <MoneyInfoElement
                        className="d-flex w-100"
                        value={inputValue}
                        fieldName={header.value}
                        isEditing={true}
                        currency={currencyCode}
                        disabled={false}
                        onChange={setInputValue}
                    />
                )
            case DataTypeEnum.INT:
            case DataTypeEnum.DECIMAL:
                return <NumberInfoElement className="w-100" value={inputValue} fieldName={header.value} disabled={false} onChange={setInputValue} />
            case DataTypeEnum.BOOLEAN:
                return <BooleanInfoElement fieldName={header.value} status={inputValue.toLowerCase() === "true"} disabled={false} onChange={setInputValue} />
            case DataTypeEnum.LOOKUP:
                return <LookupInfoElement value={inputValue} lookupValueList={getLookupValues()} disabled={false} onChange={setInputValue} />
        }
    }

    const { isEnabled: removeCurrencySymbols } = useFeatureToggle("removeCurrencySymbols")
    const displayedValue = formatFieldValueForHeader(
        optimisticFieldValue ?? fieldValue,
        { ...header, currencyCode },
        filteredLookups,
        removeCurrencySymbols,
        shouldClearDateField
    )

    const style = getFieldBorderStyle(header.value)

    const isEditableAndFieldIsNotFixed = isEditable && header.value !== "reference" && header.value !== "client" && header.value !== "entity_type"

    return (
        <div
            ref={ref}
            className={`d-flex ${displayedValue.length === 0 ? "flex-grow-1" : ""} fw-bold pe-auto rounded ${classes.display} ${
                isEditing || !isEditableAndFieldIsNotFixed ? "" : classes.editableField
            }`}
            role={isEditableAndFieldIsNotFixed ? "button" : undefined}
            onClick={onClick}
            aria-label={`${rowReference}-${header.value}-value`}
        >
            {isEditableAndFieldIsNotFixed && isEditing ? (
                <div className="d-flex gap-2 w-100">
                    {renderSwitch()}
                    <EditIcons theme={theme} isHidden={false} onSave={onEditSave} onCancel={onEditCancel} />
                </div>
            ) : (
                renderData(displayedValue, header, style, theme, canWrap)
            )}
        </div>
    )
}

export default DataCell

const getFieldBorderStyle = (fieldName: string) => {
    switch (fieldName) {
        case "text_name":
        case "name":
            return {
                border: "1px solid #96BED2",
                backgroundColor: "#C5DFEB"
            }
        case "number_overdue":
        case "overdue":
            return {
                border: "1px solid #C78488",
                backgroundColor: "#D6D4E2"
            }
        case "number_balance":
        case "balance":
            return {
                border: "1px solid #9AD0C9",
                backgroundColor: "#C5E0E5"
            }
        default:
            return
    }
}

const renderData = (
    displayedValue: string,
    header: HeaderDefinition,
    style: { border: string; backgroundColor: string } | undefined,
    theme: string,
    canWrap: boolean
) => {
    return (
        <div className="d-flex gap-1 align-items-center">
            <div
                className={`${canWrap ? "" : "text-nowrap"} px-1 rounded ${style && theme === "text-white" ? "text-grey" : ""} ${classes.contents}`}
                style={displayedValue === "" ? {} : style}
            >
                {displayedValue}
            </div>
        </div>
    )
}
