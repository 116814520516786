import { isArray, isNil, every, some } from 'lodash'

export function hasPermission(assignedPermissions, requestedPermission, requiresAll = false) {


  if (isNil(assignedPermissions) || isNil(requestedPermission)) {
    return false;
  }

  if (!isArray(requestedPermission)) {
    return assignedPermissions[requestedPermission] === true;
  }

  const foo = requiresAll
    ? every
    : some;

  return foo(requestedPermission, p => ( assignedPermissions[p] === true ));
}