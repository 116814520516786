import { connect } from 'react-redux';
import { useInternationalization } from '@progress/kendo-react-intl';

const Money = ({ number, currencyCode, format, fallback = '' }) => {
  const internationalization = useInternationalization();

  if (number === null) {
    return fallback;
  }

  const money = internationalization.formatNumber(number, format);

  return !!currencyCode ? `${currencyCode} ${money}` : money;
};

const mapStateToProps = (state) => ({
  format: state.lookupReducer.globalSettings.results.currencyFormat
});

export default connect(mapStateToProps)(Money);
