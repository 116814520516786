import GenericFilter from "../../../library/FilterList/filterTypes/GenericFilter"
import GenericAction, { convertFromActionsArrayToDto, convertFromActionsDtoToArray } from "./actions/GenericAction"
import { convertFromArrayToDto, convertFromDtoToArray } from "../../../library/FilterList/FiltersDto"
import PutScheduledWorkflowRuleDto from "./PutScheduledWorkflowRuleDto"
import GetScheduledWorkflowRuleDto from "./GetScheduledWorkflowRuleDto"
import Lookup from "../../../types/Lookup"
import DataField from "../../../types/DataField"
import Trigger from "./Trigger"

type ScheduledWorkflowRule = {
    type: "SCHEDULE"
    reference: string
    name: string
    entityConfigReference: string | undefined | null
    archived: boolean
    inPreviewMode: boolean
    trigger: Trigger | undefined
    baseFilters: GenericFilter[]
    actions: GenericAction[]
    modificationHistory: {
        modifiedAt: number
        modifiedBy: string
    }[]
}

export default ScheduledWorkflowRule

export const convertWorkflowRuleToDto = (rule: ScheduledWorkflowRule, fields: DataField[]): PutScheduledWorkflowRuleDto => ({
    name: rule.name,
    entityConfigReference: rule.entityConfigReference,
    archived: rule.archived,
    inPreviewMode: rule.inPreviewMode,
    trigger: rule.trigger ?? null,
    baseFilters: convertFromArrayToDto(rule.baseFilters, true),
    actions: convertFromActionsArrayToDto(rule.actions, fields)
})

export const convertWorkflowRuleFromDto = (rule: GetScheduledWorkflowRuleDto, fields: DataField[], lookups: Lookup[]): ScheduledWorkflowRule => ({
    ...rule,
    type: "SCHEDULE",
    baseFilters: convertFromDtoToArray(rule.baseFilters, fields, lookups),
    actions: convertFromActionsDtoToArray(rule.actions, fields, lookups)
})
