import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authZ_Permissions } from '../../../constants';
import Dropdown from '../../common/dropdown';
import { getLookup } from '../../../api/lookupApi';
import FileTypeTableGrid from './fileTypeTableGrid';
import FieldMapGrid from './fieldMapGrid';
import AddFileTypeTable from './addFileTypeTable';
import AddFieldMap from './addFieldMap';
import * as importConfigurationActions from '../../../actions/importConfigurationActions';
import { startsWith, last } from 'lodash';
import { hasPermission } from '../../../utility/authZ';

class AddFileType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileType: {
        id: '',
        importSourceTypeId: -1,
        importSourceType: '',
        importLocationId: -1,
        importLocation: '',
        name: '',
        fileNameSpecification: '',
        fileExtension: '',
        regularExpressionMatch: '',
        resolutionOrder: '',
        schemaFileName: '',
        recordXPath: '',
        xmlElementName: '',
        destinationTableId: -1,
        isActive: '',
        csvDelimiter: '',
        csvQuoted: '',
        csvHasHeaders: ''
      },
      fileTypeTable: {
        id: '',
        importTableId: -1,
        insertOnly: '',
        updateOnly: '',
        resolutionOrder: '',
        treatEmptyStringAsNotSupplied: '',
        isMasterTable: '',
        masterTableKeyFieldName: '',
        showGrid: true
      },
      fieldMap: {
        id: '',
        fileTypeId: '',
        recordInstance: '',
        destinationFieldId: '',
        sourceFieldNameOverride: '',
        sourceXPath: '',
        sourceFormat: '',
        staticValue: '',
        defaultValue: '',
        useDefaultForInsertOnly: '',
        foreignKeyLookupId: '',
        allowCascadeInsert: '',
        allowEmptyString: '',
        prohibitUpdates: '',
        stripCharactersToCompare: '',
        isNativeKey: '',
        isBaseRecordId: '',
        isContent: '',
        isExtensionField: '',
        isMandatory: '',
        isActive: '',
        concatenationDelimiter: '',
        concatenationOrder: '',
        processMultipleLookupResults: '',
        showGrid: true
      }
    };

    this.onChange = this.onChange.bind(this);
    this.onChecked = this.onChecked.bind(this);
    this.onSave = this.onSave.bind(this);
    this.cancelFileTypeTable = this.cancelFileTypeTable.bind(this);
    this.cancelFieldMap = this.cancelFieldMap.bind(this);
    this.showFileTypeTableForm = this.showFileTypeTableForm.bind(this);
    this.showFieldMapForm = this.showFieldMapForm.bind(this);
    this.fileTypeTableSave = this.fileTypeTableSave.bind(this);
    this.fieldMapSave = this.fieldMapSave.bind(this);
    this.fileTypeTableDelete = this.fileTypeTableDelete.bind(this);
    this.fieldMapDelete = this.fieldMapDelete.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ fileType: Object.assign({}, nextProps.fileType) });
    this.setState({
      fileTypeTable: Object.assign({}, nextProps.fileTypeTable)
    });
    this.setState({ fieldMap: Object.assign({}, nextProps.fieldMap) });
  }

  componentDidMount() {
    this.props.getLookup(
      this.props.client,
      'import-configuration/destinations/lookup',
      'destinations'
    );
    this.props.getLookup(
      this.props.client,
      'import-configuration/source-type/lookup',
      'sourceTypes'
    );
    this.props.getLookup(this.props.client, 'import-configuration/location/lookup', 'locations');
    this.props.getLookup(
      this.props.client,
      'import-configuration/file-type/names',
      'fileTypeNames'
    );
    this.props.getLookup(
      this.props.client,
      'import-configuration/file-type/regex',
      'fileTypeRegex'
    );

    window.scrollTo(0, 0);
  }

  showFileTypeTableForm(fileTypeTableId) {
    if (fileTypeTableId) {
      this.props.actions.loadFileTypeTable(this.props.client, fileTypeTableId);
    } else {
      this.props.actions.newFileTypeTable();
    }
  }

  showFieldMapForm(fieldMapId) {
    if (fieldMapId) {
      this.props.actions.loadFieldMap(this.props.client, fieldMapId);
    } else {
      this.props.actions.newFieldMap();
    }
  }

  onChange(event) {
    const field = event.target.name;
    const fileType = Object.assign({}, this.state.fileType);

    fileType[field] = event.target.value;

    return this.setState({ fileType: fileType });
  }

  onSelectChange(name, value) {
    const fileType = Object.assign({}, this.state.fileType);

    fileType[name] = value;

    return this.setState({ fileType: fileType });
  }

  onSourceTypeSelectChange(value, name) {
    const fileType = Object.assign({}, this.state.fileType);

    fileType['importSourceTypeId'] = value;

    if (startsWith(name.toLowerCase(), 'xml')) {
      fileType.isXml = true;
      fileType.isCsv = false;
    }

    if (startsWith(name.toLowerCase(), 'csv')) {
      fileType.isXml = false;
      fileType.isCsv = true;
    }

    return this.setState({ fileType: fileType });
  }

  onChecked(event) {
    const field = event.target.name;
    const fileType = Object.assign({}, this.state.fileType);

    fileType[field] = event.target.checked;

    return this.setState({ fileType: fileType });
  }

  onSave() {
    this.props.onSave(this.state.fileType);
  }

  isFormValid() {
    const {
      importSourceTypeId,
      importLocationId,
      destinationTableId,
      isXml,
      schemaFileName,
      recordXPath,
      xmlElementName,
      regularExpressionMatch,
      name
    } = this.state.fileType;

    if (!importSourceTypeId && Number(importSourceTypeId) < 1) {
      return false;
    }

    if (!importLocationId && Number(importLocationId) < 1) {
      return false;
    }

    if (!destinationTableId && Number(destinationTableId) < 1) {
      return false;
    }

    if (isXml) {
      if (!schemaFileName || !recordXPath || !xmlElementName) {
        return false;
      }
    }

    if (!regularExpressionMatch) {
      return false;
    }

    if (!name) {
      return false;
    }

    const existingNames = this.props.fileTypeNames.results;

    if (this.props.fileType.name.length > 0) {
      if (
        name.toLowerCase() !== this.props.fileType.name.toLowerCase() &&
        existingNames.includes(name.toLowerCase())
      ) {
        return false;
      }
    } else if (existingNames.includes(name.toLowerCase())) {
      return false;
    }

    const existingRegex = this.props.fileTypeRegex.results;

    if (this.props.fileType.regularExpressionMatch.length > 0) {
      if (
        regularExpressionMatch.toLowerCase() !==
        this.props.fileType.regularExpressionMatch.toLowerCase() &&
        existingRegex.includes(regularExpressionMatch.toLowerCase())
      ) {
        return false;
      }
    } else if (existingRegex.includes(regularExpressionMatch.toLowerCase())) {
      return false;
    }

    return true;
  }

  getRequiredForXml() {
    if (this.state.fileType.isXml) {
      return (
        <div className="form-control-feedback-wrapper warning">
          <span className="form-control-feedback">
            <i className="fa fa-asterisk" />
          </span>
        </div>
      );
    }

    return '';
  }

  fileTypeTableSave(fileTypeTable) {
    fileTypeTable.importFileTypeId = this.state.fileType.id;

    if (fileTypeTable.id) {
      this.props.actions.updateFileTypeTable(this.props.client, fileTypeTable);
    } else {
      this.props.actions.createFileTypeTable(this.props.client, fileTypeTable);
    }
  }

  fileTypeTableDelete(fileTypeTable) {
    this.props.actions.deleteFileTypeTable(this.props.client, fileTypeTable);
  }

  cancelFileTypeTable() {
    this.props.actions.cancelFileTypeTable(this.state.fileTypeTable);
  }

  fieldMapSave(fieldMap) {
    fieldMap.fileTypeId = this.state.fileType.id;

    if (fieldMap.id) {
      this.props.actions.updateFieldMap(this.props.client, fieldMap);
    } else {
      this.props.actions.createFieldMap(this.props.client, fieldMap);
    }
  }

  fieldMapDelete(fieldMap) {
    this.props.actions.deleteFieldMap(this.props.client, fieldMap);
  }

  cancelFieldMap() {
    this.props.actions.cancelFieldMap(this.state.fieldMap);
  }

  renderFileTypeForm() {
    let isDisabled = false;
    const isValid = this.isFormValid();

    let buttons = (
      <div className="form-group">
        <button
          className="btnDefault btn btn-default"
          disabled={!isValid}
          onClick={this.onSave}
          tabIndex="16"
        >
          Save
        </button>
        <button
          className="btn btn-default"
          onClick={this.props.onCancel}
          tabIndex="17"
        >
          Cancel
        </button>
      </div>
    );

    if (
      hasPermission(
        this.props.permissions,
        authZ_Permissions.FileImportAdminRead
      ) &&
      !hasPermission(
        this.props.permissions,
        authZ_Permissions.FileImportAdminUpdate
      )
    ) {
      isDisabled = true;
      buttons = (
        <div className="form-group">
          <button
            className="btn btn-default"
            onClick={this.props.onCancel}
            tabIndex="17"
          >
            Back
          </button>
        </div>
      );
    }

    if (this.state.fileTypeTable.showGrid && this.state.fieldMap.showGrid) {
      const {
        importSourceTypeId,
        importLocationId,
        name,
        fileNameSpecification,
        fileExtension,
        regularExpressionMatch,
        resolutionOrder,
        schemaFileName,
        recordXPath,
        xmlElementName,
        destinationTableId,
        isActive,
        csvDelimiter,
        csvQuoted,
        csvHasHeaders
      } = this.state.fileType;

      const xmlRequired = this.getRequiredForXml();

      return (
        <div>
          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <div className="form-control-feedback-wrapper warning">
                <span className="form-control-feedback">
                  <i className="fa fa-asterisk" />
                </span>
              </div>
              <input
                type="text"
                value={name || ''}
                name="name"
                className="form-control"
                tabIndex="1"
                placeholder="Name (must be unique)"
                onChange={this.onChange}
                title="Name (must be unique)"
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-4">
              {xmlRequired}
              <input
                type="text"
                value={schemaFileName || ''}
                name="schemaFileName"
                className="form-control"
                placeholder="XSD"
                onChange={this.onChange}
                tabIndex="9"
                title="XSD"
                disabled={isDisabled}
              />
            </div>
          </div>
          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <Dropdown
                scroll={true}
                title="Destination Table"
                idProp="id"
                selectedItemId={destinationTableId}
                textProp="name"
                data={this.props.destinations.results}
                name="importFileTypeId"
                onClick={(id) => this.onSelectChange('destinationTableId', id)}
                tabIndex="2"
                required
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-4">
              {xmlRequired}
              <input
                type="text"
                value={recordXPath || ''}
                name="recordXPath"
                className="form-control"
                placeholder="XPath"
                onChange={this.onChange}
                tabIndex="10"
                title="XPath"
                disabled={isDisabled}
              />
            </div>
          </div>
          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <Dropdown
                scroll={true}
                title="Source Type"
                idProp="id"
                selectedItemId={importSourceTypeId}
                textProp="name"
                data={this.props.sourceTypes.results}
                name="importFileTypeId"
                onClick={(id, name) => this.onSourceTypeSelectChange(id, name)}
                tabIndex="3"
                required
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-4">
              {xmlRequired}
              <input
                type="text"
                value={xmlElementName || ''}
                name="xmlElementName"
                className="form-control"
                placeholder="XElement"
                onChange={this.onChange}
                tabIndex="11"
                title="XElement"
                disabled={isDisabled}
              />
            </div>
          </div>
          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <Dropdown
                scroll={true}
                title="Location"
                idProp="id"
                selectedItemId={importLocationId}
                textProp="name"
                data={this.props.locations.results}
                name="importFileTypeId"
                onClick={(id) => this.onSelectChange('importLocationId', id)}
                tabIndex="4"
                required
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-4">
              <input
                type="text"
                value={csvDelimiter || ''}
                name="csvDelimiter"
                className="form-control"
                placeholder="CSV Delimiter (max: 1 char)"
                title="CSV Delimiter (max: 1 char)"
                onChange={this.onChange}
                tabIndex="12"
                maxLength="1"
                disabled={isDisabled}
              />
            </div>
          </div>
          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <input
                type="text"
                value={fileNameSpecification || ''}
                name="fileNameSpecification"
                className="form-control"
                placeholder="File Name"
                onChange={this.onChange}
                tabIndex="5"
                title="File Name"
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-4">
              <label>
                <input
                  name="csvQuoted"
                  type="checkbox"
                  checked={csvQuoted}
                  onChange={this.onChecked}
                  tabIndex="13"
                  disabled={isDisabled}
                />{' '}
                CSV Quoted
              </label>
            </div>
          </div>
          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <div className="form-control-feedback-wrapper warning">
                <span className="form-control-feedback">
                  <i className="fa fa-asterisk" />
                </span>
              </div>
              <input
                type="text"
                value={regularExpressionMatch || ''}
                name="regularExpressionMatch"
                className="form-control"
                placeholder="File Name RegEx (must be unique)"
                onChange={this.onChange}
                tabIndex="6"
                title="File Name RegEx (must be unique)"
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-4">
              <label>
                <input
                  name="csvHasHeaders"
                  type="checkbox"
                  checked={csvHasHeaders}
                  onChange={this.onChecked}
                  tabIndex="14"
                  disabled={isDisabled}
                />{' '}
                CSV Headers
              </label>
            </div>
          </div>
          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <input
                type="text"
                value={fileExtension || ''}
                name="fileExtension"
                className="form-control"
                placeholder="File Name Extension"
                onChange={this.onChange}
                tabIndex="7"
                title="File Name Extension"
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-4">
              <label>
                <input
                  name="isActive"
                  type="checkbox"
                  checked={isActive}
                  onChange={this.onChecked}
                  tabIndex="15"
                  disabled={isDisabled}
                />{' '}
                Is Active
              </label>
            </div>
          </div>
          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <input
                type="number"
                value={resolutionOrder}
                name="resolutionOrder"
                className="form-control"
                placeholder="Order"
                onChange={this.onChange}
                style={{ width: '480px' }}
                tabIndex="8"
                title="Order"
                disabled={isDisabled}
              />
            </div>
          </div>
          {buttons}
        </div>
      );
    }
  }

  renderFileTypeTableGrid() {
    if (
      this.state.fileTypeTable.showGrid &&
      this.state.fileType.id &&
      this.state.fieldMap.showGrid
    ) {
      const { id } = this.state.fileType;

      return (
        <div>
          <FileTypeTableGrid
            client={this.props.client}
            fileTypeId={id}
            onAdd={this.showFileTypeTableForm}
            onDelete={this.fileTypeTableDelete}
          />
        </div>
      );
    }
  }

  renderFileTypeTableForm() {
    if (this.state.fileTypeTable.showForm) {
      return (
        <div>
          <AddFileTypeTable
            client={this.props.client}
            onCancel={this.cancelFileTypeTable}
            onSave={this.fileTypeTableSave}
          />
        </div>
      );
    }
  }

  renderFieldMapGrid() {
    if (
      this.state.fieldMap.showGrid &&
      this.state.fileType.id &&
      this.state.fileTypeTable.showGrid
    ) {
      const { id } = this.state.fileType;

      return (
        <div>
          <FieldMapGrid
            client={this.props.client}
            fileTypeId={id}
            onAdd={this.showFieldMapForm}
            onDelete={this.fieldMapDelete}
          />
        </div>
      );
    }
  }

  renderFieldMapForm() {
    if (this.state.fieldMap.showForm) {
      const { name, isXml } = this.state.fileType;

      return (
        <div>
          <AddFieldMap
            client={this.props.client}
            fileTypeName={name}
            isXml={isXml}
            onCancel={this.cancelFieldMap}
            onSave={this.fieldMapSave}
          />
        </div>
      );
    }
  }

  render() {
    const { id } = this.state.fileType;
    const header = id ? `Edit File Type (Id:${id})` : 'Add File Type';

    return (
      <div>
        <div key="file-type-form" className="card below-grid-form">
          <h3>{header}</h3>
          {this.renderFileTypeForm()}
          {this.renderFileTypeTableForm()}
          {this.renderFieldMapForm()}
        </div>
        {this.renderFileTypeTableGrid()}
        {this.renderFieldMapGrid()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let fileType = {
    id: '',
    importSourceTypeId: -1,
    importSourceType: '',
    importLocationId: -1,
    importLocation: '',
    name: '',
    fileNameSpecification: '',
    fileExtension: '',
    regularExpressionMatch: '',
    resolutionOrder: '',
    schemaFileName: '',
    recordXPath: '',
    xmlElementName: '',
    destinationTableId: -1,
    isActive: '',
    csvDelimiter: '',
    csvQuoted: '',
    csvHasHeaders: ''
  };

  if (state.fileTypes.length > 0) {
    fileType = last(state.fileTypes);
  }

  let fileTypeTable = {
    id: '',
    importTableId: '',
    insertOnly: '',
    updateOnly: '',
    resolutionOrder: '',
    treatEmptyStringAsNotSupplied: '',
    isMasterTable: '',
    masterTableKeyFieldName: '',
    showGrid: true
  };

  if (state.fileTypeTables.length > 0) {
    fileTypeTable = last(state.fileTypeTables);
  }

  let fieldMap = {
    id: '',
    fileTypeId: '',
    recordInstance: '',
    destinationFieldId: '',
    sourceFieldNameOverride: '',
    sourceXPath: '',
    sourceFormat: '',
    staticValue: '',
    defaultValue: '',
    useDefaultForInsertOnly: '',
    foreignKeyLookupId: '',
    allowCascadeInsert: '',
    allowEmptyString: '',
    prohibitUpdates: '',
    stripCharactersToCompare: '',
    isNativeKey: '',
    isBaseRecordId: '',
    isContent: '',
    isExtensionField: '',
    isMandatory: '',
    isActive: '',
    concatenationDelimiter: '',
    concatenationOrder: '',
    processMultipleLookupResults: '',
    showGrid: true
  };

  if (state.fieldMaps.length > 0) {
    fieldMap = last(state.fieldMaps);
  }

  return {
    fileType: fileType,
    fileTypeTable: fileTypeTable,
    fieldMap: fieldMap,
    destinations: state.lookupReducer.destinations,
    sourceTypes: state.lookupReducer.sourceTypes,
    locations: state.lookupReducer.locations,
    fileTypeNames: state.lookupReducer.fileTypeNames,
    fileTypeRegex: state.lookupReducer.fileTypeRegex,
    permissions: state.authReducer.permissions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(importConfigurationActions, dispatch),
    getLookup: (client, serviceCall, storePropertyName) => {
      dispatch(getLookup(client, serviceCall, storePropertyName));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFileType);
