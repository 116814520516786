import { convertToCurrency } from "../../../../library/helpers/numberUtils"
import { AccountDto } from "../../types/dtos/AccountDto"
import classes from "./CurrentBalance.module.scss"

type CurrentBalanceProps = {
    className: string
    account: AccountDto
}

const CurrentBalance = ({ className, account }: CurrentBalanceProps) => {
    const isCurrentDebit = account.balance.current?.creditDebitIndicator === "Debit"
    const isAvailableDebit = account.balance.available?.creditDebitIndicator === "Debit"

    return (
        <div className={`d-flex flex-column rounded ${className} ${classes.background} p-3`}>
            <span className="fs-6 text-white">Current Balance</span>
            <span className="fs-2 text-white mb-1">
                {isCurrentDebit ? "-" : ""}
                {convertToCurrency(account.balance.current?.amount ?? 0, account.metadata.currencyCode)}
            </span>

            <span className="fs-6 text-white">Available Balance</span>
            <span className="fs-2 text-green mb-1">
                {isAvailableDebit ? "-" : ""}
                {convertToCurrency(account.balance.available?.amount ?? 0, account.metadata.currencyCode)}
            </span>
        </div>
    )
}

export default CurrentBalance
