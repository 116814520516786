export const addFileContentToRequest = (fieldName, fileName, fileType, binaryContent) => {
  return '--blob\r\n'
    + 'content-disposition: form-data; '
    + 'name="' + fieldName + '"; '
    + 'filename="' + fileName + '"\r\n'
    + 'Content-Type: ' + fileType + '\r\n'
    + '\r\n'
    + binaryContent + '\r\n';
};

export const addValueToRequest = (name, value) => {
  return '--blob\r\n'
    + 'content-disposition: form-data; name="' + name + '"\r\n'
    + '\r\n'
    + value + '\r\n';
};
