import React, { useState, useEffect, useRef } from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { executeAuthAsyncPost } from "../../utility/asyncSupport"
import { showToastErrorMessage } from "../../api/toasterApi.js"
import { isObject } from "lodash"
import { EmailEditor } from "../admin/email/emailEditor"
import { Loading, useClient, useInvevoSession } from "invevo-react-components"
import classes from "./Email.module.scss"
import { useCustomer } from "../../../../contexts/CustomerContext"
import { useSelectedEntities } from "../../../../contexts/SelectedEntitiesContext"
import { useFeatureToggle } from "../../../../hooks/useFeatureToggle"

const EmailPreview = ({ executeAuthAsyncPost, onEmailSentSuccess, contentTypes, emailTemplate, selectedAccount }) => {
    const [invevoSession, setInvevoSession] = useInvevoSession()
    const client = useClient()
    const [attachmentSummary, setAttachmentSummary] = useState([])
    const [customer] = useCustomer()
    const { selectedRefs } = useSelectedEntities()
    const [entityTransactionRefs, setEntityTransactionRefs] = useState([])
    const { isEnabled: isSqlFirst } = useFeatureToggle("sqlFirst")
    const { isEnabled: useEntityDashboardsWhenSqlFirst } = useFeatureToggle("useEntityDashboardsWhenSqlFirst")

    const hasTransAttached =
        contentTypes.showTransAttachments &&
        ((customer.selectedTransactions && customer.selectedTransactions.length > 0) || (entityTransactionRefs && entityTransactionRefs?.length > 0))

    const editorRef = useRef()

    const [emailState, setEmailState] = useState({
        subject: "",
        finalHtml: ""
    })

    const [sending, setSending] = useState(false)

    const [showEditor, setShowEditor] = useState(false)

    const [contacts, setContacts] = useState([])

    useEffect(() => {
        if (selectedRefs.transaction && selectedRefs.transaction.length > 0) {
            setEntityTransactionRefs(selectedRefs.transaction)
        }
    }, [selectedRefs.transaction, invevoSession.selectedCustomerTab])

    useEffect(() => {
        let docsToAttach = []
        if (invevoSession.selectedDocuments) {
            docsToAttach = invevoSession.selectedDocuments.map(d => (
                <div key={d.id}>
                    <i className="fa-solid fa-file"></i>
                    {` ${d.fileName}`}
                </div>
            ))
        }

        if (hasTransAttached) {
            docsToAttach.push(
                <div key={"attached-transactions"}>
                    <i className="fa-solid fa-file"></i> Transactions.csv
                </div>
            )
        }

        setAttachmentSummary(docsToAttach)
    }, [invevoSession.selectedDocuments, hasTransAttached])

    useEffect(() => {
        setContacts((invevoSession.selectedCustomerContacts || []).concat(invevoSession.selectedInternalContacts || []).filter(c => c.email))
    }, [invevoSession.selectedCustomerContacts, invevoSession.selectedInternalContacts])

    const transactionIds =
        isSqlFirst && useEntityDashboardsWhenSqlFirst ? entityTransactionRefs ?? [] : customer.selectedTransactions.map(t => t.transactionRef) || []

    useEffect(() => {
        if (emailTemplate.clientTemplateId) {
            const message = editorRef.current && contentTypes.showMessage ? editorRef.current.getHtml() : ""
            const htmlParams = {
                clientTemplateId: emailTemplate.clientTemplateId,
                customerId: emailTemplate.customerId,
                isVirtualAccount: selectedAccount.miaAccountIsVirtualAccount,
                embeddedTransactionIds: contentTypes.showTrans ? transactionIds : [],
                attachedTransactionIds: contentTypes.showTransAttachments ? transactionIds : [],
                personalisedContent: message,
                contactIds: contacts.map(c => c.contactId)
            }

            executeAuthAsyncPost(client, "EMail/CreateTemplateEmailNew", "CREATE_TEMPLATE_EMAIL", htmlParams, result =>
                setEmailState(prevState => ({
                    subject: prevState.subject ? prevState.subject : result.Subject,
                    finalHtml: result.Body
                }))
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contacts, emailTemplate, selectedAccount, contentTypes, executeAuthAsyncPost, client, customer.selectedTransactions, selectedRefs])

    const toggleMessage = () => setShowEditor(!showEditor)

    const createMarkup = () => {
        return {
            __html: emailState.finalHtml
        }
    }

    const onSubjectChange = event => {
        const subject = event.target.value
        setEmailState(prevState => ({
            ...prevState,
            subject
        }))
    }

    const sendEmail = () => {
        const emails = contacts.map(c => c.email).join(";")
        const subject = emailState.subject
        const attachments = (invevoSession.selectedDocuments || []).map(o => o.id)
        const attachedtransactionrowids = contentTypes.showTransAttachments ? transactionIds : []
        const embeddedtransactionrowids = contentTypes.showTrans ? transactionIds : []
        const clienttemplateid = emailTemplate.clientTemplateId
        const customerid = emailTemplate.customerId
        const isVirtualAccount = selectedAccount.miaAccountIsVirtualAccount
        const contactids = contacts.map(c => c.contactId)
        const personalContent = contentTypes.showMessage ? editorRef.current.getHtml() : ""

        setSending(true)
        executeAuthAsyncPost(
            client,
            "email/NewSendTemplateEmails",
            "SEND_EMAIL",
            {
                subject,
                emails,
                attachments,
                attachedtransactionrowids,
                embeddedtransactionrowids,
                clienttemplateid,
                customerid,
                isVirtualAccount,
                contactids,
                personalContent
            },
            result => {
                if (!isObject(result) && result !== "") {
                    showToastErrorMessage(result)
                    setSending(false)
                } else {
                    onEmailSentSuccess()
                }
            },
            () => {
                showToastErrorMessage("Failed to send emails")
                setSending(false)
            }
        )
    }

    const messageIcon = showEditor ? "chevron-up" : "chevron-down"

    const navToContacts = () => {
        setInvevoSession({ selectedCustomerTab: "contacts" })
    }

    const navToDocuments = () => {
        setInvevoSession({ selectedCustomerTab: "files", showTaskFiles: false })
    }

    const navToTransactions = () => {
        setInvevoSession({ selectedCustomerTab: "transactions" })
    }

    const selectedTransactionCount = isSqlFirst && useEntityDashboardsWhenSqlFirst ? entityTransactionRefs?.length : customer.selectedTransactions.length ?? 0

    return (
        <Loading isLoading={sending} colour="blue">
            <div className="row">
                <div className="col-12">
                    <h2 className="text-white">Email</h2>
                </div>
                <div className="col-12 d-flex">
                    <button className={`btn ${classes["btn-custom"]}`} type="button" onClick={navToContacts} aria-label="Add Recipients">
                        Add Recipients {contacts.length > 0 && `(${contacts.length})`}
                    </button>

                    {(contentTypes.showTransAttachments || contentTypes.showTrans) && (
                        <button className={`btn ${classes["btn-custom"]}`} type="button" onClick={navToTransactions} aria-label="Add Transactions">
                            Add Transactions {selectedTransactionCount > 0 && `(${selectedTransactionCount})`}
                        </button>
                    )}

                    <button className={`btn ${classes["btn-custom"]}`} type="button" onClick={navToDocuments} aria-label="Attach Documents">
                        Attach Documents{" "}
                        {invevoSession.selectedDocuments && invevoSession.selectedDocuments.length > 0 && `(${invevoSession.selectedDocuments.length})`}
                    </button>
                    {contentTypes.showMessage && (
                        <button className={`btn ${classes["btn-custom"]}`} type="button" onClick={toggleMessage}>
                            Add Message <i className={`fa-solid fa-${messageIcon}`}></i>
                        </button>
                    )}
                    <div className="ms-auto">
                        <button className="btn btn-primary" type="button" disabled={contacts.length === 0} onClick={sendEmail}>
                            Send
                        </button>
                    </div>
                </div>

                <div className="col-12 top15" style={{ display: showEditor ? "block" : "none" }}>
                    <EmailEditor id="reply-contents" style={{ width: "100%", height: "600px" }} ref={editorRef}></EmailEditor>
                </div>

                <div className="col-12">
                    <hr />
                </div>

                <div className="col-12">
                    <h3 className="top5 text-white">Subject</h3>
                    <input value={emailState.subject} onChange={onSubjectChange} type="text" />
                    <h3 className="text-white">To</h3>
                    <div aria-label="contacts">{contacts.map(r => r.email).join(", ")}</div>
                    {attachmentSummary.length > 0 && (
                        <div className="box">
                            <h3 className="text-white">Attachments</h3>
                            {attachmentSummary}
                        </div>
                    )}
                    <div className="preview" dangerouslySetInnerHTML={createMarkup()}></div>
                </div>
            </div>
        </Loading>
    )
}

EmailPreview.defaultProps = {
    contacts: []
}

const mapStateToProps = state => {
    return {
        transactionIds: state.functionReducer.transactionIds || [],
        selectedAccount: state.currentSelectionReducer.selectedAccount
    }
}

export default injectIntl(
    connect(mapStateToProps, {
        executeAuthAsyncPost
    })(EmailPreview)
)
