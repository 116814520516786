import classes from "./ColumnConfigButton.module.scss"
import { GreyButton, IconButton, Loading, Toggle, useApi, useClient, useConfig, useUser } from "invevo-react-components"
import { useState, useEffect, useRef } from "react"
import * as bootstrap from 'bootstrap'
import { actionTypes } from '../../reducers/actionTypes'

const ColumnConfigButton = ({ className, state, dispatch, buttonClasses }) => {
    const { transactionFields, columnConfig, clientUserDashboardConfigs } = state
    const [showColumnConfig, setShowColumnConfig] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const ref = useRef()
    const currentUser = useUser()
    const api = useApi()
    const client = useClient()
    const config = useConfig()

    useEffect(() => {
        new bootstrap.Tooltip(ref.current)
    }, [])

    const onColumnConfigClicked = () =>
        setShowColumnConfig(!showColumnConfig)

    const getTogglesState = (fieldName) =>
        columnConfig.displayedColumns.includes(fieldName)

    const onToggleState = (fieldName) => {
        if (columnConfig.displayedColumns.length === 1 && columnConfig.displayedColumns.includes(fieldName))
            return
        dispatch({ type: actionTypes.TOGGLE_DISPLAYED_COLUMN_IN_TRANSACTION_GRID, column: fieldName, columnConfig })
    }

    const saveConfig = clientUserConfig => {
        if (isSaving)
            return
        setIsSaving(true)
        api.put(`${config.DATA_API_URL}/api/${client}/client-user-dashboards-config/${currentUser.username}`, clientUserConfig)
            .then(() => {
                setIsSaving(false)
                setShowColumnConfig(false)
            }).catch(error => console.error(error))
    }

    const onReset = () => {
        dispatch({ type: actionTypes.RESET_DISPLAYED_COLUMN_IN_TRANSACTION_GRID, transactionsGridRef: columnConfig.transactionsGridRef })
        saveConfig({ transactionsGridConfigs: clientUserDashboardConfigs.transactionsGridConfigs.filter(config => config.transactionsGridRef !== columnConfig.transactionsGridRef) })
    }

    const onSave = () => saveConfig(clientUserDashboardConfigs)

    return (
        <Loading isLoading={!config.DATA_API_URL} colour="blue">
            <div ref={ref} className={`d-flex position-relative flex-column ${className ? className : ''}`}>
                <IconButton
                    ariaLabel="column-config-button"
                    buttonClasses={buttonClasses}
                    iconClasses="fa-light fa-line-columns"
                    colour="blue"
                    className={`${showColumnConfig ? classes.button : ''}`}
                    onClick={onColumnConfigClicked}
                />
                {
                    showColumnConfig &&
                    <div className="d-flex" style={{ "zIndex": 1 }}>
                        <div className={`position-absolute ${classes.columns}`}>
                            <div className={`${classes.header}`}>
                            </div>
                            <div className={`w-100 d-flex flex-column ${classes.body}`}>
                                {
                                    transactionFields.filter(tf => tf.fieldName !== "client")
                                        .map(transactionField => {
                                            return <div key={transactionField.fieldName} className="d-flex align-items-center p-2">
                                                <Toggle
                                                    toggleColour="blue"
                                                    status={getTogglesState(transactionField.fieldName.toLowerCase())}
                                                    onStatusChanged={() => onToggleState(transactionField.fieldName.toLowerCase())}
                                                    ariaLabel={`${transactionField.fieldName}-toggle`}
                                                />
                                                <span className="ms-2 text-truncate fw-light no-select"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="bottom"
                                                    aria-label={`column-config-${transactionField.fieldName}-value`}
                                                    role="listitem"
                                                    title={transactionField.displayName}>{transactionField.displayName}</span>
                                                <i className="fa-light fa-grip-lines ms-auto"></i>
                                            </div>
                                        }).reduce((previous, current) => [previous, <span key={`${previous}${current}`} className="w-100 border-top"> </span>, current])
                                }
                            </div>
                            <div className={`d-flex align-items-center p-3 fw-light ${classes.footer}`}>

                                <Loading isLoading={isSaving} colour="white">
                                    <GreyButton label="Save" iconClasses="fa-light fa-save" onClick={onSave} ariaLabel="save-config-button" />
                                    <GreyButton className="ms-3" label="Reset" iconClasses="fa-light fa-redo" onClick={onReset} ariaLabel="reset-config-button" />
                                </Loading>

                            </div>
                        </div>
                    </div>
                }
            </div>
        </Loading>
    )
}

export default ColumnConfigButton