import { Tabs, Tab, Loading, useApi, useConfig, useClient } from 'invevo-react-components';
import SmsTemplatesDashboard from './smsTemplates/SmsTemplatesDashboard';
import SmsConfiguration from './smsConfiguration/SmsConfiguration';
import SmsSenderIds from './smsSenderIds/SmsSenderIds';
import { useEffect, useState } from 'react';

const SmsConfig = () => {
    const api = useApi()
    const config = useConfig()
    const client = useClient()

    const [isFetching, setIsFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [customerGroups, setCustomerGroups] = useState([])
    const [activeSmsSenderIds, setActiveSmsSenderIds] = useState([])
    const [archivedSmsSenderIds, setArchivedSmsSenderIds] = useState([])

    useEffect(() => {
        if (isFetching || hasFetched || !config.CONNECT_API_URL) {
            return
        }
        setIsFetching(true)
        Promise.all([
            api.get(`/api/${client}/customer-groups`),
            api.get(`${config.CONNECT_API_URL}/api/${client}/sms-sender-id`),
        ]).then(responses => {
            setCustomerGroups(responses[0].data)
            setActiveSmsSenderIds(mapSmsSenderIds(responses[1].data).filter(s => s.isActive))
            setArchivedSmsSenderIds(mapSmsSenderIds(responses[1].data).filter(s => !s.isActive))

            setHasFetched(true)
            setIsFetching(false)
        })
    }, [api, client, config.CONNECT_API_URL, hasFetched, isFetching])

    return (
        <div className="d-flex w-100 h-100 px-4 pt-4">
            <Loading isLoading={isFetching} colour="blue">
                <Tabs className="flex-grow-1" tabContentEnabled={false}>
                    <Tab title="SMS Sender IDs" isActive={true}>
                        <SmsSenderIds
                            activeSmsSenderIds={activeSmsSenderIds}
                            setActiveSmsSenderIds={setActiveSmsSenderIds}
                            archivedSmsSenderIds={archivedSmsSenderIds}
                            setArchivedSmsSenderIds={setArchivedSmsSenderIds}
                            customerGroups={customerGroups} />
                    </Tab>
                    <Tab title="SMS Templates">
                        <SmsTemplatesDashboard
                            customerGroups={customerGroups}
                            senderIds={activeSmsSenderIds} />
                    </Tab>
                    <Tab title="SMS Configuration">
                        <SmsConfiguration />
                    </Tab>
                </Tabs>
            </Loading>
        </div>
    )
}

export default SmsConfig

const mapSmsSenderIds = (data) => {
    return data.map(senderId => {
        return {
            reference: senderId.reference,
            senderId: senderId.senderId,
            isActive: senderId.isActive,
            customerGroups: senderId.customerGroups,
            isNew: false
        }
    })
}