import classes from "./QuickFilters.module.scss"
import Lookup from "../../../../types/Lookup"
import DataField from "../../../../types/DataField"
import QuickFilterGroup from "./QuickFilterGroup"
import QuickFilter from "../../types/QuickFilter"
import FiltersDto from "../../../../library/FilterList/FiltersDto"

type QuickFiltersProps = {
    fields: DataField[]
    lookups: Lookup[]
    filtersDto: FiltersDto
    disabled: boolean
    selectedQuickFilters: QuickFilter[]
    onSelectedQuickFiltersUpdated: (updatedFilters: QuickFilter[]) => void
    onShowQuickFiltersToggled: () => void
}

const QuickFilters = ({
    fields,
    lookups,
    filtersDto,
    disabled,
    selectedQuickFilters,
    onSelectedQuickFiltersUpdated,
    onShowQuickFiltersToggled
}: QuickFiltersProps) => {
    const quickFilterDataFields = fields.filter(field => field.isQuickFilter)

    return (
        <div className={`bg-blue h-100 ${classes.slide} d-flex flex-column overflow-auto rounded`}>
            <div className="d-flex align-items-center pt-3 px-3 text-white">
                <span className="fs-3  fw-lighter">Quick filters</span>
                <div
                    className={`d-flex rounded ms-auto pointer ${classes.closeButton}`}
                    role="button"
                    aria-label="close-quick-filters"
                    onClick={onShowQuickFiltersToggled}
                >
                    <i className="fa-regular fa-x m-auto"></i>
                </div>
            </div>
            <div className={`d-flex flex-column w-100 mt-3 overflow-auto ${classes.container}`}>
                {quickFilterDataFields.length === 0 && <div className="align-self-center text-white">No quick filters available</div>}
                {quickFilterDataFields.map(dataField => (
                    <QuickFilterGroup
                        key={dataField.value}
                        lookups={lookups}
                        dataField={dataField}
                        filtersDto={filtersDto}
                        disabled={disabled}
                        selectedQuickFilters={selectedQuickFilters}
                        onSelectedQuickFiltersUpdated={onSelectedQuickFiltersUpdated}
                    />
                ))}
            </div>
        </div>
    )
}

export default QuickFilters
