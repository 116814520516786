import React, { useMemo } from "react"
import { actionTypes } from "../../reducers/actionTypes"
import { widgetTypes } from "../../enums/widgetTypes"
import InformationWidget from "./widgets/InformationWidget"
import TransactionsGridWidget from "./widgets/TransactionsGridWidget"
import SmallPieChartWidget from "./widgets/SmallPieChartWidget"
import SmallBarChartWidget from "./widgets/SmallBarChartWidget"
import ValueWidget from "./widgets/ValueWidget"
import AreaChartWidget from "./widgets/AreaChartWidget"
import LineChartWidget from "./widgets/LineChartWidget"
import GridWidget from "./widgets/GridWidget"
import {
    DashboardConfigAction,
    DashboardConfigCustomerEntityConfig,
    DashboardConfigEntityConfig,
    DashboardConfigTransactionFields,
    DndWidget,
    WidgetState
} from "../../reducers/dashboardConfigsReducer"
import DashboardConfigState from "../../types/DashboardConfigState"
import Lookup from "../../../../types/Lookup"
import EntityChartWidget from "./widgets/entityWidgets/EntityChartWidget"
import DndGrid from "../../../../library/dndGrid/DnDGrid"
import { v4 as uuidv4 } from "uuid"
import TrendingWidget from "./widgets/TrendingWidget"

type DashboardEditorProps = {
    className?: string
    state: {
        editingConfig: DashboardConfigState
        customerFields: DashboardConfigCustomerEntityConfig[]
        transactionFields: DashboardConfigTransactionFields[]
        showValidationErrors: boolean
        selectedContextEntityConfig?: DashboardConfigEntityConfig
        entityConfigs: DashboardConfigEntityConfig[]
        isNewEntityStructureEnabled: boolean
        isAggregatedDashboard: boolean
        lookups: Lookup[]
    }
    dispatch: React.Dispatch<DashboardConfigAction>
}
export type SortedWidget = {
    id: string
} & WidgetState

const DashboardEditor = ({ className, state, dispatch }: DashboardEditorProps) => {
    const {
        editingConfig,
        transactionFields,
        customerFields,
        showValidationErrors,
        selectedContextEntityConfig,
        entityConfigs,
        isNewEntityStructureEnabled,
        isAggregatedDashboard,
        lookups
    } = state

    const sortedWidgets: SortedWidget[] = useMemo(
        () =>
            sortWidgets([
                ...editingConfig.infoWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...editingConfig.valueWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...editingConfig.smallPieChartWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...editingConfig.smallBarChartWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...editingConfig.areaChartWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...editingConfig.lineChartWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...editingConfig.transactionsWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...editingConfig.gridWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...editingConfig.entityChartWidgets.map(w => ({ ...w, id: uuidv4() })),
                ...(editingConfig.trendingWidgets?.map(w => ({ ...w, id: uuidv4() })) ?? [])
            ]),
        [editingConfig]
    )

    const onWidgetsLayoutChange = (newLayout: DndWidget[]) => {
        dispatch({ type: actionTypes.WIDGET_LAYOUT_CHANGED, newLayout })
    }

    const availableFields = !isNewEntityStructureEnabled ? customerFields : selectedContextEntityConfig?.fields || []

    return (
        <div className={`${className ? className : ""} d-flex h-100 py-3 flex-grow-1`}>
            <div className="w-100 p-2 overflow-auto">
                {isNewEntityStructureEnabled && !selectedContextEntityConfig ? (
                    <div className="text-center">Please select an entity to configure</div>
                ) : (
                    <DndGrid onLayoutChange={onWidgetsLayoutChange}>
                        {sortedWidgets.map(widget => {
                            switch (widget.widgetType) {
                                case widgetTypes.INFORMATION:
                                    return (
                                        <div
                                            key={widget.id}
                                            id={`${widget.ordinal}`}
                                            x={widget.cellX}
                                            y={widget.cellY}
                                            w={widget.cellWidth}
                                            h={widget.cellHeight}
                                            minW={4}
                                            minH={2}
                                        >
                                            <div className="w-100 h-100 p-2 overflow-auto">
                                                <InformationWidget
                                                    className="h-100"
                                                    state={{ customerFields: availableFields, widget, showValidationErrors, isNewEntityStructureEnabled }}
                                                    dispatch={dispatch}
                                                />
                                            </div>
                                        </div>
                                    )
                                case widgetTypes.VALUE:
                                    return (
                                        <div
                                            key={widget.id}
                                            id={`${widget.ordinal}`}
                                            x={widget.gridCellX}
                                            y={widget.gridCellY}
                                            w={widget.gridCellWidth}
                                            h={widget.gridCellHeight}
                                            minW={2}
                                            minH={2}
                                        >
                                            <div className="w-100 h-100 p-2 overflow-auto">
                                                <ValueWidget
                                                    className="h-100"
                                                    state={{ customerFields: availableFields, widget, showValidationErrors, isNewEntityStructureEnabled }}
                                                    dispatch={dispatch}
                                                />
                                            </div>
                                        </div>
                                    )
                                case widgetTypes.SMALL_BAR_CHART:
                                    return (
                                        <div
                                            key={widget.id}
                                            id={`${widget.ordinal}`}
                                            x={widget.gridCellX}
                                            y={widget.gridCellY}
                                            w={widget.gridCellWidth}
                                            h={widget.gridCellHeight}
                                            minW={4}
                                            minH={4}
                                        >
                                            <div className="w-100 h-100 p-2 overflow-auto">
                                                <SmallBarChartWidget className="h-100" state={{ widget, showValidationErrors }} dispatch={dispatch} />
                                            </div>
                                        </div>
                                    )
                                case widgetTypes.AREA_CHART:
                                    return (
                                        <div
                                            key={widget.id}
                                            id={`${widget.ordinal}`}
                                            x={widget.gridCellX}
                                            y={widget.gridCellY}
                                            w={widget.gridCellWidth}
                                            h={widget.gridCellHeight}
                                            minW={4}
                                            minH={4}
                                        >
                                            <div className="w-100 h-100 p-2 overflow-auto">
                                                <AreaChartWidget className="h-100" state={{ widget, showValidationErrors }} dispatch={dispatch} />
                                            </div>
                                        </div>
                                    )
                                case widgetTypes.LINE_CHART:
                                    return (
                                        <div
                                            key={widget.id}
                                            id={`${widget.ordinal}`}
                                            x={widget.gridCellX}
                                            y={widget.gridCellY}
                                            w={widget.gridCellWidth}
                                            h={widget.gridCellHeight}
                                            minW={4}
                                            minH={4}
                                        >
                                            <div className="w-100 h-100 p-2 overflow-auto">
                                                <LineChartWidget className="h-100" state={{ widget, showValidationErrors }} dispatch={dispatch} />
                                            </div>
                                        </div>
                                    )
                                case widgetTypes.SMALL_PIE_CHART:
                                    return (
                                        <div
                                            key={widget.id}
                                            id={`${widget.ordinal}`}
                                            x={widget.gridCellX}
                                            y={widget.gridCellY}
                                            w={widget.gridCellWidth}
                                            h={widget.gridCellHeight}
                                            minW={4}
                                            minH={4}
                                        >
                                            <div className="w-100 h-100 p-2 overflow-auto">
                                                <SmallPieChartWidget className="h-100" state={{ widget, showValidationErrors }} dispatch={dispatch} />
                                            </div>
                                        </div>
                                    )
                                case widgetTypes.TRANSACTIONS_GRID:
                                    return (
                                        <div
                                            key={widget.id}
                                            id={`${widget.ordinal}`}
                                            x={widget.gridCellX}
                                            y={widget.gridCellY}
                                            w={widget.gridCellWidth}
                                            h={widget.gridCellHeight}
                                            minW={12}
                                            minH={3}
                                        >
                                            <div className="w-100 h-100 p-2 overflow-auto">
                                                <TransactionsGridWidget className="h-100" state={{ transactionFields, widget }} dispatch={dispatch} />
                                            </div>
                                        </div>
                                    )
                                case widgetTypes.GRID:
                                    return (
                                        <div
                                            key={widget.id}
                                            id={`${widget.ordinal}`}
                                            x={widget.gridCellX}
                                            y={widget.gridCellY}
                                            w={widget.gridCellWidth}
                                            h={widget.gridCellHeight}
                                            minW={12}
                                            minH={3}
                                        >
                                            <div className="w-100 h-100 p-2 overflow-auto">
                                                <GridWidget
                                                    className="h-100"
                                                    widget={widget}
                                                    dispatch={dispatch}
                                                    entityConfigs={entityConfigs}
                                                    editingConfigEntityReference={editingConfig.entityConfigReference ?? ""}
                                                    customerFields={customerFields}
                                                    isAggregatedDashboard={isAggregatedDashboard}
                                                    isNewEntityStructureEnabled={isNewEntityStructureEnabled}
                                                    lookups={lookups}
                                                />
                                            </div>
                                        </div>
                                    )
                                case widgetTypes.ENTITY_CHART_WIDGET:
                                    return (
                                        <div
                                            key={widget.id}
                                            id={`${widget.ordinal}`}
                                            x={widget.gridCellX}
                                            y={widget.gridCellY}
                                            w={widget.gridCellWidth}
                                            h={widget.gridCellHeight}
                                            minW={4}
                                            minH={4}
                                        >
                                            <div className="w-100 h-100 p-2 overflow-auto">
                                                <EntityChartWidget
                                                    className="h-100"
                                                    state={{
                                                        widget,
                                                        showValidationErrors,
                                                        entityConfigs,
                                                        lookups,
                                                        editingConfigEntityReference: editingConfig.entityConfigReference ?? ""
                                                    }}
                                                    dispatch={dispatch}
                                                />
                                            </div>
                                        </div>
                                    )
                                case widgetTypes.ENTITY_TRENDING:
                                    return (
                                        <div
                                            key={widget.id}
                                            id={`${widget.ordinal}`}
                                            x={widget.gridCellX}
                                            y={widget.gridCellY}
                                            w={widget.gridCellWidth}
                                            h={widget.gridCellHeight}
                                            minW={4}
                                            minH={4}
                                        >
                                            <div className="w-100 h-100 p-2 overflow-auto">
                                                <TrendingWidget
                                                    className="h-100"
                                                    state={{
                                                        widget,
                                                        entityConfig: selectedContextEntityConfig,
                                                        entityConfigs: entityConfigs,
                                                        lookups
                                                    }}
                                                    dispatch={dispatch}
                                                />
                                            </div>
                                        </div>
                                    )
                                default:
                                    return <></>
                            }
                        })}
                    </DndGrid>
                )}
            </div>
        </div>
    )
}

const sortWidgets = (widgets: SortedWidget[]) => [...widgets].sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1))

export default DashboardEditor
