import { useEffect, useState } from "react"
import PaymentProviderWithNoTransactions from "./PaymentProviderWithNoTransactions"
import PreviewPaymentNoTransactions from "./PreviewPaymentNoTransactions"
import SelectPaymentCard from "./SelectPaymentCard"

type PayOverdueAccountBalancesProps = {
    customerId: string,
    cardProviders: any[],
    overdue: number,
    balance: number,
    currencyCode: string
}

const PayOverdueAccountBalances = ({ customerId, cardProviders, overdue, balance, currencyCode }: PayOverdueAccountBalancesProps) => {
    const [selectedPaymentCard, setSelectedPaymentCard] = useState<any>()
    const [selectedPaymentProvider, setSelectedPaymentProvider] = useState<any>()
    const [paymentCards, setPaymentCards] = useState<any[]>([])

    useEffect(() => {
        const availableCards = cardProviders.filter(cc => cc.currencyCode === currencyCode)

        const overdueCards = availableCards.map(cc =>
            <SelectPaymentCard key={`${cc.cardId}overdue`}
                brand={cc.brand}
                amount={{total: overdue, currency: currencyCode}}
                label="Overdue balance"
                onPaymentCardSelected={(amount: any) => setSelectedPaymentCard({ amount, ...cc, isOverdueOnly: true })} />
        )

        const balanceCards = availableCards.map(cc =>
            <SelectPaymentCard key={`${cc.cardId}balance`}
                brand={cc.brand}
                amount={{total: balance, currency: currencyCode}}
                label="Account balance"
                onPaymentCardSelected={(amount: any) => setSelectedPaymentCard({ amount, ...cc, isOverdueOnly: false })} />
        )

        const customCards = availableCards.map(cc =>
            <SelectPaymentCard key={`${cc.cardId}custom`}
                brand={cc.brand}
                amount={{total: 1, currency: currencyCode}}
                label="Custom amount"
                onPaymentCardSelected={(amount: any) => setSelectedPaymentCard({ amount, ...cc, isOverdueOnly: false })} />
        )

        setPaymentCards([...overdueCards, ...balanceCards, ...customCards])
    }, [cardProviders, balance, currencyCode, overdue])

    const continueToPaymentProvider = (charges: any, amountToPay: number, selectedPaymentCard: any) => {
        setSelectedPaymentCard(undefined);
        setSelectedPaymentProvider({ charges, amountToPay: amountToPay, selectedPaymentCard })
    }

    const onBackClicked = () => setSelectedPaymentCard(undefined)

    return (
        selectedPaymentCard ?
            <PreviewPaymentNoTransactions
                customerId={customerId}
                selectedPaymentCard={selectedPaymentCard}
                isOverdueOnly={selectedPaymentCard.isOverdueOnly}
                onBackClicked={onBackClicked}
                onPaymentProviderSelected={continueToPaymentProvider} />
            : selectedPaymentProvider ?
                <PaymentProviderWithNoTransactions
                    charges={selectedPaymentProvider.charges}
                    amountToPay={selectedPaymentProvider.amountToPay}
                    paymentCard={selectedPaymentProvider.selectedPaymentCard}
                    isOverdueOnly={selectedPaymentProvider.selectedPaymentCard.isOverdueOnly}
                />                
                :
                <div className="text-white flex-grow-1 overflow-auto">
                    <div className="d-flex flex-column py-4">
                        {
                            paymentCards.length === 0 ? <></> : paymentCards
                                .reduce((prev, curr) => [prev, <div key={`${prev.key}${curr.key}`} className="my-2" />, curr])
                        }
                    </div>
                </div>
    )
}

export default PayOverdueAccountBalances