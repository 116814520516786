import { Alert } from 'invevo-react-components'
import { widgetThemes } from "../../../enums/widgetThemes"
import { dataPrimitive } from "../../../../data/enums/dataPrimitive"
import ValueCard from '../../../../../library/cards/ValueCard'
import { isDynamicData } from './helpers'

const isNumber = value => !isNaN(value) && !isNaN(parseFloat(value))

const getStyle = value => isNumber(value) ? (Number(value) >= 0 ? "good" : "bad") : "info"

const ValueDisplayWidget = ({ state }) => {
    const { widget, customerData } = state

    const getValue = (fieldName, fieldType) => {
        if (isDynamicData(customerData))
            switch (fieldType) {
                case dataPrimitive.LOOKUP: return customerData.stringFields[fieldName]
                case dataPrimitive.TEXT: return customerData.stringFields[fieldName]
                case dataPrimitive.BOOLEAN: return customerData.booleanFields[fieldName]
                case dataPrimitive.NUMBER: return customerData.numberFields[fieldName]
                case dataPrimitive.DATE: return customerData.datetimeFields[fieldName]
                default: return ""
            }
        else return customerData[fieldName]
    }

    return (
        getValue(widget.field.fieldName, widget.field.dataType) ?
            <ValueCard
                className="w-100 h-100"
                title={widget.displayName}
                theme={widget.theme === widgetThemes.DEFAULT ? "white" : "blue"}
                value1={{
                    label: widget.field.value,
                    value: getValue(widget.field.fieldName, widget.field.dataType),
                    style: getStyle(getValue(widget.field.fieldName, widget.field.dataType)),
                    dateFormat: 'DD MMM yyyy',
                    culture: "en-GB",
                    currency: "GBP"
                }}
                value2={{
                    label: widget.field.value,
                    value: getValue(widget.field.fieldName, widget.field.dataType),
                    style: getStyle(getValue(widget.field.fieldName, widget.field.dataType)),
                    dateFormat: 'DD MMM yyyy',
                    culture: "en-GB",
                    currency: "GBP"
                }}
            />
            : <Alert className="mb-3" iconClasses="fal fa-exclamation-triangle" text={`No ${widget.field.fieldName} value available for this customer`} />
    )
}

export default ValueDisplayWidget