import React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLookup } from '../../api/lookupApi.js';
import StatefulSelect from '../common/statefulSelect';
import { rolesSelectList } from '../../selectors/task';
import { useClient } from 'invevo-react-components';

const RolesSelect = ({ ids, onChange, getLookup, customerId, isVirtualAccount, roles }) => {

  const client = useClient()
  useDeepCompareEffect(() => {
    if (customerId) {
      getLookup(client, 'lookup/roles', 'roles', {
        accountId: customerId,
        isvirtualAccount: isVirtualAccount
      });
    }
  }, [customerId, isVirtualAccount, getLookup]);

  return (
    <StatefulSelect
      options={roles}
      optionIds={ids}
      onChange={onChange}
      isMulti={true}
      placeholder={'Please select role(s)'}
    />
  );
};

RolesSelect.propTypes = {
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  roles: rolesSelectList(state.lookupReducer.roles.results),
});

export default connect(mapStateToProps, { getLookup })(RolesSelect);
