import React from 'react';

const DetailColumnCell = ({ dataItem, expandChange }) => {
  const handleClick = () => expandChange({ dataItem });

  return (
    <td className="k-hierarchy-cell">
      {dataItem.expanded ? (
        // eslint-disable-next-line
        <i
          onClick={handleClick}
          className="fas fa-minus pointer"
        ></i>
      ) : (
        // eslint-disable-next-line
        <i
          onClick={handleClick}
          className="fas fa-plus pointer"
        ></i>
      )}
    </td>
  );
};

export default DetailColumnCell;
