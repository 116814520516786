import React, { useState } from 'react'
import { connect } from 'react-redux'
import NotesHistoryGrid from './notesHistoryGrid'
import AddNote from './addNote'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useClient } from 'invevo-react-components'
import { ActionStripItems, useActionStrip } from '../../../../contexts/ActionStripContext'

const HistoryPanel = (props) => {
  const client = useClient()
  const { invevoCustomerNotePlacementToTop } = useFlags()
  const [refresh, setRefresh] = useState(0)
  const { navToAction } = useActionStrip()

  const onNavToEmailReply = email => navToAction(ActionStripItems.EMAIL, { emailMode: "email-reply", emailToReply: email })

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          {invevoCustomerNotePlacementToTop ?
            (<>
              <AddNote setRefresh={setRefresh} />
              <NotesHistoryGrid
                client={client}
                refresh={refresh}
                selectedTransactionIds={props.selectedTransactionIds}
                allTransactionsSelected={props.allTransactionsSelected}
                navToEmailReply={onNavToEmailReply}
              />
            </>)
            : (<>
              <NotesHistoryGrid
                client={client}
                refresh={refresh}
                selectedTransactionIds={props.selectedTransactionIds}
                allTransactionsSelected={props.allTransactionsSelected}
                navToEmailReply={onNavToEmailReply}
              />
              <AddNote setRefresh={setRefresh} />
            </>)
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedTransactionIds: state.functionReducer.transactionIds,
    allTransactionsSelected: state.functionReducer.allTransactionsSelected
  };
};

export default connect(mapStateToProps)(HistoryPanel);
