import Tab from "../../../library/Tabs/Tab"
import Tabs from "../../../library/Tabs/Tabs"
import FileExportConfiguration from "./exportEntityConfigs/FileExportConfiguration"
import FileImportConfiguration from "./importEntityConfigs/FileImportConfiguration"
const FileEntityConfiguration = () => {
    return (
        <div className="d-flex w-100 h-100">
            <div className="d-flex flex-column flex-grow-1">
                <Tabs className="h-100 p-4" contentClassName="">
                    <Tab title="File Import" isActive={true}>
                        <FileImportConfiguration />
                    </Tab>
                    <Tab title="File Export">
                        <FileExportConfiguration />
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default FileEntityConfiguration
