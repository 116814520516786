import { DndGrid } from "invevo-react-components"
import React, { useEffect, useState } from "react"
import { widgetTypes } from "../../enums/widgetTypes"
import InfoDisplayWidget from "./widgets/InfoDisplayWidget"
import classes from "./CustomerDashboard.module.scss"
import PieChartDisplayWidget from "./widgets/PieChartDisplayWidget"
import BarChartDisplayWidget from "./widgets/BarChartDisplayWidget"
import ValueDisplayWidget from "./widgets/ValueDisplayWidget"
import AreaChartDisplayWidget from "./widgets/AreaChartDisplayWidget"
import LineChartDisplayWidget from "./widgets/LineChartDisplayWidget"
import TransactionsDisplayWidget from "./widgets/transactionDisplayWidget/TransactionsDisplayWidget"
import { useCustomer } from "../../../../contexts/CustomerContext"
import AdvancedFilters from "./transactionFilters/AdvancedFilters"

const CustomerDashboard = ({ className, state, dispatch, fullScreenButton }) => {
    const {
        dashboard,
        customerRefs,
        customerFields,
        transactionFields,
        transactionAggregations,
        clientUserDashboardsConfig,
        lookups
    } = state

    const [showGrid, setShowGrid] = useState(true)
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)

    const [customer] = useCustomer()

    const [sortedWidgets, setSortedWidgets] = useState(
        sortWidgets([
            ...dashboard.infoWidgets,
            ...dashboard.valueWidgets,
            ...dashboard.transactionsWidgets,
            ...dashboard.smallBarChartWidgets,
            ...dashboard.areaChartWidgets,
            ...dashboard.lineChartWidgets,
            ...dashboard.smallPieChartWidgets
        ])
    )

    useEffect(() => {
        setSortedWidgets(
            sortWidgets([
                ...dashboard.infoWidgets,
                ...dashboard.valueWidgets,
                ...dashboard.transactionsWidgets,
                ...dashboard.smallBarChartWidgets,
                ...dashboard.areaChartWidgets,
                ...dashboard.lineChartWidgets,
                ...dashboard.smallPieChartWidgets
            ])
        )
        setShowGrid(false)
    }, [dashboard])

    useEffect(() => {
        if (showGrid) return
        setShowGrid(true)
    }, [showGrid])

    const getTransactionConfig = widget =>
        clientUserDashboardsConfig.transactionsGridConfigs.find(config => config.transactionsGridRef === widget.reference) || {
            transactionsGridRef: widget.reference,
            displayedColumns: widget.columns.map(a => a.value)
        }

    const renderTransactionsGrid = widget => (
        <div className="w-100 h-100 overflow-auto">
            <TransactionsDisplayWidget
                state={{
                    widget,
                    customerRefs,
                    transactionFields,
                    columnConfig: getTransactionConfig(widget),
                    dashboardsConfig: clientUserDashboardsConfig,
                    lookups: lookups,
                    aggregations: transactionAggregations
                }}
                dispatch={dispatch}
            />
        </div>
    )

    return (
        <div className={`d-flex flex-column h-100 w-100 overflow-auto ${className ? className : ""} ${classes.dashboard}`}>
            <div className={`d-flex mx-4 ${showAdvancedFilters ? "mt-2" : "my-2"} fs-3 text-white fw-lighter`}>
                <div
                    role="button"
                    className={`position-relative d-flex align-items-center bg-grey text-grey ms-auto me-2 px-2 ${showAdvancedFilters ? "rounded-top" : "rounded"
                        } `}
                    onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                >
                    <i className="text-blue fal fa-filter"></i>
                    <span className="fs-4 mx-2">Advanced Filters</span>
                    {customer.advancedTransactionFilters.length > 0 && (
                        <span className={`position-absolute text-white text-center fs-5 rounded-circle mb-0 ${classes.filterCount}`}>
                            {customer.advancedTransactionFilters.length}
                        </span>
                    )}
                </div>
                <span className="me-3">{dashboard.name}</span>
                {fullScreenButton}
            </div>
            <div className="h-100 px-4 pb-4 overflow-auto">
                {showAdvancedFilters && (
                    <div className="mb-2">
                        <AdvancedFilters fields={transactionFields} lookups={lookups} />
                    </div>
                )}
                {transactionAggregations === undefined ? (
                    <></>
                ) : sortedWidgets.some(widget => widget.widgetType === widgetTypes.TRANSACTIONS_GRID) ? (
                    renderTransactionsGrid(sortedWidgets.find(widget => widget.widgetType === widgetTypes.TRANSACTIONS_GRID))
                ) : (
                    showGrid && (
                        <DndGrid isReadOnly={true}>
                            {sortedWidgets.map(widget => {
                                switch (widget.widgetType) {
                                    case widgetTypes.INFORMATION:
                                        return customer.isVirtualAccount ? (
                                            <></>
                                        ) : (
                                            <div
                                                key={`${widget.ordinal}`}
                                                id={`${widget.ordinal}`}
                                                x={widget.cellX}
                                                y={widget.cellY}
                                                w={widget.cellWidth}
                                                h={widget.cellHeight}
                                                minW={4}
                                                minH={2}
                                            >
                                                <div className="w-100 h-100 p-2">
                                                    <InfoDisplayWidget
                                                        state={{ widget, lookups, customerFields, customerData: customer.data }} />
                                                </div>
                                            </div>
                                        )
                                    case widgetTypes.VALUE:
                                        return customer.isVirtualAccount ? (
                                            <></>
                                        ) : (
                                            <div
                                                key={`${widget.ordinal}`}
                                                id={`${widget.ordinal}`}
                                                x={widget.gridCellX}
                                                y={widget.gridCellY}
                                                w={widget.gridCellWidth}
                                                h={widget.gridCellHeight}
                                                minW={2}
                                                minH={2}
                                            >
                                                <div className="w-100 h-100 p-2">
                                                    <ValueDisplayWidget state={{ widget, customerData: customer.data }} />
                                                </div>
                                            </div>
                                        )
                                    case widgetTypes.SMALL_PIE_CHART:
                                        return (
                                            <div
                                                key={`${widget.ordinal}`}
                                                id={`${widget.ordinal}`}
                                                x={widget.gridCellX}
                                                y={widget.gridCellY}
                                                w={widget.gridCellWidth}
                                                h={widget.gridCellHeight}
                                                minW={4}
                                                minH={4}
                                            >
                                                <div className="w-100 h-100 p-2">
                                                    <PieChartDisplayWidget state={{ widget, aggregations: transactionAggregations }} />
                                                </div>
                                            </div>
                                        )
                                    case widgetTypes.AREA_CHART:
                                        return (
                                            <div
                                                key={`${widget.ordinal}`}
                                                id={`${widget.ordinal}`}
                                                x={widget.gridCellX}
                                                y={widget.gridCellY}
                                                w={widget.gridCellWidth}
                                                h={widget.gridCellHeight}
                                                minW={4}
                                                minH={4}
                                            >
                                                <div className="w-100 h-100 p-2">
                                                    <AreaChartDisplayWidget state={{ widget, aggregations: transactionAggregations }} />
                                                </div>
                                            </div>
                                        )
                                    case widgetTypes.LINE_CHART:
                                        return (
                                            <div
                                                key={`${widget.ordinal}`}
                                                id={`${widget.ordinal}`}
                                                x={widget.gridCellX}
                                                y={widget.gridCellY}
                                                w={widget.gridCellWidth}
                                                h={widget.gridCellHeight}
                                                minW={4}
                                                minH={4}
                                            >
                                                <div className="w-100 h-100 p-2">
                                                    <LineChartDisplayWidget state={{ widget, aggregations: transactionAggregations }} />
                                                </div>
                                            </div>
                                        )
                                    case widgetTypes.SMALL_BAR_CHART:
                                        return (
                                            <div
                                                key={`${widget.ordinal}`}
                                                id={`${widget.ordinal}`}
                                                x={widget.gridCellX}
                                                y={widget.gridCellY}
                                                w={widget.gridCellWidth}
                                                h={widget.gridCellHeight}
                                                minW={4}
                                                minH={4}
                                            >
                                                <div className="w-100 h-100 p-2">
                                                    <BarChartDisplayWidget state={{ widget, aggregations: transactionAggregations }} />
                                                </div>
                                            </div>
                                        )
                                    default:
                                        return <React.Fragment key={widget.ordinal}></React.Fragment>
                                }
                            })}
                        </DndGrid>
                    )
                )}
            </div>
        </div>
    )
}

const sortWidgets = widgets => [...widgets].sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1))
export default CustomerDashboard
