import classes from "./ResultCountButton.module.scss"

type ResultCountButtonProps = {
    colour: string
    text: string
    icon: string
    amount: number | undefined
    onClick: () => void
}

const ResultCountButton = ({ colour, text, icon, amount, onClick }: ResultCountButtonProps) => (
    <div className={`d-flex ${classes.resultCountButtonWidth}`} aria-label={text}>
        <div className={`d-flex ${classes.onHover} align-items-center p-2 me-auto pointer`} onClick={onClick}>
            <i className={`fal ${icon} text-${colour} me-2 fs-5`} />
            <span className="small text-black fw-bold me-1 rounded">{amount ?? 0}</span>
            <span className="small text-grey">{text}</span>
        </div>
    </div>
)

export default ResultCountButton
