import React from 'react';
import { injectIntl } from 'react-intl';
import { useInvevoSession } from 'invevo-react-components'
import classes from './Post.module.scss'
import { customerDetailsPane } from '../../constants';

const PostContentSelectionButtons = ({ contacts, transactions, documents }) => {
  const [, setInvevoSession] = useInvevoSession()

  const navToContacts = () => {
    setInvevoSession({ selectedCustomerTab: customerDetailsPane.contacts })
  }

  const navToTransactions = () => {
    setInvevoSession({ selectedCustomerTab: "transactions" })
  }

  const navToDocuments = () => {
    setInvevoSession({ selectedCustomerTab: customerDetailsPane.files, showTaskFiles: false })
  }

  return (
    <div className="d-flex px-3 align-items-center">
      <button
        className={`btn ${classes['btn-custom']}`}
        type="button"
        onClick={navToContacts}
      >
        Add Recipients{' '}{contacts.length > 0 && `(${contacts.length})`}
      </button>
      <button
        className={`btn ${classes['btn-custom']}`}
        type="button"
        onClick={navToTransactions}
      >
        Add Transactions{' '}{transactions.length > 0 && `(${transactions.length})`}
      </button>
      <button
        className={`btn ${classes['btn-custom']}`}
        type="button"
        onClick={navToDocuments}
      >
        Add Documents{' '}{documents.length > 0 && `(${documents.length})`}
      </button>
    </div>
  );
};

export default injectIntl(PostContentSelectionButtons);
