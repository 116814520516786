import update from 'immutability-helper';
import { reduce } from 'lodash';
import { init } from '../constants';

export const initialAuthState = {
  isFetching: false,
  isAuthenticated: false,
  userName: '',
  errorMessage: '',
  token: undefined,
  isFetchingSecurityQuestion: false,
  securityQuestionId: -1,
  securityQuestionText: '',
  securityQuestionErrorMsg: '',
  selectedLanguage: 'en-GB',
  loginFailure: false,
  successRedirect: null
};

export const authReducerStates = {
  CONSTANTS: {}
};
init.call(authReducerStates.CONSTANTS, [
  'LOGIN_REQUEST',
  'LOGIN_REQUEST_SUCCESS',
  'LOGIN_REQUEST_FAILURE',
  'LOGOUT_REQUEST',
  'LOGOUT_REQUEST_SUCCESS',
  'REFRESH_REQUEST',
  'REFRESH_REQUEST_SUCCESS',
  'REFRESH_REQUEST_FAILURE',
  'SECURITY_QUESTION_REQUEST',
  'SECURITY_QUESTION_SUCCESS',
  'SECURITY_QUESTION_ERROR',
  'CHANGE_LANGUAGE',
  'SET_PERMISSIONS',
  'SET_USERNAME',
  'AUTH_SUCCESS',
  'UNAUTHENTICATED'
]);

// Permissions
authReducerStates[authReducerStates.CONSTANTS.SET_PERMISSIONS] = {
  action: (permissions) => ({
    type: authReducerStates.CONSTANTS.SET_PERMISSIONS,
    permissions
  }),
  reduce: (state, action) => {
    const permissions = reduce(
      action.permissions,
      (res, p) => {
        res[p] = true;
        return res;
      },
      {}
    );

    return update(state, {
      permissions: { $set: permissions }
    });
  }
};

authReducerStates[authReducerStates.CONSTANTS.SET_USERNAME] = {
  action: (userName) => ({
    type: authReducerStates.CONSTANTS.SET_USERNAME,
    userName
  }),
  reduce: (state, action) =>
    update(state, {
      userName: { $set: action.userName },
      errorMessage: { $set: '' }
    })
};
/////////////////////////
// Login
/////////////////////////
authReducerStates[authReducerStates.CONSTANTS.LOGIN_REQUEST] = {
  action: (userName) => ({
    type: authReducerStates.CONSTANTS.LOGIN_REQUEST,
    userName
  }),
  reduce: (state, action) =>
    update(state, {
      isFetching: { $set: true },
      isAuthenticated: { $set: false },
      userName: { $set: action.userName },
      errorMessage: { $set: '' },
      loginFailure: { $set: false }
    })
};
authReducerStates[authReducerStates.CONSTANTS.LOGIN_REQUEST_SUCCESS] = {
  action: (token) => ({
    type: authReducerStates.CONSTANTS.LOGIN_REQUEST_SUCCESS,
    token
  }),
  reduce: (state, action) => {
    return update(state, {
      isFetching: { $set: false },
      isAuthenticated: { $set: true },
      errorMessage: { $set: '' },
      token: { $set: action.token },
      loginFailure: { $set: false }
    });
  }
};
authReducerStates[authReducerStates.CONSTANTS.LOGIN_REQUEST_FAILURE] = {
  action: (message) => ({
    type: authReducerStates.CONSTANTS.LOGIN_REQUEST_FAILURE,
    message
  }),
  reduce: (state, action) =>
    update(state, {
      isFetching: { $set: false },
      isAuthenticated: { $set: false },
      errorMessage: { $set: action.message },
      loginFailure: { $set: true }
    })
};
/////////////////////////
// Refresh
/////////////////////////
authReducerStates[authReducerStates.CONSTANTS.REFRESH_REQUEST] = {
  action: (userName) => ({
    type: authReducerStates.CONSTANTS.REFRESH_REQUEST,
    userName
  }),
  reduce: (state, action) =>
    update(state, {
      isFetching: { $set: true },
      isAuthenticated: { $set: false },
      userName: { $set: action.userName },
      errorMessage: { $set: '' },
      loginFailure: { $set: false }
    })
};
authReducerStates[authReducerStates.CONSTANTS.REFRESH_REQUEST_SUCCESS] = {
  action: (token) => ({
    type: authReducerStates.CONSTANTS.REFRESH_REQUEST_SUCCESS,
    token
  }),
  reduce: (state, action) => {
    return update(state, {
      isFetching: { $set: false },
      isAuthenticated: { $set: true },
      errorMessage: { $set: '' },
      token: { $set: action.token },
      loginFailure: { $set: false }
    });
  }
};
authReducerStates[authReducerStates.CONSTANTS.REFRESH_REQUEST_FAILURE] = {
  action: (message) => ({
    type: authReducerStates.CONSTANTS.REFRESH_REQUEST_FAILURE,
    message
  }),
  reduce: (state, action) =>
    update(state, {
      isFetching: { $set: false },
      isAuthenticated: { $set: false },
      errorMessage: { $set: action.message },
      loginFailure: { $set: true }
    })
};
/////////////////////////
// Logout
/////////////////////////
authReducerStates[authReducerStates.CONSTANTS.LOGOUT_REQUEST] = {
  action: () => ({ type: authReducerStates.CONSTANTS.LOGOUT_REQUEST }),
  reduce: (state) =>
    update(state, {
      isFetching: { $set: true },
      isAuthenticated: { $set: false },
      token: { $set: undefined }
    })
};
authReducerStates[authReducerStates.CONSTANTS.LOGOUT_REQUEST_SUCCESS] = {
  action: () => ({ type: authReducerStates.CONSTANTS.LOGOUT_REQUEST_SUCCESS }),
  reduce: (state) =>
    update(state, {
      isFetching: { $set: false }
    })
};

/////////////////////////
// Security Question
/////////////////////////
authReducerStates[authReducerStates.CONSTANTS.SECURITY_QUESTION_REQUEST] = {
  action: () => ({
    type: authReducerStates.CONSTANTS.SECURITY_QUESTION_REQUEST
  }),
  reduce: (state) =>
    update(state, {
      isFetchingSecurityQuestion: { $set: true },
      securityQuestionId: { $set: -1 },
      securityQuestionText: { $set: '' }
    })
};
authReducerStates[authReducerStates.CONSTANTS.SECURITY_QUESTION_SUCCESS] = {
  action: (id, text) => ({
    type: authReducerStates.CONSTANTS.SECURITY_QUESTION_SUCCESS,
    securityQuestionId: id,
    securityQuestionText: text,
    securityQuestionErrorMsg: ''
  }),
  reduce: (state, action) =>
    update(state, {
      isFetchingSecurityQuestion: { $set: false },
      securityQuestionId: { $set: action.securityQuestionId },
      securityQuestionText: { $set: action.securityQuestionText },
      securityQuestionErrorMsg: { $set: '' }
    })
};
authReducerStates[authReducerStates.CONSTANTS.SECURITY_QUESTION_ERROR] = {
  action: () => ({ type: authReducerStates.CONSTANTS.SECURITY_QUESTION_ERROR }),
  reduce: (state) =>
    update(state, {
      isFetchingSecurityQuestion: { $set: false },
      securityQuestionId: { $set: -1 },
      securityQuestionText: { $set: '' },
      securityQuestionErrorMsg: { $set: 'Problem loading Security Question.' }
    })
};

/////////////////////////
// Change language
/////////////////////////
authReducerStates[authReducerStates.CONSTANTS.CHANGE_LANGUAGE] = {
  action: (newLang) => ({
    type: authReducerStates.CONSTANTS.CHANGE_LANGUAGE,
    newLang: newLang
  }),
  reduce: (state, action) =>
    update(state, {
      selectedLanguage: { $set: action.newLang }
    })
};

/////////////////////////
// Auth Success Redirect
/////////////////////////
authReducerStates[authReducerStates.CONSTANTS.AUTH_SUCCESS] = {
  action: (redirect) => ({
    type: authReducerStates.CONSTANTS.AUTH_SUCCESS,
    redirect
  }),
  reduce: (state, action) =>
    update(state, {
      successRedirect: { $set: action.redirect }
    })
};

/////////////////////////
// Unauthenticated
/////////////////////////
authReducerStates[authReducerStates.CONSTANTS.UNAUTHENTICATED] = {
  action: () => ({
    type: authReducerStates.CONSTANTS.UNAUTHENTICATED
  }),
  reduce: (state) =>
    update(state, {
      isAuthenticated: { $set: false }
    })
};

/////////////////////////
// Global reducer function
/////////////////////////
const globalReducerBuilder = function (statesHolder, initialState) {
  return function (state = initialState, action) {
    if (statesHolder.hasOwnProperty(action.type)) {
      return statesHolder[action.type].reduce(state, action);
    }
    return state;
  };
};

export const authReducer = globalReducerBuilder(
  authReducerStates,
  initialAuthState
);
