import Trigger from './triggers/Trigger'
import Actions from './actions/Actions'
import DataFilters from './filters/DataFilters'
import { useApi, useClient, useConfig } from 'invevo-react-components'
import { useState, useEffect, useCallback } from 'react'

const WorkflowSetup = ({ state, dispatch }) => {
    const api = useApi()
    const client = useClient()
    const config = useConfig()
    
    const [isFetching, setIsFetching] = useState(false)

    const getDataConfiguration = useCallback(() => {
        return new Promise((resolve, reject) => {
            api.get(`${config.AUTOMATE_API_URL}/api/${client}/field-configs`)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    if (error.response.status === 404) resolve({ data: { fields: [] } })
                    else reject(error)
                })
        })
    }, [api, client, config.AUTOMATE_API_URL])

    useEffect(() => {
        if (isFetching || state.customerData.hasFetched || !config.AUTOMATE_API_URL) return
        getDataConfiguration()
            .then(response => {
                const dataConfiguration = response.data.filter(d => d.isAvailableInAutomate)
                dispatch({
                    type: "CUSTOMER_DATA_RETRIEVED",
                    customerFields: dataConfiguration
                })
                setIsFetching(false)
            })
            .catch(error => {
                console.error(error)
            })

    }, [api, client, isFetching, getDataConfiguration, dispatch, state.customerData.hasFetched, config.AUTOMATE_API_URL])

    return (
        <div className="d-flex flex-column w-100 h-100">
            {!state.selectedWorkflowRule.reference ?
                <h5 className="text-grey">Please select a workflow or add a new one.</h5> :
                <div className="d-flex flex-column flex-grow-1">
                    <Trigger
                        selectedRule={state.selectedWorkflowRule}
                        dispatch={dispatch}
                    />
                    <hr />
                    <DataFilters
                        state={state}
                        dispatch={dispatch}
                    />
                    <hr />
                    <Actions
                        state={state}
                        dispatch={dispatch}
                    />
                </div>
            }
        </div>
    )
}

export default WorkflowSetup
