import { useState, useCallback, ChangeEvent, useEffect } from "react"
import classes from "./DataFieldDropdown.module.scss"
import { Input } from "invevo-react-components"
import { padElementsWith } from "../helpers"
import useClickOutsideRef from "../../hooks/useClickOutsideRef"
import DropdownOption from "../../types/DropdownOptions"

type AutoCompleteDropdownProps = {
    options: DropdownOption<string>[]
    onOptionSelected: (option: DropdownOption<string> | undefined) => void
    selectedOption: DropdownOption<string> | undefined
    className?: string
    ariaLabel?: string
    disabled?: boolean
}

const AutoCompleteDropdown = ({
    options,
    onOptionSelected,
    selectedOption,
    className = "",
    ariaLabel = undefined,
    disabled = false
}: AutoCompleteDropdownProps) => {
    const [inputValue, setInputValue] = useState<string>(selectedOption?.label || selectedOption?.value || "")
    const [showingOptions, setShowingOptions] = useState(false)
    const ref = useClickOutsideRef(() => {
        setShowingOptions(false)
        setInputValue(selectedOption?.label || selectedOption?.value || "")
    })

    useEffect(() => {
        if (inputValue !== "") return
        setInputValue(selectedOption?.label || selectedOption?.value || "")
    }, [inputValue, setInputValue, selectedOption])

    const onOptionClicked = useCallback(
        (option: DropdownOption<string>) => {
            setInputValue(option.label || "")

            if (selectedOption?.value !== option.value) {
                onOptionSelected(option)
                setShowingOptions(false)
            }
        },
        [onOptionSelected, selectedOption?.value]
    )

    const doesOptionMatchValue = (option: DropdownOption<string>, value: string) => option.label.toLowerCase().includes(value.toLowerCase())

    const matchedOptions = options
        .filter(option => doesOptionMatchValue(option, inputValue))
        .sort((a, b) => {
            if (a.label === b.label) return a.label > b.label ? 1 : -1
            return a.label > b.label ? 1 : -1
        })

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputString = event.target.value
        setInputValue(inputString)

        if (inputString === "") {
            onOptionSelected(undefined)
        }
    }

    const onInputClick = () => {
        if (disabled) return
        setShowingOptions(true)
    }

    const getMatchedOptionValue = (option: DropdownOption<string>) => {
        const matchingIndex = option.label.toLowerCase().indexOf(inputValue.toLowerCase())
        const firstPart = option.label.substring(0, matchingIndex)
        const matchingPart = option.label.substring(matchingIndex, matchingIndex + inputValue.length)
        const lastPart = option.label.substring(matchingIndex + inputValue.length, option.label.length)

        return (
            <span>
                {firstPart}
                <strong>{matchingPart}</strong>
                {lastPart}
            </span>
        )
    }

    const matchedOptionElements = matchedOptions.map((option, index) => (
        <div
            key={index}
            role="listitem"
            aria-label={option.value}
            className="d-flex py-2 px-1 pointer align-items-center no-select"
            onClick={() => onOptionClicked(option)}
        >
            <span className="ms-1 d-block flex-grow-1 text-grey text-truncate" data-bs-toggle="tooltip" data-bs-placement="bottom" title={option.label}>
                {getMatchedOptionValue(option)}
            </span>
        </div>
    ))

    return (
        <div ref={ref} className={`d-flex position-relative flex-column ${className ? className : ""}`}>
            <Input
                className={`w-100 ${matchedOptions.length > 0 && showingOptions && classes["input-with-matches"]}`}
                placeholder="Please select"
                value={inputValue}
                onChange={onInputChange}
                onClick={onInputClick}
                color="white"
                ariaLabel={ariaLabel}
                disabled={disabled}
            />
            <div className="d-flex w-100">
                {matchedOptions.length > 0 && showingOptions && (
                    <div className={`position-absolute ${classes.options} border text-grey shadow bg-grey w-100 ${classes.scrollable}`}>
                        {padElementsWith(matchedOptionElements, "w-100 border-top")}
                    </div>
                )}
            </div>
        </div>
    )
}

export default AutoCompleteDropdown
