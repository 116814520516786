import { useParams, useSearchParams } from "react-router-dom-v5-compat"
import { PRE_AUTH_ROUTE_KEY } from "../library/auth/constants"
import { useSessionStorage } from "./useSessionStorage"
import { useState } from "react"

const useClient = () => {
    const params = useParams()
    const clientFromRouteParams = params["client"]

    const [preAuthRoute] = useSessionStorage(PRE_AUTH_ROUTE_KEY)
    const searchParamsFromAuthRoute = preAuthRoute ? preAuthRoute.replace(preAuthRoute.split("?")[0], "") : null
    const clientFromAuthSearchParams = new URLSearchParams(searchParamsFromAuthRoute).get("client")

    const [searchParams] = useSearchParams()
    const clientFromSearchParams = searchParams.get("client")

    const updatedClient = clientFromRouteParams ?? clientFromAuthSearchParams ?? clientFromSearchParams

    const [client, setClient] = useState<string | undefined>(updatedClient ?? undefined)

    const isClientUndefined = client === undefined && updatedClient
    const hasClientChanged = client !== undefined && updatedClient && client !== updatedClient
    if (isClientUndefined || hasClientChanged) setClient(updatedClient)

    return client
}

export default useClient
