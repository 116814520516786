export type FileImportConfigFieldDataType =
    | FileImportConfigFieldDataTypeNumber
    | FileImportConfigFieldDataTypeText
    | FileImportConfigFieldDataTypeBoolean
    | FileImportConfigFieldDataTypeDate

export enum FileImportConfigFieldDataTypeEnum {
    DATE = "DATE",
    NUMBER = "NUMBER",
    TEXT = "TEXT",
    BOOLEAN = "BOOLEAN"
}

export const TextFormatter = {
    NONE: "NONE",
    UPPERCASE: "UPPERCASE",
    LOWERCASE: "LOWERCASE"
} as const

export type FileImportConfigFieldDataTypeDate = {
    type: FileImportConfigFieldDataTypeEnum.DATE
    format: string
    timezone: string
}

export type FileImportConfigFieldDataTypeNumber = {
    type: FileImportConfigFieldDataTypeEnum.NUMBER
    culture: string
}

export type FileImportConfigFieldDataTypeText = {
    type: FileImportConfigFieldDataTypeEnum.TEXT
}

export type FileImportConfigFieldDataTypeBoolean = {
    type: FileImportConfigFieldDataTypeEnum.BOOLEAN
}
