import { useGrid } from "../Grid"

const Selection = () => {
    const grid = useGrid()
    return (
        <span className="mx-2">
            <strong>{grid.selection.selectedRefs.length}</strong> {grid.selection.selectedRefs.length === 1 ? 'entity' : 'entities'} selected
        </span>
    )
}

export default Selection