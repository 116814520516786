import Toggle from "../../toggle/Toggle"
import classes from "./QuickFilters.module.scss"
import { useGrid } from "./../Grid"
import QuickFilter from "./../types/QuickFilter"

type Props = {
    quickFilters: QuickFilter[]
    removeQuickFilter: (quickFilters: QuickFilter) => void
    clearQuickFilters: () => void
    showingQuickFilters: boolean
    toggleShowQuickFilters: () => void
}

const QuickFilters = ({ quickFilters, removeQuickFilter, clearQuickFilters, showingQuickFilters, toggleShowQuickFilters }: Props) => {
    const grid = useGrid()

    const getDisplayNameForLookup = (lookupReference: string | undefined, label: string): string =>
        grid.lookups.find(l => l.reference === lookupReference)?.entries.find(e => e.reference.toLowerCase() === label?.toLowerCase())?.name ?? label

    return (
        <div className="d-flex flex-grow-1 align-items-center h-100">
            <span className="text-uppercase text-white me-2 no-select">Filters</span>
            <i className="text-white fal fa-filter" />
            {quickFilters.map(quickFilter => {
                const onClick = () => removeQuickFilter(quickFilter)
                return (
                    <div
                        key={quickFilter.field.fieldName + quickFilter.filter.label}
                        className={`ms-2 ${classes.selectedFilter} px-2 py-1 d-flex align-items-center`}
                    >
                        <span>
                            {quickFilter.field.displayName} | {getDisplayNameForLookup(quickFilter.field.dataType.lookupReference, quickFilter.filter.label)}
                            <i
                                className="ms-2 fa-light fa-times-circle pointer"
                                role="button"
                                aria-label={"remove-filter-" + quickFilter.filter.label}
                                onClick={onClick}
                            />
                        </span>
                    </div>
                )
            })}
            <div className={`d-flex align-items-center gap-2 ms-auto me-2 text-white h-100`}>
                {quickFilters.length > 0 && (
                    <button
                        className={`d-flex fs-6 px-2 align-items-center h-100 ${classes.blueButton}`}
                        onClick={clearQuickFilters}
                        aria-label="clear-filters-button"
                    >
                        Clear filters
                    </button>
                )}
                <Toggle
                    status={showingQuickFilters}
                    onStatusChanged={toggleShowQuickFilters}
                    colour="white"
                    onText="Show Quick Filters"
                    ariaLabel="show-quick-filters"
                />
            </div>
        </div>
    )
}

export default QuickFilters
