import Dropdown from "../../../../../library/dropdowns/Dropdown"
import DataField from "../../../../../types/DataField"
import DropdownOption from "../../../../../types/DropdownOptions"
import FieldType from "../../../../../types/enums/FieldType"
import Lookup from "../../../../../types/Lookup"
import EntityConfig from "../../../../entityConfig/types/EntityConfig"
import EntityRelationshipDto from "../../../../entityConfig/types/EntityRelationshipDto"
import { FileEntityExportConfig } from "../../../../integration/types/fileEntityExport/FileExportConfig"
import ActionType from "../../../types/actions/ActionType"
import GenericAction from "../../../types/actions/GenericAction"
import LegacySmsTemplate from "../../../types/LegacySmsTemplate"
import WorkflowRule from "../../../types/WorkflowRule"
import Action from "./Action"
import classes from "./Actions.module.scss"

const actionTypeOptions = {
    SMS: "SMS",
    EMAIL: "EMAIL",
    UPDATE_DATA_FIELD: "UPDATE_DATA_FIELD",
    EXPORT: "EXPORT",
    UPDATE_CHILD_ENTITY: "UPDATE_CHILD_ENTITY"
} as const

const workflowActions = [
    {
        label: "send-sms",
        value: actionTypeOptions.SMS,
        element: (
            <div className="d-flex">
                <div className={`d-flex text-blue align-items-center justify-content-center me-2 ${classes.iconWidth}`}>
                    <i className="fal fa-mobile" />
                </div>
                Send SMS
            </div>
        )
    },
    {
        label: "send-email",
        value: actionTypeOptions.EMAIL,
        element: (
            <div className="d-flex">
                <div className={`d-flex text-blue align-items-center justify-content-center me-2 ${classes.iconWidth}`}>
                    <i className="fal fa-at" />
                </div>
                Send Email
            </div>
        )
    },
    {
        label: "update-data-field",
        value: actionTypeOptions.UPDATE_DATA_FIELD,
        element: (
            <div className="d-flex">
                <div className={`d-flex text-blue align-items-center justify-content-center me-2 ${classes.iconWidth}`}>
                    <i className="fal fa-pen-field" />
                </div>
                Update Data Field
            </div>
        )
    },
    {
        label: "export",
        value: actionTypeOptions.EXPORT,
        element: (
            <div className="d-flex">
                <div className={`d-flex text-blue align-items-center justify-content-center me-2 ${classes.iconWidth}`}>
                    <i className="fal fa-file-export" />
                </div>
                Export to File
            </div>
        )
    },
    {
        label: "update-entity",
        value: actionTypeOptions.UPDATE_CHILD_ENTITY,
        element: (
            <div className="d-flex">
                <div className={`d-flex text-blue align-items-center justify-content-center me-2 ${classes.iconWidth}`}>
                    <i className="fal fa-pen-field" />
                </div>
                Update Children
            </div>
        )
    }
]

type ActionProps = {
    selectedRule: WorkflowRule
    smsTemplates: LegacySmsTemplate[]
    fields: DataField[]
    lookups: Lookup[]
    fileEntityExportConfigs: FileEntityExportConfig[]
    entityConfigs: EntityConfig[]
    isNewEntityStructureEnabled: boolean
    onRuleActionsChanged: (actions: GenericAction[]) => void
    entityRelationships: EntityRelationshipDto[]
}

const Actions = ({
    selectedRule,
    smsTemplates,
    fields,
    lookups,
    fileEntityExportConfigs,
    entityConfigs,
    isNewEntityStructureEnabled,
    onRuleActionsChanged,
    entityRelationships
}: ActionProps) => {
    const onActionRemoved = (action: GenericAction) => onRuleActionsChanged(selectedRule.actions.filter(a => a.actionReference !== action.actionReference))

    const onActionUpdated = (updated: GenericAction) =>
        onRuleActionsChanged(selectedRule.actions.map(action => (action.actionReference === updated.actionReference ? updated : action)))

    const addNewAction = (option: DropdownOption<"SMS" | "EMAIL" | "UPDATE_DATA_FIELD" | "EXPORT" | "UPDATE_CHILD_ENTITY">) => {
        switch (option.value) {
            case actionTypeOptions.SMS:
            case actionTypeOptions.EMAIL:
                onRuleActionsChanged([
                    ...selectedRule.actions,
                    {
                        type: ActionType.ASYNC_COMM_ACTION,
                        actionReference: getNextActionRef(selectedRule.actions),
                        filters: [],
                        rateLimiter: {
                            maxActionsTotal: 100000,
                            maxActionsPerMinute: 1000
                        },
                        communicationType: option.value,
                        communicationTemplateReference: "",
                        contactSelectionType: "ANY_MATCHING",
                        contactFilters: []
                    }
                ])
                break
            case actionTypeOptions.UPDATE_DATA_FIELD:
                onRuleActionsChanged([
                    ...selectedRule.actions,
                    {
                        type: ActionType.UPDATE_DATA_FIELD,
                        actionReference: getNextActionRef(selectedRule.actions),
                        filters: [],
                        fieldType: FieldType.CUSTOMER,
                        fieldUpdates: []
                    }
                ])
                break
            case actionTypeOptions.UPDATE_CHILD_ENTITY:
                onRuleActionsChanged([
                    ...selectedRule.actions,
                    {
                        type: ActionType.UPDATE_CHILD_ENTITY,
                        actionReference: getNextActionRef(selectedRule.actions),
                        targetEntityFilters: [],
                        entityTypeReference: "",
                        fieldUpdates: []
                    }
                ])
                break
            case actionTypeOptions.EXPORT:
                onRuleActionsChanged([
                    ...selectedRule.actions,
                    {
                        type: ActionType.EXPORT_ACTION,
                        actionReference: getNextActionRef(selectedRule.actions),
                        fileEntityExportConfigReference: undefined
                    }
                ])
                break
        }
    }

    const ruleHasAnyActions = selectedRule.actions.length > 0

    const getAvailableActions = () => {
        if (!isNewEntityStructureEnabled) {
            return workflowActions.filter(a => a.value !== actionTypeOptions.EXPORT && a.value !== actionTypeOptions.UPDATE_CHILD_ENTITY)
        }

        if (ruleHasAnyActions) {
            return workflowActions.filter(a => a.value !== actionTypeOptions.UPDATE_CHILD_ENTITY)
        }

        return workflowActions
    }

    const ruleHasRolldownAction = selectedRule.actions.some(action => action.type === ActionType.UPDATE_CHILD_ENTITY)

    return (
        <div className="d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center">
                <h2 className="text-grey mb-0 me-auto">Actions</h2>
                {ruleHasRolldownAction && <em className="text-grey small">Cannot add multiple actions with child update actions</em>}
                <Dropdown
                    className="ms-2"
                    options={getAvailableActions()}
                    onOptionSelected={addNewAction}
                    selectedOption={undefined}
                    disabled={ruleHasRolldownAction}
                    placeholder={
                        <span className="d-flex align-items-center">
                            <i className="fal fa-plus fs-5 me-2" />
                            Add Action
                        </span>
                    }
                    textAlign="left"
                    ariaLabel="add-action"
                />
            </div>
            {selectedRule.actions
                .sort((a, b) => (a.actionReference > b.actionReference ? 1 : -1))
                .map(
                    action =>
                        (action.type !== ActionType.EXPORT_ACTION || isNewEntityStructureEnabled) && (
                            <Action
                                key={action.actionReference}
                                action={action}
                                smsTemplates={smsTemplates}
                                fields={fields}
                                lookups={lookups}
                                fileEntityExportConfigs={fileEntityExportConfigs}
                                entityRelationships={entityRelationships}
                                selectedRule={selectedRule}
                                entityConfigs={entityConfigs}
                                onActionUpdated={onActionUpdated}
                                onActionRemoved={onActionRemoved}
                            />
                        )
                )}
        </div>
    )
}

export default Actions

const getNextActionRef = (actions: GenericAction[]): string => {
    const actionRefs = actions.map(a => parseInt(a.actionReference))
    for (let index = 0; index <= actionRefs.length; index++) {
        if (!actionRefs.includes(index)) {
            return index.toString()
        }
    }

    return ""
}
