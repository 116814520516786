import classes from './SelectedReference.module.scss'

const SelectedReference = ({ label, value, onValueRemoved }) => {
    const onValueRemovedClicked = () => onValueRemoved(value)

    return (
        <div className={`d-flex align-items-center ps-2 pe-1 me-1 text-nowrap ${classes.tag}`}>
            <span className="mb-1 no-select">{label}</span>
            <i
                role="button"
                aria-label="remove"
                className={`ms-1 pointer ${classes.tagDelete} fas fa-times-circle`}
                onClick={onValueRemovedClicked}
            />
        </div>
    )
}

export default SelectedReference