import React, { Component } from 'react';
import { connect } from 'react-redux';

class EditConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      configuration: props.configuration
    };

    this.onChange = this.onChange.bind(this);
    this.onChangeClientConfigurable = this.onChangeClientConfigurable.bind(
      this
    );
    this.onSave = this.onSave.bind(this);
  }

  onChange(event) {
    const field = event.target.name;
    const configuration = Object.assign({}, this.state.configuration);

    configuration[field] = event.target.value;

    return this.setState({ configuration: configuration });
  }

  onChangeClientConfigurable(event) {
    const clientConfigurable = event.target.checked;
    const configuration = Object.assign({}, this.state.configuration);
    configuration.clientConfigurable = clientConfigurable;
    return this.setState({ configuration: configuration });
  }

  onSave() {
    this.props.onSave(this.state.configuration);
  }

  isFormValid() {
    const { ordinal } = this.state.configuration;

    if (ordinal.length === 0) {
      return false;
    }

    return true;
  }

  render() {
    const { isClient } = this.props;
    const {
      name,
      ordinal,
      values,
      description,
      clientConfigurable
    } = this.state.configuration;

    const isValid = this.isFormValid();

    return (
      <div>
        <div key="configuration-form" className="card below-grid-form">
          <h3>Edit Configuration Type ({name})</h3>

          {description && (
            <div className="form-group">
              <div className="well" style={{ width: '480px' }}>
                {description}
              </div>
            </div>
          )}

          <div className="form-group" style={{ width: '480px' }}>
            <input
              name="values"
              type="text"
              placeholder="Values"
              className="form-control"
              value={values}
              onChange={this.onChange}
              title="Values"
            />
          </div>

          <div className="form-group" style={{ width: '480px' }}>
            <input
              name="ordinal"
              type="number"
              placeholder="Order"
              className="form-control"
              value={ordinal}
              onChange={this.onChange}
              title="Order"
            />
          </div>

          {isClient ? (
            ''
          ) : (
            <div className="form-group" style={{ width: '480px' }}>
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={clientConfigurable}
                    onChange={this.onChangeClientConfigurable}
                  />{' '}
                  Client configurable
                </label>
              </div>
            </div>
          )}

          <div className="form-group">
            <button
              className="btnDefault btn btn-default"
              disabled={!isValid}
              onClick={this.onSave}
            >
              Save
            </button>
            <button className="btn btn-default" onClick={this.props.onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(EditConfiguration);
