import useHover from '../../hooks/useHover'
import classes from './ActionStripItem.module.scss'

const ActionStripItem = ({ isActive, iconClasses, onClick, label, style, disabled = false }) => {
    const [hoverRef, isHovering] = useHover()

    const onItemClicked = () => {
        if (disabled) return
        onClick()
    }

    return (
        <div className="position-relative w-100" role="button" aria-label={`action-strip-item-${label}`}>
            <div ref={hoverRef} style={style} className={`d-flex pointer text-white ${classes.item} ${isActive ? classes.active : ''}`} onClick={onItemClicked} >
                <i className={`${disabled && classes.disabled} fs-4 m-auto ${iconClasses}`} />
            </div>

            {isHovering &&
                <div className={`position-absolute top-0 end-100 translate-start d-flex h-75 ${classes.label}`}>
                    <span className="m-auto text-nowrap fs-5 p-2 fw-lighter no-select" role="textbox">{disabled ? `${label} feature is not enabled.` : label }</span>
                </div>
            }
        </div>
    )
}

export default ActionStripItem