import ReactFlow, { Connection, Edge, EdgeChange, ReactFlowInstance, addEdge, Node, NodeChange, XYPosition } from "reactflow"
import "reactflow/dist/style.css"
import { useCallback, useRef, useState, DragEvent } from "react"
import customEdge, { CustomEdge } from "../types/CustomEdge"
import FlowNode from "../FlowNode"

type FlowEditorProps<T extends FlowNode, TTypes extends unknown> = {
    nodeTypes: {
        Node: (props: {
            data: {
                state: T
                onNodeCollapseToggle: (reference: string) => void
                onNodeDeleteClick: (reference: string) => void
            }
        }) => JSX.Element
    }
    nodes: Node[]
    setNodes: React.Dispatch<React.SetStateAction<Node[]>>
    onNodesChange: (nodeChanges: NodeChange[]) => void
    onNodesDelete?: (deletedNodes: Node[]) => void
    edges: Edge[]
    setEdges: React.Dispatch<React.SetStateAction<Edge[]>>
    onEdgesChange: (edgeChanges: EdgeChange[]) => void
    onEdgesDelete?: (deletedEdges: Edge[]) => void
    allowMultipleTargetNodes?: boolean
    allowIntraNodeConnections?: boolean
    getDefaultNodeState: T | ((type: TTypes, position: XYPosition) => T)
    dragForNewNode?: boolean
    onConnectionAdded?: (params: Connection) => void
    getCustomEdge?: (conn: Connection) => CustomEdge
    onNodeCollapseToggle?: (reference: string) => void
    onNodeDeleteClick: (reference: string) => void
}

const FlowEditor = <T extends FlowNode, TTypes extends unknown>({
    nodeTypes,
    nodes,
    setNodes,
    onNodesChange,
    onNodesDelete = () => {},
    edges,
    setEdges,
    onEdgesChange,
    onEdgesDelete = () => {},
    allowMultipleTargetNodes = false,
    allowIntraNodeConnections = false,
    getDefaultNodeState,
    dragForNewNode = true,
    onConnectionAdded,
    getCustomEdge,
    onNodeCollapseToggle = () => {},
    onNodeDeleteClick
}: FlowEditorProps<T, TTypes>) => {
    const reactFlowWrapper = useRef<HTMLDivElement>(null)
    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>()

    const onConnect = useCallback(
        (params: Connection) => {
            if ((allowIntraNodeConnections && params.sourceHandle === params.targetHandle) || (!allowIntraNodeConnections && params.source === params.target))
                return

            setEdges(edges =>
                addEdge(
                    {
                        ...params,
                        ...(getCustomEdge ? getCustomEdge(params) : customEdge)
                    },
                    edges
                ).filter(
                    edge =>
                        edge.sourceHandle !== params.sourceHandle ||
                        ((allowMultipleTargetNodes || edge.target === params.target) && edge.targetHandle === params.targetHandle)
                )
            )
            onConnectionAdded && onConnectionAdded(params)
        },
        [allowIntraNodeConnections, allowMultipleTargetNodes, getCustomEdge, onConnectionAdded, setEdges]
    )

    const onDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
        event.preventDefault()
    }, [])

    const onDrop = useCallback(
        (event: DragEvent<HTMLDivElement>) => {
            event.preventDefault()

            if (!dragForNewNode) return
            if (!reactFlowWrapper.current) return
            if (!reactFlowInstance) return

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top
            })
            const nodeType = event.dataTransfer.getData("nodeType")
            const state = getDefaultNodeState instanceof Function ? getDefaultNodeState(JSON.parse(nodeType) as TTypes, position) : getDefaultNodeState

            const newNode = {
                id: state.reference,
                type: "Node",
                position,
                data: {
                    state,
                    onNodeCollapseToggle,
                    onNodeDeleteClick
                }
            }

            setNodes(nds => nds.concat(newNode))
        },
        [dragForNewNode, getDefaultNodeState, onNodeCollapseToggle, onNodeDeleteClick, reactFlowInstance, setNodes]
    )

    const onInit = (instance: ReactFlowInstance) => setReactFlowInstance(instance)

    return (
        <div className="w-100 h-100" ref={reactFlowWrapper}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onNodesDelete={onNodesDelete}
                onEdgesChange={onEdgesChange}
                onEdgesDelete={onEdgesDelete}
                onConnect={onConnect}
                onInit={onInit}
                onDrop={onDrop}
                onDragOver={onDragOver}
                nodeTypes={nodeTypes}
                fitView
            />
        </div>
    )
}

export default FlowEditor
