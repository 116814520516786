import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ValidatingInput extends Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form

    const value =
      event.currentTarget[this.props.type === 'checkbox' ? 'checked' : 'value'];

    this.props.setValue(value);

    // If an onChange event handler has been attached then bubble the event up.
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  renderInput() {
    const title = this.props.title || this.props.placeholder;
    const isCheckbox = this.props.type === 'checkbox';
    return (
      <input
        className="form-control"
        type={this.props.type || 'text'}
        name={this.props.name}
        placeholder={this.props.placeholder}
        onChange={this.changeValue}
        checked={isCheckbox && this.props.value ? 'checked' : null}
        value={!isCheckbox ? this.props.value || '' : null}
        title={title}
        maxLength={this.props.maxLength}
        disabled={this.props.disabled}
      />
    );
  }

  renderRequiredInput() {
    return (
      <>
        {this.renderInput()}
        <span className="form-control-feedback">
          <i className="fa-solid fa-asterisk"></i>
        </span>
      </>
    );
  }

  render() {
    // Set a specific className based on the validation
    // state of this component. showRequired is true
    // when the value is empty and the required prop is
    // passed to the input. showError is true when the
    // value typed is invalid
    const className =
      (this.props.hideFormGroupClass ? '' : 'form-group ') +
      (this.props.className || ' ') +
      (this.props.required ? ' has-feedback' : '') +
      (this.props.showRequired
        ? ' required'
        : this.props.showError
        ? ' error'
        : '');

    // An error message is returned ONLY if the component is invalid
    // or the server has returned an error message
    const { errorMessage } = this.props;

    return (
      <div className={className}>
        {this.props.hideLabel ? (
          ''
        ) : (
          <label htmlFor={this.props.name}>{this.props.title}</label>
        )}

        {this.props.required ? this.renderRequiredInput() : this.renderInput()}

        <div className="validation-error">{errorMessage}</div>
      </div>
    );
  }
}

ValidatingInput.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any
};

export default withFormsy(ValidatingInput);
