import { Spinner } from "react-bootstrap"
import classes from "./IconButton.module.scss"
import { MouseEvent } from "react"

type IconButtonProps = {
    className?: string
    icon: string
    isLoading?: boolean
    theme: "blue" | "mid-blue" | "grey" | "white" | "white-flat" | "blue-flat" | "icon-only"
    size?: keyof typeof iconSize
    onClick?: () => void
    disabled?: boolean
    ariaLabel?: string
    animatedHover?: boolean
    dynamicShadow?: boolean
    tooltip?: string
    count?: number
}

const IconButton = ({
    className = "",
    icon,
    isLoading = false,
    size = "medium",
    onClick,
    theme,
    disabled = false,
    ariaLabel,
    animatedHover = true,
    dynamicShadow = false,
    tooltip,
    count
}: IconButtonProps) => {
    const clickEvent = (event: MouseEvent) => {
        event.preventDefault()
        event.stopPropagation()
        onClick && !isLoading && onClick()
    }

    const customClasses = `
        ${classes.iconButton} 
        ${theme !== "icon-only" ? classes[theme] : ""} 
        ${classes[size]} 
        ${disabled ? classes.disabled : ""}
        ${animatedHover ? classes.animatedHover : ""}
		${dynamicShadow ? classes["dynamic-shadow"] : ""}
        ${className} 
    }`

    const countValue = count !== undefined ? Intl.NumberFormat("en", { notation: "compact", compactDisplay: "short" }).format(count) : ""
    const countString = count !== undefined && count > 999 ? "≈" + countValue : countValue

    return (
        <button
            className={`d-flex align-items-center justify-content-center pe-auto ${customClasses} position-relative`}
            onClick={clickEvent}
            aria-label={ariaLabel}
            disabled={disabled}
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title={tooltip ? tooltip : ""}
        >
            {isLoading ? (
                <div className="p-1 w-100 h-100">
                    <Spinner className="w-100 h-100" aria-label={`${icon}-spinner`} animation="border" />
                </div>
            ) : (
                <>
                    <i aria-label={icon} className={`${icon} ${iconSize[size]}`} />
                    <span
                        className={`badge d-flex position-absolute w-100 px-1 py-0 align-items-start align-self-end text-end justify-content-end ${classes.count}`}
                        aria-label={`${ariaLabel}-count`}
                    >
                        {countString}
                    </span>
                </>
            )}
        </button>
    )
}

const iconSize = {
    small: "fs-6",
    medium: "fs-5",
    large: "fs-4"
}

export default IconButton
