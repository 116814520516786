import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { extend, isNil } from 'lodash';
import { getLookup } from '../../legacy/api/lookupApi';
import {
  executeAuthAsyncGet,
  executeAuthAsyncPost,
  executeAsyncResetGet
} from '../../legacy/utility/asyncSupport';
import {
  showToastWarningMessage,
  showToastSuccessMessage,
  showToastErrorMessage
} from '../../legacy/api/toasterApi';
import { EMailSupport } from '../../legacy/utility/eMailSupport';
import EmailTemplateVersionsGrid from '../../legacy/components/admin/email/emailTemplateVersionsGrid';
import Dropdown from '../../legacy/components/common/dropdown';
import { EmailEditor } from '../../legacy/components/admin/email/emailEditor';

class EmailTemplateManager extends Component {
  constructor(props) {
    super(props);

    this.editorRef = null;

    this.onEditorContentChanged = this.onEditorContentChanged.bind(this);
    this.saveEmailTemplate = this.saveEmailTemplate.bind(this);
    this.saveEmailTemplateVersion = this.saveEmailTemplateVersion.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.createMarkup = this.createMarkup.bind(this);
    this.somethingChanged = this.somethingChanged.bind(this);
    this.onNavigateFragmentsClick = this.onNavigateFragmentsClick.bind(this);

    this.state = Object.assign({}, EmailTemplateManager.initialState(), {
      toSave: EmailTemplateManager.initialSaveState()
    });
  }

  static initialState() {
    return {
      subject: '',
      masterVariables: [],
      currentVariableIndex: 0,
      htmlFragments: [],
      canAddTemplate: false,
      canAddTemplateVersion: false,
      canUpdateTemplateVersion: false
    };
  }

  static initialSaveState() {
    return {
      MasterTemplateName: '',
      masterTemplateId: null,
      MasterTemplateVersionName: '',
      masterTemplateVersionId: null,
      emailTemplateId: null,
      emailTemplateName: '',
      emailTemplateVersionId: null,
      usageTypeId: null,
      languageId: null,
      subject: ''
    };
  }

  componentDidMount() {
    this.setEditor('');
    this.props.executeAsyncResetGet([
      'EMAIL_MASTER_TEMPLATES',
      'EMAIL_TEMPLATE_VERSION',
      'EMAIL_MASTER_TEMPLATE_VERSION',
      'EMAIL_TEMPLATE_USAGE_TYPES'
    ]);
    this.props.executeAuthAsyncGet(
      this.props.client,
      'email/MasterTemplates',
      'EMAIL_MASTER_TEMPLATES'
    );
    this.props.executeAuthAsyncGet(
      this.props.client,
      'Lookup/TemplateUsageTypes',
      'EMAIL_TEMPLATE_USAGE_TYPES'
    );
  }

  componentWillUnmount() {
    this.setState(EmailTemplateManager.initialState());
    this.setEditor('');
    this.props.executeAsyncResetGet([
      'EMAIL_MASTER_TEMPLATES',
      'EMAIL_TEMPLATE_VERSION',
      'EMAIL_MASTER_TEMPLATE_VERSION',
      'EMAIL_TEMPLATE_USAGE_TYPES'
    ]);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const masterTemplateVersion =
      nextProps.EMAIL_MASTER_TEMPLATE_VERSION &&
      nextProps.EMAIL_MASTER_TEMPLATE_VERSION.result;

    if (
      !isNil(masterTemplateVersion) &&
      (isNil(this.props.EMAIL_MASTER_TEMPLATE_VERSION.result) ||
        masterTemplateVersion.EmailMasterTemplateVersionId !==
        this.props.EMAIL_MASTER_TEMPLATE_VERSION.result
          .EmailMasterTemplateVersionId)
    ) {
      this.setState({
        masterVariables: EMailSupport.extractVariablesFromContent(
          masterTemplateVersion.ContentHtml
        ),
        currentVariableIndex: 0
      });

      const { ContentHtml, MasterTemplateName } = masterTemplateVersion;
      this.setEditor(ContentHtml);
      this.somethingChanged({ ContentHtml, MasterTemplateName });
    } else {
      const templateVersion =
        nextProps.EMAIL_TEMPLATE_VERSION &&
        nextProps.EMAIL_TEMPLATE_VERSION.result;

      if (
        !isNil(templateVersion) &&
        (isNil(this.props.EMAIL_TEMPLATE_VERSION.result) ||
          templateVersion.EmailTemplateVersionId !==
          this.props.EMAIL_TEMPLATE_VERSION.result.EmailTemplateVersionId)
      ) {
        let data;
        try {
          data = JSON.parse(templateVersion.JsonContent);
        } catch (e) {
          data = {
            htmlFragments: [],
            subject: null
          };

          showToastErrorMessage('Could not load fragment: ' + e.message);
        }

        this.setState({
          htmlFragments: data.fragments,
          currentVariableIndex: 0,
          subject: data.subject
        });

        const {
          EmailTemplateVersionId,
          Name,
          TemplateUsageTypeId
        } = templateVersion;
        this.somethingChanged({
          emailTemplateVersionId: EmailTemplateVersionId,
          emailTemplateName: Name,
          usageTypeId: TemplateUsageTypeId,
          subject: data.subject
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const state = this.state;
    const currentVariable = state.masterVariables[state.currentVariableIndex];
    const content =
      (currentVariable && state.htmlFragments[currentVariable]) || '';
    // const prevContent =
    //   (currentVariable && prevState.htmlFragments[currentVariable]) || '';
    // if (content !== prevContent)
    this.setEditor(content);
  }

  setEditor = (content) => {
    this.editorRef.setHtml(content);
  };

  onEditorContentChanged(newContent) {
    const state = this.state;
    const currentVariable = state.masterVariables[state.currentVariableIndex];
    const newFragments = extend({}, state.htmlFragments);
    newFragments[currentVariable] = newContent;
    this.setState({ htmlFragments: newFragments });
  }

  somethingChanged(changedPropertiesObject) {
    const newToSave = Object.assign(
      {},
      this.state.toSave,
      changedPropertiesObject
    );
    const templateVersionDataGood =
      !isNil(newToSave.languageId) &&
      !isNil(newToSave.usageTypeId) &&
      !isNil(newToSave.emailTemplateId);

    this.setState({
      canAddTemplateVersion: templateVersionDataGood,
      canUpdateTemplateVersion: templateVersionDataGood,
      toSave: newToSave
    });
  }

  onNavigateFragmentsClick(n) {
    const len = this.state.masterVariables.length;
    const newIndex =
      (((this.state.currentVariableIndex + n) % len) + len) % len;

    this.setState({ currentVariableIndex: newIndex });
  }

  onTemplateNameChanged = (e) => {
    const emailTemplateName = e.target.value;
    this.somethingChanged({
      emailTemplateName
    });
    this.setState({ canAddTemplate: !!emailTemplateName });
  };

  onSubjectChange = (e) => {
    this.somethingChanged({
      subject: e.target.value
    });
  };

  saveEmailTemplate() {
    const data = {
      Name: this.state.toSave.emailTemplateName,
      MasterTemplateId: this.state.toSave.masterTemplateId
    };

    this.props.executeAuthAsyncPost(
      this.props.client,
      'email/SaveTemplate',
      'SAVE_EMAIL_TEMPLATE',
      data,
      (resultText) => {
        showToastSuccessMessage(resultText);
        this.grid.rebind();
      }
    );
  }

  saveEmailTemplateVersion(toSave) {
    const htmlContent = this.generateNewHtml();
    const hiddenElement = document.createElement('html');
    hiddenElement.innerHTML = htmlContent;
    const html = hiddenElement.innerHTML;
    // We have to rebuild the correct 'whole' page content, since the editor strips away <html>, <head> and <body> tags
    // Be careful: if you change this, you have to change the corresponding part in onMasterTemplateLoaded, to keep in sync operations
    const finalHtmlContent = `<!doctype html>
      <html lang='en'>
        ${html}
      </html>`;

    const {
      emailTemplateId,
      masterTemplateId,
      usageTypeId,
      languageId,
      emailTemplateVersionId,
      templateName,
      subject
    } = toSave;

    if (usageTypeId !== 4) {
      if (!EMailSupport.validateClientVariables(finalHtmlContent)) {
        showToastWarningMessage(
          'Check the names of the variables used in the template. There is an error.'
        );
      }

      if (!EMailSupport.validateClientVariables(this.state.subject)) {
        showToastWarningMessage(
          'Check the names of the variables used in the subject. There is an error.'
        );
      }
    }

    const data = {
      JsonContent: JSON.stringify({
        fragments: this.state.htmlFragments,
        subject: subject
      }),
      Name: templateName,
      MasterTemplateId: masterTemplateId,
      EMailTemplateUsageTypeId: usageTypeId,
      EmailTemplateId: emailTemplateId,
      LanguageId: languageId,
      EmailTemplateVersionId: emailTemplateVersionId
    };

    this.props.executeAuthAsyncPost(
      this.props.client,
      'email/SaveTemplate',
      'SAVE_EMAIL_TEMPLATE',
      data,
      (resultText) => {
        showToastSuccessMessage(resultText);
        this.grid.rebind();
      }
    );
  }

  generateNewHtml() {
    if (
      this.props.EMAIL_MASTER_TEMPLATE_VERSION &&
      this.props.EMAIL_MASTER_TEMPLATE_VERSION.result
    ) {
      let html = this.props.EMAIL_MASTER_TEMPLATE_VERSION.result.ContentHtml;

      this.state.masterVariables.forEach((variableName) => {
        const fragment = this.state.htmlFragments[variableName] || '';

        html = html.replace('{{' + variableName + '}}', fragment);
      });
      return html;
    }
    return '';
  }

  createMarkup() {
    return {
      __html: this.generateNewHtml()
    };
  }

  onSaveClick() {
    this.saveEmailTemplateVersion(this.state.toSave)
  }

  render() {
    const {
      MasterTemplateName,
      MasterTemplateVersionName,
      masterTemplateId,
      usageTypeId,
      languageId,
      emailTemplateName,
      subject
    } = this.state.toSave;
    const templateVersionsGrid = isNil(masterTemplateId) ? (
      ''
    ) : (
      <div className="col-12">
        <label>All Email Templates for "{MasterTemplateName}":</label>
        <EmailTemplateVersionsGrid
          client={this.props.client}
          emailMasterTemplateId={masterTemplateId}
          gridKey={this.state.gridKey}
          onRowSelected={(tuple) => {
            const { emailTemplateVersionId } = tuple; // emailTemplateId,
            this.setEditor('');
            this.somethingChanged(tuple);

            if (!isNil(emailTemplateVersionId)) {
              this.props.executeAsyncResetGet('EMAIL_TEMPLATE_VERSION');
              this.props.executeAuthAsyncGet(
                this.props.client,
                'email/TemplateVersion',
                'EMAIL_TEMPLATE_VERSION',
                { emailTemplateVersionId }
              );
            }
          }}
          ref={(me) => {
            this.grid = me;
          }}
        />
      </div>
    );

    const addEmailtemplateRow = isNil(masterTemplateId) ? (
      ''
    ) : (
      <div className="row">
        <div className="col-sm-2" style={{ padding: '30px' }}>
          <button
            className="btnDefault btn btn-default"
            onClick={() => {
              this.setState({
                toSave: { ...this.state.toSave, emailTemplateId: null }
              });
              this.saveEmailTemplate();
            }}
            disabled={!this.state.canAddTemplate}
          >
            Add New Email Template
          </button>
        </div>
        <div className="col-sm-2" style={{ padding: '30px' }}>
          <label>Email Template name:</label>
          <input
            style={{ width: '300px' }}
            value={emailTemplateName}
            onChange={this.onTemplateNameChanged}
          />
        </div>
      </div>
    );

    return (
      <div style={{ color: 'black' }}>
        <div className="row">
          <div className="col-sm-2" style={{ padding: '30px' }}>
            <Dropdown
              title={MasterTemplateName || 'Master Template'}
              data={this.props.EMAIL_MASTER_TEMPLATES.result}
              onClick={(id, text) => {
                this.setEditor('');
                this.setState(EmailTemplateManager.initialState());
                this.somethingChanged(
                  Object.assign({}, EmailTemplateManager.initialSaveState(), {
                    masterTemplateId: id,
                    MasterTemplateName: text
                  })
                );
                this.props.executeAsyncResetGet([
                  'EMAIL_MASTER_TEMPLATE_VERSIONS',
                  'EMAIL_MASTER_TEMPLATE_VERSION'
                ]);
                this.props.executeAuthAsyncGet(
                  this.props.client,
                  'email/MasterTemplateVersions',
                  'EMAIL_MASTER_TEMPLATE_VERSIONS',
                  { masterTemplateId: id }
                );
              }}
            />
          </div>
          <div className="col-sm-2" style={{ padding: '30px' }}>
            <Dropdown
              title={MasterTemplateVersionName || 'Master Template Version'}
              data={this.props.EMAIL_MASTER_TEMPLATE_VERSIONS.result}
              onClick={(id, text) => {
                this.setEditor('');
                this.somethingChanged({
                  masterTemplateVersionId: id,
                  MasterTemplateVersionName: text
                });
                this.props.executeAsyncResetGet(
                  'EMAIL_MASTER_TEMPLATE_VERSION'
                );
                this.props.executeAuthAsyncGet(
                  this.props.client,
                  'email/MasterTemplateVersion',
                  'EMAIL_MASTER_TEMPLATE_VERSION',
                  { masterTemplateVersionId: id }
                );
              }}
            />
          </div>
        </div>
        {addEmailtemplateRow}
        <div className="row" key={'row' + this.state.gridKey}>
          {templateVersionsGrid}
        </div>
        <div className="row">
          <div className="col-6" style={{ padding: '30px' }}>
            <label>Preview:</label>
            <div
              style={{
                overflow: 'scroll',
                border: 'solid black 1px',
                width: '100%',
                minHeight: '733px'
              }}
              dangerouslySetInnerHTML={this.createMarkup()}
            ></div>
          </div>
          <div className="col-6" style={{ padding: '30px' }}>
            <button
              className="btnDefault btn btn-default pull-left"
              onClick={() => this.onNavigateFragmentsClick(-1)}
            >
              Prev
            </button>
            <button
              className="btnDefault btn btn-default float-end"
              onClick={() => this.onNavigateFragmentsClick(1)}
            >
              Next
            </button>
            <div style={{ textAlign: 'center' }}>
              {this.state.masterVariables[this.state.currentVariableIndex]}
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <EmailEditor
              ref={(editor) => (this.editorRef = editor)}
              onChange={this.onEditorContentChanged}
            ></EmailEditor>
            <br />
            <br />
            <div className="well">
              <div className="row">
                <label>Subject:&nbsp;&nbsp;</label>
                <input
                  style={{ width: '400px' }}
                  value={subject}
                  onChange={this.onSubjectChange}
                />
              </div>
              <br />
              <br />
              <div className="row">
                <Dropdown
                  title="Usage"
                  idProp="Id"
                  textProp="Description"
                  selectedItemId={usageTypeId}
                  disabled={!masterTemplateId}
                  data={this.props.EMAIL_TEMPLATE_USAGE_TYPES.result}
                  onClick={(i) => this.somethingChanged({ usageTypeId: i })}
                />
              </div>
              <div className="row">
                <Dropdown
                  title="Language"
                  idProp="Id"
                  textProp="Description"
                  selectedItemId={languageId}
                  disabled={!masterTemplateId}
                  data={this.props.availableLanguages.results}
                  onClick={(i) => this.somethingChanged({ languageId: i })}
                />
              </div>
              <br />
              <br />
              <button
                className="btnDefault btn btn-default"
                onClick={() => {
                  this.setState({
                    toSave: {
                      ...this.state.toSave,
                      emailTemplateVersionId: null
                    }
                  });
                  this.saveEmailTemplateVersion({
                    ...this.state.toSave,
                    emailTemplateVersionId: null
                  });
                }}
                disabled={!this.state.canAddTemplateVersion}
              >
                Add New Email Template Version
              </button>
              <button
                className="btnDefault btn btn-default"
                onClick={this.onSaveClick}
                disabled={!this.state.canUpdateTemplateVersion}
              >
                Update Email Template Version
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { asyncReducer, lookupReducer } = state;
  const {
    EMAIL_MASTER_TEMPLATES,
    EMAIL_TEMPLATE_USAGE_TYPES,
    EMAIL_MASTER_TEMPLATE_VERSION,
    EMAIL_MASTER_TEMPLATE_VERSIONS,
    EMAIL_TEMPLATE_VERSION
  } = asyncReducer;
  const { availableLanguages } = lookupReducer;
  return {
    EMAIL_MASTER_TEMPLATES,
    EMAIL_MASTER_TEMPLATE_VERSIONS,
    EMAIL_MASTER_TEMPLATE_VERSION,
    EMAIL_TEMPLATE_USAGE_TYPES,
    EMAIL_TEMPLATE_VERSION,
    availableLanguages
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    executeAuthAsyncGet,
    executeAuthAsyncPost,
    executeAsyncResetGet,
    getLookup
  })(EmailTemplateManager)
);
