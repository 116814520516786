import StandardButton from "../../../../library/buttons/StandardButton/StandardButton"
import FlowGraph from "../../../../library/flow/FlowGraph"
import DataField from "../../../../types/DataField"
import Lookup from "../../../../types/Lookup"
import { FileEntityExportConfig } from "../../../integration/types/fileEntityExport/FileExportConfig"
import DataUpdatedWorkflowRule from "../../types/DataUpdatedWorkflowRule"
import LegacySmsTemplate from "../../types/LegacySmsTemplate"
import ScheduledWorkflowRule from "../../types/ScheduledWorkflowRule"
import ActionNode from "./ActionNode"
import DataUpdatedTriggerNode from "./DataUpdatedTriggerNode"
import FiltersNode from "./FiltersNode"
import ScheduledTriggerNode from "./ScheduledTriggerNode"
import classes from "./WorkflowFlow.module.scss"

type WorkflowFlowProps = {
    fields: DataField[]
    lookups: Lookup[]
    smsTemplates: LegacySmsTemplate[]
    fileEntityExportConfigs: FileEntityExportConfig[]
    workflowRule: ScheduledWorkflowRule | DataUpdatedWorkflowRule
    onBackClicked: () => void
}

const HORIZONTAL_SPACING = 300

const WorkflowFlow = ({ fields, lookups, smsTemplates, fileEntityExportConfigs, workflowRule, onBackClicked }: WorkflowFlowProps) => {
    const { hardCodedNodes, hardCodedEdges } =
        workflowRule.type === "SCHEDULE"
            ? getHardCodedFlowForScheduledRule(workflowRule, fields, lookups)
            : getHardCodedFlowForDataUpdatedRule(workflowRule, fields, lookups)

    const actionNodeOffset = (workflowRule.actions.length - 1) * (HORIZONTAL_SPACING / 2)

    const actionNodes = workflowRule.actions
        .map((action, index) => {
            if ("filters" in action && action.filters.length > 0) {
                return [
                    {
                        id: `${action.actionReference}-filters`,
                        position: { x: index * HORIZONTAL_SPACING - actionNodeOffset, y: 400 },
                        children: <FiltersNode filterType="action" filters={action.filters} fields={fields} lookups={lookups} />
                    },
                    {
                        id: `${action.actionReference}-action`,
                        position: { x: index * HORIZONTAL_SPACING - actionNodeOffset, y: 600 },
                        children: (
                            <ActionNode
                                actionType={action.type}
                                action={action}
                                fields={fields}
                                lookups={lookups}
                                smsTemplates={smsTemplates}
                                fileEntityExportConfigs={fileEntityExportConfigs}
                            />
                        )
                    }
                ]
            }

            return [
                {
                    id: `${action.actionReference}-action`,
                    position: { x: index * HORIZONTAL_SPACING - actionNodeOffset, y: 400 },
                    children: (
                        <ActionNode
                            actionType={action.type}
                            action={action}
                            fields={fields}
                            lookups={lookups}
                            smsTemplates={smsTemplates}
                            fileEntityExportConfigs={fileEntityExportConfigs}
                        />
                    )
                }
            ]
        })
        .reduce((acc, val) => acc.concat(val), [])

    const actionEdges = workflowRule.actions
        .map(action => {
            if ("filters" in action && action.filters.length > 0) {
                return [
                    {
                        sourceNodeId: workflowRule.type === "SCHEDULE" ? "base-filters" : "trigger",
                        targetNodeId: `${action.actionReference}-filters`
                    },
                    {
                        sourceNodeId: `${action.actionReference}-filters`,
                        targetNodeId: `${action.actionReference}-action`
                    }
                ]
            }
            return [
                {
                    sourceNodeId: workflowRule.type === "SCHEDULE" ? "base-filters" : "trigger",
                    targetNodeId: `${action.actionReference}-action`
                }
            ]
        })
        .reduce((acc, val) => acc.concat(val), [])

    return (
        <div className="position-relative d-flex flex-grow-1 bg-white">
            <div className={`position-absolute m-2 ${classes.onTop}`}>
                <StandardButton className="me-auto" iconClasses="far fa-chevron-double-left" label="Back" colour="white" onClick={onBackClicked} />
            </div>
            <FlowGraph nodes={hardCodedNodes.concat(actionNodes)} edges={hardCodedEdges.concat(actionEdges)} />
        </div>
    )
}

export default WorkflowFlow

const getHardCodedFlowForScheduledRule = (workflowRule: ScheduledWorkflowRule, fields: DataField[], lookups: Lookup[]) => ({
    hardCodedNodes: [
        {
            id: "trigger",
            position: { x: 0, y: 0 },
            children: <ScheduledTriggerNode trigger={workflowRule.trigger} />
        },
        {
            id: "base-filters",
            position: { x: 0, y: 150 },
            children: <FiltersNode filterType="base" filters={workflowRule.baseFilters} fields={fields} lookups={lookups} />
        }
    ],
    hardCodedEdges: [
        {
            sourceNodeId: "trigger",
            targetNodeId: "base-filters"
        }
    ]
})

const getHardCodedFlowForDataUpdatedRule = (workflowRule: DataUpdatedWorkflowRule, fields: DataField[], lookups: Lookup[]) => ({
    hardCodedNodes: [
        {
            id: "trigger",
            position: { x: 0, y: 0 },
            children: (
                <DataUpdatedTriggerNode
                    matchedBeforeUpdateFilters={workflowRule.matchingFiltersBeforeChange}
                    matchedAfterUpdateFilters={workflowRule.matchingFiltersAfterChange}
                    fields={fields}
                    lookups={lookups}
                />
            )
        }
    ],
    hardCodedEdges: []
})
