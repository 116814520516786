type SpinnerProps = {
    className?: string
    colour?: "blue" | "white"
    size?: "small" | "medium"
}

const Spinner = ({ className = "", colour = "white", size = "medium" }: SpinnerProps) => (
    <div className={className}>
        <div className={`spinner-border ${size === "small" ? "spinner-border-sm" : ""} text-${colour}`} role="status" />
    </div>
)

export default Spinner
