import * as types from '../actions/actionTypes';

export default function fieldMapReducer(state = [], action) {
    switch (action.type) {
        case types.LOAD_FIELD_MAP_SUCCESS:
        case types.NEW_FIELD_MAP_SUCCESS:
        case types.CREATE_FIELD_MAP_SUCCESS:
        case types.UPDATE_FIELD_MAP_SUCCESS:
        case types.DELETE_FIELD_MAP_SUCCESS:
        case types.CANCEL_FIELD_MAP_SUCCESS:
            return [...state, Object.assign({}, action.fieldMap)];
        default:
            return state;
    }
}