import DatePickerWithInput from "../Inputs/DatePickerWithInput"
import moment from "moment-timezone"
import { DATE_FORMAT_WITH_TIME, DEFAULT_DATE_FORMAT, DEFAULT_TIMEZONE } from "../helpers/dateUtils"

type DateInfoElementProps = {
    className: string
    value: string
    dateFormat: string
    timezone: string
    disabled: boolean
    onChange: (value: string) => void
    ariaLabel?: string
}

const DateInfoElement = ({ className, value, dateFormat, timezone = DEFAULT_TIMEZONE, disabled, onChange, ariaLabel }: DateInfoElementProps) => {
    const onDateValueChange = (date: Date) => {
        if (moment(date).valueOf() === 0) onChange(moment(date).valueOf().toString())
        else onChange(moment.tz(moment(date).format(DATE_FORMAT_WITH_TIME), timezone).valueOf().toString())
    }

    const datePassedToPicker = value && Number(value) && Number(value) > 0 ? new Date(moment(Number(value)).format()) : undefined

    return (
        <div className={`fs-4 ${className}`}>
            <DatePickerWithInput
                date={datePassedToPicker}
                format={dateFormat || DEFAULT_DATE_FORMAT}
                timezone={timezone}
                onDateChange={onDateValueChange}
                disabled={disabled}
                ariaLabel={ariaLabel}
            />
        </div>
    )
}

export default DateInfoElement
