import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authZ_Permissions } from '../../../constants';
import Dropdown from '../../common/dropdown';
import Select from 'react-select';
import { getLookup } from '../../../api/lookupApi';
import { orderBy, find, last, map } from 'lodash';
import { hasPermission } from '../../../utility/authZ';

class AddFieldMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldMap: {
        id: '',
        fileTypeId: '',
        recordInstance: '',
        destinationFieldId: '',
        sourceFieldNameOverride: '',
        sourceXPath: '',
        sourceFormat: '',
        staticValue: '',
        defaultValue: '',
        useDefaultForInsertOnly: '',
        foreignKeyLookupId: '',
        allowCascadeInsert: '',
        allowEmptyString: '',
        prohibitUpdates: '',
        stripCharactersToCompare: '',
        isNativeKey: '',
        isBaseRecordId: '',
        isContent: '',
        isExtensionField: '',
        isMandatory: '',
        isActive: '',
        concatenationDelimiter: '',
        concatenationOrder: '',
        processMultipleLookupResults: ''
      },
      validFormats: [
        'dd.MM.yyyy',
        'dd-MM-yyyy',
        'dd-MMM-yy',
        'dd-MM-yy',
        'dd-MMM-yyyy',
        'MM-dd-yyyy',
        'MM-dd-yy',
        'MMM-dd-yy',
        'MMM-dd-yyyy',
        'yyyy-MM-dd',
        'yy-MM-dd',
        'ddMMyyyy',
        'ddMMMyy',
        'ddMMyy',
        'ddMMMyyyy',
        'MMddyyyy',
        'MMddyy',
        'MMMddyy',
        'MMMddyyyy',
        'yyyyMMdd',
        'yyMMdd',
        'dd/MM/yyyy',
        'dd/MMM/yy',
        'dd/MM/yy',
        'dd/MMM/yy',
        'dd/MMM/yyyy',
        'MM/dd/yyyy',
        'MM/dd/yy',
        'MMM/dd/yy',
        'yyyy.MM.dd',
        'yyyy/MM/dd'
      ]
    };

    this.onChange = this.onChange.bind(this);
    this.onChecked = this.onChecked.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onStaticDefaultChange = this.onStaticDefaultChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ fieldMap: Object.assign({}, nextProps.fieldMap) });
  }

  componentDidMount() {
    this.props.getLookup(
      this.props.client,
      'import-configuration/destination-fields/lookup',
      'destinationFields'
    );
    this.props.getLookup(
      this.props.client,
      'import-configuration/foreign-keys/lookup',
      'foreignKeys'
    );
  }

  onChange(event) {
    const field = event.target.name;
    const fieldMap = Object.assign({}, this.state.fieldMap);

    fieldMap[field] = event.target.value;

    return this.setState({ fieldMap: fieldMap });
  }

  onStaticDefaultChange(event) {
    const field = event.target.name;
    const fieldMap = Object.assign({}, this.state.fieldMap);

    fieldMap[field] = event.target.value;

    if (field === 'staticValue') {
      fieldMap['defaultValue'] = '';
    } else {
      fieldMap['staticValue'] = '';
    }

    return this.setState({ fieldMap: fieldMap });
  }

  onSelectChange(name, value) {
    const fieldMap = Object.assign({}, this.state.fieldMap);

    fieldMap[name] = value;

    return this.setState({ fieldMap: fieldMap });
  }

  onDestinationFieldMapChanged(select) {
    const value = select && select.value;
    const fieldMap = Object.assign({}, this.state.fieldMap);

    fieldMap['destinationFieldId'] = value;

    return this.setState({ fieldMap: fieldMap });
  }

  onChecked(event) {
    const field = event.target.name;
    const fieldMap = Object.assign({}, this.state.fieldMap);

    fieldMap[field] = event.target.checked;

    return this.setState({ fieldMap: fieldMap });
  }

  onSave() {
    this.props.onSave(this.state.fieldMap);
  }

  isFormValid() {
    const { destinationFieldId, sourceFormat } = this.state.fieldMap;

    if (Number(destinationFieldId) === 0) {
      return false;
    }

    if (sourceFormat && sourceFormat.length > 0) {
      if (!this.state.validFormats.includes(sourceFormat)) {
        return false;
      }
    }

    return true;
  }

  render() {
    const {
      id,
      recordInstance,
      destinationFieldId,
      sourceFieldNameOverride,
      sourceXPath,
      sourceFormat,
      staticValue,
      defaultValue,
      useDefaultForInsertOnly,
      foreignKeyLookupId,
      allowCascadeInsert,
      allowEmptyString,
      prohibitUpdates,
      stripCharactersToCompare,
      isNativeKey,
      isBaseRecordId,
      isContent,
      isExtensionField,
      isMandatory,
      isActive,
      concatenationDelimiter,
      concatenationOrder,
      processMultipleLookupResults
    } = this.state.fieldMap;

    const header = id
      ? `Edit Field Map (Id:${id}) (${this.props.fileTypeName})`
      : 'Add Field Map';
    const isValid = this.isFormValid();

    let isDisabled = false;

    let buttons = (
      <div className="form-group">
        <button
          className="btnDefault btn btn-default"
          disabled={!isValid}
          onClick={this.onSave}
          tabIndex="16"
        >
          Save
        </button>
        <button
          className="btn btn-default"
          onClick={this.props.onCancel}
          tabIndex="17"
        >
          Cancel
        </button>
      </div>
    );

    if (
      hasPermission(
        this.props.permissions,
        authZ_Permissions.FileImportAdminRead
      ) &&
      !hasPermission(
        this.props.permissions,
        authZ_Permissions.FileImportAdminUpdate
      )
    ) {
      isDisabled = true;
      buttons = (
        <div className="form-group">
          <button
            className="btn btn-default"
            onClick={this.props.onCancel}
            tabIndex="17"
          >
            Back
          </button>
        </div>
      );
    }

    const destinationFields = map(
      orderBy(this.props.destinationFields.results, ['TableName', 'FieldName']),
      (m) => {
        return { label: m.name, value: m.id };
      }
    );
    const destinationField = find(
      this.props.destinationFields.results,
      (f) => f.id === destinationFieldId
    );
    const selectedFieldMap = destinationFieldId
      ? {
        value: destinationFieldId,
        label: destinationField && destinationField.name
      }
      : null;

    return (
      <div>
        <div key="field-map-form" className="card below-grid-form">
          <h3>{header}</h3>

          <div
            className="row form-group required"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4" title={'Destination Table'}>
              <div className="form-control-feedback-wrapper warning">
                <span className="form-control-feedback">
                  <i className="fa fa-asterisk" />
                </span>
              </div>
              <Select
                name="destination-field-map"
                title={'Destination Table'}
                isMulti={false}
                value={selectedFieldMap}
                options={destinationFields}
                isClearable={true}
                placeholder="Destination Table"
                onChange={this.onDestinationFieldMapChanged.bind(this)}
                required
              />
            </div>
            <div className="col-md-4">
              <label>
                <input
                  name="isNativeKey"
                  type="checkbox"
                  checked={isNativeKey}
                  onChange={this.onChecked}
                  tabIndex="12"
                  disabled={isDisabled}
                />{' '}
                Is Native Key
              </label>
            </div>
          </div>

          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <Dropdown
                scroll={true}
                title="Foreign Key"
                idProp="id"
                selectedItemId={foreignKeyLookupId}
                textProp="name"
                data={this.props.foreignKeys.results}
                name="foreignKeyLookupId"
                onClick={(id) => this.onSelectChange('foreignKeyLookupId', id)}
                tabIndex="2"
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-4">
              <label>
                <input
                  name="isMandatory"
                  type="checkbox"
                  checked={isMandatory}
                  onChange={this.onChecked}
                  tabIndex="13"
                  disabled={isDisabled}
                />{' '}
                Is Mandatory
              </label>
            </div>
          </div>

          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <input
                type="number"
                value={recordInstance}
                name="recordInstance"
                className="form-control"
                tabIndex="3"
                placeholder="Record Instance"
                onChange={this.onChange}
                title="Record Instance (unique for all table dependencies)"
                style={{ width: '480px' }}
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-4">
              <label>
                <input
                  name="isActive"
                  type="checkbox"
                  checked={isActive}
                  onChange={this.onChecked}
                  tabIndex="14"
                  disabled={isDisabled}
                />{' '}
                Is Active
              </label>
            </div>
          </div>

          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <input
                type="text"
                value={sourceFieldNameOverride}
                name="sourceFieldNameOverride"
                className="form-control"
                tabIndex="4"
                placeholder="Source Field Name"
                onChange={this.onChange}
                title="Source Field Name"
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-4">
              <label>
                <input
                  name="useDefaultForInsertOnly"
                  type="checkbox"
                  checked={useDefaultForInsertOnly}
                  onChange={this.onChecked}
                  tabIndex="15"
                  disabled={isDisabled}
                />{' '}
                Default for Insert only
              </label>
            </div>
          </div>

          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <input
                type="text"
                value={staticValue}
                name="staticValue"
                className="form-control"
                tabIndex="5"
                placeholder="Static Value"
                onChange={this.onStaticDefaultChange}
                title="Static Value"
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-4">
              <label>
                <input
                  name="allowCascadeInsert"
                  type="checkbox"
                  checked={allowCascadeInsert}
                  onChange={this.onChecked}
                  tabIndex="16"
                  disabled={isDisabled}
                />{' '}
                Allow Cascade Insert
              </label>
            </div>
          </div>

          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <input
                type="text"
                value={defaultValue}
                name="defaultValue"
                className="form-control"
                tabIndex="6"
                placeholder="Default Value"
                onChange={this.onStaticDefaultChange}
                title="Default Value"
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-4">
              <label>
                <input
                  name="allowEmptyString"
                  type="checkbox"
                  checked={allowEmptyString}
                  onChange={this.onChecked}
                  tabIndex="17"
                  disabled={isDisabled}
                />{' '}
                Allow Empty String
              </label>
            </div>
          </div>

          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <input
                type="text"
                value={sourceXPath}
                name="sourceXPath"
                className="form-control"
                tabIndex="7"
                placeholder="Source XPath"
                onChange={this.onChange}
                title="Source XPath"
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-4">
              <label>
                <input
                  name="prohibitUpdates"
                  type="checkbox"
                  checked={prohibitUpdates}
                  onChange={this.onChecked}
                  tabIndex="18"
                  disabled={isDisabled}
                />{' '}
                Prohibit Updates
              </label>
            </div>
          </div>

          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <input
                type="text"
                value={sourceFormat}
                name="sourceFormat"
                className="form-control"
                tabIndex="8"
                placeholder="Source Format"
                onChange={this.onChange}
                disabled={isDisabled}
                title="Source Format (valid entries dd.MM.yyyy, dd-MM-yyyy, dd-MMM-yy, dd-MM-yy, dd-MMM-yyyy, MM-dd-yyyy, MM-dd-yy, MMM-dd-yy, MMM-dd-yyyy, yyyy-MM-dd, yy-MM-dd, ddMMyyyy, ddMMMyy, ddMMyy, ddMMMyyyy, MMddyyyy, MMddyy, MMMddyy, MMMddyyyy, yyyyMMdd, yyMMdd, dd/MM/yyyy, dd/MMM/yy, dd/MM/yy, dd/MMM/yy, dd/MMM/yyyy, MM/dd/yyyy, MM/dd/yy, MMM/dd/yy, yyyy.MM.dd, yyyy/MM/dd)"
              />
            </div>
            <div className="col-md-4">
              <label>
                <input
                  name="isBaseRecordId"
                  type="checkbox"
                  checked={isBaseRecordId}
                  onChange={this.onChecked}
                  tabIndex="19"
                  disabled={isDisabled}
                />{' '}
                Is Base Record Id
              </label>
            </div>
          </div>

          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <input
                type="text"
                value={stripCharactersToCompare}
                name="stripCharactersToCompare"
                className="form-control"
                tabIndex="9"
                placeholder="Strip Compare Characters"
                onChange={this.onChange}
                title="Strip Compare Characters"
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-4">
              <label>
                <input
                  name="isContent"
                  type="checkbox"
                  checked={isContent}
                  onChange={this.onChecked}
                  tabIndex="20"
                  disabled={isDisabled}
                />{' '}
                Is Content
              </label>
            </div>
          </div>

          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <input
                type="text"
                value={concatenationDelimiter}
                name="concatenationDelimiter"
                className="form-control"
                tabIndex="10"
                placeholder="Concatenation Delimiter"
                onChange={this.onChange}
                title="Concatenation Delimiter"
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-4">
              <label>
                <input
                  name="isExtensionField"
                  type="checkbox"
                  checked={isExtensionField}
                  onChange={this.onChecked}
                  tabIndex="21"
                  disabled={isDisabled}
                />{' '}
                Is Extension Field
              </label>
            </div>
          </div>

          <div
            className="row form-group"
            style={{ marginRight: '-15px', marginLeft: '-15px' }}
          >
            <div className="col-md-4">
              <input
                type="number"
                value={concatenationOrder}
                name="concatenationOrder"
                className="form-control"
                tabIndex="11"
                placeholder="Concatenation Order"
                onChange={this.onChange}
                title="Concatenation Order"
                style={{ width: '480px' }}
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-4">
              <label>
                <input
                  name="processMultipleLookupResults"
                  type="checkbox"
                  checked={processMultipleLookupResults}
                  onChange={this.onChecked}
                  tabIndex="22"
                  disabled={isDisabled}
                />{' '}
                Process Multiple Lookup Results
              </label>
            </div>
          </div>

          {buttons}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let fieldMap = {
    id: '',
    fileTypeId: '',
    recordInstance: '',
    destinationFieldId: '',
    sourceFieldNameOverride: '',
    sourceXPath: '',
    sourceFormat: '',
    staticValue: '',
    defaultValue: '',
    useDefaultForInsertOnly: '',
    foreignKeyLookupId: '',
    allowCascadeInsert: '',
    allowEmptyString: '',
    prohibitUpdates: '',
    stripCharactersToCompare: '',
    isNativeKey: '',
    isBaseRecordId: '',
    isContent: '',
    isExtensionField: '',
    isMandatory: '',
    isActive: '',
    concatenationDelimiter: '',
    concatenationOrder: '',
    processMultipleLookupResults: ''
  };

  if (state.fieldMaps.length > 0) {
    fieldMap = last(state.fieldMaps);
  }

  return {
    fieldMap: fieldMap,
    destinationFields: state.lookupReducer.destinationFields,
    foreignKeys: state.lookupReducer.foreignKeys,
    permissions: state.authReducer.permissions
  };
}

export default connect(mapStateToProps, { getLookup })(AddFieldMap);
