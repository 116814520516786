import React from 'react';

import { DropDownList } from '@progress/kendo-react-dropdowns';

export default function dropdownFilterCell(data, defaultText) {
  return class extends React.Component {
    render() {
      const defaultItem = { text: defaultText, value: null };
      const valueItem = this.props.value
        ? data.find((f) => f.value === this.props.value)
        : null;
      return (
        <div className="k-filtercell">
          <DropDownList
            data={data}
            onChange={this.onChange}
            value={valueItem}
            defaultItem={defaultItem}
            textField="text"
            valueField="value"
          />
          <button
            className="k-button k-button-icon k-clear-button-visible"
            title="Clear"
            disabled={!this.hasValue(this.props.value)}
            onClick={this.onClearButtonClick}
          >
            <span className="k-icon k-i-filter-clear" />
          </button>
        </div>
      );
    }

    hasValue = (value) => Boolean(value && value.value !== null);

    onChange = (event) => {
      const hasValue = this.hasValue(event.target.value);
      this.props.onChange({
        value: hasValue ? event.target.value.value : '',
        operator: hasValue ? 'eq' : '',
        syntheticEvent: event.syntheticEvent
      });
    };

    onClearButtonClick = (event) => {
      event.preventDefault();
      this.props.onChange({
        value: null,
        operator: '',
        syntheticEvent: event
      });
    };
  };
}
