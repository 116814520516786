import moment from "moment"
import DataFieldDropdown from "../../../../../library/dropdowns/DataFieldDropdown"
import Dropdown from "../../../../../library/dropdowns/Dropdown"
import DatePickerWithInput from "../../../../../library/Inputs/DatePickerWithInput"
import Input from "../../../../../library/Inputs/Input"
import DataField from "../../../../../types/DataField"
import DataPrimitive from "../../../../../types/DataPrimitive"
import DropdownOption from "../../../../../types/DropdownOptions"
import Lookup from "../../../../../types/Lookup"
import { useState } from "react"
import { isValidNumber } from "../../../../../library/helpers/numberUtils"

type UpdateDataFieldDefinitionEntityProps = {
    filteredEntityFields: DataField[]
    entityFields: DataField[]
    lookups: Lookup[]
    id: string
    selectedField: DataField | undefined
    selectedValue: string
    selectedUpdateActionType: "set" | "increment"
    selectedSourceActionType: "value" | "field" | "now" | "unique id"
    onDataFieldDefinitionUpdated: (
        id: string,
        field: DataField | undefined,
        value: string,
        updateActionType: "set" | "increment",
        sourceActionType: "value" | "field" | "now" | "unique id"
    ) => void
    onDataFieldDefinitionRemoved: (id: string) => void
    isTextOnly?: boolean
}

const UpdateDataFieldDefinitionEntity = ({
    filteredEntityFields,
    entityFields,
    lookups,
    id,
    selectedField,
    selectedValue,
    selectedUpdateActionType,
    selectedSourceActionType,
    onDataFieldDefinitionUpdated,
    onDataFieldDefinitionRemoved,
    isTextOnly = false
}: UpdateDataFieldDefinitionEntityProps) => {
    const [dataField, setDataField] = useState<DataField | undefined>(entityFields.find(f => f.value === selectedValue))

    const onFieldSelected = (field: DataField | undefined) => {
        const updatedSelectedUpdateActionType = field?.type === DataPrimitive.NUMBER ? selectedUpdateActionType : "set"
        const updatedSelectedSourceActionType =
            field?.type !== DataPrimitive.TEXT && selectedSourceActionType === "unique id" ? "value" : selectedSourceActionType

        if (field && field.type === DataPrimitive.DATE) {
            onDataFieldDefinitionUpdated(id, field, moment(new Date()).format(), updatedSelectedUpdateActionType, updatedSelectedSourceActionType)
            return
        }
        onDataFieldDefinitionUpdated(id, field, "", updatedSelectedUpdateActionType, updatedSelectedSourceActionType)
    }

    const onValueSelected = (field: DataField | undefined) => {
        if (field) {
            setDataField(field)
            return onDataFieldDefinitionUpdated(id, selectedField, field.value, selectedUpdateActionType, selectedSourceActionType)
        }
        onDataFieldDefinitionUpdated(id, selectedField, "", selectedUpdateActionType, selectedSourceActionType)
    }

    const onUpdateActionTypeDropDownChanged = (option: DropdownOption<"set" | "increment">) => {
        if (option.value === "increment") {
            return onDataFieldDefinitionUpdated(id, selectedField, "", option.value, "value")
        }
        onDataFieldDefinitionUpdated(id, selectedField, "", option.value, selectedSourceActionType)
    }

    const onSourceActionTypeDropDownChanged = (option: DropdownOption<"value" | "field" | "now" | "unique id">) => {
        if (option.value === "now" || option.value === "unique id") {
            return onDataFieldDefinitionUpdated(id, selectedField, "", "set", option.value)
        }
        onDataFieldDefinitionUpdated(id, selectedField, "", selectedUpdateActionType, option.value)
    }

    const onRemoveClicked = () => onDataFieldDefinitionRemoved(id)

    const onDropdownValueChanged = (option: DropdownOption<string>) =>
        onDataFieldDefinitionUpdated(id, selectedField, option.value, selectedUpdateActionType, selectedSourceActionType)
    const onInputValueChanged = (value: string) => onDataFieldDefinitionUpdated(id, selectedField, value, selectedUpdateActionType, selectedSourceActionType)
    const onDataValueChanged = (date: Date) =>
        onDataFieldDefinitionUpdated(id, selectedField, moment(date).format(), selectedUpdateActionType, selectedSourceActionType)

    const getValueComponent = (selectedField: DataField) => {
        if (selectedSourceActionType === "unique id") {
            return <></>
        }

        if (selectedSourceActionType === "field") {
            return (
                <DataFieldDropdown
                    options={removeDefaultFields(getFieldsForSameType(entityFields, selectedField.type))}
                    onOptionSelected={onValueSelected}
                    selectedOption={dataField}
                    ariaLabel="update-entity-data-field"
                />
            )
        }

        if (selectedField.type === DataPrimitive.LOOKUP) {
            const lookupEntries = lookups.find(lookup => lookup.reference === selectedField.lookup)?.entries ?? []
            const options = lookupEntries
                .map(lookup => ({
                    label: lookup.name,
                    value: lookup.reference
                }))
                .sort((a, b) => a.label.localeCompare(b.label))
            return (
                <Dropdown
                    options={options}
                    onOptionSelected={onDropdownValueChanged}
                    selectedOption={options.find(o => o.value === selectedValue)}
                    fixedSize={true}
                    textAlign="left"
                />
            )
        }

        if (selectedField.type === DataPrimitive.BOOLEAN) {
            const options = [
                { label: "True", value: "true" },
                { label: "False", value: "false" }
            ]
            return (
                <Dropdown
                    options={options}
                    onOptionSelected={onDropdownValueChanged}
                    selectedOption={options.find(o => o.value === selectedValue)}
                    fixedSize={true}
                />
            )
        }

        if (selectedField.type === DataPrimitive.DATE && selectedSourceActionType === "value") {
            const initialValue = selectedValue === "" ? new Date() : new Date(selectedValue)
            return <DatePickerWithInput date={initialValue} onDateChange={onDataValueChanged} />
        }

        const shouldBeNumber = selectedUpdateActionType === "increment" || selectedSourceActionType === "now"

        return (
            <Input
                value={selectedValue}
                onChange={onInputValueChanged}
                isValid={isValidNumber(selectedValue) || !shouldBeNumber}
                placeholder="Please enter a value"
                ariaLabel="value-input"
            />
        )
    }

    if (isTextOnly) {
        return (
            <div className="d-flex gap-1">
                <span className="text-capitalize">{selectedUpdateActionType}</span>{" "}
                <span className={`fw-bold${selectedField ? "fw-danger" : ""}`}>{selectedField?.label ?? "(not set)"}</span>
                {selectedField && (
                    <>
                        {selectedUpdateActionType === "set" ? "to" : "by"}{" "}
                        <span className={`fw-bold ${selectedValue ?? "fw-danger"}`}>{selectedValue ?? "(not set)"}</span>
                    </>
                )}
            </div>
        )
    }

    const sourceActionDropDownOptions: DropdownOption<"value" | "field" | "now" | "unique id">[] = (() => {
        switch (selectedField?.type) {
            case DataPrimitive.DATE:
                return dateSourceActionDropDownOptions
            case DataPrimitive.TEXT:
                return textSourceActionDropDownOptions
            default:
                return defaultSourceActionDropDownOptions
        }
    })()

    const getSourceActionDropdown = () => {
        if (selectedUpdateActionType !== "set") {
            return <></>
        }

        return (
            <Dropdown
                options={sourceActionDropDownOptions}
                onOptionSelected={onSourceActionTypeDropDownChanged}
                selectedOption={sourceActionDropDownOptions.find(v => v.value === selectedSourceActionType)}
                fixedSize={true}
                textAlign="left"
                ariaLabel="source-action-type-dropdown"
            />
        )
    }

    const removeDefaultFields = (fields: DataField[]) => {
        return fields.filter(f => f.value !== "archived" && f.value !== "client" && f.value !== "entity_type" && f.value !== "reference")
    }

    const getFieldsForSameType = (fields: DataField[], selectedType: DataPrimitive) => {
        if (selectedType === DataPrimitive.LOOKUP || selectedType === DataPrimitive.TEXT) {
            return fields.filter(f => f.type === DataPrimitive.TEXT || f.type === DataPrimitive.LOOKUP)
        }
        return fields.filter(f => f.type === selectedType)
    }

    return (
        <div className="d-flex align-items-between gap-2">
            <div className="d-flex gap-2 align-items-center me-auto">
                <span className="text-grey text-uppercase">
                    <Dropdown
                        options={dropDownOptions}
                        onOptionSelected={onUpdateActionTypeDropDownChanged}
                        selectedOption={dropDownOptions.find(v => v.value === selectedUpdateActionType)}
                        fixedSize={true}
                        textAlign="left"
                        disabled={selectedField === undefined || selectedField.type !== DataPrimitive.NUMBER}
                    />
                </span>
                <DataFieldDropdown options={filteredEntityFields} onOptionSelected={onFieldSelected} selectedOption={selectedField} ariaLabel="field-to-set" />
                {selectedField && (
                    <>
                        <span className="text-grey text-uppercase">{selectedUpdateActionType === "set" ? "to" : "by"}</span>
                        {getSourceActionDropdown()}
                        {selectedSourceActionType === "now" && <span className="text-grey text-uppercase">plus</span>}
                        {getValueComponent(selectedField)}
                        {selectedSourceActionType === "now" && <span className="text-grey text-uppercase">days</span>}
                    </>
                )}
            </div>
            <div className="d-flex gap-2 align-items-center">
                <div className="ms-auto ps-2 me-3 border-end align-self-stretch" />
                <div role="button" className="px-2" onClick={onRemoveClicked} aria-label="filter-remove">
                    <i className="fal fa-trash-alt" />
                </div>
            </div>
        </div>
    )
}

export default UpdateDataFieldDefinitionEntity

const dropDownOptions: DropdownOption<"set" | "increment">[] = [
    {
        label: "Set",
        value: "set"
    },
    {
        label: "Increment",
        value: "increment"
    }
]

const defaultSourceActionDropDownOptions: DropdownOption<"value" | "field">[] = [
    {
        label: "Value",
        value: "value"
    },
    {
        label: "Field",
        value: "field"
    }
]

const dateSourceActionDropDownOptions: DropdownOption<"value" | "field" | "now">[] = [
    ...defaultSourceActionDropDownOptions,
    {
        label: "Now",
        value: "now"
    }
]

const textSourceActionDropDownOptions: DropdownOption<"value" | "field" | "unique id">[] = [
    ...defaultSourceActionDropDownOptions,
    {
        label: "Unique ID",
        value: "unique id"
    }
]
