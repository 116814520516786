import GridColumnConfigDto from "./types/GridColumnConfigDto"

export const defaultDisplayedFields: GridColumnConfigDto[] = [
    { fieldName: "name", ordinal: 0 },
    { fieldName: "reference", ordinal: 1 },
    { fieldName: "balance", ordinal: 2 },
    { fieldName: "overdue", ordinal: 3 }
]

export const defaultDisplayedEntityFields: GridColumnConfigDto[] = [{ fieldName: "reference", ordinal: 0 }]
