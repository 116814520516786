import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  toDataSourceRequestString,
  toDataSourceRequest
} from '@progress/kendo-data-query';
import { isEqual, remove } from 'lodash';
import update from 'immutability-helper';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { InlineEditCell } from './InlineEditCell';
import { resetGridState, storeGridState } from '../../../actions/gridCacheActions';
import buildParameterString from '../../../utility/buildParameterString';
import displayError from '../../../utility/error';
import { webApiInterface } from '../../../api/webApiInterface';
import ComponentLoader from '../ComponentLoader';
import DetailColumnCell from './DetailColumnCell';

const AbortController = window.AbortController;

class StatefullGrid extends React.Component {
  controller = new AbortController();
  gridRef = null;

  editField = 'inEdit';

  constructor(props) {
    super(props);

    const dataState = {};

    if (props.defaultField) {
      dataState.sort = [props.defaultField];
    }

    if (props.pageable) {
      dataState.take = props.pageSize || (this.props.defaultToThousand ? 1000 : 50);
      dataState.skip = 0;
    }

    if (props.initialFilter) dataState.filter = props.initialFilter;

    this.state = {
      expandedRowIds: [],

      originalRowData: null,
      isEditingMode: false,
      isDisabledRow: false,

      dataState,
      data: [],
      total: 0,
      loading: false
    };

    if (props.editableRow)
      this.InlineEditCell = InlineEditCell({
        edit: this.enterEdit,
        remove: this.remove,

        add: this.add,
        discard: this.discard,

        update: this.update,
        cancel: this.cancel,

        editField: this.editField,
        idField: props.editableRow.id
      });
  }

  componentDidMount() {
    if (this.props.stateKey) {
      this.props.dispatch(resetGridState(this.props.stateKey));
    }
    this.handleDataStateChange({ dataState: this.state.dataState });
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  componentDidUpdate(prevProps) {
    const isManualRefresh =
      !isNaN(this.props.refresh) && prevProps.refresh !== this.props.refresh;
    const isPayloadRefresh = !isEqual(
      prevProps.additionalRequestPayload,
      this.props.additionalRequestPayload
    );
    const isManualFilterChange = !isEqual(prevProps.filter, this.props.filter);

    if (isManualRefresh || isPayloadRefresh || isManualFilterChange) {
      // reset page and re-fetch
      const filter = isManualFilterChange
        ? this.props.filter
        : this.state.dataState.filter;

      const dataState = { ...this.state.dataState, filter, skip: 0 };
      this.fetchData(dataState);
    }
  }

  expandCell = (props) => (
    <DetailColumnCell {...props} expandChange={this.expandChange} />
  );

  render() {
    const expandableRow = this.props.expandableRow;
    const editableRow = this.props.editableRow;
    const checkableRow = this.props.checkableRow;
    const taskGrid = this.props.taskGrid

    return (
      <>
        <Grid
          {...this.props}
          total={this.state.total}
          ref={(r) => (this.gridRef = r)}
          data={this.state.data.map((dataItem) => ({
            ...dataItem,
            selected:
              this.props.isSelectedRow && this.props.isSelectedRow(dataItem),
            expanded: this.isExpandedRow(dataItem)
          }))}
          detail={expandableRow ? expandableRow.detail : null}
          onExpandChange={
            expandableRow && !expandableRow.isAtRowEnd
              ? this.expandChange
              : null
          }
          isExpandedRow={this.isExpandedRow}
          expandField={expandableRow ? 'expanded' : null}
          editField={editableRow ? this.editField : null}
          onItemChange={editableRow ? this.itemChange : null}
          pageable={this.getPageable()}
          skip={this.state.dataState.skip}
          take={this.state.dataState.take}
          filter={this.state.dataState.filter}
          sort={this.state.dataState.sort}
          onDataStateChange={this.handleDataStateChange}
          selectedField={this.props.isSelectedRow ? 'selected' : null}
        >
          {editableRow && (
            <GridToolbar>
              <button
                title="Add new record"
                className="k-button k-primary"
                onClick={this.addNew}
                disabled={this.state.isEditingMode}
              >
                Add new
              </button>
            </GridToolbar>
          )}
          {taskGrid ?
            <GridColumn
              field="selected"
              headerSelectionValue={checkableRow ? checkableRow.headerSelectionValue : false}
              width={checkableRow ? "35px" : "0px"}
            />
            :
            checkableRow && (
              <GridColumn
                field="selected"
                headerSelectionValue={checkableRow.headerSelectionValue}
                width="35px"
              />)
          }
          {this.props.children}
          {expandableRow && expandableRow.isAtRowEnd && (
            <GridColumn
              field="expanded"
              cell={this.expandCell}
              columnMenu={null}
              width="80px"
              headerCell={() => <></>}
            />
          )}
          {editableRow && (
            <GridColumn cell={this.InlineEditCell} width="180px" />
          )}
        </Grid>
        {this.state.loading && <ComponentLoader />}
      </>
    );
  }

  getPageable = () => {
    const hideablePaging = this.props.hideablePaging || false;
    const pageSize = this.state.dataState.take;
    const total = this.state.total;
    const pageable = this.props.pageable;

    if (hideablePaging && pageable && pageSize >= total) return null;

    return pageable;
  };

  isExpandedRow = (dataItem) => {
    if (!this.props.expandableRow) return false;

    const id = dataItem[this.props.expandableRow.id];

    return this.state.expandedRowIds.indexOf(id) !== -1;
  };

  expandChange = (event) => {
    const xId = event.dataItem[this.props.expandableRow.id];

    const isMatch = (id) => xId === id;
    const isExpanded = this.state.expandedRowIds.find(isMatch);

    let expandedRowIds = this.state.expandedRowIds.slice(0);

    if (!isExpanded) {
      if (this.props.expandableRow.type === 'multiple') {
        expandedRowIds.push(xId);
      } else {
        expandedRowIds = [xId];
      }
    } else {
      remove(expandedRowIds, isMatch);
    }

    this.setState({ expandedRowIds });
  };

  itemChange = (event) => {
    if (this.state.isDisabledRow) return;

    const index = this.dataIndexByField(event.dataItem);
    this.setState((prevState) => ({
      data: update(prevState.data, {
        [index]: { [event.field]: { $set: event.value } }
      })
    }));
  };

  dataIndexByField = (dataItem) => {
    const idField = this.props.editableRow.id;
    const id = dataItem[idField];
    return this.state.data.findIndex((row) => {
      return row[idField] === id;
    });
  };

  enterEdit = (dataItem) => {
    this.setState((prevState) => {
      const index = this.dataIndexByField(dataItem);
      const updateDataDef = {
        [index]: { $set: { ...dataItem, inEdit: true } }
      };

      if (prevState.isEditingMode) {
        const editIndex = this.dataIndexByField(prevState.originalRowData);
        updateDataDef[editIndex] = {
          $set: { ...prevState.originalRowData, inEdit: false }
        };
      }

      return {
        data: update(prevState.data, updateDataDef),
        originalRowData: update(prevState.originalRowData, {
          $set: dataItem
        }),
        isEditingMode: true,
        isDisabledRow: false
      };
    });
  };

  cancel = (dataItem) => {
    const index = this.dataIndexByField(dataItem);
    this.setState((prevState) => ({
      data: update(prevState.data, {
        [index]: { $set: { ...prevState.originalRowData, inEdit: false } }
      }),
      isEditingMode: false,
      isDisabledRow: false
    }));
  };

  remove = (dataItem) => {
    this.props.editableRow.deleteFn(dataItem, () => {
      const index = this.dataIndexByField(dataItem);
      this.setState({
        data: update(this.state.data, {
          $splice: [[index, 1]]
        }),
        isEditingMode: false,
        isDisabledRow: false
      });
    });
  };

  update = (dataItem) => {
    this.setState({
      isDisabledRow: true
    });
    this.props.editableRow.updateFn(dataItem, () => {
      const index = this.dataIndexByField(dataItem);
      this.setState({
        data: update(this.state.data, {
          [index]: { $set: { ...dataItem, inEdit: false } }
        }),
        isDisabledRow: false,
        isEditingMode: false
      });
    });
  };

  addNew = () => {
    this.setState((prevState) => ({
      data: [{ inEdit: true }, ...prevState.data],
      isEditingMode: true,
      isDisabledRow: false
    }));
  };

  add = (dataItemToAdd) => {
    this.setState({
      isDisabledRow: true
    });
    this.props.editableRow.addFn(dataItemToAdd, (dataItemWithId) => {
      this.setState((prevState) => ({
        data: update(prevState.data, {
          0: { $set: { ...dataItemWithId, inEdit: false } }
        }),
        isDisabledRow: false,
        isEditingMode: false
      }));
    });
  };

  discard = () => {
    this.setState((prevState) => ({
      data: update(prevState.data, {
        $splice: [[0, 1]]
      }),
      isDisabledRow: false,
      isEditingMode: false
    }));
  };

  handleDataStateChange = (e) => {
    this.fetchData(e.dataState);
  };

  additionalRequestQueryString() {
    const options = this.props.additionalRequestPayload;
    return options ? '&' + buildParameterString(options, true, true) : '';
  }

  handleJson = (json, dataState) => {
    let { Data, Total } = json;

    if (!Data && json) {
      Data = json;
      Total = json.length;
    }

    if (Array.isArray(Data)) {
      Data.forEach((r) => {
        if (r.DiaryDate) r.DiaryDate = new Date(r.DiaryDate);
        if (r.PeriodToDate) r.PeriodToDate = new Date(r.PeriodToDate);
        if (r.PeriodFromDate) r.PeriodFromDate = new Date(r.PeriodFromDate);
        if (r.CreatedDate) r.CreatedDate = new Date(r.CreatedDate);
        if (r.NoteDate) r.NoteDate = new Date(r.NoteDate);
        if (r.Date) r.Date = new Date(r.Date);
        if (r.FollowUpDate) r.FollowUpDate = new Date(r.FollowUpDate);
        if (r.OwnedDate) r.OwnedDate = new Date(r.OwnedDate);
        if (r.ClosedDate) r.ClosedDate = new Date(r.ClosedDate);
        if (r.TaskDate) r.TaskDate = new Date(r.TaskDate);
        if (r.DateOfApplication)
          r.DateOfApplication = new Date(r.DateOfApplication);
        if (r.NoteDateTime) r.NoteDateTime = new Date(r.NoteDateTime);
        if (r.DateUploaded) r.DateUploaded = new Date(r.DateUploaded);
        if (r.DocumentDate) r.DocumentDate = new Date(r.DocumentDate);
        if (r.TransactionDate) r.TransactionDate = new Date(r.TransactionDate);
        if (r.TransactionLineDate)
          r.TransactionLineDate = new Date(r.TransactionLineDate);
        if (r.TransactionsEventDate)
          r.TransactionsEventDate = new Date(r.TransactionsEventDate);
        if (r.EventDate) r.EventDate = new Date(r.EventDate);
        if (r.DueDate) r.DueDate = new Date(r.DueDate);
        if (r.CompletedOn) r.CompletedOn = new Date(r.CompletedOn);
        if (r.OriginalTaskDate)
          r.OriginalTaskDate = new Date(r.OriginalTaskDate);
        if (r.PaymentDate) r.PaymentDate = new Date(r.PaymentDate);
        if (r.PaymentPromiseDate)
          r.PaymentPromiseDate = new Date(r.PaymentPromiseDate);

        if (r.InstallmentDate) r.InstallmentDate = new Date(r.InstallmentDate);
        if (r.LastActivityDate)
          r.LastActivityDate = new Date(r.LastActivityDate);

        if (r.Detected) r.Detected = new Date(r.Detected);
        if (r.ImportStarted) r.ImportStarted = new Date(r.ImportStarted);
        if (r.ImportFinished) r.ImportFinished = new Date(r.ImportFinished);
        if (r.CreatedDateTime) r.CreatedDateTime = new Date(r.CreatedDateTime);
        if (r.ModifiedDateTime)
          r.ModifiedDateTime = new Date(r.ModifiedDateTime);
      });
    }

    return {
      data: Data,
      total: Total,
      dataState
    };
  };

  normaliseResponse = (response) => {
    if (!response.ok) {
      displayError(
        new Error(`Grid: ${this.props.id} error code: ${response.status}`)
      );
      return {
        Data: [],
        Total: 0
      };
    }

    return response.json();
  };

  fetchData(dataState) {
    if (this.props.stateKey)
      this.props.dispatch(storeGridState(this.props.stateKey, dataState));
    if (this.props.onStateChange) this.props.onStateChange(dataState);

    const isNewState = !isEqual(this.state.dataState, dataState);
    if (isNewState) {
      this.setState({ dataState, loading: true });
    } else {
      this.setState({ loading: true });
    }

    let url = this.props.path;
    const method = this.props.method;
    const dispatch = this.props.dispatch;

    if (this.props.beforeSendFn) this.props.beforeSendFn();

    const onSuccess = (response) => {
      const data = this.props.reverse ? response.data.reverse() : response.data;

      const { total, dataState } = response;

      if (this.props.afterReceivedFn) this.props.afterReceivedFn(data);
      if (this.props.stateKey) {
        this.props.dispatch(
          storeGridState(this.props.stateKey, dataState, {
            data,
            total
          })
        );
      }
      this.setState({ data, total, dataState, loading: false });
    };

    const onError = (err) => {
      // roll back gridState pre fetch?
      // if (this.props.stateKey)
      //   this.props.dispatch(storeGridState(this.props.stateKey, dataState));
      this.setState({ loading: false });
      displayError(err);
    };

    if (method === 'POST') {
      const body = {
        ...toDataSourceRequest(dataState),
        ...this.props.additionalRequestPayload
      };

      webApiInterface
        .authPost(this.props.client, url, dispatch, body, 'POST', this.controller.signal)
        .then(this.normaliseResponse)
        .then((json) => this.handleJson(json, dataState))
        .then(onSuccess)
        .catch(webApiInterface.abortHandler)
        .catch(onError);
    } else {
      url += `?${toDataSourceRequestString(
        dataState
      )}${this.additionalRequestQueryString()}`;

      webApiInterface
        .authFetch(this.props.client, url, dispatch, this.controller.signal)
        .then(this.normaliseResponse)
        .then((json) => this.handleJson(json, dataState))
        .then(onSuccess)
        .catch(webApiInterface.abortHandler)
        .catch(onError);
    }
  }

  static propTypes = {
    method: PropTypes.string.isRequired,
    reverse: PropTypes.bool,
    expandableRow: PropTypes.shape({
      detail: PropTypes.func.isRequired,
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['multiple', 'single']),
      isAtRowEnd: PropTypes.bool
    }),
    editableRow: PropTypes.shape({
      id: PropTypes.string.isRequired,
      addFn: PropTypes.func.isRequired,
      deleteFn: PropTypes.func.isRequired,
      updateFn: PropTypes.func.isRequired,
      validationFn: PropTypes.func
    }),
    checkableRow: PropTypes.shape({
      headerSelectionValue: PropTypes.bool
    }),
    afterReceivedFn: PropTypes.func,
    beforeSendFn: PropTypes.func,
    // 'none' is not allowed as breaks loader. Use 'scrollable-none' className instead
    scrollable: PropTypes.oneOf(['virtual', 'scrollable'])
  };

  static defaultProps = {
    method: 'GET',
    reverse: false,
    reorderable: true
  };
}
// do not create a dispatch to props here as this.props.dispatch is needed above.
export default connect(null, null, null, { forwardRef: true })(StatefullGrid);
