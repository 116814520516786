import Permission from "./Permission"
import classes from "./Permission.module.scss"

export const defaultMakeCallPermission = {
    read: true
}

const MakeCallPermission = ({ permission, role, dispatch, isOnRole }) => {
    return (
        <Permission
            label="Make call"
            value="makeCallPermission"
            role={role}
            dispatch={dispatch}
            isOnRole={isOnRole}
            permissionIcons={<i className={`fas fa-glasses mx-1 ${permission.read ? "text-blue" : classes["icon-inactive"]}`} />}
        />
    )
}

export default MakeCallPermission