import moment from "moment-timezone"

export const DEFAULT_DATE_FORMAT = "DD MMM YYYY"
export const DEFAULT_TIMEZONE = "Europe/London"
export const DATE_FORMAT_WITH_TIME = "YYYY-MM-DD HH:mm:ss"

export const formatDateFromNumber = (number: number, format?: string, timezone?: string) =>
    moment(number)
        .tz(timezone ?? DEFAULT_TIMEZONE)
        .format(format ?? DEFAULT_DATE_FORMAT)

export const isValidDate = (date: string) => date !== "" && moment(date).isValid()
