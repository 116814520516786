import classes from "./CustomerGrid.module.scss"
import { useLayout, expandablePanels } from "../../../contexts/LayoutContext"
import CustomerFilters from "./CustomerFilters"
import DataGrid from "../../../library/dataGrid/DataGrid"
import { convertToCurrency } from "../../../library/helpers/numberUtils"
import DataField from "../../../types/DataField"
import DataRow from "../../../library/dataGrid/types/DataRow"
import SortDirection from "../../../types/enums/SortDirection"
import Lookup from "../../../types/Lookup"
import QuickFilter from "../../automate/types/QuickFilter"
import DataGridState from "../../../library/dataGrid/types/DataGridState"
import { CustomerSearchAction, CustomerSearchActionEnum } from "../reducers/CustomerSearchReducer"
import GridRowActionButton from "../../../library/dataGrid/GridRowActionButton"
import GridColumnConfigDto from "../../../library/dataGrid/types/GridColumnConfigDto"

type CustomerGridProps = {
    gridReference: string
    customerDataFields: DataField[]
    defaultFields?: GridColumnConfigDto[]
    customers: DataRow[]
    totalCustomerCount: number
    totalCurrentBalances: { currency: string; value: number }[]
    pageIndex: number
    pageSize: number
    sortByField: DataField
    sortDirection: SortDirection
    lookups: Lookup[]
    selectedQuickFilters: QuickFilter[]
    showQuickFilters: boolean
    isLoadingCustomerData: boolean
    setSelectedCustomerReference: (c: string) => void
    dispatch: React.Dispatch<CustomerSearchAction>
}

const CustomerGrid = ({
    gridReference,
    customerDataFields,
    defaultFields,
    customers,
    totalCustomerCount,
    totalCurrentBalances,
    pageIndex,
    pageSize,
    sortByField,
    sortDirection,
    lookups,
    selectedQuickFilters,
    showQuickFilters,
    isLoadingCustomerData,
    setSelectedCustomerReference,
    dispatch
}: CustomerGridProps) => {
    const { setExpandedPanel } = useLayout()

    const onCustomerSelected = (customer: DataRow) => {
        setSelectedCustomerReference(customer.id)
        setExpandedPanel(expandablePanels.DEFAULT)
    }

    const gridState: DataGridState = {
        dataRows: customers,
        totalRowCount: totalCustomerCount,
        isLoading: isLoadingCustomerData,
        pageIndex: pageIndex,
        pageSize: pageSize,
        sortField: sortByField,
        sortDirection: sortDirection
    }

    const onStateChanged = (state: DataGridState) => {
        if (state.pageIndex !== pageIndex) {
            dispatch({ type: CustomerSearchActionEnum.PAGE_INDEX_UPDATED, pageIndex: state.pageIndex })
        }

        if (state.sortField !== sortByField) {
            dispatch({ type: CustomerSearchActionEnum.SORT_BY_FIELD_UPDATED, sortByField: state.sortField as DataField })
        }

        if (state.sortDirection !== sortDirection) {
            dispatch({ type: CustomerSearchActionEnum.SORT_DIRECTION_UPDATED, sortDirection: state.sortDirection as SortDirection })
        }

        if (state.pageSize !== pageSize) {
            dispatch({ type: CustomerSearchActionEnum.PAGE_SIZE_UPDATED, pageSize: state.pageSize })
        }
    }

    const renderRowActions = (row: DataRow) => (
        <GridRowActionButton
            dataRow={row}
            onClick={onCustomerSelected}
            ariaLabel={`select-customer-${row.id}`}
            iconClasses={"ms-auto text-blue fa fa-eye pointer"}
        />
    )

    const renderFooter = () => (
        <div>
            <span className="me-2">Total balance:</span>
            {totalCurrentBalances.map(totalCurrentBalance => (
                <span className="me-2" key={totalCurrentBalance.currency}>
                    {convertToCurrency(totalCurrentBalance.value.toFixed(2), totalCurrentBalance.currency)}
                </span>
            ))}
        </div>
    )

    const renderHeader = () => (
        <CustomerFilters
            lookups={lookups}
            selectedQuickFilters={selectedQuickFilters}
            showQuickFilters={showQuickFilters}
            dispatch={dispatch}
            isLoadingCustomerData={isLoadingCustomerData}
        />
    )

    return (
        <div className={`d-flex flex-column h-100 ${classes.container} rounded pt-1`} role="grid" aria-label="customers">
            <DataGrid
                gridReference={gridReference}
                fields={customerDataFields}
                defaultFields={defaultFields}
                lookups={lookups}
                state={gridState}
                onStateChanged={onStateChanged}
                renderHeader={renderHeader}
                renderFooter={renderFooter}
                renderRowActions={renderRowActions}
            />
        </div>
    )
}

export default CustomerGrid
