import GenericFilter from "../../../../library/FilterList/filterTypes/GenericFilter"
import { FileImportConfigColumnType } from "../FileImportConfigColumnType"
import { FileImportConfigFieldDataType, TextFormatter } from "../FileImportConfigFieldDataType"
import { FileImportConfigFieldDefaultValue } from "../FileImportConfigFieldDefaultValue"
import { FileFormat } from "../../components/utils"

export type FileEntityExportConfig = CsvFileEntityExportConfig | XmlFileEntityExportConfig

export type CsvFileEntityExportConfig = {
    reference: string
    entityType: string
    fileFormat: FileFormat.CSV | FileFormat.TEXT
    filename: string
    delimiter: string
    lineEnding: string
    hasHeaderRow: boolean
    quoteFieldsWith?: string
    filters: GenericFilter[]
    dateFormat?: string
    optionalFieldConfigs: EntityExportFieldConfig[]
    subdirectoryPath?: string
}

export type XmlFileEntityExportConfig = {
    reference: string
    entityType: string
    fileFormat: FileFormat.XML
    filename: string
    lineEnding: string
    quoteFieldsWith?: string
    filters: GenericFilter[]
    dateFormat?: string
    rootNodeFieldsConfig: XmlEntityExportParent
    headersNodeFieldsConfig: XmlAggregatedEntityExportParent[]
    footersNodeFieldsConfig: XmlAggregatedEntityExportParent[]
    subdirectoryPath?: string
}

export type EntityExportFieldConfig = {
    column: FileImportConfigColumnType
    dataFieldReference: string
    dataType: FileImportConfigFieldDataType
    ordinal: number
    defaultValue?: FileImportConfigFieldDefaultValue
    formatter?: keyof typeof TextFormatter
}

export type XmlEntityExportNode = XmlEntityExportFieldConfig | XmlEntityExportParent

export type XmlEntityExportFieldConfig = {
    type: XmlEntityExportNodeEnum.FIELD_CONFIG
    name: string
    dataFieldReference: string
    dataType: FileImportConfigFieldDataType
    defaultValue?: FileImportConfigFieldDefaultValue
    formatter?: keyof typeof TextFormatter
}

export type XmlEntityAggregationExportFieldConfig = {
    type: "aggregation"
    name: string
    dataFieldReference: string
    aggregationType: "SUM" | "AVERAGE" | "MIN" | "MAX" | "CARDINALITY"
}

export type XmlEntityValueExportFieldConfig = {
    type: "value"
    name: string
    value: string
}

export type XmlEntityTimestampExportFieldConfig = {
    type: "timestamp"
    name: string
    dateFormat: string
}

export type XmlEntityExportParent = {
    type: XmlEntityExportNodeEnum.PARENT
    name: string
    children: XmlEntityExportNode[]
}

export type XmlAggregatedEntityExportNode =
    | XmlAggregatedEntityExportParent
    | XmlEntityAggregationExportFieldConfig
    | XmlEntityTimestampExportFieldConfig
    | XmlEntityValueExportFieldConfig

export type XmlAggregatedEntityExportParent = {
    type: "parent"
    name: string
    children: XmlAggregatedEntityExportNode[]
}
export enum XmlEntityExportNodeEnum {
    PARENT,
    FIELD_CONFIG
}
