import { useEffect, useRef, useState } from "react"

const useIsHovering = (holdHoveringDelay: number = 0): [React.RefObject<HTMLDivElement>, boolean] => {
    const hoverableRef = useRef<HTMLDivElement>(null)

    const [isHovering, setIsHovering] = useState(false)
    const [isHoveringWithDelay, setIsHoveringWithDelay] = useState(false)

    const handleMouseMove = (e: MouseEvent) => {
        const node = hoverableRef.current
        if (!node) return

        const { left, top, width, height } = node.getBoundingClientRect()
        const x = e.clientX
        const y = e.clientY

        if (x < left || x >= left + width || y < top || y >= top + height) {
            setIsHovering(false)
        } else {
            setIsHovering(true)
        }
    }

    useEffect(() => {
        if (isHovering) {
            setIsHoveringWithDelay(true)
        } else {
            let canceled = false
            setTimeout(() => {
                if (!canceled) {
                    setIsHoveringWithDelay(false)
                }
            }, holdHoveringDelay)
            return () => {
                canceled = true
            }
        }
    }, [holdHoveringDelay, isHovering])

    useEffect(() => {
        document.addEventListener("mousemove", handleMouseMove)
        return () => document.addEventListener("mousemove", handleMouseMove)
    }, [])

    return [hoverableRef, isHoveringWithDelay]
}

export default useIsHovering
