import TeamPerformance from "./teamPerformance/TeamPerformance"
import classes from "./UserDashboards.module.scss"

const UserDashboards = () => {
    return (
        <div className="d-flex w-100 h-100">
            <div className={`d-flex flex-column p-3 bg-grey ${classes.dashboardsContainer}`}>
                <span className="fs-3 text-grey fw-light">Dashboards</span>
                <div className={`d-flex flex-column my-3 ${classes.selectedCard} text-white p-2 no-select`}>
                    <div className="d-flex align-items-center">
                        <i className="fa-light fa-gauge-simple-high"></i>
                        <span className="ms-2">Team Performance</span>
                    </div>
                    <span className="mt-2 small">Monitor your team's performance against various key performance indicators</span>
                </div>
            </div>
            <TeamPerformance />
        </div>
    )
}

export default UserDashboards
