import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as lodash from 'lodash';
import { webApiInterface } from '../../../api/webApiInterface';
import { executeAuthAsyncGet } from '../../../utility/asyncSupport';
import { authZ_Permissions, getApiUrl } from '../../../constants';
import ExportCsvButton from './exportCsvButton';
import { hasPermission } from '../../../utility/authZ';

import { CommandCell } from '../../common/grids/commandCell';
import EditDeleteCommandCell from '../../common/grids/editDeleteCommandCell';
import { ConfrimDialog } from '../../common/confirmDialog';
import { ColumnMenuFilter } from '../../common/grids/columnMenu';

import { GridColumn } from '@progress/kendo-react-grid';
import StatefulGrid from '../../common/grids/StatefulGrid';

class FieldMapGrid extends Component {
  constructor(props) {
    super(props);

    this.onEditClick = this.onEditClick.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);

    this.exportCsv = this.exportCsv.bind(this);

    this.state = {
      refresh: 0,
      showDeleteDialog: false,
      itemToDelete: null
    };

    this.EditDeleteCommandCell = EditDeleteCommandCell({
      onEditClick: this.onEditClick,
      onDeleteClick: this.onDeleteClick
    });

    this.ViewCommandCell = CommandCell({
      onCommandClick: this.onEditClick,
      cssTag: 'Edit',
      buttonText: 'Edit'
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.fieldMap.success || nextProps.fieldMap.deleted) {
      this.refresh();
    }
  }

  refresh = () => {
    this.setState({ refresh: this.state.refresh + 1 });
  };

  onEditClick = (e, dataItem) => {
    e.preventDefault();

    const fieldMapId = dataItem.id;
    this.props.onAdd(fieldMapId);
  };

  onAddClick() {
    this.props.onAdd();
  }

  onDeleteClick = (e, dataItem) => {
    e.preventDefault();

    this.setState({
      showDeleteDialog: true,
      itemToDelete: dataItem
    });
  };

  deleteItem = () => {
    if (this.state.itemToDelete !== null) {
      this.props.onDelete(this.state.itemToDelete);
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.setState({
      showDeleteDialog: false
    });
  };

  getColumns = () => {
    const columns = [
      { field: 'id', title: 'Id' },
      { field: 'recordInstance', title: 'Rec Instance' },
      { field: 'destinationField', title: 'Dest Field' },
      { field: 'sourceFieldNameOverride', title: 'Source Name' },
      { field: 'staticValue', title: 'Static Value' },
      { field: 'defaultValue', title: 'Default Value' },
      { field: 'importFieldLookup', title: 'Foreign Key' },
      {
        field: 'isNativeKey',
        title: 'Is Native Key',
        type: 'boolean',
        filter: 'boolean'
      },
      {
        field: 'isMandatory',
        title: 'Is Mandatory',
        type: 'boolean',
        filter: 'boolean'
      },
      {
        field: 'isActive',
        title: 'Is Active',
        type: 'boolean',
        filter: 'boolean'
      }
    ];

    return columns;
  };

  exportCsv() {
    const xhr = new XMLHttpRequest();
    const url =
      getApiUrl(this.props.client) +
      'api/import-configuration/export-csv?exportType=5&fileTypeId=' +
      this.props.fileTypeId;

    xhr.open('GET', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(xhr.response);
      a.download = 'FieldMapping.csv';
      a.click();
    };

    this.props.executeAuthAsyncGet(this.props.client, 'Lookup/OK', 'CHECK_OK', null, function () {
      webApiInterface.applySecurity(xhr);
      xhr.send(null);
    });
  }

  static getKey() {
    return 'field-map-grid';
  }

  render() {
    const button = hasPermission(
      this.props.permissions,
      authZ_Permissions.FileImportAdminUpdate
    ) ? (
      <button
        className="btnDefault btn btn-default float-end"
        onClick={this.onAddClick}
      >
        New Field Mapping
      </button>
    ) : (
      ''
    );

    return (
      <div>
        <h3>Field Mapping</h3>
        <ExportCsvButton clicked={this.exportCsv} />
        {button}

        <StatefulGrid
          client={this.props.client}
          id={FieldMapGrid.getKey()}
          key={FieldMapGrid.getKey()}
          path={getApiUrl(this.props.client) + 'api/import-configuration/field-maps'}
          sortable
          className={'scrollable-none'}
          pageSize={100}
          pageable={{
            pageSizes: [10, 50, 100, 250, 500],
            buttonCount: 5
          }}
          additionalRequestPayload={{
            fileTypeId: this.props.fileTypeId.toString()
          }}
          columnMenu={ColumnMenuFilter}
          defaultField={{ field: 'id', dir: 'asc' }}
          refresh={this.state.refresh}
        >
          {this.getColumns().map((column) => (
            <GridColumn key={column.field} {...column} />
          ))}

          {hasPermission(
            this.props.permissions,
            authZ_Permissions.FileImportAdminUpdate
          ) && <GridColumn cell={this.EditDeleteCommandCell} width={'200px'} />}

          {hasPermission(
            this.props.permissions,
            authZ_Permissions.FileImportAdminRead
          ) && <GridColumn cell={this.ViewCommandCell} width={'100px'} />}
        </StatefulGrid>
        <ConfrimDialog
          show={this.state.showDeleteDialog}
          message={'Are you sure you want to delete this record?'}
          onConfirm={this.deleteItem}
          onClose={this.closeDialog}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let fieldMap = {
    success: false
  };

  if (state.fieldMaps.length > 0) {
    fieldMap = lodash.last(state.fieldMaps);
  }

  return {
    permissions: state.authReducer.permissions,
    fieldMap: fieldMap
  };
}

export default connect(mapStateToProps, {
  executeAuthAsyncGet
})(FieldMapGrid);
