import { webApiInterface } from './webApiInterface';
import * as actions from '../actions/reportActions';
import { getApiUrl } from '../constants';

export const getReportDetails = (client, reportName) => (dispatch) => {
  const url = `${getApiUrl(client)}api/report/getconfig?reportName=${reportName}`;

  dispatch(actions.getReportDetails());

  return webApiInterface
    .authFetch(client, url, dispatch)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      dispatch(actions.receiveReportDetails(json));
    })
    .catch(function (err) {
      dispatch(actions.receiveReportDetailsError(err));
    });
};
