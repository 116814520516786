import { FileImportConfigFieldDataType, TextFormatter } from "../../../../types/FileImportConfigFieldDataType"
import { FileImportConfigFieldDefaultValue, FileImportConfigFieldDefaultValueEnum } from "../../../../types/FileImportConfigFieldDefaultValue"
import classes from "./XmlExportFieldConfig.module.scss"
import { XmlEntityExportNodeState, XmlEntityExportParentState } from "../../../../reducers/FileEntityExportConfigReducer"
import { IconButton, Input, Toggle } from "invevo-react-components"
import { getDataTypeFromDataField, getDefaultValueTypeFromDataField } from "../../../importConfigs/fileImportConfigSetup/importFieldConfig/ImportFieldConfig"
import DataTypeConfig from "../../../importConfigs/fileImportConfigSetup/importFieldConfig/DataTypeConfig"
import DefaultValueConfig from "../../../importConfigs/fileImportConfigSetup/importFieldConfig/DefaultValueConfig"
import EntityDataField from "../../../../../entityConfig/types/EntityDataField"
import EntityDataFieldDropdown from "../../../../../../library/dropdowns/EntityDataFieldDropdown"
import { dataTypeEnumToDataPrimitive } from "../../../utils"
import { XmlEntityExportFieldConfig, XmlEntityExportNodeEnum } from "../../../../types/fileEntityExport/FileExportConfig"
import Dropdown from "../../../../../../library/dropdowns/Dropdown"
import DropdownOption from "../../../../../../types/DropdownOptions"
import { v4 as uuidv4 } from "uuid"

type XmlExportFieldConfigCardProps = {
    state: {
        config: XmlEntityExportNodeState
        dataFields: EntityDataField[]
        onEditingNodeChanged: (changedNode: XmlEntityExportNodeState) => void
        saveEditingConfig: () => void
    }
}

const dropdownOptions = [
    {
        label: "Field",
        value: XmlEntityExportNodeEnum.FIELD_CONFIG
    },
    {
        label: "Nested Field",
        value: XmlEntityExportNodeEnum.PARENT
    }
]
const XmlExportFieldConfigCard = ({ state }: XmlExportFieldConfigCardProps) => {
    const { config, dataFields, onEditingNodeChanged, saveEditingConfig } = state

    const onDropdownOptionChange = (option: DropdownOption<XmlEntityExportNodeEnum>) => {
        onEditingNodeChanged({
            ...config,
            type: option.value
        } as XmlEntityExportNodeState)
    }

    const onNameChange = (event: React.FormEvent<HTMLInputElement>) => {
        const name = event.currentTarget.value

        onEditingNodeChanged({
            ...config,
            name
        })
    }
    const onDataFieldChange = (option?: EntityDataField | undefined) => {
        if (!option || config.type !== XmlEntityExportNodeEnum.FIELD_CONFIG) return
        onEditingNodeChanged({
            ...config,
            dataFieldReference: option.fieldName,
            dataType: {
                ...config.dataType,
                type: getDataTypeFromDataField(dataTypeEnumToDataPrimitive(option.dataType.type))
            } as FileImportConfigFieldDataType,
            defaultValue: config.defaultValue
                ? ({
                      ...config.defaultValue,
                      type: getDefaultValueTypeFromDataField(dataTypeEnumToDataPrimitive(option.dataType.type))
                  } as FileImportConfigFieldDefaultValue)
                : undefined
        })
    }

    const onDataTypeChange = (dataType: FileImportConfigFieldDataType) => {
        if (config.type !== XmlEntityExportNodeEnum.FIELD_CONFIG) return

        onEditingNodeChanged({
            ...config,
            dataType
        })
    }

    const onFormatChange = (formatter: keyof typeof TextFormatter) => {
        if (config.type !== XmlEntityExportNodeEnum.FIELD_CONFIG) return

        onEditingNodeChanged({
            ...config,
            formatter
        })
    }

    const onDefaultValueChange = (defaultValue: FileImportConfigFieldDefaultValue) => {
        if (config.type !== XmlEntityExportNodeEnum.FIELD_CONFIG) return

        onEditingNodeChanged({
            ...config,
            defaultValue
        })
    }

    const onShowDefaultValueConfigChange = () => {
        if (config.type !== XmlEntityExportNodeEnum.FIELD_CONFIG) return

        onEditingNodeChanged({
            ...config,
            defaultValue: config.defaultValue
                ? undefined
                : {
                      type: FileImportConfigFieldDefaultValueEnum.STRING,
                      stringValue: ""
                  }
        })
    }

    const addChildNode = () => {
        if (config.type !== XmlEntityExportNodeEnum.PARENT) return

        onEditingNodeChanged({
            ...config,
            children: [
                ...config.children,
                {
                    name: "New xml node",
                    key: uuidv4(),
                    parentKey: config.key,
                    type: XmlEntityExportNodeEnum.PARENT,
                    children: [],
                    selected: false
                }
            ]
        })
    }
    const isFieldConfig = config.type === XmlEntityExportNodeEnum.FIELD_CONFIG

    const getDataField = () => {
        if (isFieldConfig) {
            return dataFields.find(df => df.fieldName === config.dataFieldReference)
        }
    }
    const renderFieldConfig = (fieldConfig: XmlEntityExportFieldConfig) => {
        return (
            <div>
                <div className="d-flex flex-grow-1">
                    <div className={`d-flex flex-grow-1 flex-column small me-3`}>
                        <span className="text-uppercase small mb-1 ms-1 text-grey">Invevo data field</span>
                        <EntityDataFieldDropdown
                            className="flex-grow-1 mb-2"
                            options={state.dataFields}
                            selectedOption={getDataField()}
                            onOptionSelected={onDataFieldChange}
                            ariaLabel={"Add invevo data field"}
                        />
                    </div>
                    {getDataField() && (
                        <DataTypeConfig
                            config={fieldConfig.dataType}
                            formatter={fieldConfig.formatter}
                            onChange={onDataTypeChange}
                            onFormatterChange={onFormatChange}
                        />
                    )}
                </div>
                <div className={`${classes.horizontalDivider} m-2`} />
                {getDataField() && (
                    <>
                        <div className={`${classes.horizontalDivider} m-2`} />
                        <div className={`d-flex flex-column`}>
                            <div className="d-flex align-items-center">
                                <span className="fs-4 text-grey">Default value if empty</span>
                                <Toggle
                                    className="ms-auto"
                                    status={fieldConfig.defaultValue !== undefined}
                                    onStatusChanged={onShowDefaultValueConfigChange}
                                    toggleColour="blue"
                                    size="small"
                                    ariaLabel="Add default value"
                                />
                            </div>
                            <div className={`${classes.horizontalDivider} m-2`} />
                            {fieldConfig.defaultValue && <DefaultValueConfig config={fieldConfig.defaultValue} onChange={onDefaultValueChange} />}
                        </div>
                    </>
                )}
            </div>
        )
    }
    const renderParentConfig = (parentConfig: XmlEntityExportParentState) => {
        return (
            <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                    <span className="text-grey">Add child node </span>
                    <span className="text-grey">current children: {parentConfig.children.length}</span>
                </div>
                <IconButton className="" iconClasses="far fa-add" onClick={addChildNode} />
            </div>
        )
    }
    return (
        <div className={`d-flex w-100 ${classes.card}`}>
            <div className="d-flex flex-column p-4 w-100">
                <div className={`d-flex flex-grow-1 flex-column small`}>
                    <div className="d-flex align-items-center mb-2">
                        <span className="fs-4 text-grey">Node name</span>
                    </div>

                    <Input className="" placeholder="Please enter the field name" value={config.name} onChange={onNameChange} />
                </div>
                <div className={`${classes.horizontalDivider} m-2`} />
                {isFieldConfig ? renderFieldConfig(config) : renderParentConfig(config)}
            </div>
            <div className={"d-flex flex-column align-content-end justify-content-between m-2"}>
                <Dropdown
                    options={dropdownOptions}
                    onOptionSelected={onDropdownOptionChange}
                    selectedOption={dropdownOptions.find(option => option.value === config.type)}
                    direction={"up"}
                />
                <IconButton className="justify-content-end" iconClasses="far fa-check" onClick={saveEditingConfig} />
            </div>
        </div>
    )
}

export default XmlExportFieldConfigCard
