import { useState, useRef, useCallback, useEffect } from 'react'
import { Loading, useApi, useClient, useConfig } from 'invevo-react-components'
import { throttle } from 'lodash'
import { v4 as uuid } from 'uuid'
import classes from './TransactionReferenceAutoComplete.module.scss'
import SelectedReference from './SelectedReference'
import DropdownOption from './DropdownOption'

const MIN_CHARACTERS_FOR_AUTO_COMPLETE = 3

const TransactionReferenceAutoComplete = ({ customerRefs, onChange }) => {
    const [lastLabel, setLastLabel] = useState("")
    const [matchedOptions, setMatchedOptions] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([])

    const api = useApi()
    const ref = useRef()
    const config = useConfig()
    const client = useClient()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callApi = useCallback(throttle((currentValue, selectedOptions) => {
        if (!config.SEARCH_API_URL || currentValue.length < MIN_CHARACTERS_FOR_AUTO_COMPLETE) {
            return
        }

        setIsFetching(true)
        api.post(`${config.SEARCH_API_URL}/api/${client}/transaction-data-autocomplete`, {
            fieldName: "transactionref",
            dataPrimitive: "TEXT",
            customerRefs: customerRefs.map(cr => cr.toLowerCase()),
            currentValue: currentValue
        }).then(response => {
            const selectedOptionLabels = selectedOptions.map(o => o.value)
            setMatchedOptions(response.data.filter(o => !selectedOptionLabels.includes(o)).map((optionValue, index) => {
                return {
                    id: index,
                    value: optionValue
                }
            }))
            setIsFetching(false)
        }).catch(error => {
            console.error(error)
            setMatchedOptions([])
            setIsFetching(false)
        })
    }, 750), [config.SEARCH_API_URL]);

    useEffect(() => {
        document.addEventListener("click", handleClickOutsideComponent)
        return () => document.removeEventListener("click", handleClickOutsideComponent)
    }, []);

    useEffect(() => {
        if (!lastLabel || lastLabel === "") {
            setMatchedOptions([])
            return
        }

        callApi(lastLabel, selectedOptions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callApi, lastLabel])

    const onInputChange = event => setLastLabel(event.target.value)

    const handleClickOutsideComponent = event => {
        if (ref && ref.current) {
            if (!ref.current.contains(event.target)) {
                setMatchedOptions([])
            }
        }
    }

    const onInputClick = () => callApi(lastLabel, selectedOptions)

    const onOptionSelected = option => {
        setSelectedOptions([...selectedOptions, option])
        setMatchedOptions([])
        setLastLabel("")
    }

    const onApplyTransRefFilterClick = () => {
        if (selectedOptions.length === 0) return
        onChange(selectedOptions.map(o => o.value))
        setLastLabel("")
    }

    const onPaste = event => {
        const pastedText = event.clipboardData.getData('Text')
        if (pastedText) {
            setMatchedOptions([])
            setLastLabel("")

            const transactionRefs = pastedText.trim().split(/\s*[\s,]\s*/).filter(r => r !== "")
            onChange(transactionRefs)
        }
    }
    const onValueRemoved = optionValue => setSelectedOptions(selectedOptions.filter(v => v.value !== optionValue))

    return (
        <Loading isLoading={!config.SEARCH_API_URL} colour="blue">
            <div className='d-flex'>
                <div ref={ref} className="d-flex flex-column flex-wrap w-75">
                    <div className={`d-flex align-items-center flex-wrap p-1 ${classes.parameters}`}  >
                        {selectedOptions.map(option =>
                            <SelectedReference key={option.value} value={option.value} label={option.value} onValueRemoved={onValueRemoved} />
                        )}
                        <div className="d-flex w-100 position-relative">
                            <input
                                className={`d-flex w-100 p-0 ${classes.input} `}
                                value={lastLabel}
                                onChange={onInputChange}
                                placeholder="Please enter transaction reference"
                                onClick={onInputClick}
                                onPaste={onPaste}
                                aria-label="transaction-ref-input"
                            />
                            {isFetching &&
                                <div className={`${classes.spinnerContainer} position-absolute`}>
                                    <div className="position-relative">
                                        <div className="spinner-border spinner-border-sm text-blue" role="status"></div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="d-flex w-100">
                        {matchedOptions.length > 0 &&
                            <div className={`position-absolute w-50 ${classes.options} border text-grey shadow bg-grey`}>
                                {matchedOptions.map(o => {
                                    return (
                                        <DropdownOption
                                            key={o.id}
                                            option={o}
                                            lastLabel={lastLabel}
                                            onOptionSelected={onOptionSelected}
                                        />
                                    )
                                }).reduce((prev, curr) => [prev, <div key={generateHtmlId()} className="w-100 border-top"></div>, curr])
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className={`${classes.applyButton} ${selectedOptions.length !== 0 ? '' : classes.disabledButton} pointer d-flex ms-2 px-2 py-1`}
                    onClick={onApplyTransRefFilterClick}
                    role="button"
                    aria-label="apply-ref-filter-button">
                    <span className="m-auto no-select">Apply filter</span>
                </div>
            </div>
        </Loading>
    )
}

const generateHtmlId = () => `a${uuid().replace(/-/g, "")}`;

export default TransactionReferenceAutoComplete