import { Collapsable, Loading, useClient, useConfig, } from 'invevo-react-components'
import { v4 as uuidv4 } from 'uuid'
import { Filters } from '../../filters/Filters'

const DataFilters = ({ state, dispatch }) => {
    const client = useClient()
    const config = useConfig()

    const onFilterChange = (changedFilter, changedFilterIndex) => {
        const updatedFilters = state.selectedWorkflowRule.customerFilters.map((filter, filterIndex) => {
            if (changedFilterIndex !== filterIndex) {
                return filter
            }

            let operatorValue = {}
            if (changedFilter.operator) {
                operatorValue = changedFilter.operator
            }
            return {
                ...filter, ...{
                    fieldName: changedFilter.fieldName,
                    type: changedFilter.type,
                    value: changedFilter.value,
                    operator: operatorValue,
                    isValid: changedFilter.isValid
                }
            }
        })

        dispatch({ type: "SELECTED_WORKFLOW_RULE_UPDATED", workflowRule: { ...state.selectedWorkflowRule, customerFilters: updatedFilters } })
    }

    const onAddFilter = () => {
        dispatch({
            type: "SELECTED_WORKFLOW_RULE_UPDATED",
            workflowRule: {
                ...state.selectedWorkflowRule,
                customerFilters: [...state.selectedWorkflowRule.customerFilters, { fieldName: "", value: "", type: "", id: state.selectedWorkflowRule.customerFilters.length }]
            }
        })
    }

    const onRemoveFilter = (_, filterIndex) => {
        dispatch({
            type: "SELECTED_WORKFLOW_RULE_UPDATED",
            workflowRule: {
                ...state.selectedWorkflowRule,
                customerFilters: state.selectedWorkflowRule.customerFilters.filter((_, index) => index !== filterIndex)
            }
        })
    }

    const onRemoveAll = () => {
        dispatch({
            type: "SELECTED_WORKFLOW_RULE_UPDATED",
            workflowRule: {
                ...state.selectedWorkflowRule,
                customerFilters: state.selectedWorkflowRule.customerFilters.slice(0, 0)
            }
        })
    }

    const getFieldOptions = (state) => {
        return state.customerData.fieldConfigs.map((field, index) => {
            return {
                id: index,
                type: field.customerFieldType,
                fieldName: field.fieldName,
                displayName: field.displayName,
                fieldDataType: field.fieldDataType
            }
        })
    }


    const getAutocompleteFields = (state) => {
        return state.customerData.fieldConfigs.filter(f => f.isAvailableInAutoComplete).map(cd => cd.displayName)
    }

    return (
        <Loading isLoading={!config.AUTOMATE_API_URL} colour="blue">
            <div className="d-flex flex-column">
                <div className="d-flex justify-content-between">
                    <h2 className="text-grey mb-0">Evaluation group</h2>
                </div>
                <Collapsable className="mt-3" title={`FILTERS (${state.selectedWorkflowRule.customerFilters.length})`} id={`filter-${uuidv4()}`}>
                    <Filters
                        className="pt-3"
                        rules={state.selectedWorkflowRule.customerFilters}
                        title=""
                        onRuleChange={onFilterChange}
                        onAddRule={onAddFilter}
                        onRemoveRule={onRemoveFilter}
                        onRemoveAll={onRemoveAll}
                        inputType="customerfielddropdown"
                        fieldOptions={getFieldOptions(state)}
                        fieldsAvailableForAutoComplete={getAutocompleteFields(state)}
                        autoCompleteUrl={`${config.AUTOMATE_API_URL}/api/${client}/autocomplete-field-values`}
                        enableTooltip={true}
                    />
                </Collapsable>
            </div>
        </Loading>
    )
}

export default DataFilters