import * as types from '../actions/actionTypes';

export default function globalConfigurationReducer(state = [], action) {
    switch (action.type) {
        case types.LOAD_GLOBAL_CONFIGURATION:
            return [Object.assign({}, action.globalConfiguration)];
        default:
            return state;
    }
}

export const selectGlobalConfiguration = state => getLast(state.globalConfiguration);

// This could now always select [0] as the reducer above no longer pushes the action result into the array.
function getLast(arr) {
    if (Array.isArray(arr)) {
        return arr[arr.length - 1]
    } else {
        return arr
    }
}