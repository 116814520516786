import React from "react"

import classes from "./repaymentPlan.module.scss"

const WelcomeNewPlan = ({ onCreateNew }) => (
    <div className="container-fluid text-center mt-5">
        <i className="fa-solid fa-exclamation-triangle info-icon fa-6x"></i>
        <p className={"font-italic mt-3 mb-0 info-text"}>No payment plan currently active</p>
        <button className={`btn ${classes["btn-custom"]} mt-3`} aria-label="create-payment-plan" onClick={onCreateNew}>
            <i className="fa-solid fa-plus me-2"></i>
            Create payment plan
        </button>

        <p className={"info-text mt-3"}>Tip: You can create a payment plan based on selected invoices using the customer transaction view.</p>
    </div>
)

export default WelcomeNewPlan
