import React from 'react';

export const jsxArrayJoin = (lines, seperator = <br />) => {
  if (Array.isArray(lines)) {
    return lines.map((line, i) => (
      <React.Fragment key={Math.random()}>
        {line}
        {i < lines.length - 1 && seperator}
      </React.Fragment>
    ));
  }
  // fall back to support legacy api which returns string
  return lines;
};
