import React from 'react';

const NumberInput = ({ change, value, tabIndex, maxLength = 25 }) => {
  const onLocalChange = (e) => change(e.target.value);

  return (
    <input
      type="number"
      className="form-control"
      maxLength={maxLength}
      value={value}
      onChange={onLocalChange}
      tabIndex={tabIndex}
    />
  );
};

export default NumberInput;
