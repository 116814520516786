import React from 'react';

import UserEmailTaskGrid from './userEmailTaskGrid';
import useUserConfigColumns from '../../hooks/useUserConfigColumns';
import { createSelector } from 'reselect';
import { getUserTaskGrid } from '../../selectors/task';
import { useSelector } from 'react-redux';
import { UserConfigViewType } from '../../constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import classes from './userTaskContainer.module.scss'

const userGridTotal = createSelector(
  (state) => state.gridCacheReducer.grids,
  (grids) => {
    const { invevoTaskGridPageSize } = useFlags();
    const gridCache = getUserTaskGrid(grids);

    if (!gridCache || !gridCache.lastResponse) return '';

    const { total = 0 } = gridCache.lastResponse;
    const totalText =
      total < invevoTaskGridPageSize ? total : `${invevoTaskGridPageSize}+`;
    return `(${totalText})`;
  }
);

const UserEmailTaskContainer = () => {
  const totalTasks = useSelector(userGridTotal);

  const [columns, setColumns] = useUserConfigColumns(
    UserConfigViewType.userTaskGridColConfig
  );

  return (
    <div className={`w-100 container-fluid p-0 top20 ${classes["overflow-y"]}`} id="user-task-list">
      <div className="row">
        <div className="d-flex mb-3">
          <span className="mx-3 text-grey fs-3">Unread email {totalTasks}</span>
        </div>
      </div>

      <div className="row top10 mx-1">
        <div className="col-12">
          <UserEmailTaskGrid columns={columns} setColumns={setColumns} />
        </div>
      </div>
    </div>
  );
};

export default UserEmailTaskContainer;
