import { Provider } from 'react-redux';
import RingFenceWrapper from './RingFenceWrapper';

const RingFenceOfLegacyCode = ({ store, children }) => {
    return (
        <Provider store={store}>
            <RingFenceWrapper>{children}</RingFenceWrapper>
        </Provider>
    )
}

export default RingFenceOfLegacyCode