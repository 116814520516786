import { workspaceLayoutContext } from '../contexts/workspaceLayoutContext';
import { useContext } from 'react';
import { layoutConstants } from '../constants';

// this would be better if it took a component and checked if it was full screen irrespective of side
const useAppLayout = () => {
  const { layoutState } = useContext(workspaceLayoutContext);
  const isFullScreenLeft = layoutState.collapsed === 'right';
  const isFullScreenRight = layoutState.collapsed === 'left';

  let rightPaneBreakPoint = 'md';
  if (layoutState.rightPaneWidth <= layoutConstants.RIGHT_PANE_MIN_WIDTH) {
    rightPaneBreakPoint = 'sm';
  } else if (
    layoutState.rightPaneWidth >= layoutConstants.RIGHT_PANE_LG_WIDTH
  ) {
    rightPaneBreakPoint = 'lg';
  }
  return { isFullScreenLeft, isFullScreenRight, rightPaneBreakPoint };
};

export default useAppLayout;
