import Footer from "./Footer"
import { useEffect, useState } from 'react';
import AccountStatus from './accountStatus/AccountStatus';
import { useIsMobile } from '../../../hooks/useIsMobile'
import AgedDebt from './agedDebt/AgedDebt';
import TransactionStatus from './transactionStatus/TransactionStatus'
import TransactionType from './transactionType/TransactionType';

const Dashboard = () => {
    const [agedDebtGroups, setAgedDebtGroups] = useState([])
    const [prevAgedDebtData, setPrevAgedDebtData] = useState([])

    const [transactionStatusGroups, setTransactionStatusGroups] = useState([])
    const [prevTransStatusData, setPrevTransStatusData] = useState([])

    const [accountStatusGroups, setAccountStatusGroups] = useState([])
    const [prevAccountStatusData, setPrevAccountStatusData] = useState([])

    const [transactionTypeGroups, setTransactionTypeGroups] = useState([])
    const [prevTransTypeData, setPrevTransTypesData] = useState([])
    const isMobile = useIsMobile()

    useEffect(() => {
        window.parent.postMessage("rendered", "*")
    }, [])

    useEffect(() => {
        if (prevTransStatusData.length === 0 || transactionStatusGroups.length === 0) return
        setTimeout(() => {
            const newList = transactionStatusGroups.map(d => prevTransStatusData.includes(d.transactionStatus) ? { ...d, isSelected: true } : { ...d, isSelected: false })
            setTransactionStatusGroups(newList)
            setPrevTransStatusData([])
        }, 100)
    }, [transactionStatusGroups, prevTransStatusData])

    useEffect(() => {
        if (prevTransTypeData.length === 0 || transactionTypeGroups.length === 0) return
        setTimeout(() => {
            const newList = transactionTypeGroups.map(d => prevTransTypeData.includes(d.transactionType) ? { ...d, isSelected: true } : { ...d, isSelected: false })
            setTransactionTypeGroups(newList)
            setPrevTransTypesData([])
        }, 100)
    }, [transactionTypeGroups, prevTransTypeData])

    useEffect(() => {
        if (prevAccountStatusData.length === 0 || accountStatusGroups.length === 0) return
        setTimeout(() => {
            const newList = accountStatusGroups.map(d => prevAccountStatusData.includes(d.accountStatus) ? { ...d, isSelected: true } : { ...d, isSelected: false })
            setAccountStatusGroups(newList)
            setPrevAccountStatusData([])
        }, 100)
    }, [accountStatusGroups, prevAccountStatusData])

    useEffect(() => {
        if (prevAgedDebtData.length === 0 || agedDebtGroups.length === 0) return
        setTimeout(() => {
            const newList = agedDebtGroups.map(d => prevAgedDebtData.includes(d.index) ? { ...d, isSelected: true } : { ...d, isSelected: false })
            setAgedDebtGroups(newList)
            setPrevAgedDebtData([])
        }, 100)
    }, [agedDebtGroups, prevAgedDebtData])

    window.onmessage = event => {
        if (event.data.prevAgedDebt) {
            setPrevAgedDebtData(event.data.prevAgedDebt)
        }
        if (event.data.prevTransactionStatus) {
            setPrevTransStatusData(event.data.prevTransactionStatus)
        }
        if (event.data.prevTransactionType) {
            setPrevTransTypesData(event.data.prevTransactionType)
        }
        if (event.data.prevAccountStatus) {
            setPrevAccountStatusData(event.data.prevAccountStatus)
        }
    }

    const onSearchClick = () => {
        window.parent.postMessage({
            selectedAgedDebts: agedDebtGroups.filter(a => a.isSelected).map(d => d.index),
            selectedTransactionStatuses: transactionStatusGroups.filter(t => t.isSelected),
            selectedAccountStatuses: accountStatusGroups.filter(t => t.isSelected),
            selectedTransactionTypes: transactionTypeGroups.filter(t => t.isSelected)
        }, "*")
    }

    return (
        <div className="d-flex w-100 h-100 flex-column">
            <div className={`d-flex mt-2 px-3 overflow-auto ${isMobile ? 'flex-column' : ''} flex-grow-1`} id="container">
                <div className={`d-flex ${isMobile ? 'pb-2' : 'pe-2'} flex-column w-50 `} style={{ minWidth: '620px' }}>
                    <div className="d-flex mb-2">
                        <AgedDebt agedDebtGroups={agedDebtGroups} setAgedDebtGroups={setAgedDebtGroups} />
                    </div>
                    <div className="d-flex mt-2">
                        <AccountStatus
                            accountStatusGroups={accountStatusGroups}
                            setAccountStatusGroups={setAccountStatusGroups}
                        />
                    </div>
                </div>

                <div className={`d-flex ${isMobile ? 'pt-2' : 'ps-2'} flex-column  w-50`} style={{ minWidth: '620px' }}>
                    <div className="d-flex mb-2">
                        <TransactionStatus
                            transactionStatusGroups={transactionStatusGroups}
                            setTransactionStatusGroups={setTransactionStatusGroups} />
                    </div>
                    <div className="d-flex mt-2">
                        <TransactionType
                            transactionTypeGroups={transactionTypeGroups}
                            setTransactionTypeGroups={setTransactionTypeGroups} />
                    </div>
                </div>
            </div>
            <Footer
                selectionCount={agedDebtGroups.filter(a => a.isSelected).length +
                    accountStatusGroups.filter(t => t.isSelected).length +
                    transactionStatusGroups.filter(t => t.isSelected).length +
                    transactionTypeGroups.filter(t => t.isSelected).length}
                onSearchClick={onSearchClick} />
        </div>
    )
}

export default Dashboard