/////////////////////////
// Set selected account
/////////////////////////
export const requestSetSelectedAccount = (accountId, isVirtualAccount) => ({
    type: 'CSR_REQUEST_SET_SELECTED_ACCOUNT',
    accountId,
    isVirtualAccount
  });
  export const receiveSetSelectedAccount = (data) => ({
    type: 'CSR_RECEIVE_SET_SELECTED_ACCOUNT',
    data
  });
  export const errorSetSelectedAccount = (err) => ({
    type: 'CSR_ERROR_SET_SELECTED_ACCOUNT',
    err
  });
  
  export const customerLoaded = (customer) => ({
    type: 'CUSTOMER_LOADED',
    customer
  });
  export const customerChanged = (accountId, isVirtualAccount) => ({
    type: 'CUSTOMER_CHANGED',
    accountId,
    isVirtualAccount
  });
  
  export const resetCurrentCustomerState = () => ({
    type: 'RESET_CURRENT_CUSTOMER_STATE'
  });
  